import React from 'react';
import { Textarea, Block, Label, Button, Icon } from '@upsales/components';
import T from 'Components/Helpers/translate';
import BemClass from '@upsales/components/Utils/bemClass';
import './NotesWithSignature.scss';
import insertSignatureOnRef from 'App/helpers/insertSignatureOnRef';
import Tooltip from '@upsales/components/Tooltip/Tooltip';

interface Props extends Omit<React.ComponentProps<typeof Block>, 'onChange'> {
	onChange: (val: string) => void;
	value: string;
	required?: boolean;
	tooltipText?: string;
	disabled?: boolean;
}
const NotesWithSignature = ({ onChange, value, required, tooltipText, disabled = false, ...props }: Props) => {
	let textAreaRef = React.useRef<HTMLTextAreaElement>(null);
	const classes = new BemClass('NotesWithSignature');
	const textArea = (
		<Textarea
			value={value}
			onChange={e => onChange(e.target.value)}
			placeholder={T('default.addNotes')}
			textareaRef={r => (textAreaRef = { current: r })}
			disabled={disabled}
		/>
	);
	return (
		<Block {...props} className={classes.b()}>
			<div className={classes.elem('label').b()}>
				<Label required={required}>{T('default.notes')}</Label>
				<Button
					disabled={disabled}
					onClick={() => (textAreaRef.current ? insertSignatureOnRef(textAreaRef.current, onChange) : null)}
					type="link"
				>
					<Icon name="pencil" space="mrs" />
					{T('default.insertSign')}
				</Button>
			</div>
			{tooltipText ? <Tooltip title={tooltipText}>{textArea}</Tooltip> : textArea}
		</Block>
	);
};
export default NotesWithSignature;
