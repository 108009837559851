import Prospecting from 'App/babel/resources/Prospecting';
import RequestBuilder, { comparisonTypes } from 'Resources/RequestBuilder';
import { capitalize } from 'lodash';

const countryMap = {
	Location: 'SE',
	LocationNO: 'NO',
	LocationGB: 'GB'
};

function getData(filterName, field) {
	return function searcFn() {
		return async (term, fields, offset, limit) => {
			const rb = new RequestBuilder();
			rb.limit = limit;
			rb.offset = offset;

			if (term) {
				rb.addFilter({ field }, comparisonTypes.Search, term.toLowerCase());
			}

			const country = countryMap[filterName];

			if (country === 'NO' && field === 'postRegionName') {
				return { data: [], metadata: { limit, offset, total: 0 } };
			}

			rb.extraParams.push({ key: 'country', value: country });

			const { data, metadata } = await Prospecting.lookup(field, rb.build());
			const mappedData = data
				.filter(item => !!item[field])
				.map(item => ({
					id: item[field],
					name: country === 'SE' ? capitalize(item[field].toLowerCase()) : item[field]
				}));
			return { data: mappedData, metadata };
		};
	};
}

export const getSearchFunctions = filterName => {
	return {
		stateSerchFn: getData(filterName, 'postRegionName'),
		townSerchFn: getData(filterName, 'postTown'),
		municipalitySerchFn: getData(filterName, 'postCommunityName')
	};
};

export function initSelection() {
	return (customerId, values) => {
		const data = values.map(value => ({ id: value, name: capitalize(value.toLowerCase()) }));
		return Promise.resolve({ data });
	};
}

function isActiveValue(value) {
	return value !== null && value !== '' && value !== undefined;
}

function generateFilter(overrides = {}) {
	function generate() {
		return {
			inactive: true,
			value: {
				country: { value: [], comparisonType: 'Equals' },
				state: { value: [], comparisonType: 'Equals' },
				municipality: { value: [], comparisonType: 'Equals' },
				town: { value: [], comparisonType: 'Equals' },
				zipcode: { value: [{ start: null, end: null, key: 0 }] },
				address: { value: [], comparisonType: 'Search' }
			}
		};
	}

	function isInactive(filter) {
		return (
			!filter.value.state.value.length &&
			!filter.value.municipality.value.length &&
			!filter.value.town.value.length &&
			!filter.value.address.value.length &&
			!filter.value.zipcode.value.some(value => isActiveValue(value.start) || isActiveValue(value.end))
		);
	}

	function toUrl(filter) {
		const f = { v: {} };
		const val = filter.value;

		f.i = filter.inactive;

		if (val.country.value.length) {
			f.v.co = { v: val.country.value, c: val.country.comparisonType };
		}

		if (val.state.value.length) {
			f.v.st = { v: val.state.value, c: val.state.comparisonType };
		}

		if (val.town.value.length) {
			f.v.to = { v: val.town.value, c: val.town.comparisonType };
		}

		if (val.municipality.value.length) {
			f.v.mu = { v: val.municipality.value, c: val.municipality.comparisonType };
		}

		if (val.address.value.length) {
			f.v.ad = { v: val.address.value, c: val.address.comparisonType };
		}

		const hasZipCodeValue = val.zipcode.value.some(value => value.start || value.end);

		if (hasZipCodeValue) {
			f.v.zi = { v: val.zipcode.value };
		}

		return f;
	}

	function fromUrl(rawFilter) {
		const filter = generate();
		const val = rawFilter.v;

		if (val.co) {
			filter.value.country = { value: val.co.v, comparisonType: val.co.c };
		}

		if (val.st) {
			filter.value.state = { value: val.st.v, comparisonType: val.st.c };
		}

		if (val.mu) {
			filter.value.municipality = { value: val.mu.v, comparisonType: val.mu.c };
		}

		if (val.to) {
			filter.value.town = { value: val.to.v, comparisonType: val.to.c };
		}

		if (val.ad) {
			filter.value.address = { value: val.ad.v, comparisonType: val.ad.c };
		}

		if (val.zi) {
			filter.value.zipcode = { value: val.zi.v };
		}

		filter.inactive = rawFilter.hasOwnProperty('i') ? rawFilter.i : isInactive(filter);

		return filter;
	}

	function addZipCodeFilter(rb, value) {
		if (isActiveValue(value.start)) {
			rb.addFilter({ field: 'postZipcode' }, rb.comparisonTypes.GreaterThanEquals, value.start);
		}
		if (isActiveValue(value.end)) {
			rb.addFilter({ field: 'postZipcode' }, rb.comparisonTypes.LessThan, value.end);
		}
	}

	function build(filter, rb) {
		const value = filter.value;

		if (filter.inactive || !value) {
			return;
		}

		const activeZipCodeValues = filter.value.zipcode.value.filter(
			value => isActiveValue(value.start) || isActiveValue(value.end)
		);

		if (activeZipCodeValues.length) {
			if (activeZipCodeValues.length === 1) {
				addZipCodeFilter(rb, activeZipCodeValues[0]);
			} else {
				const orBuilder = rb.orBuilder();

				for (const value of activeZipCodeValues) {
					orBuilder.next();
					addZipCodeFilter(orBuilder, value);
				}

				orBuilder.done();
			}
		}

		if (filter.value.state.value.length) {
			rb.addFilter(
				{ field: 'postRegionName' },
				rb.comparisonTypes[filter.value.state.comparisonType],
				filter.value.state.value
			);
		}

		if (filter.value.town.value.length) {
			rb.addFilter(
				{ field: 'postTown' },
				rb.comparisonTypes[filter.value.town.comparisonType],
				filter.value.town.value
			);
		}

		if (filter.value.municipality.value.length) {
			rb.addFilter(
				{ field: 'postCommunityName' },
				rb.comparisonTypes[filter.value.municipality.comparisonType],
				filter.value.municipality.value
			);
		}

		if (filter.value.address.value.length) {
			rb.addFilter(
				{ field: 'postStreet' },
				rb.comparisonTypes[filter.value.address.comparisonType],
				filter.value.address.value
			);
		}
	}

	const filter = Object.assign(
		{
			filterName: 'Location',
			title: 'default.location',
			entity: 'account',
			type: 'raw',
			displayType: 'address',
			showOnSegment: true,
			generate,
			isInactive,
			toUrl,
			fromUrl,
			build
		},
		overrides
	);

	const searchFunctions = getSearchFunctions(filter.filterName);

	filter.dataPromise = function () {
		const promises = {
			State: searchFunctions.stateSerchFn()('', null, 0, 50),
			Town: searchFunctions.townSerchFn()('', null, 0, 50)
		};

		if (filter.filterName === 'Location') {
			promises.Municipality = searchFunctions.municipalitySerchFn()('', null, 0, 50);
		}

		return promises;
	};

	return filter;
}

export default generateFilter;
