import React, { useState, useEffect, useRef } from 'react';
import ReportcenterFilter from './ReportcenterFilter';
import { Block } from '@upsales/components';
import bemClass from '@upsales/components/Utils/bemClass';
import T from 'Components/Helpers/translate';
import ReportcenterFilterRow from './ReportcenterFilterRow';
import { removeItem } from 'Store/helpers/array';
import { RCDashboardFilter } from 'Resources/ReportDashboard';
import EasyBookingSettingsResource from 'Resources/EasyBookingSettings';
import logError from 'Helpers/logError';
import { makeCancelable, CancelablePromise } from 'App/babel/helpers/promise';
import './ReportcenterFilterEasyBooking.scss';
import ReportcenterFilterSearchbar from './ReportcenterFilterSearchbar';

type EasyBookingSettings = {
	id: number;
	title: string;
	internalName: string;
};

interface Props {
	onChange: (type: keyof RCDashboardFilter, values: number[], comparison: string) => void;
	values: RCDashboardFilter;
	updateFilterHeight: () => void;
	families: string[];
}

export const renderSelected = (values: RCDashboardFilter) => {
	let res = `${T('default.easyBooking')}: `;

	if (values?.EasyBooking?.value?.length) {
		const length = values.EasyBooking.value.length;
		if (length >= 1) {
			res += `${length} ${(values.EasyBooking.comparison === 'eq'
				? T('default.selected')
				: T('default.excluded', { count: length })
			).toLowerCase()}`;
		}
	}

	return res;
};

const ReportcenterFilterEasyBooking = ({ onChange, families, ...props }: Props) => {
	const classes = new bemClass('ReportcenterFilterEasyBooking');
	const [searchStr, setSearchStr] = useState('');
	const [easyBookings, setEasyBookings] = useState<EasyBookingSettings[]>([]);

	const timeoutRef = useRef<number>();
	const dataPromise = useRef<CancelablePromise>();
	useEffect(() => {
		clearTimeout(timeoutRef.current);
		timeoutRef.current = window.setTimeout(() => {
			dataPromise.current?.cancel();
			dataPromise.current = makeCancelable(
				EasyBookingSettingsResource.find({ internalName: `src:${searchStr}`, sort: 'internalName' })
			);
			dataPromise.current.promise
				.then(res => setEasyBookings(res.data))
				.catch(err => logError(err, 'Could not find easy bookings'));
		}, 300);
		return () => {
			clearTimeout(timeoutRef.current);
			dataPromise.current?.cancel();
		};
	}, [searchStr]);

	const filteredEasyBookings = easyBookings.filter(
		item => item.internalName.toLowerCase().indexOf(searchStr.toLowerCase()) !== -1
	);
	const value = props.values?.EasyBooking?.value || [];
	const comparison = props.values?.EasyBooking?.comparison ?? 'eq';
	const isExclude = comparison === 'ne';

	return (
		<ReportcenterFilter
			className={classes.b()}
			renderSelected={() => renderSelected(props.values)}
			getSelectedNames={() => easyBookings.filter(s => value.includes(s.id)).map(s => s.internalName)}
			icon="calendar"
			placeholder={`${T('default.easyBooking')}: ${T('reportcenter.filter.notActive')}`}
			value={value}
			resetFilter={() => onChange('EasyBooking', [], 'eq')}
			{...props}
		>
			<Block space="ptm prm pbm plm" backgroundColor="white" className={classes.elem('list-select').b()}>
				<Block space="mtm">
					<ReportcenterFilterSearchbar<number[]>
						setSearchStr={value => setSearchStr(value)}
						searchStr={searchStr}
						placeholder={`${T('default.search')} ${T('default.easyBooking').toLowerCase()}`}
						isExclude={isExclude}
						onChange={onChange}
						field="EasyBooking"
						value={value}
					/>
				</Block>
			</Block>
			<ReportcenterFilterRow
				key={0}
				onClick={() => {
					onChange('EasyBooking', [], 'eq');
				}}
				selected={!value.length}
				title={T('filters.noFilter')}
			/>
			{filteredEasyBookings.map(row => (
				<ReportcenterFilterRow
					key={row.id}
					onClick={() => {
						const i = value.indexOf(row.id);
						onChange('EasyBooking', i !== -1 ? removeItem(value, i) : [...value, row.id], comparison);
					}}
					selected={value.indexOf(row.id) !== -1}
					title={row.internalName}
				/>
			))}
		</ReportcenterFilter>
	);
};

export default ReportcenterFilterEasyBooking;
