import AccountProfile from 'App/resources/Model/AccountProfile';

const getLanguage = () => {
	switch (Tools.AppService.getSelf().language) {
		case 'sv-SE':
			return 'sv';
		case 'no-NO':
		case 'nb-NO':
			return 'no';
		case 'de-DE':
			return 'de';
		case 'fi-FI':
			return 'fi';
		case 'da-DK':
			return 'da';
		case 'nl-NL':
			return 'nl';
		case 'fr-FR':
			return 'fr';
		default:
			return 'en';
	}
};

const fontSizes = () => {
	const fontSizes = [8, 9, 10, 11];
	for (let i = 12; i <= 72; i += 2) {
		fontSizes.push(i);
	}
	return fontSizes.map(fontSize => `${fontSize}/${fontSize}px`).join(';');
};

type Config = {
	forcePasteAsPlainText: boolean;
	pasteFromWordRemoveFontStyles: boolean;
	pasteFromWordRemoveStyles: boolean;
	extraAllowedContent: string;
	pasteFilter: unknown;
	language: 'sv' | 'no' | 'de' | 'fi' | 'da' | 'nl' | 'fr' | 'en';
	uiColor: '#ffffff';
	height: '250px';
	// eslint-disable-next-line camelcase
	resize_enabled: boolean;
	allowedContent: boolean;
	removePlugins: string;
	entities: boolean;
	enterMode: number;
	shiftEnterMode: number;
	extraPlugins: string;
	// eslint-disable-next-line camelcase
	fontSize_sizes: string;
	toolbar: { name: string; items: string[] }[];
	// eslint-disable-next-line camelcase
	colorButton_colors?: string;
	// eslint-disable-next-line camelcase
	colorButton_enableAutomatic?: boolean;
};

// maybe later the plugins can be passed to the component and added here
// This function can't be called before user is logged in
export default (extraConfig = {}, accountProfile?: AccountProfile) => {
	let colors;
	if (accountProfile) {
		const colorsArr = Object.values(accountProfile.colors).map(color => color.substring(1));
		const extraColors = Object.values(accountProfile.extraColors).map(color => color.value.substring(1));
		colors = [...Array.from(new Set(colorsArr.concat(extraColors)))].join(',');
	}

	const config = {
		// Copying and pasting into CKEditor from MS Word
		// MM-1354 fix CKEDITOR.cleanWord is not a function
		forcePasteAsPlainText: false,
		pasteFromWordRemoveFontStyles: false,
		pasteFromWordRemoveStyles: false,
		extraAllowedContent: 'p(mso*,Normal)',
		pasteFilter: null,
		language: getLanguage(),
		uiColor: '#ffffff',
		height: '250px',
		resize_enabled: false,
		allowedContent: true,
		removePlugins: 'elementspath',
		entities: false,
		enterMode: window.CKEDITOR.ENTER_P,
		shiftEnterMode: window.CKEDITOR.ENTER_BR,
		extraPlugins: '',
		fontSize_sizes: fontSizes(),
		toolbar: [
			{
				name: 'basicstyles',
				items: ['Bold', 'Italic', 'Underline', '-', 'RemoveFormat']
			},
			{ name: 'colors', items: ['TextColor'] },
			{ name: 'styles', items: ['Format', 'FontSize'] },
			{ name: 'fonts', items: ['Font'] },
			{ name: 'paragraph', items: ['NumberedList', 'BulletedList'] },
			{ name: 'links', items: ['Link', 'Unlink'] }
		],
		...extraConfig
	} as Config;

	if (colors) {
		config.colorButton_colors = colors;
		config.colorButton_enableAutomatic = false;
	}

	const addPluginToToolbar = (pluginName: string, itemName: string) => {
		if (config.extraPlugins.indexOf(pluginName) !== -1 && !config.toolbar.find(i => i.name === pluginName)) {
			config.toolbar.push({ name: pluginName, items: [itemName] });
		}
	};

	addPluginToToolbar('images', 'InsertImage');
	addPluginToToolbar('tags', 'InsertTag');
	addPluginToToolbar('signature', 'InsertSignature');

	return config;
};
