import { Component, FunctionComponent, ComponentProps } from 'react';
import EditPhonecall from 'Components/EditPhonecall';
import EditTodo from 'Components/EditTodo';
import EditCallListDrawer from 'App/components/drawers/EditCallListDrawer';
import CreateTodo from 'Components/CreateTodo';
import CreateCall from 'Components/CreateCall';
import PlanPhonecallsAction from 'Components/PlanPhonecallsAction';
import PlanPhonecallsMulti from 'Components/PlanPhonecallsMulti';
import UtmLinkGenerator from 'Components/UtmLinkGenerator';
import EditSocialEventAddContacts from 'Components/SocialEvent/EditSocialEventAddContacts';
import GoalSummaryDrawer from 'Components/GoalSummaryDrawer';
import NotificationDropdown from 'Components/NotificationDropdown';
import PlanPhonecallsTriggerAction from 'Components/PlanPhonecallsTriggerAction';
import CreateTodoTriggerAction from 'Components/CreateTodoTriggerAction';
import ReportcenterDrilldownDrawer from 'App/pages/Reportcenter/ReportcenterDrilldownDrawer';
import SubscriptionListDrawer from 'Components/Admin/SubscriptionIndexing/SubscriptionListDrawer/SubscriptionListDrawer';
import AccountListDrawer from 'Components/Admin/SubscriptionIndexing/AccountListDrawer/AccountListDrawer';
import SubscriptionIndexing from 'Components/Admin/SubscriptionIndexing/SubscriptionIndexingModal';
import SubscriptionConflictModal from 'Components/Admin/SubscriptionIndexing/SubscriptionConflictModal';

import TargetCalculator from 'Components/TargetCalculator';
import CompetitorDrawer from 'App/pages/Admin/CompetitorDrawer';
import SessionExpireWarning from 'Components/Modals/SessionExpireWarning';
import EditClient from 'App/components/EditClient';
import EditDynamicLink from 'App/components/EditDynamicLink';
import AccountDeactivationModal from 'App/babel/components/Account/AccountDeactivationModal';
import SentMail from 'Components/MailDrawers/SentMail';
import OpenAccountPlan from 'App/components/EditAccountPlan/OpenAccountPlan';
import NewSingleMail from 'Components/MailDrawers/NewSingleMail';
import AddLabelsMulti from 'Components/Modals/AddLabelsMulti';
import AcceptTerms from 'App/components/AcceptTerms';
import SendWebhook from 'App/components/SendWebhook';
import RequestSupport from 'App/components/RequestSupport';
import CreateFlow from 'App/components/CreateFlow';
import CreateTemplateFlow from 'App/components/CreateTemplateFlow';
import SubscriptionPreview from 'App/components/SubscriptionPreview';
import { CreateSubscription, EditSubscription } from 'App/components/EditSubscription';
import MoveSubscription from 'App/components/MoveSubscription';
import SubscriptionPromoModal from 'App/components/EditSubscription/SubscriptionPromo';
import FlowLoopSettings from 'App/components/FlowLoopSettings';
import DeleteForbiddenAlert from 'App/components/DeleteForbiddenAlert';
import LeadDrawer from 'Components/LeadDrawer';
import VisitDrawer from 'Components/VisitDrawer';
import ForecastingDrawer from 'App/components/Forecasting';
import AllowAccess from 'App/components/AllowAccess';
import CleanMyData from 'App/components/CleanMyData';
import HandleDuplicates from 'App/components/HandleDuplicates';
import MailPreview from 'App/components/MailPreview';
import WonOpportunityWithSubs from 'App/components/WonOpportunityWithSubs';
import PriceListDrawer from 'App/components/PriceListDrawer';
import ProductBundleDrawer from 'App/components/ProductBundleDrawer';
import CreateAccount from 'App/pages/CreateAccount';
import EditProductBundleOrder from 'App/components/EditProductBundleOrder';
import EditClientTarget from 'App/components/EditClientTarget';
import ARRChangeDrawerV2 from 'App/components/ARRChangeDrawerV2';
import CreateTodosAction from 'App/components/CreateTodosAction';
import ProductSearch from 'Components/ProductSearch';
import ReportcenterTV from 'App/pages/Reportcenter/ReportcenterTV';
import FindSocialEventModal from 'App/components/FindSocialEventModal';
import FindClientModal from 'App/components/FindClientModal';
import FindFormModal from 'App/components/FindFormModal';
import FindContactModal from 'App/components/FindContactModal';
import ReportcenterTVSettings from 'App/pages/Reportcenter/ReportcenterTV/ReportcenterTVSettings';
import UserSurvey from 'App/components/UserSurvey';
import EditProductCategory from 'App/components/EditProductCategory';
import CopySocialEvent from 'Components/SocialEvent/CopySocialEvent';
import Alert, { UnsavedChangesAlert, RemoveAlert } from 'App/components/Alerts';
import EditMailSignatureRoot from 'Components/MailSignature/EditMailSignatureModal';
import CreateUpsalesAccount from 'Components/CreateAccount';
import RefreshAccount from 'Components/Modals/RefreshAccount/RefreshAccount';
import EditSocialEventAddResource from 'App/babel/components/SocialEvent/EditSocialEventAddResource';
import FullTermsModal from 'App/babel/components/Modals/FullTermsModal';
import AnonFieldPage from 'App/babel/components/anonymization/fieldPage/fieldPage';
import MultiUpdateActivity from 'App/components/MultiActionModal/MultiUpdateActivity/MultiUpdateActivity';
import MultiUpdateClient from 'App/components/MultiActionModal/MultiUpdateClient/MultiUpdateClient';
import MultiCloseActivity from 'App/components/MultiActionModal/MultiCloseActivity/MultiCloseActivity';
import AskForInput from 'App/components/Alerts/AskForInput/AskForInput';
import MatchCompaniesBeforeMerge from 'App/components/MatchCompaniesBeforeMerge';
import DuplicateIntroModal from 'App/components/DuplicateIntroModal';
import InactivateContacts from 'App/components/CleanMyData/InactivateContacts/InactivateContacts';
import EditTicketResponseTemplate from 'Components/EditTicketResponseTemplate';
import RiskDrawer from 'App/components/RiskDrawer';
import EditOrder from 'App/components/EditOrder/EditOrder';
import OpenTicket from 'App/components/EditTicket';
import EditProjectPlanTemplate from 'Components/Admin/ProjectPlanTemplate/Modals/EditProjectPlanTemplate/EditProjectPlanTemplate';
import EditTaskTemplate from 'Components/Admin/ProjectPlanTemplate/Modals/EditTaskTemplate';
import EditProjectPlan from 'Components/EditProjectPlan';
import ViewTickets from 'App/components/ViewTickets';
import SubaccountDrawer from 'Components/SubaccountDrawer/SubaccountDrawer';
import AgreementFulfillmentModal from 'App/components/AgreementFulfillmentModal';
import LostOpportunityModal from 'App/components/LostOpportunity/LostOpportunityModal';
import ClientListModal from 'App/components/ClientListModal/ClientListModal';

export enum Animation {
	Fade = 'fade',
	SlideBottom = 'slide-bottom',
	DrawerRight = 'drawer-right',
	None = 'none'
}

export type ModalOpts = {
	closeOnCurtain?: boolean;
	animation?: Animation;
	drawer?: boolean;
	closeOnEscape?: boolean;
};

export type ModalConfigName =
	| 'LeadDrawer'
	| 'VisitDrawer'
	| 'ForecastingDrawer'
	| 'EditPhonecall'
	| 'EditCallList'
	| 'MultiUpdateActivity'
	| 'MultiUpdateClient'
	| 'MultiCloseActivity'
	| 'EditTodo'
	| 'CreateTodo'
	| 'CreateCall'
	| 'CreateTodosAction'
	| 'PlanPhonecallsAction'
	| 'PlanPhonecallsMulti'
	| 'UtmLinkGenerator'
	| 'AddSocialEventContacts'
	| 'GoalSummaryDrawer'
	| 'NotificationDropdown'
	| 'PlanPhonecallsTriggerAction'
	| 'CreateTodoTriggerAction'
	| 'ReportcenterDrilldownDrawer'
	| 'SubscriptionListDrawer'
	| 'AccountListDrawer'
	| 'TargetCalculator'
	| 'CompetitorDrawer'
	| 'SessionExpireWarning'
	| 'EditClient'
	| 'AccountDeactivationModal'
	| 'SentMail'
	| 'EditAccountPlan'
	| 'NewSingleMail'
	| 'AddLabelsMulti'
	| 'AcceptTerms'
	| 'RequestSupport'
	| 'SendWebhook'
	| 'CreateFlow'
	| 'CreateTemplateFlow'
	| 'EditDynamicLink'
	| 'SubscriptionPreview'
	| 'CreateSubscription'
	| 'EditSubscription'
	| 'MoveSubscription'
	| 'SubscriptionPromoModal'
	| 'SubscriptionIndexing'
	| 'SubscriptionConflictModal'
	| 'FlowLoopSettings'
	| 'WonOpportunityWithSubs'
	| 'DeleteForbiddenAlert'
	| 'AllowAccess'
	| 'CleanMyData'
	| 'HandleDuplicates'
	| 'MailPreview'
	| 'PriceListDrawer'
	| 'ProductBundleDrawer'
	| 'CreateAccount'
	| 'EditProductBundleOrder'
	| 'EditClientTarget'
	| 'ARRChangeDrawerV2'
	| 'ProductSearch'
	| 'EditTicket'
	| 'CopySocialEvent'
	| 'ReportcenterTV'
	| 'FindSocialEvent'
	| 'FindClient'
	| 'FindForm'
	| 'FindContact'
	| 'ReportcenterTVSettings'
	| 'UserSurvey'
	| 'EditProductCategory'
	| 'Alert'
	| 'FullTerms'
	| 'UnsavedChangesAlert'
	| 'RemoveAlert'
	| 'AskForInput'
	| 'MatchCompaniesBeforeMerge'
	| 'DuplicateIntroModal'
	| 'InactivateContacts'
	| 'EditMailSignature'
	| 'CreateUpsalesAccount'
	| 'RefreshAccount'
	| 'EditSocialEventAddResource'
	| 'RiskDrawer'
	| 'AnonFieldPage'
	| 'EditOrder'
	| 'ViewTickets'
	| 'EditTicketResponseTemplate'
	| 'EditProjectPlanTemplate'
	| 'EditTaskTemplate'
	| 'EditProjectPlan'
	| 'SubaccountDrawer'
	| 'AgreementFulfillmentModal'
	| 'LostOpportunityModal'
	| 'ClientListModal';

export type ModalConfig = {
	component: FunctionComponent<any> | typeof Component;
	opts?: ModalOpts;
	props?: { [key: string]: any; onClose?: (data?: any) => void };
};

const configs = {
	LeadDrawer: {
		component: LeadDrawer,
		opts: { drawer: true, animation: Animation.DrawerRight, closeOnCurtain: true }
	},
	VisitDrawer: {
		component: VisitDrawer,
		opts: { drawer: true, animation: Animation.DrawerRight, closeOnCurtain: true }
	},
	ForecastingDrawer: {
		component: ForecastingDrawer,
		opts: { drawer: true, animation: Animation.DrawerRight, closeOnCurtain: true }
	},
	EditPhonecall: {
		component: EditPhonecall,
		opts: { drawer: true, animation: Animation.DrawerRight, closeOnCurtain: true }
	},
	EditCallList: {
		component: EditCallListDrawer,
		opts: { drawer: true, animation: Animation.DrawerRight, closeOnCurtain: true }
	},
	MultiUpdateActivity: {
		component: MultiUpdateActivity,
		opts: { drawer: false, animation: Animation.SlideBottom, closeOnCurtain: true }
	},
	MultiUpdateClient: {
		component: MultiUpdateClient,
		opts: { drawer: false, animation: Animation.SlideBottom, closeOnCurtain: true }
	},
	MultiCloseActivity: {
		component: MultiCloseActivity,
		opts: { drawer: false, animation: Animation.SlideBottom, closeOnCurtain: true }
	},
	EditTodo: { component: EditTodo, opts: { drawer: true, animation: Animation.DrawerRight, closeOnCurtain: true } },
	CreateTodo: {
		component: CreateTodo,
		opts: { drawer: true, animation: Animation.DrawerRight, closeOnCurtain: true }
	},
	CreateCall: {
		component: CreateCall,
		opts: { drawer: true, animation: Animation.DrawerRight, closeOnCurtain: true }
	},
	CreateTodosAction: {
		component: CreateTodosAction,
		opts: { drawer: true, animation: Animation.DrawerRight, closeOnCurtain: true }
	},
	PlanPhonecallsAction: {
		component: PlanPhonecallsAction,
		opts: { drawer: true, animation: Animation.DrawerRight, closeOnCurtain: true }
	},
	PlanPhonecallsMulti: {
		component: PlanPhonecallsMulti,
		opts: { drawer: true, animation: Animation.DrawerRight, closeOnCurtain: true }
	},
	UtmLinkGenerator: {
		component: UtmLinkGenerator,
		opts: { drawer: true, animation: Animation.DrawerRight, closeOnCurtain: true }
	},
	AddSocialEventContacts: {
		component: EditSocialEventAddContacts,
		opts: { animation: Animation.Fade },
		props: { modal: true }
	},
	GoalSummaryDrawer: {
		component: GoalSummaryDrawer,
		opts: { drawer: true, animation: Animation.DrawerRight, closeOnCurtain: true }
	},
	NotificationDropdown: {
		component: NotificationDropdown,
		opts: { drawer: true, animation: Animation.DrawerRight, closeOnCurtain: true }
	},
	PlanPhonecallsTriggerAction: {
		component: PlanPhonecallsTriggerAction,
		opts: { drawer: true, animation: Animation.DrawerRight, closeOnCurtain: true }
	},
	CreateTodoTriggerAction: {
		component: CreateTodoTriggerAction,
		opts: { drawer: true, animation: Animation.DrawerRight, closeOnCurtain: true }
	},
	ReportcenterDrilldownDrawer: {
		component: ReportcenterDrilldownDrawer,
		opts: { drawer: true, animation: Animation.DrawerRight, closeOnCurtain: true }
	},
	SubscriptionListDrawer: {
		component: SubscriptionListDrawer,
		opts: { drawer: true, animation: Animation.DrawerRight, closeOnCurtain: true }
	},
	AccountListDrawer: {
		component: AccountListDrawer,
		opts: { drawer: true, animation: Animation.DrawerRight, closeOnCurtain: true }
	},
	TargetCalculator: { component: TargetCalculator, opts: { drawer: true, animation: Animation.DrawerRight } },
	CompetitorDrawer: {
		component: CompetitorDrawer,
		opts: { drawer: true, animation: Animation.DrawerRight, closeOnCurtain: true }
	},
	SessionExpireWarning: {
		component: SessionExpireWarning,
		opts: {
			animation: Animation.Fade
		}
	},
	SubscriptionIndexing: {
		component: SubscriptionIndexing,
		opts: {
			animation: Animation.SlideBottom
		}
	},
	SubscriptionConflictModal: {
		component: SubscriptionConflictModal,
		opts: {
			animation: Animation.SlideBottom
		}
	},
	EditClient: {
		component: EditClient,
		opts: {
			animation: Animation.SlideBottom
		}
	},
	AccountDeactivationModal: {
		component: AccountDeactivationModal,
		opts: {
			animation: Animation.Fade
		}
	},
	SentMail: {
		component: SentMail,
		opts: { drawer: true, animation: Animation.DrawerRight, closeOnCurtain: false }
	},
	EditAccountPlan: {
		component: OpenAccountPlan,
		opts: { drawer: true, animation: Animation.DrawerRight }
	},
	NewSingleMail: {
		component: NewSingleMail,
		opts: { drawer: true, animation: Animation.DrawerRight }
	},
	AddLabelsMulti: {
		component: AddLabelsMulti,
		opts: { animation: Animation.Fade }
	},
	AcceptTerms: {
		component: AcceptTerms,
		opts: { animation: Animation.SlideBottom, closeOnCurtain: false }
	},
	RequestSupport: {
		component: RequestSupport,
		opts: { animation: Animation.SlideBottom, closeOnCurtain: false }
	},
	SendWebhook: {
		component: SendWebhook,
		opts: {
			animation: Animation.SlideBottom
		}
	},
	CreateFlow: {
		component: CreateFlow,
		opts: {
			animation: Animation.SlideBottom
		}
	},
	CreateTemplateFlow: {
		component: CreateTemplateFlow,
		opts: {
			animation: Animation.Fade
		}
	},
	EditDynamicLink: {
		component: EditDynamicLink,
		opts: {
			closeOnCurtain: true,
			animation: Animation.SlideBottom
		}
	},
	SubscriptionPreview: {
		component: SubscriptionPreview,
		opts: {
			animation: Animation.SlideBottom,
			closeOnCurtain: true
		}
	},
	CreateSubscription: {
		component: CreateSubscription,
		opts: {
			animation: Animation.Fade
		}
	},
	EditSubscription: {
		component: EditSubscription,
		opts: {
			animation: Animation.Fade
		}
	},
	MoveSubscription: {
		component: MoveSubscription,
		opts: {
			animation: Animation.SlideBottom
		}
	},
	SubscriptionPromoModal: {
		component: SubscriptionPromoModal,
		opts: {
			animation: Animation.Fade
		}
	},
	FlowLoopSettings: {
		component: FlowLoopSettings,
		opts: { drawer: true, animation: Animation.DrawerRight, closeOnCurtain: true }
	},
	WonOpportunityWithSubs: {
		component: WonOpportunityWithSubs,
		opts: {
			animation: Animation.Fade
		}
	},
	DeleteForbiddenAlert: {
		component: DeleteForbiddenAlert,
		opts: {
			animation: Animation.Fade
		}
	},
	UserSurvey: {
		component: UserSurvey,
		opts: {
			animation: Animation.Fade
		}
	},
	EditProductCategory: {
		component: EditProductCategory,
		opts: {
			animation: Animation.SlideBottom
		}
	},
	AllowAccess: {
		component: AllowAccess,
		opts: {
			animation: Animation.Fade
		}
	},
	CleanMyData: {
		component: CleanMyData,
		opts: {
			animation: Animation.SlideBottom
		}
	},
	HandleDuplicates: {
		component: HandleDuplicates,
		opts: {
			animation: Animation.SlideBottom
		}
	},
	MailPreview: {
		component: MailPreview,
		opts: {
			animation: Animation.Fade
		}
	},
	PriceListDrawer: {
		component: PriceListDrawer,
		opts: { drawer: true, animation: Animation.DrawerRight }
	},
	ProductBundleDrawer: {
		component: ProductBundleDrawer,
		opts: { drawer: true, animation: Animation.DrawerRight, closeOnCurtain: true }
	},
	CreateAccount: {
		component: CreateAccount,
		opts: {
			animation: Animation.SlideBottom
		}
	},
	EditProductBundleOrder: {
		component: EditProductBundleOrder
	},
	EditClientTarget: {
		component: EditClientTarget,
		opts: {
			drawer: true,
			closeOnCurtain: true,
			animation: Animation.DrawerRight
		}
	},
	ARRChangeDrawerV2: {
		component: ARRChangeDrawerV2,
		opts: {
			drawer: true,
			closeOnCurtain: true,
			animation: Animation.DrawerRight
		}
	},
	ProductSearch: {
		component: ProductSearch,
		opts: {}
	},
	EditTicket: {
		component: OpenTicket,
		opts: { drawer: true, animation: Animation.DrawerRight, closeOnCurtain: true }
	},
	CopySocialEvent: {
		component: CopySocialEvent,
		opts: {}
	},
	ReportcenterTV: {
		component: ReportcenterTV,
		opts: {}
	},
	FindSocialEvent: {
		component: FindSocialEventModal,
		opts: {}
	},
	FindClient: {
		component: FindClientModal,
		opts: {}
	},
	FindForm: {
		component: FindFormModal,
		opts: {}
	},
	FindContact: {
		component: FindContactModal,
		opts: {}
	},
	ReportcenterTVSettings: {
		component: ReportcenterTVSettings,
		opts: {
			drawer: true,
			animation: Animation.DrawerRight,
			closeOnCurtain: true
		}
	},
	Alert: {
		component: Alert,
		opts: {
			closeOnEscape: true
		}
	},
	UnsavedChangesAlert: {
		component: UnsavedChangesAlert,
		opts: {
			closeOnEscape: true
		}
	},
	RemoveAlert: {
		component: RemoveAlert,
		opts: {
			closeOnEscape: true
		}
	},
	AskForInput: {
		component: AskForInput,
		opts: {
			closeOnEscape: true
		}
	},
	MatchCompaniesBeforeMerge: {
		component: MatchCompaniesBeforeMerge,
		opts: {
			animation: Animation.SlideBottom
		}
	},
	DuplicateIntroModal: {
		component: DuplicateIntroModal,
		opts: {
			animation: Animation.SlideBottom
		}
	},
	InactivateContacts: {
		component: InactivateContacts,
		opts: {
			animation: Animation.SlideBottom
		}
	},
	EditMailSignature: {
		component: EditMailSignatureRoot,
		opts: {}
	},
	CreateUpsalesAccount: {
		component: CreateUpsalesAccount,
		opts: {}
	},
	RefreshAccount: {
		component: RefreshAccount,
		opts: {}
	},
	EditSocialEventAddResource: {
		component: EditSocialEventAddResource,
		opts: {}
	},
	FullTerms: {
		component: FullTermsModal,
		opts: {}
	},
	RiskDrawer: {
		component: RiskDrawer,
		opts: { drawer: true, animation: Animation.DrawerRight, closeOnCurtain: true }
	},
	AnonFieldPage: {
		component: AnonFieldPage,
		opts: {}
	},
	EditOrder: {
		component: EditOrder,
		opts: { closeOnCurtain: true }
	},
	ViewTickets: {
		component: ViewTickets,
		opts: { drawer: true, animation: Animation.DrawerRight, closeOnCurtain: true }
	},
	EditTicketResponseTemplate: {
		component: EditTicketResponseTemplate,
		opts: { animation: Animation.SlideBottom }
	},
	EditProjectPlanTemplate: {
		component: EditProjectPlanTemplate,
		opts: {
			animation: Animation.SlideBottom
		}
	},
	EditTaskTemplate: {
		component: EditTaskTemplate,
		opts: {
			animation: Animation.SlideBottom
		}
	},
	EditProjectPlan: {
		component: EditProjectPlan,
		opts: { drawer: true, closeOnCurtain: false, animation: Animation.DrawerRight }
	},
	SubaccountDrawer: {
		component: SubaccountDrawer,
		opts: { drawer: true, closeOnCurtain: true, animation: Animation.DrawerRight }
	},
	AgreementFulfillmentModal: {
		component: AgreementFulfillmentModal,
		opts: {}
	},
	LostOpportunityModal: {
		component: LostOpportunityModal,
		opts: {}
	},
	ClientListModal: {
		component: ClientListModal,
		opts: {
			closeOnCurtain: true
		}
	}
} satisfies Record<ModalConfigName, ModalConfig>;

export type ModalConfigByName<Conf extends ModalConfigName> = (typeof configs)[Conf];
export type ModalComponentProps<Conf extends ModalConfigName> = Collect<
	ComponentProps<ModalConfigByName<Conf>['component']>
>;

export default configs;
