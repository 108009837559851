import Resource from './Resource';

class OnboardingImport extends Resource {
	constructor() {
		super('onboardingimports');
	}
}

const resource = new OnboardingImport();
window.Tools.OnboardingImport = resource;
export default resource;
