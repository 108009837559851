import Resource from 'Resources/Resource';
import EngageAccount from './Model/EngageAccount';

class Engage extends Resource {
	constructor() {
		super('engage');
	}

	getAccount(): Promise<{ data: EngageAccount }> {
		return this._getRequest('account', { methodName: 'getAccount' })
			.then(r => r.data)
			.catch(err => {
				if (err.response?.status === 404) {
					return { data: null };
				}
				throw err;
			});
	}
}

const resource = new Engage();

export default resource;
