import { Checkbox, Icon, Text } from '@upsales/components';
import BemClass from '@upsales/components/Utils/bemClass';
import { PrimaryButton } from '@upsales/components/Buttons';
import { AgreementWithValueChange } from '../CardParent';
import T from 'Components/Helpers/translate';
import React, { useState } from 'react';
import moment from 'moment';

import './UpdateSplitSubs.scss';

type Props = {
	updateSubscription: (updateUpcoming: Set<number>) => void;
	upcomingStartDate?: Date;
	upcomingParents: AgreementWithValueChange[];
	close: () => void;
};

const UpdateSplitSubs = ({ close, updateSubscription, upcomingStartDate, upcomingParents }: Props) => {
	const classes = new BemClass('UpdateSplitSubs');

	const [checked, setChecked] = useState(new Set<number>(upcomingParents.map(parent => parent.id)));

	const flipId = (id: number) => {
		setChecked(pre => {
			const updated = new Set<number>([...pre]);
			if (updated.has(id)) {
				updated.delete(id);
			} else {
				updated.add(id);
			}
			return updated;
		});
	};

	const secondDate = moment(upcomingStartDate).format('L');
	const firstDate = `${moment().format('L')} - ${secondDate}`;

	return (
		<div className={classes.b()}>
			<div className={classes.elem('left-container').b()}>
				<Icon onClick={close} className={classes.elem('back-button').b()} name="arrow-left" />
				<Text size="lg" bold>
					{T('subscription.card.containsUpcomingPeriod')}
				</Text>
				<div className={classes.elem('checkboxes').b()}>
					<div>
						<Checkbox size="sm" checked disabled />
						<div>
							<Text>{T('date.currentDay')}</Text>
							<Text size="sm" color="grey-11">
								{firstDate}
							</Text>
						</div>
					</div>
					{upcomingParents.map(parent => (
						<div key={parent.id} onClick={() => flipId(parent.id)}>
							<Checkbox size="sm" checked={checked.has(parent.id)} />
							<div>
								<Text>{T('agreement.renewalDate')}</Text>
								<Text size="sm" color="grey-11">
									{`${moment(parent.metadata.agreementStartdate).format('L')} - ${
										parent.metadata.agreementEnddate
											? moment(parent.metadata.agreementEnddate).format('L')
											: ''
									}`}
								</Text>
							</div>
						</div>
					))}
				</div>
				<PrimaryButton onClick={() => updateSubscription(checked)}>
					{`${checked ? T('default.yes') : T('default.no')}, ${T(
						'subscription.card.updateSubscription'
					).toLowerCase()}`}
				</PrimaryButton>
			</div>
		</div>
	);
};

export default UpdateSplitSubs;
