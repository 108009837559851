import Attribute from './Attribute';

const FileAttributes = {
	id: new Attribute({
		type: Attribute.types.Number,
		field: 'id'
	}),
	filename: new Attribute({
		title: 'default.filename',
		type: Attribute.types.String,
		field: 'filename'
	}),
	mimetype: new Attribute({
		title: 'default.mimetype',
		type: Attribute.types.String,
		field: 'mimetype'
	}),
	uploadDate: new Attribute({
		title: 'default.uploadDate',
		type: Attribute.types.DateTime,
		field: 'uploadDate'
	}),
	entity: new Attribute({
		title: 'default.entity',
		type: Attribute.types.String,
		field: 'entity'
	}),
	entityId: {
		title: 'default.entityId',
		type: Attribute.types.Number,
		field: 'entityId'
	},
	size: new Attribute({
		title: 'default.size',
		type: Attribute.types.Number,
		field: 'size'
	}),
	labels: new Attribute({
		type: Attribute.types.Array,
		field: 'labels',
		attr: {
			id: new Attribute({
				field: 'labels.id',
				type: Attribute.types.Number
			}),
			name: new Attribute({
				field: 'labels.name',
				type: Attribute.types.String
			})
		}
	}),
	user: new Attribute({
		title: 'default.user',
		type: Attribute.types.Object,
		field: 'user',
		attr: {
			id: new Attribute({
				field: 'user.id',
				type: Attribute.types.Number
			}),
			name: new Attribute({
				field: 'user.name',
				type: Attribute.types.String
			})
		}
	})
};

export default FileAttributes;
