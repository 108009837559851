import { reducer, getInitialState } from './SubscriptionIndexingState/SubscriptionIndexingReducer';
import { PersistedIndexIncreaseSettings } from 'App/resources/Model/IndexIncreaseSettings';
import SubscriptionIndexingSummary from './SubscriptionIndexingSummary';
import SubscriptionIndexingState from './SubscriptionIndexingState';
import { State } from './SubscriptionIndexingState/SubscriptionIndexingActions';
import { FullscreenModal, Flex } from '@upsales/components';
import { useTranslation } from 'Components/Helpers/translate';
import BemClass from '@upsales/components/Utils/bemClass';
import { ModalProps } from 'App/components/Modals/Modals';
import React, { useEffect, useReducer, useState } from 'react';
import { useDebouncePromise } from 'Components/Helpers/Debounce';
import EditorHeader from 'Components/EditorHeader';

import './SubscriptionConflictModal.scss';

type Props = ModalProps & {
	indexesWithConflicts: PersistedIndexIncreaseSettings[];
	close: (saved?: boolean) => void;
};

const ConflictManageState = ({
	indexIncreaseSetting,
	saving,
	close
}: {
	indexIncreaseSetting: PersistedIndexIncreaseSettings;
	saving: boolean;
	close: (saved?: boolean) => void;
}) => {
	const [state, dispatch] = useReducer(reducer, indexIncreaseSetting, getInitialState);
	const { id, filter, indexConflictDecisionsMap } = state;

	const { getSubscriptionCount, doSave } = SubscriptionIndexingState(dispatch);

	const fetchSubscriptionCount = useDebouncePromise(
		(filter: State['filter'], indexConflictDecisionsMap: State['indexConflictDecisionsMap']) =>
			getSubscriptionCount(filter, indexConflictDecisionsMap, id),
		[],
		200
	);

	useEffect(() => {
		fetchSubscriptionCount(indexIncreaseSetting.filter, indexConflictDecisionsMap);
	}, [JSON.stringify(filter), JSON.stringify(indexConflictDecisionsMap)]);

	useEffect(() => {
		const save = async () => {
			try {
				await doSave(state);
				close(true);
			} catch (e) {
				console.log('Error saving IndexIncreaseSetting', e);
			}
		};
		if (saving) {
			save();
		}
	}, [saving]);

	return <SubscriptionIndexingSummary state={state} dispatch={dispatch} hidePreview />;
};

const SubscriptionConflictModal = ({ className, close, indexesWithConflicts }: Props) => {
	const classNames = new BemClass('SubscriptionConflictModal', className);
	const [saving, setSaving] = useState(false);

	const { t } = useTranslation();

	return (
		<FullscreenModal className={classNames.b()}>
			<EditorHeader
				onConfirmTitle={t('default.save')}
				onConfirmSupertitle={''}
				disableSave={saving}
				loading={saving}
				// @ts-ignore because it always expects null
				onConfirm={() => setSaving(true)}
				onConfirmIcon={'check'}
				className={className}
				subtitle={t('subscription.indexing.conflict.subtitle')}
				onAbort={() => close()}
				title={t('subscriptions.manage')}
			>
				{/* make ts stop complaining */}
				<></>
			</EditorHeader>

			<Flex direction="column" className={classNames.elem('wrapper').b()}>
				<Flex direction="column" alignItems="center">
					<div className={classNames.elem('content').b()}>
						{indexesWithConflicts.map((indexIncrease, i) => {
							return (
								<ConflictManageState
									key={indexIncrease.id}
									indexIncreaseSetting={indexIncrease}
									saving={saving}
									close={close}
								/>
							);
						})}
					</div>
				</Flex>
			</Flex>
		</FullscreenModal>
	);
};

export default SubscriptionConflictModal;
