import BemClass from '@upsales/components/Utils/bemClass';
import React from 'react';
import { Flex, Loader } from '@upsales/components';

type Props = {
	className?: string;
};

const Loading = ({ className }: Props) => {
	const classes = new BemClass('SubaccountDrawer__Loading', className);

	return (
		<Flex alignItems="center" className={classes.b()} direction="column" justifyContent="space-around">
			<Loader />
		</Flex>
	);
};

export default Loading;
