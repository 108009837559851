import { Title, Table, TableHeader, Text, TableRow, TableColumn, Icon } from '@upsales/components';
import { useSubscriptionGroupContext } from '../Context/SubscriptionGroupContext';
import { CurrencyFormat } from 'App/babel/utils/numberFormat';
import { SlideFade } from '@upsales/components/animations';
import BemClass from '@upsales/components/Utils/bemClass';
import React, { useState, useEffect } from 'react';
import Product from 'App/resources/Model/Product';
import T from 'Components/Helpers/translate';
import { useSelector } from 'react-redux';
import { RootState } from 'Store/index';
import moment from 'moment';

import './EditSummary.scss';
import { getCMWithRROption } from 'App/helpers/salesModelHelpers';

type OrderRowChanged = {
	price: { old?: number; new?: number };
	quantity: { old?: number; new?: number };
	discount: { old?: number; new?: number };
	product: { old?: Product; new?: Product };
	value: { old: number; new: number };
	currency: string;
};

const InfoBox = ({ title, subtitle }: { title: string; subtitle: string }) => {
	const classes = new BemClass('EditSummary');
	return (
		<div className={classes.elem('infoBox').b()}>
			<Text size="lg" bold>
				{title}
			</Text>
			<Text color="grey-11">{subtitle}</Text>
		</div>
	);
};

const genericColumn = (change: OrderRowChanged, type: 'quantity' | 'discount' | 'price', isCurrency?: boolean) => {
	const classes = new BemClass('EditSummary');
	const currencyFormat = new CurrencyFormat(change.currency);
	const oldValue = change[type].old;
	const newValue = change[type].new;

	const isIncrease = (newValue ?? 0) - (oldValue ?? 0) > 0;

	return (
		<TableColumn>
			{newValue === oldValue ? (
				<Text color="grey-11">{newValue}</Text>
			) : (
				<div className={classes.elem('strikethrough').b()}>
					<Icon name={isIncrease ? 'arrow-up' : 'arrow-down'} color={isIncrease ? 'success-4' : 'red'} />
					<Text className={classes.elem('strikethrough').elem('old-value').b()}>
						{isCurrency ? currencyFormat.short(oldValue ?? 0) : oldValue}
					</Text>
					<Icon name="arrow-right" />
					<Text>{isCurrency ? currencyFormat.short(newValue ?? 0) : newValue}</Text>
				</div>
			)}
		</TableColumn>
	);
};

const renderOrderRowChanged = (orderRowChanges: OrderRowChanged[]) => {
	const classes = new BemClass('EditSummary');

	return orderRowChanges.map((change, i) => {
		const currencyFormat = new CurrencyFormat(change.currency);
		const valueChange = change.value.new - change.value.old;

		return (
			<TableRow key={i}>
				<TableColumn>
					{change.product?.new?.id === change.product?.old?.id ? (
						<Text color="grey-11">{change.product.new?.name}</Text>
					) : (
						<div className={classes.elem('strikethrough').b()}>
							<Text className={classes.elem('strikethrough').elem('old-value').b()}>
								{change.product.old?.name}
							</Text>
							<Icon name="arrow-right" />
							<Text>{change.product.new?.name}</Text>
						</div>
					)}
				</TableColumn>
				{genericColumn(change, 'quantity')}
				{genericColumn(change, 'price', true)}
				{genericColumn(change, 'discount', true)}
				<TableColumn className={classes.elem('align-end').b()}>
					<Text color={valueChange > 0 ? 'success-4' : 'red'}>{`${
						valueChange > 0 ? '+' : ''
					}${currencyFormat.short(valueChange)}`}</Text>
				</TableColumn>
			</TableRow>
		);
	});
};

const EditSummary = () => {
	const {
		state: { subscriptionMap, diffOrders }
	} = useSubscriptionGroupContext();
	const classes = new BemClass('EditSummary');
	const { metadata } = useSelector((state: RootState) => state.App);
	const displayInMrr =
		(metadata?.params.SalesModel === 'rr' && metadata?.params.SalesModelOption === 'mrr') ||
		getCMWithRROption() === 'mrr';

	const [visible, setVisible] = useState(false);
	useEffect(() => {
		setVisible(true);
	}, []);

	const sortedSubscription = Object.values(subscriptionMap).sort((period1, period2) =>
		moment(period1.startDate) > moment(period2.startDate) ? 1 : -1
	);
	const lastSubscription = [...sortedSubscription].pop();
	const { endDate: previousEndDate } = lastSubscription?.untouchedValues || {};
	const { endDate: newEndDate, renewalDate: newRenewalDate } = lastSubscription || {};

	const delay = 600;
	let animationCount = 0;
	const diffOrdersToCreate = diffOrders.filter(d => d.show);

	const renderTables = () => {
		return Object.values(sortedSubscription).map((period, i) => {
			const orderRowChanges: OrderRowChanged[] = [];
			const { orderRows, untouchedValues, currency } = period;
			const currencyFormat = new CurrencyFormat(period.currency);

			for (const orderRow of orderRows) {
				const oldOrderRow = untouchedValues?.orderRows?.find(o => o.uuid === orderRow.uuid);
				if (
					oldOrderRow?.price !== orderRow.price ||
					oldOrderRow?.quantity !== orderRow.quantity ||
					oldOrderRow?.discount !== orderRow?.discount ||
					oldOrderRow?.product?.id !== orderRow.product?.id
				) {
					orderRowChanges.push({
						price: {
							old: oldOrderRow?.price,
							new: orderRow.price
						},
						quantity: {
							old: oldOrderRow?.quantity,
							new: orderRow.quantity
						},
						discount: {
							old: oldOrderRow?.discount,
							new: orderRow.discount
						},
						product: {
							old: oldOrderRow?.product,
							new: orderRow.product
						},
						value: {
							old: oldOrderRow?.orderRowTotal ?? 0,
							new: orderRow.orderRowTotal ?? 0
						},
						currency: currency
					});
				}
			}

			if (orderRowChanges.length === 0) {
				return null;
			}

			const currentRecuringValue = period.orderRows.reduce((sum, row) => (sum += row.recurringValue ?? 0), 0);
			const oldRecuringValue =
				period.untouchedValues?.orderRows?.reduce((sum, row) => (sum += row.recurringValue ?? 0), 0) ?? 0;
			const newRecuringValue = currentRecuringValue - oldRecuringValue;

			const futurePeriod = moment(period.startDate).isAfter();

			return (
				<SlideFade key={i} visible={visible} delayInMs={delay * ++animationCount} direction="top">
					<div className={classes.elem('firstSection').b()}>
						<div className={classes.elem('lollipop').b()}>
							<div className={classes.elem('lollipop').elem('head').b()} />
							<div className={classes.elem('lollipop').elem('line').b()} />
						</div>

						<div className={classes.elem('info').b()}>
							<div className={classes.elem('topSection').b()}>
								<div className={classes.elem('topSection').elem('section').elem('leftSide').b()}>
									<Text size="sm" color="grey-11">
										{T('subscription.modal.editSummary.period')}
									</Text>
									<Text>{`${moment(futurePeriod ? period.startDate : undefined).format('L')} - ${
										period.endDate ? moment(period.endDate).format('L') : ''
									}`}</Text>
								</div>
								<div className={classes.elem('topSection').elem('rightSide').b()}>
									<div className={classes.elem('topSection').elem('section').b()}>
										<Text size="sm" color="grey-11">
											{displayInMrr
												? T('subscription.modal.editSummary.MRRchange')
												: T('subscription.modal.editSummary.ARRchange')}
										</Text>
										<Text
											color={
												newRecuringValue === 0
													? undefined
													: newRecuringValue > 0
													? 'success-4'
													: 'red'
											}
										>{`${newRecuringValue > 0 ? '+' : ''}${currencyFormat.short(
											newRecuringValue
										)}`}</Text>
									</div>
									<div className={classes.elem('topSection').elem('verticalLine').b()}></div>
									<div className={classes.elem('topSection').elem('section').b()}>
										<Text size="sm" color="grey-11">
											{displayInMrr
												? T('subscription.modal.editSummary.newMRR')
												: T('subscription.modal.editSummary.newARR')}
										</Text>
										<Text>{currencyFormat.short(currentRecuringValue)}</Text>
									</div>
								</div>
							</div>
							<Table>
								<TableHeader
									columns={[
										{ title: T('default.product') },
										{ title: T('default.quantity') },
										{ title: T('default.price') },
										{ title: T('default.discount') },
										{ title: T('default.sum') }
									]}
								/>
								{renderOrderRowChanged(orderRowChanges)}
							</Table>
						</div>
					</div>
				</SlideFade>
			);
		});
	};

	return (
		<div className={classes.b()}>
			<div className={classes.elem('content').b()}>
				<Title>{T('subscription.modal.editSummary.theseChangesWillBeMade')}</Title>
				{renderTables()}

				{previousEndDate && !newEndDate ? (
					<SlideFade visible={visible} delayInMs={delay * ++animationCount} direction="top">
						<div>
							<Title>{T('subscription.modal.editSummary.scheduling')}</Title>
							<InfoBox
								title={moment(newRenewalDate).format('L')}
								subtitle={T('subscription.modal.editSummary.theSubscriptionWillBeRenewed')}
							/>
						</div>
					</SlideFade>
				) : null}

				{newEndDate && (!previousEndDate || !moment(previousEndDate).isSame(newEndDate)) ? (
					<SlideFade visible={visible} delayInMs={delay * ++animationCount} direction="top">
						<div>
							<Title>{T('subscription.modal.editSummary.scheduling')}</Title>
							<InfoBox
								title={moment(newEndDate).format('L')}
								subtitle={T('subscription.modal.editSummary.agreementEnds')}
							/>
						</div>
					</SlideFade>
				) : null}

				{diffOrdersToCreate.map((diffOrder, i) => {
					const currencyFormat = new CurrencyFormat(diffOrder.currency);

					return (
						<SlideFade
							key={`difforder${i}`}
							visible={visible}
							delayInMs={delay * ++animationCount}
							direction="top"
						>
							<div>
								<Title>{T('subscription.modal.editSummary.diffOrder')}</Title>
								<InfoBox
									title={`${moment(diffOrder.visualStartDate).format('L')} - ${moment(
										diffOrder.visualEndDate
									).format('L')}`}
									subtitle={T('subscription.modal.editSummary.includesAChangeOf', {
										value: currencyFormat.short(diffOrder.totalValue)
									})}
								/>
							</div>
						</SlideFade>
					);
				})}
			</div>
		</div>
	);
};

export default EditSummary;
