import { Button, OutsideClick, Text, Label, Input, Checkbox } from '@upsales/components';
import BemClass from '@upsales/components/Utils/bemClass';
import React, { MutableRefObject, useRef, useState } from 'react';
import './MailTemplateSaveDropdown.scss';
import { onTemplateSave } from '../../MailContextHelpers';
import { useMailContext } from '../../MailContext';
import T from 'Components/Helpers/translate';

interface Props {
	closeDD: MutableRefObject<(() => void) | undefined>;
	setClosed?: () => void;
}

const MailTemplateSaveDropdown = ({ closeDD, setClosed }: Props) => {
	const classes = new BemClass('MailTemplateSaveDropdown');
	const dropdownRef = useRef<HTMLDivElement>();
	const { state, dispatch } = useMailContext();

	const [isPrivate, setIsPrivate] = useState(false);
	const [templateName, setTemplateName] = useState('');
	const [templateSubject, setTemplateSubject] = useState(state.subject);

	const saveTemplateForm = (classes: BemClass) => {
		return (
			<div className={classes.elem('form').b()}>
				<Label>{T('mailTemplate.templateName')}</Label>
				<Input
					value={templateName}
					onChange={e => setTemplateName(e.target.value)}
					placeholder={T('mailTemplate.addAnInternalName')}
				/>
				<Label>{T('mail.subject')}</Label>
				<Input value={templateSubject} onChange={e => setTemplateSubject(e.target.value)} />
				<div className={classes.elem('checkbox').b()}>
					<Checkbox checked={isPrivate} onChange={value => setIsPrivate(value)} size="sm" />
					<Text>{T('mail.privateTemplate')}</Text>
					<Text color="grey-10" size="sm">
						{T('mailTemplate.onlyVisibleForYou')}
					</Text>
				</div>
				<Button
					onClick={() => {
						onTemplateSave(dispatch, state, templateName, templateSubject, isPrivate);
						setClosed?.();
					}}
					disabled={!templateName.trim().length}
				>
					{T('mailTemplate.saveTemplate')}
				</Button>
			</div>
		);
	};

	return (
		<div className={classes.b()}>
			<OutsideClick targetRef={() => dropdownRef.current || null} outsideClick={closeDD?.current}>
				<div className={classes.elem('dropdown').b()} ref={(r: HTMLDivElement) => (dropdownRef.current = r)}>
					{saveTemplateForm(classes)}
				</div>
			</OutsideClick>
		</div>
	);
};

export default MailTemplateSaveDropdown;
