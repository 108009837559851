function isTouchDevice() {
	const el = document.createElement('div');
	el.setAttribute('ongesturestart', 'return;'); // or try "ontouchstart"
	const isTouch = typeof el.ongesturestart === 'function';
	el.remove();
	return isTouch;
}

function getScrollbarWidth() {
	const div = document.createElement('div');
	const innerDiv = document.createElement('div');
	div.appendChild(innerDiv);
	div.style.overflowY = 'scroll';
	div.style.width = '100px';
	div.style.height = '10px';
	innerDiv.style.height = '10px';
	document.body.appendChild(div);

	const w = div.offsetWidth - innerDiv.clientWidth;

	div.remove();

	return w;
}

// set the hidden prop depending on browser
let hidden;
if (typeof document.hidden !== 'undefined') {
	hidden = 'hidden';
} else if (typeof document.msHidden !== 'undefined') {
	hidden = 'msHidden';
} else if (typeof document.webkitHidden !== 'undefined') {
	hidden = 'webkitHidden';
}

class BrowserService {
	constructor() {
		const userAgent = window.navigator.userAgent;

		this.isTouchDevice = isTouchDevice();
		this.scrollbarWidth = getScrollbarWidth();

		// Browsers
		this.ISFF = userAgent.indexOf('Firefox') !== -1;
		this.ISOPERA = userAgent.indexOf('Opera') !== -1;
		this.ISCHROME = userAgent.indexOf('Chrome') !== -1;
		this.ISSAFARI = userAgent.indexOf('Safari') !== -1 && !this.ISCHROME;
		this.ISWEBKIT = userAgent.indexOf('WebKit') !== -1;
		this.ISIE = userAgent.indexOf('Trident') > 0 || userAgent.indexOf('MSIE') > 0;

		// IE versions
		this.ISIE6 = userAgent.indexOf('MSIE 6') > 0;
		this.ISIE7 = userAgent.indexOf('MSIE 7') > 0;
		this.ISIE8 = userAgent.indexOf('MSIE 8') > 0;
		this.ISIE9 = userAgent.indexOf('MSIE 9') > 0;
		this.ISIE10 = userAgent.indexOf('MSIE 10') > 0;

		// Recent IE
		this.ISIE11UP = userAgent.indexOf('MSIE') === -1 && userAgent.indexOf('Trident') > 0;
		this.ISIE10UP = this.ISIE10 || this.ISIE11UP;
		this.ISIE9UP = this.ISIE9 || this.ISIE10UP;

		this.ISUNSUPPORTED = this.ISIE6 || this.ISIE7 || this.ISIE8;

		if (userAgent.indexOf('Win') !== -1) {
			this.os = 'windows';
		}
		if (userAgent.indexOf('Mac') !== -1) {
			this.os = 'osx';
		}
		if (userAgent.indexOf('X11') !== -1) {
			this.os = 'unix';
		}
		if (userAgent.indexOf('Linux') !== -1) {
			this.os = 'linux';
		}
	}

	isIE() {
		return this.ISIE;
	}

	isIE9() {
		return this.ISIE9;
	}

	isFirefox() {
		return this.ISFF;
	}

	isUnsupported() {
		return this.ISUNSUPPORTED;
	}

	isSupported() {
		return this.ISCHROME || this.ISFF;
	}

	locationOrigin() {
		if (this.ISIE) {
			return (
				window.location.protocol +
				'//' +
				window.location.hostname +
				(window.location.port ? ':' + window.location.port : '')
			);
		}
		return window.location.origin;
	}

	isOptionCombinedKey(e, key, shift) {
		const keyMatch = key === e.keyCode;
		if (!keyMatch || (shift && !e.shiftKey)) {
			return false;
		}

		// CMD key on mac
		if (this.os === 'osx' && e.metaKey) {
			e.preventDefault();
			return true;
		}

		// CTRL on all besides mac
		if (this.os !== 'osx' && e.ctrlKey) {
			e.preventDefault();
			return true;
		}

		return false;
	}

	isEnter(e) {
		return e.keyCode === 13 && !e.shiftKey;
	}

	isTab(e) {
		return e.keyCode === 9 && !e.shiftKey;
	}

	isShiftTab(e) {
		return e.keyCode === 9 && e.shiftKey;
	}

	isShiftEnter(e) {
		return e.keyCode === 13 && e.shiftKey;
	}

	isOSX() {
		return this.os === 'osx';
	}

	pageIsHidden() {
		return document[hidden];
	}
}

export default new BrowserService();
