import { SubscriptionPeriodState } from '../../Context/SubscriptionGroupState';
import CustomField, { EntityCustomField } from '../../../../resources/Model/CustomField';
import { ButtonSelect, Text, Tooltip } from '@upsales/components';
import BemClass from '@upsales/components/Utils/bemClass';
import T from 'Components/Helpers/translate';
import { useSelector } from 'react-redux';
import { RootState } from 'Store/index';
import React, { useMemo } from 'react';

import './SubscriptionPeriodButtonSelect.scss';

interface SubscriptionPeriodButtonProps {
	isSelected: boolean;
	title: string;
	timespanText: string;
	noMissingRequiredOrderFields: number;
	noMissingRequiredOrderRowFields: number;
}

const SubscriptionPeriodButton: React.FC<SubscriptionPeriodButtonProps> = ({
	isSelected,
	title,
	timespanText,
	noMissingRequiredOrderFields,
	noMissingRequiredOrderRowFields
}) => {
	const classNames = new BemClass('SubscriptionPeriodButton');

	let orderLangTag = 'subscription.modal.summary.split.missingRequired.order';
	let orderrowLangTag = 'subscription.modal.summary.split.missingRequired.orderrow';

	const noMissingRequiredFields = noMissingRequiredOrderFields + noMissingRequiredOrderRowFields;
	let tooltip = '';
	if (noMissingRequiredOrderFields > 0) {
		if (noMissingRequiredOrderFields > 1) {
			orderLangTag += '.plural';
		}
		tooltip += T(orderLangTag, { number: noMissingRequiredOrderFields });
	}
	if (noMissingRequiredOrderRowFields > 0) {
		tooltip += noMissingRequiredOrderFields > 0 ? '\n' : '';

		if (noMissingRequiredOrderRowFields > 1) {
			orderrowLangTag += '.plural';
		}
		tooltip += T(orderrowLangTag, { number: noMissingRequiredOrderRowFields });
	}

	return (
		<Tooltip title={tooltip} disabled={isSelected}>
			<div className={classNames.mod({ missing: !isSelected && noMissingRequiredFields > 0 }).b()}>
				<Text size="sm" bold={isSelected}>
					{title}
				</Text>
				<Text size="sm">{timespanText}</Text>
			</div>
		</Tooltip>
	);
};

type OrderRowSubset = {
	product?: { id: number };
	custom?: EntityCustomField[];
};

type PeriodSubset = {
	uuid: number;
	orderRows: OrderRowSubset[];
	custom?: EntityCustomField[];
	locked?: boolean;
};

type SubscriptionPeriodButtonOption = {
	period: PeriodSubset;
	title: string;
	timespanText: string;
};
interface SubscriptionPeriodButtonSelectProps {
	currentUUID: number;
	options: SubscriptionPeriodButtonOption[];
	onChange: (id: number) => void;
}

const SubscriptionPeriodButtonSelect: React.FC<SubscriptionPeriodButtonSelectProps> = ({
	currentUUID,
	options: periodOptions,
	onChange
}) => {
	const classNames = new BemClass('SubscriptionPeriodButtonSelect', 'ButtonSelect--xl');

	const customFields = useSelector(({ App }: RootState) => App.customFields);
	const customFilter = (field: CustomField) => field.$hasAccess && (field.visible || field.editable);
	const orderRowCustomFields = useMemo(() => customFields['orderrow'].filter(customFilter), []);
	const orderCustomFields = useMemo(() => customFields['order'].filter(customFilter), []);

	const countMissingRequiredCustomFields = (
		fields: CustomField[],
		customValues: SubscriptionPeriodState['custom'],
		locked?: boolean
	) => {
		const missingRequiredCustomFields = fields.filter(field => {
			if (!field.obligatoryField || locked) {
				return false;
			}

			const custom = customValues?.find(custom => custom.fieldId === field.id);
			return !custom?.value;
		});

		return missingRequiredCustomFields.length;
	};

	const countMissingRequiredOrderRowFields = (period: PeriodSubset) => {
		return period.orderRows.reduce((sum, row) => {
			const hasProduct = !!row.product;

			const noMissingRequiredCustomFields = countMissingRequiredCustomFields(
				orderRowCustomFields,
				row.custom,
				period.locked
			);

			return sum + noMissingRequiredCustomFields + (hasProduct ? 0 : 1);
		}, 0);
	};

	const options = useMemo(
		() =>
			periodOptions.map(({ period, title, timespanText }) => {
				const noMissingRequiredOrderFields = countMissingRequiredCustomFields(
					orderCustomFields,
					period.custom,
					period.locked
				);
				const noMissingRequiredOrderRowFields = countMissingRequiredOrderRowFields(period);
				const isSelected = currentUUID === period.uuid;

				return {
					icon: 'warning',
					children: (
						<SubscriptionPeriodButton
							isSelected={isSelected}
							title={title}
							timespanText={timespanText}
							noMissingRequiredOrderFields={noMissingRequiredOrderFields}
							noMissingRequiredOrderRowFields={noMissingRequiredOrderRowFields}
						/>
					),
					value: period.uuid
				};
			}),
		[periodOptions]
	);

	return (
		<ButtonSelect
			className={classNames.b()}
			size="lg"
			value={currentUUID}
			options={options}
			onChange={onChange}
		></ButtonSelect>
	);
};

export default SubscriptionPeriodButtonSelect;
