import Resource from './Resource';

class OpportunityEvents extends Resource {
	constructor() {
		super('events');
	}

	getEvents(opportunityId: number, limit?: number | null, offset?: number | null, notes?: boolean) {
		const customerId = Tools.AppService.getCustomerId();
		return this._getRequest(`${opportunityId}/events`, {
			_url: `${customerId}/opportunities`,
			params: {
				limit,
				offset,
				notes: notes ? 1 : 0
			}
		}).then(res => res.data);
	}
}

const resource = new OpportunityEvents();

export default resource;
