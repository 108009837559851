import React, { useState, Fragment, Component, ComponentProps } from 'react';
import PropTypes from 'prop-types';
import './Page.scss';
import bemClass from '@upsales/components/Utils/bemClass';
import { Flex, Loader, Title } from '@upsales/components';
import { useSoftDeployAccess } from 'App/components/hooks';

export type PageSidebarProvided = {
	expanded: boolean;
	setExpanded: (expanded: boolean) => void;
};

type BaseProps = {
	className?: string;
	transparent?: boolean;
	children?: any;
	header?: null | JSX.Element | (() => JSX.Element | null);
	sidebar?: null | JSX.Element | ((sidebarProvided: PageSidebarProvided) => JSX.Element | null);
	sidebarInitiallyExpanded?: boolean;
	fullHeight?: boolean;
	contentLoading?: boolean;
	error?: string;
	static?: boolean;
	overflow?: boolean;
	asFlex?: boolean;
};

type PageProps =
	| (BaseProps & {
			asFlex?: false;
	  })
	| (BaseProps & {
			asFlex: true;
	  } & ComponentProps<typeof Flex>);

const Page = ({
	className,
	transparent = false,
	children,
	header = null,
	sidebar = null,
	sidebarInitiallyExpanded = false,
	fullHeight = false,
	contentLoading = false,
	static: isStatic = false,
	error = '',
	overflow = false,
	asFlex = false,
	...props
}: PageProps) => {
	const [sidebarExpanded, setSidebarExpanded] = useState(sidebarInitiallyExpanded);
	let sidebarElement = sidebar;
	if (sidebar && typeof sidebar === 'function') {
		sidebarElement = sidebar({ expanded: sidebarExpanded, setExpanded: setSidebarExpanded });
	}
	let headerElement = header;
	if (header && typeof header === 'function') {
		headerElement = header();
	}
	const hasNoSidebar = useSoftDeployAccess('NO_SIDEBAR');
	const classes = new bemClass('Page', className).mod({
		transparent,
		'has-sidebar': !!sidebarElement && !error,
		'has-header': !!headerElement && !error,
		'full-height': fullHeight,
		static: isStatic,
		'no-frame': true,
		'edge-to-edge': hasNoSidebar // add class here until released
	});
	const content = contentLoading ? <Loader className={classes.elem('content-loader').b()} /> : children;
	const Component = asFlex ? Flex : 'div';
	return (
		<Component {...props} className={classes.b()}>
			{headerElement && !error ? <div className={classes.elem('header').b()}>{headerElement}</div> : null}
			{sidebarElement && !error ? (
				<Fragment>
					<div className={classes.elem('sidebar').mod({ expanded: sidebarExpanded }).b()}>
						{sidebarElement}
					</div>
					<div className={classes.elem('content').mod({ overflow }).b()}>{content}</div>
				</Fragment>
			) : error ? (
				<Title color="red" className={classes.elem('error').b()} center>
					{error}
				</Title>
			) : (
				content
			)}
		</Component>
	);
};

Page.propTypes = {
	className: PropTypes.string,
	transparent: PropTypes.bool,
	children: PropTypes.any,
	header: PropTypes.oneOfType([PropTypes.node, PropTypes.func]),
	sidebar: PropTypes.oneOfType([PropTypes.node, PropTypes.func]),
	sidebarInitiallyExpanded: PropTypes.bool,
	fullHeight: PropTypes.bool,
	contentLoading: PropTypes.bool,
	pageError: PropTypes.bool
};

export default Page;
