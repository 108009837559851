import React from 'react';
import PropTypes from 'prop-types';
import { get } from 'lodash';
import { Text, Icon, Link } from '@upsales/components';
import TimelineRow from 'Components/TimelineRow';
import { ACTIVITY_TIMELINE } from 'App/babel/enum/timeline';

const ActivityTimelineCreateOrder = ({ event, hideIcon }) => {
	const t = Tools.$translate;
	const { user, data } = event || {};
	const order = get(data, 'order') || {};
	const date = get(data, 'closeDate');
	const userName = get(user, 'name');
	const lang = {
		created: t('activity.outcomeLog.created'),
		closedThisActivity: t('activity.outcomeLog.closedThisActivity'),
		with: t('activity.outcomeLog.with')
	};
	const customerId = Tools.AppService.getCustomerId();
	const contactName = get(data, 'contact.name', '');
	const contactId = get(data, 'contact.id', '');
	const openOrder = () => {
		const type = event.subType === ACTIVITY_TIMELINE.REACHED_CREATE_ORDER ? 'order' : 'opportunity';
		const customerId = Tools.AppService.getCustomerId();
		Tools.$upModal.open('editOrder', { type, customerId, id: order.id });
	};
	const title = (
		<Text>
			{`${lang.created} `}
			<Link onClick={openOrder}>{order.description}</Link>
			{` ${lang.with} `}
			<Link
				onClick={e => e.stopPropagation()}
				href={Tools.$state.href('contact.dashboard', {
					customerId: customerId,
					id: contactId
				})}
			>
				{contactName}
			</Link>
		</Text>
	);
	const subTitle = date ? (
		<Text size="sm" color="grey-11">
			{moment(date).format('L LT')} <span dangerouslySetInnerHTML={{ __html: '&#8226;' }} /> {userName}
		</Text>
	) : null;
	const subSection = (
		<Text className="subSection">
			<Icon name="check" space="mrl" color="black" />
			{lang.closedThisActivity}
		</Text>
	);

	return (
		<TimelineRow
			icon={
				hideIcon
					? undefined
					: event.subType === ACTIVITY_TIMELINE.REACHED_CREATE_ORDER
					? 'sales'
					: 'opportunity'
			}
			event={event}
			title={title}
			subTitle={subTitle}
			subSection={subSection}
		/>
	);
};

ActivityTimelineCreateOrder.propTypes = {
	event: PropTypes.shape({})
};

export default ActivityTimelineCreateOrder;
