import Resource from './Resource';

class DesiredOutcome extends Resource {
	constructor() {
		super('desiredoutcome');
		this.dateFields = ['modDate', 'regDate', 'desiredOutcomes.modDate', 'desiredOutcomes.regDate'];
	}
}

const resource = new DesiredOutcome();

export default resource;
