import { useTranslation } from 'Components/Helpers/translate';
import BemClass from '@upsales/components/Utils/bemClass';
import React, { useEffect, useState } from 'react';
import {
	ModalContent,
	FullscreenModal,
	StateFrame,
	Table,
	TableHeader,
	TableRow,
	TableColumn,
	Loader,
	Block,
	Paginator
} from '@upsales/components';
import { ModalProps } from 'App/components/Modals/Modals';
import Headline from '@upsales/components/Headline';
import './InactivateContacts.scss';
import EditorHeader from 'Components/EditorHeader';
import { addInactivatedContactsFilter, fetchInactivatedContacts } from '../Helpers/fetchHelpers';
import { useGetData } from 'App/components/hooks';
import RequestBuilder from 'Resources/RequestBuilder';
import logError from 'Helpers/logError';

type InactivateContactsProps<T> = ModalProps<T> & {
	total: number;
	onSave?: () => void;
};

const InactivateContacts = <T extends { [key: string]: any }>({
	className,
	close,
	total,
	onSave
}: InactivateContactsProps<T>) => {
	const classes = new BemClass('InactivateContacts', className);
	const { t } = useTranslation();
	const [sort, setSort] = useState({ field: 'name', asc: true });
	const [saving, setSaving] = useState(false);
	const [offset, setOffset] = useState(0);
	const TABLE_LIMIT = 50;

	const {
		data: inactivatedContacts,
		loading,
		refetch
	} = useGetData({
		fetcher: fetchInactivatedContacts,
		fetcherProps: {
			sort
		},
		limit: TABLE_LIMIT,
		offset
	});

	useEffect(() => {
		refetch();
	}, [sort]);

	const onConfirm = () => {
		setSaving(true);
		const rb = new RequestBuilder();
		addInactivatedContactsFilter(rb);

		const CREATE_JOB_THRESHOLD = 10;

		Tools.MultiActions.customer(Tools.AppService.getCustomerId())
			.updateContact('Contact', total, [{ name: 'Active', value: 0 }], rb.build())
			.then(() => {
				const createdJob = total > CREATE_JOB_THRESHOLD;
				Tools.NotificationService.addNotification({
					style: Tools.NotificationService.style.SUCCESS,
					icon: 'check',
					title: 'default.saved',
					body: createdJob ? 'updateQued.contacts' : 'updated.contacts'
				});
				if (onSave) {
					onSave();
				}
				close();
			})
			.catch(err => {
				Tools.NotificationService.addNotification({
					style: Tools.NotificationService.style.ERROR,
					icon: 'times',
					title: 'default.error',
					body: 'updateError.contacts'
				});
				logError(err);
				setSaving(false);
			});
	};

	return (
		<FullscreenModal className={classes.b()}>
			<EditorHeader
				onConfirmTitle={t('inactivateContacts.confirm', { amount: total })}
				onConfirmSupertitle={''}
				disableSave={saving}
				loading={loading || saving}
				onConfirm={onConfirm}
				onConfirmIcon={'check'}
				className={className}
				subtitle={t('inactivateContacts.header.subtitle', { amount: total })}
				onAbort={() => close()}
				title={t('inactivateContacts.header.title')}
				icon={'user-times'}
			>
				<></>
			</EditorHeader>
			<ModalContent>
				<Headline>{t('inactivateContacts.title')}</Headline>
				<StateFrame
					icon="info"
					space="mtxl mbxl"
					state="info"
					subtitle={t('inactivateContacts.info')}
					bigIcon
					bigIconName="info"
				/>
				<div className={classes.elem('table-container').b()}>
					<Table>
						<TableHeader
							columns={[
								{ title: t('default.name'), sort: 'name' },
								{ title: t('default.email'), sort: 'email' }
							]}
							asc={sort.asc}
							onSortChange={setSort}
							sorting={sort.field}
						/>
						{loading ? (
							<TableRow>
								<TableColumn colSpan={2} size="lg">
									<Loader size="md" className={classes.elem('table-loader').b()} />
								</TableColumn>
							</TableRow>
						) : (
							inactivatedContacts.map(contact => (
								<TableRow key={contact.id}>
									<TableColumn title={contact.name ?? ''} subtitle={contact.client.name ?? ''} />
									<TableColumn color="red" title={contact.email} />
								</TableRow>
							))
						)}
					</Table>
				</div>
				{total > TABLE_LIMIT && !loading ? (
					<Block space="mtm">
						<Paginator
							limit={TABLE_LIMIT}
							offset={offset}
							total={total}
							align="center"
							onChange={offset => {
								setOffset(offset);
								refetch();
							}}
						/>
					</Block>
				) : null}
			</ModalContent>
		</FullscreenModal>
	);
};

export default InactivateContacts;
