import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import BemClass from '@upsales/components/Utils/bemClass';
import { Link, Card, CardHeader, CardContent, Button, Icon } from '@upsales/components';

import './index.scss';

const LINKEDIN = 'LinkedIn';
const FACEBOOK = 'Facebook';
const TWITTER = 'Twitter';

class WebLinks extends PureComponent {
	constructor() {
		super();

		const t = Tools.$translate;
		this.lang = {
			title: t('activity.outcome.webLinksTitle'),
			visitWebSite: t('activity.outcome.visitWebSite'),
			searchLinkedIn: t('activity.outcome.searchLinkedIn'),
			searchGoogle: t('activity.outcome.searchGoogle'),
			searchFacebook: t('activity.outcome.searchFacebook'),
			searchTwitter: t('activity.outcome.searchTwitter')
		};
	}

	render() {
		const classNames = new BemClass('WebLinks');
		const { name, facebook, twitter, linkedIn, webPage } = this.props;
		const encodedName = encodeURIComponent(name).replace(/%20/g, '+');
		const hasNewFieldsAccess = Tools.FeatureHelper.hasSoftDeployAccess('NEW_FIELDS');
		const twitterText = hasNewFieldsAccess && twitter ? TWITTER : this.lang.searchTwitter;
		const twitterHref =
			hasNewFieldsAccess && twitter
				? twitter
				: `https://twitter.com/search?q=${encodedName}&src=typed_query&f=user`;
		const facebookText = hasNewFieldsAccess && facebook ? FACEBOOK : this.lang.searchFacebook;
		const facebookHref =
			hasNewFieldsAccess && facebook ? facebook : `https://www.facebook.com/search/pages/?q=${encodedName}`;
		const linkedInText = hasNewFieldsAccess && linkedIn ? LINKEDIN : this.lang.searchLinkedIn;
		const linkedInHref =
			hasNewFieldsAccess && linkedIn
				? linkedIn
				: `https://www.linkedin.com/search/results/companies/?keywords=${encodedName}`;

		return (
			<Card className={classNames.b()}>
				<CardHeader title={this.lang.title} />
				<CardContent>
					{webPage ? (
						<Link target="_blank" href={webPage}>
							<Button type="lined" size="sm">
								<Icon name="globe" />
								{this.lang.visitWebSite}
							</Button>
						</Link>
					) : null}
					<Link target="_blank" href={`https://www.google.com/search?q=${encodedName}`}>
						<Button type="lined" size="sm">
							<Icon name="google" />
							{this.lang.searchGoogle}
						</Button>
					</Link>
					<Link target="_blank" href={linkedInHref}>
						<Button type="lined" size="sm">
							<Icon name="linkedin" />
							{linkedInText}
						</Button>
					</Link>
					<Link target="_blank" href={twitterHref}>
						<Button type="lined" size="sm">
							<Icon name="twitter" />
							{twitterText}
						</Button>
					</Link>
					<Link target="_blank" href={facebookHref}>
						<Button type="lined" size="sm">
							<Icon name="facebook" />
							{facebookText}
						</Button>
					</Link>
				</CardContent>
			</Card>
		);
	}
}

WebLinks.propTypes = {
	name: PropTypes.string,
	facebook: PropTypes.string,
	twitter: PropTypes.string,
	linkedIn: PropTypes.string,
	webPage: PropTypes.string
};

export default WebLinks;
