import { Block, ButtonBox, DateInput, Flex, Text } from '@upsales/components';
import BemClass from '@upsales/components/Utils/bemClass';
import { CurrencyFormat } from 'App/babel/utils/numberFormat';
import T from 'Components/Helpers/translate';
import moment from 'moment';
import React, { useEffect, useMemo } from 'react';
import { DiffOrder } from '../../Context/SubscriptionGroupState';
import { useSubscriptionGroupContext } from '../../Context/SubscriptionGroupContext';
import FlexItem from 'App/components/FlexItem';

type Props = {
	diffOrder: DiffOrder;
	createDifforder: boolean;
	setCreateDifforder: (shouldCreate: boolean) => void;
	setInitialCreditDate: (initialDate: string | undefined) => void;
};

const DifforderCheckboxes = ({ diffOrder, createDifforder, setCreateDifforder, setInitialCreditDate }: Props) => {
	const classes = new BemClass('DifforderCheckboxes');
	const currencyFormat = new CurrencyFormat(diffOrder.currency);

	const {
		state: { subscriptionMap }
	} = useSubscriptionGroupContext();

	const isCredit = diffOrder.totalValue < 0;

	const visualStartDateInitial = useMemo(() => diffOrder.visualStartDate, []);
	const isInFuture = moment(visualStartDateInitial).isAfter(moment(), 'day');

	const subscription = Object.values(subscriptionMap).find(
		subscription => subscription.id === diffOrder.agreementId
	)!;

	let currentPeriodStartDate = moment(subscription.invoiceStartDate);
	if (
		currentPeriodStartDate.isAfter(moment(), 'day') &&
		!currentPeriodStartDate.isSame(moment(subscription.initialInvoiceStartDate), 'day')
	) {
		currentPeriodStartDate = currentPeriodStartDate.subtract(subscription.periodLength, 'months');
	}

	const canSetStartDate = isCredit && !isInFuture;

	let dateValue = new Date(diffOrder.visualStartDate);
	if (canSetStartDate) {
		dateValue = new Date(subscription.creditInitialDate ?? visualStartDateInitial);
	}

	useEffect(() => {
		return () => {
			setInitialCreditDate(undefined);
		};
	}, []);

	return (
		<div className={classes.b()}>
			<Flex space="mbm" gap="u5">
				<Block>
					<Text bold>{T('from')}</Text>
					<DateInput
						closeOnSelect
						value={dateValue}
						onChange={e => {
							setInitialCreditDate(moment(e.target.value).format('YYYY-MM-DD'));
						}}
						min={new Date(currentPeriodStartDate.format('YYYY-MM-DD'))}
						max={
							moment().isAfter(moment(diffOrder.visualEndDate), 'day')
								? new Date(diffOrder.visualEndDate)
								: new Date()
						}
						disabled={!canSetStartDate || currentPeriodStartDate.isSame(moment(), 'day')}
						inline
					/>
				</Block>
				<Block>
					<Text bold>{T('default.to')}</Text>
					<DateInput value={new Date(diffOrder.visualEndDate)} onChange={() => null} inline disabled />
				</Block>{' '}
			</Flex>

			<Flex gap="u6" justifyContent="center">
				<FlexItem
					component={ButtonBox}
					flexGrow={1}
					title={T(`subscription.modal.difforder.create${isCredit ? 'Credit' : ''}CheckboxTitle`, {
						value: currencyFormat.short(diffOrder.totalValue)
					})}
					selected={createDifforder}
					onClick={() => setCreateDifforder(true)}
				/>
				<FlexItem
					component={ButtonBox}
					flexGrow={1}
					title={T(`subscription.modal.difforder.dontCreate${isCredit ? 'Credit' : ''}CheckboxTitle`)}
					subtitle={T(`subscription.modal.difforder.dontCreate${isCredit ? 'Credit' : ''}CheckboxSubtitle`)}
					selected={!createDifforder}
					onClick={() => setCreateDifforder(false)}
				/>
			</Flex>
		</div>
	);
};

export default DifforderCheckboxes;
