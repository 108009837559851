import React from 'react';

export default function LoadingView({ className }) {
	return (
		<div className={className}>
			<div className="SkeletonLoading__Main" />
			<div className="SkeletonLoading__Subtitle" />
		</div>
	);
}
