import BemClass from '@upsales/components/Utils/bemClass';
import { NumberInput, Text } from '@upsales/components';
import { getSelf } from 'Store/selectors/AppSelectors';
import React from 'react';

import './PercentageInput.scss';

type PercentageInputProps = {
	value: number;
	onChange: (value?: number) => void;
	disabled?: boolean;
};

const PercentageInput = ({ value, disabled, onChange }: PercentageInputProps) => {
	const self = getSelf();
	const locale = self?.userParams?.locale || 'sv-SE';

	const classNames = new BemClass('PercentageInput');

	return (
		<div className={classNames.b()}>
			<NumberInput
				className={classNames.elem('input').b()}
				disabled={disabled}
				onChange={onChange}
				locale={locale}
				value={value}
				decimals={2}
				max={100}
				min={0}
			/>
			<div className={classNames.elem('right-side').b()}>
				<Text color="dark-grey">%</Text>
			</div>
		</div>
	);
};
export default PercentageInput;
