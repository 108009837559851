import Resource from './Resource';

class SalesCoachStakeholder extends Resource {
	constructor() {
		super('salesCoachStakeholder', null, { notificationTitle: 'default.translation' });
	}

	new() {
		return {
			defaultValue: '',
			language: '',
			value: ''
		};
	}

	save(data, opts = {}) {
		data.salesCoachId = this.salesCoachId;
		return super.save(data, { ...opts });
	}

	find(filter = {}, opts = {}) {
		if (!filter.salesCoachId && this.salesCoachId) {
			filter.salesCoachId = this.salesCoachId;
		}

		return super.find(filter, opts);
	}

	setSalesCoachId(salesCoachId) {
		this.salesCoachId = salesCoachId;
	}

	deleteTag(tagId, opts = {}) {
		const path = tagId + '?deleteTagId=' + tagId;
		return super.delete(path, { ...opts });
	}
}

const resource = new SalesCoachStakeholder();

export default resource;
