import OrderModel from 'App/resources/Model/Order';
import Order from 'App/resources/Order';

export async function getOrder(id?: number): Promise<Partial<OrderModel>> {
	if (id) {
		const dbOrder = await Order.get(id);
		return dbOrder.data;
	} else {
		return Order.new();
	}
}

export async function saveOrder(order: Partial<OrderModel>): Promise<OrderModel> {
	const dbOrder = await Order.save(order);
	return dbOrder.data;
}

export async function deleteOrder(order: Partial<OrderModel>) {
	if (order.id) {
		await Order.delete(order.id);
	}
}

export function cloneOrder(order: Partial<OrderModel>): Partial<OrderModel> {
	return { ...order, id: undefined };
}

export function isOrderValid(order: Partial<OrderModel>): boolean {
	return true;
}
