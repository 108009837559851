enum UserSurveyRoles {
	NEW_SALES_MANAGER = 'NewSalesManager',
	NEW_SALES = 'NewSales',
	UPSELL_SALES_MANAGER = 'UpsellSalesManager',
	UPSELL_SALES = 'UpsellSales',
	COMBO_SALES_MANAGER = 'ComboSalesManager',
	COMBO_SALES = 'ComboSales',
	MARKETING_MANAGER = 'MarketingManager',
	MARKETING = 'Marketing',
	PROJECT_MANAGER = 'ProjectManager',
	PROJECT = 'Project',
	FINANCE_MANAGER = 'FinanceManager',
	FINANCE = 'Finance',
	SUPPORT_MANAGER = 'SupportManager',
	SUPPORT = 'Support',
	CEO = 'CEO',
	OTHER = 'Other'
}

export const UserSurveyRoleIds = {
	[UserSurveyRoles.NEW_SALES]: 1,
	[UserSurveyRoles.CEO]: 2,
	[UserSurveyRoles.FINANCE_MANAGER]: 3,
	[UserSurveyRoles.MARKETING]: 4,
	[UserSurveyRoles.UPSELL_SALES_MANAGER]: 5,
	[UserSurveyRoles.MARKETING_MANAGER]: 6,
	[UserSurveyRoles.NEW_SALES_MANAGER]: 7,
	[UserSurveyRoles.COMBO_SALES_MANAGER]: 8,
	[UserSurveyRoles.UPSELL_SALES]: 9,
	[UserSurveyRoles.PROJECT_MANAGER]: 10,
	[UserSurveyRoles.OTHER]: 11,
	[UserSurveyRoles.PROJECT]: 12,
	[UserSurveyRoles.FINANCE]: 13,
	[UserSurveyRoles.SUPPORT_MANAGER]: 14,
	[UserSurveyRoles.SUPPORT]: 15,
	[UserSurveyRoles.COMBO_SALES]: 16
};

export enum UserRoleSurveyStep {
	SALES = 'SALES',
	HOW_DO_YOU_SELL = 'HOW_DO_YOU_SELL',
	FIND_NEW_CUSTOMERS = 'FIND_NEW_CUSTOMERS',
	SELL_TO_EXISTING_CUSTOMERS = 'SELL_TO_EXISTING_CUSTOMERS',
	SELL_BOTH = 'SELL_BOTH',
	MARKETING = 'MARKETING',
	PROJECT_MANAGEMENT = 'PROJECT_MANAGEMENT',
	FINANCE = 'FINANCE',
	CUSTOMER_SUPPORT = 'CUSTOMER_SUPPORT'
}

export enum SurveyStages {
	START = 0,
	WORK_TITLE = 1,
	CRM_EXPERIENCE = 2,
	GOALS = 3,
	UPSALES_REVIEW = 4,
	FINISH = 99
}

export default UserSurveyRoles;
