import i18next, { PostProcessorModule } from 'i18next';
import { initReactI18next, useTranslation as UT } from 'react-i18next';
import english from '../../../../lang/en-US.json';

// This variable will be set when initLanguage is called, this is just a placeholder
// App should never be loaded before language has inited
let localT = (key: string, options?: object) => key;

const langCache: Map<string, string> = new Map();

// This is to keep supporting the same type of interpolation as angular-translate
// In the future we must stop creating translations with conditions in them, like {{value ? 'yes' : 'no'}}
const escapeString = (str: string) => {
	const map = {
		'\\': '&#92;',
		'&': '&amp;',
		'<': '&lt;',
		'>': '&gt;',
		'"': '\\"',
		"'": "\\'",
		'/': '&#47;',
		'(': '&#40;',
		')': '&#41;',
		'{': '&#123;',
		'}': '&#125;'
	};

	return String(str).replace(/[&<>"'/\\]/g, function (match) {
		return map[match as keyof typeof map];
	});
};

const castValue = (v: any) => {
	if (v === undefined || v === null) return v;
	switch (typeof v) {
		case 'number':
			return v;
		case 'boolean':
			return v;
		default:
		case 'string':
			return `'${escapeString(v)}'`;
	}
};

const Processor: PostProcessorModule = {
	type: 'postProcessor',
	name: 'processEvaluations',
	process: function (value, key, options) {
		return value.replace(/{{(.*?)}}/g, (match, rawExp) => {
			// To prevent xss attacks we replace parentheses
			const exp = rawExp.replace(/\(/g, '&#40;').replace(/\)/g, '&#41;');
			try {
				const str = `(() => {
					${Object.keys(options)
						.map(key => `const ${key} = ${castValue(options[key])};`)
						.join(' ')}
					return ${exp};
					})()`;
				// eslint-disable-next-line security/detect-eval-with-expression
				return eval(str);
			} catch {
				return match;
			}
		});
	}
};

const RemoveMissing: PostProcessorModule = {
	type: 'postProcessor',
	name: 'removeMissing',
	process: function (value) {
		return value.replace(/{{(.*?)}}/g, '').replace('  ', ' ');
	}
};

// Init translations
export const initLanguage = () => {
	return i18next
		.use(Processor)
		.use(RemoveMissing)
		.use(initReactI18next)
		.init({
			lng: 'en-US',
			debug: false,
			resources: {
				'en-US': { translation: english }
			},
			postProcess: ['processEvaluations', 'removeMissing'],
			interpolation: {
				escapeValue: false,
				escape: escapeString
			},
			nsSeparator: false,
			keySeparator: false
		})
		.then(t => {
			localT = t;
		});
};

// Load language file
const getLanguageFile = (lang: string) => {
	if (lang === 'noLangTran') {
		return Promise.resolve({});
	}
	if (lang === 'en-US') {
		return Promise.resolve(english);
	}
	let langToLoad = lang;
	if (lang === 'nb-NO') {
		langToLoad = 'no';
	}
	return import('../../../../lang/' + langToLoad + '.json').then(other => ({ ...english, ...other.default }));
};

// Load language file if not already loaded and sets the language
export const setLanguage = async (lang: string) => {
	langCache.clear();
	if (!i18next.hasResourceBundle(lang, 'translation')) {
		const translation = await getLanguageFile(lang);
		await i18next.addResourceBundle(lang, 'translation', translation);
	}

	return i18next.changeLanguage(lang);
};

export const useTranslation = UT;

export const getCurrentLanguage = () => i18next.language;

const translate = (tag: string, opts?: object) => {
	if (!opts && Tools.FeatureHelper?.hasSoftDeployAccess?.('SPEED_TRANSLATION_CACHE')) {
		let cached = langCache.get(tag);
		if (!cached) {
			const newVal = localT(tag);
			langCache.set(tag, newVal);
			cached = newVal;
		}
		return cached;
	}

	return localT(tag, opts);
};

export default translate;
