import Resource from './Resource';
import Attributes from 'Attributes/MailSignatureAttributes';
import RequestBuilder from './RequestBuilder';
import moment from 'moment';

class MailSignatures extends Resource {
	constructor() {
		super('mailsignature', Attributes);
		this.dateFields = ['startDate', 'endDate'];
	}

	new() {
		// return Resource().post();
		return new Promise(resolve => {
			resolve({
				name: '',
				active: true,
				standard: false,
				body: '',
				startDate: null,
				endDate: null
			});
		});
	}

	find(sort, limit = 200, offset = 0) {
		const rb = new RequestBuilder();
		rb.limit = limit;
		rb.offset = offset;

		if (sort) {
			rb.addSort(sort.field, sort.asc);
		}

		return super.find(rb.build());
	}

	getStandardSignature() {
		const rb = new RequestBuilder();
		rb.addFilter(Attributes.active, 'eq', true);
		rb.addSort('standard', false);

		return super
			.find(rb.build())
			.then(res => {
				if (res.data[0].standard) {
					return {
						error: null,
						data: res.data[0]
					};
				}

				return {
					error: null,
					data: null
				};
			})
			.catch(error => {
				return {
					error,
					data: null
				};
			});
	}

	getActiveSignatures(sort, limit = 200, offset = 0) {
		const rb = new RequestBuilder();
		rb.limit = limit;
		rb.offset = offset;

		rb.addFilter(Attributes.active, 'eq', true);
		rb.addSort(Attributes.name, true);

		return super.find(rb.build()).then(res => {
			// filter out active but where the start and end date is not active
			const cleanedArray = res.data.filter(row => {
				if (row.startDate && row.endDate) {
					if (moment().isAfter(row.startDate) && moment().isBefore(row.endDate)) {
						return true;
					}

					return false;
				} else if (row.startDate) {
					if (moment().isAfter(row.startDate)) {
						return true;
					}

					return false;
				}

				return true;
			});

			return {
				error: null,
				data: cleanedArray
			};
		});
	}

	delete(data) {
		return super
			.delete(data.id)
			.then(res => res.data)
			.catch(error => console.error(error));
	}
}

const resource = new MailSignatures();
window.Tools.MailSignatures = resource;
export default resource;
