import React, { MutableRefObject, useRef, useState } from 'react';
import { useTranslation } from 'Components/Helpers/translate';

import Modal from '@upsales/components/Modal/Modal';
import ModalHeader from '@upsales/components/ModalHeader/ModalHeader';
import ModalContent from '@upsales/components/ModalContent/ModalContent';
import ModalControls from '@upsales/components/ModalControls/ModalControls';
import BemClass from '@upsales/components/Utils/bemClass';
import Column from '@upsales/components/Column/Column';
import { PrimaryButton, ThirdButton } from '@upsales/components/Buttons';
import { defaultFilterGetter } from 'App/helpers/multiActionRunnerHelpers';
import { MultiSelect } from '../../ListView/ListViewRenderHelpers';
import RequestBuilder from 'Resources/RequestBuilder';
import Block from '@upsales/components/Block/Block';

import LabeledInput from '../../Inputs/LabeledInput';
import Textarea from '@upsales/components/Textarea/Textarea';
import { ModalProps } from 'App/components/Modals/Modals';
import { PropertyMap, PropertyArray } from '../MultiActionModal';
import { CampaignMultiSelect } from 'Components/PlanPhonecallsDrawer/CampaignSelect/CampaignSelect';
import ButtonSelect from '@upsales/components/ButtonSelect/ButtonSelect';
import { StaticUserSelect } from 'App/components/Inputs/Selects/StaticUserSelect/StaticUserSelect';
import { StaticCategoryMultiSelect } from 'App/components/Inputs/Selects/StaticCategorySelect/StaticCategorySelect';
import { StaticJourneyStepSelect } from 'App/components/Inputs/Selects/StaticJourneyStepSelect/StaticJourneyStepSelect';
import JourneyStep from 'App/resources/Model/JourneyStep';
import Category from 'App/resources/Model/Category';
import { CoreSelectItem } from 'App/components/Inputs/Selects/StaticSelect';
import { SelectItem } from '@upsales/components/Utils/selectHelpers';

type Props = {
	entity?: string;
	extraParams?: any;
	filters: RequestBuilder;
} & (
	| {
			onSave: (props: PropertyArray) => Promise<void>;
			multiSelect?: never;
	  }
	| {
			multiSelect: MultiSelect;
			onSave?: never;
	  }
) &
	ModalProps;

const MultiUpdateClient = ({ filters, multiSelect, entity, onSave, close, extraParams, className }: Props) => {
	const { t } = useTranslation();
	const modalRef: MutableRefObject<HTMLDivElement | null> = useRef<HTMLDivElement>(null);

	const classes = new BemClass('MultiUpdateClient', className);

	const userTags = [
		{
			id: '{{General.CurrentUserId}}',
			title: t('tag.general.currentuser')
		}
	];

	const [properties, setProperties] = useState<PropertyMap>({});

	const [addCampaigns, setAddCampaigns] = useState<SelectItem[]>([]);
	const [removeCampaigns, setRemoveCampaigns] = useState<SelectItem[]>([]);

	const [addCategories, setAddCategories] = useState<CoreSelectItem<Category>[]>([]);
	const [removeCategories, setRemoveCategories] = useState<CoreSelectItem<Category>[]>([]);

	const [userChangeType, setUserChangeType] = useState<'Add' | 'Set' | 'Remove'>('Add');
	const [selectedUser, setSelectedUser] = useState<CoreSelectItem | null>(null);

	const [journeyStep, setJourneyStep] = useState<CoreSelectItem<JourneyStep> | null>(null);

	const save = async () => {
		const propArray: PropertyArray = [];
		for (const key in properties) {
			const value: PropertyMap[keyof typeof properties] = properties[key];
			if (value !== null && value !== undefined) {
				propArray.push({ name: key, value });
			}
		}

		if (selectedUser) {
			const propName = (userChangeType === 'Set' ? '' : userChangeType) + 'User';
			propArray.push({ name: propName, value: selectedUser.id });
		}

		if (onSave) {
			return onSave(propArray);
		}

		const selectedFilters = defaultFilterGetter(filters, multiSelect);

		Tools.MultiActions.customer(Tools.AppService.getCustomerId()).updateAccount(
			entity || 'Client',
			multiSelect.selectedIds.length,
			propArray,
			selectedFilters.build(),
			extraParams
		);
	};

	return (
		<Modal size="lg" className={classes.b()}>
			<div ref={modalRef}>
				<ModalHeader title={t('default.updateAccounts')} onClose={close} icon="edit" />
				<ModalContent>
					<Block>
						<CampaignMultiSelect
							data-testid="selectAddCampaigns"
							alwaysShowInput
							value={addCampaigns}
							onChange={v => {
								setProperties({
									...properties,
									AddProject: v.length ? v.map(e => e.id).join(',') : null
								});
								setAddCampaigns(v ?? []);
							}}
							anchor={modalRef.current}
							withToggle={false}
							title={`${t('default.add')} ${t('default.campaigns')}`}
						/>
					</Block>
					<Block>
						<CampaignMultiSelect
							data-testid="selectRemoveCampaigns"
							alwaysShowInput
							value={removeCampaigns}
							onChange={v => {
								setProperties({
									...properties,
									RemoveProject: v.length ? v.map(e => e.id).join(',') : null
								});
								setRemoveCampaigns(v ?? []);
							}}
							anchor={modalRef.current}
							withToggle={false}
							title={`${t('default.remove')} ${t('default.campaigns')}`}
						/>
					</Block>

					<LabeledInput title={`${t('default.add')} ${t('default.categories')}`}>
						<StaticCategoryMultiSelect
							data-testid="selectAddCategories"
							categoryType="account"
							value={addCategories}
							onChange={value => {
								setProperties({
									...properties,
									AddCategories: value.length ? value.map(e => e.id).join(',') : null
								});
								setAddCategories(value);
							}}
							anchor={modalRef.current}
						/>
					</LabeledInput>

					<LabeledInput title={`${t('default.remove')} ${t('default.categories')}`}>
						<StaticCategoryMultiSelect
							data-testid="selectRemoveCategories"
							categoryType="account"
							value={removeCategories}
							onChange={value => {
								setProperties({
									...properties,
									RemoveCategories: value.length ? value.map(e => e.id).join(',') : null
								});
								setRemoveCategories(value);
							}}
							anchor={modalRef.current}
						/>
					</LabeledInput>

					<LabeledInput title={t('default.accountManager')}>
						<StaticUserSelect
							data-testid="selectAccountManager"
							extraOptions={userTags}
							value={selectedUser}
							onChange={setSelectedUser}
							anchor={modalRef.current}
						/>
						<Block space="mts">
							<ButtonSelect
								data-testid="selectUserChangeType"
								size="sm"
								value={userChangeType}
								options={[
									{ value: 'Add' as const, title: t('default.add') },
									{ value: 'Set' as const, title: t('multi.setAsOnlyAccountManager') },
									{ value: 'Remove' as const, title: t('default.remove') }
								]}
								onChange={setUserChangeType}
							/>
						</Block>
					</LabeledInput>

					<LabeledInput title={t('default.journeyStep')}>
						<StaticJourneyStepSelect
							data-testid="selectJourneyStep"
							value={journeyStep}
							onChange={value => {
								setProperties({
									...properties,
									JourneyStep: value?.id ?? null
								});
								setJourneyStep(value);
							}}
							anchor={modalRef.current}
						/>
					</LabeledInput>

					<LabeledInput title={t('default.notes')} classes={classes.elem('notes', 'col-span2').b()}>
						<Textarea
							value={properties.Notes ?? ''}
							onChange={e => {
								setProperties({ ...properties, Notes: e.target?.value || null });
							}}
						/>
					</LabeledInput>
				</ModalContent>
				<ModalControls>
					<Column align="right">
						<PrimaryButton
							onClick={() => {
								save();
								close();
							}}
						>
							{t('default.save')}
						</PrimaryButton>
						<ThirdButton onClick={() => close()}>{t('default.cancel')}</ThirdButton>
					</Column>
				</ModalControls>
			</div>
		</Modal>
	);
};

export default MultiUpdateClient;
