import React from 'react';
import PropTypes from 'prop-types';
import { NumberFormat } from 'App/babel/utils/numberFormat';

export default class NameSoliditet extends React.Component {
	constructor(props) {
		super(props);

		const t = Tools.$translate;

		this.lang = {
			headquarters: t('default.parentCompany'),
			branch: t('default.branchOffice'),
			city: t('address.city'),
			numEmployees: t('default.numOfEmployees'),
			turnover: t('default.turnover')
		};
	}

	getSubtitle() {
		const numberFormat = new NumberFormat();
		const address = {
			city: this.props.account.city,
			country: this.props.account.country
		};

		const flag =
			address && address.country ? (
				<b
					style={{ marginRight: 10 }}
					className={'icon flag-icon flag-icon-' + address.country.toLowerCase()}
				/>
			) : null;
		const addressSpan = address ? (
			<span style={{ verticalAlign: 'middle' }}>
				{flag}{' '}
				{ReactTemplates.TOOLS.withTooltip(<span>{address.city}</span>, this.lang.city, {
					key: 'city' + this.props.account.id,
					placement: 'top'
				})}
			</span>
		) : null;

		const numEmployeesText = numberFormat.short(this.props.account.noOfEmployeesExact);
		const numEmployees = (
			<span style={{ marginLeft: '10px' }}>
				<i className="fa fa-user" /> {numEmployeesText}
			</span>
		);
		const turnoverText = numberFormat.short(this.props.account.turnover * 1000);
		const turnover = (
			<span style={{ marginLeft: '10px' }}>
				<i className="fa fa-bar-chart" /> {turnoverText}
			</span>
		);

		return (
			<span className="subtitle">
				{addressSpan}
				{ReactTemplates.TOOLS.withTooltip(numEmployees, this.lang.numEmployees, {
					key: 'numEmployees' + this.props.account.id,
					placement: 'top'
				})}
				{ReactTemplates.TOOLS.withTooltip(turnover, this.lang.turnover, {
					key: 'turnover' + this.props.account.id,
					placement: 'top'
				})}
			</span>
		);
	}

	getAdditionalInfo() {
		if (this.props.account.isHeadqueraters === 0) {
			return <span className="additional-info">{this.lang.branch}</span>;
		} else if (this.props.account.rootParentDuns === null) {
			return <span className="additional-info">{this.lang.headquarters}</span>;
		}

		return null;
	}

	render() {
		const subtitle = this.getSubtitle();

		return (
			<td className={`DataSourceColumn ${this.props.field.className}`}>
				<div>
					<span className="row-text">
						{this.props.account.name} {this.getAdditionalInfo()}
					</span>
					{subtitle}
				</div>
			</td>
		);
	}
}

NameSoliditet.propTypes = {
	account: PropTypes.object,
	field: PropTypes.object
};
