import React, { useEffect, useMemo, useState } from 'react';
import ReportcenterFilter from './ReportcenterFilter';
import { Block, ButtonSelect, Text } from '@upsales/components';
import bemClass from '@upsales/components/Utils/bemClass';
import T from 'Components/Helpers/translate';
import './ReportcenterFilterProduct.scss';
import { RCDashboardFilter } from 'Resources/ReportDashboard';
import logError from 'App/babel/helpers/logError';
import _ from 'lodash';
import ProductResource from 'Resources/Product';
import ProductCategoryResource from 'Resources/ProductCategory';
import ProductAttributes from 'App/babel/attributes/ProductAttributes';
import RequestBuilder from 'Resources/RequestBuilder';
import ReportcenterFilterSearchbar from './ReportcenterFilterSearchbar';
import { buildArray, buildCategoryArray, mapProductToMinimalProduct } from './ReportcenterFilterProductHelper';
import ReportcenterFilterProductAndCategory from './ReportcenterFilterProductAndCategory';
import { type MinimalProduct } from 'App/resources/Model/Product';
import type Product from 'App/resources/Model/Product';
import type ProductCategory from 'App/resources/Model/ProductCategory';

interface Props {
	onChange: (type: keyof RCDashboardFilter, values: number[], comparison: string) => void;
	values: RCDashboardFilter;
	updateFilterHeight: () => void;
}

export const renderSelected = (
	values: RCDashboardFilter,
	returnCount: boolean = true,
	products: Readonly<MinimalProduct[]> = []
) => {
	let res = `${T('default.products')}: `;

	if (!returnCount) {
		const productNames = products
			.filter(product => {
				return values?.Product?.value?.includes?.(product.id);
			})
			.map(product => product.name);
		res += productNames.join(', ');
		return res;
	}

	const productLength = values?.Product?.value?.length || 0;
	const categoryLength = values?.ProductCategory?.value.length || 0;

	if (productLength || categoryLength) {
		const length = productLength + categoryLength;
		if (length >= 1) {
			res += `${length} ${T('default.selected').toLowerCase()}`;
		}
	}

	return res;
};

const ReportcenterFilterProduct = ({ onChange, ...props }: Props) => {
	const classes = new bemClass('ReportcenterFilterProduct');
	const cachedProducts = useMemo(() => Tools.AppService.getProducts().map(mapProductToMinimalProduct), []);
	const cachedCategories = Tools.AppService.getProductCategories();
	const isAsync = Tools.AppService.getTotals('products') > 4000;

	const [searchStr, setSearchStr] = useState('');
	const [products, setProducts] = useState<Readonly<MinimalProduct[]>>(cachedProducts);
	const [categories, setCategories] = useState<ProductCategory[]>(cachedCategories);
	const [selectedList, setSelectedList] = useState<'Products' | 'Product Categories'>(
		isAsync ? 'Product Categories' : 'Products'
	);
	const [selectedProducts, setSelectedProducts] = useState<MinimalProduct[]>([]);
	const [selectedCategories, setSelectedCategories] = useState<ProductCategory[]>([]);
	const [isOmittingInactive, setIsOmittingInactive] = useState<boolean>(false);

	const productValue = props.values?.Product?.value || [];
	const categoryValue = props.values?.ProductCategory?.value || [];

	const comparison = props.values?.Product?.comparison ?? 'eq';
	const isExclude = comparison === 'ne';

	const categoryComparison = props.values?.ProductCategory?.comparison ?? 'eq';
	const isCategoryExclude = categoryComparison === 'ne';

	const productArray = useMemo(
		() => buildArray(selectedProducts, products, searchStr),
		[selectedProducts, products, searchStr]
	);

	const categoryArray = useMemo(
		() => buildCategoryArray(selectedCategories, categories, searchStr),
		[selectedCategories, categories, searchStr]
	);

	const search = (query: string) => {
		if (selectedList === 'Product Categories') {
			setCategories(
				cachedCategories.filter(item => item.name?.toLowerCase().indexOf(query.toLowerCase()) !== -1)
			);
			return;
		}

		if (isAsync) {
			ProductResource.find({ name: `src:${query}`, sort: 'name', limit: 20 })
				.then((res: { data: Product[] }) => {
					setProducts(res.data.map(mapProductToMinimalProduct));
				})
				.catch((err: unknown) => logError(err));
		} else {
			setProducts(cachedProducts.filter(item => item.name?.toLowerCase().indexOf(query.toLowerCase()) !== -1));
		}
	};

	const updateSelectedProducts = () => {
		if (isAsync) {
			if (productValue.length) {
				const rb = new RequestBuilder();
				rb.addFilter(ProductAttributes.id, RequestBuilder.comparisonTypes.Equals, productValue);
				rb.addSort('name');
				ProductResource.find(rb.build())
					.then((res: { data: Product[] }) => {
						setSelectedProducts(
							res.data.map(mapProductToMinimalProduct).filter(p => productValue.includes(p.id))
						);
					})
					.catch((err: unknown) => logError(err));
			} else {
				setSelectedProducts([]);
			}
		} else {
			if (productValue.length) {
				setSelectedProducts(products.filter(p => productValue.includes(p.id)));
			} else {
				setSelectedProducts([]);
			}
		}
	};

	const updateSelectedCategories = () => {
		if (categoryValue.length) {
			setSelectedCategories(_.cloneDeep(cachedCategories.filter(c => categoryValue.includes(c.id))));
		} else {
			if (selectedCategories.length) {
				setSelectedCategories([]);
			}
		}
	};

	const toggleActive = () => {
		setIsOmittingInactive(!isOmittingInactive);
	};

	const getFilteredProducts = () => {
		return isOmittingInactive ? productArray?.filter(({ active }) => active) : productArray;
	};

	const ButtonSelectOptionOrder = () => {
		const productTitle = `${selectedProducts.length || ''} ${T(
			selectedProducts.length === 1 ? 'default.product' : 'default.products'
		)}`;
		const categoryTitle = `${selectedCategories.length || ''} ${T(
			selectedCategories.length === 1 ? 'default.productCategory' : 'default.productCategories'
		)}`;

		return isAsync
			? [
					{
						value: 'Product Categories',
						title: categoryTitle
					},
					{
						value: 'Products',
						title: productTitle
					}
			  ]
			: [
					{
						value: 'Products',
						title: productTitle
					},
					{
						value: 'Product Categories',
						title: categoryTitle
					}
			  ];
	};

	useEffect(() => {
		updateSelectedProducts();
		updateSelectedCategories();
		if (isAsync) {
			ProductCategoryResource.find()
				.then(({ data }) => {
					setCategories(data);
				})
				.catch(err => logError(err, 'Failed to find Product categories'));
		}
	}, []);

	useEffect(() => {
		updateSelectedProducts();
	}, [productValue, isOmittingInactive]);

	useEffect(() => {
		updateSelectedCategories();
	}, [categoryValue]);

	useEffect(() => {
		const timeout = setTimeout(() => search(searchStr), isAsync ? 300 : 0);
		return () => clearTimeout(timeout);
	}, [searchStr]);

	useEffect(() => {
		search('');
	}, [selectedList]);

	return (
		<ReportcenterFilter
			product
			className={classes.b()}
			renderSelected={() => renderSelected(props.values)}
			getSelectedNames={() => [
				...productArray.filter(p => productValue.includes(p.id)).map(p => p.name),
				...categoryArray.filter(c => categoryValue.includes(c.id)).map(c => c.name)
			]}
			icon="cube"
			placeholder={`${T('default.products')}: ${T('reportcenter.filter.notActive')}`}
			value={[...productValue, ...categoryValue]}
			resetFilter={() => {
				onChange('Product', [], 'eq');
				onChange('ProductCategory', [], 'eq');
			}}
			{...props}
		>
			<Block space="ptm prm pbm plm" backgroundColor="white" className={classes.elem('list-select').b()}>
				<Block>
					<ButtonSelect
						options={ButtonSelectOptionOrder()}
						value={selectedList}
						onChange={tab => {
							setSearchStr('');
							setSelectedList(tab);
						}}
					/>
				</Block>
				<Block space="mtm">
					<ReportcenterFilterSearchbar<number[]>
						setSearchStr={value => setSearchStr(value)}
						searchStr={searchStr}
						placeholder={`${T('default.search')} ${(selectedList === 'Products'
							? T('default.products').toLowerCase()
							: T('default.productCategories')
						).toLowerCase()}`}
						isExclude={selectedList === 'Products' ? isExclude : isCategoryExclude}
						onChange={onChange}
						field={selectedList === 'Products' ? 'Product' : 'ProductCategory'}
						value={selectedList === 'Products' ? productValue : categoryValue}
					/>
				</Block>
			</Block>
			{selectedList === 'Products' ? (
				<ReportcenterFilterProductAndCategory
					values={productValue}
					onChange={onChange}
					content={getFilteredProducts()}
					resetFilter={() => {
						onChange('Product', [], 'eq');
						setSelectedProducts([]);
					}}
					filterType="Product"
					comparison={comparison}
				/>
			) : (
				<ReportcenterFilterProductAndCategory
					values={categoryValue}
					onChange={onChange}
					content={categoryArray}
					resetFilter={() => {
						onChange('ProductCategory', [], 'eq');
						setSelectedCategories([]);
					}}
					filterType="ProductCategory"
					comparison={categoryComparison}
				/>
			)}
			<Block
				space="ptl prl pll"
				backgroundColor="grey-2"
				className={classes.elem('info').b()}
				border="ts"
				borderColor="grey-6"
			>
				<Text size="md" color="black">
					<Text color="bright-blue" onClick={toggleActive} className={classes.elem('ommit-inactive').b()}>
						{isOmittingInactive ? `${T('default.hiding')} ` : `${T('default.showing')} `}
					</Text>
					{T('admin.inactiveProducts').toLowerCase()}
				</Text>
			</Block>
		</ReportcenterFilter>
	);
};

export default ReportcenterFilterProduct;
