import React, { ReactNode } from 'react';
import Event from 'App/resources/Model/Event';
import BemClass from '@upsales/components/Utils/bemClass';
import TimelineIcon from '../TimelineIcon';
import './TimelineNoteBase.scss';
import { getTimelineIconProps } from 'Components/Helpers/getTimelineIconProps';
import BorderBox from 'App/components/BorderBox';
interface Props {
	content: ReactNode;
	isHistoryLog?: boolean;
	isLastElem: boolean;
	event: Event;
	noTimelineRowMargin?: boolean;
}
//This component helps you render timeline rows in drawers and company/contact card
/** @deprecated use TimelineGeneric with includeIcon flag in place of isHistoryLog */
const TimelineNoteBase = ({ content, isHistoryLog, isLastElem, event, noTimelineRowMargin }: Props) => {
	const classes = new BemClass('TimelineNoteBase');

	if (isHistoryLog) {
		return (
			<div className={classes.elem('history-log', 'TimelineRow').mod({ noTimelineRowMargin }).b()}>
				<TimelineIcon {...getTimelineIconProps(event)} hideLine={isLastElem} />
				<BorderBox boxShadow className={classes.elem('history-log-card', 'TimelineCard').b()}>
					{content}
				</BorderBox>
			</div>
		);
	}
	return (
		<>
			<table className={`${classes.b()} details-table`}>
				<tbody>
					<tr>
						<td style={{ padding: 0 }}>{content}</td>
					</tr>
				</tbody>
			</table>
			<div className="details-table-arrow" />
		</>
	);
};
export default TimelineNoteBase;
