export default {
	standard: [
		'whenever',
		'currentDay',
		'lastDay',
		'currentWeek',
		'lastWeek',
		'currentMonth',
		'lastMonth',
		'currentQuarter',
		'lastQuarter',
		'currentYear',
		'lastYear',
		'last7days',
		'last14days',
		'last30days',
		'custom'
	],
	future: [
		'whenever',
		'nextDay',
		'currentDay',
		'lastDay',
		'nextWeek',
		'currentWeek',
		'lastWeek',
		'nextMonth',
		'currentMonth',
		'lastMonth',
		'nextQuarter',
		'currentQuarter',
		'lastQuarter',
		'nextYear',
		'currentYear',
		'lastYear',
		'last7days',
		'last14days',
		'last30days',
		'custom'
	],
	past: [
		'whenever',
		'currentDay',
		'lastDay',
		'currentWeek',
		'lastWeek',
		'currentMonth',
		'lastMonth',
		'currentQuarter',
		'lastQuarter',
		'currentYear',
		'lastYear',
		'last7days',
		'last14days',
		'last30days',
		'custom'
	],
	activity: [
		'whenever',
		'last7days',
		'last14days',
		'last30days',
		'last90days',
		'next7days',
		'next30days',
		'next45days',
		'next90days',
		'custom'
	],
	order: [
		'whenever',
		'last7days',
		'last14days',
		'last30days',
		'last45days',
		'last90days',
		'prev6Month',
		'prev12Month',
		'prev18Month',
		'prev24Month',
		'custom'
	],
	phoneCall: [
		'whenever',
		'currentDay',
		'lastDay',
		'currentWeek',
		'lastWeek',
		'currentMonth',
		'lastMonth',
		'currentQuarter',
		'lastQuarter',
		'currentYear',
		'lastYear',
		'custom'
	]
};
