import {
	Card,
	Icon,
	Help,
	Text,
	Table,
	Select,
	Tooltip,
	TableRow,
	NumberInput,
	TableHeader,
	TableColumn,
	ButtonSelect,
	DrawerHeader,
	OutsideClick
} from '@upsales/components';
import { CancelablePromise, makeCancelable } from 'App/babel/helpers/promise';
import EditorHeaderButton from 'Components/EditorHeader/EditorHeaderButton';
import { DangerButton, ThirdButton } from '@upsales/components/Buttons';
import bemClass from '@upsales/components/Utils/bemClass';
import { SlideFade } from 'App/components/animations';
import React, { useEffect, useState } from 'react';
import { Quota } from 'App/resources/Model/Client';
import ClientResource from 'App/resources/Client';
import GenericQuota from 'Resources/GenericQuota';
import T from 'Components/Helpers/translate';
import { useSelector } from 'react-redux';
import { RootState } from 'Store/index';
import logError from 'Helpers/logError';
import { capitalize } from 'lodash';
import moment from 'moment';

import './EditClientTarget.scss';

type Props = {
	close: () => void;
	className: string;
	clientId: number;
	quotas: Quota[];
};

enum BudgetTypes {
	YEAR = 'year',
	QUARTER = 'quarter',
	MONTH = 'month'
}

type Currency = {
	title: string;
	rate: number;
	iso: string;
	id: number;
};

type PartialBy<T, K extends keyof T> = Omit<T, K> & Partial<Pick<T, K>>;
type PartialQuota = Pick<
	PartialBy<Quota, 'id'>,
	'id' | 'period' | 'quota' | 'currency' | 'currencyRate' | 'periodType'
>;

const getPeriodType = (type: BudgetTypes) =>
	({
		[BudgetTypes.YEAR]: 'yearly' as const,
		[BudgetTypes.QUARTER]: 'quarterly' as const,
		[BudgetTypes.MONTH]: 'monthly' as const
	}[type]);

const EditClientTarget = ({ close, className, quotas = [], clientId }: Props) => {
	const classes = new bemClass('EditClientTarget', className);

	const {
		customerCurrencies,
		defaultCurrency,
		params: { SalesModel, SalesModelOption }
	} = useSelector((state: RootState) => state.App?.metadata!);
	const hasRecurring = SalesModel === 'rr';
	const hasARR = hasRecurring && SalesModelOption === 'arr';
	const hasMRR = hasRecurring && SalesModelOption === 'mrr';
	const hasCM = SalesModel === 'cm';

	let currentQuotaType = 'sales';
	if (hasRecurring) {
		currentQuotaType = 'recurring';
	} else if (hasCM) {
		currentQuotaType = 'contributionMargin';
	}

	quotas = quotas.filter(q => q.type === currentQuotaType);

	const firstQuota = quotas[0];

	let stored = BudgetTypes.YEAR;
	if (firstQuota?.periodType === 'quarterly') {
		stored = BudgetTypes.QUARTER;
	} else if (firstQuota?.periodType === 'monthly') {
		stored = BudgetTypes.MONTH;
	}

	const [budgetType, setBudgetType] = useState<BudgetTypes>(stored);
	const [period, setPeriod] = useState<string>(moment().format('YYYY'));

	const [saving, setSaving] = useState<boolean>(false);
	const [canEdit, setCanEdit] = useState<boolean>(false);
	const [isDirty, setIsDirty] = useState<boolean>(!!quotas.length);
	const [showWarning, setShowWarning] = useState<boolean>(false);
	const [debounceBudgetType, setDebounceBudgetType] = useState<BudgetTypes | undefined>();

	const currencySelect: Currency[] = customerCurrencies
		.filter(c => c.active || c.iso === firstQuota?.currency)
		.map((c, i) => ({ ...c, id: i, title: c.iso }));

	const [currency, setCurrency] = useState<Currency>(
		firstQuota?.currency
			? {
					iso: firstQuota?.currency,
					title: firstQuota?.currency,
					rate: firstQuota?.currencyRate,
					id: currencySelect.find(c => c.iso === firstQuota.currency)?.id ?? 0
			  }
			: {
					...defaultCurrency,
					id: currencySelect.find(c => c.iso === defaultCurrency.iso)?.id ?? 0,
					title: defaultCurrency.iso
			  }
	);

	const [updatedQuotas, setUpdatedQuotas] = useState<PartialQuota[]>(
		quotas.map(q => ({ ...q, quota: q.quota * currency.rate }))
	);

	const changeCurrency = (c: Currency) => {
		setCurrency(c);
		setUpdatedQuotas(v => v.map(quota => ({ ...quota, currency: c.iso, currencyRate: c.rate })));
		setIsDirty(false);
	};

	useEffect(() => {
		let getPromise: CancelablePromise | undefined;
		const getClientAccess = () => {
			const { role, crm, support } = Tools.AppService.getSelf();
			const { administrator } = Tools.AppService.getSelf();
			const isSupportUser = !crm && support;
			if (administrator || (!role && !isSupportUser)) {
				setCanEdit(true);
				return;
			}

			getPromise = makeCancelable(GenericQuota.canEditClientQuota(clientId));

			getPromise.promise
				.then(({ data }) => setCanEdit(data))
				.catch(err => logError(err, 'Failed to get canEditClientQuota'));
		};

		getClientAccess();

		return () => {
			getPromise?.cancel?.();
		};
	}, []);

	const saveQuota = async () => {
		setSaving(true);
		try {
			await ClientResource.save({
				id: clientId,
				clientQuotas: updatedQuotas.map(q => ({ ...q, quota: q.quota * (1 / currency.rate) }))
			});
			setSaving(false);
			close();
		} catch (e) {
			setSaving(false);
			Tools.NotificationService.addNotification({
				style: Tools.NotificationService.style.ERROR,
				icon: 'times',
				title: 'default.error',
				body: 'saveError.settings'
			});
			logError(e, 'Failed to save quota');
		}
	};

	const updateMonthlyQuota = (value: number | undefined, month: string) => {
		if (value == null) return;
		setIsDirty(false);

		const idx = updatedQuotas.findIndex(
			q => moment(q.period).format('MMMM') === month && moment(q.period).format('YYYY') === period
		);

		if (idx >= 0) {
			updatedQuotas[idx].quota = value;
			setUpdatedQuotas(updatedQuotas);
		} else {
			setUpdatedQuotas(v => [
				...v,
				{
					period: moment(`${period}-${month}`, 'YYYY-MMMM').startOf('month').format('YYYY-MM-DD'),
					quota: value,
					currency: currency.iso,
					currencyRate: currency.rate,
					periodType: getPeriodType(budgetType)
				}
			]);
		}
	};

	const updateQuarterlyQuota = (value: number | undefined, quarter: number) => {
		if (value == null) return;
		setIsDirty(false);

		const idx = updatedQuotas.findIndex(
			q => moment(q.period).quarter() === quarter && moment(q.period).format('YYYY') === period
		);

		if (idx >= 0) {
			updatedQuotas[idx].quota = value;
			setUpdatedQuotas(updatedQuotas);
		} else {
			setUpdatedQuotas(v => [
				...v,
				{
					period: moment(`${period}-01`, 'YYYY-MM').quarter(quarter).format('YYYY-MM-DD'),
					quota: value,
					currency: currency.iso,
					currencyRate: currency.rate,
					periodType: getPeriodType(budgetType)
				}
			]);
		}
	};

	const updateYearly = (value: number | undefined) => {
		if (value == null) return;
		setIsDirty(false);

		const idx = updatedQuotas.findIndex(q => moment(q.period).format('YYYY') === period);

		if (idx >= 0) {
			updatedQuotas[idx].quota = value;
			setUpdatedQuotas(updatedQuotas);
		} else {
			setUpdatedQuotas(v => [
				...v,
				{
					period: moment(`${period}-01`, 'YYYY-MM').startOf('month').format('YYYY-MM-DD'),
					quota: value,
					currency: currency.iso,
					currencyRate: currency.rate,
					periodType: getPeriodType(budgetType)
				}
			]);
		}
	};

	const getBudgetInput = () => {
		switch (budgetType) {
			case BudgetTypes.YEAR: {
				const savedQuota = updatedQuotas.find(q => moment(q.period).format('YYYY') === period);
				return (
					<div className={classes.elem('help').b()}>
						<div>
							<Text bold>
								{T(
									hasARR
										? 'client.target.totalARRTarget'
										: hasMRR
										? 'client.target.totalMRRTarget'
										: 'client.target.totalTarget'
								)}
							</Text>
							<Help articleId={1447} sidebar />
						</div>
						<Tooltip title={T('default.missingRights')} disabled={canEdit}>
							<NumberInput
								onChange={v => updateYearly(v)}
								value={savedQuota?.quota ?? 0}
								currency={currency.iso}
								disabled={!canEdit}
								min={0}
							/>
						</Tooltip>
					</div>
				);
			}

			case BudgetTypes.MONTH: {
				return (
					<Table>
						<TableHeader
							columns={[
								{ title: T('client.target.monthsIn', { year: period }) },
								{
									title: (
										<div className={classes.elem('help').b()}>
											<Text size="sm" color="grey-11">
												{T(
													hasARR
														? 'client.target.ARRTarget'
														: hasMRR
														? 'client.target.MRRTarget'
														: 'client.target.target',
													{
														currency: currency.iso
													}
												)}
											</Text>
											<Help articleId={1447} sidebar />
										</div>
									)
								}
							]}
						/>
						{moment.months().map((month, i) => {
							const savedQuota = updatedQuotas.find(
								q =>
									moment(q.period).format('MMMM') === month &&
									moment(q.period).format('YYYY') === period
							);

							return (
								<TableRow key={'month' + i}>
									<TableColumn>
										<Text bold>{capitalize(month)}</Text>
									</TableColumn>
									<TableColumn>
										<Tooltip title={T('default.missingRights')} disabled={canEdit}>
											<NumberInput
												currency={currency.iso}
												min={0}
												value={savedQuota?.quota ?? 0}
												onChange={v => updateMonthlyQuota(v, month)}
												disabled={!canEdit}
											/>
										</Tooltip>
									</TableColumn>
								</TableRow>
							);
						})}
					</Table>
				);
			}

			case BudgetTypes.QUARTER: {
				return (
					<Table>
						<TableHeader
							columns={[
								{ title: T('client.target.quartersIn', { year: period }) },
								{
									title: (
										<div className={classes.elem('help').b()}>
											<Text size="sm" color="grey-11">
												{T(
													hasARR
														? 'client.target.ARRTarget'
														: hasMRR
														? 'client.target.MRRTarget'
														: 'client.target.target',
													{
														currency: currency.iso
													}
												)}
											</Text>
											<Help articleId={1447} sidebar />
										</div>
									)
								}
							]}
						/>
						{[
							{ quarterName: 'Q1', quarter: 1 },
							{ quarterName: 'Q2', quarter: 2 },
							{ quarterName: 'Q3', quarter: 3 },
							{ quarterName: 'Q4', quarter: 4 }
						].map(({ quarterName, quarter }, i) => {
							const savedQuota = updatedQuotas.find(
								q =>
									moment(q.period).quarter() === quarter && moment(q.period).format('YYYY') === period
							);

							return (
								<TableRow key={'quarter' + i}>
									<TableColumn>
										<Text bold>{T(`client.target.period.${quarterName}`)}</Text>
									</TableColumn>
									<TableColumn>
										<Tooltip title={T('default.missingRights')} disabled={canEdit}>
											<NumberInput
												min={0}
												currency={currency.iso}
												onChange={v => updateQuarterlyQuota(v, quarter)}
												value={savedQuota?.quota ?? 0}
												disabled={!canEdit}
											/>
										</Tooltip>
									</TableColumn>
								</TableRow>
							);
						})}
					</Table>
				);
			}
		}
	};

	const df = 'YYYY';
	const previousYear = moment().subtract(1, 'year').format(df);
	const currentYear = moment().format(df);
	const nextYear = moment().add(1, 'year').format(df);
	return (
		<div className={classes.b()}>
			<DrawerHeader onHide={close} title={T('client.target.salesTarget')}>
				<EditorHeaderButton
					title={T('default.abort')}
					onClick={close}
					supertitle={undefined}
					className={undefined}
					noIcon
					next={false}
				/>
				<EditorHeaderButton
					title={T('default.save')}
					supertitle={undefined}
					onClick={saveQuota}
					next
					icon={'check'}
					className={undefined}
					noIcon={false}
					loading={saving}
					disabled={isDirty || !canEdit}
				/>
			</DrawerHeader>
			<div className={classes.elem('body').b()}>
				<div className={classes.elem('options').b()}>
					<div className={classes.elem('options').elem('buttonSelects').b()}>
						<div>
							<Text bold>{T('client.target.setTargetsPer')}</Text>
							<ButtonSelect
								value={budgetType}
								onChange={v => {
									if (updatedQuotas.reduce((sum, q) => sum + q.quota, 0) === 0) {
										setBudgetType(v);
										setUpdatedQuotas([]);
									} else {
										setShowWarning(true);
										setDebounceBudgetType(v);
									}
								}}
								disabled={!canEdit}
								options={[
									{ value: BudgetTypes.YEAR, title: T('date.year') },
									{ value: BudgetTypes.QUARTER, title: T('date.quarter') },
									{ value: BudgetTypes.MONTH, title: T('date.month') }
								]}
							/>
							<SlideFade visible={showWarning}>
								<OutsideClick
									targetClass={classes.elem('warning').b()}
									outsideClick={() => setShowWarning(false)}
								>
									<Card className={classes.elem('warning').b()}>
										<div className={classes.elem('warning').elem('text').b()}>
											<Icon color="red" name="warning"></Icon>
											<Text>{T('client.target.clearTargets')}</Text>
										</div>
										<div>
											<DangerButton
												onClick={() => {
													if (debounceBudgetType) {
														setBudgetType(debounceBudgetType);
														setUpdatedQuotas([]);
														setShowWarning(false);
													}
												}}
											>
												{T('default.confirm')}
											</DangerButton>
											<ThirdButton onClick={() => setShowWarning(false)}>
												{T('cancel')}
											</ThirdButton>
										</div>
									</Card>
								</OutsideClick>
							</SlideFade>
						</div>

						<div>
							<Text bold>{T('client.target.timePeriod')}</Text>
							<ButtonSelect
								value={period}
								onChange={setPeriod}
								options={[
									{ value: previousYear, title: previousYear },
									{ value: currentYear, title: currentYear },
									{ value: nextYear, title: nextYear }
								]}
							/>
						</div>
					</div>
					<div className={classes.elem('currencySelect').b()}>
						<Text bold>{T('default.currency')}</Text>
						<Select<Currency>
							value={currency}
							onChange={changeCurrency}
							options={currencySelect}
							showSearch={false}
							anchor={document.querySelector('.EditClientTarget')}
							scrollContainer={document.querySelector('.EditClientTarget__body')}
							disabled={!canEdit}
						/>
					</div>
				</div>
				<div key={budgetType} className={classes.elem('budegetInputs').b()}>
					{getBudgetInput()}
				</div>
			</div>
		</div>
	);
};

export default EditClientTarget;
