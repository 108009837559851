import RequestBuilder, { comparisonTypes } from 'Resources/RequestBuilder';
import ContactResource from 'App/resources/Contact';
import { invalidRecipentCodes } from 'Helpers/parseBounceReason';

export const addInactivatedContactsFilter = (rb: RequestBuilder) => {
	rb.addFilter({ field: 'mailBounces' }, comparisonTypes.Term, ['hard_bounce']);
	rb.addFilter({ field: 'mail.type' }, comparisonTypes.Equals, 'out');
	rb.addFilter({ field: 'mail.event.value' }, comparisonTypes.Match, invalidRecipentCodes);
	rb.addFilter({ field: 'active' }, comparisonTypes.Equals, 1);
};

export const fetchInactivatedContacts = (rb: RequestBuilder, { sort }: { sort?: { field: string; asc: boolean } }) => {
	addInactivatedContactsFilter(rb);

	if (sort) {
		rb.addSort({ field: sort.field }, sort.asc);
	}
	return ContactResource.find(rb.build());
};

export const fetchOngoingInactivationJob = (rb: RequestBuilder) => {
	rb.addFilter({ field: 'action' }, comparisonTypes.Equals, 'multi-UpdateContact-Contact');
	rb.addFilter({ field: 'userIds.userId' }, comparisonTypes.Equals, 1);
	rb.addFilter({ field: 'status' }, comparisonTypes.NotEquals, '100');
	rb.addFilter({ field: 'status' }, comparisonTypes.NotEquals, '-1');

	const customerId = Tools.AppService.getCustomerId();

	return Tools.PushNotifications.customer(customerId).find(rb.build());
};

export const formatFetchedAmount = (amount: number | string) => {
	if (!amount) {
		return 0;
	}
	// Format every thousand with a space
	return amount.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ' ');
};
