import { RCStandardDashboard, dashboardSorter } from 'App/pages/Reportcenter/reportCenterHelpers';
import { AnyAction } from 'redux';
import { RCDashboard, RCDashboardExtended, RCDashboardWidget, RCDashboardFilter } from 'Resources/ReportDashboard';
import { RCWidgetData, RCWidgetMeta } from 'Resources/ReportWidget';
import { ReportSchedule } from 'App/resources/ReportSchedule';
import type { Datatypes } from 'Resources/ReportWidget';
import { IconName } from '@upsales/components';

export const RESET = '[Reportcenter] RESET';
export const SET_FETCHING_DASHBOARDS = '[Reportcenter] SET_FETCHING_DASHBOARDS';
export const SET_CREATING_DASHBOARD = '[Reportcenter] SET_CREATING_DASHBOARD';
export const SET_DASHBOARDS = '[Reportcenter] SET_DASHBOARDS';
export const SET_SELECTED_DASHBOARD = '[Reportcenter] SET_SELECTED_DASHBOARD';
export const SET_LOADING = '[Reportcenter] SET_LOADING';
export const SET_LOADING_DASHBOARD = '[Reportcenter] SET_LOADING_DASHBOARD';
export const SET_PAGE_ERROR = '[Reportcenter] SET_PAGE_ERROR';
export const SET_DASHBOARD_ERROR = '[Reportcenter] SET_DASHBOARD_ERROR';
export const SET_WIDGET_DATA = '[Reportcenter] SET_WIDGET_DATA';
export const SET_EDIT_WIDGET = '[Reportcenter] SET_EDIT_WIDGET';
export const SET_EDIT_WIDGET_DATA = '[Reportcenter] SET_EDIT_WIDGET_DATA';
export const SET_EDIT_WIDGET_CATEGORY = '[Reportcenter] SET_EDIT_WIDGET_CATEGORY';
export const SET_WIDGET_META = '[Reportcenter] SET_WIDGET_META';
export const SET_WIDGET_CATEGORIES = '[Reportcenter] SET_WIDGET_CATEGORIES';
export const SET_DASHBOARD_FILTERS = '[Reportcenter] SET_DASHBOARD_FILTERS';
export const SET_ACTIVE_DRILLDOWN = '[Reportcenter] SET_ACTIVE_DRILLDOWN';
export const SET_LOADING_DRILLDOWN = '[Reportcenter] SET_LOADING_DRILLDOWN';
export const SET_EDITING_DASHBOARD = '[Reportcenter] SET_EDITING_DASHBOARD';
export const SET_SELECTED_HASH = '[Reportcenter] SET_SELECTED_HASH';
export const SET_INITIAL_HASH = '[Reportcenter] SET_INITIAL_HASH';
export const RESET_EDIT_WIDGET_DATA = '[Reportcenter] RESET_EDIT_WIDGET_DATA';
export const SET_PENDING_DASHBOARD_SAVE = '[Reportcenter] SET_PENDING_DASHBOARD_SAVE';
export const SET_SELECTED_VIEW = '[Reportcenter] SET_SELECTED_VIEW';
export const SET_SELECTED_WIDGET_HASH = '[Reportcenter] SET_SELECTED_WIDGET_HASH';
export const SET_INITIAL_WIDGET_HASH = '[Reportcenter] SET_INITIAL_WIDGET_HASH';
export const SET_DELETING_DASHBOARD = '[Reportcenter] SET_DELETING_DASHBOARD';
export const SET_SCHEDULING = '[Reportcenter] SET_SCHEDULING';
export const SET_SCHEDULED_DASHBOARDS = '[Reportcenter] SET_SCHEDULED_DASHBOARDS';
export const SET_SCHEDULED_SELECTED_DASHBOARD = '[Reportcenter] SET_SCHEDULED_SELECTED_DASHBOARD';
export const SET_SCHEDULED_DASHBOARD_FILTERS = '[Reportcenter] SET_SCHEDULED_DASHBOARD_FILTERS';

export type ReportcenterState = {
	loading: boolean;
	fetchingDashboards: boolean;
	creatingDashboard: boolean;
	dashboards: RCDashboard[];
	selectedDashboard: ((RCDashboardExtended | RCStandardDashboard) & { usersAndRoles: object[] }) | null;
	selectedDashboardHash: string;
	initialDashboardHash: string;
	loadingDashboard: boolean;
	pageError: boolean;
	dashboardError: boolean;
	widgetData: { [k: string]: { data: RCWidgetData; loading: boolean; error: boolean } };
	widgetMeta: { [k: string]: RCWidgetMeta };
	widgetCategories: { [k: string]: { title: string; icon: IconName; widgets: string[]; sorting: number } };
	editWidget: RCDashboardWidget | null;
	editWidgetData: { data: RCWidgetData; loading: boolean };
	editWidgetCategory: string | null;
	dashboardFilters: RCDashboardFilter;
	activeDrilldown: Partial<{
		widgetId: number;
		key: string;
		keys: string[];
		link?: { attribute: string; entity: string };
		dashboardFilters: RCDashboardFilter;
		count: number;
		title?: string;
		subtitle?: string;
		columns: {
			attribute: string;
			title: string;
			link: { attribute: string; entity: string };
			placeholder?: string;
			dynamicTextPrefix?: string;
			dynamicPlaceholderPrefix?: string;
			sortType: string;
			format?: Datatypes;
			standardField?: string;
			standardFieldEntity?: string;
		}[];
		rows: any[];
		rowCount: number;
		exportEntity?: string;
	}>;
	loadingDrilldown: boolean;
	editingDashboard: boolean;
	pendingDashboardSave: boolean;
	selectedView: number | null;
	selectedWidgetHash: string;
	initialWidgetHash: string;
	deletingDashboard: boolean;
	scheduling: string | null;
	scheduledDashboards: ReportSchedule[];
	scheduledSelectedDashboard: ReportSchedule[];
	scheduledDashboardFilters: RCDashboardFilter;
};

export const initialState: ReportcenterState = {
	loading: true, // indicates if init is loading
	fetchingDashboards: true, // indicate if dashboard fetch is loading
	creatingDashboard: false, // indicate if dashboard is creating
	dashboards: [], // holds the available dashboards
	selectedDashboard: null, // holds the currently selected dashboard to view
	selectedDashboardHash: '', // holds the current state of the db. Used to check for unsaved changes
	initialDashboardHash: '', // holds the initial state of the db. Used to check for unsaved changes
	loadingDashboard: true, // indicate if current db is loading
	pageError: false, // indicate if page failed to init-load
	dashboardError: false, // indicate if db failed to load
	widgetData: {}, // indicate if db failed to load
	widgetMeta: {}, // holds metadata for all available widgets
	widgetCategories: {}, // holds information about widget categories for the new widget gallery
	editWidget: null, // holds the widgetConfig of the widget that is being edited
	editWidgetData: {
		data: {
			colors: {},
			enableDrilldown: false,
			currency: '',
			rows: [],
			total: { goal: 0, progress: 0, remaining: 0 },
			datatype: { goal: 'number', progress: 'number', remaining: 'number' },
			language: { goal: '', progress: '', remaining: '' },
			type: { name: '', family: '' },
			disableLegend: false,
			enablePercent: false,
			disableSecondaryGroupings: false
		},
		loading: true
	}, // holds the widgetData of the widget that is being edited
	editWidgetCategory: null, // holds the selected category in the new widget gallery
	dashboardFilters: {}, // holds the values of each db filter,
	activeDrilldown: {}, // holds data for the active drilldown
	loadingDrilldown: false,
	editingDashboard: false, // indicate if dashboard edit mode should be active
	pendingDashboardSave: false,
	selectedView: null, // the id of the currently selected dashboard view
	initialWidgetHash: '', // initial dashboard widgets
	selectedWidgetHash: '', // current dashboard widgets
	deletingDashboard: false, // indicate if dashboard deletion is in progress
	scheduling: null, // Indicate if scheduling is in progress
	scheduledDashboards: [], //Holds all scheduled mailing of dashboards,
	scheduledSelectedDashboard: [],
	scheduledDashboardFilters: {}
};

const ACTION_HANDLERS: { [key: string]: (s: ReportcenterState, a: AnyAction) => ReportcenterState } = {
	[RESET]: () => ({ ...initialState }),
	[SET_FETCHING_DASHBOARDS]: (state, { fetchingDashboards }) => ({ ...state, fetchingDashboards }),
	[SET_CREATING_DASHBOARD]: (state, { creatingDashboard }) => ({ ...state, creatingDashboard }),
	[SET_DASHBOARDS]: (state, { dashboards }) => ({ ...state, dashboards: dashboards.sort(dashboardSorter) }),
	[SET_SELECTED_DASHBOARD]: (state, { selectedDashboard }) => ({ ...state, selectedDashboard }),
	[SET_LOADING]: (state, { loading }) => ({ ...state, loading }),
	[SET_LOADING_DASHBOARD]: (state, { loadingDashboard }) => ({ ...state, loadingDashboard }),
	[SET_PAGE_ERROR]: (state, { pageError }) => ({ ...state, pageError }),
	[SET_DASHBOARD_ERROR]: (state, { dashboardError }) => ({ ...state, dashboardError }),
	[SET_WIDGET_DATA]: (state, { widgetData }) => ({ ...state, widgetData }),
	[SET_WIDGET_META]: (state, { widgetMeta }) => ({ ...state, widgetMeta }),
	[SET_WIDGET_CATEGORIES]: (state, { widgetCategories }) => ({ ...state, widgetCategories }),
	[SET_EDIT_WIDGET_DATA]: (state, { editWidgetData }) => ({ ...state, editWidgetData }),
	[SET_EDIT_WIDGET_CATEGORY]: (state, { editWidgetCategory }) => ({ ...state, editWidgetCategory }),
	[SET_DASHBOARD_FILTERS]: (state, { dashboardFilters }) => ({ ...state, dashboardFilters }),
	[SET_EDIT_WIDGET]: (state, { editWidget }) => ({ ...state, editWidget }),
	[SET_ACTIVE_DRILLDOWN]: (state, { activeDrilldown }) => ({ ...state, activeDrilldown }),
	[SET_LOADING_DRILLDOWN]: (state, { loadingDrilldown }) => ({ ...state, loadingDrilldown }),
	[SET_EDITING_DASHBOARD]: (state, { editingDashboard }) => ({ ...state, editingDashboard }),
	[SET_SELECTED_HASH]: (state, { selectedDashboardHash }) => ({ ...state, selectedDashboardHash }),
	[SET_INITIAL_HASH]: (state, { initialDashboardHash }) => ({ ...state, initialDashboardHash }),
	[RESET_EDIT_WIDGET_DATA]: state => ({ ...state, editWidgetData: initialState.editWidgetData }),
	[SET_PENDING_DASHBOARD_SAVE]: (state, { pendingDashboardSave }) => ({ ...state, pendingDashboardSave }),
	[SET_SELECTED_VIEW]: (state, { selectedView }) => ({ ...state, selectedView }),
	[SET_SELECTED_WIDGET_HASH]: (state, { selectedWidgetHash }) => ({ ...state, selectedWidgetHash }),
	[SET_INITIAL_WIDGET_HASH]: (state, { initialWidgetHash }) => ({ ...state, initialWidgetHash }),
	[SET_DELETING_DASHBOARD]: (state, { deletingDashboard }) => ({ ...state, deletingDashboard }),
	[SET_SCHEDULING]: (state, { scheduling }) => ({ ...state, scheduling }),
	[SET_SCHEDULED_DASHBOARDS]: (state, { scheduledDashboards }) => ({ ...state, scheduledDashboards }),
	[SET_SCHEDULED_SELECTED_DASHBOARD]: (state, { scheduledSelectedDashboard }) => ({
		...state,
		scheduledSelectedDashboard
	}),
	[SET_SCHEDULED_DASHBOARD_FILTERS]: (state, { scheduledDashboardFilters }) => ({
		...state,
		scheduledDashboardFilters
	})
};

export default (state = { ...initialState }, action: AnyAction): ReportcenterState => {
	const handler = ACTION_HANDLERS[action.type];
	return handler ? handler(state, action) : state;
};
