import React from 'react';
import T from 'Components/Helpers/translate';
import { Icon, IconName, Text } from '@upsales/components';
import BemClass from '@upsales/components/Utils/bemClass';

type Props = {
	type: string;
	errorCause?: string;
};
// This is designed to work for both email list (passes type and errorCause) and email campaign list (passes only type)
// Check with Pierre if we want Mail and MailCampaign to have the same functionality or not
const MailStatus: React.FC<Props> = ({ type, errorCause }) => {
	const statusTypesForMail: { [key: string]: string } = {
		out: 'SENT',
		in: 'RECEIVED',
		sch: 'SCHEDULED',
		cre: 'MISSING_CREDITS',
		pay: 'MISSING_PAYMENT',
		pro: 'PROCESSING',
		err: 'ERROR'
	};

	const icons: { [key: string]: { name: IconName; color: string } | null } = {
		SENT: { name: 'paper-plane', color: 'success-5' },
		ACTIVE: null,
		ARCHIVED: null,
		DEL_PROJECT: { name: 'ban', color: 'red' },
		DRAFT: { name: 'pencil', color: 'black' },
		ERROR: { name: 'warning', color: 'red' },
		MISSING_CREDITS: { name: 'warning', color: 'red' },
		MISSING_PAYMENT: { name: 'warning', color: 'red' },
		NO_CONTACTS: { name: 'user-slash', color: 'red' },
		NO_UNSUB: { name: 'ban', color: 'red' },
		SCHEDULED: { name: 'calendar', color: 'bright-orange' },
		RECEIVED: { name: 'envelope-sync', color: 'black' },
		PROCESSING: { name: 'refresh', color: 'grey-8' }
	};

	const statusType = statusTypesForMail[type] ?? type;
	const icon = icons[statusType];

	let text: string = T('mail.status.' + statusType);

	if (statusType === 'NO_UNSUB') {
		text = T('mail.notSentMissingUnsub');
	} else if (statusType === 'ERROR' && errorCause) {
		text = T('event.' + errorCause);
	}

	const classes = new BemClass('MailStatus');
	return (
		<div className={classes.b()}>
			<Text size="sm">
				{icon ? <Icon name={icon.name} color={icon.color} space="mrm" /> : null}
				{text}
			</Text>
		</div>
	);
};

export default MailStatus;
