import Tracker from './Tracker';

const socialEventTracker = new Tracker('socialEvent', {
	CREATE_EVENT: 'create',
	CANCEL_EVENT: 'cancel',
	ADD_INFO: 'addInfo',
	ADD_CONTACTS: 'addContacts',
	ADD_CONTACTS_AFTER_LAUNCH: 'addContactsAfterLaunch',
	ADD_RESOURCES: 'addResources',
	LAUNCH_EVENT: 'launchEvent'
});

export default socialEventTracker;
