import React, { useState, useEffect } from 'react';
import { get } from 'lodash';
import moment from 'moment';
import { Text, Link, Tooltip } from '@upsales/components';
import TimelineRow from 'Components/TimelineRow';
import {
	onSourceClick,
	getSourceEntity,
	getSourceResource,
	getSourceName,
	TYPES
} from 'Components/Helpers/SourceHelper';
import logError from 'App/babel/helpers/logError';

const ActivityTimelineCreated = ({ event, hideIcon }) => {
	const t = Tools.$translate;
	const { data, user, source, isPhoneCall } = event || {};
	const date = get(data, 'regDate');

	const [title, setTitle] = useState(<Text loading />);
	const createdEntity = isPhoneCall ? 'activity.outcomeLog.createdPhoneCall' : 'activity.outcomeLog.createdActivity';
	const createdEntityFromSource = t(
		isPhoneCall ? 'activity.outcomeLog.createdPhoneCallFromSource' : 'activity.outcomeLog.createdActivityFromSource'
	);
	const entityWasCreated = isPhoneCall
		? t('activity.outcomeLog.phoneCallWasCreated')
		: t('activity.outcomeLog.activityWasCreated');

	const setTitleWhenEntityHasSource = (data, source) => {
		const { administrator, userParams } = Tools.AppService.getSelf();
		const sourceName =
			administrator || userParams.mailAdmin ? (
				<Link onClick={() => onSourceClick(source, data)}>{getSourceName(source, data)}</Link>
			) : (
				<Tooltip title={t('entity.missingAccess')}>{` ${getSourceName(source, data)}`}</Tooltip>
			);
		const titleText =
			source.type === TYPES.SUBSCRIPTION_REMINDER
				? `${t(createdEntity, { user: t('default.subscriptionReminders') })} ${t('default.for').toLowerCase()}`
				: `${createdEntityFromSource} ${getSourceEntity(source).toLowerCase()}`;
		setTitle(
			<Text>
				{titleText} {sourceName}
			</Text>
		);
	};

	useEffect(() => {
		const hasSource = source && source.type && source.id;
		const resource = getSourceResource(source);
		if (hasSource && resource) {
			resource
				.get(source.id)
				.then(({ data }) => {
					setTitleWhenEntityHasSource(data, source);
				})
				.catch(err => {
					if (get(err, 'status') === 404) {
						setTitle(
							<Text>
								{t('activity.outcomeLog.sourceEntityRemoved', { source: getSourceEntity(source) })}
							</Text>
						);
					} else if (get(err, 'status') === 403) {
						setTitle(
							<Text>
								{t('activity.outcomeLog.sourceEntityAccessDenied', {
									source: getSourceEntity(source).toLowerCase()
								})}
							</Text>
						);
					} else {
						logError(err, `Failed to get source resource ${source.type} with id ${source.id}`);
					}
				});
		} else if (hasSource && source.type === TYPES.ASSIGN) {
			setTitle(<Text>{`${get(user, 'name')} ${t('activity.wasAssigned').toLowerCase()}`}</Text>);
		} else if (source?.type === TYPES.REMINDER) {
			setTitle(<Text>{t(createdEntity, { user: t('default.reminders') })}</Text>);
		} else if (user) {
			setTitle(<Text>{t(createdEntity, { user: get(user, 'name') })}</Text>);
		} else {
			setTitle(<Text>{entityWasCreated}</Text>);
		}
	}, [source]);

	const subTitle = date ? (
		<Text size="sm" color="grey-11">
			{moment(date).format('L LT')}
		</Text>
	) : null;
	const subSection = null;
	return (
		<TimelineRow
			icon={hideIcon ? undefined : 'plus'}
			hideLine={true}
			event={event}
			title={title}
			subTitle={subTitle}
			subSection={subSection}
		/>
	);
};

export default ActivityTimelineCreated;
