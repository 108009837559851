import { findIndex } from 'lodash';
export const RESET_STATE = '[Appointments] RESET_STATE';
export const OTHER_EVENTS_FETCH_SUCCESS = '[Appointments] OTHER_EVENTS_FETCH_SUCCESS';
export const EVENTS_FETCH_SUCCESS = '[Appointments] EVENTS_FETCH_SUCCESS';
export const EVENTS_FETCH_ERROR = '[Appointments] EVENTS_FETCH_ERROR';
export const APPOINTMENTS_FETCH_SUCCESS = '[Appointments] APPOINTMENTS_FETCH_SUCCESS';
export const APPOINTMENTS_FETCH_ERROR = '[Appointments] APPOINTMENTS_FETCH_ERROR';
export const APPOINTMENT_ADDED = '[Appointments] APPOINTMENT_ADDED';
export const APPOINTMENT_UPDATED = '[Appointments] APPOINTMENT_UPDATED';
export const APPOINTMENT_DELETED = '[Appointments] APPOINTMENT_DELETED';

export const initialState = {
	events: [],
	otherEvents: [],
	eventsError: null,
	appointments: [],
	appointmentsError: null
};

const ACTION_HANDLERS = {
	[RESET_STATE]: () => ({ ...initialState }),
	[EVENTS_FETCH_SUCCESS]: (state, action) => ({ ...state, events: action.value }),
	[OTHER_EVENTS_FETCH_SUCCESS]: (state, action) => ({ ...state, otherEvents: action.value }),
	[EVENTS_FETCH_ERROR]: (state, action) => ({ ...state, eventsError: action.value }),
	[APPOINTMENTS_FETCH_SUCCESS]: (state, action) => ({ ...state, appointments: action.value }),
	[APPOINTMENTS_FETCH_ERROR]: (state, action) => ({ ...state, appointmentsError: action.value }),
	[APPOINTMENT_ADDED]: (state, action) => ({ ...state, appointments: [...state.appointments, action.value] }),
	[APPOINTMENT_UPDATED]: (state, action) => {
		const indexToUpdate = findIndex(state.appointments, { id: action.value.id });
		return {
			...state,
			appointments: [
				...state.appointments.slice(0, indexToUpdate),
				action.value,
				...state.appointments.slice(indexToUpdate + 1, state.appointments.length)
			]
		};
	},
	[APPOINTMENT_DELETED]: (state, action) => {
		const indexToDelete = findIndex(state.appointments, { id: action.value.id });
		return {
			...state,
			appointments: [
				...state.appointments.slice(0, indexToDelete),
				...state.appointments.slice(indexToDelete + 1, state.appointments.length)
			]
		};
	}
};

export default (state = initialState, action) => {
	const handler = ACTION_HANDLERS[action.type];
	return handler ? handler(state, action) : state;
};
