import React from 'react';
import BEM from '@upsales/components/Utils/bemClass';
import { Icon, Text } from '@upsales/components';
import T from 'Components/Helpers/translate';

import './InactiveLabel.scss';

const InactiveLabel = ({ onClick = _.noop }) => {
	const CSS = new BEM('InactiveLabel');

	return (
		<div className={CSS.b()} onClick={onClick}>
			<Text color="grey-10" size="sm">
				<Icon name="eye-slash" /> {T('default.inactive')}
			</Text>
		</div>
	);
};

export default InactiveLabel;

window.InactiveLabel = InactiveLabel;
