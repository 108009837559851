import Resource from './Resource';
import type CommentModel from '../../resources/Model/Comment';

class Comment extends Resource {
	eventName: 'comment';
	constructor() {
		super('comments');
		this.eventName = 'comment';
		this.notifications = {
			save: () => ({
				title: 'default.saved',
				body: 'saved.comment',
				style: 'success',
				icon: 'check',
				type: 'body'
			}),
			saveError: () => ({
				title: 'default.error',
				body: 'saveError.comment',
				style: 'error',
				icon: 'times',
				type: 'body'
			}),
			delete: () => ({
				title: 'default.deleted',
				body: 'deleted.comment',
				style: 'success',
				icon: 'check',
				type: 'body'
			}),
			deleteError: () => ({
				title: 'default.error',
				body: 'deleteError.comment',
				style: 'error',
				icon: 'times',
				type: 'body'
			})
		};
	}

	async save(data: Partial<CommentModel>, opts = {}) {
		opts = {
			skipEvent: true,
			...opts
		};

		return super.save(data, opts);
	}
}

export default new Comment();
