import { findIndex } from 'lodash';

export const replaceItem = <T = any>(arr: Readonly<T[]>, index: number, item: T) => [
	...arr.slice(0, index),
	item,
	...arr.slice(index + 1)
];

export const findAndReplaceItem = <T = any>(arr: Readonly<T[]>, item: T, predicate: any) => {
	const index = findIndex(arr, predicate);
	return index === -1 ? [...arr] : replaceItem(arr, index, item);
};

export const findAndReplaceOrInsertItem = <T = any>(arr: Readonly<T[]>, item: T, predicate: any) => {
	const index = findIndex(arr, predicate);

	if (index === -1) {
		return [...arr, item];
	} else {
		return replaceItem(arr, index, item);
	}
};

export const removeItem = <T = any>(arr: Readonly<T[]>, index: number) => [
	...arr.slice(0, index),
	...arr.slice(index + 1)
];

export const findAndRemoveItem = <T = any>(arr: Readonly<T[]>, predicate: any) => {
	const index = findIndex(arr, predicate);
	return index === -1 ? [...arr] : removeItem(arr, index);
};

export const rotateByOffset = <T = any>(arr: T[], offset: number) => {
	const length = arr.length;
	const cutoff = (length + offset) % length;
	const firstPart = arr.slice(cutoff, length);
	const secondPart = arr.slice(0, cutoff);
	return [...firstPart, ...secondPart];
};

export const insertAfter = <T = any>(arr: T[], afterWhatToInsert: T, whatToInsert: T) => {
	const index = arr.indexOf(afterWhatToInsert);
	arr.splice(index + 1, 0, whatToInsert);
};

export type TreeItem<T extends { parentId: number; id: number }> = T & { children: TreeItem<T>[] };
export function buildTreeFromArrayWithParentId<T extends { parentId: number; id: number }>(
	array: T[],
	modifier = (i: T) => i
) {
	type ResType = TreeItem<ReturnType<typeof modifier>>;
	const res: ResType[] = [];

	// Build map to use when building the tree
	const categoryMap = array.reduce<Record<number, ResType>>((map, item) => {
		const catCopy = { ...modifier(item), children: [] } as ResType;
		map[item.id] = catCopy;
		res.push(catCopy);
		return map;
	}, {});

	// Iterate over the categories and build the tree using the map to find the parents
	res.forEach(item => {
		const parent = categoryMap[item.parentId];
		if (parent) {
			parent.children.push(item);
		}
	});
	// Return the root categories (that now have children)
	return res.filter(item => !item.parentId);
}
