import React from 'react';
import { Text, Link } from '@upsales/components';
import moment from 'moment';
import TimelineRow from '../TimelineRow';
import T from 'Components/Helpers/translate';
import { VisitEvent } from 'App/babel/propTypes/EventShape';

const getContactHref = id =>
	Tools.$state.href('contact.dashboard', {
		id,
		customerId: Tools.AppService.getCustomerId()
	});

const TimelineVisit = ({ event }) => {
	const { date, contacts = [], score } = event || {};

	const title = (
		<Text>
			{!(contacts && contacts.length) ? (
				T('event.uknown')
			) : (
				<Link href={getContactHref(event.contacts[0].id)}>{event.contacts[0].name}</Link>
			)}
			{` ${T('event.visited').toLowerCase()}`}
			<b>{` (${score || 0}p)`}</b>
		</Text>
	);
	const subTitle = date ? (
		<Text size="sm" color="grey-11">
			{moment(date).format('L LT')}
		</Text>
	) : null;
	return (
		<TimelineRow icon="globe" hasAvatar={false} event={event} title={title} subTitle={subTitle} subSection={null} />
	);
};

TimelineVisit.propTypes = {
	event: VisitEvent.isRequired
};

export default TimelineVisit;
