import React from 'react';
import ReportcenterFilter from '../ReportcenterFilter';
import bemClass from '@upsales/components/Utils/bemClass';
import T from 'Components/Helpers/translate';
import { titles as filterTitles } from '../../../reportCenterHelpers';
import { RCDFilterCustomDate } from 'Resources/ReportDashboard';
import ReportcenterFilterDateSelect from './ReportcenterFilterDateSelect';
import './ReportcenterFilterDate.scss';

import moment from 'moment';
import { presetDateMap, formatPeriod, monthNames } from './ReportcenterFilterDateHelpers';
import { IconName } from '@upsales/components';

const titles = filterTitles.date;

const dynamicDateName = (preset: string) => {
	const date: Date | undefined = formatPeriod(preset)[0];
	if (!presetDateMap[preset] || !date) {
		return '';
	}
	const type = presetDateMap[preset][0];
	return type === 'week'
		? ` (${T('date.weekUnit')}.${moment(date).isoWeek()})`
		: type === 'month'
		? ` (${T(monthNames[moment(date).month()])})`
		: type === 'quarter'
		? ` (Q${moment(date).quarter()})`
		: type === 'year'
		? ` (${moment(date).year()})`
		: '';
};

export const renderSelected = (preset: string) => {
	return `${T('default.date')}: ${T(titles[preset])}${dynamicDateName(preset)}`;
};

type Props = {
	onChange: (type: string, value: string | object | null, skipFetch?: boolean) => void;
	values: {
		Date?: {
			value: string;
		};
		CustomDate?: { value: RCDFilterCustomDate };
	};
	updateFilterHeight: () => void;
	isDescription?: boolean;
	showingAllFilters?: boolean;
	disableFutureDates?: boolean;
};
const ReportcenterFilterDate = ({ onChange, isDescription, showingAllFilters, ...props }: Props) => {
	const classes = new bemClass('ReportcenterFilterDate');
	const value = props.values.Date?.value ?? '';
	const customDate = props.values.CustomDate?.value;

	return isDescription && showingAllFilters && props.values.Date ? null : (
		<ReportcenterFilter
			isDescription={isDescription}
			className={classes.b()}
			renderSelected={() => renderSelected(value)}
			icon={(isDescription ? '' : 'calendar') as IconName}
			placeholder={`${T('default.date')}: ${T('reportcenter.filter.notActive')}`}
			value={value}
			resetFilter={() => {
				onChange('Date', null, true);
				onChange('CustomDate', null);
			}}
			{...props}
		>
			<ReportcenterFilterDateSelect onChange={onChange} value={value} customDate={customDate} {...props} />
		</ReportcenterFilter>
	);
};

export default ReportcenterFilterDate;
