import React, { useMemo } from 'react';
import T from 'Components/Helpers/translate';
import CallListSelect from 'Components/Inputs/CallListSelect';
import logError from 'App/babel/helpers/logError';
import CallListResource from 'Resources/CallList';
import ToggleSelect from '../ToggleSelect';

const createCallList = async (name: string) => {
	try {
		const { data: savedCallList } = await CallListResource.save({
			name,
			users: [{ id: Tools.AppService.getSelf().id }]
		});
		return savedCallList.id;
	} catch (error) {
		logError(error, 'Failed to create campaign');
		return null;
	}
};

type Props = Omit<React.ComponentProps<typeof ToggleSelect<number>>, 'children' | 'lang' | 'onCreateNew'> & {
	addSingleCall?: boolean;
	disabled?: boolean;
	anchor?: HTMLDivElement | null;
};

const PlanPhonecallsDrawerCallListSelect = (props: Props) => {
	const lang = useMemo(
		() => ({
			addTo: T('todo.addToCallList'),
			addToSingle: T('todo.addToCallListSingle'),
			placeIn: T('todo.placePhonecallsInList'),
			placeInSingle: T('todo.placePhonecallInList'),
			createNewObject: T('todo.createNewCallList'),
			nameNewObject: T('callList.nameYourList'),
			saveNewObject: T('callList.saveList')
		}),
		[]
	);

	const { addSingleCall, disabled = false, ...toggleSelectProps } = props;
	const { value, onChange } = toggleSelectProps;

	return (
		<ToggleSelect
			lang={lang}
			onCreateNew={createCallList}
			addSingle={addSingleCall}
			toggleIcon={'phone'}
			disabled={disabled}
			{...toggleSelectProps}
		>
			<CallListSelect
				disabled={disabled}
				placeholder={T('todo.selectCallList')}
				onChange={onChange}
				value={value}
				anchor={props.anchor ?? null}
			/>
		</ToggleSelect>
	);
};

export default PlanPhonecallsDrawerCallListSelect;
