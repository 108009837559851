import type { ModalConfigName } from 'App/services/Modal/modalConfigs';
import type { OpenModalProps } from 'App/services/Modal/Modal';

/**
 * Will open a drawer by name. props will be passed to the component.
 * If opts are passed they will merge and override the drawers default opts
 */

export const openDrawer: <Name extends ModalConfigName>(name: Name, props?: OpenModalProps<Name>) => void = (
	name,
	props
) => {
	const openModal = require('App/services/Modal').default; // window.Tools = {}; must be fixed in index.html
	openModal(name, props ?? {});
};
