import Filter from './Filter';
import { Equals, NotEquals, EqualsAll } from 'Resources/ComparisonTypes';
import DatePreset from 'App/upsales/common/services/datePresetService';

class SocialEventFilter extends Filter {
	type = 'raw';
	title = 'default.socialEvents';
	displayType = 'socialEvent';

	constructor(opts = {}) {
		super('SocialEvent');
		// Set options on filter
		Object.keys(opts).forEach(o => (this[o] = opts[o]));
	}

	getText(filter) {
		return Object.keys(filter.value).reduce((out, f) => {
			if (f === 'Date') {
				const value = filter.value[f].value;
				return value.preset !== 'whenever' || value.start || value.end ? ++out : out;
			} else {
				return filter.value[f].value ? ++out : out;
			}
		}, 0);
	}

	isInactive(filter) {
		return this.getText(filter) === 0;
	}

	toUrl(filter) {
		return {
			seid: filter.value.SocialEvent.value,
			sec: filter.value.SocialEvent.comparisonType,
			cs: filter.value.ContactStatus.value,
			sed: filter.value.Date?.value
		};
	}

	fromUrl(rawFilter) {
		return {
			value: {
				SocialEvent: { value: rawFilter.seid, comparisonType: rawFilter.sec || Equals },
				ContactStatus: { value: rawFilter.cs },
				Date: { value: rawFilter.sed || { start: null, end: null, preset: 'whenever' } }
			}
		};
	}

	generate() {
		return {
			value: {
				SocialEvent: { value: null, comparisonType: Equals },
				ContactStatus: { value: null },
				Date: { value: { start: null, end: null, preset: 'whenever' } }
			}
		};
	}

	build(filter, requestBuilder, getField, useTags) {
		function addFilters(value, comparisonType) {
			const rb = requestBuilder.groupBuilder();

			if (filter.value.SocialEvent.value) {
				rb.addFilter(getField('socialEvent.id'), comparisonType, value);
			}

			if (filter.value.ContactStatus.value) {
				rb.addFilter(getField('socialEvent.status'), Equals, filter.value.ContactStatus.value);
			}

			if (filter.value.Date.value.preset !== 'whenever') {
				const dates = DatePreset.getDatesFromValue(filter.value.Date.value, useTags);

				// start
				if (dates.start) {
					rb.addFilter(getField('socialEvent.regDate'), rb.comparisonTypes.GreaterThanEquals, dates.start);
				}

				// end
				if (dates.end) {
					rb.addFilter(getField('socialEvent.regDate'), rb.comparisonTypes.LowerThanEquals, dates.end);
				}
			}

			if (comparisonType === NotEquals) {
				rb.isNotFilter();
			}

			rb.done();
		}

		if (this.isInactive(filter) === false) {
			const comparisonType = filter.value.SocialEvent.comparisonType || Equals;

			if (comparisonType === EqualsAll) {
				for (const value of filter.value.SocialEvent.value) {
					addFilters([value], Equals);
				}
			} else {
				addFilters(filter.value.SocialEvent.value, comparisonType);
			}
		}
	}
}

export default SocialEventFilter;
