import moment from 'moment';
import Order from 'App/resources/Model/Order';
import Activity from 'App/resources/Model/Activity';
import Appointment from 'App/resources/Model/Appointment';

import { getAttendingStr } from 'App/helpers/getAttendingStr';
import { currencyFormat } from 'Components/Filters/Currencies';
import { containsPhoneWords } from 'App/babel/helpers/phoneWords';
import Relation, { RelationContact } from 'App/resources/Model/EntityRelation';

export const opportunityRelationMapper = (obj: Order, contact?: RelationContact): Relation | null => {
	if (!obj) {
		return null;
	}

	const masterCurrency =
		Tools.AppService.getMetadata().customerCurrencies.find(c => !!c.masterCurrency)?.iso || 'SEK';
	return {
		type: 'opportunity',
		id: obj.id,
		title: obj.description,
		subtitle: `${currencyFormat(obj.value, masterCurrency)} • ${moment(obj.date).format('YYYY-MM-DD')} • ${
			obj.stage.name
		}`,
		icon: obj.probability === 100 ? 'dollar' : 'opportunity',
		withContact: !!contact?.id && obj.contact?.id === contact?.id,
		date: obj.date
	};
};

export const appointmentRelationMapper = (
	obj: Appointment,
	userId: number,
	contact?: RelationContact
): Relation | null => {
	if (!obj) {
		return null;
	}

	return {
		type: 'appointment',
		id: obj.id,
		title: obj.description,
		subtitle: `${moment(obj.date).format('L LT')} • ${getAttendingStr(userId, obj.users, obj.contacts)}`,
		icon: 'calendar',
		withContact: !!(contact?.id && obj.contacts?.find(c => c.id === contact?.id)),
		date: obj.date
	};
};

export const activityRelationMapper = (obj: Activity, userId: number, contact?: RelationContact): Relation | null => {
	if (!obj) {
		return null;
	}

	const todoTypes = Tools.AppService.getTodoTypes();
	const isTodo = obj.activityType?.id === todoTypes.TODO.id;
	const isPhoneCall = obj.activityType?.id === todoTypes.PHONE_CALL.id;
	const activityType = isPhoneCall ? 'phonecall' : isTodo ? 'todo' : 'activity';

	return {
		type: 'activity',
		activityType,
		id: obj.id,
		title: obj.description,
		subtitle: obj.date
			? `${moment(obj.date).format(obj.time ? 'L LT' : 'L')} • ${getAttendingStr(
					userId,
					obj.users,
					obj.contacts
			  )}`
			: `${getAttendingStr(userId, obj.users, obj.contacts)}`,
		icon: containsPhoneWords(obj.activityType?.name) ? 'phone' : 'activity',
		withContact: !!(contact?.id && obj.contacts?.find(c => c.id === contact?.id)),
		date: obj.date
	};
};

const mapRelations = (
	opportunities: Order[],
	appointments: Appointment[],
	activities: Activity[],
	userId: number,
	contact?: RelationContact
) =>
	[
		...opportunities.map(rel => opportunityRelationMapper(rel, contact)),
		...appointments.map(rel => appointmentRelationMapper(rel, userId, contact)),
		...activities.map(rel => activityRelationMapper(rel, userId, contact))
	].filter(Boolean) as Relation[];

export default mapRelations;
