import { useMemo } from 'react';
import { SelectItem } from '@upsales/components/Utils/selectHelpers';
import { DynamicSelectProps } from './DynamicSelect';
import { StaticSelectProps } from './StaticSelect';
import T from 'Components/Helpers/translate';

export type CoreSelectItem<T = SelectItem> = SelectItem & Omit<T, 'id'>;

export const getDefaultLang = () => ({
	typeToSearch: T('select.typeToSearch'),
	noResults: T('default.noResults')
});

export const useSelectLanguage = (
	language: DynamicSelectProps<SelectItem>['language'] | StaticSelectProps<SelectItem>['language']
) => {
	return useMemo(() => {
		return { ...getDefaultLang(), ...language };
	}, []);
};
