import React from 'react';
import { Row, Block, Input, Toggle, Column, Text } from '@upsales/components';
import T from 'Components/Helpers/translate';
import { RCDashboardFilter } from 'Resources/ReportDashboard';
import BemClass from '@upsales/components/Utils/bemClass';

import './ReportcenterFilterSearchbar.scss';

type Props<T> = {
	setSearchStr: (value: string) => void;
	searchStr: string;
	placeholder: string;
	isExclude: boolean;
	onChange: (field: keyof RCDashboardFilter, value: T, comparison: string) => void;
	field: keyof RCDashboardFilter;
	value: T;
};

export default function ReportcenterFilterSearchbar<T>(props: Props<T>) {
	const classes = new BemClass('ReportcenterFilterSearchbar');
	return (
		<Row className={classes.b()}>
			<Column>
				<Block space="mrs">
					<Input
						onChange={e => props.setSearchStr(e.target.value)}
						value={props.searchStr}
						autofocus
						placeholder={props.placeholder}
					/>
				</Block>
			</Column>
			<Column className={classes.elem('ExcludeColumn').b()}>
				<Block className={classes.elem('ExcludeBlock').b()} space="mls">
					<Text color="grey-11" size="sm">
						<Toggle
							checked={props.isExclude}
							onChange={() => props.onChange(props.field, props.value, props.isExclude ? 'eq' : 'ne')}
						/>{' '}
						{T('filters.excludeSelected')}
					</Text>
				</Block>
			</Column>
		</Row>
	);
}
