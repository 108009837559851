import React, { useEffect, useRef, useState } from 'react';
import { connect, ConnectedProps } from 'react-redux';
import ReactDOM from 'react-dom';
import delayedUnmount from 'Components/Helpers/delayedUnmount';
import T from 'Components/Helpers/translate';
import { OUTCOME_TYPES } from 'App/babel/enum/activityOutcome';
import './FocusMode.scss';
import BemClass from '@upsales/components/Utils/bemClass';
import { Button, Column, Headline, Icon, Text, Row, Title } from '@upsales/components';
import { CancelablePromise, makeCancelable } from 'App/babel/helpers/promise';
import FocusModeTopList from './FocusModeTopList/FocusModeTopList';
import { RootState } from 'Store/index';

const mapStateToProps = ({ Todo }: RootState) => ({
	reportStats: Todo.reportStats,
	reportLoading: Todo.reportLoading,
	focusData: Todo.focus.data
});

const connector = connect(mapStateToProps, {});

type Props = ConnectedProps<typeof connector> & {
	isMounted: boolean;
	closing: boolean;
	closeDrawer: (e: React.MouseEvent) => {};
};

const FocusMode = (props: Props) => {
	const modalRoot = useRef(document.getElementsByClassName('Modals')[0]);
	const el = useRef(document.createElement('div'));

	const _ref = useRef(null);
	const _mountedPromise = useRef<CancelablePromise<null> | null>(null);

	const [mounted, setMounted] = useState(false);

	useEffect(() => {
		if (modalRoot.current) {
			el.current.className = 'Modals__modal Modals__modal--visible Modals__modal--active';
			modalRoot.current.appendChild(el.current);
		}
	}, [modalRoot.current]);

	useEffect(() => {
		document.getElementsByTagName('body')[0].style.overflow = 'hidden';

		_mountedPromise.current = makeCancelable(
			new Promise(r => {
				setTimeout(() => {
					setMounted(true);
					r(null);
				}, 0);
			})
		);

		return () => {
			_mountedPromise.current?.cancel();
			modalRoot.current?.removeChild(el.current);

			document.getElementsByTagName('body')[0].style.overflow = '';
		};
	}, []);

	const nrOfTodos = props.focusData?.filter(todo => todo.type === 'phonecall' && !todo.closeDate).length ?? 0;

	const { isMounted = true, closeDrawer, reportStats, reportLoading, closing } = props;
	const classes = new BemClass('FocusMode').mod({ open: isMounted && mounted && !closing });

	return ReactDOM.createPortal(
		<div className={classes.b()} ref={_ref}>
			<Row className={classes.elem('header').b()}>
				<Column className={classes.elem('header-remaining-calls').b()} align="left">
					<Text bold color="white" size="lg">
						{`${nrOfTodos} ${nrOfTodos > 1 ? T('todo.phoneCallsLeft') : T('todo.phoneCallLeft')}`}
					</Text>
				</Column>
				<Column align="right">
					<Button
						className={classes.elem('exit-button').b()}
						color="white"
						size="sm"
						onClick={e => {
							closeDrawer(e);
						}}
					>
						<Icon name="times" space="mrs" />
						{T('flash.close')}
					</Button>
				</Column>
			</Row>
			<Row className={classes.elem('content').b()} direction="column">
				<Row>
					<Column align="center">
						<Title bold size="lg" color="white">
							{T('activity.statistic.yourProgress')}
						</Title>
					</Column>
				</Row>
				<Row className={classes.elem('stat-row').b()}>
					<div className={classes.elem('total-outcomes').b()}>
						<Headline loading={reportLoading} color="white" size="lg">
							{reportStats.phoneCallsDone.total}
						</Headline>
						<Text color="white">{T('activity.statistic.topDials')}</Text>
					</div>
					<div className={classes.elem('call-stats').b()}>
						<Text color="grey-10">
							<Icon color="red" space="mlm mrs" name="phone-fail" />
							{reportStats.phoneCallsDone[OUTCOME_TYPES.NO_ANSWER] + ' '}
							{T('activity.outcome.statistic.notReached')}
						</Text>
						<Text color="grey-10">
							<Icon color="medium-green" space="mlm mrs" name="phone" />
							{reportStats.phoneCallsDone[OUTCOME_TYPES.ANSWER] + ' '}
							{T('activity.outcome.statistic.reached')}
						</Text>
					</div>
				</Row>
				<Row className={classes.elem('stat-row').mod('non-activities').b()}>
					<div>
						<Headline loading={reportLoading} color="white" size="md">
							{reportStats.appointmentsCreated}
						</Headline>
						<Text color="grey-10">{T('default.appointments')}</Text>
					</div>
					<div>
						<Headline loading={reportLoading} color="white" size="md">
							{reportStats.opportunitiesCreated}
						</Headline>
						<Text color="grey-10">{T('default.opportunities')}</Text>
					</div>
					<div>
						<Headline loading={reportLoading} color="white" size="md">
							{reportStats.ordersCreated}
						</Headline>
						<Text color="grey-10">{T('default.orders')}</Text>
					</div>
				</Row>
				<Row direction="column" className={classes.elem('topLists').b()}>
					<FocusModeTopList
						type="activity"
						title={T('activity.statistic.topDialsDay')}
						placeholderText={T('flash.noClosedActivitiesToDay')}
					/>
					<FocusModeTopList
						type="appointment"
						title={T('flash.closedAppointmentsToday')}
						placeholderText={T('flash.noClosedAppointments')}
					/>
				</Row>
			</Row>
		</div>,
		el.current
	);
};

export const detached = delayedUnmount(FocusMode);
export default connect(mapStateToProps, {})(delayedUnmount(FocusMode));
