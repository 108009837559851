import React from 'react';
import { ModalHeader, Modal, ModalContent, ModalControls, Icon } from '@upsales/components';
import T from 'Components/Helpers/translate';
import { ModalProps } from '../Modals/Modals';
import { DefaultButton, ThirdButton } from '@upsales/components/Buttons';
import bemClass from '@upsales/components/Utils/bemClass';
import './MailPreview.scss';

type Props = ModalProps & { html: string; isPlainText: boolean; onShare?: () => void; name?: string };

const MailPreview = ({ className, close, html, isPlainText, onShare, name }: Props) => {
	const classes = new bemClass('MailPreview', className);
	return (
		<Modal className={classes.b()} size="lg">
			<ModalHeader title={`${T('mail.preview')}${name ? `: ${name}` : ''}`} onClose={close} />
			<ModalContent className={isPlainText ? '' : 'ModalContent--no-padding'}>
				<div className="template-preview-fake-body reset" dangerouslySetInnerHTML={{ __html: html }} />
			</ModalContent>
			<ModalControls>
				{onShare ? (
					<DefaultButton
						onClick={() => {
							onShare();
							close();
						}}
					>
						<Icon name="share-alt" space="mrs" />
						{T('default.share')}
					</DefaultButton>
				) : null}
				<ThirdButton onClick={() => close()}>{T('default.abort')}</ThirdButton>
			</ModalControls>
		</Modal>
	);
};

export default MailPreview;
