import Attribute from './Attribute';
import ActivityAttributes from './ActivityAttributes';

const TodoAttributes = {
	id: ActivityAttributes.id,
	opportunity: ActivityAttributes.opportunity,
	client: ActivityAttributes.client,
	users: ActivityAttributes.users,
	contacts: ActivityAttributes.contacts,
	closeDate: ActivityAttributes.closeDate,
	date: ActivityAttributes.date,
	regDate: ActivityAttributes.regDate,
	description: ActivityAttributes.description,
	notes: ActivityAttributes.notes,
	time: ActivityAttributes.time,
	priority: ActivityAttributes.priority,
	custom: ActivityAttributes.custom,
	projectPlan: ActivityAttributes.projectPlan,
	hot: new Attribute({
		type: Attribute.types.Boolean,
		field: 'hot'
	}),
	location: new Attribute({
		type: Attribute.types.String,
		field: 'location'
	}),
	weblinkUrl: new Attribute({
		type: Attribute.types.String,
		field: 'weblinkUrl'
	}),
	type: new Attribute({
		type: Attribute.types.String,
		field: 'type'
	}),
	outcome: new Attribute({
		type: Attribute.types.String,
		field: 'outcome'
	}),
	parentActivityId: ActivityAttributes.parentActivityId,
	parentAppointmentId: ActivityAttributes.parentAppointmentId
};

export default TodoAttributes;
