import React from 'react';
import { RiskEvaluatedOpportunity } from 'App/resources/Model/Opportunity';
import SalesboardCard from '../SalesboardCard';
import { SalesboardCardConfig } from 'Store/reducers/SalesboardReducer';
import { SalesboardViewColumn } from 'App/resources/AllIWant';
import { salesBoardTracker } from 'App/babel/helpers/Tracker';
import { getOrderForecastingRisk } from 'App/enum/ForecastingRisks';

type Props = {
	order: RiskEvaluatedOpportunity;
	cardConfig: SalesboardCardConfig;
	onClick?: () => void;
	column?: SalesboardViewColumn;
};

const DealCard = ({ order, cardConfig, onClick, column }: Props) => {
	const openDeal = () => {
		const type = order.probability === 0 || order.probability === 100 ? 'order' : 'opportunity';
		const opts: { id: number; type: 'order' | 'opportunity'; initView?: string } = {
			id: order.id,
			type
		};

		if (type === 'opportunity') {
			opts.initView = 'dashboard';
		}

		const risk = getOrderForecastingRisk(order);
		const data = {
			type,
			risk: risk?.type ?? null,
			riskName: risk?.field ?? null,
			riskOccured: risk && (order.risks?.[risk.field] ?? null)
		};
		salesBoardTracker.track(salesBoardTracker.events.CLICKED_CARD, data);

		return Tools.$upModal.open('editOrder', opts);
	};

	const { field1, field2, field3, field4, showUser } = cardConfig;

	return (
		<SalesboardCard
			entity={order}
			onClick={onClick ?? openDeal}
			fields={{ top: field1, middleLeft: field2, middleRight: field3, bottomLeft: field4 }}
			showUser={showUser}
			column={column}
		/>
	);
};

export default DealCard;
