export const RESET = '[Goals] RESET';
export const SET_PINNED_WIDGETS = '[Goals] SET_PINNED_WIDGETS';
export const SET_USER_WIDGETS = '[Goals] SET_USER_WIDGETS';
export const SET_TEAM_WIDGETS = '[Goals] SET_TEAM_WIDGETS';
export const SET_SHOW_TEAM = '[Goals] SET_SHOW_TEAM';

export const initialState = {
	userWidgets: [],
	teamWIdgets: [],
	pinnedWidgets: [],
	showTeam: false
};

const ACTION_HANDLERS = {
	[RESET]: () => ({ ...initialState }),
	[SET_PINNED_WIDGETS]: (state, { pinnedWidgets }) => ({ ...state, pinnedWidgets }),
	[SET_USER_WIDGETS]: (state, { userWidgets }) => ({ ...state, userWidgets }),
	[SET_TEAM_WIDGETS]: (state, { teamWidgets }) => ({ ...state, teamWidgets }),
	[SET_SHOW_TEAM]: (state, { showTeam }) => ({ ...state, showTeam })
};

export default (state = initialState, action) => {
	const handler = ACTION_HANDLERS[action.type];
	return handler ? handler(state, action) : state;
};
