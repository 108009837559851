import { RESET, SET_LICENSES, NEXT_PAGE, SET_FIELD_VALUE, SET_LOADING, SET_ERROR } from '../reducers/CreateAccount';
import CreateAccount from '../../resources/CreateAccount';

export const reset = () => ({ type: RESET });
export const setLicenses = numLicenses => ({ type: SET_LICENSES, numLicenses });
export const nextPage = () => async (dispatch, getState) => {
	const lastPage = getState().CreateAccount.page;
	if (lastPage === 'userInfo') {
		const state = getState().CreateAccount;
		let timeout;
		try {
			// The createaccount will return an error quickly, but if it succeeds it takes some time
			dispatch({ type: SET_LOADING, value: true });

			timeout = setTimeout(() => {
				dispatch({ type: SET_LOADING, value: false });
				dispatch({ type: NEXT_PAGE });
			}, 3000);
			await CreateAccount.save({
				numLicenses: state.numLicenses,
				address: state.companyAddress,
				zipCode: state.companyZipCode,
				deliveryType: state.companyDeliveryType,
				city: state.companyCity,
				companyEmail: state.companyEmail,
				name: state.companyName,
				orgNo: state.companyOrgNo,
				contactName: state.name,
				password: state.password,
				phone: '',
				email: state.email
			});
		} catch (e) {
			if (e.response && e.response.data && e.response.data.error && e.response.data.error === 'emailIsTaken') {
				dispatch({ type: SET_ERROR, field: 'email', error: 'createAccount.userInfo.emailIsTaken' });
			}
			dispatch({ type: SET_LOADING, value: false });
			clearTimeout(timeout);
		}
	} else {
		dispatch({ type: NEXT_PAGE });
	}
};
export const setFieldValue = values => ({ type: SET_FIELD_VALUE, values });
