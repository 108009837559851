import T from 'Components/Helpers/translate';
import BemClass from '@upsales/components/Utils/bemClass';
import React, { useEffect } from 'react';
import './ReportcenterTVSettings.scss';
import { Button, DrawerHeader, Icon, Input, Label, NumberInput, Select, Text } from '@upsales/components';
import { RCDashboard } from 'Resources/ReportDashboard';

type Props = {
	className: string;
	close: () => void;
	channel: { name: string; interval: number; dashboards: any[] };
	dashboards: (RCDashboard & { dashboardId: string })[];
	save: (channel: any) => void;
};
const ReportcenterTVSettings = ({ className, close, channel, dashboards, save }: Props) => {
	const classes = new BemClass('ReportcenterTVSettings', className);
	const anchor = document.querySelector('.ReportcenterTVSettings');
	const [editChannel, setEditChannel] = React.useState(channel ?? { name: '', interval: 10, dashboards: [] });

	const lang = {
		title: channel ? T('reportcenter.tv.editChannel') : T('reportcenter.tv.createChannel'),
		cancel: T('default.cancel'),
		save: T('default.save'),
		name: T('default.name'),
		dashboards: T('looker.dashboards'),
		duration: T('reportcenter.tv.duration'),
		durationDesc: T('reportcenter.tv.durationDesc')
	};

	useEffect(() => {
		const handleEsc = (event: any) => {
			if (event.key === 'Escape') {
				close();
			}
		};
		window.addEventListener('keydown', handleEsc);
		return () => {
			window.removeEventListener('keydown', handleEsc);
		};
	}, []);

	return (
		<div className={classes.b()}>
			<DrawerHeader
				title={lang.title}
				subtitle={editChannel.name}
				onHide={() => {
					close();
				}}
			>
				<Button type="link" size="lg" onClick={close}>
					{lang.cancel}
				</Button>
				<Button
					size="lg"
					disabled={!editChannel.name?.length || !editChannel.dashboards.length || !editChannel.interval}
					onClick={() => {
						save(editChannel);
						close();
					}}
				>
					{lang.save} <Icon name="check" space="mlm" />
				</Button>
			</DrawerHeader>
			<div className={classes.elem('form').b()}>
				<div>
					<Label required>{lang.name}</Label>
					<Input
						value={editChannel.name}
						onChange={v => setEditChannel({ ...editChannel, name: v.target.value })}
					/>
				</div>
				<div>
					<Label required>{lang.dashboards}</Label>
					<Select
						multi
						value={
							editChannel.dashboards.map(({ dashboardId }) =>
								dashboards.find(db => db.dashboardId === dashboardId)
							) as any
						}
						options={dashboards}
						anchor={anchor}
						onChange={db =>
							setEditChannel({
								...editChannel,
								dashboards: [...editChannel.dashboards, { dashboardId: db.dashboardId }]
							})
						}
						onRemove={id =>
							setEditChannel({
								...editChannel,
								dashboards: editChannel.dashboards.filter((d: any) => d.dashboardId !== id.toString())
							})
						}
						onClear={() => setEditChannel({ ...editChannel, dashboards: [] })}
					/>
				</div>
				<div>
					<Label required>{lang.duration}</Label>
					<NumberInput
						min={10}
						value={editChannel.interval}
						onBlur={v => setEditChannel({ ...editChannel, interval: v ?? 10 })}
					/>
					<Text size="sm" color="grey-11">
						{lang.durationDesc}
					</Text>
				</div>
			</div>
		</div>
	);
};

export default ReportcenterTVSettings;
