import './TimelineForm.scss';

import React from 'react';
import { Text, Link } from '@upsales/components';
import moment from 'moment';
import TimelineRow from '../TimelineRow';
import T from 'Components/Helpers/translate';
import { FormEvent } from 'App/babel/propTypes/EventShape';
import BemClass from '@upsales/components/Utils/bemClass';

const TimelineForm = ({ event }) => {
	const classes = new BemClass('TimelineForm');

	const { contacts, client, form, date, score } = event || {};
	let formEvent;

	if (contacts && contacts.length) {
		formEvent = { ...event, users: [contacts[0]] };
	} else {
		formEvent = event;
	}

	const removedContact = contacts?.[0]?.id && !contacts?.[0]?.name;
	const removedForm = form && form.id && !form.title && !form.name;

	const title = (
		<React.Fragment>
			<Text>
				{removedContact ? (
					<Text className={classes.elem('inlineText').b()} italic color="grey-10">
						{T('event.form.removedContact')}
					</Text>
				) : contacts && contacts.length ? (
					<Link
						onClick={e => e.stopPropagation()}
						href={Tools.$state.href('contact.dashboard', {
							customerId: client.id,
							id: contacts[0].id
						})}
					>
						{contacts[0].name}
					</Link>
				) : (
					T('default.someone')
				)}
				{removedForm ? (
					<>
						{` ${T('event.form.filledOut')} `}
						<Text className={classes.elem('inlineText').b()} italic color="grey-10">{` ${T(
							'event.form.removed'
						)} `}</Text>
					</>
				) : (
					<>
						{` ${T('event.form.submited').toLowerCase()} `}
						{form && !removedForm ? (
							Tools.AppService.getSelf().administrator &&
							Tools.AppService.getSelf().userParams.mailAdmin ? (
								<Link onClick={e => e.stopPropagation()} href={`#/form-overview/${form.id}`}>
									{form.name}
								</Link>
							) : (
								form.name
							)
						) : (
							event.value || T('default.deleted').toLowerCase()
						)}
					</>
				)}
				{score ? <b>{` (${score}p)`}</b> : null}
			</Text>
		</React.Fragment>
	);
	const subTitle = date ? (
		<Text size="sm" color="grey-11">
			{moment(date).format('L LT')}
		</Text>
	) : null;
	return (
		<TimelineRow className={classes.b()} icon="file-text-o" event={formEvent} title={title} subTitle={subTitle} />
	);
};

TimelineForm.propTypes = {
	event: FormEvent.isRequired
};

export default TimelineForm;
