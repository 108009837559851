import React, { useEffect, useState } from 'react';
import Highcharts from 'highcharts';
import { AssistChip, Block, Icon } from '@upsales/components';
import BemClass from '@upsales/components/Utils/bemClass';
import T from 'Components/Helpers/translate';
import './ForecastingGraph.scss';
import colorMappings from '@upsales/components/Utils/colorMappings';
import { CurrencyFormat } from 'App/babel/utils/numberFormat';
import { ForecastData } from 'Resources/ReportWidget';

type salesColumn = {
	type: 'column';
	name: string;
	data: number[][];
	color: string;
};

type Props = {
	pace: ForecastData['total']['pace'];
	sales: ForecastData['total']['sales'];
	target: ForecastData['total']['target'];
	graphData: ForecastData['graph'];
	currentPeriod: string;
	currency: ForecastData['currency'];
};

const ForecastingGraph = ({ pace, sales, target, graphData, currentPeriod, currency }: Props) => {
	const classes = new BemClass('ForecastingGraph');
	const langType = T(`calendar.${graphData.period.unit}`);
	const salesModel = Tools.AppService.getMetadata().params.SalesModelOption;
	const cf = new CurrencyFormat(currency);
	const [showChip, setShowChip] = useState(true);
	const [chipPostition, setChipPosition] = useState({ budgetChip: 0, graphChip: { x: 0, y: 0 } });

	const shouldBePoint = ((target.value ?? 0) / graphData.period.total) * graphData.period.current;
	const aheadOfTarget = {
		value: 0,
		isAhead: false
	};
	const currSales = graphData.rows.find(r => r.id === graphData.period.current)?.totalSales || 0;
	let calcPoint = shouldBePoint;
	if (currSales > shouldBePoint) {
		calcPoint = currSales;
		aheadOfTarget.value = currSales - shouldBePoint;
		aheadOfTarget.isAhead = true;
	}

	const periodStartLang = currentPeriod.includes('Quarter')
		? T('forecasting.startOfQuarter')
		: currentPeriod.includes('Year')
		? T('forecasting.startOfYear')
		: T('forecasting.startOfMonth');
	const periodEndLang = currentPeriod.includes('Quarter')
		? T('forecasting.endOfQuarter')
		: currentPeriod.includes('Year')
		? T('forecasting.endOfYear')
		: T('forecasting.endOfMonth');

	const createCategories = () => {
		const categories: any = [];
		for (let i = 0; i < graphData.period.total; i++) {
			if (i === 0) {
				categories.push(periodStartLang);
			} else if (i === graphData.period.current) {
				categories.push(T('date.currentDay'));
			} else {
				categories.push('');
			}
		}
		categories.push(periodEndLang);

		return categories;
	};

	const convertGraphData = () => {
		const totalSalesData: number[][] = [[0, 0]];

		const salesColumns: salesColumn[] = [];
		const churnData: number[][] = [];
		const decreaseData: number[][] = [];
		const increaseData: number[][] = [];
		const newClientData: number[][] = [];
		const winBackData: number[][] = [];
		let currentSales = 0;

		graphData.rows.forEach(row => {
			salesColumns.push({
				type: 'column',
				name: `${langType} ${row.id}`,
				data: [[row.id, row.sales]],
				color: row.sales > 0 ? colorMappings.get('grey-7') : colorMappings.get('dark-red')
			});
			if (row.id <= graphData.period.current) {
				totalSalesData.push([row.id, row.totalSales]);
			}
			if (row.id === graphData.period.current) {
				currentSales = row.totalSales;
			}
			if (salesModel === 'mrr' || salesModel === 'arr') {
				churnData.push([row.id, row.churn || 0]);
				decreaseData.push([row.id, row.decrease || 0]);
				increaseData.push([row.id, row.increase || 0]);
				newClientData.push([row.id, row.newClient || 0]);
				winBackData.push([row.id, row.winBack || 0]);
			}
		});
		totalSalesData.push([graphData.period.current, currentSales]);
		const rrData =
			salesModel === 'mrr' || salesModel === 'arr'
				? [
						{
							type: 'line',
							data: newClientData,
							color: 'transparent',
							name: T('default.arrchange.NEW_CLIENT')
						},
						{
							type: 'line',
							data: increaseData,
							color: 'transparent',
							name: T('default.arrchange.INCREASE')
						},
						{
							type: 'line',
							data: winBackData,
							color: 'transparent',
							name: T('default.arrchange.WIN_BACK')
						},
						{
							type: 'line',
							data: decreaseData,
							color: 'transparent',
							name: T('default.arrchange.DECREASE')
						},
						{
							type: 'line',
							data: churnData,
							color: 'transparent',
							name: T('default.arrchange.LOST_CLIENT')
						}
				  ]
				: [];

		const seriesData = [
			...rrData,
			...salesColumns,
			{
				type: 'line',
				data: totalSalesData,
				color: colorMappings.get('medium-green'),
				name: T('report.totalSales'),
				marker: {
					fillColor: 'transparent',
					lineWidth: 0
				}
			},
			{
				type: 'line',
				data: [
					[graphData.period.current, currentSales],
					[graphData.period.total, pace.value]
				],
				color:
					pace.value >= (target.value ?? 0)
						? colorMappings.get('medium-blue')
						: colorMappings.get('dark-red'),
				lineWidth: 1,
				dashStyle: 'Dash',
				name: T('forecasting.withCurrentPace')
			},
			{
				type: 'line',
				data: [
					[0, 0],
					[graphData.period.total, target.value]
				],
				color: colorMappings.get('grey-10'),
				name: T('salesboard.quota'),
				lineWidth: 1
			},
			{
				data: [
					{
						x: graphData.period.current,
						y: shouldBePoint,
						marker: {
							radius: currentSales < shouldBePoint ? 3 : 0,
							symbol: 'circle'
						}
					}
				],
				color: 'black'
			}
		];

		return seriesData;
	};

	useEffect(() => {
		const seriesData: any = convertGraphData();
		const categories = createCategories();
		const tooltipAndData: any = {
			tooltip: {
				useHTML: true,
				backgroundColor: 'white',
				style: {
					color: 'black'
				},
				borderRadius: 4,
				borderColor: '#CAD2DD',
				hideDelay: 80,
				// series, key, y, x, point is dummy data for ts
				series: {
					name: ''
				},
				key: '',
				y: 1,
				x: 1,
				points: ['points'],
				formatter: function (): string {
					const pointLength = this.points.length;
					return this.points.reduce(
						function (s: string, point: any, index) {
							const value =
								point.y < 0
									? '<div style="color: #a31300;">' + cf.short(point.y) + '</div>'
									: '<div>' + cf.short(point.y) + '</div>';

							if (point.series.columnMetrics) {
								const week =
									'<div style="font-weight: 600; line-height: 18px; width: 200px; height: 22px; padding-top: 4px;">' +
									`${
										point.y !== 0
											? T('report.widget.change') + ' ' + point.series.name.toLowerCase()
											: point.series.name
									}` +
									'</div>';
								if (point.series.xData[0] === graphData.period.total) {
									return s;
								}
								if (point.y === 0) {
									return week + s;
								}
								return (
									week +
									s +
									'<div>' +
									'<div style="border-top: 1px solid #CAD2DD; width: 200px; height: 4px;"></div>' +
									'<div style="display: flex; flex-direccion: row; justify-content: space-between;">' +
									'<div>' +
									T('default.netAmount') +
									'</div>' +
									'<div>' +
									value +
									'</div>' +
									'</div>' +
									'</div>'
								);
							}
							if ((pointLength === 9 && index >= 7) || (index !== 6 && point.y === 0)) {
								return s;
							}
							if (index === 6 && point.series.xData[1] !== graphData.period.total) {
								return (
									'<div style="display: flex; flex-direction: row; justify-content: space-between; line-height: 18px; width: 200px; height: 22px; padding-top: 4px;">' +
									'<div style="display: flex; align-items: center;">' +
									'<div style="height: 8px; width: 8px; border-radius: 50%; background-color: #086471";></div>' +
									'<div style="padding-left: 4px;">' +
									point.series.name +
									'</div>' +
									'</div>' +
									value +
									'</div>' +
									s
								);
							}
							return (
								s +
								'<div style="display: flex; flex-direction: row; justify-content: space-between; line-height: 18px; width: 200px; height: 22px;">' +
								'<div>' +
								point.series.name +
								'</div>' +
								value +
								'</div>'
							);
						},
						pointLength === 2 || pointLength === 8 || (target.value === null && pointLength === 7)
							? '<div style="line-height: 18px; width: 200px; height: 22px; font-weight: 600; padding-top: 4px">' +
									this.x +
									'</div>'
							: ''
					);
				},
				shared: true
			},
			series: seriesData
		};
		if (salesModel !== 'mrr' && salesModel !== 'arr') {
			tooltipAndData.tooltip.formatter = function (): string {
				const pointLength = this.points.length;
				return this.points.reduce(
					function (s: string, point: any, index: number) {
						const value =
							point.y < 0
								? '<div style="color: #a31300;">' + cf.short(point.y) + '</div>'
								: '<div>' + cf.short(point.y) + '</div>';
						if (point.series.columnMetrics) {
							const week =
								'<div style="font-weight: 600; line-height: 18px; width: 200px; height: 22px; padding-top: 4px;">' +
								`${
									point.y !== 0
										? T('report.widget.change') + ' ' + point.series.name.toLowerCase()
										: point.series.name
								}` +
								'</div>';
							if (pointLength === 3) {
								return s;
							}
							if (point.y === 0) {
								return week + s;
							}
							return (
								week +
								s +
								'<div style="display: flex; flex-direccion: row; justify-content: space-between;">' +
								'<div>' +
								T('default.sales') +
								'</div>' +
								'<div>' +
								value +
								'</div>' +
								'</div>'
							);
						}
						if ((index >= 2 && pointLength === 4) || point.point.x === 0) {
							return s;
						}
						if (point.point.x === graphData.period.total) {
							return (
								s +
								'<div style="display: flex; flex-direction: row; justify-content: space-between; line-height: 18px; width: 200px; height: 22px; padding-top: 4px;">' +
								'<div>' +
								point.series.name +
								'</div>' +
								value +
								'</div>'
							);
						}
						return (
							'<div style="display: flex; flex-direction: row; justify-content: space-between; line-height: 18px; width: 200px; height: 22px; padding-top: 4px;">' +
							'<div style="display: flex; align-items: center;">' +
							'<div style="height: 8px; width: 8px; border-radius: 50%; background-color: #086471";></div>' +
							'<div style="padding-left: 4px;">' +
							point.series.name +
							'</div>' +
							'</div>' +
							value +
							'</div>' +
							s
						);
					},
					this.x.length > 0 && pointLength !== 4
						? '<div style=" line-height: 18px; width: 200px; height: 22px; font-weight: 600; padding-top: 4px">' +
								this.x +
								'</div>'
						: ''
				);
			};
		}
		const chart: any = Highcharts.chart('forecastGraph', {
			credits: { enabled: false },
			...tooltipAndData,
			chart: {
				height: 320
			},
			title: { text: '' },

			legend: {
				enabled: false
			},
			yAxis: {
				gridLineColor: 'transparent',
				title: {
					text: ''
				},
				plotLines: [
					{
						color: '#000000',
						width: 1.2,
						value: 0
					}
				],
				labels: {
					formatter: function () {
						//@ts-ignore
						return cf.short(this.value);
					}
				}
			},
			xAxis: {
				tickWidth: 0,
				lineColor: 'black',
				categories: categories,
				labels: {
					rotation: 0,
					y: 20,
					style: { fontSize: '12px' }
				},
				tickPositions:
					graphData.period.current > graphData.period.total * 0.85
						? [0, graphData.period.current]
						: graphData.period.current < graphData.period.total * 0.08
						? [graphData.period.current, graphData.period.total]
						: [0, graphData.period.current, graphData.period.total],
				title: {
					text: `(${langType} ${graphData.period.current} of ${graphData.period.total})`,
					style: {
						color: 'grey'
					}
				},
				plotLines: [
					{
						color: 'grey',
						width: 2,
						value: graphData.period.total
					}
				]
			},
			plotOptions: {
				area: {
					color: 'transparent'
				},
				column: {
					pointWidth: 12,
					pointPlacement: 'on',
					grouping: false,
					states: {
						hover: {
							brightness: -0.3
						}
					}
				},
				series: {
					events: {
						mouseOut: function () {
							setShowChip(true);
						},
						mouseOver: function () {
							setShowChip(false);
						}
					}
				}
			}
		});

		if (chart.axes && chart.axes.length === 2) {
			//Get height of graph
			const maxYValue = chart.axes[1].max;
			const minYValue = chart.axes[1].min;

			//Calc 100% of graph lenth
			const maxMinDistance = Math.abs(maxYValue) + Math.abs(minYValue);

			//Calc dist from point to max value in graph
			const distToMax = maxYValue - calcPoint;
			const distToMaxBudget = maxYValue - (target.value ?? 0);

			//Calc position of point relative to whole graph height/width, adjust with scale of block div compared to graph div.
			const yPosition = (distToMax / maxMinDistance) * 100 * 0.75;
			let xPosition = (graphData.period.current / graphData.period.total) * 100 * 0.7;
			const budgetChipPosition = (distToMaxBudget / maxMinDistance) * 100 * 0.85;

			xPosition = graphData.period.current > graphData.period.total * 0.85 ? xPosition + 3 : xPosition - 3;

			setChipPosition({ budgetChip: budgetChipPosition, graphChip: { x: xPosition, y: yPosition } });
		}
	}, [graphData]);
	return (
		<Block space="ptxl">
			<div id="forecastGraph" className={classes.b()} />
			{target.value !== null ? (
				<div
					style={{
						left: chipPostition.graphChip.x + '%',
						top: chipPostition.graphChip.y + '%'
					}}
					className={classes.elem('chip').mod({ hideChip: !showChip }).b()}
				>
					<AssistChip
						type="default"
						title={
							<>
								<Icon name="info" space="mrs" />
								{aheadOfTarget.isAhead
									? T('forecasting.aheadOfTarget', {
											count: cf.short(Math.round(aheadOfTarget.value))
									  })
									: T('forecasting.shouldHaveReached', {
											count: cf.short(Math.round(shouldBePoint))
									  })}
							</>
						}
					/>
				</div>
			) : null}
			{target.value !== null ? (
				<div
					style={{
						top: Math.min(chipPostition.budgetChip, 76) + '%',
						right: graphData.period.current > graphData.period.total * 0.85 ? '-22px' : '-14px'
					}}
					className={classes.elem('budget-chip').b()}
				>
					<AssistChip
						type="default"
						title={
							<>
								<Icon name="bullseye" color="grey-10" />
							</>
						}
					/>
				</div>
			) : null}
		</Block>
	);
};

export default ForecastingGraph;
