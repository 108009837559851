import { IconName } from '@upsales/components';
import Resource from './Resource';

export type RCDashboardCustomFilterValue = {
	[k: string]: { [k: number]: { value: (number | string)[]; comparison?: string } };
};

export type RCDFilterCustomDate = { start: Date | null; end: Date | null } | null;

export type RCDashboardFilter = {
	Date?: { value: string };
	CustomDate?: { value: RCDFilterCustomDate };
	User?: { value: Array<number | 'null' | '{{self}}'>; comparison: string };
	Role?: { value: Array<number | 'null'>; comparison: string };
	Stage?: { value: number[]; comparison: string };
	Campaign?: { value: Array<number | 'null'>; comparison: string };
	ClientCampaign?: { value: Array<number | 'null'>; comparison: string };
	CallList?: { value: Array<number | 'null'>; comparison: string };
	Product?: { value: number[]; comparison: string };
	ProductCategory?: { value: number[]; comparison: string };
	Currency?: { value: string[]; comparison: string };
	ClientCategory?: { value: Array<number | 'null'>; comparison: string };
	AccountManager?: { value: Array<number | 'null'>; comparison: string };
	AppointmentType?: { value: number[]; comparison: string };
	FirstAppointmentType?: { value: number[]; comparison: string };
	Type?: { value: string[]; comparison: string };
	ActivityType?: { value: number[]; comparison: string };
	Client?: { value: number[]; comparison: string };
	Event?: { value: number[]; comparison: string };
	Flow?: { value: number[]; comparison: string };
	Form?: { value: number[]; comparison: string };
	EasyBooking?: { value: number[]; comparison: string };
	Label?: { value: number[]; comparison: string };
	LabelForm?: { value: number[]; comparison: string };
	LabelMailCampaign?: { value: number[]; comparison: string };
	ClientNewSale?: { value: string[]; comparison: string };
	ClientAddressesCountry?: { value: string[]; comparison: string };
	ClientAddressesState?: { value: string[]; comparison: string };
	ClientAddressesCity?: { value: string[]; comparison: string };
	JourneyStepClient?: { value: string[]; comparison: string };
	TicketType?: { value: number[]; comparison: string };
	Custom?: {
		value: RCDashboardCustomFilterValue;
	};
};

export type RCDashboardFilterValue =
	| Required<RCDashboardFilter>[keyof RCDashboardFilter]['value']
	| (number | string)[];

export type RCWidgetFilter = {
	attribute: string;
	value: string;
	comparison: string;
};

export type ValueType = 'recurring' | 'cm' | 'sales' | 'allOpportunities' | 'relatedOpportunities';

export enum ContentSize {
	sm = 0,
	md,
	lg,
	xl
}

export type RCDashboardWidget = {
	id: number | string;
	uniqueId?: number | string; // Only on widgets in selected dashboard
	title: string;
	type: string;
	displayType: string;
	groupBy: string[];
	width: number;
	height: number;
	legend: boolean;
	filters: RCWidgetFilter[];
	aggregateSubAccounts?: boolean;
	x: number;
	y: number;
	goal: boolean;
	error?: string;
	staticFilters?: boolean;
	sorting?: string;
	percent?: boolean;
	weighted?: boolean;
	valueType?: ValueType | null;
	showGoal?: boolean;
	numberFormat?: string | null;
	customOrderSumField?: string | null;
	contentSize?: ContentSize;
	exportOutsideRC?: boolean;
};

export type RCDashboardView = {
	id: number;
	label: string;
	filters?: RCDashboardFilter[];
	widgets: RCDashboardWidget[];
	icon?: IconName;
};

export type RCDashboard = {
	id: number | string;
	sidebarGroup: string;
	description: string;
	name: string;
	userEditable: boolean;
	icon?: IconName;
	trackingName?: string;
	filters?: RCWidgetFilter[];
	views?: RCDashboardView[];
	sorting?: number;
	hidden?: boolean;
};

export type RCDashboardExtended = RCDashboard & {
	widgets: RCDashboardWidget[];
	views?: RCDashboardView[];
	viewsConfig?: {
		autoWidth?: boolean;
		hideLabel?: boolean;
	};
};

class ReportDashboard extends Resource {
	constructor() {
		super('report/dashboard');
	}

	find(): Promise<{ data: RCDashboard[] }> {
		return super.find();
	}

	get(id: number): Promise<RCDashboard> {
		return super.get(id);
	}

	saveSettings(dashboards: RCDashboard[]): Promise<void> {
		return super._postRequest('saveSettings', dashboards);
	}
}

const resource = new ReportDashboard();

export default resource;
