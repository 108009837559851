import _ from 'lodash';
import { EntityCustomField, CustomFieldWithValue } from '../Model/CustomField';

// Taken from ui/app/upsales/common/resources/mappers/helpers.js
export const mapCustom = (objFields: Readonly<CustomFieldWithValue[]>): EntityCustomField[] => {
	const mappedFields: EntityCustomField[] = [];

	objFields.forEach(({ fieldId, value, datatype }) => {
		// Only Selects can be saved as null
		if (_.isNull(value) && datatype === 'Select') {
			mappedFields.push({ fieldId, value });
			return;
		}

		// To clear integers, set value to null
		if (_.includes(['Integer', 'Currency', 'Discount', 'User'], datatype) && _.isNull(value)) {
			mappedFields.push({ fieldId, value: null });
			return;
		}

		if (datatype === 'Users' && _.isEmpty(value)) {
			mappedFields.push({ fieldId, value: null });
			return;
		}

		if (datatype === 'Integer' && isNaN(value as unknown as number)) {
			return;
		}

		if (!_.isUndefined(value) && !_.isNull(value)) {
			mappedFields.push({ fieldId, value });
		}
	});

	return mappedFields;
};
