import React from 'react';
import HistoryLog from '../HistoryLog';
import T from 'Components/Helpers/translate';
import TimelineLoadMore from 'Components/TimelineRow/TimelineLoadMore';
import AppointmentEvents from 'App/resources/AppointmentEvents';
import Appointment from 'App/resources/Model/Appointment';
import PriorHistoryLog from '../PriorHistoryLog';

import usePartialLoader, { FetcherFunction } from 'App/components/hooks/usePartialLoader';
import useSelector from 'App/components/hooks/useSelector/useSelector';
import { getTimelineRow } from 'Components/Helpers/getTimelineRow';
import { HistoryLogRenderedBy } from '../ModalHistoryLog';
import Event from 'App/resources/Model/Event';

type AppointmentHistoryLogProps = {
	appointment: PartialPick<Appointment, 'id'>;
	selectButtons?: React.ReactElement;
	showLeading?: boolean;
	renderedBy: HistoryLogRenderedBy;
};

const listeners = [
	'appointment.created',
	'appointment.updated',
	'appointment.deleted',
	'comment.deleted',
	'comment.updated'
];

const fetcher: FetcherFunction<{ appointment: AppointmentHistoryLogProps['appointment'] }, Event> = (
	filters,
	{ appointment }
) => {
	return AppointmentEvents.getEvents(appointment.id, filters.limit, filters.offset);
};

function AppointmentHistoryLog({
	appointment,
	selectButtons,
	showLeading = false,
	renderedBy
}: AppointmentHistoryLogProps) {
	if (!appointment?.id) {
		return null;
	}

	const notesFilterActive = useSelector(state => state.NotesFilterActive);

	const {
		data: events,
		total: totalEvents,
		loading,
		loadMore
	} = usePartialLoader({
		fetcher,
		broadcastTypes: listeners,
		fetcherProps: {
			appointment
		}
	});

	return (
		<HistoryLog title={T('history')} selectButtons={selectButtons} hasPriorHistory={showLeading} loading={loading}>
			{events.map((event, index) => {
				return (
					<div key={index}>
						{getTimelineRow(
							event,
							'Appointment',
							notesFilterActive,
							index === events.length - 1,
							renderedBy
						)}
					</div>
				);
			})}
			{events && events.length < totalEvents ? (
				<TimelineLoadMore
					newEventsLoading={loading}
					numEventsLoaded={events.length}
					totalEvents={totalEvents}
					incrementOffset={loadMore}
				/>
			) : null}
			{showLeading ? <PriorHistoryLog entity={appointment} type="appointment" /> : null}
		</HistoryLog>
	);
}

export default AppointmentHistoryLog;
