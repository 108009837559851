import React from 'react';

import { useTranslation } from 'Components/Helpers/translate';
import { Block, Progressbar, StateFrame } from '@upsales/components';

export type PendingPurchaseType = {
	status: number;
	action: string;
};

type Props = {
	pendingPurchase?: PendingPurchaseType | null;
	space?: string;
};

const PendingPurchaseBanner = ({ pendingPurchase, space }: Props) => {
	const { t } = useTranslation();

	return pendingPurchase ? (
		<StateFrame
			space={space}
			state="warning"
			title={t('subaccounts.notificationBanner.title')}
			icon="warning"
			subtitle={
				<>
					<Block space="mbm">{t('subaccounts.notificationBanner.subtitle')}</Block>{' '}
					<Progressbar size="sm" color="black" value={pendingPurchase.status} />{' '}
				</>
			}
		/>
	) : null;
};

export default PendingPurchaseBanner;
