import UnreleasedFeatureCustomer from 'App/resources/UnreleasedFeatureCustomer';
import { useTranslation } from 'Components/Helpers/translate';
import { DefaultButton } from '@upsales/components/Buttons';
import { useSoftDeployAccess } from 'App/components/hooks';
import BemClass from '@upsales/components/Utils/bemClass';
import { Icon, Tooltip, Row } from '@upsales/components';
import logError from 'Helpers/logError';
import React, { useState } from 'react';

import './MigrateButton.scss';
import { useAccountSelf, useSelf, useSetAccountSelf } from 'App/components/hooks/appHooks';

const MigrateButton = () => {
	const hasPromoFlag = useSoftDeployAccess('SUBSCRIPTION_PROMO');
	const hasSubscriptionModal = useSoftDeployAccess('SUBSCRIPTION_MODAL');
	const [pendingMigration, setPendingMigration] = useState(false);
	const setAccountSelf = useSetAccountSelf();
	const accountSelf = useAccountSelf();
	const self = useSelf();
	const { t } = useTranslation();

	const classes = new BemClass('SubscriptionPromoMigrationButton');

	if (!hasPromoFlag || hasSubscriptionModal) {
		return null;
	}

	const migrate = async () => {
		setPendingMigration(true);

		try {
			await UnreleasedFeatureCustomer.toggle({
				name: 'SUBSCRIPTION_MODAL',
				active: true
			});
			await UnreleasedFeatureCustomer.toggle({
				name: 'EDIT_SUBSCRIPTIONS',
				active: true
			});

			if (accountSelf?.unreleasedFeatures) {
				accountSelf.unreleasedFeatures['SUBSCRIPTION_MODAL'] = true;
				accountSelf.unreleasedFeatures['EDIT_SUBSCRIPTIONS'] = true;
				setAccountSelf(accountSelf);
			}
		} catch (err) {
			logError(err, 'Failed to migrate to new Subscription modal');
			setPendingMigration(false);
		}
	};

	const lang = {
		button: t('subscription.promo.makeSwitch.button'),
		tooltip: {
			admin: t('subscription.promo.makeSwitch.tooltip.admin'),
			nonAdmin: t('subscription.promo.makeSwitch.tooltip.nonAdmin')
		}
	};

	return (
		<Row align="center" className={classes.b()}>
			<Tooltip title={self?.administrator ? lang.tooltip.admin : lang.tooltip.nonAdmin}>
				<DefaultButton disabled={pendingMigration || !self?.administrator} onClick={() => migrate()}>
					<Icon name={'gift'} space="mrl" />
					{lang.button}
				</DefaultButton>
			</Tooltip>
		</Row>
	);
};

export default MigrateButton;
