import { IconName } from '@upsales/components';

export const getFileIcon = (mimeType: string = '', prefix: string = 'fa-', suffix: string = '-o') => {
	const type = mimeType.split('/');
	let className = '';

	switch (type[0]) {
		case 'text':
			className = 'file-text';
			break;

		case 'image':
			className = 'file-image';
			break;

		case 'audio':
			className = 'file-sound';
			break;

		case 'application': {
			switch (type[1]) {
				// case 'zip': //uncomment after we add an icon for file-zip
				// 	className = 'file-zip';
				// 	break;

				case 'pdf':
					className = 'file-pdf';
					break;

				case 'javascript':
					className = 'file-code';
					break;

				case 'vnd.ms-excel':
				case 'vnd.oasis.opendocument.spreadsheet':
					className = 'file-excel';
					break;

				case 'msword':
				case 'vnd.oasis.opendocument.text':
					className = 'file-word';
					break;

				case 'vnd.ms-powerpoint':
				case 'vnd.oasis.opendocument.presentation':
					className = 'file-powerpoint';
					break;

				default:
					className = 'file';
					break;
			}

			break;
		}

		default:
			className = 'file';
			break;
	}

	return (prefix + className + suffix) as IconName;
};

export const getFileMeta = (url: string, callback: (meta: HTMLImageElement) => void) => {
	const img = new Image();
	img.addEventListener('load', function () {
		callback(this);
	});
	img.src = url;
	return img;
};

export const getFileSize = (url: string, callback: (err: string | null, fileSize?: string | null) => void) => {
	var xhr = new XMLHttpRequest();
	xhr.open('HEAD', url, true);
	xhr.onreadystatechange = function () {
		if (xhr.readyState === 4) {
			if (xhr.status === 200) {
				callback(null, xhr.getResponseHeader('Content-Length'));
			} else {
				callback('Failed to get file size');
			}
		}
	};
	xhr.send(null);
};

export const getIconAndColor = (type: string | undefined): [IconName, string] => {
	if (type?.[0] !== '.') {
		type = '.' + type;
	}

	if (type === '.xls' || type === '.xlsx') {
		return ['file-excel', 'success-6'];
	}
	if (type === '.doc') {
		return ['file-document-box', 'blue'];
	}
	if (type === '.ppt') {
		return ['file-powerpoint', 'orange'];
	}
	if (type === '.pdf') {
		return ['file-pdf', 'red'];
	}

	const imageFiles = ['.jpg', '.jpeg', '.png'];
	if (imageFiles.includes(type.toLowerCase())) {
		return ['file-image', 'red'];
	}
	return ['file', 'black'];
};
