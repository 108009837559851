/**
 * This file is just temporary until FlexItem is implemented in the ui-library.
 * In the real implementation we will have minimal inline css and dedicated classnames.
 * Most types/classes can be shared with the existing Flex component.
 * We will also look into implementing the flex props within the baseFunctionaliti HOC, or implement a <Container /> component.
 */

import React, { CSSProperties, ComponentType } from 'react';

type FlexValue = number | string;
type FlexType = number | [FlexValue, FlexValue] | [FlexValue, FlexValue, string];
type FlexItemProps<T extends ComponentType<any>> = {
	flex?: FlexType;
	flexShrink?: number;
	flexGrow?: number;
	component?: T;
	style?: CSSProperties;
} & React.ComponentProps<T>;

const FlexItem = <T extends ComponentType<any>>({
	flex,
	flexShrink,
	flexGrow,
	component: Component = 'div' as any,
	style,
	...props
}: FlexItemProps<T>) => {
	const flexStyle = { ...style, flexShrink, flexGrow };
	if (flex !== undefined) {
		if (Array.isArray(flex)) {
			flexStyle.flex = `${flex[0] ?? ''} ${flex[1] ?? ''} ${flex[2] ?? ''}`.trim();
		} else {
			flexStyle.flex = `${flex}`;
		}
	}
	return <Component {...props} style={flexStyle} />;
};

export default FlexItem;
