import React from 'react';
import SalesboardFilterButton from '../../../app/pages/Salesboard/SalesboardFilterButton';
import forecastingFilter2 from 'App/babel/filterConfigs/Forecasting';
const ForecastingFilter2 = forecastingFilter2();
import { ListViewFilter } from 'App/resources/AllIWant';
import { forecastingTracker } from 'Helpers/Tracker';

type Props = {
	filters: ListViewFilter[];
	onFilterChange: any;
};

const ForecastingFilters = ({ filters, onFilterChange }: Props) => {
	const onOpenTracker = (filterName: string) => {
		forecastingTracker.track(forecastingTracker.events.FILTER_OPENED, {
			filterName
		});
	};
	const onSelectTracker = (filterName: string) => {
		forecastingTracker.track(forecastingTracker.events.FILTER_SELECTED, {
			filterName
		});
	};
	return (
		<div className={'ForecastingFilters'}>
			{filters.map(filter => (
				<SalesboardFilterButton
					key={filter.filterName}
					filter={filter}
					onChange={onFilterChange}
					filterConfigs={ForecastingFilter2}
					trackers={{ onOpen: onOpenTracker, onSelect: onSelectTracker }}
				></SalesboardFilterButton>
			))}
		</div>
	);
};

export default ForecastingFilters;
