import React from 'react';
import Activity from 'App/resources/Model/Activity';
import RequestBuilder, { comparisonTypes } from 'Resources/RequestBuilder';
import ActivityResource from 'Resources/Activity';
import moment from 'moment';
import QuickCreateTodoCard from 'Components/QuickCreateTodoCard';

interface Props {
	activity: Activity;
}
const EditPhonecallTodos = ({ activity, ...props }: Props) => {
	const fetchTodos = () => {
		const rb = new RequestBuilder();
		rb.addFilter({ field: 'parentActivityId' }, comparisonTypes.Equals, activity.id);
		rb.addSort({ field: 'priority' }, false);
		return ActivityResource.find(rb.build());
	};

	const onSave = (createData: Activity) => {
		let data;
		if (createData?.id) {
			data = {
				...createData
			};
		} else {
			data = {
				...createData,
				parentActivityId: activity.id,
				client: activity.client ? { id: activity.client.id } : undefined,
				activityType: { id: Tools.AppService.getTodoTypes().TODO.id },
				users: [{ id: Tools.AppService.getSelf().id }],
				contacts: activity.contacts || null
			};
		}
		if (createData?.date) {
			data.date = createData.time ? createData.date : moment(createData.date).format('YYYY-MM-DD');
		}
		return ActivityResource.save(data);
	};
	return <QuickCreateTodoCard key={activity.id} hideRelation onSave={onSave} fetchTodos={fetchTodos} />;
};

export default EditPhonecallTodos;
