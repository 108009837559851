import Resource from 'Resources/Resource';
import JourneyStepType from './Model/JourneyStep';

class JourneyStep extends Resource {
	constructor() {
		super('journeySteps');
	}

	find(...args: any): Promise<{ data: JourneyStepType[] }> {
		return super.find(...args);
	}
}

const resource = new JourneyStep();

export default resource;
