import { State } from './../SubscriptionIndexingState/SubscriptionIndexingState';
import { TableRow, TableColumn, Text, Link, EllipsisTooltip, ButtonSelect, Column, Flex } from '@upsales/components';
import ListView, { GetDataOpts, ListViewPropsExternal } from 'App/components/ListView';
import { ListViewDefaultColumn, RenderHeaderProvided } from 'App/components/ListView/ListViewRenderHelpers';
import AgreementAttributes from 'App/babel/attributes/AgreementAttributes';
import { currencyFormat } from 'Components/Filters/Currencies';
import BemClass from '@upsales/components/Utils/bemClass';
import { useTranslation } from 'Components/Helpers/translate';
import Agreement, { IndexAgreement } from 'App/resources/Model/Agreement';
import openModal from 'App/services/Modal';
import React, { useCallback, useMemo } from 'react';
import { ListViewFilter } from 'App/resources/AllIWant';
import { useAgreementFilters } from 'App/components/hooks/filters';
import { useMetadata } from 'App/components/hooks/appHooks';
import { usesMRR } from 'App/helpers/salesModelHelpers';
import { FilterConfig } from 'App/babel/filterConfigs/FilterConfig';
import { FilterPreset, generateFilter } from 'App/babel/filterConfigs/filterGenerators';
import { IndexConflictDecision } from 'App/resources/Model/IndexIncreaseSettings';
import LockIcon from '../LockIcon';

import './SubscriptionList.scss';

const SubscriptionAttributes = {
	...AgreementAttributes,
	info: {
		...AgreementAttributes.description,
		title: 'agreement',
		sortable: 'description'
	},
	yearlyValue: {
		...AgreementAttributes.yearlyValue,
		title: 'default.currentARR',
		sortable: 'yearlyValue'
	},
	increasedYearlyValue: {
		...AgreementAttributes.yearlyValue,
		displayKey: ['increasedYearlyValue', 'currency'],
		displayKeyMap: { increasedYearlyValue: 'value' },
		title: 'subscription.indexing.preview.increaseColumn.label.ARR',
		field: 'increasedYearlyValue',
		sortable: 'yearlyValue'
	},
	monthlyValue: {
		...AgreementAttributes.yearlyValue,
		displayKey: ['monthlyValue', 'currency'],
		displayKeyMap: { monthlyValue: 'value' },
		field: 'monthlyValue',
		title: 'default.currentMRR',
		sortable: 'yearlyValue'
	},
	increasedMonthlyValue: {
		...AgreementAttributes.yearlyValue,
		displayKey: ['increasedMonthlyValue', 'currency'],
		displayKeyMap: { increasedMonthlyValue: 'value' },
		title: 'subscription.indexing.preview.increaseColumn.label.MRR',
		field: 'increasedMonthlyValue',
		sortable: 'yearlyValue'
	},
	plannedIncrease: {
		...AgreementAttributes.renewalDate,
		title: 'subscription.indexing.list.column.plannedIncrease'
	},
	exclude: {
		...AgreementAttributes.description,
		title: '',
		sortable: false as const
	},
	include: {
		...AgreementAttributes.description,
		title: '',
		sortable: false as const
	},
	conflict: {
		...AgreementAttributes.description,
		title: '',
		sortable: false as const
	}
};

export type AgreementProcessed = IndexAgreement & {
	increasedYearlyValue: number;
	monthlyValue: number;
	increasedMonthlyValue: number;
};

type ListViewProps = ListViewPropsExternal<AgreementProcessed>;

const IndexConflicts = ({ t, conflicts }: { t: any; conflicts: IndexAgreement['conflictingIndexes'] }) => {
	if (!conflicts) {
		return null;
	}
	const classNames = new BemClass('IndexConflicts');
	const indexNames = conflicts.map(index => index.name);

	return (
		<div className={classNames.b()}>
			<Text size="sm" bold>
				{t('subscription.indexing.conflicts.repeatedIn')}
			</Text>
			<Text size="sm" bold>
				{indexNames.join(' + ')}
			</Text>
		</div>
	);
};

const navigateToAccount = (subscription: Agreement) => {
	openModal('EditSubscription', {
		agreementId: subscription.id,
		agreementGroupId: subscription.agreementGroupId ?? undefined
	});
};

const getRowRenderFn =
	(
		t: any,
		currency: string,
		isLocked: boolean,
		includeOrExclude: (type: 'include' | 'exclude', id: number, reloadTable: (opts: GetDataOpts) => void) => void,
		indexConflictDecisionsMap?: { [key: number]: IndexConflictDecision },
		setConflictDecision?: (agreement: Agreement, decision: string) => void
	): ListViewProps['renderTableRow'] =>
	(subscription: AgreementProcessed, { columns, attributes, reloadTable }) => {
		const columnElements = columns.map(column => {
			let content: null | JSX.Element = null;
			switch (column) {
				case 'increasedYearlyValue':
				case 'increasedMonthlyValue': {
					const totalValueColumn = column === 'increasedYearlyValue' ? 'yearlyValue' : 'monthlyValue';
					const diff = subscription[column] - (subscription[totalValueColumn] ?? 0);

					content = (
						<Column>
							<Text>{currencyFormat(subscription[column], currency)}</Text>
							<Text space="pts" color="grey-11" size="sm">{`+${currencyFormat(diff, currency)}`}</Text>
						</Column>
					);
					break;
				}

				case 'info': {
					content = (
						<div>
							<EllipsisTooltip title={subscription.description}>
								<Text>
									<Link onClick={() => navigateToAccount(subscription)}>
										{subscription.description}
									</Link>
								</Text>
							</EllipsisTooltip>
							<Text color="grey-10" size="sm">
								{'ID: ' + subscription.id}
							</Text>
							<EllipsisTooltip title={subscription.client?.name}>
								<Text color="grey-10" size="sm">
									{subscription.client?.name}
								</Text>
							</EllipsisTooltip>
						</div>
					);
					break;
				}
				case 'conflict': {
					content = (
						<ButtonSelect
							options={[
								{ value: 'include', title: t('subscription.indexing.include') },
								{ value: 'exclude', title: t('subscription.indexing.exclude') },
								{ value: 'unhandled', title: t('subscription.indexing.unhandled') }
							]}
							size="sm"
							value={
								indexConflictDecisionsMap
									? indexConflictDecisionsMap[subscription.id]?.decision ?? 'unhandled'
									: 'unhandled'
							}
							onClick={e => {
								e.stopPropagation();
							}}
							onChange={value => {
								setConflictDecision?.(subscription, value);
							}}
						/>
					);
					break;
				}
				case 'include':
				case 'exclude': {
					content = (
						<Flex justifyContent="flex-end" space="prl">
							{isLocked ? (
								<>
									<LockIcon />
									<Text italic color="grey-10">
										{t(`default.${column}`)}
									</Text>
								</>
							) : (
								<Link
									onClick={e => {
										e.stopPropagation();
										includeOrExclude(column, subscription.id, reloadTable);
									}}
								>
									<Text color="bright-blue">{t(`default.${column}`)}</Text>
								</Link>
							)}
						</Flex>
					);
					break;
				}

				default: {
					return (
						<ListViewDefaultColumn<Agreement>
							key={column}
							item={subscription}
							attributes={attributes}
							column={column}
							colSize="md"
						/>
					);
				}
			}
			return (
				<TableColumn size="md" key={column}>
					{content}
				</TableColumn>
			);
		});

		const showConflictRow = subscription.conflictingIndexes?.length && indexConflictDecisionsMap;

		return (
			<React.Fragment key={subscription.id}>
				<TableRow className={showConflictRow ? 'TableRow__conflicts' : ''}>{columnElements}</TableRow>
				{showConflictRow ? (
					<TableRow className={'TableRow__indexes'}>
						<TableColumn colSpan={columns.length} key="indexes">
							<IndexConflicts t={t} conflicts={subscription.conflictingIndexes} />
						</TableColumn>
					</TableRow>
				) : null}
			</React.Fragment>
		);
	};

const getReducedFilters = (filterConfigs: { [key: string]: FilterConfig }, interval: State['indexInterval']) => {
	filterConfigs.AccountCampaign = generateFilter(FilterPreset.Campaign, {
		field: 'client.project.id',
		parent: 'default.account'
	});

	if (interval === 'renewal') {
		// Remove invalid options from periodLength
		filterConfigs.periodLength.dataPromise = async () => ({
			data: Tools.AppService.getStaticValues('periodLength').filter((pl: any) => pl.id !== '0')
		});
	}

	const ignoreFilters = ['Active', 'Stage', 'Contact', 'noticePeriod', 'noticeDate'];

	return Object.keys(filterConfigs).reduce((acc, key) => {
		if (ignoreFilters.indexOf(key) < 0) {
			acc[key] = filterConfigs[key];
		}
		return acc;
	}, {} as { [key: string]: FilterConfig });
};

type Props = {
	columns: string[];
	getData: ListViewPropsExternal<Agreement>['getData'];
	state: State;
	indexConflictDecisionsMap?: State['indexConflictDecisionsMap'];
	fullWidth?: boolean;
	renderHeader?: (provided: RenderHeaderProvided<AgreementProcessed>) => JSX.Element;
	setConflictDecision?: (agreement: Agreement, decision: string) => void;
	includeOrExcludeSub?: (type: 'include' | 'exclude', id: number) => void;
	initialFilters?: { [filterName: string]: ListViewFilter };
	tableLimitOptions?: Array<number> & { 0: number };
};

const SubscriptionList = (props: Props) => {
	const {
		columns,
		getData,
		state,
		fullWidth,
		renderHeader,
		initialFilters,
		tableLimitOptions,
		includeOrExcludeSub,
		setConflictDecision,
		indexConflictDecisionsMap
	} = props;
	const { t } = useTranslation();
	const classes = new BemClass('SubscriptionList');
	const { subscriptionCount, indexPercentage, isLocked, indexInterval } = state;

	const metadata = useMetadata();
	const hasMRR = usesMRR(metadata);
	const agreementFilters = useAgreementFilters();
	const currency = metadata?.defaultCurrency?.iso || 'SEK';

	const mappedColumns = useMemo(() => {
		const cols = columns.map(col => {
			if (col === 'periodValue') {
				return hasMRR ? 'monthlyValue' : 'yearlyValue';
			}
			if (col === 'increasedPeriodValue') {
				return hasMRR ? 'increasedMonthlyValue' : 'increasedYearlyValue';
			}
			return col;
		});

		const indexOfLastIndexIncrease = cols.indexOf('lastIndexIncrease');
		if (indexInterval === 'renewal' && indexOfLastIndexIncrease >= 0) {
			// insert after lastIndexIncrease
			cols.splice(indexOfLastIndexIncrease + 1, 0, 'plannedIncrease');
		}

		return cols;
	}, [columns, hasMRR, indexInterval]);

	const includeOrExclude = (type: 'exclude' | 'include', id: number, reloadTable: (opts: GetDataOpts) => void) => {
		includeOrExcludeSub?.(type, id);
		setTimeout(() => reloadTable({})); // dirty
	};

	const mapToProcessed = useCallback(
		(subscriptions: Agreement[]): AgreementProcessed[] => {
			return subscriptions.map(sub => ({
				...sub,
				increasedYearlyValue: Math.round((sub.yearlyValue ?? 0) * (1 + indexPercentage / 100)),
				monthlyValue: (sub.yearlyValue ?? 0) / 12,
				increasedMonthlyValue: Math.round(((sub.yearlyValue ?? 0) * (1 + indexPercentage / 100)) / 12)
			}));
		},
		[indexPercentage]
	);

	return (
		<div className={classes.b()}>
			<div className={classes.mod({ hideTopBorder: !subscriptionCount }).b()}>
				<ListView<AgreementProcessed>
					listType="agreement"
					noStickyTableHeader
					renderTableRow={getRowRenderFn(
						t,
						currency,
						isLocked,
						includeOrExclude,
						indexConflictDecisionsMap,
						setConflictDecision
					)}
					className={classes.mod({ fullWidth, content: !fullWidth }).b()}
					attributes={SubscriptionAttributes}
					quickSearchFilter="ListSearch"
					renderHeader={renderHeader ?? (() => <></>)}
					renderToolsColumn={false}
					tableLimitOptions={tableLimitOptions}
					filterConfigs={fullWidth ? getReducedFilters(agreementFilters, indexInterval) : undefined}
					getData={async (rb, provided) => {
						rb.fields = [
							'id',
							'description',
							'client',
							'yearlyValue',
							'conflictingIndexes',
							'latestIndexIncreaseDate'
						];

						const { data: subscriptions, metadata } = await getData(rb, provided);
						const data = mapToProcessed(subscriptions);

						return { data, metadata };
					}}
					initialFilters={initialFilters}
					columns={mappedColumns}
					key={isLocked + ''}
					isFullscreen={!fullWidth} // yes, that's correct
				/>
			</div>
		</div>
	);
};

export default SubscriptionList;
