import './MoveSubscription.scss';

import PropTypes from 'prop-types';
import React, { useState, useRef, useEffect, useCallback } from 'react';
import $ from 'jquery';
import { Icon, Text, Block, Label, Button, Headline } from '@upsales/components';
import MoveSubscriptionProgress from './MoveSubscriptionProgress';
import BemClass from '@upsales/components/Utils/bemClass';
import logError from 'App/babel/helpers/logError';
import { SlideFade, Slide } from 'App/components/animations';
import T from 'Components/Helpers/translate';
import Agreement from 'App/resources/Model/Agreement';
import AgreementGroupResource from 'Resources/AgreementGroup';
import AgreementGroup from 'App/resources/Model/AgreementGroup';

type Props = {
	animate?: boolean;
	visible?: boolean;
	show?: boolean;
	className?: string;
	close?: () => void;
	closeModal?: () => void;
	closeView?: () => void;
	agreement?: Agreement;
	agreementGroup?: Partial<AgreementGroup>;
};

type SelectedAccount = { id: number; name: string };
type SelectedContact = { id: number; name: string };
type SelectRef = HTMLInputElement & { _input: HTMLInputElement };

const MoveSubscription = ({
	animate,
	visible,
	show,
	className,
	closeModal,
	closeView,
	agreement,
	agreementGroup,
	close
}: Props) => {
	agreement = agreementGroup?.currentAgreement || agreement;
	visible = visible ?? show;

	const classes = new BemClass('MoveSubscription', className);
	classes.add('FullScreenModal');
	const [pendingSave, setPendingSave] = useState(false);
	const [selectedAccount, setSelectedAccount] = useState<SelectedAccount | null>();
	const [selectedContact, setSelectedContact] = useState<SelectedContact | null>();

	const accountSelectRef = useRef<SelectRef>();
	const contactSelectRef = useRef<SelectRef>();
	const selectOpenerTimer = useRef<NodeJS.Timeout>();
	const selectedAccountRef = useRef<SelectRef>();
	const previouslySelectedAccountRef = useRef<SelectRef>();

	const renderCloseButton = () => (
		<Block space="prl ptl" className={classes.elem('cornerClose').b()}>
			<Button
				type="link"
				color="grey"
				onClick={() => {
					closeModal?.();
					close?.();
				}}
			>
				<Icon name="times" />
			</Button>
		</Block>
	);

	const renderHeadline = () => (
		<Block space="mbxl">
			<Headline className={classes.elem('headline').b()} size="sm">
				{T('agreement.moveSubscriptionHeadline')}
			</Headline>
		</Block>
	);

	const renderDescription = () => (
		<Block space="mbxl">
			<Text className={classes.elem('description').b()}>{T('agreement.moveSubscriptionDescription')}</Text>
		</Block>
	);

	const selectNewSelectedAccount = useCallback(
		account => {
			previouslySelectedAccountRef.current = selectedAccountRef.current;
			selectedAccountRef.current = account;
			setSelectedContact(null);
			setSelectedAccount(account);
		},
		[selectedAccount]
	);

	const setAccountSelectRef = (ref: HTMLInputElement) => {
		accountSelectRef.current = ref as SelectRef;
	};

	const setContactSelectRef = (ref: HTMLInputElement) => {
		contactSelectRef.current = ref as SelectRef;
	};

	const clearTimer = (timer?: NodeJS.Timeout) => {
		if (timer) {
			clearTimeout(timer);
		}
	};

	const openSelectAfterDelay = (selectRef: React.MutableRefObject<SelectRef | undefined>, openingDelay: number) => {
		if (!selectRef?.current?._input) {
			return;
		}

		clearTimer(selectOpenerTimer.current);

		selectOpenerTimer.current = setTimeout(() => {
			if (selectRef.current?._input) {
				$(selectRef.current._input).select2('open');
			}
		}, openingDelay);
	};

	useEffect(() => {
		if (visible) {
			const openingDelay = animate ? 1000 : 500;
			openSelectAfterDelay(accountSelectRef, openingDelay);
		}
	}, [visible, animate]);

	useEffect(() => {
		if (selectedAccount) {
			const animationDelay = previouslySelectedAccountRef.current ? 100 : 500;
			openSelectAfterDelay(contactSelectRef, animationDelay);
		}
	}, [selectedAccount]);

	useEffect(() => {
		return () => clearTimer(selectOpenerTimer.current);
	}, []);

	const onSave = async () => {
		if (!selectedAccount || (!agreement && !agreementGroup)) {
			return;
		}

		setPendingSave(true);

		agreementGroup =
			agreementGroup ?? (agreement?.agreementGroupId ? { id: agreement.agreementGroupId } : undefined);
		try {
			if (agreementGroup?.id) {
				await AgreementGroupResource.moveToClient(agreementGroup.id, selectedAccount.id, selectedContact?.id);
			} else if (agreement) {
				await Tools.Agreement.customer(Tools.AppService.getCustomerId()).moveAgreement(
					agreement,
					selectedAccount.id,
					selectedContact?.id
				);
			}
		} catch (err) {
			setPendingSave(false);
			logError(err, 'Failed to move subscription');
		}
	};

	const renderAccountSelect = () => (
		<Block space="mbxl">
			<Label className={classes.elem('accountSelectLabel').b()}>
				{T('agreement.moveSubscriptionAccountSelectLabel')}
			</Label>
			<ReactTemplates.INPUTS.upAccounts
				value={selectedAccount}
				ref={setAccountSelectRef}
				onChange={account => selectNewSelectedAccount(account)}
				placeholder={T('agreement.moveSubscriptionAccountSelectPlaceholder')}
				ignoreIds={agreement?.client?.id ? [agreement?.client?.id] : undefined}
				className={classes.elem('accountSelect').mod({ selected: !!selectedAccount }).b()}
			/>
		</Block>
	);

	const renderContactSelect = () => (
		<SlideFade visible={!!selectedAccount} direction="top">
			<Block className={classes.elem('contactSelectContainer').b()}>
				<Label className={classes.elem('contactSelectLabel').b()}>
					{T('agreement.moveSubscriptionContactSelectLabel', {
						companyName: selectedAccount?.name
					})}
				</Label>
				<ReactTemplates.INPUTS.upContacts
					value={selectedContact}
					ref={setContactSelectRef}
					onChange={contact => setSelectedContact(contact)}
					accountId={selectedAccount?.id}
					placeholder={T('agreement.moveSubscriptionContactSelectPlaceholder')}
					className={classes.elem('contactSelect').mod({ selected: !!selectedContact }).b()}
				/>
			</Block>
		</SlideFade>
	);

	const renderSaveButton = () => {
		const saveButtonClasses = classes.elem('saveButton');
		saveButtonClasses.add('text-ellipsis');

		return (
			<Block space="mbxl mtxl">
				<Button
					block
					size="lg"
					onClick={onSave}
					loading={pendingSave}
					disabled={!selectedAccount}
					className={saveButtonClasses.b()}
				>
					{selectedAccount
						? T('agreement.moveSubscriptionSaveButtonWithCompanyText', {
								companyName: selectedAccount.name
						  })
						: T('agreement.moveSubscriptionSaveButtonText')}
				</Button>
			</Block>
		);
	};

	const renderCancelButton = () => (
		<Block>
			<Button
				type="link"
				color="grey"
				onClick={() => {
					closeView?.();
					close?.();
				}}
				className={classes.elem('cancelButton').b()}
			>
				{T('agreement.moveSubscriptionCancelButtonText')}
			</Button>
		</Block>
	);

	return (
		<Slide complete visible={visible} disabled={!animate}>
			<div className={classes.b()}>
				{renderCloseButton()}
				<SlideFade bounce delayInMs={500} visible={visible && !pendingSave}>
					<div className={classes.elem('content').b()}>
						{renderHeadline()}
						{renderDescription()}

						<Block space="mtl" className={classes.elem('middleContent').b()}>
							<Block space="pbxl mbxl" className={classes.elem('inputContainer').b()}>
								{renderAccountSelect()}
								{renderContactSelect()}
							</Block>
							<SlideFade visible={!!selectedAccount} direction="top">
								<Block space="ptl">
									{renderSaveButton()}
									{renderCancelButton()}
								</Block>
							</SlideFade>
						</Block>
					</div>
				</SlideFade>

				<MoveSubscriptionProgress
					visible={pendingSave}
					fromCompanyName={agreement?.client?.name}
					toCompanyName={selectedAccount?.name}
					agreementId={agreement?.id}
					agreementGroupId={agreementGroup?.id}
					closeModal={() => {
						closeModal?.();
						close?.();
					}}
				/>
			</div>
		</Slide>
	);
};

MoveSubscription.propTypes = {
	animate: PropTypes.bool,
	visible: PropTypes.bool,
	closeModal: PropTypes.func,
	closeView: PropTypes.func,
	agreement: PropTypes.object
};

export default MoveSubscription;
