import moment from 'moment';
import T from 'Components/Helpers/translate';

//#region types
export type FilterMap = {
	[key: string]: string;
};

export type Option = {
	value: string;
	title: string;
	type?: string;
};

export type DateMap = {
	[key: string]: [moment.unitOfTime.DurationConstructor, number];
};
//#endregion

//#region mappers
export const monthNames: string[] = [
	'date.january',
	'date.february',
	'date.march',
	'date.april',
	'date.may',
	'date.june',
	'date.july',
	'date.august',
	'date.september',
	'date.october',
	'date.november',
	'date.december'
];

export const staticToRollingFiltersMap: FilterMap = {
	Custom: 'rolling30DaysAhead',
	NextWeek: 'rolling7DaysAhead',
	CurrentWeek: 'rolling7DaysAhead',
	LastWeek: 'rolling7DaysBack',
	NextMonth: 'rolling30DaysAhead',
	CurrentMonth: 'rolling30DaysAhead',
	LastMonth: 'rolling30DaysBack',

	NextQuarter: 'rolling3MonthsAhead',
	CurrentQuarter: 'rolling3MonthsAhead',
	LastQuarter: 'rolling3MonthsBack',
	Last5Years: 'rolling5YearsBack',
	NextYear: 'rolling12MonthsAhead',
	CurrentYear: 'rolling12MonthsAhead',
	LastYear: 'rolling12MonthsBack'
};

export const fiscalToRollingFiltersMap: FilterMap = {
	Custom: 'rolling30DaysAhead',
	NextWeek: 'rolling7DaysAhead',
	CurrentWeek: 'rolling7DaysAhead',
	LastWeek: 'rolling7DaysBack',
	NextMonth: 'rolling30DaysAhead',
	CurrentMonth: 'rolling30DaysAhead',
	LastMonth: 'rolling30DaysBack',

	NextFiscalQuarter: 'rolling3MonthsAhead',
	CurrentFiscalQuarter: 'rolling3MonthsAhead',
	LastFiscalQuarter: 'rolling3MonthsBack',
	Last5FiscalYears: 'rolling5YearsBack',
	NextFiscalYear: 'rolling12MonthsAhead',
	CurrentFiscalYear: 'rolling12MonthsAhead',
	LastFiscalYear: 'rolling12MonthsBack'
};

export const fiscalFiltersMap: FilterMap = {
	NextFiscalYear: 'NextYear',
	CurrentFiscalYear: 'CurrentYear',
	LastFiscalYear: 'LastYear',
	NextFiscalQuarter: 'NextQuarter',
	CurrentFiscalQuarter: 'CurrentQuarter',
	LastFiscalQuarter: 'LastQuarter',
	Last5FiscalYears: 'Last5Years',
	LastFiscalHalfYear: 'LastHalfYear',
	CurrentFiscalHalfYear: 'CurrentHalfYear',
	NextFiscalHalfYear: 'NextHalfYear',

	NextYear: 'NextFiscalYear',
	CurrentYear: 'CurrentFiscalYear',
	LastYear: 'LastFiscalYear',
	NextQuarter: 'NextFiscalQuarter',
	CurrentQuarter: 'CurrentFiscalQuarter',
	LastQuarter: 'LastFiscalQuarter',
	Last5Years: 'Last5FiscalYears',
	LastHalfYear: 'LastFiscalHalfYear',
	CurrentHalfYear: 'CurrentFiscalHalfYear',
	NextHalfYear: 'NextFiscalHalfYear'
};

export const presetDateMap: DateMap = {
	LastDay: ['day', -1],
	CurrentDay: ['day', 0],
	NextDay: ['day', 1],
	LastWeek: ['week', -1],
	CurrentWeek: ['week', 0],
	NextWeek: ['week', 1],
	LastMonth: ['month', -1],
	CurrentMonth: ['month', 0],
	NextMonth: ['month', 1],
	LastQuarter: ['quarter', -1],
	CurrentQuarter: ['quarter', 0],
	NextQuarter: ['quarter', 1],
	LastYear: ['year', -1],
	CurrentYear: ['year', 0],
	NextYear: ['year', 1]
};
//#endregion

//#region helper-functions
export const formatPeriod = (preset: string, fiscalYearOffset = 0) => {
	const getDates = (type: moment.unitOfTime.DurationConstructor, offset = 0, fiscalYearOffset = 0) => {
		const startDate = moment()
			.add(offset, type)
			.startOf(type === 'week' ? 'isoWeek' : type)
			.add(fiscalYearOffset, 'month');
		const endDate = moment()
			.add(offset, type)
			.endOf(type === 'week' ? 'isoWeek' : type)
			.add(fiscalYearOffset, 'month');
		if (!moment().add(offset, type).isBetween(startDate, endDate)) {
			if (fiscalYearOffset < 0) {
				while (!moment().add(offset, type).isBetween(startDate, endDate)) {
					startDate.add(1, type).startOf('month');
					endDate.add(1, type).endOf('month');
				}
			} else if (fiscalYearOffset > 0) {
				while (!moment().add(offset, type).isBetween(startDate, endDate)) {
					startDate.subtract(1, type).startOf('month');
					endDate.subtract(1, type).endOf('month');
				}
			}
		}
		return [startDate.toDate(), endDate.toDate()];
	};

	const getHalfYearRange = (offset?: number) => {
		offset = offset || 0;
		const currentMonth = moment().month();
		const start = getDates('month', -(currentMonth % 6) + 6 * offset)[0];
		const end = getDates('month', -(currentMonth % 6) + 5 + 6 * offset)[1];
		return [start, end];
	};

	const modulo = (a: number, b: number) => ((a % b) + b) % b;

	const getFiscalHalfYearRange = (offset?: number) => {
		offset = offset || 0;
		const currentMonth = moment().month();
		const startMonthOffset = modulo(fiscalYearOffset - currentMonth, -6);
		const endMonthOffset = startMonthOffset + 5;
		const start = getDates('month', startMonthOffset + 6 * offset)[0];
		const end = getDates('month', endMonthOffset + 6 * offset)[1];
		return [start, end];
	};

	if (presetDateMap[preset]) {
		const [type, offset] = presetDateMap[preset];
		return getDates(type, offset);
	}

	switch (preset) {
		case 'LastHalfYear':
			return getHalfYearRange(-1);
		case 'CurrentHalfYear':
			return getHalfYearRange();
		case 'NextHalfYear':
			return getHalfYearRange(1);
		case 'Last5Years':
			return [getDates('year', -5)[0], getDates('year')[1]];
		case 'LastFiscalYear':
			return getDates('year', -1, fiscalYearOffset);
		case 'CurrentFiscalYear':
			return getDates('year', 0, fiscalYearOffset);
		case 'NextFiscalYear':
			return getDates('year', 1, fiscalYearOffset);
		case 'LastFiscalHalfYear':
			return getFiscalHalfYearRange(-1);
		case 'CurrentFiscalHalfYear':
			return getFiscalHalfYearRange();
		case 'NextFiscalHalfYear':
			return getFiscalHalfYearRange(1);
		case 'LastFiscalQuarter':
			return getDates('quarter', -1, fiscalYearOffset);
		case 'CurrentFiscalQuarter':
			return getDates('quarter', 0, fiscalYearOffset);
		case 'NextFiscalQuarter':
			return getDates('quarter', 1, fiscalYearOffset);
		case 'Last5FiscalYears': {
			const start = getDates('year', -5, fiscalYearOffset)[0];
			const end = getDates('year', 0, fiscalYearOffset)[1];
			return [start, end];
		}

		case 'rolling7DaysBack': {
			const start = moment().add(-7, 'day').toDate();
			const end = moment().toDate();
			return [start, end];
		}
		case 'rolling7DaysAhead': {
			const start = moment().toDate();
			const end = moment().add(7, 'day').toDate();
			return [start, end];
		}

		case 'rolling30DaysBack': {
			const start = moment().add(-30, 'day').toDate();
			const end = moment().toDate();
			return [start, end];
		}
		case 'rolling30DaysAhead': {
			const start = moment().toDate();
			const end = moment().add(30, 'day').toDate();
			return [start, end];
		}

		case 'rolling3MonthsBack': {
			const start = moment().add(-3, 'month').toDate();
			const end = moment().toDate();
			return [start, end];
		}
		case 'rolling3MonthsAhead': {
			const start = moment().toDate();
			const end = moment().add(3, 'month').toDate();
			return [start, end];
		}

		case 'rolling6MonthsBack': {
			const start = moment().add(-6, 'month').toDate();
			const end = moment().toDate();
			return [start, end];
		}
		case 'rolling6MonthsAhead': {
			const start = moment().toDate();
			const end = moment().add(6, 'month').toDate();
			return [start, end];
		}

		case 'rolling12MonthsBack': {
			const start = moment().add(-1, 'year').toDate();
			const end = moment().toDate();
			return [start, end];
		}
		case 'rolling12MonthsAhead': {
			const start = moment().toDate();
			const end = moment().add(1, 'year').toDate();
			return [start, end];
		}
		case 'rolling5YearsBack': {
			const start = moment().add(-5, 'year').toDate();
			const end = moment().toDate();
			return [start, end];
		}

		default:
			return [undefined, undefined];
	}
};

export const getDateSections = (brokenFiscalYear: boolean) => {
	return [
		{ label: T('date.day'), value: T('date.day'), type: 'day' },
		{ label: T('date.week'), value: T('date.week'), type: 'week' },
		{ label: T('date.month'), value: T('date.month'), type: 'month' },
		{
			label: brokenFiscalYear ? T('date.fiscalquarter') : T('date.quarter'),
			value: brokenFiscalYear ? T('date.fiscalquarter') : T('date.quarter'),
			type: 'quarter',
			fiscal: brokenFiscalYear
		},
		{
			label: brokenFiscalYear ? T('date.fiscalhalfyear') : T('date.halfyear'),
			value: brokenFiscalYear ? T('date.fiscalhalfyear') : T('date.halfyear'),
			type: 'halfyear',
			fiscal: brokenFiscalYear
		},
		{
			label: brokenFiscalYear ? T('date.fiscalyear') : T('date.year'),
			value: brokenFiscalYear ? T('date.fiscalyear') : T('date.year'),
			type: 'year',
			fiscal: brokenFiscalYear
		}
	];
};

export const getValuesFromOptionMap = (options: any) =>
	Array.from(options)
		.map(categories => (categories as any)[1].map((option: any) => option.value))
		.join()
		.split(',');
//#endregion
