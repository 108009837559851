import Resource from 'Resources/ResourceTyped';
import SupportEmailType from './Model/SupportEmail';

class SupportEmail extends Resource<SupportEmailType> {
	constructor() {
		super('master/supportEmails');
	}

	async requestVerificationCode(email: string) {
		return this._postRequest('requestVerificationCode', { email }, { methodName: 'requestVerificationCode' });
	}

	async verifyCode(code: string): Promise<{ data: SupportEmailType }> {
		return this._postRequest('verifyCode', { code }, { methodName: 'verifyCode' }).then(r => r.data);
	}
}

const resource = new SupportEmail();

export default resource;
