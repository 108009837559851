import React, { useMemo } from 'react';
import { OUTCOME_TYPES, NO_ANSWER_ACTIONS, DEFAULT_PRESET, DEFAULT_PRESETS } from 'App/babel/enum/activityOutcome';
import Contact from 'App/resources/Model/Contact';
import T from 'Components/Helpers/translate';
import ActionButton from '../ActionButton';

// TODO: this should be partial ActivityOutcomeState
interface OutcomeStateParams {
	type?: string;
	outcome?: string;
	postpone?: string;
	preset?: null;
	closeActivity?: boolean;
}
interface Props {
	outcome: (typeof NO_ANSWER_ACTIONS)[keyof typeof NO_ANSWER_ACTIONS];
	contact: Contact;
	disabled?: boolean;
	submitted?: boolean;
	toggleOutcomeState: (args: OutcomeStateParams) => void;
	setOutcomeState: (args: OutcomeStateParams) => void;
	openSendMailModal: (contact: Contact) => void;
	renderPostpone: (presets: readonly string[]) => JSX.Element;
	renderStopTrying: () => JSX.Element;
	changeContact: () => JSX.Element;
}
const NoAnswerActions = ({
	outcome,
	contact,
	disabled = false,
	submitted = false,
	toggleOutcomeState,
	openSendMailModal,
	setOutcomeState,
	renderPostpone,
	renderStopTrying,
	changeContact
}: Props) => {
	const noAnswerActions = useMemo(() => {
		const hasEmail = !!contact?.email;
		return [
			{
				code: NO_ANSWER_ACTIONS.POSTPONE_DATE,
				title: T('activity.outcome.postponeDate'),
				icon: 'calendar-arrow-right-o' as const,
				onClick: () => {
					toggleOutcomeState({
						type: OUTCOME_TYPES.NO_ANSWER,
						outcome: NO_ANSWER_ACTIONS.POSTPONE_DATE,
						postpone: DEFAULT_PRESET
					});
				},
				disabled: disabled || submitted
			},
			{
				code: NO_ANSWER_ACTIONS.SEND_MAIL,
				title: T('activity.outcome.sendEmail'),
				icon: 'envelope' as const,
				onClick: () => {
					setOutcomeState({ type: OUTCOME_TYPES.NO_ANSWER });
					openSendMailModal(contact);
				},
				tooltip: T('activity.outcome.contactDoesNotHaveEmail'),
				showTooltip: disabled || (outcome !== NO_ANSWER_ACTIONS.SEND_MAIL && !hasEmail) || submitted,
				disabled: disabled || (outcome !== NO_ANSWER_ACTIONS.SEND_MAIL && !hasEmail) || submitted
			},
			{
				code: NO_ANSWER_ACTIONS.STOP_TRYING,
				title: T('activity.outcome.stopTrying'),
				icon: 'times-circle' as const,
				onClick: () => {
					toggleOutcomeState({
						type: OUTCOME_TYPES.NO_ANSWER,
						outcome: NO_ANSWER_ACTIONS.STOP_TRYING,
						preset: null,
						closeActivity: true
					});
				},
				disabled: disabled
			},
			{
				code: NO_ANSWER_ACTIONS.TALK_TO_SOMEONE,
				title: T('activity.outcome.talkToSomeone'),
				icon: 'user-plus' as const,
				onClick: () => {
					toggleOutcomeState({
						type: OUTCOME_TYPES.NO_ANSWER,
						outcome: NO_ANSWER_ACTIONS.TALK_TO_SOMEONE,
						preset: null,
						closeActivity: true
					});
					changeContact();
				},
				disabled: disabled
			}
		];
	}, [outcome, contact, disabled, submitted]);

	return (
		<div>
			{noAnswerActions.map(action => (
				<ActionButton
					key={action.code}
					action={action}
					outcome={outcome}
					isSelected={outcome === action.code}
				/>
			))}
			{outcome === NO_ANSWER_ACTIONS.POSTPONE_DATE ? renderPostpone(DEFAULT_PRESETS) : null}
			{outcome === NO_ANSWER_ACTIONS.STOP_TRYING ? renderStopTrying() : null}
		</div>
	);
};

export default NoAnswerActions;
