import React from 'react';
import User from 'App/resources/Model/User';
import { Text } from '@upsales/components';
import T from 'Components/Helpers/translate';
import Tooltip from '@upsales/components/Tooltip';

type UsersTestProps = {
	users: Partial<User> & { name: string }[];
	size?: React.ComponentProps<typeof Text>['size'];
	placeholder?: string;
};
const UsersText: React.FC<UsersTestProps> = ({ users, size, placeholder = 'default.noUser' }) => {
	let content: JSX.Element | null = null;
	if (!users.length) {
		content = (
			<Text italic size={size} color="grey-10">
				{T(placeholder)}
			</Text>
		);
	} else if (users.length > 1) {
		const tooltip = users
			.slice(1)
			.map(u => u.name)
			.join('\n');
		content = (
			<Tooltip title={tooltip}>
				<Text size={size}>
					{`${users[0].name} ${T('default.and').toLowerCase()}`}
					<b>{` ${users.length - 1} ${T('filters.more').toLowerCase()}`}</b>
				</Text>
			</Tooltip>
		);
	} else {
		content = <Text size={size}>{users[0].name}</Text>;
	}

	return <div className="UsersText">{content}</div>;
};

export default UsersText;
