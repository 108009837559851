import Resource from 'Resources/Resource';

class GroupMailCategory extends Resource {
	constructor() {
		super('groupmailcategories');
	}
}

const resource = new GroupMailCategory();

export default resource;
