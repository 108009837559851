import React, { useMemo, useRef } from 'react';
import { Table, TableRow, TableColumn, Text, Row, Column, Tooltip } from '@upsales/components';
import bemClass from '@upsales/components/Utils/bemClass';
import './BarchartWidget.scss';
import formatWidgetValue from '../formatWidgetValue';
import BarchartWidgetBar from './BarchartWidgetBar';
import BarchartWidgetBars from './BarchartWidgetBars';
import BarchartWidgetStackedBars from './BarchartWidgetStackedBars';
import { getMaxValues, loadDrilldown, splitRevRecDataIntoSubRows } from '../chartHelper';
import { GenericWidgetProps } from '../ReportcenterWidget';

const generateLoadingRows = (classes: bemClass, height: number, small: boolean) => {
	return [...Array(4 * height)].map((_, i) => {
		return (
			<TableRow key={`loading-${i}`} className={classes.elem('row').mod({ large: !small, loading: true }).b()}>
				{!small ? (
					<>
						<TableColumn>
							<Text loading={true} size="sm" />
						</TableColumn>
						<TableColumn>
							<div className={classes.elem('bar-wrap').b()}>
								<BarchartWidgetBar small={small} loading />
							</div>
						</TableColumn>
					</>
				) : (
					<TableColumn>
						<Row className={classes.elem('small-row-header').b()}>
							<Column>
								<Text size="sm" loading={true} />
							</Column>
							<Column align="right">
								<Text size="sm" loading={true} />
							</Column>
						</Row>
						<div className={classes.elem('bar-wrap').b()}>
							<BarchartWidgetBar small={small} loading />
						</div>
					</TableColumn>
				)}
			</TableRow>
		);
	});
};

type Props = GenericWidgetProps;
const BarchartWidget = ({
	loading = false,
	className,
	width,
	data: data_,
	groupBy,
	drilldown,
	height,
	showGoal,
	config
}: Props) => {
	const isRevRec = ['REVENUE_RECOGNITION_IDEAL', 'REVENUE_RECOGNITION_WEIGHTED'].includes(config.type);
	const data = useMemo(() => (isRevRec ? splitRevRecDataIntoSubRows(data_) : data_), [data_, isRevRec]);

	const ref = useRef<HTMLDivElement | null>(null);
	const small = width < 3;
	const classes = new bemClass('BarchartWidget', className).mod({
		'has-data': !!data.rows.length,
		large: !small,
		loading
	});
	const is2xGroup = groupBy.length === 2;

	const scaleValues = useMemo(() => getMaxValues(data.rows, showGoal || isRevRec), [data]);
	const absMaxValue =
		scaleValues.minValue < 0 ? Math.abs(scaleValues.minValue) + scaleValues.maxValue : scaleValues.maxValue;
	// Add 20% to max value so that we have space to the right (if not small)
	const maxValue = absMaxValue * (small ? 1 : 1.2);
	const BarComponent = is2xGroup || isRevRec ? BarchartWidgetStackedBars : BarchartWidgetBars;

	const rows = data.rows;

	return (
		<div className={classes.b()} ref={ref}>
			<Table>
				{loading ? (
					generateLoadingRows(classes, height, small)
				) : rows.length ? (
					rows.map((row, index) => {
						const valueToDisplay = (isRevRec ? row.goal : row.progress) ?? 0;
						const label = row.label || '';
						const value = formatWidgetValue(valueToDisplay, data.datatype?.progress, data.currency);
						const isNegativeValue = valueToDisplay < 0;
						const isFirstRow = index === 0;
						return (
							<TableRow
								key={row.key}
								className={classes.elem('row').mod({ large: !small }).b()}
								onClick={
									drilldown && data.enableDrilldown
										? e => {
												e.preventDefault();
												loadDrilldown([row.key.toString()], config);
										  }
										: undefined
								}
							>
								{!small ? (
									<>
										<TableColumn
											className={classes
												.elem('barchartColumn')
												.mod({ 'padding-top': scaleValues.minValue && isFirstRow })
												.b()}
										>
											<Tooltip title={label}>
												<Text size="sm">{label}</Text>
											</Tooltip>
										</TableColumn>
										<TableColumn
											className={classes
												.elem('barchartColumn')
												.mod({ 'padding-top': scaleValues.minValue && isFirstRow })
												.b()}
										>
											<BarComponent
												row={row}
												maxValue={maxValue}
												minValue={scaleValues.minValue}
												data={data}
												value={value}
												showGoal={showGoal}
												isFirstRow={isFirstRow}
												isNegativeValue={isNegativeValue}
												drilldown={drilldown}
												config={config}
											/>
										</TableColumn>
									</>
								) : (
									<TableColumn>
										<Row className={classes.elem('small-row-header').b()}>
											<Column>
												<Text size="sm">{label}</Text>
											</Column>
											<Column align="right">
												<Text size="sm" color={isNegativeValue ? 'red' : 'black'} bold>
													{value}
												</Text>
											</Column>
										</Row>
										<BarComponent
											row={row}
											maxValue={maxValue}
											minValue={scaleValues.minValue}
											data={data}
											value={value}
											showGoal={showGoal}
											small
											isFirstRow={isFirstRow}
											drilldown={drilldown}
											config={config}
										/>
									</TableColumn>
								)}
							</TableRow>
						);
					})
				) : (
					<TableRow className={classes.elem('noResultRow').b()}>
						<TableColumn>
							<Text className={classes.elem('noResultRowText').b()} italic>
								{data.language?.noData}
							</Text>
						</TableColumn>
					</TableRow>
				)}
			</Table>
		</div>
	);
};

export default BarchartWidget;
