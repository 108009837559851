import RequestBuilder from 'Resources/RequestBuilder';
import { DataSource } from '../DataSource';

const external = (
	searchString: string,
	dataSource: DataSource,
	fetchMetadata: boolean,
	setHasError: (hasError: boolean) => void,
	limit?: number,
	offset?: number
) => {
	const SoliditetClient = Tools.SoliditetClient;

	const rb = new RequestBuilder();

	rb.limit = limit || 5;
	rb.offset = offset || 0;

	rb.addSort('turnover', false);

	if (searchString.match(/\D{2}\d{6,}|\d{8,}/)) {
		rb.addFilter(SoliditetClient.attr.orgNo, rb.comparisonTypes.WildcardEnd, searchString);
	} else {
		rb.addFilter(SoliditetClient.attr.name, rb.comparisonTypes.WildcardEnd, searchString);
	}

	rb.addFilter(SoliditetClient.attr.status, rb.comparisonTypes.Match, 'Aktiv');

	if (dataSource.currentTab !== 'ALL') {
		rb.addFilter(SoliditetClient.attr.country, rb.comparisonTypes.Equals, dataSource.currentTab);
	}

	const params = {
		type: 'client',
		integrationId: dataSource.id as number,
		data: rb.build()
	};

	if (fetchMetadata && dataSource.countries && dataSource.countries.length) {
		params.data.countries = dataSource.countries;
	}

	return Tools.DataSource.typeahead(params)
		.then(res => {
			return {
				data: res.data,
				metadata: res.metadata,
				searchString
			};
		})
		.catch(error => {
			// This may happen if the integration is down or something like that
			console.error(error);
			setHasError(true);
		});
};

export default external;
