import React from 'react';
import { get } from 'lodash';
import { Text, Link, Icon } from '@upsales/components';
import BemClass from '@upsales/components/Utils/bemClass';
import TimelineRow from 'Components/TimelineRow';
import { ACTIVITY_TIMELINE } from 'App/babel/enum/timeline';
import './index.scss';

const ActivityTimelineStopTrying = ({ event, hideIcon }) => {
	const t = Tools.$translate;
	const lang = {
		didntReached: t('activity.outcomeLog.didntReached'),
		reached: t('activity.outcomeLog.reached'),
		stopTryingActivity: t('activity.outcomeLog.stopTryingActivity'),
		stopTryingPhoneCall: t('activity.outcomeLog.stopTryingPhoneCall'),
		reason: t('activity.outcomeLog.reason'),
		didntFindAnyone: t('activity.outcomeLog.didntFindAnyone'),
		notInterestedNow: t('activity.outcomeLog.notInterestedNow'),
		closedActivity: t('activity.outcomeLog.closedActivity'),
		closedPhoneCall: t('activity.outcomeLog.closedPhoneCall')
	};
	const { subType, data, user, isPhoneCall } = event || {};
	const userName = get(user, 'name');
	const customerId = Tools.AppService.getCustomerId();
	const contactId = get(data, 'contact.id', '');
	const contactName = get(data, 'contact.name', '');
	const reason = get(data, 'reason');
	const closeDate = get(data, 'closeDate');
	let titleText = '';
	const classNames = new BemClass('ActivityTimelineStopTrying');
	const isNoThanks = subType === ACTIVITY_TIMELINE.REACHED_NO_THANKS_STOP_TRYING;
	const entityWasClosed = isPhoneCall ? lang.closedPhoneCall : lang.closedActivity;
	const stopTrying = isPhoneCall ? lang.stopTryingPhoneCall : lang.stopTryingActivity;

	let icon;
	let iconColor;

	switch (subType) {
		case ACTIVITY_TIMELINE.NOT_FOUND_LEAD_STOP_TRYING:
			icon = 'user-times';
			iconColor = 'red';
			break;
		case ACTIVITY_TIMELINE.REACHED_STOP_TRYING:
		case ACTIVITY_TIMELINE.NOT_REACHED_STOP_TRYING:
		case ACTIVITY_TIMELINE.REACHED_NO_THANKS_STOP_TRYING:
		case ACTIVITY_TIMELINE.CLOSED_ACTIVITY:
			icon = 'times-circle';
			break;
	}

	switch (subType) {
		case ACTIVITY_TIMELINE.NOT_REACHED_STOP_TRYING:
			titleText = lang.didntReached;
			break;
		case ACTIVITY_TIMELINE.REACHED_STOP_TRYING:
		case ACTIVITY_TIMELINE.REACHED_NO_THANKS_STOP_TRYING:
			icon = 'times-circle';
			titleText = lang.reached;
			break;
	}

	let title = null;

	switch (subType) {
		case ACTIVITY_TIMELINE.CLOSED_ACTIVITY:
			title = <Text>{entityWasClosed}</Text>;
			break;
		default:
			title = (
				<Text>
					{`${titleText} `}
					{contactId && contactName ? (
						<React.Fragment>
							<Link
								onClick={e => e.stopPropagation()}
								href={Tools.$state.href('contact.dashboard', {
									customerId: customerId,
									id: contactId
								})}
							>
								{contactName}
							</Link>
							{` ${stopTrying}`}
						</React.Fragment>
					) : (
						entityWasClosed
					)}
				</Text>
			);
	}

	const subTitle = closeDate ? (
		<Text size="sm" color="grey-11">
			{moment(closeDate).format('L LT')} <span dangerouslySetInnerHTML={{ __html: '&#8226;' }} /> {userName}
		</Text>
	) : null;
	const firstSection = reason ? (
		<Text>
			<Icon name="comment" space="mrl" color="black" />
			{lang.reason}
			<span dangerouslySetInnerHTML={{ __html: '&#58;&nbsp;' }} />
			{reason}
		</Text>
	) : null;
	const secondSection = isNoThanks ? (
		<Text key="not-interested">
			<Icon name="times-circle" space="mrl" color="black" />
			{lang.notInterestedNow}
		</Text>
	) : null;

	const subSection = [firstSection, secondSection].filter(section => !!section);

	return (
		<TimelineRow
			icon={hideIcon ? undefined : icon}
			iconColor={iconColor}
			className={classNames.mod({ hasDoubleSections: isNoThanks && reason }).b()}
			event={event}
			title={title}
			subTitle={subTitle}
			subSection={subSection}
		/>
	);
};

export default ActivityTimelineStopTrying;
