import Link from './Model/Links';
import ResourceTyped from 'Resources/ResourceTyped';

type LinkEntity = 'sidebar';

class LinksResource extends ResourceTyped<Link> {
	eventName: string;

	constructor() {
		super('links', null, { notificationTitle: 'default.link' });
		this.eventName = 'dynamiclink';
	}

	new(): Omit<Link, 'id'> {
		return {
			color: '002f65',
			entity: 'client',
			href: '',
			icon: 'fa-car',
			target: '_self',
			tooltip: '',
			value: ''
		};
	}

	async find(entity: LinkEntity, id: number) {
		return this._getRequest(`${entity}/${id}`, { methodName: 'find' }).then(r => r.data);
	}

	async save(link: Partial<Link>, opts = {}) {
		return super.save(link, { ...opts, _url: 'link' });
	}
}

const resource = new LinksResource();

export default resource;
