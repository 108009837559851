import React, { useMemo } from 'react';
import { Block, Link, Select, Title } from '@upsales/components';
import moment from 'moment-timezone';
import { FormInput } from '../FormComponent';
import { FormErrorMessages, InputPropMap } from '../FormObserver/FormObserver';
import { FormObserverOnFieldChange } from '../FormObserver';
import BemClass from '@upsales/components/Utils/bemClass';
import './CreateTemplateFlowSettings.scss';
import T from 'Components/Helpers/translate';
import SelectTimeInput from './SelectTimeInput';
import { getAvailableTimezones } from 'App/helpers/DateHelpers';
import CreateFlowUnenrollment from '../CreateFlow/CreateFlowUnenrollment';
import { EditableFlow } from '../CreateFlow/CreateFlow';

type Props = {
	inputProps: InputPropMap<EditableFlow>;
	onFormChange: FormObserverOnFieldChange;
	errorMessages: FormErrorMessages;
};

const CreateTemplateFlowSettings = ({ onFormChange, inputProps, errorMessages }: Props) => {
	const classes = useMemo(() => new BemClass('CreateTemplateFlowSettings'), []);
	const selectDropdownClassName = classes.elem('select-dropdown').b();
	const selectAnchor = document.querySelector('.CreateTemplateFlow');

	const renderTitleTrigger: React.ComponentProps<typeof Select>['renderSelectedItem'] = value => (
		<Link>{value?.title}</Link>
	);
	const timezones = useMemo(() => getAvailableTimezones(), []);
	const hasFlowGoalFeature = Tools.FeatureHelper.hasSoftDeployAccess('FLOW_GOAL');

	return (
		<div className={classes.b()}>
			<FormInput
				space="mbl mtl"
				label={T(inputProps.name.label)}
				inputProps={{
					...inputProps.name,
					state: errorMessages.name ? 'error' : undefined,
					autofocus: true
				}}
				message={errorMessages.name}
			/>

			<div className={classes.elem('timeInput').b()}>
				<Title color="grey-11">
					{T('flow.templateModal.settings.time')}
					<SelectTimeInput
						value={inputProps.startTime.value}
						onChange={id => onFormChange('startTime', id)}
						anchor={selectAnchor}
						max={
							inputProps.endTime.value
								? moment(inputProps.endTime.value, 'LT').subtract(4, 'hours').format('HH:mm')
								: undefined
						}
						step={30}
					/>
					{T('flow.templateModal.settings.and')}

					<SelectTimeInput
						value={inputProps.endTime.value}
						onChange={id => onFormChange('endTime', id)}
						anchor={selectAnchor}
						min={
							inputProps.startTime.value
								? moment(inputProps.startTime.value, 'LT').add(4, 'hours').format('HH:mm')
								: undefined
						}
						step={30}
					/>
				</Title>
			</div>
			<div className={classes.elem('timezone').b()}>
				<Title color="grey-11">
					{T('flow.templateModal.settings.timezone')}
					<Select<{ id: string; title: string; utcOffset: number }>
						value={timezones.find(o => o.id === inputProps.timezone.value) ?? timezones[0]}
						anchor={selectAnchor}
						options={timezones}
						onChange={({ id }) => onFormChange('timezone', id)}
						dropdownClassName={selectDropdownClassName}
						renderSelectedItem={renderTitleTrigger}
						inline
					></Select>
					{T('flow.templateModal.settings.and')}
				</Title>
				<Link onClick={() => onFormChange('skipWeekends', !inputProps.skipWeekends.value)}>
					{T(`flow.templateModal.settings.${inputProps.skipWeekends.value ? 'notOn' : 'on'}`)}
				</Link>
				<Title color="grey-11">{T('flow.templateModal.settings.weekends')}</Title>
			</div>
			{hasFlowGoalFeature ? (
				<Block space="mtxl">
					<CreateFlowUnenrollment inputProps={inputProps} onFormChange={onFormChange} anchor={selectAnchor} />
				</Block>
			) : null}
		</div>
	);
};

export default CreateTemplateFlowSettings;
