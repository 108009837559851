const getNames = entity => {
	switch (entity.toLowerCase()) {
		case 'client':
			return ['default.client', 'default.clients'];
		case 'activity':
			return ['default.activitySinglar', 'default.activities'];
		case 'order':
			return ['default.order.singular', 'default.order.plural'];
		case 'opportunity':
			return ['default.opportunity.singular', 'default.opportunity.plural'];
		case 'project':
			return ['default.project', 'default.projects'];
		case 'userdefinedobject':
		case 'userdefinedobject2':
		case 'userdefinedobject3':
		case 'userdefinedobject4':
			return ['default.userdefinedobject', 'default.userdefinedobjects'];
		case 'agreement':
			return ['agreement.agreement', 'agreement.agreements'];
		case 'contact':
			return ['default.contact', 'default.contacts'];
		case 'segment':
			return ['default.segment', 'default.segments'];
		case 'form':
			return ['form.form', 'form.forms'];
		case 'campaign':
			return ['default.campaign', 'default.campaigns'];
		case 'calllist':
			return ['default.callList', 'default.callLists'];
		case 'appointment':
			return ['default.appointment', 'default.appointments'];
		case 'adcampaign':
			return ['default.adCampaign', 'default.adCampaigns'];
		case 'file':
			return ['file.file', 'file.files'];
		case 'mailsignature':
			return ['default.mailSignature', 'default.mailSignatures'];
		case 'flow':
			return ['default.flow', 'default.flows'];
		case 'segmentandflow':
			return ['default.segmentAndFlow', 'default.segmentsAndFlows'];
		case 'comment':
			return ['default.comment', 'default.comments'];
		case 'account':
			return ['default.account', 'default.accounts'];
		case 'task':
			return ['default.task', 'default.tasks'];
		case 'mail':
			return ['default.mail', 'default.mails'];
		case 'ticket':
			return ['default.ticket', 'default.tickets'];
		default:
			throw new Error('ENTITY NOT IMPLEMENTED: "' + entity + '"');
	}
};

const entityName = (entity, num) => {
	const nameArr = getNames(entity);
	return nameArr[num === 1 ? 0 : 1];
};

export default entityName;
