import RequestBuilder from 'Resources/RequestBuilder';

export default function createActivity(
	rb: RequestBuilder,
	entity: string,
	name?: string | null,
	extraParams?: object,
	numSelected?: number
) {
	var opts = {
		customerId: Tools.AppService.getCustomerId(),
		filters: rb,
		entity,
		name,
		extraParams,
		numSelected
	};

	return Tools.$upModal.open('CreateActivityMulti', opts);
}
