import Attribute from './Attribute';

const StandardIntegrationAttributes = {
	id: new Attribute({
		type: Attribute.types.Number,
		field: 'id'
	}),
	name: new Attribute({
		title: 'default.name',
		type: Attribute.types.String,
		field: 'name'
	}),
	supportEmail: new Attribute({
		title: 'default.supportEmail',
		type: Attribute.types.String,
		field: 'supportEmail'
	}),
	description: new Attribute({
		title: 'default.description',
		type: Attribute.types.String,
		field: 'description'
	}),
	descriptionLong: new Attribute({
		title: 'default.descriptionLong',
		type: Attribute.types.String,
		field: 'description_long'
	}),
	category: new Attribute({
		title: 'category',
		type: Attribute.types.String,
		field: 'category'
	}),
	regDate: new Attribute({
		title: 'regdate',
		type: Attribute.types.Date,
		field: 'reg_date'
	}),
	imageLink: new Attribute({
		title: 'standardintegration.imageLink',
		type: Attribute.types.String,
		field: 'image_link'
	}),
	langTagPrefix: new Attribute({
		field: 'lang_tag_prefix',
		type: Attribute.types.String,
		title: 'standardintegration.langTagPrefix'
	}),
	testUrl: new Attribute({
		title: 'standardintegration.testUrl',
		type: Attribute.types.String,
		field: 'test_url'
	}),
	alias: new Attribute({
		title: 'standardintegration.alias',
		type: Attribute.types.String,
		field: 'alias'
	}),
	env: new Attribute({
		title: 'standardintegration.env',
		type: Attribute.types.Number,
		field: 'env'
	}),
	publisherClientId: new Attribute({
		title: 'standardintegration.env',
		type: Attribute.types.Number,
		field: 'publisher_client_id'
	}),
	publisherUserId: new Attribute({
		title: 'standardintegration.env',
		type: Attribute.types.Number,
		field: 'publisher_user_id'
	}),
	publisherName: new Attribute({
		title: 'standardintegration.env',
		type: Attribute.types.String,
		field: 'publisher_name'
	}),
	public: new Attribute({
		title: 'standardintegration.public',
		type: Attribute.types.Boolean,
		field: 'public'
	}),
	endpoint: new Attribute({
		title: 'standardintegration.endpoint',
		type: Attribute.types.String,
		field: 'endpoint'
	}),
	url: new Attribute({
		title: 'standardintegration.url',
		type: Attribute.types.String,
		field: 'url'
	}),
	price: new Attribute({
		title: 'standardintegration.price',
		type: Attribute.types.Number,
		field: 'price'
	}),
	pricePerUser: new Attribute({
		title: 'standardintegration.pricePerUser',
		type: Attribute.types.Number,
		field: 'price_per_user'
	}),
	userConfigurable: new Attribute({
		title: 'standardintegration.userConfigurable',
		type: Attribute.types.Boolean,
		field: 'user_configurable'
	}),
	authenticationKey: new Attribute({
		title: 'standardintegration.authenticationKey',
		type: Attribute.types.String,
		field: 'authentication_key'
	}),
	configJson: new Attribute({
		title: 'standardintegration.configJson',
		type: Attribute.types.String,
		field: 'config_json'
	}),
	version: new Attribute({
		title: 'standardintegration.version',
		type: Attribute.types.String,
		field: 'version'
	}),
	active: new Attribute({
		title: 'standardintegration.active',
		type: Attribute.types.Boolean,
		field: 'active'
	}),
	modDate: new Attribute({
		title: 'standardintegration.modDate',
		type: Attribute.types.Date,
		field: 'mod_date'
	}),
	color: new Attribute({
		title: 'standardintegration.color',
		type: Attribute.types.String,
		field: 'color'
	}),
	externalConfig: new Attribute({
		title: 'standardintegration.externalConfig',
		type: Attribute.types.Boolean,
		field: 'external_config'
	}),
	standardIntegrationTag: new Attribute({
		title: 'standardintegration.standardIntegrationTag',
		type: Attribute.types.Array,
		field: 'standardIntegrationTag'
	}),
	standardIntegrationInit: new Attribute({
		title: 'standardintegration.standardIntegrationInit',
		type: Attribute.types.Array,
		field: 'standardIntegrationInit'
	}),
	contract: new Attribute({
		title: 'standardintegration.contract',
		type: Attribute.types.Object,
		field: 'contract'
	}),
	userContract: new Attribute({
		title: 'standardintegration.userContract',
		type: Attribute.types.Object,
		field: 'userContract'
	}),
	userOnly: new Attribute({
		type: Attribute.types.Boolean,
		field: 'userOnly'
	})
};

export default StandardIntegrationAttributes;
