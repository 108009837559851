import React, { ComponentProps } from 'react';
import PropTypes from 'prop-types';
import CreateTodo from 'Components/CreateTodo';
import T from 'Components/Helpers/translate';
import { mapProperties, parseProperties } from 'Services/ActionProperties';
import { pick } from 'lodash';
import { CancelablePromise, makeCancelable } from 'App/babel/helpers/promise';
import Client from 'App/resources/Client';
import Contact from 'Resources/Contact';
import logError from 'App/babel/helpers/logError';
import { TagEntity } from 'App/helpers/actionHelpers';
import ClientModel from 'App/resources/Model/Client';
import ContactModel from 'App/resources/Model/Contact';
import OpportunityModel from 'App/resources/Model/Opportunity';
import { ModalProps } from 'App/components/Modals/Modals';

const getClient = (id: number) => {
	return makeCancelable(Client.get(id));
};

const getContact = (id: number) => {
	return makeCancelable(Contact.get(id));
};

const getOpportunity = (id: number) => {
	return makeCancelable(Tools.Opportunity.customer(Tools.AppService.getCustomerId()).get(id));
};

type ActionProperty = {
	name: string;
	value: string | number | { id: number }[];
};

export type CreateTodoTriggerActionProps = ModalProps & {
	properties?: ActionProperty[];
	triggerEntity: TagEntity;
	onSave?: (properties: ActionProperty[]) => void;
};

export type CreateTodoTriggerActionState = {
	Client?: ClientModel;
	Contact?: ContactModel;
	Opportunity?: OpportunityModel;
	Description?: string;
	Date?: Date;
	Time?: string;
	User?: number | string;
	Notes?: string;
	Priority?: 0 | 1 | 2 | 3;
	loading: boolean;
};

class CreateTodoTriggerAction extends React.Component<CreateTodoTriggerActionProps, CreateTodoTriggerActionState> {
	getClientPromise?: CancelablePromise;
	getContactPromise?: CancelablePromise;
	getOpportunityPromise?: CancelablePromise;

	static propTypes = {
		properties: PropTypes.array,
		triggerEntity: PropTypes.string,
		className: PropTypes.string,
		close: PropTypes.func,
		onSave: PropTypes.func
	};
	constructor(p: CreateTodoTriggerActionProps) {
		super(p);
		if (p.properties) {
			this.state = { loading: true, ...parseProperties(p.properties) };
		} else {
			this.state = { loading: false };
		}
	}
	componentDidMount() {
		if (this.state.Client && typeof this.state.Client !== 'object') {
			this.getClientPromise = getClient(this.state.Client);
		}
		if (this.state.Contact && typeof this.state.Contact !== 'object') {
			this.getContactPromise = getContact(this.state.Contact);
		}
		if (this.state.Opportunity && typeof this.state.Opportunity !== 'object') {
			this.getOpportunityPromise = getOpportunity(this.state.Opportunity);
		}
		Promise.all([
			this.getClientPromise?.promise,
			this.getContactPromise?.promise,
			this.getOpportunityPromise?.promise
		])
			.then(([clientRes, contactRes, opportunityRes]) => {
				this.setState({
					loading: false,
					Client: clientRes?.data,
					Contact: contactRes?.data,
					Opportunity: opportunityRes?.data
				});
			})
			.catch(err => {
				logError(err, 'Failed to fetch');
			});
	}
	componentWillUnmount() {
		this.getClientPromise?.cancel();
		this.getContactPromise?.cancel();
		this.getOpportunityPromise?.cancel();
	}
	save = (activity: Parameters<ComponentProps<typeof CreateTodo>['onSave'] & {}>[0]) => {
		const properties = {
			Description: activity.description,
			Date: activity.date,
			User: activity.users ? activity.users[0].id : null,
			ActivityType: Tools.AppService.getTodoTypes().TODO.id,
			Client: activity.client?.id,
			Order: activity.opportunity?.id,
			Contact: activity.contacts?.[0]?.id,
			Time: activity.time,
			Notes: activity.notes || '',
			Priority: activity.priority
		};
		this.props.onSave?.(mapProperties(pick(properties, p => !!p)));
	};
	render() {
		const { close, triggerEntity, className, modalId } = this.props;
		if (!this.state.loading) {
			if (this.props.properties) {
				return (
					<CreateTodo
						className={className}
						modalId={modalId}
						triggerEntity={triggerEntity}
						triggerAction
						onSave={this.save}
						dontSaveTodo={true}
						saveBtnLabel={T('todo.editTodo.save')}
						close={close}
						description={this.state.Description}
						date={this.state.Date}
						time={this.state.Time}
						client={this.state.Client}
						opportunity={this.state.Opportunity}
						contact={this.state.Contact}
						user={this.state.User}
						notes={this.state.Notes}
						priority={this.state.Priority}
					/>
				);
			} else {
				return (
					<CreateTodo
						className={className}
						modalId={modalId}
						triggerEntity={triggerEntity}
						triggerAction
						onSave={this.save}
						dontSaveTodo={true}
						saveBtnLabel={T('todo.editTodo.save')}
						close={close}
					/>
				);
			}
		} else {
			return null;
		}
	}
}
export default CreateTodoTriggerAction;
