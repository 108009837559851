import React, { useState, useMemo, MouseEvent } from 'react';
import { useSelector } from 'react-redux';
import { Icon, Button, Tooltip } from '@upsales/components';

import T from 'Components/Helpers/translate';
import { RootState } from 'Store/index';
import { toggleNotesFilter } from 'Store/slices/NotesFilterActiveSlice';
import { useAppDispatch } from 'App/components/hooks';

const NotesFilterButton = () => {
	const notesFilterActive = useSelector((state: RootState) => state.NotesFilterActive);

	const dispatch = useAppDispatch();
	const [hovered, setHovered] = useState(false);

	const { icon, color } = useMemo(() => {
		if (notesFilterActive) {
			const color = 'white';
			return hovered ? ({ icon: 'comment-slash', color } as const) : ({ icon: 'comment', color } as const);
		}
		const color = 'light-grey';
		return hovered ? ({ icon: 'comment', color } as const) : ({ icon: 'comment-slash', color } as const);
	}, [notesFilterActive, hovered]);

	const title = notesFilterActive ? T('historyLogs.hideCommentsAndNotes') : T('historyLogs.showCommentsAndNotes');

	const onClick = (e: MouseEvent<HTMLButtonElement>) => {
		dispatch(toggleNotesFilter(!notesFilterActive));
		e?.currentTarget?.blur?.();
	};

	return (
		<Tooltip title={title}>
			<Button
				size="sm"
				onClick={onClick}
				onMouseEnter={() => !hovered && setHovered(true)}
				onMouseLeave={() => hovered && setHovered(false)}
				color={color}
			>
				<Icon name={icon} />
			</Button>
		</Tooltip>
	);
};

export default NotesFilterButton;
