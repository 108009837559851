import Resource from './Resource';

class Notes extends Resource {
	constructor() {
		super('notes');
		this.dateFields = ['date'];
	}
}

const resource = new Notes();
window.Tools.Notes = resource;
export default resource;
