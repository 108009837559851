import { numberFormat } from '../Filters/Currencies';
import BemClass from '@upsales/components/Utils/bemClass';
import PropTypes from 'prop-types';
import React from 'react';
import T from 'Components/Helpers/translate';
import './widget.scss';
import {
	Card,
	Block,
	CardHeader,
	CardContent,
	Progressbar,
	Tooltip,
	DropDownButton,
	DropDownMenu,
	Table,
	TableRow,
	TableColumn,
	Text,
	Icon
} from '@upsales/components';
import _ from 'lodash';
import moment from 'moment';

const getPeriodOptions = type => {
	const { userQuotaPeriods } = Tools.AppService.getMetadata();
	switch (type) {
		case 'RECURRING':
		case 'ONEOFF':
		case 'SALES':
		case 'CONTRIBUTION_MARGIN':
			return ['month', 'quarter', 'year'];
		case 'CREATED_PIPELINE':
			return userQuotaPeriods.pipeline === 'weekly'
				? ['week', 'month', 'quarter', 'year']
				: ['month', 'quarter', 'year'];
		case 'COMPLETED_APPOINTMENT':
		case 'BOOKED_APPOINTMENT':
		case 'COMPLETED_FIRST_APPOINTMENT':
		case 'BOOKED_FIRST_APPOINTMENT':
		case 'REACHED_CLIENTS':
			return userQuotaPeriods[type.toLowerCase()] === 'weekly' ||
				!Tools.FeatureHelper.hasSoftDeployAccess('GOALS')
				? ['week', 'month']
				: ['month'];
		default:
			return [];
	}
};
const getSubtitle = period => {
	const capitalize = string => string.charAt(0).toUpperCase() + string.slice(1);
	switch (period) {
		case 'week':
			return `${T('date.week')} ${moment().isoWeek()} ${moment().format('YYYY')}`;
		case 'month':
			return `${capitalize(moment().format('MMMM'))} ${moment().format('YYYY')}`;
		case 'quarter':
			return `Q${moment().quarter()} ${moment().format('YYYY')}`;
		case 'year':
			return `${moment().format('YYYY')}`;
		default:
			return '';
	}
};
class Widget extends React.PureComponent {
	constructor(props) {
		super(props);
	}

	render() {
		const classNames = new BemClass('Widget');
		const {
			type,
			period,
			title,
			size,
			currency,
			goal,
			progress,
			remaining,
			goalLabel,
			surpassedGoalLabel,
			progressLabel,
			remainingLabel,
			pinned,
			onPinChange,
			canPin,
			onPeriodChange,
			group,
			noMargin,
			hidePinned,
			showShadow,
			prognosis,
			prognosisLabel
		} = this.props;

		const layerStyle = {
			position: 'absolute',
			top: 0,
			bottom: 0,
			left: 0,
			right: 0
		};

		const reachedGoal = prognosis ? remaining - prognosis < 0 : remaining < 0;
		const progressValue = (progress / goal) * 100;
		const remainingValue = prognosis ? Math.abs(remaining - prognosis) : Math.abs(remaining);

		const actualRemainingLabel = reachedGoal ? surpassedGoalLabel : remainingLabel;

		const prognosisValue = prognosis ? ((progress + prognosis) / goal) * 100 : 0;
		const goalExists = goal > 0;
		const currencySpan = currency ? (
			<span className={classNames.elem('grid-item-value-currency')}>{currency}</span>
		) : null;
		const periodOptions = getPeriodOptions(type);
		const selected = _.findIndex(periodOptions, item => item === period);
		if (selected === -1) {
			onPeriodChange(periodOptions[0], group, type);
		}
		const subtitle = getSubtitle(period);
		const dropdown = (
			<DropDownMenu
				renderTrigger={(expanded, setExpanded) => (
					<DropDownButton
						icon={'chevron-down'}
						title={subtitle}
						size="sm"
						onClick={setExpanded}
						expanded={expanded}
					/>
				)}
			>
				{periodOptions ? (
					<Table>
						{new Array(periodOptions.length).fill(0).map((_, i) => (
							<TableRow key={'drop-down-menu-' + i}>
								<TableColumn
									className={i === selected ? 'TableColumn--selected' : ''}
									onClick={() => {
										onPeriodChange(periodOptions[i], group, type);
									}}
									size="lg"
									title={
										<Text>
											<Icon space="mrl" name="check" color="bright-blue" />
											{T('goals.current') + ' ' + T('date.' + periodOptions[i]).toLowerCase()}
										</Text>
									}
								/>
							</TableRow>
						))}
					</Table>
				) : null}
			</DropDownMenu>
		);
		return (
			<div className={classNames.mod({ noMargin }).b()}>
				<Card
					className={
						goalExists
							? classNames
									.elem('card')
									.mod({ 'has-prognosis-label': !!prognosis && !!prognosisLabel })
									.b()
							: classNames.elem('card').mod('no-goal').mod({ 'no-box-shadow': !showShadow }).b()
					}
				>
					<CardHeader title={title} subtitle={onPeriodChange ? dropdown : subtitle}>
						{!hidePinned && (
							<>
								{pinned ? (
									<Tooltip title={T('goals.tooltips.shownInMenu')} position="top">
										<div
											onClick={() => onPinChange(type, pinned, group)}
											className={classNames.elem('icon-background').mod('pinned').b()}
										>
											<Icon name="thumbtack" color="bright-blue" />
										</div>
									</Tooltip>
								) : canPin ? (
									<Tooltip title={T('goals.tooltips.pinToMenu')} position="top">
										<div
											onClick={() => onPinChange(type, pinned, group)}
											className={classNames.elem('icon-background').b()}
										>
											<Icon name="thumbtack" color="grey-6" />
										</div>
									</Tooltip>
								) : (
									<Tooltip title={T('goals.tooltips.maxPinned')} position="top">
										<div className={classNames.elem('icon-background').b()}>
											<Icon name="thumbtack" color="grey-6" />
										</div>
									</Tooltip>
								)}
							</>
						)}
					</CardHeader>
					{goalExists ? (
						<CardContent>
							<div className={classNames.elem('progress-bar').b()}>
								<Progressbar
									color="bright-green"
									size={size || 'md'}
									value={prognosisValue}
									style={layerStyle}
									type={'candlestick'}
									className={classNames.elem('prognosis-bar').b()}
								/>
								<Progressbar
									color={prognosis ? 'medium-green' : 'bright-green'}
									size={size || 'md'}
									value={progressValue}
									style={layerStyle}
									type={'candlestick'}
								/>
							</div>
							<div className={classNames.elem('grid').b()}>
								<div className={classNames.elem('grid-item').b()}>
									<div className={classNames.elem('grid-item-value').b()}>
										{numberFormat(goal, false, 0)}
										{currencySpan}
									</div>
									<div className={classNames.elem('grid-item-label').b()}>{goalLabel}</div>
								</div>
								<div className={classNames.elem('grid-item').b()}>
									<div className={classNames.elem('grid-item-value').b()}>
										<span
											className={classNames
												.elem('grid-item-oval')
												.mod({
													'progress-with-prognosis': !!prognosis,
													progress: !prognosis
												})
												.b()}
										/>
										{numberFormat(progress, false, 0)}
										<span className={classNames.elem('grid-item-value-currency').b()}>
											{currency}
										</span>
									</div>
									<div className={classNames.elem('grid-item-label').b()}>{progressLabel}</div>
								</div>

								<div className={classNames.elem('grid-item').b()}>
									{remainingValue ? (
										<div className={classNames.elem('grid-item-value').mod({ reachedGoal }).b()}>
											{!reachedGoal ? (
												<span className={classNames.elem('grid-item-oval').b()} />
											) : null}
											{reachedGoal ? '+' : ''}
											{numberFormat(remainingValue, false, 0)}
											<span className={classNames.elem('grid-item-value-currency').b()}>
												{currency}
											</span>
										</div>
									) : (
										<div className={classNames.elem('grid-item-value').b()}>
											<span className={classNames.elem('grid-item-oval').b()} />
											{numberFormat(remainingValue, false, 0)}
											<span className={classNames.elem('grid-item-value-currency').b()}>
												{currency}
											</span>
										</div>
									)}
									<div className={classNames.elem('grid-item-label').b()}>{actualRemainingLabel}</div>
								</div>
							</div>
							{prognosis && prognosisLabel ? (
								<Block
									backgroundColor="white"
									space="mtl prxl plxl"
									className={classNames.elem('prognosis-label').b()}
								>
									<span className={classNames.elem('grid-item-oval').mod('prognosis').b()} />
									<Text size="sm">{numberFormat(prognosis, false, 0)}</Text>
									<span className={classNames.elem('grid-item-value-currency').mod('prognosis').b()}>
										{currency}
									</span>
									<Text size="sm">{prognosisLabel}</Text>
								</Block>
							) : null}
						</CardContent>
					) : (
						<CardContent>
							<div className={classNames.elem('grid').mod('no-goal').b()}>
								<div className={classNames.elem('grid-item').mod('no-goal').b()}>
									<div className={classNames.elem('grid-item-value').b()}>
										<span className={classNames.elem('grid-item-oval').mod('progress').b()} />
										{numberFormat(progress, false, 0)}
										<span className={classNames.elem('grid-item-value-currency').b()}>
											{currency}
										</span>
									</div>
									<div className={classNames.elem('grid-item-label').b()}>{progressLabel}</div>
								</div>
								<div className={classNames.elem('grid-item').mod('no-goal').b()}>
									{remainingValue ? (
										<div className={classNames.elem('grid-item-value').b()}>
											<span className={classNames.elem('grid-item-oval').mod('remaining').b()} />
											{numberFormat(remainingValue, false, 0)}
											<span className={classNames.elem('grid-item-value-currency').b()}>
												{currency}
											</span>
										</div>
									) : null}
									<div className={classNames.elem('grid-item-label').b()}>
										{remainingValue && actualRemainingLabel ? actualRemainingLabel : null}
									</div>
								</div>
							</div>
						</CardContent>
					)}
				</Card>
			</div>
		);
	}
}

Widget.propTypes = {
	title: PropTypes.string,
	size: PropTypes.string,
	currency: PropTypes.string,
	goal: PropTypes.number,
	goalLabel: PropTypes.string,
	surpassedGoalLabel: PropTypes.string,
	progress: PropTypes.number,
	progressLabel: PropTypes.string,
	remaining: PropTypes.number,
	remainingLabel: PropTypes.string,
	pinned: PropTypes.bool,
	canPin: PropTypes.bool,
	hasRoleData: PropTypes.bool
};

export default Widget;
