import { Icon, ModalControls, ModalContent, FullscreenModal, Text, Flex } from '@upsales/components';
import { ModalProps } from 'App/components/Modals/Modals';
import { PrimaryButton } from '@upsales/components/Buttons';
import { useAnimationData } from 'App/components/hooks';
import { useTranslation } from 'Components/Helpers/translate';
import BemClass from '@upsales/components/Utils/bemClass';
import Headline from '@upsales/components/Headline';
import Lottie from 'react-lottie';
import React from 'react';
import './DuplicateIntroModal.scss';

type DuplicateIntroModalProps<T> = ModalProps<T> & {
	onConfirm: () => void;
	title: string;
	entity: string;
};

const DuplicateIntroModal = <T extends { [key: string]: any }>({
	title,
	entity,
	onConfirm,
	className,
	close
}: DuplicateIntroModalProps<T>) => {
	const classes = new BemClass('DuplicateIntroModal', className);
	const { t } = useTranslation();
	const languageKeys = ['duplicateIntroModal.description1', 'duplicateIntroModal.description2'];

	const { animationData: DuplicateIntro } = useAnimationData(() => import(`./CleaningLotties/${entity}.json`));

	return (
		<FullscreenModal className={classes.b()}>
			<ModalContent>
				<Headline space="mtm mbxl">{title}</Headline>
				<Flex direction="column" gap="u4" space="mtl mbxl">
					{languageKeys.map(key => (
						<Flex alignItems="center" key={key}>
							<Icon name="check" space="mrl" color="grey-11" />
							<Text color="grey-11">{t(key)}</Text>
						</Flex>
					))}
				</Flex>
				<Lottie
					style={{ transform: 'scale(1.33)' }}
					options={{ animationData: DuplicateIntro, loop: false }}
					height={184}
					width={600}
					isClickToPauseDisabled={true}
				/>
			</ModalContent>

			<ModalControls>
				<PrimaryButton
					size="lg"
					onClick={() => {
						close();
						onConfirm();
					}}
				>
					{t('duplicateIntroModal.confirm')}
				</PrimaryButton>
			</ModalControls>
		</FullscreenModal>
	);
};

export default DuplicateIntroModal;
