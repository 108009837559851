import { getJourneyStep, LEAD } from 'Components/Helpers/journeyStep';
import { phoneNumberHelper } from '@upsales/common';
import { SlideFade } from '@upsales/components/animations';
import { Table, TableColumn, TableRow, Text, Block } from '@upsales/components';
import { useTranslation } from 'Components/Helpers/translate';
import Client from 'App/resources/Model/Client';
import Contact from 'App/resources/Model/Contact';
import moment from 'moment';
import React from 'react';
import SalesHistory from 'App/components/SalesHistory';
import type { DetectedDuplicateType } from 'App/resources/Model/DetectedDuplicates';
import UserStack from 'App/components/UserStack';

type DataType = {
	id: number;
	duplicates: DetectedDuplicateType[];
};

type Props = {
	data?: Partial<Client> | Partial<Contact>;
	entity: string;
	isExpanded?: boolean;
	duplicateIsNotSelected: boolean;
	shouldShowMoreRows?: boolean;
};

const RenderTable = ({
	visible,
	tableData,
	greyTextColor = true
}: {
	visible: boolean | undefined;
	tableData: { title: any }[];
	greyTextColor?: boolean;
}) => {
	return (
		<SlideFade visible={visible} height maxHeight={tableData.length * 40} bounce={false}>
			<Block>
				<Table>
					{tableData.map((row, index) => (
						<TableRow key={index}>
							<TableColumn>
								<Text size="sm" color={greyTextColor ? 'grey-10' : 'black'}>
									{row.title}
								</Text>
							</TableColumn>
						</TableRow>
					))}
				</Table>
			</Block>
		</SlideFade>
	);
};

export const ExpandedTableTitles = ({
	shouldShowMoreRows,
	entity,
	item,
	expandedCards
}: {
	shouldShowMoreRows: boolean;
	entity: string;
	item: DataType;
	expandedCards: { [key: string]: boolean };
}) => {
	const { t } = useTranslation();
	const lang = {
		accountManager: t('default.accountManager'),
		created: t('default.regDate'),
		createdBy: t('admin.documentTemplate.createdBy'),
		history: t('default.history'),
		journeyStep: t('default.journeyStep'),
		phone: t('default.phone'),
		title: t('default.title')
	};

	const tableData = [
		{ title: lang.created },
		{ title: lang.createdBy },
		{ title: lang.journeyStep },
		{ title: lang.history }
	];

	if (entity === 'contacts') {
		tableData.splice(0, 0, { title: lang.title });
		tableData.splice(tableData.length - 1, 0, {
			title: lang.phone
		});
	} else if (entity === 'accounts' && shouldShowMoreRows) {
		tableData.splice(0, 0, { title: lang.accountManager });
	}

	return (
		<RenderTable visible={item.duplicates.some(duplicate => expandedCards[duplicate?.id])} tableData={tableData} />
	);
};

export const ExpandedTableValues = ({
	data,
	entity,
	isExpanded,
	duplicateIsNotSelected,
	shouldShowMoreRows
}: Props) => {
	const entityName = entity === 'accounts' ? 'client' : 'contact';

	const tableData = [
		{ title: moment(data?.regDate).format('L') },
		{ title: data?.regBy?.name || '-' },
		{ title: getJourneyStep(data?.journeyStep || LEAD)?.name },
		{ title: data ? <SalesHistory entity={data} entityName={entityName} /> : '-' }
	];

	if (entity === 'contacts') {
		tableData.splice(0, 0, { title: (data as Partial<Contact>)?.title || '-' });
		tableData.splice(tableData.length - 1, 0, {
			title: phoneNumberHelper.formatNumber((data?.phone || '').toString() || '-')
		});
	} else if (entity === 'accounts' && shouldShowMoreRows) {
		tableData.splice(0, 0, {
			title: (data as Partial<Client>)?.users?.length ? (
				<UserStack
					users={(data as Partial<Client>).users?.map(({ name, id, email }) => ({ name, id, email })) || []}
				/>
			) : (
				'-'
			)
		});
	}

	return <RenderTable visible={isExpanded} tableData={tableData} greyTextColor={duplicateIsNotSelected} />;
};
