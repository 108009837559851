import { Avatar, Column, TableColumn, TableRow, Text, Tooltip } from '@upsales/components';
import { useTranslation } from 'Components/Helpers/translate';
import BemClass from '@upsales/components/Utils/bemClass';
import { dateCalendar } from 'App/helpers/DateHelpers';
import Ticket from 'App/resources/Model/Ticket';
import { openDrawer } from 'Services/Drawer';
import './ViewTicketsTableRow.scss';
import moment from 'moment';
import React from 'react';

const ViewTicketsTableRow = ({ row }: { row: Ticket }) => {
	const { t } = useTranslation();
	const classes = new BemClass('ViewTicketsTableRow');
	const regDate = moment(row.regDate).toDate();

	return (
		<TableRow
			key={row.id}
			onClick={() => {
				openDrawer('EditTicket', { ticketId: row.id });
			}}
		>
			<TableColumn className={classes.elem('subjectColumn').b()}>
				<Column>
					<Tooltip title={row.title} disabled={row.title.length < 48}>
						<Text ellipsis>{row.title}</Text>
					</Tooltip>
				</Column>
			</TableColumn>
			<TableColumn>
				<Column>
					<Text>
						{row.status.closed ? dateCalendar(row.resolveDate!, true) : dateCalendar(regDate, true)}
					</Text>
				</Column>
			</TableColumn>
			<TableColumn>
				<Column>
					{row.user?.name ? (
						<Tooltip position="bottom" title={row.user?.name}>
							<Avatar
								initials={row.user?.name ? row.user.name : t('ticket.widget.assigneeUnassigned')}
								email={row.user?.email}
								size="sm"
								style={{ verticalAlign: 'left' }}
							/>
						</Tooltip>
					) : (
						<Text>{t('ticket.widget.assigneeUnassigned')}</Text>
					)}
				</Column>
			</TableColumn>
		</TableRow>
	);
};
export default ViewTicketsTableRow;
