import React from 'react';
import bemClass from '@upsales/components/Utils/bemClass';
import { TableRow } from '@upsales/components';
import './TableWidget.scss';
import { RCWidgetData } from 'Resources/ReportWidget';
import { RCDashboardWidget } from 'Resources/ReportDashboard';
import Cell from './Cell';

type DrilldownProps = {
	enableDrilldown: boolean;
	config: RCDashboardWidget;
};

type KeyLabelSet = {
	key: string;
	label: string;
	tooltip?: string;
	dotColor?: string;
};

type RowProps = {
	classes: bemClass;
	columnOrder: KeyLabelSet[];
	rowMap:
		| Map<string, { displayValue: string | Function | undefined; rawValue: string | number | null } | undefined>
		| undefined;
	drilldown: DrilldownProps;
	rowKey: string;
	colors: RCWidgetData['colors'];
	currency: RCWidgetData['currency'];
};

const Row = ({ classes, columnOrder, rowMap, drilldown, rowKey, colors, currency }: RowProps) => {
	return rowMap ? (
		<TableRow className={classes.elem('row').b()}>
			{columnOrder.map((column, i) => (
				<Cell
					classes={classes}
					selectable={i > 0}
					key={column.key}
					value={rowMap.get(column.key) ?? { displayValue: '0', rawValue: null }}
					drilldown={drilldown}
					rowKey={rowKey}
					dotColor={column.dotColor}
					columnKey={column.key}
					colors={colors}
					currency={currency}
				/>
			))}
		</TableRow>
	) : null;
};
export default Row;
