function generate() {
	return {
		inactive: false,
		comparisonType: 'Search',
		value: []
	};
}

function isInactive(filter) {
	return !filter.value || !filter.value.length;
}

function toUrl(filter) {
	return { v: filter.value, c: filter.comparisonType, i: filter.inactive };
}

function fromUrl(rawFilter) {
	const filter = generate();
	filter.value = rawFilter.v;
	filter.comparisonType = rawFilter.c;
	filter.inactive = rawFilter.hasOwnProperty('i') ? rawFilter.i : isInactive(filter);
	return filter;
}

function build(filter, rb) {
	const value = filter.value;
	if (filter.inactive || !value?.length) {
		return;
	}
	const field = 'description';
	const comparison = filter.comparisonType === 'Search' ? 'mand' : 'mor';
	rb.addFilter({ field }, comparison, value);
}

const filter = {
	filterName: 'keywords',
	type: 'raw',
	displayType: 'text',
	comparisonType: 'Search',
	title: 'default.keywords',
	entity: 'account',
	showOnSegment: true,
	inputTrueText: 'default.all',
	inputFalseText: 'default.any',
	tooltip: 'prospecting.manually.keywordsClarification',
	generate,
	isInactive,
	toUrl,
	fromUrl,
	build
};

export default filter;
