import React from 'react';
import bemClass from '@upsales/components/Utils/bemClass';
import { Icon, Tooltip, Text } from '@upsales/components';

import './BouncyThumbtack.scss';

interface Props {
	pinned: boolean;
	onToggle: (v: boolean) => void;
	pinnedTooltip: string;
	unPinnedTooltip: string;
	tooltipPosition?: React.ComponentProps<typeof Tooltip>['position'];
	tooltipDistance?: React.ComponentProps<typeof Tooltip>['distance'];
}

export const BouncyThumbtack: React.FC<Props> = ({
	pinned,
	onToggle,
	pinnedTooltip,
	unPinnedTooltip,
	tooltipPosition = 'top',
	tooltipDistance = 25
}) => {
	const classes = new bemClass('BouncyThumbtack');

	return (
		<div className={classes.b()}>
			{pinned ? (
				<Tooltip title={pinnedTooltip} position={tooltipPosition} distance={tooltipDistance}>
					<Text>
						<Icon name="thumbtack-pinned" onClick={() => onToggle(false)} />
					</Text>
				</Tooltip>
			) : (
				<Tooltip title={unPinnedTooltip} position={tooltipPosition} distance={tooltipDistance}>
					<Text>
						<Icon
							className={classes.elem('inactive').b()}
							name="thumbtack"
							onClick={() => onToggle(true)}
						/>
					</Text>
				</Tooltip>
			)}
		</div>
	);
};

export default BouncyThumbtack;
