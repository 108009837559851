import {
	Dispatch,
	MailState,
	SET_FROM,
	SET_SUBJECT,
	SET_MAIL_BODY,
	SAVE_TEMPLATE,
	SET_RECIPIENTS,
	REMOVE_TEMPLATE,
	SET_RECIPIENTS_CC,
	SET_RECIPIENTS_BCC,
	INITIALIZE,
	FORWARD,
	REPLY,
	SEND_SUCCESS,
	SEND_FAILED,
	SEND_PENDING
} from './MailContext';
import { removeItem } from 'Store/helpers/array';
import MailTemplate from 'App/resources/Model/MailTemplate';
import MailTemplateResource from 'Resources/MailTemplate';
import MailResource from 'Resources/Mail';
import MailIntegration from 'Resources/MailIntegration';
import ContactResource from 'Resources/Contact';
import logError from 'App/babel/helpers/logError';
import { MailRecipient } from 'App/resources/Model/Mail';
import T from 'Components/Helpers/translate';
import moment from 'moment';
import { parseSignatureTags } from 'App/babel/ckeditor/helpers/signatureHelper';
import MailSignature from 'App/resources/Model/MailSignature';

const cutSubjectIfTooLong = (subject: string, langTag: string) => {
	const MAX_SUBJECT_SIZE = 150;
	if (subject.length > MAX_SUBJECT_SIZE - langTag.length) {
		subject = subject.slice(0, MAX_SUBJECT_SIZE - langTag.length);
	}
	return subject;
};
export const forward = (subject: MailState['subject']) => ({
	type: FORWARD,
	subject: T('mailDrawer.fw') + cutSubjectIfTooLong(subject ?? '', T('mailDrawer.fw'))
});
export const reply = (subject: MailState['subject'], to: MailRecipient[], cc: MailRecipient[] = []) => ({
	type: REPLY,
	subject: T('mailDrawer.re') + cutSubjectIfTooLong(subject ?? '', T('mailDrawer.re')),
	to,
	cc
});
export const setSubject = (subject: MailState['subject']) => ({ type: SET_SUBJECT, subject });
export const setBody = (body: MailState['body']) => ({ type: SET_MAIL_BODY, body });
export const setRecipientsCc = (cc: MailState['recipients']['cc'], client: MailState['client']) => ({
	type: SET_RECIPIENTS_CC,
	cc,
	client
});
export const setRecipientsBcc = (bcc: MailState['recipients']['bcc'], client: MailState['client']) => ({
	type: SET_RECIPIENTS_BCC,
	bcc,
	client
});

export const setFrom = (from: MailState['from']) => ({ type: SET_FROM, from });

const getClientFromTo = (state: Partial<MailState>) => {
	const recipientWithClient = state.recipients!.to.find(c => !!c.client);
	return recipientWithClient?.client;
};

const getFrom = async (): Promise<MailState['from']> => {
	const self = Tools.AppService.getSelf();
	try {
		const from = await MailIntegration.getProfile();
		return from ?? self;
	} catch (err) {
		logError(err, 'Failed loding mail integration profile');
		return self;
	}
};

export const init = async (dispatch: Dispatch, state: Partial<MailState>) => {
	if (!state.client && state.recipients?.to?.length) {
		state.client = getClientFromTo(state);
	}
	// If still no client, but only one recipient, try to fetch this contact's client
	if (!state.client && state.recipients?.to.length === 1 && state.recipients?.to[0].id) {
		const { data } = await ContactResource.find({ id: state.recipients?.to[0].id });
		if (data[0]?.client?.id) {
			state.client = data[0].client;
			if (!state.recipients?.to[0].client) {
				state.recipients!.to[0].client = data[0].client;
			}
		}
	}
	let body;
	let mailSignatures: MailSignature[];
	if (Tools.FeatureHelper.isAvailable(Tools.FeatureHelper.Feature.MAIL_SIGNATURE)) {
		try {
			({ data: mailSignatures } = await Tools.MailSignatures.getActiveSignatures());
		} catch (err) {
			mailSignatures = [];
			logError(err, 'failed to fetch active mail signatures');
		}
	} else {
		mailSignatures = [];
	}
	const { defaultSignature: defaultSignatureId } = Tools.AppService.getSelf().userParams;
	const defaultSignature = mailSignatures?.find(signature => signature.id === defaultSignatureId);
	if (defaultSignature) {
		const { endDate, startDate } = defaultSignature;

		if (!startDate) {
			body = '<br>' + (await parseSignatureTags(defaultSignature.body));
		} else {
			if (moment().isSameOrAfter(startDate)) {
				if (!endDate) {
					body = '<br>' + (await parseSignatureTags(defaultSignature.body));
				} else if (moment().isBefore(endDate)) {
					body = '<br>' + (await parseSignatureTags(defaultSignature.body));
				}
			}
		}
	}

	const from = await getFrom();
	dispatch({
		type: INITIALIZE,
		state: { ...state, body: state.body ?? body ?? '', mailSignatures, from: { ...from, locked: true } }
	});
};

const formatMail = (state: Partial<MailState>, overrides: { [key: string]: unknown } = {}) => {
	// Temp, as long as we require a contact and client
	// should be like this in step 2
	// const contact = state.recipients?.to?.find(recipient => recipient.type === 'contact' && !!recipient.client);
	const contact = state.recipients?.to?.find(recipient => recipient.type === 'contact');
	const contactId = contact?.id;
	const clientId = contact?.client?.id ?? state.client?.id;

	/**
	 * God dam ui/app/resources/genericMapper.ts mapDate transformation browser time to Upsales time (that which you have in your profile settings).
	 * E.g. if the user is in Sweden (UTC+1) and Upsales time is in Finland (UTC+2), and the user presses send at 10:00 Swedish time,
	 * then the date will be moved to 10:00 in finish time, i.e. one hour in the future.
	 * But the user wants to send it NOW when they press send.
	 * So have to do the backwards transformation of what mapDate does here...
	 */
	const browserOffset = moment().utcOffset();
	const userOffset = moment.tz(Tools ? Tools.userTimezone : 'Europe/Stockholm').utcOffset();
	const offset = userOffset - browserOffset;
	const date = moment().add(offset, 'm').format();

	return {
		from: state?.from?.email,
		fromName: state?.from?.name,
		subject: state?.subject,
		body: state?.body,
		template: state?.template?.id
			? {
					id: state?.template?.id
			  }
			: undefined,
		recipients: state?.recipients,
		date,
		type: state.mailType,
		userId: Tools.AppService.getSelf().id,
		// TODO: If we in the future wan't to support sending to users and not only contacts,
		// the backend will need a major refactor
		client: clientId ? { id: clientId } : undefined,
		contact: contactId ? { id: contactId } : undefined,
		clientId,
		contactId,
		appointment: state.appointment,
		activity: state.activity,
		opportunity: state.opportunity,
		attachments: state.attachments,
		...overrides
	};
};

// TODO (Marcus, 2022-08-02): Take back this once we support multiple recipients
// const sendWithNewRecipientFormat = (mail: ReturnType<typeof formatMail>, integrationId: number) => {
// 	console.log('sending mail', mail)
// 	return MailIntegration.send(mail, {
// 		params: { integrationId, skipEvent: true }
// 	});
// };

const sendWithOldRecipientFormat = async (mail: ReturnType<typeof formatMail>, integrationId: number) => {
	const getEmail = (value: string | { email: string }) => {
		if (typeof value === 'string') {
			return value;
		} else {
			return value.email;
		}
	};

	const oldRecipientFormat = {
		recipients: mail?.recipients?.to.map(contact => ({
			client: contact?.client?.id ?? mail.client?.id,
			contact: contact.id,
			to: contact.email
		})),
		cc: mail?.recipients?.cc.map(getEmail),
		bcc: mail?.recipients?.bcc.map(getEmail)
	};

	const response = await MailIntegration.saveMulti(
		{ ...mail, ...oldRecipientFormat },
		{
			params: { integrationId }
		}
	);

	if (response.data?.length === 1) {
		response.data = response.data?.[0];
	}
	return response;
};

export const send = async (
	dispatch: Dispatch,
	state: Partial<MailState>,
	overrides: { [key: string]: unknown } = {}
) => {
	const integration = Tools.AppService.getMailIntegration()?.id;

	if (integration && state.recipients?.to?.length && state.subject && state.from) {
		const mail = formatMail(state, overrides);
		dispatch({ type: SEND_PENDING });
		try {
			const response = await sendWithOldRecipientFormat(mail, integration);
			dispatch({ type: SEND_SUCCESS, id: response.data?.id });
			return response.data;
		} catch (error) {
			logError(error, 'could not send mail');
			dispatch({ type: SEND_FAILED, error });
		}
	} else {
		logError(null, 'missing requirements to send email');
	}
};

export const scheduleMail = async (dispatch: Dispatch, state: MailState, date: Date, time: string) => {
	if (date) {
		let fixedDate = null;
		if (time) {
			fixedDate = new Date(date);
			const [hours, minutes] = time.split(':');
			fixedDate.setHours(parseInt(hours), parseInt(minutes), 0, 0);
			fixedDate = moment(fixedDate).format();
		} else {
			fixedDate = moment(date).format('YYYY-MM-DD');
		}
		return send(dispatch, state, { type: 'sch', date: fixedDate });
	}
};

export const sendTest = async (dispatch: Dispatch, state: MailState, mailAddresses: string[]) => {
	const data = {
		mail: formatMail(state, {
			to: mailAddresses
		})
	};
	try {
		await MailResource.sendTest(data);
	} catch (error) {
		logError(error, 'failed to send test mail');
	}
};

/* Add functions */
export const addRecipient = (dispatch: Dispatch, state: MailState, recipient: MailRecipient) => {
	const {
		client,
		recipients: { to }
	} = state;

	const exists = to.some(r => r.email === recipient.email);

	if (exists || (client && recipient.client && client.id !== recipient.client.id)) {
		// Needs to be the same client among contacts
		return;
	}
	dispatch({
		type: SET_RECIPIENTS,
		client: recipient.client || client,
		to: [...to, { ...recipient }]
	});
};
export const addRecipientCc = (dispatch: Dispatch, state: MailState, recipient: MailRecipient) => {
	const {
		client,
		recipients: { cc }
	} = state;

	const exists = cc.some(r => r.email === recipient.email);
	if (exists || (client && recipient.client && client.id !== recipient.client.id)) {
		// Needs to be the same client among contacts
		return;
	}
	dispatch(setRecipientsCc([...cc, recipient], recipient.client || client));
};
export const addRecipientBcc = (dispatch: Dispatch, state: MailState, recipient: MailRecipient) => {
	const {
		client,
		recipients: { bcc }
	} = state;
	const exists = bcc.some(r => r.email === recipient.email);
	if (exists || (client && recipient.client && client.id !== recipient.client.id)) {
		// Needs to be the same client among contacts
		return;
	}
	dispatch(setRecipientsBcc([...bcc, recipient], recipient.client || client));
};

/* Remove functions */
export const removeRecipient = (dispatch: Dispatch, state: MailState, removedRecipient: MailRecipient) => {
	const {
		recipients: { to }
	} = state;
	const index = to.findIndex(r =>
		removedRecipient.type === 'other'
			? r.email === removedRecipient.email
			: r.id === removedRecipient.id && r.type === removedRecipient.type
	);

	if (index !== -1) {
		const recipients = removeItem(to, index);
		dispatch({
			type: SET_RECIPIENTS,
			to: recipients,
			client: recipients.length ? state.client : undefined
		});
	}
};

export const removeRecipientCc = (dispatch: Dispatch, state: MailState, removedRecipient: MailRecipient) => {
	const {
		recipients: { cc }
	} = state;
	const index = cc.findIndex(r =>
		removedRecipient.type === 'other'
			? r.email === removedRecipient.email
			: r.id === removedRecipient.id && r.type === removedRecipient.type
	);

	if (index !== -1) {
		const recipients = removeItem(cc, index);
		dispatch(setRecipientsCc(recipients, recipients.length ? state.client : undefined));
	}
};

export const removeRecipientBcc = (dispatch: Dispatch, state: MailState, removedRecipient: MailRecipient) => {
	const {
		recipients: { bcc }
	} = state;
	const index = bcc.findIndex(r =>
		removedRecipient.type === 'other'
			? r.email === removedRecipient.email
			: r.id === removedRecipient.id && r.type === removedRecipient.type
	);

	if (index !== -1) {
		const recipients = removeItem(bcc, index);
		dispatch(setRecipientsBcc(recipients, recipients.length ? state.client : undefined));
	}
};

/* Template functions */
export const onTemplateChange = async (dispatch: Dispatch, template: MailTemplate) => {
	const { bodyJson, ...data } = template;
	let body = '';
	if (bodyJson) {
		const [{ data: extraCss }, { data: profile }] = await Promise.all([
			Tools.InkyParser.getCss(false),
			Tools.AccountProfile.get()
		]);
		({ data: body } = await Tools.InkyParser.parse(bodyJson, { extraCss, profile }));
	} else {
		body = template.body;
	}

	dispatch({ type: SAVE_TEMPLATE, ...data, body });
};

export const onTemplateUpdate = async (dispatch: Dispatch, state: MailState) => {
	const updatedTemplate = {
		id: state.template.id,
		body: state.body,
		subject: state.subject,
		attachments: state.attachments
	};
	MailTemplateResource.save(updatedTemplate)
		.then(({ data }) => {
			dispatch({ type: SAVE_TEMPLATE, ...data, overrideAttachments: true });
		})
		.catch(err => {
			logError(err, 'Could not update template');
		});
};

export const onTemplateRemove = (dispatch: Dispatch) => {
	dispatch({ type: REMOVE_TEMPLATE });
};

export const onTemplateSave = (
	dispatch: Dispatch,
	state: MailState,
	name: string,
	subject: string,
	isPrivate: boolean
) => {
	const template = {
		body: state.body,
		subject,
		name,
		private: isPrivate ? 1 : 0,
		user: Tools.AppService.getSelf().id,
		attachments: state.attachments
	};
	MailTemplateResource.save(template)
		.then(res => {
			const data = res.data;
			dispatch({
				type: SAVE_TEMPLATE,
				body: state.body,
				name,
				subject,
				id: data.id,
				attachments: data.attachments,
				overrideAttachments: true
			});
		})
		.catch(err => {
			logError(err, 'Could not save template');
		});
};
