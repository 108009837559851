import SalesboardResource from '../../resources/Salesboard';
import sortAlphabetically from '../../utils/sortAlphabetically';
import openModal from 'App/services/Modal';

/*
	The Object.freeze as these objects is never ment to be mutated.
	The reason they are there is so that i can have less if statements in the components
*/
export const initialState = {
	salesboard: Object.freeze(SalesboardResource.new()),
	referenceSalesboard: Object.freeze(SalesboardResource.new()),
	saving: false,
	openColumns: {}
};

const actionPrefix = '[AdminEditSalesboard]';
export const actions = {
	INIT: `${actionPrefix} INIT`,
	SALESBOARD_CHANGED: `${actionPrefix} SALESBOARD_CHANGED`,
	COLUMN_ADDED: `${actionPrefix} COLUMN_ADDED`,
	COLUMN_DELETED: `${actionPrefix} COLUMN_DELETED`,
	COLUMN_CHANGED: `${actionPrefix} COLUMN_CHANGED`,
	SET_SAVING: `${actionPrefix} SET_SAVING`,
	SAVE_DONE: `${actionPrefix} SAVE_DONE`,
	START_EDIT_COLUMN: `${actionPrefix} START_EDIT_COLUMN`,
	STOP_EDIT_COLUMN: `${actionPrefix} STOP_EDIT_COLUMN`
};

function omit(key, obj) {
	if (!obj) return {};
	const { [key]: omitted, ...rest } = obj; //eslint-disable-line
	return rest;
}

export const reducer = (state = initialState, action) => {
	switch (action.type) {
		case actions.INIT:
			return { ...initialState, ...action.data };
		case actions.SALESBOARD_CHANGED: {
			const salesboard = state.salesboard;
			return { ...state, salesboard: { ...salesboard, ...action.data } };
		}
		case actions.COLUMN_ADDED: {
			const salesboard = state.salesboard;
			const openColumns = { ...state.openColumns, [action.data.$id]: true };
			const columns = [...salesboard.columns, action.data];
			return { ...state, salesboard: { ...salesboard, columns }, openColumns };
		}
		case actions.START_EDIT_COLUMN: {
			return { ...state, openColumns: { ...state.openColumns, [action.data.id]: true } };
		}
		case actions.STOP_EDIT_COLUMN: {
			return { ...state, openColumns: omit([action.data.id], state.openColumns) };
		}
		case actions.SET_SAVING:
		case actions.SAVE_DONE:
		case actions.COLUMN_DELETED:
		case actions.COLUMN_CHANGED:
			return { ...state, ...action.data };
		default:
			return state;
	}
};

export const getSalesBoardFromId = id => {
	if (id) {
		const salesboards = Tools.AppService.getListViews('salesboard');
		const salesboard = _.find(salesboards, { id: parseInt(id) });

		return salesboard ? salesboard : SalesboardResource.new({ standard: true });
	} else {
		return SalesboardResource.new({ standard: true });
	}
};

/*
	I don't know if old salesboards may have funcy sortOrders so I do this for saftey.
	And by sorting them here I don't have to do a sort on every render.
*/
export const fixColumnSort = columns => {
	/* _.sortBy is a stable sort, which is what we want here */
	return _.sortBy(columns, column => column.sortOrder).map((column, index) => {
		column.sortOrder = index;
		return column;
	});
};

export const saveSalesboard = () => async (dispatch, getState) => {
	const salesboard = getState().AdminEditSalesboard.salesboard;

	dispatch({ type: actions.SET_SAVING, data: { saving: true } });

	const {
		data: { id }
	} = await SalesboardResource.save(salesboard);
	/*
	We are not using the result of the save for the reason that it wont have the $id keys on the column and they are
	needed to render correctly with the drag and drop sorting. And it is hard to match the newly saved columns with the exisitng as two columns could
	potentially look the same.
	*/
	dispatch({
		type: actions.SAVE_DONE,
		data: { referenceSalesboard: { ...salesboard, id }, salesboard: { ...salesboard, id }, saving: false }
	});

	Tools.$state.go('administration.salesboard', { id }, { reload: false, notify: false });
};

let fakeIdCounter = 1;
let hasShownStateChangeWarning = false;
let modalIsOpen = false;

export const init = ($scope, id) => (dispatch, getState) => {
	hasShownStateChangeWarning = false;
	modalIsOpen = false;

	$scope.$on('$stateChangeStart', function (e, toState, toStateParams, fromState, fromStateParams, options) {
		const { salesboard, referenceSalesboard } = getState().AdminEditSalesboard;
		const isDirty = !_.isEqual(salesboard, referenceSalesboard) ? true : false;

		if (!hasShownStateChangeWarning && isDirty && !modalIsOpen) {
			modalIsOpen = true;
			e.preventDefault();

			if (Tools.FeatureHelper.hasSoftDeployAccess('REACT_ALERT_MODAL')) {
				openModal('UnsavedChangesAlert', {
					onClose: async confirmed => {
						if (confirmed === undefined) {
							return;
						}
						modalIsOpen = false;
						hasShownStateChangeWarning = true;
						if (confirmed) {
							await dispatch(saveSalesboard());
						}
						Tools.$state.go(toState.name, toStateParams, options);
					}
				});
				return;
			}

			const resolveFalse = salesboard.columns.length ? 'default.save' : undefined;
			// eslint-disable-next-line promise/catch-or-return
			Tools.$upModal
				.open('infoConfirm', {
					title: 'default.saveChanges',
					body: 'confirm.changesWillBeLost',
					icon: 'fa-exclamation-triangle',
					resolveFalse: resolveFalse,
					resolveFalseBtnClass: 'btn-bright-blue',
					resolveTrue: 'default.discardChanges',
					resolveTrueBtnClass: 'btn-bright-blue btn-lined'
				})
				.then(async skipSave => {
					hasShownStateChangeWarning = true;

					if (skipSave === false) {
						await dispatch(saveSalesboard());
					}

					Tools.$state.go(toState.name, toStateParams, options);
				});
		}
	});

	fakeIdCounter = 1;
	const salesboard = getSalesBoardFromId(id);
	salesboard.columns = fixColumnSort(salesboard.columns);

	for (const column of salesboard.columns) {
		column.$id = fakeIdCounter++;
	}
	const referenceSalesboard = _.cloneDeep(salesboard);

	return dispatch({ type: actions.INIT, data: { salesboard, referenceSalesboard } });
};

export const updateSalesboard = data => {
	return { type: actions.SALESBOARD_CHANGED, data };
};

export const startEditColumn = id => {
	return { type: actions.START_EDIT_COLUMN, data: { id } };
};

export const stopEditColumn = id => {
	return { type: actions.STOP_EDIT_COLUMN, data: { id } };
};

export const sortColumns = (startIndex, endIndex) => (dispatch, getState) => {
	const { salesboard } = getState().AdminEditSalesboard;
	const columns = _.cloneDeep(salesboard.columns);

	const [removed] = columns.splice(startIndex, 1);
	columns.splice(endIndex, 0, removed);
	columns.forEach((column, index) => {
		column.sortOrder = index;
	});

	dispatch({ type: actions.SALESBOARD_CHANGED, data: { columns } });
};

export const saveColumn = column => (dispatch, getState) => {
	const { salesboard, openColumns } = getState().AdminEditSalesboard;

	const updatedColumns = _.map(salesboard.columns, currentColumn =>
		currentColumn.$id === column.$id ? column : currentColumn
	);
	const updatedSalesboard = { ...salesboard, columns: updatedColumns };
	const updatedOpenColumns = omit([column.$id], openColumns);

	dispatch({
		type: actions.COLUMN_CHANGED,
		data: { salesboard: updatedSalesboard, openColumns: updatedOpenColumns }
	});
};

export const deleteColumn = column => (dispatch, getState) => {
	const { salesboard, openColumns } = getState().AdminEditSalesboard;

	const updatedColumns = fixColumnSort(salesboard.columns.filter(c => c.$id !== column.$id));
	const updatedSalesboard = { ...salesboard, columns: updatedColumns };
	const updatedOpenColumns = omit([column.$id], openColumns);

	dispatch({
		type: actions.COLUMN_DELETED,
		data: { salesboard: updatedSalesboard, openColumns: updatedOpenColumns }
	});
};

export const addNewColumn = type => (dispatch, getState) => {
	const salesboard = getState().AdminEditSalesboard.salesboard;
	dispatch({
		type: actions.COLUMN_ADDED,
		data: {
			sorting: [],
			filters: [],
			image: '',
			title: '',
			type,
			sortOrder: salesboard.columns.length,
			$id: fakeIdCounter++,
			isNew: true
		}
	});
};

const dateFilterData = [
	'whenever',
	'currentMonth',
	'currentQuarter',
	'untilToday',
	'fromToday',
	'untilTomorrow',
	'last7days',
	'last30days',
	'last45days',
	'last90days',
	'prev6Month',
	'prev12Month',
	'prev18Month',
	'prev24Month',
	'custom'
];

export const columnConfigs = {
	order: {
		title: 'default.orders',
		dateFilter: {
			title: 'default.closeDate2',
			field: 'date',
			filterName: 'Date',
			data: () => dateFilterData
		},
		filter: {
			title: 'default.stages',
			field: 'stage.id',
			filterName: 'Stage',
			multiple: true,
			data: () => Tools.AppService.getStages('order')
		},
		getSortable: () => {
			var t = Tools.$translate;

			return [
				{ id: 'date', name: t('default.closeDate2') },
				{ id: 'client.name', name: t('default.account') },
				{ id: 'value', name: t('default.value') },
				{ id: 'valueInMasterCurrency', name: t('default.valueInMasterCurrency') },
				{ id: 'contributionMargin', name: t('default.contributionMargin') }
			].sort(sortAlphabetically('name'));
		}
	},
	opportunity: {
		title: 'default.opportunities',
		dateFilter: {
			title: 'default.timePeriod',
			field: 'date',
			filterName: 'Date',
			data: () => dateFilterData
		},
		filter: {
			title: 'default.stages',
			field: 'stage.id',
			filterName: 'Stage',
			multiple: true,
			data: () => Tools.AppService.getStages('opportunity')
		},
		getSortable: () => {
			var t = Tools.$translate;

			return [
				{ id: 'date', name: t('default.closeDate2') },
				{ id: 'client.name', name: t('default.account') },
				{ id: 'value', name: t('default.value') },
				{ id: 'valueInMasterCurrency', name: t('default.valueInMasterCurrency') },
				{ id: 'contributionMargin', name: t('default.contributionMargin') }
			].sort(sortAlphabetically('name'));
		}
	},
	appointment: {
		title: 'default.appointments',
		dateFilter: {
			title: 'default.timePeriod',
			field: 'date',
			filterName: 'Date',
			data: () => dateFilterData
		},
		filter: {
			title: 'default.appointmentTypes',
			field: 'activityType.id',
			filterName: 'AppointmentType',
			multiple: true,
			data: () => Tools.AppService.getActivityTypes('appointment')
		},
		getSortable: () => {
			var t = Tools.$translate;

			return [
				{ id: 'date', name: t('default.date') },
				{ id: 'client.name', name: t('default.account') }
			].sort(sortAlphabetically('name'));
		}
	}
};

export default reducer;
