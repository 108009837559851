import T from 'Components/Helpers/translate';
import { getCFFieldIdFromFilterName } from 'App/helpers/filterHelper';
import CustomField, { EntityCustomField } from 'App/resources/Model/CustomField';
import React, { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { RootState } from 'Store/index';
import { Link, Text } from '@upsales/components';
import moment from 'moment';

type CustomFieldValueTextFieldsProps = {
	type?: keyof RootState['App']['customFields'];
	fields: CustomField[];
	column: string;
	values: ({ nameType?: string } & EntityCustomField)[];
	size?: React.ComponentProps<typeof Text>['size'];
};
type CustomFieldValueTextProps = {
	type: keyof RootState['App']['customFields'];
	fields?: CustomField[];
	column: string;
	values: ({ nameType?: string } & EntityCustomField)[];
	size?: React.ComponentProps<typeof Text>['size'];
};

export function getCorrectCustomFieldValue(value: string | null, field?: CustomField) {
	let content: string | JSX.Element | null = value;
	if (!field) return content;

	if (field.datatype === 'Boolean') {
		content = value ? T('default.yes') : T('default.no');
	} else if (field.datatype === 'Date' && value) {
		const m = moment(value);
		if (m.isValid()) {
			content = m.format('L');
		} else {
			content = null;
		}
	} else if (field.datatype === 'Link' && value) {
		content = (
			<Link target="_blank" href={value} onClick={e => e.stopPropagation()}>
				{value}
			</Link>
		);
	} else if (['User', 'Users'].includes(field.datatype) && value) {
		content = Tools.ActionProperties.parseCustomFieldUserValues(value);
	}
	return content;
}

const CustomFieldValueText: React.FC<CustomFieldValueTextFieldsProps | CustomFieldValueTextProps> = ({
	values,
	type,
	column,
	size,
	fields: providedFields
}) => {
	let fields: CustomField[];
	if (type) {
		fields = useSelector(({ App }: RootState) => App.customFields[type] || []);
	} else {
		fields = providedFields || [];
	}
	const field = useMemo(() => {
		const fieldId = getCFFieldIdFromFilterName(column);
		const cf = fields.find(cf => {
			if (column.indexOf('CustomAgreement_') === -1) {
				return cf.id === fieldId && cf.nameType !== 'Agreement';
			}
			return cf.id === fieldId && cf.nameType === 'Agreement';
		});

		return cf;
	}, []);
	const valueObj = useMemo(
		() =>
			values.find(v => {
				if (column.indexOf('CustomAgreement_') === -1) {
					return v.fieldId === field?.id && v.nameType !== 'Agreement';
				}
				return v.fieldId === field?.id && v.nameType === 'Agreement';
			}),
		[values]
	);

	if (!field) {
		return null;
	}

	const value = valueObj?.value ?? null;
	const content: JSX.Element | string | null = getCorrectCustomFieldValue(value, field);

	return content ? <Text size={size}>{content}</Text> : null;
};

export default CustomFieldValueText;
