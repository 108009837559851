import React from 'react';
import PropTypes from 'prop-types';

import { connect } from 'react-redux';
import { Table, TableRow, TableColumn, Icon, Input } from '@upsales/components';

import { showView, setSearchString } from 'Store/reducers/SharedViewsReducer';
import ListTitle from '../ListTitle';

const mapDispatchToProps = {
	showView,
	setSearchString
};

const mapStateToProps = state => ({
	hiddenViewSearchString: state.SharedViews.hiddenViewSearchString,
	isHiddenView: state.SharedViews.isHiddenView
});

function HiddenListView({ list, showView, hiddenViewSearchString, setSearchString, isHiddenView, changeView }) {
	if (hiddenViewSearchString.length) {
		list = list.filter(item => item.title.toLowerCase().indexOf(hiddenViewSearchString.toLowerCase()) > -1);
	}

	const mappedList = list.map(row => {
		return (
			<TableRow key={`${row.id}-hidden`} className={row.default ? 'RowView RowView--Active' : 'RowView'}>
				<ListTitle changeView={changeView} entireView={row} {...row} />
				<TableColumn align="right">
					<Icon name="eye" color="grey-10" onClick={() => showView(row)} />
				</TableColumn>
			</TableRow>
		);
	});

	return (
		<Table>
			<TableRow>
				<TableColumn colSpan={2} className="SearchBar">
					<Input
						noborder
						icon="search"
						color="grey-2"
						placeholder="Search view"
						tabIndex={isHiddenView ? 0 : -1}
						onChange={event => setSearchString(event.target.value)}
					/>
				</TableColumn>
			</TableRow>
			{mappedList}
		</Table>
	);
}

HiddenListView.propTypes = {
	list: PropTypes.array,
	showView: PropTypes.func,
	changeView: PropTypes.func,
	isHiddenView: PropTypes.bool,
	setSearchString: PropTypes.func,
	hiddenViewSearchString: PropTypes.string
};

export const detached = HiddenListView;
export default connect(mapStateToProps, mapDispatchToProps)(HiddenListView);
