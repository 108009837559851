import React, { useState } from 'react';
import { Text, Label, Textarea, Icon } from '@upsales/components';
import FakeTooltip from 'Components/Tooltip/FakeTooltip';
import T from 'Components/Helpers/translate';
import './CommentInput.scss';
import BemClass from '@upsales/components/Utils/bemClass';
import MentionsInput from 'Components/Mentions/MentionsInput';

interface Props {
	saveComment: (comment: string) => boolean;
	onChange?: (value: string) => void;
	value?: string;
	commentLabel?: string;
	placeholder?: string;
	tooltipText?: string;
	useTooltip?: boolean;
}

const CommentInput = ({
	commentLabel = T('comment.addAComment'),
	placeholder = T('comment.startTypingHere'),
	tooltipText = T('comment.tooltip'),
	useTooltip,
	value,
	saveComment,
	onChange
}: Props) => {
	const [localValue, setLocalValue] = useState(''); // This is needed for angular components to update the comment quick enough when typing
	const classes = new BemClass('CommentInput');
	return (
		<div className={classes.b()}>
			<Label>
				<Text bold>{commentLabel}</Text>
			</Label>
			<div className={classes.elem('commentField').b()}>
				<Icon name="comment" color="grey-10" />
				{Tools.FeatureHelper.hasSoftDeployAccess('MENTION') ? (
					<MentionsInput
						value={value ?? localValue}
						onChange={value => {
							onChange?.(value);
							setLocalValue(value);
						}}
						editorProps={{
							placeholder: placeholder
						}}
						onEnter={() => {
							saveComment(localValue);
							setLocalValue('');
						}}
					/>
				) : (
					<Textarea
						value={value ?? localValue}
						onKeyDown={e => {
							// Listen for enter, shift enter should be new line
							if (e.key === 'Enter' && !e.shiftKey) {
								e.preventDefault?.();
								saveComment(localValue);
								setLocalValue('');
							}
						}}
						onChange={e => {
							onChange?.(e.target.value);
							setLocalValue(e.target.value);
						}}
						placeholder={placeholder}
						autoHeight
						maxLength={65535}
					/>
				)}
			</div>
			{value ? (
				value.length > 0
			) : localValue.length > 0 && useTooltip ? (
				<FakeTooltip title={tooltipText} />
			) : null}
		</div>
	);
};

export default CommentInput;
