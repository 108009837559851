import React, { useState, useEffect, CSSProperties } from 'react';
import bemClass from '@upsales/components/Utils/bemClass';
import { Text, Icon, Button } from '@upsales/components';
import './BouncingTooltip.scss';

interface BouncingTooltipCSSProperties extends CSSProperties {
	'--delay': string;
	'--distance': string;
	'--iteration-count': string;
	'--iteration-duration': string;
	'--direction-multiplier': string;
}

type Props = {
	title: string;
	iterationDuration?: number;
	delay?: number;
	direction?: 'left' | 'right' | 'top';
	iterationCount?: number | 'infinite';
	className?: string;
	distance?: number;
	children: ((makeTooltipDisappear: () => void) => JSX.Element) | JSX.Element;
	dissmisable?: boolean;
	visible?: boolean;
	onHide?: () => void;
};

const BouncingTooltip = ({
	title,
	direction = 'right',
	delay = 500,
	iterationDuration = 2500,
	iterationCount = 3,
	distance = 10,
	className = '',
	dissmisable = false,
	visible = true,
	onHide,
	children
}: Props) => {
	const classes = new bemClass('BouncingTooltip', className);
	const [hidden, setHidden] = useState(!visible);

	const styles: BouncingTooltipCSSProperties = {
		'--delay': `${delay}ms`,
		'--distance': `${distance}px`,
		'--iteration-count': `${iterationCount}`,
		'--iteration-duration': `${iterationDuration}ms`,
		'--direction-multiplier': direction === 'right' ? '1' : '-1'
	};

	const makeTooltipDisappear = () => {
		setHidden(true);
		onHide?.();
	};

	useEffect(() => setHidden(!visible), [visible]);

	const timeout = React.useRef<NodeJS.Timeout | null>(null);

	useEffect(() => {
		if (iterationCount === 'infinite') return;
		if (hidden && timeout.current) clearTimeout(timeout.current);

		const time = iterationCount * iterationDuration + delay;
		timeout.current = setTimeout(() => {
			if (!hidden) {
				makeTooltipDisappear();
			}
		}, time);

		return () => {
			if (timeout.current) {
				clearTimeout(timeout.current);
			}
		};
	}, [hidden]);

	return (
		<div className={classes.b()} style={styles}>
			{visible ? (
				<div className={classes.elem('tooltip').mod({ hidden, dissmisable }).mod(direction).b()}>
					<Text size="sm" color="white">
						{title}
					</Text>
					{dissmisable ? (
						<Button
							className={classes.elem('dismiss-button').b()}
							size="xs"
							onClick={() => makeTooltipDisappear()}
						>
							<Icon name="times" />
						</Button>
					) : null}
					<div className={classes.elem('arrow').mod(direction).b()}></div>
				</div>
			) : null}
			{typeof children === 'function' ? children(makeTooltipDisappear) : children}
		</div>
	);
};

export default BouncingTooltip;
