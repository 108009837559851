import Resource from 'Resources/Resource';
import { EntityCustomField } from './Model/CustomField';

import type Order from './Model/Order';
import { parseCustom } from './parsers/helpers';
import { mapCustom } from './mappers/helpers';
// Add attributes when we start using the data in the new salesboard
// import Attributes from 'App/babel/attributes/OrderAttributes';

const isOpportunity = function (order: Order) {
	return order.probability !== 0 && order.probability !== 100;
};

type OrderRowSave = Partial<Omit<Order['orderRow'][0], 'custom'>> & { custom?: EntityCustomField[] };
type OrderSave = Partial<Omit<Order, 'custom' | 'orderRow'>> & { custom?: EntityCustomField[] } & {
	orderRow?: OrderRowSave[];
};

class OrderResource extends Resource {
	constructor() {
		super('orders');
		// super('orders', Attributes);

		this.notifications = {
			delete: () => ({
				title: 'default.deleted',
				body: 'deleted.order',
				style: 'success',
				icon: 'check',
				type: 'body'
			}),
			deleteError: () => ({
				title: 'default.error',
				body: 'deleteError.order',
				style: 'error',
				icon: 'times',
				type: 'body'
			}),
			save: (data: { data: Order }) => ({
				title: 'default.saved',
				body: isOpportunity(data.data) ? 'saved.opportunity' : 'saved.order',
				style: 'success',
				icon: 'check',
				type: 'body'
			}),
			saveError: () => ({
				title: 'default.error',
				body: 'saveError.order',
				style: 'error',
				icon: 'times',
				type: 'body'
			})
		};
	}

	eventName(
		opts: { [key: string]: any },
		type: 'updated' | 'deleted' | 'added',
		res: { error: unknown; data: Order }
	) {
		if (opts.eventName) {
			return opts.eventName;
		}
		return isOpportunity(res.data) ? 'opportunity' : 'order';
	}

	map(order: Partial<Order>) {
		const { custom, orderRow, ...restOfOrder } = order;
		const mappedOrder: OrderSave = restOfOrder;

		if (custom) {
			mappedOrder.custom = mapCustom(custom);
		}
		if (orderRow) {
			mappedOrder.orderRow = orderRow.map(orderRow => {
				const { custom, ...restofOrderRow } = orderRow;
				const mappedOrderRow: OrderRowSave = restofOrderRow;

				if (custom) {
					mappedOrderRow.custom = mapCustom(custom);
				}
				return mappedOrderRow;
			});
		}

		return mappedOrder;
	}

	parse(order: Order) {
		return {
			...order,
			custom: Object.values(parseCustom(order.custom as EntityCustomField[], 'order')),
			orderRow: order.orderRow.map(row => ({
				...row,
				custom: Object.values(parseCustom(row.custom, 'orderrow'))
			}))
		};
	}
}

const resource = new OrderResource();

export default resource;
