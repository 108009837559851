import React from 'react';
import { Text, Block, Tooltip } from '@upsales/components';
import Bem from '@upsales/components/Utils/bemClass';
import translate from 'Components/Helpers/translate';
import './ExistInUpsales.scss';

type Client = {
	id: number;
	active: boolean;
};

type Props = { client: Client } & React.ComponentProps<typeof Block>;

export default ({ client, ...blockProps }: Props) => {
	const classes = new Bem('ExistInUpsales');
	const onClick = () => Tools.$state.go('account.dashboard', { id: client.id });
	const hasInactiveAccounts = Tools.FeatureHelper.isAvailable(Tools.FeatureHelper.Feature.INACTIVE_COMPANIES);
	const inactive = hasInactiveAccounts && client.active === false;
	const tooltip = inactive ? 'account.alreadyExistingButInactive' : 'prospecting.allReadyInUpsales';

	return (
		<Tooltip title={translate(tooltip)} position="left" distance={20}>
			<Block
				className={classes.b()}
				backgroundColor={inactive ? 'grey-5' : 'super-light-green'}
				onClick={onClick}
				{...blockProps}
			>
				<Text color={inactive ? 'grey-10' : 'green'}>{translate('prospecting.inUpsales')}</Text>
			</Block>
		</Tooltip>
	);
};
