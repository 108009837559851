import { useState, useEffect } from 'react';
import RequestBuilder, { comparisonTypes } from 'App/babel/resources/RequestBuilder';
import OpportunityAIResource from 'App/babel/resources/OpportunityAI';
import { isEmpty } from 'lodash';
import logError from 'App/babel/helpers/logError';
import Order from 'App/resources/Model/Order';
import { RiskEvaluatedOpportunity } from 'App/resources/Model/Opportunity';
import FieldTranslationTag from 'App/resources/Model/FieldTranslationTag';
import { OpportunityAIResponseData } from 'App/resources/Model/OpportunityAI';

type RequestOptions = {
	getRiskiest?: boolean;
	baseOnLostReason?: boolean;
	limit?: number;
};

const useRiskyOpportunities = (order?: Order, lostReasonTag?: FieldTranslationTag) => {
	const [riskyOpportunities, setRiskyOpportunities] = useState<RiskEvaluatedOpportunity[]>([]);
	const [pendingFetch, setPendingFetch] = useState(true);
	const [isHighestRiskOpportunities, setIsHighestRiskOpportunities] = useState(false);
	const lostReasonTagType = lostReasonTag?.subType;

	const addUserIdFilter = (rb: RequestBuilder) => {
		rb.addFilter(Tools.Order.attr.user, comparisonTypes.Equals, [Tools.AppService.getSelf().id]);
		return rb;
	};

	const addProbabilityFilter = (rb: RequestBuilder) => {
		rb.addFilter(Tools.Order.attr.probability, comparisonTypes.GreaterThan, 0);
		rb.addFilter(Tools.Order.attr.probability, comparisonTypes.LessThan, 100);
		return rb;
	};

	const addConfirmedBudgetFilter = (rb: RequestBuilder) => {
		rb.addFilter(Tools.Order.attr.confirmedBudget, comparisonTypes.Equals, null);
		return rb;
	};

	const addConfirmedDateFilter = (rb: RequestBuilder) => {
		rb.addFilter(Tools.Order.attr.confirmedDate, comparisonTypes.Equals, null);
		return rb;
	};

	const addConfirmedSolution = (rb: RequestBuilder) => {
		rb.addFilter(Tools.Order.attr.confirmedSolution, comparisonTypes.Equals, 0);
		return rb;
	};

	const getOpportunities = async (opportunityIds: string[]) => {
		const rb = new RequestBuilder();
		rb.addFilter(Tools.Order.attr.id, comparisonTypes.Equals, opportunityIds);

		try {
			const opportunities = await Tools.Order.customer(Tools.AppService.getCustomerId()).find(rb.build());
			return opportunities?.data ?? [];
		} catch (e) {
			logError(e, 'Failed to fetch opportunities');
			return [];
		}
	};

	const getRiskyOpportunities = async (evaluatedRiskyOpportunities: OpportunityAIResponseData) => {
		const opportunityIds = Object.keys(evaluatedRiskyOpportunities);
		const opportunities = await getOpportunities(opportunityIds);
		let evaluatedOpportunities: RiskEvaluatedOpportunity[] = opportunities.map(opportunity => {
			return {
				...opportunity,
				evaluatedRisks: evaluatedRiskyOpportunities[opportunity.id]
			};
		});
		evaluatedOpportunities = evaluatedOpportunities.sort((a, b) => b.probability - a.probability);
		setRiskyOpportunities(evaluatedOpportunities);
	};

	const addRiskFilterBasedOnLostReason = (rb: RequestBuilder) => {
		switch (lostReasonTagType) {
			case 'pricing':
				return addConfirmedBudgetFilter(rb);
			case 'timeframe':
				return addConfirmedDateFilter(rb);
			case 'solution':
				return addConfirmedSolution(rb);
			default:
				return rb;
		}
	};

	const buildRequest = (requestOptions: RequestOptions) => {
		const { baseOnLostReason, getRiskiest, limit = 6 } = requestOptions;
		let rb = new RequestBuilder();
		rb = addUserIdFilter(rb);
		rb = addProbabilityFilter(rb);
		if (baseOnLostReason) {
			rb = addRiskFilterBasedOnLostReason(rb);
		}
		if (getRiskiest) {
			rb = rb.addExtraParam('getRiskiest', true);
		}
		rb.limit = limit;
		rb.addSort(Tools.Order.attr.probability, false);
		return rb.build();
	};

	const fetchRiskyOpportunities = async (requestOptions: RequestOptions): Promise<OpportunityAIResponseData> => {
		try {
			const res = await OpportunityAIResource.find(buildRequest(requestOptions));
			return res?.data?.[0] ?? [];
		} catch (e) {
			logError(e, 'Failed to fetch risky opportunities');
			return [];
		}
	};

	const findRiskyOpportunities = async () => {
		setPendingFetch(true);
		let evaluatedOpportunities = await fetchRiskyOpportunities({ baseOnLostReason: true });
		if (isEmpty(evaluatedOpportunities)) {
			evaluatedOpportunities = await fetchRiskyOpportunities({ getRiskiest: true });
			setIsHighestRiskOpportunities(true);
		} else {
			setIsHighestRiskOpportunities(false);
		}
		if (!isEmpty(evaluatedOpportunities)) {
			await getRiskyOpportunities(evaluatedOpportunities);
		}
		setPendingFetch(false);
	};

	useEffect(() => {
		if (order && lostReasonTag) {
			if (lostReasonTag.subType) {
				findRiskyOpportunities();
			} else {
				setPendingFetch(false);
			}
		}
	}, [order, lostReasonTag]);

	return { riskyOpportunities, pendingFetch, isHighestRiskOpportunities };
};

export default useRiskyOpportunities;
