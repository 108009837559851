import T from 'Components/Helpers/translate';
import CustomField from 'App/resources/Model/CustomField';
import _ from 'lodash';

export const getCalculatingFieldTooltip = (formula: string, fieldName: string) => {
	if (!formula) {
		return fieldName;
	}

	if (!Tools.FeatureHelper.isAvailable('CALCULATING_FIELDS')) {
		return T('admin.calculation.missingFeature');
	}

	const translate = {
		'OrderedProduct.totalGrossValue': T('admin.calculation.totalGrossValue'),
		'OrderedProduct.discount': T('admin.customfieldType.Discount'),
		'OrderedProduct.totalDiscount': T('tag.order.discount'),
		'OrderedProduct.totalValue': T('admin.calculation.totalValue'),
		'OrderedProduct.quantity': T('default.quantity'),
		'OrderedProduct.price': T('default.price'),
		'OrderedProduct.listPrice': T('default.listPrice'),
		'OrderedProduct.contributionMargin': T('admin.calculation.contributionMargin'),
		'OrderedProduct.totalContributionMargin': T('admin.calculation.totalContributionMargin'),
		'OrderedProduct.purchaseCost': T('default.purchasedAtCost'),
		'Order.id': T('tag.appointment.order'),
		'Order.probability': T('tag.order.probability'),
		'Order.discount': T('admin.calculation.discountValue'),
		'Order.value': T('field.order.value'),
		'Order.grossValue': T('admin.calculation.totalGrossOrderValue')
	} as { [key: string]: string };

	const orderFields = Tools.AppService.getReadOnlyCustomFields('order');
	const orderrowFields = Tools.AppService.getReadOnlyCustomFields('orderrow');
	const productFields = Tools.AppService.getReadOnlyCustomFields('product');

	const tags = formula.toString().match(/{(.*?)}/g);

	if (tags) {
		tags.forEach(tag => {
			const pureTag = tag.replace(/\{|\}/g, '');
			const tagSplit = pureTag.split('.');
			const [type, field] = tagSplit;
			if (!field) {
				return;
			}

			const getCustomName = (customFields: readonly CustomField[], fieldId: number) => {
				const custom = _.find(customFields, { id: fieldId });

				return custom ? custom.name : '';
			};

			const getOrderFieldName = (orderrowFields: any, field: string) => {
				if (field.match(/rowCustomTotal_\d{1,}/) !== null) {
					const fieldId = parseInt(field.replace('rowCustomTotal_', ''));

					return getCustomName(orderrowFields, fieldId);
				}

				return translate['Order.' + field];
			};

			const getOrderRowFieldName = (productFields: readonly CustomField[], field: string) => {
				if (field.match(/customProduct_\d{1,}/) !== null) {
					const fieldId = parseInt(field.replace('customProduct_', ''));

					return getCustomName(productFields, fieldId);
				}

				return translate['OrderedProduct.' + field];
			};

			const isCustomField = (field: string) => {
				if (!field || typeof field !== 'string') {
					return;
				}
				return field.match(/custom_\d{1,}/) !== null;
			};

			let name = '';
			const isCustom = isCustomField(field);
			const fieldId = isCustom && parseInt(field.replace('custom_', ''));

			if (fieldId) {
				const fields = type === 'Order' ? orderFields : orderrowFields;
				name = getCustomName(fields, fieldId);
			} else {
				if (type === 'Order') {
					name = getOrderFieldName(orderrowFields, field);
				} else {
					name = getOrderRowFieldName(productFields, field);
				}
			}

			formula = formula.replace(tag, name);
		});
	}

	return T('admin.calculation.howWeCalculate') + '\n' + fieldName + ': ' + formula;
};
