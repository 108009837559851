import T from 'Components/Helpers/translate';
import Appointment from 'App/resources/Model/Appointment';

export const getAttendingStr = (
	userId: number | null,
	users: Appointment['users'],
	contacts: Appointment['contacts']
): string => {
	let text = '';
	const mainUserIndex = users.findIndex(u => u.id === userId);
	const attendees = [...users, ...contacts];

	if (mainUserIndex !== -1) {
		attendees.splice(mainUserIndex, 1);
		const mainUser = users[mainUserIndex];
		const you = Tools.AppService.getSelf();
		text += mainUser.id === you.id ? T('default.you') : mainUser.name.split(' ')[0];
	}

	if (attendees.length > 1) {
		if (mainUserIndex !== -1) {
			text += ', ';
		}
		text += `${attendees[0].name.split(' ')[0]} ${T('default.and').toLowerCase()} ${attendees.length - 1} ${T(
			'default.more2'
		).toLowerCase()}`;
	} else if (attendees.length === 1) {
		if (mainUserIndex !== -1) {
			text += ` ${T('default.and').toLowerCase()} ${attendees[0].name.split(' ')[0]}`;
		} else {
			text += attendees[0].name;
		}
	}
	return text;
};
