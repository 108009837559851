import React from 'react';
import bemClass from '@upsales/components/Utils/bemClass';
import { Text, Icon, Row, Column, Link, Checkbox, Button, Tooltip, EllipsisTooltip } from '@upsales/components';
import Todo from 'App/resources/Model/Todo';
import { ActivityContact } from 'App/resources/Model/Activity';
import { openDrawer } from 'Services/Drawer';
import T from 'Components/Helpers/translate';
import TodoListTableRowActionButton from 'App/pages/TodoList/TodoListTableRow/TodoListTableRowActionButton';
import OpportunityTodoRowChild from './OpportunityTodoRowChild';
import moment from 'moment';
import { getDateString, getDateTimeString } from 'App/helpers/DateHelpers';
import Activity from 'App/resources/Model/Activity';

import './OpportunityTodoRow.scss';

export const iconMap = {
	appointment: 'calendar' as const,
	todo: 'activity' as const,
	phonecall: 'phone' as const,
	legacy: 'activity' as const
};

export const getDate = (date: Date | string, type: string, time: string | null) => {
	if (type === 'appointment' || time) {
		return getDateTimeString(date);
	}
	return getDateString(date);
};

export const getContactLink = (contacts: ActivityContact[]) => (
	<Link
		onClick={e => e.stopPropagation()}
		href={Tools.$state.href('contact.dashboard', {
			id: contacts[0].id,
			customerId: Tools.AppService.getCustomerId()
		})}
	>
		{contacts[0].name}
	</Link>
);

export const onRowClick = (todo: Todo) => {
	switch (todo.type) {
		case 'appointment':
			Tools.$upModal.open('editAppointment', { id: todo.id });
			return;
		case 'todo':
			openDrawer('EditTodo', { todo });
			return;
		case 'phonecall': {
			const { id, priority, description, date, time, client, contacts } = todo;
			const activity = { id, priority, description, date, time, client, contacts };
			openDrawer('EditPhonecall', { activity });
			return;
		}
	}
};

interface Props {
	todo: Todo;
	onEdit: () => void;
	onDelete: () => void;
	onCheckToggle: (id: number, done: boolean) => void;
	hideActionBtn?: boolean;
	hideRelation?: boolean;
	children?: Todo[];
	updateTodo?: (todo: Activity) => void;
}

const OpportunityTodoRow = ({
	todo,
	onEdit,
	onDelete,
	onCheckToggle,
	hideActionBtn = false,
	hideRelation = false,
	children = []
}: Props) => {
	const { type } = todo;
	const classes = new bemClass('OpportunityTodoRow');
	const checked = type === 'appointment' ? moment(todo.date).isBefore(moment()) : !!todo.closeDate;
	return (
		<React.Fragment>
			<Row key={todo.id} className={classes.b()} onClick={() => onRowClick(todo)}>
				<Column className={classes.elem('check-col').b()} fixedWidth={60} align="center">
					{type === 'todo' ? (
						<Checkbox
							size="sm"
							checked={checked}
							onClick={e => {
								e.stopPropagation();
								onCheckToggle(todo.id, !checked);
							}}
							disabled={!todo.userEditable}
						/>
					) : (
						<Icon name={iconMap[todo.type]} />
					)}
				</Column>
				<Column>
					<EllipsisTooltip title={todo.description}>
						<Text className={classes.elem('description').mod({ checked }).b()}>{todo.description}</Text>
					</EllipsisTooltip>
					{todo.date ? (
						<Text color="grey-10" size="sm">
							{getDate(todo.date, todo.type, todo.time)}
						</Text>
					) : null}
				</Column>
				{todo.contacts && todo.contacts.length && !hideRelation ? (
					<Column>
						<Text>{getContactLink(todo.contacts)}</Text>
					</Column>
				) : null}
				{!hideActionBtn ? (
					<Column fixedWidth={200}>
						<TodoListTableRowActionButton todo={todo} />
					</Column>
				) : null}
				<Column align="right" fixedWidth={82}>
					<div className={classes.elem('actions').b()}>
						{todo.userEditable ? (
							<Tooltip title={T('default.edit')} position="top" distance={24}>
								<Button
									size="sm"
									color="grey"
									type="link"
									onClick={e => {
										e.stopPropagation();
										onEdit();
									}}
								>
									<Icon name="edit" />
								</Button>
							</Tooltip>
						) : null}
						{todo.userRemovable ? (
							<Tooltip title={T('default.delete')} position="top" distance={24}>
								<Button
									size="sm"
									color="grey"
									type="link"
									onClick={e => {
										e.stopPropagation();
										onDelete();
									}}
								>
									<Icon name="trash" />
								</Button>
							</Tooltip>
						) : null}
					</div>
				</Column>
				{todo.priority === 3 ? (
					<Column align="center" fixedWidth={30}>
						<Tooltip title={T('todo.prioTodo')} position="top" distance={24}>
							<Icon name="flag" color="red" />
						</Tooltip>
					</Column>
				) : null}
			</Row>
			<Column className={classes.elem('children').toString()}>
				{(children || []).map(t => (
					<OpportunityTodoRowChild
						key={`child-${t.id}${t.closeDate ?? ''}`}
						todo={t}
						onCheckToggle={val => onCheckToggle(t.id, val)}
					/>
				))}
			</Column>
		</React.Fragment>
	);
};

export default OpportunityTodoRow;
