import React from 'react';
import { useSelector } from 'react-redux';
import { RootState } from 'Store/index';
import Client, { ClientIdName } from 'App/resources/Model/Client';
import ClientResource from 'App/resources/Client';
import RequestBuilder, { comparisonTypes } from 'Resources/RequestBuilder';
import T from 'Components/Helpers/translate';
import getAngularModule from 'App/babel/angularHelpers/getAngularModule';
import { AjaxSelect, AjaxSelectProps } from './../AjaxSelect';
import ClientAttributes from 'App/babel/attributes/Client';
import { useSoftDeployAccess } from '../hooks';
import { formatWithSubAccounts } from 'App/helpers/accountsHelper';

type ClientSelectProps = Pick<
	AjaxSelectProps<ClientIdName>,
	'value' | 'onChange' | 'multiple' | 'icon' | 'state' | 'name' | 'required' | 'disabled' | 'placeholder' | 'fields'
> & {
	onlyActive?: boolean;
	modifyRb?: (rb: RequestBuilder) => void;
	autoFetch?: boolean;
	doNotUseLatestAccounts?: boolean;
};

const modifyRbWrapper = (rb: RequestBuilder, onlyActive: boolean = false, modifyRb?: (rb: RequestBuilder) => void) => {
	if (onlyActive) {
		rb.addFilter({ field: 'active' }, comparisonTypes.Equals, 1);
	}
	rb.addFilter(ClientAttributes.isExternal, comparisonTypes.Equals, false);
	rb.fields = [...(rb.fields || []), 'operationalAccount'];

	modifyRb?.(rb);

	return false;
};

const ClientSelect = ({ onlyActive, modifyRb, autoFetch, doNotUseLatestAccounts, ...props }: ClientSelectProps) => {
	const { self, customerId } = useSelector(({ App }: RootState) => App);
	const canDeactivate = Tools.FeatureHelper.isAvailable(Tools.FeatureHelper.Feature.INACTIVE_COMPANIES);
	const hasSubAccounts = useSoftDeployAccess('SUB_ACCOUNTS');

	return (
		<AjaxSelect
			{...props}
			autoFetch={autoFetch}
			resource={ClientResource}
			modifyRb={rb => modifyRbWrapper(rb, onlyActive, modifyRb)}
			results={(data, term) => {
				if (term.length || data?.length || doNotUseLatestAccounts) {
					return hasSubAccounts ? (formatWithSubAccounts(data as Client[], T) as ClientIdName[]) : data;
				}

				const LatestAccountsService = getAngularModule('LatestAccountsService');
				const latestAccounts = LatestAccountsService.get(customerId, self?.id || 0, {
					onlyActive: canDeactivate
				});

				if (!hasSubAccounts) {
					return [
						{
							name: T('default.latestAccounts'),
							children: latestAccounts
						}
					];
				}

				return formatWithSubAccounts(latestAccounts, T, true) as ClientIdName[];
			}}
		/>
	);
};

export default ClientSelect;
