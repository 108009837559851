import React from 'react';
import { Block, Icon, Text, Row, Column, Card, Title, Button, DropDownMenu } from '@upsales/components';
import BemClass from '@upsales/components/Utils/bemClass';
import T from 'Components/Helpers/translate';
import HeaderUserSelect from 'Components/Inputs/HeaderUserSelect';
import './EditPhonecallHeader.scss';
import InlineConfirm from '../Dialogs/InlineConfirm';
import Client from 'App/resources/Model/Client';
import User from 'App/resources/Model/User';
import { saveAndCloseModals } from 'Store/actions/ModalActions';
import { getDateString, getDateTimeString } from 'App/helpers/DateHelpers';
import { useAppDispatch } from 'App/components/hooks';
import ActivityQuickLinksMenu from 'Components/Activity/ActivityQuickLinksMenu/ActivityQuickLinksMenu';

const getSubtitle = (date: Props['date'], time: Props['time'], client: Props['client']) => {
	let str = '';

	if (date) {
		if (time) {
			str += getDateTimeString(date);
		} else {
			str += getDateString(date, 'll');
		}
	}

	if (client) {
		str += ` ${T('default.with').toLowerCase()} ${client.name}`;
	}
	return str.trim();
};

interface Props {
	loading: boolean;
	closeDrawer: () => void;
	client: Partial<Client> & Pick<Client, 'id' | 'name'>;
	date: string | Date;
	time: string;
	user: User;
	users: User[];
	id: number;
	onUserChange: () => void;
	onDelete?: () => void;
	savedBannerVisible: boolean;
	closed: boolean;
	focus: boolean;
	disabled?: boolean;
}
const EditPhonecallHeader: React.FC<Props> = ({
	loading,
	closeDrawer,
	client,
	date,
	time,
	user,
	users,
	id,
	onUserChange,
	onDelete,
	savedBannerVisible,
	closed,
	focus,
	disabled = false
}) => {
	const classes = new BemClass('EditPhonecallHeader');
	const dispatch = useAppDispatch();
	const hasMinimize = Tools.FeatureHelper.hasSoftDeployAccess('MINIMIZE_TASK_DRAWERS');

	return (
		<Card className={classes.b()} borderColor="grey-6" border="bs">
			<Row>
				{!focus ? (
					<Column fixedWidth={50}>
						<Button
							shadow="none"
							color="light-grey"
							onClick={closeDrawer}
							className={classes.elem('close').b()}
						>
							<Icon name="chevron-right" />
						</Button>
					</Column>
				) : null}
				{hasMinimize ? (
					<Column fixedWidth={50}>
						<Button
							shadow="none"
							color="light-grey"
							onClick={() => {
								closeDrawer();
								dispatch(saveAndCloseModals());
							}}
							className={classes.elem('minimize').b()}
						>
							<Icon name="minimize-updated" />
						</Button>
					</Column>
				) : null}
				<Column fixedWidth={50}>
					<Icon className={classes.elem('icon').b()} name="phone" />
				</Column>
				<Column>
					<Block className={classes.elem('titles').b()} space="pts">
						<Title>{T('todoListTableRow.phonecall')}</Title>
						<Text size="sm" color="grey-10" loading={loading}>
							{getSubtitle(date, time, client)}
						</Text>
						{id ? (
							<Text className={classes.elem('id').b()} size="sm" color="grey-10" loading={loading}>
								{`${T('todo.drawerHeader.activityID')}: ${id}`}
							</Text>
						) : null}
					</Block>
					<Card
						color="green"
						className={classes.elem('saved-banner').mod({ visible: savedBannerVisible }).b()}
					>
						<Text color="super-light-green" size="sm">
							{T('todo.phonecallSaved')}
						</Text>
					</Card>
				</Column>
				<Column fixedWidth={150}>
					{loading ? null : (
						<HeaderUserSelect
							title={T('default.user')}
							user={user}
							users={users}
							border="ls rs"
							borderColor="grey-4"
							onChange={onUserChange}
							disabled={closed || disabled}
						/>
					)}
				</Column>
				<Column fixedWidth={50}>
					<DropDownMenu
						align="right"
						useAnimation={true}
						renderTrigger={(expanded, setExpanded) => (
							<Button
								onClick={setExpanded}
								shadow="none"
								color="white"
								className={classes.elem('quickLinks').b()}
							>
								<Icon color="grey-10" name="link" />
							</Button>
						)}
					>
						<ActivityQuickLinksMenu activityId={id}></ActivityQuickLinksMenu>
					</DropDownMenu>
				</Column>
				{!loading ? (
					<Column fixedWidth={50}>
						<InlineConfirm
							show
							tooltip={T(onDelete ? 'default.delete' : 'noDeleteRights.activity')}
							onConfirm={onDelete ? () => Promise.resolve(onDelete()) : null}
							entity="todo.phoneCall"
						>
							<Button
								disabled={onDelete === null}
								shadow="none"
								color="white"
								className={classes.elem('delete').b()}
							>
								<Icon color="grey-10" name="trash" />
							</Button>
						</InlineConfirm>
					</Column>
				) : null}
			</Row>
		</Card>
	);
};

export default EditPhonecallHeader;
