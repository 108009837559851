import { useSubscriptionGroupContext } from 'App/components/EditSubscription/Context/SubscriptionGroupContext';
import BemClass from '@upsales/components/Utils/bemClass';
import { Child, Tree } from '../SubscriptionPeriodsTree';
import { Icon, Text, Tooltip } from '@upsales/components';
import T from 'Components/Helpers/translate';
import React, { CSSProperties } from 'react';
import moment from 'moment';

import './PeriodTree.scss';

interface TreeLineCSSProperties extends CSSProperties {
	'--solid-line-height': string;
}

const PERIOD_ROW_HEIGHT = 49;
const PERIOD_ROW_HEIGHT_WITH_CHILD = 47;

export const getLengthString = (endDate: Date | string, startDate: Date | string) => {
	const months = moment(endDate).diff(startDate, 'months');
	const days = moment(endDate).diff(moment(startDate).add(months, 'months'), 'days');

	const monthsString = months ? `${months} ${T('subscription.modal.summary.splitSidebar.tree.months')}` : null;
	const daysString = days || days === 0 ? `${days} ${T('default.day' + (days === 1 ? '' : 's'))}` : null;

	if (months && days) {
		return `${monthsString} & ${daysString}`;
	}
	return monthsString ?? daysString;
};

type Props = {
	tree: Tree;
	isActive?: boolean;
	onClick?: (resetChildIndex?: boolean) => void;
	hideLine?: boolean;
};

const PeriodTree = ({ tree, isActive, hideLine, onClick }: Props) => {
	const classes = new BemClass('PeriodTree');

	const {
		setCurrentChild,
		deleteChildPeriod,
		deleteSplitPeriod,
		lastPeriod,
		firstPeriod,
		currentPeriod,
		state: { childIndexNr, locked }
	} = useSubscriptionGroupContext();

	const periodText = T(`subscription.modal.summary.splitSidebar.tree.${tree.state}Period`);
	const canDeletePeriod =
		!locked &&
		!hideLine &&
		lastPeriod.uuid === tree.uuid &&
		firstPeriod.uuid !== lastPeriod.uuid &&
		moment(lastPeriod.startDate).isAfter(moment(), 'day');

	const renderChildPeriod = (child: Child, index: number) => {
		const isPicked = childIndexNr === index && isActive && !hideLine;
		const activeColorClassName = classes.elem('child-period').elem('focused-item').mod({ activeColor: isPicked });

		const textClassName = activeColorClassName.b();

		activeColorClassName.add('PeriodTree__circle-icon');
		const iconClassName = activeColorClassName.b();

		const canDeleteChildPeriod =
			!locked &&
			!hideLine &&
			index > 0 &&
			(!currentPeriod.nextOrderDate ||
				moment(currentPeriod.nextOrderDate).isBefore(child.startDate, 'day') ||
				moment().isBefore(child.startDate, 'day'));

		return (
			<div
				key={index}
				onClick={() => {
					onClick?.();
					setCurrentChild(index);
				}}
				className={classes
					.elem('child-period')
					.mod({ solidBorder: isPicked && !hideLine, isDisabled: hideLine })
					.b()}
			>
				<div className={classes.elem('child-period').elem('dates').b()}>
					<div>
						<div className={classes.elem('child-period').elem('line').b()} />
						<Icon className={iconClassName} color="grey-6" name="circle" />
					</div>
					<div>
						<Text className={textClassName} size="sm" bold color="grey-11">{`${moment(
							child.startDate
						).format('L')} - ${moment(child.endDate).format('L')}`}</Text>
						<Text color="grey-11" size="sm">
							{getLengthString(child.endDate, child.startDate)}
						</Text>
					</div>
				</div>
				{canDeleteChildPeriod ? (
					<Tooltip className={classes.elem('child-period').elem('trash').b()} title={T('default.remove')}>
						<Icon
							onClick={e => {
								e.stopPropagation();
								deleteChildPeriod(tree.uuid, index);
							}}
							name="trash"
						/>
					</Tooltip>
				) : null}
			</div>
		);
	};

	const currentMultiplier = currentPeriod.uuid === tree.uuid && tree.children?.length ? childIndexNr + 1 : 0;
	const styles: TreeLineCSSProperties = {
		'--solid-line-height': `${
			PERIOD_ROW_HEIGHT_WITH_CHILD * (currentMultiplier - 1) + PERIOD_ROW_HEIGHT - currentMultiplier + 1
		}px`
	};

	const hasMoreThanOneChild = (tree.children?.length ?? 0) > 1;

	const headlinePeriodLength = `${periodText} • ${
		tree.periodLength
			? `${tree.periodLength} ${T('subscription.modal.summary.splitSidebar.tree.months')}`
			: tree.endDate
			? getLengthString(tree.endDate as string, tree.startDate)
			: T('default.continually')
	}`;
	return (
		<div style={styles} className={classes.b()}>
			<div
				className={classes
					.elem('solid-line')
					.mod({ isActive: isActive && !hideLine && hasMoreThanOneChild })
					.b()}
			/>
			<div
				className={classes
					.elem('headline')
					.mod({ isActive, isActiveNoChildren: isActive && !hasMoreThanOneChild, isDisabled: hideLine })
					.b()}
				onClick={() => onClick?.(true)}
			>
				<div>
					<Icon className={classes.elem('circle-icon').b()} color="grey-6" name="circle" />
					<div>
						<Text color="grey-10" bold size="sm">
							{headlinePeriodLength}
						</Text>
						{!hasMoreThanOneChild ? (
							<Text size="sm" color="grey-11">{`${moment(tree.startDate).format('L')} - ${
								tree.endDate ? moment(tree.endDate).format('L') : ''
							}`}</Text>
						) : null}
					</div>
				</div>
				{canDeletePeriod ? (
					<Tooltip className={classes.elem('headline').elem('trash').b()} title={T('default.remove')}>
						<Icon
							onClick={e => {
								e.stopPropagation();
								deleteSplitPeriod(tree.uuid);
							}}
							name="trash"
						/>
					</Tooltip>
				) : null}
			</div>
			{hasMoreThanOneChild ? tree.children?.map(renderChildPeriod) : null}
		</div>
	);
};

export default PeriodTree;
