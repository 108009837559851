import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { DropDownMenu, Icon, Text, Row, Column, IconName } from '@upsales/components';
import BemClass from '@upsales/components/Utils/bemClass';

import './TableHeaderDropdown.scss';

type Props = {
	items: { onClick: () => void; text: string }[];
	selected: boolean | { [id: string]: boolean };
	className?: string;
};

const getSettings = (selected: Props['selected']) => {
	const out: { triggerMod: { [k: string]: boolean }; icon: IconName } = { triggerMod: {}, icon: '' as IconName };
	if (selected === true) {
		out.triggerMod['all-selected'] = true;
		out.icon = 'check-square';
	} else if (typeof selected === 'object' && Object.keys(selected).some(key => selected[key])) {
		out.triggerMod['some-selected'] = true;
		out.icon = 'minus-square-o';
	} else {
		out.triggerMod['none-selected'] = true;
		out.icon = 'square-o';
	}
	return out;
};

const runFunctions = (a: () => void, b: () => void) => {
	a();
	b();
};

const TableHeaderDropdown = ({ items, selected, className, ...props }: Props) => {
	const classes = new BemClass('TableHeaderDropdown', className);
	const { icon, triggerMod } = getSettings(selected);
	return (
		<DropDownMenu
			{...props}
			className={classes.b()}
			renderTrigger={(isOpen, setOpen) => (
				<button
					className={classes.elem('trigger-button').mod(triggerMod).mod({ open: isOpen }).b()}
					onClick={e => items.length > 0 && setOpen(e)}
				>
					<Icon name={icon} />
					<Icon name="angle-down" />
				</button>
			)}
		>
			{close => (
				<Fragment>
					{items.map((it, i) => (
						<Row
							key={'multiselect-' + i}
							className={classes.elem('item').b()}
							onClick={() => runFunctions(it.onClick, close)}
						>
							<Column align="left">
								<Text>{it.text}</Text>
							</Column>
						</Row>
					))}
				</Fragment>
			)}
		</DropDownMenu>
	);
};

TableHeaderDropdown.propTypes = {
	selected: PropTypes.oneOfType([PropTypes.bool, PropTypes.object]),
	items: PropTypes.array.isRequired
};
TableHeaderDropdown.defaultProps = {
	selected: {},
	items: []
};

export default TableHeaderDropdown;
