import React from 'react';
import PropTypes from 'prop-types';
import './columns.scss';
import { EllipsisTooltip, Text as UpText } from '@upsales/components';

const formatValue = (type, value) => {
	switch (type) {
		case 'flag':
			return <i className={'flag-icon flag-icon-' + value.toLowerCase()} />;
		case 'currency':
			return Tools.$filter('numberFormat')(value);
		case 'number':
		case 'string':
		default:
			return value;
	}
};

class Content extends React.Component {
	render() {
		const props = this.props;
		const type = props.type || 'string';
		const style = props.style || {};
		let value = props.value;

		if (props.staticValue === 'sniCode' && value === '9') {
			value = Tools.$translate('default.sniCode.00009');
		} else if (props.staticValue) {
			const staticValue = window.Tools.AppService.getStaticValue(props.staticValue, value + '');
			value = staticValue ? staticValue.name : '';
		}

		value = formatValue(type, value);

		let content = <span>{value}</span>;

		const tooltip = props.tooltip;
		const ellipsisTooltip = props.ellipsisTooltip;
		if (tooltip) {
			content = ReactTemplates.TOOLS.withTooltip(content, Tools.$translate(tooltip), { placement: 'top' });
		} else if (ellipsisTooltip) {
			content = (
				<EllipsisTooltip title={value}>
					<UpText>{content}</UpText>
				</EllipsisTooltip>
			);
		}

		const icon = props.icon;
		const iconElement = icon ? <i className={'create-account-content__icon ' + icon} /> : null;

		return (
			<div style={style} className={props.className}>
				{iconElement}
				{content}
			</div>
		);
	}
}

class Text extends React.Component {
	render() {
		const props = this.props;
		const config = props.config;
		const value = _.get(props.account, config.field) || '';

		return (
			<Content
				type={config.type}
				style={config.style}
				icon={config.icon}
				value={value}
				className={'create-account-content__text'}
				ellipsisTooltip
				staticValue={config.staticValue}
			/>
		);
	}
}

class SubText extends React.Component {
	render() {
		const props = this.props;
		const config = props.config;
		const value = _.get(props.account, config.field);

		if (value === undefined || value === null || value === '') {
			return null;
		}

		return (
			<Content
				type={config.type}
				style={config.style}
				icon={config.icon}
				value={value}
				className={'create-account-content__subtext'}
				tooltip={config.tooltip}
				staticValue={config.staticValue}
			/>
		);
	}
}

export default class Generic extends React.Component {
	render() {
		const { account, field: column } = this.props;

		const text = <Text account={account} config={column} />;

		const subTextItems = _.map(column.subText, (subText, index) => {
			return <SubText key={index} inline={true} account={account} config={subText} />;
		}).filter(subText => subText !== null);

		let subText = null;
		if (subTextItems.length) {
			subText = <div className="create-account-content__subtext-wrap">{subTextItems}</div>;
		}

		return (
			<td className="DataSourceColumn create-account-td">
				<div>
					{text}
					{subText}
				</div>
			</td>
		);
	}
}

Generic.propTypes = {
	account: PropTypes.object,
	field: PropTypes.object
};
