import Resource from './Resource';

class TodoView extends Resource {
	constructor() {
		super('todoViews');
	}

	async getStats(filterParams) {
		if (filterParams) {
			return super._getRequest('stats', { params: filterParams }).then(d => d.data);
		} else {
			return super._getRequest('stats').then(d => d.data);
		}
	}
}

const resource = new TodoView();

export default resource;
