import React from 'react';
import { Icon, Text, Tooltip } from '@upsales/components';
import { renderToString } from 'react-dom/server';
import T from 'Components/Helpers/translate';

export function ProductDescriptionTooltip({ className, description }: { className?: string; description: string }) {
	const prefixTooltipContent = (content: string) => {
		const TooltipContent = () => (
			<Text color="white">
				<b>{T('default.productDescription')}: </b>
				{content}
			</Text>
		);

		return renderToString(<TooltipContent />) // look at dis
			.replace(' data-reactroot=""', '')
			.replace(/<!-- -->/g, '');
	};

	if (!description) return null;

	return (
		<Tooltip className={className} title={prefixTooltipContent(description)} position="top">
			<Icon name="info-circle" />
		</Tooltip>
	);
}
