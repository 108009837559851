import { CancelablePromise, makeCancelable } from '@upsales/components/Utils/CancelablePromise';
import { useTranslation } from 'Components/Helpers/translate';
import React, { useState, useRef, useCallback, useMemo } from 'react';
import {
	Block,
	Button,
	DropDownMenu,
	EllipsisTooltip,
	Flex,
	Icon,
	Input,
	SelectAsync,
	Text,
	Toggle,
	Tooltip
} from '@upsales/components';
import { getSelf } from 'Store/selectors/AppSelectors';
import TicketResponseTemplate from 'App/resources/Model/TicketResponseTemplate';
import TicketResponseTemplateResource from 'App/resources/TicketResponseTemplate';
import '../MailEditor.scss';
import BemClass from '@upsales/components/Utils/bemClass';
import { capitalize } from 'lodash';
import RequestBuilder, { comparisonTypes } from 'Resources/RequestBuilder';
import { SelectItem } from '@upsales/components/Utils/selectHelpers';
import T from 'Components/Helpers/translate';
import logError from 'Helpers/logError';
import { MAX_SIZE } from 'Store/actions/helpers/sharedMailActions';
import { addNotification, STYLE as notificationStyle } from 'Store/reducers/SystemNotificationReducer';
import { useAppDispatch } from 'App/components/hooks';
import File from 'App/babel/resources/File';
import { replaceItem } from 'Store/helpers/array';

type TemplateDropdownProps = {
	brandId: number;
	editorHeaderHeight: number;
	files: any[];
	newTicket: boolean;
	onChange: (id: number | string | null, templates?: TicketResponseTemplate[]) => void;
	publicMessage: string;
	setTicketResponseTemplate: ((template: TicketResponseTemplate | undefined) => void) | undefined;
	ticketResponseTemplate: TicketResponseTemplate | undefined;
};

const getTemplateOptions = (templates: TicketResponseTemplate[], searchString?: string) => {
	if (templates === null || templates.length === 0) {
		return [];
	}

	const privateTicketResponseTemplates =
		templates?.filter(t => t.private)?.map(t => ({ id: t.id, title: t.name })) ?? [];
	const publicTicketResponseTemplates =
		templates?.filter(t => t.private === false)?.map(t => ({ id: t.id, title: t.name })) ?? [];
	const recentlyUsedTemplates = templates
		? templates
				.filter(t => t.lastUsedDate !== null)
				.sort((a, b) => {
					const aDate = new Date(a.lastUsedDate ?? '');
					const bDate = new Date(b.lastUsedDate ?? '');

					return bDate.getTime() - aDate.getTime();
				})
				.slice(0, 5)
				.map(t => ({ id: t.id, title: t.name }))
		: [];

	const arrayOfTemplates = [];

	if (!searchString && recentlyUsedTemplates && recentlyUsedTemplates.length > 0) {
		arrayOfTemplates.push({
			id: 1,
			title: T('ticket.template.recentlyUsed'),
			children: recentlyUsedTemplates
		});
	}

	if (privateTicketResponseTemplates.length > 0) {
		arrayOfTemplates.push({
			id: 2,
			title: T('ticket.template.allPrivate'),
			children: privateTicketResponseTemplates
		});
	} else {
		arrayOfTemplates.push({
			id: 2,
			title: T('ticket.template.allPrivate'),
			disabled: true
		});
	}

	if (publicTicketResponseTemplates.length > 0) {
		arrayOfTemplates.push({
			id: 3,
			title: T('ticket.template.allPublic'),
			children: publicTicketResponseTemplates
		});
	} else {
		arrayOfTemplates.push({
			id: 3,
			title: T('ticket.template.allPublic'),
			disabled: true
		});
	}

	return arrayOfTemplates;
};

const updateFileProgress = (attachments: any[], filename: string, progress: number, data?: any, error = false) => {
	const i = attachments.findIndex(f => filename === f.file.filename && f.pending === true);

	let newFile;
	if (progress === 100 && data) {
		newFile = { filename, size: data.size, value: data.id, type: 'InternalFile', pending: false };
	} else {
		newFile = { ...attachments[i], progress, error };
	}

	return replaceItem(attachments, i, newFile);
};

const TemplateDropdown = ({
	brandId,
	editorHeaderHeight,
	files,
	newTicket,
	onChange,
	publicMessage,
	setTicketResponseTemplate,
	ticketResponseTemplate
}: TemplateDropdownProps) => {
	const [ticketResponseTemplates, setTicketResponseTemplates] = useState<TicketResponseTemplate[] | null>(null);
	const [newTemplateName, setNewTemplateName] = useState('');
	const [newTemplateIsPrivate, setNewTemplateIsPrivate] = useState(false);
	const ticketResponseTemplatesPromise = useRef<CancelablePromise<{
		data: TicketResponseTemplate[];
	}> | null>(null);
	const updateResponseTemplatePromise = useRef<null | CancelablePromise<
		Awaited<ReturnType<typeof TicketResponseTemplateResource.save>>
	>>(null);
	const sizeOfAllFiles = useMemo(() => {
		ticketResponseTemplate?.attachments.reduce((total, file) => total + file.size, 0);
	}, [ticketResponseTemplate]);

	const { t } = useTranslation();
	const self = getSelf();
	const dispatch = useAppDispatch();

	const fetchResponseTemplates = useCallback(async (searchString?: string): Promise<SelectItem<any>[]> => {
		const rb = new RequestBuilder();

		if (searchString) {
			rb.addFilter({ field: 'name' }, comparisonTypes.Wildcard, searchString);
		}
		const filter = rb.orBuilder();
		filter.next();
		filter.addFilter({ field: 'regBy.id' }, comparisonTypes.Equals, self?.id);
		filter.next();
		filter.addFilter({ field: 'private' }, comparisonTypes.Equals, false);
		filter.done();
		rb.addSort('name', true);
		ticketResponseTemplatesPromise.current = makeCancelable(TicketResponseTemplateResource.find(rb.build()));
		return ticketResponseTemplatesPromise.current?.promise
			.then(({ data }) => {
				setTicketResponseTemplates(data);
				return getTemplateOptions(data, searchString);
			})
			.catch(err => {
				setTicketResponseTemplates([]);
				logError(err, 'Failed to fetch ticket response templates');
				return [];
			});
	}, []);

	const uploadFiles = async (files: any[]) => {
		const totalSize = files.reduce((total, file) => total + file.size, 0);
		if (totalSize + sizeOfAllFiles > MAX_SIZE) {
			dispatch(
				addNotification({
					style: notificationStyle.WARN,
					icon: 'warning',
					title: t('default.fileSizeTooLarge'),
					body: t('groupMail.fileLimit')
				})
			);
			return;
		}
		let pendingFiles = files
			.filter(f => !ticketResponseTemplate?.attachments.some(attachment => attachment.id === f.id))
			.map(file => {
				return { file, filename: file.name, pending: true, error: false, progress: 0, size: file.size };
			});

		for (let i = pendingFiles.length - 1; i >= 0; i--) {
			try {
				const data = await File.upload(pendingFiles[i].file.blob, {
					onUploadProgress: (progress: number) => {
						pendingFiles = updateFileProgress(pendingFiles, pendingFiles[i].file.filename, progress);
					},
					fileEntity: 'mail'
				});

				pendingFiles = updateFileProgress(pendingFiles, pendingFiles[i].file.filename, 100, data);
			} catch (e) {
				dispatch(
					addNotification({
						style: notificationStyle.ERROR,
						icon: 'times',
						title: t('mail.uploadFail'),
						body: t('file.uploadFailed')
					})
				);
				pendingFiles = pendingFiles.filter(
					att => !(att.filename === pendingFiles[i].filename && att.file.id === pendingFiles[i].file.id)
				);
			}
		}

		return [...(ticketResponseTemplate?.attachments || []), ...pendingFiles];
	};

	const createNewTemplate = async (name: string, isPrivate: boolean) => {
		const newTemplate: Partial<TicketResponseTemplate> = {
			name,
			body: publicMessage,
			private: isPrivate,
			brandId: brandId,
			attachments: await uploadFiles(files)
		};

		updateResponseTemplatePromise.current = makeCancelable(TicketResponseTemplateResource.save(newTemplate));

		updateResponseTemplatePromise.current.promise
			.then(res => {
				fetchResponseTemplates();
				if (setTicketResponseTemplate) {
					setTicketResponseTemplate(res.data ?? undefined);
				}
			})
			.catch(e => logError(e, 'Failed to save ticket response template'));

		setNewTemplateName('');
		setNewTemplateIsPrivate(false);

		return updateResponseTemplatePromise.current?.promise;
	};

	const updateTemplate = async () => {
		const updatedTemplate: Partial<TicketResponseTemplate> = {
			...ticketResponseTemplate,
			body: publicMessage,
			attachments: await uploadFiles(files)
		};

		updateResponseTemplatePromise.current = makeCancelable(TicketResponseTemplateResource.save(updatedTemplate));

		updateResponseTemplatePromise.current.promise
			.then(res => {
				fetchResponseTemplates();
				if (setTicketResponseTemplate) {
					setTicketResponseTemplate(res.data ?? undefined);
				}
			})
			.catch(e => logError(e, 'Failed to save ticket response template'));

		return updateResponseTemplatePromise.current?.promise;
	};

	const templateDropdownClass = new BemClass('TemplateDropdown');

	templateDropdownClass.mod({ newTicket });

	const renderDropDownContent = (close: () => void) => {
		const dropdownClass = new BemClass('TemplateSaveDropDown');
		return (
			<Flex
				space="ptm prm pbm plm"
				direction="column"
				justifyContent="space-between"
				className={dropdownClass.b()}
			>
				<Block>
					<Text bold>{t('ticket.template.name')}</Text>
					<Input
						value={newTemplateName}
						onChange={e => {
							e.preventDefault();
							e.stopPropagation();
							setNewTemplateName(e.target.value);
						}}
						placeholder={t('ticket.template.name.placeholder')}
						className={dropdownClass.elem('input').b()}
					/>
				</Block>
				<Flex alignItems="center" className={dropdownClass.elem('toggle').b()}>
					<Toggle
						size="sm"
						checked={newTemplateIsPrivate}
						onChange={() => setNewTemplateIsPrivate(!newTemplateIsPrivate)}
					/>
					<Block space="mls" onClick={() => setNewTemplateIsPrivate(!newTemplateIsPrivate)}>
						<Text>{t('ticket.template.private')}</Text>
						<Text color="grey-10" size="sm">
							{t('ticket.template.private.description')}
						</Text>
					</Block>
				</Flex>
				<Block className={dropdownClass.elem('button').b()}>
					<Button
						disabled={!newTemplateName}
						onClick={() => {
							createNewTemplate(newTemplateName, newTemplateIsPrivate);
							close();
						}}
					>
						{t('ticket.template.saveTemplate')}
					</Button>
				</Block>
			</Flex>
		);
	};

	return ticketResponseTemplate && setTicketResponseTemplate !== null ? (
		<Flex
			className={templateDropdownClass.mod('isDisplayingTemplateTrue').b()}
			style={{ top: `${editorHeaderHeight}px` }}
			alignItems="center"
			justifyContent="space-between"
		>
			<div className={templateDropdownClass.elem('title').b()}>
				<EllipsisTooltip title={ticketResponseTemplate.name}>
					<Text>
						{capitalize(t('default.template'))}: {ticketResponseTemplate.name}
					</Text>
				</EllipsisTooltip>
			</div>
			<Flex alignItems="center">
				<DropDownMenu
					renderTrigger={(expanded, setExpanded) => {
						return (
							<Button
								type="link"
								onClick={setExpanded}
								className={templateDropdownClass.elem('menuButton').b()}
								color="grey-10"
								hoverColor="grey-13"
							>
								{t('ticket.template.saveAsNew')}
							</Button>
						);
					}}
					align="right"
					onClose={() => {
						setNewTemplateName('');
						setNewTemplateIsPrivate(false);
					}}
				>
					{close => renderDropDownContent(close)}
				</DropDownMenu>
				<Tooltip
					title={t('ticket.template.noUpdateRights')}
					disabled={ticketResponseTemplate.regBy.id === self?.id || self?.administrator === true}
				>
					<Button
						type="link"
						onClick={updateTemplate}
						className={templateDropdownClass.elem('menuButton').b()}
						color="grey-10"
						hoverColor="grey-13"
						disabled={
							publicMessage.trimEnd() === ticketResponseTemplate.body.trimEnd() ||
							(ticketResponseTemplate.regBy.id !== self?.id && self?.administrator === false)
						}
					>
						{t('default.update')}
					</Button>
				</Tooltip>
				<Tooltip title={t('ticket.template.clear')} className={templateDropdownClass.elem('close').b()}>
					<Icon name="times" onClick={() => onChange(null)} />
				</Tooltip>
			</Flex>
		</Flex>
	) : (
		<Flex
			className={templateDropdownClass.mod('isDisplayingTemplateFalse').b()}
			style={{ top: `${editorHeaderHeight}px` }}
		>
			<SelectAsync
				value={null}
				onChange={v => onChange(v.id, ticketResponseTemplates || [])}
				onClear={() => onChange(null)}
				fetcher={fetchResponseTemplates}
				fetchOnOpen
				anchor={'.Modals__modal'}
				placeholder={t('ticket.template.useATemplate')}
				optionHeaderType={'disabled'}
				extraScrollOffset={45}
				renderItem={item => (
					<Block className={templateDropdownClass.elem('selectItem').b()}>
						<EllipsisTooltip title={item.title}>
							<Text>{item.title}</Text>
						</EllipsisTooltip>
					</Block>
				)}
				renderCustomNoResults={() => (
					<Flex
						className={templateDropdownClass.elem('noResults').b()}
						justifyContent="center"
						alignItems="center"
					>
						<Text color="grey-10" italic>
							{t('ticket.template.noTemplates')}
						</Text>
					</Flex>
				)}
			/>
			{publicMessage &&
			publicMessage
				.replace(/<br\s*\/?>/g, '')
				.replace(/&nbsp;/g, '')
				.trim().length !== 0 ? (
				<DropDownMenu
					renderTrigger={(expanded, setExpanded) => {
						return (
							<Button
								type="link"
								onClick={setExpanded}
								className={templateDropdownClass.elem('menuButton').b()}
								color="grey-10"
								hoverColor="grey-13"
							>
								{t('ticket.template.saveDraftAsTemplate')}
							</Button>
						);
					}}
					align="right"
					onClose={() => {
						setNewTemplateName('');
						setNewTemplateIsPrivate(false);
					}}
				>
					{renderDropDownContent}
				</DropDownMenu>
			) : null}
		</Flex>
	);
};

export default TemplateDropdown;
