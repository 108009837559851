import LZString from 'lz-string';

export const SET_EDITOR_BODY = '[pdfTemplates] SET_EDITOR_BODY';
export const SET_PREVIEW = '[pdfTemplates] SET_PREVIEW';
export const RESET_EDITOR = '[pdfTemplates] RESET_EDITOR';
export const SET_INITIAL_HASH = '[pdfTemplates] SET_INITIAL_HASH';

export const initialState = {
	editor: {
		templateId: 0,
		templateName: '',
		userRoles: [],
		html: '',
		css: '',
		json: '',
		language: '',
		uuid: 0,
		active: false,
		isSkeleton: false,
		isCustom: false
	},
	preview: {
		url: '',
		loading: false,
		error: null
	},
	initialHash: '',
	isDirty: false
};

export default (state = initialState, action) => {
	switch (action.type) {
		case SET_EDITOR_BODY:
			return {
				...state,
				editor: action.payload,
				isDirty: LZString.compressToBase64(JSON.stringify(action.payload)) !== state.initialHash
			};
		case SET_PREVIEW:
			return { ...state, preview: action.payload };
		case SET_INITIAL_HASH:
			return { ...state, initialHash: LZString.compressToBase64(JSON.stringify(action.payload)), isDirty: false };
		case RESET_EDITOR:
			return { ...state, preview: action.payload.preview, editor: action.payload.editor, isDirty: false };
		default:
			return state;
	}
};
