import SubscriptionIndexingState, { State } from '../SubscriptionIndexingState/SubscriptionIndexingState';
import AgreementResource from 'Resources/Agreement';
import SubscriptionList from './SubscriptionList';
import React, { useCallback } from 'react';
import RequestBuilder from 'Resources/RequestBuilder';
import { useSoftDeployAccess } from 'App/components/hooks';
import { Action } from '../SubscriptionIndexingState/SubscriptionIndexingActions';
import { Block, Title } from '@upsales/components';
import { useTranslation } from 'Components/Helpers/translate';

type Props = {
	state: State;
	dispatch: React.Dispatch<Action>;
};

const SubscriptionListPreview = ({ state, dispatch }: Props) => {
	const { id, filter, indexConflictDecisionsMap } = state;

	const { t } = useTranslation();
	const hasConflictHandling = useSoftDeployAccess('INDEXING_CONFLICT_HANDLING');
	const { excludeSubscriptionId, includeSubscriptionId } = SubscriptionIndexingState(dispatch);
	const [count, setCount] = React.useState(0);
	const conflictCount = Object.keys(indexConflictDecisionsMap).length;

	const getData = useCallback(
		async (rb: RequestBuilder) => {
			const { sort } = rb.build();
			const subFilter = { ...filter, sort };
			subFilter.limit = rb?.limit ?? 100;
			subFilter.offset = rb?.offset ?? 0;

			const promise = hasConflictHandling
				? AgreementResource.getAgreementsWithtoutConflicts(subFilter, id)
				: AgreementResource.find(subFilter);

			const { data, metadata } = await promise;
			setCount(metadata.total);

			return { data, metadata };
		},
		[filter]
	);

	const columns = ['info', 'lastIndexIncreaseDate', 'increasedPeriodValue', 'exclude'];

	return (
		<>
			{conflictCount > 0 ? (
				<Block space="ptxl pbm">
					<Title>
						{t(`subscription.indexing.summary.preview.description`, {
							count: count
						})}
					</Title>
				</Block>
			) : null}
			<SubscriptionList
				state={state}
				getData={getData}
				columns={columns}
				includeOrExcludeSub={(type, id) =>
					type === 'exclude' ? excludeSubscriptionId(id) : includeSubscriptionId(id)
				}
			/>
		</>
	);
};

export default SubscriptionListPreview;
