import Resource from 'Resources/Resource';
import Event from './Model/Event';
import Attributes from 'App/babel/attributes/EventAttributes';

class EventResource extends Resource {
	constructor() {
		super('events', Attributes);
	}

	getPriorEvents(id: number, entityType: string): Promise<Event[]> {
		return this._getRequest(`prior/${entityType}/${id}`, { methodName: 'getPriorEvents' }).then(res => res.data);
	}
}

const resource = new EventResource();

export default resource;
