import { globalTracker } from 'Helpers/Tracker';
import { SignalsListViewFilters } from 'Store/reducers/ProspectingSignalsReducer';
import logError from '../helpers/logError';
import Resource from './Resource';

interface Filter {
	id?: number;
	clientId?: number;
	filterBody: any;
	filterConfig: any;
	date?: Date;
	clients?: number;
}

class SignalsFilter extends Resource {
	constructor() {
		super('master/signals/filter');
	}

	async getFilter(): Promise<Filter> {
		const { data, metadata } = await this._getRequest('', { methodName: 'getFilter' }).then(
			response => response.data
		);
		return { ...data, ...metadata };
	}

	async saveFilter(filter: Filter): Promise<Filter> {
		const clientId = Tools.AppService.getCustomerId();
		const { data } = await this.save({ ...filter, clientId });
		return data;
	}

	async excludeClient(clientId: number): Promise<void> {
		try {
			const current = await this.getFilter();
			const include: number[] = current.filterConfig?.SignalsFilter?.value?.include || [];
			const exclude: number[] = current.filterConfig?.SignalsFilter?.value?.exclude || [];
			if (exclude.find(e => e === clientId)) return;
			const index = include.findIndex(e => e === clientId);
			if (index > -1) {
				include.splice(index, 1);
			}
			exclude.push(clientId);
			const filters = {
				SignalsFilter: {
					value: {
						...current.filterConfig?.SignalsFilter?.value,
						include,
						exclude
					}
				}
			} as SignalsListViewFilters;

			await this.createAndSaveFilterConfig(current, filters);
			return;
		} catch (err) {
			logError(err, 'could not save filter (exclude)');
			return;
		}
	}

	private async createAndSaveFilterConfig(current: Filter, updated: SignalsListViewFilters): Promise<any> {
		const build = Tools.FilterHelper.parseFilters(updated, 'account').build();
		const newFilter = {
			...current,
			filterConfig: updated,
			filterBody: build
		};
		return this.saveFilter(newFilter);
	}

	async includeClient(clientId: number): Promise<void> {
		try {
			const current = await this.getFilter();
			const include: number[] = current.filterConfig?.SignalsFilter?.value?.include || [];
			const exclude: number[] = current.filterConfig?.SignalsFilter?.value?.exclude || [];
			if (include.find(e => e === clientId)) return;
			const index = exclude.findIndex(e => e === clientId);
			if (index > -1) {
				exclude.splice(index, 1);
			}
			include.push(clientId);
			const filters = {
				SignalsFilter: {
					value: {
						...current.filterConfig?.SignalsFilter?.value,
						include,
						exclude
					}
				}
			} as SignalsListViewFilters;
			await this.createAndSaveFilterConfig(current, filters);
			globalTracker.track('Company added to signals');
			return;
		} catch (err) {
			logError(err, 'could not save filter (include)');
			return;
		}
	}

	async isEnabled(clientId: number): Promise<boolean> {
		return this._getRequest(`${clientId}`, {
			methodName: 'isEnabled'
		}).then(response => response.data);
	}
}

export default new SignalsFilter();
