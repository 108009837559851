import React, { useCallback, useEffect, useMemo } from 'react';
import {
	Block,
	Button,
	FullscreenModal,
	Icon,
	Headline,
	ModalSidebar,
	ModalHeader,
	ModalContent,
	Row,
	Table,
	TableHeader,
	TableHeaderColumn,
	Text
} from '@upsales/components';
import BemClass from '@upsales/components/Utils/bemClass';
import T from 'Components/Helpers/translate';
import _Order from 'App/resources/Model/Order';
import {
	OrderRowsProvider,
	OrderRow as _OrderRow,
	BundleRow,
	useOrderRowsSelector
} from 'App/components/OrderRows/Context/OrderContext';
import BundleRows from './BundleRows';
import { getDisplaySettings, DisplaySettings, mapBundleRowsAsOrderRows } from './helpers';
import { OrderSummary, BundleSummary } from './SummaryComponents';
import { getMetadata } from 'App/babel/store/selectors/AppSelectors';
import { BundleTracker } from 'App/babel/helpers/Tracker';
import { ClientIdName } from 'App/resources/Model/Client';
import { ModalProps } from 'App/components/Modals/Modals';
import { hasRRWithCM } from 'App/helpers/salesModelHelpers';
import { useMetadata } from '../hooks/appHooks';
import { useSoftDeployAccess } from '../hooks';

import './EditProductBundleOrder.scss';
import Product from 'App/resources/Model/Product';

type OrderRow = Required<_OrderRow>;
type Order = Omit<PartialPick<_Order, 'currency' | 'recurringInterval'>, 'client' | 'orderRow'> & {
	client: ClientIdName;
	orderRow: OrderRow[];
};

type EditProductBundleOrderProps = ModalProps<void> & {
	uuid: number;
	isAgreement: boolean;
	client: ClientIdName;
	currency: string;
	displaySettings: DisplaySettings;
	bundleOrderRow: OrderRow;
	save: (bundleOrderRow: Product) => void;
};
const EditProductBundleOrder = (props: EditProductBundleOrderProps) => {
	const classes = new BemClass('EditProductBundleOrder', props.className);
	const { isAgreement, currency, client, displaySettings, save, close, bundleOrderRow } = props;

	const { orderRows } = useOrderRowsSelector(s => ({
		orderRows: s.orderRows
	}));

	const metadata = useMetadata();
	const { SalesModel } = metadata?.params || {};
	const usingRRWithCM = hasRRWithCM(metadata!);
	const usingCM = SalesModel === 'cm';
	const showCost = usingCM || usingRRWithCM;

	const hasBundleAdjustable = useSoftDeployAccess('BUNDLE_ADJUSTABLE');

	const lang = useMemo(
		() => ({
			title: T(isAgreement ? 'agreement.withCompany' : 'order.opportunityWithCompany', {
				companyName: client.name
			}),
			save: T('default.save'),
			close: T('cancel'),
			quantity: T('default.quantity'),
			product: T('default.product'),
			sum: T('default.sum'),
			bundleDiscount: T('bundle.discount'),
			bundleCost: T('bundle.cost'),
			netPrice: `${T('default.price')} (${T('default.netAmount')})`,
			nameAndCount: T('bundle.nameAndCount', {
				name: bundleOrderRow.product.name,
				count: bundleOrderRow.product.bundle.length
			}),
			productCost: T('bundle.productCost')
		}),
		[]
	);

	/*
		We must take it from here and not look at product.bundlePriceAdjustment as
		that one may change, but the price adjustment on the orders should not.
	*/
	const priceAdjustment = useMemo(() => {
		if (!bundleOrderRow.bundleRows.length) {
			return 1;
		}
		const bundleRow = bundleOrderRow.bundleRows.find(br => br.listPrice !== 0) ?? bundleOrderRow.bundleRows[0];
		const rate = bundleRow.listPrice !== 0 ? bundleRow.price / bundleRow.listPrice : 1;
		return parseFloat(rate.toFixed(2));
	}, []);

	const productHasPriceAdjustment = bundleOrderRow.product.bundlePriceAdjustment !== 1;

	const onSave = useCallback(() => {
		// Convert type from OrderRow
		const typedBundleRows = orderRows as BundleRow[];

		const newProduct = {
			...bundleOrderRow.product,
			bundle: typedBundleRows.map(
				({
					quantity,
					tierQuantity,
					product,
					productId,
					adjustable,
					bundleRowPrice,
					bundleRowCost,
					resetOrderDiscount
				}) => ({
					quantity,
					tierQuantity,
					product: product!,
					productId,
					adjustable,
					bundleRowPrice,
					bundleRowCost,
					resetOrderDiscount
				})
			)
		};

		BundleTracker.track(BundleTracker.events.ORDER_ROW_CHANGE_SAVED);
		save(newProduct);
		close();
	}, [orderRows]);

	// This just makes the animation smoother
	useEffect(() => {
		BundleTracker.track(BundleTracker.events.ORDER_ROW_CHIP_CLICKED);
	}, []);

	return (
		<FullscreenModal className={classes.b()} withSidebar headerAtTop>
			<ModalHeader
				title={lang.title}
				icon={isAgreement ? 'recurring-order' : 'opportunity'}
				onClose={close}
				alignContent="right"
			>
				<Button className={classes.elem('actionButtons').b()} onClick={() => close()} type="link">
					<Text color="grey-11">{lang.close}</Text>
				</Button>
				<Button className={classes.elem('actionButtons').b()} onClick={onSave} color="green">
					<Text space="mrl" bold color="white">
						{lang.save}
					</Text>
					<Icon name="check" color="white" />
				</Button>
			</ModalHeader>
			<ModalSidebar placement="top" align="left">
				<Block space="mbxl">
					<BundleSummary displaySettings={displaySettings} currency={currency} />
				</Block>
				<Block>
					<OrderSummary displaySettings={displaySettings} currency={currency} />
				</Block>
			</ModalSidebar>
			<ModalContent>
				<Block className={classes.elem('Content').b()}>
					<Row className={classes.elem('ContentHeadline').b()} noWrap>
						<Icon name="bundle" />
						<Headline size="sm">{lang.nameAndCount}</Headline>
					</Row>
					<Table>
						<TableHeader>
							<TableHeaderColumn column={{ title: lang.product }} />
							<TableHeaderColumn column={{ title: lang.quantity }} />
							{productHasPriceAdjustment ? (
								<TableHeaderColumn
									column={{
										title: priceAdjustment > 1 ? lang.bundleCost : lang.bundleDiscount
									}}
								/>
							) : null}
							<TableHeaderColumn column={{ title: lang.netPrice }} />
							{hasBundleAdjustable && showCost ? (
								<TableHeaderColumn column={{ title: lang.productCost }} />
							) : null}
							<TableHeaderColumn column={{ title: lang.sum }} />
						</TableHeader>
						<BundleRows
							productHasPriceAdjustment={productHasPriceAdjustment}
							priceAdjustment={priceAdjustment}
							displaySettings={displaySettings}
							currency={currency}
							showCost={showCost}
						/>
					</Table>
				</Block>
			</ModalContent>
		</FullscreenModal>
	);
};

type Props = ModalProps<void> & {
	uuid: number;
	order: Order;
	isAgreement: boolean;
	save: (product: Product) => void;
};
export default (props: Props) => {
	const {
		order: { recurringInterval, orderRow, client, currency },
		uuid,
		...passOnProps
	} = props;

	const priceListId = orderRow[0]?.priceListId;
	const orderInterval = recurringInterval ?? 0;
	const metadata = getMetadata()!;
	const displaySettings = getDisplaySettings(metadata);

	const bundleOrderRow = orderRow.find(orderRow => orderRow.uuid === uuid) as OrderRow;
	const hasBundleAdjustable = useSoftDeployAccess('BUNDLE_ADJUSTABLE');

	const bundleRows = useMemo(
		() => mapBundleRowsAsOrderRows(bundleOrderRow, currency, orderInterval, hasBundleAdjustable),
		[]
	);

	return (
		<OrderRowsProvider orderInterval={orderInterval} initialRows={bundleRows} priceListId={priceListId}>
			<EditProductBundleOrder
				{...passOnProps}
				client={client}
				uuid={uuid}
				currency={currency}
				displaySettings={displaySettings}
				bundleOrderRow={bundleOrderRow}
			/>
		</OrderRowsProvider>
	);
};
