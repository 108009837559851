import React from 'react';
import MultiColumnChartWidget from '../MultiColumnChartWidget';
import { GenericWidgetProps } from '../ReportcenterWidget';

enum MarketingContributionTypes {
	TotalSales = 'totalSales',
	MarketingSales = 'marketingSales',
	NoneMarketingSales = 'noneMarketingSales'
}

const columns = Object.values(MarketingContributionTypes);
export default (MultiColumnChartWidget<`${MarketingContributionTypes}`>(
	columns
) as unknown) as React.ComponentType<GenericWidgetProps>;
