import { Block, Icon, Text, Tooltip } from '@upsales/components';
import BemClass from '@upsales/components/Utils/bemClass';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import copyToClipboard from 'Services/copyToClipboard';
import T from 'Components/Helpers/translate';
import './CopyableId.scss';

enum Types {
	order = 'order'
}

type CopyableIdProps = React.ComponentProps<typeof Block> & {
	objectId: number;
	type: keyof typeof Types;
	loading?: boolean;
	size?: React.ComponentProps<typeof Text>['size'];
	color?: React.ComponentProps<typeof Text>['color'];
};

const getLinkByType = (type: Types, objectId: number) => {
	switch (type) {
		case Types.order:
			return `${location.host}/${Tools.$state.href('open', { type, id: objectId })}`;
		default:
			return '';
	}
};

const CopyableId = ({
	type,
	objectId,
	loading,
	size = 'sm',
	color = 'grey-10',
	className,
	...blockProps
}: CopyableIdProps) => {
	const classes = useMemo(() => new BemClass('CopyableId', className), []);
	const [copied, setCopied] = useState(false);

	// Queue copied=false on copy
	useEffect(() => {
		let timeout: NodeJS.Timeout;
		if (copied) {
			timeout = setTimeout(() => setCopied(false), 1000);
		}
		return () => clearTimeout(timeout);
	}, [copied]);

	// Copy link to clipboard and set copied=true
	const copyLink = useCallback(() => {
		copyToClipboard(getLinkByType(type as Types, objectId));
		setCopied(true);
	}, [type, objectId]);

	return (
		<Block {...blockProps} className={classes.b()}>
			<Text size={size} color={color} loading={loading}>
				{'ID: ' + objectId}
			</Text>
			{/* Our Tooltip implementation isn't that good so i had to use two tooltips here to be able to change the text on copy */}
			<Tooltip disabled={copied} title={T('default.copyLink')} position="right">
				<Tooltip disabled={!copied} title={T('default.copiedToClipboard')} position="right">
					<Icon name="link" color="grey-10" space="mlm mrm" onClick={copyLink} />
				</Tooltip>
			</Tooltip>
		</Block>
	);
};

export default CopyableId;
