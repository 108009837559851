import Attribute, { DisplayType, Type } from './Attribute';

const FormAttributes = {
	id: Attribute({
		title: 'default.id',
		field: 'id',
		type: Type.Number,
		displayType: DisplayType.Number,
		selectableColumn: true,
		sortable: 'id'
	}),
	title: Attribute({
		title: 'default.title',
		field: 'title',
		type: Type.String,
		displayType: DisplayType.Text,
		selectableColumn: false,
		sortable: 'title'
	}),
	description: Attribute({
		title: 'default.description',
		field: 'description',
		type: Type.String,
		displayType: DisplayType.Text,
		selectableColumn: false,
		sortable: 'description'
	}),
	name: Attribute({
		title: 'default.name',
		field: 'name',
		type: Type.String,
		displayType: DisplayType.Text,
		sortable: 'name',
		selectableColumn: true
	}),
	internalName: Attribute({
		title: 'default.name',
		field: 'internalName',
		type: Type.String,
		displayType: DisplayType.Text,
		sortable: 'internalName',
		selectableColumn: false
	}),
	isArchived: Attribute({
		field: 'isArchived',
		type: Type.Boolean,
		sortable: 'isArchived',
		selectableColumn: false
	}),
	userRemovable: Attribute({
		field: 'userRemovable',
		type: Type.Boolean,
		selectableColumn: false
	}),
	userEditable: Attribute({
		field: 'userEditable',
		type: Type.Boolean
	}),
	landingPageBody: Attribute({
		title: 'default.type',
		field: 'landingPageBody',
		type: Type.String,
		displayType: DisplayType.Text,
		selectableColumn: true,
		sortable: 'landingPageBody'
	}),
	regDate: Attribute({
		title: 'default.regDate',
		field: 'regDate',
		type: Type.Date,
		displayType: DisplayType.Date,
		selectableColumn: true,
		sortable: 'regDate'
	}),
	modDate: Attribute({
		title: 'listColumnTitle.lastModified',
		field: 'modDate',
		type: Type.Date,
		displayType: DisplayType.Date,
		selectableColumn: true,
		sortable: 'modDate'
	}),
	lastSubmitDate: Attribute({
		title: 'form.lastSubmitDate',
		field: 'lastSubmitDate',
		type: Type.Date,
		displayType: DisplayType.Date,
		selectableColumn: true,
		sortable: 'lastSubmitDate',
		placeholder: 'default.never'
	}),
	user: Attribute({
		type: Type.Object,
		displayType: DisplayType.User,
		field: 'user',
		title: 'default.user',
		sortable: 'user.name',
		placeholder: 'default.noUser',
		selectableColumn: true,
		attr: {
			id: Attribute({
				field: 'user.id',
				type: Type.Number
			}),
			name: Attribute({
				field: 'user.name',
				type: Type.String
			})
		}
	}),
	actions: Attribute({
		type: Type.Array,
		field: 'actions',
		selectableColumn: false,
		attr: {
			id: Attribute({
				field: 'action.id',
				type: Type.Number
			}),
			action: Attribute({
				field: 'action.action',
				type: Type.String
			})
		}
	}),
	submits: Attribute({
		title: 'defaultView.formSubmit',
		field: 'submits',
		type: Type.Number,
		displayType: DisplayType.Number,
		selectableColumn: true,
		sortable: 'submits',
		placeholder: '0'
	}),
	views: Attribute({
		title: 'form.views',
		field: 'views',
		type: Type.Number,
		displayType: DisplayType.Number,
		selectableColumn: true,
		sortable: 'views',
		placeholder: '0'
	}),
	score: Attribute({
		title: 'default.score',
		field: 'score',
		type: Type.Number,
		displayType: DisplayType.Number,
		selectableColumn: false,
		sortable: 'score',
		placeholder: '0'
	}),
	projects: Attribute({
		type: Type.Array,
		displayType: DisplayType.Array,
		field: 'projects',
		title: 'default.campaigns',
		displayKey: ['projects', 'name'],
		selectableColumn: true,
		attr: {
			id: Attribute({
				field: 'project.id',
				type: Type.Number
			}),
			name: Attribute({
				field: 'project.name',
				type: Type.String
			})
		}
	}),
	landingPage: Attribute({
		field: 'landingPage',
		type: Type.String
	}),
	buttonText: Attribute({
		field: 'buttonText',
		type: Type.String
	}),
	thankYouTitle: Attribute({
		field: 'thankYouTitle',
		type: Type.String
	}),
	thankYouTitleOnDemand: Attribute({
		field: 'thankYouTitleOnDemand',
		type: Type.String
	}),
	thankYouText: Attribute({
		field: 'thankYouText',
		type: Type.String
	}),
	thankYouTextOnDemand: Attribute({
		field: 'thankYouTextOnDemand',
		type: Type.String
	}),
	buttonBgColor: Attribute({
		field: 'buttonBgColor',
		type: Type.String
	}),
	buttonTextColor: Attribute({
		field: 'buttonTextColor',
		type: Type.String
	}),
	backgroundColor: Attribute({
		field: 'backgroundColor',
		type: Type.String
	}),
	backgroundColorOnDemand: Attribute({
		field: 'backgroundColorOnDemand',
		type: Type.String
	}),
	textColor: Attribute({
		field: 'textColor',
		type: Type.String
	}),
	linkColor: Attribute({
		field: 'linkColor',
		type: Type.String
	}),
	redirect: Attribute({
		field: 'redirect',
		type: Type.Boolean
	}),
	showTitle: Attribute({
		field: 'showTitle',
		type: Type.Boolean
	}),
	urlName: Attribute({
		field: 'urlName',
		type: Type.String
	}),
	domain: Attribute({
		field: 'domain',
		type: Type.String
	}),
	uuid: Attribute({
		field: 'uuid',
		type: Type.String
	}),
	html: Attribute({
		field: 'html',
		type: Type.String
	}),
	socialEventId: Attribute({
		field: 'socialEventId',
		type: Type.Number
	}),
	thankYouElement: Attribute({
		field: 'thankYouElement',
		type: Type.Object
	}),
	thankYouElementOnDemans: Attribute({
		field: 'thankYouElementOnDemand',
		type: Type.Object
	}),
	socialMediaTags: Attribute({
		field: 'socialMediaTags',
		type: Type.StringifiedObject
	}),
	labels: Attribute({
		title: 'file.label',
		type: Attribute.types.Array,
		field: 'labels',
		selectableColumn: false,
		attr: {
			id: Attribute({
				field: 'labels.id',
				type: Attribute.types.Number
			}),
			name: Attribute({
				field: 'labels.name',
				type: Attribute.types.String
			})
		}
	}),
	fields: Attribute({
		field: 'fields',
		type: Type.Array,
		attr: {
			name: Attribute({
				field: 'name',
				type: Type.String
			}),
			title: Attribute({
				field: 'title',
				type: Type.String
			}),
			datatype: Attribute({
				field: 'datatype',
				type: Type.String
			}),
			options: Attribute({
				field: 'options',
				type: Type.String
			}),
			required: Attribute({
				field: 'required',
				type: Type.Boolean
			}),
			sort: Attribute({
				field: 'sort',
				type: Type.Number
			}),
			placeholder: Attribute({
				field: 'placeholder',
				type: Type.String
			}),
			info: Attribute({
				field: 'info',
				type: Type.String
			})
		}
	})
};

export default FormAttributes;
