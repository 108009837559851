import Resource from './Resource';
import Attributes from 'Attributes/ContactAttributes';

/** @deprecated use ui/app/resources/Contact.ts */
class Contact extends Resource {
	constructor() {
		super('contacts', Attributes);
	}
}

const resource = new Contact();

export default resource;
