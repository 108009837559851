import React, { ReactNode } from 'react';
import BemClass from '@upsales/components/Utils/bemClass';
import { Title, Loader, Text } from '@upsales/components';
import T from 'Components/Helpers/translate';
import './HistoryLog.scss';

type Props = {
	title: ReactNode;
	children?: ReactNode;
	selectButtons?: ReactNode;
	hasPriorHistory?: boolean;
	showTopLine?: boolean;
	loading?: boolean;
};

function HistoryLog({
	title,
	children,
	selectButtons,
	hasPriorHistory = false,
	showTopLine = false,
	loading = false
}: Props) {
	const classes = new BemClass('HistoryLog');
	return (
		<div className={classes.mod({ hasPriorHistory }).b()}>
			<div className={classes.elem('row').b()}>
				<div className={classes.elem('icon-wrapper').b()}>
					{showTopLine ? <div className={classes.elem('line').mod({ top: true }).b()} /> : null}
					<div className={classes.elem('icon').b()} />
					<div className={classes.elem('line').b()} />
				</div>
				<Title size="md">{title}</Title>
				<div className={classes.elem('filler').b()} />
				{selectButtons}
			</div>

			{loading ? (
				<div className={classes.elem('loader').b()}>
					<Loader size="sm" />
					<Text italic color="grey-11">
						{T('opportunity.fetchingHistory')}
					</Text>
				</div>
			) : null}
			{children}
		</div>
	);
}

export default HistoryLog;
