import SocialEventFilter from 'App/babel/filters/SocialEvent';
import UserRoleFilter from 'App/upsales/common/attributes/filter/userRoleFilter';

const getCriteriaFilterConfigs = segmentId => {
	const hasNewFields = Tools.FeatureHelper.hasSoftDeployAccess(Tools.FeatureHelper.Feature.NEW_FIELDS);
	/* We will do some modifications to the filters, hence the cloning */
	let filterConfigs = _.cloneDeep(Tools.ListViewService.getStandardFilters('contact'));

	filterConfigs.ClientOrderFilters.displayType = 'order';
	filterConfigs.OpportunityFilters.displayType = 'opportunity';
	filterConfigs.ClientActivityFilters.displayType = 'activity';

	filterConfigs.SocialEvent = new SocialEventFilter({
		parent: 'default.market',
		showOnSegment: Tools.FeatureHelper.isAvailable(Tools.FeatureHelper.Feature.SOCIAL_EVENTS)
	});

	delete filterConfigs.Active.parent;

	filterConfigs.CategoryClient.parent = 'default.categories';
	filterConfigs.StandardCategory.parent = 'default.categories';

	filterConfigs.AccountManager = filterConfigs.AccountManagerSegment;
	delete filterConfigs.AccountManagerSegment;
	const roleTreeOptions = {
		accessType: Tools.AppService.AccessType.CONTACT,
		selectableRoles: true,
		noParentRef: true,
		includeInactive: false
	};

	const userRoleTree = window.UserRoleTree(roleTreeOptions);
	filterConfigs.AccountManager.build = UserRoleFilter.build({
		fieldPrefix: 'client',
		userRoleTree: userRoleTree,
		sendRoleValues: true
	});

	filterConfigs = _.omit(filterConfigs, function (filter) {
		return !filter.showOnSegment || (filter.unreleasedFeature === 'NEW_FIELDS' && !hasNewFields);
	});

	/* We have to override it so we dont get segment-ception */
	filterConfigs.Segment.searchFn = function (Segment, RequestBuilder) {
		return function (term) {
			const filter = new RequestBuilder();
			filter.addFilter(Segment.attr.name, filter.comparisonTypes.Search, term);
			filter.addFilter(Segment.attr.active, filter.comparisonTypes.Equals, true);
			if (segmentId) {
				filter.addFilter(Segment.attr.id, filter.comparisonTypes.NotEquals, segmentId);
			}
			return Segment.find(filter.build());
		};
	};
	filterConfigs.Segment.displayType = 'segment';

	const contactCustomFields = Tools.AppService.getCustomFields('contact');
	const clientCustomFields = Tools.AppService.getCustomFields('account');
	const orderCustomFields = Tools.AppService.getCustomFields('order');

	filterConfigs = Object.assign(
		filterConfigs,
		Tools.FilterHelper.getConfigsFromCustomFields('contact', contactCustomFields)
	);
	filterConfigs = Object.assign(
		filterConfigs,
		Tools.FilterHelper.getConfigsFromCustomFields('account', clientCustomFields)
	);
	filterConfigs = Object.assign(
		filterConfigs,
		Tools.FilterHelper.getConfigsFromCustomFields('order', orderCustomFields)
	);

	const allowAllComperator = [
		'Campaign',
		'StandardCategory',
		'AccountManager',
		'CategoryClient',
		'CampaignClient',
		'SocialEvent',
		'OptIn',
		'UnsubscribedList'
	];

	const teamAccountManager = Tools.AppService.getMetadata().params.teamAccountManager;

	if (teamAccountManager) {
		allowAllComperator.push('AccountManager');
	}

	Object.entries(filterConfigs).forEach(([filterName, config]) => {
		config.showOnSegment = true;
		config.filterName = filterName;
		/* Contact filters does not have any entity so i add "contact" here */
		config.entity = config.entity || 'contact';

		/* We want to treat text-filters as lists */
		if (filterName.indexOf('Custom') > -1 && config.displayType === 'text') {
			config.$field.datatype = 'Select';
		}
		if (filterName.indexOf('Custom') > -1 && config.displayType === 'radio') {
			const yesOption = config.options?.find?.(({ value }) => value === true);
			const noOption = config.options?.find?.(({ value }) => value === false);

			if (yesOption) {
				yesOption.text = 'default.yes';
			}

			if (noOption) {
				noOption.text = 'default.no';
			}
		}
		if (
			config.field === 'category.id' ||
			config.field === 'client.category.id' ||
			config.field === 'project.id' ||
			config.field === 'client.project.id'
		) {
			Object.assign(config, { allowMultipleSelections: true });
		}

		if (
			allowAllComperator.includes(filterName) ||
			config.field === 'category.id' ||
			config.field === 'client.category.id'
		) {
			config.allowAllComperator = true;
		}
	});

	return filterConfigs;
};

window.Tools.getCriteriaFilterConfigs = getCriteriaFilterConfigs;
export default getCriteriaFilterConfigs;
