import React, { useEffect, useState } from 'react';
import VirtualList from '../VirtualList';
import { InfiniteLoader } from 'react-virtualized';
import { Error, Metadata } from 'App/babel/resources/ResourceTyped';
import { VirtualWidthMeasurer } from 'App/components/VirtualList';

import './AsyncVirtualList.scss';

type Response = {
	data: unknown[];
	metadata: Metadata;
	error: Error;
};

type Props = {
	/** Callback function (eg. Redux action-creator) responsible for loading the next page of items */
	loadMoreResults: (offset: number) => Response;
	onLoadMoreResults: () => void;
	virtualWidthMeasurer: VirtualWidthMeasurer;
};

const AsyncVirtualList = ({
	loadMoreResults,
	onLoadMoreResults,
	...virtualListProps
}: Props & React.ComponentProps<typeof VirtualList>) => {
	const [hasNextPage, setHasNextPage] = useState(true);
	const [isNextPageLoading, setIsNextPageLoading] = useState(false);
	const [allFetchedItems, setAllFetchedItems] = useState<unknown[]>(virtualListProps.items ?? []);

	const resetAsyncList = () => {
		setAllFetchedItems(virtualListProps.items ?? []);
		setHasNextPage(true);
		setIsNextPageLoading(false);
	};

	useEffect(() => {
		resetAsyncList();
	}, [virtualListProps.items]);

	const rowCount = hasNextPage ? allFetchedItems.length + 1 : allFetchedItems.length;

	useEffect(() => {
		// Small timeout to let any potential cellMeasurerCache to be updated before syncing
		setTimeout(() => onLoadMoreResults(), 50);
	}, [allFetchedItems]);

	// Every row is loaded except for our loading indicator row.
	const isRowLoaded = ({ index }: { index: number }) => !hasNextPage || index < allFetchedItems.length;

	const loadNextPage = async () => {
		setIsNextPageLoading(true);
		const { data, metadata } = await loadMoreResults(rowCount);
		setAllFetchedItems([...allFetchedItems, ...(data ?? [])]);
		if (metadata) {
			setHasNextPage(metadata.limit + metadata.offset < metadata.total);
		} else {
			setHasNextPage(false);
		}
		setIsNextPageLoading(false);
	};

	// Only load 1 page of items at a time.
	// Pass an empty callback to InfiniteLoader in case it asks us to load more than once.
	const loadMoreRows: any = isNextPageLoading ? () => {} : loadNextPage;
	return (
		<InfiniteLoader isRowLoaded={isRowLoaded} loadMoreRows={loadMoreRows} rowCount={rowCount}>
			{({ onRowsRendered, registerChild }) => (
				<VirtualList
					{...virtualListProps}
					ref={registerChild}
					items={allFetchedItems}
					onRowsRendered={onRowsRendered}
				/>
			)}
		</InfiniteLoader>
	);
};

export default AsyncVirtualList;
