export const RESET = '[AdminEditProduct] RESET';
export const SET_LOADING = '[AdminEditProduct] SET_LOADING';
export const SET_SAVING = '[AdminEditProduct] SET_SAVING';
export const SET_RECURRING_INTERVALS = '[AdminEditProduct] SET_RECURRING_INTERVALS';
export const SET_CATEGORIES = '[AdminEditProduct] SET_CATEGORIES';
export const SET_PRODUCT = '[AdminEditProduct] SET_PRODUCT';
export const SET_INITIAL_PRODUCT = '[AdminEditProduct] SET_INITIAL_PRODUCT';
export const SET_INITIAL_CATEGORY = '[AdminEditProduct] SET_INITIAL_CATEGORY';
export const SET_CURRENCIES = '[AdminEditProduct] SET_CURRENCIES';
export const SET_MULTI_CURRENCY = '[AdminEditProduct] SET_MULTI_CURRENCY';
export const SET_CONTRIBUTION_MARGIN_ACTIVE = '[AdminEditProduct] SET_CONTRIBUTION_MARGIN_ACTIVE';
export const SET_MASTER_CURRENCY = '[AdminEditProduct] SET_MASTER_CURRENCY';
export const SET_ERRORS = '[AdminEditProduct] SET_ERRORS';
export const SET_PRICE_TYPE = '[AdminEditProduct] SET_PRICE_TYPE';
export const SET_TIER_TYPE = '[AdminEditProduct] SET_TIER_TYPE';
export const SET_CAN_ADD_TIER_ROW = '[AdminEditProduct] SET_CAN_ADD_TIER_ROW';
export const SET_PRICE_LIST = '[AdminEditProduct] SET_PRICE_LIST';

export const PRICE_TYPE = {
	PER_UNIT: 'PER_UNIT',
	TIERED: 'TIERED'
};

export const TIER_TYPE = {
	UNIT: 'UNIT',
	TOTAL: 'TOTAL'
};

export const initialState = {
	loading: true,
	saving: false,
	intervals: [],
	categories: [],
	product: {},
	initialCategory: null,
	initialProduct: null,
	currencies: [],
	multiCurrency: false,
	contributionMarginActive: false,
	masterCurrency: null,
	errors: {
		name: false,
		tier: null,
		priceLists: []
	},
	priceType: PRICE_TYPE.PER_UNIT,
	tierType: TIER_TYPE.UNIT,
	canAddTierRow: false,
	priceList: null
};

const ACTION_HANDLERS = {
	[RESET]: () => ({ ...initialState }),
	[SET_LOADING]: (state, { loading }) => ({ ...state, loading }),
	[SET_SAVING]: (state, { saving }) => ({ ...state, saving }),
	[SET_RECURRING_INTERVALS]: (state, { intervals }) => ({ ...state, intervals }),
	[SET_CATEGORIES]: (state, { categories }) => ({ ...state, categories }),
	[SET_PRODUCT]: (state, { product }) => ({ ...state, product }),
	[SET_INITIAL_PRODUCT]: (state, { initialProduct }) => ({ ...state, initialProduct }),
	[SET_INITIAL_CATEGORY]: (state, { initialCategory }) => ({ ...state, initialCategory }),
	[SET_CURRENCIES]: (state, { currencies }) => ({ ...state, currencies }),
	[SET_MULTI_CURRENCY]: (state, { multiCurrency }) => ({ ...state, multiCurrency }),
	[SET_CONTRIBUTION_MARGIN_ACTIVE]: (state, { contributionMarginActive }) => ({ ...state, contributionMarginActive }),
	[SET_MASTER_CURRENCY]: (state, { masterCurrency }) => ({ ...state, masterCurrency }),
	[SET_ERRORS]: (state, { errors }) => ({ ...state, errors: { ...state.errors, ...errors } }),
	[SET_PRICE_TYPE]: (state, { priceType }) => {
		if (!PRICE_TYPE[priceType]) {
			return state;
		}
		return { ...state, priceType: PRICE_TYPE[priceType] };
	},
	[SET_TIER_TYPE]: (state, { tierType }) => {
		if (!TIER_TYPE[tierType]) {
			return state;
		}
		return { ...state, tierType: TIER_TYPE[tierType] };
	},
	[SET_CAN_ADD_TIER_ROW]: (state, { canAddTierRow }) => ({ ...state, canAddTierRow }),
	[SET_PRICE_LIST]: (state, { priceList }) => ({ ...state, priceList })
};

export default (state = { ...initialState }, action) => {
	const handler = ACTION_HANDLERS[action.type];
	return handler ? handler(state, action) : state;
};
