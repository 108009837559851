import orderFilters from 'App/babel/filterConfigs/Order';
import agreementFilters from 'App/babel/filterConfigs/Agreement';
import activityFilters from 'App/babel/filterConfigs/Activity';
import useCategoryConfigs from '../useCategoryConfigs';
import { FilterConfig } from 'App/babel/filterConfigs/FilterConfig';

const useFiltersWithCategories = (filters: { [key: string]: FilterConfig }) => {
	const accountCategories = useCategoryConfigs(
		'account',
		'Account',
		{
			field: 'client.category.id',
			parent: 'default.account',
			entity: undefined
		},
		'default.accountCategories'
	);

	return {
		...filters,
		...accountCategories
	};
};

export const useOrderFilters = () => useFiltersWithCategories(orderFilters());

export const useAgreementFilters = () => useFiltersWithCategories(agreementFilters());

export const useActivityFilters = () => useFiltersWithCategories(activityFilters());
