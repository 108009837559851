import { useState, useEffect } from 'react';
import logError from 'App/babel/helpers/logError';

const useAnimationData = (importFn: () => Promise<any>) => {
	const [error, setError] = useState(null);
	const [animationData, setAnimationData] = useState(null);

	useEffect(() => {
		importFn()
			.then(data => {
				setAnimationData(() => JSON.parse(JSON.stringify(data)));
			})
			.catch(e => {
				setError(e);
				logError(e, 'Failed to load animation data');
			});
	}, []);

	return { loading: animationData === null, error, animationData };
};

export default useAnimationData;
