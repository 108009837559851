import React from 'react';
import { connect } from 'react-redux';
import { showRegularView } from 'Store/reducers/SharedViewsReducer';

import HiddenList from '../Lists/HiddenList';
import T from '../../Helpers/translate';

import Bem from '@upsales/components/Utils/bemClass';
import { Icon, Title } from '@upsales/components';

import './HiddenView.scss';

const mapStateToProps = state => ({
	privateList: state.SharedViews.privateList,
	sharedList: state.SharedViews.sharedList,
	type: state.SharedViews.hiddenViewType
});

function HiddenView({ privateList, sharedList, showRegularView, type, changeView }) {
	const HiddenClass = new Bem('HideView');
	const data = {
		private: privateList,
		shared: sharedList
	};

	let list = [];

	if (type) {
		list = data[type].filter(item => {
			if (item.hidden === true) {
				return true;
			}

			return false;
		});
	}

	return (
		<div className={HiddenClass}>
			<Title className={HiddenClass.elem('Title')}>
				<Icon color="grey-10" name="chevron-left" onClick={showRegularView} />
				{T('sharedViews.hiddenViews')}
			</Title>
			<HiddenList list={list} changeView={changeView} />
		</div>
	);
}

export const detached = HiddenView;
export default connect(mapStateToProps, { showRegularView })(HiddenView);
