const roundToNearTen = function (n: number, enabled = false) {
	if (!enabled) {
		return n.toFixed(2);
	}
	return Math.ceil(n / 10) * 10;
};

function fileSize(size: number, round = false, abstract = false) {
	const bz = abstract ? 1000 : 1024;
	if (isNaN(size)) size = 0;

	if (size < bz) return size + ' Bytes';

	size /= bz;

	if (size < bz) return roundToNearTen(size, round) + ' Kb';

	size /= bz;

	if (size < bz) return roundToNearTen(size, round) + ' Mb';

	size /= bz;

	if (size < bz) return roundToNearTen(size, round) + ' Gb';

	size /= bz;

	return roundToNearTen(size, round) + ' Tb';
}

export default fileSize;
