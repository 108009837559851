import React from 'react';
import { Text, Link } from '@upsales/components';
import moment from 'moment';
import TimelineHistoryUsers from '../TimelineHistoryUsers';
import TimelineRow from '../TimelineRow';
import T from 'Components/Helpers/translate';
import { EsignEvent } from 'App/babel/propTypes/EventShape';
import { EVENT_TIMELINE_SUB_TYPES } from 'App/babel/enum/timeline';

const getContactHref = id =>
	Tools.$state.href('contact.dashboard', {
		id,
		customerId: Tools.AppService.getCustomerId()
	});

const openEsign = id => Tools.$upModal.open('confirmEsign', { id });

const getInvolvedText = involved => {
	return (
		<Text>
			{involved[0].contactId ? (
				<Link onClick={() => getContactHref(involved[0].contactId)}>{T('default.eSign').toLowerCase()}</Link>
			) : (
				`${involved[0].fstname} ${involved[0].sndname}`
			)}
			{involved.length > 1
				? ` ${T('default.and').toLowerCase()} ${involved.length - 1} ${T('filters.more').toLowerCase()}`
				: null}
		</Text>
	);
};

const TimelineEsign = ({ event }) => {
	const { date, subType, esign, users } = event || {};

	if (!esign) {
		return null;
	}

	const contacts = event.contacts || [];
	const icon = 'edit';
	const iconBg = 'white';
	const iconColor = 'grey-10';
	let Component = TimelineRow;
	let title;
	const type = subType.toLowerCase();
	switch (type) {
		case EVENT_TIMELINE_SUB_TYPES.SENT:
		case EVENT_TIMELINE_SUB_TYPES.DRAFT:
			if (!users || !users.length) {
				return null;
			}
			Component = TimelineHistoryUsers;
			title = (
				<Text>
					{` ${users[0].name} ${T(
						type === EVENT_TIMELINE_SUB_TYPES.SENT ? 'esign.didSend' : 'esign.didDraft'
					).toLowerCase()} `}
					<Link onClick={() => openEsign(event.entityId)}>{T('default.eSign').toLowerCase()}</Link>
					{` ${T('default.to').toLowerCase()} `}
					{getInvolvedText(esign.involved)}
				</Text>
			);
			break;
		case EVENT_TIMELINE_SUB_TYPES.SIGNED:
		case EVENT_TIMELINE_SUB_TYPES.DECLINED:
		case EVENT_TIMELINE_SUB_TYPES.VIEWED:
			if (!contacts.length) {
				Component = TimelineHistoryUsers;
			}

			title = (
				<Text>
					{contacts.length ? (
						<Link onClick={() => getContactHref(event.contacts[0].id)}>{event.contacts[0].name}</Link>
					) : (
						users[0].name
					)}
					{` ${T(
						type === EVENT_TIMELINE_SUB_TYPES.SIGNED
							? 'esign.didSign'
							: type === EVENT_TIMELINE_SUB_TYPES.DECLINED
							? 'esign.rejected'
							: 'esign.viewed'
					).toLowerCase()} `}
					<Link onClick={() => openEsign(event.entityId)}>{T('default.eSign').toLowerCase()}</Link>
				</Text>
			);
			break;
	}

	const subTitle = date ? (
		<Text size="sm" color="grey-11">
			{moment(date).format('L LT')}
		</Text>
	) : null;
	return (
		<Component
			icon={icon}
			iconColor={iconColor}
			iconBackground={iconBg}
			event={event}
			title={title}
			subTitle={subTitle}
			subSection={null}
		/>
	);
};

TimelineEsign.propTypes = {
	event: EsignEvent.isRequired
};

export default TimelineEsign;
