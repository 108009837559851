import { Headline, Link, Select, Text, Title } from '@upsales/components';
import BouncingTooltip from 'Components/Tooltip/BouncingTooltip';
import T from 'Components/Helpers/translate';
import React, { useMemo } from 'react';
import BemClass from '@upsales/components/Utils/bemClass';
import SubscriptionGroupScheduling from '../SubscriptionGroupScheduling';
import { useSubscriptionGroupContext } from '../../Context/SubscriptionGroupContext';
import { SlideFade } from '@upsales/components/animations';
import './SubscriptionScheduling.scss';

const SubscriptionScheduling: React.FC = () => {
	const classes = new BemClass('SubscriptionScheduling');

	// This is temporary until we can create our own modes
	const defaultMode = {
		id: 1,
		title: T('subscription.modal.summary.scheduling.standardTitle'),
		subtitle: T('subscription.modal.summary.scheduling.standardSubtitle')
	};
	const advancedMode = {
		id: 2,
		title: T('subscription.modal.summary.scheduling.advancedTitle'),
		subtitle: T('subscription.modal.summary.scheduling.advancedSubtitle')
	};

	const {
		updateAdvancedMode,
		state: { isEdit, isAdvancedMode, splitEnabled, subscriptionMap }
	} = useSubscriptionGroupContext();

	const locked = Object.values(subscriptionMap).some(sub => sub.locked);
	const wasInitiallyAdvanced = useMemo(() => isAdvancedMode, []);
	const newSplitFlag = Tools.FeatureHelper.hasSoftDeployAccess('SUBSCRIPTION_SPLITS');
	const selectedMode = useMemo(() => {
		if (isAdvancedMode) {
			return advancedMode;
		}
		return defaultMode;
	}, [isAdvancedMode]);

	const hasGroupScheduling = splitEnabled && !newSplitFlag;

	return (
		<div className={classes.elem('title-container').mod({ 'grey-background': hasGroupScheduling }).b()}>
			<div className={classes.elem('title-content').b()}>
				<div className={classes.elem('title').b()}>
					<Headline size="sm">{T('subscription.modal.summary.scheduling.title')}</Headline>
					{!newSplitFlag ? (
						<BouncingTooltip
							title={T('subscription.modal.summary.scheduling.template.tooltip')}
							direction="right"
							visible={!isEdit}
							distance={6}
						>
							{makeTooltipDisappear => {
								return (
									<Title size="lg">
										{T('subscription.modal.summary.scheduling.template.title')}
										<Select
											className={classes.elem('mode-select').b()}
											dropdownStyle={{ minWidth: '340px' }}
											value={selectedMode}
											disabled={locked || (isEdit && wasInitiallyAdvanced)}
											onChange={v => {
												updateAdvancedMode(v.id === advancedMode.id);
												makeTooltipDisappear();
											}}
											options={[defaultMode, advancedMode]}
											inline
											showSearch={false}
											renderSelectedItem={selected => <Link>{selected?.title}</Link>}
											anchor={document.querySelector('.OpenSubscription__inner')}
											dropdownClassName={classes.elem('mode-select').elem('dropdown').b()}
										/>
									</Title>
								);
							}}
						</BouncingTooltip>
					) : null}
				</div>
				{!newSplitFlag ? (
					<Text color="grey-11" size="sm">
						{T('subscription.modal.summary.scheduling.subTitle')}
					</Text>
				) : null}
				<SlideFade visible={hasGroupScheduling} height maxHeight={104}>
					<div>
						<SubscriptionGroupScheduling />
					</div>
				</SlideFade>
			</div>
		</div>
	);
};

export default SubscriptionScheduling;
