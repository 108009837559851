import { onboardingTracker } from 'App/babel/helpers/Tracker';
import _ from 'lodash';
import OnboardingCompletedResource from '../../resources/OnboardingCompleted';
import OnboardingStepCompletedResource from '../../resources/OnboardingStepCompleted';
import StandardIntegrationResource from '../../resources/StandardIntegration';
import OnboardingImportResource from '../../resources/OnboardingImport';
import IntegrationHelper from 'App/helpers/IntegrationHelper';

const initialState = {
	hasStarted: false,
	fullscreen: false,
	isSubStepActive: false,
	currentStepId: null,
	currentSubstepId: null,
	currentCustomStepId: null,
	currentStepType: '',
	currentStepComponent: '',
	onboarding: { steps: [] },
	importApps: [],
	importAppsLoading: true,
	imports: [],
	importsLoading: false,
	visits: [],
	visitsLoading: false,
	customSteps: [],
	companyProfile: null,
	importConfig: {},
	importFields: [],
	activeImportApp: null,
	importStepLoading: false,
	gettingOauth: false,
	oauthErr: null,
	gettingOauthErr: null,
	oauth: null,
	$oauthCallbackPath: '',
	$oauthState: '',
	onboardingCompletedId: null,
	visitScript: '',
	importStepCompletion: {
		1: false,
		2: false,
		3: false
	},
	isInitialized: false,
	isLastUnfinishedStep: false
};

const TOGGLE_FULLSCREEN = '[Onboarding] TOGGLE_FULLSCREEN';
const SET_FULLSCREEN = '[Onboarding] SET_FULLSCREEN';
const SET_ACTIVE_TRACK = '[Onboarding] SET_ACTIVE_TRACK';
const GO_TO_NEXT_STEP = '[Onboarding] GO_TO_NEXT_STEP';
const GO_TO_STEP = '[Onboarding] GO_TO_STEP';
const SUBSTEP = '[Onboarding] SUBSTEP';
const INIT_ONBOARDING_COMPONENT = '[Onboarding] INIT_ONBOARDING_COMPONENT';
const GO_TO_MAINSTEP = '[Onboarding] GO_TO_MAINSTEP';
const INIT_ONBOARDING = '[Onboarding] INIT_ONBOARDING';
const START_ONBOARDING = '[Onboarding] START_ONBOARDING';
const SET_PROFILE_DATA = '[Onboarding] SET_PROFILE_DATA';
const UPDATE_DATA = '[Onboarding] UPDATE_DATA';
const IMPORT_APPS_LOADED = '[Onboarding] IMPORT_APPS_LOADED';
const SET_ACTIVE_IMPORT_APP = '[Onboarding] SET_ACTIVE_IMPORT_APP';
const IMPORT_CONFIG_CHANGED = '[Onboarding] IMPORT_CONFIG_CHANGED';
const IMPORT_FIELDS_CHANGED = '[Onboarding] IMPORT_FIELDS_CHANGED';
const IMPORT_STEP_COMPLETED = '[Onboarding] IMPORT_STEP_COMPLETED';
const IMPORT_STEP_LOADING = '[Onboarding] IMPORT_STEP_LOADING';
const IMPORTS_LOADING = '[Onboarding] IMPORTS_LOADING';
const SET_IMPORTS = '[Onboarding] SET_IMPORTS';
const VISITS_LOADING = '[Onboarding] VISITS_LOADING';
const SET_VISITS = '[Onboarding] SET_VISITS';
const SET_OAUTH = '[Onboarding] SET_OAUTH';
const SET_APP_CONFIG = '[Onboarding] SET_APP_CONFIG';
const SET_ACCOUNT_PROFILE = '[Onboarding] SET_ACCOUNT_PROFILE';
const SET_VISIT_SCRIPT = '[Onboarding] SET_VISIT_SCRIPT';

export const reducer = (state = initialState, action) => {
	switch (action.type) {
		case INIT_ONBOARDING:
		case GO_TO_NEXT_STEP:
		case SUBSTEP:
		case IMPORT_APPS_LOADED:
		case IMPORT_CONFIG_CHANGED:
		case IMPORT_FIELDS_CHANGED:
		case SET_ACTIVE_IMPORT_APP:
		case IMPORT_STEP_COMPLETED:
		case IMPORT_STEP_LOADING:
		case SET_PROFILE_DATA:
		case IMPORTS_LOADING:
		case SET_IMPORTS:
		case SET_OAUTH:
		case GO_TO_STEP:
		case SET_ACCOUNT_PROFILE:
		case VISITS_LOADING:
		case SET_VISITS:
		case SET_VISIT_SCRIPT:
		case SET_FULLSCREEN:
			return { ...state, ...action.data };
		case SET_APP_CONFIG:
			return { ...state, activeImportApp: { ...state.activeImportApp, config: action.data } };
		case START_ONBOARDING:
			return { ...state, hasStarted: true, ...action.data };
		case TOGGLE_FULLSCREEN:
			return { ...state, fullscreen: !state.fullscreen };
		case SET_ACTIVE_TRACK:
			return { ...state, currentStepId: action.data };
		case INIT_ONBOARDING_COMPONENT:
			return { ...state, currentStepType: action.currentStepType };
		case GO_TO_MAINSTEP:
			return {
				...state,
				isSubStepActive: false,
				currentSubstepId: action.firstSubStepId,
				currentStepComponent: 'default'
			};
		case UPDATE_DATA:
			return { ...state, onboarding: action.data, lastUpdate: Date.now() };
		default:
			return state;
	}
};

export const setupAngularEventListeners = () => async (dispatch, getState) => {
	Tools.$rootScope.$on('onboardingStepCompleted', (angularScope, { stepId }) => {
		const { onboarding } = getState().Onboarding;
		dispatch(setMetaDataCompletedStep(stepId, { skipTrack: true }));
		const step = _.find(onboarding.steps, { id: stepId });

		if (step && step.stepType === 'Script') {
			dispatch(loadVisits());
		}
	});

	Tools.$rootScope.$on('standardIntegrationImportChanged', (angularScope, updatedImport) => {
		const { imports } = getState().Onboarding;
		const updatedImportIsPowerImport = isPowerImport(updatedImport);

		const index = _.findIndex(imports, anImport => {
			return anImport.id === updatedImport.id && updatedImportIsPowerImport === isPowerImport(anImport);
		});

		const newImportsArray = [...imports];

		if (index > -1) {
			newImportsArray[index] = updatedImport;
		} else {
			newImportsArray.push(updatedImport);
		}

		dispatch({ type: SET_IMPORTS, data: { imports: newImportsArray } });
	});
};

export const init =
	(onboarding, opts = {}) =>
	async (dispatch, getState) => {
		if (!onboarding || (Array.isArray(onboarding) && !onboarding.length)) {
			return;
		}

		if (getState().Onboarding.isInitialized) {
			const onboarding = getState().Onboarding.onboarding;
			const currentStep = _.find(onboarding.steps, step => !step.completed) || onboarding.steps[0];
			dispatch(navigateStep(currentStep));
			return;
		}

		if (Array.isArray(onboarding)) {
			onboarding = onboarding[0];
		}

		dispatch(setupAngularEventListeners());

		const importAppsPromise = dispatch(loadImportApps());
		dispatch(loadImports());
		dispatch(loadVisits());
		dispatch(loadVisitSCript());
		const companyProfilePromise = dispatch(loadCompanyProfile());
		const hasStarted = !!onboarding.onboardingCompletedId || opts.initialImportApp ? true : false;
		const data = {
			onboarding,
			onboardingCompletedId: onboarding.onboardingCompletedId,
			hasStarted,
			isInitialized: true
		};

		dispatch({ type: INIT_ONBOARDING, data });

		if (opts.initialImportApp) {
			await importAppsPromise; /* We need to wait for the apps to load so we can navigate to it */
			const { importApps } = getState().Onboarding;

			const activeImportApp = _.find(importApps, { id: opts.initialImportApp });
			const customStep = activeImportApp.startStep;
			const currentSubStep = _.find(customStep.substeps, { id: opts.initialSubstepId });

			dispatch(initImportApp(customStep));
			dispatch(navigateStep(currentSubStep));
			dispatch(onGetOauthToken(opts.oauthCode));
		} else {
			await companyProfilePromise; /* We need to wait for company profile as we may endup on a companyProfile step */
			const currentStep = _.find(onboarding.steps, step => !step.completed) || onboarding.steps[0];
			dispatch(navigateStep(currentStep));
		}
	};

function loadVisitSCript() {
	return dispatch => {
		return Tools.LeaderAccount.getScript().then(({ data }) => {
			dispatch({ type: SET_VISIT_SCRIPT, data: { visitScript: data } });
		});
	};
}

function loadCompanyProfile() {
	return dispatch => {
		return Tools.AccountProfile.get().then(result => {
			const companyProfile = result.data ? result.data : Tools.AccountProfile.new();
			dispatch({ type: SET_ACCOUNT_PROFILE, data: { companyProfile } });
		});
	};
}

function isPowerImport(anImport) {
	return anImport.hasOwnProperty('numRows');
}

function loadImports(filters = {}) {
	return async dispatch => {
		dispatch({ type: IMPORTS_LOADING, data: { importsLoading: true } });

		const promises = [
			Tools.Import.find({ status: 'FINISHED' }),
			Tools.Import.find({ status: 'STARTED' }),
			Tools.Import.find({ status: 'START' }),
			OnboardingImportResource.find(filters)
		];

		const result = await Tools.$q.all(promises);

		const imports = result.reduce((allImports, result) => {
			return allImports.concat(result.data);
		}, []);

		dispatch({ type: SET_IMPORTS, data: { imports } });
		dispatch({ type: IMPORTS_LOADING, data: { importsLoading: false } });
	};
}

function loadVisits() {
	return async dispatch => {
		dispatch({ type: VISITS_LOADING, data: { visitsLoading: true } });

		const attr = Tools.ListAttributes.get('visitor').attr;

		const customerId = Tools.AppService.getCustomerId();
		const rb = new Tools.RequestBuilder();
		rb.addSort(attr.startDate, true);
		rb.limit = 20;

		const { data: visits } = await Tools.Visitor.customer(customerId).find(rb.build());

		dispatch({ type: SET_VISITS, data: { visits } });
		dispatch({ type: VISITS_LOADING, data: { visitsLoading: false } });
	};
}

function loadImportApps() {
	return async dispatch => {
		const { data: importApps } = await StandardIntegrationResource.find({ active: true, init: 'import' });

		const customSteps = importApps.reduce((res, app, index) => {
			const id = res.length + 1;

			const step = {
				id: id,
				substepTitle: app.name,
				substepDescription: app.description,
				stepType: 'ImportConfig',
				sortId: index,
				hasSubSteps: true,
				isImportStep: true,
				appId: app.id,
				substeps: [
					{
						id: 1,
						title: 'Enter your credentials',
						stepType: 'Credentials',
						sortId: 1,
						substeps: [],
						parentId: id,
						isImportStep: true
					},
					{
						id: 2,
						title: 'Select what to sync',
						stepType: 'Config',
						sortId: 2,
						substeps: [],
						parentId: id,
						isImportStep: true
					},
					{
						id: 3,
						title: 'Start syncing',
						stepType: 'StartSync',
						sortId: 3,
						substeps: [],
						parentId: id,
						isImportStep: true
					}
				]
			};

			app.startStep = step;
			res.push(step);

			return res;
		}, []);

		dispatch({
			type: IMPORT_APPS_LOADED,
			data: { importApps, customSteps, importAppsLoading: false }
		});
	};
}

export const setCompanyProfileData = (key, value) => (dispatch, getState) => {
	const profile = Object.assign({}, getState().Onboarding.companyProfile);
	_.set(profile, key, value);

	dispatch({
		type: SET_PROFILE_DATA,
		data: {
			companyProfile: profile
		}
	});
};

export const startOnboarding = () => async (dispatch, getState) => {
	const { onboarding } = getState().Onboarding;

	/* This is to hedge against the unthinkable */
	if (!onboarding || !onboarding.id) {
		return;
	}

	onboardingTracker.track(onboardingTracker.events.START);

	const { data: result } = await OnboardingCompletedResource.save({ hasStarted: 1, onboardingId: onboarding.id });
	dispatch({ type: START_ONBOARDING, data: { onboardingCompletedId: result.id, hasStarted: true } });
};

export const toggleFullscreen = () => dispatch => {
	dispatch({ type: TOGGLE_FULLSCREEN });
};

export const goFullScreen = () => dispatch => {
	dispatch({ type: SET_FULLSCREEN, data: { fullscreen: true } });
};

export const setActiveTrack = id => dispatch => {
	dispatch({ type: SET_ACTIVE_TRACK, data: id });
};

export function setMetaDataCompletedStep(stepIdToComplete, opts = {}) {
	return dispatch => {
		Tools.OnboardingHelpers.setMetaDataCompletedStep(stepIdToComplete);
		dispatch(updateOnboardingCompletedStep(stepIdToComplete, opts));
	};
}

export const findStep = (onboarding, stepId) => {
	for (const step of onboarding.steps) {
		if (step.id === stepId) {
			return step;
		} else if (step.substeps) {
			for (const subStep of step.substeps) {
				if (subStep.id === stepId) {
					return subStep;
				}
			}
		}
	}
	return null;
};

export function updateOnboardingCompletedStep(stepIdToComplete, opts) {
	return (dispatch, getState) => {
		const { onboarding: oldOnboarding } = getState().Onboarding;

		const onboarding = Tools.OnboardingHelpers.updateOnboardingCompletedStep(stepIdToComplete, oldOnboarding);

		if (!(opts && opts.skipTrack)) {
			const step = findStep(onboarding, stepIdToComplete);
			if (step) {
				if (step.parentId) {
					const parentStep = findStep(onboarding, step.parentId) || onboarding.steps[0];
					onboardingTracker.track(onboardingTracker.events.COMPLETED, {
						type: parentStep.stepType,
						subtype: step.stepType
					});
				} else {
					onboardingTracker.track(onboardingTracker.events.COMPLETED, { type: step.stepType });
				}
			}
		}

		dispatch({ type: UPDATE_DATA, data: onboarding });
	};
}

const isAllStepsCompleted = () => (dispatch, getState) => {
	const { onboarding } = getState().Onboarding;
	const steps = onboarding.steps || [];

	for (const step of steps) {
		if (!step.hasOwnProperty('completed') || !step.completed) {
			return false;
		}
	}

	return true;
};

export const markStepAsCompleted = () => async (dispatch, getState) => {
	const { currentStepId, currentSubstepId, onboarding, onboardingCompletedId } = getState().Onboarding;

	const mainStep = _.find(onboarding.steps, { id: currentStepId });
	const currentStep = currentSubstepId ? _.find(mainStep.substeps, { id: currentSubstepId }) : mainStep;

	/* Dont need to save if it already completed */
	if (currentStep.completed) {
		return;
	}

	const stepsToCompleat = [];

	stepsToCompleat.push(currentStep.id);

	if (currentSubstepId) {
		/* The main step will finish if all other substeps is complete other than the one we skip now */
		const allSubstepsCompleted = _.every(mainStep.substeps, step => step.completed || step.id === currentSubstepId);

		/* Dont need to save if it already completed */
		if (allSubstepsCompleted && !mainStep.completed) {
			stepsToCompleat.push(mainStep.id);
		}
	}

	const completedDate = new Date();
	const promises = stepsToCompleat.map(stepId => OnboardingStepCompletedResource.save({ stepId, completedDate }));

	try {
		await Promise.all(promises);

		for (const stepId of stepsToCompleat) {
			dispatch(setMetaDataCompletedStep(stepId));
		}
	} catch (error) {
		console.error(error);
	}

	const completedOnboarding = dispatch(isAllStepsCompleted());
	if (completedOnboarding) {
		Tools.OnboardingHelpers.setEntireOnboardingDone(onboardingCompletedId);
	}
};

export const skipStep = () => async (dispatch, getState) => {
	const { onboarding, currentStepId, currentSubstepId, onboardingCompletedId } = getState().Onboarding;

	const mainStep = _.find(onboarding.steps, { id: currentStepId });
	const currentStep = currentSubstepId ? _.find(mainStep.substeps, { id: currentSubstepId }) : mainStep;

	if (currentSubstepId) {
		onboardingTracker.track(onboardingTracker.events.SKIP, {
			type: mainStep.stepType,
			subType: currentStep.stepType
		});
	} else {
		onboardingTracker.track(onboardingTracker.events.SKIP, { type: currentStep.stepType });
	}

	const stepsToCompleat = [];
	/*
		By Henriks design, if you stand on the first substep, i.e. the one that renders like a main step
		then we should finish all substeps of the current step
	*/
	const isSpecialCase = currentSubstepId && currentSubstepId === mainStep.substeps[0].id;

	if (isSpecialCase) {
		if (!mainStep.completed) {
			stepsToCompleat.push(mainStep.id);
		}

		for (const step of mainStep.substeps) {
			if (!step.completed) {
				stepsToCompleat.push(step.id);
			}
		}
	} else {
		if (!currentStep.completed) {
			stepsToCompleat.push(currentStep.id);
		}

		if (currentSubstepId) {
			/* The main step will finish if all other substeps is complete other than the one we skip now */
			const allSubstepsCompleted = _.every(
				mainStep.substeps,
				step => step.completed || step.id === currentSubstepId
			);

			if (allSubstepsCompleted && !mainStep.completed) {
				stepsToCompleat.push(mainStep.id);
			}
		}
	}

	const completedDate = new Date();
	const promises = stepsToCompleat.map(stepId => OnboardingStepCompletedResource.save({ stepId, completedDate }));

	try {
		await Promise.all(promises);

		for (const stepId of stepsToCompleat) {
			dispatch(setMetaDataCompletedStep(stepId, { skipTrack: true }));
		}

		const completedOnboarding = dispatch(isAllStepsCompleted());

		if (completedOnboarding) {
			Tools.OnboardingHelpers.setEntireOnboardingDone(onboardingCompletedId);
		}

		dispatch(navigateNext());
	} catch (error) {
		console.error(error);
	}
};

export const goBackToMainStep = () => (dispatch, getState) => {
	const { onboarding, currentStepId } = getState().Onboarding;
	const currentStep = onboarding.steps.find(t => t.id === currentStepId);

	if (currentStep.substeps) {
		dispatch({ type: GO_TO_MAINSTEP, firstSubStepId: currentStep.substeps[0].id });
	} else {
		dispatch({
			type: GO_TO_NEXT_STEP,
			data: {
				isSubStepActive: false,
				currentSubstepId: null,
				currentCustomStepId: null,
				currentStepType: currentStep.stepType,
				currentStepComponent: ''
			}
		});
	}
};

export function navigateStep(nextStep) {
	return (dispatch, getState) => {
		const { onboarding, customSteps, currentStepId } = getState().Onboarding;

		const step = nextStep.hasSubSteps
			? _.find(nextStep.substeps, step => !step.completed) || nextStep.substeps[0]
			: nextStep;

		let data;
		const isSubStep = step.parentId > 0;

		if (isSubStep) {
			const parentArray = step.isImportStep ? customSteps : onboarding.steps;
			const parent = _.find(parentArray, { id: step.parentId });
			const isSubStepActive =
				step.isImportStep || _.findIndex(parent.substeps, { id: step.id }) > 0 ? true : false;
			const currentCustomStepId = step.isImportStep ? parent.id : null;
			const nextCurrentStepId = step.isImportStep ? currentStepId : parent.id;

			data = {
				isSubStepActive: isSubStepActive,
				currentStepId: nextCurrentStepId,
				currentSubstepId: step.id,
				currentStepType: parent.stepType,
				currentStepComponent: step.stepType,
				currentCustomStepId: currentCustomStepId,
				isLastUnfinishedStep: dispatch(getNextStep(nextCurrentStepId, currentCustomStepId, step.id)) === null
			};
		} else {
			data = {
				isSubStepActive: false,
				currentStepId: step.id,
				currentSubstepId: null,
				currentStepType: step.stepType,
				currentStepComponent: null,
				isLastUnfinishedStep: dispatch(getNextStep(step.id, null, null)) === null
			};
		}
		dispatch({ type: GO_TO_STEP, data });
	};
}

export function getNextStep(currentStepId, currentCustomStepId, currentSubstepId) {
	return (dispatch, getState) => {
		const { onboarding, customSteps } = getState().Onboarding;

		const currentStepIndex = onboarding.steps.findIndex(t => t.id === currentStepId);

		const currentStep = currentCustomStepId
			? customSteps.find(customStep => customStep.id === currentCustomStepId)
			: onboarding.steps[currentStepIndex];
		const nextStep = _.find(onboarding.steps.slice(currentStepIndex + 1), step => !step.completed);

		if (currentSubstepId) {
			const currentSubStepIndex = _.findIndex(currentStep.substeps, { id: currentSubstepId });
			const nextSubStep = _.find(currentStep.substeps.slice(currentSubStepIndex + 1), step => !step.completed);

			if (nextSubStep) {
				return nextSubStep;
			}
		}
		if (nextStep) {
			return nextStep.hasSubSteps
				? _.find(nextStep.substeps, step => !step.completed) || nextStep.substeps[0]
				: nextStep;
		}
		return null;
	};
}

export function navigateNext(opts = {}) {
	return async (dispatch, getState) => {
		const { companyProfile, currentStepId, currentSubstepId, currentStepType, currentCustomStepId } =
			getState().Onboarding;

		if (opts.setAsComplete) {
			if (currentStepType === 'CompanyProfile') {
				Tools.AccountProfile.save(companyProfile);
			}
			await dispatch(markStepAsCompleted());
		}

		const nextStep = dispatch(getNextStep(currentStepId, currentCustomStepId, currentSubstepId));

		if (nextStep) {
			dispatch(navigateStep(nextStep, opts));
		} else {
			Tools.$state.go(Tools.AppService.getSelf().userParams.startPage || 'react-root-salesboard');
		}
	};
}

const setImportStepAsComplete = () => (dispatch, getState) => {
	const { importStepCompletion, currentSubstepId } = getState().Onboarding;
	dispatch({
		type: IMPORT_STEP_COMPLETED,
		data: { importStepCompletion: { ...importStepCompletion, [currentSubstepId]: true } }
	});
};

const setImportStepAsLoading = value => dispatch => {
	dispatch({ type: IMPORT_STEP_LOADING, data: { importStepLoading: value } });
};

const startImportAndGoNext = () => async (dispatch, getState) => {
	const { importConfig, activeImportApp, currentStepId } = getState().Onboarding;
	const customerId = Tools.AppService.getCustomerId();

	dispatch(setImportStepAsLoading(true));

	const appId = activeImportApp.id;
	const appNotificationOptions = activeImportApp.config.notification || {};
	const notificationOptions = _.defaults(appNotificationOptions, {
		create: false,
		showProgressBar: false,
		translationSuffix: null
	});

	try {
		const settings = {
			active: true,
			configJson: JSON.stringify(importConfig),
			id: appId,
			integrationId: appId
		};

		await Tools.StandardIntegration.setting(customerId).save(settings);

		const data = {
			stepId: currentStepId,
			integrationId: appId,
			notification: notificationOptions
		};

		await OnboardingImportResource.save(data);
	} catch (error) {
		console.error(error);
		dispatch(setImportStepAsLoading(false));
		return;
	}
	dispatch(loadImports());
	dispatch(setImportStepAsLoading(false));
	dispatch(setImportStepAsComplete());
	dispatch(navigateNext());
};

export const navigateNextImport =
	({ phase } = { phase: 1 }) =>
	async (dispatch, getState) => {
		switch (phase) {
			case 1:
			case 3: {
				const isLastUnfinishedStep = getState().Onboarding.isLastUnfinishedStep;

				/* If all other steps is complete go back to onboarding step */
				if (isLastUnfinishedStep) {
					await dispatch(setImportStepAsComplete());
					return dispatch(goBackToMainStep());
				} else {
					await dispatch(setImportStepAsComplete());
					return dispatch(navigateNext());
				}
			}
			case 2:
				return dispatch(startImportAndGoNext());
		}
	};

/*
	Code for import configuration
*/

export function onGetOauthToken(code) {
	return async (dispatch, getState) => {
		dispatch({ type: SET_OAUTH, data: { gettingOauth: true } });

		const { $oauthCallbackPath, $oauthState, activeImportApp } = getState().Onboarding;

		const customerId = Tools.AppService.getCustomerId();
		const opts = {
			callbackPath: $oauthCallbackPath.split('?')[0],
			integrationId: activeImportApp.id,
			code: code,
			state: $oauthState
		};

		try {
			const response = await Tools.StandardIntegration.data(customerId).oauth(opts);
			const data = { gettingOauth: false };

			if (response.data && response.data.error) {
				data.oauthErr = response.data.error;
				dispatch({ type: SET_OAUTH, data });
			} else {
				const { importFields } = getState().Onboarding;
				const oauthField = _.find(importFields, { type: 'oauth' }) || _.find(importFields, { type: 'oauth2' });

				dispatch({ type: SET_OAUTH, data });
				dispatch(importConfigChanged(oauthField.name, response.data, true));
			}
		} catch (error) {
			dispatch({ type: SET_OAUTH, data: { gettingOauth: false, gettingOauthErr: error } });
		}
	};
}

export const onRequestOauth = () => (dispatch, getState) => {
	const { $oauthCallbackPath, importFields, $oauthState, activeImportApp, currentSubstepId } = getState().Onboarding;
	const oauthField = _.find(importFields, { type: 'oauth' });

	document.cookie = `importOauthApp=${activeImportApp.id}-${currentSubstepId};expires=${moment()
		.add(5, 'm')
		.toDate()
		.toGMTString()};path=/`;
	window.location = `${oauthField.endpoint}/?state=${$oauthState}&callbackPath=${$oauthCallbackPath}`;
};

export const onRequestOauth2 = () => (dispatch, getState) => {
	const { activeImportApp, $oauthState, importFields, currentSubstepId } = getState().Onboarding;
	const oauthField = _.find(importFields, { type: 'oauth2' });

	document.cookie = `importOauthApp=${activeImportApp.id}-${currentSubstepId};expires=${moment()
		.add(5, 'm')
		.toDate()
		.toGMTString()};path=/`;
	window.location = `${oauthField.endpoint}/?state=${$oauthState}-${activeImportApp.id}&client_id=${oauthField.clientId}&response_type=code&redirect_uri=${oauthField.callbackUri}`;
};

export function initImportApp(step) {
	return async (dispatch, getState) => {
		const { activeImportApp, importApps } = getState().Onboarding;
		const oldAppId = activeImportApp ? activeImportApp.id : null;
		const appId = step.appId;

		if (oldAppId !== appId) {
			const app = _.find(importApps, { id: appId });
			const currentUrl = window.location.hash.replace('#', '');
			const callbackUri = `${currentUrl}${currentUrl.indexOf('?') > -1 ? '&' : '?'}customstepid=${step.id}`;

			const data = {
				activeImportApp: app,
				importStepCompletion: { 1: false, 2: false, 3: false },
				importConfig: {},
				importFields: [],
				$oauthState: Math.floor(Math.random() * 1000000000),
				$oauthCallbackPath: encodeURI(callbackUri)
			};

			dispatch({ type: SET_ACTIVE_IMPORT_APP, data });

			const config = await getConfig(app);
			dispatch({ type: SET_APP_CONFIG, data: config });

			/* For the god damn Fortnox app */
			if (config.runWithAppData) {
				const customerId = Tools.AppService.getCustomerId();
				const settings = {
					active: true,
					configJson: '{}',
					id: appId,
					integrationId: appId
				};

				try {
					Tools.StandardIntegration.setting(customerId).save(settings, { skipNotification: true });
				} catch (error) {
					console.error(error);
				}
			}

			dispatch(initImportConfig());
		}
	};
}

export function initImportConfig() {
	return async (dispatch, getState) => {
		const { activeImportApp } = getState().Onboarding;
		const config = activeImportApp.config;

		const newFields = _.cloneDeep(config.fields.account);
		const newConfig = getInitialImportConfig(newFields);

		const checkedFields = newFields.map(field => IntegrationHelper.checkField(field, newFields, newConfig));

		dispatch({ type: IMPORT_FIELDS_CHANGED, data: { importFields: checkedFields } });
		dispatch({ type: IMPORT_CONFIG_CHANGED, data: { importConfig: newConfig } });

		for (const field of checkedFields) {
			dispatch(handleField(field, true));
		}
	};
}

function getConfig(app) {
	if (app.externalConfig) {
		const customerId = Tools.AppService.getCustomerId();
		return Tools.StandardIntegration.setting(customerId).getExternalConfig(app.id);
	} else {
		try {
			return JSON.parse(app.configJson);
		} catch (error) {
			return {};
		}
	}
}

function getInitialImportConfig(fields) {
	return fields.reduce((res, field) => {
		const value = IntegrationHelper.getFieldInitValue(field, null);
		res[field.name] = value === null && field.type === 'multiselect' ? [] : value;
		return res;
	}, {});
}

const partialUpdateField = (fieldName, partialField) => (dispatch, getState) => {
	const oldImportFields = getState().Onboarding.importFields;
	const importFields = [...oldImportFields];
	const fieldIndex = _.findIndex(importFields, { name: fieldName });

	if (fieldIndex > -1) {
		const oldField = importFields[fieldIndex];
		const updatedField = Object.assign({}, oldField, partialField);
		importFields[fieldIndex] = updatedField;
		dispatch({ type: IMPORT_FIELDS_CHANGED, data: { importFields } });

		return updatedField;
	} else {
		return partialField; // who not, should never happen
	}
};

const updateField = field => (dispatch, getState) => {
	const oldImportFields = getState().Onboarding.importFields;
	const importFields = [...oldImportFields];
	const index = _.findIndex(importFields, { name: field.name });
	importFields[index] = field;

	dispatch({ type: IMPORT_FIELDS_CHANGED, data: { importFields } });

	return field;
};

const checkField = field => (dispatch, getState) => {
	const { importFields, importConfig } = getState().Onboarding;

	const checkedField = IntegrationHelper.checkField(field, importFields, importConfig);

	return dispatch(updateField(checkedField));
};

const validateField = field => async (dispatch, getState) => {
	dispatch(partialUpdateField(field.name, { $testing: true }));

	const { activeImportApp, importConfig } = getState().Onboarding;

	const method = activeImportApp.config.runWithAppData ? 'validateFieldData' : 'validateField';
	const testResult = await IntegrationHelper[method](field.name, importConfig, activeImportApp.id);

	return dispatch(partialUpdateField(field.name, { $testing: false, $tested: testResult }));
};

export const getFieldOptions = field => async (dispatch, getState) => {
	const gettingField = dispatch(partialUpdateField(field.name, { $gettingValues: true }));

	const { activeImportApp, importFields, importConfig } = getState().Onboarding;

	const method = activeImportApp.config.runWithAppData ? 'getFieldValuesData' : 'getFieldValues';
	const valueField = await IntegrationHelper[method](gettingField, importFields, activeImportApp.id, importConfig);

	return valueField.$gettingValuesErr
		? dispatch(
				partialUpdateField(field.name, {
					gettingValuesErr: valueField.$gettingValuesErr,
					$gettingValues: false
				})
		  )
		: dispatch(partialUpdateField(field.name, { values: valueField.values, $gettingValues: false }));
};

export function importConfigChanged(fieldName, fieldValue, checkDependencies) {
	return async (dispatch, getState) => {
		const { importConfig: oldImportConfig, importFields } = getState().Onboarding;

		const importConfig = { ...oldImportConfig, [fieldName]: fieldValue };
		const field = _.find(importFields, { name: fieldName });

		dispatch({ type: IMPORT_CONFIG_CHANGED, data: { importConfig } });

		if (checkDependencies) {
			if (field.externalValidation) {
				const validatedField = await dispatch(validateField(field));

				if (!validatedField.$tested.valid) {
					return validatedField;
				}
			}

			const { importFields } = getState().Onboarding;
			const dependantFields = importFields.filter(otherField => isDependend(otherField, field));

			for (const dependantField of dependantFields) {
				dispatch(handleField(dependantField, false));
			}
		}
	};
}

function isDependend(fieldA, fieldB) {
	return (fieldA.dependencies || []).indexOf(fieldB.name) > -1 && fieldA !== fieldB;
}

function handleField(field, skipCheck) {
	return async (dispatch, getState) => {
		const checkedField = skipCheck ? field : dispatch(checkField(field));

		if (
			!checkedField.$gettingValues &&
			checkedField.visible &&
			checkedField.externalValues &&
			!checkedField.values
		) {
			dispatch(getFieldOptions(checkedField));
		}

		const { importConfig } = getState().Onboarding;

		const configValue = importConfig[field.value];
		const haveValue = configValue && !(Array.isArray(configValue) && !configValue.length);

		if (haveValue) {
			if (field.externalValidation) {
				const validatedField = await dispatch(validateField(checkedField));

				if (!validatedField.$tested.valid) {
					return validatedField;
				}
			}
			const { importFields } = getState().Onboarding;
			const dependantFields = importFields.filter(otherField => isDependend(otherField, checkedField));

			for (const dependantField of dependantFields) {
				dispatch(handleField(dependantField, false));
			}
		}
	};
}

export default reducer;
