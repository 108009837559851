import React, { useRef, useEffect } from 'react';
import { ButtonSelect, Block, Row } from '@upsales/components';
import { useDetectContainerOverflow } from '../hooks';

const HistoryLogFilterButtons = <TType extends string>({
	containerRef,
	metadata,
	buttons,
	historyType,
	setHistoryType
}: {
	containerRef: React.RefObject<HTMLDivElement>;
	buttons: readonly { title: string; value: TType; icon?: string }[];
	metadata: { [k in TType]?: number };
	historyType: TType;
	setHistoryType: StateSetter<TType>;
}) => {
	const buttonSelectRef = useRef<HTMLDivElement>(null);

	const { thresholdMet: isSmall, recalculate } = useDetectContainerOverflow({
		containerRef,
		contentRefs: [buttonSelectRef],
		threshold: 240
	});

	useEffect(() => {
		recalculate();
	}, [metadata]);

	const filteredButtons = buttons.flatMap(obj => {
		if (!metadata[obj.value]) return [];
		return [
			{
				...obj,
				title: `${isSmall && !(obj.value === 'all') ? '' : obj.title} (${metadata[obj.value]})`
			}
		];
	});

	return (
		<Row ref={buttonSelectRef} noWrap>
			<Block className="HistoryLog__filter-select" space="mlm">
				<ButtonSelect size="sm" value={historyType} onChange={setHistoryType} options={filteredButtons} />
			</Block>
		</Row>
	);
};

export default HistoryLogFilterButtons;
