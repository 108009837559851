import Resource from './Resource';

class Segments extends Resource {
	constructor() {
		super('segments');
		this.dateFields = ['modDate', 'createDate'];
	}

	new() {
		return {
			name: '',
			description: '',
			nrOfContacts: 0,
			filter: []
		};
	}
}

const resource = new Segments();

window.Tools.Segments = resource;

export default resource;
