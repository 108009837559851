import Resource from './Resource';

class NotificationSettings extends Resource {
	constructor() {
		super('notificationSettings', null);
	}
}

const resource = new NotificationSettings();

export default resource;
