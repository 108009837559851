import SubscriptionIndexingState, { State } from './../SubscriptionIndexingState/SubscriptionIndexingState';
import { Action } from '../SubscriptionIndexingState/SubscriptionIndexingActions';
import { getMetadata, getRealActiveUsers } from 'Store/selectors/AppSelectors';
import { Flex, Text, DateInput, Title, Block, Select, Toggle, StateFrame } from '@upsales/components';
import PercentageInput from './../PercentageInput/PercentageInput';
import { useTranslation } from 'Components/Helpers/translate';
import { useSoftDeployAccess } from 'App/components/hooks';
import BemClass from '@upsales/components/Utils/bemClass';
import CheckboxToggle from './../CheckboxToggle';
import NotesInput from './../NotesInput';
import React, { useEffect, useMemo, useRef } from 'react';
import LockIcon from '../LockIcon';
import moment from 'moment';

import './SubscriptionIndexingSettings.scss';

type Props = {
	state: State;
	dispatch: React.Dispatch<Action>;
};

const SubscriptionIndexingSettings = (props: Props) => {
	const { state, dispatch } = props;
	const { t } = useTranslation();

	const selectAnchor = useRef<HTMLDivElement | null>(null);

	useEffect(() => {
		selectAnchor.current = document.querySelector('.SubscriptionIndexingSettings');
	}, []);

	const classNames = new BemClass('SubscriptionIndexingSettings');

	const {
		isSpecificRecipient,
		shouldCreateDiff,
		subscriptionNote,
		indexPercentage,
		indexingEnabled,
		indexInterval,
		specificDate,
		specificUser,
		orderNote,
		isLocked,
		name
	} = state;

	const {
		toggleIndexingEnabled,
		toggleCreateDiffOrder,
		setSubscriptionNote,
		toggleSpecificUser,
		setIndexPercentage,
		setIndexInterval,
		setSpecificUser,
		setSpecificDate,
		setOrderNote,
		setName
	} = SubscriptionIndexingState(dispatch);

	const activeUsers = getRealActiveUsers();
	const users = useMemo(() => activeUsers.map(user => ({ id: user.id, title: user.name, name: user.name })), []);

	const hasV2 = useSoftDeployAccess('SUBSCRIPTION_INDEXING_V2');
	const hasIndexingAtRenewal = useSoftDeployAccess('INDEXING_AT_RENEWAL');
	const allowMultiRunTestingOnly = useSoftDeployAccess('NOT_PROD_ENV_ALLOW_MULTI_RUN');
	const minDate = allowMultiRunTestingOnly ? moment().toDate() : moment().add(1, 'day').toDate();

	const groupARRChangesByUser = getMetadata()?.params.GroupARRChangesByUser ?? false;
	const recipientName = groupARRChangesByUser ? t('subscription.indexing.subscription.user') : t('account_manager');

	return (
		<div className={classNames.b()}>
			<div className={classNames.elem('content').b()}>
				<Block space="ptxl" />

				<Block space="ptl pbxl">
					<NotesInput
						placeholder={t('subscription.indexing.name.label')}
						title={t('subscription.indexing.name.label')}
						classNames={classNames}
						onChange={setName}
						value={name ?? ''}
						required
						small
					/>
				</Block>

				<Flex space="ptxl pbxl" alignItems="center">
					<Toggle space="mrs" checked={indexingEnabled} onChange={toggleIndexingEnabled} />
					<Flex direction="column" space="mlm">
						<Text bold>{t('subscription.indexing.active.label')}</Text>
						<Text size="sm" color="grey-11">
							{t('subscription.indexing.active.description')}
						</Text>
					</Flex>
				</Flex>

				<Flex space="ptl pbxl" alignItems="center">
					<Text size="xl">{t('subscription.indexing.percentage.label')}</Text>
					<Block space="mlm" />
					<PercentageInput value={indexPercentage} onChange={setIndexPercentage} />
				</Flex>

				<Block space="ptl pbxl">
					<CheckboxToggle
						tooltipTitle={
							hasIndexingAtRenewal ? t('subscription.indexing.lockIcon.tooltip') : t('default.comingSoon')
						}
						title={t('subscription.indexing.when.label')}
						options={[
							{
								title: t('subscription.indexing.when.specificDay.title'),
								subtitle: t('subscription.indexing.when.specificDay.subtitle'),
								onChange: () => (isLocked ? null : setIndexInterval('once')),
								disabled: isLocked,
								checked: indexInterval === 'once'
							},
							{
								title: t('subscription.indexing.when.renewal.title'),
								subtitle: t('subscription.indexing.when.renewal.subtitle'),
								onChange: () =>
									hasIndexingAtRenewal && !isLocked ? setIndexInterval('renewal') : null,
								disabled: !hasIndexingAtRenewal || isLocked,
								checked: indexInterval === 'renewal'
							}
						]}
					/>

					{indexInterval === 'once' && specificDate ? (
						<Block space="pts">
							<Flex alignItems="center" space="ptm">
								{isLocked ? <LockIcon /> : null}
								<Flex direction="column">
									<Text space="pbs" bold>
										{t('subscription.indexing.specificDay.chooseDay.label')}
									</Text>
									<DateInput
										title=""
										value={new Date(specificDate)}
										min={minDate}
										max={moment().add(2, 'year').toDate()}
										disabled={isLocked}
										onChange={e => {
											if (e.target.value) {
												setSpecificDate(moment(e.target.value).format('YYYY-MM-DD'));
											}
										}}
										closeOnSelect
									/>
								</Flex>
							</Flex>
						</Block>
					) : null}
					{indexInterval === 'renewal' ? (
						<Block space="ptm">
							<Block space="ptm" />
							<StateFrame
								title={t('subscription.indexing.when.renewal.stateFrame')}
								icon="info-circle"
								state={'info'}
							/>
						</Block>
					) : null}
				</Block>

				<Block space="ptl pbxl">
					<CheckboxToggle
						title={t('subscription.indexing.whom.title')}
						options={[
							{
								title: t('subscription.indexing.whom.dynamic.title') + ' ' + recipientName,
								onChange: () => toggleSpecificUser(false),
								checked: !isSpecificRecipient
							},
							{
								title: t('subscription.indexing.whom.specific.title'),
								onChange: () => toggleSpecificUser(true),
								checked: isSpecificRecipient
							}
						]}
					/>

					{isSpecificRecipient ? (
						<>
							<Block space="pts" />
							<Block space="ptm" className={classNames.elem('user-select').b()}>
								<Text bold>{t('default.selectUser')}</Text>
								<Select
									options={users}
									value={specificUser}
									onChange={setSpecificUser}
									placeholder={t('default.selectUser')}
									anchor={selectAnchor.current}
									showSearch
								/>
							</Block>
						</>
					) : null}
				</Block>

				<Block space="ptl pbxl">
					{indexInterval === 'once' ? (
						<CheckboxToggle
							title={t('subscription.indexing.difforder.title')}
							subtitle={t('subscription.indexing.difforder.subtitle')}
							options={[
								{
									title: t('subscription.indexing.difforder.yes.title'),
									subtitle: t('subscription.indexing.difforder.yes.subtitle'),
									onChange: () => (hasV2 ? toggleCreateDiffOrder(true) : null),
									disabled: !hasV2,
									checked: shouldCreateDiff
								},
								{
									title: t('subscription.indexing.difforder.no.title'),
									subtitle: t('subscription.indexing.difforder.no.subtitle'),
									onChange: () => toggleCreateDiffOrder(false),
									checked: !shouldCreateDiff
								}
							]}
						/>
					) : null}
				</Block>

				<Block space="ptl pbxl">
					<Block space="pbs">
						<Title>{t('subscription.indexing.notes.label')}</Title>
					</Block>
					<Block space="ptm pbm">
						<NotesInput
							title={t('subscription.indexing.notes.agreement.title')}
							description={t('subscription.indexing.notes.agreement.description')}
							placeholder={t('subscription.indexing.addNote')}
							value={subscriptionNote}
							onChange={setSubscriptionNote}
							classNames={classNames}
						/>
					</Block>
					{hasV2 && indexInterval === 'once' && shouldCreateDiff ? (
						<Block space="ptm">
							<NotesInput
								title={t('subscription.indexing.notes.order.title')}
								description={t('subscription.indexing.notes.order.description')}
								placeholder={t('subscription.indexing.addNote')}
								value={orderNote}
								onChange={setOrderNote}
								classNames={classNames}
							/>
						</Block>
					) : null}
				</Block>
			</div>
		</div>
	);
};

export default SubscriptionIndexingSettings;
