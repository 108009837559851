import Resource from './Resource';

class AccountManagerHistory extends Resource {
	constructor() {
		super('function/accountManagerHistory', null, {
			notificationTitle: 'accountManagerHistory.title'
		});
	}

	changeHistory(clientId, date, userIds) {
		return this._putRequest(
			`?clientId=${clientId}&date=${date}${userIds.map(id => `&userIds=${id}`).join('')}`,
			undefined,
			{ methodName: 'save' }
		);
	}

	changeSpecific(clientId, agreementId, userId) {
		return this._putRequest(
			`/specific?clientId=${clientId}&agreementId=${agreementId}&userId=${userId}`,
			undefined,
			{ methodName: 'save' }
		);
	}
}

const resource = new AccountManagerHistory();

export default resource;
