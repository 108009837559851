import { type EntityFilter } from 'App/pages/AdvancedSearch/AdvancedSearchEntities';

export type FilterNameTypes = 'ACCOUNT' | 'CONTACT' | 'ACTIVITY' | 'APPOINTMENT' | 'OPPORTUNITY' | 'ORDER';

export enum AdvancedSearchType {
	accounts = 'accounts',
	contacts = 'contacts',
	activities = 'activities',
	appointments = 'appointments',
	opportunities = 'opportunities',
	orders = 'orders'
}

export const filterNameMap = {
	[AdvancedSearchType.accounts]: 'ACCOUNT',
	[AdvancedSearchType.contacts]: 'CONTACT',
	[AdvancedSearchType.activities]: 'ACTIVITY',
	[AdvancedSearchType.appointments]: 'APPOINTMENT',
	[AdvancedSearchType.opportunities]: 'OPPORTUNITY',
	[AdvancedSearchType.orders]: 'ORDER'
} as const;

export const customFieldEntityMap = {
	[AdvancedSearchType.accounts]: 'account',
	[AdvancedSearchType.contacts]: 'contact',
	[AdvancedSearchType.activities]: 'activity',
	[AdvancedSearchType.appointments]: 'appointment',
	[AdvancedSearchType.opportunities]: 'opportunity',
	[AdvancedSearchType.orders]: 'order'
} as const;

export enum ListViewMap {
	accounts = 'advancedSearchAccount',
	contacts = 'advancedSearchContact',
	activities = 'advancedSearchActivity',
	appointments = 'advancedSearchAppointment',
	opportunities = 'advancedSearchOpportunity',
	orders = 'advancedSearchOrder'
}

export enum AdvancedSearchEntity {
	accounts = 'Client',
	contacts = 'Contact',
	activities = 'Activity',
	appointments = 'Appointment',
	opportunities = 'Opportunity',
	orders = 'Order'
}

export function getTypesObject<T>(value: T) {
	return (Object.keys(AdvancedSearchType) as AdvancedSearchType[]).reduce(
		(r, t) => ({ ...r, [t]: value }),
		{} as Record<AdvancedSearchType, T>
	);
}

export const fixOrderFilters = (filters: EntityFilter) => {
	TypedObject.keys(filters).forEach(key => {
		const entityFilters = filters[key];
		if (!entityFilters) {
			return;
		}

		[entityFilters.OrderFilters?.value, entityFilters.OpportunityFilters?.value].forEach(filterValue => {
			// Only fix if array contains objects
			if (filterValue?.OrderRowProduct?.value?.[0]?.id || filterValue?.OrderRowProductCategory?.value?.[0]?.id) {
				const productIds: number[] = [];
				const categoryIds: number[] = [];
				const selectedOrder: { id: number; isCategory?: boolean }[] = filterValue?.OrderRowProduct?.value || [];

				selectedOrder.forEach(product => {
					if (product.isCategory) {
						categoryIds.push(product.id);
					} else {
						productIds.push(product.id);
					}
				});

				if (filterValue.OrderRowProduct?.value) {
					filterValue.OrderRowProduct.value = productIds;
				}
				if (filterValue.OrderRowProductCategory?.value) {
					filterValue.OrderRowProductCategory.value = categoryIds;
					filterValue.OrderRowProductCategory.comparisonType = filterValue.OrderRowProduct.comparisonType;
				}
			}
		});
	});
};

// Don't know if this is needed. Will find out when i do customfields and other entities
// const FILTER_NAMES = {
// 	ACCOUNT: [
// 		'Id',
// 		'Name',
// 		'User',
// 		'ParentId',
// 		'AddressFilters',
// 		'OrderFilters',
// 		'OpportunityFilters',
// 		'ActivityFilters',
// 		'Fax',
// 		'Phone',
// 		'Webpage',
// 		'Notes',
// 		'Campaign',
// 		'StandardCategory',
// 		'Score',
// 		'MailCampaignFilters',
// 		'VisitorFilters',
// 		'FormSubmitFilters',
// 		'AccountManagerFilters',
// 		'Status',
// 		'RegistrationDate',
// 		'CompanyForm',
// 		'CreditRating',
// 		'Turnover',
// 		'Profit',
// 		'NoEmployees',
// 		'SniCode',
// 		'SniCodeRange',
// 		'OrgNoLookup',
// 		'SicCode',
// 		'NaicsCode',
// 		'UKSicCode',
// 		'JourneyStep',
// 		'RegDate',
// 		'Active'
// 	],
// 	CONTACT: [
// 		'Name',
// 		'Title',
// 		'Active',
// 		'Campaign',
// 		'Category',
// 		'Notes',
// 		'Phone',
// 		'CellPhone',
// 		'Unsubscribed',
// 		'UnsubscribedList',
// 		'MailBounced',
// 		'Score',
// 		'MailCampaignFilters',
// 		'VisitorFilters',
// 		'FormSubmitFilters',
// 		'AccountManagerFilters',
// 		'Id',
// 		'Segment',
// 		'Salutation',
// 		'JourneyStep',
// 		'RegDate',
// 		'EmailMissingAt'
// 	],
// 	ACTIVITY: [
// 		'Notes',
// 		'Description',
// 		'Status',
// 		'AdvancedDate',
// 		'CloseDate',
// 		'Date',
// 		'RegDate',
// 		'User',
// 		'ActivityType',
// 		'Priority',
// 		'Project',
// 		'Id',
// 		'Campaign',
// 		'CallList'
// 	],
// 	APPOINTMENT: [
// 		'Notes',
// 		'Description',
// 		'Status',
// 		'AdvancedDate',
// 		'User',
// 		'AppointmentType',
// 		'Campaign',
// 		'Id',
// 		'AppointmentOutcome'
// 	],
// 	OPPORTUNITY: [
// 		'Notes',
// 		'AdvancedDate',
// 		'ValueRange',
// 		'Stage',
// 		'Id',
// 		'Campaign',
// 		'OrderRowProduct',
// 		'OrderRowProductCategory',
// 		'IsOpportunity',
// 		'Age',
// 		'Status',
// 		'User',
// 		'Description'
// 	],
// 	ORDER: [
// 		'Notes',
// 		'AdvancedDate',
// 		'ValueRange',
// 		'Stage',
// 		'Id',
// 		'Campaign',
// 		'OrderRowProduct',
// 		'OrderRowProductCategory',
// 		'IsOpportunity',
// 		'AgreementId',
// 		'FromOpportunity',
// 		'User',
// 		'Description'
// 	],
// } as const;

// export const initFilterConfigs = (type: AdvancedSearchType) => {
// 	console.time('init filters')
// 	const filterName = filterNameMap[type];
// 	const newFilters: EntityFilter = TypedObject.keys(FILTER_NAMES).reduce((r, n) => ({ ...r, [n]: {} }), {} as EntityFilter);

// 	for (const filter of FILTER_NAMES[filterName]) {
// 		const nameLower = type.toLowerCase();

// 		if (!newFilters[filterName]) {
// 			newFilters[filterName] = {};
// 		}

// 		// This is whats taking so long i think?
// 		newFilters[filterName]![filter] = Tools.FilterHelper.filter(filter, nameLower);
// 	}

// 	console.timeEnd('init filters')
// 	return newFilters;
// };
