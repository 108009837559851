import LZString from 'lz-string';
import _ from 'lodash';
export const RESET = '[AdminGoals] RESET';
export const SET_TYPE = '[AdminGoals] SET_TYPE';
export const SET_FILTERS = '[AdminGoals] SET_FILTERS';
export const SET_TABLE_DATA = '[AdminGoals] SET_TABLE_DATA';
export const SET_INITIAL_DATA = '[AdminGoals] SET_INITIAL_DATA';
export const SET_DATA_IN_DB = '[AdminGoals] SET_DATA_IN_DB';
export const SET_LOADING = '[AdminGoals] SET_LOADING';
export const SET_SAVING = '[AdminGoals] SET_SAVING';
export const SET_PERIOD = '[AdminGoals] SET_PERIOD';
export const SET_FIRST_VISIT = '[AdminGoals] SET_FIRST_VISIT';
export const initialState = {
	goalType: '',
	users: [],
	dataInDB: false,
	initialTableData: [],
	tableData: [],
	initialHash: null,
	hash: null,
	initialPeriod: '',
	loading: true,
	saving: false,
	firstVisit: false,
	filters: {
		year: '',
		period: 'weekly',
		currency: null,
		users: [],
		showInactive: false,
		showCurrency: false
	}
};

const getJsonHash = json => {
	return LZString.compressToBase64(JSON.stringify(json));
};

const ACTION_HANDLERS = {
	[RESET]: () => ({ ...initialState }),
	[SET_TYPE]: (state, { goalType }) => ({ ...state, goalType }),
	[SET_FILTERS]: (state, { filters }) => ({ ...state, filters: { ...state.filters, ...filters } }),
	[SET_TABLE_DATA]: (state, { tableData }) => ({
		...state,
		tableData,
		hash: getJsonHash(_.cloneDeep(tableData))
	}),
	[SET_INITIAL_DATA]: (state, { tableData, initPeriod }) => ({
		...state,
		tableData,
		initialTableData: _.cloneDeep(tableData),
		initialPeriod: initPeriod,
		filters: { ...state.filters, period: initPeriod },
		hash: getJsonHash(tableData),
		initialHash: getJsonHash(tableData)
	}),
	[SET_PERIOD]: (state, { period }) => ({
		...state,
		filters: {
			...state.filters,
			period
		}
	}),
	[SET_FIRST_VISIT]: (state, { firstVisit }) => ({
		...state,
		firstVisit
	}),
	[SET_DATA_IN_DB]: (state, { dataInDB }) => ({ ...state, dataInDB }),
	[SET_LOADING]: (state, { loading }) => ({ ...state, loading }),
	[SET_SAVING]: (state, { saving }) => ({ ...state, saving })
};

export default (state = initialState, action) => {
	const handler = ACTION_HANDLERS[action.type];
	return handler ? handler(state, action) : state;
};
