import Resource from './Resource';
class UpsalesAI extends Resource {
	constructor() {
		super('upsalesAI');
	}

	clientFieldBy(field, country, opts = {}) {
		return this._getRequest(`${field}${country ? '/' + country : ''}`, {
			methodName: 'clientFieldBy',
			params: opts.params
		}).then(res => res.data);
	}
}

export default new UpsalesAI();
