import { setLanguage } from 'Components/Helpers/translate';

export default function setUILanguage(userLanguage) {
	let languagePromise;
	switch (userLanguage) {
		case 'sv-SE':
			languagePromise = setLanguage('sv-SE');
			Tools.localeService.setLocale('sv');
			break;
		case 'no-NO':
		case 'nb-NO':
			languagePromise = setLanguage('nb-NO');
			Tools.localeService.setLocale('en');
			break;
		case 'de-DE':
			languagePromise = setLanguage('de-DE');
			Tools.localeService.setLocale('en');
			break;
		case 'fi-FI':
			languagePromise = setLanguage('fi-FI');
			Tools.localeService.setLocale('en');
			break;
		case 'da-DK':
			languagePromise = setLanguage('da-DK');
			Tools.localeService.setLocale('en');
			break;
		case 'nl-NL':
			languagePromise = setLanguage('nl-NL');
			Tools.localeService.setLocale('en');
			break;
		case 'fr-FR':
			languagePromise = setLanguage('fr-FR');
			Tools.localeService.setLocale('en');
			break;
		case 'noLangTran':
			languagePromise = setLanguage('noLangTran');
			Tools.localeService.setLocale('en');
			break;
		default:
			languagePromise = setLanguage('en-US');
			Tools.localeService.setLocale('en');
			break;
	}

	return languagePromise;
}
