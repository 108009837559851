import React from 'react';
import { Block, Text, Row, Column, Headline } from '@upsales/components';
import './EditPhonecallClientContactLoading.scss';

const EditPhonecallClientContactLoading = () => {
	return (
		<Block
			className="EditPhonecallClientContactLoading"
			space="ptxl prxl pbxl plxl"
			border="rs"
			borderColor="grey-5"
		>
			<Headline size="xs" loading={true} />
			<Row>
				<Column>
					<Text size="sm" loading={true} />
				</Column>
				<Column>
					<Text size="sm" loading={true} />
				</Column>
			</Row>
			<Row>
				<Column>
					<Text size="sm" loading={true} />
				</Column>
				<Column>
					<Text size="sm" loading={true} />
				</Column>
			</Row>
			<Row>
				<Column>
					<Text size="sm" loading={true} />
				</Column>
				<Column />
			</Row>
		</Block>
	);
};

export default EditPhonecallClientContactLoading;
