import React from 'react';
import { Button, Icon, ModalControls } from '@upsales/components';
import Order from 'App/resources/Model/Order';
import T from 'Components/Helpers/translate';
import BemClass from '@upsales/components/Utils/bemClass';
import EditOrderSaveButton from '../EditOrderSaveButton';

type Props = {
	order: Partial<Order>;
	close: () => void;
	save: (isContinue: boolean) => void;
	del: () => void;
	clone: () => void;
	isValid: boolean;
};

const EditOrderControls = ({ order, close, save, del, clone, isValid }: Props) => {
	const classes = new BemClass('EditOrderControls');
	const lang = {
		abort: T('default.abort'),
		save: T('default.save'),
		saveAndContinue: T('default.saveAndContinue'),
		createCopy: T('default.createCopy')
	};
	return (
		<ModalControls className={classes.b()}>
			<div className={classes.elem('left').b()}>
				{order.id ? (
					<>
						<Button type="lined" onClick={() => del()}>
							<Icon name="trash" />
						</Button>
						<Button type="lined" onClick={() => clone()}>
							<Icon name="copy" /> {lang.createCopy}
						</Button>
					</>
				) : null}
			</div>
			<div className={classes.elem('right').b()}>
				<EditOrderSaveButton
					onSave={() => save(false)}
					onSaveAndContinue={() => save(true)}
					disabled={!isValid}
				></EditOrderSaveButton>
				<Button type="link" color="grey" onClick={() => close()}>
					{lang.abort}
				</Button>
			</div>
		</ModalControls>
	);
};

export default EditOrderControls;
