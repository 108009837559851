import DomainsResource from 'App/babel/resources/Domains';
import SystemMail from 'App/babel/resources/SystemMail';
import { AppThunk } from 'Store/index';
import { AnyAction } from 'redux';
import LandingPageDomain from 'App/resources/Model/LandingPageDomain';

import _ from 'lodash';
import logError from 'Helpers/logError';

export const SET_DOMAIN_LIST = '[Domains] Set domain list';
export const SET_DOMAIN = '[Domains] Set domain';
export const SET_SAVING_EVENT_DOMAIN = '[Domains] Set saving event domain';
export const SET_MISSING_MAIL_ACCOUNT = '[Domains] Set mail account missing error';
export const SET_MAIL_ACCOUNT = '[Domains] Set mail account';

export enum DOMAIN_TYPES {
	EMAIL = 'EMAIL',
	SOCIAL_EVENTS = 'SOCIAL_EVENTS',
	VERIFIED_SOCIAL_EVENTS = 'VERIFIED_SOCIAL_EVENTS'
}

type MailDomain = {
	id: number;
	dns?: {
		dkim1: {
			data: string;
			host: string;
			type: string;
			valid: boolean;
		};
		dkim2: {
			data: string;
			host: string;
			type: string;
			valid: boolean;
		};
		// eslint-disable-next-line camelcase
		mail_cname: {
			valid: boolean;
			type: string;
			host: string;
			data: string;
		};
	};
	data?: string;
	host?: string;
	type?: string;
	valid: boolean;
	domain: string;
	adding?: boolean;
	verified?: boolean;
};

type Domain = Optional<MailDomain, 'id'> | LandingPageDomain;

/*********** Actions **********/
const setDomains =
	(domains: Domain[], domainType: string): AppThunk =>
	dispatch => {
		dispatch({ type: SET_DOMAIN_LIST, domains, domainType });
		if (domainType === DOMAIN_TYPES.SOCIAL_EVENTS) {
			dispatch({
				type: SET_DOMAIN_LIST,
				domains: domains.filter(d => d.verified),
				domainType: DOMAIN_TYPES.VERIFIED_SOCIAL_EVENTS
			});
		}
	};

const insertDomain = (domain: Domain, index: number, domainType: string, replace: boolean) => {
	return { type: SET_DOMAIN, domain, index, domainType, replace };
};

const setMissingMailAccount = (missingMailAccount: boolean) => {
	return { type: SET_MISSING_MAIL_ACCOUNT, missingMailAccount };
};

const setSavingEventDomain = (value: boolean) => {
	return { type: SET_SAVING_EVENT_DOMAIN, value };
};

function insertDomainAt(domains: Domain[], domain: Domain, field: 'id' | 'domain', type: DOMAIN_TYPES): AppThunk {
	return dispatch => {
		const index = domains.findIndex(d => d[field as keyof Domain] === domain[field as keyof Domain]);
		dispatch(insertDomain(domain, index, type, index !== -1));
	};
}

const insertAtIndex = (list: Domain[] = [], index: number, item: Domain, replace: boolean) => [
	...list.slice(0, index),
	item,
	...list.slice(index + (replace ? 1 : 0))
];

/** Email domains **/
export const getEmailDomains = (): AppThunk => dispatch =>
	Tools.MailAccount.domains()
		.then((res: { data: MailDomain[] }) => {
			dispatch(setDomains(res.data, DOMAIN_TYPES.EMAIL));
			dispatch(setMissingMailAccount(false));
			return res.data;
		})
		.catch((err: { data?: { error?: { key: string } } }) => {
			dispatch(setMissingMailAccount(err?.data?.error?.key === 'MissingMailAccount'));
		});

export const addEmailDomain =
	(d: { name: string }): AppThunk =>
	(dispatch, getState) => {
		const domain: Omit<MailDomain, 'id' | 'type' | 'data' | 'host'> = {
			valid: false,
			adding: true,
			domain: d.name
		};

		return Tools.MailAccount.addDomain(domain).then(() => {
			const domains = getState().Domains[DOMAIN_TYPES.EMAIL];
			dispatch(insertDomainAt(domains, domain, 'domain', DOMAIN_TYPES.EMAIL));
			// Get domians to verify
			dispatch(getEmailDomains());
			return domain;
		});
	};

/** Social event domains **/
export const getSocialEventDomains = (): AppThunk => dispatch => {
	return DomainsResource.find().then(res => {
		dispatch(setDomains(res.data as LandingPageDomain[], DOMAIN_TYPES.SOCIAL_EVENTS));

		return res;
	});
};

export const addSocialEventDomain =
	(domain: Partial<LandingPageDomain> & Pick<LandingPageDomain, 'name'>): AppThunk<Promise<LandingPageDomain>> =>
	(dispatch, getState) => {
		dispatch(setSavingEventDomain(true));
		try {
			// Use this to naively parse out just the host if the user messes up their input
			// https://upsales.com -> upsales.com
			// https://upsales.com/subpage -> upsales.com
			domain.name = new URL(domain.name).host;
		} catch {
			// do nothing
		}

		return DomainsResource.save(domain).then(res => {
			const domains = getState().Domains[DOMAIN_TYPES.SOCIAL_EVENTS];
			dispatch(insertDomainAt(domains, res.data, 'id', DOMAIN_TYPES.SOCIAL_EVENTS));
			dispatch(setSavingEventDomain(false));
			return res.data;
		});
	};

export const removeDomain =
	(
		domain: LandingPageDomain | MailDomain,
		type: DOMAIN_TYPES.SOCIAL_EVENTS | DOMAIN_TYPES.VERIFIED_SOCIAL_EVENTS | DOMAIN_TYPES.EMAIL
	): AppThunk =>
	(dispatch, getState) => {
		let promise;
		if (type === DOMAIN_TYPES.EMAIL) {
			promise = Tools.MailAccount.deleteDomain(domain.id);
		} else {
			promise = DomainsResource.delete(domain.id);
		}
		return promise.then(() => {
			const domains = getState().Domains[type];
			const i = domains.findIndex(d => d.id === domain.id);
			if (i !== -1) {
				domains.splice(i, 1);
			}
			dispatch(setDomains(domains, type));
		});
	};

export const getMailAccount = (): AppThunk => dispatch => {
	return Tools.MailAccount.get()
		.then(({ data }) => {
			if (data) {
				dispatch({ type: SET_MAIL_ACCOUNT, mailAccount: data });
			} else {
				dispatch(setMissingMailAccount(true));
			}
		})
		.catch(err => {
			logError(err, 'Failed to get mailAccount');
		});
};

export const verifyEventDomain =
	(domain: LandingPageDomain): AppThunk =>
	(dispatch, getState) => {
		return DomainsResource.verify(domain.id).then(res => {
			const domains = getState().Domains[DOMAIN_TYPES.SOCIAL_EVENTS];
			const i = _.findIndex(domains, { id: domain.id });
			if (i !== -1) {
				domains[i] = { ...domain, verified: res && res.data ? res.data.valid : false };
				dispatch(setDomains(domains, DOMAIN_TYPES.SOCIAL_EVENTS));
			}
		});
	};

export const sendSystemMail = (email: string[], domain: string) => () => {
	return SystemMail.send('verifyDomains', email, { domain });
};

const ACTION_HANDLERS: { [key: string]: (s: DomainState, a: AnyAction) => DomainState } = {
	[SET_DOMAIN_LIST]: (state, action) => ({ ...state, [action.domainType]: [...action.domains] }),
	[SET_DOMAIN]: (state, action) => {
		return {
			...state,
			[action.domainType]: insertAtIndex(
				state[action.domainType as DOMAIN_TYPES],
				action.index,
				action.domain,
				action.replace
			)
		};
	},
	[SET_MISSING_MAIL_ACCOUNT]: (state, { missingMailAccount }) => {
		return { ...state, missingMailAccount };
	},
	[SET_MAIL_ACCOUNT]: (state, { mailAccount }) => {
		return { ...state, mailAccount };
	}
};

type DomainState = {
	savingEventDomain: boolean;
	missingMailAccount: boolean;
	mailAccount: null | { useHalon: boolean };
	[DOMAIN_TYPES.EMAIL]: MailDomain[];
	[DOMAIN_TYPES.SOCIAL_EVENTS]: LandingPageDomain[];
	[DOMAIN_TYPES.VERIFIED_SOCIAL_EVENTS]: LandingPageDomain[];
};

export const initialState: DomainState = {
	savingEventDomain: false,
	missingMailAccount: false,
	mailAccount: null,
	[DOMAIN_TYPES.EMAIL]: [],
	[DOMAIN_TYPES.SOCIAL_EVENTS]: [],
	[DOMAIN_TYPES.VERIFIED_SOCIAL_EVENTS]: []
};
export default (state = initialState, action: AnyAction) => {
	const handler = ACTION_HANDLERS[action.type];
	return handler ? handler(state, action) : state;
};
