import { Loader, ModalContent, ModalSidebar, WhiteModal } from '@upsales/components';
import BemClass from '@upsales/components/Utils/bemClass';
import OrderModel from 'App/resources/Model/Order';
import React, { useEffect, useRef, useState } from 'react';
import EditOrderHeader from './EditOrderHeader';
import './EditOrder.scss';
import EditOrderControls from './EditOrderControls';
import { ModalProps } from '../Modals/Modals';
import { cloneOrder, deleteOrder, getOrder, isOrderValid, saveOrder } from './OrderHelpers';
import { CancelablePromise, makeCancelable } from 'Helpers/promise';

type Props = {
	id: number;
	initView: string;
	type: 'order' | 'opportunity';
} & ModalProps;

const EditOrder = (props: Props) => {
	const classes = new BemClass('EditClient', props.className);
	const [order, setOrder] = useState<Partial<OrderModel> | undefined>();
	const promises = useRef<CancelablePromise<any>[]>([]);

	useEffect(() => {
		const orderPromise = makeCancelable(getOrder(props.id));
		orderPromise.promise.then(data => setOrder(data)).catch();
		promises.current.push(orderPromise);
		return () => {
			promises.current.forEach(promise => promise.cancel());
		};
	}, []);

	return !order ? (
		<Loader />
	) : (
		<WhiteModal className={classes.b()} size="xl" withSidebar headerAtTop>
			<EditOrderHeader order={order} close={() => close()} type={props.type}></EditOrderHeader>
			<ModalContent></ModalContent>
			<ModalSidebar></ModalSidebar>
			<EditOrderControls
				order={order}
				close={() => close()}
				save={async isContinue => {
					await saveOrder(order);
					if (!isContinue) {
						close();
					}
				}}
				del={() => deleteOrder(order)}
				clone={() => {
					const newOrder = cloneOrder(order);
					setOrder(newOrder);
				}}
				isValid={isOrderValid(order)}
			></EditOrderControls>
		</WhiteModal>
	);
};

export default EditOrder;
