import React, { useState } from 'react';
import { Row, Column, Icon, Text, Checkbox, Block } from '@upsales/components';
import './ReportcenterFilterHeader.scss';
import BemClass from '@upsales/components/Utils/bemClass';

export type Props = Partial<typeof Row> & {
	key: number | string;
	title: string;
	selected: boolean;
	bold?: boolean;
	onClick: () => void;
	renderChildren: () => void;
	noCheckbox?: boolean;
	inactive?: boolean;
	space?: string;
};

const ReportcenterFilterHeader = ({
	title,
	selected,
	bold = false,
	noCheckbox,
	inactive,
	space = 'pll ptm pbm',
	renderChildren,
	...props
}: Props) => {
	const [showChildren, setShowChildren] = useState<boolean>(true);
	const classes = new BemClass('ReportcenterFilterHeader');
	return (
		<Block>
			<Row className={classes.b()} {...props}>
				<Block border="ts" space={space} backgroundColor="grey-2" borderColor="grey-3">
					<Column>
						{noCheckbox ? null : <Checkbox checked={selected} space="mrl" size="sm" />}
						<Text bold={bold} italic={inactive} color={inactive ? 'grey-9' : undefined}>
							{title}
						</Text>
						<Icon
							className={classes.elem('chevron').mod({ open: showChildren }).b()}
							space="mrm"
							name="chevron-down"
							onClick={e => {
								e.stopPropagation();
								setShowChildren(!showChildren);
							}}
						/>
					</Column>
				</Block>
			</Row>
			{showChildren ? renderChildren() : null}
		</Block>
	);
};

export default ReportcenterFilterHeader;
