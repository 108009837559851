import React from 'react';
import { Block } from '@upsales/components';
import bemClass from '@upsales/components/Utils/bemClass';
import './BarchartWidgetBar.scss';
import { Pulse } from 'App/components/animations';

type Props = {
	small?: boolean;
	width?: string;
	left?: string;
	future?: boolean; // TODO: This should be named something like 'goal'
	loading?: boolean;
	backgroundColor?: string;
	negative?: boolean;
	onClick?: React.MouseEventHandler<HTMLDivElement>;
	roundedLeft?: boolean;
	roundedRight?: boolean;
};

const BarchartWidgetBar = ({
	small = false,
	width = '0',
	left = '0',
	future = false,
	backgroundColor,
	loading = false,
	negative = false,
	onClick,
	roundedLeft = false,
	roundedRight = false
}: Props) => {
	const classes = new bemClass('BarchartWidgetBar');

	return (
		<Pulse disabled={!loading}>
			<Block
				className={classes.mod({ future, small, loading, negative, roundedLeft, roundedRight }).b()}
				style={{ backgroundColor: backgroundColor, left, width }}
				onClick={onClick}
			/>
		</Pulse>
	);
};

export default BarchartWidgetBar;
