import React, { useEffect, useMemo, useState } from 'react';
import ReportcenterFilter from './ReportcenterFilter';
import { Block, ButtonSelect } from '@upsales/components';
import bemClass from '@upsales/components/Utils/bemClass';
import T from 'Components/Helpers/translate';
import './ReportcenterFilterAddress.scss';
import { RCDashboardFilter } from 'Resources/ReportDashboard';
import ReportcenterFilterSearchbar from './ReportcenterFilterSearchbar';
import ReportcenterFilterRow from './ReportcenterFilterRow';
import { makeCancelable } from '@upsales/components/Utils/CancelablePromise';
import logError from 'Helpers/logError';
import ReportcenterFilterHeader from './ReportcenterFilterHeader';
import multipleSelectionHelper from 'App/helpers/multipleSelectionHelper';
import { capitalize } from 'lodash';

interface Props {
	onChange: (type: keyof RCDashboardFilter, values: string[], comparison: string) => void;
	values: RCDashboardFilter;
	updateFilterHeight: () => void;
}

type AddressItem = {
	id: string;
	title: string;
};

export const renderSelected = (values: RCDashboardFilter, filterType?: 'country' | 'state' | 'city') => {
	let res = filterType ? `${T('address.address')} ${T(`address.${filterType}`)}: ` : `${T('address.address')}: `;

	if (filterType) {
		const addressMap: { [key: string]: string[] } = {};
		values?.[
			`Client${capitalize(filterType)}` as
				| 'ClientAddressesCountry'
				| 'ClientAddressesState'
				| 'ClientAddressesCity'
		]?.value?.forEach(addressValue => {
			let [address, type] = addressValue.split('_');
			if (filterType === 'country') {
				address = T(Tools.CountryCodes.getCountry(address).lang);
			}
			if (!(address in addressMap)) {
				addressMap[address] = [];
			}
			addressMap[address].push(type);
		});

		const addresses: string[] = [];
		Object.keys(addressMap).forEach(address => {
			if (addressMap[address][0] === 'All') {
				addresses.push(address);
			} else {
				addresses.push(
					`${address} (${addressMap[address].map(type => T(`address.${type.toLowerCase()}`)).join(', ')})`
				);
			}
		});

		return res + addresses.join(', ');
	}

	const countryLength = values?.ClientAddressesCountry?.value?.length || 0;
	const countyLength = values?.ClientAddressesState?.value.length || 0;
	const cityLength = values?.ClientAddressesCity?.value.length || 0;

	if (countryLength || countyLength || cityLength) {
		const length = countryLength + countyLength + cityLength;
		if (length >= 1) {
			res += `${length} ${T('default.selected').toLowerCase()}`;
		}
	}
	return res;
};

const lookupAddressField = (field: string) => {
	return makeCancelable(
		Tools.Lookup.customer(Tools.AppService.getCustomerId())
			.setType('account')
			.findEnd(field, '', 200, [{ _count: 'desc' }, { _term: 'asc' }])
	);
};

const parseLookupResults = (result: { value: string; count: number }[]) => {
	return result.reduce<AddressItem[]>((res, c) => {
		if (c.value) {
			res.push({ id: c.value, title: c.value[0].toUpperCase() + c.value.slice(1) });
		}
		return res;
	}, []);
};

export const getAddressButtons = (
	countriesAmount: number,
	countiesAmount: number,
	citiesAmount: number,
	upperCase: boolean
) => {
	const countryTitle = `${countriesAmount || ''} ${T(countriesAmount < 2 ? 'address.country' : 'address.countries')}`;
	const countyTitle = `${countiesAmount || ''} ${T(countiesAmount < 2 ? 'address.county' : 'address.counties')}`;
	const cityTitle = `${citiesAmount || ''} ${T(citiesAmount < 2 ? 'address.city' : 'address.cities')}`;
	const firstLetter = upperCase ? 'C' : 'c';
	return [
		{
			value: firstLetter + 'lientAddressesCountry',
			title: countryTitle
		},
		{
			value: firstLetter + 'lientAddressesState',
			title: countyTitle
		},
		{
			value: firstLetter + 'lientAddressesCity',
			title: cityTitle
		}
	];
};

const ReportcenterFilterAddress = ({ onChange, ...props }: Props) => {
	const classes = new bemClass('ReportcenterFilterAddress');

	const [searchStr, setSearchStr] = useState('');
	const [selectedList, setSelectedList] = useState<
		'ClientAddressesCountry' | 'ClientAddressesState' | 'ClientAddressesCity'
	>('ClientAddressesCountry');
	const [counties, setCounties] = useState<AddressItem[]>([]);
	const [cities, setCities] = useState<AddressItem[]>([]);

	const lang = {
		ClientAddressesCountry: T('address.countries'),
		ClientAddressesState: T('address.counties'),
		ClientAddressesCity: T('address.cities')
	};

	const addressTypes = [
		{
			value: 'Visit',
			title: T('address.visit')
		},
		{
			value: 'Mail',
			title: T('address.mail')
		},
		{
			value: 'Delivery',
			title: T('address.delivery')
		},
		{
			value: 'Invoice',
			title: T('address.invoice')
		},
		{
			value: 'Other',
			title: T('address.other')
		}
	];

	const countries = useMemo(() => Tools.CountryCodes.countries().map(c => ({ id: c.code, title: T(c.lang) })), []);
	const allOptions = {
		ClientAddressesCountry: countries,
		ClientAddressesState: counties,
		ClientAddressesCity: cities
	};
	const values = props.values?.[selectedList]?.value || [];
	const comparison = props.values?.[selectedList]?.comparison ?? 'eq';
	const isExclude = comparison === 'ne';

	const onUpdate = (updatedOptions: any) => {
		onChange(selectedList, updatedOptions, 'eq');
	};

	const [selectedOptions, onSelect] = multipleSelectionHelper(
		values,
		addressTypes.concat([{ value: 'All', title: '' }]).map(addressType => addressType.value),
		onUpdate
	);

	const selectedAndTitleSort = (a: AddressItem, b: AddressItem) => {
		if ((selectedOptions[a.id] && selectedOptions[b.id]) || (!selectedOptions[a.id] && !selectedOptions[b.id])) {
			return a.title > b.title ? 1 : -1;
		} else {
			return selectedOptions[a.id] ? -1 : 1;
		}
	};

	const array = allOptions[selectedList]
		.filter(item => item.title.toLowerCase().indexOf(searchStr.toLowerCase()) !== -1)
		.sort(selectedAndTitleSort);

	useEffect(() => {
		const countiesPromise = lookupAddressField('address.state');
		const citiesPromise = lookupAddressField('address.city');

		countiesPromise.promise
			.then(res => setCounties(res.data ? parseLookupResults(res.data) : []))
			.catch(err => logError(err, 'Failed to get counties'));

		citiesPromise.promise
			.then(res => setCities(res.data ? parseLookupResults(res.data) : []))
			.catch(err => logError(err, 'Failed to get cities'));

		return () => {
			countiesPromise.cancel();
			citiesPromise.cancel();
		};
	}, []);

	const selectedCountries = Object.keys(props.values?.['ClientAddressesCountry']?.value || []);
	const selectedCounties = Object.keys(props.values?.['ClientAddressesState']?.value || []);
	const selectedCities = Object.keys(props.values?.['ClientAddressesCity']?.value || []);

	const buttonSelectOptionOrder = getAddressButtons(
		selectedCountries.length,
		selectedCounties.length,
		selectedCities.length,
		true
	);

	const renderChildren = (id: string) =>
		addressTypes.map(addressOption => {
			const selectedItem =
				selectedOptions[id] && (selectedOptions[id].All || selectedOptions[id][addressOption.value]);
			return (
				<ReportcenterFilterRow
					key={`${id}_${addressOption.value}`}
					onClick={() => onSelect({ id: `${id}_${addressOption.value}`, title: '' })}
					selected={selectedItem}
					title={T(addressOption.title)}
					space="plxl ptm pbm"
				/>
			);
		});

	return (
		<ReportcenterFilter
			className={classes.b()}
			renderSelected={() => renderSelected(props.values)}
			icon="home"
			placeholder={`${T('address.address')}: ${T('reportcenter.filter.notActive')}`}
			value={[...selectedCountries, ...selectedCounties, ...selectedCities]}
			resetFilter={() => {
				onChange('ClientAddressesCountry', [], 'eq');
				onChange('ClientAddressesState', [], 'eq');
				onChange('ClientAddressesCity', [], 'eq');
			}}
			{...props}
		>
			<Block backgroundColor="white" className={classes.elem('list-select').b()}>
				<Block space="ptm prm plm">
					<ButtonSelect
						options={buttonSelectOptionOrder}
						value={selectedList}
						onChange={tab => {
							setSearchStr('');
							setSelectedList(tab);
						}}
					/>
				</Block>
				<Block space="mtm prm plm pbm">
					<ReportcenterFilterSearchbar<string[]>
						setSearchStr={value => setSearchStr(value)}
						searchStr={searchStr}
						placeholder={`${T('default.search')} ${lang[selectedList].toLowerCase()}`}
						isExclude={isExclude}
						onChange={onChange}
						field={selectedList}
						value={values}
					/>
				</Block>
				<Block>
					{array.map(option => (
						<ReportcenterFilterHeader
							key={`${option.id}_All`}
							onClick={() => onSelect({ id: `${option.id}_All`, title: '' })}
							selected={selectedOptions[option.id] && selectedOptions[option.id].All}
							renderChildren={() => renderChildren(option.id)}
							title={option.title}
						/>
					))}
				</Block>
				<Block className={classes.elem('bottom').b()} />
			</Block>
		</ReportcenterFilter>
	);
};

export default ReportcenterFilterAddress;
