import openModal from 'App/services/Modal';
import RequestBuilder from 'Resources/RequestBuilder';
import Label from 'App/resources/Model/Label';
import updateLabels from '../labelHelper';

export default function addLabel(rb: RequestBuilder, entity: string, selected: number) {
	return new Promise<void>(resolve => {
		openModal('AddLabelsMulti', {
			entityType: entity,
			onSave: (selectedLabel: Label) => {
				resolve();
				return updateLabels(entity, selectedLabel, rb.build(), selected);
			},
			onClose: () => resolve()
		});
	});
}
