import RequestBuilder from 'Resources/RequestBuilder';
import { openDrawer } from 'Services/Drawer';

export default function planPhonecalls(
	rb: RequestBuilder,
	entity: string,
	numSelected: number,
	selected: number[],
	extraParams?: object
) {
	const opts = {
		filters: rb,
		entity,
		numSelected,
		extraParams,
		selected
	};

	return openDrawer('PlanPhonecallsMulti', opts);
}
