import ListView, { ListViewPropsExternal } from 'App/components/ListView';
import { ListViewDefaultColumn } from 'App/components/ListView/ListViewRenderHelpers';
import { currencyFormat } from 'Components/Filters/Currencies';
import openModal from 'App/services/Modal';
import { useMetadata } from 'App/components/hooks/appHooks';
import { usesMRR } from 'App/helpers/salesModelHelpers';
import BemClass from '@upsales/components/Utils/bemClass';
import SubscriptionIndexingState, { State } from './../SubscriptionIndexingState/SubscriptionIndexingState';
import ArrChangelogRawResource from 'Resources/ArrChangelogRaw';
import ArrChangelogRaw from 'App/resources/Model/ArrChangelogRaw';
import React from 'react';
import RequestBuilder from 'Resources/RequestBuilder';
import ComparisonTypes from 'Resources/ComparisonTypes';
import { Column, EllipsisTooltip, Link, TableColumn, TableRow, Text } from '@upsales/components';
import Attribute, { Type } from 'App/babel/attributes/Attribute';
import { Action } from '../SubscriptionIndexingState/SubscriptionIndexingActions';

const ArrChangelogRawAttributes = {
	info: Attribute({
		field: 'agreement.description',
		type: Type.String,
		title: 'agreement',
		sortable: 'agreement.description'
	}),
	increasedValue: Attribute({
		field: 'increasedYearlyValue',
		type: Type.Number,
		title: 'subscription.indexing.preview.increaseColumn.label.ARR',
		sortable: 'arrChange'
	}),
	increasedMonthlyValue: Attribute({
		field: 'increasedMonthlyValue',
		type: Type.Number,
		title: 'subscription.indexing.preview.increaseColumn.label.MRR',
		sortable: 'arrChange'
	}),
	lastIndexIncreaseDate: Attribute({
		title: 'default.lastIndexIncreaseDate',
		field: 'agreement.latestIndexIncreaseDate',
		displayKey: 'agreement.latestIndexIncreaseDate',
		type: Type.String,
		placeholder: 'default.unknown',
		sortable: 'agreement.latestIndexIncreaseDate'
	})
};

type ListViewProps = ListViewPropsExternal<ArrChangelogRaw>;
const navigateToAccount = (subscriptionId: number) => {
	openModal('EditSubscription', {
		agreementId: subscriptionId
	});
};

const getRowRenderFn =
	(currency: string): ListViewProps['renderTableRow'] =>
	(change: ArrChangelogRaw, { columns, attributes }) => {
		const { agreement, activeAgreement, client, arrChange } = change;

		const columnElements = columns.map(column => {
			let content: null | JSX.Element = null;
			switch (column) {
				case 'info': {
					content = (
						<div>
							<EllipsisTooltip title={agreement.description}>
								<Text>
									<Link onClick={() => navigateToAccount(activeAgreement.id)}>
										{agreement.description}
									</Link>
								</Text>
							</EllipsisTooltip>
							<Text color="grey-10" size="sm">
								{'ID: ' + activeAgreement.id}
							</Text>
							<EllipsisTooltip title={client.name}>
								<Text color="grey-10" size="sm">
									{client.name}
								</Text>
							</EllipsisTooltip>
						</div>
					);
					break;
				}
				case 'increasedValue':
				case 'increasedMonthlyValue': {
					const hasMRR = column === 'increasedMonthlyValue';
					const totalARR = (agreement.orderValue * 12) / agreement.agreementIntervalPeriod;
					const totalValue = hasMRR ? totalARR / 12 : totalARR;
					const diff = hasMRR ? arrChange / 12 : arrChange;

					content = (
						<Column>
							<Text>{currencyFormat(totalValue, currency)}</Text>
							<Text space="pts" color="grey-11" size="sm">{`+${currencyFormat(diff, currency)}`}</Text>
						</Column>
					);
					break;
				}

				case 'lastIndexIncreaseDate': {
					content = (
						<Column>
							<Text>{agreement.latestIndexIncreaseDate}</Text>
						</Column>
					);
					break;
				}

				default: {
					return (
						<ListViewDefaultColumn<ArrChangelogRaw>
							key={column}
							item={change}
							attributes={attributes}
							column={column}
							colSize="md"
						/>
					);
				}
			}
			return (
				<TableColumn size="md" key={column}>
					{content}
				</TableColumn>
			);
		});

		return (
			<React.Fragment key={change.id}>
				<TableRow key={change.id}>{columnElements}</TableRow>
			</React.Fragment>
		);
	};

type Props = {
	state: State;
	dispatch: React.Dispatch<Action>;
};

const ArrChangelogRawList = ({ state, dispatch }: Props) => {
	const { id } = state;
	const classes = new BemClass('SubscriptionList');

	const metadata = useMetadata();
	const hasMRR = usesMRR(metadata);
	const currency = metadata?.defaultCurrency?.iso || 'SEK';

	const { setSubscriptionMeta } = SubscriptionIndexingState(dispatch);

	const getData = async (rb: RequestBuilder) => {
		rb.fields = ['client', 'agreement', 'arrChange', 'indexIncrease', 'activeAgreement'];

		rb.addFilter({ field: 'type' }, ComparisonTypes.Equals, 'INDEX_INCREASE');
		rb.addFilter({ field: 'agreement.indexIncrease.id' }, ComparisonTypes.Equals, id);

		ArrChangelogRawResource.find({ ...rb.build(), limit: 4000 })
			.then(({ data, metadata }) => {
				const totalIncreaseARR = data.reduce((acc: number, item: any) => {
					return acc + item.arrChange;
				}, 0);

				setSubscriptionMeta(totalIncreaseARR, metadata.total);
			})
			.catch(() => {
				setSubscriptionMeta(0, 0);
			});

		return ArrChangelogRawResource.find(rb.build());
	};

	const columns = ['info'];
	columns.push(hasMRR ? 'increasedMonthlyValue' : 'increasedValue');

	return (
		<div className={classes.b()}>
			<div className={classes.b()}>
				<ListView<ArrChangelogRaw>
					noStickyTableHeader
					listType="agreement"
					renderTableRow={getRowRenderFn(currency)}
					className={classes.mod({ content: true }).b()}
					attributes={ArrChangelogRawAttributes}
					quickSearchFilter="ListSearch"
					renderHeader={() => <></>}
					renderToolsColumn={false}
					tableLimitOptions={[100]}
					getData={getData}
					columns={columns}
					isFullscreen={true}
					key={id}
				/>
			</div>
		</div>
	);
};

export default ArrChangelogRawList;
