import React from 'react';
import { Text, Link } from '@upsales/components';
import moment from 'moment';
import TimelineRow from '../TimelineRow';
import TimelineHistoryUsers from '../TimelineHistoryUsers';
import T from 'Components/Helpers/translate';
import { ManualEvent } from 'App/babel/propTypes/EventShape';

const TimelineManual = ({ event }) => {
	const { client, contacts, date, score, value } = event || {};
	let users = event && event.users ? event.users : [];

	if (users && !users[0] && contacts && contacts.length) {
		users = [contacts[0]];
	}

	const title = (
		<Text>
			{!(contacts && contacts[0]) ? (
				<Text>
					{!(users && users.length) ? `${T('default.someone')} ` : null}
					{T('event.form.adjustedTheScoreNoContact').toLowerCase()}
					{value === 'addedToRejectlist' ? ` ${T('event.form.addedToRejectlist')}` : null}
				</Text>
			) : (
				<Text>
					{users && users.length && users[0] === contacts[0] ? `${T('default.someone')} ` : null}
					{`${T('event.form.adjustedTheScore')} `}
					<Link
						onClick={e => e.stopPropagation()}
						href={Tools.$state.href('contact.dashboard', {
							customerId: client.id,
							id: contacts[0].id
						})}
					>
						{contacts[0].name}
					</Link>
				</Text>
			)}
			<b>{score ? ` (${score}p)` : null}</b>
		</Text>
	);

	const subTitle = date ? (
		<Text size="sm" color="grey-11">
			{moment(date).format('L LT')}
		</Text>
	) : null;

	return contacts && contacts.length ? (
		users && users.length && contacts[0] !== users[0] ? (
			<TimelineHistoryUsers icon="thumbs-up" event={event} title={title} subTitle={subTitle} />
		) : (
			<TimelineRow icon="thumbs-up" event={event} title={title} subTitle={subTitle} />
		)
	) : users && users.length ? (
		<TimelineHistoryUsers icon="thumbs-up" event={event} title={title} subTitle={subTitle} />
	) : (
		<TimelineRow icon="thumbs-up" event={event} title={title} subTitle={subTitle} />
	);
};

TimelineManual.propTypes = {
	event: ManualEvent.isRequired
};

export default TimelineManual;
