import getAngularModule from 'App/babel/angularHelpers/getAngularModule';
import AccountProfile from 'App/resources/Model/AccountProfile';
import MailCampaign from 'App/resources/Model/MailCampaign';
import MailTemplate from 'App/resources/Model/MailTemplate';
import logError from 'Helpers/logError';
import openModal from 'App/services/Modal';

export type PreviewData = { accountProfile: AccountProfile; style: string } | null;

export const generateHTML = async (bodyJson: string, style: string, accountProfile: AccountProfile) => {
	const InkyParser = getAngularModule('InkyParser');
	const { data } = await InkyParser.parse(bodyJson, {
		extraCss: style,
		profile: accountProfile
	});
	return data;
};

export const getPreviewData = async (): Promise<PreviewData> => {
	const AccountProfile = getAngularModule('AccountProfile');
	const InkyParser = getAngularModule('InkyParser');

	try {
		const [{ data: accountProfile }, { data: style }] = await Promise.all([
			AccountProfile.get(),
			InkyParser.getCss(false)
		]);

		if (!accountProfile) {
			return null;
		}
		return { accountProfile, style: style || '' };
	} catch (e) {
		logError(e, 'Failed to get preview data');
		return null;
	}
};

export const getStyleDataAndGenerateHTML = async (bodyJson: string) => {
	try {
		const previewData = await getPreviewData();
		if (previewData === null) {
			return '';
		}
		const { style, accountProfile } = previewData;
		const html = generateHTML(bodyJson, style, accountProfile);
		return html;
	} catch (e) {
		logError(e, 'Failed to get preview data');
		return '';
	}
};

export async function previewTemplate(
	event: React.MouseEvent,
	template: MailTemplate | MailCampaign,
	accountProfile: AccountProfile,
	style: string
) {
	event?.stopPropagation();

	let html: string = '';
	if (template.bodyJson) {
		try {
			html = await generateHTML(template.bodyJson, style, accountProfile);
		} catch (e) {
			logError(e, 'Failed to parse template bodyJson');
		}
	} else {
		html = template.body;
	}

	openModal('MailPreview', {
		name: template.name,
		html,
		isPlainText: !template.bodyJson
	});
}
