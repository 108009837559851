import { FeatureMap, FeatureName, productMap, ProductName } from 'Store/actions/FeatureHelperActions';
export { Feature, Product } from 'Store/actions/FeatureHelperActions';
import useSelector from '../useSelector/useSelector';
import { RootState } from 'Store/index';

/*
	Logic functions used in hooks to determine if a feature is available
*/
export const getSoftDeployAccessFromState = (accountSelf: RootState['App']['accountSelf'], feature: string) => {
	// This check is here because it was always there and we still have untyped code
	if (!accountSelf) {
		return false;
	}

	// Special haxx for billing addons
	if (feature === 'BILLING_ADDONS' && accountSelf.version !== 'Starter') {
		return false;
	}

	return accountSelf.unreleasedFeatures?.[feature] || false;
};

export const getFeatureAvailableFromState = (
	accountSelf: RootState['App']['accountSelf'],
	feature: FeatureName,
	searchAccount?: boolean
) => {
	const featureObj = FeatureMap[feature];

	if (featureObj === undefined) {
		return false;
	}

	// Not supported yet
	// if (featureObj.fn && typeof featureObj.fn === 'function') {
	// 	return dispatch(featureObj.fn());
	// }
	const key = featureObj.key;

	if (accountSelf?.features[key]) {
		return true;
	} else if (searchAccount) {
		const hasAccountFeature = accountSelf?.versionData.features.find(accountFeature => accountFeature === key);
		return !!hasAccountFeature;
	} else if (featureObj.betaKey && accountSelf?.unreleasedFeatures[featureObj.betaKey]) {
		return true;
	}

	return false;
};

export const getProductAvailableFromState = (accountSelf: RootState['App']['accountSelf'], product: ProductName) => {
	const productObj = productMap[product];

	// The API only returns the features you have, so undefined = false
	if (!accountSelf || productObj === undefined) {
		return false;
	}

	const key = productObj.key;

	if (typeof key === 'string') {
		return accountSelf?.products?.[key] ? true : false;
	}
	if (typeof key === 'function') {
		return key(accountSelf);
	}
	return false;
};

/*
	Hooks
*/
export const useFeatureAvailable = (feature: FeatureName, searchAccount?: boolean) => {
	const accountSelf = useSelector(({ App }) => App.accountSelf);
	return getFeatureAvailableFromState(accountSelf, feature, searchAccount);
};

export const useSoftDeployAccess = (feature: string) => {
	const accountSelf = useSelector(({ App }) => App.accountSelf);
	return getSoftDeployAccessFromState(accountSelf, feature);
};

export const useProductAvailable = (product: ProductName) => {
	const accountSelf = useSelector(({ App }) => App.accountSelf);
	return getProductAvailableFromState(accountSelf, product);
};
