import RequestBuilder, { comparisonTypes } from 'Resources/RequestBuilder';
import Contact from './Model/Contact';
import ResourceTyped from 'Resources/ResourceTyped';

class ContactResource extends ResourceTyped<Contact> {
	eventName: string = 'contact';
	constructor() {
		super('contacts');
	}

	new(): Partial<Contact> & Pick<Contact, 'journeyStep' | 'categories' | 'custom'> {
		return {
			journeyStep: 'lead',
			categories: [],
			custom: []
		};
	}

	findByRelevance(opts: { limit: number; offset: number; accountId: number; subAccountIds?: number[] }) {
		return this.get('', { _url: 'relevance/contacts', params: opts });
	}

	search(
		searchString: string,
		clientIds?: number | null | number[],
		limit?: number,
		requestBuilder = new RequestBuilder()
	) {
		const LIMIT = limit ?? 10;

		requestBuilder.addFilter({ field: 'active' }, comparisonTypes.Equals, true);

		if (searchString.length > 0) {
			const orFilter = requestBuilder.orBuilder();
			orFilter.next();
			orFilter.addFilter({ field: 'name' }, comparisonTypes.Search, searchString);
			orFilter.next();
			orFilter.addFilter({ field: 'email' }, comparisonTypes.Search, searchString);
			orFilter.next();
			orFilter.addFilter({ field: 'client.name' }, comparisonTypes.Search, searchString);
			orFilter.next();
			orFilter.addFilter({ field: 'phone' }, comparisonTypes.Search, searchString.replace(/\s/g, ''));
			orFilter.next();
			orFilter.addFilter({ field: 'cellPhone' }, comparisonTypes.Search, searchString.replace(/\s/g, ''));

			orFilter.done();
		}

		if (clientIds) {
			const orFilter = requestBuilder.orBuilder();
			orFilter.next();
			orFilter.addFilter({ field: 'client.id' }, comparisonTypes.Equals, clientIds);
			orFilter.next();
			orFilter.addFilter({ field: 'connectedClients.relatedToClientId' }, comparisonTypes.Equals, clientIds);
			orFilter.done();
		}

		requestBuilder.addSort('name', true);
		requestBuilder.limit = LIMIT;

		return this.find(requestBuilder.build());
	}
}

const resource = new ContactResource();

export default resource;
