import { useSubscriptionGroupContext } from '../Context/SubscriptionGroupContext';
import { Tabs, Tab, Help, Link } from '@upsales/components';
import useSelector from 'App/components/hooks/useSelector';
import EditorHeader from 'Components/EditorHeader';
import CommonHeaderProps from './CommonHeader';
import T from 'Components/Helpers/translate';
import openModal from 'App/services/Modal';
import { STEP, FINAL_STEP } from '../Step';
import React, { useMemo } from 'react';

const EditHeader = ({
	hasDiffOrdersToCreate,
	disableDetailsSave,
	disableSummarySave,
	changeProxy,
	resetScroll,
	getTabName,
	className,
	subtitle,
	labMode,
	tooltip,
	onAbort,
	save
}: CommonHeaderProps) => {
	const {
		state: {
			step: currentStep,
			subscriptionMap,
			lockedReason,
			diffOrders,
			finalStep,
			orderToAdd,
			isDirty,
			client,
			locked,
			saving
		},
		setFinalStep
	} = useSubscriptionGroupContext();
	const customerId = useSelector(state => state.App.customerId);

	const steps = [STEP.DETAILS, STEP.SUMMARY, STEP.HISTORY];
	const hasEditPreviewTab = Tools.FeatureHelper.hasSoftDeployAccess('SUBSCRIPTION_EDIT_PREVIEW');

	const goToNextTab = () => {
		resetScroll();

		if (!finalStep && hasDiffOrdersToCreate) {
			return setFinalStep(FINAL_STEP.DIFFORDER);
		}

		if (!hasEditPreviewTab || finalStep === FINAL_STEP.EDIT_SUMMARY) {
			return save();
		}
		setFinalStep(FINAL_STEP.EDIT_SUMMARY);
	};

	const getConfirmTitle = () => {
		const hasProratedOrders = diffOrders?.some(diffOrder => diffOrder.totalValue > 0);
		const hasCreditOrders = diffOrders?.some(diffOrder => diffOrder.totalValue < 0);

		if ((!hasEditPreviewTab && finalStep === FINAL_STEP.DIFFORDER) || finalStep === FINAL_STEP.EDIT_SUMMARY) {
			const shouldShowDiffOrder = diffOrders.filter(diffOrder => diffOrder.show).length;

			let showDiffOrderTitle = T('subscription.modal.saveAndShowDiffOrder');
			if (hasProratedOrders && hasCreditOrders) {
				showDiffOrderTitle = T('subscription.modal.saveAndShowOrder');
			} else if (hasProratedOrders) {
				showDiffOrderTitle = T('subscription.modal.saveAndShowProratedOrder');
			} else if (hasCreditOrders) {
				showDiffOrderTitle = T('subscription.modal.saveAndShowCreditOrder');
			}
			return shouldShowDiffOrder
				? showDiffOrderTitle
				: labMode
				? T('subscription.promo.header.title')
				: T('subscription.modal.save');
		}

		if (finalStep === FINAL_STEP.DIFFORDER) {
			return T('default.preview');
		}

		const saveOrPreview = hasEditPreviewTab
			? T('default.preview')
			: labMode
			? T('subscription.promo.header.title')
			: T('subscription.modal.save');

		let diffOrderTitle = T('agreement.diffOrder');
		if (hasProratedOrders && hasCreditOrders) {
			diffOrderTitle =
				T('agreement.proratedOrder') +
				' ' +
				T('default.and').toLowerCase() +
				' ' +
				T('agreement.creditOrder').toLowerCase();
		} else if (hasProratedOrders) {
			diffOrderTitle = T('agreement.proratedOrder');
		} else if (hasCreditOrders) {
			diffOrderTitle = T('agreement.creditOrder');
		}

		return hasDiffOrdersToCreate ? diffOrderTitle : saveOrPreview;
	};

	const disableWhenUpdate = useMemo(
		() =>
			!!orderToAdd &&
			Object.values(subscriptionMap).some(period => {
				const currentValue = (
					period.children?.length ? period.children.flatMap(c => c.orderRow) : period.orderRows
				).reduce((sum, current) => sum + (current.price ?? 0) * (current.quantity ?? 0), 0);
				return currentValue < (period?.value ?? 0);
			}),
		[subscriptionMap]
	);

	const disableSave = disableDetailsSave || disableSummarySave || disableWhenUpdate;
	const hasNextStep =
		(!hasEditPreviewTab && hasDiffOrdersToCreate && !finalStep) ||
		(hasEditPreviewTab && finalStep !== FINAL_STEP.EDIT_SUMMARY);

	if (disableWhenUpdate) {
		tooltip = T('subscription.wonOpportunity.disableTooltip');
	}

	const accountHref = Tools.$state.href('account.dashboard', {
		id: client?.id,
		customerId
	});

	const onLinkNavigate = (e: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => {
		if (isDirty) {
			e.preventDefault();

			if (Tools.FeatureHelper.hasSoftDeployAccess('REACT_ALERT_MODAL')) {
				openModal('UnsavedChangesAlert', {
					onClose: async confirmed => {
						if (confirmed === undefined) {
							return;
						}
						if (confirmed) {
							await save();
						} else {
							onAbort();
						}
						window.location.href = accountHref;
					}
				});
				return;
			}

			// eslint-disable-next-line promise/catch-or-return
			Tools.$upModal
				.open('warningConfirm', {
					title: 'default.abort',
					body: 'confirm.abortEdit',
					resolveTrue: 'default.abortEdit',
					no: 'default.returnToEdit',
					icon: 'fa-warning'
				})
				.then(() => {
					window.location.href = accountHref;
					onAbort();
				});
		} else {
			onAbort();
		}
	};

	const onConfirmSupertitle = hasNextStep
		? T('subscription.modal.nextStep')
		: labMode
		? T('subscription.promo.header.supertitle')
		: null;

	return (
		<div>
			<EditorHeader
				infoLink={
					labMode
						? 'https://support.upsales.com/hc/en-us/articles/13598501826193-FAQ-Subscriptions-with-planned-changes-BETA-'
						: undefined
				}
				onConfirmSupertitle={onConfirmSupertitle}
				onBackTitle={T('subscription.modal.backToEditing')}
				onConfirmTooltip={locked ? T(lockedReason ?? '') : tooltip}
				onConfirmIcon={!hasNextStep && !labMode ? 'save' : undefined}
				disableSave={disableSave || locked}
				onConfirmTitle={getConfirmTitle()}
				onConfirmHasNext={hasNextStep}
				onAbortConfirm={isDirty && !labMode}
				tempMigrateButton={true}
				onConfirm={goToNextTab}
				icon="recurring-order"
				className={className}
				subtitle={subtitle}
				onAbort={onAbort}
				loading={saving}
				title={T('subscription.modal.editSubscription')}
				titleExtra={() => (
					<Link
						// sometimes you just want some inline styles
						style={{ marginLeft: '4px' }}
						href={accountHref}
						onClick={onLinkNavigate}
					>
						{client?.name}
					</Link>
				)}
				// @ts-ignore because it will otherwise expect null
				toolTip={client?.name}
				// @ts-ignore because it will otherwise expect null
				onBack={finalStep === FINAL_STEP.DIFFORDER ? () => setFinalStep(null) : null}
			>
				<Tabs noFlex color="white" onChange={changeProxy} selected={currentStep}>
					{steps.map(tab => (
						<Tab key={tab} id={tab}>
							{getTabName(tab)}
						</Tab>
					))}
				</Tabs>
				{disableWhenUpdate ? <Help articleId={1439} /> : null}
			</EditorHeader>
		</div>
	);
};

export default EditHeader;
