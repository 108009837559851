import _ from 'lodash';

// Will return { tier: <product_tier_obj>, tierNr: 1, last: boolean } or null if not found
export const getTierFromQuantity = (tiers, quantity) => {
	let tierNr = 0;
	// Match if quantity within tier range
	let tier = _.find(tiers, tier => {
		tierNr++;
		return (!tier.start || quantity >= tier.start) && (!tier.end || quantity <= tier.end);
	});

	// If no match and last one had an end that is lower than quantity, we pick it
	if (!tier && tiers.length && tiers[tiers.length - 1].end && tiers[tiers.length - 1].end < quantity) {
		tier = tiers[tiers.length - 1];
	}

	let last = false;

	// If no match and last one had an end that is lower than quantity, we pick it
	if (!tier && tiers.length && tiers[tiers.length - 1].end && tiers[tiers.length - 1].end < quantity) {
		tier = tiers[tiers.length - 1];
		last = true;
	}

	if (tier && tiers.length === tierNr) {
		last = true; // fix
	}

	return tier ? { tier, tierNr, last } : null;
};

// Will return { tier: <product_tier_obj>, tierNr: 1, last: boolean } or null if not found
export const getTierFromOrderRow = (orderRow, defaultPriceListId = null) => {
	const priceListId = defaultPriceListId ?? orderRow.priceListId;
	let tiers = orderRow?.product?.tiers;
	if (tiers && priceListId) {
		tiers = orderRow.product.tiers.filter(tier => tier.priceListId === priceListId);
	}

	if (!orderRow.product || !tiers || !tiers.length) {
		return null;
	}

	const quantity = parseFloat(tiers[0].isTotalPrice ? orderRow.tierQuantity : orderRow.quantity);
	return getTierFromQuantity(tiers, quantity);
};

export const getTierPriceByCurrency = (tier, currencyIso) => {
	const priceObject = _.find(tier.currencies, { currency: currencyIso });
	return priceObject ? priceObject.value : 0;
};

export const isTieredOrderRow = row =>
	!!(row.product && row.product.tiers && row.product.tiers.length && row.tierQuantity >= 1);

export const isTieredTotalOrderRow = row => !!isTieredOrderRow(row) && row.product.tiers[0].isTotalPrice;
