import { currencyDisplayDeprecated } from 'App/babel/components/Filters/Currencies';

export const getFirstName = userId => {
	return Tools.AppService.getUsers()
		.find(user => user.id === userId)
		.name.split(' ')[0];
};

export const getNameEnding = userId => {
	const firstName = getFirstName(userId);
	return firstName.substring(firstName.length - 1) !== ('s' || 'z' || 'x');
};

export const getUserCurrency = userId => {
	const defaultCurrency = Tools.AppService.getMetadata().defaultCurrency;
	const userRole = Tools.AppService.getUsers().find(user => user.id === userId).role;
	if (userRole) {
		const roleCurrency = Tools.AppService.getRoles().find(role => role.id === userRole.id).defaultCurrency;
		if (roleCurrency) {
			return (
				Tools.AppService.getMetadata().customerCurrencies.find(currency => currency.iso === roleCurrency) ||
				defaultCurrency
			);
		}
	}
	return defaultCurrency;
};

export const getStartAndEndDate = year => {
	const metadata = Tools.AppService.getMetadata();
	if (metadata.params.brokenFiscalYearEnabled) {
		const fiscalMoment = window.momentHelper.momentFiscalYear();
		return {
			start: fiscalMoment().startOf('fiscalYear').fiscalYear(year),
			end: fiscalMoment().endOf('fiscalYear').fiscalYear(year)
		};
	} else {
		return {
			start: moment(year, 'YYYY').format('YYYY-MM-DD'),
			end: moment(year, 'YYYY').endOf('year').format('YYYY-MM-DD')
		};
	}
};

export const noLetterNumberFormat = value => {
	return currencyDisplayDeprecated(value, true, true, true);
};
