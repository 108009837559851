import React from 'react';
import { Row, Column, Title, Text } from '@upsales/components';
import EditSocialEventAddResource from './EditSocialEventAddResource';
import FullscreenModal from 'App/babel/components/Dialogs/FullscreenModal';

export const addResourceModal = (title, body, cards, disabledResources) => {
	return Tools.$upModal.open('alert', {
		fullscreen: true,
		dialog: FullscreenModal,
		children: (
			<EditSocialEventAddResource title={title} body={body} cards={cards} disabledResources={disabledResources} />
		)
	});
};

export const getEmailModalText = () => {
	const t = Tools.$translate;
	return {
		title: t('socialEvent.addAutomatedEmailTitle'),
		body: t('socialEvent.addAutomatedEmailBody')
	};
};

export const getPageModalText = () => {
	const t = Tools.$translate;
	return {
		title: t('socialEvent.addEventPageTitle'),
		body: t('socialEvent.addEventPageBody')
	};
};

export const ModalHeader = ({ title, body }) => {
	const style = { margin: ' 15px auto', width: '480px' };
	return (
		<Row direction="column">
			<Column style={{ marginTop: '50px' }}>
				<Title size="xl" center>
					{title}
				</Title>
			</Column>
			<Column style={style}>
				<Text color="grey-11" style={{ marginTop: '15px' }} center>
					{body}
				</Text>
			</Column>
		</Row>
	);
};
