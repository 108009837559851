import {
	Progressbar,
	BadgeLogo,
	RadioItem,
	Headline,
	TableRow,
	Column,
	Button,
	Select,
	Block,
	Table,
	Input,
	Text,
	Icon,
	Logo,
	Row
} from '@upsales/components';
import UserSurveyRoles, { UserRoleSurveyStep, UserSurveyRoleIds, SurveyStages } from 'App/enum/UserSurvey';
import { PrimaryButton, ThirdButton } from '@upsales/components/Buttons';
import TableColumn from '@upsales/components/TableColumn/TableColumn';
import { SelectItem } from '@upsales/components/Utils/selectHelpers';
import React, { useState, useMemo, useRef, useEffect } from 'react';
import Checkbox from '@upsales/components/Checkbox/Checkbox';
import { Fade, SlideFade } from 'App/components/animations';
import UserSurveyRoleSection from './UserSurveyRoleSection';
import BemClass from '@upsales/components/Utils/bemClass';
import UserSurveyResource from 'App/resources/UserSurvey';
import { globalTracker } from 'App/babel/helpers/Tracker';
import LoginStats from 'App/babel/resources/LoginStats';
import logError from 'App/babel/helpers/logError';
import Card from '@upsales/components/Card/Card';
import T from 'Components/Helpers/translate';

import './UserSurvey.scss';
import { UserSurveyResult } from 'App/resources/AllIWant';

type Props = {
	className: string;
	close?: () => void;
	lang?: string;
	userSurvey?: UserSurveyResult;
};

type SurveySlideSectionProps = {
	classes: any;
	containerClassName?: string;
	children: React.ReactNode;
	visible: boolean;
	direction: 'left' | 'right' | 'top' | 'bottom';
	size?: string;
	headerSize?: string;
	headline?: string;
};

export type SelectValue = React.ComponentProps<typeof Select<SelectItem, true>>['value'];

type RoleOption = {
	id: string;
	title: string;
	preventAutoNext?: boolean;
	next?: RoleNode;
	extraFields?: { [key: string]: { value: string; label: string; placeholder: string } };
};

export type RoleNode = {
	title: string;
	selected?: RoleOption;
	options?: RoleOption[];
	prev?: RoleNode;
	id: string;
	depth?: number;
};

/**
 * add prev, and depth to the node to enable navigability and animations,
 * depth is used to go up or down in the animation.
 */
const createNode = (data: RoleNode, depth: number, prev?: RoleNode) => {
	const { id, title, options, selected } = data;
	const node = {
		id,
		title,
		depth,
		prev,
		selected,
		options: undefined
	} as RoleNode;
	if (options) {
		node.options = options.map(option => {
			const nodedOption = { ...option };
			if (nodedOption.next) {
				nodedOption.next = createNode(nodedOption.next, depth + 1, node);
			}
			return nodedOption;
		});
	}
	return node;
};

const managerStage = (id: string, yesKey: string, noKey: string) =>
	({
		id,
		title: 'userSurvey.role.areYouAManager',
		options: [
			{ id: yesKey, title: 'userSurvey.role.manager.yes' },
			{ id: noKey, title: 'default.no' }
		]
	} as RoleNode);

/**
 * Simple structure to handle the navigation and its options, if an option does not have a next node
 * it means it is a role option and the role navigation ends there.
 */
const root: RoleNode = {
	id: 'ROOT',
	title: 'userSurvey.role.whichDepartment',
	options: [
		{
			id: UserSurveyRoles.CEO,
			title: 'userSurvey.role.CEO'
		},
		{
			id: UserRoleSurveyStep.SALES,
			title: 'default.sales',
			next: {
				title: 'userSurvey.role.howDoYouSell',
				id: UserRoleSurveyStep.HOW_DO_YOU_SELL,
				options: [
					{
						id: UserRoleSurveyStep.FIND_NEW_CUSTOMERS,
						title: 'userSurvey.role.iFindNewCustomers',
						next: managerStage(
							UserRoleSurveyStep.FIND_NEW_CUSTOMERS,
							UserSurveyRoles.NEW_SALES_MANAGER,
							UserSurveyRoles.NEW_SALES
						)
					},
					{
						id: UserRoleSurveyStep.SELL_TO_EXISTING_CUSTOMERS,
						title: 'userSurvey.role.iSellToExistingCustomers',
						next: managerStage(
							UserRoleSurveyStep.SELL_TO_EXISTING_CUSTOMERS,
							UserSurveyRoles.UPSELL_SALES_MANAGER,
							UserSurveyRoles.UPSELL_SALES
						)
					},
					{
						id: UserRoleSurveyStep.SELL_BOTH,
						title: 'userSurvey.role.both',
						next: managerStage(
							UserRoleSurveyStep.SELL_BOTH,
							UserSurveyRoles.COMBO_SALES_MANAGER,
							UserSurveyRoles.COMBO_SALES
						)
					}
				]
			}
		},
		{
			id: UserRoleSurveyStep.MARKETING,
			title: 'userSurvey.role.marketing',
			next: managerStage(
				UserRoleSurveyStep.MARKETING,
				UserSurveyRoles.MARKETING_MANAGER,
				UserSurveyRoles.MARKETING
			)
		},
		{
			id: UserRoleSurveyStep.PROJECT_MANAGEMENT,
			title: 'userSurvey.role.projectManagement',
			next: managerStage(
				UserRoleSurveyStep.PROJECT_MANAGEMENT,
				UserSurveyRoles.PROJECT_MANAGER,
				UserSurveyRoles.PROJECT
			)
		},
		{
			id: UserRoleSurveyStep.FINANCE,
			title: 'userSurvey.role.finance',
			next: managerStage(UserRoleSurveyStep.FINANCE, UserSurveyRoles.FINANCE_MANAGER, UserSurveyRoles.FINANCE)
		},
		{
			id: UserRoleSurveyStep.CUSTOMER_SUPPORT,
			title: 'userSurvey.role.customerSupport',
			next: managerStage(
				UserRoleSurveyStep.CUSTOMER_SUPPORT,
				UserSurveyRoles.SUPPORT_MANAGER,
				UserSurveyRoles.SUPPORT
			)
		},
		{
			id: UserSurveyRoles.OTHER,
			title: 'default.other',
			preventAutoNext: true,
			extraFields: {
				otherRole: {
					value: '',
					placeholder: 'userSurvey.role.other.placeholder',
					label: 'userSurvey.role.other.label'
				}
			}
		}
	]
};

const findNodeInTree = (node: RoleNode, survey?: UserSurveyResult): RoleNode | null => {
	if (node !== null && survey) {
		if (node.options) {
			for (const option of node.options) {
				node.selected = option;
				const roleId = Object.keys(UserSurveyRoleIds).find(
					id => id === option.id
				) as keyof typeof UserSurveyRoleIds;
				if (roleId && UserSurveyRoleIds[roleId] === survey.role) {
					if (option.extraFields) {
						for (const key in option.extraFields) {
							option.extraFields[key].value = survey[key as keyof UserSurveyResult] as string;
						}
					}
					return node;
				}
				if (option.next) {
					const foundNode = findNodeInTree(option.next, survey);
					if (foundNode !== null) {
						return foundNode; // Propagate the found node up the recursion
					}
				}
				node.selected = undefined;
			}
		}
	}
	return null; // Node not found
};

const SurveySlideSection = ({
	classes,
	containerClassName,
	children,
	visible,
	direction,
	size,
	headerSize,
	headline
}: SurveySlideSectionProps) => {
	return (
		<SlideFade direction={direction} visible={visible} delayInMs={300} delayOutInMs={0} bounce>
			<div className={classes.elem('inner').b()}>
				<Block space={size} className={containerClassName ? classes.elem(containerClassName).b() : ''}>
					{headline ? (
						<Block space={headerSize}>
							<Headline size="sm">{headline}</Headline>
						</Block>
					) : null}
					{children}
				</Block>
			</div>
		</SlideFade>
	);
};

const UserSurvey = ({ className, close, userSurvey }: Props) => {
	const lang = {
		low: T('userSurvey.crmExperience.radioOne'),
		medium: T('userSurvey.crmExperience.radioTwo'),
		high: T('userSurvey.crmExperience.radioThree'),
		pro: T('userSurvey.crmExperience.radioFour'),
		generateLeads: T('userSurvey.crmGoalsLeads'),
		marketingLeads: T('userSurvey.crmGoalsMarketingLeads'),
		customerRelations: T('userSurvey.crmGoalsRelations'),
		optimiseSales: T('userSurvey.crmGoalsOptimise'),
		efficiencyAndOppurtunities: T('userSurvey.crmGoalsEfficiency'),
		insightsAndAnalytics: T('userSurvey.crmGoalsInsights'),
		simplifyAndCentralise: T('userSurvey.crmGoalsSimplify'),
		other: T('userSurvey.crmGoalsOther'),
		benefitsAndValues: T('userSurvey.upsalesReview.benefintsAndValues'),
		overwhelmed: T('userSurvey.upsalesReview.overwhelmed'),
		inadequate: T('userSurvey.upsalesReview.inadequate'),
		reviewOther: T('userSurvey.upsalesReview.other'),
		describeGoal: T('userSurvey.crmGoalDescribe')
	};

	const goals = [
		{ value: 'generateLeads', lang: lang.generateLeads },
		{ value: 'marketingLeads', lang: lang.marketingLeads },
		{ value: 'customerRelations', lang: lang.customerRelations },
		{ value: 'optimiseSales', lang: lang.optimiseSales },
		{ value: 'efficiencyAndOppurtunities', lang: lang.efficiencyAndOppurtunities },
		{ value: 'insightsAndAnalytics', lang: lang.insightsAndAnalytics },
		{ value: 'simplifyAndCentralise', lang: lang.simplifyAndCentralise },
		{ value: 'other', lang: lang.other }
	];

	const [stage, setStage] = useState(userSurvey?.stageId ?? SurveyStages.START);
	const selectedAnchor = useRef(null);
	const [selectedGoals, setGoal] = useState<string[]>(
		userSurvey?.goals
			? userSurvey?.goals.split(', ').filter(value => goals.findIndex(goal => goal.value === value) !== -1)
			: []
	);
	const [selectedGoalDescription, setGoalDescription] = useState<string>(userSurvey?.goalsDescription ?? '');
	const [selectedExperience, setExperience] = useState(userSurvey?.userExperience ?? '');
	const rolesRoot = useMemo(() => createNode(root, 1, undefined), []);
	const rootNode = createNode(root, 1, undefined);
	const [roleStep, setRoleStep] = useState<RoleNode>(findNodeInTree(rootNode, userSurvey) ?? rootNode);
	const [isRegularUser, setIsRegularUser] = useState(false);
	const [selectedReview, setReview] = useState<number>(userSurvey?.review ?? 0);
	const [selectedReviewLowScore, setReviewLowScore] = useState(userSurvey?.lowReview ?? '');
	const [selectedReviewLowScoreDescription, setReviewLowScoreDescription] = useState(
		userSurvey?.lowReviewDescription ?? ''
	);
	const [selectedSurveyCompleted, setselectedSurveyCompleted] = useState(false);
	const classes = new BemClass('UserSurvey', className);
	classes.add('FullScreenModal');

	const userExperience = [
		{ value: 'low', lang: lang.low },
		{ value: 'medium', lang: lang.medium },
		{ value: 'high', lang: lang.high },
		{ value: 'pro', lang: lang.pro }
	];

	const review = Array.from({ length: 10 }, (_, i) => ({ value: i + 1, title: (i + 1).toString() }));

	const reviewLowScore = [
		{ value: lang.benefitsAndValues, lang: lang.benefitsAndValues },
		{ value: lang.overwhelmed, lang: lang.overwhelmed },
		{ value: lang.inadequate, lang: lang.inadequate },
		{ value: lang.reviewOther, lang: lang.reviewOther }
	];

	const progressValue = (
		isRegularUser
			? [
					{ value: stage > SurveyStages.START ? 100 : 0, key: SurveyStages.START },
					{ value: stage > SurveyStages.WORK_TITLE ? 100 : 0, key: SurveyStages.WORK_TITLE },
					{ value: stage > SurveyStages.CRM_EXPERIENCE ? 100 : 0, key: SurveyStages.CRM_EXPERIENCE },
					{ value: stage > SurveyStages.GOALS ? 100 : 0, key: SurveyStages.GOALS }
			  ]
			: [
					{ value: stage > SurveyStages.START ? 100 : 0, key: SurveyStages.START },
					{ value: stage > SurveyStages.WORK_TITLE ? 100 : 0, key: SurveyStages.WORK_TITLE },
					{ value: stage > SurveyStages.CRM_EXPERIENCE ? 100 : 0, key: SurveyStages.CRM_EXPERIENCE }
			  ]
	).sort((a, b) => a.key - b.key);

	// Function for selecting and deselecting goals in the table/checkboxes
	const toggleGoal = (value: string) => {
		if (selectedGoals.length < 3 || selectedGoals.includes(value)) {
			setGoal(prevGoals =>
				prevGoals.includes(value) ? prevGoals.filter(goal => goal !== value) : [...prevGoals, value]
			);
		}
	};

	const saveSurvey = (partial?: boolean) => {
		let savedData = {
			userId: Tools.AppService.getSelf().id,
			role: UserSurveyRoleIds[roleStep.selected?.id as keyof typeof UserSurveyRoleIds],
			roleTitle: roleStep.selected?.id,
			goals: selectedGoals.join(', '),
			goalsDescription: selectedGoalDescription,
			userExperience: selectedExperience,
			review: selectedReview,
			lowReview: selectedReviewLowScore,
			lowReviewDescription: selectedReviewLowScoreDescription,
			otherRole: null
		};
		if (roleStep.selected?.extraFields) {
			const extraFields: { [key: string]: string } = {};
			for (const key in roleStep.selected.extraFields) {
				extraFields[key] = roleStep.selected.extraFields[key].value;
			}
			savedData = { ...savedData, ...extraFields };
		}
		const mixPanelSavedData = {
			...savedData,
			role: roleStep.selected?.id
		};

		try {
			UserSurveyResource.save({ ...savedData, stageId: partial ? stage : SurveyStages.FINISH });
			if (partial) {
				return;
			}
			setStage(SurveyStages.FINISH);
			globalTracker.track('UserSurveySave', mixPanelSavedData);
			setselectedSurveyCompleted(true);
		} catch (e) {
			logError(e, 'Failed to save user survey');
		}
		//set delay for closing modal
		setTimeout(() => {
			close?.();
		}, 2000);
	};

	const goNext = () => {
		setStage(prevStage => prevStage + 1);
	};

	const progressValueText = useMemo(() => {
		const total = progressValue.length;
		return stage === SurveyStages.START ? `0/${total}` : `${T('userSurvey.question')} ${stage}/${total}`;
	}, [stage, isRegularUser]);

	useEffect(() => {
		async function setRegularUser() {
			const { data: isRegularUser } = await LoginStats.userSurveyReview();
			if (isRegularUser) {
				setIsRegularUser(true);
			}
		}
		setRegularUser();
	}, []);

	return (
		<Fade>
			<Block space="prl ptl" className={classes.elem('cornerClose').b()}>
				<Button
					type="link"
					color="grey"
					onClick={() => {
						// if the it has a valid role send the event
						if (UserSurveyRoleIds[roleStep.selected?.id as keyof typeof UserSurveyRoleIds]) {
							saveSurvey(true);
						}
						close?.();
					}}
				>
					<Icon name="times" />
				</Button>
			</Block>
			<Row>
				<div className={classes.b()} ref={selectedAnchor}>
					<div>
						{!selectedSurveyCompleted ? (
							<div
								className={classes
									.elem('start-page')
									.mod({ 'static-left': stage !== SurveyStages.START })
									.b()}
							>
								<div className={classes.elem('inner-start').b()}>
									<Block space="mbxl">
										<Row>
											<BadgeLogo size="xl" />
											<Logo color="green" />
										</Row>
									</Block>
									<Block space="mbxl">
										<Headline size="sm">{T('userSurvey.start.header')}</Headline>
									</Block>
									<Block>
										<Text>{T('userSurvey.start.body')}</Text>
									</Block>
									{stage === SurveyStages.START ? (
										<>
											<Block space="mtxl">
												<Column align="center">
													<Button block onClick={goNext} size="xl">
														{T('userSurvey.start.startButton')}
													</Button>
												</Column>
											</Block>
											<Block space="mtl">
												<ThirdButton block onClick={() => close?.()}>
													{T('userSurvey.start.skipButton')}
												</ThirdButton>
											</Block>
										</>
									) : null}
								</div>
							</div>
						) : null}
					</div>
					<SlideFade
						direction={stage < SurveyStages.CRM_EXPERIENCE ? 'right' : 'left'}
						visible={stage !== SurveyStages.START && stage !== SurveyStages.FINISH}
						delayInMs={300}
						delayOutInMs={0}
					>
						<Block className={classes.elem('top-static').b()}>
							<Row className={classes.elem('progressbar-textRow').b()}>
								<Column align="center">
									<Text color="grey-10">{progressValueText}</Text>
								</Column>
							</Row>
							<Row className={classes.elem('progressbar-row').b()}>
								<Column />
								{progressValue.map(({ value, key }) => (
									<Column
										className={classes.elem('progressbar-column').b()}
										fixedWidth={80}
										key={key}
									>
										<Progressbar
											className={classes.elem('progressbar').b()}
											color="green"
											size="sm"
											value={value}
										/>
									</Column>
								))}
								<Column />
							</Row>
							<Row>
								<ThirdButton
									className={classes.elem('back-button').b()}
									onClick={() => {
										// if we are in the role selection, we need to go back to the previous step until reaching the root and then go back to the previous stage
										if (stage === SurveyStages.WORK_TITLE && roleStep.prev) {
											const currentStep = { ...roleStep.prev };
											currentStep.options = currentStep.options?.map(option => {
												// remember the selected option
												if (option.next?.id === roleStep.id) {
													option.next.selected = roleStep.selected;
												}
												return option;
											});
											setRoleStep(currentStep);
											return;
										}
										setStage(stage - 1);
									}}
								>
									<Text>
										<Icon name="chevron-left" /> {T('userSurvey.static.back')}
									</Text>
								</ThirdButton>
							</Row>
						</Block>
					</SlideFade>
					<SurveySlideSection
						direction={stage < SurveyStages.CRM_EXPERIENCE ? 'bottom' : 'top'}
						visible={stage === SurveyStages.CRM_EXPERIENCE}
						headline={T('userSurvey.crmExperience.header')}
						classes={classes}
						containerClassName="radio-block"
						size="mbxl"
						headerSize="mbxl"
					>
						{userExperience.map(({ value, lang }) => (
							<Block space="mbxl" key={value}>
								<Card borderRadius borderColor="grey-6" space="pll mbl ptm pbm plm">
									<RadioItem
										checked={selectedExperience === value}
										size="md"
										value={value}
										label={<Text bold> {lang} </Text>}
										onChange={() => {
											setExperience(value);
											goNext();
										}}
									/>
								</Card>
							</Block>
						))}
						<SlideFade delayInMs={300} delayOutInMs={0} visible={!!selectedExperience}>
							<Block space="mtxl">
								<Button onClick={goNext}>
									{T('userSurvey.nextQuestion')} <Icon name="arrow-right" />
								</Button>
							</Block>
						</SlideFade>
					</SurveySlideSection>
					<UserSurveyRoleSection
						root={rolesRoot}
						visible={stage === SurveyStages.WORK_TITLE}
						classes={classes}
						currentStage={roleStep}
						onNext={(node: RoleNode | null) => {
							if (!node) {
								// if node is null, it means we are done with the role selection
								goNext();
							} else {
								setRoleStep(node);
							}
						}}
					/>
					<SurveySlideSection
						direction={stage < SurveyStages.GOALS ? 'bottom' : 'top'}
						visible={stage === SurveyStages.GOALS}
						headline={T('userSurvey.crmGoalsHeader')}
						classes={classes}
						headerSize="mbxl"
					>
						<Block space="mbm">
							<Text padding-left="10px" size="sm">
								{T('userSurvey.choseRole.info')}
							</Text>
						</Block>
						<Block space="mbxl">
							<Table className={classes.elem('survey-table').b()}>
								{goals.map(({ value, lang }, index) => (
									<TableRow key={value}>
										<TableColumn>
											<Checkbox
												disabled={selectedGoals.length === 3 && !selectedGoals.includes(value)}
												checked={selectedGoals.includes(value)}
												onChange={() => toggleGoal(value)}
											/>
										</TableColumn>
										<TableColumn
											className={
												index === goals.length - 1
													? classes
															.elem('other-goal')
															.mod({
																expand:
																	selectedGoals.includes('other') && value === 'other'
															})
															.b()
													: ''
											}
										>
											<Text className={classes.elem('table-text').b()}>{lang}</Text>
											{selectedGoals.includes('other') && value === 'other' ? (
												<Input
													className={classes.elem('input').b()}
													placeholder={T('userSurvey.crmGoalDescribe')}
													onChange={e => setGoalDescription(e.target.value)}
													value={selectedGoalDescription}
													maxLength={128}
												/>
											) : null}
										</TableColumn>
									</TableRow>
								))}
							</Table>
						</Block>
						{isRegularUser ? (
							<SlideFade
								delayInMs={300}
								delayOutInMs={0}
								visible={
									!(
										selectedGoals.length === 0 ||
										(selectedGoals.includes('other') && !selectedGoalDescription)
									)
								}
							>
								<Button onClick={goNext}>
									{T('userSurvey.nextQuestion')} <Icon name="arrow-right" />
								</Button>
							</SlideFade>
						) : (
							<SlideFade
								delayInMs={300}
								delayOutInMs={0}
								visible={
									!(
										selectedGoals.length === 0 ||
										(selectedGoals.includes('other') && !selectedGoalDescription)
									)
								}
							>
								<Block space={'mtl'}>
									<PrimaryButton size="xl" onClick={() => saveSurvey()}>
										{T('userSurvey.submitButton')} <Icon name="check" />
									</PrimaryButton>
								</Block>
							</SlideFade>
						)}
					</SurveySlideSection>
					{isRegularUser ? (
						<SurveySlideSection
							direction={stage < SurveyStages.UPSALES_REVIEW ? 'bottom' : 'top'}
							visible={stage === SurveyStages.UPSALES_REVIEW}
							headline={T('userSurvey.upsalesReview')}
							classes={classes}
							headerSize="mbl"
							size="mbxl"
						>
							<Row>
								{review.map(({ value, title }) => (
									<Column align="center" key={value}>
										<Button
											color={selectedReview === value ? 'green' : 'grey-4'}
											value={value}
											text={title}
											size="xl"
											onClick={() => setReview(value)}
											className={classes.elem('review-button').b()}
										/>
										{value === 1 ? (
											<Text size="xs" color="grey-11">
												{T('userSurvey.upsalesReview.low')}
											</Text>
										) : null}
										{value === 10 ? (
											<Text size="xs" color="grey-11">
												{T('userSurvey.upsalesReview.high')}
											</Text>
										) : null}
									</Column>
								))}
								<SlideFade height visible={selectedReview <= 4 && selectedReview > 0}>
									<div className={classes.elem('low-review').b()}>
										<Headline size="sm">{T('userSurvey.upsalesReview.lowScore')} </Headline>
										{reviewLowScore.map(({ value, lang }) => (
											<Block space="mbxl mtxl" key={value}>
												<Card borderRadius borderColor="grey-6" space="pll mbl ptm pbm plm">
													<RadioItem
														checked={selectedReviewLowScore === value}
														label={<Text bold> {lang} </Text>}
														size="md"
														value={value}
														onChange={() => setReviewLowScore(value)}
													/>
													<SlideFade
														visible={
															value === 'Other' &&
															selectedReviewLowScore.includes('Other')
														}
														maxHeight={34}
														height
													>
														<Input
															className={classes.elem('input').b()}
															placeholder={T('userSurvey.upsalesReview.other')}
															onChange={e => setReviewLowScoreDescription(e.target.value)}
															maxLength={128}
														/>
													</SlideFade>
												</Card>
											</Block>
										))}
										<SlideFade
											visible={
												(!!selectedReviewLowScore &&
													!selectedReviewLowScore.includes('Other')) ||
												(selectedReviewLowScore.includes('Other') &&
													!!selectedReviewLowScoreDescription)
											}
											height
										>
											<Row>
												<Block space={'mtl'}>
													<PrimaryButton size="xl" onClick={() => saveSurvey()}>
														{T('userSurvey.submitButton')} <Icon name="check" />
													</PrimaryButton>
												</Block>
											</Row>
										</SlideFade>
									</div>
								</SlideFade>
							</Row>
							<SlideFade visible={selectedReview > 4} height>
								<Row>
									<Block space={'mtl'}>
										<PrimaryButton size="xl" onClick={() => saveSurvey()}>
											{T('userSurvey.submitButton')} <Icon name="check" />
										</PrimaryButton>
									</Block>
								</Row>
							</SlideFade>
						</SurveySlideSection>
					) : null}
				</div>
				<SlideFade
					direction={'left'}
					bounce
					visible={stage === SurveyStages.FINISH}
					delayInMs={300}
					delayOutInMs={0}
				>
					<div className={classes.elem('finish-page').b()}>
						<div className={classes.elem('finish-inner').b()}>
							<Block space="mbxl">
								<div className={classes.elem('finish-icon').b()}>
									<Text size="xl">
										<Icon name="check" />
									</Text>
								</div>
								<Column>
									<Headline size="sm"> {T('userSurvey.finish')}</Headline>
								</Column>
							</Block>
						</div>
					</div>
				</SlideFade>
			</Row>
		</Fade>
	);
};

export default UserSurvey;
