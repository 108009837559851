import DatePreset from 'App/upsales/common/services/datePresetService';
import ComparisonTypes from 'Resources/ComparisonTypes';

function generateFilter(options = {}, overrides = {}) {
	function generate() {
		return {
			inactive: true,
			value: {
				hasAppointment: true,
				ActivityType: { value: [] },
				RegDate: { value: { start: null, end: null, preset: 'whenever' } },
				EndDate: { value: { start: null, end: null, preset: 'whenever' } },
				Date: { value: { start: null, end: null, preset: 'whenever' } },
				Campaign: { value: [] },
				User: { value: [] },
				Outcome: { value: [] },
				SourceType: { value: [] },
				SourceId: { value: [] }
			}
		};
	}

	function isInactive(filter) {
		return filter.inactive;
	}

	function toUrl(filter) {
		const f = { v: {} };
		const val = filter.value;

		f.v.has = val.hasAppointment;
		f.i = filter.inactive;

		if (val.ActivityType.value) {
			f.v.ty = val.ActivityType.value;
		}
		if (val.Campaign.value) {
			f.v.ca = val.Campaign.value;
		}
		if (val.User.value) {
			f.v.us = val.User.value;
		}
		if (val.Outcome?.value) {
			f.v.st = val.Outcome.value;
		}
		if (val.SourceType?.value) {
			f.v.s = val.SourceType.value;
		}
		if (val.SourceId?.value) {
			f.v.si = val.SourceId.value;
		}
		if (val.Date && (val.Date.value.start || val.Date.value.end || val.Date.value.preset !== 'whenever')) {
			f.v.da = { p: val.Date.value.preset, s: val.Date.value.start, e: val.Date.value.end };
		}
		if (
			val.RegDate &&
			(val.RegDate.value.start || val.RegDate.value.end || val.RegDate.value.preset !== 'whenever')
		) {
			f.v.regDa = { p: val.RegDate.value.preset, s: val.RegDate.value.start, e: val.RegDate.value.end };
		}
		if (
			val.EndDate &&
			(val.EndDate.value.start || val.EndDate.value.end || val.EndDate.value.preset !== 'whenever')
		) {
			f.v.closeDa = { p: val.EndDate.value.preset, s: val.EndDate.value.start, e: val.EndDate.value.end };
		}

		return f;
	}

	function fromUrl(rawFilter) {
		const filter = generate();
		filter.inactive = false;
		const val = rawFilter.v;

		filter.value.hasAppointment = val.has;

		if (rawFilter.i) {
			filter.inactive = rawFilter.i;
		}
		if (val.ty) {
			filter.value.ActivityType = { value: val.ty };
		}
		if (val.ca) {
			filter.value.Campaign = { value: val.ca };
		}
		if (val.us) {
			filter.value.User = { value: val.us };
		}
		if (val.st) {
			filter.value.Outcome = { value: val.st };
		}
		if (val.s) {
			filter.value.SourceType = { value: val.s };
		}
		if (val.si) {
			filter.value.SourceId = { value: val.si };
		}

		if (val.da) {
			filter.value.Date = {
				value: {
					start: val.da.s ? new Date(val.da.s) : val.da.s,
					end: val.da.e ? new Date(val.da.e) : val.da.e,
					preset: val.da.p
				}
			};
		}
		if (val.regDa) {
			filter.value.RegDate = {
				value: {
					start: val.regDa.s ? new Date(val.regDa.s) : val.regDa.s,
					end: val.regDa.e ? new Date(val.regDa.e) : val.regDa.e,
					preset: val.regDa.p
				}
			};
		}
		if (val.closeDa) {
			filter.value.EndDate = {
				value: {
					start: val.closeDa.s ? new Date(val.closeDa.s) : val.closeDa.s,
					end: val.closeDa.e ? new Date(val.closeDa.e) : val.closeDa.e,
					preset: val.closeDa.p
				}
			};
		}

		return filter;
	}

	function build(filter, rb, filterHelperGetField, useTags) {
		const val = filter.value;

		if (filter.inactive) {
			return;
		}

		const getField = options.fieldPrefix
			? field => filterHelperGetField([options.fieldPrefix, field].join('.'))
			: filterHelperGetField;

		const idField = options.fieldPrefix ? 'appointment.id' : 'contact.appointment.id';

		if (
			!val.hasAppointment &&
			!val.ActivityType.value.length &&
			(val.Date.value.preset === 'whenever' ||
				(val.Date.value.preset === 'custom' && !val.Date.value.start && !val.Date.value.end)) &&
			!val.Campaign.value.length &&
			!val.User.value.length &&
			!val.Outcome?.value?.length &&
			!val.SourceType?.value?.length &&
			!val.SourceId?.value?.length
		) {
			rb.addFilter(getField(idField), ComparisonTypes.Equals, null);
			return;
		}

		const builder = rb.groupBuilder();

		if (val.hasAppointment) {
			builder.addFilter(getField(idField), ComparisonTypes.NotEquals, null);
		} else {
			builder.isNotFilter();
		}

		// ActivityType
		if (val.ActivityType?.value.length) {
			builder.addFilter(getField('appointment.activityType.id'), ComparisonTypes.Equals, val.ActivityType.value);
		}

		// Campaign
		if (val.Campaign?.value.length) {
			builder.addFilter(getField('appointment.project.id'), ComparisonTypes.Equals, val.Campaign.value);
		}

		// User
		if (val.User?.value.length) {
			builder.addFilter(getField('appointment.user.id'), ComparisonTypes.Equals, val.User.value);
		}

		// Outcome
		if (val.Outcome?.value?.length) {
			builder.addFilter(getField('appointment.outcome'), ComparisonTypes.MatchExact, val.Outcome.value);
		}

		// SourceType
		if (val.SourceType?.value?.length) {
			builder.addFilter(getField('appointment.sourceType'), ComparisonTypes.Equals, val.SourceType.value);
		}

		// SourceId
		if (val.SourceId?.value?.length) {
			builder.addFilter(getField('appointment.sourceId'), ComparisonTypes.Equals, val.SourceId.value);
		}

		// Date
		if (val.Date && val.Date.value.preset !== 'whenever') {
			const dates = DatePreset.getDatesFromValue(val.Date.value, useTags);

			// start
			if (dates.start) {
				builder.addFilter(getField('appointment.date'), ComparisonTypes.GreaterThanEquals, dates.start);
			}
			// end
			if (dates.end) {
				builder.addFilter(getField('appointment.date'), ComparisonTypes.LowerThanEquals, dates.end);
			}
		}

		if (val.EndDate && val.EndDate.value.preset !== 'whenever') {
			const dates = DatePreset.getDatesFromValue(val.EndDate.value, useTags);

			// start
			if (dates.start) {
				builder.addFilter(getField('appointment.endDate'), ComparisonTypes.GreaterThanEquals, dates.start);
			}
			// end
			if (dates.end) {
				builder.addFilter(getField('appointment.endDate'), ComparisonTypes.LowerThanEquals, dates.end);
			}
		}
		if (val.RegDate && val.RegDate.value.preset !== 'whenever') {
			const dates = DatePreset.getDatesFromValue(val.RegDate.value, useTags);

			// start
			if (dates.start) {
				builder.addFilter(getField('appointment.regDate'), ComparisonTypes.GreaterThanEquals, dates.start);
			}
			// end
			if (dates.end) {
				builder.addFilter(getField('appointment.regDate'), ComparisonTypes.LowerThanEquals, dates.end);
			}
		}

		if (builder.done) {
			builder.done();
		}
	}

	const filter = {
		filterName: 'Appointment',
		type: 'raw',
		displayType: 'appointment',
		title: 'default.appointments',
		parent: 'advancedSearch.contactHistory',
		entity: 'contact',
		showOnSegment: true,
		hide: true,
		generate,
		isInactive,
		toUrl,
		fromUrl,
		build,
		presets: [
			'whenever',
			'last7days',
			'last14days',
			'last30days',
			'last90days',
			'lastXdays',
			'next7days',
			'next30days',
			'next45days',
			'next90days',
			'custom'
		]
	};
	return Object.assign(filter, overrides);
}

export default generateFilter;
