export namespace EasyBookingRedux {
	export interface Interval {
		endTime: string;
		startTime: string;
	}
	export interface RecurringInterval extends Interval {
		dayOfWeek: number;
	}

	export interface DeviatingInterval extends Interval {
		date: string;
		isAvailable: boolean;
	}

	export interface OutputIntervals {
		recurring: EasyBookingRedux.RecurringInterval[];
		deviating: EasyBookingRedux.DeviatingInterval[];
	}

	export interface RemoteUser {
		bookingLimit: number;
		userId: number;
		user: {
			name: string;
			id: number;
			role: { id: number; name: string } | null;
		};
	}

	export interface User {
		bookingLimit: number;
		name: string;
		userId: number; // |
		id: number; //     | <- Both have the same value
		role: { id: number; name: string } | null;
	}

	export interface Role {
		bookingLimit: number;
		roleId: number;
	}

	export interface AppointmentLength {
		appointmentLength: number;
	}

	// More attributes can be added, run the following in your browser console for more info
	// await Tools.StandardIntegration.find({ active: true, init: 'calendar' })
	export interface EasyBookingCalendarIntegration {
		activatedByAdmin: boolean;
		activatedByUser: boolean;
		authorized: boolean;
		id: number;
		name: string;
		color: string;
		imageLink: string | null;
	}

	export type EditAvailabilityAction = 'deviation' | 'recurring' | 'deviationRestOfWeek' | 'recurringRestOfWeek';

	export type BookingRangeUnit = 'businessDays' | 'calendarDays';

	export interface BookingPageOldState {
		active: boolean;
		agenda: string;
		appointmentLengths: AppointmentLength[];
		bookingRangeUnit: BookingRangeUnit;
		bookingRangeValue: number;
		deviatingDays: EasyBookingRedux.DeviatingInterval[];
		embedDisplayLogo: boolean;
		message: string;
		recurringDays: RecurringInterval[];
		roles: EasyBookingRedux.Role[];
		showContactInfo: boolean;
		title: string;
		internalName: string;
		users: EasyBookingRedux.User[];
		weblinkActive: boolean;
		bufferTime: number;
		beforeMeetingBufferHours: number;
		beforeMeetingBufferDays: number;
		beforeMeetingBufferDaysUnit: BookingRangeUnit;
		isDefault: boolean;
		timezone: string;
		companyFieldSuggestions: boolean;
		onlyCompanyEmails: boolean;
		phoneFieldRequired: boolean;
		customLocation: string;
		bookingUrl: string;
		assignUser: boolean;
		prioritizeAccountManager: boolean;
	}

	export const OLD_STATE_SCHEMA: BookingPageOldState = {
		active: true,
		agenda: '',
		appointmentLengths: [{ appointmentLength: 60 }],
		bookingRangeUnit: 'businessDays',
		bookingRangeValue: 5,
		deviatingDays: [],
		embedDisplayLogo: false,
		message: '',
		recurringDays: [],
		roles: [],
		showContactInfo: true,
		title: '',
		internalName: '',
		users: [],
		weblinkActive: false,
		bufferTime: 15,
		beforeMeetingBufferHours: 1,
		beforeMeetingBufferDays: 0,
		beforeMeetingBufferDaysUnit: 'businessDays',
		isDefault: false,
		timezone: '',
		companyFieldSuggestions: true,
		onlyCompanyEmails: false,
		phoneFieldRequired: false,
		customLocation: '',
		bookingUrl: '',
		assignUser: false,
		prioritizeAccountManager: false
	};

	export enum ActivationState {
		Disabled = 1, //Activation process not started
		InactiveIntegrationForCustomerAsAdmin,
		InactiveIntegrationForCustomerAsUser,
		InactiveIntegrationForUser,
		ActiveIntegrationWithoutAuth,
		CanActivate //Config ok
	}

	/**
	 * The schema of a booking page as is in the backend
	 */
	interface RemoteBookingPageWithDeprecated {
		active: boolean;
		agenda: string;
		appointmentLength: number; //TODO: remove
		appointmentLengths: AppointmentLength[];
		bookingRangeUnit: BookingRangeUnit;
		bookingRangeValue: number;
		bookingUrl: string;
		bufferTime: number;
		beforeMeetingBufferHours: number;
		beforeMeetingBufferDays: number;
		beforeMeetingBufferDaysUnit: BookingRangeUnit;
		companyFieldSuggestions: boolean;
		customLocation: string;
		deviatingDays: EasyBookingRedux.DeviatingInterval[];
		embedDisplayLogo: boolean;
		embedHeight: string;
		embedTitle: string;
		embedWidth: string;
		id: number;
		isDefault: boolean;
		isShared: boolean;
		message: string;
		onlyCompanyEmails: boolean;
		phoneFieldRequired: boolean;
		recurringDays: RecurringInterval[];
		roles: EasyBookingRedux.Role[];
		showContactInfo: boolean;
		timezone: string;
		timezoneLocked: boolean;
		title: string;
		internalName: string;
		users: EasyBookingRedux.User[];
		weblinkActive: boolean;
		prioritizeAccountManager: boolean;
		assignUser: boolean;
	}

	export const BOOKING_PAGE_REMOTE_SCHEMA: RemoteBookingPageWithDeprecated = {
		active: true,
		agenda: '',
		appointmentLength: 60,
		appointmentLengths: [{ appointmentLength: 60 }],
		bookingRangeUnit: 'businessDays',
		bookingRangeValue: 5,
		bookingUrl: '',
		bufferTime: 15,
		beforeMeetingBufferHours: 1,
		beforeMeetingBufferDays: 0,
		beforeMeetingBufferDaysUnit: 'businessDays',
		companyFieldSuggestions: true,
		customLocation: '',
		deviatingDays: [],
		embedDisplayLogo: false,
		embedHeight: '',
		embedTitle: '',
		embedWidth: '',
		id: -1,
		isDefault: false,
		isShared: false,
		message: '',
		onlyCompanyEmails: false,
		phoneFieldRequired: false,
		recurringDays: [],
		roles: [],
		showContactInfo: true,
		timezone: '',
		timezoneLocked: false,
		title: '',
		internalName: '',
		users: [],
		weblinkActive: false,
		prioritizeAccountManager: false,
		assignUser: false
	};

	type DeprecatedRemoteAttributes = 'appointmentLength';

	/**
	 * The booking page interface as is in the backend
	 */
	export type RemoteBookingPage = Optional<RemoteBookingPageWithDeprecated, DeprecatedRemoteAttributes>;

	interface LocalBookingPageSchema extends RemoteBookingPage {
		calendarOffset: number;
		calendarSize: number;
		loading: boolean;
		oldState: BookingPageOldState;
		saving: boolean;
		showEditAvailability: boolean;
		showEditAvailabilityFormattedDate: string;
		showEditAvailabilityTimes: Interval[];
		showEditAvailabilityAction: EditAvailabilityAction;
		stateHasChanged: boolean;
		prioritizeAccountManager: boolean;
	}

	/**
	 * The schema of a booking page as is in Redux model
	 */
	export const BOOKING_PAGE_LOCAL_SCHEMA: LocalBookingPageSchema = {
		...BOOKING_PAGE_REMOTE_SCHEMA,
		calendarOffset: 0,
		calendarSize: 2,
		loading: true,
		oldState: OLD_STATE_SCHEMA,
		saving: false,
		showEditAvailability: false,
		showEditAvailabilityFormattedDate: '',
		showEditAvailabilityTimes: [],
		showEditAvailabilityAction: 'deviation',
		stateHasChanged: false
	};
	/**
	 * The booking page interface as is in Redux model
	 */
	export type LocalBookingPage = Omit<LocalBookingPageSchema, DeprecatedRemoteAttributes>;

	export interface LocalBookingPages {
		[bookingPageId: number]: EasyBookingRedux.LocalBookingPage;
	}

	/**
	 * The Redux state model interface
	 */
	export interface StateModel {
		active: boolean;
		bookingPages: EasyBookingRedux.LocalBookingPages;
		calendarIntegrations: EasyBookingCalendarIntegration[];
		activationState: ActivationState;
		loading: boolean;
	}

	export const INITIAL_STATE: StateModel = {
		active: false,
		bookingPages: {},
		calendarIntegrations: [],
		activationState: ActivationState.Disabled,
		loading: true
	};

	/**
	 * The paramId of the Easy Booking user param
	 */
	export const UserParamId = 70;
	/***********************************/
}
