import React from 'react';
import { Icon } from '@upsales/components';
import HiddenSelect from 'Components/Inputs/HiddenSelect';
import BemClass from '@upsales/components/Utils/bemClass';
import T from 'Components/Helpers/translate';
import { Label, Text, Link } from '@upsales/components';
import './UserSelect.scss';
import { AllIWantData } from 'App/resources/AllIWant';
import User from 'App/resources/Model/User';
import AccessType from 'App/enum/AccessType';

type UserSelectProps = {
	user: Pick<User | AllIWantData['customerSelf'], 'name' | 'id'>;
	onChange: (user: User) => void;
	preText: string;
	postText: string;
	className?: string;
	required?: boolean;
};

const UserSelect = ({ user, onChange, preText, postText, required = false, ...props }: UserSelectProps) => {
	const selfId = Tools.AppService.getSelf().id;
	const users = Tools.AppService.getUsers(AccessType.ACTIVITY);
	const classes = new BemClass('UserSelect');

	return (
		<HiddenSelect data={users} value={user} onChange={onChange} {...props} space="mrs" className={classes.b()}>
			{({ showSelect }: { showSelect: () => void }) => (
				<Label required={required}>
					{preText}
					<Link onClick={showSelect} className={classes.elem('link').b()}>
						<Text className={classes.elem('text').b()} color="bright-blue">
							{selfId === user.id ? T('default.you').toLowerCase() : user.name}
						</Text>
						<Icon name="angle-down" />
					</Link>
					{postText}
				</Label>
			)}
		</HiddenSelect>
	);
};

export default UserSelect;
