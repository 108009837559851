import { Block, Input, Label, Toggle, Text, Flex, Select } from '@upsales/components';
import { ResourceMultiSelect } from 'App/components/ResourceSelect';
import { useCustomFields, useStages } from 'App/components/hooks/appHooks';
import { useForceRender, useSoftDeployAccess } from 'App/components/hooks';
import CustomFields from 'App/components/CustomFields';
import FormObserver, {
	getCustomFieldModel,
	mapCustomValuesToArray,
	mapCustomValuesToObject
} from 'App/components/FormObserver';
import Product from 'Resources/Product';
import ProductAttributes from 'App/babel/attributes/ProductAttributes';
import ProjectPlanTemplate from 'App/resources/Model/ProjectPlanTemplate';
import React, { useMemo, useRef, useEffect, ComponentProps } from 'react';
import RequestBuilder, { comparisonTypes } from 'Resources/RequestBuilder';
import T from 'Components/Helpers/translate';

type BlockProps = ComponentProps<typeof Block>;

type Props = BlockProps & {
	projectPlanTemplate: ProjectPlanTemplate;
	onNameChange: (name: string) => void;
	onProductsChange: (products: { id: number; name: string; title: string }[]) => void;
	onStageChange: (id: number) => void;
	onActiveChange: (active: boolean) => void;
	onCustomChange: (custom: any) => void;
};

const addProductFilters = (rb: RequestBuilder) => {
	rb.addFilter(ProductAttributes.active, comparisonTypes.Equals, true);
	rb.addFilter(ProductAttributes.projectPlanTemplate.attr.id, comparisonTypes.Equals, null);
};

const ProjectSettings = ({
	projectPlanTemplate,
	onNameChange,
	onProductsChange,
	onStageChange,
	onActiveChange,
	onCustomChange,
	...blockProps
}: Props) => {
	const nameInputRef = useRef<HTMLInputElement>(null);
	const wrapperRef = useRef<HTMLDivElement>(null);
	const lang = useMemo(
		() => ({
			nameInputPlaceholder: T('editProjectPlan.nameInput.placeholder'),
			nameInputLabel: T('editProjectPlan.nameInput.label'),
			productSelectLabel: T('editProjectPlan.productSelect.label'),
			productSelectPlaceholder: T('editProjectPlan.productSelect.placeholder'),
			stageSelectLabel: T('editProjectPlan.stageSelect.label'),
			stageSelectPlaceholder: T('editProjectPlan.stageSelect.placeholder'),
			active: T('admin.active'),
			inactive: T('default.inactive')
		}),
		[]
	);

	const hasSpecificStageFeature = useSoftDeployAccess('START_PROJECT_AT_SPECIFIC_STAGE');

	// Todo: Should be using 'opportunity + done' instead of 'all'
	const stages = useStages('all');

	const customFields = useCustomFields('projectPlan');

	// Neither autofocus or autoFocus does not work for Input it seems like, talked to Alex and he said this was the way to do it right now
	useEffect(() => {
		const timeout = setTimeout(() => {
			if (nameInputRef.current) {
				nameInputRef.current.focus();
			}
		}, 250);
		return () => clearTimeout(timeout);
	}, []);

	// The following two lines is needed so that the component re-renders after it is mounted, without this wrapperRef.current is null, and the dropdown will not be positioned correctly
	// You should be able to provide a ref as anchor to Select to so that we don't need this hack
	const { triggerRender } = useForceRender();
	useEffect(() => triggerRender(), []);

	const selectedProducts = useMemo(
		() => projectPlanTemplate.products.map(({ id, name }) => ({ id, name, title: name })),
		[projectPlanTemplate.products]
	);

	const selectedStage = useMemo(() => {
		const s = projectPlanTemplate.opportunityStageId
			? stages.find(stage => stage.id === projectPlanTemplate.opportunityStageId)
			: null;
		return s ? { id: s.id, title: s.name } : null;
	}, [projectPlanTemplate.opportunityStageId]);

	const validatonModel = {
		custom: getCustomFieldModel(customFields, undefined, 'projectPlan', true)
	};
	const initialCustomValues = {
		custom: mapCustomValuesToObject(projectPlanTemplate.custom ?? [], customFields)
	};

	return (
		<Block space="ptxl" ref={wrapperRef} {...blockProps}>
			<Block>
				<Label maxLength={100} value={projectPlanTemplate.name} required>
					{lang.nameInputLabel}
				</Label>
				<Input
					inputRef={nameInputRef}
					placeholder={lang.nameInputPlaceholder}
					onChange={e => onNameChange(e.target.value)}
					state={projectPlanTemplate.name.length > 0 ? 'focus' : 'warning'}
					value={projectPlanTemplate.name}
				/>
			</Block>
			<Flex space="mtxl">
				<Toggle size="lg" checked={projectPlanTemplate.active} onChange={onActiveChange} />
				<Text space="mlm">{projectPlanTemplate.active ? lang.active : lang.inactive}</Text>
			</Flex>
			<Block space="mtxl">
				<Label>{lang.productSelectLabel}</Label>
				<ResourceMultiSelect
					modifyRb={addProductFilters}
					limit={50}
					resource={Product}
					required={false}
					placeholder={lang.productSelectPlaceholder}
					anchor={wrapperRef.current}
					value={selectedProducts}
					onChange={products => onProductsChange(products as { id: number; name: string; title: string }[])}
				/>
			</Block>
			{/* Todo: This select should be changed into a StageSelect select from app/components/Inputs/Selects once that exists */}
			{hasSpecificStageFeature && (
				<Block space="mtxl">
					<Label required>{lang.stageSelectLabel}</Label>
					<Select
						options={stages.map(stage => ({ id: stage.id, title: stage.name }))}
						required={true}
						placeholder={lang.stageSelectPlaceholder}
						anchor={wrapperRef.current}
						value={selectedStage}
						onChange={stage => onStageChange(stage.id)}
					/>
				</Block>
			)}
			<Block space="mtxl">
				<FormObserver<{ custom: { [key: string]: string | null } }>
					validateOnMount={false}
					onChange={values => {
						const mappedCustom = mapCustomValuesToArray(values.custom);
						onCustomChange(mappedCustom);
					}}
					model={validatonModel}
					initialValues={initialCustomValues}
				>
					{({ onFormChange, inputProps }) => (
						<CustomFields
							type="projectPlan"
							inputProps={inputProps}
							onChange={(id: number, value: string) => onFormChange(`custom.Custom_${id}`, value)}
							showFormGroupName={true}
							allFieldsOptional={true}
							allFieldsEditable={true}
						/>
					)}
				</FormObserver>
			</Block>
		</Block>
	);
};

export default ProjectSettings;
