import React from 'react';
import { currencyFormat } from 'Components/Filters/Currencies';
import moment from 'moment';
import RelationRow from 'Components/Inputs/RelationSelect/RelationRow';
import type Order from 'App/resources/Model/Order';
import { Text, Tooltip } from '@upsales/components';
import { RootState } from 'Store/index';
import { useSelector } from 'react-redux';
import t from 'Components/Helpers/translate';
import type Agreement from 'App/resources/Model/Agreement';
import { getSalesModelValues } from 'Components/EditProjectPlan/Helpers/Helpers';

export const OrderRelationRow = ({ order }: { order: Order | Pick<Order, 'id' | 'description'> }) => {
	if ('stage' in order) {
		const subtitle = `${currencyFormat(order.value, order.currency)} • ${moment(order.date).format(
			'YYYY-MM-DD'
		)} • ${order.stage.name}`;
		return (
			<RelationRow
				type="order"
				relationId={order.id}
				title={order.description}
				icon={order.probability === 100 ? 'dollar' : 'opportunity'}
				subtitle={subtitle}
			/>
		);
	} else {
		return (
			<RelationRow
				type="order"
				relationId={order.id}
				title={order.description}
				icon={'dollar'}
				subtitle={'subtitle'}
			/>
		);
	}
};

export const SubscriptionRelationRow = ({ subscription }: { subscription: Agreement }) => {
	const metadataParams = useSelector(({ App }: RootState) => App.metadata?.params);
	const salesModelOption = metadataParams?.SalesModelOption;
	const { salesModelValue, salesModelType } = getSalesModelValues(salesModelOption, subscription);

	const salesModelSubtitle = `${salesModelType}: ${currencyFormat(salesModelValue, subscription.currency)} • `;

	const subtitle = (
		<Text color="grey-10" size="sm">
			{salesModelSubtitle}
			<Tooltip title={t('agreement.subscriptionPreview.label.initialStartDate')}>
				{moment(subscription.metadata?.agreementStartdate).format('YYYY-MM-DD')}
			</Tooltip>
		</Text>
	);

	return (
		<RelationRow
			type="subscription"
			relationId={subscription.id}
			title={subscription.description}
			icon={'recurring-order'}
			subtitle={subtitle}
			user={subscription.user}
		/>
	);
};
