import { FormComponent } from 'App/components/FormComponent';
import { Block, Input, Label, Select, DateInput, SelectAsync } from '@upsales/components';
import { PrimaryButton } from '@upsales/components/Buttons';
import {
	ProjectTabs,
	ProjectTabValues,
	fetchClients,
	fetchContacts,
	fetchOrders,
	fetchStatusesPriorities,
	mapDefaultSelectNames,
	mapToSelectItem,
	mapToSelectItemOrder
} from 'Components/EditProjectPlan/Helpers/Helpers';
import { OrderRelationRow, SubscriptionRelationRow } from './OrderSubscriptionRelationRow';
import { useEditProjectPlanContext } from 'Components/EditProjectPlan/Context';
import { useUsers } from 'App/components/hooks/appHooks';
import BemClass from '@upsales/components/Utils/bemClass';
import Contact from 'App/resources/Model/Contact';
import Client from 'App/resources/Model/Client';
import CustomFields from 'App/components/CustomFields';
import ProjectPlanPriorityResource from 'App/resources/ProjectPlanPriority';
import ProjectPlanStatusResource from 'App/resources/ProjectPlanStatus';
import FormObserver, {
	FieldModel,
	getCustomFieldModel,
	mapCustomValuesToArray,
	mapCustomValuesToObject
} from 'App/components/FormObserver';
import moment from 'moment';
import NotesWithSignature from 'Components/Inputs/NotesWithSignature';
import ProjectPlan from 'App/resources/Model/ProjectPlan';
import React, { useEffect, useMemo, useState } from 'react';
import t from 'Components/Helpers/translate';
import type Order from 'App/resources/Model/Order';
import { AfterSelectDatePopup, AfterSelectEndDatePopup, AfterSelectUserPopup } from './AfterSelectPopup';

import './ProjectDetails.scss';
import { useSoftDeployAccess } from 'App/components/hooks';
import { formatWithSubAccounts } from 'App/helpers/accountsHelper';

type ProjectPlanForm = Omit<ProjectPlan, 'custom'> & { custom: { [key: string]: string | null } };
type ClientSelectType = { id: number; title: string; name: string; children?: [] };

// This is hard coded now. Will fix this in another ticket
const projectStageOptions = [
	{ id: 1, title: 'To Do', name: 'To Do' },
	{ id: 2, title: 'In progress', name: 'In progress' },
	{ id: 3, title: 'Done', name: 'Done' }
];

type Props = {
	validateFormOnMount: boolean;
	setSelectedTab: (selectedTab: ProjectTabValues) => void;
};

const ProjectDetails = ({ validateFormOnMount, setSelectedTab }: Props) => {
	const classes = new BemClass('ProjectDetails');
	const users = useUsers('active', false, false, true).map(({ id, name }) => ({ id, name, title: name }));
	const showStatusPriority = useSoftDeployAccess('PROJECT_PLAN_STATUS_PRIORITY');
	const hasSubAccounts = useSoftDeployAccess('SUB_ACCOUNTS');

	const {
		state,
		onProjectPlanChange,
		saveNewProject,
		getClient,
		getOrder,
		setTempStartDate,
		setTempEndDate,
		setTempUser
	} = useEditProjectPlanContext();

	const projectPlan = state.projectPlan!;
	const customFields = state.customFields;
	const order = state.order;
	const tasks = state.tasks;
	const tempStartDate = state.tempStartDate;
	const tempEndDate = state.tempEndDate;
	const tempUser = state.tempUser;
	const agreement = state.agreement;
	const savingProjectPlan = state.savingProjectPlan;

	const lang = useMemo(
		() => ({
			cancel: t('default.cancel'),
			company: t('account'),
			contact: t('contact'),
			createProject: t('projectPlan.createProjectPlan'),
			endDate: t('default.endDate'),
			name: t('editProjectPlan.nameInput.label'),
			nameInputPlaceholder: t('editProjectPlan.nameInput.placeholder'),
			order: t('order.order'),
			projectManager: t('projectPlan.projectManager'),
			projectStage: t('projectPlan.projectStage'),
			relatedTo: t('projectPlan.relatedTo'),
			select: t('default.select'),
			startDate: t('default.startDate'),
			priority: t('default.priority'),
			status: t('default.status')
		}),
		[]
	);

	const [selectAnchor, setSelectAnchor] = useState<Element | null>(null);

	useEffect(() => {
		setSelectAnchor(document.querySelector('.ProjectDetails'));
	}, []);

	const validatonModel = {
		name: FieldModel.string('default.name').required().max(100),
		client: FieldModel.object('default.client', {
			value: FieldModel.number('default.id'),
			label: FieldModel.string('default.name')
		}).required(),
		startDate: FieldModel.date('default.startDate').required(),
		endDate: FieldModel.date('activity.whenIsIt').required(),
		notes: FieldModel.string('default.notes').max(65535),
		projectPlanStage: FieldModel.object('projectPlan.projectStage', {
			value: FieldModel.number('default.id'),
			label: FieldModel.string('default.name')
		}).required(),
		projectPlanPriority: FieldModel.object('default.priority', {
			value: FieldModel.number('default.id'),
			label: FieldModel.string('default.name')
		}).required(),
		projectPlanStatus: FieldModel.object('default.status', {
			value: FieldModel.number('default.id'),
			label: FieldModel.string('default.name')
		}).required(),
		user: FieldModel.object('projectPlan.projectManager', {
			value: FieldModel.number('default.id'),
			label: FieldModel.string('default.name')
		}).required(),
		contact: FieldModel.object('default.contact', {
			value: FieldModel.number('default.id'),
			label: FieldModel.string('default.name')
		}),
		order: FieldModel.object('default.order', {
			value: FieldModel.number('default.id'),
			label: FieldModel.string('default.description')
		}),
		custom: getCustomFieldModel(customFields)
	};

	const initialValues = {
		...projectPlan,
		custom: mapCustomValuesToObject(projectPlan.custom, customFields)
	};

	const saveAndGoToTasks = () => {
		saveNewProject(projectPlan);
		getClient(projectPlan.client?.id);

		if (projectPlan.order) {
			getOrder(projectPlan.order.id);
		}

		setTimeout(() => {
			setSelectedTab(ProjectTabs.TASKS);
		}, 1000);
	};

	const openTodos = tasks.filter(task => task.closeDate === null);

	const checkUserInOpenTodos = () => {
		return openTodos.some(todo => todo.users[0]?.id === projectPlan.user?.id);
	};

	const getClientsWithSubaccounts = (searchTerm: string) => {
		return fetchClients()(searchTerm).then((data: Client[]) => {
			return formatWithSubAccounts(data, t);
		}) as Promise<ClientSelectType[]>;
	};

	return (
		<Block className={classes.b()} space="mrl pll ptxl prxl pbl">
			<FormObserver<ProjectPlanForm>
				onChange={(updatedProjectPlan, isValid, errorMessages) => {
					const mappedCustom = mapCustomValuesToArray(updatedProjectPlan.custom) as ProjectPlan['custom'];
					const reMappedProjectPlan = { ...updatedProjectPlan, custom: mappedCustom };
					onProjectPlanChange(reMappedProjectPlan, isValid, errorMessages);
				}}
				onSubmit={saveAndGoToTasks}
				validateOnMount={validateFormOnMount}
				model={validatonModel}
				initialValues={initialValues}
			>
				{({ onFormChange, inputProps, values: projectPlan, submit }) => (
					<Block className={classes.elem('container').b()}>
						{projectPlan && order ? (
							<Block space="mbxl">
								<Label>{lang.relatedTo}</Label>
								<Block>
									<OrderRelationRow order={order ?? projectPlan.order} />
								</Block>
								{agreement ? (
									<Block space="mtm">
										<SubscriptionRelationRow subscription={agreement} />
									</Block>
								) : null}
							</Block>
						) : null}

						<Block className={classes.elem('row').b()}>
							<Block className={classes.elem('column').b()}>
								<Block>
									<FormComponent label={lang.name} required={!projectPlan.id}>
										<Input placeholder={lang.nameInputPlaceholder} {...inputProps.name} />
									</FormComponent>
								</Block>
								{tempUser ? (
									<AfterSelectUserPopup setUser={user => onFormChange('user', user)} />
								) : null}
								<Block>
									<FormComponent label={lang.projectManager} required={!projectPlan.id}>
										<Select
											anchor={selectAnchor}
											multi={false}
											value={mapToSelectItem(projectPlan.user)}
											state={inputProps.user.state}
											required={inputProps.user.required}
											disabled={inputProps.user.disabled || savingProjectPlan}
											options={users}
											placeholder={lang.select}
											onChange={selectedItem => {
												if (projectPlan.id && checkUserInOpenTodos()) {
													setTimeout(() => setTempUser(selectedItem), 0);
												} else {
													onFormChange('user', selectedItem);
												}
											}}
										/>
									</FormComponent>
								</Block>
								{tempStartDate ? (
									<AfterSelectDatePopup
										setStartDate={startDate => onFormChange('startDate', startDate)}
										setEndDate={endDate => onFormChange('endDate', endDate)}
									/>
								) : null}
								<Block>
									<FormComponent label={lang.startDate} required={!projectPlan.id}>
										<DateInput
											{...inputProps.startDate}
											placeholder={lang.startDate}
											icon="calendar"
											closeOnSelect={true}
											min={undefined}
											max={projectPlan.endDate ? moment(projectPlan.endDate).toDate() : undefined}
											onChange={event => {
												if (projectPlan.id && !projectPlan.projectPlanTemplate?.offsetEndDate) {
													if (
														moment(event.target.value).diff(
															moment(projectPlan.startDate),
															'days'
														) !== 0
													) {
														setTimeout(() => setTempStartDate(event.target.value), 0);
													}
												} else {
													onFormChange('startDate', event.target.value);
												}
											}}
										/>
									</FormComponent>
								</Block>

								<Block>
									<FormComponent label={lang.projectStage} required={!projectPlan.id}>
										<Select
											anchor=".ProjectDetails"
											scrollContainer={selectAnchor}
											required={inputProps.projectPlanStage.required}
											multi={false}
											options={projectStageOptions}
											placeholder={t('default.select')}
											value={mapToSelectItem(projectPlan.projectPlanStage)}
											onChange={selectedItem =>
												onFormChange(
													'projectPlanStage',
													selectedItem as ProjectPlan['projectPlanStage']
												)
											}
										></Select>
									</FormComponent>
								</Block>
								{showStatusPriority ? (
									<Block>
										<FormComponent
											label={lang.status}
											required={inputProps.projectPlanStatus.required}
										>
											<SelectAsync
												anchor={selectAnchor}
												scrollContainer={selectAnchor}
												required={inputProps.projectPlanStatus.required}
												multi={false}
												fetcher={fetchStatusesPriorities(
													ProjectPlanStatusResource,
													projectPlan?.projectPlanStatus?.isDefault ?? true
												)}
												fetchOnMount
												showSearch={false}
												placeholder={t('default.select')}
												value={
													projectPlan?.projectPlanStatus?.isDefault
														? mapDefaultSelectNames(projectPlan.projectPlanStatus)
														: null
												}
												onChange={selectedItem =>
													onFormChange('projectPlanStatus', selectedItem)
												}
											></SelectAsync>
										</FormComponent>
									</Block>
								) : null}
							</Block>

							<Block className={classes.elem('column').b()}>
								<Block>
									<FormComponent label={lang.company} required={!projectPlan.id}>
										<SelectAsync
											key={projectPlan.client?.id ?? 0}
											state={inputProps.client.state}
											required={inputProps.client.required}
											disabled={inputProps.client.disabled || !!projectPlan.id}
											anchor={selectAnchor}
											multi={false}
											value={mapToSelectItem(projectPlan.client)}
											fetchOnMount
											fetcher={hasSubAccounts ? getClientsWithSubaccounts : fetchClients()}
											placeholder={lang.select}
											onChange={selectedItem => {
												onFormChange('client', selectedItem as unknown as Client);
												if (projectPlan.contact) {
													setTimeout(() => onFormChange('contact', null), 0);
												}
												if (projectPlan.order) {
													setTimeout(() => onFormChange('order', null), 0);
												}
											}}
										/>
									</FormComponent>
								</Block>

								<Block>
									<FormComponent label={lang.contact}>
										<SelectAsync
											key={projectPlan.client?.id ?? 0}
											anchor={selectAnchor}
											multi={false}
											disabled={inputProps.contact.disabled}
											value={mapToSelectItem(projectPlan.contact)}
											fetchOnMount
											fetcher={fetchContacts(projectPlan.client?.id)}
											placeholder={lang.select}
											onChange={selectedItem => {
												const selectedItemContact = selectedItem as Contact;
												if (!projectPlan.client) {
													onFormChange('client', selectedItemContact.client);
													setTimeout(() => onFormChange('contact', selectedItemContact), 0);
												} else {
													onFormChange('contact', selectedItemContact);
												}
											}}
											onClear={() => {
												onFormChange('contact', null);
											}}
										/>
									</FormComponent>
								</Block>
								{tempEndDate ? (
									<AfterSelectEndDatePopup
										setStartDate={startDate => onFormChange('startDate', startDate)}
										setEndDate={endDate => onFormChange('endDate', endDate)}
									/>
								) : null}
								<Block>
									<FormComponent label={lang.endDate} required={!projectPlan.id}>
										<DateInput
											{...inputProps.endDate}
											placeholder={lang.endDate}
											icon="calendar"
											closeOnSelect={true}
											min={
												projectPlan.startDate
													? moment(projectPlan.startDate).toDate()
													: undefined
											}
											max={undefined}
											onChange={event => {
												if (projectPlan.id && projectPlan.projectPlanTemplate?.offsetEndDate) {
													if (
														moment(event.target.value).diff(
															moment(projectPlan.endDate),
															'days'
														) !== 0
													) {
														setTimeout(() => setTempEndDate(event.target.value), 0);
													}
												} else {
													onFormChange('endDate', event.target.value);
												}
											}}
										/>
									</FormComponent>
								</Block>
								{!projectPlan.id ? (
									<Block>
										<FormComponent label={lang.order}>
											<SelectAsync
												key={projectPlan.client?.id ?? 0}
												anchor={selectAnchor}
												multi={false}
												disabled={inputProps.order.disabled}
												value={mapToSelectItemOrder(projectPlan.order)}
												fetchOnMount
												fetcher={fetchOrders(projectPlan.client?.id)}
												placeholder={lang.select}
												onChange={selectedItem => {
													const selectedItemOrder = selectedItem as unknown as Order;
													if (!projectPlan.client) {
														onFormChange('client', selectedItemOrder.client);
														setTimeout(() => onFormChange('order', selectedItemOrder), 0);
													} else {
														onFormChange('order', selectedItemOrder);
													}
												}}
												onClear={() => {
													onFormChange('order', null);
												}}
											/>
										</FormComponent>
									</Block>
								) : null}
								{showStatusPriority ? (
									<Block>
										<FormComponent
											label={lang.priority}
											required={inputProps.projectPlanPriority.required}
										>
											<SelectAsync
												anchor={selectAnchor}
												scrollContainer={selectAnchor}
												required={inputProps.projectPlanPriority.required}
												multi={false}
												fetcher={fetchStatusesPriorities(
													ProjectPlanPriorityResource,
													projectPlan?.projectPlanPriority?.isDefault ?? true
												)}
												fetchOnMount
												showSearch={false}
												placeholder={t('default.select')}
												value={
													projectPlan?.projectPlanPriority?.isDefault
														? mapDefaultSelectNames(projectPlan.projectPlanPriority)
														: null
												}
												onChange={selectedItem =>
													onFormChange('projectPlanPriority', selectedItem)
												}
											></SelectAsync>
										</FormComponent>
									</Block>
								) : null}
							</Block>
						</Block>

						<Block space="mbxl">
							<NotesWithSignature
								value={projectPlan.notes}
								onChange={value => onFormChange('notes', value)}
							/>
						</Block>

						<Block space="pbxl">
							<CustomFields
								type="projectPlan"
								inputProps={inputProps}
								onChange={(id, value) => onFormChange(`custom.Custom_${id}`, value)}
								disabled={false}
								showFormGroupName={true}
							/>
						</Block>
						{!projectPlan.id ? (
							<PrimaryButton
								className={classes.elem('saveButton').b()}
								disabled={savingProjectPlan}
								shadow="high"
								size="lg"
								loading={savingProjectPlan}
								onClick={submit}
							>
								{lang.createProject}
							</PrimaryButton>
						) : null}
					</Block>
				)}
			</FormObserver>
		</Block>
	);
};

export default ProjectDetails;
