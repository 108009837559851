import React from 'react';
import { Text, Avatar } from '@upsales/components';
import BemClass from '@upsales/components/Utils/bemClass';
import T from 'Components/Helpers/translate';

import './RecipientsDropDown.scss';
import { From } from './RecipientsDropDown';

type Props = {
	from: From;
};

const SelfRow = ({ from }: Props) => {
	const classes = new BemClass('RecipientsDropDown');

	return (
		<div className={classes.elem('dropDown').elem('youRow').b()}>
			{<Avatar size="sm" initials={from.name || ''} email={from.email} />}
			<Text className={classes.elem('dropDown').elem('youRow').elem('you').b()} bold>
				{T('you')}
			</Text>
			<Text className={classes.elem('dropDown').elem('youRow').elem('mail').b()} color="grey-10">
				{'(' + from.email + ')'}
			</Text>
		</div>
	);
};

export default SelfRow;
