import { PrimaryButton, ThirdButton } from '@upsales/components/Buttons';
import { useTranslation } from 'Components/Helpers/translate';
import BemClass from '@upsales/components/Utils/bemClass';
import React, { useState } from 'react';
import {
	ModalControls,
	ModalContent,
	ModalHeader,
	FullscreenModal,
	StateFrame,
	Text,
	Checkbox,
	Flex
} from '@upsales/components';
import LocalStorage from 'Components/Helpers/LocalStorage';
import { ModalProps } from 'App/components/Modals/Modals';
import './MatchCompaniesBeforeMerge.scss';
import Headline from '@upsales/components/Headline';

type MatchCompaniesBeforeMergeProps<T> = ModalProps<T> & {
	goToCleaningPage: (path?: string) => void;
	customerId: number;
};

const MatchCompaniesBeforeMerge = <T extends { [key: string]: any }>({
	goToCleaningPage,
	customerId,
	className,
	close
}: MatchCompaniesBeforeMergeProps<T>) => {
	const classes = new BemClass('MatchCompaniesBeforeMerge', className);
	const [checked, setChecked] = useState(false);
	const { t } = useTranslation();

	const handleCheckboxClick = () => {
		setChecked(!checked);
		const localStorage = new LocalStorage();
		const isChecked = JSON.stringify(checked);
		localStorage.setValueRaw('showMatchCompaniesBeforeMerge', isChecked);
	};

	return (
		<FullscreenModal className={classes.b()}>
			<ModalHeader onClose={close} />
			<ModalContent>
				<Headline>{t('matchCompaniesBeforeMerge.title')}</Headline>
				<StateFrame
					icon="lightbulb-on-o"
					space="mtxl mbxl"
					state="success"
					subtitle={t('matchCompaniesBeforeMerge.subtitle')}
				/>
				<Flex alignItems="center" space="mbl">
					<Checkbox checked={checked} onClick={() => handleCheckboxClick()} space="mrm" />
					<Text space="mls">{t('segment.doNotShowAgain')}</Text>
				</Flex>
			</ModalContent>

			<ModalControls>
				<Flex direction="column" gap="u3">
					<PrimaryButton
						className={classes.elem('goToMatchCompaniesButton').b()}
						size="lg"
						onClick={() => {
							close();
							goToCleaningPage(`/${customerId}/soliditet/matcher/`);
						}}
					>
						{t('matchCompaniesBeforeMerge.goToCompanyWashButton')}
					</PrimaryButton>
					<ThirdButton
						size="lg"
						onClick={() => {
							close();
							goToCleaningPage(`/detected-duplicates-landing-page/accounts`);
						}}
					>
						{t('matchCompaniesBeforeMerge.closeAndCleanDuplicatesButton')}
					</ThirdButton>
				</Flex>
			</ModalControls>
		</FullscreenModal>
	);
};

export default MatchCompaniesBeforeMerge;
