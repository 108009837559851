import React from 'react';
import { Row, Column, Text, Title, Tooltip, Icon } from '@upsales/components';
import formatWidgetValue from '../formatWidgetValue';
import { GenericWidgetProps } from '../ReportcenterWidget';
import bemClass from '@upsales/components/Utils/bemClass';
import { loadDrilldown } from '../chartHelper';
import './FunnelWidget.scss';

const Arrow = ({ classes, value, mod }: { classes: bemClass; value: number; mod: string }) => (
	<div className={classes.elem('arrow').mod(mod).b()}>
		<svg width="54" height="47" viewBox="0 0 54 47" fill="none" xmlns="http://www.w3.org/2000/svg" className="svg">
			<g filter="url(#filter0_d)">
				<path
					d="M4 6.32406C4 3.66018 6.55501 1.74058 9.11358 2.4822L27 7.66667L44.8864 2.4822C47.445 1.74058 50 3.66018 50 6.32406V31.3281C50 33.1084 48.8235 34.6744 47.1136 35.17L27 41L6.88641 35.17C5.17654 34.6744 4 33.1084 4 31.3281V6.32406Z"
					fill="#1D3D48"
				/>
			</g>
			<defs>
				<filter
					id="filter0_d"
					x="0"
					y="0.321289"
					width="54"
					height="46.6784"
					filterUnits="userSpaceOnUse"
					colorInterpolationFilters="sRGB"
				>
					<feFlood floodOpacity="0" result="BackgroundImageFix" />
					<feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" />
					<feOffset dy="2" />
					<feGaussianBlur stdDeviation="2" />
					<feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.2 0" />
					<feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow" />
					<feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow" result="shape" />
				</filter>
			</defs>
		</svg>
		<Text align="center">{formatWidgetValue(value, 'percent')}</Text>
	</div>
);

const FunnelWidget = ({ data, loading, currency, config, ...props }: GenericWidgetProps) => {
	const classes = new bemClass('FunnelWidget');
	if (!loading && data.total.special) {
		const {
			stage1,
			stage2,
			stage3,
			stage4,
			avgValue,
			totalValue,
			percentCompleted,
			percentCreated,
			percentWon,
			percentHitRate
		} = data.total.special;
		const openDrilldown = (type: string) => {
			if (props.drilldown) {
				loadDrilldown([type], config);
			}
		};

		return (
			<div className={classes.b()}>
				<Row className={classes.elem('content').b()}>
					<Column className={classes.elem('funnel').b()}>
						<Row onClick={() => openDrilldown('stage1')}>
							<Text className={classes.elem('value').b()}>{stage1}</Text>
							<Text className={classes.elem('label').b()}>{data.language.stage1}</Text>
						</Row>
						<Row onClick={() => openDrilldown('stage2')}>
							<Text className={classes.elem('value').b()}>{stage2}</Text>
							<Text className={classes.elem('label').b()}>{data.language.stage2}</Text>
						</Row>
						<Row onClick={() => openDrilldown('stage3')}>
							<Text className={classes.elem('value').b()}>{stage3}</Text>
							<Text className={classes.elem('label').b()}>{data.language.stage3}</Text>
						</Row>
						<Row onClick={() => openDrilldown('stage4')}>
							<Text className={classes.elem('value').b()}>{stage4}</Text>
							<Text className={classes.elem('label').b()}>{data.language.stage4}</Text>
						</Row>
						<Arrow classes={classes} value={percentCompleted} mod={'first'} />
						<Arrow classes={classes} value={percentCreated} mod={'second'} />
						<Arrow classes={classes} value={percentWon} mod={'third'} />
					</Column>
					<Column className={classes.elem('values').b()}>
						<Row>
							<Text color={'grey-10'}>{data.language.avgValue}</Text>
							<Title bold className={classes.elem('value').b()}>
								{formatWidgetValue(avgValue, data.datatype.progress, currency)}
							</Title>
						</Row>
						<Row>
							<Text color={'grey-10'}>
								{data.language.totalValue}
								<Tooltip title={data?.language?.tooltip ?? ''}>
									<Icon name="info-circle" />
								</Tooltip>
							</Text>
							<Title bold className={classes.elem('value').b()}>
								{formatWidgetValue(totalValue, data.datatype.progress, currency)}
							</Title>
						</Row>
						<Row>
							<Text color={'grey-10'}>{data.language.hitRate}</Text>
							<Title bold className={classes.elem('value').b()}>
								{formatWidgetValue(percentHitRate, 'percent')}
							</Title>
						</Row>
					</Column>
				</Row>
			</div>
		);
	} else {
		return <div></div>;
	}
};

export default FunnelWidget;
