export const newFilter = () => ({
	inactive: true,
	value: {
		has: true,
		Status: { value: 'all' }
	}
});

const ticketFilter = {
	generate: newFilter,
	isInactive: filter => filter.inactive,
	getText: (val, inactive) => {
		var num = 0;

		if (inactive !== true) {
			num++;
		}

		if (!val) {
			return num;
		}
		if (val.has) {
			num++;
		}
		if (val.Status.value) {
			num++;
		}
		return num;
	},
	toUrl: filter => {
		const f = { v: {} };
		const val = filter.value;

		f.v.has = val.has;
		f.i = filter.inactive;

		if (val.Status.value) {
			f.v.st = val.Status.value;
		}

		return f;
	},
	fromUrl: rawFilter => {
		const filter = newFilter();
		filter.inactive = false;
		const val = rawFilter.v;

		filter.value.has = val.has;

		if (rawFilter.i) {
			filter.inactive = rawFilter.i;
		}
		if (val.st) {
			filter.value.Status = { value: val.st };
		}

		return filter;
	},
	build:
		(options = {}) =>
		(filter, rb, filterHelperGetField) => {
			const val = filter.value;

			const getField = options.fieldPrefix
				? field => filterHelperGetField([options.fieldPrefix, field].join('.'))
				: filterHelperGetField;

			if (filter.inactive) {
				return;
			}

			const ticketFilters = rb.groupBuilder();
			const ticketOrFilter = ticketFilters.orBuilder();

			if (!val.has) {
				ticketFilters.isNotFilter();
			}

			switch (val.Status.value) {
				case 'all':
					ticketOrFilter.next();
					ticketOrFilter.addFilter(getField('supportTickets.hasOpen'), rb.comparisonTypes.Equals, true);
					ticketOrFilter.next();
					ticketOrFilter.addFilter(getField('supportTickets.hasClosed'), rb.comparisonTypes.Equals, true);
					ticketOrFilter.done();
					break;
				case 'open':
					ticketFilters.addFilter(getField('supportTickets.hasOpen'), rb.comparisonTypes.Equals, true);
					break;
				case 'closed':
					ticketFilters.addFilter(getField('supportTickets.hasClosed'), rb.comparisonTypes.Equals, true);
					break;
			}

			ticketFilters.done();
		}
};

export default ticketFilter;
