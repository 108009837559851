import React from 'react';
import moment from 'moment';
import T from 'Components/Helpers/translate';
import { ConfirmationDrawer } from 'App/components/drawers';

type Props = {
	visible?: boolean;
	onAnswer?: (postponeActivityDate: boolean) => void;
};

const PostponeRelatedActivity = ({ visible, onAnswer }: Props) => {
	const isWeekend = [5, 6].includes(moment().get('day')); // 5 - Friday, 6 - Saturday;
	const title = isWeekend
		? T('activity.outcome.postponePhoneCallUntilMonday')
		: T('activity.outcome.postponePhoneCallUntilTomorrow');

	return (
		<ConfirmationDrawer
			visible={visible}
			maxHeight={735}
			title={title}
			confirmButtonText={T('activity.outcome.postponePhoneCallConfirm')}
			onConfirm={onAnswer ? () => onAnswer(true) : undefined}
			cancelButtonText={T('activity.outcome.postponePhoneCallCancel')}
			onCancel={onAnswer ? () => onAnswer(false) : undefined}
		/>
	);
};

export default PostponeRelatedActivity;
