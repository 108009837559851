import Prospecting from 'App/babel/resources/Prospecting';
import logError from 'App/babel/helpers/logError';
import getAngularModule from 'App/babel/angularHelpers/getAngularModule';
import { mapProperties } from 'Services/ActionProperties';
import { pick, cloneDeep } from 'lodash';
import { onDrawerChanged, loadAIBoxes } from './Prospecting';
import T from 'Components/Helpers/translate';
import { globalTracker } from 'App/babel/helpers/Tracker';

const SET_STATE = '[ProspectingManageSelections] Set state';

export const initialState = {
	users: [],
	displayFields: [],
	selectableFields: [],
	categories: [],
	campaigns: [],
	managers: [],
	phoneCallSettings: {
		enabled: true,
		setAccountManager: true,
		properties: {
			Description: '',
			User: [],
			Priority: 0,
			AssignToManager: false,
			AssignToUser: false,
			SendNotification: false,
			Date: null,
			Time: '',
			Project: null,
			ActivityType: null
		},
		assignmentType: 'users',
		selectedUsersRoles: []
	},
	filters: null,
	sourceType: 'prospecting',
	addingCompanies: false,
	country: 'SE',
	showAccountData: false,
	height: 122
};

/*********** Actions **********/
export const init = ({ filters, sourceType, isExisting = false, country }) => ({
	type: SET_STATE,
	filters,
	isExisting,
	sourceType,
	country
});

export const reset = () => {
	const AppService = getAngularModule('AppService');
	const t = getAngularModule('$translate');
	const customFields = AppService.getCustomFields('account');
	const standardFields = Object.values(AppService.getMetadata()?.standardFields?.['Client'] ?? {});
	const filteredStandardFields = standardFields
		.filter(
			f =>
				f.editable &&
				!f.disabled &&
				f.active &&
				// These fields are already included on the form
				f.field !== 'campaigns' &&
				f.field !== 'categories' &&
				f.field !== 'users' &&
				// These fields are already filled by prospecting
				// or they don't make sense to change on bulk edit
				f.group !== 'contactInfo' &&
				f.group !== 'social' &&
				f.field !== 'name' &&
				f.field !== 'orgNo' &&
				f.field !== 'registrationDate' &&
				f.field !== 'sniCode' &&
				f.field !== 'naicsCode' &&
				f.field !== 'ukSicCode' &&
				f.field !== 'sicCode' &&
				f.field !== 'prospectingCreditRating' &&
				f.field !== 'cfar' &&
				f.field !== 'creditRating' &&
				f.field !== 'turnover' &&
				f.field !== 'profit' &&
				f.field !== 'noEmployees' &&
				f.field !== 'parentCompany' &&
				f.field !== 'companyForm' &&
				f.field !== 'status'
		)
		.map(f => {
			f.standardField = true;
			f.name = t(f.nameTag);
			return f;
		});
	const state = cloneDeep(initialState);

	state.users = AppService.getActiveUsers();
	state.displayFields = [
		...filteredStandardFields.filter(f => f.required),
		...customFields.filter(field => field.obligatoryField && field.editable && field.$hasAccess)
	];
	state.selectableFields = [
		...filteredStandardFields.filter(f => !f.required),
		...customFields.filter(field => !field.obligatoryField && field.editable && field.$hasAccess)
	];
	state.standardFields = standardFields;
	state.showAccountData = state.displayFields.length > 0;

	if (
		!Tools.FeatureHelper.hasSoftDeployAccess('TODO_LIST') ||
		!AppService.getTodoTypes().hasOwnProperty('PHONE_CALL')
	) {
		const defaultActivityTypeId = Tools.AppService.getMetadata().params.DefaultActivityTypeId;
		const activityTypes = Tools.AppService.getActivityTypes();
		const defaultActivityType =
			defaultActivityTypeId && activityTypes.find(({ id }) => id === defaultActivityTypeId);

		if (defaultActivityType) {
			state.phoneCallSettings.properties.ActivityType = defaultActivityType.id;
		} else {
			state.phoneCallSettings.properties.ActivityType = activityTypes[0].id;
		}
	} else {
		state.phoneCallSettings.properties.ActivityType = AppService.getTodoTypes().PHONE_CALL.id;
	}
	return { type: SET_STATE, ...state };
};

export const onSelectCustomField = event => (dispatch, getState) => {
	const { selectableFields, displayFields } = getState().ProspectingManageSelection;
	const customField = event.target.added;
	const index = selectableFields.findIndex(selectableField => selectableField.id === customField.id);

	if (index > -1) {
		// Need to splice the same array that the select2 hold a reference to
		selectableFields.splice(index, 1);
		dispatch({ type: SET_STATE, displayFields: [...displayFields, customField], selectableFields });
	}
};

export const onCustomFieldValueChange = (customField, value) => (dispatch, getState) => {
	const { displayFields } = getState().ProspectingManageSelection;
	const index = displayFields.findIndex(displayField => displayField.id === customField.id);

	if (index > -1) {
		const newDisplayFields = [...displayFields];
		newDisplayFields[index] = { ...customField, value };
		dispatch({ type: SET_STATE, displayFields: newDisplayFields });
	}
};

export const onCampaignChange = campaigns => ({ type: SET_STATE, campaigns });

export const onCategoryChange = categories => ({ type: SET_STATE, categories });

export const onAccountManagerChange = managers => ({ type: SET_STATE, managers });

export const onPhoneCallSettingsChanged = phoneCallSettings => ({ type: SET_STATE, phoneCallSettings });

export const getPhoneCallUsers = () => (dispatch, getState) => {
	const {
		users,
		phoneCallSettings: { assignmentType, selectedUsersRoles }
	} = getState().ProspectingManageSelection;

	if (assignmentType === 'roles') {
		return selectedUsersRoles.reduce(
			(res, roleId) =>
				users
					.filter(u => u.role && u.role.id === roleId)
					.map(u => u.id)
					.concat(res),
			[]
		);
	} else {
		return selectedUsersRoles;
	}
};

export const addCompaniesToUpsales = () => async (dispatch, getState) => {
	const {
		filters,
		sourceType,
		categories,
		campaigns,
		managers,
		displayFields,
		phoneCallSettings,
		isExisting,
		country
	} = getState().ProspectingManageSelection;

	const { assignmentType, selectedUsersRoles } = phoneCallSettings;
	const currentId = assignmentType === 'users' ? Tools.AppService.getSelf().id : Tools.AppService.getSelf().role?.id;
	const includingSelf = selectedUsersRoles.includes(currentId);
	globalTracker.track(
		`Prospecting assigned to ${assignmentType} ${includingSelf ? 'including self' : 'not including self'}`
	);

	const { selectedDrawer } = getState().Prospecting;

	dispatch({ type: SET_STATE, addingCompanies: true });

	const body = {
		filters,
		clientData: {
			custom: [],
			standard: [],
			sourceType,
			distributeAmongUsers: true,
			setAccountManager: phoneCallSettings.setAccountManager
		},
		activityProperties: [],
		country
	};

	if (managers.length && !phoneCallSettings.enabled) {
		body.clientData.users = managers;
		body.clientData.setAccountManager = true;
	}

	if (categories.length) {
		body.clientData.categories = categories.map(category => pick(category, ['id']));
	}

	if (campaigns.length) {
		body.clientData.projects = campaigns.map(campaign => pick(campaign, ['id']));
	}

	for (const field of displayFields) {
		if (field.value !== '' && field.value !== null && field.value !== undefined) {
			if (field.standardField) {
				body.clientData.standard.push({ fieldId: field.id, value: field.value, name: field.field });
			} else {
				body.clientData.custom.push({ fieldId: field.id, value: field.value });
			}
		}
	}

	if (phoneCallSettings.enabled) {
		const properties = pick(phoneCallSettings.properties, p => !!p);
		properties.User = dispatch(getPhoneCallUsers());

		if (!Tools.FeatureHelper.hasSoftDeployAccess('TODO_LIST') && !properties.Date) {
			properties.Date = moment().startOf('day').toDate();
		}

		body.activityProperties = mapProperties(properties, []);
		body.activityProperties.push({ name: 'RegBy', value: Tools.AppService.getSelf().id });
		let note = T('prospecting.notes.from', { name: Tools.AppService.getSelf().name });
		switch (sourceType) {
			case 'prospecting-startups':
				note += T('prospecting.box.startups');
				note += T('noti.prospecting-in-find-new-prospects');
				break;
			case 'prospecting-profitableCompanies':
				note += T('prospecting.box.profitableCompanies');
				note += T('noti.prospecting-in-find-new-prospects');
				break;
			case 'prospecting-hiringAtAFastRate':
				note += T('prospecting.box.hiringAtAFastRate');
				note += T('noti.prospecting-in-find-new-prospects');
				break;
			case 'prospecting-ai':
				note += T('prospecting.box.ai');
				note += T('noti.prospecting-in-find-new-prospects');
				break;
			case 'prospecting-manual':
				note += T('prospecting.entry.prospecting.title');
				note += T('noti.prospecting-in-find-new-prospects');
				break;
			case 'prospecting-lostCustomersLast3Years':
				note += T('prospecting.entry.inUpsales-lostCustomersLast3Years');
				note += T('noti.prospecting-in-find-new-prospects');
				break;
			case 'prospecting-notContactedLast2Years':
				note += T('prospecting.entry.inUpsales-notContactedLast2Years');
				note += T('noti.prospecting-in-find-new-prospects');
				break;
			case 'prospecting-lostOpportunitiesOlderThan1year':
				note += T('prospecting.entry.inUpsales-lostOpportunitiesOlderThan1year');
				note += T('noti.prospecting-in-find-new-prospects');
				break;
			case 'prospecting-similarTo':
				note += T('default.findSimilar');
				break;
		}
		note += '\n\n{{Client.About}}';
		body.activityProperties.push({ name: 'Notes', value: note });
	}
	try {
		if (isExisting) {
			const {
				selectedDrawerData: { box: selectedBox },
				Existing: { boxes }
			} = getState().Prospecting;
			const {
				Selected: { total }
			} = getState().ProspectingShared;
			body.nrOfIds = total;
			await Prospecting.runActionsSuggestions(selectedBox.name, body);
			const box = boxes.find(box => box.id === selectedBox.id);
			box.count = box.count - total;
		} else if (selectedDrawer === 'AI') {
			const {
				selectedDrawerData: { cameFromClientId }
			} = getState().Prospecting;
			await Prospecting.saveBulk(body);
			if (cameFromClientId) {
				await Tools.$state.go('account.dashboard', { id: cameFromClientId });
			} else {
				dispatch(loadAIBoxes());
			}
		} else {
			await Prospecting.saveBulk(body);
		}
		dispatch(onDrawerChanged(null));
	} catch (error) {
		if (error.response?.data?.error?.key === 'NotEnoughCredits') {
			Tools.NotificationService.addNotification({
				style: Tools.NotificationService.style.ERROR,
				title: 'default.error',
				body: 'prospecting.trial.limitReached',
				icon: 'times'
			});
			dispatch({ type: SET_STATE, addingCompanies: false });
		} else {
			logError(
				error,
				'ui/app/babel/components/Prospecting/ProspectingManageSelection/index.js - saveBulk failed'
			);
		}
	}
};

export const validateForm = (
	displayFields,
	phoneCallSettings,
	isExisting,
	standardFields,
	campaigns,
	managers,
	accountManagersRequired
) => {
	if (isExisting === false) {
		for (const customField of displayFields) {
			if (
				(customField.obligatoryField || customField.required) &&
				(customField.value === null || customField.value === '' || customField.value === undefined)
			) {
				return false;
			}
		}
		if (standardFields?.find(f => f.field === 'campaigns')?.required && !campaigns.length) {
			return false;
		}
	}

	if (!phoneCallSettings.enabled && accountManagersRequired && !managers.length) {
		return false;
	}

	if (
		phoneCallSettings.enabled &&
		(!phoneCallSettings.selectedUsersRoles.length || !phoneCallSettings.properties.Description)
	) {
		return false;
	}

	return true;
};

export const setShowAccountData = showAccountData => ({ type: SET_STATE, showAccountData });

export const setHeight = height => ({ type: SET_STATE, height });

const ACTION_HANDLERS = {
	// eslint-disable-next-line no-unused-vars
	[SET_STATE]: (state, { type, ...rest }) => ({ ...state, ...rest })
};

export default (state = initialState, action) => {
	const handler = ACTION_HANDLERS[action.type];
	return handler ? handler(state, action) : state;
};
