import { AnyAction } from 'redux';
import { AppThunk } from '..';
import SalesboardCardResource from 'App/resources/SalesboardCard';
import logError from 'App/babel/helpers/logError';
import SalesboardCard from 'App/resources/Model/SalesboardCard';

export const SET_CARD_CONFIG = '[Salesboard] Set card config';
export const SET_CARD_CONFIG_ID = '[Salesboard] Set card config id';
export const SET_OLD_CARD_CONFIG = '[Salesboard] Set old card config';
export const SET_CARD_CONFIG_SAVING = '[Salesboard] Set card config saving';
export const SET_USE_CB_IN_SALESBOARD = '[Salesboard] Set use cb in salesbord';

export type SalesboardCardConfig = SalesboardCard['config'];

type SalesboardState = {
	cardConfig: SalesboardCardConfig;
	savingCardConfig: boolean;
	cardConfigChanged: boolean;
	cardConfigId: number | null;
	oldCardConfig?: SalesboardCardConfig;
};

/*********** Actions **********/
export const setCardConfig = (data: SalesboardCardConfig) => ({ type: SET_CARD_CONFIG, data });
export const setOldCardConfig = (data: SalesboardCardConfig) => ({ type: SET_OLD_CARD_CONFIG, data });
export const setCardConfigId = (id: number) => ({ type: SET_CARD_CONFIG_ID, id });

export const initCardEditor = (): AppThunk => dispatch => {
	SalesboardCardResource.find({})
		.then(res => {
			let data;
			if (res.data?.length) {
				data = res.data[0];
				dispatch(setCardConfigId(data.id));
			} else {
				data = SalesboardCardResource.new();
			}

			dispatch(setCardConfig(data.config));
			dispatch(setOldCardConfig(data.config));
		})
		.catch(err => {
			logError(err, 'Failed to init salesboard card editor');
		});
};

export const setConfigSaving = (value: boolean) => ({ type: SET_CARD_CONFIG_SAVING, value });

export const saveConfig = (): AppThunk => (dispatch, getState) => {
	const { cardConfig, cardConfigId } = getState().Salesboard;
	dispatch(setConfigSaving(true));

	const data = { config: cardConfig, id: cardConfigId };
	SalesboardCardResource.save(data)
		.then(() => {
			dispatch(setConfigSaving(false));
			dispatch(setOldCardConfig(cardConfig));
		})
		.catch(() => {
			dispatch(setConfigSaving(false));
		});
};

export const getConfigChanged = (config: SalesboardCardConfig, oldConfig?: SalesboardCardConfig) => {
	return JSON.stringify(config) !== JSON.stringify(oldConfig);
};

const ACTION_HANDLERS: { [key: string]: (s: SalesboardState, a: AnyAction) => SalesboardState } = {
	[SET_CARD_CONFIG]: (state, action) => {
		const cardConfig = { ...state.cardConfig, ...action.data };
		return { ...state, cardConfig, cardConfigChanged: getConfigChanged(cardConfig, state.oldCardConfig) };
	},
	[SET_CARD_CONFIG_SAVING]: (state, action) => ({ ...state, savingCardConfig: action.value }),
	[SET_OLD_CARD_CONFIG]: (state, action) => ({
		...state,
		oldCardConfig: action.data,
		cardConfigChanged: getConfigChanged(state.cardConfig, action.data)
	}),
	[SET_CARD_CONFIG_ID]: (state, action) => ({ ...state, cardConfigId: action.id })
};

export const initialState: SalesboardState = {
	cardConfig: SalesboardCardResource.new().config,
	savingCardConfig: false,
	cardConfigChanged: false,
	cardConfigId: null
};

initialState.oldCardConfig = { ...initialState.cardConfig };

export default (state = initialState, action: AnyAction): SalesboardState => {
	const handler = ACTION_HANDLERS[action.type];
	return handler ? handler(state, action) : state;
};
