import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Bem from '@upsales/components/Utils/bemClass';
import { Card, CardHeader, CardContent, Link, Icon, Tabs, Tab } from '@upsales/components';

import NotificationList2 from './NotificationList2';
import { NOTIFICATION_TYPES } from 'App/babel/enum/notification';

import './index.scss';

export const TABS = {
	ALL: 'all',
	DOWNLOADS: 'downloads',
	APPS: 'apps',
	SIGNALS: 'signals'
};

class NotificationDropdown extends Component {
	constructor() {
		super();

		const t = Tools.$translate;
		this.lang = {
			title: t('notificationList.title'),
			tabAll: t('notificationList.tabs.all'),
			tabDownloads: t('notificationList.tabs.downloads'),
			tabApps: t('notificationList.tabs.apps'),
			emptyAllMsg: t('notificationList.all.empty'),
			emptyExportMsg: t('notificationList.export.empty'),
			emptyAppsMsg: t('notificationList.apps.empty')
		};

		this.state = {
			selectedTab: TABS.ALL
		};

		this.signalsEnabled =
			Tools.FeatureHelper.isAvailable(Tools.FeatureHelper.Feature.PROSPECTING_PRO) &&
			Tools.FeatureHelper.isAvailable(Tools.FeatureHelper.Feature.PROSPECTING_SIGNALS);
	}

	componentWillUnmount() {
		this.props.onClose();
	}

	onTabChanged = selectedTab => {
		this.setState({ selectedTab });
	};

	renderTabsContent() {
		const { selectedTab } = this.state;
		let notifications = this.props.notifications;

		switch (selectedTab) {
			case TABS.ALL:
				return (
					<NotificationList2
						{...this.props}
						notifications={notifications}
						emptyMessage={this.lang.emptyAllMsg}
						onTabChanged={this.onTabChanged}
						showJobs
					/>
				);
			case TABS.DOWNLOADS:
				notifications = notifications.filter(notification => notification.type === NOTIFICATION_TYPES.EXPORT);
				return (
					<NotificationList2
						{...this.props}
						notifications={notifications}
						emptyMessage={this.lang.emptyExportMsg}
					/>
				);
			case TABS.APPS:
				notifications = notifications.filter(
					notification => notification.type === NOTIFICATION_TYPES.APP_CALLBACK && notification.status !== 1
				);
				return (
					<NotificationList2
						{...this.props}
						notifications={notifications}
						emptyMessage={this.lang.emptyAppsMsg}
					/>
				);
			case TABS.SIGNALS:
				if (!this.signalsEnabled) return null;
				notifications = notifications.filter(notification => notification.type.includes('prospecting:'));
				return (
					<NotificationList2
						{...this.props}
						notifications={notifications}
						emptyMessage={this.lang.emptyAllMsg}
					/>
				);
			default:
				return null;
		}
	}

	render() {
		const classNames = new Bem('NotificationDropdown', this.props.className);
		const { selectedTab } = this.state;

		return (
			<div className={classNames.b()}>
				<Card className={classNames.elem('card').b()}>
					<CardHeader
						title={this.lang.title}
						onClick={this.props.close}
						className={classNames.elem('card-header').b()}
					>
						<Link nostyle>
							<Icon name="times" color="grey-10" />
						</Link>
					</CardHeader>
					<CardContent className={classNames.elem('content').b()}>
						<Tabs
							selected={selectedTab}
							onChange={this.onTabChanged}
							className={classNames.elem('tabs').b()}
						>
							<Tab id={TABS.ALL} title={this.lang.tabAll} />
							<Tab id={TABS.DOWNLOADS} title={this.lang.tabDownloads} />
							<Tab id={TABS.APPS} title={this.lang.tabApps} />
							{this.signalsEnabled ? <Tab id={TABS.SIGNALS} title={'Signals'} /> : null}
						</Tabs>
						{this.renderTabsContent()}
					</CardContent>
				</Card>
			</div>
		);
	}
}

NotificationDropdown.propTypes = {
	close: PropTypes.func,
	className: PropTypes.string,
	// From notification list
	notifications: PropTypes.array.isRequired,
	onClose: PropTypes.func,
	onNotificationClick: PropTypes.func.isRequired,
	exportUrl: PropTypes.string.isRequired,
	showImport: PropTypes.func.isRequired,
	total: PropTypes.number,
	loading: PropTypes.bool,
	jobs: PropTypes.array
};

export default NotificationDropdown;
