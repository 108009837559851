import React from 'react';
import T from 'Components/Helpers/translate';
import CreateCallClientContactSelect, {
	ClientValue,
	ContactValue
} from 'Components/CreateCall/CreateCallClientContactSelect';
import { Label } from '@upsales/components';
import BemClass from '@upsales/components/Utils/bemClass';

interface Props {
	onChange: (val: { client: ClientValue; contact: ContactValue }) => void;
	client?: ClientValue | null;
	contact?: ContactValue | null;
	saving?: boolean;
	autofocus?: boolean;
	clientInputRef?: (r: HTMLInputElement) => void;
	contactInputRef?: (r: HTMLInputElement) => void;
}

const ClientContactFollowupSelect = ({
	onChange,
	client,
	contact,
	saving,
	clientInputRef,
	contactInputRef,
	autofocus
}: Props) => {
	const localClientInputRef = React.useRef<HTMLInputElement>();
	const localContactInputref = React.useRef<HTMLInputElement>();
	React.useEffect(() => {
		if (autofocus) {
			setTimeout(() => {
				localClientInputRef.current?.focus();
			}, 300);
		}
	}, []);
	React.useEffect(() => {
		if (autofocus && client && !contact) {
			setTimeout(() => {
				localContactInputref.current?.focus();
			}, 300);
		}
	}, [client, contact]);
	const classes = new BemClass('ClientContactFollowupSelect');
	return (
		<div className={classes.b()}>
			<CreateCallClientContactSelect
				inputRef={r => {
					localClientInputRef.current = r;
					clientInputRef?.(r);
				}}
				space="mbxl"
				onChange={onChange}
				contact={contact}
				client={client}
			/>

			{client && !contact ? (
				<>
					<Label>{`${T('todo.whoToContactAt')} ${client.name}?`}</Label>
					<CreateCallClientContactSelect
						inputRef={r => {
							localContactInputref.current = r;
							contactInputRef?.(r);
						}}
						space="mbxl"
						onChange={onChange}
						contact={contact}
						client={client}
						clientId={client.id}
						disabled={saving}
						onlyContacts={true}
					/>
				</>
			) : null}
		</div>
	);
};

export default ClientContactFollowupSelect;
