import StandardIntegration from 'App/babel/resources/StandardIntegration';
import {
	init as initIntegrationConfig,
	onRequestOauth2,
	deActivate,
	activate,
	acceptContract,
	saveConfig
} from './ConfigIntegration';

export const RESET = '[ChooseMailIntegration] RESET';
export const SET_LOADING = '[ChooseMailIntegration] SET_LOADING';
export const SET_INTEGRATIONS = '[ChooseMailIntegration] SET_INTEGRATIONS';
export const SET_ERROR = '[ChooseMailIntegration] SET_ERROR';
export const SET_SELECTED_INTEGRATION = '[ChooseMailIntegration] SET_SELECTED_INTEGRATION';

/*********** Actions **********/

export const selectIntegration = selectedIntegration => {
	return async (dispatch, getState) => {
		const { integration, active } = getState().ConfigIntegration;
		const hasOtherActiveMailIntegration =
			integration &&
			_.find(integration.standardIntegrationInit, { type: 'mail' }) &&
			active &&
			integration.id !== selectedIntegration.id;
		if (hasOtherActiveMailIntegration) {
			await dispatch(deActivate());
		}
		dispatch(initIntegrationConfig(selectedIntegration));
		dispatch({ type: SET_SELECTED_INTEGRATION, data: { selectedIntegration } });
	};
};

export const deSelectIntegration = () => {
	return { type: SET_SELECTED_INTEGRATION, data: { selectedIntegration: null } };
};

export const init = () => async dispatch => {
	dispatch({ type: RESET });
	try {
		const { data: integrations } = await StandardIntegration.find({ active: true, init: 'mail' });
		dispatch({ type: SET_INTEGRATIONS, data: { integrations, loading: false } });
	} catch (error) {
		return dispatch({ type: SET_ERROR, data: { error: true, loading: false } });
	}
};

export const acceptTerms = oauthObj => {
	return async dispatch => {
		await dispatch(acceptContract());
		await dispatch(activate());
		if (oauthObj) {
			dispatch(onRequestOauth2(oauthObj));
		}
	};
};

export const saveIntegrationConfig = resolve => {
	return async (dispatch, getState) => {
		await dispatch(saveConfig());
		const { settingsErr } = getState().ConfigIntegration;
		if (settingsErr === null) {
			await Tools.CacheRefresher.refresh([Tools.CacheRefresher.types.METADATA]);
			const mailIntegration = Tools.AppService.getMailIntegration();
			resolve(mailIntegration);
		}
	};
};

export const initialState = {
	error: false,
	loading: true,
	integrations: [],
	selectedIntegration: null
};

const defaultHandler = (state, action) => ({ ...state, ...action.data });

const ACTION_HANDLERS = {
	[RESET]: () => initialState,
	[SET_LOADING]: defaultHandler,
	[SET_INTEGRATIONS]: defaultHandler,
	[SET_SELECTED_INTEGRATION]: defaultHandler
};

export default (state = initialState, action) => {
	const handler = ACTION_HANDLERS[action.type];
	return handler ? handler(state, action) : state;
};
