import ResourceTyped from './ResourceTyped';
import type ProjectPlanTemplate from 'App/resources/Model/ProjectPlanTemplate';

export type SpawnConfig = {
	action: 'spawn';
	projectPlanTemplateId: number;
	notes: string;
	startDate: string | null;
	endDate: string | null;
	userId: number | null;
};

export type MergeConfig = {
	action: 'merge';
	projectPlanTemplateId: number;
	projectPlanId: number;
};

export type AgreementFulfillmentChange = SpawnConfig | MergeConfig;

class FulfillmentResource extends ResourceTyped<ProjectPlanTemplate> {
	constructor() {
		super('fulfillment');

		this.notifications = {
			//@ts-expect-error The inferred typing from the JS file is not correct here
			agreementFulfillment: () => ({
				title: 'default.saved',
				body: 'saved.agreementFulfillment',
				style: 'success',
				icon: 'check',
				type: 'body'
			}),
			agreementFulfillmentError: () => ({
				title: 'default.error',
				body: 'saveError.agreementFulfillment',
				style: 'error',
				icon: 'times',
				type: 'body'
			})
		};
	}

	async agreementFulfillmentChange(agreementGroupId: number, fulfillmentChanges: AgreementFulfillmentChange[]) {
		return this._postRequest(
			'agreementFulfillmentChange',
			{ agreementGroupId, fulfillmentChanges },
			{ methodName: 'agreementFulfillmentChange' }
		).then(({ data }) => data);
	}
}

const resource = new FulfillmentResource();
export default resource;
