import { useEffect, useCallback, useRef, useState } from 'react';
import { debounce } from 'lodash';

import { type StandardIntegrationDataResponse } from 'App/resources/Model/StandardIntegrationData';
import { useAccountSelf, useIntegrations } from 'App/components/hooks/appHooks';
import StandardIntegrationDataResource from 'Resources/StandardIntegrationData';
import { ObjectWithKeys } from 'App/helpers/editTriggerHelpers/fieldHelpers';
import { makeCancelable } from '@upsales/components/Utils/CancelablePromise';
import { OrderRow } from 'App/components/OrderRows/Context/OrderContext';
import { EntityCustomField } from 'App/resources/Model/CustomField';
import getOrderChanges from 'App/helpers/getOrderChanges';
import { type Metadata } from 'App/resources/AllIWant';
import { useSoftDeployAccess } from '../featureHelper';
import useAppDispatch from '../useAppDispatch';

import { EditIntegrationResponse, onEditListenerResponse } from 'Store/reducers/EditListenerReducer';

export type EntityState = {
	custom: EntityCustomField[];
	priceList: { id: number };
	id?: number;
	uuid: number;
	orderRow: OrderRow[];
};

type EditIntegrationRequestData = {
	current: EntityState;
	previous: EntityState | undefined;
	changes: ObjectWithKeys | null;
};

export type UseCallEntityEditListenersProps = {
	entity: 'agreement';
	entityState: EntityState;
	loading: boolean;
};

type EditIntegration = Metadata['integrations']['inits'];

type IntegrationRequest = {
	pendingRequest?: ReturnType<typeof makeCancelable<StandardIntegrationDataResponse<EditIntegrationResponse>>>;
	previousEntityState?: EntityState;
};

type PendingIntegrationRequests = {
	[id: number]: IntegrationRequest;
};

const useCallEntityEditListeners = ({ entity, entityState, loading }: UseCallEntityEditListenersProps) => {
	const hasOnSubscriptionEdit = useSoftDeployAccess('APP_FRAMEWORK_ON_SUBSCRIPTION_EDIT');
	const integrations = useIntegrations();
	const accountSelf = useAccountSelf();
	const dispatch = useAppDispatch();
	const [pendingRequestsCount, setPendingRequestsCount] = useState(0);

	const pendingIntegrationRequests = useRef<PendingIntegrationRequests>({});
	const customerId = accountSelf?.id;

	const entityLiveListenerKey = entity ? (`after_${entity}_edit` as keyof EditIntegration) : undefined;
	const entityLiveListenerIntegrations = entityLiveListenerKey
		? integrations?.inits?.[entityLiveListenerKey] ?? []
		: [];

	const updatePendingRequestsCount = () => {
		const updatedPendingRequestsCount = Object.values(pendingIntegrationRequests.current).filter(
			integrationRequest => !!integrationRequest.pendingRequest
		)?.length;
		setPendingRequestsCount(updatedPendingRequestsCount);
	};

	const callEditListenerIntegration = useCallback(
		debounce(async (entityLiveListenerIntegrations, entityState, customerId) => {
			for (const integration of entityLiveListenerIntegrations) {
				if (!pendingIntegrationRequests.current[integration.id]) {
					pendingIntegrationRequests.current[integration.id] = {};
				} else {
					pendingIntegrationRequests.current[integration.id].pendingRequest?.cancel();
				}

				const previousEntityState = pendingIntegrationRequests.current[integration.id].previousEntityState;

				// Todo: Make sure to only send in order as the entityState
				// Todo: Be able to send in getOrderChanges as dependency to this function
				pendingIntegrationRequests.current[integration.id].pendingRequest = makeCancelable(
					StandardIntegrationDataResource.run<EditIntegrationRequestData, EditIntegrationResponse>({
						data: {
							current: entityState,
							previous: previousEntityState,
							changes: previousEntityState
								? getOrderChanges(entityState, previousEntityState as any)
								: null
						},
						type: `${entity}edit`,
						integrationId: integration.id
					})
				);
				updatePendingRequestsCount();

				pendingIntegrationRequests.current[integration.id].previousEntityState = structuredClone(entityState);

				const pendingRequest = pendingIntegrationRequests.current[integration.id].pendingRequest?.promise;
				if (pendingRequest) {
					try {
						const fieldActions = (await pendingRequest).data;
						pendingIntegrationRequests.current[integration.id].pendingRequest = undefined;
						updatePendingRequestsCount();

						dispatch(onEditListenerResponse(fieldActions));
					} catch (err) {
						pendingIntegrationRequests.current[integration.id].pendingRequest = undefined;
						updatePendingRequestsCount();
					}
				}
			}
		}, 250),
		[]
	);

	useEffect(() => {
		if (hasOnSubscriptionEdit && entityState && !loading && entityLiveListenerIntegrations.length && customerId) {
			callEditListenerIntegration(entityLiveListenerIntegrations, entityState, customerId);
		}
	}, [
		entityLiveListenerIntegrations.length,
		JSON.stringify(entityState),
		loading,
		customerId,
		hasOnSubscriptionEdit
	]);

	return pendingRequestsCount;
};

export default useCallEntityEditListeners;
