import DatePreset from 'App/upsales/common/services/datePresetService';
import _ from 'lodash';
import RequestBuilder, { comparisonTypes as ComparisonType } from 'Resources/RequestBuilder';
import moment from 'moment-timezone';
import { getLoggedInUserFilter } from 'App/babel/filterConfigs/filterGenerators';
import { ComparisonType as ComparisonTypeType } from 'Resources/ComparisonTypes';

// Adds filters to the requestbuilder provided and returns true if any filter was added / false if not
export default {
	range: function (
		rb: RequestBuilder,
		field: string,
		value: { start: string | number | null; end: string | number | null },
		exclude: boolean = false
	) {
		var added = false;
		const builder = exclude ? rb.groupBuilder() : rb;

		// start
		if (value.start || value.start === 0) {
			builder.addFilter({ field: field }, ComparisonType.GreaterThanEquals, value.start);
			added = true;
		}

		// end
		if (value.end || value.end === 0) {
			builder.addFilter({ field: field }, ComparisonType.LowerThanEquals, value.end);
			added = true;
		}

		if (exclude) {
			builder.isNotFilter();
			builder.done();
		}

		return added;
	},
	numRange: function (
		rb: RequestBuilder,
		field: string,
		value: { start: null | number | string; end: null | number | string }
	) {
		var added = false;

		// start
		if (!isNaN(parseInt(value.start as string)) && (value.start || value.start === 0)) {
			rb.addFilter({ field: field }, ComparisonType.GreaterThanEquals, value.start);
			added = true;
		}

		// end
		if (!isNaN(parseInt(value.end as string)) && (value.end || value.end === 0)) {
			rb.addFilter({ field: field }, ComparisonType.LowerThanEquals, value.end);
			added = true;
		}

		return added;
	},
	dateRange: function (
		rb: RequestBuilder,
		field: string,
		value: { preset: string; start: null | string; end: null | string },
		format: string | null,
		useTags?: boolean,
		exclude?: boolean
	) {
		if (value) {
			if (value.preset === 'whenever' || (value.preset === 'custom' && !value.start && !value.end)) {
				return false;
			}
			var values = value;
			if (value.preset === 'custom') {
				values = {
					start: values.start
						? moment(values.start)
								.startOf('day')
								.format(format || undefined)
						: null,
					end: values.end
						? moment(values.end)
								.endOf('day')
								.format(format || undefined)
						: null,
					preset: values.preset
				};
			} else if (value.preset === 'lastXdays') {
				values = {
					start: '{{subtract.days.' + (values.start || 1) + '|startOf.day}}',
					end: '{{endOf.day}}',
					preset: values.preset
				};
			} else {
				values = DatePreset.getValues(value.preset, format, useTags);
			}
			return this.range(rb, field, values, exclude);
		}
		return false;
	},
	boolean: function (rb: RequestBuilder, field: string, value: boolean) {
		rb.addFilter({ field: field }, ComparisonType.Equals, value);
		return true;
	},
	string: function (rb: RequestBuilder, field: string, value: string, comparisonType: ComparisonTypeType) {
		if (value) {
			var str = value.toString();
			if (str.length) {
				rb.addFilter({ field: field }, comparisonType, str);
				return true;
			}
		}
		return false;
	},
	list: function (
		rb: RequestBuilder,
		field: string,
		value: (string | number)[],
		comparisonType: ComparisonTypeType = 'eq'
	) {
		if (value) {
			var validComparisonType =
				comparisonType === 'eq' ||
				comparisonType === 'all' ||
				comparisonType === 'ne' ||
				comparisonType === 'mex' ||
				comparisonType === 'nmex' ||
				comparisonType === 'm' ||
				comparisonType === 'src' ||
				comparisonType === 'nsrc';

			if (value.length && validComparisonType) {
				var changedLength = false;
				const isUserRelatedField = [
					'user.id',
					'users.id',
					'client.users.id',
					'client.user.id',
					'order.user.id',
					'nestedUser.id',
					'regBy.id',
					'regBy',
					'userId',
					'from'
				].includes(field);

				value = _.map(value, function (s) {
					if (isUserRelatedField) {
						if (s === 'self' || s === getLoggedInUserFilter().email) {
							const currentUser = Tools.AppService.getSelf();
							return s === 'self' ? currentUser.id : currentUser.email;
						}
					}

					if (typeof s !== 'string') {
						return s;
					}
					if (s.length > 100) {
						changedLength = true;
						return s.substr(s.length - 100, s.length);
					}
					return s;
				});

				if (changedLength) {
					comparisonType = comparisonType === 'eq' ? 'wcs' : 'nwcs';
				}
				if (
					field === 'currency' &&
					value[0] === _.find(Tools.AppService.getMetadata().customerCurrencies, 'masterCurrency')?.iso
				) {
					var orBuilder = rb.orBuilder();
					orBuilder.next();
					orBuilder.addFilter({ field: field }, comparisonType, value);
					orBuilder.next();
					orBuilder.addFilter({ field: field }, comparisonType, [null]);
					orBuilder.done();
				} else {
					rb.addFilter({ field: field }, comparisonType, value);
				}

				return true;
			}
		}
		return false;
	}
};
