function generate() {
	return {
		inactive: true,
		comparisonType: 'Search',
		value: []
	};
}

function isInactive(filter) {
	return !filter.value || !filter.value.length;
}

function toUrl(filter) {
	return { v: filter.value, i: filter.inactive };
}

function fromUrl(rawFilter) {
	const filter = generate();
	filter.value = rawFilter.v;
	filter.comparisonType = rawFilter.c;
	filter.inactive = rawFilter.hasOwnProperty('i') ? rawFilter.i : isInactive(filter);
	return filter;
}

function build(filter, rb) {
	const value = filter.value;

	if (filter.inactive || !value?.length) {
		return;
	}

	rb.addFilter({ field: 'name' }, rb.comparisonTypes[filter.comparisonType], value);
}

const filter = {
	filterName: 'Name',
	field: 'basic.name',
	type: 'raw',
	displayType: 'text',
	comparisonType: 'Search',
	title: 'default.name',
	entity: 'account',
	showOnSegment: true,
	generate,
	isInactive,
	toUrl,
	fromUrl,
	build
};

export default filter;
