import React from 'react';
import { AnyAction } from 'redux';
import MailType, {
	Attachment,
	MailActivity,
	MailAppointment,
	MailOpportunity,
	MailRecipients
} from 'App/resources/Model/Mail';
import LZString from 'lz-string';
import MailSignature from 'App/resources/Model/MailSignature';

export const INITIALIZE = '[Mail] INITIALIZE';
export const SEND_PENDING = '[Mail] SEND_PENDING';
export const SEND_SUCCESS = '[Mail] SEND_SUCCESS';
export const SEND_FAILED = '[Mail] SEND_FAILED';

export const RESET = '[Mail] RESET';
export const SET_FROM = '[Mail] SET_FROM';
export const SET_SUBJECT = '[Mail] SET_SUBJECT';

export const SET_MAIL_BODY = '[Mail] SET_MAIL_BODY';

export const SET_RECIPIENTS = '[Mail] SET_RECIPIENTS';
export const SET_RECIPIENTS_CC = '[Mail] SET_RECIPIENTS_CC';
export const SET_RECIPIENTS_BCC = '[Mail] SET_RECIPIENTS_BCC';

export const SAVE_TEMPLATE = '[Mail] SAVE_TEMPLATE';
export const REMOVE_TEMPLATE = '[Mail] REMOVE_TEMPLATE';

export const ATTACHMENT_ADDED = '[Mail] ATTACHMENT_ADDED';
export const ATTACHMENT_REMOVED = '[Mail] ATTACHMENT_REMOVED';
export const FORWARD = '[Mail] FORWARD';
export const REPLY = '[Mail] REPLY';

export type MailState = {
	id?: number;
	error?: unknown;
	loading: boolean;
	from?: { id?: number; name: string; email: string; locked?: boolean };
	subject: MailType['subject'];
	body: MailType['body'];
	client?: Pick<MailType['client'], 'id'> & Partial<MailType['client']>;
	contact?: MailType['contact'];
	hasChanged: boolean;
	hash?: string;
	template: {
		id?: number;
		name?: string;
		hash?: string;
		hasChanged: boolean;
	};
	recipients: MailRecipients;
	appointment?: MailAppointment;
	activity?: MailActivity;
	opportunity?: MailOpportunity;
	attachments: Attachment[];
	mailType: 'sch' | 'out';
	mailSignatures?: MailSignature[];
};

export type Dispatch = (action: any) => void;

export const initialState: MailState = {
	id: undefined,
	from: undefined,
	loading: false,
	error: undefined,
	subject: '',
	body: '',
	hasChanged: false,
	hash: undefined,
	template: {
		id: undefined,
		name: '',
		hash: undefined,
		hasChanged: false
	},
	recipients: {
		to: [],
		cc: [],
		bcc: []
	},
	attachments: [],
	mailType: 'out',
	mailSignatures: undefined
};

const mailStateToHashObj = (state: MailState) => ({
	from: state.from,
	subject: state.subject,
	body: state.body,
	recipients: state.recipients
});

const decodeHTMLEntities = (html: string) => {
	let res = html;
	const entities = [
		['amp', '&'],
		['apos', "'"],
		['#x27', "'"],
		['#x2F', '/'],
		['#39', "'"],
		['#47', '/'],
		['lt', '<'],
		['gt', '>'],
		['nbsp', '\xa0'],
		['quot', '"']
	];

	entities.forEach(e => (res = res.replace(new RegExp('&' + e[0] + ';', 'g'), e[1])));
	res = res.replace(/(\r\n|\n|\r|\t)/gm, '');
	return res;
};

export const getJsonHash = (state: MailState) => {
	return LZString.compressToBase64(JSON.stringify(mailStateToHashObj({ ...initialState, ...state })));
};

export const getTemplateJsonHash = (subject: string, body: string) => {
	// This doesn't seem to work if the template contains pictures, or if it contains more than just a few lines. TODO - Fix
	return LZString.compressToBase64(JSON.stringify({ subject, body: decodeHTMLEntities(body) }));
};

const updateTemplate = (state: MailState, subject: string, body: string) => ({
	...state.template,
	hasChanged: state.template.hash ? getTemplateJsonHash(subject, body) !== state.template.hash : false
});

const keepAttachmentsIfProp = (attachments: Attachment[]) => {
	return attachments.filter(attachment => attachment.isProp);
};

const ACTION_HANDLERS: { [key: string]: (s: MailState, a: AnyAction) => MailState } = {
	[INITIALIZE]: (_, { state }) => ({
		...initialState,
		...state,
		loading: false,
		hash: getJsonHash({ ...initialState, ...state }),
		hasChanged: false,
		attachments: (state.attachments ?? []).map((attachment: Attachment) => ({ ...attachment, isProp: true }))
	}),
	[SEND_PENDING]: state => ({
		...state,
		loading: true,
		id: undefined,
		error: undefined
	}),
	[SEND_SUCCESS]: (state, { id }) => ({
		...state,
		loading: false,
		id,
		error: undefined
	}),
	[SEND_FAILED]: (state, { error }) => ({
		...state,
		loading: false,
		id: undefined,
		error: error
	}),
	[SET_SUBJECT]: (state, { subject }) => ({
		...state,
		subject,
		hasChanged: getJsonHash({ ...state, subject }) !== state.hash,
		template: updateTemplate(state, subject, state.body)
	}),
	[SET_RECIPIENTS]: (state, { to, client }) => ({
		...state,
		client: to.length ? client ?? state.client : undefined,
		recipients: { ...state.recipients, to: to },
		hasChanged:
			getJsonHash({
				...state,
				client: to.length ? client ?? state.client : undefined,
				recipients: { ...state.recipients, to: to }
			}) !== state.hash
	}),
	[SET_RECIPIENTS_CC]: (state, { cc, client }) => ({
		...state,
		recipients: { ...state.recipients, cc },
		client: cc.length ? client ?? state.client : undefined,
		hasChanged:
			getJsonHash({
				...state,
				recipients: { ...state.recipients, cc },
				client: cc.length ? client ?? state.client : undefined
			}) !== state.hash
	}),
	[SET_RECIPIENTS_BCC]: (state, { bcc, client }) => ({
		...state,
		recipients: { ...state.recipients, bcc },
		client: bcc.length ? client ?? state.client : undefined,
		hasChanged:
			getJsonHash({
				...state,
				recipients: { ...state.recipients, bcc },
				client: bcc.length ? client ?? state.client : undefined
			}) !== state.hash
	}),
	[SET_FROM]: (state, { from }) => ({ ...state, from, hasChanged: getJsonHash(state) !== state.hash }),
	[SET_MAIL_BODY]: (state, { body }) => ({
		...state,
		body,
		hasChanged: getJsonHash({ ...state, body }) !== state.hash,
		template: updateTemplate(state, state.subject, body)
	}),
	[SAVE_TEMPLATE]: (
		state,
		{ subject = state.subject, body = state.body, id, name, attachments = [], overrideAttachments }
	) => ({
		...state,
		subject,
		body,
		template: {
			...state.template,
			id,
			name,
			hash: getTemplateJsonHash(subject, body),
			hasChanged: false
		},
		attachments: overrideAttachments ? attachments : [...keepAttachmentsIfProp(state.attachments), ...attachments]
	}),
	[REMOVE_TEMPLATE]: state => ({
		...state,
		body: '',
		template: {
			id: undefined,
			name: '',
			hash: undefined,
			hasChanged: false
		}
	}),
	[ATTACHMENT_ADDED]: (state, { attachment }) => ({
		...state,
		hasChanged: true,
		attachments: [...state.attachments, attachment]
	}),
	[ATTACHMENT_REMOVED]: (state, { attachment }) => ({
		...state,
		attachments: [...state.attachments.filter(f => f.value.toString() !== attachment.value.toString())]
	}),
	[FORWARD]: (state, { subject }) => ({
		...state,
		subject,
		recipients: { ...state.recipients, to: [] }
	}),
	[REPLY]: (state, { subject, to, cc }) => ({
		...state,
		subject,
		recipients: { to, cc, bcc: [] }
	})
};

const MailContext = React.createContext<{ state: MailState; dispatch: Dispatch } | undefined>(undefined);

export const reducer = (state: MailState, action: AnyAction) => {
	const handler = ACTION_HANDLERS[action.type];
	return handler ? handler(state, action) : state;
};

export function MailProvider({ children }: { children: React.ReactNode }) {
	const [state, dispatch] = React.useReducer(reducer, { ...initialState });
	const value = { state, dispatch };
	return <MailContext.Provider value={value}>{children}</MailContext.Provider>;
}

export function useMailContext() {
	const context = React.useContext(MailContext);
	if (typeof context === 'undefined') {
		throw new Error('useMailContext must be used within a MailContext Provider');
	}
	return context;
}
