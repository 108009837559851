import React from 'react';
import PropTypes from 'prop-types';
import { Text, Link, Card } from '@upsales/components';
import BemClass from '@upsales/components/Utils/bemClass';
import moment from 'moment';
import T from 'Components/Helpers/translate';
import './ContactTimelineCreateContact.scss';
import logError from 'App/babel/helpers/logError';
import TimelineHistoryUsers from 'Components/TimelineRow/TimelineHistoryUsers';

const ContactRegByInfo = ({ contact }) => {
	if (!contact || (!contact.regBy && !contact.firstTouch)) {
		return null;
	}
	const classes = new BemClass('ContactRegByInfo');

	//if lang tag exist set it to it, else falsy
	let firstTouchText =
		T(`firstTouch.${contact.firstTouch?.type}`) !== `firstTouch.${contact.firstTouch?.type}`
			? T(`firstTouch.${contact.firstTouch?.type}`)
			: '';

	if (contact.firstTouch?.primary === 'SupportTicket') {
		firstTouchText = T('firstTouch.SupportTicket');
	}

	const createdByText = contact.users?.[0]?.name || contact.regBy?.name;
	if (!firstTouchText && contact.firstTouch?.type) {
		const errorMsg = `first touch type not found in lang ${contact.firstTouch.type}`;
		logError(new Error(errorMsg), errorMsg);
	}

	return (
		<Card className={classes.b()}>
			{contact.firstTouch && firstTouchText ? (
				<div className={classes.elem('col')}>
					<Text>{firstTouchText} </Text>
					<Text size="sm" color="grey-11">
						{T('default.type')}
					</Text>
				</div>
			) : null}
			{createdByText ? (
				<div className={classes.elem('col')}>
					<Text>{T(contact.regBy.name)} </Text>
					<Text size="sm" color="grey-11">
						{T('default.createdBy')}
					</Text>
				</div>
			) : null}
		</Card>
	);
};

const ContactTimelineCreateContact = ({ event, isHistoryLog }) => {
	const { contact, users } = event || {};
	const link = contact ? (
		<Link
			onClick={e => e.stopPropagation()}
			href={Tools.$state.href('contact.dashboard', {
				customerId: Tools.AppService.getCustomerId(),
				id: contact.id
			})}
		>
			{contact.name}
		</Link>
	) : null;
	let title = contact ? (
		<Text>
			{`${T('default.added').toLowerCase()} `}
			{link}
		</Text>
	) : null;
	if (!event.users?.length) {
		title = (
			<Text>
				{link}
				{` ${T('default.wasAdded').toLowerCase()}`}
			</Text>
		);
	}
	const subTitle =
		contact && contact.regDate ? (
			<Text size="sm" color="grey-11">
				{moment(contact.regDate).format('L LT')}
			</Text>
		) : null;

	const classes = new BemClass('ContactTimelineCreateContact');
	const subSection = <ContactRegByInfo contact={contact} />;
	return (
		<div className={classes.b()}>
			<TimelineHistoryUsers
				icon={isHistoryLog ? 'user-plus' : undefined}
				hideLine={true}
				hasAvatar={!!users.length && !isHistoryLog}
				event={event}
				title={title}
				subTitle={subTitle}
				subSection={subSection}
				someoneText=""
			/>
		</div>
	);
};

ContactTimelineCreateContact.propTypes = {
	event: PropTypes.shape({})
};

export default ContactTimelineCreateContact;
