import Resource from 'Resources/Resource';
import config from 'App/babel/config';
import parseMailCampaign from './parsers/mailCampaign';
import mapMailCampaign from './mappers/mailCampaign';
import MailCampaignAttributes from 'Attributes/MailCampaignAttributes';
import getAngularModule from 'App/babel/angularHelpers/getAngularModule';
import { globalTracker } from 'App/babel/helpers/Tracker';
import moment from 'moment';
import MailCampaign from './Model/MailCampaign';
import { BuildFilters } from 'Resources/RequestBuilder';

export enum status {
	DRAFT = 'DRAFT',
	SCHEDULED = 'SCHEDULED',
	SENT = 'SENT',
	NO_CONTACTS = 'NO_CONTACTS',
	NO_UNSUB = 'NO_UNSUB',
	ERROR = 'ERROR',
	DEL_PROJECT = 'DEL_PROJECT',
	ACTIVE = 'ACTIVE',
	MISSING_CREDITS = 'MISSING_CREDITS',
	MISSING_PAYMENT = 'MISSING_PAYMENT',
	ARCHIVED = 'ARCHIVED'
}

const isScheduled = (date: Date | null) => {
	if (!date || moment().isAfter(date)) {
		return false;
	}
	return true;
};

const isDraft = (mail: MailCampaign) => {
	return mail.status === status.DRAFT;
};

const sendToSegment = (mail: MailCampaign) => {
	if (isDraft(mail)) {
		return;
	}
	if (isScheduled(mail.sendDate)) {
		globalTracker.track('Scheduled mailCampaign');
		return;
	}

	globalTracker.track('Sent mailCampaign');
};

type Notification = {
	title: string;
	body: string;
	style: string;
	icon: string;
	type: string;
};

class MailCampaignResource extends Resource {
	eventName: string;
	notifications: {
		save: (data: { data: MailCampaign }) => Notification;
		saveError: () => Notification;
		delete: () => Notification;
		deleteError: () => Notification;
		archive: () => Notification;
		archiveError: () => Notification;
	};
	constructor() {
		super('mailCampaigns', MailCampaignAttributes);

		this.eventName = 'mailCampaign';

		this.parse = parseMailCampaign;
		this.map = mapMailCampaign;

		this.notifications = {
			save: ({ data }) => {
				let title;
				let body;
				if (isDraft(data)) {
					title = 'default.saved';
					body = 'mail.mailWasSavedDraft';
				} else if (isScheduled(data.sendDate)) {
					title = 'mail.mailCampaignScheduled';
					const d = moment(data.sendDate).format('L LT');
					body = getAngularModule('$translate')('mail.mailCampaignWillBeSent') + ' ' + d;
				} else {
					title = 'mail.mailCampaignStarted';
					body = 'mail.mailCampaignWasSent';
				}
				return {
					title,
					body,
					style: 'success',
					icon: 'check',
					type: 'body'
				};
			},
			saveError: () => ({
				title: 'default.error',
				body: 'saveError.mailCampaign',
				style: 'error',
				icon: 'times',
				type: 'body'
			}),
			delete: () => ({
				title: 'default.deleted',
				body: 'mail.mailCampaignDeleted',
				style: 'success',
				icon: 'check',
				type: 'body'
			}),
			deleteError: () => ({
				title: 'default.error',
				body: 'mail.mailCampaignDeleteError',
				style: 'error',
				icon: 'times',
				type: 'body'
			}),
			archive: () => ({
				title: 'default.archived',
				body: 'mail.mailCampaignArchived',
				style: 'success',
				icon: 'check',
				type: 'body'
			}),
			archiveError: () => ({
				title: 'default.error',
				body: 'mail.mailCampaignArchivedError',
				style: 'error',
				icon: 'check',
				type: 'body'
			})
		};
	}

	new(overrides = {}) {
		const self = getAngularModule('AppService').getSelf();
		return {
			name: '',
			template: null,
			user: self,
			body: '',
			bodyJson: null,
			sendDate: null,
			mailClicked: 0,
			mailRead: 0,
			mailPending: 0,
			mailSent: 0,
			from: self.email,
			fromName: self.name,
			subject: '',
			status: status.DRAFT,
			project: null,
			jobId: null,
			attachments: [],
			isArchived: false,
			filterConfig: null,
			filter: null,
			excludeRecentlyEmailed: false,
			...overrides
		};
	}

	find(filter: Partial<BuildFilters> = {}): Promise<{
		data: MailCampaign[];
		metadata: {
			total: number;
		};
	}> {
		filter.q = filter.q || [];
		filter.q.push({
			a: 'status',
			c: 'ne',
			v: [status.ACTIVE, status.MISSING_CREDITS, status.MISSING_PAYMENT, status.ARCHIVED]
		});
		return super.find(filter);
	}

	async save(data: MailCampaign, opts: object) {
		const res = await super.save(data, opts);

		sendToSegment(res.data);

		return res;
	}

	mailBodyPreview(type: string, id?: number) {
		let end: string | number;
		if (!id) {
			end = type;
			type = 'campaign';
		} else {
			end = id;
		}
		return `${config.URL}${config.API}file/mailBodyPreview/${type}/${end}`;
	}

	retry(id: number) {
		return this._postRequest(`retry/${id}`, {}, { methodName: 'retry' });
	}

	async archive(id: number, isArchived: boolean): Promise<{ data: MailCampaign }> {
		const res = await (this._putRequest(id, { id, isArchived }, { methodName: 'archive' }) as Promise<{
			data: { data: MailCampaign };
		}>);
		return res.data;
	}
}

const resource = new MailCampaignResource();

export default resource;
