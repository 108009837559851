import { ListViewFilter } from 'App/resources/AllIWant';
import { MinifiedFilter } from '../filterConfigs/FilterConfig';
import RequestBuilder from 'Resources/RequestBuilder';
import ComparisonTypes from 'Resources/ComparisonTypes';
import _ from 'lodash';
import Category, { CategoryType } from 'App/resources/Model/Category';

const categoryFilterBaseConfig = {
	field: 'category.id',
	searchField: 'name',
	displayText: _.property('name'),
	type: 'raw',
	displayType: 'listLazy',
	parent: 'default.categories',
	multiple: true,
	multiComparitors: true,
	columnPath: 'categories',
	generate: function () {
		return {
			inactive: false,
			comparisonType: 'Equals',
			value: []
		};
	},
	isInactive: function (filter: ListViewFilter) {
		return filter.inactive || !Array.isArray(filter.value) || !filter.value.length;
	},
	toUrl: function (filter: ListViewFilter) {
		return { v: filter.value, c: filter.comparisonType, i: filter.inactive };
	},
	fromUrl: function (rawFilter: MinifiedFilter) {
		const filter = this.generate();

		if (rawFilter.i) {
			filter.inactive = rawFilter.i;
		}
		if (rawFilter.c) {
			filter.comparisonType = rawFilter.c;
		}
		if (rawFilter.v) {
			filter.value = rawFilter.v;
		}
		return filter;
	},
	build: function (filter: ListViewFilter, rb: RequestBuilder, getField: (field: string) => { field: string }) {
		if (filter.inactive || !filter.value || !filter.value.length) {
			return;
		}

		const comparisonType = filter.comparisonType ? ComparisonTypes[filter.comparisonType] : ComparisonTypes.Equals;

		const groupFilter = rb.groupBuilder();
		groupFilter.addFilter(getField(this.field), comparisonType, filter.value);
		groupFilter.done();
	}
};

export default categoryFilterBaseConfig;

export const getCategoryConfigsFromState = (
	categoryTypes: CategoryType[],
	allCategories: Category[],
	type: string,
	prefix = '',
	overrides = {},
	defaultCategoryTitle = 'default.categories'
) => {
	const isUDO = type && type.indexOf('userDefined') !== -1;
	return [{ id: 0, name: '' }, ...categoryTypes].reduce((res, categoryType) => {
		const categories = allCategories.filter(
			category => (isUDO ? category.categoryTypeId : category.categoryType) === categoryType.id
		);
		const filterName = prefix + (categoryType.id ? 'Category_' + categoryType.id : 'Category');
		const filterTitle = categoryType.id === 0 ? defaultCategoryTitle : categoryType.name;

		const filterSpecificConfig = {
			parent: undefined,
			title: filterTitle,
			entity: type,
			resource: function ($q: any) {
				return (customerId: number, values: string[]) => {
					const res = categories.filter(function (category) {
						return values.map(value => parseInt(value)).indexOf(category.id) > -1;
					});

					return $q.when({ data: res });
				};
			},
			searchFn: function ($q: any) {
				return function (term: string, fields: any, offset: number, limit: number) {
					const res = categories.filter(function (category) {
						const categoryName = category.name ? category.name.toLowerCase() : '';
						const searchTerm = term ? term.toLowerCase() : '';

						return categoryName.indexOf(searchTerm) > -1;
					});

					return $q.when({
						data: _.sortBy(res.slice(offset, offset + limit), 'name'),
						metadata: {
							total: res.length,
							offset: offset,
							limit: limit
						}
					});
				};
			}
		};

		res[filterName] = Object.assign(filterSpecificConfig, categoryFilterBaseConfig, overrides);

		return res;
	}, {} as { [key: string]: any });
};
