import React, { useMemo } from 'react';
import './ReportcenterWidgetLegend.scss';
import bemClass from '@upsales/components/Utils/bemClass';
import { Text, Icon, Block, IconName } from '@upsales/components';
import { RCWidgetData } from 'Resources/ReportWidget';

type RCWidgetLegendIcon = {
	label?: string;
	color: string;
	name?: IconName;
	legend?: string;
	striped?: boolean;
	colorVariable?: string;
	backgroundColor?: string;
};

type Props = {
	colors: RCWidgetData['colors'];
	showGoal?: boolean;
	icons?: RCWidgetLegendIcon[];
};

type LegendIconProps = {
	icon: RCWidgetLegendIcon;
};

const fetchKeys = (colors: RCWidgetData['colors']) => {
	const standardLegends = ['progress', 'negativeProgress', 'goal', 'overGoal'].filter(legend =>
		Object.keys(colors ?? []).includes(legend)
	);
	const extraLegends = colors
		? Object.keys(colors)
				.filter(key => !standardLegends.includes(key))
				.sort((a, b) => (colors[b].sorting ?? 0) - (colors[a].sorting ?? 0)) // At the moment this is specifically for sorting stages by probability
		: [];

	return [...standardLegends, ...extraLegends];
};

const LegendIcon = ({ icon }: LegendIconProps) => {
	const classes = new bemClass('ReportcenterWidgetLegendIcon');
	const colorProp = icon.colorVariable ? { color: icon.colorVariable } : { style: { color: icon.color } };

	const renderIcon = () => {
		if (!icon.backgroundColor) {
			return <Icon name={icon.name || 'circle'} space="mrs" {...colorProp} striped={icon.striped} />;
		}
		return (
			<Block className={classes.elem('iconBackground').b()} space="mrs" backgroundColor={icon.backgroundColor}>
				<Icon name={icon.name || 'circle'} {...colorProp} striped={icon.striped} />
			</Block>
		);
	};

	return (
		<Block className={classes.mod({ lg: !!icon.name }).b()}>
			{renderIcon()}
			<Text size="md">{icon.label || icon.legend}</Text>
		</Block>
	);
};

const ReportcenterWidgetLegend = ({ colors, showGoal, icons }: Props) => {
	const colorKeys = useMemo(() => fetchKeys(colors), [colors]);

	const classes = new bemClass('ReportcenterWidgetLegend');
	return (
		<div className={classes.b()}>
			{icons?.length ? (
				icons.map((icon, i) => <LegendIcon key={`${icon.name}-${i}`} icon={icon} />)
			) : colors ? (
				colorKeys
					.filter(key => showGoal || !['goal', 'overGoal'].includes(key))
					.map(key => <LegendIcon key={key} icon={colors[key]} />)
			) : (
				<div />
			)}
		</div>
	);
};

export default ReportcenterWidgetLegend;
