import ContactResource from 'App/resources/Contact';
import type RequestBuilder from 'Resources/RequestBuilder';
import ValidationService from 'Services/ValidationService';
import { uniq } from 'lodash';
import type Contact from 'App/resources/Model/Contact';
import { openDrawer } from 'Services/Drawer';
import NotificationService from 'App/babel/NotificationService';
import ComparisonTypes from 'Resources/ComparisonTypes';
import { MailRecipient } from 'App/resources/Model/Mail';

export default function sendMail(rb: RequestBuilder) {
	rb.addFilter({ field: 'email' }, ComparisonTypes.NotEquals, null);
	rb.limit = 100;
	rb.fields = ['id', 'name', 'email', 'client'];

	return ContactResource.find(rb.build()).then((res: { data: Contact[] }) => {
		const filteredData = uniq(
			res.data.filter(contact => ValidationService.validEmail(contact.email)),
			contact => contact.email
		);

		if (filteredData.length) {
			if (Tools.FeatureHelper.hasSoftDeployAccess('NEW_MAIL')) {
				const contacts: MailRecipient[] = filteredData.map(recipient => ({
					id: recipient.id,
					name: recipient.name,
					email: recipient.email,
					client: recipient.client,
					type: 'contact'
				}));
				openDrawer('NewSingleMail', {
					recipients: {
						to: contacts,
						cc: [],
						bcc: []
					}
				});
			} else {
				return Tools.$upModal.open('sendEmail', {
					customerId: Tools.AppService.getCustomerId(),
					recipients: filteredData,
					type: 'mail'
				});
			}
		} else {
			NotificationService.add({
				icon: 'times',
				style: NotificationService.style.ERROR,
				title: 'activity.outcome.couldNotSendMAil',
				body: 'mail.noValidRecipient'
			});
		}
	});
}
