import moment from 'moment';

export const getSignature = () => `${Tools.AppService.getSelf().name} ${moment().format('L LT')}\n\n\n`;

export const focusSignatureTextArea = (textArea: HTMLTextAreaElement, signature: string = getSignature()) => {
	textArea.selectionStart = signature.length - 2;
	textArea.selectionEnd = signature.length - 2;
	textArea.focus();
};

export default (textArea: HTMLTextAreaElement, onChange: (notes: string) => void) => {
	if (!textArea) {
		return;
	}
	const inputName = getSignature();
	textArea.value = inputName + textArea.value;
	focusSignatureTextArea(textArea, inputName);
	onChange(textArea.value);
};
