import { Input } from '@upsales/components';
import BemClass from '@upsales/components/Utils/bemClass';
import React, { ComponentProps } from 'react';
import './ListViewQuickSearch.scss';
import { useTranslation } from 'Components/Helpers/translate';

type Props = ComponentProps<typeof Input>;

const ListViewQuickSearch = ({ className, ...props }: Props) => {
	const classes = new BemClass('ListViewQuickSearch', className);
	const { t } = useTranslation();

	return (
		<Input
			color="grey-2"
			noborder
			className={classes.b()}
			icon="search"
			placeholder={t('filters.quickSearch')}
			{...props}
		/>
	);
};

export default ListViewQuickSearch;
