import ClientContactFollowupSelect from 'App/components/Selectors/ClientContactFollowupSelect/ClientContactFollowupSelect';
import { Drawer, DrawerHeader, Input, Select, Block, Link, Loader, Tooltip, Flex } from '@upsales/components';
import { useSupportUsers } from 'App/components/hooks/appHooks';
import { useTranslation } from 'Components/Helpers/translate';
import { PrimaryButton, DefaultButton } from '@upsales/components/Buttons';
import { useSoftDeployAccess } from 'App/components/hooks';
import React, { useEffect, useRef } from 'react';
import BemClass from '@upsales/components/Utils/bemClass';
import { ModalProps, useModalClose } from 'App/components/Modals/Modals';
import { useEditTicketContext } from '../Context/Context';
import { FormComponent, FormInput } from 'App/components/FormComponent';
import { getSelf } from 'Store/selectors/AppSelectors';
import Client from 'App/resources/Model/Client';
import ContactResource from 'Resources/Contact';
import MailEditor from '../Common/MailEditor';
import PrioSelect from '../Common/PrioSelect';
import TypeSelect from '../Common/TypeSelect';
import openModal from 'App/services/Modal';
import { useSelector } from 'react-redux';
import { RootState } from 'Store/index';
import FormObserver, { FieldModel, getCustomFieldModel } from 'App/components/FormObserver';
import CustomFields from 'App/components/CustomFields';
import {
	type State,
	type TicketForm,
	mapFormToTicket,
	mapTicketToForm,
	formatErrorMessages,
	getMainRecipient
} from '../Context/Helpers';
import CcSelect from '../Common/CcSelect';
import MailAttachment from 'Components/MailDrawers/Components/MailAttachment';

import './CreateTicket.scss';

type Props = { client: Pick<Client, 'id' | 'name'> } & ModalProps;

const TITLE_MAX_LENGTH = 150;

const CreateTicket = ({ close, className, modalId }: Props) => {
	const {
		state: {
			ticket,
			saving,
			loading,
			files,
			customFields,
			formStateResetTrigger,
			publicMessage,
			errorMessages,
			hasUnsavedChanges,
			ticketResponseTemplate
		},
		save,
		init,
		removeFile,
		onTicketChange,
		setTicketResponseTemplate
	} = useEditTicketContext();
	const { user } = ticket ?? {};

	const users = useSupportUsers('active');
	const { t } = useTranslation();

	const hasTicketCC = useSoftDeployAccess('TICKET_CC');

	const self = getSelf();
	const isSupportUser = self?.support;
	const { metadata } = useSelector((state: RootState) => state.App);
	const hasPriority = metadata?.params?.TicketPriority;

	useEffect(() => {
		init();
	}, []);

	const clientContactSelectInputRef = useRef<HTMLInputElement>();
	const contactEmailInputRef = useRef<HTMLInputElement | null>(null);
	const titleInputRef = useRef<HTMLInputElement | null>(null);
	const assigneeInputRef = useRef<HTMLInputElement>(null);
	const typeInputRef = useRef<HTMLInputElement>(null);

	const anchor = document.getElementsByClassName('CreateTicket')[0];
	const classes = new BemClass('CreateTicket', className);

	const mainRecipient = getMainRecipient(ticket.involved);
	const showEmailField = !mainRecipient?.contact?.email;

	useModalClose(
		modalId,
		e => {
			if (hasUnsavedChanges || publicMessage.length > 0) {
				e.preventDefault();
				openModal('UnsavedChangesAlert', {
					confirmButtonText: t('default.goBack'),
					onClose: async (confirmed?: boolean) => {
						if (confirmed || confirmed === undefined) {
							return;
						}
						close(undefined, true);
					}
				});
			}
		},
		[hasUnsavedChanges, publicMessage.length]
	);

	const header = (
		<DrawerHeader onHide={close} title={t('ticket.createTicket')} icon="customer-support"></DrawerHeader>
	);

	if (loading) {
		return (
			<Drawer className={classes.b()}>
				{header}
				<div className={classes.elem('loader').b()}>
					<Loader />
				</div>
			</Drawer>
		);
	}

	const validationModel = {
		contactInfo: FieldModel.object('contactInfo', {
			client: FieldModel.object('default.client', {
				id: FieldModel.number('default.id').required(),
				name: FieldModel.string('default.name').required()
			}),
			contact: FieldModel.object('default.contact', {
				id: FieldModel.number('default.id').required(),
				name: FieldModel.string('default.name').required()
			}),
			email: showEmailField ? FieldModel.email('default.email').required() : FieldModel.email('default.email')
		}),
		title: FieldModel.string('default.title').required(),
		publicMessage: FieldModel.string('default.description').required(),
		emailUsed: FieldModel.email('default.emailUsed').required(),
		custom: getCustomFieldModel(customFields)
	};

	const initialValues = mapTicketToForm(ticket, customFields);

	return (
		<Drawer className={classes.b()}>
			{header}
			<FormObserver<TicketForm>
				onlyValidateTouched
				initialIsValid={false}
				onChange={(values, isValid, errorMessages) => {
					const updatedTicket = mapFormToTicket(values);
					onTicketChange(updatedTicket, isValid, errorMessages);
				}}
				onSubmit={(values, initialValues, omitPublicReply = false) => {
					if (mainRecipient) {
						const { contact, email } = mainRecipient;
						if (contact && !contact.email && email) {
							ContactResource.save({ id: contact.id, email });
						}
					}

					return save(omitPublicReply).then(() => close(undefined, true));
				}}
				skipInitialOnChange
				model={validationModel}
				initialValues={initialValues}
				resetStateIfValueChange={formStateResetTrigger}
			>
				{({ onFormChange, inputProps, errors, isValid, submit, values }) => {
					const recipient = values.contactInfo.contact || values.contactInfo.email;
					const name = values.contactInfo.contact?.name || values.contactInfo.email;
					return (
						<Flex direction="column" className={classes.elem('content').b()} gap="u5">
							<Flex direction="column" gap="u4">
								<FormComponent
									label={t('ticket.whoHasTheIssue')}
									space="mll mrl ptxl"
									required={false}
									hideMessage
								>
									<ClientContactFollowupSelect
										clientInputRef={r => (clientContactSelectInputRef.current = r)}
										onChange={({ client, contact }) => {
											// Re-focus field if it was cleared
											if (!client) {
												setTimeout(() => clientContactSelectInputRef.current?.focus());
											} else if (contact && !contact.email) {
												// Set focus on email field if selected contact is missing email
												setTimeout(() => contactEmailInputRef.current?.focus());
											} else {
												// Focus assignee field
												setTimeout(() => assigneeInputRef.current?.focus());
											}
											onFormChange('contactInfo', {
												client: client?.id ? client : null,
												contact: contact?.id ? contact : null,
												email:
													contact?.id && client?.id
														? contact.email ?? ''
														: client?.id
														? values.contactInfo.email
														: null
											});
										}}
										contact={values.contactInfo.contact}
										client={values.contactInfo.client}
										saving={saving}
										autofocus
									/>
								</FormComponent>

								{showEmailField ? (
									<FormComponent space="mrl mll" label={t('ticket.email')} required hideMessage>
										<Input
											type="email"
											inputRef={r => (contactEmailInputRef.current = r)}
											value={values.contactInfo.email}
											data-1p-ignore
											placeholder={t('default.enterEmail')}
											state={errors.email ? 'error' : undefined}
											onChange={e => {
												onFormChange('contactInfo', {
													email: e.target.value,
													contact: values.contactInfo.contact ?? null,
													client: values.contactInfo.client ?? null
												});
											}}
										/>
									</FormComponent>
								) : null}

								{hasTicketCC ? (
									<FormComponent space="mrl mll" label={t('mail.ccShort')} hideMessage>
										<CcSelect value={values.cc} onChange={values => onFormChange('cc', values)} />
									</FormComponent>
								) : null}

								{self ? (
									<FormComponent
										space="mrl mll"
										label={t('ticket.assignee')}
										labelRenderRight={
											isSupportUser
												? () => (
														<Link
															onClick={() =>
																onFormChange('user', { id: self.id, name: self.name })
															}
														>
															{t('ticket.assignMe')}
														</Link>
												  )
												: undefined
										}
										hideMessage
									>
										<Select<{ id: number; title: string }>
											options={users.map(user => ({ id: user.id, title: user.name }))}
											inputRef={assigneeInputRef}
											onChange={value => {
												onFormChange(
													'user',
													value ? { id: value.id, name: value.title } : null
												);
											}}
											placeholder={t('ticket.chooseAssignee')}
											anchor={anchor}
											value={user ? { ...user, title: user.name } : null}
											openOnFocus
										/>
									</FormComponent>
								) : null}

								{hasPriority ? (
									<FormComponent space="mrl mll" label={t('ticket.priority')} hideMessage>
										<PrioSelect onChange={value => onFormChange('priority', value)} />
									</FormComponent>
								) : null}

								<FormComponent space="mrl mll" label={t('default.type')} hideMessage>
									<TypeSelect
										inputRef={typeInputRef}
										anchor={anchor}
										onChange={value => onFormChange('type', value)}
									/>
								</FormComponent>
							</Flex>
							{customFields.length ? (
								<>
									<hr />
									<FormComponent label={t('default.customFields')} space="mll mrl" hideMessage>
										<CustomFields
											type="ticket"
											inputProps={inputProps}
											onlyShowIds={customFields.map(cf => cf.id)}
											onChange={(id: number, value: string) => {
												onFormChange(`custom.Custom_${id}`, value);
											}}
										/>
									</FormComponent>
								</>
							) : null}
							<hr />
							<Flex direction="column" gap="u4">
								<FormInput
									space={'mrl mll'}
									label={t('ticket.title')}
									inputProps={{
										placeholder: t('ticket.enterTitle'),
										inputRef: titleInputRef,
										required: true,
										value: values.title ?? '',
										maxLength: TITLE_MAX_LENGTH,
										state: errors.title ? 'error' : undefined,
										onChange: e => onFormChange('title', e.target.value),
										label: t('ticket.title'),
										name: 'title'
									}}
									hideMessage
								/>

								<FormComponent space="mrl mll" label={t('ticket.description')} required hideMessage>
									{loading ? null : (
										<>
											<MailEditor
												extra={{ height: 320 }}
												onLeave={() => close()}
												canChangeEmail
												onFormChange={onFormChange}
												ticketResponseTemplate={ticketResponseTemplate}
												setTicketResponseTemplate={setTicketResponseTemplate}
												newTicket={true}
											/>
											<Flex wrap="wrap">
												{(files ?? []).map((attachment, idx) => (
													<MailAttachment
														key={attachment.id}
														clickable={false}
														onRemove={a => removeFile(a as State['files'][0])}
														attachment={attachment}
													/>
												))}
											</Flex>
										</>
									)}
								</FormComponent>
							</Flex>
							<Block
								space="pll pbl prl ptl"
								backgroundColor="white"
								className={classes.elem('createTicket').b()}
							>
								<Tooltip disabled={isValid} title={formatErrorMessages(errorMessages)}>
									<PrimaryButton
										block
										ellipsis
										disabled={!isValid || saving}
										onClick={() => submit()}
										size="lg"
										loading={saving}
									>
										{recipient
											? t('ticket.createAndSendConfirmation', {
													name
											  })
											: t('ticket.createTicket')}
									</PrimaryButton>
									<Block space="mtm">
										<DefaultButton
											block
											ellipsis
											disabled={!isValid || saving}
											onClick={() => submit(true)}
											size="lg"
											loading={saving}
										>
											{recipient
												? t('ticket.createWithOutConfirmationTo', {
														name
												  })
												: t('ticket.createWithOutConfirmation')}
										</DefaultButton>
									</Block>
								</Tooltip>
							</Block>
						</Flex>
					);
				}}
			</FormObserver>
		</Drawer>
	);
};

export default CreateTicket;
