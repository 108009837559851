import Resource from './Resource';
class GenericQuota extends Resource {
	constructor() {
		super('genericQuotas');
	}

	async saveMultipleOngoing(data) {
		return this._putRequest('', data, { methodName: 'setMultipleOngoing' }).then(res => {
			return res.data;
		});
	}

	async canEditClientQuota(clientId) {
		return this._getRequest(`canEditClientQuota/${clientId}`).then(({ data }) => data);
	}
}
export default new GenericQuota();
