export const SET_SEGMENTS = '[GroupMailEditor] Set segments';
export const SET_SEGMENT = '[GroupMailEditor] Set segment';
export const SET_CAMPAIGNS = '[GroupMailEditor] Set campaigns';
export const SET_CONTACT_STATS = '[GroupMailEditor] Set contact stats';
export const DELETE_CONTACT_STATS = '[GroupMailEditor] Delete contact stats';
export const RESET = '[GroupMailEditor] Reset';
export const SET_SELECTED_TAB = '[GroupMailEditor] Set selected tab';
export const SET_GROUP_MAIL = '[GroupMailEditor] Set group mail';
export const SET_LOADING = '[GroupMailEditor] Set loading';
export const SET_SAVING = '[GroupMailEditor] Set saving';
export const SET_SENDING = '[GroupMailEditor] Set sending';
export const SET_ACCOUNT_PROFILE = '[GroupMailEditor] Set account profile';
export const SET_STYLE = '[GroupMailEditor] Set style';
export const SET_SELECTED_CONTENT = '[GroupMailEditor] Set selected content';
export const SET_ACTIVE_CONFIRM = '[GroupMailEditor] Set active confirm';
export const SET_CONFIRM_LOADING = '[GroupMailEditor] Set confirm loading';
export const SET_CONFIG = '[GroupMailEditor] Set config';
export const SET_LOADING_PREVIEW_BROWSER = '[GroupMailEditor] Set loading preview browser';
export const SET_LOAD_ERROR = '[GroupMailEditor] Set load error';
export const SET_LOADING_MINI_PREVIEW = '[GroupMailEditor] Set loading mini preview';
export const SET_FORM_ERROR = '[GroupMailEditor] Set form error';
export const SET_MAIL_ACCOUNT = '[GroupMailEditor] Set mail account';
export const SET_CATEGORIES = '[GroupMailEditor] Set categories';
export const SET_SIZE_OF_ALL_FILES = '[GroupMailEditor] Set size of all files';
export const SET_CONTACT_STATS_LOADING = '[GroupMailEditor] Set contact stats loading';
export const SET_SEGMENTS_PROJECTS_LOADING = '[GroupMailEditor] Set segments projects loading';
export const SET_PREVIEW_TEXT = '[GroupMailEditor] Set preview text';
export const SET_CHANGES = '[GroupMailEditor] Set changes';
export const SET_CURRENT_CHANGE = '[GroupMailEditor] Set current change';
export const SET_SOCIAL_EVENT = '[GroupMailEditor] Set social event';
export const SET_DOMAINS = '[GroupMailEditor] Set domains';
export const SET_EXCLUDE_RECENTLY_EMAILED = '[GroupMailEditor] Set exclude recently emailed';

export const TABS = {
	design: 'design',
	recipients: 'recipients',
	settingsAndSend: 'settingsAndSend'
};

export const CONTENT = {
	selectTemplate: {
		name: 'selectTemplate',
		tab: TABS.design
	},
	editor: {
		name: 'editor',
		tab: TABS.design
	},
	recipients: {
		name: 'recipients',
		tab: TABS.recipients
	},
	settingsAndSend: {
		name: 'settingsAndSend',
		tab: TABS.settingsAndSend
	}
};

export const initialState = {
	groupMail: {},
	excludeRecentlyEmailed: false,
	previewText: '', // we put this here until we can add it to groupMail data model
	accountProfile: {},
	style: '',
	loading: true,
	saving: false,
	sending: false,
	selectedTab: TABS.design,
	selectedContent: CONTENT.selectTemplate.name,
	recipient: {
		segments: [],
		campaigns: [],
		contactStats: null,
		contactStatsLoading: true
	},
	activeConfirm: null,
	confirmLoading: false,
	config: null,
	loadingPreviewBrowser: false,
	loadError: null,
	loadingMiniPreview: false,
	formError: [],
	mailAccount: {},
	categories: [],
	sizeOfAllFiles: 0,
	segmentsProjectsLoading: true,
	changes: [],
	currentChange: -1,
	socialEvent: null,
	domains: {}
};

const ACTION_HANDLERS = {
	[RESET]: () => ({ ...initialState }),
	[SET_SELECTED_TAB]: (state, { selectedTab }) => ({ ...state, selectedTab }),
	[SET_GROUP_MAIL]: (state, { groupMail }) => ({ ...state, groupMail }),
	[SET_LOADING]: (state, { loading }) => ({ ...state, loading }),
	[SET_SAVING]: (state, { saving }) => ({ ...state, saving }),
	[SET_SENDING]: (state, { sending }) => ({ ...state, sending }),
	[SET_ACCOUNT_PROFILE]: (state, { accountProfile }) => ({ ...state, accountProfile }),
	[SET_SEGMENTS]: (state, { segments }) => ({ ...state, recipient: { ...state.recipient, segments } }),
	[SET_STYLE]: (state, { style }) => ({ ...state, style }),
	[SET_CAMPAIGNS]: (state, { campaigns }) => ({ ...state, recipient: { ...state.recipient, campaigns } }),
	[DELETE_CONTACT_STATS]: (state, { contactStats }) => ({
		...state,
		recipient: { ...state.recipient, contactStats }
	}),
	[SET_CONTACT_STATS]: (state, { contactStats }) => ({ ...state, recipient: { ...state.recipient, contactStats } }),
	[SET_SELECTED_CONTENT]: (state, { selectedContent }) => ({ ...state, selectedContent }),
	[SET_ACTIVE_CONFIRM]: (state, { activeConfirm }) => ({ ...state, activeConfirm, confirmLoading: false }),
	[SET_CONFIRM_LOADING]: (state, { confirmLoading }) => ({ ...state, confirmLoading }),
	[SET_CONFIG]: (state, { config }) => ({ ...state, config }),
	[SET_LOAD_ERROR]: (state, { loadError }) => ({ ...state, loadError }),
	[SET_LOADING_PREVIEW_BROWSER]: (state, { loadingPreviewBrowser }) => ({ ...state, loadingPreviewBrowser }),
	[SET_LOADING_MINI_PREVIEW]: (state, { loadingMiniPreview }) => ({ ...state, loadingMiniPreview }),
	[SET_FORM_ERROR]: (state, { formError }) => ({ ...state, formError }),
	[SET_MAIL_ACCOUNT]: (state, { mailAccount }) => ({ ...state, mailAccount }),
	[SET_CATEGORIES]: (state, { categories }) => ({ ...state, categories }),
	[SET_SIZE_OF_ALL_FILES]: (state, { sizeOfAllFiles }) => ({ ...state, sizeOfAllFiles }),
	[SET_SEGMENTS_PROJECTS_LOADING]: (state, { segmentsProjectsLoading }) => ({ ...state, segmentsProjectsLoading }),
	[SET_PREVIEW_TEXT]: (state, { previewText }) => ({ ...state, previewText }),
	[SET_CHANGES]: (state, { changes }) => ({ ...state, changes }),
	[SET_CURRENT_CHANGE]: (state, { currentChange }) => ({ ...state, currentChange }),
	[SET_SOCIAL_EVENT]: (state, { socialEvent }) => ({ ...state, socialEvent }),
	[SET_DOMAINS]: (state, { domains }) => ({ ...state, domains }),
	[SET_EXCLUDE_RECENTLY_EMAILED]: (state, { excludeRecentlyEmailed }) => ({ ...state, excludeRecentlyEmailed }),
	[SET_CONTACT_STATS_LOADING]: (state, { contactStatsLoading }) => ({
		...state,
		recipient: { ...state.recipient, contactStatsLoading }
	})
};

export default (state = { ...initialState }, action) => {
	const handler = ACTION_HANDLERS[action.type];
	return handler ? handler(state, action) : state;
};
