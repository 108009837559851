import React from 'react';
import BemClass from '@upsales/components/Utils/bemClass';
import Relation from 'App/resources/Model/EntityRelation';
import { Card, ClickableItem, Icon, Tooltip } from '@upsales/components';
import './SelectedRelations.scss';
import { openDrawer } from 'Services/Drawer';
import { useSoftDeployAccess } from 'App/components/hooks';
import { useTranslation } from 'Components/Helpers/translate';

type Props = {
	relations: Relation[];
	removeRelation: (rel: Relation) => void;
};

const SelectedRelations = ({ relations, removeRelation }: Props) => {
	const classes = new BemClass('SelectedRelations');
	const { t } = useTranslation();
	const hasRemoveActivites = useSoftDeployAccess('REMOVE_ACTIVITIES');
	const hasTodoList = useSoftDeployAccess('TODO_LIST');

	const goToRelation = (relation: Relation) => {
		switch (relation.type) {
			case 'opportunity':
				Tools.$upModal.open('editOrder', { id: relation.id });
				break;
			case 'appointment':
				Tools.$upModal.open('editAppointment', { id: relation.id });
				break;
			case 'activity':
				if (relation.activityType === 'phonecall') {
					openDrawer('EditPhonecall', { activity: { id: relation.id } });
				} else if (relation.activityType === 'todo' && hasTodoList) {
					openDrawer('EditTodo', { todo: { id: relation.id } });
				} else {
					if (!hasRemoveActivites) {
						Tools.$upModal.open('editActivity', { id: relation.id });
					} else {
						openDrawer('EditPhonecall', { activity: { id: relation.id } });
					}
				}
				break;
			default:
				break;
		}
	};

	return (
		<Card className={classes.b()} borderRadius>
			{relations.map(relation => (
				<ClickableItem
					block
					size="lg"
					key={`${relation.type}-${relation.id}`}
					title={relation.title}
					titleTooltip={`${t('relation.view')} ${t(
						'default.' + (relation.activityType ?? relation.type)
					).toLowerCase()}`}
					subtitle={relation.subtitle}
					icon={relation.icon}
					className={classes.elem('row').b()}
					renderRight={() => (
						<Tooltip title={t('client.removeRelation')}>
							<Icon
								name="times"
								onClick={e => {
									e.stopPropagation();
									removeRelation(relation);
								}}
							/>
						</Tooltip>
					)}
					onClick={() => goToRelation(relation)}
				/>
			))}
		</Card>
	);
};

export default SelectedRelations;
