import React, { useMemo, useState } from 'react';
import bemClass from '@upsales/components/Utils/bemClass';
import { filterPresets } from '../../../reportCenterHelpers';
import T from 'Components/Helpers/translate';
import {
	FilterMap,
	Option,
	staticToRollingFiltersMap,
	fiscalToRollingFiltersMap,
	fiscalFiltersMap,
	formatPeriod,
	getDateSections,
	getValuesFromOptionMap
} from './ReportcenterFilterDateHelpers';

import { Row, DateInput, Text, Toggle, ButtonSelect } from '@upsales/components';
import './ReportcenterFilterDateSelect.scss';
import { RCDFilterCustomDate } from 'Resources/ReportDashboard';
import _ from 'lodash';
import Options from './ReportcenterFilterDateSelectOptions';
import moment from 'moment';

const presets = filterPresets.date.presets;

type ReportcenterFilterDateSelectProps = {
	onChange: (type: string, value: string | object | null) => void;
	value: string;
	customDate?: RCDFilterCustomDate;
	isDescription?: boolean;
	disableFutureDates?: boolean;
};
const ReportcenterFilterDateSelect = ({
	onChange,
	value,
	customDate,
	disableFutureDates
}: ReportcenterFilterDateSelectProps) => {
	const classes = new bemClass('ReportcenterFilterDateSelect');
	const { brokenFiscalYearEnabled, brokenFiscalYearOffset } = Tools.AppService.getMetadata().params;
	const [showFiscalYear, setShowFiscalYear] = useState<boolean>(
		brokenFiscalYearEnabled && (value?.includes('Fiscal') || value === '')
	);
	const [rollingToStaticFiltersMap, setRollingToStaticFiltersMap] = useState<FilterMap>(() =>
		_.invert(staticToRollingFiltersMap)
	);
	const [rollingToFiscalFiltersMap, setRollingToFiscalFiltersMap] = useState<FilterMap>(() =>
		_.invert(fiscalToRollingFiltersMap)
	);
	const dateSections = getDateSections(showFiscalYear);
	const [formattedStart, formattedEnd] = formatPeriod(value, brokenFiscalYearEnabled ? brokenFiscalYearOffset : 0);

	const onChangeDateInput = (newValue: Date | null, startOrEnd: string) => {
		if (newValue) {
			newValue =
				startOrEnd === 'start'
					? moment(newValue).startOf('day').toDate()
					: moment(newValue).endOf('day').toDate();
		}
		if (value !== 'Custom') {
			customDate = { start: formattedStart || null, end: formattedEnd || null };
		}
		onChange('CustomDate', {
			...customDate,
			[startOrEnd]: newValue
		});
		onChange('Date', 'Custom');
	};

	const getRollingIntervalOptions = () => {
		const options = new Map();
		options.set(
			T('date.week'),
			presets.filter(
				preset =>
					preset.type.includes('rolling') &&
					preset.type.includes('week') &&
					(!disableFutureDates || (disableFutureDates && !preset.type.includes('upcoming')))
			)
		);
		options.set(
			T('date.month'),
			presets.filter(
				preset =>
					preset.type.includes('rolling') &&
					preset.type.includes('month') &&
					(!disableFutureDates || (disableFutureDates && !preset.type.includes('upcoming')))
			)
		);
		options.set(
			T('date.quarter'),
			presets.filter(
				preset =>
					preset.type.includes('rolling') &&
					preset.type.includes('quarter') &&
					(!disableFutureDates || (disableFutureDates && !preset.type.includes('upcoming')))
			)
		);
		options.set(
			T('date.halfyear'),
			presets.filter(
				preset =>
					preset.type.includes('rolling') &&
					preset.type.includes('halfyear') &&
					(!disableFutureDates || (disableFutureDates && !preset.type.includes('upcoming')))
			)
		);
		options.set(
			T('date.year'),
			presets.filter(
				preset =>
					preset.type.includes('rolling') &&
					preset.type.includes('year') &&
					(!disableFutureDates || (disableFutureDates && !preset.type.includes('upcoming')))
			)
		);
		return options;
	};

	const [intervalType, setIntervalType] = useState(
		getValuesFromOptionMap(getRollingIntervalOptions()).includes(value) ? 'rollingInterval' : 'staticInterval'
	);

	const getStaticOptions = () => {
		const options = new Map();
		const relevantPresets = presets.filter(
			preset =>
				preset.type.includes('static') &&
				preset.type.includes(showFiscalYear ? 'fiscal' : 'calendar') &&
				(!disableFutureDates ||
					(disableFutureDates && (!preset.type.includes('upcoming') || value?.includes('Next'))))
		);

		dateSections.forEach(dateSection => {
			const periodPresets = relevantPresets.filter(preset => preset.type.includes(dateSection.type));
			options.set(dateSection.value, periodPresets);
		});
		return options;
	};
	const getOptions = () => (intervalType === 'staticInterval' ? getStaticOptions() : getRollingIntervalOptions());

	const options: Map<string, Option[]> = useMemo(() => getOptions(), [intervalType, showFiscalYear, value]);

	const onShowFiscalYear = () => {
		setShowFiscalYear(!showFiscalYear);
		if (fiscalFiltersMap[value]) {
			onChange('Date', fiscalFiltersMap[value]);
		}
	};

	function onChangeIntervalType(val: string): void {
		const nextValue =
			intervalType === 'rollingInterval'
				? showFiscalYear
					? rollingToFiscalFiltersMap[value]
					: rollingToStaticFiltersMap[value]
				: showFiscalYear
				? fiscalToRollingFiltersMap[value]
				: staticToRollingFiltersMap[value];

		if (val === 'rollingInterval') {
			setRollingToStaticFiltersMap({ ...rollingToStaticFiltersMap, [nextValue]: value });
			setRollingToFiscalFiltersMap({ ...rollingToFiscalFiltersMap, [nextValue]: value });
		}
		if (value) onChange('Date', nextValue);
		setIntervalType(val);
	}

	return (
		<div className={classes.b()}>
			<Row>
				<DateInput
					onChange={e => onChangeDateInput(e.target.value, 'start')}
					value={customDate?.start ?? formattedStart}
					icon="calendar"
					placeholder={T('reportcenter.filter.startDatePlaceholder')}
					disabled={intervalType === 'rollingInterval'}
				/>
				<DateInput
					onChange={e => onChangeDateInput(e.target.value, 'end')}
					value={customDate?.end ?? formattedEnd}
					icon="calendar"
					placeholder={T('reportcenter.filter.endDatePlaceholder')}
					disabled={intervalType === 'rollingInterval'}
				/>
				{brokenFiscalYearEnabled && intervalType === 'staticInterval' ? (
					<div className={classes.elem('fiscal-year-toggle').b()}>
						<Toggle onChange={() => onShowFiscalYear()} checked={showFiscalYear} />
						<Text>{T('reportcenter.filter.useFiscalYear')}</Text>
					</div>
				) : null}
			</Row>
			<Row>
				<ButtonSelect
					size="sm"
					onChange={val => onChangeIntervalType(val)}
					options={[
						{
							title: T('date.staticInterval'),
							value: 'staticInterval'
						},
						{
							title: T('date.rollingInterval'),
							value: 'rollingInterval'
						}
					]}
					value={intervalType}
				/>
			</Row>
			<Row>
				<Options options={options} classes={classes} onChange={onChange} value={value} />
			</Row>
		</div>
	);
};

export default ReportcenterFilterDateSelect;
