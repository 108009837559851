import BemClass from '@upsales/components/Utils/bemClass';
import { Text, Icon } from '@upsales/components';
import T from 'Components/Helpers/translate';
import React from 'react';

import './InfoBox.scss';

const InfoBox = ({ numberOfAgreements }: { numberOfAgreements: number }) => {
	const classes = new BemClass('InfoBox');

	return (
		<div className={classes.b()}>
			<Icon name="info-circle" color="blue" />
			<Text color="blue">{T('arrchange.drawer.numberOfChanges', { count: numberOfAgreements })}</Text>
		</div>
	);
};

export default InfoBox;
