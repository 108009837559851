import React, { useMemo, useState } from 'react';
import BemClass from '@upsales/components/Utils/bemClass';
import {
	Icon,
	Text,
	Avatar,
	Card,
	Tooltip,
	Table,
	TableHeader,
	TableHeaderColumn,
	TableRow,
	TableColumn,
	Link,
	Row
} from '@upsales/components';
import { SlideFade } from 'App/components/animations';
import T from 'Components/Helpers/translate';
import moment from 'moment';
import MailType, { Event } from 'App/resources/Model/Mail';
import RecipientsDropDown from './RecipientsDropDown';
import { useSelector } from 'react-redux';
import { RootState } from 'Store/index';

import './MailHeader.scss';

type MailFromSelfProps = {
	from: { name: string; email: string; type: string; id?: number };
	recipients?: MailType['recipients'];
};
const MailFromUpsalesUser = ({ from, recipients }: MailFromSelfProps) => {
	const classes = new BemClass('MailFromUpsalesUser');
	const self = useSelector(({ App }: RootState) => App.self);
	const fromSelf = from.id === self?.id;

	return from ? (
		<div className={classes.elem('fromSelf').b()}>
			<Avatar initials={from.name} email={from.email} />
			<div className={classes.elem('fromSelf').elem('recipientsContainer').b()}>
				{fromSelf ? (
					<>
						<Text className={classes.elem('fromSelf').elem('you').b()} bold>
							{T('you')}
						</Text>
						<Text className={classes.elem('fromSelf').elem('mail').b()} color="grey-10">
							{'(' + (from.name || from.email) + ')'}
						</Text>
					</>
				) : (
					<Text bold>{from.name || from.email}</Text>
				)}
				<RecipientsDropDown recipients={recipients} from={from} />
			</div>
		</div>
	) : null;
};

type MailFromContactProps = {
	from: { name: string; email: string; type: string; id?: number };
	contact?: MailType['contact'];
	recipients?: MailType['recipients'];
	close: () => void;
};
const MailFromContact = ({ from, close, recipients }: MailFromContactProps) => {
	const classes = new BemClass('MailFromContact');

	const openContact = (id: number) => {
		close();
		Tools.$state.go('contact.dashboard', { customerId: Tools.AppService.getCustomerId(), id });
	};

	return (
		<div className={classes.elem('from').elem('left').b()}>
			<Icon color="grey-10" name="user" />
			<div>
				{from.id ? (
					<Tooltip title={T('mailDrawer.showContact')}>
						<Link
							onClick={e => {
								e.stopPropagation();
								if (from.id) {
									openContact(from.id);
								}
							}}
						>
							{from.name || from.email}
						</Link>
					</Tooltip>
				) : null}
				<RecipientsDropDown recipients={recipients} from={from} />
			</div>
		</div>
	);
};

type MailStatusHeaderProps = {
	date: MailType['date'];
	expanded: boolean;
	attachments?: MailType['attachments'];
	onClick: () => void;
	events?: Event[];
	expandedEvents: boolean;
	setExpandedEvents: (expanded: boolean) => void;
};

const MailStatusHeader: React.FC<MailStatusHeaderProps> = ({
	date,
	expanded,
	attachments = [],
	events = [],
	onClick,
	expandedEvents,
	setExpandedEvents
}) => {
	const classes = new BemClass('MailStatusHeader');

	const attachmentLength = attachments.length;
	const readEvent = (events ?? []).find(e => e.type === 'open');
	const clickEvent = (events ?? []).find(e => e.type === 'click');

	const readTooltip = readEvent
		? `${T('event.mailWasRead')}: ${moment(readEvent.date).format('YYYY-MM-DD HH:mm')}\n${T(
				'event.mail.clickForAll'
		  )}`
		: '';
	const clickTooltip = clickEvent
		? `${T('clicked_link_email')}: ${moment(clickEvent.date).format('YYYY-MM-DD HH:mm')}\n${T(
				'event.mail.clickForAll'
		  )}`
		: '';
	const attachmentTooltip = attachments.map(att => att.filename).join('\n');

	return (
		<div
			className={classes.elem('from').elem('right').b()}
			onClick={e => {
				if (expanded && expandedEvents) {
					setExpandedEvents(!expandedEvents);
				}
				onClick();
			}}
		>
			<div className={classes.elem('attachments-container').b()} onClick={e => e.stopPropagation()}>
				{attachmentLength ? (
					<Tooltip title={attachmentTooltip}>
						<Card className={classes.elem('icons').mod({ attachments: true }).b()}>
							<Icon color="bright-blue" name="paperclip" />
							<Text color="bright-blue">{attachmentLength}</Text>
						</Card>
					</Tooltip>
				) : null}
			</div>
			<div
				className={classes.elem('events-container').b()}
				onClick={e => {
					if (expanded && (readEvent || clickEvent)) {
						setExpandedEvents(!expandedEvents);
						e.stopPropagation();
					}
				}}
			>
				<Tooltip title={readTooltip} disabled={!readEvent}>
					<Card className={classes.elem('icons').mod({ exists: !!readEvent, read: true }).b()}>
						{readEvent ? (
							<Icon color="bright-blue" name="eye" />
						) : (
							<Icon color="grey-10" name="eye-slash" />
						)}
					</Card>
				</Tooltip>
				<Tooltip title={clickTooltip} disabled={!clickEvent}>
					<Card className={classes.elem('icons').mod({ exists: !!clickEvent, click: true }).b()}>
						{clickEvent ? (
							<Icon color="bright-blue" name="mouse-pointer" />
						) : (
							<Icon color="grey-10" name="mouse-pointer" />
						)}
					</Card>
				</Tooltip>
			</div>
			<Text color="grey-11">{moment(date).format('L LT')}</Text>
			<Icon
				onClick={onClick}
				name="angle-down"
				className={classes.elem('from').elem('icon').mod({ rotate: expanded }).b()}
			/>
		</div>
	);
};

type EventRowProps = {
	event: Event;
};

const EventRow: React.FC<EventRowProps> = ({ event }) => {
	switch (event.type) {
		case 'open': {
			return (
				<Row>
					<Icon name="eye" />
					<Text>{T('mail.mailEvent.read')}</Text>
				</Row>
			);
		}
		case 'click': {
			return (
				<Row>
					<Icon name="mouse-pointer" />
					<Text>
						{T('mail.mailEvent.click')}:{' '}
						<Link target="_blank" href={event.value}>
							{event.value}
						</Link>
					</Text>
				</Row>
			);
		}
		default:
			return (
				<Row>
					<Text>{T('event.unknown')}</Text>
				</Row>
			);
	}
};

const EventDropdown = ({ events }: { events: Event[] }) => {
	const classes = new BemClass('MailStatusHeader');

	return (
		<div>
			<Table className={classes.elem('events').b()}>
				<TableHeader className={classes.elem('events').elem('header').b()}>
					<TableHeaderColumn>{T('default.dateAndTime')}</TableHeaderColumn>
					<TableHeaderColumn>{T('default.event')}</TableHeaderColumn>
				</TableHeader>
				{events.map((event, i) => (
					<TableRow key={event.id ?? i}>
						<TableColumn>{moment(event.date).format('YYYY-MM-DD HH:mm')}</TableColumn>
						<TableColumn>{EventRow({ event })}</TableColumn>
					</TableRow>
				))}
			</Table>
		</div>
	);
};

type MailHeaderProps = {
	from: { name: string; email: string; type: string; id?: number };
	date: MailType['date'];
	recipients?: MailType['recipients'];
	events?: Event[];
	attachments: MailType['attachments'];
	onClick: () => void;
	expanded: boolean;
	close: () => void;
};

const MailHeader = ({ date, from, recipients, onClick, expanded, attachments, events, close }: MailHeaderProps) => {
	const openAndClickEvents = useMemo(
		() => (events?.length ? events.filter(event => ['click', 'open'].indexOf(event.type) >= 0) : []),
		[events]
	);
	const [expandedEvents, setExpandedEvents] = useState(false);
	const maxHeight = 32.5 + 40 * openAndClickEvents.length;
	const classes = new BemClass('MailHeader');

	return (
		<div className={classes.b()}>
			<div className={classes.elem('from').b()}>
				{from.type === 'user' ? (
					<MailFromUpsalesUser from={from} recipients={recipients} />
				) : (
					<MailFromContact from={from} close={close} recipients={recipients} />
				)}
				<MailStatusHeader
					date={date}
					expanded={expanded}
					attachments={attachments}
					events={events}
					onClick={onClick}
					expandedEvents={expandedEvents}
					setExpandedEvents={setExpandedEvents}
				></MailStatusHeader>
			</div>
			<SlideFade speed="normal" direction="top" visible={expanded && expandedEvents} maxHeight={maxHeight} height>
				{EventDropdown({ events: openAndClickEvents })}
			</SlideFade>
		</div>
	);
};

export default MailHeader;
