import { RouteComponentProps, withRouter } from 'react-router-dom';
import React, { MutableRefObject, useMemo } from 'react';
import { ConnectedProps, connect } from 'react-redux';
import Page from 'App/pages/Page';
import bemClass from '@upsales/components/Utils/bemClass';
import { ButtonSelect, Column, Paginator, TableRow, Row, Text } from '@upsales/components';
import { RootState } from 'Store/index';
import {
	ListView as ListViewType,
	ListViewFilter,
	SalesboardUserListView,
	StandardListView,
	UserListView
} from 'App/resources/AllIWant';
import { CancelablePromise, makeCancelable } from 'App/babel/helpers/promise';
import './ListView.scss';
import logError from 'App/babel/helpers/logError';
import T from 'Components/Helpers/translate';
import {
	QuickSearchProps,
	renderDefaultHeader,
	renderDefaultTable,
	renderDefaultTableRow,
	renderDefaultNoData,
	RenderHeaderExtraProvided,
	RenderHeaderProvided,
	RenderProvided,
	RenderTableProvided,
	RenderTableRowProvided
} from './ListViewRenderHelpers';
import RequestBuilder, { Sort } from 'Resources/RequestBuilder';
import { PageSidebarProvided } from 'App/pages/Page/Page';
import ListViewActions, { MultiAction } from 'App/components/ListViewActions';
import { hideCoverup, showCoverup } from 'App/AngularApp';
import { FilterConfig } from 'App/babel/filterConfigs/FilterConfig';
import { Attr } from 'App/babel/attributes/Attribute';
import CustomField from 'App/resources/Model/CustomField';
import { SharedViewsPropsExternal } from 'Components/SharedViews/SharedViews';
import {
	filterObjToArray,
	compressChanges,
	getParamsFromSearch,
	locationSearchChanged,
	decideSelectedView,
	getPartialStateFromSearch,
	getFiltersFromView,
	getChangedPartialStateFromSearch
} from './ListViewHelpers';
import { replaceItem, removeItem } from 'Store/helpers/array';
import { getConfig } from 'App/helpers/filterHelper';
import { filterActiveAttributes } from 'App/babel/attributes/attributeHelpers';
import _ from 'lodash';
import getAngularModule from 'App/babel/angularHelpers/getAngularModule';
import type { AppState } from 'Store/reducers/AppReducer';
import type { IAngularEvent } from 'angular';
import { ListViewTableProvided } from './ListViewTable/ListViewTable';
import {
	MultiSelectContext,
	withMultiselect,
	withMultiselectForwardRef
} from '../MultiselectProvider/MultiselectProvider';
import BemClass from '@upsales/components/Utils/bemClass';
import { setView, hideView, showView, makeDefault, showSaveView } from 'Store/reducers/SharedViewsReducer';

type RenderSidebarProvided<ItemType> = RenderProvided<ItemType>;

export type GetDataProvided = {
	filters: { [filterName: string]: ListViewFilter };
	sorting: RenderProvided<any>['sorting'];
	columns: string[];
	selectedView: ListViewType | null;
};

type CleanupFn = () => void;

const listTypeToCFEntity = {
	campaign: 'project',
	accountGrowth: 'account',
	userDefinedObject1: 'userDefined1',
	userDefinedObject2: 'userDefined2',
	userDefinedObject3: 'userDefined3',
	userDefinedObject4: 'userDefined4',
	agreement: ['agreement', 'order']
} as const;

export const listTypeToCategoryEntity = {
	accountGrowth: 'account',
	userDefinedObject1: 'userDefined1',
	userDefinedObject2: 'userDefined2',
	userDefinedObject3: 'userDefined3',
	userDefinedObject4: 'userDefined4'
} as const;

export const listTypeToStandardFieldsEntity = {
	accountGrowth: 'Client',
	account: 'Client',
	opportunity: 'Order'
} as const;

export type ListViewSetStateCallback = () => void;

type TableRowRenderer<ItemType> = (
	item: ItemType,
	provided: ListViewTableProvided<ItemType, RenderTableRowProvided<ItemType>>
) => React.ReactElement<typeof TableRow>;

type MultiActions<ItemType> = MultiAction[] | ((provided: RenderProvided<ItemType>) => MultiAction[]);

export type ListViewPropsExternal<ItemType> = {
	loadError?: string;
	listType?: string;
	className?: string;
	onMount?: (provided: RenderProvided<ItemType>) => void | CleanupFn;
	renderHeader?: (provided: RenderHeaderProvided<ItemType>) => JSX.Element;
	addBtn?: boolean;
	onAddBtnClick?: (provided: RenderProvided<ItemType>) => void;
	addBtnText?: string;
	onChange?: (provided: RenderProvided<ItemType>) => void;
	getData: (
		rb: RequestBuilder,
		provided: GetDataProvided
	) => Promise<{ data: ItemType[]; metadata: { total: number; limit?: number; offset?: number } }>; // mayby use generics, for now we assume this format (99% of all usecases)
	formatTotal?: (total: number) => string;
	formatNoData?: () => string;
	renderTable?: (provided: RenderTableProvided<ItemType>) => JSX.Element;
	renderTableRow?: TableRowRenderer<ItemType>;
	renderNoData?: () => JSX.Element;
	renderSidebar?: (
		sidebarProvided: PageSidebarProvided,
		renderProvided: RenderSidebarProvided<ItemType>
	) => JSX.Element;
	renderHeaderFirstExtra?: <ItemType>(provided: RenderHeaderExtraProvided<ItemType>) => JSX.Element | null;
	renderHeaderLastExtra?: <ItemType>(provided: RenderHeaderExtraProvided<ItemType>) => JSX.Element | null;
	renderBelowHeader?: (provided: RenderProvided<ItemType> & { classes: BemClass }) => JSX.Element | null;
	multiActions?: MultiActions<ItemType>;
	itemIdentifier?: keyof ItemType;
	runMultiAction?: (
		action: MultiAction,
		provided: RenderProvided<ItemType>,
		multiselect: MultiSelectContext
	) => Promise<unknown | void> | void;
	quickSearchFilter?: string;
	quickSearchPlaceholder?: string;
	renderToolsColumn?: boolean;
	categoryEntity?: string;
	filterConfigs?: { [name: string]: FilterConfig };
	hiddenFilters?: string[]; // Can be used to hide filters that are controlled from other components but should still be functional.
	attributes: { [name: string]: Attr };
	columns?: string[]; // if not using listviews, columns can be set using this prop, or if we want to override the columns from selectedView
	useUrlData?: boolean;
	customFields?: CustomField[];
	relatedEntities?: string[];
	broadcastType?: string | string[];
	isFullscreen?: boolean;
	initialFilters?: { [filterName: string]: ListViewFilter };
	initialSorting?: RenderProvided<ItemType>['sorting'];
	isWeighted?: boolean;
	filterOpts?: { noTabs?: boolean };
	secondaryItemIdentifier?: { [key: string]: string };
	skipSortById?: boolean;
	tableLimitOptions?: Array<number> & {
		0: number; // Complain if array does not contain at least one element
	};
	onSortChange?: (sort: { field: string; asc: boolean }) => void;
	noStickyHeader?: boolean;
	noStickyTableHeader?: boolean;
	disableTitleDropDown?: boolean;
	isNewListView?: boolean;
	canSortCustomFields?: boolean;
	hideFilters?: boolean;
	subtitle?: string;
};

function isFunction(
	multiActions: MultiActions<any> | undefined
): multiActions is (provided: RenderProvided<any>) => MultiAction[] {
	return typeof multiActions === 'function';
}

export function getCustomFieldsByType(listType: string | undefined, appCustomFields: AppState['customFields']) {
	const cfType = listType ? listTypeToCFEntity[listType as keyof typeof listTypeToCFEntity] || listType : null;
	if ((Array as ReadonlyArrayConstructor).isArray(cfType)) {
		return cfType.reduce<CustomField[]>((acc, cur) => {
			return acc.concat(appCustomFields[cur] || []);
		}, []);
	}
	return appCustomFields[cfType as keyof typeof appCustomFields] || [];
}

export function mapStateToProps<ItemType = any>(
	{ App }: RootState,
	{ listType, categoryEntity, customFields }: ListViewPropsExternal<ItemType>
) {
	let standardFieldsEntity;
	if (listType) {
		const entity =
			listTypeToStandardFieldsEntity[listType as keyof typeof listTypeToStandardFieldsEntity] ?? listType;
		standardFieldsEntity = entity[0].toUpperCase() + entity.substring(1);
	}

	return {
		listViews: (listType ? App.listViews[listType] ?? [] : []) as ListViewType[],
		customerId: App.customerId,
		customFields: customFields ? customFields : getCustomFieldsByType(listType, App.customFields),
		standardFields: standardFieldsEntity ? App.metadata?.standardFields[standardFieldsEntity] : undefined,
		categoryTypes: categoryEntity && App.categoryTypes[categoryEntity] ? App.categoryTypes[categoryEntity] : null,
		categories: categoryEntity && App.categories[categoryEntity] ? App.categories[categoryEntity] : null,
		fullCustomFieldsMap: App.customFields
	};
}

const mapDispatchToProps = {
	setView,
	hideView,
	showView,
	makeDefault,
	showSaveView
};

const connector = connect(mapStateToProps, mapDispatchToProps, null, {
	forwardRef: true
});
type ListViewProps<ItemType> = ListViewPropsExternal<ItemType> &
	ConnectedProps<typeof connector> &
	RouteComponentProps & { multiselect: MultiSelectContext };

export type ListViewState<ItemType> = {
	filtersVisible: boolean; // If the filterSidebar is open or not
	error: boolean;
	total: number;
	selectedView: ListViewType | null;
	tableData: ReadonlyArray<ItemType>;
	tableLoading: boolean;
	currentStateCompression: string; // This holds the current state of filters, columns and sorting
	originalStateCompression: string; // This holds the view original state of filters, columns and sorting
	filters: { [filterName: string]: ListViewFilter };
	offset: number;
	initialLoading: boolean;
	requestBuilder: RequestBuilder | null;
	sorting: RenderProvided<ItemType>['sorting'];
	sortById: boolean;
	tableLimit: number;
};

export type GetDataOpts = {
	debounce?: boolean;
	silent?: boolean;
	resetMultiSelect?: boolean;
};

const getNewTotalFromArrayDiff = (total: number, oldArray: ReadonlyArray<any>, newArray: ReadonlyArray<any>) => {
	// If current tableData is less than new, we add the diff
	if (oldArray.length < newArray.length) {
		total += newArray.length - oldArray.length;
		// If current newArray is greater than new, we remove the diff
	} else if (oldArray.length > newArray.length) {
		total -= oldArray.length - newArray.length;
	}
	return total;
};

class ListView<ItemType extends { tempId?: string } = any> extends React.Component<
	ListViewProps<ItemType>,
	ListViewState<ItemType>
> {
	private cleanupMount: CleanupFn | null = null;
	private listeners: ReturnType<typeof Tools.$rootScope.$on>[] = [];
	private getDataCancelablePromise: CancelablePromise<{
		data: ItemType[];
		metadata: { total: number };
	}> | null = null;
	private getDataDebounceTimeout: NodeJS.Timeout | null = null;
	// Had to keep a local copy of this since the store did update after the save-request was done. This lead to a diff between the local state listView and the redux listViews
	private listViews: ListViewType[] = [];
	constructor(props: ListViewProps<ItemType>) {
		super(props);

		const locationParams = props.useUrlData ? getParamsFromSearch(props.location.search) : null;

		let selectedView: ListViewType | null = null;
		if (props.listViews.length) {
			this.listViews = _.cloneDeep(props.listViews);

			// Ugly hack to set default view in accountGrowth, because same set of standard views are in both AG
			// and new companies list
			// TODO: Remove after LIST_COMPANIES_REACT is released for all and ACCOUNT_GROWTH is removed
			if (props.location.pathname === '/accountGrowth') {
				const myCompanies = this.listViews.find(e => e.id === 'standard1');
				const accountGrowth = this.listViews.find(e => e.id === 'standard5');
				if (myCompanies && accountGrowth) {
					myCompanies.default = false;
					accountGrowth.default = true;
				}
			}

			// Figure out what view to set as selected on init. Pass search params if useUrlData is set
			selectedView = decideSelectedView(
				this.listViews,
				props.useUrlData && locationParams ? locationParams.id : null
			);
		}

		/*
			Sorting and filters could be read directly from selectedView, but then we are
			required to use views. This component should work without listViews.
		*/
		let state: ListViewState<ItemType> = {
			filtersVisible: false,
			error: false,
			total: 0,
			selectedView,
			tableData: [],
			tableLoading: true,
			currentStateCompression: '',
			originalStateCompression: '',
			filters: props.initialFilters
				? props.initialFilters
				: selectedView
				? getFiltersFromView(selectedView.filters, selectedView.type)
				: {},
			offset: 0,
			initialLoading: true,
			requestBuilder: null,
			sorting: props.initialSorting ?? (selectedView?.sorting || []),
			sortById: !this.props.skipSortById,
			tableLimit: props.tableLimitOptions?.[0] ?? 50
		};

		// Set current and original compressedChanges from the view in its original state
		// This must run before we read the url changes
		state.currentStateCompression = compressChanges({
			filters: state.filters,
			sorting: state.sorting,
			columns: state.selectedView?.columns || this.props.columns || [],
			offset: state.offset,
			tableLimit: state.tableLimit
		});
		state.originalStateCompression = state.currentStateCompression;

		// If we have a selected view and want to read data from url, we try to read it
		if (props.useUrlData && selectedView) {
			const stateFromSearch = getPartialStateFromSearch<ItemType>(props.location.search, this.listViews);

			/*
				This is just in case the view from the url was missing and we fell back to anotherone
			*/
			if (selectedView.id + '' !== locationParams?.id) {
				const search = new URLSearchParams(props.location.search);
				search.set('id', '' + selectedView.id);
				props.history.replace({ search: search.toString() });
			}
			if (stateFromSearch) {
				state = { ...state, ...stateFromSearch };
			}
		}

		this.state = state;

		if (isFunction(props.multiActions)) {
			const multiselect = props.multiActions?.(this.getRenderProvided());
			props.multiselect.setMultiActions(multiselect ?? []);
		} else {
			props.multiselect.setMultiActions((props.multiActions as MultiAction[]) ?? []);
		}
	}

	componentDidMount() {
		this.cleanupMount = this.props.onMount?.(this.getRenderProvided()) || null;

		hideCoverup();

		const fetchData = () => {
			this.getData()
				.then(() => {
					this.setState({ initialLoading: false });
				})
				.catch(e => {
					this.setState({ error: true });
					logError(e, 'Failed to load ListView');
				});
		};

		const broadcastTypes =
			typeof this.props.broadcastType === 'string' ? [this.props.broadcastType] : this.props.broadcastType ?? [];

		this.listeners = [];
		broadcastTypes.forEach(broadcastType => {
			const addedType = `${broadcastType}.added`;
			const updatedType = `${broadcastType}.updated`;
			const multiUpdatedType = `${broadcastType}.updated-multi`;
			const deletedType = `${broadcastType}.deleted`;
			const statusChangedType = `${broadcastType}.statusChanged`;

			const modifyList = (event: IAngularEvent, item: ItemType | number | number[]) => {
				function itemIsNumber(item: ItemType | number): item is number {
					return typeof item === 'number';
				}
				function itemIsNumberArray(item: ItemType | number | number[]): item is number[] {
					return Array.isArray(item);
				}
				let tableData = [...this.state.tableData];

				const type = event.name;
				const secondaryItemIdentifier = this.props.secondaryItemIdentifier?.[broadcastType];
				const itemIdentifier = (this.props.itemIdentifier ?? 'id') as keyof ItemType;
				if (!itemIsNumberArray(item)) {
					const index =
						type === addedType
							? -1
							: tableData.findIndex(
									(row: any) =>
										row[itemIdentifier] ===
											(typeof item === 'number' ? item : item[itemIdentifier]) ||
										(!itemIsNumber(item) && !!item.tempId && row.tempId === item.tempId)
							  );

					const { selectedIdMap, toggleSelected, allSelected, setTotal, total } = this.props.multiselect;

					const FilterHelper = getAngularModule('FilterHelper');
					const lastQuery = this.state.requestBuilder?.build().q;
					// @ts-expect-error these types are completely different :I
					const foundMatch = FilterHelper.match(lastQuery, item, broadcastType);

					// If multiple events are fired at the same time, we only want to update the table when it's actually changed
					let modifiedTableData = false;

					switch (type) {
						case addedType:
							if (foundMatch && !itemIsNumber(item)) {
								tableData.unshift(item);
								modifiedTableData = true;
							}
							break;
						case updatedType:
						case statusChangedType:
							if (!itemIsNumber(item)) {
								if (index >= 0) {
									if (foundMatch) {
										tableData.splice(index, 1, item);
									} else {
										tableData.splice(index, 1);
									}
									modifiedTableData = true;
								} else if (foundMatch) {
									tableData.push(item);
									modifiedTableData = true;
								}
							}
							break;
						case deletedType: {
							if (secondaryItemIdentifier && !itemIsNumber(item)) {
								tableData = tableData.filter(
									row => _.get(row, secondaryItemIdentifier) !== item[itemIdentifier]
								);
								modifiedTableData = true;
							} else if (index >= 0) {
								tableData.splice(index, 1);
								modifiedTableData = true;
							}
							if (allSelected) {
								setTotal(total - 1);
							} else if (itemIsNumber(item) && selectedIdMap[item]) {
								toggleSelected(item, false);
							} else if (!itemIsNumber(item) && selectedIdMap[item[itemIdentifier] as number]) {
								toggleSelected(item[itemIdentifier] as number, false);
							}
							break;
						}
						default:
							return;
					}
					if (modifiedTableData) {
						this.setTableData(tableData);
						this.props.onChange?.(this.getRenderProvided());
					}
				} else {
					// multiAction events
					const updatedIndices = tableData.reduce<number[]>((acc, row, index) => {
						const updatedIndex = item.findIndex(itemId => row[itemIdentifier] === itemId);
						if (updatedIndex >= 0) {
							acc.push(index);
						}
						return acc;
					}, []);
					switch (type) {
						case multiUpdatedType: {
							if (updatedIndices.length) {
								this.getData({ silent: true, debounce: true });
							}
							break;
						}
					}
				}
			};

			this.listeners = this.listeners.concat([
				Tools.$rootScope.$on(addedType, modifyList),
				Tools.$rootScope.$on(updatedType, modifyList),
				Tools.$rootScope.$on(deletedType, modifyList),
				Tools.$rootScope.$on(multiUpdatedType, modifyList),
				Tools.$rootScope.$on(statusChangedType, modifyList)
			]);
		});

		// Make sure that the local copy of listViews is updated
		this.listeners = this.listeners.concat([
			Tools.$rootScope.$on('listView.added', (e, listView: ListViewType) => this.listViews.push(listView)),
			Tools.$rootScope.$on('listView.updated', (e, listView: ListViewType) => {
				const index = this.listViews.findIndex(v => v.id === listView.id);
				this.listViews = replaceItem(this.listViews, index, listView as ListViewType);
			}),
			Tools.$rootScope.$on('listView.deleted', (e, listView: ListViewType) => {
				const index = this.listViews.findIndex(v => v.id === listView.id);
				this.listViews = removeItem(this.listViews, index);
			})
		]);

		// get initial data
		fetchData();
	}

	componentWillUnmount() {
		this.cleanupMount?.();
		this.getDataCancelablePromise?.cancel();
		this.setTableData = () => {};
		this.getData = async () => ({ data: [], metadata: { total: 0 } });
		this.listeners.forEach(unsub => unsub());
		if (this.getDataDebounceTimeout) {
			clearTimeout(this.getDataDebounceTimeout);
		}
		showCoverup();
	}

	componentDidUpdate(prevProps: ListViewProps<ItemType>, prevState: ListViewState<ItemType>) {
		if (prevProps.multiActions !== this.props.multiActions) {
			if (isFunction(this.props.multiActions)) {
				const multiselect = this.props.multiActions?.(this.getRenderProvided());
				this.props.multiselect.setMultiActions(multiselect ?? []);
			} else {
				this.props.multiselect.setMultiActions((this.props.multiActions as MultiAction[]) ?? []);
			}
		}

		if (this.props.useUrlData) {
			// Will compare changes in url
			const urlChanged = locationSearchChanged(prevProps.location.search, this.props.location.search);

			// getChangedPartialStateFromSearch will return null if nothing was changed
			const partialState = getChangedPartialStateFromSearch<ItemType>(
				this.props.location.search,
				prevProps.location.search,
				this.listViews,
				prevState
			);
			/*
			IMPORTANT: Only set state if both urlChange and partialState is truthy, else we risk an endless loop
			This is becase this setState below would trigger componentDidUpdate to run again. getChangedPartialStateFromSearch would then return same change, but locationSearchChanged would return false.
			*/
			if (urlChanged && partialState) {
				this.setState(partialState as ListViewState<ItemType>, () =>
					this.getData({ resetMultiSelect: !!partialState.filters })
				);
			}
		}
	}

	setFiltersVisible = (filtersVisible: boolean) => {
		this.setState({ filtersVisible });
	};

	private getFilteredAttributes = () => {
		const attributes = this.props.attributes;
		const standardFields = this.props.standardFields;
		const filteredAttributes = filterActiveAttributes(attributes, standardFields);
		return filteredAttributes;
	};

	// Since contact list can use account custom fields as well
	private getFilterCustomFields(filterName: string) {
		if (!filterName.startsWith('account.Custom_') && !filterName.startsWith('contact.Custom_')) {
			return this.props.customFields;
		}

		const customFieldType = filterName.split('.')[0] as keyof AppState['customFields'];

		if (!this.props.fullCustomFieldsMap[customFieldType]) {
			return this.props.customFields;
		}
		return this.props.fullCustomFieldsMap[customFieldType];
	}

	private getData = ({ debounce = false, silent = false, resetMultiSelect = true }: GetDataOpts = {}) => {
		if (!silent) {
			this.setState({ tableLoading: true });
		}

		if (resetMultiSelect) {
			this.props.multiselect.reset();
		}

		if (this.getDataDebounceTimeout) {
			clearTimeout(this.getDataDebounceTimeout);
		}
		this.getDataCancelablePromise?.cancel();

		const { getData = async () => ({ metadata: { total: 0 }, data: [] }), listType } = this.props;
		this.getDataCancelablePromise = makeCancelable(
			new Promise((resolve, reject) => {
				this.getDataDebounceTimeout = setTimeout(
					() => {
						const { filters, offset, sorting, selectedView, tableLimit } = this.state;
						let rb = new RequestBuilder();

						// Generate filters based on selectedview
						if (this.props.filterConfigs) {
							rb = Tools.FilterHelper.parseFilters(
								filters,
								selectedView?.type ?? '',
								undefined,
								undefined,
								{
									getConfig: name =>
										getConfig(
											name,
											this.props.filterConfigs || {},
											this.getFilterCustomFields(name)
										)
								}
							);
						}
						rb.limit = tableLimit;
						rb.offset = offset;

						if (sorting.length) {
							sorting.forEach(sort => {
								rb.addSort(sort.attribute, sort.ascending);
							});
						}
						const sortDirection: Sort['ascending'] = true;

						if (this.state.sortById) {
							rb.addSort('id', sortDirection);
						}

						this.setState({ requestBuilder: rb });
						getData(rb, {
							filters,
							sorting,
							columns: this.state.selectedView?.columns || this.props.columns || [],
							selectedView: this.state.selectedView
						})
							.then(resolve)
							.catch(reject);
					},
					debounce ? 400 : 0
				);
			})
		);

		this.getDataCancelablePromise.promise
			.then(res => {
				this.setState({
					total: res.metadata.total,
					tableData: res.data,
					tableLoading: false,
					initialLoading: false
				});
				this.props.multiselect.setTotal(res.metadata.total);
			})
			.catch(e => {
				logError(e, `Failed to load list view of type "${listType}"`);
			});

		return this.getDataCancelablePromise.promise;
	};

	private runMultiAction = async (action: MultiAction) => {
		const maybeAPromise = this.props.runMultiAction?.(action, this.getRenderProvided(), this.props.multiselect);
		// If a promise was returned we wait for it then we do a reload of the data (that will also deselect all selected rows)
		// This might be a closing of an old modal, so we can't catch it
		// Debounce so that the backend can finish indexing
		// Silent so we do not get a spinner
		if (maybeAPromise === undefined) {
			return;
		}
		Promise.resolve(maybeAPromise)
			.then(() => {
				this.getData({ debounce: true, silent: true });
			})
			.catch(() => {});
	};

	private setOffset = (offset: number) => {
		this.setStateAndUrlData({ offset }, { resetMultiSelect: false });
	};

	private setTableLimit = (tableLimit: number) => {
		this.setStateAndUrlData({ tableLimit }, { resetMultiSelect: false });
	};

	setTableData = (tableData: ReadonlyArray<ItemType>) => {
		// Get diff from before so we can update the total without making a request
		const total = getNewTotalFromArrayDiff(this.state.total, this.state.tableData, tableData);
		this.setState({ tableData, total });
		this.props.multiselect.setTotal(total);
	};

	setFilter = (
		filterName: string,
		valueObjPartial: Partial<ListViewFilter>,
		opts?: GetDataOpts,
		callback?: ListViewSetStateCallback
	) => {
		this.setFilters(
			{
				...this.state.filters,
				[filterName]: { ...this.state.filters[filterName], ...valueObjPartial, filterName }
			},
			opts,
			callback
		);
	};

	setFilters = (
		filters: ListViewState<ItemType>['filters'],
		opts?: GetDataOpts,
		callback?: ListViewSetStateCallback
	) => {
		this.setStateAndUrlData(
			{
				filters: { ...filters },
				selectedView: this.state.selectedView
					? { ...this.state.selectedView, filters: filterObjToArray(filters) }
					: null,
				offset: 0
			},
			opts,
			callback
		);
	};

	onSortChange = (sort: { field: string; asc: boolean }) => {
		const sorting = [{ attribute: sort.field, ascending: sort.asc }];
		this.setStateAndUrlData({
			sorting,
			selectedView: this.state.selectedView ? { ...this.state.selectedView, sorting } : null,
			offset: 0
		});
		this.props.onSortChange?.(sort);
	};

	getRenderProvided = (): RenderProvided<ItemType> => ({
		setFiltersVisible: this.setFiltersVisible,
		tableLoading: this.state.tableLoading,
		selectedView: this.state.selectedView,
		columns: this.props.columns || this.state.selectedView?.columns || [],
		tableData: this.state.tableData,
		setTableData: d => this.setTableData(d),
		itemIdentifier: this.props.itemIdentifier || ('id' as keyof ItemType),
		runMultiAction: this.runMultiAction,
		total: this.state.total,
		getTableData: () => this.state.tableData,
		setFilter: (...args) => this.setFilter(...args),
		setColumns: (...args) => this.setColumns(...args),
		requestBuilder: this.state.requestBuilder,
		filterConfigs: this.props.filterConfigs || null,
		attributes: this.props.attributes || {},
		filters: this.state.filters,
		onFilterChange: this.setFilters,
		reloadTable: this.getData,
		sorting: this.state.sorting,
		onSortChange: this.onSortChange,
		hiddenFilters: this.props.hiddenFilters || [],
		customFields: this.props.customFields,
		hasChanged: this.state.currentStateCompression !== this.state.originalStateCompression,
		listType: this.props.listType,
		onChangeView: this.onChangeView,
		hideView: this.props.hideView,
		showView: this.props.showView,
		makeDefault: this.props.makeDefault,
		showSaveView: this.props.showSaveView,
		canSortCustomFields: this.props.canSortCustomFields,
		broadcastType: this.props.broadcastType
	});

	// This should be used instead of setState to sync changes to the url and fetch new data
	setStateAndUrlData = (
		partialState: Partial<ListViewState<ItemType>>,
		opts?: GetDataOpts,
		callback?: ListViewSetStateCallback
	) => {
		const newPartialState: Partial<ListViewState<ItemType>> = { ...partialState };

		const nextView = newPartialState.selectedView || this.state.selectedView;
		const viewChanged =
			newPartialState.selectedView && newPartialState.selectedView?.id !== this.state.selectedView?.id;
		// If originalStateCompression is sent in as "reset" we assume that we want to reset it to whatever the currentStateCompression should be (on view save)
		const resetCompressedData = partialState.originalStateCompression === 'reset';

		// If view changed OR
		// If any of the keys we are interested in was in the partial we generate a new compressedData
		const compression = compressChanges<ItemType>({
			...this.state,
			...newPartialState,
			columns: this.props.columns || nextView?.columns || []
		});
		if (viewChanged || resetCompressedData || compression !== this.state.currentStateCompression) {
			newPartialState.currentStateCompression = compression;

			let search = '';
			if (viewChanged || resetCompressedData) {
				newPartialState.originalStateCompression = newPartialState.currentStateCompression;
				// Just pass id to url if view is same as original
				search = nextView ? `id=${nextView.id}` : '';
			} else {
				// Pass id and diff from original
				search = `${nextView ? `id=${nextView.id}` : ''}&f=${encodeURIComponent(
					newPartialState.currentStateCompression
				)}`;
			}
			if (this.props.useUrlData) {
				this.props.history.push({ search });
			}
		}

		if ('selectedView' in newPartialState) {
			this.props.setView?.(newPartialState.selectedView);
		}

		this.setState(newPartialState as object, () => {
			if (!this.props.useUrlData) {
				this.getData(opts);
			}
			callback?.();
		});
	};

	onChangeView = (
		selectedView: SalesboardUserListView | StandardListView | UserListView | null,
		{ fromSave = false } = {}
	) => {
		if (!selectedView) {
			// We can not handle no view
			return;
		}

		const partialState: Partial<ListViewState<ItemType>> = {
			selectedView: selectedView as ListViewType,
			filters: selectedView ? getFiltersFromView(selectedView.filters, selectedView.type) : {},
			sorting: selectedView ? (selectedView as ListViewType).sorting : [],
			offset: 0
		};

		if (fromSave) {
			// Anytime the view is saved we need to update the local array of listviews
			// We also have listeners for the scope events but this was not quick enough for some updates (state was updated before the list was)
			const index = this.listViews.findIndex(v => v.id === selectedView.id);
			this.listViews = replaceItem(this.listViews, index, selectedView as ListViewType);
			// Reset compressedData by passing originalStateCompression as "reset"
			partialState.originalStateCompression = 'reset';
		}
		this.setStateAndUrlData(partialState);
	};

	renderHeader = (classes: bemClass) => {
		const {
			renderHeader = renderDefaultHeader,
			listViews = [],
			listType,
			addBtn = false,
			onAddBtnClick = () => {},
			formatTotal = total => total + '',
			hideFilters = false,
			addBtnText = '',
			renderHeaderFirstExtra,
			renderHeaderLastExtra,
			quickSearchFilter,
			quickSearchPlaceholder = T('filters.quickSearch'),
			relatedEntities,
			filterOpts,
			disableTitleDropDown,
			isNewListView
		} = this.props;
		let sharedViewsProps: SharedViewsPropsExternal | null = null;
		if (listType) {
			sharedViewsProps = {
				// Make sure that the listview we pass is updated (this is the one that gets saved)
				selectedView: this.state.selectedView
					? {
							...this.state.selectedView,
							filters: filterObjToArray(this.state.filters),
							sorting: this.state.sorting
					  }
					: null,
				title: 'dataObject.title',
				total: this.state.total,
				canBeSaved: !!this.state.selectedView?.editable,
				hasChanged: this.state.currentStateCompression !== this.state.originalStateCompression,
				changeView: this.onChangeView,
				type: listType,
				formatTotal: formatTotal,
				hideSubtitle: false,
				isDisabled: disableTitleDropDown ?? false,
				isNewListView: isNewListView
			};
		}
		let quickSearchProps = null;
		if (quickSearchFilter) {
			quickSearchProps = {
				placeholder: quickSearchPlaceholder,
				value: this.state.filters[quickSearchFilter]?.value || '',
				className: classes.elem('quicksearch').b(),
				onChange: e =>
					this.setFilter(
						quickSearchFilter,
						{
							comparisonType: 'Wildcard',
							value: e.target.value
						},
						{ debounce: true }
					)
			} as QuickSearchProps;
		}
		return renderHeader({
			...this.getRenderProvided(),
			props: {
				className: classes.elem('header').b()
			},
			classes,
			listType,
			listViews,
			sharedViewsProps,
			addBtn,
			onAddBtnClick,
			addBtnText,
			renderHeaderFirstExtra,
			renderHeaderLastExtra,
			quickSearchProps,
			relatedEntities,
			hideFilters,
			opts: filterOpts
		});
	};

	setColumns = (columns: ListViewType['columns'], opts?: GetDataOpts, callback?: ListViewSetStateCallback) => {
		const { selectedView } = this.state;

		if (selectedView) {
			this.setStateAndUrlData({ selectedView: { ...selectedView, columns } }, opts, callback);
		}
	};

	editColumns = () => {
		const { selectedView } = this.state;
		if (!selectedView) {
			return;
		}
		const attributes = this.getFilteredAttributes() ?? {};
		const selectables = Object.keys(attributes).reduce((res, key) => {
			const a = attributes?.[key];
			if (a?.selectableColumn) {
				res.push({ field: a.field, key, title: a.title, locked: a.locked });
			}
			return res;
		}, [] as { field: string; key: string; title: string; locked?: boolean }[]);
		if (this.props.categoryTypes) {
			for (const categoryTypes of this.props.categoryTypes) {
				selectables.push({
					field: 'Category_' + categoryTypes.id,
					key: 'Category_' + categoryTypes.id,
					title: categoryTypes.name
				});
			}
		}

		// eslint-disable-next-line promise/catch-or-return
		Tools.$upModal
			.open('EditColumns', {
				attributes: this.props.attributes,
				columns: selectedView.columns,
				selectables,
				customfields: this.props.customFields,
				tableType: this.props.listType || ''
			})
			.then((columns: ListViewType['columns']) => {
				this.setColumns(columns);
			});
	};

	renderTable = (classes: bemClass) => {
		const {
			formatNoData = () => T('default.noResults'),
			renderNoData = renderDefaultNoData(formatNoData, this.props.subtitle),
			renderTable = renderDefaultTable,
			renderTableRow = renderDefaultTableRow,
			renderToolsColumn = false
		} = this.props;
		const { tableLoading } = this.state;
		return renderTable({
			...this.getRenderProvided(),
			classes,
			tableLoading,
			renderTableRow,
			formatNoData,
			renderNoData,
			renderToolsColumn,
			editColumns: this.editColumns,
			canSortCustomFields: this.props.canSortCustomFields
		});
	};

	renderBelowHeader = (classes: bemClass) => {
		return this.props.renderBelowHeader?.({ ...this.getRenderProvided(), classes }) ?? null;
	};

	render() {
		const {
			className,
			renderSidebar = () => null,
			loadError = T('listError.default'),
			isFullscreen,
			tableLimitOptions = [50, 100, 250],
			multiselect,
			noStickyHeader,
			noStickyTableHeader
		} = this.props;
		const { error, total, tableLoading, offset, initialLoading } = this.state;
		const classes = new bemClass('ListView', className).mod({
			'filters-visible': this.state.filtersVisible,
			'remove-frame': Tools.FeatureHelper.hasSoftDeployAccess('REMOVE_FRAME'),
			fullscreen: isFullscreen,
			'no-sticky-header': noStickyHeader,
			'no-sticky-table-header': noStickyTableHeader
		});
		const footerClass = classes
			.elem('footer')
			.mod({ multiSelectVisible: Object.keys(multiselect.selectedIdMap).length > 0 || multiselect.allSelected });

		if (total === offset && offset > 0) {
			this.setOffset(0);
		}

		if (!isFullscreen) {
			classes.add('Page--position');
		}
		return (
			<div className={classes.b()}>
				<Page
					sidebar={sidebarProvided => renderSidebar(sidebarProvided, this.getRenderProvided())}
					sidebarInitiallyExpanded
					error={error ? loadError : undefined}
					contentLoading={initialLoading}
					static
				>
					{this.renderHeader(classes)}
					{this.renderBelowHeader(classes)}
					{this.renderTable(classes)}
					<Row className={footerClass.b()}>
						{total > tableLimitOptions[0] && !tableLoading ? (
							<>
								<Column /> {/* Empty column to align page size selector */}
								<Column align="center">
									{total > this.state.tableLimit ? (
										<Paginator
											limit={this.state.tableLimit}
											offset={offset}
											total={total}
											align="center"
											onChange={val => this.setOffset(val)}
										/>
									) : null}
								</Column>
								<Column>
									{tableLimitOptions.length > 1 ? (
										<Row className={footerClass.elem('size-selector').b()}>
											<ButtonSelect
												size="sm"
												value={this.state.tableLimit}
												options={tableLimitOptions.map(limit => ({
													value: limit,
													title: limit.toString()
												}))}
												onChange={value => {
													this.setTableLimit(value as number);
													this.getData();
												}}
											/>
											<Text size="md">{T('filters.rowsPerPage').toLowerCase()}</Text>
										</Row>
									) : null}
								</Column>
							</>
						) : null}
					</Row>
					{/* 
					You could argue that this should be rendered by the ListViewTable, 
					but we have ListViews that implement custom table renderers that still wants multiActions 
				*/}
					{multiselect.multiActions?.length ? (
						<ListViewActions
							actions={multiselect.multiActions}
							runAction={this.runMultiAction}
							selectNone={multiselect.selectNone}
							selected={multiselect.selected}
							allSelected={multiselect.allSelected}
						/>
					) : null}
				</Page>
			</div>
		);
	}
}

export const detached = withMultiselect(ListView);

// Sorry but this was completely impossible to typescript, works though
/* @ts-ignore */
const withRouterForwardRef = Component => {
	/* @ts-ignore */
	// eslint-disable-next-line react/jsx-key
	const WithRouter = withRouter(({ forwardedRef, ...props }) => <Component ref={forwardedRef} {...props} />);

	/* @ts-ignore */
	return React.forwardRef((props, ref) => <WithRouter {...props} forwardedRef={ref} />);
};

export default React.forwardRef((props, ref) => {
	const Component = useMemo(() => {
		return connector(withRouterForwardRef(withMultiselectForwardRef(ListView)));
	}, []);

	return <Component ref={ref} {...(props as any)} />;
}) as <ItemType extends {}>(
	props: ListViewPropsExternal<ItemType> & { ref?: MutableRefObject<undefined> }
) => JSX.Element;
