import logError from 'Helpers/logError';
import { NumberFormat } from './numberFormat';

const DEFAULT_CURRENCY = 'SEK';

/** @deprecated Use App/babel/components/Filters/Currencies.ts instead  */
export class CurrencyFormat extends NumberFormat {
	readonly currency: string;

	static currency(currency: string): CurrencyFormat {
		return new CurrencyFormat(currency);
	}

	constructor(currency?: string | null, locale?: string, fractionDigits: number = 2) {
		super(locale, fractionDigits);
		this.currency = currency ? currency : this.getMasterCurrency() ?? DEFAULT_CURRENCY;
	}

	/**
	 * Formats the currency per default, i.e with only neccesarry decimals
	 *
	 * `123456789.987654321` -> `123,456,789.99 SEK`
	 *
	 * `155555555.12` -> `155,555,555.12 SEK`
	 *
	 * `1000` -> `1 000 SEK`
	 * @param nbr The number to format
	 * @returns A string representation of the number
	 */
	default(nbr: number, maximumFractionDigits?: number): string {
		if (!this.validate(nbr)) return '';

		const formatter = this.getNumberFormat('currency', 'standard', maximumFractionDigits);
		return formatter.format(nbr);
	}

	/**
	 * Formats the currency on a shorter format, using a suffix and less decimals for larger numbers.
	 *
	 * `1000000` -> `1 M SEK`
	 *
	 * `123456789.987654321` -> `123.5 M SEK`
	 *
	 * `100000` -> `100 000 SEK`
	 *
	 * @param nbr The number to format
	 * @returns A string representation of the number
	 */
	short(nbr: number, maximumFractionDigits: number = this.fractionDigits): string {
		if (!this.validate(nbr)) return '';

		let formatted: string;
		if (Math.abs(nbr) >= this.COMPACT_NOTATION_BREAKPOINT) {
			formatted = this.getNumberFormat('currency', 'compact', 1).format(nbr).replace(/mn/, 'M');
		} else {
			// Using default notation below 1 milion
			formatted = this.default(nbr, maximumFractionDigits);
		}
		// sv-SE: We wan't the milion prefix to be M instead of the default md
		return formatted;
	}

	/**
	 * Formats the currency on a verbose format, i.e with always at least 2 decimals
	 *
	 * `123456789.987654321` -> `123,456,789.99 SEK`
	 *
	 * `155555555.12` -> `155,555,555.12 SEK`
	 *
	 * `1000` -> `1 000,00 SEK`
	 * @param nbr The number to format
	 * @returns A string representation of the number
	 */
	verbose(nbr: number) {
		if (!this.validate(nbr)) return '';

		const formatter = this.getNumberFormat('currency', 'standard', undefined, 2);
		return formatter.format(nbr);
	}

	protected getMasterCurrency() {
		const customerCurrencies = Tools.AppService.getMetadata()?.customerCurrencies;
		const masterCurrency = customerCurrencies?.find(currency => currency.masterCurrency === true);
		if (masterCurrency) {
			return masterCurrency.iso;
		} else {
			logError(undefined, 'Could not find master currency');
		}
	}

	protected getNumberFormat(
		style: Intl.NumberFormatOptions['style'],
		notation: 'standard' | 'scientific' | 'engineering' | 'compact' | undefined,
		maximumFractionDigits: number = this.fractionDigits,
		minimumFractionDigits: number = 0
	): Intl.NumberFormat {
		return new Intl.NumberFormat(this.locales, {
			style,
			minimumFractionDigits: Math.min(maximumFractionDigits, minimumFractionDigits),
			maximumFractionDigits: Math.max(maximumFractionDigits, minimumFractionDigits),
			notation,
			currency: this.currency,
			currencyDisplay: 'code'
		});
	}
}
