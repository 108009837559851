import Flow from 'App/resources/Flow';
import FlowTemplateName from '../FlowTemplateName';
import buildFlowPath from './buildFlowPath';
import { FlowTemplate } from './FlowTemplate';
import getAngularModule from 'App/babel/angularHelpers/getAngularModule';
import { SegmentFilter } from 'App/resources/Model/Segment';

const template: FlowTemplate = {
	name: FlowTemplateName.SELL_MORE_TO_EXISTING_CUSTOMERS,
	title: 'flowtemplate.SELL_MORE_TO_EXISTING_CUSTOMERS.title',
	subtitle: 'flowtemplate.SELL_MORE_TO_EXISTING_CUSTOMERS.subtitle',
	imgPath: 'img/sell_more_to_existing_customers.svg',
	imgPathPreview: 'img/sell_more_to_existing_customers_preview.svg',
	getFlowPath: () => {
		const AppService = getAngularModule('AppService');
		const phoneCallActivityTypeId = AppService.getTodoTypes().PHONE_CALL.id;

		return buildFlowPath([
			Flow.newStep('sendMail'),
			Flow.newStep('clickedMail', { waitTime: 7 }),
			[
				[
					Flow.newStep('createActivity', {
						params: [
							{ name: 'ActivityType', value: phoneCallActivityTypeId },
							{ name: 'ActivityDelayInDays', value: '0' }
						]
					})
				],
				[
					Flow.newStep('snooze', { waitTime: 30 }),
					Flow.newStep('sendMail'),
					Flow.newStep('clickedMail', { waitTime: 7 }),
					Flow.newStep('createActivity', {
						params: [
							{ name: 'ActivityType', value: phoneCallActivityTypeId },
							{ name: 'ActivityDelayInDays', value: '0' }
						]
					})
				]
			]
		]);
	},
	getSegmentFilters: (productId: number) => {
		const FilterHelper = getAngularModule('FilterHelper');
		const entity = 'contact';

		const MailBounced = FilterHelper.filter('MailBounced', entity);
		MailBounced.inactive = false;
		MailBounced.value = 'hard';
		MailBounced.comparisonType = 'Equals';

		const HasEmail = FilterHelper.filter('HasEmail', entity);
		HasEmail.inactive = false;
		HasEmail.value = true;

		const JourneyStep = FilterHelper.filter('JourneyStep', entity);
		JourneyStep.inactive = false;
		JourneyStep.value = ['customer'];

		const ClientOrderFilters = FilterHelper.filter('ClientOrderFilters', entity);
		ClientOrderFilters.inactive = false;
		const orderFilterValue = ClientOrderFilters.value as { [key: string]: any };
		orderFilterValue.OrderRowProduct.value = [productId];
		ClientOrderFilters.value = orderFilterValue;

		const ClientAgreementFilters = FilterHelper.filter('ClientAgreementFilters', entity);
		ClientAgreementFilters.inactive = false;
		const agreementFilterValue = ClientAgreementFilters.value as { [key: string]: any };
		agreementFilterValue.AgreementRowProduct.value = [productId];
		ClientAgreementFilters.value = agreementFilterValue;

		const includeConfig = { HasEmail, JourneyStep };
		const excludeConfig = { MailBounced, ClientOrderFilters, ClientAgreementFilters };

		const includeBody = FilterHelper.parseFilters(includeConfig, entity, undefined, undefined, {
			useTags: true,
			groupAllFilters: true
		}).build() as SegmentFilter['body'];

		const excludeBody = FilterHelper.parseFilters(excludeConfig, entity, undefined, undefined, {
			useTags: true,
			groupAllFilters: true
		}).build() as SegmentFilter['body'];

		return [
			{ config: includeConfig, body: includeBody, isExclude: false, orGroup: false },
			{ config: excludeConfig, body: excludeBody, isExclude: true, orGroup: true }
		];
	}
};

export default template;
