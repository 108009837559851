import React, { useMemo, useEffect, useState } from 'react';
import T from 'Components/Helpers/translate';
import { Footer, Row, Text } from '@upsales/components';
import BemClass from '@upsales/components/Utils/bemClass';
import SubscriptionPreviewGraph from 'App/components/SubscriptionPreviewGraph';
import { useSubscriptionGroupContext } from 'App/components/EditSubscription/Context/SubscriptionGroupContext';
import { formatSubscriptionPeriod } from 'App/components/EditSubscription/Context/SubscriptionPeriodContextHelpers';
import { getAgreementPeriodText } from 'App/helpers/subscriptionHelper';
import SubscriptionHotspot from '../SubscriptionHotspot';
import OrderRow from 'App/resources/Model/OrderRow';
import moment from 'moment';
import { NewSubscriptionTracker } from 'Helpers/Tracker';

import './SubscriptionPeriodFooter.scss';

type Props = {
	hideGreenBox?: boolean;
	agreementsToShowDiffOrder?: number[];
	oneOffs?: (OrderRow & { totalValue: number })[];
};

const SubscriptionSummaryFooter = ({ hideGreenBox, agreementsToShowDiffOrder, oneOffs = [] }: Props) => {
	const classes = new BemClass('SubscriptionSummaryFooter');
	const {
		state: { subscriptionMap, currentUUID, childIndexNr, isEdit, showPreviewFooter },
		nextActivePeriod,
		currentPeriod,
		activePeriod,
		firstPeriod,
		expandPreviewFooter
	} = useSubscriptionGroupContext();

	const [firstRender, setFirstRender] = useState(true);
	const [userCollapsed, setUserCollapsed] = useState(false);

	const agreements = useMemo(
		() => Object.values(subscriptionMap).map(p => formatSubscriptionPeriod(p, false, true)),
		[
			JSON.stringify(subscriptionMap) // -_('.')_-
		]
	);

	const orderRows = Object.values(subscriptionMap).flatMap(period => [
		...period.orderRows,
		...(period.children?.flatMap(child => child.orderRow) ?? [])
	]);

	const products = orderRows
		.flatMap(row => row.product)
		.map(product => product?.id)
		.filter(Boolean);

	const sumValue = orderRows.reduce((sum, row) => sum + (row.orderRowTotal ?? 0), 0);

	useEffect(() => {
		const expand = () => {
			expandPreviewFooter(true, false);
			NewSubscriptionTracker.startTrackTime('autoOpenGraph');
		};

		if (products.length && !firstRender && !userCollapsed) {
			expand();
		}
		setFirstRender(false);
	}, [products.length, Object.values(subscriptionMap).length, sumValue]);

	const setExpanded = (v: boolean) => {
		if (!userCollapsed && !v) {
			setUserCollapsed(true);
		}
		expandPreviewFooter(v);
	};

	const [{ uuid: firstUUID }] = agreements;
	const boxText = getAgreementPeriodText(subscriptionMap[currentUUID], currentUUID === firstUUID);
	const extraDiffOrderValue = oneOffs.reduce((sum, curr) => sum + curr.totalValue, 0);

	const hasSplitFlag = Tools.FeatureHelper.hasSoftDeployAccess('SUBSCRIPTION_SPLITS');
	const hasAlreadyStarted = (activePeriod.orderSequenceNr ?? 0) > 0;

	const getTitleElement = () => {
		let nextOrderDate = T('agreement.noNextOrderDate');
		if (isEdit && hasAlreadyStarted) {
			const currentNextOrderDate = activePeriod.nextOrderPeriodStart
				? moment(activePeriod.nextOrderPeriodStart).subtract(activePeriod.offset, 'days')
				: null;
			const endDate = activePeriod.endDate ? moment(activePeriod.endDate) : null;
			const endDateIsBeforeNextOrderDate = endDate && endDate.isSameOrBefore(activePeriod.nextOrderPeriodStart);

			let nextOrderDateMoment =
				currentNextOrderDate && !endDateIsBeforeNextOrderDate ? currentNextOrderDate : null;
			if (endDateIsBeforeNextOrderDate && nextActivePeriod) {
				nextOrderDateMoment = moment(nextActivePeriod.invoiceStartDate).subtract(
					nextActivePeriod.offset,
					'days'
				);

				if (nextOrderDateMoment.isBefore()) {
					nextOrderDateMoment = nextOrderDateMoment.add(nextActivePeriod.orderInterval, 'months');
				}
			}
			if (nextOrderDateMoment) {
				nextOrderDate = nextOrderDateMoment.isAfter() ? nextOrderDateMoment.format('L') : moment().format('L');
			}
		} else {
			const startDateIncludingOffset = moment(firstPeriod.invoiceStartDate).subtract(firstPeriod.offset, 'days');
			if (startDateIncludingOffset.isAfter()) {
				nextOrderDate = startDateIncludingOffset.format('L');
			} else {
				nextOrderDate = moment().format('L');
			}
		}

		return (
			<div className={classes.elem('footer-title').b()}>
				<div>
					<Text size="sm" color="grey-11">
						<Row align="center">
							{T('subscription.modal.summary.footer.nextOrder')}
							<SubscriptionHotspot type="order" />
						</Row>
					</Text>
					<Text size="lg" bold>
						{nextOrderDate}
					</Text>
				</div>
				<Text>{T('subscription.modal.summary.footer.overview')}</Text>
			</div>
		);
	};
	return (
		<Footer
			className={classes.mod({ hasSideBar: hasSplitFlag && !hideGreenBox }).b()}
			expandableProps={{
				title: hasSplitFlag ? getTitleElement() : T('agreement.subscriptionPreview.headline'),
				shadowDirection: 'top',
				expandedHeight: 310,
				collapsedHeight: hasSplitFlag ? 60 : 40,
				expanded: showPreviewFooter,
				setExpanded
			}}
		>
			<SubscriptionPreviewGraph
				agreements={agreements}
				hideTitle
				greenBox={!hideGreenBox && !!(agreements.length > 1 || currentPeriod.children?.length)}
				currentUUID={currentUUID}
				childIndexNr={childIndexNr}
				currentPeriod={currentPeriod}
				boxText={boxText}
				agreementsToShowDiffOrder={agreementsToShowDiffOrder}
				isEdit={isEdit}
				extraDiffOrderValue={extraDiffOrderValue}
			/>
		</Footer>
	);
};

export default SubscriptionSummaryFooter;
