import React from 'react';
import PropTypes from 'prop-types';

import bemClass from '@upsales/components/Utils/bemClass';
import { Label, Headline, Text, Input, Card, CardContent, Loader, Button, Toggle } from '@upsales/components';
import './MailEditorReviewHeader.scss';
import translate from 'Components/Helpers/translate';
import UpSelect from 'Components/Inputs/UpSelect';
import GroupMailAttr from 'Attributes/MailCampaignAttributes';

const NAME_LENGTH = GroupMailAttr.name.size;

class MailEditorReviewHeader extends React.PureComponent {
	updateIframe = () => {
		this._iframe.contentWindow.document.open();
		this._iframe.contentWindow.document.write(this.props.html);
		this._iframe.contentWindow.document.close();
	};
	componentDidMount() {
		if (this.props.html) {
			this.updateIframe();
		}
	}
	componentDidUpdate(oldProps) {
		if (this.props.html !== oldProps.html) {
			this.updateIframe();
		}
	}
	render() {
		const {
			disabled,
			value,
			html,
			loadingMiniPreview,
			onChangeBounced,
			label,
			title,
			text,
			focusInput,
			onEdit,
			onPreviewInBrowser,
			loadingPreviewBrowser,
			onPrivateChange,
			isPrivate,
			groupMailCategories,
			onChangedGroupMailCategory,
			groupMailCategoryId
		} = this.props;
		const classes = new bemClass('MailEditorReviewHeader');

		return (
			<Card className={classes.b()} slice="grey-10">
				<CardContent>
					<Headline size="sm">{title}</Headline>
					<Text color="grey-10">{text}</Text>

					<Label required maxLength={NAME_LENGTH} value={value}>
						{label}
					</Label>

					<Input
						value={value}
						maxLength={NAME_LENGTH}
						onChange={e => onChangeBounced(e.target.value)}
						state={!value ? 'error' : null}
						inputRef={r => (this._name = r)}
						disabled={disabled}
						autofocus={focusInput}
					/>

					{groupMailCategories ? (
						<>
							<Label required>{translate('groupMail.whatTypeOfMailIsThis')}</Label>
							<UpSelect
								defaultValue={groupMailCategoryId}
								options={{ data: groupMailCategories }}
								getId={obj => obj.id}
								onChange={e => onChangedGroupMailCategory(e.target.added?.id)}
								className={!groupMailCategoryId ? 'error' : ''}
								placeholder={translate('groupMail.selectGroupMailCategory')}
								required
							/>
						</>
					) : null}

					{onPrivateChange ? (
						<div className={classes.elem('toggle-wrap').b()}>
							<Label>{translate('mail.privateTemplate')}</Label>
							<div className={classes.elem('toggle-inner-wrap').b()}>
								<Toggle checked={isPrivate} onChange={onPrivateChange} color="medium-green" />
							</div>
							<Text>{translate('mailTemplate.onlyVisibleForYou')}</Text>
						</div>
					) : null}

					<div
						className={classes
							.elem('preview-wrap')
							.mod({ visible: html || loadingMiniPreview })
							.b()}
					>
						<div className={classes.elem('preview').mod({ loaded: !loadingMiniPreview }).b()}>
							<div className={classes.elem('preview-buttons').b()}>
								<Button gradient={true} block={true} size="sm" onClick={onEdit}>
									{translate('groupMail.editMail')}
								</Button>
								<Button
									gradient={true}
									block={true}
									size="sm"
									onClick={onPreviewInBrowser}
									loading={loadingPreviewBrowser}
								>
									{translate('mail.previewInBrowser')}
								</Button>
							</div>
							<iframe ref={r => (this._iframe = r)} />
						</div>

						<Loader
							size="sm"
							noU={true}
							className={classes.elem('preview-loader').mod({ visible: loadingMiniPreview }).b()}
						/>
					</div>
				</CardContent>
			</Card>
		);
	}
}

MailEditorReviewHeader.propTypes = {
	disabled: PropTypes.bool,
	value: PropTypes.string,
	html: PropTypes.string,
	loadingMiniPreview: PropTypes.bool,
	onChange: PropTypes.func,
	onChangeBounced: PropTypes.func.isRequired,
	label: PropTypes.string,
	title: PropTypes.string,
	text: PropTypes.string,
	focusInput: PropTypes.bool,
	onEdit: PropTypes.func,
	onPreviewInBrowser: PropTypes.func,
	loadingPreviewBrowser: PropTypes.bool,
	onPrivateChange: PropTypes.func,
	isPrivate: PropTypes.bool
};

export default MailEditorReviewHeader;
