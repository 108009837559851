import SocialEvent from 'App/resources/Model/SocialEvent';
import _ from 'lodash';
import LZString from 'lz-string';
import { AnyAction } from 'redux';

export enum TABS {
	PASSED = 'passed',
	DETAILS = 'details',
	CONTACTS = 'contacts',
	RESOURCES = 'resources',
	REVIEW = 'review'
}

export enum STATUS_FILTER {
	ALL = 'all',
	INVITED = 'invited',
	ATTENDING = 'attending',
	ATTENDED = 'attended',
	DECLINED = 'declined',
	ERROR = 'error',
	WAITING_LIST = 'waitingList',
	ATTENDING_ON_DEMAND = 'attendingOnDemand',
	ATTENDED_ON_DEMAND = 'attendedOnDemand'
}

export enum SORT_FIELDS {
	NAME = 'name',
	INTERNAL_NAME = 'internalName',
	DESCRIPTION = 'description',
	WAITING_LIST_DATE = 'waitingListDate',
	DATE = 'date',
	END_DATE = 'endDate'
}

// Move further up later (to resource)
export type OptIn = {
	id: number;
};

// Move further up later (to resource)
export type AccountProfile = {};

// Move further up later (to resource)
export type MailAccount = {};

// Move further up later (to resource)
export type Client = {
	id: number;
	name: string;
};

// Move further up later (to resource)
export type Contact = {
	id: number;
	name: string;
	inviteMailError?: string | null;
	email: string;
	client: Client;
};

export type SocialEventState = {
	loading: boolean;
	saving: boolean;
	edit: SocialEvent | {};
	initialEvent: SocialEvent | {};
	editId: number | null;
	contacts: Contact[];
	segments: [];
	contactsTotal: number;
	selectedTab: TABS;
	poller: null;
	searchStr: string;
	contactSort: {
		field: SORT_FIELDS;
		asc: boolean;
	};
	selectedContacts: {};
	pagination: {
		total: number;
		limit: number;
		offset: number;
	};
	pages: [];
	addedTotal: number;
	attendingTotal: number;
	attendedTotal: number;
	invitedTotal: number;
	attendingOnDemandTotal: number;
	attendedOnDemandTotal: number;
	declinedTotal: number;
	unsubscribedTotal: number;
	launching: boolean;
	launchProgress: number;
	selectedSegment: null;
	manualSelectedContact: null;
	setImportAsAttending: boolean;
	copyEvent: null;
	copyingEvent: boolean;
	eventUrl: { valid: boolean; touched: boolean };
	contactStatusFilter: STATUS_FILTER;
	declined: number;
	added: number;
	errorTotal: number;
	hasBadCustomDomain: boolean;
	waitingListTotal: number;
	hash: string | null;
	initialHash: string | null;
	accountProfile: AccountProfile | null;
	isMarketAdmin: boolean;
	mailAccount: MailAccount | null;
	domains: { [domain: string]: boolean };
	optIns: OptIn[];
	showValidState: boolean;
	segmentsLoading: boolean;
};

export const initialState: SocialEventState = {
	loading: true,
	saving: false,
	edit: {},
	initialEvent: {},
	editId: null,
	contacts: [],
	segments: [],
	contactsTotal: 0,
	selectedTab: TABS.DETAILS,
	poller: null,
	searchStr: '',
	contactSort: {
		field: SORT_FIELDS.NAME,
		asc: true
	},
	selectedContacts: {},
	pagination: { limit: 10, total: 0, offset: 0 },
	pages: [],
	addedTotal: 0,
	attendingTotal: 0,
	attendedTotal: 0,
	invitedTotal: 0,
	attendingOnDemandTotal: 0,
	attendedOnDemandTotal: 0,
	declinedTotal: 0,
	unsubscribedTotal: 0,
	launching: false,
	launchProgress: 0,
	selectedSegment: null,
	manualSelectedContact: null,
	setImportAsAttending: false,
	copyEvent: null,
	copyingEvent: false,
	eventUrl: { valid: true, touched: false },
	contactStatusFilter: STATUS_FILTER.ALL,
	declined: 0,
	added: 0,
	errorTotal: 0,
	hasBadCustomDomain: false,
	waitingListTotal: 0,
	hash: null,
	initialHash: null,
	accountProfile: null,
	isMarketAdmin: false,
	mailAccount: null,
	domains: {},
	optIns: [],
	showValidState: false,
	segmentsLoading: false
};

export const SELECT_TAB = '[Social event] Select Tab';
export const LOADING_CHANGED = '[Social event] Loading Changed';
export const SET_EVENT = '[Social event] Set Event';
export const SET_SEGMENTS = '[Social event] Set Segments';
export const SAVING_CHANGED = '[Social event] Saving Changed';
export const SET_EDIT_ID = '[Social event] Set Edit Id';
export const SET_CONTACTS = '[Social event] Set Contacts';
export const SET_CONTACTS_TOTAL = '[Social event] Set Contacts_total';
export const SET_PAGINATION = '[Social event] Set Pagination';
export const RESET_STATE = '[Social event] Reset State';
export const SET_POLLER = '[Social event] Set Poller';
export const SET_SEARCH_STR = '[Social event] Set Search_str';
export const SET_CONTACT_SORT = '[Social event] Set Contact Sort';
export const ADD_SELECTED_CONTACTS = '[Social event] Add selected contacts';
export const SET_SELECTED_CONTACTS = '[Social event] Set selected contacts';
export const SET_LIMIT = '[Social event] Set Limit';
export const SET_OPTINS = '[Social event] Set Optins';
export const SET_ATTENDING_TOTAL = '[Social event] Set Attending Total';
export const SET_ATTENDED_TOTAL = '[Social event] Set Attended Total';
export const SET_INVITED_TOTAL = '[Social event] Set Invited Total';
export const SET_ATTENDING_ON_DEMAND_TOTAL = '[Social event] Set Attending On Demand Total';
export const SET_ATTENDED_ON_DEMAND_TOTAL = '[Social event] Set Attended On Demand Total';
export const SET_LAUNCHING = '[Social event] Set Launching';
export const SET_LAUNCH_PROGRESS = '[Social event] Set Launch_progress';
export const SHOW_VALID_STATE = '[Social event] Show Valid State';
export const SET_INITIAL_EVENT = '[Social event] Set Unsaved Event';
export const SET_SELECTED_SEGMENT = '[Social event] Set Selected Segment';
export const SET_MANUAL_SELECTED_CONTACT = '[Social event] Set Manual Selected Contact';
export const SET_SET_IMPORT_AS_ATTENDING = '[Social event] Set Set Import As Attending';
export const SET_COPY_EVENT = '[Social event] Set Copy Event';
export const SET_COPYING_EVENT = '[Social event] Set Copying Event';
export const SET_SOCIAL_EVENT_DOMAIN_URL = '[Social event] Set Social Event Domain Url';
export const SET_CONTACT_STATUS_FILTER = '[Social event] Set Contact Status Filter';
export const SET_DECLINED_TOTAL = '[Social event] Set Declined Total';
export const SET_ADDED_TOTAL = '[Social event] Set Added Total';
export const SET_ERROR_TOTAL = '[Social event] Set Error Total';
export const SET_HAS_BAD_CUSTOM_DOMAIN = '[Social event] Set Has Bad Custom Domain';
export const SET_UNSUBSCRIBED_TOTAL = '[Social event] Set Unsubscribed Total';
export const SET_WAITING_LIST_TOTAL = '[Social event] Set Waiting List Total';
export const SET_ACCOUNT_PROFILE = '[Social event] Set Account Profile';
export const SET_SEGMENTS_LOADING = '[Social event] Set Segments Loading';
export const SET_MAIL_ACCOUNT = '[Social event] Set Mail Account';
export const SET_DOMAINS = '[Social event] Set Domains';
export const SET_IS_MARKET_ADMIN = '[Social event] Set Is Market Admin';

const getSortedJsonHash = (json: Partial<SocialEvent>) => {
	delete json.userRemovable;
	delete json.userEditable;

	return LZString.compressToBase64(JSON.stringify(json, Object.keys(json).sort()));
};

const reducer = (state = initialState, action: AnyAction): SocialEventState => {
	let event;
	switch (action.type) {
		case LOADING_CHANGED:
			return { ...state, loading: action.value };
		case SET_EDIT_ID:
			return { ...state, editId: action.value };
		case SAVING_CHANGED:
			return { ...state, saving: action.value };
		case SET_EVENT:
			event = { ...state.edit, ...action.data };
			return { ...state, edit: event, hash: getSortedJsonHash(_.cloneDeep(event)) };
		case SET_INITIAL_EVENT:
			event = { ...action.data };
			return { ...state, initialEvent: event, initialHash: getSortedJsonHash(event) };
		case SELECT_TAB:
			return { ...state, selectedTab: action.value };
		case SET_CONTACTS:
			return { ...state, contacts: action.contacts };
		case SET_OPTINS:
			return { ...state, optIns: action.optIns };
		case SET_CONTACTS_TOTAL:
			return { ...state, contactsTotal: action.value };
		case SET_SEGMENTS:
			return { ...state, segments: action.segments };
		case RESET_STATE:
			return { ...initialState, segments: state.segments };
		case SET_POLLER:
			return { ...state, poller: action.poller };
		case SET_PAGINATION:
			return { ...state, pagination: action.pagination };
		case SET_SEARCH_STR:
			return { ...state, searchStr: action.value };
		case SET_CONTACT_SORT:
			return { ...state, contactSort: action.sort };
		case ADD_SELECTED_CONTACTS:
			return { ...state, selectedContacts: { ...action.selectedContacts } };
		case SET_SELECTED_CONTACTS:
			return { ...state, selectedContacts: action.selected };
		case SET_LIMIT:
			return { ...state, pagination: { ...state.pagination, limit: action.limit } };
		case SET_ATTENDING_TOTAL:
			return { ...state, attendingTotal: action.value };
		case SET_ATTENDED_TOTAL:
			return { ...state, attendedTotal: action.value };
		case SET_INVITED_TOTAL:
			return { ...state, invitedTotal: action.value };
		case SET_ATTENDING_ON_DEMAND_TOTAL:
			return { ...state, attendingOnDemandTotal: action.value };
		case SET_ATTENDED_ON_DEMAND_TOTAL:
			return { ...state, attendedOnDemandTotal: action.value };
		case SET_LAUNCHING:
			return { ...state, launching: action.value };
		case SET_LAUNCH_PROGRESS:
			return { ...state, launchProgress: action.value };
		case SHOW_VALID_STATE:
			return { ...state, showValidState: action.value };
		case SET_SELECTED_SEGMENT:
			return { ...state, selectedSegment: action.value };
		case SET_MANUAL_SELECTED_CONTACT:
			return { ...state, manualSelectedContact: action.value };
		case SET_SET_IMPORT_AS_ATTENDING:
			return { ...state, setImportAsAttending: action.value };
		case SET_COPY_EVENT:
			return { ...state, copyEvent: action.value };
		case SET_COPYING_EVENT:
			return { ...state, copyingEvent: action.value };
		case SET_SOCIAL_EVENT_DOMAIN_URL:
			return { ...state, eventUrl: { ...state.eventUrl, ...action.eventUrl } };
		case SET_CONTACT_STATUS_FILTER:
			return { ...state, contactStatusFilter: action.value };
		case SET_DECLINED_TOTAL:
			return { ...state, declinedTotal: action.value };
		case SET_ADDED_TOTAL:
			return { ...state, addedTotal: action.value };
		case SET_ERROR_TOTAL:
			return { ...state, errorTotal: action.value };
		case SET_HAS_BAD_CUSTOM_DOMAIN:
			return { ...state, hasBadCustomDomain: action.value };
		case SET_WAITING_LIST_TOTAL:
			return { ...state, waitingListTotal: action.value };
		case SET_UNSUBSCRIBED_TOTAL:
			return { ...state, unsubscribedTotal: action.value };
		case SET_ACCOUNT_PROFILE:
			return { ...state, accountProfile: action.profile };
		case SET_SEGMENTS_LOADING:
			return { ...state, segmentsLoading: action.value };
		case SET_MAIL_ACCOUNT:
			return { ...state, mailAccount: action.account };
		case SET_DOMAINS:
			return { ...state, domains: action.domains };
		case SET_IS_MARKET_ADMIN:
			return { ...state, isMarketAdmin: action.isMarketAdmin };
		default:
			return state;
	}
};

export default reducer;
