import React, { PureComponent, Fragment } from 'react';
import PropTypes from 'prop-types';
import { phoneNumberHelper } from '@upsales/common';
import intlTelInput from 'intl-tel-input';
import bemClass from '@upsales/components/Utils/bemClass';
import { Input, Text, Block } from '@upsales/components';
import './PhoneInput.scss';
// eslint-disable-next-line no-useless-escape
const phoneRegex = /^[+]*[(]{0,1}[0-9]{1,4}[)]{0,1}[-\s\./0-9]*$/;

const DEFAULT_COUNTRY = 'se';
const TOP_COUNTRIES = ['se', 'no', 'dk', 'fi', 'de', 'gb', 'us'];

const parsePhoneNumber = (number, countryCode) => {
	const dialCode = phoneNumberHelper.getDialCodeForNumberAndCountryCode(number, countryCode);
	let phone = phoneNumberHelper.format(number, countryCode, 'INTERNATIONAL');

	if (dialCode) {
		phone = phone.substring(`+${dialCode}`.length);
	}

	return { phone, dialCode };
};

const getPhoneNumberCountryCode = (number, defaultCountry, isNew) => {
	const countryCode = phoneNumberHelper.getCountryCodeForNumber(number);

	if (isNew || !countryCode) return defaultCountry || DEFAULT_COUNTRY;

	return countryCode.toString();
};

const formatNumber = (phone, countryNumber, iti) => {
	const iso = iti ? iti.getSelectedCountryData().iso2 : countryNumber;
	const cCode = phoneNumberHelper.getCountryCodeForNumber(phone);
	const countryCode = cCode ? cCode : iso;
	return phoneNumberHelper.format(phone, countryCode, 'E164');
};

// Will return formated phoneNumber (if valid) or the string that was provided
// This is the same formatting that is used in the init process of the component
// This can be used to do pre-formatting before providing the initial-value to prevent a init onChange
export const getFormatedPhoneNumber = possiblePhoneNumber => {
	if (!possiblePhoneNumber) {
		return possiblePhoneNumber;
	}

	const phoneNumberCountryCode = getPhoneNumberCountryCode(possiblePhoneNumber);

	const phone = parsePhoneNumber(possiblePhoneNumber, phoneNumberCountryCode).phone;

	const formatedPhoneNumber = formatNumber(phone, 'se');

	const isValid =
		phone?.length && phone[0] === ' '
			? phoneNumberHelper.isPossibleNumber(formatedPhoneNumber)
			: phoneRegex.test(formatedPhoneNumber);

	if (isValid) {
		return formatedPhoneNumber;
	}

	return possiblePhoneNumber;
};

export default class PhoneInput extends PureComponent {
	defaultCountry = Tools.AppService.getSelf().userParams.locale.toString().split('-')[1];

	topCountries = Tools.AppService.getTopCountries()?.length ? Tools.AppService.getTopCountries() : TOP_COUNTRIES;

	defaultCountryCode = phoneNumberHelper.getCountryCodeForRegion(this.defaultCountry);

	constructor(p) {
		super(p);
		this.state = {
			iti: null,
			phone: null
		};

		const t = Tools.$translate;
		this.lang = {
			phoneNumberCodeTip: t('inlineInput.countryCodeTip')
		};
		this.inputRef = React.createRef();
	}

	componentDidMount() {
		setTimeout(() => {
			this.init();
		}, 100);
	}

	init() {
		const phoneNumberCountryCode =
			this.props.countryCode ??
			getPhoneNumberCountryCode(this.props.phone, this.defaultCountry, this.props.isNew);
		let preferredCountries = this.topCountries.slice(0, 7);

		if (this.defaultCountry) {
			preferredCountries = preferredCountries.filter(
				country => country.toLowerCase() !== this.defaultCountry.toLowerCase()
			);
			preferredCountries.unshift(this.defaultCountry);
		}

		const phone = parsePhoneNumber(this.props.phone, phoneNumberCountryCode).phone;
		const input = this.inputRef.current;
		let iti = null;
		if (input) {
			iti = intlTelInput(input, {
				preferredCountries: preferredCountries,
				initialCountry: phoneNumberCountryCode,
				separateDialCode: true
			});
			input.addEventListener('countrychange', e => {
				const countryCode = this.state.iti.getSelectedCountryData().iso2;
				const parsedPhoneNumberObj = parsePhoneNumber(e.target.value, countryCode);
				this.phoneOnChange(parsedPhoneNumberObj.phone);
			});
		}

		this.setState({ iti, phone });
		const formatedPhoneNumber = formatNumber(phone, null, iti);
		const isValid =
			this.props.isNew || (this.state.phone?.length && this.state.phone[0] === ' ')
				? phoneNumberHelper.isPossibleNumber(formatedPhoneNumber)
				: phoneRegex.test(formatedPhoneNumber);

		const shouldUpdate = isValid && this.props.phone !== formatedPhoneNumber;

		//Check if it's a new value and if it's valid
		//bugs when canceling where it thinks a non formatted phone number is a change
		if (shouldUpdate && this.props.isNew) {
			this.props.onChange(formatedPhoneNumber);
		}
	}

	phoneOnChange = e => {
		const number = e.target ? e.target.value : e;
		const { iti } = this.state;
		// eslint-disable-next-line no-useless-escape
		if (!/^[0-9 \(\)\+\-]*$/.test(number)) {
			return;
		}

		const iso = iti ? iti.getSelectedCountryData().iso2 : null;
		const countryCode = phoneNumberHelper.getCountryCodeForNumber(number)
			? phoneNumberHelper.getCountryCodeForNumber(number).toLowerCase()
			: iso;
		const parsedPhoneNumberObj = parsePhoneNumber(number, countryCode);
		this.setState(
			{
				phone: iti ? parsedPhoneNumberObj.phone : number
			},
			() => {
				if (iti && countryCode) {
					iti.setCountry(countryCode);
				}
			}
		);

		const parsedNumber = number.length > 3 ? phoneNumberHelper.format(number, countryCode, 'E164') : number;
		this.props.onChange(parsedNumber, countryCode);
	};

	parsePhoneNumber = (number, countryCode) => parsePhoneNumber(number, countryCode);

	formatNumber = (number, countryNumber) => {
		const { iti } = this.state;
		const phone = number ? number : this.state.phone;
		const iso = iti ? iti.getSelectedCountryData().iso2 : countryNumber;
		const cCode = phoneNumberHelper.getCountryCodeForNumber(phone);
		const countryCode = cCode ? cCode : iso;

		return phoneNumberHelper.format(phone, countryCode, 'E164');
	};

	getPhoneNumberCountryCode(number) {
		const { isNew } = this.props;
		const countryCode = phoneNumberHelper.getCountryCodeForNumber(number);

		if (isNew || !countryCode) return this.defaultCountry || DEFAULT_COUNTRY;

		return countryCode.toString();
	}

	onCopy = event => {
		event.clipboardData.setData('text/plain', formatNumber(this.state.phone, null, this.iti));
		event.preventDefault();
	};

	setCountryCode = () => {
		if (this.state.iti && this.state.iti.setCountry) {
			this.state.iti.setCountry(this.defaultCountry);
		}
	};

	componentDidUpdate() {
		const isValid =
			this.props.isNew || (this.state.phone?.length && this.state.phone[0] === ' ')
				? phoneNumberHelper.isPossibleNumber(formatNumber(this.state.phone, null, this.state.iti))
				: phoneRegex.test(formatNumber(this.state.phone, null, this.state.iti));
		if (this.isValidPrev !== isValid) {
			this.props.onValidChange?.(isValid);
		}
		this.isValidPrev = isValid;
	}

	render() {
		const { name, iconClass, icon, className, required, state } = this.props;
		//this.state.phone[0] will be " " if country code is selected
		const isValid =
			this.props.isNew || (this.state.phone?.length && this.state.phone[0] === ' ')
				? phoneNumberHelper.isPossibleNumber(formatNumber(this.state.phone, null, this.state.iti))
				: phoneRegex.test(formatNumber(this.state.phone, null, this.state.iti));
		const telInput = this.state.iti || {};
		const isCountryCode = this.defaultCountryCode && this.defaultCountry;
		const classes = new bemClass('PhoneInput', className + iconClass ? ' input-group' : '');

		let input = (
			<Input
				id={name}
				name={name}
				state={state || (this.state.phone && !isValid) ? 'error' : undefined}
				autoComplete="off"
				maxLength="30"
				icon={icon}
				required={required}
				value={this.state.phone || ''}
				onChange={this.phoneOnChange}
				onCopy={this.onCopy}
				className={iconClass ? undefined : classes.b()}
				inputRef={r => {
					this.inputRef.current = r;
					this.props.inputRef?.(r);
				}}
			/>
		);

		// Legacy
		if (iconClass) {
			input = (
				<div className={classes.b() + (this.state.phone && !isValid ? ' has-error' : '')}>
					{iconClass ? (
						<span className="input-group-addon">
							<i className={'fa fa-' + iconClass} />
						</span>
					) : null}
					{input}
				</div>
			);
		}

		return (
			<Fragment>
				{input}

				{this.state.phone && !telInput.defaultCountry && isCountryCode ? (
					<Block space="mtl">
						<Text size="sm">{this.lang.phoneNumberCodeTip}</Text>
					</Block>
				) : null}
			</Fragment>
		);
	}
}

PhoneInput.propTypes = {
	inputRef: PropTypes.func,
	required: PropTypes.bool,
	state: PropTypes.string,
	phone: PropTypes.string,
	countryCode: PropTypes.string,
	name: PropTypes.string,
	iconClass: PropTypes.string, // legacy
	icon: PropTypes.string,
	onChange: PropTypes.func,
	onValidChange: PropTypes.func,
	isNew: PropTypes.bool
};
