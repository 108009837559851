export const formatURL = function (url: string) {
	if (url) {
		if (url.startsWith('https://') || url.startsWith('http://') || url.startsWith('//')) {
			return url;
		} else {
			return '//' + url;
		}
	} else {
		return '';
	}
};
