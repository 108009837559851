import React from 'react';
import { Flex, Text } from '@upsales/components';
import { currencyFormat } from 'Components/Filters/Currencies';
import Client from 'App/resources/Model/Client';
import _ from 'lodash';

export default function CurrencyColumn({ account, field }: { account: Client; field: { path: string } }) {
	const currency = Tools.AppService.getMetadata().defaultCurrency?.iso || 'SEK';
	const value: number = _.get(account, field.path) ?? 0;

	return (
		<td className="DataSourceColumn currency">
			<Flex>
				<Text>{currencyFormat(value ?? 0, currency)}</Text>
			</Flex>
		</td>
	);
}
