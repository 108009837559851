import SalesboardResource from '../../resources/Salesboard';
import sortAlphabetically from '../../utils/sortAlphabetically';

export const initialState = {
	salesboards: [],
	sort: { field: 'title', asc: true },
	deleting: {}
};

const actionPrefix = '[AdminEditSalesboard]';
export const actions = {
	SORT: `${actionPrefix} SORT`,
	INIT: `${actionPrefix} INIT`,
	SET_SALESBOARDS: `${actionPrefix} SET_SALESBOARDS`,
	START_DELETE_SALEBOARD: `${actionPrefix} START_DELETE_SALEBOARD`,
	DELETE_SALEBOARD_DONE: `${actionPrefix} DELETE_SALEBOARD_DONE`,
	DELETE_SALEBOARD_FAILED: `${actionPrefix} DELETE_SALEBOARD_FAILED`
};

export const reducer = (state = initialState, action) => {
	switch (action.type) {
		case actions.INIT:
			return { ...initialState, ...action.data };
		case actions.SORT:
		case actions.SET_SALESBOARDS:
			return { ...state, ...action.data };
		case actions.START_DELETE_SALEBOARD: {
			return { ...state, deleting: { ...state.deleting, [action.data.id]: true } };
		}
		case actions.DELETE_SALEBOARD_DONE: {
			const id = action.data.id;
			const deleting = _.omit(state.deleting, [id]);
			const salesboards = _.filter(state.salesboards, salesboard => salesboard.id !== id);

			return { ...state, deleting, salesboards };
		}
		case actions.DELETE_SALEBOARD_FAILED: {
			const id = action.data.id;
			const deleting = _.omit(state.deleting, [id]);

			return { ...state, deleting };
		}
		default:
			return state;
	}
};

export const init = () => {
	const salesboards = Tools.AppService.getListViews('salesboard');
	const filteredSalesboards = _.filter(
		salesboards,
		salesboard => !salesboard.isStandardView && salesboard.standard
	).sort(sortAlphabetically('title'));

	return { type: actions.INIT, data: { salesboards: filteredSalesboards } };
};

export const deleteSalesboard = id => async dispatch => {
	dispatch({ type: actions.START_DELETE_SALEBOARD, data: { id } });

	try {
		await SalesboardResource.delete(id);
		dispatch({ type: actions.DELETE_SALEBOARD_DONE, data: { id } });
	} catch (error) {
		dispatch({ type: actions.DELETE_SALEBOARD_FAILED, data: { id } });
	}
};

export const doSort = sort => (dispatch, getState) => {
	const salesboards = getState().AdminSalesboard.salesboards;
	const sortedSalesboard = salesboards.slice().sort(sortAlphabetically(sort.field, !sort.asc));
	dispatch({ type: actions.SORT, data: { sort, salesboards: sortedSalesboard } });
};

export const setDefault = (value, salesboard) => async dispatch => {
	const updatedSalesboard = { ...salesboard, default: value };
	await SalesboardResource.save(updatedSalesboard);
	/* We fetch all salesboards again as the reource have updated them to have the correct default values */
	const salesboards = Tools.AppService.getListViews('salesboard');
	const filteredSalesboards = _.filter(
		salesboards,
		salesboard => !salesboard.isStandardView && salesboard.standard
	).sort(sortAlphabetically('title'));

	dispatch({ type: actions.SET_SALESBOARDS, data: { salesboards: filteredSalesboards } });
};

export const editSalesboard = salesboard => {
	const id = salesboard ? salesboard.id : null;
	Tools.$state.go('administration.salesboard', { id });
};

export default reducer;
