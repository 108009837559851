import React, { FC, useMemo } from 'react';

import { Button, Column, Icon, Input, Label, Row } from '@upsales/components';
import BemClass from '@upsales/components/Utils/bemClass';

import './SendWebhookHeader.scss';

interface Props {
	keyError?: boolean;
	onChange: (field: string, value: string) => void;
	pair: { key: string; value: string };
	remove: () => void;
	valueError?: boolean;
}

const SendWebhookHeader: FC<Props> = ({ keyError, valueError, pair, onChange, remove }) => {
	const bem = useMemo(() => new BemClass('SendWebhookHeader'), []);

	return (
		<Row className={bem.b()}>
			<Column>
				<Label required>Key</Label>
				<Input
					value={pair.key}
					state={keyError ? 'error' : null}
					onChange={e => onChange('key', e.target.value)}
				/>
			</Column>

			<Column>
				<Label required>Value</Label>
				<Input
					value={pair.value}
					state={valueError ? 'error' : null}
					onChange={e => onChange('value', e.target.value)}
				/>
			</Column>

			<Button
				hoverColor="red"
				onClick={() => {
					remove();
				}}
				type="link"
			>
				<Icon space="mbl" name="trash" />
			</Button>
		</Row>
	);
};

export default SendWebhookHeader;
