import React, { Fragment } from 'react';
import {
	ModalContent,
	Block,
	Row,
	Title,
	Textarea,
	ModalControls,
	Column,
	Text,
	ModalHeader,
	Input
} from '@upsales/components';
import T from 'Components/Helpers/translate';
import BemClass from '@upsales/components/Utils/bemClass';
import Category, { CategoryType } from 'App/resources/Model/Category';

type EditClientSkeletonProps = {
	id?: number;
	onClose: () => void;
	classes: BemClass;
	categoriesByType: { [id: number]: Category[] };
	categoryTypes: CategoryType[];
	hasJourney: boolean;
	hasPriceLists: boolean;
	hasNewFields: boolean;
	groupedStandardFields: {
		standard: string[];
		social: string[];
	};
};

const f = () => (
	<Fragment>
		<Text loading></Text>
		<Input disabled />
	</Fragment>
);

const renderStandardFields = (classes: BemClass, fieldNames: string[]) => (
	<Row>
		{fieldNames.map((name, i) => (
			<Fragment key={name}>
				<Column className={classes.elem('half-col').b()}>
					<Block space="mbxl">{f()}</Block>
				</Column>
				{i % 2 ? null : <Column className={classes.elem('spacer').b()} />}
			</Fragment>
		))}
	</Row>
);

export default ({
	id,
	onClose,
	classes,
	categoriesByType,
	categoryTypes,
	hasJourney,
	hasPriceLists,
	hasNewFields,
	groupedStandardFields
}: EditClientSkeletonProps) => {
	return (
		<Fragment>
			<ModalHeader
				title={`${T(id ? 'default.edit' : 'default.create')} ${T('default.account').toLowerCase()}`}
				icon={id ? 'edit' : 'home'}
				onClose={onClose}
			/>
			<ModalContent>
				<Block space="mbxl">{f()}</Block>
				<Block space="mbxl">
					<Row>
						<Column>{f()}</Column>
						<Column className={classes.elem('spacer').b()} />
						<Column>{f()}</Column>
					</Row>
				</Block>
				<Block space="mbxl">
					<Row>
						<Column>{f()}</Column>
						<Column className={classes.elem('spacer').b()} />
						<Column>{f()}</Column>
					</Row>
				</Block>
				<Block space={categoryTypes.length ? '' : 'mbxl'}>
					<Row>
						{categoriesByType[0].length ? <Column>{f()}</Column> : null}
						{categoriesByType[0].length ? <Column className={classes.elem('spacer').b()} /> : null}
						<Column>{f()}</Column>
					</Row>
				</Block>
				{categoryTypes.length ? (
					<Block space="mbxl">
						<Row>
							{categoryTypes.map(({ id }, i) =>
								categoriesByType[id].length ? (
									<Fragment key={id}>
										<Column className={classes.elem('half-col').b()}>
											<Block space="mtxl">{f()}</Block>
										</Column>
										{i % 2 ? null : <Column className={classes.elem('spacer').b()} />}
									</Fragment>
								) : null
							)}
						</Row>
					</Block>
				) : null}
				{hasJourney || hasPriceLists ? (
					<Block space="mbxl">
						{hasJourney ? (
							<>
								<Column className={classes.elem('half-col').b()} />
								<Column className={classes.elem('spacer').b()} />
							</>
						) : null}
						{hasPriceLists ? <Column /> : null}
					</Block>
				) : null}
				{hasNewFields && groupedStandardFields.standard.length ? (
					<Block space="mbxl">
						<Block space="mbl">
							<Title loading />
						</Block>
						{renderStandardFields(classes, groupedStandardFields.standard)}
					</Block>
				) : null}

				{hasNewFields && groupedStandardFields.social.length ? (
					<Block space="mbxl">
						<Block space="mbl">
							<Title loading />
						</Block>
						{renderStandardFields(classes, groupedStandardFields.social)}
					</Block>
				) : null}
				<Block>
					<Block space="mbl">
						<Title loading />
					</Block>
					<Textarea disabled value="" />
				</Block>
			</ModalContent>
			<ModalControls />
		</Fragment>
	);
};
