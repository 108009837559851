import Resource from './Resource';

class DataSource extends Resource {
	constructor() {
		super('function/datasource');

		this.eventName = 'datasource';

		const errorHandeler = error => {
			if (_.get(error, 'error.errorCode') === 55) {
				return { title: 'default.error', body: 'error.notEnoughtCredits' };
			} else {
				return { title: 'default.error', body: 'default.errorIntegration' };
			}
		};

		this.notifications = {
			buyError: errorHandeler,
			monitorError: errorHandeler
		};
	}

	typeahead(params) {
		return this._postRequest('typeahead', params, { methodName: 'typeahead' }).then(res => res.data);
	}

	settings(params = {}) {
		return this._postRequest('settings', params, { methodName: 'settings' }).then(res => res.data);
	}

	buy(params) {
		if (!_.isPlainObject(params)) {
			return Tools.$q.reject('no params');
		}

		return this._postRequest('buy', params, { methodName: 'buy' }).then(res => res.data);
	}

	monitor(params) {
		if (!_.isPlainObject(params)) {
			return Tools.$q.reject('no params');
		}

		return this._postRequest('monitor', params, { methodName: 'monitor' }).then(res => res.data);
	}
}

const resource = new DataSource();

window.Tools.DataSource = resource;

export default resource;
