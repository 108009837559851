import React, { Component } from 'react';
import T from 'Components/Helpers/translate';
import PropTypes from 'prop-types';
import { Icon, Text } from '@upsales/components';
import './UpAudio.scss';
import bemClass from '@upsales/components/Utils/bemClass';
class UpAudio extends Component {
	static propTypes = {
		src: PropTypes.string,
		isTimeline: PropTypes.bool,
		unmount: PropTypes.func
	};
	constructor(props) {
		super(props);
		this.state = {
			playing: false,
			played: 0,
			duration: 0,
			state: 'LOADING',
			src: props.src
		};
	}
	componentDidMount() {
		if (this._audio) {
			this._audio.addEventListener('play', this.togglePlayingState);
			this._audio.addEventListener('pause', this.togglePlayingState);
			this._audio.addEventListener('timeupdate', this.timeUpdate);
			this._audio.addEventListener('canplaythrough', this.updateDuration);
			this._audio.addEventListener('loadeddata', this.loadedData);
			this.getSoundFile();
		}
	}

	componentWillUnmount() {
		if (this._audio) {
			this._audio.removeEventListener('play', this.togglePlayingState);
			this._audio.removeEventListener('pause', this.togglePlayingState);
			this._audio.removeEventListener('timeupdate', this.timeUpdate);
			this._audio.removeEventListener('canplaythrough', this.updateDuration);
			this._audio.removeEventListener('loadeddata', this.loadedData);
		}
	}

	shouldComponentUpdate() {
		return !this.draging;
	}
	getSoundFile = () => {
		const req = new XMLHttpRequest();
		req.open('GET', this.props.src);
		req.responseType = 'blob';
		req.withCredentials = true;
		req.onload = () => {
			if (req.status >= 400 || !req.response || !req.response.size) {
				this.onError();
			} else {
				const URL = window.URL || window.webkitURL;
				if (this._audio) {
					this._audio.load();
				}
				this.setState({ src: URL.createObjectURL(req.response) });
			}
		};
		req.onerror = () => {
			this.onError();
		};
		req.send(null);
	};

	formatTime = sec => {
		const secNum = parseInt(sec, 10); // don't forget the second param
		//gets HH:MM:SS
		const time = new Date(secNum * 1000).toISOString().substr(11, 8);
		const timeArr = time.split(':');
		return timeArr[0] !== '00' ? time : timeArr[1] + ':' + timeArr[2];
	};
	getPosition = element => {
		return element.getBoundingClientRect().left;
	};

	togglePlayingState = () => {
		this.setState({ playing: !this._audio.paused });
	};

	togglePlaying = e => {
		e.stopPropagation();
		if (this._audio) {
			if (this._audio.paused) {
				this._audio.play();
			} else {
				this._audio.pause();
			}
		}
	};

	timeUpdate = () => {
		this.setState({ played: this._audio.currentTime });
	};

	updateDuration = () => {
		this.setState({ duration: this._audio.duration });
	};

	setRef = (name, ref) => {
		this[name] = ref;
	};

	moveTracker = e => {
		let draged = e.clientX - this.getPosition(this._timeline);
		const timelineWidth = this._timeline.clientWidth;

		draged = Math.min(draged, timelineWidth);
		draged = Math.max(draged, 0);
		const percent = draged / timelineWidth;
		const newLeft = percent * 100 + '%';

		this._audio.currentTime = percent * this.state.duration;

		this._tracker.style.left = newLeft;
		this._progress.style.width = newLeft;
	};

	mouseDown = () => {
		this.draging = true;
		window.addEventListener('mousemove', this.moveTracker, true);
		window.addEventListener('mouseup', this.dragEnd);
		this._audio.removeEventListener('timeupdate', this.timeUpdate);
	};

	dragEnd = e => {
		e.stopPropagation();
		this.draging = false;
		this._audio.addEventListener('timeupdate', this.timeUpdate);

		this.moveTracker(e);

		window.removeEventListener('mousemove', this.moveTracker, true);
		window.removeEventListener('mouseup', this.dragEnd);
	};

	loadedData = () => {
		this.setState({ state: 'LOADED' });
	};

	onError = () => {
		this.setState({ state: 'ERROR' });
	};

	render() {
		const leftPercent = ((this.state.played || 0) / this.state.duration) * 100 + '%';
		let content = null;
		const classes = new bemClass('UpAudio');

		switch (this.state.state) {
			case 'LOADING':
				content = <ReactTemplates.loader className={classes.elem('loader').b()} />;
				break;
			case 'LOADED':
				content = (
					//if timeline render timeline look of UpAudio
					<div
						className={
							this.props.isTimeline
								? classes.elem('controls').mod('istimeline').b()
								: classes.elem('controls').b()
						}
					>
						{this.props.isTimeline ? (
							<div className={classes.elem('controls').elem('icon-wrapper').b()}>
								<div className={classes.elem('controls').elem('duration-wrap').b()}>
									<Text size="sm">{this.formatTime(this.state.played)}</Text>
									<Text size="sm" color="grey-10">
										{this.formatTime(this.state.duration)}
									</Text>
								</div>
								<div>
									<Icon
										name={this.state.playing ? 'pause-circle' : 'play-circle'}
										color="bright-blue"
										onClick={this.togglePlaying}
									/>
									<Icon
										color="bright-blue"
										space="mls"
										name="stop-circle"
										onClick={this.props.unmount}
									/>
								</div>
							</div>
						) : (
							<button
								type="button"
								className={`${classes.elem('controls').elem('btn').b()} ${classes
									.elem('controls')
									.elem('playBtn')
									.b()}`}
								onClick={this.togglePlaying}
							>
								{this.state.playing ? <b className="fa fa-pause"></b> : <b className="fa fa-play"></b>}
							</button>
						)}
						<div className={classes.elem('controls').elem('timeline-wrap')} onClick={this.moveTracker}>
							<div
								className={
									this.props.isTimeline
										? classes.elem('timeline-wrap').elem('timeline').mod('istimeline').b()
										: classes.elem('timeline-wrap').elem('timeline').b()
								}
								ref={this.setRef.bind(this, '_timeline')}
							>
								<div
									className={classes.elem('timeline-wrap').elem('progress').b()}
									style={{ width: leftPercent }}
									ref={this.setRef.bind(this, '_progress')}
								></div>
								<div
									className={classes.elem('timeline-wrap').elem('tracker').b()}
									style={{ left: leftPercent }}
									ref={this.setRef.bind(this, '_tracker')}
									onMouseDown={this.mouseDown}
								></div>
							</div>
						</div>
						{this.props.isTimeline ? null : (
							<span className={classes.elem('controls').elem('duration').b()}>
								{this.formatTime(this.state.played)}
								{' / '}
								{this.formatTime(this.state.duration)}
							</span>
						)}
					</div>
				);
				break;
			case 'ERROR':
				content = (
					<div className={classes.elem('error').b()}>
						<Text color="red">{T('error.audioFileNotLoad')}</Text>
					</div>
				);
				break;
		}

		return (
			<div className={classes.b()}>
				<audio
					className={classes.elem('audiotag').b()}
					ref={this.setRef.bind(this, '_audio')}
					onError={this.onError}
				>
					<source id="audioSrc" src={this.state.src} type="audio/mpeg" onError={this.onError} />

					{T('upAudio.noAudioSupport')}
				</audio>

				{content}
			</div>
		);
	}
}
export default UpAudio;
