import React, { useState } from 'react';
import ReportcenterFilter from './ReportcenterFilter';
import { Block } from '@upsales/components';
import bemClass from '@upsales/components/Utils/bemClass';
import T from 'Components/Helpers/translate';
import './ReportcenterFilterStage.scss';
import ReportcenterFilterRow from './ReportcenterFilterRow';
import { removeItem } from 'Store/helpers/array';
import { RCDashboardFilter } from 'Resources/ReportDashboard';
import ReportcenterFilterSearchbar from './ReportcenterFilterSearchbar';
import { RootState } from 'Store/index';

interface Props {
	onChange: (type: keyof RCDashboardFilter, values: number[], comparison: string) => void;
	values: RCDashboardFilter;
	updateFilterHeight: () => void;
	families: string[];
	dashboard: RootState['Reportcenter']['selectedDashboard'];
}

export const renderSelected = (values: RCDashboardFilter, renderCount: boolean = true) => {
	let res = `${T('default.stages')}: `;
	if (!renderCount) {
		const stageNames = Tools.AppService.getStages()
			.filter(stage => {
				return values?.Stage?.value?.includes?.(stage.id);
			})
			.map(stage => stage.name);
		res += stageNames.join(', ');
		return res;
	}

	if (values?.Stage?.value?.length) {
		const length = values.Stage.value.length;
		if (length >= 1) {
			res += `${length} ${(values.Stage.comparison === 'eq'
				? T('default.selected')
				: T('default.excluded', { count: length })
			).toLowerCase()}`;
		}
	}

	return res;
};

const ReportcenterFilterStage = ({ onChange, families, ...props }: Props) => {
	const classes = new bemClass('ReportcenterFilterStage');
	const [searchStr, setSearchStr] = useState('');

	const showAllStages = props.dashboard?.widgets.find(w =>
		['REVENUE_RECOGNITION_IDEAL', 'REVENUE_RECOGNITION_WEIGHTED'].includes(w.type)
	);

	const stages = Tools.AppService.getStages().filter(s =>
		families.includes('pipeline') || showAllStages
			? families.includes('sales')
				? s.probability > 0
				: s.probability > 0 && s.probability < 100
			: families.includes('sales')
			? s.probability === 100
			: false
	);
	const array = stages.filter(item => item.name.toLowerCase().indexOf(searchStr.toLowerCase()) !== -1);
	const value = props.values?.Stage?.value || [];
	const comparison = props.values?.Stage?.comparison ?? 'eq';
	const isExclude = comparison === 'ne';

	return (
		<ReportcenterFilter
			className={classes.b()}
			renderSelected={() => renderSelected(props.values)}
			getSelectedNames={() => stages.filter(s => value.includes(s.id)).map(s => s.name)}
			icon="opportunity"
			placeholder={`${T('default.stages')}: ${T('reportcenter.filter.notActive')}`}
			value={value}
			resetFilter={() => onChange('Stage', [], 'eq')}
			{...props}
		>
			<Block space="ptm prm pbm plm" backgroundColor="white" className={classes.elem('list-select').b()}>
				<Block space="mtm">
					<ReportcenterFilterSearchbar<number[]>
						setSearchStr={value => setSearchStr(value)}
						searchStr={searchStr}
						placeholder={`${T('default.search')} ${T('default.stages').toLowerCase()}`}
						isExclude={isExclude}
						onChange={onChange}
						field="Stage"
						value={value}
					/>
				</Block>
			</Block>
			<ReportcenterFilterRow
				key={0}
				onClick={() => {
					onChange('Stage', [], 'eq');
				}}
				selected={!value.length}
				title={T('filters.noFilter')}
			/>
			{array.map(row => (
				<ReportcenterFilterRow
					key={row.id}
					onClick={() => {
						const i = value.indexOf(row.id);
						onChange('Stage', i !== -1 ? removeItem(value, i) : [...value, row.id], comparison);
					}}
					selected={value.indexOf(row.id) !== -1}
					title={row.name}
				/>
			))}
		</ReportcenterFilter>
	);
};

export default ReportcenterFilterStage;
