import React, { FormEvent } from 'react';
import PropTypes from 'prop-types';
import { Headline, Input, Button, Text, Block, Card } from '@upsales/components';
import getAngularModule from '../../angularHelpers/getAngularModule';
import './SessionExpireWarning.scss';
import bemClass from '@upsales/components/Utils/bemClass';
import { AppState } from 'Store/reducers/AppReducer';
import T from 'Components/Helpers/translate';
import { ModalProps } from 'App/components/Modals/Modals';
import SessionResource from 'App/resources/Session';

const svg = `<svg width="130px" height="140px" viewBox="0 0 130 120" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
<title>lock-animation</title>
<defs>
	<filter x="-25.0%" y="-25.0%" width="150.0%" height="144.4%" filterUnits="objectBoundingBox" id="filter-1">
		<feOffset dx="0" dy="3" in="SourceAlpha" result="shadowOffsetOuter1"></feOffset>
		<feGaussianBlur stdDeviation="3" in="shadowOffsetOuter1" result="shadowBlurOuter1"></feGaussianBlur>
		<feColorMatrix values="0 0 0 0 0.294311876   0 0 0 0 0.334389534   0 0 0 0 0.384486607  0 0 0 0.2 0" type="matrix" in="shadowBlurOuter1" result="shadowMatrixOuter1"></feColorMatrix>
		<feMerge>
			<feMergeNode in="shadowMatrixOuter1"></feMergeNode>
			<feMergeNode in="SourceGraphic"></feMergeNode>
		</feMerge>
	</filter>
</defs>
<g id="lock-animation">
	<g transform="translate(30, 9)" fill="none" fill-rule="evenodd">
		<g filter="url(#filter-1)">
			<path
				id="lock-path"
				stroke="#A4B3C7"
				stroke-width="8.46153846"
				d="M53,26.9111111 C53,26.9111111 53,24.7642366 53,17.5 C53,7.83501688 45.1649831,0 35.5,0 C25.8350169,0 18,7.83501688 18,17.5 C18,27.1649831 18,35 18,35"></path>
			<rect id="lock-body" fill="#A4B3C7" x="0" y="34.5384615" width="70" height="55.3846154" rx="1.53846154"></rect>
		</g>
		<path
			id="check-icon"
			fill="#6B7C93"
			fill-rule="nonzero"
			d="M64.5,78.3373016 C64.7777778,78.3373016 65.0092593,78.2314815 65.1944444,78.0198413 L65.1944444,78.0198413 L76.7222222,66.531746 C76.9074074,66.3597884 77,66.1283069 77,65.8373016 C77,65.5462963 76.9074074,65.3148148 76.7222222,65.1428571 L76.7222222,65.1428571 L75.2738095,63.734127 C75.0621693,63.5224868 74.8306878,63.4166667 74.5793651,63.4166667 C74.3015873,63.4166667 74.0701058,63.5224868 73.8849206,63.734127 L73.8849206,63.734127 L64.5,73.0992063 L60.1349206,68.734127 C59.9232804,68.5224868 59.6851852,68.4166667 59.4206349,68.4166667 C59.1693122,68.4166667 58.9378307,68.5224868 58.7261905,68.734127 L58.7261905,68.734127 L57.2777778,70.1428571 C57.0925926,70.3148148 57,70.5462963 57,70.8373016 C57,71.1283069 57.0925926,71.3597884 57.2777778,71.531746 L57.2777778,71.531746 L63.8055556,78.0198413 C63.9907407,78.2314815 64.2222222,78.3373016 64.5,78.3373016 Z"></path>
	</g>
</g>
<ellipse id="shadow" fill="#002F65" opacity="0.1" cx="65" cy="120" rx="35" ry="3.84615385"></ellipse>
</svg>`;

type SessionExpireWarningProps = ModalProps<boolean>;
type SessionExpireWarningState = {
	error: boolean;
	brandError: boolean;
	isTwoFactorAuth: boolean;
	firstName: string;
	credentials: {
		email: string;
		password: string;
	};
	twoFACredentials: {
		validationKey: string;
		key: string;
	};
	loggingin: boolean;
};

class SessionExpireWarning extends React.Component<SessionExpireWarningProps, SessionExpireWarningState> {
	lang: { [key: string]: string } = {};
	static propTypes = {
		close: PropTypes.func,
		className: PropTypes.string
	};
	constructor(props: SessionExpireWarningProps) {
		super(props);

		// This modal should no longer open from places where user has already been logged out. But just in case...
		const { name, email } = (Tools.AppService.getSelf() as AppState['self']) || { name: '', email: '' };

		this.state = {
			error: false,
			brandError: false,
			isTwoFactorAuth: false,
			firstName: name.split(' ')[0],
			credentials: {
				email,
				password: ''
			},
			twoFACredentials: {
				validationKey: '',
				key: ''
			},
			loggingin: false
		};

		this.lang = {
			add: T('default.add'),
			password: T('default.password'),
			smskey: T('default.smskey'),
			sessionAboutToExpire: T('session.sessionAboutToExpire'),
			sessionAboutToExpireInfo: T('session.sessionAboutToExpireInfo'),
			refreshSession: T('session.refreshSession'),
			loginWithOther: T('session.loginWithOther'),
			credentialsError: T('login.credentialsError'),
			noValidBrand: T('login.noValidBrand'),
			warning: T('default.warning'),
			loginToUpsales: T('default.loginToUpsales'),
			wrongPass: T('session.wrongPass')
		};

		SessionResource.getLoginMethod(email);
	}

	resetErrors = () => this.setState({ error: false, brandError: false });

	logout = () => {
		const security = getAngularModule('security');
		this.props.close(false);
		security.logout();
	};

	login2FA = () => {
		SessionResource.loginTwoFA(this.state.twoFACredentials.validationKey, this.state.twoFACredentials.key)
			.then(() => {
				const customerId = Tools.AppService.getCustomerId();
				Tools.Self.getCustomerSelf(customerId)
					.then(self => {
						Tools.AppService.setSelf(self);
						this.props.close(true);
					})
					.catch(() => {
						this.setState({ error: true, loggingin: false });
					});
			})
			.catch(reason => {
				// Validation key disabled
				if (reason.data.errorCode === 96) {
					this.logout();
					return;
				}
				// else just show error invalid SMS code
				this.setState({ error: true, loggingin: false });
			});
	};

	login = (e: FormEvent) => {
		e.preventDefault();
		this.resetErrors();
		this.setState({ loggingin: true });

		if (this.state.isTwoFactorAuth) {
			return this.login2FA();
		}

		SessionResource.login(this.state.credentials)
			.then(result => {
				const customerId = Tools.AppService.getCustomerId();
				if (result.data.isTwoFactorAuth) {
					this.setState({
						isTwoFactorAuth: true,
						loggingin: false,
						twoFACredentials: { ...this.state.twoFACredentials, validationKey: result.data.token }
					});
					return;
				}

				// get self
				Tools.Self.getCustomerSelf(customerId)
					.then(res => {
						Tools.AppService.setSelf(res);
						this.props.close(true);
					})
					.catch(() => {
						this.setState({ error: true, loggingin: false });
					});
			})
			.catch(response => {
				if (response?.data?.key === 'NoValidBrand') {
					this.setState({ brandError: true, loggingin: false });
				} else {
					this.setState({ error: true, loggingin: false });
				}
			});
	};

	render() {
		const classes = new bemClass('SessionExpireWarning', this.props.className + ' FullScreenModal');
		const disabled = !this.state.credentials.password && !this.state.twoFACredentials.key;
		return (
			<div className={classes.b()}>
				<div className={classes.elem('inner').b()}>
					<div dangerouslySetInnerHTML={{ __html: svg }} />
					<Block space="mbl">
						<Headline size="sm">{`${this.lang.sessionAboutToExpire} ${this.state.firstName}`}</Headline>
					</Block>
					<Block space="mbxl">
						<Text>{`${this.lang.sessionAboutToExpireInfo} ${this.lang.refreshSession}`}</Text>
					</Block>
					<form onSubmit={this.login}>
						<Block space="mrxl mlxl">
							{this.state.isTwoFactorAuth ? (
								<Input
									size="lg"
									placeholder={this.lang.smskey}
									autofocus
									type="text"
									value={this.state.twoFACredentials.key}
									disabled={this.state.loggingin}
									onChange={e =>
										this.setState({
											twoFACredentials: {
												...this.state.twoFACredentials,
												key: e.target.value
											}
										})
									}
								/>
							) : (
								<Input
									size="lg"
									placeholder={this.lang.password}
									autofocus
									type="password"
									value={this.state.credentials.password}
									disabled={this.state.loggingin}
									onChange={e =>
										this.setState({
											credentials: { ...this.state.credentials, password: e.target.value }
										})
									}
								/>
							)}
							{this.state.error ? (
								<Card
									className={classes.elem('error').b()}
									color="super-light-red"
									space="mbxl pbl ptl plxl prxl"
								>
									<Text color="red">
										{this.state.isTwoFactorAuth ? this.lang.credentialsError : this.lang.wrongPass}
									</Text>
								</Card>
							) : null}
							{this.state.brandError ? (
								<Card
									className={classes.elem('error').b()}
									color="super-light-red"
									space="mbxl pbl ptl plxl prxl"
								>
									<Text color="red">{this.lang.noValidBrand}</Text>
								</Card>
							) : null}
							<Block space="mtxl mbl">
								<Button
									block={true}
									size="xl"
									shadow={disabled ? undefined : 'high'}
									submit={true}
									color={disabled ? 'grey' : 'green'}
									disabled={disabled}
									loading={this.state.loggingin}
								>
									{this.lang.loginToUpsales}
								</Button>
							</Block>
							<Button block={true} size="lg" type="link" onClick={this.logout}>
								{this.lang.loginWithOther}
							</Button>
						</Block>
					</form>
				</div>
			</div>
		);
	}
}

export default SessionExpireWarning;
