import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Headline, Text, Button, Input, Block, Label, Title } from '@upsales/components';
import { nextPage, setFieldValue } from '../../store/actions/CreateAccount';

export class UserInfo extends Component {
	static propTypes = {
		setFieldValue: PropTypes.func.isRequired,
		nextPage: PropTypes.func.isRequired,
		closeModal: PropTypes.func.isRequired,
		name: PropTypes.string.isRequired,
		email: PropTypes.string.isRequired,
		password: PropTypes.string.isRequired,
		repeatPassword: PropTypes.string.isRequired,
		loading: PropTypes.bool.isRequired,
		errorField: PropTypes.string,
		error: PropTypes.string
	};

	state = {
		error: null
	};

	constructor(props) {
		super(props);
		const getAngularModule = require('../../angularHelpers/getAngularModule').default;
		const t = getAngularModule('$translate');

		this.lang = {
			headline: t('createAccount.userInfo.headline'),
			subtitle: t('createAccount.userInfo.subtitle'),
			name: t('default.name'),
			email: t('default.email'),
			password: t('default.password'),
			repeatPassword: t('default.repeatPassword'),
			nextButton: t('createAccount.userInfo.nextButton'),
			iAlreadyHaveAccount: t('createAccount.iAlreadyHaveAccount'),
			passwordDoNotMatch: t('admin.passwordNoMatch')
		};
	}

	setFieldValue = field => value => {
		if (value && value.target) {
			value = value.target.value;
		}

		this.setState({
			error: null
		});

		this.props.setFieldValue({ [field]: value });
	};

	nextPage = e => {
		e.preventDefault();

		if (this.props.password !== this.props.repeatPassword) {
			this.setState({
				error: this.lang.passwordDoNotMatch
			});
			return;
		}

		this.props.nextPage();
	};

	render() {
		const getAngularModule = require('../../angularHelpers/getAngularModule').default;
		const t = getAngularModule('$translate');

		return (
			<div>
				<Block space="mbxl">
					<Headline size="sm">{this.lang.headline}</Headline>
					<Title size="sm" color="grey-11">
						{this.lang.subtitle}
					</Title>
				</Block>
				<form onSubmit={this.nextPage}>
					<Block space="mbm">
						<Label required>
							<Text bold>{this.lang.name}</Text>
						</Label>
						<Input onChange={this.setFieldValue('name')} required value={this.props.name} />
					</Block>
					<Block space="mbm">
						<Label required>
							<Text bold>{this.lang.email}</Text>
						</Label>
						<Input
							onChange={this.setFieldValue('email')}
							type="email"
							required
							value={this.props.email}
							state={this.props.errorField === 'email' ? 'error' : null}
						/>
					</Block>
					<Block space="mbm">
						<Label required>
							<Text bold>{this.lang.password}</Text>
						</Label>
						<Input
							onChange={this.setFieldValue('password')}
							type="password"
							minLength={8}
							required
							value={this.props.password}
						/>
					</Block>
					<Block space="mbxl">
						<Label required>
							<Text bold>{this.lang.repeatPassword}</Text>
						</Label>
						<Input
							onChange={this.setFieldValue('repeatPassword')}
							type="password"
							required
							state={this.state.error ? 'error' : null}
							value={this.props.repeatPassword}
						/>
					</Block>
					{this.state.error || this.props.error ? (
						<Block backgroundColor="red" space="mbxl mtxl ptl prl pbl pll">
							<Text color="white">{this.state.error || t(this.props.error)}</Text>
						</Block>
					) : null}
					<Button disabled={this.props.loading} submit color="bright-blue" block size="xl" gradient>
						{this.lang.nextButton}
					</Button>
					<Button
						disabled={this.props.loading}
						color="grey"
						block
						type="link"
						size="lg"
						onClick={() => this.props.closeModal()}
					>
						{this.lang.iAlreadyHaveAccount}
					</Button>
				</form>
			</div>
		);
	}
}

const mapStateToProps = state => ({
	name: state.CreateAccount.name,
	email: state.CreateAccount.email,
	password: state.CreateAccount.password,
	repeatPassword: state.CreateAccount.repeatPassword,
	loading: state.CreateAccount.loading,
	error: state.CreateAccount.error,
	errorField: state.CreateAccount.errorField
});

const mapDispatchToProps = {
	nextPage,
	setFieldValue
};

export default connect(mapStateToProps, mapDispatchToProps)(UserInfo);
