import React from 'react';
import bemClass from '@upsales/components/Utils/bemClass';
import PropTypes from 'prop-types';
import CKEditor from 'ckeditor4-react';
import '../../ckeditor/imagesPlugin';
import '../../ckeditor/tagsPlugin';
import '../../ckeditor/signaturePlugin';
import baseConfig from './baseConfig';
import './TextEditor.scss';

class TextEditor extends React.PureComponent {
	constructor(p) {
		super(p);
		this.ckConfig = baseConfig({ ...this.props.config }, this.props.accountProfile);
	}

	bindListeners = () => {
		// If the source btn is present in the toolbar we need to add som special onChange magic
		const sourceBtn = this._ref.querySelector('.cke_button__source');
		if (sourceBtn) {
			let typeTimeout;
			const sourceKeyUp = () => {
				if (typeTimeout) {
					clearTimeout(typeTimeout);
				}

				typeTimeout = setTimeout(() => {
					this.editor.fire('change');
				}, 200);
			};

			sourceBtn.addEventListener('click', () => {
				const source = this._ref.querySelector('.cke_source');
				if (source) {
					source.addEventListener('keyup', sourceKeyUp);
				}
			});
		}
	};

	onInstanceReady = e => {
		this.editor = e.editor;
		this.bindListeners();
		if (this.props.onInstanceReady) {
			this.props.onInstanceReady(e);
		}
	};

	onChange = e => {
		const value = e.editor.getData();
		if (this.props.value !== value) {
			this.props.onChange({ value });
		}
	};

	render() {
		const {
			config, // eslint-disable-line no-unused-vars
			onInstanceReady, // eslint-disable-line no-unused-vars
			accountProfile, // eslint-disable-line no-unused-vars
			onFocus,
			className,
			value,
			type,
			readOnly,
			instanceRef,
			textEditorHeight,
			...props
		} = this.props;
		const classes = new bemClass('TextEditor', className);
		return (
			<div className={classes.b()} {...props} ref={r => (this._ref = r)} style={{ height: textEditorHeight }}>
				<CKEditor
					ref={instanceRef}
					className={classes.elem('cke').b()}
					onChange={this.onChange}
					data={value}
					config={this.ckConfig}
					type={type}
					readOnly={readOnly}
					onInstanceReady={this.onInstanceReady}
					onFocus={e => onFocus(true, e.editor?.id)}
					onBlur={e => onFocus(false, e.editor?.id)}
					style={textEditorHeight ? { height: textEditorHeight } : undefined}
				/>
			</div>
		);
	}
}

TextEditor.propTypes = {
	onChange: PropTypes.func,
	value: PropTypes.string,
	config: PropTypes.object,
	type: PropTypes.string,
	className: PropTypes.string,
	readOnly: PropTypes.bool,
	instanceRef: PropTypes.object,
	onInstanceReady: PropTypes.func,
	accountProfile: PropTypes.object,
	onFocus: PropTypes.func
};

TextEditor.defaultProps = {
	onChange: () => {},
	value: '',
	config: {},
	type: 'classic',
	className: '',
	readOnly: false,
	instanceRef: React.createRef(),
	onInstanceReady: () => {},
	onFocus: () => {}
};

export default TextEditor;
