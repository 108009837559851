import Attribute, { Type } from './Attribute';

const CompetitorAttributes = {
	id: Attribute({
		type: Type.Number,
		field: 'id'
	}),
	name: Attribute({
		type: Type.String,
		field: 'name'
	}),
	orderCount: Attribute({
		type: Type.Number,
		field: 'orderCount'
	}),
	regDate: Attribute({
		type: Type.Date,
		field: 'regDate'
	}),
	regBy: Attribute({
		type: Type.Number,
		field: 'regBy'
	})
};

export default CompetitorAttributes;
