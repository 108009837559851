import WidgetResource from 'Resources/ReportWidget';
import {
	RESET,
	SET_PINNED_WIDGETS,
	SET_USER_WIDGETS,
	SET_TEAM_WIDGETS,
	SET_SHOW_TEAM
} from 'Store/reducers/GoalsReducer';
import _ from 'lodash';
import logError from 'App/babel/helpers/logError';
import RequestBuilder, { comparisonTypes } from 'Resources/RequestBuilder';
import { hasCMWithRR, hasRRWithCM } from 'App/helpers/salesModelHelpers';
import { getSoftDeployAccessFromState } from 'App/components/hooks/featureHelper';

const _isAppointmentWidget = widget => {
	return [
		'COMPLETED_APPOINTMENT',
		'BOOKED_APPOINTMENT',
		'COMPLETED_FIRST_APPOINTMENT',
		'BOOKED_FIRST_APPOINTMENT'
	].includes(widget.data.type.name);
};
const _requiresGoal = widget => ['REACHED_CLIENTS'].includes(widget.data.type.name);
const _setDefaultPinnedWidgets = () => {
	const sales = [
		['USER', 'SALES'],
		['USER', 'COMPLETED_APPOINTMENT'],
		['USER', 'BOOKED_APPOINTMENT']
	];
	const cm = [
		['USER', 'CONTRIBUTION_MARGIN'],
		['USER', 'COMPLETED_APPOINTMENT'],
		['USER', 'BOOKED_APPOINTMENT']
	];
	const rr = [
		['USER', 'RECURRING'],
		['USER', 'ONEOFF'],
		['USER', 'COMPLETED_APPOINTMENT']
	];
	const pinnedWidgets = {
		sales,
		cm,
		rr
	};
	window.localStorage.setItem('pinnedWidgets', JSON.stringify(pinnedWidgets));
	return pinnedWidgets;
};

const _getPinnedWidgets = () => {
	let storedPinnedWidgets;
	try {
		storedPinnedWidgets = JSON.parse(window.localStorage.getItem('pinnedWidgets'));
	} catch {
		storedPinnedWidgets = null;
	}
	if (!storedPinnedWidgets) {
		storedPinnedWidgets = _setDefaultPinnedWidgets();
	}
	return storedPinnedWidgets;
};

const _setDefaultGoalWidgetPeriods = () => {
	const { userQuotaPeriods } = Tools.AppService.getMetadata();
	const getQuotaPeriod = type => (userQuotaPeriods[type] === 'weekly' ? 'week' : 'month');

	const getDefaultPeriods = salesModel => {
		const periods = {
			USER: {
				COMPLETED_APPOINTMENT: getQuotaPeriod('completed_appointment'),
				BOOKED_APPOINTMENT: getQuotaPeriod('booked_appointment'),
				COMPLETED_FIRST_APPOINTMENT: getQuotaPeriod('completed_first_appointment'),
				BOOKED_FIRST_APPOINTMENT: getQuotaPeriod('booked_first_appointment'),
				CREATED_PIPELINE: getQuotaPeriod('pipeline'),
				SALES: getQuotaPeriod('sales'),
				REACHED_CLIENTS: getQuotaPeriod('phonecall')
			},
			TEAM: {
				COMPLETED_APPOINTMENT: getQuotaPeriod('completed_appointment'),
				BOOKED_APPOINTMENT: getQuotaPeriod('booked_appointment'),
				COMPLETED_FIRST_APPOINTMENT: getQuotaPeriod('completed_first_appointment'),
				BOOKED_FIRST_APPOINTMENT: getQuotaPeriod('booked_first_appointment'),
				CREATED_PIPELINE: getQuotaPeriod('pipeline'),
				SALES: getQuotaPeriod('sales'),
				REACHED_CLIENTS: getQuotaPeriod('phonecall')
			}
		};
		switch (salesModel) {
			case 'cm':
				periods.USER.CONTRIBUTION_MARGIN = getQuotaPeriod('contribution_margin');
				periods.TEAM.CONTRIBUTION_MARGIN = getQuotaPeriod('contribution_margin');
				break;
			case 'rr':
				periods.USER.RECURRING = getQuotaPeriod('recurring');
				periods.USER.ONEOFF = getQuotaPeriod('oneOff');
				periods.TEAM.RECURRING = getQuotaPeriod('recurring');
				periods.TEAM.ONEOFF = getQuotaPeriod('oneOff');
				break;
			default:
				break;
		}

		if (hasRRWithCM()) {
			periods.USER.CONTRIBUTION_MARGIN = getQuotaPeriod('contribution_margin');
			periods.TEAM.CONTRIBUTION_MARGIN = getQuotaPeriod('contribution_margin');
		}

		if (hasCMWithRR()) {
			periods.USER.RECURRING = getQuotaPeriod('recurring');
			periods.USER.ONEOFF = getQuotaPeriod('oneOff');
			periods.TEAM.RECURRING = getQuotaPeriod('recurring');
			periods.TEAM.ONEOFF = getQuotaPeriod('oneOff');
		}

		return periods;
	};
	const goalWidgetPeriods = {
		sales: getDefaultPeriods('sales'),
		cm: getDefaultPeriods('cm'),
		rr: getDefaultPeriods('rr')
	};
	window.localStorage.setItem('goalWidgetPeriods', JSON.stringify(goalWidgetPeriods));
	return goalWidgetPeriods;
};

const _getGoalWidgetPeriods = () => {
	let goalWidgetPeriods;
	try {
		goalWidgetPeriods = JSON.parse(window.localStorage.getItem('goalWidgetPeriods'));
		if (!goalWidgetPeriods.cm?.USER || !goalWidgetPeriods.rr?.USER?.SALES) {
			goalWidgetPeriods = null;
		}
	} catch {
		goalWidgetPeriods = null;
	}
	if (!goalWidgetPeriods) {
		goalWidgetPeriods = _setDefaultGoalWidgetPeriods();
	}
	return goalWidgetPeriods;
};

const _filterInactiveWidgets = widgets => {
	return widgets.filter(
		widget =>
			!(_isAppointmentWidget(widget) && widget.data.total.goal === 0) &&
			!(!_isAppointmentWidget(widget) && widget.data.total.goal === 0 && widget.data.total.progress === 0) &&
			!(_requiresGoal(widget) && widget.data.total.goal === 0)
	);
};

const _filterPinnedUserWidgets = (widgets, salesModel) => {
	if (!widgets) {
		return [];
	}
	const storedPinnedWidgets = _getPinnedWidgets();
	return widgets.filter(widget => {
		widget.pinned =
			storedPinnedWidgets[salesModel].findIndex(item => _.isEqual(item, ['USER', widget.data.type.name])) !== -1;
		widget.group = 'USER';
		return widget.pinned;
	});
};

const _filterPinnedTeamWidgets = (widgets, salesModel) => {
	if (!widgets) {
		return [];
	}
	const storedPinnedWidgets = _getPinnedWidgets();
	return widgets.filter(widget => {
		widget.pinned =
			storedPinnedWidgets[salesModel].findIndex(item => _.isEqual(item, ['TEAM', widget.data.type.name])) !== -1;
		widget.group = 'TEAM';
		return widget.pinned;
	});
};

const _addPeriodToUserWidgets = (widgets, salesModel) => {
	let goalWidgetPeriods = _getGoalWidgetPeriods();
	widgets.forEach(widget => {
		try {
			widget.period = goalWidgetPeriods[salesModel]['USER'][widget.data.type.name];
		} catch {
			goalWidgetPeriods = _setDefaultGoalWidgetPeriods();
			widget.period = goalWidgetPeriods[salesModel]['USER'][widget.data.type.name];
		}
	});
	return widgets;
};

const _addPeriodToTeamWidgets = (widgets, salesModel) => {
	let goalWidgetPeriods = _getGoalWidgetPeriods();
	widgets.forEach(widget => {
		try {
			widget.period = goalWidgetPeriods[salesModel]['TEAM'][widget.data.type.name];
		} catch {
			goalWidgetPeriods = _setDefaultGoalWidgetPeriods();
			widget.period = goalWidgetPeriods[salesModel]['TEAM'][widget.data.type.name];
		}
	});
	return widgets;
};

const _fixWeeklyGoals = widgets => {
	if (widgets?.length) {
		widgets.forEach(widget => {
			const shouldUpdateRemaining = _isAppointmentWidget(widget);
			let totalGoal = 0;
			let totalRemaining = 0;
			if (widget.period === 'week') {
				widget.data.rows.forEach(user => {
					const weeklyGoal = Math.ceil(user.goal / 4);
					user.goal = weeklyGoal;
					totalGoal += weeklyGoal;
					if (shouldUpdateRemaining) {
						user.remaining = Math.max(0, user.goal - user.progress);
						totalRemaining += user.remaining;
					}
				});
				widget.data.total.goal = totalGoal;
				if (shouldUpdateRemaining) {
					widget.data.total.remaining = totalRemaining;
				}
			}
		});
	}
	return widgets;
};

const _getDateFilter = (salesModel, group, type) => {
	const { userQuotaPeriods } = Tools.AppService.getMetadata();
	const goalWidgetPeriods = _getGoalWidgetPeriods();
	let period = goalWidgetPeriods[salesModel][group][type];
	if (period === 'week' && userQuotaPeriods[type.toLowerCase()] === 'monthly') {
		period = 'month';
	}
	if (!period) {
		period = userQuotaPeriods[type.toLowerCase()] === 'weekly' ? 'week' : 'month';
		_setDefaultGoalWidgetPeriods();
	}
	const rb = new RequestBuilder();
	let startOfPeriod;
	let endOfPeriod;
	switch (period) {
		case 'week':
			startOfPeriod = moment().startOf('isoWeek').format('YYYY-MM-DD');
			endOfPeriod = moment().endOf('isoWeek').format('YYYY-MM-DD');
			rb.addFilter({ field: 'date' }, comparisonTypes.GreaterThanEquals, startOfPeriod);
			rb.addFilter({ field: 'date' }, comparisonTypes.LessThanEquals, endOfPeriod);
			return rb;
		case 'month':
			startOfPeriod = moment().startOf('month').format('YYYY-MM-DD');
			endOfPeriod = moment().endOf('month').format('YYYY-MM-DD');
			rb.addFilter({ field: 'date' }, comparisonTypes.GreaterThanEquals, startOfPeriod);
			rb.addFilter({ field: 'date' }, comparisonTypes.LessThanEquals, endOfPeriod);
			return rb;
		case 'quarter':
			startOfPeriod = moment().startOf('quarter').format('YYYY-MM-DD');
			endOfPeriod = moment().endOf('quarter').format('YYYY-MM-DD');
			rb.addFilter({ field: 'date' }, comparisonTypes.GreaterThanEquals, startOfPeriod);
			rb.addFilter({ field: 'date' }, comparisonTypes.LessThanEquals, endOfPeriod);
			return rb;
		case 'year':
			startOfPeriod = moment().startOf('year').format('YYYY-MM-DD');
			endOfPeriod = moment().endOf('year').format('YYYY-MM-DD');
			rb.addFilter({ field: 'date' }, comparisonTypes.GreaterThanEquals, startOfPeriod);
			rb.addFilter({ field: 'date' }, comparisonTypes.LessThanEquals, endOfPeriod);
			return rb;
		default:
			return null;
	}
};

const _getWidgetPromises = (salesModel, shouldGetRoleData, pinnedOnly, event) => {
	const quotaEvents = ['quota.added', 'genericQuotas.added', 'activityQuotas.added'];
	const listeners = {
		ORDER: ['order.added', 'order.updated', 'order.deleted', 'quota.added', 'genericQuotas.added'],
		AGREEMENT: ['agreement.added', 'agreement.updated', 'agreement.deleted', 'genericQuotas.added'],
		OPPORTUNITY: ['opportunity.added', 'opportunity.updated', 'opportunity.deleted', 'genericQuotas.added'],
		APPOINTMENT: [
			'appointment.added',
			'appointment.updated',
			'appointment.deleted',
			'genericQuotas.added',
			'activityQuotas.added'
		],
		ACTIVITY: ['activity.updated', 'genericQuotas.added', 'activityQuotas.added']
	};

	const widgetTypes = {
		SALES: {
			listeners: [...listeners.ORDER, ...listeners.AGREEMENT],
			quotaType: 'sales'
		},
		CONTRIBUTION_MARGIN: {
			listeners: listeners.ORDER,
			quotaType: 'contributionMargin'
		},
		RECURRING: {
			listeners: [...listeners.ORDER, ...listeners.AGREEMENT],
			quotaType: 'recurring'
		},
		ONEOFF: {
			listeners: listeners.ORDER,
			quotaType: 'oneOff'
		},
		CREATED_PIPELINE: {
			listeners: listeners.OPPORTUNITY,
			quotaType: 'pipeline'
		},
		BOOKED_APPOINTMENT: {
			listeners: listeners.APPOINTMENT,
			quotaType: 'booked_appointment'
		},
		COMPLETED_APPOINTMENT: {
			listeners: listeners.APPOINTMENT,
			quotaType: 'completed_appointment'
		},
		BOOKED_FIRST_APPOINTMENT: {
			listeners: listeners.APPOINTMENT,
			quotaType: 'booked_first_appointment'
		},
		COMPLETED_FIRST_APPOINTMENT: {
			listeners: listeners.APPOINTMENT,
			quotaType: 'completed_first_appointment'
		},
		REACHED_CLIENTS: {
			listeners: listeners.ACTIVITY,
			quotaType: 'phonecall'
		}
	};

	const salesModelWidgets = {
		sales: [
			'SALES',
			'CREATED_PIPELINE',
			'BOOKED_APPOINTMENT',
			'COMPLETED_APPOINTMENT',
			'BOOKED_FIRST_APPOINTMENT',
			'COMPLETED_FIRST_APPOINTMENT',
			'REACHED_CLIENTS'
		],
		cm: [
			'CONTRIBUTION_MARGIN',
			'SALES',
			'CREATED_PIPELINE',
			'BOOKED_APPOINTMENT',
			'COMPLETED_APPOINTMENT',
			'BOOKED_FIRST_APPOINTMENT',
			'COMPLETED_FIRST_APPOINTMENT',
			'REACHED_CLIENTS'
		],
		rr: [
			'RECURRING',
			'ONEOFF',
			'SALES',
			'CREATED_PIPELINE',
			'BOOKED_APPOINTMENT',
			'COMPLETED_APPOINTMENT',
			'BOOKED_FIRST_APPOINTMENT',
			'COMPLETED_FIRST_APPOINTMENT',
			'REACHED_CLIENTS'
		]
	};

	if (hasRRWithCM()) {
		salesModelWidgets.rr.push('CONTRIBUTION_MARGIN');
	}

	if (hasCMWithRR()) {
		salesModelWidgets.cm.push('RECURRING', 'ONEOFF');
	}

	const userPromises = [];
	const teamPromises = [];

	const roleId = shouldGetRoleData ? Tools.AppService.getSelf().role.id : null;
	const teamUserIds = shouldGetRoleData
		? [...Tools.AppService.getUsers(), ...Tools.AppService.getUsers('deleted')]
				.filter(user => {
					return user.role?.id === roleId;
				})
				.map(user => {
					return user.id;
				})
		: null;

	const pinnedWidgets = _getPinnedWidgets()[salesModel] ?? [];
	for (const type of salesModelWidgets[salesModel]) {
		if (pinnedOnly && event) {
			const isUnrelatedEvent = !widgetTypes[type].listeners.includes(event.type);
			const isUnrelatedQuotaEvent =
				quotaEvents.includes(event.type) &&
				widgetTypes[type].quotaType !== event.data?.type &&
				!(event.type === 'quota.added' && widgetTypes[type].quotaType === 'sales'); // special case for the old sales quota

			if (isUnrelatedEvent || isUnrelatedQuotaEvent) {
				continue;
			}
		}

		const shouldFilterExcludedStages = ['SALES', 'ONEOFF', 'CONTRIBUTION_MARGIN'].includes(type);
		let isPinned;

		if (shouldGetRoleData) {
			isPinned = pinnedWidgets.find(([group, name]) => group === 'TEAM' && name === type);
			if (isPinned || !pinnedOnly) {
				const rb = _getDateFilter(salesModel, 'TEAM', type);
				rb.addFilter({ field: 'user' }, comparisonTypes.Equals, teamUserIds);
				if (shouldFilterExcludedStages) {
					rb.addFilter({ field: 'excludeStage' }, comparisonTypes.Equals, 0);
				}
				teamPromises.push(WidgetResource.find(type, { ...rb.build(), disableRows: true }));
			}
		}

		isPinned = pinnedWidgets.find(([group, name]) => group === 'USER' && name === type);
		if (isPinned || !pinnedOnly) {
			const rb = _getDateFilter(salesModel, 'USER', type);
			rb.addFilter({ field: 'user' }, comparisonTypes.Equals, Tools.AppService.getSelf().id);
			if (shouldFilterExcludedStages) {
				rb.addFilter({ field: 'excludeStage' }, comparisonTypes.Equals, 0);
			}
			userPromises.push(WidgetResource.find(type, { ...rb.build(), disableRows: true }));
		}
	}
	return [userPromises, teamPromises];
};

const _getWidgetData =
	(pinnedOnly = false, event = null, init = false) =>
	async (dispatch, getState) => {
		const { showTeam, userWidgets: existingUserWidgets, teamWidgets: existingTeamWidgets } = getState().Goals;
		const metadata = Tools.AppService.getMetadata();
		const salesModel = metadata.params.SalesModel;
		const [userPromises, teamPromises] = _getWidgetPromises(salesModel, showTeam, pinnedOnly, event);
		const userWidgetPromise = Promise.all(userPromises)
			.then(widgets => {
				widgets = _addPeriodToUserWidgets(_filterInactiveWidgets(widgets), salesModel);
				return widgets;
			})
			.catch(err => logError(err, 'Failed to get quota widgets'));
		const teamWidgetPromise = Promise.all(teamPromises)
			.then(widgets => {
				widgets = _addPeriodToTeamWidgets(_filterInactiveWidgets(widgets), salesModel);
				return widgets;
			})
			.catch(err => logError(err, 'Failed to get quota widgets'));
		return Promise.all([userWidgetPromise, teamWidgetPromise])
			.then(widgets => {
				let [userWidgets, teamWidgets] = widgets;

				if (!Tools.FeatureHelper.hasSoftDeployAccess('GOALS')) {
					userWidgets = _fixWeeklyGoals(userWidgets);
					teamWidgets = _fixWeeklyGoals(teamWidgets);
				}

				const pinnedUserWidgets = _filterPinnedUserWidgets(userWidgets, salesModel);
				const pinnedTeamWidgets = _filterPinnedTeamWidgets(teamWidgets, salesModel);

				if (pinnedOnly) {
					const updatedWidgets = [...pinnedUserWidgets, ...pinnedTeamWidgets];

					if (init) {
						dispatch({ type: SET_USER_WIDGETS, userWidgets });
						dispatch({ type: SET_TEAM_WIDGETS, teamWidgets });
						dispatch({ type: SET_PINNED_WIDGETS, pinnedWidgets: updatedWidgets });
						return;
					}

					// Replace the updated widgets and keep the rest
					const existingPinnedUserWidgets = _filterPinnedUserWidgets(existingUserWidgets, salesModel);
					const existingPinnedTeamWidgets = _filterPinnedTeamWidgets(existingTeamWidgets, salesModel);
					const existingPinnedWidgets = [...existingPinnedUserWidgets, ...existingPinnedTeamWidgets];

					const pinnedWidgets = existingPinnedWidgets.map(pinnedWidget => {
						const updatedWidget = updatedWidgets.find(
							widget =>
								widget.data.type.name === pinnedWidget.data.type.name &&
								widget.group === pinnedWidget.group
						);
						if (updatedWidget) {
							return updatedWidget;
						} else {
							return pinnedWidget;
						}
					});

					dispatch({ type: SET_PINNED_WIDGETS, pinnedWidgets });
					return;
				}

				const pinnedWidgets = [...pinnedUserWidgets, ...pinnedTeamWidgets];

				dispatch({ type: SET_USER_WIDGETS, userWidgets });
				dispatch({ type: SET_TEAM_WIDGETS, teamWidgets });
				dispatch({ type: SET_PINNED_WIDGETS, pinnedWidgets });
			})
			.catch(err => logError(err, 'Failed to get quota widgets'));
	};

export const init = () => async (dispatch, getState) => {
	dispatch({ type: RESET });
	const role = Tools.AppService.getSelf().role;
	const isAdmin = Tools.AppService.getSelf().administrator;
	const hasSpeedQuotaChartsUpdate = getSoftDeployAccessFromState(
		getState().App.accountSelf,
		'SPEED_QUOTA_CHARTS_UPDATE'
	);

	if (role) {
		// Find report viewing permissions
		// Travel up the role tree if permissions are inherited
		const getSettings = role =>
			Tools.Role.getSettings(role.id)
				.then(settings => {
					if (settings.templateData.access.Report === 3 || isAdmin) {
						if (settings.parent && !isAdmin) {
							getSettings(settings.parent);
						} else {
							return Promise.all([
								dispatch({ type: SET_SHOW_TEAM, showTeam: true }),
								dispatch(_getWidgetData(hasSpeedQuotaChartsUpdate, null, true))
							]);
						}
					} else {
						const showTeam = settings.templateData.access.Report === 2;

						return Promise.all([
							dispatch({ type: SET_SHOW_TEAM, showTeam }),
							dispatch(_getWidgetData(hasSpeedQuotaChartsUpdate, null, true))
						]);
					}
				})
				.catch(err => logError(err, 'Failed to get role settings'));
		return getSettings(role);
	} else {
		return dispatch(_getWidgetData(hasSpeedQuotaChartsUpdate, null, true));
	}
};

export const getWidgetData = event => async dispatch => {
	await dispatch(_getWidgetData(false, event));
};

export const getPinnedWidgetData = event => async dispatch => {
	await dispatch(_getWidgetData(true, event));
};

export const removePinnedWidget = (type, group) => async dispatch => {
	const salesModel = Tools.AppService.getMetadata().params.SalesModel;
	const pinnedWidgets = _getPinnedWidgets();
	pinnedWidgets[salesModel] = pinnedWidgets[salesModel].filter(item => !_.isEqual(item, [group, type]));
	window.localStorage.setItem('pinnedWidgets', JSON.stringify(pinnedWidgets));
	return dispatch(_getWidgetData());
};

export const addPinnedWidget = (type, group) => async dispatch => {
	const salesModel = Tools.AppService.getMetadata().params.SalesModel;
	const pinnedWidgets = _getPinnedWidgets();

	pinnedWidgets[salesModel].push([group, type]);
	if (group === 'USER' && pinnedWidgets[salesModel].length > 3) {
		// This prevents extra pins if a user has pinned team widgets before having their role report access removed
		pinnedWidgets[salesModel] = pinnedWidgets[salesModel].filter(item => !item.includes('TEAM'));
	}

	window.localStorage.setItem('pinnedWidgets', JSON.stringify(pinnedWidgets));

	return dispatch(_getWidgetData());
};

export const setGoalPeriod = (period, group, type) => async dispatch => {
	const salesModel = Tools.AppService.getMetadata().params.SalesModel;
	let currentGoalPeriods = _getGoalWidgetPeriods();

	try {
		currentGoalPeriods[salesModel][group][type] = period;
	} catch {
		currentGoalPeriods = _setDefaultGoalWidgetPeriods();
		currentGoalPeriods[salesModel][group][type] = period;
	}

	window.localStorage.setItem('goalWidgetPeriods', JSON.stringify(currentGoalPeriods));
	return dispatch(_getWidgetData());
};
