import { Table, TableHeader, TableHeaderColumn } from '@upsales/components';
import { useTranslation } from 'Components/Helpers/translate';
import BemClass from '@upsales/components/Utils/bemClass';
import ViewTicketsTableRow from './ViewTicketsTableRow';
import Ticket from 'App/resources/Model/Ticket';
import React from 'react';

type Props = {
	tickets: Ticket[] | undefined;
	selectedOpenStatus: boolean;
};

const ViewTicketsTable = ({ tickets, selectedOpenStatus }: Props) => {
	const classes = new BemClass('ViewTicketsTable');
	const { t } = useTranslation();

	const changeDateColumn = () => {
		if (selectedOpenStatus) {
			return t('ticket.widget.createdColumn');
		} else {
			return t('ticket.widget.closedColumn');
		}
	};

	return (
		<Table className={classes.b()}>
			<TableHeader>
				<TableHeaderColumn>{t('support.title')}</TableHeaderColumn>
				<TableHeaderColumn>{changeDateColumn()}</TableHeaderColumn>
				<TableHeaderColumn>{t('ticket.widget.assigneeColumn')}</TableHeaderColumn>
			</TableHeader>
			{tickets?.map(ticket => {
				return <ViewTicketsTableRow key={ticket.id} row={ticket} />;
			})}
		</Table>
	);
};

export default ViewTicketsTable;
