import React from 'react';
import Alerts, { type AlertProps } from './Alerts';
import { useTranslation } from 'Components/Helpers/translate';

const RemoveAlert = ({ title, ...props }: AlertProps) => {
	const { t } = useTranslation();
	return (
		<Alerts
			title={title ? `${t('default.remove')} ${t(title).toLowerCase()}` : 'default.remove'}
			headerIcon="warning"
			confirmButtonText="default.remove"
			{...props}
		/>
	);
};

export default RemoveAlert;
