import getAngularModule from 'App/babel/angularHelpers/getAngularModule';

export const LEAD = 'lead';
export const MQL = 'mql';
export const ASSIGNED = 'assigned';
export const SQL = 'sql';
export const PIPELINE = 'pipeline';
export const LOST_OPPORTUNITY = 'lost_opportunity';
export const DISQUALIFIED = 'disqualified';
export const CUSTOMER = 'customer';
export const LOST_CUSTOMER = 'lost_customer';

export const statuses = [LEAD, MQL, ASSIGNED, SQL, PIPELINE, LOST_OPPORTUNITY, DISQUALIFIED, CUSTOMER, LOST_CUSTOMER];

export const getJourneyStep = step => {
	if (!step) {
		step = 'lead';
	}
	const AppService = getAngularModule('AppService');
	return AppService.getJourneySteps(true).find(s => s.value === step);
};

// Default to true if provided journey status is falsy = null = LEAD
export const statusDisqualifiable = status => (!status ? true : [LEAD, MQL, ASSIGNED, SQL].indexOf(status) >= 0);
export const statusQualifiable = status => (!status ? true : [LEAD, MQL, ASSIGNED].indexOf(status) >= 0);
