import React from 'react';
import { Row } from '@upsales/components';
import BemClass from '@upsales/components/Utils/bemClass';

import WidgetRowRender from '../WidgetRowRender';
import { User, Config, StandardIntegration } from './PlainTextRenderType';

export type WidgetRow = {
	type?: string;
	text?: string;
};

type RowRenderTypeProps = {
	row: {
		cols: WidgetRow[];
		align?: 'left' | 'right' | 'center' | 'space-between' | 'space-around';
	};
	config: Config;
	object: unknown;
	getUser: () => User;
	runStandardIntegration: (standardIntegration: StandardIntegration) => void;
	getIcon: (icon: string, style?: string) => HTMLDivElement;
	runClick: (row: WidgetRow) => void;
};

const RowRenderType = ({ row, ...props }: RowRenderTypeProps) => {
	const classes = new BemClass('RowRenderType');

	return (
		<Row className={classes.b()} align={row.align}>
			{row.cols.map((col, i) => (
				<WidgetRowRender key={'rowRenderType' + i} row={col} {...props} />
			))}
		</Row>
	);
};

export default RowRenderType;
