import React from 'react';
import PropTypes from 'prop-types';
import { Table, TableColumn, TableHeader, TableRow, Button, Text, Loader } from '@upsales/components';
import NameExisting from '../CreateNewAccount/Columns/NameExisting';

const LoadRow = () => (
	<TableRow>
		<TableColumn colSpan={3}>
			<Loader size="xs" />
		</TableColumn>
	</TableRow>
);

const NoResultRow = ({ text }) => (
	<TableRow>
		<TableColumn colSpan={3}>
			<Text center={true} italic={true} color={'grey-11'}>
				{text}
			</Text>
		</TableColumn>
	</TableRow>
);

class ExistingAccounts extends React.Component {
	constructor(props) {
		super(props);
		const t = Tools.$translate;

		this.lang = {
			merge: t('default.merge'),
			noResult: t('noResult.account')
		};
	}

	render() {
		const lang = this.lang;
		const { account: currentAccount, accounts, loading, mergin, merge, closeModal } = this.props;

		const rows = accounts.map(client => {
			const uglyLazyFix =
				'TableColumn TableColumn--black TableColumn--align-left TableColumn--size-md TableColumn--md';
			const loading = mergin === client.id;

			return (
				<TableRow key={client.id}>
					<NameExisting field={{ className: uglyLazyFix }} account={client} />
					<TableColumn>
						<Text>{client.orgNo || '-'}</Text>
					</TableColumn>
					<TableColumn align="right">
						<Button
							size="sm"
							onClick={() => merge(currentAccount, client, closeModal)}
							color="green"
							disabled={!!mergin}
							loading={loading}
						>
							{lang.merge}
						</Button>
					</TableColumn>
				</TableRow>
			);
		});

		return (
			<div className="InUpsales">
				<Table>
					<TableHeader
						color="white"
						columns={[
							{ title: Tools.$translate('default.account') },
							{ title: Tools.$translate('default.orgNumberShort') },
							{ title: '' }
						]}
					/>
					{loading ? <LoadRow /> : null}
					{!accounts.length && !loading ? <NoResultRow text={lang.noResult} /> : null}
					{accounts.length && !loading ? rows : null}
				</Table>
			</div>
		);
	}
}

ExistingAccounts.propTypes = {
	closeModal: PropTypes.func,
	account: PropTypes.object,
	accounts: PropTypes.array,
	loading: PropTypes.bool,
	mergin: PropTypes.number,
	merge: PropTypes.func
};

export default ExistingAccounts;
