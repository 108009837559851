import Attribute, { DisplayType, Type } from './Attribute';

const SocialEventAttributes = {
	id: Attribute({
		type: Type.Number,
		field: 'id'
	}),
	name: Attribute({
		title: 'default.name',
		type: Type.String,
		field: 'name',
		sortable: 'name'
	}),
	versionFlag: Attribute({
		title: 'socialEvent.versionFlag',
		type: Type.Number,
		field: 'versionFlag'
	}),
	internalName: Attribute({
		title: 'default.name',
		type: Type.String,
		field: 'internalName',
		sortable: 'internalName'
	}),
	description: Attribute({
		title: 'default.description',
		type: Type.String,
		field: 'description'
	}),
	date: Attribute({
		title: 'default.date',
		type: Type.DateTime,
		displayType: DisplayType.Date,
		field: 'date',
		sortable: 'date'
	}),
	time: Attribute({
		title: 'default.time',
		type: Type.Time,
		field: 'time'
	}),
	endTime: Attribute({
		title: 'default.endTime',
		type: Type.Time,
		field: 'endTime'
	}),
	endDate: Attribute({
		title: 'default.endDate',
		type: Type.DateTime,
		field: 'endDate'
	}),
	cancelDate: Attribute({
		title: 'default.cancelDate',
		type: Type.DateTime,
		field: 'cancelDate'
	}),
	attendingScore: Attribute({
		title: 'socialEvent.attendingScore',
		type: Type.Number,
		field: 'attendingScore'
	}),
	checkInScore: Attribute({
		title: 'socialEvent.checkInScore',
		type: Type.Number,
		field: 'checkInScore'
	}),
	location: Attribute({
		title: 'address.address',
		type: Type.String,
		field: 'location',
		sortable: 'location'
	}),
	venue: Attribute({
		title: 'socialEvent.eventVenue',
		type: Type.String,
		field: 'venue'
	}),
	importing: Attribute({
		title: 'socialEvent.importing',
		type: Type.Boolean,
		field: 'importing'
	}),
	modifyingContacts: Attribute({
		type: Type.Boolean,
		field: 'modifyingContacts'
	}),
	draft: Attribute({
		title: 'socialEvent.draft',
		type: Type.Boolean,
		field: 'draft'
	}),
	pin: Attribute({
		type: Type.Number,
		field: 'pin'
	}),
	pinHash: Attribute({
		type: Type.String,
		field: 'pinHash'
	}),
	status: Attribute({
		title: 'socialEvent.eventStatus',
		type: Type.String,
		field: 'status'
	}),
	timezone: Attribute({
		title: 'socialEvent.timezone',
		type: Type.String,
		field: 'timezone'
	}),
	domain: Attribute({
		title: 'socialEvent.domain',
		type: Type.String,
		field: 'domain'
	}),
	urlName: Attribute({
		title: 'socialEvent.urlFriendlyName',
		type: Type.String,
		field: 'urlName'
	}),
	isWebinar: Attribute({
		type: Type.Boolean,
		field: 'isWebinar'
	}),
	webinarId: Attribute({
		type: Type.String,
		field: 'webinarId'
	}),
	webinarLink: Attribute({
		type: Type.String,
		field: 'webinarLink'
	}),
	webinarIntegrationId: Attribute({
		type: Type.Number,
		field: 'webinarIntegrationId'
	}),
	tickets: Attribute({
		title: 'socialEvent.seatsFilled',
		type: Type.Number,
		displayType: DisplayType.Number,
		field: 'tickets'
	}),
	eventFullMsg: Attribute({
		title: 'socialEvent.messageWhenFull',
		type: Type.String,
		field: 'eventFullMsg'
	}),
	waitingList: Attribute({
		title: 'socialEvent.waitingList',
		type: Type.Boolean,
		field: 'waitingList'
	}),
	projects: Attribute({
		title: 'socialEvent.projects',
		type: Type.Array,
		field: 'projects.id'
	}),
	isOnDemand: Attribute({
		type: Type.Boolean,
		field: 'isOnDemand'
	})
};

export default SocialEventAttributes;
