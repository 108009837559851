import Resource from './Resource';
import Attributes from 'Attributes/SalesboardAttributes';
import genericMapper from 'App/resources/genericMapper';
import AllIWantDataCache from 'App/helpers/allIWantDataCache';

const mapSalesBoardColumns = columns => {
	if (Array.isArray(columns)) {
		return columns.map(c => genericMapper(c, Attributes.columns.attr));
	}
	return [];
};

class Salesboard extends Resource {
	constructor() {
		super('salesboards', Attributes);

		this.eventName = 'salesboard';

		this.notifications = {
			save: () => ({
				title: 'default.saved',
				body: 'saved.salesboard',
				style: 'success',
				icon: 'check',
				type: 'body'
			}),
			saveError: () => ({
				title: 'default.error',
				body: 'saveError.salesboard',
				style: 'error',
				icon: 'times',
				type: 'body'
			}),
			delete: () => ({
				title: 'default.deleted',
				body: 'deleted.salesboard',
				style: 'success',
				icon: 'check',
				type: 'body'
			}),
			deleteError: () => ({
				title: 'default.error',
				body: 'deleteError.salesboard',
				style: 'error',
				icon: 'times',
				type: 'body'
			})
		};
	}

	save(salesboard, opts = {}) {
		return super.save(salesboard, opts).then(res => {
			AllIWantDataCache.clearData();

			/* If we set a new standard view to default, we have to sett all othe standard to no longer be default */
			if (res && res.data && res.data.standard && res.data.default) {
				const salesboards = _.filter(Tools.AppService.getListViews('salesboard'), salesboard => {
					return (
						salesboard.standard &&
						salesboard.id !== res.data.id &&
						salesboard.default &&
						!salesboard.isStandardView
					);
				});

				/* Should max find one other. We dont need to fire an event for the saved saleboard as that is allredy done by default */
				for (const salesboard of salesboards) {
					const updatedOtherSalesboard = { ...salesboard, default: false };
					Tools.$rootScope.$broadcast('listView.updated', updatedOtherSalesboard);
				}
			}
			return res;
		});
	}

	delete(id, opts = {}) {
		return super.delete(id, opts).then(res => {
			AllIWantDataCache.clearData();
			return res;
		});
	}

	map(data) {
		if (data.columns) {
			data.columns = mapSalesBoardColumns(data.columns);
		}
		return data;
	}

	new(overrides = {}) {
		return Object.assign(
			{
				id: null,
				default: false,
				standard: false,
				title: '',
				filters: [
					{
						filterName: 'User',
						comparisonType: 'Equals',
						field: 'user.id',
						filterType: 'selectGroup',
						inactive: false,
						value: 'self',
						title: 'default.user'
					},
					{
						filterName: 'Currency',
						comparisonType: 'Match',
						inactive: true,
						value: []
					}
				],
				sort: null,
				columns: []
			},
			overrides
		);
	}
}

const resource = new Salesboard();

window.Tools.Salesboard = resource;

export default resource;
