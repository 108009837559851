import CustomerPortfolioCompany from 'App/resources/Model/CustomerPortfolioCompany';
import { TableColumn, Help, Icon, Text } from '@upsales/components';
import React from 'react';
import T from 'Components/Helpers/translate';
import Tooltip from '@upsales/components/Tooltip/Tooltip';
import { NumberFormat } from 'App/babel/utils/numberFormat';

export const relativeRiskLookup: {
	[key: number]: [number, number];
} = {
	100: [0.0001, 0.0734],
	99: [0.0734, 0.0781],
	98: [0.0781, 0.0831],
	97: [0.0831, 0.0884],
	96: [0.0884, 0.094],
	95: [0.094, 0.1],
	94: [0.1, 0.1064],
	93: [0.1064, 0.1132],
	92: [0.1132, 0.1204],
	91: [0.1204, 0.1281],
	90: [0.1281, 0.1362],
	89: [0.1362, 0.1449],
	88: [0.1449, 0.1541],
	87: [0.1541, 0.164],
	86: [0.164, 0.1744],
	85: [0.1744, 0.1855],
	84: [0.1855, 0.1974],
	83: [0.1974, 0.2099],
	82: [0.2099, 0.2233],
	81: [0.2233, 0.2375],
	80: [0.2375, 0.2526],
	79: [0.2526, 0.2687],
	78: [0.2687, 0.2858],
	77: [0.2858, 0.304],
	76: [0.304, 0.3234],
	75: [0.3234, 0.344],
	74: [0.344, 0.3658],
	73: [0.3658, 0.3891],
	72: [0.3891, 0.4138],
	71: [0.4138, 0.4401],
	70: [0.4401, 0.4681],
	69: [0.4681, 0.4979],
	68: [0.4979, 0.5295],
	67: [0.5295, 0.5631],
	66: [0.5631, 0.5988],
	65: [0.5988, 0.6368],
	64: [0.6368, 0.6772],
	63: [0.6772, 0.7201],
	62: [0.7201, 0.7657],
	61: [0.7657, 0.8142],
	60: [0.8142, 0.8658],
	59: [0.8658, 0.9205],
	58: [0.9205, 0.9787],
	57: [0.9787, 1.0405],
	56: [1.0405, 1.1062],
	55: [1.1062, 1.176],
	54: [1.176, 1.2502],
	53: [1.2502, 1.3289],
	52: [1.3289, 1.4126],
	51: [1.4126, 1.5014],
	50: [1.5014, 1.5957],
	49: [1.5957, 1.6959],
	48: [1.6959, 1.8022],
	47: [1.8022, 1.9151],
	46: [1.9151, 2.0348],
	45: [2.0348, 2.1619],
	44: [2.1619, 2.2968],
	43: [2.2968, 2.4398],
	42: [2.4398, 2.5916],
	41: [2.5916, 2.7525],
	40: [2.7525, 2.9231],
	39: [2.9231, 3.1039],
	38: [3.1039, 3.2955],
	37: [3.2955, 3.4986],
	36: [3.4986, 3.7136],
	35: [3.7136, 3.9414],
	34: [3.9414, 4.1825],
	33: [4.1825, 4.4377],
	32: [4.4377, 4.7076],
	31: [4.7076, 4.9932],
	30: [4.9932, 5.2951],
	29: [5.2951, 5.6142],
	28: [5.6142, 5.9513],
	27: [5.9513, 6.3072],
	26: [6.3072, 6.683],
	25: [6.683, 7.0795],
	24: [7.0795, 7.4976],
	23: [7.4976, 7.9382],
	22: [7.9382, 8.4024],
	21: [8.4024, 8.8912],
	20: [8.8912, 9.4054],
	19: [9.4054, 9.9462],
	18: [9.9462, 10.5144],
	17: [10.5144, 11.1111],
	16: [11.1111, 11.7372],
	15: [11.7372, 12.3937],
	14: [12.3937, 13.0814],
	13: [13.0814, 13.8013],
	12: [13.8013, 14.5542],
	11: [14.5542, 15.3409],
	10: [15.3409, 16.162],
	9: [16.162, 17.0182],
	8: [17.0182, 17.9101],
	7: [17.9101, 18.8382],
	6: [18.8382, 19.8027],
	5: [19.8027, 20.8039],
	4: [20.8039, 21.842],
	3: [21.842, 22.9169],
	2: [22.9169, 24.0285],
	1: [24.0285, 100.0]
};

const negativRatinglangTags: {
	[key: string]: string;
} = {
	'-1': 'customerPortfolio.risks.bankruptcy',
	'-2': 'customerPortfolio.risks.noAccount',
	'-6': 'customerPortfolio.risks.delisted',
	'-8': 'customerPortfolio.risks.distraint',
	'-9': 'customerPortfolio.risks.storageCompany',
	'-11': 'customerPortfolio.risks.liquidation',
	'-12': 'customerPortfolio.risks.dissolved',
	'-15': 'customerPortfolio.risks.reconstruction',
	'-17': 'customerPortfolio.risks.badDebt',
	'-28': 'customerPortfolio.risks.deficiencyInAccount',
	'-29': 'customerPortfolio.risks.bankruptcyApplication',
	'-32': 'customerPortfolio.risks.notActive',
	'-49': 'customerPortfolio.risks.ceoMissing',
	'-52': 'customerPortfolio.risks.auditorMissing',
	'-97': 'customerPortfolio.risks.missingData'
};

function getReasonForMissingCreditRating(company: CustomerPortfolioCompany) {
	const hasCountryAndNoSwedishCountry =
		company.addresses.every(address => address.country !== 'SE') &&
		company.addresses.some(address => !!address.country);

	if (!company.active) {
		return { text: T('customerPortfolio.missingRatingReason.notActive'), articleId: null, tooltip: null };
	} else if (company.companyForm !== 'limited_company') {
		return { text: T('customerPortfolio.missingRatingReason.notLimitedCompany'), articleId: null, tooltip: null };
	} else if (!company.prospectingId && hasCountryAndNoSwedishCountry) {
		return { text: T('customerPortfolio.missingRatingReason.notSwedish'), articleId: null, tooltip: null };
	} else if (!company.prospectingId) {
		return { text: T('customerPortfolio.missingRatingReason.noProspectingId'), articleId: 767, tooltip: null };
	} else if (!company.prospectingId.startsWith('SE.')) {
		return { text: T('customerPortfolio.missingRatingReason.notSwedish'), articleId: null, tooltip: null };
	} else {
		return {
			text: T('customerPortfolio.missingRatingReason.other'),
			articleId: null,
			tooltip: T('customerPortfolio.missingRatingReason.other.tooltip')
		};
	}
}

const NumericCreditRatingColumn = ({ company }: { company: CustomerPortfolioCompany }) => {
	if (company.prospectingNumericCreditRating === null) {
		const { text, articleId, tooltip } = getReasonForMissingCreditRating(company);
		const title = articleId ? (
			<Help articleId={articleId} sidebar>
				<Text>
					{text} <Icon name="question-circle" />
				</Text>
			</Help>
		) : tooltip ? (
			<Tooltip title={tooltip}>
				<Text>
					{text} <Icon name="exclamation-circle" />
				</Text>
			</Tooltip>
		) : (
			text
		);
		const subtitle = T(`customerPortfolio.risks.noPrognosis`);
		return <TableColumn size="lg" title={title} subtitle={subtitle} />;
	} else if (company.prospectingNumericCreditRating < 0) {
		const title = T(
			negativRatinglangTags[company.prospectingNumericCreditRating] ?? 'customerPortfolio.risks.missingData'
		);
		const subtitle = T(`customerPortfolio.risks.noPrognosis`);
		const color = company.prospectingCreditRating === 'F' ? 'danger-5' : 'black';
		return <TableColumn size="lg" title={title} subtitle={subtitle} color={color} />;
	} else {
		const riskInterval = relativeRiskLookup[company.prospectingNumericCreditRating];
		const numberFormat = new NumberFormat(undefined, 2);
		const title = riskInterval
			? `${numberFormat.default(riskInterval[0])}% - ${numberFormat.default(riskInterval[1])}%`
			: '';
		return <TableColumn size="lg" title={title} />;
	}
};

export default NumericCreditRatingColumn;
