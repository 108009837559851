import React, { useState } from 'react';
import ReportcenterFilter from './ReportcenterFilter';
import { Block, Input } from '@upsales/components';
import bemClass from '@upsales/components/Utils/bemClass';
import T from 'Components/Helpers/translate';
import './ReportcenterFilterCurrency.scss';
import ReportcenterFilterRow from './ReportcenterFilterRow';
import { removeItem } from 'Store/helpers/array';
import { RCDashboardFilter } from 'Resources/ReportDashboard';

interface Props {
	onChange: (type: keyof RCDashboardFilter, values: string[], comparison: string) => void;
	values: RCDashboardFilter;
	updateFilterHeight: () => void;
}

export const renderSelected = (values: RCDashboardFilter, returnCount = true) => {
	let res = `${T('default.currency')}: `;

	if (!returnCount) {
		const metaData = Tools.AppService.getMetadata();
		const currencyNames = metaData?.customerCurrencies
			.filter(currency => {
				return values?.Currency?.value?.includes?.(currency.iso) && currency.active;
			})
			.map(currency => currency.iso);
		res += currencyNames.join(', ');
		return res;
	}

	if (values?.Currency?.value?.length) {
		const length = values.Currency.value.length;
		if (length >= 1) {
			res += `${length} ${T('default.selected').toLowerCase()}`;
		}
	}

	return res;
};

const ReportcenterFilterCurrency = ({ onChange, ...props }: Props) => {
	const classes = new bemClass('ReportcenterFilterCurrency');
	const [searchStr, setSearchStr] = useState('');
	const metaData = Tools.AppService.getMetadata();
	const currencies = metaData?.customerCurrencies.filter(c => c.active).map(c => ({ id: c.iso, name: c.iso })) ?? [];
	const array = currencies.filter(item => item.name.toLowerCase().indexOf(searchStr.toLowerCase()) !== -1);
	const value = props.values?.Currency?.value || [];
	const comparison = props.values?.Currency?.comparison ?? 'eq';

	return (
		<ReportcenterFilter
			className={classes.b()}
			renderSelected={() => renderSelected(props.values)}
			getSelectedNames={() => currencies.filter(c => value.includes(c.id)).map(c => c.name)}
			icon="euro-sign"
			placeholder={`${T('default.currency')}: ${T('reportcenter.filter.notActive')}`}
			value={value}
			resetFilter={() => onChange('Currency', [], 'eq')}
			{...props}
		>
			<Block space="ptm prm pbm plm" backgroundColor="white" className={classes.elem('list-select').b()}>
				<Block space="mtm">
					<Input
						onChange={e => setSearchStr(e.target.value)}
						value={searchStr}
						autofocus
						placeholder={`${T('default.search')} ${T('default.currency').toLowerCase()}`}
					/>
				</Block>
			</Block>
			<ReportcenterFilterRow
				key={0}
				onClick={() => {
					onChange('Currency', [], 'eq');
				}}
				selected={!value.length}
				title={T('filters.noFilter')}
			/>
			{array.map(row => (
				<ReportcenterFilterRow
					key={row.id}
					onClick={() => {
						const i = value.indexOf(row.id);
						onChange('Currency', i !== -1 ? removeItem(value, i) : [...value, row.id], comparison);
					}}
					selected={value.indexOf(row.id) !== -1}
					title={row.name}
				/>
			))}
		</ReportcenterFilter>
	);
};

export default ReportcenterFilterCurrency;
