export const convertObjectKeysToEnum = <T extends Record<string, string>>(initialObj: T): Record<keyof T, keyof T> => {
	const desiredEnum: Record<keyof T, keyof T> = {} as Record<keyof T, keyof T>;

	for (const key in initialObj) {
		desiredEnum[key] = key;
	}

	return desiredEnum;
};

export const groupEnumBySuffix = <T extends Record<string, string>>(enumObj: T, prefix: string) => {
	const transformedObj: Record<keyof T, (keyof T)[]> = {} as Record<keyof T, (keyof T)[]>;
	const excludeRegex = new RegExp(`^${prefix}`);

	for (const key in enumObj) {
		if (!excludeRegex.test(key)) continue;

		const oppositeKey = key.replace(excludeRegex, '') as keyof T;
		const oppositeValue = enumObj[oppositeKey];
		const currentValue = enumObj[key];
		transformedObj[oppositeValue] = [oppositeValue, currentValue];
	}

	return transformedObj;
};
