import React from 'react';
import TimelineGeneric from '../TimelineGeneric/TimelineGeneric';

import type { TimelineCardComponent } from '../TimelineGeneric/TimelineCardConfigs';
import { useTranslation } from 'Components/Helpers/translate';
import { Link, Text } from '@upsales/components';
import { ActiveFlowEvent } from 'App/resources/Model/Event';

const TimelineCardActiveFlow = (({ event, ...passthrough }) => {
	const activeFlowEvent = event as ActiveFlowEvent;
	const { t } = useTranslation();

	return (
		<TimelineGeneric
			event={event}
			contactPreposition=""
			titleElements={[
				'contacts',
				() => <Text>{t(`flow.active.activeIn${activeFlowEvent.contacts!.length > 1 ? '.plural' : ''}`)}</Text>,
				() => (
					<Text>
						<Link
							href={`#/${Tools.AppService.getCustomerId()}/segments/${activeFlowEvent.segmentId}/flow/`}
						>
							{activeFlowEvent.flowName}
						</Link>
					</Text>
				),
				() => (
					<>
						<Text>{t('flow.active.createdBy')} </Text> <Text bold>{activeFlowEvent.regByName}</Text>
					</>
				)
			]}
			subTitleElements={[]}
			{...passthrough}
		/>
	);
}) satisfies TimelineCardComponent;

export default TimelineCardActiveFlow;
