import React from 'react';
import PropTypes from 'prop-types';
import BuyButton from '../../DataSource/BuyButton';
import { Block } from '@upsales/components';

export default class Merge extends React.Component {
	constructor(props) {
		super(props);

		var t = Tools.$translate;

		this.lang = {
			title: t('account.newModal.merge.title'),
			subtitle: t('account.newModal.merge.subtitle'),
			addToExisting: t('account.addToExisting'),
			addInfoTo: t('account.addInfoTo'),
			selectCompany: t('default.chooseAnAccount'),
			add: t('default.add'),
			saveCompany: t('default.save') + ' ' + t('default.account').toLowerCase(),
			noName: t('default.noName'),
			adding: t('account.adding'),
			added: t('account.added'),
			abort: t('default.abort'),
			costSingular: t('integration.cost.singular'),
			confirm: t('default.confirm')
		};

		this.merge = this.merge.bind(this);
		this.setMergeIntoAccount = this.setMergeIntoAccount.bind(this);
		this.abortMerge = this.abortMerge.bind(this);

		this.state = {
			selectedMergeAccount: null,
			merging: false
		};
	}

	setMergeIntoAccount(selectedMergeAccount) {
		this.setState({ selectedMergeAccount });
	}

	merge(purchaseType) {
		const selectedMergeAccount = this.state.selectedMergeAccount;
		const { actions, addingAccount } = this.props;

		this.setState({ merging: true });
		const promise = actions.merge(addingAccount, selectedMergeAccount, purchaseType, this.props.resolve);

		if (promise && promise.catch) {
			promise.catch(() => {
				this.setState({ merging: false });
			});
		}
	}

	abortMerge() {
		this.props.actions.abortMerge();
	}

	render() {
		const { addingAccount, accounts } = this.props;
		const { selectedMergeAccount } = this.state;
		const account = addingAccount.account || {};
		const dataSource = accounts[addingAccount.dataSourceId];

		return (
			<div style={{ height: '100%' }}>
				<div className="header-content full-screen-modal-content">
					<div className="merge">
						<div className="title">{this.lang.title}</div>
						{account && account.name ? (
							<div className="subtitle">
								<span>{this.lang.add}</span>&nbsp;
								<span className="subtitle-name">{account.name}</span>&nbsp;
								<span>{this.lang.subtitle}</span>
							</div>
						) : null}
						<div className="content-wrapper">
							<div className="account-select-wrapper">
								<span className="apply-to">{this.lang.addInfoTo}</span>
								<div className="select2-dummy-wrap">
									<ReactTemplates.INPUTS.upAccounts
										className="account-select"
										value={this.state.selectedMergeAccount}
										onChange={this.setMergeIntoAccount}
										placeholder={this.lang.selectCompany}
									/>
									<div className="select2-dummy" />
								</div>
								<Block space="mll">
									<BuyButton
										pricing={dataSource.pricing}
										disabled={!selectedMergeAccount}
										added={addingAccount.added}
										adding={addingAccount.adding || this.state.merging}
										onConfim={this.merge}
										language={{ add: this.lang.addToExisting }}
										icon="code-fork"
									/>
								</Block>
							</div>
							{this.props?.isFromCreateAccount ? (
								<div class="important-message">
									<div
										dangerouslySetInnerHTML={{
											__html: Tools.$translate('account.newModal.merge.important', {
												company: account?.name
											})
										}}
									/>
								</div>
							) : null}
						</div>
					</div>
				</div>
			</div>
		);
	}
}

Merge.propTypes = {
	actions: PropTypes.object,
	account: PropTypes.object,
	dataSourceId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
	addingAccount: PropTypes.object,
	accounts: PropTypes.object,
	resolve: PropTypes.func,
	isFromCreateAccount: PropTypes.bool
};
