import React from 'react';
import { connect } from 'react-redux';
import { RootState } from 'Store/index';
import { AppState } from 'Store/reducers/AppReducer';
import T from 'Components/Helpers/translate';
import ModalWrap from 'Components/ModalWrap';
import { Modal, ModalHeader, ModalContent, ModalControls, Button, Link } from '@upsales/components';

type Entity = {
	entity: string;
	value: {
		id: number;
		name: string;
	};
};

type DeleteForbiddenAlertProps = {
	error: {
		entities: Entity[];
	};
	close: Function;
	accountSelf: AppState['accountSelf'];
};

const mapStateToProps = ({ App }: RootState) => ({
	accountSelf: App.accountSelf
});

const getHref = (accountSelf: AppState['accountSelf'], e: Entity) => {
	if (e.entity === 'Form') {
		return `#/form-overview/${e.value.id}`;
	}
	if (e.entity === 'Flow') {
		const customerId = accountSelf?.client.id;
		return `#/${customerId}/flows/${e.value.id}/`;
	}
};

const DeleteForbiddenAlert = ({ error, close, accountSelf }: DeleteForbiddenAlertProps) => (
	<ModalWrap>
		<Modal>
			<ModalHeader title={T('voice.relatedObject')} />
			<ModalContent>
				{error.entities.map(e => (
					<p key={`${e.entity}-${e.value.id}`}>
						<Link onClick={() => close()} href={getHref(accountSelf, e)}>
							{e.value.name}
						</Link>
					</p>
				))}
			</ModalContent>
			<ModalControls>
				<Button onClick={() => close()}>{T('default.cancel')}</Button>
			</ModalControls>
		</Modal>
	</ModalWrap>
);

export const detached = DeleteForbiddenAlert;

export default connect(mapStateToProps)(DeleteForbiddenAlert);
