import React, { useEffect, useRef, useState } from 'react';
import Client, { ClientAddress, ClientAddressType } from 'App/resources/Model/Client';
import UpSelect from 'Components/Inputs/UpSelect';
import T from 'Components/Helpers/translate';
import {
	Column,
	Input,
	Row,
	Textarea,
	Block,
	ButtonSelect,
	Button,
	Icon,
	OutsideClick,
	Card,
	Table,
	TableRow,
	TableColumn
} from '@upsales/components';
import _ from 'lodash';
import { SlideFade } from 'App/components/animations';
import './EditClientAddressTabs.scss';
import bemClass from '@upsales/components/Utils/bemClass';
import { InputProps } from '../FormObserver/FormObserver';

type EditClientAddressTabsProps = {
	selected: ClientAddress['type'] | null;
	onChange: (type: ClientAddress['type']) => void;
	onAdd: (type: ClientAddress['type']) => void;
	addresses: Client['addresses'];
};

const AvailableAddresses = ['Mail', 'Visit', 'Invoice', 'Delivery', 'Other'];

export const EditClientAddressTabs = ({ selected, addresses, onChange, onAdd }: EditClientAddressTabsProps) => {
	const classes = new bemClass('EditClientAddressTabs');
	const ddRef = useRef<HTMLDivElement>(null);
	const [open, setOpen] = useState(false);
	const [availableAddresses, setAvailableAddresses] = useState<string[]>([]);
	useEffect(() => {
		setAvailableAddresses(AvailableAddresses.filter(type => !addresses.find(a => a.type === type)));
	}, [addresses]);
	return (
		<Row className={classes.b()}>
			<Column>
				{selected ? (
					<ButtonSelect
						options={addresses.map(a => ({
							title: T(`address.${a.type.toLowerCase()}`),
							value: a.type
						}))}
						value={selected}
						onChange={onChange}
						size="sm"
					/>
				) : null}
			</Column>
			<Column align="right">
				<OutsideClick targetRef={() => ddRef} outsideClick={() => setOpen(false)} listen={open}>
					<Button
						color="super-light-green"
						size="sm"
						onClick={() => setOpen(true)}
						disabled={!availableAddresses.length}
					>
						<Icon name="plus" space="mrs" />
						{T('default.addAddress')}
					</Button>
					<SlideFade direction="top" visible={open}>
						<div ref={ddRef} className={classes.elem('dropdown').b()}>
							<Card borderRadius>
								<Table>
									{availableAddresses.map(a => (
										<TableRow
											className="TableRow--clickable"
											key={a}
											onMouseDown={() => onAdd(a as ClientAddressType)}
										>
											<TableColumn>{T(`address.${a.toLowerCase()}`)}</TableColumn>
										</TableRow>
									))}
								</Table>
							</Card>
						</div>
					</SlideFade>
				</OutsideClick>
			</Column>
		</Row>
	);
};

type EditClientAddressProps = {
	type: keyof typeof ClientAddressType;
	address: ClientAddress;
	onRemove: null | ((type: keyof typeof ClientAddressType) => void);
	inputProps: { [key: string]: InputProps<any> };
};

const getInputProps = (type: string, inputProps: EditClientAddressProps['inputProps'], field: string) =>
	inputProps[`addresses.${type}.${field}`];

const EditClientAddress = ({ address, onRemove, inputProps, type }: EditClientAddressProps) => {
	const [countries, setCountries] = useState<{ name?: string; id: string }[]>([]);
	useEffect(() => {
		const countries = Tools.CountryCodes.countries().map<{ name: string; id: string }>(c => ({
			id: c.id.toLowerCase(),
			name: T(c.lang)
		}));
		setCountries(_.sortBy(countries, 'name'));
	}, []);
	const countryProps = getInputProps(type, inputProps, 'country');
	return (
		<div className="EditClientAddress">
			<Textarea {...getInputProps(type, inputProps, 'address')} placeholder={T('address.address')} />
			<Block space="mtl">
				<Row>
					<Column>
						<Input {...getInputProps(type, inputProps, 'zipcode')} placeholder={T('address.zip')} />
					</Column>
					<Column fixedWidth={14} />
					<Column>
						<Input {...getInputProps(type, inputProps, 'city')} placeholder={T('address.city')} />
					</Column>
					<Column fixedWidth={14} />
					<Column>
						<Input {...getInputProps(type, inputProps, 'state')} placeholder={T('address.state')} />
					</Column>
					<Column fixedWidth={14} />
					<Column>
						{countries.length ? (
							<UpSelect
								defaultValue={countryProps.value?.toLowerCase() ?? null}
								state={countryProps.state}
								name={countryProps.name}
								data={countries}
								placeholder={T('address.country')}
								onChange={countryProps.onChange}
							/>
						) : null}
					</Column>
				</Row>
			</Block>
			{onRemove ? (
				<Button type="link" onClick={() => onRemove(address.type)} color="grey">
					<Icon name="trash" space="mrs" />
					{T('editClient.removeAddress', { type: T(`address.${address.type.toLowerCase()}`).toLowerCase() })}
				</Button>
			) : null}
		</div>
	);
};

export default EditClientAddress;
