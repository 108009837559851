import { containsPhoneWords } from 'App/babel/helpers/phoneWords';
import type ActivityType from 'App/resources/Model/ActivityType';

export const getActivityTypeIcon = (activityType?: Partial<ActivityType> & Pick<ActivityType, 'id'>) => {
	const todoTypes = Tools.AppService.getTodoTypes();
	if (activityType?.id === todoTypes.PHONE_CALL.id || containsPhoneWords(activityType?.name)) {
		return 'phone';
	}
	return 'activity';
};
