import { formatSubscriptionPeriod } from '../Context/SubscriptionPeriodContextHelpers';
import SubscriptionPeriodFooter from '../SubscriptionPeriod/SubscriptionPeriodFooter';
import { useSubscriptionGroupContext } from '../Context/SubscriptionGroupContext';
import SubscriptionHotspot from '../SubscriptionHotspot/SubscriptionHotspot';
import RequiredProratedCheckbox from './RequiredProratedCheckbox';
import { Text, Headline, Row, Block } from '@upsales/components';
import BemClass from '@upsales/components/Utils/bemClass';
import DifforderCheckboxes from './DifforderCheckboxes';
import React, { useEffect, useMemo } from 'react';
import { makeCancelable } from 'Helpers/promise';
import T from 'Components/Helpers/translate';
import logError from 'Helpers/logError';

import './SubscriptionDifforder.scss';

const SubscriptionDifforder = () => {
	const {
		state: { subscriptionMap, diffOrders, orderToAdd },
		setDiffOrders,
		showDiffOrder,
		setCreditDate
	} = useSubscriptionGroupContext();

	const classes = new BemClass('SubscriptionDifforder');

	const oneOffs = useMemo(() => {
		const rows = orderToAdd?.orderRow.filter(row => !row.product?.isRecurring) ?? [];
		const oneOffs = rows.map(row => {
			const totalValue = row.quantity * row.price;
			return { ...row, totalValue };
		});
		return oneOffs;
	}, []);

	useEffect(() => {
		const periods = Object.values(subscriptionMap);
		const agreements = periods.map(p => formatSubscriptionPeriod(p));
		const diffOrderPromise = makeCancelable(
			Tools.Agreement.customer(Tools.AppService.getCustomerId()).diffOrders(agreements, oneOffs)
		);

		diffOrderPromise.promise
			.then(({ data }) => {
				setDiffOrders(data);
			})
			.catch(e => {
				logError(e, 'Could not fetch diffOrders');
			});

		return () => diffOrderPromise?.cancel();
	}, []);

	const agreementsToShowDiffOrder = useMemo(
		() => diffOrders.filter(order => order.show).map(order => order.agreementId),
		[diffOrders]
	);

	const diffOrderIsRequired = !!orderToAdd;

	const proratedHeadline = diffOrderIsRequired
		? T('subscription.modal.difforder.titleRequired')
		: T('subscription.modal.difforder.title');
	const proratedSubtitle = diffOrderIsRequired
		? T('subscription.modal.difforder.subtitleRequired')
		: T('subscription.modal.difforder.subtitle');

	const creditHeadline = T('subscription.modal.credit.title');
	const creditSubtitle = T('subscription.modal.credit.subtitle');

	if (!diffOrders.length) return null;

	let last = '';
	const titleMap = diffOrders.reduce<{ [id: number]: boolean }>((acc, curr) => {
		const orderType = curr.totalValue > 0 ? 'prorated' : 'credit';
		acc[curr.agreementId] = last !== orderType;
		last = orderType;
		return acc;
	}, {});

	return (
		<div className={classes.b()}>
			{diffOrderIsRequired ? (
				<div className={classes.elem('container').b()}>
					<Block space="mbl">
						<Row align="center">
							<Headline size="sm">{proratedHeadline}</Headline>
							<SubscriptionHotspot type="proratedOrder" />
						</Row>
					</Block>
					<Text>{proratedSubtitle}</Text>
					<RequiredProratedCheckbox diffOrder={diffOrders[0]} oneOffs={oneOffs} />
				</div>
			) : (
				<>
					{diffOrders.map(diffOrder => (
						<div className={classes.elem('container').b()} key={diffOrder.agreementId}>
							{titleMap[diffOrder.agreementId] ? (
								<>
									<Block space="mbl mtxl">
										<Row align="left">
											<Headline size="sm">
												{diffOrder.totalValue > 0 ? proratedHeadline : creditHeadline}
											</Headline>
											<SubscriptionHotspot type="proratedOrder" />
										</Row>
									</Block>

									<Text color="grey-11">
										{diffOrder.totalValue > 0 ? proratedSubtitle : creditSubtitle}
									</Text>
								</>
							) : null}
							<Block space="mtxl" key={diffOrder.agreementId}>
								<DifforderCheckboxes
									diffOrder={diffOrder}
									createDifforder={diffOrder.show}
									setCreateDifforder={(shouldCreate: boolean) =>
										showDiffOrder(shouldCreate, diffOrder.agreementId)
									}
									setInitialCreditDate={(initialDate: string | undefined) =>
										setCreditDate(initialDate, diffOrder.agreementId)
									}
								/>
							</Block>
						</div>
					))}
				</>
			)}

			<SubscriptionPeriodFooter
				hideGreenBox
				agreementsToShowDiffOrder={agreementsToShowDiffOrder}
				oneOffs={oneOffs}
			/>
		</div>
	);
};

export default SubscriptionDifforder;
