import _ from 'lodash';
import T from 'Components/Helpers/translate';
import ComparisonTypes from 'Resources/ComparisonTypes';
import type { MultiSelect } from 'App/components/ListView/ListViewRenderHelpers';
import RequestBuilder from 'Resources/RequestBuilder';
import { MultiSelectContext } from 'App/components/MultiselectProvider/MultiselectProvider';

// This file is to help reduce repeated code in the angular Multiaction modals. It is a step on the way to a full port,
// and should make the files more managable for whoever makes the port. This file should not be needed once the port is
// done since the functionality here should be placed in a more sensible and generic component structure.
// There is still plenty of repetition, but I wanted to avoid mutating the scope in ways that are not obvious

// Typing is incomplete and very loose. Only specifies what is used in these functions and many are very generic and/or best guess

type Field = { id: string; name: string; value?: any; dates?: Field[]; datatype?: string };
type Property = { name: string; value: any };
type Scope = { [key: string]: any };
type SaveProps = {
	scope: Scope;
	modalParams: {
		extraParams?: object;
		entity?: string;
		onSave?: Function;
		filters?: RequestBuilder;
		multiselect?: MultiSelectContext;
	};
	multiSelect: { selected: number[]; selectNone: Function; reactList?: boolean };
	method: keyof ReturnType<typeof Tools.MultiActions.customer>;
	entityType: string;
	arrayProperties?: string[];
	startingProperties?: Property[];
	entityArg?: string | null;
	onSuccess?: Function;
	scopeProperties?: object | null;
};

export const getUsersByTag = (tagEntity: string, includeAssigned = false) => {
	const out: { id: string; name: string }[] = [];

	out.push({
		id: '{{General.CurrentUserId}}',
		name: T('tag.general.currentuser')
	});

	switch (tagEntity) {
		case 'client':
			out.push({
				id: '{{Client.RegById}}',
				name: T('tag.group.Client') + ': ' + T('default.regBy')
			});
			break;

		case 'contact':
			out.push({
				id: '{{Contact.RegById}}',
				name: T('tag.group.Contact') + ': ' + T('default.regBy')
			});
			out.push({
				id: '{{Client.RegById}}',
				name: T('tag.group.Client') + ': ' + T('default.regBy')
			});
			break;

		case 'order':
			out.push({
				id: '{{Order.UserId}}',
				name: T('tag.group.Order') + ': ' + T('default.salesRep')
			});
			out.push({
				id: '{{Contact.RegById}}',
				name: T('tag.group.Contact') + ': ' + T('default.regBy')
			});
			out.push({
				id: '{{Client.RegById}}',
				name: T('tag.group.Client') + ': ' + T('default.regBy')
			});
			break;

		case 'activity':
			out.push({
				id: '{{Activity.UserId}}',
				name: T('tag.group.Activity') + ': ' + T('default.user')
			});
			out.push({
				id: '{{Activity.RegById}}',
				name: T('tag.group.Activity') + ': ' + T('default.regBy')
			});
			out.push({
				id: '{{Contact.RegById}}',
				name: T('tag.group.Contact') + ': ' + T('default.regBy')
			});
			out.push({
				id: '{{Client.RegById}}',
				name: T('tag.group.Client') + ': ' + T('default.regBy')
			});
			break;
	}

	if (includeAssigned) {
		out.push({
			id: '{{Client.AssignedUserId}}',
			name: T('tag.group.Client') + ': ' + T('automationTerms.assignedUser')
		});
	}

	out.push({
		id: '{{Client.UserId}}',
		name: T('tag.group.Client') + ': ' + T('default.accountManager')
	});

	return out;
};

export const prepDates = (entity: string) => {
	const dateTags = [];

	const dateTypes = Tools.TagsService.getTagsByEntity(entity).reduce(
		(result: { [key: string]: any }, value: { type: string; entity: string }) => {
			if (value.type !== 'Date') {
				return result;
			}

			if (!result[value.entity]) {
				result[value.entity] = {
					name: T('default.' + value.entity),
					children: []
				};
			}

			result[value.entity].children.push(value);
			dateTags.push(value);
			return result;
		},
		{}
	);

	const customDateTypes = _.cloneDeep(dateTypes);
	if (customDateTypes.general && customDateTypes.general.children) {
		const clearField = { name: T('tag.general.clearfield'), tag: 'General.ClearField' };
		customDateTypes.general.children.push(clearField);
		customDateTypes.general.children.push({
			name: T('tag.special.regularDate'),
			tag: 'RegularDate'
		});
		dateTags.push(clearField);
	}

	return { dateTags, dateTypes, customDateTypes };
};

export const prepMultiSelect = (orig: object, newMultiSelect: MultiSelect | undefined) => {
	// Apply some fields from new multiselect object to the old one for compatibility between old and new lists
	if (newMultiSelect) {
		const applyObj = newMultiSelect.allSelected
			? {
					allSelectedFilter: true
			  }
			: {
					selected: newMultiSelect.selectedIds.map(Number)
			  };
		return {
			reactList: true,
			...orig,
			...applyObj
		};
	}
	return orig;
};

export const formatDateType = (dateTypes: object) => ({
	data: Object.values(dateTypes),
	formatResult: (obj: object, container: never, query: never, escape: Function) => escape(_.property('name')(obj)),
	formatSelection: (obj: object, container: never, encode: Function) => encode(_.property('name')(obj)),
	matcher: (term: string, text: never, op: { name: string }) => {
		return op.name.toUpperCase().indexOf(term.toUpperCase()) >= 0;
	},
	id: _.property('tag')
});

export const getAvailableFields = (scope: Scope, startingFields: Field[], customDateTypes: object) => {
	let newFields = _.cloneDeep(startingFields);
	if (!scope.isTrigger) {
		newFields = _.filter(newFields, 'editable');
	}
	return newFields.map(field => {
		if (customDateTypes && field.datatype === 'Date') {
			field.dates = Object.values(customDateTypes);
		}
		return field;
	});
};

export const executeSave = ({
	scope,
	modalParams,
	multiSelect,
	entityType,
	method,
	arrayProperties = [],
	startingProperties = [],
	entityArg = null,
	onSuccess = () => {},
	scopeProperties = null
}: SaveProps) => {
	scope.saving = true;

	// map properties
	const properties = startingProperties;
	const scopeProps = scopeProperties ?? scope.properties;
	Tools.ActionProperties.mapCustomFields(properties, scope.customFields, entityType);
	Tools.ActionProperties.map(properties, scopeProps, arrayProperties);

	// This should be fine to run for any modal? Only gonna do something where relevant
	const journeyStepProperty = _.find(properties, { name: 'JourneyStep' });
	if (journeyStepProperty) {
		properties.push({ name: 'ForceInvalidPropertyChange', value: true });
	}

	if (modalParams.onSave) {
		return modalParams
			.onSave(properties)
			.then(scope.resolve)
			.catch(() => {
				scope.saving = false;
			});
	}
	if (!modalParams.filters || !properties.length) {
		return scope.resolve(properties);
	}

	var filters = modalParams.filters;
	// NOTE: Old lists do not apply this filter before coming here. This is not a good place to do this, but it's what we got. I added the
	//  awkward little reactList attribute to avoid duplicating the filter for new lists
	if (modalParams.multiselect?.selectedIds.length) {
		filters.addFilter({ field: 'id' }, filters.comparisonTypes.Equals, modalParams.multiselect.selectedIds);
	} else if (multiSelect.selected?.length && !multiSelect.reactList) {
		filters.addFilter({ field: 'id' }, ComparisonTypes.Equals, multiSelect.selected);
	}

	// thanks javascript for not being too fussy about sending more arguments than needed
	const extraParams = modalParams.extraParams ? modalParams.extraParams : {};
	const methodArgs: any[] = [multiSelect.selected.length, properties, filters.build(), extraParams];
	if (entityArg) {
		// :(
		methodArgs.unshift(entityArg);
	}

	Tools.MultiActions.customer(Tools.AppService.getCustomerId())
		// @ts-ignore impossible methodArgs
		[method](...methodArgs)
		.then(() => onSuccess(properties))
		.then(() => {
			scope.saving = false;
			// Deselect all and close modal
			multiSelect.selectNone();
			scope.resolve();
		})
		.catch(() => (scope.saving = false));
};

export const parseDate = (properties: Property[], tags: any[], property: string) => {
	var res: { type: string | null; offset: number | null } = {
		type: null,
		offset: null
	};

	if (!properties) {
		return res;
	}

	var date = properties.find((prop: Property) => {
		return prop.name === property;
	});
	if (date && date.value) {
		var value = date.value;
		value = value.replace(/[{}]/g, '');
		var split = value.split(':');
		res.type = tags.reduce((result, group) => result ?? _.find(group.children, { tag: split[0] }), undefined);
		res.offset = parseInt(split[1]);
	}

	return res;
};

export const convertToNewMultiSelect = (oldMultiSelect: Omit<MultiSelect, 'selectedIds'>) => {
	return {
		...oldMultiSelect,
		selectedIds: oldMultiSelect.selected
	};
};

const defaultScopeFunctions = {
	addField: (scope: Scope) => (field: Field) => {
		_.remove(scope.availableFields, { id: field.id });
		scope.customFields.push(field);
		scope.fieldToAdd = null;
	},
	removeField: (scope: Scope) => (field: Field) => {
		_.remove(scope.customFields, { id: field.id });
		scope.availableFields.push(field);
		scope.reloadModalPosition();
	},
	addTime: (scope: Scope) => (time: Date) => {
		scope.properties.Time = time;
		if (!scope.properties.Date) {
			scope.properties.Date = new Date();
		}
		if (time) {
			scope.properties.Date.setHours(time.getHours(), time.getMinutes(), 0, 0);
		}
	},
	setDate: (scope: Scope) => () => {
		if (!scope.date.type || !scope.date.type.tag) {
			return;
		}

		scope.properties.Date = '{{' + scope.date.type.tag + ':' + (scope.date.offset || 0) + '}}';
	},
	changeCustomDate: (scope: Scope) => (field: Field) => {
		if (field.value && field.value.tag === 'RegularDate') {
			field.dates = undefined;
			// Unsure of the purpose of the line below or if it should be on every modal. It was added on updateOrderMulti.js,
			// clearly for some reason, so it makes some sense to do the same for the other modals?
			field.value = '';
		}
	},
	isSaveDisabled: (scope: Scope) => () => {
		var hasProperty = scope.properties?.reduce((res: boolean, property: any) => {
			if (res) {
				return res;
			} else if (Array.isArray(property)) {
				return !!property.length;
			} else if (typeof property === 'number') {
				return true;
			} else {
				return !!property;
			}
		}, false);

		return (
			scope.saving ||
			(scope.myForm.$invalid && scope.myForm.$dirty) ||
			(!hasProperty && !scope.customFields.length && !scope.userRole)
		);
	},
	showErrorMessage: (scope: Scope) => (form: any) => {
		for (var key in form.$error) {
			if (key !== 'required') {
				return true;
			} else {
				var requiredArr = form.$error.required;
				for (var itr = 0; itr < requiredArr.length; ++itr) {
					var obj = requiredArr[itr];
					if (!obj.$pristine) {
						return true;
					}
				}
			}
		}
		return false;
	},
	createCampaign: (scope: Scope) => () => {
		return Tools.$upModal
			.open('editCampaign', { customerId: Tools.AppService.getCustomerId(), noRedirect: true })
			.then((campaign: { id: string }) => {
				if (scope.campaigns) {
					scope.campaigns.push(campaign);
					if (scope.properties.AddProject && Array.isArray(scope.properties.AddProject)) {
						scope.properties.AddProject.push(campaign.id);
					} else {
						scope.properties.AddProject = [campaign.id];
					}
				} else {
					scope.properties.Project = campaign.id;
				}
			});
	}
};

export const attachScopeFunctions = (scope: Scope, funcs: (keyof typeof defaultScopeFunctions)[]) => {
	// Mutates scope, adding functions from defaultScopeFunctions
	return funcs.map(name => (scope[name] = defaultScopeFunctions[name](scope)));
};
