import Resource from './Resource';

class LoginStats extends Resource {
	constructor() {
		super('master/loginStats');
	}

	getYearSum() {
		return this._getRequest('sum', { methodName: 'getYearSum' }).then(r => r.data);
	}

	userSurveyReview() {
		return this._getRequest('usersurveyreview', { methodName: 'userSurveyReview' }).then(r => r.data);
	}
}

export default new LoginStats();
