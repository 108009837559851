import React, { ComponentProps } from 'react';
import { Card } from '@upsales/components';
import bemClass from '@upsales/components/Utils/bemClass';
import './MailEditorReviewSection.scss';

type Props = ComponentProps<typeof Card>;

const MailEditorReviewSection = ({ className, ...props }: Props) => {
	const classes = new bemClass('MailEditorReviewSection', className);

	return <Card {...props} className={classes.b()} />;
};

export default MailEditorReviewSection;
