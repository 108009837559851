import { Equals } from 'Resources/ComparisonTypes';
import DatePreset from 'App/upsales/common/services/datePresetService';

export const newFilter = () => ({
	inactive: true,
	value: {
		hasFormSubmits: true,
		Date: { value: { start: null, end: null, preset: 'whenever' } },
		Form: { value: [], comparisonType: Equals }
	}
});

const formSubmitFilter = {
	generate: newFilter,
	isInactive: filter => filter.inactive,
	toUrl: filter => {
		const f = { v: {} };
		const val = filter.value;

		f.v.has = val.hasFormSubmits;

		if (val.Date.value.start || val.Date.value.end) {
			f.v.da = { p: val.Date.value.preset, s: val.Date.value.start, e: val.Date.value.end };
		}
		if (val.Form.value) {
			f.v.fo = val.Form.value;
		}
		if (val.Form.comparisonType) {
			f.v.fc = val.Form.comparisonType;
		}

		return f;
	},
	getText: () => {
		return 0;
	},
	fromUrl: rawFilter => {
		const filter = newFilter();
		filter.inactive = false;
		const val = rawFilter.v;

		filter.value.hasFormSubmits = val.has;

		if (val.da) {
			filter.value.Date = {
				value: {
					start: val.da.s ? new Date(val.da.s) : val.da.s,
					end: val.da.e ? new Date(val.da.e) : val.da.e,
					preset: val.da.p
				}
			};
		}
		if (val.fo) {
			filter.value.Form.value = val.fo;
		}
		if (val.fc) {
			filter.value.Form.comparisonType = val.fc;
		}

		return filter;
	},
	build: () => (filter, rb, getField, useTags) => {
		const val = filter.value;

		if (filter.inactive) {
			return;
		}

		if (!val.hasFormSubmits && val.Date.value.preset === 'whenever' && !val.Form.value.length) {
			// If all filters is empty we only need to check for missing submits
			rb.addFilter(getField('formSubmit.id'), rb.comparisonTypes.Equals, null);
		} else if (
			val.hasFormSubmits &&
			val.Form.comparisonType === rb.comparisonTypes.EqualsAll &&
			val.Form.value.length
		) {
			for (const value of val.Form.value) {
				const groupBuilder = rb.groupBuilder();

				// Date
				if (val.Date.value.preset !== 'whenever') {
					const dates = DatePreset.getDatesFromValue(val.Date.value, useTags);

					// start
					if (dates.start) {
						groupBuilder.addFilter(
							getField('formSubmit.regDate'),
							rb.comparisonTypes.GreaterThanEquals,
							dates.start
						);
					}

					// end
					if (dates.end) {
						groupBuilder.addFilter(
							getField('formSubmit.regDate'),
							rb.comparisonTypes.LowerThanEquals,
							dates.end
						);
					}
				}

				groupBuilder.addFilter(getField('formSubmit.form.id'), rb.comparisonTypes.Term, value);

				groupBuilder.done();
			}
		} else {
			const groupBuilder = rb.groupBuilder();

			if (val.hasFormSubmits) {
				groupBuilder.addFilter(getField('formSubmit.id'), rb.comparisonTypes.NotEquals, null);
			} else {
				groupBuilder.isNotFilter();
			}

			// Date
			if (val.Date.value.preset !== 'whenever') {
				const dates = DatePreset.getDatesFromValue(val.Date.value, useTags);

				// start
				if (dates.start) {
					groupBuilder.addFilter(
						getField('formSubmit.regDate'),
						rb.comparisonTypes.GreaterThanEquals,
						dates.start
					);
				}

				// end
				if (dates.end) {
					groupBuilder.addFilter(
						getField('formSubmit.regDate'),
						rb.comparisonTypes.LowerThanEquals,
						dates.end
					);
				}
			}

			if (val.Form.value.length) {
				groupBuilder.addFilter(getField('formSubmit.form.id'), rb.comparisonTypes.Term, val.Form.value);
			}

			groupBuilder.done();
		}
	}
};

export default formSubmitFilter;
