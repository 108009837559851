import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { get } from 'lodash';
import { Text } from '@upsales/components';
import TimelineRow from 'Components/TimelineRow';

const ActivityTimelineUserChanged = ({ event, hideIcon }) => {
	const t = Tools.$translate;
	const currentUser = get(event, 'data.user') || {};
	const oldUser = get(event, 'data.oldUser') || {};
	const user = get(event, 'user') || {};
	const date = get(event, 'date');
	const lang = {
		changedUserTo: t('activity.outcomeLog.changedUserTo'),
		previousAssigned: t('activity.outcomeLog.previousAssigned')
	};
	const isEqual = currentUser.id === oldUser.id;

	if (isEqual) return null;

	const title = (
		<Text>
			<Fragment>
				{lang.changedUserTo} {currentUser.name}
			</Fragment>
		</Text>
	);
	const subTitle = date ? (
		<Text size="sm" color="grey-11">
			{moment(date).format('L LT')} <span dangerouslySetInnerHTML={{ __html: '&#8226;' }} /> {user.name}
		</Text>
	) : null;
	const subSection = oldUser.name ? (
		<Text className="subSection" color="grey-10">
			<Fragment>
				{lang.previousAssigned} {oldUser.name}
			</Fragment>
		</Text>
	) : null;

	return (
		<TimelineRow
			icon={hideIcon ? undefined : 'edit'}
			event={event}
			title={title}
			subTitle={subTitle}
			subSection={subSection}
		/>
	);
};

ActivityTimelineUserChanged.propTypes = {
	event: PropTypes.shape({})
};

export default ActivityTimelineUserChanged;
