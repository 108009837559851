import { CancelablePromise, makeCancelable } from '@upsales/components/Utils/CancelablePromise';
import RequestBuilder, { comparisonTypes } from 'Resources/RequestBuilder';
import {
	ButtonSelect,
	DropDownMenu,
	ClickableItem,
	Loader,
	Block,
	Input,
	Link,
	Text,
	Icon,
	Row,
	Flex,
	Label,
	Tooltip
} from '@upsales/components';
import { TicketForm, getMainRecipient } from 'App/components/EditTicket/Context/Helpers';
import { useEditTicketContext } from '../../../Context/Context';
import { useTranslation } from 'Components/Helpers/translate';
import React, { useEffect, useMemo, useRef, useState } from 'react';
import BemClass from '@upsales/components/Utils/bemClass';
import Contact from 'App/resources/Model/Contact';
import ContactResource from 'App/resources/Contact';
import ClientResource from 'App/resources/Client';
import logError from 'Helpers/logError';

import './ChangeContact.scss';
import { PrimaryButton } from '@upsales/components/Buttons';
import ValidationService from 'Services/ValidationService';
import Client from 'App/resources/Model/Client';
import { getJourneyStep } from 'Components/Helpers/journeyStep';
import SubAccountLabel from 'Components/Misc/SubAccountLabel';
import { useSoftDeployAccess } from 'App/components/hooks';
import { formatWithSubAccounts } from 'App/helpers/accountsHelper';
import { SelectTypeWithChildren } from 'App/components/GenericSelectEntityModal/GenericSelectEntityModal';

const LIMIT = 10;

enum MenuStages {
	Entry,
	Contact,
	Client,
	Email
}

const ChangeContact = ({
	onChange,
	disabled,
	values
}: {
	onChange: (type: string, value: any) => void;
	values: TicketForm['contactInfo'];
	disabled?: boolean;
}) => {
	const {
		state: { ticket }
	} = useEditTicketContext();
	const classes = useMemo(() => new BemClass('ChangeContact'), []);
	const hasSubAccounts = useSoftDeployAccess('SUB_ACCOUNTS');
	const { t } = useTranslation();

	const { client } = ticket ?? {};
	const mainRecipient = getMainRecipient(ticket.involved);
	const { contact } = mainRecipient ?? {};

	const [loadingClientContacts, setLoadingClientContacts] = useState<boolean>(false);
	const [buttonSelect, setButtonSelect] = useState<'client' | 'all'>(client ? 'client' : 'all');
	const [loadingContacts, setLoadingContacts] = useState<boolean>(false);
	const [clientContacts, setClientContacts] = useState<Contact[]>([]);
	const [allContacts, setAllContacts] = useState<Contact[]>([]);
	const [clients, setClients] = useState<Client[]>([]);
	const [loadingClients, setLoadingClients] = useState<boolean>(false);
	const [searchString, setSearchString] = useState<string>('');
	const [stage, setStage] = useState<number>(0);
	const [email, setEmail] = useState<string>(mainRecipient?.email ?? '');
	const validEmail = ValidationService.validEmail(email);

	const getContactsPromise = useRef<null | CancelablePromise<Awaited<ReturnType<typeof ContactResource.find>>>>(null);
	const getContactsOnClientPromise = useRef<null | CancelablePromise<
		Awaited<ReturnType<typeof ContactResource.find>>
	>>(null);
	const getClientsPromise = useRef<null | CancelablePromise<Awaited<ReturnType<typeof ClientResource.find>>>>(null);

	useEffect(() => {
		const getContacts = () => {
			setLoadingContacts(true);
			const rb = new RequestBuilder();
			if (contact) {
				rb.addFilter({ field: 'id' }, comparisonTypes.NotEquals, contact.id);
			}
			if (client) {
				rb.addFilter({ field: 'client.id' }, comparisonTypes.NotEquals, client.id);
			}

			getContactsPromise.current = makeCancelable(ContactResource.search(searchString, undefined, undefined, rb));
			getContactsPromise.current.promise
				.then(({ data }) => {
					setAllContacts(data);
				})
				.catch(e => logError(e, 'Failed to find contacts'))
				.finally(() => setLoadingContacts(false));
		};

		const getContactOnClient = () => {
			setLoadingClientContacts(true);
			const rb = new RequestBuilder();
			rb.addFilter({ field: 'client.id' }, comparisonTypes.Equals, client.id);
			if (contact) {
				rb.addFilter({ field: 'id' }, comparisonTypes.NotEquals, contact.id);
			}

			getContactsOnClientPromise.current = makeCancelable(
				ContactResource.search(searchString, undefined, undefined, rb)
			);
			getContactsOnClientPromise.current.promise
				.then(({ data }) => {
					setClientContacts(data);
				})
				.catch(e => logError(e, 'Failed to find contacts on client'))
				.finally(() => setLoadingClientContacts(false));
		};

		const getClients = () => {
			setLoadingClients(true);
			const rb = new RequestBuilder();

			if (searchString.length > 0) {
				const orFilter = rb.orBuilder();
				orFilter.next();
				orFilter.addFilter({ field: 'name' }, comparisonTypes.Search, searchString);

				if (!isNaN(parseInt(searchString))) {
					orFilter.next();
					orFilter.addFilter({ field: 'id' }, comparisonTypes.Search, parseInt(searchString));
				}

				orFilter.done();
			}
			rb.addSort('name', true);
			rb.limit = LIMIT;
			getClientsPromise.current = makeCancelable(ClientResource.find(rb.build()));
			getClientsPromise.current.promise
				.then(({ data }) => {
					setClients(data);
				})
				.catch(e => logError(e, 'Failed to find clients'))
				.finally(() => setLoadingClients(false));
		};

		if (stage === MenuStages.Entry || stage === MenuStages.Contact) {
			getContacts();
			if (client) {
				getContactOnClient();
			}
		}

		if (stage === MenuStages.Entry || stage === MenuStages.Client) {
			getClients();
		}

		return () => {
			getContactsOnClientPromise?.current?.cancel();
			getContactsPromise?.current?.cancel();
		};
	}, [client?.id, contact?.id, searchString]);

	const sameClient = 'client' === buttonSelect;
	const contacts = sameClient ? clientContacts : allContacts;
	const loading = stage === 1 ? (sameClient ? loadingClientContacts : loadingContacts) : loadingClients;

	const changeContactTitle = contact ? t('ticket.changeContact') : t('ticket.changeToContact');
	const changeEmailTitle = !contact ? t('ticket.changeEmail') : t('ticket.changeToEmail');
	const changeClientTitle = client ? t('ticket.changeClient') : t('ticket.addClient');

	const clientOptions = (close: () => void) => {
		const clientRow = (client: Client, isSubAccount = false) => (
			<Flex
				key={client.id}
				className={classes.elem('row').b()}
				data-id={client.id}
				direction="row"
				justifyContent="space-between"
				alignItems="center"
				space={`prl${isSubAccount ? ' pll' : ''}`}
				onClick={() => {
					onChange('contactInfo', {
						client: client,
						contact: null,
						email: values.email
					});
					setSearchString('');
					close();
				}}
			>
				<Flex className={classes.elem('userInfo').b()} direction="column" flex={1} space="mll">
					<Text ellipsis className={classes.elem('row').elem('title').b()}>
						{client?.name}
					</Text>
					<Text size="sm" color="grey-11" ellipsis>
						{getJourneyStep(client?.journeyStep)?.name}
					</Text>
				</Flex>
				{hasSubAccounts ? <SubAccountLabel operationalAccount={client.operationalAccount} /> : null}
			</Flex>
		);
		if (!hasSubAccounts) {
			return clients.map(cl => clientRow(cl));
		}
		const subGroups = formatWithSubAccounts(clients, t) as SelectTypeWithChildren<Client>[];
		return subGroups.map(group => (
			<React.Fragment key={group.title}>
				<Flex
					key={group.title}
					className={classes.elem('row').b()}
					direction="row"
					justifyContent="space-between"
					alignItems="center"
					space="prl"
				>
					<Flex className={classes.elem('userInfo').b()} gap={4} flex={1} space="mll">
						<Icon name={group.icon} />
						<Text ellipsis bold>
							{group.title}
						</Text>
					</Flex>
				</Flex>
				{group.children.map(cl => clientRow(cl, true))}
			</React.Fragment>
		));
	};

	return (
		<DropDownMenu
			align="right"
			className={classes.b()}
			onClose={() => {
				setStage(MenuStages.Entry);
			}}
			renderTrigger={(isExpanded, setExpanded) => (
				<Tooltip title={t('default.editContact')}>
					<ClickableItem
						borderRadius
						block
						icon="edit"
						className={classes.elem('trigger').b()}
						disabled={disabled}
						onClick={e => {
							setExpanded(e);
							setEmail(mainRecipient?.email ?? '');
						}}
					/>
				</Tooltip>
			)}
		>
			{close => (
				<>
					{stage === MenuStages.Entry ? (
						<>
							<ClickableItem
								block
								icon="email"
								title={changeEmailTitle}
								onClick={() => setStage(MenuStages.Email)}
								disabled={disabled}
							/>
							<ClickableItem
								block
								icon="user"
								title={changeContactTitle}
								onClick={() => setStage(MenuStages.Contact)}
								disabled={disabled}
							/>
							<ClickableItem
								block
								icon="home"
								title={changeClientTitle}
								onClick={() => setStage(MenuStages.Client)}
								disabled={disabled}
							/>
						</>
					) : stage === MenuStages.Contact ? (
						<>
							<Block space="pbl ptl pll prl">
								{client ? (
									<ButtonSelect
										size="sm"
										value={buttonSelect}
										onChange={v => {
											setButtonSelect(v);
											setSearchString('');
										}}
										options={[
											{ value: 'client', title: client?.name },
											{ value: 'all', title: t('default.all') }
										]}
										disabled={disabled}
									/>
								) : null}

								<Block space={client ? 'ptl pbm' : 'pbm'}>
									<Input
										icon="search"
										value={searchString}
										onChange={e => setSearchString(e.target.value)}
										placeholder={
											sameClient
												? t('ticket.searchForContactsAt', { clientName: client?.name })
												: t('ticket.searchForContacts')
										}
									/>
								</Block>
							</Block>

							{loading ? (
								<Block space="ptm">
									<Row align="center">
										<Loader size="sm" />
									</Row>
								</Block>
							) : (
								<div className={classes.elem('result').b()}>
									{contacts.map(contact => (
										<Flex
											key={contact.id}
											className={classes.elem('row').b()}
											data-id={contact.id}
											direction="row"
											justifyContent="space-between"
											alignItems="center"
											onClick={() => {
												onChange('contactInfo', {
													client: contact.client,
													contact,
													email: contact.email ?? ''
												});
												setSearchString('');
												close();
											}}
										>
											<Flex
												className={classes.elem('userInfo').b()}
												direction="column"
												flex={1}
												space="mll"
											>
												<>
													<Text ellipsis className={classes.elem('row').elem('title').b()}>
														{contact.name}
													</Text>
													<Text ellipsis size="sm" color="grey-11">
														{contact.title}
													</Text>
												</>
												{contact.client ? (
													<Flex
														space="prs"
														className={classes.elem('row').elem('subtitle').b()}
													>
														<Text size="sm" color="grey-11" ellipsis>
															{contact.client.name} •{' '}
															{getJourneyStep(contact.journeyStep)?.name}
														</Text>
													</Flex>
												) : null}
											</Flex>
											<Flex
												space="prm"
												alignItems="center"
												justifyContent="space-between"
												gap="u6"
											>
												<Tooltip disabled={!contact.email} title={contact.email ?? 'null'}>
													<Icon color={contact.email ? 'black' : 'grey-7'} name="envelope" />
												</Tooltip>
												<Tooltip
													position="left"
													disabled={!contact.cellPhone && !contact.phone}
													title={`${contact.phone ? 'Phone: ' + contact.phone + '\n' : ''}${
														contact.cellPhone ? 'Mobile: ' + contact.cellPhone : ''
													}`}
												>
													<Icon
														color={contact.cellPhone || contact.phone ? 'black' : 'grey-7'}
														name="phone"
													/>
												</Tooltip>
											</Flex>
										</Flex>
									))}
									{contacts.length === 0 ? (
										<>
											<Text space="mtl mbxl" align="center" italic color="grey-11">
												{t('default.noContacts')}
											</Text>
											<hr />
										</>
									) : null}
									<Row align="center">
										<Block space="mbm mtm">
											<Link
												onClick={() => {
													// eslint-disable-next-line promise/catch-or-return
													Tools.$upModal
														.open(
															'editContact',
															'client' === buttonSelect ? { clientId: client?.id } : {}
														)
														.then((contact: Contact) => {
															setSearchString('');
															onChange('contactInfo', {
																client: contact.client,
																contact
															});
														});
												}}
											>
												<Icon name="user-plus" space="prm" />
												{t('default.addContact')}
											</Link>
										</Block>
									</Row>
								</div>
							)}
						</>
					) : stage === MenuStages.Email ? (
						<Block space="ptl pbl pll prl">
							<Label>{t('ticket.enterNewEmail')}</Label>
							<Flex flex={1} gap="u2">
								<Input
									autofocus
									className={classes.elem('email').b()}
									type="email"
									value={email}
									onChange={e => {
										setEmail(e.target.value);
									}}
								/>

								<PrimaryButton
									disabled={!validEmail}
									submit
									onClick={() => {
										onChange('contactInfo', {
											contact: null,
											client: contact ? null : client,
											email
										});
										setButtonSelect('all');
										close();
									}}
								>
									<Icon name="check" />
								</PrimaryButton>
							</Flex>
						</Block>
					) : (
						<>
							<Block space="pbl ptl pll prl">
								<Block space={'pbm'}>
									<Input
										icon="search"
										value={searchString}
										onChange={e => setSearchString(e.target.value)}
										placeholder={t('select.typeToSearch')}
									/>
								</Block>
							</Block>

							{loading ? (
								<Block space="ptm">
									<Row align="center">
										<Loader size="sm" />
									</Row>
								</Block>
							) : (
								<div className={classes.elem('result').b()}>
									{clientOptions(close)}
									{clients.length === 0 ? (
										<>
											<Text space="mtl mbxl" align="center" italic color="grey-11">
												{t('default.noClients')}
											</Text>
											<hr />
										</>
									) : null}
								</div>
							)}
						</>
					)}
				</>
			)}
		</DropDownMenu>
	);
};

export default ChangeContact;
