import React, { useState } from 'react';
import { Icon, Tooltip, AssistChip } from '@upsales/components';
import BemClass from '@upsales/components/Utils/bemClass';
import { forecastingTracker } from 'Helpers/Tracker';
import Order from 'App/resources/Model/Order';

type Props = {
	className?: string;
	title: string;
	chipType: 'success' | 'info' | 'danger' | 'alert' | undefined;
	tooltip: string;
	tooltipPosition?: 'left' | 'right' | 'top' | 'bottom' | undefined;
	riskName: keyof Order['risks'];
	riskOccured: Date;
};

const trackHover = (chipType: Props['chipType'], riskName: Props['riskName'], riskOccured: Props['riskOccured']) =>
	forecastingTracker.track(forecastingTracker.events.RISK_CHIP_HOVERED, { chipType, riskName, riskOccured });

const RiskChip = ({ className, title, chipType, tooltip, tooltipPosition, riskName, riskOccured }: Props) => {
	const classes = new BemClass('RiskChip', className);

	const [delayHandler, setDelayHandler] = useState<ReturnType<typeof setTimeout> | null>(null);

	const onMouseEnter = () => {
		setDelayHandler(setTimeout(() => trackHover(chipType, riskName, riskOccured), 500));
	};

	const onMouseLeave = () => {
		if (delayHandler) {
			clearTimeout(delayHandler);
		}
	};

	return (
		<div className={classes.b()} onMouseEnter={onMouseEnter} onMouseLeave={onMouseLeave}>
			<Tooltip title={tooltip} position={tooltipPosition}>
				<AssistChip
					type={chipType}
					title={
						<>
							{title}
							<Icon name="question-circle" space="mls" />
						</>
					}
				/>
			</Tooltip>
		</div>
	);
};

export default RiskChip;
