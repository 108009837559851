import Resource from './Resource';
import parseSocialEvent from './parsers/socialEvent';
import mapSocialEvent from './mappers/socialEvent';
import { STYLE } from 'Store/reducers/SystemNotificationReducer';

export const ATTENDING = { status: 'attending', field: 'attending_date' };
export const DECLINED = { status: 'declined', field: 'declined_date' };
export const CHECKIN = { status: 'attended', field: 'checkin_date' };
export const INVITED = { status: 'invited', field: 'invite_date' };
export const ADDED = { status: 'added', field: 'invite_date' };
export const WAITING_LIST = { status: 'waitingList', field: 'waiting_list_date' };
export const ATTENDING_ON_DEMAND = { status: 'attendingOnDemand', field: 'attending_on_demand_date' };
export const ATTENDED_ON_DEMAND = { status: 'attendedOnDemand', field: 'attended_on_demand_date' };

export const STATUS = {
	ATTENDING,
	DECLINED,
	CHECKIN,
	INVITED,
	ADDED,
	WAITING_LIST,
	ATTENDING_ON_DEMAND,
	ATTENDED_ON_DEMAND
};

class SocialEventContacts extends Resource {
	constructor(eventId) {
		super(`socialEvents/${eventId}/contacts`);

		this.parse = parseSocialEvent;
		this.map = mapSocialEvent;

		this._eventId = eventId;

		this.notifications = {
			importContact: ({ data }) => {
				if (data.added) {
					return {
						title: 'socialEvent.contactAdded',
						body: 'socialEvent.contactWasAddedToEvent',
						style: STYLE.SUCCESS,
						icon: 'check'
					};
				} else {
					return {
						title: 'socialEvent.contactNotAdded',
						body: 'socialEvent.contactAlreadyInEvent',
						style: STYLE.INFO
					};
				}
			}
		};
	}

	eventId(id) {
		this._eventId = id;

		return new SocialEventContacts(id);
	}

	_getUrl(path) {
		if (!this._eventId) {
			throw new Error('missing eventId run .eventId() before your method');
		}
		return super._getUrl(path);
	}

	new() {
		return {
			inviteMailError: null,
			name: '',
			email: null,
			regDate: new Date(),
			inviteDate: null,
			attendingDate: null,
			declinedDate: null,
			checkinDate: null,
			attendingOnDemandDate: null,
			attendedOnDemandDate: null,
			client: null,
			status: 'added'
		};
	}

	importContacts(segmentId, status) {
		const data = { segmentId, status };
		return this._postRequest('addSegment', data, { methodName: 'importContacts' }).then(res => {
			return res.data;
		});
	}

	importContact(contactId, status) {
		const data = { contactId, status };
		return this._postRequest('', data, { methodName: 'importContact' }).then(res => {
			return res.data;
		});
	}

	excludeContact(contactId) {
		return this._deleteRequest(contactId, { methodName: 'excludeContact' }).then(res => res.data);
	}

	setContactStatus(contact, status) {
		return this._putRequest(contact.id, { status }, { methodName: 'setContactStatus' }).then(res => {
			return res.data;
		});
	}
}

const resource = new SocialEventContacts();

window.Tools.SocialEventContacts = resource;

export default resource;
