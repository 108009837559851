import React from 'react';
import PropTypes from 'prop-types';
import bemClass from '@upsales/components/Utils/bemClass';
import { CardContent, CardHeader, Table, Button, Text, TableRow, TableColumn, Loader, Icon } from '@upsales/components';
import './MailEditorReviewAttachments.scss';
import translate from 'Components/Helpers/translate';
import MailEditorReviewFileUpload from './MailEditorReviewFileUpload';
import fileSize from 'App/babel/helpers/fileSize';
import MailEditorReviewSection from 'Components/MailEditorReviewSection';

class MailEditorReviewAttachments extends React.PureComponent {
	constructor(p) {
		super(p);

		this.state = {
			added: []
		};

		this.lang = {
			attachments: translate('groupMail.attachments'),
			addAttachment: translate('groupMail.addAttachment'),
			recipientList: translate('groupMail.recipientList'),
			addAttachmentInfo: translate('groupMail.addAttachmentInfo'),
			wrongAttachmentTitle: translate('groupMail.attachmentErrorTitle'),
			wrongAttachmentBody: translate('groupMail.attachmentErrorBody')
		};

		this.onDrop = async files => {
			// Start upload
			await this.props.uploadFiles(files);
			this.setState({ added: [...this.state.added, ...files.map(f => f.name)] });
		};

		this.onDropRejected = async () => {
			Tools.NotificationService.addNotification({
				icon: 'times',
				style: Tools.NotificationService.style.ERROR,
				title: this.lang.wrongAttachmentTitle,
				body: `${this.lang.wrongAttachmentBody}image, .pdf, .doc, .docx, .xls, .xlsx, .csv`
			});
		};
	}

	render() {
		const { onRemoveAttachment, disabled, attachments } = this.props;
		const classes = new bemClass('MailEditorReviewAttachments');
		return (
			<MailEditorReviewSection className={classes.b()} slice={attachments?.length ? null : 'bright-blue'}>
				<MailEditorReviewFileUpload onDrop={this.onDrop} onDropRejected={this.onDropRejected}>
					{open => (
						<div>
							<CardHeader title={this.lang.attachments}>
								<Button gradient={true} onClick={open} disabled={disabled}>
									{this.lang.addAttachment}
								</Button>
							</CardHeader>
							{attachments?.length ? (
								<Table>
									{attachments.map(a => (
										<TableRow key={a.filename + a.value}>
											<TableColumn style={{ width: '15px' }}>
												{a.pending ? (
													<Loader size="xs" />
												) : this.state.added.indexOf(a.filename) !== -1 ? (
													<div className={classes.elem('new-ball').b()} />
												) : null}
											</TableColumn>
											<TableColumn title={a.filename} color={a.pending ? 'grey-10' : 'black'} />
											<TableColumn title={fileSize(a.size)} color="grey-10" />
											<TableColumn align="right">
												{a.pending ? null : (
													<Button
														type="link"
														color="grey"
														hoverColor="red"
														onClick={() => onRemoveAttachment(a)}
														disabled={disabled}
													>
														<Icon name="times" />
													</Button>
												)}
											</TableColumn>
										</TableRow>
									))}
								</Table>
							) : (
								<CardContent>
									<Text color="grey-10" size="lg">
										{this.lang.addAttachmentInfo}
									</Text>
									<img src="img/attachments.svg" />
								</CardContent>
							)}
						</div>
					)}
				</MailEditorReviewFileUpload>
			</MailEditorReviewSection>
		);
	}
}

MailEditorReviewAttachments.propTypes = {
	onRemoveAttachment: PropTypes.func.isRequired,
	disabled: PropTypes.bool,
	attachments: PropTypes.array
};

export default MailEditorReviewAttachments;
