import { IndexConflictDecision, IndexConflictDecisionResource } from 'App/resources/Model/IndexIncreaseSettings';
import ResourceTyped from './ResourceTyped';

class IndexConflictDecisions extends ResourceTyped<IndexConflictDecisionResource> {
	constructor() {
		super('indexConflictDecisions', null, { notificationTitle: 'admin.subscriptionIndexing' });
	}

	saveConflictDecisions(conflictDecisions: IndexConflictDecision[]) {
		return this._postRequest('saveAll', conflictDecisions);
	}
}

const resource = new IndexConflictDecisions();

export default resource;
