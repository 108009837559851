import { findIndex } from 'lodash';

export const RESET_STATE = '[Activities] RESET_STATE';
export const ACTIVITIES_FETCHING = '[Activities] ACTIVITIES_FETCHING';
export const ACTIVITIES_FETCH_SUCCESS = '[Activities] ACTIVITIES_FETCH_SUCCESS';
export const ACTIVITIES_FETCH_ERROR = '[Activities] ACTIVITIES_FETCH_ERROR';
export const ACTIVITY_ADDED = '[Activities] ACTIVITY_ADDED';
export const ACTIVITY_UPDATED = '[Activities] ACTIVITY_UPDATED';
export const ACTIVITY_DELETED = '[Activities] ACTIVITY_DELETED';

export const initialState = {
	activities: [],
	activitiesError: [],
	activitiesFetching: false,
	activitiesCount: 0
};

const ACTION_HANDLERS = {
	[RESET_STATE]: () => ({ ...initialState }),
	[ACTIVITIES_FETCHING]: (state, { value }) => ({ ...state, activitiesFetching: value ? 'loadMore' : true }),
	[ACTIVITIES_FETCH_SUCCESS]: (state, { value }) => ({
		...state,
		activities: value.loadMore ? [...state.activities, ...value.activities] : value.activities,
		activitiesCount: value.count,
		activitiesFetching: false
	}),
	[ACTIVITIES_FETCH_ERROR]: (state, action) => ({
		...state,
		activitiesError: action.value,
		activitiesFetching: false
	}),
	[ACTIVITY_ADDED]: (state, action) => ({ ...state, activities: [action.value, ...state.activities] }),
	[ACTIVITY_UPDATED]: (state, action) => {
		const indexToUpdate = findIndex(state.activities, { id: action.value.id });
		return {
			...state,
			activities: [
				...state.activities.slice(0, indexToUpdate),
				action.value,
				...state.activities.slice(indexToUpdate + 1, state.activities.length)
			]
		};
	},
	[ACTIVITY_DELETED]: (state, action) => {
		const indexToDelete = findIndex(state.activities, { id: action.value.id });
		return {
			...state,
			activities: [
				...state.activities.slice(0, indexToDelete),
				...state.activities.slice(indexToDelete + 1, state.activities.length)
			]
		};
	}
};

export default (state = initialState, action) => {
	const handler = ACTION_HANDLERS[action.type];
	return handler ? handler(state, action) : state;
};
