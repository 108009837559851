import ClientDeactivationStats from './Model/ClientDeactivationStats';
import ResourceTyped from 'Resources/ResourceTyped';

class ClientDeactivationStatsResource extends ResourceTyped<ClientDeactivationStats> {
	constructor() {
		super('accounts/deactivationStats');
	}
}

const resource = new ClientDeactivationStatsResource();

export default resource;
