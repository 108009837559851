import ResourceTyped from './ResourceTyped';
import ProjectPlanTemplate from 'App/resources/Model/ProjectPlanTemplate';

class ProjectPlanTemplateResource extends ResourceTyped<
	ProjectPlanTemplate,
	ProjectPlanTemplate,
	DeepPartial<ProjectPlanTemplate>
> {
	constructor() {
		super('projectPlanTemplates');
		this.eventName = 'projectPlanTemplate';
		this.notifications = {
			save: () => ({
				title: 'default.saved',
				body: 'saved.projectPlanTemplate',
				style: 'success',
				icon: 'check',
				type: 'body'
			}),
			saveError: () => ({
				title: 'default.error',
				body: 'saveError.projectPlanTemplate',
				style: 'error',
				icon: 'times',
				type: 'body'
			}),
			delete: () => ({
				title: 'default.deleted',
				body: 'deleted.projectPlanTemplate',
				style: 'success',
				icon: 'check',
				type: 'body'
			}),
			deleteError: () => ({
				title: 'default.error',
				body: 'deleteError.projectPlanTemplate',
				style: 'error',
				icon: 'times',
				type: 'body'
			})
		};
	}
}

const resource = new ProjectPlanTemplateResource();
export default resource;
