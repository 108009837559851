import React from 'react';
import PropTypes from 'prop-types';

class CompoundingTimer extends React.Component {
	static propTypes = {
		startTime: PropTypes.number
	};
	constructor(p) {
		super(p);
		this.state = {
			displayTime: '00:00'
		};
	}
	componentDidMount() {
		this.interval = setInterval(() => {
			if (this.props.startTime) {
				const timeDiff = (new Date().getTime() - this.props.startTime) / 1000;
				const seconds = Math.floor(timeDiff % 60);
				const secondsString = seconds < 10 ? `0${seconds}` : seconds;
				const minutes = Math.floor(timeDiff / 60);
				const minutesString = minutes < 10 ? `0${minutes}` : minutes;
				this.setState({ displayTime: `${minutesString}:${secondsString}` });
			}
		}, 1000);
	}
	render() {
		return this.state.displayTime;
	}
	componentWillUnmount() {
		clearInterval(this.interval);
	}
}

export default CompoundingTimer;
