const parseSocialEvent = socialEvent => {
	// SocialEvent dates will be utc when fetched from the api, we convert them to the user timezone here

	// set timezone here until we can change it (it causes diff between initialEvent otherwise)
	socialEvent.timezone = Tools.userTimezone;

	// Set endate to same as date if missing
	if (!socialEvent.endDate) {
		socialEvent.endDate = socialEvent.date;
	}

	// Date/Time
	const date = moment(socialEvent.date);
	if (socialEvent.date && date.isValid()) {
		socialEvent.date = date.toDate();
		socialEvent.time = date.format('LT');
	}

	// EndDate/EndTime
	const endDate = moment(socialEvent.endDate);
	if (socialEvent.endDate && endDate.isValid()) {
		socialEvent.endDate = endDate.toDate();
		socialEvent.endTime = endDate.format('LT');
	}

	// Cancel date
	const cancelDate = moment(socialEvent.cancelDate);
	if (socialEvent.cancelDate && cancelDate.isValid()) {
		socialEvent.cancelDate = endDate.toDate();
		socialEvent.canceled = true;
	}

	socialEvent.description = socialEvent.description || '';

	return socialEvent;
};

export default parseSocialEvent;
