import { Attr, Type } from 'App/babel/attributes/Attribute';
import _ from 'lodash';
import moment from 'moment';

const mappers = {
	Date: date => {
		if (date instanceof Date) {
			return moment(date).format('YYYY-MM-DD');
		}
		return null;
	},
	DateTime: date => {
		if (date instanceof Date) {
			return moment
				.tz(date, window.userTimezone || 'europe/stockholm')
				.utc()
				.format();
		}
		return null;
	},
	DateStringOrDateTime: (value): string | null => {
		if (typeof value === 'string' && moment(value).isValid()) {
			return moment(value).format('YYYY-MM-DD');
		}

		return mappers.DateTime(value);
	},
	Number: value => {
		if (!isNaN(parseInt(value as string))) {
			return parseInt(value as string);
		}
		return null;
	},
	String: value => {
		if (typeof value === 'string') {
			return value;
		}
		return null;
	},
	Time: time => {
		try {
			if (time && moment('1991-01-01 ' + time).isValid()) {
				return time;
			}
		} catch (e) {
			return null;
		}
		return null;
	},
	Boolean: value => {
		if (value === 1 || value === '1' || value === true || value === 'true') {
			return true;
		}
		if (value === 0 || value === '0' || value === false || value === 'false') {
			return false;
		}
		return null;
	},
	Object: value => {
		if (typeof value !== 'object' || value instanceof Array) {
			return null;
		}
		return value;
	},
	StringifiedObject: value => {
		if (typeof value !== 'object' || value instanceof Array) {
			return null;
		}
		try {
			return JSON.stringify(value);
		} catch (e) {
			return null;
		}
	},
	Array: value => {
		if (typeof value !== 'object' || !(value instanceof Array)) {
			return null;
		}
		return value;
	},
	Float: mapperToBeDefined => mapperToBeDefined,
	Email: mapperToBeDefined => mapperToBeDefined
} satisfies { [key in Type]: (val: unknown) => unknown };

export const mapDate = function (val: unknown) {
	const isString = typeof val === 'string';
	const isDate = val instanceof Date;
	const isMoment = val instanceof moment;
	if (isDate || isMoment || (isString && (val as string).length > 15)) {
		var momentDate = moment(val as string, moment.ISO_8601, true);
		if (momentDate.isValid()) {
			var dateString = momentDate.format('YYYY-MM-DD HH:mm:ss');
			val = moment.tz(dateString, Tools ? Tools.userTimezone : 'Europe/Stockholm').format();
		}
	}
	return val;
};

// Traverse object and apply function to each value
function mapObject<T = unknown>(obj: T, fn: (val: T) => T): T {
	if (typeof obj !== 'object' || obj === null) {
		return obj;
	}

	return Object.fromEntries(
		Object.entries(obj)
			.map(([key, value]) => {
				if (value instanceof Blob) {
					return [key, value];
				}
				if (Array.isArray(value)) {
					return [key, value.map(v => mapObject(v, fn))];
				}
				if (
					typeof value === 'object' &&
					value !== null &&
					!(value instanceof Date) &&
					!(value instanceof moment)
				) {
					return [key, mapObject(value, fn)];
				}
				return [key, fn(value)];
			})
			.filter(([key, value]) => value !== undefined)
	);
}

// Not 100% sure that this functions exactly as the old function so we only use it in select cases for now
export const mapDatesNew = function <T = unknown>(obj: T) {
	return _.isObject(obj) ? mapObject(obj, mapDate) : obj;
};

// Note: this function will delete any files in the object
export const mapDates = function <T = unknown>(obj: T) {
	return _.isObject(obj)
		? JSON.parse(JSON.stringify(obj), function (key, val) {
				return mapDate(val);
		  })
		: obj;
};

type UnknownObject = { [key: string]: unknown };

const genericMapper = <MappedData extends UnknownObject, UnMappedData extends UnknownObject>(
	data: UnMappedData | UnMappedData[],
	attributes: { [key: string]: Attr }
) => {
	const mapData = (data: UnMappedData) =>
		Object.keys(data).reduce((result, key) => {
			// only add value if attribute key exists
			if (attributes[key]) {
				(result as UnknownObject)[key] = mappers[attributes[key].type](data[key]);
			}
			return result;
		}, {} as MappedData);

	if (Array.isArray(data)) {
		// Right now the only use case is quotas so we cap it at 12 in case someone sends a very large array
		if (data.length > 12) {
			return {};
		}
		return data.map(data => mapData(data));
	}

	return mapData(data);
};

export default genericMapper;
