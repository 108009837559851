import Attribute from './Attribute';

const attributes = {
	id: new Attribute({
		title: 'default.id',
		field: 'id',
		type: Attribute.types.Number
	}),
	listPrice: new Attribute({
		title: 'default.id',
		field: 'listPrice',
		type: Attribute.types.Number
	}),
	price: new Attribute({
		title: 'default.id',
		field: 'price',
		type: Attribute.types.Number
	}),
	quantity: new Attribute({
		title: 'default.description',
		field: 'quantity',
		type: Attribute.types.Number
	}),
	product: new Attribute({
		title: 'default.description',
		field: 'product',
		type: Attribute.types.Number
	})
};

export default attributes;
