import {
	RESET,
	FETCH_APPOINTMENTS_FULFILLED,
	FETCH_APPOINTMENTS_REJECTED,
	SET_CONTACTS
} from '../actions/ActivityOutcomesActions';

export const initialState = {
	appointments: [],
	topContacts: []
};

const ACTION_HANDLERS = {
	[RESET]: () => ({ ...initialState }),
	[FETCH_APPOINTMENTS_FULFILLED]: (state, action) => ({ ...state, appointments: action.payload }),
	[FETCH_APPOINTMENTS_REJECTED]: state => ({ ...state, appointments: [] }),
	[SET_CONTACTS]: (state, action) => ({ ...state, topContacts: action.payload })
};

export default (state = initialState, action) => {
	const handler = ACTION_HANDLERS[action.type];

	return handler ? handler(state, action) : state;
};
