import {
	RESET,
	UPDATE,
	FETCH_TABLE_REJECTED,
	FETCH_TABLE_FULFILLED,
	FETCH_TABLE_PENDING,
	SET_EMBEDDABLE_SCRIPT,
	SET_SORTING,
	TABLE_TABS,
	SET_LOAD_ERROR,
	TABS,
	SELECT_TAB,
	SET_TABLE_VIEW,
	SET_OFFSET
} from '../actions/FormOverviewActions';

export const initialState = {
	selectedTab: TABS.DASHBOARD,
	tableView: TABLE_TABS.FORM_SUBMITS,
	accountProfile: {},
	currentSorting: {},
	embeddableScript: '',
	form: {},
	graphCategories: [],
	limit: 50,
	noData: true,
	offset: 0,
	optIns: [],
	params: {},
	previewHtml: '',
	reCaptcha: null,
	[TABLE_TABS.FORM_SUBMITS]: {
		isLoading: true,
		sorting: {
			attrName: 'processedDate',
			ascending: false
		}
	},
	[TABLE_TABS.VISITS]: {
		isLoading: true,
		sorting: {
			attrName: 'startDate',
			ascending: false
		}
	},
	[TABLE_TABS.LEAD_SOURCE]: {
		isLoading: false
	},
	graphConfig: {}
};

const ACTION_HANDLERS = {
	[RESET]: () => ({ ...initialState }),
	[UPDATE]: (state, action) => ({ ...state, ...action.payload }),
	[SET_LOAD_ERROR]: (state, { loadError }) => ({ ...state, loadError }),
	[SET_EMBEDDABLE_SCRIPT]: (state, action) => ({ ...state, embeddableScript: action.payload.script }),
	[FETCH_TABLE_PENDING]: (state, action) => ({ ...state, [action.name]: { ...state[action.name], isLoading: true } }),
	[FETCH_TABLE_FULFILLED]: (state, action) => ({
		...state,
		[action.name]: { ...state[action.name], ...action.payload, isLoading: false }
	}),
	[FETCH_TABLE_REJECTED]: (state, action) => ({ ...state, [action.name]: { isLoading: false } }),
	[SET_SORTING]: (state, action) => ({ ...state, [action.name]: { ...state[action.name], sorting: action.payload } }),
	[SELECT_TAB]: (state, { selectedTab }) => ({ ...state, selectedTab }),
	[SET_TABLE_VIEW]: (state, { tableView }) => ({ ...state, tableView }),
	[SET_OFFSET]: (state, action) => ({ ...state, [action.name]: { ...state[action.name], offset: action.offset } })
};

export default (state = initialState, action) => {
	const handler = ACTION_HANDLERS[action.type];

	return handler ? handler(state, action) : state;
};
