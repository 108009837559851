import React from 'react';
import emailRegex from 'Helpers/emailRegex';
import { useTranslation } from 'Components/Helpers/translate';
import { SelectTokens } from '@upsales/components';
import { type TokenItem } from '@upsales/components/Utils/selectHelpers';

type Props = {
	value: TokenItem[];
	onChange: (values: TokenItem[]) => void;
};

const CcSelect = ({ value, onChange }: Props) => {
	const { t } = useTranslation();
	return (
		<SelectTokens
			value={value}
			onChange={onChange}
			placeholder={t('default.enterEmails')}
			validator={value => emailRegex.test(value)}
			formatMessage={(message, valid) =>
				message
					? valid
						? t('default.hitEnterToAddValue', { value: message })
						: t('default.pleaseEnterValidEmail')
					: message
			}
		/>
	);
};

export default CcSelect;
