import DesiredOutcome from 'Resources/DesiredOutcome';
import DesiredOutcomeUser from 'Resources/DesiredOutcomeUser';
import DesiredOutcomeFeedback from 'Resources/DesiredOutcomeFeedback';
import getAngularModule from 'App/babel/angularHelpers/getAngularModule';
import Analytics from 'App/services/Analytics';

const RESET = '[DesiredOutcome] RESET';
const SET_CLOSE_MODAL = '[DesiredOutcome] SET_CLOSE_MODAL';
const DATA_LOADED = '[DesiredOutcome] DATA_LOADED';
const SELECT_ROLE = '[DesiredOutcome] SELECT_ROLE';
const TOGGLE_DESIRED_OUTCOME = '[DesiredOutcome] TOGGLE_DESIRED_OUTCOME';
const TOGGLE_SAVING = '[DesiredOutcome] TOGGLE_SAVING';
const TOGGLE_LOADING = '[DesiredOutcome] TOGGLE_LOADING';
const TOGGLE_FEEDBACK_VISIBLE = '[DesiredOutcome] TOGGLE_FEEDBACK_VISIBLE';

/*********** Actions **********/

export const init = (showFeedback, closeModal) => async dispatch => {
	dispatch({ type: RESET });
	dispatch({ type: SET_CLOSE_MODAL, closeModal });

	const promises = [DesiredOutcome.find({}).then(res => res.data)];

	if (showFeedback) {
		dispatch({ type: TOGGLE_FEEDBACK_VISIBLE });
		const AppService = getAngularModule('AppService');
		const userId = AppService.getAccountSelf().id;
		// roleId = 0 is the case when the user did not think any of the suggestion matched their current role
		promises.push(DesiredOutcomeUser.find({ userId }).then(res => res.data));
	}

	try {
		const [desiredOutcomes, desiredOutcomesUsers = []] = await Promise.all(promises);
		const desiredOutcomesUser = desiredOutcomesUsers.length ? desiredOutcomesUsers[0] : null;

		dispatch({ type: DATA_LOADED, desiredOutcomes, desiredOutcomesUser });
		dispatch({ type: TOGGLE_LOADING });
	} catch (error) {
		console.log(error);
		// Might aswell quit if we cant load the data
		dispatch(closeModal());
	}
};

export const selectRole = selectedRole => ({ type: SELECT_ROLE, selectedRole });

export const closeModal = () => (dispatch, getState) => {
	const closeModal = getState().DesiredOutcome.closeModal;
	closeModal();
};

export const updateUserParamAndCloseModal = () => dispatch => {
	const $rootScope = getAngularModule('$rootScope');

	// Update the userparam so that we dont get here if the user navigates to tmpStart again
	$rootScope.$broadcast('userParam.updated', 'lastDesiredOutcomeDate', moment().format('YYYY-MM-DD'));

	dispatch(closeModal());
};

export const saveFeedback = answers => async dispatch => {
	const AppService = getAngularModule('AppService');

	const userId = AppService.getAccountSelf().id;
	const promises = answers.map(({ desiredOutcomeId, value, notes }) =>
		DesiredOutcomeFeedback.save({ desiredOutcomeId, userId, value, notes })
	);

	try {
		dispatch({ type: TOGGLE_SAVING });
		await Promise.all(promises);
		dispatch({ type: TOGGLE_SAVING });
		dispatch(updateUserParamAndCloseModal());
	} catch (error) {
		console.log(error);
		dispatch({ type: TOGGLE_SAVING });
	}
};

export const saveFeedbackAndSwapRole = answers => async dispatch => {
	// Why wait for save? ;P
	dispatch({ type: TOGGLE_FEEDBACK_VISIBLE });

	const AppService = getAngularModule('AppService');
	const userId = AppService.getAccountSelf().id;

	const promises = answers.map(({ value, notes }) => DesiredOutcomeFeedback.save({ userId, value, notes }));

	try {
		await Promise.all(promises);
	} catch (error) {
		console.log(error);
	}
};

export const toggleDesiredOutcome = desiredOutcomeId => ({ type: TOGGLE_DESIRED_OUTCOME, desiredOutcomeId });

export const save = () => async (dispatch, getState) => {
	const { selectedRole, selectedDesiredOutcomes } = getState().DesiredOutcome;
	const AppService = getAngularModule('AppService');
	const userId = AppService.getAccountSelf().id;
	const roleId = selectedRole.id;

	try {
		dispatch({ type: TOGGLE_SAVING });
		await DesiredOutcomeUser.save({ userId, roleId, desiredOutcomes: selectedDesiredOutcomes });
		dispatch({ type: TOGGLE_SAVING });
		Analytics.identify(userId, { role: selectedRole.name });
		dispatch(updateUserParamAndCloseModal());
	} catch (error) {
		console.log(error);
		dispatch({ type: TOGGLE_SAVING });
	}
};

export const saveNoMatch = customTitle => async dispatch => {
	const AppService = getAngularModule('AppService');
	const userId = AppService.getAccountSelf().id;

	try {
		dispatch({ type: TOGGLE_SAVING });
		await DesiredOutcomeUser.save({ roleId: 0, desiredOutcomes: [], userId, customTitle });
		dispatch({ type: TOGGLE_SAVING });
		dispatch(updateUserParamAndCloseModal());
	} catch (error) {
		console.log(error);
		dispatch({ type: TOGGLE_SAVING });
	}
};

export const initialState = {
	loading: true,
	saving: false,
	desiredOutcomes: [],
	desiredOutcomesUser: null,
	selectedRole: null,
	selectedDesiredOutcomes: [],
	feedbackVisible: false,
	closeModal: () => {}
};

const ACTION_HANDLERS = {
	[RESET]: () => ({ ...initialState }),
	[SET_CLOSE_MODAL]: (state, { closeModal }) => ({ ...state, closeModal }),
	[DATA_LOADED]: (state, { desiredOutcomes, desiredOutcomesUser }) => ({
		...state,
		desiredOutcomes,
		desiredOutcomesUser
	}),
	[SELECT_ROLE]: (state, { selectedRole }) => ({ ...state, selectedRole, selectedDesiredOutcomes: [] }),
	[TOGGLE_SAVING]: state => ({ ...state, saving: !state.saving }),
	[TOGGLE_LOADING]: state => ({ ...state, loading: !state.loading }),
	[TOGGLE_FEEDBACK_VISIBLE]: state => ({ ...state, feedbackVisible: !state.feedbackVisible }),
	[TOGGLE_DESIRED_OUTCOME]: (state, { desiredOutcomeId }) => {
		const isSelected = state.selectedDesiredOutcomes.indexOf(desiredOutcomeId) > -1;
		const selectedDesiredOutcomes = isSelected
			? state.selectedDesiredOutcomes.filter(
					currentlySelectedDesiredOutcomeId => currentlySelectedDesiredOutcomeId !== desiredOutcomeId
			  )
			: state.selectedDesiredOutcomes.concat([desiredOutcomeId]);
		return { ...state, selectedDesiredOutcomes };
	}
};

export default (state = initialState, action) => {
	const handler = ACTION_HANDLERS[action.type];
	return handler ? handler(state, action) : state;
};
