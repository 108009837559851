import Resource from 'Resources/Resource';
import { AppointmentEvent } from './Model/Event';
import { BasicUserWithPermissions } from './Model/User';

type AppointmentEventRaw = Collect<
	Required<Pick<AppointmentEvent, 'date' | 'data'>> & {
		id?: number;
		user: number;
		type: AppointmentEvent['subType'];
	}
>;

export type AppointmentEventsResponse = {
	data: AppointmentEvent[];
	metadata: { total: number };
};

const parseEvent = (
	rawEvent: AppointmentEventRaw,
	users: BasicUserWithPermissions[],
	appointmentId: number
): AppointmentEvent => {
	const user = users.find(user => user.id === rawEvent.user);
	const event: AppointmentEvent = {
		date: rawEvent.date,
		data: rawEvent.data ?? {},
		user: user,
		subType: rawEvent.type,
		entityId: appointmentId,
		entityType: 'Appointment',
		id: rawEvent.id,
		users: user ? [user] : [],
		appointment: { id: appointmentId }
	};

	return event;
};

class AppointmentEvents extends Resource {
	constructor() {
		super('events');
	}

	getEvents(
		appointmentId: number,
		limit?: number | null,
		offset?: number | null
	): Promise<AppointmentEventsResponse> {
		const customerId = Tools.AppService.getCustomerId();
		const users = Tools.AppService.getUsers();
		return this._getRequest(`${appointmentId}/events`, {
			_url: `${customerId}/appointments`,
			params: { limit, offset }
		}).then(({ data }) => {
			data.data = data.data.map((e: AppointmentEventRaw) => parseEvent(e, users, appointmentId));
			return data;
		});
	}

	createEvent(appointmentId: number, data: AppointmentEvent) {
		const customerId = Tools.AppService.getCustomerId();
		return this._postRequest(`${appointmentId}/events`, data, { _url: `${customerId}/appointments` });
	}
}

const resource = new AppointmentEvents();

export default resource;
