import React from 'react';
import { RCWidgetDataRowShape, RCWidgetData, RCWidgetDataSubRow, HitRateSpecial } from 'Resources/ReportWidget';
import formatWidgetValue, { formatHitRateValue } from './formatWidgetValue';
import T from 'Components/Helpers/translate';
import { openDrawer } from 'Services/Drawer';
import { RCDashboardWidget } from 'Resources/ReportDashboard';
import { renderToString } from 'react-dom/server';
import { Icon, Text } from '@upsales/components';
import _ from 'lodash';
import { renderColumnProps } from './PipelineSpecialWidget/PipelineSpecialWidget';
import DistributionBar from './Components/DistributionBar';
import BarchartWidgetBars from './BarchartWidget/BarchartWidgetBars/BarchartWidgetBars';

export type CellValue = {
	displayValue: any;
	rawValue: any;
};

export const mapTableData = (
	data: RCWidgetData,
	showGoal: boolean,
	subGrouped: boolean,
	specialColumn?: ({ ...props }: renderColumnProps) => JSX.Element | undefined,
	excludedColumns: string[] = []
) => {
	if (!data.datatype || !data.total || !data.language) {
		return null;
	}

	const datatype = data.datatype.progress ?? 'currency';
	const currency = data.currency;
	const isHitRate = data.isHitRate;
	const usesProgress = !subGrouped && data.language.progress;
	const showDistribution = data.useDistribution;

	const getDistributionColumn = (columns: { key: string; label: string; progress: number }[]) => {
		const distribution = columns.map((column, idx: number) => ({
			id: idx,
			label: column.label,
			value: column.progress,
			color: data.colors[column.key]?.color
		}));

		return <DistributionBar distribution={distribution} datatype={datatype} currency={currency} />;
	};

	const getColumnOrder = () => {
		const order: { key: string; label: string; tooltip?: string }[] = [
			{ key: 'grouping', label: data.language.firstGrouping }
		];

		if (usesProgress) {
			order.push({ key: 'progress', label: data.language.progress });
		} else {
			order.push({ key: 'total', label: data.language.total, tooltip: data.language.totalTooltip });
		}

		if (data.total.additionalColumn && !subGrouped) {
			order.push({ key: 'additionalColumn', label: data.language.additionalColumn });
		}
		if (data.total.columnArray) {
			order.push(
				...data.total.columnArray
					.map(col => ({
						key: col.key,
						label: col.label,
						tooltip: data.language[`${col.key}Tooltip`],
						dotColor: showDistribution ? data.colors[col.key]?.color : undefined
					}))
					.filter(col => col.key !== 'total')
			);
		}
		if (showDistribution) {
			order.push({ key: 'distributionColumn', label: T('report.distribution') });
		}
		if (showGoal) {
			order.push({ key: 'goal', label: data.language.goal });
			order.push({ key: 'remaining', label: data.language.remaining });
		}
		return order.filter(col => !excludedColumns.includes(col.key));
	};
	const getRowOrder = () => {
		return data.rows.map(row => ({ key: row.key, label: row.label }));
	};

	const columnOrder = getColumnOrder();
	const rowOrder = getRowOrder();

	const rawRows = _.cloneDeep(data.rows);
	const mappedRows = new Map(
		rawRows.map(row => {
			const datatype = data.datatype['progress'];

			const getValues = (row: RCWidgetDataSubRow): CellValue => {
				const subRowDataType = row.dataType ?? datatype;
				let rawValue, displayValue;
				if (isHitRate) {
					rawValue = (row.progressSpecial as HitRateSpecial)?.data;
					displayValue = formatHitRateValue(subRowDataType, rawValue);
				} else if (row.progressSpecial && specialColumn) {
					rawValue = row.progressSpecial;
					displayValue = specialColumn;
				} else if (row.key === 'barchart') {
					rawValue = undefined;
					displayValue = () => (
						<BarchartWidgetBars
							row={{ ...row, rows: [] }}
							maxValue={data.total.special.maxRowValue}
							minValue={data.total.special.minRowValue}
							data={data}
							value={formatWidgetValue(row.progress, subRowDataType, currency)}
							showGoal={false}
							onlyRightDirection={true}
							calculatedWidth={80}
						/>
					);
				} else {
					rawValue = row.progress;
					displayValue = formatWidgetValue(rawValue, subRowDataType, currency);
				}
				return { rawValue, displayValue };
			};

			const { rawValue, displayValue } = getValues(row);

			const subRowMap = new Map<string, CellValue>(
				row.rows.map(subRow => {
					const { rawValue, displayValue } = getValues(subRow);
					return [subRow.key, { displayValue, rawValue }];
				})
			)
				.set(usesProgress ? 'progress' : 'total', { displayValue, rawValue })
				.set('grouping', { displayValue: row.label, rawValue: row.label });

			if (showDistribution) {
				subRowMap.set('distributionColumn', {
					displayValue: () =>
						getDistributionColumn(
							row.rows.filter(row => columnOrder.find(column => column.key === row.key))
						),
					rawValue: undefined
				});
			}

			if (showGoal) {
				subRowMap.set('goal', {
					displayValue: formatWidgetValue(row.goal, datatype, currency),
					rawValue: row.goal
				});
				subRowMap.set('remaining', {
					displayValue: formatWidgetValue(row.remaining, datatype, currency),
					rawValue: row.remaining
				});
			}

			columnOrder.forEach(column => {
				if (!subRowMap.has(column.key)) {
					subRowMap.set(column.key, {
						displayValue: isHitRate
							? formatHitRateValue(datatype, { numerator: 0, denominator: 0, percent: 0 })
							: formatWidgetValue(0, datatype, currency),
						rawValue: null
					});
				}
			});
			return [row.key, subRowMap];
		})
	);

	const getMappedTotals = () => {
		if (isHitRate) {
			const rawValue = data.total.special;

			const displayValue = formatHitRateValue(data.datatype.progress, data.total.special);
			return new Map<string, CellValue>(
				(data.total.columnArray ?? []).map(column => {
					const rawValue = (column.progressSpecial as HitRateSpecial)?.data ?? {
						numerator: 0,
						denominator: 0,
						percent: 0
					};
					const displayValue = formatHitRateValue(data.datatype.progress, rawValue);
					return [column.key, { rawValue, displayValue }];
				})
			)
				.set('grouping', { displayValue: data.language.total, rawValue: data.language.total })
				.set(usesProgress ? 'progress' : 'total', { displayValue, rawValue });
		} else if (data.total.columnArray) {
			return new Map<string, CellValue>(
				data.total.columnArray.map(column => [
					column.key,
					{
						displayValue: column.progressSpecial
							? specialColumn
							: formatWidgetValue(column.progress, datatype, currency),
						rawValue: column.progressSpecial ?? column.progress
					}
				])
			)
				.set('grouping', { displayValue: data.language.total, rawValue: data.language.total })
				.set(usesProgress ? 'progress' : 'total', {
					displayValue: formatWidgetValue(data.total.total ?? data.total.progress, datatype, currency),
					rawValue: data.total.total ?? data.total.progress
				})
				.set('distributionColumn', {
					displayValue: () => getDistributionColumn(data.total.columnArray ?? []),
					rawValue: undefined
				});
		} else {
			return new Map<string, CellValue>(
				columnOrder.map(column => {
					const totalValue = data.total[column.key as keyof typeof data.total];
					return [
						column.key,
						{ displayValue: formatWidgetValue(totalValue, datatype, currency), rawValue: totalValue }
					];
				})
			).set('grouping', { displayValue: data.language.total, rawValue: data.language.total });
		}
	};

	const mappedTotals = getMappedTotals();
	return {
		columnOrder,
		rowOrder,
		mappedRows,
		mappedTotals,
		colors: data.colors,
		currency: data.currency
	};
};

export const splitRevRecDataIntoSubRows = (data: RCWidgetData) => {
	const newData = _.cloneDeep(data);
	newData.rows.forEach(row => {
		if (row.rows.length > 0) return;

		const totalValue = row.progress;
		const pipelineValue = row.remaining;
		const closedValue = totalValue - pipelineValue;

		const negativeClosed = closedValue < 0;
		const negativePipeline = pipelineValue < 0;

		const progress = {
			key: negativeClosed ? 'negativeProgress' : 'progress',
			label: data.language.progress,
			progress: closedValue,
			sorting: row.progress > 0 ? 2 : -1,
			remaining: 0,
			goal: 0
		};

		const pipeline = {
			key: negativePipeline ? 'negativePipeline' : 'pipeline',
			label: data.language.pipeline,
			progress: pipelineValue,
			sorting: row.goal - row.progress > 0 ? 1 : -2,
			remaining: 0,
			goal: 0
		};
		row.rows.push(progress);

		if (pipeline.progress < 0) {
			row.rows.unshift(pipeline);
		} else {
			row.rows.push(pipeline);
		}
		row.rowMaxProgress = Math.max(progress.progress, pipeline.progress);
		row.rowMinProgress = Math.min(progress.progress, pipeline.progress);
	});
	return newData;
};

export const reverseIfDateGrouping = (rows: RCWidgetDataRowShape[], groupBy: string) => {
	const isDateGrouping = ['week', 'month', 'quarter', 'fiscalQuarter', 'year', 'fiscalYear'].includes(groupBy);
	return isDateGrouping ? rows.reverse() : rows;
};

export const getPercent = (value: number, maxValue: number) => `${Math.max(0, (value / maxValue) * 100)}%`;

export const getMaxValues = (rows: RCWidgetDataRowShape[], showGoal: boolean = false) => {
	const { maxValue, highestGoal, minValue } = rows.reduce(
		(res, row) => {
			const secondGroupingIsOnlyAdditionalColumn =
				row.rows?.length === 1 && row.rows[0].key === 'additionalColumn';
			const is2xGrouping = row.rows?.length > 0 && !secondGroupingIsOnlyAdditionalColumn;
			res.maxValue = Math.max(
				res.maxValue,
				showGoal ? row.goal : 0,
				is2xGrouping && row.rowMaxProgress ? row.rowMaxProgress : row.progress
			);
			res.minValue = Math.min(
				res.minValue,
				showGoal ? row.goal : 0,
				is2xGrouping && row.rowMinProgress ? row.rowMinProgress : row.progress
			);

			res.highestGoal = Math.max(res.highestGoal, showGoal ? row.goal : 0);
			return res;
		},
		{ maxValue: 0, highestGoal: 0, minValue: 0 }
	);

	return { maxValue, highestGoal, minValue };
};

const roundUp = (value: number, NbrOfSignificantDigits: number) => {
	const negative = value < 0 ? true : false;
	const absValue = Math.abs(value);
	const totalDigits = absValue < 1 ? 1 : Math.floor(Math.log10(absValue)) + 1;

	//123320 => 12.3 => 13 => 130000
	const significantDigits = absValue / Math.pow(10, totalDigits - NbrOfSignificantDigits);
	const scaleDigits = Math.ceil(significantDigits);
	const scaleValue = scaleDigits * Math.pow(10, totalDigits - NbrOfSignificantDigits);

	return negative ? scaleValue * -1 : scaleValue;
};

export const getTicks = (scaleMax: number, scaleMin: number, num: number = 3, showExtraTick = false) => {
	const ticks: number[] = [];
	const diff = scaleMax + scaleMin * -1;
	const tickDiff = roundUp(Math.ceil(diff / num), 2);

	for (let i = 0; tickDiff * i < diff; i++) {
		ticks.push(tickDiff * i);
	}

	if (scaleMin < 0) {
		//negative values ? adds one tick, reduces each tick value
		ticks.push(ticks[ticks.length - 1] + tickDiff);

		const nbrOfNegativeTicks = Math.ceil((scaleMin * -1) / tickDiff);

		for (let i = 0; i < ticks.length; i++) {
			ticks[i] -= tickDiff * nbrOfNegativeTicks;
		}
	}

	ticks[0] = scaleMin;

	if (showExtraTick) {
		// If we have an extra tick we don't want the second to last value to be scaleMax
		const latestValue = ticks[ticks.length - 1];
		ticks.push(latestValue + tickDiff);
	} else {
		ticks.push(scaleMax);
	}

	return ticks;
};

export const getTickPercentages = (ticks: number[]) => {
	const diff = Math.abs(ticks[0]) + Math.abs(ticks[ticks.length - 1]);

	const tickPercentages: number[] = [];
	tickPercentages[0] = 0;
	let tickPercent = 0;
	for (let i = 1; i < ticks.length; i++) {
		tickPercent += ((ticks[i] - ticks[i - 1]) / diff) * 100;
		tickPercentages.push(tickPercent);
	}
	return tickPercentages;
};

export const getColumnScaleValues = (maxValue: number, minValue: number = 0) => {
	const max = roundUp(maxValue, 2);
	const min = roundUp(minValue, 2);

	return { max, min };
};

export const getTooltip = (
	progress: number,
	goal: number,
	remaining: number,
	data: RCWidgetData,
	showGoal?: boolean,
	color?: string,
	progressLabel?: string,
	row?: RCWidgetDataSubRow,
	stripedColor?: boolean
): string => {
	const progressValue = formatWidgetValue(progress, data.datatype?.progress, data.currency, false);
	const colorIsHex = color?.startsWith('#');
	const colorProp = colorIsHex ? { style: { color } } : { color: color || 'green' };

	const sectionClassName = data.tooltipSettings?.alignCenter ? 'tooltip-row tooltip-row--center' : 'tooltip-row';

	const progressSection = (
		<>
			<Text color="black" size="sm" className={sectionClassName}>
				<Icon name="circle" {...colorProp} striped={stripedColor} />
				<div>
					<Text size="sm">
						{progressLabel || data.language.progress}:{' '}
						{data.tooltipSettings?.progressFormat?.value?.bold ? <b>{progressValue}</b> : progressValue}
					</Text>
					{row?.tooltipFields?.map((field, index) => (
						<Text size="sm" key={`${field.value}-${index}`}>
							{field.value}
						</Text>
					)) ?? null}
				</div>
			</Text>
		</>
	);

	const goalValue = formatWidgetValue(goal, data.datatype?.goal, data.currency, false);
	const goalSection = showGoal ? (
		<Text color="black" size="sm" className={sectionClassName}>
			<Icon name="circle" color="grey-2" />
			{data.language.goal}: {goalValue}
		</Text>
	) : null;

	const surpassedQouta = showGoal && progress > goal && goal !== 0;
	const surpassedQoutaSection = surpassedQouta ? (
		<Text size="sm" color="black" className={sectionClassName}>
			<Icon name="circle" color="success-4" />
			{T('report.surpassedQouta')}: +
			{formatWidgetValue(Math.abs(remaining), data.datatype?.goal, data.currency, false)}
		</Text>
	) : null;

	const remainingUntilQuota = progress < goal;
	const remainingUntilQuotaValue = formatWidgetValue(remaining, data.datatype?.goal, data.currency, false);

	const remainingSection =
		showGoal && remainingUntilQuota ? (
			<Text color="black" size="sm" className="no-icon">
				{data.language.remaining}: {remainingUntilQuotaValue}
			</Text>
		) : null;

	const className = 'BarchartWidgetBars__tooltip';

	const TooltipContent = () => (
		<div className={className}>
			{progressSection}
			{/* {extraTooltipFieldsSection} */}
			{goalSection}
			{surpassedQoutaSection}
			{remainingSection}
		</div>
	);

	return renderToString(<TooltipContent />)
		.replace(' data-reactroot=""', '')
		.replace(/<!-- -->/g, '');
};

export const loadDrilldown = (keys: string | string[], widgetConfig: RCDashboardWidget, drilldownType = 1) => {
	openDrawer('ReportcenterDrilldownDrawer', { keys, widgetConfig, drilldownType });
};
