import Role from 'App/resources/Model/Role';
import { TreeSelectParentStyle } from 'Components/TreeSelect';
import { SelectOptionType } from 'Components/TreeSelect/TreeSelect';
import BemClass from '@upsales/components/Utils/bemClass';
import { Block, DropDownMenu, Text, Flex, Icon } from '@upsales/components';
import { useTranslation } from 'Components/Helpers/translate';
import React, { useMemo, useState } from 'react';

import './RoleSelect.scss';

type RoleIdName = Pick<Role, 'id' | 'name'>;
type Props = {
	roles: Role[];
	selectedRoles: RoleIdName[];
	onChange: (selectedRoles: RoleIdName[]) => void;
	positionRelative?: boolean;
	reloadModalPosition?: () => void;
} & Omit<React.ComponentProps<typeof TreeSelectParentStyle>, 'options' | 'selectedOptions' | 'onChange'>;

const RoleSelect = (props: Props) => {
	const {
		roles = [],
		selectedRoles = [],
		onChange,
		positionRelative,
		reloadModalPosition,
		singleSelect,
		...rest
	} = props;

	const classes = new BemClass('RoleSelect');
	const { t } = useTranslation();
	const options = useMemo(
		() =>
			roles.map(({ id, name, parent }) => ({
				id,
				title: name,
				parent: parent ? { id: parent.id, title: parent.name } : undefined
			})),
		[roles]
	);
	const [selectedOptions, setSelectedOptions] = useState(
		(singleSelect ? selectedRoles.slice(0, 1) : selectedRoles).filter(Boolean)
	);

	const onRoleChange = (selected: SelectOptionType[]) => {
		const selectedRoles = selected.map(option => ({ id: option.id, name: option.title }));
		setSelectedOptions(selectedRoles);
		onChange(selectedRoles);
	};

	return (
		<div className={classes.mod({ positionRelative }).b()}>
			<DropDownMenu
				onClose={() => reloadModalPosition?.()}
				renderTrigger={(expanded, setExpanded) => (
					<Block
						className={classes.elem('input').b()}
						backgroundColor="white"
						border="s"
						borderColor={expanded ? 'green' : 'grey-6'}
						space="pls prs"
						borderRadius
						onClick={e => {
							setExpanded(e);
							setTimeout(() => reloadModalPosition?.(), 0);
						}}
					>
						<Flex alignItems="center" justifyContent="space-between">
							<ReactTemplates.admin.tableTokens
								noTooltip
								items={selectedOptions}
								additionalTokenSpace={50}
								placeholder={singleSelect ? t('default.selectRole') : t('default.selectRoles')}
							/>
							{props.required || !selectedOptions.length ? null : (
								<Text space="mrs" size="sm">
									<Icon
										name="times"
										color="black"
										onClick={e => {
											e.stopPropagation();
											onRoleChange([]);
										}}
									/>
								</Text>
							)}
							{expanded ? null : <Icon name="chevron-down" color="black" />}
						</Flex>
					</Block>
				)}
			>
				<TreeSelectParentStyle
					singleSelect={singleSelect}
					options={options}
					selectedOptions={selectedOptions}
					onChange={onRoleChange}
					{...rest}
				/>
			</DropDownMenu>
		</div>
	);
};

type PropsSingle = {
	selectedRole?: RoleIdName;
	onChange: (selectedRole: RoleIdName | null) => void;
} & Omit<Props, 'singleSelect' | 'onChange' | 'selectedRoles'>;

export const RoleSelectSingle = ({ onChange, ...rest }: PropsSingle) => (
	<RoleSelect
		{...rest}
		singleSelect
		onChange={([selected]) => onChange(selected ?? null)}
		selectedRoles={rest.selectedRole ? [rest.selectedRole] : []}
	/>
);

export default RoleSelect;
