import { Attr } from './Attribute';
import { StandardFieldConfig } from 'App/resources/AllIWant';

type Attributes = { [key: string]: Attr };
type StandardFields = { [key: string]: StandardFieldConfig };

export const filterActiveAttributes = (
	attributes: Attributes = {},
	standardFields: StandardFields = {}
): Attributes => {
	const standardFieldMap: { [field: string]: any } = {};
	Object.keys(standardFields).forEach(key => {
		const standardField = standardFields[key];
		standardFieldMap[standardField.field] = standardField;
	});
	const attributeKeys: string[] = Object.keys(attributes);
	const filteredAttributes: Attributes = attributeKeys.reduce((attrs: Attributes, key: string) => {
		const attribute: Attr = attributes[key];
		const standardField = standardFieldMap[attribute.field];

		if (attribute && standardField) {
			if (standardField.active) {
				attrs[key] = attribute;
			}
		} else {
			attrs[key] = attribute;
		}
		return attrs;
	}, {});

	return filteredAttributes;
};
