import React from 'react';
import { Table, TableRow, TableColumn, RadioItem, Card } from '@upsales/components';
import bemClass from '@upsales/components/Utils/bemClass';
import PropTypes from 'prop-types';
import T from 'Components/Helpers/translate';
import './CheckableList.scss';

const CheckableList = ({ asIds = true, displayKey, valueKey, items, selectedItems, onItemToggle }) => {
	const classes = new bemClass('CheckableList');

	const itemIsSelected = val => {
		const index = asIds ? selectedItems.indexOf(val) : selectedItems.findIndex(item => item[valueKey] === val);
		return index !== -1;
	};

	return (
		<Card className={classes.b()}>
			<div>
				<Table>
					{items && items.length
						? items.map(item => (
								<TableRow
									key={item[valueKey]}
									onClick={() => onItemToggle(asIds ? item[valueKey] : item)}
								>
									<TableColumn>
										<RadioItem size="xs" checked={itemIsSelected(item[valueKey])} />
									</TableColumn>
									<TableColumn>{T(item[displayKey])}</TableColumn>
								</TableRow>
						  ))
						: null}
				</Table>
			</div>
		</Card>
	);
};

CheckableList.propTypes = {
	asIds: PropTypes.bool,
	multiple: PropTypes.bool,
	onItemToggle: PropTypes.func.isRequired,
	displayKey: PropTypes.string.isRequired,
	valueKey: PropTypes.string.isRequired,
	items: PropTypes.array
};

export default CheckableList;
