const _ = window._;

class FieldList {
	constructor(props) {
		this.entities = props;

		_.each(props.udos, udo => {
			udo.isUdo = true;
			this.entities[udo.name] = [udo];
		});
	}
	get(entity, fields, addParent) {
		if (typeof entity === 'undefined' && typeof fields === 'undefined') {
			return this.entities;
		}

		if (fields) {
			if (!Array.isArray(fields)) {
				fields = [fields];
			}

			return _.chain(this.entities[entity])
				.map((field, key) => {
					let customTitle;

					if (entity === 'formsubmit' || entity === 'document' || entity === 'esign') {
						return {
							name: 'default.allFields',
							field: entity,
							parent: entity
						};
					}

					if (field.isUdo) {
						return {
							name: 'default.allFields',
							field: 'udo_' + field.id,
							isUdo: true,
							parent: entity
						};
					}

					if (fields.indexOf(key) > -1) {
						if (key === 'custom') {
							customTitle = 'admin.anonymization.customFields';
						} else if (key === 'notes') {
							customTitle = 'default.notes';
						} else if (key === 'fields') {
							customTitle = 'default.allFields';
						}

						const retField = {
							name: field.title || customTitle || field.name || key,
							field: field.field || key
						};

						if (typeof addParent !== 'undefined' && addParent === true) {
							retField.parent = entity;
						}

						if (entity === 'appointment' && key === 'custom') {
							retField.field = 'custom';
						}

						return retField;
					}
				})
				.compact()
				.value();
		}

		return this.entities[entity];
	}
}

window.FieldList = FieldList;
export default window.FieldList;
