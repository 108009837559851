import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import BemClass from '@upsales/components/Utils/bemClass';
import {
	ButtonSelect,
	Label,
	DateInput,
	TimeInput,
	DropDownMenu,
	Table,
	TableRow,
	TableColumn
} from '@upsales/components';
import moment from 'moment';

import './index.scss';

class OutcomePostponeDate extends PureComponent {
	constructor(props) {
		super(props);

		const t = Tools.$translate;
		this.lang = {
			postponeDate: t('activity.outcome.postponeDate'),
			customDate: t('activity.outcome.customDate'),
			time: t('default.time'),
			today: t('activity.today')
		};
	}

	onDateChange = ({ target }) => {
		const { date } = this.props;

		if (!moment(date).isSame(target.value)) {
			this.props.onDateChange(target.value);
		}
	};

	onTimeChange = ({ target }) => {
		this.props.onTimeChange(target.value);
	};

	renderRows(setExpanded) {
		const now = moment();
		const endDay = moment().endOf('day');
		const hoursLeft = endDay.diff(now, 'hours') || 0;
		let hours = now.set({
			minutes: 0,
			seconds: 0
		});

		if (hoursLeft <= 0) return null;

		return Array.from(new Array(hoursLeft)).map(() => {
			hours = hours.add(1, 'hour');
			const timeFormat = hours.format('LT');
			const handleClick = ({ currentTarget }) => {
				this.props.onTimeChange(new Date(currentTarget.dataset.datetime));
				setExpanded(false);
			};

			return (
				<TableRow key={hours.valueOf()} onClick={handleClick} data-datetime={hours.toISOString()}>
					<TableColumn>
						{timeFormat} {this.lang.today}
					</TableColumn>
				</TableRow>
			);
		});
	}

	render() {
		const classNames = new BemClass('OutcomePostponeDate');
		const { presets, presetValue, date, disabled, showTime, dateAnchor, dateScrollContainer } = this.props;
		const suggestedTime = moment()
			.add(30 - (moment().minute() % 30), 'minutes')
			.format('HH:mm');
		return (
			<div className={classNames.b()}>
				<div className={classNames.elem('postpone').b()}>
					<Label>{this.lang.postponeDate}</Label>
					<DropDownMenu
						align="left"
						verticalAlign="top"
						anchor={dateAnchor}
						renderTrigger={(isExpanded, setExpanded) => {
							const handPresetChange = preset => {
								if (preset === 'currentDay') {
									setExpanded(new Event('click'));
								}
								this.props.onPresetChange(preset);
							};

							return (
								<ButtonSelect
									size="md"
									options={presets}
									value={presetValue}
									onChange={handPresetChange}
									alwaysTriggerChange
								/>
							);
						}}
					>
						{setExpanded => (
							<div className={classNames.elem('postponeLaterToday').b()}>
								<Table>{this.renderRows(setExpanded)}</Table>
							</div>
						)}
					</DropDownMenu>
				</div>
				<div>
					<Label>{this.lang.customDate}</Label>
					<div className={classNames.elem('date-time-picker').b()}>
						<DateInput
							disabled={disabled}
							value={date}
							closeOnSelect
							showWeekNumbers={true}
							className={classNames.elem('date-picker').b()}
							onChange={this.onDateChange}
							calendarSize={6}
							anchor={dateAnchor}
							scrollContainer={dateScrollContainer}
						/>
						<TimeInput
							asDate
							disabled={disabled}
							placeholder={this.lang.time}
							value={showTime ? date : ''}
							className={classNames.elem('time-picker').b()}
							onChange={this.onTimeChange}
							anchor={dateAnchor}
							scrollContainer={dateScrollContainer}
							defaultSelected={suggestedTime}
						/>
					</div>
				</div>
			</div>
		);
	}
}

OutcomePostponeDate.defaultProps = {
	onPresetChange: () => {},
	onDateChange: () => {},
	onTimeChange: () => {},
	presets: []
};

OutcomePostponeDate.propTypes = {
	presets: PropTypes.arrayOf(
		PropTypes.shape({
			title: PropTypes.string,
			value: PropTypes.string
		})
	),
	presetValue: PropTypes.string,
	onPresetChange: PropTypes.func,
	date: PropTypes.instanceOf(Date),
	showTime: PropTypes.bool,
	onDateChange: PropTypes.func,
	onTimeChange: PropTypes.func,
	disabled: PropTypes.bool,
	dateAnchor: PropTypes.oneOfType([PropTypes.element, PropTypes.object]),
	dateScrollContainer: PropTypes.oneOfType([PropTypes.element, PropTypes.object])
};

export default OutcomePostponeDate;
