import { convertObjectKeysToEnum, groupEnumBySuffix } from 'App/helpers/convertObjectToEnum';

const ComparisonTypes = {
	Equals: 'eq',
	EqualsAll: 'all',
	NotEquals: 'ne',
	GreaterThan: 'gt',
	GreaterThanEquals: 'gte',
	LessThan: 'lt',
	LessThanEquals: 'lte',
	LowerThan: 'lt',
	LowerThanEquals: 'lte',

	NotGreaterThan: 'ngt',
	NotGreaterThanEquals: 'ngte',
	NotLessThan: 'nlt',
	NotLessThanEquals: 'nlte',
	NotLowerThan: 'nlt',
	NotLowerThanEquals: 'nlte',

	Like: 'like',
	Between: 'bt',
	NotBetween: 'nbt',
	Wildcard: 'wc',
	WildcardEnd: 'wce',
	WildcardStart: 'wcs',
	MatchPhrase: 'mp',
	Match: 'm',
	Term: 'trm',
	NotTerm: 'ntrm',
	Search: 'src',
	NotSearch: 'nsrc',

	NotMatchPhrase: 'nmp',
	NotMatch: 'nm',
	NotWildcard: 'nwc',
	NotWildcardEnd: 'nwce',
	NotWildcardStart: 'nwcs',

	MatchExact: 'mex',
	NotMatchExact: 'nmex',

	MAnd: 'mand',
	MOr: 'mor'
} as const;

export const Equals = ComparisonTypes.Equals;
export const EqualsAll = ComparisonTypes.EqualsAll;
export const NotEquals = ComparisonTypes.NotEquals;
export const GreaterThan = ComparisonTypes.GreaterThan;
export const GreaterThanEquals = ComparisonTypes.GreaterThanEquals;
export const LessThan = ComparisonTypes.LessThan;
export const LessThanEquals = ComparisonTypes.LessThanEquals;
export const LowerThan = ComparisonTypes.LowerThan;
export const LowerThanEquals = ComparisonTypes.LowerThanEquals;

export const NotGreaterThan = ComparisonTypes.NotGreaterThan;
export const NotGreaterThanEquals = ComparisonTypes.NotGreaterThanEquals;
export const NotLessThan = ComparisonTypes.NotLessThan;
export const NotLessThanEquals = ComparisonTypes.NotLessThanEquals;
export const NotLowerThan = ComparisonTypes.NotLowerThan;
export const NotLowerThanEquals = ComparisonTypes.NotLowerThanEquals;

export const Like = ComparisonTypes.Like;
export const Between = ComparisonTypes.Between;
export const NotBetween = ComparisonTypes.NotBetween;
export const Wildcard = ComparisonTypes.Wildcard;
export const WildcardEnd = ComparisonTypes.WildcardEnd;
export const WildcardStart = ComparisonTypes.WildcardStart;
export const MatchPhrase = ComparisonTypes.MatchPhrase;
export const Match = ComparisonTypes.Match;
export const Term = ComparisonTypes.Term;
export const NotTerm = ComparisonTypes.NotTerm;
export const Search = ComparisonTypes.Search;
export const NotSearch = ComparisonTypes.NotSearch;

export const NotMatchPhrase = ComparisonTypes.NotMatchPhrase;
export const NotMatch = ComparisonTypes.NotMatch;
export const NotWildcard = ComparisonTypes.NotWildcard;
export const NotWildcardEnd = ComparisonTypes.NotWildcardEnd;
export const NotWildcardStart = ComparisonTypes.NotWildcardStart;

export const MatchExact = ComparisonTypes.MatchExact;
export const NotMatchExact = ComparisonTypes.NotMatchExact;

export const MAnd = ComparisonTypes.MAnd;
export const MOr = ComparisonTypes.MOr;

export default ComparisonTypes;

export type ComparisonType = (typeof ComparisonTypes)[keyof typeof ComparisonTypes];
export type ComparisonTypeName = keyof typeof ComparisonTypes;

export const ComparisonTypeEnum = convertObjectKeysToEnum<typeof ComparisonTypes>(ComparisonTypes);
export const GroupedComparisonTypes = groupEnumBySuffix(ComparisonTypeEnum, 'Not');
