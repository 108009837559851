import moment from 'moment';
import { Text } from '@upsales/components';
import T from 'Components/Helpers/translate';
import { findLastIndex, findIndex } from 'lodash';
import Debounce from 'Components/Helpers/Debounce';
import Agreement from 'App/resources/Model/Agreement';
import BemClass from '@upsales/components/Utils/bemClass';
import React, { useState, useEffect, useMemo } from 'react';
import { isTieredTotalOrderRow } from 'App/babel/helpers/order';
import { RCWidgetData, RCWidgetDataSubRow } from 'Resources/ReportWidget';
import ReportcenterWidget from 'App/pages/Reportcenter/ReportcenterWidget';
import type {
	FormattedSubscriptionPeriod,
	SubscriptionPeriodState
} from 'App/components/EditSubscription/Context/SubscriptionGroupState';

import './SubscriptionPreviewGraph.scss';

const getOnlyCrucialData = (agreements?: FormattedSubscriptionPeriod[]) => {
	if (!agreements) return null;

	return JSON.stringify(
		agreements.map(agreement => ({
			periodLength: agreement.metadata.periodLength,
			orderInterval: agreement.metadata.agreementIntervalPeriod,
			offset: agreement.metadata.agreementOrderCreationTime,
			noticePeriod: agreement.metadata.noticePeriod,
			startDate: agreement.metadata.agreementStartdate,
			invoiceStartDate: agreement.metadata.agreementInvoiceStartdate,
			renewalDate: agreement.metadata.agreementRenewalDate,
			endDate: agreement.metadata.agreementEnddate,
			currency: agreement.currency,
			currencyRate: agreement.currencyRate,
			children: agreement.children,
			orderRows: agreement.orderRow.map(row => ({
				listPrice: row.listPrice,
				price: row.price,
				quantity: row.quantity
			}))
		}))
	);
};

type SubscriptionPreviewGraphProps = {
	agreement?: Agreement | FormattedSubscriptionPeriod;
	hideTitle?: boolean;
	agreements?: FormattedSubscriptionPeriod[];
	greenBox?: boolean;
	currentUUID?: number;
	childIndexNr?: number;
	currentPeriod?: SubscriptionPeriodState;
	boxText?: string;
	agreementsToShowDiffOrder?: number[];
	isEdit?: boolean;
	extraDiffOrderValue?: number;
};

type RCWidgetDataWithUUID = RCWidgetData & {
	rows: ({ uuid: number; uuids: number[] } & RCWidgetDataSubRow)[];
};

const BAR_WIDTH = 67;

const SubscriptionPreviewGraph = ({
	agreement,
	hideTitle,
	agreements,
	greenBox,
	currentUUID,
	childIndexNr = 0,
	currentPeriod,
	boxText,
	agreementsToShowDiffOrder,
	isEdit,
	extraDiffOrderValue
}: SubscriptionPreviewGraphProps) => {
	const classes = new BemClass('SubscriptionPreviewGraph');

	const [previewData, setPreviewData] = useState<RCWidgetDataWithUUID | null>(null);
	const [previewVersion, setPreviewVersion] = useState(0);
	const [scrollLeft, setScrollLeft] = useState(0);
	const [transition, setTransition] = useState(false);
	const [loading, setLoading] = useState(false);

	const hasSplitFlag = Tools.FeatureHelper.hasSoftDeployAccess('SUBSCRIPTION_SPLITS');

	const getPreview = async (dataPromise: Promise<any>) => {
		setLoading(true);
		const data = await dataPromise;
		setPreviewData(data);
		setPreviewVersion(currentVersion => currentVersion + 1);
		setLoading(false);
	};

	const fetchPreviewGroupDebounce = useMemo(
		() =>
			Debounce(
				(agreements: Agreement[], agreementsToShowDiffOrder: number[]) =>
					getPreview(
						Tools.Agreement.customer(Tools.AppService.getCustomerId()).previewGroup(
							agreements,
							agreementsToShowDiffOrder,
							isEdit,
							extraDiffOrderValue
						)
					),
				300
			),
		[]
	);

	useEffect(() => {
		setTransition(true);
		const timeoutID = setTimeout(() => {
			setTransition(false);
		}, 1600 /* transition-duration plus some fluff */);
		return () => {
			clearTimeout(timeoutID);
		};
	}, [currentUUID, childIndexNr]);

	useEffect(() => {
		if (agreement) {
			agreement.orderRow = agreement.orderRow?.map(row => ({
				...row,
				quantity: isTieredTotalOrderRow(row) ? 1 : row.quantity
			}));

			getPreview(Tools.Agreement.customer(Tools.AppService.getCustomerId()).preview(agreement));
		}
	}, [agreement]);

	useEffect(() => {
		if (agreements) {
			fetchPreviewGroupDebounce(agreements, agreementsToShowDiffOrder);
		}
	}, [getOnlyCrucialData(agreements), agreementsToShowDiffOrder]);

	//maybe move this to the widget later
	const widget = document.querySelector('.SubscriptionPreviewGraph .ReportcenterWidget__widget');
	useEffect(() => {
		const onScroll = (e: Event) => {
			setScrollLeft((e.target as HTMLElement).scrollLeft ?? 0);
		};
		widget?.addEventListener('scroll', onScroll, true);

		if (previewData && (previewData.rows?.length ?? -1) > 0) {
			const firstOrderRowIndex = previewData.rows.findIndex(r => moment(r.key).isAfter());
			if (firstOrderRowIndex >= 0) {
				widget?.scrollTo(firstOrderRowIndex * BAR_WIDTH, 0);
			}
		}

		return () => {
			widget?.removeEventListener('scroll', onScroll);
		};
	}, [widget]);

	const calcLeftAndWidth = () => {
		if (!previewData?.rows) {
			return {};
		}

		let index;
		let lastIndex;
		const currentChildren = currentPeriod?.children;
		if (currentChildren?.length && hasSplitFlag) {
			const currentChild = currentChildren[childIndexNr];

			index = findIndex(previewData.rows, row => row.uuids.includes(currentChild.uuid));
			lastIndex = findLastIndex(previewData.rows, row => row.uuids.includes(currentChild.uuid));
		} else {
			index = findIndex(previewData.rows, row => row.uuid === currentUUID);
			lastIndex = findLastIndex(previewData.rows, row => row.uuid === currentUUID);
		}

		const offset = 108;
		const left = index * BAR_WIDTH + offset;
		const width = (lastIndex - index + 1) * BAR_WIDTH;
		return { width: `${width}px`, left: `${left - scrollLeft}px` };
	};

	const style = useMemo(
		() => calcLeftAndWidth(),
		[
			scrollLeft,
			previewVersion,
			transition,
			transition && childIndexNr, // These are for updating style when we are in the middle of the transition
			transition && currentUUID
		]
	);
	if (!previewData?.rows) {
		return null;
	}

	return (
		<div className={classes.b()}>
			<ReportcenterWidget
				key={previewVersion}
				widgetConfig={{
					id: 123,
					title: hideTitle ? '' : T('agreement.subscriptionPreview.graphTitle'),
					type: 'SALES',
					displayType: 'columnChart',
					height: 1.5,
					width: 1,
					groupBy: ['month'],
					legend: !!previewData?.rows?.length,
					goal: false,
					x: 0,
					y: 0,
					filters: [],
					uniqueId: 16425236542927
				}}
				drilldown={false}
				widgetData={{
					data: previewData,
					loading,
					error: false
				}}
				getWidgetData={() => {}}
				tooltipDistance={-45}
				hideProgressLabel
				thinMode
				showExtraTick
				allowZeroHeight
				renderWithYearInLabel
				disableTooltipIfNoProgress
			/>
			{greenBox && !!previewData?.rows?.length ? (
				<>
					<div className={classes.elem('curtain').b()} />
					<div className={classes.elem('greenBox').mod({ transition }).b()} style={style}>
						{!hasSplitFlag ? (
							<Text size="sm" bold color="medium-green">
								{boxText}
							</Text>
						) : null}
					</div>
				</>
			) : null}
		</div>
	);
};

export default SubscriptionPreviewGraph;
