import _ from 'lodash';

// Return true or false if the user can see the stage
export const hasRoleAccess = function (self, product) {
	// If user is admin we can use all products OR
	// If roles is empty everyone can use this product OR
	if (!product.roles || !product.roles.length) {
		return true;
	}
	const myRoleId = self.role ? self.role.id : null;
	// If my role is in array we are all good
	if (myRoleId && _.find(product.roles, { id: myRoleId })) {
		return true;
	}
	// If we get to this we cannot use this product, sorry
	return false;
};

export const hasCategoryAccess = (product, productCategories) => {
	const categoryAccessMap = new Map(productCategories.map(pc => [pc.id, pc.$hasAccess]));
	if (!product.category) {
		return true;
	}
	return Boolean(categoryAccessMap.get(product.category.id));
};

export const hasAccess = (self, product, productCategories) => {
	return self.administrator || (hasRoleAccess(self, product) && hasCategoryAccess(product, productCategories));
};

const parseProduct = product => {
	const self = Tools.AppService.getSelf();
	const productCategories = Tools.AppService.getProductCategories();
	const hasBackendAccess = Tools.FeatureHelper.hasSoftDeployAccess('BACKEND_PRODUCT_ACCESS');
	product.$hasAccess = hasBackendAccess ? product.userUsable : hasAccess(self, product, productCategories);
	return product;
};

export default parseProduct;
