import { UiElementConfig } from 'App/resources/AllIWant';
import { EntityCustomField } from 'App/resources/Model/CustomField';
import Order from 'App/resources/Model/Order';
import { replaceItem } from 'Store/helpers/array';

export const getFrameName = (config: UiElementConfig) => {
	return `ui-element-${config.integrationId}-${config.name}`;
};

export const getFrameByConfig = (config: UiElementConfig): HTMLIFrameElement | null => {
	return document.querySelector(`[name=${getFrameName(config)}]`);
};

export type FrameMessageEvent = (string | number)[];

const isHTMLIFrameElement = (elem: any): elem is HTMLIFrameElement => {
	return elem?.style !== undefined;
};

export const postEventToFrame = (config: UiElementConfig, event: string, data: string) => {
	const frame = getFrameByConfig(config);
	if (isHTMLIFrameElement(frame)) {
		frame.contentWindow?.postMessage([event, typeof data === 'string' ? data : JSON.stringify(data)], '*');
	}
};

export const postObjectChangeToFrame = (config: UiElementConfig, object: object) => {
	postEventToFrame(config, 'onchange', typeof object === 'string' ? object : JSON.stringify(object));
};

type AnyUiElementsEntity = { id?: number };
type CustomUiElementsEntity = AnyUiElementsEntity & { custom: EntityCustomField[] };

type FieldToggle = {
	[key: string]: boolean | FieldToggle;
};

type FieldActions = {
	updated?: {
		order: Partial<Order>;
	};
	disabled?: {
		order: FieldToggle;
	};
	visible?: {
		order: FieldToggle;
	};
};

export type Utils = {
	updateObjectCustom?: (custom: EntityCustomField[]) => void;
	addOrderRow?: (
		productId: number | null,
		quantity: number | null,
		price: number | null,
		listPrice: number | null
	) => void;
	updateOrderRowPrice?: (index: number, price: number) => void;
	updateOrderRowListPrice?: (index: number, listPrice: number) => void;
	updateOrderRowProduct?: (index: number, productId: number) => void;
	setOrderRowCustomFieldValue?: (index: number, rowMappedCustom: { [id: number]: EntityCustomField }) => void;
	updateOrderFromApp?: (fieldActions: FieldActions, order?: Order) => void;
};

export const nameRequiredEvents = ['init'];

export const handleIntegrationIframeMessage = <T extends AnyUiElementsEntity>(
	type: string,
	integrationId: number | null,
	getObject: () => Readonly<T>,
	{
		updateObjectCustom,
		addOrderRow,
		updateOrderRowPrice,
		updateOrderRowListPrice,
		updateOrderRowProduct,
		setOrderRowCustomFieldValue,
		updateOrderFromApp
	}: Utils = {},
	e: MessageEvent<FrameMessageEvent>
): void => {
	if (e.data?.length > 0) {
		const event = e.data[0] as string;
		const name = e.data[e.data.length - 1] as string;
		// For the events where we need the frame we must make sure that the last data param was the name of the frame
		let frame: HTMLIFrameElement | null = null;
		if (integrationId && ['init', 'openModal'].includes(event) && e.data.length > 1) {
			frame = getFrameByConfig({ integrationId, name });
		}
		switch (event) {
			case 'init':
				// Make sure frame and height was a number
				if (!isHTMLIFrameElement(frame) || isNaN(parseInt(e.data[1] + ''))) {
					return;
				}
				frame.style.height = `${e.data[1]}px`;
				frame.style.display = 'block';
				break;
			case 'openModal':
				// eslint-disable-next-line promise/catch-or-return
				Tools.$upModal
					.open('integrationModal', {
						name: e.data[1],
						type,
						integrationId,
						objectId: getObject().id,
						frameless: true,
						fullscreen: true
					})
					.then(function (data) {
						if (e.data.length === 4 && isHTMLIFrameElement(frame)) {
							const responseEvent = e.data[2];
							frame.contentWindow?.postMessage([responseEvent, JSON.stringify(data)], '*');
						}
					});
				break;
			case 'closeModal':
				document.querySelector<HTMLElement>('.up-modal-curtain')?.click();
				break;
			case 'setCustomFieldValue':
				// validate type to where customfields can be set
				if (['editOrder'].indexOf(type) !== -1 && e.data.length === 3) {
					const fieldId = e.data[1].toString();
					const value = e.data[2].toString();
					const obj = getObject() as unknown as CustomUiElementsEntity;
					const fieldIndex = obj.custom?.findIndex(f => f.fieldId.toString() === fieldId) ?? -1;
					if (fieldIndex !== -1 && obj.custom) {
						const custom = replaceItem(obj.custom, fieldIndex, { ...obj.custom[fieldIndex], value });
						updateObjectCustom?.(custom);
					}
				}
				break;
			case 'addOrderRow':
				// (productId, quantity, price, listPrice) where productId, price and listPrice is optional
				if (['editOrder'].indexOf(type) !== -1) {
					// Check for product id
					let productId = null;
					let quantity = null;
					let price = null;
					let listPrice = null;
					const prodId = parseInt(e.data[1]?.toString());
					if (!isNaN(prodId)) {
						productId = prodId;
					}
					// Check for price
					const q = parseInt(e.data[2]?.toString());
					if (!isNaN(q)) {
						quantity = q;
					}
					// Check for price
					const p = parseInt(e.data[3]?.toString());
					if (!isNaN(p)) {
						price = p;
					}
					// Check for listPrice
					const lp = parseInt(e.data[4]?.toString());
					if (!isNaN(lp)) {
						listPrice = lp;
					}
					addOrderRow?.(productId, quantity, price, listPrice);
				}
				break;
			case 'updateOrderRowPrice':
				// (rowIndex, price)
				if (['editOrder'].indexOf(type) !== -1 && e.data.length === 3) {
					const obj = getObject() as unknown as Order;
					const index = parseInt(e.data[1].toString());
					const price = parseInt(e.data[2].toString());
					// Make sure row at index exists
					const row = obj.orderRow[index];
					if (row && !isNaN(index) && !isNaN(price)) {
						updateOrderRowPrice?.(index, price);
					}
				}
				break;
			case 'updateOrderRowListPrice':
				// (rowIndex, listPrice)
				if (['editOrder'].indexOf(type) !== -1 && e.data.length === 3) {
					const obj = getObject() as unknown as Order;
					const index = parseInt(e.data[1].toString());
					const listPrice = parseInt(e.data[2].toString());
					// Make sure row at index exists
					const row = obj.orderRow[index];
					if (row && !isNaN(index) && !isNaN(listPrice)) {
						updateOrderRowListPrice?.(index, listPrice);
					}
				}
				break;
			case 'updateOrderRowProduct':
				// (rowIndex, productId)
				if (['editOrder'].indexOf(type) !== -1 && e.data.length === 3) {
					const obj = getObject() as unknown as Order;
					const index = parseInt(e.data[1].toString());
					const productId = parseInt(e.data[2].toString());
					// Make sure row at index exists
					const row = obj.orderRow[index];
					if (row && !isNaN(index) && !isNaN(productId)) {
						updateOrderRowProduct?.(index, productId);
					}
				}
				break;
			case 'setOrderRowCustomFieldValue':
				// (rowIndex, fieldId, value)
				if (['editOrder'].indexOf(type) !== -1 && e.data.length === 4) {
					const obj = getObject() as unknown as Order;
					const index = parseInt(e.data[1].toString());
					const fieldId = parseInt(e.data[2].toString());
					const value = e.data[3].toString();
					// Make sure row at index exists
					const row = obj.orderRow[index];
					if (row && !isNaN(index) && !isNaN(fieldId)) {
						const fieldObj = row.$mappedCustom?.[fieldId];
						if (fieldObj) {
							setOrderRowCustomFieldValue?.(index, {
								...row.$mappedCustom,
								[fieldId]: { ...row.$mappedCustom?.[fieldId], value }
							});
						}
					}
				}
				break;
			case 'updateOrderFromApp':
				if (e.data.length >= 2) {
					const fieldActions = e.data[1] as FieldActions;
					const order = e.data[2] as unknown as Order;
					updateOrderFromApp?.(fieldActions, order);
				}
				break;
		}
	}
};
