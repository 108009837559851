import { Column, Row, Checkbox, Icon, Text } from '@upsales/components';
import BemClass from '@upsales/components/Utils/bemClass';
import Todo from 'App/resources/Model/Todo';
import React, { useState } from 'react';
import { iconMap, getDate, onRowClick } from './OpportunityTodoRow';

import './OpportunityTodoRowChild.scss';

interface Props {
	todo: Todo;
	onCheckToggle: (checked: boolean, id: number) => void;
}
const OpportunityTodoRowChild = ({ todo, onCheckToggle }: Props) => {
	const classes = new BemClass('OpportunityTodoRowChild');
	const [checked, setChecked] = useState(!!todo.closeDate);
	const { type } = todo;
	return (
		<Row className={classes.b()} onClick={() => onRowClick(todo)}>
			<Column fixedWidth={60} align="center" className={classes.elem('col-1').b()}>
				{type === 'todo' ? (
					<Checkbox
						size="sm"
						checked={checked}
						onClick={e => {
							e?.stopPropagation();
							onCheckToggle(!checked, todo.id);
							setChecked(!checked);
						}}
						disabled={!todo.userEditable}
					/>
				) : (
					<Icon name={iconMap[type]} />
				)}
			</Column>
			<Column className={classes.elem('col-2').b()}>
				<Text className={classes.elem('description').mod({ checked }).b()}>{todo.description}</Text>
				{todo.date ? (
					<Text color="grey-10" size="sm">
						{getDate(todo.date, todo.type, todo.time)}
					</Text>
				) : null}
			</Column>
		</Row>
	);
};
export default OpportunityTodoRowChild;
