import Attribute from './Attribute';

const OnboardingCompletedAttributes = {
	id: new Attribute({
		type: Attribute.types.Number,
		field: 'id'
	}),
	userId: new Attribute({
		type: Attribute.types.Number,
		field: 'userId'
	}),
	onboardingId: new Attribute({
		type: Attribute.types.Number,
		field: 'onboardingId'
	}),
	hasStarted: new Attribute({
		type: Attribute.types.Number,
		field: 'hasStarted'
	}),
	hasCompleted: new Attribute({
		type: Attribute.types.Number,
		field: 'hasCompleted'
	}),
	completedDate: new Attribute({
		type: Attribute.types.Date,
		field: 'completedDate'
	})
};

window.OnboardingCompletedAttributes = OnboardingCompletedAttributes;

export default OnboardingCompletedAttributes;
