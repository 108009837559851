import React from 'react';
import { Card, Flex, Icon, Text } from '@upsales/components';
import { PrimaryButton } from '@upsales/components/Buttons';
import BemClass from '@upsales/components/Utils/bemClass';
import { useTranslation } from 'Components/Helpers/translate';

type Props = {
	classes: BemClass;
	entityName: string;
	onHideDuplicate: (e: React.MouseEvent<HTMLButtonElement>) => void;
};

const HiddenDuplicatesCard = ({ classes, entityName, onHideDuplicate }: Props) => {
	const { t } = useTranslation();

	return (
		<Card color="grey-2" className={classes.elem('hiddenCard').b()} space="mbl">
			<Flex alignItems="center">
				<Flex
					alignItems="center"
					justifyContent="center"
					space="ptl prl pbl pll"
					className={classes.elem('hiddenCardButtonContainer').b()}
				>
					<PrimaryButton onClick={onHideDuplicate}>
						<Flex alignItems="center">
							<Icon space="mrm" name="eye" />
							<Text color="white">{t('default.show')}</Text>
						</Flex>
					</PrimaryButton>
				</Flex>
				<Flex direction="column" gap="u1" justifyContent="left" space="ptl prl pbl pll" alignContent="center">
					<Text bold size="lg" color="grey-10">
						{entityName === 'Client'
							? t('detectedDuplicates.hiddenCompany')
							: t('detectedDuplicates.hiddenContact')}
					</Text>
					<Text color="grey-10">{t('detectedDuplicates.hiddenInformation')}</Text>
				</Flex>
			</Flex>
		</Card>
	);
};

export default HiddenDuplicatesCard;
