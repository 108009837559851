import AppService from 'App/legacy/AppService';
import { RootState } from 'Store/index';
import Client from 'App/resources/Model/Client';

export type TestRecipient = {
	id: string;
	text: string;
};

class LocalStorage {
	AccountSelf: RootState['App']['accountSelf'];

	constructor() {
		this.AccountSelf = AppService.getAccountSelf();
	}

	getValue = <T>(key: string) => {
		const item = this.getValueRaw(key);
		return item ? (JSON.parse(item) as T) : null;
	};

	getValueRaw = (key: string) => {
		const client = this.AccountSelf?.client;
		const item = localStorage.getItem(`ls.${key}-${client?.id || ''}-${client?.userId || ''}`);

		return typeof item === 'string' ? item : null;
	};

	setValue = <T>(key: string, value: T) => {
		this.setValueRaw(key, JSON.stringify(value));
	};

	setValueRaw = (key: string, value: string) => {
		const client = this.AccountSelf?.client;

		localStorage.setItem(`ls.${key}-${client?.id || ''}-${client?.userId || ''}`, value);
	};

	getLatestAccounts = (opts = { onlyActive: false }) => {
		const accounts = this.getValue<Client[]>('latestAccounts') || [];
		return opts.onlyActive ? accounts.filter(account => account.active) : accounts;
	};

	getTestRecipients = () => this.getValue<TestRecipient[]>('testRecipients');

	setTestRecipients = (testRecipients: TestRecipient[]) => this.setValue('testRecipients', testRecipients);
}

export default LocalStorage;
