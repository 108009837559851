import { useTranslation } from 'Components/Helpers/translate';
import React from 'react';
import SocialEvent from 'App/resources/Model/SocialEvent';
import SocialEventResource from 'App/babel/resources/SocialEvents';
import GenericSelectEntityModal from '../GenericSelectEntityModal/GenericSelectEntityModal';
import type { ModalProps } from 'App/components/Modals/Modals';

type Props = ModalProps<SocialEvent>;

const FindSocialEventModal = (props: Props) => {
	const { t } = useTranslation();

	return (
		<GenericSelectEntityModal<SocialEvent>
			{...props}
			title={`${t('default.search')} ${t('default.socialEvent').toLowerCase()}`}
			displayAttr="name"
			fetch={filters => SocialEventResource.find(filters)}
		/>
	);
};

export default FindSocialEventModal;
