import Order, { OrderCustomField } from 'App/resources/Model/Order';
import OrderRow from 'App/resources/Model/OrderRow';
import { isEmpty, isEqual } from 'lodash';

type CustomFieldChanges = {
	id: number;
	value: OrderCustomField['value'];
	previousValue: OrderCustomField['value'];
};

type OrderRowChanges = {
	[key in keyof OrderRow]?: OrderRow[keyof OrderRow];
}[];

export const customFieldsChanged = (customFields: OrderCustomField[], previousCustomFields: OrderCustomField[]) => {
	const changes: CustomFieldChanges[] = [];
	customFields.forEach((customField, index: number) => {
		const value = customField.value;
		const previousValue = previousCustomFields?.[index]?.value ?? null;
		const bothFalsy = !value && !previousValue;
		if (!bothFalsy && !isEqual(value, previousValue)) {
			changes.push({ id: customField.id, value, previousValue });
		}
	});

	return changes.length ? changes : undefined;
};

const orderRowsChanged = (orderRows: OrderRow[], previousOrderRows: OrderRow[]) => {
	const changes: OrderRowChanges = [];
	orderRows.forEach((orderRow: any, index: number) => {
		const orderRowChanges: any = { id: orderRow.id, sortId: orderRow.sortId };
		Object.entries(orderRow).forEach(([key, value]) => {
			const previousValue = previousOrderRows[index]?.[key as keyof OrderRow] ?? undefined;
			if (key.startsWith('$')) {
				return;
			}
			if (key === 'custom') {
				const orderRowCustomChanges = customFieldsChanged(
					value as OrderCustomField[],
					previousValue as OrderCustomField[]
				);
				if (orderRowCustomChanges) {
					orderRowChanges[key] = orderRowCustomChanges;
				}
				return;
			}
			const bothFalsy = !value && !previousValue;
			if (!bothFalsy && !isEqual(value, previousValue)) {
				orderRowChanges[key] = { value, previousValue };
			}
		});

		const { id, sortId, ...restOfOrderRowChanges } = orderRowChanges;
		if (!isEmpty(restOfOrderRowChanges)) {
			changes.push(orderRowChanges);
		}
	});

	return changes.length ? changes : undefined;
};

const getOrderChanges = (order: Order, prevOrder: Order) => {
	const orderChanges = Object.entries(order).reduce((changes, orderKeyValue) => {
		const [key, value] = orderKeyValue;

		if (key.startsWith('$')) {
			return changes;
		}
		const previousValue = prevOrder?.[key as keyof Order];

		if (key === 'custom') {
			const customFieldChanges = customFieldsChanged(
				value as OrderCustomField[],
				previousValue as OrderCustomField[]
			);
			if (customFieldChanges) {
				changes['custom'] = customFieldChanges;
			}
			return changes;
		}

		if (key === 'orderRow') {
			const orderRowChanges = orderRowsChanged(value as OrderRow[], previousValue as OrderRow[]);
			if (orderRowChanges) {
				changes['orderRow'] = orderRowChanges;
			}
			return changes;
		}

		const bothFalsy = !value && !previousValue;
		if (!bothFalsy && !isEqual(value, previousValue)) {
			changes[key] = { value, previousValue };
		}
		return changes;
	}, {} as any);
	return isEmpty(orderChanges) ? undefined : orderChanges;
};

export default getOrderChanges;
