import PropTypes from 'prop-types';
import React from 'react';
import WidgetRowRender from './WidgetRowRender';
import './WidgetRender.scss';

const propTypes = {
	config: PropTypes.object.isRequired,
	name: PropTypes.string,
	object: PropTypes.object,
	type: PropTypes.string,
	runClick: PropTypes.func,
	getUser: PropTypes.func,
	integrations: PropTypes.object
};

class WidgetRender extends React.Component {
	constructor(props) {
		super(props);

		this.state = {
			integrations: props.integrations,
			isError: false,
			errorWidget: {},
			renderNothing: false,
			updated: {},
			hasUpdated: false
		};

		this.theWidget = this.theWidget.bind(this);
		this.runWidgetIntegration = this.runWidgetIntegration.bind(this);
		this.runStandardIntegrations = this.runStandardIntegrations.bind(this);
	}

	componentDidUpdate(prevProps) {
		if (prevProps.integrations !== this.props.integrations) {
			this.setState({
				integrations: this.props.integrations
			});
		}
		if ((!prevProps.object && this.props.object) || prevProps.object.id !== (this.props.object || {}).id) {
			this.runWidgetIntegration();
		}
	}

	runStandardIntegrations(data) {
		Tools.StandardIntegration.data(Tools.AppService.getCustomerId())
			.run(data)
			.then(response => {
				this.setState({
					integrations: response.data,
					hasUpdated: true
				});
			})
			.catch(e => console.log('runStandardIntegrations failed', e));
	}

	getIcon(icon) {
		if (icon.indexOf('http') === 0) {
			return <img key="app-icon" className="integration-icon" src={icon} />;
		}

		return <span key="app-icon">{icon}</span>;
	}

	theWidget(config, name, object, data) {
		const widget = (data.rows ?? []).map((row, rowIndex) => {
			return React.createElement(WidgetRowRender, {
				config,
				name,
				object,
				row,
				key: `${name}-${rowIndex}`,
				getUser: this.props.getUser,
				runStandardIntegrations: this.runStandardIntegrations,
				runClick: (data, config, optionIndex) => {
					if (Tools.FeatureHelper.hasSoftDeployAccess('APP_FRAMEWORK_UPDATE_DATA_ON_CLICK')) {
						return this.runWidgetIntegration().then(res => {
							this.props.runClick(res.data.rows[rowIndex].options[optionIndex], config);
						});
					} else {
						return this.props.runClick(data, config);
					}
				}
			});
		});

		if (data.icon) {
			const icon = this.getIcon(data.icon);
			widget.unshift(icon);
		}

		return widget;
	}

	runWidgetIntegration() {
		this.setState({
			loading: true
		});

		const { object, config, name, type } = this.props;

		var data = {
			type: 'widget',
			data: {
				object: object,
				user: this.props.getUser(),
				widgetType: config.widgetType
			},
			typeId: name,
			integrationId: config.integrationId
		};

		return Tools.StandardIntegration.data(Tools.AppService.getCustomerId())
			.run(data)
			.then(result => {
				this.setState({
					integrations: result.data,
					loading: false
				});
				return result;
			})
			.catch(error => {
				let errorWidget;

				if (type !== 'modal') {
					return this.setState({
						renderNothing: true
					});
				}

				if (error.data && error.data.error === 404) {
					errorWidget = {
						rows: [
							{
								type: 'text',
								text: Tools.$translate('integration.widgetNoMoreInfo')
							}
						]
					};
				} else {
					errorWidget = {
						rows: [
							{
								type: 'buttons',
								options: [
									{
										text:
											config.integrationName +
											Tools.$translate('integration.trouble') +
											' ' +
											Tools.$translate('integration.troubleRetry'),
										color: 'grey',
										link: true,
										click: {
											type: 'widget',
											name: name
										}
									}
								]
							},
							{
								type: 'buttons',
								options: [
									{
										text: Tools.$translate('integration.checkStatusAndRetry'),
										link: true,
										color: 'grey',
										click: {
											type: 'navigate',
											to: 'config',
											tab: 'support'
										}
									}
								]
							}
						]
					};
				}

				this.setState({
					errorWidget,
					isError: true,
					loading: false
				});
			});
	}

	componentDidMount() {
		this.runWidgetIntegration();
	}

	render() {
		const { object, config, name } = this.props;
		const widgets = this.theWidget(config, name, object, this.state.integrations);

		if (this.state.renderNothing) {
			return <div />;
		}

		const rowClassNames = {
			'app-icon': 'icon-row'
		};

		return this.state.loading ? (
			<div className="widget-row">
				<div className="UiElement__Widget--loading">
					<i className="fa fa-circle-o-notch fa-spin" />
				</div>
			</div>
		) : (
			<div>
				{widgets.map((widget, widgetIndex) => {
					return (
						<div
							className={rowClassNames[widget.key] ? rowClassNames[widget.key] : 'widget-row'}
							key={`widget-${widgetIndex}`}
						>
							{widget}
						</div>
					);
				})}
			</div>
		);
	}
}

WidgetRender.propTypes = propTypes;
export default WidgetRender;
