import React, { useEffect, useRef, useState } from 'react';
import { Text, Tooltip } from '@upsales/components';
import './TooltipOverflowText.scss';
import BemClass from '@upsales/components/Utils/bemClass';

interface Props extends React.ComponentProps<typeof Text> {
	tooltipProps: React.ComponentProps<typeof Tooltip>;
}

/** @deprecated: Use import { EllipsisTooltip } from '@upsales/components'; instead **/
const TooltipOverflowText = ({ tooltipProps, ...props }: Props) => {
	const titleRef = useRef<HTMLDivElement>(null);
	const [isOverflowed, setIsOverflowed] = useState(false);
	const classes = new BemClass('TooltipOverflowText');
	const T = React.forwardRef<HTMLDivElement>(({ children, ...props }, ref) => (
		<Text {...props}>
			<p className={classes.elem('inner-text').b()} ref={ref}>
				{children}
			</p>
		</Text>
	));

	useEffect(() => {
		if (titleRef.current) {
			setIsOverflowed(titleRef.current.scrollWidth > titleRef.current.offsetWidth);
		}
	}, [titleRef.current]);

	return (
		<div className={classes.b()}>
			<Tooltip {...tooltipProps} disabled={tooltipProps.disabled ?? !isOverflowed}>
				<T {...props} ref={titleRef} />
			</Tooltip>
		</div>
	);
};
export default TooltipOverflowText;
