import React, { useState } from 'react';
import ReportcenterFilter from './ReportcenterFilter';
import { Block } from '@upsales/components';
import bemClass from '@upsales/components/Utils/bemClass';
import T from 'Components/Helpers/translate';
import './ReportcenterFilterAccountManager.scss';
import ReportcenterFilterRow from './ReportcenterFilterRow';
import { removeItem } from 'Store/helpers/array';
import { RCDashboardFilter } from 'Resources/ReportDashboard';
import ReportcenterFilterSearchbar from './ReportcenterFilterSearchbar';
import User from 'App/resources/Model/User';

interface Props {
	onChange: (type: keyof RCDashboardFilter, values: Array<number | 'null'>, comparison: string) => void;
	values: RCDashboardFilter;
	updateFilterHeight: () => void;
}

export const renderSelected = (values: RCDashboardFilter, returnCount: boolean = true) => {
	let res = `${T('default.accountManagers')}: `;

	if (!returnCount) {
		const userNames = Tools.AppService.getUsers()
			.filter(user => {
				return values?.AccountManager?.value?.includes?.(user.id);
			})
			.map(user => user.name);
		if (values?.AccountManager?.value?.includes?.('null')) {
			userNames.unshift(T('default.noAccountManager'));
		}
		res += userNames.join(', ');
		return res;
	}

	if (values?.AccountManager?.value?.length) {
		const length = values.AccountManager.value.length;
		if (length >= 1) {
			res += `${length} ${(values.AccountManager.comparison === 'eq'
				? T('default.selected')
				: T('default.excluded', { count: length })
			).toLowerCase()}`;
		}
	}
	return res;
};

const ReportcenterFilterAccountManager = ({ onChange, ...props }: Props) => {
	const classes = new bemClass('ReportcenterFilterAccountManager');
	const [searchStr, setSearchStr] = useState('');
	const users = Tools.AppService.getActiveUsers();
	const array = users.filter(item => item.name.toLowerCase().indexOf(searchStr.toLowerCase()) !== -1) as Array<
		Partial<Omit<User, 'id'>> & { id: number | 'null'; name: string }
	>;
	array.unshift({ id: 'null', name: T('default.noAccountManager') });
	const value = props.values?.AccountManager?.value || [];
	const comparison = props.values?.AccountManager?.comparison ?? 'eq';
	const isExclude = comparison === 'ne';

	return (
		<ReportcenterFilter
			className={classes.b()}
			renderSelected={() => renderSelected(props.values)}
			getSelectedNames={() =>
				[
					value.includes('null') ? T('default.noAccountManager') : null,
					...users.filter(u => value.includes(u.id)).map(u => u.name)
				].filter(Boolean) as string[]
			}
			icon="account-manager"
			placeholder={`${T('default.accountManagers')}: ${T('reportcenter.filter.notActive')}`}
			value={value}
			resetFilter={() => onChange('AccountManager', [], 'eq')}
			{...props}
		>
			<Block space="ptm prm pbm plm" backgroundColor="white" className={classes.elem('list-select').b()}>
				<Block space="mtm">
					<ReportcenterFilterSearchbar<Array<number | 'null'>>
						setSearchStr={value => setSearchStr(value)}
						searchStr={searchStr}
						placeholder={`${T('default.search')} ${T('default.accountManagers').toLowerCase()}`}
						isExclude={isExclude}
						onChange={onChange}
						field="AccountManager"
						value={value}
					/>
				</Block>
			</Block>
			<ReportcenterFilterRow
				key={0}
				onClick={() => {
					onChange('AccountManager', [], 'eq');
				}}
				selected={!value.length}
				title={T('filters.noFilter')}
			/>
			{array.map(row => (
				<ReportcenterFilterRow
					key={row.id}
					onClick={() => {
						const i = value.indexOf(row.id);
						onChange('AccountManager', i !== -1 ? removeItem(value, i) : [...value, row.id], comparison);
					}}
					selected={value.indexOf(row.id) !== -1}
					title={row.name}
				/>
			))}
		</ReportcenterFilter>
	);
};

export default ReportcenterFilterAccountManager;
