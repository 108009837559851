import Resource from './Resource';

class DesiredOutcomeFeedback extends Resource {
	constructor() {
		super('desiredoutcomefeedback');
		this.dateFields = ['modDate', 'regDate'];
	}
}

const resource = new DesiredOutcomeFeedback();

export default resource;
