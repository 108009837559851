import { getAvailableCountries } from 'App/services/prospectingService';
import ValidationService from 'Services/ValidationService';
import Prospecting from 'App/babel/resources/Prospecting';
import logError from 'App/babel/helpers/logError';
import _ from 'lodash';

const TypeAhead = require('../../../services/TypeAhead').default;

export const getIntegrations = ({
	account,
	integrationFilter,
	onlyNordic,
	onlyProspecting
}: {
	account: any;
	integrationFilter?: any;
	onlyNordic?: boolean;
	onlyProspecting?: boolean;
}) => {
	let sortId = 1;
	const metadata = Tools.AppService.getMetadata();
	const hasProspecting = Tools.FeatureHelper.isAvailable(Tools.FeatureHelper.Feature.PROSPECTING_BASIC);
	const dataSources = metadata.integrations.inits.dataSource || [];

	var countryCodes = metadata.params.DnBCountryCodes;
	var validCountries =
		!countryCodes || !countryCodes.length || countryCodes === 'null'
			? ['SE', 'NO', 'DK', 'FI']
			: countryCodes.split(',');
	var user = Tools.AppService.getSelf();

	if (!Tools.FeatureHelper.isAvailable(Tools.FeatureHelper.Feature.SOLIDITET_MULTI_MARKET)) {
		var dnbCountryCodes = user.userParams.dnbCountryCodes;

		if (Array.isArray(dnbCountryCodes) && dnbCountryCodes.length) {
			validCountries = dnbCountryCodes;
		} else if (typeof dnbCountryCodes === 'string' && dnbCountryCodes.length) {
			validCountries = dnbCountryCodes.split(',');
		} else {
			validCountries = ['SE'];
		}
	}

	const prospectingConfig = {
		id: 'prospecting',
		sortId: sortId++,
		idField: 'prospectingId',
		match: null,
		type: 'prospecting',
		name: 'Prospecting',
		logo: '/img/u_square_logo.png',
		metadata: {},
		countries: getAvailableCountries(),
		countrySelect: false,
		countryTabs: {
			enabled: false,
			showAll: false,
			showTotals: false
		},
		pagination: { enabled: true },
		pricing: null,
		waitTime: 300,
		fields: [
			{
				field: 'name',
				header: 'default.account',
				width: '45%',
				type: 'nameProspecting'
			},
			{
				type: 'string',
				field: 'orgNumber',
				header: 'default.orgNumberShort',
				width: '30%'
			},
			{
				type: 'add',
				field: 'add',
				width: '25%'
			}
		],
		country: null as string | null
	};

	if (prospectingConfig.countries.length > 1) {
		prospectingConfig.countryTabs.enabled = true;
		prospectingConfig.countryTabs.showTotals = true;
		prospectingConfig.countrySelect = true;
		const countryAddress = account.addresses?.find(
			(a: any) => prospectingConfig.countries.indexOf(a.country ?? '') > -1
		);
		prospectingConfig.country = countryAddress ? countryAddress.country : prospectingConfig.countries[0];
	} else {
		prospectingConfig.country = prospectingConfig.countries[0];
	}

	//bisnode norden
	const nordicBisnode = {
		id: 'soliditet',
		sortId: sortId++,
		idField: 'dunsNo',
		match: {
			upsales: 'dunsNo',
			integration: 'dunsNo'
		},
		type: 'soliditet',
		dataType: 'soliditet',
		name: 'default.soliditet',
		logo: '/img/bisnode_square_logo.png',
		metadata: {},
		countries: validCountries,
		countrySelect: false,
		country: null,
		countryTabs: {
			enabled: true,
			showAll: true
		},
		pagination: { enabled: true },
		pricing: null,
		fields: [
			{
				field: 'name',
				header: 'default.account',
				width: '45%',
				type: 'nameSoliditet'
			},
			{
				type: 'string',
				field: 'orgNo',
				header: 'default.orgNumberShort',
				width: '30%'
			},
			{
				type: 'add',
				field: 'add',
				width: '25%'
			}
		]
	};

	if (onlyNordic === true) {
		return [nordicBisnode];
	}

	const integrations = dataSources
		.filter(integration => {
			return integration.capabilities && integration.capabilities.refresh;
		})
		.map(integration => {
			const state = {
				loading: false,
				isLoadingMore: false,
				sortId: sortId++,
				data: [],
				metadata: {},
				currentTab: 'ALL',
				offset: 0
			};

			const defaultConfig = {
				idField: 'id',
				logo: '/img/icon_globe.png',
				fields: [{ field: 'name', header: 'default.account', width: '100%', type: 'string' }],
				countryTabs: { enabled: false },
				countries: null,
				countrySelect: false,
				country: null as string | null,
				match: null,
				pricing: null
			};

			if (account && account.addresses) {
				const addressWithCountry = account.addresses.find((a: any) => a.country);
				defaultConfig.country =
					addressWithCountry && addressWithCountry.country ? addressWithCountry.country : null;
			}

			const config = {
				id: integration.id,
				type: 'integration',
				dataType: integration.name.toLowerCase(),
				name: integration.name,
				idField: _.get(integration, 'capabilities.typeahead.idField'),
				logo: _.get(integration, 'capabilities.logo'),
				fields: _.get(integration, 'capabilities.refresh.columns'),
				countryTabs: _.get(integration, 'capabilities.typeahead.countryTabs'),
				countries: _.get(integration, 'capabilities.countries'),
				countrySelect: _.get(integration, 'capabilities.refresh.countrySelect'),
				match: _.get(integration, 'capabilities.match'),
				typeahead: _.get(integration, 'capabilities.typeahead'),
				pricing: _.get(integration, 'capabilities.pricing')
			};

			const dataSource = Object.assign(state, defaultConfig, config);
			return dataSource;
		});

	if (hasProspecting) {
		integrations.unshift(prospectingConfig as any);
	}

	const hasSoliditet = Tools.AppService.getSelf().userParams.soliditetIsActive;
	if (hasSoliditet && !onlyProspecting) {
		integrations.unshift(nordicBisnode as any);
	}

	if (integrationFilter) {
		return integrations.filter(integration => integrationFilter.includes(integration.id));
	} else {
		return integrations;
	}
};

export const makeSearch = ({
	dataSource,
	duns,
	account,
	limit
}: {
	dataSource: any;
	account: any;
	duns?: string;
	limit?: number;
}) => {
	let searchString = (account.orgNo || account.name) as string | number;
	let searchField = 'name';

	if (dataSource.id === 'soliditet') {
		if (duns && duns.length && parseNumber(duns) !== -1 && !isNaN(parseNumber(duns))) {
			searchString = parseNumber(duns);
			searchField = 'dunsNo';
		} else if (account.orgNo && !isNaN(parseNumber(account.orgNo))) {
			searchString = parseNumber(account.orgNo);
			searchField = 'orgNo';
		} else {
			searchString = account.name;
			searchField = 'name';
		}
	} else if (dataSource.id === 'prospecting') {
		({ searchString, searchField } = getSearchCriteriaProspecting(dataSource, account));
	} else if (dataSource.typeahead && dataSource.typeahead.searchField) {
		searchString = account[dataSource.typeahead.searchField].toLowerCase();
	}

	const typeahead = new TypeAhead();
	dataSource.offset = 0;
	dataSource.loading = true;

	return typeahead
		.search({ searchField, searchString, dataSource, fetchMetadata: true, limit })
		.then((response: { data: any; metadata: any }) => {
			dataSource.loading = false;
			dataSource.data = response.data;
			dataSource.resMetadata = response.metadata;
			return dataSource;
		})
		.catch((err: any) => {
			logError(err, 'RefreshAccount - makeSearch - failed');
			dataSource.loading = true;
			dataSource.data = [];
			return dataSource;
		});
};

function parseNumber(input: string) {
	return parseInt(input.replace(/\D/g, ''));
}

function getSearchCriteriaProspecting(dataSource: any, account: any) {
	const country = Prospecting.getCountryFromProspectingId(account.prospectingId);
	const validCountry = dataSource.countries.includes(country);

	if (validCountry) {
		return {
			searchString: account.prospectingId,
			searchField: 'prospectingId'
		};
	}

	const isValidOrgNumber = ValidationService.validateOrgNumber(account.orgNo, dataSource.country);

	if (isValidOrgNumber) {
		return {
			searchString: account.orgNo,
			searchField: 'orgNumber'
		};
	}

	return {
		searchString: account.name,
		searchField: 'name'
	};
}
