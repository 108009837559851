import React, { useMemo } from 'react';
import {
	StaticSelect,
	StaticMultiSelect,
	type StaticSelectConsumerProps,
	type StaticMultiSelectConsumerProps
} from '../StaticSelect';
import { useTranslation } from 'Components/Helpers/translate';
import type { AppState } from 'Store/reducers/AppReducer';
import type Category from 'App/resources/Model/Category';
import useSelector from 'App/components/hooks/useSelector/useSelector';

type Props = {
	categoryType: keyof AppState['categories'];
};

const useAppCategories = (type: Props['categoryType']) => {
	const categories = useSelector(({ App }) => App.categories[type]);
	return useMemo(() => {
		return (
			categories?.map(user => ({
				...user,
				title: user.name
			})) ?? []
		);
	}, [type]);
};

const useSharedProps = (categoryType: Props['categoryType']) => {
	const { t } = useTranslation();
	const placeholder = t('category.chooseCategory');
	const options = useAppCategories(categoryType);
	return { placeholder, options };
};

export const StaticCategorySelect = ({ categoryType, ...props }: Props & StaticSelectConsumerProps<Category>) => {
	const sharedProps = useSharedProps(categoryType);
	return <StaticSelect {...sharedProps} {...props} />;
};

export const StaticCategoryMultiSelect = ({
	categoryType,
	...props
}: Props & StaticMultiSelectConsumerProps<Category>) => {
	const sharedProps = useSharedProps(categoryType);
	return <StaticMultiSelect {...sharedProps} {...props} />;
};
