import ProductResource from 'App/babel/resources/Product';
import logError from 'App/babel/helpers/logError';
import { type BundleRow, type OrderRow } from 'App/components/OrderRows/Context/OrderContext';
import updateCustomFields from './customFieldUpdater';
import { has, isEmpty } from 'lodash';
import { useCustomFields } from '../../appHooks';

type EntityCustomFields = ReturnType<typeof useCustomFields>;

export const getProduct = async (productId: number) => {
	try {
		const { data: product } = await ProductResource.get(productId);
		return product;
	} catch (err) {
		logError(err, `orderRowUpdater:getProduct:${productId}`);
		return null;
	}
};

export const updateBundleRows = (orderRowProduct: OrderRow['product'], updatedBundleRows: BundleRow[] = []) => {
	if (!orderRowProduct) {
		return undefined;
	}

	orderRowProduct.bundle = orderRowProduct.bundle.map(existingBundleRow => {
		const matchedBundleRow = updatedBundleRows.find(updatedBundleRow => {
			const productIdMatch =
				(updatedBundleRow.productId ?? undefined) !== undefined &&
				(existingBundleRow.productId ?? undefined) !== undefined &&
				updatedBundleRow.productId === existingBundleRow.productId;
			return productIdMatch;
		});

		if (!matchedBundleRow) {
			return existingBundleRow;
		}

		if (has(matchedBundleRow, 'quantity')) {
			existingBundleRow.quantity = matchedBundleRow.quantity;
			existingBundleRow.tierQuantity = matchedBundleRow.quantity;
		}

		if (matchedBundleRow.product) {
			if (existingBundleRow.product) {
				existingBundleRow.product = {
					...existingBundleRow.product,
					...matchedBundleRow.product
				};
			} else {
				existingBundleRow.product = matchedBundleRow.product;
			}
		}
		return existingBundleRow;
	});

	return orderRowProduct;
};

export type OrderRowUpdates = Partial<
	Pick<OrderRow, 'price' | 'discount' | 'discountPercent' | 'quantity' | 'purchaseCost' | 'custom' | 'product'>
> & {
	bundleProduct?: OrderRow['product'];
};

export type OrderRowUpdatesWithOpts = OrderRowUpdates & {
	opts: {
		currency: string;
	};
};

const updateOrderRows = async (
	existingOrderRows: OrderRow[] = [],
	updatedOrderRows: Partial<OrderRow>[] = [],
	orderRowCustomFields: EntityCustomFields,
	currency: string,
	onOrderRowUpdate: (uuid: OrderRow['uuid'], updates: OrderRowUpdatesWithOpts) => void,
	opts: {
		hasContributionMargin: boolean;
	}
) => {
	const orderRows = [...(existingOrderRows || [])];

	for (const updatedRow of updatedOrderRows) {
		const foundOrderRow = orderRows.find(row => {
			const uuidMatch = row.uuid && row.uuid === updatedRow.uuid;
			const idMatch = row.id && row.id === updatedRow.id;
			const sortIdMatch = row.sortId === updatedRow.sortId;
			return uuidMatch || idMatch || sortIdMatch;
		});

		if (!foundOrderRow) {
			continue;
		}

		const updates: OrderRowUpdates = {};

		const productChanged = !!updatedRow.product && updatedRow.product?.id !== foundOrderRow.product?.id;
		const productIdChanged = !!updatedRow.productId && updatedRow.productId !== foundOrderRow.productId;

		let bundleRowUpdatedFromProduct = false;

		if (productChanged || productIdChanged) {
			const productId = updatedRow.product?.id ?? updatedRow.productId;
			if (productId) {
				const foundProduct = await getProduct(productId);
				if (foundProduct) {
					updates.product = foundProduct;
					const bundleProduct = updateBundleRows(foundProduct, updatedRow.bundleRows);
					if (bundleProduct) {
						updates.bundleProduct = bundleProduct;
						bundleRowUpdatedFromProduct = true;
					}
				}
			}
		}

		if (has(updatedRow, 'bundleRows') && !bundleRowUpdatedFromProduct) {
			const bundleProduct = updateBundleRows(foundOrderRow.product, updatedRow.bundleRows);
			if (bundleProduct) {
				updates.bundleProduct = bundleProduct;
			}
		}

		if (has(updatedRow, 'discount')) {
			updates.discount = updatedRow.discount ?? 0;
		}

		if (has(updatedRow, 'discountPercent')) {
			updates.discountPercent = updatedRow.discountPercent ?? 0;
		}

		if (updatedRow.custom) {
			const updatedCustomFields = updateCustomFields(foundOrderRow, updatedRow.custom, orderRowCustomFields);
			if (updatedCustomFields) {
				updates.custom = updatedCustomFields;
			}
		}

		if (has(updatedRow, 'price')) {
			updates.price = updatedRow.price ?? 0;
		}

		if (has(updatedRow, 'quantity')) {
			updates.quantity = updatedRow.quantity ?? 0;
		}

		if (opts.hasContributionMargin && has(updatedRow, 'purchaseCost')) {
			updates.purchaseCost = updatedRow.purchaseCost ?? 0;
		}

		if (!isEmpty(updates)) {
			const updatesWithOpts: OrderRowUpdatesWithOpts = { ...updates, opts: { currency } };
			onOrderRowUpdate(foundOrderRow.uuid, updatesWithOpts);
		}
	}
	return orderRows;
};

export default updateOrderRows;
