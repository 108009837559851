import Resource from './Resource';

class CustomerLanguage extends Resource {
	constructor() {
		super('language');
	}
}

const resource = new CustomerLanguage();

window.Tools.CustomerLanguage = resource;

export default resource;
