import { useTranslation } from 'Components/Helpers/translate';
import { Block, Icon, Tooltip } from '@upsales/components';
import React from 'react';

const LockIcon = () => {
	const { t } = useTranslation();

	return (
		<Block space="prxl">
			<Tooltip title={t('subscription.indexing.lockIcon.tooltip')}>
				<Icon name="lock" color="grey-10" />
			</Tooltip>
		</Block>
	);
};

export default LockIcon;
