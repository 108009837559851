import { notesFilterTracker } from 'App/babel/helpers/Tracker';
import Analytics from 'App/services/Analytics';

import { createSlice, PayloadAction } from '@reduxjs/toolkit';

let initialized = false;

const notesFilterActiveStorageKey = 'notesFilterActive';

const getInitial = () => {
	if (localStorage.getItem(notesFilterActiveStorageKey) === null) {
		localStorage.setItem(notesFilterActiveStorageKey, 'true');
		return true;
	}
	return localStorage.getItem(notesFilterActiveStorageKey) === 'true';
};

const initNotesFilterActiveTracking = (notesFilterActive: boolean) => {
	notesFilterTracker.set(notesFilterTracker.events.NOTES_FILTER_ACTIVE, notesFilterActive);
};

const notesSlice = createSlice({
	name: 'NotesFilterActive',
	initialState: () => getInitial(),
	reducers: {
		toggleNotesFilter: (state, action: PayloadAction<boolean>) => {
			if (!initialized && Analytics.isEnabledAndLoaded(true)) {
				initialized = true;
				initNotesFilterActiveTracking(state);
			}
			notesFilterTracker.set(notesFilterTracker.events.NOTES_FILTER_ACTIVE, action.payload);
			localStorage.setItem(notesFilterActiveStorageKey, JSON.stringify(action.payload));
			return action.payload;
		}
	}
});

const { actions, reducer } = notesSlice;
export const { toggleNotesFilter } = actions;
export default reducer;
