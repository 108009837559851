import React from 'react';
import ProvisioningResource from 'App/babel/resources/Provisioning';
import BillingChangelogResource from 'App/babel/resources/BillingChangelog';
import Resource from 'App/babel/resources/Resource';
import logError from 'App/babel/helpers/logError';
import { AnyAction } from 'redux';
import { AppThunk } from '..';
import { Addon, BillingHistoryRow, TrialAddon } from 'Components/Billing/Addon';
import { Subscription } from 'Components/Billing/Subscription';
import { LicenceInfo } from 'Components/Billing/LicenceInfo';
import _ from 'lodash';
import RequestBuilder from 'Resources/RequestBuilder';
import ComparisonTypes from 'Resources/ComparisonTypes';
import moment from 'moment';
import SystemMail from 'Resources/SystemMail';
import { addNotification, STYLE as NotificationStyle } from './SystemNotificationReducer';
import { AccountSelf, Metadata } from 'App/resources/AllIWant';

const ContactResource = new Resource('contacts');
const UserResource = new Resource('users');

type BillingAddress = {
	address: string;
	city: string;
	zipcode: string;
	email: string;
	country: string;
	invoiceDeliveryType: 'mail' | 'email';
	loading: boolean;
};

type State = {
	accountManager: {} | AccountSelf['accountManager'];
	billingAddress: {} | BillingAddress;
	lang: { [key: string]: string };
	loading: boolean;
	loadingAddons: boolean;
	loadingSeatInfo: boolean;
	invoiceSubscriptions: Array<Subscription>;
	licenceInfo: {} | LicenceInfo;
	creditsToBuy: string;
	statements: [];
	addons: Array<Addon>;
	trialAddons: Array<TrialAddon>; // Should contain all trial addons (including expired)
	ongoingTrialAddons: { [key: string]: TrialAddon }; // should contain ongoing trial addons
	trialPeriod: number;
	availableAddons: Array<Addon>;
	buyingAddon: null | Addon;
	buyingTrialAddon: null | Addon;
	drilldown: number;
	contactCount: number;
	userCount: number;
	supportUserCount: number;
	maxLicences: number;
	maxSupportLicences: number;
	showCreditPurchaseCard: null | keyof Metadata['credits'];
	buyingCredits: boolean;
	seatChangeInfo:
		| {
				newSubscription: undefined | Subscription;
				newLicenceInfo: undefined | { seatRows: { quantity: number; price: number }[] };
				diffOrder: undefined | { totalValue: number };
		  }
		| undefined;
	credits: {
		bisnode: number;
		advertising: number;
		email: number;
	};
	creditsPrices: {
		bisnode: number;
	};
	editAddress: null | BillingAddress;
	selectedTab: 'overview' | 'addons';
	activeLicense: {} | Subscription;
	futureLicense: {} | null | Subscription;
	activeSubscriptions: Array<Subscription>;
	futureSubscriptions: Array<Subscription>;
	billingHistory: Array<BillingHistoryRow>;
	billingHistoryTotal: number;
	billingHistoryOffset: number;
	billingHistoryLimit: number;
	retrieveDataFailed: boolean;
};

export const initialState: State = {
	accountManager: {},
	billingAddress: {},
	lang: {},
	loading: true,
	loadingAddons: true,
	loadingSeatInfo: false,
	invoiceSubscriptions: [],
	licenceInfo: {},
	creditsToBuy: '500',
	statements: [],
	addons: [],
	trialAddons: [],
	ongoingTrialAddons: {},
	trialPeriod: 7,
	availableAddons: [],
	buyingAddon: null,
	buyingTrialAddon: null,
	drilldown: 0,
	contactCount: 0,
	userCount: 0,
	supportUserCount: 0,
	maxLicences: 0,
	maxSupportLicences: 0,
	showCreditPurchaseCard: null,
	buyingCredits: false,
	seatChangeInfo: undefined,
	credits: {
		bisnode: 0,
		email: 0,
		advertising: 0
	},
	creditsPrices: {
		bisnode: 0
	},
	editAddress: null,
	selectedTab: 'overview',
	activeLicense: {},
	futureLicense: {},
	billingHistory: [],
	billingHistoryTotal: 0,
	billingHistoryOffset: 0,
	billingHistoryLimit: 10,
	activeSubscriptions: [],
	futureSubscriptions: [],
	retrieveDataFailed: false
};

const CLEAR_STATE = '[Billing] CLEAR_STATE';
const SET_LANGUAGE = '[Billing] SET_LANGUAGE';
const SET_DATA = '[Billing] SET_DATA';
const TOGGLE_DRILLDOWN = '[Billing] TOGGLE_DRILLDOWN';
const SET_SHOW_CREDIT_PURCHASE_CARD = '[Billing] SET_SHOW_CREDIT_PURCHASE_CARD';
const CREDITS_TO_BUY = '[Billing] CREDITS_TO_BUY';
const SET_ADDONS = '[Billing] SET_ADDONS';
const SET_LOADING = '[Billing] SET_LOADING';
const SET_LOADING_ADDONS = '[Billing] SET_LOADING_ADDONS';
const SET_LOADING_SEAT_INFO = '[Billing] SET_LOADING_SEAT_INFO';
const SET_BUYING_CREDITS = '[Billing] SET_BUYING_CREDITS';
const SET_CREDITS = '[Billing] SET_CREDITS';
const SET_LICENSES = '[Billing] SET_LICENSES';
const SET_EDIT_ADDRESS = '[Billing] SET_EDIT_ADDRESS';
const SET_BILLING_ADDRESS = '[Billing] SET_BILLING_ADDRESS';
const SET_SELECTED_TAB = '[Billing] SET_SELECTED_TAB';
const SET_AVAILABLE_ADDONS = '[Billing] SET_AVAILABLE_ADDONS';
const SET_SEAT_CHANGE_INFO = '[Billing] SET_SEAT_CHANGE_INFO';
const SET_BUYING_ADDON = '[Billing] SET_BUYING_ADDON';
const SET_BUYING_TRIAL_ADDON = '[Billing] SET_BUYING_TRIAL_ADDON';
const SET_INVOICE_SUBSCRIPTIONS = '[Billing] SET_INVOICE_SUBSCRIPTIONS';
const SET_FUTURE_SUBSCRIPTIONS = '[Billing] SET_FUTURE_SUBSCRIPTIONS';
const SET_ACTIVE_LICENSE = '[Billing] SET_ACTIVE_LICENSE';
const SET_FUTURE_LICENSE = '[Billing] SET_FUTURE_LICENSE';
const SET_TRIAL_ADDONS = '[Billing] SET_TRIAL_ADDONS';
const SET_BILLING_HISTORY = '[Billing] SET_BILLING_HISTORY';
const SET_BILLING_HISTORY_TOTAL = '[Billing] SET_BILLING_HISTORY_TOTAL';
const SET_BILLING_HISTORY_OFFSET = '[Billing] SET_BILLING_HISTORY_OFFSET';
const RETRIEVE_DATA_FAILED = '[Billing] RETRIEVE_DATA_FAILED';

const filterOutOngoingTrials = (trialAddons: TrialAddon[]) => {
	const activeTrials = trialAddons.filter((trial: TrialAddon) => moment(trial.endDate).isAfter(moment()));
	return activeTrials.reduce<State['ongoingTrialAddons']>((res, trialAddon) => {
		res[trialAddon.alias] = trialAddon;
		return res;
	}, {});
};

const reducer = (state = initialState, action: AnyAction) => {
	switch (action.type) {
		case CLEAR_STATE:
			return { ...initialState };
		case SET_LANGUAGE:
			return { ...state, lang: action.value };
		case SET_LOADING_ADDONS:
			return { ...state, loadingAddons: action.value };
		case SET_LOADING_SEAT_INFO:
			return { ...state, loadingSeatInfo: action.value };
		case SET_SEAT_CHANGE_INFO:
			return { ...state, seatChangeInfo: action.value };
		case SET_SHOW_CREDIT_PURCHASE_CARD:
			return { ...state, showCreditPurchaseCard: action.value };
		case CREDITS_TO_BUY:
			return { ...state, creditsToBuy: action.value };
		case TOGGLE_DRILLDOWN:
			return { ...state, drilldown: action.value };
		case SET_ADDONS:
			return { ...state, addons: action.value as State['addons'] };
		case SET_AVAILABLE_ADDONS:
			return { ...state, availableAddons: action.value || ([] as State['availableAddons']) };
		case SET_LOADING:
			return { ...state, loading: action.value };
		case SET_BUYING_CREDITS:
			return { ...state, buyingCredits: action.value };
		case SET_BUYING_ADDON:
			return { ...state, buyingAddon: action.buyingAddon };
		case SET_BUYING_TRIAL_ADDON:
			return { ...state, buyingTrialAddon: action.buyingTrialAddon };
		case SET_CREDITS:
			return { ...state, credits: action.value };
		case SET_LICENSES:
			return { ...state, maxLicences: action.value };
		case SET_EDIT_ADDRESS:
			return { ...state, editAddress: action.value };
		case SET_BILLING_ADDRESS:
			return { ...state, billingAddress: action.value };
		case SET_SELECTED_TAB:
			return { ...state, selectedTab: action.value };
		case SET_INVOICE_SUBSCRIPTIONS:
			return { ...state, invoiceSubscriptions: action.value };
		case SET_FUTURE_SUBSCRIPTIONS:
			return { ...state, futureSubscriptions: action.value };
		case SET_ACTIVE_LICENSE:
			return { ...state, activeLicense: action.value };
		case SET_FUTURE_LICENSE:
			return { ...state, futureLicense: action.value };
		case SET_TRIAL_ADDONS: {
			const trialAddons = (action.value as State['trialAddons']) || [];
			return {
				...state,
				trialAddons,
				ongoingTrialAddons: filterOutOngoingTrials(trialAddons)
			};
		}
		case SET_BILLING_HISTORY:
			return { ...state, billingHistory: action.value };
		case SET_BILLING_HISTORY_TOTAL:
			return { ...state, billingHistoryTotal: action.value };
		case SET_BILLING_HISTORY_OFFSET:
			return { ...state, billingHistoryOffset: action.value };
		case RETRIEVE_DATA_FAILED:
			return { ...state, retrieveDataFailed: action.value };
		case SET_DATA:
			return {
				...state,
				accountManager: action.value.accountManager,
				billingAddress: action.value.billingAddress,
				invoiceSubscriptions: action.value.invoiceSubscriptions,
				licenceInfo: action.value.licenceInfo,
				trialAddons: (action.value as Partial<State>).trialAddons || [],
				ongoingTrialAddons: filterOutOngoingTrials((action.value as Partial<State>).trialAddons || []),
				loading: action.value.loading,
				userCount: action.value.userCount,
				supportUserCount: action.value.supportUserCount,
				contactCount: action.value.contactCount,
				maxLicences: action.value.maxLicences,
				maxSupportLicences: action.value.maxSupportLicences,
				credits: action.value.credits,
				creditsPrices: action.value.creditsPrices,
				activeLicense: action.value.activeLicense,
				futureLicense: action.value.futureLicense,
				billingHistory: action.value.billingHistory,
				billingHistoryTotal: action.value.billingHistoryTotal,
				billingHistoryOffset: action.value.billingHistoryOffset,
				activeSubscriptions: action.value.activeSubscriptions,
				futureSubscriptions: action.value.futureSubscriptions,
				retrieveDataFailed: action.value.retrieveDataFailed
			};
		default:
			return state;
	}
};

export default reducer;

export const clearState = () => ({ type: CLEAR_STATE });

export const toggleDrilldown = (value: number): AppThunk => {
	return (dispatch, getState) => {
		if (getState().Billing.drilldown === value) {
			value = 0;
		}
		dispatch({ type: TOGGLE_DRILLDOWN, value });
	};
};

export const setEditAddress =
	(partialAddress: Partial<BillingAddress>): AppThunk =>
	(dispatch, getState) => {
		const { editAddress } = getState().Billing;
		dispatch({ type: SET_EDIT_ADDRESS, value: { ...editAddress, ...partialAddress } });
	};

export const toggleEditAddress = (): AppThunk => {
	return (dispatch, getState) => {
		const { editAddress, billingAddress } = getState().Billing;
		if (editAddress) {
			dispatch({ type: SET_EDIT_ADDRESS, value: null });
		} else {
			dispatch({ type: SET_EDIT_ADDRESS, value: { ...billingAddress, loading: false } });
		}
	};
};

export const saveBillingAddress = (): AppThunk => (dispatch, getState) => {
	const { editAddress } = getState().Billing;

	dispatch(setEditAddress({ loading: true }));
	const saveAddress = { ...editAddress };
	delete saveAddress.loading;

	ProvisioningResource.saveBillingAddress(saveAddress)
		.then(() => {
			dispatch(toggleEditAddress());
			dispatch({ type: SET_BILLING_ADDRESS, value: saveAddress });
		})
		.catch(e => {
			console.log('Failed to save address', e);
			dispatch(setEditAddress({ loading: false }));
		});
};

export const getSeatChangeInfo = (seatDiff: number): AppThunk => {
	return async dispatch => {
		dispatch({ type: SET_LOADING_SEAT_INFO, value: true });

		let seatChangeInfo;
		try {
			seatChangeInfo = (await ProvisioningResource.getSeatChangeInfo(seatDiff)).data;
		} catch (e) {
			logError(e, 'Failed to get seat change info');
			Tools.NotificationService.addNotification({
				style: Tools.NotificationService.style.ERROR,
				icon: 'times',
				title: 'default.error',
				body: 'system.contact_upsales_support'
			});
			dispatch({ type: SET_LOADING_SEAT_INFO, value: false });
			return;
		}

		dispatch({ type: SET_LOADING_SEAT_INFO, value: false });
		dispatch({ type: SET_SEAT_CHANGE_INFO, value: seatChangeInfo });
	};
};

export const getActiveLicense = (): AppThunk => {
	return async dispatch => {
		dispatch({ type: SET_LOADING, value: true });

		const activeLicense = await ProvisioningResource.getActiveLicense();
		dispatch({ type: SET_ACTIVE_LICENSE, value: activeLicense.data });
		dispatch({ type: SET_LOADING, value: false });
	};
};

export const addSeats = (licencesToBuy: string, agreementGroup: boolean = true): AppThunk => {
	return async (dispatch, getState) => {
		dispatch({ type: SET_LOADING, value: true });

		const state = getState().Billing;
		const licenceCount = parseInt(licencesToBuy);
		await ProvisioningResource.addSeats({ licenceCount, agreementGroup });
		const subscription = ProvisioningResource.getSubscription();
		const activeLicense = ProvisioningResource.getActiveLicense();
		const futureLicense = ProvisioningResource.getFutureLicense();

		Promise.all([subscription, activeLicense, futureLicense])
			.then(([subscription, activeLicense, futureLicense]) => {
				const invoiceSubscriptions = [
					reduceOrderRow(
						subscription.data as Array<Subscription>,
						state.addons,
						futureLicense.data as null | Subscription,
						activeLicense.data as Subscription
					)
				].filter(Boolean);
				dispatch({ type: SET_LICENSES, value: state.maxLicences + licenceCount });
				dispatch({ type: SET_INVOICE_SUBSCRIPTIONS, value: invoiceSubscriptions });
				dispatch({ type: SET_ACTIVE_LICENSE, value: activeLicense.data });
				dispatch({ type: SET_FUTURE_LICENSE, value: futureLicense.data });
				Tools.NotificationService.addNotification({
					style: Tools.NotificationService.style.SUCCESS,
					icon: 'check',
					title: 'default.successful',
					body: 'default.purchaseSuccessful'
				});
			})
			.catch(e => {
				logError(e, 'Failed to unsubscribe all');
				return Tools.NotificationService.addNotification({
					style: Tools.NotificationService.style.ERROR,
					icon: 'times',
					title: 'default.error',
					body: 'default.purchaseFailed'
				});
			})

			.finally(() => dispatch({ type: SET_LOADING, value: false }));
	};
};

export const removeSeats = (newLicenseCount: string, agreementGroup: boolean = false): AppThunk => {
	return async (dispatch, getState) => {
		dispatch({ type: SET_LOADING, value: true });

		const state = getState().Billing;
		const licenceCount = parseInt(newLicenseCount);

		await ProvisioningResource.removeSeats({ licenceCount, agreementGroup: agreementGroup });
		const subscription = ProvisioningResource.getSubscription();
		const activeLicense = ProvisioningResource.getActiveLicense();
		const futureLicense = ProvisioningResource.getFutureLicense();
		const futureSubscriptions = ProvisioningResource.getFutureSubscription();

		Promise.all([subscription, activeLicense, futureLicense, futureSubscriptions])
			.then(([subscription, activeLicense, futureLicense, futureSubscriptions]) => {
				const invoiceSubscriptions = [
					reduceOrderRow(
						subscription.data as Array<Subscription>,
						state.addons,
						futureLicense.data as null | Subscription,
						activeLicense.data as Subscription
					)
				].filter(Boolean);
				dispatch({ type: SET_INVOICE_SUBSCRIPTIONS, value: invoiceSubscriptions });
				dispatch({ type: SET_ACTIVE_LICENSE, value: activeLicense.data });
				dispatch({ type: SET_FUTURE_LICENSE, value: futureLicense.data });
				dispatch({ type: SET_FUTURE_SUBSCRIPTIONS, value: futureSubscriptions.data });
				Tools.NotificationService.addNotification({
					style: Tools.NotificationService.style.SUCCESS,
					icon: 'check',
					title: Tools.$translate('admin.billing.seatRemovedSnackbar.title', {
						quantity: state.maxLicences - licenceCount
					}),
					body: Tools.$translate('admin.billing.seatRemovedSnackbar.subtitle')
				});
			})
			.catch(e => {
				logError(e, 'Failed to unsubscribe all');
				return Tools.NotificationService.addNotification({
					style: Tools.NotificationService.style.ERROR,
					icon: 'times',
					title: 'default.error',
					body: 'default.purchaseFailed'
				});
			})

			.finally(() => dispatch({ type: SET_LOADING, value: false }));
	};
};

export const fetchBillingHistory =
	(offset: number): AppThunk =>
	async (dispatch, getState) => {
		dispatch({ type: SET_LOADING, value: true });
		dispatch({ type: SET_BILLING_HISTORY_OFFSET, value: offset });

		const rb = new RequestBuilder();
		rb.addFilter({ field: 'userId' }, ComparisonTypes.NotEquals, null);
		rb.addSort({ field: 'id' }, false);
		rb.limit = getState().Billing.billingHistoryLimit;
		rb.offset = offset;
		await BillingChangelogResource.find(rb.build())
			.then(res => {
				dispatch({ type: SET_BILLING_HISTORY, value: res.data });
				dispatch({ type: SET_BILLING_HISTORY_TOTAL, value: res.metadata.total });
			})
			.catch(e => {
				logError(e, 'Failed to get billing history');
			})
			.finally(() => dispatch({ type: SET_LOADING, value: false }));
	};

export const retrieveData = (): AppThunk => {
	return dispatch => {
		dispatch({ type: SET_LOADING_ADDONS, value: true });

		const customerDetails = ProvisioningResource.getCustomerDetails();
		const subscription = ProvisioningResource.getSubscription();
		const futureSubscriptions = ProvisioningResource.getFutureSubscription();
		const licenceInfo = ProvisioningResource.getLicenceInfo();
		const addons = ProvisioningResource.getSubscriptionAddons();
		const trialAddons = ProvisioningResource.getTrialAddons();
		const availableAddons = ProvisioningResource.getAvailableAddons();
		const contactCount = ContactResource.find({ limit: 0, active: 1 });
		const userCount = UserResource.find({ limit: 0, active: 1, ghost: 0, crm: 1 });
		const supportUserCount = UserResource.find({ limit: 0, active: 1, ghost: 0, support: 1 });
		const bisnodeProduct = ProvisioningResource.getProduct('bisnode_credits', 'feature');
		const activeLicense = ProvisioningResource.getActiveLicense();
		const futureLicense = ProvisioningResource.getFutureLicense();

		Promise.all([
			customerDetails,
			userCount,
			supportUserCount,
			contactCount,
			subscription,
			licenceInfo,
			bisnodeProduct,
			addons,
			trialAddons,
			activeLicense,
			futureLicense,
			futureSubscriptions
		])
			.then(
				([
					details,
					userCount,
					supportUserCount,
					contactCount,
					subscription,
					licenceInfo,
					bisnodeProduct,
					addonsRes,
					trialAddons,
					activeLicense,
					futureLicense,
					futureSubscriptions
				]) => {
					const billingAddress = {
						address: details.data.address,
						city: details.data.city,
						zipcode: details.data.zipcode,
						email: details.data.email,
						country: details.data.country,
						invoiceDeliveryType: details.data.invoiceDeliveryType
					};

					const accountManager = Tools.AppService.getAccountSelf().accountManager;
					const { credits } = Tools.AppService.getMetadata();

					const value: Partial<State> = {
						billingAddress,
						accountManager,
						credits,
						licenceInfo: licenceInfo.data,
						activeLicense: activeLicense.data,
						futureLicense: futureLicense.data,
						trialAddons: trialAddons.data,
						loading: false,
						userCount: userCount.metadata.total,
						supportUserCount: supportUserCount.metadata.total,
						contactCount: contactCount.metadata.total,
						maxLicences: details.data.provisioningLicenses,
						maxSupportLicences: details.data.supportLicenses,
						creditsPrices: { bisnode: bisnodeProduct.data.price },
						activeSubscriptions: _.cloneDeep(subscription.data),
						futureSubscriptions: _.cloneDeep(futureSubscriptions.data)
					};

					if (Array.isArray(subscription.data)) {
						// Invoice billing
						value.invoiceSubscriptions = [
							reduceOrderRow(
								_.cloneDeep(subscription.data),
								addonsRes.data,
								futureLicense.data,
								activeLicense.data
							) as Subscription
						].filter(Boolean);
					}

					if (!value.activeLicense) {
						value.activeLicense = activeLicense.data[0];
					}

					dispatch({ type: SET_DATA, value: value });
					dispatch(setAddons(addonsRes.data));
				}
			)
			.catch(e => {
				logError(e, 'Failed fetch billing data');
				dispatch({ type: RETRIEVE_DATA_FAILED, value: true });
			})
			.finally(() => {
				dispatch({ type: SET_LOADING, value: false });
			});

		Promise.all([availableAddons])
			.then(([availableAddonsRes]) => {
				dispatch({ type: SET_AVAILABLE_ADDONS, value: availableAddonsRes.data });
				dispatch({ type: SET_LOADING_ADDONS, value: false });
			})
			.catch(e => logError(e, 'Failed to get addons'));
	};
};

export const setShowCreditPurchaseCard = (
	value: null | keyof ReturnType<typeof Tools.AppService.getMetadata>['credits']
) => ({ type: SET_SHOW_CREDIT_PURCHASE_CARD, value });

export const setCreditsToBuy = (value: string) => ({ type: CREDITS_TO_BUY, value });

export const setBuyingCredits = (value: boolean) => ({ type: SET_BUYING_CREDITS, value });

export const setBuyingAddon = (buyingAddon: null | string) => ({ type: SET_BUYING_ADDON, buyingAddon });
export const setBuyingTrialAddon = (buyingTrialAddon: null | string) => ({
	type: SET_BUYING_TRIAL_ADDON,
	buyingTrialAddon
});

function setAddons(value: Array<Addon>) {
	return { type: SET_ADDONS, value };
}

export const buyCredits = (): AppThunk => async (dispatch, getState) => {
	const { creditsToBuy, credits, lang, creditsPrices } = getState().Billing;
	const self = Tools.AppService.getSelf();
	const quantity = parseInt(creditsToBuy);
	const foromatCurrency = Tools.$filter('currencyFormat');

	const body = `
		<span>${lang.creditsToAdd}: <b>${quantity}</b></span><br>
		<span>${lang.costPerCredit}: <b>${foromatCurrency(creditsPrices.bisnode, 'SEK')}</b></span><br>
		<span>${lang.totalCost2}: <b>${foromatCurrency(quantity * creditsPrices.bisnode, 'SEK')}</b></span>
	`;

	const onBuyCredits = async () => {
		dispatch(setBuyingCredits(true));
		const { data } = await ProvisioningResource.addBisnodeCredits({
			userId: self.id,
			username: self.name,
			quantity
		});
		dispatch(setBuyingCredits(false));
		if (data) {
			dispatch(setShowCreditPurchaseCard(null));
			dispatch(setCreditsToBuy('500'));

			if (data.bisnodeCredits) {
				credits.bisnode = data.bisnodeCredits;
				dispatch({ type: SET_CREDITS, value: credits });
			}
		}
	};

	if (Tools.FeatureHelper.hasSoftDeployAccess('REACT_ALERT_MODAL')) {
		const { default: openModal } = await import('App/services/Modal');
		const bodyElement = (
			<>
				<span>
					{lang.creditsToAdd}: <b>{quantity}</b>
				</span>
				<br />
				<span>
					{lang.costPerCredit}: <b>{foromatCurrency(creditsPrices.bisnode, 'SEK')}</b>
				</span>
				<br />
				<span>
					{lang.totalCost2}: <b>{foromatCurrency(quantity * creditsPrices.bisnode, 'SEK')}</b>
				</span>
			</>
		);
		openModal('Alert', {
			alertType: 'info',
			confirmButtonText: lang.confirmPurchase,
			headerIcon: 'user',
			body: bodyElement,
			title: lang.addCredits,
			onClose: confirmed => {
				if (confirmed) {
					onBuyCredits();
				}
			}
		});
		return;
	}

	// eslint-disable-next-line promise/catch-or-return
	Tools.$upModal
		.open('successConfirm', {
			id: 'confirm-add-seats',
			resolveTrue: lang.confirmPurchase,
			required: true,
			icon: 'fa-user',
			body: body,
			title: lang.addCredits
		})
		.then(async () => {
			onBuyCredits();
		});
};

export const loadLanguage = (languageObject: { [key: string]: string }): AppThunk => {
	return dispatch => {
		dispatch({ type: SET_LANGUAGE, value: languageObject });
	};
};

export const setSelectedTab =
	(value: 'addons' | 'overview' | 'history' | 'upcomingInvoices'): AppThunk =>
	dispatch => {
		dispatch({ type: SET_SELECTED_TAB, value });
		if (value === 'history') {
			dispatch(fetchBillingHistory(0));
		}
	};

export const buyAddon =
	(addon: Addon): AppThunk<Promise<void>> =>
	async (dispatch, getState) => {
		dispatch(setBuyingAddon(addon.alias));
		return ProvisioningResource.buyAddon(addon.alias)
			.then(broughtAddon => {
				const subscription = ProvisioningResource.getSubscription();
				const activeLicense = ProvisioningResource.getActiveLicense();
				const futureLicense = ProvisioningResource.getFutureLicense();

				return Promise.all([subscription, activeLicense, futureLicense]).then(
					([subscription, activeLicense, futureLicense]) => {
						const { addons } = getState().Billing;
						const newAddons = [...addons, broughtAddon.data];
						const invoiceSubscriptions = [
							reduceOrderRow(subscription.data, newAddons, futureLicense.data, activeLicense.data)
						];
						dispatch(setAddons(newAddons));
						dispatch({ type: SET_INVOICE_SUBSCRIPTIONS, value: invoiceSubscriptions });
						dispatch({ type: SET_ACTIVE_LICENSE, value: activeLicense.data });
						dispatch({ type: SET_FUTURE_LICENSE, value: futureLicense.data });
					}
				);
			})
			.catch(e => logError(e, 'Failed to buy addon'))
			.finally(() => dispatch(setBuyingAddon(null)));
	};

export const addTrialAddon =
	(addon: Addon): AppThunk<Promise<void>> =>
	async (dispatch, getState) => {
		dispatch(setBuyingTrialAddon(addon.alias));
		const { trialPeriod } = getState().Billing;
		const trialAddons = ProvisioningResource.addTrialAddon(addon.alias, addon.productId, trialPeriod);
		return Promise.all([trialAddons])
			.then(([trialAddons]) => {
				dispatch({ type: SET_TRIAL_ADDONS, value: trialAddons.data });
				Tools.CacheRefresher.refresh([Tools.CacheRefresher.types.METADATA]);
			})
			.catch(e => logError(e, 'Failed to buy addon'))
			.finally(() => dispatch(setBuyingTrialAddon(null)));
	};

export const getOngoingTrials = (): AppThunk<Promise<void>> => dispatch => {
	return ProvisioningResource.getTrialAddons()
		.then(res => {
			dispatch({ type: SET_TRIAL_ADDONS, value: res.data });
		})
		.catch(e => logError(e, 'Failed to get ongoing trials'));
};

export const cancelAddon =
	(addon: Addon, agreementGroup: boolean = false): AppThunk =>
	async dispatch => {
		dispatch({ type: SET_LOADING, value: true });
		try {
			await ProvisioningResource.cancelAddon(addon.productId, agreementGroup);

			const addons = ProvisioningResource.getSubscriptionAddons();
			const subscription = ProvisioningResource.getSubscription();
			const activeLicense = ProvisioningResource.getActiveLicense();
			const futureLicense = ProvisioningResource.getFutureLicense();
			const futureSubscriptions = ProvisioningResource.getFutureSubscription();

			Promise.all([subscription, addons, activeLicense, futureLicense, futureSubscriptions])
				.then(([subscription, addonsRes, activeLicense, futureLicense, futureSubscriptions]) => {
					const invoiceSubscriptions = [
						reduceOrderRow(subscription.data, addonsRes.data, futureLicense.data, activeLicense.data)
					];
					dispatch({ type: SET_INVOICE_SUBSCRIPTIONS, value: invoiceSubscriptions });
					dispatch({ type: SET_ACTIVE_LICENSE, value: activeLicense.data });
					dispatch({ type: SET_FUTURE_LICENSE, value: futureLicense.data });
					dispatch({ type: SET_FUTURE_SUBSCRIPTIONS, value: futureSubscriptions.data });
				})
				.catch(e => logError(e, 'Failed to unsubscribe all'))
				.finally(() => dispatch({ type: SET_LOADING, value: false }));
		} catch (e) {
			logError(e, 'Failed to cancel addon');
			dispatch({ type: SET_LOADING, value: false });
		}
	};

export const cancelSubscription =
	(agreementGroup: boolean, reasons: string): AppThunk =>
	async dispatch => {
		dispatch({ type: SET_LOADING, value: true });

		await ProvisioningResource.cancelSubscription({ agreementGroup, reasons });

		dispatch(retrieveData());
	};

export const reactivateSubscription = (): AppThunk => async dispatch => {
	dispatch({ type: SET_LOADING, value: true });
	await ProvisioningResource.reactivateSubscription();
	const subscription = ProvisioningResource.getSubscription();
	const activeLicense = ProvisioningResource.getActiveLicense();
	const futureLicense = ProvisioningResource.getFutureLicense();
	const addons = await ProvisioningResource.getSubscriptionAddons();

	Promise.all([subscription, addons, activeLicense, futureLicense])
		.then(([subscription, addonsRes, activeLicense, futureLicense]) => {
			const invoiceSubscriptions = [
				reduceOrderRow(subscription.data, addonsRes.data, futureLicense.data, activeLicense.data)
			].filter(Boolean);
			dispatch({ type: SET_INVOICE_SUBSCRIPTIONS, value: invoiceSubscriptions });
			dispatch({ type: SET_ACTIVE_LICENSE, value: activeLicense.data });
			dispatch({ type: SET_FUTURE_LICENSE, value: futureLicense.data });
		})
		.catch(e => logError(e, 'Failed to reactivate subscription'))
		.finally(() => dispatch({ type: SET_LOADING, value: false }));
};

export const reactivateAddon =
	(addon: Addon, agreementGroup: boolean = false): AppThunk =>
	async dispatch => {
		dispatch({ type: SET_LOADING, value: true });
		await ProvisioningResource.reactivateAddon(addon.productId, agreementGroup);

		const subscription = ProvisioningResource.getSubscription();
		const activeLicense = ProvisioningResource.getActiveLicense();
		const futureLicense = ProvisioningResource.getFutureLicense();
		const addons = ProvisioningResource.getSubscriptionAddons();
		const futureSubscriptions = ProvisioningResource.getFutureSubscription();

		Promise.all([subscription, addons, activeLicense, futureLicense, futureSubscriptions])
			.then(([subscription, addonsRes, activeLicense, futureLicense, futureSubscriptions]) => {
				const invoiceSubscriptions = [
					reduceOrderRow(subscription.data, addonsRes.data, futureLicense.data, activeLicense.data)
				].filter(Boolean);
				dispatch({ type: SET_INVOICE_SUBSCRIPTIONS, value: invoiceSubscriptions });
				dispatch({ type: SET_ACTIVE_LICENSE, value: activeLicense.data });
				dispatch({ type: SET_FUTURE_LICENSE, value: futureLicense.data });
				dispatch({ type: SET_FUTURE_SUBSCRIPTIONS, value: futureSubscriptions.data });
			})
			.catch(e => logError(e, 'Failed to reactivate subscription'))
			.finally(() => dispatch({ type: SET_LOADING, value: false }));
	};

function reduceOrderRow(
	subscription: Array<Subscription>,
	addonsRes: null | Array<Addon & { used?: boolean }>,
	futureLicence: null | Subscription,
	activeLicense: Subscription
) {
	if (subscription instanceof Array) {
		return subscription.reduce<null | Subscription>((memo, sub) => {
			if (sub.orderRow) {
				sub.orderRow = sub.orderRow.map(row => {
					const addonInfo = addonsRes?.find(addon => addon.productId === row.productId && !addon.used);
					if (addonInfo?.alias === 'BRANDS') {
						addonInfo.used = true;
					}

					let endDate;

					if (activeLicense?.orderRow?.find(x => x.id === row.id)) {
						if (futureLicence?.orderRow?.find(x => x.product.id === row.product.id)) {
							endDate = futureLicence.metadata.agreementEnddate;
						} else {
							endDate = activeLicense.metadata.agreementEnddate;
						}
					} else {
						endDate = sub.metadata.agreementEnddate;
					}

					return {
						...row,
						intervalPeriod: sub.metadata.agreementIntervalPeriod,
						nextOrderDate: sub.metadata.agreementNextOrderDate,
						endDate: endDate,
						renewalDate: sub.metadata.agreementRenewalDate,
						addonInfo: addonInfo
					};
				});
				if (!memo) {
					memo = sub;
					memo.value = sub.value / sub.metadata.agreementIntervalPeriod;
					memo.yearlyValue = sub.yearlyValue;
				} else {
					memo.orderRow = [...memo.orderRow, ...sub.orderRow];
					memo.yearlyValue += sub.yearlyValue;
					memo.value += sub.orderRow.reduce((memo, row) => {
						return (
							memo + (row.price * sub.currencyRate * row.quantity) / sub.metadata.agreementIntervalPeriod
						);
					}, 0);
				}

				return memo;
			}
			return memo;
		}, null);
	}
}

// Send system email to request addon to be added
export const requestAddon =
	(alias: Addon['alias'], trial: boolean = false): AppThunk<Promise<void>> =>
	async dispatch => {
		return SystemMail.send('requestAddon', [], { alias, trial }).catch(e => {
			dispatch(
				addNotification({
					title: 'default.error',
					body: 'error.sendSystemEmail',
					style: NotificationStyle.ERROR,
					icon: 'times'
				})
			);
			throw e;
		});
	};
