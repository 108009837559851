import Attribute from './Attribute';

const BrandAttributes = {
	id: new Attribute({
		type: Attribute.types.Number,
		field: 'id'
	}),
	name: new Attribute({
		title: 'default.name',
		type: Attribute.types.String,
		field: 'name'
	}),
	active: new Attribute({
		title: 'default.active',
		type: Attribute.types.Boolean,
		field: 'active'
	}),
	logo: new Attribute({
		type: Attribute.types.String,
		field: 'logo'
	}),
	roles: new Attribute({
		type: Attribute.types.Array,
		field: 'roles'
	})
};

window.BrandAttributes = BrandAttributes;

export default BrandAttributes;
