import Resource from './Resource';
import parseProduct from './parsers/product';

class Products extends Resource {
	constructor() {
		super('products');

		this.eventName = 'product';
		this.parse = parseProduct;

		this.notifications = {
			save: () => ({
				title: 'default.saved',
				body: 'saved.product',
				style: 'success',
				icon: 'check',
				type: 'body'
			}),
			saveError: () => ({
				title: 'default.error',
				body: 'saveError.product',
				style: 'error',
				icon: 'times',
				type: 'body'
			}),
			delete: () => ({
				title: 'default.deleted',
				body: 'deleted.product',
				style: 'success',
				icon: 'check',
				type: 'body'
			}),
			deleteError: () => ({
				title: 'default.error',
				body: 'deleteError.product',
				style: 'error',
				icon: 'times',
				type: 'body'
			})
		};
	}

	new() {
		return {
			id: null,
			name: '',
			roles: [],
			currencies: [],
			custom: [],
			tiers: [],
			category: null,
			active: true,
			purchaseCost: 0,
			listPrice: 0
		};
	}

	find(filters, opts) {
		if (opts?.checkAccess) {
			// eslint-disable-next-line no-async-promise-executor
			return new Promise(async (resolve, reject) => {
				var result;

				var get = async () => {
					return super
						.find(filters, opts)
						.then(async res => {
							result = res;
							result.data = result.data.filter(product => product.$hasAccess);
						})
						.catch(reject);
				};

				await get();

				return resolve(result);
			});
		} else {
			return super.find(filters, opts);
		}
	}

	findAll(filter) {
		// eslint-disable-next-line no-async-promise-executor
		return new Promise(async (resolve, reject) => {
			var products = [];
			filter.offset = 0;
			filter.limit = 1000;

			var get = async filter => {
				return this.find(filter)
					.then(async res => {
						products = products.concat(res.data);

						if (res.data.length === res.metadata.limit) {
							filter.offset += res.metadata.limit;
							await get(filter);
						}
					})
					.catch(reject);
			};

			await get(filter);

			return resolve(products);
		});
	}
}

const resource = new Products();

export default resource;
