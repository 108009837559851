import React from 'react';
import { Button } from '@upsales/components';
import BemClass from '@upsales/components/Utils/bemClass';

const ButtonRenderType = ({ row, config, object, getUser, runStandardIntegration, runClick }) => {
	const classes = new BemClass('ButtonRenderType', 'widget-button-wrapper');
	const buttonProps = {
		size: 'xs',
		type: row.buttonType || 'lined',
		shadow: 'none',
		color: row.color || 'bright-blue',
		block: !!row.fullWidth
	};

	if (row.inline) {
		classes.add('btn-inline');
	}

	if (row.left) {
		classes.add('btn-left');
	}

	// This is legacy. Type should be passed on the elementRow itself as buttonType
	if (row.link) {
		buttonProps.type = 'link';
	}

	if (row.click) {
		buttonProps.onClick = event => {
			event.stopPropagation();

			if (row.click.type === 'widgetRow') {
				runStandardIntegration({
					type: 'widgetRow',
					data: { object: object, user: getUser() },
					typeId: row.click.name,
					integrationId: config.integrationId
				});
			} else if (row.click.type === 'closeModal') {
				document.querySelector('.up-modal-curtain')?.click();
			} else {
				runClick(row);
			}
		};
	}

	return (
		<Button {...buttonProps} className={classes.b()} target="_blank">
			{row.text}
		</Button>
	);
};

export default ButtonRenderType;
