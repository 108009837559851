import React from 'react';

type EmptyRowRenderTypeProps = {
	row: Object;
};

const EmptyRowRenderType = (props: EmptyRowRenderTypeProps) => (
	<div className="widget-text-wrapper">
		<span className="widget-text">&nbsp;</span>
	</div>
);

export default EmptyRowRenderType;
