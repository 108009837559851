import React from 'react';
import { Icon } from '@upsales/components';

import t from 'Components/Helpers/translate';

import './JourneyStepFilterRow.scss';

const JourneyStepFilterRow = ({ name, color }) => (
	<div className="JourneyStepFilterRow">
		<Icon name="circle" style={{ color }} />
		{t(name)}
	</div>
);

export const renderContent = item => <JourneyStepFilterRow {...item} />;

export default JourneyStepFilterRow;
