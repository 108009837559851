import React from 'react';
import PropTypes from 'prop-types';
import { Row, Text, Column, Help } from '@upsales/components';
import T from 'Components/Helpers/translate';

const getTranslation = (text: string | { [k: string]: string }) => {
	let language = Tools.AppService.getSelf().language || 'en';
	if (!text) {
		return '';
	}

	language = language.split('-')[0];

	return typeof text === 'object' ? text[language] || text.en : text;
};

type Props = {
	value: string;
	options: { text: string; elevio?: number; cost: number };
	checked: boolean;
	showCheckbox: boolean;
	onChange: (value: string) => void;
	id: string;
	className: string;
};

export default class BuyButtonRow extends React.Component<Props> {
	static propTypes = {
		value: PropTypes.string,
		options: PropTypes.object,
		checked: PropTypes.bool,
		showCheckbox: PropTypes.bool,
		onChange: PropTypes.func,
		id: PropTypes.string,
		className: PropTypes.string
	};

	render() {
		const { value, options, checked, onChange, showCheckbox, id = '', className = '' } = this.props;

		return (
			<Row id={id} className={`DataSourceBuyButton__PriceRow ${className}`} onClick={() => onChange(value)}>
				<Column fixedWidth={20} style={{ visibility: showCheckbox ? 'visible' : 'hidden', textAlign: 'left' }}>
					<input type="radio" name="purchaseType" checked={checked} value={value} readOnly={true} />
				</Column>
				<Column>
					<Text bold={true} size="sm">
						{getTranslation(options.text)}{' '}
						{options.elevio && <Help articleId={options.elevio} sidebar={true} />}
					</Text>
					<Text size="sm">{`${options.cost} ${T('soliditet.credits')}`}</Text>
				</Column>
			</Row>
		);
	}
}
