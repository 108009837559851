import React from 'react';
import { Link } from '@upsales/components';
import BemClass from '@upsales/components/Utils/bemClass';

const LinkRenderType = ({ row }) => {
	const classes = new BemClass('LinkRenderType', 'widget-link-wrapper');
	return (
		<Link className={classes.b()} href={row.href} target="_blank">
			{row.text}
		</Link>
	);
};

export default LinkRenderType;
