import React from 'react';
import { groupBy } from 'lodash';
import { AnyAction } from 'redux';

import { makeCancelable } from 'App/babel/helpers/promise';
import FieldTranslations from 'Resources/FieldTranslations';

import type { CancelablePromise } from 'App/babel/helpers/promise';
import type FieldTranslation from 'App/resources/Model/FieldTranslation';
import type { FieldTranslationMap } from 'App/resources/Model/FieldTranslation';

export const INITIALIZE = '[FIELD_TRANSLATIONS] INITIALIZE';
export const SET_TRANSLATIONS = '[FIELD_TRANSLATIONS] SET_TRANSLATIONS';
export const SET_PENDING_REQUESTS = '[FIELD_TRANSLATIONS] SET_PENDING_REQUESTS';

export type TranslationsMap = {
    clientorderrelation?: FieldTranslationMap;
};

export type FieldTranslationsState = {
    translations: TranslationsMap;
    types: string[];
    pendingRequests: {
        [key: string]: Promise<{ data: FieldTranslation[] }> | null;
    }
};

export type Dispatch = (action: any) => void;

export const initialState: FieldTranslationsState = {
	translations: {},
    types: [],
    pendingRequests: {},
};

const ACTION_HANDLERS: { [key: string]: (s: FieldTranslationsState, a: AnyAction) => FieldTranslationsState } = {
	[INITIALIZE]: (_, { state }) => ({
		...initialState,
		...state,
	}),
    [SET_TRANSLATIONS]: (state, { translations }) => ({
        ...state,
        translations
    }),
    [SET_PENDING_REQUESTS]: (state, { pendingRequests }) => ({
        ...state,
        pendingRequests
    })
};

const FieldTranslationsContext = React.createContext<{ state: FieldTranslationsState; dispatch: Dispatch } | undefined>(undefined);

const getFieldTranslations = async (dispatch: Dispatch, state: FieldTranslationsState) => {
    const pendingRequestKey = state.types.join('+');
    const pendingRequests = state.pendingRequests;
    let pendingRequest = pendingRequests[pendingRequestKey];
    if (!pendingRequest) {
        pendingRequest = pendingRequests[pendingRequestKey] = FieldTranslations.find({
            type: state.types,
            allTranslations: true
        });
        dispatch({ type: SET_PENDING_REQUESTS, pendingRequests });
    }

    const { data } = await pendingRequest;
    return data;
};

const setFieldTranslations = async (dispatch: Dispatch, state: FieldTranslationsState) => {
    const data = await getFieldTranslations(dispatch, state);
    const translationGroupedByType = groupBy(data, 'type');
    const translations = Object.entries(translationGroupedByType).reduce((acc: TranslationsMap, [type, translations]) => {
        acc[type as keyof TranslationsMap] = groupBy(translations, 'tagId');
        return acc;
    }, {});
    dispatch({ type: SET_TRANSLATIONS, translations });
};

export const reducer = (state: FieldTranslationsState, action: AnyAction) => {
	const handler = ACTION_HANDLERS[action.type];
	return handler ? handler(state, action) : state;
};

export function FieldTranslationsProvider({ children, types }: {
    children: React.ReactNode,
    types: FieldTranslationsState['types']
}) {
	const [state, dispatch] = React.useReducer(reducer, { ...initialState, types });
	const value = { state, dispatch };

    React.useEffect(() => {
        let pendingInit: CancelablePromise;
        if (state.types.length) {
            pendingInit = makeCancelable(setFieldTranslations(dispatch, state));
        }
        return () => {
            pendingInit?.cancel();
        };
    }, [state.types]);

	return <FieldTranslationsContext.Provider value={value}>{children}</FieldTranslationsContext.Provider>;
}

export function useFieldTranslations(type: keyof TranslationsMap): FieldTranslationMap {
    const context = React.useContext(FieldTranslationsContext);
	if (typeof context === 'undefined') {
		throw new Error('useFieldTranslationsContext must be used within a FieldTranslationsContext Provider');
	}
	return context.state.translations[type] || {};
};

export function useFieldTranslationsContext() {
	const context = React.useContext(FieldTranslationsContext);
	if (typeof context === 'undefined') {
		throw new Error('useFieldTranslationsContext must be used within a FieldTranslationsContext Provider');
	}
	return context;
}

