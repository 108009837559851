import React from 'react';
import PropTypes from 'prop-types';
import {
	FrameMessageEvent,
	getFrameName,
	handleIntegrationIframeMessage,
	postObjectChangeToFrame,
	type Utils
} from 'App/helpers/uiElementsHelper';
import { UiElementConfig } from 'App/resources/AllIWant';

type Props<T> = {
	object: T;
	config: UiElementConfig;
	type: string;
	onObjectChange: (object: Partial<T>) => void;
	utils: Utils;
};

class IframeRender<T extends { id: number }> extends React.Component<Props<T>> {
	iframeRef: React.RefObject<HTMLIFrameElement>;

	static propTypes = {
		config: PropTypes.object,
		type: PropTypes.string,
		object: PropTypes.object,
		getUser: PropTypes.func,
		onObjectChange: PropTypes.func
	};

	constructor(props: Props<T>) {
		super(props);
		this.iframeRef = React.createRef();
	}

	iframeMessage = (event: MessageEvent<FrameMessageEvent>) => {
		const { config, type, object, utils = {} } = this.props;
		handleIntegrationIframeMessage<T>(type, config.integrationId, () => object, utils, event);
	};

	componentDidMount() {
		window.addEventListener('message', this.iframeMessage);

		// Post object to iframe, this will need to happen on each update to object as well
		const { config, object } = this.props;
		postObjectChangeToFrame(config, object);

		const onLoad = () => {
			postObjectChangeToFrame(config, object);
		};

		this.iframeRef.current?.addEventListener('load', onLoad);
	}

	componentDidUpdate() {
		const { config, object } = this.props;
		if (object && config) {
			postObjectChangeToFrame(config, object);
		}
	}

	componentWillUnmount() {
		window.removeEventListener('message', this.iframeMessage);
	}

	render() {
		const { config, type, object } = this.props;

		let src = Tools.URL + Tools.API + 'function/standardIntegrationIframe/' + config.name;
		src += '?typeId=' + type;
		src += '&integrationId=' + config.integrationId;
		src += '&objectId=' + object.id;
		src += '&userId=' + Tools.AppService.getSelf().id;

		return React.createElement('iframe', {
			className: 'si-sidebar-iframe',
			name: getFrameName(config),
			src,
			ref: this.iframeRef
		});
	}
}

export default IframeRender;
