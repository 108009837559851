import React from 'react';
import PropTypes from 'prop-types';

export default function (Component) {
	class DelayedUnmount extends React.Component {
		state = {
			isMounted: this.props.isMounted
		};

		componentDidUpdate(prevProps) {
			if (prevProps.isMounted && !this.props.isMounted) {
				setTimeout(() => this.setState({ isMounted: false }), this.props.delay);
			} else if (!prevProps.isMounted && this.props.isMounted) {
				this.setState({ isMounted: true });
			}
		}

		render() {
			const { delay, ...props } = this.props;
			return this.state.isMounted ? <Component {...props} /> : null;
		}
	}

	DelayedUnmount.propTypes = {
		isMounted: PropTypes.bool.isRequired,
		delay: PropTypes.number.isRequired
	};
	DelayedUnmount.defaultProps = {
		isMounted: true,
		delay: 300
	};
	return DelayedUnmount;
}
