import { debounce } from 'lodash';
import * as ProspectingManual from './ProspectingManual';
import * as ProspectingExisting from './ProspectingExisting';
import RequestBuilder, { comparisonTypes } from 'Resources/RequestBuilder';

export const RESET = '[ProspectingShared] Reset';
export const SET_QUICK_SERCH_VALUE = '[ProspectingShared] Set quickSearch value';
export const SET_ACTIVE_TAB = '[ProspectingShared] Set active tab';
export const SET_SELECT_HEADER_TAB = '[ProspectingShared] Set select header tab';
export const UPDATE_VIEW = '[ProspectingShared] Update view';
export const SET_SELECTION = '[ProspectingShared] Set selection';

export const LIMIT = 100;

export const Tabs = {
	Search: 'Search',
	Selected: 'Selected'
};

export const Steps = {
	Search: 'Search',
	Manage: 'Manage'
};

export const initialState = {
	[Tabs.Search]: {
		data: [],
		total: 0,
		offset: 0,
		loading: false,
		sorting: 'name',
		asc: true
	},
	[Tabs.Selected]: {
		data: [],
		total: 0,
		offset: 0,
		loading: false,
		sorting: 'name',
		asc: true
	},
	activeTab: Tabs.Search,
	selectedHeaderTab: Steps.Search,
	quickSearchValue: '',
	selection: {}
};

const tabMap = {
	Existing: ProspectingExisting,
	Manually: ProspectingManual
};

const forwardCall = fn => (...args) => (dispatch, getState) => {
	const { selectedDrawer } = getState().Prospecting;
	dispatch(tabMap[selectedDrawer][fn](...args));
};
const getIdField = () => (dispatch, getState) => {
	const { selectedDrawer } = getState().Prospecting;
	switch (selectedDrawer) {
		case 'Existing':
			return 'id';
		default:
			return 'prospectingId';
	}
};
const _search = forwardCall('search');
const search = () => debounce(dispatch => dispatch(_search()), 250);
const _searchSelected = forwardCall('searchSelected');
const searchSelected = () => debounce(dispatch => dispatch(_searchSelected()), 250);

export const onHeaderTabChanged = selectedHeaderTab => ({ type: SET_SELECT_HEADER_TAB, selectedHeaderTab });

function existing(idField, item) {
	if (idField === 'id') {
		return true;
	}
	return !!item.matchInUpsales;
}

export const selectAllPage = () => (dispatch, getState) => {
	const {
		selection,
		[Tabs.Search]: { data }
	} = getState().ProspectingShared;
	const idField = dispatch(getIdField());
	const newSelection = { ...selection };

	for (const item of data) {
		const id = item[idField];

		if (!newSelection[id]) {
			newSelection[id] = { existing: existing(idField, item) };
		}
	}

	dispatch({ type: SET_SELECTION, selection: newSelection });
	dispatch({ type: UPDATE_VIEW, view: Tabs.Selected, total: Object.keys(newSelection).length });
};

export const deSelectAllPage = () => (dispatch, getState) => {
	const {
		selection,
		[Tabs.Search]: { data }
	} = getState().ProspectingShared;
	const idField = dispatch(getIdField());
	const newSelection = { ...selection };

	for (const item of data) {
		const id = item[idField];

		if (newSelection[id]) {
			delete newSelection[id];
		}
	}

	dispatch({ type: SET_SELECTION, selection: newSelection });
	dispatch({ type: UPDATE_VIEW, view: Tabs.Selected, total: Object.keys(newSelection).length });
};

export const removeAllSelections = () => dispatch => {
	dispatch({ type: SET_SELECTION, selection: {} });
	dispatch({ type: UPDATE_VIEW, view: Tabs.Selected, total: 0, data: [] });
};

export const excludeFromSelection = id => async (dispatch, getState) => {
	const {
		[Tabs.Selected]: { data }
	} = getState().ProspectingShared;
	const { selection } = getState().ProspectingShared;
	const newSelection = { ...selection };
	const idField = dispatch(getIdField());
	delete newSelection[id];
	dispatch({ type: SET_SELECTION, selection: newSelection });
	const filteredData = data.filter(company => company[idField] !== id);
	dispatch({ type: UPDATE_VIEW, view: Tabs.Selected, data: filteredData, total: Object.keys(newSelection).length });
};

export const toggleIndividualSelection = id => (dispatch, getState) => {
	const {
		selection,
		[Tabs.Search]: { data }
	} = getState().ProspectingShared;
	const idField = dispatch(getIdField());
	const newSelection = { ...selection };

	if (newSelection[id]) {
		delete newSelection[id];
	} else {
		const item = data.find(item => item[idField] === id);
		newSelection[id] = { existing: existing(idField, item) };
	}

	dispatch({ type: SET_SELECTION, selection: newSelection });
	dispatch({ type: UPDATE_VIEW, view: Tabs.Selected, total: Object.keys(newSelection).length });
};

export const getSelectionFilter = () => (dispatch, getState) => {
	const { selection } = getState().ProspectingShared;
	const idField = dispatch(getIdField());

	const rb = new RequestBuilder();
	const ids = Object.keys(selection);

	if (ids.length) {
		rb.addFilter({ field: idField }, comparisonTypes.Equals, ids);
	}

	return rb.build();
};

export const getTotalUpdates = () => (dispatch, getState) => {
	const { selection } = getState().ProspectingShared;
	return Object.values(selection).reduce((res, item) => res + item.existing, 0);
};

export const changeTab = activeTab => (dispatch, getState) => {
	const { quickSearchValue } = getState().ProspectingShared;

	dispatch({ type: SET_ACTIVE_TAB, activeTab });

	if (activeTab === Tabs.Selected) {
		dispatch(searchSelected());
	} else if (quickSearchValue) {
		dispatch(search());
	}
};

export const onQuickSearchChanged = value => (dispatch, getState) => {
	const { activeTab } = getState().ProspectingShared;

	dispatch({ type: SET_QUICK_SERCH_VALUE, quickSearchValue: value });
	dispatch({ type: UPDATE_VIEW, view: Tabs.Search, offset: 0 });
	dispatch({ type: UPDATE_VIEW, view: Tabs.Selected, offset: 0 });

	if (activeTab === Tabs.Search) {
		dispatch(search());
	} else {
		dispatch(searchSelected());
	}
};

export const onPaginationChanged = offset => (dispatch, getState) => {
	const { activeTab } = getState().ProspectingShared;

	dispatch({ type: UPDATE_VIEW, view: activeTab, offset });

	if (activeTab === Tabs.Search) {
		dispatch(search());
	} else {
		dispatch(searchSelected());
	}
};

export const onSortChange = ({ field, asc }) => (dispatch, getState) => {
	const { activeTab } = getState().ProspectingShared;
	dispatch({ type: UPDATE_VIEW, view: activeTab, sorting: field, asc });

	if (activeTab === Tabs.Selected) {
		dispatch(searchSelected());
	} else {
		dispatch(search());
	}
};

const ACTION_HANDLERS = {
	// eslint-disable-next-line no-unused-vars
	[UPDATE_VIEW]: (state, { type, view, ...props }) => ({ ...state, [view]: { ...state[view], ...props } }),
	[RESET]: () => initialState,
	[SET_QUICK_SERCH_VALUE]: (state, { quickSearchValue }) => ({ ...state, quickSearchValue }),
	[SET_ACTIVE_TAB]: (state, { activeTab }) => ({ ...state, activeTab }),
	[SET_SELECT_HEADER_TAB]: (state, { selectedHeaderTab }) => ({ ...state, selectedHeaderTab }),
	[SET_SELECTION]: (state, { selection }) => ({ ...state, selection })
};

export default (state = initialState, action) => {
	const handler = ACTION_HANDLERS[action.type];
	return handler ? handler(state, action) : state;
};
