import { IconName } from '@upsales/components';

export const truncateFilename = (filename: string, maxLength: number = 20) => {
	if (!filename?.length) {
		return '';
	}
	maxLength = maxLength < 11 ? 11 : maxLength;
	if (filename.length <= maxLength) {
		return filename;
	}

	const extIndex = filename.lastIndexOf('.');
	const extension = filename.substring(extIndex);
	const fullname = filename.substring(0, extIndex);
	const postfix = '...' + fullname.substring(fullname.length - 3, fullname.length) + extension;
	const prefix = fullname.substring(0, maxLength - postfix.length);

	return prefix + postfix;
};

export const getIconNameFromFilename = (filename: string) => {
	if (!filename?.length) {
		return 'file';
	}

	let extension = filename;
	if (filename && filename.indexOf('.') >= 0) {
		const lastPeriod = filename.lastIndexOf('.');
		extension = filename.substring(lastPeriod + 1, filename.length);
	}

	let postfix = '';

	switch (extension) {
		case 'rtf':
		case 'csv':
		case 'txt':
			postfix = '-text';
			break;
		case 'xls':
		case 'xlsx':
			postfix = '-excel';
			break;
		case 'pdf':
			postfix = '-pdf';
			break;
		case 'doc':
		case 'docx':
			postfix = '-word';
			break;
		case 'ppt':
		case 'pptx':
			postfix = '-powerpoint';
			break;
		case 'mp3':
		case 'wav':
		case 'mpga':
			postfix = '-audio';
			break;
		case 'gif':
		case 'png':
		case 'bmp':
		case 'jpg':
		case 'jpeg':
		case 'webp':
			postfix = '-image';
			break;
		case 'mp4':
		case 'webm':
		case 'mpg':
			postfix = '-video';
			break;
		case 'html':
		case 'py':
		case 'js':
			postfix = '-code';
			break;
		default:
			break;
	}

	return ('file' + postfix) as IconName;
};

export const formatFilesize = (size: number | undefined, significantDecimals: number = 1) => {
	if (!size) {
		return '-KB';
	}

	let unit = 'B';
	if (size > 1_000_000_000) {
		unit = 'GB';
		size = size / 1_000_000_000;
	} else if (size > 1_000_000) {
		unit = 'MB';
		size = size / 1_000_000;
	} else if (size > 1000) {
		unit = 'KB';
		size = size / 1000;
	}

	return +parseFloat('' + size).toFixed(significantDecimals) + unit;
};
