import React, { useCallback } from 'react';
import { AnyAction } from 'redux';
import moment from 'moment';

import { ActionsGroup, ActionsPeriod } from './SubscriptionGroupContextActions';
import OrderStage from 'App/resources/Model/OrderStage';
import { Metadata } from 'App/resources/AllIWant';
import {
	OrderInterval,
	PeriodLength,
	PeriodLengths,
	SubscriptionGroupState,
	SubscriptionPeriodState
} from './SubscriptionGroupState';

import {
	Dispatch,
	updatePeriodDates,
	saveSubscriptionGroup,
	updateCurrent,
	updateGroupFields,
	splitSubscription,
	deletePeriod,
	updateAdvancedMode,
	setSaving,
	setStartDateGroup,
	setInvoiceStartDateGroup,
	setStep,
	setFinalStep,
	isMapDirty,
	isDetailsDirty,
	setDiffOrders,
	showDiffOrder,
	setPeriodChildren,
	setCurrentChild,
	expandPreviewFooter,
	deleteChildPeriod,
	deleteSplitPeriod,
	addFuturePeriod,
	setInvoiceRelatedClient,
	updateCreditDate,
	getFulfillmentChanges
} from './SubscriptionGroupContextHelpers';

import {
	useStatics,
	toggleRenewal,
	setInvoiceStartDate,
	setCustomFields,
	setStartDate,
	setTerminationDate,
	setOffset,
	setNoticePeriod,
	setOrderRows,
	setCurrency,
	setPeriodLength,
	setOrderInterval,
	isSubDirty,
	setPriceList
} from './SubscriptionPeriodContextHelpers';
import { useSelector } from 'App/components/hooks';
import { STEP } from '../Step';
import PriceList from 'App/resources/Model/PriceList';
import T from 'Components/Helpers/translate';
import { OrderRow } from 'App/components/OrderRows/Context/OrderContext';

const ACTION_HANDLERS_GROUP: { [key: string]: (s: SubscriptionGroupState, a: AnyAction) => SubscriptionGroupState } = {
	[ActionsGroup.SET_SUBSCRIPTIONS]: (state, { subscriptionMap }) => ({
		...state,
		subscriptionMap,
		isDirty: state.isDirty || isMapDirty(state.subscriptionMap, subscriptionMap)
	}),
	[ActionsGroup.SPLIT_PERIOD]: (state, { subscriptionMap }) => ({
		...state,
		subscriptionMap,
		splitEnabled: true,
		isDirty: true
	}),
	[ActionsGroup.REMOVE_PERIOD]: (state, { subscriptionMap, currentUUID, splitEnabled = false }) => ({
		...state,
		subscriptionMap,
		currentUUID,
		splitEnabled,
		isDirty: true
	}),
	[ActionsGroup.SET_CURRENT]: (state, { id }) => ({ ...state, currentUUID: id }),
	[ActionsGroup.SET_LOCKED]: (state, { locked }) => ({ ...state, locked }),
	[ActionsGroup.SET_CURRENT_CHILD]: (state, { childIndexNr }) => ({ ...state, childIndexNr }),
	[ActionsGroup.SET_GROUP_FIELDS]: (state, { groupFields }) => ({
		...state,
		...groupFields,
		isDirty: state.isDirty || isDetailsDirty(state, { ...groupFields })
	}),
	[ActionsGroup.SET_ADVANCED_MODE]: (state, { advanced, subscriptionMap, currentUUID }) => ({
		...state,
		isAdvancedMode: advanced,
		subscriptionMap,
		currentUUID,
		splitEnabled: false,
		isDirty: state.isDirty || isMapDirty(state.subscriptionMap, subscriptionMap)
	}),
	[ActionsGroup.SET_SAVING]: (state, { saving }) => ({ ...state, saving }),
	[ActionsGroup.SET_STEP]: (state, { step }) => ({ ...state, step }),
	[ActionsGroup.SET_ID]: (state, { id }) => ({ ...state, id }),
	[ActionsGroup.SET_FINAL_STEP]: (state, { finalStep }) => ({
		...state,
		finalStep
	}),
	[ActionsGroup.SET_DIFF_ORDERS]: (state, { diffOrders }) => ({ ...state, diffOrders }),
	[ActionsGroup.EXPAND_PREVIEW_FOOTER]: (state, { showPreviewFooter }) => ({ ...state, showPreviewFooter }),
	[ActionsGroup.SET_PERIOD_CHILDREN]: (state, { subscriptionMap, childIndexNr, currentUUID, splitEnabled }) => ({
		...state,
		subscriptionMap,
		childIndexNr,
		splitEnabled,
		currentUUID: currentUUID ?? state.currentUUID,
		isDirty: state.isDirty || isMapDirty(state.subscriptionMap, subscriptionMap)
	}),
	[ActionsGroup.SET_INVOICE_RELATED_CLIENT]: (state, { invoiceRelatedClient }) => ({
		...state,
		invoiceRelatedClient,
		isDirty: true
	})
};

const updatePeriodInSubscriptionMap = (
	state: SubscriptionGroupState,
	obj: object,
	uuid: number = state.currentUUID
) => ({
	...state,
	subscriptionMap: {
		...state.subscriptionMap,
		[uuid]: { ...state.subscriptionMap[uuid], ...obj }
	},
	isDirty:
		state.isDirty ||
		isSubDirty(state.subscriptionMap[uuid], {
			...state.subscriptionMap[uuid],
			...obj
		})
});

const ACTION_HANDLERS_PERIOD: {
	[key: string]: (s: SubscriptionGroupState, a: AnyAction) => SubscriptionGroupState;
} = {
	[ActionsPeriod.TOGGLE_RENEWAL]: (state, obj) => updatePeriodInSubscriptionMap(state, obj),
	[ActionsPeriod.SET_START_DATE]: (state, obj) => updatePeriodInSubscriptionMap(state, obj),
	[ActionsPeriod.SET_ORDER_ROWS]: (state, obj) => updatePeriodInSubscriptionMap(state, obj),
	[ActionsPeriod.SET_CHILDREN]: (state, obj) => updatePeriodInSubscriptionMap(state, obj),
	[ActionsPeriod.SET_OFFSET]: (state, obj) => updatePeriodInSubscriptionMap(state, obj),
	[ActionsPeriod.SET_CUSTOM_VALUES]: (state, obj) => updatePeriodInSubscriptionMap(state, obj),
	[ActionsPeriod.SET_NOTICE_PERIOD]: (state, obj) => updatePeriodInSubscriptionMap(state, obj),
	[ActionsPeriod.SET_ORDER_INTERVAL]: (state, obj) => updatePeriodInSubscriptionMap(state, obj),
	[ActionsPeriod.SET_CURRENCY]: (state, obj) => updatePeriodInSubscriptionMap(state, obj),
	[ActionsPeriod.SET_PERIOD_AND_INTERVAL]: (state, obj) => updatePeriodInSubscriptionMap(state, obj),
	[ActionsPeriod.SET_TERMINATION_DATE]: (state, obj) => updatePeriodInSubscriptionMap(state, obj),
	[ActionsPeriod.SET_FIRST_PERIOD_START_DATE]: (state, { firstPeriodUUID, ...obj }) =>
		updatePeriodInSubscriptionMap(state, obj, firstPeriodUUID),
	[ActionsPeriod.SET_CREDIT_INITIAL_DATE]: (state, { periodUUID, ...obj }) =>
		updatePeriodInSubscriptionMap(state, obj, periodUUID),
	[ActionsPeriod.SET_PRICE_LIST]: (state, obj) => updatePeriodInSubscriptionMap(state, obj),
	[ActionsPeriod.SET_EXTERNALLY_LOCKED]: (state, obj) => updatePeriodInSubscriptionMap(state, obj)
};

const SubscriptionGroupContext = React.createContext<
	| {
			state: SubscriptionGroupState;
			dispatch: Dispatch;
			params?: Metadata['params'];
	  }
	| undefined
>(undefined);

const reducer = (state: SubscriptionGroupState, action: AnyAction) => {
	const handler = { ...ACTION_HANDLERS_GROUP, ...ACTION_HANDLERS_PERIOD }[action.type];
	return handler ? handler(state, action) : state;
};

export const getStage = (stages: OrderStage[], params?: Metadata['params'], stageId?: number) => {
	if (!stages.length) {
		return null;
	}

	const defaultStageId = params?.SubscriptionDefaultStageId || params?.defaultStageId;
	let stage = stageId ? stages.find(stage => stage.id === stageId) : null;
	stage = stage ? stage : stages.find(stage => stage.id === defaultStageId) || stages[0];
	return stage;
};

export const getPriceList = (priceLists: PriceList[], priceListId?: number | null, fromClient?: boolean) => {
	const hasPriceLists =
		Tools.FeatureHelper.hasSoftDeployAccess(Tools.FeatureHelper.Feature.PRICE_LISTS) &&
		Tools.FeatureHelper.isAvailable(Tools.FeatureHelper.Feature.PRICE_LISTS);

	let priceList;
	if (priceListId && hasPriceLists) {
		priceList = priceLists.find(pl => pl.id === priceListId);

		if (!priceList) {
			priceList = Tools.AppService.getPriceLists().find(pl => pl.id === priceListId);

			if (priceList && !priceList.active) {
				priceList.name = priceList.name + ' (' + T('default.inactive') + ')';
			}
		}
	}

	if (!priceList || (fromClient && !priceList.active)) {
		priceList = priceLists.find(pl => pl.isDefault);
	}

	return priceList!;
};

const setOrderIntervalAndUpdateDates = (
	dispatch: Dispatch,
	state: SubscriptionGroupState,
	currentPeriod: SubscriptionPeriodState,
	orderInterval: OrderInterval
) => {
	const periodWillChange =
		currentPeriod.periodLength !== 0 && orderInterval !== 1 && currentPeriod.periodLength % orderInterval !== 0;
	let periodLength = currentPeriod.periodLength;
	if (periodWillChange) {
		periodLength = PeriodLengths.find(period => period > 0 && period % orderInterval === 0) ?? 0;
	}

	updatePeriodDates(dispatch, state)(periodLength);
	setOrderInterval(dispatch, currentPeriod)(orderInterval, periodLength);
};

const setPeriodLengthAndUpdateDates = (
	dispatch: Dispatch,
	state: SubscriptionGroupState,
	currentPeriod: SubscriptionPeriodState,
	periodLength: PeriodLength
) => {
	updatePeriodDates(dispatch, state)(periodLength);
	setPeriodLength(dispatch, currentPeriod)(periodLength);
};

const setStartDateAndUpdateDates = (
	dispatch: Dispatch,
	state: SubscriptionGroupState,
	currentPeriod: SubscriptionPeriodState,
	startDate: string,
	isAdvancedMode: boolean
) => {
	if (
		moment(startDate).isAfter(currentPeriod.invoiceStartDate) &&
		Tools.FeatureHelper.hasSoftDeployAccess('SUBSCRIPTION_SPLITS') &&
		currentPeriod.periodLength !== 0
	) {
		updatePeriodDates(dispatch, state)(undefined, startDate);
	}
	setStartDate(dispatch, currentPeriod)(startDate, isAdvancedMode);
};

const setInvoiceStartDateAndUpdateDates = (
	dispatch: Dispatch,
	state: SubscriptionGroupState,
	currentPeriod: SubscriptionPeriodState,
	startDate: string
) => {
	if (Tools.FeatureHelper.hasSoftDeployAccess('SUBSCRIPTION_SPLITS') && currentPeriod.periodLength !== 0) {
		updatePeriodDates(dispatch, state)(undefined, startDate);
	}
	setInvoiceStartDate(dispatch, currentPeriod)(startDate);
};

const setEndDateAndUpdateDates = (
	dispatch: Dispatch,
	state: SubscriptionGroupState,
	currentPeriod: SubscriptionPeriodState,
	endDate?: string
) => {
	if (Tools.FeatureHelper.hasSoftDeployAccess('SUBSCRIPTION_SPLITS') && endDate) {
		updatePeriodDates(dispatch, state)(undefined, undefined, endDate);
	}
	setTerminationDate(dispatch, currentPeriod)(endDate);
};

export const getInitialState = (partial?: Partial<SubscriptionGroupState>): SubscriptionGroupState => {
	return {
		id: partial?.id ?? undefined,
		tempId: partial?.tempId ?? undefined,
		dontWait: partial?.dontWait ?? false,
		client: partial?.client ?? null,
		clientConnection: partial?.clientConnection ?? null,
		contact: partial?.contact ?? null,
		user: partial?.user ?? null,
		description: partial?.description ?? '',
		notes: partial?.notes ?? '',
		currentUUID: partial?.currentUUID ?? 0,
		subscriptionMap: partial?.subscriptionMap ?? {},
		custom: partial?.custom ?? [],
		campaign: partial?.campaign ?? null,
		stage: partial?.stage ?? null,
		isDetailsValid: partial?.isDetailsValid ?? false,
		splitEnabled: partial?.splitEnabled ?? false,
		isAdvancedMode: partial?.isAdvancedMode ?? false,
		saving: partial?.saving ?? false,
		isEdit: partial?.isEdit ?? true,
		isDirty: partial?.isDirty ?? false,
		allowOffset: partial?.allowOffset ?? false,
		errorMessages: partial?.errorMessages ?? {},
		locked: partial?.locked ?? false,
		lockedReason: partial?.lockedReason ?? '',
		fetching: partial?.fetching ?? false,
		step: partial?.step ?? STEP.DETAILS,
		finalStep: partial?.finalStep ?? null,
		diffOrders: [],
		orderToAdd: partial?.orderToAdd ?? null,
		createdFromOrderId: partial?.createdFromOrderId ?? undefined,
		childIndexNr: partial?.childIndexNr ?? 0,
		showPreviewFooter: false,
		invoiceRelatedClient: partial?.invoiceRelatedClient ?? false
	};
};

type ProviderProps = {
	initialState?: Partial<SubscriptionGroupState>;
	children: React.ReactNode;
};

export function SubscriptionGroupProvider({ initialState: partialState, children }: ProviderProps) {
	const params = useSelector(state => state.App?.metadata?.params);

	const initialState = getInitialState(partialState);

	const [state, dispatch] = React.useReducer(reducer, { ...initialState });
	const value = React.useMemo(() => ({ state, dispatch, params }), [state]);

	React.useEffect(() => {
		const stopListeningForGroupUpdates = Tools.$rootScope.$on('agreementGroup.updated', (e, group) => {
			if ((!!group.id && group.id === state.id) || (!!group.tempId && group.tempId === state.tempId)) {
				dispatch({ type: ActionsGroup.SET_LOCKED, locked: false });
				dispatch({ type: ActionsGroup.SET_ID, id: group.id });
			}
		});
		const stopListeningForAgreementUpdates = Tools.$rootScope.$on('agreement.updated', (e, { id, tempId }) => {
			if (state.tempId && state.subscriptionMap) {
				const subscriptionMap = state.subscriptionMap as SubscriptionGroupState['subscriptionMap'];
				const period = Object.values(subscriptionMap).find(period => !!tempId && tempId === period.tempId);

				if (period) {
					period.id = id;
					subscriptionMap[period.uuid] = period;

					dispatch({ type: ActionsGroup.SET_SUBSCRIPTIONS, subscriptionMap });
				}
			}
		});

		return () => {
			stopListeningForGroupUpdates();
			stopListeningForAgreementUpdates();
		};
	}, []);

	return <SubscriptionGroupContext.Provider value={value}>{children}</SubscriptionGroupContext.Provider>;
}

export function useSubscriptionGroupContext() {
	const context = React.useContext(SubscriptionGroupContext);
	if (typeof context === 'undefined') {
		throw new Error('getSubscriptionGroupState must be used within a getSubscriptionGroupState Provider');
	}

	const { state, dispatch, params } = context;

	const actionsGroup = {
		setSaving: setSaving(dispatch, state),
		saveSubscriptionGroup: saveSubscriptionGroup(dispatch, state),
		updateGroupFields: updateGroupFields(dispatch, state),
		setDiffOrders: setDiffOrders(dispatch, state),
		showDiffOrder: showDiffOrder(dispatch, state),
		setInvoiceRelatedClient: setInvoiceRelatedClient(dispatch, state),

		updateAdvancedMode: updateAdvancedMode(dispatch, state, params),

		setInvoiceStartDateGroup: setInvoiceStartDateGroup(dispatch, state),
		expandPreviewFooter: expandPreviewFooter(dispatch, state),
		setPeriodChildren: setPeriodChildren(dispatch, state),
		deleteChildPeriod: deleteChildPeriod(dispatch, state),
		deleteSplitPeriod: deleteSplitPeriod(dispatch, state),
		splitSubscription: splitSubscription(dispatch, state),
		setStartDateGroup: setStartDateGroup(dispatch, state),
		setCreditDate: updateCreditDate(dispatch, state),
		setCurrentChild: setCurrentChild(dispatch, state),
		addFuturePeriod: addFuturePeriod(dispatch, state),
		updateCurrent: updateCurrent(dispatch, state),
		deletePeriod: deletePeriod(dispatch, state),
		setFinalStep: setFinalStep(dispatch, state),
		setStep: setStep(dispatch, state),
		getFulfillmentChanges: getFulfillmentChanges(dispatch, state)
	};

	const { subscriptionMap } = state;

	const currentPeriod = subscriptionMap[state.currentUUID];
	const actionsPeriod = {
		setOrderRows: useCallback(
			(orderRows: OrderRow[]) => setOrderRows(dispatch, currentPeriod, state.childIndexNr)(orderRows),
			[currentPeriod, state.childIndexNr]
		),
		setCurrency: setCurrency(dispatch, currentPeriod),
		setCustomFields: setCustomFields(dispatch, currentPeriod),
		setPriceList: setPriceList(dispatch, currentPeriod),

		toggleRenewal: toggleRenewal(dispatch, currentPeriod),
		setOffset: setOffset(dispatch, currentPeriod),
		setNoticePeriod: setNoticePeriod(dispatch, currentPeriod),

		/* These actions updates both the period and the dates of future periods */
		setTerminationDate: (endDate?: string) => setEndDateAndUpdateDates(dispatch, state, currentPeriod, endDate),
		setInvoiceStartDate: (startDate: string) =>
			setInvoiceStartDateAndUpdateDates(dispatch, state, currentPeriod, startDate),
		setStartDate: (startDate: string, isAdvancedMode: boolean) =>
			setStartDateAndUpdateDates(dispatch, state, currentPeriod, startDate, isAdvancedMode),
		setPeriodLength: (periodLength: PeriodLength) =>
			setPeriodLengthAndUpdateDates(dispatch, state, currentPeriod, periodLength),
		setOrderInterval: (orderInterval: OrderInterval) =>
			setOrderIntervalAndUpdateDates(dispatch, state, currentPeriod, orderInterval),
		setPeriodExternallyLocked: (externallyLocked: boolean) =>
			dispatch({ type: ActionsPeriod.SET_EXTERNALLY_LOCKED, externallyLocked })
	};

	const sortedSubscription = Object.values(subscriptionMap).sort((period1, period2) =>
		moment(period1.startDate) > moment(period2.startDate) ? 1 : -1
	);
	const firstPeriod = sortedSubscription[0];
	const lastPeriod = sortedSubscription[sortedSubscription.length - 1];
	const subscriptionIndex = sortedSubscription.findIndex(period => period.uuid === state.currentUUID);
	const previousPeriod = sortedSubscription[subscriptionIndex - 1];
	const nextPeriod = sortedSubscription[subscriptionIndex + 1];
	const activePeriod =
		[...sortedSubscription].reverse().find(sub => moment().isSameOrAfter(moment(sub.startDate))) ||
		sortedSubscription[0];

	const nextActivePeriod = sortedSubscription.find(sub => moment(sub.startDate).isAfter(activePeriod.startDate));
	const isOnStartPeriod = firstPeriod?.uuid === state.currentUUID;

	return {
		state,
		lastPeriod,
		nextPeriod,
		firstPeriod,
		activePeriod,
		currentPeriod,
		previousPeriod,
		isOnStartPeriod,
		nextActivePeriod,
		...actionsGroup,
		...actionsPeriod,
		useStatics
	};
}

export function useCurrentSubscriptionPeriod() {
	const {
		state: { childIndexNr, subscriptionMap },
		currentPeriod: current
	} = useSubscriptionGroupContext();

	const currentPeriod = subscriptionMap[current.uuid ?? 0] ?? current;
	const currentChild = currentPeriod.children?.[childIndexNr];

	return { currentPeriod, currentChild };
}
