import {
	RESET,
	UPDATE,
	EDIT_PREVIEW_FIELD,
	UPDATE_HTML,
	UPDATE_FORM,
	UPDATE_SIDEBAR_VIEW,
	SIDEBAR_VIEW,
	SELECT_FIELD_TYPE,
	TOGGLE_SIDEBAR,
	SEARCH_FIELD,
	CLEAR_SEARCH_FIELD,
	FIELD_TYPE,
	UPDATE_VALIDITY,
	STEP
} from '../actions/FormEditorActions';

export const initialState = {
	stepErrors: {
		[STEP.DESIGN]: {},
		[STEP.SETTINGS]: {}
	},
	fieldType: null,
	valid: true,
	accountCustomFields: [],
	contactCustomFields: [],
	clientFields: [],
	params: {},
	meta: null,
	html: '',
	form: {},
	accountProfile: null,
	optIns: null,
	hasVerifyDomainAccess: null,
	editPreviewField: null,
	hasNewFields: null,
	sidebarView: 'edit',
	availableFields: null,
	loading: false,
	saving: false,
	searchText: '',
	socialEvent: null
};

const ACTION_HANDLERS = {
	[RESET]: () => ({ ...initialState }),
	[UPDATE]: (state, action) => ({ ...state, ...action.payload }),
	[UPDATE_SIDEBAR_VIEW]: (state, action) => ({
		...state,
		sidebarView: action.payload.sidebarView,
		searchText: ''
	}),
	[UPDATE_HTML]: (state, action) => ({
		...state,
		currentHash: action.payload.currentHash,
		html: action.payload.html
	}),
	[UPDATE_FORM]: (state, action) => ({
		...state,
		form: action.payload.form,
		fields: action.payload.form.fields
	}),
	[EDIT_PREVIEW_FIELD]: (state, action) => ({
		...state,
		editPreviewField: action.payload.fieldName,
		sidebarView: SIDEBAR_VIEW.EDIT
	}),
	[SELECT_FIELD_TYPE]: (state, action) => ({
		...state,
		sidebarView: SIDEBAR_VIEW.ADD_FIELD,
		fieldType: action.payload.fieldType
	}),
	[TOGGLE_SIDEBAR]: state => {
		const sidebarView = !state.sidebarView ? SIDEBAR_VIEW.EDIT : null;

		return { ...state, sidebarView };
	},
	[SEARCH_FIELD]: (state, action) => ({
		...state,
		sidebarView: SIDEBAR_VIEW.ADD_FIELD,
		fieldType: FIELD_TYPE.DATA_FIELD,
		searchText: action.payload.searchText
	}),
	[CLEAR_SEARCH_FIELD]: state => ({
		...state,
		searchText: ''
	}),
	[UPDATE_VALIDITY]: (state, action) => ({
		...state,
		valid: action.payload.valid,
		stepErrors: action.payload.stepErrors
	})
};

export default (state = initialState, action) => {
	const handler = ACTION_HANDLERS[action.type];

	return handler ? handler(state, action) : state;
};
