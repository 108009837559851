import React from 'react';
import Dropzone from 'react-dropzone';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import moment from 'moment';
import { Table, TableHeader, TableRow, TableColumn, Tooltip, Button, Icon } from '@upsales/components';

import { getFileIcon } from 'App/babel/helpers/file';

import './FilesTabContent.scss';

function FilesTabContent({ files, newFiles, onDrop, onRemove }) {
	const t = window.Tools.$translate;
	const account = Tools.AppService.getAccountSelf();
	const allFiles = [...files, ...newFiles];
	const columns = [
		{ title: '' },
		{ title: t('file.fileName') },
		{ title: t('file.fileSize') },
		{ title: t('file.uploadedDate') },
		{ title: t('file.uploader') },
		{ title: '' }
	];

	const hasDocuments = Tools.FeatureHelper.isAvailable(Tools.FeatureHelper.Feature.DOCUMENTS);

	function downloadFile(file) {
		if (file.id && hasDocuments) {
			window.Tools.File.download(file.id);
		}
	}

	return (
		<div className="FilesTabContent">
			<Table>
				<TableHeader columns={columns} />
				{allFiles.map(file => (
					<TableRow key={file.id || file.lastModified}>
						<TableColumn className="TableColumn__icon" onClick={downloadFile.bind(null, file)}>
							<i className={classNames('fa', getFileIcon(file.mimetype))} />
						</TableColumn>
						<TableColumn title={file.filename || file.name} onClick={downloadFile.bind(null, file)} />
						<TableColumn
							title={(file.size / 1000).toFixed(0) + ' kb'}
							onClick={downloadFile.bind(null, file)}
						/>
						<TableColumn
							title={moment(file.uploadDate).fromNow()}
							onClick={downloadFile.bind(null, file)}
						/>
						<TableColumn onClick={downloadFile.bind(null, file)}>
							<ReactTemplates.TOOLS.avatar
								user={file.user || account}
								size={15}
								avatarService={Tools.avatarService}
								style={{ marginTop: '0px' }}
							/>
							{file.user ? file.user.name : account.name}
						</TableColumn>
						<TableColumn className="TableColumn__remove">
							{hasDocuments ? (
								<Tooltip position="left" title={t('default.delete')}>
									<Button type="link" className="delete-trigger" onClick={onRemove.bind(null, file)}>
										<Icon name="trash" />
									</Button>
								</Tooltip>
							) : null}
						</TableColumn>
					</TableRow>
				))}
			</Table>

			{!allFiles.length && (
				<div className="FilesTabContent__NoFiles">
					<img src="img/empty-briefcase.svg" alt="Briefcase" />
					<div>{t('file.noFiles')}</div>
				</div>
			)}

			{hasDocuments ? (
				<Dropzone onDrop={onDrop}>
					{({ getRootProps, getInputProps, isDragActive }) => (
						<div
							{...getRootProps()}
							className={classNames('FilesTabContent__dropzone', {
								'FilesTabContent__dropzone--active': isDragActive
							})}
						>
							<input {...getInputProps()} />
							<h4>{t('default.uploadFile')}</h4>
							<Button type="link">{t('file.uploadHelpText_split1')}</Button>
							<div>{t('file.uploadHelpText_split2')}</div>
						</div>
					)}
				</Dropzone>
			) : null}
		</div>
	);
}

FilesTabContent.propTypes = {
	files: PropTypes.array.isRequired,
	newFiles: PropTypes.array.isRequired,
	onDrop: PropTypes.func.isRequired,
	onRemove: PropTypes.func.isRequired
};

export default FilesTabContent;
