import React from 'react';

import { AssistChip, Input, Label, Progress, Row, Tooltip } from '@upsales/components';
import BemClass from '@upsales/components/Utils/bemClass';
import T from 'Components/Helpers/translate';
import '../PriceListDrawer.scss';
import { PriceListToSave } from 'App/resources/Model/PriceList';

type Props = {
	classes: BemClass;
	priceList: PriceListToSave;
	isCodeAvailable: (code: string | null, id?: number) => boolean;
	updatePriceList: (savedPriceList: PriceListToSave) => void;
};

function CodeInput({ classes, priceList, isCodeAvailable, updatePriceList }: Props) {
	const appsWithPriceLists = Tools.AppService.getMetadata().integrations.active.filter(app =>
		app.inits.includes('limit_pricelists')
	);

	const appsWithPriceListsNames = appsWithPriceLists.map(app => app.name);

	const limitPriceLists = appsWithPriceLists.length > 0;

	function onlyAllowLettersNumbersSpaces(str: string) {
		if (str === '' || /^[a-z0-9\s]+$/i.test(str)) {
			updatePriceList({ ...priceList, code: str });
		}
	}
	return (
		<div className={classes.elem('codeInput').b()}>
			<Row>
				<Label>{T('pricelist.drawer.code')}</Label>
				{limitPriceLists ? null : (
					<Progress hideText percentage={((priceList.code?.length ?? 0) / 128) * 100} />
				)}
			</Row>
			<Tooltip
				title={T('pricelist.drawer.synced', { apps: appsWithPriceListsNames })}
				disabled={!limitPriceLists}
			>
				<Row>
					<Input
						maxLength={128}
						value={priceList.code ?? ''}
						disabled={limitPriceLists}
						onChange={e => onlyAllowLettersNumbersSpaces(e.target.value)}
					/>
					{!isCodeAvailable(priceList.code, priceList.id) ? (
						<AssistChip type="danger" title={T('default.alreadyExist')} icon="exclamation-circle" />
					) : null}
				</Row>
			</Tooltip>
		</div>
	);
}

export default CodeInput;
