import { useRef } from 'react';

/** Get stable keys for arrays which changes in size
 *
 * @param init - initial value to start from. Like the initial length of the array
 * @returns function which increments and yields a new key each call
 */
const useStableKeys = (init: number) => {
	const keyRef = useRef(init);
	const getStableKey = () => {
		return ++keyRef.current;
	};

	return getStableKey;
};

export default useStableKeys;
