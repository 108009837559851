import React from 'react';
import { Column, RadioItem, Label } from '@upsales/components';
import { Option } from './ReportcenterFilterDateHelpers';
import BemClass from '@upsales/components/Utils/bemClass';
import T from 'Components/Helpers/translate';
import bemClass from '@upsales/components/Utils/bemClass';
import './ReportcenterFilterDateSelectOptions.scss';

type Props = {
	options: Map<string, Option[]>;
	classes: BemClass;
	onChange: (type: string, value: string | object | null) => void;
	value: string;
};

const Options = ({ options, onChange, value }: Props) => {
	const classes = new bemClass('ReportcenterFilterDateSelectOptions');
	return (
		<div className={classes.b()}>
			{Array.from(options).map(([periodCategory, periods]) => {
				return (
					<Column key={periodCategory}>
						<Label>{periodCategory}</Label>
						{periods.map(period => (
							<RadioItem
								key={period.value}
								size="sm"
								checked={period.value === value}
								label={T(period.title)}
								value={period.value}
								onChange={val => {
									onChange('Date', val);
									onChange('CustomDate', null);
								}}
							/>
						))}
					</Column>
				);
			})}
		</div>
	);
};

export default Options;
