import './ReportcenterWidgetActiveFilters.scss';

import { isEmpty, capitalize, snakeCase, cloneDeep } from 'lodash';
import React, { useMemo } from 'react';
import { renderToString } from 'react-dom/server';

import T from 'Components/Helpers/translate';
import BemClass from '@upsales/components/Utils/bemClass';
import { Icon, Block, Tooltip, Text, IconName } from '@upsales/components';
import Project from 'App/resources/Model/Project';
import {
	RCDashboardFilter,
	RCWidgetFilter,
	RCDashboardFilterValue,
	RCDashboardCustomFilterValue
} from 'Resources/ReportDashboard';
import ClientModel from 'App/resources/Model/Client';
import { RCWidgetMeta } from 'Resources/ReportWidget';
import { getCustomField, titles } from '../reportCenterHelpers';
import { renderSelected as renderDate } from 'App/pages/Reportcenter/ReportcenterContent/ReportcenterFilter/ReportcenterFilterDate';
import {
	renderSelected as renderUser,
	renderSelectedCustom as renderUserCustom
} from 'App/pages/Reportcenter/ReportcenterContent/ReportcenterFilter/ReportcenterFilterUser';
import { renderSelected as renderStage } from 'App/pages/Reportcenter/ReportcenterContent/ReportcenterFilter/ReportcenterFilterStage';
import { renderSelected as renderCampaign } from 'App/pages/Reportcenter/ReportcenterContent/ReportcenterFilter/ReportcenterFilterCampaign';
import { renderSelected as renderAccountManager } from 'App/pages/Reportcenter/ReportcenterContent/ReportcenterFilter/ReportcenterFilterAccountManager';
import { renderSelected as renderCurrency } from 'App/pages/Reportcenter/ReportcenterContent/ReportcenterFilter/ReportcenterFilterCurrency';
import { renderSelected as renderClientCategory } from 'App/pages/Reportcenter/ReportcenterContent/ReportcenterFilter/ReportcenterFilterClientCategory';
import { renderSelected as renderProduct } from 'App/pages/Reportcenter/ReportcenterContent/ReportcenterFilter/ReportcenterFilterProduct';
import { renderSelected as renderActivityType } from 'App/pages/Reportcenter/ReportcenterContent/ReportcenterFilter/ReportcenterFilterActivityType';
import { renderSelected as renderProductCategory } from 'App/pages/Reportcenter/ReportcenterContent/ReportcenterFilter/ReportCenterFilterProductCategory';
import { renderSelected as renderClient } from 'App/pages/Reportcenter/ReportcenterContent/ReportcenterFilter/ReportcenterFilterClient';
import { renderSelected as renderAppointmentType } from 'App/pages/Reportcenter/ReportcenterContent/ReportcenterFilter/ReportcenterFilterAppointmentType';
import { renderSelected as renderFlow } from 'App/pages/Reportcenter/ReportcenterContent/ReportcenterFilter/ReportcenterFilterFlow';
import { renderSelected as renderForm } from 'App/pages/Reportcenter/ReportcenterContent/ReportcenterFilter/ReportcenterFilterForm';
import { renderSelected as renderEvent } from 'App/pages/Reportcenter/ReportcenterContent/ReportcenterFilter/ReportcenterFilterEvent';
import { renderSelected as renderEasyBooking } from 'App/pages/Reportcenter/ReportcenterContent/ReportcenterFilter/ReportcenterFilterEasyBooking';
import { renderSelected as renderLabel } from 'App/pages/Reportcenter/ReportcenterContent/ReportcenterFilter/ReportcenterFilterLabel';
import { renderSelected as renderClientNewSale } from 'App/pages/Reportcenter/ReportcenterContent/ReportcenterFilter/ReportcenterFilterClientNewSale';
import { renderSelected as renderAddress } from 'App/pages/Reportcenter/ReportcenterContent/ReportcenterFilter/ReportcenterFilterAddress';
import { renderSelected as renderJourneyStepClient } from 'App/pages/Reportcenter/ReportcenterContent/ReportcenterFilter/ReportcenterFilterJourneyStepClient';
import { renderSelected as renderTicketType } from 'App/pages/Reportcenter/ReportcenterContent/ReportcenterFilter/ReportcenterFilterTicketType';
import CustomField from 'App/resources/Model/CustomField';

interface IndexedRCDashboardFilter {
	Custom?: { value: RCDashboardCustomFilterValue; comparison?: string };
	[key: string]:
		| { value: RCDashboardFilterValue | RCDashboardCustomFilterValue | undefined; comparison?: string }
		| undefined;
}

interface Props {
	dashboardFilters: RCDashboardFilter;
	widgetFilters: RCWidgetFilter[];
	campaigns: Project[];
	disabled?: boolean;
	clients: ClientModel[];
	onEdit?: () => void;
	widgetMeta: RCWidgetMeta;
}

type FilterMapper = {
	[key: string]: {
		icon: IconName;
		renderText: (
			T: (tag: string, opts?: object | undefined) => string,
			value: RCDashboardFilterValue,
			filter: RCDashboardFilter,
			campaigns: Project[],
			clients: ClientModel[],
			customField?: CustomField
		) => string;
		hasValue: (value: RCDashboardFilterValue | undefined | null) => boolean;
	};
};

const renderRole = (filter: RCDashboardFilter) => {
	let res = `${T('report.widget.activeFilters.roles')}: `;
	const roleNames = Tools.AppService.getRoles()
		.filter(role => {
			let roleId = role.id;
			if (typeof roleId === 'string') {
				roleId = parseInt((roleId as string).replace('role-', ''), 10);
			}
			return filter?.Role?.value?.includes?.(roleId);
		})
		.map(role => role.name);
	res += roleNames.join(', ');
	return res;
};

const hasValue: FilterMapper[string]['hasValue'] = value =>
	typeof value === 'object' ? !!value && !!Object.keys(value).length : !!value && !!value.length;
export const filterMapper: FilterMapper = {
	Date: {
		icon: 'calendar',
		renderText: (T, value, filter) => renderDate(value as string),
		hasValue
	},
	User: {
		icon: 'user',
		renderText: (T, value, filter) => renderUser(filter, false),
		hasValue
	},
	Stage: {
		icon: 'opportunity',
		renderText: (T, value, filter) => renderStage(filter, false),
		hasValue
	},
	Campaign: {
		icon: 'list',
		renderText: (T, value, filter, campaigns) => renderCampaign(filter, campaigns),
		hasValue
	},
	ClientCampaign: {
		icon: 'list',
		renderText: (T, value, filter, campaigns) => renderCampaign(filter, campaigns, 'ClientCampaign'),
		hasValue
	},
	CallList: {
		icon: 'list',
		renderText: (T, value, filter, campaigns) => renderCampaign(filter, campaigns, 'CallList'),
		hasValue
	},
	Flow: {
		icon: 'code-fork',
		renderText: (T, value, filter) => renderFlow(filter),
		hasValue
	},
	Form: {
		icon: 'form',
		renderText: (T, value, filter) => renderForm(filter),
		hasValue
	},
	EasyBooking: {
		icon: 'calendar',
		renderText: (T, value, filter) => renderEasyBooking(filter),
		hasValue
	},
	Event: {
		icon: 'rocket',
		renderText: (T, value, filter) => renderEvent(filter),
		hasValue
	},
	AccountManager: {
		icon: 'user',
		renderText: (T, value, filter) => renderAccountManager(filter, false),
		hasValue
	},
	Currency: {
		icon: 'euro-sign',
		renderText: (T, value, filter) => renderCurrency(filter, false),
		hasValue
	},
	ClientCategory: {
		icon: 'tags',
		renderText: (T, value, filter) => renderClientCategory(filter, false),
		hasValue
	},
	Product: {
		icon: 'cube',
		renderText: (T, value, filter) => renderProduct(filter, true),
		hasValue
	},
	ProductCategory: {
		icon: 'tags',
		renderText: (T, value, filter) => renderProductCategory(filter),
		hasValue
	},
	Role: {
		icon: 'user',
		renderText: (T, value, filter) => renderRole(filter),
		hasValue
	},
	ActivityType: {
		icon: 'opportunity',
		renderText: (T, value, filter) => renderActivityType(filter, false),
		hasValue
	},
	Client: {
		icon: 'home',
		renderText: (T, value, filter, campaigns, clients) => renderClient(filter, true, clients),
		hasValue
	},
	Label: {
		icon: 'tags',
		renderText: (T, value, filter) => renderLabel(filter),
		hasValue
	},
	ClientNewSale: {
		icon: 'opportunity',
		renderText: (T, value, filter) => renderClientNewSale(filter, false),
		hasValue
	},
	ClientAddressesCountry: {
		icon: 'home',
		renderText: (T, value, filter) => renderAddress(filter, 'country'),
		hasValue
	},
	ClientAddressesCity: {
		icon: 'home',
		renderText: (T, value, filter) => renderAddress(filter, 'city'),
		hasValue
	},
	ClientAddressesState: {
		icon: 'home',
		renderText: (T, value, filter) => renderAddress(filter, 'state'),
		hasValue
	},
	JourneyStepClient: {
		icon: 'opportunity',
		renderText: (T, value, filter) => renderJourneyStepClient(filter),
		hasValue
	},
	Type: {
		icon: 'opportunity',
		renderText: (T, value) => {
			const salesModelOption = Tools.AppService.getMetadata().params.SalesModelOption?.toUpperCase?.();
			const types = Array.isArray(value)
				? value.map((type: string | number) => T(`arrchange.type.${type}`)).join(', ')
				: value;
			return `${T('report.widget.activeFilters.recurringType', { salesModelOption })}: ${types}`;
		},
		hasValue
	},
	AppointmentType: {
		icon: 'opportunity',
		renderText: (T, value, filter) => renderAppointmentType(filter),
		hasValue
	},
	TicketType: {
		icon: 'tags',
		renderText: (T, value, filter) => renderTicketType(filter),
		hasValue
	},
	Custom: {
		icon: 'list',
		renderText: (T, value, filter, campaigns, clients, customField) => {
			if (value && Array.isArray(value)) {
				if (customField?.datatype === 'Boolean') {
					// eslint-disable-next-line eqeqeq
					value = value.map((v: string | number) => (v == 1 ? T('default.yes') : T('default.no')));
				}
				if (customField?.datatype === 'Date') {
					value = value.map((v: string | number) => T(titles.date[v]));
				}
				if (customField?.datatype === 'User' || customField?.datatype === 'Users') {
					value = renderUserCustom(value as string[], value.length > 1).split(':')[1];
					return customField?.name + ': ' + value;
				}
				return customField?.name + ': ' + value.join(',');
			} else {
				return '';
			}
		},
		hasValue
	}
};

const ReportcenterWidgetActiveFilters = ({
	campaigns,
	clients,
	dashboardFilters,
	widgetFilters = [],
	disabled,
	onEdit,
	widgetMeta
}: Props) => {
	const classes = new BemClass('ReportcenterWidgetActiveFilters');
	const convertedWidgetFilters = useMemo(() => {
		return widgetFilters.reduce((acc: IndexedRCDashboardFilter, widgetFilter: RCWidgetFilter) => {
			if (widgetFilter.attribute.includes('_custom_')) {
				if (!acc.Custom) {
					acc.Custom = { value: {} } as { value: RCDashboardCustomFilterValue };
				}
				const [entity, fieldId] = widgetFilter.attribute.split('_custom_');
				if (!acc.Custom!.value[entity]) {
					acc.Custom!.value[entity] = {};
				}
				acc.Custom.value[entity][parseInt(fieldId)] = {
					value: widgetFilter.value.split(',').map(v => decodeURIComponent(v))
				};
				return acc;
			}
			const key = capitalize(widgetFilter.attribute);
			const stringValueKeys = [
				'currency',
				'type',
				'clientNewSale',
				'clientAddressesCountry',
				'clientAddressesCity',
				'clientAddressesState'
			];
			if (widgetFilter.attribute === 'date') {
				acc[key] = { value: widgetFilter.value, comparison: widgetFilter.comparison };
			} else if (stringValueKeys.includes(widgetFilter.attribute)) {
				acc[key] = { value: widgetFilter.value.split(','), comparison: widgetFilter.comparison };
			} else if (widgetFilter.attribute === 'role') {
				acc[key] = {
					value: widgetFilter.value
						.split(',')
						.map(value => parseInt(value.replace('role-', ''), 10))
						.filter(value => {
							return !isNaN(value);
						}),
					comparison: widgetFilter.comparison
				};
			} else if (widgetFilter.attribute === 'user') {
				acc[key] = {
					value: widgetFilter.value
						.split(',')
						.map(value => (value !== 'null' ? parseInt(value, 10) : value))
						.filter(value => {
							if (value === 'null') {
								return true;
							}
							return !isNaN(value);
						}),
					comparison: widgetFilter.comparison
				};
			} else {
				acc[key] = {
					value: widgetFilter.value
						.split(',')
						.map(value => parseInt(value, 10))
						.filter(value => {
							return !isNaN(value);
						}),
					comparison: widgetFilter.comparison
				};
			}
			return acc;
		}, {});
	}, [widgetFilters]);

	const getApplicableFilters = () => {
		const applicableFilters = cloneDeep(dashboardFilters);
		const availableWidgetFilters = widgetMeta.filterTypes;
		const customFieldTypes = widgetMeta.customFieldTypes;
		let hasFiltersToShow = false;

		// Remove irrelevant custom field filters
		if (applicableFilters.Custom && applicableFilters.Custom.value) {
			applicableFilters.Custom.value = Object.entries(applicableFilters.Custom.value).reduce(
				(res, [key, value]) => {
					if (customFieldTypes?.entities.includes(capitalize(key))) {
						res[key] = value;
					}
					return res;
				},
				{} as RCDashboardCustomFilterValue
			);
		}

		const filterKeys = Object.keys(applicableFilters) as Array<keyof typeof applicableFilters>;
		filterKeys.forEach(filterKey => {
			const snakedFilterKey = snakeCase(filterKey).toUpperCase();
			if (
				(!applicableFilters[filterKey] ||
					(Array.isArray((applicableFilters[filterKey] as any)?.value) &&
						!(applicableFilters[filterKey] as { value?: Array<keyof typeof applicableFilters> })?.value
							?.length) ||
					!availableWidgetFilters[snakedFilterKey]) &&
				filterKey !== 'Custom'
			) {
				applicableFilters[filterKey] = undefined;
			} else {
				hasFiltersToShow = true;
			}
		});
		return hasFiltersToShow ? applicableFilters : {};
	};

	const hasActiveFilters = (filters: Omit<RCDashboardFilter, 'CustomDate' | 'FirstAppointmentType'>) => {
		if (!filters) {
			return false;
		}
		return Object.entries(filters).some(([key, value]) => {
			if (!value?.value) {
				return false;
			}
			const filter = filterMapper[key];
			return filter?.hasValue?.(value.value) ?? false;
		});
	};

	const hasActiveDashboardFilters = useMemo(() => hasActiveFilters(dashboardFilters), [dashboardFilters]);
	const hasActiveWidgetFilters = useMemo(() => hasActiveFilters(convertedWidgetFilters), [dashboardFilters]);

	const renderFilter = (
		type: string,
		key: string,
		value: RCDashboardFilterValue,
		rawFilters: RCDashboardFilter,
		checkAgainstDashboardFilters?: boolean,
		customField?: CustomField,
		customFieldEntity?: string
	) => {
		const filter = filterMapper[key];
		if (!filter?.hasValue?.(value)) {
			return null;
		}

		const overridden =
			customField && customFieldEntity
				? checkAgainstDashboardFilters &&
				  !!dashboardFilters &&
				  filter.hasValue(dashboardFilters.Custom?.value?.[customFieldEntity]?.[customField.id]?.value)
				: checkAgainstDashboardFilters &&
				  !!dashboardFilters &&
				  filter.hasValue(dashboardFilters[key as keyof typeof dashboardFilters]?.value);

		return (
			<Block
				key={customField ? `${type}-${key}-${customFieldEntity}-${customField.id}` : `${type}-${key}`}
				space="ptm pbm"
				className={classes
					.elem(type)
					.mod({ [key]: key, overridden })
					.b()}
			>
				<Icon color={overridden ? 'grey-10' : 'green'} space="mrm" name={filter?.icon} />
				<Text color={overridden ? 'grey-10' : 'green'}>
					{filter.renderText(T, value, rawFilters, campaigns, clients, customField)}
				</Text>
			</Block>
		);
	};

	const renderFilters = (
		type: string,
		filters: Omit<RCDashboardFilter, 'CustomDate'>,
		checkAgainstDashboardFilters?: boolean
	) => {
		const capitalizedType = capitalize(type);
		const renderedFilters: JSX.Element[] = [];
		Object.entries(filters).forEach(([key, value]) => {
			if (value && value.value) {
				if (key === 'Custom' && typeof value.value !== 'string' && !Array.isArray(value.value)) {
					for (const entity of Object.keys(value.value)) {
						for (const fieldId of Object.keys(value.value[entity])) {
							const customFilterValue = value.value[entity][parseInt(fieldId)].value;
							const field = getCustomField(capitalize(entity), parseInt(fieldId));
							const renderedFilter = renderFilter(
								type,
								key,
								customFilterValue,
								filters,
								checkAgainstDashboardFilters,
								field,
								entity
							);
							if (renderedFilter) {
								renderedFilters.push(renderedFilter);
							}
						}
					}
				} else {
					const renderedFilter = renderFilter(type, key, value.value, filters, checkAgainstDashboardFilters);
					if (renderedFilter) {
						renderedFilters.push(renderedFilter);
					}
				}
			}
		});
		return (
			<>
				<Block
					space="ptm pbm"
					className={classes
						.elem('filterTitle')
						.mod({ [type]: type })
						.b()}
				>
					<Text bold className={classes.elem(`${type}Title`).b()}>
						{T(`report.widget.activeFilters.${type}`)}
					</Text>
				</Block>
				<div className={classes.elem('filterContainer').b()}>
					{!isEmpty(renderedFilters) ? (
						renderedFilters
					) : (
						<Block space="ptm pbm" className={classes.elem('noActiveFilters').b()}>
							<Text italic color="grey-10" className={classes.elem(`noActive${capitalizedType}`).b()}>
								{T(`report.widget.activeFilters.noActive${capitalizedType}`)}
							</Text>
						</Block>
					)}
				</div>
			</>
		);
	};

	const renderTooltip = () => {
		return renderToString(
			<div>
				{renderFilters('dashboardFilter', hasActiveDashboardFilters ? getApplicableFilters() : {})}
				{renderFilters('widgetFilter', hasActiveWidgetFilters ? convertedWidgetFilters : {}, true)}
			</div>
		);
	};

	return (
		<div className={classes.b()}>
			<Tooltip
				className={classes.elem('tooltip').b()}
				title={renderTooltip()}
				theme="white"
				position="bottom"
				distance={1}
				interactive={'true'}
				disabled={disabled || false}
			>
				<Icon color="grey-10" className={classes.elem('filterIcon').b()} name="filter" onClick={onEdit} />
			</Tooltip>
		</div>
	);
};

export default ReportcenterWidgetActiveFilters;
