import { Title, Text, Icon, Block, Flex } from '@upsales/components';
import { DiffOrder } from '../../Context/SubscriptionGroupState';
import { CurrencyFormat } from 'App/babel/utils/numberFormat';
import BemClass from '@upsales/components/Utils/bemClass';
import OrderRow from 'App/resources/Model/OrderRow';
import T from 'Components/Helpers/translate';
import React, { useState } from 'react';
import moment from 'moment';

import './RequiredProratedCheckbox.scss';

type Props = {
	diffOrder: DiffOrder;
	oneOffs?: (OrderRow & { totalValue: number })[];
};

const RequiredProratedCheckbox = ({ diffOrder, oneOffs = [] }: Props) => {
	const classes = new BemClass('RequiredProratedCheckbox');

	const [showOneOffs, setShowOneOffs] = useState(false);
	const currencyFormat = new CurrencyFormat(diffOrder.currency);
	const periodDate = `${moment(diffOrder.visualStartDate).format('L')} - ${moment(diffOrder.visualEndDate).format(
		'L'
	)}`;

	const length = oneOffs.length;
	const height = 50 + (showOneOffs ? 42 * length : 0);
	const productName = T('default.product' + (length > 1 ? 's' : '')).toLowerCase();
	const totalOneOffValue = oneOffs.reduce((sum, curr) => sum + curr.totalValue, 0);

	// TODO: Refactor to use some kind of accordion component instead of this custom implementation. Or why not just list all products?
	return (
		<div className={classes.b()}>
			<Block space="mtl mbm">
				<Title>{`${T('subscription.modal.difforder.period')}: ${periodDate}`}</Title>
			</Block>
			<Text bold size="lg">
				{T('subscription.modal.difforder.createCheckboxTitle', {
					value: currencyFormat.short(diffOrder.totalValue)
				})}
			</Text>
			{oneOffs.length ? (
				<Block
					backgroundColor="super-light-blue"
					onClick={() => setShowOneOffs(prev => !prev)}
					className={classes.elem('one-offs').mod({ rotate: showOneOffs }).b()}
					borderRadius
					space="mtl ptm prl pbm pll"
					style={{ '--height': `${height}px` } as React.CSSProperties}
				>
					<Flex direction="column" alignItems="flex-start" gap="u3">
						<Flex
							justifyContent="space-between"
							alignItems="center"
							className={classes.elem('one-offs-header').b()}
						>
							<div>
								<Text color="blue">
									{T('subscription.modal.difforder.includesOneOffs', { product: productName })}
								</Text>
								<Text color="blue" bold>
									{currencyFormat.short(totalOneOffValue)}
								</Text>
							</div>

							<Icon name="chevron-down" />
						</Flex>
						{oneOffs.map(row => (
							<Flex key={row.id} direction="column" alignItems="flex-start">
								<Text size="sm">{row.product.name}</Text>
								<Text size="sm" bold>
									{currencyFormat.short(row.totalValue)}
								</Text>
							</Flex>
						))}
					</Flex>
				</Block>
			) : null}
		</div>
	);
};

export default RequiredProratedCheckbox;
