import React, { useState, useEffect, useMemo } from 'react';
import { Row, Button, Text, Modal, ModalContent, ModalControls } from '@upsales/components';
import bemClass from '@upsales/components/Utils/bemClass';
import Label from 'App/resources/Model/Label';
import LabelResource from 'App/resources/Label';
import RequestBuilder, { comparisonTypes } from 'Resources/RequestBuilder';
import { makeCancelable } from 'App/babel/helpers/promise';
import logError from 'App/babel/helpers/logError';
import T from 'Components/Helpers/translate';
import ModalOptions from './ModalOptions';
import './AddLabelsMulti.scss';

type Props = {
	close: () => void;
	className: string;
	entityType: string;
	onSave: (selectedLabel: Label) => Promise<any>;
};

type Cancel = () => void;

function findLabels(entityType: string) {
	const rb = new RequestBuilder();
	rb.addFilter({ field: 'entity' }, comparisonTypes.Equals, entityType);
	rb.addSort('name', true);
	return LabelResource.find(rb.build()).then(res => res.data);
}

const AddLabelsMulti = ({ close, className, entityType, onSave }: Props) => {
	const [labels, setLabels] = useState<Required<Label>[]>([]);
	const [saving, setSaving] = useState<boolean>(false);
	const [selectedLabel, setSelectedLabel] = useState<Required<Label> | null>(null);

	const classes = new bemClass('AddLabelsMulti', className);
	const cancels: Cancel[] = [];

	function cancelable(inPromise: Promise<any>) {
		const { cancel, promise } = makeCancelable(inPromise);
		cancels.push(cancel);
		return promise;
	}

	const onSelect = (id: number) => {
		const label = labels.find(label => label.id === id);
		if (label) {
			setSelectedLabel(label);
		}
	};

	function run() {
		if (selectedLabel !== null) {
			setSaving(true);
			const promise = cancelable(onSave(selectedLabel));
			promise
				.then(() => close())
				.catch(error => {
					setSaving(false);
					logError(error, 'AddLabelsMulti: Could not run multiUpdate');
				});
		}
	}

	useEffect(() => {
		const promise = cancelable(findLabels(entityType));
		promise
			.then((loadedLabels: Required<Label>[]) => {
				labels.push(...loadedLabels);
				setLabels(labels);
				if (labels.length) {
					setSelectedLabel(labels[0]);
				}
			})
			.catch(error => logError(error, 'AddLabelsMulti: Could not load labels'));

		return () => cancels.forEach(cancel => cancel());
	}, []);

	const lang = useMemo(() => {
		return {
			cancel: T('default.cancel'),
			save: T('default.save'),
			selectLabel: T('file.addLabel')
		};
	}, []);

	return (
		<Modal className={classes.b()}>
			<ModalContent>
				<Text size="lg" bold={true}>
					{lang.selectLabel}
				</Text>
				<ModalOptions data={labels} onSelect={onSelect} selected={selectedLabel}></ModalOptions>
			</ModalContent>
			<ModalControls>
				<Row direction="row-reverse">
					<Button text={lang.cancel} type="link" color="grey" onClick={() => close()} />
					<Button
						data-test-id="save-button"
						loading={saving}
						text={lang.save}
						disabled={selectedLabel === null}
						onClick={() => run()}
					/>
				</Row>
			</ModalControls>
		</Modal>
	);
};

export default AddLabelsMulti;
