import Resource from './Resource';

class Periodization extends Resource {
	constructor() {
		super('periodization');
		this.dateFields = ['startDate', 'endDate'];
		this.eventName = 'periodization';

		this.notifications = {
			save: () => ({
				title: 'default.saved',
				body: 'saved.periodization',
				style: 'success',
				icon: 'check',
				type: 'body'
			}),
			saveError: () => ({
				title: 'default.error',
				body: 'saveError.periodization',
				style: 'error',
				icon: 'times',
				type: 'body'
			}),
			delete: () => ({
				title: 'default.deleted',
				body: 'deleted.periodization',
				style: 'success',
				icon: 'check',
				type: 'body'
			}),
			deleteError: () => ({
				title: 'default.error',
				body: 'deleteError.periodization',
				style: 'error',
				icon: 'times',
				type: 'body'
			})
		};
	}
}

const resource = new Periodization();
window.Tools.Periodization = resource;
export default resource;
