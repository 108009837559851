import { Block, Column, Icon, Row, Text } from '@upsales/components';
import { useTranslation } from 'Components/Helpers/translate';
import { useSoftDeployAccess } from 'App/components/hooks';
import BemClass from '@upsales/components/Utils/bemClass';
import Hotspot from 'App/components/Hotspot';
import React from 'react';

import './SchedulingHotspot.scss';

type HotspotProps = {
	className?: string;
};

const SchedulingHotspot = ({ className }: HotspotProps) => {
	const classes = new BemClass('SubscriptionSchedulingHotspot', className);
	const { t } = useTranslation();

	return (
		<Hotspot
			title={t('subscription.promo.hotspot.scheduling.title')}
			link="https://support.upsales.com/hc/en-us/articles/13373936335889"
		>
			<Row align="space-between" className={classes.b()}>
				<Column>
					<Block space="mlxl mrxl mbl">
						<Text>{t('subscription.promo.hotspot.scheduling.paragraph.text')}</Text>

						<Block space="mtl">
							<Text size="sm" color="grey-11">
								{t('subscription.promo.hotspot.scheduling.paragraph2.title')}
							</Text>
							<Text>{t('subscription.promo.hotspot.scheduling.paragraph2.text')}</Text>
						</Block>

						<Block space="mtl">
							<Text size="sm" color="grey-11">
								{t('subscription.promo.hotspot.scheduling.paragraph3.title')}
							</Text>
							<Text>{t('subscription.promo.hotspot.scheduling.paragraph3.text')}</Text>
						</Block>

						<Block space="mtl">
							<Text size="sm" color="grey-11">
								{t('subscription.promo.hotspot.scheduling.paragraph4.title')}
							</Text>
							<Text>{t('subscription.promo.hotspot.scheduling.paragraph4.text')}</Text>
						</Block>

						<Block space="mtl">
							<Text size="sm" color="grey-11">
								{t('subscription.promo.hotspot.scheduling.paragraph5.title')}
							</Text>
							<Text>{t('subscription.promo.hotspot.scheduling.paragraph5.text')}</Text>
						</Block>

						<Block space="mtl">
							<Text size="sm" color="grey-11">
								{t('subscription.promo.hotspot.scheduling.paragraph6.title')}
							</Text>
							<Text>{t('subscription.promo.hotspot.scheduling.paragraph6.text')}</Text>
						</Block>
					</Block>
				</Column>
				<Column className={classes.elem('img-column').b()}>
					<Row direction="column" noWrap>
						<Row align="center">
							<img src="img/promo/old-example.png" width="230" />
						</Row>

						<Block space="pll ptl prl pbl" backgroundColor="grey-4">
							<Text color="bright-blue" align="left">
								<Row noWrap align="center">
									<Icon name="info-circle" color="bright-blue" space="mrl" />
									{t('subscription.promo.hotspot.scheduling.info.text')}
								</Row>
							</Text>
						</Block>
					</Row>
				</Column>
			</Row>
		</Hotspot>
	);
};

const ProductsHotspot = ({ className, withChildren }: HotspotProps & { withChildren?: boolean }) => {
	const { t } = useTranslation();

	const lang = {
		title: withChildren ? t('subscription.promo.hotspot.product.title') : t('default.products'),
		paragraph: withChildren
			? t('subscription.promo.hotspot.product.paragraph.text2')
			: t('subscription.promo.hotspot.product.paragraph.text')
	};

	return (
		<Hotspot title={lang.title} renderLeft={withChildren}>
			<Block space="mlxl mrxl" className={className}>
				<Text>{lang.paragraph}</Text>
			</Block>
		</Hotspot>
	);
};

const OrderHotspot = ({ className }: HotspotProps) => {
	const { t } = useTranslation();

	return (
		<Hotspot title={t('subscription.promo.hotspot.order.title')} renderTop>
			<Block space="mlxl mrxl" className={className}>
				<Text>{t('subscription.promo.hotspot.order.paragraph.text')}</Text>

				<Block space="mtl">
					<Text>{t('subscription.promo.hotspot.order.paragraph2.text')}</Text>
				</Block>

				<Block space="mtl">
					<Text>{t('subscription.promo.hotspot.order.paragraph3.text')}</Text>
					<Text bold>{t('subscription.promo.hotspot.order.paragraph3.text2')}</Text>
				</Block>
			</Block>
		</Hotspot>
	);
};

const PlanChangeHotspot = ({ className }: HotspotProps) => {
	const { t } = useTranslation();

	return (
		<Hotspot
			title={t('subscription.promo.hotspot.planChange.title')}
			link="https://support.upsales.com/hc/en-us/articles/13373936335889"
		>
			<Block space="mlxl mrxl mbl" className={className}>
				<Block>
					<Text>{t('subscription.promo.hotspot.planChange.paragraph.text')}</Text>
				</Block>
				<Block space="mtl">
					<Row>
						<Text>{t('subscription.promo.hotspot.planChange.paragraph2.text')}</Text>
						<Block space="mrs" />
						<Text bold>{t('subscription.modal.summary.splitSidebar.button')}</Text>
						<Block space="mrs" />
						<Text>{t('subscription.promo.hotspot.planChange.paragraph2.text3')}</Text>
					</Row>
				</Block>
			</Block>
		</Hotspot>
	);
};

const RenewalCreatedHotspot = ({ className }: HotspotProps) => {
	const { t } = useTranslation();

	return (
		<Hotspot
			hidden
			openOnRender
			title={t('subscription.promo.hotspot.renewalCreated.title')}
			link="https://support.upsales.com/hc/en-us/articles/13373936335889"
		>
			<Block space="mlxl mrxl mbl" className={className}>
				<Text>{t('subscription.promo.hotspot.renewalCreated.paragraph.text')}</Text>

				<Block space="mtl">
					<Text>{t('subscription.promo.hotspot.renewalCreated.paragraph2.text')}</Text>
				</Block>

				<Block space="mtl pll ptl prl pbl" backgroundColor="super-light-green" borderRadius={true}>
					<Row noWrap align="center">
						<Block space="mrxl">
							<Icon name="lightbulb-on-o" />
						</Block>
						<Text>{t('subscription.promo.hotspot.renewalCreated.info.text')}</Text>
					</Row>
				</Block>
			</Block>
		</Hotspot>
	);
};

const CurrentChangeHotspot = ({ className }: HotspotProps) => {
	const { t } = useTranslation();

	return (
		<Hotspot
			title={t('subscription.promo.hotspot.currentChange.title')}
			link="https://support.upsales.com/hc/en-us/articles/13373936335889#h_01GV2V95T59KRT6Y5P8SQS9BE6"
		>
			<Block space="mlxl mrxl mbl" className={className}>
				<Text>{t('subscription.promo.hotspot.currentChange.paragraph.text')}</Text>
			</Block>
		</Hotspot>
	);
};

const FutureChangeHotspot = ({ className }: HotspotProps) => {
	const { t } = useTranslation();

	return (
		<Hotspot
			title={t('subscription.promo.hotspot.futureChange.title')}
			link="https://support.upsales.com/hc/en-us/articles/13373936335889#h_01GV2V9HHWQPYBKVEYT6V80SJY"
			renderLeft
		>
			<Block space="mlxl mrxl mbl" className={className}>
				<Block>
					<Text>{t('subscription.promo.hotspot.futureChange.paragraph.text')}</Text>
				</Block>
				<Block space="mtl">
					<Text>{t('subscription.promo.hotspot.futureChange.paragraph2.text')}</Text>
				</Block>
			</Block>
		</Hotspot>
	);
};

const ProratedOrderHotspot = ({ className }: HotspotProps) => {
	const { t } = useTranslation();

	return (
		<Hotspot
			title={t('subscription.promo.hotspot.proratedOrder.title')}
			link="https://support.upsales.com/hc/en-us/articles/11005263803537"
		>
			<Block space="mlxl mrxl mbl" className={className}>
				<Block>
					<Text>{t('subscription.promo.hotspot.proratedOrder.paragraph.text')}</Text>
				</Block>
				<Block space="mtl">
					<Text>{t('subscription.promo.hotspot.proratedOrder.paragraph2.text')}</Text>
				</Block>
			</Block>
		</Hotspot>
	);
};

type Props = {
	type: 'scheduling' | 'product' | 'order' | 'childProduct' | 'planChange' | 'currentChange' | 'futureChange' | 'renewalCreated' | 'proratedOrder';
};

const SubscriptionHotspot = ({ type }: Props) => {
	const hasPromoFlag = useSoftDeployAccess('SUBSCRIPTION_PROMO');
	const hasSubscriptionModal = useSoftDeployAccess('SUBSCRIPTION_MODAL');
	const classes = new BemClass('SubscriptionHotspot').mod({ [type]: true });

	if (!hasPromoFlag || hasSubscriptionModal) return null;

	switch (type) {
		case 'scheduling':
			return <SchedulingHotspot className={classes.b()} />;
		case 'product':
			return <ProductsHotspot className={classes.b()} />;
		case 'childProduct':
			return <ProductsHotspot className={classes.b()} withChildren />;
		case 'order':
			return <OrderHotspot className={classes.b()} />;
		case 'planChange':
			return <PlanChangeHotspot className={classes.b()} />;
		case 'currentChange':
			return <CurrentChangeHotspot className={classes.b()} />;
		case 'futureChange':
			return <FutureChangeHotspot className={classes.b()} />;
		case 'renewalCreated':
			return <RenewalCreatedHotspot className={classes.b()} />;
		case 'proratedOrder':
			return <ProratedOrderHotspot className={classes.b()} />;
		default:
			return null;
	}
};

export default SubscriptionHotspot;
