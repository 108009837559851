import React from 'react';
import PropTypes from 'prop-types';
import { find } from 'lodash';
import BemClass from '@upsales/components/Utils/bemClass';

export default function ContactsAsText(props) {
	const { contacts = [], users = [] } = props;
	if (!Array.isArray(contacts) || !contacts.length) {
		return null;
	}

	const customerId = Tools.AppService.getCustomerId();
	const currentUser = Tools.AppService.getSelf() || {};
	const t = Tools.$translate;
	const isYou = find(users, { id: currentUser.id });
	const restUsers = contacts.length - 1 + (users || []).length - Number(!!isYou);
	const firstContact = contacts[0] || {};
	const contactLink = (
		<a href={'#/' + customerId + '/contacts/' + firstContact.id + '/'} onClick={ReactTemplates.TOOLS.stopProp}>
			{firstContact.name}
		</a>
	);
	const classNames = new BemClass('ContactsAsText');

	return (
		<span className={classNames.b()}>
			{isYou ? `${t('default.you')}, ` : null}
			{contactLink}
			{restUsers > 0 ? (
				<span>
					{` ${t('default.and').toLowerCase()} `}
					{restUsers} {t('filters.more').toLowerCase()}
				</span>
			) : null}
		</span>
	);
}

ContactsAsText.propTypes = {
	contacts: PropTypes.arrayOf(
		PropTypes.shape({
			id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
			name: PropTypes.string,
			firstName: PropTypes.string,
			email: PropTypes.string
		})
	),
	users: PropTypes.arrayOf(
		PropTypes.shape({
			id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
			name: PropTypes.string,
			firstName: PropTypes.string,
			email: PropTypes.string
		})
	)
};
