import { get } from 'lodash';

import { OrderRow, useOrderRowsDispatch, useOrderRowsSelector } from 'App/components/OrderRows/Context/OrderContext';
import useSelector from '../useSelector';
import { useCustomFields, useProductCategories } from '../appHooks';
import ProductCategory from 'App/resources/Model/ProductCategory';
import useAppDispatch from '../useAppDispatch';
import { useEffect } from 'react';
import updateOrderRows from './updaters/orderRowUpdater';
import {
	CustomFieldOverrides,
	DisabledFields,
	FieldEntity,
	FieldWithOverride,
	RemovedOrderRow,
	UpdatedCustomField,
	VisibleFields,
	setCustomFieldOverrides,
	setUpdateHash
} from 'Store/reducers/EditListenerReducer';
import updateCustomFields, { type EntityCustomFields } from './updaters/customFieldUpdater';
import { type SubscriptionPeriodState } from 'App/components/EditSubscription/Context/SubscriptionGroupState';
import { useSoftDeployAccess } from '../featureHelper';
import { EntityCustomField } from 'App/resources/Model/CustomField';
import { hasRRWithCM } from 'App/helpers/salesModelHelpers';
import { appendOrderRows, removeOrderRow, setOrderRow } from 'App/components/OrderRows/Context/OrderContextHelpers';

const fieldExistsInFieldsMap = (
	fieldsMap: VisibleFields | DisabledFields,
	fieldName: string,
	entityType?: string,
	entity?: OrderRow,
	defaultValue?: boolean
) => {
	const fieldPath = entityType ? `${entityType}.${fieldName}` : fieldName;
	const formattedFieldPath = fieldPath.replace(/\s/g, '').toLowerCase();
	const fieldExists = get(fieldsMap, formattedFieldPath);
	if (fieldExists !== undefined) {
		return !!fieldExists;
	}

	if (!entity) {
		return defaultValue;
	}

	const entityIdPath = `${entityType}@${entity.id}.${fieldName}`;
	const formattedIdPath = entityIdPath.replace(/\s/g, '').toLowerCase();

	const idFieldFxists = get(fieldsMap, formattedIdPath);
	if (idFieldFxists !== undefined) {
		return !!idFieldFxists;
	}

	const entitySortIdPath = `${entityType}#${entity.sortId}.${fieldName}`;
	const formattedSortPath = entitySortIdPath.replace(/\s/g, '').toLowerCase();

	const sortFieldExists = get(fieldsMap, formattedSortPath);
	if (sortFieldExists !== undefined) {
		return !!sortFieldExists;
	}

	return defaultValue;
};

export const isDisabledField =
	(disabledFields: DisabledFields) =>
	(fieldName: string, entityType?: string, entity?: OrderRow, defaultIsDisabled?: boolean) => {
		return fieldExistsInFieldsMap(disabledFields, fieldName, entityType, entity, defaultIsDisabled);
	};

const isVisibleField =
	(visibleFields: VisibleFields, defaultIsVisible?: boolean) =>
	(fieldName: string, entityType?: string, entity?: OrderRow) => {
		return fieldExistsInFieldsMap(visibleFields, fieldName, entityType, entity, defaultIsVisible);
	};

export const useDisabledFields = (fieldEntity: FieldEntity) => {
	const disabledFields = useSelector(state => state.EditListener.disabledFields[fieldEntity]);

	if (!disabledFields) {
		return (fieldName: string) => undefined;
	}

	return isDisabledField(disabledFields);
};

const isVisibleRowField =
	(visibleFields: VisibleFields, productCategories: ProductCategory[], isVisible?: boolean) =>
	(fieldName: string, entityType: string, entity: OrderRow) => {
		const isCustomField = fieldName.startsWith('custom.');
		const productCategoryId = entity.product?.category?.id;

		if (isCustomField && productCategoryId) {
			const productCategory = productCategories.find(category => {
				return category.id === productCategoryId;
			});

			if (productCategory?.orderRowFields?.length) {
				const customFieldId = parseInt(fieldName.split('.')[1]);
				const hiddenByProductCategory = productCategory.orderRowFields.some(
					field => field.id === customFieldId
				);

				if (hiddenByProductCategory !== undefined) {
					return hiddenByProductCategory;
				}

				return isVisibleField(visibleFields, isVisible)(fieldName, entityType, entity);
			}
		}

		return isVisibleField(visibleFields, isVisible)(fieldName, entityType, entity);
	};

export const useVisibleFields = (fieldEntity: FieldEntity, defaultValue?: boolean) => {
	const visibleFields = useSelector(state => state.EditListener.visibleFields[fieldEntity]);

	if (!visibleFields) {
		return (fieldName: string) => defaultValue;
	}

	return isVisibleField(visibleFields, defaultValue);
};

export const useVisibleRowField = (fieldEntity: FieldEntity, defaultValue?: boolean) => {
	const productCategories = useProductCategories();
	const visibleFields = useSelector(state => {
		return state.EditListener.visibleFields[fieldEntity];
	});

	if (!visibleFields) {
		return (fieldName: string) => defaultValue;
	}

	return isVisibleRowField(visibleFields, productCategories, defaultValue);
};

export const useUpdateHash = (hashKey: string, entity?: OrderRow) => {
	const hasOnSubscriptionEdit = useSoftDeployAccess('APP_FRAMEWORK_ON_SUBSCRIPTION_EDIT');
	const updateHash = useSelector(state => state.EditListener.updateHash[hashKey] || '');
	const dispatch = useAppDispatch();

	/**
	 * @todo This is a temporary solution to update the hash when the entity is updated.
	 * This update hash is needed in order to tell the form observer that the custom fields has been externally updated.
	 * By having this here, we will always trigger this behavior on the form observer on every change within the form as well, not just when we update through the app.
	 * The issue is that if we try to update the hash from the orderRowUpdater, the hash is set and updated before the custom fields are updated
	 * So seems like redux dispatches updates and re-renders faster than React context dispatches (which is used when updating custom fields with setCustomFields)
	 */
	useEffect(() => {
		if (entity && hasOnSubscriptionEdit) {
			dispatch(setUpdateHash(hashKey, entity));
		}
	}, [entity, hasOnSubscriptionEdit]);

	return updateHash;
};

type UseUpdateOrderRows = {
	currency: string;
};

export const useUpdateOrderRows = ({ currency }: UseUpdateOrderRows) => {
	const orderRowCustomFields = useCustomFields('orderrow');
	const metadata = useSelector(({ App }) => App.metadata);
	const hasContributionMargin = metadata?.params.SalesModel === 'cm' || hasRRWithCM();
	const orderRows = useOrderRowsSelector(s => s.orderRows);
	const dispatch = useOrderRowsDispatch();

	const updatedFields = useSelector(({ EditListener }) => EditListener.updatedFields);

	const updateRowsFromApp = (updatedOrderRows: Partial<OrderRow>[]) => {
		if (updatedOrderRows) {
			updateOrderRows(
				orderRows,
				updatedOrderRows,
				orderRowCustomFields,
				currency,
				(uuid, updates) => dispatch(setOrderRow(uuid, updates)),
				{
					hasContributionMargin
				}
			);
		}
	};

	useEffect(() => {
		if (updatedFields?.order?.orderRow) {
			updateRowsFromApp(updatedFields.order.orderRow as Partial<OrderRow>[]);
		}
	}, [JSON.stringify(updatedFields?.order?.orderRow)]);
};

type UseAddedOrderRowsProps = {
	currency: string;
};

export const useAddedOrderRows = ({ currency }: UseAddedOrderRowsProps) => {
	const addedFields = useSelector(({ EditListener }) => EditListener.addedFields);
	const dispatch = useOrderRowsDispatch();

	const addRowsFromApp = async (addedOrderRows: Partial<OrderRow>[]) => {
		if (Array.isArray(addedOrderRows)) {
			dispatch(appendOrderRows(addedOrderRows as OrderRow[], currency));
		}
	};

	useEffect(() => {
		if (addedFields?.order?.orderRow) {
			addRowsFromApp(addedFields.order.orderRow as Partial<OrderRow>[]);
		}
	}, [JSON.stringify(addedFields?.order?.orderRow)]);
};

export const useRemovedOrderRows = () => {
	const removedFields = useSelector(({ EditListener }) => EditListener.removedFields);
	const dispatch = useOrderRowsDispatch();
	const orderRows = useOrderRowsSelector(s => s.orderRows);

	const removeOrderRows = async (removedOrderRows: RemovedOrderRow[]) => {
		if (Array.isArray(removedOrderRows)) {
			for (const orderRow of removedOrderRows) {
				const { id, sortId, uuid } = orderRow;
				const foundOrderRow = orderRows.find(row => {
					return row.id === id || row.sortId === sortId || row.uuid === uuid;
				});
				if (foundOrderRow) {
					dispatch(removeOrderRow(foundOrderRow.uuid));
				}
			}
		}
	};

	useEffect(() => {
		if (removedFields?.order?.orderRow) {
			removeOrderRows(removedFields.order.orderRow as RemovedOrderRow[]);
		}
	}, [JSON.stringify(removedFields?.order?.orderRow)]);
};

export const useCustomFieldOverrides = () => {
	const customFieldOverrides = useSelector(state => state.EditListener.customFieldOverrides);

	const getCustomFieldOverrides = ({
		uuid,
		sortId,
		id,
		entityType,
		fieldId
	}: {
		uuid?: number;
		sortId?: number;
		id?: number;
		entityType?: 'order' | 'orderrow';
		fieldId: number;
	}) => {
		let allOverrides: FieldWithOverride = {};
		if (uuid) {
			const entitySelector = `${entityType}!${uuid}`;
			const overrides = customFieldOverrides[entitySelector]?.[fieldId];
			if (overrides) {
				allOverrides = { ...allOverrides, ...overrides };
			}
		}
		if (id) {
			const entitySelector = `${entityType}@${id}`;
			const overrides = customFieldOverrides[entitySelector]?.[fieldId];
			if (overrides) {
				allOverrides = { ...allOverrides, ...overrides };
			}
		}
		if (sortId) {
			const entitySelector = `${entityType}#${sortId}`;
			const overrides = customFieldOverrides[entitySelector]?.[fieldId];
			if (overrides) {
				allOverrides = { ...allOverrides, ...overrides };
			}
		}
		if (entityType) {
			const entitySelector = entityType;
			const overrides = customFieldOverrides[entitySelector]?.[fieldId];
			if (overrides) {
				allOverrides = { ...allOverrides, ...overrides };
			}
		}

		return allOverrides;
	};

	return getCustomFieldOverrides;
};

type UseUpdateCustomFields = {
	entityType: 'order' | 'orderrow';
	entity: SubscriptionPeriodState | OrderRow;
	onCustomFieldUpdate?: (customFields: EntityCustomField[]) => void;
};

export const useOverrideCustomFields = () => {
	const updatedFields = useSelector(({ EditListener }) => EditListener.updatedFields);
	const dispatch = useAppDispatch();

	const overrideHandler = (updatedCustomFields: UpdatedCustomField[]) => {
		const overrides = updatedCustomFields.reduce<FieldWithOverride>((acc, field) => {
			if (!field.id) {
				return acc;
			}
			if (field.name) {
				if (!acc[field.id]) {
					acc[field.id] = {};
				}
				acc[field.id].name = field.name;
			}
			if (field.placeholder) {
				if (!acc[field.id]) {
					acc[field.id] = {};
				}
				acc[field.id].placeholder = field.placeholder;
			}
			if (field.default) {
				if (!acc[field.id]) {
					acc[field.id] = {};
				}
				acc[field.id].default = field.default;
			}
			return acc;
		}, {});
		return overrides;
	};

	useEffect(() => {
		const orderCustomFieldOverrides: CustomFieldOverrides = {};
		if (updatedFields?.order?.custom) {
			orderCustomFieldOverrides.order = overrideHandler(updatedFields?.order?.custom);
		}

		let orderRowCustomFieldOverrides: CustomFieldOverrides = {};
		if (updatedFields?.order?.orderRow) {
			orderRowCustomFieldOverrides = updatedFields?.order?.orderRow.reduce<any>((acc, row) => {
				if (!row.custom) {
					return acc;
				}
				const rowCustomOverrides = overrideHandler(row.custom);

				if ('uuid' in row && row.uuid) {
					acc[`orderrow!${row.uuid}`] = rowCustomOverrides;
				}
				if (row.id) {
					acc[`orderrow@${row.id}`] = rowCustomOverrides;
				}
				if (row.sortId) {
					acc[`orderrow#${row.sortId}`] = rowCustomOverrides;
				}
				return acc;
			}, {});
		}

		dispatch(setCustomFieldOverrides({ ...orderCustomFieldOverrides, ...orderRowCustomFieldOverrides }));
	}, [JSON.stringify(updatedFields?.order)]);
};

export const useUpdateCustomFields = ({ entityType, entity, onCustomFieldUpdate }: UseUpdateCustomFields) => {
	const orderRowCustomFields = useCustomFields('orderrow');
	const orderCustomFields = useCustomFields('order');

	let entityCustomFields: EntityCustomFields = [];
	if (entityType === 'order') {
		entityCustomFields = orderCustomFields;
	} else if (entityType === 'orderrow') {
		entityCustomFields = orderRowCustomFields;
	}

	const updatedFields = useSelector(({ EditListener }) => EditListener.updatedFields);

	useEffect(() => {
		if (updatedFields?.order?.custom) {
			const updatedCustomFields = updateCustomFields(entity, updatedFields.order.custom, entityCustomFields);
			if (updatedCustomFields) {
				onCustomFieldUpdate?.(updatedCustomFields);
			}
		}
	}, [JSON.stringify(updatedFields?.order?.custom)]);
};
