import React from 'react';

import UiElements from 'Components/UiElements';
import { useSubscriptionGroupContext } from '../../Context/SubscriptionGroupContext';
import { useIntegrations } from 'App/components/hooks/appHooks';
import { Block } from '@upsales/components';
import BemClass from '@upsales/components/Utils/bemClass';
import './SubscriptionUiElements.scss';
import { onEditListenerResponse, EditIntegrationResponse } from 'Store/reducers/EditListenerReducer';
import useAppDispatch from 'App/components/hooks/useAppDispatch';

const SubscriptionUiElements = () => {
	const classes = new BemClass('SubscriptionUiElements');
	const { state } = useSubscriptionGroupContext();
	const dispatch = useAppDispatch();

	const integrations = useIntegrations();

	const subscriptionUiElements = integrations?.uiElements?.editSubscription?.sidebar;

	const Utils = {
		updateOrderFromApp: (fieldActions: EditIntegrationResponse) => {
			dispatch(onEditListenerResponse(fieldActions));
		}
	};

	return (
		<Block space="mtxl" className={classes.b()}>
			<UiElements object={state} elements={subscriptionUiElements} utils={Utils} />
		</Block>
	);
};

export default SubscriptionUiElements;
