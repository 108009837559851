import React, { Fragment } from 'react';
import {
	Block,
	Flex,
	DrawerHeader,
	Title,
	Icon,
	Text,
	Button,
	Label,
	Loader,
	DropDownMenu,
	Tooltip
} from '@upsales/components';
import { PrimaryButton } from '@upsales/components/Buttons';
import logError from 'App/babel/helpers/logError';
import BemClass from '@upsales/components/Utils/bemClass';
import PropTypes from 'prop-types';
import { makeCancelable } from '../../helpers/promise';
import RelationSelect from 'Components/Inputs/RelationSelect';
import TodoTimePicker from 'Components/Inputs/TodoTimePicker';
import './EditTodo.scss';
import _ from 'lodash';
import T from 'Components/Helpers/translate';
import BS from 'Services/BrowserService';
import TodoShape from 'App/babel/propTypes/TodoShape';
import HeaderUserSelect from 'Components/Inputs/HeaderUserSelect';
import { setSelectedId } from 'Store/actions/TodoActions';
import { connect } from 'react-redux';
import InlineConfirm from '../Dialogs/InlineConfirm';
import NotesWithSignature from 'Components/Inputs/NotesWithSignature';
import TodoCheckboxCard from 'Components/TodoCheckboxCard';
import { openDrawer } from 'Services/Drawer';
import { TYPES } from 'Components/Helpers/SourceHelper';
import { openNewMailWithContact } from 'App/helpers/mailHelpers';
import ActivityQuickLinksMenu from 'Components/Activity/ActivityQuickLinksMenu/ActivityQuickLinksMenu';
import CustomFields from 'App/components/CustomFields';
import FormObserver, {
	getCustomFieldModel,
	mapCustomValuesToArray,
	mapCustomValuesToObject
} from 'App/components/FormObserver';
import Activity from 'App/babel/resources/Activity';
import { hasTodoCustomFields } from 'App/babel/helpers/todo';
import Client from 'App/resources/Client';

function sendFollowupMail(contact) {
	if (Tools.FeatureHelper.hasSoftDeployAccess('NEW_MAIL')) {
		openNewMailWithContact(contact);
	} else {
		Tools.$upModal.open('sendEmail', {
			customerId: Tools.AppService.getCustomerId(),
			contactId: contact?.id,
			contact
		});
	}
}

const getActivity = id => {
	return makeCancelable(Activity.get(id));
};
const getAppointment = id => {
	return makeCancelable(Tools.Appointment.customer(Tools.AppService.getCustomerId()).get(id));
};
const getContact = id => {
	return makeCancelable(Tools.Contact.customer(Tools.AppService.getCustomerId()).get(id));
};
const getClient = id => {
	return makeCancelable(Client.get(id));
};
const saveActivity = activity => {
	return makeCancelable(Activity.save(activity));
};
const deleteActivity = id => {
	return makeCancelable(Activity.delete(id));
};

const mapDispatchToProps = {
	setSelectedId
};

class EditTodo extends React.Component {
	constructor(props) {
		super(props);
		const { todo } = this.props;
		this.state = {
			id: todo.id,
			activityData: null,
			description: todo.description,
			contact: null,
			user: null,
			client: null,
			date: null,
			time: null,
			checked: false,
			hasChanged: false,
			loading: true,
			saving: false,
			inputFocus: false,
			notes: '',
			activity: null,
			custom: todo.custom ?? [],
			missingClientAccess: false,
			needToFillRequiredCustom: false
		};
		this.users = Tools.AppService.getUsers(Tools.AppService.AccessType.ACTIVITY);
		this.customFields = Tools.AppService.getCustomFields('todo');
		this.showCustomField = hasTodoCustomFields();
	}

	componentDidMount() {
		this.setState({ loading: true });
		this.props.setSelectedId(this.state.id);
		this.getActivityPromise = getActivity(this.state.id);
		this.getActivityPromise.promise
			.then(async ({ data }) => {
				let contact = null;
				const clientId = data.client?.id;
				if (data.contacts?.[0]) {
					this.getContactPromise = getContact(data.contacts[0].id);
					try {
						const res = await this.getContactPromise.promise;
						contact = res.data;
					} catch (err) {
						logError(err, 'Could not fetch related contact');
					}
				}

				let appointment = null;
				if (data.parentAppointmentId) {
					this.getAppointmentPromise = getAppointment(data.parentAppointmentId);
					try {
						const res = await this.getAppointmentPromise.promise;
						appointment = res.data;
					} catch (err) {
						logError(err, 'Could not fetch related appointment');
					}
				}
				let relatedActivity = null;
				if (data.parentActivityId) {
					this.getRelatedActivityPromise = getActivity(data.parentActivityId);
					try {
						const res = await this.getRelatedActivityPromise.promise;
						relatedActivity = res.data;
					} catch (err) {
						logError(err, 'Could not fetch related activity');
					}
				}
				this.setState({
					activityData: data,
					description: data.description,
					date: data.date,
					time: data.time ? moment(data.date).format('LT') : '',
					contact,
					user: (data.users && data.users[0]) || Tools.AppService.getSelf(),
					client: data.client,
					opportunity: data.opportunity,
					projectPlan: data.projectPlan,
					checked: !!data.closeDate,
					loading: false,
					activity: relatedActivity,
					appointment,
					relationLocked: false, // if todo has a client we need to lock the relations, we need better design to change only contact/opportunity
					notes: data.notes,
					custom: data.custom
				});

				if (clientId) {
					this.getClientPromise = getClient(clientId);

					this.getClientPromise.promise
						.then(() => this.setState({ missingClientAccess: false }))
						.catch(err => {
							if (err.request.status === 404) {
								this.setState({
									missingClientAccess: true
								});
							} else {
								this.handleRequestError(err, 'Failed to get client');
							}
						});
				}
			})
			.catch(err => {
				logError(err, 'Failed to get activity');
			});
	}
	componentWillUnmount() {
		this.props.setSelectedId(null);
		if (this.getActivityPromise) {
			this.getActivityPromise.cancel();
		}
		if (this.getRelatedActivityPromise) {
			this.getRelatedActivityPromise.cancel();
		}
		if (this.getAppointmentPromise) {
			this.getAppointmentPromise.cancel();
		}
		if (this.getContactPromise) {
			this.getContactPromise.cancel();
		}
		if (this.saveActivityPromise) {
			this.saveActivityPromise.cancel();
		}
		if (this.deleteActivityPromise) {
			this.deleteActivityPromise.cancel();
		}
		if (this.getClientPromise) {
			this.getClientPromise.cancel();
		}
	}
	componentDidUpdate(prevProps, prevState) {
		if (prevState.activityData && !prevState.hasChanged) {
			if (!_.isEqual(prevState, this.state)) {
				this.setState({ hasChanged: true });
			}
		}
	}

	save = () => {
		if (!this.state.description.replace(/\n|\r/, '').trim().length) {
			return;
		}
		this.setState({ saving: true });
		let date = null;
		if (this.state.date) {
			if (this.state.time) {
				date = new Date(this.state.date);
				const timeObject = moment(this.state.time, 'LT');
				const hours = timeObject.hour();
				const minutes = timeObject.minutes();
				date.setHours(hours, minutes, 0, 0);
			} else {
				date = moment(this.state.date).format('YYYY-MM-DD');
			}
		}
		const activity = {
			...this.state.activityData,
			contacts: this.state.contact ? [this.state.contact] : null,
			opportunity: this.state.opportunity,
			projectPlan: this.state.projectPlan,
			parentAppointmentId: this.state.appointment?.id || null,
			parentActivityId: this.state.activity?.id || null,
			client: this.state.client,
			description: this.state.description,
			users: [this.state.user],
			date,
			closeDate: this.state.checked ? new Date() : null,
			notes: this.state.notes,
			custom: this.state.custom
		};
		this.saveActivityPromise = saveActivity(activity);
		this.saveActivityPromise.promise
			.then(() => {
				this.setState({
					activityData: activity,
					hasChanged: false,
					saving: false
				});
				if (this.props.afterSave) {
					this.props.afterSave(activity);
				}
				this.props.close();
			})
			.catch(() => this.setState({ saving: false }));
	};
	onCheck = value => {
		const userEditable = this.state.activityData?.userEditable;
		if (!userEditable) {
			return;
		}
		this.setState({
			checked: value
		});
	};
	onUserChange = user => {
		this.setState({ user });
	};
	delete = () => {
		this.deleteActivityPromise = deleteActivity(this.state.id);
		return this.deleteActivityPromise.promise
			.then(this.props.close)
			.catch(err => logError(err, 'Failed to delete activity'));
	};
	onRelationSelect = relation => {
		switch (relation.type) {
			case 'opportunity':
				this.setState({
					opportunity: {
						currency: relation.currency
							? relation.currency
							: _.find(Tools.AppService.getMetadata().customerCurrencies, { masterCurrency: true }).iso,
						custom: relation.custom,
						date: relation.date,
						description: relation.description,
						id: relation.id,
						orderRow: relation.orderRow,
						orderValue: relation.value,
						probability: relation.probability,
						stage: relation.stage,
						user: relation.user
					},
					client: relation.client ? relation.client : this.state.client,
					contact: relation.contact ? relation.contact : this.state.contact
				});
				break;
			case 'company':
				this.setState({
					client: {
						id: relation.id,
						journeyStep: relation.journeyStep,
						name: relation.name,
						phone: relation.phone,
						users: relation.users
					}
				});
				break;
			case 'contact':
				this.setState({
					contact: { id: relation.id, journeyStep: relation.journeyStep, name: relation.name },
					client: relation.client
				});
				break;
			case 'appointment':
				this.setState({
					appointment: {
						id: relation.id,
						description: relation.description,
						date: relation.date,
						users: relation.users,
						contacts: relation.contacts
					},
					client: !this.state.client ? relation.client : this.state.client
				});
				break;
			case 'activity':
				this.setState({
					activity: {
						id: relation.id,
						description: relation.description,
						activityType: relation.activityType,
						users: relation.users,
						contacts: relation.contacts
					},
					client: !this.state.client ? relation.client : this.state.client
				});
				break;
		}
	};

	formatErrorMessages = errorMessages => {
		const errors = [];

		if (!this.state.description) {
			errors.push(T('validation.missingRequiredFields', { field: T('default.description') }));
		}

		if (this.state.projectPlan && !this.state.date) {
			errors.push(T('validation.missingRequiredFields', { field: T('default.dueDate') }));
		}

		if (errorMessages) {
			for (const customError of Object.values(errorMessages)) {
				if (customError !== null) {
					errors.push(customError);
				}
			}
		}

		return errors.join(', ');
	};

	saveDisabled = () => {
		return (
			!this.state.description ||
			(this.state.projectPlan && !this.state.date) ||
			(this.showCustomField && this.state.needToFillRequiredCustom)
		);
	};

	render() {
		const classes = new BemClass('EditTodo', this.props.className);
		const userRemovable = this.state.activityData?.userRemovable;
		const userEditable = this.state.activityData?.userEditable;

		const customFieldValidatonModel = {
			custom: getCustomFieldModel(this.customFields)
		};

		const customFieldInitialValues = {
			custom: mapCustomValuesToObject(this.state.custom ?? [], this.customFields)
		};

		return (
			<Flex direction="column" className={classes.b()}>
				<DrawerHeader
					onHide={this.props.close}
					title={T('todo.todo')}
					subtitle={this.state.id ? `${T('todo.drawerHeader.activityID')}: ${this.state.id}` : ''}
				>
					{this.state.loading ? null : (
						<Fragment>
							<HeaderUserSelect
								title={T('default.user')}
								user={this.state.user}
								users={this.users}
								border="ls rs"
								borderColor="grey-6"
								onChange={this.onUserChange}
								onlyAvatar={true}
								disabled={!!this.state.activityData.closeDate || !userEditable}
								customCssClass={'edit-todo-select'}
							/>
							<DropDownMenu
								align="right"
								useAnimation={true}
								renderTrigger={(expanded, setExpanded) => (
									<Button
										onClick={setExpanded}
										shadow="none"
										color="white"
										className={classes.elem('quickLinks').b()}
									>
										<Icon color="grey-10" name="link" />
									</Button>
								)}
							>
								<ActivityQuickLinksMenu activityId={this.state.id}></ActivityQuickLinksMenu>
							</DropDownMenu>
							<InlineConfirm
								show
								tooltip={T(userRemovable ? 'default.delete' : 'noDeleteRights.activity')}
								onConfirm={userRemovable ? () => this.delete() : null}
								entity="todo.todo"
							>
								<Button
									disabled={!userRemovable}
									shadow="none"
									color="white"
									className={classes.elem('delete').b()}
								>
									<Icon color="grey-10" name="trash" />
								</Button>
							</InlineConfirm>
						</Fragment>
					)}
				</DrawerHeader>
				<Block space="ptm pll prl" className={classes.elem('contentWrapper').b()}>
					<Label>
						<Title bold size="sm" space="mtl">
							{T('todo.editTodo.descriptionLabel')}
						</Title>
					</Label>
					<TodoCheckboxCard
						renderOpportunity
						sendMail={() => sendFollowupMail(this.state.contact)}
						projectPlanId={this.state.projectPlan?.id}
						createCall={() => {
							const source = { id: this.state.id, type: TYPES.TODO };
							openDrawer('CreateCall', {
								onClose: cancel => {
									if (!cancel) {
										this.save();
									}
								},
								contact: this.state.contact,
								client: this.state.client,
								notes: this.state.notes,
								activity: this.state.activityData,
								source
							});
						}}
						createTodo={() => {
							const source = { id: this.state.id, type: TYPES.TODO };
							openDrawer('CreateTodo', {
								onClose: cancel => {
									if (!cancel) {
										this.save();
									}
								},
								contact: this.state.contact,
								client: this.state.client,
								notes: this.state.notes,
								source
							});
						}}
						createAppointment={() =>
							Tools.$upModal
								.open('editAppointment', {
									appointment: {
										notes: this.state.notes,
										users: Tools.AppService.getSelf(),
										client: this.state.client,
										contacts: this.state.contact ? [this.state.contact] : null,
										sourceType: TYPES.TODO,
										sourceId: this.state.id,
										activityType: null,
										parentActivityId: this.state.id
									}
								})
								.then(() => this.save())
						}
						createOpportunity={() => {
							// eslint-disable-next-line promise/catch-or-return
							Tools.$upModal
								.open('editOrder', {
									type: 'opportunity',
									clientId: this.state.client?.id,
									contactId: this.state.contact?.id,
									notes: this.state.notes,
									source: {
										type: TYPES.TODO,
										id: this.state.id
									}
								})
								.then(() => this.save());
						}}
						createOrder={() => {
							// eslint-disable-next-line promise/catch-or-return
							Tools.$upModal
								.open('editOrder', {
									clientId: this.state.client?.id,
									contactId: this.state.contact?.id,
									notes: this.state.notes,
									source: {
										type: TYPES.TODO,
										id: this.state.id
									}
								})
								.then(() => this.save());
						}}
						contact={this.state.contact}
						checkboxProps={{
							onChange: this.onCheck,
							checked: this.state.checked,
							disabled: !userEditable
						}}
						inputProps={{
							noborder: true,
							disabled: this.state.loading || !userEditable,
							onFocus: () => {
								this.setState({ inputFocus: true });
							},
							maxLength: 100,
							onBlur: () => this.setState({ inputFocus: false }),
							value: this.state.description,
							onKeyDown: e => {
								if (BS.isEnter(e) && !this.saveDisabled()) {
									e.preventDefault();
									this.save();
								}
							},
							onChange: e =>
								this.setState({
									description: e.target.value
								})
						}}
					/>
					<Text
						color="grey-10"
						size="sm"
						className={classes
							.elem('enter-to-save')
							.mod({ visible: this.state.hasChanged && this.state.inputFocus })
							.b()}
					>
						{T('todo.editTodo.confirmEnter')}
					</Text>
				</Block>
				{this.state.loading ? (
					<Loader noU />
				) : this.showCustomField ? (
					<FormObserver
						onChange={({ custom }, isValid, errorMessages) => {
							this.setState({
								custom: mapCustomValuesToArray(custom),
								customErrorMessages: errorMessages,
								needToFillRequiredCustom: !isValid
							});
						}}
						model={customFieldValidatonModel}
						initialValues={customFieldInitialValues}
						onSubmit={() => this.save()}
						validateOnMount
					>
						{({ submit, onFormChange: onCustomFieldChange, inputProps: customFieldInputProps }) => (
							<Flex direction="column">
								<Block space="pll prl" className={classes.elem('contentWrapper').b()}>
									<RelationSelect
										onChange={this.onRelationSelect}
										client={this.state.client}
										opportunity={this.state.opportunity}
										projectPlan={this.state.projectPlan}
										appointment={this.state.appointment}
										activity={this.state.activity}
										contact={this.state.contact}
										locked={this.state.relationLocked}
										onClear={target => {
											if (target === 'client') {
												this.setState({
													contact: null,
													client: null,
													appointment: null,
													opportunity: null,
													activity: null
												});
											} else {
												this.setState({ [target]: null });
											}
										}}
										disabled={!userEditable || this.state.missingClientAccess}
										missingAccess={this.state.missingClientAccess}
									/>
									<TodoTimePicker
										onDateChange={e => this.setState({ date: e.target.value })}
										onTimeChange={e => this.setState({ time: e.target.value })}
										onClear={() => this.setState({ date: null, time: '' })}
										date={this.state.date}
										time={this.state.time}
										disabled={!userEditable}
										required={this.state.projectPlan ? true : false}
										setDueDateToday
									/>
									<NotesWithSignature
										space="mtl mbxl"
										value={this.state.notes}
										onChange={v => this.setState({ notes: v })}
										disabled={!userEditable}
									/>

									<Block>
										<CustomFields
											type="todo"
											inputProps={customFieldInputProps}
											onChange={(id, value) => onCustomFieldChange(`custom.Custom_${id}`, value)}
											disabled={false}
										/>
									</Block>
								</Block>
								<Flex
									direction="column"
									space="ptl pbs pll prl"
									className={classes
										.elem('saveButtonWrapper')
										.mod({ visible: this.state.hasChanged, notVisible: !this.state.hasChanged })
										.b()}
								>
									<Tooltip title={this.formatErrorMessages(this.state.customErrorMessages)}>
										<PrimaryButton
											disabled={this.saveDisabled()}
											block
											size="lg"
											loading={this.state.saving}
											onClick={submit}
										>
											{T('todo.editTodo.save')}
										</PrimaryButton>
									</Tooltip>
									<Button type="link" color="grey" onClick={this.props.close} size="lg">
										{T('default.cancel')}
									</Button>
								</Flex>
							</Flex>
						)}
					</FormObserver>
				) : (
					<>
						<Block space="pll prl" className={classes.elem('contentWrapper').b()}>
							<RelationSelect
								onChange={this.onRelationSelect}
								client={this.state.client}
								opportunity={this.state.opportunity}
								projectPlan={this.state.projectPlan}
								appointment={this.state.appointment}
								activity={this.state.activity}
								contact={this.state.contact}
								locked={this.state.relationLocked}
								onClear={target => {
									if (target === 'client') {
										this.setState({
											contact: null,
											client: null,
											appointment: null,
											opportunity: null,
											activity: null
										});
									} else {
										this.setState({ [target]: null });
									}
								}}
								disabled={!userEditable || this.state.missingClientAccess}
								missingAccess={this.state.missingClientAccess}
							/>
							<TodoTimePicker
								onDateChange={e => this.setState({ date: e.target.value })}
								onTimeChange={e => this.setState({ time: e.target.value })}
								onClear={() => this.setState({ date: null, time: '' })}
								date={this.state.date}
								time={this.state.time}
								disabled={!userEditable}
								required={this.state.projectPlan ? true : false}
								setDueDateToday={this.state.projectPlan ? false : true}
							/>
							<NotesWithSignature
								space="mtl mbxl"
								value={this.state.notes}
								onChange={v => this.setState({ notes: v })}
								disabled={!userEditable}
							/>
						</Block>
						<Flex
							direction="column"
							space="ptl pbs pll prl"
							className={classes
								.elem('saveButtonWrapper')
								.mod({ visible: this.state.hasChanged, notVisible: !this.state.hasChanged })
								.b()}
						>
							<Button
								disabled={this.saveDisabled()}
								color={this.state.description ? 'green' : 'light-grey'}
								loading={this.state.saving}
								onClick={this.save}
								size="lg"
							>
								{T('todo.editTodo.save')}
							</Button>
							<Button type="link" color="grey" onClick={this.props.close} size="lg">
								{T('default.cancel')}
							</Button>
						</Flex>
					</>
				)}
			</Flex>
		);
	}
}
EditTodo.propTypes = {
	className: PropTypes.string,
	todo: TodoShape,
	close: PropTypes.func.isRequired,
	setSelectedId: PropTypes.func,
	getTableData: PropTypes.func,
	id: PropTypes.number,
	afterSave: PropTypes.func
};
export const detached = EditTodo;

export default connect(null, mapDispatchToProps)(EditTodo);
