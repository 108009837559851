import React, { useState } from 'react';
import Comment from 'App/resources/Model/Comment';
import CommentResource from 'Resources/Comment';
import TimelineGeneric from '../TimelineGeneric/TimelineGeneric';
import NoteRow from '../TimelineNoteComments/NoteRow';
import BouncyThumbtack from 'App/components/Icon/BouncyThumbtack/BouncyThumbtack';
import Link from '@upsales/components/Link/Link';
import { useTranslation } from 'Components/Helpers/translate';
import { IconName } from '@upsales/components';
import type { TimelineCardComponent } from '../TimelineGeneric/TimelineCardConfigs';

const TimelineCardComment = (({ event, renderedByType, ...passthrough }) => {
	const comment = event.comment || event.data?.comment;
	const [deleted, setDeleted] = useState(false);
	const { t } = useTranslation();

	const togglePinnedComment = (c: Comment, pin: boolean) =>
		CommentResource.save({ id: c.id, isPinned: pin }, { skipNotification: true });

	const isPinned = comment?.isPinned || false;

	if (deleted || !comment) {
		return null;
	}

	// Have to do this whole thing custom to get the colon at the end without an extra space
	const contactComponent = () => (
		<>
			{t('default.commented').toLowerCase()}
			{renderedByType !== 'contact' && comment.contact ? (
				<>
					{` ${t('default.on').toLowerCase()} `}
					<Link
						onClick={e => {
							e.stopPropagation();
							return Tools.$state.go('contact.dashboard', {
								id: comment.contact?.id
							});
						}}
					>
						{comment.contact?.name ?? ''}
					</Link>
				</>
			) : null}
			{':'}
		</>
	);

	// Only comments on companies and contacts should be pinnable
	const canBePinned =
		!comment.opportunity?.id && !comment.activity?.id && !event.appointment?.id && !comment.ticket?.id;

	const actions =
		isPinned || canBePinned ? (
			<BouncyThumbtack
				pinned={isPinned}
				onToggle={async pin => {
					await togglePinnedComment(comment, pin);
				}}
				pinnedTooltip={t('pinnedComments.unpin')}
				unPinnedTooltip={t('pinnedComments.pin')}
				tooltipDistance={20}
			/>
		) : (
			<></>
		);

	const subSection = (
		<NoteRow
			key={`comment-${JSON.stringify({
				id: comment.id,
				description: comment.description
			})}`}
			onDelete={() => setDeleted(true)}
			hideIcon
			comment={comment}
			iconProps={{ icon: '' as IconName, color: '' }}
		/>
	);

	return (
		<TimelineGeneric
			event={event}
			titleElements={['users', contactComponent]}
			isExpandable
			actions={actions}
			noCommentRow
			{...passthrough}
		>
			{subSection}
		</TimelineGeneric>
	);
}) satisfies TimelineCardComponent;

export default TimelineCardComment;
