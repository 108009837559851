import React from 'react';
import BemClass from '@upsales/components/Utils/bemClass';
import T from 'Components/Helpers/translate';

import { Text, Icon } from '@upsales/components';

import './SwooshScreen.scss';

const SwooshScreen = ({ scheduled = false }: { scheduled?: boolean }) => {
	const classes = new BemClass('SwooshScreen');

	return (
		<div className={classes.b()}>
			<Icon name="check" color="medium-blue" />
			<Text color="medium-blue">{T(scheduled ? 'mail.mailScheduled' : 'mailDrawer.swoosh')}</Text>
		</div>
	);
};

export default SwooshScreen;
