import Resource from './Resource';
import { IconName, Text } from '@upsales/components';

export enum Datatypes {
	currency = 'currency',
	percent = 'percent',
	number = 'number',
	fraction = 'fraction',
	days = 'days',
	decimal = 'decimal',
	string = 'string',
	minutes = 'minutes'
}

export type ValueType = 'sales' | 'recurring' | 'cm' | 'allOpportunities' | 'relatedOpportunities';

export type HitRateSpecial = {
	type: Datatypes.fraction | Datatypes.percent;
	data: {
		numerator: number;
		denominator: number;
		percent: number;
	};
};
export type ProgressSpecial = {
	type: string;
	data: {
		[k: string]: any;
	};
	[k: string]: any;
};

export type RCWidgetDataSubRow = {
	key: string;
	remaining: number;
	goal: number;
	label: string;
	progress: number;
	conversionRate?: number;
	sorting?: number | string;
	percent?: number;
	progressSpecial?: ProgressSpecial | HitRateSpecial;
	colors?: RCWidgetData['colors'];
	additionalColumns?: {
		[key: string]: number;
	};
	icons?: {
		key: number;
		name: IconName;
		label: string;
		color: string;
		colorVariable?: string;
		backgroundColor?: string;
	}[];
	tooltipFields?: { type: 'string'; value: string }[];
	year?: string;
	optionalKey?: string | number;
	dataType?: keyof typeof Datatypes;
};

export type RCWidgetDataRowShape = RCWidgetDataSubRow & {
	rows: RCWidgetDataSubRow[];
	rowMaxProgress?: number;
	rowMinProgress?: number;
};

type RCTooltipSettings = {
	alignCenter?: boolean;
	progressFormat?: {
		value?: {
			bold?: boolean;
		};
	};
};

export type RCWidgetData = {
	excludedColumns?: string[];
	additionalColumns?: [{ field: string; datatype: keyof typeof Datatypes }];
	enableDrilldown: boolean;
	colors: {
		[k: string]: { label: string; color: string; colorVariable?: string; striped?: boolean; sorting?: number };
	};
	currency: string;
	datatype: {
		goal: keyof typeof Datatypes;
		progress: keyof typeof Datatypes;
		remaining: keyof typeof Datatypes;
	};
	language: {
		goal: string;
		progress: string;
		remaining: string;
		[k: string]: string;
	};
	type: {
		name: string;
		family: string;
	};
	rows: RCWidgetDataRowShape[];
	total: {
		goal: number;
		progress: number;
		remaining: number;
		special?: any;
		columns?: {
			[k: string]: number;
		};
		columnArray?: {
			key: string;
			label: string;
			progress: number;
			progressSpecial?: ProgressSpecial;
		}[];
		additionalColumn?: number;
		total?: number;
	};
	disableLegend?: boolean;
	enablePercent?: boolean;
	tooltip?: string;
	tooltipSettings?: RCTooltipSettings;
	disableSecondaryGroupings?: boolean;
	isHitRate?: boolean;
	useDistribution?: boolean;
	hasGoal?: boolean;
	icons?: {
		color: string;
		name: IconName;
		legend: string;
	}[];
	error?: string;
	disableRowDrilldown?: boolean;
};

export type RCWidgetMeta = {
	groupings: { [k: string]: string };
	groupingOptions?: {
		disablePrimaryGroupings?: boolean;
		requirePrimaryGroupings?: boolean;
		lockPrimaryGroupings?: boolean;
		disableSecondaryGroupings?: boolean;
		requireSecondaryGroupings?: boolean;
		lockSecondaryGroupings?: boolean;
	};
	secondaryGroupings: { [k: string]: string };
	disableSecondaryGroupings: boolean;
	displayTypes: { [k: string]: string };
	filterTypes: { [k: string]: string };
	customFieldTypes?: { entities: string[]; datatypes: string[] };
	hasGoal: boolean;
	family: string;
	colorMappings?: { [k: string]: { color: string; label: string } };
	name: string;
	export: boolean;
	exportEntity: string | null;
	special: boolean;
	enablePercent: boolean;
	enableWeightedValue: boolean;
	valueTypes: ValueType[];
	valueTypeLabel?: string;
	alwaysShowValueTypes: boolean;
	disableCustomGroupings: boolean;
	disableFutureDates: boolean;
	feature?: string;
	elevioId?: number;
	specialDrilldownOptions?: {
		[k: string]: { drilldownTypes: number[]; firstGroupings: string[]; secondGroupings: string[] };
	};
	proWidget: boolean;
	unavailableProWidget: boolean;
	infoText?: string;
};

export type ForecastTableDrilldownRow = {
	id: number;
	value: number;
	stage: {
		id: number;
		name: string;
	};
	client: {
		id: number;
		name: string;
	};
	date: Date;
	timeInPipeline: {
		value: number;
		unit: 'days' | 'weeks';
	};
};

export type ForecastTableRow = {
	user: { id: number; name: string };
	sales: { value: number };
	remaining: { value: number | null };
	pipeline: { value: number; change: number };
	pipelineCoverageRatio: { value: number | null; color: React.ComponentProps<typeof Text>['color'] };
	target: { value: number | null };
	drilldown: ForecastTableDrilldownRow[];
};

export type ForecastGraphRow = {
	id: number;
	sales: number;
	totalSales: number;
	newClient?: number;
	increase?: number;
	winBack?: number;
	decrease?: number;
	churn?: number;
};

export type ForecastData = RCWidgetData & {
	currency: string;
	total: {
		pace: { value: number; percentage: number | null };
		probableOutcome: { value: number; percentage: number | null };
		sales: { value: number; change: number };
		target: { value: number | null };
		remaining: { value: number | null };
		pipeline: { value: number; change: number };
		opportunities: { value: number; change: number };
		pipelineCoverageRatio: { value: number | null; color: string };
	};
	graph: {
		period: { total: number; current: number; unit: 'week' | 'day' };
		rows: ForecastGraphRow[];
	};
	table: ForecastTableRow[];
};

export enum ForecastStatus {
	POSITIVE,
	NEGATIVE,
	REACHED_TARGET
}

export type ForecastPreviewData = {
	currency: ForecastData['currency'];
	probableOutcome: ForecastData['total']['probableOutcome'];
	remaining: { value: number | null };
};

class ReportWidget extends Resource {
	constructor() {
		super('report');
	}
	async find(
		type: 'FORECAST',
		filters?: { [key: string]: any; preview: true }
	): Promise<{ data: ForecastPreviewData }>;
	// eslint-disable-next-line no-dupe-class-members
	async find(type: 'FORECAST', filters?: { [key: string]: any; preview?: false }): Promise<{ data: ForecastData }>;
	// eslint-disable-next-line no-dupe-class-members
	async find(
		type: Exclude<string, 'FORECAST'>,
		filters?: { [key: string]: any; preview?: boolean }
	): Promise<{ data: RCWidgetData }>;
	// eslint-disable-next-line no-dupe-class-members
	async find(
		type: string,
		filters?: { [key: string]: any; preview?: boolean }
	): Promise<{ data: RCWidgetData | ForecastPreviewData | ForecastData }> {
		if (typeof filters !== 'object') {
			filters = {};
		}

		const res = await this._getRequest(`widget/${type}`, { methodName: 'find', params: filters });
		return res?.data;
	}

	getMeta(): Promise<{ data: { [k: string]: RCWidgetMeta } }> {
		return this._getRequest('metadata/widget', { methodName: 'getMeta' }).then(res => res.data);
	}

	async get() {
		throw Error('ReportWidget.get() not allowed');
	}

	async save() {
		throw Error('ReportWidget.save() not allowed');
	}

	async delete() {
		throw Error('ReportWidget.delete() not allowed');
	}
}

const resource = new ReportWidget();

export default resource;
