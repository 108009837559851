import { EVENT_TYPES } from 'App/babel/enum/appointmentEvents';
import { EVENT_TIMELINE_SUB_TYPES, ACTIVITY_TIMELINE } from 'App/babel/enum/timeline';
import Event from 'App/resources/Model/Event';
import { containsPhoneWords } from 'App/babel/helpers/phoneWords';
import { getOutcomeStatus } from 'Components/Modals/Appointment/AppointmentOutcomes/Helpers';
import { IconName } from '@upsales/components';

const getAppointmentIconProps = (event: Event): [IconName, string?] => {
	const { outcome, outcomeAction, rescheduled, opportunity, activity, call } = event.data || {};

	if (event.subType === EVENT_TYPES.COMMENTCREATED) {
		return ['comment'];
	}

	if (rescheduled) {
		if (rescheduled.outcome === 'planned') {
			return ['calendar-arrow-right-o', 'red'];
		}
		return ['calendar-arrow-right-o'];
	}

	if (outcome) {
		const { outcomeCompleted } = getOutcomeStatus(outcome);
		if (outcomeAction && outcomeAction !== EVENT_TYPES.COMMENTCREATED) {
			return outcomeCompleted ? ['check'] : ['times'];
		}
		return outcomeCompleted ? ['check'] : ['calendar-times-o', 'red'];
	}

	if (opportunity) {
		const isOpportunity = outcomeAction === EVENT_TYPES.OPPORTUNITYCREATED;
		return isOpportunity ? ['opportunity'] : ['dollar'];
	}

	switch (event.subType?.toLowerCase()) {
		case EVENT_TYPES.CREATED:
			return ['plus'];
		case EVENT_TYPES.MAILSENT:
			return ['envelope'];
		case EVENT_TYPES.ACTIVITYCREATED:
		case EVENT_TYPES.APPOINTMENTCREATED:
		case EVENT_TYPES.CALLCREATED:
			return activity || call ? ['phone'] : ['calendar'];
		default:
			return ['calendar'];
	}
};

const getActivityIconProps = (event: Event): [IconName, string?] => {
	switch (event.subType) {
		case ACTIVITY_TIMELINE.CREATED:
			return ['plus'];
		case ACTIVITY_TIMELINE.REACHED_POSTPONED:
		case ACTIVITY_TIMELINE.NOT_REACHED_POSTPONED:
		case ACTIVITY_TIMELINE.POSTPONED:
		case ACTIVITY_TIMELINE.REACHED_NO_THANKS_POSTPONE:
		case ACTIVITY_TIMELINE.DATE_CHANGED:
			return ['calendar-arrow-right-o'];
		case ACTIVITY_TIMELINE.SET_DATE:
		case ACTIVITY_TIMELINE.REACHED_BOOK_APPOINTMENT:
			return ['calendar'];
		case ACTIVITY_TIMELINE.NOT_FOUND_LEAD_POSTPONED:
			return ['user-times'];
		case ACTIVITY_TIMELINE.NOT_FOUND_LEAD_STOP_TRYING:
			return ['user-times', 'red'];
		case ACTIVITY_TIMELINE.REACHED_STOP_TRYING:
		case ACTIVITY_TIMELINE.NOT_REACHED_STOP_TRYING:
		case ACTIVITY_TIMELINE.REACHED_NO_THANKS_STOP_TRYING:
		case ACTIVITY_TIMELINE.CLOSED_ACTIVITY:
			return ['times-circle'];
		case ACTIVITY_TIMELINE.REACHED_SEND_MAIL:
		case ACTIVITY_TIMELINE.NOT_REACHED_SEND_MAIL:
			return ['envelope'];
		case ACTIVITY_TIMELINE.REACHED_CREATE_ORDER:
			return ['sales'];
		case ACTIVITY_TIMELINE.REACHED_CREATE_OPPORTUNITY:
			return ['opportunity'];
		case ACTIVITY_TIMELINE.REACHED_PLAN_PHONECALL:
		case ACTIVITY_TIMELINE.PHONECALL:
			return ['phone'];
		case ACTIVITY_TIMELINE.USER_CHANGED:
		case ACTIVITY_TIMELINE.ACTIVITY_TYPE_CHANGED:
		case ACTIVITY_TIMELINE.DESCRIPTION_CHANGED:
			return ['edit'];
		case ACTIVITY_TIMELINE.REACHED_TALK_TO_SOMEONE:
		case ACTIVITY_TIMELINE.FOUND_LEAD_ADDED_CONTACT:
		case ACTIVITY_TIMELINE.CONTACT_CHANGED:
			if (!event.data?.contact?.id) {
				return ['user-times', 'red'];
			}
			return ['user-plus'];
		default:
			return ['' as IconName];
	}
};

export const getTimelineIconProps = (event: Event) => {
	let name: IconName | undefined, color, backgroundColor;
	color = 'grey-10';
	backgroundColor = '';
	switch (event.entityType?.toLowerCase()) {
		case 'opportunity':
			name = 'opportunity';
			break;
		case 'appointment':
			[name, color = 'grey-10'] = getAppointmentIconProps(event);
			break;
		case 'activity':
			name =
				event.activity?.activityType?.name && containsPhoneWords(event.activity.activityType.name)
					? 'phone'
					: 'activity';
			break;
		case 'activity_log':
			[name, color = 'grey-10'] = getActivityIconProps(event);
			break;
		case 'order':
			if (event.subType?.toLowerCase() === EVENT_TIMELINE_SUB_TYPES.LOST) {
				color = 'red';
				name = 'times';
				backgroundColor = 'super-light-red';
			} else if (event.subType?.toLowerCase() === EVENT_TIMELINE_SUB_TYPES.WON) {
				color = 'green';
				name = 'dollar';
				backgroundColor = 'super-light-green';
			}
			break;
		case 'mail':
			if (
				event.subType?.toLowerCase() === EVENT_TIMELINE_SUB_TYPES.SOFT_BOUNCE ||
				event.subType?.toLowerCase() === EVENT_TIMELINE_SUB_TYPES.HARD_BOUNCE
			) {
				color = event.subType.toLowerCase() === EVENT_TIMELINE_SUB_TYPES.SOFT_BOUNCE ? 'orange' : 'red';
				name = 'exclamation-triangle';
			} else {
				name = 'envelope';
			}
			break;
		case 'mailcampaign':
			name = 'email-campaign';
			break;
		case 'agreement':
			color = 'green';
			backgroundColor = 'super-light-green';
			name = 'subscription';
			break;
		case 'client':
			name = 'plus';
			break;
		case 'contact':
			name = 'user-plus';
			break;
		case 'comment':
			name = 'comment';
			break;
		case 'socialevent':
			name = 'ticket';
			break;
		case 'clientplan':
			name = 'book';
			break;
		case 'ticket':
			name = 'customer-support';
			break;
		default:
			break;
	}
	return { name, color, backgroundColor };
};
