import React, { FC, useMemo, useState } from 'react';
import T from 'Components/Helpers/translate';
import { camelCase } from 'lodash';

import {
	Block,
	Button,
	ButtonSelect,
	Icon,
	Input,
	Label,
	Modal,
	ModalContent,
	ModalControls,
	ModalHeader,
	Row,
	Text,
	Textarea,
	Title,
	Tooltip
} from '@upsales/components';

import BemClass from '@upsales/components/Utils/bemClass';

import useStableKeys from 'App/components/hooks/useStableKeys';

import UpSelect from 'Components/Inputs/UpSelect';
import ModalTagList from 'Components/Modals/ModalTagList';

import FormObserver, { FieldModel } from '../FormObserver';
import InlineAction from 'Components/Dialogs/InlineAction/InlineAction';

import SendWebhookHeader from './SendWebhookHeader';

import './SendWebhook.scss';
import { DefaultButton, PrimaryButton } from '@upsales/components/Buttons';

const HTTP_METHODS = ['POST', 'PUT', 'GET', 'DELETE'];

type ContentType = { id: number; name: string };
const CONTENT_TYPES: ContentType[] = [
	{ id: 0, name: 'application/atom+xml' },
	{ id: 1, name: 'application/json' },
	{ id: 2, name: 'application/x-www-form-urlencoded' },
	{ id: 3, name: 'application/xml' },
	{ id: 4, name: 'multipart/form-data' },
	{ id: 5, name: 'text/html' },
	{ id: 6, name: 'text/plain' }
];

type Select2Event<T> = {
	target: {
		value: T;
		added: T;
	};
};

type HeaderType = {
	id: number;
	key: string;
	value: string;
};

type Webhook = {
	[key: string]: string | HeaderType[] | ContentType | undefined; //indexable
	body?: string;
	contentType: ContentType;
	encoding: string;
	headers: HeaderType[];
	method: string;
	url: string;
};

type DBWebhook = { name: string; value: string }[];

type PrefillWebhook = {
	body: string;
	contentType: string;
	encoding: string;
	method: string;
};

/** Some helpers DB -> UI -> DB */

const upperFirst = (str: string) => {
	return str.charAt(0).toUpperCase() + str.slice(1);
};

const mapWebhookToDB = (wh: Webhook): DBWebhook => {
	const mappedWebhook = [];
	for (const [key, vals] of Object.entries(wh)) {
		if (key === 'headers' && wh[key].length > 0) {
			const headers = wh[key].map(item => `${item.key}:${item.value}`).join('\n');

			mappedWebhook.push({ name: upperFirst(key), value: headers });
		} else if (key === 'url') {
			mappedWebhook.push({ name: 'URL', value: wh[key] });
		} else if (key === 'contentType') {
			mappedWebhook.push({ name: upperFirst(key), value: wh[key].name });
		} else if (typeof vals === 'string' && vals !== '') {
			mappedWebhook.push({ name: upperFirst(key), value: vals });
		}
	}

	return mappedWebhook;
};

const mapWebhookFromDB = (wh?: DBWebhook | PrefillWebhook): Webhook => {
	const mappedWebhook: Webhook = {
		body: '',
		contentType: CONTENT_TYPES[1],
		encoding: 'UTF-8',
		headers: [],
		method: HTTP_METHODS[0],
		url: ''
	};

	if (!wh) {
		return mappedWebhook;
	}

	if (!Array.isArray(wh)) {
		// Prefill uses the same defaults as above except body
		mappedWebhook.body = wh.body;
	} else {
		for (const item of wh) {
			item.name = camelCase(item.name);
			if (item.name === 'headers') {
				const rows = item.value.split('\n');

				for (let i = 0; i < rows.length; ++i) {
					const [key, value] = rows[i].split(':');
					mappedWebhook.headers.push({ id: i, key, value });
				}
			} else if (item.name === 'contentType') {
				const idx = CONTENT_TYPES.findIndex(c => c.name === item.value);
				if (idx !== -1) {
					mappedWebhook.contentType = CONTENT_TYPES[idx];
				}
			} else {
				mappedWebhook[camelCase(item.name)] = item.value;
			}
		}
	}

	return mappedWebhook;
};

interface Props {
	className: string;
	close: (webhook?: DBWebhook) => void;
	properties?: DBWebhook | PrefillWebhook;
	tagEntity?: string;
}

type InlinePosition = 'top' | 'bottom' | null;

const SendWebhook: FC<Props> = ({ className, close, properties, tagEntity = 'general' }) => {
	const [showTagList, setShowTagList] = useState(false);
	const [showInline, setShowInline] = useState<InlinePosition>(null);

	const initialWebhook = useMemo(() => mapWebhookFromDB(properties), []);

	const createHeaderId = useStableKeys(initialWebhook.headers.length);

	const bem = useMemo(() => new BemClass('SendWebhook', className).mod({ showTagList }), [className, showTagList]);

	const lang = useMemo(
		() => ({
			addHeader: T('automationAction.SendWebhook.addHeader'),
			availableTags: T('automationAction.SendWebhook.availableTags'),
			body: 'Body', // Don't translate technical words
			cancel: T('cancel'),
			contentType: 'Content-Type', // Same here
			encoding: 'Encoding', // And here
			headerKey: 'Header key', // ...
			headers: 'Headers', // ...
			headerValue: 'Header value', // ...
			hideTags: T('automationAction.SendWebhook.hideTags'),
			method: 'Method', // ...
			noHeaders: T('automationAction.SendWebhook.noHeaders'),
			saveAction: T('default.save'),
			title: T('automationAction.SendWebhook'),
			url: 'URL' // ...
		}),
		[]
	);

	const toggleTagList = () => {
		setShowTagList(!showTagList);
	};

	const onClose = (inlineAction: 'top' | 'bottom', dirty: boolean) => {
		if (!dirty) {
			close();
		} else {
			setShowInline(inlineAction);
		}
	};

	const save = (values: Webhook) => {
		close(mapWebhookToDB(values));
	};

	return (
		<Modal className={bem.b()} size="lg">
			<div className={bem.elem('content').b()}>
				<FormObserver<Webhook>
					initialValues={initialWebhook}
					onSubmit={save}
					model={{
						body: FieldModel.string(lang.body),
						contentType: FieldModel.object(lang.contentType, {
							id: FieldModel.number('id'),
							name: FieldModel.string(lang.contentType)
						}).required(),
						encoding: FieldModel.string(lang.encoding).required(),
						headers: FieldModel.listOf(
							lang.headers,
							FieldModel.object('header', {
								id: FieldModel.number('id'),
								key: FieldModel.string(lang.headerKey).required(),
								value: FieldModel.string(lang.headerValue).required()
							})
						),
						method: FieldModel.string(lang.method).required(),
						url: FieldModel.url(lang.url).required()
					}}
				>
					{({ dirty, inputProps, onFormChange, submit, values, errors }) => {
						const showBody = values.method !== 'GET' && values.method !== 'DELETE';

						return (
							<>
								<ModalHeader title={lang.title} onClose={() => onClose('top', dirty)}>
									<DefaultButton
										onClick={toggleTagList}
										text={showTagList ? lang.hideTags : lang.availableTags}
									/>
									{showInline === 'top' ? (
										<InlineAction
											toggleInlineAction={() => setShowInline(null)}
											onReject={close}
											onConfirm={submit}
											showTop
										/>
									) : null}
								</ModalHeader>

								<ModalContent>
									<Block className={bem.elem('grid').b()}>
										<Block>
											<Label required>{lang.url}</Label>
											<Input {...inputProps.url} />
										</Block>

										<Block>
											<Label>Method</Label>
											<ButtonSelect
												color="white"
												onChange={value => {
													onFormChange('method', value);
												}}
												options={HTTP_METHODS}
												value={inputProps.method.value}
											/>
										</Block>

										<Block>
											<Label className={bem.elem('content-type').b()}>
												Content-type
												<Tooltip position="right" title={'Tooltips'}>
													<Icon name="info-circle" />
												</Tooltip>
											</Label>

											<UpSelect
												className={bem.elem('drop-down').b()}
												data={CONTENT_TYPES}
												defaultValue={values.contentType}
												onChange={(e: Select2Event<ContentType>) => {
													onFormChange('contentType', e.target.added);
												}}
												multiple={false}
												required
												storeIdInState={false}
											/>
										</Block>

										<Block>
											<Label required>Encoding</Label>
											<Input {...inputProps.encoding} />
										</Block>
									</Block>

									{showBody ? (
										<Block space="mtl">
											<Label>Body</Label>
											<Textarea
												onChange={e => onFormChange('body', e.target.value)}
												value={inputProps.body?.value ?? ''}
											/>
										</Block>
									) : null}

									<Block space="mtl">
										<Row className={bem.elem('header-text').b()}>
											<Title>Headers</Title>
											<DefaultButton
												size="sm"
												onClick={() =>
													onFormChange('headers', [
														...values.headers,
														{ id: createHeaderId() } as HeaderType
													])
												}
											>
												<Icon name="plus" space="mrs" />
												{lang.addHeader}
											</DefaultButton>
										</Row>

										<Block>
											{values.headers.length === 0 ? (
												<Text bold center color="grey-8" size="xl">
													{lang.noHeaders}
												</Text>
											) : (
												inputProps.headers.value.map((header: HeaderType, i: number) => (
													<SendWebhookHeader
														key={`header-${header.id}`}
														remove={() => {
															onFormChange('headers', [
																...values.headers.slice(0, i),
																...values.headers.slice(i + 1)
															]);
														}}
														pair={{ key: header.key, value: header.value }}
														onChange={(field: string, value: string) => {
															onFormChange(`headers[${i}].${field}`, value);
														}}
														keyError={errors[`headers[${i}].key`]}
														valueError={errors[`headers[${i}].value`]}
													/>
												))
											)}
										</Block>
									</Block>
								</ModalContent>

								<ModalControls>
									<Row>
										<PrimaryButton disabled={!dirty} text={lang.saveAction} submit />
										<Button
											text={lang.cancel}
											type="link"
											onClick={() => onClose('bottom', dirty)}
										/>
										{showInline === 'bottom' ? (
											<InlineAction
												toggleInlineAction={() => setShowInline(null)}
												onReject={close}
												onConfirm={submit}
											/>
										) : null}
									</Row>
								</ModalControls>
							</>
						);
					}}
				</FormObserver>
			</div>

			<div className={bem.elem('tags').mod({ translateTagsList: showTagList }).b()}>
				<ModalTagList
					excludedTags={tagEntity === 'order' ? [/Order_Row\S*$/] : []}
					entity={tagEntity}
					onClose={toggleTagList}
				/>
			</div>
		</Modal>
	);
};

export default SendWebhook;
