import CustomerLanguageResource from 'App/babel/resources/CustomerLanguage';
import FieldTranslationResource from 'App/babel/resources/FieldTranslations';
import SalesCoachStakeholderRoleResource from 'App/babel/resources/SalesCoachStakeholder';

/*********** Public Constants **********/

export const TYPE = {
	VALUE: 'value',
	EDIT: 'edit',
	ADD: 'add',
	NEW: 'new'
};

export const initialState = {
	defaultLanguage: null,
	loading: true,
	fieldName: null,
	editObj: null,
	fieldValues: [],
	availableLanguages: [],
	staticLanguages: [],
	isEditMode: false
};

let resource = FieldTranslationResource;

/*********** Redux stuff **********/

const SET_DEFAULT_LANGUAGE = '[Field translations] Set Default Langauge';
const SET_FIELD_NAME = '[Field translations] Set Field Name';
const SET_FIELD_VALUES = '[Field translations] Set Field Values';
const SET_AVAILABLE_LANGUAGES = '[Field translations] Set Available Languages';
const SET_EDIT = '[Field translations] Set editObj';
const SET_LOADING = '[Field translations] Set Loading';
const SET_EDIT_MODE = '[Field translations] Set Edit Mode';
const SET_STATIC_LANGUAGES = '[Field translations] Set Static Languages';

const reducer = (state = initialState, action) => {
	switch (action.type) {
		case SET_DEFAULT_LANGUAGE:
			return { ...state, defaultLanguage: action.defaultLanguage };
		case SET_FIELD_NAME:
			return { ...state, fieldName: action.fieldName };
		case SET_FIELD_VALUES:
			return { ...state, fieldValues: action.fieldValues };
		case SET_AVAILABLE_LANGUAGES:
			return { ...state, availableLanguages: action.languages };
		case SET_EDIT:
			return { ...state, editObj: action.editObj };
		case SET_LOADING:
			return { ...state, loading: action.loading };
		case SET_STATIC_LANGUAGES:
			return { ...state, staticLanguages: action.staticLanguages };
		case SET_EDIT_MODE:
			return { ...state, isEditMode: action.isEditMode };
		default:
			return state;
	}
};

export default reducer;

/*********** Actions **********/

export const setDefaultLanguage = defaultLanguage => {
	return { type: SET_DEFAULT_LANGUAGE, defaultLanguage };
};

export const setFieldName = fieldName => {
	return { type: SET_FIELD_NAME, fieldName };
};

export const setFieldValues = fieldValues => {
	return { type: SET_FIELD_VALUES, fieldValues };
};

export const setAvailableLanguages = languages => {
	return { type: SET_AVAILABLE_LANGUAGES, languages };
};

export const setEdit = editObj => {
	return { type: SET_EDIT, editObj };
};

export const setLoading = loading => {
	return { type: SET_LOADING, loading };
};

export const setEditMode = isEditMode => {
	return { type: SET_EDIT_MODE, isEditMode };
};

/*********** Private stuff **********/

const parseData = (defaultLanguage, data) => {
	const map = {};

	_.each(data, translation => {
		if (!map[translation.tagId]) {
			map[translation.tagId] = {
				tagId: translation.tagId,
				type: translation.type,
				usedCount: translation.usedCount
			};
			map[translation.tagId].translations = [];
		}

		if (defaultLanguage === translation.language) {
			map[translation.tagId].default = translation;
		} else {
			map[translation.tagId].translations.push(translation);
		}

		if (!map[translation.tagId].default) {
			map[translation.tagId].default = { ...translation, language: defaultLanguage };
			delete map[translation.tagId].default.id;
		}
	});

	return _.sortBy(Object.values(map), row => {
		return row.default.value.toLowerCase();
	});
};

export const cancelEdit = () => {
	return (dispatch, getState) => {
		const { editObj, fieldValues } = getState().TranslateField;

		if (editObj && editObj.type === TYPE.NEW) {
			fieldValues.pop();
			dispatch(setFieldValues(fieldValues));
		}

		dispatch(setEdit(null));
	};
};

export const edit = (type, obj) => {
	return (dispatch, getState) => {
		const { fieldValues, editObj } = getState().TranslateField;

		if (type === TYPE.NEW && !obj) {
			obj = {};
			fieldValues.push(obj);
			dispatch(setFieldValues(fieldValues));
		}

		// If we start editing something else when we have a new row in fieldValues, we need to remove it
		if (type !== TYPE.NEW && editObj?.type === TYPE.NEW) {
			const filteredFieldValues = fieldValues.filter(obj => !!obj.tagId);
			dispatch(setFieldValues(filteredFieldValues));
		}

		dispatch(setEdit({ type, obj }));
	};
};

/***** Dispatched functions *****/

export const getFieldValues = () => {
	return (dispatch, getState) => {
		const { defaultLanguage, fieldName, isEditMode } = getState().TranslateField;
		dispatch(setLoading(true));

		resource
			.find({ type: fieldName, allTranslations: true })
			.then(res => {
				const parsed = parseData(defaultLanguage, res.data);

				dispatch(setFieldValues(parsed));
				dispatch(setLoading(false));

				if (isEditMode && (!Array.isArray(parsed) || !parsed.length)) {
					dispatch(edit(TYPE.NEW));
				}
			})
			.catch(e => console.log('Failed to fetch field values', e));
	};
};

export const initComponent = (fieldName, salesProcessId, defaultLang = '', editMode = false, salesCoachId) => {
	return dispatch => {
		dispatch(setLoading(true));
		dispatch(setEditMode(editMode));
		Promise.all([Tools.AppService.getStaticValuesPromise('languages'), CustomerLanguageResource.get()])
			.then(res => {
				dispatch(setFieldName(fieldName));

				if (fieldName === 'stakeholder') {
					if (salesCoachId) {
						resource = SalesCoachStakeholderRoleResource;
						resource.setSalesCoachId(salesCoachId);
					}
				} else {
					resource = FieldTranslationResource;
				}

				const staticLanguages = res[0] || [];
				dispatch({ type: SET_STATIC_LANGUAGES, staticLanguages });
				const [language] = defaultLang.split('-');
				const customerLanguages = res[1];
				if (customerLanguages.data && customerLanguages.data.length) {
					let defaultLanguage = _.find(customerLanguages.data, 'isMaster');
					let languages = _.reject(customerLanguages.data, 'isMaster');

					if (language) {
						defaultLanguage = _.find(customerLanguages.data, { language });
						languages = _.reject(customerLanguages.data, { language });
					}

					dispatch(setDefaultLanguage(defaultLanguage ? defaultLanguage.language : null));

					dispatch(setAvailableLanguages(languages));
					dispatch(getFieldValues());
				} else {
					dispatch(setLoading(false));
				}
			})
			.catch(e => console.log('Failed to init translation component', e));
	};
};

export const saveDefaultLanguage = language => {
	return dispatch => {
		dispatch(setLoading(true));
		CustomerLanguageResource.save({ language: language, isMaster: true })
			.then(() => {
				dispatch(setDefaultLanguage(language));
				dispatch(getFieldValues());
				dispatch(setLoading(false));
			})
			.catch(e => console.log('Failed to save default language', e));
	};
};

export const deleteTag = tagId => {
	return dispatch => {
		resource
			.deleteTag(tagId)
			.then(() => {
				dispatch(getFieldValues());
			})
			.catch(e => console.log('Failed to delete translation tag', e));
	};
};

export const deleteTranslation = id => {
	return dispatch => {
		resource
			.delete(id)
			.then(() => {
				dispatch(cancelEdit());
				dispatch(getFieldValues());
			})
			.catch(e => console.log('Failed to delete translation', e));
	};
};

export const saveTranslation = () => {
	return (dispatch, getState) => {
		const { editObj, defaultLanguage, fieldName } = getState().TranslateField;
		const obj = editObj.obj;
		obj.type = fieldName;

		switch (editObj.type) {
			case TYPE.VALUE:
				obj.language = defaultLanguage;
				obj.value = obj.defaultValue;
			/* eslint-disable no-fallthrough */
			case TYPE.EDIT:
			case TYPE.ADD:
				/* eslint-enable no-fallthrough */
				return resource.save(obj).then(() => {
					dispatch(getFieldValues());
					dispatch(cancelEdit());
				});

			case TYPE.NEW:
				var newObj = {
					type: fieldName,
					language: defaultLanguage,
					value: obj.defaultValue
				};

				return resource
					.save(newObj)
					.then(res => {
						if (!obj.language || !obj.value) {
							// eslint-disable-next-line promise/no-return-wrap
							return Promise.resolve();
						}

						obj.tagId = res.data.tagId || res.data.tag?.id;
						return resource.save(obj, { skipNotification: true });
					})
					.finally(() => {
						dispatch(getFieldValues());
						dispatch(cancelEdit());
					});
			default:
				console.warn('Just got switched cased into default');
		}
	};
};
