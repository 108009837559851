import React, { useState, useEffect, Fragment } from 'react';
import { AppointmentEvent } from 'App/resources/Model/Event';
import Source from 'App/resources/Model/Source';
import { Text, Link, IconName } from '@upsales/components';
import TimelineRow from 'Components/TimelineRow';
import { SubTitle } from '../Helpers';
import {
	onSourceClick,
	getSourceEntity,
	getSourceResource,
	getSourceName,
	TYPES
} from 'Components/Helpers/SourceHelper';
import logError from 'App/babel/helpers/logError';
import { makeCancelable } from 'Helpers/promise';

type Props = {
	event: AppointmentEvent;
	hideIcon?: boolean;
	source: Source | undefined;
};

const CreatedEvent = ({ event, source, hideIcon }: Props) => {
	const {
		data: { regDate },
		user
	} = event;
	const { $translate: t } = Tools;

	const [title, setTitle] = useState(
		<Fragment key="title">
			{t('appointment.outcomeLog.created')}
			{user ? t('appointment.outcomeLog.byUser', { user: user.name }) : ''}
		</Fragment>
	);

	useEffect(() => {
		const hasSource = source?.type && source?.id;
		const resource = getSourceResource(source);

		if (hasSource && resource && source) {
			const sourceEntity = getSourceEntity(source) ?? '';
			const p = makeCancelable(resource.get(source.id));
			p.promise
				.then(({ data }: { data: {} }) => {
					setTitle(
						<Fragment key="title">
							{t('appointment.outcomeLog.created') + ' '}
							{t('appointment.outcomeLog.fromSource', { source: sourceEntity.toLowerCase() }) + ' '}
							<Link onClick={() => onSourceClick(source, data)}>{getSourceName(source, data)}</Link>
						</Fragment>
					);
				})
				.catch((err: { status: number }) => {
					if (err?.status === 404) {
						setTitle(
							<Fragment key="title">
								{t('appointment.outcomeLog.sourceRemoved', { source: sourceEntity })}
							</Fragment>
						);
					} else {
						logError(err, `Failed to get source resource ${source.type} with id ${source.id}`);
					}
				});

			return () => p.cancel();
		} else if (source?.type === TYPES.EASYBOOKING) {
			setTitle(
				<Fragment key="title">
					{t('appointment.outcomeLog.created') + ' '}
					{t('appointment.outcomeLog.fromSource', { source: TYPES.EASYBOOKING })}
				</Fragment>
			);
		}
	}, [source?.id, source?.type]);

	const titleComponent = <Text>{title}</Text>;
	const subTitle = <SubTitle date={regDate} />;

	return (
		<TimelineRow
			event={event}
			icon={(hideIcon ? '' : 'plus') as IconName}
			hideLine
			title={titleComponent}
			subTitle={subTitle}
		/>
	);
};

export default CreatedEvent;
