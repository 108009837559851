import './SalesboardCard.scss';
import React, { useEffect, useState } from 'react';
import { get } from 'lodash';
import { connect } from 'react-redux';
import { Avatar, Block, Card, Icon, Link, Text, Tooltip, Row, Loader, IconName } from '@upsales/components';
import BemClass from '@upsales/components/Utils/bemClass';
import T from 'Components/Helpers/translate';
import RiskChip from 'App/components/RiskChip';
import { RootState } from 'Store/index';
import { RiskEvaluatedOpportunity } from 'App/resources/Model/Opportunity';
import moment from 'moment';
import { Field } from 'App/resources/Model/SalesboardCard';
import Appointment from 'App/resources/Model/Appointment';
import User from 'App/resources/Model/User';
import { dateCalendar } from 'App/helpers/DateHelpers';
import { getOrderForecastingRisk, ForecastingRiskLevel } from 'App/enum/ForecastingRisks';
import SalesProcessToolTip from './SalesProcessToolTip';
import { useDrag } from 'react-dnd';
import { SalesboardViewColumn } from 'App/resources/AllIWant';
import { useSpeedTracker } from 'App/helpers/speedTracker';
import { useSoftDeployAccess } from '../hooks';

const mapStateToProps = (state: RootState) => ({
	salesboardState: state.Salesboard
});

type Props = {
	entity: (RiskEvaluatedOpportunity | Appointment) & { loading?: boolean };
	fields: { top?: Field; middleLeft?: Field; middleRight?: Field; bottomLeft?: Field };
	showUser: boolean;
	salesboardState: RootState['Salesboard'];
	onClick?: () => void;
	column?: SalesboardViewColumn;
};

const isAppointment = (entity: Props['entity']): entity is Appointment => entity.hasOwnProperty('users');

const SalesboardCard = ({ entity, fields, onClick, showUser, column }: Props) => {
	const [collected, drag, dragPreview] = !column
		? [{}, null, null]
		: useDrag(
				() => ({
					type: 'ALL',
					item: {
						entity,
						column,
						isAppointment: isAppointment(entity)
					},
					// Check for column to not crash when user drags something else onto the browser (like a file)
					collect: monitor =>
						monitor.getItem()?.column
							? {
									isDragging: !!monitor.isDragging(),
									otherIsDragging: monitor.getItem() && monitor.getItem().entity !== entity
							  }
							: {
									isDragging: false,
									otherIsDragging: false
							  }
				}),
				[entity]
		  );

	const startSpeedTrackingOnCondition = collected?.isDragging || false;
	const endSpeedTrackingOnCondition = !!entity && !entity.loading;

	const hasTodos = useSoftDeployAccess('TODO_LIST');
	const hasSnappySalesboard = useSoftDeployAccess('SNAPPY_SALESBOARD');

	useSpeedTracker(
		'SalesboardCard',
		startSpeedTrackingOnCondition,
		endSpeedTrackingOnCondition,
		`${entity?.id ?? ''}`
	);

	if (!entity) {
		return null;
	}

	const users = isAppointment(entity) ? entity.users ?? [] : entity.user ? [entity.user] : [];
	const [hide, setHide] = useState(false);

	useEffect(() => {
		requestAnimationFrame(() => {
			if (collected.otherIsDragging) {
				setHide(true);
			} else {
				setHide(false);
			}
		});
	}, [collected.otherIsDragging]);
	const [userAvatars, setUserAvatars] = useState<Tools.Avatar[]>([]);

	const classes = new BemClass('SalesboardCard');
	const formatCurrency = Tools.$filter('currencyFormat');
	const formatDate = (date: Date) => dateCalendar(date);

	const renderText = (
		text: string | JSX.Element,
		isTop: boolean = false,
		size: 'sm' | 'md' = 'sm',
		color: React.ComponentProps<typeof Text>['color'] = 'black'
	) => (
		<Text color={color} size={isTop ? 'md' : size} bold={isTop}>
			{text}
		</Text>
	);

	const renderMeetingIcon = (task: RiskEvaluatedOpportunity['evaluatedRisks']['meeting']) => {
		let iconColor,
			tooltipTitle,
			iconName: IconName = 'calendar-times-o';

		if (!task?.id) {
			iconColor = 'grey-7';
			tooltipTitle = T('opportunity.salesBoardToolTip.noMeeting');
		} else if (moment(task.timestamp).isAfter(moment())) {
			iconColor = 'green';
			tooltipTitle = T('opportunity.salesBoardToolTip.hasMeeting');
			iconName = 'calendar-check-o';
		} else {
			iconColor = 'red';
			tooltipTitle = T('opportunity.salesBoardToolTip.lateMeeting');
		}

		return (
			<Tooltip distance={20} title={tooltipTitle}>
				<Icon color={iconColor} name={iconName} />
			</Tooltip>
		);
	};

	const renderTaskIcons = (
		task:
			| RiskEvaluatedOpportunity['evaluatedRisks']['activity']
			| RiskEvaluatedOpportunity['evaluatedRisks']['phonecall']
			| RiskEvaluatedOpportunity['evaluatedRisks']['todo'],
		type: 'Phonecall' | 'Activity' | 'Todo'
	) => {
		let iconColor, tooltipTitle;
		const iconName: IconName = type === 'Phonecall' ? 'phone' : 'activity';

		// The task.date does not contain the time, so we need to add the time to the date
		if (task?.id) {
			if (task.date !== '0000-00-00' && task?.time) {
				const taskDate = moment(task.date);
				const taskTime = moment(task.time, 'HH:mm:ss');
				taskDate.set({
					hour: taskTime.get('hour'),
					minute: taskTime.get('minute'),
					second: taskTime.get('second')
				});
				task.date = taskDate.toDate();
			} else if (task.date !== '0000-00-00' && !task?.time) {
				// If not a time is set the default time is always in the beginning of the day 00.00 (eg. 2023-12-22 00:00)
				// Add 23 hours, 59 minutes and 59 seconds to the date to get to the end of the day
				task.date = moment(task.date).add(1, 'day').subtract(1, 'second').toDate();
			}
		}

		if (!task?.id) {
			iconColor = 'grey-7';
			tooltipTitle = T('opportunity.salesBoardToolTip.no' + type);
		} else if (task.date === '0000-00-00' || moment(task.date).isAfter(moment())) {
			iconColor = 'green';
			tooltipTitle = T('opportunity.salesBoardToolTip.has' + type);
		} else {
			iconColor = 'red';
			tooltipTitle = T('opportunity.salesBoardToolTip.late' + type);
		}

		return (
			<Tooltip distance={20} title={tooltipTitle}>
				<Icon color={iconColor} name={iconName} />
			</Tooltip>
		);
	};

	const renderRelated = (fields?: Field) => {
		const salesCoachEntity = entity as RiskEvaluatedOpportunity;

		if (!fields || salesCoachEntity?.probability === 100 || salesCoachEntity?.probability === 0) return null;

		const hasSalesProcess = (entity as RiskEvaluatedOpportunity)?.salesCoach?.[0]?.id;

		return (
			<div className={classes.elem('iconsGroup').b()}>
				{fields.meeting !== false ? renderMeetingIcon(salesCoachEntity.evaluatedRisks.meeting) : null}
				{fields.phonecall !== false && hasTodos
					? renderTaskIcons(salesCoachEntity.evaluatedRisks.phonecall, 'Phonecall')
					: null}
				{fields.todo !== false && hasTodos
					? renderTaskIcons(salesCoachEntity.evaluatedRisks.todo, 'Todo')
					: null}
				{fields.todo !== false && !hasTodos
					? renderTaskIcons(salesCoachEntity.evaluatedRisks.allActivity, 'Activity')
					: null}

				{fields.related && hasSalesProcess ? <SalesProcessToolTip order={salesCoachEntity} /> : null}
			</div>
		);
	};

	const getCustomFieldContent = (field: string | undefined, isTop?: boolean) => {
		const customFieldId = parseInt(field ?? '0');
		const customFields = (entity as RiskEvaluatedOpportunity).custom;
		const customField = customFields.find(field => field.id === customFieldId);
		let value = customField?.value ?? '';

		if (customField?.datatype === 'Date' && value) {
			value = moment(value).format('YYYY-MM-DD');
		} else if (customField?.datatype === 'Boolean') {
			value = value ? 'Yes' : 'No';
		} else if (customField?.datatype === 'User' && value) {
			value = Tools.AppService.getUsers().find(u => u.id === parseInt(value))?.name ?? '';
		}
		return renderText(value, isTop);
	};

	const renderField = (fieldConfig?: Field, isTop?: boolean) => {
		if (fieldConfig?.related) {
			return renderRelated(fieldConfig);
		}

		const fieldValue = get(entity, fieldConfig?.field || '', '');
		if (!fieldValue && fieldConfig?.field === 'client.name') {
			return (
				<Text className={classes.elem('noAccount').b()} italic color="grey-11">
					{T('default.noAccount')}
				</Text>
			);
		}

		let content;

		switch (fieldConfig?.type) {
			case 'currency':
				content = renderText(
					formatCurrency(fieldValue === '' ? 0 : fieldValue, (entity as RiskEvaluatedOpportunity).currency),
					isTop
				);
				break;
			case 'date':
				content = renderText(
					fieldValue ? formatDate(fieldValue).toString() : '',
					isTop,
					undefined,
					!isAppointment(entity) &&
						![0, 100].includes(entity.probability) &&
						moment().isAfter(fieldValue, 'day')
						? 'red'
						: undefined
				);
				break;
			case 'customField':
				content = getCustomFieldContent(fieldConfig.field, isTop);
				break;
			default:
				content = renderText(fieldValue, isTop);
				break;
		}

		if (fieldConfig && fieldConfig.link && fieldConfig.state) {
			const customerId = Tools.AppService.getCustomerId();
			const clientId = get(entity, fieldConfig?.id || 'client.id', null);
			content = (
				<Link
					onClick={e => e.stopPropagation()}
					href={Tools.$state.href(fieldConfig.state, { customerId: customerId, id: clientId })}
				>
					{content}
				</Link>
			);
		}
		return content;
	};

	const renderSection = (elemName: string, fieldConfig?: Field) => {
		const getTooltip = () => {
			const tooltipTitle = get(entity, fieldConfig?.tooltip || '', '') ?? '';
			return tooltipTitle.toString().replace(/</g, '&lt;').replace(/>/g, '&gt;'); // To handle <<No description>>
		};

		return (
			<Block className={classes.elem(elemName).mod({ clickable: fieldConfig?.link }).b()}>
				<Tooltip title={getTooltip()}>{renderField(fieldConfig, elemName === 'top')}</Tooltip>
			</Block>
		);
	};

	const getAvatars = async () => {
		const avatars = await Promise.all(users.map(async user => await Tools.avatarService.getAvatar(user)));
		setUserAvatars(avatars);
	};

	useEffect(() => {
		if (showUser) {
			getAvatars();
		}
	}, [showUser]);

	const renderUserAvatar = (user: Pick<User, 'id' | 'name'>, i: number) => {
		const userAvatar = userAvatars[i] ?? { url: null };
		return (
			<Tooltip key={i} title={user.name}>
				<Avatar src={userAvatar.url} initials={user.name} size="sm" />
			</Tooltip>
		);
	};

	const renderUserAvatars = (elemName: string) =>
		showUser ? (
			<div className={classes.elem(elemName).b()}>{users.map((user, i) => renderUserAvatar(user, i))}</div>
		) : null;

	const renderForecastingRisks = () => {
		if (isAppointment(entity)) {
			return null;
		}

		const risk = getOrderForecastingRisk(entity);

		if (!risk) {
			return null;
		}

		return (
			<Row className={classes.elem('risks').b()}>
				<Block className={classes.elem('risks').b()} space="mbm">
					<RiskChip
						title={T(`forecastingRisk.${risk.type}`)}
						chipType={risk.type === ForecastingRiskLevel.High ? 'danger' : 'alert'}
						tooltip={risk.tooltip(entity)}
						riskName={risk.field}
						riskOccured={entity.risks[risk.field] as Date}
					/>
				</Block>
			</Row>
		);
	};

	return collected.isDragging ? (
		<div ref={dragPreview}></div>
	) : !hide ? (
		<Card
			ref={drag}
			key={entity.id}
			className={classes.mod({ clickable: !!onClick, loading: entity.loading && !hasSnappySalesboard }).b()}
			space="ptm plm pbm prm"
			onClick={onClick}
			data-testid={`SalesboardCard-${entity.id}`}
		>
			{entity.loading && !hasSnappySalesboard ? <Loader size="xs" /> : null}
			{renderForecastingRisks()}
			<Row className={classes.elem('topSection').b()}>{renderSection('top', fields.top)}</Row>
			<Row className={classes.elem('middleSection').b()}>
				{renderSection('middleLeft', fields.middleLeft)}
				{renderSection('middleRight', fields.middleRight)}
			</Row>
			<Row className={classes.elem('bottomSection').b()}>
				{renderSection('bottomLeft', fields.bottomLeft)}
				{renderUserAvatars(fields.bottomLeft ? 'bottomRight' : 'bottom')}
			</Row>
		</Card>
	) : null;
};

export const detached = SalesboardCard;
const ConnectComponent = connect(mapStateToProps)(SalesboardCard);
export default ConnectComponent;
