import React, { useState } from 'react';
import { Input, Button, Icon, Title, TimeInput, Block, Toggle, Text } from '@upsales/components';
import BemClass from '@upsales/components/Utils/bemClass';
import PropTypes from 'prop-types';
import './TriggerDatePicker.scss';
import T from 'Components/Helpers/translate';
import UpSelect from 'Components/Inputs/UpSelect';
import getAngularModule from 'App/babel/angularHelpers/getAngularModule';
import { SlideFade } from '@upsales/components/animations';

const TriggerDatePicker = ({
	time,
	date,
	onTimeChange,
	onDateChange,
	onClear,
	entity,
	withToggle = false,
	isTodo = false,
	dateTag,
	offset
}) => {
	const [showInput, setShowInput] = useState(!!date);

	const formatDateObj = ({ d, o }) => {
		if (d) {
			onDateChange(`{{${d}:${o}}}`);
		} else {
			onDateChange(null);
		}
	};

	const TagsService = getAngularModule('TagsService');
	const dateOptions = Object.values(
		TagsService.getTagsByEntity(entity).reduce(function (res, val) {
			if (val.type !== 'Date') {
				return res;
			}
			if (!res[val.entity]) {
				res[val.entity] = {
					name: T('default.' + val.entity),
					children: []
				};
			}
			res[val.entity].children.push(val);
			return res;
		}, {})
	);
	const dateSelectOptions = {
		data: dateOptions,
		id: _.property('tag')
	};
	if (entity === 'activity') {
		delete dateOptions.campaign;
	}
	const classes = new BemClass('TriggerDatePicker');

	if (!showInput && !withToggle) {
		return (
			<div className={classes.b()}>
				<Button
					type="link"
					onClick={() => setShowInput(true)}
					block={true}
					className={classes.elem('trigger').b()}
				>
					<Icon name="calendar" space="mrs" />
					{T('todo.editTodo.setDueDate')}
				</Button>
			</div>
		);
	}

	const content = (
		<div className={classes.elem('content').b()}>
			<Block space="mrxl">
				<Title bold size="sm">
					{isTodo ? T('todo.whenShouldTodo') : T('todo.whenShouldCall')}
				</Title>
				<UpSelect
					options={dateSelectOptions}
					defaultValue={dateTag}
					onChange={({ target: { value: v } }) => {
						formatDateObj({ d: v, o: offset });
					}}
				/>
			</Block>
			<Block>
				<Title bold size="sm">
					{T('todo.offsetInDays')}
				</Title>
				<Input
					placeholder={T('default.addOffset')}
					type="number"
					onChange={({ target: { value: v } }) => formatDateObj({ d: dateTag, o: v })}
					value={offset || undefined}
				/>
			</Block>
			<Block>
				<Title bold size="sm">
					{T('todo.atWhatTime')}
				</Title>
				<TimeInput placeholder={T('default.addTime')} value={time || ''} onChange={onTimeChange} />
			</Block>
		</div>
	);

	if (!withToggle) {
		return (
			<div className={classes.b()}>
				{content}
				<Icon
					name="times-circle"
					color="grey-10"
					onClick={() => {
						onClear();
						setShowInput(false);
					}}
				/>
			</div>
		);
	}

	const handleToggle = value => {
		setShowInput(value);
		if (value === false) {
			onClear();
		} else {
			formatDateObj({ d: 'General.Today', o: '' });
			onTimeChange({ target: { value: '' } });
		}
	};

	return (
		<div className={classes.mod({ showInput, withToggle }).b()}>
			<Block>
				<Toggle
					icon="calendar-plus-o"
					size="lg"
					space="mrm"
					checked={showInput}
					onChange={value => {
						handleToggle(value);
					}}
				/>
				<Text className="clickable" style={{ display: 'inline' }} onClick={() => handleToggle(!showInput)}>
					{T('todo.editTodo.setDueDate')}
				</Text>
			</Block>
			<SlideFade visible={showInput} direction="top">
				{content}
			</SlideFade>
		</div>
	);
};

TriggerDatePicker.propTypes = {
	onTimeChange: PropTypes.func,
	onDateChange: PropTypes.func,
	onClear: PropTypes.func,
	time: PropTypes.string,
	entity: PropTypes.string,
	withToggle: PropTypes.bool
};

export default TriggerDatePicker;
