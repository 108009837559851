import React, { useMemo, useState } from 'react';
import {
	Block,
	Button,
	ColorSwitcher,
	Flex,
	FullscreenModal,
	ModalContent,
	ModalHeader,
	Tab,
	Tabs,
	Text,
	Title,
	Tooltip
} from '@upsales/components';
import { circle } from 'Components/Helpers/styleHelper';
import { ProjectPlanTemplate, TaskTemplate } from 'App/resources/Model/ProjectPlanTemplate';
import BemClass from '@upsales/components/Utils/bemClass';
import EditorHeaderButton from 'Components/EditorHeader/EditorHeaderButton';
import logError from 'Helpers/logError';
import LZString from 'lz-string';
import ModalTagList from 'App/babel/components/Modals/ModalTagList';
import openModal from 'App/services/Modal';
import ProjectPlanTemplateResource from 'Resources/ProjectPlanTemplate';
import ProjectSettings from './ProjectSettings';
import T from 'Components/Helpers/translate';
import TaskSettings from './TaskSettings';

import './EditProjectPlanTemplate.scss';
import { useSoftDeployAccess } from 'App/components/hooks';

type Props = {
	className: string;
	close: (projectPlanTemplate: ProjectPlanTemplate | null) => void;
	onClose: (projectPlanTemplate: ProjectPlanTemplate | null) => void;
	projectPlanTemplate: ProjectPlanTemplate | null;
};

function getNewProjectPlanTemplate() {
	return {
		name: '',
		active: true,
		taskTemplates: [],
		products: [],
		opportunityStageId: null,
		custom: []
	} as unknown as ProjectPlanTemplate;
}

function getProjectPlanTemplateHash(projectPlanTemplate: ProjectPlanTemplate | null) {
	if (projectPlanTemplate === null) {
		return LZString.compressToBase64('null');
	}

	const custom = (projectPlanTemplate.custom ?? [])
		.filter(({ value }) => value !== null)
		.sort((a, b) => a.fieldId - b.fieldId)
		.map(({ fieldId, value }) => `${fieldId}:${value}`);

	const compareObject = {
		...projectPlanTemplate,
		custom
	};

	return LZString.compressToBase64(JSON.stringify(compareObject));
}

const EditProjectPlanTemplate = (props: Props) => {
	const { className, close } = props;

	const classNames = new BemClass('EditProjectPlanTemplate', className);

	const hasSpecificStageFeature = useSoftDeployAccess('START_PROJECT_AT_SPECIFIC_STAGE');

	const lang = useMemo(
		() => ({
			cancel: T('default.cancel'),
			createProjectPlanTemplate: T('admin.projectPlan.edit.projectPlanTemplate'),
			nameProductTab: T('admin.projectPlan.edit.nameAndProductTab'),
			nextStep: T('admin.newSalesProcess.nextStep'),
			pickAProjectName: T('admin.projectTaskTemplate.pickAProjectName'),
			save: T('default.save'),
			tasksTab: T('admin.projectPlan.edit.tasksTab'),
			showAvailableTags: T('admin.documentTemplate.standardTemplatesModal.showTags')
		}),
		[]
	);

	const tabs = [
		{ id: 'projectSettings', lang: lang.nameProductTab },
		{ id: 'taskSettings', lang: lang.tasksTab }
	];

	const [tagListVisible, setTagListVisible] = useState(false);
	const [selectedTab, setSelectedTab] = useState(tabs[0].id);
	const [saving, setSaving] = useState(false);
	const [projectPlanTemplate, setProjectPlanTemplate] = useState(
		props.projectPlanTemplate ? structuredClone(props.projectPlanTemplate) : getNewProjectPlanTemplate()
	);

	function changeTab(selectedTab: string) {
		if (selectedTab === 'taskSettings' && projectPlanTemplate.name?.length === 0) {
			return;
		}
		setTagListVisible(false);
		setSelectedTab(selectedTab);
	}

	async function saveAndClose() {
		if (!saving) {
			try {
				setSaving(true);
				const { data: updastedProjectPlanTemplate } = await ProjectPlanTemplateResource.save(
					projectPlanTemplate
				);
				close(updastedProjectPlanTemplate);
			} catch (error) {
				logError(error, 'Failed to save project plan template');
			}
		}
	}

	async function onNextStep() {
		setTagListVisible(false);
		if (selectedTab === 'taskSettings') {
			await saveAndClose();
		} else {
			setSelectedTab('taskSettings');
		}
	}

	function onOffSetEndDateChange(offsetEndDate: boolean) {
		setProjectPlanTemplate({ ...projectPlanTemplate, offsetEndDate });
	}

	function onActiveChange(active: boolean) {
		setProjectPlanTemplate({ ...projectPlanTemplate, active });
	}

	function onNameChange(name: string) {
		setProjectPlanTemplate({ ...projectPlanTemplate, name: name.trimStart().substring(0, 100) });
	}

	function onCustomChange(custom: any) {
		setProjectPlanTemplate({ ...projectPlanTemplate, custom });
	}

	const onProductsChange = (products: { id: number; name: string; title: string }[]) => {
		setProjectPlanTemplate({ ...projectPlanTemplate, products });
	};

	const onStageChange = (id: number) => {
		setProjectPlanTemplate({ ...projectPlanTemplate, opportunityStageId: id });
	};

	const onTasksChange = (taskTemplates: TaskTemplate[]) => {
		setProjectPlanTemplate({ ...projectPlanTemplate, taskTemplates });
	};

	const onCancel = () => {
		const originalHash = getProjectPlanTemplateHash(props.projectPlanTemplate);
		const updatedHash = getProjectPlanTemplateHash(projectPlanTemplate);
		const isDirty = originalHash !== updatedHash;

		if (isDirty) {
			openModal('UnsavedChangesAlert', {
				disableConfirm: projectPlanTemplate.name.length === 0,
				disableConfirmTooltip: 'admin.newSalesProcess.nameRequiredSaveTooltip',
				onClose: async (confirmed?: boolean) => {
					if (confirmed === undefined) {
						return;
					}
					if (confirmed) {
						await saveAndClose();
					} else {
						close(null);
					}
				}
			});
		} else {
			close(null);
		}
	};

	const content =
		selectedTab === 'taskSettings' ? (
			<TaskSettings
				className={classNames.elem('TaskSettings').b()}
				projectPlanTemplate={projectPlanTemplate}
				onTasksChange={onTasksChange}
				onOffSetEndDateChange={onOffSetEndDateChange}
			/>
		) : (
			<ProjectSettings
				className={classNames.elem('ProjectSettings').b()}
				projectPlanTemplate={projectPlanTemplate}
				onNameChange={onNameChange}
				onProductsChange={onProductsChange}
				onStageChange={onStageChange}
				onActiveChange={onActiveChange}
				onCustomChange={onCustomChange}
			/>
		);

	const title = projectPlanTemplate.name || lang.createProjectPlanTemplate;
	const width = selectedTab === 'taskSettings' ? true : false;
	const nextButtonDisabled =
		projectPlanTemplate.name.length === 0 ||
		(hasSpecificStageFeature && projectPlanTemplate.opportunityStageId === null);

	return (
		<FullscreenModal className={classNames.mod({ width, tagListVisible }).b()} headerAtTop>
			<ModalHeader>
				<Flex alignItems="center">
					<Tooltip position="bottom" distance={20} title={title}>
						<Title space="mrl mll">{title}</Title>
					</Tooltip>
					<Tabs noFlex color="white" onChange={changeTab} selected={selectedTab}>
						{tabs.map((tab, i) => (
							<Tab key={tab.id} id={tab.id}>
								<Flex>
									<ColorSwitcher style={circle()}>{i + 1}</ColorSwitcher>
									<Text space="mlm" color="inherit" bold={selectedTab === tab.id}>
										{tab.lang}
									</Text>
								</Flex>
							</Tab>
						))}
					</Tabs>
				</Flex>
				<Block className={classNames.elem('controls').b()}>
					<EditorHeaderButton
						title={lang.cancel}
						onClick={onCancel}
						supertitle={undefined}
						className={classNames.elem('cancel').b()}
						noIcon
						next={false}
					/>
					<Tooltip title={lang.pickAProjectName} disabled={!!projectPlanTemplate.name}>
						<EditorHeaderButton
							title={selectedTab === 'taskSettings' ? lang.save : lang.tasksTab}
							supertitle={selectedTab === 'taskSettings' ? null : lang.nextStep}
							disabled={nextButtonDisabled}
							onClick={onNextStep}
							next
							className={undefined}
							noIcon={false}
						/>
					</Tooltip>
				</Block>
			</ModalHeader>
			<ModalContent>{content}</ModalContent>
			{selectedTab === 'projectSettings' ? (
				<Button
					className={classNames.elem('ModalTagListButton').b()}
					color="grey-13"
					onClick={() => setTagListVisible(true)}
					text={lang.showAvailableTags}
				/>
			) : null}
			<ModalTagList entity={'projectPlanTemplate'} onClose={() => setTagListVisible(false)} />
		</FullscreenModal>
	);
};

export default EditProjectPlanTemplate;
