import { IconName } from '@upsales/components';
import AlertConfirm from 'Components/Dialogs/AlertConfirm';
import AlertBody from 'Components/Dialogs/Body/AlertBody';
import React from 'react';
import Todo from 'Resources/Todo';
import openModal from 'App/services/Modal';
import { getConfirmationTitle } from 'App/components/Alerts/ConfirmationTexts';

export enum ACTIONS {
	closeActivity = 'closeActivity',
	updateActivity = 'updateActivity',
	updateAppointment = 'updateAppointment',
	deleteAppointment = 'deleteAppointment',
	deleteActivity = 'deleteActivity',
	deleteTasks = 'deleteTasks',
	updateActivityClient = 'updateActivityClient',
	updateAppointmentClient = 'updateAppointmentClient',
	updateActivityContact = 'updateActivityContact',
	updateAppointmentContact = 'updateAppointmentContact'
}

const ACTION_OPTS: { [id in ACTIONS]: { action: string; task: string; icon: IconName } } = {
	[ACTIONS.closeActivity]: {
		action: 'UpdateActivity',
		task: 'Update',
		icon: 'check-square'
	},
	[ACTIONS.updateActivity]: {
		action: 'UpdateActivity',
		task: 'Update',
		icon: 'edit'
	},
	[ACTIONS.updateAppointment]: {
		action: 'UpdateAppointment',
		task: 'Update',
		icon: 'edit'
	},
	[ACTIONS.deleteAppointment]: {
		action: 'DeleteEntity',
		task: 'Delete',
		icon: 'trash'
	},
	[ACTIONS.deleteActivity]: {
		action: 'DeleteEntity',
		task: 'Delete',
		icon: 'trash'
	},
	[ACTIONS.deleteTasks]: {
		action: 'DeleteTasks',
		task: 'Delete',
		icon: 'trash'
	},
	[ACTIONS.updateActivityClient]: {
		action: 'UpdateClient',
		task: 'Update',
		icon: 'home'
	},
	[ACTIONS.updateAppointmentClient]: {
		action: 'UpdateClient',
		task: 'Update',
		icon: 'home'
	},
	[ACTIONS.updateActivityContact]: {
		action: 'UpdateContact',
		task: 'Update',
		icon: 'user'
	},
	[ACTIONS.updateAppointmentContact]: {
		action: 'UpdateContact',
		task: 'Update',
		icon: 'user'
	}
};

type ActionCallback = (properties: object[]) => Promise<void>;

const genericDelete = async (cb: ActionCallback, entity: string, totalSelected: number) => {
	var confirmButtonText = Tools.$translate('admin.modal.deleteEntity');
	const alertConfirmOptions = {
		type: 'confirm',
		reactive: true,
		fullscreen: true,
		hideAbort: false,
		dialog: AlertConfirm,
		id: 'confirm-delete-fields',
		body: React.createElement(AlertBody, {
			numSelected: totalSelected,
			entity
		}),
		confirmButtonText: confirmButtonText,
		confirmClass: 'btn-red',
		confirmationMode: 'text',
		confirmText: Tools.AppService.getSelf().email,
		confirmFn: () => cb([])
	};

	if (Tools.FeatureHelper.hasSoftDeployAccess('REACT_ALERT_MODAL')) {
		return openModal('Alert', {
			...alertConfirmOptions,
			title: getConfirmationTitle(entity, totalSelected),
			onClose: confirmed => {
				if (confirmed) {
					alertConfirmOptions.confirmFn();
				}
			}
		});
	}
	return Tools.$upModal.open('alert', alertConfirmOptions);
};

const runners: { [id in ACTIONS]: (cb: ActionCallback, totalSelected: number) => Promise<void> } = {
	[ACTIONS.closeActivity]: cb => Tools.$upModal.open('closeActivityMulti', { onSave: cb }),
	[ACTIONS.updateActivity]: cb =>
		Tools.$upModal.open('updateActivityMulti', { isTodoList: true, entity: 'Activity', onSave: cb }),
	[ACTIONS.updateAppointment]: cb =>
		Tools.$upModal.open('updateAppointmentMulti', { entity: 'Appointment', onSave: cb }),
	[ACTIONS.deleteAppointment]: (cb, totalSelected) => genericDelete(cb, 'Appointment', totalSelected),
	[ACTIONS.deleteActivity]: (cb, totalSelected) => genericDelete(cb, 'Activity', totalSelected),
	[ACTIONS.deleteTasks]: (cb, totalSelected) => genericDelete(cb, 'Activity', totalSelected),
	[ACTIONS.updateActivityClient]: cb => Tools.$upModal.open('UpdateClientMulti', { onSave: cb }),
	[ACTIONS.updateAppointmentClient]: cb => Tools.$upModal.open('UpdateClientMulti', { onSave: cb }),
	[ACTIONS.updateActivityContact]: cb =>
		Tools.$upModal.open('UpdateContactMulti', { entity: 'Activity', onSave: cb }),
	[ACTIONS.updateAppointmentContact]: cb =>
		Tools.$upModal.open('UpdateContactMulti', { entity: 'Appointment', onSave: cb })
};

type Action = { id: ACTIONS; icon: IconName; title: string };

const getAction = (id: ACTIONS, title: string): Action => {
	return { id, title, icon: ACTION_OPTS[id].icon };
};

const phonecallsActions = [
	getAction(ACTIONS.updateActivity, 'todo.multi.updatePhonecalls'),
	getAction(ACTIONS.deleteActivity, 'todo.multi.removePhonecalls'),
	getAction(ACTIONS.updateActivityClient, 'todo.multi.updatePhonecallClient'),
	getAction(ACTIONS.updateActivityContact, 'todo.multi.updatePhonecallContact')
];
export const actionMap: { [type: string]: Action[] } = {
	todos: [
		getAction(ACTIONS.closeActivity, 'todo.multi.closeTodos'),
		getAction(ACTIONS.updateActivity, 'todo.multi.updateTodos'),
		getAction(ACTIONS.deleteActivity, 'todo.multi.removeTodos'),
		getAction(ACTIONS.updateActivityClient, 'todo.multi.updateTodoClient'),
		getAction(ACTIONS.updateActivityContact, 'todo.multi.updateTodoContact')
	],
	phonecalls: phonecallsActions,
	appointments: [
		getAction(ACTIONS.updateAppointment, 'todo.multi.updateAppointments'),
		getAction(ACTIONS.deleteAppointment, 'default.removeAppointments'),
		getAction(ACTIONS.updateAppointmentClient, 'todo.multi.updateAppointmentClient'),
		getAction(ACTIONS.updateAppointmentContact, 'todo.multi.updateAppointmentContact')
	],
	mixedActivities: [
		getAction(ACTIONS.closeActivity, 'todo.multi.closePhonecallsAndTodos'),
		getAction(ACTIONS.updateActivity, 'todo.multi.updatePhonecallsAndTodos'),
		getAction(ACTIONS.deleteTasks, 'todo.multi.removePhonecallsAndTodos'),
		getAction(ACTIONS.updateActivityClient, 'todo.multi.updatePhonecallAndTodoClient'),
		getAction(ACTIONS.updateActivityContact, 'todo.multi.updatePhonecallAndTodoContact')
	],
	coldcall: phonecallsActions,
	hot: phonecallsActions
};

// If selectedIds is emty then we assume all was selected
export function runAction(id: ACTIONS, filters: object, selectedIds: number[], totalSelected: number): Promise<void> {
	return new Promise((resolve, reject) => {
		if (!runners[id]) {
			return reject(new Error(`Missing todo list action "${id}"`));
		}
		runners[id](
			properties =>
				new Promise((saveResolve, saveReject) => {
					const opts = {
						selectedIds,
						filters,
						properties,
						action: ACTION_OPTS[id].action,
						task: ACTION_OPTS[id].task
					};
					Todo.multi(opts)
						.then(() => {
							resolve();
							saveResolve();
						})
						.catch(() => {
							reject();
							saveReject();
						});
				}),
			totalSelected
		);
	});
}
