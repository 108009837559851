import { SelectTypeWithChildren } from 'App/components/GenericSelectEntityModal/GenericSelectEntityModal';
import Client from 'App/resources/Model/Client';

export const formatWithSubAccounts = (
	accs: Client[] = [],
	translationFn: (langKey: string) => string,
	recent = false
): SelectTypeWithChildren<Client>[] | Client[] => {
	const mainAccounts = [];
	const subAccounts = [];
	const lang = {
		accounts: translationFn(recent ? 'default.latestAccounts' : 'default.accounts'),
		subaccounts: translationFn(recent ? 'default.latestSubaccounts' : 'account.subaccounts')
	};

	for (const acc of accs) {
		if (acc.operationalAccount) {
			subAccounts.push(acc);
		} else {
			mainAccounts.push(acc);
		}
	}
	const formattedResults: SelectTypeWithChildren<Client>[] = [];
	if (mainAccounts.length) {
		formattedResults.push({
			name: lang.accounts,
			title: lang.accounts,
			icon: 'home',
			children: mainAccounts
		});
	}
	if (subAccounts.length) {
		formattedResults.push({
			name: lang.subaccounts,
			title: lang.subaccounts,
			icon: 'subaccounts',
			children: subAccounts
		});
	}
	return formattedResults;
};

export const getRelatedClientIds = (
	object: {
		clientConnection: null | {
			id: number;
		};
		client: null | {
			operationalAccount: null | {
				id: number;
			};
		};
	},
	hasSubAccounts: boolean = true
) => {
	const relatedIds = [];

	if (object?.clientConnection?.id) {
		relatedIds.push(object.clientConnection.id);
	}
	if (hasSubAccounts && object?.client?.operationalAccount?.id) {
		relatedIds.push(object.client.operationalAccount.id);
	}

	return relatedIds;
};

export const binaryGroup = <T extends any>(objects: T[], predicate: (object: T) => boolean) =>
	objects.reduce<[T[], T[]]>(
		(res, object) => {
			const [truthy, falsey] = res;
			if (predicate(object)) {
				truthy.push(object);
			} else {
				falsey.push(object);
			}
			return [truthy, falsey];
		},
		[[], []]
	);
