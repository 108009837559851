import Tracker from './Tracker';

const forecastingTracker = new Tracker('forecasting', {
	RISK_CHIP_HOVERED: 'riskChipHovered',
	FORECAST_OPENED: 'forecastOpened',
	DRILLDOWN_OPENED: 'drilldownOpened',
	OPPORTUNITY_CLICKED: 'opportunityClicked',
	COMPANY_CLICKED: 'companyClicked',
	FILTER_OPENED: 'filterOpened',
	FILTER_SELECTED: 'filterSelected',
	SALES_CHANGE_HOVERED: 'salesChangeHovered',
	SALES_CHANGE_CLICKED: 'salesChangeClicked',
	PIPELINE_CHANGE_HOVERED: 'pipelineChangeHovered',
	PIPELINE_CHANGE_CLICKED: 'pipelineChangeClicked'
});

export default forecastingTracker;
