import ResourceTyped from 'Resources/ResourceTyped';
import CreditRisk from './Model/CreditRisk';

class CreditRiskResource extends ResourceTyped<CreditRisk> {
	constructor() {
		super('creditrisks');
	}
}

const resource = new CreditRiskResource();

export default resource;
