import { ClientColumnSubtitle, ClientColumnTitle } from 'Components/SubaccountDrawer/columnParts';
import { Block, ClickableItem, Flex, Tooltip, Text } from '@upsales/components';
import { CancelablePromise, makeCancelable } from 'Helpers/promise';
import Client, { ClientIdName } from 'App/resources/Model/Client';
import React, { useState, useRef, useEffect } from 'react';
import { renderToString } from 'react-dom/server';
import ClientResource from 'App/resources/Client';
import logError from 'Helpers/logError';

const SubaccountsColumn = ({ client, color, space }: { client: ClientIdName; color?: string; space?: string }) => {
	const { id } = client;

	const clientRequest = useRef<CancelablePromise<Awaited<ReturnType<typeof ClientResource.get>>> | null>(null);
	const [operationalAccount, setOperationalAccount] = useState<Client | null>(null);

	useEffect(() => () => clientRequest.current?.cancel(), []);

	const onClick = (e: React.MouseEvent) => {
		e.stopPropagation();
		Tools.$state.go('account.dashboard', { id });
	};

	const getOperationalAccount = () => {
		if (operationalAccount) return;

		clientRequest.current = makeCancelable(ClientResource.get(id));
		clientRequest.current.promise
			.then(({ data }) => {
				setOperationalAccount(data);
			})
			.catch(e => {
				logError(e, 'Failed to get operational account');
			});
	};

	const TooltipContent = ({ client }: { client: Client | null }) => (
		<Flex direction="column">
			{client ? (
				<>
					<ClientColumnTitle client={client} />
					<ClientColumnSubtitle client={client} />
				</>
			) : (
				<>
					<Text loading />
					<Text loading />
				</>
			)}
		</Flex>
	);

	return (
		<Block onMouseEnter={getOperationalAccount}>
			<Tooltip
				key={operationalAccount?.id ?? 'no-id'}
				title={renderToString(<TooltipContent client={operationalAccount} />)}
				theme="white"
				distance={20}
			>
				<ClickableItem space={space ? space : 'plm ptm prm pbm'} onClick={onClick} icon="subaccounts" />
			</Tooltip>
		</Block>
	);
};

export default SubaccountsColumn;
