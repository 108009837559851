import Tracker from './Tracker';

const CustomerPortfolioTracker = new Tracker('CustomerPortfolio', {
	CLICKED_CUSTOMER_TAB: 'clickedCustomerTab',
	CLICKED_RISK_TAB: 'clickedRiskTab',
	OPENED_RISK_DRAWER: 'openedRiskDrawer',
	SORT_CHANGED: 'sortChanged',
	SALES_MODEL: 'salesModel',
	SWITCHED_SALES_MODEL: 'switchedSalesModel',
	NR_USERS_FILTERED_ON: 'nrUsersFilteredOn',
	SWITCHED_NR_USERS_FILTERED_ON: 'switchedNrUsersFilteredOn',
	SWITCHED_RISK_WIDGET_GROUPING: 'switchedRiskWidgetGrouping'
});

export default CustomerPortfolioTracker;
