import React from 'react';
import { Tooltip, Text, Icon, Block } from '@upsales/components';
import bemClass from '@upsales/components/Utils/bemClass';
import { RiskEvaluatedOpportunity } from 'App/resources/Model/Opportunity';
import { renderToString } from 'react-dom/server';
import T from 'Components/Helpers/translate';
import moment from 'moment';

import './SalesProcessToolTip.scss';

type ChecklistTypes = 'decisionMakers' | 'timeframe' | 'budget' | 'solution' | 'nextStep';

const toolTipRow = (text: string, completed: boolean, key: string) => {
	const iconName = completed ? 'check' : 'times';
	const iconColor = completed ? 'white' : 'red';
	return (
		<Block space="mbs" key={key}>
			<Icon name={iconName} color={iconColor} space="mrm" />
			<Text size="sm" color="white" bold>
				{text}
			</Text>
		</Block>
	);
};

const shouldShowNextStepAsCompleted = (order: RiskEvaluatedOpportunity) => {
	const { evaluatedRisks } = order;
	let hasFutureAppointment = false;
	if (evaluatedRisks?.meeting?.endDate) {
		const appointmentTimestamp = moment(evaluatedRisks.meeting.timestamp);
		hasFutureAppointment = moment(appointmentTimestamp).isAfter(moment());
	}

	const onlyAppointments = order.mappedSalesCoach?.nextStep?.onlyAppointments;
	if (onlyAppointments) {
		return hasFutureAppointment;
	}

	if (Tools.FeatureHelper.hasSoftDeployAccess('TODO_LIST')) {
		return evaluatedRisks.futurePhonecall?.id || hasFutureAppointment;
	} else {
		return evaluatedRisks.activity?.id || hasFutureAppointment;
	}
};

const SalesProcessToolTip = ({ order }: { order: RiskEvaluatedOpportunity }) => {
	const {
		evaluatedRisks: {
			checklist,
			decisionMakersPro,
			notOld,
			confirmedSolution,
			confirmedBudget,
			confirmedDate,
			ceo,
			decisionMakerTitle,
			notPassedDate
		},
		mappedSalesCoach: salesCoach,
		stage
	} = order;

	if (!salesCoach) {
		return null;
	}

	const isTypeActiveInThisStage = (type: ChecklistTypes) => {
		let checklistItem;
		switch (type) {
			case 'decisionMakers': {
				checklistItem = salesCoach.decisionMakers;
				if (checklistItem.titleCategories.length === 0) {
					return false;
				}
				break;
			}
			case 'nextStep':
				return salesCoach.nextStep.active;
			default: {
				checklistItem = salesCoach[type];
			}
		}

		if (checklistItem?.active) {
			if (checklistItem.entireSalesProcess) {
				return true;
			}

			return checklistItem.stages[stage.id]?.active;
		}
		return false;
	};

	const classes = new bemClass('SalesProcessToolTip');
	const checked: JSX.Element[] = [];
	const unchecked: JSX.Element[] = [];
	const hasSalesProcessPro = Tools.FeatureHelper.isAvailable(Tools.FeatureHelper.Feature.SALES_PROCESS_PRO);

	if (notOld != null) {
		const value = !!notOld;
		(value ? checked : unchecked).push(
			toolTipRow(T(`opportunity.salesBoardToolTip.${value ? 'underAvg' : 'notUnderAvg'}`), value, 'notOld')
		);
	}

	if (isTypeActiveInThisStage('solution')) {
		const value = !!confirmedSolution;
		(value ? checked : unchecked).push(
			toolTipRow(
				T(`opportunity.salesBoardToolTip.${value ? 'confirmedSolution' : 'noConfirmedSolution'}`),
				!!value,
				'solution'
			)
		);
	}

	if (isTypeActiveInThisStage('budget')) {
		const value = !!confirmedBudget;
		(value ? checked : unchecked).push(
			toolTipRow(T(`opportunity.salesBoardToolTip.${value ? 'budget' : 'noBudget'}`), value, 'budget')
		);
	}

	if (!notPassedDate && isTypeActiveInThisStage('timeframe')) {
		unchecked.push(toolTipRow(T('opportunity.salesBoardToolTip.closeDatePassed'), false, 'timeframe'));
	} else if (isTypeActiveInThisStage('timeframe')) {
		const value = !!confirmedDate;
		(value ? checked : unchecked).push(
			toolTipRow(T(`opportunity.salesBoardToolTip.${value ? 'timeframe' : 'noTimeframe'}`), value, 'timeframe')
		);
	}

	if (isTypeActiveInThisStage('decisionMakers') && !hasSalesProcessPro) {
		let langTag = 'opportunity.salesBoardToolTip.includeTheTitle';
		if (!decisionMakerTitle) {
			// fallback
			langTag = 'opportunity.salesBoardToolTip.includeTheDecisionMaker';
		}
		const value = !!ceo;
		(value ? checked : unchecked).push(
			toolTipRow(T(langTag, { title: decisionMakerTitle }), !!ceo, 'decisionMakers')
		);
	}

	if (isTypeActiveInThisStage('nextStep')) {
		const value = !!shouldShowNextStepAsCompleted(order);
		(value ? checked : unchecked).push(
			toolTipRow(T(`opportunity.salesBoardToolTip.${value ? 'nextStep' : 'noNextStep'}`), value, 'timeframe')
		);
	}

	if (hasSalesProcessPro) {
		for (const { value } of decisionMakersPro?.includedStakeholders || []) {
			checked.push(
				toolTipRow(
					T('opportunity.salesBoardToolTip.titleIsIncluded', { title: value }),
					true,
					'stakeholder' + value
				)
			);
		}
		for (const { value } of decisionMakersPro?.includedTitles || []) {
			checked.push(
				toolTipRow(T('opportunity.salesBoardToolTip.titleIsIncluded', { title: value }), true, 'title' + value)
			);
		}

		for (const { value } of decisionMakersPro?.excludedStakeholders || []) {
			unchecked.push(
				toolTipRow(
					T('opportunity.salesBoardToolTip.includeTitle', { title: value }),
					false,
					'stakeholder' + value
				)
			);
		}
		for (const { value } of decisionMakersPro?.excludedTitles || []) {
			unchecked.push(
				toolTipRow(T('opportunity.salesBoardToolTip.includeTitle', { title: value }), false, 'title' + value)
			);
		}
	}

	checklist?.forEach(({ checked: itemChecked, title }, i) => {
		(itemChecked ? checked : unchecked).push(toolTipRow(title, itemChecked, `customChecklist${i}`));
	});

	const riskCount = unchecked.length;
	const riskTranslation = `${riskCount} ${T(
		riskCount === 1 ? 'opportunity.salesBoardToolTip.risk' : 'opportunity.salesBoardToolTip.risks'
	)}`;

	const toolTipTitle = (
		<div>
			<Text size="sm" color="white" bold>
				{riskTranslation}
			</Text>
			<div className={classes.elem('checklistItems').b()}>
				{unchecked}
				{checked}
			</div>
		</div>
	);

	return (
		<Tooltip key={'tooltip' + order.id} distance={20} title={renderToString(toolTipTitle)}>
			<div
				className={classes
					.elem('checklist')
					.mod({ allGood: unchecked.length === 0 })
					.b()}
			>
				<Text size="sm">
					{checked.length}/{[...checked, ...unchecked].length}
				</Text>
			</div>
		</Tooltip>
	);
};

export default SalesProcessToolTip;
