import Prospecting from 'App/babel/resources/Prospecting';
import UpsalesAI from 'App/babel/resources/UpsalesAI';
import { getDefaultIndustryFieldFromCountry, StatusCategory } from 'App/services/prospectingService';
import RequestBuilder from 'App/babel/resources/RequestBuilder';

class ProspectingAIBoxLogic {
	static getRequestBuilder = (country, offset, limit, sorting, asc) => {
		const rb = new RequestBuilder();
		rb.limit = limit;
		rb.offset = offset;
		rb.addFilter({ field: 'statusCategory' }, rb.comparisonTypes.Equals, StatusCategory.Active);
		rb.extraParams.push({ key: 'country', value: country });

		if (sorting) {
			rb.addSort(sorting, asc);
		}
		return rb;
	};

	static getNearBySni = (sni, diff) => {
		let upper = (parseInt(sni) + diff).toString();
		if (upper.length === 1) {
			upper = '0' + upper + '000';
		} else {
			upper += '000';
		}
		let lower = sni;
		if (lower.length === 1) {
			lower = '0' + lower + '000';
		} else {
			lower += '000';
		}
		return [lower, upper];
	};

	static clientFieldBy = (country, field) => {
		return UpsalesAI.clientFieldBy(field, country)
			.then(res => {
				return res.data.length
					? res
					: UpsalesAI.clientFieldBy(field, country, { params: { journeyStep: 'customer' } });
			})
			.then(res => res.data);
	};

	static addSniFilter = async (rb, country) => {
		const industryField = getDefaultIndustryFieldFromCountry(country);

		const res = await this.clientFieldBy(country, industryField);
		if (!res.length) {
			return false;
		}

		const sumSales = {};

		for (const company of res) {
			const industry = company[industryField];
			if (!industry) {
				continue;
			}
			const generalSni = industry.slice(0, 2);
			if (sumSales.hasOwnProperty(generalSni)) {
				sumSales[generalSni] += company.totalOrderValue;
			} else {
				sumSales[generalSni] = company.totalOrderValue;
			}
		}
		const orFilter = rb.orBuilder();
		orFilter.next();
		const len = Object.keys(sumSales).length >= 3 ? 3 : Object.keys(sumSales).length;
		for (let i = 0; i < len; i++) {
			const topSni = Object.keys(sumSales).reduce((a, b) => (sumSales[a] > sumSales[b] ? a : b));
			delete sumSales[topSni];
			const [lower, upper] = this.getNearBySni(topSni, 1);
			orFilter.addFilter({ field: industryField }, rb.comparisonTypes.GreaterThanEquals, lower);
			orFilter.addFilter({ field: industryField }, rb.comparisonTypes.LowerThanEquals, upper);
			if (i !== len - 1) {
				orFilter.next();
			}
		}
		orFilter.done();
		return true;
	};

	static avgTurnoverOnCustomers = country => {
		const { AppService, Report, RequestBuilder } = Tools;
		const customerId = AppService.getCustomerId();
		const filter = new RequestBuilder();
		filter.addFilter({ field: 'order.probability' }, filter.comparisonTypes.Equals, 100);
		filter.addFilter({ field: 'address.country' }, filter.comparisonTypes.Equals, country);
		const aggs = filter.aggregationBuilder();
		aggs.addAggregation(filter.aggregationTypes.Stats, 'turnover');
		aggs.done();
		return Report.customer(customerId)
			.setType(Report.type.CLIENT)
			.find(filter.build())
			.then(res => res.data.stats_turnover.avg);
	};

	static avgTurnoverOnJourneyStep = country => {
		const { AppService, Report, RequestBuilder } = Tools;
		const customerId = AppService.getCustomerId();
		const filter = new RequestBuilder();
		filter.addFilter({ field: 'journeyStep' }, filter.comparisonTypes.Equals, 'customer');
		filter.addFilter({ field: 'address.country' }, filter.comparisonTypes.Equals, country);
		const aggs = filter.aggregationBuilder();
		aggs.addAggregation(filter.aggregationTypes.Stats, 'turnover');
		aggs.done();
		return Report.customer(customerId)
			.setType(Report.type.CLIENT)
			.find(filter.build())
			.then(res => res.data.stats_turnover.avg);
	};

	static avgNoEmployeesOnCustomers = country => {
		const { AppService, Report, RequestBuilder } = Tools;
		const customerId = AppService.getCustomerId();
		const filter = new RequestBuilder();
		filter.addFilter({ field: 'order.probability' }, filter.comparisonTypes.Equals, 100);
		filter.addFilter({ field: 'address.country' }, filter.comparisonTypes.Equals, country);
		const aggs = filter.aggregationBuilder();
		aggs.addAggregation(filter.aggregationTypes.Stats, 'noEmployees');
		aggs.done();
		return Report.customer(customerId)
			.setType(Report.type.CLIENT)
			.find(filter.build())
			.then(res => res.data);
	};

	static avgNoEmployeesOnJourneyStep = country => {
		const { AppService, Report } = Tools;
		const customerId = AppService.getCustomerId();
		const filter = new RequestBuilder();
		filter.addFilter({ field: 'journeyStep' }, filter.comparisonTypes.Equals, 'customer');
		filter.addFilter({ field: 'address.country' }, filter.comparisonTypes.Equals, country);
		const aggs = filter.aggregationBuilder();
		aggs.addAggregation(filter.aggregationTypes.Stats, 'noEmployees');
		aggs.done();
		return Report.customer(customerId)
			.setType(Report.type.CLIENT)
			.find(filter.build())
			.then(res => res.data);
	};

	static getNewStartUps = async (country, offset, limit, sorting, asc) => {
		const rb = this.getRequestBuilder(country, offset, limit, sorting, asc);
		if (!(await this.addSniFilter(rb, country))) {
			return { companies: [], total: 0 };
		}
		const datelimit = moment().subtract(2, 'years').format('YYYY-MM-DD');
		rb.addFilter({ field: 'registrationDate' }, rb.comparisonTypes.GreaterThanEquals, datelimit);
		rb.addFilter({ field: 'registrationDate' }, rb.comparisonTypes.NotEquals, null);
		rb.addFilter({ field: 'notInUpsales' }, rb.comparisonTypes.Equals, true);
		rb.addFilter({ field: 'headquarters' }, rb.comparisonTypes.Equals, true);

		const filter = rb.build();
		if (country === 'GB') {
			// Specify the uk_sic_code for better performance, temporary until the logic behind this card is changed
			filter.index = 'uk_sic_code';
		}

		return Prospecting.find(filter).then(res => ({
			companies: res.data,
			total: res.metadata.total
		}));
	};

	static profitableCompanies = async (country, offset, limit, sorting, asc) => {
		const rb = this.getRequestBuilder(country, offset, limit, sorting, asc);
		if (!(await this.addSniFilter(rb, country))) {
			return { companies: [], total: 0 };
		}
		let avg = await this.avgTurnoverOnCustomers(country);
		if (!avg) {
			avg = await this.avgTurnoverOnJourneyStep(country);
		}
		rb.addFilter({ field: 'revenue' }, rb.comparisonTypes.GreaterThan, avg * 0.9);
		rb.addFilter({ field: 'revenue' }, rb.comparisonTypes.LowerThan, avg * 1.2);
		rb.addFilter({ field: 'headquarters' }, rb.comparisonTypes.Equals, true);
		rb.addFilter({ field: 'notInUpsales' }, rb.comparisonTypes.Equals, true);

		const filter = rb.build();
		return Prospecting.find(filter).then(res => ({
			companies: res.data,
			total: res.metadata.total
		}));
	};

	static hiringAtAFastRate = async (country, offset, limit, sorting, asc) => {
		let stats = await this.avgNoEmployeesOnCustomers(country);
		if (!stats.stats_noEmployees.count) {
			stats = await this.avgNoEmployeesOnJourneyStep(country);
		}
		const avg = stats.stats_noEmployees.avg;
		const rb = this.getRequestBuilder(country, offset, limit, sorting, asc);
		if (!(await this.addSniFilter(rb, country))) {
			return { companies: [], total: 0 };
		}
		if (avg > 20) {
			rb.addFilter({ field: 'noEmployeesPercentChange2Year' }, rb.comparisonTypes.GreaterThan, 10);
		} else {
			rb.addFilter({ field: 'noEmployeesPercentChange2Year' }, rb.comparisonTypes.GreaterThan, 30);
		}
		rb.addFilter({ field: 'noEmployees' }, rb.comparisonTypes.GreaterThan, 5);
		rb.addFilter({ field: 'headquarters' }, rb.comparisonTypes.Equals, true);
		rb.addFilter({ field: 'notInUpsales' }, rb.comparisonTypes.Equals, true);

		const filter = rb.build();

		return Prospecting.find(filter).then(res => ({
			companies: res.data,
			total: res.metadata.total
		}));
	};

	static ai = async (country, offset, limit, sorting, asc, params = {}) => {
		const orgNumbers =
			params.orgNumbers ??
			(await this.clientFieldBy(country, 'orgNo').then(res => res.map(item => item.orgNo).slice(0, 15)));

		if (!orgNumbers.length) {
			return { companies: [], total: 0 };
		}

		const sort = sorting ? { a: sorting, s: asc ? 'A' : 'Z' } : undefined;

		return UpsalesAI.find({ country, offset, sort, orgNumbers }).then(res => {
			if (res.data?.length === 0) {
				return { companies: [], total: 0 };
			}
			const [{ data, metadata }] = res.data;
			return {
				companies: data,
				total: metadata.total
			};
		});
	};

	static ceoOnBoard = async (country, offset, limit, sorting, asc) => {
		return Prospecting.ceoOnBoard({ country, offset, limit, sort: { a: sorting, s: asc ? 'A' : 'Z' } }).then(
			({ data, metadata }) => {
				return {
					companies: data,
					total: metadata.total
				};
			}
		);
	};

	static insideCompanyGroups = async (country, offset, limit, sorting, asc) => {
		return Prospecting.insideCompanyGroups({
			country,
			offset,
			limit,
			sort: { a: sorting, s: asc ? 'A' : 'Z' }
		}).then(({ data, metadata }) => {
			return {
				companies: data,
				total: metadata.total
			};
		});
	};
}

export default ProspectingAIBoxLogic;
