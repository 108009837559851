import React from 'react';
import BemClass from '@upsales/components/Utils/bemClass';
import { Card, Text } from '@upsales/components';
import T from 'Components/Helpers/translate';

import './SplitSubscriptionButton.scss';

interface SplitSubscriptionButtonProps {
	onClick: () => void;
	disabled: boolean;
}

const SplitSubscriptionButton: React.FC<SplitSubscriptionButtonProps> = ({ onClick, disabled }) => {
	const className = new BemClass('SplitSubscriptionButton');

	return (
		<Card
			className={className.mod({ disabled }).b()}
			onClick={() => {
				if (!disabled) onClick();
			}}
		>
			<div>
				<Text bold italic={disabled} color={disabled ? 'grey-10' : undefined}>
					{T('subscription.modal.summary.split.splitThesubscription')}
				</Text>
				<Text size="sm" color={disabled ? 'grey-10' : 'grey-11'} italic={disabled}>
					{T(
						disabled
							? 'subscription.modal.summary.split.splitThesubscription.disabled'
							: 'subscription.modal.summary.split.splitThesubscription.subtitle'
					)}
				</Text>
			</div>

			<img src="img/split-icon.svg" />
		</Card>
	);
};

export default SplitSubscriptionButton;
