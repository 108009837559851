import React, { useState, useEffect } from 'react';
import { Fade } from '@upsales/components/animations';

const FadeWrap = ({
	visible,
	unmountOnFade = false,
	children
}: {
	visible: boolean;
	unmountOnFade?: boolean;
	children: React.ReactElement;
}) => {
	const [renderChildren, setRenderChildren] = useState<boolean>(visible);

	useEffect(() => {
		if (visible) {
			setRenderChildren(true);
		} else if (unmountOnFade) {
			const timeoutID = setTimeout(() => {
				setRenderChildren(false);
			}, 310);
			return () => clearTimeout(timeoutID);
		}
	}, [visible]);

	return renderChildren ? <Fade visible={visible}>{children}</Fade> : null;
};

export default FadeWrap;
