import { useState } from 'react';
import useGetData, { GetDataProps } from './useGetData';

// Re-exporting FetcherFunction for convenience
export type { FetcherFunction } from './useGetData';

type FetchOnChanges = {
	[key: string]: string[];
};

type PartialLoaderProps<FetcherProps, ResType extends {}> = {
	batchSize?: number;
	fetchOnChanges?: FetchOnChanges;
} & Pick<GetDataProps<FetcherProps, ResType>, 'fetcher' | 'fetcherProps' | 'broadcastTypes'>;

const usePartialLoader = <FetcherProps, ResType extends {}>({
	fetcher,
	fetcherProps,
	broadcastTypes = [],
	batchSize = 25,
	fetchOnChanges = {}
}: PartialLoaderProps<FetcherProps, ResType>) => {
	const [data, setData] = useState<ResType[]>([]);
	const [offset, setOffset] = useState(0);

	const broadcastFn: GetDataProps<unknown, ResType>['onBroadcast'] = ({
		eventName,
		refetch,
		changedAttributeKeys
	}) => {
		const shouldUpdateHistory =
			!changedAttributeKeys ||
			!fetchOnChanges[eventName]?.length ||
			changedAttributeKeys.some(change => fetchOnChanges[eventName].includes(change));

		if (!shouldUpdateHistory) {
			return;
		}

		setOffset(0);
		refetch();
	};

	const {
		refetch,
		loading,
		metadata: { total }
	} = useGetData({
		fetcher,
		fetcherProps,
		broadcastTypes,
		limit: batchSize,
		offset,
		onBroadcast: broadcastFn,
		onFetched: ({ data: fetchedData }) => {
			if (offset === 0) {
				setData(() => []);
			}
			setData(oldData => oldData.concat(fetchedData));
		}
	});

	const fetchData = (newOffset: number) => {
		setOffset(newOffset);
		refetch();
	};

	const loadMore = () => {
		if (loading) {
			return;
		}
		fetchData(offset + batchSize);
	};

	const reset = () => {
		setData(() => []);
		fetchData(0);
	};

	return {
		data,
		total,
		loading,
		loadMore,
		reset
	} as const;
};

export default usePartialLoader;
