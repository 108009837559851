import { Block, Row, TableRow, Text, Column, Table, TableColumn, TableHeader, Title } from '@upsales/components';
import DrawerHeader from '@upsales/components/DrawerHeader';
import BemClass from '@upsales/components/Utils/bemClass';
import React, { useEffect, useState } from 'react';
import ClientResource from 'App/resources/Client';
import './VisitDrawer.scss';
import { dateCalendar } from 'App/helpers/DateHelpers';
import T from 'Components/Helpers/translate';
import { getDurationFromSeconds } from 'App/components/ListView/ListViewRenderHelpers';
import { formatURL } from 'App/helpers/linkFormatHelper';

type Page = {
	durationSeconds: number;
	page: string;
	url: string;
	score: number;
	id: number;
};
type Props = {
	item: any;
	className: string;
	close: () => void;
};

const VisitDrawer = (props: Props) => {
	const item = props.item;
	const [totalScore, setTotalScore] = useState(0);
	const getTotalScore = async (id: number) => {
		const res = await ClientResource.get(id);
		setTotalScore(res.data.score);
	};

	const classes = new BemClass('VisitDrawer', props.className);
	const client = item.client ?? { name: T('default.undidentifiedVisit') };
	useEffect(() => {
		getTotalScore(client.id);
	}, []);

	const clientName = client.name;
	const visitScore = item.score;
	const date = dateCalendar(item.startDate, true).toString();

	const pages = item.pages;

	const renderSummary = () => {
		return (
			<Row className={classes.elem('summary').b()}>
				<Column>
					<Title>
						{visitScore}
						{T('point')}
					</Title>
					<Text color="grey-11">{T('visitorDrawer.visitScore')}</Text>
				</Column>
				<Column>
					<Title>
						{totalScore}
						{T('point')}
					</Title>
					<Text color="grey-11">{T('visitorDrawer.totalScore')}</Text>
				</Column>
			</Row>
		);
	};

	const renderPages = () => {
		return (
			<div className={classes.elem('pages').b()}>
				<Title>{T('visitorDrawer.pagesVisited')}</Title>
				<Table>
					<TableHeader>
						<TableColumn>{T('default.time')}</TableColumn>
						<TableColumn>{T('visitor.pageTitle')}</TableColumn>
						<TableColumn>{T('visitor.pageUrl').toUpperCase()}</TableColumn>
						<TableColumn className={classes.elem('lastColumn').b()} align="right">
							{T('score')}
						</TableColumn>
					</TableHeader>
					{pages.map((page: Page, i: number) => {
						return (
							<TableRow key={i}>
								<TableColumn>{getDurationFromSeconds(page.durationSeconds)}</TableColumn>
								<TableColumn>{page.page}</TableColumn>
								<TableColumn>
									<a href={formatURL(page.url)} target="_blank">
										{page.url}
									</a>
								</TableColumn>
								<TableColumn className={classes.elem('lastColumn').b()}>
									{page.score}
									{T('point')}
								</TableColumn>
							</TableRow>
						);
					})}
				</Table>
			</div>
		);
	};

	return (
		<div className={classes.b()}>
			<div className={classes.elem('wrapper').b()}>
				<DrawerHeader title={clientName} subtitle={date} onHide={props.close}></DrawerHeader>

				<Block className={classes.elem('drawerContent').b()}>
					{renderSummary()}
					{renderPages()}
				</Block>
			</div>
		</div>
	);
};

export default VisitDrawer;
