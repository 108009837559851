import Prospecting from 'App/babel/resources/Prospecting';
import logError from 'App/babel/helpers/logError';
import RequestBuilder from 'Resources/RequestBuilder';
import { RESET } from './ProspectingShared';
import { reset as resetManageSelection } from './ProspectingManageSelection';
import { Boxes, AIBoxesIdMap, ExisitingBoxes, getAvailableAIBoxesForCountry } from 'Components/Prospecting/enumBoxes';
import { getAvailableCountries } from 'App/services/prospectingService';

const SET_STATE = '[Prospecting] Set state';

export const Tabs = {
	Existing: 'Existing',
	AI: 'AI',
	Manually: 'Manually'
};

export const boxToTabMap = {
	[Boxes.CEOOnBoard]: Tabs.AI,
	[Boxes.InsideCompanies]: Tabs.AI
};

export const initialState = {
	shouldInit: true,
	selectedTab: Tabs.Existing,
	selectedDrawer: null,
	selectedDrawerData: null,
	shouldShowSelectedTooltip: true,
	[Tabs.Existing]: {
		boxes: [],
		loading: true
	},
	[Tabs.AI]: {
		boxes: {}
	},
	availableCountries: [],
	currentCountry: 'SE'
};

export const subInitialState = {
	shouldInit: true,
	shouldShowSelectedTooltip: true,
	[Tabs.Existing]: {
		boxes: [],
		loading: true
	},
	[Tabs.AI]: {
		boxes: []
	},
	availableCountries: [],
	currentCountry: 'SE'
};

/*********** Actions **********/
export const setShouldInit = shouldInit => {
	return { type: SET_STATE, shouldInit };
};

export const setShouldShowSelectedTooltip = shouldShowSelectedTooltip => {
	return { type: SET_STATE, shouldShowSelectedTooltip };
};

let scrollLock = null;

export const onTabChanged =
	(selectedTab, scroll = true) =>
	dispatch => {
		if (scrollLock === null) {
			const element = document.querySelector(`.Prospecting__section--${selectedTab.toLowerCase()}`);
			if (element && scroll) {
				scrollLock = setTimeout(() => (scrollLock = null), 350);
				const y =
					element.getBoundingClientRect().top +
					window.pageYOffset -
					(Tools.FeatureHelper.hasSoftDeployAccess('REMOVE_FRAME') ? 135 : 150);
				window.scrollTo({ top: y, behavior: 'smooth' });
			}
			dispatch({ type: SET_STATE, selectedTab });
		}
	};

export const onDrawerChanged =
	(selectedDrawer, selectedDrawerData = null, country = null) =>
	dispatch => {
		dispatch({ type: RESET });
		dispatch(resetManageSelection());
		const newState = { type: SET_STATE, selectedDrawer, selectedDrawerData, shouldShowSelectedTooltip: true };
		if (country) {
			newState.currentCountry = country;
		}
		dispatch(newState);
	};

export const loadExistingBoxes = () => async (dispatch, getState) => {
	const { ...tabState } = getState().Prospecting[Tabs.Existing];
	try {
		const rb = new RequestBuilder();
		const { data } = await Prospecting.getSuggestionBoxes(rb.build());
		const idMap = data.reduce((res, box) => ((res[box.id] = box), res), {});
		const newBoxes = tabState.boxes.map(box => ({
			...box,
			count: idMap[box.name] ? idMap[box.name].count : 0
		}));
		dispatch({ type: SET_STATE, [Tabs.Existing]: { ...tabState, boxes: newBoxes, loading: false } });
	} catch (error) {
		logError(error);
		dispatch({ type: SET_STATE, [Tabs.Existing]: { ...tabState, loading: false } });
	}
};

export const loadAIBoxes = () => async (dispatch, getState) => {
	const hasPro = Tools.FeatureHelper.isAvailable(Tools.FeatureHelper.Feature.PROSPECTING_PRO);

	if (!hasPro) {
		return;
	}

	const {
		Prospecting: {
			currentCountry: currentCountryAtRequestStart,
			[Tabs.AI]: { boxes }
		}
	} = getState();

	const updatedBoxes = Object.entries(boxes).reduce((res, [boxId, box]) => {
		res[boxId] = { ...box, loading: true };
		return res;
	}, {});

	dispatch({ type: SET_STATE, [Tabs.AI]: { boxes: updatedBoxes } });

	const setData = (boxId, data) => {
		const {
			[Tabs.AI]: { boxes },
			currentCountry: currentCountryAtRequestFinish
		} = getState().Prospecting;

		if (currentCountryAtRequestFinish === currentCountryAtRequestStart) {
			const box = boxes[boxId];
			const updatedBox = { ...box, ...data };
			dispatch({ type: SET_STATE, [Tabs.AI]: { boxes: { ...boxes, [boxId]: updatedBox } } });
		}
	};

	for (const box of Object.values(boxes)) {
		const boxConfig = AIBoxesIdMap[box.id];
		boxConfig
			.run(currentCountryAtRequestStart, 0, 50, boxConfig.defaultSort.sorting, boxConfig.defaultSort.asc)
			.then(data => setData(box.id, { data, loading: false }))
			.catch(err => {
				setData(box.id, { data: { companies: [] }, loading: false });
				if (!err?.isCanceled && !err?.isDefaultPrevented) {
					logError(err, `Failed to load AI data for ${box.id}`);
				}
			});
	}
};

export const setCurrentCountry = currentCountry => async dispatch => {
	const availableAIBoxes = getAvailableAIBoxesForCountry(currentCountry).reduce((availableAIBoxes, { id }) => {
		availableAIBoxes[id] = { id, loading: true, data: {} };
		return availableAIBoxes;
	}, {});

	dispatch({ type: SET_STATE, currentCountry, [Tabs.AI]: { boxes: availableAIBoxes } });
	dispatch(loadAIBoxes());
};

export const init = openDrawerOnLoad => (dispatch, getState) => {
	const { shouldInit } = getState().Prospecting;
	const availableCountries = getAvailableCountries();
	const currentCountry = availableCountries[0] ?? 'SE'; // It had SE as default before, do not know what happens if you have access to no country so lets continue default to that
	const availableExistingBoxes = ExisitingBoxes.filter(box => box.hasAccess()).map(({ id, name, img }) => ({
		id,
		name,
		count: 0,
		img
	}));
	const availableAIBoxes = getAvailableAIBoxesForCountry(currentCountry).reduce((availableAIBoxes, { id, name }) => {
		availableAIBoxes[id] = { id, name, loading: true, data: {} };
		return availableAIBoxes;
	}, {});

	if (shouldInit) {
		dispatch({
			type: SET_STATE,
			...initialState,
			availableCountries,
			currentCountry,
			[Tabs.Existing]: { boxes: availableExistingBoxes, loading: true },
			[Tabs.AI]: { boxes: availableAIBoxes }
		});
	} else {
		dispatch({
			type: SET_STATE,
			...subInitialState,
			availableCountries,
			currentCountry,
			[Tabs.Existing]: { boxes: availableExistingBoxes, loading: true },
			[Tabs.AI]: { boxes: availableAIBoxes }
		});
	}

	dispatch(loadExistingBoxes());
	dispatch(loadAIBoxes());

	if (openDrawerOnLoad && boxToTabMap[openDrawerOnLoad]) {
		dispatch(
			onDrawerChanged(boxToTabMap[openDrawerOnLoad], {
				type: openDrawerOnLoad,
				fetchOnOpen: true
			})
		);
	}
};

const ACTION_HANDLERS = {
	// eslint-disable-next-line no-unused-vars
	[SET_STATE]: (state, { type, ...rest }) => ({ ...state, ...rest })
};

export default (state = initialState, action) => {
	const handler = ACTION_HANDLERS[action.type];
	return handler ? handler(state, action) : state;
};
