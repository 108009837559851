import Attribute from './Attribute';

const MailSignatureAttributes = {
	id: new Attribute({
		type: Attribute.types.Number,
		field: 'id'
	}),
	name: new Attribute({
		title: 'default.name',
		type: Attribute.types.String,
		field: 'name'
	}),
	active: new Attribute({
		title: 'default.status',
		type: Attribute.types.Boolean,
		field: 'active'
	}),
	standard: new Attribute({
		title: 'default.default',
		type: Attribute.types.Boolean,
		field: 'standard'
	}),
	body: new Attribute({
		title: '',
		type: Attribute.types.String,
		field: 'body'
	}),
	startDate: new Attribute({
		title: 'default.startDate',
		type: Attribute.types.Date,
		field: 'startDate'
	}),
	endDate: new Attribute({
		title: 'default.endDate',
		type: Attribute.types.Date,
		field: 'endDate'
	}),
	users: {
		field: 'users',
		type: Attribute.types.Array
	},
	roles: {
		field: 'roles',
		type: Attribute.types.Array
	}
};

window.MailSignatureAttributes = MailSignatureAttributes;
export default MailSignatureAttributes;
