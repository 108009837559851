import React from 'react';
import { Avatar, Tooltip, Text } from '@upsales/components';

export default function AccountManagerColumn({ account, field }) {
	if (!account.users) {
		return null;
	}

	const showName = account.users.length === 1 && !field.iconOnly;

	return (
		<td className="DataSourceColumn accountManager">
			{account.users.map(user => {
				return (
					<React.Fragment key={user.name}>
						<Tooltip title={user.name} position="top" disabled={showName}>
							<Avatar size="sm" initials={user.name} email={user.email} />
							{showName ? <Text>{user.name}</Text> : null}
						</Tooltip>
					</React.Fragment>
				);
			})}
		</td>
	);
}
