import PropTypes from 'prop-types';
import Contact from './Contact';

const user = PropTypes.shape({
	id: PropTypes.number,
	name: PropTypes.string,
	email: PropTypes.string
});

const GenericEventShape = {
	id: PropTypes.number,
	entityId: PropTypes.number,
	date: PropTypes.instanceOf(Date),
	users: PropTypes.arrayOf(user),
	entityType: PropTypes.string.isRequired,
	subType: PropTypes.string,
	score: PropTypes.number,
	client: PropTypes.shape({
		id: PropTypes.number,
		name: PropTypes.string
	}),
	value: PropTypes.string
};

export const ActivityEvent = PropTypes.shape({
	...GenericEventShape,
	activity: PropTypes.shape({
		activityType: PropTypes.shape({
			id: PropTypes.number,
			name: PropTypes.string
		}),
		description: PropTypes.string,
		id: PropTypes.number
	})
});

export const VisitEvent = PropTypes.shape({
	...GenericEventShape,
	contacts: PropTypes.arrayOf(Contact),
	visit: PropTypes.shape({
		id: PropTypes.number,
		leadSource: PropTypes.any,
		pages: PropTypes.arrayOf(
			PropTypes.shape({
				durationSeconds: PropTypes.number,
				endDate: PropTypes.instanceOf(Date),
				page: PropTypes.string,
				score: PropTypes.number,
				startDate: PropTypes.instanceOf(Date),
				url: PropTypes.string
			})
		),
		referer: PropTypes.string
	})
});

export const MailCampaignEvent = PropTypes.shape({
	...GenericEventShape,
	contacts: PropTypes.arrayOf(Contact),
	mails: PropTypes.arrayOf(
		PropTypes.shape({
			id: PropTypes.number,
			contacts: PropTypes.arrayOf(Contact),
			date: PropTypes.string,
			groupMailId: PropTypes.number,
			lastClickDate: PropTypes.string,
			lastReadDate: PropTypes.string,
			from: PropTypes.string,
			fromName: PropTypes.string
		})
	)
});

export const MarketingCustomEvent = PropTypes.shape({
	...GenericEventShape
});

export const EsignEvent = PropTypes.shape({
	...GenericEventShape,
	esign: PropTypes.shape({
		user: PropTypes.shape(user)
	})
});

export const SocialEventEvent = PropTypes.shape({
	...GenericEventShape,
	contacts: PropTypes.arrayOf(
		PropTypes.shape({
			id: PropTypes.number,
			name: PropTypes.string,
			status: PropTypes.string
		})
	),
	socialEvent: PropTypes.shape({
		id: PropTypes.number,
		name: PropTypes.string
	})
});

export const MailEvent = PropTypes.shape({
	...GenericEventShape,
	contacts: PropTypes.arrayOf(Contact),
	mail: PropTypes.shape({
		from: PropTypes.string,
		fromName: PropTypes.string,
		groupMailId: PropTypes.number,
		id: PropTypes.number,
		lastReadDate: PropTypes.instanceOf(Date),
		lastClickDate: PropTypes.instanceOf(Date),
		subject: PropTypes.string
	}),
	value: PropTypes.string
});
export const AppointmentEvent = PropTypes.shape({
	...GenericEventShape,
	appointment: PropTypes.shape({
		activityType: PropTypes.shape({ id: PropTypes.number, name: PropTypes.string }),
		description: PropTypes.string
	}),
	contacts: PropTypes.arrayOf(Contact),
	opportunity: PropTypes.shape({
		description: PropTypes.string,
		id: PropTypes.number,
		user: PropTypes.instanceOf(user)
	})
});
export const OpportunityEvent = PropTypes.shape({
	...GenericEventShape,
	contacts: PropTypes.arrayOf(Contact),
	opportunity: PropTypes.shape({
		description: PropTypes.string,
		id: PropTypes.number,
		user: PropTypes.instanceOf(user),
		orderValue: PropTypes.number
	})
});

export const LeadEvent = PropTypes.shape({
	...GenericEventShape,
	contacts: PropTypes.arrayOf(Contact)
});

export const FormEvent = PropTypes.shape({
	...GenericEventShape,
	contacts: PropTypes.arrayOf(Contact),
	form: PropTypes.shape({
		id: PropTypes.number,
		name: PropTypes.string,
		title: PropTypes.string
	}),
	visit: PropTypes.object
});

export const ManualEvent = PropTypes.shape({
	...GenericEventShape,
	contacts: PropTypes.arrayOf(Contact)
});

export default GenericEventShape;
