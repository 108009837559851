import React from 'react';
import PropTypes from 'prop-types';
import {
	Icon,
	Card,
	Title,
	Text,
	Row,
	Column,
	FullscreenModal,
	ModalContent,
	ModalHeader,
	ModalControls
} from '@upsales/components';
import { ModalHeader as OldModalHeader } from './EditSocialEventModal';
import BemClass from '@upsales/components/Utils/bemClass';
import { mailTemplateTracker } from 'App/babel/helpers/Tracker';

import './EditSocialEventAddResource.scss';

const propTypes = {
	resolve: PropTypes.func,
	cards: PropTypes.array.isRequired,
	title: PropTypes.string.isRequired,
	body: PropTypes.string.isRequired,
	disabledResources: PropTypes.object.isRequired,
	className: PropTypes.string,
	close: PropTypes.func
};

const defaultProps = {
	cards: [],
	resolve: () => {}
};

class EditSocialEventAddResource extends React.Component {
	renderCard(card) {
		const t = Tools.$translate;
		const { title, body, icon, type } = card;
		const disabled = this.props.disabledResources[type].disabled;
		const disabledReason = this.props.disabledResources[type].reason;
		const color = disabled ? 'grey-10' : null;
		return (
			<Card
				key={'resource-card-' + type}
				className="EditSocialEventAddResource--Card"
				color={
					disabled
						? disabledReason === 'socialEvent.disabledReason.templateAlreadyUsed'
							? 'success-1'
							: 'grey-3'
						: 'white'
				}
				onClick={() => {
					if (!disabled) {
						if (Tools.FeatureHelper.hasSoftDeployAccess('REACT_ALERT_MODAL')) {
							mailTemplateTracker.track(mailTemplateTracker.events.CREATED);
							this.props.close(card);
						} else {
							this.props.resolve(card);
						}
					}
				}}
			>
				<Row>
					<Column>
						{disabled ? (
							<div className="EditSocialEventAddResource--Card-check">
								<Icon
									color="white"
									name={
										disabledReason === 'socialEvent.disabledReason.templateAlreadyUsed'
											? 'check'
											: 'times'
									}
								/>
							</div>
						) : null}
						<img className="EditSocialEventAddResource--Card-Icon" src={icon} />
						<Title color={color} center className="EditSocialEventAddResource--Title">
							{t(title)}
						</Title>
						<Text center color="grey-10" className="EditSocialEventAddResource--Text">
							{t(body)}
						</Text>
						{disabled ? (
							<Text center color="grey-10" size="sm" className="EditSocialEventAddResource--Text">
								{t(disabledReason)}
							</Text>
						) : null}
					</Column>
				</Row>
			</Card>
		);
	}

	render() {
		const { title, body, cards, className, close } = this.props;

		const filteredCards = cards.filter(
			card => !(card.type === 'onDemandMail' && !Tools.FeatureHelper.hasSoftDeployAccess('EVENT_ON_DEMAND'))
		);

		if (Tools.FeatureHelper.hasSoftDeployAccess('REACT_ALERT_MODAL')) {
			const classes = new BemClass('EditSocialEventAddResource', className);
			return (
				<FullscreenModal className={classes.b()}>
					<ModalHeader title={title} fullScreen onClose={() => close(false)} alignText="center" />
					<ModalContent fullScreen>
						<Text color="grey-11" style={{ marginTop: '15px' }} center>
							{body}
						</Text>
						<div className="EditSocialEventAddResource--container">
							<div className="EditSocialEventAddResource--Card-wrapper">
								{filteredCards.map(c => this.renderCard(c))}
							</div>
						</div>
					</ModalContent>
					<ModalControls fullScreen />
				</FullscreenModal>
			);
		}

		return (
			<div className="EditSocialEventAddResource">
				<OldModalHeader title={title} body={body} />
				<div className="EditSocialEventAddResource--container">
					<div className="EditSocialEventAddResource--Card-wrapper">
						{filteredCards.map(c => this.renderCard(c))}
					</div>
				</div>
			</div>
		);
	}
}

EditSocialEventAddResource.propTypes = propTypes;
EditSocialEventAddResource.defaultProps = defaultProps;
export default EditSocialEventAddResource;
