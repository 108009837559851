import { Block, ButtonSelect, Column, DrawerHeader, Paginator } from '@upsales/components';
import RequestBuilder, { comparisonTypes } from 'Resources/RequestBuilder';
import { CancelablePromise, makeCancelable } from 'Helpers/promise';
import { useTranslation } from 'Components/Helpers/translate';
import BemClass from '@upsales/components/Utils/bemClass';
import React, { useEffect, useRef, useState } from 'react';
import ViewTicketsTable from './ViewTicketsTable';
import TicketResource from 'App/resources/Ticket';
import Drawer from '@upsales/components/Drawer';
import Ticket from 'App/resources/Model/Ticket';
import { ModalProps } from '../Modals/Modals';
import logError from 'Helpers/logError';
import './ViewTickets.scss';

const LIMIT = 50;

type Props = ModalProps & {
	openStatus: boolean;
	clientId: number;
};

const ViewTickets = ({ close, className, openStatus, clientId }: Props) => {
	const getClosedPromise = useRef<CancelablePromise<Awaited<ReturnType<typeof TicketResource.find>>> | null>(null);
	const getOpenPromise = useRef<CancelablePromise<Awaited<ReturnType<typeof TicketResource.find>>> | null>(null);
	const [selectedOpenStatus, setSelectedOpenStatus] = useState<boolean>(openStatus ? true : false);
	const [closedTickets, setClosedTickets] = useState<Ticket[]>([]);
	const [openTickets, setOpenTickets] = useState<Ticket[]>([]);
	const [closedTotal, setClosedTotal] = useState<number>(0);
	const [openTotal, setOpenTotal] = useState<number>(0);
	const [offset, setOffset] = useState<number>(0);
	const total = selectedOpenStatus ? openTotal : closedTotal;
	const classes = new BemClass('ViewTickets', className);
	const { t } = useTranslation();

	const selectedTicketStatus = () => {
		if (selectedOpenStatus) {
			return openTickets;
		} else {
			return closedTickets;
		}
	};

	const getRb = (closed: boolean) => {
		const rb = new RequestBuilder();

		rb.addFilter({ field: 'status.closed' }, comparisonTypes.Equals, closed);
		rb.addFilter({ field: 'client.id' }, comparisonTypes.Equals, clientId);
		rb.addFilter({ field: 'isArchived' }, comparisonTypes.Equals, false);
		rb.addSort({ field: 'resolveDate' }, false);
		rb.addSort({ field: 'regDate' }, false);

		rb.offset = offset;
		rb.limit = LIMIT;
		return rb;
	};

	useEffect(() => {
		const getData = () => {
			getClosedPromise.current = makeCancelable(TicketResource.find(getRb(true).build()));
			getClosedPromise.current.promise
				.then(res => {
					setClosedTickets(res.data);
					setClosedTotal(res.metadata.total);
				})
				.catch(e => logError(e));

			getOpenPromise.current = makeCancelable(TicketResource.find(getRb(false).build()));
			getOpenPromise.current.promise
				.then(res => {
					setOpenTickets(res.data);
					setOpenTotal(res.metadata.total);
				})
				.catch(e => logError(e));
		};

		getData();

		const listeners = [
			Tools.$rootScope.$on('ticket.created', getData),
			Tools.$rootScope.$on('ticket.updated', getData),
			Tools.$rootScope.$on('ticket.deleted', getData),
			Tools.$rootScope.$on('ticket.statusChanged', getData)
		];
		return () => {
			getClosedPromise.current?.cancel();
			getOpenPromise.current?.cancel();
			listeners.forEach(listener => listener());
		};
	}, [clientId, offset]);

	return (
		<Drawer className={classes.b()}>
			<DrawerHeader
				onHide={close}
				title={selectedOpenStatus ? t('ticket.widget.openTickets') : t('ticket.widget.closedTickets')}
				subtitle={
					selectedOpenStatus
						? t('ticket.widget.subtitleOpen', { amount: openTickets?.length })
						: t('ticket.widget.subtitleClosed', { amount: closedTickets?.length })
				}
			>
				<Column>
					<ButtonSelect
						className={classes.elem('statusButton').b()}
						options={[
							{
								title: `${openTickets?.length} ${t('ticket.widget.openTickets')}`,
								value: 'open'
							},
							{
								title: `${closedTickets?.length} ${t('ticket.widget.closedTickets')}`,
								value: 'closed'
							}
						]}
						value={selectedOpenStatus ? 'open' : 'closed'}
						onChange={value => {
							setSelectedOpenStatus(value === 'open');
						}}
						block
					/>
				</Column>
			</DrawerHeader>
			<ViewTicketsTable tickets={selectedTicketStatus()} selectedOpenStatus={selectedOpenStatus} />
			<Block space="mtl mbxl">
				{total > LIMIT ? (
					<Paginator
						limit={LIMIT}
						offset={offset}
						total={total}
						align="center"
						onChange={val => setOffset(val)}
					/>
				) : null}
			</Block>
		</Drawer>
	);
};

export default ViewTickets;
