import React from 'react';
import { useOrderRowsSelector } from 'App/components/OrderRows/Context/OrderContext';
import { Text, Block, Title, Row } from '@upsales/components';
import { useTranslation } from 'Components/Helpers/translate';
import { getOrderSummary, DisplaySettings } from './helpers';

type SidebarSummarySectionProps = { title: string; rows: { title: string; value: string }[] };
const SidebarSummarySection = ({ title, rows }: SidebarSummarySectionProps) => {
	return (
		<Block>
			<Title space="mbl">{title}</Title>
			{rows.map((row, index, { length }) => {
				const isLastRow = index === length - 1;

				return (
					<Row key={row.title} align="space-between">
						<Text size={isLastRow ? 'md' : 'sm'} bold={isLastRow}>
							{row.title}
						</Text>
						<Text size={isLastRow ? 'md' : 'sm'} bold={isLastRow}>
							{row.value}
						</Text>
					</Row>
				);
			})}
		</Block>
	);
};

type OrderSummaryProps = { displaySettings: DisplaySettings; currency: string };
export const OrderSummary = ({ displaySettings, currency }: OrderSummaryProps) => {
	const { t } = useTranslation();
	const data = useOrderRowsSelector(s => ({
		totalAmount: s.totalAmount,
		totalDiscount: s.totalDiscount,
		totalRecurringValue: s.totalRecurringValue,
		totalContributionMargin: s.totalContributionMargin
	}));
	const rows = getOrderSummary(displaySettings, currency, data);

	return <SidebarSummarySection title={t('default.theOpportunity')} rows={rows} />;
};

type BundleSummaryProps = { displaySettings: DisplaySettings; currency: string };
export const BundleSummary = ({ displaySettings, currency }: BundleSummaryProps) => {
	const { t } = useTranslation();
	const data = useOrderRowsSelector(s => ({
		totalAmount: s.totalAmount,
		totalDiscount: s.totalDiscount,
		totalRecurringValue: s.totalRecurringValue,
		totalContributionMargin: s.totalContributionMargin
	}));

	const rows = getOrderSummary(displaySettings, currency, data, true);

	return <SidebarSummarySection title={t('bundle.sum')} rows={rows} />;
};
