import React, { useEffect, useState } from 'react';
import BemClass from '@upsales/components/Utils/bemClass';
import { totalContributionMarginPercentage } from '../../ContributionMargin';
import { Text, Icon, NumberInput } from '@upsales/components';
import T from 'Components/Helpers/translate';
import { currencyFormat } from 'Components/Filters/Currencies';
import { OrderRow, useOrderRowsDispatch } from '../../Context/OrderContext';
import Product from 'App/resources/Model/Product';

import './CMOrderRowSummary.scss';
import { useVisibleRowField } from 'App/components/hooks/editListener';
import { useSelector } from 'App/components/hooks';
import { setPurchaseCost } from '../../Context/OrderContextHelpers';
import { NewSubscriptionTracker } from 'Helpers/Tracker';

type Props = {
	uuid: number;
	total: number;
	currency: string;
	disabled?: boolean;
	product?: Product;
	orderRow: OrderRow;
	purchaseCost: number;
	recurringValue: number;
	alsoDisplayRR: boolean;
	totalPurchaseCost: number;
	rrSalesModelOption: string;
	tracker?: typeof NewSubscriptionTracker;
};

const CMOrderRowSummary = ({
	uuid,
	total,
	tracker,
	currency,
	disabled,
	product,
	orderRow,
	purchaseCost,
	alsoDisplayRR,
	recurringValue,
	totalPurchaseCost,
	rrSalesModelOption
}: Props) => {
	const classes = new BemClass('CMOrderRowSummary');
	const decimals = useSelector(state => state.App.metadata?.params?.OrderedProductPriceDecimals);

	const isVisibleRowField = useVisibleRowField('order', true);
	const dispatch = useOrderRowsDispatch();

	const [editPurchaseCost, setEditPurchaseCost] = useState(false);
	const [cost, setCost] = useState(purchaseCost);
	useEffect(() => {
		setCost(purchaseCost);
	}, [product?.id]);

	const cm = total - totalPurchaseCost;
	return (
		<div className={classes.b()}>
			{isVisibleRowField('price', 'orderrow', orderRow) ? (
				<div className={classes.elem('row').b()}>
					<Text size="sm">{T('default.netAmount') + ':'}</Text>
					<Text size="sm">{currencyFormat(total, currency, false, decimals, 0)}</Text>
				</div>
			) : null}
			{alsoDisplayRR && isVisibleRowField(rrSalesModelOption.toLowerCase(), 'orderrow', orderRow) ? (
				<div className={classes.elem('row').b()}>
					<Text size="sm">{rrSalesModelOption + ':'}</Text>
					<Text size="sm">{currencyFormat(recurringValue, currency, false, decimals, 0)}</Text>
				</div>
			) : null}
			{isVisibleRowField('purchasecost', 'orderrow', orderRow) ? (
				<div
					className={classes.elem('row').elem('editCm').mod({ disabled }).b()}
					onClick={() => {
						if (product && !disabled) {
							setEditPurchaseCost(true);
						}
					}}
				>
					<Text size="sm">{T('default.purchasedAtCost') + ':'}</Text>
					{editPurchaseCost ? (
						<div className={classes.elem('row').b()}>
							<NumberInput
								ref={ref => {
									ref?.focus();
								}}
								onBlur={() => {
									dispatch(setPurchaseCost(uuid, cost, false, tracker));
									setEditPurchaseCost(false);
								}}
								onChange={value => setCost(value ?? 0)}
								value={cost}
								size="sm"
							/>
							<Text size="sm">{currency}</Text>
						</div>
					) : (
						<div className={classes.elem('row').elem('editIcon').b()}>
							{product ? <Icon name="edit" /> : null}
							<Text size="sm">{currencyFormat(totalPurchaseCost, currency, false, decimals, 0)}</Text>
						</div>
					)}
				</div>
			) : null}
			{isVisibleRowField('contributionmargin', 'orderrow', orderRow) ? (
				<div className={classes.elem('row').b()}>
					<Text size="sm">{T('default.contributionMarginShort') + ':'}</Text>
					<Text size="sm">{`${currencyFormat(
						cm,
						currency,
						false,
						decimals,
						0
					)} (${totalContributionMarginPercentage(total, cm)}%)`}</Text>
				</div>
			) : null}
		</div>
	);
};

export default CMOrderRowSummary;
