import T from 'Components/Helpers/translate';
import PriceList from 'App/resources/Model/PriceList';

export const getInactiveName = (name: string): string => `${name} (${T('default.inactive')})`;

export const getSelectPriceLists = (selectedId?: PriceList['id'] | null): PriceList[] => {
	const priceLists = Tools.AppService.getPriceLists();
	const selectablePriceLists = priceLists.filter(priceList => priceList.active);

	if (idIsInactive(selectablePriceLists, selectedId)) {
		const inactivePriceList = priceLists.find(priceList => priceList.id === selectedId)!;
		selectablePriceLists.push({ ...inactivePriceList, name: getInactiveName(inactivePriceList.name) });
	}

	return selectablePriceLists;
};

function idIsInactive(activePriceLists: PriceList[], priceListId?: PriceList['id'] | null) {
	return priceListId && !activePriceLists.some(priceList => priceList.id === priceListId);
}
