function generate() {
	return { value: true };
}

function toUrl(filter) {
	return { v: filter.value, i: filter.inactive };
}

function fromUrl(rawFilter) {
	const filter = generate();
	filter.inactive = rawFilter.hasOwnProperty('i') ? rawFilter.i : isInactive(filter);
	if (rawFilter.v) {
		filter.value = rawFilter.v;
	}
	return filter;
}

function isInactive(filter) {
	return filter.value === null;
}

function build(filter, rb) {
	if (filter.inactive) {
		return;
	}
	rb.addFilter({ field: 'headquarters' }, rb.comparisonTypes.Equals, filter.value);
}

const filter = {
	filterName: 'Headquarters',
	title: 'soliditet.headquarters',
	type: 'raw',
	entity: 'account',
	showOnSegment: true,
	displayType: 'radio',
	options: [
		{ text: 'soliditet.headquarters', value: true },
		{ text: 'account.branches', value: false }
	],
	generate,
	toUrl,
	fromUrl,
	isInactive,
	build
};

export default filter;
