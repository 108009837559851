import React from 'react';
import PropTypes from 'prop-types';
import {
	Table,
	TableColumn,
	TableHeader,
	TableRow,
	Button,
	Modal,
	ModalHeader,
	ModalControls,
	ModalContent,
	Tabs,
	Tab,
	Text,
	Loader,
	Link
} from '@upsales/components';
import { connect } from 'react-redux';
import BemClass from '@upsales/components/Utils/bemClass';

import Generic from '../CreateNewAccount/Columns/Generic';
import NameSoliditet from '../CreateNewAccount/Columns/NameSoliditet';
import NameProspecting from '../CreateNewAccount/Columns/NameProspecting';
import BuyButton from '../../DataSource/BuyButton';
import ExistingAccounts from './ExistingAccounts';
import { fetchClientsInUpsales } from 'Store/reducers/RefreshAccount';
import CountrySelect from 'App/components/Inputs/Selects/CountrySelect';
import logError from 'App/babel/helpers/logError';
import Prospecting from 'App/babel/resources/Prospecting';
import { makeCancelable } from 'App/babel/helpers/promise';
import { init, merge } from 'Store/reducers/RefreshAccount';
import { getIntegrations, makeSearch } from './helpers';

// loading and paginatorOffset is only passed down so that componentDidUpdate will run and recalculate the hight
const mapStateToProps = state => state.RefreshAccounts;
const mapDispatchToProps = { fetchClientsInUpsales, init, merge };

import './RefreshAccount.scss';

class RefreshAccount extends React.Component {
	constructor(props) {
		super(props);

		this.hasProspecting = Tools.FeatureHelper.isAvailable(Tools.FeatureHelper.Feature.PROSPECTING_BASIC);

		this.state = {
			activeTab: this.hasProspecting ? 'prospecting' : 'soliditet',
			dataSources: null,
			mergin: null
		};

		const t = Tools.$translate;
		this.lang = {
			headquarters: t('default.parentCompany'),
			branch: t('default.branchOffice'),
			city: t('address.city'),
			numEmployees: t('default.numOfEmployees'),
			turnover: t('default.turnover'),
			update: t('default.update'),
			noResult: t('noResult.account'),
			countrySelectPlaceholder: t('ads.targetLocationTypeToSearch'),
			changeCountry: t('default.changeCountry'),
			inUpsales: t('account.inUpsales')
		};

		this.changeTab = this.changeTab.bind(this);

		this.props.init();
	}

	componentDidMount() {
		const { account, integrationFilter, onlyNordic, onlyProspecting, duns } = this.props;
		const integrations = getIntegrations({ account, integrationFilter, onlyNordic, onlyProspecting });
		this.setState({ dataSources: integrations });

		const promises = integrations.map(dataSource => makeSearch({ dataSource, account, duns }));
		this.fetchPromise = makeCancelable(Promise.all(promises));
		this.fetchPromise.promise
			.then(dataSources => {
				this.setState({});
				return this.props.fetchClientsInUpsales(this.props.account.id, dataSources);
			})
			.catch(error => {
				if (error && !error.isCanceled) {
					logError(error, 'RefreshAccount - componentDidMount - failed');
				}
			});
	}

	componentDidUpdate() {
		const reloadModalPosition = this.props.reloadModalPosition;
		reloadModalPosition?.();
	}

	componentWillUnmount() {
		if (this.fetchPromise) {
			this.fetchPromise.cancel();
		}
	}

	changeTab(id, country) {
		if (id === 'existing') {
			return this.setState({ activeTab: id });
		}

		const { dataSources } = this.state;

		const dataSource = dataSources.filter(ds => ds.id === id)[0];
		dataSource.currentTab = country || id;
		dataSource.offset = 0;

		this.setState({ activeTab: dataSource.id });
	}

	makeTabs(dataSources) {
		const tabs = _.chain(dataSources)
			.sortBy('sortId')
			.map(dataSource => {
				const total = (dataSource.data && dataSource.data.length) || 0;

				return (
					<Tab key={dataSource.id} id={dataSource.id}>
						<img className="tab-icon" src={dataSource.logo} />
						{Tools.$translate(dataSource.shortName || dataSource.name)}
						<span className="total-sum">{total}</span>
					</Tab>
				);
			})
			.value();
		if (!this.props.onlyProspecting) {
			tabs.unshift(
				<Tab key="existing" id="existing">
					{this.lang.inUpsales}
					<span className="total-sum">{this.props.accounts?.length || 0}</span>
				</Tab>
			);
		}

		return tabs;
	}

	async mergeAccount(newAccount, dataSource, purchaseType) {
		const { account, reject, resolve, close } = this.props;
		const { SoliditetClient, AppService, DataSource } = Tools;
		const upsalesAccount = account;
		const id = newAccount[dataSource.idField];
		const onSave = Tools.FeatureHelper.hasSoftDeployAccess('REACT_ALERT_MODAL') ? close : resolve;
		const onError = Tools.FeatureHelper.hasSoftDeployAccess('REACT_ALERT_MODAL') ? close : reject;

		let promise;
		if (dataSource.id === 'soliditet') {
			const action = {
				action: 'buy',
				id: upsalesAccount.id,
				dunsNo: id
			};

			promise = SoliditetClient.customer(AppService.getCustomerId()).updateMatches({
				buy: [action]
			});
		} else if (dataSource.id === 'prospecting') {
			promise = Prospecting.save({ id: upsalesAccount.id, prospectingId: id });
		} else {
			const params = {
				type: 'client',
				integrationId: dataSource.id,
				data: {
					id: id,
					mergeId: upsalesAccount.id,
					customValues: []
				}
			};

			promise = DataSource[purchaseType || 'buy'](params);
		}

		promise
			.then(response => {
				const { data } = response;
				onSave(data ? data : upsalesAccount.id);
			})
			.catch(error => {
				console.error(error);
				onError();
			});

		this.setState({ mergin: id });
	}

	changeDataSourceCountry(value) {
		const { activeTab, dataSources } = this.state;
		const { account, duns } = this.props;
		const current = dataSources && dataSources.find(ds => ds.id === activeTab);

		if (current) {
			current.country = value;
		}

		if (value) {
			makeSearch({ dataSource: current, account, duns })
				.then(() => this.setState({}))
				.catch(error => logError(error, 'RefreshAccount - changeDataSourceCountry - failed'));
		}
		this.setState({});
	}

	makeTable(activeTab, dataSources = []) {
		if (activeTab === 'existing') {
			const { account } = this.props;
			return account && account.userRemovable ? (
				<ExistingAccounts closeModal={this.props.resolve} {...this.props} />
			) : null;
		}

		const current = dataSources && dataSources.find(ds => ds.id === activeTab);
		const mergin = this.state.mergin;

		if (current) {
			const disableBuyButton = !!this.props.mergin;
			const emptyResult = !current.data || !current.data.length;
			let headColumns = current.fields.map(field => {
				const title = field.header ? Tools.$translate(field.header) : '';
				const style = {};
				if (field.width) {
					style.width = field.width;
				}
				return (
					<th key={title} className="TableHeader__column" style={style}>
						<span>{title}</span>
					</th>
				);
			});

			if (current.countrySelect && current.country) {
				headColumns = headColumns.slice(0, headColumns.length - 1);
				headColumns.push(
					<th key="countrySelect" className="TableHeader__column--change-country">
						{current.loading ? (
							<Text style={{}} size="sm" color="grey-7">
								<b className={'flag-icon flag-icon-' + current.country.toLowerCase()}></b>
								{this.lang.changeCountry}
							</Text>
						) : (
							<Link onClick={() => this.changeDataSourceCountry(null)}>
								<b className={'flag-icon flag-icon-' + current.country.toLowerCase()}></b>
								{this.lang.changeCountry}
							</Link>
						)}
					</th>
				);
			}

			const header = <TableHeader color="white">{headColumns}</TableHeader>;

			if (current.loading) {
				return (
					<Table key={current.id}>
						{header}
						<TableRow key="no-result">
							<TableColumn colSpan={headColumns.length} align="center">
								<Loader noU={true} size="xs" style={{ display: 'inline' }} />
							</TableColumn>
						</TableRow>
					</Table>
				);
			} else if (current.countrySelect && !current.country) {
				return (
					<Table key={current.id}>
						<TableRow>
							<TableColumn colSpan={headColumns.length}>
								<div className="country-select-wrap">
									<CountrySelect
										required
										onChange={item => this.changeDataSourceCountry(item.id)}
										placeholder={this.lang.countrySelectPlaceholder}
										availableCountries={
											current.countries.length
												? current.countries.map(countryCode => countryCode.toUpperCase())
												: undefined
										}
										anchor={document.getElementById('refresh-account')}
									/>
								</div>
							</TableColumn>
						</TableRow>
					</Table>
				);
			} else if (emptyResult) {
				return (
					<Table key={current.id}>
						{header}
						<TableRow>
							<TableColumn colSpan={headColumns.length}>
								<Text center={true} italic={true} color={'grey-11'}>
									{this.lang.noResult}
								</Text>
							</TableColumn>
						</TableRow>
					</Table>
				);
			} else {
				const rows = current.data.map(data => {
					const id = data[current.idField];
					const rowIsBeingBought = mergin === id;
					const buyButtonDisabled = !!mergin || disableBuyButton;

					const columns = current.fields.map((field, index) => {
						if (field.type === 'add') {
							return (
								<TableColumn
									key="add"
									size="lg"
									style={{ textAlign: 'right', paddingRight: '10px', width: field.width }}
								>
									<BuyButton
										pricing={current.pricing}
										onConfim={value => this.mergeAccount(data, current, value)}
										language={{ add: this.lang.update }}
										adding={rowIsBeingBought}
										icon="refresh"
										color="green"
										disabled={buyButtonDisabled}
									/>
								</TableColumn>
							);
						}

						const standardColumns = {
							nameSoliditet: NameSoliditet,
							nameProspecting: NameProspecting
						};

						const Component = standardColumns[field.type] || Generic;

						return (
							<Component
								key={index}
								account={data}
								field={field}
								actions={{}}
								type={activeTab}
								dataSourceId={activeTab}
								idField={current.idField}
								pricing={current.pricing}
							/>
						);
					});

					return <TableRow key={id}>{columns}</TableRow>;
				});

				return (
					<Table key={current.id}>
						{header}
						{rows}
					</Table>
				);
			}
		}
	}

	renderModalContent() {
		const { reject, name, close } = this.props;
		const onClose = Tools.FeatureHelper.hasSoftDeployAccess('REACT_ALERT_MODAL') ? close : reject;
		const { activeTab, dataSources } = this.state;
		return (
			<>
				<ModalHeader
					icon="home-refresh"
					onClose={onClose}
					title={Tools.$translate('integration.dataSource.selectSyncSentenceTitle')}
				/>
				<ModalContent>
					<div className="header-content">
						<div className="main-text">
							<span>
								{Tools.$translate('integration.dataSource.selectSyncSentence', { company: name })}
							</span>
						</div>
					</div>
					<div className="tabs-wrapper">
						<Tabs
							noFlex
							align="left"
							selected={this.state.activeTab}
							className={'datasource-tabs'}
							onChange={this.changeTab}
						>
							{this.makeTabs(dataSources)}
						</Tabs>
					</div>
					{this.makeTable(activeTab, dataSources)}
				</ModalContent>
				<ModalControls>
					<div className="available-credits">
						<div className="inner-available-credits">
							{Tools.$translate('available_credits')}
							<span className="credits-number">
								{' / ' + Tools.AppService.getMetadata().soliditetCredits}{' '}
								<ReactTemplates.elevio articleId={839} sidebar={true} />
							</span>
						</div>
					</div>
					<Button color="grey" type="link" onClick={() => onClose()}>
						{Tools.$translate('default.abort')}
					</Button>
				</ModalControls>
			</>
		);
	}

	render() {
		if (Tools.FeatureHelper.hasSoftDeployAccess('REACT_ALERT_MODAL')) {
			const { className } = this.props;
			const classes = new BemClass('RefreshAccount', className);
			classes.add('form');

			return (
				<Modal size="lg" id="refresh-account" className={classes.b()}>
					{this.renderModalContent()}
				</Modal>
			);
		}

		return (
			<div id="refresh-account" className="form">
				{this.renderModalContent()}
			</div>
		);
	}
}

RefreshAccount.propTypes = {
	init: PropTypes.func,
	reject: PropTypes.func,
	resolve: PropTypes.func,
	name: PropTypes.string,
	duns: PropTypes.string,
	account: PropTypes.object,
	accounts: PropTypes.array,
	className: PropTypes.string,
	reloadModalPosition: PropTypes.func,
	fetchClientsInUpsales: PropTypes.func,
	mergin: PropTypes.number,
	onlyNordic: PropTypes.bool,
	onlyProspecting: PropTypes.bool,
	integrationFilter: PropTypes.arrayOf(PropTypes.string),
	close: PropTypes.func
};

const Component = connect(mapStateToProps, mapDispatchToProps)(RefreshAccount);

export const detached = RefreshAccount;
export default Component;
