import React from 'react';
import {
	ModalHeader,
	Modal,
	ModalContent,
	Text,
	Button,
	Title,
	ModalControls,
	Column,
	Block
} from '@upsales/components';
import bemClass from '@upsales/components/Utils/bemClass';
import T from 'Components/Helpers/translate';
import Self from 'App/resources/Self';
import { PrimaryButton } from '@upsales/components/Buttons';

import './AllowAccess.scss';

const AllowAccess = ({ className, close }: { className: string; close: () => void }) => {
	const classes = new bemClass('AllowAccess', className);

	const allow = () => Self.approveSupportRequest(14).then(close).catch(close);
	return (
		<Modal className={classes.b()} size="md">
			<ModalHeader title={T('allowAccess.requestAccess')} />
			<ModalContent>
				<Block space="mtxl mbxl">
					<Title size="lg">{T('allowAccess.allowUpsales')}</Title>
					<Text color="grey-11">{T('allowAccess.subtitle')}</Text>
				</Block>
			</ModalContent>
			<ModalControls>
				<Column align="right">
					<PrimaryButton size="lg" onClick={allow}>
						{T('default.approve')}
					</PrimaryButton>
					<Button onClick={close} type="link">
						{T('allowAccess.decline')}
					</Button>
				</Column>
			</ModalControls>
		</Modal>
	);
};

export default AllowAccess;
