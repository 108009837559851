import React from 'react';
import { Text } from '@upsales/components';
import moment from 'moment';
import TimelineHistoryUsers from '../TimelineHistoryUsers';
import TimelineRow from '../TimelineRow';
import T from 'Components/Helpers/translate';
import { LeadEvent } from 'App/babel/propTypes/EventShape';
const TimelineLead = ({ event }) => {
	const { users } = event || {};
	const titleWithoutUser = (
		<Text>{users && users.length ? T('default.createdLead').toLowerCase() : T('default.leadWasCreated')}</Text>
	);
	const subTitle = event.date ? (
		<Text size="sm" color="grey-11">
			{moment(event.date).format('L LT')}
		</Text>
	) : null;
	return users && users.length ? (
		<TimelineHistoryUsers icon="star" event={event} title={titleWithoutUser} subTitle={subTitle} />
	) : (
		<TimelineRow icon="star" event={event} title={titleWithoutUser} subTitle={subTitle} />
	);
};

TimelineLead.propTypes = {
	event: LeadEvent.isRequired
};

export default TimelineLead;
