import {
	OUTCOME_TYPES,
	NO_ANSWER_ACTIONS,
	ANSWER_ACTIONS,
	POSTPONED,
	ANSWER_ACTIONS_GREAT,
	LEAD_TYPES,
	NO_LEAD_ACTIONS,
	FOUND_LEAD_ACTIONS,
	USER_CHANGED,
	SET_DATE
} from './activityOutcome';

export const TIME_LINE_EVENT_TYPES = {
	ACTIVITY: 'Activity_log',
	CLIENT: 'Client',
	CONTACT: 'Contact'
} as const;

// Specific to the activity timeline
export const ACTIVITY_TIMELINE = {
	CREATED: 'created',
	OUTCOME_CHANGED: 'outcomeChanged',
	NOT_REACHED_SEND_MAIL: `${OUTCOME_TYPES.NO_ANSWER}_${NO_ANSWER_ACTIONS.SEND_MAIL}`,
	REACHED_SEND_MAIL: `${OUTCOME_TYPES.ANSWER}_${ANSWER_ACTIONS.SEND_MAIL}`,
	NOT_REACHED_POSTPONED: `${OUTCOME_TYPES.NO_ANSWER}_${POSTPONED}`,
	REACHED_POSTPONED: `${OUTCOME_TYPES.ANSWER}_${POSTPONED}`,
	POSTPONED: `${OUTCOME_TYPES.POSTPONE}_${POSTPONED}`,
	SET_DATE: `${SET_DATE}_${POSTPONED}`,
	NOT_REACHED_STOP_TRYING: `${OUTCOME_TYPES.NO_ANSWER}_${NO_ANSWER_ACTIONS.STOP_TRYING}`,
	REACHED_STOP_TRYING: `${OUTCOME_TYPES.ANSWER}_${ANSWER_ACTIONS.STOP_TRYING}`,
	REACHED_BOOK_APPOINTMENT: `${OUTCOME_TYPES.ANSWER}_${ANSWER_ACTIONS_GREAT.BOOK_APPOINTMENT}`,
	REACHED_CREATE_OPPORTUNITY: `${OUTCOME_TYPES.ANSWER}_${ANSWER_ACTIONS_GREAT.CREATE_OPPORTUNITY}`,
	REACHED_CREATE_ORDER: `${OUTCOME_TYPES.ANSWER}_${ANSWER_ACTIONS_GREAT.CREATE_ORDER}`,
	REACHED_PLAN_PHONECALL: `${OUTCOME_TYPES.ANSWER}_${ANSWER_ACTIONS_GREAT.PLAN_PHONE_CALL}`,
	NOT_FOUND_LEAD_POSTPONED: `${LEAD_TYPES.NO_LEAD}_${NO_LEAD_ACTIONS.POSTPONE_DATE}`,
	NOT_FOUND_LEAD_STOP_TRYING: `${LEAD_TYPES.NO_LEAD}_${NO_LEAD_ACTIONS.STOP_TRYING}`,
	FOUND_LEAD_ADDED_CONTACT: `${LEAD_TYPES.FOUND_LEAD}_${FOUND_LEAD_ACTIONS.ADDED_CONTACT}`,
	USER_CHANGED: USER_CHANGED,
	REACHED_NO_THANKS_POSTPONE: `${OUTCOME_TYPES.ANSWER}_${ANSWER_ACTIONS.NO_THANKS}-${POSTPONED}`,
	REACHED_NO_THANKS_STOP_TRYING: `${OUTCOME_TYPES.ANSWER}_${ANSWER_ACTIONS.NO_THANKS}-${ANSWER_ACTIONS.STOP_TRYING}`,
	REACHED_TALK_TO_SOMEONE: `${OUTCOME_TYPES.ANSWER}_${ANSWER_ACTIONS.TALK_TO_SOMEONE}`,
	ACTIVITY_TYPE_CHANGED: 'activityTypeChanged',
	CONTACT_CHANGED: 'contactChanged',
	DESCRIPTION_CHANGED: 'descriptionChanged',
	DATE_CHANGED: 'dateChanged',
	CLOSED_ACTIVITY: 'closedActivity',
	PHONECALL: 'phoneCall',
	REACHED_CHANGE_CONTACT: `${OUTCOME_TYPES.ANSWER}_change_contact`
} as const;

export const APPOINTMENT_TIMELINE = {
	RESCHEDULED: 'rescheduled'
} as const;

// Do not use for activity-timeline
export const EVENT_TIMELINE_ENTITY_TYPES = {
	SOCIAL_EVENT: 'SocialEvent',
	MAIL_CAMPAIGN: 'MailCampaign',
	MARKETING_CUSTOM: 'MarketingCustom',
	ESIGN: 'Esign',
	VISIT: 'Visit',
	AGREEMENT: 'Agreement',
	CLIENT: 'Client',
	CONTACT: 'Contact',
	ACTIVITY: 'Activity',
	MAIL: 'Mail',
	APPOINTMENT: 'Appointment',
	OPPORTUNITY: 'Opportunity',
	LEAD: 'Lead',
	ORDER: 'Order',
	FORM: 'Form',
	MANUAL: 'Manual',
	COMMENT: 'Comment',
	CLIENT_PLAN: 'ClientPlan',
	TICKET: 'Ticket'
} as const;

// Do not use for activity-timeline
export const EVENT_TIMELINE_SUB_TYPES = {
	ATTENDING: 'attending',
	ATTENDED: 'attended',
	INVITED: 'invited',
	DECLINED: 'declined',
	CLOSED: 'closed',
	CREATED: 'created',
	SEND: 'send',
	PASSED: 'passed',
	WON: 'won',
	SUBMIT: 'submit',
	DRAFT: 'draft',
	SENT: 'sent',
	SIGNED: 'signed',
	UNDELIVERED: 'undelivered',
	VIEWED: 'viewed',
	HARD_BOUNCE: 'hard_bounce',
	SOFT_BOUNCE: 'soft_bounce',
	RECEIVE: 'receive',
	LOST: 'lost',
	VIDEO: 'video',
	EVENT: 'event',
	CHAT: 'chat',
	TWITTER: 'twitter',
	ADJUSTMENT: 'manualadjustment',
	UNSUB: 'unsub',
	STATUS_CHANGED: 'statuschanged'
} as const;

// Shared types for every timeline rendering events
export const EVENT_TIMELINE = {
	VISIT: `${EVENT_TIMELINE_ENTITY_TYPES.VISIT}_`,
	MAIL_CAMPAIGN_SEND: `${EVENT_TIMELINE_ENTITY_TYPES.MAIL_CAMPAIGN}_${EVENT_TIMELINE_SUB_TYPES.SEND}`,
	AGREEMENT_CREATED: `${EVENT_TIMELINE_ENTITY_TYPES.AGREEMENT}_${EVENT_TIMELINE_SUB_TYPES.CREATED}`,
	SOCIAL_EVENT_ATTENDING: `${EVENT_TIMELINE_ENTITY_TYPES.SOCIAL_EVENT}_${EVENT_TIMELINE_SUB_TYPES.ATTENDING}`,
	SOCIAL_EVENT_ATTENDED: `${EVENT_TIMELINE_ENTITY_TYPES.SOCIAL_EVENT}_${EVENT_TIMELINE_SUB_TYPES.ATTENDED}`,
	ESIGN_DRAFT: `${EVENT_TIMELINE_ENTITY_TYPES.ESIGN}_${EVENT_TIMELINE_SUB_TYPES.DRAFT}`,
	ESIGN_SENT: `${EVENT_TIMELINE_ENTITY_TYPES.ESIGN}_${EVENT_TIMELINE_SUB_TYPES.SENT}`,
	ESIGN_SIGNED: `${EVENT_TIMELINE_ENTITY_TYPES.ESIGN}_${EVENT_TIMELINE_SUB_TYPES.SIGNED}`,
	ESIGN_UNDELIVERED: `${EVENT_TIMELINE_ENTITY_TYPES.ESIGN}_${EVENT_TIMELINE_SUB_TYPES.UNDELIVERED}`,
	ESIGN_VIEWED: `${EVENT_TIMELINE_ENTITY_TYPES.ESIGN}_${EVENT_TIMELINE_SUB_TYPES.VIEWED}`,
	MARKETING_CUSTOM_VIDEO: `${EVENT_TIMELINE_ENTITY_TYPES.MARKETING_CUSTOM}_${EVENT_TIMELINE_SUB_TYPES.VIDEO}`,
	MARKETING_CUSTOM_EVENT: `${EVENT_TIMELINE_ENTITY_TYPES.MARKETING_CUSTOM}_${EVENT_TIMELINE_SUB_TYPES.EVENT}`,
	MARKETING_CUSTOM_CHAT: `${EVENT_TIMELINE_ENTITY_TYPES.MARKETING_CUSTOM}_${EVENT_TIMELINE_SUB_TYPES.CHAT}`,
	MARKETING_CUSTOM_TWITTER: `${EVENT_TIMELINE_ENTITY_TYPES.MARKETING_CUSTOM}_${EVENT_TIMELINE_SUB_TYPES.TWITTER}`,
	SOCIAL_EVENT_INVITED: `${EVENT_TIMELINE_ENTITY_TYPES.SOCIAL_EVENT}_${EVENT_TIMELINE_SUB_TYPES.INVITED}`,
	SOCIAL_EVENT_DECLINED: `${EVENT_TIMELINE_ENTITY_TYPES.SOCIAL_EVENT}_${EVENT_TIMELINE_SUB_TYPES.DECLINED}`,
	ACTIVITY_CLOSED: `${EVENT_TIMELINE_ENTITY_TYPES.ACTIVITY}_${EVENT_TIMELINE_SUB_TYPES.CLOSED}`,
	ACTIVITY_CREATED: `${EVENT_TIMELINE_ENTITY_TYPES.ACTIVITY}_${EVENT_TIMELINE_SUB_TYPES.CREATED}`,
	MAIL_SEND: `${EVENT_TIMELINE_ENTITY_TYPES.MAIL}_${EVENT_TIMELINE_SUB_TYPES.SEND}`,
	MAIL_RECEIVE: `${EVENT_TIMELINE_ENTITY_TYPES.MAIL}_${EVENT_TIMELINE_SUB_TYPES.RECEIVE}`,
	MAIL_SOFT_BOUNCE: `${EVENT_TIMELINE_ENTITY_TYPES.MAIL}_${EVENT_TIMELINE_SUB_TYPES.SOFT_BOUNCE}`,
	MAIL_HARD_BOUNCE: `${EVENT_TIMELINE_ENTITY_TYPES.MAIL}_${EVENT_TIMELINE_SUB_TYPES.HARD_BOUNCE}`,
	MAIL_UNSUB: `${EVENT_TIMELINE_ENTITY_TYPES.MAIL}_${EVENT_TIMELINE_SUB_TYPES.UNSUB}`,
	APPOINTMENT_PASSED: `${EVENT_TIMELINE_ENTITY_TYPES.APPOINTMENT}_${EVENT_TIMELINE_SUB_TYPES.PASSED}`,
	APPOINTMENT_CREATED: `${EVENT_TIMELINE_ENTITY_TYPES.APPOINTMENT}_${EVENT_TIMELINE_SUB_TYPES.CREATED}`,
	OPPORTUNITY_CREATED: `${EVENT_TIMELINE_ENTITY_TYPES.OPPORTUNITY}_${EVENT_TIMELINE_SUB_TYPES.CREATED}`,
	LEAD_CREATED: `${EVENT_TIMELINE_ENTITY_TYPES.LEAD}_${EVENT_TIMELINE_SUB_TYPES.CREATED}`,
	ORDER_LOST: `${EVENT_TIMELINE_ENTITY_TYPES.ORDER}_${EVENT_TIMELINE_SUB_TYPES.LOST}`,
	ORDER_WON: `${EVENT_TIMELINE_ENTITY_TYPES.ORDER}_${EVENT_TIMELINE_SUB_TYPES.WON}`,
	FORM_SUBMIT: `${EVENT_TIMELINE_ENTITY_TYPES.FORM}_${EVENT_TIMELINE_SUB_TYPES.SUBMIT}`,
	MANUAL_ADJUSTMENT: `${EVENT_TIMELINE_ENTITY_TYPES.MANUAL}_${EVENT_TIMELINE_SUB_TYPES.ADJUSTMENT}`,
	COMMENT: EVENT_TIMELINE_ENTITY_TYPES.COMMENT,
	CLIENT_PLAN_CREATED: `${EVENT_TIMELINE_ENTITY_TYPES.CLIENT_PLAN}_${EVENT_TIMELINE_SUB_TYPES.CREATED}`,
	CLIENT_PLAN_STATUS_CHANGED: `${EVENT_TIMELINE_ENTITY_TYPES.CLIENT_PLAN}_${EVENT_TIMELINE_SUB_TYPES.STATUS_CHANGED}`,
	TICKET_CREATED: `${EVENT_TIMELINE_ENTITY_TYPES.TICKET}_${EVENT_TIMELINE_SUB_TYPES.CREATED}`,
	TICKET_STATUS_CHANGED: `${EVENT_TIMELINE_ENTITY_TYPES.TICKET}_${EVENT_TIMELINE_SUB_TYPES.STATUS_CHANGED}`
} as const;
