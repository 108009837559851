import Resource from './Resource';
import AgreementGroupHistoryType from 'App/resources/Model/AgreementGroupHistory';
import { TYPE, STYLE } from 'Store/reducers/SystemNotificationReducer';

class AgreementGroupHistory extends Resource {
	constructor() {
		super('agreementGroupHistory');

		// @ts-expect-error
		this.notifications.toggleHidden = () => ({
			title: 'default.changes',
			body: 'arrChange.deleted',
			style: STYLE.SUCCESS,
			icon: 'trash',
			type: TYPE.BODY
		});
	}

	toggleHidden(id: number): Promise<void> {
		return this._putRequest(`toggleHidden/${id}`, { id }, { methodName: 'toggleHidden' }).then(({ data }) => {
			Tools.$rootScope.$broadcast('arrChange.updated');

			return data;
		});
	}

	get(id: number, opts: { params: { isGroup: boolean } }): Promise<{ data: AgreementGroupHistoryType[] }> {
		return super.get(id, opts);
	}
}

const resource = new AgreementGroupHistory();

export default resource;
