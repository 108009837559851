import { useEditProjectPlanContext } from '../Context';
import logError from 'App/babel/helpers/logError';
import React, { useEffect } from 'react';
import FileResource from 'App/babel/resources/File';
import FilesTabContent from 'Components/Modals/FilesTabContent/FilesTabContent';
import { PROJECT_FILE_ENTITY } from '../Helpers/Helpers';
import NotificationService from 'App/babel/NotificationService';
import type File from 'App/resources/Model/File';

const ProjectFiles = () => {
	const {
		state: { files, projectPlan, fileAmount },
		getFiles,
		setFiles,
		setFileAmount
	} = useEditProjectPlanContext();

	useEffect(() => {
		if (!projectPlan?.id) {
			return;
		}
		getFiles(projectPlan.id);
	}, [projectPlan?.id]);

	const handleFileDrop = (droppedFiles: File[]) => {
		if (!projectPlan?.id) {
			return;
		}

		Promise.all(
			droppedFiles.map(file =>
				FileResource.upload(file, {
					fileEntity: PROJECT_FILE_ENTITY,
					fileId: projectPlan.id
				})
			)
		)
			.then(res => {
				setFiles([...files, ...res]);
				setFileAmount(fileAmount + res.length);
			})
			.catch(error => {
				logError(error, 'Error uploading files');
				NotificationService.add({
					style: NotificationService.style.ERROR,
					title: 'default.error',
					body: typeof error === 'string' ? error : 'file.uploadFailed',
					icon: 'times'
				});
			});
	};

	const handleFileRemoval = (file: File) => {
		FileResource.delete(file.id, {
			noConfirm: true
		})
			.then(res => {
				const updatedFiles = files.filter(f => f.id !== res.data.id);
				setFiles(updatedFiles);
				setFileAmount(fileAmount - 1);
			})
			.catch(error => {
				logError(error, 'Error deleting file');
				NotificationService.add({
					style: NotificationService.style.ERROR,
					title: 'default.error',
					body: typeof error === 'string' ? error : 'file.removeFailed',
					icon: 'times'
				});
			});
	};

	return <FilesTabContent files={files} newFiles={[]} onDrop={handleFileDrop} onRemove={handleFileRemoval} />;
};

export default ProjectFiles;
