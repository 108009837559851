import Resource from './Resource';

class CreateAccount extends Resource {
	constructor() {
		super('createAccount', null);
	}
}

const resource = new CreateAccount();

export default resource;
