import React from 'react';
import PropTypes from 'prop-types';
import { Checkbox, Button } from '@upsales/components';
import BemClass from '@upsales/components/Utils/bemClass';
import './SelectItem.scss';

class SelectItem extends React.Component {
	constructor(props) {
		super(props);
		this.classes = new BemClass('SelectItem');
		const t = Tools.$translate;
		this.lang = {
			defaultOnly: t('default.only').toLowerCase()
		};
	}

	render() {
		return (
			<div
				className={
					this.classes.elem('item').b() +
					(this.props.item.isRole ? ' ' + this.classes.elem('group-title').b() : '')
				}
				onClick={() => {
					if (this.props.item.isSelectAll) {
						this.props.selectAll();
					} else if (this.props.item.children) {
						this.props.item.$$selected = !this.props.item.$$selected;
						this.props.selectGroup(this.props.item, this.props.item.$$selected);
					} else {
						this.props.select(this.props.item, !this.props.item.$$selected);
					}
				}}
				style={this.props.style}
			>
				<span style={{ paddingLeft: 10 + 25 * (this.props.item.$depth || 0) + 'px' }}></span>
				<Checkbox size="sm" checked={this.props.item.$$selected} />
				<label>
					{typeof this.props.displayText === 'function'
						? this.props.displayText(this.props.item)
						: this.props.item[this.props.displayText]}
					{this.props.item.children && this.props.item.$$length ? (
						<span>{' (' + this.props.item.$$length + ')'}</span>
					) : null}
				</label>
				{!this.props.item.children && !this.props.item.isSelectAll && !this.props.singleSelect ? (
					<Button
						type="link"
						block={false}
						color="bright-blue"
						size="sm"
						onClick={e => {
							e.stopPropagation();
							this.props.selectOne(this.props.item);
						}}
					>
						{this.lang.defaultOnly}
					</Button>
				) : null}
			</div>
		);
	}
}

SelectItem.propTypes = {
	item: PropTypes.object,
	select: PropTypes.func,
	selectOne: PropTypes.func,
	selectGroup: PropTypes.func,
	selectAll: PropTypes.func,
	displayText: PropTypes.oneOfType([PropTypes.func, PropTypes.string]),
	singleSelect: PropTypes.bool,
	style: PropTypes.object
};

export default SelectItem;
