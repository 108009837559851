import store, { RootState } from 'Store/index';
import { addModal } from 'Store/actions/ModalActions';
import modalConfigs, { Animation, ModalComponentProps, ModalConfig, ModalConfigName, ModalOpts } from './modalConfigs';
import { AnyAction } from 'redux';
import { ThunkDispatch } from 'redux-thunk';
import { ModalProps } from 'App/components/Modals/Modals';

const defaultOpts: ModalOpts = {
	closeOnCurtain: false,
	animation: Animation.Fade,
	drawer: false
};

export type OpenModal = ModalConfig & { name: string; opts: ModalOpts };

export const getModalConfig = (name: ModalConfigName): OpenModal | null => {
	const d: ModalConfig = modalConfigs[name];
	if (!d) {
		return null;
	}
	return { ...d, opts: { ...defaultOpts, ...d.opts }, name };
};

export type OpenModalProps<ConfName extends ModalConfigName> = {
	onClose?: ModalComponentProps<ConfName>['close'];
} & Omit<ModalComponentProps<ConfName>, keyof ModalProps>;

const openModal = <ConfName extends ModalConfigName>(name: ConfName, props?: OpenModalProps<ConfName>) => {
	const modal = getModalConfig(name);
	if (!modal) {
		throw new Error(`There is no modal with the name "${name}"`);
	}
	const dispatch = store.dispatch as ThunkDispatch<RootState, any, AnyAction>;
	dispatch(
		addModal({
			name,
			opts: modal.opts,
			props: { ...(modal.props ?? {}), ...(props ?? {}) },
			openedTimeStamp: new Date()
		})
	);
};

export default openModal;
