import attributes from 'App/babel/attributes/Agreement';
import { FilterConfig } from 'App/babel/filterConfigs/FilterConfig';
import AccessType from 'App/enum/AccessType';
import { generateFilter, FilterPreset } from '../filterGenerators';
import DatePreset from 'App/upsales/common/services/datePresetService';
import T from 'Components/Helpers/translate';
import ComparisonTypes from 'Resources/ComparisonTypes';

export default (): { [key: string]: FilterConfig } => ({
	// No parent
	Description: generateFilter(FilterPreset.Text, {
		title: 'default.description',
		field: 'description'
	}),
	Id: generateFilter(FilterPreset.Text, {
		title: 'default.id',
		field: 'id'
	}),
	Active: generateFilter(FilterPreset.RawRadio, {
		title: 'default.active',
		options: [
			{ text: 'default.all', value: null, inactive: true },
			{ text: 'default.active', value: true },
			{ text: 'default.inactive', value: false }
		],
		build: function (filter, rb) {
			var orGroup;
			if (filter.value) {
				orGroup = rb.orBuilder();
				orGroup.next();
				orGroup.addFilter(attributes.metadata.attr.agreementEnddate, ComparisonTypes.Equals, null);
				orGroup.next();
				orGroup.addFilter(attributes.metadata.attr.agreementEnddate, ComparisonTypes.GreaterThan, new Date());
				orGroup.done();
				rb.addFilter(attributes.metadata.attr.agreementStartdate, ComparisonTypes.LessThanEquals, new Date());
			} else {
				if (filter.value === false) {
					orGroup = rb.orBuilder();
					orGroup.next();
					orGroup.addFilter(attributes.metadata.attr.agreementEnddate, ComparisonTypes.LessThan, new Date());
					orGroup.next();
					orGroup.addFilter(
						attributes.metadata.attr.agreementStartdate,
						ComparisonTypes.GreaterThan,
						new Date()
					);
					orGroup.done();
					rb.addSort(attributes.metadata.attr.agreementStartdate, false);
				}
			}
		}
	}),
	YearlyValue: generateFilter(FilterPreset.Range, {
		title: 'default.yearlyValue',
		field: 'yearlyValue'
	}),
	OrderRowProduct: generateFilter(FilterPreset.Product, {}, ['orderRow.product.id', 'children.orderRow.product.id']),
	Stage: generateFilter(FilterPreset.Stage, { field: 'stage.id' }, true),
	Campaign: generateFilter(FilterPreset.Campaign, { field: 'project.id' }),
	Contact: generateFilter(FilterPreset.ResourceLazyList, { searchField: 'title' }, 'contact'),
	clientConnection: generateFilter(FilterPreset.Text, {
		title: 'default.clientConnection',
		field: 'clientConnection.name'
	}),

	// Section Subscriptions settings
	lastIndexIncreaseDate: generateFilter(
		FilterPreset.Date,
		{
			field: 'latestIndexIncreaseDate',
			title: 'default.lastIndexIncreaseDate',
			parent: 'filter.agreementSettings',
			multiComparitors: true
		},
		'standard'
	),
	startDate: generateFilter(
		FilterPreset.Date,
		{
			field: 'metadata.agreementStartdate',
			title: 'default.startDate',
			parent: 'filter.agreementSettings'
		},
		'future'
	),
	endDate: generateFilter(
		FilterPreset.Date,
		{
			field: 'metadata.agreementEnddate',
			title: 'default.endDate',
			parent: 'filter.agreementSettings'
		},
		'future'
	),
	invoiceStartDate: generateFilter(
		FilterPreset.Date,
		{
			field: 'metadata.agreementInvoiceStartdate',
			title: 'agreement.startDateCurrentPeriod',
			parent: 'filter.agreementSettings'
		},
		'future'
	),
	nextOrderDate: generateFilter(
		FilterPreset.RawDate,
		{
			title: 'agreement.nextOrderDate',
			parent: 'filter.agreementSettings',
			isInactive: function (filter) {
				if (!filter.value) {
					return true;
				}
				if (filter.value.preset === 'custom' && (filter.value.start || filter.value.end)) {
					return false;
				}
				if (filter.value.preset !== 'whenever') {
					return false;
				}
				return true;
			},
			toUrl: function (filter) {
				type FilterType = {
					v?: {
						s?: string;
						e?: string;
						p?: string;
					};
				};
				var f: FilterType = {};

				if (filter.value) {
					f.v = {};

					if (filter.value.start) {
						f.v.s = filter.value.start;
					}
					if (filter.value.end) {
						f.v.e = filter.value.end;
					}
					if (filter.value.preset) {
						f.v.p = filter.value.preset;
					}
				}
				return f;
			},
			fromUrl: function (rawFilter) {
				if (!rawFilter || !rawFilter.v) {
					return { value: { preset: 'whenever' } };
				} else {
					var filter: {
						value: {
							start?: Date;
							end?: Date;
							preset?: string;
						};
					} = { value: {} };

					if (rawFilter.v.s) {
						filter.value.start = rawFilter.v.s;
					}
					if (rawFilter.v.e) {
						filter.value.end = rawFilter.v.e;
					}
					if (rawFilter.v.p) {
						filter.value.preset = rawFilter.v.p;
					}
					if (filter.value.preset === 'custom') {
						if (filter.value.start) {
							filter.value.start = new Date(filter.value.start);
						}
						if (filter.value.end) {
							filter.value.end = new Date(filter.value.end);
						}
					}

					return filter;
				}
			},
			build: function (filter, rb) {
				var start = filter.value ? filter.value.start : null;
				var end = filter.value ? filter.value.end : null;
				if (filter.value && filter.value.preset && filter.value.preset !== 'custom') {
					var values = DatePreset.getValues(filter.value.preset);
					start = values.start;
					end = values.end;
				}
				if (start) {
					rb.addFilter(
						{ field: 'metadata.agreementNextOrderDate' },
						ComparisonTypes.GreaterThanEquals,
						start
					);
				}

				if (end) {
					rb.addFilter({ field: 'metadata.agreementNextOrderDate' }, ComparisonTypes.LessThanEquals, end);
				}

				rb.addFilter({ field: 'metadata.willCreateMoreOrders' }, ComparisonTypes.Equals, true);
			}
		},
		'future'
	),
	renewalDate: generateFilter(
		FilterPreset.Date,
		{
			field: 'metadata.agreementRenewalDate',
			title: 'agreement.renewalDate',
			parent: 'filter.agreementSettings'
		},
		'future'
	),
	noticePeriod: generateFilter(FilterPreset.Range, {
		title: 'agreement.noticePeriod',
		field: 'metadata.noticePeriod',
		parent: 'filter.agreementSettings',
		valueSuffix: () => T('default.months').toLowerCase()
	}),
	noticeDate: generateFilter(
		FilterPreset.Date,
		{
			field: 'metadata.noticeDate',
			title: 'agreement.noticeDate',
			parent: 'filter.agreementSettings'
		},
		'future'
	),
	intervalPeriod: generateFilter(FilterPreset.List, {
		field: 'metadata.agreementIntervalPeriod',
		title: 'agreement.intervalPeriod',
		inputType: 'select',
		dataPromise: $q =>
			$q.when({
				data: Tools.AppService.getStaticValues('recurringInterval')
			}),
		parent: 'filter.agreementSettings'
	}),
	periodLength: generateFilter(FilterPreset.List, {
		field: 'metadata.periodLength',
		title: 'agreement.periodLength',
		inputType: 'select',
		dataPromise: $q =>
			$q.when({
				data: Tools.AppService.getStaticValues('periodLength')
			}),
		parent: 'filter.agreementSettings'
	}),

	// Section User
	User: generateFilter(
		FilterPreset.User,
		{
			parent: 'default.user'
		},
		AccessType.AGREEMENT
	),
	UserRole: generateFilter(FilterPreset.Role, { field: 'user.role.id' }),

	// Section Company
	Account: generateFilter(FilterPreset.Text, {
		title: 'default.accountName',
		field: 'client.name',
		parent: 'default.account'
	}),
	AccountManager: generateFilter(
		FilterPreset.User,
		{
			field: 'client.user.id',
			title: 'default.accountManagers',
			parent: 'default.account'
		},
		AccessType.ACCOUNT
	),
	AccountCategory: generateFilter(FilterPreset.ClientCategory, { field: 'client.category.id' }),

	ListSearch: generateFilter(FilterPreset.ListSearch, {}, [
		'client.name',
		'contact.name',
		'description',
		'metadata.agreementDescription'
	])
});
