import Signals from 'Resources/Signals';
import { AppThunk } from '..';

export enum FilterDate {
	ALL = 'all',
	MONTH = 'month',
	WEEK = 'week'
}

export enum FilterType {
	ALL = 'all',
	NEWS = 'news',
	UPDATES = 'updates',
	RISK = 'risk'
}

export enum FilterView {
	USER = 'user',
	ALL = 'all'
}

export const RESET = '[SignalsFeed] RESET';
export const SET_STATE = '[SignalsFeed] SET_STATE';

export const initialState = {
	filter: FilterType.RISK,
	date: FilterDate.WEEK,
	view: FilterView.USER,
	loading: false,
	offset: 0,
	limit: 50,
	total: 0,
	items: []
};

export type SignalsFeedState = {
	filter: FilterType;
	date: FilterDate;
	view: FilterView;
	loading: boolean;
	items?: any[];
	offset: number;
	limit: number;
	total: number;
};

export const init = (): AppThunk => async (dispatch, getState) => {
	dispatch({ type: SET_STATE, loading: true });
	const { view, filter, date, limit, offset } = getState().SignalsFeed;
	try {
		const { data, metadata } = await Signals.getFeed(view, filter, date, limit, offset);
		dispatch({ type: SET_STATE, items: data, total: metadata.total || 0, loading: false });
	} catch (error: any) {
		dispatch({ type: SET_STATE, items: [], total: 0, loading: false });
	}
};

export const setState =
	(partialState: Partial<SignalsFeedState>): AppThunk =>
	dispatch => {
		dispatch({ type: SET_STATE, ...partialState });
	};

export const loadItems =
	(prospectingId: string): AppThunk =>
	async (dispatch, getState) => {
		dispatch({ type: SET_STATE, loading: true });
		const { filter, date, limit, offset } = getState().SignalsFeed;
		try {
			const { data, metadata } = await Signals.getFeedForProspectingId(
				prospectingId,
				filter,
				date,
				limit,
				offset
			);
			dispatch({ type: SET_STATE, items: data, total: metadata.total || 0, loading: false });
		} catch (error: any) {
			dispatch({ type: SET_STATE, items: [], total: 0, loading: false });
		}
	};

export const onChangeDate =
	(value: FilterDate): AppThunk =>
	async (dispatch, getState) => {
		dispatch({ type: SET_STATE, date: value, loading: true });
		const { view, filter, limit, offset } = getState().SignalsFeed;
		try {
			const { data, metadata } = await Signals.getFeed(view, filter, value, limit, offset);
			dispatch({
				type: SET_STATE,
				items: data,
				total: metadata.total || 0,
				view,
				filter,
				date: value,
				limit,
				offset,
				loading: false
			});
		} catch (error: any) {
			dispatch({ type: SET_STATE, items: [], total: 0, date: value, loading: false });
		}
	};

export const onChangeView =
	(value: FilterView): AppThunk =>
	async (dispatch, getState) => {
		dispatch({ type: SET_STATE, view: value, limit: 50, offset: 0, loading: true });
		const { date, filter, limit, offset } = getState().SignalsFeed;
		try {
			const { data, metadata } = await Signals.getFeed(value, filter, date, limit, offset);
			dispatch({
				type: SET_STATE,
				items: data,
				total: metadata.total || 0,
				view: value,
				filter,
				date,
				limit,
				offset,
				loading: false
			});
		} catch (error: any) {
			dispatch({ type: SET_STATE, items: [], total: 0, view: value, loading: false });
		}
	};

export const onChangeType =
	(value: FilterType): AppThunk =>
	async (dispatch, getState) => {
		dispatch({ type: SET_STATE, filter: value, loading: true });
		const { view, date, limit, offset } = getState().SignalsFeed;
		try {
			const { data, metadata } = await Signals.getFeed(view, value, date, limit, offset);
			dispatch({
				type: SET_STATE,
				items: data,
				total: metadata.total || 0,
				view,
				filter: value,
				date,
				limit,
				offset,
				loading: false
			});
		} catch (error: any) {
			dispatch({ type: SET_STATE, items: [], total: 0, filter: value, loading: false });
		}
	};

export const paginate =
	(offset: number): AppThunk =>
	async (dispatch, getState) => {
		dispatch({ type: SET_STATE, offset: offset, loading: true });
		const { view, filter, limit, date } = getState().SignalsFeed;
		try {
			const { data, metadata } = await Signals.getFeed(view, filter, date, limit, offset);
			dispatch({ type: SET_STATE, items: data, total: metadata.total || 0, loading: false });
		} catch (error: any) {
			dispatch({ type: SET_STATE, items: [], total: 0, loading: false });
		}
	};

type ActionHandler = (
	state: SignalsFeedState,
	action: Partial<SignalsFeedState> & { type: string }
) => SignalsFeedState;

const ACTION_HANDLERS: { [key: string]: ActionHandler } = {
	[RESET]: () => ({ ...initialState }),
	[SET_STATE]: (state, { type, ...newState }) => ({ ...state, ...newState })
};

export default (state: SignalsFeedState = initialState, action: SignalsFeedState & { type: string }) => {
	const handler = ACTION_HANDLERS[action.type];
	return handler ? handler(state, action) : state;
};
