import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { RootState } from 'Store/index';
import { getDefaultCustomValues } from '../CustomFields/CustomFieldsUtils';
import { getInitialState, getPriceList, getStage } from './Context/SubscriptionGroupContext';
import Product from 'App/resources/Model/Product';
import logError from 'Helpers/logError';
import { OrderInterval, SubscriptionGroupState, SubscriptionPeriodState } from './Context/SubscriptionGroupState';
import T from 'Components/Helpers/translate';
import { SubscriptionGroupProvider } from './Context/SubscriptionGroupContext';
import { FieldTranslationsProvider } from '../FieldTranslations/FieldTranslationsContext';

import { default as OpenSubscription, Props } from './OpenSubscription';
import { getDefaultPeriodSettings } from './Context/SubscriptionGroupContextHelpers';
import { getActivePriceLists } from 'Store/selectors/AppSelectors';
import { ClientIdNamePriceList } from 'App/resources/Model/Client';
import { Self } from 'App/resources/AllIWant';
import { BundleRow } from 'App/components/OrderRows/Context/OrderContext';
import ProductResource from 'Resources/Product';
import { makeCancelable, CancelablePromise } from 'App/babel/helpers/promise';
import Order from 'App/resources/Model/Order';

type IncomingObjects = {
	client?: ClientIdNamePriceList;
	contact?: SubscriptionGroupState['contact'];
	order?: Partial<Order>;
	needsToConvert?: boolean;
	groupState?: Partial<SubscriptionGroupState>;
	allProducts?: Product[];
};

const getCompactObject = (obj: IncomingObjects['contact'] | Self) => (obj ? { id: obj.id, name: obj.name } : null);

const getCompactClient = (client?: IncomingObjects['client']) =>
	client
		? {
				id: client.id,
				name: client.name,
				priceListId: client.priceListId,
				operationalAccount: client.operationalAccount
		  }
		: null;

const useInitialState = ({
	client,
	contact,
	order,
	needsToConvert,
	allProducts
}: IncomingObjects): Partial<SubscriptionGroupState> => {
	const stages = useSelector((state: RootState) => state.App.stages.order.filter(order => order.probability !== 0));
	const user = useSelector((state: RootState) => state.App.self);
	const customFields = useSelector((state: RootState) => state.App.customFields?.['agreement']);
	const orderCustomFields = useSelector((state: RootState) => state.App.customFields?.['order']);
	const metadata = useSelector((state: RootState) => state.App?.metadata);

	const stage = getStage(stages, metadata?.params, order?.stage?.id);
	const custom = getDefaultCustomValues(customFields);

	const priceLists = getActivePriceLists();
	const priceList = getPriceList(priceLists, order?.priceListId ?? client?.priceListId, !order?.priceListId);

	contact = order?.contact ?? contact ?? null;
	const clientConnection = order?.clientConnection ?? null;

	const defaultPeriodSettings = getDefaultPeriodSettings(priceList, metadata?.params, order?.date?.toString());

	const description = order?.description ?? '';
	const notes = order?.notes ? `${T('agreement.notesFromOrder')}:\n\n${order.notes}` : '';

	const project = order?.project ?? null;
	const currency = order?.currency ?? metadata?.defaultCurrency.iso ?? 'SEK';
	const currencyRate = order?.currencyRate ?? metadata?.defaultCurrency.rate ?? 1;
	const editableCustomFieldIds = orderCustomFields
		.filter(field => field.editable)
		.map(field => field.id)
		.flat();
	const orderCustom: SubscriptionPeriodState['custom'] = (order?.custom ?? []).filter(
		custom => editableCustomFieldIds.indexOf(custom.id) >= 0
	);
	const createdFromOrderId = order?.id;

	const orderInterval = (order?.recurringInterval || defaultPeriodSettings.orderInterval) as OrderInterval;

	const isAdvancedMode =
		Tools.FeatureHelper.hasSoftDeployAccess('SUBSCRIPTION_SPLITS') ||
		orderInterval != defaultPeriodSettings.orderInterval; // eslint-disable-line eqeqeq

	const rowsWithRecurringProducts = order?.orderRow?.filter(row => row.product?.isRecurring) ?? [];
	const orderRows = rowsWithRecurringProducts
		.sort((a, b) => (a.sortId > b.sortId ? 1 : -1))
		.map((row, idx) => {
			// Order that has different currency than the master currency needs to get converted to show the correct price
			const price = row.price * (needsToConvert ? currencyRate : 1);
			const listPrice = row.listPrice * (needsToConvert ? currencyRate : 1);
			const purchaseCost = row.purchaseCost * (needsToConvert ? currencyRate : 1);
			const isTotalPrice = !!row.product?.tiers?.[0]?.isTotalPrice;
			const quantity = isTotalPrice ? 1 : row.quantity;
			const tierQuantity = Math.max(row.quantity ?? 0, row.tierQuantity ?? 0);

			// we need to get products from api to get all fields since orderrow.product doesn't have all fields
			const product = allProducts?.find(product => product.id === row.product.id) ?? row.product;
			const bundleRows = row.bundleRows?.map(bundleRow => ({
				...bundleRow,
				product: allProducts?.find(product => product.id === bundleRow.productId) ?? bundleRow.product
			}));

			return {
				...row,
				price,
				listPrice,
				purchaseCost,
				tierQuantity,
				quantity,
				id: undefined,
				uuid: Date.now() + idx,
				product,
				bundleRows
			};
		}) as BundleRow[];

	const currentUUID = Date.now();

	return {
		client: getCompactClient(order?.client ?? client),
		contact: getCompactObject(contact),
		createdFromOrderId,
		user: getCompactObject(user),
		clientConnection,
		description,
		notes,
		currentUUID,
		subscriptionMap: {
			[currentUUID]: {
				id: undefined,
				uuid: currentUUID,
				...defaultPeriodSettings,
				orderInterval,
				orderRows,
				locked: false,
				custom: orderCustom,
				isCustomFieldsValid: false,
				currency,
				currencyRate,
				priceList,
				untouchedValues: {
					orderRows
				}
			}
		},
		custom,
		campaign: project,
		stage,
		isAdvancedMode,
		allowOffset: defaultPeriodSettings.allowOffset,
		isEdit: false,
		diffOrders: []
	};
};

type CreateSubscriptionProps = IncomingObjects & Props;

export default (props: CreateSubscriptionProps) => {
	const [allProducts, setAllProducts] = useState<Product[]>();
	const initialState = props.groupState ? props.groupState : useInitialState({ ...props, allProducts });
	initialState.dontWait = props.dontWait;

	useEffect(() => {
		const productIds = new Set<number>();
		Object.keys(initialState.subscriptionMap || {}).forEach(key => {
			const uuid = parseInt(key);
			const subscription = initialState.subscriptionMap?.[uuid];
			Object.keys(initialState.subscriptionMap?.[uuid].orderRows || {}).forEach(orderRowKey => {
				const orderRow = subscription?.orderRows[parseInt(orderRowKey)];
				if (orderRow) {
					if (orderRow.product) {
						productIds.add(orderRow.product.id);
					}
					if (orderRow.bundleRows?.length) {
						for (const bundleRow of orderRow.bundleRows) {
							productIds.add(bundleRow.productId);
						}
					}
				}
			});
		});

		let productPromise: CancelablePromise<Awaited<ReturnType<typeof ProductResource.find>>> | undefined;
		const tooManyProducts = Tools.AppService.getTotals('products') > 4000;
		if (tooManyProducts) {
			productPromise = makeCancelable(ProductResource.find({ id: Array.from(productIds), usePriceLists: true }));
			productPromise.promise
				.then(({ data: allProducts }) => {
					setAllProducts(allProducts);
				})
				.catch(e => logError(e, 'Failed to get products'));
		} else {
			const allProducts = Tools.AppService.getProducts(false, true, true).filter(p => productIds.has(p.id));
			setAllProducts(allProducts);
		}
	}, []);

	return (
		<SubscriptionGroupProvider key={JSON.stringify(allProducts)} initialState={getInitialState(initialState)}>
			<FieldTranslationsProvider types={['clientorderrelation']}>
				<OpenSubscription {...props} />
			</FieldTranslationsProvider>
		</SubscriptionGroupProvider>
	);
};
