import React from 'react';
import getAngularModule, { available, initAngularInjector } from 'App/babel/angularHelpers/getAngularModule';

export const hideCoverup = () => {
	const c = document.getElementById('coverup');
	if (c) {
		c.style.display = 'none';
	}
};

export const showCoverup = () => {
	const c = document.getElementById('coverup');
	if (c) {
		c.style.display = '';
	}
};

type AngularAppProps = {
	children: JSX.Element;
};

const appHtml = `
<div ng-app="upsalesApp" ng-controller="MainCtrl as Main">
	<div id="blur-wrap" ng-class="{'is-blurred': Main.loginWarningOpen}">

		<div id="coverup"></div>

		<div id="react-root"></div>

		<div id="main-wrapper" class="remove-frame" ng-class="{'no-sidebar': Main.noSidebar}">
			<div ng-if="Main.isLoggedIn && !Main.noSidebar && !Main.reactSidebar" id="sidebar" class="text-center">
				<div ng-include="'${require('App/upsales/sidebar.html?file')}'"></div>
			</div>
			<div id="main-view" class="view-container">
				<div ui-view id="main-content"></div>
			</div>
		</div>
		<div id="filter-popup-wrap"></div>
		<div id="up-inline-curtain"></div>
	</div>

	<div up-flash-root></div>
	<div up-voice-root></div>
</div>
`;

let tries = 0;
const timeoutInSeconds = 10;

export const waitForAngular = () =>
	new Promise<void>((resolve, reject) => {
		tries++;
		if (tries >= timeoutInSeconds * 10) {
			return reject();
		}
		setTimeout(() => {
			// Wait for angular and app
			if (!window.angular || !available('AppService')) {
				resolve(waitForAngular());
			} else {
				resolve();
			}
		}, 100);
	});

// TODO: FC this
class AngularApp extends React.Component<AngularAppProps, { angularReady: boolean }> {
	constructor(props: AngularAppProps) {
		super(props);

		this.state = {
			angularReady: false
		};
	}
	componentDidMount() {
		initAngularInjector(); // this will set up the injector when we are sure that the angular module has been initialized
		waitForAngular() // Will probably resolve directly
			.then(() => {
				this.setState({ angularReady: true });
				const AppService = getAngularModule('AppService');

				// eslint-disable-next-line promise/catch-or-return
				AppService.loadedPromise.then(() => {
					if (Tools.FeatureHelper.hasSoftDeployAccess('NEW_BRANDING')) {
						document.body.classList.add('new-branding'); // aka no border since new branding is live
					}
				});
			})
			.catch(() => {
				console.error('Angular was not loaded in time... Application is broken.');
			});
	}
	render = () => (
		<div>
			{/* Render all react components that rely on algular being loaded */}
			{this.state.angularReady ? this.props.children : null}
			{/* Render the angular app */}
			<div dangerouslySetInnerHTML={{ __html: appHtml }} />
		</div>
	);
}

export default AngularApp;
