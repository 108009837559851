import React, { memo, useCallback, useEffect } from 'react';
import BemClass from '@upsales/components/Utils/bemClass';
import { Table, TableHeader, Icon, Text, Tooltip } from '@upsales/components';
import { DefaultButton } from '@upsales/components/Buttons';
import T from 'Components/Helpers/translate';
import {
	OrderRowsProvider,
	OrderRow as OrderRowType,
	useOrderRowsSelector,
	useOrderRowsDispatch
} from './Context/OrderContext';
import OrderRow from './OrderRow';
import Summary from './Summary';

import './OrderRows.scss';
import { hasCMWithRR, hasRRWithCM } from 'App/helpers/salesModelHelpers';
import {
	useVisibleFields,
	useUpdateOrderRows,
	useAddedOrderRows,
	useRemovedOrderRows
} from '../hooks/editListener/editListenerHooks';
import { ClientIdName } from 'App/resources/Model/Client';
import { _setOrderInterval, appendOrderRow, updatedBundleOrderRow } from './Context/OrderContextHelpers';
import openModal from 'App/services/Modal/Modal';
import { useSubscriptionGroupContext } from '../EditSubscription/Context/SubscriptionGroupContext';
import { useIsFirstRender, useSelector } from '../hooks';
import Product from 'App/resources/Model/Product';
import { NewSubscriptionTracker } from 'Helpers/Tracker';

type OrderRowsProps = {
	currency: string;
	isCreatedFromOrder?: boolean;
	stageId?: number;
	priceListId: number;
	disabled?: boolean;
	hideSummary?: boolean;
	orderInterval: number;
	dontFetch?: boolean;
	onChange?: (rows: OrderRowType[]) => void;
	tracker?: typeof NewSubscriptionTracker;
};

const OrderRows = ({
	currency,
	isCreatedFromOrder,
	stageId,
	disabled,
	hideSummary,
	dontFetch,
	orderInterval,
	onChange,
	tracker
}: OrderRowsProps) => {
	const params = useSelector(state => state.App.metadata?.params);

	const {
		state: { client }
	} = useSubscriptionGroupContext();

	const { orderRows } = useOrderRowsSelector(s => ({
		orderRows: s.orderRows || []
	}));
	const dispatch = useOrderRowsDispatch();

	const isFirstRender = useIsFirstRender();

	// THIS IS REALLY STUPID
	useEffect(() => {
		if (!isFirstRender) {
			// We don't want to run _setOrderInterval on the first render because of race conditions
			dispatch(_setOrderInterval(orderInterval));
		}
	}, [orderInterval]);

	// THIS IS REALLY REALLY STUPID
	useEffect(() => {
		if (!isFirstRender) {
			onChange?.(orderRows);
		}
	}, [JSON.stringify(orderRows)]);

	useUpdateOrderRows({ currency });
	useAddedOrderRows({ currency });
	useRemovedOrderRows();

	const bothCmAndRr = hasRRWithCM() || hasCMWithRR();

	const classes = new BemClass('OrderRows');

	const isVisibleField = useVisibleFields('order', true);

	const columns = [];
	if (isVisibleField('product', 'orderrow')) {
		columns.push({
			title: (
				<>
					<Text size="sm" bold>
						{T('subscription.modal.summary.orderRows.product')}
					</Text>
					<Text size="sm" color="red" bold>
						{'*'}
					</Text>
				</>
			)
		});
	}
	if (isVisibleField('quantity', 'orderrow') || isVisibleField('price', 'orderrow')) {
		columns.push({
			title: (
				<Text size="sm" bold>
					{T('subscription.modal.summary.orderRows.quantityPrice')}
				</Text>
			)
		});
	}
	if (
		(params?.UseDiscount ?? true) &&
		(isVisibleField('discount', 'orderrow') || isVisibleField('discountpercent', 'orderrow'))
	) {
		columns.push({
			title: (
				<Text size="sm" bold>
					{T('subscription.modal.summary.orderRows.discount')}
				</Text>
			)
		});
	}
	columns.push({
		title: (
			<Text size="sm" bold className={classes.elem('sumTableHeader').b()}>
				<Tooltip
					title={T('subscription.modal.summary.orderRows.sum.tooltip', {
						interval: T('order.recurringInterval.byMonth.' + orderInterval).toLowerCase()
					})}
				>
					<Icon name="question-circle" />
				</Tooltip>
				{T('subscription.modal.summary.orderRows.sum')}
			</Text>
		)
	});

	const onBundleSave = (uuid: number, product: OrderRowType['product']) => {
		dispatch(updatedBundleOrderRow(uuid, product!, currency));
	};

	const editProductBundle = useCallback(
		(uuid: number) => {
			const props = {
				uuid,
				isAgreement: true,
				order: {
					recurringInterval: orderInterval,
					orderRow: orderRows as Required<OrderRowType>[],
					client: client as ClientIdName,
					currency
				},
				save: (product: Product) => onBundleSave(uuid, product)
			};

			openModal('EditProductBundleOrder', props);
		},
		[orderInterval, orderRows]
	);

	return (
		<div className={classes.mod({ moreHeight: bothCmAndRr }).b()}>
			<Table>
				<TableHeader columns={columns} />
				{orderRows.map((orderRow, i) => {
					return (
						<OrderRow
							key={orderRow.uuid}
							disabled={disabled}
							orderRow={orderRow}
							orderRowsLength={orderRows.length}
							isCreatedFromOrder={isCreatedFromOrder}
							firstRow={i === 0}
							lastRow={i === orderRows.length - 1}
							currency={currency}
							stageId={stageId}
							isNew={orderRow.isNew}
							dontFetch={dontFetch}
							editProductBundle={orderRow => editProductBundle(orderRow.uuid)}
							tracker={tracker}
						/>
					);
				})}
			</Table>
			<div className={classes.elem('addAndSummary').b()}>
				<DefaultButton disabled={disabled} onClick={() => dispatch(appendOrderRow())}>
					<Icon name="plus" />
					<Text>{T('subscription.modal.summary.orderRows.addOrderRow')}</Text>
				</DefaultButton>

				{!hideSummary ? <Summary currency={currency} /> : null}
			</div>
		</div>
	);
};

type Props = {
	onChange?: (rows: OrderRowType[]) => void;
	orderInterval?: number;
	currentRows: OrderRowType[];
} & OrderRowsProps;

const connectProvider = ({
	orderInterval = 1,
	onChange,
	currentRows,
	isCreatedFromOrder,
	hideSummary,
	dontFetch,
	disabled,
	currency,
	stageId,
	priceListId
}: Props) => (
	<OrderRowsProvider orderInterval={orderInterval} initialRows={currentRows} priceListId={priceListId}>
		<OrderRows
			onChange={onChange}
			hideSummary={hideSummary}
			currency={currency}
			isCreatedFromOrder={isCreatedFromOrder}
			stageId={stageId}
			priceListId={priceListId}
			disabled={disabled}
			dontFetch={dontFetch}
			orderInterval={orderInterval}
		/>
	</OrderRowsProvider>
);

export default memo((props: Props) => connectProvider(props));
