import BemClass from '@upsales/components/Utils/bemClass';
import React from 'react';
import { Text } from '@upsales/components';
import './Legend.scss';

type Props = {
	title: string;
	color: string;
};

const Legend = ({ title, color }: Props) => {
	const classes = new BemClass('Legend');

	return (
		<div className={classes.b()}>
			<div
				style={{
					backgroundColor: color
				}}
				className={classes.elem('dot').b()}
			/>
			<Text size="sm">{title}</Text>
		</div>
	);
};

export default Legend;
