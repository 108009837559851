import DataSource from '../resources/DataSource';
import { bisnodeTracker } from 'App/babel/helpers/Tracker';

const SOLIDITET = 'soliditet';

export const MergeAccount = async ({ newAccount, account, dataSource, purchaseType, type }) => {
	if (dataSource.id === SOLIDITET && type && type === 'refresh') {
		const soliditetOptions = {
			updateExisting: false,
			skipProjects: false,
			skipAccountManagers: false,
			skipAddresses: false,
			skipCategories: false
		};
		bisnodeTracker.track(bisnodeTracker.events.UPDATE_SINGLE, { from: 'other' });

		await Tools.SoliditetClient.customer(Tools.AppService.getCustomerId()).refresh(
			account.id,
			[],
			soliditetOptions
		);

		return account.id;
	}

	if (dataSource.id === SOLIDITET && type && type !== 'refresh') {
		bisnodeTracker.track(bisnodeTracker.events.UPDATE_SINGLE, { from: 'other' });
		await Tools.SoliditetClient.customer(Tools.AppService.getCustomerId()).updateMatches({
			buy: [
				{
					action: 'buy',
					id: account.id,
					dunsNo: newAccount[dataSource.idField]
				}
			]
		});

		return account.id;
	}

	if (purchaseType === 'no-data') {
		account.isExternal = false;
		account.users.push(Tools.AppService.getSelf());
		await Tools.Account.customer(Tools.AppService.getCustomerId()).save(account);
		return account.id;
	}

	await DataSource[purchaseType || 'buy']({
		type: 'client',
		integrationId: dataSource.id,
		data: {
			id: newAccount.dunsNo,
			mergeId: account.id,
			customValues: []
		}
	});

	return account.id;
};
