import React from 'react';
import BemClass from '@upsales/components/Utils/bemClass';
import MailType from 'App/resources/Model/Mail';

import './MailBody.scss';

type Props = {
	body: MailType['body'];
};

const MailBody = (props: Props) => {
	const { body } = props;

	const classes = new BemClass('MailBody');

	return (
		<div className={classes.b()}>
			<div dangerouslySetInnerHTML={{ __html: body }} />
		</div>
	);
};

export default MailBody;
