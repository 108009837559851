import {
	AdvancedSearchType,
	FilterNameTypes,
	ListViewMap,
	customFieldEntityMap,
	filterNameMap,
	fixOrderFilters
} from 'App/helpers/advancedSearchHelper';
import { ListView, ListViewFilter } from 'App/resources/AllIWant';
import RequestBuilder, { Sort, comparisonTypes } from 'Resources/RequestBuilder';
import type { AnyAction } from 'redux';
import { AppThunk } from '../index';
import type Client from 'App/resources/Model/Client';
import type Contact from 'App/resources/Model/Contact';
import type Activity from 'App/resources/Model/Activity';
import type Appointment from 'App/resources/Model/Appointment';
import type Opportunity from 'App/resources/Model/Opportunity';
import type Order from 'App/resources/Model/Order';
import { EntityFilter } from 'App/pages/AdvancedSearch/AdvancedSearchEntities';
import { CancelablePromise, makeCancelable } from '@upsales/components/Utils/CancelablePromise';
import { getCustomConfig, isCustom, parseFilters } from 'App/helpers/filterHelper';
import ClientResource from 'App/resources/Client';
import ActivityResource from 'Resources/Activity';
import ContactResource from 'Resources/Contact';
import OrderResource from 'App/resources/Order';
import AppointmentResource from 'Resources/Appointment';
import ClientAttributes from 'App/babel/attributes/Client';
import { type FilterConfig } from 'App/babel/filterConfigs/FilterConfig';
import logError from 'Helpers/logError';
import clientFilters from 'App/babel/filterConfigs/Client';
import contactFilters from 'App/babel/filterConfigs/Contact';
// import activityFilters from 'App/babel/filterConfigs/Activity';
// import appointmentFilters from 'App/babel/filterConfigs/Appointment';
import orderFilters from 'App/babel/filterConfigs/Order';
import Resource from 'Resources/Resource';

export const RESET = '[AdvancedSearch] RESET';
export const MULTI_SETTER = '[AdvancedSearch] MULTI_SETTER';
export const SET_SELECTED_VIEW = '[AdvancedSearch] SET_SELECTED_VIEW';
export const SET_TYPE = '[AdvancedSearch] SET_TYPE';
export const SET_RESULT_TYPE = '[AdvancedSearch] SET_RESULT_TYPE';
export const SET_COLUMNS = '[AdvancedSearch] SET_COLUMNS';
export const SET_SORTING = '[AdvancedSearch] SET_SORTING';
export const SET_RESULTS = '[AdvancedSearch] SET_RESULTS';
export const SET_NUM_RESULTS = '[AdvancedSearch] SET_NUM_RESULTS';
export const SET_FETCHING_RESULTS = '[AdvancedSearch] SET_FETCHING_RESULTS';
export const SET_FILTERS = '[AdvancedSearch] SET_FILTERS';
export const SET_FILTERS_PARTIAL_BY_NAME = '[AdvancedSearch] SET_FILTERS_PARTIAL_BY_NAME';
export const SET_RESULTS_EXPANDED = '[AdvancedSearch] SET_RESULTS_EXPANDED';

const entityFilterMap: { [key in AdvancedSearchType]: { [name: string]: FilterConfig } } = {
	[AdvancedSearchType.accounts]: {},
	[AdvancedSearchType.contacts]: {},
	[AdvancedSearchType.activities]: {},
	[AdvancedSearchType.appointments]: {},
	[AdvancedSearchType.opportunities]: {},
	[AdvancedSearchType.orders]: {}
};

export type AdvancedSearchState = Readonly<{
	selectedView: ListView | null;
	type: AdvancedSearchType;
	resultType: AdvancedSearchType;
	columns: string[];
	sorting: Sort[];
	results: {
		accounts: Client[];
		contacts: Contact[];
		activities: Activity[];
		appointments: Appointment[];
		opportunities: Opportunity[];
		orders: Order[];
	};
	numResults: {
		accounts: number;
		contacts: number;
		activities: number;
		appointments: number;
		opportunities: number;
		orders: number;
	};
	fetchingResults: {
		accounts: boolean;
		contacts: boolean;
		activities: boolean;
		appointments: boolean;
		opportunities: boolean;
		orders: boolean;
	};
	filters: EntityFilter;
	resultsExpanded: boolean;
}>;

export const initialState: AdvancedSearchState = {
	selectedView: null,
	type: AdvancedSearchType.accounts,
	resultType: AdvancedSearchType.accounts,
	columns: [],
	sorting: [],
	results: {
		accounts: [],
		contacts: [],
		activities: [],
		appointments: [],
		opportunities: [],
		orders: []
	},
	numResults: {
		accounts: 0,
		contacts: 0,
		activities: 0,
		appointments: 0,
		opportunities: 0,
		orders: 0
	},
	fetchingResults: {
		accounts: false,
		contacts: false,
		activities: false,
		appointments: false,
		opportunities: false,
		orders: false
	},
	filters: {
		ACCOUNT: {},
		CONTACT: {},
		ACTIVITY: {},
		APPOINTMENT: {},
		OPPORTUNITY: {},
		ORDER: {}
	},
	resultsExpanded: false
};

const defaultColumns = {
	[AdvancedSearchType.accounts]: ['name', 'users', 'Address_Visit_address'],
	[AdvancedSearchType.contacts]: ['name', 'title', 'client'],
	[AdvancedSearchType.activities]: ['date', 'client', 'description', 'contacts', 'users', 'activityType'],
	[AdvancedSearchType.appointments]: ['date', 'client', 'description', 'contact', 'users', 'activityType'],
	[AdvancedSearchType.opportunities]: ['date', 'client', 'description', 'value', 'stage'],
	[AdvancedSearchType.orders]: ['date', 'client', 'description', 'value', 'stage']
} satisfies Record<AdvancedSearchType, string[]>;

const defaultSorting = {
	[AdvancedSearchType.accounts]: [{ attribute: 'name', ascending: true }],
	[AdvancedSearchType.contacts]: [{ attribute: 'name', ascending: true }],
	[AdvancedSearchType.activities]: [{ attribute: 'date', ascending: false }],
	[AdvancedSearchType.appointments]: [{ attribute: 'date', ascending: false }],
	[AdvancedSearchType.opportunities]: [{ attribute: 'date', ascending: false }],
	[AdvancedSearchType.orders]: [{ attribute: 'date', ascending: false }]
} satisfies Record<AdvancedSearchType, Sort[]>;

const entityToResource: { [key in AdvancedSearchType]: Resource } = {
	[AdvancedSearchType.accounts]: ClientResource,
	[AdvancedSearchType.contacts]: ContactResource,
	[AdvancedSearchType.activities]: ActivityResource,
	[AdvancedSearchType.appointments]: AppointmentResource,
	[AdvancedSearchType.opportunities]: OrderResource,
	[AdvancedSearchType.orders]: OrderResource
};

const entityFilterModifiers: { [key in AdvancedSearchType]?: (rb: RequestBuilder) => void } = {
	[AdvancedSearchType.accounts]: rb => {
		rb.addFilter(ClientAttributes.isExternal, comparisonTypes.Equals, 0);
	}
};

// Move to actions file
let fetchTimeout: NodeJS.Timeout | null = null;
let fetchPromise: CancelablePromise<any> | null = null;
const FETCH_DEBOUNCE_TIME = 500;
const PAGE_LIMIT = 50;

const fetchData = (): AppThunk => (dispatch, getState) => {
	if (fetchTimeout) {
		clearTimeout(fetchTimeout);
		fetchTimeout = null;
		fetchPromise?.cancel();
	}
	const { resultType, sorting, type, filters } = getState().AdvancedSearch;
	const customFields = getState().App.customFields[customFieldEntityMap[type]] || [];
	const resource = entityToResource[resultType];
	const filterName = filterNameMap[type];
	const rb = parseFilters(filters[filterName], filterName, null, null, {
		getConfig: name => {
			if (isCustom(name)) {
				return getCustomConfig(name, customFields, type);
			}
			return entityFilterMap[type][name] as FilterConfig;
		}
	});

	if (sorting.length) {
		rb.addSort(sorting[0].attribute, sorting[0].ascending);
	}
	entityFilterModifiers[resultType]?.(rb);
	rb.limit = PAGE_LIMIT;
	fetchPromise = makeCancelable(resource.find(rb.build()));

	fetchPromise.promise
		.then(({ data, metadata }) => {
			const { results, resultType, numResults } = getState().AdvancedSearch;
			dispatch({
				type: SET_NUM_RESULTS,
				numResults: { ...numResults, [AdvancedSearchType[resultType]]: metadata.total }
			});
			dispatch({ type: SET_RESULTS, results: { ...results, [AdvancedSearchType[resultType]]: data } });
		})
		.catch((err: Error) => {
			logError(err);
		})
		.finally(() => {
			const { resultType, fetchingResults } = getState().AdvancedSearch;
			dispatch({
				type: SET_FETCHING_RESULTS,
				fetchingResults: { ...fetchingResults, [AdvancedSearchType[resultType]]: false }
			});
		});
	return fetchPromise;
};

const fetchDataDebounced = (): AppThunk => dispatch => {
	if (fetchTimeout) {
		clearTimeout(fetchTimeout);
		fetchTimeout = null;
		fetchPromise?.cancel();
	}
	fetchTimeout = setTimeout(() => {
		dispatch(fetchData());
	}, FETCH_DEBOUNCE_TIME);
};

const fetch =
	(debounceFetch = false): AppThunk =>
	(dispatch, getState) => {
		const { fetchingResults, resultType } = getState().AdvancedSearch;
		dispatch({
			type: SET_FETCHING_RESULTS,
			fetchingResults: { ...fetchingResults, [AdvancedSearchType[resultType]]: true }
		});
		if (debounceFetch) {
			dispatch(fetchDataDebounced());
		} else {
			dispatch(fetchData());
		}
	};
export const setFilters =
	(filters: AdvancedSearchState['filters'], debounceFetch = false): AppThunk =>
	dispatch => {
		dispatch({ type: SET_FILTERS, filters });
		dispatch(fetch(debounceFetch));
	};

export const setPartialFilterByName =
	(
		filterNameType: FilterNameTypes,
		filterName: string,
		filter: Partial<Optional<ListViewFilter, 'filterName'>>,
		debounceFetch = false
	): AppThunk =>
	dispatch => {
		dispatch({ type: SET_FILTERS_PARTIAL_BY_NAME, filter, filterName, filterNameType });
		dispatch(fetch(debounceFetch));
	};

export const setSorting =
	(sorting: AdvancedSearchState['sorting']): AppThunk =>
	dispatch => {
		dispatch({ type: SET_SORTING, sorting });
		dispatch(fetch());
	};

export const setSelectedView =
	(selectedView: ListView): AppThunk =>
	dispatch => {
		// SET_SELECTED_VIEW will set columns and sorting
		dispatch({ type: SET_SELECTED_VIEW, selectedView });

		// This is just a simple version of selectView in the old advancedSearch controller. This will change later on
		if (selectedView.filters.length) {
			const [firstFilter] = selectedView.filters;
			if (firstFilter?.value && firstFilter?.filterName === 'advancedSearch') {
				const filters = firstFilter.value as EntityFilter;
				/*
							Fix product filters
							The old AS saved the product filters as an array of objects with both products and productCategories. This aint right
						*/
				fixOrderFilters(filters);

				dispatch(setFilters(filters));
			} else {
				// Old way of storing filters
				// Have not found any case of this yet
			}
		} else {
			dispatch(setFilters({ ...initialState.filters }));
		}
	};

// If the resultType changes we need to change columns and sorting
export const setResultType =
	(resultType: AdvancedSearchState['resultType']): AppThunk =>
	(dispatch, getState) => {
		const { selectedView, type, resultType: prevResultType } = getState().AdvancedSearch;
		if (prevResultType === resultType) {
			return;
		}
		// If type was changed to the main search type we want to set columns and sorting to the selectedView
		if (resultType === type && selectedView) {
			dispatch({ type: MULTI_SETTER, resultType, columns: selectedView.columns, sorting: selectedView.sorting });
		} else {
			// If type was changed to a sub search type we want to set columns and sorting to the default columns and sorting for that type
			dispatch({
				type: MULTI_SETTER,
				resultType,
				columns: defaultColumns[resultType],
				sorting: defaultSorting[resultType]
			});
		}
	};

// This will "reset" the state to work with another type
export const setType =
	(type: AdvancedSearchState['type']): AppThunk =>
	(dispatch, getState) => {
		const App = getState().App;
		const viewToSelect = App.listViews[ListViewMap[type]]?.[0] || null;

		dispatch({ type: SET_TYPE, newType: type });
		// We don't want to use setResultType here since this resets columns and sorting (setSelectedView will set these)
		dispatch({
			type: MULTI_SETTER,
			resultsExpanded: false,
			resultType: type,
			results: { ...initialState.results },
			numResults: { ...initialState.numResults }
		});
		dispatch(setSelectedView(viewToSelect));
	};
export const init = () => {
	entityFilterMap[AdvancedSearchType.accounts] = clientFilters();
	entityFilterMap[AdvancedSearchType.contacts] = contactFilters();
	// entityFilterMap[AdvancedSearchType.activities] = activityFilters();
	// entityFilterMap[AdvancedSearchType.appointments] = appointmentFilters();
	entityFilterMap[AdvancedSearchType.opportunities] = orderFilters();
	entityFilterMap[AdvancedSearchType.orders] = orderFilters();
};

const ACTION_HANDLERS: { [k: string]: (state: AdvancedSearchState, a: AnyAction) => AdvancedSearchState } = {
	[RESET]: () => ({ ...initialState }),
	[MULTI_SETTER]: (state, { type, ...updates }) => ({ ...state, ...updates }),
	[SET_SELECTED_VIEW]: (state, { selectedView }) => ({
		...state,
		selectedView,
		columns: selectedView.columns,
		sorting: selectedView.sorting
	}),
	[SET_TYPE]: (state, { newType }) => ({ ...state, type: newType }),
	[SET_RESULT_TYPE]: (state, { resultType }) => ({ ...state, resultType }),
	[SET_COLUMNS]: (state, { columns }) => ({ ...state, columns }),
	[SET_SORTING]: (state, { sorting }) => ({ ...state, sorting }),
	[SET_RESULTS]: (state, { results }) => ({ ...state, results }),
	[SET_NUM_RESULTS]: (state, { numResults }) => ({ ...state, numResults }),
	[SET_FETCHING_RESULTS]: (state, { fetchingResults }) => ({ ...state, fetchingResults }),
	[SET_FILTERS]: (state, { filters }) => ({ ...state, filters }),
	[SET_FILTERS_PARTIAL_BY_NAME]: (state, { filter, filterName, filterNameType }) => ({
		...state,
		filters: {
			...state.filters,
			[filterNameType]: {
				...state.filters[filterNameType as FilterNameTypes],
				[filterName]: {
					...state.filters[filterNameType as FilterNameTypes][filterName],
					...filter
				}
			}
		}
	}),
	[SET_RESULTS_EXPANDED]: (state, { resultsExpanded }) => ({ ...state, resultsExpanded })
};

export default (state = initialState, action: AnyAction) => {
	const handler = ACTION_HANDLERS[action.type];
	return handler ? handler(state, action) : state;
};
