import Resource from 'Resources/Resource';
import T from 'Components/Helpers/translate';
import { AppState } from 'Store/reducers/AppReducer';

const skipTranslate = ['naicsCode'];

class StaticValue extends Resource {
	constructor() {
		super('staticValues');
	}

	get(type: string): Promise<{
		data: AppState['staticValues'];
	}> {
		return this._getRequest(type, { methodName: 'get' })
			.then(r => r.data)
			.then(({ data }: { data: AppState['staticValues'] }) => {
				if (type !== 'all') {
					return { data };
				}
				return {
					data: Object.keys(data).reduce<AppState['staticValues']>(
						(res, type: string) => {
							res[type] = data[type].map(item => ({
								...item,
								name: skipTranslate.includes(type) ? item.name : T(item.name)
							}));
							return res;
						},
						{ languages: [] }
					)
				};
			});
	}
}

const resource = new StaticValue();

export default resource;
