// This is legacy buttons render type use button!
import React from 'react';
import ButtonRenderType from './ButtonRenderType';

const ButtonsRenderType = ({ row, config, object, getUser, runStandardIntegration, runClick }) => {
	const buttonList = row.options.map((button, i) => (
		<ButtonRenderType
			key={'btn-' + i}
			row={button}
			{...{ config, object, getUser, runStandardIntegration, runClick: row => runClick(row, i) }}
		/>
	));

	return <div className="widget-buttons-wrapper">{buttonList}</div>;
};

export default ButtonsRenderType;
