import { makeCancelable } from 'Helpers/promise';
import { useCallback } from 'react';

const Debounce = function (func, wait = 100) {
	let timeout;

	return function (...args) {
		clearTimeout(timeout);
		timeout = setTimeout(() => {
			func.apply(this, args);
		}, wait);
	};
};

export const DebouncePromise = function (func, wait = 100) {
	let timeout;
	let resolve;
	let cancelablePromise;
	return function (...args) {
		clearTimeout(timeout);
		timeout = setTimeout(() => {
			if (!cancelablePromise.isCanceled()) {
				const result = func(...args);
				resolve(result);
			}
			resolve = undefined;
		}, wait);

		cancelablePromise = makeCancelable(new Promise(r => (resolve = r)));
		return cancelablePromise;
	};
};

export const useDebounce = (fn, dependencies, wait = 100) => useCallback(Debounce(fn, wait), dependencies);
export const useDebouncePromise = (fn, dependencies, wait = 100) =>
	useCallback(DebouncePromise(fn, wait), dependencies);

export default Debounce;
