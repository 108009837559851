import React from 'react';
import bemClass from '@upsales/components/Utils/bemClass';
import { TableRow } from '@upsales/components';
import './TableWidget.scss';
import Cell from './Cell';
import { RCWidgetData } from 'Resources/ReportWidget';

type KeyLabelSet = {
	key: string;
	label: string;
	tooltip?: string;
	dotColor?: string;
};

type TotalsProps = {
	classes: bemClass;
	columnOrder: KeyLabelSet[];
	totals: Map<string, { displayValue: string | undefined | Function; rawValue: string | number | null }>;
	colors: { [k: string]: { label: string; color: string } };
	currency: RCWidgetData['currency'];
};

const Totals = ({ classes, columnOrder, totals, colors, currency }: TotalsProps) => {
	return (
		<TableRow className={classes.elem('totalRow').b()}>
			{columnOrder.map(column => (
				<Cell
					classes={classes}
					selectable={false}
					key={column.key}
					rowKey={'total'}
					columnKey={column.key}
					value={totals.get(column.key) ?? { displayValue: '0', rawValue: null }}
					dotColor={column.dotColor}
					colors={colors}
					currency={currency}
				/>
			))}
		</TableRow>
	);
};

export default Totals;
