import { useEffect, useState, useRef } from 'react';
import RequestBuilder from 'Resources/RequestBuilder';
import useForceRender from './useForceRender';
import { CancelablePromise, makeCancelable } from 'Helpers/promise';
import logError from 'Helpers/logError';

type Res<T> = { data: T[]; metadata: { total: number } };
export type FetcherFunction<TProps, TReturn extends {}> = (
	filters: RequestBuilder,
	props: TProps
) => Promise<Res<TReturn>>;

type BroadcastFunction<ResType> = (props: {
	eventName: string;
	data: ResType[];
	item: ResType;
	refetch: () => void;
	changedAttributeKeys?: readonly string[];
}) => void;

export type GetDataProps<FetcherProps, ResType extends {}> = {
	onFetched?: (res: Res<ResType>) => void;
	broadcastTypes?: readonly string[];
	limit?: number;
	offset?: number;
	fetcher: FetcherFunction<FetcherProps, ResType>;
	fetcherProps: FetcherProps;
	broadcastWaitForIndex?: boolean;
	onBroadcast?: BroadcastFunction<ResType> | { [k in 'updated' | 'deleted' | 'added']?: BroadcastFunction<ResType> };
};

const useGetData = <FetcherProps, ResType extends {}>({
	fetcher,
	onFetched = () => {},
	broadcastTypes = [],
	onBroadcast,
	fetcherProps,
	broadcastWaitForIndex = true,
	limit = 50,
	offset = 0
}: GetDataProps<FetcherProps, ResType>) => {
	const promiseRef = useRef<CancelablePromise | null>(null);

	const [loading, setLoading] = useState(false);

	const [data, setData] = useState<ResType[]>([]);
	const [metadata, setMetadata] = useState<Res<ResType>['metadata']>({ total: 0 });

	const { triggerRender: refetch, forced } = useForceRender();

	const attachBroadcastFn = (type: string, callback: BroadcastFunction<ResType> | (() => void)) => {
		return Tools.$rootScope.$on(type, (e, item, changedAttributeKeys) => {
			setLoading(true);
			return new Promise(resolve => {
				setTimeout(
					() => {
						callback({ eventName: type, data, item, refetch, changedAttributeKeys });
						resolve(null);
					},
					broadcastWaitForIndex ? 1000 : 0
				);
			}).then(() => {
				setLoading(false);
			});
		});
	};

	// Set up listeners
	useEffect(() => {
		let listeners: (() => void)[] = [];
		if (onBroadcast) {
			if (typeof onBroadcast === 'function') {
				listeners = broadcastTypes.map(type => attachBroadcastFn(type, onBroadcast));
			} else {
				const onUpdate = onBroadcast.updated ?? refetch;
				const onAdd = onBroadcast.added ?? refetch;
				const onDelete = onBroadcast.deleted ?? refetch;

				broadcastTypes.forEach(type => {
					const subtype = type.split('.')[1];
					if (subtype === 'updated') {
						listeners.push(attachBroadcastFn(type, onUpdate));
					} else if (subtype === 'added') {
						listeners.push(attachBroadcastFn(type, onAdd));
					} else if (subtype === 'deleted') {
						listeners.push(attachBroadcastFn(type, onDelete));
					} else {
						listeners.push(attachBroadcastFn(type, refetch));
					}
				});
			}
		} else {
			listeners = broadcastTypes.map(type => attachBroadcastFn(type, refetch));
		}
		return () => {
			listeners.forEach(fn => fn());
		};
	}, []);

	// Fetch data
	useEffect(() => {
		if (promiseRef.current !== null) {
			promiseRef.current.cancel();
			promiseRef.current = null;
		}

		setLoading(true);

		const rb = new RequestBuilder();
		rb.limit = limit;
		rb.offset = offset;

		promiseRef.current = makeCancelable(fetcher(rb, fetcherProps));
		promiseRef.current.promise
			.then(a => {
				setLoading(false);
				setData(a.data);
				setMetadata(a.metadata);
				onFetched(a);
			})
			.catch(e => {
				setLoading(false);
				logError(e, 'Failed to load data');
			});

		return () => {
			promiseRef.current?.cancel();
		};
	}, [forced]);

	return { loading, data, metadata, refetch };
};

export default useGetData;
