import './Animate.scss';
import React, { useMemo } from 'react';
import BemClass from '@upsales/components/Utils/bemClass';

export enum Speed {
	fast = 100,
	normal = 300,
	slow = 800
}

type Props = {
	delayInMs?: number;
	delayOutInMs?: number;
	visible?: boolean;
	className?: string;
	children: React.ReactElement | React.ReactElement[];
	mods?: { [mod: string]: boolean };
	bounce?: boolean;
	speed?: keyof typeof Speed;
	disabled?: boolean;
	height?: boolean;
	maxHeight?: number; // used to set an approximate height when animating height
};

const Animate = ({
	delayInMs = 0,
	delayOutInMs,
	visible = false,
	bounce = false,
	children,
	mods,
	speed = 'normal',
	disabled = false,
	height = false,
	maxHeight = 50
}: Props) => {
	const style = {
		'--delay': `${delayInMs}ms`,
		'--delay-out': `${delayOutInMs ?? delayInMs}ms`,
		'--max-height': `${maxHeight}px`
	};
	const c = useMemo(
		() =>
			React.Children.map(children || [], child => {
				const classes = new BemClass('Animate', child.props.className).mod({
					...mods,
					visible,
					bounce,
					disabled,
					height,
					[`speed-${speed}`]: true
				});
				return React.cloneElement(child, {
					...child.props,
					className: classes.b(),
					style: { ...(child.props.style || {}), ...style }
				});
			}),
		[children]
	);
	return <>{c}</>;
};

export default Animate;
