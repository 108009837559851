import Tracker from './Tracker';

const bisnodeTracker = new Tracker(
	'bisnode',
	{
		BUY_SINGLE: 'buySingle',
		BUY_MULTI: 'buyMultiple',
		BUY_ALL_GROUP: 'buyAllGroup',
		UPDATE_SINGLE: 'updateSingle',
		UPDATE_MULTI: 'updateMultiple',
		MERGE_SINGLE: 'mergeSingle'
	},
	false
);

export default bisnodeTracker;
