import { AnyAction } from 'redux';
import { debounce } from 'lodash';
import { EVENT_TIMELINE_ENTITY_TYPES } from 'App/babel/enum/timeline';
import { findAndReplaceItem } from 'Store/helpers/array';
import { type File } from 'App/resources/Model/Comment';
import { useCustomFields } from 'App/components/hooks/appHooks';
import { openDrawer } from 'Services/Drawer';
import ActivityResource from 'Resources/Activity';
import Agreement from 'App/resources/Model/Agreement';
import AgreementResource from 'Resources/Agreement';
import Client from 'App/resources/Model/Client';
import ClientResource from 'App/resources/Client';
import CommentResource from 'App/babel/resources/Comment';
import EventAttributes from 'App/babel/attributes/EventAttributes';
import EventResource from 'App/resources/Event';
import FileResource from 'App/babel/resources/File';
import logError from 'App/babel/helpers/logError';
import LZString from 'lz-string';
import OrderResource from 'App/resources/Order';
import ProjectPlanResource from 'Resources/ProjectPlan';
import React, { useReducer, useContext, createContext } from 'react';
import RequestBuilder, { comparisonTypes } from 'Resources/RequestBuilder';
import type ProjectPlan from 'App/resources/Model/ProjectPlan';
import type Activity from 'App/resources/Model/Activity';
import type Comment from 'App/resources/Model/Comment';
import type CustomField from 'App/resources/Model/CustomField';
import type Event from 'App/resources/Model/Event';
import type Order from 'App/resources/Model/Order';
import FileAttributes from 'App/babel/attributes/FileAttributes';
import { PROJECT_FILE_ENTITY } from './Helpers/Helpers';

//todo: Fix different actions in another ticket
enum Actions {
	SET_STATE = '[EditProjectPlanContext] SET_STATE'
}

const ACTION_HANDLERS: {
	[key: string]: (s: State, a: AnyAction) => State;
} = {
	[Actions.SET_STATE]: (state, updates) => ({ ...state, ...updates })
};

const COMMENT_LIMIT = 20;

type Dispatch = (action: any) => void;

type ProviderProps = {
	initialState?: Partial<State>;
	children: React.ReactNode;
};

type State = {
	agreement: Agreement | null;
	client: Client | null;
	comment: string;
	customFields: CustomField[];
	errorMessages: { [field: string]: string | null };
	events: (Event & { files?: File[] })[];
	files: File[];
	fileAmount: number;
	hasMoreEvents: boolean;
	hasUnsavedChanges: boolean;
	offset: number;
	order: Order | null;
	projectPlan: ProjectPlan | null;
	savedHash: string;
	savingComment: boolean;
	tasks: Activity[];
	tempStartDate: Date | null;
	tempEndDate: Date | null;
	tempUser: { id: number; name: string; title: string } | null;
	todoDrawerOpen: boolean;
	savingProjectPlan: boolean;
};

const reducer = (state: any, action: AnyAction) => {
	const handler = ACTION_HANDLERS[action.type];
	return handler ? handler(state, action) : state;
};

const EditProjectPlanContext = createContext<
	| {
			state: State;
			dispatch: Dispatch;
	  }
	| undefined
>(undefined);

export function EditProjectPlanProvider({ initialState: partialState, children }: ProviderProps) {
	const customFields = useCustomFields('projectPlan');
	const initialState = {
		projectPlan: null,
		order: null,
		tasks: [],
		savedHash: '',
		hasUnsavedChanges: false,
		errorMessages: {},
		customFields,
		events: [],
		files: [],
		fileAmount: 0,
		comment: '',
		hasMoreEvents: false,
		offset: 0,
		tempStartDate: null,
		tempEndDate: null,
		todoDrawerOpen: false,
		savingComment: false,
		savingProjectPlan: false,
		priorities: [],
		statuses: [],
		...partialState
	};
	const [state, dispatch] = useReducer(reducer, initialState);
	const value = { state, dispatch };

	return <EditProjectPlanContext.Provider value={value}>{children}</EditProjectPlanContext.Provider>;
}

function getProjectPlanHash(
	projectPlan: PartialPick<
		Omit<ProjectPlan, 'client'>,
		| 'name'
		| 'projectPlanStage'
		| 'projectPlanPriority'
		| 'projectPlanStatus'
		| 'startDate'
		| 'endDate'
		| 'notes'
		| 'finishedTasks'
		| 'openTasks'
		| 'user'
		| 'contact'
		| 'order'
		| 'custom'
	> & { client: ProjectPlan['client'] | null }
) {
	const custom = (projectPlan.custom ?? [])
		.filter(({ value }) => value !== null)
		.sort((a, b) => a.fieldId - b.fieldId)
		.map(({ fieldId, value }) => `${fieldId}:${value}`);
	const compareObject = {
		name: projectPlan.name,
		startDate: projectPlan.startDate,
		endDate: projectPlan.endDate,
		notes: projectPlan.notes,
		projectPlanStageId: projectPlan.projectPlanStage?.id ?? null,
		projectPlanPriorityId: projectPlan.projectPlanPriority?.id ?? null,
		projectPlanStatusId: projectPlan.projectPlanStatus?.id ?? null,
		userId: projectPlan.user?.id ?? null,
		clientId: projectPlan.client?.id ?? null,
		contactId: projectPlan.contact?.id ?? null,
		orderId: projectPlan.order?.id ?? null,
		custom
	};
	return LZString.compressToBase64(JSON.stringify(compareObject));
}

const debouncedSave = debounce(async (updatedProjectPlan: ProjectPlan) => {
	try {
		await ProjectPlanResource.save(updatedProjectPlan);
	} catch (error) {
		logError(error, 'Could not save project plan');
	}
}, 1000);

export const setSavingProjectPlan = (dispatch: Dispatch, state: State) => (savingProjectPlan: boolean) => {
	dispatch({ type: Actions.SET_STATE, savingProjectPlan });
};

const createNewProjectPlan = (dispatch: Dispatch, state: State) => () => {
	//todo: stage, priority, status should all be fetched from the server and set to the Default as set by the user
	const projectPlan = {
		name: '',
		projectPlanStage: { id: 1, title: 'To Do', name: 'To Do' },
		projectPlanPriority: { id: 1, name: 'projectPlan.defaultPriority.low', category: 'LOW', isDefault: true },
		projectPlanStatus: { id: 1, name: 'projectPlan.defaultStatus.onTrack', category: 'ON_TRACK', isDefault: true },
		startDate: null,
		endDate: null,
		notes: '',
		finishedTasks: 0,
		openTasks: 0,
		user: null,
		client: null,
		contact: null,
		order: null,
		custom: []
	};
	const savedHash = getProjectPlanHash(projectPlan);
	dispatch({ type: Actions.SET_STATE, projectPlan, savedHash });
};

export const getTasks = (dispatch: Dispatch, state: State) => async (projectPlanId: number) => {
	const rb = new RequestBuilder();

	rb.addFilter({ field: 'projectPlan.id' }, comparisonTypes.Equals, projectPlanId);
	rb.addSort('priority', false);
	rb.addSort('date', true);

	try {
		const { data: tasks } = await ActivityResource.find(rb.build());
		dispatch({ type: Actions.SET_STATE, tasks });
	} catch (error) {
		logError(error, 'Could not fetch project plan tasks');
	}
};

export const getAgreement = (dispatch: Dispatch, state: State) => async (agreementId: number) => {
	try {
		const { data: agreement } = await AgreementResource.get(agreementId);
		dispatch({ type: Actions.SET_STATE, agreement });
	} catch (error) {
		logError(error, 'Could not fetch agreement');
	}
};

export const getClient = (dispatch: Dispatch, state: State) => async (clientId: number) => {
	try {
		const { data: client } = await ClientResource.get(clientId);
		dispatch({ type: Actions.SET_STATE, client });
	} catch (error) {
		logError(error, 'Could not fetch client');
	}
};

export const getOrder = (dispatch: Dispatch, state: State) => async (orderId: number) => {
	try {
		const { data: order } = await OrderResource.get(orderId);
		dispatch({ type: Actions.SET_STATE, order });

		if (order.agreement) {
			getAgreement(dispatch, state)(order.agreement.id);
		}
	} catch (error) {
		logError(error, 'Could not fetch order');
	}
};

export const getProjectPlan = (dispatch: Dispatch, state: State) => async (projectPlanId: number) => {
	try {
		const { data: projectPlan } = await ProjectPlanResource.get(projectPlanId);
		const savedHash = getProjectPlanHash(projectPlan);

		dispatch({ type: Actions.SET_STATE, projectPlan, savedHash });

		if (projectPlan.order) {
			getOrder(dispatch, state)(projectPlan.order.id);
		}

		if (projectPlan.client) {
			getClient(dispatch, state)(projectPlan.client.id);
		}
	} catch (error) {
		logError(error, 'Could not fetch project plan');
	}
};

export const updateTodo = (dispatch: Dispatch, state: State) => async (updatedTodo: Activity) => {
	const updatedTasks = findAndReplaceItem(state.tasks, updatedTodo, { id: updatedTodo.id });
	dispatch({ type: Actions.SET_STATE, tasks: updatedTasks });
};

export const onToggleTaskCheckbox = (dispatch: Dispatch, state: State) => async (task: Activity) => {
	try {
		const updateState = updateTodo(dispatch, state);
		const newTodo = { ...task, closeDate: !task.closeDate ? new Date() : null };
		updateState(newTodo);
		const { data: finalTodo } = await ActivityResource.save(newTodo);
		updateState(finalTodo);
	} catch (error) {
		logError(error, 'Could not save todo');
	}
};

export const onToggleTaskPriority = (dispatch: Dispatch, state: State) => async (task: Activity) => {
	try {
		const updateState = updateTodo(dispatch, state);
		const newPriority = task.priority === 0 ? 3 : 0;
		const newTodo: Activity = { ...task, priority: newPriority };
		updateState(newTodo);
		const { data: finalTodo } = await ActivityResource.save(newTodo);
		updateState(finalTodo);
	} catch (error) {
		logError(error, 'Could not change todo priority');
	}
};

export const saveNewProject = (dispatch: Dispatch, state: State) => async (projectPlan: ProjectPlan) => {
	setSavingProjectPlan(dispatch, state)(true);
	try {
		const { data: savedProjectPlan } = await ProjectPlanResource.save(projectPlan);
		const savedHash = getProjectPlanHash(savedProjectPlan);
		dispatch({ type: Actions.SET_STATE, projectPlan: savedProjectPlan, savedHash });
	} catch (error) {
		logError(error, 'Could not create a new project plan');
	}
	setSavingProjectPlan(dispatch, state)(false);
};

export const saveAndGetTasks = (dispatch: Dispatch, state: State) => async (projectPlan: ProjectPlan) => {
	setSavingProjectPlan(dispatch, state)(true);
	try {
		await ProjectPlanResource.save(projectPlan);
		await new Promise(r => setTimeout(r, 1000)); // Lets give index some time
		await getTasks(dispatch, state)(projectPlan.id);
	} catch (error) {
		logError(error, 'Could not create a new project plan');
	}
	setSavingProjectPlan(dispatch, state)(false);
};

export const onProjectPlanChange =
	(dispatch: Dispatch, state: State) =>
	async (projectPlan: ProjectPlan, isValid: boolean, errorMessages: { [field: string]: string | null }) => {
		const currentHash = getProjectPlanHash(projectPlan);
		const savedHash = isValid ? currentHash : state.savedHash;
		const isDirty = currentHash !== state.savedHash;
		const hasUnsavedChanges = !isValid && isDirty;

		dispatch({ type: Actions.SET_STATE, projectPlan, isValid, savedHash, hasUnsavedChanges, errorMessages });

		const stateUserId = state.projectPlan?.user?.id;
		const projectPlanUserId = projectPlan.user?.id;
		const userChanged = stateUserId && projectPlanUserId && stateUserId !== projectPlanUserId;

		if (projectPlan.id && isValid && isDirty) {
			if (userChanged) {
				debouncedSave.cancel();
				saveAndGetTasks(dispatch, state)(projectPlan);
			} else {
				debouncedSave(projectPlan);
			}
		}
	};

export const saveComment = (dispatch: Dispatch, state: State) => () => {
	const { projectPlan, comment } = state;

	if (!comment.trim().length) {
		return;
	}

	dispatch({ type: Actions.SET_STATE, savingComment: true });

	const { client, id } = projectPlan!;
	const user = Tools.AppService.getSelf();

	CommentResource.save({
		user: { id: user?.id, name: user?.name },
		description: comment.replace(/\n\n/g, '\n'),
		client: client ? { id: client.id, name: client.name } : undefined,
		projectPlan: { id: id }
	})
		.then(() => {
			dispatch({ type: Actions.SET_STATE, savingComment: false });
			dispatch({ type: Actions.SET_STATE, comment: '' });
		})
		.catch(err => {
			logError(err, 'Error saving project plam comment');
			dispatch({ type: Actions.SET_STATE, savingComment: false });
		});
};

const fetchFiles = async (projectPlanId: number, limit?: number) => {
	const rb = new RequestBuilder();
	rb.addFilter(FileAttributes.entity, comparisonTypes.Equals, PROJECT_FILE_ENTITY);
	rb.addFilter(FileAttributes.entityId, comparisonTypes.Equals, projectPlanId);
	if (limit !== undefined) {
		rb.limit = limit;
	}

	const { data, metadata } = await FileResource.find(rb.build());

	return { data, metadata };
};
export const getFiles = (dispatch: Dispatch) => async (projectPlanId: number) => {
	const { data: files } = await fetchFiles(projectPlanId);
	dispatch({ type: Actions.SET_STATE, files });
};

export const getFileAmount = (dispatch: Dispatch) => async (projectPlanId: number) => {
	const { metadata } = await fetchFiles(projectPlanId, 0);
	dispatch({ type: Actions.SET_STATE, fileAmount: metadata.total });
};

const fetchEvents = async (state: State, newOffset?: number) => {
	const { projectPlan, offset } = state;
	const commentRb = new RequestBuilder();
	commentRb.addFilter({ field: 'projectPlanId' }, comparisonTypes.Equals, projectPlan!.id);

	commentRb.limit = COMMENT_LIMIT;
	commentRb.offset = newOffset ?? offset;

	const { data: comments, metadata } = await CommentResource.find(commentRb.build());

	if (!comments.length) {
		return { data: [], metadata };
	}

	const rb = new RequestBuilder();
	rb.addFilter(EventAttributes.entityType, comparisonTypes.Equals, EVENT_TIMELINE_ENTITY_TYPES.COMMENT);
	rb.addFilter(
		EventAttributes.entityId,
		comparisonTypes.Equals,
		comments.map((c: Comment) => c.id)
	);
	const { data: events } = await EventResource.find(rb.build());

	events.forEach((event: State['events'][number]) => {
		const comment = comments.find((c: Comment) => c.id === event.entityId);
		if (comment) {
			event.files = comment.files;
		}
	});

	return { data: events, metadata };
};

export const getEvents = (dispatch: Dispatch, state: State) => async () => {
	const { data: events, metadata } = await fetchEvents(state);
	dispatch({ type: Actions.SET_STATE, events });
	if (metadata.total > metadata.offset + metadata.limit) {
		dispatch({ type: Actions.SET_STATE, hasMoreEvents: true });
	}
};

export const getMoreEvents = (dispatch: Dispatch, state: State) => async () => {
	const { events, offset } = state;
	const { data: newEvents, metadata } = await fetchEvents(state, offset + COMMENT_LIMIT);

	if (!newEvents.length) {
		return;
	}

	dispatch({ type: Actions.SET_STATE, events: [...events, ...newEvents] });
	if (metadata.total > metadata.offset + metadata.limit) {
		dispatch({ type: Actions.SET_STATE, hasMoreEvents: true });
	} else {
		dispatch({ type: Actions.SET_STATE, hasMoreEvents: false });
	}
	dispatch({ type: Actions.SET_STATE, offset: offset + metadata.limit });
};

const openCreateTodoDrawer = (dispatch: Dispatch, state: State) => async () => {
	dispatch({ type: Actions.SET_STATE, todoDrawerOpen: true });

	openDrawer('CreateTodo', {
		client: state.client,
		opportunity: state.order ? { ...state.order, orderValue: state.order.value } : undefined,
		projectPlan: state.projectPlan!,
		onClose: () => {
			dispatch({ type: Actions.SET_STATE, todoDrawerOpen: false });
		}
	});
};

const openEditTodoDrawer = (dispatch: Dispatch, state: State) => async (task: Activity) => {
	dispatch({ type: Actions.SET_STATE, todoDrawerOpen: true });

	openDrawer('EditTodo', {
		todo: task,
		onClose: () => {
			dispatch({ type: Actions.SET_STATE, todoDrawerOpen: false });
		}
	});
};

export const addEvent = (dispatch: Dispatch, state: State) => (event: Event) => {
	dispatch({ type: Actions.SET_STATE, events: [...state.events, event] });
};

export const setComment = (dispatch: Dispatch, state: State) => (comment: string) => {
	dispatch({ type: Actions.SET_STATE, comment });
};

export const setOffSet = (dispatch: Dispatch, state: State) => (offset: number) => {
	dispatch({ type: Actions.SET_STATE, offset });
};

export const setOrder = (dispatch: Dispatch, state: State) => (order: Order | null) => {
	dispatch({ type: Actions.SET_STATE, order });
};

export const setTempStartDate = (dispatch: Dispatch, state: State) => (tempStartDate: Date | null) => {
	dispatch({ type: Actions.SET_STATE, tempStartDate });
};

export const setTempEndDate = (dispatch: Dispatch, state: State) => (tempEndDate: Date | null) => {
	dispatch({ type: Actions.SET_STATE, tempEndDate });
};

export const setTempUser = (dispatch: Dispatch, state: State) => (tempUser: { id: number; name: string } | null) => {
	dispatch({ type: Actions.SET_STATE, tempUser });
};

export const setFileAmount = (dispatch: Dispatch) => (amount: number) => {
	dispatch({ type: Actions.SET_STATE, fileAmount: amount });
};

export const setFiles = (dispatch: Dispatch) => (files: File[]) => {
	dispatch({ type: Actions.SET_STATE, files: files });
};

export function useEditProjectPlanContext() {
	const context = useContext(EditProjectPlanContext);

	if (typeof context === 'undefined') {
		throw new Error('useEditProjectPlanContext must be used within a Provider');
	}

	const { state, dispatch } = context;

	const actions = {
		addEvent: addEvent(dispatch, state),
		createNewProjectPlan: createNewProjectPlan(dispatch, state),
		getAgreement: getAgreement(dispatch, state),
		getClient: getClient(dispatch, state),
		getEvents: getEvents(dispatch, state),
		getMoreEvents: getMoreEvents(dispatch, state),
		getFiles: getFiles(dispatch),
		getFileAmount: getFileAmount(dispatch),
		getOrder: getOrder(dispatch, state),
		getProjectPlan: getProjectPlan(dispatch, state),
		getTasks: getTasks(dispatch, state),
		onProjectPlanChange: onProjectPlanChange(dispatch, state),
		onToggleTaskCheckbox: onToggleTaskCheckbox(dispatch, state),
		onToggleTaskPriority: onToggleTaskPriority(dispatch, state),
		saveNewProject: saveNewProject(dispatch, state),
		saveComment: saveComment(dispatch, state),
		setComment: setComment(dispatch, state),
		setOffSet: setOffSet(dispatch, state),
		setOrder: setOrder(dispatch, state),
		setTempUser: setTempUser(dispatch, state),
		setTempStartDate: setTempStartDate(dispatch, state),
		setTempEndDate: setTempEndDate(dispatch, state),
		updateTodo: updateTodo(dispatch, state),
		openCreateTodoDrawer: openCreateTodoDrawer(dispatch, state),
		openEditTodoDrawer: openEditTodoDrawer(dispatch, state),
		setSavingProjectPlan: setSavingProjectPlan(dispatch, state),
		setFileAmount: setFileAmount(dispatch),
		setFiles: setFiles(dispatch)
	};

	return { state, ...actions };
}
