import React from 'react';
import { Text, Tooltip } from '@upsales/components';
import bemClass from '@upsales/components/Utils/bemClass';
import { RCWidgetDataRowShape, RCWidgetData } from 'Resources/ReportWidget';
import formatWidgetValue from '../formatWidgetValue';
import { getPercent, getMaxValues, getColumnScaleValues, loadDrilldown, getTicks } from '../chartHelper';
import ColumnChartWidgetScale from '../ColumnChartWidget/ColumnChartWidgetScale';
import { Pulse } from 'App/components/animations';
import { GenericWidgetProps } from '../ReportcenterWidget';
import ColumnChartWidgetBars from '../ColumnChartWidget/ColumnChartWidgetBars';
import ColumnChartWidgetStackedBars from '../ColumnChartWidget/ColumnChartWidgetStackedBars';

import './MultiColumnChartWidget.scss';

const generateLoadingRows = (classes: bemClass, height: number) => {
	const ticks = [0, 1000];
	const { max } = getColumnScaleValues(1000);
	const l = 4;
	return [...Array(l)].map((_, i) => {
		return (
			<div key={`loading-${i}`} className={classes.elem('item').mod('loading').b()}>
				<div className={classes.elem('bar-wrap').b()}>
					<ColumnChartWidgetScale ticks={ticks} />
					<Pulse>
						<div
							className={classes.elem('bar').mod('loading').b()}
							style={{
								height: getPercent(200 * (l - (i + 1) / 3), max)
							}}
						></div>
					</Pulse>
					<Pulse>
						<div
							className={classes.elem('bar').mod('loading').b()}
							style={{
								height: getPercent(200 * (l - (i + 1) / 2), max)
							}}
						></div>
					</Pulse>
					<Pulse>
						<div
							className={classes.elem('bar').mod('loading').b()}
							style={{
								height: getPercent(200 * (l - (i + 1)), max)
							}}
						></div>
					</Pulse>
				</div>
				<div className={classes.elem('label').b()}>
					<Text size="sm" loading />
				</div>
				<div className={classes.elem('label').b()}>
					<Text size="sm" loading />
				</div>
			</div>
		);
	});
};

export default function <T extends string>(columns: readonly T[]) {
	type ExtendedRCWidgetDataRowShape = RCWidgetDataRowShape &
		{
			[key in T]: RCWidgetDataRowShape;
		};

	type Props = GenericWidgetProps & {
		data: {
			rows: ExtendedRCWidgetDataRowShape[];
			total: {
				[key in T]: GenericWidgetProps['data']['total'];
			};
			colors: {
				[key in T]: GenericWidgetProps['data']['colors'];
			};
		};
	};

	function getDataObject(data: Props['data'], has2xGrouping: boolean, type: T) {
		return {
			datatype: data.datatype,
			currency: data.currency,
			total: data.total[type],
			language: {
				progress: data.language[type]
			},
			colors: has2xGrouping ? data.colors : { progress: data.colors[type] }
		};
	}

	return class MultiColumnChartWidget extends React.Component<Props> {
		private _main: React.RefObject<HTMLDivElement>;

		constructor(props: Props) {
			super(props);
			this._main = React.createRef();
		}

		state = {
			scrolled: false
		};

		onScroll = (e: Event) => {
			const target = e.target as Element;
			if (target.scrollLeft > 0 && !this.state.scrolled) {
				this.setState({ scrolled: true });
			} else if (target.scrollLeft === 0 && this.state.scrolled) {
				this.setState({ scrolled: false });
			}
		};

		componentDidMount() {
			if (this._main.current) {
				this._main.current.addEventListener('scroll', this.onScroll);
			}
		}

		componentWillUnmount() {
			if (this._main.current) {
				this._main.current.removeEventListener('scroll', this.onScroll);
			}
		}

		loadDrilldown = (row: RCWidgetDataRowShape, key: T) => {
			loadDrilldown([key, row.key.toString()], this.props.config);
		};

		render() {
			const { loading = false, className, data, groupBy, drilldown, height, showGoal } = this.props;
			const { scrolled } = this.state;
			const totalSalesRows = !loading
				? data.rows.map((row: ExtendedRCWidgetDataRowShape) => row[columns[0]])
				: [];
			const { maxValue, minValue } = getMaxValues(totalSalesRows, showGoal);
			const { max, min } = getColumnScaleValues(loading ? 1000 : maxValue, minValue);
			const scaleMax = max || 1000;
			const scaleMin = min || 1000;
			const ticks = getTicks(maxValue, minValue, height * 2);
			const zeroTickIndex = ticks.indexOf(0);

			const classes = new bemClass('ColumnChartWidget', `${className}`).mod({
				loading,
				scrolled,
				'has-data': !!data.total?.progress,
				'multi-columns': true
			});

			const rows = data.rows;

			const has2xGrouping = groupBy.length === 2;
			const BarComponent = has2xGrouping ? ColumnChartWidgetStackedBars : ColumnChartWidgetBars;

			return (
				<div className={classes.b()} ref={this._main}>
					{!rows.length && !loading ? (
						<Text className={classes.elem('noResultRowText').b()} italic>
							{data.language?.noData}
						</Text>
					) : (
						<div className={classes.elem('fixed-labels').b()}>
							<div className={classes.elem('bar-wrap').mod('grey').b()}>
								<ColumnChartWidgetScale
									ticks={ticks}
									zeroTickIndex={zeroTickIndex}
									scaleMin={scaleMin}
									showValues
									dataType={data.datatype?.progress}
									currency={data.currency}
								/>
							</div>
							<div className={classes.elem('label').b()}>
								<Text size="sm" bold>
									{data.language?.firstGrouping ?? ''}
								</Text>
							</div>
							{columns.map(column => (
								<div key={column} className={classes.elem('label').b()}>
									<Text size="sm" bold>
										{data.language?.[column] || ''}
									</Text>
								</div>
							))}
						</div>
					)}

					<div className={classes.elem('items').b()}>
						{rows.length && !loading
							? rows.map((item: ExtendedRCWidgetDataRowShape) => {
									return (
										<div
											key={item.key}
											className={classes
												.elem('item')
												.mod({ clickable: !!(drilldown && data.enableDrilldown) })
												.b()}
										>
											<div className={classes.elem('column-wrap').b()}>
												{columns.map(column => (
													<BarComponent
														key={column}
														ticks={ticks}
														row={item[column]}
														data={
															(getDataObject(
																data,
																has2xGrouping,
																column
															) as unknown) as RCWidgetData
														}
														showGoal={showGoal}
														max={max}
														maxValue={maxValue}
														minValue={minValue}
														scaleMax={scaleMax}
														height={height}
														loading={loading}
														onClick={
															drilldown && data.enableDrilldown
																? () => this.loadDrilldown(item, column)
																: undefined
														}
														tooltipContainer={this._main}
														drilldown={drilldown}
														config={this.props.config}
													/>
												))}
											</div>
											<div className={classes.elem('label').b()}>
												<Tooltip title={item.label}>
													<Text size="sm">{item.label}</Text>
												</Tooltip>
											</div>
											{columns.map(column => (
												<div key={column} className={classes.elem('label').b()}>
													<Tooltip
														title={formatWidgetValue(
															item[column].progress,
															data.datatype.progress,
															data.currency,
															false
														)}
													>
														<Text
															size="sm"
															italic={!item[column].progress}
															bold={!!item[column].progress}
															color={item[column].progress ? 'black' : 'grey-10'}
														>
															{formatWidgetValue(
																item[column].progress,
																data.datatype.progress,
																data.currency,
																true
															)}
														</Text>
													</Tooltip>
												</div>
											))}
										</div>
									);
							  })
							: loading
							? generateLoadingRows(classes, height)
							: null}
					</div>
				</div>
			);
		}
	};
}
