import React, { useState, useEffect, useRef } from 'react';
import BemClass from '@upsales/components/Utils/bemClass';
import InputRevealButton from 'App/components/InputRevealButton';
import {
	Row,
	Column,
	Link,
	Label,
	Input,
	Button,
	Block,
	Toggle,
	Text,
	Help,
	Tooltip,
	Progress
} from '@upsales/components';
import T from 'Components/Helpers/translate';
import { SlideFade, Fade } from '@upsales/components/animations';

import './ToggleSelect.scss';

type Props<T> = {
	className?: string;
	value: T | null;
	onChange: (value: T | null) => void;
	onToggle?: () => void;
	addSingle?: boolean;
	withToggle?: boolean;
	alwaysShowInput?: boolean;
	title?: string;
	children: React.ReactNode;
	onCreateNew: (name: string) => Promise<T | null>;
	lang: { [key: string]: string };
	toggleIcon?: string;
	required?: boolean;
	helpProps?: {
		articleId: number;
		sidebar?: boolean;
		tooltipTitle?: string;
	};
	disabled?: boolean;
};

const ToggleSelect = <T extends any = number>(props: Props<T>) => {
	const {
		className,
		value,
		onChange,
		onToggle,
		addSingle = false,
		withToggle = false,
		helpProps,
		title,
		alwaysShowInput = false,
		children,
		lang,
		onCreateNew,
		toggleIcon,
		required = false,
		disabled = false
	} = props;

	const classes = new BemClass('PlanPhonecallsDrawerCallListSelect', className);
	const componentIsMounted = useRef(true);
	const inputRef = useRef<HTMLInputElement | null>(null);
	const [showInput, setShowInput] = useState(!!value || alwaysShowInput || required); // initially open if a value is set
	const [showCreateNew, setShowCreateNew] = useState(false);
	const [creatingNew, setCreatingNew] = useState(false);
	const [newName, setNewName] = useState('');

	// open/close if value changes
	useEffect(() => setShowInput(!!value || alwaysShowInput || required), [value, alwaysShowInput, required]);
	useEffect(() => {
		return () => {
			componentIsMounted.current = false;
		};
	}, []);

	const createList = async () => {
		setCreatingNew(true);
		const listItem = await onCreateNew(newName);

		if (componentIsMounted.current) {
			setNewName('');
			setCreatingNew(false);
			if (listItem) {
				onChange(listItem);
			}
			setShowCreateNew(false);
		}
	};

	const saveOnEnter: React.KeyboardEventHandler<HTMLInputElement> = e => {
		if (e.key === 'Enter' && newName !== '') {
			e.stopPropagation();
			e.preventDefault();
			createList();
		}
	};

	if (!showInput && !withToggle) {
		return (
			<div className={classes.b()}>
				<InputRevealButton icon="list-ol" text={lang.addTo} onClick={() => setShowInput(true)} />
			</div>
		);
	}

	const content = (
		<Block className={classes.elem('content').b()} space="mbl">
			<Fade visible={!showCreateNew && showInput}>
				<Block>
					<Row>
						<Column>
							{title ? (
								<Text bold>{title}</Text>
							) : (
								<Label required={!withToggle && required}>
									{addSingle ? lang.placeInSingle : lang.placeIn}
								</Label>
							)}
						</Column>
						{disabled ? null : (
							<Column align="right">
								<Link
									type="link"
									onClick={() => {
										setShowCreateNew(true);
										setTimeout(() => inputRef.current?.focus(), 100);
									}}
								>
									{lang.createNewObject}
								</Link>
							</Column>
						)}
					</Row>
					{children}
				</Block>
			</Fade>
			<Fade visible={showCreateNew && showInput}>
				<Block>
					<Row>
						<Column>
							<Label required={true}>{lang.nameNewObject}</Label>
							<Progress
								hideText
								percentage={(newName.length / 50) * 100}
								className={classes.elem('progress').b()}
							/>
						</Column>
					</Row>
					<Block>
						<Input
							inputRef={ref => (inputRef.current = ref)}
							onChange={e => setNewName(e.target.value)}
							onKeyPress={saveOnEnter}
							value={newName}
							maxLength={50}
						/>
					</Block>
				</Block>
			</Fade>
		</Block>
	);

	const buttons = (
		<SlideFade visible={showCreateNew && showInput} direction="top" height maxHeight={40}>
			<Block className={classes.elem('saveButtons').b()} space={showCreateNew && showInput ? 'mbl' : undefined}>
				<Button size="sm" disabled={newName === ''} onClick={() => createList()} loading={creatingNew}>
					{lang.saveNewObject}
				</Button>
				<Button
					size="sm"
					type="link"
					color="grey"
					onClick={() => {
						setShowCreateNew(false);
						setNewName('');
					}}
				>
					{T('default.abort')}
				</Button>
			</Block>
		</SlideFade>
	);

	if (!withToggle) {
		return (
			<div className={classes.mod({ showCreateNew }).b()}>
				{content}
				{buttons}
			</div>
		);
	}

	const { tooltipTitle, ...restHelpProps } = helpProps || { articleId: null };
	return (
		<div className={classes.mod({ withToggle, showCreateNew, showInput }).b()}>
			<Block>
				<Toggle
					icon={toggleIcon}
					size="lg"
					space="mrm"
					checked={showInput}
					onChange={value => {
						onToggle?.();
						setShowInput(value);
						if (!value) {
							onChange(null);
							setShowCreateNew(false);
						}
					}}
				/>
				<Label
					required={required}
					className="clickable"
					style={{ display: 'inline' }}
					onClick={() => setShowInput(!showInput)}
				>
					{addSingle ? lang.addToSingle : lang.addTo}
				</Label>
				{restHelpProps?.articleId ? (
					<Tooltip title={tooltipTitle || ''} distance={20}>
						<Help {...restHelpProps} />
					</Tooltip>
				) : null}
			</Block>
			<SlideFade visible={showInput} direction="top">
				<>
					{content}
					{buttons}
				</>
			</SlideFade>
		</div>
	);
};

export default ToggleSelect;
