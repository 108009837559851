import PropTypes from 'prop-types';
import React from 'react';
import Tooltip from '@upsales/components/Tooltip';

const propTypes = {
	onConfirm: PropTypes.func,
	entity: PropTypes.string,
	show: PropTypes.bool,
	secondAction: PropTypes.object,
	children: PropTypes.any,
	tooltipDistance: PropTypes.number,
	tooltip: PropTypes.string,
	tooltipPosition: PropTypes.string,
	btnText: PropTypes.string,
	showBody: PropTypes.bool,
	keepTabPosition: PropTypes.bool,
	onVisibleChange: PropTypes.func,
	bodyText: PropTypes.string,
	titleText: PropTypes.string,
	dropdownPosition: PropTypes.string,
};

const defaultProps = {
	dropdownPosition: 'bottom',
	showBody: true,
	onVisibleChange: () => {}
};

class InlineConfirm extends React.Component {
	constructor(props) {
		super(props);
		const t = Tools.$translate;

		this.state = {
			expanded: false,
			resolving: false
		};

		const entityName = (t(props.entity) ?? '').toLowerCase();
		this.lang = {
			confirmDeletion: t('admin.modal.deleteThisEntity', { entity: entityName }),
			actionIrreversible: t('admin.modal.actionIrreversible'),
			delete: t('default.delete'),
			entity: entityName,
			calendarIntegraionDeleteInfo:
				props.entity === 'default.appointment' && Tools.AppService.getCalendarIntegrations().length
					? t('default.calendarIntegraionDeleteInfo')
					: ''
		};
	}

	componentDidUpdate() {
		if (!this.state.expanded) {
			document.onmousedown = null;
		} else if (!document.onmousedown && this.state.expanded) {
			document.onmousedown = e => {
				if (
					this.state.expanded &&
					this._confirmModal &&
					!this._confirmModal.contains(e.target) &&
					this._confirmTab &&
					!this._confirmTab.contains(e.target)
				) {
					this.stopProp(e);
					this.setState({ expanded: false });
					this.props.onVisibleChange(false);
				}
			};
		}
	}

	stopProp(e) {
		if (e.stopPropagation) {
			e.stopPropagation();
		}
	}

	render() {
		const { onConfirm, show, secondAction, btnText, showBody, keepTabPosition, dropdownPosition, bodyText, titleText } = this.props;
		const { resolving } = this.state;

		let tabClassName = 'inline-confirm-tab';
		if (keepTabPosition) {
			tabClassName += ' keep-position';
		}

		const tab = (
			<div
				className={tabClassName}
				onClick={e => {
					if (!resolving && onConfirm) {
						this.setState({ expanded: !this.state.expanded });
						this.props.onVisibleChange(!this.state.expanded);
					}
					this.stopProp(e);
				}}
				ref={r => (this._confirmTab = r)}
			>
				{!resolving ? this.props.children : <b className="fa fa-spinner fa-spin" />}
			</div>
		);
		return show ? (
			<div
				className={this.state.expanded ? 'inline-confirm-modal' : 'inline-confirm-modal-hidden'}
				onClick={e => this.stopProp(e)}
			>
				{this.props.tooltip && !this.state.expanded ? (
					<Tooltip
						title={this.props.tooltip}
						position={
							this.props.tooltipPosition
								? this.props.tooltipPosition
								: this.props.tooltip?.length > 30
								? 'bottom'
								: 'left'
						}
						distance={this.props.tooltipDistance}
					>
						{tab}
					</Tooltip>
				) : (
					tab
				)}
				{this.state.expanded ? (
					<div className={`inline-confirm-container inline-confirm-container--${dropdownPosition}`} ref={r => (this._confirmModal = r)}>
						<h1 className="inline-confirm-title">{titleText || this.lang.confirmDeletion}</h1>
						{showBody ? (
							<span className="inline-confirm-body">
								{bodyText || (
									<>
										{this.lang.actionIrreversible} {this.lang.calendarIntegraionDeleteInfo}
									</>
								)}
							</span>
						) : null}
						<div className="inline-confirm-control">
							<button
								className="btn btn-block up-btn btn-red inline-confirm-button"
								disabled={resolving}
								onClick={() => {
									this.setState({ resolving: true, expanded: false });
									this.props.onVisibleChange(false);
									return onConfirm()?.catch(() => {
										this.setState({ resolving: false });
									});
								}}
							>
								{btnText ? btnText : `${this.lang.delete} ${this.lang.entity}`}
							</button>
							{secondAction ? (
								<button
									className={'btn btn-block up-btn inline-confirm-button' + secondAction.class}
									disabled={resolving}
									onClick={secondAction.action}
								>
									{secondAction.text}
								</button>
							) : null}
						</div>
					</div>
				) : null}
			</div>
		) : null;
	}
}

InlineConfirm.propTypes = propTypes;
InlineConfirm.defaultProps = defaultProps;

window.InlineConfirm = InlineConfirm;
export default window.InlineConfirm;
