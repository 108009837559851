import React from 'react';
import { Column, Row, Text, Table, TableColumn, TableHeader, TableHeaderColumn, TableRow } from '@upsales/components';
import formatWidgetValue from '../formatWidgetValue';
import { GenericWidgetProps } from '../ReportcenterWidget';
import bemClass from '@upsales/components/Utils/bemClass';
import './FunnelTableWidget.scss';
import { RCWidgetData, RCWidgetDataRowShape } from 'Resources/ReportWidget';
import { RCDashboardWidget } from 'Resources/ReportDashboard';
import { loadDrilldown } from '../chartHelper';
import _ from 'lodash';
type ColumnType = {
	key: string | number;
	title: string;
	sorting?: number | string;
};

type MappedRow = {
	columns: { [k: string]: { progress: number; goal?: number; remaining?: number } };
} & RCWidgetDataRowShape;

type Mapped2xRowsAndCols = { columns: ColumnType[]; rows: MappedRow[] };
const mapDataAndGetColumns = (
	rawRows: RCWidgetData['rows'],
	language: { [k: string]: string }
): Mapped2xRowsAndCols => {
	const { rows, columns } = rawRows.reduce(
		(res, row) => {
			const mappedRow = {
				...row,
				columns: {
					progress: { progress: row.progress, goal: row.goal },
					goal: { progress: row.goal },
					remaining: { progress: row.remaining }
				}
			} as MappedRow;
			row.rows.forEach(subRow => {
				if (!res.columns[subRow.key]) {
					res.columns[subRow.key] = {
						key: subRow.key,
						title: subRow.label,
						sorting: subRow.sorting
					};
				}
				mappedRow.columns[subRow.key.toString()] = subRow;
			});
			res.rows.push(mappedRow);
			return res;
		},
		{ columns: {}, rows: [] } as { columns: { [k: string]: ColumnType }; rows: MappedRow[] }
	);

	const orderedCols = _.sortBy(columns, ({ sorting }) => {
		return sorting;
	});

	return {
		rows,
		columns: [{ key: 'label', title: language?.firstGrouping }, ...orderedCols]
	};
};

type ConversionRateArrowProps = {
	value?: number;
};
const ConversionRateArrow = ({ value }: ConversionRateArrowProps) => {
	const classes = new bemClass('ConversionRateArrow');
	return typeof value === 'number' ? (
		<div className={classes.b()}>
			<svg width="49" height="24" viewBox="0 0 49 24" fill="none" xmlns="http://www.w3.org/2000/svg">
				<path
					d="M5.25202 12.2733L5.43043 12L5.25202 11.7267L1.4571 5.91319C-0.0625786 3.58518 1.60781 0.500004 4.38792 0.500003L39.001 0.500002C40.1832 0.500002 41.2856 1.09684 41.9318 2.08682L48.4029 12L41.9318 21.9132C41.2856 22.9032 40.1832 23.5 39.001 23.5L4.38793 23.5C1.60781 23.5 -0.0625793 20.4148 1.45709 18.0868L5.25202 12.2733Z"
					fill="#F6F9FB"
					stroke="#CAD2DD"
				/>
			</svg>

			<Text>{formatWidgetValue(value, 'percent')}</Text>
		</div>
	) : null;
};

const openDrilldown = (
	type: string | number,
	userId: string | number,
	config: RCDashboardWidget,
	enableDrilldown: boolean
) => {
	if (enableDrilldown) {
		loadDrilldown([type.toString(), userId.toString()], config);
	}
};

type ValueColumnProps = {
	config: RCDashboardWidget;
	progress: number;
	conversionRate?: number;
	numberType: 'number' | 'currency' | 'percent';
	type: string | number;
	userId: string | number;
	drilldown: boolean;
	currency: string;
	enableDrilldown: boolean;
};
const ValueColumn = ({
	progress,
	conversionRate,
	numberType,
	type,
	config,
	userId,
	drilldown,
	currency,
	enableDrilldown
}: ValueColumnProps) => {
	const className = drilldown ? 'Table FunnelTableWidget__column--double-grouping' : 'Table';
	return (
		<TableColumn
			className={className}
			onClick={drilldown ? () => openDrilldown(type, userId, config, enableDrilldown) : undefined}
		>
			<Row>
				<Column>
					<Text>{formatWidgetValue(progress, numberType, currency)}</Text>
				</Column>
				{typeof conversionRate === 'number' ? (
					<Column>
						<ConversionRateArrow value={conversionRate} />
					</Column>
				) : null}
			</Row>
		</TableColumn>
	);
};
const FunnelTableWidget = ({ data, loading, currency, groupBy = [], config, ...props }: GenericWidgetProps) => {
	const classes = new bemClass('FunnelTableWidget');
	const drilldownSupportedColumns = ['stage1', 'stage2', 'stage3', 'stage4'];

	if (!loading) {
		const { rows, columns } = mapDataAndGetColumns(data.rows, data.language);
		return (
			<div className={classes.b()}>
				{!rows.length ? (
					<Table className={classes.elem('noResultTable').b()}>
						<TableRow className={classes.elem('noResultRow').b()}>
							<TableColumn>
								<Text className={classes.elem('noResultRowText').b()} italic>
									{data.language?.noData}
								</Text>
							</TableColumn>
						</TableRow>
					</Table>
				) : (
					<Table>
						<TableHeader className={classes.elem('table-header').b()}>
							{columns.map(col => (
								<TableHeaderColumn key={col.key}>
									<Text size={'sm'}>{col.title}</Text>
								</TableHeaderColumn>
							))}
						</TableHeader>
						{rows.map(row => (
							<TableRow key={row.key} className={classes.elem('row').b()}>
								<TableColumn>
									<Text size="sm">{row.label}</Text>
								</TableColumn>
								{row.rows.map((subRow, i) => (
									<ValueColumn
										config={config}
										key={subRow.key}
										userId={row.key}
										type={subRow.key}
										drilldown={drilldownSupportedColumns.includes(subRow.key.toString())}
										progress={subRow.progress}
										conversionRate={subRow.conversionRate}
										numberType={i > 3 ? 'currency' : 'number'}
										currency={currency}
										enableDrilldown={props.drilldown}
									/>
								))}
							</TableRow>
						))}
					</Table>
				)}
			</div>
		);
	} else {
		return <div></div>;
	}
};

export default FunnelTableWidget;
