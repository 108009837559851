import React from 'react';
import PropTypes from 'prop-types';
import { Tooltip } from '@upsales/components';
import { NumberFormat } from 'App/babel/utils/numberFormat';

export default class NameProspecting extends React.Component {
	constructor(props) {
		super(props);

		const t = Tools.$translate;

		this.lang = {
			parentCompany: t('default.parentCompany'),
			headquarters: t('soliditet.headquarters'),
			branch: t('default.branchOffice'),
			city: t('address.city'),
			numEmployees: t('default.numOfEmployees'),
			turnover: t('default.turnover')
		};

		this.state = { ref: null };
	}

	getSubtitle() {
		const { account } = this.props;

		if (
			!account.visitingCountry &&
			!account.visitingTown &&
			!account.postCountry &&
			!account.postTown &&
			!account.registeredCountry &&
			!account.registeredTown &&
			!account.revenue &&
			!account.noEmployees &&
			!account.countryCode
		) {
			return null;
		}

		const country =
			account.visitingCountry || account.postCountry || account.registeredCountry || account.countryCode;

		const flag = country ? (
			<b style={{ marginRight: 10 }} className={'icon flag-icon flag-icon-' + country.toLowerCase()} />
		) : null;

		const town = account.visitingTown || account.postTown || account.registeredTown || null;

		const addressSpan = (
			<span style={{ verticalAlign: 'middle' }}>
				{flag}
				{ReactTemplates.TOOLS.withTooltip(<span>{town}</span>, this.lang.city, {
					placement: 'top'
				})}
			</span>
		);

		let numEmployees = null,
			turnover = null;

		if (account.headquarters === 1) {
			const numberFormat = new NumberFormat();
			if (account.noEmployees !== null) {
				const numEmployeesText = numberFormat.short(account.noEmployees);
				numEmployees = (
					<span style={{ marginLeft: '10px' }}>
						<i className="fa fa-user" /> {numEmployeesText}
					</span>
				);
			}
			if (account.revenue !== null) {
				const turnoverText = numberFormat.short(account.revenue);
				turnover = (
					<span style={{ marginLeft: '10px' }}>
						<i className="fa fa-bar-chart" /> {turnoverText}
					</span>
				);
			}
		}

		return (
			<span className="subtitle">
				{addressSpan}
				{ReactTemplates.TOOLS.withTooltip(numEmployees, this.lang.numEmployees, { placement: 'top' })}
				{ReactTemplates.TOOLS.withTooltip(turnover, this.lang.turnover, { placement: 'top' })}
			</span>
		);
	}

	getAdditionalInfo() {
		if (this.props.account.closestGroupMotherOrgnumber === null && this.props.account.groupSize > 1) {
			return <span className="additional-info">{this.lang.parentCompany}</span>;
		} else if (this.props.account.headquarters === 1) {
			return <span className="additional-info">{this.lang.headquarters}</span>;
		} else {
			return <span className="additional-info">{this.lang.branch}</span>;
		}
	}

	setRef = ref => {
		this.setState({ ref });
	};

	render() {
		let overflow = false;
		const { ref } = this.state;
		if (ref) {
			overflow = ref.scrollWidth > ref.offsetWidth;
		}
		const skipAdditionalInfo = this.props.field?.skipAdditionalInfo;
		const res = (
			<div>
				<Tooltip title={this.props.account.name} disabled={!overflow}>
					<span className="row-text" ref={this.setRef}>
						{this.props.account.name} {skipAdditionalInfo ? null : this.getAdditionalInfo()}
					</span>
				</Tooltip>

				{this.getSubtitle()}
			</div>
		);
		return this.props.noTable ? (
			<div className={`DataSourceColumn ${this.props.field?.className}`}>{res}</div>
		) : (
			<td className={`DataSourceColumn ${this.props.field?.className}`}>{res}</td>
		);
	}
}

NameProspecting.propTypes = {
	account: PropTypes.object,
	field: PropTypes.object,
	noTable: PropTypes.bool
};
