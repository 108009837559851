import Resource from './Resource';
import Attributes from 'Attributes/OnboardingStepCompletedAttributes';

class OnboardingStepCompleted extends Resource {
	constructor() {
		super('onboardingStepCompleted', Attributes);
	}
}

const resource = new OnboardingStepCompleted();
window.Tools.OnboardingStepCompleted = resource;
export default resource;
