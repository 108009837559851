import Resource from 'Resources/Resource';
import Project, { RawProject } from './Model/Project';
import parseProject from './parsers/project';
import Attributes from 'App/babel/attributes/Project';

export type { Project };

class ProjectResource extends Resource {
	eventName = 'campaign';
	parse: (client: RawProject) => Project;
	constructor() {
		super('projects', Attributes);

		this.parse = parseProject;

		this.notifications = {
			save: () => ({
				title: 'default.saved',
				body: 'saved.campaign',
				style: 'success',
				icon: 'check',
				type: 'body'
			}),
			saveError: () => ({
				title: 'default.error',
				body: 'saveError.campaign',
				style: 'error',
				icon: 'times',
				type: 'body'
			}),
			delete: () => ({
				title: 'default.deleted',
				body: 'deleted.campaign',
				style: 'success',
				icon: 'check',
				type: 'body'
			}),
			deleteError: () => ({
				title: 'default.error',
				body: 'deleteError.campaign',
				style: 'error',
				icon: 'times',
				type: 'body'
			})
		};
	}
}

const resource = new ProjectResource();

export default resource;
