import React, { ComponentProps } from 'react';
import { Loader, SelectAsync } from '@upsales/components';
import { useSelectLanguage, CoreSelectItem } from './selectHelpers';
import { SelectItem } from '@upsales/components/Utils/selectHelpers';

export type DynamicSelectProps<T extends SelectItem = SelectItem, TMulti extends boolean = false> = {
	onChange: (value: GetProp<typeof SelectAsync<T, TMulti>, 'value'>) => void;
	isLoading?: boolean;
} & Omit<ComponentProps<typeof SelectAsync<T, TMulti>>, 'onClear' | 'onChange' | 'onRemove'>;

export type DynamicSelectConsumerProps<T = {}> = PartialRequired<
	ComponentProps<typeof DynamicSelect<CoreSelectItem<T>>>,
	'value' | 'onChange'
>;

export function DynamicSelect<T extends SelectItem>({
	value,
	fetcher,
	onChange,
	language = {},
	isLoading = false,
	...selectProps
}: DynamicSelectProps<T>) {
	const finalLang = useSelectLanguage(language);

	return (
		<SelectAsync<T>
			value={value}
			fetcher={fetcher}
			language={finalLang}
			onChange={v => onChange(v)}
			onClear={() => onChange(null)}
			renderSelectedItem={isLoading ? () => <Loader noU size="xs" /> : undefined}
			{...selectProps}
		/>
	);
}

export default DynamicSelect;
