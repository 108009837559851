import React from 'react';
import { Link } from '@upsales/components';
import BemClass from '@upsales/components/Utils/bemClass';

const getHref = (linkType, id) => {
	switch (linkType) {
		case 'client':
			return Tools.$state.href('account', { id });
		case 'contact':
			return Tools.$state.href('contact', { id });
		default:
			return null;
	}
};

const InternalLinkRenderType = ({ row }) => {
	const classes = new BemClass('InternalLinkRenderType', 'widget-link-wrapper');
	return (
		<Link className={classes.b()} href={getHref(row.linkType, row.linkId)}>
			{row.text}
		</Link>
	);
};

export default InternalLinkRenderType;
