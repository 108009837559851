import Resource from './Resource';
import Attributes from 'Attributes/TodoAttributes';

class Todo extends Resource {
	constructor() {
		super('todos', Attributes);
	}

	multi(opts) {
		return this._postRequest(`multi`, opts).then(r => r.data);
	}
}

const resource = new Todo();

export default resource;
