import React, { useState } from 'react';
import ReportcenterFilter from './ReportcenterFilter';
import { Block } from '@upsales/components';
import bemClass from '@upsales/components/Utils/bemClass';
import t, { useTranslation } from 'Components/Helpers/translate';
import './ReportcenterFilterTicketType.scss';
import ReportcenterFilterRow from './ReportcenterFilterRow';
import { removeItem } from 'Store/helpers/array';
import { RCDashboardFilter } from 'Resources/ReportDashboard';
import ReportcenterFilterSearchbar from './ReportcenterFilterSearchbar';
import { RootState } from 'Store/index';
import { useTicketTypes } from 'App/components/hooks/appHooks';
import { getTicketTypesFromState } from 'Store/selectors/AppSelectors';
import store from 'Store/index';

interface Props {
	onChange: (type: keyof RCDashboardFilter, values: number[], comparison: string) => void;
	values: RCDashboardFilter;
	updateFilterHeight: () => void;
	families: string[];
	dashboard: RootState['Reportcenter']['selectedDashboard'];
}

export const renderSelected = (values: RCDashboardFilter, renderCount: boolean = true) => {
	let res = `${t('admin.ticketTypes')}: `;
	if (!renderCount) {
		const ticketTypeNames = getTicketTypesFromState(store.getState().App).reduce((res, ticketType) => {
			if (values?.TicketType?.value?.includes(ticketType.id)) {
				res.push(ticketType.name);
			}
			return res;
		}, [] as string[]);

		res += ticketTypeNames.join(', ');
		return res;
	}

	if (values?.TicketType?.value?.length) {
		const length = values.TicketType.value.length;
		if (length >= 1) {
			res += `${length} ${(values.TicketType.comparison === 'eq'
				? t('default.selected')
				: t('default.excluded', { count: length })
			).toLowerCase()}`;
		}
	}

	return res;
};

const ReportcenterFilterTicketType = ({ onChange, families, ...props }: Props) => {
	const classes = new bemClass('ReportcenterFilterTicketType');
	const [searchStr, setSearchStr] = useState('');

	const { t } = useTranslation();
	const ticketTypes = useTicketTypes();
	const array = ticketTypes.filter(item => item.name.toLowerCase().indexOf(searchStr.toLowerCase()) !== -1);
	const value = props.values?.TicketType?.value || [];
	const comparison = props.values?.TicketType?.comparison ?? 'eq';
	const isExclude = comparison === 'ne';

	return (
		<ReportcenterFilter
			className={classes.b()}
			renderSelected={() => renderSelected(props.values)}
			getSelectedNames={() => ticketTypes.filter(s => value.includes(s.id)).map(s => s.name)}
			icon="tags"
			placeholder={`${t('admin.ticketTypes')}: ${t('reportcenter.filter.notActive')}`}
			value={value}
			resetFilter={() => onChange('TicketType', [], 'eq')}
			{...props}
		>
			<Block space="ptm prm pbm plm" backgroundColor="white" className={classes.elem('list-select').b()}>
				<Block space="mtm">
					<ReportcenterFilterSearchbar<number[]>
						setSearchStr={value => setSearchStr(value)}
						searchStr={searchStr}
						placeholder={`${t('default.search')} ${t('admin.ticketTypes').toLowerCase()}`}
						isExclude={isExclude}
						onChange={onChange}
						field="TicketType"
						value={value}
					/>
				</Block>
			</Block>
			<ReportcenterFilterRow
				key={0}
				onClick={() => {
					onChange('TicketType', [], 'eq');
				}}
				selected={!value.length}
				title={t('filters.noFilter')}
			/>
			{array.map(row => (
				<ReportcenterFilterRow
					key={row.id}
					onClick={() => {
						const i = value.indexOf(row.id);
						onChange('TicketType', i !== -1 ? removeItem(value, i) : [...value, row.id], comparison);
					}}
					selected={value.indexOf(row.id) !== -1}
					title={row.name}
				/>
			))}
		</ReportcenterFilter>
	);
};

export default ReportcenterFilterTicketType;
