import React, { Component } from 'react';
import { copyToClipboardNotify } from 'Services/copyToClipboard';
import BemClass from '@upsales/components/Utils/bemClass';
import T from 'Components/Helpers/translate';
import './InlineInput.scss';
import { Icon, IconName, Input, Link, Text, Tooltip } from '@upsales/components';
import { phoneNumberHelper } from '@upsales/common';
import Client from 'App/resources/Model/Client';
import Contact from 'App/resources/Model/Contact';
import Activity from 'App/resources/Model/Activity';
import { openNewMailWithContact } from 'App/helpers/mailHelpers';
import { formatURL } from 'App/helpers/linkFormatHelper';

const emailRegex =
	// eslint-disable-next-line no-useless-escape
	/^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
const phoneRegex = /^[+]*[(]{0,1}[-\s0-9]{1,4}[)]{0,1}[-\s/0-9]*$/;
const websiteRegex =
	// eslint-disable-next-line no-useless-escape
	/^(http(s)?:\/\/.)?(www\.)?[-a-zA-Z0-9@:%._\+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_\+.~#?&//=]*)$/;
const getEmailProps = function (contact: Contact | undefined, email: string) {
	// if user can use mail-function
	const click = function (e: React.MouseEvent) {
		e?.stopPropagation();
		const metadataMap = Tools.AppService.getMetadata().map;
		if (Tools.FeatureHelper.isAvailable(Tools.FeatureHelper.Feature.FLASH) && metadataMap && metadataMap.mail) {
			e.preventDefault();

			if (email) {
				if (Tools.FeatureHelper.hasSoftDeployAccess('NEW_MAIL')) {
					openNewMailWithContact({ ...contact, email });
				} else {
					Tools.$upModal.open('sendEmail', {
						customerId: Tools.AppService.getCustomerId(),
						email,
						contactId: contact?.id
					});
				}
			}
		}
	};

	return { target: '_top', href: `mailto:${email}`, onClick: click };
};

const getPhoneProps = function (client: Client, contact: Contact | undefined, number: string, phoneRelated?: PhoneRel) {
	const phoneProps = ReactTemplates.TOOLS.upVoice({ ...contact, client } as Contact, 'contact', number, phoneRelated);
	if (phoneProps) {
		return { href: phoneProps.href, target: phoneProps.target, onClick: phoneProps.onClick };
	} else {
		return null;
	}
};

interface PhoneRel {
	type: string;
	id: number;
	closeDate: Activity['closeDate'];
}
interface Props<T = { [type: string]: string }> {
	value: string;
	type: string;
	client: Client;
	contact?: Contact;
	phoneRelated?: PhoneRel;
	onConfirmInput: (val: T) => void;
	disabled?: boolean;
}

interface State {
	value: string;
	invalidInput: boolean;
	openEdit: boolean;
	icon: IconName;
	linkProps: ReturnType<typeof getPhoneProps> | {};
	isLink: boolean;
	missing: string;
	uneditable: boolean;
	regex?: RegExp;
	isNumber: boolean;
}
export default class InlineInput<T> extends Component<Props<T>, State> {
	constructor(props: Props<T>) {
		super(props);
		const state: State = {
			value: this.props.value || '',
			invalidInput: false,
			openEdit: false,
			icon: '' as IconName,
			linkProps: {},
			isLink: false,
			missing: '',
			uneditable: props.disabled || false,
			isNumber: false
		};

		switch (this.props.type) {
			case 'cellPhone':
				state.icon = 'mobile';
				state.regex = phoneRegex;
				state.missing = T('inlineInput.addMobile');
				state.isNumber = true;
				state.isLink = true;
				state.linkProps = getPhoneProps(
					this.props.client,
					this.props.contact,
					this.props.value,
					this.props.phoneRelated
				);
				break;
			case 'phone':
				state.icon = 'phone';
				state.regex = phoneRegex;
				state.missing = T('inlineInput.addPhone');
				state.isLink = true;
				state.isNumber = true;
				state.linkProps = getPhoneProps(
					this.props.client,
					this.props.contact,
					this.props.value,
					this.props.phoneRelated
				);
				break;
			case 'email':
				state.icon = 'envelope';
				state.regex = emailRegex;
				state.missing = T('inlineInput.addEmail');
				state.isLink = true;
				state.linkProps = getEmailProps(this.props.contact, state.value);
				break;
			case 'parent':
				state.icon = 'sitemap';
				state.missing = T('inlineInput.addParent');
				state.isLink = true;
				state.linkProps = {
					onClick: () => {
						if (this.props.client.parent)
							Tools.$state.go('account.dashboard', { id: this.props.client.parent.id });
					}
				};
				state.uneditable = true;
				break;
			case 'webpage':
				state.icon = 'globe';
				state.missing = T('inlineInput.addWebsite');
				state.isLink = true;
				state.linkProps = {
					href: formatURL(this.props.client.webpage || ''),
					target: '_blank'
				};
				state.regex = websiteRegex;
				break;
			case 'linkedin':
				state.icon = 'linkedin';
				state.missing = T('inlineInput.addLinkedin');
				state.isLink = true;
				state.linkProps = {
					href: formatURL(this.props.client.linkedin),
					target: '_blank'
				};
				state.regex = websiteRegex;
				break;

			default:
				state.missing = T('default.noValue');
				break;
		}
		this.state = state;
	}
	componentDidUpdate(prevProps: Props<T>) {
		if (this.props.value && prevProps.value !== this.props.value) {
			this.setState({ value: this.props.value });
			switch (this.props.type) {
				case 'phone':
				case 'cellPhone':
					this.setState({
						linkProps: getPhoneProps(
							this.props.client,
							this.props.contact,
							this.props.value,
							this.props.phoneRelated
						)
					});
					break;
				case 'email':
					this.setState({
						linkProps: getEmailProps(this.props.contact, this.state.value)
					});
					break;
			}
		}

		switch (this.props.type) {
			case 'webpage':
				if (prevProps.client.webpage !== this.props.client.webpage) {
					this.setState({
						linkProps: { ...this.state.linkProps, href: formatURL(this.props.client.webpage || '') }
					});
				}
				break;
			case 'linkedin':
				if (prevProps.client.linkedin !== this.props.client.linkedin) {
					this.setState({
						linkProps: { ...this.state.linkProps, href: formatURL(this.props.client.linkedin) }
					});
				}
				break;
		}
	}

	validateInput = () => {
		if (this.state.regex) {
			const valid = this.state.regex.test(this.state.value);
			if (valid) {
				if (this.state.value === this.props.value) {
					this.setState({ openEdit: false });
				} else {
					this.setState({ openEdit: false, value: this.state.value }, () => {
						if (this.props.type === 'phone' || this.props.type === 'cellPhone') {
							this.setState({
								linkProps: getPhoneProps(
									this.props.client,
									this.props.contact,
									this.state.value,
									this.props.phoneRelated
								)
							});
						}
					});
					this.props.onConfirmInput({ [this.props.type]: this.state.value } as T);
				}
			} else if (!this.state.value) {
				this.props.onConfirmInput({ [this.props.type]: this.state.value } as T);
				this.setState({ openEdit: false });
			}
		} else {
			if (this.state.value === this.props.value || this.state.value) {
				this.setState({ value: this.state.value });
				this.props.onConfirmInput({ [this.props.type]: this.state.value } as T);
			}
		}
	};

	onChange = (value: string) => {
		this.setState({ value });
		if (this.state.regex) {
			const valid = this.state.regex.test(value);
			if (!valid && value) {
				this.setState({ invalidInput: true });
			} else {
				this.setState({ invalidInput: false });
			}
		} else {
			this.setState({ invalidInput: false });
		}
	};

	render() {
		const classes = new BemClass('InlineInput');
		return (
			<div className={classes.b()}>
				{this.state.openEdit ? (
					<div className={classes.mod('input-state').b()}>
						<Input
							size="sm"
							value={this.state.value ?? ''}
							className={
								!this.state.invalidInput
									? classes.elem('input-field').b()
									: classes.elem('input-field').mod('invalid-input').b()
							}
							onChange={e => this.onChange(e.target.value)}
							onBlur={this.validateInput}
							onKeyPress={event => {
								if (event.key === 'Enter') {
									this.validateInput();
								}
							}}
							autoFocus
							noborder
						/>
					</div>
				) : (
					<div
						className={classes.mod('default-state').b()}
						onClick={e => {
							if (this.state.uneditable) {
								return;
							}
							this.setState({ value: this.props.value });
							e.stopPropagation();
							if (!this.state.uneditable) {
								this.setState({ openEdit: !this.state.openEdit });
							}
						}}
					>
						{this.state.icon ? (
							<Icon
								color={this.props.value ? (this.state.isLink ? 'bright-blue' : 'black') : 'grey-10'}
								space="mrm"
								name={this.state.icon}
							/>
						) : null}
						<Text
							size="sm"
							color={this.props.value ? (this.state.isLink ? 'bright-blue' : 'black') : 'grey-10'}
						>
							{this.props.value ? (
								this.state.isLink ? (
									<Link color="bright-blue" {...this.state.linkProps}>
										{this.state.isNumber
											? phoneNumberHelper.formatNumber(this.state.value)
											: this.state.value}
									</Link>
								) : (
									this.state.value
								)
							) : (
								this.state.missing
							)}
						</Text>

						<div className={classes.elem('tools').b()}>
							<Tooltip title={T('default.edit')} distance={20}>
								{this.state.uneditable ? null : (
									<Icon
										name="edit"
										className="fa fa-edit"
										onClick={e => {
											e.stopPropagation();
											this.setState({ openEdit: !this.state.openEdit });
										}}
									/>
								)}
							</Tooltip>
							{this.state.value ? (
								<Tooltip title={T('default.copy')} distance={20}>
									<Icon
										name="copy"
										onClick={e => {
											e.stopPropagation();
											copyToClipboardNotify(this.state.value);
										}}
									/>
								</Tooltip>
							) : null}
						</div>
					</div>
				)}
			</div>
		);
	}
}
