import PropTypes from 'prop-types';
import React from 'react';

import './FullscreenModal.scss';

const propTypes = {
	hideClose: PropTypes.bool,
	children: PropTypes.node.isRequired,
	reject: PropTypes.func.isRequired,
	resolve: PropTypes.func.isRequired
};

/** @deprecated Use the Modal ui-component with fullScreen prop instead */
class FullscreenModal extends React.Component {
	render() {
		const { children, hideClose, reject } = this.props;
		let childrenElements;
		if (React.isValidElement(children)) {
			childrenElements = React.Children.map(children, child => React.cloneElement(child, this.props));
		} else {
			childrenElements = children;
		}

		return (
			<div className="FullscreenModal">
				{hideClose ? null : (
					<a className="Fullscreen__modal-close btn btn-link" onClick={reject}>
						<i className="fa fa-times" />
					</a>
				)}
				{childrenElements}
			</div>
		);
	}
}

FullscreenModal.propTypes = propTypes;

export default FullscreenModal;
