import React from 'react';
import _ from 'lodash';
import PropTypes from 'prop-types';
import BemClass from '@upsales/components/Utils/bemClass';
import { Block } from '@upsales/components';
import T from 'Components/Helpers/translate';
import './HiddenSelect.scss';

const HiddenSelectSharedPropTypes = {
	config: PropTypes.object,
	staticAddButton: PropTypes.shape({
		onClick: PropTypes.func.isRequired,
		label: PropTypes.string.isRequired
	})
};

class HiddenSelectHelper extends React.Component {
	static propTypes = {
		...HiddenSelectSharedPropTypes,
		value: PropTypes.object,
		onChange: PropTypes.func,
		disabled: PropTypes.bool,
		isAjax: PropTypes.bool,
		children: PropTypes.func.isRequired,
		data: PropTypes.oneOfType([PropTypes.func, PropTypes.array, PropTypes.oneOf([null])]),
		fetchData: PropTypes.func,
		placeholder: PropTypes.string,
		labelKey: PropTypes.string,
		idKey: PropTypes.string,
		customcssclass: PropTypes.string,
		openCallback: PropTypes.func
	};
	static defaultProps = {
		labelKey: 'name',
		idKey: 'id'
	};

	id = Date.now();

	constructor(p) {
		super(p);

		this.state = {
			open: false
		};

		this.select2Mounted = false;
	}

	destroy = () => {
		if (this._input) {
			jQuery(this._input).off();
			jQuery(this._input).select2('destroy');
		}
	};

	componentWillUnmount() {
		this.destroy();
	}
	mountSelect2() {
		const formatResult = item => {
			return _.escape(item[this.props.labelKey]);
		};
		const selectConfig = {
			formatSelection: formatResult,
			formatResult: formatResult,
			allowClear: 0,
			matcher: (term, text, op) => op[this.props.labelKey].toUpperCase().indexOf(term.toUpperCase()) >= 0,
			minimumInputLength: 2,
			quietMillis: 100,
			formatSearching: () => T('default.searching'),
			formatNoMatches: () => T('default.noResults'),
			dropdownCssClass: 'HiddenSelect--' + this.id,
			...this.props.config
		};

		if (this.props.isAjax) {
			selectConfig.ajax = {
				data: term => term,
				transport: query => {
					this.props
						.fetchData(query.data)
						.then(results => query.success({ results }))
						.catch(query.failure);
				},
				results: r => r
			};
		} else {
			selectConfig.data = this.props.data;
		}

		this.destroy();
		const input = jQuery(this._input);
		input
			.select2(selectConfig)
			.on('change', e => {
				this.props.onChange(e.added);
			})
			.on('select2-close', () => {
				if (this._inputParent) {
					this._inputParent.style.display = 'none';
				}
				this.setState({ open: false });
			})
			.on('select2-open', () => {
				this.setState({ open: true });
				this.props.openCallback?.(this.id);
			});

		this.select2Mounted = true;
		$('.HiddenSelect--' + this.id).addClass(`HiddenSelect--${this.props.customcssclass}`);
	}

	addOrRemoveStaticBtn = () => {
		const btn = document.querySelectorAll(`.HiddenSelect--${this.id} .HiddenSelect__static-add-btn`)[0];

		// Check if button should be removed
		if (btn && !this.props.staticAddButton) {
			btn.remove();
		} else if (!btn && this.props.staticAddButton) {
			const input = jQuery(this._input);
			const select2Drop = document.getElementsByClassName(`HiddenSelect--${this.id}`)[0];
			const addNewContactBtn = document.createElement('div');
			addNewContactBtn.classList.add(
				'HiddenSelect__static-add-btn',
				'Button',
				'up-btn',
				'btn-bright-blue',
				'btn-link',
				'btn-block'
			);
			addNewContactBtn.innerText = this.props.staticAddButton.label;
			select2Drop.appendChild(addNewContactBtn);
			addNewContactBtn.addEventListener('click', () => {
				input.select2('close');
				this.props.staticAddButton.onClick();
			});
		}
	};

	componentDidMount() {
		if (this.props.isAjax || this.props.data) {
			this.mountSelect2();
		}
		if (this.select2Mounted) {
			this.addOrRemoveStaticBtn();
		}
	}
	componentDidUpdate() {
		if (!this.select2Mounted && this.props.data) {
			this.mountSelect2();
		}
		if (this.select2Mounted) {
			this.addOrRemoveStaticBtn();
		}
	}
	showSelect = () => {
		if (this.props.disabled) {
			return;
		}
		this._inputParent.style.display = 'block';
		jQuery(this._input).select2('open');
	};
	render() {
		const {
			/* eslint-disable no-unused-vars */
			data,
			isAjax,
			config,
			fetchData,
			value,
			idKey,
			labelKey,
			staticAddButton,
			openCallback,
			/* eslint-enable no-unused-vars */
			...cleanProps
		} = this.props;
		const { disabled, placeholder, children, className, ...props } = cleanProps;
		const classes = new BemClass('HiddenSelect', className);
		return (
			<Block className={classes.b()} onClick={this.showSelect} {...props}>
				{children({ open: this.state.open })}
				<div className={classes.elem('hidden-select').b()} ref={r => (this._inputParent = r)}>
					<input
						ref={r => (this._input = r)}
						data-placeholder={placeholder || ''}
						disabled={disabled}
						className="form-control"
						type="hidden"
						required
					/>
				</div>
			</Block>
		);
	}
}

export const HiddenSelectAjax = ({ config = {}, ...props }) => {
	const c = { ...config };
	return <HiddenSelectHelper {...props} config={c} isAjax={true} />;
};

HiddenSelectAjax.propTypes = {
	...HiddenSelectSharedPropTypes
};

const HiddenSelect = ({ config = {}, ...props }) => {
	const c = {
		minimumInputLength: -1,
		quietMillis: 0,
		...config
	};
	return <HiddenSelectHelper {...props} config={c} isAjax={false} />;
};

HiddenSelect.propTypes = {
	...HiddenSelectSharedPropTypes,
	data: PropTypes.oneOfType([PropTypes.func, PropTypes.array, PropTypes.oneOf([null])])
};

export default HiddenSelect;
