import { AssistChip, Block, Text } from '@upsales/components';
import BemClass from '@upsales/components/Utils/bemClass';
import React, { useMemo } from 'react';
import { FlowTemplate } from 'App/enum/FlowTemplate';
import T from 'Components/Helpers/translate';
import './FlowTemplateCard.scss';

type Props = React.ComponentProps<typeof Block> & {
	template: FlowTemplate;
	preview?: boolean;
};

const FlowTemplateCard = ({ className, preview, template, ...blockProps }: Props) => {
	const { title, subtitle, chipText, chipIcon, imgPath, imgPathPreview } = template;
	const classes = useMemo(() => new BemClass('FlowTemplateCard', className).mod({ preview }), [className, preview]);

	return (
		<Block {...blockProps} borderRadius className={classes.b()}>
			<div
				className={classes.elem('illustration').b()}
				style={{ backgroundImage: `url(${preview ? imgPathPreview : imgPath})` }}
			>
				{chipText ? (
					<AssistChip className={classes.elem('chip').b()} title={T(chipText)} icon={chipIcon} />
				) : null}
			</div>
			<div className={classes.elem('info').b()}>
				<Text>{T(title)}</Text>
				<Text size="sm" color="grey-11">
					{T(subtitle)}
				</Text>
			</div>
		</Block>
	);
};

export default FlowTemplateCard;
