import React, { useEffect, useState } from 'react';
import BemClass from '@upsales/components/Utils/bemClass';
import './ReportcenterTVPlay.scss';
import { Loader } from '@upsales/components';

type Props = { channel: any };

const Dashboard = ({
	isCurrent,
	dashboard,
	onLoad
}: {
	isCurrent: boolean;
	dashboard: string;
	onLoad?: () => void;
}) => {
	const [key, setKey] = useState(dashboard + 0);
	useEffect(() => {
		if (!isCurrent) {
			setKey(dashboard + Math.random());
		}
	}, [isCurrent]);
	return (
		<iframe
			style={{ opacity: isCurrent ? 1 : 0 }}
			key={key}
			onLoad={onLoad}
			src={`${window.location.origin}/rc-dashboard.html?dashboardId=${dashboard}`}
		></iframe>
	);
};
const ReportcenterTVPlay = ({ channel }: Props) => {
	const classes = new BemClass('ReportcenterTVPlay');
	const { interval, dashboards } = channel;

	const [currentDashboard, setCurrentDashboard] = useState(0);
	const [loading, setLoading] = useState(true);

	useEffect(() => {
		let timeout: any;

		if (!loading) {
			timeout = setTimeout(() => {
				setCurrentDashboard(currentDashboard + 1 === dashboards.length ? 0 : currentDashboard + 1);
			}, interval * 1000);
		}

		return () => {
			clearTimeout(timeout);
		};
	}, [loading, currentDashboard]);

	return (
		<div className={classes.b()}>
			{loading ? (
				<div className={classes.elem('loader').b()}>
					<Loader size="xl" />
				</div>
			) : null}
			{dashboards.map((dashboard: any, index: any) => (
				<Dashboard
					isCurrent={dashboards[currentDashboard].dashboardId === dashboard.dashboardId}
					dashboard={dashboard.dashboardId}
					key={index}
					onLoad={index === 0 ? () => setLoading(false) : undefined}
				/>
			))}
		</div>
	);
};
export default ReportcenterTVPlay;
