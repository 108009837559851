import React from 'react';
import { Block, Row } from '@upsales/components';
import { FilterConfig } from 'App/babel/filterConfigs/FilterConfig';
import { ListViewFilter } from 'App/resources/AllIWant';
import StackedDateInput from 'Components/Inputs/StackedDateInput';

export type DateFilter = Omit<ListViewFilter, 'value'> & {
	value: { preset: string; start: Date | null; end: Date | null };
};

type DateFilterProps = {
	filter: DateFilter;
	filterConfig: FilterConfig;
	valueChanged: (value: DateFilter['value']) => void;
};

const DateFilterComponent = ({ filter, filterConfig, valueChanged }: DateFilterProps) => {
	return (
		<Block space="plm ptm prm pbm">
			<Row>
				<StackedDateInput
					config={filterConfig}
					filter={filter}
					onChange={(filter: DateFilter) => valueChanged(filter.value)}
				/>
			</Row>
		</Block>
	);
};

export default DateFilterComponent;
