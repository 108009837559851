import { CellMeasurerCache, CellMeasurerCacheParams } from 'react-virtualized';

type ExtendedCellMeasurerCache = CellMeasurerCache & {
	_columnWidthCache: {
		[key: string]: number;
	};
};

class VirtualWidthMeasurer {
	maxWidth: number;
	cellMeasurerCache: ExtendedCellMeasurerCache;

	constructor(cellMeasurerParams: CellMeasurerCacheParams) {
		this.maxWidth = 0;
		this.cellMeasurerCache = new CellMeasurerCache({
			...cellMeasurerParams
		}) as ExtendedCellMeasurerCache;
	}

	computeMaxWidth() {
		let newMaxWidth = this.cellMeasurerCache._columnWidthCache['0-0'];

		if (newMaxWidth) {
			newMaxWidth += 20; // +20 since there might be a scrollbar taking up some space, which will cause text to be ellipsed
		}

		return newMaxWidth ? newMaxWidth : this.maxWidth;
	}

	getMaxWidth() {
		this.maxWidth = this.computeMaxWidth();
		return this.maxWidth;
	}

	reset() {
		this.cellMeasurerCache.clearAll();
		this.maxWidth = 0;
	}
}

export default VirtualWidthMeasurer;
