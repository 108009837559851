import BemClass from '@upsales/components/Utils/bemClass';
import React, { useMemo } from 'react';
import FlowTemplateCard from '../FlowTemplateCard';
import './CreateFlowSelectTemplate.scss';
import { Fade } from '../animations';
import { Speed } from '../animations/Animate/Animate';
import { FlowTemplateName, FlowTemplates } from 'App/enum/FlowTemplate';

type Props = {
	onSelect: (title: FlowTemplateName) => void;
	visible: boolean;
};

const CreateFlowSelectTemplate: React.FC<Props> = ({ onSelect, visible }) => {
	const classes = useMemo(() => new BemClass('CreateFlowSelectTemplate'), []);
	return (
		<Fade visible={visible} delayOutInMs={0} delayInMs={Speed.normal}>
			<div className={classes.b()}>
				{Object.values(FlowTemplates).map((template, index) => (
					<FlowTemplateCard key={index} template={template} onClick={() => onSelect(template.name)} />
				))}
			</div>
		</Fade>
	);
};

export default CreateFlowSelectTemplate;
