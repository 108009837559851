import React from 'react';
import BemClass from '@upsales/components/Utils/bemClass';
import { Text, Link, Icon, Tooltip } from '@upsales/components';
import { MailOpportunity, MailActivity, MailAppointment } from 'App/resources/Model/Mail';
import moment from 'moment';
import T from 'Components/Helpers/translate';

import './RelatedTo.scss';
import { getActivityTypeIcon } from 'Components/Helpers/IconHelper';
import { currencyFormat } from 'Components/Filters/Currencies';

type Props = {
	opportunity?: MailOpportunity | null;
	appointment?: MailAppointment | null;
	activity?: MailActivity | null;
};

const getOpportunityLink = (opportunity: MailOpportunity) => (
	<Tooltip title={currencyFormat(opportunity.orderValue, opportunity.currency, true)} position="top">
		<Link onClick={() => Tools.$upModal.open('editOrder', { id: opportunity.id })}>
			<Icon name={opportunity.probability === 0 || opportunity.probability === 100 ? 'dollar' : 'opportunity'} />
			<Text color="bright-blue">{opportunity.description}</Text>
		</Link>
	</Tooltip>
);

const getActivityLink = (activity: MailActivity) => (
	<Tooltip
		title={`${
			activity.date?.toString().indexOf(':') === -1
				? moment(activity.date).format('YYYY-MM-DD')
				: moment(activity.date).format('YYYY-MM-DD HH:mm')
		}`}
		position="top"
		disabled={!activity.date}
	>
		<Link onClick={() => Tools.$upModal.open('editActivity', { id: activity.id })}>
			<Icon name={getActivityTypeIcon(activity.activityType)} />
			<Text color="bright-blue">{activity.description}</Text>
		</Link>
	</Tooltip>
);

const getAppointmentLink = (appointment: MailAppointment) => (
	<Tooltip title={moment(appointment.date).format('YYYY-MM-DD HH:mm')} position="top">
		<Link onClick={() => Tools.$upModal.open('editAppointment', { id: appointment.id })}>
			<Icon name="calendar" />
			<Text color="bright-blue">{appointment.description}</Text>
		</Link>
	</Tooltip>
);

const RelatedTo = ({ opportunity, appointment, activity }: Props) => {
	const classes = new BemClass('RelatedTo');

	return opportunity || appointment || activity ? (
		<div className={classes.b()}>
			<Text className={classes.elem('description').b()}>{T('mailDrawer.thisEmailIsRelatedTo')}</Text>
			{opportunity ? getOpportunityLink(opportunity) : null}
			{activity ? getActivityLink(activity) : null}
			{appointment ? getAppointmentLink(appointment) : null}
		</div>
	) : null;
};

export default RelatedTo;
