import React, { useState, useMemo, useEffect } from 'react';
import { Card, Block, Checkbox, Text, ClickableItem, Flex, Input, EllipsisTooltip } from '@upsales/components';
import BemClass from '@upsales/components/Utils/bemClass';
import { useTranslation } from 'Components/Helpers/translate';

import './TreeSelect.scss';

export type SelectOptionType = {
	id: number;
	title: string;
	opened?: boolean;
	disabled?: boolean;
	checked?: boolean;
	checkedHalf?: boolean;
	children?: SelectOptionType[];
	childCount?: number;
};

type SelectOptionProps = {
	option: SelectOptionType;
	setChecked: (id: number, checked: boolean) => void;
	setOpened: (id: number, opened: boolean) => void;
	disabled?: boolean;
	isFirst?: boolean;
	indent?: number;
};

const SelectOption = (props: SelectOptionProps) => {
	const { option, setOpened, setChecked, isFirst = false, indent = 0 } = props;
	const disabled = option.disabled || props.disabled;

	const { title, children, opened, checked, checkedHalf } = option;
	const classes = new BemClass('TreeSelectOption');

	const childCount = children?.length || option.childCount || 0;
	const titleString = `${title}${childCount ? ` (${childCount})` : ''}`;
	const hasBgColor = indent === 0 || childCount > 0;

	return (
		<Flex direction="column">
			{!isFirst ? <Block border="ts" borderColor="grey-3" /> : null}
			<Flex className={classes.mod({ hasBgColor }).b()} alignItems="center" justifyContent="space-between">
				<Flex
					space={'pbm ptm'}
					alignItems="center"
					className={classes.elem('tooltipFlex').b()}
					onClick={disabled ? undefined : () => setChecked(option.id, !option.checked)}
				>
					{indent ? Array.from({ length: indent }).map((_, i) => <Block key={i} space="pll" />) : null}
					<Checkbox
						checked={checked}
						disabled={disabled}
						half={checked && checkedHalf}
						space="mlm mrm"
						size="sm"
					/>
					<EllipsisTooltip title={titleString}>
						<Text className={classes.elem('child').b()} bold={!!childCount}>
							{titleString}
						</Text>
					</EllipsisTooltip>
				</Flex>
				{childCount ? (
					<ClickableItem
						size="sm"
						space={'pbm ptm'}
						icon={opened ? 'caret-down' : 'caret-up'}
						onClick={() => setOpened(option.id, !option.opened)}
					/>
				) : null}
			</Flex>
			{children?.length && opened ? (
				<Flex direction="column">
					{children.map(child => (
						<SelectOption
							key={child.id}
							option={child}
							setChecked={setChecked}
							setOpened={setOpened}
							disabled={props.disabled}
							indent={indent + 1}
						/>
					))}
				</Flex>
			) : null}
		</Flex>
	);
};

type Props = {
	options: SelectOptionType[];
	onChange: (selected: SelectOptionType[]) => void;
	singleSelect?: boolean;
	required?: boolean;
	disabled?: boolean;
};

const TreeSelect = (props: Props) => {
	const classes = new BemClass('TreeSelect');

	const filterOptions = (
		options: SelectOptionType[],
		searchValue: string,
		expandAll: boolean = false,
		count: number = 0
	): SelectOptionType[] => {
		if (count > 50) {
			return options;
		}
		let checkedFound = false;

		return options
			.map(option => {
				const children = filterOptions(option.children ?? [], searchValue, expandAll, count + 1);
				option.checked = !props.singleSelect || !checkedFound ? option.checked : false;
				if (option.checked && props.singleSelect) {
					checkedFound = true;
				}

				return {
					...option,
					disabled: option.disabled || (option.checked && props.singleSelect && props.required),
					checkedHalf: !props.singleSelect && !!children.length && option.checkedHalf,
					children,
					opened: expandAll && children.length > 0 ? true : option.opened,
					childCount: option.children?.length
				};
			})
			.filter(option => {
				const { title, children } = option;
				return title.toLowerCase().includes(searchValue.toLowerCase()) || children.length > 0;
			});
	};

	const { t } = useTranslation();
	const [searchValue, setSearchValue] = useState('');
	const [options, setOptions] = useState(filterOptions(props.options, searchValue, true));
	const filteredOptions = useMemo(() => filterOptions(options, searchValue), [options, searchValue]);

	useEffect(() => {
		setOptions(filterOptions(props.options, searchValue, true));
	}, [props.options, searchValue]);

	// Recursively update all children.
	const setChecked = (id: number, checked: boolean) => {
		const selected: SelectOptionType[] = [];

		const setCheckedRecursive = (option: SelectOptionType, checked: boolean, count: number = 0) => {
			if (count > 50) {
				return option;
			}

			option.checked = checked;
			option.checkedHalf = false;
			option.disabled = checked && props.singleSelect && props.required;
			option.children?.forEach(child =>
				setCheckedRecursive(child, props.singleSelect ? false : checked, count + 1)
			);

			if (checked) {
				selected.push(option);
			}

			return option;
		};

		const allChildrenChecked = (children: SelectOptionType[], count: number = 0): boolean => {
			if (count > 50) {
				return true;
			}
			return children.every(
				child => !!child.checked && !child.checkedHalf && allChildrenChecked(child.children ?? [], count + 1)
			);
		};

		// find option by recursively seraching through children and update the option with checked value
		const findAndUpdateOption = (
			options: SelectOptionType[],
			id: number,
			checked: boolean,
			count: number = 0
		): SelectOptionType[] => {
			if (count > 50) {
				return options;
			}

			return options.map(option => {
				if (option.id === id) {
					return setCheckedRecursive(option, checked);
				} else {
					const children = findAndUpdateOption(option.children ?? [], id, checked, count + 1);

					const newOption = {
						...option,
						disabled: false,
						checked: props.singleSelect ? false : option.checked,
						checkedHalf: !props.singleSelect && !!children.length && !allChildrenChecked(children),
						children
					};

					if (newOption.checked) {
						selected.push(newOption);
					}

					return newOption;
				}
			});
		};

		setOptions(options => {
			const newOptions = findAndUpdateOption(options, id, checked);
			props.onChange(selected);

			return newOptions;
		});
	};

	const setOpened = (id: number, opened: boolean) => {
		const findAndUpdateOption = (
			options: SelectOptionType[],
			id: number,
			opened: boolean,
			count: number = 0
		): SelectOptionType[] => {
			if (count > 50) {
				return options;
			}
			return options.map(option => {
				if (option.id === id) {
					return { ...option, opened };
				} else {
					return {
						...option,
						children: findAndUpdateOption(option.children ?? [], id, opened, count + 1)
					};
				}
			});
		};

		setOptions(options => findAndUpdateOption(options, id, opened));
	};

	return (
		<Card borderRadius border="black" className={classes.b()}>
			<Flex direction="column">
				<Block space="mlm mtm mrm mbm">
					<Input
						icon="search"
						placeholder="Search"
						clear
						value={searchValue}
						onChange={e => setSearchValue(e.target.value)}
					/>
				</Block>
				<Flex direction="column" className={classes.elem('options').b()}>
					{filteredOptions.map((option, idx) => (
						<SelectOption
							key={option.id}
							option={option}
							isFirst={idx === 0}
							setChecked={setChecked}
							setOpened={setOpened}
							disabled={props.disabled}
						/>
					))}
					{filteredOptions.length === 0 ? (
						<Flex justifyContent="center" space="mts mbm">
							<Text italic>{t('default.noResults')}</Text>
						</Flex>
					) : null}
				</Flex>
			</Flex>
		</Card>
	);
};

export default TreeSelect;
