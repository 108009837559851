import React, { useEffect, useState } from 'react';
import ReportcenterFilter from './ReportcenterFilter';
import { Block } from '@upsales/components';
import bemClass from '@upsales/components/Utils/bemClass';
import T from 'Components/Helpers/translate';
import './ReportcenterFilterCampaign.scss';
import ReportcenterFilterRow from './ReportcenterFilterRow';
import { removeItem } from 'Store/helpers/array';
import { RCDashboardFilter } from 'Resources/ReportDashboard';
import type Project from 'App/resources/Model/Project';
import ProjectResource from 'App/resources/Project';
import CallListResource from 'Resources/CallList';
import ReportcenterFilterSearchbar from './ReportcenterFilterSearchbar';
import logError from 'App/babel/helpers/logError';

const filterLang = {
	Campaign: {
		entities: 'default.projects',
		noEntity: 'default.noCampaign',
		resource: ProjectResource
	},
	CallList: {
		entities: 'default.callLists',
		noEntity: 'default.noCallList',
		resource: CallListResource
	},
	ClientCampaign: {
		entities: 'reportcenter.filter.clientCampaign',
		noEntity: 'default.noCampaign',
		resource: ProjectResource
	}
} as const;

interface Props {
	onChange: (type: keyof RCDashboardFilter, values: Array<number | 'null'>, comparison: string) => void;
	filterType?: keyof typeof filterLang;
	values: RCDashboardFilter;
	updateFilterHeight: () => void;
}

export const renderSelected = (values: RCDashboardFilter, campaigns?: Project[], filterType?: Props['filterType']) => {
	const notCampaign = filterType !== undefined;
	const usedFilter = notCampaign ? filterType : 'Campaign';
	const lang = {
		entities: T(filterLang[usedFilter].entities),
		noEntity: T(filterLang[usedFilter].noEntity)
	};
	let res = `${lang.entities}: `;
	const filterObj = values[usedFilter];

	if (campaigns) {
		const campaignNames = campaigns
			.filter(campaign => {
				return filterObj?.value?.includes?.(campaign.id);
			})
			.map(campaign => campaign.name);
		if (filterObj?.value?.includes?.('null')) {
			campaignNames.unshift(lang.noEntity);
		}
		res += campaignNames.join(', ');
		return res;
	}

	if (filterObj?.value?.length) {
		const length = filterObj.value.length;
		if (length >= 1) {
			res += `${length} ${(filterObj.comparison === 'eq'
				? T('default.selected')
				: T('default.excluded', { count: length })
			).toLowerCase()}`;
		}
	}
	return res;
};

const ReportcenterFilterCampaign = ({ onChange, filterType, ...props }: Props) => {
	const classes = new bemClass('ReportcenterFilterCampaign');
	const [searchStr, setSearchStr] = useState('');
	const [campaigns, setCampaigns] = useState<Project[]>([]);
	const [selectedCampaigns, setSelectedCampaigns] = useState<Project[]>([]);

	const notCampaign = filterType !== undefined;
	const usedFilter = notCampaign ? filterType : 'Campaign';
	const lang = {
		entities: T(filterLang[usedFilter].entities),
		noEntity: T(filterLang[usedFilter].noEntity)
	};

	const array: (Project | { id: 'null'; name: string })[] = [
		...selectedCampaigns,
		...campaigns.filter(c => !selectedCampaigns.some(sc => sc.id === c.id))
	];
	array.unshift({ id: 'null', name: lang.noEntity });

	const filterObj = props.values[usedFilter];
	const Resource = filterLang[usedFilter].resource;
	const value = filterObj?.value || [];
	const comparison = filterObj?.comparison ?? 'eq';
	const isExclude = comparison === 'ne';

	const search = (query: string) => {
		Resource.find({ name: `src:${query}`, sort: 'name', limit: 20 })
			.then(res => {
				setCampaigns(res.data);
			})
			.catch(err => logError(err));
	};

	const updateSelectedCampaigns = () => {
		if (value.length) {
			Resource.find({ id: value, sort: 'name' })
				.then(res => setSelectedCampaigns(res.data))
				.catch(err => logError(err));
		} else {
			setSelectedCampaigns([]);
		}
	};

	useEffect(() => {
		search(searchStr);
		updateSelectedCampaigns();
	}, []);

	useEffect(() => {
		updateSelectedCampaigns();
	}, [value]);

	useEffect(() => {
		const timeout = setTimeout(() => search(searchStr), 300);
		return () => clearTimeout(timeout);
	}, [searchStr]);

	return (
		<ReportcenterFilter
			className={classes.b()}
			renderSelected={() => renderSelected(props.values, undefined, filterType)}
			getSelectedNames={() =>
				[
					value.includes('null') ? lang.noEntity : null,
					...campaigns.filter(c => value.includes(c.id)).map(c => c.name)
				].filter(Boolean) as string[]
			}
			icon="list"
			placeholder={`${lang.entities}: ${T('reportcenter.filter.notActive')}`}
			value={value}
			resetFilter={() => onChange(usedFilter, [], 'eq')}
			{...props}
		>
			<Block space="ptm prm pbm plm" backgroundColor="white" className={classes.elem('list-select').b()}>
				<Block space="mtm">
					<ReportcenterFilterSearchbar<Array<number | 'null'>>
						setSearchStr={value => setSearchStr(value)}
						searchStr={searchStr}
						placeholder={`${T('default.search')} ${lang.entities.toLowerCase()}`}
						isExclude={isExclude}
						onChange={onChange}
						field={usedFilter}
						value={value}
					/>
				</Block>
			</Block>
			<ReportcenterFilterRow
				key={0}
				onClick={() => {
					onChange(usedFilter, [], 'eq');
				}}
				selected={!value.length}
				title={T('filters.noFilter')}
			/>
			{array.map(row => (
				<ReportcenterFilterRow
					key={row.id}
					onClick={() => {
						const i = value.indexOf(row.id);
						onChange(usedFilter, i !== -1 ? removeItem(value, i) : [...value, row.id], comparison);
					}}
					selected={value.indexOf(row.id) !== -1}
					title={row.name}
				/>
			))}
		</ReportcenterFilter>
	);
};

export default ReportcenterFilterCampaign;
