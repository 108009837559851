import React from 'react';
import PropTypes from 'prop-types';

import { connect } from 'react-redux';
import { Text, Table, TableRow, TableColumn, Icon, Tooltip } from '@upsales/components';
import ListTitle from '../ListTitle';
import T from '../../Helpers/translate';
import Bem from '@upsales/components/Utils/bemClass';

import {
	hideView,
	showHiddenView,
	makeDefault,
	showSaveView,
	closeEntireView
} from 'Store/reducers/SharedViewsReducer';

const mapDispatchToProps = {
	hideView,
	showHiddenView,
	makeDefault,
	showSaveView,
	closeEntireView
};

const mapStateToProps = state => ({
	selectedView: state.SharedViews.selectedView
});

function StandardList({
	list,
	listType,
	makeDefault,
	changeView,
	closeEntireView,
	hideView,
	showHiddenView,
	selectedView,
	removeDefault,
	showSaveView,
	disableChangeView
}) {
	let hiddenCount = 0;
	const mappedList = list.map(row => {
		const isEditable = row.editable && listType !== 'standard';
		let isHideable = false;

		if (row.hasOwnProperty('hidden')) {
			isHideable = true;

			if (row.hidden === true) {
				hiddenCount += 1;

				return null;
			}
		}

		const RowClass = new Bem('RowView').mod({
			Active: row.default,
			Current: row.id ? row.id === selectedView.id : row.title === selectedView.title
		});

		return (
			<TableRow key={row.id} className={RowClass.b()}>
				<ListTitle
					disabled={disableChangeView}
					changeView={view => {
						changeView(view);
						closeEntireView();
					}}
					entireView={row}
					listType={listType}
					{...row}
				/>
				<TableColumn className={RowClass.elem('RightColumnIcons')} align="right">
					{isHideable === true ? (
						<Tooltip title={T('default.hide')}>
							<Icon name="eye-slash" color="grey-10" onClick={() => hideView(row)} />
						</Tooltip>
					) : null}
					{isEditable === true ? (
						<Tooltip title={T('default.edit')}>
							<Icon
								name="pencil-square-o"
								color="grey-10"
								onClick={() => showSaveView({ editView: row })}
							/>
						</Tooltip>
					) : null}
					{removeDefault === true ? null : (
						<Tooltip title={T('default.favorite')}>
							<Icon
								color="grey-10"
								onClick={() => {
									makeDefault(row);
								}}
								name={row.default ? 'star' : 'star-o'}
							/>
						</Tooltip>
					)}
				</TableColumn>
			</TableRow>
		);
	});

	if (hiddenCount) {
		mappedList.push(
			<TableRow key="hidden" onClick={() => showHiddenView(listType)} className="RowView RowView--Hidden">
				<TableColumn>
					<Text color="bright-blue">{`+ ${hiddenCount} ${
						hiddenCount === 1
							? T('sharedViews.hiddenView').toLowerCase()
							: T('sharedViews.hiddenViews').toLowerCase()
					}`}</Text>
				</TableColumn>
				<TableColumn align="right">
					<Icon color="grey-10" name="chevron-right" />
				</TableColumn>
			</TableRow>
		);
	}

	return <Table>{mappedList}</Table>;
}

StandardList.propTypes = {
	list: PropTypes.array,
	hideView: PropTypes.func,
	showHiddenView: PropTypes.func,
	changeView: PropTypes.func,
	selectedView: PropTypes.object.isRequired,
	removeDefault: PropTypes.bool,
	makeDefault: PropTypes.func,
	listType: PropTypes.string,
	showSaveView: PropTypes.func,
	disableChangeView: PropTypes.bool,
	closeEntireView: PropTypes.func
};

export const detached = StandardList;
export default connect(mapStateToProps, mapDispatchToProps)(StandardList);
