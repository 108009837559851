import Attribute from './Attribute';

const SalesboardCardAttributes = {
	id: new Attribute({
		type: Attribute.types.Number,
		field: 'id'
	}),
	config: new Attribute({
		type: Attribute.types.Object,
		field: 'config'
	})
};

window.SalesboardCardAttributes = SalesboardCardAttributes;

export default SalesboardCardAttributes;
