import moment from 'moment';
import Flow from 'App/resources/Flow';
import FlowTemplateName from '../FlowTemplateName';
import buildFlowPath from './buildFlowPath';
import { FlowTemplate } from './FlowTemplate';
import getAngularModule from 'App/babel/angularHelpers/getAngularModule';
import { SegmentFilter } from 'App/resources/Model/Segment';
import { comparisonTypes } from 'App/babel/resources/RequestBuilder';

const template: FlowTemplate = {
	name: FlowTemplateName.CONTACTS_WHO_VISITED_A_WEBPAGE,
	title: 'flowtemplate.CONTACTS_WHO_VISITED_A_WEBPAGE.title',
	subtitle: 'flowtemplate.CONTACTS_WHO_VISITED_A_WEBPAGE.subtitle',
	imgPath: 'img/contacts_who_visited_a_web_page.svg',
	imgPathPreview: 'img/contacts_who_visited_a_web_page_preview.svg',
	getFlowPath: () => {
		return buildFlowPath([
			Flow.newStep('sendMail'),
			Flow.newStep('clickedMail', { waitTime: 7 }),
			Flow.newStep('assign')
		]);
	},
	getSegmentFilters: (page: string, fromDate?: string) => {
		const FilterHelper = getAngularModule('FilterHelper');
		const entity = 'contact';

		const MailBounced = FilterHelper.filter('MailBounced', entity);
		MailBounced.inactive = false;
		MailBounced.value = 'hard';
		MailBounced.comparisonType = 'Equals';

		const HasEmail = FilterHelper.filter('HasEmail', entity);
		HasEmail.inactive = false;
		HasEmail.value = true;

		const VisitorFilters = FilterHelper.filter('VisitorFilters', entity);
		VisitorFilters.inactive = false;
		VisitorFilters.value = {
			hasVisits: true,
			Date: {
				value: {
					start: fromDate || moment().subtract(7, 'day').startOf('day').toJSON(),
					end: null,
					preset: 'custom'
				},
				inactive: false
			},
			Page: {
				value: [page],
				comparisonType: comparisonTypes.Equals
			}
		};

		const includeConfig = { HasEmail, VisitorFilters };
		const excludeConfig = { MailBounced };

		const includeBody = FilterHelper.parseFilters(includeConfig, entity, undefined, undefined, {
			useTags: true,
			groupAllFilters: true
		}).build() as SegmentFilter['body'];

		const excludeBody = FilterHelper.parseFilters(excludeConfig, entity, undefined, undefined, {
			useTags: true,
			groupAllFilters: true
		}).build() as SegmentFilter['body'];

		return [
			{ config: includeConfig, body: includeBody, isExclude: false, orGroup: false },
			{ config: excludeConfig, body: excludeBody, isExclude: true, orGroup: true }
		];
	}
};

export default template;
