import ArrChangelogRaw from '../../resources/Model/ArrChangelogRaw';
import { BuildFilters } from './RequestBuilder';
import ResourceTyped from './ResourceTyped';

class ArrChangelogRawResource extends ResourceTyped<ArrChangelogRaw> {
	constructor() {
		super('arrChangelogRaw');
	}

	find(filters: BuildFilters, opts?: {}) {
		if (!filters.f) {
			throw new Error('You forgot to declare fields in filters');
		}
		return super.find(filters, opts);
	}
}

const resource = new ArrChangelogRawResource();

export default resource;
