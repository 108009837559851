// While building this i realized that modals and modals are the same and could be handeled such.
// Maybe i will fix this in the future
import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { removeItem } from 'Store/helpers/array';
import { removeModal, closeModal /*closeAllModals*/ } from 'Store/actions/ModalActions';
import { RootState } from 'Store/index';
import bemClass from '@upsales/components/Utils/bemClass';
import ReactDOM from 'react-dom';
import { makeCancelable } from 'App/babel/helpers/promise';
import { getModalConfig } from 'App/services/Modal';
import { Animation, ModalConfig } from 'App/services/Modal/modalConfigs';
import { StateModal } from 'Store/reducers/ModalReducer';
import delayedMount from '@upsales/components/Utils/delayedMount';
import { modalTracker } from 'App/babel/helpers/Tracker';
import { isEmpty } from 'lodash';
import logError from 'App/babel/helpers/logError';
import './Modals.scss';
import { closeElevioWidgets } from 'App/helpers/appHelper';

const UNMOUNT_DELAY = 400; // animation is 300

type ModalsProps = {
	removeModal: (id: number) => void;
	closeModal: (id: number) => void;
	modals: StateModal[];
};

type ModalsStateModal = StateModal & { unmounting?: boolean };

type ModalsState = {
	modals: ModalsStateModal[];
	ascended: boolean;
};

const mapStateToProps = ({ Modal }: RootState) => ({
	modals: Modal.openModals
});

const mapDispatchToProps = {
	removeModal,
	closeModal
};

// Props passed to the component that should render
export type ModalProps<OnCloseReturnType = undefined> = {
	close: (d?: OnCloseReturnType, skipEvent?: boolean) => void;
	className: string;
	modalId: number;
};

type DelayedModalProps<OnCloseReturnType> = {
	close: (d?: OnCloseReturnType, skipEvent?: boolean) => void;
	visible?: boolean;
	modalId: number;
	classes: bemClass;
	modalActive: boolean;
	ascended: boolean;
	drawer?: boolean;
	modalAnimation?: Animation;
	animateCurtain?: boolean;
	showCurtain?: boolean;
	onCurtainClick?: () => void;
	Component: ModalConfig['component'];
};

// Wrap modal in delayedMount/unmount HOC
const DelayedModal = delayedMount(function <OnCloseReturnType = undefined>({
	visible,
	modalId,
	classes,
	modalActive,
	modalAnimation,
	Component,
	ascended,
	drawer,
	animateCurtain,
	showCurtain,
	onCurtainClick,
	...props
}: DelayedModalProps<OnCloseReturnType>) {
	useEffect(() => {
		// Find out if we had an ascended angular-modal on open
		const openAscendedModals = document.querySelectorAll('.above-react-modal');
		if (openAscendedModals.length) {
			[].forEach.call(openAscendedModals, (el: HTMLElement) => {
				el.classList.remove('above-react-modal');
			});
			// If we did we need to put back the class when this modal is unmounted
			return () => {
				[].forEach.call(openAscendedModals, (el: HTMLElement) => {
					el.classList.add('above-react-modal');
				});
			};
		}

		closeElevioWidgets();
	}, []);

	const c = classes.elem('modal').mod({
		visible,
		active: modalActive,
		ascended,
		drawer,
		'drawer-right': drawer, // right is the only supported right now, but we can implement a left drawer later
		[`animation-${modalAnimation?.toLowerCase()}`]: true
	});

	return (
		<React.Fragment key={'modal' + modalId}>
			{showCurtain ? (
				<div
					className={classes
						.elem('curtain')
						.mod({ visible: animateCurtain ? visible : true })
						.b()}
					onClick={onCurtainClick}
				/>
			) : null}
			<Component {...(props as ModalProps<OnCloseReturnType>)} modalId={modalId} className={c.b()} />
		</React.Fragment>
	);
});

const getCompareStr = (modals: ModalsState['modals']) => modals.map(i => `${i.id}+${i.unmounting}`).join(',');

// Will return the "last" modal in the array. This will be the one rendered on top
const getActiveModal = (modals: ModalsState['modals']): ModalsStateModal | null => {
	return modals[modals.length - 1] ?? null;
};

export const useModalClose = (modalId: number, onClose: (e: any) => void, dependencies: any[]) => {
	useEffect(() => {
		// Using angular events for now, to be replaced at some point
		const listener = Tools.$rootScope.$on('modals.close', (e: any, id: number) => {
			if (modalId === id) {
				onClose(e);
			}
		});

		return () => {
			listener();
		};
	}, dependencies);
};

class Modals extends React.Component<ModalsProps, ModalsState> {
	appRoot: HTMLElement | null;
	modalRoot: HTMLDivElement;
	delayedClosePromises: { id: number; delayed: ReturnType<typeof makeCancelable> }[];
	constructor(p: ModalsProps) {
		super(p);

		this.state = {
			ascended: false,
			modals: [] // array for holding open modals
		};

		this.appRoot = document.getElementById('react-root');
		this.modalRoot = document.createElement('div');
		this.modalRoot.classList.add('Modals');

		this.delayedClosePromises = [];

		// Maybe we will need this
		// this.unmountStateChangeListener = ReduxListeners.add('$stateChangeStart', closeAllModals);
	}

	static getDerivedStateFromProps(props: ModalsProps, state: ModalsState) {
		// If anything changed to the modals-array in props
		if (getCompareStr(props.modals) !== getCompareStr(state.modals)) {
			// Keep this until upModal gone
			const openModals = document.querySelectorAll('body.modal-open');
			// const openReactModals = props.modals.filter(d => !d.unmounting);
			// const openModalsCount = openReactModals.length + openAngularModals.length;
			const active = getActiveModal(props.modals);
			if (props.modals.length && active) {
				// Disable/enable scroll if active drawer has closeOnCurtain prop
				// if (active.opts.closeOnCurtain) {
				document.getElementsByTagName('body')[0].style.overflow = 'hidden';
				// } else {
				// 	document.getElementsByTagName('body')[0].style.overflow = '';
				// }
			} else {
				document.getElementsByTagName('body')[0].style.overflow = '';
			}
			// Set new array to local state
			return { modals: props.modals, ascended: !!openModals.length };
		}
		return null;
	}

	// Function to use when closing a modal.
	// It will delay the slicing from the array and mark it as "unmounting" in the local state until it is removed.
	delayCloseModal = (id: number, data?: any) => {
		const delayed = makeCancelable(
			new Promise<void>(r => {
				// Set unmounting = true on modal obj
				const modal = this.state.modals.find(d => d.id === id);
				if (!modal) {
					return;
				}
				this.props.closeModal?.(id);

				try {
					modal.props?.onClose?.(data);
				} catch (error) {
					// If an error occurs in the onClose function we don't want to crash the app
					logError(error, 'An error occurred in the onClose function of a modal, modal will still be closed');
				}

				try {
					const diffInSec = Math.round((new Date().getTime() - modal.openedTimeStamp.getTime()) / 1000);
					const { NEW_MODAL_SERVICE } = modalTracker.events;
					modalTracker.track(NEW_MODAL_SERVICE, {
						openTime: diffInSec,
						modal: modal.name,
						isEdit: modal.props?.constructor?.name !== 'SyntheticBaseEvent' && !isEmpty(modal.props),
						reject: !data
					});
				} catch (error) {
					logError(error, 'Failed to track new modal service');
				}

				closeElevioWidgets();

				// Delay the "real" removal of the modal
				setTimeout(() => {
					const index = this.delayedClosePromises.findIndex(d => d.id === id);
					if (index !== -1) {
						this.delayedClosePromises = removeItem(this.delayedClosePromises, index);
						this.props.removeModal?.(id);
					}
					r();
				}, UNMOUNT_DELAY);
			})
		);
		delayed.promise.catch(() => {});
		// Add promise to array of delayed unmounts so we can abort it on Modals component unmount to prevent a crash
		this.delayedClosePromises.push({ id, delayed });
	};

	// Broadcast event and close modal if the event is not prevented
	delayCloseModalWithEvent = (id: number, data?: any, skipEvent?: any) => {
		if (!skipEvent) {
			// Using angular events for now, to be replaced at some point
			const e = Tools.$rootScope.$broadcast('modals.close', id);

			if (e.defaultPrevented) {
				return;
			}
		}

		this.delayCloseModal(id, data);
	};

	// When curtain is clicked we check if the active modal allows for closeOnCurtain
	onCurtainClick = () => {
		const active = getActiveModal(this.state.modals);
		if (active && active.opts.closeOnCurtain) {
			this.delayCloseModalWithEvent(active.id);
		}
	};

	// Will render the modal curtain. The curtain will be visible as long as there are any mounted modals
	renderCurtain = (classes: bemClass, mountedModals: ModalsStateModal[]) => {
		return ReactDOM.createPortal(
			<div
				className={classes.elem('curtain').mod({ visible: !!mountedModals.length }).b()}
				onClick={this.onCurtainClick}
			/>,
			this.modalRoot
		);
	};

	onKeyDown = (e: Event) => {
		const active = getActiveModal(this.state.modals);

		if ('code' in e && active?.opts.closeOnEscape) {
			if (e.code === 'Escape') {
				this.delayCloseModalWithEvent(active.id);
			}
		}
	};

	componentDidMount() {
		this.appRoot?.appendChild(this.modalRoot); // Add modal portal-root on mount
		// eslint-disable-next-line promise/catch-or-return
		Tools.AppService.loadedPromise.then(
			() =>
				(this.modalRoot.classList.value = new bemClass('Modals')
					.mod({
						ascended: this.state.ascended
					})
					.b())
		);

		document.addEventListener('keydown', this.onKeyDown);
	}

	componentDidUpdate() {
		this.modalRoot.classList.value = new bemClass('Modals')
			.mod({
				ascended: this.state.ascended
			})
			.b();
		const mountedModals = this.state.modals.filter(d => !d.unmounting);
		if (!mountedModals.length) {
			const openAscendedModals = document.querySelectorAll('.above-react-modal');

			if (openAscendedModals.length) {
				[].forEach.call(openAscendedModals, (el: HTMLElement) => {
					el.classList.remove('above-react-modal');
				});
			}
		}
	}

	componentWillUnmount() {
		this.appRoot?.removeChild(this.modalRoot); // Remove modal portal-root on unmount
		document.removeEventListener('keydown', this.onKeyDown);
	}

	// Will render a DelayedModal with the modal component as only child
	renderModal = (
		Component: ModalConfig['component'],
		{ id, props = {}, unmounting, opts }: ModalsStateModal,
		active: boolean,
		animateCurtain: boolean,
		showCurtain: boolean,
		classes: bemClass
	) => {
		return ReactDOM.createPortal(
			<DelayedModal
				{...props}
				ascended={this.state.ascended}
				unmountDelay={UNMOUNT_DELAY}
				mounted={!unmounting}
				key={id}
				modalId={id}
				classes={classes}
				modalActive={active}
				modalAnimation={opts.animation}
				drawer={opts.drawer}
				close={this.delayCloseModalWithEvent.bind(null, id)}
				Component={Component}
				animateCurtain={animateCurtain}
				showCurtain={showCurtain}
				onCurtainClick={this.onCurtainClick}
			/>,
			this.modalRoot,
			`${id}`
		);
	};

	render() {
		const { modals, ascended } = this.state;
		// Filter non-unmounting modals only
		const mountedModals = this.state.modals.filter(d => !d.unmounting);
		const classes = new bemClass('Modals').mod({ visible: !!mountedModals.length, ascended });
		const animateCurtain = modals.length === 1;
		return (
			<div>
				{modals.map((modal, i) => {
					const isMounted = mountedModals.find(d => d.id === modal.id);
					const modalConfig = getModalConfig(modal.name); // get modal config by name
					const Component = modalConfig ? modalConfig.component : null;
					const showCurtain =
						(!!isMounted && mountedModals[mountedModals.length - 1].id === modal.id) || animateCurtain;

					// Render modal if a component was found
					if (Component) {
						return this.renderModal(Component, modal, !!isMounted, animateCurtain, showCurtain, classes);
					}
					return null;
				})}
			</div>
		);
	}
}

export const detached = Modals;
export default connect(mapStateToProps, mapDispatchToProps)(Modals);
