import React, { useState } from 'react';
import BemClass from '@upsales/components/Utils/bemClass';
import { Avatar, Tooltip, Icon, Text, Row, Column } from '@upsales/components';
import HiddenSelect from 'Components/Inputs/HiddenSelect';
import './HeaderUserSelect.scss';
import User from 'App/resources/Model/User';

interface Props extends React.ComponentProps<typeof HiddenSelect> {
	warning?: string;
	disabled?: boolean;
	hasError?: boolean;
	users?: User[];
	title?: string;
	user: User;
	onlyAvatar?: boolean;
	customCssClass?: string;
}
const HeaderUserSelect: React.FC<Props> = ({
	warning,
	disabled = false,
	hasError,
	users,
	title,
	user,
	onlyAvatar = false,
	customCssClass,
	...props
}) => {
	const classes = new BemClass('HeaderUserSelect').mod({ disabled, warning, error: hasError });
	let avatar = <Avatar initials={user ? user.name : ''} email={user ? user.email : ''} size="md" />;
	const [isOpen, setIsOpen] = useState(false);
	if (warning) {
		avatar = <Tooltip title={warning}>{avatar}</Tooltip>;
	}
	return (
		<HiddenSelect
			backgroundColor={warning ? 'super-light-orange' : 'white'}
			className={isOpen ? classes.mod('open').b() : classes.b()}
			data={users}
			value={user}
			disabled={disabled}
			customcssclass={customCssClass}
			{...props}
		>
			{({ open }: { open: boolean }) => {
				setIsOpen(open);
				return (
					<Row>
						<Column fixedWidth={50} className={classes.elem('avatar-col').b()}>
							{onlyAvatar ? (
								<Tooltip title={user.name} position="bottom">
									{avatar}
								</Tooltip>
							) : (
								avatar
							)}
							{warning && !hasError ? (
								<Icon name="warning" color="orange" className={classes.elem('warning-icon').b()} />
							) : null}
						</Column>
						{!onlyAvatar || open ? (
							<Column className={classes.elem('text-col').b()}>
								<Text className={classes.elem('title').b()} color="grey-10" size="sm">
									{title}
								</Text>
								<Text className={classes.elem('user-name').b()} size="sm">
									{user ? user.name : null}
								</Text>
							</Column>
						) : null}
						<Column fixedWidth={25} className={classes.elem('caret-col').b()}>
							<Icon className={classes.elem('caret').b()} name="caret-down" />
						</Column>
					</Row>
				);
			}}
		</HiddenSelect>
	);
};

export default HeaderUserSelect;
