import React from 'react';
import PropTypes from 'prop-types';
import { Card, Column, Input, Row, Text, Toggle } from '@upsales/components';
import './TargetCard.scss';
import bemClass from '@upsales/components/Utils/bemClass';
import { getUserCurrency, noLetterNumberFormat } from '../TargetCalculatorHelper';
import formatWidgetValue from 'App/pages/Reportcenter/ReportcenterWidget/formatWidgetValue';
import T from 'Components/Helpers/translate';

const TargetCard = ({
	title,
	userId,
	target,
	nominator,
	denominator,
	result,
	inputDisable,
	toggleVal,
	type,
	onChange,
	onToggleChange
}) => {
	const classes = new bemClass('TargetCard');

	return (
		<div className={toggleVal ? classes.mod('disabled').b() : classes.b()}>
			<Card space="plxl prxl ptxl pbxl">
				<Row>
					<Column>
						<Text size="xl">{title}</Text>
					</Column>
					<Column className={classes.elem('includeToggle').b()}>
						<Row direction="row-reverse">
							<Text size="md" bold={true}>
								{T('targetCard.dontUse')}
							</Text>
							<Toggle value={toggleVal} onChange={val => onToggleChange(val, type)} color="bright-blue" />
						</Row>
					</Column>
				</Row>
				<Row>
					<Input
						className={classes
							.elem('input')
							.mod({ currencyInput: type === 'recurring' || type === 'pipeline' })
							.b()}
						value={noLetterNumberFormat(target)}
						onChange={e => onChange(userId, e.target.value)}
						disabled={inputDisable}
						maxLength={16}
						inline
						size="lg"
					/>
					{type === 'recurring' || type === 'pipeline' ? (
						<div className={classes.elem('currencyDisplay').b()}>
							<Text size="lg">{getUserCurrency(userId).iso}</Text>
						</div>
					) : null}
				</Row>
			</Card>

			<Card className={classes.elem('calcBox').b()} space="ptxl pbxl plxl prxl">
				<Row>
					<Column size={6}>
						<Row>
							<Column align="left" size={8}>
								<Text color="grey-10">{nominator.title}</Text>
							</Column>
							<Column align="right" size={4}>
								<Text color="grey-10">
									{type === 'recurring' && nominator.value
										? formatWidgetValue(
												parseInt(nominator.value.toString().replace(/ /g, '')),
												'currency',
												getUserCurrency(userId).iso,
												true
										  )
										: formatWidgetValue(nominator.value, 'number', '', false) || ''}
								</Text>
							</Column>
						</Row>
						<Row className={classes.elem('separator').b()} />

						<Row className={classes.elem('denominator').b()}>
							<Column align="left" size={8.5}>
								<Text color="grey-10">{denominator.title}</Text>
							</Column>
							<Column align="right" size={3.5}>
								<Text color="grey-10">
									{type === 'recurring'
										? formatWidgetValue(
												denominator.value,
												'currency',
												getUserCurrency(userId).iso,
												true
										  )
										: formatWidgetValue(denominator.value, 'percent', '', false) || ''}
								</Text>
							</Column>
						</Row>
					</Column>
					<Column align="center" size={1} className={classes.elem('equals').b()}>
						<Text>{'='}</Text>
					</Column>
					<Column size={5} className={classes.elem('resultCol').b()}>
						<Row>
							<Text color="grey-10">{`${result.title} ${formatWidgetValue(
								result.value,
								'number',
								'',
								false
							)}`}</Text>
						</Row>
					</Column>
				</Row>
			</Card>
		</div>
	);
};

TargetCard.propTypes = {
	userId: PropTypes.number.isRequired,
	title: PropTypes.string,
	target: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
	nominator: PropTypes.shape({
		title: PropTypes.string,
		value: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
	}),
	denominator: PropTypes.shape({
		title: PropTypes.string,
		value: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
	}),
	result: PropTypes.shape({
		title: PropTypes.string,
		value: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
	}),
	inputDisable: PropTypes.bool,
	toggleVal: PropTypes.bool,
	type: PropTypes.string,
	onChange: PropTypes.func,
	onToggleChange: PropTypes.func
};

export default TargetCard;
