import RequestBuilder, { comparisonTypes } from 'Resources/RequestBuilder';
import Mail from 'App/resources/Model/Mail';
import MailResource from 'Resources/Mail';
import { ListViewFilter } from 'App/resources/AllIWant';
import MailAttributes from 'App/babel/attributes/MailAttributes';

export default async function createMailCampaign(
	filters: {
		[filterName: string]: ListViewFilter;
	},
	allSelected: boolean,
	selectedIds: number[],
	entityType: string
) {
	let mailFilters: { [key: string]: any };
	if (entityType === 'mail') {
		const rb = new RequestBuilder();
		rb.addFilter(MailAttributes.id, comparisonTypes.Equals, selectedIds);
		const { data } = await MailResource.find(rb.build());
		const contactIds = data.map((mail: Mail) => mail.contact?.id);
		const idFilters = Tools.FilterHelper.getConfig('Id', 'contact');
		idFilters.value = contactIds ?? [];
		idFilters.comparisonType = 'Equals';
		mailFilters = { Id: idFilters };
	} else {
		if (!allSelected && !filters.MailCampaignFilters) {
			const idFilters = Tools.FilterHelper.getConfig('Id', 'contact');
			idFilters.value = selectedIds;
			idFilters.comparisonType = 'Equals';
			mailFilters = { Id: idFilters };
		} else {
			mailFilters = filters;
		}
	}
	Tools.$upModal.open('createGroupMail', { filters: mailFilters });
}
