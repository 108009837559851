import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import BemClass from '@upsales/components/Utils/bemClass';
import { Block } from '@upsales/components';
import HiddenSelect, { HiddenSelectAjax } from 'Components/Inputs/HiddenSelect';
import T from 'Components/Helpers/translate';
import RelationSelectRelations from './RelationSelectRelations';
import InputRevealButton from 'App/components/InputRevealButton';
import OrderAttributes from 'App/babel/attributes/Order';
import OrderResource from 'App/resources/Order';
import RequestBuilder, { comparisonTypes } from 'Resources/RequestBuilder';
import { formatWithSubAccounts } from 'App/helpers/accountsHelper';

const fetchContacts = (query, clientId) => {
	const { RequestBuilder, Contact, AppService } = Tools;
	const contactFilter = RequestBuilder();
	if (query) {
		contactFilter.addFilter(Contact.attr.name, contactFilter.comparisonTypes.Search, query);
	}
	contactFilter.addFilter(Contact.attr.active, contactFilter.comparisonTypes.Equals, 1);
	contactFilter.addFilter({ field: 'client.active' }, contactFilter.comparisonTypes.Equals, 1);
	if (clientId) {
		contactFilter.addFilter({ field: 'client.id' }, contactFilter.comparisonTypes.Equals, clientId);
	}
	contactFilter.limit = 100;
	return Contact.customer(AppService.getCustomerId()).find(contactFilter.build());
};

const fetchOpportunities = (query, clientId) => {
	const opportunityFilter = new RequestBuilder();
	if (query) {
		opportunityFilter.addFilter(OrderAttributes.description, comparisonTypes.Search, query);
	}
	opportunityFilter.addFilter(OrderAttributes.probability, comparisonTypes.LessThan, 100);
	opportunityFilter.addFilter(OrderAttributes.probability, comparisonTypes.GreaterThan, 0);
	opportunityFilter.addFilter({ field: 'client.active' }, comparisonTypes.Equals, 1);
	if (clientId) {
		opportunityFilter.addFilter({ field: 'client.id' }, comparisonTypes.Equals, clientId);
	}
	opportunityFilter.limit = 20;

	return OrderResource.find(opportunityFilter.build());
};

const fetchAppointments = (query, clientId) => {
	const { RequestBuilder, Appointment, AppService } = Tools;
	const rb = RequestBuilder();
	if (query) {
		rb.addFilter(Appointment.attr.description, rb.comparisonTypes.Search, query);
	}
	rb.addFilter({ field: 'client.active' }, rb.comparisonTypes.Equals, 1);
	if (clientId) {
		rb.addFilter({ field: 'client.id' }, rb.comparisonTypes.Equals, clientId);
	} else {
		rb.addFilter({ field: 'client.id' }, rb.comparisonTypes.NotEquals, null);
	}

	rb.limit = 20;

	return Appointment.customer(AppService.getCustomerId()).find(rb.build());
};

const fetchActivities = (query, clientId) => {
	const { RequestBuilder, Activity, AppService } = Tools;
	const rb = RequestBuilder();
	if (query) {
		rb.addFilter(Activity.attr.description, rb.comparisonTypes.Search, query);
	}
	rb.addFilter({ field: 'client.active' }, rb.comparisonTypes.Equals, 1);
	if (clientId) {
		rb.addFilter({ field: 'client.id' }, rb.comparisonTypes.Equals, clientId);
	} else {
		rb.addFilter({ field: 'client.id' }, rb.comparisonTypes.NotEquals, null);
	}

	rb.limit = 20;

	return Activity.customer(AppService.getCustomerId()).find(rb.build());
};

const fetchClients = query => {
	const { RequestBuilder, Account, AppService } = Tools;

	const accountFilter = RequestBuilder();
	if (query) {
		accountFilter.addFilter(Account.attr.name, accountFilter.comparisonTypes.Search, query);
	}
	accountFilter.addFilter(Account.attr.active, accountFilter.comparisonTypes.Equals, 1);
	accountFilter.limit = 20;

	return Account.customer(AppService.getCustomerId()).find(accountFilter.build());
};

const generateCombinedResult = (clients, contacts, opportunities, appointments, activities) => {
	const ret = [];
	const hasSubaccounts = Tools.FeatureHelper.hasSoftDeployAccess('SUB_ACCOUNTS');

	if (clients.length) {
		if (hasSubaccounts) {
			ret.push(...formatWithSubAccounts([...clients.map(company => ({ ...company, type: 'company' }))], T));
		} else {
			ret.push({
				name: T('todo.editTodo.companies'),
				icon: 'home',
				children: clients.map(company => ({ ...company, type: 'company' }))
			});
		}
	}
	if (contacts.length) {
		ret.push({
			name: T('default.contacts'),
			icon: 'users',
			children: _.map(contacts, contact => ({ ...contact, type: 'contact' }))
		});
	}
	if (opportunities.length) {
		ret.push({
			name: T('default.opportunity'),
			icon: 'opportunity',
			children: _.map(opportunities, opportunity => ({
				...opportunity,
				type: 'opportunity',
				name: opportunity.description
			}))
		});
	}
	if (appointments.length) {
		ret.push({
			name: T('default.appointment'),
			icon: 'calendar',
			children: _.map(appointments, appointment => ({
				...appointment,
				type: 'appointment',
				name: appointment.description
			}))
		});
	}
	if (activities.length) {
		ret.push({
			name: T('default.activities'),
			icon: 'activity',
			children: _.map(activities, activity => ({
				...activity,
				type: 'activity',
				name: activity.description
			}))
		});
	}
	return ret;
};

function fetchData(query, client, contact, opportunity, appointment, activity) {
	return Promise.all([
		appointment ? { data: [] } : fetchAppointments(query, client?.id),
		opportunity ? { data: [] } : fetchOpportunities(query, client?.id),
		client ? { data: [] } : fetchClients(query),
		activity ? { data: [] } : fetchActivities(query, client?.id),
		contact ? { data: [] } : fetchContacts(query, client?.id)
	])
		.then(
			([
				{ data: appointments },
				{ data: opportunities },
				{ data: clients },
				{ data: activities },
				{ data: contacts, metadata: metadataContacts }
			]) => [generateCombinedResult(clients, contacts, opportunities, appointments, activities), metadataContacts]
		)
		.catch(err => {
			console.log(err);
		});
}
const config = {
	formatResult: function (obj, container, query, escape) {
		let result;
		if (obj.type === 'appointment' || obj.type === 'activity') {
			result =
				'<div style="display:flex; justify-content:center; flex-direction:column;">' +
				escape(obj.description) +
				(obj.date
					? ' <i class="subtitle" style="display: block;">' +
					  (obj.date ? escape(moment(obj.date).format('L LT')) : '') +
					  '</i>'
					: '') +
				'</div>' +
				'<div class="subtitle" style="align-self:center; text-align:end;">' +
				(obj.users?.length ? escape(obj.users[0].name) : '') +
				(obj.client?.name ? '<br>' + escape(obj.client.name) : '') +
				'</div> ';
		} else if (obj.type === 'opportunity') {
			const metadata = Tools.AppService.getMetadata();
			const masterCurrency = _.find(metadata.customerCurrencies, { masterCurrency: true });
			result =
				'<div style="display:flex; justify-content:center; flex-direction:column;">' +
				escape(obj.description) +
				(obj.closeDate || obj.value
					? ' <i class="subtitle" style="display: block;">' +
					  (obj.closeDate ? escape(moment(obj.closeDate).format('L')) : '') +
					  (obj.closeDate && obj.value ? ' | ' : '') +
					  (obj.value
							? escape(obj.value) + ' ' + (obj.currency ? escape(obj.currency) : masterCurrency.iso)
							: '') +
					  '</i>'
					: '') +
				'</div>' +
				'<div class="subtitle" style="align-self:center; text-align:end;">' +
				(obj.user ? escape(obj.user.name) : '') +
				(obj.client && obj.client.name ? '<br>' + escape(obj.client.name) : '') +
				'</div> ';
		} else if (obj.type === 'company') {
			const hasSubaccounts = Tools.FeatureHelper.hasSoftDeployAccess('SUB_ACCOUNTS');
			result = escape(obj.name);
			if (hasSubaccounts && obj.operationalAccount) {
				result +=
					'<div class="subtitle grey">' +
					Tools.$translate.instant('account.subaccount.tooltip', {
						parent: obj.operationalAccount.name
					}) +
					'</div>';
			}
		} else if (obj.type === 'contact') {
			result =
				'<div style="display:flex; justify-content:center; flex-direction:column;">' +
				escape(obj.name) +
				(obj.client ? ' <i class="subtitle" style="display: block;">' + escape(obj.client.name) + '</i>' : '') +
				'</div>';
		} else {
			return (
				`<div class="Icon Icon-${obj.icon} Icon--inherit" style="padding: 0 7px;"></div>` +
				escape(obj.name ? obj.name : '')
			);
		}
		return `<div style="display:flex;justify-content:space-between">${result}</div>`;
	}
};

const getButtonContent = (type, client) => {
	const icon = 'sitemap';
	let text = '';
	let required = false;

	if (!client && type === 'phonecall') {
		text = T('todo.whoAreYouCalling');
		required = true;
	} else {
		text = T(type === 'phonecall' ? 'todo.editPhonecall.isThisRelated' : 'todo.editTodo.isThisRelated');
	}

	return { icon, text, required };
};

const RelationSelect = props => {
	const {
		client,
		contact,
		opportunity,
		projectPlan,
		activity,
		appointment,
		onClear,
		onChange,
		className,
		space,
		locked,
		lockedClient,
		type = 'todo',
		lockedContact,
		disabled = false,
		missingAccess = false
	} = props;
	const triggerData = getButtonContent(type, client);
	const classes = new BemClass('RelationSelect', className);
	const [hasRelations, setHasRelations] = useState(
		!!(client || contact || opportunity || appointment || activity || projectPlan)
	);
	const [staticData, setStaticData] = useState(null);
	const [staticSelect, setStaticSelect] = useState(client || contact);
	useEffect(() => {
		setHasRelations(!!(client || contact || opportunity || appointment || projectPlan));
		setStaticSelect(client || contact);
	}, [client, opportunity, contact, appointment, activity || projectPlan]);

	// Fetch static data if locked client/contact
	useEffect(() => {
		let ignore = false;

		async function getData() {
			setStaticData(null);
			const [data, metadataContacts] = await fetchData(
				null,
				client,
				contact,
				opportunity,
				appointment,
				activity || projectPlan
			);
			if (metadataContacts?.total > metadataContacts?.limit) {
				setStaticSelect(false);
			}
			if (!ignore) {
				setStaticData(data);
			}
		}

		if (client?.id) {
			getData();
		}
		return () => {
			ignore = true;
		};
	}, [client?.id, contact?.id, activity?.id, opportunity?.id, appointment?.id, projectPlan?.id]);

	const onCreateContact = () => {
		// eslint-disable-next-line promise/catch-or-return
		Tools.$upModal.open('editContact', { account: client }).then(contact => {
			// Set created contact as selected
			onChange({ ...contact, type: 'contact' });
			setHasRelations(true);
		});
	};

	const canAddRelations = !locked && (!client || !contact || !activity || !opportunity || !appointment) && !disabled;

	let SelectComponent = null;
	if (canAddRelations) {
		const sharedProps = {
			className: classes.b(),
			staticAddButton: {
				onClick: () => onCreateContact(),
				label: T('default.createAContact')
			},
			onChange: relation => {
				onChange(relation);
				setHasRelations(true);
			},
			children: ({ showSelect }) => (
				<InputRevealButton
					icon={triggerData.icon}
					text={triggerData.text}
					required={triggerData.required}
					onClick={showSelect}
				/>
			),
			//This is required for the select to work as intended
			key: `${client ? 'client' : ''}${contact ? 'contact' : ''}${opportunity ? 'opportunity' : ''}${
				appointment ? 'appointment' : ''
			}${activity ? 'activity' : ''}`,
			config,
			space,
			value: null,
			disabled
		};

		if (staticSelect) {
			SelectComponent = staticData?.length ? <HiddenSelect {...sharedProps} data={staticData} /> : null;
		} else {
			SelectComponent = (
				<HiddenSelectAjax
					{...sharedProps}
					fetchData={async q => {
						const data = await fetchData(q, client, contact, opportunity, appointment, activity);
						return data[0];
					}}
				/>
			);
		}
	}

	return (
		<div>
			{hasRelations ? (
				<Block className={classes.b()} space={space}>
					<RelationSelectRelations
						labelText={T('todo.editTodo.relatedTo')}
						onClear={onClear}
						setHasRelations={setHasRelations}
						opportunity={opportunity}
						projectPlan={projectPlan}
						appointment={appointment}
						activity={activity}
						client={client}
						contacts={contact ? [contact] : []}
						locked={locked}
						lockedClient={lockedClient}
						lockedContact={lockedContact}
						disabled={disabled}
						missingAccess={missingAccess}
					/>
				</Block>
			) : null}
			{SelectComponent}
		</div>
	);
};
RelationSelect.propTypes = {
	client: PropTypes.shape({ name: PropTypes.string, journeyStep: PropTypes.string }),
	contact: PropTypes.object,
	opportunity: PropTypes.object,
	projectPlan: PropTypes.object,
	appointment: PropTypes.object,
	activity: PropTypes.object,
	contactInfo: PropTypes.array,
	className: PropTypes.string,
	space: PropTypes.string,
	onClear: PropTypes.func,
	locked: PropTypes.bool,
	onChange: PropTypes.func,
	lockedClient: PropTypes.bool,
	type: PropTypes.oneOf(['todo', 'phonecall']),
	lockedContact: PropTypes.bool,
	disabled: PropTypes.bool,
	missingAccess: PropTypes.bool
};

export const _fetchData = fetchData;
export const _config = config;

export default RelationSelect;
