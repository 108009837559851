import './TimelineRescheduledTitle.scss';
import React from 'react';
import { Text } from '@upsales/components';
import getPostponedDuration from 'Components/Helpers/getTimelinePostponeDuration';
import Event from 'App/resources/Model/Event';
import t from 'Components/Helpers/translate';
import BemClass from '@upsales/components/Utils/bemClass';

type Props = {
	date?: Date | string;
	user?: Event['user'];
	userRenderedElsewhere?: boolean;
};

const TimelineRescheduledTitle = ({ date, user, userRenderedElsewhere }: Props) => {
	const classes = new BemClass('TimelineRescheduledTitle');
	const lang = {
		rescheduledTo: t('activity.outcome.rescheduledTo')
	};
	const userName = user?.name ?? '';

	if (!date) {
		return null;
	}

	return (
		<span className={classes.b()}>
			{!userRenderedElsewhere && userName ? <Text bold>{userName}</Text> : null}{' '}
			<Text>{userName || userRenderedElsewhere ? lang.rescheduledTo.toLowerCase() : lang.rescheduledTo}</Text>{' '}
			<Text>{getPostponedDuration(new Date(date))}</Text>
		</span>
	);
};

export default TimelineRescheduledTitle;
