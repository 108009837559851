import { ChildAgreement } from 'App/components/EditSubscription/Context/SubscriptionGroupState';
import { useSubscriptionGroupContext } from '../../../Context/SubscriptionGroupContext';
import { Button, Icon, DateInput, Text } from '@upsales/components';
import BemClass from '@upsales/components/Utils/bemClass';
import React, { useEffect, useState } from 'react';
import T from 'Components/Helpers/translate';
import moment from 'moment';

import './SplitDateRow.scss';

type Props = {
	fromDate: Date;
	toDate: Date;
	endDate: Date;
	isFirst: boolean;
	isLast: boolean;
	previousDate: Date;
	currentChildren?: ChildAgreement[];
	fromOnChange: (value: Date) => void;
	toOnChange: (value: Date) => void;
	removeRow: () => void;
	disableRemove?: boolean;
};

const SplitDateRow = ({
	fromDate,
	toDate,
	fromOnChange,
	removeRow,
	toOnChange,
	disableRemove,
	previousDate,
	currentChildren,
	isFirst,
	isLast,
	endDate
}: Props) => {
	const {
		state: { isEdit }
	} = useSubscriptionGroupContext();

	const classes = new BemClass('SplitDateRow');

	const [toMaxDate, setToMaxDate] = useState<Date | undefined>();

	useEffect(() => {
		if (!currentChildren?.length) return;

		for (let i = currentChildren.length - 1; i >= 0; i--) {
			if (moment(fromDate).isSameOrAfter(currentChildren[i].startDate)) {
				setToMaxDate(new Date(currentChildren[i].endDate));
				toOnChange(new Date(currentChildren[i].endDate));
				break;
			}
		}
	}, [fromDate, currentChildren]);

	const renderDateInputExtraText = (value?: string | Date | null) => {
		if (!isLast) {
			return (
				<Text italic color="grey-10" className={classes.elem('date-input-text').b()}>{`(${T(
					'subscription.modal.summary.splitPage.nextPlannedChange'
				)})`}</Text>
			);
		}
		if (moment(value).isSame(endDate)) {
			return (
				<Text className={classes.elem('date-input-text').b()}>{`(${T(
					'subscription.modal.summary.splitPage.subscriptionIsRenewed'
				)})`}</Text>
			);
		}
		return null;
	};

	const getMinDate = () => {
		if (isEdit && moment(previousDate).isBefore()) {
			return moment().toDate();
		}
		return moment(previousDate)
			.add(isFirst ? 0 : 1, 'day')
			.toDate();
	};

	return (
		<div className={classes.b()}>
			<div>
				<Text bold>{T('default.from')}</Text>
				<DateInput
					autofocus
					min={getMinDate()}
					max={toMaxDate ? endDate : moment(toDate).subtract(1, 'day').toDate()}
					placeholder={T('subscription.modal.summary.splitPage.chooseDay')}
					closeOnSelect
					rightSideChevron
					value={fromDate}
					onChange={({ target }) => (target.value ? fromOnChange(target.value) : null)}
				/>
			</div>
			<div>
				<Text bold>{T('default.to')}</Text>
				<DateInput
					renderExtraContent={renderDateInputExtraText}
					min={moment(fromDate).add(1, 'day').toDate()}
					max={toMaxDate ?? endDate}
					disabled={!isLast}
					closeOnSelect
					rightSideChevron
					value={toDate}
					onChange={({ target }) => (target.value ? toOnChange(target.value) : null)}
				/>
				<Text bold></Text>
			</div>
			<Button disabled={disableRemove} onClick={removeRow} type="link">
				<Icon name="trash" />
				{T('subscription.modal.summary.splitPage.removeChange')}
			</Button>
		</div>
	);
};

export default SplitDateRow;
