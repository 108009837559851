import thunk, { ThunkAction, ThunkDispatch } from 'redux-thunk';
import config from 'App/babel/config';
import { createStore, combineReducers, compose, applyMiddleware, AnyAction } from 'redux';
import { useDispatch } from 'react-redux';

import Account from './reducers/AccountReducer';
import Activities from './reducers/ActivitiesReducer';
import ActivityOutcomes from './reducers/ActivityOutcomesReducer';
import AdminBrand from './reducers/AdminBrandReducer';
import AdminEditProduct from './reducers/AdminEditProductReducer';
import AdminEditSalesboard from './reducers/AdminEditSalesboardReducer';
import AdminGoals from 'Store/reducers/AdminGoalsReducer.js';
import AdminLoginStats from './reducers/AdminLoginStatsReducer';
import AdminProductList from './reducers/AdminProductListReducer';
import AdminSalesboard from './reducers/AdminSalesboardReducer';
import App from './reducers/AppReducer';
import Appointments from './reducers/AppointmentsReducer';
import AssignModal from './reducers/AssignModalReducer';
import Billing, { retrieveData } from './reducers/BillingReducer';
import BuyFromDataSourceInline from './reducers/BuyFromDataSourceReducer';
import ChooseMailIntegration from './reducers/ChooseMailIntegrationReducer';
import Competitor from './reducers/CompetitorReducer';
import ConfigIntegration from './reducers/ConfigIntegration';
import CreateAccount from './reducers/CreateAccount';
import DesiredOutcome from './reducers/DesiredOutcomeReducer';
import Domains from './reducers/DomainReducer';
import EasyBookingSettingsV2 from 'Components/Admin/EasyBookingSettingsV2/EasyBookingSettingsReducer';
import EditOrder from './reducers/EditOrderReducer';
import FileStorageApps from './reducers/FileStorageAppsReducer';
import FormEditor from './reducers/FormEditorReducer';
import FormOverview from './reducers/FormOverviewReducer';
import Goals from './reducers/GoalsReducer';
import GroupMailEditor from './reducers/GroupMailEditorReducer';
import NotesFilterActive from './slices/NotesFilterActiveSlice';
import Insights from './reducers/InsightsReducer';
import JourneyEdit from './reducers/JourneyEditorReducer';
import JourneyStatuses from './reducers/JourneyStatusesReducer';
import MailSignature from './reducers/MailSignatureReducer';
import MailTemplateEditor from './reducers/MailTemplateEditorReducer';
import MarketingBoard from './reducers/MarketingBoardReducer';
import MaxCeilingReachedOnSeats from './reducers/MaxCeilingReachedOnSeats';
import Modal from './reducers/ModalReducer';
import NotificationCenter from './reducers/NotificationCenterReducer';
import Onboarding from './reducers/OnboardingReducer';
import OpportunityAI from './reducers/OpportunityAIReducer';
import Periodization from './reducers/PeriodizationReducer';
import Prospecting from 'Store/reducers/Prospecting';
import ProspectingExisting from 'Store/reducers/ProspectingExisting';
import ProspectingManageSelection from 'Store/reducers/ProspectingManageSelection';
import ProspectingManual from 'Store/reducers/ProspectingManual';
import ProspectingShared from 'Store/reducers/ProspectingShared';
import ProspectingSignals from './reducers/ProspectingSignalsReducer';
import ReduxListeners from './ReduxListeners';
import RefreshAccounts from './reducers/RefreshAccount';
import Reminders from './reducers/RemindersReducer';
import Reportcenter from './reducers/ReportcenterReducer';
import Salesboard from './reducers/SalesboardReducer';
import SalesCoachReducer from './reducers/SalesCoachReducer';
import SharedViews from './reducers/SharedViewsReducer';
import SignalsFeed from './reducers/SignalsFeedReducer';
import SingleSignOn from './reducers/SingleSignOnReducer';
import SocialEvent from './reducers/SocialEventReducer';
import SocialEventResource from './reducers/SocialEventResourceReducer';
import SystemNotification, { removeAllNotifications } from './reducers/SystemNotificationReducer';
import TemplateEditor from './reducers/TemplateEditorReducer';
import Todo from './reducers/TodoReducer';
import TranslateField from './reducers/TranslateFieldReducer';
import AdvancedSearch from './reducers/AdvancedSearchReducer';
import EditListener from './reducers/EditListenerReducer';

export const RESET_ALL_REDUCERS = 'RESET_ALL_REDUCERS';

let composeEnhancers = compose;

if (config.ENV === 'DEV' && typeof window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ === 'function') {
	composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__({
		trace: true,
		traceLimit: 25
	});
}

const appReducer = combineReducers({
	Account,
	Activities,
	ActivityOutcomes,
	AdminBrand,
	AdminEditProduct,
	AdminEditSalesboard,
	AdminGoals,
	AdminLoginStats,
	AdminProductList,
	AdminSalesboard,
	App,
	Appointments,
	AssignModal,
	Billing,
	BuyFromDataSourceInline,
	ChooseMailIntegration,
	Competitor,
	ConfigIntegration,
	CreateAccount,
	DesiredOutcome,
	Domains,
	EasyBookingSettingsV2,
	EditOrder,
	FileStorageApps,
	FormEditor,
	FormOverview,
	Goals,
	GroupMailEditor,
	NotesFilterActive,
	Insights,
	JourneyEdit,
	JourneyStatuses,
	MailSignature,
	MailTemplateEditor,
	MarketingBoard,
	MaxCeilingReachedOnSeats,
	Modal,
	NotificationCenter,
	Onboarding,
	OpportunityAI,
	Periodization,
	Prospecting,
	ProspectingExisting,
	ProspectingManageSelection,
	ProspectingManual,
	ProspectingShared,
	ProspectingSignals,
	RefreshAccounts,
	Reminders,
	Reportcenter,
	Salesboard,
	SalesCoachReducer,
	SharedViews,
	SignalsFeed,
	SingleSignOn,
	SocialEvent,
	SocialEventResource,
	SystemNotification,
	TemplateEditor,
	Todo,
	TranslateField,
	AdvancedSearch,
	EditListener
});

const rootReducer = (state: any, action: AnyAction) => {
	// This one is used on login/out and we want to reset all states to the initial state. Except for the App.loading state, this should always be the same as before
	if (action.type === RESET_ALL_REDUCERS) {
		action.loading = state.App.loading;
		state = undefined;
	}
	return appReducer(state, action);
};

const store = createStore(rootReducer, composeEnhancers(applyMiddleware(thunk)));

ReduxListeners.setStore(store);

window.reducerActions = {
	resetAll: () => store.dispatch({ type: RESET_ALL_REDUCERS })
};

window.store = {
	Billing: {
		retrieveData: () => store.dispatch(retrieveData() as unknown as AnyAction)
	},
	SystemNotification: {
		removeAllNotifications: () => store.dispatch(removeAllNotifications() as unknown as AnyAction)
	}
};

export default store;

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
/** @deprecated Please use the one from App/components/hooks */
export const useAppDispatch = () => useDispatch<ThunkDispatch<RootState, any, AnyAction>>(); // Export a hook that can be reused to resolve types
export type AppThunk<ReturnType = void> = ThunkAction<ReturnType, RootState, unknown, AnyAction>;
