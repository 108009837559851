import { validateOrgNumberForCountry } from 'App/services/prospectingService';

const emailRegExp =
	/^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
const phoneRegExp = /^[+]?[(]?[0-9]{3}[)]?[-\s.]?[0-9]{3}[-\s.]?[0-9]{4,6}$/im;

class ValidationService {
	static validEmail(email) {
		return !!(email && emailRegExp.test(email));
	}

	static validatePhone(phone) {
		return !!(phone && phoneRegExp.test(phone));
	}

	static validateOrgNumber(orgNumber, country = 'SE') {
		return validateOrgNumberForCountry(country, orgNumber);
	}
}

export default ValidationService;
