import React, { useMemo } from 'react';
import {
	StaticSelect,
	StaticMultiSelect,
	type StaticSelectConsumerProps,
	type StaticMultiSelectConsumerProps
} from '../StaticSelect';
import { useTranslation } from 'Components/Helpers/translate';
import useSelector from 'App/components/hooks/useSelector/useSelector';
import JourneyStep from 'App/resources/Model/JourneyStep';

const useSharedProps = () => {
	const { t } = useTranslation();
	const placeholder = t('appointment.outcome.action.changeJourney');

	const journeySteps = useSelector(({ App }) => App.journeySteps);
	const options = useMemo(() => {
		return (
			journeySteps?.map(step => ({
				...step,
				id: step.value,
				title: step.name
			})) ?? []
		);
	}, [journeySteps]);

	return { placeholder, options };
};

export const StaticJourneyStepSelect = (props: StaticSelectConsumerProps<JourneyStep>) => {
	const sharedProps = useSharedProps();
	return <StaticSelect {...sharedProps} {...props} />;
};

export const StaticJourneyStepMultiSelect = (props: StaticMultiSelectConsumerProps<JourneyStep>) => {
	const sharedProps = useSharedProps();
	return <StaticMultiSelect {...sharedProps} {...props} />;
};
