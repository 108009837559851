import { Avatar, AvatarStack, Tooltip } from '@upsales/components';
import BemClass from '@upsales/components/Utils/bemClass';
import User from 'App/resources/Model/User';
import T from 'Components/Helpers/translate';
import React, { useEffect, useMemo, useState } from 'react';

import './UserStack.scss';
import { CancelablePromise, makeCancelable } from '@upsales/components/Utils/CancelablePromise';

type UserStackProps = {
	users: Pick<User, 'id' | 'name' | 'email'>[];
};

const UserStack: React.FC<UserStackProps> = ({ users }) => {
	const classes = new BemClass('UserStack');
	const lang = {
		and: T('and')
	};
	const [userImgMap, setUserImgMap] = useState<{ [email: string]: { url: string } }>({});
	const tooltipText = useMemo(() => {
		const names = users.map(user => user.name) || [];
		if (names.length > 1) {
			const first = names.slice(0, -1);
			const last = names[names.length - 1];
			return `${first.join(', ')} ${lang.and} ${last}`;
		} else {
			return names.join(', ');
		}
	}, [users]);
	useEffect(() => {
		let fetchUserImg: CancelablePromise<{ profile: Tools.Avatar; email: string }[]>;
		const fn = async () => {
			fetchUserImg = makeCancelable(
				Promise.all(
					users.map(user =>
						Tools.avatarService.getAvatar(user).then(res => ({ profile: res, email: user.email }))
					)
				)
			);
			fetchUserImg.promise
				.then(profiles => {
					const tmpUserImgMap = profiles.reduce((map, data) => {
						const { profile, email } = data;
						map[email] = profile;
						return map;
					}, {} as { [email: string]: { url: string } });
					setUserImgMap(tmpUserImgMap);
				})
				.catch();
		};

		fn();
		return () => {
			fetchUserImg?.cancel();
		};
	}, [users]);

	return (
		<div className={classes.b()}>
			<Tooltip title={tooltipText || ''} distance={20}>
				<AvatarStack max={3} size={'sm'}>
					{users.map(user => {
						const img = userImgMap[user?.email];
						if (img) {
							return <Avatar key={user.id} initials={user.name} src={img.url} />;
						} else {
							return <Avatar key={user.id} initials={user.name} />;
						}
					})}
				</AvatarStack>
			</Tooltip>
		</div>
	);
};

export default UserStack;
