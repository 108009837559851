import Prospecting from 'App/babel/resources/Prospecting';
import logError from 'App/babel/helpers/logError';
import FilterConfigs from 'App/babel/filterConfigs/Prospecting';
const filterConfigs = FilterConfigs();
import RequestBuilder, { comparisonTypes } from 'Resources/RequestBuilder';
import { Tabs, LIMIT, UPDATE_VIEW, SET_QUICK_SERCH_VALUE } from './ProspectingShared';
import { prospectingTracker } from 'App/babel/helpers/Tracker';
import { isKeywordsSupportedForCountry, StatusCategory } from 'App/services/prospectingService';

const SET_HAS_CHANGED = '[ProspectingManual] Set has changed';
const SET_EXCLUDE_FILTERS = '[ProspectingManual] Set exclude filters';
const SET_INCLUDE_FILTERS = '[ProspectingManual] Set include filters';
const RESET = '[ProspectingManual] Reset';
const SET_SELECT_DATA = '[ProspectingManual] Set select data';
const SET_COUNTRY = '[ProspectingManual] Set country';

const requiredFields = [
	'name',
	'cfar',
	'orgNumber',
	'revenue',
	'postTown',
	'prospectingId',
	'employeesRangeStart',
	'employeesRangeEnd',
	'noEmployees',
	'headquarters',
	'groupSize',
	'activityText',
	'website',
	'linkedin',
	'descriptionSv',
	'description',
	'revenueRangeStart',
	'revenueRangeEnd',
	'companyForm'
];

const countryConfigs = {
	GB: {
		initialFilters: ['IndustryGB', 'LocationGB', 'EmployeesGB', 'FinancialsGB', 'NotInUpsales', 'RegDate'],
		allFilters: ['IndustryGB', 'LocationGB', 'EmployeesGB', 'FinancialsGB', 'Name', 'NotInUpsales', 'RegDate']
	},
	SE: {
		initialFilters: [
			'CompanyType',
			'Industry',
			'Location',
			'Employees',
			'Financials',
			'Headquarters',
			'NotInUpsales',
			'RegDate'
		],
		allFilters: [
			'CompanyType',
			'Industry',
			'Location',
			'Employees',
			'Financials',
			'Name',
			'Headquarters',
			'NotInUpsales',
			'RegDate'
		]
	},
	NO: {
		initialFilters: [
			'CompanyForm',
			'IndustryNO',
			'LocationNO',
			'EmployeesNO',
			'FinancialsNO',
			'Headquarters',
			'NotInUpsales',
			'RegDate'
		],
		allFilters: [
			'CompanyForm',
			'IndustryNO',
			'LocationNO',
			'EmployeesNO',
			'FinancialsNO',
			'Name',
			'Headquarters',
			'NotInUpsales',
			'RegDate'
		]
	}
};

export const initialState = {
	includeFilters: {
		isExclude: false,
		config: {},
		orGroup: false
	},
	excludeFilters: {
		isExclude: true,
		config: {},
		orGroup: true
	},
	selectData: {},
	country: 'SE',
	hasChanged: false
};

/*********** Actions **********/
const getConfig = filterName => {
	return filterConfigs[filterName];
};

export const getConfigs = () => (dispatch, getState) => {
	//Removing PROSPECTING_PRO_SIGNALS when ticket that splits it in to two is in BETA
	const trialAddonsArray = ['PROSPECTING_PRO_SIGNALS', 'PROSPECTING_PRO_ENTERPRISE'];
	const { country } = getState().ProspectingManual;
	const { trialAddons } = getState().Billing;
	const filters = countryConfigs[country].allFilters;
	const keywordsIsSupported = isKeywordsSupportedForCountry(country);
	const hasProspectingPro = Tools.FeatureHelper.isAvailable(Tools.FeatureHelper.Feature.PROSPECTING_PRO);
	if (hasProspectingPro && keywordsIsSupported) {
		filters.push('Keywords');
	}
	const filteredFilterConfig = _.pick(filterConfigs, filters);
	const isProTrial = trialAddons.find(addon => trialAddonsArray.includes(addon.value));
	if (isProTrial) {
		if (filteredFilterConfig.Keywords) {
			filteredFilterConfig.Keywords.icon = 'pro';
		}
		if (filteredFilterConfig.Financials) {
			filteredFilterConfig.Financials.icon = 'pro';
		}
	}
	return filteredFilterConfig;
};

const getSelectData = filterNames => dispatch => {
	const selectData = {};

	for (const filterName of filterNames) {
		const config = filterConfigs[filterName];
		if (config?.dataPromise) {
			selectData[config.filterName] = config.dataPromise();
		}
	}

	dispatch({ type: SET_SELECT_DATA, selectData });
};

export const getFilters = isExclude => (dispatch, getState) => {
	return isExclude ? getState().ProspectingManual.excludeFilters : getState().ProspectingManual.includeFilters;
};

export const setFilters = (isExclude, filters) => dispatch => {
	if (isExclude) {
		dispatch({ type: SET_EXCLUDE_FILTERS, filters });
	} else {
		dispatch({ type: SET_INCLUDE_FILTERS, filters });
	}
};

export const init = country => dispatch => {
	const isKeywordsEnabled = Tools.FeatureHelper.isAvailable(Tools.FeatureHelper.Feature.PROSPECTING_PRO);

	const config = countryConfigs[country].initialFilters.reduce(
		(res, filterName) => {
			res[filterName] = Tools.FilterHelper.filter(filterName, 'Prospecting', { getConfig });
			return res;
		},
		isKeywordsEnabled ? { Keywords: Tools.FilterHelper.filter('Keywords', 'Prospecting', { getConfig }) } : {}
	);

	config.NotInUpsales.value = true;
	config.NotInUpsales.inactive = false;

	dispatch({ type: SET_COUNTRY, country });
	dispatch(setFilters(false, { isExclude: false, orGroup: false, config }));
	if (country === 'SE') {
		dispatch({ type: UPDATE_VIEW, view: Tabs.Search, sorting: 'revenueSort', asc: false });
		dispatch({ type: UPDATE_VIEW, view: Tabs.Selected, sorting: 'revenueSort', asc: false });
	}
	dispatch(getSelectData(countryConfigs[country].allFilters));
};

export const reset = () => ({ type: RESET });

let latestRequestId = 0;

export const search = () => async (dispatch, getState) => {
	const { includeFilters, excludeFilters, country } = getState().ProspectingManual;
	const {
		quickSearchValue,
		[Tabs.Search]: { offset, sorting, asc }
	} = getState().ProspectingShared;

	const includeBuild = Tools.FilterHelper.parseFilters(includeFilters.config, 'Prospecting', null, null, {
		getConfig
	}).build();

	const excludeBuild = Tools.FilterHelper.parseFilters(excludeFilters.config, 'Prospecting', null, null, {
		getConfig
	}).build();

	if (
		!includeBuild?.q?.length ||
		includeBuild.q.every(filter => filter.a === 'headquarters' || filter.a === 'notInUpsales')
	) {
		return dispatch({ type: UPDATE_VIEW, view: Tabs.Search, data: [], total: 0 });
	}

	const requestId = ++latestRequestId;

	const filters = Tools.FilterHelper.mergeSegmentFilters([
		{ ...includeFilters, body: { q: includeBuild.q || [] } },
		{ ...excludeFilters, body: { q: excludeBuild.q || [] } }
	]);

	if (quickSearchValue) {
		filters.q.push({ a: 'name', c: 'src', v: quickSearchValue });
	}
	filters.q.push({ a: 'statusCategory', c: 'eq', v: StatusCategory.Active });
	filters.country = country;

	if (sorting) {
		filters.sort = { a: sorting, s: asc ? 'A' : 'Z' };
	}

	filters.offset = offset;
	filters.limit = LIMIT;
	filters.f = requiredFields;

	dispatch({ type: UPDATE_VIEW, view: Tabs.Search, loading: true });

	try {
		const {
			data,
			metadata: { total }
		} = await Prospecting.find(filters);

		if (requestId === latestRequestId) {
			dispatch({ type: UPDATE_VIEW, view: Tabs.Search, data, total, loading: false });
		}
	} catch (error) {
		logError(error, 'ui/app/babel/store/reducers/ProspectingManual.js');
		dispatch({ type: UPDATE_VIEW, view: Tabs.Search, loading: false });
	}
};

export const searchSelected = () => async (dispatch, getState) => {
	const { country } = getState().ProspectingManual;
	const {
		quickSearchValue,
		selection,
		[Tabs.Selected]: { offset, sorting, asc }
	} = getState().ProspectingShared;

	const prospectingIds = Object.keys(selection);

	if (!prospectingIds.length) {
		dispatch({ type: UPDATE_VIEW, view: Tabs.Selected, data: [], loading: false });
		return;
	}

	dispatch({ type: UPDATE_VIEW, view: Tabs.Selected, loading: true });

	const rb = new RequestBuilder();

	rb.addFilter({ field: 'prospectingId' }, comparisonTypes.Equals, prospectingIds);
	rb.extraParams.push({ key: 'country', value: country });

	if (quickSearchValue) {
		rb.addFilter({ field: 'name' }, comparisonTypes.Search, quickSearchValue);
	}

	rb.addSort(sorting, asc);
	rb.offset = offset;
	rb.limit = LIMIT;
	rb.fields = requiredFields;

	try {
		const { data } = await Prospecting.find(rb.build());
		dispatch({ type: UPDATE_VIEW, view: Tabs.Selected, data, loading: false });
	} catch (error) {
		logError(error, 'ui/app/babel/store/reducers/ProspectingManual.js');
		dispatch({ type: UPDATE_VIEW, view: Tabs.Selected, loading: false });
	}
};

export const onFilterChange = () => dispatch => {
	dispatch({ type: SET_HAS_CHANGED, value: true });
	dispatch({ type: SET_QUICK_SERCH_VALUE, quickSearchValue: '' });
	dispatch({ type: UPDATE_VIEW, view: Tabs.Search, offset: 0 });
};

export const addFilter = (isExclude, filterName) => dispatch => {
	dispatch(onFilterChange());

	const filters = dispatch(getFilters(isExclude));
	const filter = Tools.FilterHelper.filter(filterName, 'Prospecting', { getConfig });
	filter.inactive = Tools.FilterHelper.isInactiveValue(filter, 'Prospecting', null, { getConfig });
	const newFilters = { ...filters, config: { ...filters.config } };
	newFilters.config[filter.filterName] = filter;
	if (filterName === 'Keywords' && !filter.inactive) {
		dispatch({ type: UPDATE_VIEW, view: Tabs.Search, sorting: 'relevance', asc: false });
	}
	dispatch(setFilters(isExclude, newFilters));

	dispatch(search());
};

export const editFilter = (isExclude, filterName, filter) => dispatch => {
	dispatch(onFilterChange());
	const filters = dispatch(getFilters(isExclude));
	filter.inactive = Tools.FilterHelper.isInactiveValue(filter, 'Prospecting', null, { getConfig });
	const newFilters = { ...filters, config: { ...filters.config } };
	newFilters.config[filterName] = filter;
	if (filterName === 'Keywords' && !filter.inactive) {
		dispatch({ type: UPDATE_VIEW, view: Tabs.Search, sorting: 'relevance', asc: false });
	}
	dispatch(setFilters(isExclude, newFilters));

	if (filterName === 'NotInUpsales') {
		prospectingTracker.track(prospectingTracker.events.TOGGLE_IN_UPSALES, { value: !!filter.value });
	}

	dispatch(search());
};

export const removeFilter = (isExclude, filterName) => dispatch => {
	dispatch(onFilterChange());

	const filters = dispatch(getFilters(isExclude));
	const newFilters = { ...filters, config: { ...filters.config } };
	delete newFilters.config[filterName];
	dispatch(setFilters(isExclude, newFilters));

	dispatch(search());
};

export const toggleOrGroup = isExclude => dispatch => {
	dispatch(onFilterChange());

	const filters = dispatch(getFilters(isExclude));
	const newFilters = { ...filters };
	newFilters.orGroup = !newFilters.orGroup;
	dispatch(setFilters(isExclude, newFilters));

	dispatch(search());
};

const ACTION_HANDLERS = {
	[SET_HAS_CHANGED]: (state, { value }) => ({ ...state, hasChanged: value }),
	[SET_EXCLUDE_FILTERS]: (state, { filters }) => ({ ...state, excludeFilters: filters }),
	[SET_INCLUDE_FILTERS]: (state, { filters }) => ({ ...state, includeFilters: filters }),
	[RESET]: () => initialState,
	[SET_SELECT_DATA]: (state, { selectData }) => ({ ...state, selectData }),
	[SET_COUNTRY]: (state, { country }) => ({ ...state, country })
};

export default (state = initialState, action) => {
	const handler = ACTION_HANDLERS[action.type];
	return handler ? handler(state, action) : state;
};
