import React from 'react';

const imageRenderType = ({ row, config, object, getUser, runStandardIntegration, runClick }) => {
	var imageProps = {
		src: row.url,
		className: ''
	};

	if (row.center) {
		imageProps.className += 'image-center ';
	}

	if (row.click) {
		imageProps.className += 'clickable';
		imageProps.onClick = event => {
			if (event && event.stopPropagation) {
				event.stopPropagation();
			}

			if (row.click.type === 'widgetRow') {
				runStandardIntegration({
					type: 'widgetRow',
					data: { object: object, user: getUser() },
					typeId: row.click.name,
					integrationId: config.integrationId
				});
			} else {
				runClick(row);
			}
		};
	}

	return <div className="widget-image">{React.createElement('img', imageProps)}</div>;
};

export default imageRenderType;
