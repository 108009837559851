import { FilterConfig } from 'App/babel/filterConfigs/FilterConfig';
import { FilterPreset, generateFilter } from '../filterGenerators';
import { comparisonTypes } from 'Resources/RequestBuilder';
import T from 'Components/Helpers/translate';
import AccessType from 'App/enum/AccessType';

export default (): { [key: string]: FilterConfig } => ({
	Date: {
		filterName: 'Date',
		type: 'raw',
		title: 'Period',
		inputType: 'select',
		multiple: false,
		multiComparitors: false,
		comparisonType: 'Equals',
		displayText: 'name',
		searchField: 'name',
		build: (filter, rb) => {
			rb.addFilter({ field: 'date' }, comparisonTypes.Equals, filter.value[0]);
		},
		dataPromise: () => {
			const hasBrokenFiscalYear = Tools.AppService.getMetadata().params.brokenFiscalYearEnabled;
			const filterPrefix = hasBrokenFiscalYear ? 'CurrentFiscal' : 'Current';
			return Promise.resolve({
				data: [
					{ id: 'CurrentMonth', name: T('current_month') },
					{ id: filterPrefix + 'Quarter', name: T('current_quarter') },
					{ id: filterPrefix + 'Year', name: T('current_year') }
				]
			});
		}
	},
	OpportunityStage: generateFilter(
		FilterPreset.Stage,
		{
			field: 'stage',
			multiple: true,
			multiComparitors: true,
			inputType: 'list',
			dataPromise: async () => {
				const result = Tools.AppService.getStages('opportunity').filter(
					stage => stage.probability > 0 && stage.probability < 100
				);

				return { data: result };
			}
		},
		true
	),
	User: generateFilter(
		FilterPreset.User,
		{
			field: 'user',
			title: 'default.users',
			parent: 'default.user'
		},
		AccessType.REPORT
	)
});
