const { momentTags } = require('Helpers/moment');
const moment = require('moment-timezone');

var presets = {
	whenever: {
		value: 'whenever',
		name: 'date.whenever',
		get: function () {
			return {
				start: null,
				end: null
			};
		}
	},
	// Until/from * ranges sortId 0-9
	untilToday: {
		value: 'untilToday',
		name: 'date.untilToday',
		sortId: 0,
		get: function (format, useTags) {
			var momentObj = useTags ? momentTags : moment;
			return {
				start: null,
				end: momentObj().endOf('day').format(format)
			};
		}
	},
	untilTomorrow: {
		value: 'untilTomorrow',
		name: 'date.untilTomorrow',
		sortId: 1,
		get: function (format, useTags) {
			var momentObj = useTags ? momentTags : moment;
			return {
				start: null,
				end: momentObj().endOf('day').add(1, 'day').format(format)
			};
		}
	},
	untilTwoDays: {
		value: 'untilTwoDays',
		name: 'date.untilTwoDays',
		sortId: 2,
		get: function (format, useTags) {
			var momentObj = useTags ? momentTags : moment;
			return {
				start: null,
				end: momentObj().endOf('day').add(2, 'day').format(format)
			};
		}
	},
	untilOneWeek: {
		value: 'untilOneWeek',
		name: 'date.untilOneWeek',
		sortId: 3,
		get: function (format, useTags) {
			var momentObj = useTags ? momentTags : moment;
			return {
				start: null,
				end: momentObj().endOf('day').add(1, 'week').format(format)
			};
		}
	},
	untilTwoWeeks: {
		value: 'untilTwoWeeks',
		name: 'date.untilTwoWeeks',
		sortId: 4,
		get: function (format, useTags) {
			var momentObj = useTags ? momentTags : moment;
			return {
				start: null,
				end: momentObj().endOf('day').add(2, 'week').format(format)
			};
		}
	},
	untilOneMonth: {
		value: 'untilOneMonth',
		name: 'date.untilOneMonth',
		sortId: 5,
		get: function (format, useTags) {
			var momentObj = useTags ? momentTags : moment;
			return {
				start: null,
				end: momentObj().endOf('day').add('months', 1).format(format)
			};
		}
	},
	untilOneQuarter: {
		value: 'untilOneQuarter',
		name: 'date.untilOneQuarter',
		sortId: 6,
		get: function (format, useTags) {
			var momentObj = useTags ? momentTags : moment;
			return {
				start: null,
				end: momentObj().endOf('day').add('quarter', 1).format(format)
			};
		}
	},
	untilSixMonths: {
		value: 'untilSixMonths',
		name: 'date.untilSixMonths',
		sortId: 7,
		get: function (format, useTags) {
			var momentObj = useTags ? momentTags : moment;
			return {
				start: null,
				end: momentObj().endOf('day').add('months', 6).format(format)
			};
		}
	},
	untilOneYear: {
		value: 'untilOneYear',
		name: 'date.untilOneYear',
		sortId: 8,
		get: function (format, useTags) {
			var momentObj = useTags ? momentTags : moment;
			return {
				start: null,
				end: momentObj().endOf('day').add('year', 1).format(format)
			};
		}
	},
	fromToday: {
		value: 'fromToday',
		name: 'date.fromToday',
		sortId: 9,
		get: function (format, useTags) {
			var momentObj = useTags ? momentTags : moment;
			return {
				start: momentObj().startOf('day').format(format),
				end: null
			};
		}
	},

	// Day ranges sortId 10-19
	currentDay: {
		value: 'currentDay',
		name: 'date.today',
		sortId: 10,
		get: function (format, useTags) {
			var momentObj = useTags ? momentTags : moment;
			return {
				start: momentObj().startOf('day').format(format),
				end: momentObj().endOf('day').format(format)
			};
		}
	},
	lastDay: {
		value: 'lastDay',
		name: 'date.lastDay',
		sortId: 11,
		get: function (format, useTags) {
			var momentObj = useTags ? momentTags : moment;
			return {
				start: momentObj().subtract(1, 'day').startOf('day').format(format),
				end: momentObj().subtract(1, 'day').endOf('day').format(format)
			};
		}
	},
	nextDay: {
		value: 'nextDay',
		name: 'date.nextDay',
		sortId: 12,
		get: function (format, useTags) {
			var momentObj = useTags ? momentTags : moment;
			return {
				start: momentObj().add(1, 'day').startOf('day').format(format),
				end: momentObj().add(1, 'day').endOf('day').format(format)
			};
		}
	},

	// Week ranges sortId 20-29
	currentWeek: {
		value: 'currentWeek',
		name: 'date.currentWeek',
		sortId: 20,
		get: function (format, useTags) {
			var momentObj = useTags ? momentTags : moment;
			return {
				start: momentObj().startOf('week').startOf('day').format(format),
				end: momentObj().endOf('week').endOf('day').format(format)
			};
		}
	},
	lastWeek: {
		value: 'lastWeek',
		name: 'date.lastWeek',
		sortId: 21,
		get: function (format, useTags) {
			var momentObj = useTags ? momentTags : moment;
			return {
				start: momentObj().startOf('week').subtract(1, 'week').startOf('day').format(format),
				end: momentObj().endOf('week').subtract(1, 'week').endOf('day').format(format)
			};
		}
	},
	nextWeek: {
		value: 'nextWeek',
		name: 'date.nextWeek',
		sortId: 22,
		get: function (format, useTags) {
			var momentObj = useTags ? momentTags : moment;
			return {
				start: momentObj().startOf('week').add(1, 'week').startOf('day').format(format),
				end: momentObj().endOf('week').add(1, 'week').endOf('day').format(format)
			};
		}
	},
	soFarThisWeek: {
		value: 'soFarThisWeek',
		name: 'date.soFarThisWeek',
		sortId: 23,
		get: function (format, useTags) {
			var momentObj = useTags ? momentTags : moment;
			return {
				start: momentObj().startOf('week').startOf('day').format(format),
				end: momentObj().format(format)
			};
		}
	},

	// Month ranges sortId 30-39
	currentMonth: {
		value: 'currentMonth',
		name: 'date.currentMonth',
		sortId: 30,
		get: function (format, useTags) {
			var momentObj = useTags ? momentTags : moment;
			return {
				start: momentObj().startOf('month').format(format),
				end: momentObj().endOf('month').format(format)
			};
		}
	},
	lastMonth: {
		value: 'lastMonth',
		name: 'date.lastMonth',
		sortId: 31,
		get: function (format, useTags) {
			var momentObj = useTags ? momentTags : moment;
			return {
				start: momentObj().startOf('month').subtract(1, 'month').format(format),
				end: momentObj().subtract(1, 'month').endOf('month').format(format)
			};
		}
	},
	nextMonth: {
		value: 'nextMonth',
		name: 'date.nextMonth',
		sortId: 32,
		get: function (format, useTags) {
			var momentObj = useTags ? momentTags : moment;
			return {
				start: momentObj().add('months', 1).startOf('month').format(format),
				end: momentObj().add('months', 1).endOf('month').format(format)
			};
		}
	},
	soFarThisMonth: {
		value: 'soFarThisMonth',
		name: 'date.soFarThisMonth',
		sortId: 33,
		get: function (format, useTags) {
			var momentObj = useTags ? momentTags : moment;
			return {
				start: momentObj().startOf('month').format(format),
				end: momentObj().format(format)
			};
		}
	},

	// Quarter ranges sortId 40-49
	currentQuarter: {
		value: 'currentQuarter',
		name: 'date.currentQuarter',
		sortId: 40,
		get: function (format, useTags) {
			var momentObj = useTags ? momentTags : moment;
			return {
				start: momentObj().startOf('quarter').format(format),
				end: momentObj().endOf('quarter').format(format)
			};
		}
	},
	lastQuarter: {
		value: 'lastQuarter',
		name: 'date.lastQuarter',
		sortId: 41,
		get: function (format, useTags) {
			var momentObj = useTags ? momentTags : moment;
			return {
				start: momentObj().startOf('quarter').subtract(1, 'quarter').format(format),
				end: momentObj().subtract(1, 'quarter').endOf('quarter').format(format)
			};
		}
	},
	nextQuarter: {
		value: 'nextQuarter',
		name: 'date.nextQuarter',
		sortId: 42,
		get: function (format, useTags) {
			var momentObj = useTags ? momentTags : moment;
			return {
				start: momentObj().startOf('quarter').add(1, 'quarter').format(format),
				end: momentObj().add(1, 'quarter').endOf('quarter').format(format)
			};
		}
	},

	// Year ranges sortId 50-59
	currentYear: {
		value: 'currentYear',
		name: 'date.currentYear',
		sortId: 50,
		get: function (format, useTags) {
			var momentObj = useTags ? momentTags : moment;
			return {
				start: momentObj().startOf('year').format(format),
				end: momentObj().endOf('year').format(format)
			};
		}
	},
	lastYear: {
		value: 'lastYear',
		name: 'date.lastYear',
		sortId: 51,
		get: function (format, useTags) {
			var momentObj = useTags ? momentTags : moment;
			return {
				start: momentObj().startOf('year').subtract(1, 'year').format(format),
				end: momentObj().subtract(1, 'year').endOf('year').format(format)
			};
		}
	},
	nextYear: {
		value: 'nextYear',
		name: 'date.nextYear',
		sortId: 52,
		get: function (format, useTags) {
			var momentObj = useTags ? momentTags : moment;
			return {
				start: momentObj().startOf('year').add(1, 'year').format(format),
				end: momentObj().add(1, 'year').endOf('year').format(format)
			};
		}
	},
	soFarThisYear: {
		value: 'soFarThisYear',
		name: 'date.soFarThisYear',
		sortId: 53,
		get: function (format, useTags) {
			var momentObj = useTags ? momentTags : moment;
			return {
				start: momentObj().startOf('year').format(format),
				end: momentObj().format(format)
			};
		}
	},
	last7days: {
		value: 'last7days',
		name: 'date.last7days',
		sortId: 54,
		get: function (format, useTags) {
			var momentObj = useTags ? momentTags : moment;
			return {
				start: momentObj().subtract('days', 7).startOf('day').format(format),
				end: momentObj().endOf('day').format(format)
			};
		}
	},
	last14days: {
		value: 'last14days',
		name: 'date.last14days',
		sortId: 55,
		get: function (format, useTags) {
			var momentObj = useTags ? momentTags : moment;
			return {
				start: momentObj().subtract('days', 14).startOf('day').format(format),
				end: momentObj().endOf('day').format(format)
			};
		}
	},
	last30days: {
		value: 'last30days',
		name: 'date.last30days',
		sortId: 56,
		get: function (format, useTags) {
			var momentObj = useTags ? momentTags : moment;
			return {
				start: momentObj().subtract('days', 30).startOf('day').format(format),
				end: momentObj().endOf('day').format(format)
			};
		}
	},
	last45days: {
		value: 'last45days',
		name: 'date.last45days',
		sortId: 57,
		get: function (format, useTags) {
			var momentObj = useTags ? momentTags : moment;
			return {
				start: momentObj().subtract('days', 45).startOf('day').format(format),
				end: momentObj().endOf('day').format(format)
			};
		}
	},
	last90days: {
		value: 'last90days',
		name: 'date.last90days',
		sortId: 58,
		get: function (format, useTags) {
			var momentObj = useTags ? momentTags : moment;
			return {
				start: momentObj().subtract('days', 90).startOf('day').format(format),
				end: momentObj().endOf('day').format(format)
			};
		}
	},
	lastXdays: {
		value: 'lastXdays',
		name: 'date.lastXdays',
		sortId: 59
	},
	// Prev ranges sortId 60-69
	prev12Month: {
		value: 'prev12Month',
		name: 'date.prev12Month',
		sortId: 60,
		get: function (format, useTags) {
			var momentObj = useTags ? momentTags : moment;
			return {
				start: momentObj().subtract('months', 12).startOf('day').format(format),
				end: momentObj().endOf('day').format(format)
			};
		}
	},
	prev6Month: {
		value: 'prev6Month',
		name: 'date.prev6Month',
		sortId: 61,
		get: function (format, useTags) {
			var momentObj = useTags ? momentTags : moment;
			return {
				start: momentObj().subtract('months', 6).startOf('day').format(format),
				end: momentObj().endOf('day').format(format)
			};
		}
	},
	prev3Month: {
		value: 'prev3Month',
		name: 'date.prev3Month',
		sortId: 62,
		get: function (format, useTags) {
			var momentObj = useTags ? momentTags : moment;
			return {
				start: momentObj().subtract('months', 3).startOf('day').format(format),
				end: momentObj().endOf('day').format(format)
			};
		}
	},
	prev2Month: {
		value: 'prev2Month',
		name: 'date.prev2Month',
		sortId: 63,
		get: function (format, useTags) {
			var momentObj = useTags ? momentTags : moment;
			return {
				start: momentObj().subtract('months', 2).startOf('day').format(format),
				end: momentObj().endOf('day').format(format)
			};
		}
	},
	prev3Year: {
		value: 'prev3Year',
		name: 'date.prev3Year',
		sortId: 64,
		get: function (format, useTags) {
			var momentObj = useTags ? momentTags : moment;
			return {
				start: momentObj().subtract('years', 3).format(format),
				end: momentObj().endOf('day').format(format)
			};
		}
	},
	prev5Year: {
		value: 'prev5Year',
		name: 'date.prev5Year',
		sortId: 65,
		get: function (format, useTags) {
			var momentObj = useTags ? momentTags : moment;
			return {
				start: momentObj().subtract('years', 5).startOf('year').format(format),
				end: momentObj().endOf('year').format(format)
			};
		}
	},
	prev18Month: {
		value: 'prev18Month',
		name: 'date.prev18Month',
		sortId: 67,
		get: function (format, useTags) {
			var momentObj = useTags ? momentTags : moment;
			return {
				start: momentObj().subtract('months', 18).startOf('day').format(format),
				end: momentObj().endOf('day').format(format)
			};
		}
	},
	prev24Month: {
		value: 'prev24Month',
		name: 'date.prev24Month',
		sortId: 66,
		get: function (format, useTags) {
			var momentObj = useTags ? momentTags : moment;
			return {
				start: momentObj().subtract('months', 24).startOf('day').format(format),
				end: momentObj().endOf('day').format(format)
			};
		}
	},

	// from a passed dateRange to infinity, sortId 70-79
	from2Weeks: {
		value: 'from2Weeks',
		name: 'date.from2Weeks',
		sortId: 70,
		get: function (format, useTags) {
			var momentObj = useTags ? momentTags : moment;
			return {
				start: momentObj().subtract('week', 2).startOf('day').format(format),
				end: null
			};
		}
	},
	from1Months: {
		value: 'from1Months',
		name: 'date.from1Months',
		sortId: 71,
		get: function (format, useTags) {
			var momentObj = useTags ? momentTags : moment;
			return {
				start: momentObj().subtract('month', 1).startOf('day').format(format),
				end: null
			};
		}
	},
	from1point5Months: {
		value: 'from1point5Months',
		name: 'date.from1point5Months',
		sortId: 72,
		get: function (format, useTags) {
			var momentObj = useTags ? momentTags : moment;
			return {
				start: momentObj().subtract('day', 45).startOf('day').format(format),
				end: null
			};
		}
	},
	from2Months: {
		value: 'from2Months',
		name: 'date.from2Months',
		sortId: 73,
		get: function (format, useTags) {
			var momentObj = useTags ? momentTags : moment;
			return {
				start: momentObj().subtract('month', 2).startOf('day').format(format),
				end: null
			};
		}
	},
	from3Months: {
		value: 'from3Months',
		name: 'date.from3Months',
		sortId: 74,
		get: function (format, useTags) {
			var momentObj = useTags ? momentTags : moment;
			return {
				start: momentObj().subtract('month', 3).startOf('day').format(format),
				end: null
			};
		}
	},
	from6Monts: {
		value: 'from6Monts',
		name: 'date.from6Monts',
		sortId: 75,
		get: function (format, useTags) {
			var momentObj = useTags ? momentTags : moment;
			return {
				start: momentObj().subtract('month', 6).startOf('day').format(format),
				end: null
			};
		}
	},
	from9Months: {
		value: 'from9Months',
		name: 'date.from9Months',
		sortId: 76,
		get: function (format, useTags) {
			var momentObj = useTags ? momentTags : moment;
			return {
				start: momentObj().subtract('month', 9).startOf('day').format(format),
				end: null
			};
		}
	},
	from1Year: {
		value: 'from1Year',
		name: 'date.from1Year',
		sortId: 77,
		get: function (format, useTags) {
			var momentObj = useTags ? momentTags : moment;
			return {
				start: momentObj().subtract('year', 1).startOf('day').format(format),
				end: null
			};
		}
	},

	next7days: {
		value: 'next7days',
		name: 'date.next7days',
		sortId: 80,
		get: function (format, useTags) {
			var momentObj = useTags ? momentTags : moment;
			return {
				start: momentObj().startOf('day').format(format),
				end: momentObj().add('days', 7).endOf('day').format(format)
			};
		}
	},
	next30days: {
		value: 'next30days',
		name: 'date.next30days',
		sortId: 81,
		get: function (format, useTags) {
			var momentObj = useTags ? momentTags : moment;
			return {
				start: momentObj().startOf('day').format(format),
				end: momentObj().add('days', 30).endOf('day').format(format)
			};
		}
	},
	next45days: {
		value: 'next45days',
		name: 'date.next45days',
		sortId: 82,
		get: function (format, useTags) {
			var momentObj = useTags ? momentTags : moment;
			return {
				start: momentObj().startOf('day').format(format),
				end: momentObj().add('days', 45).endOf('day').format(format)
			};
		}
	},
	next90days: {
		value: 'next90days',
		name: 'date.next90days',
		sortId: 83,
		get: function (format, useTags) {
			var momentObj = useTags ? momentTags : moment;
			return {
				start: momentObj().startOf('day').format(format),
				end: momentObj().add('days', 90).endOf('day').format(format)
			};
		}
	},

	nextXdays: {
		value: 'nextXdays',
		name: 'date.nextXdays',
		sortId: 84
	},
	nextXweeks: {
		value: 'nextXweeks',
		name: 'date.nextXweeks',
		sortId: 85
	},
	nextXmonths: {
		value: 'nextXmonths',
		name: 'date.nextXmonths',
		sortId: 86
	},

	// This one is special to the marketboard
	until36MonthsAgo: {
		value: 'until36MonthsAgo',
		name: 'date.until36MonthsAgo',
		sortId: 1337,
		get: function (format, useTags) {
			var momentObj = useTags ? momentTags : moment;
			return {
				start: null,
				end: momentObj().endOf('day').add(-36, 'month').format(format)
			};
		}
	},

	// Fiscal year stuff
	lastFiscalQuarter: {
		value: 'lastFiscalQuarter',
		name: 'date.lastFiscalQuarter',
		sortId: 84,
		get: function (format, useTags) {
			var momentFactoryFn = useTags ? momentTags : moment;
			var fiscalMoment = window.momentHelper.momentFiscalYear(momentFactoryFn);

			return {
				start: fiscalMoment().startOf('fiscalQuarter').subtract(3, 'month').format(format),
				end: fiscalMoment().endOf('fiscalQuarter').subtract(3, 'month').endOf('month').format(format)
			};
		}
	},
	currentFiscalQuarter: {
		value: 'currentFiscalQuarter',
		name: 'date.currentFiscalQuarter',
		sortId: 85,
		get: function (format, useTags) {
			var momentFactoryFn = useTags ? momentTags : moment;
			var fiscalMoment = window.momentHelper.momentFiscalYear(momentFactoryFn);

			return {
				start: fiscalMoment().startOf('fiscalQuarter').format(format),
				end: fiscalMoment().endOf('fiscalQuarter').format(format)
			};
		}
	},
	nextFiscalQuarter: {
		value: 'nextFiscalQuarter',
		name: 'date.nextFiscalQuarter',
		sortId: 50,
		get: function (format, useTags) {
			var momentFactoryFn = useTags ? momentTags : moment;
			var fiscalMoment = window.momentHelper.momentFiscalYear(momentFactoryFn);

			return {
				start: fiscalMoment().startOf('fiscalQuarter').add(3, 'month').format(format),
				end: fiscalMoment().endOf('fiscalQuarter').add(3, 'month').endOf('month').format(format)
			};
		}
	},
	lastFiscalYear: {
		value: 'lastFiscalYear',
		name: 'date.lastFiscalYear',
		sortId: 86,
		get: function (format, useTags) {
			var momentFactoryFn = useTags ? momentTags : moment;
			var fiscalMoment = window.momentHelper.momentFiscalYear(momentFactoryFn);

			return {
				start: fiscalMoment().startOf('fiscalYear').subtract(1, 'year').format(format),
				end: fiscalMoment().endOf('fiscalYear').subtract(1, 'year').endOf('month').format(format)
			};
		}
	},
	currentFiscalYear: {
		value: 'currentFiscalYear',
		name: 'date.currentFiscalYear',
		sortId: 87,
		get: function (format, useTags) {
			var momentFactoryFn = useTags ? momentTags : moment;
			var fiscalMoment = window.momentHelper.momentFiscalYear(momentFactoryFn);

			return {
				start: fiscalMoment().startOf('fiscalYear').format(format),
				end: fiscalMoment().endOf('fiscalYear').format(format)
			};
		}
	},
	nextFiscalYear: {
		value: 'nextFiscalYear',
		name: 'date.nextFiscalYear',
		sortId: 88,
		get: function (format, useTags) {
			var momentFactoryFn = useTags ? momentTags : moment;
			var fiscalMoment = window.momentHelper.momentFiscalYear(momentFactoryFn);

			return {
				start: fiscalMoment().startOf('fiscalYear').add(1, 'year').format(format),
				end: fiscalMoment().endOf('fiscalYear').add(1, 'year').endOf('month').format(format)
			};
		}
	}
};

module.exports = {
	get: key => presets[key],
	getValues: (key, format, useTags) => {
		if (presets[key] && presets[key].get) {
			return presets[key].get(format, useTags);
		} else {
			return {
				start: null,
				end: null
			};
		}
	},
	getDatesFromValue: function (value, useTags) {
		if (value.preset === 'custom') {
			return value;
		} else if (value.preset.indexOf('lastX') === 0) {
			const momentFactoryFn = useTags ? momentTags : moment;
			const [, unit] = value.preset.split('X');

			return {
				start: momentFactoryFn()
					.subtract(unit, value.start || 1)
					.startOf('day')
					.format(),
				end: momentFactoryFn().endOf('day').format()
			};
		} else if (value.preset.indexOf('nextX') === 0) {
			const momentFactoryFn = useTags ? momentTags : moment;
			const [, unit] = value.preset.split('X');

			return {
				start: momentFactoryFn().startOf('day').format(),
				end: momentFactoryFn()
					.add(unit, value.start || 1)
					.endOf('day')
					.format()
			};
		} else {
			return this.getValues(value.preset, null, useTags);
		}
	}
};
