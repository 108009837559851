import React from 'react';
import { Flex, Icon, Tooltip } from '@upsales/components';
import { ThirdButton } from '@upsales/components/Buttons';
import { useTranslation } from 'Components/Helpers/translate';
import BemClass from '@upsales/components/Utils/bemClass';
import type { DetectedDuplicateType } from 'App/resources/Model/DetectedDuplicates';

type ActionsType = { [key: number]: string | number };

type DataType = {
	id: number;
	duplicates: DetectedDuplicateType[];
};

type Props = {
	classes: BemClass;
	actions: ActionsType;
	expandedCards: { [key: string]: boolean };
	ignoreFilter: boolean;
	item: DataType;
	duplicateOf: number;
	onHideDuplicate: (e: React.MouseEvent<HTMLButtonElement>) => void;
	onExpand: (e: React.MouseEvent<HTMLDivElement>) => void;
};

const HandleDuplicatesControls = ({
	classes,
	actions,
	expandedCards,
	ignoreFilter,
	item,
	duplicateOf,
	onHideDuplicate,
	onExpand
}: Props) => {
	const { t } = useTranslation();

	return (
		<Flex space="prl pll pbl ptl" className={classes.elem('controls').b()} alignItems="center">
			<Tooltip title={t('default.showMore')}>
				<ThirdButton>
					<Icon
						data-testid={`cardDuplicates-${duplicateOf}`}
						className={classes
							.elem('icon')
							.mod({
								rotate: (item as DataType).duplicates.some(duplicate => expandedCards[duplicate?.id])
							})
							.b()}
						name="chevron-down"
						onClick={onExpand}
					/>
				</ThirdButton>
			</Tooltip>
			<Tooltip title={t('handleDuplicates.noDuplicate')}>
				<ThirdButton
					data-testid={`hideButton-${duplicateOf}`}
					onClick={onHideDuplicate}
					disabled={ignoreFilter}
				>
					<Icon
						className={classes.elem('icon').b()}
						name="eye-slash"
						color={actions[duplicateOf] === 'hide' ? 'bright-blue' : 'green'}
					/>
				</ThirdButton>
			</Tooltip>
		</Flex>
	);
};

export default HandleDuplicatesControls;
