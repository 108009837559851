import React from 'react';
import { AutoSizer, List, CellMeasurer, CellMeasurerCache, IndexRange } from 'react-virtualized';

import './VirtualList.scss';
import { MeasuredCellParent } from 'react-virtualized/dist/es/CellMeasurer';

type Props<T = any> = {
	cellMeasurerCache: CellMeasurerCache;
	height: number;
	items: T[];
	rowHeight: number;
	renderRow: (item: T, style: React.CSSProperties) => React.ReactNode;
	shouldShowRow: (item: T) => boolean;
	listRef: React.ForwardedRef<any>;
	onRowsRendered: (params: IndexRange) => void;
};

const VirtualList = ({
	cellMeasurerCache,
	height,
	items,
	rowHeight,
	renderRow,
	shouldShowRow,
	onRowsRendered,
	listRef
}: Props) => {
	const renderItem = (key: string, index: number, style: React.CSSProperties, parent: MeasuredCellParent) => {
		const item = items[index];

		if (!shouldShowRow(item)) {
			return null;
		}

		return (
			<CellMeasurer
				cache={cellMeasurerCache}
				columnIndex={0}
				rowIndex={index}
				parent={parent}
				key={'cellMeasurer' + key + index}
			>
				{renderRow(item, style)}
			</CellMeasurer>
		);
	};

	return (
		<AutoSizer disableHeight>
			{({ width }) => (
				<List
					ref={listRef}
					className="list-body"
					height={height}
					rowCount={items.length}
					rowHeight={rowHeight}
					rowRenderer={({ key, index, style, parent }) => renderItem(key, index, style, parent)}
					width={width}
					overscanRowCount={20}
					onRowsRendered={onRowsRendered}
				/>
			)}
		</AutoSizer>
	);
};

export default React.forwardRef((props: Props, ref) => <VirtualList {...props} listRef={ref} />);
