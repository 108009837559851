import React from 'react';
import TimelineGeneric from '../TimelineGeneric/TimelineGeneric';
import './TimelineCardMailCampaign.scss';
import bemClass from '@upsales/components/Utils/bemClass';
import type { TimelineCardComponent } from '../TimelineGeneric/TimelineCardConfigs';
import { useTranslation } from 'Components/Helpers/translate';
import { Avatar, Link, Icon, Title, Text, Block } from '@upsales/components';
import Mail from 'App/resources/Model/Mail';
import MailOpenClickIndicator from '../../MailOpenClickIndicator';

const TimelineCardMailCampaign = (({ event, ...passthrough }) => {
	const { mails, score, value: campaignName } = event || {};
	const { t } = useTranslation();
	const classes = new bemClass('TimelineMailCampaign');

	const filteredMails = (mails || []).filter(mail => mail.contacts && mail.contacts.length);
	const contacts = (mails || []).flatMap(mail => (mail.contacts && mail.contacts.length ? mail.contacts[0] : []));

	let nrOfRead = 0;
	let nrOfClicked = 0;

	const numFilteredMails = filteredMails.length;

	filteredMails.forEach((m: Mail) => {
		if (m.lastReadDate) nrOfRead++;
		if (m.lastClickDate) nrOfClicked++;
	});

	// no-padding class is for angular compatibility, to fix padding issues
	const dropdownTable = (
		<table className={classes.elem('mail-group-table').b()}>
			<thead>
				<tr className="no-padding">
					<th className="no-padding">
						{' '}
						<Block space="mlm ptm pbm plm prm">
							<Text>{t('default.contact')}</Text>
						</Block>
					</th>
					<th className="no-padding">
						<Block space="ptm pbm plm prm">
							<Text>
								{t('mail.read')}/{t('mail.clicked')}
							</Text>
						</Block>
					</th>
				</tr>
			</thead>
			<tbody>
				{filteredMails.map(mail =>
					mail.contacts!.length ? (
						<tr className="mail-row no-padding">
							<td className="no-padding">
								<Block space="ptm pbm plm prm mtm mbm mlm mrm">
									<Title color="bright-blue" size="sm">
										<Link
											href={Tools.$state.href('mailCampaign.dashboard', {
												id: mail.contacts![0].id,
												customerId: Tools.AppService.getCustomerId()
											})}
										>
											{mail.contacts![0].name}
										</Link>
									</Title>
								</Block>
							</td>
							<td className="no-padding">
								<Block space="ptm pbm plm prm mtm mbm mlm mrm">
									<MailOpenClickIndicator mail={mail} />
								</Block>
							</td>
						</tr>
					) : (
						''
					)
				)}
			</tbody>
		</table>
	);

	const openedPercentage = numFilteredMails ? Math.round((nrOfRead / numFilteredMails) * 100) : 0;
	const clickedPercentage = numFilteredMails ? Math.round((nrOfClicked / numFilteredMails) * 100) : 0;

	const percentageIndicators = (
		<>
			<Block space="mtm mbm mlm mrm ptm pbm pll prl" border="ls rs" borderColor="grey-4">
				<Text color={openedPercentage === 100 ? 'success-6' : 'grey-10'}>
					<Icon className={classes.elem('event').b()} name={'eye'} /> {openedPercentage}%
				</Text>
			</Block>
			<Block space="mtm mbm mls mrm ptm pbm pls prm">
				<Text color={clickedPercentage === 100 ? 'success-6' : 'grey-10'}>
					<Icon className={classes.elem('event').b()} name={'mouse-pointer'} /> {clickedPercentage}%
				</Text>
			</Block>
		</>
	);

	return (
		<TimelineGeneric
			event={{
				...event,
				contacts: contacts
			}}
			titleElements={[
				contacts.length ? 'contacts' : () => <b>{t('default.someone')}</b>,
				() => ` ${t('event.contactsend').toLowerCase()} `,
				() => (
					<Link
						href={Tools.$state.href('mailCampaign.dashboard', {
							id: event.entityId,
							customerId: Tools.AppService.getCustomerId()
						})}
					>
						{campaignName}
					</Link>
				),
				() => (score ? <b>{` (${score}p)`}</b> : <></>)
			]}
			subTitleElements={[
				() =>
					event.users.length ? (
						<React.Fragment>
							<Avatar
								size="sm"
								className={classes.elem('avatar').b()}
								initials={event.users[0].name}
								email={event.users[0].email}
							/>
							{` ${event.users[0].name} ${t('esign.didSend').toLowerCase()} • `}
						</React.Fragment>
					) : (
						''
					),
				'time'
			]}
			actions={contacts.length ? percentageIndicators : null}
			contactPreposition={''}
			isExpandable={!!contacts.length}
			expanded={false}
			className={classes.b()}
			children={dropdownTable}
			{...passthrough}
			hasAvatar={false}
		/>
	);
}) satisfies TimelineCardComponent;

export default TimelineCardMailCampaign;
