import React, { useState, useEffect } from 'react';
import {
	DateInput,
	Button,
	Icon,
	Label,
	Title,
	TimeInput,
	ButtonGroup,
	Block,
	Toggle,
	Text,
	Link
} from '@upsales/components';
import { SlideFade } from '@upsales/components/animations';
import BemClass from '@upsales/components/Utils/bemClass';
import './TodoTimePicker.scss';
import T from 'Components/Helpers/translate';
import InputRevealButton from 'App/components/InputRevealButton';
import moment from 'moment';

interface Props {
	onTimeChange: (e: { target: { value: string } }) => void;
	onDateChange: React.ComponentProps<typeof DateInput>['onChange'];
	onClear: () => void;
	date?: Date | null;
	time: string;
	type?: string;
	withToggle?: boolean;
	required?: boolean;
	disabled?: boolean;
	shouldClose?: boolean;
	setDueDateToday?: boolean;
	setShouldClose?: (val: boolean) => void;
}
const TodoTimePicker = (props: Props) => {
	const {
		onTimeChange,
		onDateChange,
		onClear,
		date,
		time,
		type,
		withToggle = false,
		disabled = false,
		shouldClose,
		required = false,
		setShouldClose,
		setDueDateToday
	} = props;

	const [showInput, setShowInput] = useState(!!(date || time));

	const handleToggle = (value: boolean) => {
		if (!required) {
			setShowInput(value);
		}

		if (value === false) {
			onClear();
			if (setShouldClose) {
				setShouldClose(false);
			}
		} else {
			if (setDueDateToday) {
				onDateChange({
					target: {
						value: new Date()
					}
				});
			}
		}
	};

	useEffect(() => {
		if (required) {
			setShowInput(true);
		} else {
			setShowInput(!!(date || time));
		}
	}, [date, time]);

	const suggestedTime = moment()
		.add(30 - (moment().minute() % 30), 'minutes')
		.format('HH:mm');
	const label = shouldClose
		? T('todo.setDate')
		: type === 'todo'
		? T('todo.editTodo.setDueDate')
		: T('todo.editPhonecall.whenToCall');
	const classes = new BemClass('TodoTimePicker');

	if (!showInput && !withToggle && !required) {
		return (
			<div className={classes.b()}>
				<InputRevealButton
					className=""
					icon="calendar"
					text={label}
					onClick={() => handleToggle(true)}
					disabled={disabled}
				/>
			</div>
		);
	}
	const content = (
		<Block className={classes.elem('content').b()}>
			<div className={classes.elem('labelSection').b()}>
				<Label required={required}>
					<Icon name="calendar" space="mrs" />
					<Title bold size="sm">
						{label}
					</Title>
				</Label>
				<SlideFade visible={!shouldClose}>
					<div>
						<Button
							type="link"
							size="sm"
							disabled={disabled}
							onClick={() => onDateChange({ target: { value: new Date() } })}
						>
							{T('today')}
						</Button>
						<Button
							type="link"
							size="sm"
							disabled={disabled}
							onClick={() =>
								onDateChange({
									target: {
										value: moment(new Date()).add('days', 1).toDate()
									}
								})
							}
						>
							{T('date.nextDay')}
						</Button>
						<Button
							type="link"
							size="sm"
							disabled={disabled}
							onClick={() =>
								onDateChange({
									target: {
										value: moment(date ? date : new Date())
											.add('days', 7)
											.toDate()
									}
								})
							}
						>
							{T('todo.editPhonecall.days', { days: 7 })}
						</Button>
						<Button
							type="link"
							size="sm"
							disabled={disabled}
							onClick={() =>
								onDateChange({
									target: {
										value: moment(date ? date : new Date())
											.add('days', 30)
											.toDate()
									}
								})
							}
						>
							{T('todo.editPhonecall.days', { days: 30 })}
						</Button>
					</div>
				</SlideFade>
			</div>
			<div className={classes.elem('dueDateInputWrapper').b()}>
				<ButtonGroup>
					{showInput ? (
						<DateInput
							closeOnSelect={true}
							value={date}
							showWeekNumbers={true}
							disabled={disabled}
							placeholder={T('default.date')}
							onChange={e => {
								if (
									setShouldClose &&
									e.target.value &&
									shouldClose &&
									moment(e.target.value).isAfter(moment())
								) {
									setShouldClose(false);
								}
								onDateChange(e);
							}}
							autofocus={!date}
						/>
					) : null}
					<TimeInput
						disabled={disabled}
						placeholder={T('default.time')}
						defaultSelected={suggestedTime}
						value={time || ''}
						onChange={onTimeChange}
					/>
				</ButtonGroup>
				{withToggle || disabled ? null : (
					<Icon
						name="trash"
						color="grey-10"
						onClick={() => {
							handleToggle(false);
						}}
					/>
				)}
			</div>
		</Block>
	);

	if (!withToggle) {
		return <div className={classes.b()}>{content}</div>;
	}
	return (
		<div className={classes.mod({ showInput, withToggle }).b()}>
			<Block className={classes.elem('toggleLabel').b()}>
				<Toggle
					icon="calendar-plus-o"
					size="lg"
					space="mrm"
					checked={showInput}
					disabled={disabled}
					onChange={value => {
						handleToggle(value);
					}}
				/>
				<Text className="clickable" style={{ display: 'inline' }} onClick={() => handleToggle(!showInput)}>
					{label}
				</Text>
				{type === 'phonecall' ? (
					<Link
						onClick={() => {
							if (!shouldClose) {
								setShowInput(true);
							}
							if (setShouldClose) {
								setShouldClose(!shouldClose);
							}
						}}
					>
						{T(shouldClose ? 'todo.setDueDate.setFuture' : 'todo.setDueDate.hadCall')}
					</Link>
				) : null}
			</Block>
			<SlideFade visible={showInput} direction="top" bounce height maxHeight={64}>
				{content}
			</SlideFade>
		</div>
	);
};

export default TodoTimePicker;
