import { SubscriptionPeriodState } from 'App/components/EditSubscription/Context/SubscriptionGroupState';
import AgreementGroup from 'App/resources/Model/AgreementGroup';
import AgreementGroupResource from 'Resources/AgreementGroup';
import Agreement from 'App/resources/Model/Agreement';
import Client from 'App/resources/Model/Client';
import T from 'Components/Helpers/translate';
import moment from 'moment';
import { getSalesModelOption } from './salesModelHelpers';
import { Metadata } from 'App/resources/AllIWant';

export const getAgreementPeriodText = (agreement: SubscriptionPeriodState, isFirstPeriod?: boolean) => {
	const startDate = agreement.startDate;
	const endDate = agreement.endDate;

	if (endDate && moment(endDate).isBefore()) {
		return T('subscription.modal.summary.scheduling.passedPeriod');
	}

	if (moment(startDate).isAfter() && !isFirstPeriod) {
		return T('subscription.modal.summary.scheduling.upcomingPeriod');
	}

	return T('subscription.modal.summary.scheduling.currentPeriod');
};

type FakeAgreementGroup = {
	agreements: Agreement[];
	currentAgreement: Agreement;
	client: Pick<Client, 'id' | 'name'>;
	modDate: string;
	regDate: string;
	id?: number;
};

export const mapOldAgreement = (agreements: Agreement[]): FakeAgreementGroup => {
	agreements = agreements.sort((a, b) =>
		moment(a.metadata.agreementStartdate) < moment(b.metadata.agreementStartdate) ? -1 : 1
	);
	const [agreement] = agreements;
	const currentAgreement =
		[...agreements]
			.reverse()
			.find(agreement => moment().isSameOrAfter(moment(agreement.metadata.agreementStartdate))) || agreement;

	return {
		agreements,
		currentAgreement,
		id: agreement.agreementGroupId ?? undefined,
		client: agreement.client,
		modDate: agreement.modDate,
		regDate: agreement.regDate
	};
};

export const mergeOldAndNewAgreements = (
	agreementGroups: AgreementGroup[],
	oldAgreements: Agreement[],
	clientId: number,
	contactId?: number,
	ignoreRelatedClients = false
) => {
	const oldAgreementsMapped = oldAgreements
		.filter(agreement => !agreement.agreementGroupId)
		.map(a => mapOldAgreement([a]));
	const relatedAgreements = oldAgreements.filter(
		({ agreementGroupId, client }) => agreementGroupId && !ignoreRelatedClients && client?.id !== clientId
	);
	const agreementGroupsMapped: FakeAgreementGroup[] = [];

	for (const agreement of relatedAgreements) {
		const group = agreementGroupsMapped.find(group => group.id === agreement.agreementGroupId);

		if (!group) {
			const agreements = relatedAgreements.filter(agg => agg.agreementGroupId === agreement.agreementGroupId);
			agreementGroupsMapped.push(mapOldAgreement(agreements));
		}
	}

	const filtered = [...agreementGroups, ...agreementGroupsMapped, ...oldAgreementsMapped].filter(
		group => !!group.currentAgreement && (!contactId || contactId === group.currentAgreement.contact?.id)
	);
	const sorted = filtered.sort((a, b) =>
		moment(a.currentAgreement.metadata.agreementStartdate) < moment(b.currentAgreement.metadata.agreementStartdate)
			? 1
			: -1
	);

	return sorted;
};

export const getAllActiveAgreements = (clientId: number) =>
	AgreementGroupResource.getAllAgreements(clientId).then(agreements =>
		agreements.filter(group => {
			if (!group.currentAgreement) return false;

			const currentEndDate = group.currentAgreement.metadata.agreementEnddate;
			const isInactive = !!currentEndDate && moment(currentEndDate).isBefore();
			return !isInactive && group.currentAgreement.metadata.orderSequenceNr > 0;
		})
	);

export const getRecurringProductDefaultInterval = (metadata: Metadata) => {
	return getSalesModelOption(metadata) === 'arr' ? 12 : 1;
};
