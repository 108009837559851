import PropTypes from 'prop-types';
import React from 'react';
import fieldPageSelected from './fieldPageSelected';
import FieldList from './FieldList';
import { Row, Modal, ModalHeader, ModalContent, ModalControls } from '@upsales/components';
import t from 'Components/Helpers/translate';
import { PrimaryButton, ThirdButton } from '@upsales/components/Buttons';
import BemClass from '@upsales/components/Utils/bemClass';

import './fieldPage.scss';

class AnonFieldPage extends React.Component {
	constructor(props) {
		super(props);
		this.entities = new FieldList(props.entities);
		this.options = {
			contact: [
				'name',
				'title',
				'titleCategory',
				'phone',
				'cellPhone',
				'email',
				'category',
				'campaign',
				'notes',
				'custom' /*, Relation */
			],
			activity: ['description', 'notes', 'custom'],
			appointment: ['description', 'notes', 'location', 'custom'],
			opportunity: ['description', 'custom', 'notes'],
			order: ['description', 'custom', 'notes'],
			agreement: ['description', 'custom', 'notes'],
			event: ['value'],
			document: ['fields'],
			esign: ['fields'],
			formsubmit: ['fields'],
			mail: ['subject', 'body', 'from', 'cc', 'bcc', 'mailBodySnapshotId', 'attachments']
		};

		_.each(props.entities.udos, udo => {
			this.options[udo.name] = ['fields'];
		});

		this.isSearching = null;
		const state = { searchValue: '', searching: false };

		if (props.isModal) {
			state.fields = {
				selectedFields: this.props.selectedFields.length ? this.props.selectedFields : []
			};
		}

		this.state = state;
		this.selectField = this.selectField.bind(this);
		this.selectAllFields = this.selectAllFields.bind(this);
	}

	isSelected(selectedFields, compare, reversed) {
		if (reversed === true) {
			return selectedFields.filter(f => {
				return !(f.field === compare.field && f.parent === compare.parent);
			});
		}

		return selectedFields.filter(f => {
			return f.field === compare.field && f.parent === compare.parent;
		});
	}

	selectField(obj, remove) {
		if (this.props.isModal) {
			const stateField = Object.assign({}, this.state.fields);
			const shouldIRender = this.isSelected(stateField.selectedFields, obj);
			if (shouldIRender.length === 0) {
				stateField.selectedFields.push(obj);
				this.setState({ fields: stateField });
			} else if (remove === true) {
				stateField.selectedFields = this.isSelected(stateField.selectedFields, obj, true);
				this.setState({ fields: stateField });
			}
		} else {
			this.props.selectField(obj, remove);
		}
	}

	selectAllFields(fields) {
		if (this.props.isModal) {
			const stateField = Object.assign({}, this.state.fields);
			if (typeof fields === 'string' && fields === 'remove') {
				stateField.selectedFields = [];
				this.setState({ fields: stateField });
			} else {
				_.forEach(fields, fieldGroup => {
					_.forEach(fieldGroup, field => {
						const exists = this.isSelected(stateField.selectedFields, field);
						if (exists.length === 0) {
							stateField.selectedFields.push(field);
						}
					});
				});
				this.setState({ fields: stateField });
			}
		} else {
			this.props.selectAllFields(fields);
		}
	}

	fieldRow(type, fields, isUdo) {
		if (fields.length === 0) {
			return null;
		}

		const selectedFields = this.props.isModal ? this.state.fields.selectedFields : this.props.selectedFields;

		fields = _.map(fields, field => {
			field.parent = type;
			let shouldIRender = this.isSelected(selectedFields, field);

			if (field.parent === 'contact') {
				shouldIRender = '1';
			}

			return (
				<li key={field.name} className={shouldIRender.length !== 0 ? 'selected' : null}>
					<span
						onClick={() => {
							if (field.parent !== 'contact') {
								this.selectField(field);
							}
						}}
					>
						{t(field.name)}
					</span>
				</li>
			);
		});

		let headerText = t(`filters.columns.${type}`);

		if (type === 'agreement') {
			headerText = t('trigger.field.agreement');
		}

		if (type === 'event' || type === 'document' || type === 'esign' || type === 'formsubmit') {
			headerText = t(`admin.anonymization.list.field.${type}`);
		}

		return (
			<div key={`fieldRow-${type}`} className={`fieldRow fieldRow-${type}`}>
				<h3 key={`fieldRow-h3-${type}`} className="row-h3 regular-table-title">
					{isUdo ? type : headerText}
				</h3>
				<ul key={`fieldRow-ul-${type}`}>{fields}</ul>
			</div>
		);
	}

	renderFieldsRow(state) {
		return Object.keys(this.options).map(f => {
			const list = this.entities.get(f, this.options[f]);
			if (state.searching === false) {
				const isUdo = list.length && list[0].isUdo === true;
				return this.fieldRow(f, list, isUdo);
			} else {
				const _list = list.filter(x => t(x.name).toLowerCase().indexOf(state.searchValue.toLowerCase()) > -1);
				const isUdo = _list.length && _list[0].isUdo;
				return this.fieldRow(f, _list, isUdo);
			}
		});
	}

	searchFields(value) {
		this.setState({
			searching: value.length > 0,
			searchValue: value
		});
	}

	renderTopRow() {
		const numberOfSelectedFields =
			this.state.fields && this.state.fields.selectedFields ? this.state.fields.selectedFields.length : 0;
		const totalNumberOfFields = Object.keys(this.options).reduce(
			(num, f) => (num += this.entities.get(f, this.options[f], false).length),
			0
		);
		const buttonClassName = numberOfSelectedFields === totalNumberOfFields ? 'choose-all grey' : 'choose-all';
		return (
			<div className="fieldRow topRow">
				<h3 className="regular-table-title">
					{t('admin.anonymization.fieldsToAnonymize')}
					<span
						className={buttonClassName}
						onClick={() => {
							const list = Object.keys(this.options).map(f =>
								this.entities.get(f, this.options[f], true)
							);
							this.selectAllFields(list);
						}}
					>
						{t('default.selectAll') + ' ' + t('form.fields').toLowerCase()}
					</span>
				</h3>
				<div className="field-search">
					<i className="fa fa-search" />
					<input
						type="text"
						placeholder={t('default.search')}
						onChange={event => {
							if (this.isSearching) {
								clearTimeout(this.isSearching);
							}

							this.isSearching = setTimeout(this.searchFields.bind(this, event.target.value), 200);
						}}
					/>
				</div>
			</div>
		);
	}

	renderModalHeader(reject, title) {
		return (
			<div className="up-modal-header">
				<h2>{title ? title : t('default.confirm')}</h2>
				<a className="btn btn-link modal-close" onClick={reject}>
					<i className="fa fa-times" />
				</a>
			</div>
		);
	}

	renderModalFooter(resolve, reject, fields) {
		const rejectButton = (
			<button className="btn up-btn btn-link btn-bright-blue" onClick={reject}>
				{t('default.abort')}
			</button>
		);

		return (
			<div className="up-modal-controls">
				<button
					disabled={!fields.length}
					onClick={() => resolve({ type: 'gotFields', fields: fields })}
					className="btn up-btn btn-bright-blue"
				>
					{t('onboarding.continue')}
				</button>
				{rejectButton}
			</div>
		);
	}

	renderContent(hasFields, selectedFields, obligatoryFields) {
		return (
			<div className="fieldPage">
				<div className="col-sm-6">
					{this.renderTopRow()}
					<div className="row-holder">{this.renderFieldsRow(this.state)}</div>
				</div>
				<div className={hasFields ? 'col-sm-6' : 'col-sm-6 no-fields'}>
					{React.createElement(
						fieldPageSelected,
						{
							selectField: this.selectField,
							selectedFields: selectedFields,
							obligatoryFields: obligatoryFields,
							selectAllFields: this.selectAllFields,
							title: t('admin.anonymization.fieldsThatAnonymized')
						},
						null
					)}
				</div>
			</div>
		);
	}

	render() {
		const _selectedFields =
			this.state.fields && this.state.fields.selectedFields
				? this.state.fields.selectedFields
				: this.props.selectedFields;
		const hasFields = _selectedFields.length + this.props.obligatoryFields.length > 0;

		if (this.props.isModal && Tools.FeatureHelper.hasSoftDeployAccess('REACT_ALERT_MODAL')) {
			const { close, className } = this.props;
			const classes = new BemClass('AnonymizationFieldPageModal', className);
			const fields = _selectedFields.concat(this.props.obligatoryFields);
			return (
				<Modal size="lg" className={classes.b()}>
					<ModalHeader title={t('default.confirm')} onClose={close} />
					<ModalContent>
						{this.renderContent(hasFields, _selectedFields, this.props.obligatoryFields)}
					</ModalContent>
					<ModalControls>
						<Row align="right">
							<PrimaryButton
								disabled={!fields.length}
								onClick={() => close({ type: 'gotFields', fields: fields })}
							>
								{t('onboarding.continue')}
							</PrimaryButton>
							<ThirdButton onClick={() => close()}>{t('default.abort')}</ThirdButton>
						</Row>
					</ModalControls>
				</Modal>
			);
		}

		return (
			<div>
				{this.props.isModal ? this.renderModalHeader(this.props.reject) : null}
				{this.props.isModal ? (
					<div
						className="up-modal-content"
						style={{ padding: '0', borderTop: '1px solid #E4EAF0', marginTop: '5px' }}
					>
						<div className="up-panel">
							<div className="up-panel-content">
								{this.renderContent(hasFields, _selectedFields, this.props.obligatoryFields)}
							</div>
						</div>
					</div>
				) : (
					this.renderContent(hasFields, _selectedFields, this.props.obligatoryFields)
				)}
				{this.props.isModal
					? this.renderModalFooter(
							this.props.resolve,
							this.props.reject,
							_selectedFields.concat(this.props.obligatoryFields)
					  )
					: null}
			</div>
		);
	}
}

AnonFieldPage.propTypes = {
	isModal: PropTypes.bool,
	className: PropTypes.string,
	close: PropTypes.func,
	reject: PropTypes.func,
	resolve: PropTypes.func,
	obligatoryFields: PropTypes.array.isRequired,
	selectedFields: PropTypes.array.isRequired,
	selectAllFields: PropTypes.func.isRequired,
	entities: PropTypes.object.isRequired,
	selectField: PropTypes.func.isRequired
};

export default AnonFieldPage;
