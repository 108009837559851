import React from 'react';
import { Text, Icon, IconName } from '@upsales/components';
import Bem from '@upsales/components/Utils/bemClass';

export const outcomeTypes = {
	COMPLETED: 'completed',
	NOTCOMPLETED: 'notCompleted',
	PLANNED: 'planned'
} as const;

export const getOutcomeStatus = (outcome: (typeof outcomeTypes)[keyof typeof outcomeTypes]) => ({
	outcomeCompleted: outcome === outcomeTypes.COMPLETED,
	outcomeNotCompleted: outcome === outcomeTypes.NOTCOMPLETED
});

export const getButtonMainText = (textTag: string, iconName: IconName, iconColor: string, bold: boolean) => (
	<>
		<Icon name={iconName} color={iconColor} />
		<Text size="sm" color="black" bold={bold}>
			{Tools.$translate(textTag)}
		</Text>
	</>
);

export const getMainClass = () => new Bem('AppointmentModal--Outcomes');
