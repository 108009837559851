import React, { useState, useEffect, useRef } from 'react';
import ReportcenterFilter from './ReportcenterFilter';
import { Block } from '@upsales/components';
import bemClass from '@upsales/components/Utils/bemClass';
import T from 'Components/Helpers/translate';
import ReportcenterFilterRow from './ReportcenterFilterRow';
import { removeItem } from 'Store/helpers/array';
import { RCDashboardFilter } from 'Resources/ReportDashboard';
import Flow from 'App/resources/Model/Flow';
import FlowResource from 'App/resources/Flow';
import logError from 'Helpers/logError';
import { makeCancelable, CancelablePromise } from 'App/babel/helpers/promise';
import './ReportcenterFilterFlow.scss';
import ReportcenterFilterSearchbar from './ReportcenterFilterSearchbar';

interface Props {
	onChange: (type: keyof RCDashboardFilter, values: number[], comparison: string) => void;
	values: RCDashboardFilter;
	updateFilterHeight: () => void;
	families: string[];
}

export const renderSelected = (values: RCDashboardFilter) => {
	let res = `${T('default.flows')}: `;

	if (values?.Flow?.value?.length) {
		const length = values.Flow.value.length;
		if (length >= 1) {
			res += `${length} ${(values.Flow.comparison === 'eq'
				? T('default.selected')
				: T('default.excluded', { count: length })
			).toLowerCase()}`;
		}
	}

	return res;
};

const ReportcenterFilterFlow = ({ onChange, families, ...props }: Props) => {
	const classes = new bemClass('ReportcenterFilterFlow');
	const [searchStr, setSearchStr] = useState('');
	const [flows, setFlows] = useState<Flow[]>([]);

	const t = useRef<number>();
	const p = useRef<CancelablePromise>();
	useEffect(() => {
		clearTimeout(t.current);
		t.current = window.setTimeout(() => {
			p.current?.cancel();
			p.current = makeCancelable(FlowResource.find({ name: `src:${searchStr}`, skipStats: true }));
			p.current.promise.then(res => setFlows(res.data)).catch(err => logError(err, 'Could not find flows'));
		}, 300);
		return () => {
			clearTimeout(t.current);
			p.current?.cancel();
		};
	}, [searchStr]);

	const array = flows.filter(item => item.name.toLowerCase().indexOf(searchStr.toLowerCase()) !== -1);
	const value = props.values?.Flow?.value || [];
	const comparison = props.values?.Flow?.comparison ?? 'eq';
	const isExclude = comparison === 'ne';

	return (
		<ReportcenterFilter
			className={classes.b()}
			renderSelected={() => renderSelected(props.values)}
			getSelectedNames={() => flows.filter(s => value.includes(s.id)).map(s => s.name)}
			icon="code-fork"
			placeholder={`${T('default.flows')}: ${T('reportcenter.filter.notActive')}`}
			value={value}
			resetFilter={() => onChange('Flow', [], 'eq')}
			{...props}
		>
			<Block space="ptm prm pbm plm" backgroundColor="white" className={classes.elem('list-select').b()}>
				<Block space="mtm">
					<ReportcenterFilterSearchbar<number[]>
						setSearchStr={value => setSearchStr(value)}
						searchStr={searchStr}
						placeholder={`${T('default.search')} ${T('default.flows').toLowerCase()}`}
						isExclude={isExclude}
						onChange={onChange}
						field="Flow"
						value={value}
					/>
				</Block>
			</Block>
			<ReportcenterFilterRow
				key={0}
				onClick={() => {
					onChange('Flow', [], 'eq');
				}}
				selected={!value.length}
				title={T('filters.noFilter')}
			/>
			{array.map(row => (
				<ReportcenterFilterRow
					key={row.id}
					onClick={() => {
						const i = value.indexOf(row.id);
						onChange('Flow', i !== -1 ? removeItem(value, i) : [...value, row.id], comparison);
					}}
					selected={value.indexOf(row.id) !== -1}
					title={row.name}
				/>
			))}
		</ReportcenterFilter>
	);
};

export default ReportcenterFilterFlow;
