import { useTranslation } from 'Components/Helpers/translate';
import BemClass from '@upsales/components/Utils/bemClass';
import { Text, Input, Label } from '@upsales/components';
import React from 'react';

type NotesInputProps = {
	title: string;
	value: string;
	small?: boolean;
	required?: boolean;
	maxLength?: number;
	description?: string;
	placeholder?: string;
	onChange: (value: string) => void;
	classNames: BemClass;
};

const NotesInput = (props: NotesInputProps) => {
	const { title, small, required, description, placeholder, value, onChange, classNames, maxLength = 128 } = props;
	const { t } = useTranslation();

	return (
		<div className={classNames.elem('notes-input').mod({ small }).b()}>
			<Label
				maxLength={maxLength}
				value={value}
				required={required}
				maxLengthReachedText={maxLength ? t('default.characterLimitReached') : undefined}
			>
				<Text bold>{title}</Text>
			</Label>
			<Input
				autofocus
				placeholder={placeholder}
				value={value}
				onChange={e => onChange(e.target.value)}
				maxLength={maxLength}
			/>
			{description ? (
				<Text size="sm" color="grey-11">
					{description}
				</Text>
			) : null}
		</div>
	);
};

export default NotesInput;
