import PropTypes from 'prop-types';
import React from 'react';
import CommentResource from 'Resources/Comment';
import { Loader } from '@upsales/components';
import logError from 'App/babel/helpers/logError';
import TicketResource from 'App/resources/Ticket';

const propTypes = {
	account: PropTypes.object,
	customerId: PropTypes.oneOfType([PropTypes.array, PropTypes.number]),
	numSelected: PropTypes.number,
	allSelected: PropTypes.bool
};

class AccountBody extends React.Component {
	constructor(props) {
		super(props);
		const t = Tools.$translate;
		this.lang = {
			title: this.titleFn(t, props.account, props.numSelected),
			irreversible: t('admin.modal.actionIrreversible'),
			noItemsAffected: t('admin.modal.noAffectedItems'),
			items: {
				agreement: t('agreement.agreements'),
				order: t('default.orders'),
				contact: t('default.contacts'),
				appointment: t('default.appointments'),
				activity: t('default.activities'),
				esign: t('default.eSigns'),
				visit: t('default.visits'),
				comment: t('default.comments'),
				ticket: t('ticket.tickets')
			}
		};

		this.lang.body = this.bodyFn(props);

		this.state = {
			items: {
				agreement: null,
				activity: null,
				order: null,
				contact: null,
				appointment: null,
				esign: null,
				visit: null,
				comment: null,
				ticket: null
			},
			udo: [],
			itemsLoaded: false,
			udosLoaded: false
		};

		this.itemMap = {
			agreement: { attr: Tools.Agreement.attr.client, find: Tools.Agreement.customer(props.customerId).find },
			activity: { attr: Tools.Activity.attr.client, find: Tools.Activity.customer(props.customerId).find },
			order: { attr: Tools.Order.attr.client, find: Tools.Order.customer(props.customerId).find },
			contact: { attr: Tools.Contact.attr.client, find: Tools.Contact.find },
			appointment: {
				attr: Tools.Appointment.attr.client,
				find: Tools.Appointment.customer(props.customerId).find
			},
			esign: { attr: Tools.Esign.attr.client.attr.id, find: Tools.Esign.find },
			visit: { attr: Tools.Visitor.attr.client, find: Tools.Visitor.find },
			udo: { attr: Tools.UserDefinedObject.attr.account.attr.id, find: Tools.UserDefinedObject },
			comment: { attr: { field: 'client.id' }, find: rb => CommentResource.find(rb) },
			ticket: { attr: { field: 'client.id' }, find: rb => TicketResource.find(rb) }
		};

		this.udoArr = Tools.AppService.getMetadata().params.UserDefinedObject;
	}

	titleFn(t, account, numSelected) {
		const tail =
			account.name ||
			`${numSelected} ${t(numSelected === 1 ? 'default.account' : 'default.accounts').toLowerCase()}`;
		return t('admin.modal.confirmDeleteAccount') + tail;
	}

	bodyFn({ allSelected, numSelected }) {
		const t = Tools.$translate;
		if (allSelected) {
			return t('admin.modal.account.actionBodyAllSelected');
		} else {
			return numSelected === 1
				? t('admin.modal.account.actionBodySingle')
				: t('admin.modal.account.actionBodyMulti');
		}
	}

	// eslint-disable-next-line camelcase
	UNSAFE_componentWillMount() {
		// ITEMS
		const promises = _.map(this.state.items, (val, key) => {
			const rb = new Tools.RequestBuilder();
			rb.addFilter(this.itemMap[key].attr, rb.comparisonTypes.Equals, this.props.account.id);
			rb.limit = 0;
			return this.itemMap[key].find(rb.build());
		});

		Promise.all(promises)
			.then(res => {
				const keys = Object.keys(this.state.items);
				this.setState({
					items: _.reduce(
						keys,
						(result, val, index) => {
							result[val] = res[index].metadata.total;
							return result;
						},
						{}
					)
				});
			})
			.catch(err => logError(err))
			.finally(() => {
				this.setState({ itemsLoaded: true });
			});

		// User defined objects
		// Account filter
		const filters = new Tools.RequestBuilder();
		filters.limit = 0;
		filters.addFilter(this.itemMap.udo.attr, filters.comparisonTypes.Equals, this.props.account.id);
		Promise.all(_.map(this.udoArr, obj => this.itemMap.udo.find.setId(obj.id).find(filters.build())))
			.then(res => {
				this.setState({
					udo: _.map(res, data => data.metadata.total)
				});
			})
			.catch(err => logError(err))
			.finally(() => {
				this.setState({ udosLoaded: true });
			});
	}

	render() {
		const { lang } = this;
		const allLoaded = this.state.itemsLoaded && this.state.udosLoaded;
		const items = _.map(this.state.items, (val, key) => {
			return (
				val > 0 && (
					<p className="account-item" key={`account-item-${key}`}>
						{`${val} ${this.lang.items[key]}`}
					</p>
				)
			);
		});
		const udo = _.map(this.state.udo, (val, i) => {
			return (
				val > 0 && (
					<p className="account-item" key={`user-defined-obj-${this.udoArr[i].id}`}>
						{`${val} ${this.udoArr[i].name}`}
					</p>
				)
			);
		});
		return (
			<div className="content-delete-confirm">
				{Tools.FeatureHelper.hasSoftDeployAccess('REACT_ALERT_MODAL') ? null : <h1>{lang.title}</h1>}
				<div className="confirm-body">
					<span className="confirm-warning">{lang.irreversible}</span>
					{lang.body}
				</div>
				{this.props.allSelected ? null : (
					<div className="account-items" style={{ gridTemplateColumns: allLoaded ? '1fr 1fr' : '1fr' }}>
						{allLoaded ? (
							_.some(items) || _.some(udo) ? (
								[...items, ...udo]
							) : (
								this.lang.noItemsAffected
							)
						) : (
							<Loader />
						)}
					</div>
				)}
			</div>
		);
	}
}

AccountBody.propTypes = propTypes;

export default AccountBody;
