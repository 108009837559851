import React from 'react';
import T from 'Components/Helpers/translate';
import BemClass from '@upsales/components/Utils/bemClass';
import { Text, Button, Icon, Block, Row } from '@upsales/components';

import './InlineAbortConfirm.scss';

type Props = {
	onAbort: () => void;
	onReturn: () => void;
	title?: string;
	subTitle?: string;
	abortEdit?: string;
	returnToEdit?: string;
};

const InlineAbortConfirm = (props: Props) => {
	const {
		onAbort,
		onReturn,
		title = 'confirm.abortEdit',
		subTitle = 'confirm.changesWillBeLost',
		abortEdit = 'default.abortEdit',
		returnToEdit = 'default.returnToEdit'
	} = props;

	const classes = new BemClass('InlineAbortConfirm');

	return (
		<div className={classes.elem('wrapper').b()}>
			<Block space="pbl">
				<Row>
					<Text bold>
						<Icon color="yellow-3" name="exclamation-triangle" />
						{T(title)}
					</Text>
				</Row>
				<Row>
					<Text>{T(subTitle)}</Text>
				</Row>
			</Block>
			<Block className={classes.elem('btn-wrap').b()}>
				<Row>
					<Button onClick={onAbort}>{T(abortEdit)}</Button>
					<Button type="link" onClick={onReturn}>
						{T(returnToEdit)}
					</Button>
				</Row>
			</Block>
		</div>
	);
};

export default InlineAbortConfirm;
