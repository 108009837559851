import { Block, Icon, OutsideClick, Row, Text, Title, WhiteModal } from '@upsales/components';
import { useTranslation } from 'Components/Helpers/translate';
import BemClass from '@upsales/components/Utils/bemClass';
import Button from '@upsales/components/Button/Button';
import React, { useState } from 'react';

import './Hotspot.scss';

type Props = {
	children: React.ReactNode;
	title: string;
	link?: string;
	hidden?: boolean;
	openOnRender?: boolean;
	videoTimestamp?: number;
	renderTop?: boolean;
	renderLeft?: boolean;
};

const Hotspot = ({ title, children, hidden, openOnRender, link, videoTimestamp, renderTop, renderLeft }: Props) => {
	const classes = new BemClass('Hotspot');

	const { t } = useTranslation();
	const [showModal, setShowModal] = useState<boolean>(openOnRender || false);

	const lang = {
		readMore: t('default.readMore'),
		pushToPlay: t('hotspot.skipToChapter')
	};

	const clickLink = () => {
		window.open(link, '_blank');
	};

	return (
		<div className={classes.b()}>
			<Block space="mls mts mrxl mbs">
				<Row
					align="center"
					className={classes.elem('dot-wrapper').mod({ hidden }).b()}
					onClick={e => {
						e.stopPropagation();
						setShowModal(!showModal);
					}}
				>
					<div className={classes.elem('dot').b()}></div>
					<div className={classes.elem('first-circle').b()}></div>
					<div className={classes.elem('second-circle').b()}></div>
				</Row>
			</Block>

			{showModal ? (
				<OutsideClick targetClass="Hotspot__modal" outsideClick={() => setShowModal(false)}>
					<WhiteModal className={classes.elem('modal').mod({ top: renderTop, left: renderLeft }).b()}>
						<Row direction="column" noWrap>
							<Block space="mlxl mtl" className={classes.elem('modal-header').b()}>
								<Row align="space-between">
									<Title size="sm" bold>
										{t(title)}
									</Title>
									<Button
										size="sm"
										type="link"
										onClick={e => {
											e.stopPropagation();
											setShowModal(false);
										}}
									>
										<Icon name="times" />
									</Button>
								</Row>
							</Block>
							<Block className={classes.elem('modal-content').b()}>{children}</Block>
							{link ? (
								<Block
									space="pts pbs"
									backgroundColor="grey-1"
									className={classes.elem('modal-footer').b()}
								>
									<Row align="space-between">
										<Button type="link" onClick={clickLink}>
											<Text color="bright-blue">
												<Row align="center">
													<Icon name="question-circle" space="mrl" />
													{lang.readMore}
												</Row>
											</Text>
										</Button>
										{videoTimestamp ? (
											<Button type="link" disabled color="grey-10">
												<Icon name="fast-forward" />
												<Text>{lang.pushToPlay}</Text>
											</Button>
										) : null}
									</Row>
								</Block>
							) : (
								<Block space="mtl" />
							)}
						</Row>
					</WhiteModal>
				</OutsideClick>
			) : null}
		</div>
	);
};

export default Hotspot;
