import React from 'react';
import './SeparatorRenderType.scss';
import BemClass from '@upsales/components/Utils/bemClass';

const SeparatorRenderType = () => {
	const classes = new BemClass('SeparatorRenderType', 'widget-separator-wrapper');
	return <div className={classes.b()}></div>;
};

export default SeparatorRenderType;
