// intercom script
const mapFields = (props = {}) => {
	const properties = props.properties || {};
	const obj = {};

	if (props.givenName) {
		obj.name = props.givenName;
	}
	if (props.email) {
		obj.email = props.email;
	}
	if (properties.admin) {
		obj.administrator = properties.admin;
	}
	if (properties.language) {
		obj.language_override = properties.language;
	}
	if (properties.cellPhone) {
		obj.phone = properties.cellPhone;
	}
	if (!obj.phone && properties.phone) {
		obj.phone = properties.phone;
	}
	if (properties.masterId || properties.clientName || properties.version || properties.accountManager) {
		obj.company = {};
	}
	if (properties.masterId) {
		obj.company.id = properties.masterId;
	}
	if (properties.clientName) {
		obj.company.name = properties.clientName;
	}
	if (properties.version) {
		obj.company.plan = properties.version;
	}
	if (properties.accountManager) {
		obj.company.accountManager = properties.accountManager;
	}
	if (properties.title) {
		obj.title = properties.title;
	}
	if (properties.billingAdmin) {
		obj.billingAdmin = properties.billingAdmin;
	}
	return obj;
};

let enabled = true;
let loaded = false;
let opened = false;
let started = false;

const openZendeskChat = () => {
	if (window.zE) {
		window.zE('messenger', 'show');
		window.zE('messenger', 'open');
	}
};

const InAppChat = {
	loadScriptIfNeeded: () => {
		if (!loaded) {
			// prettier-ignore
			(function () {
				var w = window;
				var ic = w.Intercom;
				if (typeof ic === "function") {
					ic("reattach_activator");
					ic("update", w.intercomSettings);
				} else {
					var d = document;
					var i = function () {
						i.c(arguments);
					};
					i.q = [];
					i.c = function (args) {
						i.q.push(args);
					};
					w.Intercom = i;
					var l = function () {
						var s = d.createElement("script");
						s.type = "text/javascript";
						s.async = true;
						if(Tools.FeatureHelper.hasSoftDeployAccess('USE_EU_CHAT')) {
							s.src = 'https://widget.intercom.io/widget/zx7iwxwn';
						} else {
							s.src = "https://widget.intercom.io/widget/ie6y99qt";
						}
						var x = d.getElementsByTagName("script")[0];
						x.parentNode.insertBefore(s, x);
					};
					// The load event is not run in this case
					if(document.readyState === "complete") {
						l();
					} else {
						if (w.attachEvent) {
							w.attachEvent("onload", l);
						} else {
							w.addEventListener("load", l, false);
						}
					}
				}
			})();

			if (window.Intercom) {
				loaded = true;
			} else {
				console.error('Intercom could not load');
			}
		}
	},
	enable: () => {
		InAppChat.loadScriptIfNeeded();
		enabled = true;
	},
	disable: () => {
		enabled = false;
	},
	isEnabledAndLoaded: () => {
		return enabled && loaded;
	},
	isOpened: () => {
		return opened;
	},
	isStarted: () => {
		return started;
	},
	open: () => {
		if (Tools.FeatureHelper.hasSoftDeployAccess('ZENDESK_IN_APP_CHAT')) {
			openZendeskChat();
		} else {
			if (InAppChat.isEnabledAndLoaded()) {
				window.Intercom('show');
				opened = true;
			}
		}
	},
	close: () => {
		if (InAppChat.isEnabledAndLoaded()) {
			window.Intercom('hide');
			opened = false;
		}
	},
	updateUser: props => {
		if (InAppChat.isEnabledAndLoaded()) {
			window.Intercom('update', { ...mapFields(props) });
		}
	},
	update: () => {
		if (InAppChat.isEnabledAndLoaded()) {
			window.Intercom('update');
		}
	},
	init: props => {
		if (InAppChat.isEnabledAndLoaded()) {
			const config = { app_id: 'ie6y99qt', hide_default_launcher: true, ...mapFields(props) };
			if (Tools.FeatureHelper.hasSoftDeployAccess('USE_EU_CHAT')) {
				config.api_base = 'https://api-iam.eu.intercom.io';
				config.app_id = 'zx7iwxwn';
			}
			window.Intercom('boot', config);
			window.Intercom('onShow', () => {
				opened = true;
			});
			window.Intercom('onHide', () => {
				opened = false;
			});
			started = true;
		}
	},
	shutdown: () => {
		if (InAppChat.isEnabledAndLoaded()) {
			window.Intercom('shutdown');
			started = false;
		}
	},
	trackEvent: event => {
		if (InAppChat.isEnabledAndLoaded()) {
			window.Intercom('trackEvent', event);
		}
	},
	on: (event, fn) => {
		let listener = fn;
		if (InAppChat.isEnabledAndLoaded()) {
			switch (event) {
				case 'unreadCount':
					window.Intercom('onUnreadCountChange', (...args) => listener?.(...args));
					break;
				default:
					console.warn('Unsupported InAppChat event ' + event);
					break;
			}
		}
		return () => {
			listener = null;
		};
	}
};

export default InAppChat;
