import React, { useEffect, useState } from 'react';
import {
	Text,
	Block,
	Drawer,
	DrawerHeader,
	Loader,
	Icon,
	Title,
	Row,
	Column,
	ButtonSelect,
	Tooltip,
	Link
} from '@upsales/components';
import BemClass from '@upsales/components/Utils/bemClass';
import logError from 'Helpers/logError';
import RequestBuilder from 'Resources/RequestBuilder';
import { ModalProps } from 'App/components/Modals/Modals';
import { useTranslation } from 'Components/Helpers/translate';
import { makeCancelable } from 'App/babel/helpers/promise';
import CreditRiskResource from 'App/resources/CreditRisks';
import CustomerPortfolioCompany from 'App/resources/Model/CustomerPortfolioCompany';
import CreditRisk from 'App/resources/Model/CreditRisk';
import CreditData from 'App/resources/Model/CreditData';
import T from 'Components/Helpers/translate';
import './RiskDrawer.scss';
import {
	getBackgroundColor,
	getCommentariesObjectFromCodes,
	getIconColor,
	renderCreditRatingReasoning,
	salesNumberFormat
} from './RiskDrawerHelper';
import CreditRatingLabel from '../CreditRatingLabel';
import UserStack from '../UserStack';
import { DisplayValueType } from 'App/pages/CustomerPortfolio/CustomerPortfolio';
import SignalsContent from 'App/pages/Prospecting/Signals/SignalsFeed/SignalsContent';
import { FilterDate, FilterType, loadItems, setState } from 'Store/reducers/SignalsFeedReducer';
import { useAppDispatch } from '../hooks';
import { DefaultButton, ThirdButton } from '@upsales/components/Buttons';
import SignalsFilter from 'Resources/SignalsFilter';
import RiskDrawerTable from './RiskDrawerTable';
import RiskDrawerSignalsInitalState from './RiskDrawerSignalsInitialState';

type Props = ModalProps & {
	company: CustomerPortfolioCompany;
	valueType: DisplayValueType;
};

const LIMIT = 50;
const url = 'https://support.upsales.com/hc/en-us/articles/18713387663889-Credit-ratings-in-Customer-Portfolio';

function fetchCreditRisks(orgNumber: number, offset: number = 0) {
	const rb = new RequestBuilder();
	rb.limit = LIMIT;
	rb.offset = offset;
	rb.addSort('date', false);
	rb.addFilter({ field: 'orgNumber' }, rb.comparisonTypes.Equals, orgNumber);
	return makeCancelable(CreditRiskResource.find(rb.build()));
}

function getOrgNumberFromProspectingId(company: CustomerPortfolioCompany) {
	return company.prospectingId ? parseInt(company.prospectingId.split('.').pop()!) : 0;
}

async function fetchSignalsState(companyId: number): Promise<boolean> {
	const isSignalsEnabled = await SignalsFilter.isEnabled(companyId);
	return isSignalsEnabled;
}

const renderCreditRatingHeader = (classes: BemClass, company: CustomerPortfolioCompany) => {
	const title = renderCreditRatingReasoning(company);

	return (
		<Block className={classes.elem('title-row').b()}>
			<CreditRatingLabel prospectingCreditRating={company.prospectingCreditRating} space="mrm" />
			<Text size="lg" bold>
				{title}
			</Text>
		</Block>
	);
};

const renderCreditData = (classes: BemClass, creditData: CreditData, rating: string | null) => {
	const filteredCommentaries = Object.entries(creditData)
		.filter(([key, value]) => key.startsWith('commentary') && value !== null)
		.map(([key, value]) => value as number);

	const commentariesObjects = getCommentariesObjectFromCodes(filteredCommentaries);
	return (
		<>
			{commentariesObjects.length > 0 ? (
				<>
					<Title space="pbl">{T('customerPortfolio.risks.drawer.reasoning')}</Title>
					{commentariesObjects.map((commentary, index) => (
						<div key={index} className={classes.elem('commentary-row').b()}>
							<Icon
								className={classes.elem('riskdrawer-icon').b()}
								name="circle"
								color={getIconColor(commentary.type)}
								space="mrm"
							/>
							<Text size="sm">{commentary.languageTag}</Text>
						</div>
					))}
				</>
			) : null}
		</>
	);
};

const NewsList = ({ prospectingId }: { prospectingId: string }) => {
	const dispatch = useAppDispatch();

	useEffect(() => {
		dispatch(setState({ filter: FilterType.ALL, date: FilterDate.ALL }));
		dispatch(loadItems(prospectingId));
	}, []);

	return <SignalsContent />;
};

const RiskDrawer = ({ company, valueType, className, close }: Props) => {
	const { t } = useTranslation();
	const classes = new BemClass('RiskDrawer', className);

	const [creditRisks, setCreditRisks] = useState<CreditRisk[]>([]);
	const [creditData, setCreditData] = useState<CreditData>({
		orgNumber: 0,
		commentary1: null,
		commentary2: null,
		commentary3: null,
		commentary4: null,
		commentary5: null
	});
	const [loading, setLoading] = useState(true);
	const [selectedValue, setSelectedValue] = useState<FilterType>(FilterType.RISK);
	const [signalsEnabled, setSignalsEnabled] = useState<boolean | null>(null);

	useEffect(() => {
		const orgNumber = getOrgNumberFromProspectingId(company);
		const cancelablePromise = fetchCreditRisks(orgNumber);

		cancelablePromise.promise
			.then(response => {
				setCreditRisks(response.data);
				const typedMetadata = response.metadata as unknown as { commentaries: CreditData[] };
				const creditData = typedMetadata.commentaries.find(commentary => commentary.orgNumber === orgNumber);
				if (creditData) {
					setCreditData(creditData);
				}
				setLoading(false);
			})
			.catch(error => {
				logError(error);
				setLoading(false);
			});

		return () => cancelablePromise.cancel();
	}, []);

	useEffect(() => {
		const cancelablePromise = makeCancelable(fetchSignalsState(company.id));

		cancelablePromise.promise
			.then(isSignalsEnabled => {
				setSignalsEnabled(isSignalsEnabled);
			})
			.catch(error => {
				logError(error);
				setSignalsEnabled(false);
			});

		return () => cancelablePromise.cancel();
	}, [company.id]);

	const handleSignalsEnabled = () => {
		setSignalsEnabled(true);
	};

	const options = [
		{ title: t('customerPortfolio.header.tab.risk.title'), value: FilterType.RISK },
		{ title: t('admin.prospecting.signals'), value: FilterType.NEWS }
	];
	const handleChange = (value: FilterType) => {
		setSelectedValue(value);
	};

	const navigateToAccount = ({ id }: CustomerPortfolioCompany) => {
		if (Tools.FeatureHelper.hasSoftDeployAccess('REACT_CLIENT_CARD')) {
			Tools.$state.go('react-root-clientCard', { id: id, page: 'overview' });
		} else {
			Tools.$state.go('account.dashboard', { id });
		}
	};
	return (
		<Drawer className={classes.b()}>
			<DrawerHeader onHide={close}>
				<Column className={classes.elem('drawer-header').b()}>
					<Text>
						<Link
							onClick={() => {
								navigateToAccount(company);
								close();
							}}
						>
							{company.name}
						</Link>{' '}
					</Text>
					<Row className={classes.elem('drawer-header-row').b()}>
						<UserStack users={company.users} />
						<Text size="sm" space="mls mrs">
							{company.users.at(-1)?.name} • {t(`customerPortfolio.risks.drawerHeader.${valueType}`)}:{' '}
							{salesNumberFormat(company, valueType)}
						</Text>
					</Row>
				</Column>

				{signalsEnabled === false ? (
					<Tooltip
						title={t('account.signals.disabled')}
						className={classes.elem('drawer-header-tooltip').b()}
					>
						<ThirdButton
							className={classes.elem('drawer-signals').b()}
							onClick={async () => {
								await SignalsFilter.includeClient(company.id);
								setSignalsEnabled(true);
							}}
						>
							<Icon name="feed" />
						</ThirdButton>
					</Tooltip>
				) : (
					<Tooltip title={t('account.signals.enabled')} className={classes.elem('drawer-header-tooltip').b()}>
						<DefaultButton
							className={classes.elem('drawer-signals').b()}
							onClick={async () => {
								await SignalsFilter.excludeClient(company.id);
								setSignalsEnabled(false);
							}}
						>
							<Icon name="feed" />
						</DefaultButton>
					</Tooltip>
				)}
				<Tooltip
					title={t('customerPortfolio.risks.helpTextCreditRating')}
					className={classes.elem('drawer-header-tooltip').mod('help-tooltip').b()}
				>
					<ThirdButton
						className={classes.elem('drawer-signals').b()}
						onClick={() => {
							window.open(url, '_blank');
						}}
					>
						<Icon name="question-circle" />
					</ThirdButton>
				</Tooltip>
			</DrawerHeader>

			{loading ? (
				<Loader className={classes.elem('loader').b()} noU={true} />
			) : (
				<div className={classes.elem('risk-block').b()}>
					<Block
						space="plxl ptxl"
						backgroundColor={getBackgroundColor(company.prospectingCreditRating)}
						className={classes.elem('risk-block-sticky').b()}
					>
						{renderCreditRatingHeader(classes, company)}
					</Block>

					<Block>
						<Block space="plxl pbm" backgroundColor={getBackgroundColor(company.prospectingCreditRating)}>
							{creditData ? renderCreditData(classes, creditData, company.prospectingCreditRating) : null}
						</Block>
						<Row
							className={
								selectedValue === FilterType.RISK
									? classes.elem('row-content').b()
									: classes.elem('row-content--sticky').b()
							}
						>
							<Title className={classes.elem('list-title').b()}>
								{selectedValue === FilterType.RISK
									? t('customerPortfolio.risks.drawer.riskTitle')
									: t('customerPortfolio.risks.drawer.signalsTitle')}
							</Title>
							<ButtonSelect size="md" onChange={handleChange} options={options} value={selectedValue} />
						</Row>
						{selectedValue === FilterType.RISK ? <RiskDrawerTable creditRisks={creditRisks} /> : null}

						{selectedValue === FilterType.NEWS ? (
							<div>
								{signalsEnabled === false ? (
									<RiskDrawerSignalsInitalState
										companyId={company.id}
										onSignalsEnabled={handleSignalsEnabled}
									/>
								) : (
									<NewsList prospectingId={company.prospectingId!} />
								)}
							</div>
						) : null}
					</Block>
				</div>
			)}
		</Drawer>
	);
};

export default RiskDrawer;
