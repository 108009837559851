import { ACTIVITY_PRIORITY } from 'App/babel/enum/activity';
import DatePreset from 'App/upsales/common/services/datePresetService';

export const newFilter = () => ({
	inactive: true,
	value: {
		hasActivity: true,
		overdueAsClosed: false,
		ActivityType: { value: [] },
		RegDate: { value: { start: null, end: null, preset: 'whenever' } },
		CloseDate: { value: { start: null, end: null, preset: 'whenever' } },
		Date: { value: { start: null, end: null, preset: 'whenever' } },
		Outcome: { value: [] },
		Campaign: { value: [] },
		User: { value: [] },
		Priority: { value: [] },
		Status: { value: null }
	}
});

const activityFilter = {
	generate: newFilter,
	isInactive: filter => filter.inactive,
	getText: (val, inactive) => {
		var num = 0;

		if (inactive !== true) {
			num++;
		}

		if (!val) {
			return num;
		}

		if (val.Date.value.start || val.Date.value.end || val.Date.value.preset !== 'whenever') {
			num++;
		}
		if (
			val.CloseDate &&
			(val.CloseDate.value.start || val.CloseDate.value.end || val.CloseDate.value.preset !== 'whenever')
		) {
			num++;
		}
		if (
			val.RegDate &&
			(val.RegDate.value.start || val.RegDate.value.end || val.RegDate.value.preset !== 'whenever')
		) {
			num++;
		}
		if (val.overdueAsClosed) {
			num++;
		}
		if (val.ActivityType?.value?.length) {
			num++;
		}
		if (val.Campaign?.value?.length) {
			num++;
		}
		if (val.User?.value?.length) {
			num++;
		}
		if (val.Priority?.value?.length) {
			num++;
		}
		if (val.Status.value) {
			num++;
		}
		if (val.Outcome?.value?.length) {
			num++;
		}
		return num;
	},
	toUrl: filter => {
		const f = { v: {} };
		const val = filter.value;

		f.v.has = val.hasActivity;
		f.i = filter.inactive;

		if (val.ActivityType.value) {
			f.v.ty = val.ActivityType.value;
		}
		if (val.Campaign.value) {
			f.v.ca = val.Campaign.value;
		}
		if (val.User.value) {
			f.v.us = val.User.value;
		}
		if (val.Priority && val.Priority.value) {
			f.v.pr = val.Priority.value;
		}
		if (val.Status.value) {
			f.v.st = val.Status.value;
		}
		if (val.overdueAsClosed) {
			f.v.oc = true;
		}
		if (val.Outcome?.value) {
			f.v.ot = val.Outcome.value;
		}
		if (val.Date && (val.Date.value.start || val.Date.value.end || val.Date.value.preset !== 'whenever')) {
			f.v.da = { p: val.Date.value.preset, s: val.Date.value.start, e: val.Date.value.end };
		}
		if (
			val.RegDate &&
			(val.RegDate.value.start || val.RegDate.value.end || val.RegDate.value.preset !== 'whenever')
		) {
			f.v.regDa = { p: val.RegDate.value.preset, s: val.RegDate.value.start, e: val.RegDate.value.end };
		}
		if (
			val.CloseDate &&
			(val.CloseDate.value.start || val.CloseDate.value.end || val.CloseDate.value.preset !== 'whenever')
		) {
			f.v.closeDa = { p: val.CloseDate.value.preset, s: val.CloseDate.value.start, e: val.CloseDate.value.end };
		}

		return f;
	},
	fromUrl: rawFilter => {
		const filter = newFilter();
		filter.inactive = false;
		const val = rawFilter.v;

		filter.value.hasActivity = val.has;

		if (rawFilter.i) {
			filter.inactive = rawFilter.i;
		}
		if (val.ty) {
			filter.value.ActivityType = { value: val.ty };
		}
		if (val.ca) {
			filter.value.Campaign = { value: val.ca };
		}
		if (val.us) {
			filter.value.User = { value: val.us };
		}
		if (val.pr) {
			filter.value.Priority = { value: val.pr };
		}
		if (val.st) {
			filter.value.Status = { value: val.st };
		}
		if (val.ot) {
			filter.value.Outcome = { value: val.ot };
		}
		if (val.oc) {
			filter.value.overdueAsClosed = true;
		}

		if (val.da) {
			filter.value.Date = {
				value: {
					start: val.da.s ? new Date(val.da.s) : val.da.s,
					end: val.da.e ? new Date(val.da.e) : val.da.e,
					preset: val.da.p
				}
			};
		}
		if (val.regDa) {
			filter.value.RegDate = {
				value: {
					start: val.regDa.s ? new Date(val.regDa.s) : val.regDa.s,
					end: val.regDa.e ? new Date(val.regDa.e) : val.regDa.e,
					preset: val.regDa.p
				}
			};
		}
		if (val.closeDa) {
			filter.value.CloseDate = {
				value: {
					start: val.closeDa.s ? new Date(val.closeDa.s) : val.closeDa.s,
					end: val.closeDa.e ? new Date(val.closeDa.e) : val.closeDa.e,
					preset: val.closeDa.p
				}
			};
		}

		return filter;
	},
	build:
		(options = {}) =>
		(filter, rb, filterHelperGetField, useTags) => {
			const val = filter.value;

			if (filter.inactive || !val) {
				return;
			}

			const getField = options.fieldPrefix
				? field => filterHelperGetField([options.fieldPrefix, field].join('.'))
				: filterHelperGetField;

			let nd;
			let activityFilters;
			let appointmentFilters;
			let statusDateFilters;
			let typeOr;

			if (
				!val.hasActivity &&
				!val.ActivityType.value.length &&
				(val.Date.value.preset === 'whenever' ||
					(val.Date.value.preset === 'custom' && !val.Date.value.start && !val.Date.value.end)) &&
				!val.Campaign.value.length &&
				!val.User.value.length &&
				!val.Status.value
			) {
				rb.addFilter(getField('activity.id'), rb.comparisonTypes.Equals, null);
				if (!options || !options.noAppointment) {
					rb.addFilter(getField('appointment.id'), rb.comparisonTypes.Equals, null);
				}
				return;
			} else if (val.hasActivity) {
				if (!options || !options.noAppointment) {
					typeOr = rb.orBuilder();

					// Activity
					typeOr.next();
					activityFilters = typeOr.groupBuilder();
				} else {
					activityFilters = rb;
				}

				activityFilters.addFilter(getField('activity.id'), rb.comparisonTypes.NotEquals, null);
			} else {
				activityFilters = rb.groupBuilder();
				activityFilters.isNotFilter();
			}

			// ActivityType
			if (val.ActivityType.value.length) {
				activityFilters.addFilter(
					getField('activity.activityType.id'),
					rb.comparisonTypes.Equals,
					val.ActivityType.value
				);
			}

			// Campaign
			if (val.Campaign.value.length) {
				activityFilters.addFilter(
					getField('activity.project.id'),
					rb.comparisonTypes.Equals,
					val.Campaign.value
				);
			}

			// User
			if (val.User.value.length) {
				activityFilters.addFilter(getField('activity.user.id'), rb.comparisonTypes.Equals, val.User.value);
			}

			if (val.Priority && val.Priority.value.length) {
				activityFilters.addFilter(getField('activity.priority'), rb.comparisonTypes.Equals, val.Priority.value);
			}

			// Date
			if (val.Date.value.preset !== 'whenever') {
				const dates = DatePreset.getDatesFromValue(val.Date.value, useTags);
				// start
				if (dates.start) {
					activityFilters.addFilter(
						getField('activity.date'),
						rb.comparisonTypes.GreaterThanEquals,
						dates.start
					);
				}
				// end
				if (dates.end) {
					activityFilters.addFilter(getField('activity.date'), rb.comparisonTypes.LowerThanEquals, dates.end);
				}
			}
			if (val.CloseDate && val.CloseDate.value.preset !== 'whenever') {
				const dates = DatePreset.getDatesFromValue(val.CloseDate.value, useTags);
				// start
				if (dates.start) {
					activityFilters.addFilter(
						getField('activity.closeDate'),
						rb.comparisonTypes.GreaterThanEquals,
						dates.start
					);
				}
				// end
				if (dates.end) {
					activityFilters.addFilter(
						getField('activity.closeDate'),
						rb.comparisonTypes.LowerThanEquals,
						dates.end
					);
				}
			}
			if (val.RegDate && val.RegDate.value.preset !== 'whenever') {
				const dates = DatePreset.getDatesFromValue(val.RegDate.value, useTags);
				// start
				if (dates.start) {
					activityFilters.addFilter(
						getField('activity.regDate'),
						rb.comparisonTypes.GreaterThanEquals,
						dates.start
					);
				}
				// end
				if (dates.end) {
					activityFilters.addFilter(
						getField('activity.regDate'),
						rb.comparisonTypes.LowerThanEquals,
						dates.end
					);
				}
			}

			// Status
			if (val.Status.value) {
				switch (val.Status.value) {
					case 'open':
						if (val.overdueAsClosed) {
							activityFilters.addFilter(getField('activity.closeDate'), rb.comparisonTypes.Equals, null);
							activityFilters.addFilter(
								getField('activity.date'),
								rb.comparisonTypes.GreaterThanEquals,
								new Date()
							);
						} else {
							activityFilters.addFilter(getField('activity.closeDate'), rb.comparisonTypes.Equals, null);
						}
						break;
					case 'closed':
						if (val.overdueAsClosed) {
							statusDateFilters = activityFilters.orBuilder();
							statusDateFilters.next();
							statusDateFilters.addFilter(
								getField('activity.closeDate'),
								rb.comparisonTypes.NotEquals,
								null
							);
							statusDateFilters.next();
							statusDateFilters.addFilter(
								getField('activity.closeDate'),
								rb.comparisonTypes.Equals,
								null
							);
							statusDateFilters.addFilter(
								getField('activity.date'),
								rb.comparisonTypes.LessThanEquals,
								new Date()
							);
							statusDateFilters.done();
						} else {
							activityFilters.addFilter(
								getField('activity.closeDate'),
								rb.comparisonTypes.NotEquals,
								null
							);
						}

						break;
				}
			}

			if (activityFilters && activityFilters.done) {
				activityFilters.done();
			}

			if (typeOr && filter.value.Priority && Number(filter.value.Priority.value[0]) === ACTIVITY_PRIORITY.HIGH) {
				return typeOr.done();
			}

			if (!options || !options.noAppointment) {
				// Appointment
				if (val.hasActivity) {
					typeOr.next();
					appointmentFilters = typeOr.groupBuilder();
					appointmentFilters.addFilter(getField('appointment.id'), rb.comparisonTypes.NotEquals, null);
				} else {
					appointmentFilters = rb.groupBuilder();
				}

				// ActivityType
				if (val.ActivityType.value.length) {
					appointmentFilters.addFilter(
						getField('appointment.activityType.id'),
						rb.comparisonTypes.Equals,
						val.ActivityType.value
					);
				}

				// Campaign
				if (val.Campaign.value.length) {
					appointmentFilters.addFilter(
						getField('appointment.project.id'),
						rb.comparisonTypes.Equals,
						val.Campaign.value
					);
				}

				// User
				if (val.User.value.length) {
					appointmentFilters.addFilter(
						getField('appointment.user.id'),
						rb.comparisonTypes.Equals,
						val.User.value
					);
				}

				// Date
				if (val.Date.value.preset !== 'whenever') {
					const appointmentDates = DatePreset.getDatesFromValue(val.Date.value, useTags);
					// start
					if (appointmentDates.start) {
						appointmentFilters.addFilter(
							getField('appointment.date'),
							rb.comparisonTypes.GreaterThanEquals,
							appointmentDates.start
						);
					}
					// end
					if (appointmentDates.end) {
						appointmentFilters.addFilter(
							getField('appointment.date'),
							rb.comparisonTypes.LowerThanEquals,
							appointmentDates.end
						);
					}
				}

				// Reg Date
				if (val.RegDate && val.RegDate.value.preset !== 'whenever') {
					const appointmentDates = DatePreset.getDatesFromValue(val.RegDate.value, useTags);
					// start
					if (appointmentDates.start) {
						appointmentFilters.addFilter(
							getField('appointment.regDate'),
							rb.comparisonTypes.GreaterThanEquals,
							appointmentDates.start
						);
					}
					// end
					if (appointmentDates.end) {
						appointmentFilters.addFilter(
							getField('appointment.regDate'),
							rb.comparisonTypes.LowerThanEquals,
							appointmentDates.end
						);
					}
				}

				// Close Date
				if (val.CloseDate && val.CloseDate.value.preset !== 'whenever') {
					const appointmentDates = DatePreset.getDatesFromValue(val.CloseDate.value, useTags);
					// start
					if (appointmentDates.start) {
						appointmentFilters.addFilter(
							getField('appointment.endDate'),
							rb.comparisonTypes.GreaterThanEquals,
							appointmentDates.start
						);
					}
					// end
					if (appointmentDates.end) {
						appointmentFilters.addFilter(
							getField('appointment.endDate'),
							rb.comparisonTypes.LowerThanEquals,
							appointmentDates.end
						);
					}
				}

				// Status
				if (val.Status.value) {
					nd = new Date();
					switch (val.Status.value) {
						case 'open':
							appointmentFilters.addFilter(
								getField('appointment.endDate'),
								rb.comparisonTypes.GreaterThanEquals,
								nd
							);
							break;
						case 'closed':
							appointmentFilters.addFilter(
								getField('appointment.endDate'),
								rb.comparisonTypes.LowerThan,
								nd
							);
							break;
					}
				}

				if (val.hasActivity) {
					appointmentFilters.done();
					typeOr.done();
				} else {
					appointmentFilters.isNotFilter();
					appointmentFilters.done();
				}
			}
		},
	buildOnlyAppointment:
		(options = {}) =>
		(filter, rb, filterHelperGetField, useTags) => {
			const val = filter.value;

			const getField = options.fieldPrefix
				? field => filterHelperGetField([options.fieldPrefix, field].join('.'))
				: filterHelperGetField;

			if (filter.inactive) {
				return;
			}

			let appointmentsFilters;
			let typeOr;

			if (
				!val.hasActivity &&
				(val.Date.value.preset === 'whenever' ||
					(val.Date.value.preset === 'custom' && !val.Date.value.start && !val.Date.value.end)) &&
				!val.Outcome?.value?.length &&
				!val.ActivityType.value.length
			) {
				rb.addFilter(getField('appointment.id'), rb.comparisonTypes.Equals, null);
				return;
			} else if (val.hasActivity) {
				appointmentsFilters = rb;
				appointmentsFilters.addFilter(getField('appointment.id'), rb.comparisonTypes.NotEquals, null);
			} else {
				appointmentsFilters = rb.groupBuilder();
				appointmentsFilters.isNotFilter();
			}

			// Appointment
			if (val.hasActivity) {
				typeOr = rb.orBuilder();
				typeOr.next();
				appointmentsFilters = typeOr.groupBuilder();
				appointmentsFilters.addFilter(getField('appointment.id'), rb.comparisonTypes.NotEquals, null);
			} else {
				appointmentsFilters = rb.groupBuilder();
			}

			// Outcome
			if (val.Outcome?.value?.length) {
				appointmentsFilters.addFilter(
					getField('appointment.outcome'),
					rb.comparisonTypes.Term,
					val.Outcome.value
				);
			}

			// ActivityType
			if (val.ActivityType.value.length) {
				appointmentsFilters.addFilter(
					getField('appointment.activityType.id'),
					rb.comparisonTypes.Equals,
					val.ActivityType.value
				);
			}

			// Date
			if (val.Date.value.preset !== 'whenever') {
				const appointmentDates = DatePreset.getDatesFromValue(val.Date.value, useTags);
				// start
				if (appointmentDates.start) {
					appointmentsFilters.addFilter(
						getField('appointment.date'),
						rb.comparisonTypes.GreaterThanEquals,
						appointmentDates.start
					);
				}
				// end
				if (appointmentDates.end) {
					appointmentsFilters.addFilter(
						getField('appointment.date'),
						rb.comparisonTypes.LowerThanEquals,
						appointmentDates.end
					);
				}
			}

			if (val.hasActivity) {
				appointmentsFilters.done();
				typeOr.done();
			} else {
				appointmentsFilters.isNotFilter();
				appointmentsFilters.done();
			}
		}
};

export default activityFilter;
