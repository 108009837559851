import _ from 'lodash';

const getRoleId = role => {
	return typeof role.id === 'string' ? parseInt(role.id.replace('role-', ''), 10) : parseInt(role.id, 10);
};

const UserRoleTree = ({
	accessType,
	selectableRoles = false,
	noParentRef = false,
	includeInactive = false,
	roleIdPrefix = false,
	startDepthOn = 0,
	includeLoggedInUser = true,
	excludeGhosts = false,
	ignoreLength = false,
	includeSupport = false,
	formatUser = user => user,
	formatRole = role => role
} = {}) => {
	let users = _.cloneDeep(Tools.AppService.getUsers(accessType, false, includeSupport)) ?? [];
	let roles = _.cloneDeep(Tools.AppService.getRoles(accessType));

	const countChildren = array => {
		const helper = group => {
			let counter = 0;
			_.each(group.children, child => {
				if (!child.isRole) {
					counter++;
				} else {
					counter += helper(child);
				}
			});
			group.$$length = counter;
			return counter;
		};

		_.each(array, child => {
			if (child.children) {
				helper(child);
			}
		});
	};

	const setDepth = (array, depth) => {
		_.each(array, ruser => {
			ruser.$depth = depth;
			if (ruser.children) {
				setDepth(ruser.children, depth + 1);
			}
		});
	};

	const root = [];

	if (includeLoggedInUser === false) {
		const self = Tools.AppService.getSelf();

		users = users.filter(user => {
			return user.id !== self.id && user.name !== self.name;
		});
	}
	if (excludeGhosts) {
		users = users.filter(user => !user.ghost);
	}
	_.each(users, u => {
		const user = formatUser(u);
		// If root
		if (!user.role || user.role.id === 0) {
			user.$depth = startDepthOn || 0;
			root.push(user);
		} else {
			const parent = roles.find(role => {
				const roleId = getRoleId(role);
				return roleId === user.role.id;
			});

			if (parent) {
				if (!parent.children) {
					parent.children = [];
				}
				parent.children.push(user);
				if (!noParentRef) {
					user.$$parent = parent;
				}
			}
		}
	});

	// Filter out roles without users or child roles.
	if (!includeInactive) {
		roles = _.filter(roles, role => {
			if (role.children) {
				return true;
			}
			return _.some(roles, innerRole => {
				return innerRole.parent && getRoleId(innerRole.parent) === getRoleId(role);
			});
		});
	}
	_.each(roles, r => {
		const role = formatRole(r);
		// If root
		if (!role.parent || role.parent.id === 0) {
			role.$depth = startDepthOn || 0;
			root.push(role);
		} else {
			const parent = _.find(roles, rol => {
				const rolId = getRoleId(rol);
				const parentId = getRoleId(role.parent);
				return parentId === rolId || parentId === rol.$id;
			});

			if (parent) {
				if (!parent.children) {
					parent.children = [];
				}
				parent.children.push(role);
				if (!noParentRef) {
					role.$$parent = parent;
				}
			}
		}
		role.isRole = true;
		role.$id = role.id;
		if (!role.children) {
			role.children = [];
		}
		if (!selectableRoles) {
			delete role.id; // don't want select2 to be able to select a optgroup
		}
		const roleIdIsPrefixed = typeof role.id === 'string' && role.id.startsWith('role-');

		if (roleIdPrefix && !roleIdIsPrefixed) {
			role.id = 'role-' + role.$id;
		}
	});

	setDepth(root, startDepthOn || 0);
	if (!ignoreLength) {
		countChildren(root);
	}

	return root;
};

window.UserRoleTree = UserRoleTree;

export default UserRoleTree;
