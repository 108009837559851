import Resource from 'Resources/Resource';
import Attributes from 'Attributes/SalesboardCardAttributes';
import SalesboardCardType from 'App/resources/Model/SalesboardCard';
import { TYPE, STYLE } from 'Store/reducers/SystemNotificationReducer';

type MappedSalesboardCard = Omit<SalesboardCardType, 'config'> & { config: string };

class SalesboardCard extends Resource {
	eventName: string = 'salesboardCard';
	notifications: any = {
		save: () => ({
			title: 'default.saved',
			body: 'saved.salesboardCard',
			style: STYLE.SUCCESS,
			icon: 'check',
			type: TYPE.BODY
		}),
		saveError: () => ({
			title: 'default.error',
			body: 'saveError.salesboardCard',
			style: STYLE.ERROR,
			icon: 'times',
			type: TYPE.BODY
		})
	};
	constructor() {
		super('salesboardCards', Attributes);
	}

	map(salesboardCard: SalesboardCardType): MappedSalesboardCard {
		if (salesboardCard.config) {
			(salesboardCard as unknown as MappedSalesboardCard).config = JSON.stringify(salesboardCard.config);
		}

		return salesboardCard as unknown as MappedSalesboardCard;
	}

	new(): SalesboardCardType {
		return {
			config: {
				field1: { field: 'client.name', link: true, state: 'account.dashboard', id: 'client.id' },
				field2: { field: 'value', type: 'currency', tooltip: 'notes' },
				field3: { field: 'date', type: 'date' },
				field4: { related: true },
				showUser: true
			}
		};
	}

	find(...args: any): Promise<{ data: (SalesboardCardType & { id: number })[] }> {
		return super.find(...args);
	}
}

const resource = new SalesboardCard();

export default resource;
