import React from 'react';
import { CellValue } from '../chartHelper';
import { RCWidgetData } from 'Resources/ReportWidget';
import { RCDashboardWidget } from 'Resources/ReportDashboard';
import Row from './Row';
import bemClass from '@upsales/components/Utils/bemClass';

type DrilldownProps = {
	enableDrilldown: boolean;
	config: RCDashboardWidget;
};

type KeyLabelSet = {
	key: string;
	label: string;
	tooltip?: string;
	dotColor?: string;
};

type BodyProps = {
	classes: bemClass;
	data: {
		columnOrder: KeyLabelSet[];
		rowOrder: KeyLabelSet[];
		mappedRows: Map<string, Map<string, CellValue>>;
		mappedTotals: Map<string, { rawValue: string; displayValue: string }>;
		colors: RCWidgetData['colors'];
		currency: RCWidgetData['currency'];
	};
	drilldown: DrilldownProps;
};

const Body = ({ classes, data, drilldown }: BodyProps) => (
	<>
		{data.rowOrder.map(row => (
			<Row
				classes={classes}
				key={row.key}
				rowKey={row.key}
				columnOrder={data.columnOrder}
				rowMap={data.mappedRows.get(row.key)}
				drilldown={drilldown}
				colors={data.colors}
				currency={data.currency}
			/>
		))}
	</>
);
export default Body;
