import React, { useState, useEffect, useRef, useLayoutEffect } from 'react';
import { ButtonSelect, Card, Input, Text, Loader, Button } from '@upsales/components';
import BemClass from '@upsales/components/Utils/bemClass';
import T from 'Components/Helpers/translate';
import './MailTemplateSelect.scss';
import RequestBuilder, { comparisonTypes } from 'Resources/RequestBuilder';
import MailTemplateResource from 'Resources/MailTemplate';
import mailAttributes from 'Attributes/MailTemplateAttributes';
import MailTemplateType from 'App/resources/Model/MailTemplate';
import { CancelablePromise, makeCancelable } from 'App/babel/helpers/promise';
import { SlideFade } from 'App/components/animations';
import { useDebouncePromise } from 'Components/Helpers/Debounce';
import logError from 'App/babel/helpers/logError';
import MailTemplate from 'App/resources/Model/MailTemplate';

enum ButtonSelectValues {
	private,
	public
}

const LIMIT = 10;
const getMailTemplates = async (selectVal: ButtonSelectValues, search: string, offset: number) => {
	const templateFilter = new RequestBuilder();

	if (selectVal === ButtonSelectValues.private) {
		templateFilter.addFilter(mailAttributes.private, comparisonTypes.Equals, true);
	} else if (selectVal === ButtonSelectValues.public) {
		templateFilter.addFilter(mailAttributes.private, comparisonTypes.Equals, false);
	}

	if (search) {
		const orBuilder = templateFilter.orBuilder();

		orBuilder.next();
		orBuilder.addFilter(mailAttributes.name, comparisonTypes.Search, search);
		orBuilder.next();
		orBuilder.addFilter(mailAttributes.subject, comparisonTypes.Search, search);
		orBuilder.done();
	}

	templateFilter.addFilter(mailAttributes.socialEvent, comparisonTypes.Equals, null);
	templateFilter.addSort(mailAttributes.name, true);
	templateFilter.fields = ['id', 'name', 'subject', 'private', 'body', 'bodyJson', 'attachments'];
	templateFilter.limit = LIMIT;
	templateFilter.offset = offset;
	return MailTemplateResource.find(templateFilter.build());
};

interface Props {
	onChange: (template: MailTemplateType) => void;
}

//Refactor this component later to make it reusable
const MailTemplateSelect = ({ onChange }: Props) => {
	const [btnSelectVal, setBtnSelectVal] = useState<ButtonSelectValues>(ButtonSelectValues.private);
	const [recentlyUsedTemplates, setRecentlyUsedTemplates] = useState([]);
	const [templates, setTemplates] = useState<MailTemplate[]>([]);
	const [searchStr, setSearchStr] = useState('');
	const [searching, setSearching] = useState(true);
	const [total, setTotal] = useState<number>(0);
	const [offset, setOffset] = useState<number>(0);
	const [open, setOpen] = useState(false);
	const templatePromise = useRef<CancelablePromise<any>>();
	const fetchPromise = useRef<CancelablePromise<{ metadata: { total: number }; data: MailTemplate[] }>>();
	const listRef = useRef<HTMLDivElement | null>();

	const classes = new BemClass('MailTemplateSelect');

	const options = [
		{
			title: T('default.private'),
			value: ButtonSelectValues.private
		},
		{
			title: T('default.public'),
			value: ButtonSelectValues.public
		}
	];

	const fetch = useDebouncePromise(
		async (search: string, btnSelectVal: ButtonSelectValues, offset: number) => {
			setSearching(true);
			try {
				templatePromise.current = makeCancelable(getMailTemplates(btnSelectVal, search, offset));
				const res = await templatePromise.current.promise;
				setSearching(false);
				return res;
			} catch (err) {
				setSearching(false);
				logError(err, 'Failed to fetch mail templates');
			}
		},
		[],
		200
	);

	useLayoutEffect(() => {
		if (offset) {
			listRef?.current?.scrollIntoView?.({ block: 'end', behavior: 'smooth' });
		}
	}, [templates]);

	useEffect(() => {
		if (offset > 0) {
			setOffset(0);
		}
	}, [searchStr, btnSelectVal]);

	useEffect(() => {
		fetchPromise.current = fetch(searchStr, btnSelectVal, offset);
		// eslint-disable-next-line promise/catch-or-return
		fetchPromise.current.promise.then(res => {
			const { data, metadata } = res;
			if (offset) {
				setTemplates(templates.concat(data));
			} else {
				setTemplates(data);
			}
			setTotal(metadata.total);
		});
	}, [searchStr, btnSelectVal, offset]);

	useEffect(() => {
		const recentlyUsedPromise = makeCancelable(MailTemplateResource.getRecentlyUsed());
		recentlyUsedPromise.promise
			.then(setRecentlyUsedTemplates)
			.catch(err => logError(err, 'Failed to fetch recently used mail templates'));
		return () => {
			fetchPromise.current?.cancel();
			templatePromise.current?.cancel();
			recentlyUsedPromise?.cancel();
		};
	}, []);

	const resultRow = (template: MailTemplateType, index: number) => {
		return (
			<Card onClick={() => onChange(template)} className={classes.elem('result-item').b()} key={index}>
				<Text>{template.name}</Text>
				<Text size="sm" color="grey-11">
					{template.subject}
				</Text>
			</Card>
		);
	};
	const dialogRow = (text: string) => {
		return <div className={classes.elem('dialog').b()}>{text}</div>;
	};

	const showRecentlyUsed = () => {
		if (!recentlyUsedTemplates.length) {
			return null;
		}

		return (
			<>
				{dialogRow(T('mailTemplate.recentlyUsed'))}
				{recentlyUsedTemplates.length ? (
					<div className={classes.elem('results').b()}>{recentlyUsedTemplates.map(resultRow)}</div>
				) : null}
			</>
		);
	};

	const renderTemplates = () => {
		if (searching) return <Loader />;
		if (!templates.length) return dialogRow(T('noResult.generic'));
		return (
			<div ref={r => (listRef.current = r)} className={classes.elem('results').b()}>
				{!searchStr ? showRecentlyUsed() : null}
				{templates.length
					? dialogRow(
							btnSelectVal === ButtonSelectValues.private
								? T('mailDrawer.allPrivateTemplates')
								: T('mailDrawer.allPublicTemplates')
					  )
					: null}
				{templates.map(resultRow)}
			</div>
		);
	};

	const templatesVisible = open || !!templates.length || !!recentlyUsedTemplates.length;
	const templatesToLoad = total - templates.length < 10 ? total - templates.length : 10;
	return (
		<Card className={classes.b()}>
			<div className={classes.elem('filters').b()}>
				<ButtonSelect value={btnSelectVal} onChange={setBtnSelectVal} options={options} />
				<Input
					onFocus={() => setOpen(true)}
					onBlur={() => setOpen(false)}
					icon="search"
					onChange={e => setSearchStr(e.target.value)}
					placeholder={T('default.searchTemplates')}
					autofocus
				/>
			</div>
			<SlideFade height visible={templatesVisible} maxHeight={600}>
				<div>
					<Card className={classes.elem('list').mod({ open: templatesVisible }).b()}>
						{renderTemplates()}
					</Card>
					<SlideFade height visible={!searching && templates.length < total}>
						<Button
							className={classes.elem('load-more-btn').b()}
							disabled={searching}
							color="bright-blue"
							type="link"
							onClick={() => {
								setOffset(offset + templatesToLoad);
							}}
						>
							{T('mailDrawer.showXMore', { num: templatesToLoad })}
						</Button>
					</SlideFade>
				</div>
			</SlideFade>
		</Card>
	);
};

export default MailTemplateSelect;
