import React from 'react';
import { Icon } from '@upsales/components';

export const getIcon = (icon, ref, opts = {}) => {
	if (icon && icon.length) {
		let className = 'icon-holder';

		if (opts.error) {
			className += ' icon-holder--error';
		}

		return (
			<div
				className={className}
				onClick={() => {
					if (ref && typeof ref.focus === 'function') {
						ref.focus();
					}
				}}
			>
				<Icon name={icon} />
			</div>
		);
	}

	return null;
};
