import './MoveSubscriptionProgress.scss';

import React, { useState, useEffect, useRef } from 'react';
import T from 'Components/Helpers/translate';
import { Card, Text, Block, Headline } from '@upsales/components';
import BemClass from '@upsales/components/Utils/bemClass';
import { SlideFade } from 'App/components/animations';
import openAgreement from 'App/helpers/openSubscriptionHelper';

type Props = {
	visible?: boolean;
	fromCompanyName?: string;
	toCompanyName?: string;
	closeModal?: () => void;
	agreementId?: number;
	agreementGroupId?: number;
};

const MoveSubscriptionProgress = ({
	visible,
	fromCompanyName,
	toCompanyName,
	closeModal,
	agreementId,
	agreementGroupId
}: Props) => {
	const animationDelayInMs = 1500;
	const countdownInSeconds = 5;
	const classes = new BemClass('MoveSubscriptionProgress');
	const [moveToSubscriptionCountdown, setMoveToSubscriptionCountdown] = useState(countdownInSeconds);
	const startAnimationTimer = useRef<NodeJS.Timeout>();
	const countdownTimer = useRef<NodeJS.Timeout>();

	const [startAnimation, setStartAnimation] = useState(false);

	const startCountdown = () => {
		countdownTimer.current = setInterval(() => {
			setMoveToSubscriptionCountdown(count => count - 1);
		}, 1000);
	};

	const initAnimation = () => {
		startAnimationTimer.current = setTimeout(() => setStartAnimation(true), animationDelayInMs);
	};

	const moveToSubscription = () => {
		closeModal?.();

		if (agreementId) {
			openAgreement({ agreementId, agreementGroupId, isMoved: true });
		}
	};

	const clearTimer = (timer: NodeJS.Timeout | undefined) => {
		if (timer) {
			clearInterval(timer);
		}
	};

	const clearTimers = () => {
		clearTimer(countdownTimer.current);
		clearTimer(startAnimationTimer.current);
	};

	useEffect(() => {
		if (visible) {
			initAnimation();
		} else {
			clearTimers();
		}

		return () => clearTimers();
	}, [visible]);

	useEffect(() => {
		if (startAnimation) {
			startCountdown();
		}
		return () => clearTimer(countdownTimer.current);
	}, [startAnimation]);

	useEffect(() => {
		if (moveToSubscriptionCountdown === 0) {
			clearTimer(countdownTimer.current);
			moveToSubscription();
		}
	}, [moveToSubscriptionCountdown]);

	const renderCompanyCard = (elemName: string, companyName?: string) => (
		<Card space="ptxl prxl pbxl" className={classes.elem(elemName).mod({ animate: startAnimation }).b()}>
			<Text bold className="text-ellipsis">
				{companyName}
			</Text>
		</Card>
	);

	const renderIcon = () => (
		<img className={classes.elem('fileIcon').mod({ animate: startAnimation }).b()} src="img/subscription.svg" />
	);

	const renderCompanyCards = () => (
		<Block space="mbl" className={classes.elem('companyCards').b()}>
			{renderIcon()}
			{renderCompanyCard('fromCompanyCard', fromCompanyName)}
			{renderCompanyCard('toCompanyCard', toCompanyName)}
		</Block>
	);

	const renderHeadline = () => (
		<Block space="mtxl">
			<Headline size="sm" className={classes.elem('headline').b()}>
				{T('agreement.subscriptionMovedHeadline')}
			</Headline>
		</Block>
	);

	const renderCountdown = () => (
		<Block space="mtl">
			<Text className={classes.elem('countdown').b()}>
				{T('agreement.subscriptionMovedCountdown', { count: moveToSubscriptionCountdown })}
			</Text>
		</Block>
	);

	return (
		<SlideFade complete bounce visible={visible} delayInMs={animationDelayInMs}>
			<div className={classes.b()}>
				{renderCompanyCards()}
				{renderHeadline()}
				{renderCountdown()}
			</div>
		</SlideFade>
	);
};

export default MoveSubscriptionProgress;
