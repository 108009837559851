export enum STEP {
	DETAILS = 'details',
	SUMMARY = 'summary',
	HISTORY = 'history'
}

export enum FINAL_STEP {
	DIFFORDER = 'diffOrder',
	EDIT_SUMMARY = 'editSummary'
}
