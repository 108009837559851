import SubscriptionPeriodFooter from '../SubscriptionPeriod/SubscriptionPeriodFooter';
import { useSubscriptionGroupContext } from '../Context/SubscriptionGroupContext';
import SubscriptionPeriodSelector from './SubscriptionPeriodSelector';
import SplitSubscriptionSidebar from './SplitSubscriptionSidebar';
import SplitSubscriptionButton from './SplitSubscriptionButton';
import SubscriptionScheduling from './SubscriptionScheduling';
import SplitSubscriptionPage from './SplitSubscriptionPage';
import BemClass from '@upsales/components/Utils/bemClass';
import SubscriptionHotspot from '../SubscriptionHotspot';
import SubscriptionPeriod from '../SubscriptionPeriod';
import React, { memo, useEffect, useState } from 'react';
import { Headline, Row } from '@upsales/components';
import T from 'Components/Helpers/translate';

import './SubscriptionSummary.scss';

type SplitSplitSummaryProps = {
	setShowSplitPage: (show: boolean) => void;
	showSplitPage: boolean;
	createdFromOrderId?: number;
	uuid?: number;
	dontFetch?: boolean;
};

const SplitSummary: React.FC<SplitSplitSummaryProps> = memo(
	({ showSplitPage, setShowSplitPage, createdFromOrderId, uuid, dontFetch }) => {
		const classNames = new BemClass('SubscriptionSummary');
		return (
			// eslint-disable-next-line react/jsx-key
			<div className={classNames.mod({ showSplitPage }).b()}>
				{showSplitPage ? <SplitSubscriptionPage closePage={() => setShowSplitPage(false)} /> : null}
				<div className={classNames.elem('scheduling-page').mod({ showSplitPage }).b()}>
					<Headline size="sm">
						<Row>
							{T('subscription.modal.summary.scheduling.title')}
							<SubscriptionHotspot type="scheduling" />
						</Row>
					</Headline>
					<SubscriptionPeriod dontFetch={dontFetch} uuid={uuid} isCreatedFromOrder={!!createdFromOrderId} />
				</div>
			</div>
		);
	}
);

type Props = {
	close: () => void;
};

const SubscriptionSummary = ({ close }: Props) => {
	const classNames = new BemClass('SubscriptionSummary');
	const {
		state: { isEdit, splitEnabled, isAdvancedMode, createdFromOrderId, currentUUID },
		currentPeriod,
		splitSubscription
	} = useSubscriptionGroupContext();
	const [showSplitPage, setShowSplitPage] = useState(false);
	const [scroll, setScroll] = useState(0);
	const [lastUUID, setLastUUID] = useState(currentUUID);

	const hasSidebar = Tools.FeatureHelper.hasSoftDeployAccess('SUBSCRIPTION_SPLITS');

	useEffect(() => {
		if (lastUUID === currentUUID || showSplitPage) return;
		const scrollElement = (document.getElementsByClassName('OpenSubscription') ?? [])[0];
		scrollElement?.scroll({ top: 0, behavior: 'smooth' });

		if (currentUUID < lastUUID) {
			setScroll(1);
		} else {
			setScroll(-1);
		}

		const scrollTimeout = setTimeout(() => {
			setScroll(0);
			setLastUUID(currentUUID);
		}, 1000);

		return () => clearTimeout(scrollTimeout);
	}, [currentUUID]);

	if (hasSidebar) {
		const splitSummaryProps = { setShowSplitPage, showSplitPage, createdFromOrderId };
		return (
			<div className={classNames.elem('with-sidebar').b()}>
				<SplitSubscriptionSidebar
					activateSplitPage={() => setShowSplitPage(true)}
					disabled={showSplitPage}
					close={close}
				/>
				<div>
					<div
						className={classNames
							.elem('main-summary')
							.mod({ mainUp: scroll === 1, mainDown: scroll === -1 })
							.b()}
					>
						<SplitSummary {...splitSummaryProps} uuid={scroll !== 0 ? lastUUID : undefined} />
					</div>
					{scroll !== 0 ? (
						<div
							className={classNames
								.elem('fake-summary')
								.mod({ fakeUp: scroll === 1, fakeDown: scroll === -1 })
								.b()}
						>
							<SplitSummary {...splitSummaryProps} dontFetch />
						</div>
					) : null}
				</div>

				<SubscriptionPeriodFooter />
			</div>
		);
	}

	const isSplittingSupported = isAdvancedMode && (splitEnabled || !isEdit);
	return (
		<div className={classNames.b()}>
			<SubscriptionScheduling />

			{isSplittingSupported && splitEnabled ? <SubscriptionPeriodSelector /> : null}

			<SubscriptionPeriod isCreatedFromOrder={!!createdFromOrderId}>
				{isSplittingSupported && !splitEnabled && !isEdit ? (
					<SplitSubscriptionButton disabled={currentPeriod.periodLength === 0} onClick={splitSubscription} />
				) : null}
			</SubscriptionPeriod>

			<SubscriptionPeriodFooter />
		</div>
	);
};

export default SubscriptionSummary;
