import { AppState } from 'Store/reducers/AppReducer';
import { Feature, getFeatureAvailableFromState } from './featureHelper';
import useSelector from './useSelector';

export const getSelectedBrandIdFromState = (self: AppState['self'], accountSelf: AppState['accountSelf']) => {
	const hasBrands = getFeatureAvailableFromState(accountSelf, Feature.BRANDS);

	return (hasBrands && self?.userParams.selectedBrand) || 1;
};

const useSelectedBrandId = () => {
	const { self, accountSelf } = useSelector(({ App }) => ({ self: App.self, accountSelf: App.accountSelf }));

	return getSelectedBrandIdFromState(self, accountSelf);
};

export default useSelectedBrandId;
