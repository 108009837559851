import ActivityResource from 'Resources/Activity';
import { makeCancelable, CancelablePromise } from 'App/babel/helpers/promise';
import RequestBuilder, { comparisonTypes } from 'Resources/RequestBuilder';

import Order from 'App/resources/Model/Order';
import OrderResource from 'App/resources/Order';
import OrderAttributes from 'App/babel/attributes/Order';
import Activity from 'App/resources/Model/Activity';
import Appointment from 'App/resources/Model/Appointment';
import { AvailableRelationConfig } from 'App/resources/Model/EntityRelation';

const LIMIT = 10;

export type FetchPromises = [Promise<Order[]>, Promise<Appointment[]>, Promise<Activity[]>];
export type RelationPromises = [Promise<Order> | null, Promise<Appointment> | null, Promise<Activity> | null];

export const fetchOpportunities = async (
	search: string,
	userId: number,
	clientId: number,
	searchForOrders: boolean,
	searchForOpportunities: boolean
) => {
	const opportunityFilter = new RequestBuilder();
	if (searchForOpportunities && !searchForOrders) {
		opportunityFilter.addFilter(OrderAttributes.probability, comparisonTypes.LessThan, 100);
		opportunityFilter.addFilter(OrderAttributes.probability, comparisonTypes.GreaterThan, 0);
	} else if (!searchForOpportunities && searchForOrders) {
		opportunityFilter.addFilter(OrderAttributes.probability, comparisonTypes.Equals, [0, 100]);
	}
	if (search) {
		opportunityFilter.addFilter({ field: 'description' }, comparisonTypes.Search, search);
	}
	opportunityFilter.addFilter({ field: 'user.id' }, comparisonTypes.Equals, userId);
	opportunityFilter.addFilter({ field: 'client.active' }, comparisonTypes.Equals, 1);
	opportunityFilter.addFilter({ field: 'client.id' }, comparisonTypes.Equals, clientId);
	opportunityFilter.limit = LIMIT;

	const { data } = await OrderResource.find(opportunityFilter.build());
	return data;
};

export const fetchAppointments = async (search: string, userId: number, clientId: number) => {
	const { Appointment, AppService } = Tools;
	const rb = new RequestBuilder();
	if (search) {
		rb.addFilter({ field: 'description' }, comparisonTypes.Search, search);
	}
	rb.addFilter({ field: 'user.id' }, comparisonTypes.Equals, userId);
	rb.addFilter({ field: 'client.active' }, comparisonTypes.Equals, 1);
	rb.addFilter({ field: 'client.id' }, comparisonTypes.Equals, clientId);

	rb.limit = LIMIT;

	const { data } = await Appointment.customer(AppService.getCustomerId()).find(rb.build());
	return data;
};
export const fetchActivities = async (search: string, userId: number, clientId: number) => {
	const rb = new RequestBuilder();
	if (search) {
		rb.addFilter({ field: 'description' }, comparisonTypes.Search, search);
	}
	rb.addFilter({ field: 'user.id' }, comparisonTypes.Equals, userId);
	rb.addFilter({ field: 'client.active' }, comparisonTypes.Equals, 1);
	rb.addFilter({ field: 'client.id' }, comparisonTypes.Equals, clientId);

	rb.limit = LIMIT;

	const { data } = await ActivityResource.find(rb.build());
	return data;
};

export const fetch = (
	search: string,
	userId: number,
	clientId: number | null,
	availableEntities: AvailableRelationConfig = { order: false, opportunity: true, activity: true, appointment: true }
) => {
	const promises: FetchPromises = !clientId
		? [Promise.resolve([]), Promise.resolve([]), Promise.resolve([])]
		: [
				availableEntities.opportunity || availableEntities.order
					? fetchOpportunities(
							search,
							userId,
							clientId,
							availableEntities.order,
							availableEntities.opportunity
					  )
					: Promise.resolve([]),
				availableEntities.appointment ? fetchAppointments(search, userId, clientId) : Promise.resolve([]),
				availableEntities.activity ? fetchActivities(search, userId, clientId) : Promise.resolve([])
		  ];
	return makeCancelable(Promise.all(promises));
};

export const getOpportunity = async (opportunityId: number) => {
	const { data } = await OrderResource.get(opportunityId);
	return data;
};

export const getAppointment = async (appointmentId: number) => {
	const { Appointment, AppService } = Tools;
	const { data } = await Appointment.customer(AppService.getCustomerId()).get(appointmentId);
	return data;
};

export const getActivity = async (activityId: number) => {
	const { data } = await ActivityResource.get(activityId);
	return data;
};

export const getRelations = (
	opportunityId?: number | null,
	appointmentId?: number | null,
	activityId?: number | null
): CancelablePromise => {
	const promises: RelationPromises = [
		opportunityId ? getOpportunity(opportunityId) : null,
		appointmentId ? getAppointment(appointmentId) : null,
		activityId ? getActivity(activityId) : null
	];
	return makeCancelable(Promise.all(promises));
};
