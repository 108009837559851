import DatePreset from 'App/upsales/common/services/datePresetService';

function generateFilter(options = {}, overrides = {}) {
	function generate() {
		return {
			inactive: true,
			value: {
				hasActivity: true,
				overdueAsClosed: false,
				ActivityType: { value: [] },
				RegDate: { value: { start: null, end: null, preset: 'whenever' } },
				CloseDate: { value: { start: null, end: null, preset: 'whenever' } },
				Date: { value: { start: null, end: null, preset: 'whenever' } },
				Campaign: { value: [] },
				User: { value: [] },
				Priority: { value: [] },
				Status: { value: null },
				Outcome: { value: null },
				OutcomeDate: { value: { start: null, end: null, preset: 'whenever' } }
			}
		};
	}

	function isInactive(filter) {
		return filter.inactive;
	}

	function toUrl(filter) {
		const f = { v: {} };
		const val = filter.value;

		f.v.has = val.hasActivity;
		f.i = filter.inactive;

		if (val.ActivityType.value) {
			f.v.ty = val.ActivityType.value;
		}
		if (val.Campaign.value) {
			f.v.ca = val.Campaign.value;
		}
		if (val.User.value) {
			f.v.us = val.User.value;
		}
		if (val.Priority && val.Priority.value) {
			f.v.pr = val.Priority.value;
		}
		if (val.Status.value) {
			f.v.st = val.Status.value;
		}
		if (val.Outcome?.value) {
			f.v.ao = val.Outcome.value;
		}
		if (val.overdueAsClosed) {
			f.v.oc = true;
		}
		if (val.Date && (val.Date.value.start || val.Date.value.end || val.Date.value.preset !== 'whenever')) {
			f.v.da = { p: val.Date.value.preset, s: val.Date.value.start, e: val.Date.value.end };
		}
		if (
			val.OutcomeDate &&
			(val.OutcomeDate.value.start || val.OutcomeDate.value.end || val.OutcomeDate.value.preset !== 'whenever')
		) {
			f.v.oda = { p: val.OutcomeDate.value.preset, s: val.OutcomeDate.value.start, e: val.OutcomeDate.value.end };
		}
		if (
			val.RegDate &&
			(val.RegDate.value.start || val.RegDate.value.end || val.RegDate.value.preset !== 'whenever')
		) {
			f.v.regDa = { p: val.RegDate.value.preset, s: val.RegDate.value.start, e: val.RegDate.value.end };
		}
		if (
			val.CloseDate &&
			(val.CloseDate.value.start || val.CloseDate.value.end || val.CloseDate.value.preset !== 'whenever')
		) {
			f.v.closeDa = { p: val.CloseDate.value.preset, s: val.CloseDate.value.start, e: val.CloseDate.value.end };
		}

		return f;
	}

	function fromUrl(rawFilter) {
		const filter = generate();
		filter.inactive = false;
		const val = rawFilter.v;

		filter.value.hasActivity = val.has;

		if (rawFilter.i) {
			filter.inactive = rawFilter.i;
		}
		if (val.ty) {
			filter.value.ActivityType = { value: val.ty };
		}
		if (val.ca) {
			filter.value.Campaign = { value: val.ca };
		}
		if (val.us) {
			filter.value.User = { value: val.us };
		}
		if (val.pr) {
			filter.value.Priority = { value: val.pr };
		}
		if (val.ao) {
			filter.value.Outcome = { value: val.ao };
		}
		if (val.st) {
			filter.value.Status = { value: val.st };
		}
		if (val.oc) {
			filter.value.overdueAsClosed = true;
		}

		if (val.da) {
			filter.value.Date = {
				value: {
					start: val.da.s ? new Date(val.da.s) : val.da.s,
					end: val.da.e ? new Date(val.da.e) : val.da.e,
					preset: val.da.p
				}
			};
		}
		if (val.oda) {
			filter.value.OutcomeDate = {
				value: {
					start: val.oda.s ? new Date(val.oda.s) : val.oda.s,
					end: val.oda.e ? new Date(val.oda.e) : val.oda.e,
					preset: val.oda.p
				}
			};
		}
		if (val.regDa) {
			filter.value.RegDate = {
				value: {
					start: val.regDa.s ? new Date(val.regDa.s) : val.regDa.s,
					end: val.regDa.e ? new Date(val.regDa.e) : val.regDa.e,
					preset: val.regDa.p
				}
			};
		}
		if (val.closeDa) {
			filter.value.CloseDate = {
				value: {
					start: val.closeDa.s ? new Date(val.closeDa.s) : val.closeDa.s,
					end: val.closeDa.e ? new Date(val.closeDa.e) : val.closeDa.e,
					preset: val.closeDa.p
				}
			};
		}

		return filter;
	}

	function build(filter, rb, filterHelperGetField, useTags) {
		const val = filter.value;

		if (filter.inactive) {
			return;
		}

		const getField = options.fieldPrefix
			? field => filterHelperGetField([options.fieldPrefix, field].join('.'))
			: filterHelperGetField;

		const builder = rb.groupBuilder();

		if (!val.hasActivity) {
			builder.isNotFilter();
		}

		if (options.fixedActivityType) {
			builder.addFilter(
				getField('activity.activityType.id'),
				rb.comparisonTypes.Equals,
				options.fixedActivityType
			);
		} else {
			builder.addFilter(getField('activity.id'), rb.comparisonTypes.NotEquals, null);
		}

		// ActivityType
		if (val.ActivityType.value.length) {
			builder.addFilter(getField('activity.activityType.id'), rb.comparisonTypes.Equals, val.ActivityType.value);
		}

		// Campaign
		if (val.Campaign.value.length) {
			builder.addFilter(getField('activity.project.id'), rb.comparisonTypes.Equals, val.Campaign.value);
		}

		// User
		if (val.User.value.length) {
			builder.addFilter(getField('activity.user.id'), rb.comparisonTypes.Equals, val.User.value);
		}

		// Priority
		if (val.Priority && val.Priority.value.length) {
			builder.addFilter(getField('activity.priority'), rb.comparisonTypes.Equals, val.Priority.value);
		}

		// Date
		if (val.Date.value.preset !== 'whenever') {
			const dates = DatePreset.getDatesFromValue(val.Date.value, useTags);
			// start
			if (dates.start) {
				builder.addFilter(getField('activity.date'), rb.comparisonTypes.GreaterThanEquals, dates.start);
			}
			// end
			if (dates.end) {
				builder.addFilter(getField('activity.date'), rb.comparisonTypes.LowerThanEquals, dates.end);
			}
		}

		// CloseDate
		if (val.CloseDate && val.CloseDate.value.preset !== 'whenever') {
			const dates = DatePreset.getDatesFromValue(val.CloseDate.value, useTags);
			// start
			if (dates.start) {
				builder.addFilter(getField('activity.closeDate'), rb.comparisonTypes.GreaterThanEquals, dates.start);
			}
			// end
			if (dates.end) {
				builder.addFilter(getField('activity.closeDate'), rb.comparisonTypes.LowerThanEquals, dates.end);
			}
		}

		// RegDate
		if (val.RegDate && val.RegDate.value.preset !== 'whenever') {
			const dates = DatePreset.getDatesFromValue(val.RegDate.value, useTags);
			// start
			if (dates.start) {
				builder.addFilter(getField('activity.regDate'), rb.comparisonTypes.GreaterThanEquals, dates.start);
			}
			// end
			if (dates.end) {
				builder.addFilter(getField('activity.regDate'), rb.comparisonTypes.LowerThanEquals, dates.end);
			}
		}

		// Outcome Date
		if (val.OutcomeDate && val.OutcomeDate.value.preset !== 'whenever') {
			const dates = DatePreset.getDatesFromValue(val.OutcomeDate.value, useTags);
			// start
			if (dates.start) {
				builder.addFilter(
					getField('activity.lastOutcome.date'),
					rb.comparisonTypes.GreaterThanEquals,
					dates.start
				);
			}
			// end
			if (dates.end) {
				builder.addFilter(getField('activity.lastOutcome.date'), rb.comparisonTypes.LowerThanEquals, dates.end);
			}
		}

		// Outcome
		if (val.Outcome?.value) {
			builder.addFilter(getField('activity.lastOutcome.type'), rb.comparisonTypes.Equals, val.Outcome.value);
		}

		// Status
		if (val.Status.value) {
			switch (val.Status.value) {
				case 'open':
					if (val.overdueAsClosed) {
						builder.addFilter(getField('activity.closeDate'), rb.comparisonTypes.Equals, null);
						builder.addFilter(getField('activity.date'), rb.comparisonTypes.GreaterThanEquals, new Date());
					} else {
						builder.addFilter(getField('activity.closeDate'), rb.comparisonTypes.Equals, null);
					}

					break;
				case 'closed':
					if (val.overdueAsClosed) {
						const statusDateFilters = builder.orBuilder();
						statusDateFilters.next();
						statusDateFilters.addFilter(getField('activity.closeDate'), rb.comparisonTypes.NotEquals, null);
						statusDateFilters.next();
						statusDateFilters.addFilter(getField('activity.closeDate'), rb.comparisonTypes.Equals, null);
						statusDateFilters.addFilter(
							getField('activity.date'),
							rb.comparisonTypes.LessThanEquals,
							new Date()
						);
						statusDateFilters.done();
					} else {
						builder.addFilter(getField('activity.closeDate'), rb.comparisonTypes.NotEquals, null);
					}

					break;
			}
		}

		builder.done();
	}

	const filter = {
		filterName: 'Activity',
		type: 'raw',
		displayType: 'activity',
		title: 'default.activities',
		parent: 'advancedSearch.contactHistory',
		entity: 'contact',
		showOnSegment: true,
		hide: true,
		generate,
		isInactive,
		toUrl,
		fromUrl,
		build,
		presets: [
			'whenever',
			'last7days',
			'last14days',
			'last30days',
			'last90days',
			'lastXdays',
			'next7days',
			'next30days',
			'next45days',
			'next90days',
			'custom'
		]
	};
	return Object.assign(filter, overrides);
}

export default generateFilter;
