import CustomField, { type CustomFieldWithValue, type EntityCustomField } from 'App/resources/Model/CustomField';
import { has, pick } from 'lodash';
import { replaceItem } from '@upsales/components/Utils/arrayHelpers';
import { useCustomFields } from 'App/components/hooks/appHooks';
import { SubscriptionPeriodState } from 'App/components/EditSubscription/Context/SubscriptionGroupState';
import { OrderRow } from 'App/components/OrderRows/Context/OrderContext';

export type ChangedCustomField = {
	id?: number;
	fieldId: number;
	value: any;
};

export type EntityCustomFields = ReturnType<typeof useCustomFields>;

type AllowedCustomField = Pick<CustomFieldWithValue, 'id' | 'value' | 'name' | 'default'>;

export const nonAllowedCustomDataTypes = ['Calculation'];

export const allowedCustomFields = [
	'id', // Since this is used for lookup, there is no way of changing this
	'value',
	'name',
	'default'
];

const getCustomFieldValue = (
	customField: CustomFieldWithValue | CustomField,
	updatedValue: string | boolean | Date | null
) => {
	if (customField.datatype === 'Boolean') {
		return `${updatedValue}` === 'false' || `${updatedValue}` === '0' || !updatedValue ? null : true;
	}
	return `${updatedValue}`;
};

const convertToEntityCustomFields = (updatedCustomField: AllowedCustomField[]): EntityCustomField[] => {
	return updatedCustomField.map(updatedCustomField => ({
		fieldId: updatedCustomField.id,
		value: updatedCustomField.value
	}));
};

const updatedCustomFieldMapper = (updatedCustomFields: ChangedCustomField[]) => {
	return updatedCustomFields.map(updatedCustomField => {
		if (updatedCustomField.fieldId) {
			updatedCustomField.id = updatedCustomField.fieldId;
		}
		const allowedFields = pick(updatedCustomField, allowedCustomFields) as AllowedCustomField;
		return allowedFields;
	});
};

const existingCustomFieldMapper = (
	existingCustomFields: SubscriptionPeriodState['custom'],
	entityCustomFields: EntityCustomFields
): CustomFieldWithValue[] => {
	if (!existingCustomFields?.length) {
		return [];
	}
	const mappedFields = entityCustomFields.map(entityCustomField => {
		const foundCustomField = existingCustomFields.find(customField => customField.fieldId === entityCustomField.id);
		if (!foundCustomField) {
			return entityCustomField;
		}
		return {
			...entityCustomField,
			value: foundCustomField.value
		};
	});
	return mappedFields.filter(Boolean) as CustomFieldWithValue[];
};

function updateCustomFields(
	entity: SubscriptionPeriodState | OrderRow,
	updatedCustomFields: ChangedCustomField[],
	entityCustomFields: EntityCustomFields
) {
	if (!updatedCustomFields?.length) {
		return;
	}

	const allowedCustomFieldChanges = updatedCustomFieldMapper(updatedCustomFields);
	let existingCustomFields = existingCustomFieldMapper(entity.custom, entityCustomFields);
	allowedCustomFieldChanges.forEach(changedItem => {
		const indexToReplace = existingCustomFields.findIndex(item => item.id === changedItem.id);
		if (indexToReplace === -1) {
			return;
		}

		const existingCustomField = existingCustomFields[indexToReplace];
		if (nonAllowedCustomDataTypes.includes(existingCustomField.datatype)) {
			return;
		}

		if (has(changedItem, 'value')) {
			const value = getCustomFieldValue(existingCustomField, changedItem.value ?? null);
			if (value && existingCustomField.datatype === 'Select') {
				console.log('value', value);
				if (!existingCustomField.default.includes(`${value}`)) {
					return;
				}
			}
		}

		const updatedItem = {
			...existingCustomField,
			...changedItem
		} as CustomFieldWithValue;

		existingCustomFields = replaceItem<CustomFieldWithValue>(existingCustomFields, indexToReplace, updatedItem);
	});

	return convertToEntityCustomFields(existingCustomFields);
}

export default updateCustomFields;
