import config from 'App/babel/config';

const parseMailTemplate = template => {
	// Set bodyJson to null if it is a stringified object
	try {
		if (template.bodyJson && template.bodyJson[0] === '{') {
			template.bodyJson = null;
		}
	} catch (e) {
		// do nothing
	}

	// TemplateThumb url
	template.$$thumbnailUrl = `${config.URL}${config.API}thumbnail/template/${template.id}?timestamp=${Date.now()}`;

	return template;
};

window.parseMailTemplate = parseMailTemplate;

export default parseMailTemplate;
