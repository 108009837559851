import Resource from './Resource';

class Automations extends Resource {
	constructor() {
		super('automations');

		this.eventName = 'automation';

		this.notifications = {
			getError: {
				title: 'default.error',
				body: 'admin.automationLoadError'
			},
			saveError: {
				title: 'default.error',
				body: 'saveError.automation'
			},
			findError: {
				title: 'default.error',
				body: 'admin.automationsLoadError'
			},
			deleteError: {
				title: 'default.error',
				body: 'deleteError.automation'
			},
			save: {
				title: 'default.saved',
				body: 'saved.automation'
			},
			delete: {
				title: 'default.deleted',
				body: 'deleted.automation'
			}
		};
	}

	new() {
		return {
			actions: [],
			criterias: [],
			active: true,
			job: 'automation-clients',
			name: ''
		};
	}
}

const resource = new Automations();

window.Tools.Automations = resource;

export default resource;
