import { Block, Button, Card, Headline, Icon, Input, RadioItem, Text } from '@upsales/components';
import BemClass from '@upsales/components/Utils/bemClass';
import { SlideFade } from 'App/components/animations';
import React, { ReactNode, useMemo } from 'react';
import t from 'Components/Helpers/translate';
import { RoleNode } from '../UserSurvey';

type UserSurveyRoleSectionProps = {
	root: RoleNode;
	onNext: (node: RoleNode | null) => void;
	currentStage: RoleNode;
	visible: boolean;
	classes?: BemClass;
};

/**
 * At first render root and currentStage are the same and root is used to render all the screens in the tree.
 * We need to do this to be able to animate each one properly. We only store the changes in currentStage
 * to prevent multiple tree operations. And this currentStage Node will be our pivot for the navigation.
 * It has references to the next posible paths and the previous one to be able to go back quickly and store all the selections
 * in case we want to go back or forward to a previously selected screen.
 */
const UserSurveyRoleSection = ({ root, onNext, currentStage, visible, classes }: UserSurveyRoleSectionProps) => {
	const isNotEmpty = (obj: { [key: string]: { value: string; label: string; placeholder: string } }) =>
		Object.values(obj).every(val => val?.value && val.value.trim().length > 0);

	const inOrderTraversal = (node: RoleNode, callback: (roleNode: RoleNode) => ReactNode) => {
		if (node !== null) {
			if (node.options) {
				for (const option of node.options) {
					if (option.next) {
						inOrderTraversal(option.next, callback);
					}
				}
			}
			callback(node);
		}
	};

	// Only visible when the currentStage is the same as the node we are rendering, otherwise we hide it
	// As we don't change the root node, we need to check if currentStage is the same as the node we are rendering
	// and base the selections on that.
	const renderNode = (
		node: RoleNode,
		stage: RoleNode,
		classes: BemClass | undefined,
		onNext: (node: RoleNode | null) => void
	) => (
		<SlideFade
			direction={
				Number(node.depth) < Number(stage.depth) || (node.depth === stage.depth && !stage.selected?.next)
					? 'top'
					: 'bottom'
			}
			visible={visible && stage.id === node.id}
			delayInMs={300}
			delayOutInMs={0}
			key={node.id}
		>
			<div className={classes?.elem('inner').b()}>
				<Block className={classes?.elem('radio-block').b()}>
					<Block space="mbxl">
						<Headline size="sm">{t(node.title)}</Headline>
					</Block>
					{node?.options?.map(option => (
						<Block space="mbxl" key={option.id}>
							<Card borderRadius borderColor="grey-6" space="pll mbl ptm pbm plm">
								<RadioItem
									id={option.id}
									checked={stage.selected?.['id'] === option.id}
									label={<Text bold> {t(option.title)} </Text>}
									size="md"
									value={option.id}
									onChange={() => {
										const opt = stage.options?.find(o => o.id === option.id);
										const newStage = { ...stage, selected: opt };
										if (opt?.preventAutoNext) {
											onNext(newStage);
										} else if (!newStage.selected?.next) {
											onNext(newStage);
											return onNext(null);
										} else {
											onNext({ ...newStage.selected.next, prev: newStage });
										}
									}}
								/>
								{option.extraFields
									? Object.keys(option.extraFields).map(extra => (
											<SlideFade visible={stage.selected?.id === option.id} key={extra} height>
												<div
													className={classes
														?.elem(
															stage.selected?.id === option.id
																? 'extra-field-container'
																: ''
														)
														.b()}
												>
													<Text className={classes?.elem('table-text').b()} bold>
														{t(option.extraFields?.[extra].label ?? '')}
													</Text>
													<Input
														className={classes?.elem('input').b()}
														key={extra}
														placeholder={t(option.extraFields?.[extra].placeholder ?? '')}
														value={stage.selected?.extraFields?.[extra]?.value ?? ''}
														onChange={e => {
															const value = e.target.value;
															onNext({
																...stage,
																selected: {
																	...option,
																	extraFields: {
																		...(stage.selected?.extraFields ?? {}),
																		[extra]: {
																			...(stage.selected?.extraFields?.[
																				extra
																			] ?? {
																				label: '',
																				placeholder: ''
																			}),
																			value
																		}
																	}
																}
															});
														}}
														maxLength={128}
													/>
												</div>
											</SlideFade>
									  ))
									: null}
							</Card>
						</Block>
					))}
					<SlideFade
						delayInMs={300}
						delayOutInMs={0}
						visible={
							!(
								!stage.selected ||
								(stage.selected?.extraFields && !isNotEmpty(stage.selected?.extraFields))
							)
						}
					>
						<Button
							onClick={() => {
								if (!stage.selected?.next) {
									return onNext(null);
								}
								onNext({ ...stage.selected.next, prev: stage });
							}}
						>
							{t(stage.selected && !stage.selected.next ? 'userSurvey.nextQuestion' : 'default.next')}{' '}
							<Icon name="arrow-right" />
						</Button>
					</SlideFade>
				</Block>
			</div>
		</SlideFade>
	);

	const renderNodeWrapper = (node: RoleNode) => renderNode(node, currentStage, classes, nextNode => onNext(nextNode));

	// Don't rerender all screens on every render of the container component, just when we have changes in the role section
	const screens = useMemo(() => {
		const list: ReactNode[] = [];
		inOrderTraversal(root, node => list.push(renderNodeWrapper(node)));
		return list;
	}, [currentStage, visible]);

	return <>{screens}</>;
};

export default UserSurveyRoleSection;
