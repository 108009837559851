import PropTypes from 'prop-types';
import React from 'react';
import colorMappings from '@upsales/components/Utils/colorMappings';
import './WidgetBarchart.scss';
import Highcharts from 'highcharts';

class WidgetBarchart extends React.Component {
	componentDidMount() {
		this.renderChart();
	}

	componentDidUpdate() {
		this.renderChart();
	}

	renderChart() {
		if (this._chart) {
			const series = [
				{
					data: []
				}
			];

			const { row } = this.props;

			row.data.forEach(data => {
				series[0].data.push({
					y: data.value || 0,
					color: colorMappings.get(data.color || 'bright-blue')
				});
			});

			const config = {
				chart: {
					type: 'column',
					backgroundColor: 'transparent',
					margin: [10, 15, 35, 50],
					height: 200,
					spacing: [0, 0, 0, 0]
				},
				title: { text: null },
				credits: { enabled: false },
				yAxis: {
					title: { text: null },
					gridLineDashStyle: 'Dash',
					labels: {
						rotation: 0,
						y: 0,
						x: -2,
						useHTML: true,
						formatter: function () {
							return `<div class="WidgetBarchart__value-label">
								<div class="Text Text--left Text--grey-10">${
									row.datatype === 'number' ? Tools.$filter('numberFormat')(this.value) : this.value
								}</div>
							</div>`;
						}
					}
				},
				xAxis: {
					type: 'category',
					title: { text: null },
					labels: {
						rotation: 0,
						y: 0,
						useHTML: true,
						formatter: function () {
							return `<div class="WidgetBarchart__label">
								<div class="Text Text--center Text--sm Text--grey-10">${this.value}</div>
							</div>`;
						}
					},
					lineWidth: 1,
					lineColor: colorMappings.get(row.baselineColor || 'grey-10'),
					tickWidth: 0,
					categories: row.data.map((d, i) => d.title || i)
				},
				legend: { enabled: false },
				plotOptions: {
					column: {
						states: {
							hover: {
								brightness: 0,
								halo: false
							}
						}
					}
				},
				tooltip: {
					borderColor: 'transparent',
					backgroundColor: '#1E252E',
					shadow: false,
					useHTML: true,
					formatter: function () {
						return `<div class="WidgetBarchart__tooltip">
							<div class="Text Text--white">${row.datatype === 'number' ? Tools.$filter('numberFormat')(this.y) : this.y}</div>
						</div>`;
					}
				},
				series
			};
			new Highcharts.Chart(this._chart, config);
		}
	}

	render() {
		return <div className="WidgetBarchart widget-barchart-wrapper" ref={r => (this._chart = r)} />;
	}
}

WidgetBarchart.propTypes = {
	row: PropTypes.shape({
		data: PropTypes.array,
		datatype: PropTypes.oneOf(['number', 'string'])
	})
};

export default WidgetBarchart;
