import { Block, Column, Link, Row, Text, Title } from '@upsales/components';
import { dateCalendar } from 'App/helpers/DateHelpers';
import { getAccountSelf } from 'Store/selectors/AppSelectors';
import { PrimaryButton } from '@upsales/components/Buttons';
import { useEditProjectPlanContext } from '../Context';
import BemClass from '@upsales/components/Utils/bemClass';
import Comment from 'App/resources/Model/Comment';
import CommentResource from 'Resources/Comment';
import Editor from '@draft-js-plugins/editor';
import getAngularModule from 'App/babel/angularHelpers/getAngularModule';
import logError from 'App/babel/helpers/logError';
import MentionsInput from 'Components/Mentions/MentionsInput/MentionsInput';
import React, { useEffect, useMemo, useRef } from 'react';
import t from 'Components/Helpers/translate';
import UserStack from 'App/components/UserStack';

import './ProjectConversation.scss';

const ProjectConversation = () => {
	const accountSelf = getAccountSelf();
	const classes = new BemClass('ProjectConversation');
	const EventService = useMemo(() => getAngularModule('EventService'), []);
	const textareaRef = useRef<HTMLTextAreaElement | Editor | null>(null);

	const {
		state: { comment, events, hasMoreEvents, projectPlan, savingComment },
		addEvent,
		getEvents,
		getMoreEvents,
		setComment,
		saveComment
	} = useEditProjectPlanContext();

	useEffect(() => {
		if (!projectPlan?.id) {
			return;
		}
		getEvents();
	}, [projectPlan?.id]);

	const addCommentToTimeline = (comment: Comment) => {
		const event = EventService.create.Comment(comment);
		addEvent(event);
	};

	useEffect(() => {
		const commentAddedListener = Tools.$rootScope.$on(
			'comment.added',
			async (e: unknown, added: Comment & { tooBigForPusher: boolean }) => {
				if (added.tooBigForPusher) {
					let comment;
					try {
						comment = (await CommentResource.get(added.id)).data;
					} catch (e) {
						logError(e, 'Failed to get comment for project plan');
					}

					if (projectPlan!.id === comment.projectPlan?.id) {
						addCommentToTimeline(comment);
						return;
					}

					addCommentToTimeline(added);
				}
			}
		);

		return () => {
			commentAddedListener();
		};
	}, [events?.length]);

	const renderedEvents = useMemo(() => {
		if (!events) {
			return [];
		}
		return events
			.sort((a, b) => (b.date as Date).getTime() - (a.date as Date).getTime())
			.map(event => {
				if (event.comment) {
					const user = event.users[0];
					return (
						<Block
							key={event.comment.id}
							space="ptxl pbxl pll prl"
							backgroundColor={undefined}
							className={classes.elem('comment').b()}
						>
							<Row>
								<Column fixedWidth={28}>
									<UserStack users={[user]} />
								</Column>
								<Column>
									<div className={classes.elem('comment').elem('header').b()}>
										<Text bold>{user.name}</Text>
										<Text space="mls" size="sm">
											{dateCalendar(event.date as Date, true)}
										</Text>
									</div>
									<MentionsInput
										value={event.comment.description}
										onChange={() => {}}
										editorProps={{ readOnly: true }}
									/>
								</Column>
							</Row>
						</Block>
					);
				}

				return null;
			});
	}, [events?.map(e => e.id)?.join(','), events?.length]);

	return (
		<Block className={classes.b()} space="mrl pll ptxl prxl pbl">
			<Block space="mtm mbs">
				<MentionsInput
					onChange={setComment}
					value={comment}
					editorRef={r => {
						textareaRef.current = r;
					}}
					editorProps={{
						placeholder: t('projectPlan.typeYourCommentHere', {
							clientName: accountSelf?.client?.name
						})
					}}
					className={classes.elem('mention-input').b()}
				/>
			</Block>

			<Block space="mtl mbxl">
				<PrimaryButton disabled={savingComment || !comment?.trim()?.length} onClick={saveComment}>
					{t('projectPlan.writeComment')}
				</PrimaryButton>
			</Block>

			<Title space="mbm">{t('ticket.conversation')}</Title>

			{renderedEvents.length > 0 ? (
				<Block space="pbxl">
					{renderedEvents}
					{hasMoreEvents ? (
						<Block space="ptxl">
							<Row align="center">
								<Link
									onClick={() => {
										getMoreEvents();
									}}
								>
									{t('default.getMore')}
								</Link>
							</Row>
						</Block>
					) : null}
				</Block>
			) : (
				<Block space="ptxl">
					<Row align="center">
						<Text italic color="grey-11">
							{t('ticket.noComments')}
						</Text>
					</Row>
				</Block>
			)}
		</Block>
	);
};

export default ProjectConversation;
