import { useTranslation } from 'Components/Helpers/translate';
import React, { useState } from 'react';
import { ButtonBox } from '@upsales/components';
import { useGetData } from '../hooks';
import { fetchInactivatedContacts, fetchOngoingInactivationJob, formatFetchedAmount } from './Helpers/fetchHelpers';
import openModal from 'App/services/Modal';

const InactivateContactsBox = () => {
	const { t } = useTranslation();
	const [startedInactivation, setStartedInactivation] = useState(false);
	const { metadata: inactivatedContactMetadata } = useGetData({
		fetcher: fetchInactivatedContacts,
		limit: 0,
		fetcherProps: {}
	});
	const inactivatedContactCount = inactivatedContactMetadata.total;

	const { metadata: jobMetadata, loading: jobLoading } = useGetData({
		fetcher: fetchOngoingInactivationJob,
		fetcherProps: {}
	});
	const alreadyRunningJob = !!jobMetadata.total;

	return (
		<ButtonBox
			icon={'user-times'}
			selectable={false}
			title={t('cleanMyData.inactivateContacts.title', {
				inactivateContactsAmount:
					alreadyRunningJob || startedInactivation ? 0 : formatFetchedAmount(inactivatedContactCount)
			})}
			subtitle={t('cleanMyData.inactivateContacts.subtitle')}
			onClick={() => {
				openModal('InactivateContacts', {
					total: inactivatedContactCount,
					onSave: () => setStartedInactivation(true)
				});
			}}
			disabled={!inactivatedContactCount || jobLoading || alreadyRunningJob || startedInactivation}
		/>
	);
};

export default InactivateContactsBox;
