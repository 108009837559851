import React, { ReactNode } from 'react';
import * as yup from 'yup';
import ValidationModel, { FieldType, FormObserverModel } from './ValidationModel';
import NotificationService from 'App/babel/NotificationService';
import T from 'Components/Helpers/translate';
import _ from 'lodash';
import CustomField, { EntityCustomField } from 'App/resources/Model/CustomField';
import Client from 'App/resources/Model/Client';
import Order from 'App/resources/Model/Order';

export type FormObserverOnFieldChange = (name: string, value: any, cb?: () => void) => void;

export const FieldModel = {
	string: (name: string) => new ValidationModel<string>(FieldType.STRING, name),
	number: (name: string) => new ValidationModel<number>(FieldType.NUMBER, name),
	url: (name: string) => new ValidationModel<string>(FieldType.URL, name),
	email: (name: string) => new ValidationModel<string>(FieldType.EMAIL, name),
	listOf: (name: string, of: ValidationModel) => new ValidationModel(FieldType.LIST, name, { of }),
	object: <TSchema extends Record<string, ValidationModel>>(name: string, schema: TSchema) =>
		new ValidationModel<TSchema>(FieldType.OBJECT, name, { schema, objectNullable: true }),
	/*
	 * keyedObject is just a shorthand of doing object('name',{ key: anymodel }).
	 * the type of the key defaults to number. Useful for all relation objects where the key "id" is a number
	 * FieldModel.keyedObject('relation', 'id') = { id: number }
	 */
	keyedObject: (name: string, key: string, keyType?: ValidationModel) =>
		new ValidationModel(FieldType.OBJECT, name, {
			schema: {
				[key]: keyType || FieldModel.number('')
			},
			objectNullable: true
		}),
	date: (name: string) => new ValidationModel<Date>(FieldType.DATE, name),
	boolean: (name: string) => new ValidationModel<boolean>(FieldType.BOOLEAN, name)
};

type FormErrors = {
	[key: string]: boolean;
};

enum FormErrorType {
	custom = 'custom',
	required = 'required',
	min = 'min',
	max = 'max'
}

export type FormErrorTypes = {
	[key: string]: null | FormErrorType;
};

export type FormErrorMessages = {
	[key: string]: null | string;
};

export type InputProps<TValue = string> = {
	required: boolean;
	disabled?: boolean;
	maxLength?: number;
	value: TValue;
	state: undefined | 'error';
	onChange: (
		e:
			| React.ChangeEvent<HTMLInputElement>
			| React.ChangeEvent<HTMLTextAreaElement>
			| { target: { value: string | Date } }
	) => void;
	name: string;
	label: string;
	min?: number;
	max?: number;
	type?: HTMLInputElement['type'];
};

export type InputPropMap<TModel> = {
	[K in keyof TModel]: InputProps<TModel[K]>;
};

type FormObserverProps<T> = {
	children: (props: {
		onFormChange: FormObserverOnFieldChange;
		errors: FormErrors;
		errorTypes: FormErrorTypes;
		errorMessages: FormErrorMessages;
		setPristine: () => void;
		touched: {
			[key: string]: boolean;
		};
		dirty: boolean;
		isValid: boolean;
		values: T;
		submit: (...submitArgs: any[]) => void;
		inputProps: InputPropMap<T>;
	}) => ReactNode;
	onSubmit?: (values: T, initialValues: T, ...submitArgs: any[]) => void;
	model: FormObserverModel;
	initialValues: T;
	onlyValidateTouched?: boolean;
	validateOnMount?: boolean;
	notifyError?: boolean;
	scrollToError?: boolean;
	resetStateIfValueChange?: number | string | boolean;
	resetStateIfUndefined?: number | string;
	skipInitialOnChange?: boolean; // Skips the onChange triggered by the constructor
	onChange?: (values: T, isValid: boolean, errorMessages: FormErrorMessages) => void;
	onError?: (provided: { firstError: string; errors: FormErrors }) => void;
	useDebounce?: boolean;
	initialIsValid?: boolean;
};

export type MappedCustomFields = { [key: string]: string | null };

type FormObserverState<T> = {
	touched: {
		[key: string]: boolean;
	};
	values: T;
	initialValues: T;
	errors: FormErrors;
	errorTypes: FormErrorTypes;
	errorMessages: FormErrorMessages;
	dirty: boolean;
	isValid: boolean;
	model: yup.AnyObjectSchema;
	hasRunValidation: boolean;
};

// Build hook for use in functionalcomponents later
// export const useFormObserver = () => {};

/**
 * These are general functions used if you want to have custom fields in your form observer
 */
export const getCustomFieldModel = (
	customFieldsValues: CustomField[],
	stageId?: number,
	type?: string,
	allFieldsOptional?: boolean
) => {
	return FieldModel.object(
		'default.custom',
		customFieldsValues.reduce<{ [key: string]: ValidationModel }>((res, field) => {
			const fieldWithStage = field as unknown as CustomField & {
				stages?: (Order['stage'] & { required: boolean })[];
			};
			const requiredInStage = !!(
				stageId && fieldWithStage.stages?.find(stage => stage.id === stageId && stage.required)
			);
			const required = allFieldsOptional
				? false
				: (!!field.obligatoryField || requiredInStage) && !!field.editable;

			let fieldModel;
			switch (field.datatype) {
				case 'Boolean':
					fieldModel = FieldModel.boolean(field.name);
					break;
				case 'Date':
					fieldModel = FieldModel.date(field.name);
					break;
				case 'Integer':
				case 'Percent':
				case 'Discount':
					fieldModel = FieldModel.number(field.name);
					field.maxLength = Number.MAX_SAFE_INTEGER;
					break;
				default:
					fieldModel = FieldModel.string(field.name);
			}

			res[`Custom_${field.id}`] = fieldModel.required(required, type).max(field.maxLength ?? 255);
			return res;
		}, {})
	);
};

export const mapCustomValuesToObject = (custom: Client['custom'], customFields: CustomField[], isNew = false) =>
	custom.reduce<MappedCustomFields>(
		(res, f) => {
			res[`Custom_${f.fieldId}`] = f.value;
			return res;
		},
		customFields.reduce<MappedCustomFields>((res, f) => {
			if (isNew && f.datatype === 'Select' && f.dropdownDefault) {
				res[`Custom_${f.id}`] = Array.isArray(f.dropdownDefault)
					? f.dropdownDefault[0] || null
					: f.dropdownDefault;
			} else {
				res[`Custom_${f.id}`] = null;
			}
			return res;
		}, {})
	);

export const mapCustomValuesToArray = (custom: any) => {
	const mappedCustom = Object.keys(custom).reduce<EntityCustomField[]>((res, key) => {
		res.push({ value: custom[key] || null, fieldId: parseInt(key.replace('Custom_', '')) });
		return res;
	}, []);
	return mappedCustom;
};

export const getCustomFieldsSetupValues = (
	customOrderFieldValues: CustomField[],
	customValues?: EntityCustomField[],
	stageId?: number,
	type?: string,
	allFieldsOptional?: boolean
) => {
	const validatonModel: { [field: string]: ValidationModel } = {
		custom: getCustomFieldModel(customOrderFieldValues, stageId, type, allFieldsOptional)
	};
	const initialValues = {
		custom: mapCustomValuesToObject(customValues ?? [], customOrderFieldValues)
	};

	return { validatonModel, initialValues };
};

function generateKeyObject<V = undefined>(object: { [key: string]: null }, value: V | ((key: string) => V)) {
	return Object.keys(object).reduce<{ [name: string]: typeof value }>((res, key) => {
		res[key] = value instanceof Function ? value(key) : value;
		return res;
	}, {}) as {
		[name: string]: V;
	};
}

function generateYupModel(modelConfig: FormObserverModel) {
	const shape = Object.keys(modelConfig).reduce<{ [k: string]: yup.AnySchema }>((res, key) => {
		const model = modelConfig[key];
		const fieldModel = model.getYupSchema();

		if (fieldModel) {
			res[key] = fieldModel;
		}
		return res;
	}, {});
	return yup.object(shape);
}

function generateFlatKeys(modelConfig: FormObserverModel) {
	return Object.keys(modelConfig).reduce<{ [k: string]: null }>((res, key) => {
		const model = modelConfig[key];
		res[key] = null;
		model.reduceFlatSubObject(key, res);
		return res;
	}, {});
}

const getNotificationParams = (errorType: FormErrorType, fieldModel: ValidationModel, customError: string) => {
	return {
		required: {
			title: T('validation.missingRequiredFieldsTitle'),
			body: fieldModel.requiredError
		},
		min: { title: T('default.error'), body: fieldModel.minError },
		max: { title: T('default.error'), body: fieldModel.maxError },
		custom: { title: T('default.error'), body: customError }
	}[errorType];
};

const generateFieldErrorMsg = (errorType: FormErrorType, fieldModel: ValidationModel, customError: string) => {
	return (
		{
			required: fieldModel.requiredError,
			min: fieldModel.minError,
			max: fieldModel.maxError,
			custom: customError
		}[errorType] ?? customError // errorType can be other things, for example "email"
	);
};

function getSubModel(schema: FormObserverModel, key: string): ValidationModel | null {
	// match on '.' or '[..].'
	// key[0].sub will split into [key, sub]
	// key.sub.something.else will be split into [key, sub, [something, else]]
	const regEx = /\[[^]\]\.|\./gm;

	const [base, sub, ...rest] = key.split(regEx);

	if (base && sub) {
		if (schema?.[base].model.type === 'LIST' && !_.isEmpty(schema?.[base].opts.of?.schema)) {
			return getSubModel(schema[base].opts.of?.schema!, [sub, ...rest].join('.'));
		} else if (!_.isEmpty(schema?.[base]?.schema)) {
			return getSubModel(schema[base].schema!, [sub, ...rest].join('.'));
		}
	}
	if (schema[key]) {
		return schema[key];
	}
	return null;
}

class FormObserver<T extends { [key: string]: any }> extends React.Component<
	FormObserverProps<T>,
	FormObserverState<T>
> {
	flatKeys: { [key: string]: null } = {};
	constructor(p: FormObserverProps<T>) {
		super(p);
		this.resetState(p, true);
	}

	resetState(p: FormObserverProps<T>, firstTime: boolean) {
		const model = generateYupModel(p.model);
		this.flatKeys = generateFlatKeys(p.model);
		const errorsState: FormErrors = {};
		const errorTypesState: FormErrorTypes = {};
		const errorMessagesState: FormErrorMessages = {};
		const touched = generateKeyObject(this.flatKeys, key => {
			errorsState[key] = false;
			errorTypesState[key] = null;
			errorMessagesState[key] = null;
			return false;
		});

		const values = model.cast({ ...p.initialValues }) as yup.TypeOf<typeof model> as T;

		const state = {
			touched,
			errors: errorsState,
			errorTypes: errorTypesState,
			errorMessages: errorMessagesState,
			model,
			values,
			dirty: false,
			isValid: p.initialIsValid ?? true,
			initialValues: { ...values },
			hasRunValidation: false
		};

		const [errors, errorTypes, isValid, errorMessages] = this.checkForm(state.values, state.model);
		if (p.validateOnMount) {
			state.errors = errors;
			state.isValid = isValid;
			state.errorTypes = errorTypes;
			state.errorMessages = errorMessages;
			state.hasRunValidation = true;
		}

		if (!(p.skipInitialOnChange && firstTime)) {
			this.props.onChange?.(state.values, isValid, errorMessages);
		}

		if (firstTime) {
			this.state = state;
		} else {
			this.setState(state);
		}
	}

	componentDidUpdate(prevProps: Readonly<FormObserverProps<T>>): void {
		if (
			prevProps.resetStateIfValueChange !== this.props.resetStateIfValueChange ||
			(prevProps.resetStateIfUndefined !== undefined && this.props.resetStateIfUndefined === undefined)
		) {
			this.resetState(this.props, false);
		}
	}

	getInputPropsForKey(key: string, model: ValidationModel): InputProps<T> {
		return {
			state: this.state.errors[key] ? 'error' : undefined,
			required: model.model.required,
			disabled: model.model.disabled,
			maxLength: model.model.max,
			value: _.get(this.state.values, key),
			onChange: e => this.onFormChange(key, e.target.value),
			name: key,
			label: model.name,
			min: model.model.type === FieldType.NUMBER ? model.model.min : undefined,
			max: model.model.type === FieldType.NUMBER ? model.model.max : undefined,
			type: model.model.type === FieldType.NUMBER ? 'number' : undefined
		};
	}

	generateInputProps() {
		const inputProps: { [k: string]: InputProps<any> } = {};
		generateKeyObject(this.flatKeys, key => {
			const [base, sub] = key.split('.');
			if (sub && this.props.model[base].schema?.[sub]) {
				inputProps[key] = this.getInputPropsForKey(key, this.props.model[base].schema?.[sub]!);
			} else if (this.props.model[key]) {
				inputProps[key] = this.getInputPropsForKey(key, this.props.model[key]);
			}
		});
		return inputProps as InputPropMap<T>;
	}

	debouncedOnChange = _.debounce((values: T, isValid: boolean, errorMessages: FormErrorMessages) => {
		this.props.onChange?.(values, isValid, errorMessages);
	}, 500);

	onFormChange: FormObserverOnFieldChange = (fieldName, value, cb) => {
		if (value === _.get(this.state.values, fieldName)) {
			return;
		}
		let castedValue = value;
		// Do not cast if negative value but not yet added numbers
		if (value !== '-') {
			// Cast value here to ensure transformers run
			castedValue = this.state.model.cast({ [fieldName]: value })[fieldName];
		}

		const touched = { ...this.state.touched, [fieldName]: true };
		const values = _.set<T>({ ...this.state.values }, fieldName, castedValue);
		const [errors, errorTypes, isValid, errorMessages] = this.checkForm(values);
		if (this.props.onlyValidateTouched) {
			for (const key of Object.keys(touched)) {
				if (!touched[key]) {
					errors[key] = false;
				}
			}
		}

		// do real compare instead
		this.setState(
			{
				errors,
				errorTypes,
				errorMessages,
				isValid,
				dirty: true,
				values,
				touched
			},
			cb
		);

		if (this.props.useDebounce) {
			this.debouncedOnChange(values, isValid, errorMessages);
		} else {
			this.props.onChange?.(values, isValid, errorMessages);
		}
	};

	submit = (...submitArgs: any[]) => {
		// If never validated, do it and run submit again after validation
		if (!this.state.hasRunValidation) {
			const [errors, errorTypes, isValid, errorMessages] = this.checkForm(this.state.values, this.state.model);
			this.setState(
				{
					errors: errors,
					isValid: isValid,
					errorTypes: errorTypes,
					errorMessages: errorMessages,
					hasRunValidation: true
				},
				() => this.submit(...submitArgs)
			);
			return;
		}
		if (this.state.isValid) {
			this.props.onSubmit?.(this.state.values, this.state.initialValues, ...submitArgs);

			// notifyError defaults to true
		} else if (this.props.notifyError ?? true) {
			// Show notification with error
			// Base error message on first error even if there are multiple
			const firstError = Object.keys(this.state.errors).find(key => this.state.errors[key]) as string;
			const model = getSubModel(this.props.model, firstError);

			if (model) {
				const { title, body } = getNotificationParams(
					this.state.errorTypes[firstError] as FormErrorType,
					model,
					this.state.errorMessages[firstError] as string
				);
				NotificationService.add({
					autoHide: true,
					style: NotificationService.style.WARN,
					title,
					body,
					icon: 'warning'
				});

				this.props.onError?.({ firstError, errors: this.state.errors });

				// also try to scroll first errored element into view and focus it
				// Wrap in set timeout to get the view a chance to render stuff based on "onError"
				if (this.props.scrollToError ?? true) {
					setTimeout(() => {
						// Can we pass a ref instead?
						const field = document.getElementsByName(firstError);
						if (field?.[0]) {
							// If field is or contains an input, scroll to it
							if (typeof field[0]?.focus === 'function') {
								field[0].focus({ preventScroll: true });
							}
							field[0].scrollIntoView({ behavior: 'smooth', block: 'center' });
						}
					}, 0);
				}
			}
		}
	};

	onSubmit = (e: React.FormEvent<HTMLFormElement>) => {
		e.preventDefault();
		this.submit();
	};

	checkForm(
		values = this.state.values,
		model = this.state.model
	): [FormErrors, FormErrorTypes, boolean, FormErrorMessages] {
		let isValid = true;
		const errorTypes: FormErrorTypes = {};
		const errorMessages: FormErrorMessages = {};
		const errors: FormErrors = generateKeyObject(this.flatKeys, key => {
			errorTypes[key] = null;
			errorMessages[key] = null;
			return false;
		});
		try {
			model.validateSync(values, { abortEarly: false });
		} catch (e) {
			isValid = false;
			if (e instanceof yup.ValidationError) {
				e.inner.forEach(inner => {
					if (inner.path) {
						errors[inner.path] = true;
						errorTypes[inner.path] = (inner.type as FormErrorType) ?? null;
						const model = getSubModel(this.props.model, inner.path);
						errorMessages[inner.path] = model
							? generateFieldErrorMsg(inner.type as FormErrorType, model, inner.message)
							: T('default.error');
					}
				});
			}
		}

		return [errors, errorTypes, isValid, errorMessages];
	}

	// Reset the form to a unvalidated state (no errors will show until next change/submit happens)
	setPristine = () => {
		const falseObj = generateKeyObject(this.flatKeys, key => false);
		const nullObj = generateKeyObject(this.flatKeys, key => null);
		this.setState({
			errors: falseObj,
			errorTypes: nullObj,
			errorMessages: nullObj,
			isValid: true,
			dirty: false,
			touched: falseObj,
			hasRunValidation: false
		});
	};

	render() {
		return (
			<form noValidate onSubmit={this.onSubmit}>
				{this.props.children({
					onFormChange: this.onFormChange,
					submit: this.submit,
					errors: this.state.errors,
					errorTypes: this.state.errorTypes,
					errorMessages: this.state.errorMessages,
					setPristine: this.setPristine,
					touched: this.state.touched,
					dirty: this.state.dirty,
					isValid: this.state.isValid,
					values: this.state.values,
					inputProps: this.generateInputProps()
				})}
			</form>
		);
	}
}

export default FormObserver;
