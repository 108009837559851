import React from 'react';
import PropTypes from 'prop-types';
import GroupTree from './GroupTree';
import FindProspectsBranchSubtitle from 'App/babel/domain/crm/client/findProspects/FindProspectsBranchSubtitle/FindProspectsBranchSubtitle';
import { Button } from '@upsales/components';

export default class ShowGroup extends React.Component {
	constructor(props) {
		super(props);

		const t = Tools.$translate;
		this.lang = {
			createNewCompany: t('account.createNewCompany'),
			companyGroupFor: t('account.companyGroupFor'),
			companyGroupContains: t('account.companyGroupContains'),
			companies: t('default.accounts'),
			with: t('default.with'),
			branches: t('account.branches').toLowerCase(),
			branch: t('account.branch').toLowerCase(),
			goToAccount: t('default.goToAccount')
		};
	}

	scrollToSelector(selector) {
		const node = document.querySelector('#create-new-account-modal ' + selector);
		node.scrollIntoView({ behavior: 'smooth', block: 'start' });
	}

	render() {
		const { companyGroup, actions, addingAccount } = this.props;

		return (
			<div className="show-group">
				<div className="box-shadow">
					<div className="header-content full-screen-modal-content">
						<h1 className="full-screen-small-header no-margin-left ">
							<Button
								className="breadcrumb-link"
								onClick={actions.clearGroup}
								type="link"
								color="bright-blue"
							>
								{this.lang.createNewCompany}
							</Button>
							<span>{' / ' + this.lang.companyGroupFor}</span>
						</h1>
						{ReactTemplates.TOOLS.withTooltip(
							<input
								className="huge-input no-border-bottom clickable"
								type="text"
								value={companyGroup.groupAccount.name}
								disabled
								onClick={this.scrollToSelector.bind(this, '.selected-account')}
							/>,
							this.lang.goToAccount,
							{ placement: 'bottom' }
						)}
						<FindProspectsBranchSubtitle
							data={companyGroup.data}
							rootDuns={companyGroup.rootDuns}
							unknownTotal={companyGroup.unknownTotal}
							total={companyGroup.total}
							onClick={container => {
								if (container === 'found') {
									this.scrollToSelector('.found-tree');
								} else {
									this.scrollToSelector('.unknown-tree');
								}
							}}
						/>
					</div>
				</div>
				<div className="search-results">
					<div className="full-screen-modal-content">
						<GroupTree
							accounts={companyGroup.data}
							rootDuns={companyGroup.rootDuns}
							actions={actions}
							groupAccount={companyGroup.groupAccount}
							addingAccount={addingAccount}
							dataSourceId={companyGroup.dataSourceId}
							idField={companyGroup.idField}
							config={companyGroup.config}
							tree={companyGroup.tree}
							unknowns={companyGroup.unknowns}
						/>
					</div>
				</div>
			</div>
		);
	}
}

ShowGroup.propTypes = {
	companyGroup: PropTypes.object,
	actions: PropTypes.object,
	addingAccount: PropTypes.object
};
