import React, { ComponentProps } from 'react';
import { Button, Icon, IconName, Text } from '@upsales/components';
import BemClass from '@upsales/components/Utils/bemClass';
import './InputRevealButton.scss';

type Props = {
	icon: IconName;
	text: string;
	className?: string;
	required?: boolean;
};

const InputRevealButton = ({
	icon,
	text,
	className,
	required = false,
	...props
}: Props & ComponentProps<typeof Button>) => {
	const classes = new BemClass('InputRevealButton', className);
	return (
		<Button block={true} {...props} type="link" className={classes.b()}>
			{icon ? <Icon name={icon} space="mrs" className={classes.elem('icon').b()} /> : null}
			{text}
			{required ? (
				<Text size="sm" color="red" className={classes.elem('required-ball').b()}>
					{'*'}
				</Text>
			) : null}
		</Button>
	);
};

export default InputRevealButton;
