import React from 'react';
import { Text, Row, Column, Tooltip, Icon } from '@upsales/components';
import bemClass from '@upsales/components/Utils/bemClass';
import { RCWidgetData } from 'Resources/ReportWidget';
import formatWidgetValue from '../formatWidgetValue';
import { loadDrilldown } from '../chartHelper';
import './FunnelColumnChartWidget.scss';
import ColumnChartWidgetScale from '../ColumnChartWidget/ColumnChartWidgetScale';
import { GenericWidgetProps } from '../ReportcenterWidget';

const Arrow = ({ classes, value }: { classes: bemClass; value: number }) => (
	<div className={classes.elem('arrow').b()}>
		<div>
			<svg width="81" height="42" viewBox="0 0 81 42" fill="none" xmlns="http://www.w3.org/2000/svg">
				<g filter="url(#filter1_d)">
					<path
						d="M8.0314 36C4.72945 36 2.8495 32.2253 4.83892 29.59L12.8333 19L4.83892 8.41001C2.84949 5.77466 4.72944 2 8.0314 2L62.1745 2C63.4291 2 64.611 2.58866 65.367 3.59L77 19L65.367 34.41C64.611 35.4113 63.4291 36 62.1745 36L8.0314 36Z"
						fill="#1D3D48"
					/>
				</g>
				<defs>
					<filter
						id="filter1_d"
						x="0.0241699"
						y="0"
						width="80.9759"
						height="42"
						filterUnits="userSpaceOnUse"
						colorInterpolationFilters="sRGB"
					>
						<feFlood floodOpacity="0" result="BackgroundImageFix" />
						<feColorMatrix
							in="SourceAlpha"
							type="matrix"
							values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
						/>
						<feOffset dy="2" />
						<feGaussianBlur stdDeviation="2" />
						<feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.2 0" />
						<feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow" />
						<feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow" result="shape" />
					</filter>
				</defs>
			</svg>
		</div>
		<Text size="sm" color="super-light-green" align="center">
			{formatWidgetValue(value, 'percent')}
		</Text>
	</div>
);

const getMaxValue = (data: RCWidgetData) => {
	if (data?.total?.special) {
		const { stage1, stage2, stage3, stage4 } = data.total.special;
		const maxValue = [stage1, stage2, stage3, stage4].reduce((res, cur) => (res = Math.max(res, cur)), 0);
		return maxValue;
	} else {
		return 0;
	}
};
const Bar = ({
	classes,
	value,
	maxValue,
	onClick
}: {
	classes: bemClass;
	value: number;
	maxValue: number;
	onClick: () => void;
}) => (
	<div
		className={classes.elem('bar').b()}
		style={{ height: `${(value / maxValue) * 100}%` }}
		onClick={() => onClick()}
	>
		<Text
			bold
			style={{
				marginTop: value / maxValue < 0.2 ? '-20px' : '20px'
			}}
		>
			{formatWidgetValue(value, 'number')}
		</Text>
	</div>
);
type Props = GenericWidgetProps;

class FunnelColumnChartWidget extends React.Component<Props> {
	private _main: React.RefObject<HTMLDivElement>;

	constructor(props: Props) {
		super(props);
		this._main = React.createRef();
	}

	state = {
		scrolled: false
	};

	openDrilldown = (type: string, drilldown: boolean) => {
		if (drilldown) {
			loadDrilldown([type], this.props.config);
		}
	};

	render() {
		const { loading = true, className, data, drilldown } = this.props;
		const maxValue = getMaxValue(data);
		const classes = new bemClass('FunnelColumnChartWidget', className).mod({
			loading,
			'has-data': !!data.total?.progress
		});
		if (!loading && data?.total?.special) {
			const { language } = data;
			const {
				avgValue,
				stage1,
				stage2,
				stage3,
				percentCompleted,
				percentCreated,
				percentWon,
				totalValue,
				stage4,
				percentHitRate
			} = data?.total?.special;
			return (
				<div className={classes.b()} ref={this._main}>
					<Row>
						<Column className={classes.elem('bars').b()}>
							<ColumnChartWidgetScale />
							<Row>
								<Column className={classes.elem('bar-wrapper').b()}>
									<Bar
										classes={classes}
										value={stage1}
										maxValue={maxValue}
										onClick={() => this.openDrilldown('stage1', drilldown)}
									/>
									<Arrow classes={classes} value={percentCompleted} />
								</Column>
								<Column className={classes.elem('bar-wrapper').b()}>
									<Bar
										classes={classes}
										value={stage2}
										maxValue={maxValue}
										onClick={() => this.openDrilldown('stage2', drilldown)}
									/>
									<Arrow classes={classes} value={percentCreated} />
								</Column>
								<Column className={classes.elem('bar-wrapper').b()}>
									<Bar
										classes={classes}
										value={stage3}
										maxValue={maxValue}
										onClick={() => this.openDrilldown('stage3', drilldown)}
									/>
									<Arrow classes={classes} value={percentWon} />
								</Column>
								<Column className={classes.elem('bar-wrapper').b()}>
									<Bar
										classes={classes}
										value={stage4}
										maxValue={maxValue}
										onClick={() => this.openDrilldown('stage4', drilldown)}
									/>
								</Column>
							</Row>
						</Column>
						<Column className={classes.elem('totals').b()}>
							<Row direction="column">
								<Text color={'grey-10'}>{language.avgValue}</Text>
								<Text bold>{formatWidgetValue(avgValue, data.datatype.progress, data.currency)}</Text>
							</Row>
							<Row direction="column">
								<Text color={'grey-10'}>
									{language.totalValue}
									<Tooltip title={data.language?.tooltip ?? ''}>
										<Icon name="info-circle" />
									</Tooltip>
								</Text>
								<Text bold>{formatWidgetValue(totalValue, data.datatype.progress, data.currency)}</Text>
							</Row>
							<Row direction="column">
								<Text color={'grey-10'}>{language.hitRate}</Text>
								<Text bold>{formatWidgetValue(percentHitRate, 'percent')}</Text>
							</Row>
						</Column>
					</Row>
					<Row className={classes.elem('labels').b()}>
						<Column>
							<Text size="sm" bold align="center">
								{language.stage1}
							</Text>
						</Column>
						<Column>
							<Text size="sm" bold align="center">
								{language.stage2}
							</Text>
						</Column>
						<Column>
							<Text size="sm" bold align="center">
								{language.stage3}
							</Text>
						</Column>
						<Column>
							<Text size="sm" bold align="center">
								{language.stage4}
							</Text>
						</Column>
						<Column size={3} />
					</Row>
				</div>
			);
		} else {
			return null;
		}
	}
}

export default FunnelColumnChartWidget;
