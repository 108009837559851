import Resource from 'Resources/Resource';
import { BuildFilters } from 'Resources/RequestBuilder';

export enum ReportEntity {
	CLIENT = 'client',
	CONTACT = 'contact',
	PAGE = 'page',
	APPOINTMENT = 'appointment',
	ACTIVITY = 'activity',
	ORDER = 'order',
	AGREEMENT = 'agreement',
	PROJECT = 'project',
	USERDEFINEDOBJECT = 'userdefinedobject',
	USERDEFINEDOBJECT2 = 'userdefinedobject2',
	USERDEFINEDOBJECT3 = 'userdefinedobject3',
	USERDEFINEDOBJECT4 = 'userdefinedobject4',
	VISIT = 'visit',
	PRODUCT = 'product'
}

class Report extends Resource {
	constructor() {
		super('report/api');
	}

	find<T>(entity: ReportEntity, filters?: BuildFilters) {
		return super._getRequest(entity, { methodName: 'find', params: filters }).then(r => r.data as { data: T });
	}
}

const resource = new Report();

export default resource;
