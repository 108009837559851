import _axios from 'axios';
import axiosRetry from 'axios-retry';
import config from 'App/babel/config';
import findAll from 'App/babel/helpers/findAll';
import genericMapper, { mapDates, mapDatesNew } from 'App/resources/genericMapper';
import genericParser, { parseDates } from 'App/resources/genericParser';
import { TYPE, STYLE } from 'Store/reducers/SystemNotificationReducer';
import getAngularModule from '../angularHelpers/getAngularModule';
// import NotificationService from 'App/babel/NotificationService'; // This will break all with circular dependencies
import * as Sentry from '@sentry/browser';
import isIntegrationUrl from './helpers/isIntegrationUrl';
import { globalTracker } from 'Helpers/Tracker';
import { v4 as uuidv4 } from 'uuid';
import logError from 'Helpers/logError';

const axios = _axios.create();

axiosRetry(axios, {
	retries: 3,
	retryDelay: (retryNumber = 0) => {
		const seconds = Math.pow(2, retryNumber) * 1000;
		const randomMs = 1000 * Math.random();
		return seconds + randomMs;
	},
	// retry on Network Error & 5xx responses on GET, OPTIONS and HEAD
	retryCondition: axiosRetry.isSafeRequestError
});

const event = {
	DELETED: 'deleted',
	UPDATED: 'updated',
	ADDED: 'added'
};

const analyticsEvents = {
	[event.UPDATED]: 'Updated',
	[event.ADDED]: 'Created'
};

const handleData = (parser, res) => {
	return { ...res.data, data: res.data ? parser(res.data.data) : null };
};

const handleDataArray = (parser, res) => {
	return { ...res.data, data: res.data.data.map(parser) };
};

const getDefaultNotifications = opts => {
	if (!opts.notificationTitle) {
		return {};
	}

	return {
		// eslint-disable-next-line no-unused-vars
		save: _data => {
			return {
				title: opts.notificationTitle,
				body: 'default.wasSaved',
				style: STYLE.SUCCESS,
				icon: 'save',
				type: TYPE.BODY
			};
		},
		// eslint-disable-next-line no-unused-vars
		saveError: (data, _reqOpts) => {
			const translated = data?.error?.translated;
			return {
				title: translated ? 'default.unableToSave' : opts.notificationTitle,
				body: translated || 'default.unableToSave',
				style: STYLE.ERROR,
				icon: 'save',
				type: TYPE.BODY
			};
		},
		delete: () => {
			return {
				title: opts.notificationTitle,
				body: 'default.wasDeleted',
				style: STYLE.SUCCESS,
				icon: 'trash',
				type: TYPE.BODY
			};
		},
		deleteError: data => {
			const translated = data?.error?.translated;
			return {
				title: translated ? 'default.unableToDelete' : opts.notificationTitle,
				body: translated || 'default.unableToDelete',
				style: STYLE.ERROR,
				icon: 'trash',
				type: TYPE.BODY
			};
		}
	};
};

export default class Resource {
	constructor(url, attributes, opts = {}) {
		if (!url) {
			throw new Error('Resource missing url');
		}
		this.notifications = getDefaultNotifications(opts);
		this.dateFields = [];
		this._url = url;
		this._attributes = attributes;
		this.attr = attributes;

		// Default req options
		this.REQUEST_OPTIONS = {
			method: 'GET',
			headers: {
				'Content-Type': 'application/json'
			}
		};

		this.REQUEST_OPTIONS.withCredentials = true;
	}

	_map(data, opts = {}) {
		if (this._attributes && data) {
			data = genericMapper(data, this._attributes);
		}
		data = this.map(data);
		data = opts.useNewMapDates ? mapDatesNew(data) : mapDates(data);
		return data;
	}

	_parse(data) {
		data = parseDates(data, this.dateFields);
		if (this._attributes && data) {
			data = genericParser(data, this._attributes);
		}
		data = this.parse(data);
		return data;
	}

	_triggerEvent(opts, type, res, changedAttributeKeys) {
		if (!this.eventName || opts.skipEvent) {
			return res;
		}

		const entity = typeof this.eventName === 'function' ? this.eventName(opts, type, res) : this.eventName;

		const eventName = `${entity}.${type}`;

		if (res.data) {
			if (config.ENV === 'DEV' && console && console.log) {
				console.log('broadcasting:', eventName);
			}

			Tools.$rootScope.$broadcast(eventName, { ...res.data, tempId: opts.tempId }, changedAttributeKeys);

			if (analyticsEvents[type]) {
				const analyticsProps = this.analyticsProps ? this.analyticsProps(opts, type, res) : {};
				globalTracker.track(`${analyticsEvents[type]} ${entity}`, analyticsProps);
			}
		}

		return res;
	}

	_handleNotification(isError, method, opts = {}, res) {
		const skip = isError ? opts.skipErrorNotification : opts.skipNotification;
		if (!skip && this.notifications && this.notifications[method]) {
			try {
				const config =
					typeof this.notifications[method] === 'function'
						? this.notifications[method](res?.data, opts)
						: this.notifications[method];

				if (!config) {
					return;
				}

				let customBody;
				if (res?.data?.error?.key === 'FailedStandardIntegrationValidation') {
					customBody = res?.data?.error?.msg;
				}
				if (res?.data?.error?.translated) {
					customBody = res?.data?.error?.translated;
				}
				const notification = {
					style: isError ? 'error' : 'success',
					icon: isError ? 'times' : 'check',
					...config,
					body: customBody || config.body
				};
				if (opts.notificationId && !isError) {
					Tools.NotificationService.updateNotification({
						...notification,
						id: opts.notificationId,
						addIfNotFound: true,
						autoHide: true
					});
				} else {
					Tools.NotificationService.addNotification(notification);
					if (opts.notificationId) {
						Tools.NotificationService.removeNotification(opts.notificationId);
					}
				}
			} catch (err) {
				// Dont let any faulty configured notifications fuck with the request
				console.log('Notification config is broken', err);
			}
		}
	}

	_handleSuccess(method, opts, res) {
		this._handleNotification(false, method, opts, res);

		return res;
	}

	_handleError(method, opts, error) {
		const got401 = error.response?.status === 401;
		if (got401 && !['loginTwoFA'].includes(method) && !isIntegrationUrl(this._url)) {
			getAngularModule('$cacheFactory').get('$http').removeAll();
			if (
				window.location.href &&
				window.location.href.indexOf('/login') === -1 &&
				window.location.href.toLowerCase().indexOf('/manageaccount') === -1
			) {
				document.cookie = `loginReturnUrl = ${window.location.href}`;
			}
			getAngularModule('$location').path('/login');

			// Remove user from sentry-scope so error-event is ignored
			Sentry.configureScope(scope => scope.setUser({}));

			// wait for the logout to be completed before rejecting promise to avoid unneccessary sentry events
			const timeout = opts.skipRejectTimeout ? 0 : 1500;
			return new Promise((resolve, reject) => setTimeout(() => reject(error), timeout));
		}

		this._handleNotification(true, method + 'Error', opts, error.response);
		console.error(`Error in resource ${this.constructor.name}.${method}()`, error);
		return Promise.reject(error);
	}

	_getUrl(path = '', opts = {}) {
		const base = opts._url || this._url;
		const urlArr = (config.URL + config.API + base).split('/');
		urlArr.push(path);
		let url = urlArr.join('/');
		const hasQuery = url.indexOf('?') !== -1;
		if (hasQuery && url.substr(url.length - 1) === '/') {
			url = url.substr(0, url.length - 1);
		}
		if (opts.urlParams) {
			for (const [key, value] of Object.entries(opts.urlParams)) {
				url = url.replace(`:${key}`, value);
			}
		}
		return url;
	}

	_getRequest(path, opts = {}) {
		opts.methodName = opts.methodName || '_getRequest';
		return axios
			.get(this._getUrl(path, opts), { ...this.REQUEST_OPTIONS, ...opts })
			.then(this._handleSuccess.bind(this, opts.methodName, opts))
			.catch(e => this._handleError(opts.methodName, opts, e));
	}

	_putRequest(path, data, opts = {}) {
		opts.methodName = opts.methodName || '_putRequest';
		return axios
			.put(this._getUrl(path, opts), data, { ...this.REQUEST_OPTIONS, method: 'PUT', ...opts })
			.then(this._handleSuccess.bind(this, opts.methodName, opts))
			.catch(e => this._handleError(opts.methodName, opts, e));
	}

	_postRequest(path, data, opts = {}) {
		opts.methodName = opts.methodName || '_postRequest';
		return axios
			.post(this._getUrl(path, opts), data, { ...this.REQUEST_OPTIONS, method: 'POST', ...opts })
			.then(this._handleSuccess.bind(this, opts.methodName, opts))
			.catch(e => this._handleError(opts.methodName, opts, e));
	}

	_deleteRequest(path, opts = {}) {
		opts.methodName = opts.methodName || '_deleteRequest';
		return axios
			.delete(this._getUrl(path, opts), { ...this.REQUEST_OPTIONS, method: 'DELETE', ...opts })
			.then(this._handleSuccess.bind(this, opts.methodName, opts))
			.catch(e => this._handleError(opts.methodName, opts, e));
	}

	map(data) {
		return data;
	}

	parse(data) {
		return data;
	}

	find(filters = {}, opts = {}) {
		if (filters === null || typeof filters !== 'object' || !Object.keys(filters).length) {
			filters = undefined;
		}

		return this._getRequest('', { ...opts, methodName: 'find', params: filters }).then(
			handleDataArray.bind(this, this._parse.bind(this))
		);
	}

	count(filters = {}) {
		return this.find({ ...filters, limit: 0 }).then(res => res.metadata.total);
	}

	get(id, opts = {}) {
		return this._getRequest(id, { ...opts, methodName: 'get', params: opts.params }).then(
			handleData.bind(this, this._parse.bind(this))
		);
	}

	save(data, opts = {}) {
		const changedAttributeKeys = Object.keys(data);
		const method = data.id ? '_putRequest' : '_postRequest';
		let mappedData = this._map(data, opts);

		if (opts?.dontWait) {
			opts.tempId = uuidv4();
			data.state = opts.state ?? {};
			data.state.tempId = opts.tempId;

			this._triggerEvent(opts, data.id ? event.UPDATED : event.ADDED, { data });
			let notification = this.notifications.save?.();
			if (notification) {
				notification = {
					...notification,
					autoHide: false,
					body: 'default.savingChanges'
				};
				opts.notificationId = Tools.NotificationService.addNotification(notification);
			}
		}

		if (opts.parseFiles) {
			const formData = new FormData();
			for (const key in mappedData) {
				if (opts.fileKeys?.includes(key)) {
					for (const attachment of mappedData[key]) {
						if (attachment.blob) {
							formData.append('file', attachment.blob);
						} else {
							formData.append(
								'attachments',
								JSON.stringify({ filename: attachment.filename, value: attachment.value })
							);
						}
					}
				} else if (mappedData[key] instanceof Object) {
					formData.append(key, JSON.stringify(mappedData[key]));
				} else {
					formData.append(key, mappedData[key]);
				}
			}
			mappedData = formData;
		}
		return this[method](data.id, mappedData, { ...opts, methodName: 'save' })
			.then(handleData.bind(this, this._parse.bind(this)))
			.then(res =>
				this._triggerEvent(
					opts,
					data.id || opts?.dontWait ? event.UPDATED : event.ADDED,
					res,
					changedAttributeKeys
				)
			)
			.catch(e => {
				if (opts?.dontWait) {
					if (data.id) {
						this.get(data.id)
							.then(res => {
								this._triggerEvent(opts, event.UPDATED, res, changedAttributeKeys);
							})
							.catch(err => {
								logError(err, 'Error getting obj after failed update using dontWait');
							});
					} else {
						this._triggerEvent(opts, event.DELETED, { data }, changedAttributeKeys);
					}
				}
				throw e;
			});
	}

	delete(id, opts = {}) {
		return (
			this._deleteRequest(id, { ...opts, methodName: 'delete' })
				// Parser is set to just pass data (delete will only get id so nothing to parse)
				.then(handleData.bind(this, d => d, { data: { data: { id } } }))
				.then(this._triggerEvent.bind(this, opts, event.DELETED))
		);
	}

	new() {
		return {};
	}

	findAll(filters = {}, limit) {
		return findAll(this.find, filters, limit);
	}
}
