import React from 'react';
import OpportunityEvents from 'App/babel/resources/OpportunityEvents';
import HistoryLog from '../HistoryLog';
import Opportunity from 'App/resources/Model/Opportunity';
import usePartialLoader, { FetcherFunction } from 'App/components/hooks/usePartialLoader';
import { getTimelineRow } from 'Components/Helpers/getTimelineRow';
import T from 'Components/Helpers/translate';
import TimelineLoadMore from 'Components/TimelineRow/TimelineLoadMore';
import type { HistoryLogRenderedBy } from 'App/components/HistoryLog/ModalHistoryLog';
import PriorHistoryLog from '../PriorHistoryLog/PriorHistoryLog';

import './OpportunityHistoryLog.scss';
import useSelector from 'App/components/hooks/useSelector/useSelector';
import Event from 'App/resources/Model/Event';

type OpportunityHistoryLogProps = {
	opportunity: Opportunity;
	selectButtons?: React.ReactElement;
	showLeading?: boolean;
	renderedBy: HistoryLogRenderedBy;
};

const listeners = [
	'comment.added',
	'comment.deleted',
	'activity.deleted',
	'appointment.added',
	'appointment.deleted',
	'appointment.updated',
	'mail.updated',
	'activity.updated'
];

const fetcher: FetcherFunction<{ opportunity: Opportunity }, Event> = (filters, { opportunity }) => {
	return OpportunityEvents.getEvents(opportunity.id, filters.limit, filters.offset);
};

const OpportunityHistoryLog: React.FC<OpportunityHistoryLogProps> = ({
	opportunity,
	selectButtons,
	showLeading = false,
	renderedBy
}) => {
	if (!opportunity?.id) {
		return null;
	}

	const showLeadingUpTo = showLeading && !!opportunity.source?.id;
	const notesFilterActive = useSelector(state => state.NotesFilterActive);

	const {
		data: events,
		total: totalEvents,
		loading,
		loadMore
	} = usePartialLoader({
		fetcher,
		broadcastTypes: listeners,
		fetcherProps: {
			opportunity
		}
	});

	return (
		<HistoryLog
			title={T('history')}
			selectButtons={selectButtons}
			hasPriorHistory={showLeadingUpTo}
			loading={loading}
		>
			{events.map((event, index) => {
				return (
					<div key={index}>
						{getTimelineRow(
							event,
							'Opportunity',
							opportunity.id === event.entityId ? false : notesFilterActive,
							events.length - 1 === index,
							renderedBy
						)}
					</div>
				);
			})}
			{events && events.length < totalEvents ? (
				<TimelineLoadMore
					newEventsLoading={loading}
					numEventsLoaded={events.length}
					totalEvents={totalEvents}
					incrementOffset={loadMore}
				/>
			) : null}
			{showLeadingUpTo ? <PriorHistoryLog type={'opportunity'} entity={opportunity} /> : null}
		</HistoryLog>
	);
};

export default OpportunityHistoryLog;
