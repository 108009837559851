import { useTranslation } from 'Components/Helpers/translate';
import { useEditTicketContext } from '../../Context/Context';
import BemClass from '@upsales/components/Utils/bemClass';
import TicketPriorityEnum from 'App/enum/TicketPriority';
import { ButtonSelect } from '@upsales/components';
import React from 'react';

import './PrioSelect.scss';

type Props = { disabled?: boolean; onChange: (value: any) => void };

const PrioSelect = ({ disabled = false, onChange }: Props) => {
	const {
		state: { ticket }
	} = useEditTicketContext();
	const { t } = useTranslation();

	const classes = new BemClass('PrioSelect');

	return (
		<ButtonSelect
			className={classes.b()}
			disabled={disabled}
			options={[
				{ title: t('ticket.priority.low'), value: TicketPriorityEnum.LOW },
				{ title: t('ticket.priority.medium'), value: TicketPriorityEnum.MEDIUM },
				{ title: t('ticket.priority.high'), value: TicketPriorityEnum.HIGH },
				{ title: t('ticket.priority.critical'), value: TicketPriorityEnum.CRITICAL }
			]}
			value={ticket.priority}
			onChange={onChange}
		></ButtonSelect>
	);
};

export default PrioSelect;
