import React, { useMemo } from 'react';
import { Block, Input, Row } from '@upsales/components';
import BemClass from '@upsales/components/Utils/bemClass';
import { FilterConfig } from 'App/babel/filterConfigs/FilterConfig';
import T from 'Components/Helpers/translate';
import { ListViewFilter } from 'App/resources/AllIWant';

export type NumberFilter = Omit<ListViewFilter, 'value'> & { value: { start: null | number; end: null | number } };

type NumberFilterProps = {
	filter: NumberFilter;
	filterConfig: FilterConfig;
	valueChanged: (value: NumberFilter['value']) => void;
};

function NumberFilterComponent({ filter, filterConfig, valueChanged }: NumberFilterProps) {
	const className = new BemClass('SalesboardFilterButton');

	const lang = useMemo(
		() => ({
			from: T('default.from'),
			to: T('default.to')
		}),
		[]
	);

	const mapValue = (value: number | null) => (typeof value === 'number' ? value.toString() : '');

	const parseValue = (value: string, oldValue: number | null) => {
		if (/^-?\d+(\.\d+)?$/.test(value)) {
			return parseFloat(value);
		} else {
			return null;
		}
	};

	return (
		<Block space="plm ptm prm pbm" className={className.elem('nuymber').b()}>
			<Row>
				<Input
					onChange={({ target: { value } }) =>
						valueChanged({ ...filter.value, start: parseValue(value, filter.value.start) })
					}
					defaultValue={mapValue(filter.value?.start)}
					placeholder={lang.from}
				/>
				<Input
					onChange={({ target: { value } }) =>
						valueChanged({ ...filter.value, end: parseValue(value, filter.value.end) })
					}
					defaultValue={mapValue(filter.value?.end)}
					placeholder={lang.to}
				/>
			</Row>
		</Block>
	);
}

export default NumberFilterComponent;
