import { RCDashboardFilter } from 'Resources/ReportDashboard';
import T from 'Components/Helpers/translate';

export const renderSelected = (values: RCDashboardFilter) => {
	let res = `${T('default.productCategories')}: `;

	const productCategoryNames = Tools.AppService.getProductCategories()
		.filter(pc => {
			return values?.ProductCategory?.value?.includes?.(pc.id);
		})
		.map(pc => pc.name);

	res += productCategoryNames.join(', ');
	return res;
};
