import React from 'react';
import { Text } from '@upsales/components';
import { useTranslation } from 'react-i18next';
import openModal from 'App/services/Modal';

import type { TimelineCardComponent, TimelineComponentProps } from '../TimelineGeneric/TimelineCardConfigs';
import TimelineGeneric from '../TimelineGeneric/TimelineGeneric';

const TimelineCardClientPlan = (({ event, renderedByType, ...passthrough }) => {
	const { t } = useTranslation();
	const { plan, client, value } = event;

	const statusMap = {
		COMPLETED: t('accountPlan.completed'),
		NOT_COMPLETED: t('accountPlan.notCompleted')
	} as const;

	let titleElements: TimelineComponentProps['titleElements'];
	if (renderedByType === 'clientPlan') {
		switch (event.subType) {
			case 'Created':
				titleElements = ['users', () => t('accountPlan.createdThisPlan')];
				break;
			case 'StatusChanged':
				titleElements = [
					'users',
					() => t('accountPlan.changedStatusToValue'),
					() => <Text bold>{statusMap[value as 'COMPLETED' | 'NOT_COMPLETED']}</Text>
				];
				break;
		}
	} else {
		switch (event.subType) {
			case 'Created':
				titleElements = ['users', () => t('accountPlan.timeline.creaded'), 'eventLink'];
				break;
			case 'StatusChanged':
				titleElements = [
					'users',
					() => t('accountPlan.changedStatus'),
					'eventLink',
					() => t('accountPlan.changedStatusTo'),
					() => <Text bold>{statusMap[value as 'COMPLETED' | 'NOT_COMPLETED']}</Text>
				];
				break;
		}
	}

	return (
		<TimelineGeneric
			event={event}
			titleElements={titleElements}
			eventLinkTitle={plan?.description ?? ''}
			onLinkClick={() => {
				openModal('EditAccountPlan', {
					clientPlanId: plan?.id,
					clientName: client?.name,
					clientId: client?.id
				});
			}}
			hasAvatar
			{...passthrough}
		/>
	);
}) satisfies TimelineCardComponent;

export default TimelineCardClientPlan;
