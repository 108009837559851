import React from 'react';
import PropTypes from 'prop-types';
import BuyButtonRow from './BuyButtonRow';
import { Button, Icon, DropDownMenu, Row, Text, Card, CardContent } from '@upsales/components';

import './BuyButton.scss';

const valueAndDefaultValueWarning =
	'BuyButton got both value and defaultValue props. Input elements must be either controlled or uncontrolled (specify either the value prop, or the defaultValue prop, but not both)';
const valueNoOnChangeWarning =
	'Failed prop type: You provided a `value` without an `onChange` handler. This will render a read-only field. If the field should be mutable use `defaultValue`.';

export default class BuyButton extends React.Component {
	constructor(props) {
		super(props);

		const { pricing, language, value, defaultValue, onChange } = this.props;

		if (value !== undefined && defaultValue !== undefined) {
			throw new Error(valueAndDefaultValueWarning);
		}

		if (value !== undefined && typeof onChange !== 'function') {
			throw new Error(valueNoOnChangeWarning);
		}

		this.controlledComponent = value ? true : false;

		const t = Tools.$translate;
		const defaultLangue = {
			dropdownTitle: t('default.confirmPurchase'),
			confirm: t('default.confirm'),
			add: t('account.addAsNew'),
			adding: t('account.adding'),
			added: t('account.added')
		};

		this.lang = _.assign({}, defaultLangue, language);

		if (!this.controlledComponent) {
			const purchaseType = defaultValue
				? defaultValue
				: pricing && Object.keys(pricing).length
				? Object.keys(pricing)[0]
				: null;
			this.state = { purchaseType: purchaseType };
		}

		this.radioChange = this.radioChange.bind(this);
		this.renderButton = this.renderButton.bind(this);
	}

	isChecked(value) {
		return this.controlledComponent ? value === this.props.value : this.state.purchaseType;
	}

	radioChange(purchaseType) {
		this.setState({ purchaseType });
	}

	getText() {
		const { added, adding } = this.props;

		if (added) {
			return (
				<span>
					<i className="fa fa-check" /> {this.lang.added}
				</span>
			);
		} else if (adding) {
			return (
				<span>
					<i className="fa fa-spinner fa-spin" /> {this.lang.adding}
				</span>
			);
		} else {
			return this.lang.add;
		}
	}

	renderButton(props) {
		const buttonText = this.getText();
		const icon = this.props.icon;

		return (
			<Button {...props}>
				{icon && !props.loading ? <Icon name={icon} /> : null}
				<span>{buttonText}</span>
			</Button>
		);
	}

	render() {
		const {
			pricing,
			added,
			adding,
			disabled,
			onConfim,
			onChange,
			renderButton,
			onOpen,
			onClose,
			size = 'md',
			color = 'green'
		} = this.props;
		const showCheckbox = pricing && Object.keys(pricing).length > 1;
		const radioChange = this.controlledComponent ? onChange : this.radioChange;
		const buttonRenderer = renderButton || this.renderButton;
		const value = this.controlledComponent ? this.props.value : this.state.purchaseType;

		const pricingOptions = _.map(pricing, (pricingOption, key) => {
			const isChecked = key === value;

			return (
				<BuyButtonRow
					key={key}
					value={key}
					checked={isChecked}
					options={pricingOption}
					onChange={radioChange}
					className="pricing-option-row"
					showCheckbox={showCheckbox}
				/>
			);
		});

		const buttonProps = {
			className: 'DataSourceBuyButton',
			color: added ? 'green' : color,
			loading: adding,
			disabled: disabled,
			size: size
		};

		return pricingOptions.length ? (
			<DropDownMenu
				className="DataSourceBuyButton__dropdown"
				align="right"
				onOpen={onOpen}
				onClose={onClose}
				renderTrigger={(isExpanded, setExpanded) => {
					const dropDownSpecificArgs = { isExpanded, setExpanded };
					const args = {
						...buttonProps,
						onClick: setExpanded,
						className: `DataSourceBuyButton ${isExpanded ? 'DataSourceBuyButton-expanded' : ''}`
					};

					if (!isExpanded) {
						args.shadow = 'none';
					}

					return buttonRenderer(args, dropDownSpecificArgs);
				}}
			>
				<Card>
					<CardContent>
						<Row>
							<Text>{this.lang.dropdownTitle}</Text>
						</Row>
						{pricingOptions}
						<Row>
							<Button
								size="sm"
								block={true}
								color="bright-green"
								shadow="none"
								disabled={disabled || adding}
								onClick={() => onConfim(value)}
							>
								{this.lang.confirm}
							</Button>
						</Row>
					</CardContent>
				</Card>
			</DropDownMenu>
		) : (
			buttonRenderer({ ...buttonProps, onClick: () => onConfim(value), shadow: 'none' })
		);
	}
}

BuyButton.propTypes = {
	pricing: PropTypes.object,
	language: PropTypes.object,
	added: PropTypes.bool,
	adding: PropTypes.bool,
	disabled: PropTypes.bool,
	onChange: PropTypes.func,
	onConfim: PropTypes.func,
	value: PropTypes.string,
	defaultValue: PropTypes.string,
	renderButton: PropTypes.func,
	onOpen: PropTypes.func,
	onClose: PropTypes.func,
	size: PropTypes.string,
	icon: PropTypes.string,
	color: PropTypes.string
};
