import translate from 'Components/Helpers/translate';
import logError from 'App/babel/helpers/logError';
import { parseSignature } from './helpers/signatureHelper';

CKEDITOR.plugins.add('signature', {
	init: editor => {
		editor.addCommand('insertSignature', {
			exec: function (editor) {
				editor.setData(
					`<strong>${Tools.AppService.getSelf().name} ${moment().format(
						'L LT'
					)}:</strong><p></p>${editor.getData()}`,
					{
						callback: function () {
							const range = this.getSelection().getRanges()[0];
							const pNode = range.root.getFirst().getNext();
							const newRange = this.createRange();
							newRange.moveToPosition(pNode, CKEDITOR.POSITION_AFTER_START);
							newRange.select();
						}
					}
				);
			}
		});

		editor.ui.addButton('InsertSignature', {
			label: translate('default.insertSignature'),
			command: 'insertSignature',
			toolbar: 'signature'
		});
	},
	afterInit: editor => {
		setTimeout(() => {
			const btnLabel = document.querySelectorAll(`#cke_${editor.name} .cke_button__insertsignature_label`)[0];
			const btnIcon = document.querySelectorAll(`#cke_${editor.name} .cke_button__insertsignature_icon`)[0];
			if (btnLabel) {
				btnLabel.style.display = 'inline';
				btnLabel.style.paddingLeft = '0';
				btnIcon.style.display = 'none';
			}
		}, 100);
	}
});

CKEDITOR.plugins.add('signatures', {
	init: editor => {
		const transformer = editor.config?.signaturePlugin?.transformer;
		let signatures = [];
		Tools.MailSignatures.getActiveSignatures()
			.then(res => {
				signatures = res.data;
			})
			.catch(e => logError(e, 'Could not get active signatures'));

		editor.ui.addRichCombo('signatures', {
			label: translate('default.mailSignature'),
			toolbar: 'basicstyles,0',

			panel: {
				css: [CKEDITOR.skin.getPath('editor')].concat(editor.config.contentsCss),
				multiSelect: false
			},

			init: function () {
				for (const signature of signatures) {
					this.add(signature.id, signature.name);
				}
			},

			onClick: async value => {
				const signature = signatures.find(s => s.id === parseInt(value));
				editor.focus();
				editor.insertHtml(await parseSignature(signature.body, transformer));
			}
		});
	},
	afterInit: editor => {
		setTimeout(() => {
			const btnLabel = document.querySelectorAll(
				`#cke_${editor.name} .cke_combo__signatures .cke_combo_inlinelabel`
			)[0];
			if (btnLabel) {
				btnLabel.style.width = 'auto';
			}
		}, 100);
	}
});
