import React from 'react';
import { Button, Icon, Help } from '@upsales/components';
import { useSubscriptionGroupContext } from '../../Context/SubscriptionGroupContext';
import BemClass from '@upsales/components/Utils/bemClass';
import T from 'Components/Helpers/translate';
import InlineConfirm from 'Components/Dialogs/InlineConfirm';
import moment from 'moment';
import './SubscriptionPeriodSelector.scss';
import SubscriptionPeriodButtonSelect from './SubscriptionPeriodButtonSelect';
import { getAgreementPeriodText } from 'App/helpers/subscriptionHelper';

interface SubscriptionPeriodSelectorProps {}

//const SUBSCRIPTION_PERIOD_OPTIONS = [2, 3, 4];

const SubscriptionPeriodSelector: React.FC<SubscriptionPeriodSelectorProps> = () => {
	const classNames = new BemClass('SubscriptionPeriodSelector');
	const { state, updateCurrent, deletePeriod } = useSubscriptionGroupContext();

	const isEditLangExtra = state.isEdit ? '.isEdit' : '';
	const lang = {
		periods: T('subscription.modal.summary.scheduling.periods'),
		currentPeriod: T('subscription.modal.summary.scheduling.currentPeriod'),
		upcomingPeriod: T('subscription.modal.summary.scheduling.upcomingPeriod'),
		splitPrompt: T('subscription.modal.summary.scheduling.splitPrompt'),
		removeSplit: T('subscription.modal.summary.scheduling.removeSplit' + isEditLangExtra),
		removeTitle: T('subscription.modal.summary.scheduling.removeSplit.title' + isEditLangExtra),
		removeBody: T('subscription.modal.summary.scheduling.removeSplit.body' + isEditLangExtra)
	};

	const subscriptions = Object.values(state.subscriptionMap);

	const currentPeriodStart = moment(subscriptions[0].invoiceStartDate).format('ll');
	const currentPeriodEnd = (subscriptions[0].endDate
		? moment(subscriptions[0].endDate)
		: moment(subscriptions[0].invoiceStartDate).add(subscriptions[0].periodLength, 'months')
	).format('ll');

	const upcomingPeriodStart = subscriptions[1]?.invoiceStartDate
		? moment(subscriptions[1].invoiceStartDate).format('ll')
		: currentPeriodEnd;
	const upcomingPeriodEnd = subscriptions[1]?.endDate ? moment(subscriptions[1].endDate).format('ll') : null;

	const currentPeriodTimeSpan = `${currentPeriodStart} - ${currentPeriodEnd}`;
	const upcomingPeriodTimeSpan = `${upcomingPeriodStart} - ${upcomingPeriodEnd || ''}`;

	const periods = [
		{
			period: subscriptions[0],
			title: getAgreementPeriodText(subscriptions[0], true),
			timespanText: currentPeriodTimeSpan,
			value: subscriptions[0].uuid
		},
		{
			period: subscriptions[1],
			title: getAgreementPeriodText(subscriptions[1]),
			timespanText: upcomingPeriodTimeSpan,
			value: subscriptions[1]?.uuid ?? -1
		}
	];

	const hideDeletePeriod = (subscriptions[1].orderSequenceNr ?? 0) > 0;

	return periods.length > 1 ? (
		<div className={classNames.b()}>
			<div className={classNames.elem('selection-container').b()}>
				<SubscriptionPeriodButtonSelect
					currentUUID={state.currentUUID}
					options={periods}
					onChange={id => updateCurrent(id)}
				></SubscriptionPeriodButtonSelect>
				<InlineConfirm
					show={!hideDeletePeriod}
					tooltip={T('default.delete')}
					onConfirm={() => {
						const lastPeriod = [...periods].pop();
						if (lastPeriod) {
							deletePeriod(lastPeriod.value);
						}
					}}
					titleText={lang.removeTitle}
					bodyText={lang.removeBody}
					btnText={lang.removeSplit}
				>
					<Button hoverColor="grey-3" size="sm" color="grey" type="link">
						<Icon name="trash" />
					</Button>
				</InlineConfirm>
				<Help articleId={1411} sidebar />
			</div>

			{/* {!isLastPeriodSelected ? (
				<>
					<Label className={classNames.elem('multiple-periods-toggle').b()}>
						<Toggle size="lg" checked={periods.length > 2} onChange={() => {}} />
						<Text bold size="md">
							{lang.splitPrompt}
						</Text>
					</Label>

					<RadioList
						list={SUBSCRIPTION_PERIOD_OPTIONS.map(i => ({
							label: `${i} ${lang.periods}`,
							value: i,
							checked: periods.length - 1 === i,
							disabled: false
						}))}
						onChange={period => console.log(period)}
					/>
				</>
			) : null} */}
		</div>
	) : null;
};

export default SubscriptionPeriodSelector;
