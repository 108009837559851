import React from 'react';
import Animate from './Animate';

type GenericProps = Omit<React.ComponentProps<typeof Animate>, 'mods'> & {};

/** @deprecated use ui components instead */
export const Pulse = (props: Omit<GenericProps, 'delayInMs'>) => {
	return <Animate visible mods={{ pulse: true }} {...props} />;
};

/** @deprecated use ui components instead */
export const PulseScale = (props: Omit<GenericProps, 'delayInMs'>) => {
	return <Animate visible mods={{ pulseScale: true }} {...props} />;
};

/** @deprecated use ui components instead */
export const Fade = (props: GenericProps) => {
	return <Animate visible mods={{ fade: true }} {...props} />;
};

/** @deprecated use ui components instead */
type SlideProps = GenericProps & {
	direction?: 'top' | 'right' | 'bottom' | 'left';
	complete?: boolean;
};

/** @deprecated use ui components instead */
export const Slide = ({ direction = 'bottom', complete = false, ...props }: SlideProps) => {
	return <Animate mods={{ [`slide-${direction}${complete ? '-complete' : ''}`]: true, fade: false }} {...props} />;
};

/** @deprecated use ui components instead */
export const SlideFade = ({ direction = 'bottom', complete = false, ...props }: SlideProps) => {
	return <Animate mods={{ [`slide-${direction}${complete ? '-complete' : ''}`]: true, fade: true }} {...props} />;
};

type HeightProps = GenericProps & { maxHeight: number };

/** @deprecated use ui components instead */
export const Height = ({ ...props }: HeightProps) => {
	return <Animate {...props} height />;
};
