import React, { useState } from 'react';
import BemClass from '@upsales/components/Utils/bemClass';
import { DangerButton, PrimaryButton, ThirdButton } from '@upsales/components/Buttons';
import {
	Block,
	Row,
	ModalHeader,
	ModalContent,
	ModalControls,
	WhiteModal,
	FullscreenModal,
	Tooltip
} from '@upsales/components';

import { useTranslation } from 'Components/Helpers/translate';
import Confirmation from 'App/babel/components/Dialogs/Confirmation';
import { ModalProps } from '../Modals/Modals';

import './Alerts.scss';

export type AlertProps = ModalProps<boolean> & {
	body?: React.ReactElement | string;
	confirmationMode?: 'text' | string;
	confirmText?: string;
	hideAbort?: boolean;
	confirmButtonText?: string;
	headerIcon?: React.ComponentProps<typeof ModalHeader>['icon'];
	body2?: React.ReactElement | string;
	title?: string;
	fullscreen?: boolean;
	disableConfirm?: boolean;
	disableConfirmTooltip?: string;
	hideConfirm?: boolean;
	alertType?: 'info';
	cancelButtonText?: string;
};

const Alerts = ({
	close,
	className,
	title,
	confirmButtonText,
	cancelButtonText,
	fullscreen,
	body,
	body2,
	confirmText = 'UPSALES',
	confirmationMode,
	hideAbort,
	headerIcon,
	alertType,
	disableConfirm,
	disableConfirmTooltip,
	hideConfirm
}: AlertProps) => {
	const [input, setInput] = useState('');
	const [valid, setValid] = useState(!confirmationMode);
	const { t } = useTranslation();

	const classes = new BemClass('Alerts', className);

	const compare = (event: React.ChangeEvent<HTMLInputElement> | undefined) => {
		const text = event?.target.value ?? '';
		const formatedConfirmText = confirmText.replace(/\s\s+/g, ' ');
		const isValid = text === formatedConfirmText;

		setInput(text);
		setValid(isValid);
	};

	const ModalComponent = fullscreen ? FullscreenModal : WhiteModal;


	const ConfirmButton = alertType === 'info' ? PrimaryButton : DangerButton;

	const fullscreenControls = () => (
		<>
			<Block space="mbm">
				{hideConfirm ? null : (
					<Tooltip disabled={!disableConfirm || !disableConfirmTooltip} title={t(disableConfirmTooltip ?? '')}>
						<ConfirmButton disabled={!valid || disableConfirm} onClick={() => close(true)}>
							{t(confirmButtonText || title || 'alertModal.defaultConfirm')}
						</ConfirmButton>
					</Tooltip>
				)}
			</Block>
			<Block>
				{hideAbort ? null : (
					<ThirdButton onClick={() => close(false)}>
						{t(cancelButtonText || 'alertModal.cancel')}
					</ThirdButton>
				)}
			</Block>
		</>
	);

	return (
		<ModalComponent className={classes.b()}>
			<ModalHeader icon={headerIcon} title={t(title ?? '')} onClose={() => close()} />

			<ModalContent>
				{typeof body === 'string' ? <p>{t(body)}</p> : body}
				{body2 ? <p>{body2}</p> : null}
				{confirmationMode && (
					<Confirmation input={input} valid={valid} compare={compare} confirmText={confirmText} noIcon />
				)}
			</ModalContent>

			<ModalControls>
				{fullscreen ? fullscreenControls() : (
					<Row align="right">
						{hideConfirm ? null : (
							<Block space="mrl">
								<Tooltip disabled={!disableConfirm || !disableConfirmTooltip} title={t(disableConfirmTooltip ?? '')}>
									<ConfirmButton block={fullscreen} disabled={!valid || disableConfirm} onClick={() => close(true)}>
										{t(confirmButtonText || title || 'alertModal.defaultConfirm')}
									</ConfirmButton>
								</Tooltip>
							</Block>
						)}

						{hideAbort ? null : (
							<Block>
								<ThirdButton onClick={() => close(false)} block={fullscreen}>
									{t(cancelButtonText || 'alertModal.cancel')}
								</ThirdButton>
							</Block>
						)}
					</Row>
				)}
			</ModalControls>
		</ModalComponent>
	);
};

export default Alerts;

