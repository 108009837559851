import React, { useEffect, useReducer } from 'react';
import { DateInput, TimeInput, Row } from '@upsales/components';
import T from 'Components/Helpers/translate';
import LabeledInput from './LabeledInput';
import './DateTimeSelect.scss';
import moment from 'moment';

type Props = {
	label?: string;
	initialDate?: Date;
	onUpdate: (state: DateSelectState) => void;
} & Omit<PartialPick<React.ComponentProps<typeof DateInput>, 'scrollContainer'>, 'onChange'>;

type DateSelectState = {
	fullDate: Date | null;
	day: string | null;
	time: string | null;
};

type DateAction =
	| {
			type: 'setDate';
			payload: DateSelectState['fullDate'];
	  }
	| {
			type: 'setTime';
			payload: DateSelectState['fullDate'];
	  }
	| {
			type: 'clear';
	  };

const dateReducer = (state: DateSelectState, action: DateAction) => {
	switch (action.type) {
		case 'setDate': {
			const momentDate = moment(action.payload);
			if (!momentDate.isValid()) {
				return { fullDate: null, day: null, time: null };
			}

			const day = momentDate.format('YYYY-MM-DD');

			let fullDate = action.payload;
			if (state.time) {
				const [hours, minutes] = state.time.split(':');
				fullDate = momentDate.set({ hour: parseInt(hours), minute: parseInt(minutes) }).toDate();
			}

			return {
				...state,
				fullDate,
				day
			};
		}
		case 'setTime': {
			const momentDate = moment(action.payload);
			if (!momentDate.isValid()) {
				return { fullDate: null, day: null, time: null };
			}

			const time = momentDate.format('HH:mm');

			let fullDate = action.payload;
			if (state.day) {
				const momentDay = moment(state.day);
				fullDate = momentDay.set({ hour: momentDate.hours(), minute: momentDate.minutes() }).toDate();
			}

			return {
				...state,
				fullDate,
				time
			};
		}
		case 'clear':
			return {
				fullDate: null,
				day: null,
				time: null
			};
	}
};

const DateTimeSelect = ({ initialDate, onUpdate, label, ...inputProps }: Props) => {
	const [dateState, dateDispatch] = useReducer(dateReducer, { fullDate: null, day: null, time: null });

	useEffect(() => {
		onUpdate(dateState);
	}, [dateState.fullDate]);

	useEffect(() => {
		if (initialDate) {
			dateDispatch({ type: 'setDate', payload: initialDate });
		}
	}, []);

	const showTime = dateState.time !== null;
	const showDate = dateState.day !== null;

	return (
		<LabeledInput title={label ?? ''} classes="DateTimeSelect">
			<Row>
				<DateInput
					value={showDate ? dateState.fullDate ?? null : null}
					closeOnSelect
					showWeekNumbers={true}
					onChange={e => {
						dateDispatch({ type: 'setDate', payload: e.target.value });
					}}
					placeholder={T('default.date')}
					{...inputProps}
				/>
				<TimeInput
					asDate
					placeholder={T('default.time')}
					value={showTime ? dateState.fullDate ?? null : null}
					onChange={e => {
						dateDispatch({ type: 'setTime', payload: e.target.value });
					}}
					{...inputProps}
				/>
			</Row>
		</LabeledInput>
	);
};

export default DateTimeSelect;
