import moment from 'moment';

export const mapProperties = (properties = {}, arrayProperties = []) => {
	return Object.keys(properties || {}).reduce((result, key) => {
		const isArray = arrayProperties.indexOf(key) !== -1;
		const obj = {
			name: key,
			value: properties[key]
		};

		if (isArray) {
			obj.value = obj.value.join(',');
		}

		// Do not push empty arrays
		if (!(isArray && !properties[key].length) && obj.value != null) {
			result.push(obj);
		}
		return result;
	}, []);
};

export const parseProperties = function (properties = [], arrayProperties = []) {
	return (properties || []).reduce((result, prop) => {
		result[prop.name] = prop.value;
		if (prop.value === '{{General.CurrentUser}}') {
			result[prop.name] = '{{General.CurrentUserId}}';
		}
		if (arrayProperties.indexOf(prop.name) !== -1) {
			result[prop.name] = result[prop.name].split(',').map(s => parseInt(s));
		}
		return result;
	}, {});
};

export const getCustomFieldValue = function (field) {
	if (field.value) {
		if (field.datatype === 'Date' && field.value.tag) {
			return '{{' + field.value.tag + '}}';
		} else if (field.datatype === 'User') {
			return field.value.id ? String(field.value.id) : null;
		} else if (field.datatype === 'Users') {
			return field.value.map(value => value.id).join(',') || null;
		} else if (field.datatype === 'Date' && moment(field.value).format('HH:mm') === '00:00') {
			return moment(field.value).format('YYYY-MM-DD');
		}
		return field.value;
	} else if (field.datatype === 'Boolean' && !field.value) {
		return false;
	} else if (['Integer', 'Currency', 'Percent', 'Discount'].includes(field.datatype) && field.value === 0) {
		return 0;
	}
	return null;
};
