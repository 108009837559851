import React, { useMemo } from 'react';
import { Text, Link, IconName } from '@upsales/components';
import TimelineRow from 'Components/TimelineRow';
import { SubTitle } from '../Helpers';
import { AppointmentEvent } from 'App/resources/Model/Event';
import { openDrawer } from 'Services/Drawer';

function openEmail(id: number) {
	if (Tools.FeatureHelper.hasSoftDeployAccess('NEW_MAIL')) {
		openDrawer('SentMail', { mail: { id } });
	} else {
		Tools.$upModal.open('sentMail', {
			customerId: Tools.AppService.getCustomerId(),
			id
		});
	}
}

const getContactHref = (id: number) =>
	Tools.$state.href('contact.dashboard', {
		customerId: Tools.AppService.getCustomerId(),
		id
	});

type Props = {
	event: AppointmentEvent;
	hideIcon?: boolean;
};

function MailSentEvent({ event, hideIcon }: Props) {
	const {
		data: { mail = '' },
		date,
		user
	} = event;
	const { $translate: t } = Tools;

	const title = useMemo(() => {
		const {
			id,
			subject,
			contact: { id: contactId, name }
		} = JSON.parse(mail);
		return (
			<Text key="title">
				{t('activity.outcomeLog.sentEmail') + ' '}
				<Link onClick={() => openEmail(id)}>{subject}</Link>
				{` ${t('default.to').toLowerCase()} `}
				<Link href={getContactHref(contactId)}>{name}</Link>
				{user ? t('appointment.outcomeLog.byUser', { user: user.name }) : ''}
			</Text>
		);
	}, [mail, user]);

	const subTitle = <SubTitle date={date} />;

	return (
		<TimelineRow event={event} icon={(hideIcon ? '' : 'envelope') as IconName} title={title} subTitle={subTitle} />
	);
}

export default MailSentEvent;
