import { TableColumn, TableRow, Link, Checkbox, Row } from '@upsales/components';
import React from 'react';
import { CurrencyFormat } from 'App/babel/utils/numberFormat';
import T from 'Components/Helpers/translate';
import BemClass from '@upsales/components/Utils/bemClass';
import { forecastingTracker } from 'Helpers/Tracker';
import './OpportunityRow.scss';
import { ForecastTableDrilldownRow } from 'Resources/ReportWidget';

type Props = {
	excluded: number[];
	setExcluded: (ids: number[]) => void;
	row: ForecastTableDrilldownRow;
	isDropDown: boolean;
	currency: string;
};

const navigateToAccount = (id: number) => {
	window.open(
		Tools.$state.href('account.dashboard', {
			customerId: Tools.AppService.getCustomerId(),
			id
		}),
		'_blank'
	);
};

const navigateToOrder = (id: number) => {
	const opts: { id: number; type: 'opportunity'; initView?: string } = {
		id: id,
		type: 'opportunity',
		initView: 'dashboard'
	};
	return Tools.$upModal.open('editOrder', opts);
};

const OpportunityRow = ({ excluded, setExcluded, row, isDropDown, currency }: Props) => {
	const classes = new BemClass('OpportunityRow');
	const cf = new CurrencyFormat(currency);
	const unitLang = T(row.timeInPipeline.unit);
	const isChecked = !excluded.includes(row.id);
	return (
		<TableRow
			key={row.id}
			className={classes.elem('row').mod({ isDropDown }).b()}
			onClick={() => {
				forecastingTracker.track(forecastingTracker.events.OPPORTUNITY_CLICKED);
				navigateToOrder(row.id);
			}}
		>
			<TableColumn size="lg">
				<Row>
					<Checkbox
						size="sm"
						space="mrm"
						checked={isChecked}
						onClick={e => {
							e.stopPropagation();
							if (isChecked) {
								setExcluded([...excluded, row.id]);
							} else {
								setExcluded(excluded.filter(e => e !== row.id));
							}
						}}
					/>
					<Link
						onClick={e => {
							e.stopPropagation();
							forecastingTracker.track(forecastingTracker.events.COMPANY_CLICKED);
							navigateToAccount(row.client.id);
						}}
					>
						{row.client.name}
					</Link>
				</Row>
			</TableColumn>
			<TableColumn size="lg">{cf.short(row.value)}</TableColumn>
			<TableColumn size="lg">{row.stage.name}</TableColumn>
			<TableColumn size="lg">{row.date}</TableColumn>
			<TableColumn size="lg">{`${row.timeInPipeline.value} ${unitLang}`}</TableColumn>
			<TableColumn />
		</TableRow>
	);
};

export default OpportunityRow;
