import { Equals } from 'Resources/ComparisonTypes';
import DatePreset from 'App/upsales/common/services/datePresetService';
const { findAllChildren } = require('./treeHelpers');
import buildCustomFilter from 'App/upsales/common/components/listFilters/buildCustomFilter';
import getCriteriaFilterConfigs from 'Components/Criteria/getCriteriaFilterConfigs';

export const newFilter = () => ({
	inactive: true,
	value: {
		hasOpportunity: true,
		onlyZeroStage: false,
		OrderRowProduct: { value: [], comparisonType: Equals },
		OrderRowProductCategory: { value: [] },
		Value: { value: { start: null, end: null } },
		Date: { value: { start: null, end: null, preset: 'whenever' } },
		Campaign: { value: [] },
		User: { value: [] },
		Stage: { value: [] },
		Won: { value: null },
		Currency: { value: null },
		Custom: { value: [] }
	}
});

const opportunityFilter = {
	generate: newFilter,
	isInactive: filter => filter.inactive,
	getText: (val, inactive) => {
		var num = 0;

		if (inactive !== true) {
			num++;
		}

		if (val.Value.value.start || val.Value.value.end) {
			num++;
		}
		if (val.Date.value.start || val.Date.value.end || val.Date.value.preset !== 'whenever') {
			num++;
		}
		if (val.OrderRowProductCategory.value.length) {
			num++;
		}
		if (val.OrderRowProduct.value.length) {
			num++;
		}
		if (val.Campaign.value.length) {
			num++;
		}
		if (val.User.value.length) {
			num++;
		}
		if (val.onlyZeroStage || val.Stage.value.length) {
			num++;
		}
		if (val.Currency.value) {
			num++;
		}
		if (val.Won.value) {
			num++;
		}
		return num;
	},
	toUrl: function (filter) {
		const f = { v: {} };
		const val = filter.value;

		if (!val) return f;

		f.v.has = val ? val.hasOpportunity : null;
		f.v.st0 = val ? val.onlyZeroStage : null;
		f.i = filter.inactive;

		if (val.OrderRowProduct.value) {
			f.v.rowp = val.OrderRowProduct.value;
		}
		if (val.OrderRowProduct.comparisonType) {
			f.v.rowpc = val.OrderRowProduct.comparisonType;
		}
		if (val.OrderRowProductCategory.value) {
			f.v.rowc = val.OrderRowProductCategory.value;
		}
		if (val.Value.value.start || val.Value.value.end) {
			f.v.va = { s: val.Value.value.start, e: val.Value.value.end };
		}
		if (val.Date.value.start || val.Date.value.end || val.Date.value.preset !== 'whenever') {
			f.v.da = { p: val.Date.value.preset, s: val.Date.value.start, e: val.Date.value.end };
		}
		if (val.Campaign.value) {
			f.v.ca = val.Campaign.value;
		}
		if (val.User.value) {
			f.v.us = val.User.value;
		}
		if (val.Stage.value) {
			f.v.st = val.Stage.value;
		}
		if (val.Won.value) {
			f.v.wo = val.Won.value;
		}
		if (val.Currency.value) {
			f.v.cu = val.Currency.value;
		}
		if (val.Custom?.value) {
			f.v.cus = val.Custom.value;
		}

		return f;
	},
	fromUrl: function (rawFilter) {
		const filter = newFilter();
		filter.inactive = false;
		const val = rawFilter.v;

		filter.value.hasOpportunity = val.has;
		filter.value.onlyZeroStage = !!val.st0;

		if (rawFilter.i) {
			filter.inactive = rawFilter.i;
		}
		if (val.rowp) {
			filter.value.OrderRowProduct.value = val.rowp;
		}
		if (val.rowpc) {
			filter.value.OrderRowProduct.comparisonType = val.rowpc;
		}
		if (val.rowc) {
			filter.value.OrderRowProductCategory = { value: val.rowc };
		}
		if (val.va) {
			filter.value.Value = { value: { start: val.va.s, end: val.va.e } };
		}
		if (val.da) {
			filter.value.Date = {
				value: {
					start: val.da.s ? new Date(val.da.s) : val.da.s,
					end: val.da.e ? new Date(val.da.e) : val.da.e,
					preset: val.da.p
				}
			};
		}
		if (val.ca) {
			filter.value.Campaign = { value: val.ca };
		}
		if (val.us) {
			filter.value.User = { value: val.us };
		}
		if (val.st) {
			filter.value.Stage = { value: val.st };
		}
		if (val.won) {
			filter.value.Won = { value: val.won };
		}
		if (val.cu) {
			filter.value.Currency = { value: val.cu };
		}
		if (val.cus) {
			filter.value.Custom = { value: val.cus };
		}

		return filter;
	},
	build:
		(options = {}) =>
		(filter, rb, filterHelperGetField, useTags, activeFilters, getConfig) => {
			const val = filter.value;

			if (filter.inactive || !val) {
				return;
			}

			let products;
			let categories;

			if (options.mixedProductsAndCategories) {
				var ids = _.reduce(
					val.OrderRowProduct.value,
					function (result, id) {
						if (typeof id === 'string') {
							result.categoryIds.push(parseInt(id.split('-')[1]));
						} else {
							result.productIds.push(id);
						}
						return result;
					},
					{ productIds: [], categoryIds: [] }
				);

				products = ids.productIds;
				categories = ids.categoryIds;
			} else {
				products = val.OrderRowProduct.value;
				categories = val.OrderRowProductCategory.value;
			}

			const getField = options.fieldPrefix
				? field => filterHelperGetField([options.fieldPrefix, field].join('.'))
				: filterHelperGetField;

			const groupBuilder = rb.groupBuilder();
			if (
				!val.hasOpportunity &&
				!products.length &&
				val.Date.value.preset === 'whenever' &&
				!val.Campaign.value.length &&
				!val.User.value.length &&
				!val.Value.value.start &&
				!val.Value.value.start &&
				val.Won.value === null &&
				!categories.length &&
				!val.Stage.value.length &&
				!val.Currency.value
			) {
				groupBuilder.addFilter(getField('order.probability'), rb.comparisonTypes.LowerThan, 100);
				groupBuilder.isNotFilter();
				return groupBuilder.done();
			} else if (val.hasOpportunity) {
				if (val.onlyZeroStage) {
					// Use lower than so we can mix it with order filters
					groupBuilder.addFilter(getField('order.probability'), rb.comparisonTypes.Equals, 0);
				} else {
					// Use lower than so we can mix it with order filters
					groupBuilder.addFilter(getField('order.probability'), rb.comparisonTypes.LowerThan, 100);
				}
			} else {
				if (val.onlyZeroStage) {
					// Use lower than so we can mix it with order filters
					groupBuilder.addFilter(getField('order.probability'), rb.comparisonTypes.NotEquals, 0);
				} else {
					groupBuilder.addFilter(getField('order.probability'), rb.comparisonTypes.NotEquals, 100);
				}
				groupBuilder.isNotFilter();
			}

			// If both products and categories are selected they will use this builder in order for the OR clause to be correctly built
			const productOrCategoryBuilder = groupBuilder.orBuilder();

			// Products
			if (products.length) {
				if (val.OrderRowProduct.comparisonType === rb.comparisonTypes.EqualsAll) {
					for (const product of products) {
						const productGroupBuilder = groupBuilder.groupBuilder();
						productGroupBuilder.addFilter(getField('order.product.id'), rb.comparisonTypes.Equals, product);
						productGroupBuilder.done();
					}
				} else {
					const productOrBuilder =
						products.length && categories.length ? productOrCategoryBuilder : groupBuilder.orBuilder();
					productOrBuilder.next();
					productOrBuilder.addFilter(getField('order.product.id'), rb.comparisonTypes.Equals, products);
					if (!categories.length) {
						productOrBuilder.done();
					}
				}
			}

			// ProductCategory
			if (categories.length) {
				const allCategories = [
					...categories,
					...findAllChildren(Tools.AppService.getProductCategories(), categories)
				];

				if (
					val.OrderRowProduct.comparisonType === rb.comparisonTypes.EqualsAll &&
					options.mixedProductsAndCategories
				) {
					for (const category of allCategories) {
						const categoryGroupBuilder = groupBuilder.groupBuilder();
						categoryGroupBuilder.addFilter(
							getField('order.product.category.id'),
							rb.comparisonTypes.Equals,
							category
						);
						categoryGroupBuilder.done();
					}
				} else {
					const categoryOrBuilder =
						products.length && categories.length ? productOrCategoryBuilder : groupBuilder.orBuilder();
					categoryOrBuilder.next();
					categoryOrBuilder.addFilter(
						getField('order.product.category.id'),
						rb.comparisonTypes.Equals,
						categories
					);
					categoryOrBuilder.done();
				}
			}

			// Campaign
			if (val.Campaign.value.length) {
				groupBuilder.addFilter(getField('order.project.id'), rb.comparisonTypes.Equals, val.Campaign.value);
			}

			// User
			if (val.User.value.length) {
				groupBuilder.addFilter(getField('order.user.id'), rb.comparisonTypes.Equals, val.User.value);
			}

			// Date
			if (val.Date.value.preset !== 'whenever') {
				var dates = DatePreset.getDatesFromValue(val.Date.value, useTags);

				// start
				if (dates.start) {
					groupBuilder.addFilter(getField('order.date'), rb.comparisonTypes.GreaterThanEquals, dates.start);
				}

				// end
				if (dates.end) {
					groupBuilder.addFilter(getField('order.date'), rb.comparisonTypes.LowerThanEquals, dates.end);
				}
			}

			var currency = Tools.AppService.getMetadata().defaultCurrency;
			if (val.Currency.value) {
				groupBuilder.addFilter(
					getField('order.currency'),
					rb.comparisonTypes.Match,
					val.Currency.value.toLowerCase()
				);

				currency = _.find(Tools.AppService.getMetadata().customerCurrencies, function (currency) {
					return currency.iso.toLowerCase() === val.Currency.value.toLowerCase();
				});
			}

			// Value start
			if (val.Value.value.start) {
				groupBuilder.addFilter(
					getField(val.Currency.value ? 'order.value' : 'order.valueInMasterCurrency'),
					rb.comparisonTypes.GreaterThanEquals,
					currency
						? Math.round(parseInt(val.Value.value.start) / currency.rate).toString()
						: val.Value.value.start.toString()
				);
			}

			// Value end
			if (val.Value.value.end) {
				groupBuilder.addFilter(
					getField(val.Currency.value ? 'order.value' : 'order.valueInMasterCurrency'),
					rb.comparisonTypes.LowerThanEquals,
					currency
						? Math.round(parseInt(val.Value.value.end) / currency.rate).toString()
						: val.Value.value.end.toString()
				);
			}

			// Won
			if (val.Won.value !== null) {
				groupBuilder.addFilter(
					getField('order.probability'),
					rb.comparisonTypes[val.Won.value ? 'NotEquals' : 'Equals'],
					0
				);
			}

			// Stage
			if (val.Stage.value.length) {
				groupBuilder.addFilter(getField('order.stage.id'), rb.comparisonTypes.Equals, val.Stage.value);
			}

			val.Custom?.value.forEach(cf => {
				const filterName = 'order.Custom_' + cf.field;
				const field = getField(filterName).field;
				const config = getConfig ? getConfig(filterName) : getCriteriaFilterConfigs(1)[field];

				buildCustomFilter(
					{
						...cf,
						columnPath: field,
						filterName: field
					},
					field,
					config,
					'Contact',
					groupBuilder,
					'',
					{ ...options, useTags }
				);
			});

			groupBuilder.done();
		}
};

export default opportunityFilter;
