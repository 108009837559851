import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Headline, Text, Button, Input, Icon, Block, Row, Column } from '@upsales/components';
import { nextPage, setLicenses } from '../../store/actions/CreateAccount';
import WhatsIncludedCard from './WhatsIncludedCard';
import bemClass from '@upsales/components/Utils/bemClass';
import './NumLicenses.scss';

export class NumLicenses extends Component {
	static propTypes = {
		setLicenses: PropTypes.func.isRequired,
		numLicenses: PropTypes.number.isRequired,
		nextPage: PropTypes.func.isRequired,
		closeModal: PropTypes.func.isRequired
	};

	constructor(props) {
		super(props);

		const getAngularModule = require('../../angularHelpers/getAngularModule').default;
		const t = getAngularModule('$translate');

		this.lang = {
			headline: t('createAccount.numLicenses.headline'),
			iAlreadyHaveAccount: t('createAccount.iAlreadyHaveAccount'),
			nextButton: t('createAccount.numLicenses.nextButton'),
			numUsers: t('createAccount.numLicenses.numUsers'),
			terms: t('createAccount.numLicenses.terms'),
			totalMonth: t('createAccount.numLicenses.totalMonth'),
			totalYear: t('createAccount.numLicenses.totalYear'),
			upsalesCrm: t('createAccount.numLicenses.upsalesCrm'),
			upsalesCrmExplainer: t('createAccount.numLicenses.upsalesCrmExplainer'),
			readMore: t('default.learnMore')
		};
	}

	render() {
		const classes = new bemClass('NumLicenses');
		const getAngularModule = require('../../angularHelpers/getAngularModule').default;
		const filter = getAngularModule('$filter');
		const t = getAngularModule('$translate');

		return (
			<div>
				<Block space="mbm mtxl">
					<Headline size="sm">{this.lang.headline}</Headline>
				</Block>

				<Block borderColor="grey-6" border="ts rs bs ls" space="mtxl mbxl">
					<Block backgroundColor="grey-1" borderColor="grey-6" border="bs" space="ptl prl pbxl pll">
						<Text bold>{this.lang.numUsers}</Text>
						<Block space="mbxl" className={classes.elem('NumberInput').b()}>
							<Button
								color="bright-blue"
								onClick={() => this.props.setLicenses(this.props.numLicenses - 1)}
							>
								<Icon name="minus" />
							</Button>
							<Input
								type="number"
								value={this.props.numLicenses}
								onChange={e => this.props.setLicenses(e.target.value)}
							/>
							<Button
								color="bright-blue"
								onClick={() => this.props.setLicenses(this.props.numLicenses + 1)}
							>
								<Icon name="plus" />
							</Button>
						</Block>
						<Row>
							<Column>
								{t('createAccount.numLicenses.licensePrice', { numLicenses: this.props.numLicenses })}
							</Column>
							<Column align="right">{`${filter('currencyFormat')(
								this.props.numLicenses * 599,
								'SEK'
							)}`}</Column>
						</Row>
						<Block border="bs" borderColor="grey-6" space="mts mbs"></Block>
						<Row>
							<Column>
								<Text bold>{this.lang.totalMonth}</Text>
							</Column>
							<Column align="right">
								<Text bold>{`${filter('currencyFormat')(this.props.numLicenses * 599, 'SEK')}`}</Text>
							</Column>
						</Row>
						<Row>
							<Column>
								<Text bold>{this.lang.totalYear}</Text>
							</Column>
							<Column align="right">
								<Text bold>{`${filter('currencyFormat')(
									this.props.numLicenses * 599 * 12,
									'SEK'
								)}`}</Text>
							</Column>
						</Row>
					</Block>
					<Block space="ptl prl pbl pll">
						<Text size="sm" color="grey-11" className={classes.elem('Terms').b()}>
							{this.lang.terms}
						</Text>
					</Block>
				</Block>
				<WhatsIncludedCard />
				<Button color="bright-blue" block size="xl" gradient onClick={() => this.props.nextPage()}>
					{this.lang.nextButton}
				</Button>
				<Button color="grey" block type="link" size="lg" onClick={() => this.props.closeModal()}>
					{this.lang.iAlreadyHaveAccount}
				</Button>
			</div>
		);
	}
}

const mapStateToProps = state => ({
	numLicenses: state.CreateAccount.numLicenses
});

const mapDispatchToProps = {
	nextPage,
	setLicenses
};

export default connect(mapStateToProps, mapDispatchToProps)(NumLicenses);
