import PropTypes from 'prop-types';
import React from 'react';
import _ from 'lodash';
import { makeCancelable } from '@upsales/components/Utils/CancelablePromise';

const propTypes = {
	user: PropTypes.object.isRequired
};

class Avatar extends React.Component {
	constructor(props) {
		super(props);

		const size = props.size ? props.size : 30;

		this.state = {
			user: props.user,
			size: size,
			avatar: props.avatar
		};
	}

	componentDidMount() {
		const avatarService = Tools.avatarService;
		this.getter = makeCancelable(avatarService.getAvatar(this.props.user));
		// eslint-disable-next-line promise/catch-or-return
		this.getter.promise.then(avatar => {
			this.setState({ avatar: avatar });
		});
	}

	componentWillUnmount() {
		this.getter?.cancel();
	}

	shouldComponentUpdate(newProps, newState) {
		const shallUpdate =
			(newProps.user && newProps.user.id !== this.state.user.id) ||
			newState.avatar.url !== this.state.avatar.url ||
			newProps.avatar.url !== this.props.avatar.url ||
			(newProps.avatar.url && newProps.avatar.url !== this.state.avatar.url);

		if (shallUpdate) {
			return true;
		}

		return false;
	}

	UNSAFE_componentWillUpdate(newProps, newState) {
		if (newProps.user && newProps.user.id) {
			const avatar = this.props.avatar.url ? this.props.avatar : newState.avatar;
			if (newProps.avatar && newProps.avatar.url) {
				avatar.url = newProps.avatar.url;
			}

			if (this.state.avatar.url !== avatar.url) {
				this.setState({
					user: this.props.user,
					avatar: avatar
				});
			}
		}
	}

	render() {
		const { size, avatar } = this.state;
		const { user, className } = this.props;

		if (user && user.name) {
			const tag = avatar.gravatar ? <img className="img" src={avatar.url} onError={this.onError} /> : null;

			const innerStyle = _.merge(
				{
					display: avatar.initials?.length ? 'block' : 'none',
					lineHeight: size + 'px'
				},
				this.props.innerStyle || {}
			);

			const outerStyle = _.merge(
				{
					width: size,
					height: size,
					fontSize: parseInt(size) / 2 + 'px'
				},
				this.props.style || {}
			);

			let classNames = 'generic-avatar round';
			if (className) {
				classNames += ' ' + className;
			}

			return (
				<div id={this.props.id || null} className={classNames} style={outerStyle}>
					<div className="color" style={innerStyle}>
						<span className="name" style={{ lineHeight: size + 'px' }}>
							{avatar.initials}
						</span>
					</div>
					{tag}
				</div>
			);
		}

		return <div />;
	}
}

Avatar.propTypes = propTypes;
Avatar.defaultProps = {
	avatar: {
		initials: '',
		url: '',
		gravatar: false
	}
};

export default Avatar;
