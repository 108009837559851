import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Input, Block, DateInput } from '@upsales/components';
import UpSelect from 'Components/Inputs/UpSelect';

class SelectComponent extends Component {
	constructor(p) {
		super(p);
		const { config } = p;
		this.options = config.options;
		if (config && config.selectOptions) {
			this.options = Tools.AppService.getStaticValues(config.selectOptions);
		}

		this.opts = {
			formatResult: function (obj, el, x, encode) {
				return encode(obj.name);
			},
			formatSelection: function (obj, x, encode) {
				return encode(obj.name);
			}
		};
	}

	render() {
		return (
			<UpSelect
				className="form-control"
				onChange={e => {
					const value = e.target.value;
					this.props.onChange(this.props.multiple ? _.pluck(value, 'id') : value?.id || value);
				}}
				defaultValue={this.props.value}
				options={this.opts}
				data={this.options}
				multiple={this.props.multiple}
				required={this.props.required}
				placeholder={this.props.placeholder}
				icon={this.props.icon}
				state={this.props.state}
				name={this.props.name}
			/>
		);
	}
}

const StringComponent = props => {
	if (!props.isOld) {
		return (
			<Input
				id={props.id}
				value={props.value}
				disabled={props.disabled}
				required={props.required}
				tabIndex={props.tabindex}
				className={props.className}
				onChange={e => props.onChange(e.target.value)}
				placeholder={props.placeholder}
				icon={props.icon}
				state={props.state}
				name={props.name}
			/>
		);
	}
	return (
		<input
			id={props.id}
			value={props.value}
			disabled={props.disabled}
			required={props.required}
			tabIndex={props.tabindex}
			className={props.className}
			onChange={e => props.onChange(e.target.value)}
			placeholder={props.placeholder}
		/>
	);
};

class CurrencyComponent extends Component {
	constructor(props) {
		super(props);
		this.state = { focus: false };
	}

	onBlur() {
		this.setState({ focus: false });
	}

	onFocus() {
		this.setState({ focus: true });
	}

	getValue(value) {
		if (typeof value === 'number') {
			return this.state.focus ? value.toString() : value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ' ');
		} else if (typeof value === 'string') {
			return this.state.focus ? value : value.replace(/\B(?=(\d{3})+(?!\d))/g, ' ');
		} else {
			return '';
		}
	}

	render() {
		const props = this.props;
		const value = this.getValue(props.value);
		const onChange = event => {
			const inputFieldValue = event.target.value;
			const onlyNumbers = inputFieldValue.replace(/[^0-9-]/g, '');
			props.onChange(onlyNumbers);
		};

		if (!props.isOld) {
			return (
				<Input
					id={props.id}
					ref={element => (this.inputElement = element)}
					value={value}
					disabled={props.disabled}
					required={props.required}
					tabIndex={props.tabindex}
					className={props.className}
					onChange={onChange}
					placeholder={props.placeholder}
					onBlur={() => this.onBlur()}
					onFocus={() => this.onFocus()}
					icon={props.icon}
					state={props.state}
					name={props.name}
				/>
			);
		}

		return (
			<input
				id={props.id}
				ref={element => (this.inputElement = element)}
				value={value}
				disabled={props.disabled}
				required={props.required}
				tabIndex={props.tabindex}
				className={props.className}
				onChange={onChange}
				placeholder={props.placeholder}
				onBlur={() => this.onBlur()}
				onFocus={() => this.onFocus()}
			/>
		);
	}
}

const NumberComponent = props => {
	const onChange = e => {
		const inputFieldValue = e.target.value;
		const onlyNumbers = props.allowTags ? inputFieldValue : inputFieldValue.replace(/[^0-9]/g, '');
		props.onChange(onlyNumbers);
	};

	const onWheel = e => {
		e.preventDefault();
	};

	const onBlur = e => {
		e.target.removeEventListener('wheel', onWheel, { passive: false });
	};

	const onFocus = e => {
		e.target.addEventListener('wheel', onWheel, { passive: false });
	};

	if (!props.isOld) {
		return (
			<Input
				id={props.id}
				type="number"
				value={props.value}
				disabled={props.disabled}
				required={props.required}
				tabIndex={props.tabindex}
				className={props.className}
				onChange={onChange}
				// Numbers can only be positive and with no deciamals
				min={0}
				onKeyDown={e => (['+', '-', ',', '.'].includes(e.key) ? e.preventDefault() : null)}
				placeholder={props.placeholder}
				icon={props.icon}
				state={props.state}
				name={props.name}
				onFocus={onFocus}
				onBlur={onBlur}
			/>
		);
	}
	return (
		<input
			id={props.id}
			value={props.value}
			disabled={props.disabled}
			required={props.required}
			tabIndex={props.tabindex}
			className={props.className}
			onChange={onChange}
			placeholder={props.placeholder}
			onFocus={onFocus}
			onBlur={onBlur}
		/>
	);
};

const LinkComponent = props => {
	const onChange = e => {
		const inputFieldValue = e.target.value;
		const notValid =
			inputFieldValue !== '' &&
			inputFieldValue !== undefined &&
			inputFieldValue !== null &&
			!(
				'http://'.indexOf(inputFieldValue.substring(0, 7)) === 0 ||
				'https://'.indexOf(inputFieldValue.substring(0, 8)) === 0 ||
				'mailto:'.indexOf(inputFieldValue.substring(0, 7)) === 0 ||
				'ftp://'.indexOf(inputFieldValue.substring(0, 6)) === 0
			);

		if (notValid) {
			props.onChange(`https://${inputFieldValue}`);
		} else {
			props.onChange(inputFieldValue);
		}
	};
	if (!props.isOld) {
		return (
			<Input
				id={props.id}
				value={props.value || ''}
				disabled={props.disabled}
				required={props.required}
				tabIndex={props.tabindex}
				className={props.className}
				onChange={onChange}
				placeholder={props.placeholder}
				icon={props.icon}
				state={props.state}
				name={props.name}
			/>
		);
	}
	return (
		<input
			id={props.id}
			value={props.value || ''}
			disabled={props.disabled}
			required={props.required}
			tabIndex={props.tabindex}
			className={props.className}
			onChange={onChange}
			placeholder={props.placeholder}
		/>
	);
};

const DateComponent = props => {
	if (props.isOld) {
		return (
			<ReactTemplates.upFilters.components.datepicker
				placeholder={props.placeholder}
				className="form-control"
				value={props.value}
				onChange={props.onChange}
				container="body"
				placement="auto bottom-auto"
				utc={true}
			/>
		);
	}
	return (
		<DateInput
			placeholder={props.placeholder}
			value={props.value || undefined}
			onChange={({ target }) => props.onChange(target.value)}
			icon={props.icon}
			name={props.name}
			state={props.state}
			closeOnSelect={props.closeOnSelect}
		/>
	);
};

const mapValue = function (config, multiple, value) {
	const options = config && config.selectOptions ? window.Tools.AppService.getStaticValues(config.selectOptions) : [];

	if (multiple) {
		return _.map(value, function (value) {
			const selectedOption = _.find(options, { id: value });
			const name = selectedOption ? selectedOption.name : value;

			return { id: value, name: name };
		});
	} else if (value) {
		const selectedOption = _.find(options, { id: value });
		const name = selectedOption ? selectedOption.name : value;

		return { id: value, name: name };
	} else {
		return value;
	}
};

class StandardFieldInput extends Component {
	getComponent() {
		const props = this.props;
		const { config, multiple, value } = props;

		switch (config.type) {
			case 'Select':
			case 'User':
			case 'Users':
				return (
					<SelectComponent
						{...props}
						value={mapValue(config, multiple, value)}
						icon={props.isOld ? null : config.icon.name}
					/>
				);
			case 'Date':
				return (
					<div className="date-component-wrap">
						<DateComponent {...props} icon={props.isOld ? null : config.icon.name} />
					</div>
				);
			case 'Number':
				return <NumberComponent {...props} icon={props.isOld ? null : config.icon.name} />;
			case 'Link':
				return <LinkComponent {...props} icon={props.isOld ? null : config.icon.name} />;
			case 'Currency':
				return <CurrencyComponent {...props} icon={props.isOld ? null : config.icon.name} />;
			case 'String':
			default:
				return <StringComponent {...props} icon={props.isOld ? null : config.icon.name} />;
		}
	}

	render() {
		const { config, isOld } = this.props;
		if (isOld) {
			return (
				<div className="input-group standard-field-input">
					<span className="input-group-addon">
						<i className={config.icon.class}>{config.icon.value}</i>
					</span>
					{this.getComponent()}
				</div>
			);
		}
		return <Block>{this.getComponent()}</Block>;
	}
}

const inputProps = {
	id: PropTypes.string,
	className: PropTypes.string,
	value: PropTypes.any,
	tabindex: PropTypes.number,
	config: PropTypes.object.isRequired,
	required: PropTypes.bool,
	disabled: PropTypes.bool,
	multiple: PropTypes.bool,
	placeholder: PropTypes.string,
	onChange: PropTypes.func.isRequired,
	allowTags: PropTypes.bool,
	icon: PropTypes.string,
	state: PropTypes.string,
	name: PropTypes.string,
	isOld: PropTypes.bool,
	closeOnSelect: PropTypes.bool
};

LinkComponent.propTypes = inputProps;
DateComponent.propTypes = inputProps;
StandardFieldInput.propTypes = inputProps;
StringComponent.propTypes = inputProps;
NumberComponent.propTypes = inputProps;
SelectComponent.propTypes = inputProps;

export default StandardFieldInput;
