import React, { ComponentProps } from 'react';
import { SplitButtonWithActions } from '@upsales/components';
import IconName from '@upsales/components/Icon/IconName';
import { openDrawer } from 'Services/Drawer';
import { useTranslation } from 'Components/Helpers/translate';
import { getSoftDeployAccessFromState } from '../hooks/featureHelper';
import { AccountSelf } from 'App/resources/AllIWant';
import { useSelector } from '../hooks';
type SplitButtonWithActionsProps = ComponentProps<typeof SplitButtonWithActions>;

type Props = Omit<SplitButtonWithActionsProps, 'actions'> & {
	mainAction?: 'appointments' | 'todos' | 'phonecalls';
	createArgs?: { client?: { id: number; name: string }; contact?: { id: number; name: string } };
};

type Action = {
	id: string;
	visible: (accountSelf: AccountSelf) => boolean;
	onClick: (createArgs: Props['createArgs']) => void;
	title: string;
	icon?: IconName;
};

const createActions: Record<string, Action> = {
	todos: {
		id: 'todos',
		visible: (accountSelf: AccountSelf) => {
			return getSoftDeployAccessFromState(accountSelf, 'TODO_LIST');
		},
		icon: 'activity',
		title: 'todo.createTodo',
		onClick: (createArgs: Props['createArgs']) => {
			openDrawer('CreateTodo', {
				client: createArgs?.client,
				contact: createArgs?.contact
			});
		}
	},
	phonecalls: {
		id: 'phonecalls',
		visible: (accountSelf: AccountSelf) => {
			return getSoftDeployAccessFromState(accountSelf, 'TODO_LIST');
		},
		icon: 'phone',
		title: 'todo.planACall',
		onClick: (createArgs: Props['createArgs']) => {
			openDrawer('CreateCall', {
				client: createArgs?.client,
				contact: createArgs?.contact
			});
		}
	},
	appointments: {
		id: 'appointments',
		visible: () => {
			return true;
		},
		icon: 'calendar',
		title: 'todo.bookAppointment',
		onClick: (createArgs: Props['createArgs']) => {
			Tools.$upModal.open('editAppointment', {
				appointment: {
					client: createArgs?.client,
					contacts: createArgs?.contact ? [createArgs?.contact] : []
				}
			});
		}
	},
	activities: {
		id: 'activities',
		icon: 'activity',
		visible: (accountSelf: AccountSelf) => {
			return !getSoftDeployAccessFromState(accountSelf, 'REMOVE_ACTIVITIES');
		},
		title: 'create_activity',
		onClick: (createArgs: Props['createArgs']) => {
			Tools.$upModal.open('editActivity', {
				activity: {
					client: createArgs?.client,
					contacts: createArgs?.contact
				}
			});
		}
	}
};

const CreateTaskButton = ({ size, mainAction = 'phonecalls', createArgs, ...passProps }: Props) => {
	const accountSelf = useSelector(({ App }) => App.accountSelf);
	const { t } = useTranslation();
	return (
		<>
			<SplitButtonWithActions
				{...passProps}
				icon="plus"
				size={size}
				mainAction={mainAction}
				dropdownColor="green"
				actions={Object.values(createActions)
					.filter(i => i.visible?.(accountSelf!) ?? true)
					.map(i => ({ ...i, title: t(i.title), onClick: () => i.onClick(createArgs) }))}
			/>
		</>
	);
};

export default CreateTaskButton;
