import { Block, ButtonSelect, Row } from '@upsales/components';
import BemClass from '@upsales/components/Utils/bemClass';
import React, { useState } from 'react';
import T from 'Components/Helpers/translate';

import './BooleanFilter.scss';

type Props = {
	valueChanged: (value: boolean | null) => void;
};

function BooleanFilter({ valueChanged }: Props) {
	const [value, setValue] = useState(null);

	const classes = new BemClass('BooleanFilter');
	return (
		<Block space="plm ptm prm pbm" className={classes.b()}>
			<Row>
				<ButtonSelect
					value={value}
					onChange={val => {
						setValue(val);
						valueChanged(val);
					}}
					options={[
						{
							value: true,
							title: T('default.yes')
						},
						{
							value: false,
							title: T('default.no')
						},
						{
							value: (null as unknown) as boolean, //TO FIX
							title: T('default.all')
						}
					]}
				/>
			</Row>
		</Block>
	);
}

export default BooleanFilter;
