import { DefaultButton, PrimaryButton } from '@upsales/components/Buttons';
import {
	EllipsisTooltip,
	Tooltip,
	Flex,
	Block,
	Text,
	ButtonBox,
	Headline,
	Table,
	TableHeader,
	TableRow,
	TableColumn,
	Loader,
	Paginator,
	Card
} from '@upsales/components';
import { SlideFade } from 'App/components/animations';
import { makeCancelable } from 'App/babel/helpers/promise';
import BemClass from '@upsales/components/Utils/bemClass';
import logError from 'App/babel/helpers/logError';
import moment from 'moment';
import ProjectPlanResource from 'Resources/ProjectPlan';
import React, { useState, useMemo, useEffect } from 'react';
import RequestBuilder, { comparisonTypes } from 'Resources/RequestBuilder';
import T from 'Components/Helpers/translate';
import useGetData from 'App/components/hooks/useGetData';
import ProjectPlanOptions from 'App/components/ProjectPlanOrderHandler/ProjectPlanOrderHandlerCard';
import ProjectPlanTemplateResource from 'Resources/ProjectPlanTemplate';
import type { Config } from './AgreementFulfillmentModal';
import type ProjectPlan from 'App/resources/Model/ProjectPlan';
import type ProjectPlanTemplate from 'App/resources/Model/ProjectPlanTemplate';

// This is hard coded now. Will fix this in another ticket
const projectStageOptions = [
	{ id: 1, title: 'To Do', name: 'To Do' },
	{ id: 2, title: 'In progress', name: 'In progress' },
	{ id: 3, title: 'Done', name: 'Done' }
];

const fetcher = (
	rb: RequestBuilder,
	{ clientId, sort }: { clientId: number; sort: { field: string; asc: boolean } }
) => {
	rb.addFilter(ProjectPlanResource.attr.client.attr.id, comparisonTypes.Equals, clientId);
	rb.addFilter(ProjectPlanResource.attr.projectPlanStage.attr.id, comparisonTypes.Equals, [
		projectStageOptions[0].id,
		projectStageOptions[1].id
	]);
	rb.addSort({ field: sort.field }, sort.asc);
	return ProjectPlanResource.find(rb.build());
};

type Props = {
	clientId: number;
	config: Config;
	onChange: (config: Config) => void;
	openEditProjectPlan: (projectPlan: ProjectPlan) => void;
};

const Configure = ({ clientId, config, onChange, openEditProjectPlan }: Props) => {
	const classNames = new BemClass('AgreementFulfillmentModal');
	const actions = ['merge', 'spawn', 'skip'] as const;
	const LIMIT = 10 as const;

	const [offset, setOffset] = useState(0);
	const [sort, setSort] = useState({ field: 'startDate', asc: true });
	const [selectedAction, setSelectedAction] = useState(config.action);
	const [projectPlanTemplate, setProjectPlanTemplate] = useState<ProjectPlanTemplate | null>(null);
	const lang = useMemo(
		() => ({
			headline: T('AgreementFulfillmentModal.configHeadline', { productName: config.change.newProduct.name }),
			noExistingProjects: T('AgreementFulfillmentModal.noExistingProjects'),
			choose: T('default.choose'),
			clear: T('default.clear'),
			actions: {
				merge: T('AgreementFulfillmentModal.actions.merge'),
				spawn: T('AgreementFulfillmentModal.actions.spawn'),
				skip: T('AgreementFulfillmentModal.actions.skip')
			},
			columns: {
				name: T('default.name'),
				stage: T('default.stage'),
				startDate: T('default.startDate'),
				endDate: T('default.endDate'),
				projectManager: T('projectPlan.projectManager')
			}
		}),
		[]
	);
	const { loading, data, metadata, refetch } = useGetData({
		fetcher,
		offset,
		limit: LIMIT,
		fetcherProps: {
			clientId,
			sort
		},
		broadcastTypes: ['projectPlan.added', 'projectPlan.deleted', 'projectPlan.updated']
	});

	useEffect(() => refetch(), [sort, offset]);

	useEffect(() => {
		const projectPlanTemplateId = config.change.newProduct.projectPlanTemplate!.id;
		const { promise, cancel } = makeCancelable(ProjectPlanTemplateResource.get(projectPlanTemplateId));
		promise.then(({ data: projectPlanTemplate }) => setProjectPlanTemplate(projectPlanTemplate)).catch(logError);
		return () => cancel();
	}, [config.change.newProduct.projectPlanTemplate!.id]);

	const selectAction = (action: 'merge' | 'spawn' | 'skip') => {
		setSelectedAction(action);
		onChange({ ...config, action: action === 'merge' ? null : action, projectPlanId: null });
	};

	const onSelectProjectPlan = (projectPlan: ProjectPlan) => {
		if (projectPlan.id === config.projectPlanId) {
			onChange({ ...config, action: null, projectPlanId: null });
		} else {
			onChange({ ...config, action: 'merge', projectPlanId: projectPlan.id });
		}
	};

	const onOptionsChanged = (options: Config['options']) => {
		onChange({ ...config, options });
	};

	const columns = [
		{ title: lang.columns.name, sort: 'name' },
		{ title: lang.columns.stage, sort: 'projectPlanStage.name' },
		{ title: lang.columns.startDate, sort: 'startDate', sortType: 'numeric' },
		{ title: lang.columns.endDate, sort: 'endDate', sortType: 'numeric' },
		{ title: lang.columns.projectManager, sort: 'user.name' },
		{ title: '' }
	];

	return (
		<Block>
			<Headline align="left" size="sm">
				{lang.headline}
			</Headline>
			<Flex justifyContent="space-between" space="mtxl">
				{actions.map(action => (
					<Tooltip
						key={action}
						disabled={action !== 'merge' || data.length > 0}
						title={lang.noExistingProjects}
						distance={20}
						position="bottom"
					>
						<ButtonBox
							disabled={loading || (action === 'merge' && !data.length)}
							selected={action === selectedAction}
							onClick={() => selectAction(action)}
							title={lang.actions[action]}
						/>
					</Tooltip>
				))}
			</Flex>
			<Block className={classNames.elem('AnimationContainer').b()}>
				<SlideFade visible={selectedAction === 'spawn'} direction="bottom" maxHeight={220}>
					<Block>
						{projectPlanTemplate ? (
							<ProjectPlanOptions
								onChange={onOptionsChanged}
								value={config.options}
								template={projectPlanTemplate}
								productsNames={config.change.newProduct.name}
								anchorSelector={'.AgreementFulfillmentModal'}
							/>
						) : null}
					</Block>
				</SlideFade>
				<SlideFade visible={selectedAction === 'merge'} direction="bottom" maxHeight={500}>
					<Block>
						<Card space="mtxl">
							<Table>
								<TableHeader
									color="white"
									onSortChange={setSort}
									sorting={sort.field}
									asc={sort.asc}
									columns={columns}
								/>
								{loading ? (
									<TableRow key={0}>
										<TableColumn size="lg" colSpan={5}>
											<Flex justifyContent="center">
												<Loader size="md" />
											</Flex>
										</TableColumn>
									</TableRow>
								) : (
									data.map(projectPlan => (
										<TableRow
											key={projectPlan.id}
											onClick={() => openEditProjectPlan(projectPlan)}
											// @ts-expect-error The typing in the UI library does not include the success prop
											success={projectPlan.id === config.projectPlanId}
										>
											<TableColumn>
												<EllipsisTooltip title={projectPlan.name}>
													<Text size="sm">{projectPlan.name}</Text>
												</EllipsisTooltip>
											</TableColumn>
											<TableColumn>
												<EllipsisTooltip title={projectPlan.projectPlanStage.name}>
													<Text size="sm">{projectPlan.projectPlanStage.name}</Text>
												</EllipsisTooltip>
											</TableColumn>
											<TableColumn>
												<EllipsisTooltip title={moment(projectPlan.startDate).format('L')}>
													<Text size="sm">{moment(projectPlan.startDate).format('L')}</Text>
												</EllipsisTooltip>
											</TableColumn>
											<TableColumn>
												<EllipsisTooltip title={moment(projectPlan.endDate).format('L')}>
													<Text size="sm">{moment(projectPlan.endDate).format('L')}</Text>
												</EllipsisTooltip>
											</TableColumn>
											<TableColumn>
												{projectPlan.user ? (
													<EllipsisTooltip title={projectPlan.user.name}>
														<Text size="sm">{projectPlan.user.name}</Text>
													</EllipsisTooltip>
												) : (
													<Text size="sm">{'-'}</Text>
												)}
											</TableColumn>
											<TableColumn
												align="right"
												onClick={event => {
													event.stopPropagation();
													onSelectProjectPlan(projectPlan);
												}}
											>
												{projectPlan.id === config.projectPlanId ? (
													<PrimaryButton onDark={true}>{lang.clear}</PrimaryButton>
												) : (
													<DefaultButton>{lang.choose}</DefaultButton>
												)}
											</TableColumn>
										</TableRow>
									))
								)}
							</Table>
						</Card>
						{!loading && metadata.total > LIMIT ? (
							<Flex space="mtl" flex={1} justifyContent="center" alignItems="center">
								<Paginator
									limit={LIMIT}
									offset={offset}
									total={metadata.total}
									align="center"
									onChange={setOffset}
								/>
							</Flex>
						) : null}
					</Block>
				</SlideFade>
			</Block>
		</Block>
	);
};

export default Configure;
