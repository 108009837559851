import React, { useMemo } from 'react';
import {
	StaticSelect,
	StaticMultiSelect,
	type StaticSelectConsumerProps,
	type StaticMultiSelectConsumerProps,
	useSelectLanguage
} from 'App/components/Inputs/Selects/StaticSelect';
import { Flag } from '@upsales/components';
import _ from 'lodash';
import { countries } from 'App/helpers/regionHelpers';
import T, { useTranslation } from 'Components/Helpers/translate';

const countryToOption = (country: (typeof countries)[0]) => ({
	id: country.id,
	title: T(country.lang),
	country: country.code
});

type MappedCountry = ReturnType<typeof countryToOption>;

type Props = Pick<StaticSelectConsumerProps<MappedCountry>, 'language' | 'onChange'> & {
	value: string | null;
	availableCountries?: string[];
};
type PropsMulti = Pick<StaticMultiSelectConsumerProps<MappedCountry>, 'language' | 'onChange'> & {
	value: string[];
	availableCountries?: string[];
};

const useSharedProps = ({
	availableCountries = [],
	language
}: Pick<Props | PropsMulti, 'availableCountries' | 'language'>) => {
	const { t } = useTranslation();
	const options = useMemo(() => {
		const ac = availableCountries?.map(c => c.toUpperCase()) || [];
		const rows = ac.length ? countries.filter(z => ac.includes(z.code)) : countries;
		return _.sortBy(rows.map(countryToOption), 'title');
	}, [availableCountries]);
	const updatedLanguage = useSelectLanguage(language);

	return {
		placeholder: t('admin.billing.selectCountry'),
		options,
		language: updatedLanguage,
		renderItemLeft: (item: MappedCountry) => <Flag code={item.country.toLowerCase()} />
	};
};

export const CountrySelect = ({ availableCountries, language, value, ...props }: Props) => {
	const sharedProps = useSharedProps({ availableCountries, language });
	const selected = useMemo(
		() => (value ? sharedProps.options.find(c => c.id === value + ''.toUpperCase()) ?? null : null),
		[value, sharedProps.options]
	);
	return <StaticSelect<MappedCountry> value={selected} {...sharedProps} {...props} virtualized />;
};

export const CountrySelectMulti = ({ availableCountries, language, value, ...props }: PropsMulti) => {
	const sharedProps = useSharedProps({ availableCountries, language });
	const selected = useMemo(() => {
		const v = value?.map(c => c.toUpperCase()) || [];
		return sharedProps.options.filter(c => v.includes(c.id));
	}, [value, sharedProps.options]);
	return <StaticMultiSelect<MappedCountry> {...sharedProps} {...props} value={selected} virtualized />;
};

export default CountrySelect;
