// This will act as an interface between the old service and redux.
import getAngularModule from 'App/babel/angularHelpers/getAngularModule';
import store, { AppThunk, RootState } from 'Store/index';
import {
	setAccountSelf,
	setSelf,
	setAccessRights,
	setTotals,
	setUserMap,
	setRoleMap,
	setBrands,
	setTodoTypes,
	setScripts,
	setAdAccount,
	setJourneySteps,
	setProducts,
	setTopCountries,
	setCustomFields,
	setDocumentTemplates,
	setCategoryTypes,
	setCategories,
	setMetadata,
	setPhoneIntegration,
	setMailIntegration,
	setActivityTypes,
	setProductCategories,
	setStages,
	updateLocale,
	setCustomerId,
	setAllReportViews,
	setProfileImageId,
	setAllListViews,
	resetCustomFields,
	setStaticValues,
	setAcceptTerms,
	setPriceLists,
	setPaymentExtensions,
	setAuthenticated
} from 'Store/actions/AppActions';
import {
	getAccountSelf,
	getSelf,
	getAccessRights,
	getTotals,
	getUserMap,
	getRoleMap,
	getBrands,
	getTodoTypes,
	getScripts,
	getAdAccount,
	getJourneySteps,
	getProducts,
	getTopCountries,
	getCustomFields,
	getReadOnlyCustomFields,
	getDocumentTemplates,
	getCategoryTypes,
	getCategories,
	getMetadata,
	getPhoneIntegration,
	getMailIntegration,
	getCustomerId,
	getActivityTypes,
	getProductCategories,
	getStages,
	getReportViews,
	getListViews,
	getEsignIntegrations,
	getSMSIntegrations,
	getWebinarIntegrations,
	getFileStorageIntegrations,
	getCalendarIntegrations,
	getActiveCalendarIntegrations,
	getSelectedBrand,
	getActiveUsers,
	getRealActiveUsers,
	getSessionTimeoutMs,
	getUsers,
	getRoles,
	getProfileImageId,
	getStaticValues,
	getAcceptTerms,
	getPriceLists,
	getPaymentExtensions
} from 'Store/actions/AppGetterActions';
import { AllIWantData, DocumentTemplate, IntegrationInit } from 'App/resources/AllIWant';
import { AppState, initialState } from 'Store/reducers/AppReducer';
import CustomField from 'App/resources/Model/CustomField';
import ActivityType from 'App/resources/Model/ActivityType';
import OrderStage from 'App/resources/Model/OrderStage';
import ProductCategory from 'App/resources/Model/ProductCategory';
import Category, { CategoryType } from 'App/resources/Model/Category';
import { ThunkDispatch } from 'redux-thunk';
import { AnyAction } from 'redux';
import { batch } from 'react-redux';
import StaticValue from 'App/resources/Model/StaticValue';
import _ from 'lodash';

const dispatch = store?.dispatch as ThunkDispatch<RootState, any, AnyAction>;
let sessionTimer: NodeJS.Timeout;
class AppService {
	public loaded: boolean = false;
	private _isLoggedIn: boolean = false;
	public loadedPromise: Promise<void>;
	private _staticValuesPromise: Promise<{ [type: string]: StaticValue[] }>;
	private _resolveLoaded?: () => void;
	private _resolveStaticValues?: (values: { [type: string]: StaticValue[] }) => void;
	private _rejectStaticValues?: (error: Error) => void;

	constructor() {
		this.loadedPromise = new Promise((resolve, reject) => {
			this._resolveLoaded = resolve;
		});
		this._staticValuesPromise = new Promise((resolve, reject) => {
			this._resolveStaticValues = resolve;
			this._rejectStaticValues = reject;
		});
	}
	updateSession = () => {
		const timeoutMs = dispatch(getSessionTimeoutMs());
		if (sessionTimer) {
			clearTimeout(sessionTimer);
		}

		sessionTimer = setTimeout(function () {
			const $rootScope = getAngularModule('$rootScope');
			$rootScope.$broadcast('UpsalesSessionExpireWarning');
		}, timeoutMs);
	};
	isLoggedIn = () => this._isLoggedIn;
	setIsLoggedIn = (val: boolean) => (this._isLoggedIn = val);
	// Set loaded variable to true when promise is resolved
	setLoaded = () => {
		this._resolveLoaded?.();
		this.loaded = true;
		const $rootScope = getAngularModule('$rootScope');
		$rootScope.$broadcast('AppService.loaded');
	};
	// This should not be needed anymore since the app should not load before all values are done. Try to replace
	setStaticValuesPromise = (promise: Promise<{ data: { [type: string]: StaticValue[] } }>) => {
		promise
			.then(result => {
				this._resolveStaticValues?.(result.data);
			})
			.catch(error => {
				this._rejectStaticValues?.(error);
			});
	};
	getStaticValuesPromise = (type: string) => {
		return this._staticValuesPromise.then(values => {
			if (type === 'all') {
				return _.cloneDeep(values);
			}
			return _.cloneDeep(values[type]);
		});
	};
	setStaticValues = (staticValues: AppState['staticValues']) => {
		dispatch(setStaticValues(staticValues));
	};
	getStaticValues = (type: string) => dispatch(getStaticValues(type));
	getStaticValue = (type: string, id: string) => {
		const staticValues = dispatch(getStaticValues(type));
		return staticValues.find(s => s.id === id) || null;
	};
	setAccountSelf = (accountSelf: AppState['accountSelf']) => {
		dispatch(setAccountSelf(accountSelf));
	};
	// TODO: Can also be null - remove `as AppThunk` part and fix errors
	getAccountSelf = () => dispatch(getAccountSelf() as AppThunk<AllIWantData['self']>);
	setSelf = (self: AppState['self']) => {
		dispatch(setSelf(self));
	};
	// TODO: Can also be null - remove `as AppThunk` part and fix errors
	getSelf = () => dispatch(getSelf() as AppThunk<AllIWantData['customerSelf']>);
	setAccessRights = (ar: AllIWantData['accessRights']) => {
		dispatch(setAccessRights(ar));
	};
	getAccessRights = () => dispatch(getAccessRights());
	setTotals = (totals: AppState['totals']) => {
		dispatch(setTotals(totals));
	};
	getTotals = (key: keyof AppState['totals']) => dispatch(getTotals(key));
	setUserMap = (userMap: AppState['userMap']) => {
		dispatch(setUserMap(userMap));
	};
	getUserMap = () => dispatch(getUserMap());
	setRoleMap = (roleMap: AppState['roleMap']) => {
		dispatch(setRoleMap(roleMap));
	};
	getRoleMap = () => dispatch(getRoleMap());
	setBrands = (brands: AppState['brands']) => {
		dispatch(setBrands(brands));
	};
	getBrands = () => dispatch(getBrands());
	setTodoTypes = (todoTypes: AppState['todoTypes']) => {
		dispatch(setTodoTypes(todoTypes));
	};
	// TODO: Can have undefined properties - remove `as AppThunk` part and fix errors
	getTodoTypes = () => dispatch(getTodoTypes() as AppThunk<AllIWantData['todoTypes']>);
	setScripts = (scripts: AppState['scripts']) => {
		dispatch(setScripts(scripts));
	};
	getScripts = () => dispatch(getScripts());
	setAdAccount = (adAccount: AppState['adAccount']) => {
		dispatch(setAdAccount(adAccount));
	};
	getAdAccount = () => dispatch(getAdAccount());
	setJourneySteps = (journeySteps: AppState['journeySteps']) => {
		dispatch(setJourneySteps(journeySteps));
	};
	getJourneySteps = (all?: boolean) => dispatch(getJourneySteps(all));
	setProducts = (products: AppState['products']) => {
		dispatch(setProducts(products));
	};
	getProducts = (onlyActive?: boolean, skipAuth?: boolean, usePriceLists?: boolean, excludeBundles?: boolean) =>
		dispatch(getProducts(onlyActive, skipAuth, usePriceLists, excludeBundles));
	setTopCountries = (topCountries: AppState['topCountries']) => {
		dispatch(setTopCountries(topCountries));
	};
	getTopCountries = () => dispatch(getTopCountries());
	setCustomFields = (key: string, customFields: CustomField[]) => {
		dispatch(setCustomFields(key, customFields));
	};
	getCustomFields = (type: Parameters<typeof getCustomFields>[0]) => dispatch(getCustomFields(type));
	getReadOnlyCustomFields = (type: Parameters<typeof getReadOnlyCustomFields>[0]) =>
		dispatch(getReadOnlyCustomFields(type));
	setDocumentTemplates = (key: string, documentTemplates: DocumentTemplate[]) => {
		dispatch(setDocumentTemplates(key, documentTemplates));
	};
	getDocumentTemplates = (type: keyof AppState['documentTemplates']) => dispatch(getDocumentTemplates(type));
	setCategoryTypes = (key: string, categoryTypes: CategoryType[]) => {
		dispatch(setCategoryTypes(key, categoryTypes));
	};
	getCategoryTypes = (type: string | number) => dispatch(getCategoryTypes(type));
	setCategories = (key: string, categories: Category[]) => {
		dispatch(setCategories(key, categories));
	};
	getCategories = (type: string, skipAuth?: boolean) => dispatch(getCategories(type, skipAuth));
	setMetadata = (metadata: AppState['metadata']) => {
		dispatch(setMetadata(metadata));
	};
	getMetadata = () => dispatch(getMetadata());
	setPhoneIntegration = (integations?: IntegrationInit[]) => {
		dispatch(setPhoneIntegration(integations));
	};
	getPhoneIntegration = () => dispatch(getPhoneIntegration());
	setMailIntegration = (integations?: IntegrationInit[]) => {
		dispatch(setMailIntegration(integations));
	};
	getMailIntegration = () => dispatch(getMailIntegration());
	getCustomerId = () => dispatch(getCustomerId());
	setActivityTypes = (key: string, types: ActivityType[]) => {
		dispatch(setActivityTypes(key, types));
	};
	getActivityTypes = (type?: 'activity' | 'appointment', rights?: boolean) =>
		dispatch(getActivityTypes(type, rights));
	setProductCategories = (productCategories: ProductCategory[]) => {
		dispatch(setProductCategories(productCategories));
	};
	getProductCategories = (skipAuth?: boolean) => dispatch(getProductCategories(skipAuth));
	setStages = (allStages: OrderStage[]) => {
		dispatch(setStages(allStages));
	};
	setCustomerId = (id: AppState['customerId']) => {
		dispatch(setCustomerId(id));
	};
	updateLocale = () => {
		dispatch(updateLocale());
	};
	getStages = (type?: keyof AppState['stages'], skipAuth?: boolean): OrderStage[] =>
		dispatch(getStages(type, skipAuth)) as OrderStage[];
	getReportViews = (type?: string) => dispatch(getReportViews(type));
	getListViews = (type?: string) => dispatch(getListViews(type));
	getEsignIntegrations = () => dispatch(getEsignIntegrations());
	getSMSIntegrations = () => dispatch(getSMSIntegrations());
	getWebinarIntegrations = () => dispatch(getWebinarIntegrations());
	getFileStorageIntegrations = () => dispatch(getFileStorageIntegrations());
	getCalendarIntegrations = () => dispatch(getCalendarIntegrations());
	getActiveCalendarIntegrations = () => dispatch(getActiveCalendarIntegrations());
	getSelectedBrand = () => dispatch(getSelectedBrand());
	getActiveUsers = (includeSupportUsers?: boolean) => dispatch(getActiveUsers(includeSupportUsers));
	getRealActiveUsers = (includeSupportUsers?: boolean) => dispatch(getRealActiveUsers(includeSupportUsers));
	getUsers = (type?: string, includeApiUsers?: boolean, includeSupportUsers?: boolean) =>
		dispatch(getUsers(type, includeApiUsers, includeSupportUsers));
	getRoles = (type?: string) => dispatch(getRoles(type));
	setAllReportViews = (reportViews: AppState['reportViews']) => {
		dispatch(setAllReportViews(reportViews));
	};
	setProfileImageId = (userId: number, imageId: number) => {
		const self = this.getSelf();
		if (self.id === userId) {
			const $rootScope = getAngularModule('$rootScope');
			$rootScope.$broadcast('profileImage.updated', { id: userId, imageId });
			dispatch(setProfileImageId(userId, imageId));
		}
	};
	setAllListViews = (listViews: AppState['listViews']) => {
		dispatch(setAllListViews(listViews));
	};
	setAcceptTerms = (acceptTerms: AppState['acceptTerms']) => dispatch(setAcceptTerms(acceptTerms));
	getAcceptTerms = () => dispatch(getAcceptTerms());
	setPriceLists = (priceLists: AppState['priceLists']) => dispatch(setPriceLists(priceLists));
	getPriceLists = () => dispatch(getPriceLists());
	setPaymentExtensions = (paymentExtensions: AppState['paymentExtensions']) =>
		dispatch(setPaymentExtensions(paymentExtensions));
	getPaymentExtensions = () => dispatch(getPaymentExtensions());
	getProfileImageId = (userId: number) => dispatch(getProfileImageId(userId));
	reset = () => {
		batch(() => {
			if (sessionTimer) {
				clearTimeout(sessionTimer);
			}
			dispatch(setAuthenticated(false));

			this.setSelf(null);

			this.setAccountSelf(null);

			this.setCustomerId(0);

			this.setMetadata(null);

			this.setAllListViews({
				...initialState.listViews
			});

			this.setProducts([]);

			dispatch(resetCustomFields());

			this.setStages([]);
			this.setIsLoggedIn(false);

			this.setScripts([]);
		});
	};
}

export default new AppService();
