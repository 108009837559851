import { Column, Icon, ModalContent, ModalHeader, Row } from '@upsales/components';
import BemClass from '@upsales/components/Utils/bemClass';
import React, { useEffect, useMemo, useRef, useState } from 'react';
import { ModalProps } from '../Modals/Modals';
import CreateFlowSelectTemplate from './CreateFlowSelectTemplate';
import T from 'Components/Helpers/translate';
import './CreateFlowContent.scss';
import CreateFlowForm from './CreateFlowForm';
import { FormObserverOnFieldChange } from '../FormObserver';
import Flow from 'App/resources/Model/Flow';
import { Height, SlideFade } from 'App/components/animations';
import { PrimaryButton, ThirdButton } from '@upsales/components/Buttons';
import { FormErrorMessages, InputPropMap } from '../FormObserver/FormObserver';
import { Speed } from '../animations/Animate/Animate';
import { FlowTemplateName, FlowTemplates } from 'App/enum/FlowTemplate';
import ModalControls from '@upsales/components/ModalControls';
import openModal from 'App/services/Modal';
import { type EditableFlow } from './CreateFlow';

type Props = {
	flow?: Partial<Flow>;
	flowTemplateName?: FlowTemplateName | null;

	onFormChange: FormObserverOnFieldChange;
	inputProps: InputPropMap<EditableFlow>;
	errorMessages: FormErrorMessages;
	setPristine: () => void;
	close: ModalProps['close'];
	saving: boolean;
};

const CreateFlowContent = ({
	close,
	flow: initialFlow,
	flowTemplateName,
	onFormChange,
	inputProps,
	errorMessages,
	setPristine,
	saving
}: Props) => {
	const isEdit = !!initialFlow?.id;
	const canChangeSegment = !initialFlow?.segment?.id;
	const [showTemplates, setShowTemplates] = useState(
		!isEdit && !(initialFlow?.path || initialFlow?.name || flowTemplateName === null)
	);
	const classes = useMemo(() => new BemClass('CreateFlowContent'), []);
	const anchor = useRef<HTMLDivElement>(null);

	const selectTemplate = (templateName: FlowTemplateName | null) => {
		if (Tools.FeatureHelper.hasSoftDeployAccess('FLOW_TEMPLATES') && templateName) {
			openModal('CreateTemplateFlow', {
				flowTemplateName: templateName,
				segment: initialFlow?.segment,
				onClose: (flow?: Flow) => {
					if (flow) {
						if (flow.segment) {
							Tools.$state.go('editFlow', {
								segmentId: flow.segment.id,
								showSelectionTooltip: !initialFlow?.segment
							});
						} else {
							Tools.$state.go('editFlowStandalone', { id: flow.id });
						}
						close();
					}
				}
			});
		} else {
			const template = templateName ? FlowTemplates[templateName] : null;
			onFormChange('templateName', templateName, () => {
				onFormChange('name', T(template?.title || ''), () => setPristine());
			});
			setShowTemplates(false);
		}
	};

	// If we got a preselected template on open
	useEffect(() => {
		if (flowTemplateName) {
			selectTemplate(flowTemplateName);
		}
	}, []);

	const startFromScratch = (onFormChange: FormObserverOnFieldChange, setPristine: () => void) => {
		onFormChange('name', '', () => setPristine());
		setShowTemplates(false);
	};

	return (
		<div className={classes.b()} ref={anchor}>
			<ModalHeader title={T('flow.createFlow')} onClose={() => close()} />
			<ModalContent>
				<Height
					visible={showTemplates}
					maxHeight={432}
					delayInMs={showTemplates ? Speed.normal * 0.2 : 0}
					delayOutInMs={showTemplates ? Speed.normal : 0}
				>
					<div className={classes.elem('page').b()}>
						<CreateFlowSelectTemplate onSelect={selectTemplate} visible={showTemplates} />
					</div>
				</Height>

				<Height
					visible={!showTemplates}
					maxHeight={inputProps.templateName?.value ? 533 : 369}
					delayInMs={showTemplates ? Speed.normal : 0}
					delayOutInMs={!showTemplates ? Speed.normal : 0}
				>
					<div className={classes.elem('page').b()}>
						<CreateFlowForm
							visible={!showTemplates}
							inputProps={inputProps}
							onFormChange={onFormChange}
							errorMessages={errorMessages}
							canChangeSegment={canChangeSegment}
							anchor={anchor}
						/>
					</div>
				</Height>
			</ModalContent>
			<ModalControls>
				<Row>
					<Column>
						<SlideFade direction="left" visible={!isEdit && !showTemplates}>
							<ThirdButton
								onClick={() => {
									selectTemplate(null);
									setShowTemplates(true);
								}}
								className={classes.elem('change-template').b()}
							>
								<Icon name="chevron-left" space="mrs" />
								{T('mail.changeTemplateConfirm')}
							</ThirdButton>
						</SlideFade>
					</Column>
					<Column align="right">
						{showTemplates ? (
							<PrimaryButton key="next" onClick={() => startFromScratch(onFormChange, setPristine)}>
								{T('flow.newFlow.createCustomFlow')}
							</PrimaryButton>
						) : (
							<PrimaryButton key="submit" submit loading={saving}>
								{T(isEdit ? 'default.save' : 'flow.startEditing')}
							</PrimaryButton>
						)}
						<ThirdButton onClick={() => close()}>{T('default.cancel')}</ThirdButton>
					</Column>
				</Row>
			</ModalControls>
		</div>
	);
};

export default CreateFlowContent;
