import Attribute from './Attribute';

const OnboardingStepCompletedAttributes = {
	id: new Attribute({
		type: Attribute.types.Number,
		field: 'id'
	}),
	userId: new Attribute({
		type: Attribute.types.Number,
		field: 'userId'
	}),
	customerId: new Attribute({
		type: Attribute.types.Number,
		field: 'customerId'
	}),
	stepId: new Attribute({
		type: Attribute.types.Number,
		field: 'stepId'
	}),
	completedDate: new Attribute({
		type: Attribute.types.Date,
		field: 'completedDate'
	})
};

window.OnboardingStepCompletedAttributes = OnboardingStepCompletedAttributes;

export default OnboardingStepCompletedAttributes;
