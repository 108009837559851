import Role from 'App/resources/Model/Role';
import { FilterConfig } from 'App/babel/filterConfigs/FilterConfig';

type GenericFilterItem = {
	$$selected: boolean;
};

export type UserSelfFilterItem = GenericFilterItem & {
	id: 'self';
	email: string;
	name: string;
	administrator: boolean | 0 | 1;
	active: boolean | 0 | 1;
};

export type UserFilterItem = GenericFilterItem & {
	id: number;
	email: string;
	name: string;
	administrator: boolean | 0 | 1;
	active: boolean | 0 | 1;
	role: null | Pick<Role, 'id' | 'name'>;
	ghost: boolean | 0 | 1;
	userCellPhone: null | string;
	billingAdmin: boolean | 0 | 1;
	apiUser: boolean | 0 | 1;
	$depth: number;
};

export type RoleFilterItem = GenericFilterItem & {
	$$length: number;
	$$depth: number;
	$id: number;
	accessTemplate: number;
	children: (UserFilterItem | UserSelfFilterItem)[];
	defaultCurrency: string;
	defaultSalesboardId: null | number;
	description: string;
	isRole: true;
	name: string;
	parent: null | RoleFilterItem;
};

export type CurrencyFilterItem = GenericFilterItem & {
	active: boolean | 0 | 1;
	id: string;
	iso: string;
	masterCurrency: boolean | 0 | 1;
	rate: number;
};

export type CategoryFilterItem = GenericFilterItem & {
	$hasAccess: boolean;
	categoryType: number;
	id: number;
	name: string;
	roles: RoleFilterItem[];
};

export type CustomFilterItem = GenericFilterItem & {
	id: string;
	name: string;
};

export type StageFilterItem = GenericFilterItem & {
	id: number;
	name: string;
	probability: number;
	exclude: boolean;
	roles: RoleFilterItem[];
	userRemovable: boolean;
	userEditable: boolean;
	$hasAccess: boolean;
	$sortId: number;
};

export type FilterItemWithId =
	| UserSelfFilterItem
	| UserFilterItem
	| CurrencyFilterItem
	| CategoryFilterItem
	| CustomFilterItem
	| StageFilterItem;
export type FilterItem = FilterItemWithId | RoleFilterItem;
export type FilterItemValue = number | string;

export const isRoleFilter = (filterItem: any): filterItem is RoleFilterItem => {
	return filterItem.children;
};

export type StageGroupFilterItem = {
	data: StageFilterItem[];
	isGroup: boolean;
	title: string;
	options: {
		secondary: {
			text: string;
			suffix: string;
		};
	};
};

export const isStageGroups = (
	filterConfig: FilterConfig,
	dataPromiseData: any
): dataPromiseData is StageGroupFilterItem[] => {
	return filterConfig.filterName === 'Stage' && dataPromiseData.length === 2;
};
