import ProspectingAIBoxLogic from 'App/babel/components/Prospecting/ProspectingAI/ProspectingAIBoxLogic';
import { Country } from 'App/services/prospectingService';

export enum Boxes {
	AI = 1,
	Startups,
	HiringAtAFastRate,
	ProfitableCompanies,
	InsideCompanies,
	LostCustomersLast3Years, // Existing box
	NotContactedLast2Years, // Existing box
	LostOpportunitiesOlderThan1year, // Existing box
	CEOOnBoard,
	SimilarTo
}

export const AIBoxes = [
	{
		id: Boxes.Startups,
		name: 'startups',
		supportedCountries: ['SE', 'GB', 'NO'],
		title: 'prospecting.box.startups',
		run: ProspectingAIBoxLogic.getNewStartUps,
		defaultSort: {
			sorting: 'profitMargin',
			asc: false
		},
		hasAccess: () => true,
		img: {
			src: 'img/startups.svg',
			style: {
				bottom: 10,
				height: '100%'
			}
		}
	},
	{
		id: Boxes.AI,
		name: 'ai',
		supportedCountries: ['SE', 'GB', 'NO'],
		title: 'prospecting.box.ai',
		run: ProspectingAIBoxLogic.ai,
		defaultSort: {
			sorting: '',
			asc: true
		},
		hasAccess: () => true,
		img: {
			src: 'img/moneyTap.svg',
			style: {
				height: '120%',
				top: 5,
				right: 5
			}
		}
	},
	{
		id: Boxes.SimilarTo,
		name: 'similarTo',
		supportedCountries: ['SE', 'GB', 'NO'],
		title: 'prospecting.box.similarTo',
		run: ProspectingAIBoxLogic.ai,
		defaultSort: {
			sorting: '',
			asc: true
		},
		hasAccess: () => false,
		img: {
			src: 'img/moneyTap.svg',
			style: {
				height: '120%',
				top: 5,
				right: 5
			}
		}
	},
	{
		id: Boxes.InsideCompanies,
		name: 'insideCompanyGroups',
		supportedCountries: ['SE', 'NO'],
		title: 'prospecting.box.companiesInside',
		run: ProspectingAIBoxLogic.insideCompanyGroups,
		defaultSort: {
			sorting: 'name',
			asc: true
		},
		hasAccess: () => Tools.FeatureHelper.hasSoftDeployAccess('PROSPECTING_INSIDE_COMPANY_GROUPS'),
		img: {
			src: 'img/insideCompanyGroups.svg',
			style: {
				right: -30
			}
		}
	},
	{
		id: Boxes.ProfitableCompanies,
		name: 'profitableCompanies',
		supportedCountries: ['SE', 'GB', 'NO'],
		title: 'prospecting.box.profitableCompanies',
		run: ProspectingAIBoxLogic.profitableCompanies,
		defaultSort: {
			sorting: 'profitMargin',
			asc: false
		},
		hasAccess: () => true,
		img: {
			src: 'img/topIndustries.svg',
			style: {
				right: -30
			}
		}
	},
	{
		id: Boxes.HiringAtAFastRate,
		name: 'hiringAtAFastRate',
		supportedCountries: ['SE', 'GB', 'NO'],
		title: 'prospecting.box.hiringAtAFastRate',
		run: ProspectingAIBoxLogic.hiringAtAFastRate,
		defaultSort: {
			sorting: 'noEmployeesPercentChange2Year',
			asc: false
		},
		hasAccess: () => true,
		img: {
			src: 'img/hiring.svg',
			style: {
				right: -30
			}
		}
	},
	{
		id: Boxes.CEOOnBoard,
		name: 'ceoOnBoard',
		supportedCountries: ['SE', 'NO'],
		title: 'prospecting.box.ceoOnBoard',
		defaultSort: {
			sorting: 'name',
			asc: true
		},
		run: ProspectingAIBoxLogic.ceoOnBoard,
		hasAccess: () => Tools.FeatureHelper.hasSoftDeployAccess('PROSPECTING_CEO_ON_BOARD'),
		img: {
			src: 'img/boardmember.svg'
		}
	}
];

type AIBoxConfig = (typeof AIBoxes)[0];

export function getAvailableAIBoxesForCountry(country: Country) {
	return AIBoxes.filter(box => box.hasAccess() && box.supportedCountries.includes(country));
}

export const AIBoxesIdMap = AIBoxes.reduce<{ [id: number]: AIBoxConfig }>((AIBoxesIdMap, box) => {
	AIBoxesIdMap[box.id] = box;
	return AIBoxesIdMap;
}, {});

export const ExisitingBoxes = [
	{
		id: Boxes.LostCustomersLast3Years,
		name: 'lostCustomersLast3Years',
		hasAccess: () => true,
		img: {
			src: 'img/lostCusomter.svg',
			style: {
				right: -35
			}
		}
	},
	{
		id: Boxes.NotContactedLast2Years,
		name: 'notContactedLast2Years',
		hasAccess: () => true,
		img: {
			src: 'img/notContacted.svg',
			style: {
				right: -10
			}
		}
	},
	{
		id: Boxes.LostOpportunitiesOlderThan1year,
		name: 'lostOpportunitiesOlderThan1year',
		hasAccess: () => true,
		img: {
			src: 'img/lostOpportunities.svg',
			style: {
				right: -30,
				height: '105%'
			}
		}
	}
];

type ExisitingBoxConfig = (typeof ExisitingBoxes)[0];

export const ExisitingBoxesIdMap = ExisitingBoxes.reduce<{ [id: number]: ExisitingBoxConfig }>(
	(ExisitingBoxesIdMap, box) => {
		ExisitingBoxesIdMap[box.id] = box;
		return ExisitingBoxesIdMap;
	},
	{}
);
