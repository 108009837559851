import React, { useMemo } from 'react';
import { type SelectOptionType } from './TreeSelect';
import TreeSelect from './TreeSelect';
import BemClass from '@upsales/components/Utils/bemClass';

type OptionWithParent = {
	id: number;
	title: string;
	parent?: OptionWithParent | null;
};

type Props = {
	options: OptionWithParent[];
	selectedOptions: Pick<OptionWithParent, 'id'>[];
} & Omit<React.ComponentProps<typeof TreeSelect>, 'options'>;

const TreeSelectParentStyle = (props: Props) => {
	const { options = [], selectedOptions = [], ...rest } = props;

	const classes = new BemClass('TreeSelectParentStyle');

	const tree = useMemo(() => {
		// Create a lookup map to store nodes by their IDs
		type LookupType = SelectOptionType & { children: SelectOptionType[]; parentId?: number };
		const lookup: {
			[id: number]: LookupType;
		} = {};

		const selectedIdSet = new Set(selectedOptions.map(option => option.id));

		// Initialize nodes
		options.forEach(option => {
			lookup[option.id] = {
				id: option.id,
				title: option.title,
				parentId: option.parent?.id,
				checked: selectedIdSet.has(option.id),
				children: []
			};
		});

		const setParentCheckedHalf = (node: LookupType) => {
			if (node.checkedHalf) {
				return;
			}

			node.checkedHalf = true;
			const parent = lookup[node.parentId ?? 0];

			if (parent) {
				setParentCheckedHalf(parent);
			}
		};

		// Build the tree structure by linking children to parents
		const tree: SelectOptionType[] = [];

		options.forEach(option => {
			const currentNode = lookup[option.id];

			if (option.parent) {
				const parentNode = lookup[option.parent.id];

				if (parentNode) {
					parentNode.children.push(currentNode);

					if (!currentNode.checked) {
						setParentCheckedHalf(parentNode);
					}
				}
			} else {
				// If the option has no parent, it's a root node
				tree.push(currentNode);
			}
		});

		return tree;
	}, [options, selectedOptions]);

	return (
		<div className={classes.b()}>
			<TreeSelect options={tree} {...rest} />
		</div>
	);
};

export default TreeSelectParentStyle;
