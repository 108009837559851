// This should move to ui-components in another ticket, also the typing should be improved then
import { Block, Icon, Input, Label, Text, TimeInput } from '@upsales/components';
import BemClass from '@upsales/components/Utils/bemClass';
import T from 'Components/Helpers/translate';
import { InputProps } from '../FormObserver';
import React, { useMemo } from 'react';

import './FormComponent.scss';

type FormComponentProps<T = string> = Omit<React.ComponentProps<typeof Block>, 'children'> & {
	children: React.ReactNode;
	label?: string;
	state?: React.ComponentProps<typeof Input>['state'];
	message?: string | null;
	hideMessage?: boolean; // If true, will not render message element. Useful when you aren't going to show any messages and don't want it to take up space.
	required?: boolean;
	maxLength?: React.ComponentProps<typeof Input>['maxLength'];
	maxLengthText?: string;
	value?: T;
	labelRenderRight?: () => JSX.Element;
};

type FormComponentInheritedProps<T> = Omit<FormComponentProps<T>, 'children'> & {};

export function FormComponent<T = string>({
	children,
	label,
	className,
	message,
	hideMessage,
	state,
	required,
	maxLength,
	maxLengthText,
	value,
	labelRenderRight,
	...blockProps
}: FormComponentProps<T>) {
	const classes = useMemo(() => new BemClass('FormComponent', className), [className]);
	const { messageIcon, messageIconColor } = useMemo(
		() =>
			({
				error: { messageIcon: 'ban' as const, messageIconColor: 'red' },
				focus: { messageIcon: undefined, messageIconColor: undefined },
				warning: { messageIcon: 'exclamation-triangle' as const, messageIconColor: 'orange' },
				success: { messageIcon: 'check' as const, messageIconColor: 'success-4' },
				none: { messageIcon: undefined, messageIconColor: undefined }
			}[state || 'none']),
		[state]
	);

	return (
		<Block {...blockProps} className={classes.b()}>
			<div className={classes.elem('labels-container').mod({ 'has-progress': maxLength }).b()}>
				<Label
					maxLength={maxLength}
					value={typeof value === 'string' ? value : undefined}
					maxLengthReachedText={maxLength ? maxLengthText ?? T('default.characterLimitReached') : undefined}
					required={required}
					className={classes.elem('label').b()}
				>
					{label}
				</Label>
				{labelRenderRight?.()}
			</div>
			{children}
			{hideMessage ? null : (
				<Text color="grey-11" size="sm" className={classes.elem('message').b()}>
					{messageIcon ? <Icon name={messageIcon} space="mrs" color={messageIconColor} /> : null}
					{message}
				</Text>
			)}
		</Block>
	);
}

function isTextInput(inputProps: any): inputProps is React.ComponentProps<typeof Input> {
	return typeof inputProps.value === 'string';
}

function inputPropsToProps<T = string>(
	inputProps: React.ComponentProps<typeof Input | typeof TimeInput> = {}
): FormComponentInheritedProps<T> {
	return {
		state: inputProps.state,
		required: inputProps.required,
		value: inputProps.value as T,
		maxLength: isTextInput(inputProps) ? inputProps.maxLength : undefined
	};
}

type FormInputProps<T = string> = FormComponentInheritedProps<string> & {
	inputProps?: Omit<React.ComponentProps<typeof Input>, 'value'> & InputProps<T>;
};

export const FormInput: React.FC<FormInputProps> = ({ inputProps, ...props }) => {
	return (
		<FormComponent {...props} {...inputPropsToProps(inputProps)}>
			<Input {...inputProps} />
		</FormComponent>
	);
};

type FormTimeInputProps<T> = FormComponentInheritedProps<T> & {
	inputProps?: React.ComponentProps<typeof TimeInput> & { value: T };
};

export function FormTimeInput<T = string>({ inputProps, ...props }: FormTimeInputProps<T>) {
	return (
		<FormComponent {...props} {...inputPropsToProps(inputProps)}>
			{/* Had to do like this as the typing in the library sucks. I will have a look at that */}
			<TimeInput<T>
				{...{
					...inputProps,
					value: inputProps?.value as T | undefined,
					min: inputProps?.min as T | undefined,
					max: inputProps?.max as T | undefined
				}}
			/>
		</FormComponent>
	);
}

export function FormNumberInput({ inputProps, ...props }: FormInputProps<number>) {
	const inputPropsWithNumberValue = {
		...inputProps,
		value: (inputProps?.value as number | undefined)?.toString()
	};

	return (
		<FormComponent {...props} {...inputPropsToProps(inputPropsWithNumberValue)}>
			<Input {...inputPropsWithNumberValue} />
		</FormComponent>
	);
}
