import User from 'App/resources/Model/User';
import Actions from './Actions';
import TicketResource from 'App/resources/Ticket';
import CommentResource from 'App/babel/resources/Comment';
import type Comment from 'App/resources/Model/Comment';
import { type File as FileType } from 'App/resources/Model/Comment';
import type Ticket from 'App/resources/Model/Ticket';
import EventResource from 'App/resources/Event';
import ClientResource from 'App/resources/Client';
import type Event from 'App/resources/Model/Event';
import RequestBuilder, { comparisonTypes } from 'Resources/RequestBuilder';
import EventAttributes from 'App/babel/attributes/EventAttributes';
import { EVENT_TIMELINE_ENTITY_TYPES } from 'App/babel/enum/timeline';
import logError from 'Helpers/logError';
import NotificationService from 'App/babel/NotificationService';
import ContactResource from 'Resources/Contact';
import SupportEmailResource from 'App/resources/SupportEmail';
import SupportEmail from 'App/resources/Model/SupportEmail';
import LZString from 'lz-string';
import { mapCustomValuesToArray, mapCustomValuesToObject } from 'App/components/FormObserver';
import CustomField from 'App/resources/Model/CustomField';
import store from 'Store/index';
import { getCustomFieldsFromState } from 'Store/selectors/AppSelectors';
import _ from 'lodash';
import { type TokenItem } from '@upsales/components/Utils/selectHelpers';
import TicketResponseTemplate from 'App/resources/Model/TicketResponseTemplate';
import TicketResponseTemplateResource from 'App/resources/TicketResponseTemplate';
import moment from 'moment';

type UserIdName = Pick<User, 'id' | 'name'>;

const COMMENT_LIMIT = 20;

export type State = {
	ticket: any;
	offset: number;
	filter: 'all' | 'internal' | 'public';
	events: (Event & { files?: FileType[] })[];
	comment: string;
	loading: boolean;
	saving: boolean;
	savingComment: boolean;
	publicMessage: string;
	hasMoreEvents: boolean;
	replyMode: 'internal' | 'public';
	sendingPublicMessage: boolean;
	files: { size: number; type: string; filename: string; id: number; blob: File }[];
	supportEmails: SupportEmail[];
	canSendPublicMessage: boolean;
	showClosedScreen: boolean;
	isValid: boolean;
	errorMessages: { [key: string]: string | null };
	ticketHash: string;
	customFields: CustomField[];
	formStateResetTrigger: boolean; // Used to reset FormObserver state
	hasUnsavedChanges: boolean;
	ticketResponseTemplate: TicketResponseTemplate | undefined;
	totalFileSize: number; // Combined size of all attachments
};

const getTicketHash = (ticket: Ticket) => {
	const custom = ticket.custom
		.filter(({ value }) => value !== null)
		.sort((a, b) => a.fieldId - b.fieldId)
		.map(({ fieldId, value }) => `${fieldId}:${value}`);
	const involved = ticket.involved.map(i => `${i.type}:${i.email}:${i.contact?.id ?? ''}`);
	const compareObj = {
		client: ticket.client?.id ?? null,
		involved,
		title: ticket.title,
		isArchived: ticket.isArchived,
		priority: ticket.priority,
		status: ticket.status.id,
		type: ticket.type?.id,
		user: ticket.user?.id ?? null,
		custom
	};
	return LZString.compressToBase64(JSON.stringify(compareObj));
};

export const getMainRecipient = (involved: Ticket['involved']) => {
	return involved.find(i => i.type === 'TO');
};

export const getCustomFields = (ticketTypeId: number) => {
	const customFields = getCustomFieldsFromState(store.getState().App, 'ticket').filter(cf => {
		if (cf.types?.length) {
			return cf.types.some(type => type.id === ticketTypeId);
		}
		return true;
	});

	return customFields;
};

export const formatErrorMessages = (errorMessages: State['errorMessages']) => {
	return Object.values(errorMessages)
		.filter(error => typeof error === 'string')
		.join('\n');
};

export type Dispatch = (action: any) => void;

export const init = (dispatch: Dispatch, state: State) => async (ticketId?: number) => {
	dispatch({ type: Actions.SET_LOADING, loading: true });
	const [ticket, { data: supportEmails }] = await Promise.all([
		ticketId ? TicketResource.get(ticketId).then(({ data }) => data) : Promise.resolve(null),
		SupportEmailResource.find().catch(err => {
			logError(err, 'Error fetching support emails');
			return { data: [] };
		})
	]);

	if (ticketId && ticket) {
		const ticketHash = getTicketHash(ticket);
		const customFields = getCustomFields(ticket.type.id);
		dispatch({ type: Actions.SET_STATE, ticket, ticketHash, customFields });
		// The user can only send a public message if the emailUsed field of the ticket can be connected to a support email
		dispatch({
			type: Actions.SET_CAN_SEND_PUBLIC_MESSAGE,
			canSendPublicMessage: !!supportEmails.find(e => e.email === ticket.emailUsed)
		});
	} else {
		// If this is a new ticket, we need to update it with a valid support email if we have one
		if (supportEmails.length) {
			// Pick the first one for now. In the future, we might implement so that users can set a default support email
			dispatch({ type: Actions.SET_TICKET, ticket: { ...state.ticket, emailUsed: supportEmails[0].email } });
			// Users can only send a public message if there ar any support emails
			dispatch({
				type: Actions.SET_CAN_SEND_PUBLIC_MESSAGE,
				canSendPublicMessage: true
			});
		}
	}
	dispatch({ type: Actions.SET_SUPPORT_EMAILS, supportEmails });
	dispatch({ type: Actions.SET_LOADING, loading: false });
};

export const setEmailUsed = (dispatch: Dispatch, state: State) => (emailUsed: string) => {
	dispatch({ type: Actions.SET_TICKET, ticket: { ...state.ticket, emailUsed } });
};

export const setTicket = (dispatch: Dispatch, state: State) => (ticket: Ticket) => {
	dispatch({ type: Actions.SET_TICKET, ticket });
};

export const save =
	(dispatch: Dispatch, state: State) =>
	async (skipEmail = false) => {
		dispatch({ type: Actions.SET_SAVING, saving: true });

		const ticket = { ...state.ticket };

		if (!state.ticket.id) {
			ticket.description = state.publicMessage;
		}

		const savedTicket = await TicketResource.save(
			{ ...ticket, attachments: state.files },
			{ params: { skipEmail } }
		);

		dispatch({ type: Actions.SET_SAVING, saving: false });
		return savedTicket;
	};

export const deleteTicket = (dispatch: Dispatch, state: State) => async () => {
	dispatch({ type: Actions.SET_SAVING, saving: true });
	try {
		await TicketResource.delete(state.ticket.id);
	} catch (err) {
		logError(err, 'Error deleting ticket');
	} finally {
		dispatch({ type: Actions.SET_SAVING, saving: false });
	}
};

const setTicketResponseTemplateLastUsedDate = (state: State) => {
	if (state.ticketResponseTemplate) {
		TicketResponseTemplateResource.save(
			{
				lastUsedDate: moment().utc().format('YYYY-MM-DD HH:mm:ss'),
				id: state.ticketResponseTemplate.id
			},
			{ skipNotification: true }
		).catch(err => {
			logError(err, 'Failed to update ticket response template last used date');
		});
	}
};

export const setUserAndSave = (dispatch: Dispatch, state: State) => (user: UserIdName) => {
	dispatch({ type: Actions.SET_USER, user });

	dispatch({ type: Actions.SET_SAVING, saving: true });
	return TicketResource.save(
		{ id: state.ticket.id, user: { id: user.id, name: user.name, email: '' } },
		{ skipNotification: true }
	)
		.then(() => {
			dispatch({ type: Actions.SET_SAVING, saving: false });
		})
		.catch(err => {
			logError(err, 'Error saving ticket user');
			dispatch({ type: Actions.SET_SAVING, saving: false });
		});
};

const fetchEvents = async (state: State, newOffset?: number) => {
	const { ticket, offset } = state;
	const commentRb = new RequestBuilder();
	commentRb.addFilter({ field: 'ticketId' }, comparisonTypes.Equals, ticket.id);
	if (state.filter === 'internal') {
		commentRb.addFilter({ field: 'isPublic' }, comparisonTypes.Equals, false);
	} else if (state.filter === 'public') {
		commentRb.addFilter({ field: 'isPublic' }, comparisonTypes.Equals, true);
	}
	commentRb.addSort({ field: 'regDate' }, false);
	commentRb.addSort({ field: 'id' }, false);
	commentRb.limit = COMMENT_LIMIT;
	commentRb.offset = newOffset ?? offset;

	const { data: comments, metadata } = await CommentResource.find(commentRb.build());

	if (!comments.length) {
		return { data: [], metadata };
	}

	const rb = new RequestBuilder();
	rb.addFilter(EventAttributes.entityType, comparisonTypes.Equals, EVENT_TIMELINE_ENTITY_TYPES.COMMENT);
	rb.addFilter(
		EventAttributes.entityId,
		comparisonTypes.Equals,
		comments.map((c: Comment) => c.id)
	);
	rb.addSort(EventAttributes.date, false);
	rb.addSort(EventAttributes.id, false);
	const { data: events } = await EventResource.find(rb.build());

	events.forEach((event: State['events'][number]) => {
		const comment = comments.find((c: Comment) => c.id === event.entityId);
		if (comment) {
			event.files = comment.files;
		}
	});

	return { data: events, metadata };
};

export const getEvents = (dispatch: Dispatch, state: State) => async () => {
	const { data: events, metadata } = await fetchEvents(state);
	dispatch({ type: Actions.SET_EVENTS, events });
	if (metadata.total > metadata.offset + metadata.limit) {
		dispatch({ type: Actions.SET_HAS_MORE_EVENTS, hasMoreEvents: true });
	}
};

export const getMoreEvents = (dispatch: Dispatch, state: State) => async () => {
	const { events, offset } = state;
	const { data: newEvents, metadata } = await fetchEvents(state, offset + COMMENT_LIMIT);

	if (!newEvents.length) {
		return;
	}

	dispatch({ type: Actions.SET_EVENTS, events: [...events, ...newEvents] });
	if (metadata.total > metadata.offset + metadata.limit) {
		dispatch({ type: Actions.SET_HAS_MORE_EVENTS, hasMoreEvents: true });
	} else {
		dispatch({ type: Actions.SET_HAS_MORE_EVENTS, hasMoreEvents: false });
	}
	dispatch({ type: Actions.SET_OFFSET, offset: offset + metadata.limit });
};

export const addEvent = (dispatch: Dispatch, state: State) => (event: Event) => {
	if (
		(event.comment?.isPublic && state.filter === 'internal') ||
		(!event.comment?.isPublic && state.filter === 'public')
	) {
		return;
	}
	dispatch({ type: Actions.SET_EVENTS, events: [...state.events, event] });
};

export const setComment = (dispatch: Dispatch, state: State) => (comment: string) => {
	dispatch({ type: Actions.SET_COMMENT, comment: comment.replace(/\n{2,}/g, '\n') });
};

export const setPublicMessage = (dispatch: Dispatch, state: State) => (publicMessage: string) => {
	dispatch({ type: Actions.SET_PUBLIC_MESSAGE, publicMessage });
};

export const addComment = (dispatch: Dispatch, state: State) => async (isPublic: boolean) => {
	try {
		dispatch({ type: Actions.SET_SAVING_COMMENT, savingComment: true });

		const message = isPublic ? state.publicMessage : state.comment.replace(/\n\n/g, '\n');

		if (!message.trim().length && !state.files.length) {
			dispatch({ type: Actions.SET_SAVING_COMMENT, savingComment: false });
			return;
		}

		await TicketResource.addComment(state.ticket.id, message, state.files, isPublic);

		if (isPublic) {
			dispatch({ type: Actions.SET_PUBLIC_MESSAGE, publicMessage: '' });
			NotificationService.add({
				icon: 'check',
				style: NotificationService.style.SUCCESS,
				title: 'ticket.emailSent'
			});
			setTicketResponseTemplateLastUsedDate(state);
		} else {
			dispatch({ type: Actions.SET_COMMENT, comment: '' });
		}

		if (state.files.length) {
			dispatch({ type: Actions.SET_FILES, files: [] });
		}
	} catch (err) {
		if (isPublic) {
			logError(err, 'Error sending public reply');
			NotificationService.add({
				icon: 'times',
				style: NotificationService.style.ERROR,
				title: 'ticket.errorSendingEmail'
			});
		} else {
			logError(err, 'Error saving ticket comment');
		}
	} finally {
		dispatch({ type: Actions.SET_SAVING_COMMENT, savingComment: false });
	}
};

export const markAsSolved = (dispatch: Dispatch, state: State) => async (publicMessage?: string) => {
	try {
		dispatch({ type: Actions.SET_SAVING, saving: true });
		// in the future, this has to be changed when you have your own statuses
		const ticket = { ...state.ticket, status: { id: 2 }, isRead: true };
		await TicketResource.save(
			(publicMessage?.length ?? 0) > 0
				? { ...ticket, description: publicMessage, attachments: state.files }
				: ticket,
			{ skipNotification: true }
		);
		dispatch({ type: Actions.SET_SHOW_CLOSED_SCREEN, showClosedScreen: true });
	} catch (err) {
		logError(err, 'Error closing ticket');
		NotificationService.add({
			icon: 'times',
			style: NotificationService.style.ERROR,
			title: 'ticket.errorClosingTicket'
		});
	} finally {
		dispatch({ type: Actions.SET_SAVING, saving: false });
	}
};

export const markAsOpen = (dispatch: Dispatch, state: State) => async () => {
	dispatch({ type: Actions.SET_SAVING, saving: true });
	// in the future, this has to be changed when you have your own statuses
	const ticket = { ...state.ticket, status: { id: 1 } };
	await TicketResource.save(ticket, { skipNotification: true });

	dispatch({ type: Actions.SET_STATE, ticket, formStateResetTrigger: !state.formStateResetTrigger });
	dispatch({ type: Actions.SET_SAVING, saving: false });
};

export const setFilter = (dispatch: Dispatch, state: State) => async (filter: State['filter']) => {
	dispatch({ type: Actions.SET_FILTER, filter });
};

export const setFiles = (dispatch: Dispatch, state: State) => (files: State['files']) => {
	dispatch({ type: Actions.SET_FILES, files });
	dispatch({ type: Actions.SET_TOTAL_FILE_SIZE, totalFileSize: files.reduce((acc, f) => acc + f.size, 0) });
};

export const addFile = (dispatch: Dispatch, state: State) => (file: File) => {
	const { files } = state;
	const updatedFiles = [
		...files,
		{ size: file.size, type: file.type, filename: file.name, id: Date.now(), blob: file }
	];
	setFiles(dispatch, state)(updatedFiles);
};

export const removeFile = (dispatch: Dispatch, state: State) => (file: State['files'][0]) => {
	const { files } = state;
	const updatedFiles = files.filter(f => f.id !== file.id);
	setFiles(dispatch, state)(updatedFiles);
};

const _save = _.debounce(async (dispatch: Dispatch, ticket: Ticket) => {
	dispatch({ type: Actions.SET_SAVING, saving: true });
	try {
		await TicketResource.save({ ...ticket, isRead: true }, { skipNotification: true });
	} catch (err) {
		logError(err, 'Error saving ticket');
	} finally {
		dispatch({ type: Actions.SET_SAVING, saving: false });
	}
}, 1000);

export const onTicketChange =
	(dispatch: Dispatch, state: State) => async (ticket: Ticket, isValid: boolean, errorMessages: any) => {
		ticket.isPending = state.ticket.isPending;
		const newTicketHash = getTicketHash(ticket);
		const ticketHash = isValid ? newTicketHash : state.ticketHash;
		const hasChanged = newTicketHash !== state.ticketHash;
		const hasUnsavedChanges = !isValid && hasChanged;
		const clientChanged = ticket.client && ticket.client.id !== state.ticket.client?.id;
		const oldMainRecipient = getMainRecipient(state.ticket.involved);
		const newMainRecipient = getMainRecipient(ticket.involved);

		if (clientChanged) {
			const fullClient = await ClientResource.get(ticket.client!.id).then(({ data }) => data);
			ticket.client = fullClient;
		}

		const addedContactEmail =
			oldMainRecipient?.contact && !oldMainRecipient?.contact.email && newMainRecipient?.contact?.email;

		if (addedContactEmail) {
			ContactResource.save({ id: newMainRecipient.contact!.id, email: newMainRecipient.contact!.email });
		}

		const typeChanged = ticket.type.id !== state.ticket.type.id;

		let setStateObj: Partial<State> & { type: Actions } = {
			type: Actions.SET_STATE,
			ticket,
			isValid,
			errorMessages,
			ticketHash,
			hasUnsavedChanges
		};

		if (typeChanged) {
			const customFields = getCustomFields(ticket.type.id);
			setStateObj = { ...setStateObj, customFields };
		}

		const emailRequirementChanged =
			(oldMainRecipient?.contact?.email && !newMainRecipient?.contact?.email) ||
			(!oldMainRecipient?.contact?.email && newMainRecipient?.contact?.email);
		// These affect the form validation so we will trigger a form state reset
		if (typeChanged || emailRequirementChanged) {
			setStateObj = { ...setStateObj, formStateResetTrigger: !state.formStateResetTrigger };
		}

		dispatch(setStateObj);

		if (isValid && ticket.id && hasChanged) {
			_save(dispatch, ticket);
		}

		if (!isValid && ticket.id) {
			_save.cancel();
		}
	};

export type TicketForm = Ticket & {
	contactInfo: {
		client: Ticket['client'];
		contact: Ticket['involved'][number]['contact'];
		email: string;
	};
	custom: { [key: string]: string | null };
	publicMessage: string;
	cc: TokenItem[];
};

export const mapTicketToForm = (ticket: Ticket, customFields: CustomField[]) => {
	const { contact, client, involved, ...rest } = ticket;
	const mainRecipient = getMainRecipient(involved);
	return {
		...rest,
		contactInfo: {
			client: client,
			contact: mainRecipient?.contact ?? null,
			email: mainRecipient?.email ?? ''
		},
		cc: involved.filter(i => i.type === 'CC').map(i => ({ id: i.email, title: i.email })),
		custom: mapCustomValuesToObject(ticket.custom ?? [], customFields)
	} as TicketForm;
};

export const mapFormToTicket = (ticketForm: TicketForm) => {
	const { contactInfo, custom, contact, publicMessage, cc, ...rest } = ticketForm;
	const involved = [];

	if (contactInfo.contact || contactInfo.email?.length) {
		involved.push({
			type: 'TO' as const,
			email: (contactInfo.contact?.email || contactInfo.email) ?? '',
			contact: contactInfo.contact
		});
	}
	if (cc.length) {
		involved.push(...ticketForm.cc.map(({ id }) => ({ type: 'CC' as const, email: id.toString(), contact: null })));
	}
	return {
		...rest,
		client: contactInfo.client,
		contact: contactInfo.contact,
		involved,
		custom: mapCustomValuesToArray(custom)
	};
};

export const setTicketResponseTemplate =
	(dispatch: Dispatch, state: State) => (ticketResponseTemplate: TicketResponseTemplate | undefined) => {
		dispatch({ type: Actions.SET_TICKET_RESPONSE_TEMPLATE, ticketResponseTemplate });
	};
