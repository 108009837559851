import React from 'react';
import { useEditTicketContext } from '../../Context/Context';
import { Select } from '@upsales/components';
import { useTicketTypes } from 'App/components/hooks/appHooks';

type Props = {
	inputRef?: React.RefObject<HTMLInputElement>;
	anchor?: Element;
	disabled?: boolean;
	onChange: (value: any) => void;
};

const TypeSelect = ({ inputRef, anchor, disabled = false, onChange }: Props) => {
	const {
		state: {
			ticket: { type }
		}
	} = useEditTicketContext();
	const ticketTypes = useTicketTypes();

	return (
		<Select
			options={ticketTypes.map(type => ({ ...type, title: type.name }))}
			inputRef={inputRef}
			onChange={onChange}
			anchor={anchor}
			value={type ? { ...type, title: type.name } : null}
			openOnFocus
			disabled={disabled}
		/>
	);
};

export default TypeSelect;
