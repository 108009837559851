import { AnyAction } from 'redux';

export const RESET = '[MarketingBoard] Reset';
export const SET_BOARD_LOADING = '[MarketingBoard] Set board loading';
export const SET_SELECTED_DATE_PRESET = '[MarketingBoard] Set selected date preset';
export const SET_STATS_LOADING = '[MarketingBoard] Set stats loading';
export const SET_BOARD_LOADING_MORE = '[MarketingBoard] Set stats loading more';
export const SET_STATS = '[MarketingBoard] Set stats';
export const SET_DATE_RANGE = '[MarketingBoard] Set date range';
export const SET_BOARD_OFFSET = '[MarketingBoard] Set board offset';
export const SET_BOARD_DATA = '[MarketingBoard] Set board data';
export const SET_BOARD_FILTERS = '[MarketingBoard] Set board filters';
export const SET_BOARD_HAS_MORE_DATA = '[MarketingBoard] Set board has more data';
export const SET_HAS_FLOWS = '[MarketingBoard] Set has flows';

export enum DATE_PRESETS {
	today = 'today',
	yesterday = 'yesterday',
	'7days' = '7days',
	'30days' = '30days',
	'90days' = '90days',
	custom = 'custom'
}

export type Stats = {
	adClients: number;
	adClientsDiff: number;
	clicked: number | string;
	formSubmitDiff: number;
	formSubmitForms: number;
	formSubmits: number;
	impressions: number;
	mail: number;
	mailDiff: number;
	opened: number | string;
	uniqueVisits: number;
	visits: number;
	visitsDiff: number;
};

type MarketingBoardState = {
	boardLoading: boolean;
	boardLoadingMore: boolean;
	statsLoading: boolean;
	selectedDatePreset: DATE_PRESETS;
	fromDate: Date;
	toDate: Date;
	stats: Stats;
	boardOffset: number;
	boardData: {};
	boardFilters: {};
	boardHasMoreData: boolean;
	hasFlows: boolean;
};

export const initialState: MarketingBoardState = {
	boardLoading: false,
	boardLoadingMore: false,
	statsLoading: false,
	selectedDatePreset: DATE_PRESETS.today,
	fromDate: new Date(),
	toDate: new Date(),
	stats: {
		adClients: 0,
		adClientsDiff: 0,
		clicked: 0,
		formSubmitDiff: 0,
		formSubmitForms: 0,
		formSubmits: 0,
		impressions: 0,
		mail: 0,
		mailDiff: 0,
		opened: 0,
		uniqueVisits: 0,
		visits: 0,
		visitsDiff: 0
	},
	boardOffset: 0,
	boardData: {},
	boardFilters: {},
	boardHasMoreData: false,
	hasFlows: true
};

const ACTION_HANDLERS: { [key: string]: (s: MarketingBoardState, a: AnyAction) => MarketingBoardState } = {
	[RESET]: () => ({ ...initialState }),
	[SET_BOARD_LOADING]: (state, { boardLoading }) => ({ ...state, boardLoading }),
	[SET_BOARD_LOADING_MORE]: (state, { boardLoadingMore }) => ({ ...state, boardLoadingMore }),
	[SET_SELECTED_DATE_PRESET]: (state, { selectedDatePreset }) => ({ ...state, selectedDatePreset }),
	[SET_STATS]: (state, { stats }) => ({ ...state, stats }),
	[SET_DATE_RANGE]: (state, { fromDate, toDate }) => ({ ...state, fromDate, toDate }),
	[SET_STATS_LOADING]: (state, { statsLoading }) => ({ ...state, statsLoading }),
	[SET_BOARD_OFFSET]: (state, { boardOffset }) => ({ ...state, boardOffset }),
	[SET_BOARD_FILTERS]: (state, { boardFilters }) => ({ ...state, boardFilters }),
	[SET_BOARD_HAS_MORE_DATA]: (state, { boardHasMoreData }) => ({ ...state, boardHasMoreData }),
	[SET_BOARD_DATA]: (state, { boardData }) => ({ ...state, boardData }),
	[SET_HAS_FLOWS]: (state, { hasFlows }) => ({ ...state, hasFlows })
};

export default (state = { ...initialState }, action: AnyAction): MarketingBoardState => {
	const handler = ACTION_HANDLERS[action.type];
	return handler ? handler(state, action) : state;
};
