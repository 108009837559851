import React, { useMemo } from 'react';
import { useSubscriptionGroupContext } from '../Context/SubscriptionGroupContext';
import CreateHeader from './CreateHeader';
import EditHeader from './EditHeader';
import T from 'Components/Helpers/translate';
import logError from 'Helpers/logError';
import { STEP } from '../Step';
import ErrorMessages from '../ErrorEnum';
import { getSelf } from 'Store/selectors/AppSelectors';
import { Feature } from 'Store/actions/FeatureHelperActions';
import { useSoftDeployAccess, useFeatureAvailable } from 'App/components/hooks';
import openModal from 'App/services/Modal';
import type AgreementGroup from 'App/resources/Model/AgreementGroup';
import type { AgreementChange } from 'App/components/AgreementFulfillmentModal';
import { NewSubscriptionTracker } from 'Helpers/Tracker';

const groupedErrors = (errorMessages: { [key: string]: string | null }) =>
	Object.values(errorMessages)
		.filter(Boolean)
		.reduce((res, curr) => {
			res[curr ?? ''] = (res[curr ?? ''] || 0) + 1;
			return res;
		}, {} as { [key: string]: number });

const getTooltip = (
	errorMessages: { [key: string]: string | null },
	excessiveDiscountOrderRows: number,
	invalidOrderRows: number,
	invalidOrders: number,
	step: STEP,
	isEdit: boolean,
	isExternallyLocked?: boolean
) => {
	const detailsTooltip = [],
		summaryTooltip = [];

	if (isExternallyLocked) {
		summaryTooltip.push(T('subscription.modal.pendingIntegrationRequest'));
	}

	if (excessiveDiscountOrderRows && !Tools.AppService.getSelf().administrator) {
		summaryTooltip.push(
			T(`subscription.modal.excessiveDiscount`, {
				type:
					excessiveDiscountOrderRows > 1
						? T('subscription.modal.missingOrderRows')
						: T('subscription.modal.missingOrderRow'),
				count: excessiveDiscountOrderRows
			})
		);
	}

	if (invalidOrderRows) {
		summaryTooltip.push(
			T(`subscription.modal.required${invalidOrderRows === 1 ? 'One' : ''}`, {
				type:
					invalidOrderRows > 1
						? T('subscription.modal.missingOrderRows')
						: T('subscription.modal.missingOrderRow'),
				count: invalidOrderRows
			})
		);
	}

	if (invalidOrders) {
		summaryTooltip.push(
			T(`subscription.modal.required${invalidOrders === 1 ? 'One' : ''}`, {
				type:
					invalidOrders > 1
						? T('subscription.modal.missingOrderCustomFields')
						: T('subscription.modal.missingOrderCustomField'),
				count: invalidOrders
			})
		);
	}

	for (const [key, count] of Object.entries(groupedErrors(errorMessages))) {
		switch (key) {
			case ErrorMessages.Client: {
				detailsTooltip.push(T('subscription.modal.missingClient'));
				break;
			}
			case ErrorMessages.Description: {
				detailsTooltip.push(T('subscription.modal.missingDescription'));
				break;
			}
			default: {
				detailsTooltip.push(
					T(`subscription.modal.required${count === 1 ? 'One' : ''}`, {
						type:
							count > 1
								? T('subscription.modal.missingAgreementCustomFields')
								: T('subscription.modal.missingAgreementCustomField'),
						count
					})
				);
				break;
			}
		}
	}

	if (isEdit && (detailsTooltip.length || summaryTooltip.length)) {
		return [...detailsTooltip, ...summaryTooltip].join('\n');
	}

	if (step === STEP.SUMMARY && summaryTooltip.length) {
		return summaryTooltip.join('\n');
	}

	if (step === STEP.DETAILS && detailsTooltip.length) {
		return detailsTooltip.join('\n');
	}

	return '';
};

function handleFulfillmentChanges(
	isNew: boolean,
	agreementGroup: AgreementGroup,
	fulfillmentChanges: AgreementChange[]
) {
	const { id, client } = agreementGroup;

	return new Promise(resolve => {
		openModal('AgreementFulfillmentModal', {
			isNew,
			agreementGroup: { id, client },
			agreementChanges: fulfillmentChanges,
			onClose: () => resolve(undefined)
		});
	});
}

type Props = {
	close: (data?: object) => void;
	labMode?: boolean;
	className: string;
	hasDiffOrdersToCreate: boolean;
	onSave: () => void;
};

const Header = ({ close, onSave, labMode, className, hasDiffOrdersToCreate }: Props) => {
	const {
		state: {
			id,
			subscriptionMap,
			isAdvancedMode,
			isDetailsValid,
			errorMessages,
			splitEnabled,
			description,
			orderToAdd,
			finalStep,
			isEdit,
			step
		},
		saveSubscriptionGroup,
		setSaving,
		getFulfillmentChanges,
		setStep
	} = useSubscriptionGroupContext();
	const self = getSelf();
	const newSplitFlag = Tools.FeatureHelper.hasSoftDeployAccess('SUBSCRIPTION_SPLITS');
	const hasProjectPlan = useFeatureAvailable(Feature.PROJECT_PLAN);
	const hasAgreementFulfillmentSoftDeploy = useSoftDeployAccess('AGREEMENT_FULFILLMENT');
	const hasAgreementFulfillment = hasProjectPlan && hasAgreementFulfillmentSoftDeploy;

	const onAbort = () => {
		NewSubscriptionTracker.trackDataWithValues(
			false,
			isAdvancedMode,
			splitEnabled,
			finalStep ?? step,
			!!orderToAdd
		);
		close();
	};

	const resetScroll = () => {
		const element = (document?.getElementsByClassName?.('OpenSubscription') ?? [])[0];
		if (element) {
			element.scrollTop = 0;
		}
	};

	const save = async () => {
		try {
			NewSubscriptionTracker.trackDataWithValues(
				true,
				isAdvancedMode,
				splitEnabled,
				finalStep ?? step,
				!!orderToAdd
			);
			// For now only do this if it is an edit
			const fulfillmentChanges = hasAgreementFulfillment ? getFulfillmentChanges() : [];
			const haveToWait = fulfillmentChanges.length > 0;

			const res = await saveSubscriptionGroup(haveToWait);

			close(res?.data);

			if (fulfillmentChanges.length > 0 && typeof res?.data === 'object') {
				const agreementGroup = res.data as AgreementGroup;
				await handleFulfillmentChanges(!!id, agreementGroup, fulfillmentChanges);
			}

			onSave?.();
		} catch (err) {
			logError(err, 'Failed to save subscription group');
		} finally {
			setTimeout(() => setSaving(false));
		}
	};

	const leaveLabMode = async () => {
		//openModal('Build a new cool modal in next ticket');
		close();
	};

	// Needed for test :explode:
	const changeProxy = (tab: string) => {
		resetScroll();
		setStep(tab as STEP);
	};

	const getTabName = (tab: string) => {
		if (newSplitFlag && tab === 'summary') {
			tab = 'summary.split';
		}
		return T('subscription.modal.tab.' + tab);
	};

	const disableDetailsSave = !isDetailsValid;
	const disableSummarySave = useMemo(() => {
		const periods = Object.values(subscriptionMap);
		return periods.some(period => {
			if (period.externallyLocked) {
				return true;
			}
			let invalidOrderRows;
			const isAdmin = self?.administrator;
			if (newSplitFlag && period.children?.length) {
				invalidOrderRows = period.children.some(child =>
					child.orderRow.some(
						orderRow =>
							!orderRow.isValid || !orderRow.product || (orderRow.hasExcessiveDiscount && !isAdmin)
					)
				);
			} else {
				invalidOrderRows = period.orderRows.some(
					orderRow => !orderRow.isValid || !orderRow.product || (orderRow.hasExcessiveDiscount && !isAdmin)
				);
			}

			const invalidCustomFields = !period.isCustomFieldsValid;
			return invalidCustomFields || invalidOrderRows;
		});
	}, [subscriptionMap, finalStep]);

	let tooltip;
	if (disableSummarySave || disableDetailsSave) {
		let invalidOrderRows = 0;
		let invalidOrders = 0;
		let excessiveDiscountOrderRows = 0;
		let externallyLocked = false;
		Object.values(subscriptionMap).forEach(period => {
			if (period.externallyLocked) {
				externallyLocked = true;
			}
			invalidOrders += Object.values(period?.errorMessages || {}).filter(Boolean).length;

			if (newSplitFlag && period.children?.length) {
				period.children.forEach(child =>
					child.orderRow.forEach(orderRow => {
						if (!orderRow.product || !orderRow.isValid) {
							invalidOrderRows += 1;
						}
						if (orderRow.hasExcessiveDiscount) {
							excessiveDiscountOrderRows += 1;
						}
					})
				);
			} else {
				period.orderRows.forEach(orderRow => {
					if (!orderRow.product || !orderRow.isValid) {
						invalidOrderRows += 1;
					}
					if (orderRow.hasExcessiveDiscount) {
						excessiveDiscountOrderRows += 1;
					}
				});
			}
		});
		tooltip = getTooltip(
			errorMessages,
			excessiveDiscountOrderRows,
			invalidOrderRows,
			invalidOrders,
			step,
			isEdit,
			externallyLocked
		);
	}

	const Header = isEdit ? EditHeader : CreateHeader;
	return (
		<Header
			hasDiffOrdersToCreate={hasDiffOrdersToCreate}
			subtitle={description?.length ? description : null}
			disableDetailsSave={disableDetailsSave}
			disableSummarySave={disableSummarySave}
			resetScroll={resetScroll}
			changeProxy={changeProxy}
			getTabName={getTabName}
			className={className}
			labMode={labMode}
			tooltip={tooltip}
			onAbort={onAbort}
			save={labMode ? leaveLabMode : save}
		/>
	);
};

export default Header;
