import { Block, DateInput, Link, Select, SelectAsync, Text, Title } from '@upsales/components';
import BemClass from '@upsales/components/Utils/bemClass';
import {
	FlowTemplateName,
	getSegmentFiltersFromTemplateName,
	GetSegmentFiltersReturnType
} from 'App/enum/FlowTemplate';
import React, { useEffect, useMemo, useRef } from 'react';
import T from 'Components/Helpers/translate';
import { useSelector } from 'react-redux';
import { RootState } from 'Store/index';
import ProductResource from 'Resources/Product';
import Product from 'App/resources/Model/Product';
import getAngularModule from 'App/babel/angularHelpers/getAngularModule';
import RequestBuilder from 'Resources/RequestBuilder';
import ComparisonTypes from 'Resources/ComparisonTypes';
import { getProducts } from 'Store/selectors/AppSelectors';

import './CreateTemplateFlowSelection.scss';

type SelectType = { id: number; title: string; subtitle?: string };

type Props = {
	flowTemplateName: FlowTemplateName;
	onChange: (filter: GetSegmentFiltersReturnType[] | null, settings: any) => void;
	segmentSettings: {
		days?: number | null;
		product?: SelectType | null;
		page?: SelectType | null;
		visitDate?: Date | null;
	};
};

const getProductsFromServer = async (term: string): Promise<SelectType[]> => {
	const rb = new RequestBuilder();
	rb.limit = 100;
	rb.addSort({ field: 'name' }, true);
	if (term) {
		rb.addFilter({ field: 'name' }, ComparisonTypes.Search, term);
	}
	const { data } = (await ProductResource.find(rb.build())) as { data: Product[] };
	return data.map(product => ({ id: product.id, title: product.name }));
};

const getPages = async (term: string): Promise<SelectType[]> => {
	const LeaderPage = getAngularModule('LeaderPage');

	const filter = new RequestBuilder();
	filter.limit = 100;
	filter.addSort('pageImpression', false);

	if (term) {
		const or = filter.orBuilder();
		or.next();
		or.addFilter({ field: 'url' }, ComparisonTypes.Search, term);
		or.next();
		or.addFilter({ field: 'name' }, ComparisonTypes.Search, term);
		or.done();
	}

	const { data } = (await LeaderPage.find(filter.build())) as { data: { id: number; name: string; url: string }[] };

	return data.map((page: any) => ({ id: page.id, title: page.name, subtitle: page.url }));
};

const CreateTemplateFlowSelection: React.FC<Props> = ({ flowTemplateName, onChange, segmentSettings }) => {
	const classes = useMemo(() => new BemClass('CreateTemplateFlowSelection'), []);
	const visitDateInputRef = useRef<HTMLInputElement>(null);

	useEffect(() => {
		if (visitDateInputRef.current) {
			visitDateInputRef.current.setAttribute('readonly', 'readonly');
		}
	}, []);

	const setLegacyInputRef = (r: HTMLInputElement) =>
		((visitDateInputRef as React.MutableRefObject<HTMLInputElement | undefined>).current = r);

	const renderTitleTrigger = (
		placeholder: string
	): React.ComponentProps<typeof Select>['renderSelectedItem'] => value => (
		<Link>{value?.title || T(placeholder)}</Link>
	);

	const onChangeVisitDate = (visitDate: Date) => {
		if (segmentSettings.page) {
			onChange(getSegmentFiltersFromTemplateName(flowTemplateName, segmentSettings.page.subtitle, visitDate), {
				visitDate: visitDate,
				page: segmentSettings.page
			});
		} else {
			onChange(null, { visitDate: visitDate, page: null });
		}
	};

	let settingsString = null;

	const baseSelectProps = {
		anchor: document.querySelector('.CreateTemplateFlow'),
		dropdownClassName: classes.elem('select-dropdown').b(),
		inline: true
	};

	useEffect(() => {
		if (
			!segmentSettings.days &&
			[FlowTemplateName.WARM_UP_COLD_LEADS, FlowTemplateName.WELCOME_NEW_CUSTOMERS].includes(flowTemplateName)
		) {
			onChange(getSegmentFiltersFromTemplateName(flowTemplateName, 7), {
				days: 7
			});
		}
	}, []);

	switch (flowTemplateName) {
		case FlowTemplateName.WARM_UP_COLD_LEADS:
		case FlowTemplateName.WELCOME_NEW_CUSTOMERS: {
			const options = useMemo(
				() =>
					[7, 14, 30, 90].map(days => ({
						id: days,
						title: `${days} ${T('date.daysPlural').toLowerCase()}`
					})),
				[]
			);

			settingsString = (
				<Title color="grey-11" className={classes.elem('inline-text').b()}>
					{T(
						flowTemplateName === FlowTemplateName.WARM_UP_COLD_LEADS
							? 'flow.templateModal.WARM_UP_COLD_LEADS.contactsThatAreLeadLast'
							: 'flow.templateModal.WELCOME_NEW_CUSTOMERS.contactsBecameCustomersLast'
					)}

					<Select<SelectType>
						{...baseSelectProps}
						value={options.find(o => o.id === segmentSettings.days) ?? options[0]}
						options={options}
						onChange={({ id }) =>
							onChange(getSegmentFiltersFromTemplateName(flowTemplateName, id), { days: id })
						}
						renderSelectedItem={renderTitleTrigger('')}
						showSearch={false}
					></Select>
				</Title>
			);
			break;
		}
		case FlowTemplateName.SELL_MORE_TO_EXISTING_CUSTOMERS: {
			const { totalProducts } = useSelector(({ App }: RootState) => ({
				totalProducts: App.totals.products
			}));

			const options = getProducts().map(p => ({ id: p.id, title: p.name }));
			const selectProps = {
				...baseSelectProps,
				value: segmentSettings.product || null,
				onChange: (product: SelectType) =>
					onChange(getSegmentFiltersFromTemplateName(flowTemplateName, product.id), { product: product }),
				renderSelectedItem: renderTitleTrigger('default.product')
			};
			settingsString = (
				<Title color="grey-11" className={classes.elem('inline-text').b()}>
					{T('flow.templateModal.SELL_MORE_TO_EXISTING_CUSTOMERS.contactsWhereCustomerDidNotBuy')}
					{totalProducts < 4000 ? (
						<Select<SelectType> {...selectProps} options={options} />
					) : (
						<SelectAsync<SelectType> {...selectProps} fetcher={getProductsFromServer} fetchOnOpen />
					)}
				</Title>
			);
			break;
		}
		case FlowTemplateName.CONTACTS_WHO_VISITED_A_WEBPAGE: {
			settingsString = (
				<>
					<Title color="grey-11" className={classes.elem('inline-text').b()}>
						{T('flow.templateModal.CONTACTS_WHO_VISITED_A_WEBPAGE.pickContactsWhoVisited')}
						<SelectAsync<SelectType>
							{...baseSelectProps}
							renderSelectedItem={renderTitleTrigger('default.webpage')}
							value={segmentSettings.page || null}
							fetcher={getPages}
							fetchOnOpen
							onChange={page =>
								onChange(
									getSegmentFiltersFromTemplateName(
										flowTemplateName,
										page.subtitle,
										segmentSettings.visitDate
									),
									{ page: page, visitDate: segmentSettings.visitDate }
								)
							}
						/>
						{T('flow.templateModal.CONTACTS_WHO_VISITED_A_WEBPAGE.since').toLowerCase()}
					</Title>
					<DateInput
						className={classes.elem('inline-date').b()}
						value={segmentSettings.visitDate}
						onChange={e => onChangeVisitDate(e.target.value)}
						inputRef={setLegacyInputRef}
						closeOnSelect
					/>
				</>
			);
			break;
		}
	}
	return (
		<div className={classes.b()}>
			<Text size="xl" bold>
				{T('flow.templateModal.findContactsToProcess')}
			</Text>
			<Block space="mtl">{settingsString}</Block>
		</div>
	);
};

export default CreateTemplateFlowSelection;
