import './LeadSource.scss';
import React from 'react';
import { Icon, Tooltip } from '@upsales/components';
import { openDrawer } from 'Services/Drawer';
import BemClass from '@upsales/components/Utils/bemClass';
import './LeadSource.scss';

const openAdCampaign = (mailCampaignId, event) => {
	event.preventDefault();
	event.stopPropagation();

	Tools.$state.go('viewAd.dashboard', { id: mailCampaignId });
};

const openEmail = (mailId, event) => {
	event.preventDefault();
	event.stopPropagation();
	if (Tools.FeatureHelper.hasSoftDeployAccess('NEW_MAIL')) {
		openDrawer('SentMail', { mail: { id: mailId } });
	} else {
		Tools.$upModal.open('sentMail', { id: mailId });
	}
};

const LeadSource = ({ visit: item }) => {
	const classes = new BemClass('LeadSource');
	if (!item.leadSource || !item.leadSource.source) {
		return <div className="LeadSource" style={{ position: 'relative' }} />;
	}

	const leadSource = item.leadSource;
	const renderOpts = Tools.LEAD_SOURCE.getConfig(item.leadSource);
	let iconElement = null;

	if (renderOpts.iconType !== 'none') {
		iconElement =
			renderOpts.iconType === 'img' ? <img {...renderOpts.iconProps} /> : <Icon {...renderOpts.iconProps} />;
		if (renderOpts.url) {
			iconElement = <a href={renderOpts.url}>{iconElement}</a>;
		}
	}

	const title = renderOpts.title;
	let value = leadSource.value;

	let tooltip = title;
	let subtitle = null;
	if (leadSource.source === 'upsalesMail') {
		if (leadSource.mailCampaign) {
			value = leadSource.mailCampaign.subject;
		} else if (leadSource.mail) {
			value = leadSource.mail.subject;
		}
		subtitle = <a onClick={event => openEmail(leadSource.subValue, event)}>{value}</a>;
	} else if (leadSource.source === 'upsalesAds') {
		if (leadSource.adCampaign) {
			value = leadSource.adCampaign.name;
			subtitle = <a onClick={event => openAdCampaign(leadSource.adCampaign.id, event)}>{value}</a>;
		}
	} else if (leadSource.type === 'direct') {
		subtitle = leadSource.source || null;
	} else {
		subtitle = value;
	}

	if (value && value !== title) {
		tooltip += '\n' + value;
	} else if (leadSource.type !== 'direct') {
		subtitle = '';
	}

	return (
		<Tooltip position="top" title={tooltip}>
			<div className={classes.b()}>
				<div className={classes.elem('source-icon').b()}>{iconElement}</div>
				<div className={classes.elem('textColumn')}>
					<div className={classes.elem('title').b()}>
						<span>{title}</span>
					</div>
					<div className={classes.elem('subtitle').b()}>
						<span>{subtitle}</span>
					</div>
				</div>
			</div>
		</Tooltip>
	);
};
window.LeadSourceColumn = LeadSource;
export default LeadSource;
