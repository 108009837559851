import ARRChangesOnDate, { Change, Changes } from 'App/babel/resources/ARRChangesOnDate';
import { DrawerHeader, Text, Title, Loader } from '@upsales/components';
import { CurrencyFormat } from 'App/babel/utils/numberFormat';
import React, { useState, useMemo, useEffect } from 'react';
import { makeCancelable } from 'App/babel/helpers/promise';
import BemClass from '@upsales/components/Utils/bemClass';
import ChangesOnAgreement from './ChangesOnAgreement';
import T from 'Components/Helpers/translate';
import { useSelector } from 'react-redux';
import { RootState } from 'Store/index';
import logError from 'Helpers/logError';
import InfoBox from './InfoBox';
import moment from 'moment';

import './ARRChangeDrawer.scss';
import { capitalize } from 'lodash';
import { getCMWithRROption } from 'App/helpers/salesModelHelpers';

type Props = {
	close: () => void;
	className: string;
	clientId: number;
	activeAgreementId?: number;
	agreementGroupId?: number;
	date: string;
	type: string;
};

const ARRChangeDrawer = ({ className, close, clientId, activeAgreementId, agreementGroupId, date, type }: Props) => {
	const [agreementChanges, setAgreementChanges] = useState<Changes>();

	const { customerCurrencies, params } = useSelector(
		({ App }: RootState) => App.metadata ?? { customerCurrencies: [], params: { SalesModelOption: 'arr' } }
	);
	const masterCurrency = useMemo(() => customerCurrencies?.find(({ masterCurrency }) => masterCurrency), []);

	useEffect(() => {
		const arrChangePromise = makeCancelable(
			ARRChangesOnDate.getChangesOnDate(clientId, date, activeAgreementId, agreementGroupId)
		);
		arrChangePromise.promise
			.then(({ data }) => {
				data.changes = data.changes.filter((change: Change) => !!change.rowsChanged);
				setAgreementChanges(data);
			})
			.catch(e => logError(e, 'Failed to get ARR changes for date'));
		return () => {
			arrChangePromise.cancel();
		};
	}, []);

	const classes = new BemClass('ARRChangeDrawer', className);
	const currencyFormat = new CurrencyFormat(masterCurrency?.iso);

	if (!agreementChanges) {
		return (
			<div className={classes.b()}>
				<DrawerHeader onHide={close}></DrawerHeader>
				<div className={classes.elem('loading').b()}>
					<Loader />
				</div>
			</div>
		);
	}

	if (agreementChanges.changes.length === 0) {
		return (
			<div className={classes.b()}>
				<DrawerHeader onHide={close}></DrawerHeader>
				<div className={classes.elem('noResults').b()}>
					<img src={'img/empty-briefcase.svg'} />
					<Text>{T('arrchange.drawer.noChanged')}</Text>
				</div>
			</div>
		);
	}

	const agreementGroupChanges: { [key: string]: Change[] } = {};
	agreementChanges.changes.forEach(change => {
		const id = change.agreementGroupId
			? 'ag' + change.agreementGroupId
			: 'a' + (change.activeAgreementId ?? change.agreementId);
		if (agreementGroupChanges[id]) {
			agreementGroupChanges[id].push(change);
		} else {
			agreementGroupChanges[id] = [change];
		}
	});

	const changes = Object.values(agreementGroupChanges);
	const moreThanOneAgreement = Object.keys(agreementGroupChanges).length;
	const changeValue =
		activeAgreementId || agreementGroupId ? agreementChanges.changeWithoutHidden : agreementChanges.change;
	const title = T(type) === type ? T(`agreementHistory.type${capitalize(type)}`) : T(type);

	return (
		<div className={classes.b()}>
			<DrawerHeader onHide={close}>
				<div className={classes.elem('header').b()}>
					<div>
						<Title>{`${title} • ${moment(date).format('L')}`}</Title>
						<Text size="sm" color="grey-11">
							{currencyFormat.short(changeValue)}
						</Text>
					</div>
				</div>
			</DrawerHeader>
			<div className={classes.elem('body').b()}>
				{moreThanOneAgreement > 1 ? <InfoBox numberOfAgreements={moreThanOneAgreement} /> : null}
				{changes.map((changes, i) => (
					<ChangesOnAgreement
						key={'changesOnAgreement' + i}
						index={i}
						withDrowpDown={moreThanOneAgreement > 1}
						changes={changes}
						currency={masterCurrency?.iso!}
						mrr={params.SalesModelOption === 'mrr' || getCMWithRROption() === 'mrr'}
					/>
				))}
			</div>
		</div>
	);
};

export default ARRChangeDrawer;
