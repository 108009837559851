import moment from 'moment';
import getAngularModule from 'App/babel/angularHelpers/getAngularModule';

class MomentTags {
	tag = '';
	tz() {
		return this;
	}
	endOf(period) {
		this.tag += '|endOf.' + period;
		return this;
	}
	startOf(period) {
		this.tag += '|startOf.' + period;
		return this;
	}
	add(quantity, period) {
		this.tag += '|add.' + quantity + '.' + period;
		return this;
	}
	subtract(quantity, period) {
		this.tag += '|subtract.' + quantity + '.' + period;
		return this;
	}
	format() {
		if (this.tag.length === 0) {
			return '{{Today}}';
		}

		return '{{' + this.tag.substring(1) + '}}';
	}
}

export const momentTags = () => {
	return new MomentTags();
};

/*
	There probably is some smarter way to calculate this stuff that I am to stupid to 
	think of right now :/

	It is important that the value of each function is only calculated by functions supported by
	MomentTags, otherwise they wont work in segments or other places with dynamic date filters. 
*/
export const momentFiscalYear = (momentFactoryFunc = moment) => {
	return (...args) => {
		const momentInstance = momentFactoryFunc(...args);
		const fiscalMoment = momentFiscalYear();
		const AppService = getAngularModule('AppService');
		momentInstance._fiscalYearOffset = AppService.getMetadata().params.brokenFiscalYearOffset || 0;
		momentInstance.fiscalYearOffset = function (offset) {
			if (offset) {
				this._fiscalYearOffset = offset;
				return this;
			}
			return this._fiscalYearOffset;
		};
		momentInstance.endOf = function (period) {
			const endOf = Object.getPrototypeOf(this).endOf;

			if (period === 'fiscalYear') {
				const currentYear = this.year();
				const currentFiscalYear = this.fiscalYear();

				endOf.call(this, 'year').add(this._fiscalYearOffset, 'months');

				if (currentYear < currentFiscalYear) {
					this.add(1, 'year');
				} else if (currentYear > currentFiscalYear) {
					this.subtract(1, 'year');
				}
				return this;
			}
			if (period === 'fiscalQuarter') {
				const fiscalYearStart = fiscalMoment(this._now ? this._now : this).startOf('fiscalYear');
				const monthDiff = this.diff(fiscalYearStart, 'month');
				const monthsToAdd = 2 - (monthDiff % 3);

				if (monthsToAdd !== 0) {
					this.add(monthsToAdd, 'month');
				}

				return this.endOf('month');
			}
			return endOf.call(this, period);
		};
		momentInstance.startOf = function (period) {
			const startOf = Object.getPrototypeOf(this).startOf;

			if (period === 'fiscalYear') {
				const currentYear = this.year();
				const currentFiscalYear = this.fiscalYear();

				startOf.call(this, 'year').add(this._fiscalYearOffset, 'months');

				if (currentYear < currentFiscalYear) {
					this.add(1, 'year');
				} else if (currentYear > currentFiscalYear) {
					this.subtract(1, 'year');
				}
				return this;
			}
			if (period === 'fiscalQuarter') {
				const fiscalYearStart = fiscalMoment(this._now ? this._now : this).startOf('fiscalYear');
				const monthDiff = this.diff(fiscalYearStart, 'month');
				const monthsToRemove = monthDiff % 3;

				if (monthsToRemove !== 0) {
					this.subtract(monthsToRemove, 'month');
				}

				return this.startOf('month');
			}
			return startOf.call(this, period);
		};
		momentInstance.fiscalYear = function (year) {
			if (year) {
				const currentFiscalYear = this.fiscalYear();
				const yearsToAdd = year - currentFiscalYear;
				if (yearsToAdd !== 0) {
					this.add(yearsToAdd, 'year');
				}
				return this;
			}

			const currentYear = this.year();
			const s = moment(currentYear, 'YYYY').add(this._fiscalYearOffset, 'month');
			const e = moment(currentYear - 1, 'YYYY')
				.endOf('year')
				.add(this._fiscalYearOffset, 'month')
				.add(12, 'month');

			return this.isBefore(s) ? currentYear - 1 : this.isAfter(e) ? currentYear + 1 : currentYear;
		};
		// Maby this one is not working "exactly" like on would want, dont set time to 23:59:59 but i am to lazy to fix that npw
		momentInstance.fiscalQuarter = function (quarter) {
			if (quarter) {
				const monthsToAdd = (quarter - 1) * 3;
				const currentDate = this.date();
				this.startOf('fiscalYear').add(monthsToAdd, 'month');
				const daysInMonth = this.daysInMonth();
				const daysToAdd = Math.min(daysInMonth - 1, currentDate - 1);
				if (daysToAdd > 0) {
					this.add(daysToAdd, 'days');
				}
				return this;
			}
			const fiscalYearStart = fiscalMoment(this._now ? this._now : this).startOf('fiscalYear');
			const monthDiff = this.diff(fiscalYearStart, 'month');
			return Math.floor(monthDiff / 3) + 1;
		};

		/*
			We have to support momentTags to
		*/
		if (!(momentInstance instanceof moment)) {
			momentInstance._now = moment(...args);
			const functions = ['year', 'isBefore', 'isAfter', 'diff', 'date', 'daysInMonth'];
			for (const fn of functions) {
				momentInstance[fn] = function (...args) {
					return this._now[fn](...args);
				};
			}
		}

		return momentInstance;
	};
};

const methods = {
	momentFiscalYear: momentFiscalYear,
	momentTags: momentTags
};

window.momentHelper = methods;
export default methods;
