import { DrawerHeader, Drawer, TableRow, TableColumn, Link, EllipsisTooltip } from '@upsales/components';
import { ListViewDefaultColumn } from 'App/components/ListView/ListViewRenderHelpers';
import ListView, { ListViewPropsExternal } from 'App/components/ListView';
import { useTranslation } from 'Components/Helpers/translate';
import BemClass from '@upsales/components/Utils/bemClass';
import RequestBuilder from 'Resources/RequestBuilder';
import ClientAttributes from 'App/babel/attributes/Client';
import ClientResource from 'App/resources/Client';
import Client from 'App/resources/Model/Client';
import React, { useState } from 'react';

import './AccountListDrawer.scss';

type ListViewProps = ListViewPropsExternal<Client>;

const navigateToAccount = (id: number) => {
	window.open(
		Tools.$state.href('account.dashboard', {
			customerId: Tools.AppService.getCustomerId(),
			id
		}),
		'_blank'
	);
};

const getRowRenderFn =
	(): ListViewProps['renderTableRow'] =>
	(account: Client, { columns, attributes }) => {
		const columnElements = columns.map(column => {
			let content = null;
			switch (column) {
				case 'name': {
					content = (
						<div>
							<EllipsisTooltip title={account.name}>
								<Link onClick={() => navigateToAccount(account.id)}>{account.name}</Link>
							</EllipsisTooltip>
						</div>
					);
					break;
				}

				default: {
					return (
						<ListViewDefaultColumn<Client>
							key={column}
							item={account}
							attributes={attributes}
							column={column}
						/>
					);
				}
			}
			return (
				<TableColumn key={column} size="lg">
					{content}
				</TableColumn>
			);
		});

		return (
			<TableRow key={account.id} onClick={() => navigateToAccount(account.id)}>
				{columnElements}
			</TableRow>
		);
	};

type Props = {
	projectId: number;
	projectName: string;
	close: (a?: any) => void;
	className?: string;
};

const AccountListDrawer = (props: Props) => {
	const { className, projectId, projectName } = props;
	const { t } = useTranslation();

	const classes = new BemClass('AccountListDrawer');
	classes.add(className);

	const [total, setTotal] = useState<number>(0);

	const getData = async (rb: RequestBuilder) => {
		// get limit and offset from rb
		const offset = rb?.offset ?? 0;
		const limit = rb?.limit ?? 50;

		rb.fields = ['id', 'name', 'users'];
		rb.limit = limit;
		rb.offset = offset;
		rb.addSort({ field: 'name' });
		rb.addFilter({ field: 'project.id' }, 'eq', projectId);

		const { data, metadata } = await ClientResource.find(rb.build());

		setTotal(data?.length);

		return { data, metadata };
	};

	const columns = ['name', 'users'];

	const subtitle = total + ' ' + t('default.accounts').toLowerCase();

	return (
		<Drawer className={classes.b()}>
			<DrawerHeader subtitle={subtitle} title={projectName} onHide={() => props.close()} />

			<ListView<Client>
				noStickyTableHeader
				renderTableRow={getRowRenderFn()}
				className={classes.elem('list').b()}
				attributes={ClientAttributes}
				renderToolsColumn={false}
				getData={getData}
				columns={columns}
			/>
		</Drawer>
	);
};

export default AccountListDrawer;
