import Prospecting from 'App/babel/resources/Prospecting';
import logError from 'App/babel/helpers/logError';
import { Tabs, LIMIT, UPDATE_VIEW } from './ProspectingShared';
import RequestBuilder, { comparisonTypes } from 'Resources/RequestBuilder';

const SET_SELECTED_BOX = '[ProspectingExisting] Set selected box';
const RESET = '[ProspectingExisting] Reset';

export const initialState = {
	selectedBox: null
};

let latestRequestId = 0;

export const search = () => async (dispatch, getState) => {
	const { selectedBox } = getState().ProspectingExisting;
	const {
		quickSearchValue,
		[Tabs.Search]: { offset, sorting, asc }
	} = getState().ProspectingShared;

	const requestId = ++latestRequestId;

	dispatch({ type: UPDATE_VIEW, view: Tabs.Search, loading: true });

	const rb = new RequestBuilder();

	if (quickSearchValue) {
		rb.addFilter({ field: 'name' }, comparisonTypes.Search, quickSearchValue);
	}

	rb.addSort(sorting, asc);
	rb.offset = offset;
	rb.limit = LIMIT;

	try {
		const {
			data,
			metadata: { total }
		} = await Prospecting.findSuggestionBoxes(selectedBox.name, rb.build());

		if (requestId === latestRequestId) {
			dispatch({ type: UPDATE_VIEW, view: Tabs.Search, data, loading: false, total });
		}
	} catch (error) {
		logError(error, 'ui/app/babel/store/reducers/ProspectingExisting.js');
		dispatch({ type: UPDATE_VIEW, view: Tabs.Search, loading: false });
	}
};

export const searchSelected = () => async (dispatch, getState) => {
	const { selectedBox } = getState().ProspectingExisting;
	const {
		quickSearchValue,
		selection,
		[Tabs.Selected]: { offset, sorting, asc }
	} = getState().ProspectingShared;

	const ids = Object.keys(selection);

	if (!ids.length) {
		dispatch({ type: UPDATE_VIEW, view: Tabs.Selected, data: [], loading: false });
		return;
	}

	dispatch({ type: UPDATE_VIEW, view: Tabs.Selected, loading: true });

	const rb = new RequestBuilder();

	rb.addFilter({ field: 'id' }, comparisonTypes.Equals, ids);

	if (quickSearchValue) {
		rb.addFilter({ field: 'name' }, comparisonTypes.Search, quickSearchValue);
	}

	rb.addSort(sorting, asc);
	rb.offset = offset;
	rb.limit = LIMIT;

	try {
		const { data } = await Prospecting.findSuggestionBoxes(selectedBox.name, rb.build());
		dispatch({ type: UPDATE_VIEW, view: Tabs.Selected, data, loading: false });
	} catch (error) {
		logError(error, 'ui/app/babel/store/reducers/ProspectingExisting.js');
		dispatch({ type: UPDATE_VIEW, view: Tabs.Selected, loading: false });
	}
};

export const init = selectedBox => dispatch => {
	dispatch({ type: RESET });
	dispatch({ type: SET_SELECTED_BOX, selectedBox });
	dispatch(search());
};

const ACTION_HANDLERS = {
	[RESET]: () => initialState,
	[SET_SELECTED_BOX]: (state, { selectedBox }) => ({ ...state, selectedBox })
};

export default (state = initialState, action) => {
	const handler = ACTION_HANDLERS[action.type];
	return handler ? handler(state, action) : state;
};
