import Actions from './OrderContextActions';
import { Dispatch, OrderRow, OrderRowsState, BundleRow } from './OrderContext';
import { isEmpty } from 'lodash';
import { getTierFromOrderRow, getTierFromQuantity, isTieredTotalOrderRow } from 'Helpers/order';
import Product, { Currency } from 'App/resources/Model/Product';
import { CalculationCustomField, EntityCustomField } from 'App/resources/Model/CustomField';
import { NewSubscriptionTracker } from 'Helpers/Tracker';
import { calculateField } from '@upsales/common';
import { getSalesModelOption, hasCMWithRR } from 'App/helpers/salesModelHelpers';
import {
	OrderRowUpdatesWithOpts,
	getProduct,
	updateBundleRows
} from 'App/components/hooks/editListener/updaters/orderRowUpdater';
import { getRecurringProductDefaultInterval } from 'App/helpers/subscriptionHelper';

type NewSubscriptionTrackerType = typeof NewSubscriptionTracker;

// If price is larger than listPrice, then we wont have a discount and will use price instead
export const calculateTotalPrice = (orderRow: OrderRow) => {
	return Math.max(orderRow.price ?? 0, orderRow.listPrice ?? 0) * (orderRow.quantity ?? 0);
};

export const calculateCM = (orderRow: OrderRow) => {
	const isTotalPrice = orderRow.product?.tiers?.[0]?.isTotalPrice;
	if (isTotalPrice) {
		return (orderRow.price ?? 0) - (orderRow.purchaseCost ?? 0) * (orderRow.tierQuantity ?? 0);
	}
	return ((orderRow.price ?? 0) - (orderRow.purchaseCost ?? 0)) * (orderRow.tierQuantity ?? 0);
};

export const totalAmount = (orderRows: OrderRow[]) =>
	orderRows.reduce((sum, orderRow) => sum + calculateTotalPrice(orderRow), 0);
export const totalDiscount = (orderRows: OrderRow[]) =>
	orderRows.reduce((sum, orderRow) => sum + (orderRow.discount ?? 0), 0);
export const totalRecurringValue = (orderRows: OrderRow[]) =>
	orderRows.reduce((sum, orderRow) => sum + (orderRow.recurringValue ?? 0), 0);
export const totalContributionMargin = (orderRows: OrderRow[]) =>
	orderRows.reduce((sum, orderRow) => sum + calculateCM(orderRow), 0);
export const totalPurchaseCost = (orderRows: OrderRow[]) =>
	orderRows.reduce((sum, orderRow) => sum + (orderRow.purchaseCost ?? 0) * (orderRow.tierQuantity ?? 0), 0);

// Helper functions for calculating
const calculateDiscountPercent = (listPrice: number | undefined, price: number | undefined) => {
	if (!listPrice) return 0;
	if (!price) return 100;

	const decimals = Tools.AppService.getMetadata().params.OrderedProductPriceDecimals;
	return parseFloat(((100 * Math.max(listPrice - price, 0)) / listPrice).toFixed(decimals));
};
const calculateDiscount = (quantity: number | undefined, listPrice: number | undefined, price: number | undefined) =>
	(quantity ?? 0) * Math.max((listPrice ?? price ?? 0) - (price ?? 0), 0);
const calculatePrice = (listPrice: number | undefined, discount: number | undefined, quantity: number | undefined) =>
	quantity ? (listPrice ?? 0) - (discount ?? 0) / quantity : 0;
const calculateRowTotal = (price: number | undefined, quantity: number | undefined) => (price ?? 0) * (quantity ?? 0);
const calculateIntervalValue = (price: number | undefined, orderInterval: number, recurringInterval: number | null) =>
	((price ?? 0) * orderInterval) / (recurringInterval || 1);

const getRecurringValue = (rowTotal: number, orderInterval: OrderRowsState['orderInterval']) => {
	const metadata = Tools.AppService.getMetadata();
	const salesModel = metadata.params.SalesModel;
	if (salesModel !== 'rr' && !hasCMWithRR()) {
		return 0;
	}

	const salesModelOption = getSalesModelOption(metadata);
	const monthlyValue = (rowTotal ?? 0) / orderInterval;

	return (salesModelOption === 'arr' ? 12 : 1) * monthlyValue;
};

export const isRecurringProduct = (product: Product | undefined) => {
	return product?.isRecurring !== 0;
};

const hasExcessiveDiscount = (orderRow: OrderRow) => {
	if (orderRow.maxDiscount === null || orderRow.maxDiscount === undefined) {
		return undefined;
	}

	return (orderRow.discountPercent ?? 0) > orderRow.maxDiscount;
};

type Field = 'price' | 'discountPercent' | 'discount';

// Fixes orderRow fields by deducing fields the two known fields (field parameter and listPrice)
const fixOrderRowFromField = (orderRow: OrderRow, field: Field, orderInterval: number) => {
	const fixedRow: OrderRow = { ...orderRow };
	switch (field) {
		case 'price':
			fixedRow.discountPercent = calculateDiscountPercent(fixedRow.listPrice, fixedRow.price);
			fixedRow.discount = calculateDiscount(fixedRow.quantity, fixedRow.listPrice, fixedRow.price);
			break;
		case 'discountPercent':
			if (!fixedRow.listPrice) {
				break;
			}
			fixedRow.price = fixedRow.listPrice - (fixedRow.listPrice * (fixedRow.discountPercent ?? 0)) / 100;
			fixedRow.discount = calculateDiscount(fixedRow.quantity, fixedRow.listPrice, fixedRow.price);
			break;
		case 'discount':
			fixedRow.price = calculatePrice(fixedRow.listPrice, fixedRow.discount, fixedRow.quantity);
			fixedRow.discountPercent = calculateDiscountPercent(fixedRow.listPrice, fixedRow.price);
			break;
	}

	fixedRow.hasExcessiveDiscount = hasExcessiveDiscount(fixedRow);

	fixedRow.orderRowTotal = calculateRowTotal(fixedRow.price, fixedRow.quantity);
	fixedRow.recurringValue =
		isRecurringProduct(fixedRow.product) && orderInterval > 0
			? getRecurringValue(fixedRow.orderRowTotal, orderInterval)
			: 0;

	return fixedRow;
};

export const calculateIntervalValues = (
	row: OrderRow,
	oldInterval: OrderRowsState['orderInterval'],
	orderInterval?: OrderRowsState['orderInterval']
) => {
	const product = row.product;
	const currentOrderInterval = orderInterval || oldInterval;

	if (isRecurringProduct(product) && currentOrderInterval > 0) {
		let setPrice = true;
		if (product?.category) {
			const category = Tools.AppService.getProductCategories().find(c => c.id === product.category?.id);

			const formula = (
				Tools.AppService.getCustomFields('orderrow').find(
					field => field.id === category?.calculatingField?.id
				) as CalculationCustomField | undefined
			)?.formula;

			if (formula && calculateField.isValidAsValue(formula)) {
				setPrice = false;
			}
		}
		const interval = orderInterval
			? oldInterval
			: product?.recurringInterval ?? getRecurringProductDefaultInterval(Tools.AppService.getMetadata());
		row.purchaseCost = calculateIntervalValue(row.purchaseCost, currentOrderInterval, interval);
		if (setPrice) {
			row.listPrice = calculateIntervalValue(row.listPrice, currentOrderInterval, interval);
			row.price = calculateIntervalValue(row.price, currentOrderInterval, interval);
			if (row.defaultPriceListPrice) {
				row.defaultPriceListPrice = calculateIntervalValue(
					row.defaultPriceListPrice,
					currentOrderInterval,
					interval
				);
			}
		}
		row.discount = calculateDiscount(row.quantity, row.listPrice, row.price);
		row.orderRowTotal = calculateRowTotal(row.price, row.quantity);
		row.recurringValue = getRecurringValue(row.orderRowTotal, currentOrderInterval);
	}

	row.bundleRows = row.bundleRows?.map(bundleRow =>
		calculateIntervalValues(bundleRow, oldInterval, orderInterval)
	) as unknown as OrderRow['bundleRows'];

	return row;
};

export const setCustomfieldAsPrice = (orderRow: OrderRow) => {
	if (orderRow.product?.category) {
		const category = Tools.AppService.getProductCategories().find(c => c.id === orderRow.product?.category?.id);
		const formula = (
			Tools.AppService.getCustomFields('orderrow').find(field => field.id === category?.calculatingField?.id) as
				| CalculationCustomField
				| undefined
		)?.formula;

		if (
			formula &&
			calculateField.isValidAsValue(formula) &&
			Tools.FeatureHelper.isAvailable('CALCULATING_FIELDS')
		) {
			orderRow.listPrice = calculateField.calculate(formula, orderRow);
			orderRow.price = orderRow.listPrice;
		}
	}
	return orderRow;
};

export const _setOrderInterval = (orderInterval: number) => (dispatch: Dispatch, state: OrderRowsState) => {
	const orderRows = state.orderRows.map(row => calculateIntervalValues(row, state.orderInterval, orderInterval));
	dispatch({ type: Actions.ORDER_INTERVAL_CHANGED, orderRows, orderInterval });
};

const getDefaultPriceListId = () => {
	return Tools.AppService.getPriceLists().find(list => list.isDefault)!.id;
};

const getPriceListIdFromOrderRow = (orderRow: Readonly<OrderRow>) => {
	let priceListId = orderRow.priceListId;
	if (!priceListId) {
		priceListId = getDefaultPriceListId();
	}
	return priceListId;
};

const getOrderRow = (orderRows: OrderRowsState['orderRows'], uuid: number) => {
	const orderRow = orderRows.find(row => row.uuid === uuid);
	return { ...orderRow } as OrderRow;
};

const getPriceInfo = (currency: string, product: Product, priceListId: number, quantity = 1) => {
	const currencies = Tools.AppService.getMetadata().customerCurrencies;
	const defaultPriceListId = getDefaultPriceListId();
	const isMasterCurrency = currencies.find(c => c.iso === currency)?.masterCurrency ?? false;
	const isTiered = product.tiers?.length > 0;

	// If the product has no price in the selected price list, we use the default price list instead
	let productCurrencies = product.currencies.filter(c => c.priceListId === priceListId);
	if (!productCurrencies?.length) {
		productCurrencies = product.currencies.filter(c => c.priceListId === defaultPriceListId);
	}

	let productTiers = product.tiers.filter(t => t.priceListId === priceListId);
	if (isTiered && !productTiers?.length) {
		productTiers = product.tiers.filter(t => t.priceListId === defaultPriceListId);
	}

	const productPrice = productCurrencies.find(c => c.currency === currency);
	const listPrice = isTiered ? getTierPriceByQuantity({ productTiers, quantity, currency }) : productPrice?.price;
	const purchaseCost = productPrice?.purchaseCost;

	if (Tools.FeatureHelper.hasSoftDeployAccess('SAFEGUARD_PRICE_DEFAULT')) {
		// products that hasn't been saved in a long time can have no currencies and only product.listPrice/purchaseCost
		if (isMasterCurrency && priceListId === defaultPriceListId) {
			return {
				listPrice: listPrice ?? product.listPrice,
				purchaseCost: purchaseCost ?? product.purchaseCost
			};
		} else {
			return { listPrice: listPrice ?? 0, purchaseCost: purchaseCost ?? 0 };
		}
	}

	if (isMasterCurrency) {
		return {
			listPrice: listPrice || product.listPrice,
			purchaseCost: purchaseCost || product.purchaseCost
		};
	} else {
		return { listPrice: listPrice ?? 0, purchaseCost: purchaseCost ?? 0 };
	}
};

export const getMaxDiscount = (orderRow: OrderRow) => {
	const product = orderRow.product;
	const hasMaxDiscount =
		Tools.FeatureHelper.hasSoftDeployAccess('MAX_DISCOUNT') &&
		Tools.FeatureHelper.isAvailable(Tools.FeatureHelper.Feature.MAX_DISCOUNT);

	if (!product || !hasMaxDiscount) {
		return undefined;
	}

	let maxDiscount;
	if (product.tiers?.length) {
		maxDiscount = getTierFromOrderRow(orderRow)?.tier.maxDiscount;
	} else if (product.bundle?.length && product.currencies?.length) {
		maxDiscount = product.currencies[0]?.maxDiscount;
	} else {
		maxDiscount = (product.currencies || []).find(c => c.priceListId === orderRow.priceListId)?.maxDiscount;
	}

	if (maxDiscount !== null && maxDiscount !== undefined) {
		maxDiscount *= 100;
	}

	return maxDiscount;
};

export const resetAndGetOrderRow = (
	orderRow: Readonly<OrderRow>,
	currency: string,
	orderInterval: number,
	quantity = 1,
	isBundleRow: boolean = false
): OrderRow => {
	const product = orderRow.product;
	if (!product) {
		return orderRow;
	}

	const priceListId = getPriceListIdFromOrderRow(orderRow);

	const priceInfo = getPriceInfo(currency, product, priceListId, quantity);
	let { listPrice } = priceInfo;
	const { purchaseCost } = priceInfo;
	const price = listPrice;

	let newOrderRow = { ...orderRow };

	const priceLists = Tools.AppService.getPriceLists();
	const priceList = priceLists.find(priceList => priceList.id === priceListId);

	newOrderRow.priceListShowDiscount = false;
	if (priceList?.showDiscount && !priceList.isDefault) {
		const { listPrice: defaultListPrice } = getPriceInfo(currency, product, getDefaultPriceListId(), quantity);

		if (defaultListPrice > listPrice) {
			if (isBundleRow) {
				newOrderRow.defaultPriceListPrice = defaultListPrice;
			} else {
				listPrice = defaultListPrice;
			}
			newOrderRow.priceListShowDiscount = true;
		}
	}

	newOrderRow.discountPercent = 0;
	newOrderRow.discount = 0;
	newOrderRow.quantity = 1;
	newOrderRow.tierQuantity = 1;
	newOrderRow.orderRowTotal = listPrice;
	newOrderRow.price = price;
	newOrderRow.listPrice = listPrice;
	newOrderRow.purchaseCost = purchaseCost;
	newOrderRow.recurringValue = 0;
	newOrderRow.bundleRows = [];

	newOrderRow.maxDiscount = getMaxDiscount(newOrderRow);
	newOrderRow.hasExcessiveDiscount = hasExcessiveDiscount(newOrderRow);

	if (newOrderRow.priceListShowDiscount) {
		newOrderRow = fixOrderRowFromField(newOrderRow, 'price', orderInterval);
	}
	if (product.category) {
		const category = Tools.AppService.getProductCategories().find(c => c.id === product.category?.id);

		if (category?.calculatingField?.formula && calculateField.isValidAsValue(category?.calculatingField.formula)) {
			newOrderRow = setCustomfieldAsPrice(newOrderRow);
		}
	}
	return calculateIntervalValues(newOrderRow, orderInterval);
};

export const getBundleRow = (
	bundleRow: Readonly<OrderRow>,
	currency: string,
	orderInterval: number,
	quantity: number,
	bundlePriceAdjustment: number,
	adjustable?: boolean
): OrderRow => {
	const resetBundleRow = resetAndGetOrderRow(bundleRow, currency, orderInterval, quantity, true);
	// BundleRows should not themself have bundleRows ;P
	delete resetBundleRow.bundleRows;

	if (resetBundleRow.product) {
		(resetBundleRow as BundleRow).productId = resetBundleRow.product.id;
	}

	resetBundleRow.price = resetBundleRow.bundleRowPrice ?? resetBundleRow.price! * bundlePriceAdjustment;
	resetBundleRow.purchaseCost = resetBundleRow.bundleRowCost ?? resetBundleRow.purchaseCost;
	resetBundleRow.quantity = isTieredTotalOrderRow(resetBundleRow) && quantity !== 0 ? 1 : quantity;
	resetBundleRow.tierQuantity = quantity;
	resetBundleRow.latestDiscountChange = 'percent';
	resetBundleRow.adjustable = adjustable;

	return fixOrderRowFromField(resetBundleRow, 'price', orderInterval);
};

export const resetAndGetBundleOrderRow = (
	orderRow: Readonly<OrderRow>,
	currency: string,
	orderInterval: number,
	oldQuantity?: number,
	resetBundle?: boolean
): OrderRow => {
	const product = orderRow.product;
	if (!product) {
		return orderRow;
	}

	const priceListId = getPriceListIdFromOrderRow(orderRow);
	const bundlePriceAdjustment = orderRow.product?.bundlePriceAdjustment ?? 1;

	let resetDiscount = false;
	const bundleRows = product.bundle.map(
		({ tierQuantity, product, adjustable, bundleRowPrice, bundleRowCost, resetOrderDiscount }, index) => {
			if (resetOrderDiscount) {
				resetDiscount = true;
			}
			return getBundleRow(
				{
					uuid: index,
					product,
					priceListId,
					bundleRowPrice: resetBundle ? undefined : bundleRowPrice,
					bundleRowCost: resetBundle ? undefined : bundleRowCost
				},
				currency,
				orderInterval,
				tierQuantity,
				bundlePriceAdjustment,
				adjustable
			);
		}
	);

	const quantity = oldQuantity ? oldQuantity : 1;
	let price = totalAmount(bundleRows) - totalDiscount(bundleRows);
	const purchaseCost = totalPurchaseCost(bundleRows);
	const orderRowTotal = calculateRowTotal(price, quantity);
	const showDiscount = bundleRows.length && bundleRows[0].priceListShowDiscount;

	let listPrice = price;
	let discount = 0;
	let discountPercent = 0;
	let $percentPlaceholder = orderRow.$percentPlaceholder;
	if (showDiscount) {
		const defaultPricelistRows = bundleRows.map(row => ({
			...row,
			listPrice: (row.defaultPriceListPrice || row.listPrice || 0) * bundlePriceAdjustment
		}));
		listPrice = totalAmount(defaultPricelistRows);
		discountPercent = calculateDiscountPercent(listPrice, price);
		discount = listPrice - price;
		$percentPlaceholder = '' + discountPercent;
	}

	if (orderRow.discountPercent && orderRow.discountPercent !== discountPercent && !resetDiscount && !resetBundle) {
		price = listPrice - (listPrice * orderRow.discountPercent) / 100;
		discountPercent = orderRow.discountPercent;
		discount = calculateDiscount(quantity, listPrice, price);
	} else if (orderRow.discount && orderRow.discount !== discount && !resetDiscount && !resetBundle) {
		price = listPrice - (orderRow.discount ?? 0);
		discount = orderRow.discount;
		discountPercent = calculateDiscountPercent(listPrice, price);
		$percentPlaceholder = '' + discountPercent;
	}

	const recurringValue = isRecurringProduct(product)
		? (totalRecurringValue(bundleRows) * (100 - discountPercent)) / 100
		: 0;

	const newOrderRow = {
		...orderRow,
		bundleRows: bundleRows as unknown as OrderRow['bundleRows'],
		discount,
		discountPercent,
		listPrice,
		orderRowTotal,
		price,
		purchaseCost,
		quantity,
		recurringValue,
		$percentPlaceholder,
		tierQuantity: quantity,
		$listPrice: listPrice,
		$discount: discount,
		$discountPercent: discountPercent
	};

	newOrderRow.maxDiscount = getMaxDiscount(newOrderRow);
	newOrderRow.hasExcessiveDiscount = hasExcessiveDiscount(newOrderRow);

	return newOrderRow;
};

export const fixInitialOrderRows = (orderRows: OrderRow[], orderInterval: number, priceListId?: number) =>
	orderRows.map(row => {
		row.priceListId = priceListId;
		row.maxDiscount = undefined;
		const fixedRow = fixOrderRowFromField(row, 'price', orderInterval);
		fixedRow.maxDiscount = getMaxDiscount(fixedRow);
		if (fixedRow.initExcessiveDiscount) {
			fixedRow.hasExcessiveDiscount = hasExcessiveDiscount(fixedRow);
		}
		return fixedRow;
	});

export const appendOrderRows =
	(orderRows: OrderRow[], currency?: string) => async (dispatch: Dispatch, state: OrderRowsState) => {
		const orderRowsToAdd = [];
		for (const orderRow of orderRows) {
			const uuid = Date.now();
			const priceListId = getPriceListIdFromOrderRow(state.orderRows[0] ?? {});

			let newRow: OrderRow = {
				...orderRow,
				uuid,
				justAdded: !orderRow,
				priceListId,
				id: undefined
			};

			const productId = orderRow.productId || orderRow.product?.id;
			if (productId) {
				const foundProduct = await getProduct(productId);
				if (foundProduct) {
					newRow.product = foundProduct;
					if (orderRow.bundleRows) {
						newRow.product = updateBundleRows(foundProduct, orderRow.bundleRows);
					}
				}
			}

			if (orderRow.quantity) {
				newRow.tierQuantity = orderRow.quantity;
			}
			if (orderRow.bundleRows?.length && orderRow.product?.bundle && currency) {
				// eslint-disable-next-line @typescript-eslint/no-use-before-define
				newRow = bundleUpdateHandler(state, newRow, orderRow.product, currency);
			}
			if (orderRow.discount) {
				// eslint-disable-next-line @typescript-eslint/no-use-before-define
				newRow = discountUpdateHandler(state, newRow, orderRow.discount);
			}
			if (orderRow.quantity && currency) {
				// eslint-disable-next-line @typescript-eslint/no-use-before-define
				newRow = quantityUpdateHandler(state, newRow, orderRow.quantity, currency);
			}

			orderRowsToAdd.push(newRow);
		}
		dispatch({
			type: Actions.SET_ORDER_ROW,
			orderRows: [...state.orderRows, ...orderRowsToAdd]
		});
	};

export const appendOrderRow =
	(oldRow?: OrderRow, currency?: string, tracker?: NewSubscriptionTrackerType) =>
	(dispatch: Dispatch, state: OrderRowsState) => {
		const uuid = Date.now();
		const priceListId = getPriceListIdFromOrderRow(state.orderRows[0] ?? {});

		let orderRow: OrderRow = {
			...oldRow,
			uuid,
			justAdded: !oldRow,
			priceListId,
			id: undefined,
			hasExcessiveDiscount: oldRow ? hasExcessiveDiscount(oldRow) : undefined
		};

		if (oldRow?.bundleRows?.length && oldRow?.product && currency) {
			// eslint-disable-next-line @typescript-eslint/no-use-before-define
			orderRow = bundleUpdateHandler(state, orderRow, oldRow.product, currency);
		}

		if (oldRow?.price && currency) {
			// eslint-disable-next-line @typescript-eslint/no-use-before-define
			orderRow = priceUpdateHandler(state, orderRow, oldRow.price, currency);
		}
		if (oldRow?.discount) {
			// eslint-disable-next-line @typescript-eslint/no-use-before-define
			orderRow = discountUpdateHandler(state, orderRow, oldRow.discount);
		}
		if (oldRow?.quantity && currency) {
			// eslint-disable-next-line @typescript-eslint/no-use-before-define
			orderRow = quantityUpdateHandler(state, orderRow, oldRow.quantity, currency);
		}

		dispatch({
			type: Actions.SET_ORDER_ROW,
			orderRows: [...state.orderRows, orderRow]
		});

		tracker?.incrementValueAndTrack(oldRow ? 'duplicateOrderRow' : 'addOrderRow');
	};

export const removeOrderRow =
	(uuid: OrderRow['uuid'], tracker?: NewSubscriptionTrackerType) => (dispatch: Dispatch, state: OrderRowsState) => {
		const priceListId = getPriceListIdFromOrderRow(state.orderRows[0]);
		const orderRows =
			state.orderRows.length === 1
				? [{ uuid: 0, priceListId: priceListId }]
				: state.orderRows.filter(orderRow => orderRow.uuid !== uuid);

		dispatch({
			type: Actions.SET_ORDER_ROW,
			orderRows
		});
		tracker?.incrementValueAndTrack('removeOrderRow');
	};

const productUpdateHandler = (state: OrderRowsState, orderRow: OrderRow, product: Product, currency: string) => {
	orderRow.product = product;
	orderRow = product.bundle?.length
		? resetAndGetBundleOrderRow(orderRow, currency, state.orderInterval, undefined, true)
		: resetAndGetOrderRow(orderRow, currency, state.orderInterval);

	if (orderRow) {
		const productCategories = Tools.AppService.getProductCategories();
		const customFieldsToShow = (
			productCategories.find(pc => pc.id === orderRow?.product?.category?.id)?.orderRowFields || []
		).map(f => f.id);

		if (customFieldsToShow.length) {
			orderRow.custom = orderRow.custom?.filter(cf => customFieldsToShow.includes(cf.fieldId));
		}
	}
	return orderRow;
};

export const setProduct =
	(uuid: OrderRow['uuid'], product: Product, currency: string, tracker?: NewSubscriptionTrackerType) =>
	(dispatch: Dispatch, state: OrderRowsState) => {
		let orderRow = getOrderRow(state.orderRows, uuid);
		if (!orderRow) return;

		orderRow = productUpdateHandler(state, orderRow, product, currency);

		dispatch({ type: Actions.UPDATE_ORDER_ROW, orderRow });
		tracker?.incrementValueAndTrack('editOrderRow');
	};

export const bundleUpdateHandler = (state: OrderRowsState, orderRow: OrderRow, product: Product, currency: string) => {
	orderRow.product = product;

	let discountCreatedByPriceList = false;
	if (orderRow.bundleRows?.length && orderRow.bundleRows[0].priceListShowDiscount) {
		const bundleAdjustedListPrice = orderRow.bundleRows.reduce(
			(sum, bundleRow) => sum + bundleRow.price * bundleRow.quantity,
			0
		);
		discountCreatedByPriceList = bundleAdjustedListPrice.toFixed(1) === orderRow.price?.toFixed(1);
	}

	const newOrderRow = resetAndGetBundleOrderRow(orderRow, currency, state.orderInterval, orderRow.quantity);

	// Need to set this for orders to show discount percent
	if (discountCreatedByPriceList) {
		const decimals = Tools.AppService.getMetadata().params.OrderedProductPriceDecimals;
		newOrderRow.$percentPlaceholder = newOrderRow.discountPercent?.toFixed(decimals);
	}
	return newOrderRow;
};

export const updatedBundleOrderRow =
	(
		uuid: OrderRow['uuid'],
		product: Product,
		currency: string,
		existingOrderRow?: OrderRow,
		tracker?: NewSubscriptionTrackerType
	) =>
	(dispatch: Dispatch, state: OrderRowsState) => {
		const orderRow = getOrderRow(state.orderRows, uuid) || existingOrderRow;
		if (!orderRow) return;

		const newOrderRow = bundleUpdateHandler(state, orderRow, product, currency);
		dispatch({ type: Actions.UPDATE_ORDER_ROW, orderRow: newOrderRow });
		tracker?.incrementValueAndTrack('editOrderRowProductBundle');
	};

const priceUpdateHandler = (
	state: OrderRowsState,
	orderRow: OrderRow,
	price: number,
	currency: string,
	fromBundle?: boolean
) => {
	let priceListId = getPriceListIdFromOrderRow(orderRow);
	if (orderRow.priceListShowDiscount && !fromBundle) {
		priceListId = getDefaultPriceListId();
	}
	const { listPrice: productListPrice } = getPriceInfo(
		currency,
		orderRow!.product!,
		priceListId,
		orderRow.tierQuantity
	);

	const originalListPrice = orderRow.product?.isRecurring
		? (orderRow.listPrice = calculateIntervalValue(
				productListPrice,
				state.orderInterval,
				orderRow!.product!.recurringInterval ??
					getRecurringProductDefaultInterval(Tools.AppService.getMetadata())
		  ))
		: (orderRow.listPrice = productListPrice);
	const priceIsHigherThanListPrice = (price ?? 0) > (originalListPrice ?? 0);

	if (fromBundle) {
		orderRow.bundleRowPrice = price;
	}
	orderRow.price = price;
	orderRow.listPrice = priceIsHigherThanListPrice ? orderRow.price : originalListPrice;

	if (!priceIsHigherThanListPrice) {
		orderRow.latestDiscountChange = 'absolute';
	}

	orderRow = fixOrderRowFromField(orderRow, 'price', state.orderInterval);
	return orderRow;
};

export const setPrice =
	(
		uuid: OrderRow['uuid'],
		price: OrderRow['price'],
		currency: string,
		fromBundle?: boolean,
		tracker?: NewSubscriptionTrackerType
	) =>
	(dispatch: Dispatch, state: OrderRowsState) => {
		let orderRow = getOrderRow(state.orderRows, uuid);
		if (!orderRow?.product || price === undefined || isNaN(price)) return;

		orderRow = priceUpdateHandler(state, orderRow, price, currency, fromBundle);

		if (fromBundle) {
			orderRow.resetOrderDiscount = true;
		}
		dispatch({ type: Actions.UPDATE_ORDER_ROW, orderRow });
		tracker?.incrementRowValueDebounce('price');
	};

function getTierPriceByQuantity({
	productTiers,
	quantity,
	currency
}: {
	productTiers: Product['tiers'];
	quantity: number | undefined;
	currency: Currency['currency'];
}) {
	const tiers = getTierFromQuantity(productTiers, quantity);
	return (
		tiers?.tier?.currencies.find((t: { currency: string; value: number }) => t.currency === currency)?.value ?? 0
	);
}

export const quantityUpdateHandler = (
	state: OrderRowsState,
	orderRow: OrderRow,
	quantity: number | undefined,
	currency: string
) => {
	const prevQuantity = orderRow.quantity;
	const product = orderRow!.product!;
	let tierChanged = false;
	if (product.tiers?.length) {
		let productTiers = product.tiers.filter(tier => tier.priceListId === orderRow!.priceListId);
		const defaultPriceListId = getDefaultPriceListId();

		if (!productTiers.length) {
			productTiers = product.tiers.filter(tier => tier.priceListId === defaultPriceListId);
		}

		const tierPriceInput = {
			quantity,
			productTiers,
			currency
		};

		orderRow.listPrice = getTierPriceByQuantity(tierPriceInput);
		let oldListPrice = getTierPriceByQuantity({ ...tierPriceInput, quantity: orderRow.tierQuantity });

		const recurringInterval =
			product?.recurringInterval ?? getRecurringProductDefaultInterval(Tools.AppService.getMetadata());
		if (product.isRecurring && state.orderInterval > 0) {
			orderRow.listPrice = calculateIntervalValue(orderRow.listPrice, state.orderInterval, recurringInterval);
			oldListPrice = calculateIntervalValue(oldListPrice, state.orderInterval, recurringInterval);
		}

		tierChanged = orderRow.listPrice !== oldListPrice;

		const isBundleRow = !!orderRow.defaultPriceListPrice;
		if (orderRow.priceListShowDiscount && !isBundleRow) {
			const defaultProductTiers = product.tiers.filter(tier => tier.priceListId === defaultPriceListId);
			let defaultListPrice = getTierPriceByQuantity({
				...tierPriceInput,
				productTiers: defaultProductTiers
			});

			if (product.isRecurring && state.orderInterval > 0) {
				defaultListPrice = calculateIntervalValue(defaultListPrice, state.orderInterval, recurringInterval);
			}

			// Need to update price/discount here for the discount adjust below to work
			const discountCreatedByPriceList = orderRow.price === oldListPrice;
			if (discountCreatedByPriceList) {
				orderRow.price = orderRow.listPrice;
				orderRow.discount = calculateDiscount(orderRow.quantity, defaultListPrice, orderRow.price);
			}
			orderRow.listPrice = defaultListPrice;
		}
	}

	orderRow.quantity = isTieredTotalOrderRow(orderRow) && quantity !== 0 ? 1 : quantity;
	orderRow.tierQuantity = quantity;

	if (orderRow.discount || tierChanged || (orderRow.latestDiscountChange === 'percent' && orderRow.discountPercent)) {
		// Keep the same discount percent
		if (orderRow.latestDiscountChange === 'percent') {
			orderRow.price = ((orderRow.listPrice ?? 0) * (100 - (orderRow.discountPercent ?? 0))) / 100;
			orderRow.discount = calculateDiscount(orderRow.quantity, orderRow.listPrice, orderRow.price);
		}
		// Keep the same absolute discount per item
		else if (prevQuantity && orderRow.quantity) {
			const discountPerItem = (orderRow.discount ?? 0) / prevQuantity;
			orderRow.price = (orderRow.listPrice ?? 0) - discountPerItem;
			orderRow.discount = discountPerItem * orderRow.quantity;
			orderRow.discountPercent = calculateDiscountPercent(orderRow.listPrice, orderRow.price);
		}
	}
	orderRow = setCustomfieldAsPrice(orderRow);

	if (tierChanged) {
		orderRow.maxDiscount = getMaxDiscount(orderRow);
	}

	orderRow.hasExcessiveDiscount = hasExcessiveDiscount(orderRow);

	orderRow.orderRowTotal = calculateRowTotal(orderRow.price, orderRow.quantity);
	orderRow.recurringValue =
		isRecurringProduct(product) && state.orderInterval > 0
			? getRecurringValue(orderRow.orderRowTotal, state.orderInterval)
			: 0;
	return orderRow;
};

export const setQuantity =
	(
		uuid: OrderRow['uuid'],
		quantity: OrderRow['quantity'],
		currency: string,
		fromBundle?: boolean,
		tracker?: NewSubscriptionTrackerType
	) =>
	(dispatch: Dispatch, state: OrderRowsState) => {
		quantity = quantity && quantity < 0 ? 1 : quantity;
		let orderRow = getOrderRow(state.orderRows, uuid);
		if (!orderRow?.product) return;

		orderRow = quantityUpdateHandler(state, orderRow, quantity, currency);

		if (fromBundle) {
			orderRow.resetOrderDiscount = true;
		}
		dispatch({ type: Actions.UPDATE_ORDER_ROW, orderRow });
		tracker?.incrementRowValueDebounce('quantity');
		// return ;
	};

const discountUpdateHandler = (state: OrderRowsState, orderRow: OrderRow, discount: number | undefined) => {
	orderRow.discount = discount;
	orderRow.latestDiscountChange = 'absolute';
	orderRow = fixOrderRowFromField(orderRow, 'discount', state.orderInterval);
	return orderRow;
};

export const setDiscount =
	(uuid: OrderRow['uuid'], discount: OrderRow['discount'], tracker?: NewSubscriptionTrackerType) =>
	(dispatch: Dispatch, state: OrderRowsState) => {
		let orderRow = getOrderRow(state.orderRows, uuid);
		if (!orderRow?.product || orderRow?.discount === discount) return;

		orderRow = discountUpdateHandler(state, orderRow, discount);

		dispatch({ type: Actions.UPDATE_ORDER_ROW, orderRow });
		tracker?.incrementRowValueDebounce('discount');
	};

const discountPercentUpdateHandler = (
	state: OrderRowsState,
	orderRow: OrderRow,
	discountPercent: number | undefined
) => {
	orderRow.discountPercent = discountPercent;
	orderRow.latestDiscountChange = 'percent';
	orderRow = fixOrderRowFromField(orderRow, 'discountPercent', state.orderInterval);
	return orderRow;
};

export const setDiscountPercent =
	(uuid: OrderRow['uuid'], discountPercent: OrderRow['discountPercent'], tracker?: NewSubscriptionTrackerType) =>
	(dispatch: Dispatch, state: OrderRowsState) => {
		let orderRow = getOrderRow(state.orderRows, uuid);
		if (!orderRow?.product || orderRow?.discountPercent === discountPercent) return;

		orderRow = discountPercentUpdateHandler(state, orderRow, discountPercent);

		dispatch({ type: Actions.UPDATE_ORDER_ROW, orderRow });
		tracker?.incrementRowValueDebounce('percent');
	};

const customFieldsUpdateHandler = (
	state: OrderRowsState,
	orderRow: OrderRow,
	customFields: EntityCustomField[],
	isValid: boolean
) => {
	orderRow.custom = customFields;
	orderRow.isValid = isValid;

	orderRow = setCustomfieldAsPrice(orderRow);
	orderRow.orderRowTotal = calculateRowTotal(orderRow.price, orderRow.quantity);
	orderRow.recurringValue = getRecurringValue(orderRow.orderRowTotal, state.orderInterval);
	return orderRow;
};

export const setCustomFields =
	(uuid: OrderRow['uuid'], customFields: EntityCustomField[], isValid: boolean) =>
	(dispatch: Dispatch, state: OrderRowsState) => {
		let orderRow = getOrderRow(state.orderRows, uuid);
		if (!orderRow) return;

		orderRow = customFieldsUpdateHandler(state, orderRow, customFields, isValid);

		dispatch({
			type: Actions.UPDATE_ORDER_ROW,
			orderRow
		});
	};

export const moveOrderRow =
	(uuid: OrderRow['uuid'], direction: 'up' | 'down', tracker?: NewSubscriptionTrackerType) =>
	(dispatch: Dispatch, state: OrderRowsState) => {
		const { orderRows } = state;
		const orderRowIndex = orderRows.findIndex(or => or.uuid === uuid);
		const swapWith = direction === 'up' ? -1 : 1;
		// https://www.reddit.com/r/ProgrammerHumor/comments/82hgtq/how_to_swap_two_variables/
		[orderRows[orderRowIndex], orderRows[orderRowIndex + swapWith]] = [
			orderRows[orderRowIndex + swapWith],
			orderRows[orderRowIndex]
		];

		dispatch({
			type: Actions.SET_ORDER_ROW,
			orderRows
		});
		tracker?.incrementRowValueDebounce('move');
	};

const purchaseCostUpdateHandler = (
	orderRow: OrderRow,
	purchaseCost: OrderRow['purchaseCost'],
	fromBundle?: boolean
) => {
	orderRow.purchaseCost = purchaseCost;

	if (fromBundle) {
		orderRow.bundleRowCost = purchaseCost;
	}
	return orderRow;
};

export const setPurchaseCost =
	(
		uuid: OrderRow['uuid'],
		purchaseCost: OrderRow['purchaseCost'] | undefined,
		fromBundle?: boolean,
		tracker?: NewSubscriptionTrackerType
	) =>
	(dispatch: Dispatch, state: OrderRowsState) => {
		let orderRow = getOrderRow(state.orderRows, uuid);
		if (!orderRow || purchaseCost === undefined || isNaN(purchaseCost)) {
			return;
		}
		orderRow = purchaseCostUpdateHandler(orderRow, purchaseCost, fromBundle);

		dispatch({
			type: Actions.UPDATE_ORDER_ROW,
			orderRow
		});
		tracker?.incrementRowValueDebounce('cost');
	};

export const setOrderRow =
	(uuid: OrderRow['uuid'], updates: OrderRowUpdatesWithOpts, tracker?: NewSubscriptionTrackerType) =>
	(dispatch: Dispatch, state: OrderRowsState) => {
		if (!updates || isEmpty(updates)) {
			return;
		}

		let orderRow = getOrderRow(state.orderRows, uuid);
		if (!orderRow) {
			return;
		}

		if (updates.product) {
			orderRow = productUpdateHandler(state, orderRow, updates.product, updates.opts.currency);
		}
		if (updates.custom) {
			orderRow = customFieldsUpdateHandler(state, orderRow, updates.custom, true);
		}
		if (updates.price) {
			orderRow = priceUpdateHandler(state, orderRow, updates.price, updates.opts.currency);
		}
		if (updates.quantity) {
			orderRow = quantityUpdateHandler(state, orderRow, updates.quantity, updates.opts.currency);
		}
		if (updates.discount) {
			orderRow = discountUpdateHandler(state, orderRow, updates.discount);
		}
		if (updates.discountPercent) {
			orderRow = discountPercentUpdateHandler(state, orderRow, updates.discountPercent);
		}
		if (updates.purchaseCost) {
			orderRow = purchaseCostUpdateHandler(orderRow, updates.purchaseCost);
		}
		if (updates.bundleProduct) {
			orderRow = bundleUpdateHandler(state, orderRow, updates.bundleProduct, updates.opts.currency);
		}
		dispatch({
			type: Actions.UPDATE_ORDER_ROW,
			orderRow
		});
		tracker?.incrementRowValueDebounce('setOrderRow');
	};
