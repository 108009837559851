import React from 'react';
import { Title } from '@upsales/components';
import BemClass from '@upsales/components/Utils/bemClass';

const TitleRenderType = ({ row }) => {
	const classes = new BemClass('TitleRenderType', 'widget-title-wrapper');
	return (
		<div className={classes.b()}>
			<Title size={row.size}>{row.text}</Title>
		</div>
	);
};

export default TitleRenderType;
