import { Button, DropDownMenu, Icon, IconName, Table, TableColumn, TableRow, Tooltip } from '@upsales/components';
import React from 'react';

type Props = {
	options: OptionProps[];
	icon: IconName;
	tooltipTitle: string;
};

type OptionProps = {
	icon: IconName;
	type: string;
	onclick: () => void;
	title: string;
};

const EntitiesDropdown = ({ options, icon, tooltipTitle }: Props) => {
	return (
		<DropDownMenu
			align="right"
			renderTrigger={(expanded, setExpanded) => (
				<Tooltip title={tooltipTitle} position="bottom">
					<Button type="link" onClick={setExpanded}>
						<Icon name={icon} />
					</Button>
				</Tooltip>
			)}
		>
			<Table>
				{options.map(item => (
					<TableRow key={item.type} onClick={item.onclick}>
						<TableColumn size="lg" icon={item.icon} title={item.title} />
					</TableRow>
				))}
			</Table>
		</DropDownMenu>
	);
};

export default EntitiesDropdown;
