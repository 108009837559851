import React, { useEffect, useRef, useState } from 'react';
import BemClass from '@upsales/components/Utils/bemClass';
import { Text, Icon, Input, Tooltip, Progress, Row, Column } from '@upsales/components';
import T from 'Components/Helpers/translate';
import MailTemplateSelectButton from 'Components/MailDrawers/Components/MailTemplateSelectButton/MailTemplateSelectButton';
import NewMailRecipientsRow from './NewMailRecipientsRow/NewMailRecipientsRow';
import NewMailTemplateRow from './NewMailTemplateRow/NewMailTemplateRow';
import {
	setSubject,
	setFrom,
	addRecipient,
	onTemplateChange,
	addRecipientCc,
	addRecipientBcc,
	removeRecipient,
	removeRecipientCc,
	removeRecipientBcc
} from '../../../MailContextHelpers';
import { useMailContext } from '../../../MailContext';
import './NewMailHeader.scss';
import { SlideFade } from 'App/components/animations';

type ContactBadgeProps = {
	id: number | string;
	name: string;
	locked: boolean;
	lockedTooltip?: string;
	onRemove: (id: number | string) => void;
};

export const ContactBadge = ({ id, name, locked, lockedTooltip, onRemove }: ContactBadgeProps) => {
	const classes = new BemClass('ContactBadge');
	return (
		<Tooltip title={locked ? lockedTooltip : undefined}>
			<Text className={classes.mod({ locked }).b()} key={id} color={locked ? 'grey-10' : 'bright-blue'}>
				{name}
				<Icon space="mls" name={locked ? 'lock' : 'times'} onClick={locked ? undefined : () => onRemove(id)} />
			</Text>
		</Tooltip>
	);
};

const NewMailHeader = () => {
	const classes = new BemClass('NewMailHeader');
	const [hideCc, setHideCc] = useState(true);
	const [hideBcc, setHideBcc] = useState(true);
	const maxLengthOfSubject = 150;

	const { state, dispatch } = useMailContext();
	const {
		recipients,
		from,
		subject,
		hasChanged,
		template: { id: templateId }
	} = state;

	const timeoutId = useRef<number>();
	useEffect(
		() => () => {
			clearTimeout(timeoutId.current);
		},
		[]
	);

	const handleCcButtonsBlur = (e: React.FocusEvent<HTMLInputElement>) => {
		const currentTarget = e.currentTarget;
		timeoutId.current = window.setTimeout(() => {
			if (!currentTarget?.contains(document.activeElement)) {
				setHideCc(true);
				setHideBcc(true);
			}
		}, 0);
	};

	const ccHiddenAndEmpty = hideCc && recipients.cc.length === 0;
	const bccHiddenAndEmpty = hideBcc && recipients.bcc.length === 0;

	return React.useMemo(
		() => (
			<div className={classes.b()}>
				<div className={classes.elem('recipientsRows').b()} onBlur={handleCcButtonsBlur}>
					<NewMailRecipientsRow
						hide={false}
						title={T('mail.to')}
						recipients={recipients.to}
						addRecipient={addRecipient}
						removeRecipient={removeRecipient}
						required
						allowOnlyOne // TODO: remove once we have multiple recipients support
						onlyContacts // TODO: remove once we have multiple recipients support
						limitRecipientsShown={true}
					/>
					<NewMailRecipientsRow
						hide={ccHiddenAndEmpty}
						title={T('mail.cc')}
						recipients={recipients.cc}
						addRecipient={addRecipientCc}
						removeRecipient={removeRecipientCc}
						allowRawEmail={true}
					/>
					<NewMailRecipientsRow
						hide={bccHiddenAndEmpty}
						title={T('mail.bcc')}
						recipients={recipients.bcc}
						addRecipient={addRecipientBcc}
						removeRecipient={removeRecipientBcc}
						allowRawEmail={true}
					/>
					<div className={classes.elem('recipientsRows').elem('ccButtons').b()} tabIndex={0}>
						{ccHiddenAndEmpty ? (
							<Tooltip title={T('mail.ccDesc')}>
								<Text onClick={() => setHideCc(false)}>{T('mail.cc')}</Text>
							</Tooltip>
						) : null}
						{bccHiddenAndEmpty ? (
							<Tooltip title={T('mail.bccDesc')}>
								<Text onClick={() => setHideBcc(false)}>{T('mail.bcc')}</Text>
							</Tooltip>
						) : null}
					</div>
				</div>
				<div className={classes.elem('row').b()}>
					<Text color="grey-11">{T('default.from') + ':'}</Text>
					{from ? (
						<ContactBadge
							name={from.name}
							id={from.id || from.email}
							locked={from.locked || false}
							lockedTooltip={T('mail.recipientLocked')}
							onRemove={() => dispatch(setFrom(undefined))}
						></ContactBadge>
					) : null}
				</div>
				<div className={classes.elem('row').b()}>
					<Text color="red" className={classes.elem('required').b()}>
						{'*'}
					</Text>
					<Text color="grey-11">{T('mail.subject') + ':'}</Text>
					<Row className={classes.elem('subjectInput').b()} align="center">
						<Column>
							<Input
								className={classes.elem('input').b()}
								onChange={e => dispatch(setSubject(e.target.value))}
								value={subject}
								maxLength={maxLengthOfSubject}
							/>
						</Column>

						<SlideFade visible={subject.length > 0}>
							<Column fixedWidth={20}>
								<Progress
									hideText
									className={classes.elem('subjectInputProgress').b()}
									percentage={(subject.length / maxLengthOfSubject) * 100}
									tooltipProps={{
										title: T('progress.limitTextTooltip', {
											progress: subject.length,
											max: maxLengthOfSubject
										}),
										distance: 18,
										position: templateId ? 'left' : 'top'
									}}
								/>
							</Column>
						</SlideFade>
					</Row>
					{!templateId ? (
						<MailTemplateSelectButton
							onTemplateChange={template => onTemplateChange(dispatch, template)}
							saveEnabled={hasChanged}
						/>
					) : null}
				</div>
				<NewMailTemplateRow />
			</div>
		),
		[subject, from, recipients, hideCc, hideBcc, templateId, hasChanged]
	);
};

export default NewMailHeader;
