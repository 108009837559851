import T from 'Components/Helpers/translate';
import React from 'react';
import { MAX_COLUMNS, ProductSearchSettingsCategoryColumn, ProductSearchState } from '../ProductSearch';
import type Product from 'App/resources/Model/Product';
import { getCorrectCustomFieldValue } from '../../../../components/CustomFieldValueText/CustomFieldValueText';
import logError from 'Helpers/logError';
import { getCFFieldIdFromFilterName, getCustomFieldConfig, match, parseFilters } from 'App/helpers/filterHelper';
import { AutoSizer, Column, Table } from 'react-virtualized';
import 'react-virtualized/styles.css';
import { Button, EllipsisTooltip, Icon, Text, Tooltip } from '@upsales/components';
import BemClass from '@upsales/components/Utils/bemClass';
import ProductAttributes from 'App/babel/attributes/ProductAttributes';

type Props = {
	className: string;
	state: ProductSearchState;
	dispatch: React.Dispatch<Partial<ProductSearchState>>;
	addOrderRow: (product: Product) => void;
	serverFilteredProducts: boolean;
};

const Products = ({ className, state, dispatch, addOrderRow, serverFilteredProducts }: Props) => {
	const classes = new BemClass('ProductList', className);

	const { activeFilters, addedProducts, categories, selectedCategory, settings, loadingProducts } = state;
	const isAdmin = Tools.AppService.getSelf().administrator;
	const selectedCategoryObj = categories.find(category => category.id === selectedCategory)!;
	const selectedCategoryColumns = selectedCategoryObj?.columns || [];

	const getFilteredProducts = () => {
		let filteredProducts = state.products;

		if (serverFilteredProducts) return filteredProducts;

		if (state.selectedCategory) {
			filteredProducts = filteredProducts.filter(product => product.category?.id === state.selectedCategory);
		}

		if (state.searchStr.length) {
			filteredProducts = filteredProducts.filter(
				product =>
					product.name.toLowerCase().includes(state.searchStr.toLowerCase()) ||
					product.articleNo?.toLowerCase().includes(state.searchStr.toLowerCase())
			);
		}

		const parsed = parseFilters(activeFilters, 'product', null, null, {
			getConfig: (filterName, type) => {
				const fieldId = getCFFieldIdFromFilterName(filterName) ?? undefined;
				const field = Tools.AppService.getCustomFields('product').find(cf => cf.id === fieldId);
				return getCustomFieldConfig({ name: filterName, fieldId, entityType: type, field });
			}
		}).build();

		filteredProducts = filteredProducts.filter(product => match(parsed.q, product, ProductAttributes));
		filteredProducts = filteredProducts.sort((a, b) => a.sortId - b.sortId);

		return filteredProducts;
	};

	const editColumns = () => {
		const columns = selectedCategoryColumns.map(column => {
			return `Custom_${column.fieldId}`;
		});

		// eslint-disable-next-line promise/catch-or-return
		Tools.$upModal
			.open('EditColumns', {
				attributes: null,
				columns: columns,
				selectables: [],
				customfields: selectedCategoryObj.customFields,
				maxColumns: MAX_COLUMNS,
				tableType: 'product'
			})
			.then((columns: string[]) => {
				const newCategoryColumns = columns.reduce(
					(res: ProductSearchSettingsCategoryColumn[], column, index) => {
						const parts = column.split('_');
						const fieldId = Number(parts[parts.length - 1]);
						if (isNaN(fieldId)) {
							logError(`Could not get field id from column ${column}`);
						} else {
							res.push({
								fieldId,
								sortId: index + 1
							});
						}
						return res;
					},
					[]
				);

				if (newCategoryColumns.length !== columns.length) return;

				const currentCategorySettings = settings.categories.find(
					category => category.categoryId === selectedCategory
				);

				let updatedCategories;
				if (currentCategorySettings) {
					updatedCategories = settings.categories.map(category =>
						category.categoryId === selectedCategory
							? { ...category, columns: newCategoryColumns }
							: category
					);
				} else {
					updatedCategories = [
						...settings.categories,
						{
							categoryId: selectedCategory,
							sortId: 0,
							columns: newCategoryColumns
						}
					];
				}

				const updatedCategorySettings = { ...settings, categories: updatedCategories };
				dispatch({ settings: updatedCategorySettings });
			});
	};
	const filteredProducts = getFilteredProducts().map(p => ({ ...p, isAdded: addedProducts.includes(p.id) }));

	const renderHeaderColumn = (text?: string) => {
		return <div className="TableHeader__column">{text ?? ''}</div>;
	};
	const renderColumn = (title?: JSX.Element, subtitle?: string) => {
		const classes = new BemClass('TableColumn').mod('lg').mod('align-left').mod({ 'has-subtitle': !!subtitle });
		return (
			<div className={classes.b()}>
				{title}
				{subtitle ? <div className={classes.elem('subtitle').mod('dark-grey').b()}>{subtitle}</div> : null}
			</div>
		);
	};
	const renderTextColumn = (title?: string, subtitle?: string) => {
		const overflowTooltip = (
			<EllipsisTooltip title={title ?? ''}>
				<Text>{title}</Text>
			</EllipsisTooltip>
		);
		return renderColumn(overflowTooltip, subtitle);
	};

	return (
		<div className={className}>
			<AutoSizer>
				{({ height, width }) => (
					<Table
						height={height}
						width={width}
						headerHeight={34}
						rowHeight={50}
						rowCount={filteredProducts.length}
						rowGetter={({ index }) => filteredProducts[index]}
					>
						<Column
							label={T('default.product')}
							cellRenderer={({ rowData }) => renderTextColumn(rowData.name, rowData.articleNo)}
							headerRenderer={() => renderHeaderColumn(T('default.product'))}
							dataKey="name"
							width={200}
							flexGrow={1}
						/>
						{selectedCategoryColumns.map(column => {
							return (
								<Column
									key={column.fieldId}
									label={selectedCategoryObj.customFields?.find(cf => cf.id === column.fieldId)?.name}
									dataKey={column.fieldId.toString()}
									width={200}
									cellRenderer={({ cellData }) => renderTextColumn(cellData)}
									cellDataGetter={({ rowData }) => {
										const customField = selectedCategoryObj.customFields?.find(
											cf => cf.id === column.fieldId
										);
										const customFieldValue =
											rowData.custom.find((custom: any) => custom.fieldId === column.fieldId)
												?.value ?? null;
										return getCorrectCustomFieldValue(customFieldValue, customField);
									}}
									headerRenderer={() =>
										renderHeaderColumn(
											selectedCategoryObj.customFields?.find(cf => cf.id === column.fieldId)?.name
										)
									}
									flexGrow={1}
								/>
							);
						})}
						<Column
							dataKey="isAdded"
							width={100}
							headerRenderer={() =>
								isAdmin && selectedCategoryObj.customFields?.length ? (
									<div className={classes.elem('edit-column-header').b()}>
										<Tooltip title={T('filters.editColumns')} position="left">
											<Button onClick={editColumns} size="sm" type="link">
												<Icon name="cog" />
											</Button>
										</Tooltip>
									</div>
								) : null
							}
							cellRenderer={({ cellData, rowData }) =>
								renderColumn(
									cellData ? (
										<Text color="green">
											<Icon name="check" />
											{T('account.added')}
										</Text>
									) : (
										<Button
											type="link"
											onClick={() => {
												addOrderRow(rowData);
												dispatch({ addedProducts: [...state.addedProducts, rowData.id] });
											}}
										>
											<Icon name="plus" />
										</Button>
									)
								)
							}
						/>
					</Table>
				)}
			</AutoSizer>
			{!loadingProducts && filteredProducts.length === 0 ? (
				<div className={classes.elem('no-filter-container').b()}>
					<img src="img/no-filters-match.svg" data-testid="no-match-image" />
					<Text size="xl">{T('productSearch.noMatch')}</Text>
					<Text size="lg" color="grey-12">
						{T('productSearch.tryAgain')}
					</Text>
				</div>
			) : null}
		</div>
	);
};

export default Products;
