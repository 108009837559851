import React, { ComponentProps } from 'react';
import { Card, Label, Row, Column, Button, Select } from '@upsales/components';
import BemClass from '@upsales/components/Utils/bemClass';
import T from 'Components/Helpers/translate';
import HiddenContactSelect from 'Components/Inputs/HiddenContactSelect';
import './EditPhonecallNoContact.scss';
import Contact from 'App/resources/Model/Contact';
import Client from 'App/resources/Model/Client';

interface Props {
	asAjax?: boolean;
	contactsLoading?: boolean;
	contacts: Contact[];
	client: Client;
	buttonRef: React.RefObject<any>;
	onContactSelect: (contact: Contact | null) => void;
	canCreateContacts: boolean;
	onCreateContact: () => void;
	anchor?: ComponentProps<typeof Select>['anchor'];
	scrollContainer?: ComponentProps<typeof Select>['scrollContainer'];
	disabled?: boolean;
}
const EditPhonecallNoContact = ({
	contactsLoading,
	contacts,
	client,
	buttonRef,
	onContactSelect,
	asAjax,
	canCreateContacts,
	onCreateContact,
	anchor,
	scrollContainer,
	disabled = false
}: Props) => {
	const classes = new BemClass('EditPhonecallNoContact');
	return (
		<Card space="ptxl prxl pbxl plxl" className={classes.b()} color="transparent" slice="grey-10">
			<Row>
				<Column>
					<Label>{T('todo.whoToContact')}</Label>
					<HiddenContactSelect
						asAjax={asAjax}
						canCreateContacts={canCreateContacts}
						onCreateContact={onCreateContact}
						contacts={contactsLoading ? [] : contacts}
						client={client}
						onChange={onContactSelect}
						anchor={anchor}
						scrollContainer={scrollContainer}
					>
						<div className={classes.elem('button-wrapper').b()} ref={buttonRef} />
						<Button loading={contactsLoading} disabled={disabled}>
							{T('default.addContact')}
						</Button>
					</HiddenContactSelect>
				</Column>
				<Column fixedWidth={70}>
					<img src="img/create-phonecall.svg" />
				</Column>
			</Row>
		</Card>
	);
};

export default EditPhonecallNoContact;
