import RequestBuilder from 'Resources/RequestBuilder';
import type { MultiSelect } from 'App/components/ListView/ListViewRenderHelpers';
import openModal from 'App/services/Modal';

export default function updateActivities(rb: RequestBuilder, entity: string, multiSelect: MultiSelect) {
	const opts = {
		customerId: Tools.AppService.getCustomerId(),
		filters: rb,
		entity,
		multiSelect
	};
	return openModal('MultiUpdateActivity', opts);
}
