import React, { Component } from 'react';
import PropTypes from 'prop-types';
import BemClass from '@upsales/components/Utils/bemClass';
import BorderBox from 'App/components/BorderBox';
import { Tooltip } from '@upsales/components';

import './index.scss';

class OutcomeTypeButton extends Component {
	render() {
		const { mainText, hoverText, disabled, selected, activeText, onClick, pendingOutcome, disabledTooltip } =
			this.props;
		const classNames = new BemClass('OutcomeTypeButton');

		return (
			<BorderBox
				boxShadow
				className={classNames.mod({ selected, pendingOutcome, noHover: disabled || !hoverText, disabled }).b()}
			>
				<div onClick={disabled ? undefined : onClick}>
					<Tooltip disabled={!disabledTooltip} title={disabledTooltip || ''}>
						<div className={classNames.elem('main-view').b()}>{mainText}</div>
						<div className={classNames.elem('hover-view').b()}>{hoverText}</div>
						<div className={classNames.elem('selected-view').b()}>{activeText}</div>
					</Tooltip>
				</div>
			</BorderBox>
		);
	}
}

OutcomeTypeButton.propTypes = {
	mainText: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
	hoverText: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
	activeText: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
	disabled: PropTypes.bool,
	selected: PropTypes.bool,
	onClick: PropTypes.func,
	disabledTooltip: PropTypes.string,
	pendingOutcome: PropTypes.bool
};

OutcomeTypeButton.defaultProps = {
	onClick: () => {}
};

export default OutcomeTypeButton;
