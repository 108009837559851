import React from 'react';
import ColumnChartWidgetBar from '../ColumnChartWidgetBar';
import { getPercent, getTickPercentages, getTooltip, loadDrilldown } from '../../chartHelper';
import bemClass from '@upsales/components/Utils/bemClass';
import { Tooltip } from '@upsales/components';
import '../ColumnChartWidgetBars/ColumnChartWidgetBars.scss';
import { RCWidgetData, RCWidgetDataRowShape, RCWidgetDataSubRow } from 'Resources/ReportWidget';
import ColumnChartWidgetScale from '../ColumnChartWidgetScale';
import type { RCDashboardWidget } from 'Resources/ReportDashboard';

type Props = {
	row: RCWidgetDataRowShape;
	data: RCWidgetData;
	showGoal: boolean;
	max: number;
	scaleMax: number;
	height: number;
	loading: boolean;
	maxValue: number;
	minValue: number;
	onClick?: React.MouseEventHandler<HTMLDivElement>;
	ticks?: number[];
	zeroTickIndex?: number;
	tooltipContainer?: React.RefObject<HTMLElement>;
	thinMode?: boolean;
	drilldown: boolean;
	config: RCDashboardWidget;
};

const sortRows = (rows: RCWidgetDataSubRow[]) => {
	const negativeRows = rows.filter(row => Number(row.sorting ?? row.progress) < 0);
	if (negativeRows.length) {
		rows = rows.filter(row => Number(row.sorting ?? row.progress) >= 0);
		rows.unshift(...negativeRows);
	}
	return rows;
};

const getCornerRounding = (index: number, subRow: RCWidgetDataSubRow, rowLength: number) => {
	let roundedBottom = false;
	let roundedTop = false;

	if (index === 0) {
		if (subRow.progress < 0) {
			roundedBottom = true;
		}
	}
	if (index === rowLength - 1) {
		if (subRow.progress > 0) {
			roundedTop = true;
		}
	}
	return { roundedBottom, roundedTop };
};

const ColumnChartWidgetStackedBars = ({
	row,
	maxValue,
	minValue = 0,
	data,
	onClick,
	ticks = [],
	zeroTickIndex = 0,
	tooltipContainer,
	thinMode,
	drilldown,
	config,
	showGoal,
	scaleMax
}: Props) => {
	const classes = new bemClass('ColumnChartWidgetBars').mod({
		'has-data': !!data.total?.progress,
		'has-negative-values': minValue < 0,
		'thin-mode': thinMode
	});
	const negativeBaseValue = minValue < 0 ? Math.abs(minValue) : 0;
	const max = negativeBaseValue ? negativeBaseValue + maxValue : maxValue;

	const shouldBeSorted = typeof row.rows[0]?.sorting === 'number' && !config.type.includes('REVENUE_RECOGNITION_');
	const sortedRows = shouldBeSorted ? sortRows(row.rows) : row.rows;

	const heightPercentage = sortedRows.map(row => {
		const absProgress = Math.abs(row.progress ?? 0);
		const percentage = (Math.max(1, absProgress) / max) * 100;
		return `${percentage}%`;
	});

	let bottom = 0;
	const columnExistsWithNegativeValue = minValue < 0;
	if (columnExistsWithNegativeValue) {
		const rowsNegativeValue = sortedRows.reduce((sum, subRow) => {
			sum += subRow.progress < 0 ? subRow.progress : 0;
			return sum;
		}, 0);
		bottom = Math.abs(minValue - rowsNegativeValue);
	}

	const { bars } = sortedRows.reduce(
		(memo: { bars: JSX.Element[] }, subRow, index) => {
			const subRowProg = subRow.progress ?? 0;
			if (!subRowProg) {
				return memo;
			}

			const { roundedBottom, roundedTop } = getCornerRounding(index, subRow, sortedRows.length);

			const subRowProgPercent = heightPercentage[index];
			const bottomPercent = getPercent(bottom, max);
			bottom += Math.abs(subRow.progress);
			const color = data.colors[subRow.key]?.color ?? 'green';
			memo.bars.push(
				<Tooltip
					key={subRow.key + '-tooltip'}
					html={true}
					title={getTooltip(subRowProg, 0, 0, data, false, color, subRow.label)}
					style={{ height: subRowProgPercent, bottom: bottomPercent }}
					theme="white"
					appendTo={() => tooltipContainer?.current ?? document.body}
				>
					<ColumnChartWidgetBar
						key={subRow.key}
						bottom={'0%'}
						height={'100%'}
						backgroundColor={color}
						thinMode={thinMode}
						cornerRounding={{ roundedTop, roundedBottom, radius: 4 }}
						onClick={
							drilldown && data.enableDrilldown
								? e => {
										e.stopPropagation();
										loadDrilldown([row.key.toString(), subRow.key.toString()], config);
								  }
								: undefined
						}
					/>
				</Tooltip>
			);
			return memo;
		},
		{ bars: [] }
	);
	if (showGoal) {
		const tickPercentages = getTickPercentages(ticks);
		const goalBlockPercent = (Math.abs(row.goal) / max) * 100;
		bars.push(
			<ColumnChartWidgetBar
				key={row.key + 'goal'}
				backgroundColor={data.colors?.goal?.color}
				height={`${goalBlockPercent}%`}
				bottom={`${tickPercentages[zeroTickIndex]}%`}
				left="20px"
				cornerRounding={{ roundedTop: true, roundedBottom: false, radius: 4 }}
				thinMode={thinMode}
				goal
			/>
		);
	}
	return (
		<div key={row.key} className={classes.b()} onClick={onClick ? onClick : undefined}>
			<div className={classes.elem('sub-row-wrap').b()}>
				<ColumnChartWidgetScale zeroTickIndex={zeroTickIndex} ticks={ticks} />
				<div>{bars}</div>
				{minValue < 0 ? (
					<div
						className={classes.elem('zero-stripe').b()}
						style={{ bottom: getPercent(Math.abs(minValue), max) }}
					/>
				) : null}
			</div>
		</div>
	);
};

export default ColumnChartWidgetStackedBars;
