import React, { useState, useEffect } from 'react';
import logError from 'App/babel/helpers/logError';
import MailBody from '../MailBody';
import MailAttachment from '../MailAttachment';
import MailHeader from '../Mail/MailHeader';
import BemClass from '@upsales/components/Utils/bemClass';
import { SlideFade } from 'App/components/animations';
import MailType, { Attachment } from 'App/resources/Model/Mail';
import File from 'App/babel/resources/File';

import './Mail.scss';
import { makeCancelable } from 'Helpers/promise';

type MailProps = {
	from: { name: string; email: string; type: string; id?: number };
	date: MailType['date'];
	recipients?: MailType['recipients'];
	events?: MailType['events'];
	attachments?: MailType['attachments'];
	body: string;
	close: () => void;
};

type AttachmentWithSize = Attachment & { size?: number };

const Mail: React.FC<MailProps> = ({ from, date, body, recipients, events = [], attachments = [], close }) => {
	const [expanded, setExpanded] = useState(true);

	const [attachmentsWithSize, setAttachmentsWithSize] = useState<AttachmentWithSize[]>(attachments ?? []);
	const classes = new BemClass('Mail');

	useEffect(() => {
		const idArray = attachments.filter(att => att.type === 'InternalFile').map(file => file.value);
		const filePromise = makeCancelable(File.find({ id: idArray }));

		filePromise.promise
			.then((res: { data: { id: number; size: number }[] }) => {
				const withSize = attachments.map(att => {
					const found = res.data.find(file => parseInt(att.value) === file.id);
					if (found) {
						return { ...att, size: found.size };
					}
					return att;
				});
				setAttachmentsWithSize(withSize);
			})
			.catch(error => logError(error, 'Could not get FileMetadata for mail attachments'));
		return () => {
			filePromise.cancel();
		};
	}, [attachments]);

	return (
		<div className={classes.b()}>
			<MailHeader
				from={from}
				date={date}
				recipients={recipients}
				events={events}
				attachments={attachments}
				expanded={expanded}
				onClick={() => setExpanded(!expanded)}
				close={close}
			/>
			<SlideFade direction="top" visible={expanded} height>
				<div className={classes.elem('mail-body').b()}>
					<MailBody body={body} />
					{attachmentsWithSize.length ? (
						<div className={classes.elem('attachments').b()}>
							{attachmentsWithSize.map((attachment, idx) => (
								<MailAttachment attachment={attachment} key={idx}></MailAttachment>
							))}
						</div>
					) : null}
				</div>
			</SlideFade>
		</div>
	);
};

export default Mail;
