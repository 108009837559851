export const RESET = '[MaxCeilingReachedOnSeats] Reset';
export const DEACTIVATE = '[MaxCeilingReachedOnSeats] Deactivate users';
export const ADDED_SEATS = '[MaxCeilingReachedOnSeats] Added seats';

export const PAGES = {
	addOrDeactivate: 'addOrDeactivate',
	userDeactivated: 'userDeactivated',
	seatsConfirmed: 'addOseatsConfirmedrDeactivate'
};

const pageOrder = [PAGES.addOrDeactivate, PAGES.userDeactivated, PAGES.seatsConfirmed];

export const initialState = {
	page: pageOrder[0]
};

const ACTION_HANDLERS = {
	[RESET]: () => ({ ...initialState }),
	[DEACTIVATE]: state => ({ ...state, page: pageOrder[1] }),
	[ADDED_SEATS]: state => ({ ...state, page: pageOrder[2] })
};

export default (state = { ...initialState }, action) => {
	const handler = ACTION_HANDLERS[action.type];
	return handler ? handler(state, action) : state;
};
