import PropTypes from 'prop-types';
import React from 'react';
import WidgetRender from './WidgetRender';
import IframeRender from './iframeRender';
import PostIframeRender from './postIframeRender';

import './UiElements.scss';

const propTypes = {
	elements: PropTypes.array.isRequired,
	object: PropTypes.object,
	position: PropTypes.object,
	type: PropTypes.string,
	onObjectChange: PropTypes.func,
	utils: PropTypes.object
};

class UiElements extends React.Component {
	constructor(props) {
		super(props);

		this.state = {
			integrations: {}
		};

		this.runClick = this.runClick.bind(this);
	}

	openAppAdmin(integrationId, tab, forUser) {
		Tools.$state.go('administration.integration', {
			tab: tab ? tab : null,
			id: integrationId,
			customerId: Tools.AppService.getCustomerId(),
			configure: forUser ? 'user' : 'client'
		});
	}

	getUser() {
		const self = Tools.AppService.getSelf();
		return {
			id: self.id,
			name: self.name
		};
	}

	runClick(data, config) {
		var components = {
			widget: WidgetRender
		};

		switch (data.click.type) {
			case 'widget':
				Tools.StandardIntegration.data(Tools.AppService.getCustomerId())
					.run({
						type: data.click.type,
						data: {
							object: this.props.object,
							user: this.getUser(),
							widgetType: config.widgetType
						},
						typeId: data.click.name,
						integrationId: config.integrationId
					})
					.then(response => {
						this.setState({
							integrations: response.data
						});
					})
					.catch(e => console.log('StandardIntegration widget run failed', e));
				break;
			case 'modal':
				Tools.$upModal.open('appWidget', {
					fullscreen: !!data.click.fullscreen,
					obj: this.props.object,
					integrationId: config.integrationId,
					title: config.integrationName,
					name: data.click.name,
					utils: this.props.utils,
					size: data.click.size
				});
				break;
			case 'window':
				if (data.click.window === 'self') {
					window.open(data.click.url, '_self');
				} else {
					window.open(data.click.url, '_blank');
				}
				break;
			case 'reload':
				return React.createElement(components[config.type], {
					config: config,
					name: config.name,
					object: this.props.object,
					runClick: this.runClick,
					getUser: this.getUser
				});
			case 'navigate':
				if (data.click.to === 'config') {
					this.openAppAdmin(config.integrationId.toString(), data.click.tab, data.click.user);
				} else if (Tools.ScriptService.open[data.click.to]) {
					Tools.ScriptService.open[data.click.to](data.click.id, data.click.tab, data.click.params);
				}
				break;
			case 'create':
				if (Tools.ScriptService.create[data.click.entity]) {
					Tools.ScriptService.create[data.click.entity](data.click.options);
				}
				break;
		}
	}

	renderElement(elementConfig, position) {
		var coords;
		var components = {
			widget: WidgetRender
		};

		if (position) {
			coords = {
				lat: position.coords.latitude,
				long: position.coords.longitude,
				accuracy: position.accuracy
			};
		}

		if (elementConfig.type === 'postIframe') {
			return React.createElement(PostIframeRender, {
				config: elementConfig,
				type: this.props.type,
				object: this.props.object,
				getUser: this.getUser,
				coords: coords,
				utils: this.props.utils
			});
		}

		if (elementConfig.type === 'iframe') {
			return React.createElement(IframeRender, {
				config: elementConfig,
				type: this.props.type,
				object: this.props.object,
				getUser: this.getUser,
				onObjectChange: this.props.onObjectChange,
				utils: this.props.utils
			});
		}

		return React.createElement(components[elementConfig.type], {
			config: elementConfig,
			name: elementConfig.name,
			object: this.props.object,
			runClick: this.runClick,
			getUser: this.getUser,
			integrations: this.state.integrations
		});
	}

	render() {
		const { elements } = this.props;

		const html = _.map(elements, elementConfig => {
			if (elementConfig.requireLocation) {
				if (!navigator.geolocation) {
					return;
				}

				navigator.geolocation.getCurrentPosition(position => {
					this.renderElement(elementConfig, position);
				});

				return <div />;
			} else {
				return this.renderElement(elementConfig);
			}
		});

		return (
			<div id="ui-elements-wrapper" className="ui-element-widget">
				{html.map((block, index) => (
					<div key={`index-block-${index}`}>{block}</div>
				))}
			</div>
		);
	}
}

UiElements.propTypes = propTypes;

export default UiElements;
