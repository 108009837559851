import React from 'react';
import BarchartWidgetBar from '../BarchartWidgetBar';
import { getPercent, getTooltip } from '../../chartHelper';
import bemClass from '@upsales/components/Utils/bemClass';
import { Text, Tooltip } from '@upsales/components';
import './BarchartWidgetBars.scss';
import { RCWidgetData, RCWidgetDataRowShape } from 'Resources/ReportWidget';

type Props = {
	row: RCWidgetDataRowShape;
	small?: boolean;
	maxValue: number;
	minValue: number;
	data: RCWidgetData;
	value: string;
	showGoal: boolean;
	onlyRightDirection?: boolean;
	calculatedWidth?: number;
};
const BarchartWidgetBars = ({
	row,
	small = false,
	maxValue,
	minValue,
	data,
	value,
	showGoal,
	onlyRightDirection = false,
	calculatedWidth
}: Props) => {
	const classes = new bemClass('BarchartWidgetBars').mod({ small });
	const progress = row.progress ?? 0;
	const remaining = row.remaining ? Math.abs(row.remaining) : 0;
	const goal = row.goal ?? 0;
	const reachedGoal = showGoal && progress >= goal && goal > 0;

	let widthPercentage = !row.progress ? '0%' : `${(Math.max(0.001, Math.abs(row.progress)) / maxValue) * 100}%`;
	if (calculatedWidth) {
		widthPercentage = `${parseFloat(widthPercentage) * (calculatedWidth / 100)}%`;
	}

	let left = 0;
	if (minValue < 0) {
		left = Math.abs(minValue - Math.min(row.rowMinProgress || row.progress, 0));
	}
	const leftPercent = onlyRightDirection ? '0%' : getPercent(left, maxValue);
	const progressPercent = getPercent(left + Math.abs(progress), maxValue);
	const goalPercent = getPercent(left + goal, maxValue);
	const totalPercent = showGoal
		? getPercent(Math.max(Math.max(progress, 0) + Math.max(remaining, 0), goal), maxValue)
		: progressPercent;

	return (
		<div key={row.key} className={classes.b()}>
			<Tooltip
				html={true}
				title={getTooltip(progress, goal, remaining, data, showGoal)}
				style={{ width: small ? '100%' : totalPercent, left: leftPercent }}
				theme="white"
			/>

			<BarchartWidgetBar
				key="progress"
				small={small}
				width={widthPercentage}
				left={leftPercent}
				backgroundColor={progress < 0 ? data.colors?.negativeProgress?.color : data.colors?.progress?.color}
				negative={progress < 0}
				roundedLeft={(progress < 0 || left === 0) && !onlyRightDirection}
				roundedRight={(progress > 0 && (!showGoal || goal === 0 || reachedGoal)) || onlyRightDirection}
			/>
			{showGoal ? (
				reachedGoal ? (
					<BarchartWidgetBar
						key="overGoal"
						small={small}
						width={getPercent(remaining, maxValue)}
						left={goalPercent}
						roundedRight
						backgroundColor={data.colors?.overGoal.color}
					/>
				) : goal > 0 ? (
					<BarchartWidgetBar
						key="left"
						small={small}
						width={getPercent(progress < 0 ? goal : remaining, maxValue)}
						left={progressPercent}
						backgroundColor={data.colors?.goal.color}
						roundedLeft={!(minValue < 0) && progress === 0}
						roundedRight
						future
					/>
				) : null
			) : null}
			{!small ? (
				<div className={classes.elem('floating-value').b()} style={{ right: 0 }}>
					<Text size="sm" color={progress < 0 ? 'red' : 'black'} bold>
						{value}
					</Text>
				</div>
			) : null}
			{minValue < 0 && !onlyRightDirection ? (
				<div
					className={classes.elem('zero-stripe').b()}
					style={{ left: getPercent(Math.abs(minValue), maxValue) }}
				/>
			) : null}
		</div>
	);
};

export default BarchartWidgetBars;
