export enum FeedItemType {
	NEWS = 'news',
	UPDATES = 'updates',
	RISK = 'risk'
}

import NewsItem_ from './FeedItemTypes/NewsItem';
import UpdatesItem_ from './FeedItemTypes/UpdatesItem';
import RiskItem_ from './FeedItemTypes/RiskItem';

export const NewsItem = NewsItem_;
export const UpdatesItem = UpdatesItem_;
export const RiskItem = RiskItem_;
