import React, { useState } from 'react';

import BemClass from '@upsales/components/Utils/bemClass';
import './PriceListDrawer.scss';
import T from 'Components/Helpers/translate';
import {
	Button,
	ButtonBox,
	DrawerHeader,
	Flex,
	Help,
	Icon,
	Input,
	Label,
	Progress,
	Row,
	Text,
	Toggle,
	Tooltip
} from '@upsales/components';
import PriceList, { PriceListToSave } from 'App/resources/Model/PriceList';
import CodeInput from './CodeInput';
import LZString from 'lz-string';
import InlineAction from 'Components/Dialogs/InlineAction/InlineAction';
import FlexItem from '../FlexItem';

type Props = {
	close: () => void;
	className: string;
	priceList?: PriceList;
	onSave: (savedPriceList: PriceListToSave, setSaving: (saving: boolean) => void, close?: () => void) => void;
	isCodeAvailable: (code: string | null, id?: number) => boolean;
};

const initialPriceListState: PriceListToSave = {
	name: '',
	code: '',
	isDefault: false,
	active: true,
	showDiscount: false
};

function getHash(priceList: PriceListToSave): string {
	return LZString.compressToBase64(JSON.stringify(priceList));
}

function PriceListDrawer(props: Props) {
	const [priceList, updatePriceList] = useState<PriceListToSave>(props.priceList ?? initialPriceListState);
	const [saving, setSaving] = useState(false);
	const [verifyClose, setVerifyClose] = useState('');

	const classes = new BemClass('PriceListDrawer', props.className);
	const lang = {
		priceList: T('admin.pricelist'),
		create: T('default.create'),
		edit: T('default.edit'),
		cancel: T('default.cancel'),
		save: T('default.save'),
		pricelistName: T('pricelist.drawer.pricelistName'),
		defaultPriceList: T('admin.pricelist.defaultList'),
		activatePriceList: T('pricelist.drawer.activate'),
		deactivatePriceList: T('pricelist.drawer.deactivate'),
		needsDefault: T('admin.pricelists.needs.default'),
		inactiveDefault: T('admin.pricelists.inactive.default'),
		defaultNotInactive: T('admin.pricelists.defaultNotInactive'),
		showDiscountTitle: T('admin.pricelists.showDiscountTitle'),
		showDiscountBody: T('admin.pricelists.showDiscountBody'),
		dontShowDiscountTitle: T('admin.pricelists.dontShowDiscountTitle'),
		dontShowDiscountBody: T('admin.pricelists.dontShowDiscountBody')
	};

	const isNew = props.priceList === undefined;
	const initialHash = getHash(props.priceList ?? initialPriceListState);

	function onClose(where: string): void {
		if (!canSave()) {
			props.close();
			return;
		}
		setVerifyClose(where);
	}

	function canSave(): boolean {
		const isValid = priceList.name.trim() !== '' && props.isCodeAvailable(priceList.code, priceList.id);
		const hasChanges = isNew || getHash(priceList) !== initialHash;
		return isValid && !saving && hasChanges;
	}

	function updateShowDiscount(value: boolean) {
		updatePriceList(prev => ({ ...prev, showDiscount: value }));
	}

	return (
		<div className={classes.b()}>
			<div className={classes.elem('wrapper').b()}>
				<DrawerHeader
					onHide={() => onClose('left')}
					title={(isNew ? lang.create : lang.edit) + ' ' + lang.priceList.toLowerCase()}
				>
					{verifyClose ? (
						<InlineAction
							toggleInlineAction={() => setVerifyClose('')}
							onReject={props.close}
							onConfirm={() => props.onSave(priceList, setSaving, props.close)}
							showTop
							showLeft={verifyClose === 'left'}
							showRight={verifyClose === 'right'}
						/>
					) : null}
					<Button type="link" className={classes.elem('cancel-btn').b()} onClick={() => onClose('right')}>
						<Text>{lang.cancel}</Text>
					</Button>
					<Button
						className={classes.elem('save-btn').b()}
						onClick={() => props.onSave(priceList, setSaving, props.close)}
						disabled={!canSave()}
					>
						<Text size="lg" bold color="white">
							{lang.save} <Icon space="mlm" color="super-light-green" name="check" />
						</Text>
					</Button>
				</DrawerHeader>
				<div className={classes.elem('content').b()}>
					<div className={classes.elem('settings').b()}>
						<div>
							<Row>
								<Label required>{lang.pricelistName}</Label>
								<Progress hideText percentage={(priceList.name.length / 128) * 100} />
							</Row>
							<Input
								maxLength={128}
								value={priceList.name}
								onChange={e => updatePriceList({ ...priceList, name: e.target.value })}
							/>
						</div>
						<Row>
							<CodeInput
								classes={classes}
								priceList={priceList}
								isCodeAvailable={props.isCodeAvailable}
								updatePriceList={updatePriceList}
							/>
						</Row>
						<div className={classes.elem('toggleRow').b()}>
							<Tooltip
								disabled={!priceList.isDefault && priceList.active}
								title={priceList.isDefault ? lang.needsDefault : lang.inactiveDefault}
							>
								<Toggle
									size="lg"
									checked={priceList.isDefault}
									disabled={props.priceList?.isDefault || !priceList.active}
									onChange={value => updatePriceList({ ...priceList, isDefault: value })}
								/>
							</Tooltip>
							<Text className={classes.elem('textWithHelp').b()}>{lang.defaultPriceList}</Text>
							<Help articleId={1446} />
						</div>
						<Flex gap="u6" justifyContent="center">
							<FlexItem
								component={ButtonBox}
								flex={[1, 0, '0%']}
								title={lang.showDiscountTitle}
								subtitle={lang.showDiscountBody}
								selected={priceList.showDiscount}
								onClick={() => updateShowDiscount(true)}
							/>
							<FlexItem
								component={ButtonBox}
								flex={[1, 0, '0%']}
								title={lang.dontShowDiscountTitle}
								subtitle={lang.dontShowDiscountBody}
								selected={!priceList.showDiscount}
								onClick={() => updateShowDiscount(false)}
							/>
						</Flex>
					</div>
					<Row className={classes.elem('actions').b()}>
						<Tooltip disabled={!priceList.isDefault} title={lang.defaultNotInactive}>
							<Button
								type="link"
								disabled={priceList.isDefault}
								onClick={() => updatePriceList({ ...priceList, active: !priceList.active })}
							>
								<Icon name={priceList.active ? 'eye-slash' : 'eye'} space="mrs" />
								{priceList.active ? lang.deactivatePriceList : lang.activatePriceList}
							</Button>
						</Tooltip>
					</Row>
				</div>
			</div>
		</div>
	);
}

export default PriceListDrawer;
