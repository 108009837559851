import { Metadata } from 'App/resources/AllIWant';

// TODO: This should not use getMetadata() as a default argument. It creates a deep clone of the metadata object every time it's called.
export const hasRRWithCM = (metadata = Tools.AppService.getMetadata()) => {
	const params = metadata.params;

	const hasFeatureFlag = Tools.FeatureHelper.hasSoftDeployAccess('COMBINE_RR_AND_CM');
	const hasModel = params.SalesModel === 'rr';
	const hasOption3 = params.SalesModelOption3 === 'combinedWithCM';

	return hasFeatureFlag && hasModel && hasOption3;
};

// TODO: This should not use getMetadata() as a default argument. It creates a deep clone of the metadata object every time it's called.
export const hasCMWithRR = (metadata = Tools.AppService.getMetadata()) => {
	const params = metadata.params;

	const hasFeature =
		Tools.FeatureHelper.hasSoftDeployAccess('COMBINE_CM_AND_RR') &&
		Tools.FeatureHelper.isAvailable(Tools.FeatureHelper.Feature.RECURRING_ORDER);
	const hasModel = params.SalesModel === 'cm';
	const hasOption3 =
		params.SalesModelOption3 === 'cmCombinedWithARR' || params.SalesModelOption3 === 'cmCombinedWithMRR';
	return hasFeature && hasModel && hasOption3;
};

// TODO: This should not use getMetadata() as a default argument. It creates a deep clone of the metadata object every time it's called.
export const getCMWithRROption = (metadata = Tools.AppService.getMetadata()) => {
	const params = metadata.params;
	let salesModelOption;

	if (hasCMWithRR(metadata)) {
		if (params?.SalesModelOption3 === 'cmCombinedWithARR') {
			salesModelOption = 'arr';
		} else if (params?.SalesModelOption3 === 'cmCombinedWithMRR') {
			salesModelOption = 'mrr';
		}
	}

	return salesModelOption;
};

export const getSalesModelOption = (metadata: Metadata) => {
	return getCMWithRROption(metadata) ?? metadata.params.SalesModelOption;
};

export const usesMRR = (metadata?: Metadata | null) => {
	const salesModel = metadata?.params?.SalesModel;
	const salesModelOption = metadata?.params?.SalesModelOption;
	const salesModelOption3 = metadata?.params?.SalesModelOption3;

	const hasSalesModelMRR = salesModelOption === 'mrr';
	const hasCombinedMRR = salesModel === 'cm' && salesModelOption3 === 'cmCombinedWithMRR';
	return hasSalesModelMRR || hasCombinedMRR;
};
