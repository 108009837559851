import { FormErrorMessages } from 'App/components/FormObserver/FormObserver';
import { OrderRow } from 'App/components/OrderRows/Context/OrderContext';
import {
	calculateIntervalValues,
	resetAndGetBundleOrderRow,
	resetAndGetOrderRow
} from 'App/components/OrderRows/Context/OrderContextHelpers';
import { EntityCustomField } from 'App/resources/Model/CustomField';
import PriceList from 'App/resources/Model/PriceList';
import { NewSubscriptionTracker } from 'Helpers/Tracker';
import moment from 'moment';
import { useSelector } from 'react-redux';
import { RootState } from 'Store/index';
import { ActionsPeriod } from './SubscriptionGroupContextActions';
import { updateChildren } from './SubscriptionGroupContextHelpers';
import {
	SubscriptionPeriodState,
	FormattedSubscriptionPeriod,
	FormattedOrderRow,
	Intervals,
	NoticePeriods,
	PeriodLengths,
	Offsets,
	OrderInterval,
	PeriodLength
} from './SubscriptionGroupState';

const hasSplitFlag = () => Tools.FeatureHelper.hasSoftDeployAccess('SUBSCRIPTION_SPLITS');

export const sdf = 'YYYY-MM-DD';

export const useStatics = () => {
	const params = useSelector((state: RootState) => state.App?.metadata?.params);
	const customOffsets: readonly number[] = params?.SubscriptionInAdvanceOptions;
	const customNoticePeriods: readonly number[] | undefined = params?.SubscriptionNoticePeriod;
	return {
		Intervals,
		NoticePeriods: customNoticePeriods ?? NoticePeriods,
		PeriodLengths,
		Offsets: customOffsets ?? Offsets
	};
};

export type Dispatch = (action: any) => void;

export const toggleRenewal = (dispatch: Dispatch, state: SubscriptionPeriodState) => () => {
	const endOfPeriod = moment(state.invoiceStartDate)
		.add(state.periodLength || state.orderInterval, 'month')
		.format(sdf);
	const endDate = state.endDate ? undefined : endOfPeriod;
	const renewalDate = endOfPeriod;

	let children = [...(state.children ?? [])];
	if (hasSplitFlag() && children.length) {
		children[children.length - 1].endDate = endOfPeriod;
		children = updateChildren(children, state);
		dispatch({ type: ActionsPeriod.SET_CHILDREN, children });
	}

	const locked = endDate ? state.locked : false;
	dispatch({ type: ActionsPeriod.TOGGLE_RENEWAL, renewalDate, endDate, locked });
	NewSubscriptionTracker.incrementValueAndTrack(endDate ? 'switchToTermination' : 'switchToRenewal');
};

const getEndDate = (invoiceStartDate: string, periodLength: number) => {
	if (periodLength === 0) {
		return moment(invoiceStartDate).add(1, 'day').format(sdf);
	}
	return moment(invoiceStartDate).add(periodLength, 'month').format(sdf);
};

export const setInvoiceStartDate =
	(dispatch: Dispatch, state: SubscriptionPeriodState) => (invoiceStartDate: string) => {
		invoiceStartDate = moment(invoiceStartDate).format(sdf);
		const startDate = moment(invoiceStartDate).isBefore(state.startDate) ? invoiceStartDate : state.startDate;
		const nextOrderPeriodStart = moment(invoiceStartDate).isBefore() ? moment().format(sdf) : invoiceStartDate;

		let { endDate, renewalDate } = state;
		const hasFlagAndUntilFurtherNotice = hasSplitFlag() && state.periodLength === 0;
		if (endDate && !hasFlagAndUntilFurtherNotice) {
			endDate = getEndDate(invoiceStartDate, state.periodLength);
		} else {
			renewalDate = moment(invoiceStartDate).add(state.periodLength, 'month').format(sdf);
		}

		let children = [...(state.children ?? [])];
		if (hasSplitFlag() && children.length) {
			children[0].startDate = invoiceStartDate;
			children[children.length - 1].endDate = (endDate ?? renewalDate) as string;
			children = updateChildren(children, state, {
				orderInterval: state.orderInterval,
				startDate: invoiceStartDate
			});
			dispatch({ type: ActionsPeriod.SET_CHILDREN, children });
		}

		dispatch({
			type: ActionsPeriod.SET_START_DATE,
			startDate,
			invoiceStartDate,
			initialInvoiceStartDate: invoiceStartDate,
			endDate,
			renewalDate,
			nextOrderPeriodStart
		});
		NewSubscriptionTracker.incrementValueAndTrack('orderStartDate');
	};

export const setStartDate =
	(dispatch: Dispatch, state: SubscriptionPeriodState) => (startDate: string, isAdvancedMode: boolean) => {
		let { endDate, renewalDate, invoiceStartDate } = state;
		startDate = moment(startDate).format(sdf);
		if (!isAdvancedMode || moment(invoiceStartDate).isBefore(startDate)) {
			invoiceStartDate = startDate;
			if (endDate) {
				endDate = getEndDate(invoiceStartDate, state.periodLength);
			} else {
				renewalDate = moment(startDate).add(state.periodLength, 'month').format(sdf);
			}
		}

		let children = [...(state.children ?? [])];
		if (hasSplitFlag() && children.length) {
			children[0].startDate = invoiceStartDate;
			children[children.length - 1].endDate = (endDate ?? renewalDate) as string;
			children = updateChildren(children, state, {
				orderInterval: state.orderInterval,
				startDate: invoiceStartDate
			});
			dispatch({ type: ActionsPeriod.SET_CHILDREN, children });
		}

		dispatch({ type: ActionsPeriod.SET_START_DATE, startDate, invoiceStartDate, endDate, renewalDate });
		NewSubscriptionTracker.incrementValueAndTrack('orderStartDate');
	};

export const setTerminationDate =
	(dispatch: Dispatch, state: SubscriptionPeriodState) => (terminationDate?: string) => {
		let children = [...(state.children ?? [])];
		if (hasSplitFlag() && children.length && terminationDate) {
			children[children.length - 1].endDate = terminationDate;
			children = updateChildren(children, state);
			dispatch({ type: ActionsPeriod.SET_CHILDREN, children });
		}

		dispatch({ type: ActionsPeriod.SET_TERMINATION_DATE, endDate: terminationDate });
		NewSubscriptionTracker.incrementValueAndTrack('terminationDate');
	};

export const setNoticePeriod = (dispatch: Dispatch, state: SubscriptionPeriodState) => (noticePeriod: number) => {
	dispatch({ type: ActionsPeriod.SET_NOTICE_PERIOD, noticePeriod });
	NewSubscriptionTracker.incrementValueAndTrack('noticePeriod');
};

export const resetOrderRows = (
	orderRows: OrderRow[],
	orderInterval: number,
	currency: string,
	priceListId?: number
) => {
	return orderRows.map(orderRow => {
		if (priceListId) {
			orderRow.priceListId = priceListId;
		}
		return orderRow.product?.bundle?.length
			? resetAndGetBundleOrderRow(orderRow, currency, orderInterval, undefined, true)
			: resetAndGetOrderRow(orderRow, currency, orderInterval);
	});
};

export const setPriceList = (dispatch: Dispatch, state: SubscriptionPeriodState) => (priceList: PriceList) => {
	let children = [...(state.children ?? [])];
	if (hasSplitFlag() && children.length) {
		children = updateChildren(children, state, { currency: state.currency, priceListId: priceList.id }).map(
			child => ({ ...child, orderRow: child.orderRow.map(row => ({ ...row, initExcessiveDiscount: true })) })
		);
		dispatch({ type: ActionsPeriod.SET_CHILDREN, children });
	} else {
		const orderRows = resetOrderRows(state.orderRows, state.orderInterval, state.currency, priceList.id).map(
			row => ({ ...row, initExcessiveDiscount: true })
		);
		dispatch({ type: ActionsPeriod.SET_ORDER_ROWS, orderRows });
	}

	dispatch({ type: ActionsPeriod.SET_PRICE_LIST, priceList });
};

export const setOffset = (dispatch: Dispatch, state: SubscriptionPeriodState) => (offset: number) => {
	dispatch({ type: ActionsPeriod.SET_OFFSET, offset });
	NewSubscriptionTracker.incrementValueAndTrack('daysInAdvance');
};

export const setPeriodLength = (dispatch: Dispatch, state: SubscriptionPeriodState) => (periodLength: PeriodLength) => {
	let orderInterval: OrderInterval = state.orderInterval;
	if (periodLength !== 0 && periodLength % orderInterval !== 0) {
		orderInterval = Intervals.find(interval => interval === periodLength) ?? 1;
	}
	const endOfPeriod = moment(state.invoiceStartDate)
		.add(periodLength || state.periodLength, 'month')
		.format(sdf);
	const endDate = state.endDate ? endOfPeriod : undefined;
	const renewalDate = endOfPeriod;
	const noticePeriod = periodLength > 0 && state.noticePeriod > periodLength ? periodLength : state.noticePeriod;

	let children = [...(state.children ?? [])];
	if (hasSplitFlag() && children.length) {
		children[children.length - 1].endDate = endOfPeriod;
		children = updateChildren(children, state, { orderInterval });
		dispatch({ type: ActionsPeriod.SET_CHILDREN, children });
	} else {
		const orderRows = state.orderRows.map(row => calculateIntervalValues(row, state.orderInterval, orderInterval));
		dispatch({ type: ActionsPeriod.SET_ORDER_ROWS, orderRows });
	}

	dispatch({
		type: ActionsPeriod.SET_PERIOD_AND_INTERVAL,
		periodLength,
		orderInterval,
		renewalDate,
		endDate,
		noticePeriod
	});
	NewSubscriptionTracker.incrementValueAndTrack('periodLength');
};

export const setOrderInterval =
	(dispatch: Dispatch, state: SubscriptionPeriodState) =>
	(orderInterval: OrderInterval, periodLength: PeriodLength) => {
		const endOfPeriod = moment(state.invoiceStartDate)
			.add(periodLength || orderInterval, 'month')
			.format(sdf);
		const endDate = periodLength === state.periodLength ? state.endDate : state.endDate ? endOfPeriod : undefined;
		const renewalDate = endOfPeriod;
		const noticePeriod = periodLength > 0 && state.noticePeriod > periodLength ? periodLength : state.noticePeriod;

		let children = [...(state.children ?? [])];
		if (hasSplitFlag() && children.length) {
			children[children.length - 1].endDate = endOfPeriod;
			children = updateChildren(children, state, { orderInterval });
			dispatch({ type: ActionsPeriod.SET_CHILDREN, children });
		} else {
			const orderRows = state.orderRows.map(row =>
				calculateIntervalValues(row, state.orderInterval, orderInterval)
			);
			dispatch({ type: ActionsPeriod.SET_ORDER_ROWS, orderRows });
		}

		dispatch({
			type: ActionsPeriod.SET_PERIOD_AND_INTERVAL,
			periodLength,
			orderInterval,
			renewalDate,
			endDate,
			noticePeriod
		});
		NewSubscriptionTracker.incrementValueAndTrack('orderInterval');
	};

export const setCurrency =
	(dispatch: Dispatch, state: SubscriptionPeriodState) => (currency: string, currencyRate: number) => {
		let children = [...(state.children ?? [])];
		if (hasSplitFlag() && children.length) {
			children = updateChildren(children, state, { currency });
			dispatch({ type: ActionsPeriod.SET_CHILDREN, children });
		} else {
			const orderRows = resetOrderRows(state.orderRows, state.orderInterval, currency, state.priceList?.id);
			dispatch({ type: ActionsPeriod.SET_ORDER_ROWS, orderRows });
		}

		dispatch({ type: ActionsPeriod.SET_CURRENCY, currency, currencyRate });
	};

export const setOrderRows =
	(dispatch: Dispatch, state: SubscriptionPeriodState, childIndexNr: number) => (orderRows: OrderRow[]) => {
		orderRows = orderRows.map(row => ({ ...row, justAdded: false }));

		if (state.children?.length && hasSplitFlag()) {
			const children = [...(state.children ?? [])];
			const currentChild = children[childIndexNr];
			if (currentChild) {
				currentChild.orderRow = orderRows;
				dispatch({ type: ActionsPeriod.SET_CHILDREN, children });
			}
			return;
		}

		dispatch({ type: ActionsPeriod.SET_ORDER_ROWS, orderRows });
	};

export const setCustomFields =
	(dispatch: Dispatch, state: SubscriptionPeriodState) =>
	(uuid: number, customFields: EntityCustomField[], isValid: boolean, errorMessages: FormErrorMessages) => {
		if (uuid !== state.uuid) {
			return;
		}
		const custom = customFields ?? [];

		dispatch({
			type: ActionsPeriod.SET_CUSTOM_VALUES,
			custom,
			isCustomFieldsValid: isValid,
			errorMessages
		});
	};

const formatBundleRows = (bundleRows: SubscriptionPeriodState['orderRows'][0]['bundleRows']) => {
	let sortId = 0;
	return (bundleRows || []).map(bundleRow => {
		const { id, productId, quantity, tierQuantity, price, listPrice, purchaseCost } = bundleRow;

		return {
			id,
			productId,
			quantity,
			tierQuantity,
			price,
			listPrice,
			purchaseCost,
			sortId: sortId++
		};
	});
};

const formatOrderRows = (
	orderRows: SubscriptionPeriodState['orderRows'],
	currencyRate: number,
	formatForPreview = false
): FormattedOrderRow[] => {
	// When saving the agreement we want price in master currency
	// When passing to preview graph we want price in selected currency
	const conversionRate = formatForPreview ? 1 : 1 / currencyRate;

	return orderRows.map(orderRow => {
		const { id, product, quantity, price, listPrice, purchaseCost, custom, tierQuantity, bundleRows, sortId } =
			orderRow;

		return {
			id,
			product: { id: product?.id! },
			quantity,
			custom,
			tierQuantity,
			price: (price ?? 0) * conversionRate,
			listPrice: (listPrice ?? 0) * conversionRate,
			purchaseCost: purchaseCost ?? 0,
			sortId,
			bundleRows: formatBundleRows(bundleRows)
		};
	});
};

export const formatSubscriptionPeriod = (
	subscription: SubscriptionPeriodState,
	createDiffOrder = false,
	formatForPreview = false
): FormattedSubscriptionPeriod => {
	const {
		id,
		startDate,
		invoiceStartDate,
		initialInvoiceStartDate,
		endDate,
		periodLength,
		renewalDate,
		orderInterval,
		noticePeriod,
		orderRows,
		offset,
		currency,
		children,
		orderSequenceNr,
		currencyRate,
		custom,
		uuid,
		value,
		priceList,
		creditInitialDate
	} = subscription;

	const mappedChildren = children?.map(child => ({
		...child,
		orderRow: formatOrderRows(child.orderRow, currencyRate, formatForPreview),
		startDate: moment(child.startDate).format(sdf),
		endDate: moment(child.endDate).format(sdf)
	}));

	return {
		id,
		uuid,
		currency,
		currencyRate,
		custom,
		children: mappedChildren ?? [],
		value,
		createDiffOrder,
		metadata: {
			agreementIntervalPeriod: orderInterval,
			periodLength,
			agreementStartdate: moment(startDate).format(sdf),
			agreementEnddate: endDate ? moment(endDate).format(sdf) : null,
			agreementInvoiceStartdate: moment(invoiceStartDate).format(sdf),
			agreementInitialInvoiceStartdate: moment(initialInvoiceStartDate).format(sdf),
			agreementRenewalDate: periodLength > 0 && renewalDate ? moment(renewalDate).format(sdf) : null,
			agreementOrderCreationTime: offset ?? 0,
			orderSequenceNr,
			creditInitialDate,
			noticePeriod
		},
		orderRow: children?.length ? [] : formatOrderRows(orderRows, currencyRate, formatForPreview),
		priceListId: priceList.id
	};
};

const normalizeCustomValues = (customArray: EntityCustomField[] = []) => {
	return customArray
		.filter(c => c.value !== null && c.value !== undefined)
		.sort((a, b) => a.fieldId - b.fieldId)
		.map(({ fieldId, value }) => {
			const val = (value as unknown) instanceof Date ? moment(value).format(sdf) : value;
			return { fieldId, value: val };
		});
};

export const formatCustomValues = (customArray?: EntityCustomField[]) => {
	return JSON.stringify(normalizeCustomValues(customArray));
};

const sameCustomFields = (current: EntityCustomField[] | undefined, updated: EntityCustomField[] | undefined) => {
	const arr1 = normalizeCustomValues(current);
	const arr2 = normalizeCustomValues(updated);

	if (arr1.length !== arr2.length) {
		return false;
	}

	for (let i = 0; i < arr1.length; i++) {
		// eslint-disable-next-line eqeqeq
		if (arr1[i].fieldId !== arr2[i].fieldId || arr1[i].value != arr2[i].value) {
			return false;
		}
	}

	return true;
};

export const isSubDirty = (current: SubscriptionPeriodState, updated: SubscriptionPeriodState) => {
	const currentFormatted = formatSubscriptionPeriod(current);
	const updatedFormatted = formatSubscriptionPeriod(updated);

	if (!sameCustomFields(currentFormatted.custom, updatedFormatted.custom)) {
		return true;
	}

	// Custom fields are already checked - don't include them in the final comparison string
	delete currentFormatted.custom;
	delete updatedFormatted.custom;

	if (currentFormatted.orderRow.length !== updatedFormatted.orderRow.length) {
		return true;
	}

	for (let i = 0; i < currentFormatted.orderRow.length; i++) {
		if (!sameCustomFields(currentFormatted.orderRow[i].custom, updatedFormatted.orderRow[i].custom)) {
			return true;
		}

		delete currentFormatted.orderRow[i].custom;
		delete updatedFormatted.orderRow[i].custom;
	}

	const currentString = JSON.stringify(currentFormatted);
	const updatedString = JSON.stringify(updatedFormatted);

	return currentString !== updatedString;
};
