import React from 'react';
import { Row, Card, Title, Paginator } from '@upsales/components';
import BemClass from '@upsales/components/Utils/bemClass';
import SignalsEmptyResultLottie from './SignalsEmptyResultLottie';
import { useTranslation } from 'Components/Helpers/translate';
import { Item } from 'Resources/Signals';
import { paginate } from 'Store/reducers/SignalsFeedReducer';
import { RootState } from 'Store/index';
import { connect } from 'react-redux';
import { RiskItem, FeedItemType, NewsItem, UpdatesItem } from '../SignalsFeedItem';

interface SignalsContentProps {
	items: Item[] | undefined;
	total: number;
	limit: number;
	offset: number;
	paginate: (offset: number) => void;
}

const mapStateToProps = (state: RootState) => ({ ...state.SignalsFeed });

const mapDispatchToProps = {
	paginate
};

const SignalsContent: React.FC<SignalsContentProps> = ({ items, total, limit, offset, paginate }) => {
	const classes = new BemClass('SignalsFeed');
	const { t } = useTranslation();

	const getItem = (item: Item) => {
		switch (item.type) {
			case FeedItemType.NEWS:
				return <NewsItem item={item}></NewsItem>;
			case FeedItemType.UPDATES:
				return <UpdatesItem item={item}></UpdatesItem>;
			case FeedItemType.RISK:
				return <RiskItem item={item}></RiskItem>;
			default:
				return null;
		}
	};
	return (
		<div className={classes.elem('content').b()}>
			{items?.length ? (
				items.map(item => (
					<Row className={classes.elem('itemRow').b()} key={`${item.type}-${item.id}-${item.clientId}`}>
						<div className={classes.elem('item').b()}>
							<Card>{getItem(item)}</Card>
						</div>
					</Row>
				))
			) : (
				<div className={classes.elem('lottie').b()}>
					<SignalsEmptyResultLottie></SignalsEmptyResultLottie>
					<Title size="md" color="grey-10">
						{t('prospecting.signals.upToDate')}
					</Title>
				</div>
			)}
			{total > limit ? (
				<div className={classes.elem('paginator').b()}>
					<Paginator limit={limit} offset={offset} total={total} onChange={paginate}></Paginator>
				</div>
			) : null}
		</div>
	);
};

export const detached = SignalsContent;

export default connect(mapStateToProps, mapDispatchToProps)(SignalsContent);
