import iframeRenderType from './iframeRenderType';
import markdownRenderType from './markdownRenderType';
import imageRenderType from './imageRenderType';
import textRenderType from './textRenderType';
import switchRenderType from './switchRenderType';
import dropdownRenderType from './dropdownRenderType';
import ButtonsRenderType from './ButtonsRenderType';
import TitleRenderType from './TitleRenderType';
import ColsRenderType from './ColsRenderType';
import SeparatorRenderType from './SeparatorRenderType';
import WidgetBarchart from './WidgetBarchart';
import LinkRenderType from './LinkRenderType';
import InternalLinkRenderType from './InternalLinkRenderType';
import ButtonRenderType from './ButtonRenderType';
import RowRenderType from './RowRenderType';
import EmptyRowRenderType from './EmptyRowRenderType';
import PlainTextRenderType from './PlainTextRenderType';

export default {
	iframe: iframeRenderType,
	markdown: markdownRenderType,
	image: imageRenderType,
	text: textRenderType,
	switch: switchRenderType,
	dropdown: dropdownRenderType,
	title: TitleRenderType,
	cols: ColsRenderType,
	separator: SeparatorRenderType,
	barchart: WidgetBarchart,
	buttons: ButtonsRenderType, // legacy
	link: LinkRenderType,
	internalLink: InternalLinkRenderType,
	button: ButtonRenderType,
	row: RowRenderType,
	emptyRow: EmptyRowRenderType,
	plainText: PlainTextRenderType
};
