export default (
	prop: string | null = null,
	desc: boolean = false,
	language?: Parameters<String['toLocaleLowerCase']>[0]
) => (a: string | Record<string, any>, b: string | Record<string, any>) => {
	const aValue = '' + ((prop && typeof a === 'object' ? a[prop] : a) || '');
	const bValue = '' + ((prop && typeof b === 'object' ? b[prop] : b) || '');

	if (language) {
		try {
			return (
				(desc ? -1 : 1) *
				aValue.toLocaleLowerCase(language).localeCompare(bValue.toLocaleLowerCase(language), language)
			);
		} catch (error) {
			// Default to browser locale if user has an invalid
			console.error(error, language);
		}
	}

	return (desc ? -1 : 1) * aValue.toLocaleLowerCase().localeCompare(bValue.toLocaleLowerCase());
};
