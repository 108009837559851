import BemClass from '@upsales/components/Utils/bemClass';
import { useTranslation } from 'Components/Helpers/translate';
import {
	Block,
	Flex,
	Icon,
	Input,
	Modal,
	ModalContent,
	ModalHeader,
	Table,
	TableColumn,
	TableHeader,
	TableRow,
	Text
} from '@upsales/components';
import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';

import './GenericSelectEntityModal.scss';
import { CancelablePromise, makeCancelable } from '@upsales/components/Utils/CancelablePromise';
import RequestBuilder, { comparisonTypes } from 'Resources/RequestBuilder';
import type { ModalProps } from 'App/components/Modals/Modals';
import logError from 'Helpers/logError';
import IconName from '@upsales/components/Icon/IconName';

type Props<T> = ModalProps<T> & {
	modifyFilters?: (requestBuilder: RequestBuilder) => void;
	displayAttr: keyof T;
	displayExtraAttrs?: string[];
	extraColumnTitles?: string[];
	title: string;
	fetch: (
		builtFilters: ReturnType<RequestBuilder['build']>,
		searchString: string
	) => Promise<Response<T> | ResponseWithChildren<T>>;
	initialData?: T[] | SelectTypeWithChildren<T>[];
	formatDataRender?: (data: T) => JSX.Element;
};

type Response<T> = { data: T[]; metadata: { total: number } };
export type SelectTypeWithChildren<T> = { title: string; icon: IconName; children: T[]; name?: string };
type ResponseWithChildren<T> = { data: SelectTypeWithChildren<T>[]; metadata: { total: number } };

interface JsonObject {
	[key: string]: JsonObject | string | number | boolean | null;
}

const GenericSelectEntityModal = <T extends { id: number }>({
	className,
	close,
	modifyFilters,
	displayAttr,
	displayExtraAttrs,
	extraColumnTitles = [],
	title,
	fetch,
	formatDataRender,
	initialData
}: Props<T>) => {
	const classes = new BemClass('GenericSelectEntityModal', className);
	const { t } = useTranslation();
	const [searchString, setSearchString] = useState<string>('');
	const [fetching, setFetching] = useState(false);
	const [selected, setSelected] = useState<number>(-1);
	const [hits, setHits] = useState(0);
	const [results, setResults] = useState<T[] | SelectTypeWithChildren<T>[]>([]);
	const typeTimer = useRef<NodeJS.Timeout | null>(null);
	const request = useRef<CancelablePromise<Response<T> | ResponseWithChildren<T>> | null>(null);
	const inputRef = useRef<HTMLInputElement | null>(null);

	const search = () => {
		const filter = new RequestBuilder();
		filter.limit = 10;
		filter.addFilter({ field: displayAttr as string }, comparisonTypes.Wildcard, searchString);

		modifyFilters?.(filter);

		request.current = makeCancelable<Response<T> | ResponseWithChildren<T>>(fetch(filter.build(), searchString));

		request.current.promise
			.then(({ data, metadata }) => {
				setResults(data);
				setHits(metadata.total);
				setFetching(false);
				if (data.length) {
					setSelected(0);
				}
			})
			.catch(error => {
				logError(error, 'Failed to fetch entities in GenericSelectEntityModal');
			});
	};

	const clearDelayedRequest = () => {
		if (typeTimer.current) {
			clearTimeout(typeTimer.current);
		}
		request.current?.cancel();
	};

	const handleKeyDown = useCallback(
		(e: React.KeyboardEvent<HTMLInputElement>) => {
			const flatResults = (results || [])?.flatMap((result: T | SelectTypeWithChildren<T>) => {
				if (result.hasOwnProperty('children')) {
					const res = result as SelectTypeWithChildren<T>;
					return res.children;
				}
				return result;
			});
			switch (e.key) {
				case 'Escape':
					close();
					break;
				case 'Enter':
					if (selected > -1) {
						close(flatResults[selected]);
					}
					break;
				case 'ArrowDown':
					if (selected < flatResults.length - 1) {
						setSelected(selected + 1);
					} else {
						setSelected(0);
					}
					break;
				case 'ArrowUp':
					if (selected > 0) {
						setSelected(selected - 1);
					} else {
						setSelected(flatResults.length - 1);
					}
					break;
			}
		},
		[results, selected]
	);

	const getAttributeFromNestedJson = (
		jsonObj: JsonObject,
		attributeString: string
	): JsonObject | string | number | boolean | null => {
		const keys = attributeString.split('.');
		let currentObj: JsonObject | string | number | boolean | null = jsonObj;

		for (const key of keys) {
			if (currentObj && typeof currentObj === 'object' && key in currentObj) {
				currentObj = currentObj[key] as JsonObject | string | number | boolean | null;
			} else {
				return null;
			}
		}

		return currentObj;
	};

	useEffect(() => {
		clearDelayedRequest();
		setSelected(-1);

		if (searchString.length) {
			setFetching(true);
			typeTimer.current = setTimeout(() => search(), 500);
		} else if (!searchString) {
			setResults(initialData || []);
			setHits(0);
		}

		return () => {
			clearDelayedRequest();
		};
	}, [searchString]);

	useEffect(() => {
		const focusTimer = setTimeout(() => inputRef.current?.focus(), 300);
		return () => {
			clearTimeout(focusTimer);
		};
	}, []);

	const searchRows = useMemo(() => {
		let i = 0;
		const singleRow = (element: T, isChild = false) => (
			<TableRow
				key={element.id}
				onClick={() => close(element)}
				className={classes
					.elem('row')
					.mod({ selected: selected === i++ })
					.b()}
			>
				{formatDataRender ? (
					formatDataRender(element)
				) : (
					<>
						<TableColumn className={classes.elem('column').mod({ child: isChild }).b()}>
							{element[displayAttr]}
						</TableColumn>
						{displayExtraAttrs?.length
							? displayExtraAttrs.map(extraAttr => (
									<TableColumn key={extraAttr}>
										{getAttributeFromNestedJson(element, extraAttr)}
									</TableColumn>
							  ))
							: null}
					</>
				)}
			</TableRow>
		);

		return results.map((result: T | SelectTypeWithChildren<T>) => {
			if (result.hasOwnProperty('children')) {
				const res = result as SelectTypeWithChildren<T>;
				return (
					<React.Fragment key={res.title}>
						<TableRow key={res.title}>
							<TableColumn>
								<Flex gap={4} alignItems="center">
									{res.icon ? <Icon name={res.icon} /> : null}
									<Text bold>{res.title}</Text>
								</Flex>
							</TableColumn>
						</TableRow>
						{res.children.map((child: T) => {
							return singleRow(child, true);
						})}
					</React.Fragment>
				);
			}
			return singleRow(result);
		});
	}, [results, selected]);

	return (
		<Modal className={classes.b()}>
			<ModalHeader icon="search" title={title} onClose={() => close()} />
			<ModalContent>
				<Block space="ptl prl pbl pll">
					<Input
						placeholder={title}
						inputRef={inputRef}
						value={searchString}
						onChange={e => setSearchString(e.target.value)}
						onKeyDown={handleKeyDown}
					/>
				</Block>

				<Table loading={fetching}>
					{!fetching && hits ? (
						<TableHeader
							columns={[
								{
									title: `${hits} ${t(hits === 1 ? 'default.hit' : 'default.hits').toLowerCase()}`
								}
							]
								.concat(extraColumnTitles?.map(col => ({ title: t(col) })))
								.filter(val => !!val)}
						/>
					) : null}
					{searchString.length && !fetching && !hits ? (
						<TableRow>
							<TableColumn align="center">
								<Text color="grey-10">{t('default.noResults')}</Text>
							</TableColumn>
						</TableRow>
					) : null}
					{searchRows}
				</Table>
			</ModalContent>
		</Modal>
	);
};

export default GenericSelectEntityModal;
