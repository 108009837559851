import React from 'react';
import { Text, Loader, Button } from '@upsales/components';
import BemClass from '@upsales/components/Utils/bemClass';
import './index.scss';
import T from 'Components/Helpers/translate';

type Props = {
	newEventsLoading: boolean;
	incrementOffset: () => void;
	numEventsLoaded: number;
	totalEvents: number | null;
};

const TimelineLoadMore = ({ newEventsLoading, incrementOffset, numEventsLoaded, totalEvents }: Props) => {
	const classes = new BemClass('TimelineLoadMore');

	const lang = {
		getMore: T('default.getMore'),
		showing: T('default.showing'),
		of: T('default.of').toLowerCase(),
		events: T('event.events').toLowerCase()
	};

	return (
		<div className={classes.b()}>
			<div className={classes.elem('icon').b()} />
			<div className={classes.elem('content').b()}>
				{newEventsLoading ? (
					<Loader noU size="xs" />
				) : (
					<>
						{totalEvents ? (
							<Text color="grey-10">{`${lang.showing} ${numEventsLoaded} ${lang.of} ${totalEvents} ${lang.events}. `}</Text>
						) : null}
						<Button onClick={incrementOffset} type="link">
							<Text color="bright-blue">{lang.getMore}</Text>
						</Button>
					</>
				)}
			</div>
		</div>
	);
};
export default TimelineLoadMore;
