import { getCategoryConfigsFromState } from 'App/babel/filters/CategoryFilterConfig';
import { getCategoriesFromState, getCategoryTypesFromState } from 'Store/selectors/AppSelectors';
import { FilterConfig } from 'App/babel/filterConfigs/FilterConfig';
import useSelector from './useSelector';

const useCategoryConfigs = (
	type: string,
	prefix: string,
	overrides: { [key: string]: any },
	defaultCategoryTitle: string
) => {
	const isUDO = type && type.indexOf('userDefinedObject') !== -1;
	const skipAccess = isUDO || false;
	const fixedType = isUDO ? type.replace('Object', '') : type;

	const AppState = useSelector(({ App }) => App);

	const categoryTypes = getCategoryTypesFromState(AppState, fixedType);
	const categories = getCategoriesFromState(AppState, fixedType, skipAccess);

	const tmpAccountCategories = getCategoryConfigsFromState(
		categoryTypes,
		categories,
		type,
		prefix,
		overrides,
		defaultCategoryTitle
	);

	const accountCategories: { [key: string]: FilterConfig } = {};
	for (const key in tmpAccountCategories) {
		// Filter2:getCompareFilters() will get wrong config otherwise because FilterHelper.isCategory
		const newKey = key.replace('_', '');

		accountCategories[newKey] = tmpAccountCategories[key];
	}

	return accountCategories;
};

export default useCategoryConfigs;
