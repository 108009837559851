import MailCampaign from '../Model/MailCampaign';

const mapMailCampaign = (mailCampaign: MailCampaign) => {
	if (mailCampaign.hasOwnProperty('filterConfig') && !mailCampaign.filterConfig) {
		mailCampaign.filter = null;
	}

	return mailCampaign;
};

export default mapMailCampaign;
