import PropTypes from 'prop-types';
import React from 'react';

import AccountBody from './AccountBody';
import GenericBody from './GenericBody';

const propTypes = {
	entity: PropTypes.string.isRequired
};

class AlertBody extends React.Component {
	constructor(props) {
		super(props);
		switch (props.entity) {
			case 'Client':
				this.entityBody = AccountBody;
				break;
			default:
				this.entityBody = GenericBody;
		}
	}

	render() {
		const EntityBody = this.entityBody;
		return <EntityBody {...this.props} />;
	}
}

AlertBody.propTypes = propTypes;
export default AlertBody;
