import { DropDownMenu, Icon, IconName, Text } from '@upsales/components';
import BemClass from '@upsales/components/Utils/bemClass';
import { DefaultButton } from '@upsales/components/Buttons';
import { useTranslation } from 'Components/Helpers/translate';
import React from 'react';

import './StagesDropdownMenu.scss';
import OrderStage from 'App/resources/Model/OrderStage';
import { useStages } from 'App/components/hooks/appHooks';

type Props = {
	onClick: (stage: OrderStage) => void;
	title: string;
	icon: IconName;
};

const StagesDropdownMenu = ({ onClick, title, icon }: Props) => {
	const classes = new BemClass('StagesDropdownMenu');
	const stages = useStages('won');
	const { t } = useTranslation();

	return (
		<div className={classes.b()}>
			<DropDownMenu
				useAnimation
				renderTrigger={(isExpanded, setExpanded) => (
					<DefaultButton onClick={setExpanded}>
						<Icon name={icon} />
						{title}
					</DefaultButton>
				)}
			>
				<div className={classes.elem('dropdown').b()}>
					<Text size="lg" bold>
						{t('subscription.wonOpportunity.moveToWhatStage')}
					</Text>
					{stages.map(stage => (
						<div key={stage.id} onClick={() => onClick(stage)} className={classes.elem('stage-row').b()}>
							<Text>{stage.name}</Text>
							<Text>{stage.probability + '%'}</Text>
						</div>
					))}
				</div>
			</DropDownMenu>
		</div>
	);
};

export default StagesDropdownMenu;
