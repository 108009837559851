import Resource from './Resource';
import Attributes from 'Attributes/SalesCoachChecklistItem';
import SalesCoach, { SalesCoachChecklistItem as ChecklistItem } from 'App/resources/Model/SalesCoach';

/* 
	This is because from this endpoint I map on the salesCoach 
	object which is of not necessary from the  salesCoach endpoint
*/
export type SalesCoachChecklistItem = ChecklistItem & { salesCoach: Pick<SalesCoach, 'id' | 'name'> };

class SalesCoachChecklistItemResource extends Resource {
	constructor() {
		super('salesCoachChecklistItems', Attributes);
	}
}

const resource = new SalesCoachChecklistItemResource();

export default resource;
