import React, { useLayoutEffect, useState, useContext, ReactNode } from 'react';
import { PopupPortal } from '@upsales/components';
export type OptionalQueryElement = React.ComponentProps<typeof PopupPortal>['anchor'];

type PopupPortalAnchorState = {
	anchor?: OptionalQueryElement;
	scrollContainer?: OptionalQueryElement;
};

type PopupPortalAnchorProps = {
	children: ReactNode;
	anchor?: OptionalQueryElement;
	scrollContainerSelector?: string;
};

const initialState: PopupPortalAnchorState = {
	anchor: null,
	scrollContainer: null
};

export const PopupPortalAnchorsContext = React.createContext(initialState);

export const usePopupPortalAnchorsContext = () => {
	return useContext(PopupPortalAnchorsContext);
};

const PopupPortalAnchors = ({ children, anchor, scrollContainerSelector }: PopupPortalAnchorProps) => {
	const [scrollContainer, setScrollContainer] = useState<OptionalQueryElement>();

	useLayoutEffect(() => {
		if (anchor && scrollContainerSelector) {
			setScrollContainer(anchor.querySelector(scrollContainerSelector));
		}
	}, [anchor, scrollContainerSelector]);

	const state = {
		anchor: anchor,
		scrollContainer: scrollContainer
	};

	return <PopupPortalAnchorsContext.Provider value={state}>{children}</PopupPortalAnchorsContext.Provider>;
};

export default PopupPortalAnchors;
