import { Button, Tooltip, Icon, Text, IconName } from '@upsales/components';
import BemClass from '@upsales/components/Utils/bemClass';
import React from 'react';
import T from 'Components/Helpers/translate';
interface Action {
	code: string;
	disabled: boolean;
	onClick: () => void;
	showTooltip?: boolean;
	title: string;
	icon: IconName;
	tooltip?: string;
	tooltipPosition?: React.ComponentProps<typeof Tooltip>['position'];
}
interface Props {
	action: Action;
	outcome: string;
	isSelected: boolean;
}
const ActionButton = ({ action, outcome, isSelected }: Props) => {
	const classNames = new BemClass('ActivityOutcomes');
	const button = (
		<Button
			key={action.code}
			size="md"
			className={classNames.elem('sub-action-btn').b()}
			disabled={action.disabled}
			color={outcome && !isSelected ? 'light-grey' : 'super-light-green'}
			onClick={action.onClick}
		>
			<Icon space="10" name={action.icon} />
			<Text size="sm">{action.title}</Text>
		</Button>
	);

	if (action.showTooltip && action.tooltip) {
		return (
			<Tooltip key={action.code} title={action.tooltip} position={action.tooltipPosition}>
				{button}
			</Tooltip>
		);
	}

	if (isSelected) {
		return (
			<Tooltip key={action.code} title={T('activity.outcome.clickToDeselect')} position="top" distance={25}>
				{button}
			</Tooltip>
		);
	}

	return button;
};

export default ActionButton;
