import { PrimaryButton, ThirdButton } from '@upsales/components/Buttons';
import {
	FullscreenModal,
	ModalContent,
	ModalHeader,
	Tab,
	Tabs,
	Text,
	Headline,
	Flex,
	Icon,
	Loader
} from '@upsales/components';
import { replaceItem } from 'App/babel/store/helpers/array';
import { makeCancelable, CancelablePromise } from 'App/babel/helpers/promise';
import { SlideFade } from 'App/components/animations';
import BemClass from '@upsales/components/Utils/bemClass';
import Configure from './Configure';
import EditorHeaderButton from 'Components/EditorHeader/EditorHeaderButton';
import ErrorBoundary from 'App/components/ErrorBoundary';
import FallbackModal from './FallbackModal';
import FulfillmentResource from 'App/babel/resources/Fulfillment';
import history from 'App/pages/routes/history';
import logError from 'App/babel/helpers/logError';
import openDrawer from 'App/services/Modal';
import openModal from 'App/services/Modal';
import React, { useMemo, useState, useRef, useEffect } from 'react';
import T from 'Components/Helpers/translate';
import type { SpawnConfig, MergeConfig } from 'App/babel/resources/Fulfillment';
import type Client from 'App/resources/Model/Client';
import type Product from 'App/resources/Model/Product';
import type ProjectPlan from 'App/resources/Model/ProjectPlan';

import './AgreementFulfillmentModal.scss';

enum State {
	Configuring,
	Saving,
	Saved
}

export type AgreementChange = {
	startDate: string;
	endDate: string | undefined;
	newProduct: Pick<Product, 'id' | 'name' | 'projectPlanTemplate'>;
	oldProduct: Pick<Product, 'id' | 'name' | 'projectPlanTemplate'> | null;
};

export type Config = {
	id: number;
	change: AgreementChange;
	action: 'merge' | 'spawn' | 'skip' | null;
	projectPlanTemplateId: number;
	projectPlanId: number | null;
	options: {
		notes: string;
		startDate: string | null;
		endDate: string | null;
		userId: number | null;
	};
};

function getNextTabIndex(configs: Config[], currentTabIndex: number) {
	const forwardIndex = configs.slice(currentTabIndex + 1).findIndex(config => config.action === null);
	if (forwardIndex > -1) {
		return forwardIndex + currentTabIndex + 1;
	}
	return configs.slice(0, currentTabIndex).findIndex(config => config.action === null);
}

function mapConfigsToAPIFormat(configs: Config[]) {
	const spawns = configs
		.filter(config => config.action === 'spawn')
		.map(({ action, projectPlanTemplateId, options }) => ({
			action,
			projectPlanTemplateId,
			...options
		})) as SpawnConfig[];

	const merges = configs
		.filter(config => config.action === 'merge')
		.map(({ action, projectPlanTemplateId, projectPlanId }) => ({
			action,
			projectPlanTemplateId,
			projectPlanId
		})) as MergeConfig[];

	return [...spawns, ...merges];
}

export type Props = {
	isNew: boolean;
	agreementGroup: { id: number; client: Pick<Client, 'id' | 'name'> };
	agreementChanges: AgreementChange[];
	className: string;
	close: () => void;
};

const AgreementFulfillmentModal = ({ className, agreementGroup, agreementChanges, close }: Props) => {
	const classNames = new BemClass('AgreementFulfillmentModal', className);
	const clientId = agreementGroup.client.id;
	const lang = useMemo(
		() => ({
			save: T('default.save'),
			cancel: T('default.cancel'),
			close: T('default.close'),
			nextStep: T('default.nextStep'),
			nextProduct: T('AgreementFulfillmentModal.nextProduct'),
			showProjects: T('AgreementFulfillmentModal.showProjects'),
			projectsCreated: T('AgreementFulfillmentModal.projectsCreated'),
			actions: {
				merge: T('AgreementFulfillmentModal.actions.merge'),
				spawn: T('AgreementFulfillmentModal.actions.spawn'),
				skip: T('AgreementFulfillmentModal.actions.skip'),
				null: T('AgreementFulfillmentModal.actions.null')
			}
		}),
		[]
	);
	const [configs, setConfigs] = useState<Config[]>(
		agreementChanges.map((change, index) => ({
			id: index,
			change,
			action: null,
			projectPlanTemplateId: change.newProduct.projectPlanTemplate!.id,
			projectPlanId: null,
			options: {
				notes: '',
				startDate: null,
				endDate: null,
				userId: null
			}
		}))
	);
	const [tabIndex, setTabIndex] = useState(0);
	const [editProjectPlanOpen, setEditProjectPlanOpen] = useState(false);
	const savePromiseRef = useRef<CancelablePromise<ProjectPlan[]> | null>(null);
	const [state, setState] = useState<State>(State.Configuring);

	useEffect(() => () => savePromiseRef.current?.cancel(), []);

	function changeTab(tabIndex: string) {
		setTabIndex(parseInt(tabIndex));
	}

	const openEditProjectPlan = (projectPlan: ProjectPlan) => {
		setEditProjectPlanOpen(true);
		openDrawer('EditProjectPlan', {
			projectPlanId: projectPlan.id,
			onClose: () => {
				setEditProjectPlanOpen(false);
			}
		});
	};

	const save = () => {
		const fulfillmentChanges = mapConfigsToAPIFormat(configs);

		if (fulfillmentChanges.length === 0) {
			close();
		} else {
			savePromiseRef.current = makeCancelable(
				FulfillmentResource.agreementFulfillmentChange(agreementGroup.id, fulfillmentChanges)
			);

			setState(State.Saving);

			savePromiseRef.current.promise
				.then(() => setState(State.Saved))
				.catch(err => {
					logError(err, 'Failed to save agreement fulfillment changes');
					setState(State.Configuring);
				});
		}
	};

	const onCancel = () => {
		openModal('UnsavedChangesAlert', {
			title: T('default.warning'),
			body: T('AgreementFulfillmentModal.UnsavedChangesAlert.body'),
			confirmButtonText: T('default.goBack'),
			onClose: (confirmed?: boolean) => {
				if (confirmed === false) {
					close();
				}
			}
		});
	};

	const goToListView = () => {
		history.push('/projects');
		close();
	};

	function onNextStep() {
		const nextTabIndex = getNextTabIndex(configs, tabIndex);

		if (nextTabIndex !== -1) {
			setTabIndex(nextTabIndex);
		} else {
			save();
		}
	}

	function getHeaderButtonProps() {
		const finished = configs.every(config => config.action !== null);
		const onlySkip = configs.every(config => config.action === 'skip');

		if (onlySkip) {
			return { title: lang.close, supertitle: null };
		}
		if (configs.length === 1) {
			return { title: lang.save, supertitle: null, disabled: !finished };
		}
		if (finished) {
			return { title: lang.save, supertitle: null };
		}
		return { title: lang.nextProduct, supertitle: lang.nextStep };
	}

	return (
		<FullscreenModal className={classNames.mod({ editProjectPlanOpen }).b()} headerAtTop>
			<ModalHeader>
				<Flex flex={1} justifyContent="space-between">
					<Tabs noFlex color="white" align="left" onChange={changeTab} selected={`${tabIndex}`}>
						{configs.map(config => (
							<Tab
								key={config.id}
								id={`${config.id}`}
								icon={config.action ? 'check-circle' : 'question-circle'}
								title={config.change.newProduct.name}
								subtitle={lang.actions[`${config.action}`]}
								selected={config.id === tabIndex}
							/>
						))}
					</Tabs>
					{state !== State.Saved ? (
						<Flex>
							<EditorHeaderButton
								title={lang.cancel}
								supertitle={undefined}
								onClick={onCancel}
								className={undefined}
								noIcon
								next={false}
							/>
							<EditorHeaderButton
								{...getHeaderButtonProps()}
								onClick={onNextStep}
								next
								className={undefined}
								noIcon={false}
								loading={state === State.Saving}
							/>
						</Flex>
					) : (
						<EditorHeaderButton
							title={lang.close}
							supertitle={undefined}
							onClick={() => close()}
							next
							className={undefined}
							noIcon={false}
						/>
					)}
				</Flex>
			</ModalHeader>
			<ModalContent>
				{state === State.Configuring ? (
					<Configure
						key={configs[tabIndex].id}
						clientId={clientId}
						config={configs[tabIndex]}
						onChange={config => setConfigs(replaceItem(configs, tabIndex, config))}
						openEditProjectPlan={openEditProjectPlan}
					/>
				) : null}
				{state === State.Saving ? (
					<Flex flex={1} justifyContent="center">
						<Loader size="md" />
					</Flex>
				) : null}
				<SlideFade visible={state === State.Saved} direction="bottom" maxHeight={500}>
					<Flex direction="column">
						<Flex space="mtxl" flex={1} justifyContent="center">
							<Headline size="md" color="success-5">
								<Icon name="check-circle" />
							</Headline>
						</Flex>
						<Flex space="mtl mbxl" flex={1} justifyContent="center">
							<Text size="lg" color="success-5">
								{lang.projectsCreated}
							</Text>
						</Flex>
						<Flex space="mtxl" flex={1} justifyContent="center">
							<PrimaryButton size="lg" onClick={() => goToListView()}>
								{lang.showProjects}
							</PrimaryButton>
						</Flex>
						<Flex flex={1} justifyContent="center">
							<ThirdButton size="lg" onClick={() => close()}>
								{lang.close}
							</ThirdButton>
						</Flex>
					</Flex>
				</SlideFade>
			</ModalContent>
		</FullscreenModal>
	);
};

export default (props: Props) => {
	return (
		<ErrorBoundary fallback={() => <FallbackModal className={props.className} close={props.close} />}>
			<AgreementFulfillmentModal {...props} />
		</ErrorBoundary>
	);
};
