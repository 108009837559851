import React, { useState } from 'react';
import BemClass from '@upsales/components/Utils/bemClass';
import { Button, Text, Title, DateInput, TimeInput } from '@upsales/components';
import { scheduleMail } from '../../MailContextHelpers';
import { useMailContext } from '../../MailContext';
import { MailRecipient } from 'App/resources/Model/Mail';
import T from 'Components/Helpers/translate';
import moment from 'moment';
import './ScheduleMail.scss';
import logError from 'Helpers/logError';

const ROUNDING = 5 * 60 * 1000;

interface PrintRecipientsProps {
	title: string;
	recipients: MailRecipient[];
}
const PrintRecipients = ({ title, recipients }: PrintRecipientsProps) => {
	if (!recipients.length) return null;
	return (
		<>
			<Title bold>{title}</Title>
			<Text>{recipients.map(r => ' ' + (r.name || r.email)).toString()}</Text>
		</>
	);
};

interface ScheduleMailProps {
	onClose: () => void;
	onMailSchedule?: () => void;
}

const ScheduleMail = ({ onClose, onMailSchedule }: ScheduleMailProps) => {
	const { state, dispatch } = useMailContext();
	const [scheduleDate, setScheduleDate] = useState(moment().toDate());
	const nearest5Min = moment(Math.ceil(+moment(new Date()) / ROUNDING) * ROUNDING).format('HH:mm');
	const [scheduleTime, setScheduleTime] = useState(nearest5Min);
	const classes = new BemClass('ScheduleMail');

	return (
		<div className={classes.b()}>
			<div className={classes.elem('schedule').b()}>
				<Title bold>{T('mail.subject')}</Title>
				<Text>{state.subject}</Text>
				<PrintRecipients title={T('mail.to')} recipients={state.recipients.to} />
				<PrintRecipients title={T('mail.cc')} recipients={state.recipients.cc} />
				<PrintRecipients title={T('mail.bcc')} recipients={state.recipients.bcc} />
				<Title bold>{T('default.date')}</Title>
				<div className={classes.elem('dateAndTimeInput').b()}>
					<DateInput
						value={scheduleDate}
						onChange={(e: any) => setScheduleDate(e.target.value)}
						icon="calendar"
						min={moment().toDate()}
					/>
					<TimeInput
						value={scheduleTime}
						onChange={(e: any) => setScheduleTime(e.target.value)}
						icon="clock-o"
						min={moment(scheduleDate).isSame(new Date(), 'day') ? nearest5Min : undefined}
					/>
				</div>
				<Button
					onClick={async () => {
						try {
							await scheduleMail(dispatch, state, scheduleDate, scheduleTime);
							onClose();
							onMailSchedule?.();
						} catch (err) {
							logError(err, 'failed to schedule mail');
						}
					}}
				>
					{T('mail.schedule')}
				</Button>
			</div>
			<Button className={classes.elem('cancelButton').b()} type="link" color="grey" onClick={onClose}>
				{T('default.cancel')}
			</Button>
		</div>
	);
};

export default ScheduleMail;
