import React, { MutableRefObject, useRef } from 'react';
import { useTranslation } from 'Components/Helpers/translate';

import Modal from '@upsales/components/Modal/Modal';
import BemClass from '@upsales/components/Utils/bemClass';
import { PrimaryButton, ThirdButton } from '@upsales/components/Buttons';
import { defaultFilterGetter } from 'App/helpers/multiActionRunnerHelpers';
import { MultiSelect } from '../../ListView/ListViewRenderHelpers';
import RequestBuilder from 'Resources/RequestBuilder';
import { Text, Column, ModalHeader, ModalContent, ModalControls } from '@upsales/components';
import './MultiCloseActivity.scss';

import { ModalProps } from 'App/components/Modals/Modals';
import type { PropertyArray } from '../MultiActionModal';
import moment from 'moment';

type Props = {
	multiSelect: MultiSelect;
	entityType?: string;
	onSave?: (props: PropertyArray) => Promise<void>;
} & ModalProps;

const MultiCloseActivity = ({ multiSelect, entityType = 'Activity', onSave, close, className }: Props) => {
	const { t } = useTranslation();
	const modalRef: MutableRefObject<HTMLDivElement | null> = useRef<HTMLDivElement>(null);
	const isAnActivity = entityType === 'Activity';
	const classes = new BemClass('MultiCloseActivity', className);

	const save = async () => {
		const propArray: PropertyArray = [];
		propArray.push({ name: 'CloseDate', value: moment().format('YYYY-MM-DD') });

		if (onSave) {
			return onSave(propArray);
		}

		const filters = defaultFilterGetter(new RequestBuilder(), multiSelect);

		Tools.MultiActions.customer(Tools.AppService.getCustomerId()).updateActivity(
			multiSelect.selectedIds.length,
			propArray,
			filters.build()
		);
	};

	return (
		<Modal size="md" className={classes.b()}>
			<div ref={modalRef}>
				<ModalHeader
					title={isAnActivity ? t('default.closeActivities') : t('default.closeTasks')}
					onClose={close}
					icon="edit"
				/>
				<ModalContent>
					<Text>{isAnActivity ? t('confirm.multiCloseActivity') : t('confirm.multiCloseTasks')}</Text>
				</ModalContent>
				<ModalControls>
					<Column align="right">
						<PrimaryButton
							onClick={() => {
								save();
								close();
							}}
						>
							{isAnActivity ? t('default.closeActivities') : t('default.closeTasks')}
						</PrimaryButton>
						<ThirdButton onClick={() => close()}>{t('default.cancel')}</ThirdButton>
					</Column>
				</ModalControls>
			</div>
		</Modal>
	);
};

export default MultiCloseActivity;
