import React from 'react';
import T from '../Helpers/translate';
import UserRoleTree from '../Helpers/UserRoleTree';

import './UserRoleSelect.scss';

export default function UserRoleSelect({
	defaultValue,
	tabIndex = 0,
	onChange,
	placeholder = null,
	includeLoggedInUser = false,
	excludeGhosts = false,
	dropdownCssClass = null,
	className = '',
	disabled = false,
	accessType = undefined
}) {
	const data = [
		{
			name: T('default.allActiveUsers'),
			$depth: 0,
			isSpecialRow: true,
			children: UserRoleTree({
				includeInactive: true,
				selectableRoles: true,
				noParentRef: true,
				roleIdPrefix: true,
				startDepthOn: 1,
				includeLoggedInUser: includeLoggedInUser ?? false,
				excludeGhosts: excludeGhosts,
				accessType: accessType
			})
		}
	];
	const defaultProps = {
		data: data,
		multiple: true,
		tabIndex: tabIndex,
		onChange: onChange,
		value: defaultValue,
		placeholder: placeholder,
		autocomplete: 'off',
		getId: object => object.id,
		formatResult: (item, container, query, escape) => {
			const { isRole, name, children, isSpecialRow } = item;

			if (isSpecialRow) {
				return `${escape(name)}<span class='NumberChildren'>${Tools.AppService.getActiveUsers().length}</span>`;
			}

			if (isRole) {
				return `${escape(name)}<span class='NumberChildren'>${children.length}</span>`;
			}

			return escape(item.name);
		},
		formatSelection: (item, container, escape) => {
			if (typeof item === 'object') {
				return escape(item.name);
			}

			if (item.indexOf('role') === 0) {
				return escape(convertRoleObject(item).name);
			}

			return escape(convertUserObject(item).name);
		},
		className: className || 'UserRoleSelect--Select',
		dropdownCssClass: dropdownCssClass || 'UserRoleSelect--Dropdown',
		disabled: disabled
	};

	return (
		<div key={excludeGhosts}>
			<ReactTemplates.INPUTS.upUsers {...defaultProps} />
		</div>
	);

	function convertRoleObject(id) {
		const allRoles = [].concat(Tools.AppService.getRoles());
		const foundRole = _.find(allRoles, role => {
			return role.id === parseInt(id.replace('role-', ''));
		});

		return foundRole;
	}

	function convertUserObject(id) {
		const allUsers = [].concat(Tools.AppService.getUsers());
		const foundUser = _.find(allUsers, user => {
			return user.id === parseInt(id);
		});

		foundUser.id = foundUser.id.toString();
		return foundUser;
	}
}
