import './LostOpportunity.scss';

import { sortByAll } from 'lodash';
import React, { useState, useEffect } from 'react';

import BemClass from '@upsales/components/Utils/bemClass';
import T from 'Components/Helpers/translate';
import FieldTranslation from 'App/babel/resources/FieldTranslations';
import TodoResource from 'App/babel/resources/Todo';
import ActivityResource from 'App/babel/resources/Activity';
import { Block, Button, Flex, Headline, Loader, Text, Icon, Toggle, ModalHeader, Tooltip } from '@upsales/components';
import { SlideFade } from '@upsales/components/animations';
import RiskyOpportunities from './RiskyOpportunities';
import useRiskyOpportunities from 'App/components/LostOpportunity/useRiskyOpportunities';
import logError from 'App/babel/helpers/logError';
import RequestBuilder, { comparisonTypes } from 'Resources/RequestBuilder';
import CompetitorSelect from 'App/components/CompetitorSelect';
import Order, { LostReason } from 'App/resources/Model/Order';
import { PrimaryButton, ThirdButton, DefaultButton } from '@upsales/components/Buttons';
import Task from 'App/resources/Model/Todo'; // Using alias Task for type Todo for clarity
import { ModalProps } from '../Modals/Modals';

export type Props = Partial<ModalProps> & {
	visible?: boolean;
	order?: Order;
	closeModal?: (position?: string) => void;
	reject?: () => void;
	onSaveLostReason?: (lostReasonId: number, competitor?: number) => void;
	pendingSave?: boolean;
	reloadModalPosition?: () => void;
	isModal?: boolean;
	animate?: boolean;
};

const LostOpportunity = (props: Props) => {
	const {
		visible,
		order,
		close,
		closeModal,
		reject,
		onSaveLostReason,
		pendingSave,
		reloadModalPosition,
		isModal,
		animate
	} = props;
	const classes = new BemClass('LostOpportunity');
	const [selectedReason, setSelectedReason] = useState<number | null>();
	const [reasons, setReasons] = useState<LostReason[]>(() => []);
	const [savedLostReason, setSavedLostReason] = useState<LostReason>();
	const [showPostAction, setShowPostAction] = useState(false);
	const [selectedCompetitor, setSelectedCompetitor] = useState<number | null>();
	const [hideButtonContainer, setHideButtonContainer] = useState(false);
	const [hasSalesboardOpportunities, setHasSalesboardOpportunities] = useState(false);
	const onClose = closeModal ? () => closeModal('top') : reject || close;
	const [shouldDeleteTasks, setShouldDeleteTasks] = useState(false);
	const [deleteWasClicked, setDeleteWasClicked] = useState(false);
	const [relatedTasks, setRelatedTasks] = useState<Task[]>([]);
	const [relatedTodosIsExpanded, setRelatedTodosIsExpanded] = useState(false);
	const [relatedPhoneCallsIsExpanded, setRelatedPhoneCallsIsExpanded] = useState(false);
	const [relatedAppointmentsIsExpanded, setRelatedAppointmentsIsExpanded] = useState(false);

	const fetchSalesboardOpportunities = async () => {
		const rb = new RequestBuilder();
		rb.limit = 0;
		rb.addFilter({ field: 'user.id' }, comparisonTypes.Equals, Tools.AppService.getSelf().id);
		rb.addFilter({ field: 'probability' }, comparisonTypes.GreaterThan, 0);
		rb.addFilter({ field: 'probability' }, comparisonTypes.LessThan, 100);
		const orders = await Tools.Order.customer(Tools.AppService.getCustomerId()).find(rb.build());
		setHasSalesboardOpportunities((orders?.metadata?.total ?? 0) > 1);
	};

	useEffect(() => {
		fetchSalesboardOpportunities();
	}, []);

	useEffect(() => {
		if (savedLostReason) {
			if (savedLostReason?.tag?.subType) {
				setShowPostAction(true);
			} else {
				onClose?.();
			}
		}
	}, [savedLostReason, hasSalesboardOpportunities]);

	const { riskyOpportunities, pendingFetch, isHighestRiskOpportunities } = useRiskyOpportunities(
		order,
		savedLostReason?.tag
	);

	useEffect(() => {
		if (reloadModalPosition) {
			reloadModalPosition();
		}
	}, [reloadModalPosition]);

	useEffect(() => {
		if (showPostAction && !pendingFetch && hasSalesboardOpportunities && !riskyOpportunities.length) {
			onClose?.();
		}
	}, [showPostAction, hasSalesboardOpportunities, pendingFetch, riskyOpportunities]);

	const buildFieldTranslationFilter = () => {
		const rb = new RequestBuilder();
		rb.addFilter({ field: 'type' }, comparisonTypes.Equals, 'orderlostreason');
		return rb.build();
	};

	const sortByStandardReason = (reason: LostReason) => !reason?.tag?.subType;

	const sortByReasonLength = (reason: LostReason) => reason?.value?.length;

	const sortReasons = (reasons: LostReason[]) => {
		return sortByAll(reasons, [sortByStandardReason, sortByReasonLength]);
	};

	const getReasonTypes = async () => {
		const { data } = await FieldTranslation.find(buildFieldTranslationFilter());
		setReasons(sortReasons(data));
	};

	useEffect(() => {
		getReasonTypes();
	}, []);

	const findRelatedTasks = async () => {
		if (order?.id) {
			const { data } = await TodoResource.find({ opportunityId: order.id });
			setRelatedTasks(data as Task[]);
		}
	};

	const deleteRelatedTasks = async () => {
		let deletePromise;
		for (let i = 0; i < relatedTasks.length; i++) {
			if (relatedTasks[i].type === 'appointment') {
				deletePromise = Tools.Appointment.customer(Tools.AppService.getCustomerId()).delete(relatedTasks[i]);
			} else {
				deletePromise = ActivityResource.delete(relatedTasks[i].id, { skipNotification: true });
			}
			deletePromise
				.then(() => {
					relatedTasks.splice(i, 1);
				})
				.catch(() => {
					logError(`Failed to delete relatedTask`);
				});
		}
		setShouldDeleteTasks(false);
		setDeleteWasClicked(true);
	};

	useEffect(() => {
		findRelatedTasks();
		const appointmentAddedListener = Tools.$rootScope.$on('appointment.added', () => {
			findRelatedTasks();
		});
		const appointmentUpdateListener = Tools.$rootScope.$on('appointment.updated', () => {
			findRelatedTasks();
		});
		const appointmentDeleteListener = Tools.$rootScope.$on('appointment.deleted', () => {
			// wait for the delete to finish before fetching the tasks again
			setTimeout(findRelatedTasks, 1000);
		});
		const activityAddedListener = Tools.$rootScope.$on('activity.added', () => {
			findRelatedTasks();
		});
		const activityUpdateListener = Tools.$rootScope.$on('activity.updated', () => {
			findRelatedTasks();
		});
		const activityDeleteListener = Tools.$rootScope.$on('activity.deleted', () => {
			// wait for the delete to finish before fetching the tasks again
			setTimeout(findRelatedTasks, 1000);
		});
		return () => {
			appointmentAddedListener();
			appointmentUpdateListener();
			appointmentDeleteListener();
			activityAddedListener();
			activityUpdateListener();
			activityDeleteListener();
		};
	}, []);

	const onReasonClick = (reasonId: number) => {
		setSelectedReason(reasonId === selectedReason ? null : reasonId);
	};

	const saveReasonToOrder = async () => {
		if (!selectedReason || !onSaveLostReason) {
			return;
		}
		await onSaveLostReason(selectedReason, selectedCompetitor!);
		const savedReason = reasons.find(reason => reason.tagId === selectedReason);
		setSavedLostReason(savedReason);
	};

	const goToProspecting = async () => {
		Tools.$state.go('findProspects');
		if (onClose) {
			onClose();
		}
	};

	const renderHeader = () => {
		const companyName = order?.client?.name ?? '';
		return (
			<ModalHeader
				className={classes.elem('header').mod({ isModal }).b()}
				title={T('order.opportunityWithCompany', { companyName })}
				icon="opportunity"
				onClose={onClose}
			/>
		);
	};

	const renderLostOpportunityText = () => (
		<Block>
			<Headline className={classes.elem('lostOpportunityText').b()} size="xs" color="red">
				{T('order.lost.lostOpportunity')}
			</Headline>
		</Block>
	);

	const renderHeadlines = () => (
		<Block space="mtl mbl">
			<Headline className={classes.elem('condolencesHeadline').b()} size="sm">
				{T('order.lost.headline')}
			</Headline>
			<Headline className={classes.elem('condolencesSubheadline').b()} size="sm">
				{T('order.lost.subheadline')}
			</Headline>
		</Block>
	);

	const renderHeadline = (elemName: string, text: string) => (
		<Block space="mtxl mbxl">
			<Headline className={classes.elem(elemName).b()} size="sm">
				{text}
			</Headline>
		</Block>
	);

	const renderHeaderText = (elemName: string, text: string) => (
		<Block space="mts mbxl">
			<Text className={classes.elem(elemName).b()} size="lg">
				{text}
			</Text>
		</Block>
	);

	const renderReasonButton = (reason: LostReason) => {
		const isSelected = selectedReason === reason.tagId;
		return (
			<Block space="mbl mls mrs" key={`reason${reason.tagId}`} className={classes.elem('lostReason').b()}>
				{isSelected ? (
					<PrimaryButton
						className={classes.elem(`reason${reason.tagId}`).b()}
						onClick={() => onReasonClick(reason.tagId)}
					>
						<Icon name={'check'} space="mrm" />
						{reason.value}
					</PrimaryButton>
				) : (
					<DefaultButton
						className={classes.elem(`reason${reason.tagId}`).b()}
						onClick={() => onReasonClick(reason.tagId)}
					>
						<Icon name={'plus'} space="mrm" />
						{reason.value}
					</DefaultButton>
				)}
			</Block>
		);
	};

	const renderSaveButton = (disabled: boolean) => (
		<Block className={classes.elem('fullWidthButton').b()} space="mtxl prxl plxl">
			<Tooltip
				className={classes.elem('disabledSaveButtonTooltip').b()}
				title={T('order.lost.saveLostReasonTooltip')}
				disabled={!!selectedReason}
			>
				<PrimaryButton
					key="save-lost-reason"
					className={classes.elem('saveButton').b()}
					text={T('save')}
					size="lg"
					block
					onClick={() => saveReasonToOrder()}
					loading={pendingSave}
					disabled={disabled}
					color={disabled ? 'light-grey' : 'green'}
					shadow={disabled ? 'none' : ''}
				/>
			</Tooltip>
		</Block>
	);

	const renderCloseButton = () => (
		<Block space="mtl">
			{onClose ? (
				<ThirdButton
					className={classes.elem('closeButton').b()}
					onClick={() => onClose()}
					text={T('close')}
					size="lg"
				/>
			) : null}
		</Block>
	);

	const renderTaskDeleteInfo = (
		type: Task['type'],
		setIsExpanded: React.Dispatch<React.SetStateAction<boolean>>,
		isExpanded: Boolean
	) => {
		const tasks: Task[] = relatedTasks.filter(task => task.type === type);
		return (
			<>
				<Flex alignItems="center">
					<Text bold align="left" size="md">
						{tasks.length} {T(`default.${type}s`)}
					</Text>
					<Block
						className={classes
							.elem('dropdownButton')
							.mod({ hide: tasks.length === 0 })
							.b()}
					>
						<Button type="link" disabled={tasks.length === 0} onClick={() => setIsExpanded(!isExpanded)}>
							<Icon name={isExpanded ? 'chevron-up' : 'chevron-down'} color="grey-13" />
						</Button>
					</Block>
				</Flex>
				<Block className={classes.elem('taskDeleteInfoContainer').elem(type).mod({ hide: !isExpanded }).b()}>
					{tasks.map(task => (
						<Block space="pts pbm" key={task.id}>
							<Text key={task.id} color="grey-11" align="left" size="md">
								{task.description}
							</Text>
						</Block>
					))}
				</Block>
			</>
		);
	};

	const renderDeleteTaskButton = (disabled: boolean) => (
		<Button
			className={classes.elem('taskDeleteButton').mod({ hide: disabled }).b()}
			onClick={() => deleteRelatedTasks()}
			text={T('order.lost.deleteTasks')}
			size="sm"
			color="red"
			disabled={disabled}
		/>
	);

	const renderContent = () => (
		<Block space="ptl pbl prxl plxl" className={classes.elem('content').mod({ isModal }).b()}>
			<div
				className={classes
					.elem('textContainer')
					.mod({ fadeOut: !!selectedReason || shouldDeleteTasks })
					.b()}
			>
				{renderLostOpportunityText()}
				{renderHeadlines()}
			</div>

			{relatedTasks.length > 0 ? (
				<div
					className={classes
						.elem('relatedTasksContainer')
						.mod({ fadeOut: !!selectedReason, slideDown: shouldDeleteTasks })
						.b()}
				>
					<Block
						space="pts pll prl"
						className={classes
							.elem('relatedTasksText')
							.mod({ slideUp: !!selectedReason, fadeOut: deleteWasClicked })
							.b()}
					>
						<Text bold size="md" center={false} align="left">
							{T('order.lost.thereAreRelatedTasks')}
						</Text>
					</Block>

					<Block
						space="ptl pll prl pbm"
						className={classes
							.elem('taskDeleteContainer')
							.mod({ slideUp: !!selectedReason, fadeOut: deleteWasClicked })
							.b()}
					>
						<Flex alignItems="center">
							<Toggle
								size="md"
								color="red"
								icon="trash"
								checked={shouldDeleteTasks}
								disabled={relatedTasks.length === 0}
								onChange={() => setShouldDeleteTasks(!shouldDeleteTasks)}
							></Toggle>
							<Block
								className={classes.elem('deleteTaskToggleText').mod({ hide: !shouldDeleteTasks }).b()}
								space="plm"
							>
								<Text>{T('order.lost.shouldDeleteTasks')}</Text>
								<Text color="red">{T('order.lost.deleteTasksWarning')}</Text>
							</Block>
						</Flex>
						<Block
							space="ptl pbl"
							className={classes.elem('taskDeleteInfoContainer').mod({ hide: !shouldDeleteTasks }).b()}
						>
							{renderTaskDeleteInfo('todo', setRelatedTodosIsExpanded, relatedTodosIsExpanded)}
							<Block border="bs" borderColor="grey-4" />
							{renderTaskDeleteInfo(
								'phonecall',
								setRelatedPhoneCallsIsExpanded,
								relatedPhoneCallsIsExpanded
							)}
							<Block border="bs" borderColor="grey-4" />
							{renderTaskDeleteInfo(
								'appointment',
								setRelatedAppointmentsIsExpanded,
								relatedAppointmentsIsExpanded
							)}
						</Block>
						{renderDeleteTaskButton(!shouldDeleteTasks)}
					</Block>
				</div>
			) : null}
			<div className={classes.elem('bottomContent').mod({ fadeOutDown: shouldDeleteTasks }).b()}>
				<Block space="pll prl mts">
					<Text
						bold
						className={classes.elem('reasonForLossText').mod({ slideUp: !!selectedReason }).b()}
						size="md"
					>
						{T('order.lost.reasonForLoss')}
					</Text>
				</Block>

				<Block
					space="mts mbs ptm prl pll"
					className={classes.elem('lostReasons').mod({ slideUp: !!selectedReason }).b()}
				>
					{reasons.map(renderReasonButton)}
				</Block>

				<Block space="mbl" className={classes.elem('competitorSelectContainer').b()}>
					<CompetitorSelect
						visible={!!selectedReason}
						onChange={setSelectedCompetitor}
						onAddOptionModeChange={setHideButtonContainer}
					/>
				</Block>

				<div
					className={classes
						.elem('buttonContainer')
						.mod({ slideDown: !!selectedReason, hide: hideButtonContainer })
						.b()}
				>
					{renderSaveButton(!selectedReason)}
					{renderCloseButton()}
				</div>
			</div>
		</Block>
	);

	const renderFindNewLeadsButton = () => (
		<Block space="mtl">
			<PrimaryButton
				className={classes.elem('findNewLeadsButton').b()}
				onClick={goToProspecting}
				text={T('default.findProspects')}
				size="lg"
			/>
		</Block>
	);

	const renderFindNewLeads = () => (
		<Block space="ptl pbl prxl plxl mtxl" className={classes.elem('content').mod({ isModal }).b()}>
			{renderHeadline('findNewLeadsTitle', T('order.lost.findNewLeadsTitle'))}
			{renderHeaderText('findNewLeadsText', T('order.lost.findNewLeadsText'))}
			{renderFindNewLeadsButton()}
			{renderCloseButton()}
		</Block>
	);

	const renderLoader = () => (
		<div className={classes.elem('loader').b()}>
			<Loader />
		</div>
	);

	const renderPostSaveView = () => (
		<>
			{pendingFetch && renderLoader()}
			<div className={classes.elem('postSaveView').mod({ visible: !pendingFetch }).b()}>
				{!pendingFetch && showPostAction && (
					<>
						{!riskyOpportunities.length && !hasSalesboardOpportunities ? (
							renderFindNewLeads()
						) : riskyOpportunities.length ? (
							<RiskyOpportunities
								lostReason={savedLostReason}
								riskyOpportunities={riskyOpportunities}
								order={order}
								onClose={onClose}
								isHighestRiskOpportunities={isHighestRiskOpportunities}
							/>
						) : null}
					</>
				)}
			</div>
		</>
	);

	return (
		<SlideFade visible={visible} disabled={!animate} delayInMs={500}>
			<div className={classes.mod({ isModal, visible }).b()}>
				{renderHeader()}
				{savedLostReason ? renderPostSaveView() : renderContent()}
			</div>
		</SlideFade>
	);
};

export default LostOpportunity;
