import logError from 'App/babel/helpers/logError';
import { getAvailableCountries as getAvailableProspectingCountries } from 'App/services/prospectingService';
import RequestBuilder, { comparisonTypes } from 'Resources/RequestBuilder';
import ClientAttributes from 'App/babel/attributes/Client';
import getAngularModule from 'App/babel/angularHelpers/getAngularModule';

function getAvailableCountries(self: any, hasSoliditet: boolean, includeSoliditet = true, includeProspecting = true) {
	const countries = [];

	if (includeSoliditet && hasSoliditet) {
		countries.push('dk', 'fi', 'no', 'se');
	}
	if (includeProspecting) {
		const prospectingCountries = getAvailableProspectingCountries().map(country => country.toLowerCase());
		countries.push(...prospectingCountries);
	}

	return [...new Set(countries)];
}

function addCountryFilter(requestBuilder: RequestBuilder, countries: string[]) {
	const orFilter = requestBuilder.orBuilder();

	orFilter.next();
	// Either the country is one of the ones we ask for
	const countryGroupBuilder = orFilter.groupBuilder();
	countryGroupBuilder.addFilter({ field: 'address.type' }, comparisonTypes.Equals, 'Visit');
	countryGroupBuilder.addFilter({ field: 'address.country' }, comparisonTypes.Equals, countries);
	countryGroupBuilder.done();

	orFilter.next();
	// Or the country is null
	const nullGroupBuilder = orFilter.groupBuilder();
	nullGroupBuilder.addFilter({ field: 'address.type' }, comparisonTypes.Equals, 'Visit');
	nullGroupBuilder.addFilter({ field: 'address.country' }, comparisonTypes.Equals, null);
	nullGroupBuilder.done();

	orFilter.next();
	// Or it is an empty string
	const emptyGroupBuilder = orFilter.groupBuilder();
	emptyGroupBuilder.addFilter({ field: 'address.type' }, comparisonTypes.Equals, 'Visit');
	emptyGroupBuilder.addFilter({ field: 'address.country' }, comparisonTypes.Equals, '');
	emptyGroupBuilder.done();

	orFilter.done();
}

export const getMatchRequestBuilder = () => {
	const filters = new RequestBuilder();
	filters.addFilter({ field: 'isExternal' }, comparisonTypes.Equals, 0);

	const self = Tools.AppService.getSelf();
	const hasSoliditet = self?.userParams?.soliditetIsActive;
	const hasProspecting = Tools.FeatureHelper.isAvailable(Tools.FeatureHelper.Feature.PROSPECTING_BASIC);

	const orFilter = filters.orBuilder();

	if (hasSoliditet) {
		orFilter.next();
		orFilter.addFilter(ClientAttributes.dunsNo, comparisonTypes.Equals, null);

		if (hasProspecting) {
			orFilter.addFilter(ClientAttributes.prospectingId, comparisonTypes.Equals, null);
			addCountryFilter(orFilter, getAvailableCountries(self, hasSoliditet));
		} else {
			addCountryFilter(orFilter, getAvailableCountries(self, hasSoliditet, true, false));
		}

		orFilter.next();

		orFilter.addFilter(ClientAttributes.dunsNo, comparisonTypes.Equals, '0');
		if (hasProspecting) {
			orFilter.addFilter(ClientAttributes.prospectingId, comparisonTypes.Equals, null);
			addCountryFilter(orFilter, getAvailableCountries(self, hasSoliditet));
		} else {
			addCountryFilter(orFilter, getAvailableCountries(self, hasSoliditet, true, false));
		}
	}

	if (hasProspecting) {
		orFilter.next();
		orFilter.addFilter(ClientAttributes.prospectingId, comparisonTypes.Equals, null);
		addCountryFilter(orFilter, getAvailableCountries(self, false, true));
	}

	filters.addFilter(ClientAttributes.dunsNo, comparisonTypes.NotEquals, '-1');

	orFilter.done();

	return filters;
};

export const getMatches = (customerId: number): Promise<number | void> => {
	const SoliditetClient = getAngularModule('SoliditetClient');

	const filters = getMatchRequestBuilder();
	filters.limit = 0;
	filters.addSort(ClientAttributes.name, true);

	return SoliditetClient.customer(customerId)
		.findMatches(filters.build())
		.then((res: { metadata: { total: number } }) => res.metadata.total)
		.catch((e: unknown) => {
			logError(e, 'Failed to get matches from SoliditetClient');
		});
};
