export const countries = [
	{ lang: 'countries.afghanistan', code: 'AF', id: 'AF' },
	{ lang: 'countries.alandIslands', code: 'AX', id: 'AX' },
	{ lang: 'countries.albania', code: 'AL', id: 'AL' },
	{ lang: 'countries.algeria', code: 'DZ', id: 'DZ' },
	{ lang: 'countries.americanSamoa', code: 'AS', id: 'AS' },
	{ lang: 'countries.andorra', code: 'AD', id: 'AD' },
	{ lang: 'countries.angola', code: 'AO', id: 'AO' },
	{ lang: 'countries.anguilla', code: 'AI', id: 'AI' },
	{ lang: 'countries.antarctica', code: 'AQ', id: 'AQ' },
	{ lang: 'countries.antiguaAndBarbuda', code: 'AG', id: 'AG' },
	{ lang: 'countries.argentina', code: 'AR', id: 'AR' },
	{ lang: 'countries.armenia', code: 'AM', id: 'AM' },
	{ lang: 'countries.aruba', code: 'AW', id: 'AW' },
	{ lang: 'countries.australia', code: 'AU', id: 'AU' },
	{ lang: 'countries.austria', code: 'AT', id: 'AT' },
	{ lang: 'countries.azerbaijan', code: 'AZ', id: 'AZ' },
	{ lang: 'countries.bahamas', code: 'BS', id: 'BS' },
	{ lang: 'countries.bahrain', code: 'BH', id: 'BH' },
	{ lang: 'countries.bangladesh', code: 'BD', id: 'BD' },
	{ lang: 'countries.barbados', code: 'BB', id: 'BB' },
	{ lang: 'countries.belarus', code: 'BY', id: 'BY' },
	{ lang: 'countries.belgium', code: 'BE', id: 'BE' },
	{ lang: 'countries.belize', code: 'BZ', id: 'BZ' },
	{ lang: 'countries.benin', code: 'BJ', id: 'BJ' },
	{ lang: 'countries.bermuda', code: 'BM', id: 'BM' },
	{ lang: 'countries.bhutan', code: 'BT', id: 'BT' },
	{ lang: 'countries.bolivia', code: 'BO', id: 'BO' },
	{ lang: 'countries.bosniaAndHerzegovina', code: 'BA', id: 'BA' },
	{ lang: 'countries.botswana', code: 'BW', id: 'BW' },
	{ lang: 'countries.brazil', code: 'BR', id: 'BR' },
	{ lang: 'countries.britishIndianOceanTerritory', code: 'IO', id: 'IO' },
	{ lang: 'countries.bruneiDarussalam', code: 'BN', id: 'BN' },
	{ lang: 'countries.bulgaria', code: 'BG', id: 'BG' },
	{ lang: 'countries.burkinaFaso', code: 'BF', id: 'BF' },
	{ lang: 'countries.burundi', code: 'BI', id: 'BI' },
	{ lang: 'countries.cambodia', code: 'KH', id: 'KH' },
	{ lang: 'countries.cameroon', code: 'CM', id: 'CM' },
	{ lang: 'countries.canada', code: 'CA', id: 'CA' },
	{ lang: 'countries.capeVerde', code: 'CV', id: 'CV' },
	{ lang: 'countries.caymanIslands', code: 'KY', id: 'KY' },
	{ lang: 'countries.ivoryCoast', code: 'CI', id: 'CI' },
	{ lang: 'countries.centralAfricanRepublic', code: 'CF', id: 'CF' },
	{ lang: 'countries.chad', code: 'TD', id: 'TD' },
	{ lang: 'countries.chile', code: 'CL', id: 'CL' },
	{ lang: 'countries.china', code: 'CN', id: 'CN', language: 'language.chinese' },
	{ lang: 'countries.christmasIsland', code: 'CX', id: 'CX' },
	{ lang: 'countries.cocosKeelingIslands', code: 'CC', id: 'CC' },
	{ lang: 'countries.colombia', code: 'CO', id: 'CO' },
	{ lang: 'countries.comoros', code: 'KM', id: 'KM' },
	{ lang: 'countries.congo', code: 'CD', id: 'CD' },
	{ lang: 'countries.cookIslands', code: 'CK', id: 'CK' },
	{ lang: 'countries.costaRica', code: 'CR', id: 'CR' },
	{ lang: 'countries.croatia', code: 'HR', id: 'HR' },
	{ lang: 'countries.cuba', code: 'CU', id: 'CU' },
	{ lang: 'countries.cyprus', code: 'CY', id: 'CY' },
	{ lang: 'countries.czechRepublic', code: 'CZ', id: 'CZ' },
	{ lang: 'countries.denmark', code: 'DK', id: 'DK', language: 'language.danish' },
	{ lang: 'countries.djibouti', code: 'DJ', id: 'DJ' },
	{ lang: 'countries.dominica', code: 'DM', id: 'DM' },
	{ lang: 'countries.dominicanRepublic', code: 'DO', id: 'DO' },
	{ lang: 'countries.ecuador', code: 'EC', id: 'EC' },
	{ lang: 'countries.egypt', code: 'EG', id: 'EG' },
	{ lang: 'countries.elSalvador', code: 'SV', id: 'SV' },
	{ lang: 'countries.equatorialGuinea', code: 'GQ', id: 'GQ' },
	{ lang: 'countries.eritrea', code: 'ER', id: 'ER' },
	{ lang: 'countries.estonia', code: 'EE', id: 'EE' },
	{ lang: 'countries.ethiopia', code: 'ET', id: 'ET' },
	{ lang: 'countries.falklandIslandsMalvinas', code: 'FK', id: 'FK' },
	{ lang: 'countries.faroeIslands', code: 'FO', id: 'FO' },
	{ lang: 'countries.fiji', code: 'FJ', id: 'FJ' },
	{ lang: 'countries.finland', code: 'FI', id: 'FI', language: 'language.finish' },
	{ lang: 'countries.france', code: 'FR', id: 'FR', language: 'language.frensh' },
	{ lang: 'countries.frenchPolynesia', code: 'PF', id: 'PF' },
	{ lang: 'countries.frenchSouthernTerritories', code: 'TF', id: 'TF' },
	{ lang: 'countries.gabon', code: 'GA', id: 'GA' },
	{ lang: 'countries.gambia', code: 'GM', id: 'GM' },
	{ lang: 'countries.georgia', code: 'GE', id: 'GE' },
	{ lang: 'countries.germany', code: 'DE', id: 'DE', language: 'language.german' },
	{ lang: 'countries.ghana', code: 'GH', id: 'GH' },
	{ lang: 'countries.gibraltar', code: 'GI', id: 'GI' },
	{ lang: 'countries.greece', code: 'GR', id: 'GR' },
	{ lang: 'countries.greenland', code: 'GL', id: 'GL' },
	{ lang: 'countries.grenada', code: 'GD', id: 'GD' },
	{ lang: 'countries.guadeloupe', code: 'GP', id: 'GP' },
	{ lang: 'countries.guam', code: 'GU', id: 'GU' },
	{ lang: 'countries.guatemala', code: 'GT', id: 'GT' },
	{ lang: 'countries.guernsey', code: 'GG', id: 'GG' },
	{ lang: 'countries.guinea', code: 'GN', id: 'GN' },
	{ lang: 'countries.guineaBissau', code: 'GW', id: 'GW' },
	{ lang: 'countries.guyana', code: 'GY', id: 'GY' },
	{ lang: 'countries.haiti', code: 'HT', id: 'HT' },
	{ lang: 'countries.heardIslandAndMcdonaldIslands', code: 'HM', id: 'HM' },
	{ lang: 'countries.holySeeVaticanCityState', code: 'VA', id: 'VA' },
	{ lang: 'countries.honduras', code: 'HN', id: 'HN' },
	{ lang: 'countries.hongKong', code: 'HK', id: 'HK' },
	{ lang: 'countries.hungary', code: 'HU', id: 'HU' },
	{ lang: 'countries.iceland', code: 'IS', id: 'IS' },
	{ lang: 'countries.india', code: 'IN', id: 'IN' },
	{ lang: 'countries.indonesia', code: 'ID', id: 'ID' },
	{ lang: 'countries.iranIslamicRepublicOf', code: 'IR', id: 'IR' },
	{ lang: 'countries.iraq', code: 'IQ', id: 'IQ' },
	{ lang: 'countries.ireland', code: 'IE', id: 'IE' },
	{ lang: 'countries.isleOfMan', code: 'IM', id: 'IM' },
	{ lang: 'countries.israel', code: 'IL', id: 'IL' },
	{ lang: 'countries.italy', code: 'IT', id: 'IT' },
	{ lang: 'countries.jamaica', code: 'JM', id: 'JM' },
	{ lang: 'countries.japan', code: 'JP', id: 'JP', language: 'language.japanese' },
	{ lang: 'countries.jersey', code: 'JE', id: 'JE' },
	{ lang: 'countries.jordan', code: 'JO', id: 'JO' },
	{ lang: 'countries.kazakhstan', code: 'KZ', id: 'KZ' },
	{ lang: 'countries.kenya', code: 'KE', id: 'KE' },
	{ lang: 'countries.kiribati', code: 'KI', id: 'KI' },
	{ lang: 'countries.koreaDemocraticPeoplesRepublicOf', code: 'KP', id: 'KP' },
	{ lang: 'countries.koreaRepublicOf', code: 'KR', id: 'KR' },
	{ lang: 'countries.kuwait', code: 'KW', id: 'KW' },
	{ lang: 'countries.kyrgyzstan', code: 'KG', id: 'KG' },
	{ lang: 'countries.laoPeoplesDemocraticRepublic', code: 'LA', id: 'LA' },
	{ lang: 'countries.latvia', code: 'LV', id: 'LV' },
	{ lang: 'countries.lebanon', code: 'LB', id: 'LB' },
	{ lang: 'countries.lesotho', code: 'LS', id: 'LS' },
	{ lang: 'countries.liberia', code: 'LR', id: 'LR' },
	{ lang: 'countries.libya', code: 'LY', id: 'LY' },
	{ lang: 'countries.liechtenstein', code: 'LI', id: 'LI' },
	{ lang: 'countries.lithuania', code: 'LT', id: 'LT' },
	{ lang: 'countries.luxembourg', code: 'LU', id: 'LU' },
	{ lang: 'countries.macao', code: 'MO', id: 'MO' },
	{ lang: 'countries.macedoniaTheFormerYugoslavRepublicOf', code: 'MK', id: 'MK' },
	{ lang: 'countries.madagascar', code: 'MG', id: 'MG' },
	{ lang: 'countries.malawi', code: 'MW', id: 'MW' },
	{ lang: 'countries.malaysia', code: 'MY', id: 'MY' },
	{ lang: 'countries.maldives', code: 'MV', id: 'MV' },
	{ lang: 'countries.mali', code: 'ML', id: 'ML' },
	{ lang: 'countries.malta', code: 'MT', id: 'MT' },
	{ lang: 'countries.marshallIslands', code: 'MH', id: 'MH' },
	{ lang: 'countries.martinique', code: 'MQ', id: 'MQ' },
	{ lang: 'countries.mauritania', code: 'MR', id: 'MR' },
	{ lang: 'countries.mauritius', code: 'MU', id: 'MU' },
	{ lang: 'countries.mayotte', code: 'YT', id: 'YT' },
	{ lang: 'countries.mexico', code: 'MX', id: 'MX' },
	{ lang: 'countries.micronesiaFederatedStatesOf', code: 'FM', id: 'FM' },
	{ lang: 'countries.moldovaRepublicOf', code: 'MD', id: 'MD' },
	{ lang: 'countries.monaco', code: 'MC', id: 'MC' },
	{ lang: 'countries.mongolia', code: 'MN', id: 'MN' },
	{ lang: 'countries.montenegro', code: 'ME', id: 'ME' },
	{ lang: 'countries.montserrat', code: 'MS', id: 'MS' },
	{ lang: 'countries.morocco', code: 'MA', id: 'MA' },
	{ lang: 'countries.mozambique', code: 'MZ', id: 'MZ' },
	{ lang: 'countries.myanmar', code: 'MM', id: 'MM' },
	{ lang: 'countries.namibia', code: 'NA', id: 'NA' },
	{ lang: 'countries.nauru', code: 'NR', id: 'NR' },
	{ lang: 'countries.nepal', code: 'NP', id: 'NP' },
	{ lang: 'countries.netherlands', code: 'NL', id: 'NL', language: 'language.netherlands' },
	{ lang: 'countries.newCaledonia', code: 'NC', id: 'NC' },
	{ lang: 'countries.newZealand', code: 'NZ', id: 'NZ' },
	{ lang: 'countries.nicaragua', code: 'NI', id: 'NI' },
	{ lang: 'countries.niger', code: 'NE', id: 'NE' },
	{ lang: 'countries.nigeria', code: 'NG', id: 'NG' },
	{ lang: 'countries.niue', code: 'NU', id: 'NU' },
	{ lang: 'countries.norfolkIsland', code: 'NFK', id: 'NFK' },
	{ lang: 'countries.northernMarianaIslands', code: 'MP', id: 'MP' },
	{ lang: 'countries.norway', code: 'NO', id: 'NO', language: 'language.norweigan' },
	{ lang: 'countries.oman', code: 'OM', id: 'OM' },
	{ lang: 'countries.pakistan', code: 'PK', id: 'PK' },
	{ lang: 'countries.palau', code: 'PW', id: 'PW' },
	{ lang: 'countries.palestinianTerritoryOccupied', code: 'PAL', id: 'PAL' },
	{ lang: 'countries.panama', code: 'PA', id: 'PA' },
	{ lang: 'countries.papuaNewGuinea', code: 'PG', id: 'PG' },
	{ lang: 'countries.paraguay', code: 'PY', id: 'PY' },
	{ lang: 'countries.peru', code: 'PE', id: 'PE' },
	{ lang: 'countries.philippines', code: 'PH', id: 'PH' },
	{ lang: 'countries.pitcairn', code: 'PN', id: 'PN' },
	{ lang: 'countries.poland', code: 'PL', id: 'PL', language: 'language.polish' },
	{ lang: 'countries.portugal', code: 'PT', id: 'PT', language: 'language.portuguese' },
	{ lang: 'countries.puertoRico', code: 'PR', id: 'PR' },
	{ lang: 'countries.qatar', code: 'QA', id: 'QA' },
	{ lang: 'countries.reunion', code: 'RE', id: 'RE' },
	{ lang: 'countries.romania', code: 'RO', id: 'RO' },
	{ lang: 'countries.russianFederation', code: 'RU', id: 'RU' },
	{ lang: 'countries.rwanda', code: 'RW', id: 'RW' },
	{ lang: 'countries.saintBarthelemy', code: 'BL', id: 'BL' },
	{ lang: 'countries.saintHelenaAscensionAndTristanDaCunha', code: 'SH', id: 'SH' },
	{ lang: 'countries.saintKittsAndNevis', code: 'KN', id: 'KN' },
	{ lang: 'countries.saintLucia', code: 'LC', id: 'LC' },
	{ lang: 'countries.saintMartinFrenchPart', code: 'MF', id: 'MF' },
	{ lang: 'countries.saintPierreAndMiquelon', code: 'PM', id: 'PM' },
	{ lang: 'countries.saintVincentAndTheGrenadines', code: 'VC', id: 'VC' },
	{ lang: 'countries.samoa', code: 'WS', id: 'WS' },
	{ lang: 'countries.sanMarino', code: 'SM', id: 'SM' },
	{ lang: 'countries.saoTomeAndPrincipe', code: 'ST', id: 'ST' },
	{ lang: 'countries.saudiArabia', code: 'SA', id: 'SA' },
	{ lang: 'countries.senegal', code: 'SN', id: 'SN' },
	{ lang: 'countries.serbia', code: 'RS', id: 'RS' },
	{ lang: 'countries.seychelles', code: 'SC', id: 'SC' },
	{ lang: 'countries.sierraLeone', code: 'SL', id: 'SL' },
	{ lang: 'countries.singapore', code: 'SG', id: 'SG' },
	{ lang: 'countries.slovakia', code: 'SK', id: 'SK' },
	{ lang: 'countries.slovenia', code: 'SI', id: 'SI' },
	{ lang: 'countries.solomonIslands', code: 'SB', id: 'SB' },
	{ lang: 'countries.somalia', code: 'SO', id: 'SO' },
	{ lang: 'countries.southAfrica', code: 'ZA', id: 'ZA' },
	{ lang: 'countries.southGeorgiaAndTheSouthSandwichIslands', code: 'GS', id: 'GS' },
	{ lang: 'countries.spain', code: 'ES', id: 'ES', language: 'language.spanish' },
	{ lang: 'countries.sriLanka', code: 'LK', id: 'LK' },
	{ lang: 'countries.sudan', code: 'SD', id: 'SD' },
	{ lang: 'countries.suriname', code: 'SR', id: 'SR' },
	{ lang: 'countries.svalbardAndJanMayen', code: 'SJ', id: 'SJ' },
	{ lang: 'countries.swaziland', code: 'SZ', id: 'SZ' },
	{ lang: 'countries.sweden', code: 'SE', id: 'SE', language: 'language.swedish' },
	{ lang: 'countries.switzerland', code: 'CH', id: 'CH' },
	{ lang: 'countries.syrianArabRepublic', code: 'SY', id: 'SY' },
	{ lang: 'countries.taiwanProvinceOfChina', code: 'TW', id: 'TW' },
	{ lang: 'countries.tajikistan', code: 'TJ', id: 'TJ' },
	{ lang: 'countries.tanzaniaUnitedRepublicOf', code: 'TZ', id: 'TZ' },
	{ lang: 'countries.thailand', code: 'TH', id: 'TH' },
	{ lang: 'countries.timorLeste', code: 'TL', id: 'TL' },
	{ lang: 'countries.togo', code: 'TG', id: 'TG' },
	{ lang: 'countries.tokelau', code: 'TK', id: 'TK' },
	{ lang: 'countries.tonga', code: 'TO', id: 'TO' },
	{ lang: 'countries.trinidadAndTobago', code: 'TT', id: 'TT' },
	{ lang: 'countries.tunisia', code: 'TN', id: 'TN' },
	{ lang: 'countries.turkey', code: 'TR', id: 'TR' },
	{ lang: 'countries.turkmenistan', code: 'TM', id: 'TM' },
	{ lang: 'countries.turksAndCaicosIslands', code: 'TC', id: 'TC' },
	{ lang: 'countries.tuvalu', code: 'TV', id: 'TV' },
	{ lang: 'countries.uganda', code: 'UG', id: 'UG' },
	{ lang: 'countries.ukraine', code: 'UA', id: 'UA' },
	{ lang: 'countries.unitedArabEmirates', code: 'AE', id: 'AE' },
	{ lang: 'countries.unitedKingdom', code: 'GB', id: 'GB', language: 'language.english' },
	{ lang: 'countries.unitedStates', code: 'US', id: 'US' },
	{ lang: 'countries.unitedStatesMinorOutlyingIslands', code: 'UM', id: 'UM' },
	{ lang: 'countries.uruguay', code: 'UY', id: 'UY' },
	{ lang: 'countries.uzbekistan', code: 'UZ', id: 'UZ' },
	{ lang: 'countries.vanuatu', code: 'VU', id: 'VU' },
	{ lang: 'countries.venezuelaBolivarianRepublicOf', code: 'VE', id: 'VE' },
	{ lang: 'countries.vietNam', code: 'VN', id: 'VN' },
	{ lang: 'countries.virginIslandsBritish', code: 'VG', id: 'VG' },
	{ lang: 'countries.virginIslandsUs', code: 'VI', id: 'VI' },
	{ lang: 'countries.wallisAndFutuna', code: 'WF', id: 'WF' },
	{ lang: 'countries.westernSahara', code: 'EH', id: 'EH' },
	{ lang: 'countries.yemen', code: 'YE', id: 'YE' },
	{ lang: 'countries.zambia', code: 'ZM', id: 'ZM' },
	{ lang: 'countries.zimbabwe', code: 'ZW', id: 'ZW' }
];

export const locales = [
	{ locale: 'en-AU', country: 'AU', code: 'AU', language: 'en', lang: 'countries.australia' },
	{ locale: 'de-AT', country: 'AT', code: 'AT', language: 'de', lang: 'countries.austria' },
	{ locale: 'pt-BR', country: 'BR', code: 'BR', language: 'pt', lang: 'countries.brazil' },
	{ locale: 'en-CA', country: 'CA', code: 'CA', language: 'en', lang: 'countries.canada' },
	{ locale: 'zh-CN', country: 'CN', code: 'CN', language: 'zh', lang: 'countries.china' },
	{ locale: 'da-DK', country: 'DK', code: 'DK', language: 'da', lang: 'countries.denmark' },
	{ locale: 'et-EE', country: 'EE', code: 'EE', language: 'et', lang: 'countries.estonia' },
	{ locale: 'fi-FI', country: 'FI', code: 'FI', language: 'fi', lang: 'countries.finland' },
	{ locale: 'fr-FR', country: 'FR', code: 'FR', language: 'fr', lang: 'countries.france' },
	{ locale: 'de-DE', country: 'DE', code: 'DE', language: 'de', lang: 'countries.germany' },
	{ locale: 'el-GR', country: 'GR', code: 'GR', language: 'el', lang: 'countries.greece' },
	{ locale: 'zh-HK', country: 'HK', code: 'HK', language: 'zh', lang: 'countries.hongKong' },
	{ locale: 'hu-HU', country: 'HU', code: 'HU', language: 'hu', lang: 'countries.hungary' },
	{ locale: 'is-IS', country: 'IS', code: 'IS', language: 'is', lang: 'countries.iceland' },
	{ locale: 'hi-IN', country: 'IN', code: 'IN', language: 'hi', lang: 'countries.india' },
	{ locale: 'en-IE', country: 'IE', code: 'IE', language: 'en', lang: 'countries.ireland' },
	{ locale: 'he-IL', country: 'IL', code: 'IL', language: 'he', lang: 'countries.israel' },
	{ locale: 'it-IT', country: 'IT', code: 'IT', language: 'it', lang: 'countries.italy' },
	{ locale: 'ja-JP', country: 'JP', code: 'JP', language: 'ja', lang: 'countries.japan' },
	{ locale: 'ko-KR', country: 'KR', code: 'KR', language: 'ko', lang: 'countries.koreaRepublicOf' },
	{ locale: 'lv-LV', country: 'LV', code: 'LV', language: 'lv', lang: 'countries.latvia' },
	{ locale: 'lt-LT', country: 'LT', code: 'LT', language: 'lt', lang: 'countries.lithuania' },
	{ locale: 'es-MX', country: 'MX', code: 'MX', language: 'es', lang: 'countries.mexico' },
	{ locale: 'nl-NL', country: 'NL', code: 'NL', language: 'nl', lang: 'countries.netherlands' },
	{ locale: 'en-NZ', country: 'NZ', code: 'NZ', language: 'en', lang: 'countries.newZealand' },
	{ locale: 'nb-NO', country: 'NO', code: 'NO', language: 'nb', lang: 'countries.norway' },
	{
		locale: 'ru-RU',
		country: 'RU',
		code: 'RU',
		language: 'ru',
		lang: 'countries.russianFederation'
	},
	{ locale: 'af-ZA', country: 'ZA', code: 'ZA', language: 'af', lang: 'countries.southAfrica' },
	{ locale: 'es-ES', country: 'ES', code: 'ES', language: 'es', lang: 'countries.spain' },
	{ locale: 'sv-SE', country: 'SE', code: 'SE', language: 'sv', lang: 'countries.sweden' },
	{ locale: 'de-CH', country: 'CH', code: 'CH', language: 'de', lang: 'countries.switzerland' },
	{ locale: 'en-GB', country: 'GB', code: 'GB', language: 'en', lang: 'countries.unitedKingdom' },
	{ locale: 'en-US', country: 'US', code: 'US', language: 'en', lang: 'countries.unitedStates' }
];

export const languages = [
	{ country: 'dk', language: 'da', id: 'da', name: 'lang.da', iso: 'da-DK' },
	{ country: 'de', language: 'de', id: 'de', name: 'lang.de', iso: 'de-DE' },
	{ country: 'gb', language: 'en', id: 'en', name: 'lang.en', iso: 'en-GB' },
	{ country: 'fi', language: 'fi', id: 'fi', name: 'lang.fi', iso: 'fi-FI' },
	{ country: 'fr', language: 'fr', id: 'fr', name: 'lang.fr', iso: 'fr-FR' },
	{ country: 'nl', language: 'nl', id: 'nl', name: 'lang.nl', iso: 'nl-NL' },
	{ country: 'no', language: 'nb', id: 'nb', name: 'lang.no', iso: 'nb-NO' },
	{ country: 'se', language: 'sv', id: 'sv', name: 'lang.sv', iso: 'sv-SE' }
];

export const zones = [
	'Africa/Abidjan',
	'Africa/Accra',
	'Africa/Addis_Ababa',
	'Africa/Algiers',
	'Africa/Asmara',
	'Africa/Bamako',
	'Africa/Bangui',
	'Africa/Banjul',
	'Africa/Bissau',
	'Africa/Blantyre',
	'Africa/Brazzaville',
	'Africa/Bujumbura',
	'Africa/Cairo',
	'Africa/Casablanca',
	'Africa/Ceuta',
	'Africa/Conakry',
	'Africa/Dakar',
	'Africa/Dar_es_Salaam',
	'Africa/Djibouti',
	'Africa/Douala',
	'Africa/El_Aaiun',
	'Africa/Freetown',
	'Africa/Gaborone',
	'Africa/Harare',
	'Africa/Johannesburg',
	'Africa/Juba',
	'Africa/Kampala',
	'Africa/Khartoum',
	'Africa/Kigali',
	'Africa/Kinshasa',
	'Africa/Lagos',
	'Africa/Libreville',
	'Africa/Lome',
	'Africa/Luanda',
	'Africa/Lubumbashi',
	'Africa/Lusaka',
	'Africa/Malabo',
	'Africa/Maputo',
	'Africa/Maseru',
	'Africa/Mbabane',
	'Africa/Mogadishu',
	'Africa/Monrovia',
	'Africa/Nairobi',
	'Africa/Ndjamena',
	'Africa/Niamey',
	'Africa/Nouakchott',
	'Africa/Ouagadougou',
	'Africa/Porto-Novo',
	'Africa/Sao_Tome',
	'Africa/Tripoli',
	'Africa/Tunis',
	'Africa/Windhoek',
	'America/Adak',
	'America/Anchorage',
	'America/Anguilla',
	'America/Antigua',
	'America/Araguaina',
	'America/Argentina/Buenos_Aires',
	'America/Argentina/Catamarca',
	'America/Argentina/Cordoba',
	'America/Argentina/Jujuy',
	'America/Argentina/La_Rioja',
	'America/Argentina/Mendoza',
	'America/Argentina/Rio_Gallegos',
	'America/Argentina/Salta',
	'America/Argentina/San_Juan',
	'America/Argentina/San_Luis',
	'America/Argentina/Tucuman',
	'America/Argentina/Ushuaia',
	'America/Aruba',
	'America/Asuncion',
	'America/Atikokan',
	'America/Bahia',
	'America/Bahia_Banderas',
	'America/Barbados',
	'America/Belem',
	'America/Belize',
	'America/Blanc-Sablon',
	'America/Boa_Vista',
	'America/Bogota',
	'America/Boise',
	'America/Cambridge_Bay',
	'America/Campo_Grande',
	'America/Cancun',
	'America/Caracas',
	'America/Cayenne',
	'America/Cayman',
	'America/Chicago',
	'America/Chihuahua',
	'America/Costa_Rica',
	'America/Creston',
	'America/Cuiaba',
	'America/Curacao',
	'America/Danmarkshavn',
	'America/Dawson',
	'America/Dawson_Creek',
	'America/Denver',
	'America/Detroit',
	'America/Dominica',
	'America/Edmonton',
	'America/Eirunepe',
	'America/El_Salvador',
	'America/Fortaleza',
	'America/Glace_Bay',
	'America/Godthab',
	'America/Goose_Bay',
	'America/Grand_Turk',
	'America/Grenada',
	'America/Guadeloupe',
	'America/Guatemala',
	'America/Guayaquil',
	'America/Guyana',
	'America/Halifax',
	'America/Havana',
	'America/Hermosillo',
	'America/Indiana/Indianapolis',
	'America/Indiana/Knox',
	'America/Indiana/Marengo',
	'America/Indiana/Petersburg',
	'America/Indiana/Tell_City',
	'America/Indiana/Vevay',
	'America/Indiana/Vincennes',
	'America/Indiana/Winamac',
	'America/Inuvik',
	'America/Iqaluit',
	'America/Jamaica',
	'America/Juneau',
	'America/Kentucky/Louisville',
	'America/Kentucky/Monticello',
	'America/La_Paz',
	'America/Lima',
	'America/Los_Angeles',
	'America/Maceio',
	'America/Managua',
	'America/Manaus',
	'America/Martinique',
	'America/Matamoros',
	'America/Mazatlan',
	'America/Menominee',
	'America/Merida',
	'America/Metlakatla',
	'America/Mexico_City',
	'America/Miquelon',
	'America/Moncton',
	'America/Monterrey',
	'America/Montevideo',
	'America/Montreal',
	'America/Montserrat',
	'America/Nassau',
	'America/New_York',
	'America/Nipigon',
	'America/Nome',
	'America/Noronha',
	'America/North_Dakota/Beulah',
	'America/North_Dakota/Center',
	'America/North_Dakota/New_Salem',
	'America/Ojinaga',
	'America/Panama',
	'America/Pangnirtung',
	'America/Paramaribo',
	'America/Phoenix',
	'America/Port-au-Prince',
	'America/Port_of_Spain',
	'America/Porto_Velho',
	'America/Puerto_Rico',
	'America/Rainy_River',
	'America/Rankin_Inlet',
	'America/Recife',
	'America/Regina',
	'America/Resolute',
	'America/Rio_Branco',
	'America/Santa_Isabel',
	'America/Santarem',
	'America/Santiago',
	'America/Santo_Domingo',
	'America/Sao_Paulo',
	'America/Scoresbysund',
	'America/Sitka',
	'America/St_Johns',
	'America/St_Kitts',
	'America/St_Lucia',
	'America/St_Thomas',
	'America/St_Vincent',
	'America/Swift_Current',
	'America/Tegucigalpa',
	'America/Thule',
	'America/Thunder_Bay',
	'America/Tijuana',
	'America/Toronto',
	'America/Tortola',
	'America/Vancouver',
	'America/Whitehorse',
	'America/Winnipeg',
	'America/Yakutat',
	'America/Yellowknife',
	'Antarctica/Casey',
	'Antarctica/Davis',
	'Antarctica/DumontDUrville',
	'Antarctica/Macquarie',
	'Antarctica/Mawson',
	'Antarctica/McMurdo',
	'Antarctica/Palmer',
	'Antarctica/Rothera',
	'Antarctica/Syowa',
	'Antarctica/Vostok',
	'Europe/Oslo',
	'Asia/Aden',
	'Asia/Almaty',
	'Asia/Amman',
	'Asia/Anadyr',
	'Asia/Aqtau',
	'Asia/Aqtobe',
	'Asia/Ashgabat',
	'Asia/Baghdad',
	'Asia/Bahrain',
	'Asia/Baku',
	'Asia/Bangkok',
	'Asia/Beirut',
	'Asia/Bishkek',
	'Asia/Brunei',
	'Asia/Choibalsan',
	'Asia/Chongqing',
	'Asia/Colombo',
	'Asia/Damascus',
	'Asia/Dhaka',
	'Asia/Dili',
	'Asia/Dubai',
	'Asia/Dushanbe',
	'Asia/Gaza',
	'Asia/Harbin',
	'Asia/Hebron',
	'Asia/Ho_Chi_Minh',
	'Asia/Hong_Kong',
	'Asia/Hovd',
	'Asia/Irkutsk',
	'Asia/Jakarta',
	'Asia/Jayapura',
	'Asia/Jerusalem',
	'Asia/Kabul',
	'Asia/Kamchatka',
	'Asia/Karachi',
	'Asia/Kashgar',
	'Asia/Kathmandu',
	'Asia/Khandyga',
	'Asia/Kolkata',
	'Asia/Krasnoyarsk',
	'Asia/Kuala_Lumpur',
	'Asia/Kuching',
	'Asia/Kuwait',
	'Asia/Macau',
	'Asia/Magadan',
	'Asia/Makassar',
	'Asia/Manila',
	'Asia/Muscat',
	'Asia/Nicosia',
	'Asia/Novokuznetsk',
	'Asia/Novosibirsk',
	'Asia/Omsk',
	'Asia/Oral',
	'Asia/Phnom_Penh',
	'Asia/Pontianak',
	'Asia/Pyongyang',
	'Asia/Qatar',
	'Asia/Qyzylorda',
	'Asia/Rangoon',
	'Asia/Riyadh',
	'Asia/Sakhalin',
	'Asia/Samarkand',
	'Asia/Seoul',
	'Asia/Shanghai',
	'Asia/Singapore',
	'Asia/Taipei',
	'Asia/Tashkent',
	'Asia/Tbilisi',
	'Asia/Tehran',
	'Asia/Thimphu',
	'Asia/Tokyo',
	'Asia/Ulaanbaatar',
	'Asia/Urumqi',
	'Asia/Ust-Nera',
	'Asia/Vientiane',
	'Asia/Vladivostok',
	'Asia/Yakutsk',
	'Asia/Yekaterinburg',
	'Asia/Yerevan',
	'Atlantic/Azores',
	'Atlantic/Bermuda',
	'Atlantic/Canary',
	'Atlantic/Cape_Verde',
	'Atlantic/Faroe',
	'Atlantic/Madeira',
	'Atlantic/Reykjavik',
	'Atlantic/South_Georgia',
	'Atlantic/St_Helena',
	'Atlantic/Stanley',
	'Australia/Adelaide',
	'Australia/Brisbane',
	'Australia/Broken_Hill',
	'Australia/Currie',
	'Australia/Darwin',
	'Australia/Eucla',
	'Australia/Hobart',
	'Australia/Lindeman',
	'Australia/Lord_Howe',
	'Australia/Melbourne',
	'Australia/Perth',
	'Australia/Sydney',
	'CET',
	'CST6CDT',
	'EET',
	'EST',
	'EST5EDT',
	'HST',
	'MET',
	'MST',
	'MST7MDT',
	'PST8PDT',
	'WET',
	'Etc/GMT',
	'Etc/GMT+1',
	'Etc/GMT+10',
	'Etc/GMT+11',
	'Etc/GMT+12',
	'Etc/GMT+2',
	'Etc/GMT+3',
	'Etc/GMT+4',
	'Etc/GMT+5',
	'Etc/GMT+6',
	'Etc/GMT+7',
	'Etc/GMT+8',
	'Etc/GMT+9',
	'Etc/GMT-1',
	'Etc/GMT-10',
	'Etc/GMT-11',
	'Etc/GMT-12',
	'Etc/GMT-13',
	'Etc/GMT-14',
	'Etc/GMT-2',
	'Etc/GMT-3',
	'Etc/GMT-4',
	'Etc/GMT-5',
	'Etc/GMT-6',
	'Etc/GMT-7',
	'Etc/GMT-8',
	'Etc/GMT-9',
	'Etc/UCT',
	'Etc/UTC',
	'Europe/Amsterdam',
	'Europe/Andorra',
	'Europe/Athens',
	'Europe/Belgrade',
	'Europe/Berlin',
	'Europe/Prague',
	'Europe/Brussels',
	'Europe/Bucharest',
	'Europe/Budapest',
	'Europe/Zurich',
	'Europe/Chisinau',
	'Europe/Copenhagen',
	'Europe/Dublin',
	'Europe/Gibraltar',
	'Europe/London',
	'Europe/Helsinki',
	'Europe/Istanbul',
	'Europe/Kaliningrad',
	'Europe/Kiev',
	'Europe/Lisbon',
	'Europe/Luxembourg',
	'Europe/Madrid',
	'Europe/Malta',
	'Europe/Minsk',
	'Europe/Monaco',
	'Europe/Moscow',
	'Europe/Paris',
	'Europe/Riga',
	'Europe/Rome',
	'Europe/Samara',
	'Europe/Simferopol',
	'Europe/Sofia',
	'Europe/Stockholm',
	'Europe/Tallinn',
	'Europe/Tirane',
	'Europe/Uzhgorod',
	'Europe/Vaduz',
	'Europe/Vienna',
	'Europe/Vilnius',
	'Europe/Volgograd',
	'Europe/Warsaw',
	'Europe/Zaporozhye',
	'Indian/Antananarivo',
	'Indian/Chagos',
	'Indian/Christmas',
	'Indian/Cocos',
	'Indian/Comoro',
	'Indian/Kerguelen',
	'Indian/Mahe',
	'Indian/Maldives',
	'Indian/Mauritius',
	'Indian/Mayotte',
	'Indian/Reunion',
	'Pacific/Apia',
	'Pacific/Auckland',
	'Pacific/Chatham',
	'Pacific/Chuuk',
	'Pacific/Easter',
	'Pacific/Efate',
	'Pacific/Enderbury',
	'Pacific/Fakaofo',
	'Pacific/Fiji',
	'Pacific/Funafuti',
	'Pacific/Galapagos',
	'Pacific/Gambier',
	'Pacific/Guadalcanal',
	'Pacific/Guam',
	'Pacific/Honolulu',
	'Pacific/Johnston',
	'Pacific/Kiritimati',
	'Pacific/Kosrae',
	'Pacific/Kwajalein',
	'Pacific/Majuro',
	'Pacific/Marquesas',
	'Pacific/Midway',
	'Pacific/Nauru',
	'Pacific/Niue',
	'Pacific/Norfolk',
	'Pacific/Noumea',
	'Pacific/Pago_Pago',
	'Pacific/Palau',
	'Pacific/Pitcairn',
	'Pacific/Pohnpei',
	'Pacific/Port_Moresby',
	'Pacific/Rarotonga',
	'Pacific/Saipan',
	'Pacific/Tahiti',
	'Pacific/Tarawa',
	'Pacific/Tongatapu',
	'Pacific/Wake',
	'Pacific/Wallis'
];
