import { formatSubscriptionPeriod } from './Context/SubscriptionPeriodContextHelpers';
import { useSubscriptionGroupContext } from './Context/SubscriptionGroupContext';
import { ChildAgreement, SubscriptionGroupState, SubscriptionPeriodState } from './Context/SubscriptionGroupState';
import PopupPortalAnchors from 'App/helpers/PopupPortalAnchors';
import { DebouncePromise } from 'Components/Helpers/Debounce';
import { useTranslation } from 'Components/Helpers/translate';
import { OrderRow } from '../OrderRows/Context/OrderContext';
import React, { useRef, useEffect, useState } from 'react';
import SubscriptionDifforder from './SubscriptionDifforder';
import bemClass from '@upsales/components/Utils/bemClass';
import { Loader, Title, Text } from '@upsales/components';
import { NewSubscriptionTracker } from 'Helpers/Tracker';
import SubscriptionSummary from './SubscriptionSummary';
import SubscriptionDetails from './SubscriptionDetails';
import SubscriptionHistory from './SubscriptionHistory';
import { useSoftDeployAccess } from '../hooks';
import { STEP, FINAL_STEP } from './Step';
import logError from 'Helpers/logError';
import EditSummary from './EditSummary';
import BetaBanner from './BetaBanner';
import Header from './Header';

import './OpenSubscription.scss';

export type Props = {
	className: string;
	close: (data?: object) => void;
	dontWait?: boolean;
	createdFrom?: string;
	onSave?: () => void;
};

const getDiffOrders = DebouncePromise((periods: SubscriptionPeriodState[]) => {
	const agreements = periods.map(p => formatSubscriptionPeriod(p));
	return Tools.Agreement.customer(Tools.AppService.getCustomerId()).diffOrders(agreements);
}, 300);

const getOnlyCrucialData = (subscriptionMap: SubscriptionGroupState['subscriptionMap']) => {
	const cleanMap: {
		[key: string]: Partial<Omit<SubscriptionPeriodState, 'orderRows'>> & {
			orderRows: Partial<OrderRow>[];
			children?: Partial<Omit<ChildAgreement, 'orderRow'>> &
				{
					orderRow: Partial<OrderRow>[];
				}[];
		};
	} = {};

	for (const [key, period] of Object.entries(subscriptionMap)) {
		cleanMap[key] = {
			periodLength: period.periodLength,
			orderInterval: period.orderInterval,
			offset: period.offset,
			noticePeriod: period.noticePeriod,
			startDate: period.startDate,
			invoiceStartDate: period.invoiceStartDate,
			renewalDate: period.renewalDate,
			endDate: period.endDate,
			children: period.children?.map(child => ({
				...child,
				orderRow: child.orderRow.map(row => ({
					uuid: row.id ?? 0,
					listPrice: row.listPrice,
					price: row.price,
					quantity: row.quantity
				}))
			})),
			currency: period.currency,
			currencyRate: period.currencyRate,
			orderRows: period.orderRows.map(row => ({
				listPrice: row.listPrice,
				price: row.price,
				quantity: row.quantity
			})),
			creditInitialDate: period.creditInitialDate
		};
	}

	return JSON.stringify(cleanMap);
};

const OpenSubscription = ({ className, close, createdFrom, onSave = () => {} }: Props) => {
	const createSubscriptionModalRef = useRef(null);
	const hasPromoFlag = useSoftDeployAccess('SUBSCRIPTION_PROMO');
	const hasSubscriptionModal = useSoftDeployAccess('SUBSCRIPTION_MODAL');
	const hasRemoveBetaBanner = useSoftDeployAccess('REMOVE_BETA_BANNER_SUBSCRIPTION');
	const labMode = hasPromoFlag && !hasSubscriptionModal;
	const [showSplash, setShowSplash] = useState(labMode);
	const classes = new bemClass('OpenSubscription', className);
	classes.add('FullScreenModal');

	const { t } = useTranslation();

	const { state, setDiffOrders } = useSubscriptionGroupContext();

	const [hasDiffOrdersToCreate, setHasDiffOrdersToCreate] = useState(!!state.orderToAdd);

	useEffect(() => {
		if (state.isEdit) {
			return;
		}

		const { CREATE_SUBSCRIPTION } = NewSubscriptionTracker.events;
		createdFrom = createdFrom ?? 'unknown';
		NewSubscriptionTracker.track(CREATE_SUBSCRIPTION, { createdFrom });
	}, []);

	useEffect(() => {
		if (!state.isEdit || state.orderToAdd) {
			return;
		}

		const periods = Object.values(state.subscriptionMap);
		const cancellablePromise = getDiffOrders(periods);
		cancellablePromise.promise
			.then(({ data }) => {
				setHasDiffOrdersToCreate(data?.length);
				if (data?.length) {
					setDiffOrders(data);
				}
			})
			.catch(e => {
				logError(e, 'Could not fetch diffOrders');
			});

		return () => cancellablePromise?.cancel();
	}, [getOnlyCrucialData(state.subscriptionMap), state.isEdit]);

	const currentAgreementId = state.subscriptionMap?.[state.currentUUID]?.id;

	const getTabContent = () => {
		if (state.isEdit && state.fetching) {
			return (
				<div className={classes.elem('loader').b()}>
					<Loader size="xl" />
				</div>
			);
		}

		if (state.finalStep === FINAL_STEP.DIFFORDER) {
			return <SubscriptionDifforder />;
		} else if (state.finalStep === FINAL_STEP.EDIT_SUMMARY) {
			return <EditSummary />;
		}

		switch (state.step) {
			case STEP.SUMMARY:
				return <SubscriptionSummary close={close} />;
			case STEP.DETAILS:
				return <SubscriptionDetails labMode={labMode} />;
			case STEP.HISTORY:
				return <SubscriptionHistory close={close} />;
			default:
				return <div>{state.step}</div>;
		}
	};

	return (
		<div
			className={classes
				.mod({
					hideSteps: !!state.finalStep,
					withoutSubscriptionSplits: !Tools.FeatureHelper.hasSoftDeployAccess('SUBSCRIPTION_SPLITS')
				})
				.b()}
		>
			<div className={classes.elem('inner').b()} ref={createSubscriptionModalRef}>
				<Header
					close={close}
					onSave={onSave}
					labMode={labMode}
					className={classes.elem('navbar-title').b()}
					hasDiffOrdersToCreate={hasDiffOrdersToCreate}
				/>
				{labMode || hasRemoveBetaBanner ? null : (
					<BetaBanner close={close} agreementId={currentAgreementId} orderId={state.createdFromOrderId} />
				)}
				<PopupPortalAnchors
					anchor={createSubscriptionModalRef.current}
					scrollContainerSelector={'.OpenSubscription__content'}
				>
					<div className={classes.elem('content').mod({ labMode, hasRemoveBetaBanner }).b()}>
						{getTabContent()}
					</div>
				</PopupPortalAnchors>
			</div>
			{showSplash ? (
				<div className={classes.elem('promo-splash').b()} onClick={() => setShowSplash(false)}>
					<Title bold size="xl" color="white">
						{t('subscription.promo.splash.title')}
					</Title>
					<Text bold size="lg" color="white">
						{t('subscription.promo.splash.subtitle')}
					</Text>
				</div>
			) : null}
		</div>
	);
};

export default OpenSubscription;
