import './TimelineSubSection.scss';
import React from 'react';
import BemClass from '@upsales/components/Utils/bemClass';
import { Icon, Row, Column, IconName } from '@upsales/components';

type Props = {
	iconProps?: { icon: IconName; color?: string };
	hasLink?: boolean;
	children?: JSX.Element | JSX.Element[];
};

const TimelineSubSection = ({ iconProps, hasLink, children }: Props) => {
	const classes = new BemClass('TimelineSubSection');

	return (
		<Row className={classes.b()} align="center">
			{iconProps?.icon ? (
				<Column className={classes.elem('iconColumn').b()} fixedWidth={30}>
					<Icon name={iconProps.icon} color={iconProps.color || 'grey-9'} space="mrm" />
				</Column>
			) : null}
			<Column className={classes.elem('contentColumn').mod({ hasLink }).b()}>{children}</Column>
		</Row>
	);
};

export default TimelineSubSection;
