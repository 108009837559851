import React from 'react';
import PropTypes from 'prop-types';
import { Block, Title, Label, Column, Row } from '@upsales/components';
import CustomFieldShape from 'App/babel/propTypes/CustomFieldShape';
import EditProductInputWrap from './EditProductInputWrap';
import _ from 'lodash';
import './EditProductCustom.scss';

const EditProductCustom = ({ title, customFields, values, onChange, disabled }) => {
	const customFieldRows = _.map(customFields, (customField, i) => {
		const matchingCustomField = _.find(values, _customField => {
			return parseInt(_customField.fieldId) === parseInt(customField.id);
		});

		const value = matchingCustomField ? matchingCustomField.value : null;
		const props = { ...customField, value: value };

		return (
			<Column size={6} key={'product-custom-field-' + i}>
				<EditProductInputWrap>
					<Label required={!!customField.obligatoryField}>{customField.name}</Label>
					<ReactTemplates.customFieldInput
						disabled={disabled}
						field={props}
						entity={'product'}
						valueChange={e => onChange(customField.id, e)}
						useNewTime
						useNumberInput
					/>
				</EditProductInputWrap>
			</Column>
		);
	});

	if (customFieldRows.length) {
		return (
			<Block className="EditProductCustom" space="ptxl prxl pbxl plxl" backgroundColor="grey-4">
				<Title size="md">{title}</Title>
				<Row>{customFieldRows}</Row>
			</Block>
		);
	}

	return null;
};

EditProductCustom.defaultProps = {
	title: '',
	customFields: [],
	values: [],
	disabled: false,
	onChange: () => {}
};

EditProductCustom.propTypes = {
	title: PropTypes.string,
	customFields: PropTypes.arrayOf(CustomFieldShape),
	values: PropTypes.arrayOf(
		PropTypes.shape({
			fieldId: PropTypes.number
		})
	),
	disabled: PropTypes.bool,
	onChange: PropTypes.func
};

export default EditProductCustom;
