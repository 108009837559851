import React, { ReactElement } from 'react';
import { Text, Link, IconName } from '@upsales/components';
import TimelineRow from 'Components/TimelineRow';
import { SubTitle } from '../Helpers';
import { getOutcomeStatus } from 'Components/Modals/Appointment/AppointmentOutcomes/Helpers';
import { EVENT_TYPES } from 'App/babel/enum/appointmentEvents';
import { openDrawer } from 'Services/Drawer';
import { AppointmentEvent } from 'App/resources/Model/Event';
import ActivityTimelinePostpone from 'App/components/HistoryLog/ActivityHistoryLog/ActivityTimelinePostpone';
import { APPOINTMENT_TIMELINE } from 'App/babel/enum/timeline';

function openOpportunity(id: number) {
	Tools.$upModal.open('editOrder', {
		customerId: Tools.AppService.getCustomerId(),
		id
	});
}

interface UpdatedEventProps {
	event: AppointmentEvent;
	hideIcon?: boolean;
}

const UpdatedEvent: React.FC<UpdatedEventProps> = ({ event, hideIcon }) => {
	const {
		data: { outcome, opportunity, outcomeAction, rescheduled, comment },
		date,
		user
	} = event;
	const { $translate: t } = Tools;

	let title: ReactElement,
		icon: string = '',
		iconColor: string = '';
	if (rescheduled) {
		return ActivityTimelinePostpone({
			event: {
				...event,
				subType: APPOINTMENT_TIMELINE.RESCHEDULED,
				user: rescheduled.byUser,
				data: { date: rescheduled.date, oldDate: rescheduled.oldDate, outcome, comment }
			},
			hideIcon
		});
	} else if (outcome && outcomeAction && outcomeAction !== EVENT_TYPES.COMMENTCREATED) {
		const { outcomeCompleted } = getOutcomeStatus(outcome);
		icon = outcomeCompleted ? 'check' : 'times';
		let entityLink;
		let entity;
		switch (outcomeAction) {
			case EVENT_TYPES.CALLCREATED: {
				if (event.data.call) {
					const call = JSON.parse(event.data.call);
					entity = t('default.phonecall').toLowerCase();
					entityLink = (
						<Link onClick={() => openDrawer('EditPhonecall', { activity: call })}>{call.description}</Link>
					);
				}
				break;
			}
			case EVENT_TYPES.ACTIVITYCREATED: {
				if (event.data.activity) {
					const activityEvent = JSON.parse(event.data.activity);
					entity = t('default.activity').toLowerCase();
					entityLink = (
						<Link
							onClick={() => {
								Tools.$upModal.open('editActivity', { id: activityEvent.id });
							}}
						>
							{activityEvent.description}
						</Link>
					);
				}
				break;
			}
			case EVENT_TYPES.APPOINTMENTCREATED: {
				if (event.data.appointment) {
					const appointmentEvent = JSON.parse(event.data.appointment);
					entity = t('default.appointment').toLowerCase();
					entityLink = (
						<Link
							onClick={() => {
								Tools.$upModal.open('editAppointment', { id: appointmentEvent.id });
							}}
						>
							{appointmentEvent.description}
						</Link>
					);
				}
				break;
			}
			case EVENT_TYPES.OPPORTUNITYCREATED: {
				if (event.data.opportunity) {
					const opportunity = JSON.parse(event.data.opportunity);
					entity = t('default.opportunity').toLowerCase();
					entityLink = <Link onClick={() => openOpportunity(opportunity.id)}>{opportunity.description}</Link>;
				}
				break;
			}
			case EVENT_TYPES.ORDERCREATED: {
				if (event.data.opportunity) {
					const order = JSON.parse(event.data.opportunity);
					entity = t('default.order').toLowerCase();
					entityLink = <Link onClick={() => openOpportunity(order.id)}>{order.description}</Link>;
				}
				break;
			}
		}
		title = (
			<Text key="title">
				{`${(user?.name ?? '').split(' ')[0]} ${t(
					outcomeCompleted ? 'default.completed' : 'appointment.notCompleted'
				).toLowerCase()} ${t('default.appointment').toLowerCase()}: ${t(
					'activity.outcomeLog.created'
				).toLowerCase()} ${entity} `}
				{entityLink}
			</Text>
		);
	} else if (outcome) {
		const { outcomeCompleted } = getOutcomeStatus(outcome);
		icon = outcomeCompleted ? 'check' : 'calendar-times-o';
		if (!outcomeCompleted) {
			iconColor = 'red';
		}
		title = (
			<Text key="title">
				{t('appointment.outcomeLog.outcomeMarkedAs', {
					outcome: t(`appointment.outcome.${outcome}`).toLowerCase()
				})}
				{user ? t('appointment.outcomeLog.byUser', { user: user.name }) : ''}
			</Text>
		);
	} else if (opportunity) {
		const isOpportunity = outcomeAction === EVENT_TYPES.OPPORTUNITYCREATED;
		icon = isOpportunity ? 'opportunity' : 'dollar';

		const parsedOpportunity = JSON.parse(opportunity);
		title = (
			<Text key="title">
				{user?.name ?? ''} {t('activity.outcomeLog.created').toLowerCase()}
				{` ${t(isOpportunity ? 'default.opportunity' : 'default.order').toLowerCase()} `}
				<Link onClick={() => openOpportunity(parsedOpportunity.id)}>{parsedOpportunity.description}</Link>
				{` ${t('appointment.outcomeLog.fromThisAppointment')}`}
			</Text>
		);
	} else {
		return null;
	}

	const subTitle = <SubTitle date={date} />;

	const noCommentRow = !Tools.FeatureHelper.hasSoftDeployAccess('TODO_LIST');

	return (
		<TimelineRow
			event={event}
			icon={(hideIcon ? '' : icon) as IconName}
			iconColor={iconColor}
			title={title}
			subTitle={subTitle}
			noCommentRow={noCommentRow}
		/>
	);
};

export default UpdatedEvent;
