import './PriorHistoryLog.scss';

import React from 'react';
import BemClass from '@upsales/components/Utils/bemClass';
import T from 'Components/Helpers/translate';
import HistoryLog from '../HistoryLog';
import { getTimelineRow } from 'Components/Helpers/getTimelineRow';
import Event from 'App/resources/Model/Event';
import EventResource from 'App/resources/Event';
import useSelector from 'App/components/hooks/useSelector';
import usePartialLoader, { FetcherFunction } from 'App/components/hooks/usePartialLoader';

const logTypes = ['appointment', 'opportunity', 'phonecall'] as const;
type PriorHistoryLogType = (typeof logTypes)[number];

type PriorHistoryLogProps = {
	entity: { id: number };
	type: PriorHistoryLogType | '';
	selectButtons?: React.ReactElement;
};

type FetcherProps = Pick<PriorHistoryLogProps, 'entity' | 'type'>;
const fetcher: FetcherFunction<FetcherProps, Event> = (_filters, { entity, type }) => {
	return EventResource.getPriorEvents(entity.id, type).then(res => ({
		data: res,
		metadata: {
			total: res.length
		}
	}));
};

const PriorHistoryLog = ({ type, entity, selectButtons }: PriorHistoryLogProps) => {
	if (!logTypes.includes(type)) {
		return null;
	}

	const classes = new BemClass('PriorHistoryLog');
	const title = entity ? T(`priorHistoryLogs.title.${type.toLowerCase()}`) : '';

	const notesFilterActive = useSelector(state => state.NotesFilterActive);

	const { data: events, loading } = usePartialLoader({
		fetcher,
		fetcherProps: { entity, type }
	});

	if (!entity?.id) {
		return null;
	}

	const renderPriorEvent = (event: Event, lastElem?: boolean) => {
		return (
			<div key={`priorEvents-${event.id || event.entityId}`}>
				{getTimelineRow(event, type, notesFilterActive, lastElem)}
			</div>
		);
	};

	return (
		<div className={classes.b()}>
			{events.length ? (
				<HistoryLog title={title} selectButtons={selectButtons} showTopLine loading={loading}>
					{events?.map((event, index) => renderPriorEvent(event, index === events.length - 1))}
				</HistoryLog>
			) : null}
		</div>
	);
};

export default PriorHistoryLog;
