export const RESET = '[MailTemplateEditor] Reset';
export const SET_SELECTED_TAB = '[MailTemplateEditor] Set selected tab';
export const SET_TEMPLATE = '[MailTemplateEditor] Set TEMPLATE';
export const SET_GROUP_MAIL_CATEGORIES = '[MailTemplateEditor] SET group mail categories';
export const SET_LOADING = '[MailTemplateEditor] Set loading';
export const SET_SAVING = '[MailTemplateEditor] Set saving';
export const SET_ACCOUNT_PROFILE = '[MailTemplateEditor] Set account profile';
export const SET_STYLE = '[MailTemplateEditor] Set style';
export const SET_SELECTED_CONTENT = '[MailTemplateEditor] Set selected content';
export const SET_ACTIVE_CONFIRM = '[MailTemplateEditor] Set active confirm';
export const SET_CONFIRM_LOADING = '[MailTemplateEditor] Set confirm loading';
export const SET_CONFIG = '[MailTemplateEditor] Set config';
export const SET_LOADING_PREVIEW_BROWSER = '[MailTemplateEditor] Set loading preview browser';
export const SET_LOAD_ERROR = '[MailTemplateEditor] Set load error';
export const SET_LOADING_MINI_PREVIEW = '[MailTemplateEditor] Set loading mini preview';
export const SET_FORM_ERROR = '[MailTemplateEditor] Set form error';
export const SET_MAIL_ACCOUNT = '[MailTemplateEditor] Set mail account';
export const SET_SIZE_OF_ALL_FILES = '[MailTemplateEditor] Set size of all files';
export const SET_SIGNATURE = '[MailTemplateEditor] Set signature';
export const SET_INITIAL_SIGNATURE = '[MailTemplateEditor] Set initial signature';
export const SET_PREVIEW_TEXT = '[MailTemplateEditor] Set preview text';
export const SET_CHANGES = '[MailTemplateEditor] Set changes';
export const SET_CURRENT_CHANGE = '[MailTemplateEditor] Set current change';
export const SET_RETURN_TO = '[MailTemplateEditor] Set returnTo';
export const SET_SOCIAL_EVENT = '[MailTemplateEditor] Set social event';
export const SET_DOMAINS = '[MailTemplateEditor] Set domains';
export const SET_TEMPLATES_TAB = '[MailTemplateEditor] Set templates tab';

export const TABS = {
	design: 'design',
	settingsAndSend: 'settingsAndSend'
};

export const CONTENT = {
	selectTemplate: {
		name: 'selectTemplate',
		tab: TABS.design
	},
	editor: {
		name: 'editor',
		tab: TABS.design
	},
	settingsAndSend: {
		name: 'settingsAndSend',
		tab: TABS.settingsAndSend
	}
};

export const initialState = {
	template: {},
	groupMailCategories: null,
	previewText: '', // we put this here until we can add it to groupMail data model
	accountProfile: {},
	style: '',
	loading: true,
	saving: false,
	selectedTab: TABS.design,
	selectedContent: CONTENT.selectTemplate.name,
	activeConfirm: null,
	confirmLoading: false,
	config: null,
	loadingPreviewBrowser: false,
	loadError: null,
	loadingMiniPreview: false,
	formError: [],
	mailAccount: null,
	sizeOfAllFiles: 0,
	signature: '',
	initialSignature: '',
	changes: [],
	currentChange: -1,
	returnTo: null,
	socialEvent: null,
	domains: {},
	templatesTab: 'my'
};

const ACTION_HANDLERS = {
	[RESET]: () => ({ ...initialState }),
	[SET_SELECTED_TAB]: (state, { selectedTab }) => ({ ...state, selectedTab }),
	[SET_TEMPLATE]: (state, { template }) => ({ ...state, template }),
	[SET_GROUP_MAIL_CATEGORIES]: (state, { groupMailCategories }) => ({ ...state, groupMailCategories }),
	[SET_LOADING]: (state, { loading }) => ({ ...state, loading }),
	[SET_SAVING]: (state, { saving }) => ({ ...state, saving }),
	[SET_ACCOUNT_PROFILE]: (state, { accountProfile }) => ({ ...state, accountProfile }),
	[SET_STYLE]: (state, { style }) => ({ ...state, style }),
	[SET_SELECTED_CONTENT]: (state, { selectedContent }) => ({ ...state, selectedContent }),
	[SET_ACTIVE_CONFIRM]: (state, { activeConfirm }) => ({ ...state, activeConfirm, confirmLoading: false }),
	[SET_CONFIRM_LOADING]: (state, { confirmLoading }) => ({ ...state, confirmLoading }),
	[SET_CONFIG]: (state, { config }) => ({ ...state, config }),
	[SET_LOAD_ERROR]: (state, { loadError }) => ({ ...state, loadError }),
	[SET_LOADING_PREVIEW_BROWSER]: (state, { loadingPreviewBrowser }) => ({ ...state, loadingPreviewBrowser }),
	[SET_LOADING_MINI_PREVIEW]: (state, { loadingMiniPreview }) => ({ ...state, loadingMiniPreview }),
	[SET_FORM_ERROR]: (state, { formError }) => ({ ...state, formError }),
	[SET_MAIL_ACCOUNT]: (state, { mailAccount }) => ({ ...state, mailAccount }),
	[SET_SIZE_OF_ALL_FILES]: (state, { sizeOfAllFiles }) => ({ ...state, sizeOfAllFiles }),
	[SET_SIGNATURE]: (state, { signature }) => ({ ...state, signature }),
	[SET_PREVIEW_TEXT]: (state, { previewText }) => ({ ...state, previewText }),
	[SET_CHANGES]: (state, { changes }) => ({ ...state, changes }),
	[SET_CURRENT_CHANGE]: (state, { currentChange }) => ({ ...state, currentChange }),
	[SET_INITIAL_SIGNATURE]: (state, { initialSignature }) => ({ ...state, initialSignature }),
	[SET_SOCIAL_EVENT]: (state, { socialEvent }) => ({ ...state, socialEvent }),
	[SET_RETURN_TO]: (state, { returnTo }) => ({ ...state, returnTo }),
	[SET_DOMAINS]: (state, { domains }) => ({ ...state, domains }),
	[SET_TEMPLATES_TAB]: (state, { templatesTab }) => ({ ...state, templatesTab })
};

export default (state = { ...initialState }, action) => {
	const handler = ACTION_HANDLERS[action.type];
	return handler ? handler(state, action) : state;
};
