import React, { useEffect, useState } from 'react';
import {
	Block,
	DrawerHeader,
	Input,
	Drawer,
	Label,
	Checkbox,
	Row,
	Text,
	Button,
	Icon,
	Column
} from '@upsales/components';
import BemClass from '@upsales/components/Utils/bemClass';
import T from 'Components/Helpers/translate';
import './EditCallListDrawer.scss';
import { Project } from 'App/resources/Project';
import { SlideFade } from 'App/components/animations';
import UserRoleList from 'Components/Inputs/UserRoleList';
import UserSelect from 'Components/UserSelect';
import CallListResource from 'Resources/CallList';
import logError from 'Helpers/logError';

type Props = {
	className?: string;
	callList?: Partial<Project> & Pick<Project, 'id' | 'name' | 'users'>;
	close: (a?: any) => void;
};

const EditCallListDrawer = (props: Props) => {
	const creatingNew = !props.callList;

	const {
		className,
		callList = {
			name: '',
			users: [],
			active: true,
			isCallList: true,
			userEditable: true,
			userRemovable: true
		} as Partial<Project>
	} = props;
	const classes = new BemClass('EditCallListDrawer');
	classes.add(className);

	const hasSharedCallLists = Tools.FeatureHelper.hasSoftDeployAccess('SHARED_CALL_LISTS');

	const [data, setData] = useState({ ...callList });
	const [shared, setShared] = useState((callList.users?.length ?? 0) > 1 && hasSharedCallLists);

	const [mainUser, setMainUser] = useState(callList.users?.[0] ?? Tools.AppService.getSelf());
	const [sharedUserIds, setSharedUserIds] = useState((callList.users ?? []).slice(1).flatMap(u => u.id ?? []));

	const [hasChanged, setHasChanged] = useState(false);
	const [firstRender, setFirstRender] = useState(true);

	useEffect(() => {
		if (firstRender) {
			setFirstRender(false);
			return;
		}
		if (!hasChanged) {
			setHasChanged(true);
		}
	}, [data, shared, mainUser, sharedUserIds]);

	const canSave = hasChanged && !!data.name && !!mainUser && (!shared || sharedUserIds.length > 0);

	const save = () => {
		const mapUsers = (userIds: number[]) => {
			const users = Tools.AppService.getUsers();
			return userIds.flatMap(id => users.find(u => u.id === id) ?? []);
		};

		const saveData = {
			...data,
			users: [mainUser].concat(shared ? mapUsers(sharedUserIds.filter(id => id !== mainUser.id)) : [])
		};

		CallListResource.save(saveData)
			.then(res => {
				setTimeout(() =>
					props.close({
						...saveData,
						...res.data
					})
				);
				return res;
			})
			.catch(e => logError(e, 'Failed to create call list'));
	};

	return (
		<Drawer className={classes.b()}>
			<DrawerHeader
				icon="call-list"
				onHide={() => props.close()}
				title={creatingNew ? T('callList.edit.titleCreate') : T('callList.edit.titleEdit')}
			>
				<Button onClick={save} disabled={!canSave} color="green">
					<Row>
						<Text color={canSave ? 'white' : 'grey-8'} size="lg" bold>
							{T('default.save')}
						</Text>
						<Icon name="check" space="mll mts" color={canSave ? 'white' : 'grey-8'} />
					</Row>
				</Button>
			</DrawerHeader>
			<div className={classes.elem('content').b()}>
				<Block space="mbxl mtxl mll mrl">
					<Block space="mbxl">
						<Label required maxLength={50} value={data.name}>
							{T('callList.edit.nameLabel')}
						</Label>
						<Input
							value={data.name}
							onChange={e => {
								const name = e.target.value;
								setData(old => ({ ...old, name }));
							}}
						/>
					</Block>

					<Block space="mbxl">
						<UserSelect
							user={mainUser}
							onChange={setMainUser}
							preText={T('callList.edit.primaryUser')}
							postText=""
						/>
					</Block>

					{hasSharedCallLists ? (
						<Block>
							<Block space="mll mrl mtl mbl">
								<Row>
									<Checkbox
										size="sm"
										checked={shared}
										onClick={() => setShared(prev => !prev)}
										space="mrl mtm"
									/>
									<Column>
										<Text>{T('callList.edit.shareThisList')}</Text>
										<Text size="sm" color="grey-10">
											{T('callList.edit.shareListInfo')}
										</Text>
									</Column>
								</Row>
							</Block>

							<SlideFade visible={shared} direction="top">
								<Block space="mts mll mrl">
									<Label required>{T('callList.edit.shareWithUsers')}</Label>
									<UserRoleList
										usersOnly
										selectedUsersRoles={sharedUserIds}
										setSelectedUsersRoles={setSharedUserIds}
										toggleUserRole={targetId => {
											const newIds = sharedUserIds.some(id => id === targetId)
												? sharedUserIds.flatMap(id => (id === targetId ? [] : id))
												: sharedUserIds.concat(targetId);
											setSharedUserIds(newIds);
										}}
									/>
								</Block>
							</SlideFade>
						</Block>
					) : null}
				</Block>
			</div>
		</Drawer>
	);
};

export default EditCallListDrawer;
