import Appointment, { RawAppointment } from 'App/resources/Model/Appointment';
import Resource from './Resource';

class AppointmentResource extends Resource {
	eventName: string;

	constructor() {
		super('appointments');

		this.eventName = 'appointment';
		this.dateFields = ['indexDate', 'modDate', 'regDate', 'date', 'endDate'];
		this.notifications = {
			save: () => ({
				title: 'default.saved',
				body: 'saved.appointment',
				style: 'success',
				icon: 'check',
				type: 'body'
			}),
			saveError: res => ({
				title: 'default.error',
				body: 'saveError.appointment',
				style: 'error',
				icon: 'times',
				type: 'body',
				errorTranslated: res?.error?.translated
			}),
			delete: () => ({
				title: 'default.deleted',
				body: 'deleted.appointment',
				style: 'success',
				icon: 'check',
				type: 'body'
			}),
			deleteError: () => ({
				title: 'default.error',
				body: 'deleteError.appointment',
				style: 'error',
				icon: 'times',
				type: 'body'
			})
		};
	}

	analyticsProps = (
		opts: { skipNotification: boolean },
		type: 'added' | 'updated' | 'deleted',
		res: { data: Appointment }
	) => {
		return { isCompleted: res.data.outcome === 'completed' };
	};

	map(appointment: Appointment): RawAppointment {
		const mappedAppointment: RawAppointment = { ...appointment };
		if (appointment.client) {
			mappedAppointment.client = { id: appointment.client.id, name: appointment.client.name };
		}
		if (Array.isArray(appointment.users)) {
			mappedAppointment.users = appointment.users.map(({ id, name }) => ({ id, name }));
		}
		if (appointment.project) {
			mappedAppointment.project = { id: appointment.project.id, name: appointment.project.name };
		}
		if (appointment.activityType) {
			mappedAppointment.activityType = { id: appointment.activityType.id, name: appointment.activityType.name };
		}
		if (appointment.opportunity) {
			mappedAppointment.opportunity = {
				id: appointment.opportunity.id,
				description: appointment.opportunity.description
			};
		}
		return mappedAppointment;
	}
}

const resource = new AppointmentResource();

export default resource;
