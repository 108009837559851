import React from 'react';
import { EVENT_TIMELINE_SUB_TYPES } from 'App/babel/enum/timeline';
import TimelineGeneric from '../TimelineGeneric/TimelineGeneric';
import { OUTCOME_TYPES } from 'App/babel/enum/activityOutcome';
import moment from 'moment';
import { useTranslation } from 'Components/Helpers/translate';
import { Icon, Text, Tooltip } from '@upsales/components';
import { openDrawer } from 'Services/Drawer';
import BemClass from '@upsales/components/Utils/bemClass';
import type { TimelineCardComponent } from '../TimelineGeneric/TimelineCardConfigs';

const TimelineCardActivity = (({ event, ...passthrough }) => {
	const { date, activity, subType } = event || {};
	const { t } = useTranslation();
	const todoType = Tools.AppService.getTodoTypes();
	const activityType =
		Object.values(todoType).find(e => e.id === event.activity?.activityType?.id)?.name ?? 'Activity';
	const shouldHaveCompleted = moment(date) < moment() && subType !== 'Closed';

	const langs = {
		Todo: {
			completed: 'default.completed',
			planned: 'default.hasPlannedTodo',
			shouldHave: 'default.shouldHaveCompletedTodo'
		},
		Phonecall: {
			completed: 'default.completed',
			planned: 'default.hasPlannedPhonecall',
			shouldHave: 'default.shouldHaveCompletedPhonecall'
		}
	};

	const lang = langs[activityType as keyof typeof langs] ?? {
		completed: 'default.completed',
		planned: 'default.hasPlannedActivity',
		shouldHave: 'default.shouldHaveCompletedActivity'
	};

	const isPhoneCall = activity?.activityType?.id === todoType.PHONE_CALL.id;

	const activityName = isPhoneCall ? t('default.phonecall') : activity?.activityType?.name || '';
	const afterTime = isPhoneCall || activity?.activityType?.id === todoType.TODO.id ? undefined : activityName;
	const timelineProps = { ...passthrough };
	if (isPhoneCall && subType?.toLowerCase() === EVENT_TIMELINE_SUB_TYPES.CLOSED) {
		timelineProps.titleElements = ['users', 'contacts', 'action', 'eventLink'];
		timelineProps.contactPreposition =
			activity?.outcomeType === OUTCOME_TYPES.NO_ANSWER
				? t('activity.outcomeLog.didntReached').toLowerCase()
				: t('activity.outcomeLog.reached').toLowerCase();
	}
	return (
		<TimelineGeneric
			event={event}
			key={event.entityId}
			afterTime={afterTime}
			eventLinkTitle={() => activity?.description ?? ''}
			actionTitle={() => {
				if (subType?.toLowerCase() === EVENT_TIMELINE_SUB_TYPES.CLOSED) {
					return isPhoneCall
						? event.contacts?.length
							? t('activity.outcomeLog.stopTryingPhoneCall').toLocaleLowerCase()
							: t('activity.outcomeLog.stopTryingPhoneCallNoContact').toLocaleLowerCase()
						: t('default.completed').toLowerCase();
				} else if (shouldHaveCompleted) {
					return t(lang.shouldHave).toLowerCase();
				} else if (subType?.toLowerCase() === EVENT_TIMELINE_SUB_TYPES.CREATED) {
					return t(lang.planned).toLowerCase();
				}
				return '';
			}}
			actions={
				// @ts-expect-error This field exists when creating the event through EventService.create.Activity. Types could get messy if not careful, so leaving it be for now
				activity?.hasHighPriority ? (
					<Tooltip title={t('activity.priority')}>
						<Icon space="pll prl ptm pbm" name="flag" color="red"></Icon>
					</Tooltip>
				) : null
			}
			onLinkClick={() => {
				switch (event.activity?.activityType?.id) {
					case todoType.TODO.id:
						return openDrawer('EditTodo', { todo: event.activity });
					case todoType.PHONE_CALL.id:
						return openDrawer('EditPhonecall', { activity: event.activity });
					default:
						return Tools.$upModal.open('editActivity', { id: event.entityId });
				}
			}}
			commentFetchFilters={{ activityId: event.entityId }}
			subTitleElements={[
				shouldHaveCompleted && date
					? () => (
							<Text
								className={new BemClass('TimelineCard').elem('time').mod('late').b()}
								size="sm"
								color={'red'}
							>
								{moment(date).format('L')}
							</Text>
					  )
					: 'time',
				'afterTime'
			]}
			iconColor={shouldHaveCompleted && date ? 'red' : undefined}
			notes={event.activity?.notes}
			{...timelineProps}
		/>
	);
}) satisfies TimelineCardComponent;

export default TimelineCardActivity;
