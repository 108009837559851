import Attribute, { DisplayType, Type } from './Attribute';

const AgreementAttributes = {
	id: Attribute({
		title: 'default.id',
		field: 'id',
		type: Type.Number,
		groupable: false,
		selectableColumn: true,
		sortable: 'id'
	}),
	client: Attribute({
		title: 'default.account',
		field: 'client.id',
		displayKey: ['client.id', 'client.name'],
		displayKeyMap: { 'client.id': 'id', 'client.name': 'name' },
		displayType: DisplayType.ClientLink,
		type: Type.String,
		selectableColumn: true,
		sortable: 'client.name'
	}),
	stage: Attribute({
		title: 'default.stage',
		field: 'stage.id',
		displayKey: 'stage.name',
		displayType: DisplayType.Text,
		type: Type.String,
		selectableColumn: true,
		sortable: 'stage.name'
	}),
	description: Attribute({
		title: 'default.description',
		field: 'description',
		displayKey: 'description',
		displayType: DisplayType.Text,
		type: Type.String,
		selectableColumn: true
	}),
	project: Attribute({
		title: 'default.campaigns',
		field: 'project.name',
		displayKey: 'project.name',
		displayType: DisplayType.Text,
		type: Type.String,
		selectableColumn: true,
		sortable: 'project.name'
	}),
	user: Attribute({
		title: 'default.user',
		type: Type.Array,
		displayType: DisplayType.Users,
		field: 'user.id',
		inputType: 'select',
		parent: 'users',
		sortable: 'user.name',
		groupable: false,
		selectableColumn: true,
		filterable: false
	}),
	contact: Attribute({
		title: 'default.contact',
		field: 'contact.id',
		placeholder: 'default.noContact',
		displayKey: ['contact.id', 'contact.name'],
		displayKeyMap: { 'contact.id': 'id', 'contact.name': 'name' },
		displayType: DisplayType.ContactLink,
		type: Type.String,
		selectableColumn: true,
		sortable: 'contact.name'
	}),
	journeyStep: Attribute({
		title: 'default.journeyStep',
		field: 'client.journeyStep',
		displayKey: 'client.journeyStep',
		displayType: DisplayType.Journey,
		type: Type.String,
		selectableColumn: true
	}),
	clientJourneyStep: Attribute({
		title: 'default.journeyStepClient',
		field: 'client.journeyStep',
		displayKey: 'client.journeyStep',
		displayType: DisplayType.Journey,
		type: Type.String,
		selectableColumn: false
	}),
	contactJourneyStep: Attribute({
		title: 'default.journeyStepContact',
		field: 'contact.journeyStep',
		displayKey: 'contact.journeyStep',
		displayType: DisplayType.Journey,
		type: Type.String,
		selectableColumn: false
	}),
	lastIndexIncreaseDate: Attribute({
		title: 'default.lastIndexIncreaseDate',
		field: 'latestIndexIncreaseDate',
		displayKey: 'latestIndexIncreaseDate',
		displayType: DisplayType.Date,
		type: Type.String,
		selectableColumn: true,
		placeholder: 'default.unknown',
		sortable: 'latestIndexIncreaseDate'
	}),
	startDate: Attribute({
		title: 'default.startDate',
		field: 'metadata.agreementStartdate',
		displayKey: 'metadata.agreementStartdate',
		displayType: DisplayType.Date,
		type: Type.String,
		selectableColumn: true,
		sortable: 'metadata.agreementStartdate'
	}),
	endDate: Attribute({
		title: 'default.endDate',
		field: 'metadata.agreementEnddate',
		displayKey: 'metadata.agreementEnddate',
		displayType: DisplayType.Date,
		type: Type.String,
		selectableColumn: true,
		sortable: 'metadata.agreementEnddate'
	}),
	invoiceStartDate: Attribute({
		title: 'agreement.startDateCurrentPeriod',
		field: 'metadata.agreementInvoiceStartdate',
		displayKey: 'metadata.agreementInvoiceStartdate',
		displayType: DisplayType.Date,
		type: Type.String,
		selectableColumn: true,
		sortable: 'metadata.agreementInvoiceStartdate'
	}),
	renewalDate: Attribute({
		title: 'agreement.renewalDate',
		field: 'metadata.agreementRenewalDate',
		displayKey: 'metadata.agreementRenewalDate',
		displayType: DisplayType.Date,
		type: Type.String,
		selectableColumn: true,
		sortable: 'metadata.agreementRenewalDate'
	}),
	nextOrderDate: Attribute({
		title: 'agreement.nextOrderDate',
		field: 'metadata.agreementNextOrderDate',
		displayKey: 'metadata.agreementNextOrderDate',
		displayType: DisplayType.Date,
		type: Type.String,
		selectableColumn: true,
		sortable: 'metadata.agreementNextOrderDate'
	}),
	noticeDate: Attribute({
		title: 'agreement.noticeDate',
		field: 'metadata.noticeDate',
		sortable: 'metadata.noticeDate',
		displayKey: 'metadata.noticeDate',
		displayType: DisplayType.Date,
		type: Type.String,
		selectableColumn: true
	}),
	noticePeriod: Attribute({
		title: 'agreement.noticePeriod',
		field: 'metadata.noticePeriod',
		sortable: 'metadata.noticePeriod',
		displayKey: 'metadata.noticePeriod',
		displayType: DisplayType.Number,
		type: Type.Number,
		selectableColumn: true
	}),
	periodLength: Attribute({
		field: 'metadata.periodLength',
		title: 'agreement.periodLength',
		displayKey: 'metadata.periodLength',
		displayType: DisplayType.StaticValue,
		sortable: 'metadata.periodLength',
		type: Type.Number,
		selectableColumn: true
	}),
	intervalPeriod: Attribute({
		title: 'agreement.intervalPeriod',
		field: 'metadata.agreementIntervalPeriod',
		displayKey: 'metadata.agreementIntervalPeriod',
		displayType: DisplayType.StaticValue,
		type: Type.Number,
		sortable: 'metadata.agreementIntervalPeriod',
		selectableColumn: true
	}),
	probability: Attribute({
		title: 'default.probability',
		field: 'probability',
		displayKey: 'probability',
		displayType: DisplayType.Number,
		type: Type.String,
		selectableColumn: true,
		sortable: 'probability'
	}),
	value: Attribute({
		title: 'default.value',
		field: 'value',
		type: Type.String,
		displayType: DisplayType.Currency,
		displayKey: ['value', 'currency'],
		displayKeyMap: { value: 'value' },
		selectableColumn: true,
		sortable: 'value'
	}),
	yearlyValue: Attribute({
		title: 'default.yearlyValue',
		field: 'yearlyValue',
		displayKey: ['yearlyValue', 'currency'],
		displayKeyMap: { yearlyValue: 'value' },
		currencyFallback: 'master',
		displayType: DisplayType.Currency,
		type: Type.String,
		selectableColumn: true,
		sortable: 'yearlyValue'
	}),
	contributionMargin: Attribute({
		title: 'default.contributionMargin',
		field: 'contributionMargin',
		displayKey: ['contributionMargin'],
		displayKeyMap: { contributionMargin: 'value' },
		currencyFallback: 'master',
		displayType: DisplayType.Currency,
		type: Type.String,
		selectableColumn: true,
		sortable: 'contributionMargin'
	}),
	contributionMarginInAgreementCurrency: Attribute({
		title: 'default.contributionMarginInAgreementCurrency',
		field: 'contributionMarginInAgreementCurrency',
		type: Type.Number,
		displayType: DisplayType.Currency,
		displayKey: ['contributionMarginInAgreementCurrency', 'currency'],
		displayKeyMap: { contributionMarginInAgreementCurrency: 'value' },
		selectableColumn: true,
		sortable: 'contributionMarginInAgreementCurrency'
	}),
	yearlyContributionMarginInAgreementCurrency: Attribute({
		title: 'default.yearlyContributionMarginInAgreementCurrency',
		field: 'yearlyContributionMarginInAgreementCurrency',
		displayKey: ['yearlyContributionMarginInAgreementCurrency', 'currency'],
		displayKeyMap: { yearlyContributionMarginInAgreementCurrency: 'value' },
		currencyFallback: 'master',
		displayType: DisplayType.Currency,
		type: Type.String,
		selectableColumn: true,
		sortable: 'yearlyContributionMargin'
	}),
	yearlyContributionMargin: Attribute({
		title: 'default.yearlyContributionMargin',
		field: 'yearlyContributionMargin',
		type: Type.String,
		displayType: DisplayType.Currency,
		displayKey: ['yearlyContributionMargin'],
		displayKeyMap: { yearlyContributionMargin: 'value' },
		selectableColumn: true,
		sortable: 'yearlyContributionMargin'
	}),
	yearlyValueInRoleCurrency: Attribute({
		title: 'default.yearlyValueInRoleCurrency',
		field: 'yearlyValueInRoleCurrency',
		type: Attribute.types.Number
	}),
	yearlyValueInMasterCurrency: Attribute({
		title: 'default.yearlyValueInMasterCurrency',
		field: 'yearlyValueInMasterCurrency',
		type: Attribute.types.Number
	}),
	clientConnection: Attribute({
		title: 'default.clientConnection',
		field: 'clientConnection.name',
		type: Type.String,
		selectableColumn: true,
		sortable: 'clientConnection.name',
		displayKey: ['clientConnection.id', 'clientConnection.name'],
		displayKeyMap: { 'clientConnection.id': 'id', 'clientConnection.name': 'name' },
		displayType: DisplayType.ClientLink
	})
};

export default AgreementAttributes;
