import RequestBuilder from '../../resources/RequestBuilder';
import ComparisonTypes from '../../resources/ComparisonTypes';

const SoliditetCountries = ['SE', 'DK', 'NO', 'FI'];

class Soliditet {
	async makeRequest({
		searchField,
		searchString,
		limit,
		offset,
		countryCode,
		availableCountries,
		returnTotal = false
	}) {
		const SoliditetAttributes = Tools.SoliditetClient.attr;
		const filter = new RequestBuilder();

		filter.addSort('turnover', false);
		filter.addSort(SoliditetAttributes.headquarters, false);

		if (countryCode && availableCountries.indexOf(countryCode) > -1) {
			filter.addFilter(SoliditetAttributes.country, ComparisonTypes.Equals, countryCode);
		} else {
			const orBuilder = filter.orBuilder();
			for (const country of SoliditetCountries) {
				orBuilder.addFilter(SoliditetAttributes.country, ComparisonTypes.Equals, country);
				orBuilder.next();
			}

			orBuilder.done();
		}

		filter.limit = limit;
		filter.offset = offset;

		filter.addFilter(SoliditetAttributes[searchField], ComparisonTypes.Search, searchString);
		filter.addFilter(SoliditetAttributes.status, ComparisonTypes.Match, 'Aktiv');

		const apiRequest = Tools.SoliditetClient.customer(Tools.AppService.getCustomerId());
		const internalResult = await apiRequest.find(filter.build());

		if (returnTotal) {
			return internalResult.metadata.total;
		}

		return internalResult.data;
	}

	async search({ dataSource, searchField, searchString, fetchMetadata }) {
		if (!dataSource) {
			throw new Error('dataSource prop cant be undefined');
		}

		const LIMIT = 5;
		const OFFSET = 0;

		const countries = dataSource.countries;
		const currentTab = dataSource.currentTab;
		const result = { data: {}, metadata: {} };

		result.data = await this.makeRequest({
			searchField,
			searchString: searchString,
			limit: LIMIT,
			offset: OFFSET,
			countryCode: currentTab,
			availableCountries: countries
		});

		if (fetchMetadata) {
			const returnTotal = true;

			result.metadata.ALL = await this.makeRequest({
				searchField,
				searchString: searchString,
				limit: LIMIT,
				offset: OFFSET,
				availableCountries: countries,
				returnTotal: returnTotal
			});

			for (const country of countries) {
				if (SoliditetCountries.indexOf(country) > -1) {
					result.metadata[country] = await this.makeRequest({
						searchField,
						searchString: searchString,
						limit: LIMIT,
						offset: OFFSET,
						countryCode: country,
						availableCountries: countries,
						returnTotal: returnTotal
					});
				}
			}
		}

		return result;
	}
}

export default Soliditet;
