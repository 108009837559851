import _ from 'lodash';
import RequestBuilder from '../../resources/RequestBuilder';
import ComparisonTypes from '../../resources/ComparisonTypes';
const Soliditet = require('../Soliditet').default;
import Prospecting from 'App/babel/resources/Prospecting';

const SOLIDITET = 'soliditet';
const EXISTING = 'existing';
const PROSPECTING = 'prospecting';

class TypeAhead {
	constructor() {
		this.hasNewFields = Tools.FeatureHelper.hasSoftDeployAccess(Tools.FeatureHelper.Feature.NEW_FIELDS);

		const orgfield = _.find(Tools.AppService.getCustomFields('account'), { alias: 'ORG_NO' });
		this.orgFieldId = orgfield ? orgfield.id : undefined;

		this._Soliditet = new Soliditet();
	}

	async matchExisting(config, result) {
		const values = _.pluck(result.data, config.integration);

		if (!values.length) {
			return Promise.resolve(result);
		}

		const rb = new RequestBuilder();
		rb.addFilter({ field: config.upsales }, ComparisonTypes.Equals, values);
		rb.addFilter(Tools.Account.attr.isExternal, ComparisonTypes.Equals, false);

		const upsalesAccounts = await this.findAll(Tools.Account.customer(Tools.AppService.getCustomerId()), rb);

		const valueMap = upsalesAccounts.reduce((res, upsalesAccount) => {
			const key = upsalesAccount[config.upsales];
			res[key] = upsalesAccount;

			return res;
		}, {});

		const mappedData = result.data.map(dataSourceAccount => {
			const key = dataSourceAccount[config.integration];
			const match = valueMap[key];

			if (match) {
				dataSourceAccount.existing = true;
				dataSourceAccount.upsalesId = match.id;
			}

			return dataSourceAccount;
		});

		return {
			data: mappedData,
			metadata: result.metadata
		};
	}

	async findAll(resource, rb) {
		return new Promise(resolve => {
			const result = [];
			const limit = 1000;

			const get = async offset => {
				rb.offset = offset;
				rb.limit = limit;

				const response = await resource.find(rb.build());
				result.push(response.data);

				if (response.data.length === limit) {
					get(offset + limit);
				} else {
					return resolve(_.flatten(result));
				}
			};

			get(0);
		});
	}

	async searchExisting({ dataSource, searchString, limit }) {
		const rb = new RequestBuilder();
		rb.limit = limit;
		rb.offset = dataSource.offset;

		const parsed = this._Soliditet.parseNumber(searchString);
		if (!isNaN(parsed) && parsed.toString().length >= 8 && (this.orgFieldId || this.hasNewFields)) {
			var val;
			// is international --> first two characters are letters
			var tmp = parsed.toString();
			var first, second;
			if (isNaN(searchString[0]) && isNaN(searchString[1])) {
				first = tmp.slice(0, 2);
				second = tmp.slice(2, tmp.length);
				val = first + ' ' + second;
			} else {
				if (tmp.length > 5) {
					first = tmp.slice(0, 6);
					second = tmp.slice(6, tmp.length);
					val = first + '-' + second;
				} else {
					val = tmp;
				}
			}

			var orFilter = rb.orBuilder();

			if (this.orgFieldId) {
				orFilter.next();
				var groupBuilder = orFilter.groupBuilder();
				groupBuilder.addFilter({ field: 'custom.value' }, rb.comparisonTypes.Wildcard, tmp);
				groupBuilder.addFilter({ field: 'custom.fieldId' }, rb.comparisonTypes.Equals, this.orgFieldId);
				groupBuilder.done();
				orFilter.next();
				var groupBuilder2 = orFilter.groupBuilder();
				groupBuilder2.addFilter({ field: 'custom.value' }, rb.comparisonTypes.Wildcard, val);
				groupBuilder2.addFilter({ field: 'custom.fieldId' }, rb.comparisonTypes.Equals, this.orgFieldId);
				groupBuilder2.done();
			}
			if (this.hasNewFields) {
				orFilter.next();
				orFilter.addFilter({ field: 'orgNo' }, rb.comparisonTypes.Wildcard, tmp);
				orFilter.next();
				orFilter.addFilter({ field: 'orgNo' }, rb.comparisonTypes.Wildcard, val);
			}

			orFilter.done();
		} else {
			rb.addFilter(Tools.Account.attr.name, rb.comparisonTypes.WildcardEnd, searchString);
		}

		return Tools.Account.customer(Tools.AppService.getCustomerId)
			.find(rb.build())
			.then(function (res) {
				return {
					data: res.data,
					metadata: {
						ALL: res.metadata.total
					}
				};
			});
	}

	searchProspecting({ searchField, searchString, limit, offset, country }) {
		const rb = new RequestBuilder();
		rb.limit = limit;
		rb.offset = offset;

		if (searchField === 'name') {
			rb.addFilter({ field: searchField }, ComparisonTypes.Search, searchString);
		} else if (searchField === 'orgNumber') {
			rb.addFilter({ field: searchField }, ComparisonTypes.Equals, searchString);
			rb.addFilter({ field: 'headquarters' }, ComparisonTypes.Equals, true);
		} else {
			rb.addFilter({ field: searchField }, ComparisonTypes.Equals, searchString);
		}

		rb.extraParams.push({ key: 'country', value: country });

		return Prospecting.find(rb.build()).then(res => {
			return {
				data: res.data,
				metadata: {
					ALL: res.metadata.total
				}
			};
		});
	}

	async search({ searchField, searchString, dataSource, limit, offset, fetchMetadata }) {
		if (!searchString) {
			return Promise.resolve({ data: [], metadata: {} });
		}

		if (dataSource.id === SOLIDITET) {
			return this._Soliditet.search({ dataSource, searchField, searchString, fetchMetadata });
		}
		if (dataSource.id === EXISTING) {
			return this.searchExisting({ dataSource, searchString, limit });
		}
		if (dataSource.id === PROSPECTING) {
			return this.searchProspecting({ searchField, searchString, limit, offset, country: dataSource.country });
		}

		const SoliditetAttributes = Tools.SoliditetClient.attr;
		const rb = new RequestBuilder();
		rb.limit = limit || 5;
		rb.offset = offset || 0;

		rb.addSort('turnover', false);

		const orgNoRegex = /\D{2}\d{6,}|\d{8,}/;
		if (searchString.match(orgNoRegex)) {
			rb.addFilter(SoliditetAttributes.orgNo, ComparisonTypes.WildcardEnd, searchString);
		} else {
			rb.addFilter(SoliditetAttributes.name, ComparisonTypes.WildcardEnd, searchString);
		}

		rb.addFilter(SoliditetAttributes.status, ComparisonTypes.Match, 'Aktiv');
		if (dataSource.country) {
			rb.addFilter(SoliditetAttributes.country, ComparisonTypes.Equals, dataSource.country);
		}

		const params = {
			type: 'client',
			integrationId: dataSource.id,
			data: rb.build()
		};

		if (fetchMetadata && dataSource.countries && dataSource.countries.length) {
			params.data.countries = dataSource.countries;
		}

		const result = await Tools.DataSource.typeahead(params);
		if (dataSource.match) {
			const returnedMatch = await this.matchExisting(dataSource.match, result);
			return Promise.resolve(returnedMatch);
		}

		return Promise.resolve({
			data: result.data,
			metadata: result.metadata
		});
	}
}

export default TypeAhead;
