import Order from 'App/resources/Model/Order';
import { SubscriptionGroupState } from 'App/components/EditSubscription/Context/SubscriptionGroupState';
import { FormatSubscriptionGroupType } from 'App/components/EditSubscription/Context/SubscriptionGroupContextHelpers';
import { TYPE, STYLE } from 'Store/reducers/SystemNotificationReducer';
import ResourceTyped from './ResourceTyped';
import AgreementGroupType from 'App/resources/Model/AgreementGroup';
import { OldOrNewAgreement } from 'App/components/SubscriptionCards/SubscriptionCards';
import { v4 as uuidv4 } from 'uuid';
import Agreement from './Agreement';
import logError from 'Helpers/logError';

type AgreementMetadata = {
	createdOrders: {
		createdOrder: Order;
		createdDiffOrder: Order;
	}[];
	updatedOrder: Order;
};

type Opts = {
	dontWait?: boolean;
	noRefresh?: boolean;
	state?: SubscriptionGroupState;
};

class AgreementGroup extends ResourceTyped<
	AgreementGroupType,
	AgreementGroupType,
	FormatSubscriptionGroupType,
	AgreementMetadata
> {
	eventName: string;

	constructor() {
		super('agreementGroups', null, { notificationTitle: 'default.agreement' });
		this.eventName = 'agreementGroup';

		// @ts-expect-error
		this.notifications.moveToClientError = () => ({
			title: 'default.agreement',
			body: 'subscription.MoveToClientError',
			style: STYLE.ERROR,
			icon: 'trash',
			type: TYPE.BODY
		});
	}

	moveToClient(agreementGroupId: number, clientId: number, contactId?: number): Promise<AgreementGroupType> {
		return this._postRequest(
			`moveToClient/`,
			{ agreementGroupId, clientId, contactId },
			{ methodName: 'moveToClient' }
		).then(({ data: { data: agreementGroup } }) => {
			Tools.$rootScope.$broadcast('agreementGroup.updated', agreementGroup);
			return agreementGroup;
		});
	}

	getAllAgreements(clientId: number): Promise<AgreementGroupType[]> {
		return this._getRequest(`client/` + clientId).then(({ data }) => data);
	}

	getByAgreementId(agreementId: number): Promise<AgreementGroupType> {
		return this._getRequest(`agreement/` + agreementId).then(({ data }) => data);
	}

	async save(group: FormatSubscriptionGroupType, opts: Opts = {}) {
		const updatedIds = new Set();
		const tempIds: string[] = [];

		group.agreements.forEach((agreement, idx: number) => {
			if (agreement.id) {
				updatedIds.add(agreement.id);
			}

			if (opts.dontWait) {
				const tempId = uuidv4();
				tempIds.push(tempId);

				const method = agreement.id ? 'updated' : 'added';

				if (opts.state?.subscriptionMap && agreement.uuid) {
					const period = opts.state.subscriptionMap[agreement.uuid];

					if (period) {
						period.tempId = tempId;
						opts.state.subscriptionMap[period.uuid] = period;
					}
				}

				Tools.$rootScope.$broadcast('agreement.' + method, {
					...agreement,
					state: opts.state,
					tempId
				});
			}
		});

		group.opts = {
			noRefresh: true
		};

		try {
			const res = await super.save(group, opts);
			const { data, metadata } = res;

			data.agreements.forEach((agreement: any, idx: number) => {
				const method = updatedIds.has(agreement.id) || opts.dontWait ? 'updated' : 'added';
				Tools.$rootScope.$broadcast('agreement.' + method, { ...agreement, tempId: tempIds[idx] });
			});

			if (metadata?.updatedOrder) {
				Tools.$rootScope.$broadcast('opportunity.updated', metadata?.updatedOrder);
			}

			if (metadata?.createdOrders?.length) {
				const orders: Order[] = [];
				metadata.createdOrders.forEach(order => {
					const { createdOrder, createdDiffOrder } = order;

					if (createdOrder) {
						orders.push(createdOrder);
					}
					if (createdDiffOrder) {
						orders.push(createdDiffOrder);
					}
				});

				const passedOrderIds = group.agreements.map(a => a.createdFromOrderId);

				orders.forEach(order =>
					Tools.$rootScope.$broadcast(
						passedOrderIds.includes(order.id) ? 'order.updated' : 'order.added',
						order
					)
				);
			}

			const diffOrders = metadata?.createdOrders.map(o => o.createdDiffOrder).filter(Boolean);

			if (diffOrders?.length) {
				diffOrders.forEach(diffOrder => {
					Tools.$upModal.open('editOrder', { id: diffOrder.id });
				});
			}

			return res;
		} catch (err) {
			if (opts.dontWait) {
				group.agreements.forEach((agreement, idx) => {
					if (!agreement.id) {
						Tools.$rootScope.$broadcast('agreement.deleted', { tempId: tempIds[idx] });
					} else {
						Agreement.get(agreement.id)
							.then(({ data }) => {
								Tools.$rootScope.$broadcast('agreement.updated', { ...data, tempId: tempIds[idx] });
							})
							.catch(err => {
								logError(err, 'AgreementGroup - Error getting agreement after save: ');
							});
					}
				});
			}

			throw err;
		}
	}

	async delete(id: number, agreementGroup?: OldOrNewAgreement, opts = {}) {
		const res = await super.delete(id, opts);

		// We only require id when deleting, but if the group was passed then we can broadcast events for each agreement
		agreementGroup?.agreements.forEach(agreement => {
			Tools.$rootScope.$broadcast('agreement.deleted', agreement);
		});

		return res;
	}
}

export default new AgreementGroup();
