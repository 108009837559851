import config from 'App/babel/config';
import moment from 'moment';
import MailCampaign, { RawMailCampaign } from '../Model/MailCampaign';

const parseMailCampaign = (mailCampaign: Partial<RawMailCampaign>): Partial<MailCampaign> => {
	const result: Partial<MailCampaign> = { ...mailCampaign };

	// Set bodyJson to null if it is a stringified object
	try {
		if (typeof mailCampaign.bodyJson === 'string' && mailCampaign.bodyJson[0] === '{') {
			result.bodyJson = null;
		}
	} catch (e) {
		// do nothing
	}
	// Filterconfig is parsed to json in the genericParser. If this fails it will be set to null, then we make sure that filter follows
	if (!mailCampaign.filterConfig) {
		result.filter = null;
	}

	// sendDate
	const sendDate = moment(mailCampaign.sendDate);
	if (mailCampaign.sendDate && sendDate.isValid()) {
		result.sendDate = sendDate.toDate();
	}

	// TemplateThumb url
	result.$$thumbnailUrl =
		config.URL + config.API + 'thumbnail/groupMail/' + mailCampaign.id + '?timestamp=' + Date.now();

	return result;
};

export default parseMailCampaign;
