import React, { Component, Fragment, useState } from 'react';
import Comment from 'Resources/Comment';
import PropTypes from 'prop-types';
import { get } from 'lodash';
import BemClass from '@upsales/components/Utils/bemClass';
import { statusDisqualifiable } from 'Components/Helpers/journeyStep';
import moment from 'moment';
import { connect } from 'react-redux';
import { Card, CardHeader, CardContent, Icon, Text, Button, Title, Toggle, Label, Tooltip } from '@upsales/components';
import { SlideFade } from '@upsales/components/animations';
import OutcomeTypeButton from './OutcomeTypeButton';
import OutcomePostponeDate from './OutcomePostponeDate';
import PlannedAppointments from './PlannedAppointments';
import MailTemplate from 'Resources/MailTemplate';
import TopContacts from './TopContacts';
import WebLinks from './WebLinks';
import CommentInput from 'App/components/Inputs/CommentInput';
import {
	OUTCOME_TYPES,
	NO_ANSWER_ACTIONS,
	ANSWER_ACTIONS,
	ANSWER_ACTIONS_GREAT,
	DEFAULT_PRESET,
	DEFAULT_PRESETS,
	LEAD_TYPES,
	NO_LEAD_ACTIONS,
	POSTPONED,
	FOUND_LEAD_ACTIONS,
	OUTCOME_BACKEND_ACTIONS,
	FOLLOWUP_TYPES,
	SET_DATE
} from 'App/babel/enum/activityOutcome';
import { fetchAppointments } from 'Store/actions/ActivityOutcomesActions';
import { openDrawer } from 'Services/Drawer';

import './index.scss';
import logError from 'App/babel/helpers/logError';
import ActionButton from './ActionButton';
import NoAnswerActions from './ActivityOutcomesActions/NoAnswerActions';

const mapStateToProps = state => ({
	appointments: state.ActivityOutcomes.appointments
});

const mapDispatchToProps = {
	fetchAppointments
};

const SubmitButton = ({ clickHandler, content, disabled = false, iconName }) => {
	const [clicked, setClicked] = useState(false);

	return (
		<Button
			onClick={e => {
				clickHandler(e);
				setClicked(true);
			}}
			disabled={disabled}
			id="submit-btn"
			loading={clicked}
			size="lg"
		>
			<Text color={disabled ? 'grey-10' : 'white'}>
				<Icon name={iconName || 'check'} space="mrm"></Icon>
				{content}
			</Text>
		</Button>
	);
};

export class ActivityOutcomes extends Component {
	static getDerivedStateFromProps(props, state) {
		const activityId = get(props.activity, 'id');
		const date = get(props.activity, 'date', '');
		const contactId = get(props.contact, 'id');

		if (activityId !== state.activityId || contactId !== state.contactId) {
			return {
				type: null,
				outcome: null,
				mailTemplateId: null,
				reason: '',
				disqualify: false,
				postpone: null,
				submitted: false,
				closeActivity: false,
				showCustomDateTime: false,
				showAppointments: true,
				date,
				activityId,
				contactId
			};
		} else {
			return null;
		}
	}

	defaultState = {
		type: null,
		outcome: null,
		mailTemplateId: null,
		reason: '',
		commentId: null,
		commentDesc: '',
		disqualify: false,
		postpone: null,
		submitted: false,
		closeActivity: false,
		showCustomDateTime: false,
		showAppointments: true,
		opportunityAIResponse: null,
		markAsDoneButton: false,
		markAsDone: 0
	};

	constructor(props) {
		super(props);

		const t = Tools.$translate;
		const { date = '' } = props.activity || {};

		this.lang = {
			title: t('activity.outcome.title'),
			selectAction: t('activity.outcome.selectAction'),
			postponeMainText: t('activity.outcome.postponeMain'),
			postponeHoverText: t('activity.outcome.postponeHover'),
			postponeDate: t('activity.outcome.postponeDate'),
			customDate: t('activity.outcome.customDate'),
			clickToContinue: t('activity.outcome.clickToContinue'),
			postponeUntil: t('activity.outcome.postponeUntil'),
			postpone: t('activity.outcome.postpone'),
			dontPostpone: t('activity.outcome.dontPostpone'),
			dontPostponeDate: t('activity.outcome.dontPostponeDate'),
			didNotReachMain: t('activity.outcome.didNotReachMain'),
			reachedMain: t('activity.outcome.reachedMain'),
			reachedHover: t('activity.outcome.reachedHover'),
			saidNoThanks: t('activity.outcome.saidNoThanks'),
			sendEmail: t('activity.outcome.sendEmail'),
			talkToSomeone: t('activity.outcome.talkToSomeone'),
			howDidItGo: t('activity.outcome.howDidItGo'),
			stopTrying: t('activity.outcome.stopTrying'),
			selectOneTemplate: t('activity.outcome.selectOneTemplate'),
			noResults: t('default.noResults'),
			emailTemplate: t('default.emailTemplate'),
			postponeUntilTomorrow: t('activity.outcome.postponeUntilTomorrow'),
			editMail: t('groupMail.editMail'),
			contactDoesNotHaveEmail: t('activity.outcome.contactDoesNotHaveEmail'),
			emptyEmailTemplate: t('activity.outcome.emptyEmailTemplate'),
			closeActivityModal: t('activity.outcome.closeActivityModal'),
			stopTryingSubTitle: t('activity.outcome.stopTryingSubTitle'),
			disqualifyCompany: t('activity.outcome.disqualifyCompany'),
			stopTryingReason: t('activity.outcome.stopTryingReason'),
			stopTryingReasonPlaceholder: t('activity.outcome.stopTryingReasonPlaceholder'),
			bookAppointment: t('activity.outcome.bookAppointment'),
			bookAppointmentTooltip: Tools.FeatureHelper.hasSoftDeployAccess('REMOVE_ACTIVITIES')
				? t('activity.outcome.bookPhonecallTooltip')
				: t('activity.outcome.bookAppointmentTooltip'),
			createOpportunityTooltip: t('activity.outcome.createOpportunityTooltip'),
			createOrderTooltip: t('activity.outcome.createOrderTooltip'),
			planPhonecallTooltip: t('activity.outcome.planPhonecallTooltip'),
			itWentGreat: t('activity.outcome.itWentGreat'),
			createOpportunity: t('activity.outcome.createOpportunity'),
			createOrder: t('activity.outcome.createOrder'),
			planPhonecall: t('activity.outcome.planPhonecall'),
			closeActivity: t('close_activity'),
			disqualify: t('default.disqualify'),
			required: t('default.required'),
			close: t('close'),
			didNotFindLead: t('activity.outcome.didNotFindLead'),
			didNotFindLeadHover: t('activity.outcome.didNotFindLeadHover'),
			foundLead: t('activity.outcome.foundLead'),
			foundLeadHover: t('activity.outcome.foundLeadHover'),
			clickToDeselect: t('activity.outcome.clickToDeselect'),
			createNewActivity: t('activity.outcome.createNewActivity'),
			bringInfoToNewActivity: t('activity.outcome.bringInfoToNewActivity'),
			bookNewAppointment: t('activity.outcome.bookNewAppointment'),
			planACall: t('activity.outcome.planACall'),
			createATodo: t('activity.outcome.createATodo'),
			bringInfoToNewAppointment: t('activity.outcome.bringInfoToNewAppointment'),
			call: t('default.call'),
			setDate: t('activity.outcome.setDate'),
			setDateTo: t('activity.outcome.setDateTo'),
			gotoNext: t('activity.outcome.gotoNext'),
			didYouVerifyAnything: t('activity.outcome.didYouVerifyAnything'),
			timeFrame: t('activity.outcome.timeFrame'),
			closeDateHasPassed: t('activity.outcome.closeDateHasPassed'),
			budget: t('activity.outcome.budget'),
			solution: t('activity.outcome.solution'),
			markAsDone: t('activity.outcome.markAsDone'),
			didYouVerifyAnythingAbout: t('activity.outcome.didYouVerifyAnythingAbout'),
			risk: t('opportunity.risk'),
			timeframeLink: t('opportunity.timeframeLink'),
			changeCloseDate: t('change'),
			comment: t('default.comment'),
			reachedPlaceholder: t('activity.outcome.reachedPlaceholder'),
			closeActivityPlaceholder: t('activity.outcome.closeActivityPlaceholder'),
			reachedCommentLabel: t('activity.outcome.reachedCommentLabel')
		};

		this.state = {
			...this.defaultState,
			date,
			activityId: props.activity.id,
			contactId: get(props.contact, 'id'),
			showPopUp: false
		};

		const dayUnit = t('date.dayUnit');
		const monthUnit = t('date.monthUnit');

		this.presets = {
			currentDay: {
				title: t('activity.outcome.laterToday'),
				getDate: () => moment().startOf('day').toDate()
			},
			nextDay: this.nextDay,
			next7days: {
				title: `7 ${dayUnit}`,
				getDate: () => moment().add(7, 'day').startOf('day').toDate()
			},
			next30days: {
				title: `30 ${dayUnit}`,
				getDate: () => moment().add(30, 'days').startOf('day').toDate()
			},
			next60days: {
				title: `60 ${dayUnit}`,
				getDate: () => moment().add(60, 'days').startOf('day').toDate()
			},
			next90days: {
				title: `90 ${dayUnit}`,
				getDate: () => moment().add(90, 'days').startOf('day').toDate()
			},
			nextWeek: {
				title: t('date.nextWeek'),
				getDate: () => moment().add(1, 'week').startOf('day').toDate()
			},
			nextMonth: {
				title: t('date.nextMonth'),
				getDate: () => moment().add(1, 'month').startOf('day').toDate()
			},
			next6Month: {
				title: `6 ${monthUnit}`,
				getDate: () => moment().add(6, 'month').startOf('day').toDate()
			},
			next12Month: {
				title: `12 ${monthUnit}`,
				getDate: () => moment().add(12, 'month').startOf('day').toDate()
			},
			next24Month: {
				title: `24 ${monthUnit}`,
				getDate: () => moment().add(24, 'month').startOf('day').toDate()
			}
		};
		this.hasTodos = Tools.FeatureHelper.hasSoftDeployAccess('TODO_LIST');
	}

	componentDidMount() {
		this.props.fetchAppointments(this.props.activity);
	}

	componentDidUpdate(prevProps) {
		const { contact } = this.props;

		if (
			(contact && contact.id !== get(prevProps.contact, 'id')) ||
			prevProps.activity.id !== this.props.activity.id
		) {
			this.props.fetchAppointments(this.props.activity);
		}
	}

	componentWillUnmount() {
		if (this.timeout) {
			clearTimeout(this.timeout);
		}
	}

	get initialTypes() {
		const {
			activity: { id }
		} = this.props;
		const postponeType = id ? OUTCOME_TYPES.POSTPONE : SET_DATE;

		return [
			{
				code: OUTCOME_TYPES.NO_ANSWER,
				mainText: this.getMainText(OUTCOME_TYPES.NO_ANSWER),
				hoverText: this.getHoverText(OUTCOME_TYPES.NO_ANSWER),
				activeElement: this.getActiveText(OUTCOME_TYPES.NO_ANSWER),
				disabled: this.props.disabled || this.state.submitted,
				onClick: () => {
					this.setOutcomeState({ type: OUTCOME_TYPES.NO_ANSWER, outcome: NO_ANSWER_ACTIONS.POSTPONE_DATE });
					this.props.onChange({ type: OUTCOME_TYPES.NO_ANSWER, outcome: NO_ANSWER_ACTIONS.POSTPONE_DATE });
					this.onPresetChange(DEFAULT_PRESET);
				}
			},
			{
				code: OUTCOME_TYPES.ANSWER,
				mainText: this.getMainText(OUTCOME_TYPES.ANSWER),
				hoverText: this.getHoverText(OUTCOME_TYPES.ANSWER),
				activeElement: this.getActiveText(OUTCOME_TYPES.ANSWER),
				disabled: this.isSendMailDisabled(OUTCOME_TYPES.ANSWER),
				onClick: () => {
					this.setOutcomeState({ type: OUTCOME_TYPES.ANSWER });
					this.onPresetChange(null);
					this.props.onChange({ type: OUTCOME_TYPES.ANSWER, outcome: null });
				}
			},
			{
				code: postponeType,
				mainText: this.getMainText(postponeType),
				hoverText: this.getHoverText(OUTCOME_TYPES.POSTPONE),
				activeElement: this.getActiveText(postponeType),
				disabled: this.props.disabled || this.state.submitted,
				onClick: () => {
					this.setOutcomeState({ type: postponeType, outcome: POSTPONED });
					this.onPresetChange(DEFAULT_PRESET);
					this.props.onChange({ type: postponeType, outcome: POSTPONED });
				}
			}
		];
	}

	get leadTypes() {
		return [
			{
				code: LEAD_TYPES.NO_LEAD,
				mainText: this.getMainText(LEAD_TYPES.NO_LEAD),
				activeElement: this.getActiveText(LEAD_TYPES.NO_LEAD),
				hoverText: (
					<Text size="sm" color="grey-11">
						{this.lang.didNotFindLeadHover}
					</Text>
				),
				onClick: () => {
					this.setOutcomeState({ type: LEAD_TYPES.NO_LEAD, outcome: NO_LEAD_ACTIONS.POSTPONE_DATE });
					this.props.onChange({ type: LEAD_TYPES.NO_LEAD, outcome: NO_LEAD_ACTIONS.POSTPONE_DATE });
					this.onPresetChange(DEFAULT_PRESET);
				},
				disabled: this.props.disabled
			},
			{
				code: LEAD_TYPES.FOUND_LEAD,
				mainText: this.getMainText(LEAD_TYPES.FOUND_LEAD),
				activeElement: this.getActiveText(LEAD_TYPES.FOUND_LEAD),
				hoverText: (
					<Text size="sm" color="grey-11">
						{this.lang.foundLeadHover}
					</Text>
				),
				onClick: () => {
					this.setOutcomeState({ type: LEAD_TYPES.FOUND_LEAD, outcome: FOUND_LEAD_ACTIONS.ADDED_CONTACT });
					this.props.onChange({
						type: LEAD_TYPES.FOUND_LEAD,
						outcome: FOUND_LEAD_ACTIONS.ADDED_CONTACT,
						commentDesc: this.state.commentDesc
					});
					this.onPresetChange(null);
					//creation of comment needs to be handled in props.changeContact
					this.props.changeContact();
				},
				disabled: this.props.disabled
			}
		];
	}

	get followUpActivityTypes() {
		return [
			{
				code: FOLLOWUP_TYPES.NEW_ACTIVITY,
				mainText: (
					<Fragment>
						<Icon name="activity" />
						<Text>{this.lang.createNewActivity}</Text>
					</Fragment>
				),
				hoverText: (
					<Text size="sm" color="grey-11">
						{this.lang.bringInfoToNewActivity}
					</Text>
				),
				onClick: () => {
					this.props.createActivityFollowUp();
				},
				disabled: this.props.disabled
			},
			{
				code: FOLLOWUP_TYPES.BOOK_APPOINTMENT,
				mainText: (
					<Fragment>
						<Icon name="calendar" />
						<Text>{this.lang.bookNewAppointment}</Text>
					</Fragment>
				),
				activeElement: this.getActiveText(LEAD_TYPES.FOUND_LEAD),
				hoverText: (
					<Text size="sm" color="grey-11">
						{this.lang.bringInfoToNewAppointment}
					</Text>
				),
				onClick: () => {
					this.props.createActivityFollowUp(true);
				},
				disabled: this.props.disabled
			}
		];
	}

	get followUpTaskTypes() {
		return [
			{
				code: FOLLOWUP_TYPES.CREATE_TODO,
				mainText: (
					<Fragment>
						<Icon name="activity" />
						<Text>{this.lang.createATodo}</Text>
					</Fragment>
				),
				hoverText: (
					<Text size="sm" color="grey-11">
						{this.lang.bringInfoToNewActivity}
					</Text>
				),
				onClick: () => {
					this.props.createTodoFollowUp();
				},
				disabled: this.props.disabled
			},
			{
				code: FOLLOWUP_TYPES.BOOK_APPOINTMENT,
				mainText: (
					<Fragment>
						<Icon name="calendar" />
						<Text>{this.lang.bookNewAppointment}</Text>
					</Fragment>
				),
				hoverText: (
					<Text size="sm" color="grey-11">
						{this.lang.bringInfoToNewAppointment}
					</Text>
				),
				onClick: () => {
					this.props.createActivityFollowUp(true);
				},
				disabled: this.props.disabled
			},
			{
				code: FOLLOWUP_TYPES.PLAN_PHONE_CALL,
				mainText: (
					<Fragment>
						<Icon name="phone" />
						<Text>{this.lang.planACall}</Text>
					</Fragment>
				),
				hoverText: (
					<Text size="sm" color="grey-11">
						{this.lang.bringInfoToNewAppointment}
					</Text>
				),
				onClick: () => {
					this.props.createCallFollowUp();
				},
				disabled: this.props.disabled
			}
		];
	}

	get noNoLeadActions() {
		const { type } = this.state;

		return [
			{
				code: NO_LEAD_ACTIONS.POSTPONE_DATE,
				title: this.lang.postponeDate,
				icon: 'calendar-arrow-right-o',
				onClick: () => {
					this.toggleOutcomeState({
						type,
						outcome: NO_ANSWER_ACTIONS.POSTPONE_DATE,
						postpone: DEFAULT_PRESET
					});
				},
				disabled: this.props.disabled || this.state.submitted
			},
			{
				code: NO_LEAD_ACTIONS.STOP_TRYING,
				title: this.lang.stopTrying,
				icon: 'times-circle',
				onClick: () => {
					this.toggleOutcomeState({
						type,
						outcome: NO_ANSWER_ACTIONS.STOP_TRYING,
						preset: null,
						closeActivity: true
					});
				},
				disabled: this.props.disabled
			}
		];
	}

	get answerActions() {
		const name = this.contactName;
		const t = Tools.$translate;
		const { contact } = this.props;
		const hasEmail = !!get(contact, 'email');
		const { outcome, type } = this.state;
		const { activity } = this.props;

		const buttons = [
			{
				code: ANSWER_ACTIONS.BUSY,
				title: t('activity.outcome.contactWasBusy', { name }),
				icon: 'calendar-arrow-right-o',
				onClick: () => {
					this.toggleOutcomeState({ type, outcome: ANSWER_ACTIONS.BUSY, postpone: DEFAULT_PRESET });
				},
				disabled: this.props.disabled || this.state.submitted
			},
			{
				code: ANSWER_ACTIONS.STOP_TRYING,
				title: this.lang.stopTrying,
				icon: 'times-circle',
				onClick: () => {
					this.toggleOutcomeState({
						type,
						outcome: ANSWER_ACTIONS.STOP_TRYING,
						closeActivity: true,
						postpone: null
					});
				},
				disabled: this.props.disabled || this.state.submitted
			},
			{
				code: ANSWER_ACTIONS.TALK_TO_SOMEONE,
				title: this.lang.talkToSomeone,
				icon: 'user-plus',
				onClick: () => {
					this.toggleOutcomeState({
						type,
						outcome: ANSWER_ACTIONS.TALK_TO_SOMEONE,
						postpone: null,
						commentDesc: this.state.commentDesc
					});
					this.props.changeContact();
				},
				disabled: this.props.disabled || this.state.submitted
			},
			{
				code: ANSWER_ACTIONS.SEND_MAIL,
				title: this.lang.sendEmail,
				icon: 'envelope',
				onClick: () => {
					this.openSendMailModal(contact);
				},
				tooltip: this.lang.contactDoesNotHaveEmail,
				showTooltip:
					this.props.disabled || (outcome !== ANSWER_ACTIONS.SEND_MAIL && !hasEmail) || this.state.submitted,
				disabled:
					this.props.disabled || (outcome !== ANSWER_ACTIONS.SEND_MAIL && !hasEmail) || this.state.submitted
			}
		];

		if (activity.opportunity) {
			buttons.push({
				code: ANSWER_ACTIONS_GREAT.BOOK_APPOINTMENT,
				title: this.lang.bookAppointment,
				icon: 'calendar',
				onClick: () => {
					this.setOutcomeState({ type });
					this.props.bookAppointment(this.state.commentDesc);
				},
				tooltip: this.lang.bookAppointmentTooltip,
				showTooltip: true,
				tooltipPosition: 'bottom',
				disabled: this.props.disabled
			});

			if (this.props.planPhonecall && typeof this.props.planPhonecall == 'function' && this.hasTodos) {
				buttons.push({
					code: ANSWER_ACTIONS_GREAT.PLAN_PHONE_CALL,
					title: this.lang.planPhonecall,
					icon: 'phone',
					onClick: () => {
						this.setOutcomeState({ type });
						this.props.planPhonecall(this.state.commentDesc);
					},
					tooltip: this.lang.planPhonecallTooltip,
					showTooltip: true,
					tooltipPosition: 'bottom',
					disabled: this.props.disabled
				});
			}
		}

		return buttons;
	}

	get answerActionsGreat() {
		const { type } = this.state;

		const greatActionsAnswers = [
			{
				code: ANSWER_ACTIONS_GREAT.BOOK_APPOINTMENT,
				title: this.lang.bookAppointment,
				icon: 'calendar',
				onClick: () => {
					this.setOutcomeState({ type });
					this.props.bookAppointment(this.state.commentDesc);
				},
				tooltip: this.lang.bookAppointmentTooltip,
				showTooltip: true,
				tooltipPosition: 'bottom',
				disabled: this.props.disabled
			},
			{
				code: ANSWER_ACTIONS_GREAT.CREATE_OPPORTUNITY,
				title: this.lang.createOpportunity,
				icon: 'opportunity',
				onClick: () => {
					this.setOutcomeState({ type });
					this.props.createOpportunity(this.state.commentDesc);
				},
				tooltip: this.lang.createOpportunityTooltip,
				showTooltip: true,
				tooltipPosition: 'bottom',
				disabled: this.props.disabled
			},
			{
				code: ANSWER_ACTIONS_GREAT.CREATE_ORDER,
				title: this.lang.createOrder,
				icon: 'sales',
				onClick: () => {
					this.setOutcomeState({ type });
					this.props.createOrder(this.state.commentDesc);
				},
				tooltip: this.lang.createOrderTooltip,
				showTooltip: true,
				tooltipPosition: 'bottom',
				disabled: this.props.disabled
			}
		];

		if (this.props.planPhonecall && typeof this.props.planPhonecall == 'function' && this.hasTodos) {
			greatActionsAnswers.push({
				code: ANSWER_ACTIONS_GREAT.PLAN_PHONE_CALL,
				title: this.lang.planPhonecall,
				icon: 'phone',
				onClick: () => {
					this.setOutcomeState({ type });
					this.props.planPhonecall(this.state.commentDesc);
				},
				tooltip: this.lang.planPhonecallTooltip,
				showTooltip: true,
				tooltipPosition: 'bottom',
				disabled: this.props.disabled
			});
		}

		return greatActionsAnswers;
	}

	get contactName() {
		const { contact } = this.props;
		const hasNewFieldsAccess = Tools.FeatureHelper.hasSoftDeployAccess('NEW_FIELDS');

		return hasNewFieldsAccess ? get(contact, 'firstName', '') : get(contact, 'name', '').split(' ')[0];
	}

	get nextDay() {
		const t = Tools.$translate;
		const isWeekend = [5, 6].includes(moment().get('day')); // 5 - Friday, 6 - Saturday;
		const date = isWeekend ? moment().day(8) : moment().add(1, 'day'); // next Monday or plus one day

		return {
			title: t(isWeekend ? 'default.monday' : 'date.nextDay'),
			getDate: () => date.startOf('day').toDate()
		};
	}

	isSendMailDisabled(outcomeType) {
		const { contact } = this.props;
		const { outcome, mailTemplateId, type } = this.state;
		const email = get(contact, 'email');
		const isMail = (!outcomeType || outcomeType === type) && outcome === ANSWER_ACTIONS.SEND_MAIL;

		return this.props.disabled || (isMail && (!email || !mailTemplateId)) || this.state.submitted;
	}
	createCommentIfDesc = async cb => {
		let comment;
		if (this.state.commentDesc) {
			({ data: comment } = await Comment.save({
				description: this.state.commentDesc,
				client: this.props.contact?.client || this.props.client || this.props.company,
				activity: this.props.activity,
				user: Tools.AppService.getSelf(),
				outcomeType: this.state.type
			}));
		}

		if (comment) {
			this.setState({ commentId: comment.id }, () => {
				this.outcomeChange(cb);
			});
		} else {
			cb();
		}
	};
	confirm = async event => {
		if (event) {
			event.preventDefault();
			event.stopPropagation();
		}
		// if commentvalue exist then create it
		await this.createCommentIfDesc(this.props.confirm);
	};

	saveComment = async () => {
		if (!this.state.commentDesc.length) return;

		if (this.state.outcome) {
			await this.confirm();
		} else {
			Tools.NotificationService.addNotification({
				icon: 'times',
				style: Tools.NotificationService.style.ERROR,
				title: 'activity.outcome.couldNotComment',
				body: 'activity.outcome.couldNotComment.body'
			});
		}
	};

	getMainText(code) {
		const name = this.contactName;

		switch (code) {
			case OUTCOME_TYPES.NO_ANSWER:
				return (
					<Fragment>
						<Icon name="phone-fail" color="red" />
						<Text>{`${this.lang.didNotReachMain} ${name}`}</Text>
					</Fragment>
				);
			case OUTCOME_TYPES.ANSWER:
				return (
					<Fragment>
						<Icon name="phone-success" color="medium-green" />
						<Text>{`${this.lang.reachedMain} ${name}`}</Text>
					</Fragment>
				);
			case OUTCOME_TYPES.POSTPONE:
				return (
					<Fragment>
						<Icon name="calendar-arrow-right-o" color="grey-10" />
						<Text>{this.lang.postponeMainText}</Text>
					</Fragment>
				);
			case SET_DATE:
				return (
					<Fragment>
						<Icon name="calendar" color="grey-10" />
						<Text>{this.lang.setDate}</Text>
					</Fragment>
				);

			case LEAD_TYPES.NO_LEAD:
				return (
					<Fragment>
						<Icon name="user-times" color="red" />
						<Text>{this.lang.didNotFindLead}</Text>
					</Fragment>
				);
			case LEAD_TYPES.FOUND_LEAD:
				return (
					<Fragment>
						<Icon name="user-plus" color="medium-green" />
						<Text>{this.lang.foundLead}</Text>
					</Fragment>
				);
		}
	}

	getHoverText(code) {
		const t = Tools.$translate;
		const name = this.contactName;

		switch (code) {
			case OUTCOME_TYPES.NO_ANSWER:
				return (
					<Text
						size="sm"
						color="grey-11"
						dangerouslySetInnerHTML={{
							__html: t('activity.outcome.didNotReachHover', {
								name: `<span class="text-ellipses">${name}</span>`
							})
						}}
					/>
				);
			case OUTCOME_TYPES.ANSWER:
				return (
					<Text
						size="sm"
						color="grey-11"
						dangerouslySetInnerHTML={{
							__html: `${this.lang.reachedHover} <br /> <span class="text-ellipses">${name}</span>`
						}}
					/>
				);
			case OUTCOME_TYPES.POSTPONE:
				return (
					<Text size="sm" color="grey-11">
						{this.lang.postponeHoverText}
					</Text>
				);
		}
	}

	getPostponeText() {
		const { date, time } = this.props.activity;
		const { showCustomDateTime, postpone, type } = this.state;
		const format = time ? 'L LT' : 'L';
		const formatedDate = moment(date).format(format);
		const text = type === SET_DATE ? this.lang.setDateTo : this.lang.postponeUntil;

		return postpone || showCustomDateTime ? `${text}: ${formatedDate}` : this.lang.dontPostponeDate;
	}

	getActiveText(code) {
		const { contact, disabledOutcome, disabled, disabledTooltip } = this.props;
		const { outcome, mailTemplateId } = this.state;
		let clickHandler = this.confirm;
		const email = get(contact, 'email');
		let toolTipTitle;

		toolTipTitle = disabledTooltip ?? null;

		switch (code) {
			case LEAD_TYPES.NO_LEAD:
			case OUTCOME_TYPES.NO_ANSWER:
			case OUTCOME_TYPES.ANSWER: {
				let content;

				if (!outcome) {
					return <SubmitButton disabled content={this.lang.selectAction} />;
				}

				switch (outcome) {
					case ANSWER_ACTIONS.SEND_MAIL:
						toolTipTitle = !email
							? this.lang.contactDoesNotHaveEmail
							: !mailTemplateId
							? this.lang.emptyEmailTemplate
							: null;
						clickHandler = this.sendEmailAndConfirm;
						content = `${this.lang.sendEmail} ${
							this.state.postpone ? `& ${this.lang.postponeDate.toLowerCase()}` : ''
						}`;
						break;
					case NO_ANSWER_ACTIONS.STOP_TRYING:
					case ANSWER_ACTIONS.STOP_TRYING:
						clickHandler = this.confirm;
						content = `${
							this.state.disqualify
								? `${this.lang.disqualify} & ${this.lang.close.toLowerCase()}`
								: this.lang.closeActivity
						}
								& ${this.lang.closeActivityModal}`;
						break;

					default:
						content = `${this.lang.postpone} & ${this.lang.gotoNext}`;
				}

				let wrapper = (
					<SubmitButton
						disabled={disabledOutcome || disabled}
						clickHandler={clickHandler}
						content={content}
					/>
				);

				if (toolTipTitle) {
					wrapper = (
						<Tooltip title={toolTipTitle} position="bottom">
							{wrapper}
						</Tooltip>
					);
				}

				return wrapper;
			}
			case OUTCOME_TYPES.POSTPONE:
			case SET_DATE:
				return (
					<SubmitButton
						clickHandler={this.confirm}
						content={`${this.lang.postpone} & ${this.lang.gotoNext}`}
					/>
				);
			case LEAD_TYPES.FOUND_LEAD:
				return <SubmitButton disabled iconName="user-plus" content={this.lang.foundLead} />;
		}
	}

	onPresetChange = postpone => {
		this.setState({ postpone, showCustomDateTime: false });
		if (!postpone) {
			return;
		}
		const presetDate = this.presets[postpone];
		if (presetDate) {
			if (this.props.activity.time) {
				const dateMoment = moment(this.props.activity.date);
				const presetMoment = moment(presetDate.getDate());
				presetMoment.set('hours', dateMoment.get('hours')).set('minutes', dateMoment.get('minutes'));
				this.props.onPresetDateChange(presetMoment.toDate());
			} else {
				this.props.onPresetDateChange(presetDate.getDate());
			}
		} else {
			this.props.onPresetDateChange(this.state.date);
		}
	};

	onMailTemplateChange = mailTemplateId => {
		this.setState({
			mailTemplateId
		});
	};

	togglePostpone = () => {
		const { postpone } = this.state;
		const next = postpone ? null : DEFAULT_PRESET;
		this.onPresetChange(next);
	};

	sendEmailAndConfirm = event => {
		event.preventDefault();
		event.stopPropagation();

		this.sendMail();
	};

	sendMail = async () => {
		const { mailTemplateId, type, outcome } = this.state;
		const { contact } = this.props;
		const email = get(contact, 'email');
		const contactId = get(contact, 'id');
		const clientId = get(contact, 'client.id');
		if (!mailTemplateId || !email) return;

		this.setState({ submitted: true });

		try {
			const {
				data: { attachments, body, from, fromName, subject, id, name }
			} = await MailTemplate.get(mailTemplateId);
			const { data: mail } = await Tools.Mail.customer(Tools.AppService.getCustomerId()).save(
				{
					attachments,
					body,
					from,
					fromName,
					subject,
					date: new Date(),
					to: [email],
					client: clientId,
					contact: contactId,
					template: { id, name },
					doSend: true,
					isDraft: false,
					type: 'out'
				},
				{ skipEvent: true }
			);

			this.props.onChange({
				type,
				outcome,
				mailId: mail.id
			});
			this.confirm();
		} catch (e) {
			logError(e, 'Failed to send email from activity outcome');

			Tools.NotificationService.addNotification({
				icon: 'times',
				style: Tools.NotificationService.style.ERROR,
				title: 'activity.outcome.couldNotSendMAil',
				body: 'default.somethingWrong'
			});
		} finally {
			this.setState({ submitted: false });
		}
	};

	onCommentChange = value => {
		this.setState({ commentDesc: value });
	};

	onReasonCommentChange = ({ target: { value } }) => {
		this.setState(
			{
				reason: value
			},
			() => this.outcomeChange()
		);
	};

	onToggleChange = (name, checked) => {
		this.setState(
			{
				[name]: checked
			},
			() => this.outcomeChange()
		);
	};

	onDateChange = date => {
		this.setState({ postpone: null, showCustomDateTime: true });
		this.props.onDateChange(date);
	};

	onTimeChange = datetime => {
		this.setState({ showCustomDateTime: true });
		this.props.onTimeChange(datetime);
	};

	outcomeChange = cb => {
		const { type, outcome, reason, disqualify, closeActivity, noThanks, commentId, mailId } = this.state;
		this.props.onChange(
			{
				type,
				outcome: OUTCOME_BACKEND_ACTIONS[noThanks || outcome] || outcome,
				reason,
				disqualify,
				closeActivity,
				commentId,
				mailId
			},
			cb
		);
	};

	setOutcomeState = (state = {}) => {
		const {
			type,
			outcome,
			mailTemplateId,
			reason,
			disqualify,
			noThanks,
			closeActivity,
			showCustomDateTime,
			commentId
		} = this.defaultState;
		this.setState({
			type,
			outcome,
			mailTemplateId,
			reason,
			disqualify,
			noThanks,
			closeActivity,
			showCustomDateTime,
			showAppointments: false,
			commentId,
			...state
		});
	};

	toggleOutcomeState = (state = {}) => {
		const { type, outcome } = this.state;
		const toggleState = { ...state };

		if (type === state.type && outcome === state.outcome) {
			toggleState.outcome = null;
			toggleState.postpone = null;
		}

		this.setOutcomeState(toggleState);
		this.props.onChange({
			...toggleState,
			outcome: OUTCOME_BACKEND_ACTIONS[toggleState.outcome] || toggleState.outcome
		});

		if (state.outcome === NO_ANSWER_ACTIONS.STOP_TRYING) {
			// reset pendingDate to skip the date setting part (used for postpone) of the submit function (saveActivity).
			this.props.onPresetDateChange();
		}

		this.onPresetChange(toggleState.postpone);
	};

	openSendMailModal = contact => {
		if (!contact) {
			return;
		}

		if (Tools.FeatureHelper.hasSoftDeployAccess('NEW_MAIL')) {
			const { activityId, date } = this.state;
			const recipient = {
				id: contact.id,
				name: contact.name,
				client: contact.client,
				email: contact.email,
				type: 'contact'
			};
			openDrawer('NewSingleMail', {
				activity: activityId ? { id: activityId, date, description: '' } : undefined,
				recipients: { to: [recipient], cc: [], bcc: [] },
				source: this.state.type === 'not_reached' ? 'activity' : undefined,
				onSend: (mail, metadata) => {
					const { type } = this.state;

					if (metadata?.postponeDate) {
						this.onPresetChange(DEFAULT_PRESET);
					} else if (metadata?.postponeDate === false) {
						this.props.onPresetDateChange(undefined);
					}

					if (mail?.id) {
						this.setOutcomeState({
							type,
							outcome: NO_ANSWER_ACTIONS.SEND_MAIL,
							mailId: mail.id
						});

						this.props.onChange(
							{
								type,
								outcome: NO_ANSWER_ACTIONS.SEND_MAIL,
								mailId: mail.id
							},
							this.confirm
						);
					}
				}
			});
		} else {
			// eslint-disable-next-line promise/catch-or-return
			Tools.$upModal
				.open('sendEmail', {
					customerId: Tools.AppService.getCustomerId(),
					type: 'mail',
					fromActivity: true,
					contact: contact,
					contactId: contact.id
				})
				.then(({ id, postponeDate }) => {
					const { type } = this.state;

					if (postponeDate) {
						this.onPresetChange(DEFAULT_PRESET);
					}

					this.setOutcomeState({
						type,
						outcome: NO_ANSWER_ACTIONS.SEND_MAIL,
						mailId: id
					});
					this.props.onChange(
						{
							type,
							outcome: NO_ANSWER_ACTIONS.SEND_MAIL,
							mailId: id
						},
						this.confirm
					);
				});
		}
	};

	acceptAppointment = (id, notes = '') => {
		this.props.onChange({
			type: OUTCOME_TYPES.ANSWER,
			outcome: ANSWER_ACTIONS_GREAT.BOOK_APPOINTMENT
		});

		this.props.setAppointment(id, notes);
	};

	setContact = contact => {
		this.props.onChange({ type: LEAD_TYPES.FOUND_LEAD, outcome: FOUND_LEAD_ACTIONS.ADDED_CONTACT });
		this.props.setContact(contact);
	};
	renderCommentInput = classes => {
		const getCommentPlaceHolder = () => {
			let placeholder = this.lang.stopTryingReasonPlaceholder;
			if (this.state.closeActivity) {
				placeholder = this.lang.closeActivityPlaceholder;
			} else if (this.state.type === OUTCOME_TYPES.ANSWER) {
				placeholder = this.lang.reachedPlaceholder;
			}

			return placeholder;
		};
		const commentLabel =
			this.state.type === OUTCOME_TYPES.ANSWER ? this.lang.reachedCommentLabel : this.lang.comment;
		return (
			<div className={classes.elem('comment-section').b()}>
				<CommentInput
					commentLabel={commentLabel}
					saveComment={this.saveComment}
					placeholder={getCommentPlaceHolder()}
					onChange={this.onCommentChange}
					value={this.state.commentDesc}
				/>
			</div>
		);
	};

	renderActions() {
		const { type } = this.state;
		let content;

		switch (type) {
			case OUTCOME_TYPES.NO_ANSWER:
				content = (
					<NoAnswerActions
						outcome={this.state.outcome}
						contact={this.props.contact}
						disabled={this.props.disabled}
						submitted={this.state.submitted}
						toggleOutcomeState={this.toggleOutcomeState}
						setOutcomeState={this.setOutcomeState}
						openSendMailModal={this.openSendMailModal}
						renderPostpone={this.renderPostpone.bind(this)}
						renderStopTrying={this.renderStopTrying.bind(this)}
						changeContact={this.props.changeContact}
					/>
				);
				break;
			case OUTCOME_TYPES.POSTPONE:
			case SET_DATE:
				content = this.renderPostpone(DEFAULT_PRESETS);
				break;
			case OUTCOME_TYPES.ANSWER:
				content = <React.Fragment>{this.renderAnswerActions()}</React.Fragment>;
				break;
			case LEAD_TYPES.NO_LEAD:
				content = this.renderNoLeadActions();
				break;
			default:
				content = <div />;
		}

		return content;
	}

	renderPostpone(presetNames) {
		const { postpone } = this.state;
		const { disabled, dateAnchor, dateScrollContainer, pendingDate, hasPendingTime } = this.props;
		const { date, time } = this.props.activity;
		const presets = presetNames.map(value => ({ title: this.presets[value].title, value }));
		const outcomeDate = date ? new Date(date) : null;

		return (
			<Fragment>
				<OutcomePostponeDate
					disabled={disabled}
					presets={presets}
					presetValue={postpone}
					onPresetChange={this.onPresetChange}
					date={pendingDate || outcomeDate}
					showTime={pendingDate ? !!hasPendingTime : !!time}
					onDateChange={this.onDateChange}
					onTimeChange={this.onTimeChange}
					dateAnchor={dateAnchor}
					dateScrollContainer={dateScrollContainer}
				/>
				<Button size="sm" type="link" className="no-padding hidden" onClick={() => this.onPresetChange(null)}>
					{this.lang.dontPostpone}
				</Button>
			</Fragment>
		);
	}

	renderStopTrying() {
		const { disqualify, closeActivity } = this.state;
		const { activity } = this.props;

		const isDisqualifiable = activity.client ? statusDisqualifiable(activity.client.journeyStep) : false;

		return (
			<div className="mb-10">
				<Title size="md" className="no-margin hidden">
					{this.lang.stopTrying}
				</Title>
				<Text color="grey-10" className="mb-10 hidden">
					{this.lang.stopTryingSubTitle}
				</Text>
				<Label className="mb-10 pt-10" style={{ display: 'flex', alignItems: 'center' }}>
					<Toggle
						disabled
						size="lg"
						checked={closeActivity}
						onChange={checked => this.onToggleChange('closeActivity', checked)}
					/>
					<Text className="ml-10 mr-10">
						{this.lang.closeActivity} {'('}
						{this.lang.required.toLowerCase()}
						{')'}
					</Text>
					<ReactTemplates.elevio articleId={858} sidebar>
						<Icon name="info-circle" />
					</ReactTemplates.elevio>
				</Label>
				{isDisqualifiable ? (
					<Label className="mb-10" style={{ display: 'flex', alignItems: 'center' }}>
						<Toggle
							size="lg"
							checked={disqualify}
							onChange={checked => this.onToggleChange('disqualify', checked)}
						/>
						<Text className="ml-10 mr-10">{this.lang.disqualifyCompany}</Text>
						<ReactTemplates.elevio articleId={982} sidebar>
							<Icon name="info-circle" />
						</ReactTemplates.elevio>
					</Label>
				) : null}
			</div>
		);
	}

	renderNoLeadActions() {
		const { outcome } = this.state;

		return (
			<div>
				{this.noNoLeadActions.map(action => (
					<ActionButton
						key={action.code}
						action={action}
						outcome={outcome}
						isSelected={outcome === action.code}
					/>
				))}
				{outcome === NO_ANSWER_ACTIONS.POSTPONE_DATE ? this.renderPostpone(DEFAULT_PRESETS) : null}
				{outcome === NO_ANSWER_ACTIONS.STOP_TRYING ? this.renderStopTrying() : null}
			</div>
		);
	}

	flipCheckboxes = attribute => {
		const xorMarkAsDone = attribute ^ this.state.markAsDone;
		this.setState({ markAsDoneButton: xorMarkAsDone > 0, markAsDone: xorMarkAsDone });
	};

	changeCloseDate = async obj => {
		const { activity } = this.props;
		const { Order } = Tools;

		const opportunity = await Order.customer(Tools.AppService.getCustomerId())
			.get(activity.opportunity.id)
			.then(res => res.data);

		opportunity.date = obj.date;

		await Order.customer(Tools.AppService.getCustomerId())
			.save(opportunity)
			.then(() =>
				this.setState({ opportunityAIResponse: { ...this.state.opportunityAIResponse, notPassedDate: true } })
			);
	};

	updateOpportunity = async event => {
		const { markAsDone } = this.state;
		const { activity } = this.props;
		const { Order } = Tools;

		const opportunity = await Order.customer(Tools.AppService.getCustomerId())
			.get(activity.opportunity.id)
			.then(res => res.data);

		const bitString = markAsDone.toString(2).padStart(3, '0');
		if (bitString[2] === '1') {
			opportunity.confirmedDate = opportunity.date;
		}
		if (bitString[1] === '1') {
			opportunity.confirmedBudget = opportunity.value;
		}
		if (bitString[0] === '1') {
			opportunity.confirmedSolution = true;
		}

		await Order.customer(Tools.AppService.getCustomerId()).save(opportunity);
		this.toggleOutcomeState({
			type: OUTCOME_TYPES.ANSWER,
			outcome: ANSWER_ACTIONS.STOP_TRYING,
			closeActivity: true,
			postpone: null
		});
		this.confirm(event);
	};

	renderAnswerActions() {
		const { outcome } = this.state;
		const { activity } = this.props;

		return (
			<div>
				<Title size="md">{this.lang.howDidItGo}</Title>
				{this.answerActions.map(action => (
					<ActionButton
						key={action.code}
						action={action}
						outcome={this.state.outcome}
						isSelected={outcome === action.code}
					/>
				))}
				{!outcome && !activity.opportunity ? (
					<Fragment>
						<Title size="md">{this.lang.itWentGreat}</Title>
						{this.answerActionsGreat.map(action => (
							<ActionButton
								key={action.code}
								action={action}
								outcome={this.state.outcome}
								isSelected={outcome === action.code}
							/>
						))}
					</Fragment>
				) : null}
				{outcome === ANSWER_ACTIONS.STOP_TRYING ? this.renderStopTrying() : null}
			</div>
		);
	}

	renderSubActions() {
		const classNames = new BemClass('ActivityOutcomes');
		const { outcome } = this.state;
		let subActionContent = null;
		let subActionTitle = null;

		switch (outcome) {
			case ANSWER_ACTIONS.BUSY:
				subActionTitle = this.answerActions[0].title;
				subActionContent = (
					<div className={classNames.elem('sub-action').b()}>{this.renderPostpone(DEFAULT_PRESETS)}</div>
				);
				break;
			default:
				return <div />;
		}

		return (
			<Card className={classNames.elem('front')}>
				<CardHeader title={subActionTitle} className="hidden" />
				<CardContent className="pt-10">{subActionContent}</CardContent>
			</Card>
		);
	}

	renderAppointments() {
		const { appointments = [], activity } = this.props;
		const { showAppointments } = this.state;

		if (!showAppointments || appointments.length < 1 || !!activity.closeDate) return <div />;

		return (
			<PlannedAppointments
				appointments={appointments}
				contactName={this.contactName}
				accept={this.acceptAppointment}
			/>
		);
	}

	render() {
		const t = Tools.$translate;
		const { activity, contact, appointments, company, contacts, pendingOutcome, disabledTooltip, disabledOutcome } =
			this.props;
		if (!activity || !activity.client) return null;

		const classNames = new BemClass('ActivityOutcomes');
		const { type, outcome, showAppointments } = this.state;
		const shouldRenderAppointments =
			!activity.closeDate && showAppointments && Array.isArray(appointments) && appointments.length;
		const closedDateButtonTypes =
			Tools.FeatureHelper.hasSoftDeployAccess('REMOVE_ACTIVITIES') &&
			Tools.FeatureHelper.hasSoftDeployAccess('TODO_LIST')
				? this.followUpTaskTypes
				: this.followUpActivityTypes;

		const currentEntityText = Tools.FeatureHelper.hasSoftDeployAccess('TODO_LIST')
			? t('activity.outcome.followUpTitlePhonecall')
			: t('activity.outcome.followUpTitleActivity');

		const topButtons = activity.closeDate ? closedDateButtonTypes : contact ? this.initialTypes : this.leadTypes;
		const title = activity.closeDate
			? currentEntityText
			: contact
			? this.lang.title
			: `${this.lang.call} ${activity.client.name}`;
		const buttonLength = topButtons.length;

		const currentBtn = topButtons.find(b => b.code === type);

		return (
			<div className={classNames.b()}>
				<Card className={classNames.elem('front')}>
					<SlideFade visible={currentBtn} direction="top" height maxHeight={50}>
						<div
							className={classNames
								.elem('active-header', 'padded-section')
								.mod({ visible: !!currentBtn })
								.b()}
							onClick={() => {
								this.setOutcomeState({ type: null, outcome: null });
								this.props.onChange({ type: null, outcome: null });
								this.onPresetChange(DEFAULT_PRESET);
							}}
						>
							<Tooltip title="">
								<Button id="back-btn" size="sm" type="link" color="grey">
									<Icon name="chevron-left"></Icon>
								</Button>
							</Tooltip>

							{currentBtn?.mainText}
						</div>
					</SlideFade>

					{!currentBtn ? (
						<CardHeader title={title} className={classNames.elem('main-header').b()}>
							<ReactTemplates.elevio articleId={1120} sidebar>
								<Icon name="info-circle" />
							</ReactTemplates.elevio>
						</CardHeader>
					) : null}

					<CardContent className={classNames.elem('outcome-types-holder')}>
						<div className={classNames.elem('outcome-types', `grid-${buttonLength}`)}>
							{!type
								? topButtons.map(({ code, mainText, hoverText, disabled, onClick }) => (
										<OutcomeTypeButton
											key={code}
											mainText={mainText}
											hoverText={hoverText}
											disabled={disabled || disabledOutcome}
											disabledTooltip={disabledTooltip}
											pendingOutcome={pendingOutcome}
											onClick={onClick}
										/>
								  ))
								: null}
						</div>

						<div className={classNames.elem('action-row').mod({ visible: !!type }).b()}>
							<SlideFade key="comment" visible={!!type} bounce speed="slow">
								{Tools.FeatureHelper.hasSoftDeployAccess('TODO_LIST') && type !== LEAD_TYPES.FOUND_LEAD
									? this.renderCommentInput(classNames)
									: null}
							</SlideFade>
							<SlideFade key={type} visible={!!type} bounce speed="slow">
								<div>{this.renderActions()}</div>
							</SlideFade>
						</div>
					</CardContent>
				</Card>

				<SlideFade key={outcome} visible={!!outcome}>
					{this.renderSubActions()}
				</SlideFade>

				<SlideFade key="appointments" visible={!!shouldRenderAppointments}>
					{this.renderAppointments()}
				</SlideFade>

				<SlideFade key="submit" visible={currentBtn} height maxHeight={60}>
					<div className={classNames.elem('submit-btn', 'padded-section').b()}>
						{currentBtn?.activeElement}
					</div>
				</SlideFade>
				{!!activity.client && !contact ? (
					<Fragment key="TopContacts">
						<TopContacts
							client={activity.client}
							setContact={this.setContact}
							openMail={this.openSendMailModal}
							contacts={contacts}
						/>
						{company ? (
							<WebLinks
								name={company.name}
								webPage={company.webpage}
								facebook={company.facebook}
								twitter={company.twitter}
								linkedIn={company.linkedin}
							/>
						) : null}
					</Fragment>
				) : null}
			</div>
		);
	}
}

ActivityOutcomes.propTypes = {
	activity: PropTypes.shape({
		id: PropTypes.number,
		client: PropTypes.shape({
			id: PropTypes.number
		}),
		date: PropTypes.oneOfType([PropTypes.string, PropTypes.instanceOf(Date)]),
		time: PropTypes.oneOfType([PropTypes.string, PropTypes.instanceOf(Date)])
	}),
	contact: PropTypes.shape({
		id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
		name: PropTypes.string,
		firstName: PropTypes.string,
		email: PropTypes.string,
		client: PropTypes.object
	}),
	client: PropTypes.object,
	company: PropTypes.shape({
		name: PropTypes.string,
		facebook: PropTypes.string,
		twitter: PropTypes.string,
		linkedin: PropTypes.string
	}),
	appointments: PropTypes.arrayOf(
		PropTypes.shape({
			id: PropTypes.number,
			description: PropTypes.string,
			date: PropTypes.oneOfType([PropTypes.string, PropTypes.instanceOf(Date)]),
			users: PropTypes.arrayOf(
				PropTypes.shape({
					id: PropTypes.number,
					name: PropTypes.string
				})
			),
			contacts: PropTypes.arrayOf(
				PropTypes.shape({
					id: PropTypes.number,
					name: PropTypes.string
				})
			)
		})
	),
	onChange: PropTypes.func,
	onPresetDateChange: PropTypes.func,
	onDateChange: PropTypes.func,
	onTimeChange: PropTypes.func,
	confirm: PropTypes.func,
	disabled: PropTypes.bool,
	bookAppointment: PropTypes.func,
	createActivityFollowUp: PropTypes.func,
	createTodoFollowUp: PropTypes.func,
	createCallFollowUp: PropTypes.func,
	createOpportunity: PropTypes.func,
	createOrder: PropTypes.func,
	planPhonecall: PropTypes.func,
	changeContact: PropTypes.func,
	setAppointment: PropTypes.func,
	fetchAppointments: PropTypes.func,
	setContact: PropTypes.func,
	contacts: PropTypes.oneOfType([PropTypes.array.isRequired, PropTypes.oneOf([null])]),
	pendingOutcome: PropTypes.bool,
	disabledTooltip: PropTypes.string,
	isIterable: PropTypes.bool,
	dateAnchor: PropTypes.oneOfType([PropTypes.element, PropTypes.object]),
	dateScrollContainer: PropTypes.oneOfType([PropTypes.element, PropTypes.object]),
	pendingDate: PropTypes.instanceOf(Date),
	hasPendingTime: PropTypes.bool,
	disabledOutcome: PropTypes.bool
};

ActivityOutcomes.defaultProps = {
	onChange: () => {},
	onPresetDateChange: () => {},
	onDateChange: () => {},
	onTimeChange: () => {},
	confirm: () => {},
	bookAppointment: () => {},
	createActivityFollowUp: () => {},
	createTodoFollowUp: () => {},
	createCallFollowUp: () => {},
	createOpportunity: () => {},
	createOrder: () => {},
	changeContact: () => {},
	setAppointment: () => {},
	fetchAppointments: () => {},
	setContact: () => {}
};

export default connect(mapStateToProps, mapDispatchToProps)(ActivityOutcomes);
