import React from 'react';
import PropTypes from 'prop-types';
import { getIcon } from './inputHelpers';
import { Input } from '@upsales/components';
import UpActivityTypes from './UpActivityTypes';

class TextInput extends React.Component {
	render() {
		const {
			icon,
			placeholder,
			disabled,
			multiple,
			type,
			onChange,
			appointment,
			extraOpts,
			formatSelection,
			formatResult,
			defaultValue
		} = this.props;

		let extraClasses = [];
		if (!icon.length) {
			extraClasses.push('no-icon');
		}

		if (extraOpts && extraOpts.classes && extraOpts.classes.length) {
			extraClasses = extraClasses.concat(extraOpts.classes);
		}

		const iconOpts = { error: this.props.hasError };
		const iconCode = getIcon(icon, this.inputRef, iconOpts);

		if (type === 'accounts') {
			return (
				<div className={`TextInput form-control ${extraClasses.join(' ')}`}>
					{iconCode}
					<ReactTemplates.INPUTS.upAccounts
						placeholder={placeholder}
						fields={extraOpts.fields}
						value={appointment.client}
						id="appointment-account-select"
						key="appointment-account-select"
						className="appointment-account-select"
						onChange={ev => onChange(ev)}
						name="account"
						tools={{
							Account: Tools.Account,
							$state: Tools.$state,
							LatestAccountsService: Tools.LatestAccountsService,
							customerId: Tools.AppService.getCustomerId(),
							self: Tools.AppService.getSelf(),
							$translate: Tools.$translate
						}}
					/>
				</div>
			);
		}

		if (type === 'contacts') {
			return (
				<div className={`TextInput form-control ${extraClasses.join(' ')}`}>
					{iconCode}
					<ReactTemplates.INPUTS.upSelect
						className="appointment-contact-select"
						id="appointment-contact-select"
						key="appointment-contact-select"
						defaultValue={appointment.users.concat(
							appointment.contacts && Array.isArray(appointment.contacts) ? appointment.contacts : []
						)}
						placeholder={placeholder}
						onChange={_.noop}
						name="contact"
						multiple={multiple}
						formatResult={formatResult}
						accountId={extraOpts.accountId}
						formatSelection={formatSelection}
						fields={extraOpts.fields}
						tools={{
							$state: Tools.$state,
							Contact: Tools.Contact,
							$translate: Tools.$translate,
							self: Tools.AppService.getSelf(),
							customerId: Tools.AppService.getCustomerId()
						}}
						options={extraOpts.options}
						getId={extraOpts.getId}
						{...this.props}
					/>
				</div>
			);
		}

		if (type === 'campaigns') {
			return (
				<div className={`TextInput form-control ${extraClasses.join(' ')}`}>
					{iconCode}
					<ReactTemplates.INPUTS.upCampaigns
						multiple={multiple}
						name="campaigns"
						placeholder={placeholder}
						onChange={ev => onChange(ev)}
						value={appointment.project}
						id="appointment-campaigns-select"
						key="appointment-campaigns-select"
						className="appointment-campaigns-select"
					/>
				</div>
			);
		}

		if (type === 'activitytypes') {
			return (
				<div className={`TextInput form-control ${extraClasses.join(' ')}`}>
					{iconCode}
					<UpActivityTypes
						multiple={multiple}
						name="activity-types"
						entity={extraOpts.entity}
						placeholder={placeholder}
						onChange={ev => onChange(ev)}
						value={appointment.activityType}
						id="appointment-activityTypes-select"
						key="appointment-activityTypes-select"
						className="appointment-activityTypes-select"
					/>
				</div>
			);
		}

		if (type === 'opportunities') {
			return (
				<div className={`TextInput form-control ${extraClasses.join(' ')}`}>
					{iconCode}
					<ReactTemplates.INPUTS.upOpportunities
						multiple={multiple}
						name="opportunities"
						placeholder={placeholder}
						onChange={ev => onChange(ev)}
						accountId={extraOpts.accountId}
						value={appointment.opportunity}
						id="appointment-opportunities-select"
						key="appointment-opportunities-select"
						className="appointment-opportunities-select"
					/>
				</div>
			);
		}

		return (
			<div
				className={`TextInput form-control ${extraClasses.join(' ')} ${
					this.props.hasError ? 'has-error' : null
				}`}
			>
				{iconCode}
				<Input
					type={type}
					disabled={disabled}
					placeholder={placeholder}
					value={defaultValue}
					ref={input => (this.inputRef = input)}
					onChange={event => onChange(event.target.value)}
					noborder={true}
				/>
			</div>
		);
	}
}

TextInput.propTypes = {
	onChange: PropTypes.func.isRequired,
	hasError: PropTypes.bool,
	icon: PropTypes.string,
	placeholder: PropTypes.string,
	disabled: PropTypes.bool,
	multiple: PropTypes.bool,
	type: PropTypes.string,
	appointment: PropTypes.object,
	extraOpts: PropTypes.object,
	formatSelection: PropTypes.func,
	formatResult: PropTypes.func,
	defaultValue: PropTypes.string
};

TextInput.defaultProps = {
	icon: '',
	placeholder: 'Input text',
	disabled: false,
	type: 'text'
};

export default TextInput;
