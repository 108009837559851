import RequestBuilder from 'Resources/RequestBuilder';
import remove from './remove';

export default function removePromise(
	filters: RequestBuilder | null,
	entity: string,
	notifyEntity: string,
	name: string | null,
	selected: number[],
	numSelected: number
): Promise<void> {
	return new Promise((resolve, reject) => {
		remove(filters, entity, notifyEntity, name, selected, numSelected, resolve, reject);
	});
}
