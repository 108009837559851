import React, { useState } from 'react';
import ReportcenterFilter from './ReportcenterFilter';
import { Block } from '@upsales/components';
import bemClass from '@upsales/components/Utils/bemClass';
import T from 'Components/Helpers/translate';
import './ReportcenterFilterActivityType.scss';
import ReportcenterFilterRow from './ReportcenterFilterRow';
import { removeItem } from 'Store/helpers/array';
import { RCDashboardFilter } from 'Resources/ReportDashboard';
import ReportcenterFilterSearchbar from './ReportcenterFilterSearchbar';

interface Props {
	onChange: (type: keyof RCDashboardFilter, values: number[], comparison: string) => void;
	values: RCDashboardFilter;
	updateFilterHeight: () => void;
}

export const renderSelected = (values: RCDashboardFilter, returnCount: boolean = true) => {
	let res = `${T('default.activityTypes')}: `;

	if (!returnCount) {
		const activityTypeNames = Tools.AppService.getActivityTypes()
			.filter(activityType => {
				return values?.ActivityType?.value?.includes?.(activityType.id);
			})
			.map(activityType => activityType.name);
		res += activityTypeNames.join(', ');
		return res;
	}

	if (values?.ActivityType?.value?.length) {
		const length = values.ActivityType.value.length;
		if (length >= 1) {
			res += `${length} ${(values.ActivityType.comparison === 'eq'
				? T('default.selected')
				: T('default.excluded', { count: length })
			).toLowerCase()}`;
		}
	}

	return res;
};

const ReportcenterFilterActivityType = ({ onChange, ...props }: Props) => {
	const classes = new bemClass('ReportcenterFilterActivityType');
	const [searchStr, setSearchStr] = useState('');
	const activityTypes = [
		...Tools.AppService.getActivityTypes('activity'),
		...Object.values(Tools.AppService.getTodoTypes())
	].sort((a, b) => (a.name > b.name ? 1 : -1));
	const array = activityTypes.filter(item => item.name.toLowerCase().indexOf(searchStr.toLowerCase()) !== -1);
	const value = props.values?.ActivityType?.value || [];
	const comparison = props.values?.ActivityType?.comparison ?? 'eq';
	const isExclude = comparison === 'ne';

	return (
		<ReportcenterFilter
			className={classes.b()}
			renderSelected={() => renderSelected(props.values)}
			getSelectedNames={() => activityTypes.filter(at => value.includes(at.id)).map(at => at.name)}
			icon="opportunity"
			placeholder={`${T('default.activityTypes')}: ${T('reportcenter.filter.notActive')}`}
			value={value}
			resetFilter={() => onChange('ActivityType', [], 'eq')}
			{...props}
		>
			<Block space="ptm prm pbm plm" backgroundColor="white" className={classes.elem('list-select').b()}>
				<Block space="mtm">
					<ReportcenterFilterSearchbar<number[]>
						setSearchStr={value => setSearchStr(value)}
						searchStr={searchStr}
						placeholder={`${T('default.search')} ${T('default.activityTypes').toLowerCase()}`}
						isExclude={isExclude}
						onChange={onChange}
						field="ActivityType"
						value={value}
					/>
				</Block>
			</Block>
			<ReportcenterFilterRow
				key={0}
				onClick={() => {
					onChange('ActivityType', [], 'eq');
				}}
				selected={!value.length}
				title={T('filters.noFilter')}
			/>
			{array.map(row => (
				<ReportcenterFilterRow
					key={row.id}
					onClick={() => {
						const i = value.indexOf(row.id);
						onChange('ActivityType', i !== -1 ? removeItem(value, i) : [...value, row.id], comparison);
					}}
					selected={value.indexOf(row.id) !== -1}
					title={row.name}
				/>
			))}
		</ReportcenterFilter>
	);
};

export default ReportcenterFilterActivityType;
