import React, { useEffect, useState } from 'react';
import bemClass from '@upsales/components/Utils/bemClass';
import colorMappings from '@upsales/components/Utils/colorMappings';
import { ButtonSelect, Icon, Text, Title, Toggle, Tooltip } from '@upsales/components';
import RequestBuilder from 'Resources/RequestBuilder';
import WidgetResource, { ForecastData, RCWidgetData } from 'Resources/ReportWidget';
import logError from 'Helpers/logError';
import { ListViewFilter } from 'App/resources/AllIWant';
import forecastingFilter from 'App/babel/filterConfigs/Forecasting';
const ForecastingFilter = forecastingFilter();
import './ForecastingPipeline.scss';
import T from 'Components/Helpers/translate';
import Highcharts from 'highcharts';
import { CurrencyFormat, NumberFormat } from 'App/babel/utils/numberFormat';
import Legend from './Legend';

type Grouping = 'user' | 'client' | 'stage';
export type Props = {
	filters: ListViewFilter[];
	remaining: ForecastData['total']['remaining'];
	pipelineCoverageRatio: ForecastData['total']['pipelineCoverageRatio'];
	weighted: boolean;
	setWeighted: (w: boolean) => void;
	excludedOpportunities: number[];
	isSingleUser: boolean;
};

const ForecastingPipeline = ({
	filters,
	remaining,
	pipelineCoverageRatio,
	weighted,
	setWeighted,
	excludedOpportunities,
	isSingleUser
}: Props) => {
	const classes = new bemClass('ForecastingPipeline');
	const metadata = Tools.AppService.getMetadata();
	const salesModel = metadata.params.SalesModel;
	const hasBrokenFiscalYear = metadata.params.brokenFiscalYearEnabled;
	const [data, setData] = useState<RCWidgetData>();
	const [grouping, setGrouping] = useState<Grouping>(isSingleUser ? 'client' : 'user');
	const nf = new NumberFormat();
	const cf = new CurrencyFormat(data?.currency);

	const getData = () => {
		const rb = new RequestBuilder();
		const mappedFilters = filters.reduce((res: any, cur) => {
			res[cur.filterName] = cur;
			return res;
		}, {});

		const getConfig = (filterName: string) => ForecastingFilter[filterName];
		Tools.FilterHelper.parseFilters(mappedFilters, 'forecasting', rb, undefined, { getConfig });

		rb.queryArray = rb.queryArray.map(filter => {
			if (filter.a === 'user' && Array.isArray(filter.v) && filter.v.includes('self')) {
				const id = Tools.AppService.getSelf().id;
				const i = filter.v.indexOf('self');
				filter.v[i] = id;
			}
			return filter;
		});

		if (excludedOpportunities.length) {
			rb.queryArray.push({ a: 'order', c: 'ne', v: excludedOpportunities });
		}

		let widgetType;
		if (salesModel === 'rr') {
			widgetType = weighted ? 'RECURRING_WEIGHTED_PIPELINE' : 'RECURRING_PIPELINE';
		} else if (salesModel === 'cm') {
			widgetType = weighted ? 'CONTRIBUTION_MARGIN_WEIGHTED_PIPELINE' : 'CONTRIBUTION_MARGIN_PIPELINE';
		} else {
			widgetType = weighted ? 'WEIGHTED_PIPELINE' : 'PIPELINE';
		}

		WidgetResource.find(widgetType, {
			...rb.build(),
			grouping: [hasBrokenFiscalYear ? 'fiscalYear' : 'year', grouping]
		})
			.then((res: { data: RCWidgetData }) => {
				if (res.data?.rows?.length && res.data.rows[0].rows.length > 6) {
					// Reduce the data to 5 largest groups + the rest
					const allRows = [...res.data.rows[0].rows];
					const restRow = allRows.splice(5).reduce(
						(res, cur) => {
							res.key = cur.key;
							res.progress += cur.progress;
							res.count += 1;
							return res;
						},
						{ progress: 0, count: 0 } as any
					);
					if (restRow.count) {
						const groupingText =
							grouping === 'client'
								? T('default.accounts')
								: grouping === 'user'
								? T('default.users')
								: T('forecasting.stages');
						restRow.label = T('default.field.other') + ` (${restRow.count} ${groupingText})`.toLowerCase();
						allRows.push(restRow);
					}
					res.data.rows[0].rows = allRows;
				}
				setData(res.data);
			})
			.catch(err => logError(err, 'Could not fetch forecast data'));
	};

	useEffect(() => {
		const events = [
			'order.added',
			'order.updated',
			'order.deleted',
			'opportunity.added',
			'opportunity.updated',
			'opportunity.deleted',
			'agreement.added',
			'agreement.updated',
			'agreement.deleted',
			'agreementGroup.added',
			'agreementGroup.updated',
			'agreementGroup.deleted'
		];
		const unsubscribeFunctions = events.map(event => Tools.$rootScope.$on(event, getData));

		return () => {
			unsubscribeFunctions.forEach(fn => fn());
		};
	}, []);

	useEffect(() => {
		if (isSingleUser && grouping === 'user') {
			setGrouping('client');
		}
		getData();
	}, [filters, grouping, weighted, excludedOpportunities, isSingleUser]);

	useEffect(() => {
		let series: { data: (number | { name: string; y: number })[]; color: string; name?: string }[] = [];
		if (data && data.rows.length) {
			const reversedRows = [...data.rows[0].rows].reverse();
			series = reversedRows.map((row: any) => ({
				data: [{ name: row.label, y: row.progress }],
				color: data.colors[row.key].color
			}));
		}
		if ((remaining.value ?? 0) > 0) {
			const data = [];
			if (series.length) {
				data.push(0);
			}
			data.push({ name: T('salesboard.quotaLeft'), y: Math.max(remaining.value ?? 0, 0) });
			series.push({
				data,
				color: colorMappings.get('grey-5'),
				name: 'remaining'
			});
		}

		Highcharts.chart('pipelineChart', {
			chart: {
				type: 'bar',
				marginLeft: 0,
				marginRight: 0,
				events: {
					load: function () {
						//@ts-ignore
						this.series.forEach(series => {
							var points = series.points;
							points.forEach((point: any) => {
								if (
									point.shapeArgs &&
									point.dataLabel &&
									point.dataLabel.hide &&
									point.shapeArgs.height < point.dataLabel.width
								) {
									point.dataLabel.hide();
								}
							});
						});
					}
				}
			},
			title: {
				text: ''
			},
			xAxis: {
				visible: false
			},
			yAxis: {
				visible: false,
				endOnTick: false,
				startOnTick: false,
				max: Math.max(remaining.value ?? 0, data?.total.progress ?? 0)
			},
			legend: {
				enabled: false
			},
			plotOptions: {
				series: {
					stacking: 'normal',
					dataLabels: {
						enabled: true,
						inside: true,
						align: 'left',
						style: {
							textShadow: 'false'
						},
						formatter: function () {
							if (!this.y || this.y === 0) {
								return '';
							} else {
								//@ts-ignore
								if (this.series.name === 'remaining') {
									return cf.short(this.y) + ' ' + T('forecasting.leftToTarget');
								} else {
									return cf.short(this.y);
								}
							}
						}
					}
				},
				bar: {
					groupPadding: 0,
					pointPadding: 0,
					borderWidth: 0
				}
			},
			series: series,
			credits: {
				enabled: false
			},
			tooltip: {
				followPointer: true,
				useHTML: true,
				backgroundColor: 'white',
				borderColor: 'white',
				borderWidth: 0,
				borderRadius: 8,
				formatter: function () {
					return (
						`<div style="display: flex; flex-direction: row; align-items: center; width: ${
							(this as any).point.dataLabel.textSetter.width
						} ; height: 16px" >` +
						`<div style="height: 12px; width: 12px; border-radius: 50%; background-color: ${
							(this as any).point.color
						};">` +
						'</div>' +
						'<div style="padding-left:8px;">' +
						(this as any).point.name +
						': ' +
						cf.short((this as any).y) +
						'</div>' +
						'</div>'
					);
				}
			}
		});
	}, [data]);

	const buttonOptions = [
		{ title: T('account'), value: 'client', icon: 'home' },
		{ title: T('default.stage'), value: 'stage', icon: 'opportunity' }
	];
	if (!isSingleUser) {
		buttonOptions.unshift({ title: T('default.salesRep'), value: 'user', icon: 'user' });
	}

	return (
		<div className={classes.mod({ hidden: !(data && data.rows.length) && !remaining.value }).b()}>
			<div className={classes.elem('header').b()}>
				<Title size="lg">{T('forecasting.currentPipeline')}</Title>
				<ButtonSelect
					className={classes.elem('groupings').b()}
					options={buttonOptions}
					value={grouping}
					onChange={value => setGrouping(value)}
				/>
				<Toggle space="mll mrl" checked={weighted} onChange={() => setWeighted(!weighted)} />
				<Text>{T('default.WeightedValue')}</Text>
				<Tooltip title={T('default.WeightedValueToolTip')}>
					<Icon name="question-circle" space="mll" />
				</Tooltip>
			</div>
			<div className={classes.elem('widget').b()}>
				{remaining.value === null ? null : remaining.value > 0 ? (
					<Title size="md">
						<span>{T('forecasting.enoughPipeline1')} </span>
						<span style={{ fontWeight: 600, color: colorMappings.get(pipelineCoverageRatio.color) }}>
							{nf.short(pipelineCoverageRatio.value ?? 0)}X{' '}
						</span>
						<span>{T('forecasting.enoughPipeline2')}</span>
					</Title>
				) : remaining.value === 0 ? (
					<Title size="md">{T('forecasting.targetReached') + '!'}</Title>
				) : (
					<Title size="md">
						<span>{T('forecasting.targetExceededBy')} </span>
						<span style={{ color: colorMappings.get('success-6') }}>
							{cf.short(Math.abs(remaining.value))}
						</span>
						<span>!</span>
					</Title>
				)}

				<div
					id="pipelineChart"
					className={classes
						.elem('chart')
						.mod({ oneBar: remaining.value === null || remaining.value <= 0 || !data?.rows.length })
						.b()}
				/>
				<div className={classes.elem('legend').b()}>
					{data && data.rows.length
						? data.rows[0].rows.map(row => (
								<Legend key={row.key} title={row.label} color={data.colors[row.key].color} />
						  ))
						: null}
				</div>
			</div>
		</div>
	);
};

export default ForecastingPipeline;
