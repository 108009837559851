import React from 'react';
import { get } from 'lodash';
import { Text, Link, Icon, Tooltip } from '@upsales/components';
import TimelineRow from 'Components/TimelineRow';
import { ACTIVITY_TIMELINE } from 'App/babel/enum/timeline';
import { openDrawer } from 'Services/Drawer';
import TimelineSubSection from 'Components/TimelineRow/TimelineSubSection';

export const ActivityTimelineSendMail = ({ event, hideIcon }) => {
	const t = Tools.$translate;
	const lang = {
		didntReached: t('activity.outcomeLog.didntReached'),
		reached: t('activity.outcomeLog.reached'),
		sent: t('activity.outcomeLog.sent'),
		sentEmail: t('activity.outcomeLog.sentEmail'),
		mailClicked: t('event.mailClicked'),
		mailNotClicked: t('event.mailNotClicked'),
		mailWasRead: t('event.mailWasRead'),
		mailWasNotRead: t('event.mailWasNotRead'),
		someone: t('default.someone'),
		mailDeleted: t('event.mailDeleted')
	};
	const { subType, data, date, user } = event || {};
	const userName = get(user, 'name');
	const customerId = Tools.AppService.getCustomerId();
	const contactId = get(data, 'contact.id', '');
	const contactName = get(data, 'contact.name', '');
	const mailSubject = get(data, 'mail.subject', '');
	const mailClicked = get(data, 'mail.lastClickDate', '');
	const mailRead = get(data, 'mail.lastReadDate', '');

	const openEmail = e => {
		e.stopPropagation();
		const mailId = get(data, 'mail.id');

		if (Tools.FeatureHelper.hasSoftDeployAccess('NEW_MAIL')) {
			openDrawer('SentMail', { mail: { id: mailId } });
		} else {
			Tools.$upModal.open('sentMail', {
				customerId: customerId,
				id: mailId,
				contactId
			});
		}
	};

	const title = (
		<Text>
			{subType === ACTIVITY_TIMELINE.NOT_REACHED_SEND_MAIL ? `${lang.didntReached} ` : `${lang.reached} `}
			{contactName ? (
				<Link
					onClick={e => e.stopPropagation()}
					href={Tools.$state.href('contact.dashboard', {
						customerId: customerId,
						id: contactId
					})}
				>
					{contactName + ': '}
				</Link>
			) : (
				lang.someone.toLowerCase() + ': '
			)}
			{lang.sentEmail}
		</Text>
	);

	const subSection = data ? (
		<TimelineSubSection iconProps={{ icon: 'envelope' }} hasLink>
			<>
				<Text>{lang.sent}</Text>
				{data.mail ? (
					<Link onClick={openEmail} className="text-ellipsis">
						{mailSubject}
					</Link>
				) : (
					<>
						&nbsp;
						<Text italic color="grey-10">
							{lang.mailDeleted}
						</Text>
					</>
				)}
			</>
		</TimelineSubSection>
	) : null;

	const subTitle = (
		<React.Fragment>
			<Text size="sm" color="grey-11">
				{moment(date).format('L LT') + ' • ' + userName + ' • '}
			</Text>

			<Tooltip title={mailRead ? lang.mailWasRead : lang.mailWasNotRead} className="TimelineMail__read-click">
				<Icon name="eye" color={mailRead ? 'medium-purple' : 'grey-10'} space="mlm mrm" />
			</Tooltip>

			<Tooltip title={mailClicked ? lang.mailClicked : lang.mailNotClicked} className="TimelineMail__read-click">
				<Icon name="mouse-pointer" color={mailClicked ? 'medium-purple' : 'grey-10'} space="mlm mrm" />
			</Tooltip>
		</React.Fragment>
	);

	return (
		<TimelineRow
			icon={
				hideIcon
					? undefined
					: subType === ACTIVITY_TIMELINE.NOT_REACHED_SEND_MAIL
					? 'phone-fail'
					: 'phone-success'
			}
			iconColor={subType === ACTIVITY_TIMELINE.NOT_REACHED_SEND_MAIL ? 'red' : 'medium-green'}
			event={event}
			title={title}
			subTitle={subTitle}
			subSection={subSection}
		/>
	);
};

export default ActivityTimelineSendMail;
