import ResourceTyped from './ResourceTyped';
import ClientPlanType from 'App/resources/Model/ClientPlan';

class ClientPlan extends ResourceTyped<ClientPlanType> {
	constructor() {
		super('clientPlan');
	}
}

const resource = new ClientPlan();

export default resource;
