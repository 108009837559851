import PropTypes from 'prop-types';

const activityUser = PropTypes.shape({
	id: PropTypes.number,
	name: PropTypes.string
});

export default PropTypes.shape({
	id: PropTypes.number,
	client: PropTypes.shape({
		id: PropTypes.number
	}),
	date: PropTypes.oneOfType([PropTypes.string, PropTypes.instanceOf(Date)]),
	regDate: PropTypes.oneOfType([PropTypes.string, PropTypes.instanceOf(Date)]),
	time: PropTypes.oneOfType([PropTypes.string, PropTypes.instanceOf(Date)]),
	users: PropTypes.oneOfType([activityUser, PropTypes.arrayOf(activityUser)]),
	custom: PropTypes.array,
	description: PropTypes.string
});
