import React from 'react';
import { Link, TableColumn, TableRow, Text, Title } from '@upsales/components';
import bemClass from '@upsales/components/Utils/bemClass';
import T from 'Components/Helpers/translate';
import './ReportcenterDrilldownEmpty.scss';
import { openDrawer } from 'Services/Drawer';
import Client from 'App/resources/Model/Client';

export type Props = {
	client: Pick<Client, 'id' | 'name' | 'createRights'> | null;
	type?: string;
};

const openPhoneCallDrawer = (client: Props['client']) => {
	openDrawer('CreateCall', { client });
};

const ReportcenterDrilldownEmpty = ({ type, client }: Props) => {
	const classes = new bemClass('ReportcenterDrilldownEmpty');

	return (
		<TableRow className={classes.elem('empty-wrapper').b()}>
			<TableColumn colSpan={100} align={'center'}>
				{type === 'potentialOpportunityValue' && client ? (
					<>
						<img src={'img/GrowthGrid_Illustration.svg'} />
						<Title bold>{T('reportcenter.noOpenOpportunities')}</Title>
						<Text>
							<Link onClick={() => openPhoneCallDrawer(client)}>
								{T('reportcenter.callThemYouMightFindGold1')}
							</Link>
							{T('reportcenter.callThemYouMightFindGold2')}
						</Text>
					</>
				) : (
					<>
						<img src={'img/empty-briefcase.svg'} />
						<Title bold>{T('noResult.generic')}</Title>
						<Text>{T('noMatches.tryChangeFilters')}</Text>
					</>
				)}
			</TableColumn>
		</TableRow>
	);
};

export default ReportcenterDrilldownEmpty;
