import Attribute from './Attribute';

const MailTemplateAttributes = {
	id: Attribute({
		type: Attribute.types.Number,
		field: 'id'
	}),
	name: Attribute({
		title: 'default.name',
		type: Attribute.types.String,
		field: 'name',
		selectableColumn: true,
		sortable: 'name',
		size: 256
	}),
	from: Attribute({
		title: 'default.from',
		type: Attribute.types.String,
		field: 'from'
	}),
	fromName: Attribute({
		title: 'default.fromName',
		type: Attribute.types.String,
		field: 'fromName'
	}),
	subject: Attribute({
		title: 'mail.subject',
		type: Attribute.types.String,
		field: 'subject',
		selectableColumn: true,
		sortable: 'subject',
		size: 150
	}),
	body: Attribute({
		title: 'mail.body',
		type: Attribute.types.String,
		field: 'body'
	}),
	private: Attribute({
		title: 'default.private',
		type: Attribute.types.Boolean,
		field: 'private'
	}),
	usedCounter: Attribute({
		title: 'mail.timesUsed',
		type: Attribute.types.Number,
		field: 'usedCounter'
	}),
	lastUsedDate: Attribute({
		title: 'default.lastUsedDate',
		type: Attribute.types.DateTime,
		field: 'lastUsedDate'
	}),
	regDate: Attribute({
		title: 'default.created',
		type: Attribute.types.DateTime,
		field: 'regDate',
		selectableColumn: true
	}),
	user: Attribute({
		title: 'default.user',
		type: Attribute.types.Object,
		selectableColumn: true,
		field: 'user',
		sortable: 'user.name',
		attr: {
			id: Attribute({
				type: Attribute.types.Number,
				field: 'user.id'
			})
		}
	}),
	attachments: Attribute({
		title: 'mail.attachments',
		type: Attribute.types.Array,
		field: 'attachments'
	}),
	bodyJson: Attribute({
		title: 'mail.bodyJson',
		type: Attribute.types.String,
		field: 'bodyJson'
	}),
	modDate: Attribute({
		title: 'listColumnTitle.lastModified',
		type: Attribute.types.DateTime,
		field: 'modDate',
		sortable: 'modDate',
		selectableColumn: true
	}),
	socialEvent: Attribute({
		title: 'default.socialEvent',
		type: Attribute.types.Number,
		field: 'socialEvent'
	}),
	links: Attribute({
		title: 'default.links',
		type: Attribute.types.Array,
		field: 'links'
	}),
	labels: Attribute({
		title: 'file.label',
		type: Attribute.types.Array,
		field: 'labels',
		selectableColumn: true,
		attr: {
			id: Attribute({
				field: 'labels.id',
				type: Attribute.types.Number
			}),
			name: Attribute({
				field: 'labels.name',
				type: Attribute.types.String
			})
		}
	}),
	previewUrl: Attribute({
		field: 'previewUrl',
		type: Attribute.types.String
	})
};

export default MailTemplateAttributes;
