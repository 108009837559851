import Attribute, { DisplayType, Type } from './Attribute';

const projectAttr = Attribute({
	title: 'column.listSource',
	field: 'project.id',
	type: Type.Object,
	displayType: DisplayType.Number,
	selectableColumn: true,
	filterable: false,
	parent: 'project',
	attr: {
		id: Attribute({
			field: 'project.id',
			type: Type.Number
		}),
		name: Attribute({
			field: 'project.name',
			type: Type.String
		})
	}
});

const MailCampaignAttributes = {
	id: Attribute({
		title: 'default.id',
		field: 'id',
		type: Type.Number,
		displayType: DisplayType.Text,
		groupable: false,
		selectableColumn: false,
		filterable: false
	}),
	status: Attribute({
		title: 'default.status',
		field: 'status',
		type: Type.String,
		displayType: DisplayType.MailStatus,
		groupable: false,
		selectableColumn: true,
		filterable: false,
		sortable: 'status'
	}),
	flowId: Attribute({
		title: 'flowId',
		field: 'flowId',
		type: Type.Number
	}),
	mailSent: Attribute({
		title: 'mail.sent',
		field: 'mailSent',
		type: Type.Number,
		displayType: DisplayType.Number,
		groupable: false,
		selectableColumn: true,
		filterable: false,
		sortable: 'mailSent'
	}),
	mailRead: Attribute({
		title: 'mail.opened',
		field: 'mailRead',
		type: Type.Number,
		displayType: DisplayType.Number,
		groupable: false,
		selectableColumn: true,
		filterable: false,
		sortable: 'mailRead'
	}),
	mailClicked: Attribute({
		title: 'mail.clicked',
		field: 'mailClicked',
		type: Type.Number,
		displayType: DisplayType.Number,
		groupable: false,
		selectableColumn: true,
		filterable: false,
		sortable: 'mailClicked'
	}),
	isArchived: Attribute({
		title: 'default.archived',
		field: 'isArchived',
		type: Type.Boolean,
		displayType: DisplayType.Boolean,
		groupable: false,
		selectableColumn: false,
		filterable: true
	}),
	doSend: Attribute({
		field: 'doSend',
		type: Type.Boolean,
		groupable: false,
		selectableColumn: false,
		filterable: false
	}),
	date: Attribute({
		title: 'default.date',
		field: 'date',
		type: Type.Date,
		displayType: DisplayType.Date,
		groupable: false,
		selectableColumn: false,
		filterable: false
	}),
	sendDate: Attribute({
		title: 'mail.sendDate',
		field: 'sendDate',
		type: Type.DateTime,
		displayType: DisplayType.Date,
		groupable: false,
		selectableColumn: true,
		filterable: false,
		sortable: 'sendDate'
	}),
	modDate: Attribute({
		title: 'listColumnTitle.lastModified',
		field: 'modDate',
		type: Type.Date,
		displayType: DisplayType.Date,
		groupable: false,
		selectableColumn: true,
		filterable: false,
		sortable: 'modDate'
	}),
	opened: Attribute({
		title: 'default.opend',
		field: 'opend',
		type: Type.String,
		displayType: DisplayType.Text,
		groupable: false,
		selectableColumn: false,
		filterable: false
	}),
	project: projectAttr,
	campaign: { ...projectAttr, selectableColumn: false },
	segment: Attribute({
		title: 'default.segment',
		field: 'segment.id',
		type: Type.Object,
		displayType: DisplayType.Number,
		groupable: false,
		selectableColumn: false,
		filterable: false,
		parent: 'segment',
		attr: {
			id: Attribute({
				field: 'segment.id',
				type: Type.Number
			}),
			name: Attribute({
				field: 'segment.name',
				type: Type.String
			})
		}
	}),
	segments: Attribute({
		type: Type.Array,
		field: 'segments',
		attr: {
			id: Attribute({
				field: 'segments.id',
				type: Type.Number
			}),
			name: Attribute({
				field: 'segments.name',
				type: Type.String
			})
		}
	}),
	category: Attribute({
		title: 'default.category',
		type: Type.Object,
		field: 'category',
		attr: {
			id: Attribute({
				field: 'category.id',
				type: Type.Number
			}),
			title: Attribute({
				field: 'category.title',
				type: Type.String
			})
		}
	}),
	filter: Attribute({
		title: 'default.filter',
		type: Type.String,
		field: 'filter'
	}),
	filterConfig: Attribute({
		title: 'default.filterConfig',
		type: Type.StringifiedObject,
		field: 'filterConfig'
	}),
	from: Attribute({
		title: 'mail.from',
		field: 'from',
		selectableColumn: true,
		type: Type.String,
		displayType: DisplayType.Text,
		sortable: 'from'
	}),
	fromName: Attribute({
		title: 'mail.fromName',
		field: 'fromName',
		selectableColumn: true,
		sortable: 'fromName',
		type: Type.String,
		displayType: DisplayType.Text
	}),
	body: Attribute({
		title: 'mail.body',
		field: 'body',
		type: Type.String,
		displayType: DisplayType.Text
	}),
	template: Attribute({
		title: 'mail.template',
		field: 'template.id',
		type: Type.Object,
		displayType: DisplayType.MailTemplate,
		groupable: false,
		selectableColumn: true,
		filterable: false,
		parent: 'template',
		attr: {
			id: Attribute({
				field: 'template.id',
				type: Type.Number
			}),
			name: Attribute({
				field: 'template.name',
				type: Type.String
			})
		}
	}),
	to: Attribute({
		title: 'mail.reciever',
		field: 'to',
		selectableColumn: true,
		type: Type.String,
		displayType: DisplayType.Text
	}),
	subject: Attribute({
		title: 'mail.subject',
		field: 'subject',
		selectableColumn: true,
		sortable: 'subject',
		type: Type.String,
		displayType: DisplayType.Text,
		size: 150
	}),
	name: Attribute({
		title: 'default.internalName',
		field: 'name',
		selectableColumn: true,
		sortable: 'name',
		type: Type.String,
		displayType: DisplayType.Text,
		size: 256
	}),
	lastClickDate: Attribute({
		title: 'mail.readclick',
		field: 'lastClickDate',
		type: Type.String,
		displayType: DisplayType.Text
	}),
	lastEventDate: Attribute({
		title: 'mail.readclickDate',
		field: 'lastEventDate',
		type: Type.Date,
		displayType: DisplayType.Date
	}),
	lastReadDate: Attribute({
		title: 'mail.readDate',
		field: 'lastReadDate',
		type: Type.Date,
		displayType: DisplayType.Date
	}),
	user: Attribute({
		title: 'default.user',
		field: 'user.id',
		type: Type.Object,
		displayType: DisplayType.User,
		groupable: false,
		selectableColumn: true,
		filterable: false,
		parent: 'user',
		attr: {
			id: Attribute({
				field: 'user.id',
				type: Type.Number
			}),
			name: Attribute({
				field: 'user.name',
				type: Type.String
			})
		}
	}),
	isDraft: Attribute({
		title: 'mail.draft',
		field: 'isDraft',
		type: Type.Boolean,
		displayType: DisplayType.Date
	}),
	attachments: Attribute({
		title: 'mail.attachments',
		field: 'attachments',
		type: Type.Array,
		displayType: DisplayType.Text
	}),
	socialEventId: Attribute({
		field: 'socialEventId',
		type: Type.Number,
		displayType: DisplayType.Number
	}),
	socialEventSendToStatus: Attribute({
		field: 'socialEventSendToStatus',
		type: Type.String,
		displayType: DisplayType.Text
	}),
	previewUrl: Attribute({
		field: 'previewUrl',
		type: Type.String,
		displayType: DisplayType.Text
	}),
	bodyJson: Attribute({
		title: 'mail.bodyJson',
		field: 'bodyJson',
		type: Type.String,
		displayType: DisplayType.Text,
		selectableColumn: false,
		groupable: false,
		filterable: false
	}),
	contact: Attribute({
		title: 'default.contact',
		field: 'contact',
		type: Type.String,
		displayType: DisplayType.Text
	}),
	client: Attribute({
		title: 'default.account',
		field: 'client',
		type: Type.String,
		displayType: DisplayType.Text
	}),
	excludeRecentlyEmailed: Attribute({
		field: 'excludeRecentlyEmailed',
		type: Type.Boolean
	}),
	labels: Attribute({
		title: 'file.label',
		type: Attribute.types.Array,
		field: 'labels',
		selectableColumn: false,
		attr: {
			id: Attribute({
				field: 'labels.id',
				type: Attribute.types.Number
			}),
			name: Attribute({
				field: 'labels.name',
				type: Attribute.types.String
			})
		}
	})
};

export default MailCampaignAttributes;
