import React from 'react';
import PropTypes from 'prop-types';
import { Label, Text, Row } from '@upsales/components';
import BemClass from '@upsales/components/Utils/bemClass';
import T from 'Components/Helpers/translate';
import './PhonecallCustomFields.scss';

const renderCustomFields = (fields, classes, onCustomChange, disabled) => {
	return _.chain(fields)
		.map(function (field, index) {
			if (field.$hasAccess && (field.visible || field.editable)) {
				var cf = React.createElement(ReactTemplates.INPUTS.customFieldInput, {
					key: 'cf-' + index,
					field: field,
					name: field.name,
					disabled: !field.editable || disabled,
					multiple: false,
					className: 'form-control up-input',
					onChange: onCustomChange.bind(self, field.id),
					usenewdate: 1,
					useNewTime: true,
					useNumberInput: true
				});

				return (
					<div className={classes.elem('custom-field')} key={'cf-' + index + '-col'}>
						<div>
							<Label
								className={classes.elem('custom-field-label').b()}
								required={!!field.obligatoryField}
							>
								{field.name}{' '}
							</Label>
							<div className={classes.elem('custom-field-input').b()}>{cf}</div>
						</div>
					</div>
				);
			} else {
				return null;
			}
		})
		.sortBy('sortId')
		.value();
};

const PhonecallCustomFields = ({ customFields, onCustomChange, disabled = false }) => {
	const classes = new BemClass('PhonecallCustomFields');
	const requiredCustomFields = customFields?.filter(field => field.obligatoryField === 1);
	const nonRequiredCustomFields = customFields?.filter(field => field.obligatoryField === 0);
	return (
		<div className={classes.b()}>
			{requiredCustomFields?.length ? (
				<Row className={classes.elem('title').b()}>
					<Text size="xl" color="black">
						{T('default.otherInfo')}
					</Text>
				</Row>
			) : null}
			{customFields?.length ? (
				<React.Fragment>
					<div className={classes.elem('custom-fields-wrapper').b()}>
						{requiredCustomFields ? (
							<div className={classes.elem('req-customs').b()}>
								{renderCustomFields(requiredCustomFields, classes, onCustomChange, disabled)}
							</div>
						) : null}
						{nonRequiredCustomFields ? (
							<div className={classes.elem('non-req-customs').b()}>
								{renderCustomFields(nonRequiredCustomFields, classes, onCustomChange, disabled)}
							</div>
						) : null}
					</div>
				</React.Fragment>
			) : null}
		</div>
	);
};

PhonecallCustomFields.propTypes = {
	customFields: PropTypes.array,
	onCustomChange: PropTypes.func
};

export default PhonecallCustomFields;
