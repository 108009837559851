import BemClass from '@upsales/components/Utils/bemClass';
import React, { useState, useEffect, useRef } from 'react';
import DrawerHeader from '@upsales/components/DrawerHeader';
import './ForecastingDrawer.scss';
import ForecastingFilters from './ForecastingFilters';
import { ListViewFilter } from 'App/resources/AllIWant';
import { Title, Block, Loader, Icon, Tooltip } from '@upsales/components';
import DataBox, { SectionData } from './DataBox';
import ForecastingGraph from './ForecastingGraph';
import WidgetResource, { ForecastData, ForecastStatus } from 'Resources/ReportWidget';
import logError from 'Helpers/logError';
import RequestBuilder from 'Resources/RequestBuilder';
import forecastingFilter2 from 'App/babel/filterConfigs/Forecasting';
const ForecastingFilter2 = forecastingFilter2();
import T from 'Components/Helpers/translate';
import ForecastingTable from './ForecastingTable';
import { CurrencyFormat } from 'App/babel/utils/numberFormat';
import Legend from './Legend';
import colorMappings from '@upsales/components/Utils/colorMappings';
import { forecastingTracker } from 'Helpers/Tracker';
import ForecastingPipeline from './ForecastingPipeline';
import type { SalesboardState } from 'App/pages/Salesboard/Salesboard';

type Props = {
	close: () => void;
	className: string;
	salesboardFilters: any;
	valueType: SalesboardState['salesboardDisplayValueType'];
};

export const getPipelineColor = (value: number, isText?: boolean) => {
	if (isText) {
		return value > 2 ? 'success-6' : value >= 1.5 ? 'yellow-5' : 'dark-red';
	}
	return value > 2
		? colorMappings.get('success-6')
		: value >= 1.5
		? colorMappings.get('yellow-5')
		: colorMappings.get('dark-red');
};

const ForecastingDrawer = (props: Props) => {
	const classes = new BemClass('ForecastingDrawer', props.className);
	const hasBrokenFiscalYear = Tools.AppService.getMetadata().params.brokenFiscalYearEnabled;
	const filterPrefix = hasBrokenFiscalYear ? 'CurrentFiscal' : 'Current';
	const firstRender = useRef(true);
	const [forecastData, setForecastData] = useState<ForecastData | null>(null);
	const [firstSectionData, setFirstSectionData] = useState<SectionData[]>([]);
	const [secondSectionData, setSecondSectionData] = useState<SectionData[]>([]);
	const [sorting, setSorting] = useState<{ field: string; asc: boolean }>({ field: 'remaining', asc: false });
	const [filters, setFilters] = useState<ListViewFilter[]>([
		{
			comparisonType: 'Equals',
			filterName: 'User',
			inactive: !props.salesboardFilters.User?.value?.length,
			value: props.salesboardFilters.User?.value?.length ? props.salesboardFilters.User.value : []
		},
		{ comparisonType: 'Equals', filterName: 'OpportunityStage', inactive: true, value: [] },
		{ comparisonType: 'Equals', filterName: 'Date', inactive: false, value: [filterPrefix + 'Quarter'] }
	]);
	const [weighted, setWeighted] = useState<boolean>(false);
	const [excludedOpportunities, setExcludedOpportunities] = useState<number[]>([]);

	const getUserFilterText = (filters: ListViewFilter[]) => {
		const userFilter = filters.find(filter => filter.filterName === 'User');
		if (!userFilter?.value.length) {
			return { userFilterText: T('default.youPlural'), config: { isName: false, isAre: true } };
		}
		let tag = '';
		const config = { isName: false, isAre: true };
		if (userFilter.value[0] === 'self' || userFilter.value[0] === Tools.AppService.getSelf().id) {
			tag = T('default.you');
		} else {
			const userName = Tools.AppService.getUsers().find(user => user.id === userFilter.value[0])?.name || '';
			tag = userName?.split(' ')[0] ?? '';
			config.isName = true;
			config.isAre = false;
		}
		if (userFilter.value.length > 1) {
			tag = `${tag} ${T('default.and').toLowerCase()} ${userFilter.value.length - 1} ${T(
				'filters.more'
			).toLowerCase()}`;
			config.isAre = true;
		}

		return { userFilterText: tag, config };
	};

	const prepareSectionData = (data: ForecastData, filters: ListViewFilter[]) => {
		const { config } = getUserFilterText(filters);
		const cf = new CurrencyFormat(data.currency);
		const status =
			data.total.target.value === null
				? ForecastStatus.POSITIVE
				: data.total.probableOutcome.value >= data.total.target.value
				? data.total.remaining.value !== null && data.total.remaining.value < 0
					? ForecastStatus.REACHED_TARGET
					: ForecastStatus.POSITIVE
				: ForecastStatus.NEGATIVE;
		const firstSectionData: SectionData[] = [
			{
				title: T('default.sales'),
				value: cf.short(data.total.sales.value),
				valueColor: data.total.sales.value >= 0 ? 'black' : 'dark-red',
				subtitle:
					data.total.sales.change !== 0
						? `${data.total.sales.change > 0 ? '+' : ''} ${cf.short(data.total.sales.change)}`
						: '',
				subtitleColor: data.total.sales.change >= 0 ? 'black' : 'dark-red',
				onSubtitleClick: () => forecastingTracker.track(forecastingTracker.events.SALES_CHANGE_CLICKED),
				onSubtitleHover: () => forecastingTracker.track(forecastingTracker.events.SALES_CHANGE_HOVERED),
				tooltip: T('forecasting.last7Days')
			},
			{
				title: T('forecasting.probableOutcome.title'),
				value: cf.short(data.total.probableOutcome.value),
				subtitle: data.total.target.value
					? `${T('forecasting.probableOutcome.subtitle', {
							count: data.total.probableOutcome.percentage,
							are: config.isAre
					  })}`
					: '',
				icon:
					status === ForecastStatus.REACHED_TARGET
						? 'flash'
						: status === ForecastStatus.NEGATIVE
						? 'warning'
						: 'info',
				valueColor:
					status === ForecastStatus.REACHED_TARGET
						? 'success-6'
						: status === ForecastStatus.NEGATIVE
						? 'dark-red'
						: 'blue',
				className: classes
					.elem('special')
					.mod({ success: status === ForecastStatus.REACHED_TARGET })
					.mod({
						warning: data.total.target.value && data.total.probableOutcome.value < data.total.target.value
					})
					.b(),
				helpText: T('default.WeightedValueToolTip')
			}
		];

		const secondSectionData: SectionData[] = [
			{
				title: T('report.totalPipeline'),
				value: cf.short(data.total.pipeline.value),
				subtitle:
					data.total.pipeline.change !== 0
						? `${data.total.pipeline.change >= 0 ? '+' : ''}  ${cf.short(data.total.pipeline.change)}`
						: '',
				subtitleColor: data.total.pipeline.change >= 0 ? 'black' : 'dark-red',
				onSubtitleClick: () => forecastingTracker.track(forecastingTracker.events.PIPELINE_CHANGE_CLICKED),
				onSubtitleHover: () => forecastingTracker.track(forecastingTracker.events.PIPELINE_CHANGE_HOVERED),
				tooltip: T('forecasting.last7Days')
			},
			{
				title: T('report.totalOpportunitiesInPipeline'),
				value: data.total.opportunities.value,
				subtitle:
					data.total.opportunities.change !== 0
						? `${data.total.opportunities.change >= 0 ? '+' : ''} ${data.total.opportunities.change} `
						: '',
				subtitleColor: data.total.opportunities.change >= 0 ? 'black' : 'dark-red',
				tooltip: T('forecasting.last7Days')
			}
		];

		if (data.total.target.value) {
			firstSectionData.push(
				{
					title: T('salesboard.quota'),
					value: cf.short(data.total.target.value),
					icon: 'bullseye'
				},
				{
					title:
						status === ForecastStatus.REACHED_TARGET
							? T('report.surpassedQouta')
							: T('salesboard.quotaLeft'),
					value:
						status === ForecastStatus.REACHED_TARGET
							? cf.short(-(data.total.remaining.value ?? -0))
							: cf.short(data.total.remaining.value ?? 0),
					valueColor: status === ForecastStatus.REACHED_TARGET ? 'success-6' : 'black'
				}
			);
		}

		setFirstSectionData(firstSectionData);
		setSecondSectionData(secondSectionData);
	};
	let graphLegends: JSX.Element[] = [];
	if (forecastData) {
		graphLegends = [
			<Legend
				key={'probableOutcomeLegend'}
				color={
					forecastData.total.target.value !== null &&
					forecastData.total.pace.value >= forecastData.total.target.value
						? colorMappings.get('medium-blue')
						: colorMappings.get('dark-red')
				}
				title={T('forecasting.pace')}
			/>,
			<Legend key={'salesLegend'} color={colorMappings.get('medium-green')} title={T('default.sales')} />
		];
		if (forecastData.total.target.value !== null) {
			graphLegends.push(
				<Legend key={'quotaLegend'} color={colorMappings.get('grey-10')} title={T('salesboard.quota')} />
			);
		}
	}

	const getData = (
		filters: ListViewFilter[],
		sorting: { field: string; asc: boolean } = { field: 'remaining', asc: false },
		opts?: { updateTable?: boolean; updateTotal?: boolean; updateGraph?: boolean }
	) => {
		opts = {
			updateTable: opts?.updateTable ?? true,
			updateTotal: opts?.updateTotal ?? true,
			updateGraph: opts?.updateGraph ?? true
		};
		const rb = new RequestBuilder();
		const newFilterFormat = filters.reduce((newFilters: any, filter) => {
			newFilters[filter.filterName] = filter;
			return newFilters;
		}, {});

		const sortType = sorting.asc ? 'asc' : 'desc';
		const getConfig = (filterName: string) => ForecastingFilter2[filterName];
		Tools.FilterHelper.parseFilters(newFilterFormat, 'forecasting', rb, undefined, { getConfig });

		rb.queryArray = rb.queryArray.map(filter => {
			if (filter.a === 'user' && Array.isArray(filter.v) && filter.v.includes('self')) {
				const id = Tools.AppService.getSelf().id;
				const i = filter.v.indexOf('self');
				filter.v[i] = id;
			}
			return filter;
		});
		if (excludedOpportunities.length) {
			rb.queryArray.push({ a: 'order', c: 'ne', v: excludedOpportunities });
		}

		const forecastFilters = {
			...rb.build(),
			sorting: `${sorting.field} ${sortType}`,
			weighted: weighted,
			valueType: undefined as string | undefined
		};

		if (Tools.FeatureHelper.hasSoftDeployAccess('FORECAST_USE_SALESBOARD_MODEL')) {
			forecastFilters.valueType = props.valueType;
		}

		WidgetResource.find('FORECAST', forecastFilters)
			.then((res: { data: ForecastData }) => {
				const newForecastData = {
					currency: res.data.currency ?? forecastData?.currency,
					total: opts?.updateTotal ? res.data.total : forecastData?.total ?? {},
					graph: opts?.updateGraph ? res.data.graph : forecastData?.graph ?? {},
					table: opts?.updateTable ? res.data.table : forecastData?.table ?? []
				};
				setForecastData(newForecastData as ForecastData);
				if (opts?.updateTotal) {
					prepareSectionData(res.data, filters);
				}
			})
			.catch(err => logError(err, 'Could not fetch forecast data'));
	};

	const onFilterChange = (filter: ListViewFilter) => {
		const newFilters = filters.map((fil: ListViewFilter) => {
			if (fil.filterName === filter.filterName) {
				return filter;
			} else {
				return fil;
			}
		});
		setFilters(newFilters);
		getData(newFilters, sorting);
	};

	useEffect(() => {
		if (!firstRender.current) {
			getData(filters);
		}
	}, [weighted]);

	useEffect(() => {
		if (!firstRender.current) {
			getData(filters, sorting, { updateTable: false, updateGraph: false });
		}
	}, [excludedOpportunities]);

	useEffect(() => {
		if (firstRender.current) {
			getData(filters);
			firstRender.current = false;
		}
	}, []);

	useEffect(() => {
		const events = [
			'order.added',
			'order.updated',
			'order.deleted',
			'opportunity.added',
			'opportunity.updated',
			'opportunity.deleted',
			'agreement.added',
			'agreement.updated',
			'agreement.deleted',
			'agreementGroup.added',
			'agreementGroup.updated',
			'agreementGroup.deleted'
		];
		const unsubscribeFunctions = events.map(event => Tools.$rootScope.$on(event, () => getData(filters, sorting)));

		return () => {
			unsubscribeFunctions.forEach(fn => fn());
		};
	}, [filters, sorting]);

	const onSortChange = (newSorting: { field: string; asc: boolean }) => {
		getData(filters, newSorting, { updateTotal: false, updateGraph: false });
		setSorting(newSorting);
	};

	let currentPeriod = filters.find(filter => filter.filterName === 'Date')?.value[0];
	currentPeriod = currentPeriod.charAt(0).toLowerCase() + currentPeriod.slice(1);

	const periodLang = T(`date.${currentPeriod}`);
	const title = `${T('default.sales')} ${periodLang.toLowerCase()}`;
	const isSingleUser = filters.find(filter => filter.filterName === 'User')?.value.length === 1;

	return (
		<div className={classes.b()}>
			<div className={classes.elem('wrapper').b()}>
				<DrawerHeader title={T('default.forecast')} onHide={props.close}>
					<ForecastingFilters filters={filters} onFilterChange={onFilterChange} />
				</DrawerHeader>
				{forecastData ? (
					<div className={classes.elem('content').b()}>
						<Block space="pbxl ptxl plxl prxl" className={classes.elem('main-block').b()}>
							<Title size="lg">{title}</Title>
							{firstSectionData.length ? (
								<Block space="ptl" className={classes.elem('data-wrapper').b()}>
									{firstSectionData.map(section => (
										<DataBox key={section.title} {...section} />
									))}
								</Block>
							) : null}
							<Block space="ptxl prxl">
								<Title size="lg">{T('forecasting.pace')}</Title>
								<ForecastingGraph
									pace={forecastData.total.pace}
									sales={forecastData.total.sales}
									target={forecastData.total.target}
									graphData={forecastData.graph}
									currentPeriod={currentPeriod}
									currency={forecastData.currency}
								/>
								<div className={classes.elem('legend-wrapper').b()}>
									{graphLegends.map(legend => legend)}
								</div>
							</Block>
							<ForecastingPipeline
								filters={filters}
								remaining={forecastData.total.remaining}
								pipelineCoverageRatio={forecastData.total.pipelineCoverageRatio}
								weighted={weighted}
								setWeighted={setWeighted}
								excludedOpportunities={excludedOpportunities}
								isSingleUser={isSingleUser}
							/>
							{secondSectionData.length ? (
								<Block space="ptl" className={classes.elem('data-wrapper').b()}>
									{secondSectionData.map(section => (
										<DataBox key={section.title} {...section} />
									))}
								</Block>
							) : null}
						</Block>
						{forecastData.table.length ? (
							isSingleUser && !forecastData.table[0].drilldown.length ? null : (
								<Block>
									<Title size="lg" className={classes.elem('main-block').b()}>
										{isSingleUser ? T('default.opportunities') : T('forecasting.perUser')}
										<Title size="sm" className={classes.elem('subtitle').b()}>
											{isSingleUser
												? T('forecasting.perUser.subtitle2')
												: T('forecasting.perUser.subtitle')}
											<Tooltip title={T('forecasting.perUser.helpText')}>
												<Icon
													className={classes.elem('help-icon').b()}
													name="question-circle"
												/>
											</Tooltip>
										</Title>
									</Title>
									<ForecastingTable
										tableRows={forecastData.table || []}
										sorting={sorting}
										onSortChange={onSortChange}
										excluded={excludedOpportunities}
										setExcluded={setExcludedOpportunities}
										isSingleUser={isSingleUser}
										currency={forecastData.currency}
										hasTarget={forecastData.total.target.value !== null}
									/>
								</Block>
							)
						) : null}
					</div>
				) : (
					<Loader size="lg" />
				)}
			</div>
		</div>
	);
};
export default ForecastingDrawer;
