import './CompetitorDrawer.scss';
import React, { useEffect, useState, useMemo, useRef } from 'react';
import { connect } from 'react-redux';
import { CompetitorState } from 'Store/reducers/CompetitorReducer';
import { fetchCompetitors, mergeCompetitors, FetchCompetitorOptions } from 'Store/actions/CompetitorActions';
import UpSelect from 'Components/Inputs/UpSelect';
import BemClass from '@upsales/components/Utils/bemClass';
import { Row, Help, Icon, Text, Block, Column, Loader, Button, DrawerHeader } from '@upsales/components';
import T from 'Components/Helpers/translate';
import CompetitorRow from './CompetitorRow';
import { Competitor } from 'Resources/Competitor';

interface Store {
	Competitor: CompetitorState;
}

const mapStateToProps = (state: Store) => ({
	competitors: state.Competitor.competitors
});

const mapDispatchToProps = {
	fetchCompetitors,
	mergeCompetitors
};

interface CompetitorDrawerProps {
	close: () => void;
	className?: string;
	fetchCompetitors: (options: FetchCompetitorOptions) => void;
	competitors: Competitor[];
	mergeCompetitors: (competitorIdsToRemove: number[], replacementCompetitorId: number) => void;
}

interface CompetitorOption {
	id: number;
	name: string;
}

interface UpSelectOnChangeValue {
	target: {
		added: CompetitorOption | undefined;
		removed: CompetitorOption | undefined;
		value: string;
	};
}

const CompetitorDrawer = ({
	competitors,
	close,
	mergeCompetitors,
	fetchCompetitors,
	className
}: CompetitorDrawerProps) => {
	const classes = new BemClass('CompetitorDrawer', className);
	const [selectedCompetitors, setSelectedCompetitors] = useState<number[]>([]);
	const mergeMode = !!selectedCompetitors?.length;
	const [competitorIdToKeep, setCompetitorIdToKeep] = useState<number | null>(null);

	const [pendingMergeMode, setPendingMergeMode] = useState(false);
	const [pendingMergeDone, setPendingMergeDone] = useState(false);
	const mergeDoneTimer = useRef<NodeJS.Timeout | null>(null);

	const cancelMergeMode = () => {
		setCompetitorIdToKeep(null);
		setSelectedCompetitors([]);
	};

	useEffect(() => {
		if (pendingMergeDone) {
			cancelMergeMode();
			mergeDoneTimer.current = setTimeout(() => {
				setPendingMergeDone(false);
			}, 1500);
		}

		return () => {
			if (mergeDoneTimer.current) {
				clearTimeout(mergeDoneTimer.current);
			}
		};
	}, [pendingMergeDone]);

	const competitorToKeep = useMemo(() => {
		if (!competitorIdToKeep) {
			return null;
		}
		return competitors?.find(competitor => competitor.id === competitorIdToKeep) ?? null;
	}, [competitors, competitorIdToKeep]);

	const lang = {
		mergeDescription: T('competitorDrawer.mergeDescription'),
		drawerHeaderTitle: T('competitorDrawer.drawerHeaderTitle'),
		competitorHelpText: T('competitorDrawer.competitorHelpText'),
		columnCompetitors: T('competitorDrawer.columnCompetitors'),
		columnNumberOfTimesAdded: T('competitorDrawer.columnNumberOfTimesAdded'),
		mergeTextSingular: T('competitorDrawer.mergeText.singular'),
		mergeTextPlural: T('competitorDrawer.mergeText.plural', { selectedCount: selectedCompetitors.length }),
		mergeButtonWithoutSelected: T('competitorDrawer.mergeButtonTextWithoutSelected'),
		mergeButtonWithSelectedSingular: T('competitorDrawer.mergeButtonTextWithSelected.singular', {
			competitor: competitorToKeep?.name
		}),
		mergeButtonWithSelectedPlural: T('competitorDrawer.mergeButtonTextWithSelected.plural', {
			selectedCount: selectedCompetitors.length,
			competitor: competitorToKeep?.name
		}),
		cancelMergeButton: T('competitorDrawer.cancelMergeButtonText'),
		mergeSelectPlaceholder: T('competitorDrawer.mergeSelectPlaceholder'),
		mergeSelectOptionsHeader: T('competitorDrawer.mergeSelectOptionsHeader'),
		mergeDone: T('competitorDrawer.mergeDone'),
		noCompetitorsAdded: T('competitorDrawer.noCompetitorsAdded'),
		noCompetitorsAddedText: T('competitorDrawer.noCompetitorsAddedText')
	};
	const competitorArticleId = 1229;

	useEffect(() => {
		fetchCompetitors?.({ includeOrderCount: true });
	}, []);

	const onCompetitorClick = (competitorId: number, selected: boolean) => {
		const updatedSelectedCompetitors = selected
			? [...selectedCompetitors, competitorId]
			: selectedCompetitors.filter(selectedCompetitor => {
					return selectedCompetitor !== competitorId;
			  });
		setSelectedCompetitors(updatedSelectedCompetitors);
	};

	const selectableCompetitors = useMemo(() => {
		return competitors?.filter((competitor: Competitor) => !selectedCompetitors.includes(competitor.id)) ?? [];
	}, [competitors, selectedCompetitors]);

	const parseCompetitorToSelectOption = (competitor: Competitor) => ({
		id: competitor.id,
		name: competitor.name
	});

	const competitorOptions = useMemo(() => {
		return [
			{
				name: lang.mergeSelectOptionsHeader,
				children: selectableCompetitors.map(parseCompetitorToSelectOption) ?? []
			}
		];
	}, [selectableCompetitors]);

	const onMergeButtonClick = async () => {
		if (!competitorIdToKeep) {
			return;
		}
		setPendingMergeMode(true);
		await mergeCompetitors?.(selectedCompetitors, competitorIdToKeep);
		setPendingMergeDone(true);
		setPendingMergeMode(false);
	};

	const renderNormalMode = () => (
		<Block className={classes.elem('normalMode').mod({ visible: !mergeMode }).b()}>
			<Block className={classes.elem('normalModeLeftSide').b()}>
				<Text className={classes.elem('mergeDescription').b()}>{lang.mergeDescription}</Text>
				<Block space="mts">
					<Help sidebar={true} articleId={competitorArticleId} className={classes.elem('competitorHelp').b()}>
						<Text color="bright-blue">{lang.competitorHelpText}</Text>
					</Help>
				</Block>
			</Block>
			<Block>
				<img className={classes.elem('mergeImage').b()} src="img/competitor-merge.svg" />
			</Block>
		</Block>
	);

	const renderMergeMode = () => {
		const mergeButtonClasses = classes.elem('mergeButton');
		mergeButtonClasses.add('text-ellipsis');
		return (
			<Block space="prxl" className={classes.elem('mergeMode').mod({ visible: mergeMode }).b()}>
				<Block space="mbl" className={classes.elem('keepCompetitorContainer').b()}>
					<Block space="mrm" className={classes.elem('keepCompetitorText').b()}>
						<Text className={classes.elem('mergeText').b()}>
							{selectedCompetitors.length === 1 ? lang.mergeTextSingular : lang.mergeTextPlural}
						</Text>
					</Block>

					<UpSelect
						defaultValue={competitorToKeep ? parseCompetitorToSelectOption(competitorToKeep) : undefined}
						key={`keep-competitor-select-${competitors?.length}-${selectableCompetitors.length}-${mergeMode}`}
						className={classes.elem('keepCompetitorSelector').b()}
						onChange={(selected: UpSelectOnChangeValue) => {
							setCompetitorIdToKeep(selected?.target?.added?.id ?? null);
						}}
						options={{ placeholder: lang.mergeSelectPlaceholder }}
						data={competitorOptions}
						disabled={!selectableCompetitors.length}
					/>
				</Block>

				<Block className={classes.elem('buttonContainer').b()}>
					<Block space="mrl" className={classes.elem('mergeButtonBlock').b()}>
						<Button
							block
							onClick={onMergeButtonClick}
							disabled={!competitorIdToKeep}
							color={!competitorIdToKeep ? 'grey' : 'green'}
							className={mergeButtonClasses.b()}
						>
							<Icon space="mrm" name="merge" />
							{!selectedCompetitors.length || !competitorIdToKeep
								? lang.mergeButtonWithoutSelected
								: selectedCompetitors.length === 1
								? lang.mergeButtonWithSelectedSingular
								: lang.mergeButtonWithSelectedPlural}
						</Button>
					</Block>

					<Button
						color="grey"
						type="link"
						className={classes.elem('cancelMergeButton').b()}
						onClick={cancelMergeMode}
					>
						{lang.cancelMergeButton}
					</Button>
				</Block>
			</Block>
		);
	};

	const renderPendingMergeMode = () => (
		<Block
			space="prxl"
			className={classes
				.elem('pendingMergeMode')
				.mod({ visible: pendingMergeMode || pendingMergeDone })
				.b()}
		>
			<Loader className={classes.elem('pendingMergeLoader').mod({ visible: pendingMergeMode }).b()} />
			<Block className={classes.elem('pendingMergeDone').mod({ visible: pendingMergeDone }).b()}>
				<Icon color="green" name="check" />
				<Text>{lang.mergeDone}</Text>
			</Block>
		</Block>
	);

	const renderTableHeader = () => (
		<Block space="ptl prxl pbm plxl">
			<Row className={classes.elem('tableHeader').b()}>
				<Column>
					<Text size="sm">{lang.columnCompetitors}</Text>
				</Column>
				<Column>
					<Text size="sm" className={classes.elem('columnNumberOfTimesAdded').b()}>
						{lang.columnNumberOfTimesAdded}
					</Text>
				</Column>
			</Row>
		</Block>
	);

	return (
		<div className={classes.b()}>
			<DrawerHeader onHide={close} title={lang.drawerHeaderTitle} className={classes.elem('drawerHeader').b()}>
				<Block space="plxl" className={classes.elem('drawerHeaderContent').b()}>
					{renderNormalMode()}
					{renderMergeMode()}
					{renderPendingMergeMode()}
				</Block>
			</DrawerHeader>
			{renderTableHeader()}
			<Block className={classes.elem('competitorRowContainer').b()}>
				{competitors?.map(competitor => (
					<CompetitorRow
						key={competitor.id}
						competitor={competitor}
						onClick={onCompetitorClick}
						keep={competitorIdToKeep === competitor.id}
						selected={selectedCompetitors.includes(competitor.id)}
					/>
				))}
				{!competitors?.length && (
					<Block space="prxl plxl" className={classes.elem('noCompetitorsText').b()}>
						<Text>{lang.noCompetitorsAdded}</Text>
						<Text>{lang.noCompetitorsAddedText}</Text>
					</Block>
				)}
			</Block>
		</div>
	);
};

export const detached = CompetitorDrawer;

export default connect(mapStateToProps, mapDispatchToProps)(CompetitorDrawer);
