import Resource from './Resource';

class IndexIncreaseSettings extends Resource {
	constructor() {
		super('indexIncreaseSettings', null, { notificationTitle: 'admin.subscriptionIndexing' });
	}

	get(): Promise<any> {
		return super.get(0);
	}

	find(): Promise<any> {
		throw new Error('Method not allowed');
	}

	findAll(): Promise<any> {
		throw new Error('Method not allowed');
	}

	delete(): Promise<any> {
		throw new Error('Method not allowed');
	}
}

const resource = new IndexIncreaseSettings();

export default resource;
