import { ReactNode, Component, ErrorInfo } from 'react';
import logError from 'App/babel/helpers/logError';

type Props = {
	fallback?: () => ReactNode;
	children: ReactNode;
};

type State = {
	hasError: boolean;
};

// Taken from: https://react.dev/reference/react/Component#catching-rendering-errors-with-an-error-boundary
class ErrorBoundary extends Component<Props, State> {
	public state: State = {
		hasError: false
	};

	static getDerivedStateFromError() {
		// Update state so the next render will show the fallback UI.
		return { hasError: true };
	}

	componentDidCatch(error: Error, errorInfo: ErrorInfo) {
		logError(error, 'There was an error in the react component tree', errorInfo);
	}

	render() {
		if (this.state.hasError) {
			// You can render any custom fallback UI
			return this.props.fallback?.() ?? null;
		}

		return this.props.children;
	}
}

export default ErrorBoundary;
