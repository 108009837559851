import { Change, ChangeRow } from 'App/babel/resources/ARRChangesOnDate';
import TooltipOverflowText from 'App/components/Text/TooltipOverflowText';
import { CurrencyFormat } from 'App/babel/utils/numberFormat';
import BemClass from '@upsales/components/Utils/bemClass';
import T from 'Components/Helpers/translate';
import { Text, Row } from '@upsales/components';
import React from 'react';

import './ChangedRow.scss';

type Props = {
	changeType: Change['type'];
	changeRow: ChangeRow;
	currency: string;
	mrr: boolean;
};

const ChangedRow = ({ changeType, changeRow, currency, mrr }: Props) => {
	const classes = new BemClass('ChangedRow');
	const currencyFormat = new CurrencyFormat(currency);
	const recurringType = T(mrr ? 'default.monthlyValue' : 'default.annualValue');
	const quantityChanged = changeRow.quantity !== changeRow.prevQuantity;
	const tierQuantityChanged = changeRow.tierQuantity !== changeRow.prevTierQuantity;
	const priceChanged = changeRow.price !== changeRow.prevPrice;
	const intervalChanged = changeRow.prevOrderInterval && changeRow.orderInterval !== changeRow.prevOrderInterval;
	const isNew = ['ADDED', 'NEW_CLIENT', 'WIN_BACK'].indexOf(changeType) >= 0;

	return (
		<div className={classes.b()}>
			<Text className={classes.elem('product').b()}>
				<TooltipOverflowText tooltipProps={{ title: changeRow.product?.name }}>
					{changeRow.product?.name}
				</TooltipOverflowText>
			</Text>
			<div className={classes.elem('change').b()}>
				<Text size="sm" color="grey-11">
					{T('default.quantity')}
				</Text>
				{(quantityChanged || tierQuantityChanged) && !isNew ? (
					<>
						<Text className={classes.elem('previous').b()}>
							{tierQuantityChanged ? changeRow.prevTierQuantity : changeRow.prevQuantity}
						</Text>
						<Text>{` -> ${tierQuantityChanged ? changeRow.tierQuantity : changeRow.quantity}`}</Text>
					</>
				) : (
					<Text italic={!isNew} color={isNew ? 'black' : 'grey-10'}>
						{changeRow.tierQuantity}
					</Text>
				)}
			</div>
			<div className={classes.elem('change').b()}>
				<Text size="sm" color="grey-11">
					<Row>
						{`${T('default.price')} (`}
						{intervalChanged && !isNew ? (
							<>
								<Text size="sm" color="grey-11" className={classes.elem('previous').b()}>
									{changeRow.prevOrderInterval}
								</Text>
								<Text size="sm" color="grey-11">{` -> `}</Text>
							</>
						) : null}
						{`${changeRow.orderInterval} ${
							changeRow.orderInterval === 1 ? T('default.month') : T('default.months')
						}`}
						{`)`}
					</Row>
				</Text>
				{priceChanged && !isNew ? (
					<>
						<Text className={classes.elem('previous').b()}>
							{currencyFormat.short(changeRow.prevPrice)}
						</Text>
						<Text>{` -> ${currencyFormat.short(changeRow.price)}`}</Text>
					</>
				) : (
					<Text italic={!isNew} color={isNew ? 'black' : 'grey-10'}>
						{currencyFormat.short(changeRow.price)}
					</Text>
				)}
			</div>
			<Text
				className={classes.elem('sum').b()}
				color={changeRow.value < 0 ? 'danger-5' : 'success-6'}
			>{`${currencyFormat.short(changeRow.value)} (${recurringType})`}</Text>
		</div>
	);
};

export default ChangedRow;
