import { useTranslation } from 'Components/Helpers/translate';
import React, { ComponentProps, useMemo } from 'react';
import type Client from 'App/resources/Model/Client';
import ClientResource from 'App/resources/Client';
import GenericSelectEntityModal, { SelectTypeWithChildren } from '../GenericSelectEntityModal/GenericSelectEntityModal';
import type { ModalProps } from 'App/components/Modals/Modals';
import useSelector from '../hooks/useSelector/useSelector';
import { comparisonTypes } from 'Resources/RequestBuilder';
import { useFeatureAvailable, Feature, useSoftDeployAccess } from '../hooks/featureHelper';
import { formatWithSubAccounts } from 'App/helpers/accountsHelper';
import { Metadata } from 'Resources/ResourceTyped';
import SubAccountLabel from 'Components/Misc/SubAccountLabel';
import { Flex, TableColumn } from '@upsales/components';

type Props = ModalProps<Client> & {
	excludeIds?: number[];
	modifyFilters?: ComponentProps<typeof GenericSelectEntityModal>['modifyFilters'];
};

type clientSelectResponse = Promise<{ data: Client[]; metadata: Metadata }>;

const FindClientModal = ({ excludeIds, modifyFilters, ...props }: Props) => {
	const { t } = useTranslation();
	const onlyActive = useFeatureAvailable(Feature.INACTIVE_COMPANIES);
	const hasSubAccounts = useSoftDeployAccess('SUB_ACCOUNTS');

	const { customerId, selfId } = useSelector(({ App }) => ({
		customerId: App.customerId,
		selfId: App.self?.id
	}));

	const latestAccounts = useMemo(() => {
		const latAccounts = (selfId ? Tools.LatestAccountsService.get(customerId, selfId, { onlyActive }) : []).filter(
			({ id }) => !(excludeIds || []).includes(id)
		);
		return hasSubAccounts
			? (formatWithSubAccounts(latAccounts, t) as SelectTypeWithChildren<Client>[])
			: latAccounts;
	}, [customerId, selfId]);

	return (
		<GenericSelectEntityModal<Client>
			{...props}
			title={`${t('default.search')} ${t('default.account').toLowerCase()}`}
			displayAttr="name"
			initialData={latestAccounts}
			modifyFilters={rb => {
				if (onlyActive) {
					rb.addFilter({ field: 'active' }, comparisonTypes.Equals, true);
				}
				if (excludeIds?.length) {
					rb.addFilter({ field: 'id' }, comparisonTypes.NotEquals, excludeIds);
				}

				modifyFilters?.(rb);
			}}
			formatDataRender={data => (
				<TableColumn style={{ paddingLeft: '24px' }}>
					<Flex alignItems="center">
						{data.name}
						<SubAccountLabel operationalAccount={data.operationalAccount} />
					</Flex>
				</TableColumn>
			)}
			fetch={filters => {
				if (hasSubAccounts) {
					return ClientResource.find(filters).then(result => {
						const res = {
							...result,
							data: formatWithSubAccounts(result.data, t)
						};
						return res;
					}) as clientSelectResponse;
				}
				return ClientResource.find(filters);
			}}
		/>
	);
};

export default FindClientModal;
