import React, { ComponentProps } from 'react';
import { Block } from '@upsales/components';
import BemClass from '@upsales/components/Utils/bemClass';
import Client from 'App/resources/Model/Client';

import './CreditRatingLabel.scss';

type Props = ComponentProps<typeof Block> & {
	prospectingCreditRating: Client['prospectingCreditRating'];
};

export default function CreditRatingLabel({ prospectingCreditRating, ...blockProps }: Props) {
	const classes = new BemClass('CreditRatingLabel');
	const category = prospectingCreditRating === null ? 'other' : prospectingCreditRating;

	return (
		<Block className={classes.mod({ [category]: true }).b()} {...blockProps}>
			{category === 'other' ? '-' : category}
		</Block>
	);
}
