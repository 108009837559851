import Resource from 'Resources/Resource';

class ProjectPlanStatusResource extends Resource {
	constructor() {
		super('ProjectPlanStatus');
	}
}

const resource = new ProjectPlanStatusResource();

export default resource;
