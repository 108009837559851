import React, { type ChangeEvent } from 'react';
import { Button, Icon, IconName, Input } from '@upsales/components';
import BemClass from '@upsales/components/Utils/bemClass';
import FileType from 'App/resources/Model/File';
import FileResource from 'Resources/File';
import T from 'Components/Helpers/translate';

import './FileUploadButton.scss';

interface Props {
	onFileUploaded?: (file: FileType) => void;
	text?: string;
	icon?: IconName;
	onChangeHandler?: never;
}

type UploadLaterProps = {
	onChangeHandler: (e: ChangeEvent<HTMLInputElement>) => void;
	onFileUploaded?: never;
	text?: string;
	icon?: IconName;
};

const FileUploadButton: React.FC<Props | UploadLaterProps> = ({
	onFileUploaded = () => {},
	text = T('mailDrawer.addFiles'),
	icon = 'paperclip',
	onChangeHandler: onChangeHandlerProp
}) => {
	const classes = new BemClass('FileUploadButton');
	const inputRef = React.useRef<HTMLInputElement>(null);
	const handleClick = () => {
		if (inputRef.current) {
			inputRef.current.click();
		}
	};
	const onChangeHandler: React.ComponentProps<typeof Input>['onChange'] = async e => {
		if (e.target.files && e.target.files.length) {
			const file = await FileResource.upload(e.target.files[e.target.files.length - 1], {
				isMailAttachment: true
			});
			onFileUploaded(file);
		}
	};

	return (
		<div className={classes.b()}>
			<Input
				type="file"
				onChange={onChangeHandlerProp ?? onChangeHandler}
				onClick={e => ((e.target as HTMLInputElement).value = '')}
				inputRef={inputRef}
			></Input>
			<Button type="link" onClick={handleClick}>
				<Icon name={icon} />
				{text}
			</Button>
		</div>
	);
};
export default FileUploadButton;
