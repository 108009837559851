import getAngularModule from 'App/babel/angularHelpers/getAngularModule';
import { find, findIndex, cloneDeep } from 'lodash';
import entityName from 'Components/Helpers/EntityName';
import t from 'Components/Helpers/translate';
import history from 'App/pages/routes/history';
import { getLiveTags } from 'Store/actions/SocialEventActions';
import colorMappings from '@upsales/components/Utils/colorMappings';
import openModal from 'App/services/Modal';
import { getConfirmationTitle } from 'App/components/Alerts/ConfirmationTexts';
import AlertConfirm from 'App/babel/components/Dialogs/AlertConfirm';
import AlertBody from 'App/babel/components/Dialogs/Body/AlertBody';

export const TABS = {
	DASHBOARD: 'dashboard',
	OPTIONS: 'publishingOptions'
};
export const NUMBER_OF_DAYS = 30;
export const THANK_YOU_ELEMENT_TYPE = {
	LANDING_PAGE: 'landingPage'
};
export const DATE_FORMAT = 'YYYY-MM-DD';
const prefix = '[FormOverview]';

export const RESET = `${prefix} RESET`;
export const UPDATE = `${prefix} UPDATE`;
export const FETCH_TABLE_PENDING = `${prefix} FETCH_TABLE_PENDING`;
export const FETCH_TABLE_FULFILLED = `${prefix} FETCH_TABLE_FULFILLED`;
export const FETCH_TABLE_REJECTED = `${prefix} FETCH_TABLE_REJECTED`;
export const SET_EMBEDDABLE_SCRIPT = `${prefix} SET_EMBEDDABLE_SCRIPT`;
export const SET_SORTING = `${prefix} SET_SORTING`;
export const SET_LOAD_ERROR = `${prefix} SET_LOAD_ERROR`;
export const SET_TABLE_VIEW = `${prefix} SET_TABLE_VIEW`;
export const SELECT_TAB = `${prefix} SELECT_TAB`;
export const SET_OFFSET = `${prefix} SET_OFFSET`;

export const TABLE_TABS = {
	FORM_SUBMITS: 'formSubmits',
	VISITS: 'visits'
};
export const STAT = {
	[TABLE_TABS.FORM_SUBMITS]: {
		columns: [
			{ title: 'date', sort: 'processedDate', sortType: 'numeric' },
			{ title: 'contactAndClient' },
			{ title: 'history', name: 'status' },
			{ title: 'leadsource' },
			{ title: '' }
		]
	},
	[TABLE_TABS.VISITS]: {
		columns: [
			{ title: 'date', name: 'startDate', sort: 'startDate' },
			{ title: 'company', name: 'account', sort: 'client.name' },
			{ title: 'history', name: 'status' },
			{ title: 'city', name: 'location', sort: 'client.mailAddress.city' },
			{ title: 'assignedTo', name: 'assigned' },
			{ title: 'leadScore', name: 'score', sort: 'score' },
			{ title: 'leadsource', name: 'leadSource', sort: 'leadSource.type' },
			{ title: '' }
		]
	}
};

const setSeriesLength = buckets => {
	if (!buckets.length) {
		return _.range(0, NUMBER_OF_DAYS, 0);
	}

	const series = [];
	const startDate = +moment();
	const dayInMs = 60 * 60 * 24 * 1000; // ms in day

	for (let i = NUMBER_OF_DAYS - 1; i >= 0; i--) {
		const dd = startDate - dayInMs * i;
		const day = moment(dd).format(DATE_FORMAT);
		const value = _.find(buckets, { key_as_string: day });

		if (value) {
			series.push(value.doc_count);
			continue;
		}
		series.push(0);
	}
	return series;
};

const mapDataToSeries = (visit, submit) => {
	let visits = setSeriesLength(visit);
	let submits = setSeriesLength(submit);
	let iStart = 0;
	let iEnd = 0;

	for (let i = 0; i < visits.length; i++) {
		if ((visits[i] || submits[i]) && !iStart) {
			iStart = i;
		}

		if (visits[i] || submits[i]) {
			iEnd = i;
		}
	}
	visits = visits.slice(iStart, iEnd + 1);
	submits = submits.slice(iStart, iEnd + 1);

	return { visits, submits };
};

const getGraphCategories = (visit = [], submit = []) => {
	return Object.keys(
		[...visit, ...submit].reduce((prev, next) => {
			prev[next.key_as_string] = 1;
			return prev;
		}, {})
	).sort();
};

export const getSubmits = deletedId => (dispatch, getState) => {
	const name = TABLE_TABS.FORM_SUBMITS;

	dispatch({ type: FETCH_TABLE_PENDING, name });
	const { limit, form } = getState().FormOverview;
	const { sorting, offset } = getState().FormOverview[TABLE_TABS.FORM_SUBMITS];

	const AppService = getAngularModule('AppService');
	const FormSubmit = getAngularModule('FormSubmit');
	const customerId = AppService.getCustomerId();
	const RequestBuilder = getAngularModule('RequestBuilder');
	const formSubmitRb = new RequestBuilder();
	formSubmitRb.addFilter(FormSubmit.attr.form, formSubmitRb.comparisonTypes.Equals, form.id);
	if (deletedId) {
		formSubmitRb.addFilter(FormSubmit.attr.id, formSubmitRb.comparisonTypes.NotEquals, deletedId);
	}
	formSubmitRb.limit = limit;
	formSubmitRb.offset = offset;
	formSubmitRb.addSort(sorting.attrName, sorting.ascending);

	return FormSubmit.customer(customerId)
		.find(formSubmitRb.build())
		.then(res => ({
			tableLoading: false,
			currentSorting: {},
			tableData: res.data,
			tableTotal: res.metadata.total,
			offset: res.metadata.offset
		}))
		.then(payload => dispatch({ type: FETCH_TABLE_FULFILLED, payload, name }))
		.catch(() => dispatch({ type: FETCH_TABLE_REJECTED, name }));
};

const removeProtocol = str => str.replace('http://', '').replace('https://', '');

export const getVisits = () => async (dispatch, getState) => {
	const name = TABLE_TABS.VISITS;
	dispatch({ type: FETCH_TABLE_PENDING, name });
	const { limit, form } = getState().FormOverview;
	const { sorting, offset } = getState().FormOverview[TABLE_TABS.VISITS];
	const AppService = getAngularModule('AppService');
	const Visitor = getAngularModule('Visitor');
	const customerId = AppService.getCustomerId();
	const RequestBuilder = getAngularModule('RequestBuilder');
	const visitRb = new RequestBuilder();
	const url = removeProtocol(`${Tools.LANDINGPAGEPATH}/${form.uuid}`);
	if (form.urlName) {
		const domains = await Tools.Domains.find();
		const or = visitRb.orBuilder();
		or.next();
		or.addFilter({ field: 'pages.url' }, visitRb.comparisonTypes.MatchExact, [url, `${url}-frame`]);
		domains.data.forEach(domain => {
			if (domain.verified) {
				or.next();
				or.addFilter(
					{ field: 'pages.url' },
					visitRb.comparisonTypes.MatchExact,
					`${domain.name}/${form.urlName}`
				);
			}
		});
		or.done();
	} else {
		visitRb.addFilter({ field: 'pages.url' }, visitRb.comparisonTypes.MatchExact, [url, `${url}-frame`]);
	}

	visitRb.limit = limit;
	visitRb.offset = offset;
	visitRb.addSort(sorting.attrName, sorting.ascending);

	return Visitor.customer(customerId)
		.find(visitRb.build())
		.then(res => ({
			tableLoading: false,
			currentSorting: {},
			tableData: res.data,
			tableTotal: res.metadata.total,
			offset: res.metadata.offset
		}))
		.then(payload => dispatch({ type: FETCH_TABLE_FULFILLED, payload, name }))
		.catch(() => dispatch({ type: FETCH_TABLE_REJECTED, name }));
};

export const getGraphConfig = ({ seriesVisit, seriesSubmits, categories }) => {
	const t = getAngularModule('$translate');

	const series = [
		{
			name: t('form.dashboard.submits'),
			color: colorMappings.get('green'),
			data: seriesSubmits
		},
		{
			name: t('form.dashboard.visits'),
			color: colorMappings.get('grey-6'),
			data: seriesVisit
		}
	];

	return {
		chart: {
			type: 'column',
			height: 200,
			backgroundColor: 'transparent',
			marginBottom: 45,
			marginLeft: 50,
			marginTop: 10
		},
		title: {
			text: ''
		},
		legend: {
			itemMarginTop: 25,
			y: 10,
			symbolRadius: 25,
			symbolHeight: 11,
			symbolWidth: 11
		},
		tooltip: {
			enabled: true,
			positioner: function (boxWidth, boxHeight, point) {
				let x = point.plotX;
				if (boxWidth + point.plotX > this.chart.container.clientWidth) {
					x = point.plotX - boxWidth;
				}
				return {
					x,
					y: point.plotY - boxHeight + 60
				};
			}
		},
		plotOptions: {
			series: {
				dataLabels: {
					enabled: false
				}
			},
			column: {
				stacking: 'normal',
				dataLabels: {
					enabled: true
				},
				pointWidth: 25
			}
		},
		credits: {
			enabled: false
		},
		xAxis: {
			categories,
			lineWidth: 1,
			lineColor: '#76879E',
			tickWidth: 0,
			labels: {
				enabled: false
			},
			formatter: function () {
				return this.value;
			}
		},
		yAxis: {
			visible: false
		},
		series: series
	};
};

const getPreviewHtml = async (form, accountProfile, optIns) => {
	const $templateParser = getAngularModule('$templateParser');

	return await new Promise((resolve, reject) => {
		$templateParser.parseDist(
			form,
			accountProfile,
			{ noTags: true, noVisit: true, noScript: true, optIns: optIns },
			(err, html) => {
				if (err) {
					reject('ERROR NOOO');
					return;
				}

				resolve(html);
			}
		);
	});
};

export const setEmbeddable = (form, accountProfile, optIns) => async dispatch => {
	const $templateParser = getAngularModule('$templateParser');
	const Form = getAngularModule('Form');
	const { href = null } = form.thankYouElement.value;

	const formHtml = await new Promise(resolve => {
		$templateParser.parseEmbedded(form, accountProfile, { optIns }, (err, html) => {
			resolve(html || '');
		});
	});

	const embedded = await Form.getExternalLeadSourceScript(href);

	dispatch({ type: SET_EMBEDDABLE_SCRIPT, payload: { script: formHtml + embedded.script } });
};

export const setLoadError = loadError => ({ type: SET_LOAD_ERROR, loadError });
export const setSelectedTab = selectedTab => ({ type: SELECT_TAB, selectedTab });

export const init = (params, search) => async dispatch => {
	const tab = search.get('tab');
	let selectedTab = TABS.DASHBOARD;

	if (Object.values(TABS).includes(tab)) {
		selectedTab = tab;
		history.replace({
			search: ''
		});
	}

	dispatch({ type: RESET });
	dispatch(setSelectedTab(selectedTab));

	const FormMeta = getAngularModule('FormMeta');
	let meta;

	try {
		meta = await FormMeta(params);
	} catch (e) {
		return dispatch(setLoadError(e));
	}

	const form = meta.form.data;
	const { landingPageBody } = form;
	const accountProfile = meta.accountProfile.data;
	const optIns = meta.optIns.data;

	const previewHtml = await getPreviewHtml(cloneDeep(form), accountProfile, optIns);

	const RequestBuilder = getAngularModule('RequestBuilder');
	const AppService = getAngularModule('AppService');
	const customerId = AppService.getCustomerId();

	/**** FOR FORM SUBMITS **/
	const totalSubmitRb = new RequestBuilder();
	const reportSubmitRb = new RequestBuilder();
	const startDate = moment().subtract(30, 'days');
	const url = removeProtocol(`${Tools.LANDINGPAGEPATH}/${form.uuid}`);
	const pages = [url, `${url}-frame`];

	/*** FORM SUBMITS ***/
	totalSubmitRb.addFilter(Tools.FormSubmit.attr.form.attr.id, totalSubmitRb.comparisonTypes.Equals, form.id);
	totalSubmitRb.limit = 0;

	// sum visit on page per day last thirty days
	reportSubmitRb.addFilter(Tools.FormSubmit.attr.form, reportSubmitRb.comparisonTypes.Equals, form.id);
	reportSubmitRb.addFilter(
		Tools.FormSubmit.attr.regDate,
		reportSubmitRb.comparisonTypes.GreaterThanEquals,
		startDate
	);

	const aggSubmit = reportSubmitRb.aggregationBuilder();
	aggSubmit.addAggregation(reportSubmitRb.aggregationTypes.Terms, 'regDate');
	aggSubmit.aggregationInterval(reportSubmitRb.aggregationIntervals.DAY);
	aggSubmit.aggregationName('sumSubmit');
	aggSubmit.done();

	/**** FOR VISITS (NOT FORMS USED WITH CODE FOR OWN DESIGN)  **/
	const reportVisitRb = new RequestBuilder();
	const totalVisitsRb = new RequestBuilder();
	reportVisitRb.addFilter({ field: 'pages.url' }, reportVisitRb.comparisonTypes.MatchExact, pages);
	reportVisitRb.addFilter(Tools.Visitor.attr.startDate, reportVisitRb.comparisonTypes.GreaterThan, startDate);

	// get total nr of visit for the page(s) on this landing page.
	totalVisitsRb.addFilter({ field: 'pages.url' }, reportVisitRb.comparisonTypes.MatchExact, pages);
	totalVisitsRb.limit = 0;

	// also get nr of visit in total for the form...
	const aggVisits = reportVisitRb.aggregationBuilder();
	aggVisits.addAggregation(reportVisitRb.aggregationTypes.Terms, 'startDate');
	aggVisits.aggregationInterval(reportVisitRb.aggregationIntervals.DAY);
	aggVisits.aggregationName('sumVisit');
	aggVisits.done();

	/** TOTAL SCORE FOR VISIT AND FORMS ***/
	// Submits
	const scoreFormRb = new RequestBuilder();
	scoreFormRb.addFilter(Tools.FormSubmit.attr.form, scoreFormRb.comparisonTypes.Equals, form.id);
	scoreFormRb.addFilter(Tools.FormSubmit.attr.score, scoreFormRb.comparisonTypes.NotEquals, 0);
	const aggSubmits = scoreFormRb.aggregationBuilder();
	aggSubmits.addAggregation(scoreFormRb.aggregationTypes.Sum, Tools.FormSubmit.attr.score);
	aggSubmits.aggregationName('submitSum');
	aggSubmits.done();

	// Visit (Only landing page)
	const scoreVisitRb = new RequestBuilder();
	scoreVisitRb.addFilter({ field: 'pages.url' }, scoreVisitRb.comparisonTypes.MatchExact, pages);
	scoreVisitRb.addFilter(Tools.Visitor.attr.score, scoreVisitRb.comparisonTypes.NotEquals, 0);

	const aggVisits2 = scoreVisitRb.aggregationBuilder();
	aggVisits2.addAggregation(scoreVisitRb.aggregationTypes.Sum, Tools.Visitor.attr.score);
	aggVisits2.aggregationName('visitSum');
	aggVisits2.done();

	const formTotal = await Tools.FormSubmit.customer(customerId).find(totalSubmitRb.build());
	const visitTotal = await Tools.Visitor.customer(customerId).find(totalVisitsRb.build());
	const formReport = await Tools.Report.customer(customerId)
		.setType(Tools.Report.type.FORMSUBMIT)
		.find(reportSubmitRb.build());
	const visitReport = await Tools.Report.customer(customerId)
		.setType(Tools.Report.type.VISIT)
		.find(reportVisitRb.build());
	const visitScore = await Tools.Report.customer(customerId)
		.setType(Tools.Report.type.VISIT)
		.find(scoreVisitRb.build());
	const formScore = await Tools.Report.customer(customerId)
		.setType(Tools.Report.type.FORMSUBMIT)
		.find(scoreFormRb.build());
	const socialEvent = await (form.socialEventId
		? window.Tools.SocialEvents.get(form.socialEventId)
		: Promise.resolve(false));

	const mapped = mapDataToSeries(visitReport.data.sumVisit.buckets, formReport.data.sumSubmit.buckets);
	const categories = getGraphCategories(visitReport.data.sumVisit.buckets, formReport.data.sumSubmit.buckets);

	const reCaptcha = find(form.fields, { name: 'Prefs.reCaptcha' });
	const thankYouElement = form.thankYouElement;
	const embeddable = thankYouElement && thankYouElement.type === 'landingPage' && !reCaptcha;

	if (embeddable) {
		await dispatch(setEmbeddable(form, accountProfile, optIns));
	}

	const data = {
		reCaptcha,
		embeddable,
		form,
		params,
		accountProfile,
		optIns,
		previewHtml,
		domains: meta.domains.data,
		totalSubmits: formTotal.metadata.total,
		totalVisits: visitTotal.metadata.total,
		seriesVisit: mapped.visits,
		seriesSubmits: mapped.submits,
		totalScore: visitScore.data.visitSum.value + formScore.data.submitSum.value,
		noData: visitReport.data.sumVisit.doc_count + formReport.data.sumSubmit.doc_count === 0,
		socialEvent: socialEvent ? socialEvent.data : null,
		isLandingPage: !!landingPageBody,
		graphConfig: getGraphConfig({ seriesVisit: mapped.visits, seriesSubmits: mapped.submits, categories })
	};

	dispatch({ type: UPDATE, payload: data });
	dispatch(getSubmits());
	dispatch(getVisits());
};

export const setTableView = tableView => dispatch => {
	dispatch({ type: SET_TABLE_VIEW, tableView });
};

export const setTableSorting =
	({ field, asc }) =>
	(dispatch, getState) => {
		const { tableView } = getState().FormOverview;

		dispatch({ type: SET_SORTING, name: tableView, payload: { attrName: field, ascending: asc } });

		switch (tableView) {
			case TABLE_TABS.FORM_SUBMITS:
				dispatch(getSubmits());
				break;
			case TABLE_TABS.VISITS:
				dispatch(getVisits());
				break;
		}
	};

export const exportSubmits = () => (dispatch, getState) => {
	const { form } = getState().FormOverview;
	const AppService = getAngularModule('AppService');
	const canExport = AppService.getSelf().export;
	const t = getAngularModule('$translate');
	const RequestBuilder = getAngularModule('RequestBuilder');
	const ExportService = getAngularModule('ExportService');
	const customerId = AppService.getCustomerId();

	if (!canExport) {
		return Tools.NotificationService.addNotification({
			style: Tools.NotificationService.style.ERROR,
			icon: 'error',
			title: 'default.error',
			body: 'noti.export.validFalse'
		});
	}

	const columns = [{ title: t('default.date'), key: 'FormSubmit_regDate' }];

	form.fields.forEach(field => {
		if (!field.name.includes('Prefs.')) {
			columns.push({ title: t(field.title), key: 'FormSubmit_fieldValues_' + field.name });
		}
	});

	const requestBuilder = new RequestBuilder();
	requestBuilder.addFilter(Tools.FormSubmit.attr.form.attr.id, requestBuilder.comparisonTypes.Equals, form.id);
	requestBuilder.addSort('regDate', false);

	ExportService.customer(customerId)
		.create('FormSubmit', requestBuilder.build(), columns, {})
		.then(() => {})
		.catch(e => console.error('Failed to export', e));
};

export const updateForm = newForm => async (dispatch, getState) => {
	const Form = getAngularModule('Form');

	const { data: form } = await Form.save(newForm, { skipNotification: false });

	const reCaptcha = find(form.fields, { name: 'Prefs.reCaptcha' });
	const thankYouElement = form.thankYouElement;
	const embeddable = thankYouElement && thankYouElement.type === 'landingPage' && !reCaptcha;
	const { accountProfile, optIns } = getState().FormOverview;

	if (embeddable) {
		await dispatch(setEmbeddable(form, accountProfile, optIns));
	}

	const previewHtml = await getPreviewHtml(cloneDeep(form), accountProfile, optIns);

	return dispatch({ type: UPDATE, payload: { form, reCaptcha, previewHtml } });
};

export const removeReCaptcha = () => (dispatch, getState) => {
	const form = cloneDeep(getState().FormOverview.form);
	const { optIns, params, accountProfile, domains, socialEvent } = getState().FormOverview;
	const $templateParser = getAngularModule('$templateParser');
	const index = findIndex(form.fields, { name: 'Prefs.reCaptcha' });

	if (index > -1) {
		form.fields.splice(index, 1);

		dispatch(updateForm(form));
	}

	$templateParser.parseDist(
		form,
		accountProfile,
		{
			optIns,
			domains,
			liveTags: params.liveTags,
			isSocialEvent: !!socialEvent
		},
		(err, html) => {
			form.html = html;
			dispatch(updateForm(form));
		}
	);
};

export const changeExternalLink = url => (dispatch, getState) => {
	const $templateParser = getAngularModule('$templateParser');
	const form = cloneDeep(getState().FormOverview.form);

	form.thankYouElement = {
		...$templateParser.getElementConfig('landingPage'),
		value: {
			href: url
		}
	};

	dispatch(updateForm(form));
};

export const copyForm = () => async (dispatch, getState) => {
	const Form = getAngularModule('Form');
	const $state = getAngularModule('$state');
	const form = cloneDeep(getState().FormOverview.form);
	const isLandingPage = getState().FormOverview.isLandingPage;
	const options = {
		id: form.id,
		copy: true
	};

	if (isLandingPage) {
		$state.go('landingpageEditor', options);
	} else {
		form.name = form.name + ' (' + t('default.copied').toLowerCase() + ')';
		delete form.id;

		const { data } = await Form.save(form, { skipNotification: true });

		window.Tools.routerHistory.push(`/form-editor/${data.id}`);
	}
};

export const archiveForm = () => async (dispatch, getState) => {
	const form = getState().FormOverview.form;
	const $upModal = getAngularModule('$upModal');
	const Form = getAngularModule('Form');

	let title = t('default.archive') + ' ' + t('form.form').toLowerCase();
	let body = 'confirm.archiveForm';
	let btnText = 'default.archive';

	if (form.isArchived) {
		title = t('default.unArchive') + ' ' + t('form.form').toLowerCase();
		body = 'confirm.unarchiveForm';
		btnText = 'default.unArchive';
	}

	if (Tools.FeatureHelper.hasSoftDeployAccess('REACT_ALERT_MODAL')) {
		openModal('Alert', {
			title: title,
			body: body,
			confirmButtonText: btnText,
			headerIcon: 'warning',
			onClose: async confirmed => {
				if (confirmed) {
					const { data } = await Form.archive(form.id, !form.isArchived);
					return dispatch({ type: UPDATE, payload: { form: data } });
				}
			}
		});
		return;
	}

	await $upModal.open('warningConfirm', {
		title: title,
		body: body,
		resolveTrue: btnText,
		icon: 'fa-warning'
	});

	const { data } = await Form.archive(form.id, !form.isArchived);

	return dispatch({ type: UPDATE, payload: { form: data } });
};

export const deleteForm = () => async (dispatch, getState) => {
	const form = getState().FormOverview.form;
	const AppService = getAngularModule('AppService');
	const $upModal = getAngularModule('$upModal');
	const Form = getAngularModule('Form');
	const customerId = AppService.getCustomerId();
	const confirmButtonText = t('admin.modal.deleteEntity', {
		entity: t(entityName('form', 1)).toLowerCase()
	});
	const alertConfirmOptions = {
		type: 'confirm',
		reactive: true,
		fullscreen: true,
		hideAbort: false,
		dialog: AlertConfirm,
		id: 'confirm-delete-fields',
		body: React.createElement(AlertBody, {
			customerId: customerId,
			numSelected: 1,
			entity: 'form'
		}),
		confirmButtonText: confirmButtonText,
		confirmClass: 'btn-red',
		confirmationMode: 'text',
		confirmText: AppService.getSelf().email,
		confirmFn: async () => {
			// Delete
			await Form['delete'](form);

			history.push('/forms');
		}
	};

	if (Tools.FeatureHelper.hasSoftDeployAccess('REACT_ALERT_MODAL')) {
		return openModal('Alert', {
			...alertConfirmOptions,
			title: getConfirmationTitle('form', 1),
			onClose: confirmed => {
				if (confirmed) {
					alertConfirmOptions.confirmFn();
				}
			}
		});
	}

	return $upModal.open('alert', alertConfirmOptions);
};

export const removeEntry =
	({ id, client }) =>
	dispatch => {
		const $upModal = getAngularModule('$upModal');
		const AppService = getAngularModule('AppService');
		const NotificationService = getAngularModule('NotificationService');
		const FormSubmit = getAngularModule('FormSubmit');
		const Account = getAngularModule('Account');
		const customerId = AppService.getCustomerId();

		const onRemoveEntry = onlyDeleteForm => {
			if (onlyDeleteForm === false && client) {
				return $upModal
					.open('errorPrompt', {
						title: 'default.enterEmail',
						body: 'confirm.removeFormSubmitConfirm',
						placeholder: 'default.email',
						required: true,
						type: 'email'
					})
					.then(function (email) {
						var userEmail = AppService.getSelf().email;

						if (email && email === userEmail) {
							// All formSubmits from this client will be deleted..
							return Account.customer(customerId)
								['delete'](client)
								.then(() => {
									dispatch(getSubmits());
								});
						} else {
							NotificationService.addNotification({
								title: 'login.anErrorOccured',
								body: 'confirm.removeFormSubmitConfirmFail',
								style: 'error',
								icon: 'times'
							});
						}
					});
			} else {
				return FormSubmit.customer(customerId)
					.delete(id)
					.then(() => {
						dispatch(getSubmits(id));
					});
			}
		};

		if (Tools.FeatureHelper.hasSoftDeployAccess('REACT_ALERT_MODAL')) {
			return new Promise((resolve, reject) => {
				openModal('Alert', {
					title: 'confirm.removeFormSubmit',
					body: <div dangerouslySetInnerHTML={{ __html: t('formSubmit.deleteInfo') }} />,
					confirmButtonText: Tools.$translate('form.deleteFormSubmit'),
					cancelButtonText: Tools.$translate('form.deleteFormSubmitWithAll'),
					headerIcon: 'warning',
					onClose: onlyDeleteForm => {
						if (onlyDeleteForm === undefined) {
							reject();
							return;
						}
						onRemoveEntry(onlyDeleteForm);
						resolve();
					}
				});
			});
		}

		return $upModal
			.open('warningConfirmLg', {
				title: 'confirm.removeFormSubmit',
				body: 'formSubmit.deleteInfo',
				resolveTrue: Tools.$translate('form.deleteFormSubmit'), //'Ta bort formulärsubmit',
				resolveTrueBtnClass: 'btn-orange',
				resolveFalse: Tools.$translate('form.deleteFormSubmitWithAll'),
				resolveFalseBtnClass: 'btn-lined btn-red',
				no: 'default.abort',
				icon: 'fa-warning'
			})
			.then(function (onlyDeleteForm) {
				onRemoveEntry(onlyDeleteForm);
			});
	};

export const editEntry = submit => (dispatch, getState) => {
	const form = getState().FormOverview.form;
	const $upModal = getAngularModule('$upModal');

	if (submit.hasOwnProperty('pages')) {
		return;
	}
	const options = {
		submit: submit,
		form
	};

	// eslint-disable-next-line promise/catch-or-return
	$upModal.open('editFormSubmit', options).then(() => {
		dispatch(getSubmits());
	});
};

export const saveTags = form => (dispatch, getState) => {
	const { accountProfile, socialEvent, domains } = getState().FormOverview;
	const newForm = _.cloneDeep(form);
	const NotificationService = getAngularModule('NotificationService');
	const templateParser = getAngularModule('$templateParser');

	if (newForm.isLandingPage) {
		if (newForm.landingPageBody.hasForm && !newForm.thankYouElement && !newForm.landingPage) {
			// Show notification
			NotificationService.addNotification({
				style: NotificationService.style.WARN,
				icon: 'warning',
				title: 'default.error',
				body: 'default.youHaveFormErrors'
			});
		}
	}

	templateParser.parseDist(
		newForm,
		accountProfile,
		{
			domains: domains,
			liveTags: socialEvent ? getLiveTags(socialEvent) : null,
			isSocialEvent: !!socialEvent
		},
		(err, html) => {
			newForm.html = html;
			dispatch(updateForm(newForm));
		}
	);
};

export const setOffset = offset => (dispatch, getState) => {
	const { tableView } = getState().FormOverview;

	dispatch({ type: SET_OFFSET, name: tableView, offset });

	switch (tableView) {
		case TABLE_TABS.FORM_SUBMITS:
			dispatch(getSubmits());
			break;
		case TABLE_TABS.VISITS:
			dispatch(getVisits());
			break;
	}
};
