import _ from 'lodash';
import T from 'Components/Helpers/translate';
import { CurrencyFormat, NumberFormat } from 'App/babel/utils/numberFormat';

export const currencyFormatDeprecated = (number: number | string) => {
	number = '' + number;

	let newNumber = '';
	let count = 1;

	for (let i = number.length - 1; i >= 0; i--) {
		newNumber = number[i] + newNumber;

		if (count++ % 3 === 0) {
			newNumber = ' ' + newNumber;
		}
	}

	return newNumber.trim();
};

export const numberFormatDeprecated = (
	number: number,
	noLetters: boolean,
	forceRound: boolean,
	skipBillion: boolean
): string => {
	if (Tools.FeatureHelper.hasSoftDeployAccess('NUMBER_FORMAT')) {
		const numberFormat = new NumberFormat();
		if (noLetters) {
			return numberFormat.default(number);
		} else {
			return numberFormat.short(number);
		}
	}
	const wasNegative = number < 0;
	let decPlaces = Math.pow(10, 1);

	number = Math.round(number);
	if (number < 0) {
		number = number * -1;
	}

	if (number < 1000000) {
		decPlaces = 1;
	}

	const pre = 'currencyDisplay.';
	const abbrev = ['k', 'm', 'b', 't'];

	if (noLetters) {
		return (wasNegative ? '-' : '') + currencyFormatDeprecated(number);
	}

	let returnStr = number + '';

	for (let i = abbrev.length - 1; i >= 0; i--) {
		const size = Math.pow(10, (i + 1) * 3);
		if (size <= number) {
			const lessThanMillion = number < 1000000;
			// Add the letter for the abbrevation
			if (!lessThanMillion || forceRound) {
				const log = Math.log10(number);
				if (lessThanMillion) {
					returnStr = Math.round((number * decPlaces) / size) / decPlaces + '';
				} else {
					returnStr = parseFloat(((number * decPlaces) / size / decPlaces).toFixed(2)) + ''; // using parseFloat for cases where we want 3.00m to just be 3m
				}
				if (skipBillion && log >= 9) {
					returnStr = currencyFormatDeprecated(Math.round(number / 1000000)) + T(pre + 'm');
				} else {
					returnStr += T(pre + abbrev[i]);
				}
			} else {
				returnStr = currencyFormatDeprecated(number);
			}
			if (window.userLocale === 'sv-SE') {
				returnStr = returnStr.replace('.', ',');
			}
			break;
		}
	}

	return (wasNegative ? '-' : '') + returnStr;
};

export const currencyDisplayDeprecated = (
	amount: number,
	noLetters: boolean = false,
	forceRound: boolean = false,
	skipBillion: boolean = false
) => {
	if (isNaN(amount)) {
		amount = 0;
	}

	return numberFormatDeprecated(amount, noLetters, forceRound, skipBillion);
};

export const numberFormat = (
	number: number,
	verbose: boolean = false,
	fractionDigits: number = 0,
	short: boolean = false,
	minimumFractionDigits?: number,
	removeTrailingZeros?: boolean
) => {
	if (Tools.FeatureHelper.hasSoftDeployAccess('NUMBER_FORMAT')) {
		const numberFormat = new NumberFormat(undefined, fractionDigits);
		if (short) {
			return numberFormat.short(number);
		} else {
			return numberFormat.default(number);
		}
	}

	if (isNaN(number)) {
		number = 0;
	}
	var locales = ['sv-SE', 'en-GB'];
	if (window.userLocale) {
		locales.unshift(window.userLocale);
	}
	var options = {
		style: 'decimal',
		minimumFractionDigits: !isNaN(minimumFractionDigits as number) ? minimumFractionDigits : fractionDigits || 0,
		maximumFractionDigits: isNaN(fractionDigits) ? 2 : fractionDigits,
		trailingZeroDisplay: removeTrailingZeros ? 'stripIfInteger' : 'auto'
	};

	if (short || (!verbose && Math.abs(number) > 1000000)) {
		return currencyDisplayDeprecated(number, false, true, true);
	}

	return new Intl.NumberFormat(locales, options).format(number);
};

export const numberFormatShort = (number: number) => {
	return numberFormat(number, false, 0, true);
};

export const currencyFormat = (
	number: number,
	currency: string | null,
	verbose?: boolean,
	fractionDigits?: number,
	minimumFractionDigits?: number,
	removeTrailingZeros?: boolean
) => {
	if (Tools.FeatureHelper.hasSoftDeployAccess('NUMBER_FORMAT')) {
		const currencyFormat = new CurrencyFormat(currency, undefined, fractionDigits);
		if (verbose) {
			return currencyFormat.verbose(number);
		} else {
			return currencyFormat.default(number);
		}
	}

	if (isNaN(number)) {
		number = 0;
	}
	if (!currency) {
		const curr = _.find(window.Tools.AppService.getMetadata().customerCurrencies, { masterCurrency: true });
		currency = curr ? curr.iso : currency;
	}
	var locales = ['sv-SE', 'en-GB'];
	if (window.userLocale) {
		locales.unshift(window.userLocale);
	}

	var mockCurrencyCode = 'USD';
	var options = {
		style: 'currency',
		currency: mockCurrencyCode,
		currencyDisplay: 'code',
		minimumFractionDigits: !isNaN(minimumFractionDigits as number) ? minimumFractionDigits : fractionDigits || 0,
		maximumFractionDigits: fractionDigits || 2,
		trailingZeroDisplay: removeTrailingZeros ? 'stripIfInteger' : 'auto'
	};

	// Trying to determine position of currency code
	var formatted = new Intl.NumberFormat(locales, options).format(1);
	var currencyFirst = formatted.indexOf(mockCurrencyCode) === 0;

	let returnStr = '';
	if (!verbose && Math.abs(number) > 1000000) {
		returnStr = currencyDisplayDeprecated(number, false, true, true);
	} else {
		returnStr = numberFormat(
			number,
			verbose,
			fractionDigits,
			undefined,
			minimumFractionDigits,
			removeTrailingZeros
		);
	}

	return currencyFirst ? currency + ' ' + returnStr : returnStr + ' ' + currency;
};

export const currencyRange = (value: number, currency: string) => {
	const ranges = [1000000, 2500000, 5000000, 10000000, 25000000, 50000000, 100000000];
	if (!currency) {
		const curr = _.find(Tools.AppService.getMetadata().customerCurrencies, { masterCurrency: true });
		currency = curr ? curr.iso : currency;
	}
	if (value >= ranges[ranges.length - 1]) {
		return '> ' + currencyFormat(ranges[ranges.length - 1], currency);
	}

	if (value <= ranges[0]) {
		return '< ' + currencyFormat(ranges[0], currency);
	}

	for (let i = 0; i < ranges.length; i++) {
		if (value >= ranges[i] && value <= ranges[i + 1]) {
			var locales = ['sv-SE', 'en-GB'];
			if (window.userLocale) {
				locales.unshift(window.userLocale);
			}
			var mockCurrencyCode = 'USD';
			var options = {
				style: 'currency',
				currency: mockCurrencyCode,
				currencyDisplay: 'code'
			};
			// Trying to determine position of currency code
			var formatted = new Intl.NumberFormat(locales, options).format(1);
			var currencyFirst = formatted.indexOf(mockCurrencyCode) === 0;
			var currencyFirstStyle =
				currency +
				' ' +
				currencyDisplayDeprecated(ranges[i]) +
				' - ' +
				currencyDisplayDeprecated(ranges[i + 1]);
			var currencyLastStyle =
				currencyDisplayDeprecated(ranges[i]) +
				' - ' +
				currencyDisplayDeprecated(ranges[i + 1]) +
				' ' +
				currency;

			return currencyFirst ? currencyFirstStyle : currencyLastStyle;
		}
	}
};

//Convert from percent to fraction.
export const fractionFormat = (value: number) => {
	if (value < 1) {
		return '0';
	}
	function findGcd(x: number, y: number) {
		if (typeof x !== 'number' || typeof y !== 'number') return 1;
		x = Math.abs(x);
		y = Math.abs(y);
		while (y) {
			var t = y;
			y = x % y;
			x = t;
		}
		return x;
	}
	function processNumbers(x: number, y: number) {
		if (y === 100 && x > 10) {
			if (x % 33 === 0 || x % 33 === 1) {
				y = Math.round(y / 33);
				x = Math.round(x / 33);
			} else {
				y = y / 10;
				x = Math.round(x / 10);
				const gcd = findGcd(x, y);
				x = x / gcd;
				y = y / gcd;
			}
		}
		return { numerator: x, denominator: y };
	}

	let denominator = 100;
	const gcd = findGcd(value, denominator);
	value = !isNaN(value / gcd) ? value / gcd : 0;

	if (value === 0) {
		return '0';
	}

	denominator = denominator / gcd;
	const result = processNumbers(value, denominator);

	return result.numerator + ' / ' + result.denominator;
};

export const dayFormat = (value: number) => {
	if (value === undefined) {
		return '0';
	}
	value = Math.round(value);
	if (value === 1) {
		return value + ' ' + T('default.day');
	}
	if (365 <= value && value < 384) {
		return 1 + ' ' + T('default.year');
	}
	if (value > 383) {
		const numberOfYears = Math.round((value / 365) * 10) / 10;
		let returnStr = numberOfYears + ' ' + T('default.years');
		const locale = [
			'sv-SE',
			'de-AT',
			'hu-HU',
			'af-ZA',
			'de-DE',
			'es-ES',
			'ru-RU',
			'nb-NO',
			'nl-NL',
			'lt-LT',
			'lv-LV',
			'it-IT',
			'is-IS',
			'el-GR',
			'fr-FR',
			'fi-FI',
			'et-EE',
			'da-DK',
			'pt-BR'
		];
		if (locale.includes(window.userLocale ?? '')) {
			returnStr = returnStr.replace('.', ',');
		}

		return returnStr;
	}

	return value + ' ' + T('default.days');
};

export const minutesToDaysHoursMinutes = (minutes: number) => {
	let remainingMinutes = minutes;
	const days = Math.floor(minutes / 1440);
	remainingMinutes -= days * 1440;
	const hours = Math.floor(remainingMinutes / 60);
	remainingMinutes -= hours * 60;

	let result = '';
	if (days > 0) {
		result += days + 'd ';
	}
	if (hours > 0) {
		result += hours + 'h ';
	}
	if (remainingMinutes > 0) {
		result += Math.round(remainingMinutes) + 'm';
	}
	if (!result) {
		result = '0m';
	}
	return result;
};
