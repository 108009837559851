import React from 'react';
import { removeItem } from 'Store/helpers/array';
import ReportcenterFilterRow from './ReportcenterFilterRow';
import T from 'Components/Helpers/translate';
import type { RCDashboardFilter } from 'Resources/ReportDashboard';
import { type MinimalProduct } from 'App/resources/Model/Product';
import type ProductCategory from 'App/resources/Model/ProductCategory';

interface ReportcenterFilterProductAndCategoryProps {
	values: number[];
	onChange: (type: keyof RCDashboardFilter, values: number[], comparison: string) => void;
	content: MinimalProduct[] | ProductCategory[];
	resetFilter: () => void;
	filterType: 'ProductCategory' | 'Product';
	comparison: string;
}

const ReportcenterFilterProductAndCategory = ({
	onChange,
	content,
	filterType,
	resetFilter,
	values,
	comparison
}: ReportcenterFilterProductAndCategoryProps) => {
	return (
		<>
			<ReportcenterFilterRow
				key={-1}
				onClick={() => {
					resetFilter();
				}}
				selected={!values.length}
				title={T('filters.noFilter')}
			/>
			{content.map(row => (
				<ReportcenterFilterRow
					key={row.id}
					onClick={() => {
						const parent = document.querySelector('.ReportcenterFilterProduct .DropDownMenu__content');
						const parentScroll = parent?.scrollTop || 0;
						const i = values.indexOf(row.id);
						onChange(filterType, i !== -1 ? removeItem(values, i) : [...values, row.id], comparison);
						if (parent instanceof Element) {
							// This mutation observer waits until react refreshes the list of products
							// and then scrolls to the previous position. The mutation observer is then disconeected.
							const observer = new MutationObserver(() => {
								observer.disconnect();
								parent.scrollTop = parentScroll;
							});
							observer.observe(parent as Element, {
								childList: true,
								subtree: true
							});
						}
					}}
					selected={values.indexOf(row.id) !== -1}
					title={row.name}
				/>
			))}
		</>
	);
};

export default ReportcenterFilterProductAndCategory;
