import React, { useMemo, useState } from 'react';
import { Modal, Row, Column, Headline, Block, Link, Text, Button } from '@upsales/components';
import T from 'Components/Helpers/translate';
import bemClass from '@upsales/components/Utils/bemClass';
import './AcceptTerms.scss';
import { AllIWantData } from 'App/resources/AllIWant';
import AcceptTermsResource from 'App/babel/resources/AcceptTerms';
import { markdown } from 'markdown';
import { ModalProps } from '../Modals/Modals';
import logError from 'App/babel/helpers/logError';
const filesSvg = require('App/img/files.svg') as string;

type Props = ModalProps & {
	className: string;
	acceptTerms: AllIWantData['acceptTerms'];
};

export default ({ className, acceptTerms, close }: Props) => {
	const [loading, setLoading] = useState(false);
	const classes = useMemo(() => new bemClass('AcceptTerms', className), [className]);
	const html = useMemo(() => markdown.toHTML(acceptTerms.termsDesc), []);
	return (
		<Modal size="lg" className={classes.b()}>
			<Row>
				<Column className={classes.elem('illustration-col').b()}>
					<Headline color="white">{T('acceptTerms.worldChangesAsOurTerms')}</Headline>
					<img src={filesSvg} />
				</Column>
				<Column>
					<Block space="mtxl mbxl">
						<Text dangerouslySetInnerHTML={{ __html: html }}></Text>
					</Block>

					<Block space="mtxl mbxl" className={classes.elem('terms-link-block').b()}>
						<Text>{T('acceptTerms.makeSureYouReadTheUpdated')}</Text>

						<Link href={acceptTerms.termsLink} target="_blank">
							{` ${T('acceptTerms.makeSureYouReadTheUpdatedTerms').toLowerCase()}. `}
						</Link>
						<Text>{T('acceptTerms.byUsingUpsalesYouAccept')}</Text>
					</Block>

					<Row>
						<Column align="right">
							<Button
								color="green"
								size="lg"
								loading={loading}
								onClick={() => {
									setLoading(true);
									AcceptTermsResource.save({ agreementId: acceptTerms.termsId })
										.then(() => close())
										.catch((err: Error) => {
											setLoading(false);
											logError(err, 'Could not accept terms');
										});
								}}
							>
								{T('acceptTerms.iUnderstand')}
							</Button>
						</Column>
					</Row>
				</Column>
			</Row>
		</Modal>
	);
};
