export type CancelablePromise<T = any> = {
	promise: Promise<T>;
	cancel: () => void;
	isCanceled: () => boolean;
};

export function makeCancelable<T = any>(promise: Promise<T>): CancelablePromise<T> {
	let hasCanceled_ = false;

	const wrappedPromise = new Promise<T>((resolve, reject) => {
		promise
			.then(val => {
				if (!hasCanceled_) {
					resolve(val);
				}
			})
			.catch(error => {
				if (!hasCanceled_) {
					reject(error);
				}
			});
	});

	return {
		promise: wrappedPromise,
		cancel() {
			hasCanceled_ = true;
		},
		isCanceled() {
			return hasCanceled_;
		}
	};
}
