import getAngularModule from 'App/babel/angularHelpers/getAngularModule';
import RequestBuilder, { comparisonTypes } from 'App/babel/resources/RequestBuilder';
import logError from 'App/babel/helpers/logError';

const prefix = '[ActivityOutcomes]';
export const RESET = `${prefix} RESET`;
export const UPDATE = `${prefix} UPDATE`;
export const FETCH_APPOINTMENTS_PENDING = `${prefix} FETCH_TABLE_PENDING`;
export const FETCH_APPOINTMENTS_FULFILLED = `${prefix} FETCH_TABLE_FULFILLED`;
export const FETCH_APPOINTMENTS_REJECTED = `${prefix} FETCH_TABLE_REJECTED`;
export const SET_CONTACTS_LOADING = `${prefix} SET_CONTACTS_LOADING`;
export const SET_CONTACTS = `${prefix} SET_CONTACTS`;

const setContactsLoading = value => ({ type: SET_CONTACTS_LOADING, value });
const setContacts = data => ({ type: SET_CONTACTS, payload: data });

export const fetchAppointments = activity => async dispatch => {
	dispatch({ type: RESET });
	const contactId = _.get(activity, 'contacts.id') || _.get(activity, 'contacts[0].id');
	const clientId = _.get(activity, 'client.id');

	if (!contactId || !clientId) return;

	dispatch({ type: FETCH_APPOINTMENTS_PENDING });

	const Appointment = getAngularModule('Appointment');

	const rb = new RequestBuilder();
	const AppointmentAttrs = Appointment.attr;

	rb.addFilter(AppointmentAttrs.users, comparisonTypes.Equals, Tools.AppService.getSelf().id);
	rb.addFilter(AppointmentAttrs.account.attr.id, comparisonTypes.Equals, clientId);
	rb.addFilter(AppointmentAttrs.contact.attr.id, comparisonTypes.Equals, contactId);
	rb.addFilter(AppointmentAttrs.date, comparisonTypes.GreaterThanEquals, new Date());

	try {
		const { data } = await Appointment.customer(Tools.AppService.getCustomerId()).find(rb.build());

		if (Array.isArray(data)) {
			dispatch({ type: FETCH_APPOINTMENTS_FULFILLED, payload: data });
		} else {
			dispatch({ type: FETCH_APPOINTMENTS_FULFILLED, payload: null });
		}
	} catch (err) {
		logError(err, 'Failed getting appointments');
		dispatch({ type: FETCH_APPOINTMENTS_REJECTED, payload: err });
	}
};

export const fetchContacts = clientId => async dispatch => {
	if (!clientId) return;
	dispatch(setContactsLoading(true));
	const Contact = getAngularModule('Contact');
	const ContactAttrs = Contact.attr;
	const rb = new RequestBuilder();
	rb.addFilter(ContactAttrs.client.attr.id, comparisonTypes.Equals, clientId);
	rb.addFilter(ContactAttrs.active, comparisonTypes.Equals, true);
	rb.addSort(ContactAttrs.score, false);
	rb.fields = ['id', 'name', 'score', 'title', 'phone', 'email', 'cellPhone', 'titleCategory'];
	rb.limit = 5;

	try {
		const { data } = await Contact.find(rb.build());

		dispatch(setContacts(data));
		dispatch(setContactsLoading(false));
	} catch (e) {
		logError(e, 'Failed getting contacts');

		dispatch(setContacts([]));
		dispatch(setContactsLoading(false));
	}
};
