import logError from 'App/babel/helpers/logError';
import MailSignatureResource from 'App/babel/resources/MailSignature';
import _ from 'lodash';

export const initialState = {
	signatures: [],
	sort: 'name',
	asc: true,
	edit: null,
	lang: {}
};

const LOAD_SIGNATURES = '[MailSignature] LOAD_SIGNATURES';
const SET_SIGNATURES = '[MailSignature] SET_SIGNATURES';
const SET_SORT = '[MailSignature] SET_SORT';
const START_EDIT = '[MailSignature] START_EDIT';
const STOP_EDIT = '[MailSignature] STOP_EDIT';
const SET_LANGUAGE = '[MailSignature] SET_LANGUAGE';

const reducer = (state = initialState, action) => {
	switch (action.type) {
		case LOAD_SIGNATURES:
			return { ...state, signatures: action.value };
		case SET_SIGNATURES:
			return { ...state, signatures: action.value };
		case SET_SORT:
			return { ...state, sort: action.value.sort, asc: action.value.asc };
		case START_EDIT:
			return { ...state, edit: action.data };
		case STOP_EDIT:
			return { ...state, edit: null };
		case SET_LANGUAGE:
			return { ...state, lang: action.data };
		default:
			return state;
	}
};

export default reducer;

export const setLanguage = data => {
	return dispatch => {
		const langObj = {};

		_.map(data, (tag, key) => {
			langObj[key] = Tools.$translate(tag.toString());
		});

		dispatch({ type: SET_LANGUAGE, data: langObj });
	};
};

export const getSignatures = () => {
	return (dispatch, getState) => {
		const { sort, asc } = getState().MailSignature;

		MailSignatureResource.find({ field: sort, asc }).then(res => {
			dispatch({ type: LOAD_SIGNATURES, value: res.data });
		});
	};
};

export const setSort = obj => {
	return dispatch => {
		dispatch({ type: SET_SORT, value: { sort: obj.sort, asc: obj.asc } });
		dispatch(getSignatures());
	};
};

export const changeStandard = data => {
	return (dispatch, getState) => {
		const { signatures } = getState().MailSignature;

		const getEarlierStandard = _.find(signatures, sign => {
			return sign.standard === true && sign.id !== data.id;
		});

		if (data.standard === true) {
			data.standard = false;
		} else {
			data.standard = true;
		}

		if (getEarlierStandard) {
			getEarlierStandard.standard = false;
			getEarlierStandard.saveSilent = true;
			dispatch(saveSignature(getEarlierStandard));
		}

		dispatch(saveSignature(data));
	};
};

export const stopEdit = () => {
	return dispatch => dispatch({ type: STOP_EDIT });
};

export const deleteSignature = data => {
	return (dispatch, getState) => {
		const { signatures } = getState().MailSignature;

		MailSignatureResource.delete(data)
			.then(() => {
				let updatedSignatures = [].concat(signatures);

				updatedSignatures = updatedSignatures.filter(sign => {
					return sign.id !== data.id;
				});

				dispatch({ type: SET_SIGNATURES, value: updatedSignatures });
				dispatch({ type: STOP_EDIT });

				Tools.NotificationService.addNotification({
					style: Tools.NotificationService.style.SUCCESS,
					icon: 'check',
					title: 'default.deleted',
					body: 'deleted.mailSignature'
				});
			})
			.catch(() => {
				Tools.NotificationService.addNotification({
					style: Tools.NotificationService.style.ERROR,
					icon: 'times',
					title: 'default.error',
					body: 'deleteError.mailSignature'
				});
			});
	};
};

export const saveSignature = data => {
	return (dispatch, getState) => {
		const saveSilent = data.saveSilent;
		delete data.saveSilent;

		if (!data.name.length) {
			Tools.NotificationService.addNotification({
				style: Tools.NotificationService.style.ERROR,
				icon: 'times',
				title: 'default.error',
				body: 'mailSignature.error.saveWithoutName'
			});

			return dispatch({ type: START_EDIT, data: Object.assign({}, data, { nameError: true }) });
		}

		for (const rowItem of data.usersNRoles) {
			if (rowItem.isRole) {
				data.roles.push({ roleId: rowItem.id });
			} else {
				data.users.push({ userId: rowItem.id });
			}
		}

		MailSignatureResource.save(data)
			.then(response => {
				const { signatures } = getState().MailSignature;

				let updatedSignatures = [].concat(signatures);
				const found = _.find(updatedSignatures, sign => sign.id === response.data.id);

				if (found) {
					updatedSignatures = updatedSignatures.map(sign => {
						if (sign.id === response.data.id) {
							return response.data;
						}

						return sign;
					});
				} else {
					updatedSignatures.push(response.data);
				}

				dispatch({ type: SET_SIGNATURES, value: updatedSignatures });
				dispatch({ type: STOP_EDIT });

				if (!saveSilent) {
					Tools.NotificationService.addNotification({
						style: Tools.NotificationService.style.SUCCESS,
						icon: 'check',
						title: 'default.saved',
						body: 'saved.mailSignature'
					});
				}
			})
			.catch(() => {
				Tools.NotificationService.addNotification({
					style: Tools.NotificationService.style.ERROR,
					icon: 'times',
					title: 'default.error',
					body: 'saveError.mailSignature'
				});
			});
	};
};

export const startEdit = data => {
	return async dispatch => {
		const editObj = { usersNRoles: [] };
		const { users: _users, roles: _roles, ...restData } = data;

		_.map(restData, (value, key) => {
			editObj[key] = value;
		});

		for (const mainUser of _users) {
			const users = Tools.AppService.getUsers();
			for (const user of users) {
				if (user.id === mainUser.userId) {
					user.userId = user.id;
					editObj.usersNRoles.push(user);
				}
			}
		}

		for (const mainRole of _roles) {
			const roles = Tools.AppService.getRoles();
			for (const role of roles) {
				if (role.id === mainRole.roleId) {
					role.isRole = true;
					role.roleId = role.id;
					editObj.usersNRoles.push(role);
				}
			}
		}

		dispatch({ type: START_EDIT, data: editObj });

		if (Tools.FeatureHelper.hasSoftDeployAccess('REACT_ALERT_MODAL')) {
			// I have to do this because of circular dependency
			const { default: openModal } = await import('App/services/Modal');
			openModal('EditMailSignature', {
				className: 'form',
				data: editObj,
				onSave: data => dispatch(saveSignature(data))
			});
		}
	};
};

export const createSignature = () => {
	return dispatch => {
		MailSignatureResource.new()
			.then(async data => {
				const editObj = Object.assign({}, data);
				dispatch({ type: START_EDIT, data: editObj });

				if (Tools.FeatureHelper.hasSoftDeployAccess('REACT_ALERT_MODAL')) {
					// I have to do this because of circular dependency
					const { default: openModal } = await import('App/services/Modal');
					openModal('EditMailSignature', {
						className: 'form',
						data: editObj,
						onSave: data => dispatch(saveSignature(data))
					});
				}
			})
			.catch(error => {
				logError(error, 'Error creating new signature');
			});
	};
};
