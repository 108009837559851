import { handleIntegrationIframeMessage } from 'App/helpers/uiElementsHelper';
import React, { useEffect } from 'react';

const iframeRenderType = ({ row, config }) => {
	var iframeProps = {
		className: 'widget-iframe',
		sandbox: 'allow-scripts',
		name: `ui-element-${config.integrationId}-${row.id || '0'}`,
		style: {}
	};

	if (row.url) {
		iframeProps.src = row.url;
	} else if (row.html) {
		iframeProps.srcDoc = row.html;
	}

	if (row.height && typeof row.height === 'number') {
		iframeProps.style.height = `${row.height}px`;
	}

	if (row.width && typeof row.width === 'number') {
		iframeProps.style.width = `${row.width}px`;
	}

	if (row.scrolling === false) {
		row.scrolling = 'no';
	}

	const iframeMessage = event => {
		handleIntegrationIframeMessage(undefined, config.integrationId, undefined, undefined, event);
	};

	useEffect(() => {
		const l = window.addEventListener('message', iframeMessage);
		return () => {
			l();
		};
	}, []);

	iframeProps.scrolling = row.scrolling || 'auto';

	// row 95 to 101 in UiElements.js directive
	// hasnt been ported here yet.

	return <div className="widget-iframe-wrapper">{React.createElement('iframe', iframeProps)}</div>;
};

export default iframeRenderType;
