import SamlAuth from 'App/babel/resources/SamlAuth';

export const initialState = {
	id: null,
	certificate: '',
	loginUrl: '',
	logoutUrl: '',
	enabled: false,
	loading: false,
	error: null,
	dirty: false,
	saving: false,
	metadataUrl: '',
	bypassUrl: ''
};

const LOAD_SINGLE_SIGN_ON = '[SSO] LOAD_SINGLE_SIGN_ON';
const LOAD_SINGLE_SIGN_ON_SUCCESS = '[SSO] LOAD_SINGLE_SIGN_ON_SUCCESS';
const LOAD_SINGLE_SIGN_ON_ERROR = '[SSO] LOAD_SINGLE_SIGN_ON_ERROR';
const SET_SINGLE_SIGN_ON_SETTINGS = '[SSO] SET_SINGLE_SIGN_ON_SETTINGS';
const TOGGLE_SINGLE_SIGN_ON = '[SSO] TOGGLE_SINGLE_SIGN_ON';
const TOGGLE_SINGLE_SIGN_ON_ERROR = '[SSO] TOGGLE_SINGLE_SIGN_ON_ERROR';
const SAVE_SINGLE_SIGN_ON_SETTINGS = '[SSO] SAVE_SINGLE_SIGN_ON_SETTINGS';
const SAVE_SINGLE_SIGN_ON_SETTINGS_SUCCESS = '[SSO] SAVE_SINGLE_SIGN_ON_SETTINGS_SUCCESS';
const SAVE_SINGLE_SIGN_ON_SETTINGS_ERROR = '[SSO] SAVE_SINGLE_SIGN_ON_SETTINGS_ERROR';

const reducer = (state = initialState, action) => {
	const { type, ...rest } = action;
	switch (type) {
		case LOAD_SINGLE_SIGN_ON:
			return { ...state, loading: true };
		case LOAD_SINGLE_SIGN_ON_SUCCESS:
			return { ...state, loading: false, ...rest };
		case LOAD_SINGLE_SIGN_ON_ERROR:
			return { ...state, loading: false, error: rest.error };
		case TOGGLE_SINGLE_SIGN_ON:
			return { ...state, enabled: !state.enabled };
		case TOGGLE_SINGLE_SIGN_ON_ERROR:
			return { ...state, enabled: !state.enabled };
		case SET_SINGLE_SIGN_ON_SETTINGS:
			return {
				...state,
				loginUrl: rest.loginUrl,
				logoutUrl: rest.logoutUrl,
				certificate: rest.certificate,
				dirty: true
			};
		case SAVE_SINGLE_SIGN_ON_SETTINGS:
			return { ...state, saving: true };
		case SAVE_SINGLE_SIGN_ON_SETTINGS_ERROR:
			return { ...state, saving: false, error: rest.error };
		case SAVE_SINGLE_SIGN_ON_SETTINGS_SUCCESS:
			return { ...state, saving: false, dirty: false, id: 1 };
		default:
			return state;
	}
};

export default reducer;

export const saveSingleSignOnSettings = () => {
	return async (dispatch, getStore) => {
		dispatch({ type: SAVE_SINGLE_SIGN_ON_SETTINGS });

		const data = getStore().SingleSignOn;
		if (data.enabled && (!data.certificate || !data.loginUrl)) {
			Tools.NotificationService.addNotification({
				style: Tools.NotificationService.style.WARN,
				icon: 'warning',
				title: 'default.youHaveFormErrorsRequiredTitle',
				body: 'admin.singleSignOn.deactivateBeforeSave'
			});
			return dispatch({ type: SAVE_SINGLE_SIGN_ON_SETTINGS_ERROR });
		}

		try {
			await SamlAuth.save(data); //eslint-disable-line no-unused-expressions
			dispatch({ type: SAVE_SINGLE_SIGN_ON_SETTINGS_SUCCESS });
		} catch (e) {
			console.log('Save single sign on settings', { error: e });
			dispatch({ type: SAVE_SINGLE_SIGN_ON_SETTINGS_ERROR });
		}
	};
};

export const toggleSingleSignOn = () => {
	return async (dispatch, getStore) => {
		dispatch({ type: TOGGLE_SINGLE_SIGN_ON });
		const { enabled, certificate, loginUrl } = getStore().SingleSignOn;
		const ClientParam = window.Tools.ClientParam;

		if (enabled && (!certificate || !loginUrl)) {
			Tools.NotificationService.addNotification({
				style: Tools.NotificationService.style.WARN,
				icon: 'warning',
				title: 'default.youHaveFormErrorsRequiredTitle',
				body: 'default.youHaveFormErrorsRequiredBody'
			});
			return setImmediate(() => {
				return dispatch({ type: TOGGLE_SINGLE_SIGN_ON_ERROR });
			});
		}

		try {
			await ClientParam.save(218, enabled); //eslint-disable-line no-unused-expressions
			const metadata = Tools.AppService.getMetadata();
			metadata.params.HasSamlLogin = enabled;
			Tools.AppService.setMetadata(metadata);
		} catch (e) {
			console.log('Toggle sign on error', { error: e });
			dispatch({ type: TOGGLE_SINGLE_SIGN_ON_ERROR });
		}
	};
};

export const setSingleSignOnSettings = settings => {
	return {
		type: SET_SINGLE_SIGN_ON_SETTINGS,
		...settings
	};
};

export const loadSingleSignOn = () => {
	return async dispatch => {
		dispatch({ type: LOAD_SINGLE_SIGN_ON });

		try {
			const data = await SamlAuth.get();
			const enabled = Tools.AppService.getMetadata().params.HasSamlLogin;
			const bypassUrl =
				window.location.protocol +
				'//' +
				window.location.host +
				'/#/login?samlBypass=' +
				Tools.AppService.getCustomerId();
			const metadataUrl =
				window.location.origin +
				window.Tools.URL +
				window.Tools.API +
				Tools.AppService.getCustomerId() +
				'/saml/metadata.xml';
			dispatch({ type: LOAD_SINGLE_SIGN_ON_SUCCESS, ...data, enabled, bypassUrl, metadataUrl });
		} catch (e) {
			dispatch({ type: LOAD_SINGLE_SIGN_ON_ERROR, error: e });
		}
	};
};
