import React from 'react';
import TimelineGeneric from '../TimelineGeneric/TimelineGeneric';

import type { TimelineCardComponent } from '../TimelineGeneric/TimelineCardConfigs';
import { useTranslation } from 'Components/Helpers/translate';
import { EVENT_TIMELINE_SUB_TYPES } from 'App/babel/enum/timeline';

const TimelineCardOrder = (({ event, ...passthrough }) => {
	const { subType, opportunity: order, entityId } = event || {};
	const { t } = useTranslation();

	if (!order) {
		return null;
	}

	const currencyText = () => (
		<b>
			{`${subType?.toLowerCase() === EVENT_TIMELINE_SUB_TYPES.LOST ? '-' : ''}${Tools.$filter('currencyFormat')(
				order.orderValue,
				order.currency ||
					Tools.AppService.getMetadata().customerCurrencies?.find(curr => curr.masterCurrency === true)
						?.iso ||
					''
			)}`}
		</b>
	);

	return (
		<TimelineGeneric
			event={event}
			contactPreposition={
				subType?.toLowerCase() === EVENT_TIMELINE_SUB_TYPES.LOST ? 'default.with' : 'default.to'
			}
			eventLinkTitle={() => {
				if (subType?.toLowerCase() === EVENT_TIMELINE_SUB_TYPES.LOST) {
					return t('default.lostDeal').toLowerCase();
				}
				return t('default.sold').toLowerCase();
			}}
			onLinkClick={() =>
				Tools.$upModal.open('editOrder', {
					customerId: Tools.AppService.getCustomerId(),
					type: 'order',
					id: entityId
				})
			}
			titleElements={['users', 'eventLink', 'contacts', currencyText]}
			commentFetchFilters={{ opportunityId: event.entityId }}
			notes={event.opportunity?.notes || ''}
			{...passthrough}
		/>
	);
}) satisfies TimelineCardComponent;

export default TimelineCardOrder;
