import React, { useState } from 'react';
import ReportcenterFilter from './ReportcenterFilter';
import { Block } from '@upsales/components';
import bemClass from '@upsales/components/Utils/bemClass';
import T from 'Components/Helpers/translate';
import './ReportcenterFilterJourneyStepClient.scss';
import ReportcenterFilterRow from './ReportcenterFilterRow';
import { removeItem } from 'Store/helpers/array';
import { RCDashboardFilter } from 'Resources/ReportDashboard';
import ReportcenterFilterSearchbar from './ReportcenterFilterSearchbar';
import { RootState } from 'Store/index';

interface Props {
	onChange: (type: keyof RCDashboardFilter, values: string[], comparison: string) => void;
	values: RCDashboardFilter;
	updateFilterHeight: () => void;
	families: string[];
	dashboard: RootState['Reportcenter']['selectedDashboard'];
}

export const renderSelected = (values: RCDashboardFilter, renderCount: boolean = true) => {
	let res = `${T('default.journeyStepClient')}: `;
	if (!renderCount) {
		const journeySteps = Tools.AppService.getJourneySteps()
			.filter(journeyStep => {
				return values?.JourneyStepClient?.value?.includes?.(journeyStep.value);
			})
			.map(stage => stage.name);
		res += journeySteps.join(', ');
		return res;
	}

	if (values?.JourneyStepClient?.value?.length) {
		const length = values.JourneyStepClient.value.length;
		if (length >= 1) {
			res += `${length} ${(values.JourneyStepClient.comparison === 'eq'
				? T('default.selected')
				: T('default.excluded', { count: length })
			).toLowerCase()}`;
		}
	}

	return res;
};

const ReportcenterFilterJourneyStepClient = ({ onChange, families, ...props }: Props) => {
	const classes = new bemClass('ReportcenterFilterJourneyStepClient');
	const [searchStr, setSearchStr] = useState('');

	const journeySteps = Tools.AppService.getJourneySteps();
	const array = journeySteps.filter(item => item.name.toLowerCase().indexOf(searchStr.toLowerCase()) !== -1);
	const value = props.values?.JourneyStepClient?.value || [];
	const comparison = props.values?.JourneyStepClient?.comparison ?? 'eq';
	const isExclude = comparison === 'ne';

	return (
		<ReportcenterFilter
			className={classes.b()}
			renderSelected={() => renderSelected(props.values)}
			getSelectedNames={() => journeySteps.filter(s => value.includes(s.value)).map(s => s.name)}
			icon="opportunity"
			placeholder={`${T('default.journeyStepClient')}: ${T('reportcenter.filter.notActive')}`}
			value={value}
			resetFilter={() => onChange('JourneyStepClient', [], 'eq')}
			{...props}
		>
			<Block space="ptm prm pbm plm" backgroundColor="white" className={classes.elem('list-select').b()}>
				<Block space="mtm">
					<ReportcenterFilterSearchbar<string[]>
						setSearchStr={value => setSearchStr(value)}
						searchStr={searchStr}
						placeholder={`${T('default.search')} ${T('default.journeyStep').toLowerCase()}`}
						isExclude={isExclude}
						onChange={onChange}
						field="JourneyStepClient"
						value={value}
					/>
				</Block>
			</Block>
			<ReportcenterFilterRow
				key={0}
				onClick={() => {
					onChange('JourneyStepClient', [], 'eq');
				}}
				selected={!value.length}
				title={T('filters.noFilter')}
			/>
			{array.map(row => (
				<ReportcenterFilterRow
					key={row.value}
					onClick={() => {
						const i = value.indexOf(row.value);
						onChange(
							'JourneyStepClient',
							i !== -1 ? removeItem(value, i) : [...value, row.value],
							comparison
						);
					}}
					selected={value.indexOf(row.value) !== -1}
					title={row.name}
				/>
			))}
		</ReportcenterFilter>
	);
};

export default ReportcenterFilterJourneyStepClient;
