import React from 'react';
import { Tooltip } from '@upsales/components';
import formatWidgetValue from '../../formatWidgetValue';
import { Datatypes } from 'Resources/ReportWidget';
import { loadDrilldown } from '../../chartHelper';
import { RCDashboardWidget } from 'Resources/ReportDashboard';

import './DistributionBar.scss';
import bemClass from '@upsales/components/Utils/bemClass';

type DistributionBarProps = {
	distribution?: {
		id: string | number;
		label: string;
		value: number;
		count?: number;
		color: string;
		striped?: boolean;
	}[];
	datatype: keyof typeof Datatypes;
	currency?: string;
	thick?: boolean;
	drilldown?: boolean;
	enableDrilldown?: boolean;
	config?: RCDashboardWidget;
};

const DistributionBar = ({
	distribution,
	datatype,
	currency,
	thick,
	drilldown,
	enableDrilldown,
	config
}: DistributionBarProps) => {
	const classes = new bemClass('DistributionBar');

	if (thick) {
		classes.mod('thick');
	}

	if (distribution) {
		const totalValue = distribution.reduce((acc, entry) => acc + entry.value, 0);

		const drilldownEnabled = drilldown && enableDrilldown;

		return (
			<div className={classes.b()}>
				{distribution.map(entry => {
					const percentage = totalValue > 0 ? (100 * entry.value) / totalValue : 0;

					const style = {
						width: percentage + '%',
						backgroundColor: entry.color
					};

					const entryClasses = new bemClass('DistributionBar__entry');
					entryClasses.mod(entry.color);
					if (entry.striped) {
						entryClasses.mod(`${entry.color}--striped`);
					}

					const count = entry.count ? ` (${entry.count}) ` : '';
					const title = `${entry.label} ${count}- ${formatWidgetValue(entry.value, datatype, currency)}`;

					return (
						<Tooltip key={entry.id} title={title} className={entryClasses.b()} style={style}>
							<div
								style={{ width: '100%', height: '100%' }}
								onClick={
									drilldownEnabled && config
										? e => {
												e.stopPropagation();
												loadDrilldown([String(entry.id)], config);
										  }
										: undefined
								}
							/>
						</Tooltip>
					);
				})}
			</div>
		);
	}
	return <div />;
};

export default DistributionBar;
