import React from 'react';
import PropTypes from 'prop-types';
import { Avatar, Column, Row, Text } from '@upsales/components';
import BemClass from '@upsales/components/Utils/bemClass';
import './FocusModeTopListUserRows.scss';

const FocusModeTopListUserRows = ({ topListData, placeholder, loading }) => {
	const classes = new BemClass('FocusModeTopListUserRows');
	const selfID = Tools.AppService.getSelf().id;

	const defaultProps = {
		listLength: 3,
		idField: 'key',
		valueField: 'doc_count',
		nameField: 'name'
	};

	if (topListData) {
		topListData.forEach(function (bucket) {
			const user = _.find(Tools.AppService.getActiveUsers(), { id: bucket.key });
			bucket.name = user && user.name ? user.name : '';
			bucket.email = user && user.email ? user.email : '';
		});
	}

	const userRows = topListData?.length
		? topListData.map(user => {
				return (
					<Row
						key={user[defaultProps.idField]}
						className={
							user[defaultProps.idField] !== selfID
								? classes.elem('list-row').b()
								: classes.elem('list-row').mod('user-row').b()
						}
					>
						<Column size={1} className={classes.elem('list-col').mod('avatar').b()}>
							<Avatar size="sm" initials={user.name} email={user.email} />
						</Column>
						<Column align="left" className={classes.elem('list-col').mod('name').b()}>
							<Text color="white">{user[defaultProps.nameField]}</Text>
						</Column>
						<Column size={1} align="right" className={classes.elem('list-col').mod('num').b()}>
							<Text color="white">{user[defaultProps.valueField]}</Text>
						</Column>
					</Row>
				);
		  })
		: null;

	return !loading && userRows ? (
		userRows
	) : (
		<Row className={classes.elem('placeholderRow').b()}>
			<Column>
				<Text color="white">{placeholder}</Text>
			</Column>
		</Row>
	);
};

FocusModeTopListUserRows.propTypes = {
	topListData: PropTypes.array,
	placeholder: PropTypes.string,
	loading: PropTypes.bool
};

export default FocusModeTopListUserRows;
