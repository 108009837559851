export class NumberFormat {
	protected COMPACT_NOTATION_BREAKPOINT = 1000000;

	protected locales: string[] = [];
	protected fractionDigits: number;

	constructor(locale?: string, fractionDigits: number = 2) {
		this.locales = locale ? [locale] : this.getUserLocales();
		this.fractionDigits = fractionDigits;
	}

	setLocale(locale: string): string[] {
		this.locales = [locale];
		return this.locales;
	}

	getLocale(): string[] {
		return [...this.locales];
	}

	/**
	 * Formats the number per default, i.e with only neccesarry decimals
	 *
	 * `123456789.987654321` -> `123,456,789.99`
	 *
	 * `155555555.12` -> `155,555,555.12`
	 *
	 * `1000` -> `1 000`
	 * @param nbr The number to format
	 * @returns A string representation of the number
	 */
	default(nbr: number): string {
		if (!this.validate(nbr)) return '';

		const formatter = this.getNumberFormat('decimal', 'standard', undefined, undefined);
		return formatter.format(nbr);
	}

	/**
	 * Formats the number as a percent
	 *
	 * `0.251` -> `25.1%`
	 * @param nbr The number to format
	 * @returns A string representation of the number
	 */
	percent(nbr: number): string {
		if (!this.validate(nbr)) return '';

		const formatter = this.getNumberFormat('percent', 'standard', undefined, undefined);
		return formatter.format(nbr);
	}

	/**
	 * Formats the number on a shorter format, using a suffix and less decimals for larger numbers.
	 *
	 * `1000000` -> `1 M`
	 *
	 * `123456789.987654321` -> `123.5M`
	 *
	 * `100000` -> `100 000`
	 *
	 * @param nbr The number to format
	 * @returns A string representation of the number
	 */
	short(nbr: number, fractionDigits = 1): string {
		if (!this.validate(nbr)) return '';

		let formatted: string;
		if (Math.abs(nbr) >= this.COMPACT_NOTATION_BREAKPOINT) {
			formatted = this.getNumberFormat('decimal', 'compact', fractionDigits, undefined)
				.format(nbr)
				.replace(/mn/, 'M');
		} else {
			// Using default notation below 1 milion
			formatted = this.default(nbr);
		}
		// sv-SE: We wan't the milion prefix to be M instead of the default md
		return formatted;
	}

	/**
	 * Formats the number on a verbose format, i.e with always at least 2 decimals
	 *
	 * `123456789.987654321` -> `123,456,789.99`
	 *
	 * `155555555.12` -> `155,555,555.12`
	 *
	 * `1000` -> `1 000,00`
	 * @param nbr The number to format
	 * @returns A string representation of the number
	 */
	verbose(nbr: number) {
		if (!this.validate(nbr)) return '';

		const formatter = this.getNumberFormat('decimal', 'standard', undefined, 2);
		return formatter.format(nbr);
	}

	private getUserLocales(): string[] {
		const locales = ['sv-SE', 'en-GB'];
		if (window?.userLocale) {
			locales.unshift(window.userLocale);
		}
		return locales;
	}

	protected validate(nbr: number) {
		return !isNaN(nbr);
	}

	protected getNumberFormat(
		style: Intl.NumberFormatOptions['style'],
		notation: 'standard' | 'scientific' | 'engineering' | 'compact' | undefined,
		maximumFractionDigits: number = this.fractionDigits,
		minimumFractionDigits: number = 0
	): Intl.NumberFormat {
		return new Intl.NumberFormat(this.locales, { style, maximumFractionDigits, minimumFractionDigits, notation });
	}
}

export default new NumberFormat();
