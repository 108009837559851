import AccessType from 'App/enum/AccessType';

const findUsers = function (array, roleIds, selectAllChilds, output) {
	array.forEach(function (obj) {
		if (obj.isRole && obj.children.length && (_.contains(roleIds, obj.$id) || selectAllChilds)) {
			findUsers(obj.children, roleIds, true, output);
		} else if (obj.isRole && obj.children.length) {
			findUsers(obj.children, roleIds, false, output);
		} else if (!obj.isRole && selectAllChilds) {
			output.push(obj.id);
		}
	});
};

var getRoleTree = function () {
	var accessType = AccessType.CONTACT;
	var roleTreeOptions = {
		accessType: accessType,
		selectableRoles: false,
		noParentRef: true,
		includeInactive: false
	};

	return window.UserRoleTree(roleTreeOptions);
};

export default {
	generate: function () {
		return {
			inactive: false,
			comparisonType: 'Equals',
			value: []
		};
	},
	isInactive: function (filter) {
		return filter.inactive || !filter.value.length;
	},
	toUrl: function (filter) {
		return { v: filter.value, c: filter.comparisonType, i: filter.inactive };
	},
	fromUrl: function (rawFilter) {
		var filter = this.generate();

		filter.inactive = rawFilter.i;
		filter.comparisonType = rawFilter.c;
		filter.value = rawFilter.v;

		return filter;
	},
	build: function (options) {
		return function (filter, rb, filterHelperGetField) {
			if (filter.inactive) {
				return;
			}

			var getField = options.fieldPrefix
				? function (field) {
						return filterHelperGetField(options.fieldPrefix + '.' + field);
				  }
				: filterHelperGetField;

			if (options.sendRoleValues) {
				rb.addFilter(getField('user.id'), rb.comparisonTypes[filter.comparisonType], filter.value);
				return;
			}

			var ids = _.reduce(
				filter.value,
				function (result, id) {
					if (typeof id === 'string') {
						result.roleIds.push(id);
					} else {
						result.userIds.push(id);
					}
					return result;
				},
				{ userIds: [], roleIds: [] }
			);

			var parsedRoleIds = ids.roleIds.map(function (id) {
				return parseInt(id.split('-')[1]);
			});
			var userIdsFromRoles = [];

			var userRoleTree = options.userRoleTree || getRoleTree();
			findUsers(userRoleTree, parsedRoleIds, false, userIdsFromRoles);

			var allUserIds = ids.userIds.concat(userIdsFromRoles);
			rb.addFilter(getField('user.id'), rb.comparisonTypes[filter.comparisonType], allUserIds);
		};
	}
};
