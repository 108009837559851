import React from 'react';
import bemClass from '@upsales/components/Utils/bemClass';
import './ProductGridWidget.scss';
import { CellRendererProps, VirtualizedTableWidgetCore } from '../VirtualizedTableWidget/VirtualizedTableWidget';
import { GenericWidgetProps } from '../ReportcenterWidget';
import { Icon } from '@upsales/components';
import { RCWidgetDataSubRow } from 'Resources/ReportWidget';
import { loadDrilldown } from '../chartHelper';

const ProductGridWidget = ({ config, data, loading, drilldown }: GenericWidgetProps) => {
	const classes = new bemClass('ProductGridWidget');
	const enableDrilldown = data.enableDrilldown && drilldown;

	const renderBodyCell = (
		data: RCWidgetDataSubRow | undefined,
		keys: string[],
		props: Partial<CellRendererProps>
	) => {
		return (
			<div
				className={classes
					.elem('cell')
					.mod('body')
					.mod({ check: !!data?.progress })
					.mod({ opportunity: !data?.progress && !!data?.goal })
					.mod({ times: !data?.progress && !data?.goal })
					.mod({ clickable: enableDrilldown })
					.b()}
				key={props.key}
				style={props.style}
				onClick={
					enableDrilldown
						? e => {
								e.preventDefault();
								const drilldownType = data?.progress ? 1 : 2;
								loadDrilldown(keys, config, drilldownType);
						  }
						: undefined
				}
			>
				{data?.progress ? (
					<Icon name="check" color="green" />
				) : data?.goal ? (
					<Icon name="opportunity" color="yellow-5" />
				) : (
					<Icon name="times" color="red" />
				)}
			</div>
		);
	};
	return (
		<div className={classes.b()}>
			<VirtualizedTableWidgetCore
				config={config}
				data={data}
				drilldown={drilldown}
				loading={loading}
				renderBodyCell={renderBodyCell}
			/>
		</div>
	);
};

export default ProductGridWidget;
