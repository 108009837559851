import React, { Fragment } from 'react';
import BemClass from '@upsales/components/Utils/bemClass';
import { Icon, IconName, Link, Text } from '@upsales/components';
import './TodoListTableRowActionButton.scss';
import T from 'Components/Helpers/translate';
import { phoneNumberHelper } from '@upsales/common';
import Todo from 'App/resources/Model/Todo';
import { DefaultButton } from '@upsales/components/Buttons';
import Contact from 'App/resources/Model/Contact';

const getAdress = (location: string, classes: BemClass) => (
	<Text className={classes.elem('address').b()} size="sm" color="grey-10">
		<Icon name="map-marker" color="grey-10" />
		{location}
	</Text>
);

type Props = {
	todo: Todo;
};

/** @deprecated Can be removed and replaced with TodoListSmallActionButton after TASKS_COLUMN_IMPROVEMENTS is released */
const TodoListTableRowActionButton: React.FC<Props> = ({ todo }) => {
	const classes = new BemClass('TodoListTableRowActionButton');
	const contact = todo.contacts && todo.contacts.length ? todo.contacts[0] : null;
	let linkProps,
		number: string | null = null,
		content: string | null = null,
		icon: IconName | null = null;
	if (todo.type === 'phonecall') {
		if (contact && (contact.phone || contact.cellPhone) && todo.client) {
			icon = 'user';
			number = contact.cellPhone || contact.phone;
			linkProps = number
				? ReactTemplates.TOOLS.upVoice(
						{ ...contact, client: todo.client as Contact['client'] },
						'contact',
						number
				  )
				: {};
		} else if (todo.client && todo.client.phone) {
			icon = 'home';
			number = todo.client.phone;
			linkProps = ReactTemplates.TOOLS.upVoice(todo.client, 'client', number);
		}

		if (number) {
			const num = phoneNumberHelper.format(
				number,
				phoneNumberHelper.getCountryCodeForNumber(number),
				'INTERNATIONAL'
			);
			content = `${T('default.call')} ${num}`;
		}
	} else if (todo.type === 'appointment' && todo.weblinkUrl) {
		icon = 'video';
		linkProps = { href: todo.weblinkUrl, target: '_blank' };
		content = T('todoListTableRow.joinMeeting');
	}

	return (
		<Fragment>
			{content ? (
				<Link className={classes.b()} {...linkProps}>
					<DefaultButton size="xs">
						<div className={classes.elem('content-wrapper').b()}>
							{icon ? <Icon name={icon} space="mrs" /> : null}
							<Text color="inherit" size="sm">
								{content}
							</Text>
						</div>
					</DefaultButton>
				</Link>
			) : null}
			{todo.location ? getAdress(todo.location, classes) : null}
		</Fragment>
	);
};

export default TodoListTableRowActionButton;
