import T from 'Components/Helpers/translate';
import React, { useEffect, useState } from 'react';
import BemClass from '@upsales/components/Utils/bemClass';
import './ReportcenterTV.scss';
import ReportcenterTVPlay from './ReportcenterTVPlay';
import ReportcenterTVList from './ReportcenterTVList';
import ReportTVResource from 'Resources/ReportTV';
import { Button, Icon, ModalHeader } from '@upsales/components';

type Props = {
	close: () => void;
	className: string;
	dashboards: any[];
};

const ReportcenterTV = ({ close, className, dashboards }: Props) => {
	const classes = new BemClass('ReportcenterTV', className);
	classes.add('FullScreenModal');

	const [activeChannel, setActiveChannel] = useState(null);
	const [channels, setChannels] = useState([]);
	const [showPlayHeader, setShowPlayHeader] = useState(true);
	const [loading, setLoading] = useState(true);

	const getData = async () => {
		const { data } = await ReportTVResource.find();
		setChannels(data);
		setLoading(false);
	};

	const save = async (channel: any) => {
		channel.dashboards.forEach((db: any, index: number) => {
			db.sortId = index + 1;
		});
		await ReportTVResource.save(channel);
		getData();
	};

	const deleteChannel = async (channel: any) => {
		await ReportTVResource.delete(channel.id);
		getData();
	};

	const timeout = React.useRef<any>(null);
	useEffect(() => {
		const handleEsc = (event: any) => {
			if (event.key === 'Escape') {
				close();
			}
		};

		const handlePointerMove = () => {
			if (document.fullscreenElement !== null) {
				setShowPlayHeader(true);
				if (typeof timeout.current === 'number') {
					clearTimeout(timeout.current);
				}

				timeout.current = setTimeout(() => {
					setShowPlayHeader(false);
				}, 2000);
			}
		};

		const handleFullscreenChange = () => {
			if (document.fullscreenElement === null) {
				clearTimeout(timeout.current);
				setShowPlayHeader(true);
				setActiveChannel(null);
			} else {
				setShowPlayHeader(false);
			}
		};

		getData();

		window.addEventListener('pointermove', handlePointerMove);
		window.addEventListener('keydown', handleEsc);
		window.addEventListener('fullscreenchange', handleFullscreenChange);
		return () => {
			window.removeEventListener('keydown', handleEsc);
			window.removeEventListener('pointermove', handlePointerMove);
			window.removeEventListener('fullscreenchange', handleFullscreenChange);
		};
	}, []);

	return (
		<div className={classes.b()}>
			{activeChannel ? (
				<>
					{showPlayHeader ? (
						<ModalHeader id="play-header">
							<Button
								color="grey-2"
								onClick={() => {
									if (document.fullscreenElement) {
										document.exitFullscreen();
										setActiveChannel(null);
									}
								}}
							>
								<Icon name="times" />
							</Button>
						</ModalHeader>
					) : null}
					<ReportcenterTVPlay channel={activeChannel} />
					<div className={classes.elem('curtain').b()} />
				</>
			) : (
				<>
					<ModalHeader onClose={close} icon="tv" title={T('reportcenter.tv')} />
					{!loading ? (
						<ReportcenterTVList
							channels={channels}
							setActiveChannel={setActiveChannel}
							save={save}
							deleteChannel={deleteChannel}
							dashboards={dashboards.map(db => ({
								...db,
								title: db.name,
								dashboardId: db.id.toString()
							}))}
						/>
					) : null}
				</>
			)}
		</div>
	);
};
export default ReportcenterTV;
