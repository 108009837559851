export const RESET_STATE = '[FileStorageApps] RESET_STATE';
export const FILE_STORAGE_FETCHING = '[FileStorageApps] FILE_STORAGE_FETCHING';
export const STOP_FILE_STORAGE_FETCHING = '[FileStorageApps] STOP_FILE_STORAGE_FETCHING';
export const SET_FILE_STORAGE_FOLDER_SUCCESS = '[FileStorageApps] SET_FILE_STORAGE_FOLDER_SUCCESS';
export const REMOVE_FILE_STORAGE_FOLDER_SUCCESS = '[FileStorageApps] REMOVE_FILE_STORAGE_FOLDER_SUCCESS';

export const initialState = {
	selectedApps: [],
	selectedAppsFetching: false
};

const ACTION_HANDLERS = {
	[RESET_STATE]: () => ({ ...initialState }),
	[FILE_STORAGE_FETCHING]: state => ({ ...state, selectedAppsFetching: true }),
	[STOP_FILE_STORAGE_FETCHING]: state => ({ ...state, selectedAppsFetching: false }),
	[SET_FILE_STORAGE_FOLDER_SUCCESS]: (state, action) => ({
		...state,
		selectedAppsFetching: false,
		selectedApps: [...state.selectedApps, action.value]
	}),

	[REMOVE_FILE_STORAGE_FOLDER_SUCCESS]: (state, action) => {
		const indexToRemove = state.selectedApps.findIndex(x => x.integrationId === action.value.integrationId);
		return {
			...state,
			selectedAppsFetching: false,
			selectedApps: [
				...state.selectedApps.slice(0, indexToRemove),
				...state.selectedApps.slice(indexToRemove + 1)
			]
		};
	}
};

export default (state = initialState, action) => {
	const handler = ACTION_HANDLERS[action.type];
	return handler ? handler(state, action) : state;
};
