import { Card, Input, Checkbox, Title, Block, Button, Icon, Tooltip, Text } from '@upsales/components';
import React from 'react';
import './TodoCheckboxCard.scss';
import BemClass from '@upsales/components/Utils/bemClass';
import { SlideFade } from 'App/components/animations';
import T from 'Components/Helpers/translate';
import Contact from 'App/resources/Model/Contact';
interface Props {
	sendMail: () => void;
	createAppointment: () => void;
	createOpportunity: () => void;
	createOrder: () => void;
	createCall: () => void;
	createTodo: () => void;
	renderOpportunity: boolean;
	contact?: (Partial<Contact> & Pick<Contact, 'id'>) | null;
	inputProps: React.ComponentProps<typeof Input>;
	checkboxProps: React.ComponentProps<typeof Checkbox>;
	projectPlanId?: number;
}
const TodoCheckboxCard = ({
	sendMail,
	createAppointment,
	createOpportunity,
	createOrder,
	createCall,
	createTodo,
	renderOpportunity,
	contact,
	inputProps,
	checkboxProps,
	projectPlanId
}: Props) => {
	const actions = [
		{
			title: T('default.email'),
			tooltip: T('todo.closeAndCreateMail'),
			icon: 'envelope' as const,
			onClick: sendMail,
			shouldRender: () => !!contact,
			disabled: !contact?.email,
			disabledTooltip: T('activity.outcome.contactDoesNotHaveEmail')
		},
		{
			title: T('default.phonecall'),
			tooltip: T('todo.closeAndCreateCall'),
			icon: 'phone' as const,
			onClick: createCall,
			shouldRender: () => true
		},
		{
			title: T('default.todo'),
			tooltip: T('todo.closeAndCreateTodo'),
			icon: 'activity' as const,
			onClick: createTodo,
			shouldRender: () => true
		},
		{
			title: T('default.appointment'),
			tooltip: T('todo.closeAndCreateAppointment'),
			icon: 'calendar' as const,
			onClick: createAppointment,
			shouldRender: () => true
		},
		{
			title: T('default.opportunity'),
			tooltip: T('todo.closeAndCreateOpportunity'),
			icon: 'opportunity' as const,
			onClick: createOpportunity,
			shouldRender: () => renderOpportunity
		},
		{
			title: T('default.order'),
			tooltip: T('todo.closeAndCreateOrder'),
			icon: 'sales' as const,
			onClick: createOrder,
			shouldRender: () => true
		}
	];
	const classes = new BemClass('TodoCheckboxCard');
	return (
		<Block className={classes.b()}>
			<Card className={classes.elem('input-wrap').b()}>
				<Checkbox {...checkboxProps} />
				{checkboxProps.checked ? <Text color="grey-10">{inputProps?.value}</Text> : <Input {...inputProps} />}
			</Card>
			<SlideFade
				bounce
				height
				maxHeight={checkboxProps.checked ? 170 : 0}
				visible={!!(checkboxProps.checked && !projectPlanId && actions.filter(a => a.shouldRender()).length)}
				direction="top"
			>
				<Card
					space="pll prl"
					className={classes
						.elem('followup')
						.mod({
							visible: !!(
								checkboxProps.checked &&
								!projectPlanId &&
								actions.filter(a => a.shouldRender()).length
							)
						})
						.b()}
				>
					<Title>{T('todo.anyFollowUp')}</Title>
					<Block space="ptl" className={classes.elem('buttons').b()}>
						{actions
							.filter(a => a.shouldRender())
							.map((a, index) => (
								<Tooltip
									distance={30}
									position="top"
									key={index}
									disabled={!a.disabled && !a.tooltip}
									title={a.tooltip || a.disabledTooltip || ''}
								>
									<Button onClick={a.onClick} disabled={a.disabled}>
										<Icon space="mrm" name={a.icon} />
										{a.title}
									</Button>
								</Tooltip>
							))}
					</Block>
				</Card>
			</SlideFade>
		</Block>
	);
};

export default TodoCheckboxCard;
