import logError from 'App/babel/helpers/logError';

const prefix = '[Insights] ';
export const actions = {
	RESET_STATE: `${prefix} Reset state`,
	FETCH_DASHBOARDS_PENDING: `${prefix} Fetch dashboards pending`,
	FETCH_DASHBOARDS_ERROR: `${prefix} Fetch dashboards error`,
	FETCH_DASHBOARDS_SUCCESS: `${prefix} Fetch dashboards success`,
	FETCH_REPORTS_PENDING: `${prefix} Fetch reports pending`,
	FETCH_REPORTS_ERROR: `${prefix} Fetch reports error`,
	FETCH_REPORTS_SUCCESS: `${prefix} Fetch reports success`,
	FETCH_EXPLORES_PENDING: `${prefix} Fetch explores pending`,
	FETCH_EXPLORES_ERROR: `${prefix} Fetch explores error`,
	FETCH_EXPLORES_SUCCESS: `${prefix} Fetch explores success`,
	FETCH_SIGNED_URL_PENDING: `${prefix} Fetch signed URL pending`,
	FETCH_SIGNED_URL_ERROR: `${prefix} Fetch signed URL error`,
	FETCH_SIGNED_URL_SUCCESS: `${prefix} Fetch signed URL success`,
	OPEN_DROPDOWN: `${prefix} Open dropdown menu`,
	CLOSE_DROPDOWN: `${prefix} Close dropdown menu`,
	GO_TO_HOME: `${prefix} Go to home`,
	REFRESH_DATA: `${prefix} Refresh data`,
	SET_STATE_PARAMS_TYPE: `${prefix} Set state params type`,
	UPDATE_DROPDOWN_SEARCH: `${prefix} Update dropdown search`
};

const initialState = {
	basicReporting: [],
	dashboards: [],
	reports: [],
	explores: [],
	dashboardsPending: false,
	signedUrl: null,
	openDropdown: null,
	stateParamsType: '',
	dropdownSearch: ''
};

const fetchDashboardsSucess = dashboards => ({
	type: actions.FETCH_DASHBOARDS_SUCCESS,
	dashboards: dashboards.filter(dashboard => !dashboard.id || dashboard.id.indexOf('basic_reporting::') !== 0),
	basicReporting: dashboards.filter(dashboard => dashboard.id.indexOf('basic_reporting::') === 0)
});

const getSignedUrl = function (type, id, useNext) {
	return dispatch => {
		dispatch({ type: actions.FETCH_SIGNED_URL_PENDING });
		return Tools.LookerSSO.get(type, id, useNext)
			.then(function (url) {
				dispatch({ type: actions.FETCH_SIGNED_URL_SUCCESS, signedUrl: url.data });
			})
			.catch(err => {
				console.error(`${prefix} Fetch signed url failed`, err);
				dispatch({ type: actions.FETCH_SIGNED_URL_ERROR });
			});
	};
};

const fetchDashboards = () => {
	return dispatch => {
		dispatch({ type: actions.FETCH_DASHBOARDS_PENDING });
		Tools.LookerDashboard.list()
			.then(dashboards => {
				dispatch(fetchDashboardsSucess(dashboards.data));
			})
			.catch(err => {
				console.error('Failed to fetch dashboards', err);
				dispatch({ type: actions.FETCH_DASHBOARDS_ERROR });
			});
	};
};

const fetchReports = () => {
	return dispatch => {
		dispatch({ type: actions.FETCH_REPORTS_PENDING });
		Tools.LookerLook.list()
			.then(reports => {
				dispatch({ type: actions.FETCH_REPORTS_SUCCESS, reports: reports.data });
			})
			.catch(err => {
				console.error('Failed to fetch reports', err);
				dispatch({ type: actions.FETCH_REPORTS_ERROR });
			});
	};
};

const fetchExplores = () => {
	return dispatch => {
		dispatch({ type: actions.FETCH_EXPLORES_PENDING });
		Tools.LookerExplore.list()
			.then(explores => {
				dispatch({ type: actions.FETCH_EXPLORES_SUCCESS, explores: explores.data });
			})
			.catch(err => {
				console.error('Failed to fetch explores', err);
				dispatch({ type: actions.FETCH_EXPLORES_ERROR });
			});
	};
};

const goToHome = () => {
	return { type: actions.GO_TO_HOME };
};

export const openDropdown = dropdown => {
	return { type: actions.OPEN_DROPDOWN, dropdown };
};

export const updateDropdownSearch = value => {
	return { type: actions.UPDATE_DROPDOWN_SEARCH, dropdownSearch: value };
};

export const closeDropdown = () => {
	return { type: actions.CLOSE_DROPDOWN };
};

export const refreshData = () => {
	return { type: actions.REFRESH_DATA };
};

export const setFavorite = (dashboardId, isFavorite) => {
	return (dispatch, getState) => {
		const { Insights } = getState();
		let updatedDashboard;
		const nextDashboards = Insights.dashboards.map(dashboard => {
			if (dashboard.id !== dashboardId) {
				return dashboard;
			}

			updatedDashboard = {
				...dashboard,
				isFavorite
			};

			return updatedDashboard;
		});

		dispatch(fetchDashboardsSucess(nextDashboards));
		Tools.LookerDashboard.save(updatedDashboard).catch(e => {
			logError(e, 'Failed saving looker dashboards');
			dispatch(fetchDashboardsSucess(Insights.dashboards));
		});
	};
};

const setStateParamsType = type => {
	return { type: actions.SET_STATE_PARAMS_TYPE, stateParamsType: type };
};

export const onStateChange = stateParams => {
	return (dispatch, getState) => {
		const { Insights } = getState();
		if (!Insights.dashboards.length) {
			dispatch(fetchDashboards());
		}

		if (!Insights.reports.length) {
			dispatch(fetchReports());
		}

		if (!Insights.explores.length) {
			dispatch(fetchExplores());
		}

		if (stateParams.type && stateParams.id) {
			const type = stateParams.type === 'basicreporting' ? 'dashboard' : stateParams.type;
			dispatch(getSignedUrl(type, stateParams.id, stateParams.useNext === 'true'));
		} else {
			dispatch(goToHome());
		}

		dispatch(setStateParamsType(stateParams.type));
	};
};

const ACTION_HANDLERS = {
	[actions.RESET_STATE]: () => ({ ...initialState }),
	[actions.FETCH_DASHBOARDS_PENDING]: state => ({ ...state, dashboardsPending: true }),
	[actions.FETCH_DASHBOARDS_SUCCESS]: (state, action) => ({
		...state,
		dashboards: action.dashboards,
		basicReporting: action.basicReporting,
		dashboardsPending: false
	}),
	[actions.FETCH_DASHBOARDS_ERROR]: state => ({ ...state, dashboardsPending: false }),
	[actions.FETCH_REPORTS_SUCCESS]: (state, action) => ({ ...state, reports: action.reports }),
	[actions.FETCH_EXPLORES_SUCCESS]: (state, { explores }) => ({ ...state, explores }),
	[actions.FETCH_SIGNED_URL_SUCCESS]: (state, { signedUrl }) => ({ ...state, signedUrl }),
	[actions.GO_TO_HOME]: state => ({ ...state, signedUrl: null }),
	[actions.OPEN_DROPDOWN]: (state, { dropdown }) => ({ ...state, openDropdown: dropdown, dropdownSearch: '' }),
	[actions.CLOSE_DROPDOWN]: state => ({ ...state, openDropdown: null, dropdownSearch: '' }),
	[actions.REFRESH_DATA]: state => ({ ...state, explores: [], dashboards: [], reports: [] }),
	[actions.SET_STATE_PARAMS_TYPE]: (state, { stateParamsType }) => ({ ...state, stateParamsType }),
	[actions.UPDATE_DROPDOWN_SEARCH]: (state, { dropdownSearch }) => ({ ...state, dropdownSearch })
};

export default (state = initialState, action) => {
	const handler = ACTION_HANDLERS[action.type];
	return handler ? handler(state, action) : state;
};
