import { Equals } from 'Resources/ComparisonTypes';
import DatePreset from 'App/upsales/common/services/datePresetService';

export const newFilter = () => ({
	inactive: true,
	value: {
		hasVisits: true,
		Date: { value: { start: null, end: null, preset: 'currentMonth' } },
		Page: { value: [], comparisonType: Equals }
	}
});

const visitorFilter = {
	generate: newFilter,
	isInactive: filter => filter.inactive,
	toUrl: filter => {
		const f = { v: {} };
		const val = filter.value;

		f.v.has = val.hasVisits;

		if (val.Date.value.start || val.Date.value.end || val.Date.value.preset) {
			f.v.da = { p: val.Date.value.preset, s: val.Date.value.start, e: val.Date.value.end };
		}
		if (val.Page.value) {
			f.v.pa = val.Page.value;
		}
		if (val.Page.comparisonType) {
			f.v.pc = val.Page.comparisonType;
		}

		return f;
	},
	getText: () => {
		return 0;
	},
	fromUrl: rawFilter => {
		const filter = newFilter();
		filter.inactive = false;
		const val = rawFilter.v;

		filter.value.hasVisits = val.has;

		if (val.da) {
			filter.value.Date = {
				value: {
					start: val.da.s ? new Date(val.da.s) : val.da.s,
					end: val.da.e ? new Date(val.da.e) : val.da.e,
					preset: val.da.p
				}
			};
		}
		if (val.pa) {
			filter.value.Page.value = val.pa;
		}
		if (val.pc) {
			filter.value.Page.comparisonType = val.pc;
		}

		return filter;
	},
	build: () => (filter, rb, getField, useTags) => {
		const val = filter.value;

		if (filter.inactive) {
			return;
		}

		if (!val.hasVisits && val.Date.value.preset === 'whenever' && !val.Page.value.length) {
			// If all filters is empty we only need to check for missing campaigns
			return rb.addFilter(getField('visit.id'), rb.comparisonTypes.Equals, null);
		} else if (val.hasVisits && val.Page.comparisonType === rb.comparisonTypes.EqualsAll && val.Page.value.length) {
			for (const value of val.Page.value) {
				const groupBuilder = rb.groupBuilder();

				// Date
				if (val.Date.value.preset !== 'whenever') {
					const dates = DatePreset.getDatesFromValue(val.Date.value, useTags);

					// start
					if (dates.start) {
						groupBuilder.addFilter(
							getField('visit.startDate'),
							rb.comparisonTypes.GreaterThanEquals,
							dates.start
						);
					}

					// end
					if (dates.end) {
						groupBuilder.addFilter(
							getField('visit.startDate'),
							rb.comparisonTypes.LowerThanEquals,
							dates.end
						);
					}
				}

				groupBuilder.addFilter(getField('visit.page.url'), rb.comparisonTypes.Term, value);

				groupBuilder.done();
			}
		} else {
			const groupBuilder = rb.groupBuilder();
			if (val.hasVisits) {
				groupBuilder.addFilter(getField('visit.id'), rb.comparisonTypes.NotEquals, null);
			} else {
				groupBuilder.isNotFilter();
			}

			// Date
			if (val.Date.value.preset !== 'whenever') {
				const dates = DatePreset.getDatesFromValue(val.Date.value, useTags);

				// start
				if (dates.start) {
					groupBuilder.addFilter(
						getField('visit.startDate'),
						rb.comparisonTypes.GreaterThanEquals,
						dates.start
					);
				}

				// end
				if (dates.end) {
					groupBuilder.addFilter(getField('visit.startDate'), rb.comparisonTypes.LowerThanEquals, dates.end);
				}
			}

			if (val.Page.value.length) {
				groupBuilder.addFilter(getField('visit.page.url'), rb.comparisonTypes.Term, val.Page.value);
			}

			groupBuilder.done();
		}
	}
};

export default visitorFilter;
