import RequestBuilder from 'Resources/RequestBuilder';

export default function resetScore(rb: RequestBuilder, entity: string, extraParams?: object) {
	const opts = {
		customerId: Tools.AppService.getCustomerId(),
		filters: rb,
		extraParams,
		entity,
		hideDate: false
	};
	return Tools.$upModal.open('LeadResetScore', opts);
}
