import React from 'react';

export class ParentLine extends React.Component {
	render() {
		return <td className="DataSourceColumn parent-line-column" />;
	}
}

export class ParentLineLast extends React.Component {
	render() {
		return <td className="DataSourceColumn last-parent-line-column" />;
	}
}

export class ParentLineStraight extends React.Component {
	render() {
		return <td className="DataSourceColumn straight-parent-line-column" />;
	}
}
