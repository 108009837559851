const getValue = value => (value === 'true' || value === true ? true : false);

export const fixOldHistoryFilters = filters => {
	filters.forEach(function (listView) {
		listView.filters.forEach(function (filter) {
			if (filter.filterName === 'HasActivity' && typeof filter.value !== 'object') {
				filter.value = {
					hasActivity: getValue(filter.value),
					overdueAsClosed: false,
					ActivityType: { value: [] },
					Date: { value: { start: null, end: null, preset: 'whenever' } },
					Campaign: { value: [] },
					User: { value: [] },
					Status: { value: null }
				};
			} else if (filter.filterName === 'HasOrder' && typeof filter.value !== 'object') {
				filter.value = {
					hasOrder: getValue(filter.value),
					OrderRowProduct: { value: [] },
					OrderRowProductCategory: { value: [] },
					Value: { value: { start: null, end: null } },
					Date: { value: { start: null, end: null, preset: 'whenever' } },
					Campaign: { value: [] },
					User: { value: [] },
					Stage: { value: [] },
					Currency: { value: null }
				};
			} else if (filter.filterName === 'HasOpportunity' && typeof filter.value !== 'object') {
				filter.value = {
					hasOpportunity: getValue(filter.value),
					OrderRowProduct: { value: [] },
					OrderRowProductCategory: { value: [] },
					Value: { value: { start: null, end: null } },
					Date: { value: { start: null, end: null, preset: 'whenever' } },
					Campaign: { value: [] },
					User: { value: [] },
					Stage: { value: [] },
					Won: { value: null },
					Currency: { value: null }
				};
			}
		});
	});
};
