import React from 'react';
import { Text } from '@upsales/components';
import BemClass from '@upsales/components/Utils/bemClass';

export type User = {
	id: number;
	name: string;
};

export type Config = {
	integrationId: number;
	integrationName: string;
	name: string;
	type: string;
};

export type StandardIntegration = {
	type: string;
	data: {
		object: unknown;
		user: User;
	};
	typeId: string;
	integrationId: number;
};

type PlainTextRow = React.ComponentProps<typeof Text> & {
	text: string;
	style?: string;
	icon?: string;
	click?: {
		type: string;
		name: string;
		to?: string;
		id?: unknown;
	};
};

type WidgetRow = {
	row: PlainTextRow;
	config: Config;
	object: unknown;
	getUser: () => User;
	runStandardIntegration: (standardIntegration: StandardIntegration) => void;
	getIcon: (icon: string, style?: string) => HTMLDivElement;
	runClick: (row: PlainTextRow) => void;
};

const PlainTextRenderType = ({
	row,
	object,
	getUser,
	config,
	runStandardIntegration,
	getIcon,
	runClick
}: WidgetRow) => {
	let onClick;
	const classes = new BemClass('widget-text');

	if (row.style) {
		classes.add(row.style);
	}

	if (row.click) {
		onClick = (event: React.MouseEvent<HTMLElement>) => {
			event?.stopPropagation?.();

			if (row.click!.type === 'widgetRow') {
				runStandardIntegration({
					type: 'widgetRow',
					data: { object: object, user: getUser() },
					typeId: row.click!.name,
					integrationId: config.integrationId
				});
			} else {
				runClick(row);
			}
		};
	}

	return (
		<Text className={classes.b()} size={row.size || 'sm'} onClick={onClick}>
			{row.icon ? getIcon(row.icon, row.style) : null}
			{row.text}
		</Text>
	);
};

export default PlainTextRenderType;
