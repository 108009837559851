import React from 'react';
import PropTypes from 'prop-types';
import { Input } from '@upsales/components';
import BemClass from '@upsales/components/Utils/bemClass';
import './SelectUserGroup.scss';
import SelectItem from './SelectItem';
import { VirtualWidthMeasurer, AsyncVirtualList } from 'App/components/VirtualList';
import { ComparisonTypeEnum, GroupedComparisonTypes } from 'Resources/ComparisonTypes';

class SelectUserGroup extends React.Component {
	constructor(props) {
		super(props);
		this.classes = new BemClass('SelectUserGroup');
		this.searchTimeout = 0;
		this.state = {
			search: '',
			results: this.props.results ?? [],
			selectedComparator: props.selectedComparator,
			maxHeight: 0
		};

		const t = Tools.$translate;
		this.lang = {
			title: props.title ? t(props.title) : '',
			searchPlaceholder: t('default.search'),
			filtersAll: t('filters.all').toLowerCase(),
			filtersMore: t('filters.more').toLowerCase(),
			defaultSelect: t('default.select'),
			defaultAll: t('default.all'),
			defaultAnd: t('default.and').toLowerCase(),
			defaultOr: t('default.or').toLowerCase(),
			filtersEquals: t('filters.Equals').toLowerCase(),
			filtersNotEquals: t('filters.NotEquals').toLowerCase(),
			defaultHit: t('default.hit').toLowerCase(),
			defaultHits: t('default.hits').toLowerCase()
		};
		this.lang.titleLowerCase = this.lang.title.toLowerCase();

		this.onSearch = this.onSearch.bind(this);
		this.toggleComparator = this.toggleComparator.bind(this);

		this.contentRef = React.createRef();
		this.containerRef = React.createRef();

		this.virtualWidthMeasurer = new VirtualWidthMeasurer({
			defaultWidth: 100,
			minWidth: 70,
			fixedHeight: true
		});

		this.defaultWidth = 0;
	}

	componentDidUpdate() {
		if (this.props.results !== this.state.results) {
			this.setState({ results: this.props.results }, () => {
				if (this.props.useVirtualList) {
					this.virtualWidthMeasurer.reset();
					this.recalculateMaxWidth();
				}
			});
		}
	}

	componentDidMount() {
		const { useVirtualList } = this.props;
		if (useVirtualList) {
			this.defaultWidth = this.contentRef.current.scrollWidth;
			this.setVirtualDimensionState();
			this.recalculateMaxWidth();
		}
	}

	componentWillUnmount() {
		if (this.props.async) {
			this.props.async();
		}
	}

	setVirtualDimensionState() {
		if (!this.contentRef.current) {
			return;
		}
		const maxHeightAsString = getComputedStyle(this.contentRef.current).getPropertyValue(
			'--select-user-group-content-max-height'
		);
		let maxHeight = parseInt(maxHeightAsString);
		if (isNaN(maxHeight)) {
			maxHeight = 0;
		}
		this.setState({ maxHeight });
	}

	toggleComparator() {
		let selectedComparator = this.state.selectedComparator;

		if (GroupedComparisonTypes.Match.includes(selectedComparator)) {
			selectedComparator =
				selectedComparator === ComparisonTypeEnum.Match
					? ComparisonTypeEnum.NotMatch
					: ComparisonTypeEnum.Match;
		} else {
			selectedComparator =
				selectedComparator === ComparisonTypeEnum.Equals
					? ComparisonTypeEnum.NotEquals
					: ComparisonTypeEnum.Equals;
		}

		this.setState({ selectedComparator });
		this.props.toggleComparator(selectedComparator);
	}

	reset = () => {
		const selectedComparator = GroupedComparisonTypes.Match.includes(this.state.selectedComparator)
			? ComparisonTypeEnum.Match
			: ComparisonTypeEnum.Equals;

		this.setState({ selectedComparator });
		this.props.reset(selectedComparator);
	};

	renderItem = item => {
		const shouldShowUser = !this.props.hideInactive || (item.active && !item.ghost);

		if (item.isRole) {
			if (this.props.hideEmptyRoles && (item.children.length === 0 || item.$$length === 0)) {
				return null;
			}

			return (
				<div key={'role' + item.$id}>
					<SelectItem item={item} selectGroup={this.props.selectGroup} displayText={this.props.displayText} />
					{this.userTree(item.children)}
				</div>
			);
		} else if (shouldShowUser) {
			return (
				<SelectItem
					key={item.id}
					item={item}
					select={this.props.select}
					selectOne={this.props.selectOne}
					displayText={this.props.displayText}
					singleSelect={this.props.singleSelect}
				/>
			);
		} else {
			return null;
		}
	};

	userTree(items) {
		if (!items) return null;

		const list = items.map(this.renderItem);

		return <div>{list}</div>;
	}

	recalculateMaxWidth = () => {
		// When the virtual list has loaded more results, we need to update the width of the container
		const maxWidth = this.virtualWidthMeasurer.getMaxWidth();
		if (maxWidth > this.defaultWidth && this.containerRef.current) {
			this.containerRef.current.style.width = maxWidth + 'px';
		}
	};

	virtualList(items) {
		const rowHeight = 46;
		const maxHeight = this.state.maxHeight - 46; // -46 since we have a select all button row above
		const height = Math.min(maxHeight, rowHeight * items.length);

		return (
			<AsyncVirtualList
				height={height}
				items={items}
				rowHeight={rowHeight}
				cellMeasurerCache={this.virtualWidthMeasurer.cellMeasurerCache}
				renderRow={(item, style) => (
					<SelectItem
						style={style}
						key={item.id}
						item={item}
						select={this.props.select}
						selectOne={this.props.selectOne}
						displayText={this.props.displayText}
						singleSelect={this.props.singleSelect}
					/>
				)}
				shouldShowRow={item => !this.props.hideInactive || !!(item.active && !item.ghost)}
				loadMoreResults={offset => this.props.asyncOffset?.(offset)}
				onLoadMoreResults={this.recalculateMaxWidth}
			/>
		);
	}

	countChildren(item) {
		if (item.children) {
			return item.children.reduce((res, child) => res + this.countChildren(child), 0);
		}
		return 1;
	}

	filterResult(filter, children) {
		return children.reduce((result, child) => {
			const displayText =
				typeof this.props.displayText === 'function'
					? this.props.displayText(child)
					: child[this.props.displayText];
			if (displayText.toLowerCase().indexOf(filter.toLowerCase()) > -1) {
				result.push(child);
			} else if (child.children) {
				const childResult = this.filterResult(filter, child.children);
				if (childResult.length) {
					result.push({
						...child,
						children: childResult,
						$$length: this.countChildren({ children: childResult })
					});
				}
			}
			return result;
		}, []);
	}

	onSearch(e) {
		const value = e.target.value;
		this.setState({ search: value });

		if (this.props.async) {
			this.props.async(value);
		}
	}

	static getDerivedStateFromProps(props, state) {
		if (state.search) return null;
		return { results: props.results };
	}

	render() {
		const { useVirtualList, resultsMetadata, resetOnSelectAll } = this.props;
		const results = this.filterResult(this.state.search, this.state.results);

		const totalResultsLength = resultsMetadata?.total || results.length;

		return (
			<div ref={this.containerRef} className={this.classes.b()}>
				<div className={this.classes.elem('search-wrap').b()}>
					<Input
						autoFocus={true}
						placeholder={this.lang.searchPlaceholder}
						value={this.state.search}
						onChange={this.onSearch}
					/>
					{this.state.results.length && this.state.search ? (
						<div className={this.classes.elem('search-info').b()}>
							{totalResultsLength}{' '}
							{totalResultsLength === 1 ? this.lang.defaultHit : this.lang.defaultHits}
						</div>
					) : null}
				</div>

				<div
					ref={this.contentRef}
					className={this.classes.elem('content').mod({ overflowY: !useVirtualList }).b()}
				>
					{!this.props.required ? (
						<SelectItem
							item={{
								name: this.lang.defaultSelect + ' ' + this.lang.filtersAll,
								$$selected: this.props.allSelected,
								isSelectAll: true
							}}
							displayText="name"
							selectAll={resetOnSelectAll ? this.reset : this.props.selectAll}
						/>
					) : null}
					{useVirtualList ? this.virtualList(results) : this.userTree(results)}
				</div>
				{this.props.singleSelect ? null : (
					<div className={this.classes.elem('toolbar').b()}>
						{this.props.selectedRows && this.props.selectedRows.length ? (
							<span>
								{this.lang.title}{' '}
								<a onClick={this.toggleComparator} className="toggler">
									{this.state.selectedComparator === ComparisonTypeEnum.Equals ||
									this.state.selectedComparator === ComparisonTypeEnum.Match
										? this.lang.filtersEquals
										: this.lang.filtersNotEquals}
								</a>
								{' ' + this.props.firstSelectedText}
								{this.props.selectedRows.length > 1 ? (
									<span>
										{' ' +
											(GroupedComparisonTypes.Match.includes(this.state.selectedComparator)
												? this.lang.defaultOr
												: this.lang.defaultAnd)}{' '}
										{this.props.selectedRows.length - 1} {this.lang.filtersMore}
									</span>
								) : null}
							</span>
						) : (
							<span>
								{this.lang.defaultAll} {this.lang.titleLowerCase}
							</span>
						)}
					</div>
				)}
			</div>
		);
	}
}

SelectUserGroup.propTypes = {
	results: PropTypes.array,
	resultsMetadata: PropTypes.object,
	selectedRows: PropTypes.array,
	select: PropTypes.func,
	selectOne: PropTypes.func,
	selectGroup: PropTypes.func,
	selectAll: PropTypes.func,
	toggleComparator: PropTypes.func,
	title: PropTypes.string,
	async: PropTypes.func,
	asyncOffset: PropTypes.func,
	selectedComparator: PropTypes.string,
	firstSelectedText: PropTypes.string,
	allSelected: PropTypes.bool,
	hideInactive: PropTypes.bool,
	displayText: PropTypes.oneOfType([PropTypes.func, PropTypes.string]),
	hideEmptyRoles: PropTypes.bool,
	singleSelect: PropTypes.bool,
	required: PropTypes.bool,
	useVirtualList: PropTypes.bool,
	resetOnSelectAll: PropTypes.bool,
	reset: PropTypes.func
};

export default SelectUserGroup;
