import React, { Fragment } from 'react';
import Select from 'react-select';
import { ValueType } from 'react-select/lib/types';
import { compact } from 'lodash';
import bemClass from '@upsales/components/Utils/bemClass';
import {
	Button,
	Card,
	CardContent,
	Column,
	DrawerHeader,
	Icon,
	Input,
	Label,
	Row,
	Textarea,
	Help
} from '@upsales/components';
import PropTypes from 'prop-types';

import './UtmLinkGenerator.scss';
import copyToClipboard from 'Services/copyToClipboard';

// Campaign name
// This one is placed before the Source field mainly because the field beneath the source field depends on what source the user selects
//
// Campaign source
// It’s a dropdown, so the user can select from the different categories the filter in the website visit list supports.
// 	Users can select “Other“ in the dropdown to create a custom link.
//
// 	Source categories:
// Paid Social
// UTM Source = dropdown where user selects social network
// UTM Medium = paidsocial, user cannot change this value
//
// Social
// UTM Source = dropdown where user selects social network
// UTM Medium = social, user cannot change this value

// Paid Search
// UTM Source = ppc, user cannot change this value
// UTM Medium = free text
//
// Other campaign
// All are free text.

type Props = {
	url: string;
	campaign: string;
	close: () => void;
	className?: string;
};

type State = {
	url: string;
	copied: boolean;
	campaignSource?: null | CampaignSource;
	utmSourceItem?: null | UtmSource;
	/* eslint-disable camelcase */
	utm_source: string;
	utm_medium: string;
	utm_campaign: string;
	utm_term: string;
	utm_content: string;
	/* eslint-enable camelcase */
};

type UtmSource = { key: string; value: string };

type CampaignSource = {
	key: string;
	value: string;
	/* eslint-disable camelcase */
	utm_medium?: string;
	utm_sources?: Array<UtmSource>;
	utm_source?: string;
	/* eslint-enable camelcase */
};

class UtmLinkGenerator extends React.Component<Props, State> {
	lang: { [key: string]: string };

	static propTypes = {
		url: PropTypes.string,
		campaign: PropTypes.string,
		close: PropTypes.func,
		className: PropTypes.string
	};

	static defaultProps = {
		url: '',
		campaign: '',
		close: () => null
	};

	socialNetworks = [
		{ key: 'facebook', value: 'Facebook' },
		{ key: 'linkedin', value: 'Linkedin' },
		{ key: 'twitter', value: 'Twitter' },
		{ key: 'instagram', value: 'Instagram' }
	];

	campaignSources = [
		{ key: 'paidSocial', value: 'Paid Social', utm_medium: 'paidsocial', utm_sources: this.socialNetworks },
		{ key: 'social', value: 'Social', utm_medium: 'social', utm_sources: this.socialNetworks },
		{ key: 'paidSearch', value: 'Paid Search', utm_source: 'adwords' },
		{ key: 'other', value: 'Other campaign' }
	];

	state: State = {
		url: this.props.url,
		copied: false,
		campaignSource: null,
		utmSourceItem: null,
		utm_source: '',
		utm_medium: '',
		utm_campaign: this.props.campaign,
		utm_term: '',
		utm_content: ''
	};

	constructor(props: Props) {
		super(props);

		const t = Tools.$translate;

		this.lang = {
			buildTrackedLink: t('utm.buildTrackedLink'),
			campaign: t('utm.campaign'),
			campaignPlaceholder: t('utm.campaign.placeholder'),
			source: t('utm.source'),
			sourcePlaceholder: t('utm.source.placeholder'),
			content: t('utm.content'),
			contentPlaceholder: t('utm.content.placeholder'),
			copyLink: t('landingpage.copyLink'),
			howUTMWorks: t('utm.howUTMWorks'),
			term: t('utm.term'),
			termPlaceholder: t('utm.term.placeholder'),
			linkCopiedToClipboard: t('default.linkCopiedToClipboard'),
			utmMedium: t('utm.utmMedium'),
			utmMediumPlaceholder: t('utm.utmMedium.placeholder'),
			utmSource: t('utm.utmSource'),
			websiteURL: t('utm.websiteURL'),
			websiteURLPlaceholder: t('utm.websiteURL.placeholder'),
			yourTrackedlink: t('utm.yourTrackedlink')
		};
	}

	copyUTMToClipboard = (url: string) => {
		this.setState({ copied: true });
		copyToClipboard(url);

		setTimeout(() => {
			this.setState({ copied: false });
		}, 1000);
	};

	ensureUrlHasProtocol = () => {
		const { url } = this.state;

		if (url && !url.includes('http')) {
			this.setState({ url: `https://${url}` });
		}
	};

	// eslint-disable-next-line camelcase
	handleCampaignSourceChange = (campaignSource: ValueType<CampaignSource>) => {
		const value = campaignSource as CampaignSource;
		this.setState({
			campaignSource: value,
			utm_medium: value?.utm_medium || '',
			utm_source: value?.utm_source || ''
		});
	};

	handleUTMSourceChange = (utmSourceItem: ValueType<UtmSource>) => {
		const value = utmSourceItem as UtmSource;
		this.setState({ utmSourceItem: value, utm_source: value?.key });
	};

	renderUTMSourceField() {
		const { campaignSource, utmSourceItem } = this.state;

		if (!campaignSource) {
			return null;
		}

		return (
			<Fragment>
				<Label required>{this.lang.utmSource}</Label>
				{campaignSource.utm_sources ? (
					<Select<UtmSource>
						value={utmSourceItem as UtmSource}
						onChange={this.handleUTMSourceChange}
						isClearable={false}
						options={campaignSource.utm_sources}
						getOptionLabel={option => option.value}
						getOptionValue={option => option.key}
					/>
				) : (
					<Input
						value={this.state.utm_source}
						onChange={({ currentTarget: { value } }) => this.setState({ utm_source: value })}
						disabled={!!campaignSource.utm_source}
					/>
				)}
			</Fragment>
		);
	}

	renderUTMMedium() {
		const { campaignSource } = this.state;

		return (
			<Row>
				<Column>
					<Label>{this.lang.utmMedium}</Label>
					<Input
						placeholder={this.lang.utmMediumPlaceholder}
						value={this.state.utm_medium}
						disabled={!!campaignSource!.utm_medium}
						onChange={({ currentTarget: { value } }) => this.setState({ utm_medium: value })}
					/>
				</Column>
			</Row>
		);
	}

	renderCopySection(classes: bemClass) {
		// eslint-disable-next-line camelcase
		const { url, utm_source, utm_campaign, copied } = this.state;

		// eslint-disable-next-line camelcase
		if (compact([utm_campaign, utm_source]).length !== 2) {
			// UTM medium shouldnt be required. The only required UTM tags should be name and
			// source because those are the most frequently used.
			return null;
		}

		const utmLink =
			`${url}?` +
			Object.keys(this.state)
				.filter(key => key.includes('utm_') && this.state[key as keyof State])
				.map(key => `${key}=${encodeURI(this.state[key as keyof State] as string)}`)
				.join('&');

		return (
			<Card className={classes.elem('UtmCode').b()}>
				<CardContent>
					<Label>{this.lang.yourTrackedlink}</Label>
					<Textarea value={utmLink} autoHeight />
					<Button
						color={copied ? 'bright-green' : 'bright-blue'}
						size="md"
						block
						onClick={() => this.copyUTMToClipboard(utmLink)}
					>
						{copied && <Icon className="pull-left" name="check" />}
						{copied ? this.lang.linkCopiedToClipboard : this.lang.copyLink}
					</Button>
				</CardContent>
			</Card>
		);
	}

	render() {
		const { close, campaign, className } = this.props;
		const { url, campaignSource } = this.state;
		const classes = new bemClass('UtmLinkGenerator', className);

		return (
			<div className={classes.b()}>
				<DrawerHeader title={this.lang.buildTrackedLink} onHide={close}>
					<Column fixedWidth={50}>
						<Help articleId={762} sidebar={true} />
					</Column>
				</DrawerHeader>
				<div className={classes.elem('Content').b()}>
					<div className={classes.elem('Fields').b()}>
						<Row>
							<Column>
								<Label required>{this.lang.websiteURL}</Label>
								<Input
									type="url"
									value={url}
									placeholder={this.lang.websiteURLPlaceholder}
									onChange={({ currentTarget: { value } }) => this.setState({ url: value })}
									onBlur={this.ensureUrlHasProtocol}
									autofocus={true}
								/>
							</Column>
						</Row>
						<Row>
							<Column>
								<Label required>{this.lang.campaign}</Label>
								<Input
									placeholder={this.lang.campaignPlaceholder}
									defaultValue={campaign}
									onChange={({ currentTarget: { value } }) => this.setState({ utm_campaign: value })}
									disabled={!url}
								/>
							</Column>
						</Row>
						<Row>
							<Column>
								<Label required>{this.lang.source}</Label>
								<Select<CampaignSource>
									isClearable={false}
									value={campaignSource as CampaignSource}
									placeholder={this.lang.sourcePlaceholder}
									getOptionLabel={option => option.value}
									getOptionValue={option => option.key}
									options={(this.campaignSources as unknown) as CampaignSource[]}
									isDisabled={!url}
									onChange={this.handleCampaignSourceChange}
								/>
							</Column>
							<Column>{this.renderUTMSourceField()}</Column>
						</Row>
						{url && campaignSource && (
							<Fragment>
								{this.renderUTMMedium()}
								<Row>
									<Column>
										<Label>{this.lang.term}</Label>
										<Input
											placeholder={this.lang.termPlaceholder}
											onChange={({ currentTarget: { value } }) =>
												this.setState({ utm_term: value })
											}
										/>
									</Column>
								</Row>
								<Row>
									<Column>
										<Label>{this.lang.content}</Label>
										<Input
											placeholder={this.lang.contentPlaceholder}
											onChange={({ currentTarget: { value } }) =>
												this.setState({ utm_content: value })
											}
										/>
									</Column>
								</Row>
							</Fragment>
						)}
					</div>

					{this.renderCopySection(classes)}
				</div>
			</div>
		);
	}
}

export const detached = UtmLinkGenerator;
export default UtmLinkGenerator;
