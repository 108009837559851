import React, { useEffect, useState, useCallback, useRef } from 'react';
import Contact from 'App/resources/Model/Contact';
import ContactResource from 'Resources/Contact';
import RequestBuilder, { comparisonTypes } from 'Resources/RequestBuilder';
import { AjaxSelect, AjaxSelectProps } from './../AjaxSelect';
import logError from 'Helpers/logError';
import { CancelablePromise, makeCancelable } from 'App/babel/helpers/promise';
import { debounce } from 'lodash';
import { Row, Column, Icon, Text } from '@upsales/components';
import { renderToString } from 'react-dom/server';
import { useTranslation } from 'Components/Helpers/translate';
import { useSoftDeployAccess } from '../hooks';
import { binaryGroup } from 'App/helpers/accountsHelper';

type ContactInSelect = Partial<Pick<Contact, 'id' | 'name' | 'cellPhone' | 'phone' | 'email' | 'client'>> & {
	icon?: string;
	children?: ContactInSelect[];
};

type ContactSelectProps = Pick<
	AjaxSelectProps<ContactInSelect>,
	'value' | 'onChange' | 'multiple' | 'icon' | 'state' | 'name' | 'required' | 'disabled' | 'placeholder'
> & {
	onlyActive?: boolean;
	clientId?: number;
	relatedClientIds?: number[];
	operationalAccountId?: number;
};

type ContactPromise = CancelablePromise<{
	metadata: { total: number };
}>;

const ContactSelect = ({
	onlyActive,
	clientId,
	operationalAccountId,
	relatedClientIds = [],
	...props
}: ContactSelectProps) => {
	const { t } = useTranslation();
	const hasSubAccounts = useSoftDeployAccess('SUB_ACCOUNTS');
	const [isStatic, setIsStatic] = useState(true);
	const fetchContactPromise = useRef<ContactPromise | null>(null);

	const stringifiedRelatedClientIds = relatedClientIds.join(',');

	const modifyRb = useCallback(
		(rb: RequestBuilder) => {
			if (!rb.fields) {
				rb.fields = [];
			}
			rb.fields = [...rb.fields, 'client', 'phone', 'cellPhone', 'email'];

			if (onlyActive) {
				rb.addFilter({ field: 'active' }, comparisonTypes.Equals, 1);
			}
			if (clientId) {
				const clientIds = [clientId, ...relatedClientIds];

				const orFilter = rb.orBuilder();
				orFilter.next();
				orFilter.addFilter({ field: 'client.id' }, comparisonTypes.Equals, clientIds);
				orFilter.next();
				orFilter.addFilter({ field: 'connectedClients.relatedToClientId' }, comparisonTypes.Equals, clientIds);
				orFilter.done();
			}
			return false;
		},
		[onlyActive, clientId, stringifiedRelatedClientIds]
	);

	const getContactLengthToDetermineStatic = useCallback(
		debounce((clientId: number, relatedClientIds: number[]) => {
			const rb = new RequestBuilder();

			const clientIds = [...relatedClientIds];
			if (clientId) {
				clientIds.push(clientId);
			}

			const orFilter = rb.orBuilder();
			orFilter.next();
			orFilter.addFilter({ field: 'client.id' }, comparisonTypes.Equals, clientIds);
			orFilter.next();
			orFilter.addFilter({ field: 'connectedClients.relatedToClientId' }, comparisonTypes.Equals, clientIds);
			orFilter.done();

			rb.addFilter({ field: 'active' }, comparisonTypes.Equals, true);
			rb.limit = 0;
			fetchContactPromise.current = makeCancelable(ContactResource.find(rb.build())) as ContactPromise;
			fetchContactPromise.current.promise
				.then(({ metadata }) => setIsStatic(metadata.total < 500))
				.catch(err => logError(err, 'Faild to fetch from ContactSelect'));
		}, 150),
		[]
	);

	useEffect(() => {
		if (clientId == null) {
			return;
		}
		getContactLengthToDetermineStatic(clientId, relatedClientIds);
		return () => fetchContactPromise.current?.cancel();
	}, [clientId, stringifiedRelatedClientIds]);

	const formatResult = (obj: ContactInSelect, el: any, x: any, encode: (text: string) => string) => {
		const isRelation = obj.client?.id && obj.client.id !== clientId && relatedClientIds.includes(obj.client.id);

		return renderToString(
			<>
				<Row align="center">
					<Column>
						<Text color="inherit">{encode(obj?.name ?? '')}</Text>
						{isRelation ? (
							<Text size="sm" color="inherit">
								<Icon name="sitemap" space="mrm" />
								{obj.client?.name}
							</Text>
						) : null}
					</Column>
					<Row>
						<Text size="sm" color="inherit">
							{obj.email ? <Icon name="email" space="mrl" /> : null}
							{obj.phone ? <Icon name="phone" space="mrl" /> : null}
							{obj.cellPhone ? <Icon name="mobile" space="mrl" /> : null}
						</Text>
					</Row>
				</Row>
			</>
		);
	};

	return (
		<AjaxSelect
			{...props}
			isStatic={isStatic}
			key={'contact-select-clientId-' + clientId}
			resource={ContactResource}
			modifyRb={modifyRb}
			refreshData={stringifiedRelatedClientIds}
			formatResult={formatResult}
			results={(data, term) => {
				if (term.length || isStatic) {
					if (!operationalAccountId) {
						return data;
					}

					const predicate = (contact: ContactInSelect) =>
						operationalAccountId ? operationalAccountId === contact.client?.id : false;
					const [mainContacts, contacts] = binaryGroup(data, predicate);

					if (!hasSubAccounts || !mainContacts.length) {
						return contacts;
					}

					const result: ContactInSelect[] = [];
					if (contacts.length) {
						result.push({
							icon: 'home',
							name: t('default.contacts'),
							children: contacts
						});
					}

					result.push({
						name: t('default.fromMainAccount'),
						children: mainContacts
					});

					return result;
				}
				return [];
			}}
		/>
	);
};

export default ContactSelect;
