import LoginStatsResource from 'Resources/LoginStats';
import RequestBuilder, { comparisonTypes } from 'App/babel/resources/RequestBuilder';
import moment from 'moment';
import { AnyAction } from 'redux';
import { AppThunk } from '..';

const prefix = '[Insights] ';
export const actions = {
	RESET_STATE: `${prefix} Reset state`,
	SET_TABLE_LOADING: `${prefix} Set table loading`,
	SET_TABLE_ERROR: `${prefix} Set table error`,
	SET_ROWS: `${prefix} Set rows`,
	SET_CHART_DATA: `${prefix} Set chart data`
};

const getChartData = (): AppThunk => dispatch => {
	return LoginStatsResource.getYearSum().then(res => {
		dispatch({ type: actions.SET_CHART_DATA, chartData: res.data });
	});
};

const getTableData = (): AppThunk => dispatch => {
	dispatch({ type: actions.SET_TABLE_ERROR, tableError: false });
	dispatch({ type: actions.SET_TABLE_LOADING, tableLoading: true });
	const rb = new RequestBuilder();

	rb.addSort('date', false);

	rb.addFilter(
		{ field: 'date' },
		comparisonTypes.GreaterThanEquals,
		moment().startOf('day').add(-7, 'days').toDate()
	);

	return LoginStatsResource.find(rb.build())
		.then(res => {
			dispatch({ type: actions.SET_TABLE_LOADING, tableLoading: false });
			dispatch({ type: actions.SET_ROWS, rows: res.data });
		})
		.catch(() => {
			dispatch({ type: actions.SET_TABLE_LOADING, tableLoading: false });
			dispatch({ type: actions.SET_TABLE_ERROR, tableError: true });
		});
};

export const init = (): AppThunk => dispatch => {
	dispatch({ type: actions.RESET_STATE });
	dispatch(getChartData());
	dispatch(getTableData());
};

const initialState: {
	tableLoading: boolean;
	tableError: boolean;
	rows: Array<{
		id: number;
		date: string;
		email: string;
		clientId: number;
		ipadress: string;
		master: boolean;
		referrer?: string | null;
		success: boolean;
		user: { id: number; email: string; clienddbUserId: number; name: string };
	}>;
	chartData: { date: string; value: number }[];
} = {
	tableLoading: true,
	tableError: false,
	rows: [],
	chartData: []
};

const ACTION_HANDLERS: { [key: string]: (state: typeof initialState, action: AnyAction) => typeof initialState } = {
	[actions.RESET_STATE]: () => ({ ...initialState }),
	[actions.SET_TABLE_LOADING]: (state, { tableLoading }) => ({ ...state, tableLoading }),
	[actions.SET_ROWS]: (state, { rows }) => ({ ...state, rows }),
	[actions.SET_CHART_DATA]: (state, { chartData }) => ({ ...state, chartData })
};

export default (state = initialState, action: AnyAction) => {
	const handler = ACTION_HANDLERS[action.type];
	return handler ? handler(state, action) : state;
};
