import { Block, ModalContent, ModalHeader, Text, WhiteModal, Icon, Button, Row } from '@upsales/components';
import { getSoftDeployAccessFromState } from 'App/components/hooks/featureHelper';
import { DefaultButton, PrimaryButton } from '@upsales/components/Buttons';
import { ClientIdNamePriceList } from 'App/resources/Model/Client';
import { useTranslation } from 'Components/Helpers/translate';
import { ContactIdName } from 'App/resources/Model/Contact';
import LocalStorage from 'Components/Helpers/LocalStorage';
import { useSoftDeployAccess } from 'App/components/hooks';
import BemClass from '@upsales/components/Utils/bemClass';
import { ModalProps } from 'App/components/Modals/Modals';
import MigrateButton from './MigrateButton/MigrateButton';
import { getSelf } from 'Store/selectors/AppSelectors';
import openModal from 'App/services/Modal';
import { RootState } from 'Store/index';
import React from 'react';

import './SubscriptionPromo.scss';

const openSubscription = (client?: ClientIdNamePriceList, contact?: ContactIdName) => {
	openModal('CreateSubscription', { client, contact });
};

type PromoButtonType = {
	space?: string;
	client?: ClientIdNamePriceList;
	contact?: ContactIdName;
};

export const PromoButton = (props: PromoButtonType) => {
	const hasPromoFlag = useSoftDeployAccess('SUBSCRIPTION_PROMO');
	const hasSubscriptionModal = useSoftDeployAccess('SUBSCRIPTION_MODAL');
	const { t } = useTranslation();

	if (!hasPromoFlag || hasSubscriptionModal) {
		return null;
	}

	const { space, client, contact } = props;

	return (
		<Block space={space}>
			<DefaultButton onClick={() => openSubscription(client, contact)}>
				<Icon name={'gift'} space="mrl" />
				{t('subscription.promo.modal.buttonText')}
			</DefaultButton>
		</Block>
	);
};

export const renderSubscriptionPromo = (
	accountSelf: RootState['App']['accountSelf'],
	client?: ClientIdNamePriceList,
	contact?: ContactIdName
) => {
	const hasPromoFlag = getSoftDeployAccessFromState(accountSelf, 'SUBSCRIPTION_PROMO');
	const hasSubscriptionModal = getSoftDeployAccessFromState(accountSelf, 'SUBSCRIPTION_MODAL');

	if (!hasPromoFlag || hasSubscriptionModal) {
		return;
	}

	const source = contact ? 'contact' : client ? 'account' : 'list';
	const localStorage = new LocalStorage();
	const key = `seen-subscription-promo-from-${source}`;
	const alreadySeen = localStorage.getValue(key);

	if (!alreadySeen) {
		openModal('SubscriptionPromoModal', { client, contact });
		localStorage.setValue(key, true);
	}
};

type Props = ModalProps & {
	client?: ClientIdNamePriceList;
	contact?: ContactIdName;
};

const SubscriptionPromo = (props: Props) => {
	const { client, contact, close, className } = props;
	const classes = new BemClass('SubscriptionPromo', className);
	const { t } = useTranslation();

	const self = getSelf();
	const isAdmin = self?.administrator;

	const lang = {
		header: t('subscription.promo.modal.header'),
		title: t('subscription.promo.modal.title'),
		subtitle: t('subscription.promo.modal.subtitle'),
		descriptionPart1: t('subscription.promo.modal.descriptionPart1'),
		descriptionPart2: t('subscription.promo.modal.descriptionPart2'),
		descriptionPart2Admin: t('subscription.promo.modal.descriptionPart2Admin'),
		descriptionPart3: t('subscription.promo.modal.descriptionPart3'),
		descriptionPart4: t('subscription.promo.modal.descriptionPart4'),
		descriptionPart5: t('subscription.promo.modal.descriptionPart5'),
		buttonText: t('subscription.promo.modal.buttonText'),
		readMore: t('default.readMore')
	};

	const clickLink = () => {
		window.open(
			'https://support.upsales.com/hc/en-us/articles/13598501826193-FAQ-Subscriptions-with-planned-changes-BETA-',
			'_blank'
		);
	};

	return (
		<WhiteModal className={classes.b()} size={'md'}>
			<Block space="ptxl" />
			<ModalHeader onClose={close} alignContent="center" useHeadline title={lang.header} />

			<ModalContent>
				<Block space="mlxl mtxl mrxl mbxl">
					<Block backgroundColor="super-light-blue" space="plxl ptxl pbxl prxl mbxl" borderRadius>
						<Text color="blue" bold size="lg">
							{lang.title}
						</Text>
						<Text color="blue">{lang.subtitle}</Text>
						<img className={classes.elem('info-image').b()} src="img/info-cropped.png" />
					</Block>
					<Block space="pbl">
						<Text>
							{lang.descriptionPart1}
							{isAdmin ? lang.descriptionPart2Admin : lang.descriptionPart2}
							{lang.descriptionPart3}
						</Text>
						<Text>{lang.descriptionPart4}</Text>
					</Block>
					<Block space="ptl pbl">
						<Text>{lang.descriptionPart5}</Text>
					</Block>
					<Block space="ptl">
						<Row align={isAdmin ? 'space-around' : 'left'}>
							<PrimaryButton
								className={classes.elem('button').b()}
								onClick={() => {
									openSubscription(client, contact);
									close();
								}}
							>
								{lang.buttonText}
							</PrimaryButton>
							{isAdmin ? <MigrateButton /> : null}
							<Button color="green" type="link" onClick={clickLink}>
								<Icon name="question-circle" space="mrm" />
								{lang.readMore}
							</Button>
						</Row>
					</Block>
				</Block>
			</ModalContent>
			<img className={classes.elem('image').b()} src="img/lift-off.svg" />
		</WhiteModal>
	);
};

export default SubscriptionPromo;
