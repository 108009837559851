import Tracker from './Tracker';

const userEngagementTracker = new Tracker('userEngagement', {
	CUSTOMER_ACTIVATED: 'customerActivated',
	CUSTOMER_DEACTIVATED: 'customerDeactivated',
	USER_ACTIVATED: 'userActivated',
	USER_DEACTIVATED: 'userDeactivated',
	USER_ACTIVATED_DATATYPE: 'userActivatedDatatype', // should include the user type and data type
	USER_DEACTIVATED_DATATYPE: 'userDeactivatedDatatype', // should include the user type and data type
	EMAIL_SENT: 'emailSent' // should include the email type
});

export default userEngagementTracker;
