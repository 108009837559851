import React from 'react';
import PropTypes from 'prop-types';
import bemClass from '@upsales/components/Utils/bemClass';
import { Button, Icon } from '@upsales/components';
import './EditorHeaderButton.scss';

const EditorHeaderButton = ({
	title,
	supertitle,
	className,
	noIcon,
	next,
	icon = 'chevron-right',
	disabled = false,
	...props
}) => {
	const classes = new bemClass('EditorHeaderButton', className);
	const hasSuper = !!supertitle;
	const color = next ? 'green' : 'grey';
	const type = next ? null : 'link';
	const disabledColor = disabled ? 'light-grey' : null;

	return (
		<Button
			{...props}
			disabled={disabled}
			shadow="none"
			color={disabledColor || color}
			type={type}
			className={classes.mod({ next, 'no-icon': noIcon }).b()}
		>
			{hasSuper ? <div className={classes.elem('super').mod({ disabled }).b()}>{supertitle}</div> : null}
			{hasSuper || next ? <div className={classes.elem('title').mod({ disabled }).b()}>{title}</div> : title}
			{next && !noIcon ? <Icon name={icon} color={disabled ? 'grey-10' : undefined} /> : null}
		</Button>
	);
};

EditorHeaderButton.propTypes = {
	title: PropTypes.string,
	next: PropTypes.bool,
	noIcon: PropTypes.bool
};

export default EditorHeaderButton;
