import Resource from 'Resources/Resource';

type UnreleasedFeatureToggleData = {
	name: string;
	active: boolean;
};

class UnreleasedFeatureCustomer extends Resource {
	constructor() {
		super('master/unreleasedFeatureCustomer');
	}

	toggle(data: UnreleasedFeatureToggleData) {
		return this._postRequest('', data, { methodName: 'toggle' }).then(res => {
			return res.data;
		});
	}
}

const resource = new UnreleasedFeatureCustomer();

export default resource;
