import ResourceTyped from './ResourceTyped';
import { PersistedIndexIncreaseSettings } from 'App/resources/Model/IndexIncreaseSettings';

class IndexIncreaseConfig extends ResourceTyped<PersistedIndexIncreaseSettings> {
	constructor() {
		super('indexIncreaseConfigs', null, { notificationTitle: 'admin.subscriptionIndexing' });
	}

	getAllConflicts(): Promise<PersistedIndexIncreaseSettings[]> {
		return this._getRequest('', {
			methodName: 'getAllConflicts'
		}).then(d => d.data.data);
	}
}

const resource = new IndexIncreaseConfig();

export default resource;
