import {
	Block,
	Button,
	Card,
	Checkbox,
	Flex,
	Icon,
	Label,
	Progressbar,
	Table,
	TableColumn,
	TableHeader,
	TableRow,
	Text,
	Tooltip
} from '@upsales/components';
import { PrimaryButton, ThirdButton } from '@upsales/components/Buttons';
import { useEditProjectPlanContext } from 'App/babel/components/EditProjectPlan/Context';
import Activity from 'App/resources/Model/Activity';
import BemClass from '@upsales/components/Utils/bemClass';
import moment from 'moment';
import React, { useEffect, useMemo, useState } from 'react';
import t from 'Components/Helpers/translate';
import TodoListTableRowQuickActions from 'App/pages/TodoList/TodoListTableRow/TodoListTableRowQuickActions';
import UserStack from 'App/components/UserStack';

import './ProjectTasks.scss';

type Props = {
	close: () => void;
};

type TodoActivity = Activity & {
	type: string;
	hot: boolean;
	weblinkUrl: string | null;
	location: string | null;
};

const ProjectTasks = (props: Props) => {
	const classes = new BemClass('ProjectTasks');

	const [showClosedTasks, setShowClosedTasks] = useState(false);

	const {
		getTasks,
		onToggleTaskCheckbox,
		onToggleTaskPriority,
		openCreateTodoDrawer,
		openEditTodoDrawer,
		state: { projectPlan, tasks, client }
	} = useEditProjectPlanContext();

	const lang = useMemo(
		() => ({
			addTask: t('default.addTask'),
			createFirstTask: t('editProjectPlan.createYourFirstTask'),
			task: t('default.task'),
			dueDate: t('admin.dueDate'),
			user: t('default.user')
		}),
		[]
	);

	useEffect(() => {
		if (projectPlan?.id) {
			getTasks(projectPlan.id);
		}
	}, []);

	const toggleShowClosedTasks = () => {
		setShowClosedTasks(!showClosedTasks);
	};

	const taskTable = (taskType: Activity[], greyBackground: boolean) => {
		return taskType.map(task => (
			<TableRow
				onClick={openEditTodoDrawer.bind(null, task)}
				key={task.id}
				className={classes.elem('completed').mod({ grey: greyBackground }).b()}
			>
				<TableColumn size="lg" className={classes.elem('checkboxRow').b()}>
					<Flex>
						<Checkbox
							checked={!!task.closeDate}
							onClick={event => {
								event.stopPropagation();
								onToggleTaskCheckbox(task);
							}}
							size="sm"
						/>
						<Text space="mll" className={classes.elem('taskTitle').mod({ strike: !!task.closeDate }).b()}>
							{task.description}
						</Text>
					</Flex>
				</TableColumn>
				<TableColumn>{task.date ? moment(task.date).format('YYYY-MM-DD') : ''}</TableColumn>
				<TableColumn>
					<UserStack users={task.users}></UserStack>
				</TableColumn>
				<TableColumn align="center">
					<Flex justifyContent="flex-end">
						<Tooltip title={t(task.priority === 3 ? 'todo.prioTodo' : 'todo.clickToPrioCall')}>
							<Button
								type="link"
								color={task.priority === 3 ? 'red' : 'grey'}
								onClick={event => {
									event.stopPropagation();
									onToggleTaskPriority(task);
								}}
							>
								<Icon name="flag" />
							</Button>
						</Tooltip>
						<TodoListTableRowQuickActions todo={{ ...(task as TodoActivity), type: 'todo' }} />
					</Flex>
				</TableColumn>
			</TableRow>
		));
	};

	const columns = [{ title: lang.task }, { title: lang.dueDate }, { title: lang.user }, { title: '' }];

	const openTasks = tasks.filter(task => !task.closeDate);
	const closedTasks = tasks.filter(task => !!task.closeDate);
	const finishedTasks = tasks.filter(task => !!task.closeDate).length;

	const hasClosedTasks = closedTasks.length > 0;
	const totalNumberOfTasks = tasks.length;

	return (
		<Block className={classes.b()} space="mrl pll ptxl prxl pbl">
			{tasks.length > 0 ? (
				<Flex>
					<Block className={classes.elem('column').b()}>
						<Label>
							{t('projectPlan.tasksCompleted', { completed: finishedTasks, total: totalNumberOfTasks })}
						</Label>
						<Progressbar size="sm" color="green" value={(finishedTasks / totalNumberOfTasks) * 100} />
					</Block>
					{client ? (
						<Block space="mlm">
							<PrimaryButton size="sm" onClick={openCreateTodoDrawer}>
								<Icon space="mrs" name="plus" />
								{lang.addTask}
							</PrimaryButton>
						</Block>
					) : null}
				</Flex>
			) : (
				<Card space="mtxl plxl" color={'light-grey'}>
					<Flex space="pll" justifyContent="space-between" align-items="center">
						<Block space="ptxl">
							<Text space="mtm mbxl" size="xl">
								{lang.createFirstTask}
							</Text>
							<Button onClick={openCreateTodoDrawer}>
								<Icon space="mrm" name="plus" />
								{lang.addTask}
							</Button>
						</Block>
						<Block>
							<img src={'img/upsales-todo.gif'} alt="todo-gif" />
						</Block>
					</Flex>
				</Card>
			)}
			{tasks.length > 0 ? (
				<Card space="mtxl mbxl">
					<Table>
						<TableHeader columns={columns}></TableHeader>
						{taskTable(openTasks, false)}
						{hasClosedTasks ? (
							<TableRow className={classes.elem('completed').mod({ grey: showClosedTasks }).b()}>
								<TableColumn size="lg" colSpan={4} align="center">
									<ThirdButton onClick={toggleShowClosedTasks}>
										{!showClosedTasks
											? t('projectPlan.tasksCompleted.show', {
													total: closedTasks.length
											  })
											: t('projectPlan.tasksCompleted.hide')}
										<Icon
											name={'angle-down'}
											className={classes.elem('icon').mod({ rotate: showClosedTasks }).b()}
										/>
									</ThirdButton>
								</TableColumn>
							</TableRow>
						) : null}
						{showClosedTasks ? taskTable(closedTasks, true) : null}
					</Table>
				</Card>
			) : null}
		</Block>
	);
};

export default ProjectTasks;
