import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import bemClass from '@upsales/components/Utils/bemClass';
import React, { useEffect } from 'react';
import NumLicenses from './NumLicenses';
import CompanyInfo from './CompanyInfo';
import UserInfo from './UserInfo';
import Creating from './Creating';
import { PAGES } from '../../store/reducers/CreateAccount';
import { reset } from '../../store/actions/CreateAccount';

import './CreateAccount.scss';
import { FullscreenModal, ModalContent } from '@upsales/components';

const pages = {
	[PAGES.numLicenses]: NumLicenses,
	[PAGES.companyInfo]: CompanyInfo,
	[PAGES.userInfo]: UserInfo,
	[PAGES.creating]: Creating
};

export const CreateAccount = props => {
	const Page = props.page;
	const classes = new bemClass('CreateAccount', props.className);

	useEffect(() => {
		props.reset();
	}, []);

	return (
		<FullscreenModal className={classes.b()}>
			<ModalContent>
				<Page closeModal={props.close} />
			</ModalContent>
		</FullscreenModal>
	);
};

const mapStateToProps = state => ({
	page: pages[state.CreateAccount.page]
});

const mapDispatchToProps = {
	reset
};

CreateAccount.propTypes = {
	className: PropTypes.string,
	reset: PropTypes.func.isRequired,
	close: PropTypes.func.isRequired,
	page: PropTypes.oneOf(Object.values(pages)).isRequired
};

export default connect(mapStateToProps, mapDispatchToProps)(CreateAccount);
