import React, { useState } from 'react';
import ReportcenterFilter from './ReportcenterFilter';
import { ButtonSelect, Block } from '@upsales/components';
import bemClass from '@upsales/components/Utils/bemClass';
import T from 'Components/Helpers/translate';
import './ReportcenterFilterUser.scss';
import ReportcenterFilterRow from './ReportcenterFilterRow';
import { removeItem } from 'Store/helpers/array';
import { RCDashboardFilter } from 'Resources/ReportDashboard';
import ReportcenterFilterSearchbar from './ReportcenterFilterSearchbar';
import AccessType from 'App/enum/AccessType';

export const renderSelected = (values: RCDashboardFilter, returnCount = true) => {
	let res = `${T('default.users')}: `;
	if (!returnCount) {
		const userNames = [
			{ id: 'null', name: T('default.noUser'), role: null },
			{ id: '{{self}}', name: T('default.loggedInUser') },
			...Tools.AppService.getUsers()
		]
			.filter(user => {
				return (
					values?.User?.value?.includes?.(user.id) ||
					(!user.role && values?.Role?.value?.includes?.('null')) ||
					(user.role && values?.Role?.value?.includes?.(user.role.id))
				);
			})
			.map(user => user.name);
		res += userNames.join(', ');
		return res;
	}

	const totalCount = (values?.User?.value?.length ?? 0) + (values?.Role?.value?.length ?? 0);
	if (totalCount >= 1) {
		res += `${totalCount} ${T('default.selected')}`;
	}
	return res;
};

export const renderSelectedCustom = (values: string[], returnCount = true) => {
	const parsedValues = values.map(v => (isNaN(parseInt(v)) ? v : parseInt(v)));
	let res = `${T('default.users')}: `;
	if (!returnCount) {
		const userNames = [
			{ id: 'null', name: T('default.noUser'), role: null },
			{ id: '{{self}}', name: T('default.loggedInUser') },
			...Tools.AppService.getUsers()
		]
			.filter(user => parsedValues.includes(user.id))
			.map(user => user.name);
		res += userNames.join(', ');
		return res;
	}

	const totalCount = parsedValues.length;
	if (totalCount >= 1) {
		res += `${totalCount} ${T('default.selected')}`;
	}
	return res;
};

const formatTab = (type: 'User' | 'Role', values: RCDashboardFilter, plural: string, singular: string) => {
	if (values?.[type]?.value?.length) {
		return `${values?.[type]?.value?.length} ${T(
			values?.[type]?.value?.length === 1 ? singular : plural
		).toLowerCase()}`;
	}

	return T(plural);
};

export interface Props {
	onChange: (type: keyof RCDashboardFilter, values: Array<number | 'null' | '{{self}}'>, comparison: string) => void;
	values: RCDashboardFilter;
	updateFilterHeight: () => void;
}

const ReportcenterFilterUser = ({ onChange, ...props }: Props) => {
	const classes = new bemClass('ReportcenterFilterUser');
	const [selectedList, setSelectedList] = useState<'User' | 'Role'>('User');
	const [searchStr, setSearchStr] = useState('');
	const users = Tools.AppService.getUsers(AccessType.REPORT, false);
	const roles = Tools.AppService.getRoles(AccessType.REPORT);
	let array: Array<
		Partial<
			Omit<ReturnType<typeof Tools.AppService.getActiveUsers | typeof Tools.AppService.getRoles>[number], 'id'>
		> & { id: number | 'null' | '{{self}}'; name: string }
	> = [];
	if (selectedList === 'User') {
		array = users.filter(item => item.name.toLowerCase().indexOf(searchStr.toLowerCase()) !== -1) as Array<
			Partial<Omit<ReturnType<typeof Tools.AppService.getActiveUsers>[number], 'id'>> & {
				id: number | 'null';
				name: string;
			}
		>;
		array.unshift({ id: 'null', name: T('default.noUser') });
		array.unshift({ id: '{{self}}', name: T('default.loggedInUser') });
	} else {
		array = roles.filter(item => item.name.toLowerCase().indexOf(searchStr.toLowerCase()) !== -1) as Array<
			Partial<Omit<ReturnType<typeof Tools.AppService.getRoles>[number], 'id'>> & {
				id: number | 'null';
				name: string;
			}
		>;
		array.unshift({ id: 'null', name: T('default.noRole') });
	}
	const value = props.values?.[selectedList]?.value || [];
	const comparison = props.values?.[selectedList]?.comparison ?? 'eq';
	const isExclude = comparison === 'ne';
	return (
		<ReportcenterFilter
			className={classes.b()}
			renderSelected={() => renderSelected(props.values)}
			getSelectedNames={() =>
				[
					props.values?.Role?.value?.includes('null') ? T('default.noRole') : null,
					...roles
						.filter(r => props.values?.Role?.value?.includes(r.id))
						.map(r => r.name)
						.concat(users.filter(u => props.values?.User?.value?.includes(u.id)).map(u => u.name))
				].filter(Boolean) as string[]
			}
			icon="user"
			placeholder={`${T('default.users')}: ${T('reportcenter.filter.notActive')}`}
			value={(props.values?.User?.value?.length || props.values?.Role?.value?.length) ?? 0}
			resetFilter={() => {
				onChange('User', [], 'eq');
				onChange('Role', [], 'eq');
			}}
			{...props}
		>
			<Block space="ptm prm pbm plm" backgroundColor="white" className={classes.elem('list-select').b()}>
				<ButtonSelect
					options={[
						{
							value: 'User',
							title: formatTab('User', props.values, 'default.users', 'default.user')
						},
						{
							value: 'Role',
							title: formatTab('Role', props.values, 'default.roles', 'default.role')
						}
					]}
					value={selectedList}
					onChange={tab => {
						setSelectedList(tab);
						setSearchStr('');
					}}
				/>
				<Block space="mtm">
					<ReportcenterFilterSearchbar<Array<number | 'null' | '{{self}}'>>
						setSearchStr={value => setSearchStr(value)}
						searchStr={searchStr}
						placeholder={T(selectedList === 'User' ? 'activity.searchUsers' : 'activity.searchRoles')}
						isExclude={isExclude}
						onChange={onChange}
						field={selectedList}
						value={value}
					/>
				</Block>
			</Block>
			<ReportcenterFilterRow
				key={0}
				onClick={() => {
					onChange(selectedList, [], comparison);
				}}
				selected={!value.length}
				title={T('filters.noFilter')}
			/>
			{array.map(row => (
				<ReportcenterFilterRow
					key={row.id}
					onClick={() => {
						const i = value.indexOf(row.id);
						onChange(selectedList, i !== -1 ? removeItem(value, i) : [...value, row.id], comparison);
					}}
					selected={value.indexOf(row.id) !== -1}
					title={row.name}
				/>
			))}
		</ReportcenterFilter>
	);
};

export default ReportcenterFilterUser;
