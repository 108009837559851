import React from 'react';
import PropTypes from 'prop-types';
import { Button, Icon, OutsideClick } from '@upsales/components';

class DropdownRenderType extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			open: false
		};

		this.makeList = this.makeList.bind(this);
		this.makeButton = this.makeButton.bind(this);
		this.makeOptions = this.makeOptions.bind(this);
		this.onButtonClick = this.onButtonClick.bind(this);
	}

	onButtonClick() {
		const isOpen = this.state.open;

		this.setState({
			open: !isOpen
		});
	}

	makeButton(text) {
		return (
			<Button shadow="none" block={true} type="lined" size="xs" onClick={this.onButtonClick}>
				{text}
				<Icon name="caret-down" style={{ marginLeft: '5px' }} />
			</Button>
		);
	}

	makeList(options) {
		var opts = this.makeOptions(options);

		return (
			<ul
				className="dropdown-menu up-dropdown-white"
				style={{
					display: 'block'
				}}
			>
				{opts}
			</ul>
		);
	}

	makeOptions(options) {
		const { object, getUser, config, runStandardIntegration, runClick } = this.props;

		return options.map(opt => {
			return (
				<li
					key={`options-map-${opt.text}`}
					onClick={event => {
						if (event && event.stopPropagation) {
							event.stopPropagation();
						}

						if (opt.click.type === 'widgetRow') {
							runStandardIntegration({
								type: 'widgetRow',
								data: { object: object, user: getUser() },
								typeId: opt.click.name,
								integrationId: config.integrationId
							});
						} else {
							runClick(opt);
						}
					}}
				>
					<a>{opt.text}</a>
				</li>
			);
		});
	}

	render() {
		const self = this;
		const { row } = this.props;

		return (
			<div className="widget-dropdown-wrapper">
				<div className={this.state.open ? 'btn-group btn-block dropdown open' : 'btn-group btn-block dropdown'}>
					{this.makeButton(row.text)}
					{this.state.open ? (
						<OutsideClick
							targetClass="dropdown-menu"
							outsideClick={function () {
								self.setState({ open: false });
							}}
						>
							{this.makeList(row.options)}
						</OutsideClick>
					) : null}
				</div>
			</div>
		);
	}
}

DropdownRenderType.propTypes = {
	row: PropTypes.object,
	object: PropTypes.object,
	getUser: PropTypes.func,
	config: PropTypes.object,
	runStandardIntegration: PropTypes.func,
	runClick: PropTypes.func
};

export default DropdownRenderType;
