export const RESET = '[CreateAccount] Reset';
export const NEXT_PAGE = '[CreateAccount] Next page';
export const SET_LICENSES = '[CreateAccount] Set licenses';
export const SET_FIELD_VALUE = '[CreateAccount] Set field value';
export const SET_LOADING = '[CreateAccount] Set loading';
export const SET_ERROR = '[CreateAccount] Set error';

export const PAGES = {
	numLicenses: 'numLicenses',
	companyInfo: 'companyInfo',
	userInfo: 'userInfo',
	creating: 'creating'
};

const pageOrder = [PAGES.numLicenses, PAGES.companyInfo, PAGES.userInfo, PAGES.creating];

export const initialState = {
	page: pageOrder[0],
	numLicenses: 1,
	companyOrgNo: '',
	companyName: '',
	companyAddress: '',
	companyZipCode: '',
	companyDeliveryType: 'E-post',
	companyCity: '',
	companyEmail: '',
	termsAccepted: false,
	confirmAuthorized: false,
	name: '',
	email: '',
	password: '',
	repeatPassword: '',
	loading: false,
	error: '',
	errorField: null
};

const ACTION_HANDLERS = {
	[RESET]: () => ({ ...initialState }),
	[NEXT_PAGE]: state => ({ ...state, page: pageOrder[pageOrder.indexOf(state.page) + 1] }),
	[SET_LICENSES]: (state, { numLicenses }) => ({ ...state, numLicenses: numLicenses || 1 }),
	[SET_FIELD_VALUE]: (state, { values }) => ({ ...state, ...values }),
	[SET_LOADING]: (state, { value }) => ({ ...state, loading: value }),
	[SET_ERROR]: (state, { error, field }) => ({ ...state, error: error, errorField: field })
};

export default (state = { ...initialState }, action) => {
	const handler = ACTION_HANDLERS[action.type];
	return handler ? handler(state, action) : state;
};
