import React, { FC, useMemo, useState, Fragment, ChangeEvent } from 'react';
import {
	Block,
	Label,
	Modal,
	ModalContent,
	ModalHeader,
	Input,
	ModalControls,
	Row,
	Column,
	Button,
	Toggle,
	ColorSelect,
	ButtonSelect
} from '@upsales/components';
import BemClass from '@upsales/components/Utils/bemClass';
import { cloneDeep } from 'lodash';

import './EditDynamicLink.scss';
import 'Components/Modals/ModalTagListStyles.scss';

import FormObserver, { FieldModel } from '../FormObserver';
import LinksResource from 'App/resources/Links';
import _Link from 'App/resources/Model/Links';
import T from 'Components/Helpers/translate';
import InlineAction from 'Components/Dialogs/InlineAction/InlineAction';
import getIcons, { DynamicLinkIcon } from './getIcons';
import UpSelect from 'Components/Inputs/UpSelect';
import ModalTagList from 'Components/Modals/ModalTagList';
import { useAppDispatch, useSelector } from '../hooks';
import { setSidebarLinks } from 'Store/actions/AppActions';
import { replaceItem, removeItem } from 'Store/helpers/array';

const entities = ['client', 'contact', 'appointment', 'activity', 'order', 'sidebar'];

interface Link extends Omit<_Link, 'id'> {
	id?: number;
}

interface Props {
	className: string;
	link?: Link;
	close: (link?: Link) => void;
}
const EditDynamicLink: FC<Props> = ({ className, link: _link, close }) => {
	const [showTagList, setShowTagList] = useState(false);
	const [showInlineAction, setShowInlineAction] = useState<'top' | 'bottom' | null>(null);

	const [link, setLink] = useState<Link>(_link ?? LinksResource.new());
	const [saving, setSaving] = useState(false);
	const dispatch = useAppDispatch();
	const { sidebarLinks } = useSelector(({ App }) => ({
		sidebarLinks: App.sidebarLinks
	}));

	const classes = useMemo(
		() => new BemClass('EditDynamicLink', className).mod({ showTagList }),
		[className, showTagList]
	);
	const ModalTagListStyles = useMemo(() => new BemClass('ModalTagListStyles'), [showTagList]);

	const lang = useMemo(
		() => ({
			addHeader: T('automationAction.SendWebhook.addHeader'),
			availableTags: T('tag.available'),
			body: 'Body', // Don't translate technical words
			cancel: T('cancel'),
			contentType: 'Content-Type', // Same here
			encoding: 'Encoding', // And here
			hideTags: T('todo.hideTags'),
			headers: 'Headers', // ...
			headerKey: 'Header key', // ...
			headerValue: 'Header value', // ...
			method: 'Method', // ...
			noHeaders: T('automationAction.SendWebhook.noHeaders'),
			saveAction: T('automationAction.SaveAction'),
			url: 'URL' // ...
		}),
		[]
	);

	const save = async () => {
		setSaving(true);
		const _link = cloneDeep(link);

		if (_link.color.startsWith('#')) {
			_link.color = _link.color.replace('#', '');
		}

		if (_link.icon?.length) {
			const icon = getIcons().find(i => i.text === _link.icon);
			_link.icon = icon?.id ?? '';
		}

		LinksResource.save(_link)
			.then(res => {
				const i = sidebarLinks.findIndex(l => l.id === res.data.id);
				if (res.data.entity === 'sidebar') {
					// Set the state of sidebar links to keep the sidebar updated
					if (i !== -1) {
						dispatch(setSidebarLinks(replaceItem(sidebarLinks, i, res.data)));
					} else {
						dispatch(setSidebarLinks([...sidebarLinks, res.data]));
					}
				} else if (i !== -1) {
					// If we get here, then the updated link entity has been changed from 'sidebar' to something else
					dispatch(setSidebarLinks(removeItem(sidebarLinks, i)));
				}
				close(res.data);
			})
			.catch(err => {
				setSaving(false);
			});
	};

	const onClose = (showInlineAction: 'top' | 'bottom', dirty: boolean) => {
		if (!dirty) {
			close();
			return;
		}
		setShowInlineAction(showInlineAction);
	};

	const rejectChanges = () => {
		setShowInlineAction(null);
		close();
	};

	return (
		<Modal className={classes.b()} size="lg">
			<div className={classes.elem('content').b()}>
				<FormObserver<Link>
					onSubmit={save}
					model={{
						entity: FieldModel.string('default.entity'),
						value: FieldModel.string('default.value')
							.required(T('validation.missingRequiredFields', { field: T('default.title') }))
							.max(255),
						href: FieldModel.string('default.href')
							.required(T('validation.missingRequiredFields', { field: T('admin.linkHref') }))
							.max(255),
						target: FieldModel.string('default.target'),
						tooltip: FieldModel.string('default.tooltip'),
						icon: FieldModel.string('default.icon'),
						color: FieldModel.string('default.color')
					}}
					initialValues={link}
					onChange={newLink => setLink(newLink)}
					validateOnMount
				>
					{({ onFormChange, dirty, values, inputProps, submit }) => (
						<Fragment>
							<ModalHeader
								title={`${T(link.id ? 'default.edit' : 'default.create')} ${T(
									'default.link'
								).toLowerCase()}`}
								icon={link.id ? 'edit' : 'plus'}
								onClose={() => onClose('top', dirty)}
							>
								<Button
									onClick={() => setShowTagList(s => !s)}
									type="lined"
									text={showTagList ? lang.hideTags : lang.availableTags}
									data-testid="toggle-tags"
								/>
								{showInlineAction === 'top' ? (
									<InlineAction
										toggleInlineAction={() => setShowInlineAction(null)}
										onReject={close}
										onConfirm={submit}
										showTop
										data-testid="inline-top"
									/>
								) : null}
							</ModalHeader>
							<ModalContent>
								<Block space="mbxl">
									<Column>
										<Label>{T('admin.linkEntity')}</Label>
										<ButtonSelect
											options={entities.map(entity => ({
												title: T(`default.${entity}`),
												value: entity
											}))}
											onChange={entity => {
												onFormChange('entity', entity);
											}}
											disabled={saving}
											value={inputProps.entity.value}
										/>
									</Column>
								</Block>
								<Block space="mbxl">
									<Row>
										<Column>
											<Label required value={inputProps.value.value} maxLength={255}>
												{T('default.title')}
											</Label>
											<Input {...inputProps.value} disabled={saving} />
										</Column>
										<Column className={classes.elem('spacer').b()} />
										<Column>
											<Label required value={inputProps.href.value} maxLength={255}>
												{T('admin.linkHref')}
											</Label>
											<Input {...inputProps.href} disabled={saving} />
										</Column>
									</Row>
								</Block>
								<Block space="mbxl">
									<Row className={classes.elem('toggle-wrapper').b()}>
										<Label>{T('admin.openLinkInNewWin')}</Label>
										<Toggle
											className={classes.elem('toggle').b()}
											checked={inputProps.target.value === '_blank'}
											onChange={checked => onFormChange('target', checked ? '_blank' : '_self')}
										/>
									</Row>
								</Block>
								<Block space="mbxl mtxl">
									<Row>
										<Column>
											<Label>{T('admin.icon')}</Label>
											<UpSelect
												state={inputProps.icon.state}
												name={inputProps.icon.name}
												placeholder={T('admin.linkIcon')}
												required
												data={getIcons()}
												defaultValue={inputProps.icon.value}
												dropdownCssClass="icon-select"
												onChange={(e: ChangeEvent<HTMLInputElement>) => {
													onFormChange('icon', e.target.value);
												}}
												formatResult={(
													icon: DynamicLinkIcon,
													container: any,
													query: any,
													escape: (q: string) => string
												) => '<b class="fa ' + escape(icon.text) + '"></b>'}
												formatSelection={(
													icon: DynamicLinkIcon,
													container: any,
													escape: (q: string) => string
												) =>
													'<b class="fa ' + escape(icon.text) + '"></b> ' + escape(icon.text)
												}
												matcher={(trm: string, icon: string) =>
													icon.substring(3).indexOf(trm) !== -1
												}
												disabled={saving}
												data-testid="EditDynamicLink-UpSelect"
											/>
											<br />
											<span>{T('admin.weUseIconsFrom')}</span>{' '}
											<a href="http://fontawesome.io/" target="_blank">
												FontAwesome
											</a>
										</Column>
										<Column className={classes.elem('spacer').b()} />
										<Column>
											<Label>{T('mail.color')}</Label>
											<ColorSelect
												{...inputProps.color}
												onChange={color =>
													onFormChange('color', color ? color.replace('#', '') : '')
												}
												color={`#${inputProps.color.value}`}
											/>
										</Column>
									</Row>
								</Block>
							</ModalContent>
							<ModalControls>
								<Row>
									<Column align="right">
										<Button submit loading={saving} data-testid="EditDynamicLink-save-btn">
											{T('default.save')}
										</Button>
										<Button
											color="grey"
											type="link"
											onClick={() => onClose('bottom', dirty)}
											data-testid="EditDynamicLink-abort-btn"
										>
											{T('default.abort')}
										</Button>
										{showInlineAction === 'bottom' ? (
											<InlineAction
												toggleInlineAction={() => setShowInlineAction(null)}
												onReject={rejectChanges}
												onConfirm={submit}
												data-testid="inline-bottom"
											/>
										) : null}
									</Column>
								</Row>
							</ModalControls>
						</Fragment>
					)}
				</FormObserver>
			</div>
			<div className={ModalTagListStyles.mod({ translateTagsList: showTagList }).b()}>
				<ModalTagList
					excludedTags={link.entity === 'client' ? ['Client.Www'] : []}
					entity={link.entity}
					onClose={() => setShowTagList(s => !s)}
				/>
			</div>
		</Modal>
	);
};

export default EditDynamicLink;
