import Attributes from 'Attributes/ActivityAttributes';
import ResourceTyped from './ResourceTyped';
import Activity, { ActivityOnlyRequiredForSave } from 'App/resources/Model/Activity';
import moment from 'moment';

// Need this in the old resource as well :facepalm:
export const analyticsProps = (
	opts: { skipNotification: boolean },
	type: 'added' | 'updated' | 'deleted',
	res: { data: Activity }
) => {
	const todoTypes = Tools.AppService.getTodoTypes();

	// This is not exact, but will do
	const closedActivity = moment().subtract(10, 's').isBefore(res.data.closeDate);

	if (res.data.activityType.id === todoTypes.PHONE_CALL.id) {
		return { type: 'Phone call', closedActivity };
	} else if (res.data.activityType.id === todoTypes.TODO.id) {
		return { type: 'Todo', closedActivity };
	} else {
		return { type: 'Activity', closedActivity };
	}
};

class ActivityResource extends ResourceTyped<Activity, Activity, ActivityOnlyRequiredForSave> {
	eventName: string;

	constructor() {
		super('activities', Attributes);

		this.eventName = 'activity';

		this.notifications = {
			save: () => ({
				title: 'default.saved',
				body: 'saved.activity',
				style: 'success',
				icon: 'check',
				type: 'body'
			}),
			saveError: () => ({
				title: 'default.error',
				body: 'saveError.activity',
				style: 'error',
				icon: 'times',
				type: 'body'
			}),
			delete: () => ({
				title: 'default.deleted',
				body: 'deleted.activity',
				style: 'success',
				icon: 'check',
				type: 'body'
			}),
			deleteError: () => ({
				title: 'default.error',
				body: 'deleteError.activity',
				style: 'error',
				icon: 'times',
				type: 'body'
			})
		};
	}

	analyticsProps = analyticsProps;
}

const resource = new ActivityResource();

export default resource;
