// This reducer could be refactored away and logic moved to component. App state already has brands.
import { findAndReplaceOrInsertItem, replaceItem } from 'Store/helpers/array';
import BrandResource from '../../resources/Brand';
import sortAlphabetically from '../../utils/sortAlphabetically';
import { setBrands } from 'Store/actions/AppActions';

export const initialState = {
	brands: [],
	sort: { field: 'name', asc: true },
	edit: null,
	saving: false,
	loading: true
};

const actionPrefix = '[AdminEditBrands]';
export const actions = {
	SORT: `${actionPrefix} SORT`,
	INIT: `${actionPrefix} INIT`,
	SET_BRAND: `${actionPrefix} SET_BRAND`,
	SET_BRANDS: `${actionPrefix} SET_BRANDS`,
	SAVE_BRANDS: `${actionPrefix} SAVE_BRANDS`,
	START_EDIT_BRAND: `${actionPrefix} START_EDIT_BRAND`,
	STOP_EDIT_BRAND: `${actionPrefix} STOP_EDIT_BRAND`,
	CHANGE_EDIT_PROP: `${actionPrefix} CHANGE_EDIT_PROP`,
	SET_SAVING: `${actionPrefix} SET_SAVING`,
	SET_LOADING: `${actionPrefix} SET_LOADING`
};

export const reducer = (state = initialState, action) => {
	switch (action.type) {
		case actions.INIT:
			return { ...initialState, ...action.data };
		case actions.SORT:
		case actions.SET_BRANDS:
		case actions.START_EDIT_BRAND:
		case actions.SET_SAVING:
		case actions.SET_LOADING:
			return { ...state, ...action.data };
		case actions.STOP_EDIT_BRAND:
			return { ...state, edit: null };
		case actions.CHANGE_EDIT_PROP: {
			const { prop, value } = action.data;
			const edit = state.edit;
			return { ...state, edit: { ...edit, [prop]: value } };
		}
		case actions.SET_BRAND: {
			const brand = action.data.brand;
			const brands = [...state.brands];
			const index = _.findIndex(brands, { id: brand.id });

			if (index > -1) {
				brands[index] = brand;
			} else {
				brands.unshift(brand); // When saving a new brand
			}
			return { ...state, brands };
		}
		default:
			return state;
	}
};

export const newBrand = () => BrandResource.new();

export const init = () => async dispatch => {
	dispatch({ type: actions.SET_LOADING, data: { loading: true } });

	try {
		const { data: brands } = await BrandResource.find();
		const sortedBrands = brands.sort(sortAlphabetically(initialState.sort.field, !initialState.sort.asc));
		dispatch({ type: actions.INIT, data: { brands: sortedBrands, loading: false } });
		dispatch(setBrands(brands));
	} catch (error) {
		console.log(error);
		dispatch({ type: actions.INIT, data: { loading: false } });
	}
};

export const doSort = sort => (dispatch, getState) => {
	const brands = getState().AdminBrand.brands;
	const sortedbrands = brands.slice().sort(sortAlphabetically(sort.field, !sort.asc));
	dispatch({ type: actions.SORT, data: { sort, brands: sortedbrands } });
};

export const setActive = (value, brand) => async (dispatch, getState) => {
	const updatedBrand = { ...brand, active: value };

	try {
		const { data: brand } = await BrandResource.save(updatedBrand);

		dispatch({ type: actions.SET_BRAND, data: { brand } });
		const brands = getState().App.brands;
		const index = brands.findIndex(b => b.id === brand.id);
		dispatch(setBrands(replaceItem(brands, index, brand)));
	} catch (error) {
		console.log(error);
	}
};

export const startEditBrand = brand => {
	return { type: actions.START_EDIT_BRAND, data: { edit: brand } };
};

export const stopEditBrand = () => {
	return { type: actions.STOP_EDIT_BRAND };
};

export const changeEditProp = (prop, value) => {
	return { type: actions.CHANGE_EDIT_PROP, data: { prop, value } };
};

export const saveBrand = () => async (dispatch, getState) => {
	const edit = getState().AdminBrand.edit;
	dispatch({ type: actions.SET_SAVING, data: { saving: true } });

	try {
		const { data: brand } = await BrandResource.save(edit);
		dispatch({ type: actions.SET_BRAND, data: { brand } });
		const brands = getState().App.brands;
		dispatch(setBrands(findAndReplaceOrInsertItem(brands, brand, item => item.id === brand.id)));
	} catch (error) {
		console.log(error);
	}

	dispatch({ type: actions.SET_SAVING, data: { saving: false } });
	dispatch({ type: actions.STOP_EDIT_BRAND });
};

export default reducer;
