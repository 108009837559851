import { ChildAgreement } from 'App/components/EditSubscription/Context/SubscriptionGroupState';
import { sdf } from 'App/components/EditSubscription/Context/SubscriptionPeriodContextHelpers';
import BemClass from '@upsales/components/Utils/bemClass';
import React, { Dispatch, SetStateAction } from 'react';
import { DefaultButton } from '@upsales/components/Buttons';
import T from 'Components/Helpers/translate';
import SplitDateRow from '../SplitDateRow';
import { Icon } from '@upsales/components';
import moment from 'moment';

import './SplitDateRows.scss';

type Props = {
	startDate: Date;
	endDate: Date;
	dateRows: Date[];
	hide: boolean;
	renderBottomPart: () => JSX.Element;
	setDateRows: Dispatch<SetStateAction<Date[]>>;
	currentChildren?: ChildAgreement[];
};

const SplitDateRows = ({
	startDate,
	endDate,
	dateRows,
	hide,
	setDateRows,
	renderBottomPart,
	currentChildren
}: Props) => {
	const classes = new BemClass('SplitDateRows');

	const updateDate = (value: Date, from: boolean, index: number) => {
		setDateRows(prev => {
			const i = from ? index : index + 1;
			prev[i] = moment(moment(value).format(sdf)).toDate();
			return [...prev];
		});
	};
	const addRow = () => {
		setDateRows(prev => {
			if (moment(prev[prev.length - 1]).isSame(endDate, 'day')) {
				prev[prev.length - 1] = moment(prev[prev.length - 2] ?? startDate)
					.add(1, 'day')
					.toDate();
			}
			return [...prev, endDate];
		});
	};

	return (
		// Every SplitDateRow contains two dates, but the endDate is the same as the startDate for the next row
		<div className={classes.mod({ hide }).b()}>
			{dateRows.map((date, i, allDates) => {
				if (i === allDates.length - 1) return null;

				const nextDate = allDates[i + 1];
				const prevDate = allDates[i - 1];
				const isLast = i === allDates.length - 2;
				const disableIfOnlyOneRow = allDates.length <= 2;

				return (
					<SplitDateRow
						key={i}
						isFirst={i === 0}
						isLast={isLast}
						disableRemove={disableIfOnlyOneRow}
						fromDate={date}
						toDate={nextDate ?? endDate}
						previousDate={prevDate ?? startDate}
						endDate={endDate}
						fromOnChange={value => updateDate(value, true, i)}
						toOnChange={value => updateDate(value, false, i)}
						currentChildren={currentChildren}
						removeRow={() =>
							setDateRows(prev => {
								prev.splice(i, 1);
								return [...prev];
							})
						}
					/>
				);
			})}
			{!currentChildren?.length ? (
				<DefaultButton onClick={addRow} className={classes.elem('add-row-button').b()}>
					<Icon name="plus" />
					{T('subscription.modal.summary.splitPage.planAnotherChange')}
				</DefaultButton>
			) : null}
			{renderBottomPart()}
		</div>
	);
};

export default SplitDateRows;
