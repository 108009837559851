import React, { ReactNode } from 'react';
import { TIME_LINE_EVENT_TYPES, EVENT_TIMELINE_ENTITY_TYPES, ACTIVITY_TIMELINE } from 'App/babel/enum/timeline';
import HistoryLog from '../HistoryLog';
import ActivityEvents, { ActivityEvent } from 'App/babel/resources/ActivityEvents';
import ActivityTimelineCreated from './ActivityTimelineCreated';
import ActivityTimelinePostpone from './ActivityTimelinePostpone';
import ActivityTimelineSendMail from './ActivityTimelineSendMail';
import ActivityTimelineStopTrying from './ActivityTimelineStopTrying';
import ActivityTimelineBookAppointment from './ActivityTimelineBookAppointment';
import ActivityTimelineCreateOrder from './ActivityTimelineCreateOrder';
import ActivityTimelineUserChanged from './ActivityTimelineUserChanged';
import ActivityTimelineChangeActivityType from './ActivityTimelineChangeActivityType';
import ActivityTimelineChangedContact from './ActivityTimelineChangedContact';
import ActivityTimelineChangeDescription from './ActivityTimelineChangeDescription';
import ActivityTimelinePhonecall from './ActivityTimelinePhonecall';
import ActivityTimelinePlanPhonecall from './ActivityTimelinePlanPhonecall';
import PriorHistoryLog from '../PriorHistoryLog';
import T from 'Components/Helpers/translate';
import Activity from 'App/resources/Model/Activity';
import usePartialLoader from 'App/components/hooks/usePartialLoader';
import Event from 'App/resources/Model/Event';
import TimelineLoadMore from 'Components/TimelineRow/TimelineLoadMore/TimelineLoadMore';
import TimelineGeneric from 'Components/TimelineRow/TimelineGeneric';
import { FetcherFunction } from 'App/components/hooks/useGetData';

const listeners = ['activity.updated', 'comment.added', 'comment.deleted'] as const;

const fetcher: FetcherFunction<{ activity: Props['activity'] }, ActivityEvent> = (filters, { activity }) => {
	return ActivityEvents.getEvents(activity.id, filters.limit, filters.offset);
};

type Props = {
	activity: Activity;
	showLeading: boolean;
	selectButtons: ReactNode;
};

const getActivityTimeLine = (event: Event, activity: Props['activity']) => {
	const isPhoneCall = activity?.activityType?.id === Tools.AppService.getTodoTypes().PHONE_CALL.id;
	const { subType } = event || {};

	switch (subType) {
		case ACTIVITY_TIMELINE.CREATED:
			return (
				<ActivityTimelineCreated
					event={{ ...event, source: activity?.source, isPhoneCall: isPhoneCall }}
					hideIcon={false}
				/>
			);
		case ACTIVITY_TIMELINE.REACHED_POSTPONED:
		case ACTIVITY_TIMELINE.NOT_REACHED_POSTPONED:
		case ACTIVITY_TIMELINE.POSTPONED:
		case ACTIVITY_TIMELINE.SET_DATE:
		case ACTIVITY_TIMELINE.NOT_FOUND_LEAD_POSTPONED:
		case ACTIVITY_TIMELINE.REACHED_NO_THANKS_POSTPONE:
		case ACTIVITY_TIMELINE.DATE_CHANGED:
			return <ActivityTimelinePostpone event={event} />;
		case ACTIVITY_TIMELINE.REACHED_STOP_TRYING:
		case ACTIVITY_TIMELINE.NOT_REACHED_STOP_TRYING:
		case ACTIVITY_TIMELINE.NOT_FOUND_LEAD_STOP_TRYING:
		case ACTIVITY_TIMELINE.REACHED_NO_THANKS_STOP_TRYING:
		case ACTIVITY_TIMELINE.CLOSED_ACTIVITY:
			return <ActivityTimelineStopTrying event={{ ...event, isPhoneCall: isPhoneCall }} hideIcon={false} />;
		case ACTIVITY_TIMELINE.REACHED_SEND_MAIL:
		case ACTIVITY_TIMELINE.NOT_REACHED_SEND_MAIL:
			return <ActivityTimelineSendMail event={event} hideIcon={false} />;
		case ACTIVITY_TIMELINE.REACHED_BOOK_APPOINTMENT:
			return <ActivityTimelineBookAppointment event={event} hideIcon={false} />;
		case ACTIVITY_TIMELINE.REACHED_CREATE_OPPORTUNITY:
		case ACTIVITY_TIMELINE.REACHED_CREATE_ORDER:
			return <ActivityTimelineCreateOrder event={event} hideIcon={false} />;
		case ACTIVITY_TIMELINE.REACHED_PLAN_PHONECALL:
			return <ActivityTimelinePlanPhonecall event={event} hideIcon={false} />;
		case ACTIVITY_TIMELINE.USER_CHANGED:
			if (event.data?.user?.id && event.data.user.id === event.data?.oldUser?.id) {
				return null;
			}
			return <ActivityTimelineUserChanged event={event} hideIcon={false} />;
		case ACTIVITY_TIMELINE.ACTIVITY_TYPE_CHANGED:
			return <ActivityTimelineChangeActivityType event={event} hideIcon={false} />;
		case ACTIVITY_TIMELINE.REACHED_TALK_TO_SOMEONE:
		case ACTIVITY_TIMELINE.FOUND_LEAD_ADDED_CONTACT:
		case ACTIVITY_TIMELINE.CONTACT_CHANGED:
			return <ActivityTimelineChangedContact event={event} hideIcon={false} />;
		case ACTIVITY_TIMELINE.DESCRIPTION_CHANGED:
			return <ActivityTimelineChangeDescription event={event} hideIcon={false} />;
		case ACTIVITY_TIMELINE.PHONECALL:
			return <ActivityTimelinePhonecall event={event} hideIcon={false} />;
		default:
			return null;
	}
};

export const ActivityHistoryLog = (props: Props) => {
	const { activity, showLeading } = props;

	if (!activity || !activity.id) {
		return null;
	}
	const fetchOnChanges = {
		'activity.updated': ['description', 'contacts', 'outcome']
	};
	const {
		data: events,
		loading: isEventsLoading,
		total: totalEvents,
		loadMore
	} = usePartialLoader({
		fetcher,
		fetcherProps: { activity },
		broadcastTypes: listeners,
		fetchOnChanges
	});

	const todoType = Tools.AppService.getTodoTypes();
	const priorHistoryLogType = activity?.activityType?.id === todoType.PHONE_CALL.id ? 'phonecall' : '';

	const timelineElements = events.map((event, index) => {
		const { data } = event || {};
		const { type, outcome } = data || {};
		const subType = event.type === ACTIVITY_TIMELINE.OUTCOME_CHANGED ? `${type}_${outcome}` : event.type;
		const activityEvent = {
			...event,
			entityType: TIME_LINE_EVENT_TYPES.ACTIVITY,
			subType
		};

		const activityTimeLine = getActivityTimeLine(activityEvent, activity);
		if (!activityTimeLine) {
			return null;
		}

		return (
			<TimelineGeneric
				key={index}
				event={activityEvent}
				bypassComponent={activityTimeLine}
				hideLine={subType === ACTIVITY_TIMELINE.CREATED}
			/>
		);
	});

	let regDateLine = null;

	if (!timelineElements.length) {
		const activityEvent = {
			entityType: EVENT_TIMELINE_ENTITY_TYPES.ACTIVITY,
			subType: ACTIVITY_TIMELINE.CREATED,
			entityId: activity.id,
			user: Array.isArray(activity.users) ? activity.users[0] : activity.users,
			users: activity.users,
			data: {
				regDate: activity.regDate
			}
		};
		regDateLine = (
			<TimelineGeneric
				event={activityEvent}
				bypassComponent={getActivityTimeLine(activityEvent, activity)}
				hideLine
			/>
		);
	} else {
		timelineElements.push(timelineElements.shift() ?? null);
	}

	return (
		<HistoryLog
			title={T('history')}
			hasPriorHistory={showLeading}
			loading={isEventsLoading}
			selectButtons={props.selectButtons}
		>
			{timelineElements}
			{events && events.length < totalEvents ? (
				<TimelineLoadMore
					newEventsLoading={isEventsLoading}
					numEventsLoaded={events.length}
					totalEvents={totalEvents}
					incrementOffset={loadMore}
				/>
			) : null}
			{regDateLine}
			{showLeading ? <PriorHistoryLog entity={activity} type={priorHistoryLogType} /> : null}
		</HistoryLog>
	);
};

export default ActivityHistoryLog;
