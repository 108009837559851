import Resource from 'Resources/Resource';

class ProjectPlanPriorityResource extends Resource {
	constructor() {
		super('ProjectPlanPriority');
	}
}

const resource = new ProjectPlanPriorityResource();

export default resource;
