import './LostOpportunityModal.scss';

import React from 'react';

import { Modal } from '@upsales/components';
import BemClass from '@upsales/components/Utils/bemClass';
import LostOpportunity, { Props } from './LostOpportunity';

export default (props: Props) => {
	const classes = new BemClass('LostOpportunityModal', props.className);
	return (
		<Modal className={classes.b()}>
			<LostOpportunity visible={true} isModal={true} {...props}></LostOpportunity>
		</Modal>
	);
};
