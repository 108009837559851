import { OUTCOME_TYPES as PHONECALL_OUTCOME } from 'App/babel/enum/activityOutcome';
import { outcomeTypes as APPOINTMENT_OUTCOME } from 'Components/Modals/Appointment/AppointmentOutcomes/Helpers';

import type { AnyAction } from 'redux';
import type Todo from 'App/resources/Model/Todo';
import type Role from 'App/resources/Model/Role';

export const RESET = '[Todo] RESET';
export const SET_TABLE_DATA = '[Todo] SET_TABLE_DATA';
export const SET_VIEWS = '[Todo] SET_VIEWS';
export const SET_VIEW_STATS = '[Todo] SET_VIEW_STATS';
export const SET_FILTERS = '[Todo] SET_FILTERS';
export const SET_SELECTED_VIEW = '[Todo] SET_SELECTED_VIEW';
export const SET_OFFSET = '[Todo] SET_OFFSET';
export const SET_REPORT_STATS = '[Todo] SET_REPORT_STATS';
export const SET_SELECTED_ID = '[Todo] SET_SELECTED_ID';
export const SET_TOP_LIST = '[Todo] SET_TOP_LIST';
export const SET_SELECTED_ROLES_ACTIVITY = '[Todo] SET_SELECTED_ROLES_ACTIVITY';
export const SET_SELECTED_ROLES_APPOINTMENT = '[Todo] SET_SELECTED_ROLES_APPOINTMENT';
export const SET_FOCUS_DATA = '[Todo] SET_FOCUS_DATA';
export const SET_MULTI_SELECT = '[Todo] SET_MULTI_SELECT';
export const SET_DAYS_TO_HIGHLIGHT = '[Todo] SET_DAYS_TO_HIGHLIGHT';

export type ViewType = 'all' | 'appointments' | 'todos' | 'phonecalls';
type ViewTimeModifier = 'Today' | 'Tomorrow' | 'Late' | 'CompletedToday' | 'Week';
type ViewGroup =
	| 'all'
	| 'today'
	| 'tomorrow'
	| 'week'
	| 'nextWeek'
	| 'leads'
	| 'late'
	| 'noDate'
	| 'completedToday'
	| 'custom';

export type ViewName =
	| `${ViewType}${ViewTimeModifier}`
	| 'allLateYesterday'
	| 'allLeads'
	| 'coldCallLeads'
	| 'hotLeads'
	| `custom-${number}`
	| `custom-all-${number}`;

export type TodoListView = {
	name: ViewName;
	group: ViewGroup;
	type: ViewType;
};

export type CallListView = {
	isShared: boolean;
	id: number;
} & TodoListView;

export type TodoReportStats = {
	ordersCreated: number;
	appointmentsCreated: number;
	opportunitiesCreated: number;
	todosDone: number;
	phoneCallsDone: { [PHONECALL_OUTCOME.NO_ANSWER]: number; [PHONECALL_OUTCOME.ANSWER]: number; total: number };
	appointmentsDone: { [k in (typeof APPOINTMENT_OUTCOME)[keyof typeof APPOINTMENT_OUTCOME]]: number } & {
		total: number;
	};
	topListData: {
		[k: string]: {
			key: number;
			// eslint-disable-next-line camelcase
			doc_count: number;
		};
	};
};

export type TodoMultiSelect = {
	available: boolean;
	enabled: boolean;
	allSelected: boolean;
	selectedIds: { [k: Todo['id']]: boolean };
	total?: number;
};

export type TodoState = Readonly<{
	tableLoading: boolean;
	viewsLoading: boolean;
	viewStatsLoading: boolean;
	tableData: Todo[];
	tableTotal: number;
	selectedView: ViewName;
	views: {
		[k in Exclude<ViewGroup, 'custom'>]?: TodoListView[];
	} & {
		custom?: CallListView[];
	};
	viewMap: { [k in ViewName]?: TodoListView | CallListView };
	viewStats: { [k in ViewName]?: number };
	filters: {
		prio: boolean;
		searchString: string;
	};
	offset: number;
	reportLoading: boolean;
	reportStats: TodoReportStats;
	selectedRolesActivity: Role[];
	selectedRolesAppointment: Role[];
	selectedId: number | null;
	focus: {
		data: Todo[];
		loading: boolean;
	};
	multiselect: TodoMultiSelect;
	daysToHighlight: string;
}>;

export const initialState: TodoState = {
	tableLoading: true,
	viewsLoading: true,
	viewStatsLoading: true,
	tableData: [],
	tableTotal: 0,
	selectedView: 'allToday',
	views: {},
	viewMap: {},
	viewStats: {},
	filters: {
		prio: false,
		searchString: ''
	},
	offset: 0,
	reportLoading: true,
	reportStats: {
		ordersCreated: 0,
		appointmentsCreated: 0,
		opportunitiesCreated: 0,
		todosDone: 0,
		phoneCallsDone: { [PHONECALL_OUTCOME.NO_ANSWER]: 0, [PHONECALL_OUTCOME.ANSWER]: 0, total: 0 },
		appointmentsDone: {
			[APPOINTMENT_OUTCOME.COMPLETED]: 0,
			[APPOINTMENT_OUTCOME.NOTCOMPLETED]: 0,
			[APPOINTMENT_OUTCOME.PLANNED]: 0,
			total: 0
		},
		topListData: {}
	},
	selectedRolesActivity: [],
	selectedRolesAppointment: [],
	selectedId: null,
	focus: {
		data: [],
		loading: true
	},
	multiselect: {
		available: false,
		enabled: false,
		allSelected: false,
		selectedIds: {}
	},
	daysToHighlight: ''
};

const getMultiselectedTotal = (multiselect: TodoMultiSelect, tableTotal: number) => {
	if (multiselect.allSelected) {
		return tableTotal;
	}
	return Object.keys(multiselect.selectedIds).reduce((total, id) => {
		if (multiselect.selectedIds[Number(id)]) {
			total++;
		}
		return total;
	}, 0);
};

const ACTION_HANDLERS: { [k: string]: (state: TodoState, a: AnyAction) => TodoState } = {
	[RESET]: () => ({ ...initialState }),
	[SET_TABLE_DATA]: (state, { tableLoading, tableData, tableTotal }) => ({
		...state,
		tableLoading,
		tableData,
		tableTotal: !isNaN(tableTotal) ? tableTotal : state.tableTotal,
		multiselect: {
			...state.multiselect,
			total: getMultiselectedTotal(state.multiselect, !isNaN(tableTotal) ? tableTotal : state.tableTotal)
		}
	}),
	[SET_VIEWS]: (state, { viewsLoading, views, viewMap }) => ({ ...state, viewsLoading, views, viewMap }),
	[SET_VIEW_STATS]: (state, { viewStatsLoading, viewStats }) => ({ ...state, viewStatsLoading, viewStats }),
	[SET_FILTERS]: (state, { filters }) => ({ ...state, filters: { ...state.filters, ...filters } }),
	[SET_SELECTED_VIEW]: (state, { selectedView }) => ({ ...state, selectedView }),
	[SET_OFFSET]: (state, { offset }) => ({ ...state, offset }),
	[SET_REPORT_STATS]: (state, { reportLoading, reportStats }) => ({ ...state, reportLoading, reportStats }),
	[SET_SELECTED_ID]: (state, { selectedId }) => ({ ...state, selectedId }),
	[SET_TOP_LIST]: (state, { topListData }) => ({ ...state, reportStats: { ...state.reportStats, topListData } }),
	[SET_SELECTED_ROLES_ACTIVITY]: (state, { selectedRolesActivity }) => ({
		...state,
		selectedRolesActivity
	}),
	[SET_SELECTED_ROLES_APPOINTMENT]: (state, { selectedRolesAppointment }) => ({
		...state,
		selectedRolesAppointment
	}),
	[SET_FOCUS_DATA]: (state, { focus }) => ({
		...state,
		focus: { ...state.focus, ...focus }
	}),
	[SET_MULTI_SELECT]: (state, { multiselect }) => {
		const newMultiselect = { ...state.multiselect, ...multiselect };
		newMultiselect.total = getMultiselectedTotal(newMultiselect, state.tableTotal);
		return {
			...state,
			multiselect: newMultiselect
		};
	},
	[SET_DAYS_TO_HIGHLIGHT]: (state, { daysToHighlight }) => ({
		...state,
		daysToHighlight
	})
};

export default (state = initialState, action: AnyAction) => {
	const handler = ACTION_HANDLERS[action.type];
	return handler ? handler(state, action) : state;
};
