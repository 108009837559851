import React, { useEffect, useRef, useState } from 'react';
import { Title, Text, Row, AssistChip, Tooltip } from '@upsales/components';
import bemClass from '@upsales/components/Utils/bemClass';
import './BigNumberWidget.scss';
import formatWidgetValue from '../formatWidgetValue';
import { RCWidgetData } from 'Resources/ReportWidget';
import { RCDashboardWidget, ContentSize } from 'Resources/ReportDashboard';
import { Pulse } from 'App/components/animations';
import { loadDrilldown } from '../chartHelper';

type Props = {
	className: string;
	loading: boolean;
	drilldown: boolean;
	data: RCWidgetData;
	height: number;
	width: number;
	groupBy: string[];
	config: RCDashboardWidget;
	showGoal: boolean;
};

type USTObj<T> = {
	wrapperRef: React.RefObject<T>;
	contentRef: React.RefObject<T>;
	numberRef: React.RefObject<T>;
	size: ContentSize;
};

const MAX_RESIZE_RESETS = 2;

function usePoormansAutofit<T>(
	width: number,
	height: number,
	loading: boolean,
	hasChip: boolean,
	contentSize?: ContentSize
): USTObj<T> {
	const contentRef = useRef<HTMLElement & T>(null);
	const wrapperRef = useRef<HTMLElement & T>(null);
	const numberRef = useRef<HTMLElement & T>(null);
	const [size, setSize] = useState(contentSize ?? ContentSize.sm);
	const [prevContentWidth, setPrevContentWidth] = useState(0);
	const [prevWidgetWidth, setPrevWidgetWidth] = useState(0);
	const [prevWidgetHeight, setPrevWidgetHeight] = useState(0);
	// Protection from indefinitely switching between sizes
	const [stopResize, setStopResize] = useState(false);
	const [checkResize, setCheckResize] = useState(false);
	const [resizeResets, setResizeResets] = useState(0);

	const sizeDownRatio = 0.8;
	const sizeUpRatio = 0.45;
	const sizeUpHeight = 0.6;

	useEffect(() => {
		if (contentSize !== undefined) {
			setSize(contentSize);
		} else if (contentRef?.current && wrapperRef?.current && !loading) {
			// Start at smallest text size and trigger this function until content fits within bounds
			// defined above.
			const { width: widgetWidth, height: widgetHeight } = wrapperRef.current.getBoundingClientRect();
			const { width: contentWidth, height: contentHeight } = contentRef.current.getBoundingClientRect();
			const growableContentWidth = numberRef.current?.getBoundingClientRect()?.width ?? contentWidth;

			// Do like this instead of using ref on chip to account for margin
			const chipWidth = hasChip ? contentWidth - growableContentWidth : 0;

			const tooBig = growableContentWidth / sizeDownRatio + chipWidth > widgetWidth;
			const tooSmall =
				growableContentWidth / sizeUpRatio + chipWidth < widgetWidth &&
				contentHeight < widgetHeight * sizeUpHeight;
			let forceCheck = false;

			if (
				resizeResets <= MAX_RESIZE_RESETS &&
				(Math.abs(widgetWidth - prevWidgetWidth) > 2 || Math.abs(widgetHeight - prevWidgetHeight) > 2)
			) {
				// Size of widget changed -- start new resize
				setSize(ContentSize.sm);
				setStopResize(false);
				setResizeResets(prev => prev + 1);
				forceCheck = true; // force another iteration, in case no other useEffect triggers changed
			} else if (!stopResize) {
				if (tooSmall && size < ContentSize.xl) {
					setSize(size + 1);
				} else if (tooBig && size > ContentSize.sm) {
					setSize(size - 1);
					setStopResize(true); // Stop if we ever need to size down to avoid infinite resize
				}
			}

			setPrevWidgetWidth(widgetWidth);
			setPrevWidgetHeight(widgetHeight);
			setPrevContentWidth(contentWidth);
			setCheckResize(forceCheck);
		}
	}, [width, height, prevContentWidth, size, checkResize, loading, contentSize]);

	return { size, wrapperRef, numberRef, contentRef };
}

const BigNumberWidget = ({ loading = false, drilldown, data, className, showGoal, width, height, config }: Props) => {
	const isRevRec = ['REVENUE_RECOGNITION_IDEAL', 'REVENUE_RECOGNITION_WEIGHTED'].includes(data.type?.name);
	const isHitRate = data.isHitRate;
	const progress = data.total?.progress ?? 0;
	const goal = data.total?.goal ?? 0;
	const remaining = Math.abs(data.total?.remaining) ?? 0;
	const dt = data.datatype?.progress;
	const reachedGoal = data.total?.remaining < 0 ?? false;
	const hasChip = !!data.total?.special?.trend || !!data.total?.special?.negativeTrend;
	const trend = data.total?.special?.trend ?? data.total?.special?.negativeTrend ?? null;
	const trendGrew = trend > 0;
	const formattedTrend = trend?.toString().replace('-', '') + '%';

	const { contentRef, wrapperRef, numberRef, size } = usePoormansAutofit<HTMLDivElement>(
		width,
		height,
		loading,
		hasChip,
		config?.contentSize
	);
	const classes = new bemClass('BigNumberWidget', className).mod({ loading, [ContentSize[size]]: true });

	const isPositiveTrend = () => {
		if (trendGrew) return !!data.total?.special?.trend;

		return !!data.total?.special?.negativeTrend;
	};

	const drilldownEnabled = drilldown && data.enableDrilldown;
	return (
		<Pulse disabled={!loading}>
			<div
				className={classes.mod({ drilldownEnabled }).b()}
				ref={wrapperRef}
				onClick={drilldownEnabled ? () => loadDrilldown([], config) : undefined}
			>
				{showGoal ? (
					<div className={classes.elem('value').b()} ref={contentRef}>
						<div>
							<Title bold>{formatWidgetValue(progress, 'number')}</Title>
							<Title color="grey-10">{`\u00A0${data.language?.of?.toLowerCase()}\u00A0`}</Title>
							<Title bold>{formatWidgetValue(goal, dt, data.currency)}</Title>
						</div>
						<Row align="center">
							<Text color="grey-10">
								{(reachedGoal ? `${data.language?.overGoal}` : `${data.language?.remaining}`) +
									':\u00A0'}
							</Text>
							<Text color={reachedGoal ? 'success-4' : 'grey-10'}>
								{`${reachedGoal ? '+' : ''}${formatWidgetValue(remaining, dt, data.currency)}`}
							</Text>
						</Row>
					</div>
				) : isHitRate ? (
					<div className={classes.elem('value').b()} ref={contentRef}>
						<Title center bold>
							{dt === 'percent'
								? `${data.total.special.percent} %`
								: // else fraction
								  `${data.total.special.numerator} / ${data.total.special.denominator}`}
						</Title>
					</div>
				) : (
					<>
						<div
							className={classes
								.elem('value')
								.mod({
									adjustForAverage: data.total?.special?.avg
								})
								.b()}
							ref={contentRef}
						>
							<Row align="center">
								<Title center bold ref={numberRef}>
									{formatWidgetValue(isRevRec ? goal : progress, dt, data.currency)}
								</Title>
								{hasChip ? (
									<Tooltip title={data.language?.toolTip ?? ''}>
										<AssistChip
											title={formattedTrend}
											icon={trendGrew ? 'caret-up' : 'caret-down'}
											type={isPositiveTrend() ? 'success' : 'danger'}
										/>
									</Tooltip>
								) : null}
							</Row>
						</div>
						{data.total?.special?.avg ? (
							<Row align="center" className={classes.elem('average').b()}>
								<Text color="grey-10">{`${data.language?.clientAverage}: \u00A0`}</Text>
								<Text bold>{formatWidgetValue(data.total?.special?.avg, dt, data.currency)}</Text>
							</Row>
						) : null}
					</>
				)}
			</div>
		</Pulse>
	);
};

export default BigNumberWidget;
