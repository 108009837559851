import { OrderInterval, PeriodLength } from '../../Context/SubscriptionGroupState';
import { useSubscriptionGroupContext } from '../../Context/SubscriptionGroupContext';
import { usePopupPortalAnchorsContext } from 'App/helpers/PopupPortalAnchors';
import { sdf } from '../../Context/SubscriptionPeriodContextHelpers';
import { DateInput, Link, Text, Select } from '@upsales/components';
import bemClass from '@upsales/components/Utils/bemClass';
import PriceList from 'App/resources/Model/PriceList';
import T from 'Components/Helpers/translate';
import React, { useMemo } from 'react';
import moment from 'moment';

import './SubscriptionPeriodScheduling.scss';

const SubscriptionPeriodScheduling: React.FC = () => {
	const classes = new bemClass('SubscriptionPeriodScheduling');

	const {
		currentPeriod,
		isOnStartPeriod,
		useStatics,
		toggleRenewal,
		setStartDate,
		setTerminationDate,
		setNoticePeriod,
		setPeriodLength,
		setOffset,
		setOrderInterval,
		setInvoiceStartDate,
		setPriceList,
		state: { isEdit, isAdvancedMode, subscriptionMap, splitEnabled, allowOffset }
	} = useSubscriptionGroupContext();

	const { anchor, scrollContainer } = usePopupPortalAnchorsContext();

	const { Intervals, NoticePeriods, PeriodLengths, Offsets } = useStatics();

	const hasPriceLists =
		Tools.FeatureHelper.isAvailable(Tools.FeatureHelper.Feature.PRICE_LISTS) &&
		Tools.FeatureHelper.hasSoftDeployAccess('PRICE_LISTS');
	const getPriceListItem = (priceList: PriceList) => {
		return { ...priceList, title: priceList.name };
	};
	const priceListOptions = Tools.AppService.getPriceLists()
		?.filter(pl => pl.active)
		.map(getPriceListItem);
	const selectedPriceList = getPriceListItem(currentPeriod.priceList);

	const getPeriodLengthItem = (periodLength: PeriodLength) => {
		const untilFurtherNotice = T('agreement.periodLength.untilFurtherNotice').toLowerCase();
		const title = `${periodLength} ${T('date.month' + (periodLength > 1 ? 's' : '')).toLowerCase()}`;
		return {
			id: periodLength,
			title: periodLength === 0 ? untilFurtherNotice : title
		};
	};
	const periodLengthOptions = useMemo(() => {
		const validPeriodLengths = splitEnabled && isOnStartPeriod ? PeriodLengths.filter(p => p > 0) : PeriodLengths;
		return validPeriodLengths.map(periodLength => getPeriodLengthItem(periodLength));
	}, [PeriodLengths, splitEnabled, isOnStartPeriod]);
	const selectedPeriodLength = getPeriodLengthItem(currentPeriod.periodLength);

	const getIntervalItem = (interval: OrderInterval) => ({
		id: interval,
		title: T(`order.recurringInterval.byMonth.${interval}`).toLowerCase()
	});
	const intervalOptions = useMemo(() => Intervals.map(interval => getIntervalItem(interval)), [Intervals]);
	const selectedInterval = getIntervalItem(currentPeriod.orderInterval);

	const getOffsetItem = (offset: number) => ({ id: offset, title: `${offset} ${T('default.days')}` });
	const offsetOptions = useMemo(() => Offsets.map(offset => getOffsetItem(offset)), [Offsets]);
	currentPeriod.offset = typeof currentPeriod.offset === 'number' ? currentPeriod.offset : 0;
	const selectedOffset = getOffsetItem(currentPeriod.offset ?? 0);

	const getNoticePeriodItem = (period: number) => {
		const noNoticePeriodTitle = T('subscription.modal.summary.scheduling.noticePeriod.no');
		const noticePeriodTitle = `${period} ${T('date.month' + (period > 1 ? 's' : '')).toLowerCase()}`;
		return {
			id: period,
			title: period === 0 ? noNoticePeriodTitle : noticePeriodTitle
		};
	};

	const noticePeriodOptions = useMemo(() => {
		const validNoticePeriods =
			currentPeriod.periodLength > 0
				? NoticePeriods.filter(period => period <= currentPeriod.periodLength)
				: NoticePeriods;
		return validNoticePeriods.map(period => getNoticePeriodItem(period));
	}, [NoticePeriods, currentPeriod]);

	const selectedNoticePeriod = getNoticePeriodItem(currentPeriod.noticePeriod);

	const endDateHasPassed = currentPeriod.endDate && moment(currentPeriod.endDate).isBefore();

	const currentPeriodLocked = currentPeriod.locked || currentPeriod.externallyLocked;

	const renderRenewalOrTerminationRow = () => {
		if (isOnStartPeriod && Object.keys(subscriptionMap).length > 1) return null;

		let subscriptionOrPeriodText = T(
			'subscription.modal.summary.scheduling.renewedOrTerminated' + (splitEnabled ? '.period' : '')
		);

		if (endDateHasPassed) {
			subscriptionOrPeriodText = splitEnabled
				? T('subscription.modal.summary.scheduling.period')
				: T('subscription.modal.summary.scheduling.subscription');
		}

		if (currentPeriod.periodLength === 0) {
			return (
				<Text className={classes.elem('row').b()}>
					{currentPeriod.endDate ? (
						<>
							<Text>{T('subscription.modal.summary.scheduling.renewedOrTerminated')}</Text>
							<Link
								className={classes.elem('termination-toggle').b()}
								onClick={() =>
									currentPeriod.endDate
										? setTerminationDate()
										: setTerminationDate(moment().toString())
								}
							>
								{T('subscription.modal.summary.scheduling.terminated').toLowerCase() + ' '}
							</Link>
							<Text className={'Text--left-padding'}>
								{T('subscription.modal.summary.scheduling.terminated.part2')}
							</Text>
						</>
					) : (
						<Text>{T('agreement.theAgreement') + ' ' + T('default.hasNo').toLowerCase()}</Text>
					)}
					{currentPeriod.endDate ? (
						<DateInput
							anchor={anchor}
							scrollContainer={scrollContainer}
							className={`Input--sm Input--no-border${currentPeriodLocked ? ' DateInput--disabled' : ''}`}
							closeOnSelect={true}
							min={moment(currentPeriod.invoiceStartDate).add(1, 'day').toDate()}
							value={new Date(currentPeriod.endDate)}
							showWeekNumbers={true}
							placeholder={T('default.date')}
							disabled={currentPeriodLocked}
							inline
							onChange={e => setTerminationDate(e.target.value.toDateString())}
						/>
					) : (
						<Link
							className={classes.elem('termination-toggle').b()}
							onClick={() =>
								currentPeriod.endDate
									? setTerminationDate()
									: setTerminationDate(
											moment(currentPeriod.invoiceStartDate).add(1, 'day').format(sdf)
									  )
							}
						>
							{T('subscription.modal.summary.scheduling.terminationDate').toLowerCase()}
						</Link>
					)}
				</Text>
			);
		}

		return (
			<Text className={classes.elem('row').b()}>
				{subscriptionOrPeriodText}
				{currentPeriodLocked ? (
					T(`subscription.modal.summary.scheduling.${currentPeriod.endDate ? 'terminated' : 'renewed'}`)
				) : (
					<Link className={classes.elem('renewal-toggle').b()} onClick={toggleRenewal}>
						{T(
							`subscription.modal.summary.scheduling.${
								currentPeriod.endDate ? (endDateHasPassed ? 'wasTerminated' : 'terminated') : 'renewed'
							}`
						)}
					</Link>
				)}
				{currentPeriod.endDate ? (
					isAdvancedMode ? (
						<>
							<Text className={currentPeriodLocked ? 'Text--left-padding' : ''}>
								{T('subscription.modal.summary.scheduling.terminated.part2')}
							</Text>
							<DateInput
								anchor={anchor}
								scrollContainer={scrollContainer}
								className={`Input--sm Input--no-border${
									currentPeriodLocked ? ' DateInput--disabled' : ''
								}`}
								closeOnSelect={true}
								min={moment(currentPeriod.invoiceStartDate).add(1, 'day').toDate()}
								max={moment(currentPeriod.invoiceStartDate)
									.add(currentPeriod.periodLength, 'M')
									.toDate()}
								value={new Date(currentPeriod.endDate)}
								showWeekNumbers={true}
								placeholder={T('default.date')}
								disabled={currentPeriodLocked}
								inline
								onChange={e => setTerminationDate(e.target.value.toDateString())}
							/>
						</>
					) : (
						T('subscription.modal.summary.scheduling.terminated.part2') +
						moment(currentPeriod.endDate).format('L')
					)
				) : (
					moment(currentPeriod.renewalDate).format('L') +
					T('subscription.modal.summary.scheduling.renewed.part2')
				)}
			</Text>
		);
	};

	const renderFirstPeriodText = () => {
		if (isAdvancedMode && splitEnabled) return null;

		const startOrderDateHasPassed = moment(currentPeriod.invoiceStartDate).isBefore();
		const orderPeriodText = T(
			'subscription.modal.summary.scheduling.' + (startOrderDateHasPassed ? 'orderPeriodDate' : 'firstOrderDate')
		);

		if (isAdvancedMode) {
			return (
				<>
					{orderPeriodText}
					<DateInput
						anchor={anchor}
						disabled={isEdit}
						scrollContainer={scrollContainer}
						className={`Input--sm Input--no-border${isEdit ? ' DateInput--disabled' : ''}`}
						closeOnSelect={true}
						value={new Date(currentPeriod.invoiceStartDate)}
						showWeekNumbers={true}
						placeholder={T('default.date')}
						inline
						onChange={e => setInvoiceStartDate(e.target.value.toDateString())}
					/>
				</>
			);
		}

		return orderPeriodText + moment(currentPeriod.invoiceStartDate).format('L');
	};

	const renderPeriodLengthText = () => {
		const startDateHasPassed = moment(currentPeriod.startDate).isBefore(moment().format(sdf));

		const endFinalTag = endDateHasPassed ? '.ended' : '.ends';

		let startFinalTag = startDateHasPassed ? '.started' : '.starts';
		if (splitEnabled) {
			startFinalTag += '.it';
		}

		let periodFinalTag = splitEnabled ? '.this' : '';
		if (endDateHasPassed) {
			periodFinalTag += '.passed';
		}

		const periodStartsText = T('subscription.modal.summary.scheduling' + startFinalTag);
		const periodLengthText = T('subscription.modal.summary.scheduling.periodLength' + periodFinalTag);
		const periodEndsText =
			T('subscription.modal.summary.scheduling' + endFinalTag) +
			moment(currentPeriod.endDate ?? currentPeriod.renewalDate).format('L');

		return (
			<Text className={classes.elem('row').b()}>
				{periodLengthText}
				<Select
					className={classes.elem('inline-select').b()}
					dropdownClassName={classes.elem('inline-dropdown').b()}
					value={selectedPeriodLength}
					onChange={item => {
						if (typeof item.id === 'number') {
							setPeriodLength(item.id);
						}
					}}
					options={periodLengthOptions}
					disabled={!isAdvancedMode || isEdit}
					inline
					showSearch={false}
					anchor={document.querySelector('.OpenSubscription__inner')}
				/>
				{periodStartsText}
				{!splitEnabled ? (
					<DateInput
						anchor={anchor}
						scrollContainer={scrollContainer}
						className={`Input--sm Input--no-border${isEdit ? ' DateInput--disabled' : ''}`}
						closeOnSelect={true}
						disabled={isEdit}
						value={new Date(currentPeriod.startDate)}
						showWeekNumbers={true}
						placeholder={T('default.date')}
						inline
						onChange={e => setStartDate(e.target.value.toDateString(), isAdvancedMode)}
					/>
				) : (
					`${moment(currentPeriod.startDate).format('L')} ${currentPeriod.periodLength ? periodEndsText : ''}`
				)}
			</Text>
		);
	};

	const renderPriceListRow = () => {
		if (hasPriceLists) {
			return (
				<Text className={classes.elem('row').b()}>
					{T('subscription.modal.summary.scheduling.priceList')}
					<Select
						dropdownClassName={classes.elem('inline-dropdown').b()}
						value={selectedPriceList}
						onChange={setPriceList}
						options={priceListOptions}
						disabled={currentPeriodLocked}
						inline
						showSearch={false}
						anchor={document.querySelector('.OpenSubscription__inner')}
					/>
				</Text>
			);
		}

		return null;
	};

	const initialOffset = useMemo(() => currentPeriod.offset, []);

	const hadOffset = isEdit && initialOffset;
	const isOffsetAllowed = allowOffset && (initialOffset || isAdvancedMode);
	const shouldShowOffset = hadOffset || isOffsetAllowed;

	let noticeFinalTag = splitEnabled ? '.period' : '';
	if (endDateHasPassed) {
		noticeFinalTag += '.passed';
	}
	const noticePeriodStartText = T('subscription.modal.summary.scheduling.noticePeriod.start' + noticeFinalTag);
	return (
		<div className={classes.b()}>
			{renderPeriodLengthText()}
			<Text className={classes.elem('row').b()}>
				{T('subscription.modal.summary.scheduling.orderInterval' + (endDateHasPassed ? '.passed' : ''))}
				<Select
					className={classes.elem('inline-select').b()}
					dropdownClassName={classes.elem('inline-dropdown').b()}
					value={selectedInterval}
					onChange={item => {
						if (typeof item.id === 'number') {
							setOrderInterval(item.id);
						}
					}}
					options={intervalOptions}
					disabled={!isAdvancedMode || isEdit}
					inline
					showSearch={false}
					anchor={document.querySelector('.OpenSubscription__inner')}
				/>
				{renderFirstPeriodText()}
			</Text>

			{shouldShowOffset ? (
				<ul>
					<li>
						<Text className={classes.elem('row').b()}>
							{T(
								'subscription.modal.summary.scheduling.offset.start' +
									(endDateHasPassed ? '.passed' : '')
							)}
							<Select
								className={classes.elem('inline-select').b()}
								dropdownClassName={classes.elem('inline-dropdown').b()}
								value={selectedOffset}
								onChange={item => {
									if (typeof item.id === 'number') {
										setOffset(item.id);
									}
								}}
								options={offsetOptions}
								disabled={currentPeriodLocked || !isAdvancedMode}
								inline
								showSearch={false}
								anchor={document.querySelector('.OpenSubscription__inner')}
							/>
							{T('subscription.modal.summary.scheduling.offset.end')}
						</Text>
					</li>
				</ul>
			) : null}
			<Text className={classes.elem('row').b()}>
				{noticePeriodStartText}
				<Select
					className={classes.elem('inline-select').b()}
					dropdownClassName={classes.elem('inline-dropdown').b()}
					value={selectedNoticePeriod}
					onChange={item => {
						if (typeof item.id === 'number') {
							setNoticePeriod(item.id);
						}
					}}
					options={noticePeriodOptions}
					disabled={currentPeriodLocked || !isAdvancedMode}
					inline
					showSearch={false}
					anchor={document.querySelector('.OpenSubscription__inner')}
				/>
				{T('subscription.modal.summary.scheduling.noticePeriod.end')}
			</Text>
			{renderRenewalOrTerminationRow()}
			{renderPriceListRow()}
		</div>
	);
};

export default SubscriptionPeriodScheduling;
