import moment from 'moment';
import t from 'Components/Helpers/translate';

const getPresets = () => {
	const lang = {
		yesterday: t('yesterday'),
		today: t('activity.today'),
		tomorrow: t('activity.tomorrow')
	};
	return [
		{
			isMatch(date: Date) {
				return moment(date).isBetween(
					moment().subtract(1, 'day').startOf('day'),
					moment().subtract(1, 'day').endOf('day'),
					null,
					'[]'
				);
			},
			format: lang.yesterday.toLowerCase()
		},
		{
			isMatch(date: Date) {
				return moment(date).isBetween(moment().startOf('day'), moment().endOf('day'), null, '[]');
			},
			format: lang.today.toLowerCase()
		},
		{
			isMatch(date: Date) {
				return moment(date).isBetween(
					moment().add(1, 'day').startOf('day'),
					moment().add(1, 'day').endOf('day'),
					null,
					'[]'
				);
			},
			format: lang.tomorrow.toLowerCase()
		}
	];
};

const getPostponedDuration = (date: Date) => {
	const presets = getPresets();

	let format = 'D MMM YYYY';
	const matchPreset = presets.find(preset => preset.isMatch(date));
	const isStartDate = moment(date).startOf('day').isSame(date);

	if (!isStartDate) {
		format += ' LT';
	}

	if (matchPreset) {
		return `${!isStartDate ? moment(date).format('LT') : ''} ${matchPreset.format}`;
	}

	return moment(date).locale(Tools.AppService.getSelf().language).format(format);
};

export default getPostponedDuration;
