import Resource from './Resource';

class AcceptTermsResource extends Resource {
	constructor() {
		super('master/acceptTerms');
	}
}

const resource = new AcceptTermsResource();

export default resource;
