import PropTypes from 'prop-types';
import React from 'react';
import Confirmation from './Confirmation';
import Konami from '../Helpers/Konami';
import { Button } from '@upsales/components';

const propTypes = {
	body: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
	confirmationMode: PropTypes.string,
	confirmText: PropTypes.string,
	reactive: PropTypes.bool,
	resolve: PropTypes.func,
	hideAbort: PropTypes.bool,
	reject: PropTypes.func,
	no: PropTypes.string,
	resolveTrue: PropTypes.bool,
	resolveFalse: PropTypes.bool,
	confirmClass: PropTypes.string,
	confirmButtonText: PropTypes.string,
	confirmButtonSize: PropTypes.oneOf(Button.sizes),
	id: PropTypes.string,
	body2: PropTypes.string,
	iconClasses: PropTypes.string,
	title: PropTypes.string,
	fullscreen: PropTypes.bool
};

class AlertConfirm extends React.Component {
	constructor(props) {
		super(props);

		const state = { resolving: false };
		this.inConfirmationMode = props.confirmationMode && props.confirmationMode === 'text';
		if (this.inConfirmationMode) {
			state.input = '';
			state.valid = false;
			state.confirmationMode = props.confirmationMode;
			state.confirmText = 'UPSALES';

			if (props.confirmText && props.confirmText.length) {
				state.confirmText = props.confirmText;
			}
		} else {
			state.valid = true;
		}

		if (props.reactive === true) {
			state.reactive = true;
		}

		this.state = state;
		this.compare = this.compare.bind(this);
		this.konami = Konami(() => {
			this.setState({ resolving: true });
			props.resolve();
		});
	}

	//eslint-disable-next-line camelcase
	UNSAFE_componentWillUpdate(nextProps, nextState) {
		if (this.inConfirmationMode) {
			const formatedConfirmText = this.state.confirmText?.replace(/\s\s+/g, ' ') ?? '';
			if (nextState.input && nextState.input.toLowerCase().trim() === formatedConfirmText.toLowerCase().trim()) {
				nextState.valid = true;
			} else if (this.state.valid === true) {
				nextState.valid = false;
			}
		}
	}

	componentWillUnmount() {
		this.konami.unload();
	}

	compare(event) {
		const text = event.target.value;
		const state = Object.assign({}, this.state);

		state.input = text;
		this.setState({
			input: state.input
		});
	}

	buttonControls() {
		const {
			hideAbort,
			reject,
			no,
			resolveTrue,
			resolveFalse,
			confirmClass,
			resolve,
			confirmButtonText,
			confirmButtonSize
		} = this.props;

		const { valid, resolving } = this.state;

		const rejectButton = !hideAbort && (
			<Button color="grey" type="link" disabled={resolving} onClick={reject}>
				{no ? no : Tools.$translate('default.abort')}
			</Button>
		);

		if (resolveTrue || resolveFalse) {
			return (
				<div className="up-modal-controls" disabled={valid}>
					{rejectButton}
				</div>
			);
		}

		if (resolve) {
			return (
				<div className="up-modal-controls">
					<Button
						disabled={!valid || resolving}
						onClick={() => {
							this.setState({ resolving: true });
							resolve();
						}}
						color={
							confirmClass && confirmClass.indexOf('btn-') === 0
								? confirmClass.replace('btn-', '')
								: confirmClass
						}
						size={confirmButtonSize || 'lg'}
					>
						{confirmButtonText}
						{resolving ? <b className="fa fa-spinner fa-spin" /> : null}
					</Button>
					{rejectButton}
				</div>
			);
		}
	}

	renderModalHeader(title, iconClasses, reject, valid) {
		const topBorder = valid
			? {
					borderTop: '5px solid #5cb85c',
					borderRadius: '0'
			  }
			: {
					borderTop: '5px solid #dc0d17',
					borderRadius: '0'
			  };

		return (
			<div className="up-modal-header" style={topBorder}>
				<h2>
					{iconClasses && <i className={`${iconClasses} template-icon`} />}
					{title ? title : Tools.$translate('default.confirm')}
				</h2>
				<a className="btn btn-link modal-close" onClick={reject}>
					<i className="fa fa-times" />
				</a>
			</div>
		);
	}

	renderFullscreenHeader(reject) {
		return (
			<div className="up-modal-header fullscreen-header">
				<a className="btn btn-link modal-close" onClick={reject}>
					<b className="modal-exit-button fa fa-times" />
				</a>
			</div>
		);
	}

	render() {
		const valid = this.state.valid;
		const { id, body, body2, confirmationMode, iconClasses, title, reject, fullscreen } = this.props;

		return (
			<div id={id}>
				{fullscreen
					? this.renderFullscreenHeader(reject)
					: this.renderModalHeader(title, iconClasses, reject, valid)}
				<div className={`up-modal-content${fullscreen ? ' fullscreen-content' : ''}`}>
					<div className="up-panel">
						<div className="up-panel-content">
							{typeof body === 'string' ? <p>{body}</p> : body}
							{body2 ? <p>{body2}</p> : null}
							{confirmationMode && (
								<Confirmation
									input={this.state.input}
									valid={this.state.valid}
									compare={this.compare}
									confirmText={this.state.confirmText}
								/>
							)}
						</div>
					</div>
				</div>
				{this.buttonControls()}
			</div>
		);
	}
}

AlertConfirm.propTypes = propTypes;

export default AlertConfirm;
