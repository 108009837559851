import { AnyAction } from 'redux';
import { Competitor } from 'Resources/Competitor';

export const RESET = '[Competitor] RESET';
export const SET_COMPETITORS = '[Competitor] SET_COMPETITORS';
export const FETCH_COMPETITORS_PENDING = `[Competitor] FETCH_COMPETITORS_PENDING`;
export const MERGE_COMPETITORS_PENDING = `[Competitor] MERGE_COMPETITORS_PENDING`;
export const REMOVE_COMPETITOR_PENDING = `[Competitor] REMOVE_COMPETITOR_PENDING`;

export interface CompetitorState {
	competitors: Competitor[];
	pendingCompetitorFetch: boolean;
	pendingCompetitorMerge: boolean;
	pendingCompetitorRemoval: boolean;
}
interface SetCompetitorArgs {
	competitors: Competitor[];
}
interface PendingFetchArgs {
	pending: boolean;
}
interface CompetitorActionHandlers {
	[key: string]: (state: CompetitorState, props?: any) => CompetitorState;
}

export const initialState: CompetitorState = {
	competitors: [],
	pendingCompetitorFetch: false,
	pendingCompetitorMerge: false,
	pendingCompetitorRemoval: false
};

const ACTION_HANDLERS: CompetitorActionHandlers = {
	[RESET]: () => ({ ...initialState }),
	[SET_COMPETITORS]: (state: CompetitorState, { competitors }: SetCompetitorArgs) => ({ ...state, competitors }),
	[FETCH_COMPETITORS_PENDING]: (state: CompetitorState, { pending }: PendingFetchArgs) => ({
		...state,
		pendingCompetitorFetch: pending
	}),
	[MERGE_COMPETITORS_PENDING]: (state: CompetitorState, { pending }: PendingFetchArgs) => ({
		...state,
		pendingCompetitorMerge: pending
	}),
	[REMOVE_COMPETITOR_PENDING]: (state: CompetitorState, { pending }: PendingFetchArgs) => ({
		...state,
		pendingCompetitorRemoval: pending
	})
};

export default (state = initialState, action: AnyAction) => {
	const handler = ACTION_HANDLERS[action.type];
	return handler ? handler(state, action) : state;
};
