import Order from 'App/resources/Model/Order';
import translate from 'Components/Helpers/translate';

export enum ForecastingRiskLevel {
	Medium = 'Medium',
	High = 'High'
}

export class ForecastingRisk {
	field: keyof Order['risks'];
	softDeployFlag: string;
	type: ForecastingRiskLevel;
	langOpts?: (order: Order) => Object;
	extraRequirement?: (order: Order) => boolean;

	constructor(config: Pick<ForecastingRisk, 'field' | 'softDeployFlag' | 'type' | 'langOpts' | 'extraRequirement'>) {
		const { field, softDeployFlag, type, langOpts, extraRequirement } = config;
		this.field = field;
		this.softDeployFlag = softDeployFlag;
		this.type = type;
		this.langOpts = langOpts;
		this.extraRequirement = extraRequirement;
	}

	hasRisk(order: Order) {
		return (
			!!order.risks?.[this.field] &&
			Tools.FeatureHelper.hasSoftDeployAccess(this.softDeployFlag) &&
			(this.extraRequirement?.(order) ?? true)
		);
	}

	tooltip(order: Order) {
		return translate(`forecastingRisk.${this.field}`, this.langOpts?.(order));
	}
}

export const ForecastingRisks: ForecastingRisk[] = [
	new ForecastingRisk({
		field: 'noNextStepOccured',
		type: ForecastingRiskLevel.High,
		softDeployFlag: 'SALESBOARD_RISK_NO_NEXT_STEP',
		langOpts: () => ({})
	}),
	new ForecastingRisk({
		field: 'noAppointmentsOccured',
		type: ForecastingRiskLevel.High,
		softDeployFlag: 'SALESBOARD_RISK_NO_APPOINTMENTS',
		langOpts: order => ({
			statCount: order.userSalesStatistics?.mostCommonAppointmentsPerOrder,
			orderCount: order.noCompletedAppointments
		})
	}),
	new ForecastingRisk({
		field: 'postponedAppointmentsOccured',
		type: ForecastingRiskLevel.High,
		softDeployFlag: 'SALESBOARD_RISK_POSTPONED_APPOINTMENTS',
		langOpts: order => ({ count: order.noPostponedAppointments })
	}),
	new ForecastingRisk({
		field: 'closingDateChangedOccured',
		softDeployFlag: 'SALESBOARD_RISK_CLOSING_DATE',
		type: ForecastingRiskLevel.High,
		langOpts: order => ({ count: order.noTimesClosingDateChanged }),
		extraRequirement: order => order.probability >= 50
	}),
	new ForecastingRisk({
		field: 'notAnsweringCallsOccured',
		softDeployFlag: 'SALESBOARD_RISK_NOT_ANSWERING_CALLS',
		type: ForecastingRiskLevel.High,
		langOpts: order => ({ count: order.noTimesCallsNotAnswered })
	}),
	new ForecastingRisk({
		field: 'orderValueOccured',
		softDeployFlag: 'SALESBOARD_RISK_ORDER_VALUE',
		type: ForecastingRiskLevel.High
	}),
	new ForecastingRisk({
		field: 'closingDateChangedOccured',
		softDeployFlag: 'SALESBOARD_RISK_CLOSING_DATE',
		type: ForecastingRiskLevel.Medium,
		langOpts: order => ({ count: order.noTimesClosingDateChanged }),
		extraRequirement: order => order.probability < 50
	}),
	new ForecastingRisk({
		field: 'orderValueChangedOccured',
		softDeployFlag: 'SALESBOARD_RISK_ORDER_VALUE',
		type: ForecastingRiskLevel.Medium,
		langOpts: order => ({ count: order.noTimesOrderValueChanged })
	}),
	new ForecastingRisk({
		field: 'noRecentContactOccured',
		softDeployFlag: 'SALESBOARD_RISK_NO_RECENT_CONTACT',
		type: ForecastingRiskLevel.Medium,
		langOpts: () => ({})
	}),
	new ForecastingRisk({
		field: 'noStakeholderInvolvedOccured',
		softDeployFlag: 'SALESBOARD_RISK_STAKEHOLDER',
		type: ForecastingRiskLevel.Medium,
		langOpts: order => ({ titleCategory: order.userSalesStatistics?.mostCommonStakeholder })
	}),
	new ForecastingRisk({
		field: 'daysInStageOccured',
		softDeployFlag: 'SALESBOARD_RISK_DAYS_IN_STAGE',
		type: ForecastingRiskLevel.Medium,
		langOpts: order => ({ stage: order.stage.name, count: order.userSalesStatistics?.avgSalesCycleLength })
	}),
	new ForecastingRisk({
		field: 'companySizeOccured',
		softDeployFlag: 'SALESBOARD_RISK_COMPANY_SIZE',
		type: ForecastingRiskLevel.Medium
	})
];

export const getOrderForecastingRisk = (order: Order) => ForecastingRisks.find(risk => risk.hasRisk(order)) ?? null;
