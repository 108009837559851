import { OpenModal } from 'App/services/Modal/Modal';
import { ModalConfigName } from 'App/services/Modal/modalConfigs';
import { AnyAction } from 'redux';

export const RESET = '[Modal] RESET';
export const SET_MODALS = '[Modal] SET_MODALS';
export const SET_SAVED_MODALS = '[Modal] SET_SAVED_MODALS';

export type StateModal = Omit<OpenModal, 'component'> & {
	id: number;
	unmounting?: boolean;
	name: ModalConfigName;
	openedTimeStamp: Date;
};

export type ModalState = {
	openModals: StateModal[];
	savedModals: StateModal[];
	redirectPage: string | null;
};

export const initialState: ModalState = {
	openModals: [],
	savedModals: [],
	redirectPage: null
};

const ACTION_HANDLERS: { [key: string]: (s: ModalState, a: AnyAction) => ModalState } = {
	[RESET]: () => ({ ...initialState }),
	[SET_MODALS]: (state, { modals }) => ({ ...state, openModals: [...modals] }),
	[SET_SAVED_MODALS]: (state, { savedModals, redirectPage }) => ({
		...state,
		savedModals: [...savedModals],
		redirectPage
	})
};

export default (state = initialState, action: AnyAction) => {
	const handler = ACTION_HANDLERS[action.type];
	return handler ? handler(state, action) : state;
};
