import React from 'react';
import PropTypes from 'prop-types';
import UsersText from 'App/components/UsersText';

export default class Users extends React.Component {
	constructor(props) {
		super(props);

		var t = Tools.$translate;
		this.lang = {
			noAccountManager: t('default.noAccountManager')
		};
	}

	render() {
		if (this.props?.account?.merging) {
			return <td />;
		}

		return (
			<td className="DataSourceColumn">
				<div
					className={
						!Array.isArray(this.props?.account?.users) || this.props?.account?.users?.length === 0
							? 'no-account-manager'
							: 'with-account-manager'
					}
				>
					<ReactTemplates.TOOLS.avatar size={30} user={this.props?.account?.users?.[0]} />
					<UsersText users={this.props?.account?.users} placeholder={this.lang.noAccountManager}></UsersText>
				</div>
			</td>
		);
	}
}

Users.propTypes = {
	account: PropTypes.object
};
