import { CurrencyFormat } from 'App/babel/utils/numberFormat';
import { Icon, Text, Tooltip } from '@upsales/components';
import BemClass from '@upsales/components/Utils/bemClass';
import Agreement from 'App/resources/Model/Agreement';
import ToolTipOrderRows from '../ToolTipOrderRows';
import T from 'Components/Helpers/translate';
import { ChildType } from '../CardParent';
import moment from 'moment';
import React from 'react';

import './CardChildRow.scss';

type Props = {
	isFirstAgreement?: boolean;
	isLastAgreement?: boolean;
	hideActions?: boolean;
	isPassed?: boolean;
	hasMRR?: boolean;
	childAgreement: Agreement | ChildType;
	currentYearlyValue?: number;
	editSubscription: () => void;
	onDocumentClick?: () => void;
	valueChange?: number;
	firstChild?: boolean;
};

const CardChildRow = ({
	hasMRR = false,
	isFirstAgreement = false,
	isLastAgreement = false,
	isPassed = false,
	currentYearlyValue,
	childAgreement,
	hideActions,
	onDocumentClick,
	editSubscription,
	valueChange,
	firstChild = false
}: Props) => {
	const classes = new BemClass('CardChildRow');
	const divider = hasMRR ? 12 : 1;
	const currentAgreementValue = isPassed ? 0 : (currentYearlyValue ?? 0) / divider;

	const newValue = ((childAgreement as Agreement).yearlyValue ?? 0) / divider;

	const salesChange = valueChange ? valueChange / divider : newValue - currentAgreementValue;
	const { agreementEnddate, agreementRenewalDate } = childAgreement.metadata;
	const child = childAgreement as ChildType;

	const endDate = agreementEnddate || agreementRenewalDate;
	const currencyFormat = new CurrencyFormat(childAgreement.currency);

	const hasIncreased = salesChange >= 0;
	const currencyStartSign = hasIncreased && !isFirstAgreement ? '+' : '';
	const salesChangeText = currencyStartSign + currencyFormat.short(salesChange) + ` (${hasMRR ? 'MRR' : 'ARR'})`;

	let state: 'child' | 'renewed' | 'terminated' = 'renewed';
	if (child.child) {
		if (firstChild && isLastAgreement && child.parentEndDate) {
			state = 'terminated';
		} else if (firstChild) {
			state = 'renewed';
		} else {
			state = 'child';
		}
	} else if (isLastAgreement && agreementEnddate) {
		state = 'terminated';
	}

	return (
		<div
			onClick={editSubscription}
			className={classes
				.mod({ hasPassed: isPassed, hasEndDate: state === 'terminated', hasRenewed: state === 'renewed' })
				.b()}
		>
			<div className={classes.elem('leftSide').b()}>
				<div className={classes.elem('leftColumn').b()}>
					{!isPassed ? (
						<>
							{
								{
									renewed: <Icon color={'success-4'} name={'subscription'} />,
									terminated: <Icon color={'red'} name={'times'} />,
									child: undefined
								}[state]
							}
						</>
					) : null}
					<div className={classes.elem('date').b()}>
						{!isPassed && state !== 'child' ? (
							<Text size="sm" color="grey-11">
								{state === 'terminated'
									? T('subscription.card.terminationDate')
									: T('agreement.renewalDate')}
							</Text>
						) : null}
						<Text>{`${moment(childAgreement.metadata.agreementStartdate).format('L')} - ${moment(
							endDate
						).format('L')}`}</Text>
					</div>
				</div>
				<div className={classes.elem('rightColumn').b()}>
					<Text size="sm" color="grey-11">
						{isFirstAgreement
							? T('subscription.card.newAgreement')
							: hasIncreased
							? T('arrchange.type.INCREASE')
							: T('arrchange.type.DECREASE')}
					</Text>
					<Text color={hasIncreased ? 'success-4' : 'red'}>{salesChangeText}</Text>
				</div>
			</div>
			{!hideActions ? (
				<div className={classes.elem('actions').b()}>
					<ToolTipOrderRows
						interval={childAgreement.metadata.agreementIntervalPeriod}
						hasMRR={hasMRR}
						currency={childAgreement.currency}
						currencyRate={childAgreement.currencyRate}
						orderRows={childAgreement.orderRow}
					>
						<Icon color="grey-11" className={classes.elem('boxesIcon').b()} name="cubes" />
					</ToolTipOrderRows>
					{onDocumentClick ? (
						<Tooltip title={T('default.createDocument')}>
							<Icon
								color="grey-11"
								className={classes.elem('documentTemplates').b()}
								name="file"
								onClick={e => {
									e.stopPropagation();
									onDocumentClick();
								}}
							/>
						</Tooltip>
					) : null}
				</div>
			) : null}
		</div>
	);
};

export default CardChildRow;
