import React, { useEffect, useMemo, useState } from 'react';
import T from 'Components/Helpers/translate';
import {
	Avatar,
	Block,
	Column,
	Input,
	Label,
	Modal,
	ModalContent,
	ModalHeader,
	Row,
	Text,
	Title
} from '@upsales/components';
import BemClass from '@upsales/components/Utils/bemClass';
import { ModalProps } from '../Modals/Modals';
import { DangerButton, PrimaryButton } from '@upsales/components/Buttons';
import './RequestSupport.scss';
import { makeCancelable } from 'App/babel/helpers/promise';
import Self from 'App/resources/Self';
import logError from 'App/babel/helpers/logError';

type Props = ModalProps & {
	email: string;
	message: string;
	name: string;
};

const MIN_DAYS = 1;
const MAX_DAYS = 30;

const RequestSupport: React.FC<Props> = ({ className, close, email, name, message }) => {
	const classes = useMemo(() => new BemClass('RequestSupport', className), [className]);
	const [days, setDays] = useState(14);
	const [loading, setLoading] = useState(false);

	useEffect(() => {
		let approvePromise: { promise: Promise<void>; cancel: () => void };
		if (loading) {
			approvePromise = makeCancelable(Self.approveSupportRequest(days));

			approvePromise.promise
				.then(() => {
					// Improvement: Show animotion on accept complete and then close modal
					// Like: Thank you, {name} now has acces to your account (animated green check icon fades in)
					close();
				})
				.catch(e => {
					logError(e, 'Failed to approve support session');
					setLoading(false);
				});
		}
		return () => {
			approvePromise?.cancel();
		};
	}, [loading]);

	return (
		<Modal className={classes.b()} size="sm">
			<ModalHeader title={T('support.requestTitle')} />
			<ModalContent>
				<Row>
					<Column fixedWidth={50}>
						<Avatar email={email} initials={name} />
					</Column>
					<Column>
						<Title>{name}</Title>
						<Text>{T('support.requestText')}</Text>
					</Column>
				</Row>
				{message.length ? (
					<Block space="mtxl">
						<Label>{T('default.message')}</Label>
						<Text>{message}</Text>
					</Block>
				) : null}
				<Block space="mtxl mbxl">
					<Label>{T('support.requestDays')}</Label>
					<Input
						type="number"
						min={MIN_DAYS}
						max={MAX_DAYS}
						value={days.toString()}
						onChange={e => setDays(Math.min(MAX_DAYS, Math.max(MIN_DAYS, parseInt(e.target.value))))}
					/>
				</Block>
				<Row>
					<Column>
						<PrimaryButton block onClick={() => setLoading(true)} loading={loading}>
							{T('default.approve')}
						</PrimaryButton>
					</Column>
					<Column fixedWidth={12} />
					<Column>
						<DangerButton block onClick={() => close()}>
							{T('default.deny')}
						</DangerButton>
					</Column>
				</Row>
			</ModalContent>
		</Modal>
	);
};

export default RequestSupport;
