import React, { useEffect, useRef, useState } from 'react';
import ColumnChartWidgetBar from '../ColumnChartWidgetBar';
import { getTooltip, getTickPercentages } from '../../chartHelper';
import bemClass from '@upsales/components/Utils/bemClass';
import { Tooltip } from '@upsales/components';
import './ColumnChartWidgetBars.scss';
import { RCWidgetData, RCWidgetDataRowShape } from 'Resources/ReportWidget';
import ColumnChartWidgetScale from '../ColumnChartWidgetScale';
import type { RCDashboardWidget } from 'Resources/ReportDashboard';

type Props = {
	ticks: number[];
	row: RCWidgetDataRowShape;
	data: RCWidgetData;
	showGoal: boolean;
	scaleMax: number;
	height: number;
	loading: boolean;
	maxValue?: number;
	minValue?: number;
	fallbackHeight?: number; // This is, at least so far, only used for testing purposes, as the clientHeight is always 0 in testing, which breaks all the tests.
	zeroTickIndex?: number;
	onClick?: React.MouseEventHandler<HTMLDivElement>;
	tooltipContainer?: React.RefObject<HTMLElement>;
	thinMode?: boolean;
	drilldown: boolean;
	config: RCDashboardWidget;
	showExtraTick?: boolean;
	allowZeroHeight?: boolean;
	tooltipDistance?: number;
	disableTooltipIfNoProgress?: boolean;
};

const ColumnChartWidgetBars = ({
	row,
	data,
	showGoal,
	minValue = 0,
	ticks = [],
	fallbackHeight = 0,
	zeroTickIndex = 0,
	onClick,
	tooltipContainer,
	thinMode,
	showExtraTick,
	allowZeroHeight,
	tooltipDistance,
	disableTooltipIfNoProgress
}: Props) => {
	const classes = new bemClass('ColumnChartWidgetBars').mod({
		'has-data': !!data.total?.progress,
		'has-negative-values': minValue < 0
	});
	const [pixelHeight, setpixelHeight] = useState(fallbackHeight);

	const ref = useRef<HTMLDivElement>(null);

	const progress = row.progress ?? 0;
	const remaining = row.remaining ? Math.abs(row.remaining) : 0;
	const goal = row.goal ?? 0;
	const negative = progress < 0;
	const tickPercentages = getTickPercentages(ticks);
	const scaleHeight = Math.abs(ticks[0]) + Math.abs(ticks[ticks.length - 1]);
	const progressBlockPercent = (Math.abs(progress) / scaleHeight) * 100;
	const goalBlockPercent = (Math.abs(goal) / scaleHeight) * 100;
	const progressIsNull = row.progress === null;

	useEffect(() => {
		const currentHeight = ref?.current?.clientHeight || fallbackHeight;
		setpixelHeight(currentHeight);
	});

	// if the height of a bar is less than a pixel, it sometimes displays on the wrong side of the zero-line
	const tooLowToShowProperly = (progressBlockPercent / 100) * pixelHeight < 1;

	const progressCornerRounding =
		(progressBlockPercent / 100) * pixelHeight > 4 ? 4 : (progressBlockPercent / 100) * pixelHeight;
	const goalCornerRounding =
		(goalBlockPercent / 100) * pixelHeight > 4 ? 4 : Math.floor((goalBlockPercent / 100) * pixelHeight);
	const rowProgressLabel = negative ? row.colors?.negativeProgress?.label : row.colors?.progress?.label;
	const rowColor = negative ? row.colors?.negativeProgress?.colorVariable : row.colors?.progress?.colorVariable;
	const stripedColor = negative ? row.colors?.negativeProgress?.striped : row.colors?.progress?.striped;

	const tooltipTitle = getTooltip(
		progress,
		goal,
		remaining,
		data,
		showGoal,
		rowColor,
		rowProgressLabel,
		row,
		stripedColor
	);

	const negativeBarBottomStyling = `calc(${tickPercentages[zeroTickIndex] - progressBlockPercent}% + ${
		tooLowToShowProperly ? `0px` : `1px`
	})`;

	const tooLowToShowProperlyHeight = allowZeroHeight && progressIsNull ? '0px' : '1px';
	const barStyling = {
		backgroundColor: negative ? data.colors?.negativeProgress?.color : data.colors?.progress?.color,
		color: rowColor ?? undefined,
		height: tooLowToShowProperly ? tooLowToShowProperlyHeight : `${progressBlockPercent}%`,
		bottom: negative ? negativeBarBottomStyling : `${tickPercentages[zeroTickIndex]}%`,
		cornerRounding: progressCornerRounding,
		striped: stripedColor
	};

	const tooltipDisabled = disableTooltipIfNoProgress && progressIsNull;

	return (
		<div key={row.key} className={classes.b()} ref={ref} onClick={onClick}>
			<ColumnChartWidgetScale zeroTickIndex={zeroTickIndex} ticks={ticks} showExtraTick={showExtraTick} />
			<Tooltip
				html={true}
				title={tooltipTitle}
				disabled={tooltipDisabled}
				distance={tooltipDistance}
				theme="white"
				style={{ bottom: '0px' }}
				appendTo={() => (tooltipContainer?.current ? tooltipContainer.current : document.body)}
			>
				{/* Goal bar */}
				{showGoal && goal > 0 ? (
					<ColumnChartWidgetBar
						backgroundColor={data.colors?.goal.color}
						height={`${goalBlockPercent}%`}
						bottom={`${tickPercentages[zeroTickIndex]}%`}
						cornerRounding={{ roundedTop: true, roundedBottom: false, radius: goalCornerRounding }}
						thinMode={thinMode}
					/>
				) : null}
				{/* Progress bar */}
				<ColumnChartWidgetBar
					negative={negative}
					backgroundColor={barStyling.backgroundColor}
					color={barStyling.color}
					height={barStyling.height}
					bottom={barStyling.bottom}
					cornerRounding={{
						roundedTop: (!showGoal && !negative) || progress > goal,
						roundedBottom: negative,
						radius: progressCornerRounding
					}}
					thinMode={thinMode}
					striped={barStyling.striped}
					icons={row.icons}
				/>
			</Tooltip>
		</div>
	);
};

export default ColumnChartWidgetBars;
