import T from 'Components/Helpers/translate';
import React, { useEffect, useState } from 'react';
import { DrawerHeader, Input } from '@upsales/components';
import type Order from 'App/resources/Model/Order';
import { ProductSearchState } from '../ProductSearch';

type Props = {
	className: string;
	description: Order['description'];
	state: ProductSearchState;
	dispatch: React.Dispatch<Partial<ProductSearchState>>;
	close: () => void;
};

const Header = ({ className, description, state, dispatch, close }: Props) => {
	const [searchStr, setSearchStr] = useState(state.searchStr);

	useEffect(() => {
		const debounce = setTimeout(() => {
			dispatch({ searchStr });
		}, 300);

		return () => {
			if (debounce) {
				clearTimeout(debounce);
			}
		};
	}, [searchStr]);

	return (
		<DrawerHeader
			aria-label="Product Search Header"
			className={className}
			onHide={close}
			title={`${T('order.addProductsTo')} ${
				description?.length ? description : `<<${T('default.noDescription')}>>`
			}`}
		>
			<div id="product-search">
				<Input
					icon="search"
					placeholder={T('product.searchProducts')}
					value={searchStr}
					onChange={e => {
						setSearchStr(e.target.value);
					}}
				/>
			</div>
		</DrawerHeader>
	);
};

export default Header;
