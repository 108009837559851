import React from 'react';
import PropTypes from 'prop-types';

const propTypes = {
	config: PropTypes.object,
	type: PropTypes.string,
	object: PropTypes.object,
	coords: PropTypes.coords,
	getUser: PropTypes.func
};

// TODO: make this work.
// check if this should be awailable too?
class PostIframeRender extends React.Component {
	componentDidMount() {}

	render() {
		const { config, type, object, coords } = this.props;
		// to do
		const target = `ui-element-${config.integrationId}-${config.name}`;
		const iframeProps = {
			className: 'si-sidebar-iframe',
			src: '#',
			name: target,
			style: {
				display: 'none'
			}
		};

		const url = Tools.URL + Tools.API + 'function/standardIntegrationIframe/';
		const formProps = {
			action: url,
			method: 'POST',
			target: target
		};

		const frameData = {
			type: 'uiElement',
			data: JSON.stringify({
				name: config.name,
				object: object,
				user: this.getUser(),
				coords: coords
			}),
			typeId: type,
			integrationId: config.integrationId
		};

		const formBody = [];
		_.each(Object.keys(frameData), k => {
			const inputProps = {
				type: 'hidden',
				name: k,
				value: frameData[k]
			};

			formBody.push(React.createElement('input', inputProps));
		});

		const form = React.createElement('form', formProps, formBody);
		// form.submit();
		// HOW TO FIX DYNAMIC SUBMIT OF THE FORM?

		return React.createElement('iframe', iframeProps, form);
	}
}

PostIframeRender.propTypes = propTypes;
export default PostIframeRender;
