import { MailState } from 'App/babel/components/MailDrawers/MailContext';
import Mail, { MailRecipient, MailRecipients } from 'App/resources/Model/Mail';
import Contact, { ContactMail } from 'App/resources/Model/Contact';
import T from 'Components/Helpers/translate';
import { IntegrationConfig } from 'App/resources/AllIWant';
import logError from 'Helpers/logError';
import { openDrawer } from 'Services/Drawer';

//check if mailIntegration config is valid
export const mailIntegrationIsValid = async (mailIntegration: IntegrationConfig, onReject?: () => void) => {
	const customerId = Tools.AppService.getCustomerId();
	const data = {
		type: 'validateMailIntegration',
		typeId: '',
		data: {},
		integrationId: mailIntegration.id
	};
	try {
		const { data: validateResult } = await Tools.StandardIntegration.data(customerId).run(data);
		return !!validateResult?.valid;
	} catch (err: any) {
		//109 and 121 are standardintegration errors
		if ([109, 121].includes(err.data?.error?.errorCode)) {
			// if StandardIntegrationvallidate request fails, something is wrong with the setup.
			return false;
		} else {
			logError(err, 'Could not get a valid mail integration');
			return onReject?.();
		}
	}
};

const doesRecipientsExceedMaxSize = (recipients: MailRecipient[]) => {
	const MAX_SIZE = 150;
	let len = -1;
	// Because they are saved with a semicolon between each other we add +1 to all except the first one, hence len = -1;
	recipients.forEach(rec => {
		len += rec.email.length + 1;
	});
	return len > MAX_SIZE;
};

export const getTooltipIfRecipientsExceedMaxSize = (recipients: MailRecipients) => {
	if (doesRecipientsExceedMaxSize(recipients.cc)) {
		return T('mailDrawer.tooManyRecipientsCc');
	}
	if (doesRecipientsExceedMaxSize(recipients.bcc)) {
		return T('mailDrawer.tooManyRecipientsBcc');
	}
	return undefined;
};

type UserMail = { id: number; name: string; role?: unknown; email: string };
export const formatRecipients = (mailData: Mail) => {
	if (!mailData.recipients) {
		const cc = (mailData.cc ?? []).map(email => ({ email, type: 'other' })) as MailRecipient[];
		const bcc = (mailData.bcc ?? []).map(email => ({ email, type: 'other' })) as MailRecipient[];
		const contact: ContactMail | undefined = mailData.contact;
		const user: UserMail | undefined = mailData.users?.length ? mailData.users[0] : undefined;
		let recipient: MailRecipient;
		if (mailData.type === 'in' && user) {
			recipient = { id: user?.id, type: 'user', email: user.email, name: user?.name };
		} else if (mailData.type === 'out' && contact) {
			recipient = { id: contact?.id, type: 'contact', email: contact.email, name: contact.name };
		} else {
			recipient = { type: 'other', email: mailData.to };
		}
		mailData.recipients = {
			to: [recipient],
			cc: cc,
			bcc: bcc
		};
		return mailData;
	}

	mailData.recipients.to = mailData.recipients.to.map((recipient: MailRecipient) => ({
		...recipient,
		name: mailData.type === 'in' ? mailData.users[0]?.name || '' : mailData.contact?.name
	}));
	return mailData;
};

export const openNewMailWithContact = (contact?: Partial<Contact> | null, extraMailProps?: Partial<Mail>) => {
	if (!contact?.email) {
		openDrawer('NewSingleMail', { ...extraMailProps });
		return;
	}
	const { id, name, email, client, title } = contact;
	const to: MailRecipient = { id, name, email, client, type: 'contact', title };
	openDrawer('NewSingleMail', {
		recipients: { to: [to], cc: [], bcc: [] },
		client,
		...extraMailProps
	});
};

export const openNewMailDrawerWithCopiedData = (mail: Mail) => {
	// Wait so that the previous drawer is closed first
	setTimeout(() => {
		openDrawer('NewSingleMail', {
			subject: mail.subject,
			body: mail.body,
			attachments: mail.attachments
		});
	}, 400);
};

export const previewInBrowser = async (state: MailState) => {
	const {
		from,
		subject,
		body,
		recipients: { to, cc, bcc }
	} = state;
	const preview = window.open(
		'',
		'',
		'height=768,width=1024,scrollbars=yes,resizable=yes,toolbar=no,status=no,menu=no,titlebar=no,location=no,addressbar=no'
	) as Window;

	const recipientRow = (title: string, recipient: MailRecipient[]) => `<tr>
			<td>
				<b>${title}:</b>
			</td>
			<td>${recipient.map(r => ' ' + (r.name ?? r.email))}</td>
	</tr>`;

	const html = `<head>
		<style>
			body {
				margin: 0;
				border-top: 5px solid #002f65;
				color: #333;
				font-family: Helvetica;
				font-size: 14px;
			}
			#info h2 {
				color: #002f65;
				font-family: Helvetica;
				font-size: 22px;
				margin-bottom: 6px;
			}
			#info p{
				margin-top: 2px;
				margin-bottom: 2px;
			}
			#emailHeaders {
				font-size:12px;
			}
			#emailHeaders tr {
				border-bottom: 1px solid #f0f0f0;
			}
		</style>
	</head>
	<body>
		<table border=0 width="100%" cellpadding="0" cellspacing="0">
			<tr width="100%">
				<td width="100%" id="info" bgcolor="#fff">
					<font>
						<h2>
							${T('mail.preview')} ${T('mail.mail').toLowerCase()}
						</h2>
						<p>${T('mail.previewDisclaimer')}.</p>
					</font>
				</td>
			</tr>
			<tr width="100%">
				<td width="100%" id="emailHeaders">
					<br/>
					<table border=0 width="100%">
						<tr width="100%">
							<td>
								<b>${T('mail.from')}:</b>
							</td>
							<td>
								${from?.name}
							</td>
						</tr>
						${recipientRow(T('mail.to'), to)}
						${cc.length ? recipientRow(T('mail.cc'), cc) : ''}
						${bcc.length ? recipientRow(T('mail.bcc'), bcc) : ''}
						<td>
							<b>${T('mail.subject')}:</b>
						</td>
							<td>${subject}</td>
						</tr>
					</table>
					<br/>
				</td>
			</tr>
			<tr>
				<td id="previewBody">
					${body || ''}
				</td>
			</tr>
		</table>
	</body>`;

	var toggleStyle =
		' style="width: 50%; height: 40px; line-height: 40px; background-color: #fff; border: 1px solid #ccc; color: #888; cursor: pointer"';
	var toggles = `<div style="position: absolute; width: 100%; z-index: 10000; top: 0; left: 0; font-family: sans-serif;"><button type="button"
		${toggleStyle}
		onClick=document.getElementById("preview-iframe").style.width="100%"><b class="fa fa-desktop"></b>
		${T('ads.desktop')}
		</button>`;
	toggles += `<button type="button"'
		${toggleStyle}
		onClick=document.getElementById("preview-iframe").style.width="400px"><b class="fa fa-mobile" style="margin-right: 3px;"></b>
		${T('default.phone')}
		</button></div>`;
	preview.document.write(
		toggles +
			'<div style="width: 100%; height: 100%; display: flex; justify-content: center; position: absolute; left: 0;"><iframe id="preview-iframe" style="width: 100%; height: 100%;"></iframe></div>'
	);
	const iframe = preview.document.getElementById('preview-iframe') as any;
	const script = preview.document.createElement('script');
	script.src = 'https://use.fontawesome.com/01a8d84c9a.js';
	preview.document.head.appendChild(script);
	iframe.contentWindow.document.open();
	iframe.contentWindow.document.write(html);
	iframe.contentWindow.document.close();
};
