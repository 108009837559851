import SalesCoach from 'Resources/SalesCoach';
import logError from 'App/babel/helpers/logError';
import { replaceItem } from 'Store/helpers/array';
import SalesCoachChecklistItemResource from 'Resources/SalesCoachChecklistItems';
import { salesCoachTracker } from 'App/babel/helpers/Tracker';

const RESET_SALES_COACH = '[SalesCoach] Reset sales coach';
const SET_STATE = '[SalesCoach] Set state';
const SET_TYPE = '[SalesCoach] Set decisionMakers type';
const SET_FIELDS = '[SalesCoach] Set decisionMakers fields';
const SET_ONLY_APPOINTMENTS = '[SalesCoach] Set only appointments';
const SET_ACTIVE = '[SalesCoach] Set decision makers active';

export const initialState = {
	id: null,
	isNew: true,
	name: '',
	active: false,
	users: [],
	roles: [],
	assignmentType: 'users',
	isNameUnique: true,
	budget: {
		active: null,
		entireSalesProcess: true,
		stages: {}
	},
	nextStep: {
		active: null,
		onlyAppointments: true
	},
	solution: {
		active: null,
		entireSalesProcess: true,
		stages: {}
	},
	decisionMakers: {
		active: null,
		entireSalesProcess: true,
		stages: {},
		titleCategories: []
	},
	decisionMakersPro: {
		typeOfDecisionMakers: 'titleCategories',
		fields: []
	},
	timeframe: {
		active: null,
		entireSalesProcess: true,
		stages: {}
	},
	allSalesCoaches: [],
	checklist: [],
	checklistItems: []
};

/*********** Actions **********/

export const resetSalesCoach = () => dispatch => {
	dispatch({ type: RESET_SALES_COACH });
};

export const loadCheckboxItems = () => async (dispatch, getState) => {
	try {
		const id = getState().SalesCoachReducer.id;
		const filter = id ? { salesCoachId: `ne:${id}` } : {};
		const { data: checklistItems } = await SalesCoachChecklistItemResource.find(filter);
		dispatch({ type: SET_STATE, checklistItems });
	} catch (e) {
		logError(e, 'loadCheckboxItems failed');
	}
};

export const setSolution = solution => dispatch => {
	dispatch({ type: SET_STATE, solution });
};

export const toggleChecklistItem = checklistItem => (dispatch, getState) => {
	const checklist = getState().SalesCoachReducer.checklist;
	const idx = checklist.findIndex(selectedChecklistItem => selectedChecklistItem.id === checklistItem.id);

	if (idx !== -1) {
		const updated = { ...checklistItem, active: !checklistItem.active };
		dispatch({ type: SET_STATE, checklist: replaceItem(checklist, idx, updated) });
	}
};

export const setChecklistItem = checklistItem => (dispatch, getState) => {
	const { checklist } = getState().SalesCoachReducer;
	const idx = checklist.findIndex(selectedChecklistItem => selectedChecklistItem.id === checklistItem.id);

	if (idx !== -1) {
		dispatch({ type: SET_STATE, checklist: replaceItem(checklist, idx, checklistItem) });
	} else {
		dispatch({ type: SET_STATE, checklist: [...checklist, checklistItem] });
	}
};

export const removeChecklistItem = checklistItem => (dispatch, getState) => {
	const checklist = getState().SalesCoachReducer.checklist;
	const filtered = checklist.filter(selectedChecklistItem => selectedChecklistItem.id !== checklistItem.id);
	dispatch({ type: SET_STATE, checklist: filtered });
};

export const setNextStep = nextStep => dispatch => {
	dispatch({ type: SET_STATE, nextStep });
};

export const setBudget = budget => dispatch => {
	dispatch({ type: SET_STATE, budget });
};

export const setActiveIfNew = () => (dispatch, getState) => {
	const { isNew } = getState().SalesCoachReducer;
	if (isNew) {
		dispatch({ type: SET_STATE, active: true });
	}
};

export const setTimeframe = timeframe => dispatch => {
	dispatch({ type: SET_STATE, timeframe });
};

export const setDecisionMakers = decisionMakers => dispatch => {
	dispatch({ type: SET_STATE, decisionMakers });
};

export const setUsers = users => dispatch => {
	dispatch({ type: SET_STATE, users });
};

export const setRoles = roles => dispatch => {
	dispatch({ type: SET_STATE, roles });
};

export const setAssignmentType = assignmentType => dispatch => {
	dispatch({ type: SET_STATE, assignmentType });
};

export const setDecisionMakersType = type => dispatch => {
	dispatch({ type: SET_TYPE, typeOfDecisionMakers: type });
};

export const setFields = fields => dispatch => {
	dispatch({ type: SET_FIELDS, fields });
};

export const saveDecisionMakers = () => async (dispatch, getState) => {
	const { SalesCoachReducer } = getState();
	try {
		await SalesCoach.saveDecisionMakers(
			SalesCoachReducer.id,
			SalesCoachReducer.decisionMakersPro.fields,
			SalesCoachReducer.decisionMakersPro.typeOfDecisionMakers,
			SalesCoachReducer.name
		);
	} catch (e) {
		logError(e, 'Failed to save decision makers');
	}
};

export const setName = (name, allSalesCoaches = []) => (dispatch, getState) => {
	const id = getState().SalesCoachReducer.id;
	const alreadyExists = allSalesCoaches.find(
		coach => id !== coach.id && coach.name.toLowerCase() === name.toLowerCase()
	);

	if (alreadyExists) {
		dispatch({ type: SET_STATE, isNameUnique: false });
	} else {
		dispatch({ type: SET_STATE, isNameUnique: true });
	}
	dispatch({ type: SET_STATE, name });
};

export const getAllSalesCoaches = () => async dispatch => {
	try {
		const res = await SalesCoach.find({ sort: '-active, name' });
		dispatch({ type: SET_STATE, allSalesCoaches: res.data });
	} catch (e) {
		logError(e, 'Failed to fetch SalesCoaches');
	}
};

const trackSalesCoach = salesCoach => {
	const { active: activeBudget } = salesCoach.budget;
	const { active: activeSolution } = salesCoach.solution;
	const { active: activeTimeframe } = salesCoach.timeframe;
	const { active: activeDecisionMakers } = salesCoach.decisionMakers;
	const { active: activeNextStep, onlyAppointments } = salesCoach.nextStep;

	salesCoachTracker.track(salesCoachTracker.events.SAVED_SALES_COACH, {
		budget: !!activeBudget,
		nextStep: !!activeNextStep,
		solution: !!activeSolution,
		timeframe: !!activeTimeframe,
		decisionMakers: !!activeDecisionMakers,
		onlyAppointments: !!onlyAppointments
	});
};

export const save = coach => async (dispatch, getState) => {
	try {
		const salesCoach = coach || { ...getState().SalesCoachReducer };
		const { isNameUnique } = salesCoach;
		if (!isNameUnique) {
			return;
		}

		salesCoach.checklist = salesCoach.checklist.map(checklistItem => {
			if (checklistItem.id > 0) {
				return checklistItem;
			}
			return { ...checklistItem, id: undefined };
		});
		const {
			data: { id }
		} = await SalesCoach.save(salesCoach);
		trackSalesCoach(salesCoach);
		dispatch({ type: SET_STATE, id });
	} catch (e) {
		logError(e, 'Failed to save SalesCoach');
	}
};

export const toggleActive = salesCoach => async dispatch => {
	salesCoach.active = !salesCoach.active;
	await dispatch(save(salesCoach));
	dispatch(getAllSalesCoaches());
};

export const setSalesCoach = salesCoach => dispatch => {
	dispatch({ type: SET_STATE, ...salesCoach });
	dispatch({ type: SET_STATE, isNew: false });
};

export const setDecisionMakersActive = active => dispatch => {
	dispatch({ type: SET_ACTIVE, active });
};

const ACTION_HANDLERS = {
	[SET_STATE]: (state, { ...rest }) => ({ ...state, ...rest }),
	[RESET_SALES_COACH]: state => ({ ...initialState, allSalesCoaches: state.allSalesCoaches }),
	[SET_ONLY_APPOINTMENTS]: (state, { onlyAppointments }) => ({
		...state,
		nextStep: { ...state.nextStep, onlyAppointments }
	}),
	[SET_TYPE]: (state, { typeOfDecisionMakers }) => ({
		...state,
		decisionMakersPro: { ...state.decisionMakersPro, typeOfDecisionMakers }
	}),
	[SET_FIELDS]: (state, { fields }) => ({
		...state,
		decisionMakersPro: { ...state.decisionMakersPro, fields }
	}),
	[SET_ACTIVE]: (state, { active }) => ({
		...state,
		decisionMakers: { ...state.decisionMakers, active }
	})
};

export default (state = { ...initialState }, action) => {
	const handler = ACTION_HANDLERS[action.type];
	return handler ? handler(state, action) : state;
};
