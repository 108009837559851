import React, { useEffect, useMemo, useState } from 'react';
import {
	Headline,
	Input,
	Loader,
	NumberInput,
	Table,
	TableColumn,
	TableHeader,
	TableRow,
	Text,
	Tooltip
} from '@upsales/components';
import bemClass from '@upsales/components/Utils/bemClass';
import { currencyFormat } from 'App/babel/components/Filters/Currencies';
import T from 'Components/Helpers/translate';
import { getTierFromQuantity, getTierPriceByCurrency } from 'App/babel/helpers/order';
import { useSelector } from 'react-redux';
import { RootState } from 'Store/index';
import './PotentialOpportunityValue.scss';
import Product from 'App/resources/Model/Product';
import PlanNextStepDropDown from './PlanNextStepDropDown';
import Client from 'App/resources/Model/Client';
import { getCMWithRROption } from 'App/helpers/salesModelHelpers';

export type Props = {
	product: Pick<
		Product,
		'name' | 'id' | 'currencies' | 'listPrice' | 'recurringInterval' | 'tiers' | 'isRecurring'
	> | null;
	client: Pick<Client, 'id' | 'name' | 'createRights'> | null;
};

const PotentialOpportunityValue = ({ product, client }: Props) => {
	const classes = new bemClass('PotentialOpportunityValue');
	const metadata = useSelector((state: RootState) => state.App.metadata);
	const currency = metadata?.defaultCurrency.iso ?? '';
	const lang = {
		potentialOpportunityValue: T('reportcenter.potentialOpportunityValue'),
		product: T('default.product'),
		quantityAndPrice: T('subscription.modal.summary.orderRows.quantityPrice'),
		sum: T('default.sum'),
		salesModelOption: T(
			metadata?.params.SalesModelOption === 'arr' || getCMWithRROption() === 'arr' ? 'default.arr' : 'default.mrr'
		)
	};

	const [quantity, setQuantity] = useState<number>(1);
	const [loading, setLoading] = useState<boolean>(true);

	useEffect(() => {
		if (product && client) {
			setLoading(false);
		}
	}, [product, client]);

	const hasRecurringInterval = useMemo(() => {
		return metadata?.params.SalesModel === 'rr' && product?.recurringInterval && product?.isRecurring;
	}, [product]);

	const tier = useMemo(() => {
		if (product?.tiers && product.tiers.length) {
			return getTierFromQuantity(product.tiers, quantity);
		}
	}, [product, quantity]);

	const price = useMemo(() => {
		let price = product?.currencies?.find(
			(c: { currency: string; price: number; purchaseCost: number }) => c.currency === currency
		)?.price;
		if (price == null) {
			// Older products do not automatically fill the currencies array with 0 values when created
			const masterCurrency = metadata?.customerCurrencies.find(c => c.masterCurrency)?.iso;
			if (currency !== masterCurrency) {
				price = 0;
			} else {
				price = product?.listPrice;
			}
		}
		if (tier) {
			price = getTierPriceByCurrency(tier.tier, currency);
		}
		if (hasRecurringInterval && product?.recurringInterval && product.recurringInterval > 0) {
			const monthlyPrice = (price || 0) / product.recurringInterval;
			if (metadata?.params.SalesModelOption === 'arr') {
				return monthlyPrice * 12;
			}
			return monthlyPrice;
		}
		return price ?? 0;
	}, [product, tier, hasRecurringInterval]);

	const sum = useMemo(() => {
		if (tier && tier.tier.isTotalPrice) {
			return price;
		}
		return price * quantity;
	}, [product, quantity, price]);

	return (
		<div className={classes.b()}>
			<Headline size={'xs'}>{lang.potentialOpportunityValue}</Headline>
			{!loading ? (
				<>
					<Table>
						<TableHeader
							columns={[{ title: lang.product }, { title: lang.quantityAndPrice }, { title: lang.sum }]}
						></TableHeader>
						<TableRow key={product?.id}>
							<TableColumn>
								<Tooltip title={product?.name}>
									<Input placeholder={`${product?.name}`} disabled />
								</Tooltip>
							</TableColumn>
							<TableColumn>
								<div className={classes.elem('quantity-wrapper').b()}>
									<NumberInput min={0} value={quantity} onChange={v => setQuantity(v ?? 1)} />
									<Input value={currencyFormat(price, currency, undefined, 2, 0)} disabled />
								</div>
							</TableColumn>
							<TableColumn>
								<Text bold>
									{currencyFormat(sum, currency, undefined, 2, 0)}
									{hasRecurringInterval ? ` (${lang.salesModelOption})` : null}
								</Text>
							</TableColumn>
						</TableRow>
					</Table>
					{client ? <PlanNextStepDropDown client={client} /> : null}
				</>
			) : (
				<div className={classes.elem('loader').b()}>
					<Loader noU />
				</div>
			)}
		</div>
	);
};

export default PotentialOpportunityValue;
