import { StandardFieldConfig } from 'App/resources/AllIWant';
import { OrderCustomField } from 'App/resources/Model/Order';

import SalesCoach from 'Resources/SalesCoach';
import SalesCoachType from 'App/resources/Model/SalesCoach';
import RequestBuilder, { comparisonTypes } from 'Resources/RequestBuilder';
import logError from 'Helpers/logError';
import OrderStage from 'App/resources/Model/OrderStage';
import { CustomFieldWithStages, EntityCustomField } from 'App/resources/Model/CustomField';

export enum VALIDATION {
	MISSING_VALUE = 'MissingValue'
}

export type ValidationError = {
	error: VALIDATION;
	field: StandardFieldConfig | CustomFieldWithStages;
};

type ValidationEntity = {
	id?: number;
	stage?: OrderStage;
	closeDate?: Date | null;
	user?: { id: number; role: { id: number; name: string } | null };
	custom: EntityCustomField[];
};

export type SalesCoachField = StandardFieldConfig & {
	selectOptions: SalesCoachType[];
};

const isActiveAndRequiredField = (field: StandardFieldConfig) => {
	const isActive = !!field.active && !field.disabled;
	const isRequired = !!field.required;
	const isEditable = !!field.editable;
	return !!isActive && isRequired && isEditable;
};

const getRequiredStandardFieldsForEntity = (entityType: string): StandardFieldConfig[] => {
	const standardFields = Tools.AppService.getMetadata().standardFields[entityType];
	if (!standardFields) {
		return [];
	}

	return Object.values(standardFields).reduce((requiredFields: StandardFieldConfig[], field: StandardFieldConfig) => {
		if (isActiveAndRequiredField(field)) {
			requiredFields.push(field);
		}
		return requiredFields;
	}, [] as StandardFieldConfig[]);
};

const validateRequiredFields = (fieldsToValidate: StandardFieldConfig[], entity: ValidationEntity) => {
	return fieldsToValidate.reduce((validationErrors, field) => {
		const fieldValue = entity[field.field as keyof ValidationEntity];
		const missingValue = Array.isArray(fieldValue) ? !fieldValue.length : !fieldValue;

		if (missingValue) {
			validationErrors.push({ error: VALIDATION.MISSING_VALUE, field });
		}
		return validationErrors;
	}, [] as ValidationError[]);
};

const constructSalesCoachField = (entity: ValidationEntity, salesCoaches: SalesCoachType[]): SalesCoachField => ({
	id: entity?.id ?? 0,
	name: 'Sales process',
	active: true,
	group: 'standard',
	canHide: false,
	canMakeRequired: false,
	required: true,
	tooltip: null,
	nameTag: 'salesCoach',
	field: 'salesCoach',
	disabled: false,
	editable: true,
	sortOrder: 0,
	selectOptions: salesCoaches as any
});

const getRequiredSalesCoachField = async (entity: ValidationEntity) => {
	if (entity.closeDate) {
		return null;
	}

	const salesCoachFilter = new RequestBuilder();
	salesCoachFilter.addFilter({ field: 'active' }, comparisonTypes.Equals, true);
	try {
		let { data: salesCoaches } = await SalesCoach.find(salesCoachFilter.build());

		// imo this should be done on the backend, but since it uses order.user.id, which is not available on the backend, it is done here.
		salesCoaches = salesCoaches.filter(salesCoach => {
			const salesCoachUsers = salesCoach.users;
			const salesCoachRoles = salesCoach.roles;
			// If both lists are empty then by default it is available for all
			if (salesCoachUsers?.length === 0 && salesCoachRoles?.length === 0) {
				return true;
			} else {
				const includesUser = salesCoachUsers?.includes(entity.user?.id ?? -1);
				const includesRole = salesCoachRoles?.includes(entity.user?.role?.id ?? -1);

				if (includesUser || includesRole) {
					return true;
				}
			}
			return false;
		});

		if (!salesCoaches.length) {
			return null;
		}
		return constructSalesCoachField(entity, salesCoaches as unknown as SalesCoachType[]);
	} catch (err) {
		logError(err, 'Failed to get sales coaches for validation');
		return null;
	}
};

export const requiredStandardFieldsValidator = async (entityType: string, entity: ValidationEntity) => {
	const requiredStandardFields = getRequiredStandardFieldsForEntity(entityType);
	let requiredFields = [...requiredStandardFields];

	if (entityType === 'Order') {
		const requiredSalesCoachField = await getRequiredSalesCoachField(entity);
		if (requiredSalesCoachField) {
			requiredFields = [...requiredFields, requiredSalesCoachField];
		}
	}

	requiredFields = requiredFields.filter(Boolean) as StandardFieldConfig[];

	if (!requiredFields.length) {
		return [];
	}
	return validateRequiredFields(requiredFields, entity);
};

const isActiveAndRequiredCustomField = (entity: ValidationEntity, field: CustomFieldWithStages) => {
	let isRequiredOnStage = false;
	const hasStages = field?.stages && entity?.stage?.id;

	if (hasStages) {
		const hasOrderCustomStages = Tools.FeatureHelper.isAvailable(Tools.FeatureHelper.Feature.ORDER_CUSTOM_STAGES);
		isRequiredOnStage =
			hasOrderCustomStages &&
			!!field.stages?.find(fieldStage => {
				const isCurrentStage = entity.stage!.id === fieldStage.id;
				const fieldIsRequiredOnStage = fieldStage.required;
				return isCurrentStage && fieldIsRequiredOnStage;
			});
	}

	const isVisible = !!field.visible;
	const isRequired = !!field.obligatoryField || isRequiredOnStage;
	const isApplicable = !!field.$hasAccess;

	return isVisible && isRequired && isApplicable;
};

const isFieldHiddenForUser = (roles: OrderCustomField['roles']) => {
	const loggedInUser = Tools.AppService.getSelf();
	if (loggedInUser.administrator) {
		return false;
	}
	if (!loggedInUser.role) {
		return true;
	}
	const roleIdsWhichCanSeeThisField = roles.map(role => role.id);
	const canSeeField = roleIdsWhichCanSeeThisField.some((roleId: number) => loggedInUser.role?.id === roleId);
	return !canSeeField;
};

export const requiredCustomFieldsValidator = (entity: ValidationEntity, customFields: CustomFieldWithStages[]) => {
	if (!customFields?.length) {
		return [];
	}

	return customFields.reduce((validationErrors: ValidationError[], customField: CustomFieldWithStages) => {
		const customValue: EntityCustomField | undefined = entity.custom?.find(
			field => field.fieldId === customField.id
		);

		if (customField?.roles?.length) {
			const fieldIsHiddenForUser = isFieldHiddenForUser(customField.roles);
			if (fieldIsHiddenForUser) {
				return validationErrors;
			}
		}
		if (!isActiveAndRequiredCustomField(entity, customField)) {
			return validationErrors;
		}
		const missingValue = !customValue?.value;
		if (missingValue) {
			validationErrors.push({ error: VALIDATION.MISSING_VALUE, field: customField });
		}

		return validationErrors;
	}, [] as ValidationError[]);
};

const validateRequiredEntityFields = async (
	entityType: string,
	entity: ValidationEntity,
	customFields: CustomFieldWithStages[] = []
) => {
	const standardFieldsValidationErrors = await requiredStandardFieldsValidator(entityType, entity);
	if (!customFields.length) {
		return standardFieldsValidationErrors;
	}
	const customFieldsValidationErrors = requiredCustomFieldsValidator(entity, customFields);
	return [...standardFieldsValidationErrors, ...customFieldsValidationErrors];
};

export default validateRequiredEntityFields;
