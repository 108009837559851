import React from 'react';
import PropTypes from 'prop-types';
import { Text } from '@upsales/components';
import t from 'Components/Helpers/translate';
import './FindProspectsBranchSubtitle.scss';
import bemClass from '@upsales/components/Utils/bemClass';

const FindProspectsBranchSubtitle = ({ data, rootDuns, size = 'md', onClick, total, unknownTotal }) => {
	const classes = new bemClass('FindProspectsBranchSubtitle');
	let totalAccounts = 0;
	let branches = 0;

	if (total === null || total === undefined) {
		totalAccounts = data ? data.length : 0;
	} else {
		totalAccounts = total;
	}

	if (unknownTotal === null || unknownTotal === undefined) {
		const groupedByParent = _.groupBy(data, 'parentDuns');
		if (!groupedByParent[null]) {
			groupedByParent[null] = [];
		}

		branches = _.reduce(
			groupedByParent[null],
			(result, account) => {
				if (parseInt(account.dunsNo) !== parseInt(rootDuns)) {
					result++;
				}

				return result;
			},
			0
		);
	} else {
		branches = unknownTotal;
	}

	let branchesText;

	if (branches > 0) {
		branchesText = (
			<span>
				<Text size={size} color="grey-10">
					{t('default.with').toLowerCase()}
				</Text>
				<Text
					size={size}
					color="grey-13"
					className={classes.elem('clickable').b()}
					onClick={() => onClick('unknown')}
				>{`${branches} ${(branches === 1 ? t('account.branch') : t('account.branches')).toLowerCase()}`}</Text>
			</span>
		);
	}
	return (
		<div className={classes.b()}>
			<Text size={size} color="grey-10">
				{t('account.companyGroupContains')}
			</Text>
			<Text
				size={size}
				color="grey-13"
				className={classes.elem('clickable').b()}
				onClick={() => onClick('found')}
			>{`${totalAccounts} ${t('default.accounts').toLowerCase()}`}</Text>
			{branchesText}
		</div>
	);
};

FindProspectsBranchSubtitle.propTypes = {
	data: PropTypes.arrayOf(PropTypes.object).isRequired,
	rootDuns: PropTypes.number,
	size: PropTypes.oneOf(['sm', 'md']),
	onClick: PropTypes.func.isRequired
};

FindProspectsBranchSubtitle.defaultProps = {
	onClick: () => {},
	data: []
};

export default FindProspectsBranchSubtitle;
