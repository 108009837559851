import React, { useEffect, useRef, useState } from 'react';
import { ConnectedProps, connect } from 'react-redux';
import { Title, Text, Icon } from '@upsales/components';
import { startCopyEvent } from 'Store/actions/SocialEventActions';
import './CopySocialEvent.scss';
import EditSocialEventCopySvg from './EditSocialEventCopySvg';
import BemClass from '@upsales/components/Utils/bemClass';
import { useTranslation } from 'Components/Helpers/translate';
import { CancelablePromise, makeCancelable } from '@upsales/components/Utils/CancelablePromise';
import { PrimaryButton } from '@upsales/components/Buttons';
import { RootState } from 'Store/index';
import { ModalProps } from 'App/components/Modals/Modals';
import { SlideFade } from '@upsales/components/animations';

const mapStateToProps = (state: RootState) => ({
	copying: state.SocialEvent.copyingEvent
});

const mapDispatchToProps = {
	startCopyEvent
};

const connector = connect(mapStateToProps, mapDispatchToProps);

type Props = ModalProps & ConnectedProps<typeof connector>;

const CopySocialEvent = ({ className, close, copying, startCopyEvent }: Props) => {
	const [visible, setVisible] = useState(false);
	const { t } = useTranslation();
	const copyRequest = useRef<CancelablePromise | null>(null);

	const classes = new BemClass('CopySocialEvent', className);
	classes.add('FullScreenModal');

	useEffect(() => {
		const delay = setTimeout(() => setVisible(true), 300);
		return () => {
			copyRequest.current?.cancel();
			clearTimeout(delay);
		};
	}, []);

	const onCopy = () => {
		copyRequest.current = makeCancelable(startCopyEvent());

		// eslint-disable-next-line promise/catch-or-return
		copyRequest.current.promise.finally(() => close());
	};

	return (
		<div className={classes.b()}>
			<button className="FullScreenModal__close" onClick={() => close()}>
				<Icon name="times" />
			</button>

			<SlideFade visible={visible} direction="bottom">
				<div className={classes.elem('inner').b()}>
					<EditSocialEventCopySvg />
					<Title size="lg" className={classes.elem('title').b()}>
						{t('socialEvent.duplicateEvent')}
					</Title>
					<Text size="lg" color="grey-10" className={classes.elem('text').b()}>
						{t('socialEvent.duplicateEventInfo')}
					</Text>
					<PrimaryButton size="lg" loading={copying} onClick={onCopy}>
						{t('socialEvent.duplicateEvent')}
					</PrimaryButton>
				</div>
			</SlideFade>
		</div>
	);
};

export const detached = CopySocialEvent;
const Component = connector(CopySocialEvent);
export default Component;
