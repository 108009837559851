import { FilterConfig } from 'App/babel/filterConfigs/FilterConfig';
import { FilterPreset, generateFilter } from '../filterGenerators';
import ComparisonTypes from 'Resources/ComparisonTypes';
import AccessType from 'App/enum/AccessType';
import T from 'Components/Helpers/translate';
import { ACTIVITY_PRIORITY } from 'App/babel/enum/activity';
import { comparisonTypes } from 'Resources/RequestBuilder';
import EasyBookingSettings from 'Resources/EasyBookingSettings';
import AppService from 'App/legacy/AppService';

export default (): { [key: string]: FilterConfig } => ({
	Date: generateFilter(
		FilterPreset.Date,
		{
			field: 'date',
			title: 'default.date'
		},
		'standard'
	),
	RegDate: generateFilter(
		FilterPreset.Date,
		{
			field: 'regDate',
			title: 'default.regDate'
		},
		'past'
	),
	CloseDate: generateFilter(
		FilterPreset.Date,
		{
			field: 'closeDate',
			title: 'default.closeDate'
		},
		'standard'
	),
	Priority: {
		field: 'priority',
		title: 'activity.priority',
		type: 'raw',
		displayType: 'radio',
		generate: function () {
			return {
				value: [ACTIVITY_PRIORITY.NONE]
			};
		},
		build: function (filter, rb, getField) {
			if (filter.value === ACTIVITY_PRIORITY.HIGH || filter.value[0] === ACTIVITY_PRIORITY.HIGH) {
				rb.addFilter(getField('priority'), comparisonTypes.Equals, filter.value);
			}
		},
		options: [
			{ text: 'default.all', value: ACTIVITY_PRIORITY.NONE, inactive: true },
			{ text: 'default.important', value: ACTIVITY_PRIORITY.HIGH }
		]
	},
	ActivityType: {
		type: 'raw',
		inputType: 'radio',
		displayType: 'radio',
		title: 'default.activityType',
		options: [
			{ value: 'all', text: T('default.all'), inactive: true },
			{ value: 'todo', text: T('default.todo') },
			{ value: 'phonecall', text: T('default.phonecall') },
			{ value: 'appointment', text: T('default.appointment') }
		],
		build: ({ value }, rb) => {
			const todoTypes = AppService.getTodoTypes();
			if (value === null) {
				return;
			} else if (value === 'todo') {
				rb.addFilter({ field: 'activityType.id' }, ComparisonTypes.Equals, todoTypes.TODO!.id);
			} else if (value === 'phonecall') {
				rb.addFilter({ field: 'activityType.id' }, ComparisonTypes.Equals, todoTypes.PHONE_CALL!.id);
			} else {
				rb.addFilter({ field: 'isAppointment' }, ComparisonTypes.Equals, true);
			}
		}
	},
	User: generateFilter(
		FilterPreset.User,
		{
			field: 'user.id',
			title: 'default.users'
		},
		AccessType.ACTIVITY
	),
	UserRole: generateFilter(FilterPreset.Role, { field: 'user.role.id' }),
	RegBy: generateFilter(FilterPreset.User, { title: 'default.createdBy', field: 'regBy' }),
	Contact: generateFilter(FilterPreset.ResourceLazyList, { searchField: 'title', field: 'contact.id' }, 'contact'),
	Campaign: generateFilter(FilterPreset.Campaign, { field: 'project.id' }),
	Status: {
		title: 'default.status',
		type: 'raw',
		inputType: 'radio',
		displayType: 'radio',
		options: [
			{ text: 'default.all', value: null, inactive: true },
			{ text: 'default.open', value: false },
			{ text: 'default.closed', value: true }
		],
		build: ({ value }, rb) => {
			if (value === null) {
				return;
			} else if (value === true) {
				rb.addFilter({ field: 'closeDate' }, ComparisonTypes.NotEquals, null);
			} else {
				rb.addFilter({ field: 'closeDate' }, ComparisonTypes.Equals, null);
			}
		}
	},
	Description: generateFilter(FilterPreset.Text, {
		field: 'description',
		title: 'default.description'
	}),
	AccountManagerFilters: generateFilter(
		FilterPreset.User,
		{
			field: 'client.user.id',
			title: 'default.accountManagers'
			// TODO: this filter doesn't work
			// selectMissingRow: {
			// 	item: {
			// 		id: null,
			// 		name: T('default.noAccountManager')
			// 	}
			// }
		},
		AccessType.ACTIVITY
	),
	Notes: generateFilter(FilterPreset.Notes),
	HasOpportunity: generateFilter(
		FilterPreset.HasOrder,
		{
			title: 'default.opportunity'
		},
		'opportunity'
	),

	/* Account filters */
	Client: generateFilter(
		FilterPreset.ResourceLazyList,
		{
			field: 'client.id',
			title: 'default.account',
			parent: 'default.account'
		},
		'client'
	),
	AccountCategory: generateFilter(FilterPreset.ClientCategory, {
		field: 'client.category.id',
		parent: 'default.account'
	}),
	AddressFilters: generateFilter(
		FilterPreset.HistoryFilter,
		{
			field: 'client',
			title: 'default.addresses',
			parent: 'default.account',
			entity: undefined
		},
		'address',
		{ fieldPrefix: 'client' }
	),
	JourneyStep: generateFilter(FilterPreset.JourneyStep, { parent: 'default.account', field: 'client.journeyStep' }),

	/* Hidden filters */
	hasDate: generateFilter(FilterPreset.RawRadio, {
		title: 'hasDate',
		hide: true,
		build: (filter, rb) => {
			const val = filter.value;

			if (val == null) {
				return;
			} else if (val === true) {
				rb.addFilter({ field: 'date' }, ComparisonTypes.NotEquals, null);
			} else {
				rb.addFilter({ field: 'date' }, ComparisonTypes.Equals, null);
			}
		}
	}),

	/* Appointment filters */
	Outcome: generateFilter(FilterPreset.List, {
		field: 'outcome',
		title: 'default.outcome',
		dataPromise: $q => {
			return $q.when({
				data: [
					{ id: 'planned', name: T('filter.history.planned') },
					{ id: 'completed', name: T('filter.history.completed') },
					{ id: 'notCompleted', name: T('filter.history.notCompleted') }
				]
			});
		},
		parent: 'filters.columns.appointment'
	}),
	EasyBooing: {
		field: 'easyBooking.id',
		type: 'raw',
		inputType: 'select',
		displayType: 'list',
		multiple: true,
		dataPromise: function () {
			return EasyBookingSettings.find({});
		},
		generate: function () {
			return {
				comparisonType: 'Equals',
				value: []
			};
		},
		build: function (filter, rb, getField) {
			const pageId = filter.value;
			const group = rb.groupBuilder();

			group.addFilter(getField('source.type'), ComparisonTypes.Equals, 'easyBooking');
			group.addFilter(getField('source.id'), ComparisonTypes.Equals, pageId);
			group.done();
		},
		searchField: 'internalName',
		displayText: 'internalName',
		multiComparitors: true,
		search: false,
		title: 'default.easyBooking',
		listTitle: 'default.easyBooking',
		parent: 'filters.columns.appointment'
	},
	AppointmentType: {
		field: 'activityType.id',
		inputType: 'select',
		multiple: true,
		dataPromise: function (customerId, $q) {
			return $q.when({ data: AppService.getActivityTypes('appointment', true) });
		},
		type: 'list',
		searchField: 'name',
		displayText: 'name',
		multiComparitors: true,
		search: false,
		title: 'default.appointmentTypes',
		listTitle: 'default.appointmentType',
		parent: 'filters.columns.appointment'
	},
	AppointmentOutcome: {
		title: 'appointmentOutcome',
		hide: true,
		field: 'outcome',
		type: 'list',
		inputType: 'select',
		dataPromise: function (customerId, $q) {
			return $q.when({ data: AppService.getStaticValuesPromise('appointmentOutcome') });
		},
		searchField: 'text',
		displayText: item => item.text,
		comparisonType: 'MatchExact',
		parent: 'filters.columns.appointment'
	},

	ListSearch: generateFilter(FilterPreset.ListSearch, {}, ['client.name', 'contact.name', 'description'])
});
