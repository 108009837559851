import React, { useMemo, useState } from 'react';
import BemClass from '@upsales/components/Utils/bemClass';
import './LeadDrawer.scss';
import RequestBuilder, { comparisonTypes } from 'Resources/RequestBuilder';
import logError from 'App/babel/helpers/logError';
import EventAttributes from 'App/babel/attributes/EventAttributes';
import store from 'Store/index';
import Event from 'App/resources/Model/Event';
import { ContactEvent } from 'App/resources/Model/Contact';
import Client from 'App/resources/Model/Client';
import ClientResource from 'App/resources/Client';
import EventResource from 'App/resources/Event';
import moment from 'moment';
import T from 'Components/Helpers/translate';
import Avatar from 'Components/Avatar';
import {
	Text,
	Icon,
	DrawerHeader,
	Column,
	Row,
	Title,
	Block,
	TableColumn,
	Table,
	TableRow,
	TableHeader,
	Link,
	Tooltip,
	IconName
} from '@upsales/components';
import { openDrawer } from 'Services/Drawer';

type LeadDrawerProps = {
	item: any;
	className: string;
	close: () => void;
	isContact: boolean;
};

export default ({ item, className, isContact, close }: LeadDrawerProps) => {
	const [events, setEvents] = useState<Event[]>([]);
	const [client, setClient] = useState<Client>();
	const { customerId } = store.getState().App;
	const [totalEvents, setTotalEvents] = useState(0);
	const classes = new BemClass('LeadDrawer', className);

	const isOverdue = () => {
		if (client?.processedBy?.user && client?.processedBy?.entityType === 'Activity') {
			const dateTime = moment(client?.processedBy?.date);
			if (client?.processedBy?.time) {
				const timeParts: number[] = client?.processedBy?.time?.split(':').map(part => parseInt(part));
				dateTime.hours(timeParts[0]).minutes(timeParts[1]);
			} else {
				dateTime.hours(23).minutes(59);
			}

			return moment().isAfter(dateTime);
		} else {
			return false;
		}
	};

	const getEvents = () => {
		const eventRb = new RequestBuilder();

		if (isContact) {
			eventRb.addFilter(EventAttributes.contactId, comparisonTypes.Equals, item.contactId);
		} else {
			eventRb.addFilter(EventAttributes.clientId, comparisonTypes.Equals, item.clientId);
		}
		eventRb.addFilter(EventAttributes.date, comparisonTypes.LessThanEquals, moment().format());
		eventRb.addFilter({ field: 'feedLeads2' }, comparisonTypes.Equals, null);

		eventRb.addSort(EventAttributes.date, false);
		eventRb.addSort(EventAttributes.entityType, false);

		eventRb.limit = 12;
		eventRb.offset = 0;

		EventResource.find(eventRb.build())
			.then(events => {
				setEvents(events.data);
				setTotalEvents(events.metadata.total);
			})
			.catch(err => {
				logError(err, 'Could not get events');
			});
	};

	const getClient = () => {
		ClientResource.get(item.clientId)
			.then(res => {
				setClient(res.data);
			})
			.catch(err => {
				logError(err, 'Could not get client');
			});
	};

	useMemo(() => {
		getEvents();
		if (!isContact) {
			getClient();
		}
	}, []);

	const openMail = (mailId: number | undefined) => {
		if (mailId) {
			if (Tools.FeatureHelper.hasSoftDeployAccess('NEW_MAIL')) {
				openDrawer('SentMail', { mail: { id: mailId } });
			} else {
				Tools.$upModal.open('sentMail', { customerId: customerId, id: mailId });
			}
		}
	};

	const openVisit = (visitId: number | undefined) => {
		if (visitId) {
			Tools.$upModal.open('viewVisit', { id: visitId });
		}
	};

	const openContact = (contact: ContactEvent[] | undefined, id?: number) => {
		const contactId = id ? id : contact && contact.length ? contact[0].id : null;
		if (contactId) {
			window.open(
				Tools.$state.href('contact.dashboard', {
					customerId: Tools.AppService.getCustomerId(),
					id: contactId
				}),
				'_blank'
			);
		}
	};

	const openClient = (clientId: number) => {
		window.open(
			Tools.$state.href('account.dashboard', {
				customerId: Tools.AppService.getCustomerId(),
				id: clientId
			}),
			'_blank'
		);
	};

	const renderEvents = () => {
		return events.map((event: Event) => {
			let icon = '';
			let text = '';
			let content: null | JSX.Element = null;
			let special: null | string = null;

			switch (event.entityType) {
				case 'Form': {
					icon = 'form';
					text = ` ${T('event.form.filledOut')} `.toLowerCase();
					content = (
						<Link onClick={() => window.open('#/form-overview/' + event?.form?.id, '_blank')}>
							{event?.form?.title}
						</Link>
					);
					break;
				}
				case 'Mail': {
					switch (event.subType) {
						case 'unsub': {
							icon = 'unlink';
							text = ` ${T('mail.unsubscribed')} `.toLowerCase();
							content = <Link onClick={() => openMail(event?.mail?.id)}>{event?.mail?.subject}</Link>;
							break;
						}
						case 'click': {
							icon = 'email';
							text = ` ${T('event.contactclick')} `.toLowerCase();
							content = <Link onClick={() => openMail(event?.mail?.id)}>{event?.mail?.subject}</Link>;
							break;
						}
						case 'open': {
							icon = 'email';
							text = ` ${T('event.contactopen')} `.toLowerCase();
							content = <Link onClick={() => openMail(event?.mail?.id)}>{event?.mail?.subject}</Link>;
							break;
						}
					}

					break;
				}

				case 'Visit':
					icon = 'globe';
					text = '';
					special = `${event.visit?.pages?.length} ${T('event.pages')} ${event.score}p`;
					content = (
						<Link onClick={() => openVisit(event?.visit?.id)}>{` ${T(
							'event.visited'
						).toLowerCase()} `}</Link>
					);
					break;
			}
			return (
				<TableRow key={event.id}>
					<TableColumn>
						<Text size="sm" className={classes.elem('event').b()}>
							<Icon name={icon as IconName} color="black" space="mrl" />
							{event.contacts && event.contacts.length > 0 ? (
								<Link onClick={() => openContact(event.contacts)}>{event.contacts[0].name}</Link>
							) : (
								T('default.someone')
							)}{' '}
							{text} {content}
						</Text>
					</TableColumn>
					<TableColumn title={special ? special : `${event.score}p`} />
					<TableColumn className={classes.elem('date').b()} title={moment(event.date).format('YYYY-MM-DD')} />
				</TableRow>
			);
		});
	};

	const renderNextActivity = () => {
		const avatar = client?.processedBy ? <Avatar size={20} user={client?.processedBy.user} /> : null;
		return (
			<div className={classes.elem('info-col').b()}>
				{client?.processedBy ? (
					<Tooltip title={client.processedBy.user.name}>
						{avatar}
						<Text color={isOverdue() ? 'red' : 'black'}>
							{moment(client.processedBy.date).format('YYYY-MM-DD')}
						</Text>
					</Tooltip>
				) : (
					<Text italic>{T('default.noActivity')}</Text>
				)}
				<Text color="grey-11" bold>
					{T('next_activity')}
				</Text>
			</div>
		);
	};

	const renderInfo = () => {
		return (
			<Block>
				<Row className={classes.elem('info').b()}>
					<Column>
						<div>
							{client?.users.length ? (
								client.users.map(user => {
									return (
										<Text key={user.id} size="md">
											{user.name}
										</Text>
									);
								})
							) : (
								<Text size="md" italic>
									{T('default.noAccountManager')}
								</Text>
							)}
							<Text color="grey-11" bold>
								{T('default.accountManager')}
							</Text>
						</div>
					</Column>
					<Column>
						<div className={classes.elem('info-col').b()}>
							<Title size="md">
								<Icon space="mrl" name="tachometer" />
								{item.scoreTotal}
							</Title>
							<Text color="grey-11" bold>
								{T('default.leadScore')}
							</Text>
						</div>
					</Column>
					<Column>{renderNextActivity()}</Column>
				</Row>
			</Block>
		);
	};

	return (
		<div className={classes.b()}>
			<DrawerHeader title={isContact ? item.contactName : item.clientName} onHide={close}></DrawerHeader>
			<div className={classes.elem('wrapper').b()}>
				{!isContact ? renderInfo() : null}
				<Table>
					<TableHeader
						columns={[
							{ title: T('default.event') },
							{ title: T('default.score') },
							{ title: T('default.date') }
						]}
					/>

					{renderEvents()}
				</Table>
				{totalEvents > 12 ? (
					<Link
						className={classes.elem('show-more').b()}
						onClick={() => (isContact ? openContact([], item.contactId) : openClient(item.clientId))}
					>
						{T('scoreboard.showMoreEvents')}
					</Link>
				) : null}
			</div>
		</div>
	);
};
