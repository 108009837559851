import * as yup from 'yup';
import T from 'Components/Helpers/translate';

export type FormObserverModel = {
	[name: string]: ValidationModel;
};

type FormObserverCustomValidator = (value: unknown, values: unknown) => string | false;

type FormObserverFieldModel = {
	type: FieldType;
	required: boolean;
	disabled?: boolean;
	max?: number;
	min?: number;
	defaultValue?: any;
	customValidator: null | FormObserverCustomValidator;
};

type ValidationModelOpts = {
	of?: ValidationModel;
	objectNullable?: boolean;
	schema?: FormObserverModel;
};

type ValidatedValidationModelOpts = Required<ValidationModelOpts>;

export enum FieldType {
	STRING = 'STRING',
	NUMBER = 'NUMBER',
	URL = 'URL',
	LIST = 'LIST',
	OBJECT = 'OBJECT',
	DATE = 'DATE',
	EMAIL = 'EMAIL',
	BOOLEAN = 'BOOLEAN'
}

const fixUrl = (value: string) => {
	if (
		value &&
		!(
			'http://'.indexOf(value.substring(0, 7)) === 0 ||
			'https://'.indexOf(value.substring(0, 8)) === 0 ||
			'mailto:'.indexOf(value.substring(0, 7)) === 0 ||
			'ftp://'.indexOf(value.substring(0, 6)) === 0
		)
	) {
		return 'http://' + value;
	}
	return value;
};

export default class ValidationModel<TValue = unknown> {
	model: FormObserverFieldModel;
	opts: ValidationModelOpts;
	name: string;
	requiredError: string;
	minError: string | null = null;
	maxError: string | null = null;
	schema: null | FormObserverModel = null;

	validateOpts(opts = this.opts) {
		if (this.model.type === FieldType.LIST && !opts.of) {
			throw new Error('Validation model of type list requires an "of" model');
		}
		if (this.model.type === FieldType.OBJECT && !opts.schema) {
			throw new Error('Validation model of type keyed_object requires a "key"');
		}
	}

	constructor(type: FieldType, name: string, opts: ValidationModelOpts = {}) {
		this.model = { type, required: false, customValidator: null };
		this.opts = opts;
		this.name = name;
		this.requiredError = T('validation.missingRequiredFields', { field: T(name) });
		this.schema = opts.schema || {};

		this.validateOpts();
	}
	default(value: TValue) {
		this.model.defaultValue = value;
		return this;
	}
	/* Allow following call patterns
		required() = true, default error msg
		required('Custom message') = true, Custom message
		required(true) = true, default error msg
		required(true, 'Custom message') = true, Custom message
		required(false) = false, default error msg
		required(false, 'Custom message') = false, Custom message
	*/
	required(...args: [boolean, string?] | [boolean] | [string] | []) {
		this.model.required = args?.[0] !== undefined ? !!args[0] : true;
		if (args[args.length - 1] && typeof args[args.length - 1] === 'string') {
			this.requiredError = args[args.length - 1] as string;
		}
		return this;
	}
	disabled(disabled?: boolean) {
		this.model.disabled = disabled ?? false;
		return this;
	}
	max(max: number, errorMessage?: string) {
		this.model.max = max;
		this.maxError = errorMessage || T('validation.numberMaxError', { field: T(this.name), max });
		return this;
	}
	min(min: number, errorMessage?: string) {
		this.model.min = min;
		this.minError = errorMessage || T('validation.numberMinError', { field: T(this.name), min });
		return this;
	}
	customValidator(fn: FormObserverCustomValidator) {
		this.model.customValidator = fn;
		return this;
	}
	getYupSchema(o?: ValidationModelOpts): yup.AnySchema | null {
		const mergedOpts = { ...this.opts, ...(o ?? {}) };
		this.validateOpts(mergedOpts);
		const opts = mergedOpts as ValidatedValidationModelOpts;
		let fieldModel = null;
		switch (this.model.type) {
			case FieldType.STRING:
				fieldModel = yup
					.string()
					// Trim whitespace
					.transform(currentValue => (currentValue === ' ' ? '' : currentValue))
					.ensure();
				break;
			case FieldType.NUMBER:
				fieldModel = yup
					.number()
					.transform((currentValue, originalValue) => {
						return originalValue === '' ? null : currentValue;
					})
					.transform((currentValue, originalValue) => {
						// Find and replace any "-" that is not in the beginning of the string
						if (originalValue?.lastIndexOf?.('-') > 0) {
							const replaced = parseInt(originalValue.replace(/(?!^)-/g, ''));
							return isNaN(replaced) ? null : replaced;
						}
						return currentValue;
					})
					.nullable(true);
				break;
			case FieldType.URL:
				fieldModel = yup
					.string()
					.url()
					.transform(function (value) {
						if (yup.string().url().isValidSync(value)) {
							return value;
						}
						return fixUrl(value);
					})
					.ensure();
				break;
			case FieldType.LIST:
				fieldModel = yup.array();
				if (opts.of) {
					const ofModel = opts.of.getYupSchema({ objectNullable: false });
					if (ofModel) {
						fieldModel = fieldModel.of(ofModel);
					}
				}
				break;
			case FieldType.OBJECT:
				if (opts.schema) {
					fieldModel = yup.object(
						Object.keys(opts.schema).reduce<{ [k: string]: yup.AnySchema }>((res, key) => {
							const yupSchema = opts.schema[key].getYupSchema();
							if (yupSchema) {
								res[key] = yupSchema;
							}
							return res;
						}, {})
					);
					if (opts.objectNullable) {
						fieldModel = fieldModel.nullable();
					}
					if (this.model.defaultValue !== undefined) {
						fieldModel = fieldModel.default(this.model.defaultValue);
					}
				}
				break;
			case FieldType.DATE:
				fieldModel = yup.date().nullable(true);
				break;
			case FieldType.EMAIL:
				fieldModel = yup.string().email().nullable(true);
				break;
			case FieldType.BOOLEAN:
				fieldModel = yup
					.mixed()
					.nullable(true)
					.oneOf([null, 'true', true, '1', 'on', 1, 'false', false, '0', 'off', 0]);
				//apparently you need to have both nullable and null to make it work in yup
				break;
		}
		if ([FieldType.STRING, FieldType.NUMBER].includes(this.model.type)) {
			if (this.model.min) {
				fieldModel = (fieldModel as yup.ArraySchema<any>).min(this.model.min);
			}
			if (this.model.max) {
				fieldModel = (fieldModel as yup.ArraySchema<any>).max(this.model.max);
			}
		}
		if (fieldModel && this.model.required) {
			if (this.model.type === FieldType.LIST) {
				fieldModel = (fieldModel as yup.ArraySchema<any>).min(1);
			} else {
				fieldModel = fieldModel.required();
			}
		}
		if (fieldModel && this.model.customValidator) {
			fieldModel = (fieldModel as yup.AnySchema).test(
				'custom',
				'custom',
				(value, { path, parent, createError }) => {
					const message = this.model.customValidator?.(value, parent) ?? false;
					if (message) {
						return createError({ path, message, type: 'custom' });
					}
					return true;
				}
			);
		}
		return fieldModel;
	}
	// Wont work on lists
	reduceFlatSubObject(baseKey: string, res: { [k: string]: null }) {
		if (this.model.type === FieldType.OBJECT && this.schema) {
			Object.keys(this.schema).forEach(key => {
				res[`${baseKey}.${key}`] = null;
				this.schema![key].reduceFlatSubObject(`${baseKey}.${key}`, res);
			});
		}
	}
}
