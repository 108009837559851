import CustomField, { EntityCustomField } from 'App/resources/Model/CustomField';

export const getDefaultCustomValues = (customFields: CustomField[]) =>
	customFields.reduce((res: EntityCustomField[], field) => {
		if (field.datatype === 'Select' && field.dropdownDefault?.length) {
			const value = Array.isArray(field.dropdownDefault) ? field.dropdownDefault[0] : field.dropdownDefault;
			if (value) {
				res.push({ fieldId: field.id, value });
			}
		}
		return res;
	}, []);
