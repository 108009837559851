import angular from 'angular';
import type Activity from 'App/resources/Model/Activity';
import type Project from 'App/resources/Model/Project';
import type Event from 'App/resources/Model/Event';
import type Comment from 'App/resources/Model/Comment';
import type { Attr } from 'App/babel/attributes/Attribute';
import { Metadata } from 'App/babel/resources/ResourceTyped';

type Injector = { get: (n: string) => any };

let injector: Injector;

export const initAngularInjector = () => {
	try {
		angular.module('upsalesApp').run([
			'$injector',
			($injector: Injector) => {
				injector = $injector;
			}
		]);
	} catch {
		// nope
	}
};

type GenericResource<T> = {
	find: (filters: object) => Promise<{ data: T[]; error: any; metadata: Metadata }>;
};

type AngularModule = typeof Tools & {
	// Angular built-ins
	$anchorScroll: angular.IAnchorScrollService;
	$cacheFactory: angular.ICacheFactoryService;
	$compile: angular.ICompileService;
	$controller: angular.IControllerService;
	$document: angular.IDocumentService;
	$exceptionHandler: angular.IExceptionHandlerService;
	$filter: angular.IFilterService;
	$http: angular.IHttpService;
	$httpBackend: angular.IHttpBackendService;
	$httpParamSerializer: angular.IHttpParamSerializer;
	$httpParamSerializerJQLike: angular.IHttpParamSerializer;
	$interpolate: angular.IInterpolateService;
	$interval: angular.IIntervalService;
	$locale: angular.ILocaleService;
	$location: angular.ILocationService;
	$log: angular.ILogService;
	$parse: angular.IParseService;
	$q: angular.IQService;
	$rootElement: angular.IRootElementService;
	$rootScope: angular.IRootScopeService;
	$sce: angular.ISCEService;
	$sceDelegate: angular.ISCEDelegateService;
	$templateCache: angular.ITemplateCacheService;
	$templateRequest: angular.ITemplateRequestService;
	$timeout: angular.ITimeoutService;
	$window: angular.IWindowService;

	// custom
	$safeApply: (scope: angular.IScope) => void;
	$translate: (tag: string, variables?: object) => string;
	ActivityAttributes: () => { attr: Record<string, Attr> };
	AgreementAttributes: () => { attr: Record<string, Attr> };
	AppointmentAttributes: () => { attr: Record<string, Attr> };
	Campaign: { attr: Record<string, Attr> };
	EditorCk: { getOptions: (opts: object) => object };
	EventAttributes: () => { attr: Record<string, Attr> };
	EventService: { create: { Comment: (comment: Comment) => Event; Activity: (activity: Activity) => Event } };
	MailAttributes: () => { attr: Record<string, Attr> };
	OpportunityAttributes: () => { attr: Record<string, Attr> };
	OrderAttributes: () => { attr: Record<string, Attr> };
	Project: { customer: (id: number) => GenericResource<Project> };
	security: { logout: () => void };
	UdoLink: { ACCOUNT: 4; CONTACT: 6; CAMPAIGN: 1 };
	[k: string]: unknown;
};

type AngularFunctionInjection<TTuple extends readonly string[]> = [
	...TTuple,
	(...a: { [k in keyof TTuple]: AngularModule[TTuple[k]] }) => unknown
];

// Allow angular module types to be inferred from the name
declare global {
	namespace angular {
		interface IModule {
			controller<T extends readonly string[]>(name: string, config: AngularFunctionInjection<T>): angular.IModule;
			directive<T extends readonly string[]>(name: string, config: AngularFunctionInjection<T>): angular.IModule;
			factory<T extends readonly string[]>(name: string, config: AngularFunctionInjection<T>): angular.IModule;
			run<T extends readonly string[]>(config: AngularFunctionInjection<T>): angular.IModule;
			service<T extends readonly string[]>(name: string, config: AngularFunctionInjection<T>): angular.IModule;
		}
	}
}

const getAngularModule = <T extends string>(name: T): AngularModule[T] => {
	if (name === '$translate') {
		return injector.get(name).instant;
	}
	return injector.get(name);
};

export default getAngularModule;

export const available = (nameOfModule: string): boolean => {
	try {
		injector.get(nameOfModule);
		return true;
	} catch (e) {
		return false;
	}
};
