const copyToClipboard = value => {
	try {
		const elem = document.createElement('input');
		elem.style.position = 'absolute';
		elem.style.left = -9999;
		elem.style.top = -9999;
		document.body.appendChild(elem);
		elem.value = value;
		elem.select();

		document.execCommand('copy');
		elem.remove();

		return true;
	} catch (err) {
		console.error('[ERROR] copyToClipboard:', err);
		return false;
	}
};

export default copyToClipboard;

export const copyToClipboardNotify = value => {
	if (copyToClipboard(value)) {
		Tools.NotificationService.addNotification({
			icon: 'check',
			style: Tools.NotificationService.style.SUCCESS,
			title: 'default.copiedToClipboard'
		});
	} else {
		Tools.NotificationService.addNotification({
			icon: 'times',
			style: Tools.NotificationService.style.ERROR,
			title: 'default.error'
		});
	}
};
