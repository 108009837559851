import React from 'react';
import { get } from 'lodash';
import { Text, Link, Icon, Tooltip } from '@upsales/components';
import TimelineRow from 'Components/TimelineRow';
import { ACTIVITY_TIMELINE, APPOINTMENT_TIMELINE } from 'App/babel/enum/timeline';
import getPostponedDuration from 'Components/Helpers/getTimelinePostponeDuration';
import { TimelinePostponeSubtitle, TimelineRescheduledTitle } from 'Components/TimelineRow/TimelinePostpone';

const ActivityTimelinePostpone = ({ event, hideIcon = false }) => {
	let icon = 'calendar-arrow-right-o';
	let iconColor = null;
	let title = null;
	const t = Tools.$translate;
	const lang = {
		didntReached: t('activity.outcomeLog.didntReached').toLowerCase(),
		reached: t('activity.outcomeLog.reached').toLowerCase(),
		postponeFor: t('activity.outcomeLog.postponeFor').toLowerCase(),
		didntFindAnyone: t('activity.outcomeLog.didntFindAnyone').toLowerCase(),
		notInterestedNow: t('activity.outcomeLog.notInterestedNow'),
		dateSetTo: t('activity.outcomeLog.dateSetTo').toLowerCase(),
		updatedDate: t('activity.outcomeLog.updatedDate').toLowerCase()
	};
	const { subType, data, user, date: eventDate } = event || {};
	const date = get(data, 'date');
	const userName = get(user, 'name');
	const userFirstName = (userName || '').split(' ')[0];
	const oldDate = get(data, 'oldDate', '');
	const customerId = Tools.AppService.getCustomerId();
	const contactId = get(data, 'contact.id', '');
	const contactName = get(data, 'contact.name', '');

	if ([ACTIVITY_TIMELINE.NOT_REACHED_POSTPONED, ACTIVITY_TIMELINE.NOT_FOUND_LEAD_POSTPONED].indexOf(subType) !== -1) {
		iconColor = 'red';
	}

	const renderUsername = () => {
		return (
			<Tooltip title={userName}>
				<b>{userFirstName}</b>
			</Tooltip>
		);
	};

	switch (subType) {
		case ACTIVITY_TIMELINE.POSTPONED:
			title = (
				<Text>
					{renderUsername()} {lang.postponeFor} {getPostponedDuration(date)}
				</Text>
			);
			break;
		case APPOINTMENT_TIMELINE.RESCHEDULED:
			if (data.outcome) {
				iconColor = 'red';
			}
			title = <TimelineRescheduledTitle date={date} user={user} />;
			break;
		case ACTIVITY_TIMELINE.SET_DATE:
			icon = 'calendar';
			title = (
				<Text>
					{renderUsername()} {lang.dateSetTo} {getPostponedDuration(date)}
				</Text>
			);
			break;
		case ACTIVITY_TIMELINE.DATE_CHANGED:
			title = (
				<Text>
					{renderUsername()} {lang.updatedDate}
				</Text>
			);
			break;
		case ACTIVITY_TIMELINE.NOT_REACHED_POSTPONED:
		case ACTIVITY_TIMELINE.REACHED_POSTPONED:
		case ACTIVITY_TIMELINE.REACHED_NO_THANKS_POSTPONE:
			title = (
				<Text>
					{renderUsername()}{' '}
					{subType === ACTIVITY_TIMELINE.NOT_REACHED_POSTPONED ? `${lang.didntReached} ` : `${lang.reached} `}
					<Link
						onClick={e => e.stopPropagation()}
						href={Tools.$state.href('contact.dashboard', {
							customerId: customerId,
							id: contactId
						})}
					>
						{contactName}
					</Link>
					<span dangerouslySetInnerHTML={{ __html: '&#58;' }} /> {lang.postponeFor}{' '}
					{getPostponedDuration(date)}
				</Text>
			);
			break;
		case ACTIVITY_TIMELINE.NOT_FOUND_LEAD_POSTPONED:
			icon = 'user-times';
			title = (
				<Text>
					{renderUsername()} {lang.didntFindAnyone}
					<span dangerouslySetInnerHTML={{ __html: '&#58;' }} /> {lang.postponeFor}{' '}
					{getPostponedDuration(date)}
				</Text>
			);
			break;
	}

	const subTitle = (
		<TimelinePostponeSubtitle
			date={date}
			oldDate={oldDate}
			eventDate={eventDate}
			showPostponedFromDate={subType !== ACTIVITY_TIMELINE.SET_DATE}
		/>
	);
	const subSection =
		subType === ACTIVITY_TIMELINE.REACHED_NO_THANKS_POSTPONE ? (
			<Text>
				<Icon name="times-circle" space="mrl" color="black" />
				{lang.notInterestedNow}
			</Text>
		) : null;

	return (
		<TimelineRow
			icon={hideIcon ? '' : icon}
			iconColor={iconColor}
			event={event}
			title={title}
			subTitle={subTitle}
			subSection={subSection}
		/>
	);
};

export default ActivityTimelinePostpone;
