import { SegmentFilter } from 'App/resources/Model/Segment';
import { GroupFilter, OrFilter, BuildFilters } from 'Resources/RequestBuilder';
import { Equals, NotEquals } from 'App/babel/resources/ComparisonTypes';
import getAngularModule from 'App/babel/angularHelpers/getAngularModule';
import RequestBuilder from 'Resources/RequestBuilder';
import { AggregationTypes } from 'Resources/Aggregation';
import ContactAttributes from 'App/babel/attributes/ContactAttributes';
import _ from 'lodash';

/* If you change in this method remember that you have to change in datalayer/repositories/segment.js -> mergeFilters to */
export function mergeFilters(filterObjects: SegmentFilter[]) {
	const includeFilters = _.filter(filterObjects, { isExclude: false });
	const excludeFilters = _.filter(filterObjects, { isExclude: true });
	const manuallyIncludedFilter = (filterObjects || []).find(
		filterObject =>
			Object.keys(filterObject.config).length === 1 &&
			filterObject.config.hasOwnProperty('Id') &&
			filterObject.isExclude === false
	);

	let userId = null;
	let customerId = null;

	const includeFilterObject = includeFilters.reduce<OrFilter>(
		(res, filterObj) => {
			const filter = filterObj.body;

			if (filter.customerId) {
				customerId = filter.customerId;
			}
			if (filter.userId) {
				userId = filter.userId;
			}

			if (filter.q && filter.q.length) {
				if (filterObj.orGroup) {
					_.each(filter.q, innerFilter => {
						res.or.q.push([innerFilter]);
					});
				} else {
					res.or.q.push(filter.q);
				}
			}
			return res;
		},
		{ or: { q: [] } }
	);

	const excludeFilterObjects = excludeFilters.reduce<GroupFilter[]>((res, filterObj) => {
		const filter = filterObj.body;

		if (filter.customerId) {
			customerId = filter.customerId;
		}
		if (filter.userId) {
			userId = filter.userId;
		}

		if (filter.q && filter.q.length) {
			const groupFilter: GroupFilter = { group: { q: [[]], not: true } };
			const orFilter: OrFilter = { or: { q: [] } };

			if (filterObj.orGroup) {
				_.each(filter.q, innerFilter => {
					orFilter.or.q.push([innerFilter]);
				});
			} else {
				// We always wrap in an or query here, even when it is technically not needed.
				// This is because orQuery wraps the the group in an groupedChildGroup that in turn sets the correct type on group filters in the "if (element instanceof GroupQuery && !element.segment) { ... }" block.
				orFilter.or.q.push(filter.q);
			}

			if (orFilter.or.q.length) {
				groupFilter.group.q = [[orFilter]];
			}

			if (groupFilter.group.q[0].length) {
				if (
					manuallyIncludedFilter &&
					Array.isArray(manuallyIncludedFilter?.config?.Id?.value) &&
					manuallyIncludedFilter.config.Id.value.length
				) {
					const filter = manuallyIncludedFilter.body.q[0];
					groupFilter.group.q[0].push({ ...filter, c: NotEquals });
				}
				res.push(groupFilter);
			}
		}
		return res;
	}, []);

	const out: SegmentFilter['body'] = { q: [] };

	if (includeFilterObject.or.q.length) {
		out.q.push(includeFilterObject);
	} else {
		/* This is for backup saftey so we dont accidentally make a selection without any filter that select all contacts */
		out.q = [{ a: ContactAttributes.id.field, c: Equals, v: -1 }];
	}

	if (excludeFilterObjects.length) {
		out.q = out.q.concat(excludeFilterObjects);
	}

	if (userId && customerId) {
		out.customerId = customerId;
		out.userId = userId;
	}

	return out;
}
export async function countParticipants(filter: SegmentFilter[]): Promise<{ clients: number; contacts: number }> {
	const Report = getAngularModule('Report');
	const AppService = getAngularModule('AppService');

	const requestBuilder = new RequestBuilder();
	requestBuilder.addExtraParam('useEntityAccess', true);

	const aggName = 'clientCount';
	const aggregationBuilder = requestBuilder.aggregationBuilder();
	aggregationBuilder.addAggregation(AggregationTypes.Cardinality, ContactAttributes.client.attr.id.field);
	aggregationBuilder.aggregationName(aggName);
	aggregationBuilder.aggregationSize(1);
	aggregationBuilder.done();

	const customerId = AppService.getCustomerId();
	const aggregationFilters = requestBuilder.build() as BuildFilters;
	const segmentFilters = mergeFilters(filter);

	const result = await Report.customer(customerId)
		.setType(Report.type.CONTACT)
		.find({ ...aggregationFilters, ...segmentFilters });

	const { value: clients, doc_count: contacts } = result.data[aggName];

	return { clients, contacts };
}
