import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { groupBy } from 'lodash';
import moment from 'moment';
import Bem from '@upsales/components/Utils/bemClass';
import { Text } from '@upsales/components';

import NotificationCard from '../NotificationCard';
import './index.scss';

class NotificationList2 extends PureComponent {
	constructor(props) {
		super(props);

		const t = Tools.$translate;

		this.lang = {
			today: t('today'),
			yesterday: t('yesterday'),
			thisWeek: t('notificationList.thisWeek'),
			activeJobs: t('notificationList.activeJobs')
		};
	}

	getGroupedNotificationList(notifications = []) {
		const startDate = moment().startOf('day');
		const endDate = moment().endOf('day');
		const yesterdayStart = moment().subtract(1, 'day').startOf('day');
		const yesterdayEnd = moment().subtract(1, 'day').endOf('day');

		return groupBy(notifications, notification => {
			const { date } = notification;

			if (moment(date).isBetween(startDate, endDate, null, '[]')) {
				return this.lang.today;
			}

			if (moment(date).isBetween(yesterdayStart, yesterdayEnd, null, '[]')) {
				return this.lang.yesterday;
			}

			return moment(date).format('YYYY-MM-DD');
		});
	}

	renderList(list = []) {
		const { exportUrl, onNotificationClick, showImport, close, onTabChanged } = this.props;
		return list.map((item, index) => (
			<NotificationCard
				key={index}
				notification={item}
				exportUrl={exportUrl}
				onClick={onNotificationClick}
				showImport={showImport}
				closeDrawer={close}
				onTabChanged={onTabChanged}
			/>
		));
	}

	renderJobs() {
		const { jobs } = this.props;
		const classNames = new Bem('NotificationList2');
		return (
			<div className={classNames.elem('group').b()}>
				<Text color="grey-10" size="xl" className={classNames.elem('group-title').b()}>
					{this.lang.activeJobs}
				</Text>
				{this.renderList(jobs)}
			</div>
		);
	}

	renderGroup() {
		const classNames = new Bem('NotificationList2');
		const { notifications } = this.props;
		const grouped = this.getGroupedNotificationList(notifications);
		const largeNames = [this.lang.today, this.lang.yesterday, this.lang.thisWeek];

		return Object.keys(grouped).map((name, index) => (
			<div key={index} className={classNames.elem('group').b()}>
				<Text
					color="grey-10"
					size={largeNames.includes(name) ? 'xl' : 'md'}
					className={classNames.elem('group-title').b()}
				>
					{name}
				</Text>
				{this.renderList(grouped[name])}
			</div>
		));
	}

	render() {
		const { notifications, emptyMessage, jobs, showJobs } = this.props;
		const classNames = new Bem('NotificationList2');
		return (
			<div className={classNames.b()}>
				{showJobs && jobs.length > 0 && this.renderJobs()}
				{notifications.length > 0 ? (
					this.renderGroup()
				) : (
					<div className={classNames.elem('empty').b()}>
						<img src="img/empty-briefcase.png" alt="empty" width="87" height="89" />
						<Text color="grey-10">{emptyMessage}</Text>
					</div>
				)}
			</div>
		);
	}
}

NotificationList2.propTypes = {
	close: PropTypes.func,
	notifications: PropTypes.array.isRequired,
	exportUrl: PropTypes.string.isRequired,
	onNotificationClick: PropTypes.func,
	showImport: PropTypes.func,
	emptyMessage: PropTypes.string,
	onTabChanged: PropTypes.func
};

export default NotificationList2;
