import { PrimaryButton, ThirdButton } from '@upsales/components/Buttons';
import BemClass from '@upsales/components/Utils/bemClass';
import { Card, Icon, Text } from '@upsales/components';
import T from 'Components/Helpers/translate';
import React from 'react';

import './ExitConfirmDropdown.scss';

type Props = {
	close: (data?: object) => void;
	currentStage: string;
	hideExit: () => void;
};

const ExitConfirmDropdown = ({ hideExit, close, currentStage }: Props) => {
	const classes = new BemClass('ExitConfirmDropdown');

	return (
		<Card className={classes.b()}>
			<Icon onClick={hideExit} name="times" />
			<Text bold>{T('subscription.wonOpportunity.confirmExit.title')}</Text>
			<Text color="grey-11">
				{T('subscription.wonOpportunity.confirmExit.subtitle', { stage: currentStage })}
			</Text>
			<div className={classes.elem('buttons').b()}>
				<PrimaryButton onClick={close}>{T('default.abortEdit')}</PrimaryButton>
				<ThirdButton onClick={hideExit}>{T('default.returnToEdit')}</ThirdButton>
			</div>
		</Card>
	);
};

export default ExitConfirmDropdown;
