import React from 'react';
import { Text, Link } from '@upsales/components';
import moment from 'moment';
import TimelineRow from '../TimelineRow';
import T from 'Components/Helpers/translate';
import { MarketingCustomEvent } from 'App/babel/propTypes/EventShape';

const getContactHref = id =>
	Tools.$state.href('contact.dashboard', {
		id,
		customerId: Tools.AppService.getCustomerId()
	});

const getTypeData = event => {
	switch (event.subType) {
		case 'Event':
			return { text: `${T('event.attendedEvent')} ${event.value || T('event.unknown')}`, icon: 'ticket' };
		case 'Chat':
			return { text: `${T('event.chattedOn')} ${event.value || T('event.unknown')}`, icon: 'comment' };
		case 'Video':
			return { text: `${T('event.watchedVideo')} ${event.value || T('event.unknown')}`, icon: 'play-circle' };
		case 'Twitter':
			return { text: `${T('event.tweeted')} ${event.value || T('event.unknown')}`, icon: 'twitter' };
		default:
			return {
				text: `${T('event.hadMarketingEvent')} ${event.subType} ${event.value || ''}`,
				icon: event.score < 0 ? 'thumbs-down' : 'bullseye'
			};
	}
};

const TimelineMarketingCustom = ({ event }) => {
	const { date, score } = event || {};

	const contacts = event.contacts || [];
	const { text, icon } = getTypeData(event);

	const title = (
		<Text>
			{contacts.length ? (
				<Link href={getContactHref(contacts[0].id)}>{contacts[0].name}</Link>
			) : (
				T('default.someone')
			)}
			{` ${text}`}
			<b>{` (${score || 0}p)`}</b>
		</Text>
	);
	const subTitle = date ? (
		<Text size="sm" color="grey-11">
			{moment(date).format('L LT')}
		</Text>
	) : null;
	return (
		<TimelineRow icon={icon} hasAvatar={false} event={event} title={title} subTitle={subTitle} subSection={null} />
	);
};

TimelineMarketingCustom.propTypes = {
	event: MarketingCustomEvent.isRequired
};

export default TimelineMarketingCustom;
