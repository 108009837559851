import RequestBuilder, { comparisonTypes as ComparisonTypes } from 'Resources/RequestBuilder';
import _ from 'lodash';
import parse from './filterParse';
import { ListViewFilter } from 'App/resources/AllIWant';
import { CustomFieldFilterConfig } from 'App/babel/filterConfigs/FilterConfig';
// import { ComparisonType } from 'Resources/ComparisonTypes';

const notCTToCT = {
	[ComparisonTypes.NotEquals]: ComparisonTypes.Equals,
	[ComparisonTypes.NotGreaterThan]: ComparisonTypes.GreaterThan,
	[ComparisonTypes.NotGreaterThanEquals]: ComparisonTypes.GreaterThanEquals,
	[ComparisonTypes.NotLessThan]: ComparisonTypes.LessThan, // same as ComparisonTypes.NotLowerThan
	[ComparisonTypes.NotLessThanEquals]: ComparisonTypes.LessThanEquals, // same as ComparisonTypes.NotLowerThanEquals
	[ComparisonTypes.NotBetween]: ComparisonTypes.Between,
	[ComparisonTypes.NotTerm]: ComparisonTypes.Term,
	[ComparisonTypes.NotSearch]: ComparisonTypes.Search,
	[ComparisonTypes.NotMatchPhrase]: ComparisonTypes.MatchPhrase,
	[ComparisonTypes.NotMatch]: ComparisonTypes.Match,
	[ComparisonTypes.NotWildcard]: ComparisonTypes.Wildcard,
	[ComparisonTypes.NotWildcardEnd]: ComparisonTypes.WildcardEnd,
	[ComparisonTypes.NotWildcardStart]: ComparisonTypes.WildcardStart,
	[ComparisonTypes.NotMatchExact]: ComparisonTypes.MatchExact
} as const;

const customDatatypeFields = {
	Integer: 'valueInteger',
	Percent: 'valueInteger',
	Currency: 'valueDouble',
	Discount: 'valueDouble',
	Double: 'valueDouble',
	Boolean: 'valueBoolean',
	Date: 'valueDate',
	Time: 'valueTime',
	Text: 'value',
	String: 'value',
	Email: 'value',
	Link: 'value',
	Select: 'value',
	User: 'value',
	Users: 'valueArray',
	Calculation: 'valueInteger'
} as const;

export default (
	filter: ListViewFilter,
	filterName: string,
	filterConfig: CustomFieldFilterConfig,
	type: string,
	requestBuilder: RequestBuilder,
	prefix: string,
	options: { useTags?: boolean }
) => {
	if (!filter.inactive) {
		var groupBuilder = requestBuilder.groupBuilder();
		var filterWasAdded = false;
		var ct;
		var tempPrefix = prefix;
		if (filterName.indexOf('.') !== -1) {
			var customType = filterName.substring(0, filterName.lastIndexOf('.'));
			let filterType = _.get(options, 'filterType', type);
			if (filterType === 'userDefinedObject') {
				filterType = 'userDefinedObject1';
			}
			if (customType !== filterType) {
				if (customType === 'account') {
					customType = 'client';
				}
				tempPrefix = customType + '.';
			}
		}

		if (
			filterConfig.$field &&
			customDatatypeFields[filterConfig.$field.datatype as keyof typeof customDatatypeFields]
		) {
			tempPrefix = filterName.indexOf('CustomAgreement') !== -1 ? 'metadata.' : tempPrefix;

			var valueField =
				tempPrefix +
				'custom.' +
				customDatatypeFields[filterConfig.$field.datatype as keyof typeof customDatatypeFields];
			switch (filterConfig.$field.datatype) {
				case 'Discount':
				case 'Currency':
				case 'Percent':
				case 'Time':
					filterWasAdded = parse.range(
						groupBuilder,
						valueField,
						filter.value as { start: string; end: string }
					);
					break;

				case 'Integer':
				case 'Calculation':
					filterWasAdded = parse.numRange(
						groupBuilder,
						valueField,
						filter.value as { start: string; end: string }
					);
					break;

				case 'Date':
					filterWasAdded = parse.dateRange(
						groupBuilder,
						valueField,
						filter.value as { start: string; end: string; preset: string },
						'YYYY-MM-DD',
						options.useTags
					);
					break;

				case 'Boolean':
					var val = filter.value as boolean;
					if (!filter.value) {
						groupBuilder.isNotFilter();
						val = true;
					}
					filterWasAdded = parse.boolean(groupBuilder, valueField, val);
					break;

				case 'Text':
				case 'String':
				case 'Link':
				case 'Email':
					ct = filter.comparisonType ? ComparisonTypes[filter.comparisonType] : ComparisonTypes.WildcardEnd;
					filterWasAdded = parse.string(groupBuilder, valueField, filter.value as string, ct);
					break;

				case 'Select':
				case 'User':
				case 'Users':
					if (Array.isArray(filter.value) && filter.value && filter.value.length !== 0) {
						ct = filter.comparisonType ? ComparisonTypes[filter.comparisonType] : ComparisonTypes.Term;

						if (notCTToCT[ct as keyof typeof notCTToCT]) {
							groupBuilder.isNotFilter();
							ct = notCTToCT[ct as keyof typeof notCTToCT];
						}

						const selfIndex = filter.value.indexOf('self');

						if (selfIndex > -1) {
							const value = [...filter.value];
							value[selfIndex] = `${Tools.AppService.getSelf().id}`;

							groupBuilder.addFilter({ field: valueField }, ct, value);
						} else {
							groupBuilder.addFilter({ field: valueField }, ct, filter.value);
						}

						filterWasAdded = true;
					}
					break;

				default:
					groupBuilder.addFilter({ field: valueField }, ComparisonTypes.Term, filter.value);
					filterWasAdded = true;
					break;
			}
		} else {
			groupBuilder.addFilter({ field: tempPrefix + 'custom.value' }, ComparisonTypes.Term, filter.value);
			filterWasAdded = true;
		}

		// Only set groupFilter if any filter was parsed
		if (filterWasAdded) {
			groupBuilder.addFilter(
				{ field: tempPrefix + 'custom.fieldId' },
				ComparisonTypes.Equals,
				filterConfig.field
			);
			groupBuilder.done();
		}
	}
};
