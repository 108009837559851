import { Block, Text, Tooltip } from '@upsales/components';
import Client from 'App/resources/Model/Client';
import T from 'Components/Helpers/translate';
import React from 'react';

type Props = Pick<Client, 'operationalAccount'>;

export const SubAccountLabelRaw = ({ operationalAccount }: Props) => {
	if (!operationalAccount) {
		return null;
	}

	return (
		<Block space="pls prs mls" backgroundColor="grey-2" borderRadius>
			<Tooltip title={T('account.subaccount.tooltip', { parent: operationalAccount.name })}>
				<Text size="sm" color="grey-12">
					{T('account.subaccount')}
				</Text>
			</Tooltip>
		</Block>
	);
};

const SubAccountLabel = ({ operationalAccount }: Props) => {
	const hasSubAccounts = Tools.FeatureHelper.hasSoftDeployAccess('SUB_ACCOUNTS');

	if (!hasSubAccounts || !operationalAccount) {
		return null;
	}

	return <SubAccountLabelRaw operationalAccount={operationalAccount} />;
};

export default SubAccountLabel;
