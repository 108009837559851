import Resource from 'Resources/Resource';

export type ReportSchedule = {
	id?: number;
	name: string;
	description: string;
	schedule: string;
	sendImmediately: boolean;
	status?: 'ACTIVE' | 'PAUSED' | 'SENDING' | 'SENT' | 'ERROR';
	reportDashboardId: string;
	recipients: Array<{
		type: string;
		properties: Array<{ key: string; value: string }>;
	}>;
	filters: Array<{
		attribute: string;
		comparison: string;
		value: string;
	}>;
	format: string;
};

class ReportScheduleResource extends Resource {
	constructor() {
		super('reportSchedules');
	}

	async checkSlackScope() {
		return this._getRequest(`checkSlackScope`, { methodName: 'checkSlackScope' }).then(res => res.data);
	}
}

const resource = new ReportScheduleResource();

export default resource;
