import React from 'react';
import bemClass from '@upsales/components/Utils/bemClass';
import { Block, TableColumn, Text } from '@upsales/components';
import './TableWidget.scss';
import { CellValue, loadDrilldown } from '../chartHelper';
import { RCWidgetData } from 'Resources/ReportWidget';
import { RCDashboardWidget } from 'Resources/ReportDashboard';

type DrilldownProps = {
	enableDrilldown: boolean;
	config: RCDashboardWidget;
	disableRowDrilldown?: boolean;
};

type CellProps = {
	classes: bemClass;
	value: CellValue;
	drilldown?: DrilldownProps;
	dotColor?: string;
	rowKey: string;
	columnKey: string;
	selectable?: boolean;
	colors: RCWidgetData['colors'];
	currency: RCWidgetData['currency'];
};

const Cell = ({
	classes,
	value,
	drilldown,
	rowKey,
	columnKey,
	dotColor,
	selectable = true,
	colors,
	currency
}: CellProps) => {
	const isRowTotal = ['grouping', 'total'].includes(columnKey);
	const keys = isRowTotal ? [rowKey] : [rowKey, columnKey];

	const emptyData = !value.rawValue || value.rawValue === null;
	const customCell =
		typeof value.displayValue === 'function'
			? value.displayValue({
					value: value.rawValue,
					datatype: 'currency',
					currency: currency ?? 'SEK',
					colors: colors
			  })
			: null;
	let color: React.ComponentProps<typeof Text>['color'] | undefined;

	const isBold = rowKey === 'total';

	if (!customCell) {
		if (columnKey === 'remaining') {
			// should make sure its always string
			if (value.rawValue && value.rawValue < 0) {
				value.displayValue = `+${`${value.displayValue}`.substring(1)}`;
				color = 'success-4';
			}
		} else if (value.rawValue && value.rawValue < 0) {
			color = 'red';
		}
	}

	let columnDrilldown = true;
	if (!selectable) {
		columnDrilldown = !(drilldown?.disableRowDrilldown ?? false);
	}

	let colorProp = {};
	if (dotColor) {
		const colorIsHex = dotColor?.startsWith('#');
		colorProp = colorIsHex ? { style: { backgroundColor: dotColor } } : { color: dotColor || 'green' };
	}

	return (
		<TableColumn
			className={classes
				.elem('cell')
				.mod({
					selectable:
						drilldown?.enableDrilldown &&
						selectable &&
						columnKey !== 'remaining' &&
						columnKey !== 'goal' &&
						columnDrilldown,
					emptyData,
					hasBarchart: columnKey === 'barchart'
				})
				.mod({ distribution: columnKey === 'distributionColumn' })
				.b()}
			onClick={() =>
				drilldown?.enableDrilldown &&
				keys &&
				columnKey !== 'remaining' &&
				columnKey !== 'goal' &&
				columnDrilldown
					? loadDrilldown(keys, drilldown.config)
					: null
			}
		>
			{customCell !== null ? (
				customCell
			) : (
				<Text bold={isBold} size="sm" color={color ?? (emptyData ? 'grey-10' : 'black')}>
					{dotColor ? <Block {...colorProp} className={classes.elem('info-row-dot').b()} /> : null}
					{value.displayValue}
				</Text>
			)}
		</TableColumn>
	);
};

export default Cell;
