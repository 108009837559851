import RequestBuilder from 'Resources/RequestBuilder';
import type { MultiSelect } from 'App/components/ListView/ListViewRenderHelpers';

export default function updateContacts(
	rb: RequestBuilder,
	clientContacts: boolean = false,
	entity: string,
	extraParams?: object,
	multiSelect?: MultiSelect
) {
	const opts = {
		customerId: Tools.AppService.getCustomerId(),
		filters: rb,
		extraParams,
		clientContacts,
		entity,
		multiSelect
	};
	return Tools.$upModal.open('UpdateContactMulti', opts);
}
