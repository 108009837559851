import React from 'react';
import PropTypes from 'prop-types';
import PlanPhonecallsDrawer from '../PlanPhonecallsDrawer';
import T from 'Components/Helpers/translate';

const PlanPhonecallsAction = props => (
	<PlanPhonecallsDrawer saveBtnLabel={T('default.save')} relativeTime={true} {...props} />
);
PlanPhonecallsAction.propTypes = {
	onSave: PropTypes.func.isRequired
};

export default PlanPhonecallsAction;
