import React, { useMemo } from 'react';
import { DropDownButton, DropDownMenu } from '@upsales/components';
import BemClass from '@upsales/components/Utils/bemClass';
import { FilterConfig } from 'App/babel/filterConfigs/FilterConfig';
import { getConfig, isInactiveValue } from 'App/helpers/filterHelper';
import useSelector from 'App/components/hooks/useSelector';
import logError from 'Helpers/logError';
import T from 'Components/Helpers/translate';
import moment from 'moment';
import datePresetService from 'App/upsales/common/services/datePresetService';
import { ListViewFilter } from 'App/resources/AllIWant';
import './SalesboardFilterButton.scss';
import BooleanFilter from './Filters/BooleanFilter';
import TimeFilterComponent from './Filters/TimeFilter/TimeFilter';
import TextFilterComponent from './Filters/TextFilter';
import DateFilterComponent from './Filters/DateFilter';
import NumberFilterComponent from './Filters/NumberFilter';
import ListFilterComponent from './Filters/ListFilter';
import { FilterItem } from './Filters/ListFilter/ListFilterTypes';
import { DateFilter } from './Filters/DateFilter/DateFilter';
import { TimeFilter } from './Filters/TimeFilter/TimeFilter';
import { TextFilter } from './Filters/TextFilter/TextFilter';
import { NumberFilter } from './Filters/NumberFilter/NumberFilter';
import { Metadata } from 'App/babel/resources/ResourceTyped';

const isCustomUser = (filterConfig: FilterConfig) =>
	filterConfig.type === 'custom' && ['User', 'Users'].includes(filterConfig.$field?.datatype ?? '');

const isList = (filterConfig: FilterConfig) =>
	isCustomUser(filterConfig) ||
	(filterConfig.type === 'custom' && ['Select'].includes(filterConfig.$field?.datatype ?? '')) ||
	(filterConfig.type !== 'custom' && ['selectGroup', 'select', 'list'].includes(filterConfig.inputType ?? ''));
const isDate = (filterConfig: FilterConfig) =>
	(filterConfig.type === 'custom' && ['Date'].includes(filterConfig.$field?.datatype ?? '')) ||
	(filterConfig.type !== 'custom' && ['date'].includes(filterConfig.inputType ?? ''));
const isRange = (filterConfig: FilterConfig) =>
	(filterConfig.type === 'custom' &&
		['Integer', 'Percent', 'Currency'].includes(filterConfig.$field?.datatype ?? '')) ||
	(filterConfig.type !== 'custom' && ['range'].includes(filterConfig.inputType ?? ''));
const isTime = (filterConfig: FilterConfig) =>
	filterConfig.type === 'custom' && ['Time'].includes(filterConfig.$field?.datatype ?? '');
const isBoolean = (filterConfig: FilterConfig) =>
	(filterConfig.type === 'custom' && ['Boolean'].includes(filterConfig.$field?.datatype ?? '')) ||
	(filterConfig.type !== 'custom' && ['boolean'].includes(filterConfig.inputType ?? ''));
const isText = (filterConfig: FilterConfig) =>
	(filterConfig.type === 'custom' &&
		['Text', 'String', 'Link', 'Email'].includes(filterConfig.$field?.datatype ?? '')) ||
	(filterConfig.type !== 'custom' && ['text'].includes(filterConfig.inputType ?? ''));

export type RangeFilter = Omit<ListViewFilter, 'value'> & { value: { preset: string; start: string; end: string } };

const rangeEmpty = function (val?: string | null) {
	return val === '' || val === null || val === undefined;
};

const getValueText = (filterConfig: FilterConfig, filter: ListViewFilter) => {
	if (isDate(filterConfig)) {
		const dateFilter = filter as DateFilter;
		if (dateFilter.value?.preset === 'custom') {
			/* eslint-enable eqeqeq */
			let start = dateFilter.value.start || '',
				end = dateFilter.value.end || '',
				separator = start || end ? ' - ' : '';

			if (start) {
				start = moment(start).format('L');
			}
			if (end) {
				end = moment(end).format('L');
			}
			return start + separator + end || T('date.whenever');
		} else if (dateFilter.value.preset) {
			const preset = datePresetService.get(dateFilter.value.preset);
			return T(preset.name);
		} else {
			return T('date.whenever');
		}
	} else if (isTime(filterConfig)) {
		const timeFilter = { ...filter } as TimeFilter;
		let start = timeFilter.value?.start || '',
			end = timeFilter.value?.end || '',
			separator = start || end ? ' - ' : '';

		if (start) {
			start = moment(start, 'HH:mm').format('LT');
		}
		if (end) {
			end = moment(end, 'HH:mm').format('LT');
		}
		return start + separator + end || '';
	} else if (isRange(filterConfig)) {
		const rangeFilter = filter as RangeFilter;
		if (!rangeEmpty(rangeFilter.value?.start) && !rangeEmpty(rangeFilter.value?.end)) {
			return rangeFilter.value.start + ' - ' + rangeFilter.value.end;
		} else if (!rangeEmpty(rangeFilter.value?.start)) {
			return '> ' + rangeFilter.value.start;
		} else if (!rangeEmpty(rangeFilter.value?.end)) {
			return '< ' + rangeFilter.value.end;
		}
	} else if (isBoolean(filterConfig)) {
		if (filter.value === null) {
			return T('default.all');
		} else {
			return T(filter.value ? 'default.yes' : 'default.no');
		}
	} else if (isText(filterConfig)) {
		return `${filter.value}*`;
	} else {
		return filter.value ?? T('default.all');
	}
};

type Props = {
	resetOnSelectAll?: boolean;
	filter: ListViewFilter;
	onChange: (value: ListViewFilter) => void;
	filterConfigs: {
		[name: string]: FilterConfig<any>;
	};
	trackers?: {
		onOpen: (filterName: string) => void;
		onSelect: (filterName: string) => void;
	};
};

export default function SalesboardFilterButton({ filter, onChange, filterConfigs, trackers, resetOnSelectAll }: Props) {
	const className = new BemClass('SalesboardFilterButton');
	const orderCustomFields = useSelector(state => state.App.customFields.order);
	const filterConfig = useMemo(
		() => getConfig(filter.filterName, filterConfigs, orderCustomFields, 'order'),
		[filter.filterName]
	);

	if (!filterConfig) {
		logError(`Failed to load filterConfig for ${filter.filterName}`);
		return null;
	}

	const valueText = getValueText(filterConfig, filter);
	const subtitle = (valueText ? valueText : '') as string;

	const valueChanged = (value: ListViewFilter['value']) => {
		const updatedFilter = { ...filter, value };
		updatedFilter.inactive = isInactiveValue(updatedFilter, filterConfig);
		onChange(updatedFilter);
	};

	const reset = (comparisonType: ListViewFilter['comparisonType']) => {
		const updatedFilter = { ...filter, comparisonType, value: [] };
		updatedFilter.inactive = isInactiveValue(updatedFilter, filterConfig);
		onChange(updatedFilter);
	};

	const getFilterComponent = (filterConfig: FilterConfig, setClosed: () => void) => {
		if (isText(filterConfig)) {
			return (
				<TextFilterComponent filter={filter as TextFilter} setClosed={setClosed} valueChanged={valueChanged} />
			);
		} else if (isDate(filterConfig)) {
			return (
				<DateFilterComponent
					filter={filter as DateFilter}
					filterConfig={filterConfig}
					valueChanged={valueChanged}
				/>
			);
		} else if (isBoolean(filterConfig)) {
			return <BooleanFilter valueChanged={valueChanged} />;
		} else if (isTime(filterConfig)) {
			return (
				<TimeFilterComponent
					filter={filter as TimeFilter}
					filterConfig={filterConfig}
					valueChanged={valueChanged}
				/>
			);
		} else if (isRange(filterConfig)) {
			return (
				<NumberFilterComponent
					filter={filter as NumberFilter}
					filterConfig={filterConfig}
					valueChanged={valueChanged}
				/>
			);
		}

		return null;
	};
	if (filterConfig.inputType === 'list') {
		filterConfig.inputType = 'selectGroup';
	}
	if (isList(filterConfig)) {
		const comparisonTypeChanged = (comparisonType: ListViewFilter['comparisonType']) => {
			const updatedFilter = { ...filter, comparisonType };
			updatedFilter.inactive = isInactiveValue(updatedFilter, filterConfig);
			onChange(updatedFilter);
		};

		if (!filter.value) {
			filter.inactive = true;
		}

		return (
			<ListFilterComponent
				className={className.b()}
				filter={filter}
				filterConfig={filterConfig}
				reset={reset}
				resetOnSelectAll={resetOnSelectAll}
				valueChanged={valueChanged}
				comparisonTypeChanged={comparisonTypeChanged}
				getDataPromise={(searchStr?: string, offset: number = 0) => {
					if (filterConfig.dataPromise) {
						const dataPromise = filterConfig.dataPromise as (
							...injectables: any
						) => Promise<{ data: FilterItem[]; metadata?: Metadata }>;
						return dataPromise();
					} else if (filterConfig.searchFn) {
						return filterConfig.searchFn()(searchStr ?? '', ['id', 'name'], offset, 50);
					} else {
						return Promise.resolve({ data: [] });
					}
				}}
				trackers={trackers}
			/>
		);
	}

	return (
		<DropDownMenu
			className={className.b()}
			align="right"
			renderTrigger={(expanded, setExpanded) => (
				<DropDownButton
					expanded={expanded}
					size="sm"
					title={T(
						typeof filterConfig.title === 'function' ? filterConfig.title() : filterConfig.title ?? ''
					)}
					subtitle={subtitle}
					color="white"
					onClick={event => setExpanded(event)}
				/>
			)}
		>
			{setClosed => getFilterComponent(filterConfig, setClosed)}
		</DropDownMenu>
	);
}
