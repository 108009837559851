import PropTypes from 'prop-types';
import React from 'react';
import _ from 'lodash';

const propTypes = {
	modalParams: PropTypes.object,
	onClose: PropTypes.func,
	entity: PropTypes.string,
	isFromPdfTemplates: PropTypes.bool,
	excludedTags: PropTypes.arrayOf(PropTypes.oneOfType([PropTypes.string, PropTypes.instanceOf(RegExp)]))
};

class ModalTagList extends React.Component {
	constructor(props) {
		super(props);

		this.state = {
			search: '',
			lastCopiedTag: null
		};

		this.renderListItems = this.renderListItems.bind(this);
	}

	getOrderRowtagValues = tag => {
		if (tag.useMath) {
			if (tag.valueThree) {
				if (tag.useFormatter) {
					return `currencyFormatter (math (math this.${tag.valueOne} '${tag.operator}' this.${tag.valueTwo}) '${tag.secondOperator}' ${tag.valueThree})`;
				}
				return `math (math this.${tag.valueOne} '${tag.operator}' this.${tag.valueTwo}) '${tag.secondOperator}' ${tag.valueThree}`;
			}
			if (tag.useFormatter) {
				return `currencyFormatter (math this.${tag.valueOne} '${tag.operator}' this.${tag.valueTwo})`;
			}
			return `math this.${tag.valueOne} '${tag.operator}' this.${tag.valueTwo}`;
		}
		if (tag.useFormatter) {
			return `currencyFormatter this.${tag.value}`;
		}
		return `this.${tag.value}`;
	};

	getTagValueForPdfTemplate = tag => {
		const KeyValueArray = tag.pdfTemplateTag.split('.');
		if (tag.rowProperty) {
			return tag.value;
		}
		if (tag.orderRow) {
			return this.getOrderRowtagValues(tag);
		}
		if (tag.useMath) {
			if (tag.useFormatter) {
				return `currencyFormatter (math ${tag.valueOne} '${tag.operator}' ${tag.valueTwo})`;
			}
			return `math ${tag.valueOne} '${tag.operator}' ${tag.valueTwo}`;
		}
		if (tag.useFormatter) {
			return `currencyFormatter ${tag.value}`;
		}
		if (KeyValueArray[0] === this.props.entity) {
			return tag.pdfTemplateTag;
		} else {
			if (KeyValueArray[0] === 'general') {
				return tag.pdfTemplateTag;
			}
			if (KeyValueArray[0] === 'custom') {
				let objectPath = this.props.entity;
				if (tag.nameMapper && tag.nameMapper !== 'order') {
					if (tag.nameMapper === 'Order_Row') {
						objectPath = 'this';
					} else {
						objectPath = `${this.props.entity}.${tag.nameMapper}`;
					}
				}
				return `customField ${objectPath} ${tag.tagId}`;
			}
			return `${this.props.entity}.${tag.pdfTemplateTag}`;
		}
	};

	copyToClipBoard(tag) {
		if (this.props.isFromPdfTemplates) {
			document.getElementById('tag-copy-element').value = '{{{' + tag + '}}}';
		} else {
			document.getElementById('tag-copy-element').value = '{{' + tag + '}}';
		}
		document.getElementById('tag-copy-element').select();

		try {
			document.execCommand('copy');
			this.setState({
				lastCopiedTag: tag
			});

			setTimeout(() => this.setState({ lastCopiedTag: null }), 1500);
		} catch (err) {
			console.err('Copy Error', err);
		}
	}

	onSearch(value) {
		const state = Object.assign({}, this.state);
		state.search = value;
		this.setState(state);
	}

	renderListItems(tags) {
		tags = _.filter(tags, tag => {
			if (
				!this.state.search.length ||
				tag.name.toLowerCase().indexOf(this.state.search.toLowerCase() || '') !== -1 ||
				tag.tag.toLowerCase().indexOf(this.state.search.toLowerCase() || '') !== -1
			) {
				return true;
			}

			return false;
		});
		if (this.props.isFromPdfTemplates) {
			const groupedTags = _.groupBy(_.sortBy(tags, 'name'), 'entity');
			return _.map(groupedTags, (tagGroup, entity) => {
				let entityName = Tools.$translate(`default.${entity}`);
				if (entity === 'undefined') {
					entityName = Tools.$translate(`default.other`);
				}
				return (
					<React.Fragment key={entity}>
						<div className="ModalTagList--header" key="header">
							{entityName}
						</div>
						{tagGroup.map((tag, index) => {
							if (!tag.pdfTemplateTag) {
								return null;
							}
							const tagValue = this.getTagValueForPdfTemplate(tag);

							return (
								<li key={index} onClick={() => this.copyToClipBoard(tagValue)}>
									<div className="value">{tag.name}</div>
									<div className="name">{`{{{${tagValue}}}}`}</div>
									{this.state.lastCopiedTag !== tagValue ? (
										<div className="copy">{Tools.$translate('default.copy')}</div>
									) : null}
									{this.state.lastCopiedTag === tagValue ? (
										<div className="copied">
											<i className="fa fa-check" />
											{Tools.$translate('default.copiedToClipboard')}
										</div>
									) : null}
								</li>
							);
						})}
					</React.Fragment>
				);
			});
		}
		return _.map(tags, tag => {
			return (
				<li key={tag.tag} onClick={() => this.copyToClipBoard(tag.tag)}>
					<div className="value">{tag.name}</div>
					<div className="name">{`{{${tag.tag}}}`}</div>
					{this.state.lastCopiedTag !== tag.tag ? (
						<div className="copy">{Tools.$translate('default.copy')}</div>
					) : null}
					{this.state.lastCopiedTag === tag.tag ? (
						<div className="copied">
							<i className="fa fa-check" />
							{Tools.$translate('default.copiedToClipboard')}
						</div>
					) : null}
				</li>
			);
		});
	}

	render() {
		let tags = Tools.TagsService.getTagsByEntity(
			this.props.entity || this.props.modalParams.tagEntity || this.props.modalParams.entity.toLowerCase()
		);
		if (this.props.isFromPdfTemplates) {
			const classicTags = Tools.TagService.getClassicTagsByEntity(
				this.props.entity || this.props.modalParams.tagEntity
			);
			const pdfTempateClassicTagList = classicTags
				.filter(tag => tag.pdfTemplateTag)
				.map(tag => ({ ...tag, entity: this.props.entity || this.props.modalParams.tagEntity }));
			tags = tags.concat(pdfTempateClassicTagList);
		}

		tags = _.uniq(tags, 'tag');

		// Filter away all tags that are in props.excludedTags
		if (this.props.excludedTags && this.props.excludedTags.length)
			tags = _.filter(tags, tag =>
				this.props.excludedTags.every(eTag => {
					if (eTag instanceof RegExp) {
						return !eTag.test(tag.tag);
					} else {
						return eTag !== tag.tag;
					}
				})
			);

		return (
			<div id="tag-list">
				<h2>{Tools.$translate('tag.available')}</h2>
				<button
					type="button"
					id="hide-tags-btn"
					className="up-btn btn-link no-shadow btn-grey btn-hover-white"
					onClick={() => this.props.onClose()}
				>
					<b className="fa fa-chevron-left" />
				</button>
				<p className="text-grey info-text">{Tools.$translate('tag.intro')}</p>
				<div id="tag-search">
					<b className="fa fa-search" />
					<input
						type="text"
						onChange={input => this.onSearch(input.target.value)}
						placeholder={Tools.$translate('default.search')}
					/>
				</div>

				<input type="text" id="tag-copy-element" />

				<ul id="tags-list-ul">{this.renderListItems(tags)}</ul>
			</div>
		);
	}
}

ModalTagList.propTypes = propTypes;

ModalTagList.defaultProps = {
	isFromPdfTemplates: false
};

window.ModalTagList = ModalTagList;
export default ModalTagList;
