import React, { useEffect, useState, useMemo } from 'react';
import { DropDownButton, DropDownMenu } from '@upsales/components';
import { FilterConfig } from 'App/babel/filterConfigs/FilterConfig';
import logError from 'Helpers/logError';
import T from 'Components/Helpers/translate';
import SelectUserGroup from 'Components/Modals/SelectUserGroup/SelectUserGroup';
import { difference, union } from 'lodash';
import { ListViewFilter } from 'App/resources/AllIWant';
import { Metadata } from 'App/babel/resources/ResourceTyped';
import { ComparisonTypeName, GroupedComparisonTypes } from 'Resources/ComparisonTypes';
import {
	FilterItem,
	isRoleFilter,
	isStageGroups,
	FilterItemValue,
	FilterItemWithId,
	StageGroupFilterItem
} from './ListFilterTypes';

type ListFilter<T> = Omit<ListViewFilter, 'value'> & { value: T[] };
type ListFilterProps<T> = {
	className: string;
	filter: ListFilter<T>;
	filterConfig: FilterConfig;
	reset?: (comparisonType: ComparisonTypeName) => void;
	resetOnSelectAll?: boolean;
	valueChanged: (value: ListFilter<T>['value']) => void;
	comparisonTypeChanged: (comparisonType: ComparisonTypeName) => void;
	getDataPromise: (
		searchStr?: string,
		offset?: number
	) => Promise<{ data: FilterItem[] | StageGroupFilterItem[]; metadata?: Metadata }>;
	trackers?: {
		onOpen: (filterName: string) => void;
		onSelect: (filterName: string) => void;
	};
};

const updateSelected = (data: FilterItem[], values: FilterItemValue[]) => {
	for (const item of data) {
		if (isRoleFilter(item)) {
			item.children.forEach((childItem: any) => updateSelected([childItem], values));
			item.$$selected = item.children.every((childItem: any) => childItem.$$selected);
		} else {
			item.$$selected = values.includes(item.id);
		}
	}
};

const findDeep = (data: FilterItem[], value: FilterItemValue): FilterItem | undefined => {
	for (const item of data) {
		if (isRoleFilter(item)) {
			const foundItem = findDeep(item.children, value);
			if (foundItem) {
				return foundItem;
			}
		} else {
			const isMatch = value === item.id;
			if (isMatch) {
				return item;
			}
		}
	}
};

const selectDeep = function (item: FilterItem): FilterItemValue[] {
	if (!isRoleFilter(item)) {
		return [item.id];
	} else {
		return item.children.reduce((memo: any, childItem: any) => {
			return memo.concat(selectDeep(childItem));
		}, []);
	}
};

function ListFilterComponent({
	className,
	filter,
	filterConfig,
	valueChanged,
	comparisonTypeChanged,
	getDataPromise,
	trackers,
	reset,
	resetOnSelectAll
}: ListFilterProps<FilterItemValue>) {
	const [data, setData] = useState<FilterItem[]>([]);
	const [searchStr, setSearchStr] = useState('');
	const [metadata, setMetadata] = useState<Metadata | undefined>();
	const [selectedRows, setSelectedRows] = useState<FilterItemValue[]>(filter.value);

	const getData = async (searchString: string) => {
		try {
			let { data: newData, metadata } = await getDataPromise(searchString);
			if (!newData) {
				return;
			}
			if (isStageGroups(filterConfig, newData)) {
				newData = [...newData[0].data, ...newData[1].data];
			}
			const selected = data.filter(item => item.$$selected);
			for (const item of selected) {
				if (
					!newData.find(newItem => {
						return (
							(isRoleFilter(newItem) ? newItem.$id : newItem.id) ===
							(isRoleFilter(item) ? item.$id : item.id)
						);
					})
				) {
					newData.splice(0, 0, item);
				}
			}
			updateSelected(newData, filter.value);
			setData(newData);
			setMetadata(metadata);
		} catch (error) {
			logError(error, `Failed to load data for ${filterConfig.filterName}`);
		}
	};

	useEffect(() => {
		getData(searchStr);
	}, [filter, searchStr]);

	const valueText = useMemo(() => {
		if (filter.inactive || !data.length) {
			return T('default.all');
		} else {
			const found = findDeep(data, filter.value[0]);
			if (found) {
				return typeof filterConfig.searchField === 'function'
					? filterConfig.searchField(found)
					: found[(filterConfig.searchField as keyof typeof filterConfig.searchField) ?? 'name'];
			}
			return filterConfig.type === 'custom' ? filter.value[0] : '';
		}
	}, [data, filter.value, filter.inactive]);

	const title = T(typeof filterConfig.title === 'function' ? filterConfig.title() : filterConfig.title ?? '');
	const subtitle =
		filter.value?.length > 1
			? `${valueText} ${T('default.and').toLowerCase()} ${filter.value.length - 1} ${T(
					'filters.more'
			  ).toLowerCase()}`
			: valueText;

	const select = (value: FilterItemValue[]) => {
		if (trackers?.onSelect) {
			trackers.onSelect(filter.filterName);
		}
		updateSelected(data, value);
		valueChanged(value);
	};

	const internalReset = (comparisonType: ComparisonTypeName) => {
		if (reset) {
			if (trackers?.onSelect) {
				trackers.onSelect(filter.filterName);
			}
			updateSelected(data, []);
			reset(comparisonType);
			setSelectedRows([]);
		}
	};

	const canSelectMultiple =
		filterConfig.multiple || GroupedComparisonTypes.Match.includes(filter.comparisonType as ComparisonTypeName);

	return (
		<DropDownMenu
			className={className}
			align="right"
			renderTrigger={(expanded, setExpanded) => (
				<DropDownButton
					expanded={expanded}
					size="sm"
					title={title}
					subtitle={subtitle ? subtitle.toString() : undefined}
					color="white"
					onClick={event => {
						if (trackers?.onOpen && !expanded) {
							trackers.onOpen(filter.filterName);
						}
						setExpanded(event);
					}}
				/>
			)}
		>
			<SelectUserGroup
				results={data}
				resultsMetadata={metadata}
				reset={internalReset}
				resetOnSelectAll={resetOnSelectAll}
				selectAll={() => {
					select([]);
					setSelectedRows([]);
				}}
				select={(item: FilterItemWithId, selected: boolean) => {
					if (canSelectMultiple) {
						const newValue = selected
							? [...filter.value, item.id]
							: filter.value.filter(id => id !== item.id);
						select(newValue);
						setSelectedRows(newValue);
					} else {
						select([item.id]);
					}
				}}
				selectOne={(item: FilterItemWithId) => {
					select([item.id]);
					setSelectedRows([item.id]);
				}}
				selectGroup={(item: FilterItem, selected: boolean) => {
					const values = selectDeep(item);
					const fn = selected ? union : difference;
					const newValue = fn(filter.value, values);
					select(newValue);
					setSelectedRows(newValue);
				}}
				allSelected={!filter.value.length}
				async={(searchStr: string) => {
					setSearchStr(searchStr);
				}}
				asyncOffset={(offset?: number) => {
					return getDataPromise(searchStr, offset);
				}}
				selectedComparator={filter.comparisonType}
				selectedRows={selectedRows}
				displayText={filterConfig.displayText ?? 'name'}
				title={title}
				firstSelectedText={valueText}
				hideEmptyRoles={true}
				singleSelect={!canSelectMultiple}
				toggleComparator={(comparisonType: ComparisonTypeName) => comparisonTypeChanged(comparisonType)}
				required={filterConfig.type === 'raw'}
				useVirtualList={filterConfig.displayType === 'listLazy' && filterConfig.filterName === 'Campaign'}
			/>
		</DropDownMenu>
	);
}

export default ListFilterComponent;
