import React from 'react';
import BarchartWidgetBar from '../BarchartWidgetBar';
import { getPercent, getTooltip, loadDrilldown } from '../../chartHelper';
import bemClass from '@upsales/components/Utils/bemClass';
import '../BarchartWidgetBars/BarchartWidgetBars.scss';
import { Text, Tooltip } from '@upsales/components';
import { RCWidgetData, RCWidgetDataRowShape } from 'Resources/ReportWidget';
import type { RCDashboardWidget } from 'Resources/ReportDashboard';

type Props = {
	row: RCWidgetDataRowShape;
	small?: boolean;
	maxValue: number;
	minValue: number;
	data: RCWidgetData;
	value: string;
	isFirstRow: boolean;
	isNegativeValue?: boolean;
	drilldown: boolean;
	config: RCDashboardWidget;
	showGoal: boolean;
};
const BarchartWidgetStackedBars = ({
	row,
	small = false,
	maxValue,
	minValue,
	data,
	value,
	isNegativeValue,
	drilldown,
	config,
	showGoal
}: Props) => {
	const classes = new bemClass('BarchartWidgetBars').mod({ small });
	let left = 0;

	// In cases where another row has negative value, but this row does not
	let negativeRows;
	if (minValue < 0) {
		negativeRows = row.rows.filter(subRow => subRow.progress < 0);
		const rowsNegativeValue = negativeRows.reduce((sum, subRow) => sum + subRow.progress, 0);
		left = Math.abs(minValue - rowsNegativeValue);
	}

	const zeroLine = Math.abs(minValue);
	// Place negative rows first if there are both negative and positive, because negative values should be
	// left of zero line
	const orderedRows = negativeRows ? negativeRows.concat(row.rows.filter(subRow => subRow.progress >= 0)) : row.rows;

	const widthPercentage = orderedRows.map(row => {
		const absProgress = Math.abs(row.progress ?? 0);
		const percentage = (Math.max(1, absProgress) / maxValue) * 100;
		return `${percentage}%`;
	});

	const { bars } = orderedRows.reduce(
		(memo, subRow, index) => {
			const subRowProg = subRow.progress ?? 0;
			if (!subRowProg) {
				return memo;
			}
			const subRowProgPercent = widthPercentage[index];
			const leftPercent = getPercent(left, maxValue);
			left += Math.abs(subRow.progress);
			const { color } = data.colors[subRow.key];
			memo.bars.push(
				<Tooltip
					key={subRow.key + '-tooltip'}
					html={true}
					title={getTooltip(subRowProg, 0, 0, data, false, color, subRow.label)}
					style={{ width: subRowProgPercent, left: leftPercent }}
					theme="white"
				>
					<BarchartWidgetBar
						key={subRow.key}
						small={small}
						width={'100%'}
						backgroundColor={color}
						onClick={
							drilldown && data.enableDrilldown
								? e => {
										e.stopPropagation();
										loadDrilldown([row.key.toString(), subRow.key.toString()], config);
								  }
								: undefined
						}
						roundedLeft={index === 0 && (minValue === 0 || negativeRows?.length)}
						roundedRight={index === orderedRows.length - 1 && (!showGoal || row.remaining <= 0)}
					/>
				</Tooltip>
			);
			return memo;
		},
		{ bars: [] as JSX.Element[] }
	);

	return (
		<div key={row.key} className={classes.b()}>
			<div className={classes.elem('sub-row-wrap').b()}>
				{bars}
				{showGoal && row.remaining > 0 ? (
					<BarchartWidgetBar
						key="left"
						small={small}
						width={getPercent(row.goal, maxValue)}
						left={getPercent(zeroLine, maxValue)}
						backgroundColor={data.colors?.goal?.color}
						roundedRight
						future
					/>
				) : null}
			</div>
			{!small ? (
				<div className={classes.elem('floating-value').b()} style={{ right: 0 }}>
					<Text size="sm" color={isNegativeValue ? 'red' : 'black'} bold>
						{value}
					</Text>
				</div>
			) : null}
			{minValue < 0 ? (
				<div
					className={classes.elem('zero-stripe').b()}
					style={{ left: getPercent(Math.abs(minValue), maxValue) }}
				/>
			) : null}
		</div>
	);
};

export default BarchartWidgetStackedBars;
