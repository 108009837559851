import { FilterConfig, FilterObj, MinifiedFilter } from 'App/babel/filterConfigs/FilterConfig';
import RequestBuilder from 'Resources/RequestBuilder';
import ComparisonTypes, { ComparisonTypeName } from 'Resources/ComparisonTypes';
import { renderContent as renderJourneyStepFilterRow } from 'Components/Filters/JourneyStepFilterRow';
import ClientResource from 'App/resources/Client';
import ClientAttributes from 'App/babel/attributes/Client';
import ProductResource from 'Resources/Product';
import ProductAttributes from '../attributes/ProductAttributes';
import ProjectResource from 'App/resources/Project';
import ProjectAttributes from '../attributes/Project';
import ContactResource from 'App/resources/Contact';
import ContactAttributes from '../attributes/ContactAttributes';
import FormResource from 'App/resources/Form';
import FormAttributes from 'App/babel/attributes/FormAttributes';
import TaskFilter from 'App/babel/angular/attributes/filter/taskFilter';
import ActivityFilter from 'App/babel/angular/attributes/filter/activityFilter';
import VisitorFilter from 'App/babel/angular/attributes/filter/visitorFilter';
import OrderFilter from 'App/babel/angular/attributes/filter/orderFilter';
import OpportunityFilter from 'App/babel/angular/attributes/filter/opportunityFilter';
import AddressFilter from 'App/upsales/common/attributes/filter/addressFilter';
import datePresets from './filterDatePresets';
import User from 'App/resources/Model/User';
import _ from 'lodash';
import AccessType from 'App/enum/AccessType';
import store from 'Store/index';
import UserRoleTree from 'Components/Helpers/UserRoleTree';
import parse from 'App/upsales/common/components/listFilters/filterParse';
import MailCampaignFilter from 'App/babel/angular/attributes/filter/mailCampaignFilter';
import FormSubmitFilter from '../angular/attributes/filter/formSubmitFilter';
import AccountManagerFilter from 'App/upsales/common/attributes/filter/accountManagerFilter';
import type { AppState } from 'Store/reducers/AppReducer';
import StaticValue from 'App/resources/Model/StaticValue';
import ticketFilter from '../angular/attributes/filter/ticketFilter';
import categoryFilterBaseConfig from '../filters/CategoryFilterConfig';

export const dateFilterPresets = datePresets['standard'];

export enum FilterPreset {
	// Basic filters
	Date = 'date',
	List = 'list',
	ListLazy = 'listLazy',
	Number = 'number',
	Radio = 'radio',
	Range = 'range',
	RawValue = 'rawValue',
	RawValueComparison = 'rawValueComparison',
	Text = 'text',

	// General, non-basic filters
	HistoryFilter = 'historyFilter',
	NotNullRadio = 'notNullRadio',
	RawDate = 'rawDate',
	RawRadio = 'rawRadio',
	ResourceLazyList = 'resourceLazyList',
	StaticSelectList = 'staticSelectList',
	StaticValueLookup = 'staticValueLookup',

	// Special purpose filters
	Campaign = 'campaign',
	Currency = 'currency',
	ClientCategory = 'clientCategory',
	HasOrder = 'hasOrder',
	JourneyStep = 'journeyStep',
	LeadSource = 'leadSource',
	ListSearch = 'listSearch',
	Notes = 'notes',
	Parent = 'parent',
	Product = 'product',
	Role = 'role',
	Stage = 'stage',
	User = 'user',
	Visit = 'visit',
	LastAssigned = 'lastAssigned',
	AccountManager = 'accountManager'
}

type Result = { id: string; name: string; count: number };
type SearchResource = 'client' | 'product' | 'project' | 'contact' | 'form';

type BasicFilters =
	| FilterPreset.Date
	| FilterPreset.List
	| FilterPreset.ListLazy
	| FilterPreset.Number
	| FilterPreset.Radio
	| FilterPreset.Range
	| FilterPreset.RawValue
	| FilterPreset.RawValueComparison
	| FilterPreset.Text;

type RequireFieldFilters =
	| FilterPreset.List
	| FilterPreset.ListLazy
	| FilterPreset.Range
	| FilterPreset.Radio
	| FilterPreset.Text
	| FilterPreset.Currency
	| FilterPreset.ClientCategory
	| FilterPreset.Role
	| FilterPreset.Visit;

type SpecialFilters =
	| FilterPreset.Date
	| FilterPreset.HasOrder
	| FilterPreset.HistoryFilter
	| FilterPreset.LeadSource
	| FilterPreset.ListSearch
	| FilterPreset.Stage
	| FilterPreset.Product
	| FilterPreset.RawDate
	| FilterPreset.RawRadio
	| FilterPreset.ResourceLazyList
	| FilterPreset.StaticSelectList
	| FilterPreset.StaticValueLookup
	| FilterPreset.LastAssigned
	| FilterPreset.User;

type DefaultFilters = Exclude<FilterPreset, RequireFieldFilters | SpecialFilters>;

type DefaultReq = Record<DefaultFilters, [overload?: Partial<FilterConfig>]>;
type FieldReq = Record<RequireFieldFilters, [overload: { field: string } & Partial<FilterConfig>]>;
type SpecialReq = {
	date: [overload: { field: string } & Partial<FilterConfig>, dates: keyof typeof datePresets];
	hasOrder: [overload: Partial<FilterConfig>, type?: 'order' | 'opportunity'];
	historyFilter: [
		overload: { title: string } & Partial<FilterConfig>,
		filterType:
			| 'task'
			| 'activity'
			| 'order'
			| 'opportunity'
			| 'address'
			| 'mailCampaign'
			| 'visitor'
			| 'formSubmit'
			| 'ticket',
		buildParams?: { [key: string]: any }
	];
	leadSource: [overload: Partial<FilterConfig>, entity: string, entities: string, filterField: string];
	lastAssigned: [overload: Partial<FilterConfig>, dates: string, field: string];
	listSearch: [overload: Partial<FilterConfig>, buildFields: string[]];
	product: [overload: Partial<FilterConfig>, field: string | string[]];
	stage: [overload: { field: string } & Partial<FilterConfig>, orderOnly: boolean];
	staticSelectList: [overload: Partial<FilterConfig>, field: string];
	staticValueLookup: [overload: Partial<FilterConfig>, field: string];
	rawDate: [overload: { build: Function } & Partial<FilterConfig>, dates: keyof typeof datePresets];
	rawRadio: [overload: { build: Function } & Partial<FilterConfig>];
	resourceLazyList: [overload: Partial<FilterConfig>, resource: SearchResource];
	user: [overload: Partial<FilterConfig>, accesstype?: AccessType | null];
};

type Requirements = { [T in FilterPreset]: (DefaultReq & FieldReq & SpecialReq)[T] };

const categoryFilterBase = () => ({ ...categoryFilterBaseConfig } as Partial<FilterConfig>);

const rbBuildWildcard = (buildFilters: string[]) => (filter: FilterObj, rb: RequestBuilder) => {
	if (filter.value?.length) {
		var orBuilder = rb.orBuilder();

		buildFilters.forEach(value => {
			orBuilder.next();
			orBuilder.addFilter({ field: value }, ComparisonTypes.Wildcard, filter.value);
		});

		orBuilder.done();
	}
};

export const generateSearchFn: (resourceType: SearchResource) => FilterConfig['searchFn'] = resourceType => () => {
	const resources = {
		client: { res: ClientResource, attr: ClientAttributes, findOpts: {} },
		product: { res: ProductResource, attr: ProductAttributes, findOpts: { checkAccess: true } },
		project: { res: ProjectResource, attr: ProjectAttributes, findOpts: {} },
		contact: { res: ContactResource, attr: ContactAttributes, findOpts: {} },
		form: { res: FormResource, attr: FormAttributes, findOpts: {} }
	};
	const { res, attr, findOpts = {} } = resources[resourceType];

	return (term, fields, offset, limit) => {
		const filter = new RequestBuilder();

		filter.fields = fields;
		filter.offset = offset;
		filter.limit = limit;
		filter.addSort(attr.name, true);
		if (term) {
			filter.addFilter(attr.name, ComparisonTypes.Search, term);
		}
		if ('active' in attr) {
			filter.addFilter(attr.active, ComparisonTypes.Equals, true);
		}

		return res.find(filter.build(), findOpts);
	};
};

export const getLoggedInUserFilter = function (): Partial<Omit<User, 'id'>> & { id: 'self' } {
	return {
		id: 'self',
		email: 'self@upsales.com',
		name: Tools.$translate('default.loggedInUser'),
		administrator: true,
		active: true
	};
};

const basicFilters: { [key in BasicFilters]: (...args: any[]) => Partial<FilterConfig> } = {
	// Some basic filters define both type and displaytype so that type can be overridden with 'raw' without breaking display
	date: (dates: keyof typeof datePresets) => ({
		inputType: 'date',
		type: 'dateRange',
		// Cloning here to be safe; some parts of the code mutates preset arrays
		presets: _.clone(datePresets[dates])
	}),
	list: () => ({
		type: 'list',
		inputType: 'list',
		multiple: true,
		multiComparitors: true,
		searchField: 'name',
		displayText: 'name'
	}),
	listLazy: () => ({
		type: 'list',
		displayType: 'listLazy',
		displayText: 'name',
		inputType: 'select',
		rowComponent: 'listRow',
		search: true,
		includeFields: ['name', 'id'],
		searchField: 'name',
		multiple: true,
		multiComparitors: true,
		tooltip: true
	}),
	number: () => ({
		type: 'text',
		inputType: 'number'
	}),
	range: () => ({
		type: 'range',
		inputType: 'range',
		displayType: 'range'
	}),
	radio: () => ({
		type: 'radio',
		inputType: 'radio',
		displayType: 'radio',
		options: [
			{ text: 'default.all', inactive: true, value: null },
			{ text: 'default.with', value: true, comparisonType: 'Equals' },
			{ text: 'default.without', value: false, comparisonType: 'Equals' }
		]
	}),
	rawValue: () => ({
		type: 'raw',
		generate: () => ({ value: null }),
		toUrl: filter => ({ v: filter.value }),
		fromUrl: rawFilter => ({ value: rawFilter.v })
	}),
	rawValueComparison: () => ({
		type: 'raw',
		// FormSubmit did not set comparisonType in generate. Should be fine?
		generate: () => ({ value: null, comparisonType: 'Equals' }),
		toUrl: filter => ({ v: filter.value, c: filter.comparisonType } as MinifiedFilter),
		fromUrl: rawFilter => ({
			value: rawFilter.v,
			comparisonType: rawFilter.c as keyof typeof ComparisonTypes
		})
	}),
	text: () => ({
		inputType: 'text',
		type: 'text'
	})
};

const presetFilters: Record<FilterPreset, (...args: any[]) => Partial<FilterConfig>> = {
	...basicFilters,
	historyFilter: (
		filterType:
			| 'task'
			| 'activity'
			| 'order'
			| 'opportunity'
			| 'address'
			| 'mailCampaign'
			| 'visitor'
			| 'formSubmit'
			| 'ticket',
		buildParams = {}
	) => {
		const objMap = {
			task: TaskFilter,
			activity: ActivityFilter,
			visitor: VisitorFilter,
			formSubmit: FormSubmitFilter,
			order: OrderFilter,
			opportunity: OpportunityFilter,
			address: AddressFilter,
			mailCampaign: MailCampaignFilter,
			ticket: ticketFilter
		};
		const extraParamMap = {
			task: { presets: datePresets['activity'] },
			activity: { presets: datePresets['activity'] },
			visitor: { presets: datePresets['standard'] },
			formSubmit: { presets: datePresets['standard'] },
			order: { presets: datePresets['order'] },
			opportunity: { presets: datePresets['order'] },
			address: {
				inputType: 'address',
				displayType: 'address',
				availableAddresses: ['Visit', 'Mail', 'Delivery', 'Invoice', 'Other']
			},
			mailCampaign: { presets: datePresets['order'] },
			ticket: {}
		};

		const filterObj = objMap[filterType];
		const extraParams = extraParamMap[filterType];

		return {
			parent: 'filter.sale',
			type: 'raw',
			displayType: 'HistoryFilter',
			entity: 'account',
			getText: filterObj.getText,
			generate: filterObj.generate,
			isInactive: filterObj.isInactive,
			toUrl: filterObj.toUrl,
			fromUrl: filterObj.fromUrl,
			build: filterObj.build(buildParams),
			...extraParams
		};
	},
	notNullRadio: () => ({
		type: 'notNull',
		displayType: 'radio',
		options: [
			{ text: 'default.all', inactive: true },
			{ text: 'default.yes', value: true },
			{ text: 'default.no', value: false }
		]
	}),
	rawDate: (dates: keyof typeof datePresets) => ({
		...basicFilters.date(dates),
		type: 'raw',
		displayType: 'dateRange',
		generate: () => ({ value: { preset: 'whenever' } })
	}),
	rawRadio: () => ({
		...basicFilters.radio(),
		type: 'raw',
		generate: () => ({ value: null })
	}),
	resourceLazyList: (resource: SearchResource) => ({
		...basicFilters.listLazy(),
		field: resource + '.id',
		searchFn: generateSearchFn(resource),
		resource: _.capitalize(resource),
		title: 'default.' + resource
	}),
	staticSelectList: (field: string) => ({
		field,
		type: 'list',
		unreleasedFeature: 'NEW_FIELDS',
		inputType: 'select',
		dataPromise: () =>
			Promise.resolve({
				data: Tools.AppService.getStaticValues(field)
			}),
		searchField: 'text',
		parent: 'filters.columns.account',
		comparisonType: 'MatchExact'
	}),
	staticValueLookup: (field: string): FilterConfig => {
		const staticValuePromise = Tools.AppService.getStaticValuesPromise(field).then(result => {
			// eslint-disable-next-line you-dont-need-lodash-underscore/reduce
			return _.reduce(
				result as StaticValue[],
				(idMap, staticValue) => {
					idMap[staticValue.id] = staticValue;
					return idMap;
				},
				{} as { [key: string]: StaticValue }
			);
		});

		return {
			type: 'list',
			displayType: 'listLazy',
			comparisonType: 'MatchExact',
			unreleasedFeature: 'NEW_FIELDS',
			parent: 'filters.columns.account',
			field: field,
			columnPath: field,
			title: 'tag.account.' + field,
			resource: () => {
				return (customerId, values) => {
					return staticValuePromise.then(result => {
						const data = _.map(values, id => {
							const staticValue = result[id];
							const name = staticValue ? staticValue.name : '';
							return { id: id, name: name };
						});
						return { data: data, metadata: { total: 0 } };
					});
				};
			},
			searchFn: (Account, AppService, Lookup) => {
				return (term, fields, offset, limit) => {
					const { customerId } = store.getState().App;
					const lookUpPromise = Lookup.customer(customerId).setType('account').findEnd(field, '', 1000);

					return Promise.all([staticValuePromise, lookUpPromise]).then(result => {
						const searchTerm = (term || '').trim().toLowerCase();

						const mappedData = _.map(result[1].data, (item: { value: string }) => {
							const id = item.value;
							const staticValue = result[0][id];
							const name = staticValue ? staticValue.name : id;
							return { id: id, name: name };
						}).filter(item => {
							return (item.name || '').toLowerCase().indexOf(searchTerm) > -1;
						});
						const data = _.sortBy(mappedData, 'name').slice(offset, offset + limit);
						const metadata = { total: mappedData.length, offset: offset, limit: limit };
						return { data: data, metadata: metadata };
					});
				};
			}
		};
	},

	campaign: () => ({
		...basicFilters.listLazy(),
		field: 'project.id',
		searchFn: generateSearchFn('project'),
		title: 'default.campaigns',
		columnPath: 'campaigns',
		resource: 'Campaign'
	}),
	currency: () => {
		const metadata = Tools.AppService.getMetadata();
		const currencies = metadata?.customerCurrencies;
		const show = currencies && currencies.length && metadata.params.MultiCurrency;
		return {
			hide: !show,
			title: 'default.currency',
			inputType: 'select',
			type: 'list',
			displayText: (obj: { iso: string }) => obj.iso,
			dataPromise: () =>
				Promise.resolve({
					data: _.sortBy(currencies, 'iso').map(c => ({ ...c, id: c.iso }))
				}),
			searchField: 'iso',
			comparisonType: 'Match',
			multiComparitors: false,
			multiple: false
		};
	},
	clientCategory: () => ({
		...categoryFilterBase(),
		isInactive: filter => !filter.value || !filter.value.length,
		inputType: 'list',
		field: 'client.category.id',
		displayType: 'list',
		title: 'default.accountCategories',
		dataPromise: () =>
			Promise.resolve({
				data: Tools.AppService.getCategories('account').filter(
					({ categoryType }: { categoryType: number }) => categoryType === 0
				)
			}),
		parent: 'default.account',
		build: function (filter, rb, getField) {
			if (filter.inactive || !filter.value || !filter.value.length) {
				return;
			}

			const comparisonType =
				filter.comparisonType === 'NotEquals' ? ComparisonTypes.NotEquals : ComparisonTypes.Equals;

			const groupFilter = rb.groupBuilder();
			groupFilter.addFilter(getField('client.category.id'), comparisonType, filter.value);
			groupFilter.done();
		}
	}),
	hasOrder: (type: 'order' | 'opportunity' = 'order') => ({
		...basicFilters.radio(),
		type: 'raw',
		generate: () => ({ value: null }),
		build: (filter, rb) => {
			const val = filter.value;
			const comp = type === 'order' ? ComparisonTypes.Equals : ComparisonTypes.NotEquals;

			const group = rb.groupBuilder();
			if (val === null) {
				return;
			} else {
				group.addFilter({ field: 'client.order.probability' }, comp, 100);
				group.addFilter({ field: 'client.order.id' }, ComparisonTypes.NotEquals, null);
				if (val === false) {
					group.isNotFilter();
				}
				group.done();
			}
			rb.addFilter({ field: 'contact.client.id' }, ComparisonTypes.NotEquals, null);
		}
	}),
	journeyStep: () => ({
		field: 'journeyStep',
		type: 'list',
		filterName: 'JourneyStep',
		title: 'default.journeyStep',
		dataPromise: () =>
			Promise.resolve({
				data: Tools.AppService.getJourneySteps().map(j => ({ ...j, id: j.value }))
			}),
		displayComponent: item => renderJourneyStepFilterRow(item)
	}),
	leadSource: (entity: string, entities: string, filterField: string) => ({
		...basicFilters.rawValueComparison(),
		filterName: 'LeadSource',
		title: 'default.leadsource',
		displayType: 'listLeadSource',
		dataPromise:
			(FilterHelper: typeof Tools.FilterHelper, LeadSource) =>
			(activeFilters): Promise<{ data: any[] }> => {
				const rb = new RequestBuilder();
				// eslint-disable-next-line you-dont-need-lodash-underscore/omit
				const filters = _.omit(_.cloneDeep(activeFilters), 'LeadSource');
				return LeadSource.getAllSources(FilterHelper.parseFilters(filters, entity, rb).build(), {
					type: entities
				});
			},
		build: function (filter, rb) {
			if (filter.value && filter.value.length) {
				const selectedTypes: { [key: string]: boolean } = {};
				const selectedSource: { [key: string]: boolean } = {};
				if (filter.comparisonType !== 'NotEquals') {
					const Or = rb.orBuilder();

					_.each(filter.value, function (item) {
						if (!item.source) {
							Or.next();
							Or.addFilter({ field: filterField + '.type' }, ComparisonTypes.Equals, item.type);
							selectedTypes[item.type] = true;
						}
					});

					_.each(filter.value, function (item) {
						if (!item.value && !selectedTypes[item.type]) {
							Or.next();
							Or.addFilter({ field: filterField + '.source' }, ComparisonTypes.Equals, item.source);
							Or.addFilter({ field: filterField + '.type' }, ComparisonTypes.Equals, item.type);
							selectedSource[item.source] = true;
						}
					});

					_.each(filter.value, function (item) {
						if (!selectedSource[item.source] && !selectedTypes[item.type]) {
							Or.next();
							Or.addFilter({ field: filterField + '.source' }, ComparisonTypes.Equals, item.source);
							Or.addFilter({ field: filterField + '.type' }, ComparisonTypes.Equals, item.type);
							Or.addFilter({ field: filterField + '.value' }, ComparisonTypes.Equals, item.value);
						}
					});

					Or.done();
				} else {
					_.each(filter.value, function (item) {
						if (!item.source) {
							const Group = rb.groupBuilder();
							Group.addFilter({ field: filterField + '.type' }, ComparisonTypes.Equals, item.type);
							Group.isNotFilter();
							Group.done();
							selectedTypes[item.type] = true;
						}
					});

					_.each(filter.value, function (item) {
						if (!item.value && !selectedTypes[item.type]) {
							const Group = rb.groupBuilder();
							Group.addFilter({ field: filterField + '.source' }, ComparisonTypes.Equals, item.source);
							Group.addFilter({ field: filterField + '.type' }, ComparisonTypes.Equals, item.type);
							Group.isNotFilter();
							Group.done();
							selectedSource[item.source] = true;
						}
					});

					_.each(filter.value, function (item) {
						if (!selectedSource[item.source] && !selectedTypes[item.type]) {
							const Group = rb.groupBuilder();
							Group.addFilter({ field: filterField + '.source' }, ComparisonTypes.Equals, item.source);
							Group.addFilter({ field: filterField + '.type' }, ComparisonTypes.Equals, item.type);
							Group.addFilter({ field: filterField + '.value' }, ComparisonTypes.Equals, item.value);
							Group.isNotFilter();
							Group.done();
						}
					});
				}
			}
		}
	}),
	listSearch: buildFields => ({
		...basicFilters.rawValue(),
		filterName: 'ListSearch',
		build: rbBuildWildcard(buildFields)
	}),
	notes: () => ({
		inputType: 'text',
		type: 'raw',
		generate: () => ({ value: null }),
		build: (filter, rb, getField) => {
			if (filter.value) {
				const orBuilder = rb.orBuilder();

				orBuilder.next();
				orBuilder.addFilter(getField('comment.description'), ComparisonTypes.Search, filter.value);

				orBuilder.next();
				orBuilder.addFilter(getField('notes'), ComparisonTypes.Wildcard, filter.value);

				orBuilder.done();
			}
		},
		title: 'default.notes',
		displayType: 'text'
	}),
	parent: () => ({
		...basicFilters.listLazy(),
		field: 'parent.name',
		columnPath: 'parent',
		includeFields: undefined,
		dataPromise: (customerId, Account, Lookup) => {
			return (
				Lookup.customer(customerId)
					.setType('account')
					.findEnd(Account.attr.parent.attr.name, '', 1000) as Promise<{
					data: { value: string; count: number }[];
				}>
			).then(res => {
				const data = res.data.reduce<Result[]>((res, item) => {
					if (item.value) {
						res.push({ id: item.value, name: item.value, count: item.count });
					}
					return res;
				}, []);
				return { data: _.sortBy(data, 'name') };
			});
		},
		searchFn: (Account, AppService, Lookup) => {
			const customerId = AppService.getCustomerId();

			return function (term) {
				return Lookup.customer(customerId)
					.setType('account')
					.findEnd(Account.attr.parent.attr.name, term.toLowerCase(), 1000)
					.then((res: { data: { value: string; count: number }[] }) => {
						const data = res.data.reduce<Result[]>((res, item) => {
							if (item.value) {
								res.push({ id: item.value, name: item.value, count: item.count });
							}
							return res;
						}, []);
						return { data: data };
					});
			};
		},
		displayText: 'name',
		title: 'default.parentCompany'
	}),
	product: (field: string | string[]) => {
		const defaultConfig = {
			...basicFilters.rawValueComparison(),
			title: 'default.products',
			multiple: true,
			displayText: 'name',
			showInactiveToggle: true,
			isInactive: filter => !(filter && filter.value && filter.value.length),
			build: function (filter, rb, getField) {
				function addFilter(builder: RequestBuilder, field: string) {
					if (filter.comparisonType === 'NotEquals') {
						var groupFilter = builder.groupBuilder();
						groupFilter.isNotFilter();
						groupFilter.addFilter(getField(field), ComparisonTypes.Equals, filter.value);
						groupFilter.done();
					} else {
						builder.addFilter(getField(field), ComparisonTypes.Equals, filter.value);
					}
				}

				if (filter.value && filter.value.length) {
					if (Array.isArray(field)) {
						const orBuilder = rb.orBuilder();
						for (const subField of field) {
							orBuilder.next();
							addFilter(orBuilder, subField);
						}
						orBuilder.done();
					} else {
						addFilter(rb, field);
					}
				}
			}
		} as Partial<FilterConfig>;

		if (Tools.AppService.getTotals('products') > 4000) {
			const searchConfig = {
				displayType: 'listLazy',
				search: true,
				searchField: 'name',
				resource: 'Product',
				inputType: 'select',
				searchFn: generateSearchFn('product')
			};
			return { ...defaultConfig, ...searchConfig };
		} else {
			const dataPromiseConfig = {
				displayType: 'virtualizedList',
				inputType: 'selectGroup',
				searchField: 'name',
				dataPromise: () => {
					return Tools.ProductTreeFilterMeta(true, '');
				}
			};
			return { ...defaultConfig, ...dataPromiseConfig };
		}
	},
	role: () => ({
		...basicFilters.list(),
		title: 'default.role',
		dataPromise: () =>
			Promise.resolve({
				data: Tools.AppService.getRoles()
			}),
		parent: 'default.user',
		includeFields: ['name', 'id']
	}),
	stage: (orderOnly = false) => ({
		...basicFilters.list(),
		title: 'default.stages',
		inputType: 'select',
		dataPromise: async () => {
			const createObj = (stage: keyof AppState['stages'], title: string) => ({
				data: Tools.AppService.getStages(stage),
				isGroup: true,
				title: Tools.$translate(title),
				options: {
					secondary: {
						text: 'probability',
						suffix: '%'
					}
				}
			});

			const result = [createObj('won', 'order.order')];
			if (!orderOnly) {
				result.push(createObj('opportunity', 'default.opportunity.singular'));
			}

			return { data: result };
		}
	}),
	user: (accessType = null) => ({
		...basicFilters.list(),
		inputType: 'selectGroup',
		field: 'user.id',
		groupParent: 'role.id',
		groupParentTitle: 'role.name',
		noParent: 'default.otherUsers',
		title: 'default.user',
		dataPromise: async () => {
			const data = UserRoleTree({ accessType });

			if ([AccessType.ACCOUNT, AccessType.CONTACT].includes(accessType)) {
				data.unshift({
					id: null,
					name: Tools.$translate('default.noAccountManager'),
					active: true
				});
			}

			data.unshift(getLoggedInUserFilter());

			return { data };
		}
	}),
	accountManager: () => ({
		title: 'filter.accountManagerFilter',
		type: 'raw',
		getText: AccountManagerFilter.getText,
		generate: AccountManagerFilter.generate,
		isInactive: AccountManagerFilter.isInactive,
		toUrl: AccountManagerFilter.toUrl,
		fromUrl: AccountManagerFilter.fromUrl,
		build: AccountManagerFilter.build()
	}),
	visit: () => ({
		type: 'list',
		title: 'visit.country',
		resource: 'Visitor',
		multiComparitors: true,
		dataPromise: (customerId, Visitor, Lookup) => {
			return (
				Lookup.customer(customerId).setType('visit').findEnd(Visitor.attr.country, '', 1000) as Promise<{
					data: { value: string; count: number }[];
				}>
			).then(res => {
				const data = res.data.reduce<Result[]>((res, item) => {
					if (typeof item.value === 'string') {
						const country = Tools.CountryCodes.getCountry(item.value.toUpperCase());
						const name = country ? Tools.$translate(country.lang) : item.value;
						res.push({ id: item.value, name: name, count: item.count });
					}
					return res;
				}, []);
				return { data: _.sortBy(data, 'name') };
			});
		}
	}),
	lastAssigned: (dates: keyof typeof datePresets, field: string) => ({
		...basicFilters.date(dates),
		title: 'assign.lastAssigned',
		field: field,
		inputType: 'date',
		multiComparitors: true,
		displayType: 'dateRange',
		type: 'raw',
		generate: () => ({ value: null }),
		toUrl: filter => ({ v: filter.value, c: filter.comparisonType }),
		fromUrl: rawFilter => ({
			value: rawFilter.v,
			comparisonType: rawFilter.c as ComparisonTypeName
		}),
		build: (filter, rb) => {
			const equals = filter.comparisonType === 'Equals';

			const group = rb.groupBuilder();
			const values = parse.dateRange(group, field, filter.value, 'YYYY-MM-DD');

			if (values) {
				if (!equals) {
					group.isNotFilter();
				}
				group.done();
			}
		}
	})
};

export function generateFilter<T extends FilterPreset>(preset: T, ...props: Requirements[T]) {
	const [overload, args] = [props[0], props.slice(1)];
	return { ...presetFilters[preset](...args), ...overload } as FilterConfig;
}
