import './EditOrderSaveButton.scss';

import React, { useState } from 'react';
import BemClass from '@upsales/components/Utils/bemClass';
import { Block, OutsideClick, Button, Icon, Card, Tooltip } from '@upsales/components';
import T from 'Components/Helpers/translate';

type Props = {
	onSave?: () => void;
	onSaveAndContinue?: () => void;
	disabled?: boolean;
	disabledTooltip?: string;
};

const EditOrderSaveButton = ({ onSave, onSaveAndContinue, disabled, disabledTooltip }: Props) => {
	const [open, setOpen] = useState(false);
	const classes = new BemClass('EditOrderSaveButton');

	const toggleExpanded = () => setOpen(!open);

	const renderSaveButton = () => (
		<div className={classes.elem('saveButtonWrapper').mod({ open }).b()}>
			<Button
				type="link"
				disabled={disabled}
				onClick={onSave || toggleExpanded}
				className={classes.elem('saveButton').b()}
			>
				{T('order.save')}
			</Button>
			<Button
				type="link"
				disabled={disabled}
				onClick={toggleExpanded}
				className={classes.elem('toggleIcon').mod({ open }).b()}
			>
				<Icon name="caret-up" />
			</Button>
		</div>
	);

	const renderSaveAndContinueButton = () => (
		<div className={classes.elem('dropdownWrapper').b()}>
			<Card className={classes.elem('dropdownMenu').b()}>
				<Button
					type="link"
					disabled={disabled}
					className={classes.elem('saveAndContinueButton').b()}
					onClick={() => {
						setOpen(false);
						onSaveAndContinue?.();
					}}
				>
					{T('order.saveAndContinue')}
				</Button>
			</Card>
			<div className={classes.elem('hidingOverflow').b()} />
		</div>
	);

	return (
		<Block space="mll" className={classes.b()}>
			<Tooltip disabled={!disabled || !disabledTooltip} title={disabledTooltip}>
				<OutsideClick targetClass="EditOrderSaveButton" outsideClick={() => setOpen(false)} listen={open}>
					{renderSaveButton()}
					{open && renderSaveAndContinueButton()}
				</OutsideClick>
			</Tooltip>
		</Block>
	);
};

export default EditOrderSaveButton;
