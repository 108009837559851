import React from 'react';
import { connect } from 'react-redux';

import { saveView, showSaveView } from 'Store/reducers/SharedViewsReducer';
import StandardList from '../Lists/StandardList';
import T from '../../Helpers/translate';
import Bem from '@upsales/components/Utils/bemClass';
import { Title, Button } from '@upsales/components';

import './StandardView.scss';

const mapStateToProps = state => ({
	standardList: state.SharedViews.list,
	sharedList: state.SharedViews.sharedList,
	privateList: state.SharedViews.privateList,
	selectedView: state.SharedViews.selectedView
});

const mapDispatchToProps = {
	saveView,
	showSaveView
};

function StandardView({ privateList, sharedList, standardList, changeView, isChangingView, showSaveView }) {
	const StandardClass = new Bem('StandardView');
	const listArray = [];

	if (privateList.length) {
		listArray.push([
			<Title key="private-list-title" className={StandardClass.elem('Title')}>
				{T('sharedViews.myViews')}
			</Title>,
			<StandardList
				key="private-standard-list"
				listType="private"
				list={privateList}
				changeView={changeView}
				disableChangeView={isChangingView}
			/>
		]);
	}

	if (sharedList.length) {
		listArray.push([
			<Title
				key="shared-views-title"
				className={StandardClass.elem('Title').mod({ Secondary: privateList.length })}
			>
				{T('sharedViews.sharedViews')}
			</Title>,
			<StandardList
				key="shared-views-list"
				listType="shared"
				list={sharedList}
				changeView={changeView}
				disableChangeView={isChangingView}
			/>
		]);
	}

	listArray.push([
		<Title key="standard-views-title" className={StandardClass.elem('Title').mod('Secondary')}>
			{T('sharedViews.standardViews')}
		</Title>,
		<StandardList
			key="standard-views-list"
			changeView={changeView}
			listType="standard"
			list={standardList}
			removeDefault={true}
			disableChangeView={isChangingView}
		/>
	]);

	return (
		<div className={StandardClass}>
			<Button
				className={StandardClass.elem('SaveAs').b()}
				type="link"
				onClick={() => {
					showSaveView({ createNew: true });
				}}
			>
				{T('filters.saveViewAs')}
			</Button>
			{_.flatten(listArray)}
		</div>
	);
}

export const detached = StandardView;
export default connect(mapStateToProps, mapDispatchToProps)(StandardView);
