import React from 'react';
import TimelineGeneric from '../TimelineGeneric/TimelineGeneric';

import { Link } from '@upsales/components';
import type { TimelineCardComponent } from '../TimelineGeneric/TimelineCardConfigs';
import { useTranslation } from 'Components/Helpers/translate';

const actionMap = {
	Invited: 'socialEvent.isInvitedTo',
	Attending: 'socialEvent.isAttendingTo',
	Attended: 'socialEvent.attendedTo',
	Declined: 'socialEvent.declinedTo'
} as const;

const rsvpMap = {
	Invited: 'socialEvent.status.invited',
	Attending: 'socialEvent.status.attending',
	Attended: 'socialEvent.status.attended',
	Declined: 'socialEvent.status.declined'
} as const;

const TimelineCardSocialEvent = (({ event, renderedByType, ...passthrough }) => {
	const { socialEvent, entityId, contacts = [], score } = event || {};
	const { t } = useTranslation();

	if (!socialEvent) {
		return null;
	}

	const subType = event.subType as keyof typeof actionMap;
	const action = actionMap[subType] ?? 'socialEvent.isInvitedTo';

	const isContact = renderedByType === 'contact';

	const filteredContacts = !isContact ? contacts.filter(c => c.status === event.subType?.toLowerCase()) : contacts;

	return (
		<TimelineGeneric
			event={{
				...event,
				contacts: filteredContacts
			}}
			actionTitle={action}
			titleElements={[
				filteredContacts.length ? 'contacts' : () => <b>{t('default.someone')}</b>,
				'action',
				() => (
					<Link
						href={Tools.$state.href('react-root-editSocialEvent', {
							id: entityId,
							customerId: Tools.AppService.getCustomerId()
						})}
					>
						{socialEvent.name}
					</Link>
				),
				() => (score ? <b>{`(${score}p)`}</b> : <></>)
			]}
			afterTime={
				isContact || !filteredContacts.length
					? undefined
					: e => `${filteredContacts.length} / ${contacts.length} ${t(rsvpMap[subType]).toLocaleLowerCase()}`
			}
			contactPreposition={''}
			{...passthrough}
			hasAvatar={false}
		/>
	);
}) satisfies TimelineCardComponent;

export default TimelineCardSocialEvent;
