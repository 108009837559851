import Event from 'App/resources/Model/Event';
import Resource from './Resource';
import { ACTIVITY_TIMELINE } from '../enum/timeline';

export type ActivityEvent = Event & {
	type: (typeof ACTIVITY_TIMELINE)[keyof typeof ACTIVITY_TIMELINE];
};

type ActivityEventRes = { data: ActivityEvent[]; metadata: { total: number } };

class ActivityEvents extends Resource {
	dateFields = ['date', 'data.oldDate', 'data.closeDate', 'data.regDate', 'data.date'];

	constructor() {
		super('events');
	}

	getEvents(activityId: number, limit?: number | null, offset?: number | null) {
		const customerId = Tools.AppService.getCustomerId();
		return this._getRequest(`${activityId}/events`, {
			_url: `${customerId}/activities`,
			params: { limit, offset }
		}).then(res => {
			res.data.data = res.data.data.map((event: Event) => this._parse(event));
			return res.data as ActivityEventRes;
		});
	}
}

const resource = new ActivityEvents();

export default resource;
