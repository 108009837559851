import { FilterConfig } from 'App/babel/filterConfigs/FilterConfig';
import AccessType from 'App/enum/AccessType';
import ComparisonTypes from 'Resources/ComparisonTypes';
import moment from 'moment';
import Category from 'App/resources/Model/Category';
import { AnyAction } from 'redux';
import { getCategories } from 'Store/actions/AppGetterActions';
import store from 'Store/index';
import { generateFilter, FilterPreset } from '../filterGenerators';
import Import from 'App/resources/Import';
import activityFilter from 'App/babel/angular/attributes/filter/activityFilter';

export default (): { [key: string]: FilterConfig } => ({
	// No parent
	Name: generateFilter(FilterPreset.Text, {
		field: 'name',
		title: 'default.name'
	}),
	Phone: generateFilter(FilterPreset.Text, {
		field: 'phone',
		title: 'default.phone'
	}),
	Parent: generateFilter(FilterPreset.Parent),
	User: generateFilter(
		FilterPreset.User,
		{
			title: 'default.accountManager',
			field: 'user.id'
		},
		AccessType.ACCOUNT
	),
	Campaign: generateFilter(FilterPreset.Campaign),
	ContactName: generateFilter(FilterPreset.Text, {
		title: 'filters.contactname',
		field: 'contact.name'
	}),
	HasContacts: generateFilter(FilterPreset.RawRadio, {
		title: 'default.contacts',
		build: (filter, rb) => {
			const val = filter.value;

			if (val == null) {
				return;
			} else if (val === true) {
				const groupBuilder = rb.groupBuilder();
				groupBuilder.addFilter({ field: 'contact.active' }, ComparisonTypes.Equals, true);
				groupBuilder.addFilter({ field: 'contact.id' }, ComparisonTypes.GreaterThan, 0);
				groupBuilder.done();
			} else {
				const groupBuilder = rb.groupBuilder();
				groupBuilder.isNotFilter();
				groupBuilder.addFilter({ field: 'contact.active' }, ComparisonTypes.Equals, true);
				groupBuilder.addFilter({ field: 'contact.id' }, ComparisonTypes.GreaterThan, 0);
				groupBuilder.done();
			}
		}
	}),
	JourneyStep: generateFilter(FilterPreset.JourneyStep),
	Active: {
		field: 'active',
		listTitle: 'default.activeInactive',
		title: 'filters.companyStatus',
		type: 'radio',
		options: [
			{ text: 'default.all', inactive: true },
			{ text: 'default.activeContacts', value: true, comparisonType: 'Equals' },
			{ text: 'default.inactiveContacts', value: false, comparisonType: 'Equals' }
		]
	},
	SubAccounts: {
		field: 'operationalAccount.id',
		title: 'account.subaccounts',
		type: 'radio',
		inputType: 'radio',
		options: [
			{ text: 'default.show', inactive: true, comparisonType: 'NotEquals' },
			{ text: 'default.hide', value: null, comparisonType: 'Equals' },
			{ text: 'default.only', value: null, comparisonType: 'NotEquals' }
		]
	},
	Notes: generateFilter(FilterPreset.Notes),

	// Section Categories
	StandardCategory: {
		field: 'category.id',
		inputType: 'select',
		dataPromise: () => {
			const categories = store.dispatch(
				getCategories('account', false) as unknown as AnyAction
			) as unknown as Category[];
			return Promise.resolve({ data: categories });
		},
		searchField: 'name',
		displayText: 'name',
		title: 'default.categories',
		multiComparitors: true,
		multiple: true,
		type: 'list',
		columnPath: 'categories',
		parent: 'default.categories'
	},

	// Section Marketing
	Score: generateFilter(FilterPreset.Range, {
		title: 'default.score',
		field: 'score',
		columnPath: 'score',
		parent: 'navbar.market'
	}),
	ScoreUpdateDate: generateFilter(
		FilterPreset.Date,
		{
			title: 'default.scoreUpdate',
			field: 'scoreUpdateDate',
			columnPath: 'scoreUpdateDate',
			parent: 'navbar.market'
		},
		'future'
	),
	HasVisit: generateFilter(FilterPreset.RawRadio, {
		title: 'default.siteVisits',
		parent: 'navbar.market',
		build: (filter, rb) => {
			const val = filter.value;

			if (val == null) {
				return;
			} else if (val === true) {
				rb.addFilter({ field: 'visit.id' }, ComparisonTypes.NotEquals, null);
			} else {
				rb.addFilter({ field: 'visit.id' }, ComparisonTypes.Equals, null);
			}
		}
	}),
	HasMail: generateFilter(FilterPreset.RawRadio, {
		title: 'default.mailing',
		parent: 'navbar.market',
		build: (filter, rb) => {
			const val = filter.value;

			if (val == null) {
				return;
			} else if (val === true) {
				const group = rb.groupBuilder();
				group.addFilter({ field: 'mail.mailCampaign.id' }, ComparisonTypes.GreaterThan, 0);
				group.addFilter({ field: 'mail.errorCause' }, ComparisonTypes.Equals, null);
				group.done();
			} else {
				const notGroup = rb.groupBuilder();
				notGroup.addFilter({ field: 'mail.mailCampaign.id' }, ComparisonTypes.GreaterThan, 0);
				notGroup.addFilter({ field: 'mail.errorCause' }, ComparisonTypes.Equals, null);
				notGroup.isNotFilter();
				notGroup.done();
			}
		}
	}),
	HasFormSubmit: generateFilter(FilterPreset.RawRadio, {
		title: 'event.types.Form',
		parent: 'navbar.market',
		// don't know if the date filters is needed
		build: (filter, rb) => {
			const val = filter.value;

			if (val == null) {
				return;
			}
			let start;
			let end;
			let isExclude = false;

			if (typeof val === 'object') {
				if (val.start && moment(val.start).isValid()) {
					start = val.start;
				}
				if (val.end && moment(val.end).isValid()) {
					end = val.end;
				}
				if (val.exclude) {
					isExclude = true;
				}
			} else if (val === false) {
				isExclude = true;
			}
			if (val && !isExclude) {
				// has submit
				if (start) {
					rb.addFilter({ field: 'formSubmit.regDate' }, ComparisonTypes.GreaterThanEquals, val.start);
				}
				if (end) {
					rb.addFilter({ field: 'formSubmit.regDate' }, ComparisonTypes.LessThanEquals, val.end);
				}
				rb.addFilter({ field: 'formSubmit.id' }, ComparisonTypes.NotEquals, null);
			} else {
				// missing submit
				// Find out if we need a group filter
				if (start && end) {
					// need both to work
					const groupBuilder = rb.groupBuilder();
					groupBuilder.isNotFilter();
					groupBuilder.addFilter(
						{ field: 'formSubmit.regDate' },
						ComparisonTypes.GreaterThanEquals,
						val.start
					);
					groupBuilder.addFilter({ field: 'formSubmit.regDate' }, ComparisonTypes.LessThanEquals, val.end);
					groupBuilder.addFilter({ field: 'formSubmit.id' }, ComparisonTypes.NotEquals, null);
					groupBuilder.done();
				} else {
					rb.addFilter({ field: 'formSubmit.id' }, ComparisonTypes.Equals, null);
				}
			}
		}
	}),

	// Section Company fields
	RegistrationDate: generateFilter(
		FilterPreset.Date,
		{
			title: 'default.founded',
			unreleasedFeature: 'NEW_FIELDS',
			field: 'registrationDate',
			parent: 'filters.columns.account'
		},
		'future'
	),
	RegDate: generateFilter(
		FilterPreset.Date,
		{
			title: 'default.created',
			field: 'regDate',
			parent: 'filters.columns.account'
		},
		'future'
	),
	SniCode: generateFilter(FilterPreset.StaticValueLookup, {}, 'sniCode'),
	SniCodeRange: generateFilter(FilterPreset.Range, {
		title: 'tag.account.sniCodeRange',
		field: 'sniCode',
		parent: 'filters.columns.account'
	}),
	SicCode: generateFilter(FilterPreset.StaticValueLookup, {}, 'sicCode'),
	NaicsCode: generateFilter(FilterPreset.StaticValueLookup, {}, 'naicsCode'),
	UKSicCode: generateFilter(FilterPreset.StaticValueLookup, {}, 'ukSicCode'),
	NaceCode: generateFilter(FilterPreset.StaticValueLookup, {}, 'naceCode'),
	Status: generateFilter(FilterPreset.StaticSelectList, { title: 'default.status' }, 'status'),
	CompanyForm: generateFilter(FilterPreset.StaticSelectList, { title: 'soliditet.corporateForm' }, 'companyForm'),
	CreditRating: generateFilter(FilterPreset.StaticSelectList, { title: 'default.creditRating' }, 'creditRating'),
	Turnover: generateFilter(FilterPreset.Range, {
		title: 'default.turnover',
		unreleasedFeature: 'NEW_FIELDS',
		field: 'turnover',
		parent: 'filters.columns.account'
	}),
	Profit: generateFilter(FilterPreset.Range, {
		title: 'default.profit',
		unreleasedFeature: 'NEW_FIELDS',
		field: 'profit',
		parent: 'filters.columns.account'
	}),
	NoEmployees: generateFilter(FilterPreset.Range, {
		title: 'default.numOfEmployees',
		unreleasedFeature: 'NEW_FIELDS',
		field: 'noEmployees',
		parent: 'filters.columns.account'
	}),
	OrgNo: generateFilter(FilterPreset.Text, {
		field: 'orgNo',
		unreleasedFeature: 'NEW_FIELDS',
		title: 'default.orgNumber',
		parent: 'filters.columns.account'
	}),
	OrgNoLookup: generateFilter(
		FilterPreset.StaticValueLookup,
		{
			field: 'orgNo',
			comparisonType: 'MatchExact',
			unreleasedFeature: 'NEW_FIELDS',
			title: 'default.orgNumber',
			parent: 'filters.columns.account',
			hide: true
		},
		'orgNo'
	),
	Id: generateFilter(FilterPreset.Number, {
		title: 'default.id',
		field: 'id',
		columnPath: 'id',
		parent: 'filters.columns.account'
	}),
	FromImport: generateFilter(FilterPreset.List, {
		field: 'importId',
		inputType: 'select',
		dataPromise: () => Import.find({ status: Import.status.FINISHED, limit: 500, f: ['id', 'file'] }),
		searchField: 'file.filename',
		displayText: imprt => (imprt.file ? imprt.file.filename : ''),
		title: 'import.import',
		parent: 'filters.columns.account'
	}),

	// Section Sales History
	HasTodo: generateFilter(
		FilterPreset.HistoryFilter,
		{
			title: 'default.todo'
		},
		'task',
		{ phonecall: false }
	),
	HasPhonecall: generateFilter(
		FilterPreset.HistoryFilter,
		{
			title: 'default.phonecall'
		},
		'task',
		{ phonecall: true }
	),
	HasAppointment: generateFilter(
		FilterPreset.HistoryFilter,
		{
			title: 'default.appointment',
			build: activityFilter.buildOnlyAppointment()
		},
		'activity'
	),
	HasActivity: generateFilter(
		FilterPreset.HistoryFilter,
		{
			title: 'default.activity'
		},
		'activity'
	),
	HasOpportunity: generateFilter(
		FilterPreset.HistoryFilter,
		{
			title: 'default.opportunity'
		},
		'opportunity',
		{ mixedProductsAndCategories: true }
	),
	HasOrder: generateFilter(
		FilterPreset.HistoryFilter,
		{
			title: 'default.order'
		},
		'order',
		{ mixedProductsAndCategories: true }
	),
	LastContacted: generateFilter(
		FilterPreset.Date,
		{
			title: 'accountGrowth.lastMet',
			field: 'growth.activity.date',
			parent: 'filter.sale'
		},
		'order'
	),

	// Section Contact information
	ContactPhone: generateFilter(FilterPreset.Text, {
		title: 'filters.columns.phoneContact',
		field: 'contact.phone',
		parent: 'filters.columns.contactinformation'
	}),
	Fax: generateFilter(FilterPreset.Text, {
		field: 'fax',
		title: 'default.fax',
		parent: 'filters.columns.contactinformation'
	}),
	AddressFilters: generateFilter(
		FilterPreset.HistoryFilter,
		{
			title: 'default.addresses',
			parent: 'filters.columns.contactinformation'
		},
		'address'
	),
	Webpage: generateFilter(FilterPreset.Text, {
		field: 'webpage',
		title: 'default.webpage',
		parent: 'filters.columns.contactinformation'
	}),

	// Section Support
	HasSupportTickets: generateFilter(
		FilterPreset.HistoryFilter,
		{
			title: 'ticket.tickets',
			parent: 'default.support',
			unreleasedFeature: 'CLIENT_CONTACT_SUPPORT_FILTERS'
		},
		'ticket'
	),
	AccountManagerFilters: generateFilter(FilterPreset.AccountManager, {
		field: 'user.id',
		title: 'filter.accountManagerFilter',
		hide: true
	}),
	MailCampaignFilters: generateFilter(
		FilterPreset.HistoryFilter,
		{
			title: 'filters.advancedMailCampaignFilters',
			hide: true
		},
		'mailCampaign'
	),
	ActivityFilters: generateFilter(
		FilterPreset.HistoryFilter,
		{
			title: 'default.activitiesAndAppointments',
			hide: true
		},
		'activity'
	),
	VisitorFilters: generateFilter(
		FilterPreset.HistoryFilter,
		{
			title: 'event.types.Visit',
			hide: true
		},
		'visitor'
	),
	FormSubmitFilters: generateFilter(
		FilterPreset.HistoryFilter,
		{
			title: 'event.types.Visit',
			hide: true
		},
		'formSubmit'
	),
	OpportunityFilters: generateFilter(
		FilterPreset.HistoryFilter,
		{
			title: 'default.opportunity',
			hide: true
		},
		'opportunity',
		{ mixedProductsAndCategories: false }
	),
	OrderFilters: generateFilter(
		FilterPreset.HistoryFilter,
		{
			title: 'default.sales',
			hide: true
		},
		'order',
		{ mixedProductsAndCategories: false }
	),

	// Quick Search
	ListSearch: generateFilter(FilterPreset.ListSearch, {}, ['name'])
});
