enum ErrorMessages {
	Client = 'Missing client',
	User = 'Missing user',
	Description = 'Missing description',
	Stage = 'Missing stage',
	AgreementCustom = 'Missing agreement custom field',
	OrderRowCustom = 'Missing order row custom field',
	OrderCustom = 'Missing order custom field'
}

export default ErrorMessages;
