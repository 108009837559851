import Resource from './Resource';
import Attributes from 'Attributes/OnboardingCompletedAttributes';

class OnboardingCompleted extends Resource {
	constructor() {
		super('onboardingCompleted', Attributes);
	}
}

const resource = new OnboardingCompleted();
window.Tools.OnboardingCompleted = resource;
export default resource;
