import { BuildFilters } from './RequestBuilder';
import ResourceTyped, { Metadata } from './ResourceTyped';
import Agreement from 'App/resources/Model/Agreement';

class AgreementResource extends ResourceTyped<Agreement> {
	constructor() {
		super('agreements');
	}

	getAgreementsWithtoutConflicts = async (
		filters: BuildFilters,
		indexId?: number
	): Promise<{
		data: Agreement[];
		metadata: Metadata;
		err: Error;
	}> => {
		return super
			._getRequest('withoutConflicts', {
				methodName: 'getAgreementsWithtoutConflicts',
				params: { filters, indexId }
			})
			.then(res => res.data);
	};

	getAgreementsWithConflicts = async (
		filters: BuildFilters,
		indexId?: number
	): Promise<{
		data: Agreement[];
		metadata: Metadata;
		err: Error;
	}> => {
		return super
			._getRequest('withConflicts', {
				methodName: 'getAgreementsWithConflicts',
				params: { filters, indexId }
			})
			.then(res => res.data);
	};
}

const resource = new AgreementResource();

export default resource;
