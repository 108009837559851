import { replaceItem } from 'Store/helpers/array';

export const initialState = {
	notifications: [],
	timers: [],
	total: 0
};

const ADD_NOTIFICATION = '[SystemNotification] ADD_NOTIFICATION';
const SET_NOTIFICATIONS = '[SystemNotification] SET_NOTIFICATIONS';
const ADD_NOTIFICATION_TIMER = '[SystemNotification] ADD_NOTIFICATION_TIMER';
const SET_TIMERS = '[SystemNotification] SET_TIMERS';

export const TYPE = {
	BODY: 'body',
	PROGRESS: 'progress',
	REMINDER: 'reminder',
	INTEGRATION_ERROR: 'integrationError',
	GENERIC_ENTITY_ADDED: 'genericEntityAdded',
	MISSING_ACCOUNT_PROFILE: 'missingAccountProfile'
};

export const STYLE = {
	SUCCESS: 'success',
	ERROR: 'error',
	INFO: 'info',
	WARN: 'warn'
};

const reducer = (state = initialState, action) => {
	switch (action.type) {
		case ADD_NOTIFICATION:
			return { ...state, notifications: [...state.notifications, action.notification], total: state.total + 1 };
		case ADD_NOTIFICATION_TIMER:
			return { ...state, timers: [...state.timers, action.timer] };
		case SET_NOTIFICATIONS:
			return { ...state, notifications: [...action.notifications], total: action.notifications.length };
		case SET_TIMERS:
			return { ...state, timers: [...action.timers] };
		default:
			return state;
	}
};

export default reducer;

/*********** Actions **********/

export const addNotificationObj = notification => {
	return { type: ADD_NOTIFICATION, notification };
};

export const addNotificationTimer = timer => {
	return { type: ADD_NOTIFICATION_TIMER, timer };
};

export const setNotifications = notifications => {
	return { type: SET_NOTIFICATIONS, notifications };
};

export const setTimers = timers => {
	return { type: SET_TIMERS, timers };
};

/***** Dispatched actions *****/

export const removeNotification = id => {
	return (dispatch, getState) => {
		const { notifications } = getState().SystemNotification;
		const i = notifications.findIndex(n => n.id === id);
		if (i !== -1) {
			dispatch(setNotifications(replaceItem(notifications, i, { ...notifications[i], visible: false })));
		}
		// Give it time to animate out
		setTimeout(() => {
			const { notifications } = getState().SystemNotification;
			const i = notifications.findIndex(n => n.id === id);
			if (i !== -1) {
				notifications.splice(i, 1);
				dispatch(setNotifications(notifications));
			}
		}, 300);
	};
};

export const removeAllNotifications = () => {
	return dispatch => {
		dispatch(setNotifications([]));
	};
};

export const holdHideNotification = id => {
	return (dispatch, getState) => {
		const { timers } = getState().SystemNotification;
		const i = timers.findIndex(n => n.id === id);
		if (i !== -1) {
			clearTimeout(timers[i].timer);
			timers.splice(i, 1);
			dispatch(setTimers(timers));
		}
	};
};

const queueHideFunction = (id, body = '', addedSeconds = 0) => {
	const minTimeForNotification = 3000;
	const maxTimeForNotification = 7000;
	const readingSpeed = 200; // words per minute
	const charactersPerWord = 5.8;

	const timePerCharacter = (readingSpeed * charactersPerWord) / 60;
	const notificationTimeout =
		Math.min(Math.max(body.length * timePerCharacter, minTimeForNotification), maxTimeForNotification) +
		addedSeconds;

	return dispatch => {
		dispatch(
			addNotificationTimer({
				id: id,
				timer: setTimeout(() => {
					dispatch(removeNotification(id));
					dispatch(holdHideNotification(id));
				}, notificationTimeout)
			})
		);
	};
};

export const addNotification = (config = {}) => {
	return (dispatch, getState) => {
		// validate
		if (!config.title) {
			throw new Error('A notification requires a title');
		}
		config.style = config.style || STYLE.INFO;
		if (!STYLE[config.style.toUpperCase()]) {
			throw new Error(`"${config.style}" is not a valid style`);
		}
		const id = Date.now();

		const notification = {
			...config,
			id,
			title: Tools.$translate(config.title),
			body:
				(Tools.$translate(config.body) || '') + (config.errorTranslated ? ` - ${config.errorTranslated}` : ''),
			style: STYLE[config.style.toUpperCase()],
			icon: config.icon || null,
			autoHide: config.autoHide === undefined ? true : !!config.autoHide,
			type: config.type,
			visible: false // will be added as visible then we update it so it can animate in
		};

		dispatch(addNotificationObj(notification));

		// Give it time to mount before setting to visible
		setTimeout(() => {
			const { notifications } = getState().SystemNotification;
			const i = notifications.findIndex(item => item.id === id);
			if (i !== -1) {
				dispatch(setNotifications(replaceItem(notifications, i, { ...notification, visible: true })));
			}
		});

		if (notification.autoHide) {
			const extraSeconds = config.customBody ? config.customBody.extraSeconds : 0;
			dispatch(queueHideFunction(notification.id, notification.body, extraSeconds));
		}
		return id;
	};
};

export const updateNotification = config => {
	return (dispatch, getState) => {
		if (!config.id) {
			throw new Error('A notification requires an id to update');
		}
		const notification = { ...config };
		if (config.title) {
			notification.title = Tools.$translate(config.title);
		}
		if (config.body) {
			notification.body = Tools.$translate(config.body);
		}

		const { notifications } = getState().SystemNotification;
		let found = false;
		for (var i = 0; i < notifications.length; i++) {
			if (notifications[i].id === notification.id) {
				found = true;
				notifications[i] = Object.assign(notifications[i], notification);
				dispatch(holdHideNotification(notification.id));
				dispatch(setNotifications(notifications));
				if (notifications[i].autoHide) {
					dispatch(queueHideFunction(notification.id, notification.body));
				}
				return;
			}
		}

		if (!found && config?.addIfNotFound) {
			dispatch(addNotification(config));
		}
	};
};

export const resumeHideNotification = (id, autoHide = true) => {
	return dispatch => {
		if (autoHide) {
			dispatch(queueHideFunction(id));
		}
	};
};
