import { AppThunk } from 'Store/index';
import { AnyAction } from 'redux';
import LZString from 'lz-string';

import { omitForcedVisibleFields, transformFieldsToLowerCase } from 'App/helpers/editTriggerHelpers/fieldHelpers';
import { type ObjectWithKeys } from 'App/helpers/editTriggerHelpers/fieldHelpers';
import { type OrderRow } from 'App/components/OrderRows/Context/OrderContext';
import { type OrderCustomField } from 'App/resources/Model/Order';
import type Order from 'App/resources/Model/Order';
import { batch } from 'react-redux';

export const SET_DISABLED_FIELDS = '[EditListener] Set disabled fields';
export const SET_VISIBLE_FIELDS = '[EditListener] Set visible fields';
export const SET_UPDATED_FIELDS = '[EditListener] Set updated fields';
export const SET_ADDED_FIELDS = '[EditListener] Set added fields';
export const SET_REMOVED_FIELDS = '[EditListener] Set removed fields';
export const SET_UPDATE_HASH = '[EditListener] Set update hash';
export const SET_CUSTOM_FIELD_OVERRIDES = '[EditListener] Set custom field overrides';

export type FieldEntity = 'order';

export type DisabledFields = {
	[key in FieldEntity]?: ObjectWithKeys;
};

export type VisibleFields = {
	[key in FieldEntity]?: ObjectWithKeys;
};

export type UpdatedCustomField = Partial<OrderCustomField> & {
	placeholder?: string;
};

export type UpdatedFields = {
	order?: Partial<Order> & {
		custom?: UpdatedCustomField[];
		orderRow?: Partial<OrderRow>[] & {
			custom?: UpdatedCustomField[];
		};
	};
};

export type AddedFields = {
	order?: {
		orderRow?: Partial<OrderRow>[] & {
			custom?: UpdatedCustomField[];
		};
	};
};

export type RemovedOrderRow = Partial<Pick<OrderRow, 'id' | 'sortId' | 'uuid'>>;

export type RemovedFields = {
	order?: {
		orderRow?: RemovedOrderRow;
	};
};

export type FieldOverride = {
	name?: string;
	placeholder?: string;
	default?: string | string[];
};

export type FieldWithOverride = {
	[fieldId: number]: FieldOverride;
};

export type CustomFieldOverrides = {
	[selector: string]: FieldWithOverride;
};
export const RESET = '[EditListener] Reset';

type EditListenerState = {
	disabledFields: DisabledFields;
	visibleFields: VisibleFields;
	updatedFields: UpdatedFields;
	addedFields: AddedFields;
	removedFields: RemovedFields;
	updateHash: { [key: string]: string };
	customFieldOverrides: CustomFieldOverrides;
};

const initialState: EditListenerState = {
	disabledFields: {},
	visibleFields: {},
	updatedFields: {},
	addedFields: {},
	removedFields: {},
	updateHash: {},
	customFieldOverrides: {}
};

export type EditIntegrationResponse = {
	disabled: DisabledFields;
	visible: VisibleFields;
	updated: UpdatedFields;
	added: AddedFields;
	removed: RemovedFields;
};

const ACTION_HANDLERS: { [key: string]: (s: EditListenerState, a: AnyAction) => EditListenerState } = {
	[SET_DISABLED_FIELDS]: (state, action) => ({ ...state, disabledFields: action.disabledFields }),
	[SET_VISIBLE_FIELDS]: (state, action) => ({ ...state, visibleFields: action.visibleFields }),
	[SET_UPDATED_FIELDS]: (state, action) => ({ ...state, updatedFields: action.updatedFields }),
	[SET_ADDED_FIELDS]: (state, action) => ({ ...state, addedFields: action.addedFields }),
	[SET_REMOVED_FIELDS]: (state, action) => ({ ...state, removedFields: action.removedFields }),
	[SET_UPDATE_HASH]: (state, action) => {
		const updateHash = state.updateHash;
		updateHash[action.key] = action.updateHash;
		return {
			...state,
			updateHash
		};
	},
	[SET_CUSTOM_FIELD_OVERRIDES]: (state, action) => ({ ...state, customFieldOverrides: action.customFieldOverrides }),
	[RESET]: state => ({ ...state, ...initialState })
};

const Actions = {
	setDisabledFields: (disabledFields: DisabledFields) => ({
		type: SET_DISABLED_FIELDS,
		disabledFields
	}),
	setVisibleFields: (visibleFields: VisibleFields) => ({
		type: SET_VISIBLE_FIELDS,
		visibleFields
	}),
	setUpdatedFields: (updatedFields: UpdatedFields) => ({
		type: SET_UPDATED_FIELDS,
		updatedFields
	}),
	setAddedFields: (addedFields: AddedFields) => ({
		type: SET_ADDED_FIELDS,
		addedFields
	}),
	setRemovedFields: (removedFields: RemovedFields) => ({
		type: SET_REMOVED_FIELDS,
		removedFields
	}),
	setUpdateHash: (key: string, updateHash: string) => ({ type: SET_UPDATE_HASH, key, updateHash }),
	setCustomFieldOverrides: (customFieldOverrides: CustomFieldOverrides) => ({
		type: SET_CUSTOM_FIELD_OVERRIDES,
		customFieldOverrides
	}),
	reset: () => ({ type: RESET })
};

export const setDisabledFields =
	(disabledFields: DisabledFields): AppThunk =>
	dispatch => {
		if (disabledFields) {
			dispatch(Actions.setDisabledFields(disabledFields));
		}
	};

export const setVisibleFields =
	(visibleFields: VisibleFields): AppThunk =>
	dispatch => {
		if (visibleFields) {
			dispatch(Actions.setVisibleFields(visibleFields));
		}
	};

export const setUpdatedFields =
	(updatedFields: UpdatedFields): AppThunk =>
	dispatch => {
		if (updatedFields) {
			dispatch(Actions.setUpdatedFields(updatedFields));
		}
	};

export const setUpdateHash =
	(key: string, updatedObject: any): AppThunk =>
	dispatch => {
		const updateHash = LZString.compressToBase64(JSON.stringify(updatedObject));
		dispatch(Actions.setUpdateHash(key, updateHash));
	};

export const setCustomFieldOverrides =
	(customFieldOverrides: CustomFieldOverrides): AppThunk =>
	dispatch => {
		if (customFieldOverrides) {
			dispatch(Actions.setCustomFieldOverrides(customFieldOverrides));
		}
	};

export const setAddedFields =
	(addedFields: AddedFields): AppThunk =>
	dispatch => {
		if (addedFields) {
			dispatch(Actions.setAddedFields(addedFields));
		}
	};

export const setRemovedFields =
	(removedFields: RemovedFields): AppThunk =>
	dispatch => {
		if (removedFields) {
			dispatch(Actions.setRemovedFields(removedFields));
		}
	};

export const onEditListenerResponse =
	(fieldActions: EditIntegrationResponse): AppThunk =>
	dispatch => {
		const disabledFields = fieldActions?.disabled ? transformFieldsToLowerCase(fieldActions!.disabled) : undefined;
		const visibleFields = fieldActions?.visible
			? omitForcedVisibleFields(transformFieldsToLowerCase(fieldActions!.visible))
			: undefined;
		const updatedFields = fieldActions?.updated;
		const addedFields = fieldActions?.added;
		const removedFields = fieldActions?.removed;

		batch(() => {
			if (disabledFields) {
				dispatch(setDisabledFields(disabledFields));
				dispatch(setUpdateHash('disabledFields', disabledFields));
			}
			if (visibleFields) {
				dispatch(setVisibleFields(visibleFields));
				dispatch(setUpdateHash('visibleFields', visibleFields));
			}
			if (updatedFields) {
				dispatch(setUpdatedFields(updatedFields));
				dispatch(setUpdateHash('updatedFields', updatedFields));
			}
			if (addedFields) {
				dispatch(setAddedFields(addedFields));
			}
			if (removedFields) {
				dispatch(setRemovedFields(removedFields));
			}
		});
	};

export const reset = (): AppThunk => dispatch => {
	dispatch(Actions.reset());
};

export default (state = initialState, action: AnyAction) => {
	const handler = ACTION_HANDLERS[action.type];
	return handler ? handler(state, action) : state;
};
