import { useEffect, useRef } from 'react';
import _ from 'lodash';
import useForceRender from './useForceRender';

type Props = {
	containerRef: React.RefObject<HTMLDivElement>;
	contentRefs: React.RefObject<HTMLDivElement>[];
	threshold?: number;
};

const getRefsWidth = (refs: React.RefObject<HTMLDivElement>[]) => {
	return refs.reduce((acc, ref) => acc + (ref.current?.offsetWidth ?? 0), 0);
};

const useDetectContainerOverflow = ({ containerRef, contentRefs, threshold = 60 }: Props) => {
	const { triggerRender } = useForceRender();

	const thresholdMetRef = useRef(false);
	const contentFullSizeRef = useRef(0);

	const checkTabsSize = _.debounce(
		() => {
			const containerWidth = containerRef.current?.offsetWidth ?? 0;
			const contentWidth = getRefsWidth(contentRefs);

			if (contentWidth > contentFullSizeRef.current) {
				contentFullSizeRef.current = contentWidth;
			}

			const isSmall = contentFullSizeRef.current > containerWidth - threshold;

			if (isSmall !== thresholdMetRef.current) {
				thresholdMetRef.current = isSmall;
				triggerRender();
			}
		},
		50,
		{ maxWait: 300 }
	);

	useEffect(() => {
		if (containerRef.current) {
			checkTabsSize();

			window.addEventListener('resize', checkTabsSize);
			return () => {
				window.removeEventListener('resize', checkTabsSize);
			};
		}
	}, [[containerRef, ...contentRefs].every(ref => !!ref.current)]);

	const recalculate = () => {
		checkTabsSize();
	};

	return {
		thresholdMet: thresholdMetRef.current,
		recalculate
	};
};

export default useDetectContainerOverflow;
