import { parseFilters } from 'App/helpers/filterHelper';
import _ from 'lodash';
import contactFilters from 'App/babel/filterConfigs/Contact';
const ContactFilters = contactFilters();

const MergeFilters = (
	filters,
	getConfig = fc => (ContactFilters[fc] ? { ...ContactFilters[fc], filterName: fc } : null)
) => {
	filters = filters || [];
	return filters.reduce((res, filterObj) => {
		var activeConfigs = _.pick(filterObj.config, (filter, filterName) => {
			const configName = Tools.FilterHelper.getConfigName(filterName);
			const fc = getConfig ? getConfig(configName) : null;
			return !Tools.FilterHelper.isInactiveValue(filter, 'contact', fc);
		});

		if (Object.keys(activeConfigs).length) {
			const useNewParser = Tools.FeatureHelper.hasSoftDeployAccess('FLOW_GOAL');
			const parser = useNewParser ? parseFilters : Tools.FilterHelper.parseFilters;
			const build = parser(activeConfigs, 'contact', null, null, {
				getConfig,
				useTags: true,
				groupAllFilters: true
			}).build();
			const body = { q: build.q || [] };

			if (build.customerId) {
				body.customerId = build.customerId;
			}
			if (build.userId) {
				body.userId = build.userId;
			}

			res.push({ ...filterObj, config: activeConfigs, body: body });
		}
		return res;
	}, []);
};

export default MergeFilters;
