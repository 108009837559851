import Resource from 'Resources/Resource';

class UserSurvey extends Resource {
	constructor() {
		super('master/userSurvey');
	}
	getRoles() {
		return this._getRequest('', { _url: 'master/userSurveyRole' }).then(r => r.data);
	}
	delete(): Promise<{ error: Error }> {
		throw new Error('Method not implemented.');
	}

	find(): Promise<{ error: Error }> {
		throw new Error('Method not implemented.');
	}
	get(): Promise<{ error: Error }> {
		throw new Error('Method not implemented.');
	}
}

const resource = new UserSurvey();

export default resource;
