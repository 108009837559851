import { Block, Column, Icon, Row, Toggle, Text, Select, Checkbox, Flex } from '@upsales/components';
import React, { useMemo } from 'react';
import T from 'Components/Helpers/translate';
import { FormObserverOnFieldChange, InputPropMap } from '../FormObserver/FormObserver';
import './CreateFlowUnenrollment.scss';
import BemClass from '@upsales/components/Utils/bemClass';
import { EditableFlow } from './CreateFlow';

type Props = {
	inputProps: InputPropMap<EditableFlow>;
	onFormChange: FormObserverOnFieldChange;
	anchor: Element | null;
};

const CreateFlowUnenrollment: React.FC<Props> = ({ inputProps, onFormChange, anchor }) => {
	const classes = useMemo(() => new BemClass('CreateFlowUnenrollment'), []);

	const options = useMemo(
		() =>
			[7, 14, 30, 90].map(days => ({
				id: `last${days}days`,
				title: `${days} ${T('date.daysPlural').toLowerCase()}`
			})),
		[]
	);
	return (
		<div className={classes.b()}>
			<Block space="mbm">
				<Text bold>{T('flow.form.endFlow')}</Text>
			</Block>
			<Row>
				<Column fixedWidth={20}>
					<Checkbox
						size="sm"
						checked={inputProps.endFlowOnEmail.value}
						onChange={value => onFormChange('endFlowOnEmail', value)}
					/>
				</Column>
				<Column fixedWidth={12} />
				<Column>
					<Text color="grey-11" className={classes.elem('inline-text').b()}>
						{T('flow.form.mail.unenrollment')}
					</Text>
					<Select
						value={options.find(o => o.id === inputProps.endFlowOnEmailDays.value) ?? options[0]}
						options={options}
						anchor={anchor}
						inline
						className={classes.elem('inline-select').b()}
						showSearch={false}
						onChange={value => onFormChange('endFlowOnEmailDays', value.id)}
						disabled={!inputProps.endFlowOnEmail.value}
					/>
				</Column>
			</Row>

			<Block space="mtl">
				<Text size="md" bold>
					<Icon space="mtl mbl" name="trophy" /> {T('flow.form.addGoal')}
				</Text>
				<Flex gap="u3" alignItems="center">
					<Flex flex={[0, 0, '0%']}>
						<Toggle
							onChange={value => onFormChange('hasGoal', value)}
							checked={inputProps.hasGoal.value}
							size="md"
						/>
					</Flex>
					<Flex direction="column">
						<Text size="md">{T('flow.form.goalMeeting')}</Text>
						<Text size="sm">{T('flow.form.goalMeetingDescription')}</Text>
					</Flex>
				</Flex>
			</Block>
		</div>
	);
};

export default CreateFlowUnenrollment;
