import T from '../Helpers/translate';
import { numberFormat } from 'App/babel/components/Filters/Currencies';
import { useSelector } from 'react-redux';
import { RootState } from 'Store/index';

type SubtitleGeneratorProps = {
	type?: string;
	total: number;
	entityName?: string;
	listTitle?: string;
	formatTotal?: (total: number) => string;
	isReport?: boolean;
	hideSubtitle?: boolean;
};

const SubtitleGenerator = (props: SubtitleGeneratorProps) => {
	const { type, total, entityName, listTitle, formatTotal, isReport = false, hideSubtitle = false } = props;

	if (isReport || hideSubtitle) {
		return null;
	}

	if (formatTotal) {
		const totalString = formatTotal(total);

		if (totalString) {
			return totalString.toString();
		}
	}

	if (type?.includes('userDefinedObject')) {
		const udos = useSelector(({ App }: RootState) => App.metadata?.params.UserDefinedObject ?? []);
		const udoId = parseInt(type.replace('userDefinedObject', ''));
		return `${numberFormat(total)} ${udos.find(x => x.id === udoId)?.name.toLowerCase() ?? ''}`;
	}

	const fallbackName = T(entityName || listTitle || '').toLowerCase();
	return `${numberFormat(total)} ${fallbackName}`;
};

export default SubtitleGenerator;
