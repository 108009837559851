import React from 'react';
import { Text, TableColumn, Avatar, Tooltip } from '@upsales/components';
import T from '../Helpers/translate';

function ListTitle({ owner, title, description, changeView, entireView, disabled, listType }) {
	const isCommunityType = listType === 'shared' || listType === 'private';
	let content = [];

	if (isCommunityType && title.length > 25) {
		title = title.substring(0, 25) + '...';
	}

	if (owner) {
		if (isCommunityType && title.length > 20) {
			title = title.substring(0, 20) + '...';
		}

		content = [
			<Tooltip
				offset="-10, 0"
				key="avatar-tooltip"
				title={`${T('sharedViews.sharedBy')} ${owner.name}`}
				style={{ display: 'inline-block' }}
			>
				<Avatar size="sm" initials={owner.name} email={owner.email} />
			</Tooltip>
		];
	}

	if (description) {
		content.push(
			<Tooltip key="text-title-tooltip" title={_.escape(T(description))}>
				<Text color="grey-12">{T(title)}</Text>
			</Tooltip>
		);
	} else {
		content.push(
			<Text className="no-tooltip" key="text-title" color="grey-12">
				{T(title)}
			</Text>
		);
	}

	return <TableColumn onClick={() => !disabled && changeView(entireView)}>{content}</TableColumn>;
}

export default ListTitle;
