import React from 'react';

import { EVENT_TIMELINE } from 'App/babel/enum/timeline';
import TimelineVisit from 'Components/TimelineRow/TimelineVisit';
import TimelineMail from 'Components/TimelineRow/TimelineMail';
import TimelineLead from 'Components/TimelineRow/TimelineLead';
import TimelineForm from 'Components/TimelineRow/TimelineForm';
import TimelineManual from 'Components/TimelineRow/TimelineManual';
import TimelineMarketingCustom from 'Components/TimelineRow/TimelineMarketingCustom';
import TimelineEsign from 'Components/TimelineRow/TimelineEsign';
import logError from 'App/babel/helpers/logError';
import type { HistoryLogRenderedBy } from 'App/components/HistoryLog/ModalHistoryLog';
import TimelineGeneric, { TimelineProps } from 'Components/TimelineRow/TimelineGeneric';
import TimelineGenericRender, { TimelineConfig } from 'Components/TimelineRow/TimelineGeneric/TimelineCardConfigs';
import Event from 'App/resources/Model/Event';

export const getTimelineRow = (
	event: Event,
	logType: string,
	showNotes: boolean = false,
	isLastElem: boolean = false,
	renderedBy?: HistoryLogRenderedBy,
	additionalProps: Partial<TimelineProps> = {}
) => {
	try {
		if (!event) {
			return null;
		}

		let { entityType }: { entityType?: string } = event;

		if (entityType === 'Appointment' && event.subType === 'commcrea') {
			entityType = 'Comment';
		}
		if (entityType === 'Contact' && event.subType === 'created') {
			entityType = 'AddedContact';
		}

		if (entityType && entityType in TimelineConfig) {
			return (
				<TimelineGenericRender
					type={entityType as Extract<TimelineConfig, typeof entityType>}
					key={event.id || event.entityId}
					includeIcon
					event={{ ...event, entityType: entityType as Event['entityType'] }}
					hideLine={isLastElem}
					showComments={showNotes}
					renderedById={entityType.toLowerCase() === renderedBy?.type ? renderedBy?.id : undefined}
					renderedByType={renderedBy?.type}
					{...additionalProps}
				/>
			);
		}

		/**
		 * Shared events
		 * @deprecated New cards should be added as a TimelineConfig and go through the TimelineGenericRender above
		 */
		let component = null;
		switch (`${entityType ? entityType : null}_${event.subType ? event.subType.toLowerCase() : ''}`) {
			case EVENT_TIMELINE.MAIL_SEND:
			case EVENT_TIMELINE.MAIL_RECEIVE:
			case EVENT_TIMELINE.MAIL_SOFT_BOUNCE:
			case EVENT_TIMELINE.MAIL_HARD_BOUNCE:
				component = <TimelineMail key={event.id} event={event} isHistoryLog hideLine={isLastElem} />;
				break;
			case EVENT_TIMELINE.VISIT:
				component = <TimelineVisit key={event.id} event={event as any} />;
				break;
			case EVENT_TIMELINE.LEAD_CREATED:
				component = <TimelineLead key={event.id} event={event as any} />;
				break;
			case EVENT_TIMELINE.FORM_SUBMIT:
				component = <TimelineForm key={event.id} event={event as any} />;
				break;
			case EVENT_TIMELINE.MANUAL_ADJUSTMENT:
				component = <TimelineManual key={event.id} event={event as any} />;
				break;
			case EVENT_TIMELINE.MARKETING_CUSTOM_VIDEO:
			case EVENT_TIMELINE.MARKETING_CUSTOM_EVENT:
			case EVENT_TIMELINE.MARKETING_CUSTOM_CHAT:
			case EVENT_TIMELINE.MARKETING_CUSTOM_TWITTER:
				component = <TimelineMarketingCustom key={event.id} event={event as any} />;
				break;
			case EVENT_TIMELINE.ESIGN_DRAFT:
			case EVENT_TIMELINE.ESIGN_SENT:
			case EVENT_TIMELINE.ESIGN_SIGNED:
			case EVENT_TIMELINE.ESIGN_UNDELIVERED:
			case EVENT_TIMELINE.ESIGN_VIEWED:
				component = <TimelineEsign key={event.id} event={event as any} />;
				break;
		}

		return component !== null ? <TimelineGeneric key={event.id} event={event} bypassComponent={component} /> : null;
	} catch (e) {
		logError(e, `Failed to render ${logType}HistoryLog event`);
		return null;
	}
};
