import React, { MutableRefObject, useMemo, useRef, useState } from 'react';
import { useTranslation } from 'Components/Helpers/translate';
import { type ActivityPriority } from 'App/babel/enum/activity';

import { useSoftDeployAccess } from '../../hooks';
import Modal from '@upsales/components/Modal/Modal';
import ModalHeader from '@upsales/components/ModalHeader/ModalHeader';
import ModalContent from '@upsales/components/ModalContent/ModalContent';
import ModalControls from '@upsales/components/ModalControls/ModalControls';
import BemClass from '@upsales/components/Utils/bemClass';
import Column from '@upsales/components/Column/Column';
import { PrimaryButton, ThirdButton } from '@upsales/components/Buttons';
import TextInput from 'Components/Inputs/TextInput';
import { defaultFilterGetter } from 'App/helpers/multiActionRunnerHelpers';
import { MultiSelect } from '../../ListView/ListViewRenderHelpers';
import RequestBuilder from 'Resources/RequestBuilder';
import Block from '@upsales/components/Block/Block';
import DateTimePicker from '../../Inputs/DateTimeSelect';
import { getActiveUsers } from 'Store/selectors/AppSelectors';

import './MultiUpdateActivity.scss';
import { CampaignIdSelect } from 'Components/PlanPhonecallsDrawer/CampaignSelect';
import ButtonSelect from '@upsales/components/ButtonSelect/ButtonSelect';
import LabeledInput from '../../Inputs/LabeledInput';
import FormObserver from '../../FormObserver/FormObserver';
import CustomFields from '../../CustomFields/CustomFields';
import { getCustomFieldsSetUpValues } from '../../FormObserver';
import { getUsersByTag, prepDates } from 'Helpers/multiModals';
import { NumberInput, Select } from '@upsales/components';
import Textarea from '@upsales/components/Textarea/Textarea';
import Row from '@upsales/components/Row/Row';
import { SelectItem } from '@upsales/components/Utils/selectHelpers';
import { ModalProps } from 'App/components/Modals/Modals';
import type { PropertyMap, PropertyArray } from '../MultiActionModal';
import { useActivityTypes, useCustomFields } from 'App/components/hooks/appHooks';

type Props = {
	multiSelect: MultiSelect;
	entityType?: string;
	isTrigger?: boolean;
	onSave?: (props: PropertyArray) => Promise<void>;
} & ModalProps;

type UserData = { id: string | number; name: string };

const prepTriggerCategories = (dateTypesRaw: {
	[k: string]: { name: string; children: { tag: string; name: string }[] };
}) => {
	return Object.keys(dateTypesRaw).map(key => {
		const val = dateTypesRaw[key];
		return {
			id: key,
			title: val.name,
			children: val.children?.map(child => ({
				id: child.tag,
				title: child.name
			}))
		} as SelectItem;
	});
};

const MultiUpdateActivity = ({ multiSelect, isTrigger = false, entityType = '', onSave, close, className }: Props) => {
	const { t } = useTranslation();
	const modalRef: MutableRefObject<HTMLDivElement | null> = useRef<HTMLDivElement>(null);

	const classes = new BemClass('MultiUpdateActivity', className);

	const hasCallLists = useSoftDeployAccess('CALL_LISTS');
	const hasTodosCallLists = useSoftDeployAccess('TODO_LIST') && hasCallLists;
	const hasRemoveActivities = useSoftDeployAccess('REMOVE_ACTIVITIES');

	const meta = {
		activeUsers: getActiveUsers() as UserData[],
		activityTypes: useActivityTypes(),
		activityCustomFields: useCustomFields('activity')
	};

	const { userOptions, activityTypeOptions } = useMemo(() => {
		const activityTypeOptions = [...getUsersByTag(entityType), ...meta.activityTypes].map(e => ({
			...e,
			title: e.name
		}));
		const userOptions = meta.activeUsers.map(e => ({ ...e, title: e.name }));

		return { userOptions, activityTypeOptions };
	}, [meta.activeUsers, meta.activityTypes]);

	const { dateTypes } = prepDates(entityType || 'activity');
	const triggerDateGroups = prepTriggerCategories(dateTypes);

	const [properties, setProperties] = useState<PropertyMap>({ Priority: null });
	const [customProperties, setCustomProperties] = useState<Record<string, string>>({});

	const [selectedUser, setSelectedUser] = useState<(typeof userOptions)[number] | null>(null);
	const [selectedActivityType, setSelectedActivityType] = useState<(typeof activityTypeOptions)[number] | null>(null);
	const [triggerDate, setTriggerDate] = useState<{
		selectItem: SelectItem | null;
		offset: number;
	}>({ selectItem: null, offset: 0 });

	const save = async () => {
		const propArray: PropertyArray = [];
		for (const key in properties) {
			let value: PropertyMap[keyof typeof properties];
			if (key === 'Date' && triggerDate.selectItem?.id) {
				value = '{{' + triggerDate.selectItem.id + ':' + (triggerDate.offset || 0) + '}}';
			} else {
				value = properties[key as keyof typeof properties];
			}
			if (value !== null && value !== undefined) {
				propArray.push({ name: key, value });
			}
		}

		for (const key in customProperties) {
			const value = customProperties[key as keyof typeof customProperties];
			if (value !== null && value !== undefined) {
				propArray.push({ name: key, value });
			}
		}

		if (onSave) {
			return onSave(propArray);
		}

		const filters = defaultFilterGetter(new RequestBuilder(), multiSelect);

		Tools.MultiActions.customer(Tools.AppService.getCustomerId()).updateActivity(
			multiSelect.selectedIds.length,
			propArray,
			filters.build()
		);
	};

	const { validatonModel, initialValues } = getCustomFieldsSetUpValues(meta.activityCustomFields);

	return (
		<Modal size="lg" className={classes.b()}>
			<div ref={modalRef}>
				<ModalHeader title={t('todo.multi.updatePhonecallsAndTodos')} onClose={close} icon="edit" />
				<ModalContent>
					{isTrigger ? (
						<LabeledInput title={t('default.description')} classes="col-span2">
							<TextInput
								value={properties.Description}
								onChange={(value: string) => {
									setProperties({ ...properties, Description: value });
								}}
							/>
						</LabeledInput>
					) : null}

					{!isTrigger ? (
						<DateTimePicker
							label={t('default.date')}
							onUpdate={value => {
								setProperties({ ...properties, Date: value.day, Time: value.time });
							}}
							scrollContainer={modalRef.current}
						></DateTimePicker>
					) : null}

					{isTrigger ? (
						<Row className={classes.elem('trigger-date').b()}>
							<LabeledInput title={t('default.date')}>
								<Select
									options={triggerDateGroups}
									optionHeaderType="disabled"
									value={triggerDate.selectItem}
									onChange={item => {
										setTriggerDate(prev => ({ ...prev, selectItem: item ?? null }));
									}}
									placeholder={`${t('default.select')} ${t('default.date').toLowerCase()}`}
									anchor={modalRef.current}
								/>
							</LabeledInput>
							<LabeledInput title={t('automationAction.DateOffset')}>
								<NumberInput
									value={triggerDate.offset}
									onChange={offset => {
										setTriggerDate(prev => ({ ...prev, offset: offset ?? 0 }));
									}}
								/>
							</LabeledInput>
						</Row>
					) : null}

					{!hasRemoveActivities ? (
						<LabeledInput title={t('default.activityType')}>
							<Select
								options={activityTypeOptions}
								value={selectedActivityType ?? null}
								placeholder={`${t('default.select')} ${t('default.activityType').toLowerCase()}`}
								onChange={value => {
									setSelectedActivityType(value);
									setProperties({ ...properties, ActivityType: value?.id ?? null });
								}}
								anchor={modalRef.current}
								onClear={() => {
									setSelectedActivityType(null);
									setProperties({ ...properties, ActivityType: null });
								}}
							/>
						</LabeledInput>
					) : null}

					<LabeledInput title={t('default.user')}>
						<Select
							placeholder={`${t('default.select')} ${t('default.user').toLowerCase()}`}
							value={selectedUser}
							onChange={user => {
								setSelectedUser(user);
								setProperties({ ...properties, User: user?.id ?? null });
							}}
							anchor={modalRef.current}
							onClear={() => {
								setSelectedUser(null);
								setProperties({ ...properties, User: null });
							}}
							options={userOptions}
						/>
					</LabeledInput>

					<Block space="ptm">
						<CampaignIdSelect
							alwaysShowInput
							value={properties.Project ?? null}
							onChange={v => setProperties({ ...properties, Project: v })}
							anchor={modalRef.current}
							withToggle={false}
							title={`${t('default.campaign')}`}
						/>
					</Block>

					{hasTodosCallLists ? (
						<Block space="ptm">
							<CampaignIdSelect
								alwaysShowInput
								isCallList
								value={properties.CallList ?? null}
								onChange={val => {
									setProperties({ ...properties, CallList: val });
								}}
								anchor={modalRef.current}
								withToggle={false}
								title={`${t('default.callList')}`}
							/>
						</Block>
					) : null}

					<LabeledInput title={t('default.priority')} classes="col-span2">
						<ButtonSelect<ActivityPriority | null>
							options={[
								{
									title: t('updateActivityMulti.prio.dontChange'),
									// ButtonSelectOption in ui-components has poor typing. This isn't worth the effort
									value: null as any
								},
								{
									title: t('updateActivityMulti.prio.notPrioritized'),
									value: 0
								},
								{
									title: t('updateActivityMulti.prio.prioritized'),
									value: 3
								}
							]}
							value={properties.Priority ?? null}
							onChange={(val: ActivityPriority | null) => {
								setProperties({ ...properties, Priority: val });
							}}
						/>
					</LabeledInput>

					<Block className="col-span2">
						<FormObserver
							onChange={values => {
								const mappedCustom = Object.keys(values.custom).reduce<Record<string, string>>(
									(res, key) => {
										const value = values.custom[key];
										if (!value) {
											return res;
										}
										res[`Activity.${key}`] = value;
										return res;
									},
									{}
								);

								setCustomProperties(mappedCustom);
							}}
							onlyValidateTouched
							model={validatonModel}
							initialValues={initialValues}
						>
							{({ onFormChange, inputProps }) => (
								<CustomFields
									type="activity"
									inputProps={inputProps}
									onChange={(id, value) => onFormChange(`custom.custom_${id}`, value)}
									showFormGroupName={true}
								/>
							)}
						</FormObserver>
					</Block>

					<LabeledInput title={t('default.notes')} classes={classes.elem('notes', 'col-span2').b()}>
						<Textarea
							value={properties.Notes ?? ''}
							onChange={e => {
								setProperties({ ...properties, Notes: e.target?.value ?? null });
							}}
						/>
					</LabeledInput>
				</ModalContent>
				<ModalControls>
					<Column align="right">
						<PrimaryButton
							onClick={() => {
								save();
								close();
							}}
						>
							{t('default.save')}
						</PrimaryButton>
						<ThirdButton onClick={() => close()}>{t('default.cancel')}</ThirdButton>
					</Column>
				</ModalControls>
			</div>
		</Modal>
	);
};

export default MultiUpdateActivity;
