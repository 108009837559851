import React from 'react';
import { Block, Title, Icon, Column, Text, Button } from '@upsales/components';
import BemClass from '@upsales/components/Utils/bemClass';
import { useTranslation } from 'Components/Helpers/translate';
import SignalsFilter from 'Resources/SignalsFilter';
import './RiskDrawer.scss';

interface SignalsInitalStateProps {
	companyId: number;
	onSignalsEnabled: () => void;
}
const url = 'https://support.upsales.com/hc/en-us/articles/360021022677';

const RiskDrawerSignalsInitalState = ({ companyId, onSignalsEnabled }: SignalsInitalStateProps) => {
	const { t } = useTranslation();
	const classes = new BemClass('RiskDrawer');

	return (
		<Block space="ptm" className={classes.elem('initial-state-block').b()}>
			<Title size="lg">{t('customerPortfolio.risks.drawer.signals.initalState.title')}</Title>
			<div className={classes.elem('initial-state-content').b()}>
				<Column>
					<Text color="grey-11" className={classes.elem('initial-state-text').b()}>
						{t('customerPortfolio.risks.drawer.signals.initalState.description')}
					</Text>
					<div className={classes.elem('initial-state-button').b()}>
						<Button
							onClick={async () => {
								await SignalsFilter.includeClient(companyId);
								onSignalsEnabled();
							}}
						>
							<Icon name="feed" color="white" className={classes.elem('button-icon').b()} />
							{t('account.signals.disabled')}
						</Button>
						<Button
							type="link"
							onClick={() => {
								window.open(url, '_blank');
							}}
						>
							<Icon name="question-circle" className={classes.elem('button-icon').b()} />
							{t('default.readMore')}
						</Button>
					</div>
				</Column>
				<Column align="right">
					<img className={classes.elem('initial-state-img').b()} src="img/signals-Initial-state.svg" />
				</Column>
			</div>
		</Block>
	);
};

export default RiskDrawerSignalsInitalState;
