import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import {
	Headline,
	Text,
	Button,
	Input,
	Block,
	Row,
	Column,
	Label,
	ButtonSelect,
	Link,
	Toggle
} from '@upsales/components';
import { nextPage, setLicenses, setFieldValue } from '../../store/actions/CreateAccount';
import config from 'App/babel/config';

import bemClass from '@upsales/components/Utils/bemClass';
import './CompanyInfo.scss';

export class CompanyInfo extends Component {
	static propTypes = {
		companyName: PropTypes.string.isRequired,
		setFieldValue: PropTypes.func.isRequired,
		nextPage: PropTypes.func.isRequired,
		companyOrgNo: PropTypes.string.isRequired,
		companyDeliveryType: PropTypes.oneOf(['E-post', 'Post']),
		companyEmail: PropTypes.string.isRequired,
		companyAddress: PropTypes.string.isRequired,
		companyZipCode: PropTypes.string.isRequired,
		companyCity: PropTypes.string.isRequired,
		termsAccepted: PropTypes.bool.isRequired,
		numLicenses: PropTypes.number.isRequired,
		confirmAuthorized: PropTypes.bool.isRequired,
		closeModal: PropTypes.func.isRequired
	};

	constructor(props) {
		super(props);
		const getAngularModule = require('../../angularHelpers/getAngularModule').default;
		const t = getAngularModule('$translate');
		this.lang = {
			headline: t('whitelabelUpgrade.companyInfoPage.headline'),
			companyName: t('whitelabelUpgrade.companyInfoPage.companyName'),
			noCompanyName: t('createAccount.companyInfo.noCompanyName'),
			iAmAuthorized: t('createAccount.companyInfo.iAmAuthorized'),
			nextButton: t('createAccount.companyInfo.nextButton'),
			iAlreadyHaveAccount: t('createAccount.iAlreadyHaveAccount'),
			orgNo: t('default.orgNumber'),
			address: t('address.address'),
			city: t('address.city'),
			zipcode: t('address.zip'),
			deliveryType: t('whitelabelUpgrade.companyInfoPage.deliveryType'),
			email: t('whitelabelUpgrade.companyInfoPage.email'),
			post: t('whitelabelUpgrade.companyInfoPage.post'),
			invoiceEmail: t('whitelabelUpgrade.companyInfoPage.invoiceEmail'),
			iAcceptTerms: t('whitelabelUpgrade.userPage.acceptTerms')
		};
	}

	setFieldValue = field => value => {
		if (value && value.target) {
			value = value.target.value;
		}
		if (field === 'companyOrgNo' || field === 'companyZipCode') {
			value = value.replace(/\D+/g, '');
		}
		this.props.setFieldValue({ [field]: value });
	};

	render() {
		const classes = new bemClass('CompanyInfo');
		const getAngularModule = require('../../angularHelpers/getAngularModule').default;
		const t = getAngularModule('$translate');
		return (
			<div className={classes.b()}>
				<Block space="mbxl">
					<Headline size="sm">
						{t('createAccount.companyInfo.headline', {
							companyName: this.props.companyName || this.lang.noCompanyName
						})}
					</Headline>
				</Block>
				<form
					onSubmit={e => {
						e.preventDefault();
						this.props.nextPage();
					}}
				>
					<Block space="mbm">
						<Label required>
							<Text bold>{this.lang.companyName}</Text>
						</Label>
						<Input onChange={this.setFieldValue('companyName')} required value={this.props.companyName} />
					</Block>
					<Block space="mbm">
						<Label required>
							<Text bold>{this.lang.orgNo}</Text>
						</Label>
						<Input
							minLength={11}
							maxLength={11}
							onChange={this.setFieldValue('companyOrgNo')}
							required
							value={`${this.props.companyOrgNo.substring(0, 6)}${
								this.props.companyOrgNo.length > 6 ? '-' : ''
							}${this.props.companyOrgNo.substring(6, 10)}`}
						/>
					</Block>
					<Block space="mbm">
						<Label required>
							<Text bold>{this.lang.deliveryType}</Text>
						</Label>
						<ButtonSelect
							onChange={this.setFieldValue('companyDeliveryType')}
							required
							options={[
								{ title: this.lang.email, value: 'E-post' },
								{ title: this.lang.post, value: 'Post' }
							]}
							value={this.props.companyDeliveryType}
						/>
					</Block>
					{this.props.companyDeliveryType === 'E-post' ? (
						<Block space="mbm">
							<Label required>
								<Text bold>{this.lang.invoiceEmail}</Text>
							</Label>
							<Input
								onChange={this.setFieldValue('companyEmail')}
								type="email"
								required
								value={this.props.companyEmail}
							/>
						</Block>
					) : null}
					<Block space="mbm">
						<Label required>
							<Text bold>{this.lang.address}</Text>
						</Label>
						<Input
							onChange={this.setFieldValue('companyAddress')}
							required
							value={this.props.companyAddress}
						/>
					</Block>
					<Row>
						<Column>
							<Block space="mbxl mrs">
								<Label required>
									<Text bold>{this.lang.zipcode}</Text>
								</Label>
								<Input
									onChange={this.setFieldValue('companyZipCode')}
									type="string"
									minLength={6}
									maxLength={6}
									required
									value={`${this.props.companyZipCode.substring(0, 3)}${
										this.props.companyZipCode.length > 3 ? ' ' : ''
									}${this.props.companyZipCode.substring(3)}`}
								/>
							</Block>
						</Column>
						<Column>
							<Block space="mbxl mls">
								<Label required>
									<Text bold>{this.lang.city}</Text>
								</Label>
								<Input
									onChange={this.setFieldValue('companyCity')}
									required
									value={this.props.companyCity}
								/>
							</Block>
						</Column>
					</Row>

					<Block space="mtxl mbxl">
						<Row>
							<Column size={1}>
								<Toggle
									color="bright-blue"
									required
									checked={this.props.termsAccepted}
									onChange={this.setFieldValue('termsAccepted')}
								/>
							</Column>
							<Column>
								<Link
									onClick={e => {
										e.preventDefault();
										Tools.$upModal.open('pdfGeneric', {
											url: `${config.URL}v2/licenseAgreement?orgNo=${this.props.companyOrgNo}&name=${this.props.companyName}&address=${this.props.companyAddress}&zipCode=${this.props.companyZipCode}&city=${this.props.companyCity}&email=${this.props.companyEmail}&licenses=${this.props.numLicenses}`
										});
									}}
								>
									{this.lang.iAcceptTerms}
								</Link>
							</Column>
						</Row>
					</Block>
					<Block space="mtxl mbxl">
						<Row>
							<Column size={1}>
								<Toggle
									color="bright-blue"
									required
									checked={this.props.confirmAuthorized}
									onChange={this.setFieldValue('confirmAuthorized')}
								/>
							</Column>
							<Column>
								<Text>{this.lang.iAmAuthorized}</Text>
							</Column>
						</Row>
					</Block>

					<Button
						submit
						disabled={!this.props.confirmAuthorized || !this.props.termsAccepted}
						color="bright-blue"
						block
						size="xl"
						gradient
					>
						{this.lang.nextButton}
					</Button>
					<Button color="grey" block type="link" size="lg" onClick={() => this.props.closeModal()}>
						{this.lang.iAlreadyHaveAccount}
					</Button>
				</form>
			</div>
		);
	}
}

const mapStateToProps = state => ({
	companyOrgNo: state.CreateAccount.companyOrgNo,
	companyName: state.CreateAccount.companyName,
	companyAddress: state.CreateAccount.companyAddress,
	companyZipCode: state.CreateAccount.companyZipCode,
	companyDeliveryType: state.CreateAccount.companyDeliveryType,
	companyCity: state.CreateAccount.companyCity,
	companyEmail: state.CreateAccount.companyEmail,
	termsAccepted: state.CreateAccount.termsAccepted,
	confirmAuthorized: state.CreateAccount.confirmAuthorized,
	numLicenses: state.CreateAccount.numLicenses
});

const mapDispatchToProps = {
	nextPage,
	setLicenses,
	setFieldValue
};

export default connect(mapStateToProps, mapDispatchToProps)(CompanyInfo);
