import Attribute from './Attribute';

const ContactAttributes = {
	id: new Attribute({
		type: Attribute.types.Number,
		field: 'id'
	}),
	name: new Attribute({
		title: 'default.name',
		type: Attribute.types.String,
		field: 'name'
	}),
	active: new Attribute({
		title: 'default.active',
		type: Attribute.types.Boolean,
		field: 'active'
	}),
	cellPhone: new Attribute({
		title: 'default.cellPhone',
		type: Attribute.types.String,
		field: 'cellPhone'
	}),
	phone: new Attribute({
		title: 'default.phone',
		type: Attribute.types.String,
		field: 'phone'
	}),
	journeyStep: new Attribute({
		title: 'default.journeyStep',
		type: Attribute.types.String,
		field: 'journeyStep'
	}),
	title: new Attribute({
		title: 'default.title',
		type: Attribute.types.String,
		field: 'title'
	}),
	email: new Attribute({
		title: 'default.email',
		type: Attribute.types.String,
		field: 'email'
	}),
	score: new Attribute({
		title: 'default.score',
		type: Attribute.types.Number,
		field: 'score'
	}),
	regDate: new Attribute({
		title: 'default.regDate',
		type: Attribute.types.Date,
		field: 'regDate'
	}),
	regBy: new Attribute({
		title: 'default.regBy',
		type: Attribute.types.Object,
		field: 'regDate',
		attr: {
			id: new Attribute({
				field: 'regBy.id',
				type: Attribute.types.Number
			}),
			name: new Attribute({
				field: 'regBy.name',
				type: Attribute.types.String
			})
		}
	}),
	client: new Attribute({
		title: 'mail.client',
		type: Attribute.types.Object,
		field: 'client',
		attr: {
			id: new Attribute({
				field: 'client.id',
				type: Attribute.types.Number
			}),
			name: new Attribute({
				field: 'client.name',
				type: Attribute.types.String
			}),
			active: new Attribute({
				field: 'client.active',
				type: Attribute.types.Boolean
			})
		}
	}),
	firstTouch: new Attribute({
		type: Attribute.types.Object,
		field: 'firstTouch',
		attr: {
			type: new Attribute({
				field: 'firstTouch.type',
				type: Attribute.types.String
			}),
			primary: new Attribute({
				field: 'firstTouch.primary',
				type: Attribute.types.String
			}),
			secondary: new Attribute({
				field: 'firstTouch.secondary',
				type: Attribute.types.String
			}),
			date: new Attribute({
				field: 'firstTouch.date',
				type: Attribute.types.Date
			})
		}
	}),
	emailBounceReason: Attribute({
		field: 'emailBounceReason',
		type: Attribute.types.String,
		title: 'default.emailBounceReason',
		selectableColumn: false
	}),
	mailBounces: Attribute({
		field: 'mailBounces',
		type: Attribute.types.Array
	})
};

export default ContactAttributes;
