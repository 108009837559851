import React, { useRef, useEffect, useState } from 'react';
import { Row, Textarea, Button, Icon, Title, Tooltip } from '@upsales/components';
import T from 'Components/Helpers/translate';
import './EditPhonecallNotes.scss';
import BemClass from '@upsales/components/Utils/bemClass';
import BorderBox from 'App/components/BorderBox';
import { getSignature, focusSignatureTextArea } from 'App/helpers/insertSignatureOnRef';

type Props = {
	loading: boolean;
	notes: string;
	onChange: (notes: string) => void;
	textAreaProps: Partial<React.ComponentProps<typeof Textarea>>;
	headline?: string;
	hasError?: boolean;
	textareaTooltipText?: string;
	disabled?: boolean;
	onAutoSave?: (notes: string) => void;
};

const AUTOSAVE_NOTES_DEBOUNCE = 5000;

export default ({
	loading,
	notes,
	onChange,
	textAreaProps,
	headline = T('default.notes'),
	hasError = false,
	textareaTooltipText,
	disabled = false,
	onAutoSave = undefined
}: Props) => {
	const classes = new BemClass('EditPhonecallNotes');
	let textArea = useRef<HTMLTextAreaElement>(null);
	const [internalNotes, setInternalNotes] = useState(notes || '');
	const signatureAddedRef = useRef(false);
	const autoSaveTimeoutRef = useRef<number | null>(null);

	const textAreaContainer = (
		<Row className={classes.elem('textarea-row').b()} key="notes">
			<Textarea
				{...textAreaProps}
				textareaRef={r => (textArea = { current: r })}
				noborder={true}
				onChange={e => {
					setInternalNotes(e.target.value);
				}}
				onBlur={() => {
					onChange(internalNotes);
					if (onAutoSave && internalNotes !== notes) {
						if (autoSaveTimeoutRef.current !== null) {
							clearTimeout(autoSaveTimeoutRef.current);
						}
						onAutoSave(internalNotes);
					}
				}}
				value={internalNotes || ''}
				disabled={disabled}
			/>
		</Row>
	);

	const lang = {
		tooltip: T('notes.internalClarification')
	};

	const handleSignatureInsert = () => {
		setInternalNotes(getSignature() + (internalNotes ? internalNotes : ''));
		signatureAddedRef.current = true;
	};

	useEffect(() => {
		setInternalNotes(notes);
	}, [notes]);

	useEffect(() => {
		if (onAutoSave && internalNotes !== notes) {
			if (autoSaveTimeoutRef.current !== null) {
				clearTimeout(autoSaveTimeoutRef.current);
			}

			autoSaveTimeoutRef.current = window.setTimeout(() => {
				onAutoSave(internalNotes);
			}, AUTOSAVE_NOTES_DEBOUNCE);

			return () => {
				if (autoSaveTimeoutRef.current !== null) {
					clearTimeout(autoSaveTimeoutRef.current);
				}
			};
		}
	}, [onAutoSave, internalNotes, notes]);

	useEffect(() => {
		if (signatureAddedRef.current && textArea.current) {
			focusSignatureTextArea(textArea.current);
			signatureAddedRef.current = false;
		}
	}, [internalNotes]);

	return (
		<BorderBox boxShadow className={classes.mod({ ['has-error']: hasError }).b()}>
			<Row className={classes.elem('header').b()}>
				<div className={classes.elem('title').b()}>
					<Title>{headline}</Title>
					<Tooltip title={lang.tooltip}>
						<Icon name="question-circle"></Icon>
					</Tooltip>
				</div>
				{!loading && !disabled ? (
					<div className={classes.elem('add-signature').b()}>
						<Button
							type="link"
							color="bright-blue"
							onClick={handleSignatureInsert}
							disabled={!!textAreaProps?.disabled}
						>
							<Icon name="pencil" />
							{T('default.insertSign')}
						</Button>
					</div>
				) : null}
			</Row>
			{!loading ? (
				textareaTooltipText ? (
					<Tooltip
						title={textareaTooltipText}
						className={classes.elem('textarea-row').b()}
						style={{ display: 'inline-flex' }}
					>
						{textAreaContainer}
					</Tooltip>
				) : (
					textAreaContainer
				)
			) : null}
		</BorderBox>
	);
};
