import bemClass from '@upsales/components/Utils/bemClass';
import React, { useState, useMemo, useRef, useEffect } from 'react';
import {
	FlowTemplateName,
	getFlowPathFromTemplateName,
	FlowTemplates,
	GetSegmentFiltersReturnType
} from 'App/enum/FlowTemplate';
import EditorHeader from 'Components/EditorHeader';
import { Tabs, Tab, ColorSwitcher } from '@upsales/components';
import { circle } from 'Components/Helpers/styleHelper';
import T from 'Components/Helpers/translate';
import FormObserver, { FieldModel } from '../FormObserver';
import Flow from 'App/resources/Model/Flow';
import moment from 'moment';
import CreateTemplateFlowSettings from './CreateTemplateFlowSettings';
import './CreateTemplateFlow.scss';
import { FormErrorMessages, InputPropMap } from '../FormObserver/FormObserver';
import { FormObserverOnFieldChange } from '../FormObserver';
import { CancelablePromise, makeCancelable } from 'Helpers/promise';
import FlowResource, { EndCriteriaName } from 'App/resources/Flow';
import SegmentResource from 'App/resources/Segment';
import Segment from 'App/resources/Model/Segment';
import { flowTracker } from 'Helpers/Tracker';
import logError from 'Helpers/logError';
import './CreateTemplateFlow.scss';
import CreateTemplateFlowSelection from './CreateTemplateFlowSelection';
import './CreateTemplateFlow.scss';
import { useSelector } from 'react-redux';
import { RootState } from 'Store/index';
import CreateTemplateFlowParticipants from './CreateTemplateFlowParticipants';
import { EditableFlow } from '../CreateFlow/CreateFlow';

type Props = {
	className: string;
	close: (data?: Flow) => void;
	flowTemplateName: FlowTemplateName;
	segment?: Pick<Segment, 'id' | 'name'> | null;
};

const STEP = {
	SELECTION: 'selection',
	SETTINGS: 'settings'
};

type SegmentSettings = React.ComponentProps<typeof CreateTemplateFlowSelection>['segmentSettings'];

const useFormModel = () =>
	useMemo(
		() => ({
			name: FieldModel.string('flow.templateModal.settings.name').required(),
			startTime: FieldModel.string('default.startTime').required(),
			endTime: FieldModel.string('default.endTime').required(),
			timezone: FieldModel.string('default.timeZone').required(),
			skipWeekends: FieldModel.boolean('flow.newFlow.notOnWeekends').required(),
			hasGoal: FieldModel.boolean('hasGoal'),
			endFlowOnEmail: FieldModel.boolean('endFlowOnEmail'),
			endFlowOnEmailDays: FieldModel.string('endFlowOnEmailDays')
		}),
		[]
	);

export default ({ className, close, flowTemplateName, segment }: Props) => {
	const classes = new bemClass('CreateTemplateFlow', className);
	const steps = [STEP.SETTINGS];
	if (!segment) {
		steps.unshift(STEP.SELECTION);
	}
	const [currentTab, setTab] = useState(steps[0]);
	const nextStep = steps[steps.indexOf(currentTab) + 1];
	const savePromise = useRef<CancelablePromise<{ data: Flow }>>();
	const [saving, setSaving] = useState(false);
	const formModel = useFormModel();
	const initialName = T(FlowTemplates[flowTemplateName].title).replace(/(,$)/, '');
	const [segmentFilter, setSegmentFilter] = useState<GetSegmentFiltersReturnType[] | null>(null);
	classes.add('FullScreenModal');

	const [segmentSettings, setSegmentSettings] = useState<SegmentSettings>({
		days: null,
		product: null,
		page: null,
		visitDate: new Date()
	});

	const onFilterChange = (filter: GetSegmentFiltersReturnType[] | null, settings: SegmentSettings) => {
		setSegmentFilter(filter);
		setSegmentSettings(settings);
	};

	const saveFlow = async (flow: EditableFlow) => {
		setSaving(true);
		if (!segment) {
			let savedSegment: { data: Segment };

			const newSegment = {
				name: flow.name,
				description: '',
				active: 1,
				filter: segmentFilter,
				sourceTemplate: flowTemplateName
			};

			try {
				savedSegment = (await SegmentResource.save(newSegment)) as { data: Segment };
			} catch (error) {
				logError(error, 'Failed to save segment');
				setSaving(false);
				return;
			}
			flow.segmentId = savedSegment.data.id;
		} else {
			flow.segmentId = segment.id;
		}
		flow.path = getFlowPathFromTemplateName(flowTemplateName);
		flow.sourceTemplate = flowTemplateName;

		const endCriterias = [];
		const goalEndCriteria = FlowResource.getEndCriteria(EndCriteriaName.AppointmentGoalEndCriteria);
		const emailEndCriteria = FlowResource.getEndCriteria(EndCriteriaName.EmailEndCriteria);

		if (flow.hasGoal) {
			endCriterias.push(goalEndCriteria);
		}
		if (flow.endFlowOnEmail && emailEndCriteria.filterConfig?.InboundEmail?.value?.Date?.value) {
			endCriterias.push(emailEndCriteria);
			emailEndCriteria.filterConfig.InboundEmail.value.Date.value.preset = `${flow.endFlowOnEmailDays}`;
		}

		savePromise.current = makeCancelable(
			FlowResource.save({ ...flow, endFlowOnEmailDays: undefined, hasGoal: undefined, endFlowOnEmail: undefined })
		);
		savePromise.current.promise
			.then(({ data }) => {
				flowTracker.track(flowTracker.events.CREATE, { template: FlowTemplateName });
				close(data);
			})
			.catch(e => {
				logError(e, 'Failed to save flow');
				setSaving(false);
			});
	};
	const userTimezone = useSelector(({ App }: RootState) => App.self?.userParams.timeZone) || 'Europe/Stockholm';

	const gotoNextTab = async (submit: () => void) => {
		if (nextStep) {
			setTab(nextStep);
		} else {
			submit();
		}
	};

	useEffect(() => () => savePromise.current?.cancel(), []);

	const tabContent = (props: {
		inputProps: InputPropMap<EditableFlow>;
		onFormChange: FormObserverOnFieldChange;
		errorMessages: FormErrorMessages;
	}) => {
		switch (currentTab) {
			case STEP.SELECTION:
				return (
					<>
						<CreateTemplateFlowSelection
							segmentSettings={segmentSettings}
							flowTemplateName={flowTemplateName}
							onChange={onFilterChange}
						/>
						{segmentFilter ? <CreateTemplateFlowParticipants filters={segmentFilter} /> : null}
					</>
				);
			case STEP.SETTINGS:
				return <CreateTemplateFlowSettings {...props} />;
			default:
				return null;
		}
	};

	return (
		<div className={classes.b()}>
			<FormObserver<EditableFlow>
				initialValues={{
					name: initialName || '',
					startTime: moment('08:00', 'HH:mm').format('LT'),
					endTime: moment('17:00', 'HH:mm').format('LT'),
					timezone: userTimezone,
					skipWeekends: true,
					hasGoal: false,
					endFlowOnEmail: false,
					endFlowOnEmailDays: 'last7days'
				}}
				model={formModel}
				onSubmit={data => {
					if (!nextStep) {
						saveFlow(data);
					}
				}}
			>
				{({ onFormChange, inputProps, errorMessages, isValid, submit }) => (
					<div>
						<EditorHeader
							title={initialName}
							subtitle={T('flow.templateModal.subtitle')}
							onAbort={() => close()}
							className={classes.elem('navbar').b()}
							onAbortTitle={T('default.cancel')}
							onConfirm={() => gotoNextTab(submit)}
							onConfirmSupertitle={T('flow.templateModal.nextStep')}
							onConfirmTitle={
								nextStep ? T('flow.templateModal.tab.settings') : T('flow.templateModal.editFlow')
							}
							disableSave={!isValid || saving || (!segmentFilter && !segment)}
							onConfirmHasNext={!!nextStep}
							onConfirmIcon={'chevron-right'}
						>
							<Tabs noFlex color="white" onChange={tab => setTab(tab)} selected={currentTab}>
								{steps.map((tab, i) => (
									<Tab key={tab} id={tab} disabled={!isValid || saving || !segmentFilter}>
										<ColorSwitcher style={circle()}>{i + 1}</ColorSwitcher>
										{T('flow.templateModal.tab.' + tab)}
									</Tab>
								))}
							</Tabs>
						</EditorHeader>
						<div className={classes.elem('tabContent').b()}>
							{tabContent({ onFormChange, inputProps, errorMessages })}
						</div>
					</div>
				)}
			</FormObserver>
		</div>
	);
};
