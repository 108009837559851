import {
	CompetitorState,
	RESET,
	SET_COMPETITORS,
	FETCH_COMPETITORS_PENDING,
	REMOVE_COMPETITOR_PENDING,
	MERGE_COMPETITORS_PENDING
} from 'Store/reducers/CompetitorReducer';
import Competitor from 'Resources/Competitor';
import logError from 'App/babel/helpers/logError';
import { AppThunk } from 'App/babel/store/index';
import RequestBuilder from 'Resources/RequestBuilder';

export interface FetchCompetitorOptions {
	includeOrderCount?: boolean;
}

const _competitorFindRequest = async (options: FetchCompetitorOptions) => {
	const rb = new RequestBuilder();
	if (options.includeOrderCount) {
		rb.addExtraParam('includeOrderCount', true);
	}

	const competitorResponse = await Competitor.find(rb.build());
	return competitorResponse.data;
};

const _competitorDeleteRequest = async (competitorId: number | number[]) => {
	await Competitor.delete(competitorId);
};

const _competitorMergeRequest = async (competitorIds: number[], replacementCompetitorId: number) => {
	return (await Competitor.mergeCompetitors(competitorIds, replacementCompetitorId)).data;
};

export const fetchCompetitors = (options: FetchCompetitorOptions = {}): AppThunk => async dispatch => {
	dispatch({ type: RESET });
	dispatch({ type: FETCH_COMPETITORS_PENDING, pending: true });
	try {
		const competitors = await _competitorFindRequest(options);
		dispatch({ type: SET_COMPETITORS, competitors });
	} catch (err) {
		logError(err, 'Failed to fetch competitors');
	}
	dispatch({ type: FETCH_COMPETITORS_PENDING, pending: false });
};

export const mergeCompetitors = (
	competitorIdsToRemove: number[],
	replacementCompetitorId: number
): AppThunk => async dispatch => {
	dispatch({ type: MERGE_COMPETITORS_PENDING, pending: true });
	try {
		const competitors = await _competitorMergeRequest(competitorIdsToRemove, replacementCompetitorId);
		dispatch({ type: SET_COMPETITORS, competitors });
	} catch (err) {
		logError(err, 'Failed to merge competitors');
	}
	dispatch({ type: MERGE_COMPETITORS_PENDING, pending: false });
};

export const removeCompetitor = (competitorId: number): AppThunk => async (
	dispatch,
	getStore: () => { Competitor: CompetitorState }
) => {
	const { competitors } = getStore().Competitor;
	const competitorToRemove = competitors.find(competitor => competitor.id === competitorId);

	if (competitorToRemove) {
		dispatch({ type: REMOVE_COMPETITOR_PENDING, pending: true });
		try {
			await _competitorDeleteRequest(competitorId);
			const updatedCompetitors = competitors.filter(competitor => competitor.id !== competitorId);
			dispatch({ type: SET_COMPETITORS, competitors: updatedCompetitors });
		} catch (err) {
			logError(err, 'Failed to remove competitor');
		}
		dispatch({ type: REMOVE_COMPETITOR_PENDING, pending: false });
	}
};
