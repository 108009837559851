import { useTranslation } from 'Components/Helpers/translate';
import React, { ComponentProps } from 'react';
import type Form from 'App/resources/Model/Form';
import FormResource from 'App/resources/Form';
import GenericSelectEntityModal from '../GenericSelectEntityModal/GenericSelectEntityModal';
import type { ModalProps } from 'App/components/Modals/Modals';
import { comparisonTypes } from 'Resources/RequestBuilder';

type Props = ModalProps<Form> & {
	excludeIds?: number[];
	modifyFilters?: ComponentProps<typeof GenericSelectEntityModal>['modifyFilters'];
};

const FindFormModal = ({ excludeIds, modifyFilters, ...props }: Props) => {
	const { t } = useTranslation();

	return (
		<GenericSelectEntityModal<Form>
			{...props}
			title={`${t('default.search')} ${t('form.form').toLowerCase()}`}
			displayAttr="name"
			modifyFilters={rb => {
				if (excludeIds?.length) {
					rb.addFilter({ field: 'id' }, comparisonTypes.NotEquals, excludeIds);
				}

				modifyFilters?.(rb);
			}}
			fetch={filters => FormResource.find(filters)}
		/>
	);
};

export default FindFormModal;
