import React, { useState, useEffect, useRef, useMemo } from 'react';
import bemClass from '@upsales/components/Utils/bemClass';
import { Table, TableRow, TableColumn, Text, TableHeader, TableHeaderColumn, Tooltip, Icon } from '@upsales/components';
import './TableWidget.scss';
import { mapTableData } from '../chartHelper';
import { RCWidgetData } from 'Resources/ReportWidget';
import { GenericWidgetProps } from '../ReportcenterWidget';
import { renderColumnProps } from '../PipelineSpecialWidget/PipelineSpecialWidget';
import NoResults from './NoResults';
import Body from './Body';
import Totals from './Totals';
import { RCDashboardWidget } from 'Resources/ReportDashboard';

export const renderLoadingTable = (classes: bemClass, height: number) => (
	<Table>
		{[...Array(4 * (height ?? 1))].map((row, i) => (
			<TableRow key={`loadingRow${i}`} className={classes.elem('loading-row').b()}>
				<TableColumn>
					<Text loading={true} size="sm" />
				</TableColumn>
				<TableColumn>
					<Text loading={true} size="sm" />
				</TableColumn>
				<TableColumn>
					<Text loading={true} size="sm" />
				</TableColumn>
				<TableColumn>
					<Text loading={true} size="sm" />
				</TableColumn>
			</TableRow>
		))}
	</Table>
);

export const renderNoResultsTable = (classes: bemClass, data: RCWidgetData) => (
	<Table>
		<TableRow className={classes.elem('noResultRow').b()}>
			<TableColumn>
				<Text className={classes.elem('noResultRowText').b()} italic>
					{data.language?.noData}
				</Text>
			</TableColumn>
		</TableRow>
	</Table>
);

export type Props = GenericWidgetProps & {
	config: RCDashboardWidget & { skipTotal?: boolean };
	specialColumn?: ({ value, datatype, currency, colors }: renderColumnProps) => JSX.Element | undefined;
	excludedColumns?: string[];
};

const TableWidget = ({
	data,
	groupBy,
	showGoal,
	config,
	specialColumn,
	excludedColumns = [],
	width,
	height,
	loading,
	drilldown
}: Props) => {
	const wrapper = useRef<HTMLDivElement | null>(null);
	const [scrolledTop, setScrolledTop] = useState(false);
	const [scrolledBot, setScrolledBot] = useState(false);
	const [scrolledLeft, setScrolledLeft] = useState(false);

	useEffect(() => {
		wrapper.current?.dispatchEvent(new Event('scroll'));
	}, [loading, width, height]);

	useEffect(() => {
		const onScroll = (e: Event) => {
			const target = e.target as Element;
			const maxVertical = target.scrollHeight - target.clientHeight;
			const maxHorizontal = target.scrollWidth - target.clientWidth;

			if (maxVertical <= 0) {
				if (scrolledTop) setScrolledTop(false);
				if (scrolledBot) setScrolledBot(false);
			} else {
				if (target.scrollTop > 0 && !scrolledTop) setScrolledTop(true);
				if (target.scrollTop === 0 && scrolledTop) setScrolledTop(false);

				if (target.scrollTop < maxVertical - 3 && !scrolledBot) setScrolledBot(true);
				if (Math.abs(target.scrollTop - maxVertical) < 2 && scrolledBot) setScrolledBot(false);
			}

			if (maxHorizontal <= 0) {
				if (scrolledLeft) setScrolledLeft(false);
			} else {
				if (target.scrollLeft > 0 && !scrolledLeft) setScrolledLeft(true);
				if (target.scrollLeft === 0 && scrolledLeft) setScrolledLeft(false);
			}
		};

		wrapper.current?.addEventListener('scroll', onScroll);
		// Make sure to trigger scroll event so shadows appear immediately without having to scroll first
		wrapper.current?.dispatchEvent(new Event('scroll'));
		return () => {
			wrapper.current?.removeEventListener('scroll', onScroll);
		};
	}, [scrolledTop, scrolledBot, scrolledLeft, loading]);

	const classes = new bemClass('TableWidget');
	const loadingTable = useMemo(() => renderLoadingTable(classes, height), [height]);
	const subGrouped = groupBy?.length > 1;
	const noData = (data.rows?.length ?? 0) < 1;
	const drilldownProps = {
		enableDrilldown: data.enableDrilldown && drilldown,
		config: config,
		disableRowDrilldown: data.disableRowDrilldown
	};

	const renderedTable = useMemo(() => {
		const mappedData = mapTableData(data, showGoal, subGrouped, specialColumn, data.excludedColumns);
		return mappedData ? (
			<Table className={classes.elem('table').mod({ hasTotal: !config.skipTotal }).b()}>
				<TableHeader className={classes.elem('tableHeader').b()}>
					{mappedData.columnOrder.map(column => (
						<TableHeaderColumn className={classes.elem('headerColumn').b()} key={column.key}>
							<Text size="sm">
								{column.label}
								{column.tooltip ? (
									<Tooltip title={column.tooltip}>
										<Icon space="mls" name="info-circle" />
									</Tooltip>
								) : null}
							</Text>
						</TableHeaderColumn>
					))}
				</TableHeader>
				<Body classes={classes} data={mappedData} drilldown={drilldownProps} />
				{config.skipTotal ? null : (
					<Totals
						classes={classes}
						totals={mappedData.mappedTotals}
						columnOrder={mappedData.columnOrder}
						colors={mappedData.colors}
						currency={mappedData.currency}
					/>
				)}
			</Table>
		) : null;
	}, [data, showGoal, drilldownProps.enableDrilldown]);

	return loading || renderedTable === null ? (
		loadingTable
	) : noData ? (
		<NoResults text={data.language.noData} />
	) : (
		<div className={classes.mod({ scrolledTop, scrolledBot, scrolledLeft }).b()} ref={wrapper}>
			{renderedTable}
		</div>
	);
};
export default TableWidget;
