export const EVENT_TYPES = {
	CREATED: 'created',
	MAILSENT: 'mailsent',
	UPDATED: 'updated',
	APPOINTMENTCREATED: 'appocrea',
	ACTIVITYCREATED: 'acticrea',
	CALLCREATED: 'callcrea',
	COMMENTCREATED: 'commcrea',
	ORDERCREATED: 'ordecrea',
	OPPORTUNITYCREATED: 'oppocrea'
} as const;
export type AppointmentEventType = typeof EVENT_TYPES[keyof typeof EVENT_TYPES];
