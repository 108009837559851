import logError from 'App/babel/helpers/logError';
import { ListViewFilter } from 'App/resources/AllIWant';
import JourneyStep from 'App/resources/Model/JourneyStep';
import SignalsFilter from 'Resources/SignalsFilter';
import { AppThunk } from '..';

export const RESET = '[ProspectingSignals] RESET';
export const SET_STATE = '[ProspectingSignals] SET_STATE';

export const initialState = {
	filterBody: {},
	filterConfig: {},
	clients: 0,
	loading: false,
	signalsAutoUpdate: false
};

export type SignalsListViewFilters = { [name: string]: ListViewFilter } & {
	SignalsFilter?: Omit<ListViewFilter, 'value'> & {
		value: { journey?: string[]; include?: number[]; exclude?: number[] };
	};
};

type State = {
	clients: number;
	filterBody: Object;
	signalsAutoUpdate: boolean;
	loading: boolean;
	filterConfig: SignalsListViewFilters;
	id?: number;
	clientId?: number;
};

export const init = (): AppThunk => async dispatch => {
	const response = await SignalsFilter.getFilter();
	const { params } = Tools.AppService.getMetadata();
	dispatch({ type: SET_STATE, ...response, signalsAutoUpdate: params.SignalsAutoUpdate });
};

export const toggleAutoUpdate = (value: boolean): AppThunk => async dispatch => {
	dispatch({ type: SET_STATE, loading: true, signalsAutoUpdate: value });
	try {
		await Tools.ClientParam.save(239, value);
		const metadata = Tools.AppService.getMetadata();
		Tools.AppService.setMetadata({
			...metadata,
			params: {
				...metadata.params,
				SignalsAutoUpdate: value
			}
		});
	} catch (err) {
		logError(err, 'failed when updating signalsAutoUpdate');
	}
	dispatch({ type: SET_STATE, loading: false });
};

export const onChange = (values: JourneyStep[]): AppThunk => async (dispatch, getState) => {
	const state = getState().ProspectingSignals;

	const filters = {
		SignalsFilter: {
			filterName: 'SignalsFilter',
			value: {
				...state.filterConfig.SignalsFilter?.value,
				journey: values.map(v => v.value)
			}
		}
	};

	const build = Tools.FilterHelper.parseFilters(filters, 'account').build();
	const newFilter = {
		id: state.id,
		clientId: state.clientId,
		filterConfig: filters,
		filterBody: build
	};
	const response = await SignalsFilter.saveFilter(newFilter);
	dispatch({ type: SET_STATE, ...response });
};

type ActionHandler = (state: State, action: Partial<State> & { type: string }) => State;

const ACTION_HANDLERS: { [key: string]: ActionHandler } = {
	[RESET]: () => ({ ...initialState }),
	[SET_STATE]: (state, { type, ...newState }) => ({ ...state, ...newState })
};

export default (state: State = initialState, action: State & { type: string }) => {
	const handler = ACTION_HANDLERS[action.type];
	return handler ? handler(state, action) : state;
};
