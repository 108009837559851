enum AccessType {
	ACCOUNT = 'client',
	ACTIVITY = 'activity',
	APPOINTMENT = 'appointment',
	AGREEMENT = 'agreement',
	CONTACT = 'contact',
	CLIENT = 'client',
	ORDER = 'order',
	OPPORTUNITY = 'opportunity',
	PROJECT = 'project',
	REPORT = 'report',
	USERDEFOBJ1 = 'userdefobj1',
	USERDEFOBJ2 = 'userdefobj2',
	USERDEFOBJ3 = 'userdefobj3',
	USERDEFOBJ4 = 'userdefobj4'
}

export default AccessType;
