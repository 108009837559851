import Store from 'Store/index';

class ReduxListeners {
	ARS: angular.IRootScopeService | null = null;
	store: typeof Store | null = null;
	setRootScope(ANGULAR_ROOT_SCOPE: angular.IRootScopeService) {
		this.ARS = ANGULAR_ROOT_SCOPE;
	}
	setStore(store: typeof Store) {
		this.store = store;
	}
	add<T = any>(event: string, ...actions: ((data: T, changedAttributeKeys?: (keyof T)[]) => any)[]) {
		if (!this.ARS) {
			throw new Error('Angular rootScope is not set.');
		}
		if (!this.store) {
			throw new Error('Redux store is not set.');
		}
		const off = this.ARS.$on(event, (e, data: T, changedAttributeKeys?: (keyof T)[]) => {
			actions.forEach(action => this.store?.dispatch(action(data, changedAttributeKeys)));
		});
		return () => off();
	}
}

export default new ReduxListeners();
