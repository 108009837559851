import React from 'react';
import { getJourneyStep } from 'Components/Helpers/journeyStep';
import { EllipsisTooltip, Flex } from '@upsales/components';
import t from 'Components/Helpers/translate';

export default function JourneyColumn({ account }) {
	const status = getJourneyStep(account.journeyStep);

	if (status) {
		return (
			<td className="DataSourceColumn journey">
				<EllipsisTooltip title={t(status.name)}>
					<Flex justifyContent="flex-end">
						<div>{t(status.name)}</div>
					</Flex>
				</EllipsisTooltip>
			</td>
		);
	}
	return <td className="DataSourceColumn journey"></td>;
}
