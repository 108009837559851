import { DropDownButton, DropDownMenu, Button, OutsideClick } from '@upsales/components';
import BemClass from '@upsales/components/Utils/bemClass';
import React, { useRef, useState } from 'react';
import T from 'Components/Helpers/translate';
import './MailTemplateSelectButton.scss';
import MailTemplateSelect from 'App/components/Selectors/MailTemplateSelect';
import MailTemplate from 'App/resources/Model/MailTemplate';
import MailTemplateSaveDropdown from '../MailTemplateSaveDropdown/MailTemplateSaveDropdown';

interface Props {
	onTemplateChange: (template: MailTemplate) => void;
	saveEnabled: boolean;
}
const MailTemplateSelectButton = ({ onTemplateChange, saveEnabled = true }: Props) => {
	const closeDD = useRef<() => void>();
	const classes = new BemClass('MailTemplateSelectButton');
	const [showTemplateSelect, setShowTemplateSelect] = useState(false);
	const [showTemplateSave, setShowTemplateSave] = useState(false);
	const dropdownRef = useRef<HTMLDivElement>();

	return (
		<div className={classes.b()}>
			<DropDownMenu
				align="right"
				arrowed={false}
				renderTrigger={(expanded, setExpanded) => (
					<DropDownButton
						onClick={(e: Parameters<typeof setExpanded>[0]) => {
							e.stopPropagation();
							setExpanded(e);
						}}
						title={T('mail.templates')}
						size="sm"
						expanded={expanded}
					/>
				)}
			>
				{setClosed => {
					closeDD.current = () => {
						setShowTemplateSelect(false);
						setShowTemplateSave(false);
						setClosed();
					};
					return (
						<OutsideClick
							targetRef={() => dropdownRef.current || null}
							outsideClick={closeDD.current}
							iframeListen
						>
							<div
								className={classes.elem('dropdown').b()}
								ref={(r: HTMLDivElement) => (dropdownRef.current = r)}
							>
								{showTemplateSelect || !saveEnabled ? (
									<MailTemplateSelect
										onChange={t => {
											onTemplateChange(t);
											closeDD.current?.();
										}}
									/>
								) : showTemplateSave ? (
									<MailTemplateSaveDropdown closeDD={closeDD} />
								) : (
									<React.Fragment>
										<Button onClick={() => setShowTemplateSelect(true)} color="white">
											{T('mail.useATemplate')}
										</Button>
										<Button onClick={() => setShowTemplateSave(true)} color="white">
											{T('mailTemplate.saveAsTemplate')}
										</Button>
									</React.Fragment>
								)}
							</div>
						</OutsideClick>
					);
				}}
			</DropDownMenu>
		</div>
	);
};

export default MailTemplateSelectButton;
