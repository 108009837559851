import RequestBuilder from 'Resources/RequestBuilder';

type Filters = RequestBuilder | { offset?: number | null; limit?: number | null; [key: string]: any };

type FindFn<T> = (rb: Filters) => Promise<{ data: T[] }>;

export default function findAll<T = any>(find: FindFn<T>, baseFilters: Filters = {}, limit = 1000): Promise<T[]> {
	const result: T[][] = [];

	const get = async (offset: number): Promise<T[]> => {
		const filters = { ...baseFilters };
		filters.offset = offset;
		filters.limit = limit;

		const res = await find(filters);
		result.push(res.data);

		if (res.data.length === limit) {
			return get(offset + limit);
		} else {
			return result.flat();
		}
	};

	return get(0);
}
