import React, { useState } from 'react';
import { Text, Link, Avatar, Block, Icon, Tooltip } from '@upsales/components';
import TimelineRow from 'Components/TimelineRow';
import T from 'Components/Helpers/translate';
import './ActivityTimelinePhonecall.scss';
import UpAudio from 'Components/Inputs/UpAudio';
import bemClass from '@upsales/components/Utils/bemClass';
import getAngularModule from 'App/babel/angularHelpers/getAngularModule';
const ActivityTimelinePhonecall = ({ event, hideIcon }) => {
	const { data, date } = event || {};
	const customerId = Tools.AppService.getCustomerId();
	const phoneNumber = data.phoneNumber;
	const user = Tools.AppService.getUsers().find(e => e.id === event.user?.id);
	const [src, setSrc] = useState('');
	const classes = new bemClass('ActivityTimelinePhonecall');
	const phoneIntegration = Tools.AppService.getPhoneIntegration();

	// Disable play button if this check is false
	const callPlayable =
		!phoneIntegration?.capabilities?.noRecordings && phoneIntegration?.id && event.date && data.conversationUrl;

	const formatTime = timeInSeconds => {
		const secNum = parseInt(timeInSeconds, 10);
		//gets HH:MM:SS
		const time = new Date(secNum * 1000).toISOString().substr(11, 8);
		const timeArr = time.split(':');
		return timeArr[0] !== '00' ? time : timeArr[1] + ':' + timeArr[2];
	};
	const openPhoneCall = () => {
		const URL = getAngularModule('URL');
		const API = getAngularModule('API');
		setSrc(
			URL +
				API +
				'function/voice/recording/?id=' +
				data.conversationUrl +
				'&type=recording&integrationId=' +
				phoneIntegration.id
		);
	};
	const title = (
		<Text>
			{`${T('default.to')}: `}
			{data.contact || data.client ? (
				<Link
					onClick={e => e.stopPropagation()}
					href={
						data.contact
							? Tools.$state.href('contact.dashboard', {
									customerId: customerId,
									id: data.contact.id
							  })
							: Tools.$state.href('account.dashboard', { customerId: customerId, id: data.client.id })
					}
				>
					{data.contact?.name || data.client.name}
				</Link>
			) : (
				T('default.someone')
			)}
		</Text>
	);
	const subTitle = date ? (
		<Text size="sm" color="grey-11">
			{moment(date).format('L LT')}{' '}
			<Text size="sm" color="grey-10">
				{phoneNumber}
			</Text>
		</Text>
	) : null;
	const subSection = null;
	return (
		<TimelineRow
			icon={hideIcon ? undefined : 'phone'}
			event={event}
			title={title}
			subTitle={subTitle}
			subSection={subSection}
			cardHeaderChildren={
				<Block className={classes.elem('audio-section')} space="prm">
					{src ? (
						<UpAudio src={src} unmount={() => setSrc('')} isTimeline />
					) : (
						<React.Fragment>
							{callPlayable ? (
								<Text size="sm" color="bright-blue">
									{formatTime(data.durationInS)}
								</Text>
							) : null}
							<Tooltip disabled={!!callPlayable} title={T('phonecall.noRecording')}>
								<Icon
									color={callPlayable ? 'bright-blue' : 'grey-8'}
									name="play-circle"
									space="prs mls"
									className="play-icon"
									onClick={callPlayable ? openPhoneCall : undefined}
								/>
							</Tooltip>
						</React.Fragment>
					)}
					<Avatar initials={user?.name} email={user?.email} />
				</Block>
			}
		/>
	);
};

export default ActivityTimelinePhonecall;
