import React from 'react';
import './DefaultNoData.scss';
import { Block, Table, TableColumn, TableRow, Text, Title } from '@upsales/components';
import T from 'Components/Helpers/translate';
import bemClass from '@upsales/components/Utils/bemClass';

type DefaultNoDataProps = {
	formatNoData: () => string;
	subtitle?: string | JSX.Element;
	colSpan?: number;
};

export default function DefaultNoData({ formatNoData, colSpan = 100, subtitle }: DefaultNoDataProps) {
	const classes = new bemClass('DefaultNoDataRow');

	return (
		<TableRow className={classes.b()}>
			<TableColumn colSpan={colSpan} align="center">
				<Block space="ptxl pbxl">
					<img alt="upsales briefcase" src="img/empty-briefcase.svg" />
					<Block space="ptl pbl">
						<Title color="grey-10">{formatNoData()}</Title>
					</Block>
					<Text color="grey-10">{subtitle ?? T('noMatches.tryChangeFilters')}</Text>
				</Block>
			</TableColumn>
		</TableRow>
	);
}

export const DefaultNoDataAsTable = (props: DefaultNoDataProps) => (
	<Table>
		<DefaultNoData {...props} />
	</Table>
);
