import InlineAbortConfirm from 'Components/Dialogs/InlineAbortConfirm/InlineAbortConfirm';
import { Icon, Link, Block, Row, Title, Text, Tooltip, DropDownMenu } from '@upsales/components';
import MigrateButton from 'App/components/EditSubscription/SubscriptionPromo/MigrateButton';
import EditorHeaderButton from 'Components/EditorHeader/EditorHeaderButton';
import React, { useState, useRef, useEffect } from 'react';
import bemClass from '@upsales/components/Utils/bemClass';
import T from 'Components/Helpers/translate';

import './EditorHeader.scss';

const EditorHeader = ({
	className,
	title,
	subtitle,
	children,
	infoLink = '',
	onAbort,
	onAbortTitle = '',
	onAbortConfirm = false,
	onBackTitle = '',
	onConfirm,
	onConfirmTitle,
	onConfirmSupertitle,
	onConfirmTooltip = '',
	onConfirmHasNext = false,
	onConfirmIcon = '',
	tempMigrateButton = false,
	icon = '',
	disableSave,
	onBack = null,
	loading = false,
	titleExtra = () => {},
	toolTip = null,
	...props
}) => {
	const classes = new bemClass('EditorHeader', className);

	const [titleTooLong, setTitleTooLong] = useState(false);
	const titleRef = useRef();

	const isTooLong = element => (element ? element.offsetWidth < element.scrollWidth : false);

	const onWindowResize = () => {
		setTitleTooLong(isTooLong(titleRef.current));
	};

	useEffect(() => {
		window.addEventListener('resize', onWindowResize);

		return () => window.removeEventListener('resize', onWindowResize);
	}, []);

	useEffect(() => {
		setTitleTooLong(isTooLong(titleRef.current));
	}, [title]);
	return (
		<div {...props} className={classes.b()}>
			{title ? (
				<>
					<div className={classes.elem('icon').b()}>{icon ? <Icon name={icon} size="lg" /> : null}</div>
					<div className={classes.elem('title').b()}>
						<Tooltip title={toolTip ?? title} disabled={!titleTooLong}>
							<Title size="sm" color="black">
								<div ref={titleRef}>
									{title}
									{titleExtra()}
								</div>
							</Title>
						</Tooltip>
						{subtitle ? (
							<Text size="sm" color="grey-10">
								{subtitle}
							</Text>
						) : null}
					</div>
				</>
			) : null}
			{onAbort || onConfirm || onBack ? (
				<div className={classes.elem('controls').b()}>
					<Row>
						{infoLink?.length ? (
							<Row align="center">
								<Block space="mrl">
									<Link href={infoLink} target="_blank">
										<Icon name="question-circle" color="black" />
									</Link>
								</Block>
							</Row>
						) : null}
						{tempMigrateButton ? <MigrateButton /> : null}
						{onBack ? (
							<EditorHeaderButton
								title={onBackTitle || T('editorHeader.controls.back')}
								onClick={onBack}
							/>
						) : null}
						{onAbort ? (
							<DropDownMenu
								data-testid="DropDownMenu"
								useAnimation
								noMinWidth
								align="right"
								renderTrigger={(expanded, setExpanded) => (
									<EditorHeaderButton
										title={onAbortTitle || T('default.abort')}
										onClick={onAbortConfirm ? setExpanded : onAbort}
									/>
								)}
								children={setClosed => <InlineAbortConfirm onAbort={onAbort} onReturn={setClosed} />}
							/>
						) : null}
						{onConfirm ? (
							<Tooltip title={onConfirmTooltip} disabled={!onConfirmTooltip} data-testid="title-tooltip">
								<EditorHeaderButton
									title={onConfirmTitle}
									supertitle={onConfirmSupertitle}
									next
									onClick={onConfirm}
									disabled={disableSave}
									noIcon={!onConfirmHasNext && !onConfirmIcon}
									icon={onConfirmIcon?.length ? onConfirmIcon : undefined}
									loading={loading}
								/>
							</Tooltip>
						) : null}
					</Row>
				</div>
			) : null}
			{children}
		</div>
	);
};

export default EditorHeader;
