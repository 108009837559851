/*
	State management helpers

	Utils for creating react-contexts with selectors and dispatchers.

	usage:
		const actionHandlers = {
			INCREMENT_COUNT: (state, action) => ({ ...state, count: state.count + action.count }),
		};
		export { Provider, useSelector, useDispatch } = createLocalContextWithActionHandlers(actionHandlers);
	or better named:
		const c = createLocalContextWithActionHandlers(actionHandlers);
		export const OrderModalProvider = c.Provider;
		export const useOrderModalSelector = c.useSelector;
		export const useOrderModalDispatch = c.useDispatch;
*/

import React, {
	createContext,
	ReactNode,
	useCallback,
	useEffect,
	useMemo,
	useRef,
	useContext,
	useReducer
} from 'react';

interface ProviderProps<T> {
	readonly value: T;
	readonly children: ReactNode;
}

export type Action = { type: string; [key: string]: any };
export type Dispatch = (action: Action) => void;
type ActionHandlers<T> = { [key: string]: (state: T, action: Action) => T };

type ContextGetter<T> = () => T;
type ContextListener<T> = (state: T) => void;
type ContextListeners<T> = Set<ContextListener<T>>;
type SelectorContextType<T> = readonly [ContextGetter<T>, ContextListeners<T>];
type Selector<T, TSelected> = (state: T) => TSelected;

const throwContextError = (fn: string) => {
	throw new Error(`You can only call ${fn} inside a valid context.`);
};

// TODO: Create an alternative that takes a reducer for more flexibility
// export const createLocalContext = <T,>(reducer: (state: T, action: any) => T) => {
export function createLocalContextWithActionHandlers<T>(actionHandlers: ActionHandlers<T>) {
	// Context holding the "real" state
	const StateContext = createContext<T>({} as T);
	// Context holding the dispatch function
	const DispatchContext = createContext<Dispatch>(() => {});
	// Context holding the selectors to partially get the state.
	const SelectorContext = createContext<SelectorContextType<T>>([() => ({} as T), new Set()]);

	// The exposed useSelector hook
	function useSelector<TSelected = any>(selector: Selector<T, TSelected>): TSelected {
		// Connect to the context and set up the forceUpdate function
		const [getter, listeners] = useContext(SelectorContext);
		const [, forceUpdate] = useReducer(dummy => dummy + 1, 0);

		// Store the last selector and selected state
		const latestSelector = useRef(selector);
		const latestSelectedState = useRef<TSelected>();

		// Current value of the state
		const currentValue = getter();

		// Make sure the hook is called inside a valid context
		if (currentValue === undefined) {
			throwContextError('useSelector');
		}

		// Run the selector with the current value to get the selected state
		const selectedState = useMemo(() => selector(currentValue), [currentValue, selector]);

		// Update the refs anytime the state, selected state or selector changes
		useEffect(() => {
			latestSelector.current = selector;
			latestSelectedState.current = selectedState;
		}, [currentValue, selectedState, selector]);

		// Add the listener to be called when the state changes
		useEffect(() => {
			const listener = (nextValue: T) => {
				// Run the selector with the new value
				const newSelectedState = latestSelector.current?.(nextValue);

				// If the selected state has changed, force an update
				if (newSelectedState !== latestSelectedState.current) {
					forceUpdate();
				}
			};

			listeners.add(listener);

			return () => {
				listeners.delete(listener);
			};
		}, [listeners]);

		return selectedState;
	}

	// The exposed useDispatch hook. It returns the dispatch function of this context, but adds the ability to pass a function that gets the dispatch function as an argument.
	const useDispatch = () => {
		const d = useContext(DispatchContext);
		if (!d) {
			throwContextError('useDispatch');
		}
		const dispatch = useCallback(
			action => {
				if (typeof action === 'function') {
					return d(action.bind(null, d));
				}
				return d(action);
			},
			[d]
		);
		return dispatch;
	};

	const reducer = (state: T, action: Action | ((state: T) => Action)) => {
		if (typeof action === 'function') {
			action = action(state);
		}
		if (!action?.type) return state;
		const handler = actionHandlers[action.type];
		return handler ? handler(state, action) : state;
	};

	const Provider = ({ children, value }: ProviderProps<T>) => {
		// Set up the reducer
		const [state, dispatch] = useReducer(reducer, { ...value });

		// Ref to the current value of the state
		const stateValueRef = useRef(state);

		// Ref to the listeners
		const listeners = useRef<ContextListeners<T>>(new Set());

		// Every time the state changes, update the ref and call the listeners
		useEffect(() => {
			stateValueRef.current = state;
			listeners.current.forEach(listener => {
				listener(state);
			});
		}, [state]);

		// Function to get the current value of the state
		const getContextValue: ContextGetter<T> = useCallback(() => {
			return stateValueRef.current;
		}, [stateValueRef]);

		// The selector context value. Holds the getter and the listeners to call when the state changes
		const contextValue: SelectorContextType<T> = useMemo(
			() => [getContextValue, listeners.current],
			[stateValueRef]
		);

		// Wrap the children in the contexts
		return (
			<StateContext.Provider value={state}>
				<SelectorContext.Provider value={contextValue}>
					<DispatchContext.Provider value={dispatch}>{children}</DispatchContext.Provider>
				</SelectorContext.Provider>
			</StateContext.Provider>
		);
	};

	return {
		Provider,
		useSelector,
		useDispatch
	};
}
