import Resource from './Resource';
import {
	StandardIntegrationDataRequest,
	StandardIntegrationDataResponse
} from 'App/resources/Model/StandardIntegrationData';

class StandardIntegrationDataResource extends Resource {
	constructor() {
		super('function/standardIntegrationData');
	}

	transformRequest<RequestData>(data: StandardIntegrationDataRequest<RequestData> | string) {
		if (typeof data === 'string') {
			try {
				return JSON.parse(data);
			} catch (err) {
				return data;
			}
		}
		return data;
	}

	run<RequestData, ResponseData>(
		data: StandardIntegrationDataRequest<RequestData>
	): Promise<StandardIntegrationDataResponse<ResponseData>> {
		return this.save(data);
	}
}

const resource = new StandardIntegrationDataResource();

export default resource;
