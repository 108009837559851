export const OUTCOME_TYPES = {
	NO_ANSWER: 'not_reached',
	ANSWER: 'reached',
	POSTPONE: 'postpone'
} as const;

export const LEAD_TYPES = {
	NO_LEAD: 'no_lead_found',
	FOUND_LEAD: 'found_lead'
} as const;

export const FOLLOWUP_TYPES = {
	NEW_ACTIVITY: 'new_activity',
	BOOK_APPOINTMENT: 'book_appointment',
	CREATE_TODO: 'create_todo',
	PLAN_PHONE_CALL: 'plan_phone_call'
} as const;

export const NO_ANSWER_ACTIONS = {
	POSTPONE_DATE: 'postponed',
	SEND_MAIL: 'send_mail',
	STOP_TRYING: 'stop_trying',
	TALK_TO_SOMEONE: 'change_contact'
} as const;

export const ANSWER_ACTIONS = {
	BUSY: 'busy',
	STOP_TRYING: 'stop_trying',
	NO_THANKS: 'no_thanks',
	SEND_MAIL: 'send_mail',
	TALK_TO_SOMEONE: 'change_contact'
} as const;

export const ANSWER_ACTIONS_GREAT = {
	BOOK_APPOINTMENT: 'book_appointment',
	CREATE_OPPORTUNITY: 'create_opportunity',
	CREATE_ORDER: 'create_order',
	PLAN_PHONE_CALL: 'plan_phone_call'
} as const;

export const NO_LEAD_ACTIONS = {
	POSTPONE_DATE: 'postponed',
	STOP_TRYING: 'stop_trying'
} as const;

export const FOUND_LEAD_ACTIONS = {
	ADDED_CONTACT: 'added_contact'
} as const;

export const DEFAULT_PRESET = 'nextDay';
export const DEFAULT_PRESETS = [
	'currentDay',
	'nextDay',
	'next7days',
	'next30days',
	'next60days',
	'next90days'
] as const;

export const POSTPONED = 'postponed';
export const USER_CHANGED = 'userChanged';

export const CHANGED_CONTACT = 'changed_contact';

export const SET_DATE = 'set_date';

export const OUTCOME_BACKEND_ACTIONS = {
	[ANSWER_ACTIONS.BUSY]: POSTPONED
} as const;

export const APPOINTMENT_OUTCOME_TYPES = {
	PLANNED: 'planned',
	COMPLETED: 'completed',
	NOT_COMPLETED: 'notCompleted'
} as const;
