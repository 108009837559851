import BemClass from '@upsales/components/Utils/bemClass';
import T from 'Components/Helpers/translate';
import { Block, Column, DrawerHeader, RadioItem, Select, Text, Row, Icon, Label } from '@upsales/components';
import React, { useMemo, useRef } from 'react';

import './FlowLoopSettings.scss';
import { ModalProps } from 'App/components/Modals/Modals';
import Flow from 'App/resources/Model/Flow';
import { SelectItem } from '@upsales/components/Utils/selectHelpers';
import { PrimaryButton } from '@upsales/components/Buttons';
import { SlideFade } from '@upsales/components/animations';
import FormObserver, { FieldModel } from 'App/components/FormObserver';
import { FormNumberInput } from 'App/components/FormComponent';

export type OnCloseReturnType = Pick<Flow, 'loop' | 'loopTime' | 'loopUnit'>;

type Props = ModalProps<OnCloseReturnType> & OnCloseReturnType & {};

const radioItems = [
	{ title: 'flow.step.end.title', subtitle: 'flow.nonLoopedFlowInfo', value: false },
	{
		title: 'flow.loopFlow',
		subtitle: 'flow.loopedFlowInfo',
		value: true
	}
];

type UnitItem = SelectItem & { id: Flow['loopUnit'] };

const FlowLoopSettings = ({ className, close, loop, loopTime, loopUnit }: Props) => {
	const classes = new BemClass('FlowLoopSettings', className);
	const selectAnchor = useRef(null);

	const units: { [key in Flow['loopUnit']]: UnitItem } = {
		week: { id: 'week', title: T('date.weeks') },
		month: { id: 'month', title: T('date.months') }
	};
	const formModel = useMemo(
		() => ({
			loop: FieldModel.boolean(''),
			loopTime: FieldModel.number('flow.delay').min(1).required(),
			loopUnit: FieldModel.string('').required()
		}),
		[]
	);

	return (
		<div className={classes.b()} ref={selectAnchor}>
			<DrawerHeader onHide={() => close()} title={T('flow.editEndpoint')} />
			<Block className={classes.elem('content').b()} backgroundColor="grey-1" space="prxl pbxl plxl ptm">
				<FormObserver<OnCloseReturnType>
					initialValues={{ loop, loopTime: loopTime || 1, loopUnit }}
					model={formModel}
					onSubmit={values => close(values)}
					onlyValidateTouched
				>
					{({ onFormChange, values, inputProps, errorMessages, dirty, errors }) => (
						<>
							<Block space="mbl">
								<Text size="sm" bold color="grey-11">
									{T('flow.behavior')}
								</Text>
							</Block>
							{radioItems.map(({ value, title, subtitle }) => (
								<Block key={value + '-row'} space="mbl" className={classes.elem('radio-row').b()}>
									<Row
										onClick={() => {
											if (!value) {
												values.loopTime = 1;
												onFormChange('loopTime', 1);
												values.loopUnit = 'month';
												onFormChange('loopUnit', 'month');
											}
											onFormChange('loop', value);
										}}
									>
										<Column fixedWidth={44}>
											<RadioItem
												checked={value === values.loop}
												size="sm"
												label=""
												value={value}
											/>
										</Column>
										<Column>
											<Text>{T(title)}</Text>
											<Text size="sm" color="grey-11">
												{T(subtitle)}
											</Text>
										</Column>
									</Row>
								</Block>
							))}
							<SlideFade visible={values.loop} direction="top" height maxHeight={86}>
								<Block space="mbl">
									<Row className={classes.elem('loop-inputs').b()}>
										<Column>
											<FormNumberInput
												label={T(inputProps.loopTime.label)}
												inputProps={inputProps.loopTime}
												message={errors.loopTime ? errorMessages.loopTime : null}
											/>
										</Column>
										<Column fixedWidth={16} />
										<Column>
											<Label>&nbsp;</Label>
											<Select
												value={units[values.loopUnit]}
												options={Object.values(units)}
												anchor={selectAnchor.current}
												scrollContainer={selectAnchor.current}
												onChange={selected => onFormChange('loopUnit', selected.id)}
												showSearch={false}
											/>
										</Column>
									</Row>
								</Block>
							</SlideFade>
							<PrimaryButton submit>
								<Icon name="check" space="mrs" /> {T('default.save')}
							</PrimaryButton>
						</>
					)}
				</FormObserver>
			</Block>
		</div>
	);
};

export default FlowLoopSettings;
