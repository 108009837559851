import React from 'react';
import {
	useOrderRowsDispatch,
	OrderRow as _OrderRow,
	useOrderRowsSelector
} from 'App/components/OrderRows/Context/OrderContext';
import { TableRow, TableColumn, NumberInput } from '@upsales/components';
import { DisplaySettings, getOrderRowSummary } from './helpers';
import { CurrencyFormat, NumberFormat } from 'App/babel/utils/numberFormat';
import { useSoftDeployAccess } from '../hooks';
import BemClass from '@upsales/components/Utils/bemClass';
import { setPrice, setPurchaseCost, setQuantity } from '../OrderRows/Context/OrderContextHelpers';

type OrderRow = Required<_OrderRow>;

type BundleRowProps = BundleRowsProps & { orderRow: OrderRow };
const ComponentRow = (props: BundleRowProps) => {
	const { displaySettings, priceAdjustment, productHasPriceAdjustment, orderRow, currency, showCost } = props;
	const classes = new BemClass('BundleRow');
	const dispatch = useOrderRowsDispatch();

	const numberFormat = new NumberFormat(undefined, 0);
	const currencyFormat = new CurrencyFormat(currency, undefined, displaySettings.priceDecimalPlaces);
	const hasBundleAdjustable = useSoftDeployAccess('BUNDLE_ADJUSTABLE');
	const summary = getOrderRowSummary(displaySettings, currency, orderRow);
	const listPrice = orderRow.listPrice;
	const price = orderRow.price;

	const regularDiscount = parseFloat(((listPrice - price) / listPrice).toFixed(2));

	// If the bundle has an initial added cost. The discount is negative.
	const discountPercent = priceAdjustment > 1 && regularDiscount !== 0 ? regularDiscount * -1 : regularDiscount;

	return (
		<TableRow className={!orderRow.tierQuantity ? classes.elem('unused').b() : ''}>
			<TableColumn
				size="lg"
				title={orderRow.product.name}
				subtitle={
					displaySettings.showArticleNo && orderRow.product.articleNo ? orderRow.product.articleNo : undefined
				}
			/>
			<TableColumn size="lg">
				<NumberInput
					min={0}
					max={Number.MAX_SAFE_INTEGER}
					decimals={10}
					value={orderRow.tierQuantity}
					onChange={tierQuantity => dispatch(setQuantity(orderRow.uuid, tierQuantity, currency, true))}
				/>
			</TableColumn>
			{productHasPriceAdjustment ? (
				<TableColumn size="lg" title={listPrice > 0 ? numberFormat.percent(discountPercent) : ''} />
			) : null}

			{!hasBundleAdjustable || !orderRow.adjustable ? (
				<TableColumn size="lg" title={currencyFormat.default(orderRow.price)} />
			) : null}
			{hasBundleAdjustable && !orderRow.adjustable && showCost ? (
				<TableColumn size="lg" title={currencyFormat.default(orderRow.purchaseCost)} />
			) : null}
			{hasBundleAdjustable && orderRow.adjustable ? (
				<>
					<TableColumn size="lg">
						<NumberInput
							max={Number.MAX_SAFE_INTEGER}
							decimals={10}
							value={orderRow.bundleRowPrice ?? orderRow.price}
							onChange={price => dispatch(setPrice(orderRow.uuid, price, currency, true))}
							currency={currency}
						/>
					</TableColumn>
					{showCost ? (
						<TableColumn size="lg">
							<NumberInput
								min={0}
								max={Number.MAX_SAFE_INTEGER}
								decimals={10}
								value={orderRow.purchaseCost}
								onChange={purchaseCost => dispatch(setPurchaseCost(orderRow.uuid, purchaseCost, true))}
								currency={currency}
							/>
						</TableColumn>
					) : null}
				</>
			) : null}
			<TableColumn align="right" size="lg" title={summary.title} subtitle={summary.subtitle} />
		</TableRow>
	);
};

type BundleRowsProps = {
	priceAdjustment: number;
	productHasPriceAdjustment: boolean;
	displaySettings: DisplaySettings;
	currency: string;
	showCost: boolean;
};

const BundleRows = ({ ...props }: BundleRowsProps) => {
	const orderRows = useOrderRowsSelector(s => s.orderRows);

	return (
		<>
			{orderRows.map(orderRow => (
				<ComponentRow key={orderRow.uuid} orderRow={orderRow as Required<OrderRow>} {...props} />
			))}
		</>
	);
};

export default BundleRows;
