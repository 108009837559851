import './BorderBox.scss';
import React, { ComponentProps } from 'react';
import BemClass from '@upsales/components/Utils/bemClass';
import { Card } from '@upsales/components';

type Props = {
	children: React.ReactNode;
	className?: string;
	boxShadow?: boolean;
} & ComponentProps<typeof Card>;

const BorderBox = ({ children, className, boxShadow = false, ...rest }: Props) => {
	const classes = new BemClass('BorderBox', className);

	return (
		<Card className={classes.mod({ boxShadow }).b()} borderColor="grey-6" border="small" borderRadius {...rest}>
			{children}
		</Card>
	);
};

export default BorderBox;
