import File from 'App/resources/Model/File';
import type { AttachmentWithSize } from 'App/resources/Model/Mail';

/**
 * Check if attachment is uploading
 * @returns {boolean} true if any attachment is still uploading | false if attachments upload done
 */
export const checkFileUploading = (attachments: File) => {
	if (Array.isArray(attachments)) {
		return attachments.some(item => item.pending === true);
	} else {
		return false;
	}
};

export const convertFileToAttachment = (file: File): AttachmentWithSize => ({
	value: String(file.id),
	filename: file.filename,
	type: file.type,
	size: file.size
});
