import React, { useEffect } from 'react';
import bemClass from '@upsales/components/Utils/bemClass';
import { Button, Column, Input, Icon, Tooltip } from '@upsales/components';
import UserSelect from 'Components/UserSelect';
import T from 'Components/Helpers/translate';
import OpportunityTodosTimepicker from '../OpportunityTodosTimepicker';
import { Fade } from 'App/components/animations';
import FakeTooltip from 'Components/Tooltip/FakeTooltip';
import Activity from 'App/resources/Model/Activity';
import User from 'App/resources/Model/User';

import './OpportunityEditTodoRow.scss';

interface Props {
	todo: Activity;
	onChange: (todo: Activity) => void;
	inputIcon?: string;
	autofocus?: boolean;
	noTooltip?: boolean;
	showTimePicker?: boolean;
	selectUser?: boolean;
}

const OpportunityEditTodoRow = ({
	todo,
	onChange,
	inputIcon,
	autofocus = false,
	noTooltip = false,
	showTimePicker = true,
	selectUser = false
}: Props) => {
	const classes = new bemClass('OpportunityEditTodoRow');
	const inputRef = React.useRef<HTMLInputElement>(null);

	useEffect(() => {
		const input = inputRef.current;
		input?.setSelectionRange(0, input.value.length);
	}, []);

	const [focused, setFocused] = React.useState(false);
	const onFocus = () => setFocused(true);
	const onBlur = () => setFocused(false);

	const focusDateElement = () => {
		const node = document.querySelector('.OpportunityTodosTimepicker #trigger') as HTMLButtonElement;
		if (node && !todo.date) {
			node.click();
		}
	};
	return (
		<React.Fragment>
			{selectUser ? (
				<Column className={classes.elem('contact-col').b()} size={3}>
					<UserSelect
						user={todo.users?.length ? (todo.users[0] as User) : Tools.AppService.getSelf()}
						onChange={user =>
							onChange({
								...todo,
								users: [user ? user : Tools.AppService.getSelf()]
							})
						}
						className={classes.elem('user-select').b()}
						preText={T('todo.whatShouldXDoPre')}
						postText={T('todo.whatShouldXDoPost')}
					/>
				</Column>
			) : null}
			<Column className={classes.elem('text').b()} size={selectUser ? 6 : undefined}>
				<Input
					className={classes.elem('desc-col').b()}
					//lastpass don't hijack my stuff
					data-lpignore="true"
					icon={inputIcon}
					inputRef={inputRef}
					onFocus={onFocus}
					onBlur={onBlur}
					noborder={true}
					value={todo.description}
					onChange={e => onChange({ ...todo, description: e.target.value })}
					placeholder={T('todo.typeToAdd')}
					autofocus={autofocus}
					maxLength={100}
					onKeyDown={event => {
						if (event.key === 'Tab' && todo.description.length) {
							event.preventDefault();
							focusDateElement();
						}
					}}
				/>
				{todo.description.length && focused && !noTooltip ? (
					<FakeTooltip title={T('todo.editTodo.confirmEnter')} />
				) : null}
			</Column>
			<Fade visible={todo.description.length > 0 || !!todo.date}>
				<Column align="right" className={classes.elem('date-col').b()}>
					{showTimePicker ? (
						<OpportunityTodosTimepicker
							onChange={e => onChange({ ...todo, date: e.date, time: e.time })}
							onClear={() => onChange({ ...todo, date: null, time: '' })}
							date={todo.date as Date}
							time={todo.time || ''}
							autofocus={!todo.date}
						/>
					) : null}

					<Button
						type="link"
						size="sm"
						color={todo.priority === 3 ? 'red' : 'grey'}
						onClick={() => onChange({ ...todo, priority: todo.priority === 3 ? 0 : 3 })}
					>
						<Tooltip title={T(todo.priority === 3 ? 'todo.prioTodo' : 'todo.prioritizeTodo')}>
							<Icon name="flag" />
						</Tooltip>
					</Button>
				</Column>
			</Fade>
		</React.Fragment>
	);
};

export default OpportunityEditTodoRow;
