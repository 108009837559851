import Resource from 'Resources/Resource';
import Attributes from 'App/babel/attributes/FlowAttributes';
import Flow, { FlowStepType, FlowStep, EndCriteria } from 'App/resources/Model/Flow';
import MergeFilters from 'Services/MergeFilters';
import logError from 'Helpers/logError';

export enum EndCriteriaName {
	EmailEndCriteria = 'EmailEndCriteria',
	AppointmentGoalEndCriteria = 'AppointmentGoalEndCriteria'
}
class FlowResource extends Resource {
	constructor() {
		super('flows', Attributes);
	}

	map(unmappedData: Partial<Flow>) {
		const endCriterias =
			unmappedData.endCriterias?.map(criteria => {
				let filterBody = '';
				try {
					const [{ body }] = MergeFilters([{ config: criteria.filterConfig }]);
					filterBody = JSON.stringify(body);
				} catch (e) {
					console.log(logError(e, 'Failed to generate filter body'));
				}

				return { ...criteria, filterBody };
			}) || undefined;

		return { ...unmappedData, endCriterias };
	}

	getEndCriteria(name: EndCriteriaName) {
		return ({
			EmailEndCriteria: {
				isGoal: false,
				filterConfig: {
					InboundEmail: {
						inactive: false,
						value: {
							hasInbound: true,
							Date: {
								value: {
									start: null,
									end: null,
									preset: 'last7days'
								}
							}
						},
						filterName: 'InboundEmail',
						dataType: 'raw'
					}
				},
				filterBody: ''
			},
			AppointmentGoalEndCriteria: {
				isGoal: true,
				filterConfig: {
					Appointment: {
						inactive: false,
						value: {
							hasAppointment: true,
							ActivityType: {
								value: []
							},

							RegDate: {
								value: {
									start: null,
									end: null,
									preset: 'last7days'
								}
							}
						},
						filterName: 'Appointment',
						dataType: 'raw'
					}
				},
				filterBody: ''
			}
		} as { [key in EndCriteriaName]: EndCriteria })[name];
	}

	newStep(type: FlowStepType, props: Partial<FlowStep> = {}) {
		const step: FlowStep = {
			type,
			params: [],
			childYes: null,
			childNo: null,
			waitTime: null,
			waitUnit: null,
			formId: null,
			templateId: null,
			mailCampaignId: null,
			link: null
		};

		switch (type) {
			case 'snooze':
			case 'readMail':
				step.waitTime = props.waitTime || 1;
				step.waitUnit = props.waitUnit || 'day';
				break;
			case 'clickedMail':
				step.waitTime = props.waitTime || 1;
				step.waitUnit = props.waitUnit || 'day';
				step.link = props.link || null;
				break;
			case 'formSubmit':
				step.waitTime = props.waitTime || 1;
				step.waitUnit = props.waitUnit || 'day';
				step.formId = props.formId || null;
				break;
			case 'sendMail':
				step.params = [];
				step.templateId = props.templateId || null;
				break;
			case 'createActivity':
			case 'updateClient':
			case 'updateContact':
			case 'assign':
				step.params = props.params || [];
				break;
		}

		return step;
	}
}

const resource = new FlowResource();

export default resource;
