import Resource from 'Resources/Resource';

export type TriggerAttribute = {};

class Trigger extends Resource {
	constructor() {
		super('triggers');
	}

	getTriggerAttributes(): Promise<{ data: { [key: string]: TriggerAttribute[] } }> {
		return super
			._getRequest('', { methodName: 'getTriggerAttributes', _url: 'triggerAttributes' })
			.then(r => r.data);
	}
}

const resource = new Trigger();

export default resource;
