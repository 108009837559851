import CustomField, { EntityCustomField, MappedCustomFields } from '../Model/CustomField';
import { parseDate } from 'App/resources/genericParser';

export const parseCustom = (
	objFields: Readonly<EntityCustomField[]>,
	type: Parameters<typeof Tools['AppService']['getCustomFields']>[0]
) => {
	const customFields: Readonly<CustomField[]> = Tools.AppService.getCustomFields(type);
	const mappedCustom = customFields.reduce<MappedCustomFields>((res, field) => {
		const valueObj = objFields.find(f => f.fieldId === field.id);

		res[field.id] = {
			...field,
			fieldId: field.id,
			visible: !!field.visible,
			editable: !!field.editable,
			value: null,
			dropdownDefault: ''
		};

		// Check default value if array --> make it a string
		if (field.datatype === 'Select') {
			res[field.id].dropdownDefault = Array.isArray(field.dropdownDefault)
				? field.dropdownDefault[0]
				: field.dropdownDefault;
			res[field.id].default = field.default ?? [];
		} else if (field.datatype !== 'Calculation') {
			res[field.id].default = Array.isArray(field.default) ? field.default[0] : field.default;
		} else {
			res[field.id].default = '';
		}

		if (valueObj) {
			res[field.id].value = valueObj.value;
			switch (res[field.id].datatype) {
				case 'Boolean':
					res[field.id].value = res[field.id].value ? true : false;
					break;
				case 'Date':
					res[field.id].value = parseDate(res[field.id].value);
					break;
			}
		}
		return res;
	}, {});

	return mappedCustom;
};
