import { Text, Title, Icon, Headline, OutsideClick } from '@upsales/components';
import AgreementGroup from 'App/resources/Model/AgreementGroup';
import { DefaultButton } from '@upsales/components/Buttons';
import CardParents from '../SubscriptionCards/CardParents';
import BemClass from '@upsales/components/Utils/bemClass';
import { ClientIdName } from 'App/resources/Model/Client';
import ExitConfirmDropdown from './ExitConfirmDropdown';
import OrderStage from 'App/resources/Model/OrderStage';
import StagesDropdownMenu from './StagesDropdownMenu';
import Order from 'App/resources/Model/Order';
import T from 'Components/Helpers/translate';
import openModal from 'App/services/Modal';
import React, { useState } from 'react';

import './WonOpportunityWithSubs.scss';

type Props = {
	saveWithStageAndOpenWonModal: (stage: OrderStage) => void;
	openWonModalFollowedByDiffOrder: () => void;
	activeAgreementGroups: AgreementGroup[];
	close: (data?: object) => void;
	preSelectedStage?: OrderStage;
	fromSalesboard?: boolean;
	client: ClientIdName;
	currentStage: string;
	cancel?: () => void;
	className: string;
	order: Order;
};

const WonOpportunityWithSubs = ({
	cancel = () => {},
	order,
	close,
	client,
	className,
	currentStage,
	fromSalesboard,
	activeAgreementGroups,
	preSelectedStage,
	saveWithStageAndOpenWonModal,
	openWonModalFollowedByDiffOrder
}: Props) => {
	const classes = new BemClass('WonOpportunityWithSubs', className);
	classes.add('FullScreenModal');

	const [showExit, setShowExit] = useState(false);

	const openNewSubscriptionWithStage = (stage: OrderStage) => {
		openModal('CreateSubscription', {
			order: { ...order, stage },
			needsToConvert: !!fromSalesboard,
			createdFrom: 'saveOrder',
			onSave: () => {
				close();
				saveWithStageAndOpenWonModal(stage);
			}
		});
	};

	return (
		<div className={classes.b()}>
			{showExit ? (
				<OutsideClick outsideClick={() => setShowExit(false)} targetClass="ExitConfirmDropdown">
					<ExitConfirmDropdown
						currentStage={currentStage}
						close={() => {
							close();
							cancel();
						}}
						hideExit={() => setShowExit(false)}
					/>
				</OutsideClick>
			) : null}
			<Icon onClick={() => setShowExit(true)} name="times" className={classes.elem('exit-button').b()} />
			<div className={classes.elem('container').b()}>
				<Headline size="sm">
					{T('subscription.wonOpportunity.title')} <span>{client.name}</span>
				</Headline>
				<Text color="grey-11">
					{T('subscription.wonOpportunity.subtitle', { count: activeAgreementGroups.length })}
				</Text>
				<CardParents
					client={client}
					willUpdateAgreement
					orderToAdd={order}
					onSave={() => {
						close();
						openWonModalFollowedByDiffOrder();
					}}
					showContactLink={false}
					agreementGroups={activeAgreementGroups}
				/>
				<Title size="lg">{T('subscription.wonOpportunity.orCreateNew')}</Title>
				<div className={classes.elem('buttons').b()}>
					{preSelectedStage ? (
						<>
							<DefaultButton onClick={() => openNewSubscriptionWithStage(preSelectedStage)}>
								<Icon name="subscription" />
								{T('subscription.wonOpportunity.createNewSubscription')}
							</DefaultButton>
							<DefaultButton
								onClick={() => {
									close();
									saveWithStageAndOpenWonModal(preSelectedStage);
								}}
							>
								<Icon name="sales" />
								{T('subscription.wonOpportunity.createIndependantOrder')}
							</DefaultButton>
						</>
					) : (
						<>
							<StagesDropdownMenu
								icon="subscription"
								onClick={openNewSubscriptionWithStage}
								title={T('subscription.wonOpportunity.createNewSubscription')}
							/>
							<StagesDropdownMenu
								icon="sales"
								onClick={stage => {
									close();
									saveWithStageAndOpenWonModal(stage);
								}}
								title={T('subscription.wonOpportunity.createIndependantOrder')}
							/>
						</>
					)}
				</div>
			</div>
		</div>
	);
};

export default WonOpportunityWithSubs;
