import { RowChanged as RowChangedType, QuantityTypes, PriceTypes, Change } from 'App/babel/resources/ARRChangesOnDate';
import TooltipOverflowText from 'App/components/Text/TooltipOverflowText';
import { CurrencyFormat } from 'App/babel/utils/numberFormat';
import BemClass from '@upsales/components/Utils/bemClass';
import T from 'Components/Helpers/translate';
import { Text } from '@upsales/components';
import React from 'react';

import './RowChanged.scss';

type Props = {
	changeType: Change['type'];
	changeRow: RowChangedType;
	currency: string;
	mrr: boolean;
};

const RowChanged = ({ changeType, changeRow, currency, mrr }: Props) => {
	const classes = new BemClass('RowChanged');
	const currencyFormat = new CurrencyFormat(currency);
	const recurringType = T(mrr ? 'default.monthlyValue' : 'default.annualValue');
	const quantityChanged = changeRow.quantityType !== QuantityTypes.QUANTITY_UNCHANGED;
	const priceChanged = changeRow.priceType !== PriceTypes.PRICE_UNCHANGED;
	const isNew = ['ADDED', 'NEW_CLIENT', 'WIN_BACK'].indexOf(changeType) >= 0;

	return (
		<div className={classes.b()}>
			<Text className={classes.elem('product').b()}>
				<TooltipOverflowText tooltipProps={{ title: changeRow.productName }}>
					{changeRow.productName}
				</TooltipOverflowText>
			</Text>
			<div className={classes.elem('change').b()}>
				<Text size="sm" color="grey-11">
					{T('default.quantity')}
				</Text>
				{quantityChanged && !isNew ? (
					<>
						<Text className={classes.elem('previous').b()}>{changeRow.quantityPrevious}</Text>
						<Text>{` -> ${changeRow.quantityCurrent}`}</Text>
					</>
				) : (
					<Text italic={!isNew} color={isNew ? 'black' : 'grey-10'}>
						{changeRow.quantityCurrent}
					</Text>
				)}
			</div>
			<div className={classes.elem('change').b()}>
				<Text size="sm" color="grey-11">
					{`${T('default.price')} (${changeRow.interval} ${
						changeRow.interval === 1 ? T('default.month') : T('default.months')
					})`}
				</Text>
				{priceChanged && !isNew ? (
					<>
						<Text className={classes.elem('previous').b()}>
							{currencyFormat.short(changeRow.pricePrevious)}
						</Text>
						<Text>{` -> ${currencyFormat.short(changeRow.priceCurrent)}`}</Text>
					</>
				) : (
					<Text italic={!isNew} color={isNew ? 'black' : 'grey-10'}>
						{currencyFormat.short(changeRow.priceCurrent)}
					</Text>
				)}
			</div>
			<Text
				className={classes.elem('sum').b()}
				color={changeRow.change < 0 ? 'danger-5' : 'success-6'}
			>{`${currencyFormat.short(changeRow.change)} (${recurringType})`}</Text>
		</div>
	);
};

export default RowChanged;
