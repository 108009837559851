import React, { useRef } from 'react';
import BemClass from '@upsales/components/Utils/bemClass';
import {
	Block,
	OutsideClick,
	Button,
	Icon,
	Card,
	DropDownButton,
	DropDownMenu,
	Text,
	Tooltip
} from '@upsales/components';
import T from 'Components/Helpers/translate';
import './MailReplyButton.scss';
import { MailRecipients } from 'App/resources/Model/Mail';

interface Props {
	replyAll: () => void;
	reply: () => void;
	copy: () => void;
	recipients: MailRecipients;
	disabled?: boolean;
}

const MailReplyButton = ({ replyAll, reply, copy, recipients, disabled = false }: Props) => {
	const classes = new BemClass('MailReplyButton');

	const closeDD = useRef<() => void>();
	const dropdownRef = useRef<HTMLDivElement>();
	const onlyOneRecipient = recipients?.to.length === 1 && (recipients?.cc.length ?? 0) === 0;

	return (
		<Block className={classes.mod({ disabled }).b()}>
			<Tooltip title={T('mailDrawer.replyToEveryone')} disabled={onlyOneRecipient}>
				<Button
					disabled={disabled}
					onClick={e => {
						e.stopPropagation();
						if (onlyOneRecipient) {
							reply();
						} else {
							replyAll();
						}
					}}
				>
					<Icon space="mrs" name={onlyOneRecipient ? 'reply' : 'reply-all'} />
					<Text color="white">{onlyOneRecipient ? T('mailDrawer.reply') : T('mailDrawer.replyAll')}</Text>
				</Button>
			</Tooltip>
			<DropDownMenu
				align="right"
				arrowed={false}
				renderTrigger={(expanded, setExpanded) => (
					<DropDownButton
						onClick={(e: Parameters<typeof setExpanded>[0]) => {
							e.stopPropagation();
							setExpanded(e);
						}}
						disabled={disabled}
						size="sm"
						expanded={expanded}
					/>
				)}
			>
				{setClosed => {
					closeDD.current = () => {
						setClosed();
					};
					return (
						<OutsideClick
							targetRef={() => dropdownRef.current || null}
							outsideClick={closeDD.current}
							iframeListen
						>
							<div ref={(r: HTMLDivElement) => (dropdownRef.current = r)}>
								{!onlyOneRecipient ? (
									<Card
										className={classes.elem('item-card').b()}
										onClick={() => {
											reply();
											setClosed();
										}}
									>
										<Icon space="mrs" name="reply" />
										<Text>{`${T('mailDrawer.reply')} ${
											recipients?.to?.length > 1
												? ''
												: recipients?.to?.[0]?.name?.split(' ')[0] ?? ''
										}`}</Text>
									</Card>
								) : null}
								<Card
									className={classes.elem('item-card').b()}
									onClick={() => {
										copy();
										setClosed();
									}}
								>
									<Icon space="mrs" name="copy" />
									<Text>{T('mailDrawer.copyEmail')}</Text>
								</Card>
							</div>
						</OutsideClick>
					);
				}}
			</DropDownMenu>
		</Block>
	);
};
export default MailReplyButton;
