import { DrawerHeader, Drawer, TableRow, TableColumn, Text, Link, EllipsisTooltip } from '@upsales/components';
import { ListViewDefaultColumn } from 'App/components/ListView/ListViewRenderHelpers';
import AgreementAttributes from 'App/babel/attributes/AgreementAttributes';
import ListView, { ListViewPropsExternal } from 'App/components/ListView';
import { currencyFormat } from 'Components/Filters/Currencies';
import { useTranslation } from 'Components/Helpers/translate';
import { getMetadata } from 'Store/selectors/AppSelectors';
import BemClass from '@upsales/components/Utils/bemClass';
import Agreement from 'App/resources/Model/Agreement';
import RequestBuilder from 'Resources/RequestBuilder';
import openModal from 'App/services/Modal';
import React, { useMemo, useState } from 'react';

import './SubscriptionListDrawer.scss';

const SubscriptionAttributes = {
	info: {
		...AgreementAttributes.description,
		title: 'agreement'
	},
	yearlyValue: {
		...AgreementAttributes.yearlyValue,
		title: 'default.currentARR',
		sortable: false as const
	},
	increasedYearlyValue: {
		...AgreementAttributes.yearlyValue,
		displayKey: ['increasedYearlyValue', 'currency'],
		displayKeyMap: { increasedYearlyValue: 'value' },
		title: 'subscription.indexing.preview.increaseColumn.label',
		field: 'increasedYearlyValue',
		sortable: false as const
	},
	exclude: {
		...AgreementAttributes.description,
		title: ''
	}
};

type ListViewProps = ListViewPropsExternal<Agreement>;

const navigateToAccount = (subscription: Agreement) => {
	openModal('EditSubscription', {
		agreementId: subscription.id,
		agreementGroupId: subscription.agreementGroupId ?? undefined
	});
};

const getRowRenderFn =
	(t: any, excludeSubscription: (subscription: Agreement) => void): ListViewProps['renderTableRow'] =>
	(subscription: Agreement, { columns, attributes }) => {
		const columnElements = columns.map(column => {
			let content = null;
			switch (column) {
				case 'info': {
					content = (
						<div>
							<EllipsisTooltip title={subscription.description}>
								<Link onClick={() => navigateToAccount(subscription)}>{subscription.description}</Link>
							</EllipsisTooltip>
							<Text size="sm">{'ID: ' + subscription.id}</Text>
							<EllipsisTooltip title={subscription.client?.name}>
								<Text size="sm">{subscription.client?.name}</Text>
							</EllipsisTooltip>
						</div>
					);
					break;
				}
				case 'exclude': {
					content = (
						<Link
							onClick={e => {
								e.stopPropagation();
								excludeSubscription(subscription);
							}}
						>
							<Text size="sm">{t('default.exclude')}</Text>
						</Link>
					);
					break;
				}

				default: {
					return (
						<ListViewDefaultColumn<Agreement>
							key={column}
							item={subscription}
							attributes={attributes}
							column={column}
						/>
					);
				}
			}
			return (
				<TableColumn key={column} size="lg">
					{content}
				</TableColumn>
			);
		});

		return (
			<TableRow key={subscription.id} onClick={() => navigateToAccount(subscription)}>
				{columnElements}
			</TableRow>
		);
	};

type Props = {
	excludeSubscription: (subscription: Agreement) => void;
	subscriptions: Agreement[];
	excludeDisabled?: boolean;
	close: (a?: any) => void;
	indexPercentage: number;
	className?: string;
};

const SubscriptionListDrawer = (props: Props) => {
	const { className, excludeDisabled, subscriptions, indexPercentage, excludeSubscription } = props;
	const [subs, setSubs] = useState<Agreement[]>(subscriptions);
	const { t } = useTranslation();

	const classes = new BemClass('SubscriptionListDrawer');
	classes.add(className);

	const metadata = getMetadata();
	const defaultCurrency = metadata?.defaultCurrency?.iso ?? 'SEK';

	const increase = useMemo(() => {
		const totalIncrease =
			(subs?.reduce((acc, sub) => acc + (sub.yearlyValueInMasterCurrency ?? 0), 0) * indexPercentage) / 100;

		return currencyFormat(totalIncrease, defaultCurrency);
	}, [subs, indexPercentage, defaultCurrency]);

	const getData = async (rb: RequestBuilder) => {
		// get limit and offset from rb
		const offset = rb?.offset ?? 0;
		const limit = rb?.limit ?? 50;

		const data = subs?.slice(offset, offset + limit).map(sub => ({
			...sub,
			increasedYearlyValue: Math.round((sub.yearlyValue ?? 0) * (1 + indexPercentage / 100))
		}));

		return {
			data,
			metadata: { total: subs?.length }
		};
	};

	const key = useMemo(() => Math.random(), [subs]);

	const doExclude = (subscription: Agreement) => {
		excludeSubscription(subscription);
		setSubs(subs.filter(sub => sub.id !== subscription.id));
	};

	const columns = ['info', 'yearlyValue', 'increasedYearlyValue'];
	if (!excludeDisabled) {
		columns.push('exclude');
	}

	return (
		<Drawer className={classes.b()}>
			<DrawerHeader
				subtitle={t('subscription.indexing.preview.header.subtitle', {
					value: increase
				})}
				title={t('subscription.indexing.preview.header.title', { count: subs.length })}
				onHide={() => props.close()}
			/>

			<ListView<Agreement>
				noStickyTableHeader
				renderTableRow={getRowRenderFn(t, doExclude)}
				className={classes.elem('list').b()}
				attributes={SubscriptionAttributes}
				renderToolsColumn={false}
				getData={getData}
				columns={columns}
				key={key}
			/>
		</Drawer>
	);
};

export default SubscriptionListDrawer;
