import React, { useState } from 'react';
import { Icon, Text, Card, OutsideClick } from '@upsales/components';
import MailType, { MailRecipient } from 'App/resources/Model/Mail';
import BemClass from '@upsales/components/Utils/bemClass';
import T from 'Components/Helpers/translate';
import SelfRow from './SelfRow';
import UserRow from './UserRow';
import { useSelector } from 'react-redux';
import { RootState } from 'Store/index';

import './RecipientsDropDown.scss';
export type From = { name?: string; email: string; type: string; id?: number };
type Props = { recipients?: MailType['recipients']; from: From };

const RecipientsDropDown = (props: Props) => {
	const { recipients, from } = props;
	const [showDropDown, setShowDropDown] = useState(false);
	const { self } = useSelector(({ App }: RootState) => App);

	const classes = new BemClass('RecipientsDropDown');

	const allRecipients = recipients ? (Object.values(recipients) as any).flat() : [];

	const userRow = (user: From, type: string) => {
		if (self?.id === user.id) {
			return <SelfRow from={user} key={type + user.email} />;
		}
		return <UserRow user={user} key={type + user.email} />;
	};

	const toField = allRecipients
		.map((user: MailRecipient) => user.email)
		.slice(0, 2)
		.join(', ');
	const toFieldHidden = allRecipients.slice(2).length;
	return (
		<div className={classes.b()}>
			<div className={classes.elem('list').b()} onClick={() => setShowDropDown(true)}>
				<Text className="text-ellipsis">
					{`${T('mailDrawer.to')}: `}
					{toField}
				</Text>
				<Text>{toFieldHidden > 0 ? ` +${toFieldHidden}` : null}</Text>
				<Icon name="chevron-down" />
			</div>
			{showDropDown ? (
				<OutsideClick targetClass={classes.elem('dropDown').b()} outsideClick={() => setShowDropDown(false)}>
					<Card className={classes.elem('dropDown').b()}>
						<div className={classes.elem('dropDown').elem('section').b()}>
							<Text className={classes.elem('dropDown').elem('section').elem('type').b()} bold>
								{T('mailDrawer.from')}
								{userRow(from, 'from')}
							</Text>
						</div>
						<div className={classes.elem('dropDown').elem('section').b()}>
							<Text className={classes.elem('dropDown').elem('section').elem('type').b()} bold>
								{T('mailDrawer.to')}
							</Text>
							{recipients?.to.map(user => userRow(user, 'to'))}
						</div>
						{recipients?.cc?.length ?? 0 > 0 ? (
							<div className={classes.elem('dropDown').elem('section').b()}>
								<Text className={classes.elem('dropDown').elem('section').elem('type').b()} bold>
									{T('mailDrawer.copy')}
								</Text>
								{recipients?.cc.map(user => userRow(user, 'cc'))}
							</div>
						) : null}

						{recipients?.bcc?.length ?? 0 > 0 ? (
							<div className={classes.elem('dropDown').elem('section').b()}>
								<Text className={classes.elem('dropDown').elem('section').elem('type').b()} bold>
									{T('mailDrawer.secretCopy')}
								</Text>
								{recipients?.bcc.map(user => userRow(user, 'bcc'))}
							</div>
						) : null}
					</Card>
				</OutsideClick>
			) : null}
		</div>
	);
};

export default RecipientsDropDown;
