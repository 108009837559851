import React from 'react';
import Lottie from 'react-lottie';
import bemClass from '@upsales/components/Utils/bemClass';
import { useAnimationData } from 'App/components/hooks';

const SignalsEmptyResultLottie: React.FC = () => {
	const classes = new bemClass('SignalsEmptyResultLottie');
	const { animationData } = useAnimationData(() => import('./emptyResultLottie.json'));

	const defaultOptions = {
		loop: false,
		autoplay: true,
		animationData,
		rendererSettings: {
			preserveAspectRatio: 'xMidYMid slice'
		}
	};

	return (
		<div className={classes.b()}>
			<Lottie options={defaultOptions} height={300} width={300} isClickToPauseDisabled={true} />
		</div>
	);
};

export default SignalsEmptyResultLottie;
