import BemClass from '@upsales/components/Utils/bemClass';
import React, { useState } from 'react';
import { Table, TableHeader } from '@upsales/components';
import T from 'Components/Helpers/translate';
import RowDropDown from './RowDropDown';
import './ForecastingTable.scss';
import OpportunityRow from './OpportunityRow';
import moment from 'moment';
import { ForecastTableRow, ForecastTableDrilldownRow } from 'Resources/ReportWidget';

type Props = {
	tableRows: ForecastTableRow[];
	sorting: { field: string; asc: boolean };
	excluded: number[];
	setExcluded: (ids: number[]) => void;
	onSortChange: (newSorting: { field: string; asc: boolean }) => void;
	isSingleUser: boolean;
	currency: string;
	hasTarget: boolean;
};

const ForecastingTable = ({
	tableRows,
	sorting,
	onSortChange,
	excluded,
	setExcluded,
	isSingleUser,
	currency,
	hasTarget
}: Props) => {
	const classes = new BemClass('ForecastingTable');
	const [singleSorting, setSingleSorting] = useState<{ field: string; asc: boolean }>({
		field: 'timeInPipeline',
		asc: false
	});

	let sortedOpp = [] as ForecastTableDrilldownRow[];
	if (isSingleUser) {
		sortedOpp = tableRows[0].drilldown.sort((a: ForecastTableDrilldownRow, b: ForecastTableDrilldownRow) => {
			if (singleSorting.field === 'timeInPipeline') {
				return a.timeInPipeline.value > b.timeInPipeline.value ? 1 : -1;
			} else if (singleSorting.field === 'value') {
				return a.value > b.value ? 1 : -1;
			} else if (singleSorting.field === 'date') {
				return moment(a.date).isBefore(moment(b.date)) ? -1 : 1;
			} else if (singleSorting.field === 'stage') {
				return a.stage.name.localeCompare(b.stage.name);
			} else if (singleSorting.field === 'client') {
				return a.client.name.localeCompare(b.client.name);
			} else {
				return -1;
			}
		});
		if (!singleSorting.asc) {
			sortedOpp = sortedOpp.reverse();
		}
	}
	let columns = [];
	if (isSingleUser) {
		columns = [
			{ title: T('default.account'), sort: 'client', sortType: 'alpha' },
			{ title: T('default.value'), sort: 'value', sortType: 'numeric' },
			{ title: T('default.stage'), sort: 'stage', sortType: 'alpha' },
			{ title: T('default.closeDate2'), sort: 'date', sortType: 'alpha' },
			{ title: T('forecasting.daysInPipeline'), sort: 'timeInPipeline', sortType: 'numeric' }
		];
	} else {
		columns = [
			{ title: T('default.salesRep') },
			{ title: T('default.sales'), sort: 'sales', sortType: 'numeric' },
			{ title: hasTarget ? T('goals.leftToGoal') : '', sort: hasTarget ? 'remaining' : '', sortType: 'numeric' },
			{ title: T('report.totalPipeline'), sort: 'pipeline', sortType: 'numeric' },
			{ title: hasTarget ? T('forecasting.enoughPipeline.title') : '' },
			{ title: '' }
		];
	}

	return (
		<div className={classes.b()}>
			{isSingleUser ? (
				<Table>
					<TableHeader
						asc={singleSorting.asc}
						sorting={singleSorting.field}
						onSortChange={sorting => setSingleSorting(sorting)}
						columns={columns}
					/>
					{sortedOpp.map(row => (
						<OpportunityRow
							key={row.id}
							excluded={excluded}
							setExcluded={setExcluded}
							row={row}
							isDropDown={false}
							currency={currency}
						/>
					))}
				</Table>
			) : (
				<Table>
					<TableHeader
						columns={columns}
						asc={sorting.asc}
						sorting={sorting.field}
						onSortChange={onSortChange}
					/>
					{tableRows.map(row => (
						<RowDropDown
							key={row.user.id}
							{...row}
							excluded={excluded}
							setExcluded={setExcluded}
							currency={currency}
						/>
					))}
				</Table>
			)}
		</div>
	);
};

export default ForecastingTable;
