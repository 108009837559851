import Automations from 'Resources/Automations';
import RequestBuilder from 'Resources/RequestBuilder';
import { removeItem } from '../helpers/array';

const SET_LOADING = '[JourneyStatuses] Set Loading';
const SET_AUTOMATIONS = '[JourneyStatuses] Set Automations';
const SET_SYNC_STATUS = '[JourneyStatuses] Set Sync Status';
const SET_SYNC_STATUS_LOADING = '[JourneyStatuses] Set Sync Status Loading';

const initialState = {
	loading: true,
	automations: {
		mql: []
	},
	syncStatus: false,
	syncStatusLoading: false
};

/*********** Actions **********/
const setLoading = loading => ({ type: SET_LOADING, loading });
const setAutomations = data => ({ type: SET_AUTOMATIONS, data });
const setSyncStatusLoading = syncStatusLoading => ({ type: SET_SYNC_STATUS_LOADING, syncStatusLoading });

const getAutomations = () => dispatch => {
	dispatch(setLoading(true));

	const filters = new RequestBuilder();
	filters.addFilter({ field: 'journeyType' }, RequestBuilder.comparisonTypes.NotEquals, null);

	return Automations.find(filters.build())
		.then(res => {
			const data = res.data.reduce((res, a) => {
				if (!res[a.journeyType]) {
					res[a.journeyType] = [];
				}
				res[a.journeyType].push(a);
				return res;
			}, {});
			dispatch(setAutomations(data));
			dispatch(setLoading(false));
		})
		.catch(err => {
			console.log(err);
			dispatch(setLoading(false));
		});
};

export const init = () => dispatch => {
	// Get client param for syncStatus
	const syncStatus = Tools.AppService.getMetadata().params.SyncJourneyStatus;
	dispatch({ type: SET_SYNC_STATUS, syncStatus });

	// Get automations
	return dispatch(getAutomations());
};

export const setSyncStatus = syncStatus => dispatch => {
	dispatch(setSyncStatusLoading(true));

	return Tools.ClientParam.save(221, syncStatus ? '1' : '0')
		.then(() => {
			dispatch({ type: SET_SYNC_STATUS, syncStatus });
			const metadata = Tools.AppService.getMetadata();
			metadata.params.SyncJourneyStatus = syncStatus;
			Tools.AppService.setMetadata(metadata);
			dispatch(setSyncStatusLoading(false));
		})
		.catch(() => {
			dispatch(setSyncStatusLoading(false));
		});
};

export const removeAutomation = automation => (dispatch, getState) => {
	return Automations.delete(automation.id).then(() => {
		const { automations } = getState().JourneyStatuses;
		const type = automation.journeyType;
		const i = (automations[type] || []).findIndex(a => a.id === automation.id);
		if (i === -1) {
			return;
		}
		const newAutomations = removeItem(automations[type], i);
		dispatch(setAutomations({ ...automations, [type]: newAutomations }));
	});
};

//** For testing **//
export const _actions = {
	setLoading,
	setAutomations,
	getAutomations,
	setSyncStatusLoading
};
export const _initialState = initialState;
//**             **//

const ACTION_HANDLERS = {
	[SET_LOADING]: (state, action) => ({ ...state, loading: action.loading }),
	[SET_AUTOMATIONS]: (state, action) => ({ ...state, automations: action.data }),
	[SET_SYNC_STATUS]: (state, { syncStatus }) => ({ ...state, syncStatus }),
	[SET_SYNC_STATUS_LOADING]: (state, { syncStatusLoading }) => ({ ...state, syncStatusLoading })
};

export default (state = initialState, action) => {
	const handler = ACTION_HANDLERS[action.type];
	return handler ? handler(state, action) : state;
};
