import React, { useEffect, useState } from 'react';
import { DateInput, Button, Icon, Text, TimeInput, Label, Tooltip } from '@upsales/components';
import BemClass from '@upsales/components/Utils/bemClass';
import './OpportunityTodosTimepicker.scss';
import T from 'Components/Helpers/translate';
import moment from 'moment-timezone';

type Props = {
	onChange: (e: { date: Date | null; time: string | null }) => void;
	onClear: () => void;
	date: Date;
	autofocus: boolean;
	time: string;
	labels?: boolean;
};

const OpportunityTodosTimepicker = ({ onChange, onClear, date, time, autofocus, labels }: Props) => {
	const [showInput, setShowInput] = useState(!!(date || time));

	//Closes the date picker after submitting a todo
	useEffect(() => {
		if (showInput && !(date || time)) {
			setShowInput(false);
		}
	}, [date, time]);

	const timeChange = (e: { target: { value: Date } }) => {
		const newTime = e.target.value;

		const newDate = date ? moment(date).toDate() : moment().toDate();

		if (newTime) {
			newDate.setHours(newTime.getHours(), newTime.getMinutes(), 0, 0);
		} else {
			newDate.setHours(0, 0, 0, 0);
		}

		onChange({ date: newTime || date ? newDate : null, time: newTime ? moment(newTime).format('HH:mm') : null });
	};

	const dateChange = (e: { target: { value: Date } }) => {
		const newDateInput = e.target.value;

		if (!newDateInput) {
			return onChange({ date: null, time: null });
		}

		const newDate = moment(newDateInput).toDate();

		if (date && time) {
			const oldDate = moment(date).toDate();
			newDate.setHours(oldDate.getHours(), oldDate.getMinutes(), 0, 0);
		}

		onChange({ date: newDate, time: time ? time : null });
	};

	const suggestedTime = moment()
		.add(30 - (moment().minute() % 30), 'minutes')
		.format('HH:mm');
	const classes = new BemClass('OpportunityTodosTimepicker');
	if (showInput) {
		return (
			<div className={classes.mod('active').b()}>
				<div className={classes.elem('content').mod({ labels }).b()}>
					{labels ? (
						<Label>
							<Text>{T('default.dateAndTime')}</Text>
						</Label>
					) : null}

					<div className={classes.elem('content-input').mod({ labels }).b()}>
						<DateInput
							closeOnSelect={true}
							value={date}
							placeholder={!labels ? T('default.date') : undefined}
							onChange={dateChange}
							autofocus={autofocus}
						/>

						<TimeInput
							placeholder={!labels ? T('default.time') : undefined}
							value={time ? date : undefined}
							defaultSelected={suggestedTime}
							asDate={true}
							onChange={timeChange}
						/>
					</div>
				</div>

				<Tooltip title={T('todo.opportunity.removeDate')} position="top" distance={24}>
					<Button
						id="close-btn"
						type="link"
						color="grey"
						size="sm"
						onClick={() => {
							onClear();
							setShowInput(false);
						}}
					>
						<Icon name="calendar-times-o" />
					</Button>
				</Tooltip>
			</div>
		);
	} else {
		return (
			<div className={classes.b()}>
				<Tooltip title={T('todo.opportunity.addDueDate')} position="top" distance={24}>
					<Button
						id="trigger"
						className={classes.elem('trigger').b()}
						onClick={() => setShowInput(true)}
						size="sm"
						type="link"
						color="grey"
					>
						<Icon name="calendar" />
					</Button>
				</Tooltip>
			</div>
		);
	}
};
export default OpportunityTodosTimepicker;
