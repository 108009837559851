import React from 'react';
import { Input } from '@upsales/components';

class FocusableInput extends React.Component {
	componentDidMount() {
		this.input.focus();
	}

	render() {
		return <Input inputRef={input => (this.input = input)} {...this.props} />;
	}
}

export default FocusableInput;
