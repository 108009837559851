import Resource from './Resource';
import NotificationService from 'App/babel/NotificationService';

class SamlAuth extends Resource {
	constructor() {
		super('samlAuth');

		this.notifications = {
			save: () => {
				return {
					title: 'admin.singleSignOn',
					body: 'default.saved',
					style: NotificationService.style.SUCCESS,
					icon: 'check'
				};
			},
			saveError: () => {
				return {
					title: 'admin.singleSignOn',
					body: 'default.unableToSave',
					style: NotificationService.style.ERROR,
					icon: 'save',
					type: NotificationService.type.Body
				};
			}
		};
	}

	new() {
		return {
			certificate: '',
			loginUrl: '',
			logoutUrl: ''
		};
	}

	async get() {
		try {
			const { data } = await this._getRequest(1);
			if (!data) {
				return this.new();
			}
			return data.data;
		} catch (e) {
			return this.new();
		}
	}
}

const resource = new SamlAuth();

window.Tools.SamlAuth = resource;

export default resource;
