import BemClass from '@upsales/components/Utils/bemClass';
import React from 'react';
import { Text, Block, Icon, Title, Tooltip, IconName } from '@upsales/components';
import './DataBox.scss';

export type SectionData = {
	title: string;
	valueColor?: 'black' | 'blue' | 'dark-red' | 'success-6' | 'yellow-5';
	subtitle?: string;
	subtitleColor?: React.ComponentProps<typeof Text>['color'];
	value: string | number;
	icon?: IconName;
	elevioId?: number;
	className?: string;
	children?: JSX.Element;
	onSubtitleClick?: () => void;
	onSubtitleHover?: () => void;
	tooltip?: string;
	helpText?: string;
};

type Props = SectionData;

const DataBox = ({
	title,
	subtitle,
	value,
	icon,
	valueColor = 'black',
	subtitleColor = 'black',
	className,
	children,
	onSubtitleClick,
	onSubtitleHover,
	tooltip,
	helpText
}: Props) => {
	const classes = new BemClass('DataBox', className);
	return (
		<Block className={classes.b()}>
			<div className={classes.elem('title-wrapper').b()}>
				<Text size="md" bold={true}>
					{title}
				</Text>
				{helpText ? (
					<Tooltip title={helpText}>
						<Icon name="question-circle" color={valueColor} />
					</Tooltip>
				) : null}
			</div>
			<div className={classes.elem('value-wrapper').b()}>
				{icon ? <Icon name={icon} color={valueColor} /> : null}
				<Title color={valueColor} size="lg">
					{value}
				</Title>
			</div>
			{children}
			{subtitle ? (
				<Text
					color={subtitleColor}
					size="sm"
					onClick={onSubtitleClick}
					className={classes.elem('subtitle').b()}
					onMouseOver={onSubtitleHover}
				>
					{subtitle} {tooltip}
				</Text>
			) : (
				<Text />
			)}
		</Block>
	);
};

export default DataBox;
