import React from 'react';
import bemClass from '@upsales/components/Utils/bemClass';
import { Icon, Tooltip } from '@upsales/components';
import './ReportcenterWidgetInfo.scss';

type Props = {
	text: string;
};

const ReportcenterWidgetInfo = ({ text }: Props) => {
	const classes = new bemClass('ReportcenterWidgetInfo');

	return (
		<div className={classes.b()}>
			<Tooltip title={text}>
				<Icon name="info-circle" color="grey-10" />
			</Tooltip>
		</div>
	);
};

export default ReportcenterWidgetInfo;
