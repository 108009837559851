import { type MinimalProduct } from 'App/resources/Model/Product';
import type Product from 'App/resources/Model/Product';
import type ProductCategory from 'App/resources/Model/ProductCategory';

export const mapProductToMinimalProduct = (product: Product): MinimalProduct => ({
	id: product.id,
	name: product.name,
	active: product.active
});

export const buildArray = (
	selected: readonly MinimalProduct[],
	all: readonly MinimalProduct[],
	searchStr: string
): MinimalProduct[] => {
	const selectedOnSearch = selected.filter(item => item.name?.toLowerCase().indexOf(searchStr?.toLowerCase()) !== -1);
	const notSelected = all.filter(p => !selected.some(sp => sp.id === p.id));
	return searchStr ? [...selectedOnSearch, ...notSelected] : [...selected, ...notSelected];
};

export const buildCategoryArray = (
	selected: readonly ProductCategory[],
	all: readonly ProductCategory[],
	searchStr: string
): ProductCategory[] => {
	const selectedOnSearch = selected.filter(item => item.name?.toLowerCase().indexOf(searchStr?.toLowerCase()) !== -1);
	const notSelected = all.filter(p => !selected.some(sp => sp.id === p.id));
	return searchStr ? [...selectedOnSearch, ...notSelected] : [...selected, ...notSelected];
};
