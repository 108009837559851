import { Row, Text, Title, Column, Block, Tooltip } from '@upsales/components';
import { makeCancelable } from '@upsales/components/Utils/CancelablePromise';
import RequestBuilder, { comparisonTypes } from 'Resources/RequestBuilder';
import { useTranslation } from 'Components/Helpers/translate';
import React, { useEffect, useRef, useState } from 'react';
import BemClass from '@upsales/components/Utils/bemClass';
import { CancelablePromise } from 'Helpers/promise';
import TicketResource from 'App/resources/Ticket';
import { openDrawer } from 'Services/Drawer';
import logError from 'Helpers/logError';
import BorderBox from '../BorderBox';
import './TicketWidget.scss';

interface Props {
	clientId: number;
}

const TicketWidget = ({ clientId }: Props) => {
	const getClosedPromise = useRef<CancelablePromise<Awaited<ReturnType<typeof TicketResource.find>>> | null>(null);
	const getOpenPromise = useRef<CancelablePromise<Awaited<ReturnType<typeof TicketResource.find>>> | null>(null);

	const [closedTickets, setClosedTickets] = useState<number>();
	const [openTickets, setOpenTickets] = useState<number>();

	const getRb = (closed: boolean) => {
		const rb = new RequestBuilder();

		rb.addFilter({ field: 'status.closed' }, comparisonTypes.Equals, closed);
		rb.addFilter({ field: 'client.id' }, comparisonTypes.Equals, clientId);
		rb.addFilter({ field: 'isArchived' }, comparisonTypes.Equals, false);
		rb.limit = 0;
		return rb;
	};

	useEffect(() => {
		const getData = () => {
			getClosedPromise.current = makeCancelable(TicketResource.find(getRb(true).build()));
			getClosedPromise.current.promise
				.then(res => {
					setClosedTickets(res.metadata.total);
				})
				.catch(e => logError(e));

			getOpenPromise.current = makeCancelable(TicketResource.find(getRb(false).build()));
			getOpenPromise.current.promise
				.then(res => {
					setOpenTickets(res.metadata.total);
				})
				.catch(e => logError(e));
		};

		getData();

		const listeners = Tools.$rootScope.$on('ticket.statusChanged', getData);

		return () => {
			getClosedPromise.current?.cancel();
			getOpenPromise.current?.cancel();
			listeners();
		};
	}, [clientId]);

	const { t } = useTranslation();

	const classes = new BemClass('TicketWidget');

	return (
		<BorderBox boxShadow space="ptl" className={classes.b()}>
			<Block className={classes.elem('unsetblock').b()}>
				<Title color="black">{t('ticket.widget.title')}</Title>
				<Row>
					<Column
						className={classes.elem('widgetColumn').b()}
						onClick={() => {
							if (openTickets === 0) return;
							openDrawer('ViewTickets', { openStatus: true, clientId });
						}}
					>
						<Tooltip
							disabled={!openTickets}
							position="bottom"
							title={
								openTickets === 1
									? t('ticket.widget.tooltipOpenSingular')
									: t('ticket.widget.tooltipOpenPlural', { amount: openTickets })
							}
						>
							<Text size="xl">{openTickets}</Text>
							<Text space="mbl " color="grey-10">
								{t('default.open')}
							</Text>
						</Tooltip>
					</Column>
					<Column
						className={classes.elem('widgetColumn').b()}
						onClick={() => {
							if (closedTickets === 0) return;
							openDrawer('ViewTickets', { openStatus: false, clientId });
						}}
					>
						<Tooltip
							disabled={!closedTickets}
							position="bottom"
							title={
								closedTickets === 1
									? t('ticket.widget.tooltipClosedSingular')
									: t('ticket.widget.tooltipClosedPlural', { amount: closedTickets })
							}
						>
							<Text size="xl">{closedTickets}</Text>
							<Text space="mbl" color="grey-10">
								{' '}
								{t('default.closed')}
							</Text>
						</Tooltip>
					</Column>
				</Row>
			</Block>
		</BorderBox>
	);
};

export default TicketWidget;
