import React from 'react';
import { Modal, ModalContent, ModalHeader, ModalControls, Column, Link, Button, Flex } from '@upsales/components';
import { useTranslation } from 'Components/Helpers/translate';
import { ModalProps } from 'App/components/Modals/Modals';

import Client from 'App/resources/Model/Client';

const ClientListModal: React.FC<ModalProps & { clients: Pick<Client, 'id' | 'name'>[] }> = ({
	clients,
	close,
	className
}) => {
	const { t } = useTranslation();
	const sortedClients = [...clients].sort((a, b) => a.name.localeCompare(b.name));
	return (
		<Modal size="sm" className={className}>
			<ModalHeader title={t('default.clients')} />
			<ModalContent>
				<Flex direction="column">
					{sortedClients.map(client => (
						<Link
							key={client.id}
							href={'#/' + Tools.AppService.getCustomerId() + '/accounts/' + client.id + '/'}
							onClick={() => close()}
						>
							{client.name}
						</Link>
					))}
				</Flex>
			</ModalContent>
			<ModalControls>
				<Column align="right">
					<Button color="grey" type="link" onClick={() => close()}>
						{t('default.cancel')}
					</Button>
				</Column>
			</ModalControls>
		</Modal>
	);
};

export default ClientListModal;
