import IntegrationHelper from 'App/helpers/IntegrationHelper';

const RESET = '[ConfigIntegration] RESET';
const SET_INITIALIZING = '[ConfigIntegration] SET_INITIALIZING';
const CONFIG_CHANGED = '[ConfigIntegration] CONFIG_CHANGED';
const FIELDS_CHANGED = '[ConfigIntegration] FIELDS_CHANGED';
const SET_APP_CONFIG = '[ConfigIntegration] SET_APP_CONFIG';
const SET_OAUTH = '[ConfigIntegration] SET_OAUTH';
const SET_INTEGRATION = '[ConfigIntegration] SET_INTEGRATION';
const SET_CONTRACT_ERR = '[ConfigIntegration] SET_CONTRACT_ERR';
const SET_SETTINGS_ERR = '[ConfigIntegration] SET_SETTINGS_ERR';
const SET_CONTRACT_ACCEPTED = '[ConfigIntegration] SET_CONTRACT_ACCEPTED';
const SET_SAVING_CONTRACT = '[ConfigIntegration] SET_SAVING_CONTRACT';
const SET_SAVING_CONFIG = '[ConfigIntegration] SET_SAVING_CONFIG';
const SET_ACTIVE = '[ConfigIntegration] SET_ACTIVE';

const TYPES = {
	ACCOUNT: 'account',
	USER: 'user'
};

export function getAndSaveOauthToken(auth) {
	return async dispatch => {
		await dispatch(getOauthToken(auth.code));
		await dispatch(saveConfig());
	};
}

export function getOauthToken(code) {
	return async (dispatch, getState) => {
		dispatch({ type: SET_OAUTH, data: { gettingOauth: true } });

		const { $oauthCallbackPath, $oauthState, integration } = getState().ConfigIntegration;
		const customerId = Tools.AppService.getCustomerId();
		const opts = {
			callbackPath: $oauthCallbackPath.split('?')[0],
			integrationId: integration.id,
			code: code,
			state: $oauthState
		};

		try {
			const response = await Tools.StandardIntegration.data(customerId).oauth(opts);
			const data = { gettingOauth: false };

			if (response.data && response.data.error) {
				data.oauthErr = response.data.error;
				dispatch({ type: SET_OAUTH, data });
			} else {
				const { fields } = getState().ConfigIntegration;
				const oauthField = _.find(fields, { type: 'oauth' }) || _.find(fields, { type: 'oauth2' });

				dispatch({ type: SET_OAUTH, data });
				dispatch(configChanged(oauthField.name, response.data, true));
			}
		} catch (error) {
			dispatch({ type: SET_OAUTH, data: { gettingOauth: false, gettingOauthErr: error } });
		}
	};
}

function getBaseOauthEndpoint(endpoint) {
	return endpoint.indexOf('?') > -1 ? `${endpoint}&` : `${endpoint}/?`;
}

export const onRequestOauth = state => (dispatch, getState) => {
	const { $oauthCallbackPath, fields, $oauthState } = getState().ConfigIntegration;
	const oauthField = _.find(fields, { type: 'oauth' });

	if (state) {
		const stringifiedState = JSON.stringify(state);
		document.cookie = `oauthRedirectState=${stringifiedState};expires=${moment()
			.add(5, 'm')
			.toDate()
			.toGMTString()};path=/`;
	}

	const endpoint = getBaseOauthEndpoint(oauthField.endpoint);
	window.location = `${endpoint}state=${$oauthState}&callbackPath=${$oauthCallbackPath}`;
};

export const onRequestOauth2 = state => (dispatch, getState) => {
	const { integration, $oauthState, fields } = getState().ConfigIntegration;
	const oauthField = _.find(fields, { type: 'oauth2' });
	dispatch({ type: SET_OAUTH, data: { gettingOauth: true } });

	if (state) {
		const stringifiedState = JSON.stringify(state);
		document.cookie = `oauthRedirectState=${stringifiedState};expires=${moment()
			.add(30, 'm')
			.toDate()
			.toGMTString()};path=/`;
	}

	const endpoint = getBaseOauthEndpoint(oauthField.endpoint);
	window.location = `${endpoint}state=${$oauthState}-${integration.id}&client_id=${oauthField.clientId}&response_type=code&redirect_uri=${oauthField.callbackUri}`;
};

export const getStandardIntegrationSettings = () => {
	return async (dispatch, getState) => {
		const { integration, type } = getState().ConfigIntegration;
		const customerId = Tools.AppService.getCustomerId();
		const method = type === TYPES.USER ? 'userSetting' : 'setting';
		const {
			data: [currentSettings]
		} = await Tools.StandardIntegration[method](customerId).find({ integration_id: integration.id });
		if (currentSettings) {
			dispatch({ type: SET_ACTIVE, data: { active: currentSettings.active } });
			const parsedConfig = parseConfig(currentSettings.configJson);
			for (const [key, value] of Object.entries(parsedConfig)) {
				dispatch(configChanged(key, value));
			}
		}
	};
};

export function init(integration) {
	return async dispatch => {
		dispatch({ type: RESET });

		if (!integration) {
			return;
		}

		dispatch({ type: SET_INITIALIZING, data: { initializing: true } });

		const data = {
			integration,
			$oauthState: Math.floor(Math.random() * 1000000000),
			$oauthCallbackPath: encodeURI(window.location.hash.replace('#', ''))
		};

		dispatch({ type: SET_INTEGRATION, data });
		const config = await getConfig(integration);
		dispatch({ type: SET_APP_CONFIG, data: config });
		dispatch(initConfig());
		await dispatch(getStandardIntegrationSettings());
		dispatch({ type: SET_INITIALIZING, data: { initializing: false } });
	};
}

export function hasConfigTest() {
	return (dispatch, getState) => {
		const { integration, type, fields } = getState().ConfigIntegration;
		return integration.config[type === TYPES.USER ? 'userTest' : 'test'] && fields.length ? true : false;
	};
}

export function testConfig() {
	return async (dispatch, getState) => {
		const { integration, type, active, config } = getState().ConfigIntegration;
		const customerId = Tools.AppService.getCustomerId();
		const method = type === TYPES.USER ? 'userSetting' : 'setting';
		const settings = {
			active,
			configJson: JSON.stringify(config),
			id: integration.id,
			integrationId: integration.id
		};
		return Tools.StandardIntegration[method](customerId).testConfig(settings, {
			masterIntegration: integration,
			skipNotification: true
		});
	};
}

export function saveConfig(skipTestConfig = false) {
	return async (dispatch, getState) => {
		const { integration, type, active, config } = getState().ConfigIntegration;
		const customerId = Tools.AppService.getCustomerId();
		const method = type === TYPES.USER ? 'userSetting' : 'setting';
		const shouldTestConfig = !skipTestConfig && dispatch(hasConfigTest());
		const settings = {
			active,
			configJson: JSON.stringify(config),
			id: integration.id,
			integrationId: integration.id
		};

		try {
			dispatch({ type: SET_SAVING_CONFIG, data: { savingConfig: true } });
			if (shouldTestConfig) {
				await dispatch(testConfig());
			}
			await Tools.StandardIntegration[method](customerId).save(settings, {
				masterIntegration: integration,
				skipNotification: true
			});
			dispatch({ type: SET_SAVING_CONFIG, data: { savingConfig: false } });
			dispatch({ type: SET_SETTINGS_ERR, data: { settingsErr: null } });
		} catch (error) {
			console.error(error);
			dispatch({ type: SET_SETTINGS_ERR, data: { settingsErr: error } });
			dispatch({ type: SET_SAVING_CONFIG, data: { savingConfig: false } });
		}
	};
}

export const setActive = active => {
	return { type: SET_ACTIVE, data: { active } };
};

export const deActivate = () => {
	return async dispatch => {
		dispatch(setActive(false));
		await dispatch(saveConfig(true));
	};
};

export const activate = () => {
	return async dispatch => {
		dispatch(setActive(true));
		await dispatch(saveConfig(true));
	};
};

export const acceptContract = () => {
	return async (dispatch, getState) => {
		const { integration, type } = getState().ConfigIntegration;
		const customerId = Tools.AppService.getCustomerId();

		const contract = {
			contractId: integration.id
		};

		if (type === TYPES.USER) {
			contract.contractId = integration.userContract.id;
		}

		try {
			dispatch({ type: SET_SAVING_CONTRACT, data: { savingContract: true } });
			await Tools.Contract.accepted(customerId).save(contract, { skipNotification: true });
			dispatch({ type: SET_CONTRACT_ACCEPTED, data: { contractAccepted: true } });
			dispatch({ type: SET_SAVING_CONTRACT, data: { savingContract: false } });
		} catch (error) {
			console.error(error);
			return dispatch({ type: SET_CONTRACT_ERR, data: { contractErr: error, savingContract: false } });
		}
	};
};

export function initFromId(integrationId) {
	return async dispatch => {
		const { data: integration } = await Tools.StandardIntegration.get(integrationId);
		await dispatch(init(integration));
	};
}

export function initConfig() {
	return async (dispatch, getState) => {
		const { integration, type } = getState().ConfigIntegration;
		const config = integration.config;

		const newFields = _.cloneDeep(config.fields[type]);
		const newConfig = getInitialConfig(newFields);

		const checkedFields = newFields.map(field => IntegrationHelper.checkField(field, newFields, newConfig));

		dispatch({ type: FIELDS_CHANGED, data: { fields: checkedFields } });
		dispatch({ type: CONFIG_CHANGED, data: { config: newConfig } });

		for (const field of checkedFields) {
			dispatch(handleField(field, true));
		}
	};
}

function parseConfig(configJson) {
	try {
		return JSON.parse(configJson);
	} catch (error) {
		return {};
	}
}

function getConfig(integration) {
	if (integration.externalConfig) {
		const customerId = Tools.AppService.getCustomerId();
		return Tools.StandardIntegration.setting(customerId).getExternalConfig(integration.id);
	} else {
		return parseConfig(integration.configJson);
	}
}

function getInitialConfig(fields) {
	return fields.reduce((res, field) => {
		const value = IntegrationHelper.getFieldInitValue(field, null);
		res[field.name] = value === null && field.type === 'multiselect' ? [] : value;
		return res;
	}, {});
}

const partialUpdateField = (fieldName, partialField) => (dispatch, getState) => {
	const oldImportFields = getState().ConfigIntegration.fields;
	const fields = [...oldImportFields];
	const fieldIndex = _.findIndex(fields, { name: fieldName });

	if (fieldIndex > -1) {
		const oldField = fields[fieldIndex];
		const updatedField = Object.assign({}, oldField, partialField);
		fields[fieldIndex] = updatedField;
		dispatch({ type: FIELDS_CHANGED, data: { fields } });

		return updatedField;
	} else {
		return partialField; // who not, should never happen
	}
};

const updateField = field => (dispatch, getState) => {
	const oldImportFields = getState().ConfigIntegration.fields;
	const fields = [...oldImportFields];
	const index = _.findIndex(fields, { name: field.name });
	fields[index] = field;

	dispatch({ type: FIELDS_CHANGED, data: { fields } });

	return field;
};

const checkField = field => (dispatch, getState) => {
	const { fields, config } = getState().ConfigIntegration;

	const checkedField = IntegrationHelper.checkField(field, fields, config);

	return dispatch(updateField(checkedField));
};

const validateField = field => async (dispatch, getState) => {
	dispatch(partialUpdateField(field.name, { $testing: true }));

	const { integration, config } = getState().ConfigIntegration;

	const method = integration.config.runWithAppData ? 'validateFieldData' : 'validateField';
	const testResult = await IntegrationHelper[method](field.name, config, integration.id);

	return dispatch(partialUpdateField(field.name, { $testing: false, $tested: testResult }));
};

export const getFieldOptions = field => async (dispatch, getState) => {
	const gettingField = dispatch(partialUpdateField(field.name, { $gettingValues: true }));

	const { integration, fields, config } = getState().ConfigIntegration;

	const method = integration.config.runWithAppData ? 'getFieldValuesData' : 'getFieldValues';
	const valueField = await IntegrationHelper[method](gettingField, fields, integration.id, config);

	return valueField.$gettingValuesErr
		? dispatch(
				partialUpdateField(field.name, {
					gettingValuesErr: valueField.$gettingValuesErr,
					$gettingValues: false
				})
		  )
		: dispatch(partialUpdateField(field.name, { values: valueField.values, $gettingValues: false }));
};

export function isConfigValid() {
	return (dispatch, getState) => {
		const { fields, config } = getState().ConfigIntegration;

		const disabled = fields.reduce((disabled, field) => {
			if (disabled) {
				return true;
			}

			if (field.externalValidation) {
				return !(field.$tested && field.$tested.valid);
			} else if (field.required) {
				const value = config[field.name];
				return !value || (Array.isArray(value) && !value.length);
			}
			return false;
		}, false);
		return !disabled;
	};
}

export function configChanged(fieldName, fieldValue, checkDependencies) {
	return async (dispatch, getState) => {
		const { config: oldImportConfig, fields } = getState().ConfigIntegration;

		const config = { ...oldImportConfig, [fieldName]: fieldValue };
		const field = _.find(fields, { name: fieldName });

		dispatch({ type: CONFIG_CHANGED, data: { config } });

		if (checkDependencies) {
			if (field.externalValidation) {
				const validatedField = await dispatch(validateField(field));

				if (!validatedField.$tested.valid) {
					return validatedField;
				}
			}

			const { fields } = getState().ConfigIntegration;
			const dependantFields = fields.filter(otherField => isDependend(otherField, field));

			for (const dependantField of dependantFields) {
				dispatch(handleField(dependantField, false));
			}
		}
	};
}

function isDependend(fieldA, fieldB) {
	return (fieldA.dependencies || []).indexOf(fieldB.name) > -1 && fieldA !== fieldB;
}

function handleField(field, skipCheck) {
	return async (dispatch, getState) => {
		const checkedField = skipCheck ? field : dispatch(checkField(field));

		if (
			!checkedField.$gettingValues &&
			checkedField.visible &&
			checkedField.externalValues &&
			!checkedField.values
		) {
			dispatch(getFieldOptions(checkedField));
		}

		const { config } = getState().ConfigIntegration;

		const configValue = config[field.value];
		const haveValue = configValue && !(Array.isArray(configValue) && !configValue.length);

		if (haveValue) {
			if (field.externalValidation) {
				const validatedField = await dispatch(validateField(checkedField));

				if (!validatedField.$tested.valid) {
					return validatedField;
				}
			}
			const { fields } = getState().ConfigIntegration;
			const dependantFields = fields.filter(otherField => isDependend(otherField, checkedField));

			for (const dependantField of dependantFields) {
				dispatch(handleField(dependantField, false));
			}
		}
	};
}

export const initialState = {
	integration: null,
	type: TYPES.USER,
	config: {},
	fields: [],
	active: false,
	initializing: false,
	contractAccepted: false,

	gettingSettings: true,
	gettingOauth: false,

	savingConfig: false,
	savingContract: false,

	oauthErr: null,
	gettingOauthErr: null,
	contractErr: null,
	settingsErr: null,

	oauth: null,
	$oauthCallbackPath: '',
	$oauthState: ''
};

export default (state = initialState, action) => {
	switch (action.type) {
		case RESET:
			return initialState;
		case CONFIG_CHANGED:
		case FIELDS_CHANGED:
		case SET_OAUTH:
		case SET_INTEGRATION:
		case SET_SETTINGS_ERR:
		case SET_CONTRACT_ERR:
		case SET_SAVING_CONTRACT:
		case SET_CONTRACT_ACCEPTED:
		case SET_SAVING_CONFIG:
		case SET_ACTIVE:
		case SET_INITIALIZING:
			return { ...state, ...action.data };
		case SET_APP_CONFIG:
			return { ...state, gettingSettings: false, integration: { ...state.integration, config: action.data } };
		default:
			return state;
	}
};
