import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import T from 'Components/Helpers/translate';
import { Button, Column, Icon, Row, Text, OutsideClick } from '@upsales/components';
import BemClass from '@upsales/components/Utils/bemClass';
import './FocusModeTopList.scss';
import UpSelect from 'Components/Inputs/UpSelect';
import { getTopListData, setSelectedRolesActivity, setSelectedRolesAppointment } from 'Store/actions/TodoActions';
import ReduxListeners from 'Store/ReduxListeners';
import FocusModeTopListUserRows from './FocusModeTopListUserRows';

const mapDispatchToProps = {
	getTopListData,
	setSelectedRolesActivity,
	setSelectedRolesAppointment
};

const mapStateToProps = ({ Todo }) => ({
	reportLoading: Todo.reportLoading,
	activityTopListData: Todo.reportStats?.topListData?.activityList,
	appointmentTopListData: Todo.reportStats?.topListData?.appointmentList,
	selectedRolesActivity: Todo.selectedRolesActivity ? Todo.selectedRolesActivity : [],
	selectedRolesAppointment: Todo.selectedRolesAppointment ? Todo.selectedRolesAppointment : []
});

class FocusModeTopList extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			showSettings: false
		};
		this.ref = '';

		this.roles = Tools.AppService.getRoles(
			this.props.type === 'activity'
				? Tools.AppService.AccessType.ACTIVITY
				: Tools.AppService.AccessType.APPOINTMENT
		);
	}

	componentDidMount() {
		this.props.setSelectedRolesActivity([]);
		this.props.setSelectedRolesAppointment([]);
		this.props.getTopListData();
		this.listeners = [
			ReduxListeners.add('activity.updated', getTopListData),
			ReduxListeners.add('appointment.added', getTopListData)
		];
	}

	componentWillUnmount() {
		this.listeners.map(off => off());
	}
	changeRoles = e => {
		const selectedRoles =
			this.props.type === 'activity'
				? [...this.props.selectedRolesActivity]
				: [...this.props.selectedRolesAppointment];

		if (e.target.added) {
			selectedRoles.push(e.target.added);
		}

		if (e.target.removed) {
			const i = selectedRoles.findIndex(role => role.id === e.target.removed.id);
			if (i !== -1) {
				selectedRoles.splice(i, 1);
			}
		}

		if (this.props.type === 'activity' && selectedRoles) {
			this.props.setSelectedRolesActivity(selectedRoles);
		} else if (this.props.type === 'appointment' && selectedRoles) {
			this.props.setSelectedRolesAppointment(selectedRoles);
		}
	};

	getRef = () => {
		return this.ref;
	};

	setRef = (name, ref) => {
		return (this[parseInt(name)] = ref);
	};

	render() {
		const classes = new BemClass('FocusModeTopList');
		return (
			<div className={classes.b()} ref={this.setRef.bind(this, 'ref')}>
				<OutsideClick
					outsideClick={async () => {
						if (this.state.showSettings) {
							await this.props.getTopListData();
							this.setState({ showSettings: false });
						}
					}}
					targetClass="FocusModeTopList__settings-header-wrapper"
					targetRef={this.getRef}
				>
					<div className={classes.elem('settings-header-wrapper').b()}>
						<Row className={classes.elem('header-row').b()}>
							<Column className={classes.elem('title-col').b()}>
								<Text bold size="md" color="white">
									{this.props.title}
								</Text>
							</Column>

							<Column align="right" size={1}>
								<Button
									color="white"
									type="link"
									onClick={async e => {
										e.stopPropagation();
										if (this.state.showSettings) await this.props.getTopListData();
										this.setState({ showSettings: !this.state.showSettings });
									}}
								>
									{this.state.showSettings ? <Icon name="times" /> : <Icon name="cog" />}
								</Button>
							</Column>
						</Row>
						{this.state.showSettings ? (
							<div className={classes.elem('settings').b()}>
								<Text bold color="black">
									{`${T('filters.filterOn')} ${T('default.role').toLowerCase()}`}
								</Text>
								<UpSelect
									key={'role'}
									className={classes.elem('role-select').b()}
									data={this.roles}
									defaultValue={
										this.props.type === 'activity'
											? this.props.selectedRolesActivity
											: this.props.selectedRolesAppointment
									}
									onChange={this.changeRoles}
									placeholder={T('todo.focusModeTopList.selectRoles')}
									multiple={true}
									getId={role => role.id}
									formatSelection={(role, c, escape) => escape(role.name)}
									formatResult={(role, c, q, escape) => escape(role.name)}
									matcher={(term, u, role) =>
										role.name.toLowerCase().indexOf(term.toLowerCase()) !== -1
									}
								/>
							</div>
						) : null}
					</div>
				</OutsideClick>
				<FocusModeTopListUserRows
					topListData={
						this.props.type === 'activity'
							? this.props.activityTopListData
							: this.props.appointmentTopListData
					}
					placeholder={this.props.placeholderText}
					loading={this.props.reportLoading}
				/>
			</div>
		);
	}
}

FocusModeTopList.propTypes = {
	title: PropTypes.string,
	placeholderText: PropTypes.string,
	type: PropTypes.string,
	activityTopListData: PropTypes.array,
	appointmentTopListData: PropTypes.array,
	getTopListData: PropTypes.func,
	reportLoading: PropTypes.bool,
	selectedRolesActivity: PropTypes.array,
	selectedRolesAppointment: PropTypes.array,
	setSelectedRolesActivity: PropTypes.func,
	setSelectedRolesAppointment: PropTypes.func
};
export const detached = FocusModeTopList;
export default connect(mapStateToProps, mapDispatchToProps)(FocusModeTopList);
