import { OrderInterval, PeriodLength } from '../../Context/SubscriptionGroupState';
import { useSubscriptionGroupContext } from '../../Context/SubscriptionGroupContext';
import { usePopupPortalAnchorsContext } from 'App/helpers/PopupPortalAnchors';
import { sdf } from '../../Context/SubscriptionPeriodContextHelpers';
import { DateInput, Link, Text, Select } from '@upsales/components';
import bemClass from '@upsales/components/Utils/bemClass';
import PriceList from 'App/resources/Model/PriceList';
import T from 'Components/Helpers/translate';
import React, { useMemo } from 'react';
import moment from 'moment';

import './SubscriptionSplitScheduling.scss';

/**
 * This is mostly a copy of SubscriptionPeriodScheduling made to a separate file for readability
 * SubscriptionPeriodScheduling should be removed in the future
 */
const SubscriptionSplitScheduling: React.FC<{ uuid?: number }> = ({ uuid }) => {
	const classes = new bemClass('SubscriptionSplitScheduling');
	const baseLangTag = 'subscription.modal.summary.scheduling.split';

	const {
		currentPeriod: currentFromState,
		isOnStartPeriod: onFirstPeriod,
		firstPeriod,
		lastPeriod,
		useStatics,
		toggleRenewal,
		setStartDate,
		setTerminationDate,
		setNoticePeriod,
		setPeriodLength,
		setOffset,
		setOrderInterval,
		setInvoiceStartDate,
		setPriceList,
		state: { isEdit, subscriptionMap, splitEnabled, allowOffset }
	} = useSubscriptionGroupContext();

	// This is used to have a delayed change of the period when scrolling to next period
	const currentPeriod = subscriptionMap[uuid ?? 0] ?? currentFromState;
	const isOnStartPeriod = uuid ? firstPeriod.uuid === uuid : onFirstPeriod;

	const { anchor, scrollContainer } = usePopupPortalAnchorsContext();

	const { Intervals, NoticePeriods, PeriodLengths, Offsets } = useStatics();

	const hasPriceLists =
		Tools.FeatureHelper.isAvailable(Tools.FeatureHelper.Feature.PRICE_LISTS) &&
		Tools.FeatureHelper.hasSoftDeployAccess('PRICE_LISTS');
	const getPriceListItem = (priceList: PriceList) => {
		return { ...priceList, title: priceList.name };
	};
	const priceListOptions = Tools.AppService.getPriceLists()
		?.filter(pl => pl.active)
		.map(getPriceListItem);
	const selectedPriceList = getPriceListItem(currentPeriod.priceList);

	const getPeriodLengthItem = (periodLength: PeriodLength) => {
		const untilFurtherNotice = T('agreement.periodLength.untilFurtherNotice').toLowerCase();
		const title = `${periodLength} ${T('date.month' + (periodLength > 1 ? 's' : '')).toLowerCase()}`;
		return {
			id: periodLength,
			title: periodLength === 0 ? untilFurtherNotice : title
		};
	};

	const periodLengthOptions = useMemo(() => {
		let validPeriodLengths = PeriodLengths.filter(p => p >= 0); // Typescript man...

		if (currentPeriod.children?.length) {
			const lastChild = currentPeriod.children[currentPeriod.children.length - 1];
			const lastChildPeriodLength = moment(lastChild.endDate).diff(lastChild.startDate, 'M', true);
			const currentPL = currentPeriod.periodLength;
			validPeriodLengths = validPeriodLengths.filter(pl => pl > currentPL - lastChildPeriodLength);
		}

		return validPeriodLengths.map(periodLength => getPeriodLengthItem(periodLength));
	}, [PeriodLengths, splitEnabled, isOnStartPeriod, currentPeriod.children, currentPeriod.periodLength]);

	const selectedPeriodLength = getPeriodLengthItem(currentPeriod.periodLength);

	const getIntervalItem = (interval: OrderInterval) => ({
		id: interval,
		title: T(`order.recurringInterval.byMonth.${interval}`).toLowerCase()
	});
	const intervalOptions = useMemo(() => Intervals.map(interval => getIntervalItem(interval)), [Intervals]);
	const selectedInterval = getIntervalItem(currentPeriod.orderInterval);

	const getOffsetItem = (offset: number) => ({ id: offset, title: `${offset} ${T('default.days')}` });
	const offsetOptions = useMemo(() => Offsets.map(offset => getOffsetItem(offset)), [Offsets]);
	currentPeriod.offset = typeof currentPeriod.offset === 'number' ? currentPeriod.offset : 0;
	const selectedOffset = getOffsetItem(currentPeriod.offset ?? 0);

	const getNoticePeriodItem = (period: number) => {
		const noNoticePeriodTitle = T('subscription.modal.summary.scheduling.noticePeriod.no');
		const noticePeriodTitle = `${period} ${T('date.month' + (period > 1 ? 's' : '')).toLowerCase()}`;
		return {
			id: period,
			title: period === 0 ? noNoticePeriodTitle : noticePeriodTitle
		};
	};

	const noticePeriodOptions = useMemo(() => {
		const validNoticePeriods =
			currentPeriod.periodLength > 0
				? NoticePeriods.filter(period => period <= currentPeriod.periodLength)
				: NoticePeriods;
		return validNoticePeriods.map(period => getNoticePeriodItem(period));
	}, [NoticePeriods, currentPeriod]);

	const selectedNoticePeriod = getNoticePeriodItem(currentPeriod.noticePeriod);

	const futurePeriodsHasChildren = useMemo(() => {
		const sortedSubscription = Object.values(subscriptionMap).sort((period1, period2) =>
			moment(period1.startDate) > moment(period2.startDate) ? 1 : -1
		);
		const indexOfCurrent = sortedSubscription.findIndex(period => period.uuid === currentPeriod.uuid);
		return sortedSubscription
			.slice(indexOfCurrent + 1)
			.some(period => period.children?.length || (period.periodLength === 0 && period.endDate));
	}, [currentPeriod.uuid, subscriptionMap]);

	const initialOffset = useMemo(() => currentPeriod.offset, []);
	const hadOffset = isEdit && initialOffset;
	const isOffsetAllowed = allowOffset;
	const shouldShowOffset = hadOffset || isOffsetAllowed;

	const endDateHasPassed = currentPeriod.endDate && moment(currentPeriod.endDate).isBefore();
	const startDateHasPassed = moment(currentPeriod.startDate).isBefore(moment().format(sdf));
	const invoiceStartDateHasPassed = moment(currentPeriod.invoiceStartDate).isBefore(moment().format(sdf));

	const currentPeriodLocked = currentPeriod.locked || currentPeriod.externallyLocked;

	const getMaxDate = () => {
		if (currentPeriod.children?.length) {
			const firstChild = currentPeriod.children[0];
			return moment(firstChild.endDate).subtract(1, 'd').toDate();
		}
		if (currentPeriod.periodLength === 0 && currentPeriod.endDate) {
			return moment(currentPeriod.endDate).subtract(1, 'd').toDate();
		}
		return undefined;
	};

	const getMinDate = () => {
		if (!currentPeriod.children?.length) return undefined;

		const lastChild = currentPeriod.children[currentPeriod.children.length - 1];
		return moment(lastChild?.startDate).subtract(currentPeriod.periodLength, 'M').add(1, 'day').toDate();
	};

	/*** Schedule rows ***/
	const renderFirstRow = () => {
		let partOne = baseLangTag + '.subscriptionStarts';
		if (!isOnStartPeriod) {
			partOne = baseLangTag + '.renewedPeriodStarts';
		}
		if (startDateHasPassed) {
			partOne += '.passed';
		}

		let partTwo = baseLangTag + '.andPeriodLengthIs';
		if (endDateHasPassed) {
			partTwo += '.passed';
		}

		const hasAlreadyStarted = (currentPeriod.orderSequenceNr ?? 0) > 0;
		const disablePeriodLength = (isEdit && hasAlreadyStarted) || futurePeriodsHasChildren;
		const disableDateInput = disablePeriodLength || !isOnStartPeriod;
		return (
			<Text className={classes.elem('row').b()}>
				{T(partOne)}
				<DateInput
					anchor={anchor}
					scrollContainer={scrollContainer}
					className={`Input--sm Input--no-border${disableDateInput ? ' DateInput--disabled' : ''}`}
					closeOnSelect
					disabled={disableDateInput}
					max={getMaxDate()}
					value={new Date(currentPeriod.startDate)}
					showWeekNumbers
					placeholder={T('default.date')}
					inline
					onChange={e => setStartDate(e.target.value.toDateString(), true)}
				/>
				{T(partTwo)}
				<Select
					className={classes.elem('inline-select').b()}
					dropdownClassName={classes.elem('inline-dropdown').b()}
					value={selectedPeriodLength}
					onChange={item => {
						if (typeof item.id === 'number') {
							setPeriodLength(item.id);
						}
					}}
					options={periodLengthOptions}
					disabled={disablePeriodLength}
					inline
					showSearch={false}
					anchor={document.querySelector('.OpenSubscription__inner')}
				/>
			</Text>
		);
	};

	const renderSecondRow = () => {
		let partOne = baseLangTag + '.orderPeriodStarts';
		if (invoiceStartDateHasPassed) {
			partOne += '.passed';
		}

		const hasAlreadyStarted = (currentPeriod.orderSequenceNr ?? 0) > 0;
		const isDisabled = hasAlreadyStarted || !isOnStartPeriod || futurePeriodsHasChildren;

		return (
			<Text className={classes.elem('row').b()}>
				{T(partOne)}
				<DateInput
					anchor={anchor}
					disabled={isDisabled}
					scrollContainer={scrollContainer}
					className={`Input--sm Input--no-border${isDisabled ? ' DateInput--disabled' : ''}`}
					closeOnSelect
					max={getMaxDate()}
					min={getMinDate()}
					value={new Date(currentPeriod.invoiceStartDate)}
					showWeekNumbers
					placeholder={T('default.date')}
					inline
					onChange={e => setInvoiceStartDate(e.target.value.toDateString())}
				/>
				{/* {`${T(partTwo)} ${moment(currentPeriod.endDate ?? currentPeriod.renewalDate).format('L')}`} We might want to add this in the future */}
			</Text>
		);
	};

	const renderThirdRow = () => {
		let partOne = baseLangTag + '.ordersAreCreated';
		if (endDateHasPassed) {
			partOne += '.passed';
		}

		const hasAlreadyStarted = (currentPeriod.orderSequenceNr ?? 0) > 0;

		return (
			<Text className={classes.elem('row').b()}>
				{T(partOne)}
				<Select
					className={classes.elem('inline-select').b()}
					dropdownClassName={classes.elem('inline-dropdown').b()}
					value={selectedInterval}
					onChange={item => {
						if (typeof item.id === 'number') {
							setOrderInterval(item.id);
						}
					}}
					options={intervalOptions}
					disabled={isEdit && hasAlreadyStarted}
					inline
					showSearch={false}
					anchor={document.querySelector('.OpenSubscription__inner')}
				/>
			</Text>
		);
	};

	const renderFourthRow = () => {
		if (!shouldShowOffset) return null;

		let partOne = baseLangTag + '.ordersAreCreated';
		if (endDateHasPassed) {
			partOne += '.passed';
		}

		const partTwo = baseLangTag + '.inAdvance';

		return (
			<ul>
				<li>
					<Text className={classes.elem('row').b()}>
						{T(partOne)}
						<Select
							className={classes.elem('inline-select').b()}
							dropdownClassName={classes.elem('inline-dropdown').b()}
							value={selectedOffset}
							onChange={item => {
								if (typeof item.id === 'number') {
									setOffset(item.id);
								}
							}}
							options={offsetOptions}
							disabled={currentPeriodLocked}
							inline
							showSearch={false}
							anchor={document.querySelector('.OpenSubscription__inner')}
						/>
						{T(partTwo)}
					</Text>
				</li>
			</ul>
		);
	};

	const renderFifthRow = () => {
		let partOne = baseLangTag + '.currentPeriodHas';

		if (isOnStartPeriod && endDateHasPassed) {
			partOne = baseLangTag + '.passedPeriodHas';
		}

		if (!isOnStartPeriod) {
			partOne = baseLangTag + '.renewedPeriodHas';
		}

		if (endDateHasPassed) {
			partOne += '.passed';
		}

		const partTwo = baseLangTag + '.noticePeriod';

		return (
			<Text className={classes.elem('row').b()}>
				{T(partOne)}
				<Select
					className={classes.elem('inline-select').b()}
					dropdownClassName={classes.elem('inline-dropdown').b()}
					value={selectedNoticePeriod}
					onChange={item => {
						if (typeof item.id === 'number') {
							setNoticePeriod(item.id);
						}
					}}
					options={noticePeriodOptions}
					disabled={currentPeriodLocked}
					inline
					showSearch={false}
					anchor={document.querySelector('.OpenSubscription__inner')}
				/>
				{T(partTwo)}
			</Text>
		);
	};

	const renderSixthRow = () => {
		const untilFurtherNotice = currentPeriod.periodLength === 0;
		const notLast = currentPeriod.uuid !== lastPeriod.uuid;

		if (!untilFurtherNotice && notLast && Object.keys(subscriptionMap).length > 1) return null;

		const untilFurtherNoticeNotLast = untilFurtherNotice && notLast;

		let partOne = baseLangTag + '.theSubscription';
		let partTwo = baseLangTag;

		if (untilFurtherNotice && !currentPeriod.endDate) {
			partOne += '.isMissing';
			partTwo += '.terminationDate';

			return (
				<Text className={classes.elem('row').b()}>
					{T(partOne)}
					{currentPeriodLocked ? (
						T(partTwo)
					) : (
						<Link
							className={classes.elem('renewal-toggle').mod({ renewed: !currentPeriod.endDate }).b()}
							onClick={toggleRenewal}
						>
							{T(partTwo)}
						</Link>
					)}
				</Text>
			);
		}

		if (currentPeriod.endDate) {
			partTwo += '.isTerminated';
			if (endDateHasPassed) {
				partTwo += '.passed';
			}
		} else {
			partTwo += '.isRenewed';
		}

		let partThree = moment(currentPeriod.renewalDate).format('L');
		if (currentPeriod.endDate) {
			partThree = moment(currentPeriod.endDate).format('L');
		}

		const getMinDate = () => {
			if (currentPeriod.children?.length) {
				const lastChild = currentPeriod.children[currentPeriod.children.length - 1];
				return moment(lastChild.startDate).toDate();
			}
			return moment(currentPeriod.startDate).toDate();
		};

		const disabledDateInput = futurePeriodsHasChildren;

		return (
			<Text className={classes.elem('row').b()}>
				{T(partOne)}
				{disabledDateInput || untilFurtherNoticeNotLast ? (
					` ${T(partTwo)}`
				) : (
					<Link
						className={classes.elem('renewal-toggle').mod({ renewed: !currentPeriod.endDate }).b()}
						onClick={toggleRenewal}
					>
						{T(partTwo)}
					</Link>
				)}
				{currentPeriod.endDate ? (
					<DateInput
						anchor={anchor}
						scrollContainer={scrollContainer}
						className={`Input--sm Input--no-border${disabledDateInput ? ' DateInput--disabled' : ''}`}
						closeOnSelect={true}
						min={getMinDate()}
						max={
							currentPeriod.periodLength
								? moment(currentPeriod.invoiceStartDate).add(currentPeriod.periodLength, 'M').toDate()
								: undefined
						}
						value={new Date(currentPeriod.endDate)}
						showWeekNumbers={true}
						placeholder={T('default.date')}
						disabled={disabledDateInput}
						inline
						onChange={e => setTerminationDate(e.target.value.toDateString())}
					/>
				) : (
					T(partThree)
				)}
			</Text>
		);
	};

	const renderSeventhRow = () => {
		if (hasPriceLists) {
			return (
				<Text className={classes.elem('row').b()}>
					{T('subscription.modal.summary.scheduling.split.priceList')}
					<Select
						className={classes.elem('priceListSelect').b()}
						dropdownClassName={classes.elem('inline-dropdown').b()}
						value={selectedPriceList}
						onChange={setPriceList}
						options={priceListOptions}
						disabled={currentPeriodLocked}
						inline
						showSearch={false}
						anchor={document.querySelector('.OpenSubscription__inner')}
					/>
				</Text>
			);
		}

		return null;
	};

	return (
		<div className={classes.b()}>
			{renderFirstRow()}
			{renderSecondRow()}
			{renderThirdRow()}
			{renderFourthRow()}
			{renderFifthRow()}
			{renderSixthRow()}
			{renderSeventhRow()}
		</div>
	);
};

export default SubscriptionSplitScheduling;
