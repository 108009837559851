import React from 'react';
import Bem from '@upsales/components/Utils/bemClass';
import { isSalesboardListView, isUserListView, ListView, SalesboardListView } from 'App/resources/AllIWant';
import { connect, ConnectedProps } from 'react-redux';
import { initView, showSaveView, showRegularView, saveView, setView } from 'Store/reducers/SharedViewsReducer';
import { Button, DropDownMenu, DropDownButton, Card, Icon, Tooltip, SplitButtonWithActions } from '@upsales/components';

import T from '../Helpers/translate';
import StandardView from './Views/StandardView';
import HiddenView from './Views/HiddenView';
import SaveView from './Views/SaveView';
import LoadingView from './Views/LoadingView';
import SubtitleGenerator from './SubtitleGenerator';

import './SharedViews.scss';
import { RootState } from 'Store/index';

// This can be inferred in mapStateToProps instead, once SharedViewsReducer is typed
type SharedViewsStateProps = {
	loading: boolean;
	entityName: string;
	listTitle: string;
	shared: boolean;
	isHiddenView: boolean;
	isSaveView: boolean;
	isExpanded: boolean;
	closeDropdown: boolean;
	isSaving: boolean;
	reducerType: string;
	selfId: number | null;
};

export type SharedViewsPropsExternal = {
	selectedView?: ListView | SalesboardListView | null;
	title: string;
	total: number;
	canBeSaved: boolean;
	hasChanged: boolean;
	changeView: (selectedView: ListView | SalesboardListView | null, opts?: { fromSave?: boolean }) => void;
	type: string;
	formatTotal?: (total: number) => string;
	hideSubtitle?: boolean;
	isDisabled?: boolean;
	isReport?: boolean;
	isChangingView?: boolean;
	wrapperId?: string;
	wrapperStyle?: React.HTMLAttributes<HTMLDivElement>['style'];
	isNewListView?: boolean;
};

type StaredViewsState = {
	forceExpand: boolean;
	forceClose: boolean;
};

const mapStateToProps = ({ SharedViews, App }: RootState): SharedViewsStateProps => {
	return {
		loading: SharedViews.loading,
		entityName: SharedViews.entityName,
		listTitle: SharedViews.title,
		shared: SharedViews.shared,
		isHiddenView: SharedViews.isHiddenView,
		isSaveView: SharedViews.isSaveView,
		isExpanded: SharedViews.isExpanded,
		closeDropdown: SharedViews.closeDropdown,
		isSaving: SharedViews.isSaving,
		reducerType: SharedViews.type,
		selfId: App.self?.id ?? null
	};
};

const mapDispatchToProps = {
	initView,
	showSaveView,
	showRegularView,
	saveView,
	setView
};

type Action = {
	id: string;
	onClick: () => void;
	title: string;
};

const connector = connect(mapStateToProps, mapDispatchToProps);
type SharedViewsProps = ConnectedProps<typeof connector> & SharedViewsPropsExternal;

class SharedViews extends React.Component<SharedViewsProps, StaredViewsState> {
	cardRef: React.RefObject<HTMLDivElement> | null = null;
	constructor(props: SharedViewsProps) {
		super(props);

		this.state = {
			forceExpand: false,
			forceClose: false
		};

		this.cardRef = React.createRef();
	}

	componentDidMount() {
		const { initView, ...restProps } = this.props;
		initView(restProps);
	}

	componentDidUpdate() {
		if (this.state.forceExpand) {
			this.setState({
				forceExpand: false
			});
		}

		if (this.state.forceClose) {
			this.setState({
				forceClose: false
			});
		}
	}

	// eslint-disable-next-line camelcase
	UNSAFE_componentWillReceiveProps(nextProps: SharedViewsProps) {
		const shouldCallSetExpand = nextProps.isSaveView === true && this.props.isSaveView === false;
		const shouldClose = nextProps.closeDropdown !== this.props.closeDropdown;

		if (shouldCallSetExpand) {
			this.setState({
				forceExpand: true
			});
		}

		if (shouldClose) {
			this.setState({
				forceClose: true
			});
		}
		// This is a hack to set selected view to the state if it's changed from outside. Redux should have nothing to do with this component later.
		if (nextProps.selectedView && nextProps.selectedView.id !== this.props.selectedView?.id) {
			this.props.setView(nextProps.selectedView);
		}
	}

	DropDownRenderTrigger(
		expanded: boolean,
		setExpanded: (e: React.MouseEvent<HTMLButtonElement | HTMLDivElement, MouseEvent>) => void
	) {
		const { forceExpand, forceClose } = this.state;
		const {
			hasChanged,
			selectedView,
			showSaveView,
			changeView,
			type,
			formatTotal,
			saveView,
			total,
			entityName,
			listTitle,
			isReport,
			isDisabled,
			hideSubtitle,
			isSaving,
			selfId,
			isNewListView
		} = this.props;

		if ((forceExpand && !expanded) || (forceClose && expanded)) {
			setImmediate(() => {
				setExpanded({
					preventDefault: () => {},
					stopPropagation: () => {}
				} as React.MouseEvent<HTMLButtonElement>);
			});
		}

		function isMyView(view: ListView | SalesboardListView) {
			return isUserListView(view) && view.regBy === selfId;
		}

		const createActions: Record<string, Action> = {
			save: {
				id: 'save',
				title: 'filters.saveViewAsNew',
				onClick: () => {
					showSaveView({ editView: null, createNew: true });
				}
			},
			update: {
				id: 'update',
				title: 'sharedViews.updateView',
				onClick: () => {
					const isResetView = false;
					saveView({}, selectedView, isResetView);
					if (selectedView) {
						changeView(selectedView, { fromSave: true });
					}
				}
			}
		};

		const isCustomerStandardSalesboard = isSalesboardListView(selectedView) && selectedView?.standard;

		return (
			<DropDownButton
				key="dropdownbutton"
				title={T(selectedView?.title ?? '')}
				subtitle={
					SubtitleGenerator({
						type,
						entityName,
						listTitle,
						hideSubtitle,
						total,
						formatTotal,
						isReport
					}) || undefined
				}
				onClick={e => (!isDisabled ? setExpanded(e) : null)}
				expanded={expanded}
				hideArrow={!!selectedView && hasChanged && !isDisabled}
			>
				{selectedView && hasChanged && !isDisabled ? (
					!!parseInt(selectedView.id + '') === true &&
					!isCustomerStandardSalesboard &&
					isMyView(selectedView) ? (
						/**
						 * @todo  @upsales/components 2.18.4
						 * CONSISTENCY - SplitButtonWithActions' prop actions does not allow handling of events but SplitButton does.
						 * When that is implemented the div wrapper can be removed and the stopPropagation can be added to the onClick.
						 * The following code snippet can also be readded to the save onClick:
						 *
						 * if (!expanded) {
						 * 	setExpanded(e);
						 * }
						 * @link https://github.com/upsales/ui-components/issues/530
						 */
						<div onClick={e => e.stopPropagation()}>
							<SplitButtonWithActions
								size="xs"
								space="mrs"
								trigger="click"
								mainAction={selectedView.editable && !isCustomerStandardSalesboard ? 'update' : 'save'}
								actions={Object.values(createActions).map(i => ({
									...i,
									title: T(i.title),
									onClick: () => {
										i.onClick();
									}
								}))}
							/>
						</div>
					) : (
						<Button
							disabled={isSaving}
							key="save-view-as-button"
							size="xs"
							className="FloatingButton"
							color="green"
							onClick={event => {
								showSaveView({ editView: null, createNew: true });
								if (!expanded) {
									setExpanded(event);
								}

								event.stopPropagation();
							}}
						>
							{T('filters.saveViewAs')}
						</Button>
					)
				) : null}
				{isNewListView && hasChanged ? (
					<Tooltip position="right" title={T('sharedViews.changesTooltip')}>
						<Icon color="blue" name="info-circle" />
					</Tooltip>
				) : null}
			</DropDownButton>
		);
	}

	onOpen = () => {
		// Find trigger based on class (this is because Button cant have refs yet, will only exist one trigger)
		const triggerElem = document.querySelector('.SharedViews .DropDownButton');
		if (triggerElem && this.cardRef?.current) {
			// Calculate avaliable space below trigger and set maxHeight on dropdown to this (-12px margin)
			const rect = triggerElem.getBoundingClientRect();
			const availableSpace = window.innerHeight - rect.bottom - 12;
			this.cardRef.current.style.maxHeight = `${availableSpace}px`;
		}
	};

	render() {
		const {
			selectedView,
			isHiddenView,
			isSaveView,
			changeView,
			hasChanged,
			showRegularView,
			isChangingView,
			isDisabled,
			wrapperId,
			wrapperStyle,
			isNewListView
		} = this.props;
		const MainClass = new Bem('SharedViews').mod({
			loading: selectedView === null,
			isChanged: hasChanged && (isNewListView ?? !isDisabled),
			isDisabled: isDisabled,
			hasRemoveFrame: Tools.FeatureHelper.hasSoftDeployAccess('REMOVE_FRAME'),
			isNewListView: isNewListView
		});

		if (selectedView === null) {
			return <LoadingView className={MainClass} />;
		}

		const wrapperObject: React.HTMLAttributes<HTMLDivElement> = {
			className: MainClass.b()
		};

		if (wrapperId) {
			wrapperObject.id = wrapperId;
		}

		if (wrapperStyle) {
			wrapperObject.style = wrapperStyle;
		}

		return (
			<div {...wrapperObject}>
				<DropDownMenu
					align="left"
					renderTrigger={(expanded, setExpanded) => this.DropDownRenderTrigger(expanded, setExpanded)}
					onClose={() => showRegularView()}
					onOpen={this.onOpen}
				>
					<Card
						className={MainClass.elem('Expanded').mod({ isHiddenView, isSaveView }).b()}
						ref={this.cardRef}
					>
						{!isNewListView ? (
							<StandardView changeView={changeView} isChangingView={isChangingView} />
						) : null}
						<HiddenView changeView={changeView} />
						<SaveView changeView={changeView} />
					</Card>
				</DropDownMenu>
			</div>
		);
	}
}

export const detached = SharedViews;

export default connector(SharedViews);
