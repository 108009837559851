import React, { useState, useContext } from 'react';
import { Text, Link, IconName } from '@upsales/components';
import TimelineHistoryUsers from '../TimelineHistoryUsers';
import TimelineRow from '../TimelineRow';
import { OnChangeValue } from 'App/components/RelationSelect';
import T from 'Components/Helpers/translate';
import { EVENT_TIMELINE_SUB_TYPES } from 'App/babel/enum/timeline';
import RelationDropdownMenu from 'App/components/dropdowns/RelationDropdownMenu';
import MailResource from 'Resources/Mail';
import { TYPES } from 'Components/Helpers/SourceHelper';
import TimelineNoteBase from '../TimelineNoteBase';
import RelatedButtons from 'App/components/Buttons/RelatedButtons';
import logError from 'App/babel/helpers/logError';
import { openDrawer } from 'Services/Drawer';
import { PopupPortalAnchorsContext } from 'App/helpers/PopupPortalAnchors';
import Event from 'App/resources/Model/Event';
import MailOpenClickIndicator from 'Components/MailOpenClickIndicator';

type Props = {
	event: Event;
	isHistoryLog: boolean;
	hideLine: boolean;
	hideIcon?: boolean;
};

const TimelineMail = ({ event, isHistoryLog, hideLine, hideIcon = false }: Props) => {
	const { contacts, date, entityId, mail, subType } = event || {};

	const contact = contacts?.[0];

	const [relations, setRelations] = useState({
		opportunity: event.opportunity,
		appointment: event.appointment,
		activity: event.activity
	});
	const [editingMailRelations, setEditingMailRelations] = useState(false);

	const { scrollContainer, anchor } = useContext(PopupPortalAnchorsContext);

	if (!mail?.subject || !contact) {
		return null;
	}

	const onRelationChange = async (value: OnChangeValue) => {
		const { opportunityId, appointmentId, activityId } = value;
		try {
			const {
				data: { appointment, activity, opportunity }
			} = await MailResource.save({
				id: mail.id,
				opportunity: opportunityId ? { id: opportunityId } : null,
				appointment: appointmentId ? { id: appointmentId } : null,
				activity: activityId ? { id: activityId } : null
			});
			setRelations({ appointment, activity, opportunity });
		} catch (err) {
			logError(err, 'failed to save relations');
		}
	};
	const type = subType?.toLowerCase();
	let iconColor: string = '';
	let title: JSX.Element | null = null;
	let icon: IconName = 'envelope';
	let Component: typeof TimelineHistoryUsers | typeof TimelineRow = TimelineHistoryUsers;
	let bounced = false;

	switch (type) {
		case EVENT_TIMELINE_SUB_TYPES.RECEIVE:
		case EVENT_TIMELINE_SUB_TYPES.SEND: {
			const isReceived = type === EVENT_TIMELINE_SUB_TYPES.RECEIVE;
			title = (
				<Text>
					{`${isReceived ? T('event.receivedMail').toLowerCase() : T('default.mailSent').toLowerCase()} `}
					<Link
						className="text-ellipsis"
						onClick={() => {
							if (Tools.FeatureHelper.hasSoftDeployAccess('NEW_MAIL')) {
								openDrawer('SentMail', { mail: { id: entityId } });
							} else {
								Tools.$upModal.open('sentMail', { id: entityId });
							}
						}}
					>
						<span className="text-ellipsis">{mail.subject}</span>
					</Link>
					{` ${isReceived ? T('default.from').toLowerCase() : T('default.to').toLowerCase()} `}
					<Link onClick={() => Tools.$state.go('contact.dashboard', { id: contact.id })}>{contact.name}</Link>
				</Text>
			);
			break;
		}
		case EVENT_TIMELINE_SUB_TYPES.SOFT_BOUNCE:
		case EVENT_TIMELINE_SUB_TYPES.HARD_BOUNCE:
			bounced = true;
			Component = TimelineRow;
			icon = 'exclamation-triangle';
			iconColor = type === EVENT_TIMELINE_SUB_TYPES.SOFT_BOUNCE ? 'orange' : 'red';
			title = (
				<Text>
					{`${contact.name}'s ${T('event.emailBounced').toLowerCase()} (${T(
						type === EVENT_TIMELINE_SUB_TYPES.SOFT_BOUNCE
							? 'event.email_soft_bounce'
							: 'event.email_hard_bounce'
					).toLowerCase()}), `}
					<Link
						onClick={() => {
							if (Tools.FeatureHelper.hasSoftDeployAccess('NEW_MAIL')) {
								openDrawer('SentMail', { mail: { id: entityId } });
							} else {
								Tools.$upModal.open('sentMail', { id: entityId });
							}
						}}
					>{` ${mail.subject} `}</Link>
				</Text>
			);
			break;
		case EVENT_TIMELINE_SUB_TYPES.UNSUB:
			Component = TimelineRow;
			icon = 'unlink';
			iconColor = 'red';
			title = (
				<Text>
					{event.value
						? `${contact.name} ${T('default.unsubscribedFrom').toLowerCase()} ${event.value}, `
						: `${contact.name} ${T('mail.mailUnsubscribed').toLowerCase().replace('.', '')}, `}
					<Link
						onClick={() => {
							if (Tools.FeatureHelper.hasSoftDeployAccess('NEW_MAIL')) {
								openDrawer('SentMail', { mail: { id: entityId } });
							} else {
								Tools.$upModal.open('sentMail', { id: entityId });
							}
						}}
					>{` ${mail.subject} `}</Link>
				</Text>
			);
			break;
		default:
			break;
	}

	const subTitle = MailOpenClickIndicator({ mail, date, bounced, showDate: true });
	const actions =
		Tools.FeatureHelper.hasSoftDeployAccess('TODO_LIST') &&
		Tools.FeatureHelper.hasSoftDeployAccess('MAIL_RELATION') ? (
			<div className="TimelineMail__actions">
				<RelatedButtons
					activity={relations.activity}
					appointment={relations.appointment}
					opportunity={relations.opportunity}
					smallIcons
					inEdit={editingMailRelations}
				/>
				<RelationDropdownMenu
					client={event.client}
					contact={contact}
					anchor={anchor}
					opportunityId={relations.opportunity?.id}
					appointmentId={relations.appointment?.id}
					activityId={relations.activity?.id}
					onRelationChange={onRelationChange}
					source={{ id: entityId, type: TYPES.MAIL }}
					onHideRelationSelect={() => setEditingMailRelations(false)}
					onShowRelationSelect={() => setEditingMailRelations(true)}
					tooltip={T('todo.showOptions')}
					scrollContainer={scrollContainer}
					iconColor="grey-8"
				/>
			</div>
		) : null;

	return (
		<TimelineNoteBase
			event={event}
			isHistoryLog={isHistoryLog}
			isLastElem={hideLine}
			content={
				<Component
					noIcon
					icon={hideIcon ? undefined : icon}
					iconColor={iconColor}
					event={event}
					title={title}
					subTitle={subTitle}
					actions={actions}
				/>
			}
			noTimelineRowMargin
		></TimelineNoteBase>
	);
};

export default TimelineMail;
