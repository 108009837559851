import OpportunityAIResource from 'App/babel/resources/OpportunityAI';
import FieldTranslation from 'App/babel/resources/FieldTranslations';

const SET_STATE = '[OpportunityAI] Set state';

export const initialState = {
	avg: null,
	appointment: null,
	activity: null,
	phonecall: null,
	id: null,
	isDecisionMakerInvolved: null,
	decisionMakerId: null,
	decisionMakerName: null,
	userId: null,
	includedTitles: null,
	excludedTitles: null,
	checklist: []
};

/*********** Actions **********/

export const getParameters = () => async (dispatch, getState) => {
	const { id } = getState().OpportunityAI;
	const res = await OpportunityAIResource.get(id);
	const {
		avg,
		appointment,
		activity,
		phonecall,
		isDecisionMakerInvolved,
		decisionMakerId,
		opportunity,
		decisionMakerName,
		includedTitles,
		excludedTitles,
		excludedStakeholders,
		includedStakeholders,
		checklist
	} = res.data;

	const { data: fields } =
		includedTitles?.length > 0
			? await FieldTranslation.find({
					type: 'titlecategory',
					tagId: includedTitles.map(contact => contact.title_category)
			  })
			: { data: [] };
	const included = includedTitles?.map(contact => {
		contact.title = fields.find(field => field.tagId === contact.title_category).value;
		return contact;
	});

	const { data: excluded } =
		excludedTitles?.length > 0
			? await FieldTranslation.find({ type: 'titlecategory', tagId: excludedTitles })
			: { data: [] };

	const { data: excludedStakeholdersFields } =
		excludedStakeholders?.length > 0
			? await FieldTranslation.find({ type: 'stakeholder', tagId: excludedStakeholders })
			: { data: [] };

	const { data: includedStakeholdersFields } =
		includedStakeholders?.length > 0
			? await FieldTranslation.find({
					type: 'stakeholder',
					tagId: includedStakeholders.map(stakeholder => stakeholder.tag_id)
			  })
			: { data: [] };

	const mappedStakeholder = includedStakeholders?.map(contact => {
		contact.title = includedStakeholdersFields.find(field => field.tagId === contact.tag_id).value;
		return contact;
	});

	dispatch({
		type: SET_STATE,
		avg,
		appointment,
		activity,
		phonecall,
		isDecisionMakerInvolved,
		decisionMakerId,
		decisionMakerName,
		opportunity,
		includedTitles: included,
		excludedTitles: excluded,
		includedStakeholders: mappedStakeholder,
		excludedStakeholders: excludedStakeholdersFields,
		checklist
	});
};
export const setIds = id => dispatch => {
	dispatch({ type: SET_STATE, id });
};

const ACTION_HANDLERS = {
	// eslint-disable-next-line no-unused-vars
	[SET_STATE]: (state, { type, ...rest }) => ({ ...state, ...rest })
};

export default (state = initialState, action) => {
	const handler = ACTION_HANDLERS[action.type];
	return handler ? handler(state, action) : state;
};
