import Attribute, { DisplayType, Type } from './Attribute';

const FlowAttributes = {
	id: Attribute({
		title: 'default.id',
		field: 'id',
		type: Type.Number,
		displayType: DisplayType.Number,
		groupable: false,
		selectableColumn: true,
		filterable: false,
		sortable: 'id'
	}),
	name: Attribute({
		title: 'default.name',
		field: 'name',
		type: Type.String,
		displayType: DisplayType.Text,
		groupable: false,
		sortable: 'name',
		selectableColumn: true,
		filterable: false
	}),
	regDate: Attribute({
		title: 'default.appointmentCreateDate',
		field: 'regDate',
		type: Type.Date,
		displayType: DisplayType.Date,
		groupable: false,
		selectableColumn: true,
		filterable: false,
		sortable: 'regDate'
	}),
	regBy: Attribute({
		title: 'default.createdBy',
		field: 'regBy',
		type: Type.Number,
		displayType: DisplayType.User,
		groupable: false,
		selectableColumn: true,
		sortable: 'regBy',
		displayAttr: 'name',
		parent: 'users'
	}),
	segment: Attribute({
		type: Type.Object,
		title: 'default.segment',
		field: 'segment.id',
		displayType: DisplayType.Number,
		groupable: false,
		sortable: false,
		// sortable: 'segment.name',
		selectableColumn: false
	}),
	segmentId: Attribute({
		title: 'default.segmentId',
		field: 'segmentId',
		type: Type.Number,
		displayType: DisplayType.Number,
		groupable: false,
		sortable: false,
		selectableColumn: false
	}),
	startTime: Attribute({
		title: 'default.startTime',
		field: 'startTime',
		type: Type.String,
		displayType: DisplayType.Text,
		groupable: false,
		sortable: false,
		selectableColumn: false
	}),
	endTime: Attribute({
		title: 'default.endTime',
		field: 'endTime',
		type: Type.String,
		displayType: DisplayType.Text,
		groupable: false,
		sortable: false,
		selectableColumn: false
	}),
	timezone: Attribute({
		title: 'default.timezone',
		field: 'timezone',
		type: Type.String,
		displayType: DisplayType.Text,
		groupable: false,
		sortable: false,
		selectableColumn: false
	}),
	skipWeekends: Attribute({
		title: 'default.skipWeekends',
		field: 'skipWeekends',
		type: Type.Boolean,
		displayType: DisplayType.Boolean,
		groupable: false,
		sortable: false,
		selectableColumn: false
	}),
	sourceTemplate: Attribute({
		title: 'default.sourceTemplate',
		field: 'sourceTemplate',
		type: Type.String,
		displayType: DisplayType.Text,
		groupable: false,
		sortable: false,
		selectableColumn: false
	}),
	path: Attribute({
		title: 'default.path',
		field: 'path',
		type: Type.Object,
		groupable: false,
		sortable: false,
		selectableColumn: false
	}),
	status: Attribute({
		title: 'default.status',
		field: 'status',
		type: Type.String,
		displayType: DisplayType.Text,
		groupable: false,
		sortable: 'status',
		selectableColumn: true
	}),
	loop: Attribute({
		title: 'default.loop',
		field: 'loop',
		type: Type.Boolean,
		displayType: DisplayType.Boolean,
		groupable: false,
		sortable: false,
		selectableColumn: false
	}),
	loopTime: Attribute({
		title: 'default.loopTime',
		field: 'loopTime',
		type: Type.Number,
		displayType: DisplayType.Number,
		groupable: false,
		sortable: false,
		selectableColumn: false
	}),
	loopUnit: Attribute({
		title: 'default.loopTime',
		field: 'loopTime',
		type: Type.String,
		displayType: DisplayType.Text,
		groupable: false,
		sortable: false,
		selectableColumn: false
	}),
	endCriterias: Attribute({
		title: 'default.endCriterias',
		field: 'endCriterias',
		type: Type.Array,
		displayType: DisplayType.Text,
		groupable: false,
		sortable: false,
		selectableColumn: false
	}),
	endCriteriasEmail: Attribute({
		title: 'default.endCriteriasEmail',
		field: 'endCriteriasEmail',
		type: Type.Array,
		displayType: DisplayType.Text,
		groupable: false,
		sortable: false,
		selectableColumn: false
	})
};

export default FlowAttributes;
