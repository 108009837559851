import { bisnodeTracker } from 'App/babel/helpers/Tracker';

export const initialState = {
	accounts: [],
	loading: true,
	mergin: null,
	paginatorOffset: 0
};

const INIT = '[REFRESH_ACCOUNT] INIT';
const FETCH_EXISTING_STARTED = '[REFRESH_ACCOUNT] FETCH_EXISTING_STARTED';
const FETCH_EXISTING_FAILED = '[REFRESH_ACCOUNT] FETCH_EXISTING_FAILED';
const FETCH_EXISTING_DONE = '[REFRESH_ACCOUNT] FETCH_EXISTING_DONE';
const MERGE_EXISTING_STARTED = '[REFRESH_ACCOUNT] MERGE_EXISTING_STARTED';
const MERGE_EXISTING_FAILED = '[REFRESH_ACCOUNT] MERGE_EXISTING_FAILED';

export const reducer = (state = initialState, action) => {
	switch (action.type) {
		case INIT:
			return initialState;
		case FETCH_EXISTING_STARTED:
			return { ...state, loading: true };
		case FETCH_EXISTING_FAILED:
			return { ...state, loading: false };
		case FETCH_EXISTING_DONE:
			return { ...state, loading: false, ...action.data };
		case MERGE_EXISTING_STARTED:
			return { ...state, ...action.data };
		case MERGE_EXISTING_FAILED:
			return { ...state, mergin: null };
		default:
			return state;
	}
};

export const init = () => ({ type: INIT });

export const fetchClientsInUpsales = (currentAccountId, dataSources) => async dispatch => {
	const soliditetData = dataSources.find(dataSource => dataSource.id === 'soliditet')?.data || [];
	const prospectingData = dataSources.find(dataSource => dataSource.id === 'prospecting')?.data || [];
	const dunsNumbers = soliditetData.map(client => client.dunsNo);
	const prospectingIds = prospectingData.map(client => client.prospectingId);

	if (!dunsNumbers.length && !prospectingIds.length) {
		return dispatch({ type: FETCH_EXISTING_DONE, data: { accounts: [], paginatorOffset: 0 } });
	}

	dispatch({ type: FETCH_EXISTING_STARTED });

	const customerId = Tools.AppService.getCustomerId();
	const rb = new Tools.RequestBuilder();

	rb.addFilter({ field: 'id' }, rb.comparisonTypes.NotEquals, currentAccountId);

	const orBuilder = rb.orBuilder();

	if (dunsNumbers.length) {
		orBuilder.next();
		orBuilder.addFilter({ field: 'dunsNo' }, rb.comparisonTypes.Equals, dunsNumbers);
	}
	if (prospectingIds.length) {
		orBuilder.next();
		orBuilder.addFilter({ field: 'prospectingId' }, rb.comparisonTypes.Equals, prospectingIds);
	}

	orBuilder.done();

	try {
		const result = await Tools.Account.customer(customerId).find(rb.build());
		dispatch({ type: FETCH_EXISTING_DONE, data: { accounts: result.data, paginatorOffset: 0 } });
	} catch (error) {
		dispatch({ type: FETCH_EXISTING_FAILED });
	}
};

export const merge = (currentAccount, targetAccount, closeModal) => async dispatch => {
	const t = Tools.$translate;
	const body = `${t('account.mergeInfo1')} <b>${targetAccount.name}</b><br/><br/>${t('account.mergeInfo2')}`;

	try {
		await Tools.$upModal.open('defaultConfirm', {
			title: 'account.mergeClients',
			body,
			resolveFalse: 'default.merge',
			no: 'default.abort',
			icon: 'fa-success'
		});
	} catch (error) {
		return;
	}

	bisnodeTracker.track(bisnodeTracker.events.UPDATE_SINGLE, { from: 'client' });

	dispatch({ type: MERGE_EXISTING_STARTED, data: { mergin: targetAccount.id } });

	try {
		const customerId = Tools.AppService.getCustomerId();
		await Tools.Account.customer(customerId).merge(targetAccount, currentAccount);

		closeModal(); // Have to close the modal to be able to do a state.go for some reason

		Tools.$state.go('account.dashboard', { id: targetAccount.id });
	} catch (error) {
		dispatch({ type: MERGE_EXISTING_FAILED });
	}
};

export default reducer;
