import React from 'react';
import { Avatar, Text, Button, Row, Column, Icon, OutsideClick, Block, Help, Tooltip } from '@upsales/components';
import UpSelect from 'Components/Inputs/UpSelect';
import HiddenSelect from 'Components/Inputs/HiddenSelect';
import './InlineChangeHistory.scss';
import BemClass from '@upsales/components/Utils/bemClass';
import AccountManagerHistory from 'Resources/AccountManagerHistory';
import { makeCancelable } from 'App/babel/helpers/promise';
import T from 'Components/Helpers/translate';
import moment from 'moment';

type User = {
	id: number;
	removed?: boolean;
	email?: string;
	name: string;
};

type ChangeProp = {
	id: number;
	users: User[];
	client: {
		id: number;
	};
	agreementId?: number;
	hasSpecificUser?: boolean;
	date: Date;
};

type Props = {
	change: ChangeProp;
	specificChange?: boolean;
	onChange: (arg: ChangeProp & { users: User[] }) => void;
};

type State = {
	editing: boolean;
	changed: boolean;
	user: User;
	userMap: { [key: string]: User };
	saving: boolean;
};

type ChangeUserEvent = { target: { added: User } };

const matcher = (term: string, undef: any, item: { name: string }) => {
	const name = Tools.$translate(item.name).toLowerCase();
	return name.indexOf(term.toLowerCase()) !== -1;
};

class InlineChangeHistory extends React.Component<Props, State> {
	selectableUsers: User[];
	hasTeamAccount: boolean;
	savePromise?: {
		cancel: Function;
		promise: Promise<void>;
	};

	constructor(props: Props) {
		super(props);

		this.state = this.getFreshState();
		this.selectableUsers = Tools.AppService.getUsers('client');
		this.hasTeamAccount = Tools.AppService.getMetadata().params.teamAccountManager;

		const canRevertUserChange = props.specificChange && props.change.hasSpecificUser;

		if (canRevertUserChange) {
			this.selectableUsers.unshift({
				id: 0,
				name: T('default.accountManagers'),
				email: 'whatever'
			});
		}
	}

	getFreshState() {
		const { change } = this.props;

		// Users are on the change object.
		const users = change.users;

		const initialState: State = {
			editing: false,
			changed: false,
			user: {
				id: 0,
				name: users?.map(u => u.name).join(', '),
				email: users?.map(u => u.email).join(', ')
			},
			userMap: {},
			saving: false
		};

		for (const user of users) {
			initialState.userMap[user.id] = { ...user };
		}

		return initialState;
	}

	componentWillUnmount() {
		if (this.savePromise) {
			this.savePromise.cancel();
		}
	}

	save = () => {
		const { user, userMap } = this.state;
		const { change } = this.props;
		this.setState({ saving: true });

		const users = this.hasTeamAccount
			? Object.keys(userMap)
					.filter(id => !userMap[id].removed)
					.map(id => userMap[id])
			: [user];

		this.savePromise = makeCancelable(
			this.props.specificChange
				? AccountManagerHistory.changeSpecific(change.client.id, change.agreementId, user.id)
				: AccountManagerHistory.changeHistory(
						change.client.id,
						moment(change.date).format('YYYY-MM-DD'),
						users.map(u => u.id)
				  )
		);
		this.savePromise.promise
			.then(() => {
				this.setState({ editing: false, saving: false });
				this.props.onChange({ ...change, users });
			})
			.catch(() => this.setState({ saving: false, editing: false }));
	};

	renderSingleAccountManager() {
		return (
			<UpSelect
				className="form-control"
				placeholder={T('accountManagerHistory.single.setAccountManager')}
				defaultValue={this.state.user}
				matcher={matcher}
				data={this.selectableUsers}
				required={true}
				onChange={(e: ChangeUserEvent) => this.setState({ user: e.target.added, changed: true })}
			/>
		);
	}

	renderMultiAccountManager(classes: BemClass) {
		const users = Object.values(this.state.userMap);

		const renderUsers = users.map(user => {
			const removeUser = () => {
				const userMap = { ...this.state.userMap };
				userMap[user.id].removed = true;
				this.setState({ changed: true, userMap });
			};

			const undoUser = () => {
				const userMap = { ...this.state.userMap };
				userMap[user.id].removed = false;
				this.setState({ userMap });
			};

			return (
				<Row key={user.id} className={classes.elem('multi-user').mod({ removed: user.removed }).b()}>
					<Column>
						<Avatar initials={user.name} email={user.email} size="sm" />
					</Column>
					<Column>
						<Text color={user.removed ? 'grey-10' : 'black'}>{user.name}</Text>
						{user.removed ? (
							<Text color="grey-10" italic={true} size="sm">
								{T('default.removed')}
							</Text>
						) : null}
					</Column>
					<Column fixedWidth={30} align="right">
						<Button
							type="link"
							color="grey"
							className="btn-inline"
							onClick={() => (user.removed ? undoUser() : removeUser())}
						>
							<Icon name={user.removed ? 'undo' : 'trash'} />
						</Button>
					</Column>
				</Row>
			);
		});

		const selectUser = (user: User) => {
			const userMap = { ...this.state.userMap };
			userMap[user.id] = user;
			this.setState({ changed: true, userMap });
		};

		const userKeys = Object.keys(this.state.userMap);
		const selectableUsers = this.selectableUsers.filter(u => !userKeys.includes(u.id + ''));

		return (
			<div>
				<Block space="mbl">{renderUsers}</Block>
				<Block>
					<HiddenSelect
						key={userKeys.join(',')}
						placeholder={T('accountManagerHistory.multi.addUser')}
						options={{ matcher }}
						data={selectableUsers}
						required={true}
						onChange={selectUser}
					>
						{({ showSelect }: { showSelect: Function }) => (
							<Button
								type="link"
								color="bright-blue"
								className="btn-inline"
								onClick={() => showSelect?.()}
							>
								<Icon name="plus" space="mrs" /> {T('accountManagerHistory.multi.addUser')}
							</Button>
						)}
					</HiddenSelect>
				</Block>
			</div>
		);
	}

	render() {
		const { editing, changed, saving } = this.state;
		const { change, specificChange } = this.props;

		const classes = new BemClass('InlineChangeHistory').mod(`${change.id}`);
		const hasDatePassed = moment().startOf('day').isAfter(change.date);

		return (
			<OutsideClick
				targetClass={`InlineChangeHistory--${change.id}`}
				outsideClick={() => this.setState(this.getFreshState())}
				className={classes.b()}
				onClick={e => e.stopPropagation()}
				listen={editing}
			>
				<div
					className={classes
						.elem('button')
						.mod({ specificChange, editing: specificChange ? true : editing })
						.b()}
				>
					{specificChange ? (
						<Icon
							name="edit"
							onClick={e => {
								e.stopPropagation();
								this.setState({ editing: !editing });
							}}
						/>
					) : (
						<Tooltip
							distance={20}
							disabled={hasDatePassed ? true : false}
							position="top"
							title={T('accountManagerHistory.changeFuture')}
							className={classes.elem('warningTooltip').b()}
						>
							<Button
								disabled={hasDatePassed ? false : true}
								type="link"
								color="white"
								onClick={e => {
									e.stopPropagation();
									this.setState({ editing: !editing });
								}}
							>
								<Text color={hasDatePassed ? 'grey-11' : 'dark-red'}>{T('default.edit')}</Text>
							</Button>
						</Tooltip>
					)}
				</div>
				<Block space="ptxl prxl pbxl plxl" className={classes.elem('edit').mod({ editing }).b()}>
					<Row className={classes.elem('header').b()}>
						<Column>
							<Text bold>{T('accountManagerHistory.single.header')}</Text>
						</Column>
						<Column align="right" fixedWidth={20}>
							<Help articleId={920} sidebar={true} />
						</Column>
					</Row>
					<Block space="mtl">
						{this.hasTeamAccount && !specificChange
							? this.renderMultiAccountManager(classes)
							: this.renderSingleAccountManager()}
					</Block>
					<Block space="mtl" className={classes.elem('action-buttons').b()}>
						<Button
							color={changed ? 'bright-blue' : 'light-grey'}
							disabled={!changed}
							shadow={!changed ? 'none' : undefined}
							loading={saving}
							onClick={this.save}
						>
							{T('default.save')}
						</Button>
						<Button color="grey" type="link" onClick={() => this.setState(this.getFreshState())}>
							{T('default.cancel')}
						</Button>
					</Block>
				</Block>
			</OutsideClick>
		);
	}
}

export default InlineChangeHistory;
