import T from 'Components/Helpers/translate';

export type TagEntity = 'client' | 'contact' | 'order' | 'activity' | 'phonecall' | 'todo';
export type UserProperty = {
	value: string;
	title: string;
};

export const getDynamicUserProperties = (tagEntity: TagEntity, isAutomation = false) => {
	const properties: UserProperty[] = [];
	switch (tagEntity) {
		case 'client':
			properties.unshift({
				value: '{{Client.UserId}}',
				title: T('automationTerms.client.accountManager')
			});
			properties.unshift({
				value: '{{Client.AssignedUserId}}',
				title: T('automationTerms.client.assignedUser')
			});
			properties.unshift({
				value: '{{Client.RegById}}',
				title: T('automationTerms.client.regBy')
			});
			break;

		case 'contact':
			properties.unshift({
				value: '{{Client.UserId}}',
				title: T('automationTerms.client.accountManager')
			});
			properties.unshift({
				value: '{{Client.AssignedUserId}}',
				title: T('automationTerms.client.assignedUser')
			});
			properties.unshift({
				value: '{{Client.RegById}}',
				title: T('automationTerms.client.regBy')
			});
			properties.unshift({
				value: '{{Contact.RegById}}',
				title: T('automationTerms.contact.regBy')
			});
			break;

		case 'order':
			properties.unshift({
				value: '{{Client.UserId}}',
				title: T('automationTerms.client.accountManager')
			});
			properties.unshift({
				value: '{{Client.AssignedUserId}}',
				title: T('automationTerms.client.assignedUser')
			});
			properties.unshift({
				value: '{{Client.RegById}}',
				title: T('automationTerms.client.regBy')
			});
			properties.unshift({
				value: '{{Contact.RegById}}',
				title: T('automationTerms.contact.regBy')
			});
			properties.unshift({
				value: '{{Order.UserId}}',
				title: T('automationTerms.order.assignedUser')
			});
			break;

		case 'activity':
		case 'phonecall':
			properties.unshift({
				value: '{{Client.UserId}}',
				title: T('automationTerms.client.accountManager')
			});
			properties.unshift({
				value: '{{Client.AssignedUserId}}',
				title: T('automationTerms.client.assignedUser')
			});
			properties.unshift({
				value: '{{Client.RegById}}',
				title: T('automationTerms.client.regBy')
			});
			properties.unshift({
				value: '{{Contact.RegById}}',
				title: T('automationTerms.contact.regBy')
			});
		//should fall through
		case 'todo':
			properties.unshift({
				value: '{{Activity.RegById}}',
				title: T('automationTerms.activity.regBy')
			});
			properties.unshift({
				value: '{{Activity.UserId}}',
				title: T('automationTerms.activity.assignedUser')
			});
			break;
		default:
			break;
	}
	if (!isAutomation) {
		properties.unshift({
			value: '{{General.CurrentUserId}}',
			title: T('tag.general.currentuser')
		});
	}
	return properties;
};
