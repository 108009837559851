import React, { useRef, useState, useEffect, useMemo } from 'react';
import { Text, Icon, Input, Card, Button, Tooltip, Block } from '@upsales/components';
import { useMailContext } from '../../../../MailContext';
import { useDebounce } from 'Components/Helpers/Debounce';
import { SlideFade } from 'App/components/animations';
import RequestBuilder, { comparisonTypes } from 'Resources/RequestBuilder';
import ContactType from 'App/resources/Model/Contact';
import Contact from 'Resources/Contact';
import { MailRecipient } from 'App/resources/Model/Mail';
import BemClass from '@upsales/components/Utils/bemClass';
import logError from 'App/babel/helpers/logError';
import { renderToString } from 'react-dom/server';
import T from 'Components/Helpers/translate';

import './NewMailRecipientsRow.scss';
import { useSelector } from 'react-redux';
import { RootState } from 'Store/index';
import User, { type BasicUserWithPermissions } from 'App/resources/Model/User';
import emailRegex from 'App/babel/helpers/emailRegex';
import RecipientListItem from './RecipientListItem/RecipientListItem';
import { CancelablePromise, makeCancelable } from 'Helpers/promise';

type RecipientBadgeProps = {
	recipient: MailRecipient;
	onRemove: (recipient: MailRecipient) => void;
};

const RecipientBadge: React.FC<RecipientBadgeProps> = ({ recipient, onRemove }) => {
	const classes = new BemClass('RecipientBadge');
	const renderTooltip = () => {
		return renderToString(
			<Block>
				<Text color="white" size="sm">
					{recipient.email}
				</Text>
				{recipient.client ? (
					<Text color="white" size="sm">
						{recipient.title ? `${recipient.title} ${T('default.at').toLowerCase()} ` : ''}
						{recipient.client.name}
					</Text>
				) : null}
			</Block>
		);
	};

	return (
		<Tooltip title={renderTooltip()}>
			<Text className={classes.b()} color={'bright-blue'}>
				{recipient.name ?? recipient.email ?? T('default.noName')}
				<Icon
					space="mls"
					name={'times'}
					onClick={e => {
						onRemove(recipient);
						e.stopPropagation();
					}}
				/>
			</Text>
		</Tooltip>
	);
};

type ExtraRecipientsBadgeProps = {
	nrOfRecipients: number;
	setShowAllRecipients: (value: boolean) => void;
};

const ExtraRecipientsBadge: React.FC<ExtraRecipientsBadgeProps> = ({ nrOfRecipients, setShowAllRecipients }) => {
	const nrOfExtraRecipients = nrOfRecipients - 3;
	const classes = new BemClass('ExtraRecipientsBadge');
	return (
		<Block className={classes.b()}>
			<Text color="bright-blue">{T('mail.andMore', { number: nrOfExtraRecipients })}</Text>
			<Icon
				name="chevron-down"
				color="bright-blue"
				onClick={e => {
					setShowAllRecipients(true);
					e.stopPropagation();
				}}
			/>
		</Block>
	);
};

type NewMailRecipientsRowProps = {
	hide: boolean;
	title: string;
	recipients: MailRecipient[];
	addRecipient: any;
	removeRecipient: any;
	required?: boolean;
	allowRawEmail?: boolean;
	allowOnlyOne?: boolean;
	onlyContacts?: boolean;
	limitRecipientsShown?: boolean;
};

const isContact = (entity: User | BasicUserWithPermissions | ContactType): entity is ContactType => {
	return !!(entity as ContactType).client;
};

const mapToRecipient = (
	recipient: User | BasicUserWithPermissions | ContactType,
	type: 'user' | 'contact'
): MailRecipient => ({
	id: recipient.id,
	name: recipient.name,
	email: recipient.email,
	type,
	client: isContact(recipient) ? recipient.client : undefined
});

const NewMailRecipientsRow = ({
	hide,
	title,
	recipients,
	addRecipient,
	removeRecipient,
	required,
	allowRawEmail = false,
	allowOnlyOne,
	onlyContacts,
	limitRecipientsShown = false
}: NewMailRecipientsRowProps) => {
	const classes = new BemClass('NewMailRecipientsRow');
	const { state, dispatch } = useMailContext();
	const [searchString, setSearchString] = useState('');
	const allUsers = useSelector(({ App }: RootState) => App.userMap.active);
	const users = React.useMemo(() => {
		const usersWithinSearch = allUsers.filter(
			u =>
				recipients.findIndex(r => r.type === 'user' && r.id === u.id) === -1 &&
				!!u.email &&
				u.name.toLowerCase().includes(searchString.toLowerCase())
		);
		return usersWithinSearch.length > 5 ? usersWithinSearch.splice(0, 5) : usersWithinSearch;
	}, [searchString]);
	const [showAddContact, setShowAddContact] = useState(false);
	const [showDropDown, setShowDropDown] = useState(false);
	const [selectableRecipients, setSelectableRecipients] = useState<MailRecipient[]>([]);
	const inputRef = useRef<HTMLInputElement>(null);
	const contactPromise = useRef<CancelablePromise<{ data: ContactType[] }>>();
	const [showAllRecipients, setShowAllRecipients] = useState<boolean>(!limitRecipientsShown);

	const createAndAddNewContact = () => {
		// eslint-disable-next-line promise/catch-or-return
		Tools.$upModal.open('editContact', { forceRequiredEmail: true }).then((contact: ContactType) => {
			addRecipient(dispatch, state, mapToRecipient(contact, 'contact'));
			setSearchString('');
			setShowAddContact(false);
		});
	};

	const updateSearchString = (str: string) => {
		setSearchString(str);
		if (str && !showDropDown) {
			setShowDropDown(true);
		} else if (!str && showDropDown) {
			setShowDropDown(false);
		}
	};

	const fetchRecipients = useDebounce(
		(searchString: string) => {
			const contactRB = new RequestBuilder();
			contactRB.addFilter({ field: 'email' }, comparisonTypes.NotEquals, null);
			contactRB.addFilter({ field: 'active' }, comparisonTypes.Equals, true);
			if (recipients?.length ?? 0 > 0) {
				contactRB.addFilter({ field: 'id' }, comparisonTypes.NotEquals, recipients.map(c => c.id) as number[]);
			}

			const or = contactRB.orBuilder();
			or.next();
			or.addFilter({ field: 'name' }, comparisonTypes.Search, searchString);
			or.next();
			or.addFilter({ field: 'email' }, comparisonTypes.Search, searchString);
			or.done();
			contactRB.limit = 10;

			// Should only be able to fetch contacts from one client
			if (state.client) {
				contactRB.addFilter({ field: 'client.id' }, comparisonTypes.Equals, state.client.id);
			}

			contactPromise.current = makeCancelable(Contact.find(contactRB.build()));
			contactPromise.current.promise
				.then(({ data }) => {
					if (onlyContacts) {
						setSelectableRecipients(data.map(c => mapToRecipient(c, 'contact')));
					} else {
						setSelectableRecipients([
							...users.map(u => mapToRecipient(u, 'user')),
							...data.map(c => mapToRecipient(c, 'contact'))
						]);
					}
				})
				.catch(e => {
					logError(e, 'Failed to fetch contacts from email drawer');
				});
		},
		[recipients, users],
		300
	);

	useEffect(() => {
		return () => {
			contactPromise.current?.cancel();
		};
	}, []);

	useEffect(() => {
		if (searchString) {
			fetchRecipients(searchString);
		}
	}, [searchString]);

	useEffect(() => {
		if (!hide) {
			inputRef.current?.focus();
		}
	}, [hide]);

	const recipientsToShow = useMemo(() => {
		if (!showAllRecipients) {
			return recipients.slice(0, 3);
		}
		return recipients;
	}, [showAllRecipients, recipients]);

	const onRecipientSelected = (recipient: MailRecipient) => {
		addRecipient(dispatch, state, recipient, recipient?.client);
		setSearchString('');
		setShowDropDown(false);
		setTimeout(() => {
			setShowAddContact(true);
			inputRef.current?.focus();
		}, 10);
	};

	const ref = useRef<HTMLDivElement | null>(null);
	useEffect(() => {
		if (limitRecipientsShown && showAllRecipients) {
			const handleClick = (event: { target: any }) => {
				if (ref.current && !ref.current.contains(event.target)) {
					setShowAllRecipients(false);
				}
			};

			document.addEventListener('click', handleClick);
			return () => {
				document.removeEventListener('click', handleClick);
			};
		}
	}, [ref, showAllRecipients]);

	const canAddMoreRecipients = !allowOnlyOne || recipients.length === 0;
	return (
		<div ref={ref} className={classes.mod({ hide, required }).b()}>
			<Text color="grey-11">{title + ':'}</Text>
			<div className={classes.elem('recepientsWrapper').b()}>
				{recipientsToShow.map((recipient, idx, all) => (
					<RecipientBadge
						key={`${recipient.email}-${recipient.id}`}
						recipient={recipient}
						onRemove={(r: MailRecipient) => {
							if (all.length === 1) {
								setTimeout(() => inputRef.current?.focus(), 10);
							}
							removeRecipient(dispatch, state, r);
						}}
					/>
				))}
				{!showAllRecipients && recipients.length > 3 ? (
					<ExtraRecipientsBadge
						nrOfRecipients={recipients.length}
						setShowAllRecipients={setShowAllRecipients}
					/>
				) : null}
				{canAddMoreRecipients ? (
					<div
						className={classes.elem('inputField').b()}
						onBlur={() => {
							setShowDropDown(false);
							setShowAddContact(false);
							if (!recipients.length) {
								setSearchString('');
							}
						}}
					>
						{showAddContact || recipients.length === 0 ? (
							<Input
								className={classes.elem('input').b()}
								value={searchString}
								onChange={e => updateSearchString(e.target.value)}
								inputRef={inputRef}
								onKeyDown={e => {
									if (allowRawEmail && (e.key === 'Enter' || e.key === 'Tab')) {
										// Validate string email
										if (emailRegex.test(searchString)) {
											setSearchString('');
											addRecipient(dispatch, state, { email: searchString, type: 'other' });
										}
									}
								}}
							/>
						) : (
							<Icon
								name="plus"
								color="grey-8"
								space="mlm"
								onClick={() => {
									setShowAddContact(true);
									setSearchString('');
									setTimeout(() => inputRef.current?.focus(), 10);
								}}
							/>
						)}
					</div>
				) : null}
			</div>
			{showAddContact || recipients.length === 0 ? (
				<div className={classes.elem('contactSelect').b()}>
					<SlideFade visible={showDropDown} direction="top">
						<Card className={classes.elem('contactList').b()}>
							{selectableRecipients.map((recipient, idx) => (
								<RecipientListItem
									key={idx}
									recipient={recipient}
									onClick={onRecipientSelected}
								></RecipientListItem>
							))}
							<Button
								block
								size="lg"
								color="bright-blue"
								type="link"
								onMouseDown={() => {
									createAndAddNewContact();
								}}
							>
								<Icon name="user-plus" space="mrs" /> {T('default.createAContact')}
							</Button>
						</Card>
					</SlideFade>
				</div>
			) : null}
		</div>
	);
};
export default NewMailRecipientsRow;
