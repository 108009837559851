import _ from 'lodash';

// Return true or false if the user can see the category
const hasRoleAccess = function (self, product) {
	// If user is admin we can use all products OR
	// If roles is empty everyone can use this product OR
	if (self.administrator || !product.roles || !product.roles.length) {
		return true;
	}
	const myRoleId = self.role ? self.role.id : null;
	// If my role is in array we are all good
	if (myRoleId && _.find(product.roles, { id: myRoleId })) {
		return true;
	}
	// If we get to this we cannot use this product, sorry
	return false;
};

const parseProduct = product => {
	const self = Tools.AppService.getSelf();

	product.$hasAccess = Tools.FeatureHelper.hasSoftDeployAccess('BACKEND_PRODUCT_ACCESS')
		? product.userUsable
		: hasRoleAccess(self, product);

	return product;
};

export default parseProduct;
