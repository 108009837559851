import DatePreset from 'App/upsales/common/services/datePresetService';

export const newFilter = () => ({
	inactive: true,
	value: {
		has: true,
		Status: { value: null },
		Outcome: { value: null },
		Date: { value: { start: null, end: null, preset: 'whenever' } }
	}
});

const taskFilter = {
	generate: newFilter,
	isInactive: filter => filter.inactive,
	getText: (val, inactive) => {
		var num = 0;

		if (inactive !== true) {
			num++;
		}

		if (!val) {
			return num;
		}

		if (val.Date && (val.Date.value.start || val.Date.value.end || val.Date.value.preset !== 'whenever')) {
			num++;
		}
		if (val.has) {
			num++;
		}
		if (val.Status.value) {
			num++;
		}
		if (val.Outcome.value) {
			num++;
		}
		return num;
	},
	toUrl: filter => {
		const f = { v: {} };
		const val = filter.value;

		f.v.has = val.has;
		f.i = filter.inactive;

		if (val.Status.value) {
			f.v.st = val.Status.value;
		}
		if (val.Outcome.value) {
			f.v.ot = val.Outcome.value;
		}
		if (val.Date && (val.Date.value.start || val.Date.value.end || val.Date.value.preset !== 'whenever')) {
			f.v.regDa = { p: val.Date.value.preset, s: val.Date.value.start, e: val.Date.value.end };
		}

		return f;
	},
	fromUrl: rawFilter => {
		const filter = newFilter();
		filter.inactive = false;
		const val = rawFilter.v;

		filter.value.has = val.has;

		if (rawFilter.i) {
			filter.inactive = rawFilter.i;
		}
		if (val.st) {
			filter.value.Status = { value: val.st };
		}
		if (val.ot) {
			filter.value.Outcome = { value: val.ot };
		}
		if (val.regDa) {
			filter.value.Date = {
				value: {
					start: val.regDa.s ? new Date(val.regDa.s) : val.regDa.s,
					end: val.regDa.e ? new Date(val.regDa.e) : val.regDa.e,
					preset: val.regDa.p
				}
			};
		}

		return filter;
	},
	build:
		(options = {}) =>
		(filter, rb, filterHelperGetField, useTags) => {
			const val = filter.value;

			const getField = options.fieldPrefix
				? field => filterHelperGetField([options.fieldPrefix, field].join('.'))
				: filterHelperGetField;

			if (filter.inactive) {
				return;
			}

			const activityFilters = rb.groupBuilder();
			const todoTypes = Tools.AppService.getTodoTypes();
			const activityType = options.phonecall ? todoTypes.PHONE_CALL.id : todoTypes.TODO.id;

			activityFilters.addFilter(getField('activity.activityType.id'), rb.comparisonTypes.Equals, activityType);

			if (val.has) {
				activityFilters.addFilter(getField('activity.id'), rb.comparisonTypes.NotEquals, null);
			} else {
				activityFilters.isNotFilter();
			}

			if (val.Status.value) {
				switch (val.Status.value) {
					case 'open':
						activityFilters.addFilter(getField('activity.closeDate'), rb.comparisonTypes.Equals, null);
						break;
					case 'closed':
						activityFilters.addFilter(getField('activity.closeDate'), rb.comparisonTypes.NotEquals, null);
						break;
				}
			}

			if (options.phonecall && val.Outcome.value) {
				switch (val.Outcome.value) {
					case 'reached':
						activityFilters.addFilter(
							getField('activity.lastOutcome.type'),
							rb.comparisonTypes.Equals,
							'reached'
						);
						break;
					case 'not_reached':
						activityFilters.addFilter(
							getField('activity.lastOutcome.type'),
							rb.comparisonTypes.Equals,
							'not_reached'
						);
						break;
				}
			}

			if (val.Date.value.preset !== 'whenever') {
				const dates = DatePreset.getDatesFromValue(val.Date.value, useTags);
				// start
				if (dates.start) {
					activityFilters.addFilter(
						getField('activity.date'),
						rb.comparisonTypes.GreaterThanEquals,
						dates.start
					);
				}
				// end
				if (dates.end) {
					activityFilters.addFilter(getField('activity.date'), rb.comparisonTypes.LowerThanEquals, dates.end);
				}
			}

			activityFilters.done();
		}
};

export default taskFilter;
