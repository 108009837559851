import {
	AssistChip,
	Block,
	Button,
	Card,
	CardHeader,
	Flex,
	Headline,
	Help,
	Icon,
	Link,
	Table,
	TableColumn,
	TableHeader,
	TableRow,
	Text,
	Toggle,
	Tooltip
} from '@upsales/components';
import './TaskSettings.scss';
import { ProjectPlanTemplate, TaskTemplate } from 'App/resources/Model/ProjectPlanTemplate';
import { replaceItem, removeItem } from 'App/babel/store/helpers/array';
import InlineConfirm from 'Components/Dialogs/InlineConfirm';
import openModal from 'App/services/Modal';
import React, { useMemo, ComponentProps } from 'react';
import T from 'Components/Helpers/translate';
import UserStack from 'App/components/UserStack';
import { useSoftDeployAccess } from 'App/components/hooks/featureHelper';
import BemClass from '@upsales/components/Utils/bemClass';

type BlockProps = ComponentProps<typeof Block>;

type Props = BlockProps & {
	projectPlanTemplate: ProjectPlanTemplate;
	onTasksChange: (taskTemplates: TaskTemplate[]) => void;
	onOffSetEndDateChange: (offsetEndDate: boolean) => void;
};

const RoleStack = ({ roles }: { roles: TaskTemplate['roles'] }) => {
	const maxNumberOfRoles = 1;
	const tooltip = roles
		.slice(maxNumberOfRoles, roles.length)
		.map(role => role.name)
		.join(', ');
	return (
		<Flex>
			{roles.slice(0, maxNumberOfRoles).map(role => (
				<AssistChip key={role.id} title={role.name} space="mrs" />
			))}
			{roles.length > maxNumberOfRoles ? (
				<Tooltip title={tooltip}>
					<Text>{`+${roles.length - maxNumberOfRoles}`}</Text>
				</Tooltip>
			) : null}
		</Flex>
	);
};

const TaskSettings = ({ projectPlanTemplate, onTasksChange, onOffSetEndDateChange, ...blockProps }: Props) => {
	const classes = new BemClass('TaskSettings');
	const hasRecurringTaskFeature = useSoftDeployAccess('RECURRING_TASKS');

	const taskSettingsHeadline =
		projectPlanTemplate.products.length === 1
			? T('admin.projectPlan.taskSettings.headline', { productName: projectPlanTemplate.products[0].name })
			: projectPlanTemplate.products.length > 1
			? T('admin.projectPlan.taskSettings.headlineMultipleProducts')
			: T('admin.projectPlan.taskSettings.headlineNoProducts');

	const lang = useMemo(
		() => ({
			active: T('admin.active'),
			addTask: T('admin.projectPlan.taskSettings.addTask'),
			cardHeader: T('default.tasks'),
			inactive: T('default.inactive'),
			none: T('default.none'),
			readMore: T('default.readMore'),
			createFirstTask: T('admin.taskSettings.createFirstTask'),
			recurringTask: T('activity.markAsRecurring'),
			status: T('default.status'),
			tasksCalculation: T('admin.projectPlan.taskSettings.tasksCalculation'),
			taskDueDaysStart: T('admin.projectPlan.taskSettings.taskDueStartDate'),
			taskDueDaysEnd: T('admin.projectPlan.taskSettings.taskDueEndDate'),
			taskSettingsDescription: T('admin.projectPlan.taskSettings.description'),
			taskSettingsHeadline: taskSettingsHeadline,
			title: T('default.title'),
			usersRoles: T('admin.projectPlan.taskSettings.usersRoles'),
			deleteTask: T('admin.projectPlan.taskSettings.deleteTask')
		}),
		[]
	);

	function onTaskChange(taskTemplate: TaskTemplate, index: number) {
		const updatedTaskTemplates = replaceItem(projectPlanTemplate.taskTemplates, index, taskTemplate);
		onTasksChange(updatedTaskTemplates);
	}

	function onRemoveTask(index: number) {
		const updatedTaskTemplates = removeItem(projectPlanTemplate.taskTemplates, index);
		onTasksChange(updatedTaskTemplates);
	}

	function editTask(taskTemplate: TaskTemplate, index: number) {
		openModal('EditTaskTemplate', {
			taskTemplate,
			onClose: (taskTemplate: TaskTemplate | null) => {
				if (taskTemplate) {
					onTaskChange(taskTemplate, index);
				}
			},
			offsetEndDate: projectPlanTemplate.offsetEndDate
		});
	}

	function createNewTask() {
		openModal('EditTaskTemplate', {
			taskTemplate: null,
			onClose: (taskTemplate: TaskTemplate | null) => {
				if (taskTemplate) {
					const updatedTaskTemplates = [...projectPlanTemplate.taskTemplates, taskTemplate];
					onTasksChange(updatedTaskTemplates);
				}
			},
			offsetEndDate: projectPlanTemplate.offsetEndDate
		});
	}

	const getRowCustom = (taskTemplate: TaskTemplate, index: number) => {
		return (
			<TableRow
				key={taskTemplate.id ?? taskTemplate.tempId}
				inactive={!taskTemplate.active}
				onClick={() => editTask(taskTemplate, index)}
			>
				<TableColumn>
					<Text>{taskTemplate.name}</Text>
				</TableColumn>
				<TableColumn>
					<Flex alignItems="center">
						<Text space="mrm">{T('todo.editPhonecall.days', { days: taskTemplate.startDateOffset })}</Text>
						{taskTemplate.isRecurring && hasRecurringTaskFeature ? (
							<AssistChip icon="repeat" title={lang.recurringTask}></AssistChip>
						) : null}
					</Flex>
				</TableColumn>
				<TableColumn>
					{taskTemplate.roles.length ? <RoleStack roles={taskTemplate.roles} /> : null}
					{taskTemplate.users.length ? <UserStack users={taskTemplate.users} /> : null}
					{!taskTemplate.roles.length && !taskTemplate.users.length ? (
						<Text italic title={lang.none} />
					) : null}
				</TableColumn>
				<TableColumn>
					<Flex
						onClick={(e: React.MouseEvent<HTMLElement>) => {
							e.stopPropagation();
							onTaskChange({ ...taskTemplate, active: !taskTemplate.active }, index);
						}}
					>
						<Toggle checked={taskTemplate.active} />
						<Text space="mlm">{taskTemplate.active ? lang.active : lang.inactive}</Text>
					</Flex>
				</TableColumn>
				<TableColumn align="right">
					<InlineConfirm entity={lang.deleteTask} show onConfirm={() => onRemoveTask(index)}>
						<Button type="link" color="grey" size="md">
							<Icon name="trash" />
						</Button>
					</InlineConfirm>
				</TableColumn>
			</TableRow>
		);
	};

	const columns = [
		{ title: lang.title },
		{ title: projectPlanTemplate.offsetEndDate ? lang.taskDueDaysEnd : lang.taskDueDaysStart },
		{ title: lang.usersRoles },
		{ title: lang.status },
		{ title: '' }
	];

	return (
		<Block space="ptxl pbxl" className={classes.b()} {...blockProps}>
			<Headline>{lang.taskSettingsHeadline}</Headline>
			<Block space="mtl">
				<Text size="lg">
					{lang.taskSettingsDescription}
					<Flex inline>
						<Help articleId={1484} sidebar>
							<Text space="mls" size="lg" color="medium-blue">
								{lang.readMore}
							</Text>
						</Help>
					</Flex>
				</Text>
			</Block>
			<Flex alignItems="center" space="mtxl">
				<Toggle size="lg" checked={projectPlanTemplate.offsetEndDate} onChange={onOffSetEndDateChange} />
				<Text size="lg" space="mlm">
					{lang.tasksCalculation}
				</Text>
				<Help articleId={1501} sidebar>
					<Link>
						<Icon color="black" space="mlm" name="question-circle"></Icon>
					</Link>
				</Help>
			</Flex>

			{projectPlanTemplate.taskTemplates.length === 0 ? (
				<Card space="mtxl" color={'light-grey'}>
					<Flex space="plxl" justifyContent="space-between" alignItems="center">
						<Block>
							<Text space="mbxl" size="xl">
								{lang.createFirstTask}
							</Text>
							<Button onClick={() => createNewTask()}>
								<Icon space="mrm" name="plus" />
								{lang.addTask}
							</Button>
						</Block>
						<Block>
							<img src={'img/upsales-todo.gif'} alt="todo-gif" />
						</Block>
					</Flex>
				</Card>
			) : (
				<Card space="mtxl">
					<CardHeader title={lang.cardHeader}>
						<Button onClick={() => createNewTask()}>
							<Icon space="mrm" name="plus" />
							{lang.addTask}
						</Button>
					</CardHeader>
					<Table>
						<TableHeader columns={columns}></TableHeader>
						{projectPlanTemplate.taskTemplates.map((row, index) => getRowCustom(row, index))}
					</Table>
				</Card>
			)}
		</Block>
	);
};

export default TaskSettings;
