import { MailResourceSave, MailResourceSendTest } from 'App/resources/Model/Mail';
import Resource from './Resource';
class Mail extends Resource {
	eventName: 'mail';
	notifications: { [key: string]: () => { [key: string]: string } };

	constructor() {
		super('mail');
		this.eventName = 'mail';
		this.notifications = {
			test: () => ({
				title: 'mail.sent',
				body: 'mail.testSent',
				style: 'success',
				icon: 'check',
				type: 'body'
			}),
			testError: () => ({
				title: 'default.error',
				body: 'saveError.testMail',
				style: 'error',
				icon: 'times',
				type: 'body'
			}),
			delete: () => ({
				title: 'default.deleted',
				body: 'deleted.mail',
				style: 'success',
				icon: 'check',
				type: 'body'
			})
		};
	}

	save(data: MailResourceSave, opts = {}) {
		return super.save(data, opts);
	}
	async sendTest(data: { mail: MailResourceSendTest }, opts = {}) {
		const mappedData = this._map(data);
		const res = await this._postRequest('test', mappedData, { ...opts, methodName: 'test' });
		return { ...res.data, data: this._parse.bind(this)(res.data.data) };
	}
}
export default new Mail();
