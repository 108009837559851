import Resource from './Resource';
import Attributes from 'Attributes/CompetitorAttributes';

export interface Competitor {
	id: number;
	name: string;
	orderCount?: number;
}

class CompetitorResource extends Resource {
	constructor() {
		super('competitors', Attributes);
	}

	mergeCompetitors(competitorIdsToRemove: number[], replacementCompetitorId: number) {
		return this._postRequest(`merge/${replacementCompetitorId}/with/${competitorIdsToRemove}`);
	}
}

const resource = new CompetitorResource();

export default resource;
