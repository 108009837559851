import React, { CSSProperties } from 'react';
import { Text } from '@upsales/components';
import bemClass from '@upsales/components/Utils/bemClass';
import './ColumnChartWidgetScale.scss';
import formatWidgetValue from '../../formatWidgetValue';
import { Datatypes } from 'Resources/ReportWidget';
import { getTickPercentages } from '../../chartHelper';

type PropsShowValue = {
	ticks?: number[];
	zeroTickIndex?: number;
	scaleMin?: number;
	showValues?: boolean;
	dataType?: keyof typeof Datatypes;
	currency?: string;
	showExtraTick?: boolean;
};

function ColumnChartWidgetScale({
	ticks = [],
	zeroTickIndex = 0,
	scaleMin = 0,
	showValues = false,
	dataType = 'number',
	currency = undefined,
	showExtraTick = false
}: PropsShowValue) {
	const classes = new bemClass('ColumnChartWidgetScale');
	const tickPercentages = getTickPercentages(ticks);
	const isFirstOrLastTick = (tickIndex: number) =>
		tickIndex === 0 || (!showExtraTick && tickIndex === ticks.length - 1);

	//#region sub-render functions
	const renderValue = (i: number, value: number, bottomStyling: CSSProperties) => {
		return (
			<Text key={i} size="sm" color="grey-10" className={classes.elem('tick-text').b()} style={bottomStyling}>
				{formatWidgetValue(value, dataType, currency)}
			</Text>
		);
	};

	const renderTick = (i: number, bottomStyling: CSSProperties) => {
		return (
			<div
				key={i}
				className={classes.elem(zeroTickIndex === i ? 'tick-line-thick' : 'tick-line').b()}
				style={bottomStyling}
			/>
		);
	};
	//#endregion

	return (
		<div className={classes.mod({ 'has-negative-values': scaleMin < 0 }).b()}>
			{ticks.map((value, i) => {
				if (isFirstOrLastTick(i)) {
					return null;
				}
				const bottomStyling = { bottom: `${tickPercentages[i]}%` };
				return showValues ? renderValue(i, value, bottomStyling) : renderTick(i, bottomStyling);
			})}
		</div>
	);
}

export default ColumnChartWidgetScale;
