import SubscriptionIndexingState, { State } from '../SubscriptionIndexingState/SubscriptionIndexingState';
import AgreementResource from 'Resources/Agreement';
import SubscriptionList from './SubscriptionList';
import React, { useCallback } from 'react';
import RequestBuilder from 'Resources/RequestBuilder';
import { useSoftDeployAccess } from 'App/components/hooks';
import Agreement from 'App/resources/Model/Agreement';
import { Block, Title } from '@upsales/components';
import { useTranslation } from 'Components/Helpers/translate';
import { Action } from '../SubscriptionIndexingState/SubscriptionIndexingActions';

type Props = {
	state: State;
	dispatch: React.Dispatch<Action>;
	setConflictDecision: (agreement: Agreement, decision: string) => void;
	indexConflictDecisionsMap: State['indexConflictDecisionsMap'];
};

const SubscriptionListConflict = ({ state, dispatch, indexConflictDecisionsMap }: Props) => {
	const { id, filter } = state;

	const { t } = useTranslation();
	const { excludeSubscriptionId, includeSubscriptionId } = SubscriptionIndexingState(dispatch);
	const conflictCount = Object.keys(indexConflictDecisionsMap).length;

	const hasConflictHandling = useSoftDeployAccess('INDEXING_CONFLICT_HANDLING');

	const getData = useCallback(
		async (rb: RequestBuilder) => {
			const { sort } = rb.build();
			const subFilter = { ...filter, sort };
			subFilter.limit = rb?.limit ?? 25;
			subFilter.offset = rb?.offset ?? 0;

			return AgreementResource.getAgreementsWithConflicts(subFilter, id);
		},
		[filter]
	);

	if (!hasConflictHandling) return null;

	const columns = ['info', 'lastIndexIncreaseDate', 'increasedPeriodValue', 'exclude'];

	return (
		<>
			{conflictCount ? (
				<Block space="ptxl pbm">
					<Title>
						{t(`subscription.indexing.summary.conflicts.description`, {
							count: conflictCount
						})}
					</Title>
				</Block>
			) : null}
			<SubscriptionList
				indexConflictDecisionsMap={indexConflictDecisionsMap}
				includeOrExcludeSub={(type, id) =>
					type === 'exclude' ? excludeSubscriptionId(id) : includeSubscriptionId(id)
				}
				tableLimitOptions={[5, 25]}
				state={state}
				getData={getData}
				columns={columns}
			/>
		</>
	);
};

export default SubscriptionListConflict;
