import { Component } from 'react';
import PropTypes from 'prop-types';
import T from 'Components/Helpers/translate';
class UpActivityTypes extends Component {
	static propTypes = {
		multiple: PropTypes.bool,
		required: PropTypes.bool,
		disabled: PropTypes.bool,
		onChange: PropTypes.func.isRequired,
		entity: PropTypes.string,
		placeholder: PropTypes.string,
		className: PropTypes.string,
		value: PropTypes.any,
		data: PropTypes.any
	};

	componentDidMount() {
		const ActivityType = Tools.ActivityType;
		const opts = {
			multiple: this.props.multiple || false,
			required: this.props.required || false,
			disabled: this.props.disabled || false,
			ajax: false,
			asIds: false,
			onChange: this.props.onChange,
			idAttr: ActivityType.attr.id,
			titleAttr: ActivityType.attr.name,
			sorting: { field: ActivityType.attr.name, ascending: true },
			data: () => {
				const customActivityTypes = _.sortByOrder(
					Tools.AppService.getActivityTypes(this.props.entity, true),
					'name'
				);
				const regularTypes = _.sortByOrder(_.values(Tools.AppService.getTodoTypes()), 'name');
				if (Tools.FeatureHelper.hasSoftDeployAccess('TODO_LIST') && regularTypes.length) {
					const data = [];
					if (this.props.entity !== 'appointment') {
						data.push({
							name: T(
								Tools.FeatureHelper.hasSoftDeployAccess('REMOVE_ACTIVITIES')
									? 'default.upsalesTaskTypes'
									: 'default.upsalesActivityTypes'
							),
							children: regularTypes
						});
					}
					if (customActivityTypes.length) {
						data.push({
							name: T(
								this.props.entity !== 'appointment'
									? 'default.customActivityTypes'
									: 'default.appointmentTypes'
							),
							children: customActivityTypes
						});
					}
					return data;
				} else {
					return customActivityTypes;
				}
			},
			placeholder: this.props.placeholder || ''
		};
		if (this.props.data) {
			opts.data = undefined;
			opts.select2 = {};
			opts.select2.ajax = {
				data: function (term) {
					return term;
				},
				transport: query => {
					let data = this.props.data;
					if (query.data && query.data.length) {
						data = _.filter(this.props.data, function (item) {
							return item.name.toLowerCase().indexOf(query.data.toLowerCase()) !== -1;
						});
					}

					return query.success(data);
				},
				results: function (data) {
					return { results: data };
				}
			};
		}
		const input = jQuery(this._input);
		ReactTemplates.TOOLS.selectHelper.getSelect2Options(this, opts, input, options => {
			input.select2(options);
		});
	}
	componentDidUpdate() {
		ReactTemplates.TOOLS.selectHelper.updateValue.call(this);
	}
	render() {
		return ReactTemplates.TOOLS.selectHelper.getInputComponent(this);
	}
}
export default UpActivityTypes;
