import React from 'react';
import { Card, Button, Text, Icon, Row, Column } from '@upsales/components';
import BemClass from '@upsales/components/Utils/bemClass';
import CompoundingTimer from './CompoundingTimer';
import './EditPhonecallCallBlock.scss';
import T from 'Components/Helpers/translate';
import Contact from 'App/resources/Model/Contact';
import Client from 'App/resources/Model/Client';
interface CallData {
	callState: string;
	callStart: number;
	calleeInfo: { number: number; contact?: { name: string }; client?: { name: string } };
	contact: Contact;
	client: Client;
	number: number;
}
interface State {
	callState: string;
	phone: number | null;
	contactName: string;
	callStart: null | number;
}
class EditPhonecallCallBlock extends React.Component<{}, State> {
	unsubCallInfoChange?: ReturnType<typeof Tools.$rootScope.$on> = undefined;
	unsubOpenVoice?: ReturnType<typeof Tools.$rootScope.$on> = undefined;
	constructor(props: {}) {
		super(props);
		this.state = {
			callState: '',
			phone: null,
			contactName: '',
			callStart: null
		};
	}

	componentDidMount() {
		if (Tools.VoiceService.callInProgress()) {
			const callInfo = Tools.VoiceService.getCallInfo();
			this.setState({
				callState: callInfo.callState,
				phone: callInfo.calleeInfo?.number,
				contactName: callInfo.calleeInfo?.contact?.name || callInfo?.calleeInfo?.client?.name || '',
				callStart: callInfo.callStart
			});
		}
		//When state of call changes (idle, dialing, inCall) or call timestart is set
		this.unsubCallInfoChange = Tools.$rootScope.$on('callInfoChange', this.onCallInfoChange);
		this.unsubOpenVoice = Tools.$rootScope.$on('openVoice', this.onOpenVoice);
	}

	componentWillUnmount() {
		if (this.unsubOpenVoice) {
			this.unsubOpenVoice();
		}
		if (this.unsubCallInfoChange) {
			this.unsubCallInfoChange();
		}
	}
	onOpenVoice = (e: unknown, data: CallData) => {
		const { contact, client, number } = data;
		this.setState({
			phone: number,
			contactName: contact?.name || client?.name || '',
			callState: 'dialing'
		});
	};
	onCallInfoChange = (e: unknown, data: CallData) => {
		const { callState, callStart, calleeInfo } = data;
		this.setState({
			callState,
			callStart,
			phone: calleeInfo?.number,
			contactName: calleeInfo?.contact?.name || calleeInfo?.client?.name || ''
		});
	};

	hangUp = () => {
		Tools.VoiceService.hangUp();
	};
	render() {
		const { callState, contactName, phone, callStart } = this.state;
		let callContent;
		if (callState === 'dialing') {
			callContent = (
				<React.Fragment>
					<Text bold>{`${T('todo.calling')} ${contactName}`}</Text>
					<Text color="grey-10">{phone}</Text>
				</React.Fragment>
			);
		} else if (callState === 'inCall') {
			callContent = (
				<React.Fragment>
					<Text bold>{`${T('todo.connectedTo')} ${contactName}`}</Text>
					<Text color="bright-blue">
						{callStart ? <CompoundingTimer startTime={callStart} /> : null}
						{phone ? ` | ${phone}` : null}
					</Text>
				</React.Fragment>
			);
		}
		const classes = new BemClass('EditPhonecallCallBlock');
		if (callState && callState !== 'idle') {
			return (
				<Card
					className={classes.b()}
					border="s"
					borderColor={callState === 'inCall' ? 'bright-blue' : 'grey-5'}
					color={callState === 'inCall' ? 'super-light-blue' : 'grey-1'}
					space="ptl pll pbl prl"
				>
					<Row>
						<Column size={8}>{callContent}</Column>
						<Column>
							<Button onClick={this.hangUp} color="red">
								<Icon name="phone" color="white" />
								{T('voice.hungUp')}
							</Button>
						</Column>
					</Row>
				</Card>
			);
		}
		return null;
	}
}

export default EditPhonecallCallBlock;
