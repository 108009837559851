import React, { ComponentProps, ReactNode, useEffect, useRef, useState } from 'react';
import HistoryLog from '../HistoryLog';
import { EVENT_TIMELINE_ENTITY_TYPES, EVENT_TIMELINE_SUB_TYPES } from 'App/babel/enum/timeline';
import { getTimelineRow } from '../../../babel/components/Helpers/getTimelineRow';
import moment from 'moment';
import T from 'Components/Helpers/translate';
import usePartialLoader, { FetcherFunction } from 'App/components/hooks/usePartialLoader';
import ComparisonTypes from 'Resources/ComparisonTypes';
import TimelineGenericRender, {
	TimelineComponentProps,
	TimelineConfig
} from 'Components/TimelineRow/TimelineGeneric/TimelineCardConfigs';
import Client from 'App/resources/Model/Client';
import type { HistoryLogRenderedBy } from 'App/components/HistoryLog/ModalHistoryLog';
import Event from 'App/resources/Model/Event';
import TimelineLoadMore from 'Components/TimelineRow/TimelineLoadMore/TimelineLoadMore';
import RequestBuilder from 'Resources/RequestBuilder';
import { makeCancelable } from 'Helpers/promise';
import HistoryLogFilterButtons from '../HistoryLogFilterButtons';
import NotesFilterButton from 'App/components/NotesFilter/NotesFilterButton';

const historyTypes = ['all', 'activities', 'order', 'appointments', 'market', 'opportunities', 'signals'] as const;
type HistoryType = (typeof historyTypes)[number];
type HistoryTypeCounts = { [k in HistoryType]?: number };

const listeners = [
	'activity.added',
	'activity.deleted',
	'activity.updated',
	'appointment.added',
	'appointment.deleted',
	'appointment.updated',
	'comment.added',
	'comment.deleted',
	'comment.updated',
	'clientPlan.added',
	'clientPlan.updated'
];

const fetcher: FetcherFunction<{ client: Client; historyType?: HistoryType; onlyMetadata?: boolean }, Event> = (
	filters,
	{ client, historyType, onlyMetadata = false }
) => {
	const event = Tools.Event;

	const historyTypeFilters: Record<string, () => void> = {
		activities: () => filters.addFilter(event.attr.entityType, ComparisonTypes.Equals, ['Activity']),
		order: () => filters.addFilter(event.attr.entityType, ComparisonTypes.Equals, ['Order', 'Agreement']),
		appointments: () => filters.addFilter(event.attr.entityType, ComparisonTypes.Equals, ['Appointment']),
		market: () => filters.addFilter({ field: 'feedMarket' }, ComparisonTypes.Equals, null),
		opportunities: () => filters.addFilter(event.attr.entityType, ComparisonTypes.Equals, ['Opportunity']),
		signals: () => filters.addFilter(event.attr.entityType, ComparisonTypes.Equals, ['News', 'Signals'])
	};

	filters.addFilter(event.attr.clientId, ComparisonTypes.Equals, client.id);
	filters.addFilter(event.attr.date, ComparisonTypes.LessThanEquals, moment().format());

	if (historyType !== 'market') {
		filters.addFilter(event.attr.feed, ComparisonTypes.Equals, null);
		filters.addFilter(event.attr.entityType, ComparisonTypes.NotEquals, ['Client']);
	}

	if (historyType && historyType in historyTypeFilters) {
		historyTypeFilters[historyType]?.();
	}

	filters.addSort(event.attr.date, false);
	filters.addSort(event.attr.entityType, false);

	if (onlyMetadata) {
		filters.limit = 0;
	}

	return event.find(filters.build());
};

type Props = {
	client: Client;
	notesFilterActive?: boolean;
	renderedBy: HistoryLogRenderedBy;
	cardAdditionalProps?: Partial<TimelineComponentProps>;
} & PartialPick<ComponentProps<typeof HistoryLog>, 'selectButtons'>;

const ClientHistoryLog = ({ client, notesFilterActive = true, renderedBy, cardAdditionalProps, ...props }: Props) => {
	const [historyType, setHistoryType] = useState<HistoryType>('all');
	const [metadata, setMetadata] = useState<HistoryTypeCounts>({ all: 0 });

	const {
		data: events,
		total: totalEvents,
		loading,
		loadMore,
		reset
	} = usePartialLoader({
		fetcher,
		broadcastTypes: listeners,
		fetcherProps: {
			client,
			historyType
		}
	});

	const containerRef = useRef<HTMLDivElement>(null);

	const filterButtons = [
		{ title: T('all'), value: 'all' },
		{ title: T('default.activities'), value: 'activities', icon: 'activity' },
		{ title: T('default.order'), value: 'order', icon: 'dollar' },
		{ title: T('default.appointments'), value: 'appointments', icon: 'calendar' },
		{ title: T('default.market'), value: 'market', icon: 'bullhorn' },
		{ title: T('default.opportunities'), value: 'opportunities', icon: 'opportunity' },
		{ title: T('default.signals'), value: 'signals', icon: 'signal' }
	] as const;

	const getClientTimeline = (event: Event, isLastElem: boolean = false) => {
		const entityType = event.entityType || '';
		const subType = event.subType || '';

		if (entityType === EVENT_TIMELINE_ENTITY_TYPES.CONTACT) {
			switch (subType.toLowerCase()) {
				case EVENT_TIMELINE_SUB_TYPES.CREATED:
					return (
						<TimelineGenericRender
							type={TimelineConfig.AddedContact}
							event={event}
							includeIcon
							hideLine={isLastElem}
							{...cardAdditionalProps}
						/>
					);
				default:
					return null;
			}
		}

		return getTimelineRow(event, 'Client', notesFilterActive, isLastElem, renderedBy, cardAdditionalProps);
	};

	useEffect(() => {
		if (renderedBy.type !== 'company') {
			return;
		}
		const promise = makeCancelable(
			Promise.all(
				historyTypes.map(async type => {
					return fetcher(new RequestBuilder(), { client, historyType: type, onlyMetadata: true }).then(
						res => {
							return { type, count: res.metadata.total };
						}
					);
				})
			).then(res => {
				const metadata = res.reduce((acc, cur) => {
					acc[cur.type] = cur.count;
					return acc;
				}, {} as Record<HistoryType, number>);
				setMetadata(metadata);
			})
		);

		return () => {
			promise.cancel();
		};
	}, []);

	useEffect(() => {
		reset();
	}, [historyType]);

	if (!client || !client.id) {
		return null;
	}

	let timelineElements: ReactNode[] = [];
	if (events && events.length) {
		timelineElements = events.map(event => {
			return event ? <div key={event.id}>{getClientTimeline(event)}</div> : null;
		});
	}

	const clientRegEvent = {
		entityType: EVENT_TIMELINE_ENTITY_TYPES.CLIENT,
		subType: EVENT_TIMELINE_SUB_TYPES.CREATED,
		entityId: client.id,
		users: client.regBy ? [client.regBy] : [],
		date: client.regDate
	};

	return (
		<div className="HistoryLog" ref={containerRef}>
			<HistoryLog
				title={T('history')}
				loading={loading}
				{...props}
				selectButtons={
					renderedBy.type === 'company' ? (
						<>
							<NotesFilterButton />
							<HistoryLogFilterButtons
								buttons={filterButtons}
								containerRef={containerRef}
								metadata={metadata}
								historyType={historyType}
								setHistoryType={setHistoryType}
							/>
						</>
					) : (
						props.selectButtons
					)
				}
			>
				{timelineElements}
				{timelineElements.length < totalEvents ? (
					<TimelineLoadMore
						newEventsLoading={loading}
						numEventsLoaded={events.length}
						totalEvents={totalEvents ? totalEvents : null}
						incrementOffset={loadMore}
					/>
				) : null}
				<TimelineGenericRender
					type={TimelineConfig.AddedClient}
					event={clientRegEvent}
					includeIcon
					hideLine
					{...cardAdditionalProps}
				/>
			</HistoryLog>
		</div>
	);
};

export default ClientHistoryLog;
