import Tracker from './Tracker';

const salesCoachTracker = new Tracker('salesCoach', {
	ACTIVATED_BUDGET: 'activatedBudget',
	SAVED_SALES_COACH: 'savedSalesCoach',
	DEACTIVATED_BUDGET: 'deactivatedBudget',
	ACTIVATED_SOLUTION: 'activatedSolution',
	ACTIVATED_TIMEFRAME: 'activatedTimeframe',
	ADDED_TITLE_CATEGORY: 'addedTitleCategory',
	DEACTIVATED_SOLUTION: 'deactivatedSolution',
	DEACTIVATED_TIMEFRAME: 'deactivatedTimeframe',
	ACTIVATED_DECISION_MAKER: 'activatedDecisionMaker',
	DEACTIVATED_DECISION_MAKER: 'deactivatedDecisionMaker'
});

export default salesCoachTracker;
