import React from 'react';
import { Block, Icon } from '@upsales/components';
import bemClass from '@upsales/components/Utils/bemClass';
import './ColumnChartWidgetBar.scss';
import { Pulse } from 'App/components/animations';

import { RCWidgetDataSubRow } from 'App/babel/resources/ReportWidget';

type Props = {
	negative?: boolean;
	height?: string;
	bottom?: string;
	left?: string;
	progress?: boolean;
	goal?: boolean;
	color?: string;
	loading?: boolean;
	backgroundColor?: string;
	maxValue?: number;
	cornerRounding?: { roundedTop: boolean; roundedBottom: boolean; radius: number };
	thinMode?: boolean;
	onClick?: React.MouseEventHandler<HTMLDivElement>;
	striped?: boolean;
	icons?: RCWidgetDataSubRow['icons'];
};

const ColumnChartWidgetBar = ({
	negative = false,
	loading = false,
	height,
	progress = false,
	goal = false,
	backgroundColor,
	color = 'green',
	bottom,
	left,
	cornerRounding = { roundedTop: false, roundedBottom: false, radius: 4 },
	thinMode,
	onClick,
	striped,
	icons
}: Props) => {
	const classes = new bemClass('ColumnChartWidgetBar').mod({ thinMode });
	return (
		<Pulse disabled={!loading}>
			<Block
				className={classes.mod({ loading, progress, goal, negative }).b()}
				backgroundColor={color}
				striped={striped}
				style={{
					height,
					backgroundColor,
					bottom,
					left,
					borderTopLeftRadius: `${cornerRounding.roundedTop ? cornerRounding.radius : 0}px`,
					borderTopRightRadius: `${cornerRounding.roundedTop ? cornerRounding.radius : 0}px`,
					borderBottomRightRadius: `${cornerRounding.roundedBottom ? cornerRounding.radius : 0}px`,
					borderBottomLeftRadius: `${cornerRounding.roundedBottom ? cornerRounding.radius : 0}px`
				}}
				onClick={onClick}
			>
				{icons ? (
					<Block className={classes.elem('icons').b()}>
						{icons.map((icon, i) => (
							<Block
								key={'ColumnChartWidgetBar__icon' + icon.key}
								className={classes.elem('iconWrapper').b()}
								backgroundColor={icon.backgroundColor}
								style={{ transform: `translatey(50%) translatex(${-25 * i}%)` }}
							>
								<Icon
									className={classes.elem('icon').b()}
									name={icon.name}
									color={icon.colorVariable || 'white'}
								/>
							</Block>
						))}
					</Block>
				) : undefined}
			</Block>
		</Pulse>
	);
};

export default ColumnChartWidgetBar;
