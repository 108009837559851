import Resource from 'Resources/ResourceTyped';
import type TicketResponseTemplateModel from './Model/TicketResponseTemplate';

class TicketResponseTemplate extends Resource<TicketResponseTemplateModel> {
	eventName = 'ticketResponseTemplate';
	constructor() {
		super('ticketResponseTemplates');

		this.notifications = {
			save: () => ({
				title: 'default.saved',
				body: 'saved.mailTemplate',
				style: 'success',
				icon: 'check',
				type: 'body'
			}),
			saveError: data => ({
				title: 'default.error',
				body: 'saveError.mailTemplate',
				style: 'error',
				icon: 'times',
				type: 'body',
				error: data?.error
			}),
			delete: () => ({
				title: 'default.deleted',
				body: 'deleted.mailTemplate',
				style: 'success',
				icon: 'check',
				type: 'body'
			}),
			deleteError: data => ({
				title: 'default.error',
				body: 'deleteError.template',
				style: 'error',
				icon: 'times',
				type: 'body',
				error: data?.error
			})
		};
	}
}

const resource = new TicketResponseTemplate();

export default resource;
