import { PrimaryButton, ThirdButton } from '@upsales/components/Buttons';
import { useTranslation } from 'Components/Helpers/translate';
import BemClass from '@upsales/components/Utils/bemClass';
import { renderToString } from 'react-dom/server';
import React, { useState } from 'react';
import { 
    ModalControls, 
    ModalContent, 
    ModalHeader, 
    WhiteModal, 
    Tooltip, 
    Block,
    Label, 
    Input, 
    Row, 
    Text 
} from '@upsales/components';

import { FormObserverModel } from 'App/components/FormObserver/ValidationModel';
import { ModalProps } from 'App/components/Modals/Modals';
import FormObserver from 'App/components/FormObserver';

import './AskForInput.scss';

type AskForInputProps<T> = ModalProps<T> & {
    formModel: FormObserverModel;
    cancelButtonText?: string;
    saveButtonText?: string;
    className?: string;
    initialValues: T;
    title: string;
    body?: string;
};

const AskForInput = <T extends { [key: string]: any },>({
    cancelButtonText,
    saveButtonText,
    initialValues,
    className,
    formModel,
    close,
    title,
    body,
}: AskForInputProps<T>) => {
    const classes = new BemClass('AskForInput', className);
	const { t } = useTranslation();

    const [validationErrors, setValidationErrors] = useState<(string | null)[]>([]);
    const [saveEnabled, setSaveEnabled] = useState(false);
    const [inputValues, setInputValues] = useState<T>();

    
    const getDisabledTooltip = () => renderToString(
        <ul className={classes.elem('validationErrors').b()}>
            {validationErrors.map((message) => {
                return <li className={classes.elem('validationError').b()} key={message}>{t(message ?? '')}</li>;
            })}
        </ul>
    );

	return (
		<WhiteModal className={classes.b()}>
			<ModalHeader icon="info" title={t(title)} onClose={() => close()} />

			<ModalContent>
                {body ? <Text space="mbl" color="grey-11">{t(body)}</Text> : null}

                <FormObserver<T>
                    initialValues={initialValues}
                    model={formModel}
                    onChange={(values, isValid, errorMessages) => {
                        setValidationErrors(Object.values(errorMessages));
                        setSaveEnabled(isValid);
                        setInputValues(values);
                    }}
                >
                    {({ onFormChange, inputProps }) => {
                        return Object.values(inputProps).map(input => (
                            <Block key={input.label}>
                                <Label>{t(input.label)}</Label>
                                <Input 
                                    {...input} 
                                    onChange={event => onFormChange(input.name, event.target.value)} 
                                />
                            </Block>
                        ));
                    }}
                </FormObserver>
			</ModalContent>

            <ModalControls>
				<Row align="right">
					<Tooltip disabled={saveEnabled} title={getDisabledTooltip()}>
						<PrimaryButton disabled={!saveEnabled} onClick={() => close(inputValues)}>
							{t(saveButtonText || 'default.save')}
						</PrimaryButton>
					</Tooltip>

                    <ThirdButton onClick={() => close(undefined)}>
                        {t(cancelButtonText || 'default.cancel')}
                    </ThirdButton>
				</Row>
			</ModalControls>
		</WhiteModal>
	);
};

export default AskForInput;
