import React, { CSSProperties, memo, useMemo } from 'react';
import BemClass from '@upsales/components/Utils/bemClass';
import { Headline, Text, Title, Select, Icon } from '@upsales/components';
import T from 'Components/Helpers/translate';
import OrderRows from 'App/components/OrderRows';
import SubscriptionPeriodScheduling from './SubscriptionPeriodScheduling';
import SubscriptionSplitScheduling from './SubscriptionSplitScheduling';
import CustomFields from 'App/components/CustomFields';
import { useSelector } from 'App/components/hooks';
import { useSubscriptionGroupContext } from './../Context/SubscriptionGroupContext';
import { SubscriptionCustomFormType } from '../Context/SubscriptionGroupState';
import FormObserver, { mapCustomValuesToArray, getCustomFieldsSetUpValues } from 'App/components/FormObserver';
import SubscriptionHotspot from '../SubscriptionHotspot';
import ErrorMessages from '../ErrorEnum';
import moment from 'moment';

import './SubscriptionPeriod.scss';

import {
	useDisabledFields,
	useSubscriptionEditListener,
	useVisibleFields,
	useUpdateHash
} from 'App/components/hooks/editListener';
import { useCustomFieldOverrides } from 'App/components/hooks/editListener/editListenerHooks';
import { NewSubscriptionTracker } from 'Helpers/Tracker';

interface SubscriptionPeriodProps {
	children?: React.ReactNode;
	isCreatedFromOrder?: boolean;
	uuid?: number; // Is used for scrolling period animation to show old values on the period scrolling away
	dontFetch?: boolean; // So fake scroll component don't do unnecessary fetches
}

const SubscriptionPeriod: React.FC<SubscriptionPeriodProps> = ({ children, isCreatedFromOrder, uuid, dontFetch }) => {
	const classes = new BemClass('SubscriptionPeriod');
	const {
		state: { stage, isEdit, locked, childIndexNr, subscriptionMap },
		currentPeriod: current,
		setOrderRows,
		setCustomFields,
		setCurrency,
		expandPreviewFooter
	} = useSubscriptionGroupContext();
	const customerCurrencies = useSelector(state => state.App?.metadata?.customerCurrencies) || [];

	const currentPeriod = subscriptionMap[uuid ?? 0] ?? current;
	const hasAlreadyStarted = (currentPeriod.orderSequenceNr ?? 0) > 0;
	const isFakeComponent = !!uuid;

	const currencyOptions = useMemo(() => {
		const activeCurrencies = customerCurrencies.filter(c => c.active);

		return activeCurrencies.map(currency => ({
			id: currency.iso,
			title: currency.iso,
			currencyRate: currency.rate
		}));
	}, [customerCurrencies]);

	const {
		uuid: currentUUID,
		currency,
		orderInterval,
		priceList: { id: priceListId },
		locked: periodLocked,
		externallyLocked
	} = currentPeriod;

	const currentPeriodLocked = periodLocked || externallyLocked;

	const orderRowsKey = (uuid ?? currentUUID + currency + orderInterval + priceListId) + '' + childIndexNr;

	const customFields = useSelector(({ App }) => App.customFields);

	useSubscriptionEditListener();
	const isDisabledField = useDisabledFields('order');
	const updatedHash = useUpdateHash(`${currentPeriod.uuid}`);
	const getCustomFieldOverrides = useCustomFieldOverrides();
	const isVisibleField = useVisibleFields('order');

	const visibleAndEditableCustomFields = customFields['order'].filter(field => {
		const fieldIsVisibleFromApp = isVisibleField(`custom.${field.id}`);
		// If the field is hidden from app, it should not be validated
		if (fieldIsVisibleFromApp !== undefined && !fieldIsVisibleFromApp) {
			return false;
		}

		// If the field is visible from app, it should be validated
		const fieldIsVisible = fieldIsVisibleFromApp ?? field.visible;

		return field.$hasAccess && (fieldIsVisible || field.editable);
	});

	const { validatonModel, initialValues } = getCustomFieldsSetUpValues(
		visibleAndEditableCustomFields,
		currentPeriod.custom,
		stage?.id,
		ErrorMessages.OrderCustom,
		currentPeriodLocked
	);

	const hasSplitFlag = Tools.FeatureHelper.hasSoftDeployAccess('SUBSCRIPTION_SPLITS');
	const hasChildren = currentPeriod.children?.length && hasSplitFlag;
	const currentChild = currentPeriod.children?.[childIndexNr];
	const hideSummary = hasChildren && currentChild?.state !== 'default';
	const showInfoBox = hideSummary && !isEdit;

	const currentRows = hasChildren ? currentChild?.orderRow ?? [] : currentPeriod.orderRows;

	const orderRowsDisabled =
		locked ||
		currentPeriodLocked ||
		(isEdit && !!currentChild && moment().isSameOrAfter(currentChild.endDate, 'day'));

	const formObserverKey = currentUUID + (currentPeriodLocked ? 'locked' : 'unlocked');

	const renderOrderRowsInfoBox = () => {
		if (!showInfoBox) return null;

		const infoNr = currentChild?.state === 'several' ? '1' : '2';

		return (
			<div className={classes.elem('orderRowsInfoBox').b()}>
				<Icon color="blue" name="info-circle" />
				<Text size="sm" color="blue">
					{T('subscription.modal.summary.orderRows.infoBoxInfo' + infoNr)}{' '}
					<u onClick={() => expandPreviewFooter(true, true, true)}>
						{T('subscription.modal.summary.orderRows.infoBoxEnd')}
					</u>
				</Text>
			</div>
		);
	};

	const getCustomFieldsSubtitle = () => {
		if (Object.values(subscriptionMap).length <= 1 && !currentPeriod.children?.length) return null;

		const pl = currentPeriod.periodLength || 1;
		const periodLengthText = `${pl} ${T('default.month' + (pl > 1 ? 's' : ''))}`;
		return (
			<Text size="sm" color="grey-11">
				{T('subscription.modal.summary.order.commonForEntirePeriod')} <b>{periodLengthText}</b>
			</Text>
		);
	};

	const renderChildDate = () => {
		if (!hasChildren) return null;

		interface TranslateDateCSSProperties extends CSSProperties {
			'--translate-date': string;
		}
		const styles: TranslateDateCSSProperties = {
			'--translate-date': `${-30 * childIndexNr}px`
		};

		const children = currentPeriod.children ?? [];
		return (
			<div className={classes.elem('dates-container').b()}>
				<div className={classes.elem('dates-container').elem('moving-container').b()} style={styles}>
					{children.map((child, i) => (
						<div key={child.id ?? i} style={{ transform: `translateY(${i * 30}px)` }}>{`${moment(
							child.startDate
						).format('L')} - ${moment(child.endDate).format('L')}`}</div>
					))}
				</div>
			</div>
		);
	};

	return (
		<>
			<div className={classes.b()}>
				{hasSplitFlag ? <SubscriptionSplitScheduling uuid={uuid} /> : <SubscriptionPeriodScheduling />}

				{children}

				<div className={classes.elem('order').b()}>
					<Headline id="headline-id-for-scrolling" size="sm">
						{hasSplitFlag ? T('default.products') : T('subscription.modal.summary.order.title')}
						{renderChildDate()}
						<Title size="lg">
							<Select<{ id: string; title: string; currencyRate: number }>
								value={{
									id: currentPeriod.currency,
									title: currentPeriod.currency,
									currencyRate: currentPeriod.currencyRate
								}}
								onChange={v => {
									if (typeof v.id === 'string') {
										setCurrency(v.id, v.currencyRate);
									}
								}}
								options={currencyOptions}
								disabled={isEdit && hasAlreadyStarted}
								inline
								showSearch={false}
								anchor={document.querySelector('.OpenSubscription__inner')}
							/>
						</Title>
						<SubscriptionHotspot type={hasChildren ? 'childProduct' : 'product'} />
					</Headline>
					{!hasSplitFlag ? (
						<Text color="grey-11" size="sm">
							{T('subscription.modal.summary.order.subTitle')}
						</Text>
					) : null}
					{renderOrderRowsInfoBox()}
				</div>

				<OrderRows
					key={orderRowsKey}
					disabled={orderRowsDisabled}
					orderInterval={currentPeriod.orderInterval}
					isCreatedFromOrder={isCreatedFromOrder}
					onChange={isFakeComponent ? undefined : setOrderRows}
					currentRows={currentRows}
					currency={currentPeriod.currency}
					priceListId={currentPeriod.priceList.id}
					stageId={stage?.id}
					tracker={NewSubscriptionTracker}
					hideSummary={!!hideSummary}
					dontFetch={dontFetch}
				/>

				<FormObserver<SubscriptionCustomFormType>
					key={formObserverKey}
					onChange={(values, isValid, errorMessages) => {
						const mappedCustom = mapCustomValuesToArray(values.custom);
						setCustomFields(currentUUID, mappedCustom, isValid, errorMessages);
					}}
					validateOnMount={isCreatedFromOrder}
					onlyValidateTouched
					model={validatonModel}
					initialValues={initialValues}
					resetStateIfValueChange={`${currentPeriod.uuid}-${updatedHash}`}
					skipInitialOnChange={!!updatedHash}
				>
					{({ onFormChange, inputProps }) => (
						<CustomFields
							className={classes.elem('customFields').b()}
							disabled={currentPeriodLocked}
							title={T('subscription.modal.summary.order.orderField')}
							type="order"
							inputProps={inputProps}
							onChange={(id: number, value: string) => onFormChange(`custom.Custom_${id}`, value)}
							stageId={stage?.id}
							renderSubtitle={getCustomFieldsSubtitle}
							orderOrOrderRow={{
								...currentPeriod,
								orderRow: currentRows
							}}
							showFormGroupName={true}
							isDisabledField={fieldName => isDisabledField(fieldName)}
							isVisibleField={fieldName => isVisibleField(fieldName)}
							getCustomFieldOverrides={fieldId =>
								getCustomFieldOverrides({
									entityType: 'order',
									fieldId
								})
							}
						/>
					)}
				</FormObserver>
			</div>
		</>
	);
};

export const detached = SubscriptionPeriod;
export default memo(SubscriptionPeriod);
