import PropTypes from 'prop-types';
import React from 'react';

const propTypes = {
	input: PropTypes.string,
	compare: PropTypes.func,
	confirmText: PropTypes.string,
	valid: PropTypes.bool,
	noIcon: PropTypes.bool,
};

class Confirmation extends React.Component {
	getIcon(input, valid) {
		if (input && input.length > 1) {
			if (valid) {
				return <i className="fa fa-check fa-lg valid" />;
			}
			return <i className="fa fa-times fa-lg invalid" />;
		} else {
			return <i className="fa fa-lock fa-lg empty" />;
		}
	}
	getClassName(input, valid) {
		if (input && input.length > 1) {
			if (valid) {
				return 'form-control valid';
			}

			return 'form-control invalid';
		}

		return 'form-control';
	}

	render() {
		const { input, valid, compare, confirmText, noIcon } = this.props;

		const txt = Tools.$translate('default.typeToConfirm', { value: confirmText }) + '.';
		const validClassName = this.getClassName(input, valid);

		return (
			<div className="confirmation">
				{noIcon ? null : <span className="validation-icon">{this.getIcon(input, valid)}</span>}
				<label>{txt}</label>
				<input type="text" className={validClassName} value={input} onChange={compare} />
			</div>
		);
	}
}

Confirmation.propTypes = propTypes;

export default Confirmation;
