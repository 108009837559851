import Resource from './Resource';
import parseProductCategory from './parsers/productCategory';

class ProductCategory extends Resource {
	constructor() {
		super('productCategories');

		this.eventName = 'productCategory';

		this.parse = parseProductCategory;

		this.notifications = {
			save: () => ({
				title: 'default.saved',
				body: 'saved.productCategory',
				style: 'success',
				icon: 'check',
				type: 'body'
			}),
			saveError: () => ({
				title: 'default.error',
				body: 'saveError.productCategory',
				style: 'error',
				icon: 'times',
				type: 'body'
			}),
			delete: () => ({
				title: 'default.deleted',
				body: 'deleted.productCategory',
				style: 'success',
				icon: 'check',
				type: 'body'
			}),
			deleteError: () => ({
				title: 'default.error',
				body: 'deleteError.productCategory',
				style: 'error',
				icon: 'times',
				type: 'body'
			})
		};
	}
}

const resource = new ProductCategory();

export default resource;
