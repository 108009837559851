import React from 'react';
import PropTypes from 'prop-types';
import Account from './Account';
import { Tab, Tabs, Button } from '@upsales/components';
import _ from 'lodash';

export default class Search extends React.Component {
	constructor(props) {
		super(props);

		const t = Tools.$translate;
		this.lang = {
			createNewCompany: t('account.createNewCompany'),
			createNewCompanyPlaceholder: t('account.createNewCompanyPlaceholder'),
			createAccountManually: t('default.createAccountManually')
		};

		this.scrollTo = this.scrollTo.bind(this);
		this.restoreScroll = this.restoreScroll.bind(this);
		this.setRef = this.setRef.bind(this);
	}

	scrollTo(dataSourceId) {
		const node = this['_' + dataSourceId];

		if (node) {
			node.scrollIntoView({ behavior: 'smooth', block: 'start' });
		}
	}

	renderTabs() {
		const tabs = _.chain(this.props.accounts)
			.sortBy('sortId')
			.map(dataSource => {
				const total = dataSource.metadata.ALL || 0;

				return (
					<Tab key={dataSource.id} id={dataSource.id}>
						{dataSource.logo ? (
							<img height={26} width={26} className="tab-icon" src={dataSource.logo} />
						) : null}
						{Tools.$translate(dataSource.shortName || dataSource.name)}
						{total ? <span className="total-sum">{total}</span> : null}
					</Tab>
				);
			})
			.value();

		return (
			<Tabs noFlex align="left" className={'search-tabs'} onChange={this.scrollTo}>
				{tabs}
			</Tabs>
		);
	}

	restoreScroll(domNode) {
		if (this.props.searchYPos && domNode && domNode.scrollTop !== this.props.searchYPos) {
			domNode.scrollTop = this.props.searchYPos;
			this.props.actions.clearScroll();
		}
	}

	setRef(name, node) {
		this[name] = node;
	}

	render() {
		const { actions, addingAccount, accounts, search, companyGroup, hasRequriedFields, setInputRef } = this.props;

		const enterManually = actions.enterManually;

		const sortedDataSources = _.sortBy(accounts, 'sortId');
		const renderedDataSources = _.map(sortedDataSources, dataSource => {
			return (
				<Account
					key={dataSource.id}
					setRef={this.setRef}
					{...dataSource}
					actions={actions}
					addingAccount={addingAccount}
					companyGroup={companyGroup}
					hasRequriedFields={hasRequriedFields}
				/>
			);
		});

		return (
			<div>
				<div className={search.length ? 'box-shadow' : ''}>
					<div className="divider-wrap">
						<div className="header-content header-content-1 full-screen-modal-content">
							<h1 className="full-screen-small-header">{this.lang.createNewCompany}</h1>
							<div className="huge-input-container">
								<i className="fa fa-search" />
								<input
									autoComplete="new-company-name"
									className="huge-input"
									type="text"
									placeholder={this.lang.createNewCompanyPlaceholder}
									value={search}
									onChange={this.props.actions.doSearch}
									autoFocus
									ref={r => setInputRef?.(r)}
								/>
							</div>
						</div>
						<div className="header-divider" />
					</div>
					<div className="header-content tab-wrap full-screen-modal-content">
						{search.length > 0 && this.renderTabs()}
						{search.length > 0 ? (
							<Button className="pull-right btn-link" onClick={enterManually}>
								{this.lang.createAccountManually}
							</Button>
						) : null}
					</div>
				</div>
				<div
					id="create-account-search-results"
					className={search.length ? 'search-results' : 'search-results no-opacity'}
					ref={r => {
						this.restoreScroll(r);
					}}
				>
					<div className="full-screen-modal-content">{renderedDataSources}</div>
				</div>
			</div>
		);
	}
}

Search.propTypes = {
	search: PropTypes.string,
	accounts: PropTypes.object,
	searchYPos: PropTypes.oneOfType([PropTypes.object, PropTypes.number]),
	actions: PropTypes.object,
	addingAccount: PropTypes.object,
	companyGroup: PropTypes.object,
	hasRequriedFields: PropTypes.bool,
	setInputRef: PropTypes.func
};
