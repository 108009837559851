import React from 'react';

import './EditSocialEventCopySvg.scss';

const EditSocialEventCopySvg = () => {
	const svg = {
		__html: `
		<svg 
			xmlns="http://www.w3.org/2000/svg" 
			width="240" 
			height="140" 
			viewBox="0 0 240 140" 
			xmlns:xlink="http://www.w3.org/1999/xlink">
		<defs>
			<rect 
				id="a" 
				width="71" 
				height="79.823" 
				x=".1" 
				rx="2"/>
			<filter 
				id="b" 
				width="105.6%" 
				height="105%" 
				x="-2.8%" 
				y="-1.3%" 
				filterUnits="objectBoundingBox">
				<feOffset
					dy="1" 
					in="SourceAlpha" 
					result="shadowOffsetOuter1"/>
				<feGaussianBlur 
					stdDeviation=".5" 
					in="shadowOffsetOuter1" 
					result="shadowBlurOuter1"/>
				<feColorMatrix 
					values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.2 0" 
					in="shadowBlurOuter1"/>
			</filter>
			<polygon 
				id="d" 
				points="0 5.957 20.774 0 20.774 20.851 0 14.894"/>
			<path 
				id="f" 
				d="M0,0 L5.11827957,0.29787234 L7.04472507,8.1939567 C7.12326742,8.51588486 6.92596424,8.84053035 6.60403609,8.91907271 C6.55748437,8.93043015 6.50973973,8.93617021 6.46182257,8.93617021 L3.15471922,8.93617021 C2.89041037,8.93617021 2.65723256,8.76321376 2.58053575,8.51027746 L0,0 Z"/>
			<rect 
				id="h" 
				width="71" 
				height="79.823" 
				x=".1" 
				rx="2"/>
			<filter 
				id="i" 
				width="105.6%" 
				height="105%" 
				x="-2.8%" 
				y="-1.3%" 
				filterUnits="objectBoundingBox">
				<feOffset
					dy="1" 
					in="SourceAlpha" 
					result="shadowOffsetOuter1"/>
				<feGaussianBlur 
					stdDeviation=".5" 
					in="shadowOffsetOuter1" 
					result="shadowBlurOuter1"/>
				<feColorMatrix 
				values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.2 0" 
				in="shadowBlurOuter1"/>
			</filter>
			<polygon 
				id="k" 
				points="0 5.957 20.774 0 20.774 20.851 0 14.894"/>
			<path 
				id="m" 
				d="M0,0 L5.11827957,0.29787234 L7.04472507,8.1939567 C7.12326742,8.51588486 6.92596424,8.84053035 6.60403609,8.91907271 C6.55748437,8.93043015 6.50973973,8.93617021 6.46182257,8.93617021 L3.15471922,8.93617021 C2.89041037,8.93617021 2.65723256,8.76321376 2.58053575,8.51027746 L0,0 Z"/>
		</defs>
	
		<g 
		class="big-group"
		fill="none" 
		fill-rule="evenodd">
		<g 
		class="first-paper"
		transform="translate(39 30)">
		  <mask 
			id="c" 
			fill="white">
			<use 
			xlink:href="#a"/>
		  </mask>
		<use
			fill="black" filter="url(#b)" 
			xlink:href="#a"/>
		<use
			fill="#9933C0" 
			xlink:href="#a"/>
		  <polygon 
			fill="#FFFFFF" 
			points="61 0 68 0 71 80 20 80" 
			opacity=".1" 
			mask="url(#c)"/>
		  <polygon 
			fill="#FFFFFF" 
			points="11 0 23 0 71 80 0 80" 
			opacity=".1" 
			mask="url(#c)"/>
			<g 
		  transform="translate(12 23)" 
		  mask="url(#c)">
			<ellipse 
				cx="21.075" 
			cy="41.511" 
				fill="#002F65" 
				opacity=".1" 
				rx="17.763" 
				ry="1.489"/>
			<g 
			transform="rotate(-20 51.291 8.792)">
				<g 
			  transform="translate(11.14 .596)">
				<mask 
					id="e" 
						fill="white">
				<use
				  xlink:href="#d"/>
				</mask>
				<use 
					fill="#EDF1F5" 
					xlink:href="#d"/>
				<rect 
					width="21.075" 
					height="15.787" 
					x="-.602" 
					y="10.723" 
						fill="#CAD2DD" 
						mask="url(#e)"/>
			</g>
				<g 
				  transform="translate(9.032 15.787)">
				<path 
					fill="#CAD2DD" 
					d="M0.301075269,1.19148936 L2.55913978,1.19148936 L3.43891602,4.92184756 C3.51498049,5.24437017 3.31518669,5.56748885 2.99266408,5.64355332 C2.94752851,5.6541982 2.90131101,5.65957447 2.85493717,5.65957447 L1.82979401,5.65957447 C1.55147957,5.65957447 1.30970081,5.46818429 1.24581516,5.19730138 L0.301075269,1.19148936 Z"/>
				<path 
					fill="#A4B3C7" 
					d="M0.0301070675,0 L2.28817158,0 L3.34193502,4.46808511 L1.48740066,4.2234444 C1.24376554,4.1913053 1.04435882,4.01384735 0.984154099,3.77559038 L0.0301070675,0 Z"/>
			</g>
			  <path 
				fill="#4E0065" 
				d="M31.9139785,14.5957447 C33.7430506,14.5957447 35.2258065,13.1287628 35.2258065,11.3191489 C35.2258065,9.50953508 33.7430506,8.04255319 31.9139785,8.04255319 C30.0849064,8.04255319 30.0849064,14.5957447 31.9139785,14.5957447 Z"/>
				<rect 
				width="3.011" 
				height="22.638" 
				x="30.409" 
				fill="#BB75DD" 
				rx="1.505"/>
				<g 
				  transform="translate(5.118 16.979)">
				<mask 
					id="g" 
					fill="white">
				<use
				  xlink:href="#f"/>
				</mask>
				<use 
					fill="#EDF1F5" 
					xlink:href="#f"/>
				<polygon 
					fill="#CAD2DD" 
					points="-.301 -2.681 5.118 -2.681 5.653 2.49 .084 1.876" 
					mask="url(#g)"/>
			</g>
			  <path 
				fill="#EDF1F5" 
				  d="M5.72043011,17.2604982 C2.52255537,17.0316993 0,14.3924093 0,11.1702128 C0,7.94801622 2.52255537,5.30872623 5.72043011,5.07992736 L5.72043011,5.06382979 L11.144086,5.06382979 C11.8068277,5.06382979 12.344086,5.60108809 12.344086,6.26382979 L12.344086,16.0765957 C12.344086,16.7393374 11.8068277,17.2765957 11.144086,17.2765957 L5.72043011,17.2765957 L5.72043011,17.2604982 Z"/>
			  <path 
				fill="#E4EAF0" 
				  d="M6.17204301,17.2765957 C7.82939068,17.2765957 9.48673835,17.2765957 11.144086,17.2765957 L11.144086,17.2765957 C11.8068277,17.2765957 12.344086,16.7393374 12.344086,16.0765957 C12.344086,14.4411348 12.344086,12.8056738 12.344086,11.1702128 C10.2007989,11.4680851 7.99291359,11.6170213 5.72043011,11.6170213 C2.75975862,11.6170213 1.11558554,10.4722148 0.787910848,8.18260181 C0.286163696,9.06579313 0,10.0849399 0,11.1702128 C0,14.542675 2.76331778,17.2765957 6.17204301,17.2765957 Z"/>
			  <path 
				fill="#BB75DD" 
				  d="M9.6344086,5.06382979 L11.4451613,5.06382979 C12.107903,5.06382979 12.6451613,5.60108809 12.6451613,6.26382979 L12.6451613,16.0765957 C12.6451613,16.7393374 12.107903,17.2765957 11.4451613,17.2765957 L9.6344086,17.2765957 L9.6344086,5.06382979 Z"/>
			  <polygon 
				fill="#4E0065" 
				points="36.731 5.957 40.946 3.277 41.247 4.617 45.763 3.277 40.344 6.553 40.043 5.064" 
				opacity=".2"/>
			  <polygon 
				fill="#4E0065" 
				points="40.652 13.128 44.871 10.425 45.166 11.767 49.679 10.405 44.256 13.71 43.962 12.219" 
				opacity=".2" 
				transform="rotate(25 45.166 12.058)"/>
			  <polygon 
				fill="#4E0065" 
				points="35.825 18.448 40.035 15.732 40.327 17.077 44.83 15.707 39.418 19.027 39.126 17.532" 
				opacity=".2" 
				transform="rotate(41 40.327 17.367)"/>
			</g>
		  </g>
			<g 
			  transform="translate(-11 -1)" 
			  mask="url(#c)">
			<polygon 
				fill="#FFFFFF" 
				points="29 12 43 12 36 34"/>
			<polygon 
				fill="#FFFFFF" 
				points="54 7 68 7 61 29" 
				transform="rotate(-22 61 18)"/>
			<polygon 
				fill="#FFFFFF" 
				points="4.34 7.152 18.34 7.152 11.34 29.152" 
				transform="rotate(22 11.34 18.152)"/>
			<path stroke="#FFFFFF" 
				d="M1,-8.52651283e-14 C11.9133434,8.36587963 23.2466767,12.5488194 35,12.5488194 C46.7533233,12.5488194 58.7866566,8.36587963 71.1,-8.52651283e-14"/>
		  </g>
			<g 
			fill="#FFFFFF" 
			opacity=".6" 
			mask="url(#c)">
			<g 
				transform="translate(4 2)">
				<rect 
				width="4" 
				height="4" 
				x=".828" 
				y="24.828" 
				transform="rotate(45 2.828 26.828)"/>
				<rect 
				width="4" 
				height="4" 
				x="30.828" 
				y="66.828" 
				transform="rotate(45 32.828 68.828)"/>
				<rect 
				width="2" 
				height="2" 
				x="55.828" 
				y="65.828" 
				transform="rotate(45 56.828 66.828)"/>
				<rect 
				width="2" 
				height="2" 
				x="38.828" 
				y="58.828" 
				transform="rotate(45 39.828 59.828)"/>
				<rect 
				width="2" 
				height="2" 
				x="5.828" 
				y="63.828" 
				transform="rotate(45 6.828 64.828)"/>
				<rect 
				width="2" 
				height="2" 
				x="1.828" 
				y="53.828" 
				transform="rotate(45 2.828 54.828)"/>
				<rect 
				width="2" 
				height="2" 
				x="31.828" 
				y="13.828" 
				transform="rotate(45 32.828 14.828)"/>
				<rect 
				width="2" 
				height="2" 
				x="51.828" 
				y="23.828" 
				transform="rotate(45 52.828 24.828)"/>
				<rect 
				width="2" 
				height="2" 
				x="61.828" 
				y=".828" 
				transform="rotate(45 62.828 1.828)"/>
				<rect 
				width="2" 
				height="2" 
				x="11.828" 
				y="4.828" 
				transform="rotate(45 12.828 5.828)"/>
				<rect 
				width="2" 
				height="2" 
				x="61.828" 
				y="45.828" 
				transform="rotate(45 62.828 46.828)"/>
			</g>
		  </g>
		  <ellipse 
			cx="35" 
			cy="105" 
			fill="#002F65" 
			opacity=".1" 
			rx="35" 
			ry="5"/>
		</g>
		<g
			class="second-paper"
			transform="translate(39 30)"
			opacity="0">
		<mask 
			id="j" 
			fill="white">
			<use 
			xlink:href="#h"/>
		</mask>
			<use 
				fill="black" 
				filter="url(#i)" 
				xlink:href="#h"/>
			<use 
				fill="#4A90E2" 
				xlink:href="#h"/>
			<polygon 
				fill="#FFFFFF" 
				points="61 0 68 0 71 80 20 80" 
				opacity=".1" 
				mask="url(#j)"/>
			<polygon 
				fill="#FFFFFF" 
				points="11 0 23 0 71 80 0 80" 
				opacity=".1" 
				mask="url(#j)"/>
			<g 
				transform="translate(12 23)" 
				mask="url(#j)">
			<ellipse 
				cx="21.075" 
				cy="41.511" 
				fill="#002F65" 
				opacity=".1" 
				rx="17.763" 
				ry="1.489"/>
			<g 
				transform="rotate(-20 51.291 8.792)">
				<g 
					transform="translate(11.14 .596)">
				<mask 
					id="l" 
					fill="white">
				<use
					xlink:href="#k"/>
				</mask>
				<use 
					fill="#EDF1F5" 
					xlink:href="#k"/>
				<rect 
					width="21.075" 
					height="15.787" 
					x="-.602" 
					y="10.723" 
					fill="#CAD2DD" 
					mask="url(#l)"/>
			</g>
				<g 
			  transform="translate(9.032 15.787)">
				<path 
					fill="#CAD2DD" 
				d="M0.301075269,1.19148936 L2.55913978,1.19148936 L3.43891602,4.92184756 C3.51498049,5.24437017 3.31518669,5.56748885 2.99266408,5.64355332 C2.94752851,5.6541982 2.90131101,5.65957447 2.85493717,5.65957447 L1.82979401,5.65957447 C1.55147957,5.65957447 1.30970081,5.46818429 1.24581516,5.19730138 L0.301075269,1.19148936 Z"/>
				<path 
					fill="#A4B3C7" 
				d="M0.0301070675,0 L2.28817158,0 L3.34193502,4.46808511 L1.48740066,4.2234444 C1.24376554,4.1913053 1.04435882,4.01384735 0.984154099,3.77559038 L0.0301070675,0 Z"/>
			</g>
			  <path 
				fill="#4E0065" 
			  d="M31.9139785,14.5957447 C33.7430506,14.5957447 35.2258065,13.1287628 35.2258065,11.3191489 C35.2258065,9.50953508 33.7430506,8.04255319 31.9139785,8.04255319 C30.0849064,8.04255319 30.0849064,14.5957447 31.9139785,14.5957447 Z"/>
				<rect 
				width="3.011" 
				height="22.638" 
				x="30.409" 
					fill="#BB75DD" 
					rx="1.505"/>
				<g 
			  transform="translate(5.118 16.979)">
				<mask 
					id="n" 
						fill="white">
				<use
				  xlink:href="#m"/>
				</mask>
				<use 
					fill="#EDF1F5" 
					xlink:href="#m"/>
				<polygon 
					fill="#CAD2DD" 
					points="-.301 -2.681 5.118 -2.681 5.653 2.49 .084 1.876" 
					mask="url(#n)"/>
			</g>
			  <path 
				fill="#EDF1F5" 
			  d="M5.72043011,17.2604982 C2.52255537,17.0316993 0,14.3924093 0,11.1702128 C0,7.94801622 2.52255537,5.30872623 5.72043011,5.07992736 L5.72043011,5.06382979 L11.144086,5.06382979 C11.8068277,5.06382979 12.344086,5.60108809 12.344086,6.26382979 L12.344086,16.0765957 C12.344086,16.7393374 11.8068277,17.2765957 11.144086,17.2765957 L5.72043011,17.2765957 L5.72043011,17.2604982 Z"/>
			  <path 
				fill="#E4EAF0" 
			  d="M6.17204301,17.2765957 C7.82939068,17.2765957 9.48673835,17.2765957 11.144086,17.2765957 L11.144086,17.2765957 C11.8068277,17.2765957 12.344086,16.7393374 12.344086,16.0765957 C12.344086,14.4411348 12.344086,12.8056738 12.344086,11.1702128 C10.2007989,11.4680851 7.99291359,11.6170213 5.72043011,11.6170213 C2.75975862,11.6170213 1.11558554,10.4722148 0.787910848,8.18260181 C0.286163696,9.06579313 0,10.0849399 0,11.1702128 C0,14.542675 2.76331778,17.2765957 6.17204301,17.2765957 Z"/>
			  <path 
				fill="#BB75DD" 
			  d="M9.6344086,5.06382979 L11.4451613,5.06382979 C12.107903,5.06382979 12.6451613,5.60108809 12.6451613,6.26382979 L12.6451613,16.0765957 C12.6451613,16.7393374 12.107903,17.2765957 11.4451613,17.2765957 L9.6344086,17.2765957 L9.6344086,5.06382979 Z"/>
			  <polygon 
				fill="#4E0065" 
				points="36.731 5.957 40.946 3.277 41.247 4.617 45.763 3.277 40.344 6.553 40.043 5.064" 
				opacity=".2"/>
			  <polygon 
				fill="#4E0065" 
				points="40.652 13.128 44.871 10.425 45.166 11.767 49.679 10.405 44.256 13.71 43.962 12.219" 
				opacity=".2" 
				transform="rotate(25 45.166 12.058)"/>
			  <polygon 
				fill="#4E0065" 
				points="35.825 18.448 40.035 15.732 40.327 17.077 44.83 15.707 39.418 19.027 39.126 17.532" 
				opacity=".2" 
				transform="rotate(41 40.327 17.367)"/>
			</g>
		  </g>
			<g 
		  transform="translate(-11 -1)" 
		  mask="url(#j)">
			<polygon 
				fill="#FFFFFF" 
				points="29 12 43 12 36 34"/>
			<polygon 
				fill="#FFFFFF" 
				points="54 7 68 7 61 29" 
				transform="rotate(-22 61 18)"/>
			<polygon 
				fill="#FFFFFF" 
				points="4.34 7.152 18.34 7.152 11.34 29.152" 
				transform="rotate(22 11.34 18.152)"/>
			<path stroke="#FFFFFF" 
			d="M1,-8.52651283e-14 C11.9133434,8.36587963 23.2466767,12.5488194 35,12.5488194 C46.7533233,12.5488194 58.7866566,8.36587963 71.1,-8.52651283e-14"/>
		  </g>
			<g 
			fill="#FFFFFF" 
			opacity=".6" 
			mask="url(#j)">
			<g 
			transform="translate(4 2)">
				<rect 
				width="4" 
				height="4" 
				x=".828" 
				y="24.828" 
				transform="rotate(45 2.828 26.828)"/>
				<rect 
				width="4" 
				height="4" 
				x="30.828" 
				y="66.828" 
				transform="rotate(45 32.828 68.828)"/>
				<rect 
				width="2" 
				height="2" 
				x="55.828" 
				y="65.828" 
				transform="rotate(45 56.828 66.828)"/>
				<rect 
				width="2" 
				height="2" 
				x="38.828" 
				y="58.828" 
				transform="rotate(45 39.828 59.828)"/>
				<rect 
				width="2" 
				height="2" 
				x="5.828" 
				y="63.828" 
				transform="rotate(45 6.828 64.828)"/>
				<rect 
				width="2" 
				height="2" 
				x="1.828" 
				y="53.828" 
				transform="rotate(45 2.828 54.828)"/>
				<rect 
				width="2" 
				height="2" 
				x="31.828" 
				y="13.828" 
				transform="rotate(45 32.828 14.828)"/>
				<rect 
				width="2" 
				height="2" 
				x="51.828" 
				y="23.828" 
				transform="rotate(45 52.828 24.828)"/>
				<rect 
				width="2" 
				height="2" 
				x="61.828" 
				y=".828" 
				transform="rotate(45 62.828 1.828)"/>
				<rect 
				width="2" 
				height="2" 
				x="11.828" 
				y="4.828" 
				transform="rotate(45 12.828 5.828)"/>
				<rect 
				width="2" 
				height="2" 
				x="61.828" 
				y="45.828" 
				transform="rotate(45 62.828 46.828)"/>
			</g>
		  </g>
		  <ellipse 
			cx="35" 
			cy="105" 
			fill="#002F65" 
			opacity=".1" 
			rx="35" 
			ry="5"/>
		</g>
	  </g>
	</svg>`
	};
	return <div className="EditSocialEventCopySvg" dangerouslySetInnerHTML={svg} />;
};

EditSocialEventCopySvg.propTypes = {};

export const detached = EditSocialEventCopySvg;
export default EditSocialEventCopySvg;
