import getAngularModule from 'App/babel/angularHelpers/getAngularModule';
import Label from 'App/resources/Model/Label';
import { formsTracker, mailCampaignTracker, mailTemplateTracker } from 'App/babel/helpers/Tracker';

const updateLabels = (entity: string, selectedLabel: Label, filters: object, selected: number) => {
	const properties = [{ name: 'Labels', value: selectedLabel.id ? [selectedLabel.id] : [] }];
	const MultiActions = getAngularModule('MultiActions');
	const AppService = getAngularModule('AppService');
	const customerId = AppService.getCustomerId();
	switch (entity) {
		case 'Form':
			formsTracker.track(formsTracker.events.ADDED_LABEL);
			return MultiActions.customer(customerId).updateForm(selected, properties, filters);
		case 'MailTemplate':
			mailTemplateTracker.track(mailTemplateTracker.events.ADDED_LABEL);
			return MultiActions.customer(customerId).updateMailtemplate(selected, properties, filters);
		case 'MailCampaign':
			mailCampaignTracker.track(mailCampaignTracker.events.ADDED_LABEL);
			return MultiActions.customer(customerId).updateMailCampaign(selected, properties, filters);
	}
	return new Promise(() => {});
};

export default updateLabels;
