import React from 'react';
import TimelineGeneric from '../TimelineGeneric';
import { openDrawer } from 'Services/Drawer';
import { useTranslation } from 'Components/Helpers/translate';
import type { TimelineCardComponent } from '../TimelineGeneric/TimelineCardConfigs';

const TimelineCardTicket = (({ event, ...passthrough }) => {
	const { t } = useTranslation();

	let value;
	if (event.value) {
		value = JSON.parse(event.value);
	}

	const openTicket = () => openDrawer('EditTicket', { ticketId: event.entityId });

	switch (event.subType) {
		case 'Created':
			return (
				<TimelineGeneric
					event={event}
					eventLinkTitle={value.title}
					onLinkClick={openTicket}
					titleElements={
						// If a user created the ticket (it has a user) then we want it to say "USER created the ticket TICKET_TITLE"
						event.users.length
							? ['users', () => t('ticket.createdTheTicket').toLowerCase(), 'eventLink', 'contacts']
							: ['contacts', () => t('ticket.sentInTheTicket').toLowerCase(), 'eventLink']
					}
					contactPreposition={event.users.length ? undefined : ''}
					hasAvatar
					{...passthrough}
				/>
			);
		case 'StatusChanged':
			if (value.status.closed) {
				return (
					<TimelineGeneric
						event={event}
						actionTitle={t('ticket.closedTicket').toLowerCase()}
						eventLinkTitle={value.title}
						onLinkClick={openTicket}
						hasAvatar
						{...passthrough}
					/>
				);
			} else {
				return (
					<TimelineGeneric
						event={event}
						actionTitle={t('ticket.reopenedTicket').toLowerCase()}
						eventLinkTitle={value.title}
						onLinkClick={openTicket}
						hasAvatar
						{...passthrough}
					/>
				);
			}
		default:
			return null;
	}
}) satisfies TimelineCardComponent;

export default TimelineCardTicket;
