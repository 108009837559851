import React from 'react';
import { Label, Title } from '@upsales/components';
import RelationRow from '../RelationRow';
import BemClass from '@upsales/components/Utils/bemClass';
import PropTypes from 'prop-types';
import './RelationSelectRelations.scss';
import { containsPhoneWords } from 'App/babel/helpers/phoneWords';
import { getAttendingStr } from 'App/helpers/getAttendingStr';

const RelationSelectRelations = ({
	appointment,
	opportunity,
	projectPlan,
	client,
	activity,
	contacts,
	labelText,
	onClear,
	setHasRelations,
	required = false,
	locked = false,
	lockedClient = false,
	lockedContact = false,
	disabled = false,
	missingAccess = false
}) => {
	const classes = new BemClass('RelationSelectRelations');
	const mappedClient = client ? client : contacts?.length && contacts[0].client ? contacts[0].client : null;
	const hasProjectPlanAccess = Tools.FeatureHelper.isAvailable(Tools.FeatureHelper.Feature.PROJECT_PLAN);

	return (
		<div className={classes.b()} key="relations">
			<div className={classes.elem('labelSection').b()}>
				<Label required={required}>
					<Title bold size="sm">
						{labelText}
					</Title>
				</Label>
			</div>
			{mappedClient && (!contacts || !contacts.length) ? (
				<RelationRow
					title={mappedClient.name}
					journeyStep={mappedClient.journeyStep}
					key="client"
					type="client"
					relationId={mappedClient.id}
					onClear={!locked && !projectPlan ? onClear : null}
					setHasRelations={setHasRelations}
					lockedClient={lockedClient}
					disabled={disabled}
					missingAccess={missingAccess}
				/>
			) : null}
			{contacts && contacts.length
				? contacts.map((contact, index) => {
						return (
							<RelationRow
								type="contact"
								key={index}
								title={contact?.name}
								subtitle={mappedClient ? mappedClient?.name : null}
								journeyStep={contact.journeyStep}
								relationId={contact.id}
								onClear={!locked ? onClear : null}
								setHasRelations={setHasRelations}
								lockedContact={lockedContact}
								disabled={disabled}
								missingAccess={missingAccess}
								clickableSubtitleId={mappedClient?.id}
							/>
						);
				  })
				: null}
			{opportunity ? (
				<RelationRow
					type="opportunity"
					key="opportunity"
					title={opportunity.description}
					icon={opportunity.probability === 100 ? 'dollar' : 'opportunity'}
					subtitle={
						opportunity.orderValue
							? `${opportunity.orderValue} ${
									opportunity.currency
										? opportunity.currency
										: _.find(Tools.AppService.getMetadata().customerCurrencies, {
												masterCurrency: true
										  }).iso
							  } • ${moment(opportunity.date).format('YYYY-MM-DD')} • ${opportunity.stage.name}`
							: ''
					}
					relationId={opportunity.id}
					onClear={!locked && !projectPlan ? onClear : null}
					setHasRelations={setHasRelations}
					disabled={disabled}
				/>
			) : null}
			{appointment ? (
				<RelationRow
					type="appointment"
					key="appointment"
					title={appointment.description}
					subtitle={`${moment(appointment.date).format('L LT')} • ${getAttendingStr(
						appointment.users?.[0]?.id,
						appointment.users || [],
						appointment.contacts || []
					)}`}
					relationId={appointment.id}
					onClear={!locked ? onClear : null}
					setHasRelations={setHasRelations}
					disabled={disabled}
				/>
			) : null}
			{activity ? (
				<RelationRow
					type="activity"
					key="activity"
					icon={containsPhoneWords(activity.activityType?.name) ? 'phone' : 'activity'}
					title={activity.description}
					subtitle={`${
						activity.date ? moment(activity.date).format(activity.time ? 'L LT' : 'L') + ' • ' : ''
					}
					  ${getAttendingStr(activity.users?.[0]?.id, activity.users || [], activity.contacts || [])}`}
					relationId={activity.id}
					onClear={!locked ? onClear : null}
					setHasRelations={setHasRelations}
					disabled={disabled}
				/>
			) : null}
			{projectPlan && hasProjectPlanAccess ? (
				<RelationRow
					type="projectPlan"
					key="projectPlan"
					icon={'truck'}
					title={projectPlan.name}
					relationId={projectPlan.id}
					onClear={null}
					setHasRelations={setHasRelations}
					disabled={disabled}
				/>
			) : null}
		</div>
	);
};

RelationSelectRelations.propTypes = {
	appointment: PropTypes.object,
	opportunity: PropTypes.object,
	projectPlan: PropTypes.object,
	client: PropTypes.object,
	contacts: PropTypes.array,
	labelText: PropTypes.string,
	onClear: PropTypes.func,
	setHasRelations: PropTypes.func,
	required: PropTypes.bool,
	locked: PropTypes.bool,
	lockedClient: PropTypes.bool,
	lockedContact: PropTypes.bool,
	disabled: PropTypes.bool,
	missingAccess: PropTypes.bool
};
export default RelationSelectRelations;
