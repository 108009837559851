import React, { useEffect, useState } from 'react';
import logError from 'App/babel/helpers/logError';
import UpSelect from 'Components/Inputs/UpSelect';
import RequestBuilder, { comparisonTypes } from 'Resources/RequestBuilder';
import Resource from 'Resources/Resource';

export type Select2Event<T> = {
	target: {
		added: undefined | T;
		removed: undefined | T;
		value: T;
	};
};

const STATIC_LIMIT = 500;

type Select2Data<T> = (T | { name: string; children: T[] })[];

export type AjaxSelectProps<T> = {
	state?: 'error' | null;
	multiple?: boolean;
	required?: boolean;
	disabled?: boolean;
	autoOpen?: boolean;
	autoFetch?: boolean;
	value?: null | T | T[];
	onChange: (e: Select2Event<T>) => void;
	fields?: string[];
	sort?: string;
	icon?: string;
	searchField?: string;
	resource: Resource;
	modifyRb?: (rb: RequestBuilder) => boolean;
	results?: (data: T[], term: string) => Select2Data<T>;
	name?: string;
	nameKey?: string;
	isStatic?: boolean;
	placeholder?: string;
	formatResult?: (obj: T, el: any, x: any, encode: (name: string) => string) => string;
	formatSelection?: (obj: T, container: any, encode: (name: string) => string) => string;
	defaultValue?: T;
	storeIdInState?: boolean;
	options?: object;
	className?: string;
	refreshData?: any;
};

export const AjaxSelect = <T extends {}>({
	value,
	resource,
	modifyRb,
	autoFetch = false,
	searchField = 'name',
	fields = ['id', 'name'],
	sort = 'name',
	results,
	nameKey = 'name',
	isStatic = false,
	options: optionsProps,
	refreshData,
	...props
}: AjaxSelectProps<T>) => {
	const [staticData, setStaticData] = useState<T[] | null>(null);

	const getData = async (term?: string) => {
		const rb = new RequestBuilder();
		rb.fields = fields;
		rb.addSort({ field: sort }, true);
		rb.limit = isStatic ? STATIC_LIMIT : 50;

		const replaceTermSearch = modifyRb?.(rb);
		if (!replaceTermSearch && term) {
			rb.addFilter({ field: searchField }, comparisonTypes.Wildcard, term);
		}
		return resource.find(rb.build());
	};

	useEffect(() => {
		if (isStatic || refreshData) {
			getData()
				.then(({ data }) => setStaticData(data))
				.catch(error => {
					logError(error, 'Failed to load AjaxSelect');
					setStaticData([]);
				});
		}
	}, [refreshData]);

	const options = {
		minimumInputLength: results || isStatic ? -1 : 1,
		ajax: {
			data: (term: string) => term,
			transport: ({
				success,
				data: term
			}: {
				success: (d: { data: T[]; term: string }) => void;
				data: string;
			}) => {
				if (isStatic) {
					const array =
						(term
							? staticData?.filter((item: { [k: string]: string }) =>
									item[nameKey].toLowerCase().includes(term.toLowerCase())
							  )
							: staticData) || [];
					return success({ data: array, term });
				}
				if (term.length < 1 && !autoFetch) {
					return success({ data: [], term });
				}
				getData(term)
					.then(r => success({ data: r.data, term }))
					.catch(error => {
						logError(error, 'Failed to load AjaxSelect');
						success({ data: [], term });
					});
			},
			results: ({ data, term }: { data: T[]; term: string }) => ({ results: results?.(data, term) || data })
		},
		initSelection: (_e: any, callback: (value?: null | T | T[]) => void) => {
			callback(value);
		}
	};
	// Key is needed because of the stupid select2 - please do not remove
	return (
		<UpSelect
			key={staticData ? `got-data-${staticData.length}` : 'not-loaded'}
			{...props}
			defaultValue={value}
			options={{ ...options, ...optionsProps }}
		/>
	);
};
