export const SET_MAIL_TEMPLATE = '[Reminders] SET_MAIL_TEMPLATE';
export const SET_CONFIG = '[Reminders] SET_CONFIG';
export const SET_STYLE = '[Reminders] SET_STYLE';
export const SET_ACCOUNT_PROFILE = '[Reminders] SET_ACCOUNT_PROFILE';
export const SET_USER_PARAMS = '[Reminders] SET_USER_PARAMS';
export const SET_CLIENT_PARAMS = '[Reminders] SET_CLIENT_PARAMS';
export const SET_ENGAGEMENT_EMAIL_SETTINGS = '[Reminders] SET_ENGAGEMENT_EMAIL_SETTINGS';
export const SET_LOADING = '[Reminders] SET_LOADING';
export const SET_TOTAL = '[Reminders] SET_TOTAL';
export const initialState = {
	total: 0,
	mailTemplate: null,
	config: null,
	style: null,
	accountProfile: null,
	userParams: {
		keepInTouchReminderActive: false,
		keepInTouchReminderMonths: 3,
		keepInTouchReminderMailTemplate: null,
		keepInTouchReminderRecipients: [],
		keepInTouchReminderProject: null,
		subscriptionReminderPeriod: 1,
		subscriptionReminderOnUser: true,
		subscriptionReminderOnAccountManager: false,
		engagementEmail: null
	},
	clientParams: {
		EnableEngagementEmails: false
	},
	engagementEmailSettings: null,
	loading: true
};

const ACTION_HANDLERS = {
	[SET_MAIL_TEMPLATE]: (state, { mailTemplate }) => ({ ...state, mailTemplate }),
	[SET_CONFIG]: (state, { config }) => ({ ...state, config }),
	[SET_STYLE]: (state, { style }) => ({ ...state, style }),
	[SET_ACCOUNT_PROFILE]: (state, { accountProfile }) => ({ ...state, accountProfile }),
	[SET_USER_PARAMS]: (state, { userParams }) => ({ ...state, userParams: { ...state.userParams, ...userParams } }),
	[SET_CLIENT_PARAMS]: (state, { clientParams }) => ({
		...state,
		clientParams: { ...state.clientParams, ...clientParams }
	}),
	[SET_ENGAGEMENT_EMAIL_SETTINGS]: (state, { engagementEmailSettings }) => ({
		...state,
		engagementEmailSettings: { ...state.engagementEmailSettings, ...engagementEmailSettings }
	}),
	[SET_LOADING]: (state, { loading }) => ({ ...state, loading }),
	[SET_TOTAL]: (state, { total }) => ({ ...state, total })
};

export default (state = initialState, action) => {
	const handler = ACTION_HANDLERS[action.type];
	return handler ? handler(state, action) : state;
};
