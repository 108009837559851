import { ClientIdName } from 'App/resources/Model/Client';
import Resource from './Resource';

export enum QuantityTypes {
	QUANTITY_INCREASE = 'productIncrease',
	QUANTITY_DECREASE = 'productDecrease',
	QUANTITY_UNCHANGED = 'productUnchanged'
}

export enum PriceTypes {
	PRICE_INCREASE = 'priceIncrease',
	PRICE_DECREASE = 'priceDecrease',
	PRICE_UNCHANGED = 'priceUnchanged'
}

export type ChangeRow = {
	value: number;

	product: {
		id: number;
		name: string;
	};

	price: number;
	quantity: number;
	tierQuantity: number;
	orderInterval: number;

	prevPrice: number;
	prevQuantity: number;
	prevTierQuantity: number;
	prevOrderInterval: number;
};

export type RowChanged = {
	change: number;

	productId: number;
	productName: string;

	interval: number;

	pricePrevious: number;
	priceCurrent: number;
	priceType: PriceTypes;

	quantityPrevious: number;
	quantityCurrent: number;
	quantityType: QuantityTypes;

	discountChange: number;
};

export type Change = {
	id: number;
	regDate: string;
	clientId: number;
	arrChangeDate: string;
	agreementGroupId?: number;
	hidden: boolean;
	endDate: unknown;
	agreementId: number;
	users: {
		id: number;
		name: string;
	}[];
	activeAgreementId: number;
	description: string;
	type:
		| 'moved'
		| 'editedDescription'
		| 'increase'
		| 'indexIncrease'
		| 'contraction'
		| 'lost'
		| 'period'
		| 'newAgreement'
		| 'productChange'
		| 'DECREASE'
		| 'INCREASE'
		| 'INDEX_INCREASE'
		| 'LOST_CLIENT'
		| 'NEW_CLIENT'
		| 'WIN_BACK'
		| 'ADDED'
		| 'ENDED';
	change: number;
	changedBy: number;
	client: ClientIdName;
	rowsChanged?: RowChanged[];
	changedRows?: ChangeRow[];
};

export type Changes = {
	change: number;
	changeWithoutHidden: number;
	changes: Change[];
};

class ARRChangesOnDate extends Resource {
	constructor() {
		super('arrChangesOnDate');
	}

	getChangesOnDate(
		clientId: number,
		date: string,
		activeAgreementId?: number,
		agreementGroupId?: number
	): Promise<{ data: Changes }> {
		return this._getRequest(``, { params: { clientId, date, activeAgreementId, agreementGroupId } });
	}
}

const resource = new ARRChangesOnDate();

export default resource;
