import React, { useMemo } from 'react';
import { Link, Select } from '@upsales/components';
import BemClass from '@upsales/components/Utils/bemClass';
import moment from 'moment';
import './SelectTimeInput.scss';

type Props = {
	value: string | null;
	onChange: (id: string) => void;
	anchor: Element | null;
	min?: string;
	max?: string;
	step?: number;
};

const getDiff = (time: string) => {
	return moment('1991-01-01 ' + time).diff('1991-01-01 00:00', 'minutes');
};

const validateTime = (time: string) => {
	return time && moment(time, 'LT', true).isValid();
};

const generateOptions = (minTime?: string, maxTime?: string, step?: number) => {
	let i = 0;
	let max = 1440;
	const options = [];
	const timeStep = step || 30;

	if (minTime && validateTime(minTime)) {
		i = getDiff(minTime);
	}
	if (maxTime && validateTime(maxTime)) {
		max = getDiff(maxTime) + timeStep;
	}

	while (i < max) {
		const format = moment({
			hour: Math.floor(i / 60),
			minute: i % 60
		}).format('LT');

		options.push({ id: format, title: format });

		i += timeStep;
	}
	return options;
};

const SelectTimeInput = ({ value, onChange, anchor, min, max, step }: Props) => {
	const classes = useMemo(() => new BemClass('SelectTimeInput'), []);
	const selectDropdownClassName = classes.elem('select-dropdown').b();

	const options = useMemo(() => generateOptions(min, max, step), [min, max]);
	const renderTitleTrigger: React.ComponentProps<typeof Select>['renderSelectedItem'] = value => (
		<Link>{value?.title}</Link>
	);

	return (
		<Select<{ id: string; title: string }>
			showSearch={false}
			value={options.find(o => o.id === value) || options[0]}
			anchor={anchor}
			options={options}
			onChange={({ id }) => onChange(id)}
			dropdownClassName={selectDropdownClassName}
			renderSelectedItem={renderTitleTrigger}
			inline
		></Select>
	);
};

export default SelectTimeInput;
