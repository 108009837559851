import getAngularModule from 'App/babel/angularHelpers/getAngularModule';
const comparisonTypes = require('App/babel/resources/RequestBuilder').comparisonTypes;

const FIELDS = ['revenue', 'revenueChange', 'result', 'ebitda', 'profitMargin', 'currentRatio', 'solidity'];
const CURRENCY_FIELDS = { revenue: true, result: true, ebitda: true };

function generateFilter(baseCurrency, useRange, overrides = {}) {
	function generate() {
		const AppService = getAngularModule('AppService');
		const currencies = AppService.getMetadata().customerCurrencies;
		const baseCustomerCurrency = currencies.find(currency => currency.iso === baseCurrency);
		const defaultCurrency = currencies.find(currency => currency.masterCurrency) || baseCustomerCurrency;

		return {
			inactive: true,
			value: {
				currency: baseCustomerCurrency ? defaultCurrency : null,
				revenue: {
					value: {
						start: null,
						end: null
					}
				},
				revenueChange: {
					value: {
						start: null,
						end: null,
						offset: 1
					}
				},
				result: {
					value: {
						start: null,
						end: null
					}
				},
				ebitda: {
					value: {
						start: null,
						end: null
					}
				},
				profitMargin: {
					value: {
						start: null,
						end: null
					}
				},
				currentRatio: {
					value: {
						start: null,
						end: null
					}
				},
				solidity: {
					value: {
						start: null,
						end: null
					}
				}
			}
		};
	}

	function isInactive(filter) {
		return !FIELDS.some(
			field => filter.value[field].value.start !== null || filter.value[field].value.end !== null
		);
	}

	function toUrl(filter) {
		const f = { v: {} };
		const val = filter.value;

		f.i = filter.inactive;

		if (val.revenue.value) {
			f.v.rev = { s: val.revenue.value.start, e: val.revenue.value.end };
		}

		if (val.result.value) {
			f.v.res = { s: val.result.value.start, e: val.result.value.end };
		}

		if (val.ebitda.value) {
			f.v.ebt = { s: val.ebitda.value.start, e: val.ebitda.value.end };
		}

		if (val.profitMargin.value) {
			f.v.pm = { s: val.profitMargin.value.start, e: val.profitMargin.value.end };
		}

		if (val.currentRatio.value) {
			f.v.cr = { s: val.currentRatio.value.start, e: val.currentRatio.value.end };
		}

		if (val.solidity.value) {
			f.v.sol = { s: val.solidity.value.start, e: val.solidity.value.end };
		}

		if (val.revenueChange.value) {
			f.v.rec = {
				s: val.revenueChange.value.start,
				e: val.revenueChange.value.end,
				o: val.revenueChange.value.offset
			};
		}

		return f;
	}

	function fromUrl(rawFilter) {
		const filter = generate();
		const val = rawFilter.v;

		if (val.rev) {
			filter.value.revenue = { start: val.rev.s, end: val.rev.e };
		}

		if (val.res) {
			filter.value.result = { start: val.res.s, end: val.res.e };
		}

		if (val.ebt) {
			filter.value.ebitda = { start: val.ebt.s, end: val.ebt.e };
		}

		if (val.pm) {
			filter.value.profitMargin = { start: val.pm.s, end: val.pm.e };
		}

		if (val.cr) {
			filter.value.currentRatio = { start: val.cr.s, end: val.cr.e };
		}

		if (val.sol) {
			filter.value.solidity = { start: val.sol.s, end: val.sol.e };
		}

		if (val.rec) {
			filter.value.revenueChange = { start: val.rec.s, end: val.rec.e, offset: val.rec.o || 1 };
		}

		filter.inactive = rawFilter.hasOwnProperty('i') ? rawFilter.i : isInactive(filter);

		return filter;
	}

	function build(filter, rb) {
		const value = filter.value;

		if (filter.inactive || !value) {
			return;
		}

		const AppService = getAngularModule('AppService');
		const currencies = AppService.getMetadata().customerCurrencies;
		const baseCustomerCurrency = currencies.find(currency => currency.iso === baseCurrency);

		const selectedCurrency = filter.value.currency;
		const currencyRate =
			baseCustomerCurrency && selectedCurrency ? baseCustomerCurrency.rate / selectedCurrency.rate : 1;

		for (const field of FIELDS) {
			const value = filter.value[field].value;
			const apiField = field === 'revenueChange' ? `revenuePercentChange${value.offset || 1}Year` : field;

			if (apiField === 'revenue' && useRange) {
				const outerOrBuilder = rb.orBuilder();

				// Headquarters = true
				outerOrBuilder.next();
				outerOrBuilder.addFilter({ field: 'revenue' }, comparisonTypes.NotEquals, null);

				if (value.start !== null) {
					outerOrBuilder.addFilter(
						{ field: 'revenue' },
						rb.comparisonTypes.GreaterThanEquals,
						value.start * currencyRate
					);
				}

				if (value.end !== null) {
					outerOrBuilder.addFilter(
						{ field: 'revenue' },
						rb.comparisonTypes.LessThan,
						value.end * currencyRate
					);
				}
				// Headquarters = false
				outerOrBuilder.next();
				outerOrBuilder.addFilter({ field: 'revenue' }, comparisonTypes.Equals, null);

				if (value.start !== null) {
					const innerOrBuilder = outerOrBuilder.orBuilder();
					innerOrBuilder.next();
					innerOrBuilder.addFilter(
						{ field: 'revenueRangeStart' },
						comparisonTypes.GreaterThanEquals,
						value.start
					);
					innerOrBuilder.next();
					innerOrBuilder.addFilter(
						{ field: 'revenueRangeEnd' },
						comparisonTypes.GreaterThanEquals,
						value.start
					);
					innerOrBuilder.done();
				}

				if (value.end !== null) {
					const innerOrBuilder = outerOrBuilder.orBuilder();
					innerOrBuilder.next();
					innerOrBuilder.addFilter({ field: 'revenueRangeStart' }, comparisonTypes.LessThanEquals, value.end);
					innerOrBuilder.next();
					innerOrBuilder.addFilter({ field: 'revenueRangeEnd' }, comparisonTypes.LessThanEquals, value.end);
					innerOrBuilder.done();
				}

				outerOrBuilder.done();
			} else {
				if (value.start !== null) {
					const filterValue = CURRENCY_FIELDS[field] ? value.start * currencyRate : value.start;
					rb.addFilter({ field: apiField }, rb.comparisonTypes.GreaterThanEquals, filterValue);
				}

				if (value.end !== null) {
					const filterValue = CURRENCY_FIELDS[field] ? value.end * currencyRate : value.end;
					rb.addFilter({ field: apiField }, rb.comparisonTypes.LessThan, filterValue);
				}
			}
		}
	}

	const filter = {
		type: 'raw',
		displayType: 'prospectingFinancials',
		title: 'widget.accountEconomy',
		entity: 'account',
		showOnSegment: true,
		fields: FIELDS,
		baseCurrency,
		generate,
		isInactive,
		toUrl,
		fromUrl,
		build
	};

	return Object.assign(filter, overrides);
}

export default generateFilter;
