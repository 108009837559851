const CountryConfig = {
	SE: {
		industryField: 'sniCode',
		currency: 'SEK',
		supportKeywords: true,
		orgNumberPattern: /^\d{6}-?\d{4}$/
	},
	GB: {
		industryField: 'ukSicCode',
		currency: 'GBP',
		supportKeywords: true,
		orgNumberPattern: /^[A-Z]{2}[0-9]{6}$|[0-9]{8}/
	},
	NO: {
		industryField: 'naceCode',
		currency: 'NOK',
		supportKeywords: false,
		orgNumberPattern: /^\d{9}|\d{3} \d{3} \d{3}$/
	}
};

export type Country = keyof typeof CountryConfig;

export enum StatusCategory {
	InactiveOrDeregistered,
	ActiveWithWarning,
	Active
}

export function getAvailableCountries(): Country[] {
	const { isAvailable, Feature } = Tools.FeatureHelper;

	const countries: Country[] = [];

	if (isAvailable(Feature.PROSPECTING_SE)) {
		countries.push('SE');
	}
	if (isAvailable(Feature.PROSPECTING_NO)) {
		countries.push('NO');
	}
	if (isAvailable(Feature.PROSPECTING_GB)) {
		countries.push('GB');
	}

	return countries;
}

export function getCountryFromProspectingId(prospectingId: string | null): Country | null {
	if (typeof prospectingId !== 'string') {
		return null;
	}
	if (prospectingId.startsWith('UK')) {
		return 'GB';
	}
	if (prospectingId.startsWith('SE')) {
		return 'SE';
	}
	if (prospectingId.startsWith('NO')) {
		return 'NO';
	}
	return null;
}

export function getAvailableCountryFromProspectingId(prospectingId: string): Country | null {
	const country = getCountryFromProspectingId(prospectingId);

	if (country === null) {
		return null;
	}

	const countries = getAvailableCountries();
	return countries.includes(country) ? country : null;
}

export function getDefaultIndustryFieldFromCountry(country: Country) {
	switch (country) {
		case 'GB':
		case 'NO':
			return CountryConfig[country].industryField;
		case 'SE':
		default:
			return CountryConfig['SE'].industryField;
	}
}

export function getDefaultIndustryFieldFromProspectingId(prospectingId: string) {
	const country = getCountryFromProspectingId(prospectingId);
	return getDefaultIndustryFieldFromCountry(country ?? 'SE');
}

export function getDefaultCurrencyFromCountry(country: Country) {
	switch (country) {
		case 'GB':
		case 'NO':
			return CountryConfig[country].currency;
		case 'SE':
		default:
			return CountryConfig['SE'].currency;
	}
}

export function getDefaultCurrencyFromProspectingId(prospectingId: string) {
	const country = getCountryFromProspectingId(prospectingId);
	return getDefaultCurrencyFromCountry(country ?? 'SE');
}

export function validateOrgNumberForCountry(country: Country, orgNumber: string) {
	switch (country) {
		case 'GB':
		case 'NO':
			return CountryConfig[country].orgNumberPattern.test(orgNumber);
		case 'SE':
		default:
			return CountryConfig['SE'].orgNumberPattern.test(orgNumber);
	}
}

export function isKeywordsSupportedForCountry(country: Country) {
	return CountryConfig[country]?.supportKeywords ?? false;
}
