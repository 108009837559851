import React, { ComponentProps, useMemo } from 'react';
import { Select } from '@upsales/components';
import { SelectItem } from '@upsales/components/Utils/selectHelpers';
import { useSelectLanguage, CoreSelectItem } from './selectHelpers';

export type StaticSelectProps<T extends SelectItem = SelectItem, TMulti extends boolean = false> = {
	onChange: (value: GetProp<typeof Select<T, TMulti>, 'value'>) => void;
	extraOptions?: T[];
} & Omit<ComponentProps<typeof Select<T, TMulti>>, 'onClear' | 'onChange' | 'onRemove'>;

export type StaticSelectConsumerProps<T = {}> = PartialRequired<
	ComponentProps<typeof StaticSelect<CoreSelectItem<T>>>,
	'value' | 'onChange'
>;

export type StaticMultiSelectConsumerProps<T = {}> = PartialRequired<
	ComponentProps<typeof StaticMultiSelect<CoreSelectItem<T>>>,
	'value' | 'onChange'
>;

function useSharedProps<T extends SelectItem>({
	options,
	extraOptions,
	language
}: Pick<StaticSelectProps<T, false>, 'options' | 'extraOptions' | 'language'>) {
	const finalOptions = useMemo(() => {
		return [...(extraOptions ?? []), ...options];
	}, [extraOptions, options]);

	const finalLang = useSelectLanguage(language);

	return {
		finalOptions,
		finalLang
	};
}

export function StaticSelect<T extends SelectItem>({
	extraOptions,
	value,
	options,
	onChange,
	language = {},
	...selectProps
}: StaticSelectProps<T, false>) {
	const { finalOptions, finalLang } = useSharedProps({ options, extraOptions, language });

	return (
		<Select
			value={value}
			options={finalOptions}
			language={finalLang}
			onChange={v => onChange(v)}
			onClear={() => onChange(null)}
			{...selectProps}
		/>
	);
}

export function StaticMultiSelect<T extends SelectItem>({
	onChange,
	value,
	extraOptions,
	options,
	language = {},
	...selectProps
}: StaticSelectProps<T, true>) {
	const { finalOptions, finalLang } = useSharedProps({ options, extraOptions, language });

	const innerOnChange = (newValue: T) => {
		let finalValue: T[] = [];
		if (newValue) {
			const valueIds = value.map(item => item.id);
			if (valueIds.includes(newValue.id)) {
				finalValue = value.filter(item => item.id !== newValue.id);
			} else {
				finalValue = [...value, newValue];
			}
		} else {
			finalValue = [];
		}
		onChange(finalValue);
	};

	return (
		<Select
			multi
			hideSelected
			value={value}
			options={finalOptions}
			language={finalLang}
			onChange={innerOnChange}
			onClear={() => onChange([])}
			onRemove={id => {
				const newValue = value.filter(item => item.id !== id);
				onChange(newValue);
			}}
			{...selectProps}
		/>
	);
}

export { useSelectLanguage, type CoreSelectItem };
export default StaticSelect;
