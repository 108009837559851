import { CancelablePromise, makeCancelable } from '@upsales/components/Utils/CancelablePromise';
import ClientPlanResource from 'App/babel/resources/ClientPlan';
import BemClass from '@upsales/components/Utils/bemClass';
import ClientPlan from 'App/resources/Model/ClientPlan';
import { Drawer, Loader } from '@upsales/components';
import React, { useEffect, useState } from 'react';
import ClientResource from 'App/resources/Client';
import EditAccountPlan from './EditAccountPlan';
import logError from 'Helpers/logError';

import './EditAccountPlan.scss';

type EditAccountPlanProps = React.ComponentProps<typeof EditAccountPlan>;

type Props = Partial<EditAccountPlanProps> & { clientPlanId?: number; clientId?: number };

/**
 * This component is used to open the EditAccountPlan modal,
 * it should receive either a clientPlanId, a clientId or a plan.
 * If it receives a clientPlanId it will fetch the client plan and open the modal with the fetched plan.
 * If it receives only a clientId it will open the modal with a new client plan.
 * If it receives a plan it will open the modal with the plan.
 * In case it does not receive userEditable it will fetch the client to check if it is userEditable.
 * To do this it will use the clientId or the actualPlan?.clientId.
 */
const OpenAccountPlan = ({ clientId, clientPlanId, userEditable, clientName, plan, ...props }: Props) => {
	const [actualPlan, setActualPlan] = useState<ClientPlan | undefined>(plan);
	const [isUserEditable, serIsUserEditable] = useState<boolean | undefined>(userEditable);
	const [name, setName] = useState<string>(clientName ?? '');
	const [isLoading, setIsLoading] = useState<boolean>(false);
	const classes = new BemClass('EditAccountPlan', props.className);

	useEffect(() => {
		setActualPlan(plan);
	}, [plan]);

	useEffect(() => {
		let clientRequest: CancelablePromise | undefined;
		// if isUserEditable is not defined and clientId or actualPlan?.clientId are defined then we need to get the client to check if it is userEditable
		if ((typeof isUserEditable !== 'boolean' && (clientId || actualPlan?.clientId)) || !clientName) {
			setIsLoading(true);
			clientRequest = makeCancelable(ClientResource.get(clientId ?? actualPlan?.clientId));

			clientRequest.promise
				.then(({ data }) => {
					setName(data?.name);
					serIsUserEditable(data?.userEditable);
				})
				.catch(err => logError(err, 'failed to get client'))
				.finally(() => setIsLoading(false));
		}
		return () => {
			clientRequest?.cancel?.();
		};
	}, [userEditable, actualPlan, clientId]);

	useEffect(() => {
		let getRequest: CancelablePromise | undefined;
		if (clientPlanId) {
			setIsLoading(true);
			getRequest = makeCancelable(ClientPlanResource.get(clientPlanId));
			getRequest.promise
				.then(({ data }) => setActualPlan(data))
				.catch(err => logError(err, 'failed to get client plan'))
				.finally(() => setIsLoading(false));
		}

		return () => {
			getRequest?.cancel?.();
		};
	}, []);

	if (isLoading) {
		return (
			<Drawer className={classes.b()}>
				<div className={classes.elem('loader').b()}>
					<Loader />
				</div>
			</Drawer>
		);
	}

	// if clientPlanId is undefined and actualPlan is undefined then we are creating a new client plan, but we need the clientId
	if (!actualPlan && !clientPlanId && clientId) {
		return (
			<Drawer className={classes.b()}>
				<EditAccountPlan
					{...(props as EditAccountPlanProps)}
					clientName={clientName ?? name}
					clientId={clientId}
					userEditable={isUserEditable}
				/>
			</Drawer>
		);
	}

	// if clientPlanId is defined and actualPlan is defined then we are editing an existing client plan
	return actualPlan ? (
		<Drawer className={classes.b()}>
			<EditAccountPlan
				{...(props as EditAccountPlanProps)}
				plan={actualPlan}
				clientName={clientName ?? name}
				clientId={actualPlan.clientId ?? clientId}
				userEditable={isUserEditable}
			/>
		</Drawer>
	) : null;
};

export default OpenAccountPlan;
