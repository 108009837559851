import { Block, Column, Label, Row, Toggle } from '@upsales/components';
import React, { useMemo } from 'react';
import T from 'Components/Helpers/translate';
import { FormObserverOnFieldChange, FormErrorMessages, InputPropMap } from '../FormObserver/FormObserver';
import UpSegment from 'Components/Inputs/upSegment';
import Segment from 'App/resources/Model/Segment';
import { FormInput, FormTimeInput } from '../FormComponent';
import moment from 'moment';
import { FormComponent } from '../FormComponent/FormComponent';
import UpTimezone from 'Components/Inputs/UpTimezone';
import { Fade } from '../animations';
import { Speed } from '../animations/Animate/Animate';
import './CreateFlowForm.scss';
import BemClass from '@upsales/components/Utils/bemClass';
import CreateFlowUnenrollment from './CreateFlowUnenrollment';
import type { EditableFlow } from './CreateFlow';

type Props = {
	inputProps: InputPropMap<EditableFlow>;
	onFormChange: FormObserverOnFieldChange;
	errorMessages: FormErrorMessages;
	canChangeSegment: boolean;
	visible: boolean;
	anchor: React.RefObject<HTMLDivElement>;
};

const CreateFlowForm = ({ inputProps, onFormChange, errorMessages, canChangeSegment, visible, anchor }: Props) => {
	const classes = useMemo(() => new BemClass('CreateFlowForm'), []);

	const hasFlowGoalFeature = Tools.FeatureHelper.hasSoftDeployAccess('FLOW_GOAL');

	return (
		<div className={classes.b()}>
			<Fade visible={visible} delayOutInMs={0} delayInMs={Speed.normal}>
				<Block>
					<Row>
						<Column>
							<FormInput
								label={T(inputProps.name.label)}
								inputProps={{
									...inputProps.name,
									maxLength: 45,
									state: errorMessages.name ? 'error' : undefined,
									autofocus: true
								}}
								message={errorMessages.name}
							/>
						</Column>
						<Column fixedWidth={12} />
						<Column>
							<FormComponent
								label={T(inputProps.segment?.label ?? '')}
								required={inputProps.segment?.required}
								state={errorMessages.segment ? 'error' : null}
								message={errorMessages.segment}
							>
								<UpSegment
									disabled={canChangeSegment ? false : true}
									className={errorMessages.segment ? 'has-error' : ''}
									onlyWithoutFlows
									onlyActive
									onChange={(segment: Segment) => onFormChange('segment', segment)}
									placeholder={`<i class="Icon Icon-user-friends Space--margin-right-small"></i>${T(
										'flow.newFlow.connectToSegment'
									)}`}
									formatSelection={(segment: Segment, c: unknown, escape: (s: string) => string) =>
										`<i class="Icon Icon-user-friends Space--margin-right-small"></i>${escape(
											segment.name
										)}`
									}
									value={inputProps.segment?.value}
									options={{
										select2: {
											dropdownCssClass: 'inline-edit-select2--dropdown',
											escapeMarkup: (m: string) => m
										}
									}}
								/>
							</FormComponent>
						</Column>
					</Row>
					<Block>
						<Row>
							<Column>
								<Row>
									<Column>
										<FormTimeInput
											label={T('flow.newFlow.whenToSend')}
											message={errorMessages.startTime}
											inputProps={{
												...inputProps.startTime,
												icon: 'clock-o',
												placeholder: T('default.startTime'),
												max: inputProps.endTime.value
													? moment(inputProps.endTime.value, 'LT')
															.subtract(4, 'hours')
															.format('HH:mm')
													: undefined,
												anchor: anchor.current,
												onChange: e => onFormChange('startTime', e.target.value)
											}}
										/>
									</Column>
									<Column fixedWidth={12} />
									<Column>
										<FormTimeInput
											message={errorMessages.endTime}
											inputProps={{
												...inputProps.endTime,
												placeholder: T('default.endTime'),
												min: inputProps.startTime.value
													? moment(inputProps.startTime.value, 'LT')
															.add(4, 'hours')
															.format('HH:mm')
													: undefined,
												anchor: anchor.current,
												onChange: e => onFormChange('endTime', e.target.value)
											}}
											className={classes.elem('hide-label').b()}
										/>
									</Column>
								</Row>
							</Column>
							<Column fixedWidth={12} />
							<Column>
								<FormComponent
									label={T('flow.newFlow.whatTimezone')}
									required={inputProps.timezone.required}
									state={errorMessages.timezone ? 'error' : null}
									message={errorMessages.timezone}
								>
									<UpTimezone
										className={errorMessages.timezone ? 'has-error' : ''}
										placeholder={`<i class="Icon Icon-user-globe Space--margin-right-small"></i>${T(
											'default.timeZone'
										)}`}
										formatSelection={(
											object: { id: string; name: string },
											c: any,
											escape: (s: string) => string
										) => escape(object.name)}
										required
										onChange={(timezone: { id: string; name: string } | null) =>
											onFormChange('timezone', timezone ? timezone.id : timezone)
										}
										value={
											inputProps.timezone.value
												? { id: inputProps.timezone.value, name: inputProps.timezone.value }
												: null
										}
										options={{
											select2: {
												escapeMarkup: (m: string) => m
											}
										}}
									/>
								</FormComponent>
							</Column>
						</Row>
					</Block>
					<Block space="mbxl">
						<Row>
							<Column>
								<Toggle
									onChange={value => onFormChange('skipWeekends', value)}
									checked={inputProps.skipWeekends.value}
									size="lg"
									space="mrm"
								/>
								<Label>{T('flow.newFlow.notOnWeekends')}</Label>
							</Column>
						</Row>
					</Block>
					{hasFlowGoalFeature ? (
						<CreateFlowUnenrollment
							inputProps={inputProps}
							onFormChange={onFormChange}
							anchor={anchor.current}
						/>
					) : (
						<Block />
					)}
				</Block>
			</Fade>
		</div>
	);
};

export default CreateFlowForm;
