import Attribute, { DisplayType, Type } from './Attribute';

const ProjectPlanAttributes = {
	id: Attribute({
		title: 'default.id',
		field: 'id',
		type: Type.Number,
		displayType: DisplayType.Number,
		selectableColumn: true,
		sortable: 'id'
	}),
	name: Attribute({
		title: 'default.name',
		field: 'name',
		type: Type.String,
		displayType: DisplayType.Text,
		sortable: 'name',
		selectableColumn: true
	}),
	startDate: Attribute({
		title: 'default.startDate',
		field: 'startDate',
		type: Type.Date,
		displayType: DisplayType.Date,
		selectableColumn: true,
		sortable: 'startDate'
	}),
	endDate: Attribute({
		title: 'default.dueDate',
		field: 'endDate',
		type: Type.Date,
		displayType: DisplayType.Date,
		selectableColumn: true,
		sortable: 'endDate'
	}),
	user: Attribute({
		type: Type.Object,
		displayType: DisplayType.User,
		field: 'user',
		title: 'projectPlan.projectManager',
		sortable: 'user.name',
		placeholder: 'default.noProjectManager',
		selectableColumn: true,
		attr: {
			id: Attribute({
				field: 'user.id',
				type: Type.Number
			}),
			name: Attribute({
				field: 'user.name',
				type: Type.String
			})
		}
	}),
	contactAndClient: Attribute({
		title: 'default.contactAndClient',
		field: 'contactAndClient',
		type: Type.Object,
		selectableColumn: true,
		sortable: 'client.name'
	}),
	openTasks: Attribute({
		title: 'default.openTasks',
		field: 'openTasks',
		type: Type.Number,
		selectableColumn: true,
		sortable: 'openTasks'
	}),
	finishedTasks: Attribute({
		title: 'default.finishedTasks',
		field: 'finishedTasks',
		type: Type.Number,
		selectableColumn: true,
		sortable: 'finishedTasks'
	}),
	openTasksAndFinishedTasks: Attribute({
		title: 'report.widget.progress',
		field: 'openTasks',
		type: Type.Number,
		selectableColumn: true,
		sortable: 'openTasks',
		tooltip: 'projectPlan.list.progressHeaderTooltip'
	}),
	client: Attribute({
		type: Type.Object,
		displayType: DisplayType.ClientLink,
		field: 'client',
		title: 'default.client',
		sortable: 'client.name',
		placeholder: 'default.noAccount',
		selectableColumn: true,
		attr: {
			id: Attribute({
				field: 'client.id',
				type: Type.Number
			}),
			name: Attribute({
				field: 'client.name',
				type: Type.String
			})
		}
	}),
	contact: Attribute({
		type: Type.Object,
		displayType: DisplayType.ContactLink,
		field: 'contact',
		title: 'default.contact',
		sortable: 'contact.name',
		placeholder: 'default.noContact',
		selectableColumn: true,
		attr: {
			id: Attribute({
				field: 'contact.id',
				type: Type.Number
			}),
			name: Attribute({
				field: 'contact.name',
				type: Type.String
			})
		}
	}),
	order: Attribute({
		type: Type.Object,
		title: 'default.order',
		field: 'order',
		displayType: DisplayType.Order,
		sortable: 'order.description',
		selectableColumn: true,
		attr: {
			id: Attribute({
				field: 'order.id',
				type: Type.Number
			}),
			description: Attribute({
				field: 'order.description',
				type: Type.Number
			})
		}
	}),
	projectPlanStage: Attribute({
		type: Type.Object,
		title: 'projectPlan.projectStage',
		field: 'projectPlanStage.name',
		displayType: DisplayType.Text,
		displayKey: 'projectPlanStage.name',
		sortable: 'projectPlanStage.name',
		selectableColumn: true,
		attr: {
			id: Attribute({
				field: 'projectPlanStage.id',
				type: Type.Number
			}),
			name: Attribute({
				field: 'projectPlanStage.name',
				type: Type.Number
			})
		}
	}),
	projectPlanTemplate: Attribute({
		type: Type.Object,
		title: 'default.projectPlanTemplate',
		field: 'projectPlanTemplate',
		displayType: DisplayType.Text,
		displayKey: 'projectPlanTemplate.name',
		sortable: 'projectPlanTemplate.name',
		selectableColumn: true,
		attr: {
			id: Attribute({
				field: 'projectPlanTemplate.id',
				type: Type.Number
			}),
			name: Attribute({
				field: 'projectPlanTemplate.name',
				type: Type.String
			}),
			offsetEndDate: Attribute({
				field: 'projectPlanTemplate.offsetEndDate',
				type: Type.Boolean
			})
		}
	}),
	projectPlanPriority: Attribute({
		type: Type.Object,
		title: 'default.projectPlanPriority',
		field: 'projectPlanPriority.name',
		displayType: DisplayType.Text,
		displayKey: 'projectPlanPriority.name',
		sortable: 'projectPlanPriority.name',
		selectableColumn: false,
		attr: {
			id: Attribute({
				field: 'projectPlanPriority.id',
				type: Type.Number
			}),
			name: Attribute({
				field: 'projectPlanPriority.name',
				type: Type.String
			}),
			category: Attribute({
				field: 'projectPlanPriority.category',
				type: Type.String
			}),
			isDefault: Attribute({
				field: 'projectPlanPriority.isDefault',
				type: Type.Boolean
			})
		}
	}),
	projectPlanStatus: Attribute({
		type: Type.Object,
		title: 'default.projectPlanStatus',
		field: 'projectPlanStatus.name',
		displayType: DisplayType.Text,
		displayKey: 'projectPlanStatus.name',
		sortable: 'projectPlanStatus.name',
		selectableColumn: false,
		attr: {
			id: Attribute({
				field: 'projectPlanStatus.id',
				type: Type.Number
			}),
			name: Attribute({
				field: 'projectPlanStatus.name',
				type: Type.String
			}),
			category: Attribute({
				field: 'projectPlanStatus.category',
				type: Type.String
			}),
			isDefault: Attribute({
				field: 'projectPlanStatus.isDefault',
				type: Type.Boolean
			})
		}
	}),
	notes: Attribute({
		title: 'default.notes',
		field: 'notes',
		type: Type.String,
		displayType: DisplayType.Text,
		sortable: 'notes',
		selectableColumn: false
	}),
	regDate: Attribute({
		title: 'default.regDate',
		field: 'regDate',
		type: Type.Date,
		displayType: DisplayType.Date,
		sortable: 'regDate',
		selectableColumn: false
	}),
	regBy: Attribute({
		type: Type.Object,
		displayType: DisplayType.User,
		field: 'user',
		title: 'default.regBy',
		sortable: 'regBy.name',
		selectableColumn: false,
		attr: {
			id: Attribute({
				field: 'regBy.id',
				type: Type.Number
			}),
			name: Attribute({
				field: 'regBy.name',
				type: Type.String
			})
		}
	}),
	modDate: Attribute({
		title: 'default.modDate',
		field: 'modDate',
		type: Type.Date,
		displayType: DisplayType.Date,
		sortable: 'modDate',
		selectableColumn: false
	}),
	modBy: Attribute({
		type: Type.Object,
		displayType: DisplayType.User,
		field: 'user',
		title: 'default.modBy',
		sortable: 'modBy.name',
		selectableColumn: false,
		attr: {
			id: Attribute({
				field: 'modBy.id',
				type: Type.Number
			}),
			name: Attribute({
				field: 'modBy.name',
				type: Type.String
			})
		}
	}),
	custom: Attribute({
		type: Type.Array,
		field: 'custom'
	})
};

export default ProjectPlanAttributes;
