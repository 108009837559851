import { Button, Icon, Headline, Text, Tooltip, ButtonBox } from '@upsales/components';
import { useSubscriptionGroupContext } from '../../Context/SubscriptionGroupContext';
import React, { useEffect, useMemo, useState } from 'react';
import { PrimaryButton } from '@upsales/components/Buttons';
import SubscriptionHotspot from '../../SubscriptionHotspot';
import BemClass from '@upsales/components/Utils/bemClass';
import T from 'Components/Helpers/translate';
import SplitDateRows from './SplitDateRows';
import moment from 'moment';

import './SplitSubscriptionPage.scss';

type Props = {
	closePage: () => void;
};

const SplitSubscriptionPage = ({ closePage }: Props) => {
	const classes = new BemClass('SplitSubscriptionPage');

	const {
		state: { subscriptionMap },
		currentPeriod,
		lastPeriod,
		firstPeriod,
		setPeriodChildren,
		addFuturePeriod,
		updateCurrent
	} = useSubscriptionGroupContext();

	// If periodId is 0 then we add a new period
	const currentPeriodIsActive = !currentPeriod.endDate || moment(currentPeriod.endDate).isAfter(moment(), 'day');
	const [periodId, setPeriodId] = useState(
		currentPeriod.periodLength !== 0 && currentPeriodIsActive ? currentPeriod.uuid : 0
	);
	const [onFirstStep, setOnFirstStep] = useState(true);
	const [dateRows, setDateRows] = useState<Date[]>([]);

	const scrollElement = (document.getElementsByClassName('OpenSubscription') ?? [])[0];

	const startLastDate = useMemo(() => {
		let startDate, endDate;

		if (!periodId) {
			const lastDate = lastPeriod.endDate ?? lastPeriod.renewalDate;
			startDate = moment(lastDate).toDate();
			endDate = moment(lastDate)
				.add(lastPeriod.periodLength || 1, 'months')
				.toDate();
		} else {
			const chosenPeriod = subscriptionMap[periodId];
			startDate = moment(chosenPeriod.invoiceStartDate).toDate();
			endDate = moment(chosenPeriod.endDate ?? chosenPeriod.renewalDate).toDate();
		}

		return { startDate, endDate };
	}, [periodId]);

	useEffect(() => {
		scrollElement?.scroll(0, 0);
	}, []);

	const goToSecondPage = () => {
		const hasStarted = moment(startLastDate.startDate).isBefore(moment(), 'day');
		const hasEnded = moment(startLastDate.endDate).isBefore(moment(), 'day');

		setDateRows([hasStarted && !hasEnded ? new Date() : startLastDate.startDate, startLastDate.endDate]);
		setOnFirstStep(false);
	};

	const scrollToProducts = () => {
		setTimeout(() => {
			const headerElement = document.getElementById('headline-id-for-scrolling');
			const scrollOffset = 95;
			const scrollDistance =
				(headerElement?.getBoundingClientRect().top ?? 0) + scrollElement.scrollTop - scrollOffset;
			scrollElement?.scroll({ top: scrollDistance, behavior: 'smooth' });
		}, 500);
	};

	const updateChildrenPeriods = () => {
		const currentChildren = currentPeriod?.children ?? [];
		const addedStartDate = moment(dateRows[0]).format();
		const addedLastDate = moment(dateRows[dateRows.length - 1]).format();
		let childrenPeriods: { startDate: string; endDate: string }[] = [...currentChildren];
		let addLeftChildStartDate, addRightChildStartDate;

		let intervalStart = addedStartDate;
		let intervalEnd = addedLastDate;

		let childIndexNr = 0;
		for (let i = currentChildren.length - 1; i >= 0; i--) {
			if (moment(addedStartDate).isSameOrAfter(currentChildren[i].startDate, 'day')) {
				intervalStart = currentChildren[i].startDate;
				intervalEnd = currentChildren[i].endDate;

				if (!moment(intervalStart).isSame(addedStartDate, 'day')) {
					childrenPeriods[i].endDate = addedStartDate;
					addLeftChildStartDate = addedStartDate;
					childIndexNr = i + 1;
				}
				if (!moment(intervalEnd).isSame(addedLastDate, 'day')) {
					if (!addLeftChildStartDate) {
						childrenPeriods[i].endDate = addedLastDate;
						childIndexNr = i;
					}
					addRightChildStartDate = addedLastDate;
				}
				break;
			}
		}

		if (addLeftChildStartDate) {
			childrenPeriods.push({ startDate: addLeftChildStartDate, endDate: addRightChildStartDate ?? intervalEnd });
		}
		if (addRightChildStartDate) {
			childrenPeriods.push({ startDate: addRightChildStartDate, endDate: intervalEnd });
		}

		childrenPeriods = childrenPeriods.sort((a, b) => (moment(a.startDate).isBefore(b.startDate) ? -1 : 1));
		setPeriodChildren(childrenPeriods, periodId, childIndexNr);
	};

	const createChildrenPeriods = () => {
		const getDateObject = (start: Date, end: Date) => ({
			startDate: moment(start).format(),
			endDate: moment(end).format()
		});

		const firstDate = dateRows[0];
		const lastDate = dateRows[dateRows.length - 1];
		const childrenPeriods = [];

		// Create the children from the date rows
		if (!moment(firstDate).isSame(startLastDate.startDate)) {
			childrenPeriods.push(getDateObject(startLastDate.startDate, dateRows[0]));
		}
		for (let i = 0; i < dateRows.length - 1; i++) {
			childrenPeriods.push(getDateObject(dateRows[i], dateRows[i + 1]));
		}
		if (moment(lastDate).isBefore(startLastDate.endDate)) {
			childrenPeriods.push(getDateObject(lastDate, startLastDate.endDate));
		}

		setPeriodChildren(childrenPeriods, periodId, 1);
		scrollToProducts();
	};

	const save = () => {
		if (currentPeriod.children?.length) {
			updateChildrenPeriods();
		} else {
			createChildrenPeriods();
		}
		closePage();
	};

	const renderBottomPart = () => {
		const displayButtonText = () => {
			if (periodId === 0) {
				return T('subscription.modal.summary.splitPage.planRenewedPeriod');
			}
			return onFirstStep
				? T('subscription.modal.summary.splitPage.chooseDayForTheChange')
				: T('subscription.modal.summary.splitPage.planChange');
		};

		const onClick = () => {
			if (onFirstStep && !periodId) {
				addFuturePeriod();
				closePage();
				return;
			}

			if (onFirstStep) {
				return goToSecondPage();
			}

			save();
		};

		const disablePrimaryButton =
			!onFirstStep &&
			moment(dateRows[0]).isSame(startLastDate.startDate) &&
			moment(dateRows[1]).isSame(startLastDate.endDate);
		return (
			<div className={classes.elem('bottom').b()}>
				<Button onClick={closePage} type="link">
					{T('default.abort')}
				</Button>
				<Tooltip
					disabled={!disablePrimaryButton}
					title={T('subscription.modal.summary.splitPage.saveButtonDisabled')}
				>
					<PrimaryButton disabled={disablePrimaryButton} onClick={onClick}>
						<Icon color="white" space="mrl" name={onFirstStep && periodId ? 'arrow-right' : 'save'} />
						{displayButtonText()}
					</PrimaryButton>
				</Tooltip>
			</div>
		);
	};

	const renderCheckboxButtons = (hide: boolean) => {
		const lastPeriodEndDate = lastPeriod.endDate ?? lastPeriod.renewalDate;
		const periods = Object.values(subscriptionMap).filter(
			p => !p.endDate || moment(p.endDate).isAfter(moment(), 'day')
		);

		return (
			<div className={classes.elem('boxes').mod({ hide }).b()}>
				<div className={classes.elem('boxes-grid').mod({ hide }).b()}>
					{periods.map((p, i) => (
						<Tooltip
							key={p.uuid}
							distance={-25}
							disabled={p.periodLength > 0}
							title={T('subscription.modal.summary.splitPage.checkboxCardDisabled')}
						>
							<ButtonBox
								title={T(
									`subscription.modal.summary.splitPage.${
										i ? 'withinTheRenewedPeriod' : 'withinTheCurrentPeriod'
									}`
								)}
								subtitle={`${moment(p.invoiceStartDate).format('L')} - ${moment(
									p.endDate ?? p.renewalDate
								).format('L')}`}
								disabled={p.periodLength === 0}
								selected={periodId === p.uuid}
								onClick={() => {
									if (p.periodLength === 0) return;
									updateCurrent(p.uuid);
									setPeriodId(p.uuid);
								}}
							>
								<SubscriptionHotspot type="currentChange" />
							</ButtonBox>
						</Tooltip>
					))}
					<ButtonBox
						title={T('subscription.modal.summary.splitPage.planRenewedPeriod')}
						subtitle={`${moment(lastPeriodEndDate).format('L')} - `}
						selected={periodId === 0}
						onClick={() => setPeriodId(0)}
					>
						<SubscriptionHotspot type="futureChange" />
					</ButtonBox>
				</div>
				{renderBottomPart()}
			</div>
		);
	};

	const renderContent = () => {
		return (
			<>
				{renderCheckboxButtons(!onFirstStep)}
				<SplitDateRows
					renderBottomPart={renderBottomPart}
					startDate={startLastDate.startDate}
					endDate={startLastDate.endDate}
					dateRows={dateRows}
					setDateRows={setDateRows}
					hide={onFirstStep}
					currentChildren={currentPeriod.children}
				/>
			</>
		);
	};

	const getHeadlineSubtitle = () => {
		const isFirst = firstPeriod.uuid === periodId;
		const mainText = T(`subscription.modal.summary.splitPage.changeWithin${isFirst ? 'Current' : 'Renewed'}Period`);
		return `${mainText}: ${moment(startLastDate.startDate).format('L')} - ${moment(startLastDate.endDate).format(
			'L'
		)}`;
	};

	return (
		<div className={classes.b()}>
			<div
				onClick={
					!onFirstStep
						? () => {
								setDateRows([]);
								setOnFirstStep(true);
						  }
						: undefined
				}
				className={classes.elem('headline').mod({ backButton: !onFirstStep }).b()}
			>
				<Icon name="arrow-left" />
				<div className={classes.elem('headline').elem('text').mod({ lastStep: !onFirstStep }).b()}>
					<Headline size="sm">{T('subscription.modal.summary.splitPage.whenWillTheChangeApply')}</Headline>
					<div>
						<Headline size="sm">{T('subscription.modal.summary.splitPage.chooseDayForTheChange')}</Headline>
						<Text size="sm" color="grey-11">
							{getHeadlineSubtitle()}
						</Text>
					</div>
				</div>
			</div>
			{renderContent()}
		</div>
	);
};

export default SplitSubscriptionPage;
