/* Used to check if an activity should have then phone icon */
export const containsPhoneWords = word => {
	if (!word) {
		return false;
	}
	const wordLowerCase = word.toLowerCase();
	return (
		wordLowerCase.indexOf('telefon') !== -1 ||
		wordLowerCase.indexOf('phone') !== -1 ||
		wordLowerCase.indexOf('ring') !== -1 ||
		wordLowerCase.indexOf('call') !== -1
	);
};
