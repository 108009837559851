enum BOUNCE_REASONS {
	FULL_INBOX = 'full_inbox',
	MESSAGE_TO_LARGE = 'message_to_large',
	INVALID_RECIPIENT = 'invalid_recipient',
	UNKNOWN = 'unknown',
	SPAM = 'spam',
	TEMPORARY_BLOCKED = 'temporary_blocked'
}

export const invalidRecipentCodes = ['5.1.0', '5.1.10', '5.1.1', '5.1.6', '5.1.7', '5.2.1'];

const CONFIGURATIONS = [
	{
		result: BOUNCE_REASONS.UNKNOWN,
		phrases: [
			'requested action aborted',
			"domain isn't in my list of allowed rcpthosts",
			'esta casilla ha expirado por falta de uso',
			'host ?name is unknown',
			'no relaying allowed',
			'no such domain',
			'not our customer',
			'relay not permitted',
			'relay access denied',
			'relaying denied',
			'relaying not allowed',
			'this system is not configured to relay mail',
			'unable to relay',
			'unrouteable mail domain',
			'we do not relay',
			"couldn't find any host named",
			"couldn't find any host by that name",
			'perm_failure: dns error',
			'temporary lookup failure',
			'unrouteable address',
			"can't connect to",
			'local policy violation',
			'mailbox currently suspended',
			'mail can not be delivered',
			'delivery failed',
			"mail couldn't be delivered",
			'too many hops'
		],
		statusCodes: ['5.0.0', '5.2.0', '5.5.0', '5.4.6']
	},
	{
		result: BOUNCE_REASONS.FULL_INBOX,
		phrases: [
			'quota exceeded',
			'over quota',
			'mailbox is full',
			'mailbox full',
			'quotaexceeded',
			'mailbox size limit exceeded',
			'benutzer hat zuviele mails auf dem server',
			'exceeded storage allocation',
			'mailbox quota usage exceeded',
			'quota violation',
			'user has exhausted allowed storage space',
			'user has too many messages on the server',
			'user mailbox exceeds allowed size',
			'mailfolder is full',
			'user has exceeded',
			'not enough storage space'
		],
		statusCodes: ['4.2.2', '5.2.2']
	},
	{
		result: BOUNCE_REASONS.MESSAGE_TO_LARGE,
		phrases: [],
		statusCodes: ['5.3.4']
	},
	{
		result: BOUNCE_REASONS.INVALID_RECIPIENT,
		phrases: [
			'recipnotfound',
			'recipientnotfound',
			'the email account that you tried to reach does not exist',
			'mailbox unavailable',
			'554 delivery error:',
			'account has been disabled',
			'account is unavailable',
			'account not found',
			'address invalid',
			'address is unknown',
			'address unknown',
			'addressee unknown',
			'address_not_found',
			'bad address',
			'bad destination mailbox address',
			'destin. sconosciuto',
			'destinatario errato',
			'destinatario sconosciuto o mailbox disatttivata',
			'does not exist',
			'email address was not found',
			'excessive userid unknowns',
			'indirizzo inesistente',
			'invalid account',
			'invalid address',
			'invalid or unknown virtual user',
			'invalid mailbox',
			'invalid recipient',
			'invalid email address',
			'mailbox not found',
			'mailbox unavailable',
			'nie istnieje',
			'nie ma takiego konta',
			'no mail box available for this user',
			'no mailbox here',
			'no one with that email address here',
			'no such address',
			'no such email address',
			'no such mail drop defined',
			'no such mailbox',
			'no such person at this address',
			'no such recipient',
			'no such user',
			'not a known user',
			'not a valid mailbox',
			'not a valid user',
			'not available',
			'not exists',
			'recipient address rejected',
			'recipient not allowed',
			'recipient not found',
			'recipient rejected',
			'recipient unknown',
			"server doesn't handle mail for that user",
			'this account is disabled',
			'this address no longer accepts mail',
			'this email address is not known to this system',
			'unknown account',
			'unknown address or alias',
			'unknown email address',
			'unknown local part',
			'unknown or illegal alias',
			'unknown or illegal user',
			'unknown recipient',
			'unknown user',
			'user disabled',
			"user doesn't exist in this server",
			'user invalid',
			'user is suspended',
			'user is unknown',
			'user not found',
			'user not known',
			'user unknown',
			'valid rcpt command must precede data',
			'was not found in ldap server',
			'we are sorry but the address is invalid',
			'unable to find alias user',
			'smtputf8 argument required',
			'unable to get mx info',
			'old address no longer valid',
			'recipient no longer on server',
			'account inactive',
			'adressat unbekannt oder mailbox deaktiviert',
			'destinataire inconnu ou boite aux lettres desactivee',
			'mail is not currently being accepted for this mailbox',
			'el usuario esta en estado: inactivo',
			'email account that you tried to reach is disabled',
			'inactive user',
			'mailbox disabled for this recipient',
			'mailbox has been blocked due to inactivity',
			'mailbox is currently unavailable',
			'mailbox is inactive',
			'mailbox locked or suspended',
			'mailbox temporarily disabled',
			'podane konto jest zablokowane administracyjnie lub nieaktywne',
			"questo indirizzo e' bloccato per inutilizzo",
			'recipient mailbox was disabled',
			'account disabled',
			'mailbox is disabled',
			'deactivated mailbox',
			'the email account that you tried to reach is disabled'
		],
		statusCodes: invalidRecipentCodes
	},
	{
		result: BOUNCE_REASONS.SPAM,
		phrases: [
			'spam',
			'blocked message',
			'550 ou-00',
			'550 sc-00',
			'550 dy-00',
			'554 denied',
			'you have been blocked by the recipient',
			'requires that you verify',
			'access denied',
			'administrative prohibition - unable to validate recipient',
			'blacklisted',
			'blocked for spam',
			'conection refused',
			'connection refused due to abuse',
			'dial-up or dynamic-ip denied',
			'domain has received too many bounces',
			'failed several antispam checks',
			'found in a dns blacklist',
			'ips blocked',
			'is blocked by',
			'mail refused',
			'message does not pass domainkeys',
			'message looks like spam',
			'message refused by',
			'not allowed access from your location',
			'permanently deferred',
			'rejected by policy',
			'rejected by windows live hotmail for policy reasons',
			'rejected for policy reasons',
			'rejecting banned content',
			'sorry, looks like spam',
			'spam message discarded',
			'too many spams from your ip',
			'transaction failed',
			'transaction rejected',
			'wiadomosc zostala odrzucona przez system antyspamowy',
			'your message was declared spam'
		],
		statusCodes: ['5.7.1']
	},
	{
		result: BOUNCE_REASONS.TEMPORARY_BLOCKED,
		phrases: ['greylist', 'greylisted', 'temporary blocked', 'temporarily blocked'],
		statusCodes: ['4.7.1']
	}
];

function getStatusCode(reason: string) {
	const match = reason.match(
		/^\d{3} #?(?<secondDotted>\d{1}.\d{1}.\d{1,3})|(?<notDotted>^\d{3})|(?<dotted>^\d{1}.\d{1}.\d{1,3})/
	);
	if (match === null) {
		return '5.0.0';
	}
	return (
		match?.groups?.secondDotted || match?.groups?.dotted || match?.groups?.notDotted.split('').join('.') || '5.0.0'
	);
}

function checkPhrases(phrases: string[], reason: string) {
	return phrases.some(phrase => reason.includes(phrase));
}

function checkStatusCodes(statusCodes: string[], statusCode: string) {
	return statusCodes.includes(statusCode);
}

export default function parse(rawReason: string = '') {
	const reason = rawReason.toLowerCase();
	const statusCode = getStatusCode(reason);

	for (const { statusCodes, phrases, result } of CONFIGURATIONS) {
		if (checkStatusCodes(statusCodes, statusCode) || checkPhrases(phrases, reason)) {
			return result;
		}
	}

	return BOUNCE_REASONS.UNKNOWN;
}
