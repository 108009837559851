import { MergeAccount as BuyFromDataSource } from '../../services/BuyFromDataSource';
import Prospecting from 'App/babel/resources/Prospecting';

export const initialState = {
	accounts: {},
	dataSource: null,
	dataSourceLoading: false
};

const actionPrefix = '[BuyFromDataSourceInline]';
const START_LOADING_DATA_SOURCE = `${actionPrefix} START_LOADING_DATA_SOURCE`;
const FINISHED_LOADING_DATA_SOURCE = `${actionPrefix} FINISHED_LOADING_DATA_SOURCE`;
const SET_STATE = `${actionPrefix} SET_STATE`;

export const loadDataSource = id => async dispatch => {
	try {
		dispatch({ type: START_LOADING_DATA_SOURCE });
		const { data } = await Tools.StandardIntegration.get(id);
		dispatch({ type: FINISHED_LOADING_DATA_SOURCE, data });
	} catch (error) {
		dispatch({ type: FINISHED_LOADING_DATA_SOURCE, data: null });
		console.error('ui/app/babel/store/reducers/BuyFromDataSourceReducer.js - loadDataSource:', error);
	}
};

export const initAccount = account => {
	return { type: SET_STATE, data: { id: account.id, loading: false, accountsWithSimilarDuns: [] } };
};

export async function onAdd(account, mergeAccount = null) {
	if (mergeAccount) {
		const customerId = Tools.AppService.getCustomerId();
		await Tools.Account.customer(customerId).merge(mergeAccount, account);
	}
	const buyAccount = mergeAccount ? mergeAccount : account;

	if (buyAccount.prospectingId && Tools.FeatureHelper.isAvailable(Tools.FeatureHelper.Feature.PROSPECTING_BASIC)) {
		await Prospecting.save({ id: buyAccount.id, prospectingId: buyAccount.prospectingId, customValues: [] });
	} else {
		await BuyFromDataSource({ dataSource: { id: 'soliditet' }, account: buyAccount, type: 'refresh' });
	}
	return account.id;
}

export const getSimilarClients = account => {
	const customerId = Tools.AppService.getCustomerId();
	const rb = new Tools.RequestBuilder();
	rb.fields = ['id', 'name', 'prospectingId'];

	const orBuilder = rb.orBuilder();
	orBuilder.next();
	orBuilder.addFilter({ field: 'dunsNo' }, rb.comparisonTypes.Equals, account.dunsNo);
	if (account.prospectingId && Tools.FeatureHelper.isAvailable(Tools.FeatureHelper.Feature.PROSPECTING_BASIC)) {
		orBuilder.next();
		orBuilder.addFilter({ field: 'prospectingId' }, rb.comparisonTypes.Equals, account.prospectingId);
	}
	orBuilder.done();

	rb.addFilter({ field: 'id' }, rb.comparisonTypes.NotEquals, account.id);
	return Tools.Account.customer(customerId).find(rb.build());
};

export const onNordicChoiseClick = (closeDropDownMenu, account, mergeAccount) => async (dispatch, getState) => {
	const state = getState().BuyFromDataSourceInline.accounts[account.id];

	try {
		dispatch({ type: SET_STATE, data: { ...state, loading: true } });
		closeDropDownMenu();
		const res = await onAdd(account, mergeAccount);
		dispatch({ type: SET_STATE, data: { ...state, loading: false } });
		return res;
	} catch (error) {
		dispatch({ type: SET_STATE, data: { ...state, loading: false } });
		console.error('ui/app/babel/store/reducers/BuyFromDataSourceReducer.js - onNordicChoiseClick:', error);
	}
};

export const onNordicMainClick = (event, setExpanded, account) => async (dispatch, getState) => {
	const state = getState().BuyFromDataSourceInline.accounts[account.id];

	try {
		dispatch({ type: SET_STATE, data: { ...state, loading: true } });
		const { data: accountsWithSimilarDuns } = await getSimilarClients(account);
		// If we have more then one we show them a drop down of options
		if (accountsWithSimilarDuns.length > 1) {
			dispatch({ type: SET_STATE, data: { ...state, loading: false, accountsWithSimilarDuns } });
			setExpanded(event);
			return;
		}
		dispatch({ type: SET_STATE, data: { ...state, loading: true, accountsWithSimilarDuns } });
		// If we have only one or none we just run onAdd
		const mergeAccount = accountsWithSimilarDuns.length ? accountsWithSimilarDuns[0] : null;
		const res = await onAdd(account, mergeAccount);
		dispatch({ type: SET_STATE, data: { ...state, loading: false, accountsWithSimilarDuns } });
		return res;
	} catch (error) {
		dispatch({ type: SET_STATE, data: { ...state, loading: false } });
		console.error('ui/app/babel/store/reducers/BuyFromDataSourceReducer.js - onNordicMainClick:', error);
	}
};

export default (state = initialState, action) => {
	switch (action.type) {
		case START_LOADING_DATA_SOURCE:
			return { ...state, dataSourceLoading: true };
		case FINISHED_LOADING_DATA_SOURCE:
			return { ...state, dataSourceLoading: false, dataSource: action.data };
		case SET_STATE:
			return { ...state, accounts: { ...state.accounts, [action.data.id]: action.data } };
		default:
			return state;
	}
};
