import RequestBuilder from 'Resources/RequestBuilder';
import T from 'Components/Helpers/translate';
import AlertConfirm from 'Components/Dialogs/AlertConfirm';
import { MultiSelect } from 'App/components/ListView/ListViewRenderHelpers';
import NotificationService from 'App/babel/NotificationService';
import ContactAttributes from 'App/babel/attributes/Contact';
import getAngularModule from 'App/babel/angularHelpers/getAngularModule';
import AnonFieldPage from 'App/babel/components/anonymization/fieldPage/fieldPage';
import _ from 'lodash';
import { Attr } from 'App/babel/attributes/Attribute';
import openModal from 'App/services/Modal';

export default async function anonymizeContacts(requestBuilder: RequestBuilder, multiSelect: MultiSelect) {
	const metadata = Tools.AppService.getMetadata();

	const entities = {
		udos: metadata.params.UserDefinedObject,
		contact: ContactAttributes,
		activity: getAngularModule('ActivityAttributes')().attr,
		appointment: getAngularModule('AppointmentAttributes')().attr,
		order: getAngularModule('OrderAttributes')().attr,
		opportunity: getAngularModule('OpportunityAttributes')().attr,
		mail: getAngularModule('MailAttributes')().attr,
		formsubmit: ['fields'],
		esign: ['fields'],
		document: ['fields'],
		event: getAngularModule('EventAttributes')().attr,
		agreement: getAngularModule('AgreementAttributes')().attr
	} as Record<string, Record<string, Attr> | typeof metadata.params.UserDefinedObject | ['fields']>;

	const obligatoryFields = [
		{ name: 'column.name', field: 'name', parent: 'contact' },
		{ name: 'default.title', field: 'title', parent: 'contact' },
		{ name: 'default.phone', field: 'phone', parent: 'contact' },
		{ name: 'default.cellPhone', field: 'cellPhone', parent: 'contact' },
		{ name: 'default.email', field: 'email', parent: 'contact' },
		{ name: 'default.notes', field: 'notes', parent: 'contact' },
		{ name: 'admin.anonymization.customFields', field: 'custom', parent: 'contact' }
	];

	const opts = {
		type: 'confirm',
		id: 'must-have-fields',
		body: T('admin.anonymization.go.error.body'),
		title: T('admin.anonymization.go.error.title'),
		dialog: AnonFieldPage,
		selectedFields: [],
		entities: entities,
		obligatoryFields,
		selectField: () => {},
		selectAllFields: () => {},
		reactive: true,
		isModal: true
	};

	const getAnonymizedText = function (field: string) {
		const types = {
			name: T('admin.anonymization.fields.name'),
			description: T('admin.anonymization.fields.description')
		} as Record<string, string>;

		return types[field] ?? '';
	};

	const generatePropertiesConfig = function (groupedFields: Record<string, Array<Attr & { isUdo: boolean }>>) {
		const object: Record<string, Record<string, string>> = {};

		_.each(groupedFields, function (fields, key) {
			let objectKey = key![0].toUpperCase() + key!.slice(1, key!.length);
			if (fields[0].hasOwnProperty('isUdo') && fields[0].isUdo === true) {
				objectKey =
					fields[0].field[0].toUpperCase() +
					fields[0].field.slice(1, fields[0].field.length).replace('_', '');
			}
			object[objectKey] = {};
			_.each(fields, function (f) {
				var fieldKey = f.field[0].toUpperCase() + f.field.slice(1, f.field.length);
				object[objectKey][fieldKey] = getAnonymizedText(f.field);
			});
		});

		return object;
	};

	const onConfirm = async (alertResponse: any) => {
		if (multiSelect.selectedIds && multiSelect.selectedIds.length) {
			requestBuilder.addFilter({ field: 'id' }, requestBuilder.comparisonTypes.Equals, multiSelect.selectedIds);
		}
		alertResponse.fields.push({ name: 'default.email', field: 'to', parent: 'mail' });

		const actionObject = {
			objects: multiSelect.selectedIds,
			filter: requestBuilder.build(),
			properties: generatePropertiesConfig(_.groupBy(alertResponse.fields, 'parent'))
		};

		try {
			const actionResponse = await Tools.MultiActions.customer(Tools.AppService.getCustomerId()).anonymize(
				actionObject
			);
			NotificationService.add({
				style: NotificationService.style.SUCCESS,
				icon: 'check',
				title: 'default.saved',
				body: multiSelect.selectedIds.length ? 'anonymization.done' : 'updateQued.anonymization'
			});

			// multiSelect.selectNone();
			return actionResponse.metadata;
		} catch (e) {
			NotificationService.add({
				style: NotificationService.style.ERROR,
				icon: 'times',
				title: 'default.error',
				body: e
			});
			throw e;
		}
	};

	const onAnonPageResponse = async (anonPageResponse?: any) => {
		if (!anonPageResponse) {
			return;
		}
		if (anonPageResponse.type === 'gotFields' && anonPageResponse.fields.length > 0) {
			const alertOpts = {
				type: 'confirm',
				reactive: true,
				dialog: AlertConfirm,
				id: 'confirm-delete-fields',
				body:
					T('admin.anonymization.go.confirm.body_beforeNumber') +
					multiSelect.selected +
					T('admin.anonymization.go.confirm.body_afterNumber'),
				body2: T('admin.anonymization.go.confirm.body_afterNumber_2'),
				title: T('admin.anonymization.go.confirm.title'),
				confirmButtonText: T('admin.anonymization.startProcess'),
				confirmationMode: 'text',
				obligatoryFields: obligatoryFields,
				confirmText: Tools.AppService.getSelf().email
			};

			if (Tools.FeatureHelper.hasSoftDeployAccess('REACT_ALERT_MODAL')) {
				openModal('Alert', {
					...alertOpts,
					onClose: confirmed => {
						if (confirmed) {
							onConfirm(anonPageResponse);
						}
					}
				});
			} else {
				await Tools.$upModal.open('alert', opts);
				onConfirm(anonPageResponse);
			}
		}
	};

	if (Tools.FeatureHelper.hasSoftDeployAccess('REACT_ALERT_MODAL')) {
		openModal('AnonFieldPage', {
			...opts,
			onClose: (anonPageResponse: any) => {
				if (anonPageResponse) {
					onAnonPageResponse(anonPageResponse);
				}
			}
		});
	} else {
		const alertResponse = await Tools.$upModal.open('alert', opts);
		onAnonPageResponse(alertResponse);
	}
}
