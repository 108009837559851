/*
	This file is responsible for parsing values from the api (via Resource)
	Typing is set to be {expected datatype} or null
*/
import { Attr, Type } from 'App/babel/attributes/Attribute';
import _ from 'lodash';
import moment from 'moment';

// The only date parser function needed (for date and dateTime datatypes)
const dateParser = (date: Date | string | null): Date | null => {
	try {
		if (date && moment(date).isValid()) {
			const dateString = moment
				.tz(date, Tools ? Tools.userTimezone : 'Europe/Stockholm')
				.format('YYYY-MM-DD HH:mm:ss');
			return moment(dateString).toDate();
		}
	} catch (e) {
		return null;
	}
	return null;
};

const parsers: { [key in Type]?: (value: any) => any } = {
	// Date, DateTime and DateStringOrDateTime can handle strings, Date instances and null. While parsing dates and making sure that they are Date objects or null we do the same regarding time or not.
	[Type.Date]: dateParser,
	[Type.DateTime]: dateParser,
	[Type.DateStringOrDateTime]: dateParser,
	// Number can handle strings, numbers and null and will cast them to number | null
	[Type.Number]: (value: string | number | null): number | null => {
		if (!isNaN(parseInt(value as string))) {
			return parseInt(value as string);
		}
		return null;
	},
	// Number can handle strings, numbers and null and will cast them to number | null
	[Type.Float]: (value: string | number | null): number | null => {
		if (!isNaN(parseFloat(value as string))) {
			return parseFloat(value as string);
		}
		return null;
	},
	// String can handle strings or null
	[Type.String]: (value: string | null): string | null => {
		if (typeof value === 'string') {
			return value;
		}
		return null;
	},
	// Time can handle strings or null. Will return timestring if valid format, or null if not
	[Type.Time]: (time: string | null): string | null => {
		try {
			if (time && moment('1991-01-01 ' + time).isValid()) {
				return time;
			}
		} catch (e) {
			return null;
		}
		return null;
	},
	// Boolean will cast, 1/0, "1"/"0", "true"/"false" to booleans
	[Type.Boolean]: (value: 1 | 0 | boolean | string | null): boolean | null => {
		if (value === 1 || value === '1' || value === true || value === 'true') {
			return true;
		}
		if (value === 0 || value === '0' || value === false || value === 'false') {
			return false;
		}
		return null;
	},
	// Object will parse object or stringified object to object. If parsed value is an array or if parse failed it will return null
	[Type.Object]: (value: object | string | null): object | null => {
		try {
			if (typeof value === 'string') {
				value = JSON.parse(value);
			}
			if (typeof value !== 'object' || value instanceof Array) {
				return null;
			}
		} catch (e) {
			return null;
		}
		return value;
	},
	// StringifiedObject will take a stringified object and cast it to object
	[Type.StringifiedObject]: (value: string): object | null => {
		try {
			return JSON.parse(value);
		} catch (e) {
			return null;
		}
	},
	// Array will take a string or array and cast to array or null
	[Type.Array]: (value: string | any[] | null): any[] | null => {
		try {
			if (typeof value === 'string') {
				value = JSON.parse(value);
			}
			if (typeof value !== 'object' || !(value instanceof Array)) {
				return null;
			}
		} catch (e) {
			return null;
		}
		return value;
	}
};

/* 
	parseDate will try to cast dates from different formats:
		* YYYY-MM-DD,
		* stringified date object

	If no formats were matched then we return the in value (maybe input was a date or null)
*/
export const parseDate = function <T>(val: T): T | Date | null {
	const YYYYMMDD = /^[0-9]{4}-(0[1-9]|1[012])-(0[1-9]|[12][0-9]|3[01])$/;
	if (val && typeof val === 'string' && YYYYMMDD.test(val)) {
		return moment(val).toDate();
	}

	if (typeof val === 'string' && val.length > 15) {
		const momentVal = moment.tz(val, moment.ISO_8601, true, Tools ? Tools.userTimezone : 'Europe/Stockholm');
		if (momentVal.isValid()) {
			// moment.defaultZone.name dont exist on moment, but it really does. This property does not exist on the moment module (accourding to typescript)
			return momentVal
				.tz((moment as typeof moment & { defaultZone: { name: string } }).defaultZone.name, true)
				.toDate();
		}
	}

	return val;
};

// Function to parse all key values in an object that "looks like" a date
const parseAllDates = function <In extends { [key: string]: any }, Out>(obj: In): Out {
	return _.isObject(obj)
		? JSON.parse(JSON.stringify(obj), function (key, val) {
				return parseDate(val);
		  })
		: obj;
};

// will parse all provided keys from an object to dates
const parseDateFields = function <In, Out>(obj: In, fields: string[]): Out {
	// Iterate provided fields. If field matches array syntax (activity.users[].regDate) users are iterated to parse every user's regDate
	_.forEach(fields, function (field) {
		if (field.includes('[]')) {
			const indexOf = field.indexOf('[]'); // position of array "[]"
			const arrKey = field.substring(0, indexOf); // path to array on the object (activity.users)
			const nestedField = field.substring(indexOf + '[]'.length, field.length); // the nested field to parse on each row (regDate)
			((_.get(obj, arrKey) || []) as object[]).map(obj => parseDateFields(obj, [nestedField]));
		} else {
			const val = _.get(obj, field);
			if (val) {
				_.set(obj, field, parseDate(val as string)); // Cast to string here to satisfy typescript. parseDate can handle anything really
			}
		}
	});
	return (obj as unknown) as Out;
};

// Will parse provided date keys on object or array of objects
export const parseDates = function <In extends { [key: string]: any }, Out>(
	obj: In[] | In,
	fields?: string[]
): Out[] | Out {
	if (Array.isArray(obj) && fields) {
		return _.map(obj, function (o) {
			return parseDateFields<In, Out>(o, fields);
		});
	}

	if (!Array.isArray(obj) && fields) {
		return parseDateFields(obj, fields);
	}

	return parseAllDates<In, Out>(obj as In);
};

// Will parse object keys accourding to found attribute type
const genericParser = <Raw extends { [key: string]: any }, Parsed>(
	data: Raw,
	attributes: { [field: string]: Attr }
): Parsed => {
	const mapped = Object.keys(data).reduce<{ [key: string]: any }>((result, key) => {
		// Only parse values we have an attribute and parser for
		if (attributes[key] && parsers[attributes[key].type]) {
			result[key] = parsers[attributes[key].type]?.(data[key]);
		}
		if (['userEditable', 'userRemovable'].indexOf(key) !== -1) {
			result[key] = data[key];
		}
		return result;
	}, {});

	return mapped as Parsed;
};

export default genericParser;
