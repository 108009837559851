import { useState } from 'react';

const useForceRender = () => {
	const [count, setCount] = useState(0);

	return {
		forced: count,
		triggerRender: () => {
			setCount(val => val + 1);
		}
	};
};

export default useForceRender;
