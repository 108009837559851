// TODO: Parse markdown to json on init, then work with json in preview and map to markdown on save
import translate from 'Components/Helpers/translate';
import { get } from 'lodash';

const parser = new DOMParser();

export const types = {
	EMPTY: 'EMPTY',
	TEXT: 'TEXT',
	IMAGE: 'IMAGE',
	HR: 'HR',
	BUTTON: 'BUTTON',
	HTML: 'HTML',
	VIDEO: 'VIDEO',
	SOCIAL: 'SOCIAL'
};
export const mediums = ['linkedin', 'facebook', 'twitter', 'instagram', 'youtube'];

export const CONTAINER_WIDTH = 580;

const getColumnClass = column => `col-id-${column.id}-id`;
const getRowClass = row => `row-id-${row.id}-id`;

// Get a new row with all default values
export const getEmptyRow = () => ({
	id: Date.now(),
	style: { self: {} },
	columns: [],
	fullWidth: false,
	backgroundColor: '#ffffff',
	collapse: false
});

export const getColumnInnerMaxWidth = column => {
	return (column.initialFixedWidth || column.width) - column.hPadding * 2;
};

export const getImageWidth = column => {
	const maxWidth = getColumnInnerMaxWidth(column);
	if (column.imgWidth > maxWidth) {
		return maxWidth;
	} else if (column.userImgWidth > column.imgWidth) {
		return Math.min(column.userImgWidth, maxWidth);
	}
	return column.imgWidth;
};

export const getColWidthFromSize = (size, manualSize = false) => {
	// This condition is because size 3 and 4 columns both will return width as 173, 70/30 will break line unless we reduce 7o size
	if (size === 8 && manualSize) {
		return Math.floor((CONTAINER_WIDTH / 12) * size);
	}
	return Math.round((CONTAINER_WIDTH / 12) * size);
};

// Get a column with default values based on type
export const getEmptyCol = (row, accountProfile, type = types.EMPTY, manualSize = false, columnProps = {}) => {
	let col = {
		id: Date.now(),
		size: manualSize ? row.size : 12 / (row.columns.length || 1),
		type,
		content: '',
		style: {
			self: {
				padding: '10px 10px',
				'padding-left': '10px',
				'padding-right': '10px',
				'padding-top': '10px',
				'padding-bottom': '10px'
			}
		},
		rowId: manualSize ? row.rowId : row.id,
		vPadding: 10,
		hPadding: 10
	};
	col.width = getColWidthFromSize(col.size, manualSize);
	switch (type) {
		case types.BUTTON:
			col.content = translate('bannerEditor.button');
			col.href = '#';
			col.expand = false;
			col.align = 'center';
			col.btnBg = get(accountProfile, 'colors.buttonBg', '#4A90E2');
			col.color = get(accountProfile, 'colors.buttonText', '#ffffff');
			col.showCaption = false;
			col.caption = '';
			col.captionAlign = 'left';
			break;
		case types.VIDEO:
		case types.IMAGE:
			{
				const imgWidth = col.width - col.hPadding - col.vPadding;
				col.imgWidth = imgWidth;
				col.imgWidth = getImageWidth(col); // fix the width so it fits
				col.realImgWidth = imgWidth;
				col.src =
					type === types.IMAGE
						? `http://placehold.it/${imgWidth}x${imgWidth}`
						: 'https://upsales.wistia.com/medias/8s6lbmg88o';
				col.href = '';
				col.showCaption = false;
				col.caption = '';
				col.captionAlign = 'left';
				col.initialFixedWidth = col.width;
				col.initialImgWidth = getImageWidth(col);
			}
			break;
		case types.SOCIAL:
			col.caption = '';
			col.captionEnabled = false;
			col.align = 'center';
			col.linkedinEnabled = false;
			col.facebookEnabled = false;
			col.twitterEnabled = false;
			col.youtubeEnabled = false;
			col.instagramEnabled = false;
			col.iconSize = '24px';
			col.iconMargin = 8;
			col.iconColor = 'dark';
			col.linkedinURL = '';
			col.twitterURL = '';
			col.facebookURL = '';
			col.youtubeURL = '';
			col.instagramURL = '';
			break;
		case types.HTML:
		case types.TEXT:
			col.src = '';
			col.align = 'left';
			break;
		case types.HR:
			col.hrColor = '#cacaca';
			col.hrStyle = 'solid';
			break;
	}

	if (Object.keys(columnProps).length > 0) {
		col = { ...col, ...columnProps };
	}

	return col;
};

export const parseVariable = (str, accountProfile) => {
	if (!str) {
		return str;
	}
	var key = str.toString().replace('$|', '').replace('|$', '');
	var value = accountProfile.colors[key];

	if (value) {
		var re = new RegExp('\\$\\|' + key + '\\|\\$', 'g');
		return str.replace(re, value);
	}

	return str;
};

const dataStyle = (mdElement, attr, value, selector) => {
	var currentStyle = {};
	var objStyle = mdElement.dataset.style;

	selector = selector || 'self';
	if (objStyle) {
		try {
			currentStyle = JSON.parse(objStyle);
		} catch (e) {
			currentStyle = {};
		}
	}

	if (value === null && currentStyle[selector]) {
		delete currentStyle[selector][attr];
	} else if (value === undefined && currentStyle[selector]) {
		// Get
		return currentStyle[selector][attr] || null;
	} else {
		// Set
		if (!currentStyle[selector]) {
			currentStyle[selector] = {};
		}
		currentStyle[selector][attr] = value;
	}

	mdElement.dataset.style = JSON.stringify(currentStyle);
};

const parseColumnTextContent = contentElem => {
	const children = [...contentElem.children];
	children.forEach(child => {
		if ((child.tagName === 'SPAN' || child.tagName === 'EM') && !child.style.fontSize) {
			child.style.fontSize = 'inherit';
		}
		parseColumnTextContent(child);
	});
};

const getRootElemFromMd = markdown => {
	const tmp = document.createElement('div');
	tmp.innerHTML = markdown;
	const root = tmp.firstChild;
	// tmp.remove();

	return root;
};

const getBaseSettings = accountProfile => ({
	hasHeader: false,
	headerLogoAlign: 'left',
	hasFooter: false,
	fullWidthHeader: false,
	bordered: false,
	borderColor: '#000',
	headerBg: accountProfile.colors.mainBg,
	bodyBg: accountProfile.colors.bodyBg,
	previewText: '',
	unsubText: translate(
		Tools.FeatureHelper.hasSoftDeployAccess('OPTIONAL_UNSUB')
			? 'mailTags.subscripitons'
			: 'mailTags.unsubscribeValue'
	),
	headerLogo: '',
	logoType: 'url',
	headerLink: '',
	darkHeader: false,
	hasBrowserLink: false,
	browserLink: translate('mailTags.openEmailInBrowser'),
	browserLinkColor: accountProfile.colors.linkText,
	footerTextColor: accountProfile.colors.footerText,
	footerLinkTextColor: accountProfile.colors.footerLinkText
});

const parseSettings = (markdown, accountProfile, parseVariables) => {
	const root = getRootElemFromMd(markdown);
	const settings = getBaseSettings(accountProfile);

	const browserLinkElem = root.getElementsByTagName('browser-link');
	if (browserLinkElem.length) {
		settings.hasBrowserLink = true;
	}
	const browserLink = root.dataset['browserlink'];
	if (browserLink !== undefined) {
		settings.browserLink = browserLink;
	}
	const browserLinkColor = root.dataset['browserlinkcolor'];
	if (browserLinkColor) {
		settings.browserLinkColor = browserLinkColor;
	}

	const headerElem = root.getElementsByTagName('header');
	if (headerElem.length) {
		settings.hasHeader = true;

		const align = headerElem[0].dataset['align'];

		if (align) {
			settings.headerLogoAlign = align;
		}

		const href = headerElem[0].dataset['href'];

		if (href) {
			settings.headerLink = href;
		}

		const logo = headerElem[0].dataset['logo'] || '__brightLogo__';

		if (logo) {
			settings.headerLogo = logo;

			if (logo.indexOf('__') === 0) {
				settings.logoType = 'logo';
			} else {
				settings.logoType = 'url';
			}
		}

		const bgColor = headerElem[0].dataset['bgcolor'];

		if (bgColor) {
			settings.headerBg = parseVariables ? parseVariable(bgColor, accountProfile) : bgColor;
		}

		if (headerElem[0].classList.contains('dark-header')) {
			settings.darkHeader = true;
		}

		if (root.classList.contains('full-width')) {
			settings.fullWidthHeader = true;
		}
	}

	const footerElem = root.getElementsByTagName('footer');
	if (footerElem.length) {
		settings.hasFooter = true;

		const footerLinkTextColor = footerElem[0].dataset['footerlinktextcolor'];
		if (footerLinkTextColor) {
			settings.footerLinkTextColor = footerLinkTextColor;
		}
		const footerTextColor = footerElem[0].dataset['footertextcolor'];
		if (footerTextColor) {
			settings.footerTextColor = footerTextColor;
		}
	}

	const borderedElem = root.querySelectorAll('.main-container.bordered');
	if (borderedElem.length) {
		settings.bordered = true;
		const color = dataStyle(borderedElem[0], 'border');

		if (color) {
			settings.borderColor = parseVariables ? parseVariable(color, accountProfile) : color;
		}
	}

	const bodyBg = root.dataset['bodybg'];

	if (bodyBg) {
		settings.bodyBg = parseVariables ? parseVariable(bodyBg, accountProfile) : bodyBg;
	}

	const previewText = root.dataset['previewtext'];
	settings.previewText = previewText || '';

	const unsubText = root.dataset['unsubtext'];
	if (unsubText !== undefined) {
		settings.unsubText = unsubText;
	}

	const headerMargin = root.dataset['headermargin'];
	if (headerMargin) {
		settings.headerMargin = parseInt(headerMargin);
	}
	if (!headerMargin || isNaN(settings.headerMargin)) {
		settings.headerMargin = 15;
	}

	return settings;
};

const alignElements = (mdElement, alignment) => {
	['h1', 'h2', 'h3', 'h4', 'h5', 'p', 'li'].forEach(tag => {
		const elems = mdElement.getElementsByTagName(tag);
		mdElement.classList.remove('text-left', 'text-right', 'text-center');
		if (alignment) {
			mdElement.classList.remove('text-left', 'text-right', 'text-center');
		}
		for (let i = 0; i < elems.length; i++) {
			elems[i].classList.remove('text-left', 'text-right', 'text-center');
			if (alignment) {
				elems[i].classList.add('text-' + alignment);
			}
		}
	});
};

export const addColumnSocial = (contentElem, column) => {
	const links = contentElem.getElementsByTagName('a');
	const imgs = contentElem.getElementsByTagName('img');

	[...links].forEach((link, index) => {
		const medium = mediums.find(m => imgs[index]?.attributes?.src?.value.includes(m));
		if (medium) {
			column[`${medium}Enabled`] = true;

			if (link?.attributes?.href?.value) {
				column[`${medium}URL`] = link.attributes.href.value;
			}
		}
	});

	const img = imgs[0];
	if (img?.attributes?.width?.value) {
		column.iconSize = `${img.attributes.width.value}px`;
	}
	if (img?.attributes?.src?.value?.indexOf('light') !== -1) {
		column.iconColor = 'light';
	}

	const text = contentElem.getElementsByTagName('p')[0];
	if (text) {
		column.captionEnabled = true;
		column.caption = `<p>${text.innerHTML}</p>`;
	}

	const secondDiv = links[0].parentNode;

	if (secondDiv?.nodeName === 'TH') {
		const margin = img.style.margin.split(' ')[1] || img.style.margin;
		column.iconMargin = parseInt(margin?.replace('px', '')) || 0;

		const ths = contentElem.getElementsByTagName('th');

		if (ths[0]?.childElementCount === 0 && ths[ths.length - 1]?.childElementCount === 0) {
			column.align = 'center';
		} else if (ths[0]?.childElementCount === 0) {
			column.align = 'right';
		} else if (ths[ths.length - 1]?.childElementCount === 0) {
			column.align = 'left';
		} else {
			column.align = 'center';
		}
	} else if (secondDiv?.style?.margin) {
		const margin = secondDiv.style.margin.split(' ')[1] || secondDiv.style.margin;
		column.iconMargin = parseInt(margin?.replace('px', '')) || 0;
		column.align = secondDiv.parentNode?.style?.textAlign || 'center';
	}
};

const addColumnExtras = (column, columnElem, accountProfile) => {
	const dataContentElement = columnElem.querySelectorAll('[data-content-element]')[0];
	switch (column.type) {
		case types.VIDEO:
		case types.IMAGE:
			{
				const captionElem = columnElem.querySelectorAll('div.caption')[0];
				const columnContent = columnElem.querySelector('div.column-content');
				column.imgAlign =
					columnContent && columnContent.dataset && columnContent.dataset.imgAlign
						? columnContent.dataset.imgAlign
						: 'center';
				const img = columnElem.getElementsByTagName('img')[0];
				column.src = img.src;
				if (columnElem.dataset.align) {
					column.align = columnElem.dataset.align;
				}
				column.imgWidth = parseInt(img.dataset.width);
				column.realImgWidth = parseInt(img.dataset.realwidth);
				if (captionElem) {
					column.showCaption = true;
					column.caption = captionElem.innerHTML;
				}

				const link = columnElem.getElementsByTagName('a')[0];
				if (link && link.attributes && link.attributes.href && link.attributes.href.value) {
					column.href = link.attributes.href.value;
				}
			}
			break;
		case types.BUTTON:
			{
				const btn = columnElem.getElementsByTagName('button')[0];
				column.content = btn.textContent.trim();
				column.expand = btn.classList.contains('expand');
				if (btn.classList.contains('left')) {
					column.align = 'left';
				} else if (btn.classList.contains('right')) {
					column.align = 'right';
				}
				column.btnBg = parseVariable(
					dataStyle(btn, 'background', undefined, 'tr:first-child > td:not(.expander)'),
					accountProfile
				);

				const innerSpan = btn.getElementsByTagName('span')[0];
				if (innerSpan) {
					column.color = parseVariable(dataStyle(innerSpan, 'color'), accountProfile) || '#ffffff';
				}

				if (btn.classList.contains('expand')) {
					column.expand = true;
				}

				if (btn.getAttribute('href')) {
					column.href = btn.getAttribute('href');
				}
			}
			break;
		case types.HR:
			{
				const hr = columnElem.getElementsByTagName('td')[0] || columnElem.getElementsByTagName('hr')[0];
				const hrStyle = dataStyle(hr, 'border-bottom-style');
				if (hrStyle) {
					column.hrStyle = hrStyle;
				}

				const borderColor = dataStyle(hr, 'border-bottom-color');
				if (borderColor) {
					column.hrColor = borderColor;
				}
			}
			break;
		case types.HTML:
		case types.SOCIAL:
		case types.TEXT:
			{
				const contentElem = columnElem.querySelectorAll('.column-content')[0];
				if (contentElem) {
					if (column.type === types.TEXT) {
						// Div's are nasty in emails
						const divs = contentElem.getElementsByTagName('div');
						[...divs].forEach(div => {
							const p = document.createElement('p');
							p.innerHTML = div.innerHTML;
							div.parentNode.replaceChild(p, div);
						});
					}
					// Remove alignment from caption before getting it
					alignElements(contentElem, null);
					column.content = contentElem.innerHTML;
					if (contentElem.dataset.align) {
						column.align = contentElem.dataset.align;
					}
					if (column.type === types.SOCIAL && column.content) {
						addColumnSocial(contentElem, column);
					}
				}
			}
			break;
		default:
			column.content = columnElem.innerHTML;
	}

	if ([types.BUTTON, types.IMAGE, types.VIDEO].indexOf(column.type) !== -1) {
		const captionElem = columnElem.querySelectorAll('div.caption')[0];

		if (captionElem) {
			column.showCaption = true;
			// Remove alignment from caption before getting it
			alignElements(captionElem, null);
			column.caption = captionElem.innerHTML;
			if (dataContentElement) {
				column.captionAlign =
					dataContentElement.dataset[column.type === types.BUTTON ? 'btntextalign' : 'align'];
			}
		}
	}
};

const parseStyle = style => {
	try {
		return JSON.parse(style);
	} catch (e) {
		return {};
	}
};

export const generateConfigFromMarkdown = (markdown, accountProfile) => {
	const config = parseSettings(markdown, accountProfile, true);
	const root = getRootElemFromMd(markdown);

	config.rows = [];

	const rowElems = root.querySelectorAll('.editable-row');

	for (let i = 0; i < rowElems.length; i++) {
		const row = getEmptyRow();
		row.id = parseInt(rowElems[i].dataset.id);

		try {
			row.style = parseStyle(rowElems[i].dataset.style);
			if (rowElems[i].tagName.toUpperCase() === 'WRAPPER') {
				row.fullWidth = true;
			}
			const bg = dataStyle(rowElems[i], 'background-color');
			if (bg) {
				row.backgroundColor = bg;
			}
			const noCollapse = dataStyle(rowElems[i], 'no-collapse');
			if (noCollapse) {
				row.noCollapse = noCollapse === 'true';
			}
			// row.collapse = rowElems[i].classList.contains('collapse')

			const columns = rowElems[i].querySelectorAll('.editable-column');
			row.columns = Array(columns.length); // create columns array with correct length
			const realRows = [];
			for (let ci = 0; ci < columns.length; ci++) {
				let column;
				try {
					column = getEmptyCol(row, accountProfile, types[columns[ci].dataset.type]);
					column.id = parseInt(columns[ci].dataset.id);
					const size = parseFloat(columns[ci].getAttribute('large'));
					const width = parseInt(columns[ci].dataset.width);
					if (!isNaN(size)) {
						column.size = size;
						column.width = getColWidthFromSize(size);
					} else if (!isNaN(width)) {
						// Use provided width if it's ok
						column.width = width;
					}

					if (types[columns[ci].dataset.type]) {
						column.type = columns[ci].dataset.type;
						column.type = columns[ci].dataset.type;
					}
					column.style = parseStyle(columns[ci].dataset.style);

					const vPadding = parseInt(column.style.self ? column.style.self['padding-top'] : null);
					const hPadding = parseInt(column.style.self ? column.style.self['padding-left'] : null);
					if (!isNaN(vPadding)) {
						column.vPadding = vPadding;
					}
					if (!isNaN(hPadding)) {
						column.hPadding = hPadding;
					}
					const columnBackground = column.style.self ? column.style.self['background'] : null;
					if (columnBackground) {
						column.enableCellBackground = true;
						column.backgroundColor = columnBackground;
					}

					addColumnExtras(column, columns[ci], accountProfile);
				} catch (e) {
					console.error('Faild to parse column', e);
					// Push empty column if we fail to parse the column
					column = getEmptyCol(row, accountProfile);
					column.id = parseInt(columns[ci].dataset.id);
				}
				realRows.push(column);
			}
			row.columns = realRows;
		} catch (e) {
			console.error('Faild to parse row', e);
			// Push empty row if we fail to parse the row
		}
		config.rows.push(row);
	}

	return config;
};

const createIconButton = (icon, url, color, size, margin) => {
	const container = document.createElement('th');
	const thWidth = (size === '24px' ? 24 : 48) + 2 * margin;
	container.style.setProperty('width', thWidth);
	container.style.width = `${thWidth}px`;
	const element = document.createElement('a');
	element.href = url;
	element.target = '_blank';
	const img = document.createElement('img');
	img.src = `https://img.upsales.com/icons/${icon}-${color}-${size}.png`;
	img.height = size === '24px' ? 24 : 48;
	img.width = size === '24px' ? 24 : 48;
	img.style.overflow = 'visible';
	img.style.margin = `0px ${margin}px`;
	element.appendChild(img);
	container.appendChild(element);
	return container;
};

const generateSocialFollowContent = columnConfig => {
	const outer = document.createElement('div');

	const trWidth = columnConfig.width - 2 * columnConfig.vPadding;
	const iconWidth = columnConfig.iconSize === '24px' ? 24 : 48;
	const iconThWidth = iconWidth + 2 * columnConfig.iconMargin;
	const totalNumberOfEnabledMediums = mediums.reduce(
		(sum, medium) => (columnConfig[`${medium}Enabled`] ? sum + 1 : sum),
		0
	);
	const totalFillerThWidth = trWidth - totalNumberOfEnabledMediums * iconThWidth;
	const fillerThWidth = Math.floor(totalFillerThWidth / 2);

	// Caption
	if (columnConfig.captionEnabled) {
		const caption = document.createElement('div');
		caption.innerHTML = columnConfig.caption;
		alignElements(caption, columnConfig.align);
		outer.appendChild(caption);
	}

	// Social Links
	const table = document.createElement('table');
	table.style.tableLayout = 'fixed';
	outer.appendChild(table);

	const links = document.createElement('tr');
	table.appendChild(links);

	switch (columnConfig.align) {
		case 'center':
		case 'right': {
			const fillerTh = document.createElement('th');
			fillerTh.style.width = `${fillerThWidth}px`;
			fillerTh.style.setProperty('width', fillerThWidth);
			links.appendChild(fillerTh);
			break;
		}
	}

	mediums.forEach(medium => {
		if (columnConfig[`${medium}Enabled`]) {
			links.appendChild(
				createIconButton(
					medium,
					columnConfig[`${medium}URL`],
					columnConfig.iconColor,
					columnConfig.iconSize,
					columnConfig.iconMargin
				)
			);
		}
	});

	switch (columnConfig.align) {
		case 'left':
		case 'center': {
			const fillerTh = document.createElement('th');
			fillerTh.style.width = `${fillerThWidth}px`;
			fillerTh.style.setProperty('width', fillerThWidth);
			links.appendChild(fillerTh);
		}
	}

	return outer;
};

const generateColumnMarkdownFromConfig = (columnConfig, rowConfig) => {
	const colMd = document.createElement('columns');
	colMd.classList.add(getColumnClass(columnConfig), 'editable-column');

	// General stuff
	colMd.dataset.id = columnConfig.id;
	colMd.dataset.type = columnConfig.type;
	colMd.dataset.width = columnConfig.width;
	colMd.setAttribute('large', columnConfig.size);

	if (rowConfig.noCollapse) {
		colMd.setAttribute('small', columnConfig.size);
	}

	dataStyle(colMd, 'padding', `${columnConfig.vPadding}px ${columnConfig.hPadding}px`);
	dataStyle(colMd, 'padding-top', columnConfig.vPadding + 'px');
	dataStyle(colMd, 'padding-bottom', columnConfig.vPadding + 'px');
	dataStyle(colMd, 'padding-left', columnConfig.hPadding + 'px');
	dataStyle(colMd, 'padding-right', columnConfig.hPadding + 'px');
	if (columnConfig.enableCellBackground && columnConfig.backgroundColor) {
		dataStyle(colMd, 'background', columnConfig.backgroundColor);
	}

	let content = null;

	// Type specific stuff
	switch (columnConfig.type) {
		case types.VIDEO:
		case types.IMAGE: {
			dataStyle(colMd, 'line-height', '0');

			content = document.createElement('div');
			if (columnConfig.imgAlign) {
				content.style.textAlign = columnConfig.imgAlign;
				content.dataset.imgAlign = columnConfig.imgAlign;
			} else {
				content.style.textAlign = 'center';
				content.dataset.imgAlign = 'center';
			}

			content.style.lineHeight = '1';
			content.dataset.lineHeight = '1';

			if (columnConfig.href) {
				content.innerHTML = `<a href="${columnConfig.href}" target="_blank" style="display:block;line-height:0;text-align:${content.dataset.imgAlign}"><img class="thumbnail" /></a>`;
			} else {
				content.innerHTML = '<img class="thumbnail" />';
			}
			// Make sure image is not wider than column inner width
			columnConfig.imgWidth = getImageWidth(columnConfig);

			const img = content.getElementsByTagName('img')[0];
			img.dataset.width = columnConfig.imgWidth;
			img.dataset.realwidth = columnConfig.realImgWidth;
			img.style.maxWidth = columnConfig.imgWidth + 'px';
			img.setAttribute('width', columnConfig.imgWidth);
			img.setAttribute('src', columnConfig.src);
			img.setAttribute('data-retina-ready', 'true');
			if (rowConfig.noCollapse) {
				img.classList.add('no-collapse-image');
			}
			break;
		}
		case types.HR: {
			content = document.createElement('table');
			content.innerHTML = `<table style="height: 1px" cellpadding="0" cellspacing="0">
				<tr style="height: 1px">
					<td class="horizontal-row">&nbsp;</td>
				</tr>
			</table>`;
			const hr = content.getElementsByTagName('td')[0];
			dataStyle(hr, 'border-bottom-color', columnConfig.hrColor);
			dataStyle(hr, 'border-bottom-style', columnConfig.hrStyle);
			dataStyle(hr, 'border-bottom-width', '1px !important');
			break;
		}
		case types.BUTTON: {
			content = document.createElement('div');
			content.classList.add('center-wrapper');
			content.innerHTML = '<button><span></span></button>';
			const btn = content.getElementsByTagName('button')[0];
			const span = btn.getElementsByTagName('span')[0];
			// Content
			span.textContent = columnConfig.content;

			// Colors
			dataStyle(btn, 'background', columnConfig.btnBg, 'tr:first-child > td:not(.expander)');
			dataStyle(btn, 'border-color', columnConfig.btnBg, 'tr:first-child > td:not(.expander)');
			dataStyle(span, 'color', columnConfig.color);

			// Align, Size and classes
			btn.classList.remove('left', 'center', 'right', 'tiny', 'small', 'large', 'expand');
			btn.classList.add('radius', columnConfig.align, columnConfig.size, 'email-btn');
			btn.classList.add(columnConfig.align);

			if (columnConfig.expand) {
				btn.classList.add('expand');
			}

			if (columnConfig.href) {
				btn.setAttribute('href', columnConfig.href);
			}

			// Fixed max size (needed for Outlook on Win10)
			const btnMaxWidth = Math.round((CONTAINER_WIDTH / 12) * columnConfig.size - columnConfig.vPadding * 2);
			if (columnConfig.content.length * 8 > btnMaxWidth || columnConfig.expand) {
				dataStyle(btn, 'width', btnMaxWidth + 'px');
			}
			break;
		}
		case types.SOCIAL:
			content = generateSocialFollowContent(columnConfig);
			break;
		case types.HTML:
		case types.TEXT: {
			content = document.createElement('div');
			content.innerHTML = columnConfig.content;
			parseColumnTextContent(content);
			alignElements(content, columnConfig.align);
			content.dataset.align = columnConfig.align;
		}
	}

	// create caption element
	if ([types.BUTTON, types.IMAGE, types.VIDEO].indexOf(columnConfig.type) !== -1) {
		let captionElem = content.querySelectorAll('.caption')[0];

		if (columnConfig.showCaption) {
			captionElem = document.createElement('div');
			captionElem.classList.add('caption');
			captionElem.innerHTML = columnConfig.caption;
			content.append(captionElem);
			if (columnConfig.type === types.BUTTON) {
				content.dataset.btntextalign = columnConfig.captionAlign;
			} else {
				content.dataset.align = columnConfig.captionAlign;
			}
			alignElements(captionElem, columnConfig.captionAlign);
		}
	}

	if (content) {
		content.setAttribute('data-content-element', '');
		content.classList.add('column-content');
		colMd.append(content);
	}

	return colMd;
};

const generateRowMarkdownFromConfig = rowConfig => {
	const rowMd = document.createElement(rowConfig.fullWidth ? 'wrapper' : 'container');
	if (rowConfig.fullWidth) {
		rowMd.innerHTML = '<container><row></row></container>';
	} else {
		rowMd.innerHTML = '<row></row>';
	}
	rowMd.classList.add(getRowClass(rowConfig), 'editable-row');
	const rowMdRow = rowMd.getElementsByTagName('row')[0];

	rowMd.dataset.style = rowConfig.style;
	rowMd.dataset.id = rowConfig.id;

	dataStyle(rowMd, 'background-color', rowConfig.backgroundColor);
	if (rowConfig.noCollapse) {
		dataStyle(rowMd, 'no-collapse', '' + rowConfig.noCollapse);
	}

	rowConfig.columns.forEach(column => {
		const colMd = generateColumnMarkdownFromConfig(column, rowConfig);

		rowMdRow.append(colMd);
	});

	return rowMd;
};

const getMdRootElem = config => {
	const root = document.createElement('table');
	root.classList.add('body');
	root.dataset.bodybg = config.bodyBg;
	root.dataset.headermargin = config.headerMargin;
	root.dataset.unsubtext = config.unsubText;
	root.dataset.browserlink = config.browserLink;
	root.dataset.browserlinkcolor = config.browserLinkColor;
	root.dataset.previewtext = config.previewText;
	root.innerHTML = `<tbody>
		<tr>
			<td class="center" align="center" valign="top">
				<center class="top-center"></center>
			</td>
		</tr>
	</tbody>`;

	return root;
};

export const getEmptyMarkdown = accountProfile => {
	return getMdRootElem(getBaseSettings(accountProfile)).outerHTML;
};

export const generateMarkdownFromConfig = config => {
	const root = getMdRootElem(config);

	const content = root.getElementsByTagName('center')[0];

	if (config.hasBrowserLink) {
		const browserLinkMd = document.createElement('browser-link');
		content.append(browserLinkMd);
	}

	// Add header
	if (config.hasHeader) {
		const headerMd = document.createElement('header');

		if (config.darkHeader) {
			headerMd.classList.add('dark-header');
		}
		if (config.fullWidthHeader) {
			root.classList.add('full-width');
		}

		headerMd.dataset.bgcolor = config.headerBg;
		headerMd.dataset.align = config.headerLogoAlign;

		headerMd.dataset.logo = config.headerLogo;
		headerMd.dataset.href = config.headerLink;

		content.append(headerMd);
	}

	// Add rows
	const mainContainer = document.createElement('wrapper');
	mainContainer.classList.add('main-container');
	mainContainer.id = 'main-container';
	// ADD BORDER HERE

	config.rows.forEach(row => {
		const rowMd = generateRowMarkdownFromConfig(row);

		mainContainer.append(rowMd);
	});

	content.append(mainContainer);

	// Add footer
	if (config.hasFooter) {
		const footerMd = document.createElement('footer');

		footerMd.dataset.footertextcolor = config.footerTextColor;
		footerMd.dataset.footerlinktextcolor = config.footerLinkTextColor;

		content.append(footerMd);
	}

	return root.outerHTML;
};

// This method is used to get previewText from plainText html emails
export const getPreviewFromHtml = html => {
	const root = getRootElemFromMd(`<div>${html}</div>`);
	const preview = root.querySelector('#preview');

	return preview ? preview.textContent.trim() : '';
};

// This method is used to set/update previewText in html for plainText emails
export const setPreviewTextToHtml = (html, previewText = '') => {
	const root = parser.parseFromString(html, 'text/html');
	let preview = root.querySelector('#preview');

	if (!preview) {
		preview = document.createElement('div');
		preview.id = 'preview';
		root.body.prepend(preview);
	}

	if (previewText) {
		preview.textContent = previewText;
	} else {
		preview.innerHTML = '&nbsp;';
	}

	preview.style.display = 'none';
	preview.style.fontSize = '1px';
	preview.style.color = '#333333';
	preview.style.lineHeight = '1px';
	preview.style.maxHeight = '0px';
	preview.style.maxWidth = '0px';
	preview.style.opacity = 0;
	preview.style.overflow = 'hidden';

	return root.documentElement.outerHTML;
};

export const reflowColumns = columns => {
	return columns.map(col => {
		col.size = 12 / columns.length;
		col.width = getColWidthFromSize(col.size);

		if ([types.IMAGE, types.VIDEO].includes(col.type)) {
			col.initialFixedWidth = col.width;
			if (!col.userImgWidth) {
				col.userImgWidth = col.imgWidth;
			}
			col.imgWidth = getImageWidth(col);
		}
		return col;
	});
};
