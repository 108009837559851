import React from 'react';
import PropTypes from 'prop-types';
import { Text, Title } from '@upsales/components';
import { circle } from 'Components/Helpers/styleHelper';
import './SocialEventPageHeader.scss';

const SocialEventPageHeader = ({ number, title, text, elevio, elevioText, ...props }) => {
	const t = Tools.$translate;
	return (
		<div className="SocialEventPageHeader" {...props}>
			<div>
				{number ? (
					<Title size="sm" color="inherit" style={{ display: 'inline-block' }}>
						<div className="SocialEventPageHeader__circle" style={{ ...circle(2) }}>
							{number}
						</div>
					</Title>
				) : null}
				<Title size="xl" style={{ display: 'inline-block' }}>
					{title}
				</Title>
			</div>
			<Text color="grey-11" className="SocialEventPageHeader__text">
				{text + ' '}
				{elevio ? <ReactTemplates.elevio articleId={elevio} sidebar text={t(elevioText)} /> : null}
			</Text>
		</div>
	);
};

SocialEventPageHeader.propTypes = {
	number: PropTypes.number,
	elevio: PropTypes.number,
	elevioText: PropTypes.string,
	title: PropTypes.string.isRequired,
	text: PropTypes.string.isRequired
};

SocialEventPageHeader.defaultProps = {
	title: '',
	text: ''
};

export default SocialEventPageHeader;
