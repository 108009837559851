import { FullscreenModal, ModalContent, ModalHeader, Flex, Headline } from '@upsales/components';
import EditorHeaderButton from 'Components/EditorHeader/EditorHeaderButton';
import React from 'react';
import T from 'Components/Helpers/translate';

type Props = {
	className: string;
	close: () => void;
};

const FallbackModal = ({ className, close }: Props) => (
	<FullscreenModal className={className} headerAtTop>
		<ModalHeader>
			<Flex flex={1} direction="column" alignItems="flex-end">
				<EditorHeaderButton
					title={T('default.close')}
					onClick={() => close()}
					supertitle={undefined}
					noIcon
					className={undefined}
					next={false}
				/>
			</Flex>
		</ModalHeader>
		<ModalContent>
			<Flex flex={1} justifyContent="center">
				<Headline>{T('sentry.modal.title')}</Headline>
			</Flex>
		</ModalContent>
	</FullscreenModal>
);

export default FallbackModal;
