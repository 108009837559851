import { ClientIdName, ClientIdNamePriceList } from 'App/resources/Model/Client';
import { ContactIdName } from 'App/resources/Model/Contact';
import { ProjectIdName } from 'App/resources/Model/Project';
import OrderStage from 'App/resources/Model/OrderStage';
import User from 'App/resources/Model/User';
import { EntityCustomField } from 'App/resources/Model/CustomField';
import { OrderRow } from 'App/components/OrderRows/Context/OrderContext';
import Product from 'App/resources/Model/Product';
import { STEP, FINAL_STEP } from '../Step';
import Order from 'App/resources/Model/Order';
import PriceList from 'App/resources/Model/PriceList';

export const Intervals = [1, 3, 4, 6, 12, 24, 36, 48, 60] as const;
export const NoticePeriods = [0, 1, 2, 3, 6, 12] as const;
export const PeriodLengths = [0, 1, 2, 3, 4, 6, 12, 24, 36, 48, 60] as const;
export const Offsets = [0, 15, 30, 45, 60, 90] as const;

export type OrderInterval = (typeof Intervals)[number];
export type PeriodLength = (typeof PeriodLengths)[number];

export type SubscriptionUserType = (Partial<User> & Pick<User, 'id' | 'name'>) | null;

export type PeriodSettings = {
	periodLength: PeriodLength;
	orderInterval: OrderInterval;
	offset?: number;
	allowOffset: boolean;
	noticePeriod: number;
	startDate: string;
	invoiceStartDate: string;
	initialInvoiceStartDate: string;
	renewalDate?: string;
	errorMessages: { [key: string]: string | null };
	endDate?: string;
	priceList: PriceList;
};

export type SubscriptionGroupState = {
	id?: number;
	tempId?: string;
	dontWait?: boolean;
	client: ClientIdNamePriceList | null;
	contact: ContactIdName | null;
	clientConnection: ClientIdName | null;
	user: SubscriptionUserType;
	description: string;
	notes: string;
	subscriptionMap: {
		[uuid: number]: SubscriptionPeriodState;
	};
	currentUUID: number;
	childIndexNr: number;
	custom: EntityCustomField[];
	campaign: ProjectIdName | null;
	stage: Pick<OrderStage, 'id' | 'name'> | null;
	isDetailsValid: boolean;
	splitEnabled: boolean;
	isAdvancedMode: boolean;
	createdFromOrderId?: number;
	allowOffset: boolean;
	saving: boolean;
	errorMessages: { [key: string]: string | null };
	isEdit: boolean;
	fetching: boolean;
	locked?: boolean;
	lockedReason?: string;
	isDirty: boolean;
	step: STEP;
	finalStep: FINAL_STEP | null;
	diffOrders: DiffOrder[];
	orderToAdd?: Order | null;
	showPreviewFooter: boolean;
	invoiceRelatedClient: boolean;
};

export type DiffOrder = {
	agreementId: number;
	visualStartDate: string;
	visualEndDate: string;
	totalValue: number;
	currency: string;
	show: boolean;
};

export type ChildAgreement = {
	id?: number;
	orderRow: OrderRow[];
	startDate: string;
	endDate: string;
	periodLength: number;
	state: 'default' | 'several' | 'part';
	uuid: number;
	parentId?: number;
};

export type SubscriptionPeriodState = {
	id?: number;
	readonly uuid: number;
	periodLength: PeriodSettings['periodLength'];
	orderInterval: PeriodSettings['orderInterval'];
	offset?: PeriodSettings['offset'];
	noticePeriod: PeriodSettings['noticePeriod'];
	startDate: PeriodSettings['startDate'];
	invoiceStartDate: PeriodSettings['invoiceStartDate'];
	initialInvoiceStartDate: PeriodSettings['initialInvoiceStartDate'];
	renewalDate?: PeriodSettings['renewalDate'];
	endDate?: PeriodSettings['endDate'];
	orderRows: OrderRow[];
	custom?: EntityCustomField[];
	value?: number;
	currency: string;
	currencyRate: number;
	isCustomFieldsValid: boolean;
	locked?: boolean;
	tempId?: string;
	errorMessages: PeriodSettings['errorMessages'];
	orderSequenceNr?: number;
	nextOrderDate?: string | null;
	nextOrderPeriodStart?: string | null;
	willCreateMoreOrders?: boolean;
	untouchedValues?: {
		endDate?: PeriodSettings['endDate'];
		orderRows?: OrderRow[];
		children?: {
			id: number | undefined;
			uuid: number;
			orderRow: ChildAgreement['orderRow'];
		}[];
	};
	isParent?: boolean;
	children?: ChildAgreement[];
	priceList: PeriodSettings['priceList'];
	creditInitialDate?: string;
	externallyLocked?: boolean;
};

export type FormattedOrderRow = {
	id?: number;
	product?: Pick<Product, 'id'>;
	quantity?: number;
	price?: number;
	listPrice?: number;
	sortId?: number;
	custom?: EntityCustomField[];
};

export type SubscriptionCustomFormType = {
	custom: { [key: string]: string | null };
};

export type FormattedSubscriptionPeriod = {
	id?: number;
	uuid?: number;
	currency: string;
	currencyRate: number;
	custom?: EntityCustomField[];
	createDiffOrder: boolean;
	metadata: {
		agreementIntervalPeriod: SubscriptionPeriodState['orderInterval'];
		periodLength: number;
		agreementStartdate: string;
		agreementInvoiceStartdate: string;
		agreementInitialInvoiceStartdate: string;
		agreementEnddate?: string | null;
		noticePeriod: number;
		agreementRenewalDate: string | null;
		orderSequenceNr?: number;
		agreementOrderCreationTime: number;
		custom?: EntityCustomField[];
		creditInitialDate?: string;
	};
	orderRow: FormattedOrderRow[];
	children?: (Omit<ChildAgreement, 'orderRow'> & { orderRow: FormattedOrderRow[] })[];
	value?: number;
	priceListId: number;
};
