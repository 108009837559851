import React from 'react';
import PropTypes from 'prop-types';

export default class View extends React.Component {
	constructor(props) {
		super(props);

		var t = Tools.$translate;
		this.lang = {
			view: t('default.show'),
			goToAccount: t('default.goToAccount')
		};

		this.goToAccount = this.goToAccount.bind(this);
	}

	goToAccount() {
		this.props.actions.goToAccount(this.props.account.id);
	}

	render() {
		var button = (
			<span>
				<Button type="link" color="bright-blue" onClick={this.goToAccount}>
					{this.lang.view}
				</Button>
			</span>
		);
		return (
			<td className="DataSourceColumn view-column">
				{ReactTemplates.TOOLS.withTooltip(button, this.lang.goToAccount, {
					key: this.props.account.id,
					placement: 'left'
				})}
			</td>
		);
	}
}

View.propTypes = {
	actions: PropTypes.object,
	account: PropTypes.object
};
