import React from 'react';
import BemClass from '@upsales/components/Utils/bemClass';
import TextEditor from 'Components/TextEditor';
import NewMailHeader from './NewMailHeader';
import { ATTACHMENT_ADDED, ATTACHMENT_REMOVED, useMailContext } from '../../MailContext';
import { setBody } from '../../MailContextHelpers';
import getAngularModule from 'App/babel/angularHelpers/getAngularModule';
import MailAttachment from '../MailAttachment';
import { convertFileToAttachment } from 'Components/Helpers/MailAttachmentHelper';
import FileUploadButton from 'App/components/Buttons/FileUploadButton';

import './NewMail.scss';

type Props = {
	reply?: boolean;
};

const NewMail = ({ reply }: Props) => {
	const {
		state: { body, attachments, mailSignatures },
		dispatch
	} = useMailContext();
	const classes = new BemClass('NewMail');
	const [config, setConfig] = React.useState<React.ComponentProps<typeof TextEditor>['config']>();

	React.useEffect(() => {
		const getConfig = async (reply: boolean | undefined) => {
			const EditorCk = getAngularModule('EditorCk');
			setConfig({
				...EditorCk.getOptions({
					height: 'auto',
					fileEntity: 'mail',
					mailSignatures
				}),
				extraPlugins: 'lineheight',
				forceSimpleAmpersand: true,
				enterMode: window.CKEDITOR.ENTER_BR,
				shiftEnterMode: window.CKEDITOR.ENTER_BR,
				removePlugins: 'liststyle,tableselection,tabletools,tableresize,contextmenu,image'
			});
		};
		getConfig(reply);
	}, [reply, mailSignatures]);

	const renderMailEditor = () => {
		return (
			<div className={classes.elem('mail-editor-wrapper').b()}>
				<FileUploadButton
					onFileUploaded={file =>
						dispatch({ type: ATTACHMENT_ADDED, attachment: convertFileToAttachment(file) })
					}
				/>
				{mailSignatures ? (
					<TextEditor
						className="MailContentEditorTextEditor"
						config={config}
						onInstanceReady={config.init}
						onChange={({ value }: { value: string }) => {
							dispatch(setBody(value));
						}}
						value={body}
					/>
				) : null}
			</div>
		);
	};
	return React.useMemo(() => {
		return (
			<div className={classes.b()}>
				<NewMailHeader />
				{config ? renderMailEditor() : null}
				<div className={classes.elem('footer').b()}>
					{attachments.map((attachment, idx) => (
						<MailAttachment
							attachment={attachment}
							key={idx}
							onRemove={attachment => {
								dispatch({ type: ATTACHMENT_REMOVED, attachment });
							}}
						></MailAttachment>
					))}
				</div>
			</div>
		);
	}, [body, config, attachments]);
};

export default NewMail;
