import moment from 'moment';
import { findIndex } from 'lodash';
import SocialEventResource from 'App/babel/resources/SocialEvents';
import RequestBuilder from 'App/babel/resources/RequestBuilder';
import { Equals, NotEquals } from 'App/babel/resources/ComparisonTypes';
import getAngularModule from 'App/babel/angularHelpers/getAngularModule';
import translate from 'Components/Helpers/translate';
import {
	addResourceModal,
	getEmailModalText,
	getPageModalText
} from 'App/babel/components/SocialEvent/EditSocialEventModal';
import { getLiveTags, isPassed } from 'Store/actions/SocialEventActions';
import config from 'App/babel/config';
import { socialEventTracker } from 'App/babel/helpers/Tracker';
import _ from 'lodash';
import history from 'App/pages/routes/history';
import MailCampaignResource from 'App/resources/MailCampaign';
import MailCampaignAttributes from 'App/babel/attributes/MailCampaignAttributes';
import openModal from 'App/services/Modal';
import logError from 'Helpers/logError';

const RESOURCE_LIMIT = 20;

export const initialState = {
	disabled: {},
	emails: [],
	manualEmails: [],
	pages: [],
	landingpageUrl: '',
	pagination: {
		email: { total: 0, limit: RESOURCE_LIMIT, offset: 0 },
		page: { total: 0, limit: RESOURCE_LIMIT, offset: 0 },
		manualEmail: { total: 0, limit: RESOURCE_LIMIT, offset: 0 }
	}
};

export const TYPES = {
	FIRSTINVITE: 'firstInvite',
	BOOKINGCONFIRMATION: 'bookingConfirmation',
	LANDINGPAGE: 'landingpage',
	FORM: 'form',
	THANKSFORATTENDING: 'thanksForAttending',
	SORRYDIDNOTMAKEIT: 'sorryDidNotMakeIt',
	REMINDERTOMORROWSEVENT: 'reminderTomorrowsEvent',
	WAITINGLISTCONFIRMATION: 'waitingListConfirmation',
	ONDEMANDMAIL: 'onDemandMail'
};

export const emailCards = [
	{
		type: TYPES.FIRSTINVITE,
		title: 'socialEvent.firstInviteTitle',
		body: 'socialEvent.firstInviteBody',
		icon: 'img/marketing/envelope-1.svg',
		templateName: () => 'systemMailTemplate.socialEventInvite',
		disableIf: state => {
			if (isPassed(state.SocialEvent.edit.date)) {
				return { disabled: true, reason: 'socialEvent.disabledReason.eventPassed' };
			}
			if (state.SocialEventResource.emails.map(e => e.type).includes(TYPES.FIRSTINVITE)) {
				return { disabled: true, reason: 'socialEvent.disabledReason.templateAlreadyUsed' };
			}

			return { disabled: false, reason: null };
		}
	},
	{
		type: TYPES.BOOKINGCONFIRMATION,
		title: 'socialEvent.bookingConfirmationTitle',
		body: 'socialEvent.bookingConfirmationBody',
		icon: 'img/marketing/ticket.svg',
		templateName: edit => `systemMailTemplate.socialEventConfirmation${edit.isWebinar ? '.webinar' : ''}`,
		disableIf: state => {
			if (isPassed(state.SocialEvent.edit.date)) {
				return { disabled: true, reason: 'socialEvent.disabledReason.eventPassed' };
			}
			if (state.SocialEventResource.emails.map(e => e.type).includes(TYPES.BOOKINGCONFIRMATION)) {
				return { disabled: true, reason: 'socialEvent.disabledReason.templateAlreadyUsed' };
			}

			return { disabled: false, reason: null };
		}
	},
	{
		type: TYPES.REMINDERTOMORROWSEVENT,
		title: 'socialEvent.reminderTomorrowsEvent',
		body: 'socialEvent.reminderTomorrowsEventBody',
		icon: 'img/marketing/bell-notification.svg',
		templateName: edit => `systemMailTemplate.reminderTomorrowsEvent${edit.isWebinar ? '.webinar' : ''}`,
		disableIf: state => {
			if (
				moment().isAfter(moment(state.SocialEvent.edit.date).add(-1, 'days').startOf('day')) &&
				state.SocialEvent.edit.versionFlag !== 2
			) {
				return { disabled: true, reason: 'socialEvent.disabledReason.sendDatePassed' };
			}
			if (
				moment().isAfter(moment(state.SocialEvent.edit.date).add(-30, 'minutes')) &&
				state.SocialEvent.edit.versionFlag === 2
			) {
				return { disabled: true, reason: 'socialEvent.disabledReason.sendDatePassed' };
			}
			if (state.SocialEventResource.emails?.map(e => e.type).includes(TYPES.REMINDERTOMORROWSEVENT)) {
				return { disabled: true, reason: 'socialEvent.disabledReason.templateAlreadyUsed' };
			}

			return { disabled: false, reason: null };
		}
	},
	{
		type: TYPES.THANKSFORATTENDING,
		title: 'socialEvent.thanksForAttending',
		body: 'socialEvent.thanksForAttendingBody',
		icon: 'img/marketing/smiley-happy.svg',
		templateName: () => 'systemMailTemplate.thanksForAttending',
		disableIf: state => {
			if (
				moment().isAfter(
					moment(state.SocialEvent.edit.date).add(1, 'days').startOf('day') &&
						state.SocialEvent.edit.versionFlag !== 2
				)
			) {
				return { disabled: true, reason: 'socialEvent.disabledReason.sendDatePassed' };
			}
			if (state.SocialEventResource.emails?.map(e => e.type).includes(TYPES.THANKSFORATTENDING)) {
				return { disabled: true, reason: 'socialEvent.disabledReason.templateAlreadyUsed' };
			}

			return { disabled: false, reason: null };
		}
	},
	{
		type: TYPES.SORRYDIDNOTMAKEIT,
		title: 'socialEvent.sorryDidNotMakeIt',
		body: 'socialEvent.sorryDidNotMakeItBody',
		icon: 'img/marketing/smiley-sad.svg',
		templateName: () => 'systemMailTemplate.sorryDidNotMakeIt',
		disableIf: state => {
			if (
				moment().isAfter(
					moment(state.SocialEvent.edit.date).add(1, 'days').startOf('day') &&
						state.SocialEvent.edit.versionFlag !== 2
				)
			) {
				return { disabled: true, reason: 'socialEvent.disabledReason.sendDatePassed' };
			}
			if (state.SocialEventResource.emails?.map(e => e.type).includes(TYPES.SORRYDIDNOTMAKEIT)) {
				return { disabled: true, reason: 'socialEvent.disabledReason.templateAlreadyUsed' };
			}

			return { disabled: false, reason: null };
		}
	},
	{
		type: TYPES.ONDEMANDMAIL,
		title: 'socialEvent.onDemandMail',
		body: 'socialEvent.onDemandMailBody',
		icon: 'img/optin-settings.svg',
		templateName: () => 'systemMailTemplate.onDemandMail',
		disableIf: state => {
			if (!state.SocialEvent.edit.isWebinar) {
				return { disabled: true, reason: 'socialEvent.disabledReason.notAvailableforVenue' };
			}
			if (!state.SocialEvent.edit.isOnDemand) {
				return { disabled: true, reason: 'socialEvent.disabledReason.needToActivateOnDemand' };
			}
			if (state.SocialEventResource.emails.find(e => e.type === TYPES.ONDEMANDMAIL)) {
				return { disabled: true, reason: 'socialEvent.disabledReason.templateAlreadyUsed' };
			}

			return { disabled: false, reason: null };
		}
	},
	{
		type: TYPES.WAITINGLISTCONFIRMATION,
		title: 'socialEvent.waitingListConfirmation',
		body: 'socialEvent.waitingListConfirmationBody',
		icon: 'img/waiting-list.svg',
		templateName: () => 'systemMailTemplate.socialEventWaitingListConfirmation',
		disableIf: state => {
			if (state.SocialEvent.edit.isWebinar) {
				return { disabled: true, reason: 'socialEvent.disabledReason.notAvailableforWebinar' };
			}
			if (isPassed(state.SocialEvent.edit.date)) {
				return { disabled: true, reason: 'socialEvent.disabledReason.sendDatePassed' };
			}
			if (state.SocialEventResource.emails.map(e => e.type).includes(TYPES.WAITINGLISTCONFIRMATION)) {
				return { disabled: true, reason: 'socialEvent.disabledReason.templateAlreadyUsed' };
			}

			return { disabled: false, reason: null };
		}
	}
];
export const pageCards = [
	{
		type: TYPES.LANDINGPAGE,
		title: 'socialEvent.landingpageTitle',
		body: 'socialEvent.landingpageBody',
		icon: 'img/marketing/landing-page.svg',
		editor: 'landingpageEditor',
		templateName: edit => `socialEvent${edit.isWebinar ? 'Webinar' : 'Default'}`,
		disableIf: state => {
			if (state.SocialEventResource.pages.map(e => e.type).includes(TYPES.LANDINGPAGE)) {
				return { disabled: true, reason: 'socialEvent.disabledReason.onlyOneLandingpage' };
			}

			return { disabled: false, reason: null };
		}
	},
	{
		type: TYPES.FORM,
		title: 'socialEvent.formTitle',
		body: 'socialEvent.formBody',
		icon: 'img/marketing/form.svg',
		editor: 'formEditor',
		templateName: () => null,
		disableIf: () => ({ disabled: false, reason: null })
	}
];

const RESET_STATE = '[SocialEventResource] Reset state';
const ADD_AUTOMATED_EMAIL = '[SocialEventResource] Add automated email';
const SET_AUTOMATED_EMAILS = '[SocialEventResource] Set automated emails';
const ADD_MANUAL_EMAIL = '[SocialEventResource] Add manual email';
const SET_MANUAL_EMAILS = '[SocialEventResource] Set manual emails';
const ADD_PAGE = '[SocialEventResource] Add Page';
const SET_PAGES = '[SocialEventResource] Set Pages';
const SET_LANDINGPAGE_URL = '[SocialEventResource] Set landingpage url';
const SET_RESOURCE_DISABLED_DATA = '[SocialEventResource] Enable resource';
const SET_PAGINATION = '[SocialEventResource] Set pagination';
const RESET_PAGINATION = '[SocialEventResource] Reset pagination';

const reducer = (state = initialState, action) => {
	switch (action.type) {
		case RESET_STATE:
			return initialState;
		case SET_RESOURCE_DISABLED_DATA:
			return { ...state, disabled: { ...state.disabled, [action.resourceId]: action.data } };
		case ADD_AUTOMATED_EMAIL:
			return { ...state, emails: [...state.emails, action.email] };
		case SET_AUTOMATED_EMAILS:
			return { ...state, emails: [...action.emails] };
		case ADD_MANUAL_EMAIL:
			return { ...state, manualEmails: [...state.manualEmails, action.email] };
		case SET_MANUAL_EMAILS:
			return { ...state, manualEmails: [...action.emails] };
		case ADD_PAGE:
			return { ...state, pages: [...state.pages, action.page] };
		case SET_PAGES:
			return { ...state, pages: [...action.pages] };
		case SET_LANDINGPAGE_URL:
			return { ...state, landingpageUrl: action.value };
		case SET_PAGINATION:
			return { ...state, pagination: { ...state.pagination, ...action.value } };
		case RESET_PAGINATION:
			return { ...state, pagination: initialState.pagination };
		default:
			return state;
	}
};

export default reducer;

/*********** Actions **********/

export const setAutomatedEmail = email => {
	return { type: ADD_AUTOMATED_EMAIL, email };
};

export const setAutomatedEmails = emails => {
	return { type: SET_AUTOMATED_EMAILS, emails };
};

export const setManualEmail = email => {
	return { type: ADD_MANUAL_EMAIL, email };
};

export const setManualEmails = emails => {
	return { type: SET_MANUAL_EMAILS, emails };
};

export const setPages = pages => {
	return { type: SET_PAGES, pages };
};

export const addPage = page => {
	return { type: ADD_PAGE, page };
};

export const setLandingpageUrl = (socialEvent, uuid) => {
	let value;
	if (socialEvent.domain && socialEvent.urlName) {
		value = 'http://' + socialEvent.domain + '/' + socialEvent.urlName;
	} else {
		value = config.LANDINGPAGEPATH + '/' + uuid;
	}
	return { type: SET_LANDINGPAGE_URL, value };
};

export const setDisableResourceState = (resourceId, data) => {
	return { type: SET_RESOURCE_DISABLED_DATA, resourceId, data };
};

export const reset = () => {
	return { type: RESET_STATE };
};

export const resetPagination = () => {
	return { type: RESET_PAGINATION };
};

const setPaginationRaw = (type, obj) => {
	return { type: SET_PAGINATION, value: { [type]: obj } };
};

/***** Dispatched actions *****/

export const setPagination = (type, offset) => (dispatch, getState) => {
	const { pagination } = getState().SocialEventResource;
	dispatch(setPaginationRaw(type, { ...pagination[type], offset }));
	switch (type) {
		case 'email':
			dispatch(getAutomatedEmails());
			break;
		case 'manualEmail':
			dispatch(getManualEmails());
			break;
		case 'page':
			dispatch(getPages());
			break;
	}
};

/***** PAGES *****/
export function getPages(type, noLimit) {
	return (dispatch, getState) => {
		const {
			SocialEvent: { editId, edit },
			SocialEventResource: { pagination }
		} = getState();
		return SocialEventResource.findPages(editId, noLimit ? { offset: 0, limit: 100 } : pagination.page, type).then(
			res => {
				const { offset, limit } = res.metadata;
				if (res.data.length === 0 && offset > 0) {
					// Refetch with lower offset
					dispatch(setPagination('page', offset - limit));
				} else {
					dispatch(setPaginationRaw('page', { ...pagination.page, total: res.metadata.total }));
					dispatch(setPages(res.data));
					pageCards.forEach(card => dispatch(setDisableResourceState(card.type, card.disableIf(getState()))));
					res.data.forEach(page => {
						if (page.type === TYPES.LANDINGPAGE) {
							dispatch(setLandingpageUrl(edit, page.uuid));
						}
					});
					return res.metadata;
				}
			}
		);
	};
}

export const updatePage = (page, remove) => {
	return (dispatch, getState) => {
		const { pages } = getState().SocialEventResource;
		const i = _.findIndex(pages, { uuid: page.uuid });
		if (i !== -1 && remove) {
			pages.splice(i, 1);
		} else if (i !== -1) {
			pages.splice(i, 1, { ...pages[i], ...page });
		}

		dispatch(setPages(pages));
	};
};

export const deletePage = page => {
	return (dispatch, getState) => {
		const { editId } = getState().SocialEvent;
		dispatch(updatePage({ ...page, isRemoving: true }));
		return SocialEventResource.deletePage(editId, page.id).then(() => {
			dispatch(updatePage(page, true));
			pageCards.forEach(card => dispatch(setDisableResourceState(card.type, card.disableIf(getState()))));
			dispatch(getPages());
		});
	};
};

export const addEventPage = () => {
	return (dispatch, getState) => {
		const { title, body } = getPageModalText();
		const { disabled } = getState().SocialEventResource;

		if (Tools.FeatureHelper.hasSoftDeployAccess('REACT_ALERT_MODAL')) {
			const onAddResource = pageCard => {
				dispatch(createPage(pageCard))
					.then(() => dispatch(getPages()))
					.catch(error => logError(error, 'Error creating event page'));
			};

			return openModal('EditSocialEventAddResource', {
				title,
				body,
				cards: pageCards,
				disabledResources: disabled,
				onClose: pageCard => {
					if (pageCard) {
						onAddResource(pageCard);
					}
				}
			});
		}

		return addResourceModal(title, body, pageCards, disabled)
			.then(pageCard => dispatch(createPage(pageCard)))
			.then(() => dispatch(getPages()));
	};
};

export function createPage({ type, editor, disableIf, templateName }) {
	return async (dispatch, getState) => {
		const {
			SocialEvent: { edit, editId },
			SocialEventResource: { landingpageUrl }
		} = getState();
		const domainProps = edit.domain && edit.urlName ? { domain: edit.domain, urlName: edit.urlName } : null;

		if (type === 'form') {
			const eventId = editId;
			const Form = getAngularModule('Form');
			const FormEditorMeta = getAngularModule('FormEditorMeta');
			const meta = await FormEditorMeta({ customerId: Tools.AppService.getCustomerId() });

			return Form.save({
				...meta.form.data,
				title: translate('form.letsGetInTouch'),
				description: translate('form.fillOutForm'),
				name: edit.name,
				socialEventId: eventId,
				domain: domainProps?.domain || null,
				urlName: domainProps?.urlName || null,
				actions: [
					{
						action: 'EventRegistration',
						properties: [
							{
								name: 'EventId',
								value: eventId
							}
						]
					}
				]
			}).then(res => {
				const formId = res.data.id;
				socialEventTracker.track(socialEventTracker.events.ADD_RESOURCES, { type });
				return SocialEventResource.savePage(eventId, formId, type).then(() => {
					history.push(`/form-editor/${formId}`);
				});
			});
		}

		return Tools.$upModal
			.open(editor, {
				name: edit.name,
				eventId: editId,
				templateName: templateName(edit),
				saveBtnText: 'socialEvent.saveAndReturnToEvent',
				rejectOnBack: true,
				liveTags: getLiveTags(edit, landingpageUrl),
				strictTagList: [getGroupedLiveTags(edit, landingpageUrl)],
				domainProps,
				isSocialEvent: true
			})
			.then(landingpage => {
				socialEventTracker.track(socialEventTracker.events.ADD_RESOURCES, { type: type });
				// Save relation to database
				return SocialEventResource.savePage(editId, landingpage.id, type).then(res => {
					// Create a row in the pages-table
					const page = {
						id: res.data.id,
						name: landingpage.name,
						uuid: landingpage.uuid,
						pageId: landingpage.id,
						type
					};
					dispatch(addPage(page));
					dispatch(setLandingpageUrl(edit, landingpage.uuid));

					dispatch(setDisableResourceState(type, disableIf(getState())));
				});
			});
	};
}

export const editPage = ({ pageId, type }) => {
	return (dispatch, getState) => {
		if (type === 'form') {
			return history.push(`/form-editor/${pageId}`);
		}

		const {
			SocialEvent: { edit, editId },
			SocialEventResource: { landingpageUrl }
		} = getState();

		Tools.$upModal
			.open(type + 'Editor', {
				id: pageId,
				eventId: editId,
				saveBtnText: 'socialEvent.saveAndReturnToEvent',
				liveTags: getLiveTags(edit, landingpageUrl),
				strictTagList: [getGroupedLiveTags(edit, landingpageUrl)],
				isSocialEvent: true
			})
			.then(({ name, uuid }) => dispatch(updatePage({ name, uuid })))
			.catch(err => console.error(err));
	};
};
/***** End of PAGES *****/

/***** AUTOMATED EMAILS *****/
const createEmail =
	({ type }) =>
	(dispatch, getState) => {
		const {
			SocialEvent: { editId }
		} = getState();
		return history.push(`/mail-template-editor/new?eventId=${editId}&type=${type}&emptyEventTemplate=true`);
	};

export function getAutomatedEmails(noLimit) {
	return (dispatch, getState) => {
		const {
			SocialEvent: { editId },
			SocialEventResource: { pagination }
		} = getState();
		return SocialEventResource.findEmails(editId, noLimit ? { offset: 0, limit: 100 } : pagination.email).then(
			res => {
				const { offset, limit } = res.metadata;
				if (res.data.length === 0 && offset > 0) {
					// Refetch with lower offset
					dispatch(setPagination('email', offset - limit));
				} else {
					dispatch(setAutomatedEmails(res.data));
					dispatch(setPaginationRaw('email', { ...pagination.email, total: res.metadata.total }));
					// Update disable state for automated emails
					emailCards.forEach(card =>
						dispatch(setDisableResourceState(card.type, card.disableIf(getState())))
					);
				}
			}
		);
	};
}

export const addAutomatedEmail = () => {
	return (dispatch, getState) => {
		const { title, body } = getEmailModalText();
		const { disabled } = getState().SocialEventResource;

		if (Tools.FeatureHelper.hasSoftDeployAccess('REACT_ALERT_MODAL')) {
			return openModal('EditSocialEventAddResource', {
				title,
				body,
				cards: emailCards,
				disabledResources: disabled,
				onClose: async emailCard => {
					if (emailCard) {
						await dispatch(createEmail(emailCard));
						await dispatch(getAutomatedEmails());
					}
				}
			});
		}

		return addResourceModal(title, body, emailCards, disabled)
			.then(emailCard => dispatch(createEmail(emailCard)))
			.then(() => dispatch(getAutomatedEmails()));
	};
};

export const editAutomatedEmail = template => {
	return () => {
		return history.push(`/mail-template-editor/${template.templateId}`);
	};
};
export const editAutomatedEmailSendTime = (e, template) => {
	e.stopPropagation();

	// Wait until mail template has loaded before scrolling to element
	return () => {
		history.push(`/mail-template-editor/${template.templateId}/settings`);

		const observer = new MutationObserver(() => {
			const element = document.getElementById('mailTemplateEditorSocialEventSendTime');
			if (element) {
				element.scrollIntoView({ behavior: 'smooth' });
				observer.disconnect();
			}
		});
		observer.observe(document, { childList: true, subtree: true });
	};
};
export const deleteAutomatedEmail = email => (dispatch, getState) => {
	dispatch(deleteEmail(email))
		.then(() => {
			const { emails } = getState().SocialEventResource;
			const emailArr = updateResource(emails, email, true);
			dispatch(setAutomatedEmails(emailArr));
			emailCards.forEach(card => dispatch(setDisableResourceState(card.type, card.disableIf(getState()))));
			dispatch(getAutomatedEmails());
		})
		.catch(err => console.error(err));
};
function deleteEmail(email) {
	return (dispatch, getState) => {
		const { editId } = getState().SocialEvent;
		return SocialEventResource.deleteEmail(editId, email.id);
	};
}

function getGroupedLiveTags(edit, landingpageUrl) {
	const tagMap = getLiveTags(edit, landingpageUrl);
	const tagMapWithWebinar = _.cloneDeep(tagMap);
	tagMapWithWebinar['Mail.CanNotAttendLink'] = {
		name: window.Tools.$translate('socialEvent.canNotAttendLink'),
		value: `<a href="{{Mail.CanNotAttendLink}}" ng-non-bindable>${window.Tools.$translate(
			'socialEvent.canNotAttend'
		)}</a>`,
		html: true
	};
	tagMapWithWebinar['Mail.WebinarInviteLink'] = {
		name: edit.isWebinar
			? window.Tools.$translate('socialEvent.webinar.link')
			: window.Tools.$translate('socialEvent.webinar.linkPlaceholder'),
		value: '<a href="{{Mail.WebinarInviteLink}}" ng-non-bindable>webinar</a>',
		html: true,
		isDisabled: !edit.isWebinar
	};
	return {
		title: translate('default.socialEvents'),
		tags: Object.keys(tagMapWithWebinar)
			.map(key => {
				if (tagMapWithWebinar[key].html) {
					return tagMapWithWebinar[key];
				}
				return { name: tagMapWithWebinar[key].name, value: `{{${key}}}`, isDisabled: false };
			})
			.filter(t => !t.hidden),
		liveValues: tagMap
	};
}

/***** End of AUTOMATED EMAILS *****/

/***** MANUAL EMAILS *****/
export function getManualEmails() {
	return (dispatch, getState) => {
		const {
			SocialEvent: { editId },
			SocialEventResource: { pagination }
		} = getState();
		const { id, socialEventId, socialEventSendToStatus } = MailCampaignAttributes;
		const filter = new RequestBuilder();
		filter.addFilter(socialEventId, Equals, editId);
		filter.addFilter(socialEventSendToStatus, NotEquals, null);
		filter.addSort(id, true);
		filter.offset = pagination.manualEmail.offset;
		filter.limit = pagination.manualEmail.limit;
		return MailCampaignResource.find(filter.build()).then(res => {
			const { offset, limit } = res.metadata;
			if (res.data.length === 0 && offset > 0) {
				// Refetch with lower offset
				dispatch(setPagination('manualEmail', offset - limit));
			} else {
				dispatch(setPaginationRaw('manualEmail', { ...pagination.manualEmail, total: res.metadata.total }));
				dispatch(setManualEmails(res.data));
			}
		});
	};
}

export const addManualEmail = () => {
	return (dispatch, getState) => {
		const {
			SocialEvent: { edit }
		} = getState();
		return Tools.$upModal.open('createGroupMail', { socialEventId: edit.id });
	};
};

export const editManualEmail = groupMailId => {
	return () => {
		return history.push(`/group-mail-editor/${groupMailId}`);
	};
};

export const deleteManualEmail = email => (dispatch, getState) => {
	return MailCampaignResource.delete(email.id).then(() => {
		const { manualEmails } = getState().SocialEventResource;
		dispatch(setManualEmails(updateResource(manualEmails, email, true)));
	});
};

/***** End of MANUAL EMAILS *****/

/***** Helpers *****/
function updateResource(list, resource, remove = false) {
	const i = findIndex(list, { id: resource.id });
	if (i !== -1 && remove) {
		list.splice(i, 1);
	} else if (i !== -1) {
		list.splice(i, 1, { ...list[i], ...resource });
	}
	return list;
}

/***** End of Helpers *****/
