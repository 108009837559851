import React from 'react';
import bemClass from '@upsales/components/Utils/bemClass';

import './FakeTooltip.scss';

type Props = {
	title: string;
	tooltipRef?: (r: HTMLDivElement) => void;
};

const FakeTooltip = ({ title, tooltipRef }: Props) => {
	const classes = new bemClass('FakeTooltip');

	return (
		<div
			className={classes.b()}
			ref={r => {
				if (r) {
					tooltipRef?.(r);
				}
			}}
		>
			<div className={classes.elem('arrow').b()}></div>
			<span>{title}</span>
		</div>
	);
};

export default FakeTooltip;
