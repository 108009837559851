import { SubscriptionGroupState } from 'App/components/EditSubscription/Context/SubscriptionGroupState';
import Agreement from 'App/resources/Model/Agreement';
import openModal from 'App/services/Modal';

type OnlyAgreement = {
	agreement: Agreement;
};

type OnlyAgreementId = {
	agreementId: number;
};

type MinimalGroup = {
	id?: number;
	agreements: Agreement[];
	state?: SubscriptionGroupState;
};

type OpenAgreementType = (OnlyAgreement | OnlyAgreementId) & {
	agreementId?: number;
	agreementGroupId?: number;
	agreementGroup?: MinimalGroup;
	agreement?: Agreement & { state?: SubscriptionGroupState };
	childIndexNr?: number;
	dontWait?: boolean;
	isMoved?: boolean;
};

const openAgreement = async (props: OpenAgreementType) => {
	const { agreementId, agreementGroupId, agreementGroup, agreement, childIndexNr, dontWait, isMoved } = props;

	const customerId = Tools.AppService.getCustomerId();
	if (!Tools.FeatureHelper.hasSoftDeployAccess('EDIT_SUBSCRIPTIONS')) {
		return Tools.$upModal.open('editAgreement', { id: agreementId || agreement?.id, customerId });
	}

	let agreements;
	if (agreementGroup) {
		agreements = agreementGroup.agreements;
	}

	const groupId =
		agreementGroupId ||
		agreementGroup?.id ||
		(agreement?.agreementGroupId !== null ? agreement?.agreementGroupId : undefined);

	return openModal('EditSubscription', {
		groupState: agreementGroup?.state || agreement?.state,
		agreementGroupId: groupId,
		agreementId: agreementId || agreement?.id,
		childIndexNr,
		agreements,
		agreement: groupId ? undefined : agreement, // if agreementGroup is defined, we need to fetch the whole group from server
		dontWait,
		isMoved
	});
};

export default openAgreement;
