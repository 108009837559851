import React from 'react';
import { useSelector } from 'react-redux';
import { RootState } from 'Store/index';
import UpSelect from 'Components/Inputs/UpSelect';
import { comparisonTypes } from 'Resources/RequestBuilder';
import ProjectResource from 'App/resources/Project';
import Project from 'App/resources/Model/Project';
import JourneyStep from 'App/resources/Model/JourneyStep';
import T from 'Components/Helpers/translate';
import { AjaxSelect, AjaxSelectProps, Select2Event } from '../AjaxSelect';
import PriceList from 'App/resources/Model/PriceList';
import { getSelectPriceLists } from 'App/helpers/priceListHelper';

type ProjectSelectProps = Pick<
	AjaxSelectProps<Project>,
	'value' | 'onChange' | 'multiple' | 'icon' | 'state' | 'name' | 'required' | 'className'
>;

const STATIC_LIMIT = 500;

export const ProjectSelect = ({ ...props }: ProjectSelectProps) => {
	const { activeCampaigns } = useSelector(({ App }: RootState) => App.totals);
	return (
		<AjaxSelect
			{...props}
			// Use static select if less than 500
			isStatic={activeCampaigns < STATIC_LIMIT}
			resource={ProjectResource}
			modifyRb={rb => {
				rb.addFilter({ field: 'active' }, comparisonTypes.Equals, 1);
				return false;
			}}
		/>
	);
};

type JourneySelectProps = {
	onChange: (step: JourneyStep | null) => void;
	value: null | JourneyStep['value'];
	required?: boolean;
};
export const JourneySelect = ({ value, onChange, required }: JourneySelectProps) => {
	const journeySteps = useSelector(({ App }: RootState) => App.journeySteps);

	return (
		<UpSelect
			icon="journey"
			onChange={(e: Select2Event<JourneyStep>) => onChange(e.target.added ?? null)}
			defaultValue={value}
			options={{
				formatSelection: (o: { name: string }) => T(o.name),
				formatResult: (o: { name: string }) => T(o.name),
				id: (o: { value: string }) => o.value
			}}
			data={journeySteps}
			required={required}
		/>
	);
};

type PriceListSelectProps = {
	defaultId: null | PriceList['id'];
	onChange: (step: number | null) => void;
	required?: boolean;
};

export const PriceListSelect = ({ defaultId, onChange }: PriceListSelectProps) => {
	const priceLists = getSelectPriceLists(defaultId);

	return (
		<UpSelect
			onChange={(e: Select2Event<PriceList>) => onChange(Number(e.target.value) ?? null)}
			placeholder={T('default.priceListSelect')}
			defaultValue={defaultId}
			data={priceLists}
		/>
	);
};
