import React from 'react';
import { Row, Icon, Text, Checkbox, Flex } from '@upsales/components';
import './ReportcenterFilterRow.scss';

export type Props = Partial<typeof Row> & {
	key: number | string;
	title: string;
	selected: boolean;
	bold?: boolean;
	onClick: () => void;
	onReset?: () => void;
	noCheckbox?: boolean;
	inactive?: boolean;
	space?: string;
};

const ReportcenterFilterRow = ({
	title,
	selected,
	bold = false,
	onReset,
	noCheckbox,
	inactive,
	space = 'pll ptm pbm prl',
	...props
}: Props) => {
	return (
		<Flex className="ReportcenterFilterRow" space={space} justifyContent="space-between" {...props}>
			<Flex alignItems="center">
				{noCheckbox ? null : <Checkbox checked={selected} space="mrl" size="sm" />}
				<Text bold={bold} italic={inactive} color={inactive ? 'grey-9' : undefined}>
					{title}
				</Text>
			</Flex>
			{selected && onReset ? (
				<Flex alignItems="center">
					<Icon
						color="grey-10"
						name="times"
						onClick={e => {
							e.stopPropagation();
							onReset?.();
						}}
					/>
				</Flex>
			) : null}
		</Flex>
	);
};

export default ReportcenterFilterRow;
