import React from 'react';
import BemClass from '@upsales/components/Utils/bemClass';
import CreditRisk from 'App/resources/Model/CreditRisk';
import {
	Text,
	Table,
	TableHeader,
	TableHeaderColumn,
	TableRow,
	TableColumn,
	Icon,
	Block,
	Title
} from '@upsales/components';
import { useTranslation } from 'Components/Helpers/translate';
import moment from 'moment';
import { getIconColor } from './RiskDrawerHelper';
import './RiskDrawer.scss';

interface RiskDrawerTableProps {
	creditRisks: CreditRisk[];
}

const RiskDrawerTable = ({ creditRisks }: RiskDrawerTableProps) => {
	const { t } = useTranslation();
	const classes = new BemClass('RiskDrawer');

	if (creditRisks.length === 0) {
		return (
			<Block space="ptxl pbxl" className={classes.elem('risk-drawer-table-empty').b()}>
				<img src="img/empty-briefcase.svg" />
				<Title bold space="ptxl">
					{t('customerPortfolio.risks.drawer.noRisks')}
				</Title>
				<Text>{t('customerPortfolio.risks.drawer.noRisks.description')}</Text>
			</Block>
		);
	}
	return (
		<Table>
			<TableHeader>
				<TableHeaderColumn>{t('default.typeOfEvent')}</TableHeaderColumn>
				<TableHeaderColumn align="right">{t('default.eventDate')}</TableHeaderColumn>
			</TableHeader>
			{creditRisks.map(creditRisk => (
				<TableRow key={creditRisk?.id}>
					<TableColumn className={classes.elem('riskdrawer-row').b()}>
						<Icon
							className={classes.elem('riskdrawer-icon').b()}
							name="circle"
							color={getIconColor(creditRisk?.severity)}
							space="mrm"
						/>
						{t(`customerPortfolio.risks.${creditRisk?.name}`)}
					</TableColumn>
					<TableColumn align="right">{moment(creditRisk?.date).format('L')}</TableColumn>
				</TableRow>
			))}
		</Table>
	);
};

export default RiskDrawerTable;
