import React, { useEffect, useRef, useState } from 'react';
import {
	Block,
	Help,
	Input,
	Label,
	Loader,
	Modal,
	ModalContent,
	ModalHeader,
	Select,
	Text,
	Flex,
	Toggle
} from '@upsales/components';
import BemClass from '@upsales/components/Utils/bemClass';
import { useTranslation } from 'Components/Helpers/translate';
import { ModalProps } from '../Modals/Modals';
import ProductCategory from 'App/resources/Model/ProductCategory';
import { useAppDispatch } from 'App/components/hooks';
import { Feature, useSoftDeployAccess, useFeatureAvailable } from 'App/components/hooks/featureHelper';
import { PrimaryButton, ThirdButton } from '@upsales/components/Buttons';
import { useCustomFields, useProductCategories, useRoles } from 'App/components/hooks/appHooks';
import Role from 'App/resources/Model/Role';
import { calculateField } from '@upsales/common';
import CustomField, { CalculationCustomField, CustomFieldWithStages } from 'App/resources/Model/CustomField';
import { CancelablePromise, makeCancelable } from '@upsales/components/Utils/CancelablePromise';
import RequestBuilder from 'Resources/RequestBuilder';
import ProductAttributes from 'App/babel/attributes/ProductAttributes';
import ComparisonTypes from 'Resources/ComparisonTypes';
import ProductResource from 'Resources/Product';
import ProductCategoryResource from 'Resources/ProductCategory';
import logError from 'Helpers/logError';
import { STYLE, addNotification } from 'Store/reducers/SystemNotificationReducer';
import { TreeItem, buildTreeFromArrayWithParentId } from 'Store/helpers/array';
import FormObserver, { FieldModel } from '../FormObserver';
import RoleSelect from 'Components/RoleSelect';

type SelectOption = { id: number; title: string; disabled?: boolean };
type CategoryOption = ProductCategory & { title: string };

const roleToOption = (role: PartialPick<Role, 'id' | 'name'>) => ({ id: role.id, title: role.name });
const customFieldToOption = (cf: CustomField) => ({ id: cf.id, title: cf.name });

// Will traverse the tree and remove the targetId and all its children. This is used to exclude the current category and its childrens so you can't select them as parent.
const recursiveFilter = function (categories: TreeItem<CategoryOption>[], targetId: number) {
	const filteredCategories = categories.filter(category => {
		const isNotTarget = category.id !== targetId;
		// If not target continue search in children
		if (isNotTarget) {
			category.children = recursiveFilter(category.children, targetId);
		}
		// Ok if not target, otherwise prune tree
		return isNotTarget;
	});

	return filteredCategories;
};

const getTieredProductsCount = (id: number) => {
	const rb = new RequestBuilder();
	rb.limit = 0;
	rb.addFilter(ProductAttributes.categoryId, ComparisonTypes.Equals, id);
	rb.addFilter(ProductAttributes.tiers.attr.start, ComparisonTypes.NotEquals, null);

	return ProductResource.find(rb.build()).then(({ metadata }) => metadata.total);
};

type Props = ModalProps & { productCategory?: ProductCategory };
type Model = Optional<ProductCategory, 'id' | 'userUsable' | '$hasAccess'>;

const EditProductCategory = ({ className, close, productCategory }: Props) => {
	const classes = new BemClass('EditProductCategory', className);
	const { t } = useTranslation();
	const dispatch = useAppDispatch();

	const hasBackendProductAccess = useSoftDeployAccess('BACKEND_PRODUCT_ACCESS');
	const hasRowCustomVisibility = useFeatureAvailable(Feature.ROW_CUSTOM_VISIBILITY);
	const hasCalculatingRowValue = useFeatureAvailable(Feature.CALCULATING_ROW_VALUE);
	const hasUserPermissionsAdvanced = useFeatureAvailable(Feature.USER_PERMISSIONS_ADVANCED);
	const hasProductTiers = useFeatureAvailable(Feature.PRODUCT_TIERS);
	const hasTreeSelect = useSoftDeployAccess('TREE_SELECT');

	const [loading, setLoading] = useState(true);
	const [saving, setSaving] = useState(false);
	const [hasTieredProducts, setHasTieredProducts] = useState(false);
	const [notUniqueNameError, setNotUniqueNameError] = useState(false);
	const [parentOptions, setParentOptions] = useState<TreeItem<CategoryOption>[]>([]);

	const anchor = document.querySelector('.Modals');
	const categories = useProductCategories(true).map(c => ({ ...c, title: c.name }));
	const getProductsRequest = useRef<CancelablePromise<number> | null>(null);

	const roles = useRoles('all');
	const customFields: CustomFieldWithStages[] = useCustomFields('orderrow');
	const customFieldsOptions = customFields.reduce<SelectOption[]>((res, cf) => {
		if (cf.editable || cf.visible) {
			res.push({ id: cf.id, title: cf.name });
		}
		return res;
	}, []);

	const calculatingFieldOptions = customFields.reduce<(SelectOption & { children: SelectOption[] })[]>(
		(res, field) => {
			if (field.datatype === 'Calculation') {
				if (!calculateField.isValidAsValue(field.formula)) {
					res[1].children.push({ ...customFieldToOption(field), disabled: true });
				} else {
					res[0].children.push(customFieldToOption(field));
				}
			}
			return res;
		},
		[
			{ id: -1, title: t('product.selectable'), children: [] },
			{ id: -2, title: t('product.nonSelectable'), children: [] }
		]
	);

	useEffect(() => {
		const tree = buildTreeFromArrayWithParentId(categories);
		if (productCategory?.id) {
			// Remove the current category and its children from the parent options
			setParentOptions(recursiveFilter(tree, productCategory.id));

			// Check if the category has product tiers feature, if yes we need to check if the category has products with tiers in them
			if (hasProductTiers) {
				getProductsRequest.current = makeCancelable(getTieredProductsCount(productCategory.id));
			} else {
				getProductsRequest.current = makeCancelable(Promise.resolve(0));
			}
		} else {
			getProductsRequest.current = makeCancelable(Promise.resolve(0));
			setParentOptions(tree);
		}

		getProductsRequest.current.promise
			.then(count => {
				setHasTieredProducts(!!count);
				setLoading(false);
			})
			.catch(e => {
				// Show error and close modal
				logError(e, 'Failed to fetch tiered products in product category');
				dispatch(
					addNotification({
						title: 'default.error',
						style: STYLE.ERROR,
						icon: 'times',
						body: 'openError.productCategory'
					})
				);
				close();
			});

		return () => {
			getProductsRequest.current?.cancel();
		};
	}, []);

	const save = (category: Model) => {
		setNotUniqueNameError(false);
		setSaving(true);
		ProductCategoryResource.save(category)
			.then(() => close())
			.catch(e => {
				setSaving(false);
				if (e?.response?.data?.error?.key === 'NameNotUnique') {
					setNotUniqueNameError(true);
				} else {
					logError(e, 'Failed to save productCategory');
				}
			});
	};

	const fieldSpace = 'mbxl';

	const validatonModel = {
		name: FieldModel.string('name').required().max(128)
	};

	return (
		<Modal className={classes.b()} size="sm">
			<ModalHeader
				color="green"
				title={t(productCategory?.id ? 'admin.editProductCategory' : 'admin.createProductCategory')}
				onClose={close}
			/>
			{loading ? (
				<Flex alignItems="center" direction="column" space="mtl mbl">
					<Loader noU size="sm" />
				</Flex>
			) : (
				<FormObserver<Model>
					model={validatonModel}
					initialValues={
						productCategory || {
							name: '',
							roles: [] as Role[],
							orderRowFields: [],
							inheritRoles: hasBackendProductAccess,
							parentId: 0,
							sortId: 99999999,
							calculatingField: null
						}
					}
					validateOnMount={false}
					onSubmit={values => {
						save(values);
					}}
				>
					{({ onFormChange, inputProps, values }) => (
						<ModalContent>
							<Block space={fieldSpace}>
								<Label required maxLength={inputProps.name.maxLength} value={inputProps.name.value}>
									{t('admin.productCategoryName')}
								</Label>
								<Input
									disabled={saving}
									{...inputProps.name}
									state={notUniqueNameError ? 'error' : null}
								/>
								{notUniqueNameError ? (
									<Text color="red" italic size="sm" space="mts">
										{t('saveError.productCategory.nameNotUnique')}
									</Text>
								) : null}
							</Block>

							<Block space={fieldSpace}>
								<Label>{t('admin.products.setUnderCategory')}</Label>
								<Select
									placeholder={t('admin.chooseCategory')}
									onClear={() => onFormChange('parentId', 0)}
									onChange={c => onFormChange('parentId', c.id)}
									options={parentOptions}
									disabled={saving}
									anchor={anchor}
									value={
										values.parentId ? categories.find(c => c.id === values.parentId) || null : null
									}
								/>
							</Block>

							{hasUserPermissionsAdvanced ? (
								<Block space={fieldSpace}>
									<Flex justifyContent="space-between">
										<Label>{t('default.roles')}</Label>
										{hasBackendProductAccess && !hasTreeSelect ? (
											<Help articleId={1500} sidebar />
										) : null}
									</Flex>
									{hasTreeSelect ? (
										<RoleSelect
											roles={roles}
											positionRelative
											selectedRoles={productCategory?.roles || []}
											disabled={saving}
											onChange={v => onFormChange('roles', v)}
										/>
									) : (
										<Select
											placeholder={t('admin.documentTemplateModal.rolesPlaceholder')}
											options={roles.map(roleToOption)}
											value={values.roles.map(roleToOption)}
											disabled={saving}
											multi
											hideSelected
											anchor={anchor}
											onRemove={id =>
												onFormChange(
													'roles',
													values.roles.filter(r => r.id !== id)
												)
											}
											onChange={v =>
												onFormChange('roles', [...values.roles, { id: v.id, name: v.title }])
											}
										/>
									)}
									{hasBackendProductAccess && values.parentId && !hasTreeSelect ? (
										<Flex space="mtm">
											<Toggle
												size="sm"
												color="medium-green"
												disabled={saving}
												checked={values.inheritRoles}
												onChange={v => onFormChange('inheritRoles', v)}
											/>
											<Text size="sm" space="mlm">
												{t('admin.productCategory.inheritFromParent')}
											</Text>
										</Flex>
									) : null}
								</Block>
							) : null}

							{hasRowCustomVisibility ? (
								<Block space={fieldSpace}>
									<Label>{t('admin.productCategory.orderRowFields.onlyShow')}</Label>
									<Select
										placeholder={t('admin.productCategory.orderRowFields.selectField')}
										options={customFieldsOptions}
										disabled={saving}
										multi
										hideSelected
										value={values.orderRowFields.map(cf => ({
											id: cf.id,
											title: cf.name || t('default.deleted')
										}))}
										anchor={anchor}
										onRemove={id =>
											onFormChange(
												'orderRowFields',
												values.orderRowFields.filter(cf => cf.id !== id)
											)
										}
										onChange={cf =>
											onFormChange('orderRowFields', [
												...values.orderRowFields,
												{ id: cf.id, name: cf.title }
											])
										}
									/>
								</Block>
							) : null}

							{hasCalculatingRowValue ? (
								<Block space={fieldSpace}>
									<Flex justifyContent="space-between">
										<Label>{t('admin.productCategory.useCalculatingFieldAsRowValue')}</Label>
										<Help articleId={997} sidebar />
									</Flex>
									<Select<SelectOption>
										placeholder={t('default.notUsed')}
										disabled={hasTieredProducts || saving}
										optionHeaderType="disabled"
										options={calculatingFieldOptions}
										value={
											values.calculatingField
												? {
														id: values.calculatingField.id,
														title: values.calculatingField.name
												  }
												: null
										}
										anchor={anchor}
										onChange={cf =>
											onFormChange(
												'calculatingField',
												(customFields as CalculationCustomField[]).find(f => f.id === cf.id) ||
													null
											)
										}
										onClear={() => onFormChange('calculatingField', null)}
									/>
									{hasTieredProducts ? (
										<Text color="grey-10" italic size="sm" space="mts">
											{t('product.hasTieredProducts')}
										</Text>
									) : null}
								</Block>
							) : null}

							<PrimaryButton
								block
								submit
								size="lg"
								shadow="none"
								disabled={!values.name}
								loading={saving}
							>
								{t('default.save')}
							</PrimaryButton>
							<ThirdButton onClick={() => close()} block>
								{t('default.abort')}
							</ThirdButton>
						</ModalContent>
					)}
				</FormObserver>
			)}
		</Modal>
	);
};

export default EditProductCategory;
