import React from 'react';
import { Block, Input, Row } from '@upsales/components';
import { ListViewFilter } from 'App/resources/AllIWant';

export type TextFilter = Omit<ListViewFilter, 'value'> & { value: string };

type TextFilterProps = {
	filter: TextFilter;
	setClosed: () => void;
	valueChanged: (value: TextFilter['value']) => void;
};

function TextFilterComponent({ filter, setClosed, valueChanged }: TextFilterProps) {
	return (
		<Block space="plm ptm prm pbm">
			<Row>
				<form onSubmit={() => setClosed()}>
					<Input autofocus value={filter.value} type="text" onChange={e => valueChanged(e.target.value)} />
				</form>
			</Row>
		</Block>
	);
}

export default TextFilterComponent;
