import React, { ReactNode } from 'react';
import moment from 'moment';
import HistoryLog from '../HistoryLog';
import { EVENT_TIMELINE_ENTITY_TYPES, EVENT_TIMELINE_SUB_TYPES } from 'App/babel/enum/timeline';
import ContactTimelineCreateContact from './ContactTimelineCreateContact';
import { getTimelineRow } from '../../../babel/components/Helpers/getTimelineRow';
import T from 'Components/Helpers/translate';
import ComparisonTypes from 'Resources/ComparisonTypes';
import Contact from 'App/resources/Model/Contact';
import usePartialLoader, { type FetcherFunction } from 'App/components/hooks/usePartialLoader';
import type { HistoryLogRenderedBy } from 'App/components/HistoryLog/ModalHistoryLog';
import { TimelineComponentProps } from 'Components/TimelineRow/TimelineGeneric/TimelineCardConfigs';
import TimelineLoadMore from 'Components/TimelineRow/TimelineLoadMore/TimelineLoadMore';
import Event from 'App/resources/Model/Event';

const listeners = ['comment.added', 'comment.deleted', 'comment.updated'];

type Props = {
	contact: Contact;
	client: Contact['client'];
	notesFilterActive?: boolean;
	renderedBy: HistoryLogRenderedBy;
	selectButtons?: ReactNode;
	cardAdditionalProps?: Partial<TimelineComponentProps>;
};

const fetcher: FetcherFunction<Pick<Props, 'notesFilterActive' | 'contact'>, Event> = (
	filters,
	{ contact, notesFilterActive }
) => {
	const event = Tools.Event;

	filters.addFilter(event.attr.contacts.attr?.id, ComparisonTypes.Equals, contact.id);
	filters.addFilter(event.attr.date, ComparisonTypes.LessThanEquals, moment().format());
	filters.addFilter({ field: 'feedContact2' }, ComparisonTypes.Equals, null);
	filters.addFilter(event.attr.entityType, ComparisonTypes.NotEquals, ['Contact']);

	if (notesFilterActive) {
		filters.addFilter({ field: 'feedNotes' }, ComparisonTypes.Equals, null);
	}

	filters.addSort(event.attr.date, false);
	filters.addSort(event.attr.entityType, false);
	return event.find(filters.build());
};

const ContactHistoryLog = ({ contact, client, renderedBy, notesFilterActive, selectButtons }: Props) => {
	const {
		data: events,
		total: totalEvents,
		loading,
		loadMore
	} = usePartialLoader({
		fetcher,
		broadcastTypes: listeners,
		fetcherProps: {
			contact,
			notesFilterActive
		}
	});

	if (!contact || !contact.id) {
		return null;
	}
	let timelineElements: ReactNode[] = [];
	if (events && events.length) {
		timelineElements = events.map(event => {
			return event ? (
				<div key={event.id}>{getTimelineRow(event, 'Contact', notesFilterActive, false, renderedBy)}</div>
			) : null;
		});
	}

	const contactRegEvent = {
		entityType: EVENT_TIMELINE_ENTITY_TYPES.CONTACT,
		subType: EVENT_TIMELINE_SUB_TYPES.CREATED,
		users: contact.regBy ? [contact.regBy] : [],
		entityId: contact.id,
		client: client,
		contact: contact
	};

	return (
		<HistoryLog title={T('history')} selectButtons={selectButtons} loading={loading}>
			{timelineElements}
			{timelineElements.length < totalEvents ? (
				<TimelineLoadMore
					newEventsLoading={loading}
					numEventsLoaded={events.length}
					totalEvents={totalEvents ? totalEvents : null}
					incrementOffset={loadMore}
				/>
			) : null}
			<ContactTimelineCreateContact isHistoryLog event={contactRegEvent} />
		</HistoryLog>
	);
};

export default ContactHistoryLog;
