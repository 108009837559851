import React from 'react';
import PropTypes from 'prop-types';
import { Button, Card, Column, Icon, Row } from '@upsales/components';
import './FocusModeFooter.scss';
import BemClass from '@upsales/components/Utils/bemClass';
import T from 'Components/Helpers/translate';

const FocusModeFooter = ({ iterateCallList, focusData, index }) => {
	const classes = new BemClass('FocusModeFooter');
	return (
		<Card className={classes.b()} space="ptxl prxl pbxl plxl" borderColor="grey-6" border="ts">
			<Row>
				<Column align="left">
					<Button
						type="lined"
						color="bright-blue"
						onClick={() => iterateCallList(false)}
						shadow="none"
						disabled={!focusData[index - 1]}
					>
						<Icon name="chevron-left" />
						{`${T('default.previous')} ${T('todo.phoneCall').toLowerCase()}`}
					</Button>
				</Column>
				<Column align="right">
					<Button
						type="lined"
						color="bright-blue"
						onClick={() => iterateCallList(true)}
						shadow="none"
						disabled={!focusData[index + 1]}
					>
						{`${T('default.next')} ${T('todo.phoneCall').toLowerCase()}`}
						<Icon name="chevron-right" />
					</Button>
				</Column>
			</Row>
		</Card>
	);
};

FocusModeFooter.propTypes = {
	focusData: PropTypes.array,
	loading: PropTypes.bool,
	iterateCallList: PropTypes.func
};

export default FocusModeFooter;
