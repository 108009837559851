import Resource from 'Resources/Resource';

class Import extends Resource {
	status = {
		DRAFT: 'DRAFT',
		START: 'START',
		STARTED: 'STARTED',
		FINISHED: 'FINISHED'
	};

	type = {
		CLIENT_AND_CONTACT: 'CLIENT_AND_CONTACT',
		PRODUCT: 'PRODUCT'
	};

	constructor() {
		super('imports');
	}
}

const resource = new Import();

export default resource;
