import { useSubscriptionGroupContext } from '../../Context/SubscriptionGroupContext';
import SubscriptionSidebarActions from './SubscriptionSidebarActions';
import SubscriptionPeriodsTree from './SubscriptionPeriodsTree';
import lottieEng from './animations/splitAnimation_eng.json';
import lottieSwe from './animations/splitAnimation_swe.json';
import { PrimaryButton } from '@upsales/components/Buttons';
import SubscriptionHotspot from '../../SubscriptionHotspot';
import React, { useEffect, useRef, useState } from 'react';
import BemClass from '@upsales/components/Utils/bemClass';
import T from 'Components/Helpers/translate';
import { Block, Text, Row } from '@upsales/components';
import Lottie from 'react-lottie';
import SubscriptionUiElements from './SubscriptionUiElements';
import { useSoftDeployAccess } from 'App/components/hooks';

import './SplitSubscriptionSidebar.scss';

const defaultOptions = (animationData: any) => ({
	autoplay: false, // Does not work...
	loop: false,
	animationData: animationData,
	rendererSettings: {
		preserveAspectRatio: 'xMidYMid slice'
	}
});

type Animation = {
	setDirection: (direction: -1 | 1) => void;
	setSpeed: (speed: number) => void;
	play: () => void;
};

type Props = {
	disabled?: boolean;
	close: () => void;
	activateSplitPage: () => void;
};

const SplitSubscriptionSidebar = ({ disabled, close, activateSplitPage }: Props) => {
	const classes = new BemClass('SplitSubscriptionSidebar');

	const {
		state: { locked, subscriptionMap },
		currentPeriod
	} = useSubscriptionGroupContext();
	const lottieRef = useRef<Lottie & { anim: Animation }>(null);

	const hasAppFrameworkUiElementsSubscription = useSoftDeployAccess('APP_FRAMEWORK_UI_ELEMENTS_SUBSCRIPTION');

	// Lottie's autoplay: false does not work so I have to do this...
	const [stopAnimation, setStopAnimation] = useState(true);

	const showTree = Object.values(subscriptionMap).length > 1 || currentPeriod.children?.length;

	const playAnimation = (playBack: boolean) => {
		if (showTree || disabled) return;

		setStopAnimation(false);
		const direction = playBack ? -1 : 1;
		const speed = playBack ? 5 : 1;
		lottieRef.current?.anim.setDirection(direction);
		lottieRef.current?.anim.setSpeed(speed);
		lottieRef.current?.anim.play();
	};

	const correctLangLottie = Tools.AppService.getSelf()?.language === 'sv-SE' ? lottieSwe : lottieEng;

	useEffect(() => {
		if (disabled || showTree || stopAnimation) return;
		playAnimation(true);
	}, [disabled]);

	return (
		<div className={classes.b()} onMouseEnter={() => playAnimation(false)} onMouseLeave={() => playAnimation(true)}>
			<div className={classes.elem('fixedSidebar').b()}>
				<div className={classes.elem('sidebarContent').b()}>
					{showTree ? (
						<SubscriptionPeriodsTree activateSplitPage={activateSplitPage} disabled={disabled} />
					) : (
						<div className={classes.mod('create-state').b()}>
							<Text size="lg" bold>
								<Row align="center">
									{T('subscription.modal.summary.splitSidebar.title')}
									<SubscriptionHotspot type="planChange" />
								</Row>
							</Text>
							<Block space="pll prl">
								<Text color="grey-11">{T('subscription.modal.summary.splitSidebar.subtitle')}</Text>
							</Block>
							<Lottie
								isStopped={stopAnimation}
								ref={lottieRef}
								options={defaultOptions(correctLangLottie)}
							/>
							<PrimaryButton onClick={activateSplitPage} disabled={disabled || locked}>
								<Block space="mlxl mrxl">{T('subscription.modal.summary.splitSidebar.button')}</Block>
							</PrimaryButton>
						</div>
					)}

					{hasAppFrameworkUiElementsSubscription ? <SubscriptionUiElements /> : null}
				</div>

				<SubscriptionSidebarActions close={close} />
			</div>
		</div>
	);
};

export default SplitSubscriptionSidebar;
