import { useEffect, useMemo, useRef } from 'react';

import useCallEntityEditListeners from './useCallEntityEditListeners';
import {
	useCurrentSubscriptionPeriod,
	useSubscriptionGroupContext
} from 'App/components/EditSubscription/Context/SubscriptionGroupContext';
import { reset, setUpdateHash } from 'Store/reducers/EditListenerReducer';
import { useOverrideCustomFields, useUpdateCustomFields } from './editListenerHooks';
import useAppDispatch from '../useAppDispatch';

export const useSubscriptionCallEditListener = () => {
	const { currentPeriod, currentChild } = useCurrentSubscriptionPeriod();
	const {
		state: { fetching },
		currentPeriod: { externallyLocked, uuid },
		setCustomFields,
		setPeriodExternallyLocked
	} = useSubscriptionGroupContext();
	const dispatch = useAppDispatch();

	const entityState = useMemo(
		() => ({
			custom: currentPeriod.custom ?? [],
			priceList: currentPeriod.priceList,
			id: currentPeriod.id,
			uuid: currentPeriod.uuid,
			orderRow: currentChild?.orderRow || currentPeriod.orderRows
		}),
		[currentPeriod, currentChild]
	);

	useEffect(() => {
		dispatch(reset());

		return () => {
			dispatch(reset());
		};
	}, [uuid]);

	const pendingRequestsCount = useCallEntityEditListeners({ entity: 'agreement', entityState, loading: fetching });

	const focusedElementBeforeLocked = useRef<Element | null>(null);

	useEffect(() => {
		const lockPeriod = pendingRequestsCount > 0;
		if (lockPeriod) {
			focusedElementBeforeLocked.current = document.activeElement;
		}
		setPeriodExternallyLocked(lockPeriod);
	}, [pendingRequestsCount]);

	const focusPreviouslyFocusedInputElement = () => {
		const focusedElement = focusedElementBeforeLocked.current;
		if (focusedElement && 'name' in focusedElement) {
			const className = Array.from(focusedElementBeforeLocked.current?.classList ?? []).join('.');
			const selector = `.${className}[name="${focusedElement.name}"]`;
			const element = document.querySelector(selector) as HTMLElement;
			if (element && 'focus' in element) {
				element.focus?.();
			}
		}
	};

	useEffect(() => {
		if (!externallyLocked && focusedElementBeforeLocked.current) {
			focusPreviouslyFocusedInputElement();
		}
	}, [externallyLocked]);

	useUpdateCustomFields({
		entityType: 'order',
		entity: currentPeriod,
		onCustomFieldUpdate: updatedCustomFields => {
			setCustomFields(uuid, updatedCustomFields, true, {});
			dispatch(setUpdateHash(`${currentPeriod.uuid}`, updatedCustomFields));
		}
	});
	useOverrideCustomFields();
};

export default useSubscriptionCallEditListener;
