// I think we can refactor the filter sidebar component to not needing this in the future
import { FilterConfig } from 'App/babel/filterConfigs/FilterConfig';
import CustomField from 'App/resources/Model/CustomField';
import _ from 'lodash';

export default class FilterService {
	public customerId: number;
	public filterConfigs: { [name: string]: FilterConfig };
	public customFields: CustomField[];
	public relatedEntities: string[];

	constructor(
		customerId: number,
		filterConfigs: { [name: string]: FilterConfig },
		customFields?: CustomField[],
		relatedEntities?: any[]
	) {
		this.customerId = customerId;
		this.filterConfigs = filterConfigs;
		this.customFields = customFields ? _.filter(customFields, '$hasAccess') : [];
		this.relatedEntities = relatedEntities || [];
	}
}
