import React, { ComponentProps } from 'react';
import CreateTodo from 'Components/CreateTodo/CreateTodo';
import T from 'Components/Helpers/translate';
import { mapProperties as mp, parseProperties as pp } from 'Services/ActionProperties';
import { ModalProps } from '../Modals/Modals';

enum KeyToPropertyMap {
	description = 'Description',
	user = 'User',
	priority = 'Priority',
	notes = 'Notes',
	ActivityType = 'ActivityType',
	date = 'Date',
	time = 'Time'
}
type PropertyKeys = `${KeyToPropertyMap}`;
type PropertyValue = any;
type Property = { name: PropertyKeys; value: PropertyValue };
type PropertyMap = { [attribute in KeyToPropertyMap]?: PropertyValue };

const parseProperties: (propertiesArray: Property[]) => PropertyMap = pp;
const mapProperties: (properties: PropertyMap) => Property[] = mp;

type Props = ModalProps & {
	onSave: (propertiesArray: Property[]) => void;
	properties: Property[];
};

type SettableKeys = Omit<typeof KeyToPropertyMap, 'ActivityType'>;

type OnSave = Extract<ComponentProps<typeof CreateTodo>, { dontSaveTodo: true }>['onSave'];
type SaveReturn = Omit<Parameters<OnSave>[0], 'users'> & { date?: Date | null; user?: number };
type InProps = Pick<SaveReturn, keyof SettableKeys>;

const CreateTodosAction = ({ className, modalId, onSave, close, properties }: Props) => {
	const save: OnSave = data => {
		const convertedProps = (Object.keys(KeyToPropertyMap) as (keyof SettableKeys)[]).reduce<PropertyMap>(
			(res, propKey) => {
				if (propKey === 'user') {
					const propValue = data.users?.[0]?.id || null;
					if (propValue) {
						res[KeyToPropertyMap[propKey]] = propValue;
					}
					return res;
				}
				const propValue = data[propKey];
				if (propValue || propValue === 0) {
					res[KeyToPropertyMap[propKey]] = propValue;
				}

				return res;
			},
			{}
		);
		convertedProps.ActivityType = Tools.AppService.getTodoTypes().TODO.id;
		const propArray = mapProperties(convertedProps);
		onSave(propArray);
	};

	const parsed = parseProperties(properties);
	const parsedProps = (Object.keys(KeyToPropertyMap) as (keyof typeof KeyToPropertyMap)[]).reduce<InProps>(
		(res, propKey) => {
			if (propKey !== 'ActivityType') {
				res[propKey] = parsed[KeyToPropertyMap[propKey]];
			}
			return res;
		},
		{}
	);

	return (
		<CreateTodo
			className={className}
			modalId={modalId}
			onSave={save}
			dontSaveTodo
			saveBtnLabel={T('todo.editTodo.save')}
			close={close}
			triggerAction
			triggerEntity="contact"
			asAutomationAction // to hide the "Logged in user" option in the user select list
			{...parsedProps}
		/>
	);
};

export default CreateTodosAction;
