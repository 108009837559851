import React, { PureComponent } from 'react';
import { connect, ConnectedProps } from 'react-redux';
import bemClass from '@upsales/components/Utils/bemClass';
import { Block, Button, Label, SelectTokens } from '@upsales/components';
import translate from 'Components/Helpers/translate';
import LocalStorage, { TestRecipient } from 'Components/Helpers/LocalStorage';
import { RootState } from 'Store/index';

type MailEditorSendTestState = {
	sendToEmails: TestRecipient[];
	sendingTests: boolean;
};

const mapStateToProps = ({ App }: RootState) => ({
	email: App.accountSelf?.email ?? ''
});

const connector = connect(mapStateToProps, {});

type SalesboardPropsFromRedux = ConnectedProps<typeof connector>;

interface MailEditorSendTestProps extends SalesboardPropsFromRedux {
	sendTest: (emails: string[]) => Promise<void>;
}

// eslint-disable-next-line no-useless-escape
const emailRegex = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

class MailEditorSendTest extends PureComponent<MailEditorSendTestProps, MailEditorSendTestState> {
	lang = {
		sendTest: translate('groupMail.sendTest'),
		testRecipients: translate('mail.testRecipients')
	};

	mounted = false;
	LocalStorage: LocalStorage;

	constructor(p: MailEditorSendTestProps) {
		super(p);

		this.LocalStorage = new LocalStorage();

		let sendToEmails = this.LocalStorage.getTestRecipients();
		if (!sendToEmails) {
			const selfEmail = this.props.email;
			sendToEmails = this.props.email ? [{ id: selfEmail, text: selfEmail }] : [];
		}

		this.state = {
			sendToEmails,
			sendingTests: false
		};
	}

	componentDidMount() {
		this.mounted = true;
	}

	componentWillUnmount() {
		this.mounted = false;
	}

	setStateSafe = (state: Partial<MailEditorSendTestState>) => {
		if (this.mounted) {
			this.setState(state as MailEditorSendTestState);
		}
	};

	sendTest = async () => {
		this.setStateSafe({ sendingTests: true });
		this.LocalStorage.setTestRecipients(this.state.sendToEmails);
		this.props
			.sendTest(this.state.sendToEmails.map(obj => obj.id))
			.then(() => this.setStateSafe({ sendingTests: false }))
			.catch(() => this.setStateSafe({ sendingTests: false }));
	};

	render() {
		const classes = new bemClass('MailEditorSendTest');
		return (
			<div className={classes.b()}>
				<Label>{this.lang.testRecipients}</Label>
				<Block space="mbl">
					<SelectTokens
						value={this.state.sendToEmails.map(o => ({ id: o.id, title: o.text }))}
						validator={term => emailRegex.test(term)}
						onChange={values =>
							this.setStateSafe({
								sendToEmails: values.map(o => ({ id: o.id.toString(), text: o.id.toString() }))
							})
						}
						formatMessage={(string, isValid) =>
							isValid ? `${translate('default.add')}: ${string}` : translate('default.enterEmail')
						}
					/>
				</Block>
				<Button
					block={true}
					onClick={this.sendTest}
					loading={this.state.sendingTests}
					disabled={this.state.sendToEmails.length === 0}
				>
					{this.lang.sendTest}
				</Button>
			</div>
		);
	}
}

export const detached = MailEditorSendTest;

export default connector(MailEditorSendTest);
