import Resource from './Resource';

class DetectedDuplicates extends Resource {
	constructor() {
		super('detectedduplicates');
	}

	batch(data: object) {
		return this._postRequest('batch/', data, { methodName: 'batch' }).then(res => res.data);
	}
}
const instance = new DetectedDuplicates();

export default instance;
