import Resource from './Resource';

type TemplateName = 'verifyDomains' | 'installingScript' | 'requestAddon';

class SystemMail extends Resource {
	constructor() {
		super('function/system-mail');
	}

	send(
		templateName: TemplateName,
		email: string[],
		additional?: { domain?: string; alias?: string; trial?: boolean }
	) {
		return this._postRequest('', { templateName, email, additional }, {}).then(d => d.data);
	}
}

const resource = new SystemMail();

export default resource;
