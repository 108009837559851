enum ActionsGroup {
	SET_SUBSCRIPTIONS = '[SubscriptionGroup] SET_SUBSCRIPTIONS',
	SET_CURRENT = '[SubscriptionGroup] SET_CURRENT',
	SET_LOCKED = '[SubscriptionGroup] SET_LOCKED',
	SET_CURRENT_CHILD = '[SubscriptionGroup] SET_CURRENT_CHILD',
	SPLIT_PERIOD = '[SubscriptionGroup] SPLIT_PERIOD',
	REMOVE_PERIOD = '[SubscriptionGroup] REMOVE_PERIOD',
	SET_GROUP_FIELDS = '[SubscriptionGroup] SET_GROUP_FIELDS',
	SET_ADVANCED_MODE = '[SubscriptionGroup] SET_ADVANCED_MODE',
	SET_SAVING = '[SubscriptionGroup] SET_SAVING',
	SET_STEP = '[SubscriptionGroup] SET_STEP',
	SET_ID = '[SubscriptionGroup] SET_ID',
	SET_FINAL_STEP = '[SubscriptionGroup] SET_FINAL_STEP',
	SET_DIFF_ORDERS = '[SubscriptionGroup] SET_DIFF_ORDERS',
	EXPAND_PREVIEW_FOOTER = '[SubscriptionGroup] EXPAND_PREVIEW_FOOTER',
	DELETE_CHILD = '[SubscriptionGroup] DELETE_CHILD',
	SET_PERIOD_CHILDREN = '[SubscriptionGroup] SET_PERIOD_CHILDREN',
	SET_INVOICE_RELATED_CLIENT = '[SubscriptionGroup] SET_INVOICE_RELATED_CLIENT'
}

enum ActionsPeriod {
	TOGGLE_RENEWAL = '[SubscriptionGroup] TOGGLE_RENEWAL',
	SET_START_DATE = '[SubscriptionGroup] SET_START_DATE',
	SET_TERMINATION_DATE = '[SubscriptionGroup] SET_TERMINATION_DATE',
	SET_ORDER_ROWS = '[SubscriptionGroup] SET_ORDER_ROWS',
	SET_OFFSET = '[SubscriptionGroup] SET_OFFSET',
	SET_CUSTOM_VALUES = '[SubscriptionGroup] SET_CUSTOM_VALUES',
	SET_NOTICE_PERIOD = '[SubscriptionGroup] SET_NOTICE_PERIOD',
	SET_ORDER_INTERVAL = '[SubscriptionGroup] SET_ORDER_INTERVAL',
	SET_CURRENCY = '[SubscriptionGroup] SET_CURRENCY',
	SET_PERIOD_AND_INTERVAL = '[SubscriptionGroup] SET_PERIOD_AND_INTERVAL',
	SET_FIRST_PERIOD_START_DATE = '[SubscriptionGroup] SET_FIRST_PERIOD_START_DATE',
	SET_CREDIT_INITIAL_DATE = '[SubscriptionGroup] SET_CREDIT_INITIAL_DATE',
	SET_CUSTOM_FIELDS_VALID = '[SubscriptionGroup] SET_CUSTOM_FIELDS_VALID',
	SET_CHILDREN = '[SubscriptionGroup] SET_CHILDREN',
	SET_PRICE_LIST = '[SubscriptionGroup] SET_PRICE_LIST',
	SET_EXTERNALLY_LOCKED = '[SubscriptionGroup] SET_EXTERNALLY_LOCKED'
}

export { ActionsGroup, ActionsPeriod };
