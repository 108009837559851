import Resource from './Resource';
import { TYPE, STYLE } from 'Store/reducers/SystemNotificationReducer';

class Domains extends Resource {
	constructor() {
		super('domains');

		this.notifications = {
			verify: () => {
				return {
					title: 'mail.validatedDomain',
					style: STYLE.SUCCESS,
					icon: 'check',
					type: TYPE.Body
				};
			},
			verifyError: () => {
				return {
					title: 'default.error',
					body: 'default.domainVerifyError',
					style: STYLE.ERROR,
					icon: 'times',
					type: TYPE.Body
				};
			}
		};
	}

	new() {
		return {
			domain: '',
			hash: ''
		};
	}

	verify(id) {
		return this._postRequest('verify/' + id, {}, { methodName: 'verify' }).then(res => res.data);
	}

	checkAvailable(domain, url, socialEventId, landingpageId) {
		return this._getRequest('checkAvailable/', {
			methodName: 'checkAvailable',
			params: { domain, url, socialEventId, landingpageId }
		}).then(res => res.data);
	}
}

const resource = new Domains();

window.Tools.Domains = resource;

export default resource;
