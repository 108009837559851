import React from 'react';
import PropTypes from 'prop-types';

import { getJourneyStep, statuses } from 'Components/Helpers/journeyStep';
import t from 'Components/Helpers/translate';

const JourneyStepIcon = ({ id }) => {
	const status = getJourneyStep(id);
	return !status ? null : <div className="JourneyStepIcon">{t(status.name)}</div>;
};

JourneyStepIcon.propTypes = {
	id: PropTypes.oneOf(statuses)
};

export default JourneyStepIcon;
