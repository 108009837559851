import React from 'react';
import PropTypes from 'prop-types';
import Search from './Search';
import RequiredFields from './RequiredFields';
import ShowGroup from './ShowGroup';
import Merge from './Merge';
import getAngularModule from '../../../angularHelpers/getAngularModule';
import { Logo, Icon } from '@upsales/components';

class Root extends React.Component {
	constructor(props) {
		super(props);
		const t = getAngularModule('$translate');

		this.lang = {
			backToSearch: t('account.backToSearch')
		};
	}

	render() {
		let Content = Search;
		let view = 'search';

		if (this.props.addingAccount && this.props.addingAccount.merging) {
			view = 'merge';
			Content = Merge;
		} else if (this.props.requiredFields && this.props.addingAccount) {
			view = 'requiredFields';
			Content = RequiredFields;
		} else if (this.props.companyGroup && this.props.companyGroup.openingGroup === false) {
			view = 'showGroup';
			Content = ShowGroup;
		}

		return (
			<div>
				<Logo id="up-logo-wrapper" color="green" />
				{view === 'showGroup' || view === 'requiredFields' || view === 'merge' ? (
					<button className="up-btn back-to-search no-shadow" onClick={this.props.actions.backToSearch}>
						{this.lang.backToSearch}
					</button>
				) : null}
				<button className="exit-button" onClick={this.props.actions.close}>
					<Icon name="times" />
				</button>
				<Content {...this.props} {...(view === 'merge' ? { isFromCreateAccount: true } : {})} />
			</div>
		);
	}
}

Root.propTypes = {
	requiredFields: PropTypes.oneOfType([PropTypes.array, PropTypes.object]),
	actions: PropTypes.object,
	addingAccount: PropTypes.object,
	companyGroup: PropTypes.object,
	setInputRef: PropTypes.func
};

window.CreateNewAccountModal = Root;
export default Root;
