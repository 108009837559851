import React from 'react';
import TimelineGeneric from '../TimelineGeneric/TimelineGeneric';

import type { TimelineCardComponent } from '../TimelineGeneric/TimelineCardConfigs';
import { useTranslation } from 'Components/Helpers/translate';
import { EVENT_TIMELINE_SUB_TYPES } from 'App/babel/enum/timeline';

const TimelineCardOpportunity = (({ event, renderedById, ...passthrough }) => {
	const { entityId, opportunity, subType } = event || {};
	const { t } = useTranslation();

	if (!opportunity) {
		return null;
	}

	const secondElement =
		renderedById === opportunity.id
			? () => `${t('default.createdThis').toLowerCase()} ${t('default.opportunity').toLowerCase()}`
			: 'eventLink';

	const users =
		opportunity.regBy && subType?.toLowerCase() === EVENT_TIMELINE_SUB_TYPES.CREATED
			? [opportunity.regBy]
			: event.users;

	const currencyText = () => (
		<b>{`${Tools.$filter('currencyFormat')(
			opportunity.orderValue || opportunity.value,
			opportunity.currency ||
				Tools.AppService.getMetadata().customerCurrencies.find(curr => curr.masterCurrency === true)?.iso ||
				''
		)}`}</b>
	);

	return (
		<TimelineGeneric
			event={{
				...event,
				users
			}}
			onLinkClick={() => Tools.$upModal.open('editOrder', { id: entityId })}
			eventLinkTitle={'default.createdOpportunity'}
			titleElements={['users', secondElement, 'contacts', currencyText]}
			commentFetchFilters={{ opportunityId: event.entityId }}
			notes={event.opportunity?.notes || ''}
			afterTime={opportunity.stage?.name}
			{...passthrough}
		/>
	);
}) satisfies TimelineCardComponent;

export default TimelineCardOpportunity;
