import './RecipientListItem.scss';

import { Text } from '@upsales/components';
import BemClass from '@upsales/components/Utils/bemClass';
import { MailRecipient } from 'App/resources/Model/Mail';

import React from 'react';

type RecipientListItemProps = {
	recipient: MailRecipient;
	onClick?: (recipient: MailRecipient) => void;
};

const RecipientListItem: React.FC<RecipientListItemProps> = ({ recipient, onClick = () => {} }) => {
	const classes = new BemClass('RecipientListItem');

	return (
		<div className={classes.b()} onMouseDown={() => onClick(recipient)}>
			<div>
				<Text color="black">{recipient.name}</Text>
				<div className={classes.elem('info').b()}>
					<Text size="sm" color="grey-11" className={'text-ellipsis'}>{`${recipient.email}`}</Text>

					{recipient.type === 'contact' && recipient.client ? (
						<React.Fragment>
							<Text color="grey-11" className={classes.elem('info').elem('dot').b()}>
								{'•'}
							</Text>
							<Text size="sm" color="grey-11" className={'text-ellipsis'}>
								{recipient.client.name}
							</Text>
						</React.Fragment>
					) : null}
				</div>
			</div>
		</div>
	);
};

export default RecipientListItem;
