import getAngularModule from 'App/babel/angularHelpers/getAngularModule';

function generate() {
	return {
		inactive: true,
		comparisonType: 'Equals',
		value: []
	};
}

function isInactive(filter) {
	return filter.value.length === 0;
}

function toUrl(filter) {
	return { v: filter.value, c: filter.comparisonType };
}

function fromUrl(rawFilter) {
	const filter = generate();
	filter.value = rawFilter.v;
	filter.comparisonType = rawFilter.c;
	filter.inactive = rawFilter.hasOwnProperty('i') ? rawFilter.i : isInactive(filter);
	return filter;
}

function build(filter, rb) {
	const value = filter.value;

	if (filter.inactive || !value || !value.length) {
		return;
	}

	const comparisonType = rb.comparisonTypes[filter.comparisonType] || rb.comparisonTypes.Equals;

	rb.addFilter({ field: 'companyForm' }, comparisonType, value);
}

const filter = {
	filterName: 'CompanyType',
	type: 'raw',
	displayType: 'list',
	title: 'soliditet.corporateForm',
	entity: 'account',
	showOnSegment: true,
	generate,
	toUrl,
	fromUrl,
	isInactive,
	build,
	dataPromise: () => {
		const AppService = getAngularModule('AppService');
		return AppService.getStaticValuesPromise('companyForm').then(res => {
			const filterIds = ['housing_company', 'county_unit', 'primary_municipality'];
			const filtered = res.filter(item => !filterIds.includes(item.id));
			return { data: filtered, metadata: { total: filtered.length } };
		});
	}
};

export default filter;
