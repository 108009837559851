import React, { useCallback, useEffect, useMemo, useState } from 'react';
import BemClass from '@upsales/components/Utils/bemClass';
import './MentionsInput.scss';
import { useSelector } from 'react-redux';
import { RootState } from 'Store/index';
import { EditorState, ContentState, convertToRaw, RawDraftContentState, convertFromRaw } from 'draft-js';
import PluginEditor from '@draft-js-plugins/editor';
import createMentionPlugin, { defaultSuggestionsFilter, MentionData } from '@draft-js-plugins/mention';
import { draftToMarkdown } from 'markdown-draft-js';
import { type BasicUserWithPermissions } from 'App/resources/Model/User';

type MentionItem = { data: { mention: any } };
interface Props {
	onChange: (val: string) => void;
	value: string;
	editorRef?: (r: PluginEditor | null) => void;
	editorProps?: Partial<React.ComponentProps<typeof PluginEditor>>;
	className?: string;
	onEnter?: Function;
}

const convertToSaveFormat = (editorState: EditorState) => {
	const raw = convertToRaw(editorState?.getCurrentContent());

	// text will look like @__user$__user.id{$_user.name_$}
	let text = draftToMarkdown(raw, {
		entityItems: {
			mention: {
				open: function (item) {
					const tagData = item as MentionItem;
					if (tagData.data?.mention.tag) return `${tagData.data.mention.tag}{$_`;
					else return '$_';
				},
				close: function () {
					return '_$}';
				}
			}
		}
	});

	//replace everything within {$_ _$} to get save format
	text = text.replace(/{\$_.*?_\$}/g, '');
	return text;
};

const formatToUpsalesMention = (entity: MentionData) => {
	return { ...entity, tag: `@__user$${entity.id}__` };
};

type SuggestionState = { user: MentionData[] };
export const getEditorStateFromValue = (value: string, users: BasicUserWithPermissions[]) => {
	if (!value) {
		return EditorState.createEmpty();
	}

	const tagRegex = /@__(?<entity>user)\$(?<id>\d+)__/g;
	// get mentions from initial value
	//TODO fetch entities from found mentions. First iteration just users so no need to fetch
	const foundMentions = [...Array.from(value.matchAll(tagRegex))];

	// add user data to array. TODO should support other entities
	const entityMap = foundMentions.reduce((res: RawDraftContentState['entityMap'], m, index) => {
		if (m?.groups?.id) {
			// only users for now, entitytype exist on m.groups.entity
			const entityData = users.find(usr => usr.id === parseInt(m.groups?.id as string));
			if (entityData) {
				res[index] = {
					type: 'mention',
					mutability: 'IMMUTABLE',
					data: { mention: formatToUpsalesMention(entityData) }
				};
			}
		}

		return res;
	}, {});

	const rawContent = convertToRaw(ContentState.createFromText(value));
	rawContent.entityMap = entityMap;

	rawContent.blocks = rawContent.blocks.map(block => {
		let text = block.text;
		//
		//replace tag values with entity text
		const ranges = [];
		for (let index = 0; index < foundMentions.length; index++) {
			const mention = foundMentions[index];
			const tagIdx = text.indexOf(mention[0]);
			if (tagIdx !== -1) {
				const user = users.find(u => u.id === parseInt(mention?.groups?.id as string));

				text = text.replace(mention[0], user?.name ?? '');
				ranges.push({ offset: tagIdx, key: index, length: user?.name.length ?? 0 });
			}
		}

		return { ...block, text, entityRanges: ranges };
	});

	return EditorState.createWithContent(convertFromRaw(rawContent));
};

// only supports users for now
const MentionsInput = ({ value, onChange, editorRef, editorProps, className = '', onEnter }: Props) => {
	const classes = new BemClass('MentionsInput', className);
	const users = useSelector((state: RootState) => state.App.userMap.active);
	const activeUsers = useMemo(() => users.filter(user => user.active && !user.ghost), [users]);
	const [open, setOpen] = useState(false);
	const [editorState, setEditorState] = useState(() => getEditorStateFromValue(value, users));
	const [suggestions, setSuggestions] = useState<SuggestionState>({
		user: activeUsers.map(formatToUpsalesMention)
	});
	const ref = React.useRef<PluginEditor | null>(null);

	// if parent sets comment to '' it should empty the editor state as well. Need to come up with some solution to be able to set to whatever value you like from parent
	useEffect(() => {
		if (!value && editorState.getCurrentContent().getPlainText()) {
			setEditorState(EditorState.push(editorState, ContentState.createFromText(''), 'remove-range'));
		}
	}, [value]);
	const { MentionSuggestions, mentionPlugin } = useMemo(() => {
		const mentionPlugin = createMentionPlugin({
			entityMutability: 'IMMUTABLE',
			supportWhitespace: true,
			mentionTrigger: '@',
			theme: {
				mention: classes.elem('mention').b(),
				mentionSuggestions: classes.elem('mention-suggestions').b(),
				mentionSuggestionsEntry: classes.elem('mention-suggestions-entry').b(),
				mentionSuggestionsEntryAvatar: classes.elem('mention-suggestions-entry-avatar').b(),
				mentionSuggestionsEntryFocused: classes.elem('mention-suggestions-entry-focused').b(),
				mentionSuggestionsEntryText: classes.elem('mention-suggestions-entry-text').b()
			}
		});
		const { MentionSuggestions } = mentionPlugin;
		return { MentionSuggestions, mentionPlugin };
	}, []);
	const onOpenChange = useCallback((_open: boolean) => {
		setOpen(_open);
	}, []);
	const onSearchChange = useCallback(({ value }: { value: string }) => {
		setSuggestions({ user: defaultSuggestionsFilter(value, suggestions.user) });
	}, []);
	return (
		<div className={classes.b()}>
			<PluginEditor
				editorState={editorState}
				onChange={state => {
					onChange(convertToSaveFormat(state));
					setEditorState(state);
				}}
				plugins={[mentionPlugin]}
				ref={r => {
					editorRef?.(r);
					ref.current = r;
				}}
				stripPastedStyles
				keyBindingFn={e => {
					// Disable starting with a new line because it makes everything awkward
					if (e.key === 'Enter' && e.shiftKey && value === '') {
						return '';
					}

					if (e.key === 'Enter' && !e.shiftKey) {
						onEnter?.();
						// need to return some string or it won't override default behaviour
						return '';
					}
				}}
				{...editorProps}
			/>
			<MentionSuggestions
				open={open}
				onOpenChange={onOpenChange}
				suggestions={suggestions.user}
				onSearchChange={onSearchChange}
			/>
		</div>
	);
};

export default MentionsInput;
