import React from 'react';
import PlanPhonecallsDrawer, { Properties } from '../PlanPhonecallsDrawer';
import logError from 'App/babel/helpers/logError';
import { makeCancelable } from 'App/babel/helpers/promise';
import getAngularModule from 'App/babel/angularHelpers/getAngularModule';
import T from 'Components/Helpers/translate';
import RequestBuilder, { comparisonTypes } from 'Resources/RequestBuilder';

const numberFilter = (num: number) => Tools.$filter('number')(num);

const createActivities = (
	entity: string,
	numSelected: number,
	properties: Properties,
	filters: object,
	extraParams = {}
) => {
	const MultiActions = getAngularModule('MultiActions');
	const customerId = Tools.AppService.getCustomerId();
	return makeCancelable(
		MultiActions.customer(customerId).createActivity(entity, numSelected, properties, filters, '', extraParams)
	);
};

type Props = {
	numSelected: number;
	filters: RequestBuilder;
	entity: string;
	selected: number[];
	extraParams?: object;
};

class PlanPhonecallsMulti extends React.PureComponent<Props> {
	save = (properties: Properties) => {
		const { filters, entity, numSelected, selected, extraParams } = this.props;

		if (selected && selected.length) {
			filters.addFilter({ field: 'id' }, comparisonTypes.Equals, selected);
		}

		const savePromise = createActivities(
			entity,
			(selected && selected.length) || numSelected,
			properties,
			filters.build(),
			extraParams
		);

		return savePromise.promise.catch((err: Error) => logError(err, 'Failed to create phonecalls'));
	};

	render() {
		// eslint-disable-next-line no-unused-vars
		const { filters, entity, numSelected, selected, ...cleanProps } = this.props;
		return (
			<PlanPhonecallsDrawer
				{...cleanProps}
				onSave={this.save}
				saveBtnLabel={T('todo.planNumPhonecalls', { num: numberFilter(numSelected) })}
			/>
		);
	}
}

export default PlanPhonecallsMulti;
