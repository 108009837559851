import { useTranslation } from 'Components/Helpers/translate';
import { useEditTicketContext } from '../../Context/Context';
import { SlideFade } from '@upsales/components/animations';
import BemClass from '@upsales/components/Utils/bemClass';
import { useAnimationData } from 'App/components/hooks';
import { Headline, Text } from '@upsales/components';
import React, { useRef } from 'react';
import Lottie from 'react-lottie';

import './ClosedTicketScreen.scss';

const ClosedTicketScreen = () => {
	const { animationData, loading } = useAnimationData(() => import('./check.json'));
	const { t } = useTranslation();

	const {
		state: { showClosedScreen }
	} = useEditTicketContext();

	const classes = new BemClass('ClosedTicketScreen');
	const lottieRef = useRef<Lottie>(null);
	const defaultOptions = {
		loop: false,
		animationData,
		autoplay: false,
		rendererSettings: {
			preserveAspectRatio: 'xMidYMid slice',
			className: classes.elem('content').elem('lottie').b()
		}
	};

	return (
		<SlideFade visible={showClosedScreen} speed="slow">
			<div className={classes.elem('content').b()}>
				{!loading && showClosedScreen ? (
					<Lottie ref={lottieRef} options={defaultOptions} height={150} width={150} />
				) : null}

				<Headline color="success-5" space="mbm">
					{t('ticket.ticketClosed')}
				</Headline>

				<Text color="success-5" space="mbm">
					{t('ticket.goodJob')}
				</Text>
			</div>
		</SlideFade>
	);
};

export default ClosedTicketScreen;
