import BemClass from '@upsales/components/Utils/bemClass';
import React from 'react';
import Links from 'App/resources/Model/Links';

type Props = {
	quickLinks: Links[];
	className?: string;
};

const QuickLinks: React.FC<Props> = ({ quickLinks, className }) => {
	const classes = new BemClass(className || 'QuickLinks');
	return (
		<>
			{quickLinks.map((quickLink: Links) => {
				return (
					<a
						className={classes.b()}
						key={quickLink.id}
						href={quickLink.href}
						target={quickLink.target || '_blank'}
					>
						{quickLink.icon && quickLink.icon.startsWith('fa') ? (
							<b
								className={`icon fa ${quickLink.icon} custom-icon`}
								style={{ color: quickLink.color ? `#${quickLink.color}` : undefined }}
							/>
						) : null}
						{quickLink.icon && !quickLink.icon.startsWith('fa') ? (
							<img src={quickLink.icon} alt={quickLink.value} className={classes.elem('icon').b()} />
						) : null}
						<span className={classes.elem('label').b()}>{quickLink.value}</span>
					</a>
				);
			})}
		</>
	);
};

export default QuickLinks;
