import { Table, TableColumn, TableRow, Text } from '@upsales/components';
import React from 'react';

type NoResultsProps = {
	text: string;
};
const NoResults = ({ text }: NoResultsProps) => (
	<Table>
		<TableRow>
			<TableColumn>
				<Text italic>{text}</Text>
			</TableColumn>
		</TableRow>
	</Table>
);

export default NoResults;
