import React from 'react';
import Client from 'App/resources/Model/Client';
import SalesHistory from 'App/components/SalesHistory';

export default function HistoryColumn({ account }: { account: Client }) {
	return (
		<td className="DataSourceColumn history">
			<SalesHistory entity={account} entityName={'client'} />
		</td>
	);
}
