import React from 'react';
import config from 'App/babel/config';
import BemClass from '@upsales/components/Utils/bemClass';
import { Attachment } from 'App/resources/Model/Mail';
import { truncateFilename, getIconNameFromFilename, formatFilesize } from 'Components/Helpers/FileUtils';
import { Card, Tooltip, Column, Row, Icon, Text } from '@upsales/components';
import { AttachmentWithSize } from 'App/resources/Model/Mail';

import './MailAttachment.scss';

type UploadLater = { size: number; type: string; filename: string; id: number };

type Props = {
	attachment: AttachmentWithSize | UploadLater;
	clickable?: boolean;
	onRemove?: (attachment: Attachment | UploadLater) => void;
};

const onClick = (attachment: AttachmentWithSize | UploadLater) => {
	const isFile = (attachment: AttachmentWithSize | UploadLater): attachment is UploadLater =>
		!!(attachment as UploadLater).id;
	if (isFile(attachment)) {
		window.open(config.URL + config.API + 'resources/download/internal/' + attachment.id + '?inline=true');
		return;
	}

	if (['PdfTemplate', 'Template'].includes(attachment.type) && attachment.url) {
		window.open(attachment.url);
	} else {
		window.open(config.URL + config.API + 'resources/download/internal/' + attachment.value + '?inline=true');
	}
};

const MailAttachment = ({ attachment, onRemove, clickable = true }: Props) => {
	const classes = new BemClass('MailAttachment');

	return (
		<div className={classes.b()}>
			<Tooltip title={attachment.filename} position="top">
				<Card
					className={classes.elem('card').mod({ clickable }).b()}
					onClick={e => clickable && onClick(attachment)}
				>
					<Icon name={getIconNameFromFilename(attachment.filename)}></Icon>
					<Column>
						<Row>{truncateFilename(attachment.filename, onRemove ? 18 : 22)}</Row>
						<Row>
							{attachment.size ? <Text color="grey-10">{formatFilesize(attachment.size)}</Text> : null}
						</Row>
					</Column>
					{onRemove ? (
						<Icon
							space="mrs"
							name="times"
							onClick={e => {
								e?.stopPropagation();
								onRemove(attachment);
							}}
						/>
					) : null}
				</Card>
			</Tooltip>
		</div>
	);
};

export default MailAttachment;
