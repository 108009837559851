import bemClass from '@upsales/components/Utils/bemClass';
import React, { ComponentProps, useState, useEffect } from 'react';
import { Block, Icon, Title, Text, Loader } from '@upsales/components';
import T from 'Components/Helpers/translate';
import { countParticipants } from 'App/services/SegmentService';
import { CancelablePromise, makeCancelable } from 'App/babel/helpers/promise';
import logError from 'App/babel/helpers/logError';
import { SegmentFilter } from 'App/resources/Model/Segment';

import './CreateTemplateFlowParticipants.scss';

type BlockProps = ComponentProps<typeof Block>;
type Props = { filters: SegmentFilter[] } & BlockProps;

export default ({ filters, className, ...blockProps }: Props) => {
	const classes = new bemClass('CreateTemplateFlowParticipants', className);

	const [participants, setParticipants] = useState({ contacts: 0, clients: 0 });
	const [loading, setLoading] = useState(false);
	const reqRef = React.useRef<CancelablePromise<{ contacts: number; clients: number }> | null>(null);

	useEffect(() => {
		// If we have a promise cancel it before starting a new one so we don't get a race condition
		reqRef.current?.cancel();

		setLoading(true);
		reqRef.current = makeCancelable(countParticipants(filters));

		reqRef.current.promise
			.then(res => {
				setParticipants(res);
				setLoading(false);
			})
			.catch(err => logError(err, 'CreateTemplateFlowParticipants: getParticipantsCount'));

		return () => reqRef.current?.cancel();
	}, [filters]);

	return (
		<Block className={classes.b()} {...blockProps}>
			{loading ? (
				<>
					<Loader size="xs" noU={true} />
					<Text>{T('createTemplateFlowParticipants.loading')}</Text>
				</>
			) : (
				<>
					<Title>
						<Icon name="users" space="mrl" />
						{T('createTemplateFlowParticipants.participants', participants)}
					</Title>
					<Block
						space="mtxl ptxl prxl pbxl plxl"
						backgroundColor="super-light-blue"
						borderColor="bright-blue"
						className={classes.elem('info').b()}
					>
						<Text size="lg" color="medium-blue">
							<Icon name="info-circle" space="mrl" />
							{T('createTemplateFlowParticipants.info')}
						</Text>
					</Block>
				</>
			)}
		</Block>
	);
};
