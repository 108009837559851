import { Avatar, Tooltip } from '@upsales/components';
import BemClass from '@upsales/components/Utils/bemClass';
import { Esign } from 'App/resources/Model/Esign';
import _ from 'lodash';
import moment from 'moment';
import React from 'react';
import './EsignInvolvees.scss';

type OverEightSingularType = OverEightProps & {
	type: string;
};
const OverEightSingular = ({ type, involved, classes }: OverEightSingularType) => {
	return (
		<Tooltip
			title={`${involved.length} parties ${
				type === 'waiting' ? 'have not responded' : `have ${type}`
			}: \n\n${involved.map(involvee => involvee.fstname + ' ' + involvee.sndname).join(', ')}`}
			distance={20}
		>
			<Avatar
				size="sm"
				className={classes
					.elem('involvee')
					.mod({ signed: type === 'signed', declined: type === 'declined', notClickable: true })
					.b()}
				initials={`${involved.length}`}
				onClick={e => e.stopPropagation()}
			/>
		</Tooltip>
	);
};

type OverEightProps = Pick<Esign, 'involved'> & { classes: BemClass };
const OverEight = ({ involved, classes }: OverEightProps) => {
	const signed: Esign['involved'] = [];
	const declined: Esign['involved'] = [];
	const waiting: Esign['involved'] = [];

	involved.forEach(involvee => {
		if (involvee.sign) {
			signed.push(involvee);
		} else if (involvee.declineDate) {
			declined.push(involvee);
		} else {
			waiting.push(involvee);
		}
	});

	return (
		<>
			{signed.length ? <OverEightSingular involved={signed} type="signed" classes={classes} /> : null}
			{declined.length ? <OverEightSingular involved={declined} type="declined" classes={classes} /> : null}
			{waiting.length ? <OverEightSingular involved={waiting} type="waiting" classes={classes} /> : null}
		</>
	);
};

type Props = Pick<Esign, 'involved'>;
const EsignInvolvees = ({ involved }: Props) => {
	const future = moment().add(1, 'year').toDate();
	const sortedInvolvees = _.chain(involved)
		.sortBy(involvee => {
			if (!involvee.sign) {
				return future;
			}
			return involvee.sign;
		})
		.sortBy(involvee => {
			return involvee.userId ? 0 : 1;
		})
		.value();

	const onClick = (e: React.MouseEvent<HTMLDivElement, MouseEvent>, id: number | null) => {
		if (id) {
			e.stopPropagation();
			Tools.$state.go('contact.dashboard', { id });
		}
	};

	const classes = new BemClass('EsignInvolvees');
	return (
		<div className={classes.b()}>
			{sortedInvolvees.length < 8 ? (
				sortedInvolvees.map(involvee => {
					return (
						<Tooltip key={involvee.id} title={`${involvee.fstname} ${involvee.sndname}`} distance={20}>
							<Avatar
								size="sm"
								className={classes
									.elem('involvee')
									.mod({
										notClickable: !involvee.contactId,
										signed: !!involvee.sign,
										declined: !!involvee.declineDate
									})
									.b()}
								initials={involvee.fstname + ' ' + involvee.sndname}
								onClick={e => onClick(e, involvee.contactId)}
							/>
						</Tooltip>
					);
				})
			) : (
				<OverEight involved={sortedInvolvees} classes={classes} />
			)}
		</div>
	);
};

export default EsignInvolvees;
