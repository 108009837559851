import Attribute from './Attribute';
import OrderRowAttributes from './OrderRow';

const orderRow = new Attribute({
	title: 'default.orderRow',
	field: 'orderRow.id',
	type: Attribute.types.Array,
	attr: OrderRowAttributes
});

const project = new Attribute({
	title: 'default.campaigns',
	field: 'project.id',
	type: Attribute.types.Object,
	attr: {
		id: {
			field: 'project.id',
			type: Attribute.types.Number
		},
		name: {
			field: 'project.name',
			type: Attribute.types.String
		}
	}
});

const user = new Attribute({
	title: 'default.user',
	field: 'user.id',
	type: Attribute.types.Object,
	attr: {
		id: {
			field: 'user.id',
			type: Attribute.types.Number
		},
		name: {
			field: 'user.name',
			type: Attribute.types.String
		}
	}
});

const contact = new Attribute({
	title: 'default.contact',
	field: 'contact.id',
	type: Attribute.types.Object,
	attr: {
		id: {
			field: 'contact.id',
			type: Attribute.types.Number
		},
		name: {
			field: 'contact.name',
			type: Attribute.types.String
		}
	}
});

const account = new Attribute({
	title: 'default.account',
	field: 'client.id',
	type: Attribute.types.Object,
	attr: {
		id: {
			field: 'client.id',
			type: Attribute.types.Number
		},
		name: {
			field: 'client.name',
			type: Attribute.types.String
		}
	}
});

const stage = new Attribute({
	title: 'default.stage',
	field: 'stage.id',
	type: Attribute.types.Object,
	attr: {
		id: {
			field: 'stage.id',
			type: Attribute.types.Number
		},
		name: {
			field: 'stage.name',
			type: Attribute.types.String
		}
	}
});

const clientConnection = new Attribute({
	title: 'default.clientConnection',
	field: 'clientConnection.id',
	type: Attribute.types.Object,
	attr: {
		id: {
			field: 'clientConnection.id',
			type: Attribute.types.Number
		},
		name: {
			field: 'clientConnection.name',
			type: Attribute.types.String
		}
	}
});

const metadata = new Attribute({
	title: '',
	field: 'metadata',
	type: Attribute.types.Object,
	attr: {
		agreementStartdate: {
			field: 'metadata.agreementStartdate',
			type: Attribute.types.Date
		},
		agreementEnddate: {
			field: 'metadata.agreementEnddate',
			type: Attribute.types.Date
		},
		agreementInvoiceStartdate: {
			field: 'metadata.agreementInvoiceStartdate',
			type: Attribute.types.Date
		},
		agreementNextOrderDate: {
			field: 'metadata.agreementNextOrderDate',
			type: Attribute.types.Date
		},
		agreementRenewalDate: {
			field: 'metadata.agreementRenewalDate',
			type: Attribute.types.Date
		},
		agreementIntervalPeriod: {
			field: 'metadata.agreementIntervalPeriod',
			type: Attribute.types.Number
		},
		agreementNotes: {
			field: 'metadata.agreementNotes',
			type: Attribute.types.String
		},
		agreementOrderCreationTime: {
			field: 'metadata.agreementOrderCreationTime',
			type: Attribute.types.Date
		},
		periodLength: {
			field: 'metadata.periodLength',
			type: Attribute.types.Number
		}
	}
});

const attributes = {
	id: new Attribute({
		title: 'default.id',
		field: 'id',
		type: Attribute.types.Number
	}),
	description: new Attribute({
		title: 'default.description',
		field: 'metadata.agreementDescription',
		type: Attribute.types.String
	}),
	intervalPeriod: new Attribute({
		title: 'agreement.intervalPeriod',
		field: 'metadata.agreementIntervalPeriod',
		type: Attribute.types.String
	}),
	periodLength: new Attribute({
		title: 'agreement.periodLength',
		field: 'metadata.periodLength',
		type: Attribute.types.String
	}),
	value: new Attribute({
		title: 'default.value',
		field: 'value',
		type: Attribute.types.String
	}),
	contributionMargin: new Attribute({
		title: 'default.contributionMargin',
		field: 'contributionMargin',
		type: Attribute.types.Number
	}),
	contributionMarginInAgreementCurrency: new Attribute({
		title: 'default.contributionMarginInAgreementCurrency',
		field: 'contributionMarginInAgreementCurrency',
		type: Attribute.types.Number
	}),
	yearlyValue: new Attribute({
		title: 'default.yearlyValue',
		field: 'yearlyValue',
		type: Attribute.types.String
	}),
	yearlyValueInMasterCurrency: new Attribute({
		title: 'default.yearlyValueInMasterCurrency',
		field: 'yearlyValueInMasterCurrency',
		type: Attribute.types.Number
	}),
	yearlyValueInRoleCurrency: new Attribute({
		title: 'default.yearlyValueInRoleCurrency',
		field: 'yearlyValueInRoleCurrency',
		type: Attribute.types.Number
	}),
	yearlyContributionMargin: new Attribute({
		title: 'default.yearlyContributionMargin',
		field: 'yearlyContributionMargin',
		type: Attribute.types.String
	}),
	yearlyContributionMarginInAgreementCurrency: new Attribute({
		title: 'default.yearlyContributionMarginInAgreementCurrency',
		field: 'yearlyContributionMarginInAgreementCurrency',
		type: Attribute.types.String
	}),
	project,
	startDate: new Attribute({
		title: 'default.startDate',
		field: 'metadata.agreementStartdate',
		type: Attribute.types.Date
	}),
	endDate: new Attribute({
		title: 'default.endDate',
		field: 'metadata.agreementEnddate',
		type: Attribute.types.Date
	}),
	invoiceStartDate: new Attribute({
		title: 'agreement.startDateCurrentPeriod',
		field: 'metadata.agreementInvoiceStartdate',
		type: Attribute.types.Date
	}),
	nextOrderDate: new Attribute({
		title: 'agreement.nextOrderDate',
		field: 'metadata.agreementNextOrderDate',
		type: Attribute.types.Date
	}),
	renewalDate: new Attribute({
		title: 'agreement.renewalDate',
		field: 'metadata.agreementRenewalDate',
		type: Attribute.types.Date
	}),
	user,
	contact,
	account,
	client: account,
	campaign: project,
	probability: new Attribute({
		title: 'default.probability',
		field: 'probability',
		type: Attribute.types.Number,
		filterName: 'probability',
		comparisonTypes: ['Equals', 'NotEquals']
	}),
	notes: new Attribute({
		field: 'notes',
		type: Attribute.types.String
	}),
	product: new Attribute({
		title: 'default.product',
		field: 'orderRow.product.id',
		type: Attribute.types.String
	}),
	orderRow,
	stage,
	currency: new Attribute({
		title: 'default.currency',
		field: 'currency',
		type: Attribute.types.String
	}),
	currencyRate: new Attribute({
		title: 'default.currencyRate',
		field: 'currencyRate',
		type: Attribute.types.Float
	}),
	clientConnection,
	metadata,
	journeyStep: new Attribute({
		title: 'default.journeyStep',
		field: 'client.journeyStep'
	}),
	clientJourneyStep: new Attribute({
		title: 'default.journeyStepClient',
		field: 'client.journeyStep'
	}),
	contactJourneyStep: new Attribute({
		title: 'default.journeyStepContact',
		field: 'contact.journeyStep'
	}),
	movedFrom: new Attribute({
		field: 'movedFrom'
	})
};

export default attributes;
