import React, { useState, useEffect, useRef } from 'react';
import ReportcenterFilter from './ReportcenterFilter';
import { Block, ButtonSelect } from '@upsales/components';
import bemClass from '@upsales/components/Utils/bemClass';
import T from 'Components/Helpers/translate';
import ReportcenterFilterRow from './ReportcenterFilterRow';
import { removeItem } from 'Store/helpers/array';
import { RCDashboardFilter } from 'Resources/ReportDashboard';
import Label from 'App/resources/Model/Label';
import LabelResource from 'App/resources/Label';
import logError from 'Helpers/logError';
import { makeCancelable, CancelablePromise } from 'App/babel/helpers/promise';
import './ReportcenterFilterLabel.scss';
import ReportcenterFilterSearchbar from './ReportcenterFilterSearchbar';
import RequestBuilder, { comparisonTypes } from 'Resources/RequestBuilder';
import { useSoftDeployAccess } from 'App/components/hooks';

interface Props {
	onChange: (type: keyof RCDashboardFilter, values: number[], comparison: string) => void;
	values: RCDashboardFilter;
	updateFilterHeight: () => void;
	families: string[];
}

export const renderSelected = (values: RCDashboardFilter) => {
	let res = `${T('file.labels')}: `;
	const formLength = values?.LabelForm?.value?.length;
	const mailCampaignLength = values?.LabelMailCampaign?.value?.length;

	if (formLength || mailCampaignLength) {
		const length = (formLength || 0) + (mailCampaignLength || 0);
		if (length >= 1) {
			res += `${length} ${(values?.LabelForm?.comparison === 'ne' &&
			values?.LabelMailCampaign?.comparison === 'ne'
				? T('default.excluded')
				: T('default.selected', { count: length })
			).toLowerCase()}`;
		}
	}

	return res;
};

const filterToEntityType = {
	LabelForm: 'Form',
	LabelMailCampaign: 'MailCampaign'
} satisfies { [k in keyof RCDashboardFilter]: Label['entity'] };

const entityTypeToFilter = {
	Form: 'LabelForm',
	MailCampaign: 'LabelMailCampaign'
} satisfies Partial<{ [k in Label['entity']]: keyof RCDashboardFilter }>;

export const mapLabelEntityTypes = (filters: string[]) => {
	const entityTypes: Label['entity'][] = [];
	filters.forEach(key => {
		const entityType = filterToEntityType[key as keyof typeof filterToEntityType];
		if (entityType !== undefined && entityTypes.indexOf(entityType) === -1) {
			entityTypes.push(entityType);
		}
	});
	return entityTypes;
};

const getLabelButtons = (formsAmount: number, mailCampaignAmount: number) => {
	const formTitle = `${formsAmount || ''} ${T(formsAmount < 2 ? 'form.form' : 'form.forms')}`;
	const mailCampaignTitle = `${mailCampaignAmount || ''} ${T(
		mailCampaignAmount < 2 ? 'default.mailinglist' : 'default.mailinglists'
	)}`;
	return [
		{
			value: entityTypeToFilter.Form,
			title: formTitle
		},
		{
			value: entityTypeToFilter.MailCampaign,
			title: mailCampaignTitle
		}
	];
};

const ReportcenterFilterLabel = ({ onChange, families, ...props }: Props) => {
	const classes = new bemClass('ReportcenterFilterLabel');
	const [searchStr, setSearchStr] = useState('');
	const [selectedList, setSelectedList] = useState<'LabelForm' | 'LabelMailCampaign'>('LabelForm');
	const [formLabels, setFormLabels] = useState<Label[]>([]);
	const [mailCampaignLabels, setMailCampaignLabels] = useState<Label[]>([]);

	const timeoutRef = useRef<number>();
	const dataPromise = useRef<CancelablePromise>();
	const hasMailCampaignLabels = useSoftDeployAccess('MAIL_CAMPAIGN_LABELS');
	const entityTypes = mapLabelEntityTypes(Object.keys(props.values)).filter(
		entityType => entityType !== filterToEntityType.LabelMailCampaign || hasMailCampaignLabels
	);
	useEffect(() => {
		clearTimeout(timeoutRef.current);
		timeoutRef.current = window.setTimeout(() => {
			dataPromise.current?.cancel();
			const requestBuilder = new RequestBuilder();
			requestBuilder.addSort('name', true);
			requestBuilder.addFilter({ field: 'name' }, comparisonTypes.Search, searchStr);
			requestBuilder.addFilter({ field: 'entity' }, comparisonTypes.Equals, entityTypes);
			dataPromise.current = makeCancelable(LabelResource.find(requestBuilder.build()));
			dataPromise.current.promise
				.then(res => {
					const formLabel: Label[] = [];
					const mailCampaignLabel: Label[] = [];
					res.data.forEach((label: Label) => {
						if (label.entity === filterToEntityType.LabelForm) {
							formLabel.push(label);
						} else if (label.entity === filterToEntityType.LabelMailCampaign) {
							mailCampaignLabel.push(label);
						}
					});
					setFormLabels(formLabel);
					setMailCampaignLabels(mailCampaignLabel);
				})
				.catch(err => logError(err, 'Could not find labels'));
		}, 300);
		return () => {
			clearTimeout(timeoutRef.current);
			dataPromise.current?.cancel();
		};
	}, [searchStr, props.values]);

	useEffect(() => {
		if (!Object.keys(props.values).includes('LabelForm')) {
			setSelectedList('LabelMailCampaign');
		} else {
			setSelectedList('LabelForm');
		}
	}, [Object.keys(props.values).includes('LabelForm'), Object.keys(props.values).includes('LabelMailCampaign')]);

	const values = props.values?.[selectedList]?.value || [];
	const comparison = props.values?.[selectedList]?.comparison ?? 'eq';
	const isExclude = comparison === 'ne';

	const labels = selectedList === entityTypeToFilter.Form ? formLabels : mailCampaignLabels;
	const filteredLabels = labels.filter(item => item.name.toLowerCase().indexOf(searchStr.toLowerCase()) !== -1);

	const formSelected = props.values?.['LabelForm']?.value || [];
	const mailCampaignSelected = props.values?.['LabelMailCampaign']?.value || [];
	const allSelected = [...formSelected, ...mailCampaignSelected];
	const allLabels = [...formLabels, ...mailCampaignLabels];

	const resetFilters = () => {
		onChange('LabelForm', [], 'eq');
		onChange('LabelMailCampaign', [], 'eq');
	};

	return entityTypes.includes(filterToEntityType.LabelForm) ||
		entityTypes.includes(filterToEntityType.LabelMailCampaign) ? (
		<ReportcenterFilter
			className={classes.b()}
			renderSelected={() => renderSelected(props.values)}
			getSelectedNames={() => allLabels.filter(s => s.id && allSelected.includes(s.id)).map(s => s.name)}
			icon="tags"
			placeholder={`${T('file.labels')}: ${T('reportcenter.filter.notActive')}`}
			value={[...formSelected, ...mailCampaignSelected]}
			resetFilter={() => resetFilters()}
			{...props}
		>
			<Block space="ptm prm pbm plm" backgroundColor="white" className={classes.elem('list-select').b()}>
				{entityTypes.length === 2 ? (
					<Block space="ptm prm plm">
						<ButtonSelect
							options={getLabelButtons(formSelected.length, mailCampaignSelected.length)}
							value={selectedList}
							onChange={tab => {
								setSearchStr('');
								setSelectedList(tab);
							}}
						/>
					</Block>
				) : null}
				<Block space="mtm">
					<ReportcenterFilterSearchbar<number[]>
						setSearchStr={value => setSearchStr(value)}
						searchStr={searchStr}
						placeholder={`${T('default.search')} ${T('file.labels').toLowerCase()}`}
						isExclude={isExclude}
						onChange={() => {
							const compare = isExclude ? 'eq' : 'ne';
							onChange(selectedList, props.values?.[selectedList]?.value || [], compare);
						}}
						field="Label"
						value={values}
					/>
				</Block>
			</Block>
			<ReportcenterFilterRow
				key={0}
				onClick={() => {
					resetFilters();
				}}
				selected={!values.length}
				title={T('filters.noFilter')}
			/>
			{filteredLabels.map(row =>
				row.id ? (
					<ReportcenterFilterRow
						key={row.id}
						onClick={() => {
							if (row.id && row.entity) {
								const mapFilter = entityTypeToFilter as MaybeObj<typeof entityTypeToFilter>;
								const filterKey = mapFilter[row.entity];
								if (!filterKey) {
									return;
								}

								const filter = props.values[filterKey];
								if (filter !== undefined) {
									const i = filter.value.indexOf(row.id);
									onChange(
										filterKey,
										i !== -1 ? removeItem(filter.value, i) : [...filter.value, row.id],
										comparison
									);
								}
							}
						}}
						selected={values.indexOf(row.id) !== -1}
						title={row.name}
					/>
				) : null
			)}
		</ReportcenterFilter>
	) : null;
};

export default ReportcenterFilterLabel;
