enum ClientPlanType {
	GROW = 'Grow',
	KEEP = 'Keep',
	RISK = 'Risk'
}

export enum PlanStatus {
	COMPLETED = 'COMPLETED',
	NOT_COMPLETED = 'NOT_COMPLETED'
}

export const ClientPlanTypeInfo = {
	[ClientPlanType.GROW]: {
		icon: 'arrow-up' as const,
		title: 'accountPlan.growth',
		chipType: 'info' as const
	},
	[ClientPlanType.KEEP]: {
		icon: 'thumbs-up' as const,
		title: 'accountPlan.keep',
		chipType: 'success' as const
	},
	[ClientPlanType.RISK]: {
		icon: 'warning' as const,
		title: 'accountPlan.risk',
		chipType: 'danger' as const
	}
};

export default ClientPlanType;
