import Client, { RawClient } from '../Model/Client';
import { parseCustom } from './helpers';
import Category, { CategoryType } from '../Model/Category';
import _ from 'lodash';

export const parseCategories = (
	obj: {
		categories: Category[];
		$standardCategories?: Category[];
		$extraCategories?: { [k: Category['id']]: Category[] };
	},
	categories: Category[],
	categoryTypes?: CategoryType[]
) => {
	if (!obj || !Array.isArray(obj.categories)) {
		return;
	}

	obj.$standardCategories = obj.categories;
	if (categoryTypes && categoryTypes.length) {
		obj.$extraCategories = {};

		_.forEach(categoryTypes, function (categoryType) {
			obj.$extraCategories![categoryType.id] = [];

			// Options for this category type
			_.forEach(obj.categories, function (category) {
				const found: any = _.find(categories, { id: category.id });
				if (found && (found.categoryType === categoryType.id || found.categoryTypeId === categoryType.id)) {
					obj.$extraCategories![categoryType.id].push(category);
				}
			});

			// Remove this category type from arrays
			obj.$standardCategories = _.difference(obj.$standardCategories!, obj.$extraCategories![categoryType.id]);
		});
	}
};

const parseUsers = (client: RawClient) => {
	const allFullUsers = Tools.AppService.getUsers();
	const clientFullUsers = allFullUsers.filter(fullUser =>
		client.users.map(partialUser => partialUser.id).includes(fullUser.id)
	);
	client.users = clientFullUsers.sort((a, b) => a.id - b.id);
};

const parseClient = (client: RawClient): Client => {
	const self = Tools.AppService.getSelf();
	let accountOwner = false;
	let categories = null;
	let categoryTypes = null;
	(client?.users ?? []).forEach(user => {
		if (user.id === self.id) {
			accountOwner = true;
		}
	});

	const createRights: { [key: string]: boolean } = {};
	Object.keys(self.createRights).forEach(type => {
		const value = (self.createRights as { [key: string]: string })[type];
		createRights[type] =
			value === 'ALL' ||
			(value === 'OWN' && accountOwner) ||
			(value === 'OWN' && ['Activity', 'Appointment', 'Opportunity', 'Order', 'Agreement'].includes(type));
	});

	(client as Client).createRights = createRights as Client['createRights'];

	if (client.custom) {
		(client as Client).$mappedCustom = parseCustom(client.custom, 'account');
	}
	if (!categoryTypes) {
		categoryTypes = Tools.AppService.getCategoryTypes('account');
	}
	if (!categories) {
		categories = Tools.AppService.getCategories('account');
	}

	parseCategories(client, categories, categoryTypes);
	parseUsers(client);

	client.$adCampaign = {};

	if (client.adCampaign && client.adCampaign.length) {
		for (var i = 0; i < client.adCampaign.length; i++) {
			client.$adCampaign[client.adCampaign[i].id] = client.adCampaign[i];
		}
	}

	return client as Client;
};

export default parseClient;
