import React, { Fragment } from 'react';
import { get } from 'lodash';
import { Text, Link } from '@upsales/components';
import TimelineRow from 'Components/TimelineRow';
import { ACTIVITY_TIMELINE } from 'App/babel/enum/timeline';

const ActivityTimelineChangedContact = ({ event, hideIcon }) => {
	const t = Tools.$translate;
	const lang = {
		reached: t('activity.outcomeLog.reached'),
		previousContact: t('activity.outcomeLog.previousContact'),
		changedContactTo: t('activity.outcomeLog.changedContactTo'),
		removedContact: t('activity.outcomeLog.removedContact') + ' ',
		addedContact: t('activity.outcomeLog.addedContact') + ' '
	};
	const { subType, data } = event || {};
	const user = get(event, 'user') || {};
	const date = get(event, 'date');
	const customerId = Tools.AppService.getCustomerId();
	const contact = get(data, 'contact') || {};
	const oldContact = get(data, 'oldContact');
	let iconName = 'user-plus';
	let iconColor;

	const title = (
		<Text>
			{subType === ACTIVITY_TIMELINE.REACHED_TALK_TO_SOMEONE ? (
				<Fragment>
					{lang.reached}
					{oldContact ? (
						<Link
							onClick={e => e.stopPropagation()}
							href={Tools.$state.href('contact.dashboard', {
								customerId: customerId,
								id: oldContact.id
							})}
						>
							{' ' + oldContact.name}
						</Link>
					) : null}
					<span dangerouslySetInnerHTML={{ __html: '&#58;' }} />{' '}
				</Fragment>
			) : null}

			{oldContact && contact.id ? lang.changedContactTo : contact.id ? lang.addedContact : lang.removedContact}
			{contact.id ? (
				<Link
					onClick={e => e.stopPropagation()}
					href={Tools.$state.href('contact.dashboard', {
						customerId: customerId,
						id: contact.id
					})}
				>
					{contact.name}
				</Link>
			) : null}
		</Text>
	);
	const subTitle = date ? (
		<Text size="sm" color="grey-11">
			{moment(date).format('L LT')} <span dangerouslySetInnerHTML={{ __html: '&#8226;' }} /> {user.name}
		</Text>
	) : null;
	const subSection = oldContact ? (
		<Text className="subSection" color="grey-10">
			{lang.previousContact} {oldContact.name}
		</Text>
	) : null;

	if (!contact.id) {
		iconName = 'user-times';
		iconColor = 'red';
	}
	if (subType === ACTIVITY_TIMELINE.REACHED_CHANGE_CONTACT && oldContact) {
		iconColor = 'green';
	}

	return (
		<TimelineRow
			icon={hideIcon ? undefined : iconName}
			iconColor={iconColor}
			event={event}
			title={title}
			subTitle={subTitle}
			subSection={subSection}
		/>
	);
};

export default ActivityTimelineChangedContact;
