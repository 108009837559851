import React from 'react';
import TableWidget from '../TableWidget';
import { Datatypes, ProgressSpecial, RCWidgetData } from 'Resources/ReportWidget';
import { GenericWidgetProps } from '../ReportcenterWidget';
import DistributionBar from '../Components/DistributionBar';

export type renderColumnProps = {
	value: string | number | ProgressSpecial;
	datatype: keyof typeof Datatypes;
	currency: string;
	colors?: RCWidgetData['colors'];
};

type Stage = {
	stageId: string;
	stageName: string;
	value: number;
	total: number;
	count: number;
};

const renderColumn = ({ value, datatype, currency, colors }: renderColumnProps) => {
	if (typeof value !== 'string' && typeof value !== 'number') {
		if (value?.type === 'stageDistribution') {
			const distribution = value?.data?.stages?.map((stage: Stage) => ({
				id: stage.stageId,
				label: stage.stageName,
				value: stage.total,
				count: stage.count,
				color: colors?.[stage.stageId]?.color
			}));

			return <DistributionBar distribution={distribution} datatype={datatype} currency={currency} />;
		}
	}
};

const PipelineSpecialWidget = ({ data, ...props }: GenericWidgetProps) => {
	return <TableWidget data={data} specialColumn={renderColumn} {...props} />;
};

export default PipelineSpecialWidget;
