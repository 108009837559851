import React from 'react';
import { Button, ModalHeader, ModalContent, ModalControls, FullscreenModal } from '@upsales/components';
import BemClass from '@upsales/components/Utils/bemClass';
import { markdown } from 'markdown';
import t from 'Components/Helpers/translate';

import './FullTermsModal.scss';

type Props = {
	terms: string;
	version: number;
	resolve: () => void;
	reject: () => void;
	price?: number;
	accept: boolean;
	className?: string;
	close?: (confirmed?: boolean) => void;
};

export default function FullTermsModal(props: Props) {
	const { terms, price, version, resolve, reject, accept, className, close } = props;
	const clientName = Tools.AppService.getAccountSelf().client.name;

	const defaultTerms =
		'This integration is developed, hosted and maintained by **' +
		clientName +
		'**. \n\nBy activating this integration ' +
		clientName +
		' will have access to your Upsales account. \n\nAny configuration the integration may have, including credentials such as API keys, usernames and passwords will also be available to ' +
		clientName +
		'. \n\nBy accepting these terms and conditions you confirm to have read, understood and accepted them.';

	const strTerms = terms?.length > 0 ? terms : defaultTerms;
	if (Tools.FeatureHelper.hasSoftDeployAccess('REACT_ALERT_MODAL')) {
		const classes = new BemClass('FullTermsModal', className);
		return (
			<FullscreenModal className={classes.b()}>
				<ModalHeader onClose={() => close?.()} />
				<ModalContent>
					<div className="html-wrapper">
						<div
							className="html-content"
							dangerouslySetInnerHTML={{
								__html: version > 1 || !(terms?.length > 0) ? markdown.toHTML(strTerms) : strTerms
							}}
						/>
						{price && (
							<div className="html-content-price">
								<h4>
									{t('default.price') + ': '}
									{Tools.$filter('currencyFormat')(price, 'SEK')}
								</h4>
							</div>
						)}
					</div>
				</ModalContent>
				<ModalControls>
					{accept ? (
						<>
							<Button onClick={() => close?.(true)} size="lg">
								{t('integration.acceptTerms')}
							</Button>
							<Button onClick={() => close?.()} size="lg" type="link">
								{t('default.abort')}
							</Button>
						</>
					) : (
						<>
							<Button onClick={() => close?.()}>{t('integration.acceptedTerms')}</Button>
							<Button onClick={() => close?.()} size="lg" type="link">
								{t('default.close')}
							</Button>
						</>
					)}
				</ModalControls>
			</FullscreenModal>
		);
	}

	return (
		<div>
			<div className="html-wrapper">
				<div
					className="html-content"
					dangerouslySetInnerHTML={{ __html: version > 1 ? markdown.toHTML(terms) : terms }}
				/>
				{price && (
					<div className="html-content-price">
						<h4>
							{t('default.price') + ': '}
							{Tools.$filter('currencyFormat')(price, 'SEK')}
						</h4>
					</div>
				)}
			</div>
			<div className="accept-row">
				{accept ? (
					<>
						<Button onClick={resolve} size="lg">
							{t('integration.acceptTerms')}
						</Button>
						<Button onClick={reject} size="lg" type="link">
							{t('default.abort')}
						</Button>
					</>
				) : (
					<>
						<Button onClick={reject}>{t('integration.acceptedTerms')}</Button>
						<Button onClick={reject} size="lg" type="link">
							{t('default.close')}
						</Button>
					</>
				)}
			</div>
		</div>
	);
}
