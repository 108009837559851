import { Modal } from '@upsales/components';
import BemClass from '@upsales/components/Utils/bemClass';
import Flow from 'App/resources/Model/Flow';
import moment from 'moment';
import React, { useEffect, useMemo, useRef, useState } from 'react';
import FormObserver, { FieldModel } from '../FormObserver';
import { ModalProps } from '../Modals/Modals';
import CreateFlowContent from './CreateFlowContent';
import T from 'Components/Helpers/translate';
import './CreateFlow.scss';
import { FlowTemplateName, getFlowPathFromTemplateName } from 'App/enum/FlowTemplate';
import logError from 'Helpers/logError';
import FlowResource, { EndCriteriaName } from 'App/resources/Flow';
import { CancelablePromise, makeCancelable } from 'Helpers/promise';
import { flowTracker } from 'Helpers/Tracker';

export type EditableFlow = Pick<
	Flow,
	'name' | 'startTime' | 'endTime' | 'timezone' | 'skipWeekends' | 'segment' | 'segmentId' | 'path'
> & {
	id?: number;
	templateName?: FlowTemplateName | null;
	hasGoal: boolean;
	endFlowOnEmail: boolean;
	endFlowOnEmailDays: string;
	sourceTemplate?: string;
};

type Props = ModalProps<Flow | undefined> & {
	flow?: Partial<Flow>; // Initial flow data. If it contains an id, it's an edit flow and we will show the form. If a name or path was provided, we will end up on the form view.
	flowTemplateName?: FlowTemplateName | null; // Pass a template name to preselect it, or null to show the form on open
};

const getTimeError = ({ startTime, endTime }: EditableFlow) => {
	if (startTime && endTime && moment(endTime, 'LT').subtract(30, 'm').isSameOrBefore(moment(startTime, 'LT'))) {
		return T('flow.newFlow.endtimeCantBeBeforeStart');
	}

	return false;
};

const useFormModel = () =>
	useMemo(
		() => ({
			name: FieldModel.string('default.name').required(),
			segment: FieldModel.object('default.segment', {
				id: FieldModel.number('id'),
				name: FieldModel.string('default.name')
			}),
			startTime: FieldModel.string('default.startTime').required(),
			endTime: FieldModel.string('default.endTime')
				.customValidator((v, values) => getTimeError(values as EditableFlow))
				.required(),
			timezone: FieldModel.string('default.timeZone').required(),
			skipWeekends: FieldModel.boolean('flow.newFlow.notOnWeekends'),
			templateName: FieldModel.string('default.templateName'),
			hasGoal: FieldModel.boolean('hasGoal'),
			endFlowOnEmail: FieldModel.boolean('endFlowOnEmail'),
			endFlowOnEmailDays: FieldModel.string('endFlowOnEmailDays')
		}),
		[]
	);

export default ({ className, close, ...props }: Props) => {
	const classes = useMemo(() => new BemClass('CreateFlow', className), [className]);
	const formModel = useFormModel();
	const initialFlow = props.flow;
	const [saving, setSaving] = useState(false);
	const savePromise = useRef<CancelablePromise<{ data: Flow }>>();

	const saveFlow = (flow: EditableFlow) => {
		flow.segmentId = flow.segment?.id || null;
		setSaving(true);

		if (flow.templateName) {
			flow.path = getFlowPathFromTemplateName(flow.templateName);
		}
		const endCriterias = [];
		const goalEndCriteria =
			initialFlow?.endCriterias?.find(c => c.isGoal) ||
			FlowResource.getEndCriteria(EndCriteriaName.AppointmentGoalEndCriteria);
		const emailEndCriteria =
			initialFlow?.endCriterias?.find(c => !c.isGoal) ||
			FlowResource.getEndCriteria(EndCriteriaName.EmailEndCriteria);

		if (flow.hasGoal) {
			endCriterias.push(goalEndCriteria);
		}
		if (flow.endFlowOnEmail && emailEndCriteria.filterConfig?.InboundEmail?.value?.Date?.value) {
			endCriterias.push(emailEndCriteria);
			emailEndCriteria.filterConfig.InboundEmail.value.Date.value.preset = `${flow.endFlowOnEmailDays}`;
		}

		savePromise.current = makeCancelable(
			FlowResource.save({
				...flow,
				endFlowOnEmailDays: undefined,
				hasGoal: undefined,
				endFlowOnEmail: undefined,
				templateName: undefined,
				startTime: flow.startTime ? moment(flow.startTime, 'LT').format('HH:mm') : flow.startTime,
				endTime: flow.endTime ? moment(flow.endTime, 'LT').format('HH:mm') : flow.endTime,
				endCriterias
			})
		);
		savePromise.current.promise
			.then(({ data }) => {
				// Track if create
				if (!flow.id) {
					flowTracker.track(flowTracker.events.CREATE, { template: flow.templateName || 'StartFromScratch' });
				}
				close(data);
			})
			.catch(e => {
				logError(e, 'Failed to save flow');
				setSaving(false);
			});
	};

	useEffect(() => () => savePromise.current?.cancel(), []);

	// TODO: build a "useFormObserver" hook to avoid this wrapping
	return (
		<Modal className={classes.b()} size="lg">
			<FormObserver<EditableFlow>
				initialValues={{
					id: initialFlow?.id || undefined,
					name: initialFlow?.name || '',
					segment: initialFlow?.segment || null,
					startTime: initialFlow?.startTime || moment('08:00', 'HH:mm').format('LT'),
					endTime: initialFlow?.endTime || moment('17:00', 'HH:mm').format('LT'),
					timezone: initialFlow?.timezone || 'Europe/Stockholm',
					skipWeekends: !!initialFlow?.skipWeekends,
					templateName: null,
					path: initialFlow?.path || null,
					hasGoal: !!initialFlow?.endCriterias?.find(c => c.isGoal),
					endFlowOnEmail: !!initialFlow?.endCriterias?.find(c => !c.isGoal),
					endFlowOnEmailDays:
						initialFlow?.endCriterias?.find(c => !c.isGoal)?.filterConfig?.InboundEmail?.value?.Date?.value
							?.preset || 'last7days'
				}}
				model={formModel}
				onSubmit={data => saveFlow(data)}
			>
				{({ onFormChange, inputProps, errorMessages, setPristine }) => (
					<CreateFlowContent
						{...{ onFormChange, inputProps, errorMessages, setPristine, close, saving }}
						{...props}
					/>
				)}
			</FormObserver>
		</Modal>
	);
};
