import React from 'react';
import { Flex, Icon, Text, Tooltip } from '@upsales/components';
import BemClass from '@upsales/components/Utils/bemClass';
import { useTranslation } from 'Components/Helpers/translate';
import './MaxDiscountInfo.scss';

// Meant to position below an input field so make sure it has a relative position parent
function MaxDiscountInfo({
	maxDiscount,
	hasExcessiveDiscount
}: {
	maxDiscount?: number | null;
	hasExcessiveDiscount?: boolean;
}) {
	const { t } = useTranslation();
	const classes = new BemClass('MaxDiscountInfo');
	const hasMaxDiscount =
		Tools.FeatureHelper.hasSoftDeployAccess('MAX_DISCOUNT') &&
		Tools.FeatureHelper.isAvailable(Tools.FeatureHelper.Feature.MAX_DISCOUNT);

	const isAdmin = Tools.AppService.getSelf().administrator;

	const userExcessiveDiscount = hasExcessiveDiscount && !isAdmin;
	const adminExcessiveDiscount = hasExcessiveDiscount && isAdmin;

	if (!hasMaxDiscount || maxDiscount == null) {
		return null;
	}
	const tooltipTitle = adminExcessiveDiscount
		? t('default.discount.adminTooltip', { discount: +maxDiscount.toFixed(2) })
		: userExcessiveDiscount
		? t('default.discount.userTooltip', { discount: +maxDiscount.toFixed(2) })
		: '';

	const color = !hasExcessiveDiscount ? 'black' : isAdmin ? 'yellow-5' : 'danger-5';

	return (
		<Tooltip title={tooltipTitle} className={classes.b()}>
			<Flex gap={'u1'} alignItems="center">
				<Icon name="info-circle" color={color} />
				<Text size="xs" color={color}>{`${t('default.maxDiscount')} ${+maxDiscount.toFixed(2)}%`}</Text>
			</Flex>
		</Tooltip>
	);
}

export default MaxDiscountInfo;
