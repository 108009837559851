import React from 'react';
import { useOrderRowsSelector } from '../Context/OrderContext';
import { Text } from '@upsales/components';
import T from 'Components/Helpers/translate';
import BemClass from '@upsales/components/Utils/bemClass';
import { currencyFormat } from 'Components/Filters/Currencies';
import { totalContributionMarginPercentage } from '../ContributionMargin';

import './Summary.scss';
import { getCMWithRROption, hasRRWithCM } from 'App/helpers/salesModelHelpers';
import { useVisibleFields } from 'App/components/hooks/editListener';
import { useSelector } from 'App/components/hooks';

type Props = {
	currency: string;
};

const Summary = ({ currency }: Props) => {
	const { metadata } = useSelector(state => state.App);
	const { totalDiscount, totalAmount, totalRecurringValue, totalContributionMargin } = useOrderRowsSelector(s => ({
		totalDiscount: s.totalDiscount,
		totalAmount: s.totalAmount,
		totalRecurringValue: s.totalRecurringValue,
		totalContributionMargin: s.totalContributionMargin
	}));
	const showDiscount = metadata?.params.UseDiscount;
	const cmWithRROption = getCMWithRROption();
	const salesModelOption = cmWithRROption ?? metadata?.params.SalesModelOption ?? '';
	const salesModel = metadata?.params.SalesModel ?? 'sales';
	const decimals = 2; // 2 is enough for summary
	const shouldDisplayRR = (salesModel === 'rr' && ['arr', 'mrr'].indexOf(salesModelOption) >= 0) || !!cmWithRROption;
	const shouldDisplayCM = salesModel === 'cm' || hasRRWithCM();
	const isVisibleField = useVisibleFields('order', true);

	const classes = new BemClass('Summary');
	return (
		<div className={classes.b()}>
			<div className={classes.elem('labels').b()}>
				{isVisibleField('totalgross') ? (
					<Text size="sm" bold>
						{T('subscription.modal.summary.orderRows.amount')}
					</Text>
				) : null}
				{showDiscount && isVisibleField('totaldiscount') ? (
					<Text size="sm" bold>
						{T('subscription.modal.summary.orderRows.discount')}
					</Text>
				) : null}
				{shouldDisplayRR && isVisibleField('totalrr') ? (
					<Text size="sm" bold>
						{salesModelOption.toUpperCase()}
					</Text>
				) : null}
				{shouldDisplayCM && isVisibleField('totalcontributionmargin') ? (
					<Text size="sm" bold>
						{T('default.contributionMarginShort')}
					</Text>
				) : null}
				{isVisibleField('totalnet') ? (
					<Text bold>{T('subscription.modal.summary.orderRows.netAmount')}</Text>
				) : null}
			</div>
			<div className={classes.elem('values').b()}>
				{isVisibleField('totalgross') ? (
					<Text size="sm">{currencyFormat(totalAmount, currency, true, decimals, 2)}</Text>
				) : null}
				{showDiscount && isVisibleField('totaldiscount') ? (
					<Text size="sm">{`${totalDiscount ? '-' : ''}${currencyFormat(
						totalDiscount,
						currency,
						true,
						decimals,
						2
					)}`}</Text>
				) : null}

				{shouldDisplayRR && isVisibleField('totalrr') ? (
					<Text size="sm">{currencyFormat(totalRecurringValue, currency, true, decimals, 2)}</Text>
				) : null}
				{shouldDisplayCM && isVisibleField('totalcontributionmargin') ? (
					<Text size="sm">{`${currencyFormat(
						totalContributionMargin,
						currency,
						true,
						decimals,
						2
					)} (${totalContributionMarginPercentage(
						totalAmount - totalDiscount,
						totalContributionMargin
					)}%)`}</Text>
				) : null}
				{isVisibleField('totalnet') ? (
					<Text bold>{currencyFormat(totalAmount - totalDiscount, currency, true, decimals, 2)}</Text>
				) : null}
			</div>
		</div>
	);
};

export default Summary;
