import React from 'react';

const markdownRenderType = ({ row }) => {
	var markdownProps = {
		className: ['widget-md']
	};

	if (row.style) {
		markdownProps.className.push(row.style);
	}

	var convertedMarkdown = markdown.toHTML(row.markdown).replace(/<a/g, '<a target="_blank"');
	markdownProps.className = markdownProps.className.join(' ').trim();

	return <div className="widget-md-wrapper">{React.createElement('div', markdownProps, convertedMarkdown)}</div>;
};

export default markdownRenderType;
