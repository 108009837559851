import Resource from './Resource';
import translate from 'Components/Helpers/translate';
import moment from 'moment';
import { globalTracker } from 'Helpers/Tracker';

function isDraft(mail) {
	return !!(mail?.isDraft || mail?.status === 'DRAFT');
}

function isScheduled(date) {
	if (!date) {
		return false;
	}
	return moment().isBefore(date);
}

class MailIntegration extends Resource {
	constructor() {
		super('mailintegration');
		this.eventName = 'mail';

		this.notifications = {
			save: ({ data: mail }) => {
				if (isDraft(mail)) {
					return {
						title: 'default.saved',
						body: 'mail.mailWasSavedDraft'
					};
				}
				if (isScheduled(mail?.date)) {
					const d = moment(mail?.sendDate || mail?.date).format('L LT');

					return {
						title: 'mail.mailScheduled',
						body: translate('mail.mailWillBeSent') + ' ' + d
					};
				}
				return {
					title: 'mail.sent',
					body: 'mail.mailWasSent'
				};
			},
			saveError: () => ({
				title: 'default.error',
				body: 'saveError.mail'
			}),
			saveMulti: ({ data, metadata }) => {
				if (data.length === 0) {
					return {
						title: 'default.error',
						body: 'saveError.mail'
					};
				}

				const total = metadata.sent + metadata.error;
				const partial = metadata.sent;
				const scheduled = data[0] ? isScheduled(data[0]?.date) : false;

				if (scheduled) {
					const d = moment(data[0].sendDate || data[0].date).format('L LT');

					if (metadata.error) {
						return {
							title: 'mail.mailScheduled',
							body: `${translate('mail.mailMultiPartialWillBeSent', { total, partial })} ${d}`
						};
					} else {
						return {
							title: 'mail.mailScheduled',
							body: `${translate('mail.mailMultiAllWillBeSent', { total })} ${d}`
						};
					}
				} else {
					if (metadata.error) {
						return {
							title: 'mail.sent',
							body: translate('mail.mailMultiPartialWasSent', { total, partial })
						};
					} else {
						return {
							title: 'mail.sent',
							body: translate('mail.mailMultiAllWasSent', { total })
						};
					}
				}
			},
			saveMultiError: () => ({
				title: 'default.error',
				body: 'saveError.mail'
			})
		};
	}

	saveMulti(data, opts = {}) {
		const mappedData = this._map(data);

		const mailAppName = Tools.AppService.getMetadata().integrations.inits.mail.find(
			a => a.id === opts.params.integrationId
		).name;
		globalTracker.track('Sent single-email', { app: mailAppName });
		return this._postRequest('', mappedData, { ...opts, methodName: 'saveMulti' }).then(res => {
			for (const mail of res.data.data) {
				this._triggerEvent(opts, 'added', { data: mail });
			}
			return { ...res.data, data: res.data.data.map(this._parse.bind(this)) };
		});
	}

	test(data, opts = {}) {
		const mappedData = this._map(data);
		return this._postRequest('test', mappedData, { ...opts, methodName: 'test' }).then(res => {
			console.log(res);
			return { ...res.data, data: res.data.data.map(this._parse.bind(this)) };
		});
	}

	send(data, opts = {}) {
		const mappedData = this._map(data);
		return this._postRequest('send', mappedData, { ...opts, _url: 'mail', methodName: 'save' }).then(res => {
			this._triggerEvent(opts, 'added', res.data);
			return { ...res.data, data: this._parse(res.data.data) };
		});
	}

	async getProfile() {
		const customerId = Tools.AppService.getCustomerId();
		const integrationId = Tools.AppService.getMailIntegration().id;
		const result = await Tools.StandardIntegration.data(customerId).run({
			type: 'profile',
			typeId: '',
			data: {},
			integrationId
		});
		return result.data;
	}
}

const resource = new MailIntegration();

export default resource;
