import LZString from 'lz-string';

import logError from 'Helpers/logError';

class IndexedDB {
	dbName: string;
	storeName: string;

	constructor(dbName: string, storeName: string) {
		this.dbName = dbName;
		this.storeName = storeName;
	}

	getConnection(mode: IDBTransactionMode) {
		return new Promise<IDBTransaction>((resolve, reject) => {
			const request = indexedDB.open(this.dbName);

			request.onsuccess = (event: any) => {
				try {
					const db: IDBDatabase = event.target.result;
					resolve(db.transaction(this.storeName, mode));
				} catch (err) {
					reject(err);
				}
			};

			// New database (or version) created
			request.onupgradeneeded = (event: any) => {
				try {
					const db: IDBDatabase = event.target.result;
					const transaction: IDBTransaction = event.target.transaction;
					db.createObjectStore(this.storeName);
					resolve(transaction);
				} catch (err) {
					reject(err);
				}
			};

			request.onerror = (event: any) => {
				reject(request.error);
			};
		});
	}

	getObjectStore = async (mode: IDBTransactionMode) => {
		try {
			const transaction = await this.getConnection(mode);
			return transaction.objectStore(this.storeName);
		} catch (err) {
			logError(err, 'IndexedDB: error when getting object store');
			return null;
		}
	};

	getValueFromObjectStore = async (key: string) => {
		try {
			const objectStore = await this.getObjectStore('readonly');
			return new Promise<string>((resolve, reject) => {
				if (!objectStore) {
					return reject('No object store');
				}

				const request = objectStore?.get(key);
				request.onerror = reject;
				request.onsuccess = (event: any) => {
					resolve(event.target.result);
				};
			});
		} catch (err) {
			logError(err, 'IndexedDB: error when getting value');
			return null;
		}
	};

	getValue = async <T>(key: string) => {
		try {
			let storedValue = await this.getValueFromObjectStore(key);
			if (storedValue) {
				storedValue = LZString.decompressFromUTF16(storedValue);
				try {
					storedValue = JSON.parse(`${storedValue}`);
				} catch (err) {
					logError(err, 'IndexedDB: error when parsing to json');
				}
			}
			return storedValue as T;
		} catch (err) {
			logError(err, 'Data: error when getting value');
			return null;
		}
	};

	setValue = async (key: string, value: any) => {
		try {
			const objectStore = await this.getObjectStore('readwrite');
			if (!value) {
				objectStore?.put(value, key);
				return;
			}

			const compressedValue = LZString.compressToUTF16(JSON.stringify(value));
			objectStore?.put(compressedValue, key);
		} catch (err) {
			logError(err, 'IndexedDB: error when setting value');
		}
	};

	setValueWithPartialKey = async (partialKey: string, value: any) => {
		try {
			const objectStore = await this.getObjectStore('readwrite');
			if (!objectStore) {
				return;
			}
			objectStore.openCursor().onsuccess = (event: any) => {
				const cursor = event.target.result;
				if (cursor) {
					if (cursor.key.includes(partialKey)) {
						cursor.update(value);
					}
					cursor.continue();
				}
			};
		} catch (err) {
			logError(err, 'IndexedDB: error when setting value with partial key');
		}
	};
}

export default IndexedDB;
