import Resource from './Resource';

type Opts = {
	grouping: string | string[];
	q: { a: string; c: string; v: string }[];
	limit?: number;
	offset?: number;
	keys?: string | string[];
	isTest?: boolean;
};

class ReportWidgetDrilldown extends Resource {
	constructor() {
		super('report');
	}

	getDrilldownByType(widgetType: string, keys: string | string[], opts: Opts) {
		return this._genericGet(`${widgetType}`, keys, opts);
	}

	private async _genericGet(path: string, keys: string | string[], opts: Opts, filters: object | undefined = {}) {
		if (!Array.isArray(keys)) {
			opts.keys = [keys];
		} else {
			opts.keys = keys;
		}
		return this._getRequest(`widget/${path}`, {
			methodName: 'get',
			params: { ...opts, drilldown: true }
		}).then(res => res.data);
	}

	async get() {
		throw Error('ReportWidgetDrilldown.get() not allowed');
	}

	async save() {
		throw Error('ReportWidgetDrilldown.save() not allowed');
	}

	async delete() {
		throw Error('ReportWidgetDrilldown.delete() not allowed');
	}
}

const resource = new ReportWidgetDrilldown();

export default resource;
