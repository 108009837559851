import React from 'react';
import { get } from 'lodash';
import { Text } from '@upsales/components';
import TimelineRow from 'Components/TimelineRow';
import BemClass from '@upsales/components/Utils/bemClass';

import './index.scss';

const ActivityTimelineChangeDescription = ({ event, hideIcon }) => {
	const t = Tools.$translate;
	const lang = {
		changedDescriptionTo: t('activity.outcomeLog.changedDescriptionTo'),
		prevDescription: t('activity.outcomeLog.prevDescription')
	};
	const description = get(event.data, 'description');
	const oldDescription = get(event.data, 'oldDescription');
	const date = get(event, 'date');
	const user = get(event, 'user') || {};
	const classNames = new BemClass('ActivityTimelineChangeDescription');

	const title = (
		<Text>
			{lang.changedDescriptionTo} {description}
		</Text>
	);
	const subTitle = date ? (
		<Text size="sm" color="grey-11">
			{moment(date).format('L LT')} <span dangerouslySetInnerHTML={{ __html: '&#8226;' }} /> {user.name}
		</Text>
	) : null;
	const subSection = oldDescription ? (
		<Text color="grey-11" className={classNames.elem('subsection-text').b()}>
			{lang.prevDescription} {oldDescription}
		</Text>
	) : null;

	return (
		<TimelineRow
			icon={hideIcon ? undefined : 'edit'}
			event={event}
			title={title}
			subTitle={subTitle}
			subSection={subSection}
		/>
	);
};

export default ActivityTimelineChangeDescription;
