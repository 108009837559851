import { Equals } from 'Resources/ComparisonTypes';
import DatePreset from 'App/upsales/common/services/datePresetService';

export const newFilter = function () {
	return {
		inactive: true,
		value: {
			hasMailCampaign: true,
			Date: { value: { start: null, end: null, preset: 'whenever' } },
			EventType: { value: null, not: false },
			MailCampaign: { value: [], comparisonType: Equals }
		}
	};
};

const mailCampaignFilter = {
	generate: newFilter,
	isInactive: function (filter) {
		return filter.inactive;
	},
	getText: (val, inactive) => {
		let num = 0;

		if (inactive !== true) {
			num++;
		}

		if (val.MailCampaign.value.length) {
			num++;
		}

		if (val.Date.value.start || val.Date.value.end || val.Date.value.preset !== 'whenever') {
			num++;
		}
		return num;
	},
	toUrl: function (filter) {
		const f = { v: {} };
		const val = filter.value;

		f.v.has = val.hasMailCampaign;

		if (val.Date.value.start || val.Date.value.end) {
			f.v.da = { p: val.Date.value.preset, s: val.Date.value.start, e: val.Date.value.end };
		}
		if (val.EventType.value) {
			f.v.ev = val.EventType.value;
			f.v.en = val.EventType.not;
		}
		if (val.MailCampaign.value) {
			f.v.ma = val.MailCampaign.value;
		}
		if (val.MailCampaign.comparisonType) {
			f.v.mc = val.MailCampaign.comparisonType;
		}
		return f;
	},
	fromUrl: function (rawFilter) {
		const filter = newFilter();
		filter.inactive = false;
		const val = rawFilter.v;

		filter.value.hasMailCampaign = val.has;

		if (val.da) {
			filter.value.Date = {
				value: {
					start: val.da.s ? new Date(val.da.s) : val.da.s,
					end: val.da.e ? new Date(val.da.e) : val.da.e,
					preset: val.da.p
				}
			};
		}
		if (val.ev) {
			filter.value.EventType = { value: val.ev };

			if (val.en !== undefined) {
				filter.value.EventType.not = val.en;
			} else {
				filter.value.EventType.not = false;
			}
		}
		if (val.ma) {
			filter.value.MailCampaign.value = val.ma;
		}
		if (val.mc) {
			filter.value.MailCampaign.comparisonType = val.mc;
		}

		return filter;
	},
	build: function () {
		return function (filter, rb, getField, useTags) {
			const val = filter.value;

			if (filter.inactive) {
				return;
			}

			if (
				!val.hasMailCampaign &&
				val.Date.value.preset === 'whenever' &&
				!val.EventType.value &&
				!val.MailCampaign.value.length
			) {
				// If all filters is empty we only need to check for missing submits
				const groupBuilder = rb.groupBuilder();
				groupBuilder.addFilter(getField('mail.mailCampaign.id'), rb.comparisonTypes.GreaterThan, 0);
				groupBuilder.addFilter(getField('mail.errorCause'), rb.comparisonTypes.Equals, null);
				groupBuilder.isNotFilter();
				groupBuilder.done();
			} else if (
				val.hasMailCampaign &&
				val.MailCampaign.comparisonType === rb.comparisonTypes.EqualsAll &&
				val.MailCampaign.value.length
			) {
				for (const value of val.MailCampaign.value) {
					const groupBuilder = rb.groupBuilder();

					groupBuilder.addFilter(getField('mail.errorCause'), rb.comparisonTypes.Equals, null);
					groupBuilder.addFilter(getField('mail.mailCampaign.id'), rb.comparisonTypes.Equals, value);

					if (val.Date.value.preset !== 'whenever') {
						const dates =
							val.Date.value.preset !== 'custom'
								? DatePreset.getDatesFromValue(val.Date.value, useTags)
								: val.Date.value;
						const dateKey = val.EventType.value && !val.EventType.not ? 'mail.event.date' : 'mail.date';

						// start
						if (dates.start) {
							groupBuilder.addFilter(
								getField(dateKey),
								rb.comparisonTypes.GreaterThanEquals,
								dates.start
							);
						}

						// end
						if (dates.end) {
							groupBuilder.addFilter(getField(dateKey), rb.comparisonTypes.LowerThanEquals, dates.end);
						}
					}

					if (val.EventType.value) {
						if (val.EventType.not) {
							groupBuilder.addFilter(
								getField('mail.event.type'),
								rb.comparisonTypes.NotEquals,
								val.EventType.value
							);
						} else {
							groupBuilder.addFilter(
								getField('mail.event.type'),
								rb.comparisonTypes.Equals,
								val.EventType.value
							);
							groupBuilder.addFilter(getField('mail.event.type'), rb.comparisonTypes.NotEquals, null);
						}
					}
					groupBuilder.done();
				}
			} else {
				const groupBuilder = rb.groupBuilder();

				if (val.Date.value.preset !== 'whenever') {
					const dates =
						val.Date.value.preset !== 'custom'
							? DatePreset.getDatesFromValue(val.Date.value, useTags)
							: val.Date.value;
					const dateKey = val.EventType.value && !val.EventType.not ? 'mail.event.date' : 'mail.date';

					// start
					if (dates.start) {
						groupBuilder.addFilter(getField(dateKey), rb.comparisonTypes.GreaterThanEquals, dates.start);
					}

					// end
					if (dates.end) {
						groupBuilder.addFilter(getField(dateKey), rb.comparisonTypes.LowerThanEquals, dates.end);
					}
				}

				groupBuilder.addFilter(getField('mail.errorCause'), rb.comparisonTypes.Equals, null);

				if (val.hasMailCampaign) {
					groupBuilder.addFilter(getField('mail.mailCampaign.id'), rb.comparisonTypes.GreaterThan, 0);

					if (val.EventType.value) {
						if (val.EventType.not) {
							groupBuilder.addFilter(
								getField('mail.event.type'),
								rb.comparisonTypes.NotEquals,
								val.EventType.value
							);
						} else {
							groupBuilder.addFilter(
								getField('mail.event.type'),
								rb.comparisonTypes.Equals,
								val.EventType.value
							);
							groupBuilder.addFilter(getField('mail.event.type'), rb.comparisonTypes.NotEquals, null);
						}
					}
					if (val.MailCampaign.value.length) {
						const comparisonType = val.MailCampaign.comparisonTypes || rb.comparisonTypes.Equals;

						groupBuilder.addFilter(
							getField('mail.mailCampaign.id'),
							comparisonType,
							val.MailCampaign.value
						);
					}
				} else {
					if (val.MailCampaign.value.length) {
						groupBuilder.addFilter(
							getField('mail.mailCampaign.id'),
							rb.comparisonTypes.Equals,
							val.MailCampaign.value
						);

						if (val.EventType.value) {
							groupBuilder.addFilter(
								getField('mail.event.type'),
								rb.comparisonTypes.Equals,
								val.EventType.value
							);
						}
					} else {
						groupBuilder.addFilter(getField('mail.mailCampaign.id'), rb.comparisonTypes.GreaterThan, 0);
					}

					groupBuilder.isNotFilter();
				}

				groupBuilder.done();
			}
		};
	}
};

export default mailCampaignFilter;
