import { Card, Text, Icon, DropDownMenu } from '@upsales/components';
import { OldOrNewAgreement } from '../../SubscriptionCards';
import BemClass from '@upsales/components/Utils/bemClass';
import Agreement from 'App/resources/Model/Agreement';
import { DangerButton } from '@upsales/components/Buttons';
import T from 'Components/Helpers/translate';
import moment from 'moment';
import React from 'react';

import './DeleteGroup.scss';

type Props = {
	removeSubscription: (agreement: Agreement, agreementGroupId?: number, agreementGroup?: OldOrNewAgreement) => void;
	agreementGroup: OldOrNewAgreement;
	deleting: boolean;
};

const DeleteGroup = ({ removeSubscription, agreementGroup, deleting }: Props) => {
	const classes = new BemClass('DeleteGroup');

	const { agreements, currentAgreement } = agreementGroup;

	let futureAgreementsCount = 0;
	agreements.forEach(agreement => {
		if (agreement.children?.length) {
			const currentChild =
				[...(currentAgreement.children ?? [])]
					.reverse()
					.find(child => moment().isSameOrAfter(moment(child.startDate))) ?? currentAgreement?.children?.[0];

			agreement.children.forEach(child => {
				if (moment(child.startDate).isAfter(currentChild?.startDate)) {
					futureAgreementsCount++;
				}
			});
		} else if (
			moment(agreement.metadata.agreementStartdate).isAfter(currentAgreement.metadata.agreementStartdate)
		) {
			futureAgreementsCount++;
		}
	});

	return (
		<div className={classes.b()}>
			<DropDownMenu
				align="right"
				renderTrigger={(expanded, setExpanded) => <Icon name="trash" onClick={setExpanded} />}
				useAnimation
			>
				<Card
					className={classes
						.elem('dropDown')
						.mod({ hasSplit: futureAgreementsCount > 0 })
						.b()}
				>
					<div>
						<Text bold align="center">
							{T('subscription.delete.deleteSubscription')}
						</Text>
						<Text size="sm" color="grey-11" align="center">
							{T('subscription.delete.cannotBeUndone')}
						</Text>
					</div>
					{futureAgreementsCount > 0 ? (
						<>
							<hr />

							<Text className={classes.elem('dropDown').elem('containsPlannedChange').b()}>
								{T(
									futureAgreementsCount === 1
										? 'subscription.delete.theSubscriptionContainsPlannedChange'
										: 'subscription.delete.theSubscriptionContainsPlannedChanges',
									{ count: futureAgreementsCount }
								)}
							</Text>
						</>
					) : null}
					<DangerButton
						loading={deleting}
						onClick={e => {
							e.stopPropagation();
							removeSubscription(agreementGroup.currentAgreement, agreementGroup.id, agreementGroup);
						}}
					>
						{T('subscription.delete.deleteTheSubscription')}
					</DangerButton>
				</Card>
			</DropDownMenu>
		</div>
	);
};

export default DeleteGroup;
