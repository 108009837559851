import { ListView, ListViewFilter } from 'App/resources/AllIWant';
import logError from 'Helpers/logError';
import LZString from 'lz-string';
import { ListViewState } from './ListView';
import _ from 'lodash';

type FiltersObj = { [filterName: string]: ListViewFilter };
type UrlData<T> = Pick<ListViewState<T>, 'filters' | 'sorting' | 'offset' | 'tableLimit'> & { columns: string[] };

const removeEmptyFilters = <T = any>(filters: { [filterName: string]: ListViewFilter }) => {
	if (!filters) {
		return filters;
	}
	return Object.keys(filters).reduce((r, k) => {
		if (!['""', '[]'].includes(JSON.stringify(filters![k]?.value))) {
			r[k] = filters![k];
		}
		return r;
	}, {} as ListViewState<T>['filters']);
};

// Compresses selected data from provided object into a base64 string. Will remove emty filters
export const compressChanges = <T = any>(state: Partial<UrlData<T>>) => {
	return LZString.compressToBase64(
		JSON.stringify({
			// Ignore empty filters
			filters: typeof state.filters !== 'undefined' ? removeEmptyFilters<T>(state.filters) : null,
			columns: state.columns,
			sorting: state.sorting,
			offset: state.offset,
			tableLimit: state.tableLimit
		})
	);
};

// Decompresses base64 string back to the object
export const decompressChanges = <T = any>(compressedData: string): UrlData<T> | null => {
	const defaultRes = {
		filters: {},
		sorting: [],
		columns: [],
		offset: 0,
		tableLimit: 50
	};
	let res = {};
	const string = LZString.decompressFromBase64(compressedData) || '{}';
	try {
		res = JSON.parse(string);
	} catch (e) {
		logError(e, 'Failed to parse listView compressedData ' + string);
		return null;
	}
	return { ...defaultRes, ...res } as UrlData<T>;
};

// Will convert name mapped object of filter objects into array of filter objects
export const filterObjToArray = (filters: FiltersObj) => {
	return Object.keys(filters).map(filterName => filters[filterName]);
};

// Will get selected params from the url search string and build to an object
export const getParamsFromSearch = (search: string) => {
	const params = new URLSearchParams(search);
	const offset = parseInt(params.get('offset') + '');
	const f = params.get('f');

	return {
		id: params.get('id') || null,
		compressedData: f ? decodeURIComponent(f).replaceAll(' ', '+') : null,
		offset: !isNaN(offset) ? offset : null
	};
};

// Will compare two searchstrings and say if they differ
export const locationSearchChanged = (oldSearch: string, newSearch: string) => {
	const oldParams = getParamsFromSearch(oldSearch);
	const newParams = getParamsFromSearch(newSearch);
	return JSON.stringify(oldParams) !== JSON.stringify(newParams);
};

// Will return the correct selected view from a list of available views and an optional id param
// if id is provided it will always try to return that, then any default, then the first in the list. Else null
export const decideSelectedView = (listViews: ListView[], id: string | null): ListView | null => {
	let selectedView: ListView | null = null;

	// use id if it was provided
	if (id) {
		selectedView = listViews.find(v => v.id + '' === id + '') || null;
	}

	// Fallback to default, or first if no default
	if (!selectedView) {
		selectedView = listViews.find(v => v.default) || listViews[0] || null;
	}
	return selectedView;
};

// Will take a list of filter objects and build a name mapped object of it
export const getFiltersFromView = (filters: ListViewFilter[], type?: string) =>
	filters.reduce<{ [n: string]: ListViewFilter }>((res, obj) => {
		if (obj.filterName?.substring(0, 7) === 'Custom_' && type) {
			obj.filterName = `${type}.${obj.filterName}`;
		}
		res[obj.filterName] = obj;
		return res;
	}, {});

type SearchPartialState<ItemType> = Omit<Partial<ListViewState<ItemType>>, 'selectedView'> & {
	selectedView: ListView;
};

// Will try to build a partial ListViewState from a provided url search string
export function getPartialStateFromSearch<ItemType>(
	search: string,
	listViews: ListView[]
): SearchPartialState<ItemType> | null {
	const { id, compressedData, offset } = getParamsFromSearch(search);
	const selectedView = decideSelectedView(listViews, id);
	const decompressedData = compressedData ? decompressChanges<ItemType>(compressedData) : null;

	// Bail out if selected view was not found. For now the filters to url thing requires a listview
	if (!selectedView) {
		return null;
	}

	const viewFilters = getFiltersFromView(selectedView.filters, selectedView.type);
	const originalStateCompression = compressChanges<ItemType>({
		filters: viewFilters,
		sorting: selectedView.sorting,
		columns: selectedView.columns,
		offset: 0,
		tableLimit: selectedView.limit
	});

	const partialState: SearchPartialState<ItemType> = {
		selectedView,
		filters: decompressedData?.filters ? decompressedData.filters : viewFilters,
		sorting: decompressedData?.sorting.length ? decompressedData.sorting : selectedView.sorting,
		offset: decompressedData?.offset ?? offset ?? 0,
		tableLimit: decompressedData?.tableLimit ?? 50,
		originalStateCompression,
		currentStateCompression: compressedData || originalStateCompression
	};
	if (partialState.selectedView && decompressedData?.columns?.length) {
		partialState.selectedView.columns = decompressedData.columns;
	}
	return partialState;
}

// Will return a partial listView state or null depending on if anything changed compared to prev state and url search
export function getChangedPartialStateFromSearch<ItemType>(
	search: string,
	prevSearch: string,
	listViews: ListView[],
	prevState: ListViewState<ItemType>
) {
	const stateFromSearch = getPartialStateFromSearch<ItemType>(search, listViews);

	// If we somehow got nothing from getPartialStateFromSearch then we need to abort (missing selected view)
	if (!stateFromSearch) {
		return null;
	}

	// If view changed
	const { compressedData } = getParamsFromSearch(search);
	const { compressedData: prevCompressedData } = getParamsFromSearch(prevSearch);
	const viewChanged =
		// If we went from having no selectedView
		(!prevState.selectedView && !!stateFromSearch.selectedView) ||
		// Or selected changed
		(prevState.selectedView && prevState.selectedView.id !== stateFromSearch.selectedView.id) ||
		// Or if we went from having compressedData to not having it
		(prevCompressedData && !compressedData);
	const partialState: Partial<ListViewState<ItemType>> = {};
	const compressionChanged = prevCompressedData !== compressedData;
	const offsetChanged = stateFromSearch.offset !== prevState.offset;
	if (viewChanged) {
		partialState.selectedView = stateFromSearch.selectedView;
		partialState.filters = getFiltersFromView(
			stateFromSearch.selectedView.filters,
			stateFromSearch.selectedView.type
		);
		partialState.sorting = stateFromSearch.selectedView.sorting;
		partialState.offset = 0; // get from url

		// Reset compressedData by passing originalStateCompression as "reset"
		partialState.originalStateCompression = stateFromSearch.originalStateCompression;
		partialState.currentStateCompression = stateFromSearch.originalStateCompression;
	} else {
		if (offsetChanged) {
			partialState.offset = stateFromSearch.offset;
		}
		if (compressedData && compressionChanged) {
			// If we have compressedData and it differs from the current we set filters from it
			const decompressedData = decompressChanges<ItemType>(compressedData);
			if (decompressedData) {
				if (!_.isEqual(stateFromSearch.filters, removeEmptyFilters(prevState.filters))) {
					partialState.filters = stateFromSearch.filters;
				}
				partialState.sorting = stateFromSearch.sorting;
				partialState.selectedView = stateFromSearch.selectedView;
				partialState.offset = stateFromSearch.offset;
				partialState.tableLimit = stateFromSearch.tableLimit;
			}
		}
	}
	if (viewChanged || compressionChanged || offsetChanged) {
		return partialState;
	}
	return null;
}
