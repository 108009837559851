import React, { useEffect, useState } from 'react';
import {
	Block,
	Card,
	Flex,
	FullscreenModal,
	Headline,
	Help,
	Icon,
	Loader,
	ModalContent,
	Paginator,
	StateFrame,
	Text,
	Title
} from '@upsales/components';
import './HandleDuplicates.scss';
import { decideHeadline, handleHideAction } from './Helpers/Helpers';
import { ExpandedTableTitles } from './DuplicatesCard/DuplicatesExpandedTable';
import { formatFetchedAmount } from '../CleanMyData/Helpers/fetchHelpers';
import { omit } from 'lodash';
import { PrimaryButton } from '@upsales/components/Buttons';
import { useTranslation } from 'Components/Helpers/translate';
import bemClass from '@upsales/components/Utils/bemClass';
import DetectedDuplicate from 'App/babel/resources/DetectedDuplicate';
import DuplicatesCard from './DuplicatesCard/DuplicatesCard';
import EditorHeader from 'Components/EditorHeader';
import HandleDuplicatesControls from './HandleDuplicatesControls';
import HiddenDuplicatesCard from './DuplicatesCard/HiddenDuplicatesCard';
import logError from 'App/babel/helpers/logError';
import RequestBuilder, { comparisonTypes } from 'Resources/RequestBuilder';
import type { DetectedDuplicateType } from 'App/resources/Model/DetectedDuplicates';
import useGetData, { FetcherFunction } from 'App/components/hooks/useGetData';

import './HandleDuplicates.scss';

type Props = {
	className: string;
	close: () => void;
	entity: string;
	entityName: string;
	ignoreFilter: boolean;
	filter: boolean;
	totalDuplicates: number;
};

type DataType = {
	id: number;
	duplicates: DetectedDuplicateType[];
};

type FetcherProps = {
	ignoreFilter: boolean;
	entityName: string;
	filter: boolean;
};

type ActionsType = { [key: number]: string | number };

const getAPIFilters = (
	rb: RequestBuilder,
	{ ignoreFilter, entityName, filter }: { ignoreFilter?: boolean; entityName: string; filter: boolean }
) => {
	rb.addExtraParam('groupDuplicates', true);

	if (typeof ignoreFilter === 'boolean') {
		rb.addFilter({ field: 'ignore' }, comparisonTypes.Equals, ignoreFilter);
	}

	if (entityName === 'Client' && filter) {
		rb.addFilter({ field: 'sameDataHubId' }, comparisonTypes.Equals, filter);
	} else if (entityName === 'Contact' && filter) {
		rb.addFilter({ field: 'sameNameEmailAndClient' }, comparisonTypes.Equals, filter);
	}

	const filters = rb.build();
	filters.entity = entityName;

	return filters;
};

const fetcher: FetcherFunction<{ ignoreFilter: boolean; entityName: string; filter: boolean }, DataType> = (
	rb: RequestBuilder,
	{ ignoreFilter, entityName, filter }: { ignoreFilter: boolean; entityName: string; filter: boolean }
) => {
	const filters = getAPIFilters(rb, { ignoreFilter, entityName, filter });
	return DetectedDuplicate.find(filters);
};

const LIMIT = 50;

const HandleDuplicates = ({ className, close, entity, entityName, ignoreFilter, filter, totalDuplicates }: Props) => {
	const classes = new bemClass('HandleDuplicates', className);
	const { t } = useTranslation();
	const [offset, setOffset] = useState(0);
	const [actions, setActions] = useState<ActionsType>({});
	const [isDoingActions, setIsDoingActions] = useState(false);
	const [expandedCards, setExpandedCards] = useState<{ [key: number]: boolean }>({});

	const { loading, data, metadata, refetch } = useGetData<FetcherProps, DataType>({
		fetcher,
		offset,
		limit: LIMIT,
		fetcherProps: {
			ignoreFilter,
			entityName,
			filter
		}
	});

	const onConfirm = async () => {
		try {
			setIsDoingActions(true);
			if (filter) {
				const rb = new RequestBuilder();
				const duplicateOfIds = Object.keys(actions).map(id => parseInt(id));
				// Exclude those who the user has choosen either to hide, not to match or those who they have choosen another company/contact to match with then the one we sugest
				if (duplicateOfIds.length > 0) {
					rb.addFilter({ field: 'duplicateOf' }, comparisonTypes.NotEquals, duplicateOfIds);
				}
				const filters = getAPIFilters(rb, { entityName, filter });
				const filteredActions = omit(actions, value => value === 'exclude');
				await DetectedDuplicate.batch({ ...filteredActions, filters, entity: entityName });
			} else {
				await DetectedDuplicate.batch({ ...actions, entity: entityName });
			}
			Tools.$rootScope.$broadcast('duplicates.updated');
			close();
		} catch (e) {
			logError(e, 'Failed to batch update duplicates');
		} finally {
			setIsDoingActions(false);
		}
	};

	useEffect(() => {
		if (!filter) {
			const newExpandedCards = data.reduce<{ [key: number]: boolean }>((acc, item) => {
				item.duplicates.forEach(duplicate => {
					acc[duplicate.id] = true;
				});
				return acc;
			}, {});
			setExpandedCards(newExpandedCards);
		}
	}, [filter, ignoreFilter, data]);

	const lang = {
		cancel: t('default.cancel'),
		duplicateClients: t('detectedDuplicates.title.client'),
		duplicateContacts: t('detectedDuplicates.title.contact'),
		infoText: t('handleDuplicates.infoText'),
		noDuplicatesFound: t('detectedDuplicates.noDuplicates'),
		readMore: t('default.readMore'),
		saveChanges: t('default.saveChanges')
	};

	const accountEntity = entity === 'accounts';
	const numberCount = filter
		? metadata.total - Object.values(actions).filter(value => value === 'exclude' || value === 'hide').length
		: Object.values(actions).filter(value => typeof value === 'number').length;
	const saveButtonIsDisabled = filter
		? metadata.total - Object.values(actions).filter(value => value === 'exclude').length === 0
		: Object.keys(actions).length === 0;

	return (
		<FullscreenModal headerAtTop className={classes.b()}>
			<EditorHeader
				title={accountEntity ? lang.duplicateClients : lang.duplicateContacts}
				icon={accountEntity ? 'building' : 'user'}
				className={className}
				onAbort={close}
				onConfirm={onConfirm}
				onConfirmIcon="check"
				loading={isDoingActions}
				disableSave={saveButtonIsDisabled}
				onConfirmTitle={
					numberCount > 0
						? t('handleDuplicates.saveAndMerge', { amount: formatFetchedAmount(numberCount) })
						: lang.saveChanges
				}
				onConfirmSupertitle={''}
				subtitle={`${formatFetchedAmount(totalDuplicates)} ${
					totalDuplicates === 1
						? t('detectedDuplicates.duplicate').toLowerCase()
						: t('detectedDuplicates.duplicates')
				}`}
			>
				<></>
			</EditorHeader>
			<ModalContent>
				<Block className={classes.elem('headlineBlock').b()} space="mtxl">
					<Headline space="mbl">{decideHeadline(entityName, ignoreFilter)}</Headline>
					<StateFrame state="info" icon="info-circle" title={lang.infoText}>
						<Help articleId={1003} sidebar>
							<PrimaryButton>
								<Flex alignItems="center">
									<Icon name="question-circle" space="mrm" />
									<Text color="white">{lang.readMore}</Text>
								</Flex>
							</PrimaryButton>
						</Help>
					</StateFrame>
				</Block>
				{loading ? (
					<Flex justifyContent="center">
						<Loader />
					</Flex>
				) : !loading && data?.length ? (
					<Block className={classes.elem('cardWrapper').b()}>
						{data.map(item => {
							const key = `${entityName}:${item.id}`;
							if (actions[item.id] === 'hide' && ignoreFilter !== true) {
								return (
									<HiddenDuplicatesCard
										key={key}
										classes={classes}
										onHideDuplicate={e => {
											e.stopPropagation();
											const nextActions = handleHideAction(actions, item.id);
											setActions(nextActions);
										}}
										entityName={entityName}
									/>
								);
							}

							return (
								<Card key={key} space="mbl">
									<Flex>
										<Flex direction="column">
											<HandleDuplicatesControls
												classes={classes}
												onExpand={() => {
													const ids = (item as DataType).duplicates.reduce(
														(memo: { [key: string]: boolean }, { id }: { id: number }) => {
															memo[id] = expandedCards[id] ? false : true;
															return memo;
														},
														{}
													);
													setExpandedCards({ ...expandedCards, ...ids });
												}}
												actions={actions}
												expandedCards={expandedCards}
												ignoreFilter={ignoreFilter}
												item={item}
												duplicateOf={item.id}
												onHideDuplicate={e => {
													e.stopPropagation();
													const nextActions = handleHideAction(actions, item.id);
													setActions(nextActions);
												}}
											/>
											<ExpandedTableTitles
												shouldShowMoreRows={item.duplicates?.length > 2}
												entity={entity}
												item={item}
												expandedCards={expandedCards}
											/>
										</Flex>

										<Flex alignItems="center" className={classes.elem('scroll').b()}>
											{item.duplicates.map((duplicate: DetectedDuplicateType, index: number) => {
												if (!duplicate || !duplicate.id) {
													return null;
												}
												const isChecked = actions[item.id]
													? actions[item.id] === duplicate.id
													: filter
													? index === 0
													: false;

												return (
													<DuplicatesCard
														key={duplicate.id}
														object={duplicate}
														entity={entity}
														id={duplicate.id}
														handleCardSelection={id => {
															const nextActions = { ...actions };

															if (filter) {
																if (isChecked) {
																	nextActions[item.id] = 'exclude';
																} else {
																	nextActions[item.id] = id;
																}
															} else {
																if (actions[item.id] === id) {
																	delete nextActions[item.id];
																} else {
																	nextActions[item.id] = id;
																}
															}

															setActions(nextActions);
														}}
														isChecked={isChecked}
														isExpanded={!!expandedCards[duplicate?.id]}
														shouldShowMoreRows={item.duplicates.length > 2}
													/>
												);
											})}
										</Flex>
									</Flex>
								</Card>
							);
						})}
						{metadata.total > LIMIT ? (
							<Block space="ptl">
								<Paginator
									onChange={val => {
										setOffset(val);
										refetch();
									}}
									total={metadata.total}
									limit={LIMIT}
									offset={offset}
									align="center"
								/>
							</Block>
						) : null}
					</Block>
				) : (
					<Flex space="ptl" justifyContent="center" direction="column" alignItems="center" gap="u2">
						<img alt="upsales briefcase" src="img/empty-briefcase.png" width={100} />
						<Title color="grey-10">{lang.noDuplicatesFound}</Title>
					</Flex>
				)}
			</ModalContent>
		</FullscreenModal>
	);
};

export default HandleDuplicates;
