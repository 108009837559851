import { Block, Label, Row } from '@upsales/components';
import BemClass from '@upsales/components/Utils/bemClass';
import React, { ReactNode } from 'react';

type Props = {
	title: string;
	classes?: string;
	children: ReactNode;
	topRight?: ReactNode;
} & React.ComponentProps<typeof Label>;

const LabeledInput = ({ classes, title, children, topRight, ...labelProps }: Props) => {
	const classNames = new BemClass('LabeledInput', classes ?? '');

	return (
		<Block className={classNames.b()} space="ptm pbl">
			<Row className={classNames.elem('label-row').b()}>
				<Label {...labelProps}>{title}</Label>
				{!!topRight && <div className={classNames.elem('top-right').b()}>{topRight}</div>}
			</Row>
			<div className={classNames.elem('content').b()}>{children}</div>
		</Block>
	);
};

export default LabeledInput;
