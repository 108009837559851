import React from 'react';
import { DrawerHeader, Block, ButtonSelect, Text, Column, Title, Button, Loader, Flex } from '@upsales/components';
import { connect } from 'react-redux';
import { removePinnedWidget, addPinnedWidget, setGoalPeriod, getWidgetData } from 'Store/actions/GoalsActions';
import bemClass from '@upsales/components/Utils/bemClass';
import T from 'Components/Helpers/translate';
import Widget from 'Components/Widget';
import './GoalSummaryDrawer.scss';
import PropTypes from 'prop-types';
import logError from 'Helpers/logError';

const mapStateToProps = state => ({
	userWidgets: state.Goals.userWidgets,
	teamWidgets: state.Goals.teamWidgets,
	pinnedWidgets: state.Goals.pinnedWidgets,
	showTeam: state.Goals.showTeam
});

const mapDispatchToProps = {
	removePinnedWidget,
	addPinnedWidget,
	setGoalPeriod,
	getWidgetData
};

class GoalSummaryDrawer extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			isLoaded: false,
			role: null,
			tab: 1
		};
		this.onTabChange = this.onTabChange.bind(this);
		this.onPinChange = this.onPinChange.bind(this);

		this.language = {
			goals: T('goals.goals'),
			yourGoals: T('goals.yourGoals'),
			teamGoals: T('goals.teamGoals'),
			noTargets: T('goals.noTargets'),
			noTargetsTeam: T('goals.noTargetsTeam'),
			noTargetsDescription: T('goals.noTargetsDescription'),
			setTargetsTeam: T('goals.setTargetsTeam'),
			setTargets: T('goals.setTargets')
		};

		this.isAdmin = Tools.AppService.getSelf().administrator;
	}

	componentDidMount() {
		const role = Tools.AppService.getSelf().role;
		const hasPersonalGoals = this.props.userWidgets?.length !== 0;
		const tab = this.props.showTeam && !hasPersonalGoals ? 2 : 1;

		this.props
			.getWidgetData()
			.then(() => {
				if (role) {
					this.setState({ isLoaded: true, role, tab });
				} else {
					this.setState({ isLoaded: true, tab });
				}
			})
			.catch(err => {
				logError(err);
			});
	}

	renderHeader() {
		const { role } = this.state;
		const { showTeam } = this.props;
		const hideButtonSelect = !role || !showTeam;
		if (hideButtonSelect) {
			return <DrawerHeader onHide={this.props.close} title={this.language.yourGoals}></DrawerHeader>;
		}
		const { tab } = this.state;
		const options = [
			{ title: this.language.yourGoals, value: 1 },
			{ title: this.language.teamGoals, value: 2 }
		];
		return (
			<DrawerHeader onHide={this.props.close}>
				<Column>
					<ButtonSelect options={options} value={tab} onChange={this.onTabChange}></ButtonSelect>
				</Column>
			</DrawerHeader>
		);
	}
	renderWidgets(classes) {
		const { userWidgets, teamWidgets, pinnedWidgets, close } = this.props;
		const { tab } = this.state;
		const widgetsToRender = tab !== 1 ? teamWidgets : userWidgets;
		if (widgetsToRender?.length) {
			return widgetsToRender.map(widget => (
				<Widget
					key={widget.data.type.name}
					type={widget.data.type.name}
					period={widget.period}
					title={widget.data.language.title}
					subtitle={widget.data.language.subTitle}
					size={'md'}
					currency={widget.data.currency}
					goal={widget.data.total.goal}
					goalLabel={widget.data.language.goal}
					surpassedGoalLabel={widget.data.language.overGoal}
					progress={widget.data.total.progress}
					progressLabel={widget.data.language.progress}
					remaining={widget.data.total ? widget.data.total.remaining : null}
					remainingLabel={
						widget.data.language
							? widget.data.total.goal > widget.data.total.progress
								? widget.data.language.remaining
								: T('report.surpassedQouta')
							: null
					}
					pinned={widget.pinned}
					onPinChange={this.onPinChange}
					canPin={pinnedWidgets?.length < 3}
					group={widget.group}
					onPeriodChange={this.props.setGoalPeriod}
				/>
			));
		} else {
			return (
				<Block className={classes.elem('no-targets')}>
					<img src="img/missingTargets.svg" />
					<Title color="black" size="md">
						{tab === 1 ? this.language.noTargets : this.language.noTargetsTeam}
					</Title>
					<Text>{this.language.noTargetsDescription}</Text>
					{this.isAdmin ? (
						<Button
							onClick={() => {
								Tools.$state.go('administration.salesquota', {});
								close();
							}}
						>
							{tab === 1 ? this.language.setTargets : this.language.setTargetsTeam}
						</Button>
					) : null}
				</Block>
			);
		}
	}

	onTabChange(val) {
		this.setState({ tab: val });
	}

	onPinChange(type, isPinned, group) {
		if (isPinned) {
			this.props.removePinnedWidget(type, group);
		} else {
			if (this.props.pinnedWidgets?.length < 3) {
				this.props.addPinnedWidget(type, group);
			}
		}
	}

	render() {
		const classes = new bemClass('GoalSummaryDrawer', this.props.className);
		const { tab, isLoaded, role } = this.state;
		return (
			<Block className={classes.b()}>
				{isLoaded ? this.renderHeader() : null}
				{isLoaded ? (
					<Block className={classes.elem('content-wrapper').b()}>
						{tab === 2 && role && this.props.teamWidgets?.length ? (
							<Text id="role-title">{role.name + ' ' + this.language.goals + ':'}</Text>
						) : null}
						{this.renderWidgets(classes)}
					</Block>
				) : (
					<Flex className={classes.elem('loader-wrapper').b()} justifyContent="center" alignItems="center">
						<Loader />
					</Flex>
				)}
			</Block>
		);
	}
}

GoalSummaryDrawer.propTypes = {
	className: PropTypes.string,
	close: PropTypes.func.isRequired,
	userWidgets: PropTypes.array,
	teamWidgets: PropTypes.array,
	pinnedWidgets: PropTypes.array,
	removePinnedWidget: PropTypes.func,
	addPinnedWidget: PropTypes.func,
	getWidgetData: PropTypes.func,
	showTeam: PropTypes.bool,
	setGoalPeriod: PropTypes.func
};

export const detached = GoalSummaryDrawer;

export default connect(mapStateToProps, mapDispatchToProps)(GoalSummaryDrawer);
