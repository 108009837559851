import { NumberFormat } from 'App/babel/utils/numberFormat';
import { DisplayValueType } from 'App/pages/CustomerPortfolio/CustomerPortfolio';
import { relativeRiskLookup } from 'App/pages/CustomerPortfolio/NumericCreditRatingColumn';
import CustomerPortfolioCompany from 'App/resources/Model/CustomerPortfolioCompany';
import T from 'Components/Helpers/translate';
import { numberFormat } from 'Components/Filters/Currencies';

interface CommentaryObject {
	code: number;
	languageTag: string;
	type: number;
}
export function getCommentariesObjectFromCodes(codes: number[]): CommentaryObject[] {
	const commentaryList: CommentaryObject[] = [
		{
			code: 620,
			languageTag: T('customerPortfolio.risks.drawer.commentaryText1'),
			type: 1
		},
		{
			code: 498,
			languageTag: T('customerPortfolio.risks.drawer.commentaryText2'),
			type: 0
		},
		{
			code: 452,
			languageTag: T('customerPortfolio.risks.drawer.commentaryText2'),
			type: 0
		},
		{
			code: 504,
			languageTag: T('customerPortfolio.risks.drawer.commentaryText2'),
			type: 0
		},

		{
			code: 388,
			languageTag: T('customerPortfolio.risks.drawer.commentaryText3'),
			type: -1
		},
		{
			code: 389,
			languageTag: T('customerPortfolio.risks.drawer.commentaryText3'),
			type: -1
		},

		{
			code: 341,
			languageTag: T('customerPortfolio.risks.drawer.commentaryText4'),
			type: 0
		},
		{
			code: 462,
			languageTag: T('customerPortfolio.risks.drawer.commentaryText4'),
			type: 0
		},
		{
			code: 494,
			languageTag: T('customerPortfolio.risks.drawer.commentaryText4'),
			type: 0
		},
		{
			code: 479,
			languageTag: T('customerPortfolio.risks.drawer.commentaryText4'),
			type: 0
		},
		{
			code: 542,
			languageTag: T('customerPortfolio.risks.drawer.commentaryText4'),
			type: 0
		},
		{
			code: 594,
			languageTag: T('customerPortfolio.risks.drawer.commentaryText4'),
			type: 0
		},

		{
			code: 342,
			languageTag: T('customerPortfolio.risks.drawer.commentaryText5'),
			type: -1
		},
		{
			code: 343,
			languageTag: T('customerPortfolio.risks.drawer.commentaryText5'),
			type: -1
		},
		{
			code: 363,
			languageTag: T('customerPortfolio.risks.drawer.commentaryText5'),
			type: -1
		},
		{
			code: 364,
			languageTag: T('customerPortfolio.risks.drawer.commentaryText5'),
			type: -1
		},
		{
			code: 395,
			languageTag: T('customerPortfolio.risks.drawer.commentaryText5'),
			type: -1
		},
		{
			code: 396,
			languageTag: T('customerPortfolio.risks.drawer.commentaryText5'),
			type: -1
		},
		{
			code: 480,
			languageTag: T('customerPortfolio.risks.drawer.commentaryText5'),
			type: -1
		},
		{
			code: 481,
			languageTag: T('customerPortfolio.risks.drawer.commentaryText5'),
			type: -1
		},
		{
			code: 543,
			languageTag: T('customerPortfolio.risks.drawer.commentaryText5'),
			type: -1
		},
		{
			code: 544,
			languageTag: T('customerPortfolio.risks.drawer.commentaryText5'),
			type: -1
		},
		{
			code: 595,
			languageTag: T('customerPortfolio.risks.drawer.commentaryText5'),
			type: -1
		},
		{
			code: 596,
			languageTag: T('customerPortfolio.risks.drawer.commentaryText5'),
			type: -1
		},

		{
			code: 334,
			languageTag: T('customerPortfolio.risks.drawer.commentaryText6'),
			type: -1
		},
		{
			code: 335,
			languageTag: T('customerPortfolio.risks.drawer.commentaryText6'),
			type: -1
		},
		{
			code: 336,
			languageTag: T('customerPortfolio.risks.drawer.commentaryText6'),
			type: -1
		},

		{
			code: 352,
			languageTag: T('customerPortfolio.risks.drawer.commentaryText7'),
			type: 0
		},
		{
			code: 353,
			languageTag: T('customerPortfolio.risks.drawer.commentaryText7'),
			type: 0
		},
		{
			code: 413,
			languageTag: T('customerPortfolio.risks.drawer.commentaryText7'),
			type: 0
		},
		{
			code: 414,
			languageTag: T('customerPortfolio.risks.drawer.commentaryText7'),
			type: 0
		},
		{
			code: 469,
			languageTag: T('customerPortfolio.risks.drawer.commentaryText7'),
			type: 0
		},
		{
			code: 470,
			languageTag: T('customerPortfolio.risks.drawer.commentaryText7'),
			type: 0
		},
		{
			code: 532,
			languageTag: T('customerPortfolio.risks.drawer.commentaryText7'),
			type: 0
		},
		{
			code: 533,
			languageTag: T('customerPortfolio.risks.drawer.commentaryText7'),
			type: 0
		},
		{
			code: 584,
			languageTag: T('customerPortfolio.risks.drawer.commentaryText7'),
			type: 0
		},
		{
			code: 585,
			languageTag: T('customerPortfolio.risks.drawer.commentaryText7'),
			type: 0
		},

		{
			code: 358,
			languageTag: T('customerPortfolio.risks.drawer.commentaryText8'),
			type: 0
		},
		{
			code: 359,
			languageTag: T('customerPortfolio.risks.drawer.commentaryText8'),
			type: 0
		},
		{
			code: 419,
			languageTag: T('customerPortfolio.risks.drawer.commentaryText8'),
			type: 0
		},
		{
			code: 420,
			languageTag: T('customerPortfolio.risks.drawer.commentaryText8'),
			type: 0
		},
		{
			code: 475,
			languageTag: T('customerPortfolio.risks.drawer.commentaryText8'),
			type: 0
		},
		{
			code: 476,
			languageTag: T('customerPortfolio.risks.drawer.commentaryText8'),
			type: 0
		},
		{
			code: 538,
			languageTag: T('customerPortfolio.risks.drawer.commentaryText8'),
			type: 0
		},
		{
			code: 539,
			languageTag: T('customerPortfolio.risks.drawer.commentaryText8'),
			type: 0
		},
		{
			code: 590,
			languageTag: T('customerPortfolio.risks.drawer.commentaryText8'),
			type: 0
		},
		{
			code: 591,
			languageTag: T('customerPortfolio.risks.drawer.commentaryText8'),
			type: 0
		},

		{
			code: 338,
			languageTag: T('customerPortfolio.risks.drawer.commentaryText9'),
			type: -1
		},
		{
			code: 371,
			languageTag: T('customerPortfolio.risks.drawer.commentaryText9'),
			type: -1
		},
		{
			code: 428,
			languageTag: T('customerPortfolio.risks.drawer.commentaryText9'),
			type: -1
		},
		{
			code: 488,
			languageTag: T('customerPortfolio.risks.drawer.commentaryText9'),
			type: -1
		},
		{
			code: 551,
			languageTag: T('customerPortfolio.risks.drawer.commentaryText9'),
			type: -1
		},

		{
			code: 378,
			languageTag: T('customerPortfolio.risks.drawer.commentaryText10'),
			type: 1
		},
		{
			code: 379,
			languageTag: T('customerPortfolio.risks.drawer.commentaryText10'),
			type: 1
		},
		{
			code: 434,
			languageTag: T('customerPortfolio.risks.drawer.commentaryText10'),
			type: 1
		},
		{
			code: 435,
			languageTag: T('customerPortfolio.risks.drawer.commentaryText10'),
			type: 1
		},
		{
			code: 495,
			languageTag: T('customerPortfolio.risks.drawer.commentaryText10'),
			type: 1
		},
		{
			code: 496,
			languageTag: T('customerPortfolio.risks.drawer.commentaryText10'),
			type: 1
		},
		{
			code: 558,
			languageTag: T('customerPortfolio.risks.drawer.commentaryText10'),
			type: 1
		},
		{
			code: 559,
			languageTag: T('customerPortfolio.risks.drawer.commentaryText10'),
			type: 1
		},
		{
			code: 603,
			languageTag: T('customerPortfolio.risks.drawer.commentaryText10'),
			type: 1
		},

		{
			code: 392,
			languageTag: T('customerPortfolio.risks.drawer.commentaryText11'),
			type: 1
		},

		{
			code: 391,
			languageTag: T('customerPortfolio.risks.drawer.commentaryText12'),
			type: 0
		},

		{
			code: 376,
			languageTag: T('customerPortfolio.risks.drawer.commentaryText13'),
			type: 0
		},
		{
			code: 377,
			languageTag: T('customerPortfolio.risks.drawer.commentaryText13'),
			type: 0
		},
		{
			code: 433,
			languageTag: T('customerPortfolio.risks.drawer.commentaryText13'),
			type: 0
		},
		{
			code: 494,
			languageTag: T('customerPortfolio.risks.drawer.commentaryText13'),
			type: 0
		},
		{
			code: 557,
			languageTag: T('customerPortfolio.risks.drawer.commentaryText13'),
			type: 0
		},
		{
			code: 602,
			languageTag: T('customerPortfolio.risks.drawer.commentaryText13'),
			type: 0
		},

		{
			code: 447,
			languageTag: T('customerPortfolio.risks.drawer.commentaryText14'),
			type: 1
		},
		{
			code: 448,
			languageTag: T('customerPortfolio.risks.drawer.commentaryText14'),
			type: 1
		},
		{
			code: 449,
			languageTag: T('customerPortfolio.risks.drawer.commentaryText14'),
			type: 1
		},
		{
			code: 450,
			languageTag: T('customerPortfolio.risks.drawer.commentaryText14'),
			type: 1
		},
		{
			code: 509,
			languageTag: T('customerPortfolio.risks.drawer.commentaryText14'),
			type: 1
		},
		{
			code: 510,
			languageTag: T('customerPortfolio.risks.drawer.commentaryText14'),
			type: 1
		},
		{
			code: 511,
			languageTag: T('customerPortfolio.risks.drawer.commentaryText14'),
			type: 1
		},
		{
			code: 512,
			languageTag: T('customerPortfolio.risks.drawer.commentaryText14'),
			type: 1
		},
		{
			code: 574,
			languageTag: T('customerPortfolio.risks.drawer.commentaryText14'),
			type: 1
		},
		{
			code: 575,
			languageTag: T('customerPortfolio.risks.drawer.commentaryText14'),
			type: 1
		},
		{
			code: 576,
			languageTag: T('customerPortfolio.risks.drawer.commentaryText14'),
			type: 1
		},
		{
			code: 612,
			languageTag: T('customerPortfolio.risks.drawer.commentaryText14'),
			type: 1
		},
		{
			code: 613,
			languageTag: T('customerPortfolio.risks.drawer.commentaryText14'),
			type: 1
		},

		{
			code: 331,
			languageTag: T('customerPortfolio.risks.drawer.commentaryText15'),
			type: -1
		},
		{
			code: 332,
			languageTag: T('customerPortfolio.risks.drawer.commentaryText15'),
			type: -1
		},

		{
			code: 339,
			languageTag: T('customerPortfolio.risks.drawer.commentaryText16'),
			type: 1
		},
		{
			code: 372,
			languageTag: T('customerPortfolio.risks.drawer.commentaryText16'),
			type: 1
		},
		{
			code: 429,
			languageTag: T('customerPortfolio.risks.drawer.commentaryText16'),
			type: 1
		},
		{
			code: 489,
			languageTag: T('customerPortfolio.risks.drawer.commentaryText16'),
			type: 1
		},
		{
			code: 552,
			languageTag: T('customerPortfolio.risks.drawer.commentaryText16'),
			type: 1
		},

		{
			code: 619,
			languageTag: T('customerPortfolio.risks.drawer.commentaryText17'),
			type: 1
		},
		{
			code: 720,
			languageTag: T('customerPortfolio.risks.drawer.commentaryText17'),
			type: 1
		},

		{
			code: 333,
			languageTag: T('customerPortfolio.risks.drawer.commentaryText18'),
			type: 1
		},

		{
			code: 349,
			languageTag: T('customerPortfolio.risks.drawer.commentaryText19'),
			type: 1
		},
		{
			code: 410,
			languageTag: T('customerPortfolio.risks.drawer.commentaryText19'),
			type: 1
		},
		{
			code: 466,
			languageTag: T('customerPortfolio.risks.drawer.commentaryText19'),
			type: 1
		},
		{
			code: 529,
			languageTag: T('customerPortfolio.risks.drawer.commentaryText19'),
			type: 1
		},
		{
			code: 581,
			languageTag: T('customerPortfolio.risks.drawer.commentaryText19'),
			type: 1
		},

		{
			code: 355,
			languageTag: T('customerPortfolio.risks.drawer.commentaryText20'),
			type: 1
		},
		{
			code: 416,
			languageTag: T('customerPortfolio.risks.drawer.commentaryText20'),
			type: 1
		},
		{
			code: 472,
			languageTag: T('customerPortfolio.risks.drawer.commentaryText20'),
			type: 1
		},
		{
			code: 535,
			languageTag: T('customerPortfolio.risks.drawer.commentaryText20'),
			type: 1
		},
		{
			code: 587,
			languageTag: T('customerPortfolio.risks.drawer.commentaryText20'),
			type: 1
		},

		{
			code: 354,
			languageTag: T('customerPortfolio.risks.drawer.commentaryText21'),
			type: 1
		},
		{
			code: 415,
			languageTag: T('customerPortfolio.risks.drawer.commentaryText21'),
			type: 1
		},
		{
			code: 471,
			languageTag: T('customerPortfolio.risks.drawer.commentaryText21'),
			type: 1
		},
		{
			code: 534,
			languageTag: T('customerPortfolio.risks.drawer.commentaryText21'),
			type: 1
		},
		{
			code: 586,
			languageTag: T('customerPortfolio.risks.drawer.commentaryText21'),
			type: 1
		},

		{
			code: 360,
			languageTag: T('customerPortfolio.risks.drawer.commentaryText22'),
			type: 1
		},
		{
			code: 421,
			languageTag: T('customerPortfolio.risks.drawer.commentaryText22'),
			type: 1
		},
		{
			code: 477,
			languageTag: T('customerPortfolio.risks.drawer.commentaryText22'),
			type: 1
		},
		{
			code: 540,
			languageTag: T('customerPortfolio.risks.drawer.commentaryText22'),
			type: 1
		},
		{
			code: 592,
			languageTag: T('customerPortfolio.risks.drawer.commentaryText22'),
			type: 1
		},

		{
			code: 368,
			languageTag: T('customerPortfolio.risks.drawer.commentaryText23'),
			type: -1
		},
		{
			code: 425,
			languageTag: T('customerPortfolio.risks.drawer.commentaryText23'),
			type: -1
		},
		{
			code: 485,
			languageTag: T('customerPortfolio.risks.drawer.commentaryText23'),
			type: -1
		},
		{
			code: 548,
			languageTag: T('customerPortfolio.risks.drawer.commentaryText23'),
			type: -1
		},

		{
			code: 390,
			languageTag: T('customerPortfolio.risks.drawer.commentaryText24'),
			type: 0
		},

		{
			code: 330,
			languageTag: T('customerPortfolio.risks.drawer.commentaryText25'),
			type: 1
		},

		{
			code: 344,
			languageTag: T('customerPortfolio.risks.drawer.commentaryText26'),
			type: 1
		},
		{
			code: 403,
			languageTag: T('customerPortfolio.risks.drawer.commentaryText26'),
			type: 1
		},
		{
			code: 458,
			languageTag: T('customerPortfolio.risks.drawer.commentaryText26'),
			type: 1
		},
		{
			code: 520,
			languageTag: T('customerPortfolio.risks.drawer.commentaryText26'),
			type: 1
		},
		{
			code: 577,
			languageTag: T('customerPortfolio.risks.drawer.commentaryText26'),
			type: 1
		},

		{
			code: 347,
			languageTag: T('customerPortfolio.risks.drawer.commentaryText27'),
			type: 1
		},
		{
			code: 407,
			languageTag: T('customerPortfolio.risks.drawer.commentaryText27'),
			type: 1
		},
		{
			code: 463,
			languageTag: T('customerPortfolio.risks.drawer.commentaryText27'),
			type: 1
		},
		{
			code: 525,
			languageTag: T('customerPortfolio.risks.drawer.commentaryText27'),
			type: 1
		},
		{
			code: 579,
			languageTag: T('customerPortfolio.risks.drawer.commentaryText27'),
			type: 1
		},

		{
			code: 622,
			languageTag: T('customerPortfolio.risks.drawer.commentaryText28'),
			type: 0
		},
		{
			code: 624,
			languageTag: T('customerPortfolio.risks.drawer.commentaryText28'),
			type: 0
		},
		{
			code: 625,
			languageTag: T('customerPortfolio.risks.drawer.commentaryText28'),
			type: 0
		},
		{
			code: 626,
			languageTag: T('customerPortfolio.risks.drawer.commentaryText28'),
			type: 0
		},
		{
			code: 627,
			languageTag: T('customerPortfolio.risks.drawer.commentaryText28'),
			type: 0
		},
		{
			code: 628,
			languageTag: T('customerPortfolio.risks.drawer.commentaryText28'),
			type: 0
		},

		{
			code: 340,
			languageTag: T('customerPortfolio.risks.drawer.commentaryText29'),
			type: 1
		},
		{
			code: 361,
			languageTag: T('customerPortfolio.risks.drawer.commentaryText29'),
			type: 1
		},
		{
			code: 393,
			languageTag: T('customerPortfolio.risks.drawer.commentaryText29'),
			type: 1
		},
		{
			code: 478,
			languageTag: T('customerPortfolio.risks.drawer.commentaryText29'),
			type: 1
		},
		{
			code: 541,
			languageTag: T('customerPortfolio.risks.drawer.commentaryText29'),
			type: 1
		},
		{
			code: 593,
			languageTag: T('customerPortfolio.risks.drawer.commentaryText29'),
			type: 1
		},

		{
			code: 446,
			languageTag: T('customerPortfolio.risks.drawer.commentaryText30'),
			type: 0
		},
		{
			code: 507,
			languageTag: T('customerPortfolio.risks.drawer.commentaryText30'),
			type: 0
		},
		{
			code: 570,
			languageTag: T('customerPortfolio.risks.drawer.commentaryText30'),
			type: 0
		},
		{
			code: 610,
			languageTag: T('customerPortfolio.risks.drawer.commentaryText30'),
			type: 0
		},

		{
			code: 374,
			languageTag: T('customerPortfolio.risks.drawer.commentaryText31'),
			type: -1
		},
		{
			code: 375,
			languageTag: T('customerPortfolio.risks.drawer.commentaryText31'),
			type: -1
		},
		{
			code: 431,
			languageTag: T('customerPortfolio.risks.drawer.commentaryText31'),
			type: -1
		},
		{
			code: 432,
			languageTag: T('customerPortfolio.risks.drawer.commentaryText31'),
			type: -1
		},
		{
			code: 491,
			languageTag: T('customerPortfolio.risks.drawer.commentaryText31'),
			type: -1
		},
		{
			code: 492,
			languageTag: T('customerPortfolio.risks.drawer.commentaryText31'),
			type: -1
		},
		{
			code: 493,
			languageTag: T('customerPortfolio.risks.drawer.commentaryText31'),
			type: -1
		},
		{
			code: 554,
			languageTag: T('customerPortfolio.risks.drawer.commentaryText31'),
			type: -1
		},
		{
			code: 555,
			languageTag: T('customerPortfolio.risks.drawer.commentaryText31'),
			type: -1
		},
		{
			code: 556,
			languageTag: T('customerPortfolio.risks.drawer.commentaryText31'),
			type: -1
		},
		{
			code: 600,
			languageTag: T('customerPortfolio.risks.drawer.commentaryText31'),
			type: -1
		},
		{
			code: 601,
			languageTag: T('customerPortfolio.risks.drawer.commentaryText31'),
			type: -1
		},

		{
			code: 508,
			languageTag: T('customerPortfolio.risks.drawer.commentaryText32'),
			type: 0
		},
		{
			code: 571,
			languageTag: T('customerPortfolio.risks.drawer.commentaryText32'),
			type: 0
		},
		{
			code: 572,
			languageTag: T('customerPortfolio.risks.drawer.commentaryText32'),
			type: 0
		},
		{
			code: 573,
			languageTag: T('customerPortfolio.risks.drawer.commentaryText32'),
			type: 0
		},
		{
			code: 611,
			languageTag: T('customerPortfolio.risks.drawer.commentaryText32'),
			type: 0
		},

		{
			code: 615,
			languageTag: T('customerPortfolio.risks.drawer.commentaryText33'),
			type: -1
		},
		{
			code: 616,
			languageTag: T('customerPortfolio.risks.drawer.commentaryText33'),
			type: -1
		},

		{
			code: 617,
			languageTag: T('customerPortfolio.risks.drawer.commentaryText34'),
			type: 0
		},
		{
			code: 618,
			languageTag: T('customerPortfolio.risks.drawer.commentaryText35'),
			type: 0
		},

		{
			code: 443,
			languageTag: T('customerPortfolio.risks.drawer.commentaryText35'),
			type: -1
		},
		{
			code: 444,
			languageTag: T('customerPortfolio.risks.drawer.commentaryText35'),
			type: -1
		},
		{
			code: 445,
			languageTag: T('customerPortfolio.risks.drawer.commentaryText35'),
			type: -1
		},
		{
			code: 504,
			languageTag: T('customerPortfolio.risks.drawer.commentaryText35'),
			type: -1
		},
		{
			code: 505,
			languageTag: T('customerPortfolio.risks.drawer.commentaryText35'),
			type: -1
		},
		{
			code: 506,
			languageTag: T('customerPortfolio.risks.drawer.commentaryText35'),
			type: -1
		},
		{
			code: 567,
			languageTag: T('customerPortfolio.risks.drawer.commentaryText35'),
			type: -1
		},
		{
			code: 568,
			languageTag: T('customerPortfolio.risks.drawer.commentaryText35'),
			type: -1
		},
		{
			code: 569,
			languageTag: T('customerPortfolio.risks.drawer.commentaryText35'),
			type: -1
		},
		{
			code: 608,
			languageTag: T('customerPortfolio.risks.drawer.commentaryText35'),
			type: -1
		},
		{
			code: 609,
			languageTag: T('customerPortfolio.risks.drawer.commentaryText35'),
			type: -1
		},

		{
			code: 350,
			languageTag: T('customerPortfolio.risks.drawer.commentaryText36'),
			type: -1
		},
		{
			code: 351,
			languageTag: T('customerPortfolio.risks.drawer.commentaryText36'),
			type: -1
		},
		{
			code: 411,
			languageTag: T('customerPortfolio.risks.drawer.commentaryText36'),
			type: -1
		},
		{
			code: 412,
			languageTag: T('customerPortfolio.risks.drawer.commentaryText36'),
			type: -1
		},
		{
			code: 467,
			languageTag: T('customerPortfolio.risks.drawer.commentaryText36'),
			type: -1
		},
		{
			code: 468,
			languageTag: T('customerPortfolio.risks.drawer.commentaryText36'),
			type: -1
		},
		{
			code: 530,
			languageTag: T('customerPortfolio.risks.drawer.commentaryText36'),
			type: -1
		},
		{
			code: 531,
			languageTag: T('customerPortfolio.risks.drawer.commentaryText36'),
			type: -1
		},
		{
			code: 582,
			languageTag: T('customerPortfolio.risks.drawer.commentaryText36'),
			type: -1
		},
		{
			code: 583,
			languageTag: T('customerPortfolio.risks.drawer.commentaryText36'),
			type: -1
		},

		{
			code: 356,
			languageTag: T('customerPortfolio.risks.drawer.commentaryText37'),
			type: -1
		},
		{
			code: 357,
			languageTag: T('customerPortfolio.risks.drawer.commentaryText37'),
			type: -1
		},
		{
			code: 417,
			languageTag: T('customerPortfolio.risks.drawer.commentaryText37'),
			type: -1
		},
		{
			code: 418,
			languageTag: T('customerPortfolio.risks.drawer.commentaryText37'),
			type: -1
		},
		{
			code: 473,
			languageTag: T('customerPortfolio.risks.drawer.commentaryText37'),
			type: -1
		},
		{
			code: 474,
			languageTag: T('customerPortfolio.risks.drawer.commentaryText37'),
			type: -1
		},
		{
			code: 536,
			languageTag: T('customerPortfolio.risks.drawer.commentaryText37'),
			type: -1
		},
		{
			code: 537,
			languageTag: T('customerPortfolio.risks.drawer.commentaryText37'),
			type: -1
		},
		{
			code: 588,
			languageTag: T('customerPortfolio.risks.drawer.commentaryText37'),
			type: -1
		},
		{
			code: 589,
			languageTag: T('customerPortfolio.risks.drawer.commentaryText37'),
			type: -1
		},

		{
			code: 369,
			languageTag: T('customerPortfolio.risks.drawer.commentaryText38'),
			type: 1
		},
		{
			code: 426,
			languageTag: T('customerPortfolio.risks.drawer.commentaryText38'),
			type: 1
		},
		{
			code: 486,
			languageTag: T('customerPortfolio.risks.drawer.commentaryText38'),
			type: 1
		},
		{
			code: 549,
			languageTag: T('customerPortfolio.risks.drawer.commentaryText38'),
			type: 1
		},

		{
			code: 345,
			languageTag: T('customerPortfolio.risks.drawer.commentaryText39'),
			type: -1
		},
		{
			code: 346,
			languageTag: T('customerPortfolio.risks.drawer.commentaryText39'),
			type: -1
		},
		{
			code: 404,
			languageTag: T('customerPortfolio.risks.drawer.commentaryText39'),
			type: -1
		},
		{
			code: 405,
			languageTag: T('customerPortfolio.risks.drawer.commentaryText39'),
			type: -1
		},
		{
			code: 406,
			languageTag: T('customerPortfolio.risks.drawer.commentaryText39'),
			type: -1
		},
		{
			code: 459,
			languageTag: T('customerPortfolio.risks.drawer.commentaryText39'),
			type: -1
		},
		{
			code: 460,
			languageTag: T('customerPortfolio.risks.drawer.commentaryText39'),
			type: -1
		},
		{
			code: 461,
			languageTag: T('customerPortfolio.risks.drawer.commentaryText39'),
			type: -1
		},
		{
			code: 462,
			languageTag: T('customerPortfolio.risks.drawer.commentaryText39'),
			type: -1
		},
		{
			code: 521,
			languageTag: T('customerPortfolio.risks.drawer.commentaryText39'),
			type: -1
		},
		{
			code: 522,
			languageTag: T('customerPortfolio.risks.drawer.commentaryText39'),
			type: -1
		},
		{
			code: 523,
			languageTag: T('customerPortfolio.risks.drawer.commentaryText39'),
			type: -1
		},
		{
			code: 524,
			languageTag: T('customerPortfolio.risks.drawer.commentaryText39'),
			type: -1
		},
		{
			code: 578,
			languageTag: T('customerPortfolio.risks.drawer.commentaryText39'),
			type: -1
		},

		{
			code: 348,
			languageTag: T('customerPortfolio.risks.drawer.commentaryText40'),
			type: -1
		},
		{
			code: 408,
			languageTag: T('customerPortfolio.risks.drawer.commentaryText40'),
			type: -1
		},
		{
			code: 409,
			languageTag: T('customerPortfolio.risks.drawer.commentaryText40'),
			type: -1
		},
		{
			code: 464,
			languageTag: T('customerPortfolio.risks.drawer.commentaryText40'),
			type: -1
		},
		{
			code: 465,
			languageTag: T('customerPortfolio.risks.drawer.commentaryText40'),
			type: -1
		},
		{
			code: 526,
			languageTag: T('customerPortfolio.risks.drawer.commentaryText40'),
			type: -1
		},
		{
			code: 527,
			languageTag: T('customerPortfolio.risks.drawer.commentaryText40'),
			type: -1
		},
		{
			code: 528,
			languageTag: T('customerPortfolio.risks.drawer.commentaryText40'),
			type: -1
		},
		{
			code: 580,
			languageTag: T('customerPortfolio.risks.drawer.commentaryText40'),
			type: -1
		},

		{
			code: 623,
			languageTag: T('customerPortfolio.risks.drawer.commentaryText41'),
			type: 0
		},
		{
			code: 629,
			languageTag: T('customerPortfolio.risks.drawer.commentaryText41'),
			type: 0
		},
		{
			code: 630,
			languageTag: T('customerPortfolio.risks.drawer.commentaryText41'),
			type: 0
		},
		{
			code: 631,
			languageTag: T('customerPortfolio.risks.drawer.commentaryText41'),
			type: 0
		},
		{
			code: 632,
			languageTag: T('customerPortfolio.risks.drawer.commentaryText41'),
			type: 0
		},
		{
			code: 633,
			languageTag: T('customerPortfolio.risks.drawer.commentaryText41'),
			type: 0
		},

		{
			code: 614,
			languageTag: T('customerPortfolio.risks.drawer.commentaryText42'),
			type: -1
		},

		{
			code: 387,
			languageTag: T('customerPortfolio.risks.drawer.commentaryText43'),
			type: -1
		},

		{
			code: 644,
			languageTag: T('customerPortfolio.risks.drawer.commentaryText44'),
			type: -1
		},
		{
			code: 645,
			languageTag: T('customerPortfolio.risks.drawer.commentaryText44'),
			type: -1
		},
		{
			code: 646,
			languageTag: T('customerPortfolio.risks.drawer.commentaryText44'),
			type: -1
		},
		{
			code: 647,
			languageTag: T('customerPortfolio.risks.drawer.commentaryText44'),
			type: -1
		},
		{
			code: 648,
			languageTag: T('customerPortfolio.risks.drawer.commentaryText44'),
			type: -1
		},
		{
			code: 649,
			languageTag: T('customerPortfolio.risks.drawer.commentaryText44'),
			type: -1
		},

		{
			code: 337,
			languageTag: T('customerPortfolio.risks.drawer.commentaryText45'),
			type: -1
		},
		{
			code: 370,
			languageTag: T('customerPortfolio.risks.drawer.commentaryText45'),
			type: -1
		},
		{
			code: 427,
			languageTag: T('customerPortfolio.risks.drawer.commentaryText45'),
			type: -1
		},
		{
			code: 487,
			languageTag: T('customerPortfolio.risks.drawer.commentaryText45'),
			type: -1
		},
		{
			code: 550,
			languageTag: T('customerPortfolio.risks.drawer.commentaryText45'),
			type: -1
		},

		{
			code: 639,
			languageTag: T('customerPortfolio.risks.drawer.commentaryText46'),
			type: -1
		},
		{
			code: 640,
			languageTag: T('customerPortfolio.risks.drawer.commentaryText46'),
			type: -1
		},
		{
			code: 641,
			languageTag: T('customerPortfolio.risks.drawer.commentaryText46'),
			type: -1
		},
		{
			code: 642,
			languageTag: T('customerPortfolio.risks.drawer.commentaryText46'),
			type: -1
		},
		{
			code: 643,
			languageTag: T('customerPortfolio.risks.drawer.commentaryText46'),
			type: -1
		},

		{
			code: 373,
			languageTag: T('customerPortfolio.risks.drawer.commentaryText47'),
			type: -1
		},
		{
			code: 430,
			languageTag: T('customerPortfolio.risks.drawer.commentaryText47'),
			type: -1
		},
		{
			code: 490,
			languageTag: T('customerPortfolio.risks.drawer.commentaryText47'),
			type: -1
		},
		{
			code: 553,
			languageTag: T('customerPortfolio.risks.drawer.commentaryText47'),
			type: -1
		},
		{
			code: 599,
			languageTag: T('customerPortfolio.risks.drawer.commentaryText47'),
			type: -1
		},

		{
			code: 365,
			languageTag: T('customerPortfolio.risks.drawer.commentaryText48'),
			type: 1
		},
		{
			code: 422,
			languageTag: T('customerPortfolio.risks.drawer.commentaryText48'),
			type: 1
		},
		{
			code: 482,
			languageTag: T('customerPortfolio.risks.drawer.commentaryText48'),
			type: 1
		},
		{
			code: 545,
			languageTag: T('customerPortfolio.risks.drawer.commentaryText48'),
			type: 1
		},
		{
			code: 597,
			languageTag: T('customerPortfolio.risks.drawer.commentaryText48'),
			type: 1
		},

		{
			code: 366,
			languageTag: T('customerPortfolio.risks.drawer.commentaryText49'),
			type: 0
		},
		{
			code: 423,
			languageTag: T('customerPortfolio.risks.drawer.commentaryText49'),
			type: 0
		},
		{
			code: 483,
			languageTag: T('customerPortfolio.risks.drawer.commentaryText49'),
			type: 0
		},
		{
			code: 546,
			languageTag: T('customerPortfolio.risks.drawer.commentaryText49'),
			type: 0
		},
		{
			code: 598,
			languageTag: T('customerPortfolio.risks.drawer.commentaryText49'),
			type: 0
		},

		{
			code: 367,
			languageTag: T('customerPortfolio.risks.drawer.commentaryText50'),
			type: -1
		},
		{
			code: 424,
			languageTag: T('customerPortfolio.risks.drawer.commentaryText50'),
			type: -1
		},
		{
			code: 484,
			languageTag: T('customerPortfolio.risks.drawer.commentaryText50'),
			type: -1
		},
		{
			code: 547,
			languageTag: T('customerPortfolio.risks.drawer.commentaryText50'),
			type: -1
		},

		{
			code: 381,
			languageTag: T('customerPortfolio.risks.drawer.commentaryText51'),
			type: -1
		},
		{
			code: 382,
			languageTag: T('customerPortfolio.risks.drawer.commentaryText51'),
			type: -1
		},
		{
			code: 437,
			languageTag: T('customerPortfolio.risks.drawer.commentaryText51'),
			type: -1
		},
		{
			code: 438,
			languageTag: T('customerPortfolio.risks.drawer.commentaryText51'),
			type: -1
		},
		{
			code: 498,
			languageTag: T('customerPortfolio.risks.drawer.commentaryText51'),
			type: -1
		},
		{
			code: 499,
			languageTag: T('customerPortfolio.risks.drawer.commentaryText51'),
			type: -1
		},
		{
			code: 561,
			languageTag: T('customerPortfolio.risks.drawer.commentaryText51'),
			type: -1
		},
		{
			code: 562,
			languageTag: T('customerPortfolio.risks.drawer.commentaryText51'),
			type: -1
		},
		{
			code: 605,
			languageTag: T('customerPortfolio.risks.drawer.commentaryText51'),
			type: -1
		},
		{
			code: 606,
			languageTag: T('customerPortfolio.risks.drawer.commentaryText51'),
			type: -1
		},
		{
			code: 634,
			languageTag: T('customerPortfolio.risks.drawer.commentaryText51'),
			type: -1
		},
		{
			code: 635,
			languageTag: T('customerPortfolio.risks.drawer.commentaryText51'),
			type: -1
		},
		{
			code: 636,
			languageTag: T('customerPortfolio.risks.drawer.commentaryText51'),
			type: -1
		},
		{
			code: 637,
			languageTag: T('customerPortfolio.risks.drawer.commentaryText51'),
			type: -1
		},
		{
			code: 638,
			languageTag: T('customerPortfolio.risks.drawer.commentaryText51'),
			type: -1
		},

		{
			code: 380,
			languageTag: T('customerPortfolio.risks.drawer.commentaryText52'),
			type: 1
		},
		{
			code: 436,
			languageTag: T('customerPortfolio.risks.drawer.commentaryText52'),
			type: 1
		},
		{
			code: 497,
			languageTag: T('customerPortfolio.risks.drawer.commentaryText52'),
			type: 1
		},
		{
			code: 560,
			languageTag: T('customerPortfolio.risks.drawer.commentaryText52'),
			type: 1
		},
		{
			code: 604,
			languageTag: T('customerPortfolio.risks.drawer.commentaryText52'),
			type: 1
		}
	];

	// Filter objects based on the provided codes
	const filteredObjects = commentaryList.filter(obj => codes.includes(obj.code));
	return filteredObjects;
}

// This is being used to return icon colour for 2 different data values in Risk Drawer
export const getIconColor = (arg: string | number) => {
	switch (arg) {
		case 'low':
			return 'success-5';
		case 'medium':
			return 'yellow-4';
		case 'high':
			return 'danger-4';
		case 1:
			return 'success-5';
		case 0:
			return 'yellow-4';
		case -1:
			return 'danger-4';
		default:
			return 'grey-11';
	}
};

export const getBackgroundColor = (arg: string | null) => {
	if (arg === null) {
		return 'grey-2';
	}

	switch (arg) {
		case 'A':
		case 'B':
		case 'C':
			return 'success-1';
		case 'D':
			return 'yellow-1';
		case 'E':
		case 'F':
			return 'danger-1';
		default:
			return 'grey-2';
	}
};

const negativRatingRiskDrawerLangTags: {
	[key: string]: string;
} = {
	'-1': 'customerPortfolio.risks.drawer.negativerating.bankruptcy',
	'-2': 'customerPortfolio.risks.drawer.negativerating.noAccount',
	'-6': 'customerPortfolio.risks.drawer.negativerating.delisted',
	'-8': 'customerPortfolio.risks.drawer.negativerating.distraint',
	'-9': 'customerPortfolio.risks.drawer.negativerating.storageCompany',
	'-11': 'customerPortfolio.risks.drawer.negativerating.liquidation',
	'-12': 'customerPortfolio.risks.drawer.negativerating.dissolved',
	'-15': 'customerPortfolio.risks.drawer.negativerating.reconstruction',
	'-17': 'customerPortfolio.risks.drawer.negativerating.badDebt',
	'-28': 'customerPortfolio.risks.drawer.negativerating.deficiencyInAccount',
	'-29': 'customerPortfolio.risks.drawer.negativerating.bankruptcyApplication',
	'-32': 'customerPortfolio.risks.drawer.negativerating.notActive',
	'-49': 'customerPortfolio.risks.drawer.negativerating.ceoMissing',
	'-52': 'customerPortfolio.risks.drawer.negativerating.auditorMissing',
	'-97': 'customerPortfolio.risks.drawer.negativerating.missingData'
};

const getNegativeRatingTitle = (company: CustomerPortfolioCompany) => {
	if (company.prospectingNumericCreditRating == null) {
		return;
	}
	const negativeRatingTitle = T(
		negativRatingRiskDrawerLangTags[company.prospectingNumericCreditRating] ?? 'customerPortfolio.risks.missingData'
	);
	return negativeRatingTitle;
};

export const getPositiveRatingTitle = (company: CustomerPortfolioCompany) => {
	if (company.prospectingNumericCreditRating == null) {
		return;
	}
	const riskInterval = relativeRiskLookup[company.prospectingNumericCreditRating];
	const numberFormat = new NumberFormat(undefined, 2);
	const percentageRange = `${numberFormat.default(riskInterval[0])}% - ${numberFormat.default(riskInterval[1])}%`;

	const creditRating = company.prospectingCreditRating;
	const numericRating = company.prospectingNumericCreditRating;
	const riskPrognosis = T('customerPortfolio.risks.drawer.postiverating.riskPrognosis') + percentageRange;
	let descriptorKey = '';
	switch (company.prospectingCreditRating) {
		case 'A':
		case 'B':
			descriptorKey = T('customerPortfolio.risks.drawer.postiverating.veryReliable');
			break;

		case 'C':
			descriptorKey = T('customerPortfolio.risks.drawer.postiverating.reliable');
			break;

		case 'D':
			descriptorKey = T('customerPortfolio.risks.drawer.postiverating.unReliable');
			break;
		case 'E':
			descriptorKey = T('customerPortfolio.risks.drawer.postiverating.veryUnReliable');
			break;

		default:
			descriptorKey = '';
			break;
	}
	const result = `${descriptorKey} ${creditRating} ${numericRating} ${riskPrognosis}`;

	return result;
};

export const renderCreditRatingReasoning = (company: CustomerPortfolioCompany) => {
	if (company.prospectingNumericCreditRating === null) {
		return T('customerPortfolio.risks.missing');
	} else if (company.prospectingNumericCreditRating < 0) {
		return getNegativeRatingTitle(company);
	} else {
		return getPositiveRatingTitle(company);
	}
};

export const salesNumberFormat = (company: CustomerPortfolioCompany, valueType: DisplayValueType) => {
	let saleNumber: number | undefined;

	switch (valueType) {
		case 'value':
			saleNumber = company.salesLast12Months;
			break;
		case 'annualValue':
			saleNumber = company.arrLast12Months;
			break;
		case 'monthlyValue':
			saleNumber = company.mrrLast12Months;
			break;
		case 'contributionMargin':
			saleNumber = company.cmLast12Months;
			break;
		default:
			saleNumber = 0;
	}
	const formattedSaleNumber = saleNumber !== undefined ? numberFormat(saleNumber) : numberFormat(0);
	return `${formattedSaleNumber} ${company.currency}`;
};
