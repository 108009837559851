import FlowTemplateName from '../FlowTemplateName';
import type { FlowTemplate, GetSegmentFiltersReturnType } from './FlowTemplate';

// Templates
import WelcomeNewCustomers from './WelcomeNewCustomers';
import ContactsWhoVisitedAWebpage from './ContactsWhoVisitedAWebpage';
import SellMoreToExistingCustomers from './SellMoreToExistingCustomers';
import WarmUpColdLeads from './WarmUpColdLeads';

export type { FlowTemplate, GetSegmentFiltersReturnType };
export { FlowTemplateName };

export const FlowTemplates = {
	[WelcomeNewCustomers.name]: WelcomeNewCustomers,
	[WarmUpColdLeads.name]: WarmUpColdLeads,
	[ContactsWhoVisitedAWebpage.name]: ContactsWhoVisitedAWebpage,
	[SellMoreToExistingCustomers.name]: SellMoreToExistingCustomers
};

export const getFlowPathFromTemplateName = (flowTemplateName: FlowTemplateName) => {
	const flowTemplate = FlowTemplates[flowTemplateName];
	return flowTemplate.getFlowPath();
};

export const getSegmentFiltersFromTemplateName = (flowTemplateName: FlowTemplateName, ...args: any[]) => {
	const flowTemplate = FlowTemplates[flowTemplateName];
	return flowTemplate.getSegmentFilters(...args);
};
