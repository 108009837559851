import { SET_MODALS, SET_SAVED_MODALS, StateModal, ModalState } from 'Store/reducers/ModalReducer';
import { removeItem } from 'Store/helpers/array';
import { AppThunk } from '..';
import { replaceItem } from '@upsales/components/Utils/arrayHelpers';
import openModal from 'App/services/Modal/Modal';
import { batch } from 'react-redux';

const UNMOUNT_DELAY = 400;

export const closeModal =
	(id: number): AppThunk =>
	(dispatch, getStore) => {
		const { openModals } = getStore().Modal;
		const index = openModals.findIndex(d => d.id === id);

		if (index !== -1) {
			const oldItem = openModals[index];
			const modals = replaceItem(openModals, index, { ...oldItem, unmounting: true });
			dispatch({ type: SET_MODALS, modals });
		}
	};

export const addModal =
	(modal: Omit<StateModal, 'id'>): AppThunk<number> =>
	(dispatch, getState) => {
		const { openModals } = getState().Modal;
		const id = (Math.random() * 2 ** 31) | 0;

		dispatch({ type: SET_MODALS, modals: [...openModals, { ...modal, id }] });
		return id;
	};

export const removeModal =
	(id: number): AppThunk =>
	(dispatch, getStore) => {
		const { openModals } = getStore().Modal;
		const index = openModals.findIndex(d => d.id === id);

		if (index !== -1) {
			const modals = removeItem(openModals, index);
			dispatch({ type: SET_MODALS, modals });
		}
	};

export const updateModalProps =
	(id: number, props: Partial<StateModal['props']>): AppThunk =>
	(dispatch, getStore) => {
		const { openModals } = getStore().Modal;
		const index = openModals.findIndex(d => d.id === id);

		if (index !== -1) {
			const oldItem = openModals[index];
			const modals = replaceItem(openModals, index, { ...oldItem, props: { ...oldItem.props, ...props } });
			dispatch({ type: SET_MODALS, modals });
		}
	};

export const closeAllModals = (): AppThunk => async dispatch => {
	await setTimeout(() => {
		dispatch({ type: SET_MODALS, modals: [] });
	}, UNMOUNT_DELAY);
};

export const clearSavedModals = () => ({ type: SET_SAVED_MODALS, savedModals: [], redirectPage: null });

// For animations to display correctly you need to call the modal's close function before calling this. TODO: figure out
// 	a better way handle that
export const saveAndCloseModals =
	(redirectPage: ModalState['redirectPage'] = null): AppThunk =>
	(dispatch, getStore) => {
		const { openModals } = getStore().Modal;
		if (openModals.length) {
			batch(() => {
				dispatch({
					type: SET_SAVED_MODALS,
					savedModals: openModals,
					redirectPage
				});
				dispatch(closeAllModals());
			});
		}
	};

export const restoreSavedModals =
	(skipRedirect: boolean = false): AppThunk =>
	async (dispatch, getStore) => {
		const { savedModals, redirectPage } = getStore().Modal;
		if (savedModals.length) {
			if (!skipRedirect && !!redirectPage) {
				Tools.$state.go(redirectPage);
			}
			savedModals.map(modal => openModal(modal.name, modal.props ?? {}));
			dispatch(clearSavedModals());
		}
	};
