import RequestBuilder, { comparisonTypes } from 'Resources/RequestBuilder';
import usePartialLoader, { FetcherFunction } from 'App/components/hooks/usePartialLoader';
import TimelineLoadMore from 'Components/TimelineRow/TimelineLoadMore';
import { EVENT_TIMELINE_ENTITY_TYPES } from 'App/babel/enum/timeline';
import EventAttributes from 'App/babel/attributes/EventAttributes';
import BemClass from '@upsales/components/Utils/bemClass';
import EventResource from 'App/resources/Event';
import T from 'Components/Helpers/translate';
import HistoryLog from '../HistoryLog';
import React from 'react';
import Event from 'App/resources/Model/Event';

import { getTimelineRow } from 'Components/Helpers/getTimelineRow';
import { useSelector } from 'App/components/hooks';

type ClientPlanHistoryLogProps = {
	planId: number;
};

const fetcher: FetcherFunction<{ planId: number }, Event> = (filters, { planId }) => {
	const rb = new RequestBuilder();
	rb.addFilter(EventAttributes.entityType, comparisonTypes.Equals, EVENT_TIMELINE_ENTITY_TYPES.CLIENT_PLAN);
	rb.addFilter(EventAttributes.entityId, comparisonTypes.Equals, planId);

	rb.addSort(EventAttributes.date, false);

	rb.offset = filters.offset;
	rb.limit = filters.limit;

	return EventResource.find(rb.build());
};

const ClientPlanHistoryLog = ({ planId }: ClientPlanHistoryLogProps) => {
	const {
		data: events,
		total: totalEvents,
		loading,
		loadMore
	} = usePartialLoader({ fetcher, fetcherProps: { planId } });

	const notesFilterActive = useSelector(state => state.NotesFilterActive);

	if (!planId) {
		return null;
	}

	const classes = new BemClass('ClientPlanHistoryLog');

	return (
		<div className={classes.b()}>
			<HistoryLog title={T('history')} loading={loading}>
				{events.map((event, i) => {
					return getTimelineRow(event, 'ClientPlan', notesFilterActive, i === events.length - 1, {
						type: 'clientPlan',
						id: planId
					});
				})}
				{events && events.length < totalEvents ? (
					<TimelineLoadMore
						newEventsLoading={loading}
						numEventsLoaded={events.length}
						totalEvents={totalEvents}
						incrementOffset={loadMore}
					/>
				) : null}
			</HistoryLog>
		</div>
	);
};

export default ClientPlanHistoryLog;
