import { useTranslation } from 'Components/Helpers/translate';
import bemClass from '@upsales/components/Utils/bemClass';
import fnxAppStoreFeaureCompare from './fnx-appstore-feature-comparison@3x.png';
import { Card, CardContent, CardHeader, Column, Link, Row, Text } from '@upsales/components';
import './WhatsIncludedCard.scss';
import React from 'react';

export default function WhatsIncludedCard(props) {
	const classes = new bemClass('WhatsIncludedCard');
	const { t } = useTranslation();

	return (
		<Link target="_blank" nostyle href="https://www.upsales.com/sv/pris/">
			<Card className={classes.b()} space="mtsm mbxl" slice="grey-10" slicePosition="right" {...props}>
				<CardHeader title={t('createAccount.whatsIncluded')} />
				<CardContent>
					<Row>
						<Column size={8}>
							<Text color="grey-11">{t('createAccount.checkOut')}</Text>
						</Column>
						<Column align="right">
							<img src={fnxAppStoreFeaureCompare} width="80" height="80" />
						</Column>
					</Row>
				</CardContent>
			</Card>
		</Link>
	);
}
