import { useMemo, useEffect } from 'react';
import Tracker from 'App/babel/helpers/Tracker';

type SpeedTrackerValues = {
	[key: string]: number;
};

type SpeedTrackerOptions = {
	trackerValues?: SpeedTrackerValues;
	pageName: string;
	debug?: boolean;
};

type WaitForSelector = {
	type: 'pageLoad' | 'interactable';
	selector: string;
};

export const defaultTrackerValues = {
	pageLoad: 0,
	interactable: 0
};

const TRACKING_EVENTS = {
	PAGE_LOAD: 'pageLoad',
	INTERACTABLE: 'interactable',
	PERFORMANCE: 'performance'
};

class SpeedTracker extends Tracker<SpeedTrackerValues> {
	pageName: string;
	debug = false;
	tracking = false;

	constructor({ trackerValues = defaultTrackerValues, pageName, debug }: SpeedTrackerOptions) {
		super(`Speed: ${pageName}`, {}, false, { ...trackerValues });

		this.debug = debug || false;
		this.pageName = pageName;
	}

	startTracking = () => {
		if (this.tracking) {
			return;
		}

		this.startTrackTime(TRACKING_EVENTS.PAGE_LOAD);
		this.tracking = true;
	};

	endTracking = () => {
		this.endTrackTime(TRACKING_EVENTS.PAGE_LOAD);
		this.resetTracker();
		this.tracking = false;
	};

	resetTracker = () => {
		const valuesToTrackKeys = Object.keys(this.valuesToTrack);
		for (const valueKey of valuesToTrackKeys) {
			this.valuesToTrack[valueKey] = 0;
		}
		this.timeTracker = {};
	};

	getTimeForEvent = (event: string) => {
		let time = this.getTrackedTime(TRACKING_EVENTS.PAGE_LOAD);
		if (!time) {
			if (this.debug) {
				console.log(`No time recorded for ${this.pageName}.${event}`);
			}
			return;
		}
		time = (Date.now() - time) / 1000;
		if (this.debug) {
			console.log(`Time for ${this.pageName}.${event}: ${time}`);
		}

		return time;
	};

	publishInteractable = () => {
		if (this.tracking) {
			const time = this.getTimeForEvent(TRACKING_EVENTS.INTERACTABLE);
			this.incrementValue(TRACKING_EVENTS.INTERACTABLE, time);
		}
	};

	publishPageLoad = () => {
		if (this.tracking) {
			const time = this.getTimeForEvent(TRACKING_EVENTS.PAGE_LOAD);
			this.incrementValue(TRACKING_EVENTS.PAGE_LOAD, time);
		}
	};

	sendResults = (optimized = false) => {
		if (this.tracking) {
			this.trackWithValues(TRACKING_EVENTS.PERFORMANCE, { optimized });
			this.endTracking();
		}
	};

	callPublish = (WaitForSelector: WaitForSelector) => {
		switch (WaitForSelector.type) {
			case TRACKING_EVENTS.PAGE_LOAD:
				this.publishPageLoad();
				break;
			case TRACKING_EVENTS.INTERACTABLE:
				this.publishInteractable();
				break;
			default:
				break;
		}
	};

	waitForCondition = (waitForSelectors: WaitForSelector[]) => {
		let waited = 0;
		const maxWait = 20000;
		let unresolvedSelectors = [...waitForSelectors];

		const waitForResolve = (resolve: (value: unknown) => void) => {
			if (!this.tracking) {
				resolve(false);
				return;
			}

			for (const waitForSelector of unresolvedSelectors) {
				const elementVisible = document.querySelector(waitForSelector.selector);

				if (elementVisible) {
					this.callPublish(waitForSelector);

					unresolvedSelectors = unresolvedSelectors.filter(
						selector => selector.selector !== waitForSelector.selector
					);
				}
			}

			if (unresolvedSelectors.length === 0) {
				this.sendResults();
				resolve(true);
				return;
			}

			if (waited >= maxWait) {
				resolve(false);
				return;
			}

			setTimeout(() => {
				waited += 50;
				waitForResolve(resolve);
			}, 50);
		};

		return new Promise(resolve => {
			waitForResolve(resolve);
		});
	};

	async publishResults(waitForSelectors: WaitForSelector[]) {
		if (waitForSelectors) {
			const fulfilled = await this.waitForCondition(waitForSelectors);
			if (!fulfilled) {
				return;
			}
		}
	}
}

const speedTrackers: { [key: string]: SpeedTracker } = {};

export const getSpeedTracker = (pageName: string, createIfNotExists?: boolean, uniqueId?: string, debug?: boolean) => {
	if (!Tools.FeatureHelper.hasSoftDeployAccess('UI_SPEED_MEASURE')) {
		return null;
	}
	const speedTrackerKey = uniqueId ? `${pageName}-${uniqueId}` : pageName;
	const existingSpeedTracker = speedTrackers[speedTrackerKey];
	if (existingSpeedTracker) {
		return existingSpeedTracker;
	}
	if (!createIfNotExists) {
		return null;
	}

	const speedTracker = new SpeedTracker({ pageName, debug });
	speedTrackers[speedTrackerKey] = speedTracker;
	return speedTracker;
};

export const useSpeedTracker = (
	pageName: string,
	startOnCondition: boolean,
	endOnCondition: boolean,
	uniqueId?: string
) => {
	const speedTracker = useMemo(() => getSpeedTracker(pageName, true, uniqueId), [pageName]);

	useEffect(() => {
		if (endOnCondition) {
			speedTracker?.publishInteractable();
			speedTracker?.sendResults();
		}
	}, [endOnCondition]);

	useEffect(() => {
		if (startOnCondition) {
			speedTracker?.endTracking();
			speedTracker?.startTracking();
		}
	}, [startOnCondition]);
};

export default getSpeedTracker;
