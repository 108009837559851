import QuickLinks from 'Components/Helpers/QuickLinks';
import React, { useEffect, useState } from 'react';
import Links from 'App/resources/Model/Links';
import './ActivityQuickLinksMenu.scss';
import BemClass from '@upsales/components/Utils/bemClass';
import { makeCancelable } from 'Helpers/promise';
import T from 'Components/Helpers/translate';

type Props = {
	activityId: number;
};

const ActivityQuickLinksMenu: React.FC<Props> = ({ activityId }) => {
	const classes = new BemClass('ActivityQuickLinksMenu');
	const [links, setLinks] = useState<Links[]>([]);

	useEffect(() => {
		const { promise, cancel } = makeCancelable(
			Tools.Links.customer(Tools.AppService.getCustomerId()).get('activity', activityId)
		);
		promise
			.then(response => {
				setLinks(response.data);
			})
			.catch(() => {
				setLinks([]);
			});
		return cancel;
	}, []);

	return (
		<div className={classes.b()}>
			<h5 className={classes.elem('title').b()}>{T('default.dynamiclinks')}</h5>
			<div className={classes.elem('linksContainer').b()}>
				<QuickLinks quickLinks={links} className={classes.elem('link').b()} />
				{links.length === 0 ? (
					<div className={classes.elem('noLinks').b()}>
						<span>{T('default.dynamiclinksEmpty')}</span>
					</div>
				) : null}
			</div>
		</div>
	);
};

export default ActivityQuickLinksMenu;
