import Resource from 'Resources/Resource';
import { type AccountSelf } from 'App/resources/AllIWant';

class SelfResource extends Resource {
	constructor() {
		super('self');
	}

	get(): Promise<{ data: AccountSelf; error: Error }> {
		return super.get();
	}

	approveSupportRequest(numberOfDays: number) {
		const customerId = Tools.AppService.getCustomerId();
		return this._postRequest(
			`${numberOfDays}`,
			{},
			{ _url: `${customerId}/self/support`, methodName: 'approveSupportRequest' }
		);
	}

	getCustomerSelf() {
		const customerId = Tools.AppService.getCustomerId();
		return this._getRequest('', { _url: `${customerId}/self`, methodName: 'getCustomerSelf' });
	}
}

const resource = new SelfResource();

export default resource;
