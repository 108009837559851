import Tracker from './Tracker';

export const labelEvents = {
	CREATED_LABEL: 'createdLabel',
	ADDED_LABEL: 'addedLabel',
	CLICKED_LABEL: 'clickedLabel'
};

const FileBrowserTracker = new Tracker('FileBrowser', {
	...labelEvents,
	UPLOAD: 'upload'
});

export default FileBrowserTracker;
