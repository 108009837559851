import React from 'react';
import TextEditor, { baseConfig } from 'Components/TextEditor';

const textEditorConfig = ({ isSocialEvent, isWebinar, tagEntity }) =>
	baseConfig({
		height: 'calc(100vh - 135px)',
		extraPlugins: 'tags,images,signatures,lineheight',
		pluginParams: {
			tags: { socialEvent: isSocialEvent ? { isWebinar } : undefined, tagEntity }
		},
		forcePasteAsPlainText: true,
		toolbar: [
			{ name: 'styles', items: ['Font', 'FontSize', 'Format', 'lineheight'] },
			{
				name: 'basicstyles',
				items: ['Bold', 'Italic', 'Underline', 'Strike', 'Subscript', 'Superscript', '-', 'RemoveFormat']
			},
			{ name: 'colors', items: ['TextColor', 'BGColor'] },
			{
				name: 'paragraph',
				items: [
					'NumberedList',
					'BulletedList',
					'-',
					'Outdent',
					'Indent',
					'-',
					'JustifyLeft',
					'JustifyCenter',
					'JustifyRight',
					'JustifyBlock'
				]
			},
			{ name: 'insert', items: ['Table', 'HorizontalRule'] },
			{ name: 'document', items: ['Source'] },
			{ name: 'links', items: ['Link', 'Unlink'] },
			{ name: 'signatures', items: ['signatures'] }
		],
		format_maintitle: { name: 'Main title', element: 'h2' },
		format_secondarytitle: { name: 'Title', element: 'h3' },
		format_tags: 'paragraph;ingress;maintitle;secondarytitle',
		format_paragraph: {
			name: 'Paragraph',
			element: 'p',
			attributes: { class: 'paragraph' }
		},
		format_ingress: {
			name: 'Ingress',
			element: 'p',
			attributes: { class: 'ingress' }
		},
		removePlugins: 'image,elementspath'
	});

export default ({ isSocialEvent, isWebinar, tagEntity, ...props }) => (
	<TextEditor {...props} config={textEditorConfig({ isSocialEvent, isWebinar, tagEntity })} />
);
