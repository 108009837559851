export const parseSignatureTags = async body => {
	const currentUser = Tools.AppService.getSelf();
	body = body.replace('{{General.CurrentUserName}}', currentUser.name);
	body = body.replace('{{General.CurrentUserTitle}}', currentUser.userTitle || '');
	body = body.replace('{{General.CurrentUserCellPhone}}', currentUser.userCellPhone || '');
	body = body.replace('{{General.CurrentUserEmail}}', currentUser.email);
	body = body.replace('{{General.CurrentUserPhone}}', currentUser.userPhone);
	if (body.includes('{{General.Company')) {
		const account = await Tools.MailAccount.get();
		body = body.replace('{{General.CompanyName}}', account.data.name);
		body = body.replace('{{General.CompanyAddress}}', account.data.address);
		body = body.replace('{{General.CompanyZipCode}}', account.data.zipCode);
		body = body.replace('{{General.CompanyCity}}', account.data.city);
		body = body.replace('{{General.CompanyCountry}}', account.data.country);
	}
	return body;
};

const parseSignatureHtml = async (body, transformer) => {
	body = body.replace(/<p[^>]+?>|<p>|\s{2,}/g, '');
	body = body.replace(/<\/p>/g, '<br>');
	body = body.replace(/>\n/g, '>');

	if (transformer) {
		body = await transformer(body);
	}

	return body.replace(/(<br\s*\/?\s*>\s*)+$/, '');
};

export const parseSignature = async (body, transformer) => {
	body = await parseSignatureTags(body);
	body = await parseSignatureHtml(body, transformer);
	return '<br>' + body;
};
