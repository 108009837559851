import React, { useMemo } from 'react';
import { DateInput, Text } from '@upsales/components';
import bemClass from '@upsales/components/Utils/bemClass';
import { usePopupPortalAnchorsContext } from 'App/helpers/PopupPortalAnchors';
import T from 'Components/Helpers/translate';
import { useSubscriptionGroupContext } from '../../Context/SubscriptionGroupContext';
import moment from 'moment';
import './SubscriptionGroupScheduling.scss';

const SubscriptionGroupScheduling = () => {
	const {
		state: { isEdit, subscriptionMap },
		setStartDateGroup,
		setInvoiceStartDateGroup
	} = useSubscriptionGroupContext();
	const { anchor, scrollContainer } = usePopupPortalAnchorsContext();
	const classes = new bemClass('SubscriptionGroupScheduling');

	const sortedSubscription = useMemo(
		() =>
			Object.values(subscriptionMap).sort((period1, period2) =>
				moment(period1.startDate) >= moment(period2.startDate) ? 1 : -1
			),
		[subscriptionMap]
	);

	const first = sortedSubscription[0];
	const last = [...sortedSubscription].pop();

	const hasStartDatePassed = moment(first.startDate).isBefore();
	const hasOrderStartDatePassed = moment(first.invoiceStartDate).isBefore();

	const subscriptionStartsText = T(
		'subscription.modal.summary.scheduling.' +
			(hasStartDatePassed ? 'theSubscriptionStarted' : 'theSubscriptionStarts')
	);
	const subscriptionOrderStartsText = T(
		'subscription.modal.summary.scheduling.' +
			(hasOrderStartDatePassed ? 'theOrderPeriodStarted' : 'theOrderPeriodStarts')
	);

	return (
		<div className={classes.b()}>
			<div className={classes.elem('container').b()}>
				<Text>{subscriptionStartsText}</Text>
				<DateInput
					anchor={anchor}
					disabled={isEdit}
					scrollContainer={scrollContainer}
					className={`Input--sm Input--no-border${isEdit ? ' DateInput--disabled' : ''}`}
					closeOnSelect={true}
					value={new Date(first.startDate)}
					showWeekNumbers={true}
					placeholder={T('default.date')}
					onChange={e => setStartDateGroup(e.target.value.toDateString())}
					inline
				/>
			</div>
			<div className={classes.elem('container').b()}>
				<Text>{subscriptionOrderStartsText}</Text>
				<DateInput
					anchor={anchor}
					disabled={isEdit}
					scrollContainer={scrollContainer}
					className={`Input--sm Input--no-border${isEdit ? ' DateInput--disabled' : ''}`}
					closeOnSelect={true}
					value={new Date(first.invoiceStartDate)}
					showWeekNumbers={true}
					placeholder={T('default.date')}
					onChange={e => setInvoiceStartDateGroup(e.target.value.toDateString())}
					inline
				/>
			</div>
			{first.endDate && moment(first.endDate).isBefore() ? null : (
				<div className={classes.elem('container').b()}>
					<Text>
						{`${T('subscription.modal.summary.scheduling.theSubscriptionIsDivided', {
							first: first.periodLength
						})} ${
							last?.periodLength
								? T('subscription.modal.summary.scheduling.theSubscriptionIsDividedPart2', {
										second: last.periodLength
								  })
								: T('subscription.modal.summary.scheduling.theSubscriptionIsDividedPart2.notice')
						}`}
					</Text>
				</div>
			)}
		</div>
	);
};

export default SubscriptionGroupScheduling;
