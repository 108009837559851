import React from 'react';
import { Icon, Text } from '@upsales/components';
import BemClass from '@upsales/components/Utils/bemClass';
import { From } from './RecipientsDropDown';
import TooltipOverflowText from 'App/components/Text/TooltipOverflowText';

type Props = {
	user: From;
};

const UserRow = ({ user }: Props) => {
	const classes = new BemClass('RecipientsDropDown');
	const mailString = user.email || '';

	return (
		<div className={classes.elem('userRow').b()}>
			<Icon name="user" color="grey-11" />
			<div className={classes.elem('userRow').elem('text').b()}>
				<Text>{user.name}</Text>
				<TooltipOverflowText tooltipProps={{ title: mailString.replace(/,/g, '\n') }} size="sm" color="grey-11">
					{mailString.length && user.name ? `(${mailString})` : mailString}
				</TooltipOverflowText>
			</div>
		</div>
	);
};

export default UserRow;
