import './RiskyOpportunities.scss';
import React, { useMemo } from 'react';
import BemClass from '@upsales/components/Utils/bemClass';
import { Block, Headline, Text } from '@upsales/components';
import T from 'Components/Helpers/translate';

import DealCard from 'App/components/DealCard';
import { connect } from 'react-redux';
import { RootState } from 'Store/index';
import Order, { LostReason } from 'App/resources/Model/Order';
import { RiskEvaluatedOpportunity } from 'App/resources/Model/Opportunity';
import { ThirdButton } from '@upsales/components/Buttons';
import history from 'App/pages/routes/history';

const getTitlesForLostReason = (lostReason?: LostReason, isHighestRiskOpportunities?: boolean) => {
	if (isHighestRiskOpportunities) {
		return [T('order.lost.highestRiskOpportunitiesTitle'), T('order.lost.highestRiskOpportunitiesSubTitle')];
	}

	if (!lostReason) {
		return ['', ''];
	}
	const lostReasonText = lostReason.value?.toLowerCase() ?? '';
	return [
		T('order.lost.riskyOpportunitiesTitle', { lostReason: lostReasonText }),
		T('order.lost.riskyOpportunitiesSubTitle', { lostReason: lostReasonText })
	];
};

const mapStateToProps = (state: RootState) => ({
	salesboardConfig: state.Salesboard
});

type Props = {
	lostReason?: LostReason;
	order?: Order;
	riskyOpportunities?: RiskEvaluatedOpportunity[];
	onClose?: () => void;
	isHighestRiskOpportunities?: boolean;
	salesboardConfig: RootState['Salesboard'];
};

const RiskyOpportunities = ({
	lostReason,
	order,
	riskyOpportunities = [],
	onClose,
	isHighestRiskOpportunities,
	salesboardConfig
}: Props) => {
	const classes = new BemClass('RiskyOpportunities');

	const [title, subTitle] = useMemo(() => {
		return getTitlesForLostReason(lostReason, isHighestRiskOpportunities);
	}, [lostReason]);

	const goToSalesboard = (order: Order, closeModal?: boolean) => {
		const id = order?.id;

		if (closeModal && onClose) {
			onClose();
		}

		if (id && !history.location.pathname.startsWith('/salesboard')) {
			history.push('/salesboard/');
		}
	};

	const openEditOrder = async (order: Order) => {
		goToSalesboard(order);
		Tools.$upModal.open('editOrder', { id: order.id });
		if (onClose) {
			onClose();
		}
	};

	const renderTitle = () => (
		<Block space="mbxl">
			<Headline className={classes.elem('title').b()} size="sm">
				{title}
			</Headline>
		</Block>
	);

	const renderSubTitle = () => (
		<Block space="mbxl">
			<Text className={classes.elem('subTitle').b()} size="lg">
				{subTitle}
			</Text>
		</Block>
	);

	const renderTopContent = () => (
		<Block className={classes.elem('topContent').b()} space="ptm pbm">
			{renderTitle()}
			{renderSubTitle()}
		</Block>
	);

	const renderDealCards = () => (
		<Block space="mtxl mbxl" className={classes.elem('dealCards').b()}>
			{riskyOpportunities.map(order => (
				<DealCard
					cardConfig={salesboardConfig.cardConfig}
					key={order.id}
					order={order}
					onClick={() => openEditOrder(order)}
				/>
			))}
		</Block>
	);

	const renderSalesboardLinkButton = () => (
		<Block space="mtm">
			<ThirdButton
				className={classes.elem('salesboardLinkButton').b()}
				onClick={order ? () => goToSalesboard(order, true) : undefined}
			>
				{T('order.lost.goToSalesboard')}
			</ThirdButton>
		</Block>
	);

	const renderCloseButton = () => (
		<Block space="mtl">
			<ThirdButton className={classes.elem('closeButton').b()} onClick={onClose} text={T('close')} />
		</Block>
	);

	const renderMainContent = () => (
		<Block className={classes.elem('mainContent').b()} space="ptxl prxl pbxl plxl">
			{!!riskyOpportunities.length && renderDealCards()}
			{renderSalesboardLinkButton()}
			{onClose && renderCloseButton()}
		</Block>
	);

	return (
		<Block space="mtxl" className={classes.b()}>
			{renderTopContent()}
			{renderMainContent()}
		</Block>
	);
};

export const detached = RiskyOpportunities;
const ConnectComponent = connect(mapStateToProps)(RiskyOpportunities);
export default ConnectComponent;
