const multipleSelectionHelper = (
	allSelectedOptions: string[] = [],
	optionTypes: string[],
	onChange: (values: number[] | string[] | (string | number)[], optionID?: string) => void
) => {
	const selectedOptions: any = {};
	allSelectedOptions.forEach(selectedOption => {
		const [parentID, typeID] = selectedOption.toString().split('_');
		if (parentID && typeID) {
			selectedOptions[parentID] = selectedOptions[parentID] || {};
			if (typeID === 'All') {
				optionTypes.forEach(optionType => (selectedOptions[parentID][optionType] = true));
			} else {
				selectedOptions[parentID][typeID] = true;
			}
		}
	});

	const updateAllSelected = (parentID: string, childID: string) => {
		let updateResult = allSelectedOptions ? allSelectedOptions : [];
		if (selectedOptions[parentID].All) {
			updateResult = updateResult.filter((value: any) => value !== childID);
			onChange(updateResult, parentID);
		} else {
			updateResult = updateResult.filter((value: any) => value.split('_')[0] !== parentID);
			updateResult.push(`${parentID}_All`);
			onChange(updateResult, parentID);
		}
	};

	const removeSelected = (parentID: string, childOptionType: string) => {
		let updateResult = allSelectedOptions ? allSelectedOptions : [];
		if (selectedOptions[parentID].All) {
			updateResult = updateResult.filter((value: any) => value !== `${parentID}_All`);
			optionTypes.forEach(optionType => {
				if (optionType !== 'All' && `${parentID}_${optionType}` !== `${parentID}_${childOptionType}`) {
					updateResult.push(`${parentID}_${optionType}`);
				}
			});
		} else {
			updateResult = updateResult.filter((value: any) => value !== `${parentID}_${childOptionType}`);
		}
		onChange(updateResult, parentID);
	};

	const onSelected = (val: { id: string; title: string }, remove: undefined | boolean) => {
		const [parentID, typeID] = val.id.split('_');
		if (selectedOptions[parentID] === undefined) {
			selectedOptions[parentID] = {};
		}
		if (typeID === 'All') {
			updateAllSelected(parentID, val.id);
		} else {
			if (remove || selectedOptions[parentID][typeID]) {
				removeSelected(parentID, typeID);
			} else {
				let allSelected = true;
				optionTypes.forEach(optionType => {
					if (
						optionType !== 'All' &&
						optionType !== typeID &&
						selectedOptions[parentID][optionType] !== true
					) {
						allSelected = false;
					}
				});

				if (allSelected) {
					updateAllSelected(parentID, val.id);
				} else {
					const updateResult = allSelectedOptions ? allSelectedOptions : [];
					updateResult.push(val.id);
					onChange(updateResult, parentID);
				}
			}
		}
	};

	return [selectedOptions, onSelected];
};

export default multipleSelectionHelper;
