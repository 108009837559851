import Attribute from './Attribute';

const ProductAttributes = {
	id: new Attribute({
		field: 'id',
		title: 'default.id',
		type: Attribute.types.Number,
		groupable: false,
		sortable: false,
		selectableColumn: false,
		filterable: false
	}),
	name: new Attribute({
		title: 'default.name',
		field: 'name',
		type: Attribute.types.String,
		groupable: false,
		sortable: 'name',
		selectableColumn: true
	}),
	description: new Attribute({
		title: 'default.description',
		field: 'description',
		type: Attribute.types.String,
		inputType: 'text',
		groupable: false,
		sortable: false,
		selectableColumn: true,
		filterable: false
	}),
	userId: new Attribute({
		title: 'default.user',
		field: 'userId',
		type: Attribute.types.Number,
		filterName: 'userId',
		refParams: {
			type: 'user',
			field: 'name',
			identifyer: 'id',
			refType: 'appointment'
		},
		comparisonTypes: ['Equals', 'NotEquals'],
		inputType: 'select',
		groupable: true,
		sortable: false,
		selectableColumn: true,
		filterable: true
	}),
	categoryId: new Attribute({
		field: 'category.id',
		type: Attribute.types.Number
	}),
	active: new Attribute({
		field: 'active',
		type: Attribute.types.Number
	}),
	sortId: new Attribute({
		field: 'sortId',
		type: Attribute.types.Number
	}),
	tiers: new Attribute({
		title: 'default.tiers',
		field: 'tier.start',
		parent: 'tiers',
		attr: {
			start: {
				field: 'tier.start',
				type: Attribute.types.Number
			}
		}
	}),
	articleNo: new Attribute({
		field: 'articleNo',
		type: Attribute.types.String
	}),
	projectPlanTemplate: Attribute({
		selectableColumn: false,
		attr: {
			id: Attribute({
				field: 'projectPlanTemplate.id',
				type: Attribute.types.Number
			}),
			name: Attribute({
				field: 'projectPlanTemplate.name',
				type: Attribute.types.String
			})
		}
	})
};

export default ProductAttributes;
