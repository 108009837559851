import React from 'react';
import PropTypes from 'prop-types';
import BemClass from '@upsales/components/Utils/bemClass';
import { Icon, Block, IconName } from '@upsales/components';

import './TimelineIcon.scss';

type Props = {
	name?: IconName;
	color?: string;
	backgroundColor?: string;
	hideLine?: boolean;
};

const TimelineIcon = ({ name, color, backgroundColor, hideLine = false }: Props) => {
	const classNames = new BemClass('TimelineIcon');

	if (!name) {
		return null;
	}

	return (
		<div className={classNames.b()}>
			<Block backgroundColor={backgroundColor || undefined} className={classNames.elem('iconCard').b()}>
				<Icon name={name} color={color || 'grey-10'} />
			</Block>
			{!hideLine ? <div className={classNames.elem('line').b()} /> : null}
		</div>
	);
};

TimelineIcon.propTypes = {
	name: PropTypes.string,
	backgroundColor: PropTypes.string,
	color: PropTypes.string,
	hideLine: PropTypes.bool
};

export default TimelineIcon;
