import React from 'react';
import Alerts, { type AlertProps } from './Alerts';

const UnsavedChangesAlert = (props: AlertProps) => {
    return (
        <Alerts
            title="default.unsavedChanges"
            body="confirm.changesWillBeLost"
            headerIcon="exclamation-triangle"
            confirmButtonText="default.save"
            cancelButtonText="default.discardChanges"
            alertType="info"
            {...props}
        />
    );
};

export default UnsavedChangesAlert;
