import React from 'react';
import { get } from 'lodash';
import { Text } from '@upsales/components';
import TimelineRow from 'Components/TimelineRow';

const ActivityTimelineChangeActivityType = ({ event, hideIcon }) => {
	const t = Tools.$translate;
	const lang = {
		changedActivityTypeTo: t('activity.outcomeLog.changedActivityTypeTo'),
		previousType: t('activity.outcomeLog.previousType')
	};
	const date = get(event, 'date');
	const user = get(event, 'user') || {};
	const activityType = get(event, 'data.activityType') || {};
	const oldActivityType = get(event, 'data.oldActivityType') || {};
	const isEqual = activityType.id === oldActivityType.id;

	if (isEqual) return null;

	const title = (
		<Text>
			{lang.changedActivityTypeTo} {activityType.name}
		</Text>
	);
	const subTitle = date ? (
		<Text size="sm" color="grey-11">
			{moment(date).format('L LT')} <span dangerouslySetInnerHTML={{ __html: '&#8226;' }} /> {user.name}
		</Text>
	) : null;
	const subSection = oldActivityType.name ? (
		<Text className="subSection" color="grey-10">
			{lang.previousType} {oldActivityType.name}
		</Text>
	) : null;

	return (
		<TimelineRow
			icon={hideIcon ? undefined : 'edit'}
			event={event}
			title={title}
			subTitle={subTitle}
			subSection={subSection}
		/>
	);
};

export default ActivityTimelineChangeActivityType;
