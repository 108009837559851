import React, { Component } from 'react';

export default class Button extends Component {
	render() {
		var content = this.props.children;
		var className = (this.props.className || '').split(' ');

		className.push('up-btn');

		if (this.props.color) {
			className.push('btn-' + this.props.color);
		}

		if (this.props.type) {
			className.push('btn-' + this.props.type);
		}

		if (this.props.shadow) {
			if (this.props.shadow === 'none') {
				className.push('no-shadow');
			} else if (this.props.shadow === 'high') {
				className.push('high-shadow');
			}
		}

		if (this.props.size) {
			className.push('btn-' + this.props.size);
		}

		if (this.props.hoverColor) {
			className.push('btn-hover-' + this.props.hoverColor);
		}

		if (this.props.loading) {
			content = (
				<div className="loader-wrap">
					<Loader noU={true} />
					<span style={{ opacity: 0 }}>{this.props.children}</span>
				</div>
			);
		}

		if (this.props.block === true) {
			className.push('btn-block');
		}

		return (
			<button
				id={this.props.id}
				disabled={this.props.disabled || this.props.loading}
				style={this.props.style}
				onClick={this.props.onClick}
				type={this.props.submit ? 'submit' : 'button'}
				className={className.join(' ')}
				value={this.props.value}
			>
				{content}
			</button>
		);
	}
}

window.Button = Button;
