import BemClass from '@upsales/components/Utils/bemClass';
import React from 'react';
import T from 'Components/Helpers/translate';
import type { BundleState } from '../ProductBundleDrawer';
import EditProductCustom from 'Components/Modals/EditProduct/EditProductCustom';

type Props = {
	classes: BemClass;
	bundle: BundleState;
	updateBundle: React.Dispatch<Partial<BundleState>>;
};
const BundleCustomFields = ({ classes, bundle, updateBundle }: Props) => {
	const customChanged = (customFieldId: number, value: string) => {
		const custom = [...bundle.custom];
		const matchingField = custom.find(field => field.fieldId === customFieldId);
		if (matchingField) {
			matchingField.value = value;
		} else {
			custom.push({
				fieldId: customFieldId,
				value
			});
		}
		updateBundle({ custom });
	};
	return (
		<div className={classes.elem('customfields').b()}>
			<EditProductCustom
				title={T('admin.products.customFields')}
				customFields={bundle.customFields}
				values={bundle.custom}
				onChange={customChanged}
				disabled={bundle.saving}
			/>
		</div>
	);
};

export default BundleCustomFields;
