import Resource from './Resource';

class DesiredOutcomeUser extends Resource {
	constructor() {
		super('desiredoutcomeuser');
		this.dateFields = ['modDate', 'regDate'];
	}
}

const resource = new DesiredOutcomeUser();

export default resource;
