const comparisonTypes = require('App/babel/resources/RequestBuilder').comparisonTypes;

const FIELDS = ['noEmployees', 'noEmployeesChange'];

function generateFilter(useRange, overrides = {}) {
	function generate() {
		return {
			inactive: true,
			value: {
				noEmployees: {
					value: {
						start: null,
						end: null
					}
				},
				noEmployeesChange: {
					value: {
						start: null,
						end: null,
						offset: 1
					}
				}
			}
		};
	}

	function isInactive(filter) {
		return !FIELDS.some(
			field => filter.value[field].value.start !== null || filter.value[field].value.end !== null
		);
	}

	function toUrl(filter) {
		const f = { v: {} };
		const val = filter.value;

		f.i = filter.inactive;

		if (val.noEmployees.value) {
			f.v.noe = { s: val.noEmployees.value.start, e: val.noEmployees.value.end };
		}

		if (val.noEmployeesChange.value) {
			f.v.nec = {
				s: val.noEmployeesChange.value.start,
				e: val.noEmployeesChange.value.end,
				o: val.noEmployeesChange.value.offset
			};
		}

		return f;
	}

	function fromUrl(rawFilter) {
		const filter = generate();
		const val = rawFilter.v;

		if (val.noe) {
			filter.value.noEmployees = { start: val.noe.s, end: val.noe.e };
		}

		if (val.nec) {
			filter.value.noEmployeesChange = { start: val.nec.s, end: val.nec.e, offset: val.nec.o || 1 };
		}

		filter.inactive = rawFilter.hasOwnProperty('i') ? rawFilter.i : isInactive(filter);

		return filter;
	}

	function build(filter, rb) {
		const value = filter.value;

		if (filter.inactive || !value || (value.start === null && value.end === null)) {
			return;
		}

		for (const field of FIELDS) {
			const value = filter.value[field].value;
			const apiField = field === 'noEmployeesChange' ? `noEmployeesPercentChange${value.offset || 1}Year` : field;

			if (apiField === 'noEmployees' && useRange) {
				const outerOrBuilder = rb.orBuilder();

				// Headquarters = true
				outerOrBuilder.next();
				outerOrBuilder.addFilter({ field: 'headquarters' }, comparisonTypes.Equals, true);

				if (value.start !== null) {
					outerOrBuilder.addFilter({ field: 'noEmployees' }, comparisonTypes.GreaterThanEquals, value.start);
				}
				if (value.end !== null) {
					outerOrBuilder.addFilter({ field: 'noEmployees' }, comparisonTypes.LessThanEquals, value.end);
				}
				// Headquarters = false
				outerOrBuilder.next();
				outerOrBuilder.addFilter({ field: 'headquarters' }, comparisonTypes.Equals, false);

				if (value.start !== null) {
					const innerOrBuilder = outerOrBuilder.orBuilder();
					innerOrBuilder.next();
					innerOrBuilder.addFilter(
						{ field: 'employeesRangeStart' },
						comparisonTypes.GreaterThanEquals,
						value.start
					);
					innerOrBuilder.next();
					innerOrBuilder.addFilter(
						{ field: 'employeesRangeEnd' },
						comparisonTypes.GreaterThanEquals,
						value.start
					);
					innerOrBuilder.done();
				}

				if (value.end !== null) {
					const innerOrBuilder = outerOrBuilder.orBuilder();
					innerOrBuilder.next();
					innerOrBuilder.addFilter(
						{ field: 'employeesRangeStart' },
						comparisonTypes.LessThanEquals,
						value.end
					);
					innerOrBuilder.next();
					innerOrBuilder.addFilter({ field: 'employeesRangeEnd' }, comparisonTypes.LessThanEquals, value.end);
					innerOrBuilder.done();
				}

				outerOrBuilder.done();
			} else {
				if (value.start !== null) {
					rb.addFilter({ field: apiField }, comparisonTypes.GreaterThanEquals, value.start);
				}

				if (value.end !== null) {
					rb.addFilter({ field: apiField }, comparisonTypes.LessThan, value.end);
				}
			}
		}
	}

	const filter = {
		filterName: 'Employees',
		type: 'raw',
		displayType: 'prospectingEmployees',
		title: 'default.employees',
		entity: 'account',
		showOnSegment: true,
		generate,
		isInactive,
		toUrl,
		fromUrl,
		build
	};
	return Object.assign(filter, overrides);
}

export default generateFilter;
