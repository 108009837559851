import './CompetitorRow.scss';
import React from 'react';
import BemClass from '@upsales/components/Utils/bemClass';
import { Row, Card, Icon, Text, Block, Column, Checkbox, IconName } from '@upsales/components';
import T from 'Components/Helpers/translate';
import { Competitor } from 'Resources/Competitor';
interface Props {
	keep?: boolean;
	selected?: boolean;
	competitor: Competitor;
	onClick?: (id: number, selected: boolean) => void;
}

const CompetitorRow = ({ competitor, onClick, selected = false, keep = false }: Props) => {
	const classes = new BemClass('CompetitorRow');
	const onCompetitorClick = () => {
		onClick?.(competitor?.id, !selected);
	};

	if (!competitor) {
		return <></>;
	}

	const wrapperClassName = classes;
	wrapperClassName.add('clickable');

	const nameClassName = classes.elem('name');
	nameClassName.add('text-ellipsis');

	const nameTextClassName = classes.elem('nameText');
	nameTextClassName.add('text-ellipsis');

	return (
		<Card space="plxl prxl" className={wrapperClassName.b()} onClick={keep ? undefined : onCompetitorClick}>
			<Row>
				<Column className={classes.elem('textColumn').b()} size={11}>
					<Block space="ptl pbl" className={classes.elem('row').b()}>
						<Block space="mrm" className={nameClassName.b()}>
							<Block space="mrm">
								<Checkbox disabled={keep} checked={selected} onChange={onCompetitorClick} />
							</Block>
							<Text className={nameTextClassName.b()}>{competitor.name}</Text>
						</Block>

						<Text
							italic
							color={selected ? 'grey-11' : keep ? 'bright-blue' : 'inherit'}
							className={classes
								.elem('willBeMergedOrKeptText')
								.mod({ visible: selected || keep })
								.b()}
						>
							<Icon space="mrm" name={(selected ? 'times' : keep ? 'check' : '') as IconName} />
							{keep ? T('competitorDrawer.competitorWillBeKept') : ''}
							{selected ? T('competitorDrawer.competitorWillBeMerged') : ''}
						</Text>
					</Block>
				</Column>
				<Column className={classes.elem('orderCountColumn').b()} size={1}>
					<Text color="grey-11" className={classes.elem('orderCount').b()}>
						{competitor.orderCount}
					</Text>
				</Column>
			</Row>
		</Card>
	);
};

export default CompetitorRow;
