import Tracker from './Tracker';

const BillingTracker = new Tracker('Billing', {
	REQUESTED_ADDON: 'requested_addon',
	BOUGHT_ADDON: 'bought_addon',
	STARTED_ADDON_TRIAL: 'started_addon_trial',
	VIEWED_ADDONS_PAGE: 'viewed_addons_page',
	ADDON_READ_MORE: 'addon_read_more',
	OPEN_ADDON_TERMS: 'open_addon_terms',
	ABORTED_ADDON_PURCHASE: 'aborted_addon_purchase',
	ADD_SEATS: 'add_seats',
	REMOVE_SEATS: 'remove_seats'
});

export default BillingTracker;
