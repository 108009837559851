import Order from 'App/resources/Model/Order';
import { get, set, merge } from 'lodash';
import logError from 'App/babel/helpers/logError';
import CustomField from 'App/resources/Model/CustomField';
import OrderRow from 'App/resources/Model/OrderRow';

export type ObjectWithKeys = {
	[key: string]: boolean | ObjectWithKeys;
};

export type UpdatedFields = Partial<Order> & {
	orderRow?: Partial<OrderRow>;
};

export const transformFieldsToLowerCase = (fields: ObjectWithKeys): ObjectWithKeys => {
	try {
		return Object.fromEntries(
			Object.entries(fields).map(([key, value]) => {
				if (typeof value === 'object') {
					return [key.toLowerCase(), transformFieldsToLowerCase(value)];
				}
				return [key.toLowerCase(), value];
			})
		);
	} catch (err) {
		logError(err, 'fieldHelpers:transformFieldsToLowerCase', fields);
		return fields;
	}
};

export const getKeysAsPaths = (
	objectWithKeys: ObjectWithKeys,
	order: Order,
	onlyAddKeyWithThisValue?: boolean,
	path: string = '',
	paths: string[] = []
) => {
	for (let key of Object.keys(objectWithKeys)) {
		const value = objectWithKeys[key];
		const isNestedObject = typeof value === 'object';

		if (key === 'selectedCurrency') {
			key = 'currency';
		}

		if (!isNestedObject) {
			const compareValue = onlyAddKeyWithThisValue !== undefined;
			if (compareValue) {
				if (onlyAddKeyWithThisValue === value) {
					paths.push(path ? `${path}.${key}` : key);
				}
			} else {
				paths.push(path ? `${path}.${key}` : key);
			}

			if (key === 'discount') {
				// Here we need to add $discount as well,
				// since changes is made to this variable in the view instead of discount
				paths.push(`${path}.$discount`);
			} else if (key === 'discountPercent') {
				paths.push(`${path}.$discountPercent`);
			}
		} else {
			const keyInLowerCase = key.toLocaleLowerCase();
			if (keyInLowerCase.startsWith('orderrow@')) {
				const orderRowId = parseInt(keyInLowerCase.split('@')[1]);
				const index = order.orderRow.findIndex(row => row.id === orderRowId);
				let updatedPath = path ? `${path}.orderRow` : 'orderRow';
				updatedPath += `.[${index}]`;

				if (index !== -1) {
					getKeysAsPaths(value as ObjectWithKeys, order, onlyAddKeyWithThisValue, updatedPath, paths);
				}
			} else if (keyInLowerCase === 'custom') {
				const customFieldPath = path ? `${path}.custom` : 'custom';
				const customFields = get(order, customFieldPath, []) as CustomField[];

				Object.entries(value).forEach(([id, customFieldValue]) => {
					let updatedPath = customFieldPath;

					const index = customFields.findIndex(field => field.id === parseInt(id));
					updatedPath += `.[${index}]`;

					if (index !== -1) {
						const compareValue = onlyAddKeyWithThisValue !== undefined;
						if (compareValue) {
							if (onlyAddKeyWithThisValue === customFieldValue) {
								paths.push(updatedPath);
							}
						} else {
							paths.push(updatedPath);
						}
					}
				});
			} else if (keyInLowerCase === 'orderrow') {
				order.orderRow.forEach((row, index) => {
					let updatedPath = path ? `${path}.orderRow` : 'orderRow';
					updatedPath += `.[${index}]`;
					getKeysAsPaths(value as ObjectWithKeys, order, onlyAddKeyWithThisValue, updatedPath, paths);
				});
			} else {
				getKeysAsPaths(
					value as ObjectWithKeys,
					order,
					onlyAddKeyWithThisValue,
					path ? `${path}.${key}` : key,
					paths
				);
			}
		}
	}
	return paths;
};

export const revertChangesIfAffectedField = (
	orderBefore: Order,
	currentOrder: Order,
	affectedFields: ObjectWithKeys,
	revertIfValue: boolean
) => {
	const fieldPathsWithId = ['project', 'clientConnection'];
	const revertedFields: UpdatedFields = {};

	const keysAsPaths = getKeysAsPaths(affectedFields, orderBefore, revertIfValue);
	for (let fieldPath of keysAsPaths) {
		if (fieldPath.endsWith('.all')) {
			const fieldPathWithoutAll = fieldPath.replace(/\.all$/, '');
			const fieldValueBefore = get(orderBefore, fieldPathWithoutAll);
			set(revertedFields, fieldPathWithoutAll, fieldValueBefore);
		} else {
			const fieldValueBefore = get(orderBefore, fieldPath);
			const fieldValueAfter = get(currentOrder, fieldPath);

			if (fieldValueBefore !== fieldValueAfter) {
				const orderRowPath = fieldPath.split('.').slice(0, 2).join('.');
				if (fieldPath.startsWith('orderRow.')) {
					const orderRowIdPath = `${orderRowPath}.id`;
					if (fieldPath.endsWith('.all')) {
						set(revertedFields, orderRowPath, get(orderBefore, orderRowPath));
					} else {
						set(revertedFields, orderRowIdPath, get(orderBefore, orderRowIdPath));
					}
				}
				if (fieldPath.endsWith('$discount')) {
					const orderRowDiscountPath = `${orderRowPath}.discount`;
					set(revertedFields, orderRowDiscountPath, fieldValueBefore);
				}
				if (fieldPathsWithId.includes(fieldPath) && !fieldValueBefore) {
					fieldPath += '.id';
				}
				set(revertedFields, fieldPath, fieldValueBefore);
			}
		}
	}
	return { order: revertedFields };
};

export const mergeRevertedFieldWithUpdatedFields = (revertedFields: any, updatedFields: any) => {
	return merge(revertedFields, updatedFields, (objValue, srcValue) => {
		if (Array.isArray(objValue)) {
			return srcValue;
		}
	});
};

/*
    If we have fields on the orders (which are not custom fields) that for some reason is hidden,
    then the applications should not be able to force them to be visible.
    So if they are trying to do it, then we simply remove them from the enquiry
*/
export const omitForcedVisibleFields = (fields: ObjectWithKeys = {}) => {
	return Object.keys(fields).reduce((onlyHiddenFields: ObjectWithKeys, fieldPath) => {
		// Only custom fields can be forced to be visible
		if (fieldPath === 'custom') {
			onlyHiddenFields[fieldPath] = fields[fieldPath];
			return onlyHiddenFields;
		}

		const value = fields[fieldPath];

		if (typeof value === 'object') {
			onlyHiddenFields[fieldPath] = omitForcedVisibleFields(value as ObjectWithKeys);
			return onlyHiddenFields;
		}
		if (value === false) {
			onlyHiddenFields[fieldPath] = value;
		}
		return onlyHiddenFields;
	}, {});
};
