import { FilterConfig } from 'App/babel/filterConfigs/FilterConfig';
import RequestBuilder, { comparisonTypes } from 'Resources/RequestBuilder';
import _ from 'lodash';
import Competitor from 'Resources/Competitor';
import FieldTranslations from 'Resources/FieldTranslations';
import { generateFilter, FilterPreset } from '../filterGenerators';
import AccessType from 'App/enum/AccessType';

export default (): { [key: string]: FilterConfig } => ({
	// No parent
	Date: generateFilter(
		FilterPreset.Date,
		{
			field: 'date',
			title: 'default.closeDate2'
		},
		'future'
	),
	Currency: generateFilter(FilterPreset.Currency, { field: 'currency' }),
	ValueRange: {
		title: 'default.value',
		type: 'raw',
		inputType: 'range',
		displayType: 'range',
		toUrl: function (filter) {
			var f = { c: filter.comparisonType, v: filter.value, i: false };
			if (filter.inactive) {
				f.i = true;
			} else if (Array.isArray(filter.value) && !filter.value.length) {
				return undefined;
			}
			return f;
		},
		generate: function () {
			return { value: { start: null, end: null } };
		},
		valueSuffix: function (filterProps) {
			const metaData = Tools.AppService.getMetadata();
			var activeFilters = filterProps.activeFilters;
			var suffix = metaData.defaultCurrency.iso.toUpperCase();
			if (
				activeFilters.Currency &&
				activeFilters.Currency.value &&
				Array.isArray(activeFilters.Currency.value) &&
				activeFilters.Currency.value.length
			) {
				suffix = activeFilters.Currency.value[0].toString();
			}
			return suffix;
		},
		build: function (filter, rb, getField, useTags, activeFilters: any) {
			var metadata = Tools.AppService.getMetadata();
			var selectedCurrency: typeof metadata.defaultCurrency | undefined;

			if (
				activeFilters &&
				activeFilters.Currency &&
				activeFilters.Currency.value &&
				Array.isArray(activeFilters.Currency.value) &&
				activeFilters.Currency.value.length
			) {
				selectedCurrency = _.find(metadata.customerCurrencies, function (currency) {
					return currency.iso.toLowerCase() === activeFilters.Currency.value[0]?.toLowerCase();
				});
			} else {
				selectedCurrency = metadata.defaultCurrency;
			}

			['start', 'end'].forEach(key => {
				if (filter.value[key] || filter.value[key] === 0) {
					var or = rb.orBuilder();
					or.next();

					var myCurrency = or.groupBuilder();
					myCurrency.addFilter(
						getField('value'),
						key === 'start' ? comparisonTypes.GreaterThanEquals : comparisonTypes.LessThanEquals,
						filter.value[key]
					);
					myCurrency.addFilter(getField('currency'), comparisonTypes.Equals, selectedCurrency?.iso);
					myCurrency.done();

					or.next();

					var otherCurrencies = or.groupBuilder();
					otherCurrencies.addFilter(
						getField('valueInMasterCurrency'),
						key === 'start' ? comparisonTypes.GreaterThanEquals : comparisonTypes.LessThanEquals,
						Math.round(filter.value[key] / (selectedCurrency?.rate || 1))
					);
					otherCurrencies.addFilter(getField('currency'), comparisonTypes.NotEquals, selectedCurrency?.iso);
					otherCurrencies.done();
					or.done();
				}
			});
		}
	},
	OrderRowProduct: generateFilter(FilterPreset.Product, {}, 'orderRow.product.id'),
	OrderRowActiveProduct: generateFilter(
		FilterPreset.Product,
		{
			// This filter is only relevant to certain custom sales boards and shouldn't show up in the normal list
			hide: true,
			dataPromise: () => Tools.ProductTreeFilterMeta(false, '')
		},
		'orderRow.product.id'
	),
	Stage: generateFilter(FilterPreset.Stage, { field: 'stage.id' }, false),
	Campaign: generateFilter(FilterPreset.Campaign),
	ProbabilityRange: generateFilter(FilterPreset.Range, {
		field: 'probability',
		title: 'default.probability',
		hide: true
	}),
	Notes: generateFilter(FilterPreset.Notes),
	RegDate: generateFilter(
		FilterPreset.Date,
		{
			field: 'regDate',
			title: 'default.regDate'
		},
		'future'
	),

	// Section User
	User: generateFilter(
		FilterPreset.User,
		{
			field: 'user.id',
			title: 'default.users',
			parent: 'default.user'
		},
		AccessType.ORDER
	),
	UserRole: generateFilter(FilterPreset.Role, { field: 'user.role.id' }),
	Role: generateFilter(FilterPreset.Role, { hide: true, field: 'user.role.id' }), // here for compatibility, shouldn't show up in list

	// Section Company
	Client: generateFilter(
		FilterPreset.ResourceLazyList,
		{
			title: 'default.account',
			parent: 'default.account',
			hide: true
		},
		'client'
	),
	Account: generateFilter(
		FilterPreset.ResourceLazyList,
		{
			title: 'default.account',
			parent: 'default.account'
		},
		'client'
	),
	AccountManager: generateFilter(
		FilterPreset.User,
		{
			field: 'client.user.id',
			title: 'default.accountManagers',
			parent: 'default.account'
		},
		AccessType.ORDER
	),
	AccountCategory: generateFilter(FilterPreset.ClientCategory, { field: 'client.category.id' }),

	// Section Order - other
	Contact: generateFilter(FilterPreset.ResourceLazyList, { parent: 'filter.orderOther' }, 'contact'),
	Description: generateFilter(FilterPreset.Text, {
		field: 'description',
		title: 'default.description',
		parent: 'filter.orderOther'
	}),
	Id: generateFilter(FilterPreset.Text, { field: 'id', title: 'order.id', parent: 'filter.orderOther' }),
	CloseDate: generateFilter(
		FilterPreset.Date,
		{
			field: 'closeDate',
			title: 'default.closeDate',
			parent: 'filter.orderOther'
		},
		'future'
	),
	LostReason: generateFilter(FilterPreset.List, {
		field: 'lostReason.tagId',
		title: 'order.lostReason',
		searchField: 'value',
		displayText: 'value',
		dataPromise: async () => {
			const filter = new RequestBuilder();
			filter.addFilter({ field: 'type' }, comparisonTypes.Equals, 'orderlostreason');
			const { metadata, data } = await FieldTranslations.find(filter.build());
			return {
				metadata,
				data: data.map(({ tagId, value }: { tagId: number; value: string }) => ({ id: tagId, value }))
			};
		},
		parent: 'filter.orderOther'
	}),
	Competitor: generateFilter(FilterPreset.List, {
		field: 'competitorId',
		title: 'order.competitorId',
		dataPromise: () => Promise.resolve(Competitor.find()),
		parent: 'filter.orderOther'
	}),
	clientConnection: generateFilter(FilterPreset.Text, {
		title: 'default.clientConnection',
		field: 'clientConnection.name',
		parent: 'filter.orderOther'
	}),

	// Quick search
	ListSearch: generateFilter(
		FilterPreset.ListSearch,
		{
			build: (filter, rb) => {
				if (filter.value?.length) {
					const orBuilder = rb.orBuilder();

					orBuilder.next();
					orBuilder.addFilter({ field: 'client.name' }, comparisonTypes.Wildcard, filter.value);

					orBuilder.next();
					orBuilder.addFilter({ field: 'contact.name' }, comparisonTypes.Wildcard, filter.value);

					// This one blocks using default build function because it's not wildcard...
					orBuilder.next();
					orBuilder.addFilter({ field: 'orderRow.product.name' }, comparisonTypes.Match, filter.value);

					orBuilder.next();
					orBuilder.addFilter({ field: 'user.name' }, comparisonTypes.Wildcard, filter.value);

					orBuilder.next();
					orBuilder.addFilter({ field: 'description' }, comparisonTypes.Wildcard, filter.value);

					orBuilder.done();
				}
			}
		},
		[]
	)
});
