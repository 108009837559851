import { FlowStep } from 'App/resources/Model/Flow';

type FlowStepConfigArray = [FlowStep, ...FlowStep[], [FlowStepConfigArray, FlowStepConfigArray]] | FlowStep[];

const buildPath = (steps: FlowStepConfigArray) => {
	const topParent = steps[0];
	let currentParen = topParent;

	for (const step of steps.slice(1)) {
		if (Array.isArray(step)) {
			const [yesPath, noPath] = step;
			currentParen.childYes = buildPath(yesPath);
			currentParen.childNo = buildPath(noPath);
		} else {
			currentParen.childYes = step;
			currentParen = step;
		}
	}

	return topParent;
};

export default buildPath;
