import Attribute from './Attribute';

const SalesboardAttributes = {
	id: new Attribute({
		type: Attribute.types.Number,
		field: 'id'
	}),
	description: new Attribute({
		title: 'default.description',
		type: Attribute.types.String,
		field: 'description'
	}),
	title: new Attribute({
		title: 'default.title',
		type: Attribute.types.String,
		field: 'title'
	}),
	type: new Attribute({
		title: 'default.type',
		type: Attribute.types.String,
		field: 'type'
	}),
	columnLimit: new Attribute({
		type: Attribute.types.Number,
		field: 'columnLimit'
	}),
	standard: new Attribute({
		type: Attribute.types.Boolean,
		field: 'standard'
	}),
	shared: new Attribute({
		type: Attribute.types.Boolean,
		field: 'shared'
	}),
	private: new Attribute({
		type: Attribute.types.Boolean,
		field: 'private'
	}),
	editable: new Attribute({
		type: Attribute.types.Boolean,
		field: 'editable'
	}),
	hidden: new Attribute({
		type: Attribute.types.Boolean,
		field: 'hidden'
	}),
	isStandardView: new Attribute({
		type: Attribute.types.Boolean,
		field: 'isStandardView'
	}),
	regDate: new Attribute({
		type: Attribute.types.DateTime,
		field: 'regDate'
	}),
	regBy: new Attribute({
		type: Attribute.types.Number,
		field: 'regBy'
	}),
	modDate: new Attribute({
		type: Attribute.types.DateTime,
		field: 'modDate'
	}),
	default: new Attribute({
		type: Attribute.types.Boolean,
		field: 'default'
	}),
	roles: new Attribute({
		type: Attribute.types.Array,
		field: 'roles'
	}),
	users: new Attribute({
		type: Attribute.types.Array,
		field: 'users'
	}),
	columns: new Attribute({
		type: Attribute.types.Array,
		field: 'columns',
		attr: {
			id: new Attribute({ type: Attribute.types.Number }),
			image: new Attribute({ type: Attribute.types.String }),
			sortOrder: new Attribute({ type: Attribute.types.Number }),
			sorting: new Attribute({ type: Attribute.types.Array }),
			filters: new Attribute({ type: Attribute.types.Array }),
			title: new Attribute({ type: Attribute.types.String }),
			type: new Attribute({ type: Attribute.types.String })
		}
	}),
	filters: new Attribute({
		type: Attribute.types.Array,
		field: 'filters'
	}),
	sort: new Attribute({
		type: Attribute.types.Object,
		field: 'sort'
	}),
	sorting: new Attribute({
		type: Attribute.types.Array,
		field: 'sorting'
	})
};

window.SalesboardAttributes = SalesboardAttributes;

export default SalesboardAttributes;
