import { useTranslation } from 'Components/Helpers/translate';
import React, { ComponentProps } from 'react';
import type Contact from 'App/resources/Model/Contact';
import ContactResource from 'App/resources/Contact';
import GenericSelectEntityModal from '../GenericSelectEntityModal/GenericSelectEntityModal';
import type { ModalProps } from 'App/components/Modals/Modals';
import RequestBuilder, { comparisonTypes } from 'Resources/RequestBuilder';
import ContactAttributes from 'App/babel/attributes/ContactAttributes';

type Props = ModalProps<Contact> & {
	excludeIds?: number[];
	modifyFilters?: ComponentProps<typeof GenericSelectEntityModal>['modifyFilters'];
};

const FindContactModal = ({ excludeIds, modifyFilters, ...props }: Props) => {
	const { t } = useTranslation();
	const columnTitles = ['default.client'];
	const extraAttributes = [ContactAttributes.client.attr.name.field];
	return (
		<GenericSelectEntityModal<Contact>
			{...props}
			title={`${t('default.search')} ${t('default.contact').toLowerCase()}`}
			displayAttr="name"
			displayExtraAttrs={extraAttributes}
			extraColumnTitles={columnTitles}
			fetch={(_buildRb, searchString) => {
				const rb = new RequestBuilder();
				const or = rb.orBuilder();
				or.next();
				or.addFilter({ field: 'name' }, comparisonTypes.Wildcard, searchString);
				or.next();
				or.addFilter({ field: 'email' }, comparisonTypes.WildcardEnd, searchString);
				for (const attr of extraAttributes) {
					or.next();
					or.addFilter({ field: attr }, comparisonTypes.Wildcard, searchString);
				}
				or.done();

				if (excludeIds?.length) {
					rb.addFilter({ field: 'id' }, comparisonTypes.NotEquals, excludeIds);
				}

				modifyFilters?.(rb);

				return ContactResource.find(rb.build());
			}}
		/>
	);
};

export default FindContactModal;
