import React from 'react';
import { Text, Block, RadioList, RadioItem } from '@upsales/components';
import _ from 'lodash';
import UpSelect from 'Components/Inputs/UpSelect';

type OptionData = {
	name: string;
	id: number;
};

type Props = {
	data: OptionData[];
	onSelect: (value: number) => void;
	selected: OptionData | null;
};

const ModalOptions = ({ data, onSelect, selected }: Props) => {
	if (!data.length) {
		return null;
	}
	if (data.length <= 5) {
		return (
			<RadioList onChange={value => onSelect(parseInt(value))} size="sm">
				{data.map(item => {
					return (
						<RadioItem key={item.id} value={item.id} size="sm" checked={_.isEqual(selected, item)} label="">
							<Text>{item.name}</Text>
						</RadioItem>
					);
				})}
			</RadioList>
		);
	} else {
		return (
			<Block space="mtm">
				<UpSelect
					required={true}
					onChange={({ target: { added } }: { target: { added: OptionData } }) => onSelect(added.id)}
					defaultValue={selected}
					data={data}
				/>
			</Block>
		);
	}
};

export default ModalOptions;
