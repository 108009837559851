import Resource from './Resource';
import Attributes from 'Attributes/Project';

class CallList extends Resource {
	eventName = 'campaign';

	constructor() {
		super('callLists', Attributes);

		this.notifications = {
			save: () => ({
				title: 'default.saved',
				body: 'saved.callList',
				style: 'success',
				icon: 'check',
				type: 'body'
			}),
			saveError: () => ({
				title: 'default.error',
				body: 'saveError.callList',
				style: 'error',
				icon: 'times',
				type: 'body'
			}),
			delete: () => ({
				title: 'default.deleted',
				body: 'deleted.callList',
				style: 'success',
				icon: 'check',
				type: 'body'
			}),
			deleteError: () => ({
				title: 'default.error',
				body: 'deleteError.callList',
				style: 'error',
				icon: 'times',
				type: 'body'
			})
		};
	}
}

export default new CallList();
