class EasyBookingLocalStorage<T> {
	private key: string;

	constructor(key: string) {
		this.key = key;
	}

	private getKey() {
		const client = Tools.AppService.getAccountSelf().client;
		return `ls.easyBooking.${this.key}-${client.id}-${client.userId}`;
	}

	getValue = (): T | null => {
		const item = localStorage.getItem(this.getKey());
		return item ? (JSON.parse(item) as T) : null;
	};

	setValue = (value: T) => {
		localStorage.setItem(this.getKey(), JSON.stringify(value));
	};
}

export default EasyBookingLocalStorage;
