import Tracker from './Tracker';

const settingsTracker = new Tracker('settings', {
	USER_TOGGLE_SUBSCRIPTION_REMINDER: 'userToggleSubscriptionReminder',
	CUSTOMER_REMINDER_MONTH_PERIOD: 'customerReminderMonthPeriod',
	CUSTOMER_REMINDER_ACTIVATE: 'customerReminderActivate',
	SUBSCRIPTION_REMINDER_PERIOD: 'subscriptionReminderPeriod'
});

export default settingsTracker;
