import { getSoftDeployAccessFromState } from 'App/components/hooks/featureHelper';
import { AllIWantData } from 'App/resources/AllIWant';
import IndexedDB from 'Components/Helpers/IndexedDB';
import SelfResource from 'App/resources/Self';
import logError from 'Helpers/logError';

class AllIWantDataCache {
	data: AllIWantData | null = null;

	indexedDBKey: string;
	indexedDB: IndexedDB | null = null;

	listeners: (() => void)[] = [];

	constructor() {
		try {
			this.indexedDB = new IndexedDB('cache', 'allIWant');
		} catch (err) {
			logError(err, 'AllIWantDataCache: error when initing IndexedDB');
		}
		this.indexedDBKey = '';
	}

	setData = async (data: AllIWantData) => {
		if (!this.indexedDB) {
			return;
		}

		try {
			await this.indexedDB.setValue(`${this.indexedDBKey}`, data);
		} catch (err) {
			logError(err, 'AllIWantDataCache: error when setting data');
		}
	};

	clearData = async () => {
		if (!this.indexedDB) {
			return;
		}

		try {
			await this.indexedDB.setValueWithPartialKey(this.indexedDBKey, null);
		} catch (err) {
			logError(err, 'AllIWantDataCache: error when clearing data');
		}
	};

	initSession = async () => {
		const { data: self } = await SelfResource.get();
		if (!self?.client) {
			return { hasAllIWantCache: false };
		}
		this.indexedDBKey = `data-${self.client.id}-${self.client.userId}`;

		const hasAllIWantCache = getSoftDeployAccessFromState(self, 'ALLIWANT_CACHE');
		return { hasAllIWantCache };
	};

	registerListener = (listener: string) => {
		try {
			return Tools.$rootScope.$on(listener, () => {
				this.clearData();
			});
		} catch (err) {
			logError(err, 'AllIWantDataCache: error when registering listeners');
			return () => {};
		}
	};

	registerListeners = () => {
		this.listeners.forEach(listener => listener());
		this.listeners = [
			this.registerListener('userParam.updated'),
			this.registerListener('documentTemplate.added'),
			this.registerListener('documentTemplate.updated'),
			this.registerListener('documentTemplate.deleted')
		];
	};

	getDataPromise = async () => {
		const { hasAllIWantCache } = await this.initSession();

		if (!hasAllIWantCache || !this.indexedDB) {
			return Tools.AllIWant.get();
		}
		try {
			this.registerListeners();

			const dataFromDb = await this.indexedDB.getValue<AllIWantData>(`${this.indexedDBKey}`);
			if (dataFromDb) {
				if (dataFromDb.self) {
					Tools.AppService.setAccountSelf(dataFromDb.self);
				}
				if (dataFromDb.customerSelf) {
					Tools.AppService.setSelf(dataFromDb.customerSelf);
				}
				return Promise.resolve({ data: dataFromDb });
			}
			const res = await Tools.AllIWant.get();
			this.setData(res.data);
			return res;
		} catch (err) {
			return Tools.AllIWant.get();
		}
	};
}

const allIWantDataCache = new AllIWantDataCache();
export default allIWantDataCache;
