import React from 'react';
import { get } from 'lodash';
import { Text, Link, Icon } from '@upsales/components';
import TimelineRow from 'Components/TimelineRow';

const ActivityTimelineBookAppointment = ({ options = {}, event, hideIcon }) => {
	const t = Tools.$translate;
	const lang = {
		bookedAppointment: t('activity.outcomeLog.bookedAppointment'),
		with: t('activity.outcomeLog.with'),
		closedThisActivity: t('activity.outcomeLog.closedThisActivity')
	};
	const { user, data } = event || {};
	const customerId = Tools.AppService.getCustomerId();
	const contactName = get(data, 'contact.name', '');
	const contactId = get(data, 'contact.id', '');
	const appointmentId = get(data, 'appointment.id', '');
	const appointmentName = get(data, 'appointment.description', '');
	const openAppointment = () => {
		Tools.$upModal.open('editAppointment', { id: appointmentId });
	};
	const date = get(event.data, 'closeDate');
	const userName = get(user, 'name');

	const title = (
		<Text>
			{`${lang.bookedAppointment} `}
			<Link onClick={openAppointment}>{appointmentName}</Link>
			{` ${lang.with} `}
			<Link
				onClick={e => e.stopPropagation()}
				href={Tools.$state.href('contact.dashboard', {
					customerId: customerId,
					id: contactId
				})}
			>
				{contactName}
			</Link>
		</Text>
	);
	const subTitle = date ? (
		<Text size="sm" color="grey-11">
			{moment(date).format('L LT')} <span dangerouslySetInnerHTML={{ __html: '&#8226;' }} /> {userName}
		</Text>
	) : null;
	const subSection = (
		<Text className="subSection">
			<Icon name="check" space="mrl" color="black" />
			{lang.closedThisActivity}
		</Text>
	);

	return (
		<TimelineRow
			icon={hideIcon ? undefined : 'calendar'}
			options={options}
			event={event}
			title={title}
			subTitle={subTitle}
			subSection={subSection}
		/>
	);
};

export default ActivityTimelineBookAppointment;
