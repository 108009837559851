import { Text, Button, Title, Link, Flex } from '@upsales/components';
import bemClass from '@upsales/components/Utils/bemClass';
import moment from 'moment';
import React from 'react';
import SignalsAddMenu from 'App/pages/Prospecting/Signals/SignalsAddMenu';

import './UpdatesItem.scss';

const UpdatesItem = ({ item }: any) => {
	const t = Tools.$translate;
	const classes = new bemClass('UpdatesItem');

	if (item.value && typeof item.value === 'string') {
		item.value = JSON.parse(item.value);
	}

	const getClickState = () => {
		switch (item.title) {
			case 'accountsNew':
			case 'accountsChanged':
			case 'listingNew':
			case 'listingRemoved':
			case 'shareIssueNew':
				return {
					clickState: 'account.financial',
					lang: 'event.signals.click.financial',
					img: 'img/report.gif'
				};

			case 'directorsChanged':
				return { clickState: 'account.contacts', lang: 'event.signals.click.board', img: 'img/board.gif' };
			default:
				return { clickState: 'account.dashboard', lang: 'event.signals.click.company' };
		}
	};

	const getLangTag = () => {
		if (item.title === 'directorsChanged') {
			if (item.value?.added?.length === 1 && item.value?.removed?.length === 0) {
				return 'event.signals.directorsAddedSingular';
			} else if (item.value?.added?.length === 0 && item.value?.removed?.length === 1) {
				return 'event.signals.directorsRemovedSingular';
			} else if (item.value?.added?.length === 1 && item.value?.removed?.length === 1) {
				return 'event.signals.directorsChangedSingular';
			} else if (item.value?.added?.length > 1 && item.value?.removed?.length === 0) {
				return 'event.signals.directorsAddedPlural';
			} else if (item.value?.added?.length === 0 && item.value?.removed?.length > 1) {
				return 'event.signals.directorsRemovedPlural';
			} else {
				return 'event.signals.directorsChangedPlural';
			}
		} else {
			return `event.signals.${item.title}`;
		}
	};

	const clickOptions = getClickState();
	const langTag = getLangTag();

	const onClick = () => {
		if (clickOptions) {
			const customerId = Tools.AppService.getCustomerId();
			Tools.$state.go(clickOptions.clickState, { customerId, id: item.clientId });
		}
	};

	const accountHref = Tools.$state.href('account.dashboard', {
		id: item.clientId,
		customerId: Tools.AppService.getCustomerId()
	});

	const getLangValue = (item: { name: string; title: string; value: any }) => {
		if (item.title === 'statusChanged') {
			return { status: Tools.$translate(`default.companyStatus.${(item.value?.status || '').toUpperCase()}`) };
		} else if (item.title === 'nameChanged') {
			return { name: item.value.newName || item.name };
		} else {
			return item.value;
		}
	};

	return (
		<>
			<div className={classes.elem('text-container').b()}>
				<div className={classes.elem('text').b()}>
					<Link onClick={e => e.stopPropagation()} href={accountHref}>
						<Text bold size="md">
							{item.name}
						</Text>
					</Link>
					<Title onClick={onClick} className={classes.elem('title').b()} size="md">
						{t(langTag, getLangValue(item))}
					</Title>
				</div>
				{clickOptions.img ? (
					<div className={classes.elem('image').b()}>
						<img src={clickOptions.img}></img>
					</div>
				) : null}
			</div>
			<Flex justifyContent="space-between" space="ptl pbl pll prl">
				<Flex gap="u3">
					<Button color="super-light-green" onClick={onClick}>
						{t(clickOptions.lang)}
					</Button>
					<SignalsAddMenu item={item} />
				</Flex>
				<Flex gap="u1" justifyContent="flex-end" alignItems="center">
					<Text size="sm" color="grey-10">
						{moment(item.date).format('LL')}
					</Text>
				</Flex>
			</Flex>
		</>
	);
};

export default UpdatesItem;
