import PropTypes from 'prop-types';
import React from 'react';
import entityName from '../../Helpers/EntityName';

const propTypes = {
	entity: PropTypes.string.isRequired,
	numSelected: PropTypes.number
};

class GenericBody extends React.Component {
	constructor(props) {
		super(props);
		const { entity, numSelected, fullModalBody } = props;
		const t = Tools.$translate;
		const entityText = t(entityName(entity, numSelected)).toLowerCase();
		this.lang = {
			title: this.titleFunction(numSelected, entityText, fullModalBody, t),
			irreversible: t('admin.modal.actionIrreversible'),
			body: t(numSelected === 1 ? 'admin.modal.genericBodySingle' : 'admin.modal.genericBodyMulti', {
				entity: entityText
			}),
			calendarIntegraionDeleteInfo:
				entity.toLowerCase() === 'appointment' && Tools.AppService.getCalendarIntegrations().length
					? t('default.calendarIntegraionDeleteInfo')
					: ''
		};
	}

	render() {
		const { lang } = this;

		return (
			<div className="content-delete-confirm">
				{Tools.FeatureHelper.hasSoftDeployAccess('REACT_ALERT_MODAL') ? null : (
					<h1>{lang.title}</h1>
				)}
				<div className="confirm-body">
					<span className="confirm-warning">{lang.irreversible}</span>
					{lang.body} {lang.calendarIntegraionDeleteInfo}
				</div>
			</div>
		);
	}

	titleFunction(numSelected, entityText, fullModalBody, t) {
		if (fullModalBody) {
			return t('admin.modal.deleteThisEntity', { entity: entityText });
		} else {
			return (
				t('admin.modal.confirmDeleteAccount') +
				(numSelected === 1 ? entityText : `${numSelected} ${entityText}`)
			);
		}
	}
}

GenericBody.propTypes = propTypes;

export default GenericBody;
