import moment from 'moment';
import RequestBuilder, { comparisonTypes } from 'App/babel/resources/RequestBuilder';
import { ACTIVITY_PRIORITY } from 'App/babel/enum/activity';
import logError from 'App/babel/helpers/logError';

export const views = {
	ASSIGN: 'ASSIGN',
	ACTIVITIES: 'ACTIVITIES',
	ASSIGNED: 'ASSIGNED',
	CLOSEACTIVITY: 'CLOSEACTIVITY'
};

export const initialState = {
	client: null,
	loading: true,
	view: views.ASSIGN,
	activitiesLoading: true,
	activitiesError: false,
	activitiesAndAppointments: [],
	notes: '',
	selectedItem: null,
	assign: {
		step: 1,
		user: null,
		role: null,
		sendEmail: false,
		setAsManager: true,
		description: '',
		notes: '',
		priority: ACTIVITY_PRIORITY.NONE,
		callListId: null,
		projectId: null,
		assigning: false,
		contact: null,
		advanced: false,
		advancedData: {}
	},
	contacts: [],
	contactsLoading: false,
	contactSearch: '',
	assignableUsers: [],
	blinkUserStep: false
};

const RESET_STATE = '[AssignModal] Reset state';
const SET_CLIENT = '[AssignModal] Set client';
const SET_LOADING = '[AssignModal] Set loading';
const SET_ACTIVITIES_LOADING = '[AssignModal] Set activities loading';
const SET_APPOINTIVITIES = '[AssignModal] Set appointivities';
const SET_VIEW = '[AssignModal] Set view';
const SET_NOTES = '[AssignModal] Set notes';
const SET_SELECTED_ITEM = '[AssignModal] Set selected item';
const SET_ASSIGN_DATA = '[AssignModal] Set assign data';
const SET_ADVANCED_ASSIGN_DATA = '[AssignModal] Set advanced assign data';
const SET_CONTACTS = '[AssignModal] Set contacts';
const SET_CONTACTS_LOADING = '[AssignModal] Set contacts loading';
const SET_CONTACT_SEARCH = '[AssignModal] Set contact search';
const SET_ASSIGNABLE_USERS = '[AssignModal] Set assignable users';
const SET_ACTIVITIES_ERROR = '[AssignModal] Set activities error';

const getActivities = clientId => {
	const rb = new RequestBuilder();
	rb.addFilter({ field: 'client.id' }, comparisonTypes.Equals, clientId);
	rb.addFilter({ field: 'closeDate' }, comparisonTypes.Equals, null);
	rb.addSort({ field: 'date' }, false);

	return Tools.Activity.customer(Tools.AppService.getCustomerId())
		.find(rb.build())
		.then(res => {
			if (res && Array.isArray(res.data)) {
				return res.data;
			}
			return [];
		});
};

const getAppointments = clientId => {
	const rb = new RequestBuilder();
	rb.addFilter({ field: 'client.id' }, comparisonTypes.Equals, clientId);
	rb.addFilter({ field: 'endDate' }, comparisonTypes.GreaterThanEquals, moment().utc().format());
	rb.addSort({ field: 'date' }, false);
	rb.addSort({ field: 'name' }, true);

	return Tools.Appointment.customer(Tools.AppService.getCustomerId())
		.find(rb.build())
		.then(res => {
			if (res && Array.isArray(res.data)) {
				return res.data;
			}
			return [];
		});
};

const getAssignableUsers = client => {
	const metadata = Tools.AppService.getMetadata();
	var data = [
		{
			name: Tools.$translate('default.user'),
			children: Tools.AppService.getActiveUsers()
		}
	];

	if (Array.isArray(client.users) && client.users.length) {
		data.unshift({
			name: Tools.$translate(
				metadata.params.teamAccountManager ? 'default.accountManagers' : 'default.accountManager'
			),
			children: client.users
		});
	}

	return data;
};

/*********** Actions **********/
const setClient = client => ({ type: SET_CLIENT, client });
const setActivitiesLoading = value => ({ type: SET_ACTIVITIES_LOADING, value });
const setAppointivities = data => ({ type: SET_APPOINTIVITIES, data });
export const changeView = view => ({ type: SET_VIEW, view });
const setInitialView = client => {
	const view =
		client.processedBy && client.processedBy.user && client.processedBy.user.id ? views.ACTIVITIES : views.ASSIGN;
	return changeView(view);
};
export const setLoading = value => ({ type: SET_LOADING, value });
export const resetState = value => ({ type: RESET_STATE, value });
export const setSelectedItem = item => ({ type: SET_SELECTED_ITEM, item });
export const setAssignData = data => ({ type: SET_ASSIGN_DATA, data });
export const setAdvAssignData = data => ({ type: SET_ADVANCED_ASSIGN_DATA, data });
export const setAssignableUsers = users => ({ type: SET_ASSIGNABLE_USERS, users });
export const setNotes = () => ({ type: SET_NOTES });
const setContacts = data => ({ type: SET_CONTACTS, data });
const setContactsLoading = value => ({ type: SET_CONTACTS_LOADING, value });
const setActivitiesError = value => ({ type: SET_ACTIVITIES_ERROR, value });
const getActivitiesAndAppointments = () => (dispatch, getState) => {
	const { client } = getState().AssignModal;
	dispatch(setActivitiesLoading(true));
	dispatch(setActivitiesError(false));
	return Promise.all([getActivities(client.id), getAppointments(client.id)])
		.then(res => {
			const activitiesAndAppointments = res[0].concat(res[1]).sort((a, b) => {
				if (+new Date(a.date) === +new Date(b.date)) {
					return a.id < b.id ? 1 : -1;
				}
				return +new Date(a.date) < +new Date(b.date) ? 1 : -1;
			});

			dispatch(setAppointivities(activitiesAndAppointments));
			dispatch(setActivitiesLoading(false));
		})
		.catch(() => {
			dispatch(setActivitiesLoading(false));
			dispatch(setActivitiesError(true));
		});
};

const getContacts = () => (dispatch, getState) => {
	dispatch(setContactsLoading(true));
	const { client, contactSearch } = getState().AssignModal;
	const rb = new RequestBuilder();
	rb.addFilter(Tools.Contact.attr.client.attr.id, comparisonTypes.Equals, client.id);
	rb.addFilter(Tools.Contact.attr.active, comparisonTypes.Equals, true);
	rb.addSort(Tools.Contact.attr.score, false);
	rb.fields = ['id', 'name', 'score', 'title'];

	if (contactSearch) {
		rb.addFilter(Tools.Contact.attr.name, comparisonTypes.Wildcard, contactSearch);
	}
	rb.limit = 5;
	Tools.Contact.find(rb.build())
		.then(res => {
			dispatch(setContacts(res.data));
			dispatch(setContactsLoading(false));
		})
		.catch(e => {
			logError(e, 'Failed getting contacts');

			dispatch(setContacts([]));
			dispatch(setContactsLoading(false));
		});
};

let contactSearchTimeout = null;
export const setContactSearch = value => dispatch => {
	dispatch({ type: SET_CONTACT_SEARCH, value });
	if (contactSearchTimeout) {
		clearTimeout(contactSearchTimeout);
	}
	contactSearchTimeout = setTimeout(() => {
		dispatch(getContacts());
	}, 200);
};

export const onOpen = params => dispatch => {
	dispatch({ type: RESET_STATE });

	let clientPromise;
	let contactPromise;

	// If the client we got is missing managers or if we come from the leads list we get the client
	if (!params.client.users || params.from === 'listLeads') {
		clientPromise = Tools.Account.customer(Tools.AppService.getCustomerId())
			.get(params.client.clientId || params.client.id)
			.then(res => {
				return res.data;
			});
	} else {
		clientPromise = Promise.resolve(params.client);
	}
	if (params.contactId) {
		contactPromise = Tools.Contact.get(params.contactId).then(res => res.data);
	} else {
		contactPromise = Promise.resolve(null);
	}

	if (params.formId && params.formFields?.length) {
		Tools.Form.get(params.formId)
			.then(({ data: form }) => {
				let notes = '';

				form.fields.forEach(field => {
					const match = params.formFields.find(f => f.name === field.name);

					if (match) {
						notes += `${field.title}\n${match.value}\n`;
					}
				});

				if (notes) {
					dispatch(setAssignData({ notes }));
				}
			})
			.catch(e => {
				logError(e, 'Failed getting form');
			});
	}

	return Promise.all([clientPromise, contactPromise]).then(([client, contact]) => {
		dispatch(setClient(client));
		dispatch(getActivitiesAndAppointments());
		dispatch(setInitialView(client));
		const users = getAssignableUsers(client);
		dispatch(setAssignableUsers(users));
		dispatch(getContacts());
		if (contact) {
			dispatch(setAssignData({ contact }));
		}
		dispatch(setLoading(false));
	});
};

export const closeActivity = () => (dispatch, getState) => {
	const { notes, selectedItem } = getState().AssignModal;

	var data = {
		id: selectedItem.id,
		closeDate: new Date(),
		notes: (selectedItem.notes ? selectedItem.notes + '\n\n' : '') + notes
	};

	Tools.Activity.customer(Tools.AppService.getCustomerId())
		.save(data, { updateSuccessBody: 'default.activityClosed' })
		.then(() => {
			const { activitiesAndAppointments, client } = getState().AssignModal;

			// Remove closed activity
			const newActivitiesAndAppointments = _.filter(activitiesAndAppointments, item => {
				return item.id !== selectedItem.id;
			});
			dispatch(setAppointivities(newActivitiesAndAppointments));

			dispatch(setNotes(''));
			dispatch(changeView(views.ASSIGNED));

			setTimeout(function () {
				dispatch(changeView(newActivitiesAndAppointments.length ? views.ACTIVITIES : views.ASSIGN));

				if (!newActivitiesAndAppointments.length) {
					delete client.processedBy;
					dispatch(setClient(client));
				}
			}, 1500);
		})
		.catch(e => {
			logError(e, 'Failed saving activity');
		});
};

export const updateUser = (item, users) => (dispatch, getState) => {
	dispatch(setActivitiesLoading(true));

	const resource = item.isAppointment ? Tools.Appointment : Tools.Activity;

	const data = { id: item.id, users: Array.isArray(users) ? users : [users] };

	resource
		.customer(Tools.AppService.getCustomerId())
		.save(data)
		.then(res => {
			const { activitiesAndAppointments } = getState().AssignModal;

			const index = activitiesAndAppointments.findIndex(item => item.id === res.data.id);

			if (index > -1) {
				activitiesAndAppointments[index] = res.data;
				dispatch(setAppointivities(activitiesAndAppointments));
			}

			dispatch(setActivitiesLoading(false));
		})
		.catch(() => {
			dispatch(setActivitiesLoading(false));
		});
};

export const assign = () => async (dispatch, getState) => {
	const {
		client,
		assign: { setAsManager, description, priority, user, notes, contact, projectId, callListId }
	} = getState().AssignModal;
	dispatch(setAssignData({ assigning: true }));
	const params = {
		setUserAsAccountManager: setAsManager,
		replaceCurrentAccountManager: false, // we will try this for now
		dontSendEmail: false,
		activityDescription: description,
		activityPriority: priority,
		activityNote: notes,
		contactId: contact ? contact.id : null,
		notify: true
	};
	if (projectId) {
		params.activityProject = projectId;
	}

	if (callListId) {
		params.activityCallList = callListId;
	}
	if (Tools.FeatureHelper.hasSoftDeployAccess('TODO_LIST')) {
		const self = Tools.AppService.getSelf();
		params.Source = { type: 'assign', id: self.id };
	}

	try {
		const { data } = await Tools.Account.customer(Tools.AppService.getCustomerId()).assign(client, user, params);
		dispatch(setAssignData({ ...initialState.assign }));
		if (Tools.FeatureHelper.isAvailable(Tools.FeatureHelper.Feature.ACTIVITIES_AND_APPOINTMENTS) && data.activity) {
			const meta = Tools.AppService.getMetadata();
			const activityType = Tools.AppService.getActivityTypes('activity').find(
				a => (a.id = meta.params.DefaultActivityTypeId)
			);
			Tools.$rootScope.$broadcast('activity.added', {
				id: data.activity,
				client,
				contacts: contact ? [contact] : null,
				users: [user],
				date: moment().startOf('day').toDate(),
				notes,
				description,
				priority,
				isAppointment: false,
				closeDate: null,
				activityType
			});

			const metadata = Tools.AppService.getMetadata();

			if (setAsManager) {
				if (metadata.params.teamAccountManager && client.users.findIndex(u => u.id === user.id) === -1) {
					client.users.push(user);
					dispatch(setClient(client));
				} else if (!metadata.params.teamAccountManager) {
					client.users = [user];
					dispatch(setClient(client));
				}
			}

			dispatch(getActivitiesAndAppointments());
		}

		dispatch(changeView(views.ACTIVITIES));

		return { activity: data.activity, user };
	} catch (e) {
		dispatch(setAssignData({ assigning: false }));
	}
};

const ACTION_HANDLERS = {
	[RESET_STATE]: () => ({ ...initialState }),
	[SET_LOADING]: (state, action) => ({ ...state, loading: action.value }),
	[SET_ACTIVITIES_LOADING]: (state, action) => ({ ...state, activitiesLoading: action.value }),
	[SET_CLIENT]: (state, action) => ({ ...state, client: action.client }),
	[SET_VIEW]: (state, action) => ({ ...state, view: action.view }),
	[SET_APPOINTIVITIES]: (state, action) => ({ ...state, activitiesAndAppointments: action.data }),
	[SET_NOTES]: (state, action) => ({ ...state, notes: action.notes }),
	[SET_SELECTED_ITEM]: (state, action) => ({ ...state, selectedItem: action.item }),
	[SET_ASSIGN_DATA]: (state, action) => ({
		...state,
		assign: { ...state.assign, ...action.data },
		blinkUserStep: !!(action.data.user || action.data.role)
	}),
	[SET_ADVANCED_ASSIGN_DATA]: (state, action) => ({
		...state,
		assign: {
			...state.assign,
			advancedData: { ...state.assign.advancedData, ...action.data }
		}
	}),
	[SET_CONTACTS]: (state, action) => ({ ...state, contacts: action.data }),
	[SET_CONTACTS_LOADING]: (state, action) => ({ ...state, contactsLoading: action.value }),
	[SET_CONTACT_SEARCH]: (state, action) => ({ ...state, contactSearch: action.value }),
	[SET_ASSIGNABLE_USERS]: (state, action) => ({ ...state, assignableUsers: action.users }),
	[SET_ACTIVITIES_ERROR]: (state, action) => ({ ...state, activitiesError: action.value })
};

export default (state = initialState, action) => {
	const handler = ACTION_HANDLERS[action.type];
	return handler ? handler(state, action) : state;
};
