import React from 'react';
import { Toggle } from '@upsales/components';

const switchRenderType = ({ row, config, object, getUser, runStandardIntegration, runClick }) => {
	var toggleProps = {};

	if (row.state === true) {
		toggleProps.checked = true;
	}

	if (row.click) {
		toggleProps.onChange = event => {
			if (event && event.stopPropagation) {
				event.stopPropagation();
			}

			if (row.click.type === 'widgetRow') {
				runStandardIntegration({
					type: 'widgetRow',
					data: { object: object, user: getUser() },
					typeId: row.click.name,
					integrationId: config.integrationId
				});
			} else {
				runClick(row);
			}
		};
	}

	return (
		<div className="widget-switch-wrapper">
			<Toggle {...toggleProps} />
		</div>
	);
};

export default switchRenderType;
