const mapSocialEvent = socialEvent => {
	// SocialEvent dates will have to be converted to utc before passed to the api

	// We set this here until we can change it for every event
	socialEvent.timezone = window.userTimezone;

	// Date/Time
	if (socialEvent.date && socialEvent.time) {
		const timeMoment = moment(`1991-01-01 ${socialEvent.time}`, 'YYYY-MM-DD LT');
		socialEvent.date = moment(socialEvent.date).hours(timeMoment.hours()).minutes(timeMoment.minutes()).format();
	}

	// EndDate/EndTime
	if (socialEvent.endDate && socialEvent.endTime) {
		const endTimeMoment = moment(`1991-01-01 ${socialEvent.endTime}`, 'YYYY-MM-DD LT');

		socialEvent.endDate = moment(socialEvent.endDate)
			.hours(endTimeMoment.hours())
			.minutes(endTimeMoment.minutes())
			.format();
	}

	// CancelDate
	if (socialEvent.cancelDate) {
		socialEvent.cancelDate = moment(socialEvent.cancelDate);
	}

	if (!socialEvent.draft && moment().isAfter(socialEvent.endDate)) {
		socialEvent.passed = true;
	}

	socialEvent.time = undefined;
	socialEvent.endTime = undefined;

	return socialEvent;
};

export default mapSocialEvent;
