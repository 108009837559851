import * as Sentry from '@sentry/browser';

export default (
	err: any,
	msg = 'There was an error in the application',
	...rest: Parameters<(typeof console)['error']>[1]
) => {
	// If we got an error we pass it to sentry
	if (err) {
		err = ensureError(err);
		console.error(msg, ...rest, err); // log error to console

		const isClientError =
			(String(err.code) && String(err.code).startsWith('4')) ||
			(String(err.status) && String(err.status).startsWith('4'));
		if (!isClientError) {
			Sentry.captureException(err);
		}
	}
};

function ensureError(err: unknown) {
	if (err instanceof Error) {
		return err;
	}
	if (typeof err === 'object' && err !== null) {
		Sentry.addBreadcrumb({
			category: 'logError',
			message: 'logError received object',
			level: Sentry.Severity.Log,
			data: err
		});
		const theError = new Error((err as { message?: any }).message || JSON.stringify(err)) as unknown as Error & {
			[key: string]: any;
		};
		TypedObject.keys(err).forEach(key => {
			theError[key] = err[key];
		});
		return theError;
	}

	return new Error(err?.toString());
}
