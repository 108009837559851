import React, { useState, useEffect, useRef } from 'react';
import ReportcenterFilter from './ReportcenterFilter';
import { Block } from '@upsales/components';
import bemClass from '@upsales/components/Utils/bemClass';
import T from 'Components/Helpers/translate';
import ReportcenterFilterRow from './ReportcenterFilterRow';
import { removeItem } from 'Store/helpers/array';
import { RCDashboardFilter } from 'Resources/ReportDashboard';
import SocialEvent from 'App/resources/Model/SocialEvent';
import SocialEventResource from 'App/babel/resources/SocialEvents';
import logError from 'Helpers/logError';
import { makeCancelable, CancelablePromise } from 'App/babel/helpers/promise';
import './ReportcenterFilterEvent.scss';
import ReportcenterFilterSearchbar from './ReportcenterFilterSearchbar';

interface Props {
	onChange: (type: keyof RCDashboardFilter, values: number[], comparison: string) => void;
	values: RCDashboardFilter;
	updateFilterHeight: () => void;
	families: string[];
}

export const renderSelected = (values: RCDashboardFilter) => {
	let res = `${T('default.socialEvent')}: `;

	if (values?.Event?.value?.length) {
		const length = values.Event.value.length;
		if (length >= 1) {
			res += `${length} ${(values.Event.comparison === 'eq'
				? T('default.selected')
				: T('default.excluded', { count: length })
			).toLowerCase()}`;
		}
	}

	return res;
};

const ReportcenterFilterEvent = ({ onChange, families, ...props }: Props) => {
	const classes = new bemClass('ReportcenterFilterEvent');
	const [searchStr, setSearchStr] = useState('');
	const [events, setEvents] = useState<SocialEvent[]>([]);

	const t = useRef<number>();
	const p = useRef<CancelablePromise>();
	useEffect(() => {
		clearTimeout(t.current);
		t.current = window.setTimeout(() => {
			p.current?.cancel();
			p.current = makeCancelable(
				SocialEventResource.find({ q: { a: 'name', c: 'src', v: searchStr }, sort: '-id', limit: 50 })
			);
			p.current.promise
				.then(res => setEvents(res.data))
				.catch(err => logError(err, 'Could not find social events'));
		}, 300);
		return () => {
			clearTimeout(t.current);
			p.current?.cancel();
		};
	}, [searchStr]);

	const array = events.filter(item => item.name.toLowerCase().indexOf(searchStr.toLowerCase()) !== -1);
	const value = props.values?.Event?.value || [];
	const comparison = props.values?.Event?.comparison ?? 'eq';
	const isExclude = comparison === 'ne';

	return (
		<ReportcenterFilter
			className={classes.b()}
			renderSelected={() => renderSelected(props.values)}
			getSelectedNames={() => events.filter(s => value.includes(s.id)).map(s => s.name)}
			icon="rocket"
			placeholder={`${T('default.socialEvent')}: ${T('reportcenter.filter.notActive')}`}
			value={value}
			resetFilter={() => onChange('Event', [], 'eq')}
			{...props}
		>
			<Block space="ptm prm pbm plm" backgroundColor="white" className={classes.elem('list-select').b()}>
				<Block space="mtm">
					<ReportcenterFilterSearchbar<number[]>
						setSearchStr={value => setSearchStr(value)}
						searchStr={searchStr}
						placeholder={`${T('default.search')} ${T('default.socialEvent').toLowerCase()}`}
						isExclude={isExclude}
						onChange={onChange}
						field="Event"
						value={value}
					/>
				</Block>
			</Block>
			<ReportcenterFilterRow
				key={0}
				onClick={() => {
					onChange('Event', [], 'eq');
				}}
				selected={!value.length}
				title={T('filters.noFilter')}
			/>
			{array.map(row => (
				<ReportcenterFilterRow
					key={row.id}
					onClick={() => {
						const i = value.indexOf(row.id);
						onChange('Event', i !== -1 ? removeItem(value, i) : [...value, row.id], comparison);
					}}
					selected={value.indexOf(row.id) !== -1}
					title={row.name}
				/>
			))}
		</ReportcenterFilter>
	);
};

export default ReportcenterFilterEvent;
