import _ from 'lodash';
import Notes from 'Resources/Notes';

export const initialState = {
	notes: [],
	groupedNotes: [],
	message: '',
	submitting: false,
	loading: false
};

const groupNotes = notes => {
	const grouped = _.groupBy(notes, n => moment(n.date).format('YYYY-MM-DD'));
	const groupedNotes = [];
	Object.keys(grouped).forEach(dateKey => {
		groupedNotes.push({
			date: new Date(dateKey),
			messages: grouped[dateKey]
		});
	});

	return groupedNotes;
};

const SET_NOTES = '[EditOrder] SET_NOTES';
const SET_MESSAGE = '[EditOrder] SET_MESSAGE';
const ADD_NOTE = '[EditOrder] ADD_NOTE';
const SET_SUBMITTING = '[EditOrder] SET_SUBMITTING';
const SET_ORDER_ID = '[EditOrder] SET_ORDER_ID';
const SET_LOADING = '[EditOrder] SET_LOADING';

const reducer = (state = initialState, action) => {
	let groupedNotes;
	let notes;
	switch (action.type) {
		case SET_NOTES:
			notes = [...action.notes];
			groupedNotes = groupNotes(notes);
			return { ...state, notes, groupedNotes };
		case ADD_NOTE:
			notes = [...state.notes, action.note];
			groupedNotes = groupNotes(notes);
			return { ...state, notes, groupedNotes };
		case SET_MESSAGE:
			return { ...state, message: action.value };
		case SET_SUBMITTING:
			return { ...state, submitting: action.value };
		case SET_ORDER_ID:
			return { ...state, orderId: action.id };
		case SET_LOADING:
			return { ...state, loading: action.value };
		default:
			return state;
	}
};

export default reducer;

/*********** Actions **********/

export const setNotes = notes => {
	return { type: SET_NOTES, notes };
};

export const addNote = note => {
	return { type: ADD_NOTE, note };
};

export const setMessage = value => {
	return { type: SET_MESSAGE, value };
};

export const setSubmitting = value => {
	return { type: SET_SUBMITTING, value };
};

export const setOrderId = id => {
	return { type: SET_ORDER_ID, id };
};

export const setLoading = value => {
	return { type: SET_LOADING, value };
};

/***** Dispatched actions *****/

export const getNotes = () => (dispatch, getState) => {
	dispatch(setLoading(true));
	const { orderId } = getState().EditOrder;
	Notes.find({ orderId }).then(res => {
		dispatch(setNotes(res.data));
		dispatch(setLoading(false));
	});
};

export const submitNote = () => (dispatch, getState) => {
	dispatch(setSubmitting(true));
	const { message, orderId } = getState().EditOrder;
	return Notes.save({ message, orderId }).then(res => {
		dispatch(setSubmitting(false));
		dispatch(addNote(res.data));
		dispatch(setMessage(''));
	});
};
