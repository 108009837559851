import Resource from './Resource';
import parseMailTemplate from './parsers/mailTemplate';
import config from 'App/babel/config';
import Attributes from 'Attributes/MailTemplateAttributes';
import getAngularModule from 'App/babel/angularHelpers/getAngularModule';

export type UsedInData = Array<
	| { id: string; name: string; usedCount: number; resourceType: 'flowStep' }
	| { id: string; name: string; type: string; resourceType: 'socialEventEmail' }
>;

class MailTemplate extends Resource {
	eventName: 'mailTemplate';
	constructor() {
		super('mail/templates', Attributes);

		this.eventName = 'mailTemplate';

		this.parse = parseMailTemplate;

		this.notifications = {
			save: () => ({
				title: 'default.saved',
				body: 'saved.mailTemplate',
				style: 'success',
				icon: 'check',
				type: 'body'
			}),
			saveError: data => ({
				title: 'default.error',
				body: 'saveError.mailTemplate',
				style: 'error',
				icon: 'times',
				type: 'body',
				error: data?.error
			}),
			delete: () => ({
				title: 'default.deleted',
				body: 'deleted.mailTemplate',
				style: 'success',
				icon: 'check',
				type: 'body'
			}),
			deleteError: data => ({
				title: 'default.error',
				body: 'deleteError.template',
				style: 'error',
				icon: 'times',
				type: 'body',
				error: data?.error
			})
		};
	}

	new(overrides = {}) {
		const self = getAngularModule('AppService').getSelf();
		return {
			name: '',
			user: self,
			subject: '',
			body: '',
			bodyJson: null,
			from: self.email,
			fromName: self.name,
			usedCounter: 0,
			active: 0,
			private: false,
			roles: [],
			attachments: [],
			...overrides
		};
	}

	mailBodyPreview(type: string, id: number | string) {
		if (!id) {
			id = type;
			type = 'template';
		}
		return `${config.URL}${config.API}/file/mailBodyPreview/${type}/${id}`;
	}

	async getRecentlyUsed() {
		const res = await this._getRequest('recentlyUsed', { methodName: 'getRecentlyUsed' });
		return res.data;
	}

	async getUsedIn(id: number): Promise<UsedInData> {
		const res = await this._getRequest(`usedIn/${id}`, { methodName: 'getUsedIn' });
		return res.data;
	}
}

const resource = new MailTemplate();

export default resource;
