import PropTypes from 'prop-types';
import { Component } from 'react';
import T from 'Components/Helpers/translate';

class UpSegment extends Component {
	componentDidUpdate() {
		const selectHelper = ReactTemplates.TOOLS.selectHelper;
		selectHelper.updateValue.call(this);
	}

	componentDidMount() {
		const Segment = Tools.Segment;
		let opts = {
			resource: Segment,
			required: this.props.required || false,
			multiple: this.props.multiple || false,
			placeholder: this.props.placeholder,
			onChange: this.props.onChange,
			ajax: true,
			asIds: false,
			idAttr: Segment.attr.id,
			titleAttr: Segment.attr.name,
			sorting: { field: Segment.attr.name, ascending: true },
			emptyMsg: T('default.noName'),
			filterType: 'contact',
			formatResult: this.props.formatResult || ((object, container, query, escape) => escape(object.name)),
			formatSelection: this.props.formatSelection || ((object, container, escape) => escape(object.name)),
			filters: rb => {
				if (this.props.onlyActive) {
					rb.addFilter(Segment.attr.active, rb.comparisonTypes.Equals, true);
				}
			}
		};

		const input = jQuery(this._input);

		const selectHelper = ReactTemplates.TOOLS.selectHelper;
		opts = _.merge({}, opts, this.props.options);

		selectHelper.getSelect2Options(this, opts, input, options => {
			if (this.props.onlyWithoutFlows) {
				options.data = _.filter(options.data, function (o) {
					return !o.flowStatus;
				});
			}

			input.select2(options);
		});
	}

	componentWillUnmount() {
		const input = jQuery(this._input);
		input.select2('destroy');
	}

	render() {
		const selectHelper = ReactTemplates.TOOLS.selectHelper;
		return selectHelper.getInputComponent(this);
	}
}

UpSegment.propTypes = {
	onlyWithoutFlows: PropTypes.bool,
	onlyActive: PropTypes.bool,
	multiple: PropTypes.bool,
	onChange: PropTypes.func.isRequired,
	required: PropTypes.bool,
	placeholder: PropTypes.string,
	options: PropTypes.object,
	formatResult: PropTypes.func,
	formatSelection: PropTypes.func
};

export default UpSegment;
