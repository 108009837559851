import React, { Component, ReactNode } from 'react';
import BemClass from '@upsales/components/Utils/bemClass';
import { Card, CardContent, Button, Icon } from '@upsales/components';
import Avatar from 'Components/Avatar';
import { connect } from 'react-redux';
import { RootState } from 'Store/index';

import './TimelineCard.scss';
import NoteRow from '../TimelineNoteComments/NoteRow';
import Event from 'App/resources/Model/Event';

type TimelineCardStateProps = {
	notesFilterActive?: boolean;
};

export type TimelineCardProps = {
	event: Event;
	backgroundColor?: 'transparent' | 'white';
	hasAvatar?: boolean;
	isExpandable?: boolean;
	title: ReactNode;
	subTitle?: ReactNode;
	subSection?: ReactNode;
	actions?: ReactNode;
	children?: ReactNode;
	cardHeaderChildren?: ReactNode;
	expanded?: boolean | null;
	noCommentRow?: boolean;
	onCardClick?: React.MouseEventHandler;
} & TimelineCardStateProps;

const mapStateToProps = (state: RootState) => ({
	notesFilterActive: state.NotesFilterActive
});

class TimelineCard extends Component<TimelineCardProps> {
	state = {
		isExpanded: this.props.expanded
	};
	componentDidUpdate(prevProps: Readonly<TimelineCardProps>): void {
		if (prevProps.expanded !== this.props.expanded && this.props.expanded !== null) {
			this.setState({ isExpanded: this.props.expanded });
		}
	}

	toggleExpanded: React.MouseEventHandler = e => {
		e.stopPropagation();
		this.setState({
			isExpanded: !this.state.isExpanded
		});
	};

	render() {
		const classNames = new BemClass('TimelineCard');
		const {
			event,
			title,
			subTitle,
			actions,
			backgroundColor,
			hasAvatar = false,
			isExpandable = false,
			noCommentRow = false,
			notesFilterActive,
			onCardClick
		} = this.props;
		let { subSection } = this.props;

		const { isExpanded } = this.state;
		const iconName = isExpanded ? 'chevron-up' : 'chevron-down';

		if (!event) {
			return '';
		}

		if (event.data?.comment?.description && notesFilterActive && !noCommentRow) {
			const commentRow = (
				<NoteRow comment={event.data?.comment ?? undefined} iconProps={{ icon: 'comment', color: 'grey-9' }} />
			);

			if (subSection) {
				if (!Array.isArray(subSection)) {
					subSection = [subSection, commentRow];
				} else {
					subSection.push(commentRow);
				}
			} else {
				subSection = commentRow;
			}
		}

		const user = event.user || (event as Event).users?.[0];

		const cardClickFn = onCardClick || (isExpandable ? this.toggleExpanded : undefined);

		return (
			<Card className={classNames.b()} color={backgroundColor || 'white'}>
				<div
					className={classNames
						.elem('cardHeader')
						.mod({ hasSubSection: !!subSection, clickable: !!cardClickFn })
						.b()}
					onClick={cardClickFn}
				>
					{hasAvatar && user ? (
						<div className={classNames.elem('avatar').b()}>
							<Avatar user={user} initials={user?.name ?? ''} email={user?.email ?? ''} />
						</div>
					) : null}
					<div className={classNames.elem('title-wrapper').b()}>
						<div className={classNames.elem('title-section').b()}>
							{title}
							<div className={classNames.elem('subtitle').b()}>{subTitle}</div>
						</div>
						<div className={classNames.elem('actions').b()} onClick={e => e.stopPropagation()}>
							{actions ? actions : null}
							{isExpandable ? (
								<Button type="link" onClick={this.toggleExpanded}>
									<Icon name={iconName} color="grey-13" />
								</Button>
							) : null}
						</div>
					</div>
					{this.props.cardHeaderChildren}
				</div>
				{/* Unclear what the purpose of using both subSection and children is here, feels like they fill the same function */}
				{subSection ? (
					Array.isArray(subSection) ? (
						subSection.map((section, index) => (
							<div key={index} className={classNames.elem('subSection').b()}>
								{section}
							</div>
						))
					) : (
						<div className={classNames.elem('subSection').b()}>{subSection}</div>
					)
				) : null}
				{this.props.children ? (
					<CardContent
						className={classNames
							.elem('cardContent')
							.mod({ open: isExpanded || false })
							.b()}
					>
						{this.props.children}
					</CardContent>
				) : null}
			</Card>
		);
	}
}

export const detached = TimelineCard;
export default connect(mapStateToProps, {})(TimelineCard);
