import { useSubscriptionGroupContext } from '../Context/SubscriptionGroupContext';
import { Tabs, Tab, ColorSwitcher } from '@upsales/components';
import { circle } from 'Components/Helpers/styleHelper';
import EditorHeader from 'Components/EditorHeader';
import CommonHeaderProps from './CommonHeader';
import T from 'Components/Helpers/translate';
import { STEP } from '../Step';
import React from 'react';

const CreateHeader = ({
	disableSummarySave,
	disableDetailsSave,
	resetScroll,
	changeProxy,
	getTabName,
	className,
	labMode,
	subtitle,
	onAbort,
	tooltip,
	save
}: CommonHeaderProps) => {
	const {
		state: { client, isDirty, step: currentStep, saving },
		setStep
	} = useSubscriptionGroupContext();
	const title =
		T('default.createSubscription') + (client ? ` ${T('default.with').toLowerCase()} ${client.name}` : '');
	const steps = [STEP.DETAILS, STEP.SUMMARY];
	const nextStep = steps[steps.indexOf(currentStep) + 1];

	const gotoNextTab = async () => {
		if (nextStep) {
			resetScroll();
			setStep(nextStep);
		} else {
			await save();
		}
	};

	const onConfirmSupertitle = nextStep
		? T('subscription.modal.nextStep')
		: labMode
		? T('subscription.promo.header.supertitle')
		: null;

	const onConfirmTitle = nextStep
		? getTabName('summary')
		: labMode
		? T('subscription.promo.header.title')
		: T('subscription.modal.save');

	return (
		<div>
			<EditorHeader
				infoLink={
					labMode
						? 'https://support.upsales.com/hc/en-us/articles/13598501826193-FAQ-Subscriptions-with-planned-changes-BETA-'
						: undefined
				}
				onConfirmTitle={onConfirmTitle}
				onConfirmSupertitle={onConfirmSupertitle}
				disableSave={nextStep ? disableDetailsSave : disableSummarySave}
				onConfirmIcon={!nextStep && !labMode ? 'save' : undefined}
				onConfirmHasNext={!!nextStep}
				onConfirmTooltip={tooltip}
				tempMigrateButton={true}
				icon={'recurring-order'}
				onAbortConfirm={isDirty && !labMode}
				onConfirm={gotoNextTab}
				className={className}
				subtitle={subtitle}
				onAbort={onAbort}
				loading={saving}
				title={title}
			>
				<Tabs noFlex color="white" onChange={changeProxy} selected={currentStep}>
					{steps.map((tab, i) => (
						<Tab key={tab} id={tab} disabled={disableDetailsSave}>
							<ColorSwitcher style={circle()}>{i + 1}</ColorSwitcher>
							{getTabName(tab)}
						</Tab>
					))}
				</Tabs>
			</EditorHeader>
		</div>
	);
};

export default CreateHeader;
