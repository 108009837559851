import Attribute, { DisplayType, Type, Attr } from './Attribute';

const ContactAttributes = {
	id: Attribute({
		title: 'default.id',
		field: 'id',
		type: Type.Number,
		groupable: false,
		selectableColumn: false
	}),
	account: Attribute({
		title: 'default.account',
		field: 'client.id',
		displayKey: 'client',
		displayAttr: 'name',
		type: Type.String,
		displayType: DisplayType.ClientLink,
		link: 'accounts',
		groupable: false,
		sortable: 'client.name',
		selectableColumn: false,
		parent: 'client',
		attr: {
			id: {
				field: 'client.id',
				type: Type.Number
			},
			name: {
				field: 'client.name',
				type: Type.String,
				skipMap: true
			},
			users: {
				field: 'client.users.id',
				type: Type.Number,
				parent: 'client.users',
				skipMap: true
			}
		}
	}),
	connectedClients: Attribute({
		title: 'default.connectedClients',
		field: 'connectedClients.relatedToClientId',
		displayAttr: 'name',
		type: Type.Object,
		displayType: DisplayType.Text,
		selectableColumn: false,
		attr: {
			relatedToClientId: {
				field: 'connectedClients.relatedToClientId',
				type: Type.Number
			}
		}
	}),
	categories: Attribute({
		title: 'default.categories',
		field: 'category.id',
		type: Type.Number,
		displayType: DisplayType.Category,
		placeholder: 'default.noCategories',
		groupable: false,
		selectableColumn: true,
		filterable: false,
		parent: 'categories',
		attr: {
			id: {
				field: 'category.id',
				type: Type.Number
			},
			name: {
				field: 'category.name',
				type: Type.String
			}
		}
	}),
	campaigns: Attribute({
		title: 'default.campaigns',
		field: 'project.id',
		displayKey: ['projects', 'name'],
		placeholder: 'default.noCampaigns',
		type: Type.Number,
		displayType: DisplayType.Array,
		groupable: false,
		selectableColumn: true,
		parent: 'projects',
		attr: {
			id: {
				field: 'project.id',
				type: Type.Number
			},
			name: {
				field: 'project.name',
				type: Type.String
			}
		}
	}),
	clientText: Attribute({
		title: 'default.account',
		field: 'client.id',
		displayAttr: 'name',
		type: Type.String,
		displayType: DisplayType.Text,
		link: 'accounts',
		groupable: false,
		sortable: 'client.name',
		selectableColumn: false,
		parent: 'client',
		attr: {
			id: {
				field: 'client.id',
				type: Type.Number
			},
			name: {
				field: 'client.name',
				type: Type.String,
				skipMap: true
			},
			users: {
				field: 'client.users.id',
				type: Type.Number,
				parent: 'client.users',
				skipMap: true
			}
		}
	}),
	contactText: Attribute({
		title: 'column.name',
		field: 'name',
		displayKey: ['id', 'name'],
		type: Type.String,
		displayType: DisplayType.ContactLink,
		selectableColumn: false,
		sortable: 'name'
	}),
	contact: Attribute({
		title: 'default.contact',
		field: 'contact',
		type: Type.String,
		displayType: DisplayType.Contact,
		selectableColumn: false
	}),
	name: Attribute({
		title: 'column.name',
		field: 'name',
		type: Type.String,
		selectableColumn: true,
		sortable: 'name'
	}),
	firstName: Attribute({
		title: 'default.firstName',
		field: 'firstName',
		type: Type.String,
		displayType: DisplayType.Text,
		selectableColumn: false,
		sortable: 'firstName'
	}),
	lastName: Attribute({
		title: 'default.lastName',
		field: 'lastName',
		type: Type.String,
		displayType: DisplayType.Text,
		selectableColumn: false,
		sortable: 'lastName'
	}),
	title: Attribute({
		title: 'default.title',
		field: 'title',
		type: Type.String,
		displayType: DisplayType.Text,
		selectableColumn: true,
		sortable: 'title'
	}),
	titleCategory: Attribute({
		title: 'default.titlecategory',
		field: 'titleCategory',
		displayKey: 'titleCategory.value',
		displayType: DisplayType.Text,
		type: Type.Object,
		groupable: false,
		sortable: 'titleCategory.tagId',
		selectableColumn: true,
		attr: {
			tagId: {
				field: 'titleCategory.tagId',
				type: Type.Number
			},
			value: {
				field: 'titleCategory.value',
				type: Type.String
			},
			language: {
				field: 'titleCategory.language',
				type: Type.String
			}
		},
		unreleasedFeature: 'NEW_FIELDS'
	}),
	salutation: Attribute({
		title: 'default.salutation',
		field: 'salutation.value',
		displayAttr: 'value',
		displayType: DisplayType.Text,
		type: Type.Object,
		groupable: false,
		sortable: 'salutation.tagId',
		selectableColumn: false,
		parent: 'salutation',
		attr: {
			tagId: {
				field: 'salutation.tagId',
				type: Type.Number
			},
			value: {
				field: 'salutation.value',
				type: Type.String
			},
			language: {
				field: 'salutation.language',
				type: Type.String
			}
		},
		unreleasedFeature: 'NEW_FIELDS'
	}),
	phone: Attribute({
		title: 'default.phone',
		displayType: DisplayType.PhoneNumber,
		field: 'phone',
		selectableColumn: true,
		type: Type.String,
		placeholder: 'default.noSetPhone'
	}),
	cellPhone: Attribute({
		title: 'default.cellPhone',
		displayType: DisplayType.PhoneNumber,
		field: 'cellPhone',
		selectableColumn: true,
		type: Type.String,
		placeholder: 'default.noSetCellphone'
	}),
	email: Attribute({
		field: 'email',
		type: Type.Email,
		title: 'default.email',
		selectableColumn: true,
		sortable: 'email'
	}),
	emailBounceReason: Attribute({
		field: 'emailBounceReason',
		type: Type.String,
		title: 'default.emailBounceReason',
		selectableColumn: false
	}),
	notes: Attribute({
		field: 'notes',
		type: Type.String
	}),
	score: Attribute({
		field: 'score',
		title: 'default.score',
		type: Type.Number,
		displayType: DisplayType.Score,
		groupable: false,
		selectableColumn: true,
		filterable: true,
		sortable: 'score'
	}),
	scoreUpdateDate: Attribute({
		title: 'default.scoreUpdate',
		field: 'scoreUpdateDate',
		type: Type.Date,
		displayType: DisplayType.Date,
		selectableColumn: true,
		filterable: true,
		sortable: 'scoreUpdateDate'
	}),
	users: Attribute({
		title: 'default.user',
		field: 'user.id',
		type: Type.Number,
		displayType: DisplayType.Text,
		inputType: 'select',
		displayAttr: 'name',
		parent: 'users',
		attr: {
			id: {
				field: 'user.id',
				type: Type.Number
			},
			name: {
				field: 'user.name',
				type: Type.String
			}
		}
	}),
	active: Attribute({
		field: 'active',
		type: Type.Boolean
	}),
	unsubscribed: Attribute({
		title: 'mail.unsubscribed',
		field: 'unsubscribed',
		type: Type.Date
	}),
	segments: Attribute({
		title: 'segment.segments',
		field: 'segment.id',
		displayAttr: 'name',
		displayKey: ['segments', 'name'],
		placeholder: 'default.noSegments',
		type: Type.Object,
		displayType: DisplayType.Array,
		groupable: false,
		selectableColumn: true,
		parent: 'segments',
		attr: {
			id: {
				field: 'segment.id',
				type: Type.Number
			},
			name: {
				field: 'segment.name',
				type: Type.String
			},
			date: {
				field: 'segment.date',
				type: Type.Date
			}
		}
	}),
	history: Attribute({
		title: 'column.contactHistory',
		field: 'history',
		type: Type.String,
		selectableColumn: true
	}),
	markethistory: Attribute({
		title: 'column.marketHistory',
		field: 'markethistory',
		type: Type.String,
		selectableColumn: true
	}),
	socialEvent: Attribute({
		field: 'socialEvent.id',
		type: Type.Number
	}),
	socialEventStatus: Attribute({
		field: 'socialEvent.status',
		type: Type.String
	}),
	journeyStep: Attribute({
		title: 'default.journeyStep',
		field: 'journeyStep',
		type: Type.String,
		displayType: DisplayType.Journey,
		sortable: 'journeySort',
		selectableColumn: false,
		groupable: false
	})
} as { [key: string]: Attr };

ContactAttributes.client = { ...ContactAttributes.account };
ContactAttributes.client.selectableColumn = true;

export default ContactAttributes;
