import React, { useMemo } from 'react';
import { Modal, Block, ModalContent, Headline, Text, Icon } from '@upsales/components';
import BemClass from '@upsales/components/Utils/bemClass';
import T from 'Components/Helpers/translate';
import Agreement, { AgreementMetadata } from 'App/resources/Model/Agreement';

import moment from 'moment';
import { RootState } from 'Store/index';
import { useSelector } from 'react-redux';
import SubscriptionPreviewGraph from 'App/components/SubscriptionPreviewGraph';
import './SubscriptionPreview.scss';
import 'App/pages/Reportcenter/ReportcenterWidget/BarchartWidget/BarchartWidgetBars/BarchartWidgetBars.scss';

interface Props {
	className?: string;
	close: () => void;
	periodLength: AgreementMetadata['periodLength'];
	startDate: AgreementMetadata['agreementStartdate'];
	orderDate: AgreementMetadata['agreementNextOrderDate'] | null;
	orderInterval: AgreementMetadata['agreementIntervalPeriod'];
	renewalDate: AgreementMetadata['agreementRenewalDate'] | null;
	agreement: Agreement;
	orderCreationTime: AgreementMetadata['agreementOrderCreationTime'];
	endDate: AgreementMetadata['agreementEnddate'];
}

const SubscriptionPreview = ({
	className,
	close,
	periodLength,
	startDate,
	orderDate,
	orderInterval,
	renewalDate,
	agreement,
	orderCreationTime,
	endDate
}: Props) => {
	const classes = new BemClass('SubscriptionPreview', className);
	const recurringValues = useSelector((state: RootState) => {
		return state.App.staticValues.recurringInterval;
	});
	const orderIntervalText = useMemo(
		() => (recurringValues?.find(rv => parseInt(rv.id) === orderInterval)?.name || '').toLowerCase(),
		[orderInterval]
	);

	const renderPeriodLength = () => {
		const periodLengthText =
			periodLength === 0
				? T('agreement.periodLength.untilFurtherNotice').toLowerCase()
				: periodLength === 1
				? `${periodLength} ${T('agreement.subscriptionPreview.description.periodLengthMonths.singular')}`
				: `${periodLength} ${T('agreement.subscriptionPreview.description.periodLengthMonths.plural')}`;

		return (
			<div className={classes.elem('description-row').b()}>
				<Text size="lg" color="grey-11">
					{T('agreement.subscriptionPreview.description.periodLength')}
					<Text size="lg" color="blue">
						{` ${periodLengthText} `}
					</Text>
					{T('agreement.subscriptionPreview.description.startDate')}
					<Text size="lg" color="blue">
						{` ${moment(startDate).format('L')}`}
					</Text>
					{'.'}
				</Text>
			</div>
		);
	};

	const renderFirstOrder = () => (
		<div className={classes.elem('description-row').b()}>
			<Text size="lg" color="grey-11">
				{T('agreement.subscriptionPreview.description.firstOrder')}
				<Text size="lg" color="blue">
					{` ${moment(orderDate).format('L')}`}
				</Text>
				{'.'}
			</Text>
		</div>
	);

	const renderOrderCreation = () => {
		const orderCreationTimeText =
			orderCreationTime === 1
				? T('agreement.subscriptionPreview.description.orderCreatedInAdvance.singular')
				: T('agreement.subscriptionPreview.description.orderCreatedInAdvance.plural');

		return (
			<div className={classes.elem('description-row').b()}>
				<Text size="lg" color="grey-11">
					{T('agreement.subscriptionPreview.description.orderCreationInterval')}
					<Text size="lg" color="blue">
						{` ${orderIntervalText}`}
					</Text>
					{!!orderCreationTime && orderCreationTime !== 0 ? (
						<Text size="lg" color="blue">
							{`, ${orderCreationTime} ${orderCreationTimeText}`}
						</Text>
					) : null}
					{'.'}
				</Text>
			</div>
		);
	};

	const renderRenewedOrTerminated = () => {
		const renewedOrEndedText = endDate
			? ` ${T('agreement.subscriptionPreview.description.ended')}`
			: ` ${T('agreement.subscriptionPreview.description.renewed')}`;
		const textBeforeDate = endDate
			? ` ${T('agreement.subscriptionPreview.description.endDate')}`
			: ` ${T('agreement.subscriptionPreview.description.renewedWithSamePeriodLength')}`;

		if (!endDate && !renewalDate) {
			return null;
		}

		return (
			<div className={classes.elem('description-row').b()}>
				<Text size="lg" color="grey-11">
					{T('agreement.subscriptionPreview.description.willBeRenewedOrTerminated')}
				</Text>
				<Text size="lg" color="blue">
					{renewedOrEndedText}
				</Text>
				<Text size="lg" color="grey-11">
					{textBeforeDate}
				</Text>
				<Text size="lg" color="blue">
					{' ' + moment(endDate || renewalDate).format('L') + '.'}
				</Text>
			</div>
		);
	};

	const renderOrderDateAndCreation = () => {
		if (orderDate) {
			return (
				<>
					{renderFirstOrder()}
					{renderOrderCreation()}
				</>
			);
		}
		return (
			<div className={classes.elem('description-row').b()}>
				<Text size="lg" color="grey-11">
					{T('agreement.subscriptionPreview.description.noOrderWillBeCreated')}
				</Text>
			</div>
		);
	};

	return (
		<Modal size="xl" className={classes.b()}>
			<ModalContent>
				<Block className={classes.elem('summary-card').b()}>
					<Block space="mbxl" className={classes.elem('header').b()}>
						<Headline size="sm">{T('agreement.subscriptionPreview.headline')}</Headline>
						<Icon onClick={close} name="times" color="grey-10" />
					</Block>
					<Block space="mbxl" className={classes.elem('summary-content').b()}>
						<div className={classes.elem('summary-description').b()}>
							{renderPeriodLength()}
							{renderOrderDateAndCreation()}
							{renderRenewedOrTerminated()}
						</div>
					</Block>

					<Block space="pll prl ptl pbl" className={classes.elem('summary-card-graph').b()}>
						<SubscriptionPreviewGraph agreement={agreement} />
					</Block>
				</Block>
			</ModalContent>
		</Modal>
	);
};

export default SubscriptionPreview;
