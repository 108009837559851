import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Block, Button, Card, DrawerHeader, Headline, Icon, Row, Text, Title } from '@upsales/components';
import './TargetCalculator.scss';
import bemClass from '@upsales/components/Utils/bemClass';
import logError from 'App/babel/helpers/logError';
import { getFirstName, getUserCurrency, getStartAndEndDate, getNameEnding } from './TargetCalculatorHelper';
import SuggestedQuota from 'Resources/SuggestedQuota';
import GenericQuota from 'Resources/GenericQuota';
import TargetCard from './TargetCard';
import T from 'Components/Helpers/translate';

const TargetCalculator = ({ userId, close, className }) => {
	const classes = new bemClass('TargetCalculator', className);
	const [bookedAppointmentsTarget, setBookedAppointmentsTarget] = useState(0);
	const [completedAppointmentsTarget, setCompletedAppointmentsTarget] = useState(0);
	const [excludedTargets, setExcludedTargets] = useState({
		recurring: false,
		pipeline: false,
		completed_appointment: false,
		booked_appointment: false
	});
	const [hitrates, setHitrates] = useState({});
	const [pipelineTarget, setPipelineTarget] = useState(0);
	const [recurring, setRecurring] = useState('');
	const [showTargets, setShowTargets] = useState(false);
	const [totals, setTotals] = useState({});
	const [saving, setSaving] = useState(false);
	const [done, setDone] = useState(false);

	useEffect(() => {
		SuggestedQuota.get(`${userId}/${0}`)
			.then(res => {
				if (res.data) {
					setTotals({
						wonDealsForValue: res.data.wonDealsForValue,
						avgWonValue: res.data.avgWonValue,
						completedAppointments: res.data.completedAppointments,
						plannedAppointments: res.data.plannedAppointments,
						opportunitiesCreatedTarget: res.data.opportunitiesCreatedTarget
					});
				}
			})
			.catch(e => {
				logError(e);
			});
	}, [userId]);

	const onTargetChange = async (userId, value) => {
		setShowTargets(true);
		const formattedValue = parseInt(value.toString().replace(/ /gm, ''));
		setRecurring(formattedValue);
		if (formattedValue) {
			SuggestedQuota.get(`${userId}/${formattedValue}`)
				.then(res => {
					if (res.data) {
						setPipelineTarget(res.data.pipelineTarget);
						setCompletedAppointmentsTarget(res.data.appointmentCompletedTarget);
						setBookedAppointmentsTarget(res.data.appointmentPlannedTarget);
						setHitrates({
							pipeToOrder: res.data.hitRatePipeToOrder,
							meetingsToPipe: res.data.hitRateMeetingsToPipe,
							meetings: res.data.hitRateMeetings
						});
						setTotals({
							wonDealsForValue: res.data.wonDealsForValue,
							avgWonValue: res.data.avgWonValue,
							completedAppointments: res.data.completedAppointments,
							plannedAppointments: res.data.plannedAppointments,
							opportunitiesCreatedTarget: res.data.opportunitiesCreatedTarget
						});
					}
				})
				.catch(e => logError(e, 'Failed to get target recommendations'));
		} else {
			setPipelineTarget(0);
			setCompletedAppointmentsTarget(0);
			setBookedAppointmentsTarget(0);
			setShowTargets(false);
			setExcludedTargets({
				recurring: false,
				pipeline: false,
				completed_appointment: false,
				booked_appointment: false
			});
		}
	};

	const onToggleChange = (value, type) => {
		setExcludedTargets({ ...excludedTargets, [type]: value });
	};

	const saveTargets = async () => {
		const promises = [];
		setSaving(true);
		Object.keys(excludedTargets).forEach(async key => {
			let quota = 0;
			let saveCurrency = false;
			switch (key) {
				case 'recurring':
					quota = parseInt(recurring.toString().replace(/ /g, ''));
					saveCurrency = true;
					break;
				case 'pipeline':
					quota = parseInt(pipelineTarget.toString().replace(/ /g, ''));
					saveCurrency = true;
					break;
				case 'completed_appointment':
					quota = parseInt(completedAppointmentsTarget.toString().replace(/ /g, ''));
					break;
				case 'booked_appointment':
					quota = parseInt(bookedAppointmentsTarget.toString().replace(/ /g, ''));
					break;
			}
			if (!excludedTargets[key]) {
				const genericQuotaPromise = new Promise((resolve, reject) => {
					try {
						const fiscalMoment = getStartAndEndDate(moment().format('YYYY'));

						GenericQuota.saveMultipleOngoing({
							periodType: 'monthly',
							period: fiscalMoment.start,
							periodEnd: fiscalMoment.end,
							type: key.toString(),
							users: [
								{
									userId,
									quota,
									currency: saveCurrency ? getUserCurrency(userId).iso : null,
									currencyRate: saveCurrency ? getUserCurrency(userId).rate : null
								}
							]
						});
					} catch (e) {
						logError(e);
						reject();
					}
					resolve();
				});
				promises.push(genericQuotaPromise);
			}
		});
		await Promise.all(promises);
		setSaving(false);
		setDone(true);
		setTimeout(() => {
			close();
		}, 1000);
	};

	const isDisabled = () => {
		return !recurring || Object.values(excludedTargets).indexOf(false) === -1;
	};

	return (
		<Block className={classes.b()}>
			<DrawerHeader
				title={
					getNameEnding(userId)
						? T('targetCalculator.header', { firstName: getFirstName(userId) })
						: T('targetCalculator.headerNoS', { firstName: getFirstName(userId) })
				}
				onHide={() => close()}
			>
				<Button
					className={classes
						.elem('saveButton')
						.mod({
							disabled: isDisabled()
						})
						.b()}
					color="bright-blue"
					disabled={isDisabled()}
					size="lg"
					loading={saving}
					onClick={saveTargets}
				>
					{!saving && !done ? (
						<Text size="lg" color="white" bold>
							{T('targetCalculator.saveTargets')}
						</Text>
					) : done ? (
						<Text color="white">
							<Icon name="check" space="mrm" />
							{T('targetCalculator.targetsSaved')}
						</Text>
					) : null}
				</Button>
			</DrawerHeader>
			<Card className={classes.elem('content').b()} key="content">
				<Row>
					<Headline size="sm" color="black">
						{T('targetCalculator.headline', { firstName: getFirstName(userId) })}
					</Headline>
					<Title color="grey-10">
						{getNameEnding(userId)
							? T('targetCalculator.headlineBody', { firstName: getFirstName(userId) })
							: T('targetCalculator.headlineBodyNoS', { firstName: getFirstName(userId) })}
						<span>{'  '}</span>
						<ReactTemplates.elevio sidebar={false} articleId={1219} text={T('default.readMore')} />
					</Title>
				</Row>

				<TargetCard
					title={T('targetCalculator.subscriptionTitle')}
					userId={userId}
					target={recurring}
					nominator={{ title: T('targetCalculator.subscriptionNominatorTitle'), value: recurring }}
					onChange={onTargetChange}
					denominator={{
						title: getNameEnding(userId)
							? T('targetCalculator.subscriptionDenominatorTitle', { firstName: getFirstName(userId) })
							: T('targetCalculator.subscriptionDenominatorTitleNoS', {
									firstName: getFirstName(userId)
							  }),
						value: totals.avgWonValue
					}}
					result={{ title: T('targetCalculator.subscriptionResult'), value: totals.wonDealsForValue ?? 0 }}
					inputDisable={false}
					onToggleChange={onToggleChange}
					toggleVal={excludedTargets.recurring}
					type={'recurring'}
				/>
				{showTargets ? (
					<div className={classes.elem('targetCards').b()}>
						<TargetCard
							title={T('targetCalculator.pipelineTitle')}
							userId={userId}
							target={pipelineTarget}
							nominator={{
								title: T('targetCalculator.pipelineNominatorTitle'),
								value: totals.wonDealsForValue
							}}
							denominator={{
								title: getNameEnding(userId)
									? T('targetCalculator.pipelineDenominatorTitle', {
											firstName: getFirstName(userId)
									  })
									: T('targetCalculator.pipelineDenominatorTitleNoS', {
											firstName: getFirstName(userId)
									  }),
								value: parseFloat(hitrates.pipeToOrder) * 100
							}}
							result={{
								title: T('targetCalculator.pipelineResult'),
								value: totals.opportunitiesCreatedTarget
							}}
							inputDisable={true}
							onToggleChange={onToggleChange}
							toggleVal={excludedTargets.pipeline}
							type={'pipeline'}
						/>

						<TargetCard
							title={T('targetCalculator.completedAppointmentsTitle')}
							userId={userId}
							target={completedAppointmentsTarget}
							nominator={{
								title: T('targetCalculator.completedAppointmentsNominatorTitle'),
								value: totals.opportunitiesCreatedTarget
							}}
							denominator={{
								title: T('targetCalculator.completedAppointmentsDenominatorTitle'),
								value: parseFloat(hitrates.meetingsToPipe) * 100
							}}
							result={{
								title: T('targetCalculator.completedAppointmentsResult'),
								value: completedAppointmentsTarget
							}}
							inputDisable={true}
							onToggleChange={onToggleChange}
							toggleVal={excludedTargets.completed_appointment}
							type={'completed_appointment'}
						/>
						<TargetCard
							title={T('targetCalculator.bookedAppointmentsTitle')}
							userId={userId}
							target={bookedAppointmentsTarget}
							nominator={{
								title: T('targetCalculator.bookedAppointmentsNominatorTitle'),
								value: completedAppointmentsTarget
							}}
							denominator={{
								title: T('targetCalculator.bookedAppointmentsDenominatorTitle'),
								value: parseFloat(hitrates.meetings) * 100
							}}
							result={{
								title: T('targetCalculator.bookedAppointmentsResult'),
								value: bookedAppointmentsTarget
							}}
							inputDisable={true}
							onToggleChange={onToggleChange}
							toggleVal={excludedTargets.booked_appointment}
							type={'booked_appointment'}
						/>
					</div>
				) : null}
			</Card>
		</Block>
	);
};
TargetCalculator.propTypes = {
	userId: PropTypes.number.isRequired,
	className: PropTypes.string,
	close: PropTypes.func.isRequired
};
export default TargetCalculator;
