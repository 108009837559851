import React, { useRef } from 'react';
import T from 'Components/Helpers/translate';
import { Text, Icon, DropDownMenu } from '@upsales/components';
import { onTemplateRemove, onTemplateUpdate } from '../../../../MailContextHelpers';
import MailTemplateSaveDropdown from '../../../MailTemplateSaveDropdown/MailTemplateSaveDropdown';
import BemClass from '@upsales/components/Utils/bemClass';
import { useMailContext } from '../../../../MailContext';
import './NewMailTemplateRow.scss';

const NewMailTemplateRow = () => {
	const classes = new BemClass('NewMailTemplateRow');
	const { state, dispatch } = useMailContext();
	const {
		template: { name, hasChanged, id }
	} = state;
	const hideRow = !id;
	const closeDD = useRef<() => void>();
	const saveTemplateDropdown = () => {
		return (
			<DropDownMenu
				align="right"
				arrowed={false}
				renderTrigger={(expanded, setExpanded) => (
					<Text
						className={classes.elem('saveButton').b()}
						onClick={(e: Parameters<typeof setExpanded>[0]) => {
							e.stopPropagation();
							setExpanded(e);
						}}
					>
						{T('mail.saveAsNew')}
					</Text>
				)}
			>
				{setClosed => {
					closeDD.current = () => {
						setClosed();
					};
					return <MailTemplateSaveDropdown setClosed={setClosed} closeDD={closeDD} />;
				}}
			</DropDownMenu>
		);
	};

	return (
		<div className={classes.mod({ hideRow }).b()}>
			<Text color="grey-11">{T('mail.template') + ':'}</Text>
			<div className={classes.elem('contentRow').b()}>
				<div className={classes.elem('leftColumn').mod({ hasChanged }).b()}>
					<Text>{name}</Text>
					<Text color="grey-10">{'(' + T('default.changed') + ')'}</Text>
				</div>
				<div className={classes.elem('rightColumn').mod({ hasChanged }).b()}>
					{saveTemplateDropdown()}
					<Text onClick={() => onTemplateUpdate(dispatch, state)}>{T('default.update')}</Text>
					<Icon name="times" onClick={() => onTemplateRemove(dispatch)} />
				</div>
			</div>
		</div>
	);
};

export default NewMailTemplateRow;
