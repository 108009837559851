import React, { useState, useEffect, useRef } from 'react';
import ReportcenterFilter from './ReportcenterFilter';
import { Block } from '@upsales/components';
import bemClass from '@upsales/components/Utils/bemClass';
import T from 'Components/Helpers/translate';
import ReportcenterFilterRow from './ReportcenterFilterRow';
import { removeItem } from 'Store/helpers/array';
import { RCDashboardFilter } from 'Resources/ReportDashboard';
import Form from 'App/resources/Model/Form';
import FormResource from 'App/resources/Form';
import logError from 'Helpers/logError';
import { makeCancelable, CancelablePromise } from 'App/babel/helpers/promise';
import './ReportcenterFilterForm.scss';
import ReportcenterFilterSearchbar from './ReportcenterFilterSearchbar';

interface Props {
	onChange: (type: keyof RCDashboardFilter, values: number[], comparison: string) => void;
	values: RCDashboardFilter;
	updateFilterHeight: () => void;
	families: string[];
}

export const renderSelected = (values: RCDashboardFilter) => {
	let res = `${T('form.forms')}: `;

	if (values?.Form?.value?.length) {
		const length = values.Form.value.length;
		if (length >= 1) {
			res += `${length} ${(values.Form.comparison === 'eq'
				? T('default.selected')
				: T('default.excluded', { count: length })
			).toLowerCase()}`;
		}
	}

	return res;
};

const ReportcenterFilterForm = ({ onChange, families, ...props }: Props) => {
	const classes = new bemClass('ReportcenterFilterForm');
	const [searchStr, setSearchStr] = useState('');
	const [forms, setForms] = useState<Form[]>([]);

	const timeoutRef = useRef<number>();
	const dataPromise = useRef<CancelablePromise>();
	useEffect(() => {
		clearTimeout(timeoutRef.current);
		timeoutRef.current = window.setTimeout(() => {
			dataPromise.current?.cancel();
			dataPromise.current = makeCancelable(FormResource.find({ name: `src:${searchStr}` }));
			dataPromise.current.promise
				.then(res => setForms(res.data))
				.catch(err => logError(err, 'Could not find forms'));
		}, 300);
		return () => {
			clearTimeout(timeoutRef.current);
			dataPromise.current?.cancel();
		};
	}, [searchStr]);

	const filteredForms = forms.filter(item => item.name.toLowerCase().indexOf(searchStr.toLowerCase()) !== -1);
	const value = props.values?.Form?.value || [];
	const comparison = props.values?.Form?.comparison ?? 'eq';
	const isExclude = comparison === 'ne';

	return (
		<ReportcenterFilter
			className={classes.b()}
			renderSelected={() => renderSelected(props.values)}
			getSelectedNames={() => forms.filter(s => value.includes(s.id)).map(s => s.name)}
			icon="code-fork"
			placeholder={`${T('form.forms')}: ${T('reportcenter.filter.notActive')}`}
			value={value}
			resetFilter={() => onChange('Form', [], 'eq')}
			{...props}
		>
			<Block space="ptm prm pbm plm" backgroundColor="white" className={classes.elem('list-select').b()}>
				<Block space="mtm">
					<ReportcenterFilterSearchbar<number[]>
						setSearchStr={value => setSearchStr(value)}
						searchStr={searchStr}
						placeholder={`${T('default.search')} ${T('form.forms').toLowerCase()}`}
						isExclude={isExclude}
						onChange={onChange}
						field="Form"
						value={value}
					/>
				</Block>
			</Block>
			<ReportcenterFilterRow
				key={0}
				onClick={() => {
					onChange('Form', [], 'eq');
				}}
				selected={!value.length}
				title={T('filters.noFilter')}
			/>
			{filteredForms.map(row => (
				<ReportcenterFilterRow
					key={row.id}
					onClick={() => {
						const i = value.indexOf(row.id);
						onChange('Form', i !== -1 ? removeItem(value, i) : [...value, row.id], comparison);
					}}
					selected={value.indexOf(row.id) !== -1}
					title={row.name}
				/>
			))}
		</ReportcenterFilter>
	);
};

export default ReportcenterFilterForm;
