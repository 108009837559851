import BemClass from '@upsales/components/Utils/bemClass';
import FlexItem from 'App/components/FlexItem';
import React, { useMemo, useState, useEffect, useRef } from 'react';
import T from 'Components/Helpers/translate';
import { EllipsisTooltip, Block, Icon, Title, Flex, Text, Link, Help } from '@upsales/components';
import { SlideFade } from '@upsales/components/animations';

import type Client from 'App/resources/Model/Client';
import type { DrawerClient } from './SubaccountDrawer';

type Props = {
	client: DrawerClient;
	setIncludeSubaccount: (includeSubaccountData: boolean) => void;
	includeSubaccountData: boolean;
	subaccounts: Pick<Client, 'id' | 'name'>[];
};

const Banner = ({ client, subaccounts, includeSubaccountData, setIncludeSubaccount }: Props) => {
	const classes = new BemClass('SubaccountDrawer');
	const [addedText, setAddedText] = useState('');
	const timeoutRef = useRef<ReturnType<typeof setTimeout> | null>(null);
	const lang = useMemo(
		() => ({
			description: T('SubaccountDrawer.Banner.description'),
			hasManySubaccount: T('SubaccountDrawer.Banner.hasManySubaccount', { count: subaccounts.length }),
			hasNoSubaccounts: T('SubaccountDrawer.Banner.hasNoSubaccounts'),
			hasOneSubaccount: T('SubaccountDrawer.Banner.hasOneSubaccount'),
			includeSubaccountData: T('SubaccountDrawer.Banner.includeSubaccountData'),
			infoTooltip: T('SubaccountDrawer.Banner.infoTooltip'),
			readMore: T('default.readMore')
		}),
		[subaccounts]
	);

	useEffect(() => {
		const unsubscribe = Tools.$rootScope.$on(
			'account.subaccountsAdded',
			(event: unknown, { clients }: { clients: Client[] }) => {
				switch (clients.length) {
					case 0:
						setAddedText(
							`${T('subaccounts.notificationBanner.title')} - ${T(
								'subaccounts.notificationBanner.subtitle'
							)}`
						);
						break;
					case 1:
						setAddedText(T('SubaccountDrawer.Banner.addedOne', { name: clients[0].name }));
						break;
					case 2:
						setAddedText(T('SubaccountDrawer.Banner.addedTwo', { name: clients[0].name }));
						break;
					default:
						setAddedText(
							T('SubaccountDrawer.Banner.addedMany', { name: clients[0].name, count: clients.length - 1 })
						);
						break;
				}

				if (timeoutRef.current) {
					clearTimeout(timeoutRef.current);
				}

				timeoutRef.current = setTimeout(() => {
					setAddedText('');
				}, 2500);
			}
		);

		return () => {
			unsubscribe();

			if (timeoutRef.current) {
				clearTimeout(timeoutRef.current);
			}
		};
	}, []);

	const getTitle = () => {
		switch (subaccounts.length) {
			case 0:
				return lang.hasNoSubaccounts;
			case 1:
				return lang.hasOneSubaccount;
			default:
				return lang.hasManySubaccount;
		}
	};

	return (
		<Block backgroundColor="super-light-blue" className={classes.elem('Banner').b()}>
			<SlideFade delayInMs={100} direction="top" maxHeight={200} visible={addedText.length > 0}>
				<Block backgroundColor="success-1" className={classes.elem('Banner__Added').b()}>
					<Flex direction="column" justifyContent="center">
						<Flex justifyContent="center" space="mbxl">
							<Block backgroundColor="success-2" space="ptm pbm plm prm">
								<Title size="xl">
									<Icon color="success-6" name="check" />
								</Title>
							</Block>
						</Flex>
						<Flex justifyContent="center" space="mrxl mlxl">
							<Title size="lg">{addedText}</Title>
						</Flex>
					</Flex>
				</Block>
			</SlideFade>
			<Flex space="mbm">
				<EllipsisTooltip title={client.name}>
					<Title bold={true} size="lg" space="mrm">
						{client.name}
					</Title>
				</EllipsisTooltip>
				<FlexItem flexShrink={0}>
					<Title size="lg">{getTitle()}</Title>
				</FlexItem>
			</Flex>
			<Text color="grey-11" size="sm">
				{lang.description}
				<Help articleId={1514} sidebar={true}>
					<Link>{lang.readMore}</Link>
				</Help>
			</Text>
		</Block>
	);
};

export default Banner;
