import './TimelinePostponeSubtitle.scss';
import React from 'react';
import moment from 'moment';
import { Icon, Text, Tooltip } from '@upsales/components';
import t from 'Components/Helpers/translate';
import BemClass from '@upsales/components/Utils/bemClass';

type Props = {
	date?: Date;
	oldDate?: Date;
	eventDate?: Date;
	showPostponedFromDate?: boolean;
};

const TimelinePostponeSubtitle = ({ date, oldDate, eventDate, showPostponedFromDate = true }: Props) => {
	const classes = new BemClass('TimelinePostponeSubtitle');
	const hasBothDates = date && oldDate && showPostponedFromDate;

	if (!hasBothDates && !eventDate) {
		return null;
	}

	const formatDate = (date: Date) => {
		const dateFormat = moment(date).startOf('day').isSame(date) ? 'L' : 'L LT';
		return moment(date).format(dateFormat);
	};

	const renderChangedDate = (from: Date, to: Date) => {
		return (
			<>
				<Tooltip position="bottom" title={t('activity.outcomeLog.previousDueDate')} distance={20}>
					{formatDate(from)}
				</Tooltip>
				<Icon name="arrow-right" className={classes.elem('arrow').b()} />
				<Tooltip position="bottom" title={t('activity.outcomeLog.newDueDate')} distance={20}>
					{formatDate(to)}
				</Tooltip>
			</>
		);
	};

	return (
		<Text size="sm" color="grey-11" className={classes.b()}>
			{date && oldDate && showPostponedFromDate ? renderChangedDate(oldDate, date) : null}
			{!hasBothDates && eventDate ? formatDate(eventDate) : null}
		</Text>
	);
};

export default TimelinePostponeSubtitle;
