import Resource from 'Resources/Resource';

class Label extends Resource {
	constructor() {
		super('labels');
	}
}

const resource = new Label();

export default resource;
