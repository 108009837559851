import PropTypes from 'prop-types';
import { Component } from 'react';

class UpTimezone extends Component {
	UNSAFE_componentWillMount() {
		const t = Tools.$translate;

		this.lang = {
			noName: t('default.noName')
		};
	}

	componentDidUpdate() {
		const selectHelper = ReactTemplates.TOOLS.selectHelper;
		selectHelper.updateValue.call(this);
	}

	componentDidMount() {
		let opts = {
			required: this.props.required || false,
			multiple: this.props.multiple || false,
			placeholder: this.props.placeholder,
			onChange: this.props.onChange,
			ajax: true,
			asIds: false,
			formatResult: this.props.formatResult || ((object, container, query, escape) => escape(object.name)),
			formatSelection: this.props.formatSelection || ((object, container, escape) => escape(object.name)),
			getter: () => {
				const data = _.map(Tools.CountryCodes.zones, name => ({
					id: name,
					name,
					utcOffset: moment.tz.zone(name).offsets[moment.tz.zone(name).offsets.length - 1] / -60
				}));
				return Tools.$q.when({ data, metadata: { total: data.length } });
			}
		};

		const input = jQuery(this._input);

		const selectHelper = ReactTemplates.TOOLS.selectHelper;
		opts = _.merge({}, opts, this.props.options);

		selectHelper.getSelect2Options(this, opts, input, options => {
			input.select2(options);
		});
	}

	render() {
		const selectHelper = ReactTemplates.TOOLS.selectHelper;
		return selectHelper.getInputComponent(this);
	}
}

UpTimezone.propTypes = {
	multiple: PropTypes.bool,
	onChange: PropTypes.func.isRequired,
	required: PropTypes.bool,
	placeholder: PropTypes.string,
	options: PropTypes.object,
	formatResult: PropTypes.func,
	formatSelection: PropTypes.func
};

window.UpTimezone = UpTimezone;

export default UpTimezone;
