import React, { ComponentProps } from 'react';
import TimelineGeneric, { type TimelineProps } from './TimelineGeneric';
import T from 'Components/Helpers/translate';

import openAgreement from 'App/helpers/openSubscriptionHelper';
import TimelineCardActiveFlow from '../TimelineCardConfigs/TimelineCardActiveFlow';
import TimelineCardActivity from '../TimelineCardConfigs/TimelineCardActivity';
import TimelineCardAppointment from '../TimelineCardConfigs/TimelineCardAppointment';
import TimelineCardClientPlan from '../TimelineCardConfigs/TimelineCardClientPlan';
import TimelineCardComment from '../TimelineCardConfigs/TimelineCardComment';
import TimelineCardOpportunity from '../TimelineCardConfigs/TimelineCardOpportunity';
import TimelineCardOrder from '../TimelineCardConfigs/TimelineCardOrder';
import TimelineCardSocialEvent from '../TimelineCardConfigs/TimelineCardSocialEvent';
import TimelineCardTicket from '../TimelineCardConfigs/TimelineCardTicket';
import TimelineCardMailCampaign from '../TimelineCardConfigs/TimelineCardMailCampaign';

export enum TimelineConfig {
	Activity = 'Activity',
	AddedClient = 'AddedClient',
	AddedContact = 'AddedContact',
	Agreement = 'Agreement',
	Appointment = 'Appointment',
	ClientPlan = 'ClientPlan',
	Comment = 'Comment',
	ActiveFlow = 'ActiveFlow',
	Opportunity = 'Opportunity',
	Order = 'Order',
	SocialEvent = 'SocialEvent',
	Ticket = 'Ticket',
	MailCampaign = 'MailCampaign'
}

export type TimelineComponentProps = {
	renderedByType?: string;
	renderedById?: number;
} & TimelineProps;

export type TimelineCardComponent = (props: TimelineComponentProps) => ReturnType<typeof TimelineGeneric> | null;

const timelineComponents = {
	[TimelineConfig.Activity]: TimelineCardActivity,
	[TimelineConfig.AddedClient]: ({ event, ...passthrough }) => (
		<TimelineGeneric
			event={event}
			titleElements={[
				event.users.length ? 'users' : null,
				() => (event.users.length ? T('default.didCreateAccount').toLowerCase() : T('default.accountCreated'))
			]}
			{...passthrough}
		/>
	),
	[TimelineConfig.AddedContact]: ({ event, ...passthrough }) => (
		<TimelineGeneric
			event={event}
			contactPreposition=""
			titleElements={[() => T('default.contact'), 'contacts', () => T('default.wasCreated').toLowerCase()]}
			afterTime={() => event.user?.name ?? event.users?.[0]?.name ?? ''}
			{...passthrough}
		/>
	),
	[TimelineConfig.Agreement]: ({ event, ...passthrough }) => (
		<TimelineGeneric
			event={event}
			eventLinkTitle={() => T('default.didCreateAgreement').toLowerCase()}
			onLinkClick={() => openAgreement({ agreementId: event.entityId })}
			{...passthrough}
		/>
	),
	[TimelineConfig.Appointment]: TimelineCardAppointment,
	[TimelineConfig.ClientPlan]: TimelineCardClientPlan,
	[TimelineConfig.Comment]: TimelineCardComment,
	[TimelineConfig.ActiveFlow]: TimelineCardActiveFlow,
	[TimelineConfig.Opportunity]: TimelineCardOpportunity,
	[TimelineConfig.Order]: TimelineCardOrder,
	[TimelineConfig.SocialEvent]: TimelineCardSocialEvent,
	[TimelineConfig.Ticket]: TimelineCardTicket,
	[TimelineConfig.MailCampaign]: TimelineCardMailCampaign
} satisfies {
	[k in string]: TimelineCardComponent;
};

const TimelineGenericRender = <TType extends keyof typeof timelineComponents>({
	type,
	...passthrough
}: { type: TType } & ComponentProps<(typeof timelineComponents)[TType]>) => {
	return timelineComponents[type]({ ...passthrough });
};

export default TimelineGenericRender;
