import PropTypes from 'prop-types';
import React from 'react';
import { Input, Row, Block } from '@upsales/components';
import _ from 'lodash';
import './StackedDateInput.scss';

export default class StackedDateInput extends React.Component {
	constructor(props) {
		super(props);

		this.onPresetChanged = this.onChange.bind(this, 'preset');
		this.onStartChange = this.onChange.bind(this, 'start');
		this.onEndChange = this.onChange.bind(this, 'end');

		this.lang = {
			anyTime: Tools.$translate('date.anyTime'),
			last7days: Tools.$translate('date.last7days'),
			last14days: Tools.$translate('date.last14days'),
			last30days: Tools.$translate('date.last30days'),
			last45days: Tools.$translate('date.last45days'),
			last60days: Tools.$translate('date.last60days'),
			last90days: Tools.$translate('date.last90days'),
			last180days: Tools.$translate('date.last180days'),
			lastXdays: Tools.$translate('date.lastXdays'),
			lastXweeks: Tools.$translate('date.lastXweeks'),
			lastXmonths: Tools.$translate('date.lastXmonths'),
			lastXyears: Tools.$translate('date.lastXyears'),
			nextXdays: Tools.$translate('date.nextXdays'),
			nextXweeks: Tools.$translate('date.nextXweeks'),
			nextXmonths: Tools.$translate('date.nextXmonths'),
			nextXyears: Tools.$translate('date.nextXyears'),
			currentMonth: Tools.$translate('date.currentMonth'),
			currentQuarter: Tools.$translate('date.currentQuarter'),
			currentWeek: Tools.$translate('date.currentWeek'),
			currentYear: Tools.$translate('date.currentYear'),
			lastMonth: Tools.$translate('date.lastMonth'),
			lastWeek: Tools.$translate('date.lastWeek'),
			lastYear: Tools.$translate('date.lastYear'),
			customDate: Tools.$translate('segment.filter.input.customDate'),
			whenever: Tools.$translate('date.whenever'),
			start: Tools.$translate('default.from'),
			end: Tools.$translate('default.to'),
			numOfDays: Tools.$translate('default.numOfDays'),
			numOfWeeks: Tools.$translate('default.numOfWeeks'),
			numOfMonths: Tools.$translate('default.numOfMonths'),
			numOfYears: Tools.$translate('default.numOfYears'),
			placeholder: Tools.$translate('date.chooseDate')
		};

		const { config } = this.props;

		const defaultPreset = [
			{ id: 'whenever', name: _.capitalize(this.lang.whenever) },
			{ id: 'currentMonth', name: _.capitalize(this.lang.currentMonth) },
			{ id: 'currentQuarter', name: _.capitalize(this.lang.currentQuarter) },
			{ id: 'currentWeek', name: _.capitalize(this.lang.currentWeek) },
			{ id: 'currentYear', name: _.capitalize(this.lang.currentYear) },
			{ id: 'lastMonth', name: _.capitalize(this.lang.lastMonth) },
			{ id: 'lastWeek', name: _.capitalize(this.lang.lastWeek) },
			{ id: 'lastYear', name: _.capitalize(this.lang.lastYear) },
			{ id: 'lastXdays', name: _.capitalize(this.lang.lastXdays) },
			{ id: 'custom', name: _.capitalize(this.lang.customDate) }
		];

		if (Array.isArray(config.presets) && config.presets.length) {
			this.dateArray = config.presets.map(preset => ({
				id: preset,
				name: _.capitalize(Tools.$translate(`date.${preset}`))
			}));
		} else {
			this.dateArray = defaultPreset;
		}
	}

	onChange(key, value) {
		const { filter } = this.props;
		const newFilter = _.cloneDeep(filter);

		if (key === 'preset') {
			newFilter.value = {};
			newFilter.value.preset = value.target.value || 'whenever';
			newFilter.value.start = undefined;
			newFilter.value.end = undefined;
		}

		const isDynamic = this.isDynamicPreset(newFilter.value.preset);

		switch (key) {
			case 'start':
				newFilter.value.start = value ? value : null;
				if (isDynamic) {
					newFilter.value.start = Math.abs(newFilter.value.start) || '';
				}
				break;
			case 'end':
				newFilter.value.end = value ? moment(value).endOf('day').toDate() : null;
				break;
		}

		newFilter.inactive = !(
			newFilter.value.start ||
			newFilter.value.end ||
			(newFilter.value.preset !== 'whenever' && !isDynamic)
		);

		this.props.onChange(newFilter);
	}

	getDynamicInputPlaceholder(preset) {
		const [, unit] = preset.split('X');
		return this.lang[`numOf${_.capitalize(unit)}`];
	}

	isDynamicPreset(preset) {
		return preset?.indexOf('lastX') === 0 || preset?.indexOf('nextX') === 0;
	}

	render() {
		const { filter, autoFocus } = this.props;
		const isCustom = filter.value.preset === 'custom';
		const presetValue = this.dateArray.find(({ id }) => id === filter.value.preset);
		const showDynamicInput = this.isDynamicPreset(filter.value.preset);

		return (
			<div className={'datepicker-group' + (isCustom ? ' datepicker-custom-visible' : '')}>
				<div className="datepicker-select-wrap" data-test-id="listfilters-history-opportunity-dateselect">
					<ReactTemplates.INPUTS.upSelect
						key={'datepicker-select'}
						data={this.dateArray}
						required={this.props.required}
						multiple={false}
						className="datepicker-select form-control"
						dropdownCssClass="StackedDateInput__select2-dropdown"
						onChange={this.onPresetChanged}
						autoOpen={autoFocus || false}
						defaultValue={presetValue}
						matcher={(term, undef, item) =>
							(item.name || '').toLowerCase().indexOf((term || '').toLowerCase()) !== -1
						}
					/>
				</div>
				{filter.value.preset === 'custom' ? (
					<div className="datepicker-wrap" key="datepicker-wrap">
						<div id="datepicker-container">
							<ReactTemplates.upFilters.components.datepicker
								placeholder={this.lang.start}
								className="form-control"
								value={filter.value.start}
								onChange={this.onStartChange}
								container={'body'}
							/>
							<ReactTemplates.upFilters.components.datepicker
								placeholder={this.lang.end}
								className="form-control"
								value={filter.value.end}
								onChange={this.onEndChange}
								container="body"
								placement="bottom-right"
							/>
						</div>
					</div>
				) : null}
				{showDynamicInput ? (
					<div className="datepicker-wrap" key="datepicker-wrap">
						<Row>
							<Block space="prm plm">{this.lang[filter.value.preset]}</Block>
							<Input
								type="number"
								placeholder={this.getDynamicInputPlaceholder(filter.value.preset)}
								value={filter.value.start || ''}
								onChange={e => this.onStartChange(Math.min(1000, e.target.value))}
							/>
						</Row>
					</div>
				) : null}
			</div>
		);
	}
}

StackedDateInput.propTypes = {
	filter: PropTypes.object.isRequired,
	config: PropTypes.object.isRequired,
	onChange: PropTypes.func.isRequired,
	autoFocus: PropTypes.bool,
	required: PropTypes.bool
};

StackedDateInput.defaultProps = {
	config: {},
	required: true
};
