import React from 'react';
import BemClass from '@upsales/components/Utils/bemClass';
import Comment from 'App/resources/Model/Comment';
import { Card } from '@upsales/components';
import NoteRow from './NoteRow';

const renderCommentRow = (comment: Comment, key: number) => {
	return <NoteRow comment={comment} key={key} />;
};
const renderNoteSection = (notes: string, classes: BemClass) => {
	if (!notes) {
		return null;
	}
	return (
		<div className={classes.elem('notes-section').b()}>
			<NoteRow text={notes} iconProps={{ icon: 'pencil', color: 'grey-10' }} hideEdit />
		</div>
	);
};

interface Props {
	comments: Comment[];
	notes?: string;
}

const TimelineNoteComments = ({ comments, notes }: Props) => {
	const classes = new BemClass('TimelineNoteComments');

	const hasNotesOrComments = !!notes?.length || !!comments.length;

	if (!hasNotesOrComments) {
		return null;
	}

	return (
		<Card className={classes.b()}>
			{comments.map(c => renderCommentRow(c, c.id))}
			{notes ? renderNoteSection(notes, classes) : null}
		</Card>
	);
};
export default TimelineNoteComments;
