import { type ModalConfigName } from './modalConfigs';

const hasActiveScripts = (types: string[]) => {
	return (
		Tools.FeatureHelper.isAvailable(Tools.FeatureHelper.Feature.UI_SCRIPTS) &&
		Tools.AppService.getScripts().some(script => script.active && types.includes(script.type))
	);
};

const shouldOpenModal = (name: ModalConfigName) => {
	switch (name) {
		case 'CreateAccount':
			return (
				Tools.FeatureHelper.hasSoftDeployAccess('CREATE_ACCOUNT_REACT') && !hasActiveScripts(['client_edit'])
			);
		case 'EditClient':
			return Tools.FeatureHelper.hasSoftDeployAccess('EDIT_CLIENT_REACT') && !hasActiveScripts(['client_edit']);
		default:
			return true;
	}
};

export default shouldOpenModal;
