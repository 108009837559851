// The purpose of this file is to help transition ReportcenterActions to typescript
// Eventually we can move this code back to ReportcenterActions
import {
	RESET,
	SET_FETCHING_DASHBOARDS,
	SET_CREATING_DASHBOARD,
	SET_DASHBOARDS,
	SET_LOADING,
	SET_LOADING_DASHBOARD,
	SET_PAGE_ERROR,
	SET_DASHBOARD_ERROR,
	SET_WIDGET_DATA,
	SET_WIDGET_META,
	SET_WIDGET_CATEGORIES,
	SET_EDIT_WIDGET_DATA,
	SET_EDIT_WIDGET_CATEGORY,
	SET_ACTIVE_DRILLDOWN,
	SET_LOADING_DRILLDOWN,
	SET_EDITING_DASHBOARD,
	SET_SELECTED_HASH,
	SET_INITIAL_HASH,
	SET_PENDING_DASHBOARD_SAVE,
	SET_SELECTED_WIDGET_HASH,
	SET_INITIAL_WIDGET_HASH,
	SET_SCHEDULED_DASHBOARDS,
	SET_SCHEDULED_SELECTED_DASHBOARD,
	SET_SELECTED_VIEW,
	SET_DASHBOARD_FILTERS,
	SET_SELECTED_DASHBOARD,
	SET_DELETING_DASHBOARD,
	SET_SCHEDULING,
	RESET_EDIT_WIDGET_DATA,
	initialState,
	SET_EDIT_WIDGET,
	SET_SCHEDULED_DASHBOARD_FILTERS
} from 'Store/reducers/ReportcenterReducer';

import { Feature } from '../FeatureHelperActions';
import { ReportcenterState } from 'Store/reducers/ReportcenterReducer';
import LZString from 'lz-string';
import { AppThunk } from 'App/rc-dashboard/store';

const getHash = (db: ReportcenterState['selectedDashboard'], filters: ReportcenterState['dashboardFilters']) =>
	LZString.compressToBase64(JSON.stringify({ widgets: db?.widgets ?? {}, filters }));
const getWidgetHash = (db: ReportcenterState['selectedDashboard']) =>
	LZString.compressToBase64(JSON.stringify({ widgets: db?.widgets ?? {} }));

export const reset = () => ({ type: RESET });
export const setFetchingDashboards = (fetchingDashboards: ReportcenterState['fetchingDashboards']) => ({
	type: SET_FETCHING_DASHBOARDS,
	fetchingDashboards
});
export const setCreatingDashboard = (creatingDashboard: ReportcenterState['creatingDashboard']) => ({
	type: SET_CREATING_DASHBOARD,
	creatingDashboard
});
export const setDashboards = (dashboards: ReportcenterState['dashboards']) => ({ type: SET_DASHBOARDS, dashboards });
export const setLoading = (loading: ReportcenterState['loading']) => ({ type: SET_LOADING, loading });
export const setLoadingDashboard = (loadingDashboard: ReportcenterState['loadingDashboard']) => ({
	type: SET_LOADING_DASHBOARD,
	loadingDashboard
});
export const setPendingDashboardSave = (pendingDashboardSave: ReportcenterState['pendingDashboardSave']) => ({
	type: SET_PENDING_DASHBOARD_SAVE,
	pendingDashboardSave
});
export const setPageError = (pageError: ReportcenterState['pageError']) => ({ type: SET_PAGE_ERROR, pageError });
export const setDashboardError = (dashboardError: ReportcenterState['dashboardError']) => ({
	type: SET_DASHBOARD_ERROR,
	dashboardError
});
export const setWidgetDataObject = (widgetData: ReportcenterState['widgetData']) => ({
	type: SET_WIDGET_DATA,
	widgetData
});
export const setWidgetMeta = (widgetMeta: ReportcenterState['widgetMeta']) => ({ type: SET_WIDGET_META, widgetMeta });
export const setWidgetCategories = (widgetCategories: ReportcenterState['widgetCategories']) => ({
	type: SET_WIDGET_CATEGORIES,
	widgetCategories
});
export const setEditWidgetData = (editWidgetData: ReportcenterState['editWidgetData']) => ({
	type: SET_EDIT_WIDGET_DATA,
	editWidgetData
});
export const setEditWidgetCategory = (editWidgetCategory: ReportcenterState['editWidgetCategory']) => ({
	type: SET_EDIT_WIDGET_CATEGORY,
	editWidgetCategory
});
export const setActiveDrilldown = (activeDrilldown: ReportcenterState['activeDrilldown']) => ({
	type: SET_ACTIVE_DRILLDOWN,
	activeDrilldown
});
export const setScheduledDashboards = (scheduledDashboards: ReportcenterState['scheduledDashboards']) => ({
	type: SET_SCHEDULED_DASHBOARDS,
	scheduledDashboards
});
export const setLoadingDrilldown = (loadingDrilldown: ReportcenterState['loadingDrilldown']) => ({
	type: SET_LOADING_DRILLDOWN,
	loadingDrilldown
});
export const setEditingDashboard = (editingDashboard: ReportcenterState['editingDashboard']) => ({
	type: SET_EDITING_DASHBOARD,
	editingDashboard
});
export const setScheduledSelectedDashboard = (
	scheduledSelectedDashboard: ReportcenterState['scheduledSelectedDashboard']
) => ({
	type: SET_SCHEDULED_SELECTED_DASHBOARD,
	scheduledSelectedDashboard
});
export const setInitialDashboardHash = (
	db: ReportcenterState['selectedDashboard'],
	filters: ReportcenterState['dashboardFilters']
) => ({
	type: SET_INITIAL_HASH,
	initialDashboardHash: getHash(db, filters)
});
export const setSelectedDashboardHash = (
	db: ReportcenterState['selectedDashboard'],
	filters: ReportcenterState['dashboardFilters']
) => ({
	type: SET_SELECTED_HASH,
	selectedDashboardHash: getHash(db, filters)
});
export const setInitialWidgetHash = (db: ReportcenterState['selectedDashboard']) => ({
	type: SET_INITIAL_WIDGET_HASH,
	initialWidgetHash: getWidgetHash(db)
});
export const setSelectedWidgetHash = (db: ReportcenterState['selectedDashboard']) => ({
	type: SET_SELECTED_WIDGET_HASH,
	selectedWidgetHash: getWidgetHash(db)
});
export const setSelectedView = (selectedView: ReportcenterState['selectedView']) => ({
	type: SET_SELECTED_VIEW,
	selectedView
});
export const setDashboardFilters =
	(dashboardFilters: ReportcenterState['dashboardFilters']): AppThunk =>
	(dispatch, getState) => {
		const { selectedDashboard } = getState().Reportcenter;
		dispatch(setSelectedDashboardHash(selectedDashboard, dashboardFilters));
		dispatch(setSelectedWidgetHash(selectedDashboard));
		dispatch({ type: SET_DASHBOARD_FILTERS, dashboardFilters });
	};
export const setSelectedDashboard =
	(selectedDashboard: ReportcenterState['selectedDashboard']): AppThunk =>
	(dispatch, getState) => {
		const { dashboardFilters } = getState().Reportcenter;
		dispatch(setSelectedDashboardHash(selectedDashboard, dashboardFilters));
		dispatch(setSelectedWidgetHash(selectedDashboard));
		dispatch({ type: SET_SELECTED_DASHBOARD, selectedDashboard });
	};
export const setDeletingDashboard = (deletingDashboard: ReportcenterState['deletingDashboard']) => ({
	type: SET_DELETING_DASHBOARD,
	deletingDashboard
});
export const setScheduling =
	(scheduling: ReportcenterState['scheduling']): AppThunk =>
	dispatch => {
		dispatch({ type: SET_SCHEDULING, scheduling });
	};
export const resetEditWidgetData = (): AppThunk => dispatch => {
	dispatch({ type: RESET_EDIT_WIDGET_DATA });
};
export const setEditWidget =
	(
		editWidget: ReportcenterState['editWidget'],
		willFetchData?: boolean,
		shouldSetCategory?: boolean,
		categoryOverride?: string
	): AppThunk =>
	(dispatch, getState) => {
		const { editWidgetData, widgetMeta } = getState().Reportcenter;
		// reset widgetData if editWidget is removed
		if (!editWidget) {
			dispatch(setEditWidgetData(initialState.editWidgetData));
			if (Tools.FeatureHelper.hasSoftDeployAccess('NEW_WIDGET_GALLERY')) {
				dispatch(setEditWidgetCategory(null));
			}
		}
		// set loading to prevent mismatch between widget config and widget data when rendering widget
		if (willFetchData) {
			dispatch(setEditWidgetData({ ...editWidgetData, loading: true }));
		}
		// set editWidgetCategory if necessary
		if (
			Tools.FeatureHelper.hasSoftDeployAccess('NEW_WIDGET_GALLERY') &&
			editWidget &&
			editWidget.type &&
			shouldSetCategory
		) {
			const category =
				categoryOverride ??
				(widgetMeta[editWidget.type].proWidget && Tools.FeatureHelper.isAvailable(Feature.REPORTCENTER_PRO)
					? 'proWidgets'
					: ['emailCampaign', 'form', 'socialEvent', 'flow', 'websiteVisit'].includes(
							widgetMeta[editWidget.type].family
					  )
					? 'marketing'
					: widgetMeta[editWidget.type].family);
			dispatch(setEditWidgetCategory(category));
		}
		dispatch({ type: SET_EDIT_WIDGET, editWidget });
	};
export const setScheduledDashboardFilters =
	(scheduledDashboardFilters: ReportcenterState['scheduledDashboardFilters']): AppThunk =>
	dispatch => {
		dispatch({ type: SET_SCHEDULED_DASHBOARD_FILTERS, scheduledDashboardFilters });
	};
