import Flow from 'App/resources/Flow';
import FlowTemplateName from '../FlowTemplateName';
import buildFlowPath from './buildFlowPath';
import { FlowTemplate } from './FlowTemplate';
import getAngularModule from 'App/babel/angularHelpers/getAngularModule';
import { SegmentFilter } from 'App/resources/Model/Segment';

const template: FlowTemplate = {
	name: FlowTemplateName.WELCOME_NEW_CUSTOMERS,
	title: 'flowtemplate.WELCOME_NEW_CUSTOMERS.title',
	subtitle: 'flowtemplate.WELCOME_NEW_CUSTOMERS.subtitle',
	imgPath: 'img/welcome_new_customers.svg',
	imgPathPreview: 'img/welcome_new_customers_preview.svg',
	chipText: 'flowtemplate.WELCOME_NEW_CUSTOMERS.chipText',
	chipIcon: 'stopwatch',
	getFlowPath: () => {
		return buildFlowPath([
			Flow.newStep('sendMail'),
			Flow.newStep('snooze', { waitTime: 7 }),
			Flow.newStep('sendMail'),
			Flow.newStep('snooze', { waitTime: 7 }),
			Flow.newStep('sendMail')
		]);
	},
	getSegmentFilters: (numberOfDays: number = 1) => {
		const FilterHelper = getAngularModule('FilterHelper');
		const entity = 'contact';

		const MailBounced = FilterHelper.filter('MailBounced', entity);
		MailBounced.inactive = false;
		MailBounced.value = 'hard';
		MailBounced.comparisonType = 'Equals';

		const HasEmail = FilterHelper.filter('HasEmail', entity);
		HasEmail.inactive = false;
		HasEmail.value = true;

		const JourneyStep = FilterHelper.filter('JourneyStep', entity);
		JourneyStep.inactive = false;
		JourneyStep.value = ['customer'];

		const JourneyStepChangedDate = FilterHelper.filter('JourneyStepChangedDate', entity);
		JourneyStepChangedDate.inactive = false;
		JourneyStepChangedDate.value = {
			start: numberOfDays,
			preset: 'lastXdays'
		};

		const includeConfig = { HasEmail, JourneyStep, JourneyStepChangedDate };
		const excludeConfig = { MailBounced };

		const includeBody = FilterHelper.parseFilters(includeConfig, entity, undefined, undefined, {
			useTags: true,
			groupAllFilters: true
		}).build() as SegmentFilter['body'];

		const excludeBody = FilterHelper.parseFilters(excludeConfig, entity, undefined, undefined, {
			useTags: true,
			groupAllFilters: true
		}).build() as SegmentFilter['body'];

		return [
			{ config: includeConfig, body: includeBody, isExclude: false, orGroup: false },
			{ config: excludeConfig, body: excludeBody, isExclude: true, orGroup: true }
		];
	}
};

export default template;
