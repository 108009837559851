import { Link, Text, Tooltip } from '@upsales/components';
import BemClass from '@upsales/components/Utils/bemClass';
import Comment from 'App/resources/Model/Comment';
import Event from 'App/resources/Model/Event';
import T from 'Components/Helpers/translate';
import { makeCancelable } from 'Helpers/promise';
import CommentResource from 'Resources/Comment';
import moment from 'moment';
import React, { useEffect, useMemo, useState } from 'react';
import TimelineRow, { TimelineRowProps } from '../TimelineRow';
import './TimelineGeneric.scss';
import logError from 'Helpers/logError';
import TimelineNoteComments from '../TimelineNoteComments';
import { getTimelineIconProps } from 'Components/Helpers/getTimelineIconProps';
import { useSoftDeployAccess } from 'App/components/hooks';
import SubaccountsColumn from 'Components/Account/AccountListContacts/Columns/SubaccountsColumn';
import type Client from 'App/resources/Model/Client';

// Generic timeline card. Should be able to replace many of the special case timeline cards that
// do very similar things, which should result in code that is easier to follow and more consistant
// behavior
// Hopefully should be able to get around using TimelineHistoryUsers completely

// Provide a lang tag or a function to generate a string based on the event
type TitleTag = string | ((e: Event) => string);

type DefaultElement = 'users' | 'eventLink' | 'action' | 'contacts' | 'time' | 'afterTime';
// Pass in the elements to include in the title, either as a string for predetermined ones or as a function that renders a
// custom Text element
type ElementRender = DefaultElement | ((e: Event) => React.ReactNode) | null;

export type TimelineProps = {
	event: Event;
	actionTitle?: TitleTag;
	eventLinkTitle?: TitleTag;
	onLinkClick?: React.MouseEventHandler;
	afterTime?: TitleTag;
	contactPreposition?: string;
	titleElements?: ElementRender[];
	subTitleElements?: ElementRender[];
	/**
	 * Used to bypass this component with a different one. Used for code that goes through here but doesn't
	 * fit the format, or components that are only partially converted
	 */
	bypassComponent?: JSX.Element | null;
	commentFetchFilters?: { [key: string]: any };
	notes?: string;
	showComments?: boolean;
	/**
	 * Boolean flag to set if icon is included with card. When set, generates icon props to pass to TimelineRow based on event
	 * @see { getTimelineIconProps }
	 * */
	includeIcon?: boolean;
	/** Enables clicking anywhere on the card to trigger onLinkClick */
	enableCardClick?: boolean;
	client?: Pick<Client, 'id' | 'name'>;
} & Partial<TimelineRowProps>;

const getComments = async (filters: TimelineProps['commentFetchFilters']) => {
	const { data: comments } = await CommentResource.find(filters);
	return comments;
};

const andOthers = (list: { name: string }[]) =>
	list.length > 1 ? (
		<>
			{` ${T('and')} `}
			<Tooltip
				title={list
					.slice(1)
					.map(e => e.name)
					.join(', ')}
			>
				<b>{`${list.length - 1} ${T('timeline.others')}`}</b>
			</Tooltip>
		</>
	) : null;

const TimelineGeneric = ({
	event,
	actionTitle,
	eventLinkTitle,
	onLinkClick,
	afterTime,
	contactPreposition = 'default.with',
	titleElements = ['users', 'action', 'eventLink', 'contacts'],
	subTitleElements = ['time', 'afterTime'],
	bypassComponent,
	commentFetchFilters,
	notes = '',
	showComments = true,
	includeIcon = false,
	enableCardClick = false,
	className,
	client,
	...props
}: TimelineProps) => {
	const { noCommentRow = false } = props;
	const [comments, setComments] = useState<Comment[]>([]);
	const hasSubaccounts = useSoftDeployAccess('SUB_ACCOUNTS');

	const fetchComments = () => {
		const promise = makeCancelable(getComments(commentFetchFilters));
		promise.promise
			.then(comments => {
				setComments(comments);
			})
			.catch(err => {
				logError(err, 'failed to fetch comments for event');
			});
		return promise;
	};

	const subaccountIcon =
		client && event.client && client.id !== event.client.id
			? SubaccountsColumn({
					client: { id: event.client.id, name: event.client.name },
					space: 'pls'
			  })
			: null;

	useEffect(() => {
		if (commentFetchFilters && Tools.FeatureHelper.hasSoftDeployAccess('TODO_LIST')) {
			let promise = fetchComments();
			const commentListeners = ['comment.added', 'comment.deleted', 'comment.updated'].map(eventName =>
				Tools.$rootScope.$on(eventName, (_e, entity) => {
					if (
						[entity.opportunity?.id, entity.appointment?.id, entity.client?.id, entity.contact?.id].some(
							id => id === event.entityId
						)
					) {
						promise = fetchComments();
					}
				})
			);

			return () => {
				commentListeners.forEach(listener => listener());
				promise?.cancel();
			};
		}
	}, [event.id, event.entityType]);

	const commentComponent = <TimelineNoteComments comments={comments} notes={notes} />;

	// We highjack how useMemo works to listen for changes to showComments
	let changedExpanded = null;
	useMemo(() => {
		changedExpanded = showComments;
	}, [showComments]);

	const iconProps = useMemo(() => {
		if (includeIcon) {
			const { name: icon, color: iconColor, backgroundColor: iconBackground } = getTimelineIconProps(event);
			return { icon, iconColor, iconBackground };
		}
		return {};
	}, [includeIcon]);

	if (bypassComponent) {
		return (
			<div
				key={event.id ?? `${event.entityType}-${event.entityId}`}
				className={new BemClass('TimelineGeneric').elem('bypass').b()}
			>
				{bypassComponent}
				{commentComponent}
			</div>
		);
	}

	const { contacts, date } = event;
	const users = event.user ? [event.user] : event.users?.filter(x => !!x) ?? [];

	const getTitleTag = (titleTag: TitleTag = '') => {
		return typeof titleTag === 'string' ? T(titleTag) : titleTag(event);
	};

	const titleTags = {
		actionTitle: getTitleTag(actionTitle) ?? '',
		eventLinkTitle: getTitleTag(eventLinkTitle) ?? '',
		afterTime: getTitleTag(afterTime) ?? ''
	};

	const titleElementConstructors: { [k in DefaultElement]: () => JSX.Element | null } = {
		users: () =>
			users.length ? (
				<>
					<b>{`${users[0].name}`}</b>
					{andOthers(users)}
				</>
			) : (
				<b>{T('default.someone')}</b>
			),
		action: () => (titleTags.actionTitle.length ? <Text>{titleTags.actionTitle}</Text> : null),
		eventLink: () =>
			onLinkClick && titleTags.eventLinkTitle.length ? (
				<Text italic>
					<Link
						onClick={e => {
							e.stopPropagation();
							onLinkClick(e);
						}}
					>
						{titleTags.eventLinkTitle}
					</Link>
				</Text>
			) : null,
		contacts: () =>
			contacts?.length && contacts[0].name ? (
				<>
					{`${T(contactPreposition).toLowerCase()} `}
					<Link
						onClick={e => e.stopPropagation()}
						href={Tools.$state.href('contact.dashboard', {
							customerId: event?.client?.id ?? 0,
							id: contacts[0].id
						})}
					>
						{contacts[0].name}
					</Link>
					{hasSubaccounts && contacts.length === 1 ? subaccountIcon : null}
					{andOthers(contacts)}
				</>
			) : null,
		time: () =>
			date ? (
				<Text size="sm" color={'grey-11'}>
					{moment(date).format('L LT')}
				</Text>
			) : null,
		afterTime: () => (titleTags.afterTime.length ? <>{`• ${titleTags.afterTime}`}</> : null)
	};

	const renderElement = (elem: ElementRender, i: number) => {
		if (elem === null) {
			return null;
		}

		const content = typeof elem === 'string' ? titleElementConstructors[elem]() : elem(event);
		return content ? <React.Fragment key={i}>{content} </React.Fragment> : null;
	};

	const title = (
		<Text className={new BemClass('TimelineHistoryUsers').elem('combined-title').b()}>
			{titleElements.map(renderElement)}
		</Text>
	);

	const renderedSubElems = subTitleElements.map(renderElement).filter(e => !!e);
	const subTitle = renderedSubElems.length ? (
		<Text size="sm" color={'grey-11'}>
			{renderedSubElems}
		</Text>
	) : null;

	return (
		<TimelineRow
			key={event.id ?? `${event.entityType}-${event.entityId}`}
			onCardClick={enableCardClick ? onLinkClick : undefined}
			className={new BemClass('TimelineGeneric', className).b()}
			event={event}
			title={title}
			subTitle={subTitle}
			children={commentComponent}
			isExpandable={!noCommentRow && (!!comments?.length || !!notes?.length)}
			expanded={changedExpanded}
			{...iconProps}
			{...props}
		/>
	);
};

export default TimelineGeneric;
