import './ConfirmationDrawer.scss';
import React, { ComponentProps } from 'react';
import { Headline, Block } from '@upsales/components';
import { PrimaryButton, ThirdButton } from '@upsales/components/Buttons';
import BemClass from '@upsales/components/Utils/bemClass';
import { SlideFade } from 'App/components/animations';

type Props = {
	className?: string;
	visible?: boolean;
	animationDirection?: ComponentProps<typeof SlideFade>['direction'];
	maxHeight?: number;
	title?: string;
	confirmButtonText?: string;
	onConfirm?: () => void;
	cancelButtonText?: string;
	onCancel?: () => void;
};

const ConfirmationDrawer = ({
	className = '',
	visible,
	animationDirection = 'top',
	maxHeight,
	title,
	confirmButtonText,
	onConfirm,
	cancelButtonText,
	onCancel
}: Props) => {
	const classes = new BemClass('ConfirmationDrawer');
	classes.add(className);

	return (
		<SlideFade direction={animationDirection} visible={visible} maxHeight={maxHeight}>
			<Block className={classes.mod({ visible }).b()}>
				<div className={classes.elem('content').b()}>
					<Block space="mbxl">
						<Headline size="sm">{title}</Headline>
					</Block>
					<div>
						<PrimaryButton onClick={onConfirm ? () => onConfirm() : undefined}>
							{confirmButtonText}
						</PrimaryButton>
						<ThirdButton onClick={onCancel ? () => onCancel() : undefined}>{cancelButtonText}</ThirdButton>
					</div>
				</div>
			</Block>
		</SlideFade>
	);
};

export default ConfirmationDrawer;
