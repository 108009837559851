import Attribute from './Attribute';

const SalesCoachChecklistItemAttributes = {
	id: new Attribute({
		type: Attribute.types.Number,
		field: 'id'
	}),
	title: new Attribute({
		title: 'default.title',
		type: Attribute.types.String,
		field: 'title'
	}),
	stages: new Attribute({
		title: 'default.stages',
		type: Attribute.types.Array,
		attr: {
			id: {
				field: 'stages.id',
				type: Attribute.types.Number
			},
			name: {
				field: 'stages.name',
				type: Attribute.types.String
			}
		}
	}),
	salesCoach: new Attribute({
		title: 'default.salesCoach',
		type: Attribute.types.Object,
		attr: {
			id: {
				field: 'salesCoach.id',
				type: Attribute.types.Number
			},
			name: {
				field: 'salesCoach.name',
				type: Attribute.types.String
			}
		}
	})
};

export default SalesCoachChecklistItemAttributes;
