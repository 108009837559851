import React from 'react';
import { Row, Column } from '@upsales/components';
import WidgetRowRender from '../WidgetRowRender';
import './ColsRenderType.scss';
import BemClass from '@upsales/components/Utils/bemClass';

const ColsRenderType = ({ row, ...props }) => {
	const classes = new BemClass('ColsRenderType', 'widget-cols-wrapper');
	return (
		<Row className={classes.b()}>
			{row.cols.map((col, i) => (
				<Column key={i} align={col.align} size={col.size} className={col.ellipsis ? 'ellipsis-col' : ''}>
					<WidgetRowRender row={col.element} {...props} />
				</Column>
			))}
		</Row>
	);
};

export default ColsRenderType;
