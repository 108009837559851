class Filter {
	constructor(filterName, fields = {}) {
		this._name = filterName;
		this._shortName = filterName
			.match(/[A-Z][a-z]+/g)
			.map(n => n[0].toLowerCase())
			.join('');
		this._fields = JSON.parse(JSON.stringify(fields));
	}
}

window.Filter = Filter;
export default Filter;

// CombinedFilter commit 41573b6a0b6d5f6173210ba85b95fae2e24c2769
