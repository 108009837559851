import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Button, Card, Label, Title, Row, Column, Loader, Icon, Toggle, Text } from '@upsales/components';
import { DefaultButton } from '@upsales/components/Buttons';
import colorMappings from '@upsales/components/Utils/colorMappings';
import {
	addSegment,
	setSelectedSegment,
	selectContactsManually,
	startImportAfterLaunch,
	setManualSelectedContact,
	setSetImportAsAttending
} from 'Store/actions/SocialEventActions';
import SocialEventPageHeader from './SocialEventPageHeader';
import './EditSocialEventAddContacts.scss';
import UpSelect from 'Components/Inputs/UpSelect';
import T from 'Components/Helpers/translate';
import BemClass from '@upsales/components/Utils/bemClass';

const propTypes = {
	className: PropTypes.string,
	indicator: PropTypes.number,
	segments: PropTypes.array.isRequired,
	addSegment: PropTypes.func.isRequired,
	editId: PropTypes.number,
	setSelectedSegment: PropTypes.func,
	selectedSegment: PropTypes.object,
	selectContactsManually: PropTypes.func,
	modal: PropTypes.bool,
	close: PropTypes.func,
	isDraft: PropTypes.bool,
	manualSelectedContact: PropTypes.object,
	startImportAfterLaunch: PropTypes.func,
	setManualSelectedContact: PropTypes.func,
	importAsAttending: PropTypes.bool,
	segmentsLoading: PropTypes.bool,
	setSetImportAsAttending: PropTypes.func,
	missingAccess: PropTypes.bool
};

const defaultProps = {
	segments: [],
	close: () => {}
};

const mapStateToProps = state => ({
	segments: state.SocialEvent.segments,
	editId: state.SocialEvent.editId,
	selectedSegment: state.SocialEvent.selectedSegment,
	isDraft: state.SocialEvent.edit.draft,
	manualSelectedContact: state.SocialEvent.manualSelectedContact,
	importAsAttending: state.SocialEvent.setImportAsAttending,
	segmentsLoading: state.SocialEvent.segmentsLoading,
	missingAccess: !state.SocialEvent.edit.userEditable
});

const mapDispatchToProps = {
	addSegment,
	setSelectedSegment,
	selectContactsManually,
	startImportAfterLaunch,
	setManualSelectedContact,
	setSetImportAsAttending
};

class EditSocialEventAddContacts extends React.Component {
	constructor(props) {
		super(props);

		this.lang = {
			segments: T('segment.segments'),
			selectContactsToInvite: T('socialEvent.selectContactsToInvite'),
			selectContactsManually: T('socialEvent.selectContactsManually'),
			youCanAlwaysAdMore: T('socialEvent.youCanAlwaysAdMore'),
			useSavedSegment: T('socialEvent.useSavedSegment'),
			chooseSegment: T('segment.chooseSegment'),
			addContacts: T('default.addContacts'),
			addMoreContactsToEvent: T('socialEvent.addMoreContactsToEvent'),
			changeContact: T('socialEvent.changeContact'),
			selectSegmentOrContactToAdd: T('socialEvent.selectSegmentOrContactToAdd'),
			add: T('default.add'),
			setAsAdded: T('socialEvent.setAsAdded'),
			setAsAddedInfo: T('socialEvent.setAsAddedInfo')
		};

		this.colors = {
			grey: colorMappings.get('grey-10'),
			white: colorMappings.get('white')
		};

		this.selectContactsManually = this.selectContactsManually.bind(this);
	}

	selectContactsManually() {
		// Close modal if this is a draft event
		if (this.props.isDraft && this.props.modal) {
			this.props.close(false);
		}
		this.props.selectContactsManually();
	}

	render() {
		const classses = new BemClass('EditSocialEventContacts', this.props.className);
		const {
			segments: segmentArray,
			addSegment,
			setSelectedSegment,
			editId,
			selectedSegment,
			modal,
			close,
			isDraft,
			manualSelectedContact,
			startImportAfterLaunch,
			setManualSelectedContact,
			importAsAttending,
			setSetImportAsAttending,
			segmentsLoading
		} = this.props;
		const {
			selectContactsToInvite,
			youCanAlwaysAdMore,
			useSavedSegment,
			segments,
			chooseSegment,
			selectContactsManually,
			addContacts,
			addMoreContactsToEvent,
			changeContact,
			selectSegmentOrContactToAdd,
			add,
			setAsAdded,
			setAsAddedInfo
		} = this.lang;
		const nothingSelected = !selectedSegment && !manualSelectedContact;
		if (modal) {
			classses.add('FullScreenModal');
		}
		return (
			<div className={classses.b()}>
				{modal ? (
					<button className="FullScreenModal__close" onClick={() => close(false)}>
						<Icon name="times" />
					</button>
				) : null}

				{modal ? (
					<SocialEventPageHeader title={addMoreContactsToEvent} text={youCanAlwaysAdMore} />
				) : (
					<SocialEventPageHeader
						number={this.props.indicator}
						title={selectContactsToInvite}
						text={youCanAlwaysAdMore}
					/>
				)}
				<div className="EditSocialEventAddContacts__select-segment-wrapper">
					<Card>
						<Row>
							<Column className="EditSocialEventAddContacts__select-segment-card">
								<Title size="md">{useSavedSegment}</Title>
								<div>
									<Label>{segments}</Label>
									{!segmentsLoading && editId ? (
										<div>
											<UpSelect
												className="form-control"
												onChange={e => {
													const id = parseInt(e.target.value);
													const selectedSegment = _.find(segmentArray, { id });
													if (selectedSegment && selectedSegment.nrOfContacts === null) {
														Tools.Segments.get(id, {
															params: { mapContactCount: true }
														}).then(({ data }) => {
															setSelectedSegment(data);
														});
													}
													setImmediate(() => {
														// Let upselect set state before this
														setSelectedSegment(selectedSegment);
													});
												}}
												data={segmentArray}
												defaultValue={selectedSegment}
												formatResult={(s, container, query, escape) => escape(s.name)}
												formatSelection={(s, el, encode) => {
													return `${encode(s.name)}<div class="pull-right">${
														s.nrOfContacts != null
															? `<b class="Icon Icon-user"></b> ${s.nrOfContacts}`
															: ''
													}</div>`;
												}}
												getId={s => s.id}
												placeholder={chooseSegment}
												matcher={(term, arg2, item) => {
													return item.name.toLowerCase().indexOf(term.toLowerCase()) !== -1;
												}}
												disabled={this.props.missingAccess}
											/>
											{this.props.selectedSegment && isDraft ? (
												<Button
													shadow={false}
													className="EditSocialEventAddContacts__confirm-segment"
													block
													color="green"
													onClick={() => {
														close(true);
														addSegment();
													}}
												>
													{addContacts}
												</Button>
											) : null}
										</div>
									) : (
										<Loader size="xs" style={{ margin: '0 auto' }} />
									)}
								</div>
								<div
									className={
										'EditSocialEventAddContacts__card-cover' +
										(manualSelectedContact
											? ' EditSocialEventAddContacts__card-cover--visible'
											: '')
									}
								/>
							</Column>
							<Column className="EditSocialEventAddContacts__select-contact-card">
								<Title size="md">{selectContactsManually}</Title>
								<div className="EditSocialEventAddContacts__manual-select-contact">
									{manualSelectedContact ? (
										<div className="EditSocialEventAddContacts__selected-contact">
											{manualSelectedContact.name}
											<Button
												type="link"
												color="grey"
												onClick={() => setManualSelectedContact(null)}
											>
												<Icon name="times-circle" />
											</Button>
										</div>
									) : null}
									<DefaultButton
										size="lg"
										onClick={this.selectContactsManually}
										disabled={this.props.missingAccess}
									>
										{manualSelectedContact ? changeContact : selectContactsManually}
									</DefaultButton>
								</div>
								<div
									className={
										'EditSocialEventAddContacts__card-cover' +
										(selectedSegment ? ' EditSocialEventAddContacts__card-cover--visible' : '')
									}
								/>
							</Column>
						</Row>
					</Card>

					{!isDraft ? (
						<div className="EditSocialEventAddContacts__after-launch-settings">
							<div className="EditSocialEventAddContacts__after-launch-settings-toggle">
								<Toggle
									size="lg"
									color="bright-blue"
									checked={importAsAttending}
									onChange={setSetImportAsAttending}
								/>
								<Title size="sm" color="grey-13">
									{setAsAdded}
								</Title>
								<Text size="sm" color="grey-11">
									{setAsAddedInfo}
								</Text>
							</div>
							<Button
								block
								disabled={nothingSelected}
								size="lg"
								onClick={() => {
									startImportAfterLaunch();
									close(true);
								}}
							>
								{nothingSelected ? selectSegmentOrContactToAdd : add}
							</Button>
						</div>
					) : null}
				</div>
			</div>
		);
	}
}

EditSocialEventAddContacts.propTypes = propTypes;
EditSocialEventAddContacts.defaultProps = defaultProps;
export const detached = EditSocialEventAddContacts;
const Component = connect(mapStateToProps, mapDispatchToProps)(EditSocialEventAddContacts);
export default Component;
