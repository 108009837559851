import React, { useMemo } from 'react';
import T from 'Components/Helpers/translate';
import { Link, Title, DrawerHeader, Flex } from '@upsales/components';

import type { DrawerClient } from './SubaccountDrawer';

type Props = {
	client: DrawerClient;
	goToAccount: (client: Pick<DrawerClient, 'id'>) => void;
	close: () => void;
};

const Header = ({ client, goToAccount, close }: Props) => {
	const lang = useMemo(() => ({ title: T('SubaccountDrawer.title') }), []);

	return (
		<DrawerHeader onHide={close}>
			<Flex space="mll" alignItems="center">
				<Title>
					{`${lang.title} `}
					{client.hasAccess ? <Link onClick={() => goToAccount(client)}>{client.name}</Link> : client.name}
				</Title>
			</Flex>
		</DrawerHeader>
	);
};

export default Header;
