import PropTypes from 'prop-types';
import React from 'react';
import { Text, Button, Icon, Block, Row, Column, OutsideClick, Tooltip } from '@upsales/components';
import './InlineAction.scss';
import BemClass from '@upsales/components/Utils/bemClass';

class InlineAction extends React.Component {
	constructor(props) {
		super(props);
		const t = Tools.$translate;

		this.lang = {
			discardChanges: t('default.inlineAction.discard'),
			saveChanges: t('default.inlineAction.save'),
			confirmTitle: t('default.inlineAction.title')
		};
	}

	handleClickOutside = () => {
		this.props.toggleInlineAction('none');
	};

	render() {
		const { showTop, showLeft, showRight, saveDisabled, saveDisabledTooltip } = this.props;
		const classes = new BemClass('InlineAction');
		return (
			<OutsideClick targetClass="InlineAction__wrapper" outsideClick={() => this.handleClickOutside()}>
				<div
					className={classes
						.elem('wrapper')
						.mod({ top: showTop, left: showLeft, right: showRight, bottom: !showTop })
						.b()}
				>
					{!showTop ? (
						<Block space="pll">
							<Row>
								<Column>
									<Text size={'xl'}>{this.lang.confirmTitle}</Text>
								</Column>
							</Row>
						</Block>
					) : null}
					<Block className={classes.elem('btn-wrap').b()}>
						<Row>
							<Column>
								<Button type="link" color="red" onClick={() => this.props.onReject()}>
									<Icon name="trash" /> {this.lang.discardChanges}
								</Button>
							</Column>
							<Column>
								<Tooltip disabled={!saveDisabled || !saveDisabledTooltip} title={saveDisabledTooltip}>
									<Button disabled={saveDisabled} onClick={() => this.props.onConfirm()}>
										<Icon name="check" /> {this.lang.saveChanges}
									</Button>
								</Tooltip>
							</Column>
						</Row>
					</Block>
					{showTop ? (
						<Block space="pll">
							<Row>
								<Column>
									<Text size={'xl'}>{this.lang.confirmTitle}</Text>
								</Column>
							</Row>
						</Block>
					) : null}
				</div>
			</OutsideClick>
		);
	}
}

InlineAction.defaultProps = {
	showTop: false,
	showRight: true,
	showLeft: false
};

InlineAction.propTypes = {
	toggleInlineAction: PropTypes.func.isRequired,
	onReject: PropTypes.func.isRequired,
	onConfirm: PropTypes.func.isRequired,
	showTop: PropTypes.bool,
	showRight: PropTypes.bool,
	showLeft: PropTypes.bool,
	saveDisabled: PropTypes.bool,
	saveDisabledTooltip: PropTypes.string
};

export default InlineAction;
