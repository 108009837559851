import FunnelWidget from './FunnelWidget';
import FunnelTableWidget from './FunnelTableWidget';
import FunnelColumnChartWidget from './FunnelColumnChartWidget';

const widgetMap = {
	funnel: FunnelWidget,
	table: FunnelTableWidget,
	columnChart: FunnelColumnChartWidget
} as const;

function FunnelWidgetWrap<T extends keyof typeof widgetMap>(displayType: keyof typeof widgetMap): typeof widgetMap[T];
function FunnelWidgetWrap(displayType: string): null;
function FunnelWidgetWrap<T extends keyof typeof widgetMap>(displayType: T | string) {
	if (!(displayType in widgetMap)) {
		return null;
	}
	const C = widgetMap[displayType as T];
	return C;
}

export default FunnelWidgetWrap;
