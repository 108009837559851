import Attribute, { Type } from './Attribute';

const EventAttribute = {
	id: Attribute({
		title: 'default.id',
		field: 'id',
		type: Type.Number
	}),
	clientId: Attribute({
		title: 'default.account',
		field: 'client.id',
		type: Type.Number
	}),
	contactId: Attribute({
		title: 'default.account',
		field: 'contacts.id',
		type: Type.Number
	}),
	date: Attribute({
		title: 'default.date',
		field: 'date',
		selectableColumn: true,
		sortable: 'date',
		type: Type.Date
	}),
	score: Attribute({
		title: 'default.score',
		field: 'score',
		sortable: 'score',
		selectableColumn: true,
		type: Type.Number
	}),
	entityType: Attribute({
		title: 'default.type',
		field: 'entityType',
		type: Type.String
	}),
	subType: Attribute({
		title: 'event.subType',
		field: 'subType',
		type: Type.String
	}),
	entityId: Attribute({
		title: 'event.entityId',
		field: 'entityId',
		type: Type.Number
	}),
	opportunityId: Attribute({
		title: 'event.opportunityId',
		field: 'opportunityId',
		type: Type.Number
	}),
	opportunity: Attribute({
		title: 'event.opportunity',
		field: 'opportunity',
		type: Type.Number,
		attr: {
			id: Attribute({
				field: 'opportunity.id',
				type: Type.Number
			}),
			notes: Attribute({
				field: 'opportunity.notes',
				type: Type.String
			})
		}
	}),
	groupMailId: Attribute({
		title: 'default.groupMailId',
		field: 'mail.groupMailId',
		type: Type.Number
	}),
	client: Attribute({
		type: Type.Object,
		title: 'default.account',
		field: 'client',
		displayAttr: 'name',
		groupable: false,
		link: 'accounts',
		//sortable: 'client.name',
		selectableColumn: true,
		parent: 'client',
		attr: {
			id: Attribute({
				field: 'client.id',
				type: Type.Number
			}),
			name: Attribute({
				field: 'client.name',
				type: Type.String
			})
		}
	}),
	users: Attribute({
		type: Type.Array,
		title: 'default.user',
		field: 'users',
		displayAttr: 'name',
		selectableColumn: true,
		parent: 'users',
		attr: {
			id: Attribute({
				field: 'user.id',
				type: Type.Number
			}),
			name: Attribute({
				field: 'user.name',
				type: Type.String
			})
		}
	}),
	form: Attribute({
		type: Type.Object,
		title: 'default.form',
		field: 'form',
		attr: {
			id: Attribute({
				field: 'form.id',
				type: Type.Number
			}),
			name: Attribute({
				field: 'form.name',
				type: Type.String
			}),
			title: Attribute({
				field: 'form.title',
				type: Type.String
			})
		}
	}),
	visit: Attribute({
		type: Type.Object,
		title: 'default.visit',
		field: 'visit',
		attr: {
			id: Attribute({
				field: 'visit.id',
				type: Type.Number
			}),
			leadSource: Attribute({
				field: 'visit.leadSource',
				type: Type.String
			}),
			referer: Attribute({
				field: 'visit.referer',
				type: Type.String
			})
		}
	}),
	contacts: Attribute({
		type: Type.Array,
		title: 'default.contacts',
		field: 'contacts',
		attr: {
			id: Attribute({
				field: 'contact.id',
				type: Type.Number
			}),
			name: Attribute({
				field: 'contact.name',
				type: Type.String
			})
		}
	}),
	mail: Attribute({
		type: Type.Object,
		title: 'default.mail',
		field: 'mail',
		displayAttr: 'mail',
		parent: 'mail',
		attr: {
			id: Attribute({
				field: 'mail.id',
				type: Type.Number
			}),
			groupMailId: Attribute({
				field: 'mail.groupMailId',
				type: Type.Number
			})
		}
	}),
	feed: Attribute({
		title: 'feed',
		field: 'feed',
		type: Type.String,
		groupable: false,
		selectableColumn: false,
		sortable: false
		// inputType: 'text',
	}),
	feedContact: Attribute({
		title: 'feedContact',
		field: 'feedContact',
		type: Type.String,
		groupable: false,
		selectableColumn: false,
		sortable: false
		// inputType: 'text',
	}),
	value: Attribute({
		title: 'default.value',
		field: 'value',
		type: Type.String
	}),
	comment: Attribute({
		title: 'default.comment',
		field: 'comment',
		type: Type.Object,
		attr: {
			id: Attribute({
				field: 'comment.id',
				type: Type.Number
			}),
			description: Attribute({
				field: 'comment.description',
				type: Type.String
			})
		}
	})
};

export default EventAttribute;
