import React, { useMemo } from 'react';
import { Block, TimeInput, Row } from '@upsales/components';
import { FilterConfig } from 'App/babel/filterConfigs/FilterConfig';
import T from 'Components/Helpers/translate';
import moment from 'moment';
import { ListViewFilter } from 'App/resources/AllIWant';

export type TimeFilter = Omit<ListViewFilter, 'value'> & { value: { start: null | string; end: null | string } };
type TimeFilterProps = {
	filter: TimeFilter;
	filterConfig: FilterConfig;
	valueChanged: (value: TimeFilter['value']) => void;
};

const get24HourTime = (val: string | null) => (val ? moment(val, ['h:mm A']).format('HH:mm') : null);
const getLocalTime = (val?: string | null) => (val ? moment(val, ['h:mm A']).format('LT') : null);

function TimeFilterComponent({ filter, valueChanged }: TimeFilterProps) {
	const lang = useMemo(
		() => ({
			from: T('default.from'),
			to: T('default.to')
		}),
		[]
	);

	return (
		<Block space="plm ptm prm pbm">
			<Row>
				<TimeInput
					onChange={({ target: { value } }) => valueChanged({ ...filter.value, start: get24HourTime(value) })}
					value={getLocalTime(filter.value?.start)}
					placeholder={lang.from}
				/>
				<TimeInput
					onChange={({ target: { value } }) => valueChanged({ ...filter.value, end: get24HourTime(value) })}
					value={getLocalTime(filter.value?.end)}
					placeholder={lang.to}
				/>
			</Row>
		</Block>
	);
}

export default TimeFilterComponent;
