import Resource from './Resource';
import Attributes from 'Attributes/BrandAttributes';

class Brand extends Resource {
	constructor() {
		super('brands', Attributes);

		this.eventName = 'brand';

		this.notifications = {
			save: () => ({
				title: 'default.saved',
				body: 'saved.brand',
				style: 'success',
				icon: 'check',
				type: 'body'
			}),
			saveError: ({ error }) => ({
				title: 'default.error',
				body: error.translated || 'saveError.brand',
				style: 'error',
				icon: 'times',
				type: 'body'
			}),
			delete: () => ({
				title: 'default.deleted',
				body: 'deleted.brand',
				style: 'success',
				icon: 'check',
				type: 'body'
			}),
			deleteError: () => ({
				title: 'default.error',
				body: 'deleteError.brand',
				style: 'error',
				icon: 'times',
				type: 'body'
			})
		};
	}

	new(overrides = {}) {
		return Object.assign(
			{
				id: null,
				name: '',
				logo: null,
				active: true,
				roles: []
			},
			overrides
		);
	}
}

const resource = new Brand();

window.Tools.Brand = resource;

export default resource;
