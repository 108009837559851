export const newFilter = () => ({
	inactive: true,
	value: {
		missingAccountManager: false,
		User: { value: [] }
	}
});

const buildFilter = function (field, rb, value, ct) {
	rb.addFilter(field, ct, value);
};

export default {
	generate: function () {
		return {
			inactive: false,
			value: {
				missingAccountManager: false,
				User: { value: [], comparisonType: 'Equals' }
			}
		};
	},
	getText: function () {
		return 0;
	},
	isInactive: function (filter) {
		return filter.inactive;
	},
	toUrl: function (filter) {
		var f = { v: {} };
		var val = filter.value;

		f.v.missing = val.missingAccountManager;
		if (val.User.value) {
			f.v.u = val.User.value;
			f.v.uCt = val.User.comparisonType;
		}

		return f;
	},
	fromUrl: function (rawFilter) {
		var filter = this.generate();
		filter.inactive = false;
		var val = rawFilter.v;

		filter.value.missingAccountManager = val.missing;

		if (val.u) {
			filter.value.User = { value: val.u };
			if (val.uCt) {
				filter.value.User.comparisonType = val.uCt;
			}
		}

		return filter;
	},
	build: function () {
		function beforeBuildFilter(value) {
			const selfIndex = value.indexOf('self');

			if (selfIndex > -1) {
				value[selfIndex] = Tools.AppService.getSelf().id;
			}
		}

		return function (filter, rb, getField) {
			var val = filter.value;

			if (filter.inactive) {
				return;
			}

			var field = getField('user.id');

			if (val.missingAccountManager) {
				// Build or filter for missingAccountManager

				if (val.User.value.length) {
					beforeBuildFilter(val.User.value);
					var orGroup = rb.orBuilder();
					orGroup.next();
					buildFilter(field, orGroup, [], rb.comparisonTypes.Equals);
					orGroup.next();
					buildFilter(field, orGroup, val.User.value, rb.comparisonTypes[val.User.comparisonType]);
					orGroup.done();
				} else {
					buildFilter(field, rb, [], rb.comparisonTypes.Equals);
				}
			} else {
				if (val.User.value.length) {
					beforeBuildFilter(val.User.value);
					buildFilter(field, rb, val.User.value, rb.comparisonTypes[val.User.comparisonType]);
				}
			}
		};
	}
};
