import RequestBuilder from 'Resources/RequestBuilder';
import type { MultiSelect } from 'App/components/ListView/ListViewRenderHelpers';

export default function updateAppointments(rb: RequestBuilder, entity: string, multiselect: MultiSelect) {
	const opts = {
		customerId: Tools.AppService.getCustomerId(),
		filters: rb,
		hideDate: false,
		entity,
		multiselect
	};
	return Tools.$upModal.open('updateAppointmentMulti', opts);
}
