import Resource from './Resource';
import SalesCoachType, { MappedSalesCoach, Fields } from 'App/resources/Model/SalesCoach';

type StageObj = {
	stageId: number;
	active?: boolean;
	required?: boolean;
	name: string;
};

const mapStages = (stages: Array<StageObj>) => {
	const mappedStages = {} as MappedSalesCoach['budget']['stages'];
	stages.forEach((stage: StageObj) => {
		mappedStages[stage.stageId] = {
			stageId: stage.stageId,
			name: stage.name,
			required: !!stage.required,
			active: stage.active
		};
	});
	return mappedStages;
};

export type MappedSalesCoachExtended = Omit<
	MappedSalesCoach,
	'id' | 'nextStep' | 'decisionMakers' | 'decisionMakersPro'
> & {
	id: number;
	isNameUnique: boolean;
	nextStep: Omit<MappedSalesCoach['nextStep'], 'entireSalesProcess'>;
	decisionMakers: Omit<MappedSalesCoach['decisionMakers'], 'titleCategories'> & {
		titleCategories: { titleId: number }[];
	};
	decisionMakersPro: Omit<MappedSalesCoach['decisionMakersPro'], 'fields'> & {
		fields: { rowId: number; activeStages: { stageId: number }[] }[];
	};
};

const map = (unmapped: SalesCoachType): MappedSalesCoachExtended => ({
	id: unmapped.id,
	name: unmapped.name,
	users: unmapped.users,
	roles: unmapped.roles,
	active: unmapped.active,
	isNameUnique: true,
	assignmentType: unmapped.users?.length > 0 ? 'users' : 'roles',
	budget: {
		active: unmapped.budgetActive,
		entireSalesProcess: !unmapped.budgetStages.length,
		stages: mapStages(unmapped.budgetStages)
	},
	nextStep: {
		active: unmapped.nextStepActive,
		onlyAppointments: unmapped.nextStepOnlyAppointments
	},
	solution: {
		active: unmapped.solutionActive,
		entireSalesProcess: !unmapped.solutionStages.length,
		stages: mapStages(unmapped.solutionStages)
	},
	timeframe: {
		active: unmapped.timeframeActive,
		entireSalesProcess: !unmapped.timeframeStages.length,
		stages: mapStages(unmapped.timeframeStages)
	},
	decisionMakers: {
		active: unmapped.decisionMakersActive,
		entireSalesProcess: !unmapped.decisionMakersStages.length,
		stages: mapStages(unmapped.decisionMakersStages),
		titleCategories: unmapped.decisionMakersTitles
	},
	checklist: unmapped.checklist,
	decisionMakersPro: {
		typeOfDecisionMakers: unmapped.decisionMakersType,
		fields: unmapped.decisionMakers
	}
});
class SalesCoach extends Resource {
	constructor() {
		super('salesCoaches', null, { notificationTitle: 'admin.salesProcess' });
	}

	find(filter: object): Promise<{ data: ReturnType<typeof map>[] }> {
		return super
			.find(filter)
			.then(obj => ({ ...obj, data: obj.data.map((unmapped: SalesCoachType) => map(unmapped)) }));
	}

	get(id: number): any {
		return super.get(id).then(obj => ({ ...obj, data: map(obj.data) }));
	}

	save({
		name,
		active,
		users,
		roles,
		assignmentType,
		budget,
		nextStep,
		solution,
		decisionMakers,
		timeframe,
		id,
		checklist,
		decisionMakersPro
	}: MappedSalesCoach) {
		const mapping = {
			id,
			name,
			active,
			users: users,
			roles: roles,
			assignmentType: assignmentType,
			budgetActive: budget.active,
			budgetStages: Object.entries(budget.stages).map(([stageId, stage]) => ({
				stageId: stageId,
				name: stage.name,
				required: stage.required,
				active: stage.active
			})),
			nextStepActive: nextStep.active,
			nextStepOnlyAppointments: nextStep.onlyAppointments,
			solutionActive: solution.active,
			solutionStages: Object.entries(solution.stages).map(([stageId, stage]) => ({
				stageId: stageId,
				name: stage.name,
				required: stage.required,
				active: stage.active
			})),
			timeframeActive: timeframe.active,
			timeframeStages: Object.entries(timeframe.stages).map(([stageId, stage]) => ({
				stageId: stageId,
				name: stage.name,
				required: stage.required,
				active: stage.active
			})),
			decisionMakersActive: decisionMakers.active,
			decisionMakersTitles: decisionMakers.titleCategories.map((title: any) => ({
				titleId: title.titleId
			})),
			decisionMakersStages: Object.entries(decisionMakers.stages).map(([stageId, stage]) => ({
				stageId: stageId,
				name: stage.name,
				required: stage.required,
				active: stage.active
			})),
			decisionMakersType: decisionMakersPro.typeOfDecisionMakers,
			checklist
		};
		return super.save(mapping);
	}

	saveDecisionMakers(id: number, decisionMakers: Fields, type: string, name: string) {
		return super.save({ id, decisionMakers, decisionMakersType: type, name });
	}
}
export default new SalesCoach();
