import Resource from './Resource';
import RequestBuilder, { comparisonTypes } from './RequestBuilder';
import Attributes from 'Attributes/SocialEventAttributes';
import parseSocialEvent from './parsers/socialEvent';
import mapSocialEvent from './mappers/socialEvent';

class SocialEvents extends Resource {
	constructor() {
		super('socialEvents', Attributes);

		this.eventName = 'socialEvent';
		this.dateFields = ['date', 'endDate'];

		this.parse = parseSocialEvent;
		this.map = mapSocialEvent;
	}

	new() {
		return {
			name: '',
			versionFlag: 1,
			internalName: '',
			description: '',
			location: '',
			venue: '',
			attendingScore: 0,
			checkInScore: 0,
			date: null,
			endDate: null,
			time: '',
			endTime: '',
			draft: true,
			timezone: window.userTimezone,
			domain: '',
			urlName: '',
			tickets: null,
			eventFullMsg: null,
			waitingList: true,
			isWebinar: false,
			webinarId: null,
			webinarLink: null,
			webinarIntegrationId: null,
			userEditable: true,
			isOnDemand: false
		};
	}

	findPages(eventId, { offset, limit }, type) {
		const rb = new RequestBuilder();
		rb.limit = limit;
		rb.offset = offset;

		return this._getRequest(`${eventId}/pages`, { methodName: 'findPages', params: { ...rb.build(), type } }).then(
			res => {
				return res.data;
			}
		);
	}

	savePage(eventId, pageId, type) {
		return this._postRequest(`${eventId}/pages`, { pageId, type }, { methodName: 'savePage' }).then(res => {
			return res.data;
		});
	}

	deletePage(eventId, pageId) {
		return this._deleteRequest(`${eventId}/pages/${pageId}`, { methodName: 'deletePage' }).then(res => res.data);
	}

	findTemplateEmail(templateId) {
		const rb = new RequestBuilder();
		rb.addFilter({ field: 'templateId' }, comparisonTypes.Equals, templateId);
		rb.limit = 1;

		return this._getRequest(`emails/search`, { methodName: 'findTemplateEmail', params: rb.build() }).then(res => {
			return res.data?.data?.length ? res.data.data[0] : null;
		});
	}

	findEmails(eventId, { offset, limit }) {
		const rb = new RequestBuilder();
		rb.limit = limit;
		rb.offset = offset;

		return this._getRequest(`${eventId}/emails`, { methodName: 'findEmails', params: rb.build() }).then(res => {
			return res.data;
		});
	}

	saveEmail(eventId, templateId, type, isDefaultTemplate, mailCampaignCategoryId, emailSendTime) {
		return this._postRequest(
			`${eventId}/emails`,
			{ templateId, type, isDefaultTemplate, mailCampaignCategoryId, emailSendTime },
			{ methodName: 'saveEmail' }
		).then(res => {
			return res.data;
		});
	}

	updateEmail(id, eventId, templateId, type, isDefaultTemplate, mailCampaignCategoryId, emailSendTime) {
		return this._putRequest(
			`${eventId}/emails/${id}`,
			{ templateId, type, isDefaultTemplate, mailCampaignCategoryId, emailSendTime },
			{ methodName: 'updateEmail' }
		).then(res => {
			return res.data;
		});
	}

	deleteEmail(eventId, emailId) {
		const notifcationConfig = {
			title: 'default.email',
			icon: 'trash'
		};
		return this._deleteRequest(`${eventId}/emails/${emailId}`, { methodName: 'deleteEmail' })
			.then(res => {
				Tools.NotificationService.addNotification({
					body: 'default.wasDeleted',
					style: 'success',
					type: 'body',
					...notifcationConfig
				});

				return res.data;
			})
			.catch(e => {
				Tools.NotificationService.addNotification({
					body: 'default.unableToDelete',
					style: 'error',
					type: 'body',
					...notifcationConfig
				});

				throw e;
			});
	}

	getStats(eventId) {
		return this._getRequest(`${eventId}/stats`, { methodName: 'getStats' }).then(res => {
			return res.data;
		});
	}

	get(id, opts = {}) {
		opts.params = opts.params || {};
		opts.params.includeHash = true;
		opts.params.includePageUrl = true;
		return super.get(id, opts);
	}

	getStatsMultiple(ids) {
		return this._getRequest(null, {
			methodName: 'getStatsMultiple',
			params: { ids: ids.join(',') },
			_url: 'socialEventStats'
		}).then(res => {
			return res.data;
		});
	}

	getDefaultTemplate(type) {
		return this._getRequest(`defaultTemplates/${type}`, { methodName: 'getDefaultTemplate' }).then(res => {
			return res.data;
		});
	}

	removeDefaultTemplate(id, type) {
		return this._deleteRequest(`defaultTemplates/${type}/${id}`, { methodName: 'removeDefaultTemplate' }).then(
			res => {
				return res.data;
			}
		);
	}
}

const resource = new SocialEvents();

window.Tools.SocialEvents = resource;

export default resource;
