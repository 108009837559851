import React from 'react';
import { Item } from 'Resources/Signals';
import CreateTaskButton from 'App/components/CreateTaskButton';

type SignalsAddMenuProps = {
	item: Item;
};

const SignalsAddMenu: React.FC<SignalsAddMenuProps> = ({ item }) => {
	return <CreateTaskButton createArgs={{ client: { id: item.clientId, name: item.name } }} />;
};

export default SignalsAddMenu;
