import Tracker from './Tracker';
import debounce from 'Components/Helpers/Debounce';

export const trackerValues: SubscriptionTrackerValues = {
	details: 0,
	summary: 0,
	history: 0,
	diffOrder: 0,
	editSummary: 0,
	pageSwitches: 0,
	showGraph: 0,
	showGraphFromInfoBox: 0,
	setPeriodChildren: 0,
	hideGraph: 0,
	switchToAdvance: 0,
	switchToDefault: 0,
	split: 0,
	removeSplit: 0,
	removeChild: 0,
	periodChange: 0,
	periodChildChange: 0,
	motherStartDate: 0,
	motherOrderStartDate: 0,
	// Period
	periodLength: 0,
	startDate: 0,
	orderStartDate: 0,
	orderInterval: 0,
	daysInAdvance: 0,
	noticePeriod: 0,
	switchToRenewal: 0,
	switchToTermination: 0,
	terminationDate: 0,
	// Order row
	addOrderRow: 0,
	removeOrderRow: 0,
	duplicateOrderRow: 0,
	editOrderRow: 0,
	editOrderRowProductBundle: 0
};

type pageNames = {
	details: number;
	summary: number;
	history: number;
	diffOrder: number;
	editSummary: number;
};

export type SubscriptionTrackerValues = pageNames & {
	/* All types below are also tracking events, used in incrementValueAndTrack(eventName) */
	showGraph: number;
	hideGraph: number;
	showGraphFromInfoBox: number;
	switchToAdvance: number;
	switchToDefault: number;
	pageSwitches: number;
	split: number;
	removeSplit: number;
	removeChild: number;
	periodChange: number;
	periodChildChange: number;
	setPeriodChildren: number;
	motherStartDate: number;
	motherOrderStartDate: number;
	// Period
	periodLength: number;
	startDate: number;
	orderStartDate: number;
	orderInterval: number;
	daysInAdvance: number;
	noticePeriod: number;
	switchToRenewal: number;
	switchToTermination: number;
	terminationDate: number;
	// Order row
	addOrderRow: number;
	removeOrderRow: number;
	duplicateOrderRow: number;
	editOrderRow: number;
	editOrderRowProductBundle: number;
};

// These tracking events are only used max once every creation of a subscription
const TRACKING_EVENTS = {
	CREATE_SUBSCRIPTION: 'createSubscription',
	USE_SUBSCRIPTION: 'useSubscription',
	CHANGE_TO_OLD: 'changeToOld'
};

export class NewSubscriptionTracker extends Tracker<SubscriptionTrackerValues> {
	debounceValues: { [keyName: string]: () => void };

	constructor() {
		super('newSubscriptionModal', TRACKING_EVENTS, true, { ...trackerValues });
		this.debounceValues = {};
		this.startTrackTime('betaBanner');
	}

	incrementRowValueDebounce(keyName: string) {
		if (!this.debounceValues[keyName]) {
			this.debounceValues[keyName] = debounce(() => {
				this.incrementValueAndTrack('editOrderRow');
			}, 2000);
		}
		this.debounceValues[keyName]();
	}

	incrementValueAndTrack(name: keyof SubscriptionTrackerValues, data = {}) {
		this.incrementValue(name);
		this.track(name, data);
	}

	/* Helper functions */
	trackPreviewGraph(showGraph: boolean) {
		if (showGraph) {
			this.incrementValueAndTrack('showGraph');
		} else {
			let closeTime = undefined;
			if (this.getTrackedTime('autoOpenGraph')) {
				closeTime = this.endTrackTime('autoOpenGraph');
			}
			this.incrementValueAndTrack('hideGraph', { closeTime });
		}
	}

	trackPageSwitch(page: keyof pageNames, previousPage: keyof pageNames) {
		this.startTrackTime(page);
		this.incrementValue('pageSwitches');

		if (this.getTrackedTime(previousPage)) {
			const time = this.endTrackTime(previousPage);
			this.incrementValue(previousPage, time);
		}
	}

	trackDataWithValues(save: boolean, isAdvanced: boolean, hasSplit: boolean, step: keyof pageNames, update: boolean) {
		const time = this.endTrackTime(step);
		this.incrementValue(step, time);

		const totalTime =
			this.valuesToTrack.details +
			this.valuesToTrack.summary +
			this.valuesToTrack.history +
			this.valuesToTrack.diffOrder +
			this.valuesToTrack.editSummary;
		this.trackWithValues(TRACKING_EVENTS.USE_SUBSCRIPTION, {
			save,
			update,
			isAdvanced,
			hasSplit,
			totalTime
		});
	}

	trackBetaBanner(feedback: string) {
		const { CHANGE_TO_OLD } = TRACKING_EVENTS;
		const time = this.endTrackTime('betaBanner');
		this.track(CHANGE_TO_OLD, {
			feedback: feedback.length > 0 ? feedback : undefined,
			timeBeforeChange: time
		});
	}
}
export default new NewSubscriptionTracker();
