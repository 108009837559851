import PropTypes from 'prop-types';
import React from 'react';

const proptypes = {
	title: PropTypes.string.isRequired,
	selectField: PropTypes.func.isRequired,
	selectAllFields: PropTypes.func.isRequired,
	selectedFields: PropTypes.array.isRequired,
	obligatoryFields: PropTypes.array.isRequired
};

class fieldPageSelected extends React.Component {
	renderNoFieldsSelected() {
		return (
			<div className="briefcase-holder">
				<div className="briefcase" style={{ backgroundImage: 'url(img/empty-briefcase.svg)' }} />
				<h5 className="no-fields-title">{Tools.$translate('admin.anonymization.noSelectedFields.title')}</h5>
				<p className="no-fields-copy">{Tools.$translate('admin.anonymization.noSelectedFields.copy')}</p>
			</div>
		);
	}

	renderRemoveFieldIcon(field) {
		if (field.parent === 'contact') {
			return (
				<div className="pull-right">
					{ReactTemplates.TOOLS.withTooltip(
						<i className="fa fa-lock" style={{ color: '#999' }} />,
						Tools.$translate('form.required'),
						{ placement: 'top', key: `obligatoryField-anonymization-${field.parent}-${field.field}` }
					)}
				</div>
			);
		}

		return (
			<i
				className="fa fa-times"
				onClick={() => {
					this.props.selectField(field, true);
				}}
			/>
		);
	}

	render() {
		const { selectedFields, obligatoryFields } = this.props;
		const _selectedFields = selectedFields
			.flat()
			.filter(field => {
				if (field.field === 'titleCategory.value') {
					return false;
				} else {
					return !_.some(obligatoryFields, field);
				}
			})
			.concat(obligatoryFields);
		const newSelectedField = _.groupBy(_selectedFields, 'parent');
		const hasFields = _selectedFields.length > 0;

		return (
			<div className="fieldPageSelected--holder topRow">
				<h3 className="regular-table-title">
					{this.props.title}
					<span
						className={selectedFields.length > 0 ? 'deselect-all' : 'deselect-all grey'}
						onClick={() => {
							this.props.selectAllFields('remove');
						}}
					>
						{Tools.$translate('default.remove') + ' ' + Tools.$translate('default.allFields').toLowerCase()}
					</span>
				</h3>
				<div className="fieldPageSelected-selected row-holder">
					{hasFields
						? _.map(newSelectedField, (fields, key) => {
								const retArray = [];
								retArray.push(
									<h3 key={key}>
										{fields[0].isUdo ? key : Tools.$translate(`filters.columns.${key}`)}
									</h3>
								);
								retArray.push(
									<ul key={`${key}-ul-fields`} className="fieldPageSelected--fieldsList">
										{fields.map(field => {
											return (
												<li key={`${field.parent}_${field.field}_${field.name}`}>
													{Tools.$translate(field.name)}
													{this.renderRemoveFieldIcon(field)}
												</li>
											);
										})}
									</ul>
								);

								return retArray;
						  })
						: this.renderNoFieldsSelected()}
				</div>
			</div>
		);
	}
}

fieldPageSelected.propTypes = proptypes;
export default fieldPageSelected;
