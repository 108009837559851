import Resource from './Resource';

class FieldTranslation extends Resource {
	constructor() {
		super('fieldTranslations', null, { notificationTitle: 'default.translation' });
	}

	new(type) {
		return {
			defaultValue: '',
			type: type,
			language: '',
			value: ''
		};
	}

	setType(type) {
		this.type = type;
	}

	save(data, opts = {}) {
		return super.save(data, { ...opts, type: this.type });
	}

	delete(id, opts = {}) {
		return super.delete(id, { ...opts, type: this.type });
	}

	deleteTag(tagId, opts = {}) {
		const path = tagId + '?deleteTagId=' + tagId;
		return super.delete(path, { ...opts });
	}
}

const resource = new FieldTranslation();

export default resource;
