import React, { Component } from 'react';
import _ from 'lodash';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Card, CardHeader, Table, TableHeader, TableRow, TableColumn, Icon, Tooltip, Link } from '@upsales/components';
import { fetchContacts } from 'Store/actions/ActivityOutcomesActions';
import Button from 'Components/Inputs/Button';
import BemClass from '@upsales/components/Utils/bemClass';

import './index.scss';

const mapStateToProps = (state, props) => {
	const newProps = {};
	if (props.contacts) {
		newProps.topContacts = _.sortBy(props.contacts, 'score').reverse().splice(0, 5);
	} else {
		newProps.topContacts = state.ActivityOutcomes.topContacts;
	}
	return newProps;
};

const mapDispatchToProps = {
	fetchContacts
};

export class TopContacts extends Component {
	constructor(props) {
		super(props);

		const t = Tools.$translate;
		this.lang = {
			title: t('activity.outcome.topContacts'),
			waysOfContacting: t('activity.outcome.waysOfContacting'),
			emailLinkTooltip: t('activity.outcome.emailLinkTooltip'),
			phoneLinkTooltip: t('activity.outcome.phoneLinkTooltip'),
			name: t('default.name'),
			score: t('default.scoreTotal'),
			add: t('default.add'),
			contactMissingEmail: t('activity.outcome.contactMissingEmail'),
			contactMissingMobile: t('activity.outcome.contactMissingMobile'),
			contactMissingPhone: t('activity.outcome.contactMissingPhone')
		};

		this.numberFormat = Tools.$filter('numberFormat');
	}

	componentDidMount() {
		const { client, contacts } = this.props;
		if (!contacts) {
			this.props.fetchContacts(client.id);
		}
	}

	componentDidUpdate(prevProps) {
		const { client, contacts } = this.props;

		if (prevProps.client.id !== client.id && !contacts) {
			this.props.fetchContacts(client.id);
		}
	}

	getActionButtons(contact) {
		return [
			{
				icon: 'phone',
				link: ReactTemplates.TOOLS.upVoice(contact, 'contact', contact.phone),
				disabled: !contact.phone,
				onClick: () => {
					this.props.setContact(contact);
				},
				type: 'phone',
				tooltip: this.lang.phoneLinkTooltip,
				tooltipForDisabled: this.lang.contactMissingPhone
			},
			{
				icon: 'mobile',
				link: ReactTemplates.TOOLS.upVoice(contact, 'contact', contact.cellPhone),
				disabled: !contact.cellPhone,
				onClick: () => {
					this.props.setContact(contact);
				},
				type: 'cellPhone',
				tooltip: this.lang.phoneLinkTooltip,
				tooltipForDisabled: this.lang.contactMissingMobile
			},
			{
				icon: 'envelope',
				disabled: !contact.email,
				onClick: () => {
					this.props.setContact(contact);
					this.props.openMail(contact);
				},
				type: 'email',
				tooltip: this.lang.emailLinkTooltip,
				tooltipForDisabled: this.lang.contactMissingEmail
			},
			{
				text: this.lang.add,
				onClick: () => {
					this.props.setContact(contact);
				},
				type: 'add'
			}
		];
	}

	renderActionButton(button) {
		const { type, disabled, tooltipForDisabled, onClick, link } = button;
		const tooltip = disabled && tooltipForDisabled ? tooltipForDisabled : button.tooltip;
		let element;
		if (link) {
			element = (
				<Link
					key={type}
					className="up-btn btn-bright-blue btn-link"
					color="bright-blue"
					type="link"
					disabled={disabled}
					target={link.target}
					href={link.href}
					onClick={event => {
						onClick();
						link.onClick(event);
					}}
				>
					{button.icon ? (
						<Icon name={button.icon} color={button.disabled ? 'grey-6' : 'bright-blue'} />
					) : null}
					{button.text}
				</Link>
			);
		} else {
			element = (
				<Button key={type} color="bright-blue" type="link" disabled={disabled} onClick={onClick}>
					{button.icon ? (
						<Icon name={button.icon} color={button.disabled ? 'grey-6' : 'bright-blue'} />
					) : null}
					{button.text}
				</Button>
			);
		}

		if (tooltip) {
			element = (
				<Tooltip key={type} title={tooltip} position="left">
					{element}
				</Tooltip>
			);
		}

		return element;
	}

	render() {
		const { topContacts, client } = this.props;
		const classNames = new BemClass('TopContacts');

		if (!topContacts || topContacts.length === 0) return null;

		const { name } = client || {};
		const title = `${this.lang.title} ${name}`;
		const tableColumns = [
			{ title: this.lang.name },
			{ title: this.lang.score },
			{ title: this.lang.waysOfContacting }
		];
		const tableRows = (topContacts || []).map(contact => (
			<TableRow key={contact.id}>
				<TableColumn
					title={contact.name}
					subtitle={contact.title || (contact.titleCategory ? contact.titleCategory.value : null)}
				/>
				<TableColumn title={this.numberFormat(contact.score)} />
				<TableColumn align="right">{this.getActionButtons(contact).map(this.renderActionButton)}</TableColumn>
			</TableRow>
		));

		return (
			<Card className={classNames.b()}>
				<CardHeader title={title} />
				<Table>
					<TableHeader columns={tableColumns} />
					{tableRows}
				</Table>
			</Card>
		);
	}
}

TopContacts.propTypes = {
	client: PropTypes.shape({
		id: PropTypes.number,
		name: PropTypes.string
	}),
	topContacts: PropTypes.arrayOf(
		PropTypes.shape({
			id: PropTypes.number,
			name: PropTypes.string,
			email: PropTypes.string,
			phone: PropTypes.string
		})
	),
	fetchContacts: PropTypes.func,
	setContact: PropTypes.func,
	openMail: PropTypes.func,
	contacts: PropTypes.oneOfType([PropTypes.array.isRequired, PropTypes.oneOf([null])])
};

TopContacts.defaultProps = {
	fetchContacts: () => {},
	setContact: () => {},
	openMail: () => {}
};

export default connect(mapStateToProps, mapDispatchToProps)(TopContacts);
