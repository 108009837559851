import Tracker from './Tracker';

const reportCenterTracker = new Tracker('reportcenter', {
	OLD_REPORTCENTER: 'oldReportCenter',
	NEW_REPORTCENTER: 'newReportCenter',
	SALES_STANDARD_DASHBOARD: 'salesStandardDashboard',
	CM_STANDARD_DASHBOARD: 'cmStandardDashboard',
	PIPELINE_STANDARD_DASHBOARD: 'pipelineStandardDashboard',
	ACTIVITY_STANDARD_DASHBOARD: 'activityStandardDashboard',
	APPOINTMENTS_STANDARD_DASHBOARD: 'appointmentsStandardDashboard',
	SHOW_STANDARD_DASHBOARD_FILTERS: 'showStandardDashboardFilter',
	HIDE_STANDARD_DASHBOARD_FILTERS: 'hideStandardDashboardFilter',
	ADD_WIDGET: 'addWidget',
	ADD_WIDGET_TO_DASHBOARD: 'addWidgetToDashboard',
	CREATE_DASHBOARD: 'createDashboard',
	REVENUE_RECOGNITION_DASHBOARD: 'revenueRecognitionDashboard',
	ARR_OVERVIEW_DASHBOARD: 'arrOverviewDashboard',
	MRR_OVERVIEW_DASHBOARD: 'mrrOverviewDashboard',
	ARR_VSTARGET_DASHBOARD: 'arrVsTargetDashboard',
	MRR_VSTARGET_DASHBOARD: 'mrrVsTargetDashboard',
	STANDARD_PERFORMANCE_OVERVIEW_DASHBOARD: 'standardPerformanceOverviewDashboard',
	CM_PERFORMANCE_OVERVIEW_DASHBOARD: 'cmPerformanceOverviewDashboard',
	MRR_PERFORMANCE_OVERVIEW_DASHBOARD: 'mrrPerformanceOverviewDashboard',
	ARR_PERFORMANCE_OVERVIEW_DASHBOARD: 'mrrPerformanceOverviewDashboard',
	MARKETING_STANDARD_DASHBOARD: 'marketingStandardDashboard'
});

export default reportCenterTracker;
