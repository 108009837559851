import React from 'react';
import { Headline, Text, Flex, ButtonBox, Button } from '@upsales/components';
import { PrimaryButton } from '@upsales/components/Buttons';
import T from 'Components/Helpers/translate';
import type { BundleState } from '../ProductBundleDrawer';
import { ProductTypes } from '../ProductBundleDrawer';
import BemClass from '@upsales/components/Utils/bemClass';

type Props = {
	classes: BemClass;
	bundle: BundleState;
	updateBundle: React.Dispatch<Partial<BundleState>>;
};

const ProductTypeSelect = ({ classes, bundle, updateBundle }: Props) => {
	const { productType, tmpProductType } = bundle;
	const isProductTypeChange = productType !== null;

	const handleTypeSelection = (type: ProductTypes) => {
		if (productType === null) {
			updateBundle({ productType: type });
		} else {
			updateBundle({ tmpProductType: type });
		}
	};

	const lang = {
		headline: isProductTypeChange
			? T('admin.products.changeBundleProductTypeHeadline')
			: T('admin.products.bundleProductTypeHeadline'),
		description: isProductTypeChange
			? T('admin.products.changeBundleProductTypeDesc')
			: T('admin.products.bundleProductTypeDesc'),
		confirm: T('admin.products.changeBundleProductTypeConfirm'),
		cancel: T('default.cancel'),
		oneOffProducts: T('admin.products.oneOffProducts'),
		onlyOneOffs: T('admin.products.onlyOneOffs'),
		recurringProducts: T('admin.products.recurringProducts'),
		onlyRecurring: T('admin.products.onlyRecurring')
	};

	return (
		<Flex className={classes.elem('type-select').b()} direction="column" alignItems="center">
			<Headline size="sm">{lang.headline}</Headline>
			<Text size="md" color="grey-11" align="center">
				{lang.description}
			</Text>
			<Flex gap="u5">
				<ButtonBox
					onClick={() => handleTypeSelection(ProductTypes.ONEOFF)}
					selected={tmpProductType === ProductTypes.ONEOFF}
					icon="cube"
					title={lang.oneOffProducts}
					subtitle={lang.onlyOneOffs}
				/>
				<ButtonBox
					onClick={() => handleTypeSelection(ProductTypes.RECURRING)}
					selected={tmpProductType === ProductTypes.RECURRING}
					icon="recurring-order"
					title={lang.recurringProducts}
					subtitle={lang.onlyRecurring}
				/>
			</Flex>
			{productType !== null ? (
				<>
					<PrimaryButton
						className={classes.elem('confirm-btn').b()}
						onClick={() => updateBundle({ productType: tmpProductType })}
						disabled={productType === tmpProductType}
					>
						{lang.confirm}
					</PrimaryButton>
					<Button type="link" onClick={() => updateBundle({ showProductTypeSelect: false })}>
						{lang.cancel}
					</Button>
				</>
			) : null}
		</Flex>
	);
};

export default ProductTypeSelect;
