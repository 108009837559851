import React from 'react';
import TimelineRow from '../TimelineRow';
import T from 'Components/Helpers/translate';
import { IconName, Text } from '@upsales/components';
import BemClas from '@upsales/components/Utils/bemClass';
import Event from 'App/resources/Model/Event';
import './TimelineHistoryUsers.scss';
import PropTypes from 'prop-types';
import { EVENT_TIMELINE_SUB_TYPES } from 'App/babel/enum/timeline';
// This component just takes title without the user's name and adds it to the title in bold
//
interface Props {
	icon?: IconName;
	iconColor?: string;
	iconBackground?: string;
	hasAvatar?: boolean;
	event: Event;
	title?: JSX.Element | null;
	subTitle?: JSX.Element | null;
	subSection?: JSX.Element | null;
	noIcon?: boolean;
	hideLine?: boolean;
	isExpandable?: boolean;
	children?: JSX.Element;
	expanded?: boolean;
	noCommentRow?: boolean;
	actions?: JSX.Element | null;
	someoneText?: string;
}

/** @deprecated This component is superceded by TimelineGeneric.tsx. Create or use a config from TimelineCardConfigs.tsx instead */
const TimelineHistoryUsers = ({
	icon,
	iconColor = '',
	iconBackground = '',
	hasAvatar = false,
	event,
	title,
	subTitle,
	subSection,
	noIcon = false,
	hideLine = false,
	isExpandable = false,
	children,
	expanded = false,
	noCommentRow = false,
	actions,
	someoneText = 'default.someone'
}: Props) => {
	const classes = new BemClas('TimelineHistoryUsers');
	const { users, user, entityType, mail, subType } = event;
	let titleWithUser: JSX.Element =
		user || (users?.length && users[0]) ? (
			users?.length > 1 ? (
				<React.Fragment>
					<Text bold>{`${users[0].name}  `}</Text>
					{T('and')}
					<Text className={classes.elem('title').b()} bold>
						{` ${users.length - 1}	${T('timeline.others')} `}
					</Text>
					{title}
				</React.Fragment>
			) : (
				<React.Fragment>
					<Text bold>{(users?.[0]?.name || user?.name) + ' '}</Text>
					{title}
				</React.Fragment>
			)
		) : (
			<React.Fragment>
				<Text bold>{T(someoneText) + ' '}</Text>
				{title}
			</React.Fragment>
		);
	if (entityType === 'Mail' && mail?.fromName) {
		titleWithUser = (
			<React.Fragment>
				<Text bold>
					{(subType === EVENT_TIMELINE_SUB_TYPES.RECEIVE ? event.users[0]?.name ?? '' : mail?.fromName) + ' '}
				</Text>
				{title}
			</React.Fragment>
		);
	}
	return (
		<div className={classes.b()}>
			<TimelineRow
				icon={noIcon ? undefined : icon}
				hideLine={hideLine}
				iconColor={iconColor}
				iconBackground={iconBackground}
				hasAvatar={hasAvatar}
				event={event}
				title={<div className={classes.elem('combined-title').b()}>{titleWithUser}</div>}
				subTitle={subTitle}
				subSection={subSection}
				isExpandable={isExpandable}
				children={children}
				expanded={expanded}
				noCommentRow={noCommentRow}
				actions={actions}
			/>
		</div>
	);
};
TimelineHistoryUsers.protoTypes = {
	actions: PropTypes.node
};
export default TimelineHistoryUsers;
