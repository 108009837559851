import { TableColumn, TableRow, Icon, Text, Tooltip } from '@upsales/components';
import React, { useState, useEffect } from 'react';
import Avatar from 'Components/Avatar';
import { CurrencyFormat } from 'App/babel/utils/numberFormat';
import T from 'Components/Helpers/translate';
import BemClass from '@upsales/components/Utils/bemClass';
import './RowDropDown.scss';
import { forecastingTracker } from 'Helpers/Tracker';
import OpportunityRow from './OpportunityRow';
import { ForecastData, ForecastTableRow } from 'Resources/ReportWidget';

type Props = ForecastTableRow & {
	excluded: number[];
	setExcluded: (ids: number[]) => void;
	currency: ForecastData['currency'];
};
const RowDropDown = ({
	user,
	sales,
	remaining,
	pipeline,
	pipelineCoverageRatio,
	target,
	drilldown,
	excluded,
	setExcluded,
	currency
}: Props) => {
	const classes = new BemClass('RowDropDown');
	const cf = new CurrencyFormat(currency);
	const [open, setOpen] = useState(false);
	const userColumn = (
		<TableColumn size="lg">
			<div>
				<Avatar size={20} user={user} />
				<div>
					<Text>{user.name}</Text>
				</div>
			</div>
		</TableColumn>
	);

	useEffect(() => {
		setOpen(false);
	}, [drilldown, drilldown.length]);

	const columns = [
		{ title: T('default.account') },
		{ title: T('default.value') },
		{ title: T('default.stage') },
		{ title: T('default.closeDate2') },
		{ title: T('forecasting.daysInPipeline') },
		{ title: '' }
	];
	const pipelineSymbol = pipeline.change > 0 ? '+' : pipeline.change < 0 ? '' : '+/-';
	const pipelineColumn = (
		<TableColumn className={classes.elem('pipeline-column').b()} size="lg">
			<Text size="md">{cf.short(pipeline.value)}</Text>
			<Tooltip title={T('forecasting.changeLastDays')} position="right" distance={-85}>
				<Text color={pipeline.change < 0 ? 'dark-red' : 'black'} size="sm">{`${pipelineSymbol} ${cf.short(
					pipeline.change
				)}`}</Text>
			</Tooltip>
		</TableColumn>
	);

	return (
		<>
			<TableRow
				key={user.id}
				className={classes.elem('main-row').mod({ open }).b()}
				onClick={
					drilldown.length
						? () => {
								if (!open) {
									forecastingTracker.track(forecastingTracker.events.DRILLDOWN_OPENED);
								}
								setOpen(!open);
						  }
						: () => {}
				}
			>
				{userColumn}
				<TableColumn size="lg">{cf.short(sales.value)}</TableColumn>
				<TableColumn size="lg">
					{target.value && remaining.value != null ? (
						remaining.value > 0 ? (
							cf.short(remaining.value)
						) : (
							<>
								<Text size="md">{T('forecasting.targetReached')}</Text>
								<Text size="sm" color="success-6">
									{'+ ' + cf.short(-remaining.value)}
								</Text>
							</>
						)
					) : null}
				</TableColumn>
				{pipelineColumn}
				<TableColumn size="lg">
					{remaining.value !== null ? (
						<Text size="md" color={pipelineCoverageRatio.color}>
							{pipelineCoverageRatio.value !== null
								? `${pipelineCoverageRatio.value}X`
								: T('forecasting.targetReached')}
						</Text>
					) : null}
				</TableColumn>

				<TableColumn size="lg">
					{drilldown.length ? <Icon name={open ? 'chevron-up' : 'chevron-down'} /> : null}
				</TableColumn>
			</TableRow>
			{open ? (
				<>
					<TableRow>
						{columns.map(col => (
							<TableColumn key={col.title} size="sm">
								<Text bold={true} size="sm">
									{col.title}
								</Text>
							</TableColumn>
						))}
					</TableRow>
					{drilldown.length
						? drilldown.map(row => (
								<OpportunityRow
									key={row.id}
									row={row}
									excluded={excluded}
									setExcluded={setExcluded}
									isDropDown
									currency={currency}
								/>
						  ))
						: null}
				</>
			) : null}
		</>
	);
};

export default RowDropDown;
