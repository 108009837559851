import CreateAccountRoot from 'App/babel/components/Modals/CreateNewAccount/CreateNewAccount';
import ProspectingClient from 'App/resources/Model/ProspectingClient';
import SoliditetClient from 'App/resources/Model/SoliditetClient';
import React, { useEffect, useState, useRef } from 'react';
import ValidationService from 'Services/ValidationService';
import { makeCancelable } from 'App/babel/helpers/promise';
import {
	Country,
	getAvailableCountries,
	getDefaultIndustryFieldFromCountry,
	StatusCategory
} from 'App/services/prospectingService';
import BemClass from '@upsales/components/Utils/bemClass';
import CustomField from 'App/resources/Model/CustomField';
import Prospecting from 'App/babel/resources/Prospecting';
import RequestBuilder from 'Resources/RequestBuilder';
import logError from 'App/babel/helpers/logError';
import Client from 'App/resources/Model/Client';
import { globalTracker } from 'Helpers/Tracker';
import openModal, { shouldOpenModal } from 'App/services/Modal';
import { useSelector } from 'react-redux';
import { RootState } from 'Store/index';
import { cloneDeep } from 'lodash';
import _ from 'lodash';

import type { DataSource, Datasources } from './DataSource';
import { external } from './DataSources';

import './CreateAccount.scss';

type Props = {
	className: string;
	close: () => void;
	onSave?: (client: Client) => void;
};

type FieldConfig = {
	field: string;
	width: string;
	type: string;
	header?: string;
	staticValue?: string;
};

const LIMIT = 5;

const getExistingConfig = () => ({
	id: 'existing' as const,
	idField: 'id' as const,
	companyGroup: null,
	match: null,
	type: 'existing',
	name: 'account.alreadyInUpsales',
	shortName: 'default.upsales',
	logo: null,
	countries: ['SE'],
	pagination: { enabled: true },
	pricing: null,
	waitTime: 300,
	fields: [
		{
			field: 'name',
			type: 'nameExisting',
			header: 'default.account',
			width: '55%'
		},
		{
			type: 'users',
			field: 'users',
			header: 'default.accountManager',
			width: '35%'
		},
		{
			type: 'view',
			field: 'view',
			header: '',
			width: '10%'
		}
	]
});

const getProspectingConfig = (countries: Country[]) => {
	const prosepctingCountryFields = countries.reduce<{ [country: string]: FieldConfig[] }>((fields, country) => {
		const industryField = getDefaultIndustryFieldFromCountry(country);

		fields[country] = [
			{
				field: 'name',
				header: 'default.account',
				width: '25%',
				type: 'nameProspecting'
			},
			{
				type: 'string',
				field: 'orgNumber',
				header: 'default.orgNumberShort',
				width: '12%'
			},
			{
				type: 'string',
				field: industryField,
				header: 'default.companyBranch',
				width: '25%',
				staticValue: industryField
			},
			{
				type: 'groupSize',
				field: 'groupSize',
				header: 'default.companySize',
				width: '12%'
			},
			{
				type: 'add',
				field: 'add',
				width: '28%'
			}
		];

		return fields;
	}, {});

	const config = {
		id: 'prospecting' as const,
		idField: 'prospectingId' as const,
		fields: prosepctingCountryFields,
		useCountryFields: true,
		companyGroup: {
			groupIdField: 'orgNumber' as const,
			parentField: 'closestGroupMotherOrgnumber' as const,
			ultimateParentField: 'groupMotherOrgnumber' as const
		},
		match: null,
		type: 'prospecting',
		name: 'Upsales prospecting',
		logo: '/img/u_square_logo.png',
		countries,
		countryTabs: {
			enabled: countries.length > 1,
			showAll: false,
			showTotals: countries.length > 1
		},
		currentTab: undefined as Country | undefined,
		pagination: { enabled: true },
		pricing: null,
		waitTime: 300
	};

	return config;
};

const getSoliditetConfig = (validCountries: CountriesSoliditet[]) => ({
	id: 'soliditet' as const,
	idField: 'dunsNo' as const,
	companyGroup: {
		groupIdField: 'dunsNo' as const,
		parentField: 'parentDuns' as const,
		ultimateParentField: 'rootParentDuns' as const
	},
	match: {
		upsales: 'dunsNo' as const,
		integration: 'dunsNo'
	},
	type: 'soliditet',
	name: 'default.soliditet',
	logo: '/img/bisnode_square_logo.png',
	countries: validCountries,
	countryTabs: {
		enabled: true,
		showAll: true,
		showTotals: true
	},
	pagination: { enabled: true },
	pricing: null,
	waitTime: 300,
	fields: [
		{
			field: 'name',
			header: 'default.account',
			width: '25%',
			type: 'nameSoliditet'
		},
		{
			type: 'string',
			field: 'orgNo',
			header: 'default.orgNumberShort',
			width: '12%'
		},
		{
			type: 'string',
			field: 'sniCode',
			header: 'default.companyBranch',
			width: '25%',
			staticValue: 'sniCode'
		},
		{
			type: 'groupSize',
			field: 'groupSize',
			header: 'default.companySize',
			width: '12%'
		},
		{
			type: 'add',
			field: 'add',
			width: '28%'
		}
	]
});

type AddingAccount = {
	account?: any;
	externalId: string;
	dataSourceId: Datasources | number;
	merging?: boolean;
	isExternal?: boolean;
	added?: boolean;
	adding?: boolean;
};

type UpsalesAccount = Pick<Client, 'id' | 'name' | 'addresses' | 'projects' | 'priceListId'>;

type CountriesSoliditet = 'SE' | 'NO' | 'DK' | 'FI';

const CreateAccount = ({ className, close, onSave }: Props) => {
	const { customerId, customFields, metadata, self } = useSelector(({ App }: RootState) => App);
	const SoliditetClient = Tools.SoliditetClient;
	const Account = Tools.Account;

	const classes = new BemClass('CreateAccountModal', className);
	classes.add('FullScreenModal');

	const searchTimeouts = useRef<{ [key: string]: ReturnType<typeof setTimeout> }>({});
	const requests = useRef<{ [key: string]: ReturnType<typeof makeCancelable> }>({});
	const sortId = useRef(0);
	const inputRef = useRef<HTMLInputElement | null>(null);

	const getInitialState = () => ({
		loading: false,
		isLoadingMore: false,
		hasError: false,
		sortId: sortId.current++,
		data: [],
		metadata: {},
		currentTab: 'ALL',
		offset: 0
	});

	const [searchInput, setSearchInput] = useState('');
	const [accounts, setAccounts] = useState<{
		[key: string]: DataSource;
	}>({
		existing: Object.assign(getInitialState(), getExistingConfig())
	});
	const [companyGroup, setCompanyGroup] = useState<{
		dataSourceId: Datasources;
		idField: string;
		config: {
			groupIdField: 'orgNumber' | 'dunsNo';
			parentField: 'closestGroupMotherOrgnumber' | 'parentDuns';
			ultimateParentField: 'groupMotherOrgnumber' | 'rootParentDuns';
		};
		rootDuns: string | number | null;
		openingGroup: boolean;
		groupAccount: ProspectingClient | SoliditetClient;
		data: null | SoliditetClient[] | ProspectingClient[];
		unknownTotal?: number | null;
		total?: number | null;
		tree?: ProspectingClient | null;
		unknowns?: ProspectingClient[] | null;
	} | null>(null);
	const [addingAccount, setAddingAccount] = useState<AddingAccount | null>(null);
	const customFieldsAccount = customFields['account'];
	const requiredCustomFieldsAccount = customFieldsAccount.filter(value => {
		return value.obligatoryField && value.$hasAccess && value.editable && value.alias !== 'ORG_NO';
	});
	const [requiredFields, setRequiredFields] = useState<CustomField[] | null>(null);

	const getIntegartions = () => {
		if (!metadata) {
			return [];
		}
		return (metadata.integrations.inits.dataSource ?? [])
			.filter(integration => integration.capabilities && integration.capabilities.typeahead)
			.map(integration => {
				const state = {
					loading: false,
					isLoadingMore: false,
					sortId: sortId.current++,
					data: [],
					metadata: {},
					currentTab: 'ALL',
					offset: 0,
					invalidSettings: false
				};

				const defaultConfig = {
					idField: 'id',
					logo: '/img/icon_globe.png',
					fields: [{ field: 'name', header: 'default.account', width: '100%', type: 'string' }],
					countryTabs: { enabled: false, showTotals: true, showAll: false },
					pagination: { enabled: true },
					countries: null,
					match: null,
					pricing: null,
					waitTime: 300
				};

				const config = {
					id: integration.id,
					type: 'integration',
					name: integration.name,
					idField: integration.capabilities?.typeahead?.idField || defaultConfig.idField,
					logo: integration.capabilities?.logo || integration.imageLink || defaultConfig.logo,
					fields: integration.capabilities?.typeahead?.columns || defaultConfig.fields,
					countryTabs: integration.capabilities?.typeahead?.countryTabs || defaultConfig.countryTabs,
					pagination: integration.capabilities?.typeahead?.pagination || defaultConfig.pagination,
					countries: integration.capabilities?.countries || defaultConfig.countries,
					match: integration.capabilities?.match || defaultConfig.match,
					pricing: integration.capabilities?.pricing || defaultConfig.pricing,
					waitTime: integration.capabilities?.typeahead?.waitTime || defaultConfig.waitTime
				};

				return Object.assign(state, config);
			});
	};

	useEffect(() => {
		const t = setTimeout(() => {
			inputRef?.current?.focus?.();
		}, 300);

		return () => clearTimeout(t);
	}, [inputRef.current]);

	useEffect(() => {
		if (Tools.FeatureHelper.isAvailable(Tools.FeatureHelper.Feature.PROSPECTING_BASIC)) {
			const prospectingCountries = getAvailableCountries();
			const prospectingConfig = getProspectingConfig(prospectingCountries);
			prospectingConfig.currentTab = prospectingConfig.countries[0];
			accounts.prospecting = Object.assign(getInitialState(), prospectingConfig);
			setAccounts({ ...accounts });
		}

		if (self?.userParams.soliditetIsActive) {
			const countryCodes = metadata?.params.DnBCountryCodes;

			let validCountries =
				!countryCodes || !countryCodes.length || countryCodes === 'null'
					? ['SE', 'NO', 'DK', 'FI']
					: countryCodes.split(',');

			if (!Tools.FeatureHelper.isAvailable(Tools.FeatureHelper.Feature.SOLIDITET_MULTI_MARKET)) {
				const dnbCountryCodes = self?.userParams.dnbCountryCodes;
				if (Array.isArray(dnbCountryCodes) && dnbCountryCodes.length) {
					validCountries = dnbCountryCodes;
				} else if (
					typeof dnbCountryCodes === 'string' &&
					dnbCountryCodes.length &&
					dnbCountryCodes.split(',').every(val => ['SE', 'FI', 'NO', 'DK'].includes(val))
				) {
					validCountries = dnbCountryCodes.split(',');
				} else {
					validCountries = ['SE'];
				}
			}

			accounts.soliditet = Object.assign(getInitialState(), getSoliditetConfig(validCountries));
			setAccounts({ ...accounts });
		}

		const integrations = getIntegartions();
		integrations.forEach(integration => {
			accounts[integration.id] = Object.assign(getInitialState(), integration);
		});
		setAccounts({ ...accounts });

		integrations.forEach(function (integration) {
			if (integration.countryTabs && integration.countryTabs.enabled) {
				const params = {
					type: 'client',
					integrationId: integration.id
				};

				const dataSource = accounts[integration.id];
				Tools.DataSource.settings<{ countries: string[] }>(params)
					.then(res => {
						const { error } = res;
						const { countries } = res.data;

						if (error) {
							console.error(error);
							dataSource.invalidSettings = true;
							setAccounts({ ...accounts });
						} else if (countries) {
							dataSource.countries = countries;

							if (!integration.countryTabs.showAll && countries.length) {
								dataSource.currentTab = countries[0];
							}

							accounts[integration.id] = dataSource;
							setAccounts({ ...accounts });
						}
					})
					.catch(error => {
						console.error(error);
						dataSource.hasError = true;
						setAccounts({ ...accounts });
					});
			}
		});

		return () => {
			Object.values(searchTimeouts.current).forEach(timeout => clearTimeout(timeout));
			Object.values(requests.current).forEach(request => request.cancel());
		};
	}, []);

	const hasNewFields = Tools.FeatureHelper.hasSoftDeployAccess(Tools.FeatureHelper.Feature.NEW_FIELDS);

	const parseNumber = (str: string) => {
		return parseInt(str.replace(/\D/g, ''));
	};

	const orgField = customFieldsAccount.find(c => c.alias === 'ORG_NO');
	const orgFieldId = orgField ? orgField.id : undefined;

	const searchExisting = (dataSource: DataSource, searchString: string) => {
		const offset = dataSource.offset;

		const rb = new RequestBuilder();
		rb.limit = LIMIT;
		rb.offset = offset;

		const parsed = parseNumber(searchString);

		if (
			!isNaN(parsed) &&
			parsed.toString().length >= 8 &&
			parsed.toString().length <= 14 &&
			(orgFieldId || hasNewFields)
		) {
			let val;
			// is international --> first two characters are letters
			const tmp = parsed.toString();
			let first, second;
			// @ts-ignore -_(^-^)_-
			if (isNaN(searchString[0]) && isNaN(searchString[1])) {
				first = tmp.slice(0, 2);
				second = tmp.slice(2, tmp.length);
				val = first + ' ' + second;
			} else {
				if (tmp.length > 5) {
					first = tmp.slice(0, 6);
					second = tmp.slice(6, tmp.length);
					val = first + '-' + second;
				} else {
					val = tmp;
				}
			}

			const orFilter = rb.orBuilder();

			if (orgFieldId) {
				orFilter.next();
				const groupBuilder = orFilter.groupBuilder();
				groupBuilder.addFilter({ field: 'custom.value' }, rb.comparisonTypes.Wildcard, tmp);
				groupBuilder.addFilter({ field: 'custom.fieldId' }, rb.comparisonTypes.Equals, orgFieldId);
				groupBuilder.done();
				orFilter.next();
				const groupBuilder2 = orFilter.groupBuilder();
				groupBuilder2.addFilter({ field: 'custom.value' }, rb.comparisonTypes.Wildcard, val);
				groupBuilder2.addFilter({ field: 'custom.fieldId' }, rb.comparisonTypes.Equals, orgFieldId);
				groupBuilder2.done();
			}
			if (hasNewFields) {
				orFilter.next();
				orFilter.addFilter({ field: 'orgNo' }, rb.comparisonTypes.Wildcard, tmp);
				orFilter.next();
				orFilter.addFilter({ field: 'orgNo' }, rb.comparisonTypes.Wildcard, val);
			}

			orFilter.done();
		} else {
			// first search for clients by name WCE
			rb.addFilter(Tools.Account.attr.name, rb.comparisonTypes.WildcardEnd, searchString);
		}

		return Tools.Account.customer(customerId)
			.find(rb.build())
			.then(res => {
				return {
					data: res.data,
					metadata: {
						ALL: res.metadata.total
					},
					searchString
				};
			});
	};

	const findAll = (resource: any, rb: RequestBuilder) => {
		return Tools.findAll(resource.find, rb.build());
	};

	const searchProspecting = (dataSource: DataSource, searchString: string, fetchMetadata: boolean) => {
		const country = dataSource.currentTab;
		const offset = dataSource.offset;
		const rb = new RequestBuilder();
		rb.limit = LIMIT;
		rb.offset = offset;
		rb.fields = [
			'closestGroupMotherOrgnumber',
			'groupMotherOrgnumber',
			'registeredCountry',
			'visitingCountry',
			'registeredTown',
			'headquarters',
			'visitingTown',
			'postCountry',
			'noEmployees',
			'countryCode',
			'orgNumber',
			'groupSize',
			'ukSicCode',
			'postTown',
			'naceCode',
			'revenue',
			'sniCode',
			'cfar',
			'name',
			'id'
		];

		rb.addFilter({ field: 'statusCategory' }, rb.comparisonTypes.Equals, [
			StatusCategory.Active,
			StatusCategory.ActiveWithWarning
		]);

		const possibleOrgNumber = ValidationService.validateOrgNumber(searchString, dataSource.currentTab);

		if (possibleOrgNumber) {
			rb.addFilter({ field: 'orgNumber' }, rb.comparisonTypes.Equals, searchString);
		} else {
			rb.addFilter({ field: 'name' }, rb.comparisonTypes.Search, searchString);
		}

		rb.addSort('revenue', false);
		rb.addSort('headquarters', false);
		rb.addSort('groupMotherOrgnumber', true);

		rb.extraParams.push({ key: 'country', value: country });
		const filters = rb.build();

		if (fetchMetadata && dataSource.countries && dataSource.countries.length) {
			filters.countries = dataSource.countries;
		}

		return Prospecting.find(filters).then(res => {
			for (const company of res.data) {
				if (company.matchInUpsales) {
					company.existing = true;
					company.existingInactive = !company.matchInUpsales.active;
					company.isExternal = company.matchInUpsales?.isExternal;
					company.upsalesId = company.matchInUpsales.id;
				}
			}
			return { ...res, searchString };
		});
	};

	const matchExisting = function (
		config: DataSource['match'],
		res: { data: any[]; metadata: any; searchString: string }
	) {
		if (!config) {
			return res;
		}
		const values = _.pluck(res.data, config.integration);

		if (!values.length) {
			return res;
		}

		const rb = new RequestBuilder();
		rb.addFilter({ field: config.upsales }, rb.comparisonTypes.Equals, values);
		rb.addFilter(Account.attr.isExternal, rb.comparisonTypes.Equals, false);

		return findAll(Account.customer(customerId), rb).then(upsalesAccounts => {
			const valueMap = upsalesAccounts.reduce((res, upsalesAccount) => {
				const key = upsalesAccount[config.upsales];
				if (!key) {
					return res;
				}

				res[key] = upsalesAccount;

				return res;
			}, {} as { [key: string]: Client });

			const mappedData = res.data.map(dataSourceAccount => {
				const key = dataSourceAccount[config.integration];
				const match = valueMap[key];

				if (match) {
					dataSourceAccount.existing = true;
					dataSourceAccount.existingInactive = match.active ? false : true;
					dataSourceAccount.upsalesId = match.id;
				}

				return dataSourceAccount;
			});

			return { data: mappedData, metadata: res.metadata, searchString: res.searchString };
		});
	};

	const mapSoliditetToExistingUpsales = function (soliditetAccounts: SoliditetClient[]) {
		const dunses = soliditetAccounts.map(s => s.dunsNo);

		if (!dunses.length) {
			return soliditetAccounts;
		}

		const dunsChunks = _.chunk(dunses, 1000);
		const promises = dunsChunks.map(dunses => {
			const dunsFilter = new RequestBuilder();
			dunsFilter.addFilter(Account.attr.dunsNo, dunsFilter.comparisonTypes.Equals, dunses);
			dunsFilter.addFilter(Account.attr.isExternal, dunsFilter.comparisonTypes.Equals, false);
			return findAll(Account.customer(customerId), dunsFilter);
		});
		return Promise.all(promises)
			.then(res => res.flat())
			.then(upsalesAccounts => {
				upsalesAccounts.forEach(upsalesClient => {
					const index = soliditetAccounts.findIndex(soliditetClient => {
						return parseInt(upsalesClient.dunsNo ?? '') === soliditetClient.dunsNo;
					});

					if (index !== -1) {
						soliditetAccounts[index] = Object.assign({}, soliditetAccounts[index], {
							...upsalesClient,
							existing: true,
							existingInactive: !!upsalesClient.active
						});
					}
				});
				return soliditetAccounts;
			});
	};

	const _searchSoliditet = (searchString: string, limit: number, offset: number, country?: string | null) => {
		const filter = new RequestBuilder();
		filter.addSort('turnover', false);
		filter.addSort(SoliditetClient.attr.headquarters, false);

		const integration = accounts.soliditet;
		const countries = integration.countries;

		if (country && countries && countries.indexOf(country) !== -1) {
			filter.addFilter(SoliditetClient.attr.country, filter.comparisonTypes.Equals, country);
		} else {
			const orBuilder = filter.orBuilder();
			countries?.forEach(country => {
				orBuilder.addFilter.bind(null, SoliditetClient.attr.country, filter.comparisonTypes.Equals, country);
				orBuilder.next();
			});
			orBuilder.done();
		}

		filter.limit = limit;
		filter.offset = offset;

		// If user enters number lte 10 digits we search for clients by org nr
		const parsed = parseNumber(searchString);

		if (!isNaN(parsed) && parsed.toString().length >= 8 && parsed.toString().length <= 14) {
			filter.addFilter(SoliditetClient.attr.orgNo, filter.comparisonTypes.Equals, parsed);
		} else {
			filter.addFilter(SoliditetClient.attr.name, filter.comparisonTypes.Search, searchString);
		}
		filter.addFilter(SoliditetClient.attr.status, filter.comparisonTypes.Match, 'Aktiv');

		return SoliditetClient.customer(customerId).find(filter.build());
	};

	const searchSoliditet = (dataSource: DataSource, searchString: string, fetchMetadata: boolean) => {
		const countries = dataSource.countries;
		const currentTab = dataSource.currentTab;

		const promises: (ReturnType<typeof _searchSoliditet> | null)[] = [
			_searchSoliditet(searchString, LIMIT, dataSource.offset ?? 0, currentTab)
		];

		if (fetchMetadata && countries) {
			const promiseSE = countries.indexOf('SE') !== -1 ? _searchSoliditet(searchString, 0, 0, 'SE') : null;
			const promiseNO = countries.indexOf('NO') !== -1 ? _searchSoliditet(searchString, 0, 0, 'NO') : null;
			const promiseDK = countries.indexOf('DK') !== -1 ? _searchSoliditet(searchString, 0, 0, 'DK') : null;
			const promiseFI = countries.indexOf('FI') !== -1 ? _searchSoliditet(searchString, 0, 0, 'FI') : null;

			promises.push(_searchSoliditet(searchString, 0, 0, null));
			promises.push(promiseSE);
			promises.push(promiseNO);
			promises.push(promiseDK);
			promises.push(promiseFI);
		}

		return Promise.all(promises).then(res => {
			const [soliditet] = res;
			const result: {
				data: any;
				searchString: string;
				metadata?: { ALL: number; SE: number; NO: number; DK: number; FI: number };
			} = {
				data: soliditet?.data,
				searchString
			};

			if (fetchMetadata) {
				const [
					,
					soliditetMetadataALL,
					soliditetMetadataSE,
					soliditetMetadataNO,
					soliditetMetadataDK,
					soliditetMetadataFI
				] = res;
				result.metadata = {
					ALL: soliditetMetadataALL ? soliditetMetadataALL.metadata.total : 0,
					SE: soliditetMetadataSE ? soliditetMetadataSE.metadata.total : 0,
					NO: soliditetMetadataNO ? soliditetMetadataNO.metadata.total : 0,
					DK: soliditetMetadataDK ? soliditetMetadataDK.metadata.total : 0,
					FI: soliditetMetadataFI ? soliditetMetadataFI.metadata.total : 0
				};
			}
			return result;
		});
	};

	const search = (
		searchString: string,
		dataSource: DataSource,
		limit: number,
		offset: number,
		fetchMetadata: boolean
	) => {
		let promise;
		if (!searchString.length) {
			promise = Promise.resolve({ data: [], metadata: {}, searchString });
		} else if (dataSource.id === 'soliditet') {
			promise = searchSoliditet(dataSource, searchString, fetchMetadata);
		} else if (dataSource.id === 'existing') {
			promise = searchExisting(dataSource, searchString);
		} else if (dataSource.id === 'prospecting') {
			promise = searchProspecting(dataSource, searchString, fetchMetadata);
		} else {
			const setHasError = (hasError: boolean) => {
				dataSource.hasError = hasError;
				setAccounts({ ...accounts });
			};
			promise = external(searchString, dataSource, fetchMetadata, setHasError, limit, offset);
		}

		if (!promise) {
			return;
		}

		if (dataSource.match) {
			return promise.then(res => {
				if (!res?.data) {
					return res;
				}
				return matchExisting(dataSource.match, res);
			});
		} else {
			return promise as Promise<any>;
		}
	};

	const doSearch = (event: React.ChangeEvent<HTMLInputElement>) => {
		const value = event.target.value;

		setSearchInput(event.target.value);

		const dataSources = accounts;
		Object.values(dataSources).forEach(dataSource => {
			if (searchTimeouts.current[dataSource.id]) {
				clearTimeout(searchTimeouts.current[dataSource.id]);
				requests.current[dataSource.id]?.cancel?.();
			}
			const waitTime = dataSource.waitTime || 300;

			searchTimeouts.current[dataSource.id] = setTimeout(() => {
				dataSource.loading = true;
				dataSource.offset = 0;
				dataSources[dataSource.id] = dataSource;
				setAccounts({ ...dataSources });

				const searchString = value.trim();
				const fetchMetadata = true;

				const makeCanelblePromise = makeCancelable(search(searchString, dataSource, LIMIT, 0, fetchMetadata)!);
				requests.current[dataSource.id] = makeCanelblePromise;

				makeCanelblePromise.promise
					.then(res => {
						dataSource.data = res?.data || [];
						dataSource.metadata = res?.metadata || {};
						dataSource.loading = false;
						dataSource.hasError = !res?.data || !res?.metadata;
						dataSources[dataSource.id] = dataSource;
						setAccounts({ ...dataSources });
					})
					.catch(e => {
						logError(e);
						dataSource.data = [];
						dataSource.metadata = {};
						dataSource.loading = false;
						dataSources[dataSource.id] = dataSource;
						setAccounts({ ...dataSources });
					});
			}, waitTime);
		});
	};

	const loadMoreAccounts = (dataSourceId: Datasources) => {
		const dataSource = accounts[dataSourceId];
		if (!dataSource) {
			return;
		}
		if (searchTimeouts.current[dataSource.id]) {
			clearTimeout(searchTimeouts.current[dataSource.id]);
			requests.current[dataSource.id]?.cancel?.();
		}

		dataSource.isLoadingMore = true;
		dataSource.offset = (dataSource.offset ?? 0) + LIMIT;

		accounts[dataSourceId] = dataSource;
		setAccounts({ ...accounts });

		const fetchMetadata = false;
		const makeCanelblePromise = makeCancelable(
			search?.(searchInput.trim(), dataSource, LIMIT, dataSource.offset, fetchMetadata)!
		);
		requests.current[dataSource.id] = makeCanelblePromise;

		return makeCanelblePromise.promise
			.then(res => {
				dataSource.data = dataSource.data?.concat(res.data);
				dataSource.isLoadingMore = false;
				dataSource.hasError = false;

				accounts[dataSourceId] = dataSource;
				setAccounts({ ...accounts });
			})
			.catch(() => {
				dataSource.isLoadingMore = false;
				accounts[dataSourceId] = dataSource;
				setAccounts({ ...accounts });
			});
	};

	const changeTab = function (dataSourceId: Datasources, country: Country) {
		const dataSource = accounts[dataSourceId];
		if (!dataSource) {
			return;
		}
		if (searchTimeouts.current[dataSource.id]) {
			clearTimeout(searchTimeouts.current[dataSource.id]);
			requests.current[dataSource.id]?.cancel?.();
		}
		dataSource.currentTab = country;
		dataSource.offset = 0;
		dataSource.loading = true;
		accounts[dataSourceId] = dataSource;
		setAccounts({ ...accounts });

		const searchString = searchInput.trim();
		const fetchMetadata = false;

		const makeCanelblePromise = makeCancelable(search?.(searchString, dataSource!, LIMIT, 0, fetchMetadata)!);
		requests.current[dataSource.id] = makeCanelblePromise;

		makeCanelblePromise.promise
			.then(res => {
				const newCurrentTab = dataSource.currentTab;
				const currentSearchString = res.searchString.trim();
				dataSource.loading = false;
				dataSource.hasError = false;

				if (newCurrentTab === country && currentSearchString === searchString) {
					dataSource.data = res.data;
				}

				accounts[dataSourceId] = dataSource;
				setAccounts({ ...accounts });
			})
			.catch(e => {
				logError(e);
				dataSource.data = [];
				dataSource.loading = false;

				accounts[dataSourceId] = dataSource;
				setAccounts({ ...accounts });
			});
	};

	const openGroup = (account: ProspectingClient | SoliditetClient, dataSourceId: Datasources) => {
		const dataSource = accounts[dataSourceId];
		if (!dataSource) {
			return;
		}

		const config = dataSource.companyGroup;
		if (!config) {
			return;
		}

		const isProspecting = (account: SoliditetClient | ProspectingClient): account is ProspectingClient =>
			dataSourceId === 'prospecting';

		// idk a better way to do this :(
		const rootDuns = (
			(account as any)[config.ultimateParentField]
				? (account as any)[config.ultimateParentField]
				: (account as any)[config.groupIdField]
		) as number | null;

		const group = {
			dataSourceId: dataSourceId,
			idField: dataSource.idField,
			config: config,
			rootDuns: rootDuns,
			openingGroup: true,
			groupAccount: account,
			data: null
		};

		setCompanyGroup({ ...group });

		if (isProspecting(account)) {
			const rb = new RequestBuilder();
			rb.addFilter({ field: 'prospectingId' }, rb.comparisonTypes.Equals, account.prospectingId);
			rb.extraParams.push({ key: 'country', value: dataSource.currentTab });
			Prospecting.findGroupstructure(rb.build())
				.then((res: { data: ProspectingClient[] }) => {
					const getBranches = (item: ProspectingClient) => {
						let allBranches = item.branches;
						for (const child of item.children) {
							const childBranches = getBranches(child);
							allBranches = allBranches.concat(childBranches);
						}
						return allBranches;
					};

					let total = 0;

					const fixMatchInUpsales = (item: ProspectingClient) => {
						total++;
						if (item.matchInUpsales) {
							Object.assign(item, item.matchInUpsales, { existing: item.matchInUpsales });
						}
						for (const branch of item.branches) {
							total++;
							if (branch.matchInUpsales) {
								Object.assign(branch, branch.matchInUpsales, { existing: branch.matchInUpsales });
							}
						}
						for (const child of item.children) {
							fixMatchInUpsales(child);
						}
					};

					const tree = res.data[0];
					if (tree) {
						fixMatchInUpsales(tree);
					}
					const unknowns = tree ? getBranches(tree) : null;

					setCompanyGroup({
						...group,
						unknownTotal: unknowns?.length ?? 0,
						total: total,
						data: [],
						tree: tree,
						unknowns: unknowns,
						openingGroup: false
					});
				})
				.catch(e => {
					logError(e, 'Failed openGroup');
				});
		} else {
			const rb = new RequestBuilder();
			const or = rb.orBuilder();
			or.next();
			or.addFilter(SoliditetClient.attr.rootParentDuns, rb.comparisonTypes.Equals, rootDuns);
			or.next();
			or.addFilter(SoliditetClient.attr.dunsNo, rb.comparisonTypes.Equals, rootDuns);
			or.done();

			findAll(SoliditetClient.customer(customerId), rb)
				.then(mapSoliditetToExistingUpsales)
				.then(mappedAccounts => {
					setCompanyGroup({
						...group,
						unknownTotal: null,
						total: null,
						unknowns: null,
						tree: null,
						data: mappedAccounts,
						openingGroup: false
					});
				})
				.catch(e => {
					logError(e, 'Failed openGroup');
				});
		}
	};

	const goToAccount = (id: number) => {
		close();
		Tools.$state.go('account.dashboard', { id });
	};

	const addAccount = (addingAccount: AddingAccount, customValues: CustomField[], pricingKey?: string) => {
		const requiredValues = customValues || [];
		let promise;
		if (addingAccount.dataSourceId === 'soliditet') {
			const opts = {
				updateExisting: false,
				skipProjects: false,
				skipAccountManagers: false,
				skipAddresses: false,
				skipCategories: false
			};

			promise = SoliditetClient.customer(customerId).buy(addingAccount.externalId, requiredValues, opts);
		} else if (addingAccount.dataSourceId === 'prospecting') {
			let id;
			if (addingAccount.isExternal) {
				id = addingAccount.account?.upsalesId || addingAccount.account?.id;
			}
			promise = Prospecting.save({
				id,
				prospectingId: addingAccount.externalId,
				customValues
			});
		} else {
			let method = 'buy' as const;

			if (pricingKey) {
				method = accounts[addingAccount.dataSourceId].pricing?.[pricingKey].endpoint || 'buy';
			}

			const params = {
				type: 'client',
				integrationId: addingAccount.dataSourceId,
				data: {
					id: addingAccount.externalId,
					customValues: customValues
				}
			};
			promise = Tools.DataSource[method](params);
		}
		if (!promise) {
			return;
		}
		promise
			.then(createdAccount => {
				setAddingAccount({ ...addingAccount, added: true });

				if (onSave) {
					close();
					onSave(createdAccount.data);
				} else {
					goToAccount(createdAccount.data.id);
				}
			})
			.catch(err => {
				addingAccount.added = false;
				addingAccount.adding = false;
				setAddingAccount({ ...addingAccount, adding: false, added: false });

				const errorKey = err.response?.data?.error?.key;
				let notificationBody = 'default.error';

				if (errorKey === 'NotEnoughCredits' && addingAccount.dataSourceId === 'prospecting') {
					notificationBody = 'prospecting.trial.limitReached';
				} else if (errorKey === 'NoSuchEntity') {
					notificationBody = 'errorNotFound.account';
				} else if (errorKey === 'NoEditRights') {
					notificationBody = 'noEditRights.client';
				} else {
					console.error(err);
				}

				Tools.NotificationService.addNotification({
					style: Tools.NotificationService.style.ERROR,
					title: 'default.error',
					body: notificationBody,
					icon: 'times'
				});
			});

		setAddingAccount({ ...addingAccount, adding: true });
	};

	const beginAddAccount = (dataSourceId: Datasources, account: ProspectingClient, pricingKey: string) => {
		const dataSource = accounts[dataSourceId];

		if (dataSource) {
			const addingAccount = {
				externalId: account[dataSource.idField as 'prospectingId'],
				dataSourceId: dataSourceId,
				pricingKey: pricingKey
			};

			if (requiredCustomFieldsAccount.length !== 0) {
				setAddingAccount(addingAccount);
				setRequiredFields(cloneDeep(requiredCustomFieldsAccount));
			} else {
				addAccount(addingAccount, [], pricingKey);
			}
		}
	};

	const beginAddExternal = (dataSourceId: Datasources, account: ProspectingClient) => {
		if (dataSourceId === 'prospecting') {
			const dataSource = accounts[dataSourceId];

			const addingAccount = {
				account: account,
				externalId: account[dataSource.idField as 'prospectingId'],
				dataSourceId: dataSourceId,
				isExternal: true
			};

			if (requiredCustomFieldsAccount.length !== 0) {
				setAddingAccount(addingAccount);
				setRequiredFields(cloneDeep(requiredCustomFieldsAccount));
			} else {
				addAccount(addingAccount, []);
			}
		}
	};

	const beginMerge = (dataSourceId: Datasources, account: ProspectingClient) => {
		const dataSource = accounts[dataSourceId];

		const addingAccount = {
			account: account,
			externalId: account[dataSource.idField as 'prospectingId'],
			dataSourceId: dataSource.id,
			merging: true
		};

		setAddingAccount(addingAccount);
	};

	const merge = (addingAccount: AddingAccount, upsalesAccount: UpsalesAccount, pricingKey: string) => {
		let promise: Promise<any> | undefined;

		if (addingAccount.dataSourceId === 'soliditet') {
			const action = {
				action: 'buy' as const,
				id: upsalesAccount.id,
				dunsNo: addingAccount.externalId
			};

			promise = SoliditetClient.customer(customerId).updateMatches({ buy: [action] });
		} else if (addingAccount.dataSourceId === 'prospecting') {
			promise = Prospecting.save({
				id: upsalesAccount.id,
				prospectingId: addingAccount.externalId
			});
		} else {
			let method = 'buy' as const;

			if (pricingKey) {
				method = accounts[addingAccount.dataSourceId].pricing?.[pricingKey].endpoint || 'buy';
			}

			const params = {
				type: 'client',
				integrationId: addingAccount.dataSourceId,
				data: {
					id: addingAccount.externalId,
					mergeId: upsalesAccount.id,
					customValues: []
				}
			};
			promise = Tools.DataSource[method](params);
		}

		if (!promise) {
			return;
		}

		promise
			.then(res => {
				setAddingAccount({ ...addingAccount, added: true, adding: false, merging: false });

				if (onSave) {
					close();
					onSave(res?.data);
				} else {
					goToAccount(upsalesAccount.id);
				}
			})
			.catch(err => {
				setAddingAccount({ ...addingAccount, adding: false, merging: false });
				console.error(err);
			});

		setAddingAccount({ ...addingAccount, adding: true });
	};

	const abortMerge = () => {
		setAddingAccount(null);
	};

	const enterManually = () => {
		globalTracker.track('add', { type: 'account', location: 'manually' });

		const name = searchInput;

		if (onSave) {
			if (shouldOpenModal('EditClient')) {
				openModal('EditClient', {
					prefillData: { name },
					noRedirect: true,
					onClose: client => {
						if (client) {
							onSave(client);
						}
					}
				});
			} else {
				const account = Tools.Account.new();
				account.name = name;
				// eslint-disable-next-line promise/catch-or-return
				Tools.$upModal
					.open('editAccount', {
						account: account,
						customerId: customerId,
						fromModal: true
					})
					.then(client => {
						if (client) {
							onSave(client);
						}
					});
			}
		} else {
			if (shouldOpenModal('EditClient')) {
				openModal('EditClient', { prefillData: { name } });
			} else {
				const account = Tools.Account.new();
				account.name = name;
				Tools.$upModal.open('editAccount', {
					account: account,
					customerId: customerId
				});
			}
		}

		close();
	};

	const backToSearch = () => {
		setCompanyGroup(null);
		setAddingAccount(null);
		setRequiredFields(null);
	};

	return (
		<div className={classes.b()}>
			<CreateAccountRoot
				actions={{
					close,
					merge,
					doSearch,
					changeTab,
					openGroup,
					abortMerge,
					addAccount,
					beginMerge,
					goToAccount,
					backToSearch,
					enterManually,
					beginAddAccount,
					beginAddExternal,
					loadMoreAccounts
				}}
				accounts={accounts}
				search={searchInput}
				companyGroup={companyGroup}
				addingAccount={addingAccount}
				requiredFields={requiredFields}
				openingGroup={companyGroup?.openingGroup ?? false}
				hasRequriedFields={!!requiredCustomFieldsAccount.length}
				setInputRef={(r: HTMLInputElement) => (inputRef.current = r)}
			/>
		</div>
	);
};

export default CreateAccount;
