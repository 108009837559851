import React from 'react';
import { IconBadge, IconBadgeGroup } from '@upsales/components';
import BemClass from '@upsales/components/Utils/bemClass';
import T from 'Components/Helpers/translate';
import Client from 'App/resources/Model/Client';
import Contact from 'App/resources/Model/Contact';
import moment from 'moment-timezone';

export interface HistoryProperties {
	hasActivity?: Date | true | null;
	hadActivity?: Date | true | null;
	hasAppointment?: Date | null;
	hadAppointment?: Date | null;
	hasOpportunity?: Date | null;
	hadOpportunity?: Date | null;
	hasOrder?: Date | null;
	hadOrder?: Date | null;
}

interface SalesHistoryProps {
	className?: string;
	entity: Omit<Partial<Client> | Partial<Contact>, keyof HistoryProperties> & HistoryProperties;
	entityName: string;
	color?: 'green' | 'blue';
}

const SalesHistory: React.FC<SalesHistoryProps> = ({ entity, className = '', entityName, color = 'blue' }) => {
	const classes = new BemClass('SalesHistory', className);

	const activityEntity = Tools.FeatureHelper.hasSoftDeployAccess('TODO_LIST') ? 'tasks' : 'activity';

	const lang = {
		hasActivity: T(`history.${entityName}.${activityEntity}.has`),
		hadActivity: T(`history.${entityName}.${activityEntity}.had`),
		noActivity: T(`history.${entityName}.${activityEntity}.no`),
		passedActivity: T(`history.${entityName}.${activityEntity}.passed`),
		hasAppointment: T(`history.${entityName}.appointment.has`),
		hadAppointment: T(`history.${entityName}.appointment.had`),
		noAppointment: T(`history.${entityName}.appointment.no`),
		hasOpportunity: T(`history.${entityName}.opportunity.has`),
		hadOpportunity: T(`history.${entityName}.opportunity.had`),
		noOpportunity: T(`history.${entityName}.opportunity.no`),
		hasOrder: T(`history.${entityName}.order.has`),
		hadOrder: T(`history.${entityName}.order.had`),
		noOrder: T(`history.${entityName}.order.no`)
	};

	// Activities
	const hasActivity = entity?.hasActivity;
	const overdueActivity = (() => {
		if (!(hasActivity === true || !hasActivity)) {
			const now = moment().tz('Europe/Stockholm');
			const appDate = moment(hasActivity);
			const diff = now.diff(appDate);
			return diff >= 0;
		}
		return false;
	})();
	const hadActivityWithin12Months = entity?.hadActivity;

	// Appointments
	const hasAppointment = entity?.hasAppointment;
	const hadAppointmentWithin12Months = entity?.hadAppointment;

	// Opportunities
	const hasOpportunity = entity?.hasOpportunity;
	const hadOpportunityWithin12Months = entity?.hadOpportunity;

	// Orders
	const hasOrder = entity?.hasOrder;
	const hadOrderWithin12Months = entity?.hadOrder;

	const badgeColors = {
		passed: {
			backgroundColor: 'grey-4',
			iconColor: 'red',
			iconColorHover: 'dark-red'
		},
		has: {
			backgroundColor: `medium-${color}`,
			backgroundColorHover: `${color}`,
			iconColor: 'white'
		},
		had: {
			backgroundColor: 'grey-4',
			iconColor: `medium-${color}`,
			iconColorHover: `${color}`
		},
		none: {
			backgroundColor: 'grey-4',
			iconColor: 'grey-8',
			iconColorHover: 'grey-9'
		}
	};

	return (
		<IconBadgeGroup className={classes.b()}>
			{!hasActivity ? (
				hadActivityWithin12Months ? (
					<IconBadge icon={'activity'} tooltip={lang.hadActivity} {...badgeColors.had} />
				) : (
					<IconBadge icon={'activity'} tooltip={lang.noActivity} {...badgeColors.none} />
				)
			) : overdueActivity ? (
				<IconBadge icon={'activity'} tooltip={lang.passedActivity} {...badgeColors.passed} />
			) : (
				<IconBadge icon={'activity'} tooltip={lang.hasActivity} {...badgeColors.has} />
			)}

			{!hasAppointment && !hadAppointmentWithin12Months ? (
				<IconBadge icon={'calendar'} tooltip={lang.noAppointment} {...badgeColors.none} />
			) : null}
			{!hasAppointment && hadAppointmentWithin12Months ? (
				<IconBadge icon={'calendar'} tooltip={lang.hadAppointment} {...badgeColors.had} />
			) : null}
			{hasAppointment ? <IconBadge icon={'calendar'} tooltip={lang.hasAppointment} {...badgeColors.has} /> : null}

			{!hasOpportunity && !hadOpportunityWithin12Months ? (
				<IconBadge icon={'opportunity'} tooltip={lang.noOpportunity} {...badgeColors.none} />
			) : null}
			{!hasOpportunity && hadOpportunityWithin12Months ? (
				<IconBadge icon={'opportunity'} tooltip={lang.hadOpportunity} {...badgeColors.had} />
			) : null}
			{hasOpportunity ? (
				<IconBadge icon={'opportunity'} tooltip={lang.hasOpportunity} {...badgeColors.has} />
			) : null}

			{!hasOrder && !hadOrderWithin12Months ? (
				<IconBadge icon={'sales'} tooltip={lang.noOrder} {...badgeColors.none} />
			) : null}
			{!hasOrder && hadOrderWithin12Months ? (
				<IconBadge icon={'sales'} tooltip={lang.hadOrder} {...badgeColors.had} />
			) : null}
			{hasOrder ? <IconBadge icon={'sales'} tooltip={lang.hasOrder} {...badgeColors.has} /> : null}
		</IconBadgeGroup>
	);
};

export default SalesHistory;
