const customerId = 'ogsdweHWKUpm0Ci3KAkx9llTOugqWbZQ';
const analytics = [];
analytics.SNIPPET_VERSION = '3.1.0';
analytics.invoked = false;
analytics.methods = [
	'trackSubmit',
	'trackClick',
	'trackLink',
	'trackForm',
	'pageview',
	'identify',
	'reset',
	'group',
	'track',
	'ready',
	'alias',
	'page',
	'once',
	'off',
	'on'
];

analytics.factory = function (method) {
	return function () {
		const argumentsClone = Array.prototype.slice.call(arguments);
		argumentsClone.unshift(method);
		analytics.push(argumentsClone);
		return analytics;
	};
};

for (const method of analytics.methods) {
	analytics[method] = analytics.factory(method);
}

let enabled = true;

const Analytics = {
	loadScriptIfNeeded: () => {
		if (!analytics.initialize && !analytics.invoked) {
			analytics.invoked = true;
			// Do not like to have variables on window, but to afraid to break segment if I remove it
			window.analytics = analytics;

			const protocol = 'https:' === document.location.protocol ? 'https://' : 'http://';
			const script = document.createElement('script');
			script.type = 'text/javascript';
			script.async = true;
			script.src = `${protocol}cdn.segment.com/analytics.js/v1/${customerId}/analytics.min.js`;
			const parent = document.getElementsByTagName('script')[0];
			parent.parentNode.insertBefore(script, parent);
		}
	},
	enable: () => {
		Analytics.loadScriptIfNeeded();
		enabled = true;
	},
	disable: () => {
		enabled = false;
	},
	isEnabledAndLoaded: (waitForMixpanel = false) => {
		return enabled && analytics.invoked && (waitForMixpanel ? !!window.mixpanel : true);
	},
	trackSubmit: (...args) => {
		if (Analytics.isEnabledAndLoaded()) {
			return window.analytics.trackSubmit(...args);
		}
	},
	trackClick: (...args) => {
		if (Analytics.isEnabledAndLoaded()) {
			return window.analytics.trackClick(...args);
		}
	},
	trackLink: (...args) => {
		if (Analytics.isEnabledAndLoaded()) {
			return window.analytics.trackLink(...args);
		}
	},
	trackForm: (...args) => {
		if (Analytics.isEnabledAndLoaded()) {
			return window.analytics.trackForm(...args);
		}
	},
	pageview: (...args) => {
		if (Analytics.isEnabledAndLoaded()) {
			return window.analytics.pageview(...args);
		}
	},
	identify: (...args) => {
		if (Analytics.isEnabledAndLoaded()) {
			return window.analytics.identify(...args);
		}
	},
	reset: (...args) => {
		if (Analytics.isEnabledAndLoaded()) {
			return window.analytics.reset(...args);
		}
	},
	group: (...args) => {
		if (Analytics.isEnabledAndLoaded()) {
			return window.analytics.group(...args);
		}
	},
	track: (...args) => {
		if (Analytics.isEnabledAndLoaded()) {
			return window.analytics.track(...args);
		}
	},
	ready: (...args) => {
		if (Analytics.isEnabledAndLoaded()) {
			return window.analytics.ready(...args);
		}
	},
	alias: (...args) => {
		if (Analytics.isEnabledAndLoaded()) {
			return window.analytics.alias(...args);
		}
	},
	page: (...args) => {
		if (Analytics.isEnabledAndLoaded()) {
			return window.analytics.page(...args);
		}
	},
	once: (...args) => {
		if (Analytics.isEnabledAndLoaded()) {
			return window.analytics.once(...args);
		}
	},
	off: (...args) => {
		if (Analytics.isEnabledAndLoaded()) {
			return window.analytics.off(...args);
		}
	},
	on: (...args) => {
		if (Analytics.isEnabledAndLoaded()) {
			return window.analytics.on(...args);
		}
	},
	registerOnce: (...args) => {
		if (Analytics.isEnabledAndLoaded(true)) {
			return window.mixpanel.register_once(...args);
		}
	},
	register: (...args) => {
		if (Analytics.isEnabledAndLoaded(true)) {
			return window.mixpanel.register(...args);
		}
	},
	peopleSet: (...args) => {
		if (Analytics.isEnabledAndLoaded(true)) {
			return window.mixpanel.people.set(...args);
		}
	},
	peopleSetOnce: (...args) => {
		if (Analytics.isEnabledAndLoaded(true)) {
			return window.mixpanel.people.set_once(...args);
		}
	},
	peopleIncrement: (...args) => {
		if (Analytics.isEnabledAndLoaded(true)) {
			return window.mixpanel.people.increment(...args);
		}
	}
};

export default Analytics;
