import React from 'react';
import BemClass from '@upsales/components/Utils/bemClass';
import { IconName } from '@upsales/components';
import TimelineIcon from './TimelineIcon';
import TimelineCard, { TimelineCardProps } from './TimelineCard';
import BorderBox from 'App/components/BorderBox';

import './TimelineRow.scss';

export type TimelineRowProps = {
	event: TimelineCardProps['event'];
	className?: string;
	hideLine?: boolean;
	icon?: IconName;
	iconColor?: string;
	iconBackground?: string;
	useBorderBox?: boolean;
} & TimelineCardProps;

const TimelineRow = ({
	subSection,
	className,
	hideLine = false,
	icon,
	iconColor,
	iconBackground,
	useBorderBox = false,
	...passthrough
}: TimelineRowProps) => {
	const classNames = new BemClass('TimelineRow', className);

	const classes = classNames.mod({ hasSubSection: !!subSection }).b();

	const content = (
		<>
			{icon ? (
				<TimelineIcon name={icon} color={iconColor} backgroundColor={iconBackground} hideLine={hideLine} />
			) : null}
			<TimelineCard subSection={subSection} {...passthrough} />
		</>
	);

	if (useBorderBox) {
		return (
			<BorderBox boxShadow className={classes}>
				{content}
			</BorderBox>
		);
	}

	return <div className={classes}>{content}</div>;
};

export default TimelineRow;
