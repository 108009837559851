import Tracker from './Tracker';

const accountPlanTracker = new Tracker('accountPlan', {
	CREATE_OPPORTUNITY: 'createOpportunityAccountPlan',
	CREATE_PHONE_CALL: 'createPhoneCallAccountPlan',
	CREATE_ACTIVITY: 'createActivityAccountPlan',
	CREATE_MEETING: 'createMeetingAccountPlan',
	NOT_COMPLETED: 'notCompletedAccountPlan',
	CREATE_ORDER: 'createOrderAccountPlan',
	CREATE_TODO: 'createTodoAccountPlan',
	COMPLETED: 'completedAccountPlan',
	CANCELED: 'canceledAccountPlan',
	CREATED: 'createdAccountPlan',
	EDITED: 'editedAccountPlan'
});

export default accountPlanTracker;
