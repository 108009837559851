import React, { useState, useRef } from 'react';
import BemClass from '@upsales/components/Utils/bemClass';
import {
	Block,
	OutsideClick,
	Button,
	Icon,
	Card,
	DropDownButton,
	DropDownMenu,
	Text,
	Tooltip
} from '@upsales/components';
import { previewInBrowser } from 'App/helpers/mailHelpers';
import { sendTest } from '../../MailContextHelpers';
import MailEditorSendTest from 'Components/MailEditorSendTest';
import { useMailContext } from '../../MailContext';
import ScheduleMail from '../ScheduleMail/ScheduleMail';
import T from 'Components/Helpers/translate';
import './MailSendButton.scss';

interface Props {
	onClick: () => void;
	disabled?: boolean;
	tooltipText?: string;
	onMailSchedule?: () => void;
	loading: boolean;
}
const MailSendButton = ({ onClick, disabled = false, onMailSchedule, tooltipText, loading }: Props) => {
	const classes = new BemClass('MailSendButton');
	const [showTestMailModal, setShowTestMailModal] = useState(false);
	const [showScheduleModal, setShowScheduleModal] = useState(false);
	const { state, dispatch } = useMailContext();
	const closeDD = useRef<() => void>();
	const dropdownRef = useRef<HTMLDivElement>();
	const onSendTest = async (addresses: string[], onClose: () => void) => {
		await sendTest(dispatch, state, addresses);
		setShowTestMailModal(false);
		onClose();
		return;
	};

	return (
		<Block className={classes.mod({ disabled }).b()}>
			<Tooltip title={tooltipText} disabled={!tooltipText}>
				<Button
					disabled={disabled}
					onClick={e => {
						e.stopPropagation();
						onClick();
					}}
					loading={loading}
				>
					<Icon space="mrs" name={'paper-plane'} />
					<Text color="white">{T('mail.send')}</Text>
				</Button>
			</Tooltip>
			<DropDownMenu
				align="right"
				arrowed={false}
				renderTrigger={(expanded, setExpanded) => (
					<DropDownButton
						onClick={(e: Parameters<typeof setExpanded>[0]) => {
							e.stopPropagation();
							setExpanded(e);
						}}
						disabled={disabled}
						size="sm"
						expanded={expanded}
					/>
				)}
			>
				{setClosed => {
					closeDD.current = () => {
						setClosed();
						setShowTestMailModal(false);
						setShowScheduleModal(false);
					};
					return (
						<OutsideClick
							targetRef={() => dropdownRef.current || null}
							outsideClick={closeDD.current}
							iframeListen
						>
							<div ref={(r: HTMLDivElement) => (dropdownRef.current = r)}>
								<Card
									className={classes.elem('item-card').b()}
									onClick={() => setShowTestMailModal(true)}
								>
									<Icon space="mrs" name="paper-plane" />
									<Text>{T('mail.sendTest')}</Text>
								</Card>
								<Card className={classes.elem('item-card').b()} onClick={() => previewInBrowser(state)}>
									<Icon space="mrs" name="eye" />
									<Text>{T('default.preview')}</Text>
								</Card>
								<Card
									className={classes.elem('item-card').b()}
									onClick={() => setShowScheduleModal(true)}
								>
									<Icon space="mrs" name="schedule-mailing" />
									<Text>{T('mail.schedule')}</Text>
								</Card>
								{showTestMailModal ? (
									<div className={classes.elem('modal').b()}>
										<MailEditorSendTest
											sendTest={(addresses: string[]) => onSendTest(addresses, setClosed)}
										/>
										<Button
											className={classes.elem('cancel-button').b()}
											type="link"
											color="grey"
											onClick={() => {
												setClosed();
												setShowTestMailModal(false);
											}}
										>
											{T('default.cancel')}
										</Button>
									</div>
								) : null}
								{showScheduleModal ? (
									<div className={classes.elem('modal').b()}>
										<ScheduleMail
											onClose={() => {
												setClosed();
												setShowScheduleModal(false);
											}}
											onMailSchedule={onMailSchedule}
										/>
									</div>
								) : null}
							</div>
						</OutsideClick>
					);
				}}
			</DropDownMenu>
		</Block>
	);
};

export default MailSendButton;
