import Flow from 'App/resources/Flow';
import FlowTemplateName from '../FlowTemplateName';
import buildFlowPath from './buildFlowPath';
import { FlowTemplate } from './FlowTemplate';
import getAngularModule from 'App/babel/angularHelpers/getAngularModule';
import { SegmentFilter } from 'App/resources/Model/Segment';
import { ListViewFilter } from 'App/resources/AllIWant';

const template: FlowTemplate = {
	name: FlowTemplateName.WARM_UP_COLD_LEADS,
	title: 'flowtemplate.WARM_UP_COLD_LEADS.title',
	subtitle: 'flowtemplate.WARM_UP_COLD_LEADS.subtitle',
	imgPath: 'img/warm_up_cold_leads.svg',
	imgPathPreview: 'img/warm_up_cold_leads_preview.svg',
	getFlowPath: () => {
		return buildFlowPath([
			Flow.newStep('sendMail'),
			Flow.newStep('snooze', { waitTime: 30 }),
			Flow.newStep('sendMail'),
			Flow.newStep('snooze', { waitTime: 90 }),
			Flow.newStep('sendMail'),
		]);
	},
	getSegmentFilters: (numberOfDays: number = 1) => {
		const FilterHelper = getAngularModule('FilterHelper');
		const entity = 'contact';
		const preset = `last${numberOfDays}days`;

		const MailBounced = FilterHelper.filter('MailBounced', entity);
		MailBounced.inactive = false;
		MailBounced.value = 'hard';
		MailBounced.comparisonType = 'Equals';

		const HasEmail = FilterHelper.filter('HasEmail', entity);
		HasEmail.inactive = false;
		HasEmail.value = true;

		const JourneyStep = FilterHelper.filter('JourneyStep', entity);
		JourneyStep.inactive = false;
		JourneyStep.value = ['lead', 'sql', 'mql', 'assigned'];

		const Appointment = FilterHelper.filter('Appointment', entity);
		Appointment.inactive = false;
		const appointmentFilterValue = Appointment.value as { [key: string]: any };
		appointmentFilterValue.Date.value = {
			start: null,
			end: null,
			preset,
		};
		Appointment.value = appointmentFilterValue;

		const MailCampaignFilters = FilterHelper.filter('MailCampaignFilters', entity);
		MailCampaignFilters.inactive = false;
		const mailCampaignFilterValue = MailCampaignFilters.value as { [key: string]: any };

		mailCampaignFilterValue.Date.value = {
			start: numberOfDays,
			end: null,
			preset: 'lastXdays',
		};
		MailCampaignFilters.value = mailCampaignFilterValue;

		const includeConfig = { HasEmail, JourneyStep };
		const excludeConfig: { [filterName: string]: ListViewFilter } = {
			MailBounced,
			Appointment,
			MailCampaignFilters,
		};

		if (Tools.FeatureHelper.hasSoftDeployAccess('REMOVE_ACTIVITIES')) {
			const ContactPhoneCall = FilterHelper.filter('ContactPhoneCall', entity);
			ContactPhoneCall.inactive = false;
			const phoneCallFilterValue = ContactPhoneCall.value as { [key: string]: any };
			phoneCallFilterValue.Date.value = {
				start: null,
				end: null,
				preset,
			};
			ContactPhoneCall.value = phoneCallFilterValue;
			excludeConfig.ContactPhoneCall = ContactPhoneCall;
		} else {
			const ContactOnlyActivityFilters = FilterHelper.filter('ContactOnlyActivityFilters', entity);
			ContactOnlyActivityFilters.inactive = false;
			const phoneCallFilterValue = ContactOnlyActivityFilters.value as { [key: string]: any };
			phoneCallFilterValue.Date.value = {
				start: null,
				end: null,
				preset,
			};
			ContactOnlyActivityFilters.value = phoneCallFilterValue;
			excludeConfig.ContactOnlyActivityFilters = ContactOnlyActivityFilters;
		}

		const includeBody = FilterHelper.parseFilters(includeConfig, entity, undefined, undefined, {
			useTags: true,
			groupAllFilters: true,
		}).build() as SegmentFilter['body'];

		const excludeBody = FilterHelper.parseFilters(excludeConfig, entity, undefined, undefined, {
			useTags: true,
			groupAllFilters: true,
		}).build() as SegmentFilter['body'];

		return [
			{ config: includeConfig, body: includeBody, isExclude: false, orGroup: false },
			{ config: excludeConfig, body: excludeBody, isExclude: true, orGroup: true },
		];
	},
};

export default template;
