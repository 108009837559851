import React from 'react';
import ReportcenterFilter from './ReportcenterFilter';
import bemClass from '@upsales/components/Utils/bemClass';
import T from 'Components/Helpers/translate';
import ReportcenterFilterRow from './ReportcenterFilterRow';
import { removeItem } from 'Store/helpers/array';
import { RCDashboardFilter } from 'Resources/ReportDashboard';
import './ReportcenterFilterLabel.scss';

interface Props {
	onChange: (type: keyof RCDashboardFilter, values: string[], comparison: string) => void;
	values: RCDashboardFilter;
	updateFilterHeight: () => void;
	families: string[];
}

export const renderSelected = (values: RCDashboardFilter, renderCount: boolean = true) => {
	let res = `${T('default.typeOfSale')}: `;
	if (!renderCount) {
		const array: { id: string; name: string }[] = [];
		array.push({ id: 'NEW', name: T('arrchange.type.NEW_CLIENT') });
		array.push({ id: 'EXISTING', name: T('default.existingCustomer') });
		const newSaleNames = array
			.filter(typeOfSale => {
				return values?.ClientNewSale?.value?.includes?.(typeOfSale.id);
			})
			.map(typeOfSale => typeOfSale.name);
		res += newSaleNames.join(', ');
		return res;
	}

	if (values?.ClientNewSale?.value?.length) {
		const length = values.ClientNewSale.value.length;
		if (length >= 1) {
			res += `${length} ${(values.ClientNewSale.comparison === 'eq'
				? T('default.selected')
				: T('default.excluded', { count: length })
			).toLowerCase()}`;
		}
	}
	return res;
};

const ReportcenterFilterClientNewSale = ({ onChange, families, ...props }: Props) => {
	const classes = new bemClass('ReportcenterFilterClientNewSale');
	const value = props.values?.ClientNewSale?.value || [];
	const array: { id: string; name: string }[] = [];
	array.push({ id: 'NEW', name: T('arrchange.type.NEW_CLIENT') });
	array.push({ id: 'EXISTING', name: T('default.existingCustomer') });
	const comparison = props.values?.ClientNewSale?.comparison ?? 'eq';

	return (
		<ReportcenterFilter
			className={classes.b()}
			renderSelected={() => renderSelected(props.values)}
			getSelectedNames={() =>
				[
					value.includes('NEW') ? array[0].name : null,
					value.includes('EXISTING') ? array[1].name : null
				].filter(Boolean) as string[]
			}
			icon="opportunity"
			placeholder={`${T('default.typeOfSale')}: ${T('reportcenter.filter.notActive')}`}
			value={value}
			resetFilter={() => onChange('ClientNewSale', [], 'eq')}
			{...props}
		>
			<ReportcenterFilterRow
				key={0}
				onClick={() => {
					onChange('ClientNewSale', [], 'eq');
				}}
				selected={!value.length}
				title={T('filters.noFilter')}
			/>
			{array.map(row => (
				<ReportcenterFilterRow
					key={row.id}
					onClick={() => {
						const i = value.indexOf(row.id);
						onChange('ClientNewSale', i !== -1 ? removeItem(value, i) : [...value, row.id], comparison);
					}}
					selected={value.indexOf(row.id) !== -1}
					title={row.name}
				/>
			))}
		</ReportcenterFilter>
	);
};

export default ReportcenterFilterClientNewSale;
