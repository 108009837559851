import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Headline, Button, Block, Title } from '@upsales/components';
import WistiaVideo from '../WistiaVideo';
import bemClass from '@upsales/components/Utils/bemClass';
import getAngularModule from '../../angularHelpers/getAngularModule';

export class Creating extends Component {
	static propTypes = {
		email: PropTypes.string.isRequired,
		password: PropTypes.string.isRequired,
		closeModal: PropTypes.func.isRequired
	};

	state = {
		isFinished: false
	};

	constructor(props) {
		super(props);

		const getAngularModule = require('../../angularHelpers/getAngularModule').default;
		const t = getAngularModule('$translate');
		this.lang = {
			headline: t('createAccount.creating.headline'),
			explainer: t('createAccount.creating.explainer'),
			login: t('default.login'),
			beingCreated: t('createAccount.creating.btnCreating'),
			quickIntro: t('createAccount.creating.quickIntro')
		};
	}

	componentDidMount() {
		const Session = getAngularModule('Session');
		const $cookies = getAngularModule('$cookies');
		const Self = getAngularModule('Self');

		const interval = setInterval(() => {
			// Try to login
			return Session.login({ email: this.props.email, password: this.props.password }).then(() => {
				return Self.get().then(() => {
					$cookies.failed = undefined;
					clearInterval(interval);
					this.setState({
						isFinished: true
					});
				});
			});
		}, 5000);
	}

	login = () => {
		const security = getAngularModule('security');
		const Self = getAngularModule('Self');
		const CacheService = getAngularModule('CacheService');
		const $state = getAngularModule('$state');

		return Self.get().then(res => {
			var self = res.data;
			// set logged in variable on the app
			security.loggedIn();

			var customerId = self.clients[0].clientId;

			// Restore cache
			CacheService.restore();

			// Reset all reducers to initState
			window.reducerActions.resetAll();

			$state.go('tmpStart', { customerId: customerId });
			this.props.closeModal();
		});
	};

	render() {
		const classes = new bemClass('Creating');
		return (
			<div className={classes.b()}>
				<Block space="mbxl">
					<Headline size="sm">{this.lang.headline}</Headline>
					<Block space="mtxl mbxl">
						<Title size="md" color="grey-11">
							{this.lang.explainer}
						</Title>
					</Block>
					<Button loading={!this.state.isFinished} block size="xl" onClick={this.login}>
						{this.state.isFinished ? this.lang.login : this.lang.beingCreated}
					</Button>
					<Block space="mtxl mbxl">
						<Title size="md" color="grey-11">
							{this.lang.quickIntro}
						</Title>
					</Block>
					<img
						onClick={() => {
							Tools.$upModal.open('generic', {
								Component: WistiaVideo,
								video: 'https://upsales.wistia.com/medias/zvdnxv7ixd?wvideo=zvdnxv7ixd',
								thumbnail:
									'https://embedwistia-a.akamaihd.net/deliveries/e57f39181864a56a1575c7e0a5ec4fe5.jpg',
								constantHeight: 500,
								inlineStyle: {
									width: '890px',
									height: '500px',
									marginLeft: '-445px',
									top: '180px',
									zIndex: '9999'
								}
							});
						}}
						src={
							'https://embedwistia-a.akamaihd.net/deliveries/e57f39181864a56a1575c7e0a5ec4fe5.jpg?image_play_button_size=1x&amp;image_crop_resized=450x255&amp;image_play_button=1&amp;image_play_button_color=54bbffe0'
						}
					/>
				</Block>
			</div>
		);
	}
}

const mapStateToProps = state => ({
	email: state.CreateAccount.email,
	password: state.CreateAccount.password
});

export default connect(mapStateToProps)(Creating);
