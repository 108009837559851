import './CompetitorSelect.scss';
import React, { useState, useMemo, useEffect, useRef } from 'react';
import BemClass from '@upsales/components/Utils/bemClass';
import { Icon, Text, Block, Input, Button } from '@upsales/components';
import T from 'Components/Helpers/translate';
import logError from 'App/babel/helpers/logError';
import Resource from 'App/babel/resources/Resource';
import { PrimaryButton, ThirdButton } from '@upsales/components/Buttons';
interface Competitor {
	id: number;
	name: string;
}
interface Props {
	visible?: boolean;
	onChange: (competitorId: number | null) => void;
	onAddOptionModeChange: (addOptionMode: boolean) => void;
}

const CompetitorSelect = ({ visible = false, onChange, onAddOptionModeChange }: Props) => {
	const classes = new BemClass('CompetitorSelect');
	const animationDelay = 200;
	const transitionDurationStyle: React.CSSProperties = { transitionDuration: `${animationDelay}ms` };

	const [name, setName] = useState('');
	const inputRef = useRef<HTMLInputElement>(null);
	const [showInput, setShowInput] = useState(false);
	const [competitors, setCompetitors] = useState<Competitor[]>([]);
	const [nameInputError, setNameInputError] = useState('');
	const CompetitorResource = useMemo(() => new Resource('competitors'), []);
	const [selectedCompetitor, setSelectedCompetitor] = useState<Competitor | null>(null);

	const competitorNames = useMemo(() => {
		return competitors.map(competitor => competitor.name);
	}, [competitors]);

	const fetchCompetitors = async () => {
		try {
			const competitorsResponse = await CompetitorResource.find();
			setCompetitors(competitorsResponse?.data || []);
		} catch (err) {
			logError(err, 'Failed to get competitors');
		}
	};

	useEffect(() => {
		fetchCompetitors();
	}, []);

	useEffect(() => {
		if (name) {
			const nameLimit = 128;
			const competitorExists = competitorNames.includes(name);
			if (competitorExists) {
				setNameInputError('competitorSelect.inputError.existingCompetitor');
			} else if (name.length > nameLimit) {
				setNameInputError('competitorSelect.inputError.tooLongName');
			}
		}
	}, [name, competitorNames]);

	useEffect(() => {
		if (showInput) {
			setTimeout(() => inputRef.current?.focus?.(), animationDelay);
		}
	}, [showInput]);

	useEffect(() => {
		if (!visible) {
			setShowInput(false);
		}
	}, [visible]);

	useEffect(() => {
		onAddOptionModeChange?.(showInput);
	}, [showInput]);

	const onAddOptionButtonClick = () => {
		setShowInput(true);
	};

	const onNameInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
		setNameInputError('');
		setName(event.target.value);
	};

	const onCancelAddCompetitorButtonClick = () => {
		setShowInput(false);
	};

	const clearInput = () => {
		setShowInput(false);
		setName('');
	};

	const onAddCompetitorButtonClick = async () => {
		const competitorName = name.trim();
		if (!competitorName) {
			setNameInputError('competitorSelect.inputError.emptyName');
			return;
		}

		try {
			await CompetitorResource.save({ name: competitorName });
			clearInput();
			await fetchCompetitors();
		} catch (err) {
			logError(err, 'Failed to add competitor');
		}
	};

	const onCompetitorOptionClick = (competitor: Competitor, isSelected: boolean) => {
		const updatedCompetitor = isSelected ? null : competitor;
		setSelectedCompetitor(updatedCompetitor);
		setShowInput(false);
		onChange?.(updatedCompetitor?.id || null);
	};

	const onNameInputKeyUp = (event: React.KeyboardEvent<HTMLInputElement>) => {
		if (nameInputError || !name) {
			return;
		}
		if (event.key === 'Enter') {
			onAddCompetitorButtonClick();
		}
	};

	const renderHeaderText = () => (
		<Block space="plxl prxl mbl">
			<Text bold className={classes.elem('headerText').b()}>
				{T('competitorSelect.headerText')}
			</Text>
		</Block>
	);

	const renderCompetitorOption = (competitor: Competitor) => {
		const selected = competitor.name === selectedCompetitor?.name;
		return (
			<Block key={competitor.name} space="mrl mbm" className={classes.elem('competitorWrapper').b()}>
				<Button
					color={selected ? 'green' : 'super-light-green'}
					onClick={() => onCompetitorOptionClick(competitor, selected)}
					className={classes.elem('competitor').mod({ selected }).b()}
				>
					<Icon
						name="check"
						space="mrs"
						style={transitionDurationStyle}
						className={classes.elem('selectedIcon').mod({ visible: selected }).b()}
					/>
					{competitor.name}
				</Button>
			</Block>
		);
	};

	const renderAddOptionButton = () => (
		<ThirdButton
			disabled={!!nameInputError}
			onClick={onAddOptionButtonClick}
			className={classes.elem('addOptionButton').mod({ visible: !showInput }).b()}
		>
			<Icon name="plus" space="mrs" />
			{T('competitorSelect.addCompetitorOptionButtonText')}
		</ThirdButton>
	);

	const renderNameInput = () => (
		<>
			<Block>
				<Input
					clear
					value={name}
					onKeyUp={onNameInputKeyUp}
					onChange={onNameInputChange}
					inputClassName={classes.elem('nameInput').b()}
					placeholder={T('competitorSelect.competitorNameInputPlaceholder')}
					inputRef={inputRef}
				/>
			</Block>
			<Block space="mtm" className={classes.elem('nameInputText').b()}>
				<Text
					size="sm"
					color="red"
					style={transitionDurationStyle}
					className={classes.elem('nameInputError').mod({ visible: !!nameInputError }).b()}
				>
					{T(nameInputError)}
				</Text>

				<Text
					size="sm"
					color="grey-11"
					style={transitionDurationStyle}
					className={classes.elem('nameInputDescription').mod({ visible: !nameInputError }).b()}
				>
					{T('competitorSelect.competitorNameInputDescription')}
				</Text>
			</Block>
		</>
	);

	const renderAddCompetitorButton = () => (
		<Block space="mrm">
			<PrimaryButton
				disabled={!name || !!nameInputError}
				onClick={onAddCompetitorButtonClick}
				color={!name ? 'grey' : 'green'}
				className={classes.elem('addCompetitorButton').b()}
			>
				{T('competitorSelect.addCompetitorButtonText')}
			</PrimaryButton>
		</Block>
	);

	const renderCancelAddCompetitorButton = () => (
		<Button
			type="link"
			className={classes.elem('cancelAddCompetitorButton').b()}
			onClick={onCancelAddCompetitorButtonClick}
		>
			{T('competitorSelect.cancelAddCompetitorButtonText')}
		</Button>
	);

	return (
		<div className={classes.mod({ visible }).b()}>
			{renderHeaderText()}
			<Block space="prxl plxl" className={classes.elem('competitorOptions').b()}>
				{competitors.map(renderCompetitorOption)}
				{renderAddOptionButton()}
			</Block>

			<Block
				space="prxl plxl mbm mtxl"
				style={transitionDurationStyle}
				className={classes
					.elem('inputContainer')
					.mod({
						visible: showInput,
						noCompetitors: !competitors.length
					})
					.b()}
			>
				{renderNameInput()}

				<Block space="mtxl" className={classes.elem('buttonContainer').b()}>
					{renderAddCompetitorButton()}
					{renderCancelAddCompetitorButton()}
				</Block>
			</Block>
		</div>
	);
};

export default CompetitorSelect;
