import {
	numberFormat,
	fractionFormat,
	dayFormat,
	minutesToDaysHoursMinutes
} from 'App/babel/components/Filters/Currencies';
import { CurrencyFormat, NumberFormat } from 'App/babel/utils/numberFormat';
import { Datatypes } from 'Resources/ReportWidget';

export default (val: number, type: keyof typeof Datatypes, currency?: string, short = true): string => {
	switch (type) {
		case Datatypes['currency']: {
			const cf = new CurrencyFormat(currency);
			return short ? cf.short(val || 0) : cf.default(val || 0);
		}
		case Datatypes['percent']:
			return `${numberFormat(val, !short, 2, false).replace(/\D00$/, '')}%`;
		default:
		case Datatypes['number']: {
			const nf = new NumberFormat();
			return short ? nf.short(val || 0) : nf.default(val || 0);
		}
		case Datatypes['fraction']:
			return fractionFormat(val);
		case Datatypes['days']:
			return dayFormat(val);
		case Datatypes['decimal']: {
			const nf = new NumberFormat(undefined, 2);
			return nf.verbose(val || 0);
		}
		case Datatypes['minutes']:
			return minutesToDaysHoursMinutes(val);
	}
};

export const formatHitRateValue = (
	type: keyof typeof Datatypes,
	hitRateData: { numerator: number | undefined; denominator: number | undefined; percent: number | undefined },
	short = true
): string => {
	switch (type) {
		case Datatypes['percent']:
			if (!hitRateData.denominator) {
				return 'N/A';
			}
			return `${numberFormat(hitRateData?.percent || 0, !short, 2, false).replace(/\D00$/, '')}%`;
		default:
		case Datatypes['fraction']:
			return `${numberFormat(hitRateData.numerator ?? 0, !short)} / ${numberFormat(
				hitRateData.denominator ?? 0,
				!short
			)}`;
	}
};
