import React from 'react';
import { Icon, Tooltip } from '@upsales/components';
import moment from 'moment';
import './MailOpenClickIndicator.scss';
import bemClass from '@upsales/components/Utils/bemClass';
import T from 'Components/Helpers/translate';
import { Text } from '@upsales/components';
import Mail from 'App/resources/Model/Mail';

interface MailOpenClickIndicatorProps {
	mail: Mail;
	date?: string | Date | null | undefined;
	bounced?: boolean;
	showDate?: boolean;
}

const MailOpenClickIndicator: React.FC<MailOpenClickIndicatorProps> = ({
	mail,
	date,
	bounced,
	showDate
}: MailOpenClickIndicatorProps) => {
	if (mail.type === 'err') {
		return <></>;
	}

	const classes = new bemClass('MailOpenClickIndicator');
	const hasRead = !!mail.lastReadDate;
	const hasClicked = !!mail.lastClickDate;
	const hasReadText = mail.lastReadDate
		? `${T('event.mailWasRead')} ${moment(mail.lastReadDate).format('YYYY-MM-DD HH:mm')}`
		: T('event.mailWasNotRead');
	const hasClickedText = mail.lastClickDate
		? `${T('mail.mailEvent.click')} ${moment(mail.lastClickDate).format('YYYY-MM-DD HH:mm')}`
		: T('event.mailNotClicked');

	return date && bounced ? (
		<>
			<Text size="sm" color="grey-11">
				{`${moment(date).format('L LT')} •`}
			</Text>
			<Text space="mls" size="sm" color="red">
				{` ${mail.to}`}
			</Text>
		</>
	) : (
		<>
			<div className={classes.elem('container').b()}>
				{date && showDate && (
					<Text size="sm" color="grey-11">
						{moment(date).format('L LT')}
					</Text>
				)}
				<div className={classes.elem('event').b()}>
					<Tooltip title={hasReadText}>
						<Icon
							className={classes.elem('event').elem('read').mod({ hasRead }).b()}
							name={hasRead ? 'eye' : 'eye-slash'}
						></Icon>
					</Tooltip>
					<Tooltip title={hasClickedText}>
						<Icon
							className={classes.elem('event').elem('click').mod({ hasClicked }).b()}
							name="mouse-pointer"
						></Icon>
					</Tooltip>
				</div>
			</div>
		</>
	);
};

export default MailOpenClickIndicator;
