const INTEGRATION_URLS = [
	'standardintegrationdata',
	'standardintegrationsettings',
	'standardintegrationusersettings',
	'datasource',
	'function/esign'
];

export default function isIntegrationUrl(url: string): boolean {
	if (typeof url !== 'string') {
		return false;
	}
	const lowercasedUrl = url.toLowerCase();
	return INTEGRATION_URLS.some(integrationUrl => lowercasedUrl.includes(integrationUrl));
}
