import { mapDate } from '../../resources/genericMapper';
import type { Filter } from 'Resources/RequestBuilder';
import type { Attr } from '../attributes/Attribute';
import type { ComparisonType } from './ComparisonTypes';

export const AggregationTypes = {
	Terms: 'terms',
	Sum: 'sum',
	Stats: 'stats',
	Missing: 'missing',
	Exists: 'exists',
	Range: 'range',
	Cardinality: 'cardinality'
} as const;
type AggregationType = (typeof AggregationTypes)[keyof typeof AggregationTypes];

export const AggregationIntervals = {
	DAY: 'day',
	WEEK: 'week',
	MONTH: 'month',
	QUARTER: 'quarter',
	YEAR: 'year'
} as const;
type AggregationInterval = (typeof AggregationIntervals)[keyof typeof AggregationIntervals];

export type AggregationFilter = {
	type: AggregationType | null;
	field: string | null;
	sort?: string;
	interval?: AggregationInterval;
	size?: number;
	aggName?: string;
	order?: { value: string; sort: string };
	aggs?: AggregationFilter[];
	filter?: Filter[];
};

class Aggregation {
	parent: AggregationFilter[];
	subAggsArr: AggregationFilter[];
	aggObj: AggregationFilter;

	constructor(parent: AggregationFilter[]) {
		this.parent = parent;
		this.subAggsArr = [];
		this.aggObj = { type: null, field: null }; // use this later to build nested aggregations
	}

	// Adds a new filter to group
	addAggregation = (type: AggregationType, field: string | { field: string }, sort?: string) => {
		if (typeof field === 'object' && field.field) {
			field = field.field;
		}

		// Add this aggregation
		this.aggObj.type = type;
		this.aggObj.field = field as string;

		if (sort) {
			this.aggObj.sort = sort;
		}
	};

	aggregationInterval = (interval: AggregationFilter['interval']) => {
		this.aggObj.interval = interval;
	};

	aggregationSize = (size: AggregationFilter['size']) => {
		this.aggObj.size = size;
	};

	aggregationName = (name: AggregationFilter['aggName']) => {
		this.aggObj.aggName = name;
	};

	aggregationOrder = (attribute: string, ascending: boolean) => {
		this.aggObj.order = { value: attribute, sort: ascending ? 'asc' : 'desc' };
	};

	addFilter = (attribute: Pick<Attr, 'field'>, comparison: ComparisonType, value: Filter['v']) => {
		if (value === undefined) {
			return this;
		}
		if (this.aggObj.filter === undefined) {
			this.aggObj.filter = [];
		}
		this.aggObj.filter.push({ a: attribute.field, c: comparison, v: mapDate(value) as any });
	};

	done = () => {
		if (this.subAggsArr.length) {
			this.aggObj.aggs = this.subAggsArr;
		}
		this.parent.push(this.aggObj);
	};

	aggregationBuilder = () => {
		return new Aggregation(this.subAggsArr);
	};
}

export default Aggregation;
