module.exports = {
	ENV: 'DEV',
	URL: '/api/',
	API: 'v2/',
	SOCKET: 'http://localhost:3030',
	PUBLICPATH: 'http://localhost:3060/public/',
	FORMPATH: 'http://localhost:10001/files-public-dev/public/forms/',
	LANDINGPAGEPATH: 'http://localhost:3060',
	CLASSIC_URL: 'http://localhost:8080/professional/application/',
	STATIC_MAPS_KEY: 'AIzaSyBz8DCi-zXpKspt_-LTIlhu-0vbLYMTNOA',
	GOOGLE_FONTS_KEY: 'AIzaSyDRwD9bJV8XncXBSWG9SQxM0sHHPso5rbw',
	STATUSPAGE: {
		pageId: 'xgxqnwgpzcyz',
		key: '91c46437-14a8-4b0b-b7f8-8e3df6ded05f',
		componentId: '1gr032v9zz1j'
	},
	PUSHER: {
		key: '0dae677b9a6736e36932',
		cluster: 'mt1'
	},
	SENTRY: {
		enable: false,
		env: 'development',
		dsn: null
	},
	CHECKIN_URL: 'http://localhost:3002/',
	CUSTOM_DOMAIN_CNAME: 'alphadomain.upsales.com',
	chatEnabled: true
};
